import { connect } from 'react-redux';
import { compose } from 'recompose';
import { publishHeaderHeight } from '../store/ui/dimensions';
import { DimensionsState, State } from '../@types';

export type Props = {
  dimensions: DimensionsState;
  publishHeaderHeight: typeof publishHeaderHeight;
};

const withDimensions: any = compose(
  connect(
    ({ ui: { dimensions } }: State) => ({
      dimensions,
    }),
    {
      publishHeaderHeight,
    }
  )
);
export default withDimensions;
