import { toByteArray } from 'base64-js';
import { makeAPICall, makeS3UploadAPICall } from './helpers';
import {
  APIResponse,
  RentalData,
  CarStatusData,
  ServiceTypesData,
  ExtraOptions,
  FuelInfo,
  OutputDamage,
  ReservationInputData,
} from '../@types';
import { uploadFileFromStorage } from './helpers/makeUploadFileAPICall';

const rentalClient = {
  getRentalData: async (rentalId: string): Promise<APIResponse<RentalData>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: `/rental/${rentalId}`,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  getUserRentalData: async (): Promise<APIResponse<RentalData>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: '/rental',
    });
    return {
      notModified,
      data,
      error,
    };
  },
  getDriverCarStatus: async (
    rentalId: string,
    refresh: boolean = false
  ): Promise<APIResponse<CarStatusData>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: `/rental/${rentalId}/carStatus`,
      params: {
        refresh,
      },
    });
    return {
      notModified,
      data,
      error,
    };
  },
  reserveCar: async (
    reservationData: ReservationInputData
  ): Promise<APIResponse<RentalData>> => {
    const { data, error } = await makeAPICall({
      method: 'POST',
      url: '/rental',
      params: reservationData,
    });
    return {
      data,
      error,
    };
  },
  cancelReservation: async (
    rentalId: string
  ): Promise<APIResponse<RentalData>> => {
    const { error } = await makeAPICall({
      method: 'DELETE',
      url: `/rental/${rentalId}`,
    });
    return {
      error,
    };
  },
  finishServiceType: async (
    rentalId: string,
    type: Array<string> | null | undefined,
    release: boolean | null | undefined,
    note: string
  ): Promise<APIResponse<RentalData>> => {
    const { data, error } = await makeAPICall({
      method: 'PUT',
      url: `rental/${rentalId}/finishServiceType`,
      data: {
        serviceTypes: type,
        updateReleaseLocation: release === true,
        note,
      },
    });
    return {
      data,
      error,
    };
  },
  uploadImageS3: async (
    url: string,
    image: string
  ): Promise<APIResponse<null>> => {
    const binary = toByteArray(image);
    const { error } = await makeS3UploadAPICall({
      url,
      data: binary,
    });
    return {
      error,
    };
  },
  uploadServiceImage: async (
    rentalId: string,
    image: string
  ): Promise<APIResponse<null>> => {
    const { error } = await uploadFileFromStorage(
      `/rental/${rentalId}/uploadServiceImage`,
      image
    );
    return {
      error,
    };
  },
  finishRental: async (
    rentalId: string,
    forceViolations: Array<string>
  ): Promise<APIResponse<RentalData>> => {
    let requestData = {};

    if (forceViolations.length) {
      requestData = {
        forceViolations,
      };
    }

    const { data, error } = await makeAPICall({
      method: 'PUT',
      url: `rental/${rentalId}/finalize`,
      data: requestData,
    });
    return {
      data,
      error,
    };
  },
  getServiceTypes: async (
    rentalId: string
  ): Promise<APIResponse<ServiceTypesData>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: `/rental/${rentalId}/serviceTypes`,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  updateRental: async (
    rentalId: string,
    extraOptions: ExtraOptions
  ): Promise<APIResponse<ServiceTypesData>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'PUT',
      url: `/rental/${rentalId}`,
      data: extraOptions,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  getFuelInfo: async (rentalId: string): Promise<APIResponse<FuelInfo>> => {
    const { data, error } = await makeAPICall({
      method: 'GET',
      url: `/rental/${rentalId}/refuel`,
    });
    return {
      data,
      error,
    };
  },
  rateCar: async (
    rentalId: string,
    review: boolean
  ): Promise<APIResponse<null>> => {
    const { error } = await makeAPICall({
      method: 'PUT',
      url: `/rental/${rentalId}/rate`,
      params: {
        review,
      },
    });
    return {
      error,
    };
  },
  canFinishRental: async (
    rentalId: string
  ): Promise<APIResponse<RentalData>> => {
    const { data, error } = await makeAPICall({
      method: 'GET',
      url: `/rental/${rentalId}/canfinish`,
    });
    return {
      data,
      error,
    };
  },
  addDamage: async (
    carId: string,
    damage: OutputDamage
  ): Promise<APIResponse<OutputDamage>> => {
    const { data, error } = await makeAPICall({
      method: 'POST',
      url: `/cars/${carId}/damages`,
      data: damage,
    });
    return {
      data,
      error,
    };
  },
  deleteDamage: async (
    carId: string,
    damageId: number
  ): Promise<APIResponse<null>> => {
    const { error } = await makeAPICall({
      method: 'DELETE',
      url: `/cars/${carId}/damages/${damageId}`,
    });
    return {
      error,
    };
  },
  addDamageImage: async (
    carId: string,
    damageId: number | string,
    imageBase64: string
  ): Promise<APIResponse<null>> => {
    const binary = toByteArray(imageBase64);
    const { error } = await makeAPICall({
      method: 'POST',
      url: `/cars/${carId}/damages/${damageId}/images`,
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      data: binary,
    });
    return {
      error,
    };
  },
  addDamageImageBlob: async (
    carId: string,
    damageId: number | string,
    uri: string
  ): Promise<APIResponse<null>> => {
    const { error } = await uploadFileFromStorage(
      `cars/${carId}/damages/${damageId}/images`,
      uri
    );
    return {
      error,
    };
  },
  deleteDamageImage: async (
    carId: string,
    damageId: number,
    imageId: string
  ): Promise<APIResponse<null>> => {
    const { error } = await makeAPICall({
      method: 'DELETE',
      url: `/cars/${carId}/damages/${damageId}/images/${imageId}.jpg`,
    });
    return {
      error,
    };
  },
  unlockCar: async (
    rentalId: string,
    centralLockOnly: boolean = false
  ): Promise<APIResponse<null>> => {
    const { error } = await makeAPICall({
      method: 'PUT',
      url: `/rental/${rentalId}/unlock`,
      params: {
        centralLockOnly,
      },
    });
    return {
      error,
    };
  },
  turnIgnitionOn: async (rentalId: string): Promise<APIResponse<null>> => {
    const { error } = await makeAPICall({
      method: 'PUT',
      url: `/debug/${rentalId}/turnIgnitionOn`,
    });
    return {
      error,
    };
  },
  turnIgnitionOff: async (rentalId: string): Promise<APIResponse<null>> => {
    const { error } = await makeAPICall({
      method: 'PUT',
      url: `/debug/${rentalId}/turnIgnitionOff`,
    });
    return {
      error,
    };
  },
  uploadStartCheckImage: async (
    rentalId: string,
    image: string
  ): Promise<APIResponse<null>> => {
    const binary = toByteArray(image);
    const { error } = await makeAPICall({
      method: 'POST',
      url: `/rental/${rentalId}/uploadStartCheckImage`,
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      data: binary,
    });
    return {
      error,
    };
  },
  uploadEndCheckImage: async (
    rentalId: string,
    image: string
  ): Promise<APIResponse<null>> => {
    const binary = toByteArray(image);
    const { error } = await makeAPICall({
      method: 'POST',
      url: `/rental/${rentalId}/uploadEndCheckImage`,
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      data: binary,
    });
    return {
      error,
    };
  },
  takeService: async (rentalId: string): Promise<APIResponse<RentalData>> => {
    const { data, error } = await makeAPICall({
      method: 'PUT',
      url: `rental/${rentalId}/updateCarMode`,
      data: {
        carMode: 'reserved',
      },
    });
    return {
      data,
      error,
    };
  },
  startService: async (rentalId: string): Promise<APIResponse<RentalData>> => {
    const { data, error } = await makeAPICall({
      method: 'PUT',
      url: `rental/${rentalId}/updateCarMode`,
      data: {
        carMode: 'opened',
      },
    });
    return {
      data,
      error,
    };
  },
  generateServiceTripUrl: async (
    rentalId: string
  ): Promise<APIResponse<null>> => {
    const { data, error } = await makeAPICall({
      method: 'POST',
      url: `rental/${rentalId}/serviceImageUrl`,
    });
    return {
      data,
      error,
    };
  },
  generateStartCheckImageUrl: async (
    rentalId: string
  ): Promise<APIResponse<null>> => {
    const { data, error } = await makeAPICall({
      method: 'POST',
      url: `rental/${rentalId}/startCheckImageUrl`,
    });
    return {
      data,
      error,
    };
  },
  generateEndCheckImageUrl: async (
    rentalId: string
  ): Promise<APIResponse<null>> => {
    const { data, error } = await makeAPICall({
      method: 'POST',
      url: `rental/${rentalId}/endCheckImageUrl`,
    });
    return {
      data,
      error,
    };
  },
  getCarDamages: async (
    carId: string
  ): Promise<APIResponse<Array<OutputDamage>>> => {
    const { data, error } = await makeAPICall({
      method: 'GET',
      url: `/cars/${carId}/damages`,
    });
    return {
      data,
      error,
    };
  },
};
export default rentalClient;
