import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getFleetInsurances, bookCar } from '../store/userData/bookings';
import { CLIENT_TYPE, OutputCarData, State } from '../@types';

export type Props = {
  from: string;
  backBy: string;
  bookingCarInProgress: boolean;
  bookCar: typeof bookCar;
  getFleetInsurances: typeof getFleetInsurances;
  selectedCar: OutputCarData;
};
const withBookingConfirmation: any = compose(
  connect(
    ({
      carsSearch: { backBy, from },
      networkStatus: { activities },
      ui: {
        map: { selectedCar },
      },
    }: State) => ({
      from,
      backBy,
      selectedCar,
      bookingCarInProgress: activities.some((activityType: string) =>
        [CLIENT_TYPE.BOOKING_CLIENT.BOOK_CAR].includes(activityType)
      ),
    }),
    {
      bookCar,
      getFleetInsurances,
    }
  )
);
export default withBookingConfirmation;
