import { isIOS } from './isIOS';
import { showAlert } from './showAlert';
import {
  ActionSheetIOS,
  DeviceEventEmitter,
  PermissionsManager,
  Intercom,
} from '../config';
import { UIManager } from '../native';
import { callPhoneNumber } from './phoneCallHelpers';
import { LocalizePropType } from '../@types';

const displayMessenger = () => {
  PermissionsManager?.requestNotificationPermission(() => {
    Intercom.displayMessenger();
  });
};

const handleIntercomMode = (
  intercomHandlingMode: 'always' | 'onLogin' | 'onContactSupport' | 'never',
  supportContacted: boolean,
  isAuthorizedUser: boolean
) => {
  if (intercomHandlingMode === 'always') {
    displayMessenger();
  } else if (intercomHandlingMode === 'onContactSupport') {
    if (!supportContacted) {
      DeviceEventEmitter?.emit('setSupportContacted');
    }

    displayMessenger();
  } else if (intercomHandlingMode === 'onLogin' && isAuthorizedUser) {
    displayMessenger();
  }
};

export const helpAction = (
  showCallOption: boolean,
  localize: LocalizePropType,
  intercomHandlingMode: 'always' | 'onLogin' | 'onContactSupport' | 'never',
  supportContacted: boolean,
  isAuthorizedUser: boolean,
  isVerifiedUser: boolean = false,
  isRentalStarted: boolean = false
) => {
  if (intercomHandlingMode === 'never') {
    showAlert(
      localize('help.dialog.title'),
      '',
      localize('support.call'),
      () => {
        callPhoneNumber(localize('intercom.number'));
      },
      localize('cancel')
    );
  } else if (isVerifiedUser && showCallOption) {
    if (isIOS()) {
      ActionSheetIOS.showActionSheetWithOptions(
        {
          title: '',
          message: localize('help.dialog.title'),
          options: [
            'cancel',
            'customerSupport.call',
            'user.action.support.text',
          ].map((key) => localize(key as any as string)),
          cancelButtonIndex: 0,
        },
        (buttonIndex: any) => {
          if (buttonIndex === 1) {
            callPhoneNumber(
              localize(
                isRentalStarted ? 'customerSupport.number' : 'intercom.number'
              )
            );
          } else if (buttonIndex === 2) {
            handleIntercomMode(
              intercomHandlingMode,
              supportContacted,
              isAuthorizedUser
            );
          }
        }
      );
    } else {
      UIManager.showListAlert(
        null,
        [
          localize('customerSupport.call'),
          localize('user.action.support.text'),
        ],
        (response) => {
          if (response.buttonIndex) {
            const selectedIndex = parseInt(response.buttonIndex, 10);

            if (selectedIndex === 0) {
              callPhoneNumber(
                localize(
                  isRentalStarted ? 'customerSupport.number' : 'intercom.number'
                )
              );
            } else if (selectedIndex === 1) {
              handleIntercomMode(
                intercomHandlingMode,
                supportContacted,
                isAuthorizedUser
              );
            }
          }
        }
      );
    }
  } else {
    handleIntercomMode(
      intercomHandlingMode,
      supportContacted,
      isAuthorizedUser
    );
  }
};
