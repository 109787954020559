import { ConnectCarData, CarStatusData, ConnectDeviceData } from '../../index';

export type ConnectUiState = {
  readonly selectedCar: ConnectCarData | null;
  readonly selectedDevice: ConnectDeviceData | null;
  readonly selectedDeviceStatus: CarStatusData | null;
};
enum ConnectUiTypes {
  SET_SELECTED_CAR = 'connect/ui/SET_SELECTED_CAR',
  SET_SELECTED_DEVICE = 'connect/ui/SET_SELECTED_DEVICE',
  SET_SELECTED_DEVICE_STATUS = 'connect/ui/SET_SELECTED_DEVICE_STATUS',
}
export default ConnectUiTypes;
export type SetSelectedConnectCar = {
  type: ConnectUiTypes.SET_SELECTED_CAR;
  payload: {
    selectedCar: ConnectCarData | null;
  };
};

export type SetSelectedConnectDevice = {
  type: ConnectUiTypes.SET_SELECTED_DEVICE;
  payload: {
    selectedDevice: ConnectDeviceData | null;
  };
};

export type SetSelectedConnectDeviceStatus = {
  type: ConnectUiTypes.SET_SELECTED_DEVICE_STATUS;
  payload: {
    selectedDeviceStatus: CarStatusData | null;
  };
};

export type ConnectUiAction =
  | SetSelectedConnectCar
  | SetSelectedConnectDevice
  | SetSelectedConnectDeviceStatus;
