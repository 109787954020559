import { makeAPICall } from './helpers';
import { APIResponse } from '../@types';

const apiClient = {
  getStatus: async (): Promise<APIResponse<null>> => {
    const { error } = await makeAPICall({
      method: 'GET',
      url: '/status',
      checkModified: false,
    });
    return {
      error,
    };
  },
};
export default apiClient;
