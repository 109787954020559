import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setCircleCode, clearCircleCode } from '../store/ui/firstSteps';
import { State } from '../@types';

export type Props = {
  circleCode: string;
  circles: Array<any>;
  isOBI: boolean;
  isExpert: boolean;
  isServiceHero: boolean;
  setCircleCode: typeof setCircleCode;
  clearCircleCode: typeof clearCircleCode;
};

const withCircle: any = compose(
  connect(
    ({
      ui: {
        firstSteps: { circleCode },
        map: { selectedCar },
      },
      userData: {
        user: { user },
        rental,
      },
      carsSearch: { circleId },
      appData: { areas },
    }: State) => ({
      circleCode,
      circles: user?.circles || [],
      isServiceHero:
        user?.serviceHero ||
        !!user?.circles?.find(
          (circle) => circle.id === circleId && circle.circleType === 'service'
        ),
      isOBI:
        areas?.find((area) =>
          [selectedCar?.areaId, rental?.rentalData?.carData?.areaId].includes(
            area?.id
          )
        )?.fleetStyle === 'obi',
      isExpert:
        areas?.find((area) =>
          [selectedCar?.areaId, rental?.rentalData?.carData?.areaId].includes(
            area?.id
          )
        )?.fleetStyle === 'expert',
    }),
    {
      setCircleCode,
      clearCircleCode,
    }
  )
);
export default withCircle;
