import { connect } from 'react-redux';
import { compose } from 'recompose';
import { UserDataOutput, State } from '../@types';
import { clearAuth } from '../store/userData/user';

export type Props = {
  email: string;
  user: UserDataOutput | null | undefined;
  clearAuth: typeof clearAuth;
};

const withErrorBoundary: any = compose(
  connect(
    ({
      userData: {
        user: { email, user },
      },
    }: State) => ({
      email,
      user,
    }),
    {
      clearAuth,
    }
  )
);
export default withErrorBoundary;
