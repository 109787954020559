export default {
    "de": {
      "AT": "Österreich",
      "Ab": "Ab",
      "BE": "Belgien",
      "BG": "Bulgarien",
      "CH": "Schweiz",
      "CY": "Zypern",
      "CZ": "Tschechien",
      "Camera": "Kamera",
      "DE": "Deutschland",
      "DK": "Dänemark",
      "DL.button.text": "Führerschein jetzt scannen",
      "DL.desr": "Lass deine Fahrerlaubnis bestätigen, indem du deinen Führerschein jetzt hochlädst.",
      "DL.stepFour": "Der Führerschein wird nur akzeptiert, wenn er auf dich ausgestellt ist",
      "DL.stepOne": "Halte deinen Führerschein im Original (keine Kopie und nicht deinen Personalausweis!) bereit.",
      "DL.stepThree": "Achte auf die Lichtverhältnisse und nimm alles ab, was dein Gesicht verdeckt (Brille, Hut etc.)",
      "DL.stepTwo": "Lege deinen Führerschein auf einen neutralen Untergrund, der sich farblich vom Führerschein abhebt.",
      "DL.title": "Fahrerlaubnis",
      "EE": "Estland",
      "EL": "Griechenland",
      "ES": "Spanien",
      "EUR": "€",
      "FI": "Finnland",
      "FR": "Frankreich",
      "HR": "Kroatien",
      "HU": "Ungarn",
      "ID.button.text": "Meinen Personalausweis scannen",
      "ID.desr": "Um diese Funktion nutzen zu können, hinterlege jetzt deinen Personalausweis.",
      "ID.manual.option": "Ich habe kein Dokument mit meiner Adresse",
      "ID.stepOne": "Ich habe einen deutschen Personalausweis.",
      "ID.stepThree": "Achte auf die Lichtverhältnisse und nimm alles ab, was dein Gesicht verdeckt (Brille, Hut etc.)",
      "ID.stepTwo": "Lege deinen Personalausweis auf einen neutralen Untergrund, der sich farblich vom Ausweis abhebt.",
      "ID.title": "Personalausweis",
      "IE": "Irland",
      "IT": "Italien",
      "LT": "Litauen",
      "LU": "Luxemburg",
      "LV": "Lettland",
      "MR": "Herr",
      "MRS": "Frau",
      "MT": "Malta",
      "NL": "Niederlande",
      "PL": "Polen",
      "PT": "Portugal",
      "RO": "Rumänien",
      "SE": "Schweden",
      "SI": "Slowenien",
      "SK": "Slowakei",
      "about.dialog": "Über GETAWAY",
      "about.url": "https://get-a-way.com/getaway-carsharing?utm_source=app",
      "accepted.invitation": "ANGENOMMENE EINLADUNGEN",
      "accountHolder": "Kontoinhaber",
      "action.permission.button.title": "Neu anmelden",
      "action.permission.details": "Wir haben eine Fehlermeldung erhalten und arbeiten bereits an der Lösung. Bitte beende jetzt die App und starte sie neu. \n\nSollte das Problem weiterhin auftreten, kontaktiere bitte unser ServiceTeam.",
      "action.permission.title": "Etwas hat nicht funktioniert.",
      "add": "HINZUFÜGEN",
      "add.car.enhancement.wizard.description": "Je besser dein Auto ausgestattet ist, desto eher wird es gemietet.",
      "add.car.enhancement.wizard.title": "Aktualisiere die Ausstattung deines Autos",
      "add.damage.wizard.description": "Bitte protokolliere alle aktuellen Mängel am Auto, um für eventuelle Neuschäden reibungsfreier entschädigt zu werden.",
      "add.damage.wizard.footer": "Ich bestätige, dass das Auto bis auf die bereits aufgeführten Mängel schadenfrei ist.",
      "add.damage.wizard.title": "Schäden & Mängel",
      "add.damage.wizard.update": "ÜBERNEHMEN",
      "add.payment.method.success": "Danke, deine Zahlungsmethode ist aktualisiert worden.",
      "addpayment.card.desc": "Kreditkarte hinzufügen",
      "addpayment.card.title": "Visa oder MasterCard",
      "addpayment.directdebit.desc": "Girokonto hinzufügen",
      "addpayment.paypal": "Paypal",
      "addpayment.paypal.desc": "Verbinde dein Paypal-Konto",
      "address": "Adresse",
      "address.austria.streetNum.label": "Haus/Stiege/Tür",
      "addressCheck.dialog.message": "Die Überprüfung deiner Meldeadresse war nicht erfolgreich. \n\nBitte kontaktiere unser ServiceTeam.",
      "addressCheck.dialog.title": "Überprüfung der Meldeadresse",
      "addressCheck.dialog.tryAgain": "ERNEUT VERSUCHEN",
      "addressCheck.dialog.yes": "OK",
      "addressProof.button.text": "Dokument hochladen",
      "addressProof.desr": "Meine Adresse durch ein offizielles Dokument bestätigen lassen",
      "addressProof.stepOne": "Schreiben vom Finanzamt, von deiner Bank, etc.",
      "addressProof.stepTwo": "Adresse und Name gut lesbar",
      "addressProof.title": "Dokument mit Adresse",
      "addressSearch.cancel.text": "Abbrechen",
      "addressSearch.current.label": "Mein Standort",
      "addressSearch.search.placeholder": "Adresse suchen",
      "android.update.dialog.message": "Es ist eine neue Version erhältlich. Bitte aktualisiere auf die neuste Version.",
      "android.update.dialog.no": "Später",
      "android.update.dialog.title": "Update erhältlich",
      "android.update.dialog.yes": "Update",
      "api_error.carUnavailable": "dieses Auto ist nicht zur Verfügung bitte ein anderes wählen",
      "api_error.not_available": "Diese Aktion kann nicht ausgeführt werden.",
      "api_error.not_available_forPayin": "Diese Aktion kann mit der momentan ausgewählten Zahlungsmethode nicht ausgeführt werden.",
      "api_error.payment.3ds_required": "Bitte authentifiziere jetzt deine Kreditkarte (Händlername \"GETAWAY\"), um fortzufahren.\n\nDie Authentifizierung löst keine Zahlung aus.",
      "api_error.payment.adyen_3ds_response_not_received": "Die Antwort unseres Zahlungsdienstleisters hat zu lange gedauert. Bitte versuche es erneut.",
      "api_error.payment.card_validation_attempts_exceeded": "Verifizierung der Zahlungsmethode fehlgeschlagen, bitte kontaktiere den Support",
      "api_error.payment.card_validation_failed": "Verifizierung fehlgeschlagen",
      "api_error.user.schufaCheck.data_incomplete": "Daten unvollständig",
      "api_error.user_not_belong_to_circle_brand": "Kein Mitglied des Nutzerkreises",
      "app.old.dialog.description": "Wir haben festgestellt, dass auf deinem Gerät noch die alte GETAWAY-App installiert ist. \n\nBitte klicke auf \"Deinstallieren\", um diese zu entfernen.",
      "app.old.dialog.title": "Deinstalliere die alte App",
      "app.privacy.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA-INT_dataprivacy-de.pdf",
      "app.terms.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA-INT_termsandconditions-de.pdf",
      "applink.error": "Dieser Link ist nicht mehr aktuell. Bitte kontaktiere unser Service Team für weitere Infos.",
      "apply": "ÜBERNEHMEN",
      "area.brand_not_resolved": "Gewählter Anbieter wurde nicht erkannt.",
      "area.fleet.brand.not_match": "Gewählte Flotte gehört nicht zum ausgewählten Anbieter.",
      "area.fleet_not_resolved": "Flotte wurde nicht erkannt.",
      "area.label": "Rückgabezone",
      "area.label.outside": "Bitte beachte die Rückgabezone",
      "area.walkthrough.description": "Bringe das Fahrzeug zum Beenden der Miete in die farblich hervorgehobene Rückgabezone mit einem Tankstand von mind. 25% zurück und verriegele es mit deinem Handy.\n\nNeu: Möchtest du, dass wir das Fahrzeug zurückzubringen? Dann wähle am Ende der Miete einfach den bequemen Rückbring-Service iHv. nur 10€ + 0,49€ je 100m.",
      "area.walkthrough.title": "Zurückbringen und Miete beenden",
      "auth.failed.alert.body": "Bitte versuche es erneut oder klicke \"Vergessen?\", um dein Passwort zurückzusetzen.",
      "auth.failed.alert.title": "Falsches Passwort",
      "backend.error": "Ups..etwas ist schief gelaufen. Bitte probiere es gleich noch einmal.",
      "balance": "Guthaben",
      "balance.addFunds": "Guthaben hinzufügen",
      "balance.addFunds.description": "Spare mit unseren Guthabenpaketen",
      "balance.addPaymentMethod": "Zahlungsmittel hinzufügen",
      "balance.delete.confirm": "Möchtest du dieses Zahlungsmittel tatsächlich entfernen?",
      "balance.getFreeCredit": "Erhalte gratis Guthaben",
      "balance.getFreeCredit.description": "Meistere Herausforderungen und erhalte Belohnungen.",
      "balance.invoice.failure": "Entschuldige bitte. Der Beleg konnte nicht versendet werden.",
      "balance.invoice.success": "Dein Beleg wird dir zeitnah per E-Mail gesendet!",
      "balance.paymentMethods": "ZAHLUNGSMITTEL",
      "balance.payoutFunds": "Guthaben auszahlen",
      "balance.payoutFunds.addSEPA.dialog.message": "Bitte füge deine Kontodaten hinzu, sodass wir dir dein Guthaben auszahlen können.",
      "balance.payoutFunds.addSEPA.dialog.title": "Auszahlungsart hinzufügen",
      "balance.payoutFunds.description": "Auszahlbares Guthaben",
      "balance.payoutFunds.dialog.message": "Auszahlbares Guthaben wird deinem Konto am Monatsende automatisch gutgeschrieben.",
      "balance.payoutFunds.dialog.title": "Guthaben auszahlen",
      "balance.payoutFunds.error.message": "Auszahlbares Guthaben wird einmal im Monat automatisch auf dein hinterlegtes Bankonto überwiesen.",
      "balance.send.invoice": "BELEG\SENDEN",
      "balance.transactions": "UMSÄTZE",
      "bluetooth.dialog.description": "Per Bluethooth kannst du Autos in deiner Umgebung einfach per Handy öffnen.",
      "bluetooth.dialog.title": "Bluetooth® aktivieren",
      "bluetooth.disabled.message": "Aktiviere Bluetooth® zur Handy/Auto-Interaktion",
      "bluetooth.permission.allow.details": "Bitte erlaube GETAWAY Bluetooth-Zugriff, damit du Fahrzeuge mit der die App öffnen kannst.",
      "bluetooth.permission.allow.title": "Bluetooth Freigabe",
      "bluetooth.permission.details": "Zur sicheren Handy/Auto-Interaktion aktiviere jetzt die Bluetooth-Funktion im Control Center deines Handys.",
      "bluetooth.permission.message": "Bitte erlaube GETAWAY Bluetooth-Zugriff, damit du Fahrzeuge mit der die App öffnen kannst.",
      "bluetooth.permission.title": "Aktiviere Bluethooth®",
      "bluetooth.permission.yes": "zulassen",
      "bonus.sheet.description.lowAndOut": "Erhalte 5 € Sofort-Gutschrift, wenn du die Miete in der Rückgabezone beendest und weitere 5 €, wenn du das Fahrzeug betankst.",
      "bonus.sheet.description.lowFuel": "Erhalte eine 5€ Sofort-Gutschrift als Dank, wenn du das Fahrzeug volltankst. Die Bezahlung ist kostenfrei und bargeldlos mit der bereitgestellten Tankkarte im Handschuhfach. ",
      "bonus.sheet.description.out&low": "Erhalte eine 5€ Sofort-Gutschrift, wenn du den Mietvorgang in der Rückgabezone des Fahrzeugs beendest.\n\nErhalte weitere 5€, wenn du das Fahrzeug volltankst. Die Bezahlung ist kostenfrei und bargeldlos mit der bereitgestellten Tankkarte im Handschuhfach.",
      "bonus.sheet.description.outsideArea": "Erhalte eine 5€ Sofort-Gutschrift als Dank, wenn du den Mietvorgang in der Rückgabezone des Fahrzeugs beendest.",
      "bonus.sheet.ok": "Ok",
      "booking.BookedCarIsBLOCKED": "Fahrzeug war nicht verfügbar",
      "booking.BookedCarIsRELEASE_READY": "Fahrzeug war nicht verfügbar",
      "booking.BookedCarIsRENTED": "Fahrzeug war nicht verfügbar",
      "booking.BookedCarIsUnavailable": "Fahrzeug war nicht verfügbar",
      "booking.BookingCancelled": "Reservierung wurde storniert",
      "booking.DriverHasOngoingRental": "Es lief bereits eine Miete",
      "booking.OtherReason": "technische Probleme",
      "booking.OtherRentalConstraintViolation": "Fahrzeug war nicht verfügbar",
      "booking.RentalCancelled": "Miete wurde storniert",
      "booking.RentalExpired": "Reservierung abgelaufen",
      "booking.accessToken": "technische Probleme",
      "booking.backByConflictsWithBooking": "technische Probleme",
      "booking.backByExpired": "technische Probleme",
      "booking.blockedByUpcomingBooking": "technische Probleme",
      "booking.blockedPaymentConfirmed": "Zahlung fehlgeschlagen",
      "booking.button.text": "Auf Karte anzeigen",
      "booking.cancelled.at": "Reservierung storniert um %s",
      "booking.car.unavailable": "Das Fahrzeug ist nicht verfügbar",
      "booking.confirm.from": "Von",
      "booking.confirm.profile": "Fahrzeug",
      "booking.confirm.to": "Bis",
      "booking.confirmation.button": "Jetzt reservieren",
      "booking.confirmation.title": "Prüfe deine Reservierung",
      "booking.conformation.no.address": "Abholzone anzeigen",
      "booking.create": "GETAWAY finden",
      "booking.create.success": "Danke! Wir haben deine Anfrage erhalten und suchen jetzt einen passenden GETAWAY für dich.",
      "booking.details.header.descr": "Sobald die Reservierung beginnt, hast du 30 Minuten Zeit, das Fahrzeug mit deinem Handy zu entriegeln.",
      "booking.details.section.title": "Deine Reservierung",
      "booking.driver.accepted.title": "gute Fahrt mit deinem GETAWAY!",
      "booking.driver.cancel": "Reservierung stornieren",
      "booking.driver.cancel.dialog.message": "Stornierst du diese Buchung, wird deine Anfrage gelöscht.",
      "booking.driver.cancel.dialog.title": "Buchung stornieren",
      "booking.driver.cancel.dialog.yes": "Jetzt stornieren",
      "booking.driver.cancel.success": "Reservierung wurde erfolgreich storniert.",
      "booking.driver.confirm.dialog.message": "Gute Wahl! Mit deiner Bestätigung wird dieser GETAWAY für dich reserviert.",
      "booking.driver.confirm.dialog.title": "Diesen GETAWAY reservieren",
      "booking.driver.confirm.dialog.yes": "Jetzt reservieren",
      "booking.driver.details.description": "Sobald wir passende Autos für dich gefunden haben, schicken wir dir die Auswahl.",
      "booking.driver.details.header.description": "Bitte sei nachsichtig. Dieses Feature befindet sich in der Testphase. Wir freuen uns über dein Feedback!",
      "booking.driver.details.header.title": "Finde deinen perfekten GETAWAY! [BETA]",
      "booking.driver.details.text": "Die Preise basieren auf der genannten Dauer und Gesamtstrecke inkl. Kraftstoff. Der Endpreis berechnet sich nach tatsächlicher Dauer und zurückgelegter Strecke.",
      "booking.driver.details.title": "Deine Reservierung",
      "booking.driver.nooffers": "✨\nVerfügbare GETAWAYs\nwerden dir hier angezeigt.",
      "booking.driver.offer.request": "Anfragen",
      "booking.driver.offer.reserve": "Reservieren",
      "booking.driver.offer.reserving": "Reservierung läuft...",
      "booking.driver.offered.title": "wähle deinen perfekten GETAWAY!",
      "booking.driver.waitingoffer": "Angebot warten",
      "booking.end": "Bis",
      "booking.fuelCardMissing": "technische Probleme",
      "booking.haveBlockedPaymentIfPayInOnly": "bitte wählest du den Zeitrahmen für die  Reservierung.",
      "booking.header.in": "Noch",
      "booking.headline.expired": "Reservierung ist abgelaufen",
      "booking.headline.reservation": "Reservierungsdaten",
      "booking.headline.started": "Reservierung hat begonnen",
      "booking.label.checked": "Zurückgenommen",
      "booking.label.expired": "Abgelaufen",
      "booking.label.started": "Gestartet",
      "booking.label.waiting.checkin": "in Rückgabe",
      "booking.map.bar.text": "Nächste Reservierung:",
      "booking.mileage.placeholder": "Ich weiß noch nicht",
      "booking.mileage.title": "Gesamtstrecke (circa)",
      "booking.online": "technische Probleme",
      "booking.payByLink.description": "Schließe jetzt die Zahlung ab und kehre im Anschluss zur App zurück.\n\nDieser Service wird im Auftrag von \"Share die Robbe\" bereitgestellt von der GETAWAY GmbH.",
      "booking.payin.blockPayment.description": "Hinterlege jetzt eine Kaution iHv. 75€, um die Reservierung abzuschließen.\n\nIm Anschluss an den Mietvorgang wird dir die Kaution nach Verrechnung aller Mietkosten erstattet.",
      "booking.payin.blockPayment.title": "Kaution bei Zahlung per Vorkasse",
      "booking.payin.blockPayment.yes.text": "Weiter",
      "booking.places.current": "Aktuelle Position",
      "booking.places.search": "Adresse eingeben",
      "booking.places.title": "Von wo",
      "booking.preferredcar.title": "Auto",
      "booking.requestedCar.title": "Fahrzeug",
      "booking.start": "Von",
      "booking.start.address": "Von wo",
      "booking.userMarkedForFraud": "Zahlung fehlgeschlagen",
      "booking.warning.payin.button": "Jetzt Kaution hinterlegen",
      "booking.warning.payin.text": "Hinterlege jetzt die Kaution, um die Reservierung abzuschließen. Andernfalls wird sie in wenigen Minuten storniert.\n\nIm Anschluss an den Mietvorgang wird dir die Kaution nach Verrechnung aller Mietkosten erstattet.",
      "booking.warning.payin.text.payed": "Du hast die Kaution erfolgreich hinterlegt. Bitte warte nun auf den Beginn deiner Miete.",
      "booking.warning.payin.title": "Kaution",
      "booking.warning.payin.title.payed": "Gut gemacht!",
      "booking.warning.payin.value": "75,00 Euro",
      "bookings.headline.booked": "Gebucht",
      "bookings.headline.reservation": "Reserviert",
      "bookings.profile.cancel.text": "Reservierung stornieren",
      "bookings.profile.title": "Meine Reservierungen",
      "bookings.where.desc": "Der genaue Standort deines Fahrzeugs wird dir 30 Minuten vor Buchungsbeginn angezeigt.",
      "brand.getaway.name": "GETAWAY",
      "brand.mis.name": "Miet Smart",
      "brand.msi.name": "Miet Smart",
      "brand.rw.name": "Share die Robbe",
      "brand.ubq.name": "ubeeqo",
      "call": "Anrufen",
      "callout.show.availability": "Verfügbarkeit anzeigen",
      "camera.addressProof.upload": "hochladen",
      "camera.permission.details": "Um fortzufahren und Fotos aufzunehmen, erlaube GETAWAY jetzt Zugriff auf deine Kamera.",
      "camera.permission.no": "Später",
      "camera.permission.title": "Zugriff auf Kamera",
      "camera.permission.yes": "Erlauben",
      "cancel": "Abbrechen",
      "cancel.FirstSteps.dialog.message": "Nur 2 Minuten: Schließe jetzt die Registrierung ab und starte sofort deine erste Miete.",
      "cancel.FirstSteps.dialog.message.noBonus": "Nur 2 Minuten: Schließe jetzt die Registrierung ab und starte sofort deine erste Miete.",
      "cancel.FirstSteps.dialog.no": "Abbrechen",
      "cancel.FirstSteps.dialog.yes": "Jetzt nicht",
      "cancel.rental.handover": "Möchtest du den aktuellen Mietvorgang abbrechen?",
      "car.approximateMileage.gt250k": "über 250.000 km",
      "car.approximateMileage.lt100k": "50.000 - 100.000 km",
      "car.approximateMileage.lt150k": "100.000 - 150.000 km",
      "car.approximateMileage.lt200k": "150.000 - 200.000 km",
      "car.approximateMileage.lt250k": "200.000 - 250.000 km",
      "car.approximateMileage.lt50k": "unter 50.000 km",
      "car.available.book": "BUCHEN",
      "car.co2.text": "Kg eingespartes CO2",
      "car.damage": "Schaden",
      "car.damage.create.dialog.title": "Schaden oder Mangel melden",
      "car.damage.edit.notallowed.description": "Während einer laufenden Miete kann das Schadenprotokoll nicht geändert werden.",
      "car.damage.edit.notallowed.title": "Änderungen nicht möglich",
      "car.damage.remove.dialog.remove": "Schaden entfernen",
      "car.damage.remove.dialog.title": "Bist du sicher?",
      "car.damages": "Schäden",
      "car.damages.photos.dialog.description": "Füge jetzt bitte mindestens ein Foto von diesem Schaden/Mangel hinzu, um diesen besser nachvollziehbar zu machen.",
      "car.damages.photos.dialog.title": "Fotos vom Schaden",
      "car.data.title": "Stammdaten",
      "car.demo.unavailable.messege": "Bitte öffne den Appstore und aktualisiere jetzt deine App-Version, um dieses Auto zu mieten.",
      "car.demo.unavailable.title": "Miete nicht möglich",
      "car.details.available.until.placeholder": "Verfügbar bis",
      "car.details.car.info": "AUTOPROFIL",
      "car.details.carplate.placeholder": "Kennzeichen",
      "car.details.damages": "Schäden",
      "car.details.equipment": "AUSSTATTUNG",
      "car.details.fuel.diesel": "Diesel",
      "car.details.fuel.electric": "Elektrisch",
      "car.details.fuel.hybrid": "Hybrid",
      "car.details.fuel.hybrid_diesel": "Hybrid/Diesel",
      "car.details.fuel.hybrid_unleaded95": "Hybrid/Benzin 95",
      "car.details.fuel.hybrid_unleaded98": "Hybrid/Benzin 98",
      "car.details.fuel.lpg": "LPG",
      "car.details.fuel.other": "Anderer",
      "car.details.fuel.placeholder": "Kraftstoff",
      "car.details.fuel.unleaded95": "Benzin 95",
      "car.details.fuel.unleaded98": "Benzin 98",
      "car.details.gear.automatic": "Automatik",
      "car.details.gear.manual": "Manuell",
      "car.details.gear.type.placeholder": "Getriebeart",
      "car.details.height": "Ladehöhe",
      "car.details.initial.registration.placeholder": "Erstzulassung",
      "car.details.length": "Ladelänge",
      "car.details.list.damages.placeholder": "Gemeldete Schäden",
      "car.details.listofdamages.title": "SCHADENSLISTE",
      "car.details.loadCapacity": "Ladegewicht",
      "car.details.lock.text": "VERRIEGELN",
      "car.details.mileage.gt100": "Mehr als 100.000 km",
      "car.details.mileage.lt100": "Weniger als 100.000 km",
      "car.details.mileage.lt20": "Weniger als 20.000 km",
      "car.details.mileage.lt50": "Weniger als 50.000 km",
      "car.details.mileage.placeholder": "Kilometerstand",
      "car.details.pickupreturn.placeholder": "Standort",
      "car.details.pollution.placeholder": "Umweltplakette",
      "car.details.pos.reviews": "Positive Bewertungen",
      "car.details.reviews": "Bewertungen",
      "car.details.techincaldata": "Technische Daten",
      "car.details.uhr": "Uhr",
      "car.details.unlock.text": "Miete starten",
      "car.details.unlock.text.brand": "Miete starten",
      "car.details.unlocking.text": "Entriegeln...",
      "car.details.width": "Ladebreite",
      "car.device.doors.open": "Bitte stelle sicher, dass alle Türen verschlossen sind.",
      "car.device.fuel.card.missing": "Bitte lege die Tankkarte in die dafür vorgesehene Halterung im Handschuhfach.",
      "car.device.keys.missing": "Bitte stelle sicher, dass der Autoschlüssel in der dafür vorgesehenen Halterung im Handschuhfach liegt.",
      "car.device.windows.open": "Bitte stelle sicher, dass alle Fenster verschlossen sind.",
      "car.equipment.abs": "ABS",
      "car.equipment.adaptiveLighting": "Kurvenlicht",
      "car.equipment.airConditioning": "Klimaanlage",
      "car.equipment.alloyWheels": "Leichtmetallfelgen",
      "car.equipment.auxiliaryHeating": "Standheizung",
      "car.equipment.childSeat": "Kindersitz",
      "car.equipment.collisionAvoidanceSystem": "Notbremsassistent",
      "car.equipment.cruiseControl": "Tempomat",
      "car.equipment.disabledAccessible": "Behindertengerecht",
      "car.equipment.distanceControlAssistSystem": "Abstandstempomat",
      "car.equipment.electricHeatedSeats": "Sitzheizung",
      "car.equipment.esp": "ESP",
      "car.equipment.feature": "Extras",
      "car.equipment.fourWheelDrive": "Allradantrieb",
      "car.equipment.handsFreeKit": "Freisprechanlage",
      "car.equipment.interior": "Innenausstattung",
      "car.equipment.laneDepartureWarningSystem": "Spurhalteassistent",
      "car.equipment.multifunctionalWheel": "Multifunktionslenkrad",
      "car.equipment.navigationSystem": "Navigationssystem",
      "car.equipment.panoramicRoof": "Panorama-Dach",
      "car.equipment.parkingAssistSystem": "Einparkhilfe",
      "car.equipment.powerAssistedSteering": "Servolenkung",
      "car.equipment.rainSensor": "Regensensor",
      "car.equipment.roofBars": "Dachgepäckträger",
      "car.equipment.safety": "Sicherheit",
      "car.equipment.startStopSystem": "Start/Stopp-Automatik",
      "car.equipment.sunroof": "Schiebedach",
      "car.equipment.tractionControl": "Traktionskontrolle",
      "car.equipment.trailerCoupling": "Anhängerkupplung",
      "car.feedback.text": "liebe dein Auto",
      "car.findNearest.notExist": "Kein verfügbares Fahrzeug. Versuche einen anderen Zeitraum oder vergrößere den Kartenausschnitt.",
      "car.handover.failed.description.unlock": "Entriegele das Auto und starte den Vorgang erneut.",
      "car.handover.failed.title": "Vorgang fehlgeschlagen",
      "car.handover.manual.fuelLevel.description": "Niemand möchte ein Auto mit fast leerem Tank hinterlassen bekommen. Bitte bestätige, dass das der Tankstand mindestens ca. 25% beträgt.",
      "car.handover.manual.fuelLevel.title": "Tankstand ausreichend?",
      "car.handover.nodamages": "Keine gemeldeten Schäden",
      "car.insurance": "Versicherungsschutz",
      "car.owner.dialog.rented.message": "Zum Schutz der Privatsphäre des Mieters ist die aktuelle Position deines Autos verschlüsselt. Wir benachrichtigen dich im Anschluss des Mietvorgangs.",
      "car.owner.dialog.rented.title": "Aktuell vermietet",
      "car.pollution.green": "Grün",
      "car.pollution.nobadge": "Keine",
      "car.pollution.red": "Rot",
      "car.pollution.unknown": "Unbekannt",
      "car.pollution.yellow": "Gelb",
      "car.price": "%@ € pro km",
      "car.price.desc": "Lege fest, was du pro Kilometer verdienen möchtest. Du kannst diesen Wert jederzeit anpassen.",
      "car.price.footer": "*Hierbei handelt es sich um eine unverbindliche Schätzung. Letztlich hängen die tatsächlichen Einnahmen von der Verfügbarkeit und Begehrtheit deines Autos ab.",
      "car.price.high": "Zu teuer",
      "car.price.low": "Zu günstig",
      "car.price.title": "Verdienst pro Kilometer",
      "car.price.update": "SPEICHERN",
      "car.profile.blockReasons.label": "Downtime-Grund:",
      "car.profile.brand": "Marke",
      "car.profile.damages": "Schäden & Mängel",
      "car.profile.description": "Bearbeite dein Autoprofil",
      "car.profile.dimensions.note": "Maße können abweichen. Wenn es knapp werden könnte, wähle zur Sicherheit eine größere Fahrzeugkategorie.",
      "car.profile.engine": "Motorleistung",
      "car.profile.equipment": "Ausstattung",
      "car.profile.history.date.interval.to": "bis",
      "car.profile.instructions": "Die besten Tipps, um deine Mieteinnahmen zu erhöhen, findest du in unserem %@.",
      "car.profile.instructions.url": "https://service.get-a-way.com/wie-vermiete-ich-mein-auto#mein-autoprofil",
      "car.profile.instructions.url.text": "HilfeCenter",
      "car.profile.lastCleaning.label": "Letzte Reinigung:",
      "car.profile.model": "Modell",
      "car.profile.price.details": " https://robbe.app/feetable",
      "car.profile.price.message": "Circa %@ € pro Monat*",
      "car.profile.price.per.km": "Dein Verdienst pro km",
      "car.profile.rental.description": "Liste aller Mietvorgänge",
      "car.profile.rental.history": "MIETVORGÄNGE",
      "car.profile.rented.back": "Rückkehr spät.",
      "car.profile.terminate": "AUTO ENTFERNEN",
      "car.profile.view": "Autoprofil",
      "car.registration.camera.description": "Positioniere die aufgeklappte Kfz-Schein Vorderseite innerhalb der Markierung.",
      "car.registration.photo.description": "(vollständig aufgeklappt)",
      "car.registration.photo.title": "Kfz-Schein Vorderseite",
      "car.release.ignition.message": "Du kannst dein Auto nur freigeben, wenn der Motor aus ist.",
      "car.release.ignition.title": "Freigabe nicht möglich",
      "car.released": "Dein Auto ist freigegeben worden",
      "car.released.error": "Bitte probiere es später erneut",
      "car.rental.cancelled": "Deine Reservierung ist storniert worden.",
      "car.rental.cancelled.time": "Deine Buchung ist storniert worden. Du hast das Auto nicht rechtzeitig geöffnet.",
      "car.rental.count": "Mietvorgänge",
      "car.rental.rental": "Mietvorgang",
      "car.rental.rentals": "Mietvorgänge",
      "car.rental.trips": "FAHRTEN",
      "car.rentals.title": "Mietvorgänge",
      "car.rented.back": "Zurück am",
      "car.rented.backtoday": "Zurück um",
      "car.rented.backtomorrow": "Morgen zurück um",
      "car.reserve.blockPayment.description": "Mit der Reservierung werden 50,-€  auf deiner Kreditkarte geblockt und im Anschluss an den Mietvorgang nach Verrechnung mit den Mietkosten wieder freigegeben.",
      "car.reserve.blockPayment.title": "Kaution bei Kreditkartenzahlung",
      "car.reserve.blockPayment.yes.text": "Fortfahren",
      "car.reserve.carBooked.description": "Dieses Fahrzeug hat eine Folgemiete und muss bis zu diesem Zeitpunkt zurückgebracht werden. Andernfalls können Gebühren anfallen.",
      "car.reserve.carBooked.title": "Fahrzeugrückgabe in %s.",
      "car.reserve.carBooked.yes.text": "Zustimmen",
      "car.review.title": "Erfahrungen",
      "car.search.notExist": "Dieses Fahrzeug existiert nicht.",
      "car.service.count": "Service-Fahrten",
      "car.status.not.found": "Autostatus kann nicht gelesen werden.",
      "car.submit.approximateMileage": "Ungefährer Kilometerstand",
      "car.submit.footer": "Das Auto  ist verkehrstüchtig mit gültiger Haftpflichtversicherung und Hauptuntersuchung.",
      "car.submit.leased": "Ich bin Leasingnehmer",
      "car.submit.legalnotice": "Ich stimme einer etwaigen Datenprüfung zur Zahlungswahrscheinlichkeit zu und bestätige, dass das Auto verkehrstüchtig ist und über eine gültige Haftpflichtversicherung wie auch einer Hauptuntersuchung verfügt.",
      "car.submit.legalnotice.url": "https://getaway-docs.s3.eu-central-1.amazonaws.com/de-DE/Getaway_DataPrivacy-SchufaChecks-de.pdf",
      "car.submit.legalnotice.url.text": "Datenprüfung",
      "car.submit.notowner": "Ich bin nicht Halter/Eigentümer",
      "car.submit.owner": "Ich bin Halter/Eigentümer",
      "car.submit.ownership": "Wem gehört das Auto?",
      "car.submit.ownership.description": "Du kannst das Auto problemlos anmelden, auch wenn du selbst nicht Halter/Eigentümer bist. Mehr Infos folgen in der Anmeldebestätigung.",
      "car.terminate.dialog.message": "Bitte kontaktiere unser Service Team, um dein Autoprofil entfernen zu lassen.",
      "car.terminate.dialog.title": "Autoprofil entfernen",
      "car.title": "DEIN AUTO",
      "car.transaction.title": "Mieteinnahme %@",
      "car.unlock.bluetooth.disabled": "Bluetooth® aktivieren \nzum Entriegeln",
      "car.unlock.bluetooth.disabled.brand": "Bluetooth® aktivieren",
      "car.unlock.bluetooth.searching": "Distanz verringern\nzum Entriegeln",
      "car.unlock.bluetooth.searching.brand": "Distanz verringern",
      "car.unlock.closeToCar.action": "Ich stehe am Fahrzeug - jetzt entriegeln",
      "car.unlock.tooFar.message": "Bitte probiere es erneut aus geringerer Distanz. Sobald sich dein Handy mit dem Fahrzeug verbunden hat, kannst du es mit der App entriegeln.\n\nBei Problemen kontaktiere bitte unser ServiceTeam über das Chat-Symbol oben rechts im Bildschirm.",
      "car.unlock.tooFar.title": "Bitte verringere die Distanz",
      "car.unreleased": "Dein Auto ist nicht mehr freigegeben",
      "car.unreleased.error": "Dein Auto kann nicht freigegeben werden. Bitte probiere es später erneut.",
      "carCheck.addImage.labelFour": "Hinten, rechts",
      "carCheck.addImage.labelOne": "Vorne, links",
      "carCheck.addImage.labelThree": "Hinten, links",
      "carCheck.addImage.labelTwo": "Vorne, rechts",
      "card.number": "Kreditkartennummer",
      "carprofile.footnote": "Die besten Tipps, um deine Mieteinnahmen zu erhöhen, findest du in unserem %@.",
      "carprofile.footnote.url": "https://service.get-a-way.com/wie-vermiete-ich-mein-auto#mein-autoprofil",
      "carprofile.footnote.url.text": "HilfeCenter",
      "carprofile.troubleshoot.description": "Der Motor deines Autos springt nicht an? Hier kannst du die Motorsperre entriegeln.",
      "carprofile.troubleshoot.title": "Motorsperre verwalten",
      "cars.carousel.title": "Finde deinen perfekten GETAWAY!",
      "challenges.completed.section": "GEMEISTERTE HERAUSFORDERUNGEN",
      "challenges.firstSteps.addPayment.text": "Zur Verifizierung deines Nutzerkontos",
      "challenges.firstSteps.addPayment.title": "Hinterlege eine Zahlungsmethode",
      "challenges.firstSteps.bonusTransactionReason": "Erste Schritte",
      "challenges.firstSteps.createAccount.text": "Großartig, du hast dich erfolgreich registriert",
      "challenges.firstSteps.createAccount.title": "Erstelle ein Nutzerkonto",
      "challenges.firstSteps.description": "Du bist kurz davor, die Straße zu erobern. Erledige schnell die ersten Schritte und erhalte sofort %s € Startguthaben!",
      "challenges.firstSteps.firstRental.text": "Mache deinen ersten Ausflug mit GETAWAY",
      "challenges.firstSteps.firstRental.title": "Miete ein Auto",
      "challenges.firstSteps.listCar.text": "Lass dein Auto Geld für dich verdienen!",
      "challenges.firstSteps.listCar.title": "Registriere dein Auto",
      "challenges.firstSteps.saveAddress.text": "Schreibe uns, wo du wohnst",
      "challenges.firstSteps.saveAddress.title": "Hinterlege deine Meldeadresse",
      "challenges.firstSteps.title": "Erste Schritte",
      "challenges.firstSteps.verifyDriversLicense.text": "Um Zugang zum Fuhrpark zu erhalten",
      "challenges.firstSteps.verifyDriversLicense.title": "Bestätige deine Fahrerlaubnis",
      "challenges.firstSteps.verifyPhoneNumber.text": "Um dein Handy zum Autoschlüssel zu machen",
      "challenges.firstSteps.verifyPhoneNumber.title": "Bestätige deine Handy-Nummer",
      "challenges.inviteFriends.bonusTransactionReason": "Eingeladener Freund",
      "challenges.inviteFriends.description": "Schenke deinen Freunden und Nachbarn einen 10€ Willkommensbonus und erhalte selbst 10€, wenn sie diesen einlösen.",
      "challenges.inviteFriends.title": "Lade 5 Freunde ein",
      "challenges.up.to": "Bis zu",
      "change": "Ändern",
      "change.password.success": "Dein Passwort ist erfolgreich aktualisiert worden.",
      "circle.brand_not_resolved": "Nutzerkreis nicht erkannt",
      "circles.button.text": "ÜBERNEHMEN",
      "circles.input.placeholder": "Einladungscode eingeben",
      "circles.join.success.msg": "Du hast jetzt besonderen Zugang zu Fahrzeugen und Preisen!",
      "circles.join.success.title": "Du bist dem Nutzerkreis beigetreten.",
      "clear.damages": "Schäden zurücksetzen",
      "clear.damages.prompt.text": "Bist du sicher?",
      "clipboard.link.copied": "Dein Einladungslink ist in deine Zwischenablage kopiert worden.",
      "confirm": "Bestätigen",
      "contacts.invite.contact": "LADE %d KONTAKT EIN",
      "contacts.invite.contact.many": "LADE %d KONTAKTE EIN",
      "contacts.invite.earn": "Verdiene bis zu %@",
      "contacts.permission.details": "Um fortzufahren und Kontakte auszuwählen, erlaube GETAWAY jetzt Zugang zu deinen Kontakten.",
      "contacts.permission.no": "später",
      "contacts.permission.title": "Zugriff auf Kontakte",
      "contacts.permission.yes": "Erlauben",
      "continue": "Fortfahren",
      "creditcheck.terms": "Ich stimme einer etwaigen Datenprüfung zur Zahlungswahrscheinlichkeit für die Auswahl der Zahlarten zu.",
      "creditcheck.terms.highlight": "Datenprüfung",
      "creditcheck.terms.url": "https://getaway-docs.s3.eu-central-1.amazonaws.com/de-DE/Getaway_DataPrivacy-SchufaChecks-de.pdf",
      "custom.camera.description.car.registration": "Bitte fotografiere deinen Fahrzeugschein auf einer neutralen Oberfläche und stelle sicher, dass er innerhalb des Rahmens ist.",
      "custom.camera.description.damage": "Bitte achte darauf, dass der Schaden gut zu erkennen und in der Mitte des Photos ist.",
      "custom.camera.description.license": "Bitte lege deinen Führerschein auf eine neutrale Oberfläche innerhalb der Markierung und nimm ein Foto auf.",
      "custom.camera.description.selfie": "Bitte halte den Aufnahme-Button für 3 Sekunden gedrückt.",
      "custom.camera.holdmessage.selfie": "Halte den Aufnahme-Button für 3 Sekunden gedrückt, um dein Selfie aufzunehmen.",
      "custom.camera.repeat": "Wiederholen",
      "custom.camera.submit": "Übernehmen",
      "custom.camera.title.car.registration": "Fahrzeugschein",
      "custom.camera.title.damage": "Schadenaufnahme",
      "custom.camera.title.license.back": "Führerschein-Rückseite",
      "custom.camera.title.license.front": "Führerschein-Vorderseite",
      "custom.camera.title.selfie": "Selfie",
      "damage.add.noImages.error": "Bitte stelle sicher, dass du mindestens zwei Bilder hinzugefügt hast.",
      "damage.add.success": "Vielen Dank für deine Schadensmeldung!",
      "damage.detials.reportedon": "Gemeldet am:",
      "damage.new": "NEU",
      "damage.new.notification.description": "Vielen Dank für die Aktualisierung des Schadenprotokolls! Du hast nun die Möglichkeit, die Vermieterin anzurufen.",
      "damages.add": "Schaden melden",
      "damages.add.button.text": "Schaden hinzufügen",
      "damages.add.error.noimages": "Bitte füge mind. ein Foto des Schadens hinzu, um fortzufahren.",
      "damages.add.image.note": "Mindestens zwei Fotos (unterschiedliche Blickwinkel/ Abstände). Auf einem Foto muss das Kennzeichen zu erkennen sein.",
      "damages.bumper": "Stoßstange",
      "damages.burn": "Brandfleck",
      "damages.carRoadworthy": "Ist das Fahrzeug verkehrstüchtig?",
      "damages.characteristic": "Eigenschaften",
      "damages.characteristic.freeText": "anderes",
      "damages.characteristics.burn": "Brandfleck",
      "damages.characteristics.crack": "Riss",
      "damages.characteristics.deepScratch": "Kratzer (bis zur Grundierung)",
      "damages.characteristics.deepscratch": "Kratzer (bis zur Grundierung)",
      "damages.characteristics.dent": "Delle",
      "damages.characteristics.dirt": "Verschmutzung",
      "damages.characteristics.missing": "Nicht vorhanden",
      "damages.characteristics.other": "Anderes",
      "damages.characteristics.permanentDirt": "Dauerhafte Verschmutzung",
      "damages.characteristics.scratch": "Kratzer (oberflächlich)",
      "damages.characteristics.stoneChip": "Steinschlag",
      "damages.chars.left": "verbleibende Zeichen",
      "damages.component": "Bauteil",
      "damages.component.bumper": "Stoßstange",
      "damages.component.door": "Tür",
      "damages.component.engineBonnet": "Motorhaube",
      "damages.component.exteriorMirror": "Spiegel",
      "damages.component.floor": "Boden",
      "damages.component.hubcap": "Radkappe",
      "damages.component.interiorTrim": "Innenverkleidung",
      "damages.component.other": "Anderes",
      "damages.component.rearWindow": "Fenster",
      "damages.component.seat": "Sitz",
      "damages.component.sill": "Schweller",
      "damages.component.trunk": "Kofferraum/ Ladefläche",
      "damages.component.wheelRim": "Felge",
      "damages.component.windscreen": "Frontscheibe",
      "damages.component.wing": "Kotflügel",
      "damages.confirmRecord.button.text": "Schadensmeldung bestätigen",
      "damages.crack": "Riss",
      "damages.damage.desc": "Schadenbeschreibung",
      "damages.deepscratch": "Kratzer",
      "damages.dent": "Delle",
      "damages.description": "Schadensbeschreibung",
      "damages.dirt": "Verschmutzung",
      "damages.door": "Tür",
      "damages.engine.bonnet": "Motorhaube",
      "damages.exterior": "Außen",
      "damages.exterior.mirror": "Spiegel",
      "damages.floor": "Boden",
      "damages.gt10cm": ">10 cm",
      "damages.hubcap": "Radkappe",
      "damages.interior": "Innen",
      "damages.location": "Lage",
      "damages.location.front": "Vorderseite",
      "damages.location.na": "nicht verfügbar",
      "damages.location.rear": "Rückseite",
      "damages.location.side.center": "Mitte",
      "damages.location.side.codriver": "Beifahrerseite",
      "damages.location.side.driver": "Fahrerseite",
      "damages.lt10cm": "5 - 10cm",
      "damages.lt5cm": "<5 cm",
      "damages.new.photos.add": "Fotos hinzufügen",
      "damages.noRecord.text": "Keine Schadenmeldung",
      "damages.other": "Anderes",
      "damages.pernament.dirt": "Dauerhafte Verschmutzung",
      "damages.rear.window": "Fenster",
      "damages.scratch": "Kratzer",
      "damages.seat": "Sitz",
      "damages.side": "Seite",
      "damages.side.center": "Mitte",
      "damages.side.coDriversSide": "Beifahrerseite",
      "damages.side.driversSide": "Fahrerseite",
      "damages.sill": "Schweller",
      "damages.size": "Größe",
      "damages.size.gt10cm": ">10cm",
      "damages.size.lt10cm": "5 - 10cm",
      "damages.size.lt5cm": "<5cm",
      "damages.stonechip": "Steinschlang",
      "damages.thirdParty": "Beteiligung einer dritten Partei",
      "damages.thirdParty.carRoadworthyDontKnow": "Ich weiß es nicht",
      "damages.thirdParty.dontKnow": "Ich weiß nicht",
      "damages.thirdParty.isInvolved": "Dritte Parteien sind beteiligt",
      "damages.thirdParty.notInvolved": "Dritte Parteien sind nicht beteiligt",
      "damages.thirdParty.notRoadworthy": "Nicht verkehrstüchtig",
      "damages.thirdParty.roadworthy": "Verkehrstüchtig",
      "damages.title": "Schadensbericht",
      "damages.toolbar.done": "Fertig",
      "damages.toolbar.next": "Nächstes",
      "damages.trim": "Innenverkleidung",
      "damages.trunk": "Kofferraum",
      "damages.wheel.rim": "Felge",
      "damages.where": "Wo",
      "damages.where.exterior": "Außen",
      "damages.where.interior": "Innen",
      "damages.windscreen": "Frontscheibe",
      "damages.wing": "Kotflügel",
      "dashboard.about": "Rechtliches",
      "dashboard.balance": "Zahlungen",
      "dashboard.carprofile": "Autoprofil",
      "dashboard.dialog": "Impressum",
      "dashboard.dialog.getaway": "GETAWAY Impressum",
      "dashboard.dialog.getaway.url": "https://getaway.app/imprint",
      "dashboard.dialog.interrent.url": "https://www.interrent.com/de/rechtliche-hinweise",
      "dashboard.dialog.msi.url": "https://www.mietsmart.com/about/",
      "dashboard.dialog.privacy": "Datenschutzerklärung",
      "dashboard.dialog.rw": "Impressum",
      "dashboard.dialog.rw.url": "https://robbe.app/imprint",
      "dashboard.dialog.terms": "AGB",
      "dashboard.dialog.ubq": "Impressum",
      "dashboard.dialog.ubq.url": "https://ubq.app/imprint",
      "dashboard.freecredit": "Erhalte gratis Guthaben",
      "dashboard.headline": "Hallo 👋\n%@ %@",
      "dashboard.help": "Hilfe-Center",
      "dashboard.hi": "Hi",
      "dashboard.list.car": "Erfahre, wie viel du mit deinem Auto verdienen kannst.",
      "dashboard.list.cars": "Weiteres Auto anmelden",
      "dashboard.logout": "ABMELDEN",
      "dashboard.logout.active.rental.prompt": "Bitte beende den Mietvorgang",
      "dashboard.logout.prompt": "Möchtest du dich tatsächlich abmelden?",
      "dashboard.logout.success": "Du hast dich erfolgreich abgemeldet.",
      "dashboard.myRentals": "Mietvorgänge",
      "dashboard.profile": "Nutzerkonto",
      "dashboard.releasing": "Freigeben...",
      "dashboard.review": "Die App bewerten",
      "dashboard.services": "Serviceaufträge",
      "dashboard.settings": "EINSTELLUNGEN",
      "day": "Tag",
      "day.ago": "Tage her",
      "day.short": "Tag",
      "days": "Tage",
      "days.ago": "Tage her",
      "days.short": "Tage",
      "delete": "Entfernen",
      "description": "Beschreibung",
      "dialog.later": "Später",
      "direct.debit": "Lastschriftverfahren",
      "direct.debit.account.bic": "Bankleitzahl",
      "direct.debit.account.number": "Kontonummer",
      "direct.debit.add": "HINZUFÜGEN",
      "direct.debit.agreements.less": "Hiermit erteile ich der GETAWAY GmbH (Gläubiger-ID DE62ZZZ00002030615) eine Einzugsermächtigung als SEPA-Lastschriftmandat zur Einziehung fälliger Zahlungen und weise mein Geldinstitut an, die von GETAWAY bzw. ihrem Zahlungsdienstleister auf mein Konto gezogenen Lastschriften einzulösen. Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen. Ich stimme der Fristverkürzung für die Vorankündigungen (Pre-Notification) zukünftiger SEPA-Lastschriften auf einen (1) Kalendertag zu. …Weniger ↑",
      "direct.debit.agreements.more": "Hiermit erteile ich der GETAWAY GmbH (Gläubiger-ID DE62ZZZ00002030615) eine Einzugsermächtigung als SEPA-Lastschriftmandat (Mehr ↓) zur Einziehung fälliger Zahlungen und weise mein Geldinstitut an, ...",
      "direct.debit.desc": "Hinterlege deine IBAN für sichere Ein- und Auszahlungen per SEPA-Lastschriftverfahren.",
      "direct.debit.iban": "IBAN",
      "direct.debit.title": "Lastschriftverfahren",
      "directdebit": "Lastschriftverfahren",
      "disconnected.dialog.message": "Du scheinst keine Internetverbindung zu haben. Bitte gehe online, um die App nutzen zu können.",
      "disconnected.dialog.title": "Du bist offline",
      "distance": "Gesamtstrecke",
      "document.upload.success": "hochladen erfolgreich abgeschlossen",
      "done": "Fertig",
      "dongle.rental.fuelSettings.instruction": "Die Quittung muss nicht aufbewahrt werden. Im Anschluss lege bitte die Tankkarte zurück in das Heftchen zum KFZ-Schein.",
      "dongle.rental.section.locked": "Zurückgegeben",
      "dongle.rental.section.unlocked": "Übernommen",
      "driver.id.status.open": "Personalausweis hinzufügen",
      "driver.id.status.verified": "Personalausweis",
      "driver.id.status.verifying": "Personalausweis wird geprüft",
      "driver.license.banned.failure": "Bitte trage den Zeitraum ein",
      "driver.license.banned.update.button": "AKTUALISIEREN",
      "driver.license.banned.update.date.button": "WÄHLE",
      "driver.license.banned.update.description": "Ich habe ein behördlich angeordnetes Fahrverbot innerhalb des angegebenen Zeitraums.",
      "driver.license.banned.update.end": "Verbotsende",
      "driver.license.banned.update.start": "Verbotsbeginn",
      "driver.license.banned.update.title": "Fahrverbot",
      "driver.license.banned.warning": "Bitte beginne mit dem Anfangsdatum des Fahrverbots",
      "driver.license.bottom.description": "Hiermit versichere ich, dass meine Fahrerlaubnis behördlich ausgestellt und in Deutschland gültig ist und ich bei Bekanntwerden eines behördlich angeordneten Fahrverbots, diesen unverzüglich an GETAWAY melde.",
      "driver.license.frontImage": "Vorderseite",
      "driver.license.info.desc": "Bitte lass deinen Führerschein bestätigen, damit du Autos in deiner Umgebung mieten kannst.",
      "driver.license.info.save": "BESTÄTIGEN",
      "driver.license.info.title": "Deine Fahrerlaubnis",
      "driver.license.notuploaded.description": "Bitte lass deinen Führerschein bestätigen",
      "driver.license.open.description": "Eingereicht am %@",
      "driver.license.rearImage": "Rückseite",
      "driver.license.selfieImage": "Dein Selfie",
      "driver.license.status.deleted": "Abgelehnt, bitte erneut prüfen lassen",
      "driver.license.status.notuploaded": "Führerschein hinzufügen",
      "driver.license.status.open": "Wird geprüft...",
      "driver.license.status.paused": "Pausiert",
      "driver.license.status.verified": "Führerschein",
      "driver.license.status.verifying": "Führerschein wird geprüft",
      "driver.license.submit.failed": "Bitte lade alle Fotos hoch, um fortzufahren.",
      "driver.license.submit.notNow": "Bitte lass deine Fahrerlaubnis bestätigen, um Autos in deiner Nähe mieten zu können.",
      "driver.license.submit.success": "Danke. Wir haben deine Fahrerlaubnis erhalten und melden uns bei dir nach erfolgter Prüfung.",
      "driver.license.submited.description": "Eingereicht am %@",
      "driver.license.title": "Fahrerlaubnis",
      "driver.license.top.description": "Lass deine Fahrerlaubnis bestätigen, indem du deinen Führerschein jetzt hochlädst.",
      "driver.license.verified.description": "Aktualisiere deine Fahrerlaubnis",
      "driver.mis.price.description": "{0} €/Tag + {1} €/Mehr-Km",
      "driver.mis.price.map.description": "€/Tag",
      "driver.mis.spain.price.description": "{0} €/Tag",
      "driver.mis.spain.price.map.description": "€/Tag",
      "driver.mis.transporter.price.description": "{0} €/Std + {1} €/Mehr-Km",
      "driver.mis.transporter.price.map.description": "€/Std",
      "driver.price.description": "{0} €/Std. + {1} €/km",
      "driver.price.ikea.description": "{0} €/Std + {1} €/km",
      "driver.price.ikea.map.description": "€/Std.",
      "driver.price.map.description": "€/Std.",
      "driver.price.obi.description": "{0} €/Std. + {1} €/Mehr-Km",
      "drivercar.profile.priceDetails": "Preisinfo",
      "drivercar.profile.request.action": "Anfragen",
      "drivercar.profile.reserve.action": "Jetzt buchen",
      "drivercar.profile.service.reserve.action": "Service starten",
      "drivercar.rental.dialog.message": "Möchtest du das Auto für den persönlichen Gebrauch mieten oder einen GETAWAY Service durchführen?",
      "drivercar.rental.dialog.reserve.rental": "Weiter für persönlichen Gebrauch",
      "drivercar.rental.dialog.reserve.service": "Weiter als Service-Trip",
      "drivercar.rental.dialog.title": "Bitte wähle dein Fahrerprofil",
      "drivercar.reserved.status": "RESERVIERT",
      "drivercar.review.novalue": "✨ Neues Auto",
      "drivercar.unavailable.status": "Aktuell in Nutzung",
      "driving.damage.candrive": "Auto ist noch verkehrstüchtig.",
      "driving.damage.cantdrive": "Auto ist nicht mehr verkehrstüchtig.",
      "driving.finish.rental.dialog.message": "Der Mietvorgang kann nur beendet werden, wenn der Motor aus ist.",
      "driving.finish.rental.dialog.retry": "Erneut versuchen",
      "driving.finish.rental.dialog.title": "Beenden nicht möglich",
      "driving.refuel.dialog.message": "Der Tankvorgang kann nur gestartet werden, wenn der Motor aus ist.",
      "driving.refuel.dialog.title": "Tanken nicht möglich",
      "drivinglicense.form.selfie.description": "Video (3 Sekunden) aufnehmen",
      "drivinglicense.form.selfie.done.dialog.again": "Wiederholen",
      "drivinglicense.form.selfie.done.dialog.message": "Dein Selfie wird zur Verifizierung deines Führerscheins benötigt.",
      "drivinglicense.form.selfie.done.dialog.title": "Selfie hochladen",
      "duration": "Gesamtdauer",
      "enjoyYourTrip.location.subtitle": "Abhol- & Rückkehrort",
      "enjoyYourTrip.title": "Gute Fahrt!",
      "equipment.4wd": "Allradantrieb",
      "equipment.abs": "ABS",
      "equipment.aircondition": "Klimaanlage",
      "equipment.childseat": "Kindersitz",
      "equipment.collosion.avoidance": "Notbremsassistent",
      "equipment.cruise": "Tempomat",
      "equipment.disabled": "Behindertengerecht",
      "equipment.esp": "ESP",
      "equipment.handsfree": "Freisprechanlage",
      "equipment.heating.aux": "Standheizung",
      "equipment.heating.seat": "Sitzheizung",
      "equipment.light.adaptive": "Kurvenlicht",
      "equipment.multifunctionalwheel": "Multifunktionslenkrad",
      "equipment.navigation": "Navigationssystem",
      "equipment.parking.assistant": "Einparkhilfe",
      "equipment.powersteering": "Servolenkung",
      "equipment.roof.bars": "Dachgepäckträger",
      "equipment.roof.panorama": "Panorama-Dach",
      "equipment.sensor.lane": "Spurhalteassistent",
      "equipment.sensor.proximity": "Abstandstempomat",
      "equipment.sensor.rain": "Regensensor",
      "equipment.start-stop": "Start/Stopp-Automatik",
      "equipment.sunroof": "Schiebedach",
      "equipment.traction": "Traktionskontrolle",
      "equipment.trailor": "Anhängerkupplung",
      "equipment.wheels.alloy": "Leichtmetallfelgen",
      "error": "Etwas hat nicht funktioniert. Bitte probiere es erneut. Danke!",
      "feature.gate.register.title": "PLZ FREISCHALTEN",
      "feature.gate.register.url": "https://www.get-a-way.com/",
      "feature.gate.start": "2018-01-18",
      "feature.gate.submit": "STARTEN",
      "feature.gate.zip.error": "Diese Postleitzahl ist noch nicht freigeschaltet. Sichere dir jetzt deine Einladung.",
      "feature.gate.zipcodes": "10589",
      "fees.url": "https://get-a-way.com/entgelte",
      "finish.rental.refreshButton": "Aktualisieren",
      "finished.rental.carkey.description": "Bitte lege den Autoschlüssel in das dafür vorgesehene Fach zurück.",
      "finished.rental.carkey.title": "Autoschlüssel",
      "finished.rental.checkBelongings.button": "FINISHED",
      "finished.rental.checkBelongings.title": "Bitte achte auf deine persönlichen Gegenstände.",
      "finished.rental.doors.description": "Bitte stelle sicher",
      "finished.rental.doors.title": "Fenster & Türen",
      "finished.rental.fuelLevel.description": "Bitte betanke das Fahrzeug (kostenfrei mit Tankkarte), bevor du den Mietvorgang beendest. Andernfalls können Entgelte (<25%: 5€; <10%: 50€) fällig werden.",
      "finished.rental.fuelLevel.title": "Geringer Tankstand",
      "finished.rental.fuelcard.description": "Bitte lege die Tankkarte in das dafür vorgesehene Fach zurück.",
      "finished.rental.fuelcard.title": "Tankkarte",
      "finished.rental.location.button": "ROUTE ANZEIGEN",
      "finished.rental.location.description": "Bitte beende die Miete in der angegebenen Rückgabezone. Andernfalls können Gebühren anfallen.",
      "finished.rental.location.force": "Mietende erzwingen",
      "finished.rental.location.title": "Rückgabezone",
      "finished.rental.locked.button": "VERRIEGELN",
      "finished.rental.locked.description": "Um fortzufahren, verriegele jetzt bitte das Auto von außen und stelle sicher, dass alle Türen und Fenster verschlossen sind.",
      "finished.rental.locked.title": "Auto von außen verriegeln",
      "finished.rental.thank.you.button": "JA",
      "finished.rental.thank.you.description": "Bitte entnehme den Schlüssel aus dem Handschuhfach.",
      "firstSteps.address.city.placeholder": "Stadt",
      "firstSteps.address.country.placeholder": "Land",
      "firstSteps.address.instructions": "Bitte beachte, dass es sich hierbei um deine aktuelle Meldeadresse handeln muss.",
      "firstSteps.address.personalInfo": "Persönliche Informationen",
      "firstSteps.address.postalCode.placeholder": "PLZ",
      "firstSteps.address.search.text": "Adresse suchen",
      "firstSteps.address.streetName.placeholder": "Straße",
      "firstSteps.address.streetNum.placeholder": "Nr.",
      "firstSteps.address.subTitle": "Adresse",
      "firstSteps.address.submitBtn.text": "Hinzufügen",
      "firstSteps.address.title": "Meldeadresse",
      "firstSteps.payment.sepa.description.text": "Hiermit erteile ich der GETAWAY GmbH (Gläubiger-ID DE62ZZZ00002030615) eine Einzugsermächtigung als SEPA-Lastschriftmandat zur Einziehung fälliger Zahlungen und weise mein Geldinstitut an, die von GETAWAY bzw. ihrem Zahlungsdienstleister auf mein Konto gezogenen Lastschriften einzulösen. Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen. Ich stimme der Fristverkürzung für die Vorankündigungen (Pre-Notification) zukünftiger SEPA-Lastschriften auf einen (1) Kalendertag zu.",
      "firstSteps.payment.sepa.iban.placeholder": "IBAN",
      "firstSteps.payment.sepa.instruction": "Hinterlege deine IBAN für sichere Ein- und Auszahlungen per SEPA-Lastschriftverfahren.",
      "firstSteps.payment.sepa.name.placeholder": "Kontoinhaber",
      "firstSteps.payment.sepa.submit.text": "Hinzufügen",
      "firstSteps.payment.sepa.title": "Lastschriftverfahren hinzufügen",
      "firstSteps.payment.sepa.url": "https://google.com",
      "firstSteps.payment.sepa.url.text": "Mehr",
      "from": "Ab",
      "fuelstation.ap.name": "AP",
      "fuelstation.aral.name": "ARAL",
      "fuelstation.aral.title": "Aral Tankstelle",
      "fuelstation.avanti.name": "Avanti",
      "fuelstation.bp.name": "BP",
      "fuelstation.eni.name": "Eni",
      "fuelstation.help.text": "Hilfe zur Nutzung der Tankkarte",
      "fuelstation.om.name": "OM",
      "fuelstation.omv.name": "OMV",
      "fuelstation.openallday": "24 Stunden geöffnet",
      "fuelstation.opennow": "Aktuell geöffnet:",
      "fuelstation.openstoday": "Heute geöffnet:",
      "fuelstation.openstomorrow": "Morgen geöffnet:",
      "fuelstation.status.prefix": "Tankstelle",
      "fuelstation.total.name": "Total",
      "fuelstation.westfalen.name": "Westfalen",
      "handover.all.around.confirm": "Ignorieren & fortfahren",
      "handover.all.around.no": "Rundum-Fotos aufnehmen",
      "handover.all.around.warning": "Bitte bedenke, dass du verpflichtet bist, den Fahrzeugzustand beim Beenden der Miete mittels Bereitstellung der Rundum-Fotos zu bezeugen.",
      "handover.belonging.title": "Alle Habseligkeiten entnommen",
      "handover.belongings.button.title": "Ich habe alles bei mir",
      "handover.belongings.decr": "Bitte überprüfe, dass du keine privaten Gegenstände im Auto vergessen hast.",
      "handover.belongings.title": "Nichts vergessen?",
      "handover.bottom.description": "Du findest den Schlüssel im Handschuhfach auf der Beifahrerseite",
      "handover.carCheck.title": "Rundum-Fotos aufgenommen",
      "handover.card.title": "Tankkarte zurückgelegt",
      "handover.damages.count": "%d Schäden",
      "handover.demages.description": "Gemeldete Schäden",
      "handover.dongle.card.title": "Tankkarte zurückgeben",
      "handover.dongle.key.title": "Schlüssel zurückgeben",
      "handover.doorsWidows.title": "Türen und Fenster verschlossen",
      "handover.doorsWindows.title": "Ich habe die Türen und Fenster verschlossen",
      "handover.engine.dialog.message": "Starte einfach den Motor, um den Übergabe-Check zu bestätigen. Du findest den Autoschlüssel im Handschuhfach auf der Beifahrerseite.",
      "handover.engine.dialog.title": "Jetzt Motor starten",
      "handover.fuel.title": "Ich wähle den Tank-Service",
      "handover.fuelCard.button.title": "Aktualisieren",
      "handover.fuelCard.decr": "Bitte sicherstellen, dass sich die Tankkarte im Handschuhfach befindet.",
      "handover.fuelCard.title": "Tankkarte zurückgelegt",
      "handover.key.title": "Schlüssel zurückgelegt",
      "handover.location.button.title": "Rückgabeort anzeigen",
      "handover.location.decr": "Bitte die Miete im Umkreis des angegebenen Rückgabeorts beenden. ",
      "handover.location.option": "Selbst fahren",
      "handover.location.title": "Rückgabeort",
      "handover.location.warning": "Ich erkläre mich einverstanden, 500 € zu zahlen.",
      "handover.lock.button.title": "Auto verriegeln",
      "handover.lock.locking": "Verriegle ...",
      "handover.lock.title": "Schließe alle Türen und Fenster und verriegele das Fahrzeug von außen.",
      "handover.lowFuel.button.title": "Suche nächste Tankstelle",
      "handover.lowFuel.decr": "Bitte tanke das Auto, bevor du die Miete beendest. Für Hilfe, kontaktiere unser Service-Team.",
      "handover.lowFuel.option": "Selbst tanken",
      "handover.lowFuel.title": "Niedriger Kraftstoffstand",
      "handover.lowFuel.warning": "Ich erkläre mich einverstanden, 50 € zu zahlen.",
      "handover.report": "Schaden bzw. Mangel melden",
      "handover.returnKey.button.title": "Aktualisieren",
      "handover.returnKey.decr": "Bitte sicherstellen, dass sich der Autoschlüssel im Handschuhfach befindet.",
      "handover.returnKey.title": "Autoschlüssel zurückgeben",
      "handover.returnLocation.title": "Ich wähle den Rückbring-Service (nur innerhalb von 5km um die Rückgabezone möglich)",
      "handover.title": "Starte jetzt den Motor",
      "handover.top.description": "Wenn das Auto bis auf die bereits aufgeführten Mängel schadenfrei ist, starte den Motor und habe eine gute Fahrt!",
      "handover.unlock.description": "Deine Miete wurde nicht beendet. Bitte versuche es erneut. Sollte das Problem anschließend nicht behoben sein, wende dich bitte an unser ServiceTeam.",
      "handover.unlock.title": "Etwas ist schief gelaufen!",
      "help.dialog.chat": "LiveChat",
      "help.dialog.helpcenter": "HilfeCenter",
      "help.dialog.title": "Kundenservice",
      "home.about": "© GETAWAY, rechtl. Info ",
      "home.walkthrough.login": "Login",
      "home.walkthrough.signup": "Los geht's",
      "hours.short": "Std.",
      "ikeaMain.duration.prefix": ".",
      "image.upload.success": "Hochladen erfolgreich",
      "input.error.invalid": "Falsche Eingabe",
      "insecuredevice.dialog.message": "Dieses Handy ist nicht mit deinem Nutzerkonto verknüpft. Um es zu verifizieren, senden wir dir einen Bestätigungscode per SMS an die Handynummer, die in deinem Konto hinterlegt ist.",
      "insecuredevice.dialog.no": "Jetzt nicht",
      "insecuredevice.dialog.title": "Dieses Handy verifizieren",
      "insecuredevice.dialog.yes": "Verifizieren",
      "insurance.charterline.150": "Maximal 150 €",
      "insurance.charterline.1500": "Kein Versicherungsschutz",
      "insurance.charterline.15000": "Kein Schutz",
      "insurance.charterline.151": "Maximal 150 €",
      "insurance.charterline.500": "Maximal 500 €",
      "insurance.charterline.501": "Maximal 500 €",
      "insurance.charterline.650": "Maximal 650 €",
      "insurance.charterline.750": "Maximal 750 €",
      "insurance.charterline.description": "Selbstbeteiligung",
      "insurance.charterline.description.1500": "Bis zu 15.000 €",
      "insurance.charterline.headline": "Selbstbeteiligung",
      "insurance.charterline.value.150": "+19 €/Tag",
      "insurance.charterline.value.1500": "inkl.",
      "insurance.charterline.value.15000": "inkl.",
      "insurance.charterline.value.151": "+29 €/Tag",
      "insurance.charterline.value.500": "+11 €/Tag",
      "insurance.charterline.value.501": "+21 €/Tag",
      "insurance.charterline.value.650": "inkl.",
      "insurance.charterline.value.750": "inkl.",
      "insurance.description": "maximal",
      "insurance.description.free": "Bis zu",
      "insurance.label.free": "Kein Versicherungsschutz",
      "insurance.previous_for_the_same_car_must_be_used": "Bei Benutzung des gleichen Fahrzeugs muss die selbe Versicherungsoption gewählt werden.",
      "insurance.rental.title": "Deine Selbstbeteiligung!",
      "insurance.value": "€/Tag",
      "insurance.value.free": "inkl.",
      "internet.disconnected.message": "Bitte überprüfe deine Internetverbindung",
      "invitation.description": "Gib jetzt deinen Einladungscode an",
      "invitation.remove.text": "ENTFERNEN",
      "invitation.submit.text": "ÜBERNEHMEN",
      "invitation.text.placeholder": "Einladungscode",
      "invitation.title": "Du hast eine Einladung?",
      "invite.browse.contacts.button": "KONTAKTE DURCHSUCHEN",
      "invite.contacs.success": "Einladung ist abgeschickt worden. Wir benachrichtigen dich, sobald sie angenommen wird.",
      "invite.contact.email.warning": "Du musst einen E-Mail Account eingerichtet haben",
      "invite.email.description": "GETAWAY ist der erste professionelle Fuhrpark von Autofahrern für Autofahrer. Wenn du ein Auto hast, kannst du es Geld verdienen lassen und somit alle deine Autokosten decken. Hast du einen Führerschein, kannst du jederzeit und überall dein Wunschauto per App finden und sofort losfahren. Probiere es aus und sichere dir mit meiner Einladung 10€ Startguthaben auf %@",
      "invite.email.title": "Mach mit bei GETAWAY",
      "invite.friends.share.button": "TEILEN",
      "invite.friends.share.description": "Zum Kopieren, Einladungslink klicken und halten",
      "invite.message": "Ich schenke dir 10€ Startguthaben für GETAWAY, deinem Universalschlüssel zu jedem Auto in deiner Umgebung.  Oder vermiete dein eigenes Auto und verdiene 100€ wöchentlich. Lade dir jetzt gratis die App herunter.",
      "invite.message.title": "Du bist zu GETAWAY eingeladen worden",
      "joinCircle.title": "Nutzerkreis beitreten",
      "keine": "Keine",
      "landing.card1.desc": "Decke deine Autokosten, indem du es sicher und ohne Aufwand in deiner Nachbarschaft vermietest.",
      "landing.card1.title": "Dein Auto verdient es!",
      "landing.card2.desc": "Unsere Versicherung schützt dein Auto und deine Mobilität.",
      "landing.card2.title": "Rundum versichert",
      "landing.later": "SPÄTER",
      "landing.login": "Ich habe bereits ein Nutzerkonto",
      "landing.signup": "Willkommen!",
      "landing.signup.dialog.email.exists": "Es scheint, als sei deine E-Mail Adresse bereits bei GETAWAY hinterlegt. Möchtest du dein Passwort zurücksetzen?",
      "late": "abgelaufen",
      "less": "Weniger",
      "license.addPhoto.back": "Führerschein-Rückseite hinzufügen",
      "license.addPhoto.front": "Führerschein-Vorderseite hinzufügen",
      "license.addVideo": "Selfie-Video hinzufügen",
      "license.addedPhoto.back": "Führerschein-Rückseite",
      "license.addedPhoto.front": "Führerschein-Vorderseite",
      "license.addedVideo": "Selfie-Video",
      "license.submit.title": "Weiter",
      "license.title": "Führerschein hinzufügen",
      "list.car.desc": "Fotografiere jetzt die Vorderseite deines Fahrzeugscheins und lass gratis prüfen, wie viel dein Auto für dich verdienen kann.",
      "list.car.footer": "Das Auto hat eine gültige Haftpflichtversicherung und eine gültige Hauptuntersuchung.",
      "list.car.license": "Kfz-Schein Vorderseite (vollst. aufgeklappt)",
      "list.car.ownership.error": "Bitte gib das Eigentumsverhältnis an",
      "list.car.ownership.message": "Wer nicht Halter bzw. Eigentümer des Fahrzeugs ist, muss alternativ eine Vollmacht vorlegen. Eine Vorlage wird zur Verfügung gestellt.",
      "list.car.submit": "JETZT CHECKEN LASSEN",
      "list.car.success": "Danke, dein Fahrzeugschein ist übermittelt worden. Wir schicken dir eine E-Mail mit der weiteren Vorgehensweise.",
      "list.car.takeaphoto.error": "Bitte lade deinen Kfz-Schein hoch, um fortzufahren.",
      "list.car.title": "Wie viel verdient dein Auto?",
      "loading": "loading ...",
      "location.permission": "Bitte aktiviere jetzt Standortdienste",
      "location.permission.details": "Um u.a. Autos in deiner Nähe angezeigt zu bekommen, aktiviere jetzt bitte die Standortdienste.",
      "location.permission.no": "Später",
      "location.permission.title": "Standortdienste aktivieren",
      "location.permission.unauthorized": "GETAWAY kann ohne die Info über deinen Standort nicht einwandfrei funktionieren.",
      "location.permission.yes": "Erlauben",
      "lock.error": "Auto kann nicht verschlossen werden. Bitte kontaktiere unser Service Team.",
      "login.button.title": "Login",
      "login.email.placeholder": "E-Mail",
      "login.failed.again.text": "Erneut versuchen",
      "login.failed.alert.body": "E-Mail und/oder Passwort stimmen nicht. Versuche es noch einmal oder klicke \"Vergessen?\"",
      "login.failed.alert.title": "Anmelden fehlgeschlagen",
      "login.forgot.text": "Passwort zurücksetzen",
      "login.forgot.title": "Passwort vergessen?",
      "login.orloginfacebook.text": "Oder per",
      "login.orsignupfacebook.text": "Oder per",
      "login.password.placeholder": "Passwort",
      "login.title": "Schön, dich wiederzusehen!",
      "logout.prompty.logout": "JA",
      "mages.510cm": "5 - 10cm",
      "map.bookings.button.text": "Reservierung ab",
      "map.callout.bonus.lowAndOut": "Bis zu 10 € Sofort-Gutschrift.",
      "map.callout.bonus.lowFuel": "5€ Sofort-Bonus",
      "map.callout.bonus.lowFuel&out": "Bis zu 10€ Sofort-Bonus",
      "map.car.eligible.description": "[In Warteschlange] Bald in deiner Nähe verfügbar.",
      "map.car.inqueue.callout": "Melden sich mehr aus meiner Nähe an, stehe ich in Kürze zur Verfügung.",
      "map.car.release_ready.callout": "Dieses Fahrzeug ist leider aktuell nicht verfügbar. Bitte wähle einen anderen Zeitraum.",
      "map.car.release_ready.description": "[In Nutzung] Bitte probiere es später erneut.",
      "map.car.released.callout": "Verfügbar bis\n%@",
      "map.car.selected.callout": "Ich bin bald verfügbar. Du wirst mich in Kürze mieten können 🙌",
      "map.fillProfile.dialog.add": "Hinzufügen",
      "map.fillProfile.dialog.message": "Vervollständige jetzt deine Nutzerangaben (nur 2 Min.), um fortzufahren.",
      "map.fillProfile.dialog.no": "Jetzt nicht",
      "map.fillProfile.dialog.secure": "Kreditkartenüberprüfung",
      "map.fillProfile.dialog.support": "Registrierung abschließen",
      "map.fillProfile.dialog.title": "Fehlende Nutzerangaben",
      "map.fillProfile.dialog.yes": "OK",
      "map.filter.check": "Verfügbarkeit prüfen",
      "map.filter.description": "Gib den gewünschten Abholungs- und Rückgabezeitpunkt an.",
      "map.filter.findCar": "Fahrzeug finden",
      "map.filter.header": "Wann möchtest du ein Fahrzeug?",
      "map.filter.now": "Ab sofort",
      "map.filter.pickup": "Abholung",
      "map.filter.reset": "Zurücksetzen",
      "map.filter.return": "Rückgabe",
      "map.filter.secondary.text": "Zeitraum ändern",
      "map.filter.time.to": "bis",
      "map.filter.title": "Wann brauchst du ein Fahrzeug?",
      "map.marker.perHours": "€/Std.",
      "map.popup.firststeps": "%@ und erhalte deinen Willkommensbonus.",
      "map.popup.firststeps.url": "https://applink.get-a-way.com/user-challenges-firststeps",
      "map.popup.firststeps.url.text": "Schließe jetzt deine Anmeldung ab",
      "map.rented.callout": "Im gewählten Zeitraum nicht verfügbar.",
      "map.reservedCarHeader.settings.title": "Mieteinstellungen (Versicherung etc.)",
      "map.reservedCarHeader.title": "Das Auto ist reserviert!",
      "map.service.lastCleaning.new": "neu",
      "map.station.card.button": "Auswählen",
      "map.station.card.description": "Station",
      "map.station.card.headline": "Wähle eine Fahrzeugkategorie",
      "map.station.card.no.categories": "In dieser Station stehen im gewählten Zeitraum keine Fahrzeuge per App zur Verfügung.",
      "map.station.card.position": "Standort",
      "map.station.card.title": "Robben & Wientjes",
      "map.station.card.workinghrs": "Öffnungszeiten",
      "map.station.closed.text": "In dieser Station stehen im gewählten Zeitraum keine Fahrzeuge per App zur Verfügung.",
      "map.time": "Autos filtern",
      "map.time.select.apply": "Filtern",
      "map.time.select.title": "wie lange möchtest du einen GETAWAY?",
      "map.unavailable.callout": "Im gewählten Zeitraum nicht verfügbar. %s .",
      "map.unavailable.callout.link": "https://www.google.com/",
      "map.unavailable.callout.link.text": "Verfügbarkeit anzeigen",
      "map.usercar.BLOCKED.callout": "✋ Ich bin aktuell geblockt. Bitte kontaktiere das ServiceTeam.",
      "map.usercar.ELIGIBLE.callout": "💪 Ich habe alle Tests bestanden",
      "map.usercar.EQUIPPED.callout": "⚙ Ich bin ausgerüstet",
      "map.usercar.SELECTED.callout": "🎉 Ich kann es kaum erwarten, ausgerüstet zu werden!",
      "map.usercar.blocked.callout": "✋ Ich bin aktuell geblockt. Bitte kontaktiere das ServiceTeam.",
      "map.usercar.eligible.callout": "💪 Ich habe alle Tests bestanden",
      "map.usercar.equipment_ready.callout": "👨‍🔧 Ich warte auf den GETAWAY Techniker",
      "map.usercar.listed.callout": "💰 Ich könnte bis zu 400€ pro Monat verdienen!",
      "map.usercar.released.callout": "Freigegeben bis\n%@",
      "map.usercar.selected.callout": "🎉 Ich kann es kaum erwarten, ausgerüstet zu werden!",
      "map.usercar.service.callout": "🚗✨ Ich genieße gerade einen Service",
      "mileage": "Km-Stand",
      "minute": "Minute",
      "minute.short": "Min.",
      "minutes": "Minuten",
      "more": "Mehr",
      "multiplecars.add": "Jetzt neues Auto prüfen lassen",
      "multiplecars.footnote": "Tipps und Tricks, wie du mit deinem Auto Geld verdienst, erfährst du in unserem %@.",
      "multiplecars.footnote.url": "https://service.get-a-way.com/wie-vermiete-ich-mein-auto#geld-verdienen",
      "multiplecars.footnote.url.text": "HilfeCenter",
      "multiplecars.released.returntime": "bis",
      "multiplecars.rented.returntime": "bis spät.",
      "multiplecars.title": "MEINE AUTOS",
      "navigate": "Navigieren",
      "needsAccessKitCheck": "AccessKit prüfen",
      "needsBattery": "Niedriger Batteriestand",
      "needsCleaning": "Reinigung nötig",
      "needsFuel": "Kraftstoff nötig",
      "needsFuelCard": "Tankkarte nötig",
      "needsKeyFob": "KeyFob nötig",
      "needsOther": "Sonstiges nötig",
      "needsRelocation": "Verlegungsfahrt nötig",
      "needsRepair": "Reparatur nötig",
      "new.insurance.charterline.150": "150 €",
      "new.insurance.charterline.1500": "1500 €",
      "new.insurance.charterline.15000": "15.000 €",
      "new.insurance.charterline.151": "150 €",
      "new.insurance.charterline.500": "500 €",
      "new.insurance.charterline.501": "500 €",
      "new.insurance.charterline.650": "650 €",
      "new.insurance.charterline.750": "750 €",
      "new.insurance.charterline.description": "Max",
      "new.insurance.charterline.description.15000": "Bis zu",
      "no": "Nein",
      "no.geocode": "Rückgabezone anzeigen",
      "noBlockReason": "Kein Downtime-Grund verfügbar",
      "noRentals": "Keine Mietvorgänge vorhanden",
      "noServices": "Keine Aufträge",
      "nocars.invite": "EINLADEN",
      "nocars.screen.title": "Lade Freunde ein für mehr Autos in deiner Nähe!",
      "notNow": "Nicht jetzt",
      "notification.backby.desc": "Bitte denke an die rechtzeitige Rückgabe, andernfalls können Gebühren anfallen.",
      "notification.backby.hide": "HINWEIS VERBERGEN",
      "notification.backby.title": "Noch %s",
      "notification.backby.title.late": "Seit %s",
      "notification.booking.rentals": "Meine Mieten",
      "notification.booking.success": "Du hast deine Buchung erfolgreich durchgeführt. Du findest den Buchungsstatus in deinem Nutzerkonto.",
      "notification.email": "Per E-Mail",
      "notification.email.desc": "Erlaubnis, dich per E-Mail zu kontaktieren.",
      "notification.finishRental.complain": "😕 Ich möchte mich beschweren.",
      "notification.finishRental.description": "Deine Miete wurde beendet. Du erhältst eine Übersicht per E-Mail.",
      "notification.finishRental.title": "Vielen Dank, dass du GETAWAY nutzt!",
      "notification.mail": "Per Post",
      "notification.mail.desc": "Erlaubnis, dich per Post zu kontaktieren.",
      "notification.push": "Per App-Mitteilung",
      "notification.push.desc": "Erlaubnis, dich per App-Mitteilung zu kontaktieren.",
      "notification.rental.dongle.finish.description": "Sobald die Stationsmitarbeiter Schlüssel und Fahrzeug entgegengenommen haben, wird deine Miete beendet und dir eine Übersicht per E-Mail geschickt.",
      "notification.sms": "Per SMS",
      "notification.sms.desc": "Erlaubnis, dich per SMS zu kontaktieren.",
      "now": "Jetzt",
      "offline": "OFF",
      "offlineService.report.title": "Auftragsübersicht",
      "ok": "OK",
      "online": "ON",
      "open.directions.title": "Navigation starten",
      "opennow": "Aktuell geöffnet",
      "openstoday": "Heute geöffnet",
      "openstomorrow": "Morgen geöffnet",
      "parking.description": "Gratis für 120 Min. Jede weitere kostet nur 5 Ct",
      "parking.hours": "Stunden",
      "parking.minutes": "Minuten",
      "parking.title": "Parkmodus",
      "password.token.expired": "Dieser Zurücksetzen-Link ist abgelaufen. Bitte setze dein Passwort erneut zurück.",
      "payment.3ds.title": "Kreditkarte authentifizieren",
      "payment.add.description": "Zur Verifizierung deines Nutzerkontos. Es erfolgt keine Abbuchung. Die Anmeldung ist und bleibt gratis.",
      "payment.add.error": "Fehlgeschlagen. Bitte versuche es erneut oder kontaktiere unser Service Team.",
      "payment.card.add.description": "Kreditkarte hinzufügen und verifizieren",
      "payment.card.add.title": "Kreditkarte hinzufügen",
      "payment.card.add.verifying": "Verifizierung läuft...",
      "payment.card.code.label": "Prüfziffern (CVC)",
      "payment.card.expiration.label": "Gültigkeit (mm/jj)",
      "payment.card.name.label": "Karteninhaber",
      "payment.card.number.label": "Kartennummer",
      "payment.footnote": "Ich stimme einer etwaigen %s zur Zahlungswahrscheinlichkeit für die Auswahl der Zahlarten zu.",
      "payment.footnote.url": "https://getaway-docs.s3.eu-central-1.amazonaws.com/de-DE/Getaway_DataPrivacy-SchufaChecks-de.pdf",
      "payment.footnote.url.text": "Datenprüfung",
      "payment.postingText": "Dankt.",
      "payment.postingTextReference": "%s",
      "payment.secured.success": "Authentifizierung erfolgreich bestanden, danke!",
      "payment.sepa.description": "SEPA-Lastschrift",
      "paypal.desc": "Füge eine unserer sicheren und beliebten Zahlungsmittel hinzu.",
      "paypal.placeholder": "Paypal-Konto",
      "paypal.title": "Paypal hinzufügen",
      "per.calday": "/Kal.Tag",
      "per.km": "je km",
      "permission.settings.button": "ERLAUBEN",
      "phone.pairing.message": "Klicke hier, um dieses Handy zu verifizieren.",
      "phone.resend.code": "CODE ERNEUT SENDEN",
      "phone.verification.resend.success": "Bestätigungscode ist erneut versendet worden.",
      "phone.verify.code.apply": "CODE ÜBERNEHMEN",
      "phone.verify.code.desc": "Wir haben dir soeben einen 4-stelligen Code per SMS an %s geschickt. Bitte trage ihn jetzt ein.",
      "phone.verify.code.title": "Trage deinen Bestätigungscode ein",
      "phone.verify.desc": "Bitte trage deine Handy-Nummer ein. Wir senden dir im Anschluss eine SMS mit einem Bestätigungscode.",
      "phone.verify.mobile.phone.text": "Handy-Nummer",
      "phone.verify.send.button": "Bestätigen",
      "phone.verify.send.code": "SMS-Code anfordern",
      "phone.verify.sms": "{{code}} lautet dein GETAWAY Bestätigungscode.",
      "phone.verify.title": "Handy-Nummer hinzufügen",
      "phone.wrong.format.error": "Bitte gebe die richtige Telefonnummer ein",
      "phoneNumberVerification.authy.custom_message": "{{code}} lautet dein GETAWAY Bestätigungscode.",
      "phonenumber.verification.error": "Diese Handy-Nummer kann nicht bestätigt werden. Bitte nutze eine andere oder kontaktiere unser Service Team.",
      "photo": "Foto",
      "photo.continue": "FORTFAHREN",
      "photo.library": "Fotos",
      "photo.source.dialog.title": "Fotos hochladen via",
      "photos": "Fotos",
      "photos.add": "Fotos vom Auto",
      "photos.add.button": "Foto hinzufügen",
      "photos.add.description": "Von Vorne, von der Seite und vom Innenraum. Je ansprechender, desto mehr Mieteinnahmen 🤩",
      "photos.desc": "Ein Foto sagt mehr als Tausend Wort. Füge jetzt mindestens eins hinzu.",
      "photos.permission": "Bitte erlaube GETAWAY Zugriff auf deine Fotos in deinen Einstellungen.",
      "photos.permission.details": "Um fortzufahren und Fotos hochzuladen, erlaube GETAWAY jetzt Zugang zu deinen Fotos.",
      "photos.permission.no": "Nicht jetzt",
      "photos.permission.title": "Zugang zu Fotos",
      "photos.permission.yes": "Erlauben",
      "photos.photo": "Foto",
      "photos.photo.reached.max": "Du hast die Maximalanzahl an Fotos erreicht.",
      "photos.skip.no.photos": "Du kannst Fotos jederzeit ergänzen.",
      "photos.skip.photos": "Du hast mindestens ein Foto hochgeladen.",
      "picker.placeholder": "Wähle aus ...",
      "price": "Preis",
      "price.details.footnote": "*Fahranfänger ist, wer seine Fahrerlaubnis vor weniger als 24 Monaten erhalten hat. Die vollständige Aufstellung aller Entgelte sind der %s zu entnehmen.",
      "price.details.footnote.url.text": "aktuellen Preisliste",
      "price.distance.100.prefix": "bis 100 km:",
      "price.distance.101.prefix": "ab 101. km:",
      "price.distance.headline": "Fahrtstrecke",
      "price.duration.headline": "Mietdauer",
      "price.duration.headline.mo-fr": "Mietdauer (Mo-Fri)",
      "price.duration.headline.mo-sa": "Mietdauer",
      "price.duration.headline.sa-mo": "Sa, 18:00 - Mo, 08:00",
      "price.duration.headline.sa-so": "Mietdauer (Sa-So)",
      "price.duration.holiday": "Feiertags: %s/Kal.Tag (inkl. 100km)",
      "price.duration.holiday.headline": "Feiertag",
      "price.duration.mo-fr.night.prefix": "21 - 9:30 Uhr:  ",
      "price.duration.mo-fr.prefix": "9:30 - 21 Uhr: %s",
      "price.duration.mo-sa.daily": "Mo-Sa, 8-18 Uhr: %s/Kal.Tag (inkl. 100 km)        ",
      "price.duration.mo-sa.hourly": "Mo-Sa 8 - 20: %s/Std (inkl. 20 km/Std), max. %s/Kal.Tag (inkl. 100 km)",
      "price.duration.mo-sa.night": "Mo-Sa, außerhalb der Öffnungszeiten: %s (inkl. 100km)",
      "price.duration.sa-mo": "Sa, 18 Uhr - Mo, 8 Uhr: %s (inkl. 150 km)",
      "price.duration.sa-so.night.prefix": "Sa, 18 - Mo, 9:30 Uhr:  ",
      "price.duration.sa-so.prefix": "Sa, 9:30 - 18 Uhr:  %s  (1. Std. gratis)",
      "price.insurance.150.prefix": "max. 150€:",
      "price.insurance.1500.prefix": "max. 1.500€: Inkl.",
      "price.insurance.15000.prefix": "max. 15.000€:  inkl.",
      "price.insurance.151.prefix": "max. 150€:",
      "price.insurance.500.prefix": "max. 500€:",
      "price.insurance.501.prefix": "max. 500€:",
      "price.insurance.650.prefix": "max. 650€:  inkl.",
      "price.insurance.750.prefix": "max. 750€:  inkl.",
      "price.insurance.headline": "Selbstbeteiligung (je Schadensfall)",
      "price.insurance.young.headline": "Selbstbeteiligung (Fahranfänger*)",
      "price.return.service.headline": "Rückhol-Service (außerh. Rückgabezone)",
      "price.tank.10.prefix": "Rückgabe <10%:",
      "price.tank.25.prefix": "Rückgabe <25%: ",
      "price.tank.headline": "Tank-Service",
      "price.weekend.surcharge.prefix": "Wochenendzuschlag (Fr-So):",
      "pricedetails.basic.title": "Zahle nur, was du verfährst",
      "pricedetails.distancePackages.remaining": "Ab %@. Kilometer",
      "pricedetails.example.day": "Ein Tag (24 Std.)",
      "pricedetails.example.description": "inkl. %s km* und Kraftstoff",
      "pricedetails.example.hour": "Eine Stunde",
      "pricedetails.example.kilometer": "Ein Kilometer",
      "pricedetails.example.kilometer.description": "Inkl. Kraftstoff",
      "pricedetails.example.month": "Ein Monat (28 Tage)",
      "pricedetails.example.title": "Preisbeispiele (inkl. Kraftstoff & Versicherung)",
      "pricedetails.example.value.description": "entspricht %s je Min.",
      "pricedetails.example.value.description.day": "Ø %s €/Kal.Tag",
      "pricedetails.example.value.description.owner": "du verdienst %s",
      "pricedetails.example.week": "Eine Woche",
      "pricedetails.longterm.description.oneday": "inkl. 80 km je 24 Std.*",
      "pricedetails.longterm.footnote": "*Jeder weitere Kilometer bzw. weitere Standzeit wird nutzungsgenau abgerechnet.\n\nDir wird automatisch das günstigste Angebot berechnet. Eine Übersicht aller Entgelte findest du %@.",
      "pricedetails.longterm.footnote.url": "https://get-a-way.com/agb/entgelte-und-gebuehren/",
      "pricedetails.longterm.footnote.url.text": "hier",
      "pricedetails.longterm.tier1.description": "inkl. 80 km* und Kraftstoff",
      "pricedetails.longterm.tier1.title": "Ab dem 1. Tag",
      "pricedetails.longterm.tier1.value.description": "je 24 Std.",
      "pricedetails.longterm.tier2.description": "inkl. 40 km* und Kraftstoff",
      "pricedetails.longterm.tier2.title": "Ab dem 4. Tag",
      "pricedetails.longterm.tier2.value.description": "je 24 Std.",
      "pricedetails.longterm.tier3.description": "inkl. 20 km* und Kraftstoff",
      "pricedetails.longterm.tier3.title": "Ab dem 8. Tag",
      "pricedetails.longterm.tier3.value.description": "je 24 Std.",
      "pricedetails.longterm.title": "Ab %s.",
      "pricedetails.longterm.value.description": "je %s",
      "pricedetails.parking.freeminutes": "%s Min. gratis je km",
      "pricedetails.parking.title": "Standzeit",
      "pricedetails.parking.value": "danach %s je Min.",
      "pricedetails.pricePerKm.title": "Gefahrene Strecke",
      "pricedetails.pricePerKm.value": "%s je km",
      "pricedetails.specialoffers.title": "TAGESRABATTE",
      "pricedetails.title": "Preisinfo",
      "pricing.CharterlinePricing.url": "https://api.get-a-way.com/pricing/5d170e35f892b13d8468f283/pdf",
      "pricing.HagebauMarktPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/58944_Preisliste+Ubeeqo_Hagebaumarkt_A4_v02.pdf",
      "pricing.HeyOBIPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf",
      "pricing.IkeaInnsbruck.duration.hourly": "9:30 Uhr - 21:00 Uhr: %s/Std",
      "pricing.IkeaInnsbruck.duration.night": "21:00 Uhr - 9:30 Uhr: %s",
      "pricing.IkeaInnsbruck.duration.weekday": "Mietdauer (Mo-Fri)",
      "pricing.IkeaInnsbruck.duration.weekend": "Mietdauer (Sa-So)",
      "pricing.IkeaInnsbruck.duration.weekend.sat": "Sa, 9:30 Uhr - 18:00 Uhr : %s",
      "pricing.IkeaInnsbruck.duration.weekend.so": "Sa, 18 - Mo, 9:30 Uhr: %s",
      "pricing.IkeaInnsbruckPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA-UBQ-IKEA_v211101_Preisliste_Ikea_11-2021.pdf",
      "pricing.IkeaMainPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA-UBQ-IKEA_v211101_Preisliste_Ikea_11-2021.pdf",
      "pricing.MISSmartPricing.url": "https://api.get-a-way.com/pricing/61cb2b425071913188afebcf/pdf",
      "pricing.MISSmartSpainPricing.url": "https://api.get-a-way.com/pricing/61cb2b425071913188afebcf/pdf",
      "pricing.MISTransporterPricing.url": "https://api.get-a-way.com/pricing/61cb2b425071913188afebcf/pdf",
      "pricing.OBIEmployeesPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_OBI_Employees.pdf",
      "pricing.OBIPublicUsersPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf",
      "pricing.OBIUsersPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf",
      "pricing.charterline.distance.100": "bis 100km: %s/km",
      "pricing.charterline.distance.101": "ab dem 101. km: %s/km",
      "pricing.charterline.duration.hourly": "%s/Std (max. %s/Kal.Tag)",
      "pricing.charterline.duration.weekend": "Fr-So: +1,00 €/Std (max. 12,00 €/Kal.Tag)",
      "pricing.degressive.distance": "${format:euro(price.driversPricePerKm)}/km (ab dem ${price.firstDiscountedKm}. km nur ${format:euro(price.firstDiscountedPrice)})",
      "pricing.degressive.parking": "${price.freeParkingMinutesPerKm} Freiminuten/km, danach ${format:cents(price.pricePerParkingMinute)} Ct/Min.",
      "pricing.distance": "Fahrtstrecke",
      "pricing.distance.details": "%s/km",
      "pricing.distance.excess": "Fahrtstrecke (je Mehrkilometer)",
      "pricing.duration": "Mietdauer",
      "pricing.duration.hourly": "%s/Std",
      "pricing.expert.distance": "%s/km",
      "pricing.expert.duration": "%s/Std",
      "pricing.fee.driver.injury.protection": "Insassenschutz: %s",
      "pricing.fee.driver.protection": "Zusatzleistungen",
      "pricing.fee.return": "Rückbring-Service (außerhalb der Rückgabezone)",
      "pricing.fee.return.details": "%s + %s/100m",
      "pricing.fee.tank": "Tankservice",
      "pricing.fee.tank.low.10": "Rückgabe <10%:",
      "pricing.fee.tank.low.25": "Rückgabe <25%:",
      "pricing.heyOBI.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf",
      "pricing.insurance": "Selbstbeteiligung (je Schadensfall)",
      "pricing.insurance.table": "max. %s€: %s€/Kal.Tag",
      "pricing.insurance.table.daily": "max. %s€: %s€/24Std",
      "pricing.insurance.table.free": "max. %s€: inklusive",
      "pricing.insurance.young": "Selbstbehalt (Young Driver)",
      "pricing.longterm.distance": "${format:euro(price.driversPricePerKm)}/km",
      "pricing.longterm.parking": "${price.freeParkingMinutesPerKm} Freiminuten/km, danach ${format:cents(price.pricePerParkingMinute)} Ct/Min.",
      "pricing.longtermanddistance.distance": "${format:euro(price.driversPricePerKm)}/km (ab dem ${price.firstDiscountedKm + 1}. km nur ${format:euro(price.firstDiscountedPrice)})",
      "pricing.longtermanddistance.parking": "${price.freeParkingMinutesPerKm} Freiminuten/km, danach ${format:cents(price.pricePerParkingMinute)} Ct/Min.",
      "pricing.mis.distance": "inklusive 50 km/24Std. (%s/Mehr-Km)",
      "pricing.mis.duration.after.30": "Mietdauer (ab 30 Tage)",
      "pricing.mis.duration.before.30": "Mietdauer (bis 30 Tage)",
      "pricing.mis.duration.daily": "Tag 1 bis 3:  %s/24Std.",
      "pricing.mis.duration.daily.plus3": "Ab dem 4. Tag: %s/24Std.",
      "pricing.mis.duration.daily.plus30": "%s/24Std.",
      "pricing.mis.duration.transporter.hourly": "ab der 4. Stunde:  %s/Std. (max. %s/24Std.)",
      "pricing.mis.duration.transporter.hourly.lt3h": "bis zu 3 Stunden: %s",
      "pricing.mis.spain.duration.daily": "%s/24Std",
      "pricing.mis.transporter.distance": "inklusive 50 km/24Std. (%s/Mehr-Km)",
      "pricing.obi.distance": "%s/ Mehr-Km",
      "pricing.obi.duration.holiday": "An Feiertagen: %s (inkl. 100km)",
      "pricing.obi.duration.hourly": "Mo-Sa, 8-20 Uhr: %s/Std (inkl. 20 km/Std), max. %s/Kal.Tag (inkl. 100 km)",
      "pricing.obi.duration.night": "Mo-Sa, 20 Uhr - 8 Uhr: %s (inkl. 100km)",
      "pricing.obi.duration.weekend": "Sa 20:00 Uhr - Mo 08:00 Uhr: %s (inkl. 150km)",
      "pricing.obiEmployees.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_OBI_Employees.pdf",
      "pricing.obiPublicUsers.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf",
      "pricing.obiUsers.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf",
      "pricing.serviceTrip.distance": ".",
      "pricing.serviceTrip.parking": ".",
      "pricing.static.distance": "${price.driversPricePerKm}/km",
      "pricing.static.parking": "${price.freeParkingMinutes} Freiminuten/km, danach ${format:cents(price.pricePerParkingMinute)} Ct/Min.",
      "pricing.title": "Preisangaben",
      "privacy.text": "DEINE DATEN SIND PRIVAT & GESCHÜTZT",
      "privacy.url": "https://get-a-way.com/datenschutz?utm_source=app",
      "push.permission": "Bitte aktiviere jetzt App-Mitteilungen",
      "push.permission.details": "Erlaube GETAWAY dir App-Mitteilungen zu senden, um dich über den aktuellen Status deines Autos bzw. deiner Miete informieren zu können.",
      "push.permission.no": "Später",
      "push.permission.title": "Erlaube Mitteilungen",
      "push.permission.yes": "Erlauben",
      "qrCamera.title": "QR-Code scannen",
      "referFriend.code.error": "Bitte trage einen gültigen Einladungscode ein",
      "refuel": "Betankung",
      "refuel.payment.subTitle": "Bezahlung",
      "refuel.payment.title": "Kreditkarten PIN",
      "refuel.subTitle": "Bitte nimm die GETAWAY-Tankkarte aus dem Handschuhfach und nutze die angezeigte PIN. Die Quittung brauchst du nicht aufzuheben.",
      "refuel.title": "Tanke gratis",
      "rent.car.driver.gear.restricted": "Du kannst dieses Auto nicht mieten, da deine Fahrerlaubnis nur für Automatikgetriebe gilt.",
      "rent.car.driver.license.baned": "Du hast ein Fahrverbot",
      "rent.car.driver.license.open": "Deine Fahrerlaubnis ist nicht geprüft",
      "rental": "Miete",
      "rental.action.damages": "Schaden melden",
      "rental.action.finish": "Miete beenden",
      "rental.action.fuel": "Tanken",
      "rental.add.damage.wizard.description": "Protokolliere alle aktuellen Mängel, um eventuelle Neuschäden besser identifizieren zu können.",
      "rental.cancel": "Abbrechen",
      "rental.cancel.handover.ok": "Mietvorgang beenden",
      "rental.cancel.handover.title": "Du bist dabei, den aktuellen Vorgang abzubrechen.",
      "rental.carCheck.button": "Fortfahren",
      "rental.carCheck.cancel": "Beenden",
      "rental.carCheck.footnote": "Dies schützt dich im Schadensfalle vor zu hohen Kosten.",
      "rental.carCheck.header.desc": "Nimm jetzt je ein Foto aus vier verschiedenen Perspektiven auf, um den Motor starten zu können.",
      "rental.carCheck.header.title": "Rundum-Check",
      "rental.checkBelongings.finish.message": "Bitte achte auf deine persönlichen Sachen, bevor du fortfährst.",
      "rental.checkBelongings.finish.messege": "Bitte achte auf deine persönlichen Sachen, bevor du fortfährst.",
      "rental.checkBelongings.finish.title": "Nichts vergessen?",
      "rental.damage.added": "Der Schaden/Mangel ist um %@ erfolgreich protokolliert worden.",
      "rental.dialog.service.error": "Bitte wähle den durchgeführten Service und mindestens ein Foto aus.",
      "rental.dongle.end.title": "Miete beenden",
      "rental.dongle.handover.description": "Bitte gehe nun die Checkliste durch, um den Mietvorgang zu beenden",
      "rental.dongle.key.return.description": "Bitte übergebe Fahrzeugschlüssel und -papiere (inkl. der Tankkarte) am Ende deiner Miete einem Stationsmitarbeiter am Express-Schalter. Alternativ wirf diese in den entsprechend ausgeschilderten Nachteinwurf der Station.",
      "rental.dongle.key.return.title": "Schlüssel & Papiere zurückgeben",
      "rental.driving.backBy": "Rückgabezeit",
      "rental.driving.damages.add": "Melden",
      "rental.driving.greeting": "Gute Fahrt!",
      "rental.extra.F3023.title": "Insassenschutz: 10,00 €",
      "rental.finish.button": "Miete beenden",
      "rental.finish.success": "Dein Mietvorgang ist erfolgreich beendet worden. Habe einen schönen Tag!",
      "rental.fuelScreen.title": "Bargeldlos Tanken",
      "rental.fuelSettings.desc": "Tanke bequem & bargeldlos mit der Tankkarte aus dem Handschuhfach an allen Partnertankstellen.",
      "rental.fuelSettings.fuelCard": "Tankkarte: ",
      "rental.fuelSettings.fuelNote": "Keine Premium-Kraftstoffe tanken. Andernfalls können Entgelte fällig werden.",
      "rental.fuelSettings.fuelPin": "PIN:  ",
      "rental.fuelSettings.gasStation.desc": "Bitte vergewissere dich vor dem Tankvorgang, dass die Tankstelle die Tankkarte akzeptiert.",
      "rental.fuelSettings.instruction": "Die Quittung muss nicht aufbewahrt werden.\nIm Anschluss lege bitte die Tankkarte in die dafür vorgesehene Halterung im Handschuhfach zurück.",
      "rental.fuelSettings.navigate": "Karte",
      "rental.fuelSettings.stepOne": "1. Partnertankstelle anfahren",
      "rental.fuelSettings.stepThree": "3. Mit Tankkarte bezahlen",
      "rental.fuelSettings.stepTwo": "2. Kraftstoff tanken (keine Premiumsorte)",
      "rental.fuelSettings.title": "Kostenlos Tanken",
      "rental.fuelSettings.where": "Wo?",
      "rental.fuelstation": "Tankestelle",
      "rental.handover.description": "Alles in Ordnung? Dann verriegele das Fahrzeug von außen, um die Miete zu beenden.",
      "rental.handover.header": "Danke,",
      "rental.handover.title": "Miete beenden",
      "rental.history.header": "Miete",
      "rental.history.invoice": "Rechnung zur Miete anzeigen",
      "rental.insurance.change.error": "Für diese Fahrt ist keine Versicherungsoption verfügbar.",
      "rental.insurance.dialog.button": "VERSICHERUNG ANRUFEN",
      "rental.insurance.dialog.description": "Für unmittelbare Hilfe rufe jetzt bitte unseren Versicherungspartner \"Gothaer Versicherung\" an und beziehe dich auf folgende Versicherungsschein-Nummer:",
      "rental.insurance.dialog.footer": "24/7 Schadenservicecenter unter der Festnetznummer +49 30 5508 81508",
      "rental.insurance.dialog.number": "02.925.777 777",
      "rental.insurance.dialog.number.description": "Versicherungsschein-Nr",
      "rental.insurance.dialog.phone.number": "+4930550881508",
      "rental.insurance.dialog.title": "Soforthilfe",
      "rental.insurance.gothaer.dialog.description": "Für unmittelbare Hilfe rufe jetzt bitte unseren Versicherungspartner \"Gothaer Versicherung\" an und beziehe dich auf folgende Versicherungsschein-Nummer:",
      "rental.insurance.gothaer.dialog.footer": "24/7 Schadenservicecenter unter der Festnetznummer +49 30 5508 81508",
      "rental.insurance.label": "Selbstbeteiligung",
      "rental.insurance.settings.footnote": "Im Schadensfall kann es schnell teuer werden. Begrenze dein Haftungsrisiko und wähle eine %s",
      "rental.insurance.settings.more.url.text": "Selbstbeteiligung",
      "rental.insurances.info.url": "https://getaway.app/insurance",
      "rental.internet.disconnected.message": "Es kann keine Verbindung hergestellt werden. Bitte stelle sicher, dass du online bist. Bei Schwierigkeiten kontaktiere bitte unser Service Team.",
      "rental.internet.disconnected.title": "Keine Internetverbindung",
      "rental.keyOut.button.title": "Ich habe den Autoschlüssel",
      "rental.opened.damages.title": "Schadenprotokoll",
      "rental.opened.dongle.continue": "Weiter",
      "rental.opened.header.desc": "Wenn das Fahrzeug keine neuen Schäden aufweist, dann starte jetzt den Motor.",
      "rental.opened.header.title": "Starte den Motor",
      "rental.opened.instruction": "Bitte den Schlüssel der Halterung entnehmen.",
      "rental.opened.key.option": "Entnehme den Schlüssel",
      "rental.opened.video.description": "Die Schlüsselhalterung befindet sich im Handschuhfach.",
      "rental.opened.video.title": "Entnehme den Schlüssel",
      "rental.parking.findCar": "Finde mein Auto",
      "rental.prompty.end": "Miete beenden",
      "rental.rate.negative": "NEIN",
      "rental.rate.positive": "JA",
      "rental.rate.title": "Hat dir das Auto gefallen?",
      "rental.reserved.desc.connected": "Mit dem Entriegeln startest du den kostenpflichtigen Mietvorgang.",
      "rental.reserved.desc.disabled": "Aktiviere Bluetooth, um das Fahrzeug mit deinem Handy zu entriegeln.",
      "rental.reserved.desc.searching": "Gehe jetzt zum Fahrzeug, um es mit deinem Handy zu entriegeln.",
      "rental.reserved.header.connected": "Miete starten",
      "rental.reserved.header.disabled": "Aktiviere Bluetooth",
      "rental.reserved.header.searching": "Verringere die Distanz ",
      "rental.returnlocation": "Rückgabeort",
      "rental.returntime.late": "%@ zu spät",
      "rental.review.negative.notification": "Vielen Dank für dein Feedback...",
      "rental.section.booked": "Gebucht",
      "rental.section.locked": "Verriegelt",
      "rental.section.reserved": "Reserviert",
      "rental.section.unlocked": "Entriegelt",
      "rental.service.note": "Zusätzlicher Hinweis (optional)",
      "rental.service.report.button": "Servicebericht absenden",
      "rental.service.report.submit.success": "Dein Bericht wurde übermittelt",
      "rental.service.report.title": "Servicebericht",
      "rental.settings.insurance.more": "Mehr Infos zur Selbstbeteiligung",
      "rental.settings.insurance.title": "Reduziere deine Selbstbeteiligung!",
      "rental.settings.title": "Mieteinstellung",
      "rental.startEngine.decr": "Sofern das Auto keine neuen Schäden (siehe Schadensprotokoll) aufweist, starte bitte jetzt den Motor. Hab eine sichere Fahrt!",
      "rental.startEngine.title": "Starte jetzt den Motor!",
      "rental.status": "Status",
      "rental.title": "Miete %s",
      "rental.walkthrough.button": "Ok",
      "rental.walkthrough.page1.description": "Im Schadensfall kann es schnell teuer werden. Wähle deine gewünschte Haftungsbegrenzung (Selbstbeteiligung) bevor du das Fahrzeug mit deinem Handy entriegelst.",
      "rental.walkthrough.page1.title": "Reduziere deine Selbstbeteiligung",
      "rental.walkthrough.page2.description": "Bringe das Fahrzeug in die farblich hervorgehobene Rückgabezone mit einem Tankstand von mind. 25% zurück und verriegele es mit deinem Handy.\n\nNeu: Möchtest du, dass wir das Fahrzeug zurückzubringen? Dann wähle am Ende der Miete einfach den bequemen Rückbring-Service iHv. nur 10€ + 0,49€ je 100m.",
      "rental.walkthrough.page2.title": "Zurückbringen und Miete beenden",
      "reserve.btn.unavailable": "Aktuell in Miete",
      "reset": "Zurücksetzen",
      "returncar.apply": "JETZT FREIGEBEN",
      "returncar.cancel": "Auto jetzt nicht freigeben",
      "returncar.title": ", wann möchtest du dein Auto wieder?",
      "review": "Bewertung",
      "review.dialog.later": "Nicht jetzt 😒",
      "review.dialog.message": "Wenn dir unsere App gefällt, schreibe uns bitte eine Bewertung.\n\nDamit hilfst du, mehr Nutzer für nachhaltige Mobilität zu begeistern 🙏",
      "review.dialog.no": "Feedback",
      "review.dialog.title": "⭐⭐⭐⭐⭐",
      "review.dialog.yes": "Ja, gefällt mir 😃",
      "route.dialog.applemaps": "Apple Maps",
      "route.dialog.copy": "Adresse kopieren",
      "route.dialog.googlemaps": "Google Maps",
      "salutation": "Anrede",
      "save": "Speichern",
      "schufa.address": "ADRESSE",
      "schufa.button.title": "Jetzt freischalten",
      "schufa.dateOfBirth.dateFormat": "Tag / Monat / Jahr",
      "schufa.dateOfBirth.label": "Geburtsdatum",
      "schufa.instruction": "Um diese Zahlungsmethode freizuschalten, gib bitte folgende Infos zur Prüfung an.",
      "schufa.notification.message": "😥 Unser Zahlungsmittelanbieter kann leider kein SEPA-Mandat für dich einrichten. Bitte wähle eine alternative Zahlungsmethode. Wir arbeiten bereits an weiteren Alternativen.",
      "schufa.notification.title": "Zahlungsmethode freischalten",
      "schufa.personalInfo": "PERSÖNLICHE INFORMATIONEN",
      "schufa.title": "Zahlungsart freischalten",
      "schufaCheck.dialog.message": "😥 Unser Zahlungsmittelanbieter kann leider kein SEPA-Mandat für dich einrichten. Bitte wähle eine alternative Zahlungsmethode. Wir arbeiten bereits an weiteren Alternativen.",
      "schufaCheck.dialog.title": "Zahlungsmittel-Freigabe",
      "schufaCheck.dialog.yes": "OK",
      "seconds.short": "Sek.",
      "selectPayment.desr": "Hinterlege dein gewünschtes Zahlungsmittel, um deine Registrierung abzuschließen. ",
      "selectPayment.sepaCard.text": "Lastschrift hinzufügen",
      "selectPayment.title": "Zahlungsmittel hinzufügen",
      "selectPayment.visaCard.text": "Visa oder MasterCard hinzufügen",
      "selfie.add.button": "Video (3 Sekunden) aufnehmen",
      "service.button.text": "Auftrag annehmen",
      "service.map.filter.apply": "Auf Karte anzeigen",
      "service.report.accomplished": "Ich habe Services durchgeführt",
      "service.report.image.note": "Auf mindestens einem Foto muss das Kennzeichen zu erkennen sein.",
      "service.report.noService.note": "Zusätzliche Notizen",
      "service.reserved.services.title": "Angefragte Aufträge",
      "service.start.button": "Auftrag starten",
      "service.started.finish.button": "Auftrag abschließen",
      "service.started.services.title": "Angefragte Aufträge",
      "service.tab.all": "Alle",
      "service.tab.thisWeek": "Woche",
      "service.tab.thisweek": "Woche",
      "service.tab.today": "Heute",
      "service.tab.tomorrow": "Morgen",
      "service.title": "Service-Fahrt",
      "service.type.basicBranding": "Autosticker",
      "service.type.basicCarPhotos": "Fotos für Autoprofil",
      "service.type.basicDamageRecords": "Schadensakte",
      "service.type.batteryChangeOpening": "Batteriewechsel (mit Notfallöffnung)",
      "service.type.batteryChangeSimple": "Batteriewechsel (einfach)",
      "service.type.batteryCharge": "Batterie laden",
      "service.type.cleanOutsideDry": "Außenreinigung (trocken)",
      "service.type.comboPremium": "comboPremium",
      "service.type.comboPro": "Paket \"Komplettpflege\"",
      "service.type.comboRefuelWashInside": "Paket \"Tanken & Innenreinigung\"",
      "service.type.comboRefuelWashOutside": "Package \"Refuel & Clean outside\"",
      "service.type.comboRefuelWashTotal": "Package \"Refuel & Total Clean\"",
      "service.type.dashboardCleaning": "Lüftungsschlitze und Ablagen intensiv reinigen",
      "service.type.desinfektion": "Desinfektion",
      "service.type.exchangeFuelCard": "Tankkarte wechseln",
      "service.type.extra10min": "Sonderreinigungszeit, je 10 Min",
      "service.type.grillCleaning": "Kühlergrill-Reinigung",
      "service.type.highGlossFinish": "Hochglanz-Finish mit Lackversiegelung",
      "service.type.noService": "Ich habe keinen Service durchgeführt",
      "service.type.otherBasicService": "Sonstiger Service",
      "service.type.refuel": "Betankung",
      "service.type.relocate": "Verlegungsfahrt",
      "service.type.relocation": "Verlegungsfahrt",
      "service.type.rimsCleaning": "Felgen-Intensivpflege",
      "service.type.safetyCheck": "Safety-Check",
      "service.type.tirePressure": "Reifendruck",
      "service.type.vacuumCleaning": "Kofferraum und Fußmatten intensiv saugen",
      "service.type.washInside": "Innenreinigung",
      "service.type.washOutside": "Außenreinigung (nass)",
      "service.type.windshieldFluidRefill": "Auffüllen des Scheibenwischwassers",
      "services.item.subTitle": "Wasch-Mein-Auto Serviceauftrag",
      "services.map.filter.apply": "Auf Karte anzeigen",
      "settings": "Einstellungen",
      "settings.addPaymentMethod.payIn": "Vorkasse freischalten",
      "settings.addPaymentMethod.sepa": "Lastschrift hinzufügen",
      "settings.addPaymentMethod.sepa.disabled": "Aktuell nicht verfügbar",
      "settings.addPaymentMethod.visa": "Kreditkarte hinzufügen",
      "settings.address.footnote": "Mit dem Bearbeiten meiner Adressangaben stimme ich einer etwaigen %s zur Identitätsprüfung zu.",
      "settings.address.footnote.url": "https://getaway-docs.s3.eu-central-1.amazonaws.com/de-DE/Getaway_DataPrivacy-SchufaChecks-de.pdf",
      "settings.address.footnote.url.text": "Datenprüfung",
      "settings.circles.data": "EXKLUSIVE NUTZERKREISE",
      "settings.circles.data.add": "Nutzerkreis beitreten",
      "settings.email.update.success": "Danke, deine E-Mail-Adresse ist aktualisiert worden.",
      "settings.login.data": "ZUGANGSDATEN",
      "settings.login.data.driverslicense": "FAHRERLAUBNIS",
      "settings.login.data.email.edit": "Tippe, um deine E-Mail-Adresse zu ändern",
      "settings.login.data.email.facebook": "Verbinde deinen Facebook-Account",
      "settings.login.data.facebook.dest": "Verbinde deinen Facebook-Account und nutze der 1-Klick Login. Wir posten nichts auf Facebook ohne deinen ausdrücklichen Wunsch!",
      "settings.login.data.moreactions": "WEITERE EINSTELLUNGEN",
      "settings.login.data.notifications": "Benachrichtigungen",
      "settings.login.data.notifications.change": "Verwalte deine Benachrichtigungen",
      "settings.login.data.password": "Passwort zurücksetzen",
      "settings.login.data.password.edit": "Tippe, um dein Passwort zu ändern",
      "settings.login.data.terminate": "Konto entfernen",
      "settings.login.data.touchId": "Touch/Face ID",
      "settings.login.data.touchId.edit": "Login mit Touch/Face ID",
      "settings.login.data.user.add.address.text": "Meldeadresse hinzufügen",
      "settings.login.data.user.add.phone.text": "Handy-Nummer hinzufügen",
      "settings.login.data.user.address.disabled.text": "Für Änderungen kontaktiere bitte unser Service Team",
      "settings.login.data.user.address.text": "Tippe, um deine Adresse zu ändern",
      "settings.login.data.user.info.text": "Für Änderungen kontaktiere bitte unser Service Team",
      "settings.login.data.user.phone.text": "Tippe, um deine Handy-Nummer zu ändern",
      "settings.logout": "Abmelden",
      "settings.notification": "Benachrichtigung",
      "settings.payIn.open": "Diese Zahlungsart kann nicht genutzt werden, bis die Identitätsprüfung abgeschlossen ist. Bitte kontaktiere unseren Support.",
      "settings.payIn.status.open.message": "Bitte füge deinen Personalausweis hinzu, um Zahlung per Vorkasse nutzen zu können.",
      "settings.payment.data": "ZAHLUNGSMITTEL",
      "settings.payment.data.add": "Zahlungsmittel hinzufügen",
      "settings.payment.footnote": "Mit dem Hinzufügen bzw. Bearbeiten eines Zahlungsprofils stimme ich einer etwaigen %s zur Zahlungswahrscheinlichkeit für die Auswahl der Zahlarten zu.",
      "settings.payment.footnote.url": "https://getaway-docs.s3.eu-central-1.amazonaws.com/de-DE/Getaway_DataPrivacy-SchufaChecks-de.pdf",
      "settings.payment.footnote.url.text": "Datenprüfung",
      "settings.payment.payIn.title": "Zahlung per Vorkasse",
      "settings.payment.sepa.title": "Lastschrift hinzufügen",
      "settings.payment.sepa.url": "https://google.com",
      "settings.permission": "Berechtigung erforderlich",
      "settings.personal.data": "PERSÖNLICHE DATEN",
      "settings.terminate.info": "Bitte kontaktiere unser Service Team, um dein Konto löschen zu lassen.",
      "settings.terminate.no": "Abbrechen",
      "settings.terminate.yes": "Kontakt",
      "settings.title": "EINSTELLUNGEN",
      "settings.upload.camera": "Foto aufnehmen",
      "settings.upload.file": "Datei auswählen",
      "settings.upload.title": "Dokument zur Prüfung hochladen",
      "settings.uploadDoc": "Dokument hochladen",
      "share.car.message": "Schau dir dieses Auto bei GETAWAY an: %@ %@, ab %@/km bzw. %@/Tag inkl. Versicherung und Benzin https://applink.get-a-way.com/map?id=%@",
      "share.car.subject": "Ich habe ein Auto für dich gefunden",
      "share.panel.description": "Teile deinen Einladungslink",
      "share.panel.title": "Du willst mehr Autos?",
      "shortcut.release": "Mein Auto freigeben",
      "shortcut.release4hours": "Mein Auto für 4 Std. freigeben",
      "signup.button.title": "Jetzt gratis registrieren",
      "signup.desc": "Melde dich jetzt gratis an und starte deine erste Miete in wenigen Minuten.",
      "signup.desc.noBonus": "Melde dich jetzt gratis an und starte deine erste Miete.",
      "signup.description": "melde dich jetzt gratis bei GETAWAY an und erhalte %@.",
      "signup.description.highlight": " 5€ Startguthaben ",
      "signup.dialog.ownsACar.message": "Die Info hilft uns, unsere Services für dich zu verbessern.",
      "signup.dialog.ownsACar.title": "Hast du ein Auto?",
      "signup.domain.dialog.confirm": "Bestätigen",
      "signup.domain.dialog.message": "Bitte prüfe deine E-Mail-Adresse.",
      "signup.email.placeholder": "E-Mail",
      "signup.facebook.complete.placeholder": "VERVOLLSTÄNDIGEN",
      "signup.facebook.dateofbirth.placeholder": "Geburtstag",
      "signup.facebook.email.placeholder": "E-Mail",
      "signup.facebook.firstname.placeholder": "Vorname",
      "signup.facebook.lastname.placeholder": "Nachname",
      "signup.facebook.picker.mr": "Herr",
      "signup.facebook.picker.mrs": "Frau",
      "signup.facebook.picker.ms": "Frau",
      "signup.facebook.title": "VIA FACEBOOK ANMELDEN",
      "signup.facebook.title.placeholder": "Anrede",
      "signup.fees.text": "Entgelte & Gebühren",
      "signup.header": "Jetzt registrieren",
      "signup.invite.circleCard.text": "Schließ dich einem unserer exklusiven Nutzerkreise an und erhalte besonderen Zugang zu Fahrzeugen und Preisen!",
      "signup.invite.code.seleted.text": "Erhalte jetzt dein %@ Startguthaben von %@ !",
      "signup.invite.title": "Ich bin eingeladen worden (optional)",
      "signup.name.placeholder": "Vorname",
      "signup.name.policy.text": "Datenschutzerklärung",
      "signup.name.terms.text": "Mit der Registrierung akzeptiere ich die AGB und Datenschutzerklärung von GETAWAY.",
      "signup.name.termsservice.text": "AGB",
      "signup.password.placeholder": "Passwort",
      "signup.policy.cancel": "Abbrechen",
      "signup.terms.text": "Mit deiner Registrierung akzeptierst du die %s und %s von %s.",
      "signup.terms.url": "https://getaway.app/terms",
      "signup.terms.url.text": "AGB",
      "signup.title": "Hi,",
      "signup.zipcode.placeholder": "Wie lautet deine Postleitzahl?",
      "sort.byDistance": "Sortieren (Distanz)",
      "sort.byDistance.label": "Nach Distanz sortieren",
      "sort.byDue": "Sortieren (Fälligkeit)",
      "sort.byDue.label": "Nach Fälligkeit sortieren",
      "start.engine.description": "Bitte entnehme den Schlüssel aus dem Handschuhfach.",
      "start.engine.title": "Starte den Motor und gute Fahrt!",
      "station.booking.button": "Schlüsselübergabe",
      "station.booking.cancel": "Reservierung stornieren",
      "station.booking.category": "Fahrzeugkategorie",
      "station.booking.code": "Reservierungscode",
      "station.booking.confirm.button": "Jetzt reservieren",
      "station.booking.confirm.category": "Fahrzeugkategorie",
      "station.booking.confirm.dates": "Reservierungsdaten",
      "station.booking.confirm.description": "Überprüfe deine Reservierungsdaten und schließe die Reservierung ab",
      "station.booking.confirm.from": "Von",
      "station.booking.confirm.group": "Abholung",
      "station.booking.confirm.title": "Prüfe deine Reservierung",
      "station.booking.confirm.to": "Bis",
      "station.booking.confirm.where": "Wo?",
      "station.booking.description": "Sobald deine Reservierung beginnt, hast du 30 Minuten Zeit, das Fahrzeug am Express-Schalter der Station abzuholen.",
      "station.booking.from": "Von",
      "station.booking.group": "Abholung",
      "station.booking.key.category": "Kategorie",
      "station.booking.key.code": "Reservierungscode",
      "station.booking.key.description": "Zeige diese Ansicht einem Mitarbeiter an der Station. Nach erfolgreicher Schlüsselübergabe beginnt dein Mietvorgang automatisch.",
      "station.booking.key.from": "Von",
      "station.booking.key.insurance": "Selbstbeteiligung",
      "station.booking.key.insurance.default": "SB500",
      "station.booking.key.item": "Fahrzeug",
      "station.booking.key.title": "Schlüsselübergabe",
      "station.booking.key.to": "Bis",
      "station.booking.later.than": "Bis spätestens",
      "station.booking.later.value": "Schlüsselübergabe",
      "station.booking.merchant.waiting": "wird abgerufen",
      "station.booking.reservation": "Reserviert",
      "station.booking.title": "Deine Reservierung",
      "station.booking.to": "Bis",
      "station.booking.where": "Wo?",
      "submit": "Übernehmen",
      "submt": "Übernehmen",
      "support.call": "Service Team anrufen",
      "support.cancel": "Abbrechen",
      "support.chat": "Chat mit Service Team",
      "support.email": "E-Mail schreiben",
      "support.phone.number": "+493012088089",
      "tba": "tba",
      "terms.url": "https://get-a-way.com/agb?utm_source=app",
      "textkey": "de-DE",
      "time": "Zeitpunkt",
      "tirePressure": "Reifendruck",
      "today": "Heute",
      "tomorrow": "Morgen",
      "touchId.password.dialog.message": "Bitte gib jetzt dein GETAWAY Passwort ein, um Touch ID zu aktivieren.",
      "touchId.password.dialog.title": "Mit Passwort bestätigen",
      "touchid.enable.message": "Melde dich ab sofort noch einfacher bei GETAWAY per Touch ID an.",
      "touchid.login.message": "Nutze Touch ID, um dich noch einfacher bei GETAWAY anzumelden.",
      "transaction.open.button": "Jetzt bezahlen",
      "transaction.open.description": "Bitte zahle die offene Rechnung ",
      "transaction.open.title": "Offene Zahlung",
      "transactions.bonus.description": "{0}",
      "transactions.bonus.title": "Gutschrift",
      "transactions.cancel.description": "Storno von {0}",
      "transactions.cancel.title": "Rückerstattung",
      "transactions.fee.description": "{0} {1}",
      "transactions.fee.title": "Entgelt #{0}",
      "transactions.firststepsbonus.description": "🎉 Willkommensbonus",
      "transactions.goodwillbonus.description": "Kulanz-Gutschrift {0}",
      "transactions.payment.deposit.description": "Mietvorgang {0}",
      "transactions.payment.deposit.title": "Kaution Einzahlung",
      "transactions.payment.title": "Zahlung der Miete {0}",
      "transactions.payout.title": "Auszahlung",
      "transactions.refuel.description": "{0} am {1} Uhr",
      "transactions.refuel.title": "Tankvorgang",
      "transactions.refuelbonus.description": "🙏 Danke für's Tanken",
      "transactions.refund.deposit.description": "Mietvorgang {0}",
      "transactions.refund.deposit.title": "Rückzahlung Kaution",
      "transactions.refund.title": "Auszahlung",
      "transactions.rental.description": "{0} | {1}km am {2} Uhr",
      "transactions.rental.title": "Mietvorgang {0}",
      "transactions.type.bonus": "Gutschrift",
      "transactions.type.bonus.description": "Erste Schritte wurden gemeistert",
      "transactions.type.fee": "Gebühr",
      "transactions.type.payment": "Zahlung",
      "transactions.type.payment.reason": "Grund",
      "transactions.type.referrerBonus": "Gutschrift",
      "transactions.type.referrerBonus.description": "Für deine Empfehlung an deine Freunde",
      "trip.cancel.dialog.title": "Willst du deine Reservierung stornieren?",
      "trip.damage.report": "Schaden melden",
      "trip.driving.refuel": "Jetzt tanken",
      "trip.service.type.refuel": "Refueling",
      "trip.service.type.relocate": "Relocating",
      "trip.service.type.relocateAndRelease": "Relocate And Release",
      "trip.service.type.title": "Welcher Service wurde durchgeführt?",
      "trip.support": "Service kontaktieren",
      "tripHistory.complain": "Beschwerde einreichen",
      "tripHistory.end": "Rückgabe",
      "tripHistory.fee": "Mietvorgang",
      "tripHistory.revenue": "Mieteinnahmen",
      "tripHistory.services": "Durchgeführte(r) Service(s)",
      "tripHistory.start": "Übernahme",
      "tutorial.listcar.button": "AUTO GRATIS CHECKEN",
      "tutorial.listcar.step1.message": "Dein Auto steht täglich 23 Std. still. Gib es in Standzeiten minutenweise zum Vermieten frei und verdiene bis zu 100€ pro Woche und mehr.",
      "tutorial.listcar.step1.title": "Parkst du \nnoch oder \nverdienst du \nschon?",
      "tutorial.listcar.step2.message": "Bestimme spontan die Verfügbarkeit und deinen Verdienst pro Kilometer. Erhalte deine Einnahmen automatisch gutgeschrieben.",
      "tutorial.listcar.step2.title": "Du \nbehältst\ndie \nKontrolle",
      "tutorial.listcar.step3.message": "Wir prüfen jeden Mieter, behalten dein Auto mit modernster Technologie im Blick und lassen es rundum über „Die Gothaer“ versichern.",
      "tutorial.listcar.step3.title": "Damit\ndu gelassen\nbleiben\nkannst",
      "tutorial.listcar.step4.message": "Bestehende Ressourcen effizienter nutzen: Entlaste die Umwelt ganz nebenbei, ohne auf deine Freiheit eines Autos zu verzichten.",
      "tutorial.listcar.step4.title": "Gut für’s \nGewissen. \nGut für die \nUmwelt.",
      "tutorial.listcar.step5.message": "Lass dein Auto jetzt gratis und unverbindlich von uns prüfen und erfahre, wie viel dein Auto für dich verdienen kann.",
      "tutorial.listcar.step5.title": "Wie viel\nkann dein\nAuto \nverdienen?",
      "tutorial.map.listCar.button": "JETZT CHECKEN",
      "tutorial.map.listCar.message": "Lass jetzt gratis und unverbindlich prüfen, wie viel dein Auto für dich verdienen kann.",
      "tutorial.map.listCar.title": "Wie viel verdient \ndein Auto?",
      "tutorial.map.step1.message": "Für Hilfe, Feedback oder Anregungen ist unser Team stets nur einen Klick entfernt.",
      "tutorial.map.step1.title": "Stets an\ndeiner Seite",
      "tutorial.map.step2.message": "Über das Nutzer-Symbol gelangst du zu deinen persönlichen Einstellungen.",
      "tutorial.map.step2.title": "Alles\nim Blick",
      "tutorial.map.step3.message": "Gib deinen Wunschzeitraum an und verfügbare Autos werden automatisch gefiltert.",
      "tutorial.map.step3.title": "Spontan\nein Auto?",
      "tutorial.map.step4.button": "LOS GEHT’S",
      "tutorial.map.step4.message": "Schließe die ersten Schritte ab und sichere dir dein Startguthaben. Es dauert nur 2 Minuten.",
      "tutorial.map.step4.title": "Erobere\ndie Straße",
      "tutorial.signup.button": "LOS GEHT’S",
      "tutorial.signup.extraAction": "Ich habe ein Nutzerkonto »",
      "tutorial.signup.step1.message": "Öffne & miete Privatautos in deiner Nähe sofort per Handy\n",
      "tutorial.signup.step1.title": "AUTOS\nVON\nNEBENAN",
      "tutorial.signup.step2.message": "Preise je Kilometer machen Kosten planbar und dich im Stau entspannt.",
      "tutorial.signup.step2.title": "STRESS\nDICH\nNICHT",
      "tutorial.signup.step3.message": "Vermiete dein Auto spontan, während du es selbst nicht benötigst.",
      "tutorial.signup.step3.title": "DU\nHAST\nEIN\nAUTO?",
      "tutorial.signup.step4.message": "Gib dein Auto beim Parken mit nur einem Klick zum Vermieten frei",
      "tutorial.signup.step4.title": "MIT\nNUR\nEINEM\nKLICK",
      "unavailablecar.request": "Start Booking",
      "until": "Bis",
      "update.address.add": "HINZUFÜGEN",
      "update.address.city": "Stadt",
      "update.address.country": "Land",
      "update.address.desc": "Bitte beachte, dass es sich hierbei um deine offizielle Meldeadresse handeln muss.",
      "update.address.germany": "Deutschland",
      "update.address.location.error": "GETAWAY ist noch nicht in deinem Land verfügbar.",
      "update.address.number": "Nr",
      "update.address.search": "Adresse suchen",
      "update.address.street": "Straße",
      "update.address.success": "Danke, deine Adresse ist erfolgreich aktualisiert worden.",
      "update.address.update": "AKTUALISIEREN",
      "update.address.zip": "Plz",
      "update.email.desc": "Deine aktuelle E-Mail-Adresse lautet %@. Bitte gib deine neue E-Mail ein und bestätige dies mit deinem Passwort.",
      "update.email.new": "Neue E-Mail-Adresse",
      "update.email.title": "E-Mail-Adresse aktualisieren",
      "update.email.update": "AKTUALISIEREN",
      "update.email.wrong.pass": "Falsches Passwort",
      "update.notifications.channels": "KANÄLE",
      "update.notifications.email": "Per E-Mail",
      "update.notifications.email.desc": "Erlaubnis, dich per E-Mail zu kontaktieren.",
      "update.notifications.mail": "Per Post",
      "update.notifications.mail.desc": "Erlaubnis, dich per Post zu kontaktieren.",
      "update.notifications.push": "Per Push-Nachricht",
      "update.notifications.push.desc": "Erlaubnis, dich per App-Mitteilung zu kontaktieren.",
      "update.notifications.push.wizard.button": "ZU DEN EINSTELLUNGEN",
      "update.notifications.push.wizard.desc": "Aktiviere Push-Nachrichten",
      "update.notifications.push.wizard.latter": "NICHT JETZT",
      "update.notifications.push.wizard.latter.error": "Push-Nachricht sind deaktiviert",
      "update.notifications.push.wizard.title": "Push-Nachrichten aktivieren",
      "update.notifications.sms": "Per SMS",
      "update.notifications.sms.desc": "Erlaubnis, dich per SMS zu kontaktieren.",
      "update.notifications.title": "BENACHRICHTIGUNGEN",
      "update.notifications.topics.name.bookings.description": "Wir halten dich über deine Buchungen auf dem Laufenden.",
      "update.notifications.topics.name.bookings.title": "Buchungen",
      "update.notifications.topics.name.monthlyOverview.description": "Behalte deine Fahrten und dein Guthaben im Blick",
      "update.notifications.topics.name.monthlyOverview.title": "Monatliche Übersicht",
      "update.password.forgot.error": "Bitte gib deine E-Mail-Adresse an und tippe erneut auf \"Vergessen\". Wir senden dir im Anschluss ein Link zum Zurücksetzen deines Passworts.",
      "update.password.text": "Sofern die E-Mail-Adresse registriert ist, ist soeben ein Link zum Zurücksetzen des Passworts an %@ gesendet worden.",
      "update.password.wizard.desc": "Bitte gib ein neues Passwort ein",
      "update.password.wizard.newpassword": "Neues Passwort",
      "update.password.wizard.title": "Passwort aktualisieren",
      "update.password.wizard.update": "AKTUALISIEREN",
      "user.DL.completed.message": "Um deine Fahrerlaubnis zu aktualisieren, wende dich bitte an unser ServiceTeam.",
      "user.DL.inProgress.message": "Bitte habe noch etwas Geduld. Deine Fahrerlaubnis wird gerade verifiziert.",
      "user.DL.resubmission.message": "Um deinen Führerschein hinzuzufügen kontaktiere bitte unseren Support.",
      "user.ID.completed.message": "Um eine Änderung vorzunehmen, kontaktiere bitte unser ServiceTeam.",
      "user.ID.inProgress.message": "Dein Ausweisdokument wird verifiziert. Wir bitten um Geduld.",
      "user.action.support.text": "LiveChat öffnen",
      "user.add_to_circle.brand_not_matches": "Nutzerkreis und Anbieter stimmen nicht überein, Nutzerkreis kann daher nicht hinzugefügt werden.",
      "user.address.completed.message": "Um deine Adresse zu ändern, wende dich bitte an unser ServiceTeam.",
      "user.address.inProgress.message": "Bitte habe noch etwas Geduld. Deine Adresse wird gerade verifiziert.",
      "user.car.selector.listed": "%@ (vom %@)",
      "user.car.selector.release_ready": "%@ (Nicht freigegeben)",
      "user.car.selector.released": "%@ (Freigegeben)",
      "user.car.selector.rented": "%@ (In Miete)",
      "user.car.selector.submitted": "Neues Auto (vom %@)",
      "user.car.selector.title": "Wähle dein Auto",
      "user.circle.description": "Willst du diesen Nutzerkreis wirklich verlassen?",
      "user.circle.leave": "Nutzerkreis verlassen",
      "user.circle.leave.description": "Wenn du diesen Nutzerkreis verlässt, hast du keinen Zugang mehr zu dessen Fahrzeugen und Angeboten. Bist du sicher, dass du fortfahren möchtest?",
      "user.circle.switchAccess": "Nutzerkreis wechseln",
      "user.deletePayment.dialog.message": "Bist du sicher ?",
      "user.deletePayment.dialog.no": "Abbrechen",
      "user.deletePayment.dialog.title": "Zahlungsmittel entfernen",
      "user.deletePayment.dialog.yes": "Entfernen",
      "user.email.completed.message": "Um deine E-Mail-Adresse zu ändern, wende dich bitte an unser ServiceTeam.",
      "user.logout.cancelReservation.message": "Bitte storniere die Reservierung, bevor du fortfährst.",
      "user.logout.cancelReservation.ok": "Ok",
      "user.logout.dialog.message": "Bist du sicher?",
      "user.logout.dialog.no": "Abbrechen",
      "user.logout.dialog.title": "Abmelden",
      "user.logout.dialog.yes": "Abmelden",
      "user.noPayment.action": "Zahlungsmittel hinzufügen",
      "user.noPayment.message": "Bitte hinterlege zuerst ein gültiges Zahlungsmittel",
      "user.notNow": "Jetzt nicht",
      "user.payment.not.added": "Es wurde bisher keine Zahlungsmethode hinterlegt. Bitte hole dies zuerst nach.",
      "user.phone.completed.message": "Um deine Handynummer zu ändern, wende dich bitte an unser ServiceTeam.",
      "user.selectPayment.noCC": "Ich habe keine Kreditkarte",
      "user.terminate.dialog.message": "Achtung: Die Löschung deines Benutzerkontos kann nicht wieder rückgängig gemacht werden!",
      "user.terminate.dialog.no": "Abbrechen",
      "user.terminate.dialog.title": "Nutzerkonto entfernen",
      "user.terminate.dialog.yes": "Jetzt endgültig entfernen",
      "user.transaction.settled.success": "Zahlung wurde erfolgreich abgeschlossen.",
      "usercar.fillprofile.footnote": "Die besten Tipps, um deine Mieteinnahmen zu erhöhen, findest du in unserem %@.",
      "usercar.fillprofile.footnote.url": "https://service.get-a-way.com/wie-vermiete-ich-mein-auto#mein-autoprofil",
      "usercar.fillprofile.footnote.url.text": "HilfeCenter",
      "usercar.item.blocked.title": "Dein Auto kann nicht freigegeben werden. Bitte kontaktiere unser Service Team für mehr Infos.",
      "usercar.item.eligible.title": "In Warteschlange",
      "usercar.item.equipment_ready.title": "Installation in Kürze",
      "usercar.item.equipped.title": "Dein Auto ist ausgerüstet.",
      "usercar.item.listed.title": "Dein Auto steht auf der Warteliste.",
      "usercar.item.release_ready.title": "Nicht freigegeben",
      "usercar.item.released.title": "Freigegeben bis",
      "usercar.item.selected.title": "Dein Auto wird in Kürze ausgerüstet.",
      "usercar.item.submitted.description": "Übermittelt am",
      "usercar.item.submitted.title": "Dein Auto wird geprüft.",
      "usercar.item.terminated.description": "Entfernt am",
      "usercar.item.terminated.title": "Dein Autoprofil wird entfernt.",
      "usercar.photo.description": "Mach am besten ein Foto von vorne, von der Seite und vom Innenraum.",
      "usercar.profile.blocked.status": "BLOCKIERT",
      "usercar.profile.blocked.title": "Dieses Auto ist aktuell geblockt.",
      "usercar.profile.eligible.description": "Es ist nun in der Warteschlange, um in den Fuhrpark aufgenommen zu werden. Je mehr sich aus deiner Nähe anmelden, desto schneller bist du dabei. Teile jetzt deinen persönlichen Einladungscode mit deinen Freunden und Nachbarn.",
      "usercar.profile.eligible.status": "IN WARTESCHLANGE",
      "usercar.profile.eligible.title": "Prima %@, dein Auto hat alle Tests bestanden 💪",
      "usercar.profile.equipment_ready.description": "Unser Techniker kommt zum vereinbarten Wunschtermin zu der von dir gewählten Adresse. Er wird dich ca. 30 Minuten vor Terminbeginn anrufen, um den exakten Treffpunkt beim Auto abzusprechen. Bitte habe deinen Autoschlüssel und Kfz-Schein parat.\n\nBitte gib uns mind. 48 Stunden vorab Bescheid, solltest du den Termin nicht wahrnehmen können, um etwaige Gebühren zu vermeiden.",
      "usercar.profile.equipment_ready.status": "INSTALLATION",
      "usercar.profile.equipment_ready.title": "%@, der Einbautermin für dein Auto steht bevor",
      "usercar.profile.equipped.description": "Bitte habe einen Moment Geduld. Sobald die letzten Funktions- und Sicherheitschecks abgeschlossen sind, wird dein Auto freigeschaltet.",
      "usercar.profile.equipped.status": "WIRD FREIGESCHALTET",
      "usercar.profile.equipped.title": "%@, in Installation ist abgeschlossen",
      "usercar.profile.firssteps.description": "Um Vermieter zu werden, schließe jetzt bitte die Ersten Schritte ab.",
      "usercar.profile.firssteps.title": "Bitte vervollständige jetzt deine Nutzerangaben",
      "usercar.profile.firststeps.description": "Im Anschluss können wir prüfen, was dein Auto für dich verdienen könnte.",
      "usercar.profile.firststeps.title": "%@, bitte vervollständige jetzt deine https://applink.get-a-way.com/user-challenges-firststeps",
      "usercar.profile.firststeps.title.url": "https://applink.get-a-way.com/user-challenges-firststeps",
      "usercar.profile.firststeps.title.url.text": "Nutzerangaben",
      "usercar.profile.listed.description": "Lege hier fest, wie Mietern dein Auto angeboten werden soll. Du kannst diese Infos jederzeit anpassen.",
      "usercar.profile.listed.status": "IN PRÜFUNG",
      "usercar.profile.listed.title": "%@, vervollständige jetzt dein Autoprofil",
      "usercar.profile.release.availableuntil": "Jetzt freigeben bis",
      "usercar.profile.release_ready.status": "PRIVAT",
      "usercar.profile.released.availableuntil": "Freigegeben bis",
      "usercar.profile.released.status": "FREIGEGEBEN",
      "usercar.profile.rented.status": "VERMIETET",
      "usercar.profile.selected.description": "Um einfachste Mietabläufe zu gewährleisten und Acht auf dein Auto geben zu können, wenn es vermietet wird, rüsten wir es einmalig mit unserem SafetyKit aus. Unser Techniker-Team wird dich zeitnah kontaktieren, um einen individuellen Einbautermin zu vereinbaren.",
      "usercar.profile.selected.status": "AUSGEWÄHLT",
      "usercar.profile.selected.title": "%@, dein Auto kann ausgerüstet werden 🙌",
      "usercar.profile.service.status": "IM SERVICE",
      "usercar.profile.submit.action": "Jetzt prüfen lassen",
      "usercar.profile.submit.description": "Hunderte sind bereits dabei. Lass jetzt gratis & unverbindlich prüfen, wie viel dein Auto für dich verdienen könnte.",
      "usercar.profile.submit.left": "MEIN AUTO",
      "usercar.profile.submit.status": " ",
      "usercar.profile.submit.title": "erfahre, was dein Auto für dich verdienen könnte!",
      "usercar.profile.submitted.description": "Lege hier fest, wie Mietern dein Auto angeboten werden soll. Du kannst diese Infos jederzeit anpassen.",
      "usercar.profile.submitted.status": "IN PRÜFUNG",
      "usercar.profile.submitted.title": "%@, vervollständige jetzt dein Autoprofil",
      "usercar.profile.terminated.status": "TERMINATED",
      "usercar.profile.title": "Mein Auto",
      "usercar.review.novalue": "✨ Neues Auto",
      "usercar.troubleshoot": "Störungsbehebung",
      "usercar.troubleshoot.dialog.message": "Als Autobesitzer kannst du jetzt dein Auto entriegeln und die Motorsperre aufheben. Dein Auto ist im Anschluss nicht mehr über GETAWAY gesichert. Wir empfehlen dringend, nur fortzufahren, sofern du dich unmittelbar an deinem Auto befindest.",
      "usercar.troubleshoot.dialog.title": "Störungsbehebung",
      "usercar.troubleshoot.dialog.unlock": "Auto- und Motorsperre deaktivieren",
      "usercarprofile.example.title": "Was ein Mieter zahlen würde",
      "usercarprofile.price.details.footnote": "Weitere Infos, wie du den idealen Preis festlegst, findest du in unserem %@.",
      "usercarprofile.price.details.footnote.url": "https://service.get-a-way.com/wie-vermiete-ich-mein-auto/mein-autoprofil/so-legst-du-den-idealen-preis-fur-dein-auto-fest",
      "usercarprofile.price.details.footnote.url.text": "Hilfe-Center",
      "usercarprofile.price.indicator.title": "du verdienst",
      "usercarprofile.price.slider.aboveaverage": "überdurchschnittlich",
      "usercarprofile.submit.status": " ",
      "validation.accountholder.invalid": "Bitte gib einen gültigen Kontoinhaber an",
      "validation.address.street.invalid": "Bitte gültigen Straßennamen eintragen",
      "validation.address.streetnumber.invalid": "Bitte gültige Hausnummer eintragen",
      "validation.address.zip.invalid": "Bitte gültige Postleitzahl eintragen",
      "validation.email.invalid": "Bitte gültige E-Mail-Adresse eintragen",
      "validation.iban.invalid": "Bitte eine gültige IBAN eintragen",
      "validation.name.invalid": "Mind. zwei Buchstaben",
      "validation.password.invalid": "Mind. 8 Zeichen (inkl. Groß-/ Kleinbuchst./ Ziff./ Sonderz.)",
      "validation.phone.invalid": "Diese Handy-Nummer kann nicht bestätigt werden. Bitte nutze eine andere.",
      "validation.required": "Bitte ausfüllen.",
      "validation.required.empty": "Dieses Feld darf nicht leer bleiben",
      "validator.name.error": "Dein Name muss mindestens zwei Buchstaben enthalten.",
      "validator.password.error": "Passwort muss mind. aus 6 Zeichen inklusive einer Ziffer bestehen.",
      "validator.streetNumber.error": "Bitte gebe eine gültige Hausnummer an",
      "verify.phone.failed": "Falscher Code",
      "verify.phone.passwordView.description": "Deine aktuelle Handy-Nummer lautet %@. Bitte gib dein Passwort ein.",
      "verify.phone.passwordView.title": "Handy-Nummer ändern",
      "verify.phone.resend.success": "Du wirst in Kürze einen Bestätigungscode per SMS erhalten",
      "verify.phone.success": "Danke, deine Handy-Nummer ist erfolgreich aktualisiert worden.",
      "video": "Video",
      "violation.adyenPaymentProvider.responseValidator.authorized.assertTrue": "Diese Karte kann nicht hinzugefügt werden. Bitte nutze eine andere Kreditkarte.",
      "violation.adyenPaymentProvider.responseValidator.cvcDeclined.assertFalse": "Ungültige Prüfziffer. Bitte überprüfe deine Angaben und versuche es erneut.",
      "violation.adyenPaymentProvider.responseValidator.noResponse.assertFalse": "Verbindung fehlgeschlagen. Bitte probiere es in wenigen Momenten erneut.",
      "violation.adyenPaymentProvider.responseValidator.notificationSuccess.assertTrue": "Diese Karte kann nicht hinzugefügt werden. Bitte nutze eine andere Kreditkarte.",
      "violation.apiVersionCheck.version.min": "Deine App-Version wird nicht mehr unterstützt, bitte update sie über den App Store.",
      "violation.booking.bookingPayInAllowedValidator.payInAllowed.assertTrue": "Zahlung per Vorkasse kann nicht genutzt werden, solange noch ein Guthaben existiert. Bitte kontaktiere den Kundenservice.",
      "violation.booking.bookingPaymentValidator.blockedPaymentNotNeededOrConfirmed.assertTrue": "Leider wurde die Autorisierung des Sicherheitsbetrages von deiner Bank abgelehnt. Bitte versuche es mit einer anderen Zahlungsmethode.",
      "violation.booking.bookingRequestValidator.canBook.assertTrue": "Leider kannst du dieses Fahrzeug nicht buchen. Bitte wähle ein anderes Fahrzeug oder eine andere Zeit.",
      "violation.booking.bookingRequestValidator.confirmedCarValid.assertTrue": "Dieses Fahrzeug ist für den gewählten Mietzeitraum leider nicht verfügbar.",
      "violation.booking.bookingRequestValidator.watchlistCheckPassed.assertTrue": "Bitte stelle sicher, dass dein Nutzerkonto vollständig ist. Falls du Hilfe benötigst, wende dich bitte an den Kundenservice.",
      "violation.booking.bookingRequestValidator.withinOpeningHours.assertTrue": "Buchung nicht möglich. Start- und/oder Endzeit der Buchungsfrage liegen außerhalb der Öffnungszeiten.",
      "violation.booking.bookingRequestValidator.youngDriver.assertFalse": "Wir bitten um Geduld. Um Autos bei GETAWAY mieten zu können, muss dir die Führerscheinklasse B vor mehr als zwei Jahren erteilt worden sein. Du wirst bei Erreichen der Frist automatisch freigeschaltet und benachrichtigt.",
      "violation.car.chassisNumberUniqueValidator.chassisNumber.assertTrue": "Fahrgestellnummer bereits im System",
      "violation.car.damages.characteristics.notNull": "Bitte spezifiziere die Art des Schadens.",
      "violation.car.damages.component.notNull": "Bitte wähle eine Schadenkomponente aus.",
      "violation.car.damages.where.notNull": "Bitte spezifiziere den Ort des Schadens am Auto.",
      "violation.car.generateOwnerAccessToken.rented.assertFalse": "Das Auto kann während eines laufenden Mietvorgangs nicht geöffnet werden.",
      "violation.car.onlineCheck.online.assertTrue": "Das Auto scheint sich in einem Funkloch zu befinden. Bitte probiere es an einer anderen Position erneut oder kontaktiere unser Service Team.",
      "violation.car.owner.notNull": "Das Auto muss einem Besitzer zugeordnet sein.",
      "violation.car.plateUniqueValidator.plate.assertTrue": "Autokennzeichen bereits im System",
      "violation.car.registrationCertificate.plate.pattern": "Bitte gib ein gültiges Kfz-Kennzeichen an.",
      "violation.car.registrationCertificate.plate.plateRegion": "Die Region des eingegebenen Kfz-Kennzeichens ist unbekannt.",
      "violation.car.releaseCheck.availableUntil.assertTrue": "Zu kurz. Bitte wähle einen Freigabezeitraum von mindestens 90 Minuten.",
      "violation.car.releaseCheck.basicPrice.notNull": "Um dein Auto freizugeben, lege jetzt deinen Verdienst pro Kilometer fest.",
      "violation.car.releaseCheck.doorsClosed.assertTrue": "Bitte verschließe alle Türen, um fortzufahren.",
      "violation.car.releaseCheck.fuelCardIn.assertTrue": "Bitte lege die Tankkarte in das dafür vorgesehene Fach, um fortzufahren.",
      "violation.car.releaseCheck.fuelLevel.min": "Der Tankstand muss mindestens 25% betragen, um das Auto freigeben zu können.",
      "violation.car.releaseCheck.ignitionOff.assertTrue": "Bitte schalte die Zündung aus, um fortzufahren.",
      "violation.car.releaseCheck.keyFobIn.assertTrue": "Bitte lege den Schlüssel in das dafür vorgesehene Fach, um fortzufahren.",
      "violation.car.releaseCheck.online.assertTrue": "Das Auto scheint sich in einem Funkloch zu befinden. Bitte probiere es an einer anderen Position erneut oder kontaktiere unser Service Team.",
      "violation.car.releaseCheck.paymentMethodExists.assertTrue": "Bitte hinterlege eine gültige Zahlungsmethode.",
      "violation.car.releaseCheck.windowsClosed.assertTrue": "Bitte verschließe alle Fenster, um fortzufahren.",
      "violation.clientVersionCheck.version.min": "Deine App-Version wird nicht mehr unterstützt, bitte update sie über den App Store.",
      "violation.device.alreadyPairedValidator.device.assertTrue": "Dein Nutzerkonto ist nicht mit diesem Smartphone verbunden. Bitte kontaktiere unser Service Team, um fortzufahren.",
      "violation.driversLicenseTokenData.validation.validToken.assertTrue": "Deine Sitzung ist ungültig bzw. abgelaufen. Bitte versuche es erneut.",
      "violation.fleetAdmin.validPasswordValidator.password.assertTrue": "Bitte trage ein gültiges Passwort ein (mind. 6 Zeichen, inkl. mind. einer Ziffer)",
      "violation.forgotPasswordResource.resetPasswordCheck.validToken.assertTrue": "Dieser Link ist nicht mehr aktuell. Bitte kontaktiere unser Service Team für weitere Infos.",
      "violation.installation.mechanic.notNull": "Die Installation muss einem registrierten Techniker zugeordnet werden.",
      "violation.paymentMethod.existingPaymentMethod.isBlocked.assertFalse": "Das Zahlungsmittel kann nicht hinzugefügt werden. Bitte verwende ein anderes Zahlungsmittel.",
      "violation.paymentResource.paymentMethodTypeCheck.paymentMethodNotAllowed.assertFalse": "Das Zahlungsmittel kann nicht hinzugefügt werden. Bitte verwende ein anderes Zahlungsmittel.",
      "violation.phoneNumber.noNewNumberWhenPairedValidator.phoneNumber.assertTrue": "Nicht möglich. Dein Nutzerkonto ist mit diesem Smartphone verbunden. Bitte kontaktiere unser Service Team, um fortzufahren.",
      "violation.phoneNumber.userPhoneNumberUniqueValidator.phoneNumber.assertTrue": "Diese Handynummer kann nicht hinterlegt werden. Bitte probiere eine andere",
      "violation.phoneNumber.validPhoneNumberValidator.phoneNumber.assertTrue": "Ungültiger Code! Bitte stelle sicher, dass du die richtige Handynummer angegeben hast oder fordere einen neuen Code an.",
      "violation.phoneNumber.validVerificationCodeValidator.phoneNumber.assertTrue": "Bitte gebe die richtige Telefonnummer ein",
      "violation.rentalResource.serviceTripCheck.userUnauthorized.assertFalse": "Tut mir leid, du darfst leider keinen ServiceTrip durchführen. Bitte starte eine reguläre Miete.",
      "violation.sepa.ibanValidator.sepa.assertTrue": "Bitte gib eine gültige IBAN ein.",
      "violation.serviceCircle.serviceCircleValidator.pricingEmpty.assertTrue": "Die Änderung von Preisen für Service UserGroups wird nicht unterstützt",
      "violation.trip.cancellation.reserved.assertTrue": "Nur gültige Reservierungen können zurückgenommen werden.",
      "violation.trip.driverQualificationValidator.addressVerified.assertTrue": "Bitte hinterlege deine Meldeadresse, um fortzufahren.",
      "violation.trip.driverQualificationValidator.creditCheck.assertTrue": "Deine Nutzerdaten sind unvollständig. Bitte kontaktiere unser Service Team.",
      "violation.trip.driverQualificationValidator.identityChecked.assertTrue": "Deine Nutzerdaten sind unvollständig. Bitte kontaktiere unser Service Team.",
      "violation.trip.driverQualificationValidator.paymentMethodExists.assertTrue": "Bitte hinterlege eine gültige Zahlungsmethode, um fortzufahren.",
      "violation.trip.driverQualificationValidator.phoneNumberVerified.assertTrue": "Bitte verifiziere deine Handy-Nummer auf diesem Gerät, um fortzufahren.",
      "violation.trip.driverQualificationValidator.positiveBalance.assertTrue": "Bitte stelle sicher, dass dein Guthaben positiv ist, um fortzufahren.",
      "violation.trip.driverQualificationValidator.validLicence.assertTrue": "Um Autos zu mieten, muss dein Führerschein verifiziert worden sein. Den aktuellen Status kannst du in deinem Nutzerprofil einsehen.",
      "violation.trip.finishCheck.bitLessFuelOnReturn.assertFalse": "Ich bestätige, dass der aktuelle Tankstand mindestens dem Tankstand zu Beginn der Miete entspricht.",
      "violation.trip.finishCheck.correctLocation.assertTrue": "Bitte beende die Miete im Umkreis des angegebenen Rückgabeorts. Andernfalls können Gebühren anfallen.",
      "violation.trip.finishCheck.extremelyLowFuelLevel.assertFalse": "Ich wähle den Tank-Service  <10% (50,- €)",
      "violation.trip.finishCheck.finishLocationAllowed.assertTrue": "Ich habe das Fahrzeug zurückgebracht",
      "violation.trip.finishCheck.fuelLevel.min": "Ich wähle den Tank-Service (5 €)",
      "violation.trip.finishCheck.initialUnlockIsSet.assertTrue": "Das Auto muss initial geöffnet worden sein, um den Mietvorgang beenden zu können.",
      "violation.trip.finishCheck.insideArea.assertTrue": "Ich wähle den Rückbringservice (10€ + 4.9€/km)",
      "violation.trip.finishCheck.online.assertTrue": "Es konnte leider keine Verbindung zum Fahrzeug hergestellt werden. Bitte versuche es erneut oder kontaktiere unseren Support.",
      "violation.trip.postReservation.accessToken.assertTrue": "Das Fahrzeug kann zum jetzigen Zeitpunkt nicht reserviert werden.",
      "violation.trip.postReservation.accessToken.notNull": "Ups..etwas ist schief gelaufen. Bitte probiere es gleich noch einmal.",
      "violation.trip.postReservation.blockedPaymentConfirmed.assertTrue": "Deine Bank hat diese Transaktion abgelehnt. Bitte kontaktiere deine Bank für weitere Informationen oder versuche es mit einem anderen Zahlungsmittel.",
      "violation.trip.rating.onlyFinished": "Der Mietvorgang muss erfolgreich beendet worden sein, um diesen bewerten zu können.",
      "violation.trip.rentalReservationValidator.addressVerified.assertTrue": "Bitte hinterlege deine Meldeadresse, um fortzufahren.",
      "violation.trip.rentalReservationValidator.backByConflictsWithBooking.assertFalse": "Dieses Fahrzeug ist für den gewählten Zeitraum bereits reserviert. Bitte wähle ein anderes Fahrzeug oder eine andere Zeit.",
      "violation.trip.rentalReservationValidator.blockedByUpcomingBooking.assertFalse": "Dieses Fahrzeug ist bereits reserviert, bitte wähle ein anderes.",
      "violation.trip.rentalReservationValidator.carAvailable.assertTrue": "Dieses Auto ist nicht mehr verfügbar. Bitte wähle ein anderes.",
      "violation.trip.rentalReservationValidator.carStatusReleased.assertTrue": "Dieses Auto ist nicht mehr verfügbar. Bitte wähle ein anderes.",
      "violation.trip.rentalReservationValidator.creditworthinessApproved.assertTrue": "Deine Nutzerdaten sind unvollständig. Bitte kontaktiere unser Service Team.",
      "violation.trip.rentalReservationValidator.identityChecked.assertTrue": "Deine Angaben zu Name, Geburtsdatum und Meldeadresse konnten nicht bestätigt werden. Bitte kontaktiere uns über das LiveChat-Symbol oben rechts, um deine Verifizierung abzuschließen.",
      "violation.trip.rentalReservationValidator.online.assertTrue": "Das Fahrzeug ist aktuell nicht verfügbar und kann daher nicht gemietet werden.",
      "violation.trip.rentalReservationValidator.paymentMethodExists.assertTrue": "Bitte hinterlege eine gültige Zahlungsmethode, um fortzufahren.",
      "violation.trip.rentalReservationValidator.positiveBalance.assertTrue": "Bitte stelle sicher, dass dein Guthaben positiv ist, um fortzufahren.",
      "violation.trip.rentalReservationValidator.serviceTripAllowed.assertTrue": "Dieses Fahrzeug kann mit deinem Nuterkonto nicht für Service-Fahrten gemietet werden.",
      "violation.trip.rentalReservationValidator.userMarkedForFraud.assertFalse": "😐 Leider ist diese Aktion momentan nicht möglich. Für weitere Informationen kontaktiere bitte unser ServiceTeam.",
      "violation.trip.rentalReservationValidator.userMarkedForFraud.assertTrue": "😐 Leider ist diese Aktion momentan nicht möglich. Für weitere Informationen kontaktiere bitte unser ServiceTeam.",
      "violation.trip.rentalReservationValidator.validLicence.assertTrue": "Um Autos zu mieten, muss dein Führerschein verifiziert worden sein. Den aktuellen Status kannst du in deinem Nutzerprofil einsehen.",
      "violation.trip.rentalReservationValidator.watchlistCheckPassed": "Bitte stelle sicher, dass dein Nutzerkonto vollständig ist. Wenn du Hilfe benötigst, kontaktiere gern unseren Kundenservice.",
      "violation.trip.rentalReservationValidator.youngDriver.assertFalse": "Wir bitten um Geduld. Um Autos bei GETAWAY mieten zu können, muss dir die Führerscheinklasse B vor mehr als zwei Jahren erteilt worden sein. Du wirst bei Erreichen der Frist automatisch freigeschaltet und benachrichtigt.",
      "violation.trip.reservationValidator.manualGearRestricted.assertFalse": "Deine Fahrerlaubnis erstreckt sich nicht für Autos mit manueller Schaltung! Bitte wähle ein anderes.",
      "violation.user.account.paymentMethods.sepa.ibanValid": "Bitte gib eine gültige IBAN ein.",
      "violation.user.archiveCheck.hasOpenBookingRequests.assertFalse": "Es scheint als hättest du noch offene Rechnungen bei uns. Bitte kontaktiere einmal unser Service Team für weitere Informationen und um fortzufahren.",
      "violation.user.archiveCheck.hasOpenTransactions.assertFalse": "Kann nicht fortgesetzt werden, da entweder eine offene oder eine nicht beglichene Rechnung vorliegt. Bitte versuchen Sie es später erneut, da dies von der Bestätigung der Zahlung durch die Bank abhängig ist.",
      "violation.user.dateOfBirth.min": "Um ein Fahrzeug mieten zu können, musst du mindestens 2 Jahre lang im Besitz eines gültigen Führerscheins der Klasse B sein.",
      "violation.user.device.alreadyPaired": "Dein Nutzerkonto ist nicht mit diesem Smartphone verbunden. Bitte kontaktiere unser Service Team, um fortzufahren.",
      "violation.user.email.notNull": "Bitte hinterlege in deinem Nutzerkonto eine gültige E-Mail Adresse.",
      "violation.user.email.pattern": "Bitte hinterlege deinem Nutzerkonto eine gültige E-Mail Adresse.",
      "violation.user.email.userEmail.Unique": "Es scheint, als sei deine E-Mail Adresse bereits bei GETAWAY hinterlegt. Möchtest du dein Passwort zurücksetzen?",
      "violation.user.email.userEmailUnique": "Es scheint, als sei deine E-Mail Adresse bereits bei GETAWAY hinterlegt. Möchtest du dein Passwort zurücksetzen?",
      "violation.user.firstName.notNull": "Bitte hinterlege in deinem Nutzerkonto einen gültigen Vornamen.",
      "violation.user.firstName.size": "Bitte hinterlege in deinem Nutzerkonto einen gültigen Vornamen.",
      "violation.user.lastName.size": "Dein Nachname muss aus mind. 2 Buchstaben bestehen.",
      "violation.user.password.validPassword": "Bitte trage ein gültiges Passwort ein (mind. 6 Zeichen, inkl. mind. einer Ziffer)",
      "violation.user.phoneNumber.phoneNumber.noNewNumberWhenPaired": "Nicht möglich. Dein Nutzerkonto ist mit diesem Smartphone verbunden. Bitte kontaktiere unser Service Team, um fortzufahren.",
      "violation.user.phoneNumber.phoneNumber.validVerificationCode": "Ungültiger Code! Bitte stelle sicher, dass du die richtige Handynummer angegeben hast oder fordere einen neuen Code an.",
      "violation.user.phoneNumber.userPhoneNumberUnique": "Diese Handynummer kann nicht hinterlegt werden. Bitte probiere eine andere.",
      "violation.user.phoneNumber.validPhoneNumber": "Bitte hinterlege in deinem Nutzerkonto eine gültige Handynummer.",
      "violation.user.phoneNumber.validVerificationCode": "Dieser Bestätigungscode ist ungültig. Bitte fordere einen neuen an.",
      "violation.user.referredBy.referralCodeExists": "Dieser Einladungscode ist ungültig.",
      "violation.user.requireDevice.hasPairedDevice.assertTrue": "Bitte verifiziere deine Handynummer auf diesem Smartphone, um fortzufahren.",
      "violation.user.requireDevice.isCorrectDevice.assertTrue": "Dieses Handy ist nicht autorisiert für diese Interaktion. Bitte kontaktiere unser Service Team.",
      "violation.user.userEmailUniqueValidator.email.assertTrue": "Es scheint, als sei deine E-Mail Adresse bereits bei GETAWAY hinterlegt. Möchtest du dein Passwort zurücksetzen?",
      "violation.user.validPasswordValidator.password.assertTrue": "Bitte trage ein gültiges Passwort ein (mind. 6 Zeichen, inkl. mind. einer Ziffer) ",
      "violation.validatePaymentMethods.haveBlockedPaymentIfPayInOnly": "bitte wählest du den Zeitrahmen für die Reservierung.",
      "violation.validatePaymentMethods.haveBlockedPaymentIfPayInOnly.assertTrue": "bitte wählest du den Zeitrahmen für die Reservierung.",
      "wait": "Einen Augenblick bitte..",
      "wj.map.filter.violation.duration": "Zeitraum muss min. 1 Stunde betragen.",
      "wj.map.filter.violation.min": "Stelle sicher, dass die angegebenen Daten korrekt sind.",
      "wj.map.filter.violation.order": "Stelle sicher, dass die angegebenen Daten korrekt sind.",
      "wj.schufa.birthday.violation.max": "Prüfe, ob das Datum korrekt ist.",
      "wj.schufa.birthday.violation.min": "Prüfe, ob das Datum korrekt ist.",
      "wma.trackingPage.extendedArea.name": "Anfahrt, erweitertes Gebiet",
      "yes": "Ja",
      "yesterday": "gestern",
    },
    "en": {
      "AT": "Austria",
      "Ab": "From",
      "BE": "Belgium",
      "BG": "Bulgaria",
      "CH": "Switzerland",
      "CY": "Cyprus",
      "CZ": "Czechia",
      "Camera": "Camera",
      "DE": "Germany",
      "DK": "Denmark",
      "DL.button.text": "Scan your driver's license now",
      "DL.desr": "Get your driver's license confirmed by uploading it now.",
      "DL.stepFour": "The driving license can only be verified if it's made out for you",
      "DL.stepOne": "Have your original driver's license (no copy and not your ID!) ready.",
      "DL.stepThree": "Pay attention to the lighting conditions so that your driver's license is clearly recognizable.",
      "DL.stepTwo": "Place your driver's license on a neutral surface that is different in color from the driver's license.",
      "DL.title": "Driving licence",
      "EE": "Estonia",
      "EL": "Greece",
      "ES": "Spain",
      "EUR": "€",
      "FI": "Finland",
      "FR": "France",
      "HR": "Croatia",
      "HU": "Hungary",
      "ID.button.text": "Scan my ID Card",
      "ID.desr": "To unlock this feature upload your identification document, now.",
      "ID.manual.option": "I don't have a document with address",
      "ID.stepOne": "I have a German identity card",
      "ID.stepThree": "Pay attention to the lighting conditions so that your ID is clearly recognizable. Also please remove anything that would obstruct the visibility of your face (hat, glasses, etc..)",
      "ID.stepTwo": "Place your Identity Card on a neutral surface that is different in color from the card.",
      "ID.title": "Personal Identity Document",
      "IE": "Ireland",
      "IT": "Italy",
      "LT": "Lithuania",
      "LU": "Luxembourg",
      "LV": "Latvia",
      "MR": "Mr.",
      "MRS": "Mrs.",
      "MT": "Malta",
      "NL": "Netherlands",
      "PL": "Poland",
      "PT": "Portugal",
      "RO": "Romania",
      "SE": "Sweden",
      "SI": "Slovenia",
      "SK": "Slovakia",
      "about.dialog": "About GETAWAY",
      "about.url": "https://get-a-way.com/getaway-carsharing?utm_source=app",
      "accepted.invitation": "ACCEPTED INVITATIONS",
      "accountHolder": "Account holder",
      "action.permission.button.title": "New login",
      "action.permission.details": "We have received an error message and are already working on the solution. Please close the app now and restart it.\n\nIf the problem persists, please contact our ServiceTeam.",
      "action.permission.title": "Something went wrong.",
      "add": "ADD",
      "add.car.enhancement.wizard.description": "Update your cars features in order to increase its desireability.",
      "add.car.enhancement.wizard.title": "Add your car's features",
      "add.damage.wizard.description": "Listing your current damages helps identify future damages and get imbursed.",
      "add.damage.wizard.footer": "I confirm that the list of damages is complete if anything changes I will update my car profile.",
      "add.damage.wizard.title": "List of damages",
      "add.damage.wizard.update": "APPLY",
      "add.payment.method.success": "Thanks for adding a payment method.",
      "addpayment.card.desc": "Add your credit card",
      "addpayment.card.title": "Visa or MasterCard",
      "addpayment.directdebit.desc": "Add Bank Account",
      "addpayment.paypal": "PayPal",
      "addpayment.paypal.desc": "Connect your PayPal account",
      "address": "Address",
      "address.austria.streetNum.label": "House/Building/Door",
      "addressCheck.dialog.message": "Your Address check was not successful.\n\nPlease contact our customer support.",
      "addressCheck.dialog.title": "Address check",
      "addressCheck.dialog.tryAgain": "TRY AGAIN",
      "addressCheck.dialog.yes": "OK",
      "addressProof.button.text": "Upload document",
      "addressProof.desr": "Get your address confirmed by uploading a document proof.",
      "addressProof.stepOne": "Utility bill, bank note ...",
      "addressProof.stepTwo": "Address and name visible",
      "addressProof.title": "Document with address",
      "addressSearch.cancel.text": "Cancel",
      "addressSearch.current.label": "My location",
      "addressSearch.search.placeholder": "Search address",
      "android.update.dialog.message": "There is a new version of our app available, please update to the latest version.",
      "android.update.dialog.no": "Later",
      "android.update.dialog.title": "Update available",
      "android.update.dialog.yes": "Update",
      "api_error.carUnavailable": "Selected car is unavailable for booking please choose another one",
      "api_error.not_available": "Action not possible",
      "api_error.not_available_forPayin": "This action is not available for the active payment method",
      "api_error.payment.3ds_required": "Please verify your credit card (merchant \"GETAWAY\") to continue.",
      "api_error.payment.adyen_3ds_response_not_received": "payment provider took too long to respond, please try again",
      "api_error.payment.card_validation_attempts_exceeded": "credit card validation failed and number of attempts exceeded - please contact customer service to add credit card",
      "api_error.payment.card_validation_failed": "credit card validation failed",
      "api_error.user.schufaCheck.data_incomplete": "Data incomplete",
      "api_error.user_not_belong_to_circle_brand": "The user is not a member of the selected group brand",
      "app.old.dialog.description": "We've detected that you still have the old GETAWAY app installed on your device.\n\nPlease Click \"Uninstall\" to remove it.",
      "app.old.dialog.title": "Uninstall the old app",
      "app.privacy.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA-INT_dataprivacy-de.pdf",
      "app.terms.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA-INT_termsandconditions-de.pdf",
      "applink.error": "This link is not valid anymore. Please contact our service team for more information.",
      "apply": "APPLY",
      "area.brand_not_resolved": "Selected brand was not recognized",
      "area.fleet.brand.not_match": "selected fleet is not belong to the selected brand",
      "area.fleet_not_resolved": "Selected fleet was not recognized",
      "area.label": "return area",
      "area.label.outside": "Please mind the return area",
      "area.walkthrough.description": "To finish the rental return the vehicle in the return area with a fuel status of >25% and lock it with your mobile phone.\n\nNew: Choose the return service at the end of your trip and we take care of the return.",
      "area.walkthrough.title": "Return and finish rental",
      "auth.failed.alert.body": "You've entered a wrong password. Please try again or click \"Forgot?\" to reset your password.",
      "auth.failed.alert.title": "Incorrect password",
      "backend.error": "Ups..something went wrong. Please try again later.",
      "balance": "Balance",
      "balance.addFunds": "Add Funds",
      "balance.addFunds.description": "Save money with the prepaid plans",
      "balance.addPaymentMethod": "Add payment method",
      "balance.delete.confirm": "Are you sure you want delete this payment method?",
      "balance.getFreeCredit": "Get credit for FREE",
      "balance.getFreeCredit.description": "Get free credit by completing some simple challenges.",
      "balance.invoice.failure": "Sorry, we are unable to send receipt.",
      "balance.invoice.success": "Your receipt will be emailed to you soon!",
      "balance.paymentMethods": "PAYMENT METHODS",
      "balance.payoutFunds": "Payout funds",
      "balance.payoutFunds.addSEPA.dialog.message": "Please enter your bank account details so we can payout your withdrawable balance.",
      "balance.payoutFunds.addSEPA.dialog.title": "Add payout method",
      "balance.payoutFunds.description": "Withdrawal balance",
      "balance.payoutFunds.dialog.message": "Withdrawable balance will be automatically paid out to your bank account at the end of the calendar month.",
      "balance.payoutFunds.dialog.title": "Payout funds",
      "balance.payoutFunds.error.message": "Funds are automatically paid to your account once a month.",
      "balance.send.invoice": "SEND\RECEIPT",
      "balance.transactions": "TRANSACTIONS",
      "bluetooth.dialog.description": "Please enable Bluetooth to unlock the car and start your trip.",
      "bluetooth.dialog.title": "Enable Bluetooth®",
      "bluetooth.disabled.message": "Enable bluetooth® for the smartphone car interaction",
      "bluetooth.permission.allow.details": "Please grant GETAWAY access to your Bluetooth to allow the app to access the vehicles",
      "bluetooth.permission.allow.title": "Access Bluethooth®",
      "bluetooth.permission.details": "Please open the control center by swiping up from the bottom of your screen and enable your bluetooth.",
      "bluetooth.permission.message": "Please grant GETAWAY access to your Bluetooth to allow the app to access the vehicles",
      "bluetooth.permission.title": "Enable Bluethooth®",
      "bluetooth.permission.yes": "Allow",
      "bonus.sheet.description.lowAndOut": "Get a € 5 instant bonus if you finish the rental in the vehicle's return area. Get another 5 € instant bonus if you refuel the vehicle.",
      "bonus.sheet.description.lowFuel": "Get a € 5 bonus by filling up the vehicle for free. Simply pay with your fuel card in the glove compartment.",
      "bonus.sheet.description.out&low": "Get a 5€ instant bonus if you finish the rental in the vehicle's return area.\n\nGet another 5€ instant bonus if you refuel the vehicle.",
      "bonus.sheet.description.outsideArea": "Get a 5€ instant bonus if you finish the rental in the vehicle's return area.",
      "bonus.sheet.ok": "Ok",
      "booking.BookedCarIsBLOCKED": "Vehicle was unavailable",
      "booking.BookedCarIsRELEASE_READY": "Vehicle was unavailable",
      "booking.BookedCarIsRENTED": "Vehicle was unavailable",
      "booking.BookedCarIsUnavailable": "Vehicle was unavailable",
      "booking.BookingCancelled": "Booking was cancelled",
      "booking.DriverHasOngoingRental": "Another rental was ongoing",
      "booking.OtherReason": "Technical issues",
      "booking.OtherRentalConstraintViolation": "Vehicle was unavailable",
      "booking.RentalCancelled": "Rental was cancelled",
      "booking.RentalExpired": "Rental was expired",
      "booking.accessToken": "Technical issues",
      "booking.backByConflictsWithBooking": "Technical issues",
      "booking.backByExpired": "Technical issues",
      "booking.blockedByUpcomingBooking": "Technical issues",
      "booking.blockedPaymentConfirmed": "Payment failed",
      "booking.button.text": "Show on map",
      "booking.cancelled.at": "Booking cancelled at %s",
      "booking.car.unavailable": "Car is not available, yet",
      "booking.confirm.from": "From",
      "booking.confirm.profile": "Vehicle",
      "booking.confirm.to": "To",
      "booking.confirmation.button": "Confirm and create",
      "booking.confirmation.title": "Check your reservation",
      "booking.conformation.no.address": "Display pick-up zone",
      "booking.create": "Find my GETAWAY",
      "booking.create.success": "Thanks! We've received your booking request. We'll search for your perfect GETAWAY.",
      "booking.details.header.descr": "As soon as the reservation starts, you have 30 min time to unlock the vehicle with your smartphone.",
      "booking.details.section.title": "Your reservation",
      "booking.driver.accepted.title": "enjoy your GETAWAY!",
      "booking.driver.cancel": "Cancel booking",
      "booking.driver.cancel.dialog.message": "Canceling this booking results in removing your booking request.",
      "booking.driver.cancel.dialog.title": "Cancel booking",
      "booking.driver.cancel.dialog.yes": "Cancel now",
      "booking.driver.cancel.success": "Your reservation has been cancelled.",
      "booking.driver.confirm.dialog.message": "Good choice! With your confirmation, we'll reserve this lovely GETAWAY for you.",
      "booking.driver.confirm.dialog.title": "Reserve that GETAWAY",
      "booking.driver.confirm.dialog.yes": "Reserve now",
      "booking.driver.details.description": "As soon as we’ve the right car for you, we’ll contact you.",
      "booking.driver.details.header.description": "Please be lenient with this service. This feature is in testing mode. We look forward to your feedback.",
      "booking.driver.details.header.title": "Find your perfect GETAWAY! [BETA]",
      "booking.driver.details.text": "Pricing (incl. fuel) based on assumed duration and mileage. Final pricing is calculated pay-as-you-drive based on actual duration and mileage.",
      "booking.driver.details.title": "Your Reservation",
      "booking.driver.nooffers": "✨\nAvailable GETAWAYs \nwill be listed here soon.",
      "booking.driver.offer.request": "Request",
      "booking.driver.offer.reserve": "Reserve",
      "booking.driver.offer.reserving": "Reserving...",
      "booking.driver.offered.title": "choose your perfect GETAWAY!",
      "booking.driver.waitingoffer": "Waiting offer",
      "booking.end": "Until",
      "booking.fuelCardMissing": "Technical issues",
      "booking.haveBlockedPaymentIfPayInOnly": "A deposit using payment method PayIn must be paid before Rental.",
      "booking.header.in": "In",
      "booking.headline.expired": "Reservation has expired",
      "booking.headline.reservation": "reservation information",
      "booking.headline.started": "Reservation has started",
      "booking.label.checked": "check-in finished",
      "booking.label.expired": "Expired",
      "booking.label.started": "Started",
      "booking.label.waiting.checkin": "check-in in progress",
      "booking.map.bar.text": "Your next reservation:",
      "booking.mileage.placeholder": "I don't know, yet",
      "booking.mileage.title": "Approx. mileage",
      "booking.online": "Technical issues",
      "booking.payByLink.description": "Complete the payment and return to the app after. This service is being offered by GETAWAY GmbH on behalf of the \"Share die Robbe\".",
      "booking.payin.blockPayment.description": "By reserving this vehicle, a deposit of 75€ will be blocked on your card/account, which will be released after clearing with the rental costs after the rental has been finished.",
      "booking.payin.blockPayment.title": "Deposit for pay per link payment",
      "booking.payin.blockPayment.yes.text": "Continue",
      "booking.places.current": "Current location",
      "booking.places.search": "Enter address",
      "booking.places.title": "From where",
      "booking.preferredcar.title": "Car",
      "booking.requestedCar.title": "Vehicle",
      "booking.start": "From",
      "booking.start.address": "From where",
      "booking.userMarkedForFraud": "Payment failed",
      "booking.warning.payin.button": "Pay deposit now",
      "booking.warning.payin.text": "Now leave the deposit to complete the reservation. Otherwise, it will be canceled in a few minutes.\n\nAfter the rental process, the deposit will be refunded to you after all rental costs have been offset.",
      "booking.warning.payin.text.payed": "You've successfully left the deposit. wait for your rental",
      "booking.warning.payin.title": "Kaution",
      "booking.warning.payin.title.payed": "Well done!",
      "booking.warning.payin.value": "75,00 Euro",
      "bookings.headline.booked": "Booked",
      "bookings.headline.reservation": "Reserved",
      "bookings.profile.cancel.text": "Cancel Booking",
      "bookings.profile.title": "My Bookings",
      "bookings.where.desc": "The excact vehicle's location will be shared 30 min before the reservation starts.",
      "brand.getaway.name": "GETAWAY",
      "brand.mis.name": "Miet Smart",
      "brand.msi.name": "Miet Smart",
      "brand.rw.name": "Share die Robbe",
      "brand.ubq.name": "ubeeqo",
      "call": "Call",
      "callout.show.availability": "Verfügbarkeit anzeigen",
      "camera.addressProof.upload": "Upload",
      "camera.permission.details": "Please grant GETAWAY access to your camera to allow the app to take photos.",
      "camera.permission.no": "Later",
      "camera.permission.title": "Access Camera",
      "camera.permission.yes": "Allow",
      "cancel": "Cancel",
      "cancel.FirstSteps.dialog.message": "You need only 2 Minutes: Complete your registration and start your first rental.",
      "cancel.FirstSteps.dialog.message.noBonus": "You need only 2 Minutes: Complete your registration and start your first rental.",
      "cancel.FirstSteps.dialog.no": "Cancel",
      "cancel.FirstSteps.dialog.yes": "Not now",
      "cancel.rental.handover": "Do you want to finish the current rental?",
      "car.approximateMileage.gt250k": "above 250.000 km",
      "car.approximateMileage.lt100k": "50.000 - 100.000 km",
      "car.approximateMileage.lt150k": "100.000 - 150.000 km",
      "car.approximateMileage.lt200k": "150.000 - 200.000 km",
      "car.approximateMileage.lt250k": "200.000 - 250.000 km",
      "car.approximateMileage.lt50k": "below 50,000 km",
      "car.available.book": "RENT IT",
      "car.co2.text": "Kg of saved CO2",
      "car.damage": "Damage",
      "car.damage.create.dialog.title": "Add Damage",
      "car.damage.edit.notallowed.description": "Editing car damages while car is rented is not allowed, please contact support if needed.",
      "car.damage.edit.notallowed.title": "Editing not possible",
      "car.damage.remove.dialog.remove": "Remove this damage",
      "car.damage.remove.dialog.title": "Are you sure?",
      "car.damages": "Damages",
      "car.damages.photos.dialog.description": "Pleas add at least on photo of this damage in order to report it correctly.",
      "car.damages.photos.dialog.title": "Photo of the damage",
      "car.data.title": "Car Info",
      "car.demo.unavailable.messege": "Please go the App Store and update your app version to rent this car.",
      "car.demo.unavailable.title": "Car Unavailable",
      "car.details.available.until.placeholder": "Available until",
      "car.details.car.info": "CAR INFO",
      "car.details.carplate.placeholder": "Plate number",
      "car.details.damages": "Damages",
      "car.details.equipment": "EQUIPMENT",
      "car.details.fuel.diesel": "Diesel",
      "car.details.fuel.electric": "Electric",
      "car.details.fuel.hybrid": "Hybrid",
      "car.details.fuel.hybrid_diesel": "Hybrid/Diesel",
      "car.details.fuel.hybrid_unleaded95": "Hybrid/Benzin 95",
      "car.details.fuel.hybrid_unleaded98": "Hybrid/Benzin 98",
      "car.details.fuel.lpg": "LPG",
      "car.details.fuel.other": "Other",
      "car.details.fuel.placeholder": "Fuel",
      "car.details.fuel.unleaded95": "Unleaded 95",
      "car.details.fuel.unleaded98": "Unleaded 98",
      "car.details.gear.automatic": "Automatic",
      "car.details.gear.manual": "Manual",
      "car.details.gear.type.placeholder": "Gear",
      "car.details.height": "Loading height",
      "car.details.initial.registration.placeholder": "Initial registration date",
      "car.details.length": "Loading length",
      "car.details.list.damages.placeholder": "Listed Damages",
      "car.details.listofdamages.title": "LIST OF DAMAGES",
      "car.details.loadCapacity": "Payload",
      "car.details.lock.text": "LOCK",
      "car.details.mileage.gt100": "More than 100.000 km",
      "car.details.mileage.lt100": "Less than 100.000 km",
      "car.details.mileage.lt20": "Less than 20.000 km",
      "car.details.mileage.lt50": "Less than 50.000 km",
      "car.details.mileage.placeholder": "Milage",
      "car.details.pickupreturn.placeholder": "Location",
      "car.details.pollution.placeholder": "Pollution Badge",
      "car.details.pos.reviews": "Positive Reviews",
      "car.details.reviews": "reviews",
      "car.details.techincaldata": "Technical Data",
      "car.details.uhr": "Clock",
      "car.details.unlock.text": "Start rental",
      "car.details.unlock.text.brand": "Start rental",
      "car.details.unlocking.text": "Unlocking...",
      "car.details.width": "Loading width",
      "car.device.doors.open": "Please make sure that all doors are closed.",
      "car.device.fuel.card.missing": "Please return the fuel card to the box in the glove compartment.",
      "car.device.keys.missing": "Please make sure the car keys are in the box in the glove compartment.",
      "car.device.windows.open": "Please make sure that all windows are closed.",
      "car.equipment.abs": "ABS",
      "car.equipment.adaptiveLighting": "Adaptive lighting",
      "car.equipment.airConditioning": "Air conditioning",
      "car.equipment.alloyWheels": "Alloy wheels",
      "car.equipment.auxiliaryHeating": "Auxiliary heating",
      "car.equipment.childSeat": "Child seat",
      "car.equipment.collisionAvoidanceSystem": "Collision avoidance system",
      "car.equipment.cruiseControl": "Cruise control",
      "car.equipment.disabledAccessible": "Disabled accessible",
      "car.equipment.distanceControlAssistSystem": "Distance control assist system",
      "car.equipment.electricHeatedSeats": "Electric heated seats",
      "car.equipment.esp": "ESP",
      "car.equipment.feature": "Features",
      "car.equipment.fourWheelDrive": "Four wheel drive",
      "car.equipment.handsFreeKit": "Hands-free kit",
      "car.equipment.interior": "Interior",
      "car.equipment.laneDepartureWarningSystem": "Lane departure warning system",
      "car.equipment.multifunctionalWheel": "Multifunctional wheel",
      "car.equipment.navigationSystem": "Navigation system",
      "car.equipment.panoramicRoof": "Panoramic roof",
      "car.equipment.parkingAssistSystem": "Parking assist system",
      "car.equipment.powerAssistedSteering": "Power assisted steering",
      "car.equipment.rainSensor": "Rain sensor",
      "car.equipment.roofBars": "Roof bars",
      "car.equipment.safety": "Safety",
      "car.equipment.startStopSystem": "Start-stop system",
      "car.equipment.sunroof": "Sunroof",
      "car.equipment.tractionControl": "Traction control",
      "car.equipment.trailerCoupling": "Trailer coupling",
      "car.feedback.text": "Love your car",
      "car.findNearest.notExist": "no cars available nearby, try another timeframe or change the map zoom.",
      "car.handover.failed.description.unlock": "Unlock the car if necessary and restart the process again.",
      "car.handover.failed.title": "Failed to proceed",
      "car.handover.manual.fuelLevel.description": "Nobody wants to receive a car with an almost empty fuel tank. Please confirm that the car’s fuel level is above 25%.",
      "car.handover.manual.fuelLevel.title": "Fuel level sufficient?",
      "car.handover.nodamages": "No reported damages",
      "car.insurance": "Insurance coverage",
      "car.owner.dialog.rented.message": "Your car is being rented. You will receive an email once the rental is finished.",
      "car.owner.dialog.rented.title": "Car is being rented",
      "car.pollution.green": "Green",
      "car.pollution.nobadge": "Without badge",
      "car.pollution.red": "Red",
      "car.pollution.unknown": "Unknown",
      "car.pollution.yellow": "Yellow",
      "car.price": "%@ € per km",
      "car.price.desc": "This is where you tell us how much you want to earn. You can also adjust this anytime later.",
      "car.price.footer": "*This is a non-binding estimation. Your earnings ultimatively depend on the availability and desirability of your car.",
      "car.price.high": "Too expensive",
      "car.price.low": "Too cheap",
      "car.price.title": "Set a price per km",
      "car.price.update": "SAVE",
      "car.profile.blockReasons.label": "Downtime reason(s):",
      "car.profile.brand": "Make",
      "car.profile.damages": "Damages And Defects",
      "car.profile.description": "Edit your car profile",
      "car.profile.dimensions.note": "Dimentions can slightly vary, When not sure please pay extra attention to the vehicle dimension and always aim for some extra space.",
      "car.profile.engine": "Engine power",
      "car.profile.equipment": "Equipment",
      "car.profile.history.date.interval.to": "to",
      "car.profile.instructions": "Check out our %@ how to increase your rental earnings.",
      "car.profile.instructions.url": "https://service.get-a-way.com/wie-vermiete-ich-mein-auto#mein-autoprofil",
      "car.profile.instructions.url.text": "help center",
      "car.profile.lastCleaning.label": "Last cleaning:",
      "car.profile.model": "Model",
      "car.profile.price.details": " https://robbe.app/feetable",
      "car.profile.price.message": "Circa %@ € per month*",
      "car.profile.price.per.km": "Your earnings per km",
      "car.profile.rental.description": "Rental History",
      "car.profile.rental.history": "RENTAL HISTORY",
      "car.profile.rented.back": "Returns latest,",
      "car.profile.terminate": "TERMINATE CAR PROFILE",
      "car.profile.view": "View your car profile",
      "car.registration.camera.description": "Make sure the fully opened car registration front side is positioned within the frame.",
      "car.registration.photo.description": "(fully opened)",
      "car.registration.photo.title": "front of the car registration license",
      "car.release.ignition.message": "Please turn of the engine to release your car.",
      "car.release.ignition.title": "Cannot release car",
      "car.released": "Your car has been released.",
      "car.released.error": "Releasing car error",
      "car.rental.cancelled": "Your reservation has been cancelled.",
      "car.rental.cancelled.time": "Your booking has been cancelled because you haven't unlocked car on time.",
      "car.rental.count": "Rentals",
      "car.rental.rental": "Rental",
      "car.rental.rentals": "Rentals",
      "car.rental.trips": "TRIPS",
      "car.rentals.title": "Rentals",
      "car.rented.back": "Back on",
      "car.rented.backtoday": "Back at",
      "car.rented.backtomorrow": "Back tomorrow at",
      "car.reserve.blockPayment.description": "By reserving this vehicle, a deposit of 50€ will be blocked on your credit card, which will be released after clearing with the rental costs after the rental has been finished.",
      "car.reserve.blockPayment.title": "Deposit for credit card payment",
      "car.reserve.blockPayment.yes.text": "Continue",
      "car.reserve.carBooked.description": "This vehicle has a planned booking and the driver expect to pick it up by then. late return fees might apply.",
      "car.reserve.carBooked.title": "Vehicle Should be returned in %s .",
      "car.reserve.carBooked.yes.text": "Agree",
      "car.review.title": "Reviews",
      "car.search.notExist": "This car does not exist.",
      "car.service.count": "Service trips",
      "car.status.not.found": "Could not read car status",
      "car.submit.approximateMileage": "Approximate mileage",
      "car.submit.footer": "I hereby confirm, that this car has a valid vehicle insurance and general inspection certificate.",
      "car.submit.leased": "I am leasing the car",
      "car.submit.legalnotice": "I hereby confirm, that this car has a valid vehicle insurance and general inspection certificate and allow GETAWAY to proceed a credit rating check.",
      "car.submit.legalnotice.url": "https://getaway-docs.s3.eu-central-1.amazonaws.com/de-DE/Getaway_DataPrivacy-SchufaChecks-de.pdf",
      "car.submit.legalnotice.url.text": "credit rating check",
      "car.submit.notowner": "I am not the owner",
      "car.submit.owner": "I am the owner",
      "car.submit.ownership": "Who owns the car?",
      "car.submit.ownership.description": "If you don't own the car, you need to hand in letter of attorney later. A template will be provided.",
      "car.terminate.dialog.message": "Please contact our service team to remove your car's profile.",
      "car.terminate.dialog.title": "Terminate my car",
      "car.title": "YOUR CAR",
      "car.transaction.title": "Rental income %@",
      "car.unlock.bluetooth.disabled": "Enable bluetooth®\nto unlock",
      "car.unlock.bluetooth.disabled.brand": "Enable bluetooth®",
      "car.unlock.bluetooth.searching": "Reduce distance\nto unlock",
      "car.unlock.bluetooth.searching.brand": "Reduce distance",
      "car.unlock.closeToCar.action": "I am at the vehicle - unlock now",
      "car.unlock.tooFar.message": "Unable to unlock the car from this distance. Please try again once you are closer.\n\nElse please contact our ServiceTeam via the chat icon on the top-right of your screen.",
      "car.unlock.tooFar.title": "Please reduce distance",
      "car.unreleased": "Your car has been unreleased.",
      "car.unreleased.error": "Unreleasing car error. Please try again later.",
      "carCheck.addImage.labelFour": "Back, right",
      "carCheck.addImage.labelOne": "front, left",
      "carCheck.addImage.labelThree": "Back, left",
      "carCheck.addImage.labelTwo": "front, right",
      "card.number": "card number",
      "carprofile.footnote": "Check out our %@ how to increase your rental earnings.",
      "carprofile.footnote.url": "https://service.get-a-way.com/wie-vermiete-ich-mein-auto#mein-autoprofil",
      "carprofile.footnote.url.text": "help center",
      "carprofile.troubleshoot.description": "Your car's engine doesn't start? Follow the link to unlock the immobilizer.",
      "carprofile.troubleshoot.title": "Manage immobilizer",
      "cars.carousel.title": "Find your perfect GETAWAY!",
      "challenges.completed.section": "COMPLETED CHALLENGES",
      "challenges.firstSteps.addPayment.text": "To verify your user account",
      "challenges.firstSteps.addPayment.title": "Save a payment method",
      "challenges.firstSteps.bonusTransactionReason": "First Steps",
      "challenges.firstSteps.createAccount.text": "Well done! You've successfully signed up",
      "challenges.firstSteps.createAccount.title": "Signup for GETAWAY",
      "challenges.firstSteps.description": "This is a great way to start your journey with GETAWAY. Complete these first steps and get %s€ bonus from us!",
      "challenges.firstSteps.firstRental.text": "Take your first ride with GETAWAY",
      "challenges.firstSteps.firstRental.title": "Take a ride",
      "challenges.firstSteps.listCar.text": "Make money with your car!",
      "challenges.firstSteps.listCar.title": "List your car",
      "challenges.firstSteps.saveAddress.text": "Easy address saving",
      "challenges.firstSteps.saveAddress.title": "Save your registered address",
      "challenges.firstSteps.title": "First Steps",
      "challenges.firstSteps.verifyDriversLicense.text": "To get access to the fleet of cars",
      "challenges.firstSteps.verifyDriversLicense.title": "Verify your driving license",
      "challenges.firstSteps.verifyPhoneNumber.text": "To convert your smartphone into a car key",
      "challenges.firstSteps.verifyPhoneNumber.title": "Confirm your phone number",
      "challenges.inviteFriends.bonusTransactionReason": "User referral",
      "challenges.inviteFriends.description": "Earn 10€ for each invited friend. At the same time your friend will receive 10€ welcome credit upon signing up.",
      "challenges.inviteFriends.title": "Invite 5 friends",
      "challenges.up.to": "Earn up to",
      "change": "Change",
      "change.password.success": "Your password has been successfully updated.",
      "circle.brand_not_resolved": "Cannot resolve circle's brand",
      "circles.button.text": "CONFIRM",
      "circles.input.placeholder": "Enter invitation code",
      "circles.join.success.msg": "You have special access in vehicles and prices! ",
      "circles.join.success.title": "You just joined a circle.",
      "clear.damages": "Clear damages",
      "clear.damages.prompt.text": "You are about to clear all car's damages",
      "clipboard.link.copied": "Invitation link was copied to clipboard.",
      "confirm": "Confirm",
      "contacts.invite.contact": "INVITE %d CONTACT",
      "contacts.invite.contact.many": "INVITE %d CONTACTS",
      "contacts.invite.earn": "You can earn up to %@",
      "contacts.permission.details": "Please grant GETAWAY access to your contacts to allow the app to send invitations.",
      "contacts.permission.no": "Later",
      "contacts.permission.title": "Access contacts",
      "contacts.permission.yes": "Allow",
      "continue": "Continue",
      "creditcheck.terms": "To verify my payment method, I hereby agree to proceed a credit rating check.",
      "creditcheck.terms.highlight": "credit rating check",
      "creditcheck.terms.url": "https://getaway-docs.s3.eu-central-1.amazonaws.com/de-DE/Getaway_DataPrivacy-SchufaChecks-de.pdf",
      "custom.camera.description.car.registration": "Please place your car registration certificate on a neutral surface while making sure its fully in the frame and take a photo.",
      "custom.camera.description.damage": "Please make sure the damage is visible and centered in the frame.",
      "custom.camera.description.license": "Please place your driving license on a neutral surface within the outlined square and take a photo.",
      "custom.camera.description.selfie": "Please hold down the record button for 3 seconds.",
      "custom.camera.holdmessage.selfie": "Hold down the record button for 3 seconds to submit your selfie.",
      "custom.camera.repeat": "Retake",
      "custom.camera.submit": "Apply",
      "custom.camera.title.car.registration": "Car Registration Certificate",
      "custom.camera.title.damage": "Damage Photo",
      "custom.camera.title.license.back": "Driving License back",
      "custom.camera.title.license.front": "Driving License front",
      "custom.camera.title.selfie": "Selfie",
      "damage.add.noImages.error": "Please make sure that you added two pictures at least",
      "damage.add.success": "Thank you for reporting a damage! ",
      "damage.detials.reportedon": "Reported on:",
      "damage.new": "NEW",
      "damage.new.notification.description": "Thanks for updating the damage records!",
      "damages.add": "Report damage",
      "damages.add.button.text": "Add damage",
      "damages.add.error.noimages": "Please add photos of the damage before submitting",
      "damages.add.image.note": "At least two photos (different angles / distances). The license plate must be recognizable on one photo.",
      "damages.bumper": "Stoßstange",
      "damages.burn": "Brandfleck",
      "damages.carRoadworthy": "Is the vehicle roadworthy?",
      "damages.characteristic": "Characteristics",
      "damages.characteristic.freeText": "other",
      "damages.characteristics.burn": "Burn",
      "damages.characteristics.crack": "Crack",
      "damages.characteristics.deepScratch": "Deep scratch",
      "damages.characteristics.deepscratch": "Deep scratch",
      "damages.characteristics.dent": "Dent",
      "damages.characteristics.dirt": "Dirt",
      "damages.characteristics.missing": "Missing",
      "damages.characteristics.other": "Other",
      "damages.characteristics.permanentDirt": "Stubborn dirt",
      "damages.characteristics.scratch": "Scratch",
      "damages.characteristics.stoneChip": "Stone chip",
      "damages.chars.left": "characters left",
      "damages.component": "Component",
      "damages.component.bumper": "Bumper",
      "damages.component.door": "Door",
      "damages.component.engineBonnet": "Engine bonnet",
      "damages.component.exteriorMirror": "Mirror",
      "damages.component.floor": "Floor",
      "damages.component.hubcap": "Hubcap",
      "damages.component.interiorTrim": "Interior trim",
      "damages.component.other": "Other",
      "damages.component.rearWindow": "Window",
      "damages.component.seat": "Seat",
      "damages.component.sill": "Sill",
      "damages.component.trunk": "Trunk/ bed",
      "damages.component.wheelRim": "Rim",
      "damages.component.windscreen": "Windscreen",
      "damages.component.wing": "Wing",
      "damages.confirmRecord.button.text": "Confirm damage record",
      "damages.crack": "Riss",
      "damages.damage.desc": "Damage description",
      "damages.deepscratch": "Kratzer",
      "damages.dent": "Delle",
      "damages.description": "Schadensbeschreibung",
      "damages.dirt": "Verschmutzung",
      "damages.door": "Tür",
      "damages.engine.bonnet": "Motorhaube",
      "damages.exterior": "Außen",
      "damages.exterior.mirror": "Spiegel",
      "damages.floor": "Boden",
      "damages.gt10cm": ">10 cm",
      "damages.hubcap": "Radkappe",
      "damages.interior": "Innen",
      "damages.location": "Location",
      "damages.location.front": "Vorderseite",
      "damages.location.na": "N/A",
      "damages.location.rear": "Rückseite",
      "damages.location.side.center": "Mitte",
      "damages.location.side.codriver": "Beifahrerseite",
      "damages.location.side.driver": "Fahrerseite",
      "damages.lt10cm": "5 - 10cm",
      "damages.lt5cm": "<5 cm",
      "damages.new.photos.add": "Add photos",
      "damages.noRecord.text": "No damge records",
      "damages.other": "Anderes",
      "damages.pernament.dirt": "Dauerhafte Verschmutzung",
      "damages.rear.window": "Fenster",
      "damages.scratch": "Kratzer",
      "damages.seat": "Sitz",
      "damages.side": "Side",
      "damages.side.center": "Center",
      "damages.side.coDriversSide": "Co-Driver's side",
      "damages.side.driversSide": "Driver's side",
      "damages.sill": "Schweller",
      "damages.size": "Size",
      "damages.size.gt10cm": ">10cm",
      "damages.size.lt10cm": "5 - 10cm",
      "damages.size.lt5cm": "<5cm",
      "damages.stonechip": "Steinschlang",
      "damages.thirdParty": "Third party involment",
      "damages.thirdParty.carRoadworthyDontKnow": "I don‘t know",
      "damages.thirdParty.dontKnow": "I don’t know",
      "damages.thirdParty.isInvolved": "Third-party/ies is/are involved",
      "damages.thirdParty.notInvolved": "Third-party/ies is/are not involved",
      "damages.thirdParty.notRoadworthy": "Not-roadworthy",
      "damages.thirdParty.roadworthy": "Roadworthy",
      "damages.title": "Damage records",
      "damages.toolbar.done": "Done",
      "damages.toolbar.next": "Next",
      "damages.trim": "Innenverkleidung",
      "damages.trunk": "Kofferraum",
      "damages.wheel.rim": "Felge",
      "damages.where": "Where",
      "damages.where.exterior": "Exterior",
      "damages.where.interior": "Interior",
      "damages.windscreen": "Frontscheibe",
      "damages.wing": "Kotflügel",
      "dashboard.about": "About",
      "dashboard.balance": "Payments",
      "dashboard.carprofile": "Car profile",
      "dashboard.dialog": "Copyright page",
      "dashboard.dialog.getaway": "GETAWAY",
      "dashboard.dialog.getaway.url": "https://getaway.app/imprint",
      "dashboard.dialog.interrent.url": "https://www.interrent.com/de/rechtliche-hinweise",
      "dashboard.dialog.msi.url": "https://www.mietsmart.com/about/",
      "dashboard.dialog.privacy": "Privacy Policy",
      "dashboard.dialog.rw": "Copyright page",
      "dashboard.dialog.rw.url": "https://robbe.app/imprint",
      "dashboard.dialog.terms": "Terms of Service",
      "dashboard.dialog.ubq": "Copyright page",
      "dashboard.dialog.ubq.url": "https://ubq.app/imprint",
      "dashboard.freecredit": "Get free balance",
      "dashboard.headline": "Hello 👋\n%@ %@",
      "dashboard.help": "Help Center",
      "dashboard.hi": "Hi",
      "dashboard.list.car": "Check how much your car could earn.",
      "dashboard.list.cars": "List another car",
      "dashboard.logout": "LOGOUT",
      "dashboard.logout.active.rental.prompt": "Please end your rental before logging out.",
      "dashboard.logout.prompt": "Are you sure you want to logout?",
      "dashboard.logout.success": "You have been successfully logged out.",
      "dashboard.myRentals": "Rentals",
      "dashboard.profile": "User account",
      "dashboard.releasing": "Releasing...",
      "dashboard.review": "Review this app",
      "dashboard.services": "Services orders",
      "dashboard.settings": "SETTINGS",
      "day": "day",
      "day.ago": "days ago",
      "day.short": "Day",
      "days": "days",
      "days.ago": "days ago",
      "days.short": "Days",
      "delete": "delete",
      "description": "Description",
      "dialog.later": "Not now",
      "direct.debit": "Direct debit",
      "direct.debit.account.bic": "Bank number",
      "direct.debit.account.number": "Account number",
      "direct.debit.add": "ADD",
      "direct.debit.agreements.less": "I consent to processing all necessary data to GETAWAY GmbH (creditor identifier DE62ZZZ00002030615) for the procedure of payments and related credit- and identity checks. The data protection rules of GETAWAY are applicable. I grant GETAWAY a SEPA Direct Debit mandate and authorise GETAWAY to send instructions to my financial institution to debit my account for collecting due payments and authorise my financial institution to debit my account in accordance with the instructions from GETAWAY [German version applicable]. ...Less ↑",
      "direct.debit.agreements.more": "I consent to processing all necessary data to GETAWAY GmbH (creditor identifier DE62ZZZ00002030615) for the procedure of payments (Mehr ↓) and related credit- and identity checks. The data protection rules ...",
      "direct.debit.desc": "Enter your account data to easily setup direct debit as a payment method.",
      "direct.debit.iban": "IBAN",
      "direct.debit.title": "Add direct debit",
      "directdebit": "Direct Debit",
      "disconnected.dialog.message": "It seems like you are not connected to the internet. Please make sure to enable your internet connection to use the app.",
      "disconnected.dialog.title": "You are offline",
      "distance": "Distance",
      "document.upload.success": "Uploaded successfuly",
      "done": "Done",
      "dongle.rental.fuelSettings.instruction": "You do not have to keep the invoice. After fueling please put the fuel card back in the booklet together with the vehicle documents.",
      "dongle.rental.section.locked": "Given back",
      "dongle.rental.section.unlocked": "Taken over",
      "driver.id.status.open": "Add Identity card",
      "driver.id.status.verified": "Identity card",
      "driver.id.status.verifying": "Verifying Identity card",
      "driver.license.banned.failure": "Please enter the starting and ending date of the ban before submitting",
      "driver.license.banned.update.button": "UPDATE",
      "driver.license.banned.update.date.button": "CHOOSE",
      "driver.license.banned.update.description": "I'm banned from driving for legal reasons between the dates entered below.",
      "driver.license.banned.update.end": "Ending date",
      "driver.license.banned.update.start": "Starting date",
      "driver.license.banned.update.title": "Banned from driving",
      "driver.license.banned.warning": "Please enter the starting date first",
      "driver.license.bottom.description": "By submitting this I acknowledge that if my driving license is held by the authorities or if I am not legally allowed to drive for any reason I will change the status of this document.",
      "driver.license.frontImage": "Front Image",
      "driver.license.info.desc": "In order to unlock any car around you, please verify your driving license.",
      "driver.license.info.save": "CONFIRM",
      "driver.license.info.title": "Your driving License",
      "driver.license.notuploaded.description": "Please verify your driver license",
      "driver.license.open.description": "Submitted on %@",
      "driver.license.rearImage": "Rear Image",
      "driver.license.selfieImage": "Your Selfie",
      "driver.license.status.deleted": "Declined, please verify again",
      "driver.license.status.notuploaded": "Add driver's license",
      "driver.license.status.open": "Verifying...",
      "driver.license.status.paused": "Banned",
      "driver.license.status.verified": "Driving license",
      "driver.license.status.verifying": "Verifying driving license",
      "driver.license.submit.failed": "Please attach all required photos to continue.",
      "driver.license.submit.notNow": "Please get your license verified to rent cars nearby.",
      "driver.license.submit.success": "Thanks for submitting your license. We will contact you after the check.",
      "driver.license.submited.description": "Submitted on %@",
      "driver.license.title": "Driving license",
      "driver.license.top.description": "Submit your driving license for verification by taking photos of it's front, rear and a selfi.",
      "driver.license.verified.description": "Update your driving license",
      "driver.mis.price.description": "{0} €/Day + {1} €/Extra Km",
      "driver.mis.price.map.description": "€/Day",
      "driver.mis.spain.price.description": "{0} €/Day",
      "driver.mis.spain.price.map.description": "€/Day",
      "driver.mis.transporter.price.description": "{0} €/Hr + {1} €/Extra Km",
      "driver.mis.transporter.price.map.description": "€/Hr",
      "driver.price.description": "{0} €/Hr + {1} €/km",
      "driver.price.ikea.description": "{0} €/hr + {1} €/km",
      "driver.price.ikea.map.description": "€/hr",
      "driver.price.map.description": "€/Hr",
      "driver.price.obi.description": "{0} €/Hr. + {1} €/excess km",
      "drivercar.profile.priceDetails": "price info",
      "drivercar.profile.request.action": "Request",
      "drivercar.profile.reserve.action": "Book now",
      "drivercar.profile.service.reserve.action": "Start service",
      "drivercar.rental.dialog.message": "Do you want to rent this car for personal use or to run a GETAWAY service? ",
      "drivercar.rental.dialog.reserve.rental": "Continue for personal use",
      "drivercar.rental.dialog.reserve.service": "Continue as service trip",
      "drivercar.rental.dialog.title": "Please choose your driving profile",
      "drivercar.reserved.status": "RESERVED",
      "drivercar.review.novalue": "✨ New car",
      "drivercar.unavailable.status": "Currently in use",
      "driving.damage.candrive": "Car can still be driven.",
      "driving.damage.cantdrive": "Car can't be driven anymore.",
      "driving.finish.rental.dialog.message": "Please turn of the engine to finish this rental.",
      "driving.finish.rental.dialog.retry": "Retry ",
      "driving.finish.rental.dialog.title": "Cannot finish",
      "driving.refuel.dialog.message": "Please turn of the engine to refuel the car.",
      "driving.refuel.dialog.title": "Refueling not possible",
      "drivinglicense.form.selfie.description": "Record a short selfie video",
      "drivinglicense.form.selfie.done.dialog.again": "Record again",
      "drivinglicense.form.selfie.done.dialog.message": "Your selfie is required to verify your driver license.",
      "drivinglicense.form.selfie.done.dialog.title": "Upload selfie",
      "duration": "Duration",
      "enjoyYourTrip.location.subtitle": "Pick-up & Return Location",
      "enjoyYourTrip.title": "Enjoy your trip!",
      "equipment.4wd": "Four wheel drive",
      "equipment.abs": "ABS",
      "equipment.aircondition": "Air conditioning",
      "equipment.childseat": "Child seat",
      "equipment.collosion.avoidance": "Collision avoidance system",
      "equipment.cruise": "Cruise control",
      "equipment.disabled": "Disabled accessible",
      "equipment.esp": "ESP",
      "equipment.handsfree": "Hands-free kit",
      "equipment.heating.aux": "Auxiliary heating",
      "equipment.heating.seat": "Electric heated seats",
      "equipment.light.adaptive": "Adaptive lighting",
      "equipment.multifunctionalwheel": "Multifunctional wheel",
      "equipment.navigation": "Navigation system",
      "equipment.parking.assistant": "Parking assist system",
      "equipment.powersteering": "Power assisted steering",
      "equipment.roof.bars": "Roof bars",
      "equipment.roof.panorama": "Panoramic roof",
      "equipment.sensor.lane": "Lane departure warning system",
      "equipment.sensor.proximity": "Distance control assist system",
      "equipment.sensor.rain": "Rain sensor",
      "equipment.start-stop": "Start-stop system",
      "equipment.sunroof": "Sunroof",
      "equipment.traction": "Traction control",
      "equipment.trailor": "Trailer coupling",
      "equipment.wheels.alloy": "Alloy wheels",
      "error": "Something went wrong. Please try again. Thanks!",
      "feature.gate.register.title": "UNLOCK YOUR AREA",
      "feature.gate.register.url": "https://www.get-a-way.com/",
      "feature.gate.start": "2018-01-18",
      "feature.gate.submit": "START",
      "feature.gate.zip.error": "This postal code is not yet activated, get an invitation code now.",
      "feature.gate.zipcodes": "10589",
      "fees.url": "https://get-a-way.com/entgelte",
      "finish.rental.refreshButton": "Refresh",
      "finished.rental.carkey.description": "Please make sure that the car key is in the glove compartment.",
      "finished.rental.carkey.title": "Car key",
      "finished.rental.checkBelongings.button": "FINISHED",
      "finished.rental.checkBelongings.title": "Check for personal belongings.",
      "finished.rental.doors.description": "Please make sure that all the doors \nand windows are closed",
      "finished.rental.doors.title": "Doors & windows",
      "finished.rental.fuelLevel.description": "Please refuel the vehicle (free with provided fuel card) before finishing the rental. Else fees (<25%: 5€; <10%: 50€) might apply.",
      "finished.rental.fuelLevel.title": "Low fuel level",
      "finished.rental.fuelcard.description": "Please return the fuel card to the box in the glove compartment.",
      "finished.rental.fuelcard.title": "Fuel card",
      "finished.rental.location.button": "GET DIRECTIONS",
      "finished.rental.location.description": "Too far from the return area! Please check the return area or finish the rental regardless; Fees may apply.",
      "finished.rental.location.force": "Force finish",
      "finished.rental.location.title": "Return area",
      "finished.rental.locked.button": "LOCK",
      "finished.rental.locked.description": "Please lock the car from the outside to continue and make sure all doors and windows are locked.",
      "finished.rental.locked.title": "Lock vehicle from outside",
      "finished.rental.thank.you.button": "YES",
      "finished.rental.thank.you.description": "Please take the key from the glove \ncompartment.",
      "firstSteps.address.city.placeholder": "City",
      "firstSteps.address.country.placeholder": "Country",
      "firstSteps.address.instructions": "Please note that this must be your current registration address.",
      "firstSteps.address.personalInfo": "Personal Information",
      "firstSteps.address.postalCode.placeholder": "Post code",
      "firstSteps.address.search.text": "Search address",
      "firstSteps.address.streetName.placeholder": "Street",
      "firstSteps.address.streetNum.placeholder": "No.",
      "firstSteps.address.subTitle": "Address",
      "firstSteps.address.submitBtn.text": "Add",
      "firstSteps.address.title": "Registration address",
      "firstSteps.payment.sepa.description.text": "I consent to processing all necessary data to GETAWAY GmbH (creditor identifier DE62ZZZ00002030615) for the procedure of payments and related credit- and identity checks. The data protection rules of GETAWAY are applicable. I grant GETAWAY a SEPA Direct Debit mandate and authorise GETAWAY to send instructions to my financial institution to debit my account for collecting due payments and authorise my financial institution to debit my account in accordance with the instructions from GETAWAY (German version applies).",
      "firstSteps.payment.sepa.iban.placeholder": "IBAN",
      "firstSteps.payment.sepa.instruction": "Deposit your IBAN for secure deposits and withdrawals via SEPA Direct Debit.",
      "firstSteps.payment.sepa.name.placeholder": "Account holder",
      "firstSteps.payment.sepa.submit.text": "Add",
      "firstSteps.payment.sepa.title": "Add payment",
      "firstSteps.payment.sepa.url": "https://google.com",
      "firstSteps.payment.sepa.url.text": "More",
      "for": "detailed pricing please checkout %s",
      "from": "from",
      "fuelstation.ap.name": "AP",
      "fuelstation.aral.name": "ARAL",
      "fuelstation.aral.title": "Aral fuel station",
      "fuelstation.avanti.name": "Avanti",
      "fuelstation.bp.name": "BP",
      "fuelstation.eni.name": "Eni",
      "fuelstation.help.text": "help with fueling",
      "fuelstation.om.name": "OM",
      "fuelstation.omv.name": "OMV",
      "fuelstation.openallday": "opened 24h",
      "fuelstation.opennow": "Open now: ",
      "fuelstation.openstoday": "Opens today: ",
      "fuelstation.openstomorrow": "Opens tomorrow: ",
      "fuelstation.status.prefix": "fuel station",
      "fuelstation.total.name": "Total",
      "fuelstation.westfalen.name": "Westfalen",
      "handover.all.around.confirm": "Ignorieren & fortfahren",
      "handover.all.around.no": "Rundum-Fotos aufnehmen",
      "handover.all.around.warning": "Bitte bedenke, dass du verpflichtet bist, den Fahrzeugzustand beim Beenden der Miete mittels Bereitstellung der Rundum-Fotos zu bezeugen.",
      "handover.belonging.title": "All belongings taken",
      "handover.belongings.button.title": "I have my things out",
      "handover.belongings.decr": "Please make sure you have everything out of the car before continuing.",
      "handover.belongings.title": "Please check your belongings.",
      "handover.bottom.description": "You will find the car key in the glove compartment",
      "handover.carCheck.title": "All-around photos taken",
      "handover.card.title": "The fuel card has been returned",
      "handover.damages.count": "%d Damages",
      "handover.demages.description": "Listed damages",
      "handover.dongle.card.title": "put back fuel card",
      "handover.dongle.key.title": "Give back key",
      "handover.doorsWidows.title": "Doors and windows are closed",
      "handover.doorsWindows.title": "I have closed all doors and windows",
      "handover.engine.dialog.message": "Simply start the engine to confirm the handover check. You'll find the key in the glove compartment.",
      "handover.engine.dialog.title": "Start the engine",
      "handover.fuel.title": "I choose refuel service",
      "handover.fuelCard.button.title": "Refresh",
      "handover.fuelCard.decr": "Please make sure that the fuel card is in the glove compartment.",
      "handover.fuelCard.title": "Fuel card has been returned",
      "handover.key.title": "The key has been returned",
      "handover.location.button.title": "Show return location",
      "handover.location.decr": "Now the car is not in its return location. Please return it before finishing the rental.",
      "handover.location.option": "Drive yourself",
      "handover.location.title": "Return location",
      "handover.location.warning": "I am willing to pay 500 €",
      "handover.lock.button.title": "Lock the car",
      "handover.lock.locking": "Locking ...",
      "handover.lock.title": "Shut all doors and windows and lock the vehicle from outside",
      "handover.lowFuel.button.title": "Locate next gas station",
      "handover.lowFuel.decr": "Please refuel the car before finishing the rental. Contact our service team if you need help.",
      "handover.lowFuel.option": "Refuel yourself",
      "handover.lowFuel.title": "Low fuel level",
      "handover.lowFuel.warning": "I am willing to pay 50 €.",
      "handover.report": "Report a damage or infringement",
      "handover.returnKey.button.title": "Refresh",
      "handover.returnKey.decr": "Please make sure that the car key is in the glove compartment.",
      "handover.returnKey.title": "Return car key ",
      "handover.returnLocation.title": "I choose the return service",
      "handover.title": "Start the engine",
      "handover.top.description": "If the car is free of damages aside from the already reported ones, start the engine and have a safe trip!",
      "handover.unlock.description": "Your rental wasn't finished, please try to unlock and try again. If you still have a problem please contact our service team.",
      "handover.unlock.title": "Something went wrong!",
      "help.dialog.chat": "LiveChat",
      "help.dialog.helpcenter": "HelpCenter",
      "help.dialog.title": "Customer Support",
      "home.about": "© GETAWAY, legal info ",
      "home.walkthrough.login": "Login",
      "home.walkthrough.signup": "Get started",
      "hours.short": "hrs",
      "ikeaMain.duration.prefix": ".",
      "image.upload.success": "Uploaded successfully",
      "input.error.invalid": "Invalid input",
      "insecuredevice.dialog.message": "This smartphone is not paired to your account. To verify it we'll send you a confirmation code via sms to the phone number stored in your account.",
      "insecuredevice.dialog.no": "Not now",
      "insecuredevice.dialog.title": "Verify this smartphone",
      "insecuredevice.dialog.yes": "Verify",
      "insurance.charterline.150": "Maximum 150 €",
      "insurance.charterline.1500": "no protection",
      "insurance.charterline.15000": "No protection",
      "insurance.charterline.151": "Maximum 150 €",
      "insurance.charterline.500": "Maximum 500 €",
      "insurance.charterline.501": "Maximum 500 €",
      "insurance.charterline.650": "Maximum 650 €",
      "insurance.charterline.750": "Maximum 750 €",
      "insurance.charterline.description": "Co-Payment",
      "insurance.charterline.description.1500": "Up to 15.000 €",
      "insurance.charterline.headline": "Co-Payment",
      "insurance.charterline.value.150": "+19 €/Tag",
      "insurance.charterline.value.1500": "incl.",
      "insurance.charterline.value.15000": "incl.",
      "insurance.charterline.value.151": "+29 €/Day",
      "insurance.charterline.value.500": "+11 €/Day",
      "insurance.charterline.value.501": "+21 €/Day",
      "insurance.charterline.value.650": "incl.",
      "insurance.charterline.value.750": "incl.",
      "insurance.description": "Max",
      "insurance.description.free": "Up to",
      "insurance.label.free": "no protection",
      "insurance.previous_for_the_same_car_must_be_used": "User should use the same insurance if he rents the same car again.",
      "insurance.rental.title": "Your retention!",
      "insurance.value": "€/day",
      "insurance.value.free": "incl.",
      "internet.disconnected.message": "Please check your internet connection",
      "invitation.description": "Enter your invitation code to redeem your welcome bonus",
      "invitation.remove.text": "Remove",
      "invitation.submit.text": "APPLY",
      "invitation.text.placeholder": "Invitation Code",
      "invitation.title": "do you have an invitation?",
      "invite.browse.contacts.button": "BROWSE CONTACTS",
      "invite.contacs.success": "Invitations have been sent. We will notify you as soon as your invitations have been accepted.",
      "invite.contact.email.warning": "You need to set up an email account on your device to invite friends via email.",
      "invite.email.description": "Accept my invitation dear friends %@",
      "invite.email.title": "I want you to join GETAWAY",
      "invite.friends.share.button": "SHARE",
      "invite.friends.share.description": "Click and hold to copy your invitation link",
      "invite.message": "Join me on GETAWAY, today. Simply download the app and receive a 10€ welcome bonus with my invitation:",
      "invite.message.title": "You've been invited to join GETAWAY",
      "joinCircle.title": "Enter the circle",
      "keine": "None",
      "landing.card1.desc": "Enjoy our pay per km billing system and save money and time!",
      "landing.card1.title": "Get any car arounds you \nand drive it around",
      "landing.card2.desc": "We’ve got you covered! List your car and we\ntake care of insurances and other stuf that\nEd needs to brag about here!",
      "landing.card2.title": "List your car and start\nmaking money!",
      "landing.later": "LATER",
      "landing.login": "I already have a user account",
      "landing.signup": "Welcome!",
      "landing.signup.dialog.email.exists": "It seems like you already have an account with GETAWAY, do you want to reset your password?",
      "late": "late",
      "less": "Less",
      "license.addPhoto.back": "Add driver's license back",
      "license.addPhoto.front": "Add driver's license front",
      "license.addVideo": "Add selfy video",
      "license.addedPhoto.back": "Driver's license back",
      "license.addedPhoto.front": "Driver's license front",
      "license.addedVideo": "Selfy video",
      "license.submit.title": "Further",
      "license.title": "Add driver's license",
      "list.car.desc": "To check how much your car could earn for you, take a picture of the full front of the car’s registration certificate.",
      "list.car.footer": "This car has a valid vehicle insurance and general inspection.",
      "list.car.license": "Vehicle registration",
      "list.car.ownership.error": "Please select your ownership status for this car",
      "list.car.ownership.message": "If you don't own the car, you need to hand in letter of attorney later. A template will be provided.",
      "list.car.submit": "SUBMIT FOR CHECK",
      "list.car.success": "Thanks. Your car registration license has been submitted. We've sent you an email with the next steps.",
      "list.car.takeaphoto.error": "Please take a photo first",
      "list.car.title": "How much car your car earn?",
      "loading": "loading ...",
      "location.permission": "Please enable location services, now",
      "location.permission.details": "Please grant GETAWAY access to your location to enable location-based services and to show you available cars around.",
      "location.permission.no": "Later",
      "location.permission.title": "Enable location-based services",
      "location.permission.unauthorized": "Getaway can't work propery without accessing your location.",
      "location.permission.yes": "Allow",
      "lock.error": "We are unable to lock the car, please contact support",
      "login.button.title": "Login",
      "login.email.placeholder": "Email",
      "login.failed.again.text": "Try again",
      "login.failed.alert.body": "You've entered a wrong email or password. Please try again or click \"Forgot?\" to reset your password.",
      "login.failed.alert.title": "Failed to login",
      "login.forgot.text": "Reset password",
      "login.forgot.title": "Forgot password?",
      "login.orloginfacebook.text": "Or login with",
      "login.orsignupfacebook.text": "Or sign up with",
      "login.password.placeholder": "Password",
      "login.title": "Good to see you again!",
      "logout.prompty.logout": "YES",
      "mages.510cm": "5 - 10cm",
      "map.bookings.button.text": "Booking from",
      "map.callout.bonus.lowAndOut": "Up to 10€ instant bonus",
      "map.callout.bonus.lowFuel": "5€ instant bonus",
      "map.callout.bonus.lowFuel&out": "Up to 10€ instant bonus",
      "map.car.eligible.description": "[in queue] soon being available",
      "map.car.inqueue.callout": "If more users around sign up I will be available soon.",
      "map.car.release_ready.callout": "Car is currently unavailable, please try another time frame",
      "map.car.release_ready.description": "[currently busy] Please check again, later",
      "map.car.released.callout": "Available until\n%@",
      "map.car.selected.callout": "I will be available very soon. Rent me another day 🙌",
      "map.fillProfile.dialog.add": "Add",
      "map.fillProfile.dialog.message": "Ccomplete your user details now (only 2 minutes) to continue.",
      "map.fillProfile.dialog.no": "Not now",
      "map.fillProfile.dialog.secure": "Card Authentication",
      "map.fillProfile.dialog.support": "Complete registration",
      "map.fillProfile.dialog.title": "Missing user information",
      "map.fillProfile.dialog.yes": "OK",
      "map.filter.check": "Check availability",
      "map.filter.description": "Set your desired pick-up and return time.",
      "map.filter.findCar": "Find vehicle",
      "map.filter.header": "When would you like  a vehicle?",
      "map.filter.now": "Now",
      "map.filter.pickup": "Pick-up",
      "map.filter.reset": "Reset",
      "map.filter.return": "Return",
      "map.filter.secondary.text": "Edit date",
      "map.filter.time.to": "until",
      "map.filter.title": "When do you want a vehicle?",
      "map.marker.perHours": "€/Hr",
      "map.popup.firststeps": "%@ and get your welcome bonus, today.",
      "map.popup.firststeps.url": "https://applink.get-a-way.com/user-challenges-firststeps",
      "map.popup.firststeps.url.text": "Finish you profile",
      "map.rented.callout": "Not available in your chosen time frame.",
      "map.reservedCarHeader.settings.title": "Upgrade your rental settings",
      "map.reservedCarHeader.title": "This car is reserved!",
      "map.service.lastCleaning.new": "new",
      "map.station.card.button": "choose",
      "map.station.card.description": "station",
      "map.station.card.headline": "Choose a vehicle category",
      "map.station.card.no.categories": "No available cars  in given time frame",
      "map.station.card.position": "location",
      "map.station.card.title": "Robben & Wientjes",
      "map.station.card.workinghrs": "Working Hours",
      "map.station.closed.text": "Station is closed this time period",
      "map.time": "filter cars",
      "map.time.select.apply": "Filter",
      "map.time.select.title": "how long do you want a GETAWAY?",
      "map.unavailable.callout": "Not available in your chosen time frame. %s .",
      "map.unavailable.callout.link": "https://www.google.com/",
      "map.unavailable.callout.link.text": "Show availability.",
      "map.usercar.BLOCKED.callout": "✋ I am currently blocked. Please contact the ServiceTeam.",
      "map.usercar.ELIGIBLE.callout": "💪 I've passed all tests",
      "map.usercar.EQUIPPED.callout": "⚙ I am equipped",
      "map.usercar.SELECTED.callout": "🎉 I can't wait to get equipped!",
      "map.usercar.blocked.callout": "✋ I am currently blocked. Please contact the ServiceTeam.",
      "map.usercar.eligible.callout": "💪 I've passed all tests",
      "map.usercar.equipment_ready.callout": "👨‍🔧 I am waiting for the technician",
      "map.usercar.listed.callout": "💰 Ka-Ching! I could earn up to 400€ every month.",
      "map.usercar.released.callout": "Released until\n%@",
      "map.usercar.selected.callout": "🎉 I can't wait to get equipped!",
      "map.usercar.service.callout": "🚗✨ I am currently enjoying a service",
      "mileage": "Mileage",
      "minute": "minute",
      "minute.short": "min",
      "minutes": "minutes",
      "more": "More",
      "multiplecars.add": "Submit new car",
      "multiplecars.footnote": "%@ out how to make money by renting your car with GETAWAY!",
      "multiplecars.footnote.url": "https://service.get-a-way.com/wie-vermiete-ich-mein-auto#geld-verdienen",
      "multiplecars.footnote.url.text": "Find out",
      "multiplecars.released.returntime": "until",
      "multiplecars.rented.returntime": "by not later than",
      "multiplecars.title": "MY CARS",
      "navigate": "Navigate",
      "needsAccessKitCheck": "needs AccessKit check",
      "needsBattery": "needs battery",
      "needsCleaning": "needs cleaning",
      "needsFuel": "needs fuel",
      "needsFuelCard": "needs fuel card",
      "needsKeyFob": "needs key fob",
      "needsOther": "needs other",
      "needsRelocation": "needs relocation",
      "needsRepair": "needs Repair",
      "new.insurance.charterline.150": "150 €",
      "new.insurance.charterline.1500": "1500 €",
      "new.insurance.charterline.15000": "15.000 €",
      "new.insurance.charterline.151": "150 €",
      "new.insurance.charterline.500": "500 €",
      "new.insurance.charterline.501": "500 €",
      "new.insurance.charterline.650": "650 €",
      "new.insurance.charterline.750": "750 €",
      "new.insurance.charterline.description": "Max",
      "new.insurance.charterline.description.15000": "Up to",
      "no": "No",
      "no.geocode": "show area",
      "noBlockReason": "No downtime reason available",
      "noRentals": "No rentals yet",
      "noServices": "No services",
      "nocars.invite": "INVITE",
      "nocars.screen.title": "Invite your friends for more cars in this area!",
      "notNow": "Not now",
      "notification.backby.desc": "Bitte denke an die rechtzeitige Rückgabe, andernfalls können Gebühren anfallen.",
      "notification.backby.hide": "HIDE NOTIFICATION",
      "notification.backby.title": "%s left",
      "notification.backby.title.late": "Since %s",
      "notification.booking.rentals": "My rentals",
      "notification.booking.success": "You've successfully placed your booking. You'll find its status in your account.",
      "notification.email": "Via email",
      "notification.email.desc": "Permission to contact via email.",
      "notification.finishRental.complain": "😕 I want to complain.",
      "notification.finishRental.description": "Your rental has been finished. You will receive an overview via email.",
      "notification.finishRental.title": "Thank you for using GETAWAY!",
      "notification.mail": "Via mail",
      "notification.mail.desc": "Permission to contact via mail.",
      "notification.push": "Via push notification",
      "notification.push.desc": "Permission to contact via push notification.",
      "notification.sms": "Via SMS",
      "notification.sms.desc": "Permission to contact via SMS.",
      "now": "Now",
      "offline": "OFF",
      "offlineService.report.title": "Service report",
      "ok": "OK",
      "online": "ON",
      "open.directions.title": "Start navigation",
      "opennow": "Open now",
      "openstoday": "Opens today",
      "openstomorrow": "Opens tomorrow",
      "parking.description": "Free for 120 minutes. Any additional costs only 5 Ct",
      "parking.hours": "hours",
      "parking.minutes": "minutes",
      "parking.title": "Parking",
      "password.token.expired": "Expired token, please submit a new password reset request.",
      "payment.3ds.title": "Credit card authentication",
      "payment.add.description": "For verification of your user account, only. The registration is and stays completely free of charge.",
      "payment.add.error": "Payment method error message. Please try again.",
      "payment.card.add.description": "add and verify your credit card",
      "payment.card.add.title": "add credit card",
      "payment.card.add.verifying": "Verifying...",
      "payment.card.code.label": "CVC/CVV",
      "payment.card.expiration.label": "Expiration",
      "payment.card.name.label": "Account holder",
      "payment.card.number.label": "Card number",
      "payment.footnote": "I agree with a possible % s on the probability of payment for the choice of payment methods.",
      "payment.footnote.url": "https://getaway-docs.s3.eu-central-1.amazonaws.com/de-DE/Getaway_DataPrivacy-SchufaChecks-de.pdf",
      "payment.footnote.url.text": "Data check",
      "payment.postingText": "Thanks.",
      "payment.postingTextReference": "%s",
      "payment.secured.success": "authentication passed successfully, thanks !",
      "payment.sepa.description": "SEPA Direct Deposit",
      "paypal.desc": "Add one of our popular and secure payment methods to use our services.",
      "paypal.placeholder": "PayPal account",
      "paypal.title": "Add PayPal",
      "per.calday": "/calendar day",
      "per.km": "per km",
      "permission.settings.button": "GRANT ACCESS",
      "phone.pairing.message": "Click here, to pair this smartphone to your account.",
      "phone.resend.code": "RESEND CODE",
      "phone.verification.resend.success": "Confirmation code has been sent again.",
      "phone.verify.code.apply": "APPLY CODE",
      "phone.verify.code.desc": "We've just sent you a 4 digits verification code at %s. Enter the code below to verify your device.",
      "phone.verify.code.title": "Enter your confirmation code",
      "phone.verify.desc": "Enter your phone number to receive a confirmation code via SMS.",
      "phone.verify.mobile.phone.text": "Mobile phone number",
      "phone.verify.send.button": "Confirm",
      "phone.verify.send.code": "Request SMS-Code",
      "phone.verify.sms": "{{code}} is your GETAWAY verification code.",
      "phone.verify.title": "Add mobile phone number",
      "phone.wrong.format.error": "Please enter correct phone number",
      "phoneNumberVerification.authy.custom_message": "{{code}} is your GETAWAY verification code.",
      "phonenumber.verification.error": "Unable to verify this phone number, please contact support or use another number.",
      "photo": "Photo",
      "photo.continue": "CONTINUE",
      "photo.library": "Photo Library",
      "photo.source.dialog.title": "Upload photos via",
      "photos": "Photos",
      "photos.add": "Add car photos",
      "photos.add.button": "Add photo",
      "photos.add.description": "Take photos the front, the side and interior. Great photos help to have more rentals 🤩",
      "photos.desc": "A picture is worth a thousand words. Add some photos that really make your car shine",
      "photos.permission": "Please give this app permission to access your photo library in your settings.",
      "photos.permission.details": "Please grant GETAWAY access to your photo library to upload photos.",
      "photos.permission.no": "Not now",
      "photos.permission.title": "Access Photos",
      "photos.permission.yes": "Allow",
      "photos.photo": "Photo",
      "photos.photo.reached.max": "You reached max photos count.",
      "photos.skip.no.photos": "You can always add photos later.",
      "photos.skip.photos": "You have uploaded photos.",
      "picker.placeholder": "Select an item ...",
      "price": "Price",
      "price.details.footnote": "* A new driver is someone with a class B driving license less than 24 months old.",
      "price.details.footnote.url.text": "Current Price list",
      "price.distance.100.prefix": "first 100km: ",
      "price.distance.101.prefix": "from 101km:",
      "price.distance.headline": "Mileage",
      "price.duration.headline": "Duration",
      "price.duration.headline.mo-fr": "Weekdays (Mon-Fri)",
      "price.duration.headline.mo-sa": "rental duration",
      "price.duration.headline.sa-mo": "Sat, 18:00 - Mon, 08:00",
      "price.duration.headline.sa-so": "Weekend (Sat-Sun)",
      "price.duration.holiday": "on holidays: %s (100 km incl.)",
      "price.duration.holiday.headline": "Holiday",
      "price.duration.mo-fr.night.prefix": "9pm - 9:30am: ",
      "price.duration.mo-fr.prefix": "9:30am - 09:00 pm: %s",
      "price.duration.mo-sa.daily": "8am - 6pm: %s/work day (100 km incl.)",
      "price.duration.mo-sa.hourly": "Mon-Sat 8am - 8pm: %s/hr + (20km incl. /hr), max. %s/day (100km incl.)",
      "price.duration.mo-sa.night": "7pm - 6am: %s/night (100km incl.)",
      "price.duration.sa-mo": "Sat 6pm - Mon 8am: %s (150 km incl.)",
      "price.duration.sa-so.night.prefix": "Sa, 6pm - Mon 9:30am: ",
      "price.duration.sa-so.prefix": "Sa, 9:30am - 6pm :  %s (1st hour free)",
      "price.insurance.150.prefix": "max. 150€: ",
      "price.insurance.1500.prefix": "max. 1.500€: Incl.",
      "price.insurance.15000.prefix": "max. 15.000€:  Incl.",
      "price.insurance.151.prefix": "max. 150€: ",
      "price.insurance.500.prefix": "max. 500€: ",
      "price.insurance.501.prefix": "max. 500€: ",
      "price.insurance.650.prefix": "max. 650€:  incl.",
      "price.insurance.750.prefix": "max. 750€:  incl.",
      "price.insurance.headline": "Insurance Deductable (per Claim)",
      "price.insurance.young.headline": "Insurance Deductable (New Drivers*)",
      "price.return.service.headline": "Return Service (Outside Return Area)",
      "price.tank.10.prefix": "Return <10%: ",
      "price.tank.25.prefix": "Return <25%: ",
      "price.tank.headline": "Fueling Service",
      "price.weekend.surcharge.prefix": "WeekendSurcharge (Fr-Sun):",
      "pricedetails.basic.title": "Pay As You Drive",
      "pricedetails.distancePackages.remaining": "After %@. km",
      "pricedetails.example.day": "One day (24 hrs)",
      "pricedetails.example.description": "incl. %s km* and fuel",
      "pricedetails.example.hour": "One hour",
      "pricedetails.example.kilometer": "One Kilometer",
      "pricedetails.example.kilometer.description": "Including insurance and fuel",
      "pricedetails.example.month": "One month (28 days)",
      "pricedetails.example.title": "PRICING CASES",
      "pricedetails.example.value.description": "equals %s per Min.",
      "pricedetails.example.value.description.day": "Ø %s € per cal.day",
      "pricedetails.example.value.description.owner": "you earn %s",
      "pricedetails.example.week": "One week",
      "pricedetails.longterm.description.oneday": "incl. 80 km per 24 Hrs*",
      "pricedetails.longterm.footnote": "Prices include insurance and fuel. You will automatically be charged the cheapest price.\n \n*each additional Kilometer or standing time will be billed according to the peruse pricing.",
      "pricedetails.longterm.footnote.url": "https://get-a-way.com/agb/entgelte-und-gebuehren/",
      "pricedetails.longterm.footnote.url.text": "hier",
      "pricedetails.longterm.tier1.description": "incl. 80 km* and fuel",
      "pricedetails.longterm.tier1.title": "From 1st day",
      "pricedetails.longterm.tier1.value.description": "per 24 Hrs",
      "pricedetails.longterm.tier2.description": "incl. 40 km* and fuel",
      "pricedetails.longterm.tier2.title": "From day 4",
      "pricedetails.longterm.tier2.value.description": "per 24 Hrs",
      "pricedetails.longterm.tier3.description": "incl. 20 km* and fuel",
      "pricedetails.longterm.tier3.title": "From day 8",
      "pricedetails.longterm.tier3.value.description": "per 24 Hrs",
      "pricedetails.longterm.title": "From %s th day",
      "pricedetails.longterm.value.description": "per %s",
      "pricedetails.parking.freeminutes": "%s Min. free per km",
      "pricedetails.parking.title": "Standing time",
      "pricedetails.parking.value": "afterwards %s per Min.",
      "pricedetails.pricePerKm.title": "Driven Mileage",
      "pricedetails.pricePerKm.value": "%s per km",
      "pricedetails.specialoffers.title": "Daily deals",
      "pricedetails.title": "Price info",
      "pricing.CharterlinePricing.url": "https://api.get-a-way.com/pricing/5d170e35f892b13d8468f283/pdf",
      "pricing.HagebauMarktPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/58944_Preisliste+Ubeeqo_Hagebaumarkt_A4_v02.pdf",
      "pricing.HeyOBIPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf",
      "pricing.IkeaInnsbruck.duration.hourly": "9:30am - 09:00 pm: %s/Std",
      "pricing.IkeaInnsbruck.duration.night": "9pm - 9:30am: %s",
      "pricing.IkeaInnsbruck.duration.weekday": "rental hours (Mon-Fri)",
      "pricing.IkeaInnsbruck.duration.weekend": "rental hours (Sat-Sun)",
      "pricing.IkeaInnsbruck.duration.weekend.sat": "Sa, 9:30am - 6pm : %s",
      "pricing.IkeaInnsbruck.duration.weekend.so": "Sa, 6pm - Mon 9:30am: %s",
      "pricing.IkeaInnsbruckPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA-UBQ-IKEA_v211101_Preisliste_Ikea_11-2021.pdf",
      "pricing.IkeaMainPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA-UBQ-IKEA_v211101_Preisliste_Ikea_11-2021.pdf",
      "pricing.MISSmartPricing.url": "https://api.get-a-way.com/pricing/61cb2b425071913188afebcf/pdf",
      "pricing.MISSmartSpainPricing.url": "https://api.get-a-way.com/pricing/61cb2b425071913188afebcf/pdf",
      "pricing.MISTransporterPricing.url": "https://api.get-a-way.com/pricing/61cb2b425071913188afebcf/pdf",
      "pricing.OBIEmployeesPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_OBI_Employees.pdf",
      "pricing.OBIPublicUsersPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf",
      "pricing.OBIUsersPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf",
      "pricing.charterline.distance.100": "first 100km: %s/Km",
      "pricing.charterline.distance.101": "from 101km: %s/km",
      "pricing.charterline.duration.hourly": "%s/Std (max. %s/cal. day)",
      "pricing.charterline.duration.weekend": "WeekendSurcharge (Fr-Sun): + %s/Std (Max %s/day)",
      "pricing.degressive.distance": "${format:euro(price.driversPricePerKm)}/km (from ${price.firstDiscountedKm}. km only ${format:euro(price.firstDiscountedPrice)})",
      "pricing.degressive.parking": "${price.freeParkingMinutesPerKm} free minutes/km, then ${format:cents(price.pricePerParkingMinute)} Ct/Min.",
      "pricing.distance": "Mileage",
      "pricing.distance.details": "%s/km",
      "pricing.distance.excess": "Mileage (per excess km)",
      "pricing.duration": "rental duration",
      "pricing.duration.hourly": "%s/h",
      "pricing.expert.distance": "%s/km",
      "pricing.expert.duration": "%s/h",
      "pricing.fee.driver.injury.protection": "Passenger Protection: %s",
      "pricing.fee.driver.protection": "Extras",
      "pricing.fee.return": "Return Service (Outside Return Area)",
      "pricing.fee.return.details": "%s + %s/100m",
      "pricing.fee.tank": "Fueling Service",
      "pricing.fee.tank.low.10": "Return <10%:",
      "pricing.fee.tank.low.25": "Return <25%:",
      "pricing.heyOBI.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf",
      "pricing.insurance": "Insurance Deductable (per Claim)",
      "pricing.insurance.table": "max. %s€: %s€/cal. day",
      "pricing.insurance.table.daily": "max. %s€: %s€/24hrs",
      "pricing.insurance.table.free": "max. %s€: Included",
      "pricing.insurance.young": "Insurance Deductable (Young driver)",
      "pricing.longterm.distance": "${format:euro(price.driversPricePerKm)}/km",
      "pricing.longterm.parking": "${price.freeParkingMinutesPerKm} free minutes/km, then ${format:cents(price.pricePerParkingMinute)} Ct/Min.",
      "pricing.longtermanddistance.distance": "${format:euro(price.driversPricePerKm)}/km (from ${price.firstDiscountedKm}. km only ${format:euro(price.firstDiscountedPrice)})",
      "pricing.longtermanddistance.parking": "${price.freeParkingMinutesPerKm} free minutes/km, then ${format:cents(price.pricePerParkingMinute)} Ct/Min.",
      "pricing.mis.distance": "incl. 50 km/24Hrs (%s/excess km)",
      "pricing.mis.duration.after.30": "rental duration (after 30 days)",
      "pricing.mis.duration.before.30": "rental duration (up to 30 days)",
      "pricing.mis.duration.daily": "1st to 3rd day: %s/24hrs",
      "pricing.mis.duration.daily.plus3": "from day 4: %s/24hrs",
      "pricing.mis.duration.daily.plus30": "%s/24hrs.",
      "pricing.mis.duration.transporter.hourly": "After 3 hours: %s/h. (max. %s/24hrs)",
      "pricing.mis.duration.transporter.hourly.lt3h": "first 3 hours: %s",
      "pricing.mis.spain.duration.daily": "%s/24hrs",
      "pricing.mis.transporter.distance": "%s/excess km (50 km/24Hrs incl.)",
      "pricing.obi.distance": "%s/excess km",
      "pricing.obi.duration.holiday": "on holidays: %s (incl. 100km)        ",
      "pricing.obi.duration.hourly": "Mon-Sat 8am-8pm: %s/hr (20km incl. /hr), max. %s/day (100km incl.)",
      "pricing.obi.duration.night": "8pm - 8am: %s/night (100km incl.)",
      "pricing.obi.duration.weekend": "Sat 8pm - Mon 8am: %s (incl. 150km)",
      "pricing.obiEmployees.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_OBI_Employees.pdf",
      "pricing.obiPublicUsers.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf",
      "pricing.obiUsers.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf",
      "pricing.serviceTrip.distance": ".",
      "pricing.serviceTrip.parking": ".",
      "pricing.static.distance": "${price.driversPricePerKm}/km",
      "pricing.static.parking": "${price.freeParkingMinutes} free minutes/km, then ${format:cents(price.pricePerParkingMinute)} Ct/Min.",
      "pricing.title": "Price Details",
      "privacy.text": "YOUR DATA ARE PRIVATE AND PROTECTED",
      "privacy.url": "https://get-a-way.com/datenschutz?utm_source=app",
      "push.permission": "Please enable push notifications now",
      "push.permission.details": "Allow GETAWAY to send you push notifications, in order to get notified when your car or rental status is changing.",
      "push.permission.no": "Later",
      "push.permission.title": "Enable push notifications",
      "push.permission.yes": "Allow",
      "qrCamera.title": "Scan QR code",
      "referFriend.code.error": "Please enter a valid invitation code",
      "refuel": "refuel",
      "refuel.payment.subTitle": "Payment",
      "refuel.payment.title": "Credit Card PIN",
      "refuel.subTitle": "Take the credit card from the glove compartment and use the PIN displayed below. You don't need to keep the receipt.",
      "refuel.title": "Refuel for free",
      "rent.car.driver.gear.restricted": "You can't rent this car as your driving license is restricted to automatic gears only.",
      "rent.car.driver.license.baned": "Your driving license is banned",
      "rent.car.driver.license.open": "Your driving license is under verification, we will email you once its ready.",
      "rental": "Rental",
      "rental.action.damages": "Report damage",
      "rental.action.finish": "Finish rental",
      "rental.action.fuel": "Refuel",
      "rental.add.damage.wizard.description": "Listing all current damages helps to identify future damages.",
      "rental.cancel": "Cancel",
      "rental.cancel.handover.ok": "Finish rental",
      "rental.cancel.handover.title": "You are about to cancel the current process.",
      "rental.carCheck.button": "Continue",
      "rental.carCheck.cancel": "Finish",
      "rental.carCheck.footnote": "This helps to avoid to high costs in case of a damage.",
      "rental.carCheck.header.desc": "Take a photo from all four perspectives to start the engine.",
      "rental.carCheck.header.title": "All-around check",
      "rental.checkBelongings.finish.message": "Please make sure you have everything out of the car before continuing.",
      "rental.checkBelongings.finish.messege": "Please make sure you have everything out of the car before continuing.",
      "rental.checkBelongings.finish.title": "Please check your belongings",
      "rental.damage.added": "The damage was successfully logged at %@",
      "rental.dialog.service.error": "Please select performed services and at least one image.",
      "rental.dongle.end.title": "end rental",
      "rental.dongle.handover.description": "To end your rental please check all the points in this list",
      "rental.dongle.key.return.description": "Bitte übergebe Fahrzeugschlüssel und -papiere (inkl. der Tankkarte) am Ende deiner Miete einem Stationsmitarbeiter am Express-Schalter. Alternativ wirf diese in den entsprechend ausgeschilderten Nachteinwurf der Station.",
      "rental.driving.backBy": "Time to return",
      "rental.driving.damages.add": "Report",
      "rental.driving.greeting": "Enjoy your trip!",
      "rental.extra.F3023.title": "Passenger protection: 10,00 €",
      "rental.finish.button": "Finish rental",
      "rental.finish.success": "Your rental was finished successfully!\nHave a nice day!",
      "rental.fuelScreen.title": "Refuel cashless",
      "rental.fuelSettings.desc": "Refuel comfortably & cashlessly with the fuel card from the glove compartment at all partner petrol stations.",
      "rental.fuelSettings.fuelCard": "Fuel card: ",
      "rental.fuelSettings.fuelNote": "Do not refuel premium types. Else fees might apply.",
      "rental.fuelSettings.fuelPin": "PIN:  ",
      "rental.fuelSettings.gasStation.desc": "Prior to the refuelling please check that the gas station accepts the fuel card provided.",
      "rental.fuelSettings.instruction": "You don’t need to keep the receipt.\nReturn the fuel card after.",
      "rental.fuelSettings.navigate": "Map",
      "rental.fuelSettings.stepOne": "1. Find partner gas station",
      "rental.fuelSettings.stepThree": "3. Pay with the fuel card",
      "rental.fuelSettings.stepTwo": "2. Refuel (no premium type)",
      "rental.fuelSettings.title": "Refuel for free",
      "rental.fuelSettings.where": "Where?",
      "rental.fuelstation": "Fuel Station",
      "rental.handover.description": "If everything is alright, lock the vehicle from the outside to finish the rental.",
      "rental.handover.header": "Thank you,",
      "rental.handover.title": "Finish rental",
      "rental.history.header": "Rental",
      "rental.history.invoice": "Show rental invoice",
      "rental.insurance.change.error": "Insurance option is not available for this trip.",
      "rental.insurance.dialog.button": "CALL INSURANCE",
      "rental.insurance.dialog.description": "For instant help please call our insurance partner \"Gothaer\", now. Please refer to the following insurance policy number:",
      "rental.insurance.dialog.footer": "24/7 Service center. Landline number +49 30 5508 81508",
      "rental.insurance.dialog.number": "02.925.777 777",
      "rental.insurance.dialog.number.description": "Insurance policy number",
      "rental.insurance.dialog.phone.number": "+4930550881508",
      "rental.insurance.dialog.title": "Emergency Support",
      "rental.insurance.gothaer.dialog.description": "For instant help please call our insurance partner \"Gothaer\", now. Please refer to the following insurance policy number:",
      "rental.insurance.gothaer.dialog.footer": "24/7 Service center. Landline number +49 30 5508 81508.",
      "rental.insurance.label": "Insurance",
      "rental.insurance.settings.footnote": "In case of damage, it can quickly become expensive. Limit your liability risk and choose a% s",
      "rental.insurance.settings.more.url.text": "Percentage excess",
      "rental.insurances.info.url": "https://getaway.app/insurance",
      "rental.internet.disconnected.message": "Unable to connect to GETAWAY. Please make sure you're connected to the internet or call our service team.",
      "rental.internet.disconnected.title": "No internet connection",
      "rental.keyOut.button.title": "I have the car key",
      "rental.opened.damages.title": "Damage record",
      "rental.opened.dongle.continue": "Continue",
      "rental.opened.header.desc": "If the vehicle is free of new damages, start the engine now.",
      "rental.opened.header.title": "Start the engine",
      "rental.opened.instruction": "Please take the key.",
      "rental.opened.key.option": "Take out the key",
      "rental.opened.video.description": "The key holder is located in the glove compartment.",
      "rental.opened.video.title": "Take out the key",
      "rental.parking.findCar": "Find my car",
      "rental.prompty.end": "Finish Rental",
      "rental.rate.negative": "NO",
      "rental.rate.positive": "YES",
      "rental.rate.title": "Did you like the car?",
      "rental.reserved.desc.connected": "Unlock the vehicle to start payable rental.",
      "rental.reserved.desc.disabled": "Activate bluetooth to unlock the vehicle with your mobile phone.",
      "rental.reserved.desc.searching": "Go to the vehicle to unlock it with your mobile phone.",
      "rental.reserved.header.connected": "Start rental",
      "rental.reserved.header.disabled": "Enable Bluetooth",
      "rental.reserved.header.searching": "Reduce distance",
      "rental.returnlocation": "Return Location",
      "rental.returntime.late": "%@ late",
      "rental.review.negative.notification": "Thanks for your feedback...",
      "rental.section.booked": "Booked",
      "rental.section.locked": "Locked",
      "rental.section.reserved": "Reserved",
      "rental.section.unlocked": "Unlocked",
      "rental.service.note": "Additional note (optional)",
      "rental.service.report.button": "Submit service report",
      "rental.service.report.submit.success": "Your report was submitted",
      "rental.service.report.title": "Service report",
      "rental.settings.insurance.more": "More information",
      "rental.settings.insurance.title": "Reduce your percentage excess!",
      "rental.settings.title": "Rental Settings",
      "rental.startEngine.decr": "if the car is free of damages aside from the already reported once, start the engine and have a save trip!",
      "rental.startEngine.title": "Start the engine now!",
      "rental.status": "Status",
      "rental.title": "Rental %s",
      "rental.walkthrough.button": "Ok",
      "rental.walkthrough.page1.description": "Select your desired liability limit (retention) before unlocking the vehicle with your mobile phone.",
      "rental.walkthrough.page1.title": "Select insurance and start rental",
      "rental.walkthrough.page2.description": "Return the vehicle to the color-highlighted return area with a tank level of at least 25% and lock it with your mobile phone.\n\nNew: Choose the return service at the end of your trip and we take care of the return.",
      "rental.walkthrough.page2.title": "Return and finish rental",
      "reserve.btn.unavailable": "Currently in rental ",
      "reset": "Reset",
      "returncar.apply": "RELEASE NOW",
      "returncar.cancel": "I don't want to release my car",
      "returncar.title": ", when do you want your car to be returned?",
      "review": "Review",
      "review.dialog.later": "Not now 😒",
      "review.dialog.message": "If you’re, pls review our app and help to convince more neighbours to make mobility sustainable🙏",
      "review.dialog.no": "Feedback",
      "review.dialog.title": "⭐⭐⭐⭐⭐",
      "review.dialog.yes": "Yes, I like 😃",
      "route.dialog.applemaps": "Apple Maps",
      "route.dialog.copy": "Copy Address",
      "route.dialog.googlemaps": "Google Maps",
      "salutation": "Salutation",
      "save": "Save",
      "schufa.address": "ADDRESS",
      "schufa.button.title": "Request unlock",
      "schufa.dateOfBirth.dateFormat": "Day / Month / Year",
      "schufa.dateOfBirth.label": "Date of birth",
      "schufa.instruction": "To unlock this payment method please enter the following infos to run a quick check.",
      "schufa.notification.message": "😥 Unser Zahlungsmittelanbieter kann leider kein SEPA-Mandat für dich einrichten. Bitte wähle eine alternative Zahlungsmethode. Wir arbeiten bereits an weiteren Alternativen.",
      "schufa.notification.title": "Unlock payment method",
      "schufa.personalInfo": "PERSONAL INFO",
      "schufa.title": "Unlock payment method",
      "schufaCheck.dialog.message": "😥 Unser Zahlungsmittelanbieter kann leider kein SEPA-Mandat für dich einrichten. Bitte wähle eine alternative Zahlungsmethode. Wir arbeiten bereits an weiteren Alternativen.",
      "schufaCheck.dialog.title": "Payment method approval",
      "schufaCheck.dialog.yes": "OK",
      "seconds.short": "sec.",
      "selectPayment.desr": "Enter your preferred payment method to complete your registration.",
      "selectPayment.sepaCard.text": "Add SEPA",
      "selectPayment.title": "Add payment method",
      "selectPayment.visaCard.text": "Add Visa or MasterCard",
      "selfie.add.button": "take a 3 sec selfie video",
      "service.button.text": "Reserve service",
      "service.map.filter.apply": "Show on map",
      "service.report.accomplished": "I have accomplished services",
      "service.report.image.note": "The license plate must be recognizable on at least one photo.",
      "service.report.noService.note": "Extra notes",
      "service.reserved.services.title": "Requested services",
      "service.start.button": "Start service",
      "service.started.finish.button": "Finish service",
      "service.started.services.title": "Requested services",
      "service.tab.all": "All",
      "service.tab.thisWeek": "Week",
      "service.tab.thisweek": "Week",
      "service.tab.today": "Today",
      "service.tab.tomorrow": "Tmrw",
      "service.title": "Service trip",
      "service.type.basicBranding": "Affix stickers",
      "service.type.basicCarPhotos": "Update car profile photos",
      "service.type.basicDamageRecords": "damage protocol",
      "service.type.batteryChangeOpening": "Battery change (w/ emergency opening)",
      "service.type.batteryChangeSimple": "Battery Change (simple)",
      "service.type.batteryCharge": "Charge battery",
      "service.type.cleanOutsideDry": "wash outside (dry)",
      "service.type.comboPremium": "comboPremium",
      "service.type.comboPro": "Package \"Total Care\"",
      "service.type.comboRefuelWashInside": "Package \"Refuel & Clean inside\"",
      "service.type.comboRefuelWashOutside": "Paket \"Tanken & Glanzaußenwäsche\"",
      "service.type.comboRefuelWashTotal": "Paket \"Tanken & Innen-/Außenreinigung\"",
      "service.type.dashboardCleaning": "clean ventillation and storage places",
      "service.type.desinfektion": "desinifect",
      "service.type.exchangeFuelCard": "Replace fuel card",
      "service.type.extra10min": "Extra Time, per 10 Minutes",
      "service.type.grillCleaning": "Grill cleaning",
      "service.type.highGlossFinish": "high-gloss finish and color sealing",
      "service.type.noService": "I haven’t performed any services",
      "service.type.otherBasicService": "Other Service",
      "service.type.refuel": "Refuel",
      "service.type.relocate": "Relocation",
      "service.type.relocation": "Relocation",
      "service.type.rimsCleaning": "Intensive rim cleaning",
      "service.type.safetyCheck": "Safety Check",
      "service.type.tirePressure": "tire pressure check",
      "service.type.vacuumCleaning": "hover trunk and floor mats",
      "service.type.washInside": "clean inside",
      "service.type.washOutside": "clean outside (wet)",
      "service.type.windshieldFluidRefill": "Fill-up washer fluid",
      "services.item.subTitle": "WMA Order",
      "services.map.filter.apply": "Show on map",
      "settings": "Settings",
      "settings.addPaymentMethod.payIn": "Unlock pay in advance",
      "settings.addPaymentMethod.sepa": "Add SEPA",
      "settings.addPaymentMethod.sepa.disabled": "Currently not available",
      "settings.addPaymentMethod.visa": "Add credit card",
      "settings.address.footnote": "By changing my address info, I hereby agree to proceed a %s to verify my identity.",
      "settings.address.footnote.url": "https://getaway-docs.s3.eu-central-1.amazonaws.com/de-DE/Getaway_DataPrivacy-SchufaChecks-de.pdf",
      "settings.address.footnote.url.text": "data check",
      "settings.circles.data": "EXCLUSIVE CIRCLES",
      "settings.circles.data.add": "Join a circle",
      "settings.email.update.success": "Thanks. Your email address has been updated.",
      "settings.login.data": "LOGIN DATA",
      "settings.login.data.driverslicense": "DRIVING LICENSE",
      "settings.login.data.email.edit": "Tap here to edit your email address",
      "settings.login.data.email.facebook": "Connect your facebook account",
      "settings.login.data.facebook.dest": "Connect your facebook account and enjoy one-click login. We won't publish anything on your timeline without your prior permission!",
      "settings.login.data.moreactions": "MORE ACTIONS",
      "settings.login.data.notifications": "Notifications",
      "settings.login.data.notifications.change": "Manage your notifications settings",
      "settings.login.data.password": "Reset password",
      "settings.login.data.password.edit": "Tap here to edit your password",
      "settings.login.data.terminate": "Terminate account",
      "settings.login.data.touchId": "Touch/Face ID",
      "settings.login.data.touchId.edit": "Login via Touch/Face ID",
      "settings.login.data.user.add.address.text": "Add Registered address",
      "settings.login.data.user.add.phone.text": "Add mobile phone number.",
      "settings.login.data.user.address.disabled.text": "Please contact our Service Team to make changes",
      "settings.login.data.user.address.text": "Tap here to edit your address",
      "settings.login.data.user.info.text": "Please contact our Service Team to make changes",
      "settings.login.data.user.phone.text": "Tap here to edit your mobile phone number.",
      "settings.logout": "Logout",
      "settings.notification": "Notification",
      "settings.payIn.open": "Payment method is blocked until identity check is verified, please contact support team",
      "settings.payIn.status.open.message": "Please add an Identity card to activate Pay in",
      "settings.payment.data": "PAYMENT METHODS",
      "settings.payment.data.add": "Add payment method",
      "settings.payment.footnote": "By adding or editing a payment profile, I agree with a possible %s on the probability of payment for choosing the payment methods.",
      "settings.payment.footnote.url": "https://getaway-docs.s3.eu-central-1.amazonaws.com/de-DE/Getaway_DataPrivacy-SchufaChecks-de.pdf",
      "settings.payment.footnote.url.text": "Data check",
      "settings.payment.payIn.title": "Pay in advance",
      "settings.payment.sepa.title": "Add SEPA",
      "settings.payment.sepa.url": "https://google.com",
      "settings.permission": "Permission needed",
      "settings.personal.data": "PERSONAL DATA",
      "settings.terminate.info": "To terminate your account please contact service team.",
      "settings.terminate.no": "Cancel",
      "settings.terminate.yes": "Contact",
      "settings.title": "SETTINGS",
      "settings.upload.camera": "Open Camera",
      "settings.upload.file": "Choose file",
      "settings.upload.title": "Upload Dokument",
      "settings.uploadDoc": "Upload a document",
      "share.car.message": "Check out this car at GETAWAY: %@ %@, from %@/km or %@/day incl. insurance and fuel. https://applink.get-a-way.com/map?id=%@",
      "share.car.subject": "I’ve found a car for you",
      "share.panel.description": "Share your invitation link",
      "share.panel.title": "Want more cars?",
      "shortcut.release": "Release my car",
      "shortcut.release4hours": "Release my car for 4 hrs",
      "signup.button.title": "Create free account",
      "signup.desc": "Sign up for free and start renting immediately.",
      "signup.desc.noBonus": "Sign up for free and start renting immediately.",
      "signup.description": "create your free account and receive a %@, today.",
      "signup.description.highlight": " 5€ welcome bonus ",
      "signup.dialog.ownsACar.message": "This info help us provide better service for you.",
      "signup.dialog.ownsACar.title": "Do you have a car?",
      "signup.domain.dialog.confirm": "Confirm",
      "signup.domain.dialog.message": "Please double check your email address.",
      "signup.email.placeholder": "Email",
      "signup.facebook.complete.placeholder": "COMPLETE",
      "signup.facebook.dateofbirth.placeholder": "Date of birth",
      "signup.facebook.email.placeholder": "Email",
      "signup.facebook.firstname.placeholder": "First name",
      "signup.facebook.lastname.placeholder": "Last name",
      "signup.facebook.picker.mr": "Mr.",
      "signup.facebook.picker.mrs": "Mrs",
      "signup.facebook.picker.ms": "Ms.",
      "signup.facebook.title": "SIGN UP VIA FACEBOOK",
      "signup.facebook.title.placeholder": "Title",
      "signup.fees.text": "Fees table",
      "signup.header": "Sign up now",
      "signup.invite.circleCard.text": "Join this circle and access special vehicles and offers! ",
      "signup.invite.code.seleted.text": "Get your %@ welcome credit from %@ today!",
      "signup.invite.title": "I've got invited (optional)",
      "signup.name.placeholder": "First name",
      "signup.name.policy.text": "Privacy Policy",
      "signup.name.terms.text": "By creating an account you agree to GETAWAY's Terms of Service and Data Privacy Terms.",
      "signup.name.termsservice.text": "Terms of Service",
      "signup.password.placeholder": "Password",
      "signup.policy.cancel": "Cancel",
      "signup.terms.text": "With your registration you accept the %s and %s of %s.",
      "signup.terms.url": "https://getaway.app/terms",
      "signup.terms.url.text": "Terms of Service",
      "signup.title": "Hi,",
      "signup.zipcode.placeholder": "What is your zip code?",
      "sort.byDistance": "sort by distance",
      "sort.byDistance.label": "Sort by distance",
      "sort.byDue": "sort by due date",
      "sort.byDue.label": "sort by due date",
      "start.engine.description": "Please take the key from the glove compartment",
      "start.engine.title": "Start the engine and enjoy",
      "station.booking.cancel": "cancel reservation",
      "station.booking.category": "vehicle category",
      "station.booking.code": "Reservation Code",
      "station.booking.confirm.button": "Confirm Reservation",
      "station.booking.confirm.category": "vehicle category",
      "station.booking.confirm.dates": "reservation information",
      "station.booking.confirm.description": "check your reservation information and finish your reservation",
      "station.booking.confirm.from": "From",
      "station.booking.confirm.group": "pick-up",
      "station.booking.confirm.title": "check your reservation",
      "station.booking.confirm.to": "To",
      "station.booking.confirm.where": "Where?",
      "station.booking.description": "When your reservation starts, you have 30 minutes to pick up your vehicle at the station's express counter.",
      "station.booking.from": "From",
      "station.booking.group": "pick-up",
      "station.booking.key.category": "category",
      "station.booking.key.code": "reservation code",
      "station.booking.key.from": "From",
      "station.booking.key.insurance": "insurance",
      "station.booking.key.insurance.default": "SB500",
      "station.booking.key.to": "To",
      "station.booking.merchant.waiting": "being requested",
      "station.booking.reservation": "reserved",
      "station.booking.title": "Your Reservation",
      "station.booking.to": "To",
      "station.booking.where": "Where?",
      "submit": "Apply",
      "submt": "Apply",
      "support.call": "Call support team",
      "support.cancel": "Cancel",
      "support.chat": "Chat with support team",
      "support.email": "Email support team",
      "support.phone.number": "+493012088089",
      "tba": "tba",
      "terms.url": "https://get-a-way.com/agb?utm_source=app",
      "textkey": "en-DE",
      "time": "Point of time",
      "tirePressure": "tire pressure check",
      "today": "Today",
      "tomorrow": "tomorrow",
      "touchId.password.dialog.message": "Please enter your GETAWAY password to enable Touch ID.",
      "touchId.password.dialog.title": "Confirm with password",
      "touchid.enable.message": "From now on, you can conveniently login via Touch ID.",
      "touchid.login.message": "Enable Touch ID for a convenient and secure login to GETAWAY.",
      "transaction.open.button": "Pay now",
      "transaction.open.description": "Please pay the open receipt",
      "transaction.open.title": "Open transaction",
      "transactions.bonus.description": "{0}",
      "transactions.bonus.title": "Bonus",
      "transactions.cancel.description": "Cancellation of {0}",
      "transactions.cancel.title": "Refund",
      "transactions.fee.description": "{0} {1}",
      "transactions.fee.title": "Fee #{0}",
      "transactions.firststepsbonus.description": "🎉 Welcome Bonus",
      "transactions.goodwillbonus.description": "Goodwill bonus {0}",
      "transactions.payment.deposit.description": "Rental {0}",
      "transactions.payment.deposit.title": "Deposit",
      "transactions.payment.title": "Payment of rental {0}",
      "transactions.payout.title": "Payout",
      "transactions.refuel.description": "{0} at {1}",
      "transactions.refuel.title": "Refuel",
      "transactions.refuelbonus.description": "🙏 Thanks for refuelling",
      "transactions.refund.deposit.description": "Rental {0}",
      "transactions.refund.deposit.title": "Deposit Refund",
      "transactions.refund.title": "Amount paid out",
      "transactions.rental.description": "{0} | {1}km at {2}",
      "transactions.rental.title": "Rental {0}",
      "transactions.type.bonus": "Reward",
      "transactions.type.bonus.description": "Credit for finishing First steps",
      "transactions.type.fee": "Fee",
      "transactions.type.payment": "Payment",
      "transactions.type.payment.reason": "Reason",
      "transactions.type.referrerBonus": "Credit",
      "transactions.type.referrerBonus.description": "Reward for inviting a friend to join getaway",
      "trip.cancel.dialog.title": "Do you want to cancel your booking?",
      "trip.damage.report": "Report a damage",
      "trip.driving.refuel": "Refuel now",
      "trip.service.type.refuel": "Refueling",
      "trip.service.type.relocate": "Relocating",
      "trip.service.type.relocateAndRelease": "Relocate And Release",
      "trip.service.type.title": "What was the performed service?",
      "trip.support": "Contact service",
      "tripHistory.complain": "Report a complaint",
      "tripHistory.end": "Return",
      "tripHistory.fee": "Rental",
      "tripHistory.revenue": "Rental earnings",
      "tripHistory.services": "Executed service(s)",
      "tripHistory.start": "Takeover",
      "tutorial.listcar.button": "CHECK YOUR EARNINGS",
      "tutorial.listcar.step1.message": "Your car stands idle around for 23h a day. Put your car to work with one hassle-free click whenever you park it.",
      "tutorial.listcar.step1.title": "Generate\nincome\nwhile parking",
      "tutorial.listcar.step2.message": "You can set the availability and your car's earnings per km. Earnings are credited automatically.",
      "tutorial.listcar.step2.title": "You\nare in\ncontrol",
      "tutorial.listcar.step3.message": "We verify each traveler’s identity and have your car fully covered by our insurance partner „Die Gothaer“.",
      "tutorial.listcar.step3.title": "We take\ncare of\nyour piece\nof mind",
      "tutorial.listcar.step4.message": "Let’s use our resources more efficiently. Join us to save the environment without losing the convenience of owning a car.",
      "tutorial.listcar.step4.title": "Great for\nyou. Great \nfor the\nenvironment",
      "tutorial.listcar.step5.message": "Join thousands of car owners and list your car by simply taking a photo of your car registration license via the app.",
      "tutorial.listcar.step5.title": "How \nmuch can \nyour car\nearn?",
      "tutorial.map.listCar.button": "CHECK NOW",
      "tutorial.map.listCar.message": "Make a free and nonbinding check, how much your car could earn for you.",
      "tutorial.map.listCar.title": "How much could\nyour car earn?",
      "tutorial.map.step1.message": "If there’s anything we can do for you, please let us know.",
      "tutorial.map.step1.title": "We are just\na tap away",
      "tutorial.map.step2.message": "Access your profile and all your settings by simply clicking the user icon.",
      "tutorial.map.step2.title": "Stay\ntuned",
      "tutorial.map.step3.message": "Just choose for how long you want the car, and we will show you all the available cars until that time.",
      "tutorial.map.step3.title": "Get a car\nget away!",
      "tutorial.map.step4.button": "GET STARTED",
      "tutorial.map.step4.message": "Complete your profile and get free credit. It takes usually less than 2 minutes.",
      "tutorial.map.step4.title": "Get your\nstreet credibility",
      "tutorial.signup.button": "GET STARTED",
      "tutorial.signup.extraAction": "I've an account »",
      "tutorial.signup.step1.message": "Unlock & drive any private car around with your smartphone",
      "tutorial.signup.step1.title": "UNLOCK\nANY\nCAR",
      "tutorial.signup.step2.message": "Charges per kilometer keep you relaxed and costs transparent.",
      "tutorial.signup.step2.title": "STAY\nRELAXED",
      "tutorial.signup.step3.message": "Rent your car spontaneously whenever you park it.",
      "tutorial.signup.step3.title": "YOUR\nCAR\nCAN\nWORK",
      "tutorial.signup.step4.message": "Release your car with only one click on your smartphone",
      "tutorial.signup.step4.title": "JUST\nA\nCLICK",
      "unavailablecar.request": "Start Booking",
      "until": "Until",
      "update.address.add": "ADD",
      "update.address.city": "City",
      "update.address.country": "Country",
      "update.address.desc": "Please note that this address needs to be your official living address.",
      "update.address.germany": "Germany",
      "update.address.location.error": "Our service is not available in your country yet.",
      "update.address.number": "Number",
      "update.address.search": "Search address",
      "update.address.street": "Street",
      "update.address.success": "Thanks! Your address has been successfully updated.",
      "update.address.update": "UPDATE",
      "update.address.zip": "ZIP Code",
      "update.email.desc": "Your current email address is %@. Please enter your new email address and confirm with your current password.",
      "update.email.new": "New email address",
      "update.email.title": "Change your email address",
      "update.email.update": "UPDATE",
      "update.email.wrong.pass": "Incorrect password",
      "update.notifications.channels": "CHANNELS",
      "update.notifications.email": "Via email",
      "update.notifications.email.desc": "Permission to contact you via email.",
      "update.notifications.mail": "Via mail",
      "update.notifications.mail.desc": "Permission to contact you via mail.",
      "update.notifications.push": "Via push notification",
      "update.notifications.push.desc": "Permission to contact you via push notification.",
      "update.notifications.push.wizard.button": "GO TO SETTINGS",
      "update.notifications.push.wizard.desc": "Please enable push notifications in your settings to get immediate updates.",
      "update.notifications.push.wizard.latter": "NOT NOW",
      "update.notifications.push.wizard.latter.error": "Push notifications are disabled",
      "update.notifications.push.wizard.title": "Enable push notifications",
      "update.notifications.sms": "Via SMS",
      "update.notifications.sms.desc": "Permission to contact you via SMS.",
      "update.notifications.title": "NOTIFICATIONS",
      "update.notifications.topics.name.bookings.description": "Receive notifications about your bookings.",
      "update.notifications.topics.name.bookings.title": "Bookings Notifications",
      "update.notifications.topics.name.monthlyOverview.description": "Receive monthly financial reports for your account",
      "update.notifications.topics.name.monthlyOverview.title": "Monthly Reports",
      "update.password.forgot.error": "Please enter your email address and tap \"Forgot\" again. We will send you an email with the instructions to reset your password.",
      "update.password.text": "We've just sent an email to %@ with further instructions.",
      "update.password.wizard.desc": "Please enter your new password",
      "update.password.wizard.newpassword": "New password",
      "update.password.wizard.title": "New password",
      "update.password.wizard.update": "UPDATE",
      "user.DL.completed.message": "To change your driving license, please contact our service team.",
      "user.DL.inProgress.message": "Your driving licence is being verified, please wait.",
      "user.DL.resubmission.message": "To add your driving license, please contact our service team.",
      "user.ID.completed.message": "To change your Identity card, please contact our service team.",
      "user.ID.inProgress.message": "Your Identity card is being verified, please wait.",
      "user.action.support.text": "Open LiveChat",
      "user.add_to_circle.brand_not_matches": "Circle's and User's Brands are different so User cannot be added to that Circle",
      "user.address.completed.message": "To change your address, please contact our service team.",
      "user.address.inProgress.message": "Your address is being verified, please wait.",
      "user.car.selector.listed": "%@ (from %@)",
      "user.car.selector.release_ready": "%@ (Unreleased)",
      "user.car.selector.released": "%@ (Released)",
      "user.car.selector.rented": "%@ (In Rental)",
      "user.car.selector.submitted": "New Car (from %@)",
      "user.car.selector.title": "Select your car",
      "user.circle.description": "Do you really want to leave this circle?",
      "user.circle.leave": "Leave this circle",
      "user.circle.leave.description": "By leacing this circle, you will not have access to circle cars & offers any more. Are you sure you want to proceed?",
      "user.circle.switchAccess": "Switch access to this circle",
      "user.deletePayment.dialog.message": "Are you sure ?",
      "user.deletePayment.dialog.no": "Cancel",
      "user.deletePayment.dialog.title": "Delete payment",
      "user.deletePayment.dialog.yes": "Delete",
      "user.email.completed.message": "To change your email address, please contact our service team.",
      "user.logout.cancelReservation.message": "Please cancel the reservation before moving forward.",
      "user.logout.cancelReservation.ok": "Ok",
      "user.logout.dialog.message": "Are you sure ?",
      "user.logout.dialog.no": "Cancel",
      "user.logout.dialog.title": "Logout",
      "user.logout.dialog.yes": "Logout",
      "user.noPayment.action": "Add payment method",
      "user.noPayment.message": "You need to add a valid payment method first",
      "user.notNow": "Not now",
      "user.payment.not.added": "You don't have any payment methods, Please add one first ",
      "user.phone.completed.message": "To change your phone number, please contact our service team.",
      "user.selectPayment.noCC": "I don't have a credit card",
      "user.terminate.dialog.message": "By proceeding all your data will be lost and your account can't be recovered!",
      "user.terminate.dialog.no": "Cancel",
      "user.terminate.dialog.title": "Delete your account",
      "user.terminate.dialog.yes": "DELETE NOW",
      "user.transaction.settled.success": "Transaction has been settled successfuly",
      "usercar.fillprofile.footnote": "Check out our %@ how to increase your rental earnings.",
      "usercar.fillprofile.footnote.url": "https://service.get-a-way.com/wie-vermiete-ich-mein-auto#mein-autoprofil",
      "usercar.fillprofile.footnote.url.text": "help center",
      "usercar.item.blocked.title": "Can't release. Please contact our Service Team",
      "usercar.item.eligible.title": "In queue to get selected",
      "usercar.item.equipment_ready.title": "Soon to be equipped",
      "usercar.item.equipped.title": "Your car is ready to join the fleet.",
      "usercar.item.listed.title": "Your car is on our waiting list.",
      "usercar.item.release_ready.title": "Unreleased",
      "usercar.item.released.title": "Released until",
      "usercar.item.selected.title": "Selected- look forward to get equipped.",
      "usercar.item.submitted.description": "Submitted on",
      "usercar.item.submitted.title": "Thanks for submitting your car's license.",
      "usercar.item.terminated.description": "Terminated on ",
      "usercar.item.terminated.title": "Your car will be removed",
      "usercar.photo.description": "Take a photo from the front, the sides and the interior.",
      "usercar.profile.blocked.status": "BLOCKED",
      "usercar.profile.blocked.title": "This car is currently blocked.",
      "usercar.profile.eligible.description": "Your car is in the queue, now. Speed up the onboarding and share your personal invitation code with your friends and neighbours.",
      "usercar.profile.eligible.status": "IN QUEUE",
      "usercar.profile.eligible.title": "Awesome %@, your car has past all tests 💪",
      "usercar.profile.equipment_ready.description": "Our technician will meet you at the agreed time and address. He will call you 30 minutes before it actually starts. Please have your car key and car's registration license with you.\n\nIf you need to cancel the appointment please inform us minimum 48 hours in advance to avoid any possible cancelation fees.",
      "usercar.profile.equipment_ready.status": "INSTALLATION",
      "usercar.profile.equipment_ready.title": "%@, your car will be equipped soon",
      "usercar.profile.equipped.description": "Just a moment. As soon as the final checks are completed, we will activate your car.",
      "usercar.profile.equipped.status": "CONNECTED",
      "usercar.profile.equipped.title": "%@, your car has been equipped",
      "usercar.profile.firssteps.description": "In order to earn money with your car please finish the First Steps.",
      "usercar.profile.firssteps.title": "Please complete your account infos",
      "usercar.profile.firststeps.description": "Following this, we are able to check what your car could earn for you.	",
      "usercar.profile.firststeps.title": "%@, please update your https://applink.get-a-way.com/user-challenges-firststeps, now",
      "usercar.profile.firststeps.title.url": "https://applink.get-a-way.com/user-challenges-firststeps",
      "usercar.profile.firststeps.title.url.text": "user profile",
      "usercar.profile.listed.description": "Specify now, how your car should be offered in the app. You can modify these infos at any time later, too.",
      "usercar.profile.listed.status": "VERIFYING",
      "usercar.profile.listed.title": "%@, enrich your car's profile now",
      "usercar.profile.release.availableuntil": "Release now until",
      "usercar.profile.release_ready.status": "PRIVATE",
      "usercar.profile.released.availableuntil": "Released until",
      "usercar.profile.released.status": "RELEASED",
      "usercar.profile.rented.status": "IN RENTAL",
      "usercar.profile.selected.description": "To ensure a convenient and safe rental experience, we'll equip your car with our SafetyKit once. Our technician will call you soon to find an  appointment for the installation.",
      "usercar.profile.selected.status": "SELECTED",
      "usercar.profile.selected.title": "%@, your car is ready to get equipped 🙌",
      "usercar.profile.service.status": "IN SERVICE",
      "usercar.profile.submit.action": "Submit car license now",
      "usercar.profile.submit.description": "Hundreds have joined already. Check now, how much you car could earn for you. Its free and without any engagement.",
      "usercar.profile.submit.left": "MY CAR",
      "usercar.profile.submit.status": " ",
      "usercar.profile.submit.title": "start to earn money with your car, today!",
      "usercar.profile.submitted.description": "Specify now, how your car should be offered in the app. You can modify these infos at any time later, too.",
      "usercar.profile.submitted.status": "VERIFYING",
      "usercar.profile.submitted.title": "%@, enrich your car's profile now",
      "usercar.profile.terminated.status": "TERMINATED",
      "usercar.profile.title": "My Car",
      "usercar.review.novalue": "✨ New car",
      "usercar.troubleshoot": "Troubleshooting",
      "usercar.troubleshoot.dialog.message": "As a car owner, you can now unlock your car and deactivate the immobilizer. After, your car is not locked via GETAWAY anymore. We strongly recommend to proceed only if you are next to your car.",
      "usercar.troubleshoot.dialog.title": "Troubleshooting",
      "usercar.troubleshoot.dialog.unlock": "Unlock car and immobilizer",
      "usercarprofile.example.title": "What the driver would pay",
      "usercarprofile.price.details.footnote": "Learn more about setting the perfect pricing in our %@.",
      "usercarprofile.price.details.footnote.url": "https://service.get-a-way.com/wie-vermiete-ich-mein-auto/mein-autoprofil/so-legst-du-den-idealen-preis-fur-dein-auto-fest",
      "usercarprofile.price.details.footnote.url.text": "help center ",
      "usercarprofile.price.indicator.title": "you earn",
      "usercarprofile.price.slider.aboveaverage": "above average",
      "usercarprofile.submit.status": " ",
      "validation.accountholder.invalid": "Please enter a valid account holder name",
      "validation.address.street.invalid": "Please enter a valid street name",
      "validation.address.streetnumber.invalid": "Please enter a valid street number",
      "validation.address.zip.invalid": "Please enter a valid postal code",
      "validation.email.invalid": "Please enter a valid email address.",
      "validation.iban.invalid": "Please enter a valid IBAN",
      "validation.name.invalid": "2 letters min",
      "validation.password.invalid": "Min. 8 characters (incl. 1 cap/ lowercase/ no./ special sign)",
      "validation.phone.invalid": "This mobile phone number can't be confirmed. Please try another one.",
      "validation.required": "Please fill in.",
      "validation.required.empty": "This field can't be empty",
      "validator.name.error": "Name must be at least 2 characters long.",
      "validator.password.error": "Password must contain at least 6 characters and one digit.",
      "validator.streetNumber.error": "Please enter a valid street number",
      "verify.phone.failed": "Incorrect code",
      "verify.phone.passwordView.description": "Your current mobile number is %@. Please enter your password to change it.",
      "verify.phone.passwordView.title": "Change your mobile number",
      "verify.phone.resend.success": "You'll shortly receive an SMS with the confirmation code",
      "verify.phone.success": "Thanks! Your phone number has been successfully updated.",
      "video": "video",
      "violation.adyenPaymentProvider.responseValidator.authorized.assertTrue": "This credit card can’t be added. Please try another one.",
      "violation.adyenPaymentProvider.responseValidator.cvcDeclined.assertFalse": "Incorrect CVC. Please check your credit card data and try again.",
      "violation.adyenPaymentProvider.responseValidator.noResponse.assertFalse": "Could not connect to payment provider. Please try again.",
      "violation.adyenPaymentProvider.responseValidator.notificationSuccess.assertTrue": "This credit card can’t be added. Please try another one.",
      "violation.apiVersionCheck.version.min": "Your app version is not supported any longer, please update it via the App Store.",
      "violation.booking.bookingPayInAllowedValidator.payInAllowed.assertTrue": "PayIn as blocked payment for booking not allowed if user has withdrawable balance. That balance should be paid to the user first. He should contact CS to solve this.",
      "violation.booking.bookingPaymentValidator.blockedPaymentNotNeededOrConfirmed.assertTrue": "Deposit payment refused by your bank. Please try another payment method.",
      "violation.booking.bookingRequestValidator.canBook.assertTrue": "Unfortunately you cannot take this vehicle because you have already booked another vehicle.",
      "violation.booking.bookingRequestValidator.confirmedCarValid.assertTrue": "This vehicle is unfortunately not available for the selected rental period.",
      "violation.booking.bookingRequestValidator.watchlistCheckPassed.assertTrue": "Please make sure that your profile is completed. Contact customer support if you need help.",
      "violation.booking.bookingRequestValidator.withinOpeningHours.assertTrue": "Car is unavailable. out of working hours",
      "violation.booking.bookingRequestValidator.youngDriver.assertFalse": "Please be patient. Your driving license needs to be issues at least two years ago. You will automatically approved and notified as soon this happens.",
      "violation.car.chassisNumberUniqueValidator.chassisNumber.assertTrue": "This chassisNumber is already in the system",
      "violation.car.damages.characteristics.notNull": "Please choose the damage characteristics.",
      "violation.car.damages.component.notNull": "Please choose a damage component.",
      "violation.car.damages.where.notNull": "Please define the location of the damage.",
      "violation.car.generateOwnerAccessToken.rented.assertFalse": "The car can't be opened during an active rental.",
      "violation.car.onlineCheck.online.assertTrue": "Connection lost. Please change car's location and try, again. Or contact our Service Team.",
      "violation.car.owner.notNull": "The car needs to be assigned to an owner.",
      "violation.car.plateUniqueValidator.plate.assertTrue": "This plate is already in the system",
      "violation.car.registrationCertificate.plate.pattern": "Please enter a valid car plate.",
      "violation.car.registrationCertificate.plate.plateRegion": "The region of the entered car plate is unknown.",
      "violation.car.releaseCheck.availableUntil.assertTrue": "The return date is too early ! Please chose a longer time frame.",
      "violation.car.releaseCheck.basicPrice.notNull": "Price is not set ! Please set the price per km for your car before releasing.",
      "violation.car.releaseCheck.doorsClosed.assertTrue": "Doors are open! Please close them to continue.",
      "violation.car.releaseCheck.fuelCardIn.assertTrue": "Fuel card is not in its place! Please return it to continue.",
      "violation.car.releaseCheck.fuelLevel.min": "Fuel level is below 25%, Please refuel the car and try again.",
      "violation.car.releaseCheck.ignitionOff.assertTrue": "Ignition is on! Please turn off the car to continue .",
      "violation.car.releaseCheck.keyFobIn.assertTrue": "Key is not in its place! Please return it to continue.",
      "violation.car.releaseCheck.online.assertTrue": "Connection lost. Please change car's location and try, again. Or contact our Service Team.",
      "violation.car.releaseCheck.paymentMethodExists.assertTrue": "Please add a valid payment method.",
      "violation.car.releaseCheck.windowsClosed.assertTrue": "Windows are open! Please close them to continue.",
      "violation.clientVersionCheck.version.min": "Your app version is not supported any longer, please update it via the App Store.",
      "violation.device.alreadyPairedValidator.device.assertTrue": "Your user account is not paired with the current smartphone. Please contact our Service Team to continue.",
      "violation.driversLicenseTokenData.validation.validToken.assertTrue": "Your session is invalid or has expired. Please try again.",
      "violation.fleetAdmin.validPasswordValidator.password.assertTrue": "Please enter a valid password (six characters long, incl. at least one digit)",
      "violation.forgotPasswordResource.resetPasswordCheck.validToken.assertTrue": "This link is not valid anymore. Please contact our service team for more information.",
      "violation.installation.mechanic.notNull": "The installation needs to be assigned to a registered mechanic.",
      "violation.paymentMethod.existingPaymentMethod.isBlocked.assertFalse": "You can't add this payment method. Please try again with another payment method.",
      "violation.paymentResource.paymentMethodTypeCheck.paymentMethodNotAllowed.assertFalse": "You can't add this payment method. Please try again with another payment method.",
      "violation.phoneNumber.noNewNumberWhenPairedValidator.phoneNumber.assertTrue": "Not allowed. Your Device is Paired. Please contact our Service Team to continue. ",
      "violation.phoneNumber.userPhoneNumberUniqueValidator.phoneNumber.assertTrue": "This mobile number can't be used. Please try another one",
      "violation.phoneNumber.validPhoneNumberValidator.phoneNumber.assertTrue": "Invalid code, please make sure to enter the right one or request a new code.",
      "violation.phoneNumber.validVerificationCodeValidator.phoneNumber.assertTrue": "Please enter correct phone number",
      "violation.rentalResource.serviceTripCheck.userUnauthorized.assertFalse": "User is not allowed to do service trips",
      "violation.sepa.ibanValidator.sepa.assertTrue": "Please enter a valid IBAN.",
      "violation.serviceCircle.serviceCircleValidator.pricingEmpty.assertTrue": "Changing pricing for Service UserGroups is not supported",
      "violation.trip.cancellation.reserved.assertTrue": "Only valid reservations can be canceled.",
      "violation.trip.driverQualificationValidator.addressVerified.assertTrue": "Please register your address to continue.",
      "violation.trip.driverQualificationValidator.creditCheck.assertTrue": "Please contact our service team to complete your user data.",
      "violation.trip.driverQualificationValidator.identityChecked.assertTrue": "Please contact our service team to complete your user data.",
      "violation.trip.driverQualificationValidator.paymentMethodExists.assertTrue": "Hmm, payment is not linked! Please submit a payment method to continue.",
      "violation.trip.driverQualificationValidator.phoneNumberVerified.assertTrue": "Please verify your phone number on this device to continue.",
      "violation.trip.driverQualificationValidator.positiveBalance.assertTrue": "Insufficient funds. Please make sure you don't have a negative balance before starting a new trip.",
      "violation.trip.driverQualificationValidator.validLicence.assertTrue": "Your driving license needs to be verified to rent cars. Please check the current status in your account settings.",
      "violation.trip.finishCheck.bitLessFuelOnReturn.assertFalse": "I confirm that the fuel level matches the trip start.",
      "violation.trip.finishCheck.correctLocation.assertTrue": "Too far from the return location ! Please check the return location or finish the rental regardless; Fees may apply.",
      "violation.trip.finishCheck.extremelyLowFuelLevel.assertFalse": "I choose the refuel service <10% (50,- €)",
      "violation.trip.finishCheck.finishLocationAllowed.assertTrue": "I have returned the car",
      "violation.trip.finishCheck.fuelLevel.min": "I choose refuel service (5 €)",
      "violation.trip.finishCheck.initialUnlockIsSet.assertTrue": "The car has to be initially unlocked in order to finish the rental.",
      "violation.trip.finishCheck.insideArea.assertTrue": "I choose return service (10€ + 4.9€/km)",
      "violation.trip.finishCheck.online.assertTrue": "failed to connect to the vehicle, please try again or contact customer support",
      "violation.trip.postReservation.accessToken.assertTrue": "This vehicle can't be reserver at the moment.",
      "violation.trip.postReservation.accessToken.notNull": "Ups..something went wrong. Please try again later.",
      "violation.trip.postReservation.blockedPaymentConfirmed.assertTrue": "Your bank has refused this transaction. Please contact your bank for more info or try another payment method.",
      "violation.trip.rating.onlyFinished": "The rental has to be finished successfully to add a rating.",
      "violation.trip.rentalReservationValidator.addressVerified.assertTrue": "Please register your address to continue.",
      "violation.trip.rentalReservationValidator.backByConflictsWithBooking.assertFalse": "This vehicle is already booked in this timeframe. Please choose another time or another vehicle.",
      "violation.trip.rentalReservationValidator.blockedByUpcomingBooking.assertFalse": "This vehicle has a booking starting soon, please try another one.",
      "violation.trip.rentalReservationValidator.carAvailable.assertTrue": "Oops! The selected car is not available anymore.",
      "violation.trip.rentalReservationValidator.carStatusReleased.assertTrue": "Oops, car was taken offline. Please look for another one.",
      "violation.trip.rentalReservationValidator.creditworthinessApproved.assertTrue": "Please contact our service team to complete your user data.",
      "violation.trip.rentalReservationValidator.identityChecked.assertTrue": "Your name, birthday and registered address couldn't be approved, yet. Please contact us via the live chat icon to finish your verification.",
      "violation.trip.rentalReservationValidator.online.assertTrue": "This vehicle is currently unavailable and can not be rented.",
      "violation.trip.rentalReservationValidator.paymentMethodExists.assertTrue": "Please add a valid payment method to continue.",
      "violation.trip.rentalReservationValidator.positiveBalance.assertTrue": "Insufficient funds. Please make sure you don't have a negative balance before starting a new trip.",
      "violation.trip.rentalReservationValidator.serviceTripAllowed.assertTrue": "User is not allowed to perform service trips on this car.",
      "violation.trip.rentalReservationValidator.userMarkedForFraud.assertFalse": "😐 Unfortunately the action you attempt is not available for the moment. Please contact our ServiceTeam for further information.",
      "violation.trip.rentalReservationValidator.userMarkedForFraud.assertTrue": "😐 Unfortunately the action you attempt is not available for the moment. Please contact our ServiceTeam for further information.",
      "violation.trip.rentalReservationValidator.validLicence.assertTrue": "Your driving license needs to be verified to rent cars. Please check the current status in your account settings.",
      "violation.trip.rentalReservationValidator.watchlistCheckPassed": "Please make sure that your profile is completed. Contact customer support if you need help.",
      "violation.trip.rentalReservationValidator.youngDriver.assertFalse": "Please be patient. Your driving license needs to be issues at least two years ago. You will automatically approved and notified as soon this happens.",
      "violation.trip.reservationValidator.manualGearRestricted.assertFalse": "Sorry but this car have a manual gearbox and it seems like you're not allowed to drive that! If this is a mistake please contact our support. ",
      "violation.user.account.paymentMethods.sepa.ibanValid": "Please enter a valid IBAN",
      "violation.user.archiveCheck.hasOpenBookingRequests.assertFalse": "It seems like you have some open invoices please contact our customer support to continue.",
      "violation.user.archiveCheck.hasOpenTransactions.assertFalse": "Can't continue due to either open or unsettled invoice. Please try again later, as settlement is dependent on payment confirmation.",
      "violation.user.dateOfBirth.min": "To rent a vehicle, you must have a valid Class B license for at least 2 years.",
      "violation.user.device.alreadyPaired": "Your user account is not paired with the current smartphone. Please contact our Service Team to continue.",
      "violation.user.email.notNull": "Please add a valid email address to your user account.",
      "violation.user.email.pattern": "Please add a valid email address to your user account.",
      "violation.user.email.userEmail.Unique": "Hmm, it seems like we already have an account for this email, do you want to reset your password?",
      "violation.user.email.userEmailUnique": "Hmm, it seems like we already have an account for this email, do you want to reset your password?",
      "violation.user.firstName.notNull": "Please add a valid first name to your user account.",
      "violation.user.firstName.size": "Please add a valid first name to your user account.",
      "violation.user.lastName.size": "Your last name must contain at least 2 characters.",
      "violation.user.password.validPassword": "Please enter a valid password (six characters long, incl. at least one digit)",
      "violation.user.phoneNumber.phoneNumber.noNewNumberWhenPaired": "Not allowed. Your Device is Paired. Please contact our Service Team to continue.",
      "violation.user.phoneNumber.phoneNumber.validVerificationCode": "Invalid code, please make sure to enter the right one or request a new code.",
      "violation.user.phoneNumber.userPhoneNumberUnique": "This mobile number can't be used. Please try another one.",
      "violation.user.phoneNumber.validPhoneNumber": "Please add a valid mobile number to your user account.",
      "violation.user.phoneNumber.validVerificationCode": "This verification code is invalid. Please request a new one.",
      "violation.user.referredBy.referralCodeExists": "Hmm, it seems like this referral code doesn't exist, can you please double check or contact our customer support, they would love to help!",
      "violation.user.requireDevice.hasPairedDevice.assertTrue": "Please verify your mobile number on this smartphone to continue.",
      "violation.user.requireDevice.isCorrectDevice.assertTrue": "This phone is not authorised for this interaction. Please contact our service team.",
      "violation.user.userEmailUniqueValidator.email.assertTrue": "Hmm, it seems like we already have an account for this email, do you want to reset your password?",
      "violation.user.validPasswordValidator.password.assertTrue": "Please enter a valid password (six characters long, incl. at least one digit)",
      "violation.validatePaymentMethods.haveBlockedPaymentIfPayInOnly": "A deposit using payment method PayIn must be done before Rental",
      "violation.validatePaymentMethods.haveBlockedPaymentIfPayInOnly.assertTrue": "A deposit using payment method PayIn must be paid before Rental.",
      "wait": "Please wait..",
      "wj.map.filter.violation.duration": "Range selected should be at least one hour",
      "wj.map.filter.violation.min": "Please make sure selected dates are valid",
      "wj.map.filter.violation.order": "Please make sure selected dates are valid",
      "wj.schufa.birthday.violation.max": "Please make sure selected date is valid",
      "wj.schufa.birthday.violation.min": "Please make sure selected date is valid",
      "wma.trackingPage.extendedArea.name": "Driving to extended area",
      "yes": "Yes",
      "yesterday": "yesterday",
    },
};