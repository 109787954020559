import initialState from './InitialState';
import TYPES from '../../../@types/redux/store/AreasTypes';
import { AreasAction, AreasState } from '../../../@types/redux/store/AreasTypes';

export default (
  state: AreasState = initialState,
  action: AreasAction
): AreasState => {
  switch (action.type) {
    case TYPES.SET_AREAS: {
      return action.payload.areas;
    }
    default: {
      action as never; // eslint-disable-line no-unused-expressions
      return state;
    }
  }
};
