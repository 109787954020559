import { addHours } from 'date-fns';
import rentalClient from '../../../clients/rentalClient';
import {
  setNetworkActivity,
  setNetworkSuccess,
  setNetworkError,
} from '../../networkStatus';
import {
  CLIENT_TYPE,
  OutputDamage,
  S3TemporaryAccessData,
} from '../../../@types';
import { RentalData, CarStatusData, FuelInfo } from '../../../@types';
import TYPES from '../../../@types/redux/store/RentalTypes';
import { reloadDeviceInfo, setAccessToken } from '../../bluetooth';
import { APP } from '../../../enums';
import { setNotification } from '../../ui/notifications';
import DataNativeBridge from '../../../native/dataNativeBridge';
import { setMapContent, setOpened } from '../../ui/map';
import { AnalyticsManager, BluetoothManager, S3Manager } from '../../../native';
import { LocalizePropType } from '../../../enhancers/withTextLocalizer';
import { setAppRated } from '../../appData/common';
import {
  ClearCarDamages,
  Dispatch,
  GetState,
  SetCanLock,
  SetCarDamages,
  SetIsBookingStartedEventSent,
  SetIsCarChecked,
  SetIsServiceReportSubmitted,
  SetLastFinishedRentalDate,
} from '../../../@types';
import { clearNewDamages, setNewDamage } from '../../ui/damages';
import apiClient from '../../../clients/apiClient';
import { searchCars, setRegionChangeNo } from '../../carsSearch';
import { clearServiceTypes } from '../../appData/serviceTypes/Actions';
import { getBookings } from '../bookings';
import {
  isRentalCard,
  isRentalExpired,
  isRentalMapContent,
  isRentalOngoing,
  mapAnnotationFromCarMode,
  mapCardFromCarMode,
  isIOS,
  showAppReviewDialog,
  helpAction,
  showBlockPaymentDialog,
  isWeb,
  isBefore24hrs,
} from '../../../helpers';
import { awsS3Client } from '../../../clients';
import { RentalImagePath } from '../../../clients/awsS3Client';
import { PermissionsManager } from '../../../config';

export const clearForceRentalDriveMode = () => ({
  type: TYPES.CLEAR_FORCE_RENTAL_DRIVE_MODE,
});

export const forceRentalDriveMode = () => ({
  type: TYPES.FORCE_RENTAL_DRIVE_MODE,
});

export const clearCarDamages = (): ClearCarDamages => ({
  type: TYPES.CLEAR_CAR_DAMAGES,
});

export const setCarDamages = (
  carDamages: Array<OutputDamage>
): SetCarDamages => ({
  type: TYPES.SET_CAR_DAMAGES,
  payload: {
    carDamages,
  },
});

export const setLastFinishedRentalDate = (
  lastFinishedRentalDate: Date
): SetLastFinishedRentalDate => ({
  type: TYPES.SET_LAST_FINISHED_RENTAL_DATE,
  payload: {
    lastFinishedRentalDate,
  },
});

export const setCanLock = (canLock: boolean): SetCanLock => ({
  type: TYPES.SET_CAN_LOCK,
  payload: {
    canLock,
  },
});

export const setIsServiceReportSubmitted = (
  isServiceReportSubmitted: boolean
): SetIsServiceReportSubmitted => ({
  type: TYPES.SET_IS_SERVICE_REPORT_SUBMITTED,
  payload: {
    isServiceReportSubmitted,
  },
});

export const setIsCarChecked = (isCarChecked: boolean): SetIsCarChecked => ({
  type: TYPES.SET_IS_CAR_CHECKED,
  payload: {
    isCarChecked,
  },
});

export const setIsBookingStartedEventSent = (
  isBookingStartedEventSent: boolean
): SetIsBookingStartedEventSent => ({
  type: TYPES.SET_IS_BOOKING_STARTED_EVENT,
  payload: {
    isBookingStartedEventSent,
  },
});

export const clearFinishRentalViolations = () => async (dispatch: Dispatch) => {
  dispatch({
    type: TYPES.CLEAR_FINISH_RENTAL_VIOLATIONS,
  });
};

export const setDriverCarStatus =
  (driverCarStatus: CarStatusData) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { driverCarStatus: existingStatus, rentalData } =
      getState().userData.rental;

    if (!existingStatus || existingStatus.version !== driverCarStatus.version) {
      dispatch({
        type: TYPES.SET_DRIVER_CAR_STATUS,
        payload: {
          driverCarStatus,
        },
      });

      // update rental data if needed
      if (
        existingStatus &&
        existingStatus.ignitionOff === true &&
        driverCarStatus.ignitionOff === false
      ) {
        // eslint-disable-next-line no-use-before-define
        dispatch(setRentalData({ ...rentalData, carMode: 'driving' }, true));
      }

      if (
        existingStatus &&
        existingStatus.ignitionOff === false &&
        driverCarStatus.ignitionOff === true
      ) {
        // eslint-disable-next-line no-use-before-define
        dispatch(setRentalData({ ...rentalData, carMode: 'parking' }, true));
      }
    }
  };

export const getDriverCarStatus =
  (
    rentalId: string,
    activateLoader: boolean = false,
    successCallback?: () => any
  ) =>
  async (dispatch: Dispatch) => {
    const networkActivity = activateLoader
      ? CLIENT_TYPE.RENTAL_CLIENT.GET_DRIVER_CAR_STATUS_LOADER
      : CLIENT_TYPE.RENTAL_CLIENT.GET_DRIVER_CAR_STATUS;
    dispatch(setNetworkActivity(networkActivity));
    // $FlowFixMe
    const { notModified, data, error } = await rentalClient.getDriverCarStatus(
      rentalId,
      true
    );

    if (error) {
      dispatch(setNetworkError(networkActivity, error));
    } else {
      if (!!data && !notModified) {
        DataNativeBridge.setCarStatusData(data);
        dispatch(setDriverCarStatus(data));
      }

      if (typeof successCallback === 'function') {
        successCallback();
      }

      dispatch(setNetworkSuccess(networkActivity));
    }
  };

export const clearRental = () => (dispatch: Dispatch, getState: GetState) => {
  const { rentalData } = getState().userData.rental;
  const { mapType, cardType } = getState().ui.map.content;

  if (isRentalMapContent(mapType) || isRentalCard(cardType)) {
    dispatch(setMapContent(null, 'none', null, true));
  }

  if (rentalData) {
    DataNativeBridge.clearRentalData();
    dispatch(setOpened(false));
    dispatch({
      type: TYPES.CLEAR_RENTAL_DATA,
    });
    dispatch(setRegionChangeNo(0));
    dispatch(reloadDeviceInfo());
    // this is to avoid calling Service report again before rental finish
    setTimeout(() => dispatch(setIsServiceReportSubmitted(false)), 5000);
    dispatch(searchCars());
  }

  dispatch(clearForceRentalDriveMode());
  clearFinishRentalViolations();
};

const setRentalMapContent =
  (rentalData: RentalData) => (dispatch: Dispatch, getState: GetState) => {
    if (isRentalOngoing(rentalData)) {
      const prevMapType = getState().ui.map.content.mapType;
      const prevMapContentId = getState().ui.map.content.id;
      const { opened } = getState().ui.map;
      const isOfflineServiceMode = rentalData.type === 'plannedServiceTrip';

      if (
        mapAnnotationFromCarMode(rentalData.carMode) === 'openedCar' &&
        !isOfflineServiceMode
      ) {
        dispatch(setOpened(true));
        setTimeout(() => {
          dispatch(
            setMapContent(
              mapAnnotationFromCarMode(rentalData.carMode),
              mapCardFromCarMode(rentalData.carMode, isOfflineServiceMode),
              rentalData?.carData?.id
            )
          );
        }, 500);
      } else if (opened) {
        setTimeout(() => {
          dispatch(
            setMapContent('openedCar', 'openedCar', rentalData?.carData?.id)
          );
        }, 500);
      } else {
        dispatch(
          setMapContent(
            isOfflineServiceMode
              ? 'offlineService'
              : prevMapType || mapAnnotationFromCarMode(rentalData.carMode),
            mapCardFromCarMode(rentalData.carMode, isOfflineServiceMode),
            prevMapContentId || rentalData?.carData?.id
          )
        );
      }
    } else {
      dispatch(setMapContent(null, 'none', null));
    }
  };

export const getCarDamages =
  (
    carId: string,
    successCallbackFunction?: (data: Array<OutputDamage>) => any,
    failureCallbackFunction?: () => any
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.GET_CAR_DAMAGES));
    // $FlowFixMe
    const { data, error } = await rentalClient.getCarDamages(carId);

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.GET_CAR_DAMAGES, error)
      );

      if (typeof failureCallbackFunction === 'function') {
        failureCallbackFunction();
      }
    } else {
      dispatch(setCarDamages(data!));
      dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.GET_CAR_DAMAGES));

      if (typeof successCallbackFunction === 'function') {
        successCallbackFunction(data!);
      }
    }
  };
export const setRentalData =
  (rentalData: RentalData, forceUpdate: boolean = false) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { isBookingStartedEventSent, rentalData: existingRentalData } =
      getState().userData.rental;

    if (
      isRentalOngoing(rentalData) &&
      rentalData?.carData?.vehicleConnection === 'cloudboxx'
    ) {
      BluetoothManager.activateBluetooth();
    }

    if (!existingRentalData && isRentalOngoing(rentalData)) {
      dispatch(getCarDamages(rentalData?.carData?.id!));
    }

    if (
      !existingRentalData ||
      (existingRentalData &&
        ((existingRentalData.id !== rentalData.id &&
          isRentalOngoing(rentalData)) ||
          (existingRentalData.id === rentalData.id &&
            (existingRentalData?.distance! < rentalData?.distance! ||
              forceUpdate ||
              existingRentalData?.version! < rentalData?.version! ||
              (existingRentalData.carData &&
                existingRentalData?.carData?.version! <
                  rentalData?.carData?.version!)))))
    ) {
      if (
        !isBookingStartedEventSent &&
        rentalData.carMode === 'reserved' &&
        rentalData.bookingId
      ) {
        AnalyticsManager.event({
          event: APP.ANALYTICS.EVENT.STARTED_BOOKING,
        });
        dispatch(setIsBookingStartedEventSent(true));
        dispatch(getBookings(null, false));
      }

      if (
        !['cancelled', 'finished'].includes(rentalData?.carMode!) &&
        !(rentalData.carMode === 'reserved' && isRentalExpired(rentalData))
      ) {
        let updatedRentalData = { ...rentalData };

        // don't set rental data car mode if prev status equals driving and current status equals opened
        if (
          existingRentalData &&
          ['driving', 'parking'].includes(existingRentalData?.carMode!) &&
          rentalData.carMode === 'opened'
        ) {
          updatedRentalData = {
            ...rentalData,
            carMode: existingRentalData.carMode,
          };
        }

        if (!isWeb() && !existingRentalData && isRentalOngoing(rentalData)) {
          PermissionsManager.requestBluetoothPermission(
            () => {
              DataNativeBridge.setRentalData(updatedRentalData);
            },
            () => {
              DataNativeBridge.setRentalData(updatedRentalData);
            }
          );
        } else {
          DataNativeBridge.setRentalData(updatedRentalData);
        }

        if (['driving', 'parking'].includes(updatedRentalData.carMode!)) {
          dispatch(setOpened(false));
        }

        dispatch({
          type: TYPES.SET_RENTAL_DATA,
          payload: {
            rentalData: updatedRentalData,
          },
        });
        dispatch(reloadDeviceInfo());

        if (isRentalOngoing(updatedRentalData)) {
          if (updatedRentalData && updatedRentalData.access) {
            dispatch(setAccessToken(updatedRentalData.access));
          }
        }

        dispatch(setRentalMapContent(updatedRentalData));
      } else if (!isRentalOngoing(rentalData)) {
        dispatch(clearRental());
      }
    } else if (existingRentalData && isRentalOngoing(existingRentalData)) {
      dispatch(setRentalMapContent(existingRentalData));
    }
  };
export const getRentalData =
  (
    rentalId: string | null = null,
    callbackFunction: (arg0: any) => any = () => {},
    forceUpdate = false
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.GET_RENTAL_DATA));
    const { rentalData } = getState().userData.rental;
    // $FlowFixMe
    const { notModified, data, error } = rentalId
      ? await rentalClient.getRentalData(rentalId)
      : await rentalClient.getUserRentalData();

    if (error) {
      if (error.detail.status === 404) {
        apiClient
          .getStatus()
          .then(() => {
            dispatch(clearRental());
          })
          .catch(() => {
            dispatch(
              setNotification({
                message: 'backend.error',
                type: APP.NOTIFICATION_TYPE.ERROR,
              })
            );
          });
      }

      dispatch(
        setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.GET_RENTAL_DATA, error)
      );
    } else {
      if (!!data && !notModified) {
        dispatch(setRentalData(data, forceUpdate));
      }

      if (typeof callbackFunction === 'function') {
        callbackFunction(data || rentalData);
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.GET_RENTAL_DATA));
    }
  };
export const reserveCar =
  (
    localize: LocalizePropType,
    car: any,
    serviceRental?: boolean,
    callbackFunction?: () => any,
    createBlockPayment: boolean | null | undefined = null,
    handleViolationsCallback: (violations: any) => void = () => {}
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const { circleId } = getState().carsSearch;
    const backBy =
      car?.backBy && isBefore24hrs(new Date(car?.backBy))
        ? new Date(car?.backBy)
        : car?.availableUntil && isBefore24hrs(new Date(car?.availableUntil))
        ? new Date(car?.availableUntil)
        : addHours(new Date(), 24);
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.RESERVE_CAR));
    const reservationData: any = {
      carId: car.id,
      type: serviceRental ? 'serviceTrip' : 'usual',
      backBy,
      circleId,
    };

    if (createBlockPayment !== null) {
      reservationData.createBlockPayment = createBlockPayment;
    }

    // $FlowFixMe
    const { data, error } = await rentalClient.reserveCar(reservationData);

    if (error) {
      AnalyticsManager.event({
        event: APP.ANALYTICS.EVENT.RESERVE_VEHICLE,
        properties: APP.ANALYTICS.OPTIONS.FAILED,
      });
      dispatch(setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.RESERVE_CAR, error));

      if (error.detail.status === 400) {
        const isBlockPaymentNeeded = Object.keys(
          error.detail.data.violations
        ).find(
          (violation) =>
            violation === 'api_error.block_payment_parameter_missing'
        );

        if (createBlockPayment === null && isBlockPaymentNeeded) {
          showBlockPaymentDialog(localize, () => {
            dispatch(
              reserveCar(localize, car, serviceRental, callbackFunction, true)
            );
          });
        }
        if (isWeb() && !isBlockPaymentNeeded) {
          handleViolationsCallback(error.detail.data.violations);
        }
      } else {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
    } else {
      AnalyticsManager.event({
        event: APP.ANALYTICS.EVENT.RESERVE_VEHICLE,
        properties: APP.ANALYTICS.OPTIONS.SUCCESS,
      });

      if (data) {
        DataNativeBridge.setRentalData(data);

        if (typeof callbackFunction === 'function') {
          callbackFunction();
        }

        dispatch(setRentalData(data));
        dispatch(clearCarDamages());
        dispatch(clearNewDamages());
        dispatch(clearServiceTypes());
        dispatch(clearForceRentalDriveMode());
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.RESERVE_CAR));
    }
  };
export const cancelRental =
  (callbackFunction?: () => any) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const { rentalData } = getState().userData.rental;

    if (rentalData && isRentalOngoing(rentalData)) {
      const { id } = rentalData;
      dispatch(
        setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.CANCEL_RESERVATION)
      );
      // $FlowFixMe
      const { error } = await rentalClient.cancelReservation(id!);

      if (error) {
        dispatch(
          setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.CANCEL_RESERVATION, error)
        );

        if (error.detail.status === 404) {
          // already finished
          dispatch(clearRental());
        }

        if (error.detail.status === 403) {
          dispatch(
            setNotification({
              message: 'backend.error',
              type: APP.NOTIFICATION_TYPE.ERROR,
            })
          );
        }
      } else {
        dispatch(clearRental());
        dispatch(
          setNotification({
            message: 'car.rental.cancelled',
            type: APP.NOTIFICATION_TYPE.INFO,
          })
        );

        if (typeof callbackFunction === 'function') {
          callbackFunction();
        }

        dispatch(
          setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.CANCEL_RESERVATION)
        );
      }
    }
  };
export const finishServiceType =
  (
    rentalId: string,
    serviceTypes: any,
    note: string,
    successCallback: () => void = () => {}
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.FINISH_SERVICE_TYPE));
    // $FlowFixMe
    const { error } = await rentalClient.finishServiceType(
      rentalId,
      serviceTypes,
      serviceTypes !== null && serviceTypes.includes('relocate'),
      note
    );

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.FINISH_SERVICE_TYPE, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      if (typeof successCallback === 'function') {
        successCallback();
      }

      dispatch(
        setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.FINISH_SERVICE_TYPE)
      );
      dispatch(
        setNotification({
          message: 'rental.service.report.submit.success',
          type: APP.NOTIFICATION_TYPE.INFO,
        })
      );
    }
  };
export const uploadServiceImage =
  (rentalId: string, image: string) => async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.UPLOAD_SERVICE_IMAGE)
    );
    const { error } = await rentalClient.uploadServiceImage(rentalId, image);

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.UPLOAD_SERVICE_IMAGE, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      dispatch(
        setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.UPLOAD_SERVICE_IMAGE)
      );
    }
  };
export const uploadServiceImageS3 =
  (url: string, image: any) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.UPLOAD_SERVICE_IMAGE_S3)
    );
    const { rentalData } = getState().userData.rental;
    const { error } = await rentalClient.uploadImageS3(url, image);

    if (error) {
      dispatch(
        setNetworkError(
          CLIENT_TYPE.RENTAL_CLIENT.UPLOAD_SERVICE_IMAGE_S3,
          error
        )
      );
      dispatch(uploadServiceImage(rentalData?.id!, image));
    } else {
      dispatch(
        setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.UPLOAD_SERVICE_IMAGE_S3)
      );
    }
  };
export const setFinishRentalViolations =
  (rentalViolations: Record<string, any>) => (dispatch: Dispatch) => {
    Object.keys(rentalViolations).forEach((key) => {
      if (key === 'online') {
        dispatch(
          setNotification({
            message: rentalViolations.online,
            type: APP.NOTIFICATION_TYPE.ERROR,
            localize: false,
          })
        );
      }
    });
    dispatch({
      type: TYPES.SET_FINISH_RENTAL_VIOLATIONS,
      payload: {
        rentalViolations,
      },
    });
  };
export const rateCar =
  (localize: LocalizePropType, rentalId: string, review: boolean) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.RATE_CAR));
    const { appRated } = getState().appData.common;
    const { bundleId, appleAppId } = getState().config;
    // $FlowFixMe
    const { error } = await rentalClient.rateCar(rentalId, review);

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.RATE_CAR, error));
    } else {
      dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.RATE_CAR));
    }

    if (review && !appRated) {
      if (isIOS()) {
        dispatch(setAppRated(true));
      }

      showAppReviewDialog(localize, bundleId, appleAppId, () => {
        dispatch(setAppRated(true));
      });
    }
  };
export const finishRental =
  (
    localize: LocalizePropType,
    rentalId: string,
    successCallback: () => void = () => {},
    failureCallback: () => void = () => {},
    forceViolations: Array<string> = []
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    clearFinishRentalViolations();
    const { rentalData } = getState().userData.rental;
    const { supportContacted, brandSettings } = getState().appData.common;
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.FINISH_RENTAL));
    // $FlowFixMe
    const { data, error } = await rentalClient.finishRental(
      rentalId,
      forceViolations
    );

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.FINISH_RENTAL, error));

      if (error.detail.status === 400) {
        Object.keys(error.detail.data.violations).forEach((violation) => {
          dispatch(
            setNotification({
              message: error.detail.data.violations[violation],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      } else {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }

      if (typeof failureCallback === 'function') {
        failureCallback();
      }
    } else if (data) {
      setRentalData(data);
      setTimeout(() => {
        dispatch(
          setNotification({
            title: 'notification.finishRental.title',
            message:
              rentalData?.vehicleConnection === 'cloudboxx'
                ? 'notification.finishRental.description'
                : 'notification.rental.dongle.finish.description',
            type: APP.NOTIFICATION_TYPE.CUSTOM,
            actionText: 'notification.finishRental.complain',
            actionCallback: () => {
              dispatch(rateCar(localize, rentalId, false));
              helpAction(
                true,
                localize,
                brandSettings?.intercomHandlingMode!,
                supportContacted,
                true,
                true
              );
            },
            dismissCallback: () => {
              dispatch(rateCar(localize, rentalId, true));
            },
            duration: 6000,
            autoDismissCallback: () => {
              dispatch(rateCar(localize, rentalId, true));
            },
          })
        );
      }, 3000);
      dispatch(setLastFinishedRentalDate(new Date()));
      dispatch(clearNewDamages());
      dispatch(clearForceRentalDriveMode());

      if (typeof successCallback === 'function') {
        successCallback();
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.FINISH_RENTAL));
    }
  };

const clearRentalViolations = () => (dispatch: Dispatch) => {
  dispatch({
    type: TYPES.CLEAR_RENTAL_VIOLATIONS,
  });
};

export const setRentalViolations =
  (rentalViolations: Record<string, any>) => (dispatch: Dispatch) => {
    const updatedRentalViolations = { ...rentalViolations };

    if (updatedRentalViolations['api_error.block_payment_parameter_missing']) {
      delete updatedRentalViolations[
        'api_error.block_payment_parameter_missing'
      ];
    }

    dispatch({
      type: TYPES.SET_RENTAL_VIOLATIONS,
      payload: {
        rentalViolations: updatedRentalViolations,
      },
    });
    setTimeout(() => dispatch(clearRentalViolations()), 1000);
  };
export const updateRental =
  (
    rentalId: string,
    extras: Record<string, any>,
    successCallbackFunction: () => any = () => {},
    failureCallbackFunction: () => any = () => {}
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.UPDATE_RENTAL));
    // $FlowFixMe
    const { data, error } = await rentalClient.updateRental(rentalId, extras);

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.UPDATE_RENTAL, error));

      if (typeof failureCallbackFunction === 'function') {
        failureCallbackFunction();
      }

      if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }

      if (error.detail.status === 409) {
        dispatch(
          setNotification({
            message: 'rental.insurance.change.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
    } else {
      if (data) {
        dispatch(setRentalData(data));
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.UPDATE_RENTAL));

      if (typeof successCallbackFunction === 'function') {
        successCallbackFunction();
      }
    }
  };
export const setFuelInfo = (fuelInfo: FuelInfo) => (dispatch: Dispatch) => {
  dispatch({
    type: TYPES.SET_FUEL_INFO,
    payload: {
      fuelInfo,
    },
  });
};
export const getFuelInfo = (rentalId: string) => async (dispatch: Dispatch) => {
  dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.GET_FUEL_INFO));
  // $FlowFixMe
  const { data, error } = await rentalClient.getFuelInfo(rentalId);

  if (error) {
    dispatch(setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.GET_FUEL_INFO, error));
    dispatch(
      setNotification({
        message: 'backend.error',
        type: APP.NOTIFICATION_TYPE.ERROR,
      })
    );
  } else {
    if (data) {
      dispatch(setFuelInfo(data));
    }

    dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.GET_FUEL_INFO));
  }
};
export const canFinishRental =
  (rentalId: string) => async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.CAN_FINISH));
    // $FlowFixMe
    const { data, error } = await rentalClient.canFinishRental(rentalId);

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.CAN_FINISH, error));

      if (error.detail.status === 400) {
        const canLock = Object.keys(error.detail.data.violations).every(
          (violation) =>
            [
              'keyFobIn',
              'fuelLevel',
              'extremelyLowFuelLevel',
              'fuelCardIn',
              'ignitionOff',
            ].includes(violation)
        );
        dispatch(setCanLock(canLock));
      } else {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
    } else {
      dispatch(clearFinishRentalViolations());
      dispatch(setCanLock(true));

      if (data) {
        DataNativeBridge.setRentalData(data);
        dispatch(setRentalData(data));
      }
    }

    dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.CAN_FINISH));
  };
export const addDamage =
  (carId: string, damage: any, callbackFunction?: (damageId: any) => void) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const { rentalData } = getState().userData.rental;
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.ADD_DAMAGE));
    const { data, error } = await rentalClient.addDamage(carId, damage);

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.ADD_DAMAGE, error));

      if (error.detail.status === 400) {
        Object.keys(error.detail.data.violations).forEach((violation) => {
          dispatch(
            setNotification({
              message: error.detail.data.violations[violation],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      } else {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
    } else if (data) {
      if (rentalData?.carData?.id) {
        setTimeout(
          // eslint-disable-next-line no-use-before-define
          () => dispatch(getCarDamages(rentalData?.carData?.id!)),
          3000
        );
      }

      if (typeof callbackFunction === 'function') {
        dispatch(setNewDamage(data.id!));
        callbackFunction(data.id);
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.ADD_DAMAGE));
    }
  };
export const deleteDamage =
  (carId: string, damageId: number) => async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.DELETE_DAMAGE));
    const { error } = await rentalClient.deleteDamage(carId, damageId);

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.DELETE_DAMAGE, error));

      if (error.detail.status === 400) {
        Object.keys(error.detail.data.violations).forEach((violation) => {
          dispatch(
            setNotification({
              message: error.detail.data.violations[violation],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      } else {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
    } else {
      dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.DELETE_DAMAGE));
    }
  };
export const addDamageImageBlob =
  (carId: string, damageId: number | string, imageData: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.ADD_DAMAGE_IMAGE));
    const { error } = await rentalClient.addDamageImageBlob(
      carId,
      damageId,
      imageData
    );

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.ADD_DAMAGE_IMAGE, error)
      );

      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.ADD_DAMAGE_IMAGE));
    }
  };
export const addDamageImage =
  (carId: string, damageId: number | string, imageBase64: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.ADD_DAMAGE_IMAGE));
    // $FlowFixMe
    const { error } = await rentalClient.addDamageImage(
      carId,
      damageId,
      imageBase64
    );

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.ADD_DAMAGE_IMAGE, error)
      );

      if (error.detail.status === 400) {
        Object.keys(error.detail.data.violations).forEach((violation) => {
          dispatch(
            setNotification({
              message: error.detail.data.violations[violation],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      } else {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
    } else {
      dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.ADD_DAMAGE_IMAGE));
    }
  };
export const deleteDamageImage =
  (carId: string, damageId: number, imageId: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.DELETE_DAMAGE_IMAGE));
    // $FlowFixMe
    const { error } = await rentalClient.deleteDamageImage(
      carId,
      damageId,
      imageId
    );

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.DELETE_DAMAGE_IMAGE, error)
      );

      if (error.detail.status === 400) {
        Object.keys(error.detail.data.violations).forEach((violation) => {
          dispatch(
            setNotification({
              message: error.detail.data.violations[violation],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      } else {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
    } else {
      dispatch(
        setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.DELETE_DAMAGE_IMAGE)
      );
    }
  };
export const unlockCar =
  (
    rentalId: string,
    centralLockOnly: boolean = false,
    successCallback: () => void = () => {}
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.UNLOCK_CAR));
    const { error } = await rentalClient.unlockCar(rentalId, centralLockOnly);

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.UNLOCK_CAR, error));
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      successCallback();
      dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.UNLOCK_CAR));
    }
  };
export const turnIgnitionOn =
  (rentalId: string) => async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.TURN_IGNITION_ON));
    // $FlowFixMe
    const { error } = await rentalClient.turnIgnitionOn(rentalId);

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.TURN_IGNITION_ON, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.TURN_IGNITION_ON));
    }
  };
export const turnIgnitionOff =
  (rentalId: string) => async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.TURN_IGNITION_OFF));
    // $FlowFixMe
    const { error } = await rentalClient.turnIgnitionOff(rentalId);

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.TURN_IGNITION_OFF, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.TURN_IGNITION_OFF));
    }
  };
export const uploadStartCheckImage =
  (rentalId: string, image: any, callback: () => void = () => {}) =>
  async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.UPLOAD_START_CHECK_IMAGE)
    );
    // $FlowFixMe
    const { error } = await rentalClient.uploadStartCheckImage(rentalId, image);

    if (error) {
      dispatch(
        setNetworkError(
          CLIENT_TYPE.RENTAL_CLIENT.UPLOAD_START_CHECK_IMAGE,
          error
        )
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );

      if (typeof callback === 'function') {
        callback();
      }
    } else {
      if (typeof callback === 'function') {
        callback();
      }

      dispatch(
        setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.UPLOAD_START_CHECK_IMAGE)
      );
    }
  };
export const uploadStartCheckImageS3 =
  (url: string, image: any, callback: () => void = () => {}) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.UPLOAD_START_CHECK_IMAGE_S3)
    );
    const { rentalData } = getState().userData.rental;
    const { error } = await rentalClient.uploadImageS3(url, image);

    if (error) {
      dispatch(
        setNetworkError(
          CLIENT_TYPE.RENTAL_CLIENT.UPLOAD_START_CHECK_IMAGE_S3,
          error
        )
      );
      dispatch(uploadStartCheckImage(rentalData?.id!, image, callback));
    } else {
      if (typeof callback === 'function') {
        callback();
      }

      dispatch(
        setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.UPLOAD_START_CHECK_IMAGE_S3)
      );
    }
  };
export const uploadEndCheckImage =
  (rentalId: string, image: any, callback: () => void = () => {}) =>
  async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.UPLOAD_END_CHECK_IMAGE)
    );
    // $FlowFixMe
    const { error } = await rentalClient.uploadEndCheckImage(rentalId, image);

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.UPLOAD_END_CHECK_IMAGE, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );

      if (typeof callback === 'function') {
        callback();
      }
    } else {
      if (typeof callback === 'function') {
        callback();
      }

      dispatch(
        setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.UPLOAD_END_CHECK_IMAGE)
      );
    }
  };
export const uploadEndCheckImageS3 =
  (
    rentalId: string,
    url: string,
    image: any,
    callback: () => void = () => {}
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.UPLOAD_END_CHECK_IMAGE_S3)
    );
    const { error } = await rentalClient.uploadImageS3(url, image);

    if (error) {
      dispatch(
        setNetworkError(
          CLIENT_TYPE.RENTAL_CLIENT.UPLOAD_END_CHECK_IMAGE_S3,
          error
        )
      );
      dispatch(uploadEndCheckImage(rentalId, image, callback));
    } else {
      if (typeof callback === 'function') {
        callback();
      }

      dispatch(
        setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.UPLOAD_END_CHECK_IMAGE_S3)
      );
    }
  };
export const takeService =
  (rentalId: string, successCallbackFunction?: () => any) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.TAKE_SERVICE));
    // $FlowFixMe
    const { data, error } = await rentalClient.takeService(rentalId);

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.TAKE_SERVICE, error));
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      if (data) {
        dispatch(setRentalData(data));
        dispatch(clearServiceTypes());
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.TAKE_SERVICE));

      if (typeof successCallbackFunction === 'function') {
        successCallbackFunction();
      }
    }
  };
export const startService =
  (rentalId: string, successCallbackFunction?: () => any) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.START_SERVICE));
    // $FlowFixMe
    const { data, error } = await rentalClient.startService(rentalId);

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.START_SERVICE, error));
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      if (data) {
        dispatch(setRentalData(data));
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.START_SERVICE));

      if (typeof successCallbackFunction === 'function') {
        successCallbackFunction();
      }
    }
  };
export const generateServiceTripUrl =
  (
    rentalId: string,
    successCallbackFunction?: (data: any) => any,
    failureCallbackFunction?: () => any
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.GENERATE_SERVICE_IMAGE_URL)
    );
    // $FlowFixMe
    const { data, error } = await rentalClient.generateServiceTripUrl(rentalId);

    if (error) {
      dispatch(
        setNetworkError(
          CLIENT_TYPE.RENTAL_CLIENT.GENERATE_SERVICE_IMAGE_URL,
          error
        )
      );

      if (typeof failureCallbackFunction === 'function') {
        failureCallbackFunction();
      }
    } else {
      dispatch(
        setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.GENERATE_SERVICE_IMAGE_URL)
      );

      if (typeof successCallbackFunction === 'function') {
        successCallbackFunction(data);
      }
    }
  };
export const generateStartCheckImageUrl =
  (
    rentalId: string,
    successCallbackFunction?: (data: any) => any,
    failureCallbackFunction?: () => any
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(
        CLIENT_TYPE.RENTAL_CLIENT.GENERATE_START_CHECK_IMAGE_URL
      )
    );
    // $FlowFixMe
    const { data, error } = await rentalClient.generateStartCheckImageUrl(
      rentalId
    );

    if (error) {
      dispatch(
        setNetworkError(
          CLIENT_TYPE.RENTAL_CLIENT.GENERATE_START_CHECK_IMAGE_URL,
          error
        )
      );

      if (typeof failureCallbackFunction === 'function') {
        failureCallbackFunction();
      }
    } else {
      dispatch(
        setNetworkSuccess(
          CLIENT_TYPE.RENTAL_CLIENT.GENERATE_START_CHECK_IMAGE_URL
        )
      );

      if (typeof successCallbackFunction === 'function') {
        successCallbackFunction(data);
      }
    }
  };
export const generateEndCheckImageUrl =
  (
    rentalId: string,
    successCallbackFunction?: (data: any) => any,
    failureCallbackFunction?: () => any
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.GENERATE_END_CHECK_IMAGE_URL)
    );
    // $FlowFixMe
    const { data, error } = await rentalClient.generateEndCheckImageUrl(
      rentalId
    );

    if (error) {
      dispatch(
        setNetworkError(
          CLIENT_TYPE.RENTAL_CLIENT.GENERATE_END_CHECK_IMAGE_URL,
          error
        )
      );

      if (typeof failureCallbackFunction === 'function') {
        failureCallbackFunction();
      }
    } else {
      dispatch(
        setNetworkSuccess(
          CLIENT_TYPE.RENTAL_CLIENT.GENERATE_END_CHECK_IMAGE_URL
        )
      );

      if (typeof successCallbackFunction === 'function') {
        successCallbackFunction(data);
      }
    }
  };

export const getRentalS3Credential =
  (
    rentalId: string,
    successCallbackFunction?: (data: S3TemporaryAccessData) => any,
    failureCallbackFunction?: () => any
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.GET_S3_CREDENTIALS));
    const { data, error } = await awsS3Client.getRentalCredential(rentalId);

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.GET_S3_CREDENTIALS, error)
      );

      if (typeof failureCallbackFunction === 'function') {
        failureCallbackFunction();
      }
    } else {
      if (data) {
        if (typeof successCallbackFunction === 'function') {
          successCallbackFunction(data);
        }
      }
      dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.GET_S3_CREDENTIALS));
    }
  };

export const uploadRentalS3Image = (
  imageName: string,
  imagePath: RentalImagePath,
  fileUri: string,
  accessData: S3TemporaryAccessData,
  successCallback?: () => any,
  failureCallback?: (err: any) => any
) => {
  const { folder } = accessData;
  const fileKey = `${folder}/${imagePath}/${imageName}.appUpload`;

  return S3Manager.uploadFile(
    fileKey,
    fileUri,
    accessData,
    successCallback,
    failureCallback
  );
};
