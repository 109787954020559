import { PURGE } from 'redux-persist';
import initialState from './InitialState';
import TYPES from '../../../@types/redux/store/RentalTypes';
import { RentalAction, RentalState } from '../../../@types';

export default (
  state: RentalState = initialState,
  action: RentalAction
): RentalState => {
  switch (action.type) {
    case TYPES.SET_RENTAL_DATA: {
      return { ...state, rentalData: action.payload.rentalData };
    }

    case TYPES.SET_DRIVER_CAR_STATUS: {
      return { ...state, driverCarStatus: action.payload.driverCarStatus };
    }

    case TYPES.SET_RENTAL_VIOLATIONS: {
      return { ...state, rentalViolations: action.payload.rentalViolations };
    }

    case TYPES.CLEAR_RENTAL_VIOLATIONS: {
      return { ...state, rentalViolations: null };
    }

    case TYPES.SET_FINISH_RENTAL_VIOLATIONS: {
      return {
        ...state,
        finishRentalViolations: action.payload.rentalViolations,
      };
    }

    case TYPES.SET_FUEL_INFO: {
      return { ...state, fuelInfo: action.payload.fuelInfo };
    }

    case TYPES.SET_LAST_FINISHED_RENTAL_DATE: {
      return {
        ...state,
        lastFinishedRentalDate: action.payload.lastFinishedRentalDate,
      };
    }

    case TYPES.SET_CAN_LOCK: {
      return { ...state, canLock: action.payload.canLock };
    }

    case TYPES.CLEAR_FINISH_RENTAL_VIOLATIONS: {
      return {
        ...state,
        finishRentalViolations: initialState.finishRentalViolations,
      };
    }

    case TYPES.SET_IS_SERVICE_REPORT_SUBMITTED: {
      return {
        ...state,
        isServiceReportSubmitted: action.payload.isServiceReportSubmitted,
      };
    }

    case TYPES.SET_IS_CAR_CHECKED: {
      return { ...state, isCarChecked: action.payload.isCarChecked };
    }

    case TYPES.SET_IS_BOOKING_STARTED_EVENT: {
      return {
        ...state,
        isBookingStartedEventSent: action.payload.isBookingStartedEventSent,
      };
    }

    case TYPES.CLEAR_RENTAL_DATA: {
      return {
        ...state,
        rentalData: initialState.rentalData,
        driverCarStatus: initialState.driverCarStatus,
        rentalViolations: initialState.rentalViolations,
        finishRentalViolations: initialState.finishRentalViolations,
        fuelInfo: initialState.fuelInfo,
        canLock: initialState.canLock,
        isBookingStartedEventSent: initialState.isBookingStartedEventSent,
        isCarChecked: initialState.isCarChecked,
      };
    }

    case TYPES.SET_CAR_DAMAGES: {
      return { ...state, carDamages: action.payload.carDamages };
    }

    case TYPES.CLEAR_CAR_DAMAGES: {
      return { ...state, carDamages: null };
    }

    case TYPES.FORCE_RENTAL_DRIVE_MODE: {
      return { ...state, forceRentalDriveMode: true };
    }

    case TYPES.CLEAR_FORCE_RENTAL_DRIVE_MODE: {
      return { ...state, forceRentalDriveMode: false };
    }

    // @ts-ignore
    case PURGE:
      return initialState;

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
