import {
  ConnectCarData,
  ConnectDeviceData,
  CarStatusData,
  SetSelectedConnectCar,
  SetSelectedConnectDeviceStatus,
  SetSelectedConnectDevice,
} from '../../../@types';
import TYPES from '../../../@types/redux/store/ConnectUiTypes';

export const setSelectedConnectCar = (
  selectedCar: ConnectCarData | null
): SetSelectedConnectCar => ({
  type: TYPES.SET_SELECTED_CAR,
  payload: {
    selectedCar,
  },
});

export const setSelectedConnectDevice = (
  selectedDevice: ConnectDeviceData | null
): SetSelectedConnectDevice => ({
  type: TYPES.SET_SELECTED_DEVICE,
  payload: {
    selectedDevice,
  },
});

export const setSelectedConnectDeviceStatus = (
  selectedDeviceStatus: CarStatusData | null
): SetSelectedConnectDeviceStatus => ({
  type: TYPES.SET_SELECTED_DEVICE_STATUS,
  payload: {
    selectedDeviceStatus,
  },
});
