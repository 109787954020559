import { PURGE } from 'redux-persist';
import initialState from './InitialState';
import TYPES from '../../../@types/redux/store/MapTypes';
import { MapAction, MapState } from '../../../@types';

export default (
  state: MapState = initialState,
  action: MapAction
): MapState => {
  switch (action.type) {
    case TYPES.SET_MAP_CONTENT: {
      return {
        ...state,
        content: {
          cardType: action.payload.cardType,
          mapType: action.payload.mapType,
          id: action.payload.id,
        },
      };
    }

    case TYPES.SET_CARD_STATE: {
      return { ...state, cardState: action.payload.cardState };
    }

    case TYPES.SET_MAP_REGION: {
      return { ...state, visibleRegion: action.payload.region };
    }

    case TYPES.SET_MAP_BOUNDARIES: {
      return { ...state, mapBoundaries: action.payload.mapBoundaries };
    }

    case TYPES.SET_CARS_SEARCH_ALLOWED: {
      return { ...state, carsSearchAllowed: action.payload.carsSearchAllowed };
    }

    case TYPES.SET_OPENED: {
      return { ...state, opened: action.payload.opened };
    }

    case TYPES.SET_IS_FIRST_RENTAL: {
      return { ...state, isFirstRental: action.payload.isFirstRental };
    }

    case TYPES.SET_SELECTED_CAR: {
      return { ...state, selectedCar: action.payload.selectedCar };
    }

    case TYPES.SET_ACTIVE_ALERT: {
      return { ...state, activeAlert: action.payload.activeAlert };
    }

    case TYPES.SET_ALERT_BASE_MODE: {
      return { ...state, alertBaseMode: action.payload.alertBaseMode };
    }

    case TYPES.SET_USER_LOCATION: {
      return { ...state, showUserLocation: action.payload.showUserLocation };
    }

    case TYPES.SET_DEFAULT_TAB: {
      return { ...state, defaultTab: action.payload.defaultTab };
    }

    case TYPES.SET_SHOW_HOME_WT: {
      return { ...state, showHomeWT: action.payload.showHomeWT };
    }

    case TYPES.SET_USER_ADDRESS: {
      return { ...state, userAddress: action.payload.address };
    }

    case TYPES.SET_MAP_FILTER: {
      return {
        ...state,
        mapFilter: {
          type: action.payload.type,
        },
      };
    }

    case TYPES.CLEAR_MAP_FILTER: {
      return {
        ...state,
        mapFilter: {
          type: '',
        },
      };
    }
    // @ts-ignore
    case PURGE:
      return initialState;

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
