import { ConfigState } from '../../@types';

const initialState: ConfigState = {
  googleApiAccessToken: '',
  apiBaseURL: '',
  apiVersion: '',
  bundleId: '',
  appleAppId: '',
  xBrandId: '',
};

export default initialState;
