import { CarAccessTokenData, CarStatusData } from '../../index';

export type BluetoothStatus =
  | 'disabled'
  | 'searching'
  | 'active'
  | 'disconnected';

export type BluetoothState = {
  status: any;
  readonly connectedDevice: {
    readonly carId: string | null | undefined;
    readonly deviceQnr: string | null | undefined;
    readonly accessToken: CarAccessTokenData | null | undefined;
    readonly carStatus: any | null | undefined;
  };
};

enum BluetoothTypes {
  SET_BLUETOOTH_STATUS = 'bluetooth/SET_BLUETOOTH_STATUS',
  SET_DEVICE_INFO = 'bluetooth/SET_DEVICE_INFO',
  RELOAD_DEVICE_INFO = 'bluetooth/RELOAD_DEVICE_INFO',
  SET_ACCESS_TOKEN = 'bluetooth/SET_ACCESS_TOKEN',
  SET_CAR_STATUS = 'bluetooth/SET_CAR_STATUS',
  CLEAR_CONNECTED_DEVICE = 'bluetooth/CLEAR_CONNECTED_DEVICE',
}

export default BluetoothTypes;

export type SetBluetoothStatus = {
  type: BluetoothTypes.SET_BLUETOOTH_STATUS;
  payload: {
    status: BluetoothStatus | string;
  };
};

export type SetDeviceInfo = {
  type: BluetoothTypes.SET_DEVICE_INFO;
  payload: {
    carId: string;
    deviceQnr: string;
  };
};

export type SetAccessToken = {
  type: BluetoothTypes.SET_ACCESS_TOKEN;
  payload: {
    accessToken: CarAccessTokenData;
  };
};

export type SetCarStatus = {
  type: BluetoothTypes.SET_CAR_STATUS;
  payload: {
    carStatus: CarStatusData;
  };
};

export type ClearConnectedDevice = {
  type: BluetoothTypes.CLEAR_CONNECTED_DEVICE;
};

export type BluetoothAction =
  | SetBluetoothStatus
  | SetDeviceInfo
  | SetAccessToken
  | SetCarStatus
  | ClearConnectedDevice;
