import { $PropertyType } from 'utility-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  dismissNetworkError,
  clearNetworkErrors,
} from '../store/networkStatus';
import { State, NetworkStatusState } from '../@types';

export type Props = {
  networkStatus: {
    activities: $PropertyType<NetworkStatusState, 'activities'>;
    errors: $PropertyType<NetworkStatusState, 'errors'>;
  };
  dismissNetworkError: typeof dismissNetworkError;
  clearNetworkErrors: typeof clearNetworkErrors;
};

const withNetworkStatus: any = compose(
  connect(
    ({ networkStatus: { activities, errors } }: State) => ({
      networkStatus: {
        activities,
        errors,
      },
    }),
    {
      dismissNetworkError,
      clearNetworkErrors,
    }
  )
);
export default withNetworkStatus;
