import { connect } from 'react-redux';
import { compose, withHandlers, mapProps } from 'recompose';
import { differenceInMinutes } from 'date-fns';
import withTextLocalizer from './withTextLocalizer';
import { isRentalOngoing, isRentalStarted, helpAction } from '../helpers';
import { OutputBookingData, RentalData, State } from '../@types';

export type Props = {
  isAuthorizedUser: boolean;
  isVerifiedUser: boolean;
  rentalStarted: boolean;
  intercomHandlingMode: 'always' | 'onLogin' | 'onContactSupport' | 'never';
  supportContacted: boolean;
  helpAction: (arg?: any) => void;
};

const shouldShowCallOption = (
  xBrandId: string,
  rentalData: RentalData,
  bookings: OutputBookingData,
  lastFinishedRentalDate: Date
) =>
  isRentalOngoing(rentalData) ||
  bookings?.filter((booking: { status: string }) =>
    ['confirmed', 'offered'].includes(booking.status)
  ).length > 0 ||
  (lastFinishedRentalDate &&
    differenceInMinutes(new Date(), new Date(lastFinishedRentalDate)) < 15);

const withIntercom: any = compose(
  withTextLocalizer,
  connect(
    ({
      config: { xBrandId },
      appData: {
        common: { firstSteps, supportContacted, brandSettings },
      },
      userData: {
        user: { basicAuth, user },
        rental: { rentalData, lastFinishedRentalDate },
        bookings: { bookings },
      },
    }: State) => ({
      showCallOption: shouldShowCallOption(
        xBrandId,
        rentalData!,
        bookings,
        lastFinishedRentalDate!
      ),
      intercomHandlingMode: brandSettings?.intercomHandlingMode,
      supportContacted,
      isAuthorizedUser: user && !!basicAuth,
      isVerifiedUser: firstSteps,
      rentalStarted: isRentalStarted(rentalData),
    })
  ),
  withHandlers({
    helpAction:
      ({
        showCallOption,
        localize,
        intercomHandlingMode,
        supportContacted,
        isAuthorizedUser,
        isVerifiedUser,
        rentalStarted,
      }: any) =>
      (showCallOptionParam: any) =>
        helpAction(
          showCallOptionParam || showCallOption,
          localize,
          intercomHandlingMode,
          supportContacted,
          isAuthorizedUser,
          isVerifiedUser,
          rentalStarted
        ),
  } as any),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mapProps(({ dispatch, ...props }: any) => ({ ...props }))
);
export default withIntercom;
