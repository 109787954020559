import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getTexts } from '../store/appData/texts';

export type Props = {
  getTexts: typeof getTexts;
};

const withTextUpdater: any = compose(
  connect(null, {
    getTexts,
  })
);
export default withTextUpdater;
