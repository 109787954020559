import { CarsSearchState } from '../../@types';

const initialState: CarsSearchState = {
  mapCenterLocation: {
    latitude: 52.519783,
    longitude: 13.416321,
  },
  radius: 2000,
  from: null,
  backBy: null,
  mileage: null,
  availableCars: {},
  unavailableCars: {},
  searchCars: [],
  circleId: null,
  regionChangeNo: 0,
  categories: [],
};

export default initialState;
