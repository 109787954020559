import { connect } from 'react-redux';
import { compose } from 'recompose';
import { jumioDocumentUploaded } from '../store/userData/user';
import { State } from '../@types';
import { setAlertBaseMode } from '../store/ui/map';
export type Props = {
  jumioCallbackURL: string;
  jumioDocumentUploaded: typeof jumioDocumentUploaded;
  setAlertBaseMode: typeof setAlertBaseMode;
};
const withJumio: any = compose(
  connect(
    ({
      userData: {
        user: { user, basicAuth },
      },
      config: { apiBaseURL },
    }: State) => ({
      user,
      basicAuth,
      apiBaseURL,
    }),
    {
      jumioDocumentUploaded,
      setAlertBaseMode,
    }
  )
);
export default withJumio;
