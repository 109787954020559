import React from 'react';
import { Image } from 'antd-latest';
import './CarDataView.less';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import BrandButton from '../../components/BrandButton/BrandButton';
import {
  ConnectCarData,
  LocalizePropType,
  titleForDriverCar,
} from 'getaway-data-layer';
import ListItem from '../../components/ListItem/ListItem';
import ImageInput from '../../components/ImageInput/ImageInput';
import VerticalSpace from '../../components/VerticalSpace/VerticalSpace';

type props = {
  localize: LocalizePropType;
  car: ConnectCarData;
  started: boolean;
  installationScan: string | undefined;
  onPressRemoveImage: () => void;
  onPressStart: () => void;
  onPressUpload: () => void;
};

export const REGISTRATION_IMAGE_ID = 'vehicle-registration';

const CarDataView = ({
  localize,
  car,
  started,
  installationScan,
  onPressUpload,
  onPressStart,
  onPressRemoveImage,
}: props) => {
  const isDisabled = started && (!car || !installationScan);
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('car.data.view.title')}
        description={localize('car.data.view.description')}
        showCloseButton={started}
      />
      <div className="screen-body">
        <ListItem title={localize('connect.item.plate')} value={car.plate} />
        <ListItem
          title={localize('connect.item.model')}
          value={titleForDriverCar(car)}
        />
        <ListItem
          title={localize('connect.item.chassisNumber')}
          value={car.chassisNumber!}
        />
        {started && (
          <ListItem
            title={localize('car.data.view.document')}
            value={
              <div className="installation-sample-wrapper">
                <div>{localize('car.data.view.document.sample')}</div>
                <Image
                  key="sample"
                  width={22}
                  height={22}
                  src={'/images/installation-sample.png'}
                  wrapperStyle={{ marginLeft: '10px', borderRadius: '8px' }}
                />
              </div>
            }
            showSeparator={false}
          />
        )}
        <ImageInput
          show={started}
          containerStyle={{ height: window.innerHeight * 0.25 }}
          imageId={REGISTRATION_IMAGE_ID}
          image={installationScan}
          onPressRemoveImage={onPressRemoveImage}
        />
        <VerticalSpace />
        <BrandButton
          title={localize(
            started ? 'car.data.view.btn.upload' : 'car.data.view.btn.start'
          )}
          onClick={started ? onPressUpload : onPressStart}
          disabled={isDisabled}
          attachListeners={false}
        />
      </div>
    </div>
  );
};

export default CarDataView;
