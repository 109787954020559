import {
  Image,
  SetFuelCardBack,
  SetFuelCardFront,
  SetVehicleRegistration,
} from '../../../@types';
import TYPES from '../../../@types/redux/store/ConnectCameraTypes';

export const setVehicleRegistration = (
  vehicleRegistration: Image | null
): SetVehicleRegistration => ({
  type: TYPES.SET_VEHICLE_REGISTRATION,
  payload: {
    vehicleRegistration,
  },
});

export const setFuelCardFront = (
  fuelCardFront: Image | null
): SetFuelCardFront => ({
  type: TYPES.SET_FUEL_CARD_FRONT,
  payload: {
    fuelCardFront,
  },
});

export const setFuelCardBack = (
  fuelCardBack: Image | null
): SetFuelCardBack => ({
  type: TYPES.SET_FUEL_CARD_BACK,
  payload: {
    fuelCardBack,
  },
});
