import { State } from '../@types';

export const selectAreas = (store: State) => store.appData.areas;
export const selectDialog = (store: State) => store.appData.common.dialog;
export const selectOpenedModal = (store: State) =>
  store.appData.common.openedModal;
export const selectIsVerifiedUser = (store: State) =>
  store.appData.common.firstSteps;
export const selectBrandSettings = (store: State) =>
  store.appData.common.brandSettings;
export const selectCommon = (store: State) => store.appData.common;
export const selectFeatures = (store: State) => store.appData.features;
