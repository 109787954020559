import { store } from '../../config';
import { setUser } from '../../store/userData/user';
import { setDriverCarStatus, setRentalData } from '../../store/userData/rental';
import { updateBookingRT } from '../../store/userData/bookings';
import {
  CarStatusData,
  DriverBookingData,
  RentalData,
  UserDataOutput,
} from '../../@types';

const getSetter = {
  setUser(user: UserDataOutput) {
    store.dispatch(setUser(user));
  },

  setRentalData(rentalData: RentalData) {
    store.dispatch(setRentalData(rentalData));
  },

  updateBooking(booking: DriverBookingData) {
    store.dispatch(updateBookingRT(booking));
  },

  setDriverCarStatus(driverCarStatus: CarStatusData) {
    store.dispatch(setDriverCarStatus(driverCarStatus));
  },
};
export default getSetter;
