import dataClient from '../../../clients/dataClient';
import {
  setNetworkActivity,
  setNetworkSuccess,
  setNetworkError,
} from '../../networkStatus';
import { SetFeatures, Dispatch, GetState } from '../../../@types';
import TYPES from '../../../@types/redux/store/FeaturesTypes';
import { CLIENT_TYPE } from '../../../@types';

export const INTERNAL_USERS = [
  'get-a-way.com',
  'getaway.pro',
  'europcar.com',
  'ubeeqo.com',
  'buchbinder.de',
];

export const setFeatures = (features: Array<string>): SetFeatures => ({
  type: TYPES.SET_FEATURES,
  payload: {
    features,
  },
});

export const getFeatures =
  () => async (dispatch: Dispatch, getState: GetState) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.DATA_CLIENT.GET_FEATURES));
    const userEmail = getState().userData?.user?.user?.email || '';
    const isInternal = INTERNAL_USERS.includes(userEmail.split('@')[1]);
    // $FlowFixMe
    const { notModified, data, error } = await dataClient.getFeatures(
      isInternal
    );

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.DATA_CLIENT.GET_FEATURES, error));
    } else {
      if (!!data && !notModified) {
        dispatch(setFeatures(data?.mobileFeatures || []));
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.DATA_CLIENT.GET_FEATURES));
    }
  };
