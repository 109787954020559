import initialState from './InitialState';
import TYPES from '../../@types/redux/store/DeviceTypes';
import { DeviceAction, DeviceState } from '../../@types';
import { isIOS } from '../../helpers';

export default (
  state: DeviceState = initialState,
  action: DeviceAction
): DeviceState => {
  switch (action.type) {
    case TYPES.SET_DEVICE_ORIENTATION: {
      return { ...state, orientation: action.payload.orientation };
    }

    case TYPES.SET_DEVICE_UUID: {
      return { ...state, deviceUUID: action.payload.deviceUUID };
    }

    case TYPES.SET_PLATFORM: {
      return { ...state, platform: action.payload.platform };
    }

    case TYPES.SET_IS_KEYBOARD_OPENED: {
      return { ...state, isKeyboardOpened: action.payload.isKeyboardOpened };
    }

    case TYPES.SET_TOTAL_MEMORY: {
      return { ...state, totalMemory: action.payload.totalMemory };
    }

    case TYPES.SET_USED_MEMORY: {
      return { ...state, usedMemory: action.payload.usedMemory };
    }

    case TYPES.SET_MODEL: {
      return { ...state, model: action.payload.model };
    }

    case TYPES.SET_PUSH_TOKEN: {
      return { ...state, pushToken: action.payload.pushToken };
    }

    case TYPES.SET_PUSH_DEVICE_ID: {
      return { ...state, pushDeviceID: action.payload.pushDeviceID };
    }

    case TYPES.SET_LANGUAGE: {
      return { ...state, language: action.payload.language };
    }

    case TYPES.SET_LAST_KNOWN_LOCATION: {
      return { ...state, lastKnownLocation: action.payload.lastKnownLocation };
    }

    case TYPES.SET_USER_AGENT: {
      return { ...state, userAgent: action.payload.userAgent };
    }

    case TYPES.SET_CAMERA_PERMISSION: {
      return {
        ...state,
        status: {
          ...state.status,
          cameraPermission: action.payload.cameraPermission,
        },
      };
    }

    case TYPES.SET_LOCATION_PERMISSION: {
      return {
        ...state,
        status: {
          ...state.status,
          locationPermission: action.payload.locationPermission,
        },
      };
    }

    case TYPES.SET_BLUETOOTH_PERMISSION: {
      return {
        ...state,
        status: {
          ...state.status,
          bluetoothPermission: isIOS()
            ? action.payload.bluetoothPermission
            : state.status.bluetoothPermission,
        },
      };
    }

    case TYPES.SET_DEVICE_PAIRED: {
      return {
        ...state,
        status: { ...state.status, devicePaired: action.payload.devicePaired },
      };
    }

    case TYPES.SET_DEVICE_CONNECTED: {
      return {
        ...state,
        status: {
          ...state.status,
          deviceConnected: action.payload.deviceConnected,
        },
      };
    }

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
