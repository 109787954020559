import { connect } from 'react-redux';
import { compose } from 'recompose';
import { State } from '../@types';
import { getServices } from '../store/service';
import { startService, takeService } from '../store/userData/rental';
import { setMapFilter } from '../store/ui/map';

export type Props = {
  services: Array<any>;
  getServices: typeof getServices;
  takeService: typeof takeService;
  startService: typeof startService;
  setMapFilter: typeof setMapFilter;
};

const withServices: any = compose(
  connect(
    ({ services }: State) => ({
      services,
    }),
    {
      getServices,
      takeService,
      startService,
    }
  )
);
export default withServices;
