import { APP } from '../enums';
import { TextGroup } from '../@types';

export const isBrand = (xbrandId?: string | null | undefined) =>
  !!xbrandId && xbrandId.length;

// get Brand text group from GET texts response
export const getBrandGroup = (xBrandId: string): TextGroup =>
  xBrandId === APP.BRAND.UBEEQO
    ? 'ubeeqo'
    : xBrandId === APP.BRAND.ROBBEN_WIENTJES
    ? 'robben-wientjes'
    : xBrandId === APP.BRAND.KONIG
    ? 'konig'
    : xBrandId === process?.env?.REACT_APP_X_BRAND_ID
    ? 'miet-smart'
    : 'default';
// For now all Brands are forcing de, will keep the function for any future changes
export const getBrandLanguage = (xBrandId: string): string =>
  xBrandId ? 'de' : 'de';

export const getBrandName = (xbrandId: string): string => {
  if (xbrandId) {
    switch (xbrandId) {
      case '000000000000000000000002':
        return 'ubq';

      case '000000000000000000000003':
        return 'rw';

      case '5ef1f2e4c8f48f0f7912d487':
        return 'msi';

      default:
        return '';
    }
  }

  return 'getaway';
};
