import austriaGasStations from './gasStations.austria';
import germanyGasStations from './gasStations.germany';

// Script to assign location and id's to  gas stations
// console.log(germanyGasStations.map((station) => {
//   const location = `${Number(station.Latitude.split('.').join("")) / Math.pow(10,station.Latitude.length -4)},${
//   Number(station.Longitude.split('.').join("")) / Math.pow(10,station.Latitude.length -4)
//     }`;
//   console.log(Number(station.Latitude.split('.').join("")) / Math.pow(10,station.Latitude.length -4));
//   return {
//     ...station,
//     location,
//     id: location,
//   };
// }));

export const gasStations = [...austriaGasStations, ...germanyGasStations];
