/* eslint-disable no-restricted-globals */
import axios from 'axios';
import objectHash from 'object-hash';
import { store } from '../../config';
import { isXPairedDeviceHeaderSupported } from './isXPairedDeviceHeaderSupported';
import { getBrandLanguage, isBrand } from '../../helpers';
import { APICallArgs, APIResponse } from '../../@types';
import { setGetHistory } from '../../store/networkStatus';
import { setDevicePaired } from '../../store/device';
import { clearAuth } from '../../store/userData/user';
import { clearAdminAuth } from '../../store/connect/userData';
import { isWeb } from '../../helpers';
import {isConnect} from '../../utils/isConnect';

type APP_CLIENT = 'web' | 'mobile' | 'connect';

const getApplicationClient = (): APP_CLIENT =>
  isConnect() ? 'connect' : isWeb() ? 'web' : 'mobile';

export const makeAPICall = async ({
  method = 'GET',
  url,
  params,
  data,
  headers,
  timeout = 120 * 1000,
  checkModified = !isWeb(),
}: APICallArgs): Promise<APIResponse<any>> => {
  try {
    const state = store.getState();
    const { apiBaseURL, apiVersion, xBrandId } = state.config;
    const requestConfig: any = {
      method,
      baseURL: apiBaseURL,
      url,
      params,
      data,
      timeout,
      responseType: 'json',
      headers: {
        Accept: 'application/json; charset=utf-8',
        'Content-Type': 'application/json; charset=utf-8',
        'X-API-Version': apiVersion,
        'X-Application-Client': getApplicationClient(),
        'Cache-Control': 'no-cache',
      },
    };
    let auth;
    if (isConnect()) {
      auth = state.connect.userData.basicAuth;
    } else {
      auth = state.userData.user.basicAuth;
    }

    const {
      deviceUUID,
      platform,
      model,
      pushToken,
      pushDeviceID,
      language: deviceLanguage,
      lastKnownLocation,
      userAgent,
    } = state.device;

    if (xBrandId) {
      requestConfig.headers['X-Brand-ID'] = xBrandId;
    }

    if (deviceUUID) {
      requestConfig.headers['X-Device-UUID'] = deviceUUID;
    }

    if (isWeb() || isConnect()) {
      requestConfig.headers['X-Device-Screen-Color-Depth'] = screen.colorDepth;
      requestConfig.headers['X-Device-Screen-Width'] = screen.width;
      requestConfig.headers['X-Device-Screen-Height'] = screen.height;
      requestConfig.headers['X-Device-Java-Enabled'] = navigator.javaEnabled();
      requestConfig.headers['X-Device-Time-Zone-Offset'] =
        new Date().getTimezoneOffset();
    }

    if (!isWeb() && !isConnect()) {
      if (platform) {
        requestConfig.headers[
          'X-Device-Platform'
        ] = `${platform.name} ${platform.version}`;
      }
    }

    if (model) {
      requestConfig.headers['X-Device-Model'] = model;
    }

    if (pushToken) {
      requestConfig.headers['X-Push-Token'] = pushToken;
    }

    if (pushDeviceID) {
      requestConfig.headers['X-Push-Device-ID'] = pushDeviceID;
    }

    if (deviceLanguage) {
      requestConfig.headers['Accept-Language'] = isBrand(xBrandId)
        ? getBrandLanguage(xBrandId)
        : deviceLanguage;
    }

    if (lastKnownLocation) {
      requestConfig.headers[
        'X-Application-Geolocation'
      ] = `${lastKnownLocation.latitude},${lastKnownLocation.longitude}`;
    }

    if (userAgent) {
      requestConfig.headers['User-Agent'] = `${userAgent} React-Native`;
    }

    if (auth) {
      requestConfig.headers.Authorization = auth;
    }

    let lastFetchedAt;
    let requestHash;
    const isGetRequest = method.toLowerCase() === 'get';

    if (!isConnect() || (checkModified && isGetRequest)) {
      const { getHistory } = state.networkStatus;
      requestHash = objectHash(requestConfig, {
        unorderedArrays: true,
      });
      lastFetchedAt = getHistory[requestHash];

      if (lastFetchedAt) {
        requestConfig.headers['If-Modified-Since'] = lastFetchedAt;
      }
    }

    if (headers) {
      requestConfig.headers = { ...requestConfig.headers, ...headers };
    }

    // $FlowFixMe
    const response = await axios(requestConfig);

    if (
      ['/serviceImageUrl', '/startCheckImageUrl', '/endCheckImageUrl'].some(
        (path) => url.includes(path)
      )
    ) {
      return {
        data: response?.headers?.location || response?.data.value,
      };
    }

    if (url === '/user/paymentMethods/adyen/publicKey') {
      // eslint-disable-next-line no-underscore-dangle
      return {
        data: response.request._response || response.data,
      };
    }

    if (checkModified && isGetRequest && requestHash) {
      store.dispatch(setGetHistory(requestHash, new Date().toUTCString()));
    }

    if (response.headers && isXPairedDeviceHeaderSupported(url)) {
      store.dispatch(setDevicePaired(!response.headers['x-device-paired']));
    }

    return {
      data: response.data,
    };
  } catch (error: any) {
    if (error.headers && isXPairedDeviceHeaderSupported(url)) {
      store.dispatch(setDevicePaired(!error.headers['x-device-paired']));
    }

    if (error.response) {
      // Non-2xx response received
      const { data: errorData, status, headers: errorHeaders } = error.response;

      // 304 received for unmodified, cached response
      if (status === 304) {
        return {
          notModified: true,
        };
      }

      // handle HTTP_UNAUTHORIZED
      if (status === 401) {
        if (isConnect()) {
          clearAdminAuth();
        } else {
          store.dispatch(clearAuth);
        }
      }

      return {
        error: {
          type: 'error-response-received',
          detail: {
            data: errorData,
            status,
            headers: errorHeaders,
          },
          stack: error,
        },
      };
    }

    if (error.request) {
      // No response received
      // instanceof XMLHttpRequest
      return {
        error: {
          type: 'no-response-received',
          detail: error.request,
          stack: error,
        },
      };
    }

    // Error setting up the request
    return {
      error: {
        type: 'request-setup-failed',
        detail: {
          message: error.message,
        },
        stack: error,
      },
    };
  }
};
export default makeAPICall;
