import { PURGE } from 'redux-persist';
import initialState from './InitialState';
import TYPES from '../../../@types/redux/store/ConnectAppDataTypes';
import { ConnectAppDataAction, ConnectAppDataState } from '../../../@types';
export default (
  state: ConnectAppDataState = initialState,
  action: ConnectAppDataAction
): ConnectAppDataState => {
  switch (action.type) {
    case TYPES.SET_CARS: {
      return {
        ...state,
        cars: action.payload.cars!,
      };
    }
    case TYPES.SET_DEVICES: {
      return {
        ...state,
        devices: action.payload.devices!,
      };
    }
    case TYPES.SET_FUEL_CARDS: {
      return {
        ...state,
        fuelCards: action.payload.fuelCards!,
      };
    }
    case TYPES.SET_INSTALLATION: {
      return {
        ...state,
        installation: action.payload.installation!,
      };
    }

    // @ts-ignore
    case PURGE:
      return { ...initialState };

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
