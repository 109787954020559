export type FeaturesState = Array<string>;
export const MANDATORY_ALL_ROUND_PHOTOS = 'mandatoryAllAroundPhotos';

enum FeaturesTypes {
  SET_FEATURES = 'appData/features/SET_FEATURES',
}

export default FeaturesTypes;

export type SetFeatures = {
  type: FeaturesTypes.SET_FEATURES;
  payload: {
    features: Array<string>;
  };
};

export type FeaturesAction = SetFeatures;
