import distance from '@turf/distance';
import { locationFromString } from './locationFromString';
import { Location } from '../@types';

const fromHourMinuteToFormatted = (time: number): string => {
  let timeStr = time.toString();

  while (timeStr.length < 4) timeStr = `0${timeStr}`;

  return `${timeStr.slice(0, 2)}:${timeStr.slice(2, 4)}`;
};

const toOpeningHoursString = (
  localize: any,
  openingHours: Array<number>
): string => {
  if (openingHours[0] === 0 && openingHours[1] === 2359) {
    return localize('fuelstation.openallday');
  }

  return `${fromHourMinuteToFormatted(
    openingHours[0]
  )}-${fromHourMinuteToFormatted(openingHours[1])}`;
};

const toHoursInt = (openingHours: string): Array<number> =>
  openingHours ? openingHours.split('-').map((item) => parseInt(item, 10)) : [];

export const openingHoursString = (
  localize: any,
  weekDayFrom: string,
  weekDayTo: string,
  saturdayFrom: string,
  saturdayTo: string,
  sundayFrom: string,
  sundayTo: string,
  short: boolean = true,
  date: Date = new Date()
): string => {
  const weekDayHrs = `${weekDayFrom.replace(':', '')}-${weekDayTo.replace(
    ':',
    ''
  )}`;
  const satHrs = `${saturdayFrom.replace(':', '')}-${saturdayTo.replace(
    ':',
    ''
  )}`;
  const sunHrs = `${sundayFrom.replace(':', '')}-${sundayTo.replace(':', '')}`;
  const dayOfWeek = date.getDay();
  let todayStr = weekDayHrs;
  let tomorrowStr = weekDayHrs;

  switch (dayOfWeek) {
    case 5:
      tomorrowStr = satHrs;
      break;

    case 6:
      todayStr = satHrs;
      tomorrowStr = sunHrs;
      break;

    case 0:
      todayStr = sunHrs;
      break;

    default:
  }

  const currentTime = date.getHours() * 100 + date.getMinutes();
  const todayHours = toHoursInt(todayStr);
  let openingHours = todayHours;
  let status;

  if (todayHours[0] > currentTime) {
    status = 'openstoday';
  } else if (todayHours[1] > currentTime) {
    status = 'opennow';
  } else {
    openingHours = toHoursInt(tomorrowStr);
    status = 'openstomorrow';
  }

  if (short) {
    return localize(status);
  }

  return `${localize(status)}: ${toOpeningHoursString(localize, openingHours)}`;
};

export const distanceToNearestGasStation = (
  currentLocation: Location,
  gasStations: Array<any>
): any => {
  if (currentLocation) {
    const distances = gasStations
      .map((gasStation) => locationFromString(gasStation.location))
      .map((target) =>
        distance(
          [currentLocation.latitude, currentLocation.longitude],
          [target.latitude, target.longitude]
        )
      );
    return Math.min(...distances);
  }
  return 0;
};

export const statusForGasStation = (localize: any, gasStation: any) =>
  gasStation?.WeekDayFrom
    ? openingHoursString(
        localize,
        gasStation?.WeekDayFrom,
        gasStation?.WeekDayTo,
        gasStation?.SaturdayFrom,
        gasStation?.SaturdayTo,
        gasStation?.SundayFrom,
        gasStation?.SundayTo
      )
    : gasStation?.IsOpen24Hours === 'Yes' || gasStation?.IsOpen24Hours === 'Ja'
    ? localize('opennow')
    : null;
