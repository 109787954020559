import { APIResponse, S3TemporaryAccessData } from '../@types';
import { makeAPICall } from './helpers';

export type RentalImagePath =
  | 'serviceImage'
  | 'startCheckImage'
  | 'endCheckImage';

const awsS3Client = {
  getRentalCredential: async (
    rentalId: string
  ): Promise<APIResponse<S3TemporaryAccessData>> => {
    const { data, error } = await makeAPICall({
      method: 'GET',
      url: `/aws/s3/temporaryCredentials/trip/${rentalId}`,
    });
    return {
      data,
      error,
    };
  },
};
export default awsS3Client;
