import { connect } from 'react-redux';
import { compose } from 'recompose';
import { State } from '../@types';
import {
  setUser,
  login,
  signUp,
  forgetPassword,
  setAuth,
  clearAuth,
  getUser,
  updateUser,
  addPayment,
  checkReferral,
  getTransactionPaymentLink,
  getTransactions,
  getMoreTransactions,
  deletePayment,
  terminateUser,
  schufaCheck,
  sendVerificationCode,
  joinCircle,
  removeCircle,
  checkCircle,
  uploadDocument,
  getPaymentPublicKey,
  submitIdentityShopper,
  submitChallenge,
  isPaymentVerified,
  setRecoverPaymentAuth,
  getPaymentData,
  setActivePaymentData,
} from '../store/userData/user';
import { PaymentRequestData } from '../@types';

export type Props = {
  email: string;
  user: any | null | undefined;
  basicAuth: string | null | undefined;
  paymentPublicKey: string | null | undefined;
  recoverPaymentAuth: boolean;
  transactions: any;
  setUser: typeof setUser;
  login: typeof login;
  signUp: typeof signUp;
  forgetPassword: typeof forgetPassword;
  setAuth: typeof setAuth;
  clearAuth: typeof clearAuth;
  getUser: typeof getUser;
  updateUser: typeof updateUser;
  terminateUser: typeof terminateUser;
  addPayment: typeof addPayment;
  deletePayment: typeof deletePayment;
  checkReferral: typeof checkReferral;
  getTransactionPaymentLink: typeof getTransactionPaymentLink;
  getTransactions: typeof getTransactions;
  getMoreTransactions: typeof getMoreTransactions;
  schufaCheck: typeof schufaCheck;
  sendVerificationCode: typeof sendVerificationCode;
  joinCircle: typeof joinCircle;
  removeCircle: typeof removeCircle;
  checkCircle: typeof checkCircle;
  uploadDocument: typeof uploadDocument;
  getPaymentPublicKey: typeof getPaymentPublicKey;
  submitIdentityShopper: typeof submitIdentityShopper;
  submitChallenge: typeof submitChallenge;
  isPaymentVerified: typeof isPaymentVerified;
  setRecoverPaymentAuth: typeof setRecoverPaymentAuth;
  getPaymentData: typeof getPaymentData;
  activePaymentData: PaymentRequestData | null | undefined;
  setActivePaymentData: typeof setActivePaymentData;
};

const withUser: any = compose(
  connect(
    ({
      userData: {
        user: {
          email,
          user,
          basicAuth,
          transactions,
          paymentPublicKey,
          recoverPaymentAuth,
          signUpCircle,
          activePaymentData,
        },
      },
    }: State) => ({
      email,
      user,
      basicAuth,
      transactions,
      paymentPublicKey,
      recoverPaymentAuth,
      signUpCircle,
      activePaymentData,
    }),
    {
      setUser,
      login,
      signUp,
      forgetPassword,
      setAuth,
      clearAuth,
      getUser,
      updateUser,
      terminateUser,
      addPayment,
      deletePayment,
      checkReferral,
      getTransactionPaymentLink,
      getTransactions,
      getMoreTransactions,
      schufaCheck,
      sendVerificationCode,
      joinCircle,
      removeCircle,
      checkCircle,
      uploadDocument,
      getPaymentPublicKey,
      submitIdentityShopper,
      submitChallenge,
      isPaymentVerified,
      setRecoverPaymentAuth,
      setActivePaymentData,
      getPaymentData,
    }
  )
);
export default withUser;
