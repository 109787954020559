import { $PropertyType } from 'utility-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  setDeviceUUID,
  setPushDeviceID,
  setPushToken,
  setLanguage,
  setPlatform,
  setModel,
  setLastKnownLocation,
  setUserAgent,
  setDevicePaired,
  setCameraPermission,
  setLocationPermission,
  setBluetoothPermission,
  setOrientation,
  setTotalMemory,
  setUsedMemory,
} from '../store/device';
import { DeviceState, State } from '../@types';

export type Props = {
  deviceUUID: $PropertyType<DeviceState, 'deviceUUID'>;
  devicePaired: $PropertyType<
    $PropertyType<DeviceState, 'status'>,
    'devicePaired'
  >;
  locationPermission: any;
  cameraPermission: any;
  bluetoothPermission: any;
  language: $PropertyType<DeviceState, 'language'>;
  lastKnownLocation: $PropertyType<DeviceState, 'lastKnownLocation'>;
  setDeviceUUID: typeof setDeviceUUID;
  setPushDeviceID: typeof setPushDeviceID;
  setPushToken: typeof setPushToken;
  setPlatform: typeof setPlatform;
  setModel: typeof setModel;
  setLanguage: typeof setLanguage;
  setLastKnownLocation: typeof setLastKnownLocation;
  setUserAgent: typeof setUserAgent;
  setCameraPermission: typeof setCameraPermission;
  setLocationPermission: typeof setLocationPermission;
  setBluetoothPermission: typeof setBluetoothPermission;
  setDevicePaired: typeof setDevicePaired;
  setOrientation: typeof setOrientation;
  setTotalMemory: typeof setTotalMemory;
  setUsedMemory: typeof setUsedMemory;
  freeMemory: any;
};

const withDevice: any = compose(
  connect(
    ({
      device: {
        language,
        deviceUUID,
        lastKnownLocation,
        orientation,
        totalMemory,
        usedMemory,
        status: {
          devicePaired,
          locationPermission,
          cameraPermission,
          bluetoothPermission,
        },
      },
    }: State) => ({
      deviceUUID,
      lastKnownLocation,
      devicePaired,
      locationPermission,
      cameraPermission,
      bluetoothPermission,
      language,
      orientation,
      // @ts-ignore
      freeMemory: totalMemory - usedMemory,
    }),
    {
      setDeviceUUID,
      setPushDeviceID,
      setPushToken,
      setPlatform,
      setModel,
      setLanguage,
      setLastKnownLocation,
      setUserAgent,
      setCameraPermission,
      setLocationPermission,
      setBluetoothPermission,
      setDevicePaired,
      setOrientation,
      setTotalMemory,
      setUsedMemory,
    }
  )
);
export default withDevice;
