import { NetworkStatusState } from '../../@types';

const initialState: NetworkStatusState = {
  activities: [],
  errors: [],
  errorHistory: [],
  getHistory: {},
};

export default initialState;
