const ANIMATION_TYPE = {
  EXPANDED: 'expanded',
  COLLAPSED: 'collapsed',
  COLLAPSE: 'collapse',
  EXPAND: 'expand',
};

const SCROLL_DIRECTION = {
  UP: 'up',
  DOWN: 'down',
  LEFT: 'left',
  RIGHT: 'right',
  NONE: 'none',
};

const LANGUAGE = {
  DE: 'de',
  EN: 'en',
};

const PLATFORM = {
  IOS: 'ios' as string,
  ANDROID: 'android',
  BROWSER: 'browser',
};

const BRAND = {
  UBEEQO: '000000000000000000000002',
  ROBBEN_WIENTJES: '000000000000000000000003',
  KONIG: '6200f3fefe8c982d99ec1625',
};

const NOTIFICATION_TYPE = {
  INFO: 'INFO',
  ERROR: 'ERROR',
  CUSTOM: 'CUSTOM',
};

const ANALYTICS = {
  EVENT: {
    CAR_LICENCE_SUBMIT: 'Submitting carLicense',
    START_RENTAL: 'rentedVehicle',
    START_RENTAL_API: 'rentedVehicleAPI',
    CLICKING_FINISH_RENTAL: 'Clicking finishRental',
    CLICKING_REQUEST_CAR: 'Clicking requestCar',
    CLICKING_CREATE_BOOKING: 'Clicking createBooking',
    SELECTING_AVAILABLE_CAR: 'availableVehicle selected',
    SELECTING_UNAVAILABLE_CAR: 'Selecting unavailableCar',
    CREATING_BOOKING: 'Creating Booking',
    SHARING_CAR: 'Sharing car',
    SEARCHING_NOCARS: 'Searching noCars',
    RESERVE_VEHICLE: 'reserveVehicle',
    SIGN_UP: 'signUp',
    ADD_PHONE: 'addPhone',
    ADD_ADDRESS: 'addAddress',
    ADD_DRIVING_LICENSE: 'addDrivingLicense',
    ADD_PAYMENT: 'addPayment',
    FINISHED_FIRST_STEPS: '1stSteps',
    STARTED_BOOKING: 'Started Booking',
    ADD_IDENTITY_DOCUMENT: 'addIdentityDocument',
    SELECTING_POI_STATION: 'poiStation selected',
    CREATING_STATION_BOOKING: 'Creating station Booking',
  },
  SCREEN: {
    RETURN_TIME: 'Viewing returnTimeFilter',
    PRICE_DETAILS: 'Viewing PriceDetails',
    DRIVER_BOOKING_DETAILS: 'Viewing DriverBookingDetails',
    CAR_TROUBLESHOOTING: 'Viewing CarProfilePTroubleshooting',
    LIST_CAR_CARD: 'Viewing ListCarCard',
    LIST_CAR: 'Viewing ListCar',
    MAP: 'Viewing Map',
  },
  OPTIONS: {
    VIEWED: 'viewed',
    SUCCESS: 'success',
    FAILED: 'failed',
    CANCELED: 'canceled',
    DROPPED: 'dropped',
  },
};

const enums = {
  ANIMATION_TYPE,
  SCROLL_DIRECTION,
  LANGUAGE,
  PLATFORM,
  NOTIFICATION_TYPE,
  ANALYTICS,
  BRAND,
};

export default enums;
