export type Image = {
  readonly id?: string;
  uri: string;
  data?: string;
  base64?: string;
};

export type CarCheckImages = any;

export type CameraState = {
  readonly images: Array<Image>;
  readonly carCheckImages: CarCheckImages;
};

enum CameraTypes {
  SET_CAMERA_IMAGES = 'camera/SET_CAMERA_IMAGES',
  REMOVE_CAMERA_IMAGE = 'camera/REMOVE_CAMERA_IMAGE',
  CLEAR_CAMERA_IMAGES = 'camera/CLEAR_CAMERA_IMAGES',
  SET_CAR_CHECK_CAMERA_IMAGE = 'camera/SET_CAR_CHECK_CAMERA_IMAGE',
  REMOVE_CAR_CHECK_CAMERA_IMAGE = 'camera/REMOVE_CAR_CHECK_CAMERA_IMAGE',
  RESET_STORE = 'RESET_STORE',
}

export default CameraTypes;

export type SetCameraImages = {
  type: CameraTypes.SET_CAMERA_IMAGES;
  payload: Image;
};

export type RemoveCameraImage = {
  type: CameraTypes.REMOVE_CAMERA_IMAGE;
  payload: {
    id: string | number;
  };
};

export type ClearCameraImages = {
  type: CameraTypes.CLEAR_CAMERA_IMAGES;
};

export type SetCarCheckCameraImage = {
  type: CameraTypes.SET_CAR_CHECK_CAMERA_IMAGE;
  payload: {
    index: Number;
    uri: string;
    base64?: string;
  };
};

export type RemoveCarCheckCameraImage = {
  type: CameraTypes.REMOVE_CAR_CHECK_CAMERA_IMAGE;
  payload: {
    index: number;
  };
};

export type CameraAction =
  | SetCameraImages
  | RemoveCameraImage
  | ClearCameraImages
  | SetCarCheckCameraImage
  | RemoveCarCheckCameraImage;
