import memoize from 'memoize-one';
import TYPES, {
  SetDefaultTab,
  SetShowHomeWT,
  SetUserAddress,
} from '../../../@types/redux/store/MapTypes';
import {
  CardState,
  ClearMapFilter,
  MapFilterType,
  MapRegion,
  SetActiveAlert,
  SetAlertBaseMode,
  SetMapBoundaries,
  SetMapContent,
  SetMapFilter,
  SetShowUserLocation,
  Dispatch,
  GetState,
} from '../../../@types';
import { DriverCarData, Location, RentalData } from '../../../@types';
import {
  distanceBetweenLocationsInKm,
  isRentalCard,
  isRentalOngoing,
  locationFromString,
} from '../../../helpers';
import {
  deeplinkDataAvailable,
  mapContentFromDeeplink,
  setDeeplinkUrl,
} from '../deeplink';
import { contentIdFromDeeplink } from '../deeplink/helpers';
import { searchCarById } from '../../carsSearch';
import gasStations from './gasStations';

export const setMapFilter = (
  type: MapFilterType | null | undefined
): SetMapFilter => ({
  type: TYPES.SET_MAP_FILTER,
  payload: {
    type,
  },
});
export const setMapBoundaries = (
  mapBoundaries: Array<Location>
): SetMapBoundaries => ({
  type: TYPES.SET_MAP_BOUNDARIES,
  payload: {
    mapBoundaries,
  },
});
export const setDefaultTab = (defaultTab: number): SetDefaultTab => ({
  type: TYPES.SET_DEFAULT_TAB,
  payload: {
    defaultTab,
  },
});

export const setShowHomeWT = (showHomeWT: boolean): SetShowHomeWT => ({
  type: TYPES.SET_SHOW_HOME_WT,
  payload: {
    showHomeWT,
  },
});

export const setUserAddress = (address: string): SetUserAddress => ({
  type: TYPES.SET_USER_ADDRESS,
  payload: {
    address,
  },
});

export const setShowUserLocation = (
  showUserLocation: boolean
): SetShowUserLocation => ({
  type: TYPES.SET_USER_LOCATION,
  payload: {
    showUserLocation,
  },
});
export const clearMapFilter = (): ClearMapFilter => ({
  type: TYPES.CLEAR_MAP_FILTER,
});

const updateMapContent = (
  mapType: any | null | undefined,
  cardType: string,
  id: string[] | string | null | undefined = null
): SetMapContent => ({
  type: TYPES.SET_MAP_CONTENT,
  payload: {
    mapType,
    cardType,
    id,
  },
});

export const canNotModifyMapContent = (
  rentalData?: RentalData,
  cardType?: string,
  clearingRentalData: boolean = false
): boolean =>
  !!(
    !clearingRentalData &&
    rentalData &&
    isRentalOngoing(rentalData) &&
    !isRentalCard(cardType)
  );
export const setMapContent =
  (
    mapType: any | null | undefined,
    cardType: string,
    id: string[] | string | null | undefined = null,
    clearingRentalData: boolean = false
  ) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { content } = getState().ui.map;
    const { rentalData } = getState().userData.rental as RentalData;
    if (
      content.mapType === mapType &&
      content.cardType === cardType &&
      content.id === id
    )
      return;
    if (canNotModifyMapContent(rentalData, cardType, clearingRentalData))
      return;
    dispatch(updateMapContent(mapType, cardType, id));
  };
export const memorizedSortedCars = memoize(
  (cars: Array<DriverCarData>, userLocation: Location) =>
    cars.sort((l, r) =>
      distanceBetweenLocationsInKm(
        locationFromString(l.position),
        userLocation
      ) >
      distanceBetweenLocationsInKm(locationFromString(r.position), userLocation)
        ? 1
        : -1
    )
);
export const resolveInitialMapContent = // eslint-disable-next-line no-unused-vars

    (userLocation: Location | null | undefined) =>
    (dispatch: Dispatch, getState: GetState) => {
      const { rentalData } = getState().userData.rental;
      const { availableCars, unavailableCars } = getState().carsSearch;
      const { deeplinkUrl } = getState().ui.deeplink;

      if (rentalData === null) {
        if (deeplinkUrl.length > 0 && deeplinkUrl.includes('/map?id')) {
          const mapContent = mapContentFromDeeplink(
            deeplinkUrl,
            availableCars,
            unavailableCars,
            gasStations
          );

          if (mapContent) {
            dispatch(
              setMapContent(
                mapContent.mapType,
                mapContent.cardType,
                mapContent.id
              )
            );
          } else {
            // clear map content in case car is not found
            dispatch(setMapContent(null, 'none', null));
          }

          dispatch(setDeeplinkUrl(''));
        } // else if (availableCarsList.length > 0 && userLocation) {
        //   const sortedCars = userLocation
        //     ? memorizedSortedCars(availableCarsList, userLocation)
        //     : availableCarsList;
        //   if (sortedCars[0]?.vehicleConnection !== 'geoTab') {
        //     dispatch(
        //       setMapContent('availableCar', content.cardType, sortedCars[0].id),
        //     );
        //   }
        // }
      }
    };
export const setInitialMapContent =
  () => async (dispatch: Dispatch, getState: GetState) => {
    const { deeplinkUrl } = getState().ui.deeplink;
    const contentId = contentIdFromDeeplink(deeplinkUrl);

    if (contentId) {
      const { availableCars, unavailableCars } = getState().carsSearch;

      if (
        !deeplinkDataAvailable(
          deeplinkUrl,
          availableCars,
          unavailableCars,
          gasStations
        )
      ) {
        dispatch(searchCarById(contentId));
      }
    }
  };
export const updateMapContentWithCar =
  (carId: string) => (dispatch: Dispatch, getState: GetState) => {
    const { content } = getState().ui.map;
    const availableCar = getState().carsSearch.availableCars[carId];

    if (availableCar) {
      dispatch(setMapContent('selectedAvailableCar', content.cardType, carId));
    }

    const unavailableCar = getState().carsSearch.unavailableCars[carId];

    if (unavailableCar) {
      dispatch(setMapContent('selectedUnavailableCar', 'none', carId));
    }
  };
export const setMapRegion = (region: MapRegion) => (dispatch: Dispatch) => {
  dispatch({
    type: TYPES.SET_MAP_REGION,
    payload: {
      region,
    },
  });
};
export const setCardState = (cardState: CardState) => (dispatch: Dispatch) => {
  dispatch({
    type: TYPES.SET_CARD_STATE,
    payload: {
      cardState,
    },
  });
};
export const setCarsSearchAllowed =
  (carsSearchAllowed: boolean) => (dispatch: Dispatch) => {
    dispatch({
      type: TYPES.SET_CARS_SEARCH_ALLOWED,
      payload: {
        carsSearchAllowed,
      },
    });
  };
export const setOpened = (opened: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: TYPES.SET_OPENED,
    payload: {
      opened,
    },
  });
};
export const setIsFirstRental =
  (isFirstRental: boolean) => (dispatch: Dispatch) => {
    dispatch({
      type: TYPES.SET_IS_FIRST_RENTAL,
      payload: {
        isFirstRental,
      },
    });
  };
export const setSelectedCar =
  (selectedCar: DriverCarData | null | undefined) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { content } = getState().ui.map;
    dispatch({
      type: TYPES.SET_SELECTED_CAR,
      payload: {
        selectedCar,
      },
    });

    if (selectedCar === null && content?.mapType !== 'bookingArea') {
      dispatch(setMapContent(null, 'none', null));
    }
  };
export const setActiveAlert = (
  activeAlert: any | null | undefined
): SetActiveAlert => ({
  type: TYPES.SET_ACTIVE_ALERT,
  payload: {
    activeAlert,
  },
});
export const setAlertBaseMode = (alertBaseMode: boolean): SetAlertBaseMode => ({
  type: TYPES.SET_ALERT_BASE_MODE,
  payload: {
    alertBaseMode,
  },
});
