import { CarEquipmentsData } from '../../index';

export type CarEquipmentsState = CarEquipmentsData | undefined;

enum CarEquipmentsTypes {
  SET_CAR_EQUIPMENTS = 'appData/carEquipments/SET_CAR_EQUIPMENTS',
}

export default CarEquipmentsTypes;

export type SetCarEquipments = {
  type: CarEquipmentsTypes.SET_CAR_EQUIPMENTS;
  payload: {
    carEquipments: CarEquipmentsData | undefined;
  };
};

export type CarEquipmentsAction = SetCarEquipments;
