import { PURGE } from 'redux-persist';
import initialState from './InitialState';
import TYPES from '../../../@types/redux/store/DimensionsTypes';
import { DimensionsAction, DimensionsState } from '../../../@types';

export default (
  state: DimensionsState = initialState,
  action: DimensionsAction
): DimensionsState => {
  switch (action.type) {
    case TYPES.PUBLISH_HEADER_HEIGHT: {
      return { ...state, headerHeight: action.payload.headerHeight };
    }
    // @ts-ignore
    case PURGE:
      return initialState;

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
