import { PURGE } from 'redux-persist';
import initialState from './InitialState';
import TYPES from '../../../@types/redux/store/DamagesTypes';
import { DamagesAction, DamagesState } from '../../../@types';

export default (
  state: DamagesState = initialState,
  action: DamagesAction
): DamagesState => {
  switch (action.type) {
    case TYPES.SET_NEW_DAMAGE: {
      return { ...state, newDamages: [...state.newDamages, action.payload.id] };
    }

    case TYPES.CLEAR_NEW_DAMAGES: {
      return initialState;
    }

    // @ts-ignore
    case PURGE:
      return initialState;

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
