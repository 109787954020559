export default [{
  "Brand": "ARAL",
  "Street": "Heerstraße  325",
  "PostCode": 13593,
  "City": "Berlin",
  "Latitude": "52.516.664",
  "Longitude": "13.177.808",
  "Telephone": "0303616676",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.516664,13.177808",
  "id": "52.516664,13.177808"
}, {
  "Brand": "ARAL",
  "Street": "Residenzstraße 29",
  "PostCode": 13409,
  "City": "Berlin",
  "Latitude": "52.568.315",
  "Longitude": "13.361.864",
  "Telephone": "0304961035",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.568315,13.361864",
  "id": "52.568315,13.361864"
}, {
  "Brand": "ARAL",
  "Street": "Seestraße 18/19",
  "PostCode": 13353,
  "City": "Berlin",
  "Latitude": "52.546.403",
  "Longitude": "13.345.187",
  "Telephone": "0304536026",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.546403,13.345187",
  "id": "52.546403,13.345187"
}, {
  "Brand": "ARAL",
  "Street": "Britzer Damm 202-204",
  "PostCode": 12347,
  "City": "Berlin",
  "Latitude": "52.445.368",
  "Longitude": "13.433.414",
  "Telephone": "0306076210",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.445368,13.433414",
  "id": "52.445368,13.433414"
}, {
  "Brand": "ARAL",
  "Street": "Potsdamer Chaussee 6",
  "PostCode": 14163,
  "City": "Berlin",
  "Latitude": "52.433.110",
  "Longitude": "13.238.092",
  "Telephone": "0308027017",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.43311,13.238092",
  "id": "52.43311,13.238092"
}, {
  "Brand": "ARAL",
  "Street": "Reichsstraße 13/14",
  "PostCode": 14052,
  "City": "Berlin",
  "Latitude": "52.513.646",
  "Longitude": "13.266.036",
  "Telephone": "0303042708",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.513646,13.266036",
  "id": "52.513646,13.266036"
}, {
  "Brand": "ARAL",
  "Street": "Prinzenstraße 29",
  "PostCode": 10969,
  "City": "Berlin",
  "Latitude": "52.501.894",
  "Longitude": "13.409.839",
  "Telephone": "0306149860",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.501894,13.409839",
  "id": "52.501894,13.409839"
}, {
  "Brand": "ARAL",
  "Street": "Falkenseer Damm 16",
  "PostCode": 13585,
  "City": "Berlin",
  "Latitude": "52.542.746",
  "Longitude": "13.199.304",
  "Telephone": "0303333811",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.542746,13.199304",
  "id": "52.542746,13.199304"
}, {
  "Brand": "ARAL",
  "Street": "Rathenower Straße 8",
  "PostCode": 10559,
  "City": "Berlin",
  "Latitude": "52.525.458",
  "Longitude": "13.356.891",
  "Telephone": "0303944041",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.525458,13.356891",
  "id": "52.525458,13.356891"
}, {
  "Brand": "ARAL",
  "Street": "Levetzowstraße 9",
  "PostCode": 10555,
  "City": "Berlin",
  "Latitude": "52.521.082",
  "Longitude": "13.334.004",
  "Telephone": "0303914575",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.521082,13.334004",
  "id": "52.521082,13.334004"
}, {
  "Brand": "ARAL",
  "Street": "Argentinische Allee 49",
  "PostCode": 14163,
  "City": "Berlin",
  "Latitude": "52.444.544",
  "Longitude": "13.242.588",
  "Telephone": "03081496169",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "52.444544,13.242588",
  "id": "52.444544,13.242588"
}, {
  "Brand": "ARAL",
  "Street": "Brunnenstraße 119",
  "PostCode": 13355,
  "City": "Berlin",
  "Latitude": "52.540.785",
  "Longitude": "13.393.457",
  "Telephone": "03046404481",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.540785,13.393457",
  "id": "52.540785,13.393457"
}, {
  "Brand": "ARAL",
  "Street": "Mariendorfer Damm 195",
  "PostCode": 12107,
  "City": "Berlin",
  "Latitude": "52.434.036",
  "Longitude": "13.390.571",
  "Telephone": "0307418509",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.434036,13.390571",
  "id": "52.434036,13.390571"
}, {
  "Brand": "ARAL",
  "Street": "Gartenfelder Straße 71",
  "PostCode": 13599,
  "City": "Berlin",
  "Latitude": "52.543.568",
  "Longitude": "13.243.265",
  "Telephone": "0303343935",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.543568,13.243265",
  "id": "52.543568,13.243265"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 86",
  "PostCode": 12159,
  "City": "Berlin",
  "Latitude": "52.474.113",
  "Longitude": "13.338.869",
  "Telephone": "0308515286",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.474113,13.338869",
  "id": "52.474113,13.338869"
}, {
  "Brand": "ARAL",
  "Street": "Scharnweberstraße 12-13",
  "PostCode": 13405,
  "City": "Berlin",
  "Latitude": "52.563.375",
  "Longitude": "13.329.887",
  "Telephone": "0304964005",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.563375,13.329887",
  "id": "52.563375,13.329887"
}, {
  "Brand": "ARAL",
  "Street": "Neuköllner Straße 219",
  "PostCode": 12357,
  "City": "Berlin",
  "Latitude": "52.428.631",
  "Longitude": "13.479.837",
  "Telephone": "0306614212",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.428631,13.479837",
  "id": "52.428631,13.479837"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Straße 24",
  "PostCode": 13507,
  "City": "Berlin",
  "Latitude": "52.584.372",
  "Longitude": "13.287.744",
  "Telephone": "03043400950",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.584372,13.287744",
  "id": "52.584372,13.287744"
}, {
  "Brand": "ARAL",
  "Street": "Vor dem Schlesischen Tor",
  "PostCode": 10997,
  "City": "Berlin",
  "Latitude": "52.496.857",
  "Longitude": "13.449.614",
  "Telephone": "03061286590",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.496857,13.449614",
  "id": "52.496857,13.449614"
}, {
  "Brand": "ARAL",
  "Street": "Forsthausstraße 50",
  "PostCode": 60528,
  "City": "Frankfurt",
  "Latitude": "50.080.015",
  "Longitude": "08.655.258",
  "Telephone": "069671026",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.080015,8.655258",
  "id": "50.080015,8.655258"
}, {
  "Brand": "ARAL",
  "Street": "Eschersh. Landstr.  523",
  "PostCode": 60431,
  "City": "Frankfurt",
  "Latitude": "50.151.655",
  "Longitude": "08.662.366",
  "Telephone": "069525947",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "08:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "50.151655,8.662366",
  "id": "50.151655,8.662366"
}, {
  "Brand": "ARAL",
  "Street": "Louisenstraße 146",
  "PostCode": 61348,
  "City": "Bad Homburg",
  "Latitude": "50.221.734",
  "Longitude": "08.628.591",
  "Telephone": "06172450201",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.221734,8.628591",
  "id": "50.221734,8.628591"
}, {
  "Brand": "ARAL",
  "Street": "Gelnhäuser Straße 17",
  "PostCode": 63505,
  "City": "Langenselbold",
  "Latitude": "50.175.696",
  "Longitude": "09.046.026",
  "Telephone": "061842103",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.175696,9.046026",
  "id": "50.175696,9.046026"
}, {
  "Brand": "ARAL",
  "Street": "A 66 Erbenheim-Nord",
  "PostCode": 65205,
  "City": "Wiesbaden",
  "Latitude": "50.055.113",
  "Longitude": "08.311.917",
  "Telephone": "0611718300",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.055113,8.311917",
  "id": "50.055113,8.311917"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Landstr. 200",
  "PostCode": 61440,
  "City": "Oberursel",
  "Latitude": "50.180.441",
  "Longitude": "08.605.376",
  "Telephone": "0617173993",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.180441,8.605376",
  "id": "50.180441,8.605376"
}, {
  "Brand": "ARAL",
  "Street": "Konrad-Adenauer-Allee 1-3",
  "PostCode": 61130,
  "City": "Nidderau",
  "Latitude": "50.233.936",
  "Longitude": "08.863.251",
  "Telephone": "0618722133",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.233936,8.863251",
  "id": "50.233936,8.863251"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Straße 52",
  "PostCode": 61476,
  "City": "Kronberg",
  "Latitude": "50.173.923",
  "Longitude": "08.523.387",
  "Telephone": "0617379124",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.173923,8.523387",
  "id": "50.173923,8.523387"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 10",
  "PostCode": 63486,
  "City": "Bruchköbel",
  "Latitude": "50.178.068",
  "Longitude": "08.914.258",
  "Telephone": "0618177724",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "18:00",
  "IsOpen24Hours": "Nein",
  "location": "50.178068,8.914258",
  "id": "50.178068,8.914258"
}, {
  "Brand": "ARAL",
  "Street": "Kirchhohl 15",
  "PostCode": 65207,
  "City": "Wiesbaden",
  "Latitude": "50.132.818",
  "Longitude": "08.297.100",
  "Telephone": "0612761428",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "18:00",
  "SundayFrom": "10:00",
  "SundayTo": "17:00",
  "IsOpen24Hours": "Nein",
  "location": "50.132818,8.2971",
  "id": "50.132818,8.2971"
}, {
  "Brand": "ARAL",
  "Street": "Dotzheimer Straße 93",
  "PostCode": 65197,
  "City": "Wiesbaden",
  "Latitude": "50.077.702",
  "Longitude": "08.221.285",
  "Telephone": "061147027",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.077702,8.221285",
  "id": "50.077702,8.221285"
}, {
  "Brand": "ARAL",
  "Street": "Bieberer Straße 157",
  "PostCode": 63071,
  "City": "Offenbach",
  "Latitude": "50.098.988",
  "Longitude": "08.785.816",
  "Telephone": "06985703117",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.098988,8.785816",
  "id": "50.098988,8.785816"
}, {
  "Brand": "ARAL",
  "Street": "Darmstädter Landstr. 304",
  "PostCode": 60598,
  "City": "Frankfurt",
  "Latitude": "50.089.701",
  "Longitude": "08.690.747",
  "Telephone": "0696809040",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.089701,8.690747",
  "id": "50.089701,8.690747"
}, {
  "Brand": "ARAL",
  "Street": "Mainzer Landstraße 545",
  "PostCode": 65933,
  "City": "Frankfurt",
  "Latitude": "50.100.187",
  "Longitude": "08.603.721",
  "Telephone": "069394954",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.100187,8.603721",
  "id": "50.100187,8.603721"
}, {
  "Brand": "ARAL",
  "Street": "Zeilsheimer Straße 62",
  "PostCode": 65719,
  "City": "Hofheim",
  "Latitude": "50.087.986",
  "Longitude": "08.458.349",
  "Telephone": "061926791",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.087986,8.458349",
  "id": "50.087986,8.458349"
}, {
  "Brand": "ARAL",
  "Street": "Pariser Straße 2",
  "PostCode": 55131,
  "City": "Mainz",
  "Latitude": "49.987.860",
  "Longitude": "08.266.955",
  "Telephone": "06131573110",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.98786,8.266955",
  "id": "49.98786,8.266955"
}, {
  "Brand": "ARAL",
  "Street": "Erbacher Straße 2",
  "PostCode": 65343,
  "City": "Eltville",
  "Latitude": "50.024.137",
  "Longitude": "08.116.059",
  "Telephone": "06123899352",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.024137,8.116059",
  "id": "50.024137,8.116059"
}, {
  "Brand": "ARAL",
  "Street": "A3",
  "PostCode": 65520,
  "City": "Bad Camberg",
  "Latitude": "50.300.410",
  "Longitude": "08.236.824",
  "Telephone": "064346568",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.30041,8.236824",
  "id": "50.30041,8.236824"
}, {
  "Brand": "ARAL",
  "Street": "Steinbacher Straße1",
  "PostCode": 65620,
  "City": "Waldbrunn",
  "Latitude": "50.505.561",
  "Longitude": "08.092.233",
  "Telephone": "06436602648",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.505561,8.092233",
  "id": "50.505561,8.092233"
}, {
  "Brand": "ARAL",
  "Street": "Urseler Straße 38",
  "PostCode": 61348,
  "City": "Bad Homburg",
  "Latitude": "50.221.659",
  "Longitude": "08.607.080",
  "Telephone": "06172301300",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.221659,8.60708",
  "id": "50.221659,8.60708"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 1",
  "PostCode": 55595,
  "City": "Roxheim",
  "Latitude": "49.867.574",
  "Longitude": "07.820.235",
  "Telephone": "067120354475",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.867574,7.820235",
  "id": "49.867574,7.820235"
}, {
  "Brand": "ARAL",
  "Street": "Monzinger Straße 102-104",
  "PostCode": 55566,
  "City": "Bad Sobernheim",
  "Latitude": "49.787.675",
  "Longitude": "07.639.332",
  "Telephone": "067514069",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "07:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.787675,7.639332",
  "id": "49.787675,7.639332"
}, {
  "Brand": "ARAL",
  "Street": "Bosenheimer Straße 45",
  "PostCode": 55543,
  "City": "Bad Kreuznach",
  "Latitude": "49.841.989",
  "Longitude": "07.867.241",
  "Telephone": "06718962600",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.841989,7.867241",
  "id": "49.841989,7.867241"
}, {
  "Brand": "ARAL",
  "Street": "Im Steinautal 2",
  "PostCode": 55765,
  "City": "Ellweiler",
  "Latitude": "49.617.227",
  "Longitude": "07.158.907",
  "Telephone": "067822453",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.617227,7.158907",
  "id": "49.617227,7.158907"
}, {
  "Brand": "ARAL",
  "Street": "Kreuznacher Straße/B 41",
  "PostCode": 55596,
  "City": "Waldböckelheim",
  "Latitude": "49.819.016",
  "Longitude": "07.721.366",
  "Telephone": "067586273",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.819016,7.721366",
  "id": "49.819016,7.721366"
}, {
  "Brand": "ARAL",
  "Street": "Aral an der A 66, Nordsei",
  "PostCode": 65929,
  "City": "Frankfurt",
  "Latitude": "50.119.608",
  "Longitude": "08.543.903",
  "Telephone": "06930852981",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.119608,8.543903",
  "id": "50.119608,8.543903"
}, {
  "Brand": "ARAL",
  "Street": "Aral an der A 66, Südseit",
  "PostCode": 65929,
  "City": "Frankfurt",
  "Latitude": "50.118.079",
  "Longitude": "08.541.727",
  "Telephone": "06930850810",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.118079,8.541727",
  "id": "50.118079,8.541727"
}, {
  "Brand": "ARAL",
  "Street": "Vordere Oderstraße 2",
  "PostCode": 65468,
  "City": "Trebur",
  "Latitude": "49.930.350",
  "Longitude": "08.406.678",
  "Telephone": "061473139",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.93035,8.406678",
  "id": "49.93035,8.406678"
}, {
  "Brand": "ARAL",
  "Street": "Gelnhäuser Straße 1",
  "PostCode": 63571,
  "City": "Gelnhausen",
  "Latitude": "50.186.713",
  "Longitude": "09.162.186",
  "Telephone": "0605168801",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.186713,9.162186",
  "id": "50.186713,9.162186"
}, {
  "Brand": "ARAL",
  "Street": "Adam-Opel-Straße 47-49",
  "PostCode": 65428,
  "City": "Rüsselsheim",
  "Latitude": "49.985.192",
  "Longitude": "08.429.170",
  "Telephone": "0614262663",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.985192,8.42917",
  "id": "49.985192,8.42917"
}, {
  "Brand": "ARAL",
  "Street": "Hitchinstraße 44",
  "PostCode": 55411,
  "City": "Bingen",
  "Latitude": "49.948.458",
  "Longitude": "07.903.118",
  "Telephone": "0672144743",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.948458,7.903118",
  "id": "49.948458,7.903118"
}, {
  "Brand": "ARAL",
  "Street": "Waldstraße 215",
  "PostCode": 63071,
  "City": "Offenbach",
  "Latitude": "50.086.880",
  "Longitude": "08.770.483",
  "Telephone": "06983071423",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.08688,8.770483",
  "id": "50.08688,8.770483"
}, {
  "Brand": "ARAL",
  "Street": "Homburger Straße 66",
  "PostCode": 61184,
  "City": "Karben",
  "Latitude": "50.231.780",
  "Longitude": "08.766.445",
  "Telephone": "060393472",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "04:30",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.23178,8.766445",
  "id": "50.23178,8.766445"
}, {
  "Brand": "ARAL",
  "Street": "An der B 49 Südseite",
  "PostCode": 65614,
  "City": "Beselich",
  "Latitude": "50.467.915",
  "Longitude": "08.137.499",
  "Telephone": "06484396",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.467915,8.137499",
  "id": "50.467915,8.137499"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 27",
  "PostCode": 61209,
  "City": "Echzell",
  "Latitude": "50.383.314",
  "Longitude": "08.882.463",
  "Telephone": "06008424",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.383314,8.882463",
  "id": "50.383314,8.882463"
}, {
  "Brand": "ARAL",
  "Street": "Höhenstraße 1",
  "PostCode": 61381,
  "City": "Friedrichsdorf",
  "Latitude": "50.252.922",
  "Longitude": "08.635.035",
  "Telephone": "061722663645",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.252922,8.635035",
  "id": "50.252922,8.635035"
}, {
  "Brand": "ARAL",
  "Street": "Lämmerspieler Straße 50",
  "PostCode": 63179,
  "City": "Obertshausen",
  "Latitude": "50.088.720",
  "Longitude": "08.863.730",
  "Telephone": "06104407280",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:30",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:30",
  "SundayFrom": "07:00",
  "SundayTo": "21:30",
  "IsOpen24Hours": "Nein",
  "location": "50.08872,8.86373",
  "id": "50.08872,8.86373"
}, {
  "Brand": "ARAL",
  "Street": "Kennedy-/Berliner Straße",
  "PostCode": 63477,
  "City": "Maintal",
  "Latitude": "50.132.162",
  "Longitude": "08.850.056",
  "Telephone": "06181441748",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.132162,8.850056",
  "id": "50.132162,8.850056"
}, {
  "Brand": "ARAL",
  "Street": "Usinger Straße 154",
  "PostCode": 61239,
  "City": "Ober-Mörlen-Ziegenbe",
  "Latitude": "50.366.384",
  "Longitude": "08.623.850",
  "Telephone": "06002868",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "19:30",
  "SundayFrom": "08:00",
  "SundayTo": "19:30",
  "IsOpen24Hours": "Nein",
  "location": "50.366384,8.62385",
  "id": "50.366384,8.62385"
}, {
  "Brand": "ARAL",
  "Street": "Lindenstraße 33",
  "PostCode": 63538,
  "City": "Großkrotzenburg",
  "Latitude": "50.081.866",
  "Longitude": "08.986.247",
  "Telephone": "06186318",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "09:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.081866,8.986247",
  "id": "50.081866,8.986247"
}, {
  "Brand": "ARAL",
  "Street": "Ludwig-Landmann-Straße 90",
  "PostCode": 60488,
  "City": "Frankfurt",
  "Latitude": "50.141.447",
  "Longitude": "08.609.893",
  "Telephone": "069764309",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "50.141447,8.609893",
  "id": "50.141447,8.609893"
}, {
  "Brand": "ARAL",
  "Street": "Kelkheimer Str 43",
  "PostCode": 65779,
  "City": "Kelkheim",
  "Latitude": "50.145.994",
  "Longitude": "08.429.585",
  "Telephone": "0619561301",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.145994,8.429585",
  "id": "50.145994,8.429585"
}, {
  "Brand": "ARAL",
  "Street": "B 448",
  "PostCode": 63110,
  "City": "Rodgau",
  "Latitude": "50.069.198",
  "Longitude": "08.877.869",
  "Telephone": "061044657",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.069198,8.877869",
  "id": "50.069198,8.877869"
}, {
  "Brand": "ARAL",
  "Street": "Ratsweg 12",
  "PostCode": 60386,
  "City": "Frankfurt",
  "Latitude": "50.123.524",
  "Longitude": "08.723.396",
  "Telephone": "069413968",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.123524,8.723396",
  "id": "50.123524,8.723396"
}, {
  "Brand": "ARAL",
  "Street": "Koblenzer Straße 81",
  "PostCode": 56130,
  "City": "Bad Ems",
  "Latitude": "50.336.306",
  "Longitude": "07.706.284",
  "Telephone": "026032131",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.336306,7.706284",
  "id": "50.336306,7.706284"
}, {
  "Brand": "ARAL",
  "Street": "Koblenzer Straße 15",
  "PostCode": 56459,
  "City": "Langenhahn",
  "Latitude": "50.583.839",
  "Longitude": "07.918.299",
  "Telephone": "02663969011",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.583839,7.918299",
  "id": "50.583839,7.918299"
}, {
  "Brand": "ARAL",
  "Street": "Am Autobahnzubringer/West",
  "PostCode": 56203,
  "City": "Höhr-Grenzhausen",
  "Latitude": "50.441.919",
  "Longitude": "07.666.416",
  "Telephone": "026242696",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.441919,7.666416",
  "id": "50.441919,7.666416"
}, {
  "Brand": "ARAL",
  "Street": "Koblenzer Straße 25 a",
  "PostCode": 56410,
  "City": "Montabaur",
  "Latitude": "50.429.346",
  "Longitude": "07.822.906",
  "Telephone": "02602970181",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.429346,7.822906",
  "id": "50.429346,7.822906"
}, {
  "Brand": "ARAL",
  "Street": "Hunsrückhöhenstraße",
  "PostCode": 54497,
  "City": "Morbach",
  "Latitude": "49.816.904",
  "Longitude": "07.127.880",
  "Telephone": "065333195",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.816904,7.12788",
  "id": "49.816904,7.12788"
}, {
  "Brand": "ARAL",
  "Street": "Kurfürstenstraße 69",
  "PostCode": 54516,
  "City": "Wittlich",
  "Latitude": "49.982.280",
  "Longitude": "06.900.637",
  "Telephone": "0657120841",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.98228,6.900637",
  "id": "49.98228,6.900637"
}, {
  "Brand": "ARAL",
  "Street": "Ostallee 22",
  "PostCode": 54290,
  "City": "Trier",
  "Latitude": "49.754.705",
  "Longitude": "06.647.396",
  "Telephone": "065174942",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.754705,6.647396",
  "id": "49.754705,6.647396"
}, {
  "Brand": "ARAL",
  "Street": "Griedeler Straße 70",
  "PostCode": 35510,
  "City": "Butzbach",
  "Latitude": "50.436.610",
  "Longitude": "08.681.257",
  "Telephone": "06033970722",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.43661,8.681257",
  "id": "50.43661,8.681257"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 9",
  "PostCode": 56477,
  "City": "Rennerod",
  "Latitude": "50.603.163",
  "Longitude": "08.064.023",
  "Telephone": "02664999560",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Ja",
  "location": "50.603163,8.064023",
  "id": "50.603163,8.064023"
}, {
  "Brand": "ARAL",
  "Street": "Oppenheimer Str. 7",
  "PostCode": 64521,
  "City": "Groß-Gerau",
  "Latitude": "49.921.675",
  "Longitude": "08.472.126",
  "Telephone": "061527854",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.921675,8.472126",
  "id": "49.921675,8.472126"
}, {
  "Brand": "ARAL",
  "Street": "Biebricher Allee 186",
  "PostCode": 65203,
  "City": "Wiesbaden",
  "Latitude": "50.048.535",
  "Longitude": "08.241.274",
  "Telephone": "0611694289",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.048535,8.241274",
  "id": "50.048535,8.241274"
}, {
  "Brand": "ARAL",
  "Street": "Schlangenbader Straße 8",
  "PostCode": 65344,
  "City": "Eltville",
  "Latitude": "50.056.360",
  "Longitude": "08.119.202",
  "Telephone": "0612371407",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.05636,8.119202",
  "id": "50.05636,8.119202"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Straße",
  "PostCode": 63619,
  "City": "Bad Orb",
  "Latitude": "50.229.155",
  "Longitude": "09.343.829",
  "Telephone": "06052900313",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.229155,9.343829",
  "id": "50.229155,9.343829"
}, {
  "Brand": "ARAL",
  "Street": "Taunus-Schnellweg-Nordsei",
  "PostCode": 61352,
  "City": "Bad Homburg",
  "Latitude": "50.208.720",
  "Longitude": "08.616.669",
  "Telephone": "0617221266",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.20872,8.616669",
  "id": "50.20872,8.616669"
}, {
  "Brand": "ARAL",
  "Street": "Taunus-Schnellweg-Südseit",
  "PostCode": 61352,
  "City": "Bad Homburg",
  "Latitude": "50.208.427",
  "Longitude": "08.615.339",
  "Telephone": "06172690835",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.208427,8.615339",
  "id": "50.208427,8.615339"
}, {
  "Brand": "ARAL",
  "Street": "Am Industriehof 11",
  "PostCode": 60487,
  "City": "Frankfurt",
  "Latitude": "50.129.113",
  "Longitude": "08.624.005",
  "Telephone": "069776451",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.129113,8.624005",
  "id": "50.129113,8.624005"
}, {
  "Brand": "ARAL",
  "Street": "Chauvignystraße 15",
  "PostCode": 65366,
  "City": "Geisenheim",
  "Latitude": "49.988.261",
  "Longitude": "07.977.877",
  "Telephone": "06722995520",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.988261,7.977877",
  "id": "49.988261,7.977877"
}, {
  "Brand": "ARAL",
  "Street": "Isenburger Straße 20",
  "PostCode": 63150,
  "City": "Heusenstamm",
  "Latitude": "50.058.698",
  "Longitude": "08.788.944",
  "Telephone": "061045123",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.058698,8.788944",
  "id": "50.058698,8.788944"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Straße 55",
  "PostCode": 65549,
  "City": "Limburg",
  "Latitude": "50.383.734",
  "Longitude": "08.070.257",
  "Telephone": "06431408687",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.383734,8.070257",
  "id": "50.383734,8.070257"
}, {
  "Brand": "ARAL",
  "Street": "Friedrich-Ebert-Straße54",
  "PostCode": 65428,
  "City": "Rüsselsheim",
  "Latitude": "49.986.844",
  "Longitude": "08.416.374",
  "Telephone": "0614215334",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.986844,8.416374",
  "id": "49.986844,8.416374"
}, {
  "Brand": "ARAL",
  "Street": "Darmstädter Straße 43",
  "PostCode": 63303,
  "City": "Dreieich",
  "Latitude": "50.013.304",
  "Longitude": "08.692.331",
  "Telephone": "0610365671",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.013304,8.692331",
  "id": "50.013304,8.692331"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Straße 68",
  "PostCode": 65189,
  "City": "Wiesbaden",
  "Latitude": "50.066.237",
  "Longitude": "08.269.020",
  "Telephone": "0611701141",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.066237,8.26902",
  "id": "50.066237,8.26902"
}, {
  "Brand": "ARAL",
  "Street": "Bierstadter Straße 51",
  "PostCode": 65189,
  "City": "Wiesbaden",
  "Latitude": "50.083.342",
  "Longitude": "08.262.308",
  "Telephone": "0611565373",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.083342,8.262308",
  "id": "50.083342,8.262308"
}, {
  "Brand": "ARAL",
  "Street": "Mainzer Straße 103",
  "PostCode": 65189,
  "City": "Wiesbaden",
  "Latitude": "50.062.170",
  "Longitude": "08.252.317",
  "Telephone": "061174478",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.06217,8.252317",
  "id": "50.06217,8.252317"
}, {
  "Brand": "ARAL",
  "Street": "Andernacher Straße 207",
  "PostCode": 56070,
  "City": "Koblenz",
  "Latitude": "50.378.368",
  "Longitude": "07.584.987",
  "Telephone": "026183720",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.378368,7.584987",
  "id": "50.378368,7.584987"
}, {
  "Brand": "ARAL",
  "Street": "Schlachthofstraße 79",
  "PostCode": 56073,
  "City": "Koblenz",
  "Latitude": "50.362.888",
  "Longitude": "07.574.478",
  "Telephone": "026142582",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.362888,7.574478",
  "id": "50.362888,7.574478"
}, {
  "Brand": "ARAL",
  "Street": "Hochheimer Straße 25",
  "PostCode": 55246,
  "City": "Mainz-Kostheim",
  "Latitude": "50.007.951",
  "Longitude": "08.292.079",
  "Telephone": "0613463241",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.007951,8.292079",
  "id": "50.007951,8.292079"
}, {
  "Brand": "ARAL",
  "Street": "Lyoner Straße 70",
  "PostCode": 60528,
  "City": "Frankfurt",
  "Latitude": "50.078.100",
  "Longitude": "08.633.934",
  "Telephone": "0696661885",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.0781,8.633934",
  "id": "50.0781,8.633934"
}, {
  "Brand": "ARAL",
  "Street": "Talstraße 12 / B414",
  "PostCode": 56472,
  "City": "Hof",
  "Latitude": "50.664.441",
  "Longitude": "08.015.791",
  "Telephone": "0266164375",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.664441,8.015791",
  "id": "50.664441,8.015791"
}, {
  "Brand": "ARAL",
  "Street": "Sebastianstraße 158",
  "PostCode": 53474,
  "City": "Bad Neuenahr-Ahrweil",
  "Latitude": "50.545.523",
  "Longitude": "07.112.139",
  "Telephone": "0264135033",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.545523,7.112139",
  "id": "50.545523,7.112139"
}, {
  "Brand": "ARAL",
  "Street": "Trierer Straße 97",
  "PostCode": 54411,
  "City": "Hermeskeil",
  "Latitude": "49.657.723",
  "Longitude": "06.933.154",
  "Telephone": "06503404",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:30",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:30",
  "SundayFrom": "07:00",
  "SundayTo": "22:30",
  "IsOpen24Hours": "Nein",
  "location": "49.657723,6.933154",
  "id": "49.657723,6.933154"
}, {
  "Brand": "ARAL",
  "Street": "Ruwerer Straße 35",
  "PostCode": 54292,
  "City": "Trier",
  "Latitude": "49.780.284",
  "Longitude": "06.702.440",
  "Telephone": "065152397",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.780284,6.70244",
  "id": "49.780284,6.70244"
}, {
  "Brand": "ARAL",
  "Street": "Oberstiftstr 41 A",
  "PostCode": 54338,
  "City": "Schweich",
  "Latitude": "49.826.963",
  "Longitude": "06.753.925",
  "Telephone": "065022225",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "10:00",
  "SundayTo": "17:00",
  "IsOpen24Hours": "Nein",
  "location": "49.826963,6.753925",
  "id": "49.826963,6.753925"
}, {
  "Brand": "ARAL",
  "Street": "Trierer Straße 1",
  "PostCode": 53518,
  "City": "Adenau",
  "Latitude": "50.377.937",
  "Longitude": "06.949.287",
  "Telephone": "02691433",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.377937,6.949287",
  "id": "50.377937,6.949287"
}, {
  "Brand": "ARAL",
  "Street": "Sinziger Straße19",
  "PostCode": 53424,
  "City": "Remagen",
  "Latitude": "50.572.125",
  "Longitude": "07.236.269",
  "Telephone": "0264223321",
  "WeekDayFrom": "07:30",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "19:00",
  "SundayFrom": "09:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "50.572125,7.236269",
  "id": "50.572125,7.236269"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 221",
  "PostCode": 56575,
  "City": "Weißenthurm",
  "Latitude": "50.422.402",
  "Longitude": "07.445.442",
  "Telephone": "026372107",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "05:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.422402,7.445442",
  "id": "50.422402,7.445442"
}, {
  "Brand": "ARAL",
  "Street": "Brohltalstraße 71",
  "PostCode": 56651,
  "City": "Niederzissen",
  "Latitude": "50.460.181",
  "Longitude": "07.223.001",
  "Telephone": "026366272",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "08:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "50.460181,7.223001",
  "id": "50.460181,7.223001"
}, {
  "Brand": "ARAL",
  "Street": "Wiedtalstraße 24",
  "PostCode": 53577,
  "City": "Neustadt (Wied)",
  "Latitude": "50.620.807",
  "Longitude": "07.427.844",
  "Telephone": "0268331350",
  "WeekDayFrom": "08:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "10:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "50.620807,7.427844",
  "id": "50.620807,7.427844"
}, {
  "Brand": "ARAL",
  "Street": "Moselstraße / B 416",
  "PostCode": 56330,
  "City": "Kobern-Gondorf",
  "Latitude": "50.314.255",
  "Longitude": "07.462.393",
  "Telephone": "026074065",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.314255,7.462393",
  "id": "50.314255,7.462393"
}, {
  "Brand": "ARAL",
  "Street": "Kelberger Straße 68",
  "PostCode": 56727,
  "City": "Mayen",
  "Latitude": "50.325.299",
  "Longitude": "07.213.625",
  "Telephone": "026513438",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "06:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.325299,7.213625",
  "id": "50.325299,7.213625"
}, {
  "Brand": "ARAL",
  "Street": "Berggärtenstraße",
  "PostCode": 56564,
  "City": "Neuwied",
  "Latitude": "50.443.075",
  "Longitude": "07.469.469",
  "Telephone": "0263131991",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.443075,7.469469",
  "id": "50.443075,7.469469"
}, {
  "Brand": "ARAL",
  "Street": "A3",
  "PostCode": 53577,
  "City": "Neustadt",
  "Latitude": "50.599.924",
  "Longitude": "07.432.348",
  "Telephone": "026833502",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.599924,7.432348",
  "id": "50.599924,7.432348"
}, {
  "Brand": "ARAL",
  "Street": "An der Bundesstraße 521",
  "PostCode": 63674,
  "City": "Altenstadt",
  "Latitude": "50.284.888",
  "Longitude": "08.940.860",
  "Telephone": "060479858950",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.284888,8.94086",
  "id": "50.284888,8.94086"
}, {
  "Brand": "ARAL",
  "Street": "Friedrich-Ebert-Anlage 7",
  "PostCode": 63450,
  "City": "Hanau",
  "Latitude": "50.129.699",
  "Longitude": "08.921.511",
  "Telephone": "061819698409",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.129699,8.921511",
  "id": "50.129699,8.921511"
}, {
  "Brand": "ARAL",
  "Street": "Rheinstraße 18",
  "PostCode": 55435,
  "City": "Gau-Algesheim",
  "Latitude": "49.964.873",
  "Longitude": "08.017.713",
  "Telephone": "067252340",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "49.964873,8.017713",
  "id": "49.964873,8.017713"
}, {
  "Brand": "ARAL",
  "Street": "Friedberger Landstr. 300",
  "PostCode": 60389,
  "City": "Frankfurt",
  "Latitude": "50.133.384",
  "Longitude": "08.696.017",
  "Telephone": "0699551070",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.133384,8.696017",
  "id": "50.133384,8.696017"
}, {
  "Brand": "ARAL",
  "Street": "A 66 Erbenheim-Süd",
  "PostCode": 65205,
  "City": "Wiesbaden",
  "Latitude": "50.054.256",
  "Longitude": "08.311.488",
  "Telephone": "0611700710",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.054256,8.311488",
  "id": "50.054256,8.311488"
}, {
  "Brand": "ARAL",
  "Street": "A5",
  "PostCode": 35305,
  "City": "Grünberg",
  "Latitude": "50.623.664",
  "Longitude": "08.895.533",
  "Telephone": "0640191370",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.623664,8.895533",
  "id": "50.623664,8.895533"
}, {
  "Brand": "ARAL",
  "Street": "A3",
  "PostCode": 63879,
  "City": "Weibersbrunn",
  "Latitude": "49.897.450",
  "Longitude": "09.392.536",
  "Telephone": "060949889336",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.89745,9.392536",
  "id": "49.89745,9.392536"
}, {
  "Brand": "ARAL",
  "Street": "BAB A 49 -Ostseite",
  "PostCode": 34295,
  "City": "Edermünde",
  "Latitude": "51.223.041",
  "Longitude": "09.420.125",
  "Telephone": "056656454",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "07:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.223041,9.420125",
  "id": "51.223041,9.420125"
}, {
  "Brand": "ARAL",
  "Street": "Königstraße 32",
  "PostCode": 66538,
  "City": "Neunkirchen",
  "Latitude": "49.344.607",
  "Longitude": "07.174.258",
  "Telephone": "06821140910",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.344607,7.174258",
  "id": "49.344607,7.174258"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße",
  "PostCode": 55494,
  "City": "Rheinböllen",
  "Latitude": "49.996.164",
  "Longitude": "07.683.820",
  "Telephone": "067643000",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.996164,7.68382",
  "id": "49.996164,7.68382"
}, {
  "Brand": "ARAL",
  "Street": "Schiffenberger Weg 68",
  "PostCode": 35394,
  "City": "Gießen",
  "Latitude": "50.574.159",
  "Longitude": "08.684.971",
  "Telephone": "064176428",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.574159,8.684971",
  "id": "50.574159,8.684971"
}, {
  "Brand": "ARAL",
  "Street": "Prälat-Caire-Straße 14",
  "PostCode": 67071,
  "City": "Ludwigshafen",
  "Latitude": "49.492.061",
  "Longitude": "08.383.311",
  "Telephone": "0621680551",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.492061,8.383311",
  "id": "49.492061,8.383311"
}, {
  "Brand": "ARAL",
  "Street": "Haagstraße",
  "PostCode": 66679,
  "City": "Losheim am See",
  "Latitude": "49.511.373",
  "Longitude": "06.753.374",
  "Telephone": "0687291044",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.511373,6.753374",
  "id": "49.511373,6.753374"
}, {
  "Brand": "ARAL",
  "Street": "Brunckstraße 100",
  "PostCode": 67063,
  "City": "Ludwigshafen",
  "Latitude": "49.503.806",
  "Longitude": "08.412.600",
  "Telephone": "0621696456",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.503806,8.4126",
  "id": "49.503806,8.4126"
}, {
  "Brand": "ARAL",
  "Street": "Sandstraße 126",
  "PostCode": 57072,
  "City": "Siegen",
  "Latitude": "50.880.257",
  "Longitude": "08.026.544",
  "Telephone": "027124660",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.880257,8.026544",
  "id": "50.880257,8.026544"
}, {
  "Brand": "ARAL",
  "Street": "An der B 27/B292",
  "PostCode": 74821,
  "City": "Mosbach",
  "Latitude": "49.337.732",
  "Longitude": "09.116.638",
  "Telephone": "0626191199",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.337732,9.116638",
  "id": "49.337732,9.116638"
}, {
  "Brand": "ARAL",
  "Street": "Straßburger Straße 34a",
  "PostCode": 77694,
  "City": "Kehl",
  "Latitude": "48.575.546",
  "Longitude": "07.817.470",
  "Telephone": "07851958877",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.575546,7.81747",
  "id": "48.575546,7.81747"
}, {
  "Brand": "ARAL",
  "Street": "Industriestraße 5 a",
  "PostCode": 67346,
  "City": "Speyer",
  "Latitude": "49.309.731",
  "Longitude": "08.444.535",
  "Telephone": "0623261143",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.309731,8.444535",
  "id": "49.309731,8.444535"
}, {
  "Brand": "ARAL",
  "Street": "Mainzer Straße 117",
  "PostCode": 67657,
  "City": "Kaiserslautern",
  "Latitude": "49.455.755",
  "Longitude": "07.795.879",
  "Telephone": "063143688",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.455755,7.795879",
  "id": "49.455755,7.795879"
}, {
  "Brand": "ARAL",
  "Street": "Linkenheimer Landstr. 125",
  "PostCode": 76149,
  "City": "Karlsruhe",
  "Latitude": "49.038.118",
  "Longitude": "08.393.598",
  "Telephone": "07214539429",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.038118,8.393598",
  "id": "49.038118,8.393598"
}, {
  "Brand": "ARAL",
  "Street": "Mainzer Straße 140",
  "PostCode": 67547,
  "City": "Worms",
  "Latitude": "49.654.737",
  "Longitude": "08.359.395",
  "Telephone": "06241490921",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.654737,8.359395",
  "id": "49.654737,8.359395"
}, {
  "Brand": "ARAL",
  "Street": "Saarstraße 58 a",
  "PostCode": 54634,
  "City": "Bitburg",
  "Latitude": "49.955.921",
  "Longitude": "06.525.196",
  "Telephone": "06561940247",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.955921,6.525196",
  "id": "49.955921,6.525196"
}, {
  "Brand": "ARAL",
  "Street": "Neumühler Weg 40",
  "PostCode": 66130,
  "City": "Saarbrücken",
  "Latitude": "49.207.392",
  "Longitude": "07.021.482",
  "Telephone": "0681875544",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.207392,7.021482",
  "id": "49.207392,7.021482"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 72",
  "PostCode": 57334,
  "City": "Bad Laasphe",
  "Latitude": "50.930.106",
  "Longitude": "08.424.937",
  "Telephone": "02752200416",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.930106,8.424937",
  "id": "50.930106,8.424937"
}, {
  "Brand": "ARAL",
  "Street": "Petersberger Straße 101",
  "PostCode": 36100,
  "City": "Petersberg",
  "Latitude": "50.552.548",
  "Longitude": "09.701.944",
  "Telephone": "066165535",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.552548,9.701944",
  "id": "50.552548,9.701944"
}, {
  "Brand": "ARAL",
  "Street": "Rommelsbacher Straße 140",
  "PostCode": 72760,
  "City": "Reutlingen",
  "Latitude": "48.511.714",
  "Longitude": "09.204.224",
  "Telephone": "07121380665",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.511714,9.204224",
  "id": "48.511714,9.204224"
}, {
  "Brand": "ARAL",
  "Street": "Bundesstraße 3",
  "PostCode": 77749,
  "City": "Hohberg",
  "Latitude": "48.415.245",
  "Longitude": "07.886.977",
  "Telephone": "07808911057",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "48.415245,7.886977",
  "id": "48.415245,7.886977"
}, {
  "Brand": "ARAL",
  "Street": "Calmbacher Straße 53",
  "PostCode": 75323,
  "City": "Bad Wildbad",
  "Latitude": "48.758.847",
  "Longitude": "08.551.376",
  "Telephone": "07081380973",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.758847,8.551376",
  "id": "48.758847,8.551376"
}, {
  "Brand": "ARAL",
  "Street": "Alter Postweg 1",
  "PostCode": 74564,
  "City": "Crailsheim",
  "Latitude": "49.138.681",
  "Longitude": "10.062.769",
  "Telephone": "07951294260",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.138681,10.062769",
  "id": "49.138681,10.062769"
}, {
  "Brand": "ARAL",
  "Street": "Saarbrücker Straße 175",
  "PostCode": 66557,
  "City": "Illingen-Uchtelfange",
  "Latitude": "49.383.264",
  "Longitude": "07.011.543",
  "Telephone": "0682543054",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "07:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.383264,7.011543",
  "id": "49.383264,7.011543"
}, {
  "Brand": "ARAL",
  "Street": "Lorscher Straße 53",
  "PostCode": 64646,
  "City": "Heppenheim",
  "Latitude": "49.643.989",
  "Longitude": "08.625.536",
  "Telephone": "062527969160",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.643989,8.625536",
  "id": "49.643989,8.625536"
}, {
  "Brand": "ARAL",
  "Street": "Amperestraße 1",
  "PostCode": 64625,
  "City": "Bensheim",
  "Latitude": "49.672.910",
  "Longitude": "08.597.638",
  "Telephone": "06251581734",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.67291,8.597638",
  "id": "49.67291,8.597638"
}, {
  "Brand": "ARAL",
  "Street": "Schönbornstraße 11",
  "PostCode": 63741,
  "City": "Aschaffenburg",
  "Latitude": "49.987.099",
  "Longitude": "09.160.152",
  "Telephone": "06021410332",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.987099,9.160152",
  "id": "49.987099,9.160152"
}, {
  "Brand": "ARAL",
  "Street": "Mainzer Straße 95",
  "PostCode": 65428,
  "City": "Rüsselsheim",
  "Latitude": "49.993.163",
  "Longitude": "08.387.130",
  "Telephone": "06142910915",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.993163,8.38713",
  "id": "49.993163,8.38713"
}, {
  "Brand": "ARAL",
  "Street": "Autozubringer Süd",
  "PostCode": 74199,
  "City": "Untergruppenbach",
  "Latitude": "49.086.424",
  "Longitude": "09.275.176",
  "Telephone": "0713170810",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.086424,9.275176",
  "id": "49.086424,9.275176"
}, {
  "Brand": "ARAL",
  "Street": "Elisabeth-Selbert-Straße",
  "PostCode": 63128,
  "City": "Dietzenbach",
  "Latitude": "50.013.430",
  "Longitude": "08.794.110",
  "Telephone": "060748511300",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.01343,8.79411",
  "id": "50.01343,8.79411"
}, {
  "Brand": "ARAL",
  "Street": "Konrad-Adenauer-Straße 14",
  "PostCode": 55218,
  "City": "Ingelheim",
  "Latitude": "49.979.070",
  "Longitude": "08.054.020",
  "Telephone": "06132431937",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.97907,8.05402",
  "id": "49.97907,8.05402"
}, {
  "Brand": "ARAL",
  "Street": "Matthias-Öchsler- Str. 2",
  "PostCode": 91522,
  "City": "Ansbach",
  "Latitude": "49.299.054",
  "Longitude": "10.586.178",
  "Telephone": "09819775633",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.299054,10.586178",
  "id": "49.299054,10.586178"
}, {
  "Brand": "ARAL",
  "Street": "Schwabenheimer Weg 135",
  "PostCode": 55543,
  "City": "Bad Kreuznach",
  "Latitude": "49.851.806",
  "Longitude": "07.892.767",
  "Telephone": "06718965105",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.851806,7.892767",
  "id": "49.851806,7.892767"
}, {
  "Brand": "ARAL",
  "Street": "Trierer Straße 44-46",
  "PostCode": 66265,
  "City": "Heusweiler",
  "Latitude": "49.341.224",
  "Longitude": "06.933.607",
  "Telephone": "06806609512",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.341224,6.933607",
  "id": "49.341224,6.933607"
}, {
  "Brand": "ARAL",
  "Street": "Rheinallee 207",
  "PostCode": 55120,
  "City": "Mainz",
  "Latitude": "50.027.154",
  "Longitude": "08.222.996",
  "Telephone": "06131687011",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.027154,8.222996",
  "id": "50.027154,8.222996"
}, {
  "Brand": "ARAL",
  "Street": "Mainfrankenpark 24",
  "PostCode": 97337,
  "City": "Dettelbach",
  "Latitude": "49.778.483",
  "Longitude": "10.066.275",
  "Telephone": "09302932100",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.778483,10.066275",
  "id": "49.778483,10.066275"
}, {
  "Brand": "ARAL",
  "Street": "Mammolshainer Weg",
  "PostCode": 61462,
  "City": "Königstein",
  "Latitude": "50.179.242",
  "Longitude": "08.475.964",
  "Telephone": "06174209242",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.179242,8.475964",
  "id": "50.179242,8.475964"
}, {
  "Brand": "ARAL",
  "Street": "Straßburgstraße 1",
  "PostCode": 97424,
  "City": "Schweinfurt",
  "Latitude": "50.021.655",
  "Longitude": "10.216.618",
  "Telephone": "09721608526",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.021655,10.216618",
  "id": "50.021655,10.216618"
}, {
  "Brand": "ARAL",
  "Street": "Mannheimer Straße 1",
  "PostCode": 68766,
  "City": "Hockenheim",
  "Latitude": "49.330.458",
  "Longitude": "08.536.052",
  "Telephone": "06205289123",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.330458,8.536052",
  "id": "49.330458,8.536052"
}, {
  "Brand": "ARAL",
  "Street": "Hockenheimer Landstraße 4",
  "PostCode": 68723,
  "City": "Schwetzingen",
  "Latitude": "49.377.107",
  "Longitude": "08.560.663",
  "Telephone": "06202128091",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.377107,8.560663",
  "id": "49.377107,8.560663"
}, {
  "Brand": "ARAL",
  "Street": "Fackenburger Allee 20",
  "PostCode": 23554,
  "City": "Lübeck",
  "Latitude": "53.870.460",
  "Longitude": "10.670.016",
  "Telephone": "045142183",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.87046,10.670016",
  "id": "53.87046,10.670016"
}, {
  "Brand": "ARAL",
  "Street": "Lindenstraße 24",
  "PostCode": 25524,
  "City": "Itzehoe",
  "Latitude": "53.929.192",
  "Longitude": "09.509.999",
  "Telephone": "048213114",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.929192,9.509999",
  "id": "53.929192,9.509999"
}, {
  "Brand": "ARAL",
  "Street": "Maintaler Straße 20",
  "PostCode": 63452,
  "City": "Hanau",
  "Latitude": "50.147.583",
  "Longitude": "08.901.271",
  "Telephone": "061819839891",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.147583,8.901271",
  "id": "50.147583,8.901271"
}, {
  "Brand": "ARAL",
  "Street": "Raun 112",
  "PostCode": 63667,
  "City": "Nidda",
  "Latitude": "50.408.580",
  "Longitude": "09.013.224",
  "Telephone": "060432456",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "50.40858,9.013224",
  "id": "50.40858,9.013224"
}, {
  "Brand": "ARAL",
  "Street": "Seestraße 34",
  "PostCode": 61200,
  "City": "Wölfersheim",
  "Latitude": "50.402.727",
  "Longitude": "08.820.052",
  "Telephone": "060365204",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.402727,8.820052",
  "id": "50.402727,8.820052"
}, {
  "Brand": "ARAL",
  "Street": "Friedberger Straße 89",
  "PostCode": 61118,
  "City": "Bad Vilbel",
  "Latitude": "50.190.224",
  "Longitude": "08.742.365",
  "Telephone": "06101307498",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.190224,8.742365",
  "id": "50.190224,8.742365"
}, {
  "Brand": "ARAL",
  "Street": "Kleine Brückenstraße 34",
  "PostCode": 61352,
  "City": "Bad Homburg",
  "Latitude": "50.225.309",
  "Longitude": "08.677.370",
  "Telephone": "06172185476",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.225309,8.67737",
  "id": "50.225309,8.67737"
}, {
  "Brand": "ARAL",
  "Street": "A5",
  "PostCode": 64625,
  "City": "Bensheim",
  "Latitude": "49.689.280",
  "Longitude": "08.603.341",
  "Telephone": "0625165100",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.68928,8.603341",
  "id": "49.68928,8.603341"
}, {
  "Brand": "ARAL",
  "Street": "Kieler Straße 285",
  "PostCode": 22525,
  "City": "Hamburg",
  "Latitude": "53.583.811",
  "Longitude": "09.933.157",
  "Telephone": "040545127",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.583811,9.933157",
  "id": "53.583811,9.933157"
}, {
  "Brand": "ARAL",
  "Street": "Rendsburger Straße 48-52",
  "PostCode": 24534,
  "City": "Neumünster",
  "Latitude": "54.082.141",
  "Longitude": "09.979.484",
  "Telephone": "0432112622",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.082141,9.979484",
  "id": "54.082141,9.979484"
}, {
  "Brand": "ARAL",
  "Street": "Stresemannstraße 329",
  "PostCode": 22761,
  "City": "Hamburg",
  "Latitude": "53.563.814",
  "Longitude": "09.925.584",
  "Telephone": "040857265",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.563814,9.925584",
  "id": "53.563814,9.925584"
}, {
  "Brand": "ARAL",
  "Street": "Rissener Straße 89",
  "PostCode": 22880,
  "City": "Wedel",
  "Latitude": "53.583.641",
  "Longitude": "09.720.515",
  "Telephone": "041032519",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.583641,9.720515",
  "id": "53.583641,9.720515"
}, {
  "Brand": "ARAL",
  "Street": "Pinneberger Straße 1-3",
  "PostCode": 22457,
  "City": "Hamburg",
  "Latitude": "53.632.537",
  "Longitude": "09.910.657",
  "Telephone": "0405502787",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.632537,9.910657",
  "id": "53.632537,9.910657"
}, {
  "Brand": "ARAL",
  "Street": "Fockbeker Chaussee 4-6",
  "PostCode": 24768,
  "City": "Rendsburg",
  "Latitude": "54.307.929",
  "Longitude": "09.648.026",
  "Telephone": "0433171246",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.307929,9.648026",
  "id": "54.307929,9.648026"
}, {
  "Brand": "ARAL",
  "Street": "Gneversdorfer Weg 12-14",
  "PostCode": 23570,
  "City": "Lübeck",
  "Latitude": "53.959.333",
  "Longitude": "10.861.492",
  "Telephone": "045025175",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.959333,10.861492",
  "id": "53.959333,10.861492"
}, {
  "Brand": "ARAL",
  "Street": "Peutestraße 2",
  "PostCode": 20539,
  "City": "Hamburg",
  "Latitude": "53.527.635",
  "Longitude": "10.024.657",
  "Telephone": "0407899840",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.527635,10.024657",
  "id": "53.527635,10.024657"
}, {
  "Brand": "ARAL",
  "Street": "An der Bundesstraße 201",
  "PostCode": 24376,
  "City": "Kappeln",
  "Latitude": "54.659.250",
  "Longitude": "09.925.332",
  "Telephone": "04642924788",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "54.65925,9.925332",
  "id": "54.65925,9.925332"
}, {
  "Brand": "ARAL",
  "Street": "Manhagener Allee 47-49",
  "PostCode": 22926,
  "City": "Ahrensburg",
  "Latitude": "53.669.536",
  "Longitude": "10.243.955",
  "Telephone": "0410252343",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.669536,10.243955",
  "id": "53.669536,10.243955"
}, {
  "Brand": "ARAL",
  "Street": "Hermann-Körner-Straße 51",
  "PostCode": 21465,
  "City": "Reinbek",
  "Latitude": "53.522.228",
  "Longitude": "10.244.515",
  "Telephone": "0407279460",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "53.522228,10.244515",
  "id": "53.522228,10.244515"
}, {
  "Brand": "ARAL",
  "Street": "Nordstraße 20",
  "PostCode": 24943,
  "City": "Flensburg",
  "Latitude": "54.797.087",
  "Longitude": "09.476.214",
  "Telephone": "04616740568",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.797087,9.476214",
  "id": "54.797087,9.476214"
}, {
  "Brand": "ARAL",
  "Street": "Bgm.-Steenbock-Str. 39",
  "PostCode": 23701,
  "City": "Eutin",
  "Latitude": "54.128.861",
  "Longitude": "10.624.868",
  "Telephone": "0452178038",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "54.128861,10.624868",
  "id": "54.128861,10.624868"
}, {
  "Brand": "ARAL",
  "Street": "Eiffestraße 506",
  "PostCode": 20537,
  "City": "Hamburg",
  "Latitude": "53.551.589",
  "Longitude": "10.051.430",
  "Telephone": "040213820",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.551589,10.05143",
  "id": "53.551589,10.05143"
}, {
  "Brand": "ARAL",
  "Street": "Osterfeldstraße 30",
  "PostCode": 22529,
  "City": "Hamburg",
  "Latitude": "53.598.394",
  "Longitude": "09.974.470",
  "Telephone": "0405531672",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.598394,9.97447",
  "id": "53.598394,9.97447"
}, {
  "Brand": "ARAL",
  "Street": "Nordschleswiger Straße 91",
  "PostCode": 22049,
  "City": "Hamburg",
  "Latitude": "53.579.851",
  "Longitude": "10.069.837",
  "Telephone": "040687042",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.579851,10.069837",
  "id": "53.579851,10.069837"
}, {
  "Brand": "ARAL",
  "Street": "Winterhuder Weg 25",
  "PostCode": 22085,
  "City": "Hamburg",
  "Latitude": "53.573.575",
  "Longitude": "10.022.581",
  "Telephone": "0402207516",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.573575,10.022581",
  "id": "53.573575,10.022581"
}, {
  "Brand": "ARAL",
  "Street": "Kastanienallee 17-19",
  "PostCode": 21255,
  "City": "Tostedt",
  "Latitude": "53.282.134",
  "Longitude": "09.714.003",
  "Telephone": "04182293396",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.282134,9.714003",
  "id": "53.282134,9.714003"
}, {
  "Brand": "ARAL",
  "Street": "Bargteheider Straße 100",
  "PostCode": 22143,
  "City": "Hamburg",
  "Latitude": "53.604.469",
  "Longitude": "10.147.826",
  "Telephone": "0406773554",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.604469,10.147826",
  "id": "53.604469,10.147826"
}, {
  "Brand": "ARAL",
  "Street": "Hamburger Straße 110",
  "PostCode": 23843,
  "City": "Bad Oldesloe",
  "Latitude": "53.803.071",
  "Longitude": "10.359.054",
  "Telephone": "0453182243",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.803071,10.359054",
  "id": "53.803071,10.359054"
}, {
  "Brand": "ARAL",
  "Street": "Schönberger Landstraße135",
  "PostCode": 24232,
  "City": "Schönkirchen",
  "Latitude": "54.338.912",
  "Longitude": "10.221.949",
  "Telephone": "04348446",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "54.338912,10.221949",
  "id": "54.338912,10.221949"
}, {
  "Brand": "ARAL",
  "Street": "Sievekingsallee/Saling 30",
  "PostCode": 20535,
  "City": "Hamburg",
  "Latitude": "53.561.393",
  "Longitude": "10.048.490",
  "Telephone": "0402502222",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.561393,10.04849",
  "id": "53.561393,10.04849"
}, {
  "Brand": "ARAL",
  "Street": "PoppenbüttelerWeg 126",
  "PostCode": 22399,
  "City": "Hamburg",
  "Latitude": "53.649.732",
  "Longitude": "10.064.419",
  "Telephone": "0405385227",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.649732,10.064419",
  "id": "53.649732,10.064419"
}, {
  "Brand": "ARAL",
  "Street": "Segeberger Straße 42",
  "PostCode": 23866,
  "City": "Nahe",
  "Latitude": "53.795.733",
  "Longitude": "10.141.460",
  "Telephone": "04535407",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.795733,10.14146",
  "id": "53.795733,10.14146"
}, {
  "Brand": "ARAL",
  "Street": "Spaldingstraße 107",
  "PostCode": 20097,
  "City": "Hamburg",
  "Latitude": "53.550.429",
  "Longitude": "10.019.964",
  "Telephone": "040234524",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.550429,10.019964",
  "id": "53.550429,10.019964"
}, {
  "Brand": "ARAL",
  "Street": "Jesteburger Straße 12",
  "PostCode": 21218,
  "City": "Seevetal",
  "Latitude": "53.382.450",
  "Longitude": "09.979.346",
  "Telephone": "041052804",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.38245,9.979346",
  "id": "53.38245,9.979346"
}, {
  "Brand": "ARAL",
  "Street": "Holsteiner Chaussee 55",
  "PostCode": 22523,
  "City": "Hamburg",
  "Latitude": "53.614.712",
  "Longitude": "09.906.061",
  "Telephone": "0405706132",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.614712,9.906061",
  "id": "53.614712,9.906061"
}, {
  "Brand": "ARAL",
  "Street": "Friedensallee 92",
  "PostCode": 25335,
  "City": "Elmshorn",
  "Latitude": "53.763.849",
  "Longitude": "09.662.711",
  "Telephone": "0412182611",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.763849,9.662711",
  "id": "53.763849,9.662711"
}, {
  "Brand": "ARAL",
  "Street": "Apensener Straße 20",
  "PostCode": 21614,
  "City": "Buxtehude",
  "Latitude": "53.466.480",
  "Longitude": "09.676.610",
  "Telephone": "0416184850",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.46648,9.67661",
  "id": "53.46648,9.67661"
}, {
  "Brand": "ARAL",
  "Street": "Ohechaussee 146",
  "PostCode": 22848,
  "City": "Norderstedt",
  "Latitude": "53.671.994",
  "Longitude": "09.983.360",
  "Telephone": "0405234266",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.671994,9.98336",
  "id": "53.671994,9.98336"
}, {
  "Brand": "ARAL",
  "Street": "An der E 47 -Landseite-",
  "PostCode": 23775,
  "City": "Großenbrode",
  "Latitude": "54.382.657",
  "Longitude": "11.093.810",
  "Telephone": "04367206",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "06:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "54.382657,11.09381",
  "id": "54.382657,11.09381"
}, {
  "Brand": "ARAL",
  "Street": "Meldorfer Straße 24",
  "PostCode": 25709,
  "City": "Marne",
  "Latitude": "53.959.975",
  "Longitude": "09.007.651",
  "Telephone": "04851606",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "53.959975,9.007651",
  "id": "53.959975,9.007651"
}, {
  "Brand": "ARAL",
  "Street": "Grodener Chaussee 52",
  "PostCode": 27472,
  "City": "Cuxhaven",
  "Latitude": "53.853.061",
  "Longitude": "08.711.241",
  "Telephone": "0472123442",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.853061,8.711241",
  "id": "53.853061,8.711241"
}, {
  "Brand": "ARAL",
  "Street": "Flensburger Chaussee 44",
  "PostCode": 25813,
  "City": "Husum",
  "Latitude": "54.487.644",
  "Longitude": "09.077.271",
  "Telephone": "0484172690",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.487644,9.077271",
  "id": "54.487644,9.077271"
}, {
  "Brand": "ARAL",
  "Street": "Stadtstraße 31",
  "PostCode": 25348,
  "City": "Glückstadt",
  "Latitude": "53.780.917",
  "Longitude": "09.430.447",
  "Telephone": "041242725",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.780917,9.430447",
  "id": "53.780917,9.430447"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 4",
  "PostCode": 22885,
  "City": "Barsbüttel",
  "Latitude": "53.569.388",
  "Longitude": "10.152.557",
  "Telephone": "0406700104",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.569388,10.152557",
  "id": "53.569388,10.152557"
}, {
  "Brand": "ARAL",
  "Street": "Kollaustraße 200",
  "PostCode": 22453,
  "City": "Hamburg",
  "Latitude": "53.615.552",
  "Longitude": "09.951.808",
  "Telephone": "040585456",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.615552,9.951808",
  "id": "53.615552,9.951808"
}, {
  "Brand": "ARAL",
  "Street": "Bremer Straße 300",
  "PostCode": 21077,
  "City": "Hamburg",
  "Latitude": "53.438.596",
  "Longitude": "09.936.119",
  "Telephone": "0407604241",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.438596,9.936119",
  "id": "53.438596,9.936119"
}, {
  "Brand": "ARAL",
  "Street": "Mengestraße 14",
  "PostCode": 21107,
  "City": "Hamburg",
  "Latitude": "53.500.284",
  "Longitude": "09.994.158",
  "Telephone": "0407534767",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.500284,9.994158",
  "id": "53.500284,9.994158"
}, {
  "Brand": "ARAL",
  "Street": "Schleswiger Damm 173",
  "PostCode": 22457,
  "City": "Hamburg",
  "Latitude": "53.641.563",
  "Longitude": "09.916.875",
  "Telephone": "0405509471",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.641563,9.916875",
  "id": "53.641563,9.916875"
}, {
  "Brand": "ARAL",
  "Street": "Ostfrieslandstraße 80",
  "PostCode": 21129,
  "City": "Hamburg",
  "Latitude": "53.526.580",
  "Longitude": "09.884.868",
  "Telephone": "0407428605",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.52658,9.884868",
  "id": "53.52658,9.884868"
}, {
  "Brand": "ARAL",
  "Street": "Hans-Böckler-Straße 35",
  "PostCode": 27578,
  "City": "Bremerhaven",
  "Latitude": "53.587.704",
  "Longitude": "08.612.600",
  "Telephone": "047160300",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:30",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:30",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.587704,8.6126",
  "id": "53.587704,8.6126"
}, {
  "Brand": "ARAL",
  "Street": "Stresemannstraße 118",
  "PostCode": 27576,
  "City": "Bremerhaven",
  "Latitude": "53.555.202",
  "Longitude": "08.593.656",
  "Telephone": "047153080",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.555202,8.593656",
  "id": "53.555202,8.593656"
}, {
  "Brand": "ARAL",
  "Street": "Bremer Landstraße4",
  "PostCode": 27412,
  "City": "Tarmstedt",
  "Latitude": "53.223.233",
  "Longitude": "09.076.715",
  "Telephone": "0428389110",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "53.223233,9.076715",
  "id": "53.223233,9.076715"
}, {
  "Brand": "ARAL",
  "Street": "Nienburger Straße 1",
  "PostCode": 27283,
  "City": "Verden",
  "Latitude": "52.918.079",
  "Longitude": "09.219.863",
  "Telephone": "042312747",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.918079,9.219863",
  "id": "52.918079,9.219863"
}, {
  "Brand": "ARAL",
  "Street": "Ziegelstraße 46",
  "PostCode": 23795,
  "City": "Bad Segeberg",
  "Latitude": "53.942.005",
  "Longitude": "10.300.040",
  "Telephone": "045512920",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.942005,10.30004",
  "id": "53.942005,10.30004"
}, {
  "Brand": "ARAL",
  "Street": "Theisbrügger Straße 1",
  "PostCode": 21706,
  "City": "Drochtersen",
  "Latitude": "53.715.352",
  "Longitude": "09.372.777",
  "Telephone": "041437463",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.715352,9.372777",
  "id": "53.715352,9.372777"
}, {
  "Brand": "ARAL",
  "Street": "Elmshorner Straße 36",
  "PostCode": 25524,
  "City": "Itzehoe",
  "Latitude": "53.905.809",
  "Longitude": "09.507.933",
  "Telephone": "0482182469",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.905809,9.507933",
  "id": "53.905809,9.507933"
}, {
  "Brand": "ARAL",
  "Street": "Langenhorner Chaussee 53-",
  "PostCode": 22335,
  "City": "Hamburg",
  "Latitude": "53.638.753",
  "Longitude": "10.013.372",
  "Telephone": "0405315155",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.638753,10.013372",
  "id": "53.638753,10.013372"
}, {
  "Brand": "ARAL",
  "Street": "Ulzburger Straße 440",
  "PostCode": 22844,
  "City": "Norderstedt",
  "Latitude": "53.727.633",
  "Longitude": "09.997.779",
  "Telephone": "0405222155",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.727633,9.997779",
  "id": "53.727633,9.997779"
}, {
  "Brand": "ARAL",
  "Street": "Hamburger Straße 30",
  "PostCode": 21481,
  "City": "Lauenburg",
  "Latitude": "53.374.186",
  "Longitude": "10.550.394",
  "Telephone": "041533120",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "53.374186,10.550394",
  "id": "53.374186,10.550394"
}, {
  "Brand": "ARAL",
  "Street": "Ratzeburger Allee 116",
  "PostCode": 23562,
  "City": "Lübeck",
  "Latitude": "53.841.219",
  "Longitude": "10.707.904",
  "Telephone": "0451503133",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.841219,10.707904",
  "id": "53.841219,10.707904"
}, {
  "Brand": "ARAL",
  "Street": "Wasserkrüger Weg 131",
  "PostCode": 23879,
  "City": "Mölln",
  "Latitude": "53.614.967",
  "Longitude": "10.691.139",
  "Telephone": "045423656",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.614967,10.691139",
  "id": "53.614967,10.691139"
}, {
  "Brand": "ARAL",
  "Street": "Hamburger Landstraße 103",
  "PostCode": 24113,
  "City": "Molfsee",
  "Latitude": "54.272.592",
  "Longitude": "10.074.924",
  "Telephone": "0431651536",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.272592,10.074924",
  "id": "54.272592,10.074924"
}, {
  "Brand": "ARAL",
  "Street": "Schweriner Straße 75",
  "PostCode": 23909,
  "City": "Ratzeburg",
  "Latitude": "53.692.910",
  "Longitude": "10.787.740",
  "Telephone": "0454183929",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.69291,10.78774",
  "id": "53.69291,10.78774"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 61",
  "PostCode": 24980,
  "City": "Schafflund",
  "Latitude": "54.760.746",
  "Longitude": "09.176.394",
  "Telephone": "04639326",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "18:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "54.760746,9.176394",
  "id": "54.760746,9.176394"
}, {
  "Brand": "ARAL",
  "Street": "Mittelweg 3",
  "PostCode": 25980,
  "City": "Sylt",
  "Latitude": "54.900.401",
  "Longitude": "08.339.205",
  "Telephone": "046518367790",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "54.900401,8.339205",
  "id": "54.900401,8.339205"
}, {
  "Brand": "ARAL",
  "Street": "Hindenburgstraße 10",
  "PostCode": 27442,
  "City": "Gnarrenburg",
  "Latitude": "53.376.417",
  "Longitude": "09.006.809",
  "Telephone": "04763627155",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.376417,9.006809",
  "id": "53.376417,9.006809"
}, {
  "Brand": "ARAL",
  "Street": "Ringstraße 26",
  "PostCode": 32427,
  "City": "Minden",
  "Latitude": "52.283.131",
  "Longitude": "08.902.097",
  "Telephone": "057121415",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.283131,8.902097",
  "id": "52.283131,8.902097"
}, {
  "Brand": "ARAL",
  "Street": "Georgstraße 48",
  "PostCode": 49809,
  "City": "Lingen",
  "Latitude": "52.522.326",
  "Longitude": "07.334.073",
  "Telephone": "05913800",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.522326,7.334073",
  "id": "52.522326,7.334073"
}, {
  "Brand": "ARAL",
  "Street": "Fröhlkingstraße 6",
  "PostCode": 49584,
  "City": "Fürstenau",
  "Latitude": "52.513.603",
  "Longitude": "07.677.745",
  "Telephone": "059012771",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.513603,7.677745",
  "id": "52.513603,7.677745"
}, {
  "Brand": "ARAL",
  "Street": "Oppelner Straße 1",
  "PostCode": 31167,
  "City": "Bockenem",
  "Latitude": "52.001.907",
  "Longitude": "10.135.185",
  "Telephone": "05067697875",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.001907,10.135185",
  "id": "52.001907,10.135185"
}, {
  "Brand": "ARAL",
  "Street": "Schiffdorfer Chaussee 120",
  "PostCode": 27574,
  "City": "Bremerhaven",
  "Latitude": "53.528.709",
  "Longitude": "08.616.031",
  "Telephone": "047135219",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.528709,8.616031",
  "id": "53.528709,8.616031"
}, {
  "Brand": "ARAL",
  "Street": "Hamburger Straße 47",
  "PostCode": 27283,
  "City": "Verden",
  "Latitude": "52.947.981",
  "Longitude": "09.233.142",
  "Telephone": "0423173264",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.947981,9.233142",
  "id": "52.947981,9.233142"
}, {
  "Brand": "ARAL",
  "Street": "Drangstedter Straße 46",
  "PostCode": 27624,
  "City": "GEESTLAND",
  "Latitude": "53.626.414",
  "Longitude": "08.824.384",
  "Telephone": "047455085",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.626414,8.824384",
  "id": "53.626414,8.824384"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 110",
  "PostCode": 25462,
  "City": "Rellingen",
  "Latitude": "53.654.786",
  "Longitude": "09.823.491",
  "Telephone": "04101204018",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.654786,9.823491",
  "id": "53.654786,9.823491"
}, {
  "Brand": "ARAL",
  "Street": "Stockholmstraße 2",
  "PostCode": 24109,
  "City": "Kiel",
  "Latitude": "54.326.109",
  "Longitude": "10.058.283",
  "Telephone": "0431527585",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.326109,10.058283",
  "id": "54.326109,10.058283"
}, {
  "Brand": "ARAL",
  "Street": "Ritterhuder Straße 55",
  "PostCode": 27711,
  "City": "Osterholz-Scharmbeck",
  "Latitude": "53.214.826",
  "Longitude": "08.778.652",
  "Telephone": "047912772",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.214826,8.778652",
  "id": "53.214826,8.778652"
}, {
  "Brand": "ARAL",
  "Street": "Möllner Straße 85",
  "PostCode": 21493,
  "City": "Schwarzenbek",
  "Latitude": "53.511.530",
  "Longitude": "10.489.115",
  "Telephone": "04151895430",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.51153,10.489115",
  "id": "53.51153,10.489115"
}, {
  "Brand": "ARAL",
  "Street": "A7",
  "PostCode": 24791,
  "City": "Alt Duvenstedt",
  "Latitude": "54.364.191",
  "Longitude": "09.670.657",
  "Telephone": "04338466",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.364191,9.670657",
  "id": "54.364191,9.670657"
}, {
  "Brand": "ARAL",
  "Street": "Pagenstecherstraße 58-60",
  "PostCode": 49090,
  "City": "Osnabrück",
  "Latitude": "52.289.738",
  "Longitude": "08.027.446",
  "Telephone": "054161931",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.289738,8.027446",
  "id": "52.289738,8.027446"
}, {
  "Brand": "ARAL",
  "Street": "Oesterstraße 92",
  "PostCode": 25704,
  "City": "Meldorf",
  "Latitude": "54.088.684",
  "Longitude": "09.096.075",
  "Telephone": "048323211",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "54.088684,9.096075",
  "id": "54.088684,9.096075"
}, {
  "Brand": "ARAL",
  "Street": "Olof-Palme-Allee 2",
  "PostCode": 25541,
  "City": "Brunsbüttel",
  "Latitude": "53.900.422",
  "Longitude": "09.121.024",
  "Telephone": "048527272",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "53.900422,9.121024",
  "id": "53.900422,9.121024"
}, {
  "Brand": "ARAL",
  "Street": "Winsener Str. 25",
  "PostCode": 21220,
  "City": "Seevetal",
  "Latitude": "53.390.645",
  "Longitude": "10.045.243",
  "Telephone": "0410582801",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.390645,10.045243",
  "id": "53.390645,10.045243"
}, {
  "Brand": "ARAL",
  "Street": "Schwedendamm 21",
  "PostCode": 24143,
  "City": "Kiel",
  "Latitude": "54.307.356",
  "Longitude": "10.136.729",
  "Telephone": "043174400",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.307356,10.136729",
  "id": "54.307356,10.136729"
}, {
  "Brand": "ARAL",
  "Street": "Hamburger Straße 123",
  "PostCode": 24558,
  "City": "Henstedt-Ulzburg",
  "Latitude": "53.786.987",
  "Longitude": "09.979.266",
  "Telephone": "0419393626",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.786987,9.979266",
  "id": "53.786987,9.979266"
}, {
  "Brand": "ARAL",
  "Street": "Isenstedter Straße 139",
  "PostCode": 32339,
  "City": "Espelkamp",
  "Latitude": "52.373.930",
  "Longitude": "08.634.729",
  "Telephone": "05772936176",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.37393,8.634729",
  "id": "52.37393,8.634729"
}, {
  "Brand": "ARAL",
  "Street": "BAB 21",
  "PostCode": 23795,
  "City": "Schackendorf",
  "Latitude": "53.960.176",
  "Longitude": "10.260.458",
  "Telephone": "045514698",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.960176,10.260458",
  "id": "53.960176,10.260458"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 62",
  "PostCode": 26954,
  "City": "Nordenham",
  "Latitude": "53.486.812",
  "Longitude": "08.475.246",
  "Telephone": "0473122702",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.486812,8.475246",
  "id": "53.486812,8.475246"
}, {
  "Brand": "ARAL",
  "Street": "Leipziger Straße 8c",
  "PostCode": 38302,
  "City": "Wolfenbüttel",
  "Latitude": "52.163.111",
  "Longitude": "10.552.871",
  "Telephone": "05331907575",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.163111,10.552871",
  "id": "52.163111,10.552871"
}, {
  "Brand": "ARAL",
  "Street": "Rönnebrocksweg 15",
  "PostCode": 27356,
  "City": "Rotenburg",
  "Latitude": "53.116.688",
  "Longitude": "09.395.152",
  "Telephone": "04261971902",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "06:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "53.116688,9.395152",
  "id": "53.116688,9.395152"
}, {
  "Brand": "ARAL",
  "Street": "Münsterstraße 63-67",
  "PostCode": 49377,
  "City": "Vechta",
  "Latitude": "52.718.923",
  "Longitude": "08.287.141",
  "Telephone": "04441921437",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.718923,8.287141",
  "id": "52.718923,8.287141"
}, {
  "Brand": "ARAL",
  "Street": "Altendorfer Tor",
  "PostCode": 37574,
  "City": "Einbeck",
  "Latitude": "51.811.566",
  "Longitude": "09.890.761",
  "Telephone": "0556174228",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.811566,9.890761",
  "id": "51.811566,9.890761"
}, {
  "Brand": "ARAL",
  "Street": "An der Autobahn 1",
  "PostCode": 27404,
  "City": "Gyhum",
  "Latitude": "53.193.417",
  "Longitude": "09.288.884",
  "Telephone": "0428695120",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.193417,9.288884",
  "id": "53.193417,9.288884"
}, {
  "Brand": "ARAL",
  "Street": "Rendsburger Landstr. 205",
  "PostCode": 24113,
  "City": "Kiel",
  "Latitude": "54.300.466",
  "Longitude": "10.092.195",
  "Telephone": "04316409660",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.300466,10.092195",
  "id": "54.300466,10.092195"
}, {
  "Brand": "ARAL",
  "Street": "Detmolder Straße 75",
  "PostCode": 33100,
  "City": "Paderborn",
  "Latitude": "51.732.299",
  "Longitude": "08.764.838",
  "Telephone": "05251527584",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.732299,8.764838",
  "id": "51.732299,8.764838"
}, {
  "Brand": "ARAL",
  "Street": "Braunschw. Heerstraße 79",
  "PostCode": 29227,
  "City": "Celle",
  "Latitude": "52.600.635",
  "Longitude": "10.107.382",
  "Telephone": "05141880723",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.600635,10.107382",
  "id": "52.600635,10.107382"
}, {
  "Brand": "ARAL",
  "Street": "Dieselstraße 1",
  "PostCode": 23738,
  "City": "Lensahn",
  "Latitude": "54.217.590",
  "Longitude": "10.896.773",
  "Telephone": "04363901841",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.21759,10.896773",
  "id": "54.21759,10.896773"
}, {
  "Brand": "ARAL",
  "Street": "Großmannstraße 195-211",
  "PostCode": 20539,
  "City": "Hamburg",
  "Latitude": "53.536.753",
  "Longitude": "10.064.204",
  "Telephone": "04078079995",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.536753,10.064204",
  "id": "53.536753,10.064204"
}, {
  "Brand": "ARAL",
  "Street": "Bruchstraße 35",
  "PostCode": 57462,
  "City": "Olpe",
  "Latitude": "51.024.264",
  "Longitude": "07.844.693",
  "Telephone": "027612212",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.024264,7.844693",
  "id": "51.024264,7.844693"
}, {
  "Brand": "ARAL",
  "Street": "Konrad-Adenauer-Allee 3 a",
  "PostCode": 21614,
  "City": "Buxtehude",
  "Latitude": "53.470.105",
  "Longitude": "09.713.646",
  "Telephone": "04161645637",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.470105,9.713646",
  "id": "53.470105,9.713646"
}, {
  "Brand": "ARAL",
  "Street": "Schimmelmannstraße 97",
  "PostCode": 22043,
  "City": "Hamburg",
  "Latitude": "53.572.234",
  "Longitude": "10.104.974",
  "Telephone": "04065389512",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.572234,10.104974",
  "id": "53.572234,10.104974"
}, {
  "Brand": "ARAL",
  "Street": "Lange Straße 39",
  "PostCode": 38300,
  "City": "Wolfenbüttel",
  "Latitude": "52.156.558",
  "Longitude": "10.539.676",
  "Telephone": "053311235",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.156558,10.539676",
  "id": "52.156558,10.539676"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 1",
  "PostCode": 37589,
  "City": "Echte",
  "Latitude": "51.787.755",
  "Longitude": "10.064.217",
  "Telephone": "05553919618",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.787755,10.064217",
  "id": "51.787755,10.064217"
}, {
  "Brand": "ARAL",
  "Street": "Hamburger Straße 35 b",
  "PostCode": 21339,
  "City": "Lüneburg",
  "Latitude": "53.275.159",
  "Longitude": "10.401.078",
  "Telephone": "04131855291",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.275159,10.401078",
  "id": "53.275159,10.401078"
}, {
  "Brand": "ARAL",
  "Street": "Ludwig-Erhard-Straße 4",
  "PostCode": 29525,
  "City": "Uelzen",
  "Latitude": "52.995.480",
  "Longitude": "10.539.605",
  "Telephone": "05813897450",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.99548,10.539605",
  "id": "52.99548,10.539605"
}, {
  "Brand": "ARAL",
  "Street": "Bramfelder Chaussee 66",
  "PostCode": 22177,
  "City": "Hamburg",
  "Latitude": "53.597.208",
  "Longitude": "10.064.701",
  "Telephone": "04069793273",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.597208,10.064701",
  "id": "53.597208,10.064701"
}, {
  "Brand": "ARAL",
  "Street": "Nettelnburger Landweg 2",
  "PostCode": 21035,
  "City": "Hamburg",
  "Latitude": "53.486.699",
  "Longitude": "10.183.866",
  "Telephone": "04073596311",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.486699,10.183866",
  "id": "53.486699,10.183866"
}, {
  "Brand": "ARAL",
  "Street": "Weserstraße 239",
  "PostCode": 27572,
  "City": "Bremerhaven",
  "Latitude": "53.518.363",
  "Longitude": "08.591.478",
  "Telephone": "04717004265",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.518363,8.591478",
  "id": "53.518363,8.591478"
}, {
  "Brand": "ARAL",
  "Street": "Koppelberg 1",
  "PostCode": 24159,
  "City": "Kiel-Friedrichsort",
  "Latitude": "54.403.429",
  "Longitude": "10.166.216",
  "Telephone": "04313970552",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.403429,10.166216",
  "id": "54.403429,10.166216"
}, {
  "Brand": "ARAL",
  "Street": "Alte Heerstraße 4",
  "PostCode": 31061,
  "City": "Alfeld",
  "Latitude": "51.995.752",
  "Longitude": "09.799.870",
  "Telephone": "05181828326",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.995752,9.79987",
  "id": "51.995752,9.79987"
}, {
  "Brand": "ARAL",
  "Street": "Lise-Meitner-Allee 3",
  "PostCode": 25436,
  "City": "Tornesch",
  "Latitude": "53.718.120",
  "Longitude": "09.759.808",
  "Telephone": "04120909760",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.71812,9.759808",
  "id": "53.71812,9.759808"
}, {
  "Brand": "ARAL",
  "Street": "Paderborner Tor 180/B 7",
  "PostCode": 34414,
  "City": "Warburg",
  "Latitude": "51.494.593",
  "Longitude": "09.126.022",
  "Telephone": "05641743838",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.494593,9.126022",
  "id": "51.494593,9.126022"
}, {
  "Brand": "ARAL",
  "Street": "Rudolf-Diesel-Straße 2",
  "PostCode": 26670,
  "City": "Uplengen-Jübberde",
  "Latitude": "53.262.841",
  "Longitude": "07.756.089",
  "Telephone": "04956990408",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.262841,7.756089",
  "id": "53.262841,7.756089"
}, {
  "Brand": "ARAL",
  "Street": "Max-Planck-Straße 18",
  "PostCode": 21423,
  "City": "Winsen",
  "Latitude": "53.339.323",
  "Longitude": "10.219.539",
  "Telephone": "04171690795",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.339323,10.219539",
  "id": "53.339323,10.219539"
}, {
  "Brand": "ARAL",
  "Street": "Hörster Heide 2",
  "PostCode": 49434,
  "City": "Neuenkirchen",
  "Latitude": "52.491.489",
  "Longitude": "08.078.994",
  "Telephone": "05495952150",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.491489,8.078994",
  "id": "52.491489,8.078994"
}, {
  "Brand": "ARAL",
  "Street": "Holstenstraße 112",
  "PostCode": 25560,
  "City": "Schenefeld",
  "Latitude": "54.039.641",
  "Longitude": "09.480.057",
  "Telephone": "04892859750",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.039641,9.480057",
  "id": "54.039641,9.480057"
}, {
  "Brand": "ARAL",
  "Street": "Osterholzer Heerstraße 119-121",
  "PostCode": 28307,
  "City": "Bremen",
  "Latitude": "53.058.102",
  "Longitude": "08.938.544",
  "Telephone": "04214173193",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.058102,8.938544",
  "id": "53.058102,8.938544"
}, {
  "Brand": "ARAL",
  "Street": "Weser-Ems-Str. 1",
  "PostCode": 28816,
  "City": "Stuhr",
  "Latitude": "53.021.027",
  "Longitude": "08.687.874",
  "Telephone": "042213799",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.021027,8.687874",
  "id": "53.021027,8.687874"
}, {
  "Brand": "ARAL",
  "Street": "Europaallee",
  "PostCode": 28309,
  "City": "Bremen",
  "Latitude": "53.034.740",
  "Longitude": "08.891.315",
  "Telephone": "04214581380",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.03474,8.891315",
  "id": "53.03474,8.891315"
}, {
  "Brand": "ARAL",
  "Street": "Rudolf-Diesel-Straße 2",
  "PostCode": 24558,
  "City": "Henstedt-Ulzburg",
  "Latitude": "53.803.960",
  "Longitude": "09.939.107",
  "Telephone": "04193888252",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.80396,9.939107",
  "id": "53.80396,9.939107"
}, {
  "Brand": "ARAL",
  "Street": "An der E 47 -Seeseite-",
  "PostCode": 23775,
  "City": "Großenbrode",
  "Latitude": "54.382.411",
  "Longitude": "11.093.557",
  "Telephone": "04367207",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.382411,11.093557",
  "id": "54.382411,11.093557"
}, {
  "Brand": "ARAL",
  "Street": "Ernst-Barlach-Straße 1",
  "PostCode": 26389,
  "City": "Wilhelmshaven",
  "Latitude": "53.526.613",
  "Longitude": "08.065.767",
  "Telephone": "0442171688",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.526613,8.065767",
  "id": "53.526613,8.065767"
}, {
  "Brand": "ARAL",
  "Street": "Dieselstraße 2",
  "PostCode": 48499,
  "City": "Salzbergen",
  "Latitude": "52.314.780",
  "Longitude": "07.353.656",
  "Telephone": "05976940750",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "52.31478,7.353656",
  "id": "52.31478,7.353656"
}, {
  "Brand": "ARAL",
  "Street": "Bockhorner Landstraße 63a",
  "PostCode": 23826,
  "City": "Bark O.T. Bockhorn",
  "Latitude": "53.918.409",
  "Longitude": "10.127.657",
  "Telephone": "045586759924",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.918409,10.127657",
  "id": "53.918409,10.127657"
}, {
  "Brand": "ARAL",
  "Street": "Eckernförder Straße 9 b",
  "PostCode": 24376,
  "City": "Kappeln",
  "Latitude": "54.657.239",
  "Longitude": "09.946.128",
  "Telephone": "0464281008",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "54.657239,9.946128",
  "id": "54.657239,9.946128"
}, {
  "Brand": "ARAL",
  "Street": "An der Autobahn Nr. 2",
  "PostCode": 23858,
  "City": "Reinfeld",
  "Latitude": "53.823.576",
  "Longitude": "10.504.098",
  "Telephone": "04533791203",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.823576,10.504098",
  "id": "53.823576,10.504098"
}, {
  "Brand": "ARAL",
  "Street": "Wilhelm-Iwan-Ring 1",
  "PostCode": 21035,
  "City": "Hamburg",
  "Latitude": "53.489.817",
  "Longitude": "10.115.083",
  "Telephone": "04073431926",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.489817,10.115083",
  "id": "53.489817,10.115083"
}, {
  "Brand": "ARAL",
  "Street": "Hamburger Straße 65",
  "PostCode": 25355,
  "City": "Barmstedt",
  "Latitude": "53.784.349",
  "Longitude": "09.788.203",
  "Telephone": "0412385138",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "53.784349,9.788203",
  "id": "53.784349,9.788203"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 1",
  "PostCode": 21709,
  "City": "Himmelpforten",
  "Latitude": "53.610.039",
  "Longitude": "09.313.823",
  "Telephone": "04144210521",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.610039,9.313823",
  "id": "53.610039,9.313823"
}, {
  "Brand": "ARAL",
  "Street": "Sprockhöveler Straße 147",
  "PostCode": 58455,
  "City": "Witten",
  "Latitude": "51.437.722",
  "Longitude": "07.321.280",
  "Telephone": "02302274691",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "05:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "51.437722,7.32128",
  "id": "51.437722,7.32128"
}, {
  "Brand": "ARAL",
  "Street": "Astenbergstraße 2",
  "PostCode": 57319,
  "City": "Bad Berleburg",
  "Latitude": "51.056.654",
  "Longitude": "08.392.903",
  "Telephone": "02751892558",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.056654,8.392903",
  "id": "51.056654,8.392903"
}, {
  "Brand": "ARAL",
  "Street": "Südring 285",
  "PostCode": 45711,
  "City": "Datteln",
  "Latitude": "51.655.755",
  "Longitude": "07.351.915",
  "Telephone": "023632297",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.655755,7.351915",
  "id": "51.655755,7.351915"
}, {
  "Brand": "ARAL",
  "Street": "Im I. Westfeld 35",
  "PostCode": 44388,
  "City": "Dortmund",
  "Latitude": "51.497.046",
  "Longitude": "07.332.839",
  "Telephone": "0231631156",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.497046,7.332839",
  "id": "51.497046,7.332839"
}, {
  "Brand": "ARAL",
  "Street": "Wittener Straße 95",
  "PostCode": 44575,
  "City": "Castrop-Rauxel",
  "Latitude": "51.543.495",
  "Longitude": "07.317.006",
  "Telephone": "0230523098",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "05:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "51.543495,7.317006",
  "id": "51.543495,7.317006"
}, {
  "Brand": "ARAL",
  "Street": "Westfalendamm 166",
  "PostCode": 44141,
  "City": "Dortmund",
  "Latitude": "51.504.057",
  "Longitude": "07.498.477",
  "Telephone": "02319776560",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.504057,7.498477",
  "id": "51.504057,7.498477"
}, {
  "Brand": "ARAL",
  "Street": "Wambeler Hellweg 129",
  "PostCode": 44143,
  "City": "Dortmund",
  "Latitude": "51.520.784",
  "Longitude": "07.523.512",
  "Telephone": "0231597276",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.520784,7.523512",
  "id": "51.520784,7.523512"
}, {
  "Brand": "ARAL",
  "Street": "Marienborner Straße 173",
  "PostCode": 57074,
  "City": "Siegen",
  "Latitude": "50.876.316",
  "Longitude": "08.037.726",
  "Telephone": "027162061",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:45",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.876316,8.037726",
  "id": "50.876316,8.037726"
}, {
  "Brand": "ARAL",
  "Street": "Allendorfer Straße 41",
  "PostCode": 59846,
  "City": "Sundern",
  "Latitude": "51.284.878",
  "Longitude": "07.954.361",
  "Telephone": "02393321",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.284878,7.954361",
  "id": "51.284878,7.954361"
}, {
  "Brand": "ARAL",
  "Street": "Hattinger Straße 451",
  "PostCode": 44795,
  "City": "Bochum",
  "Latitude": "51.443.369",
  "Longitude": "07.194.065",
  "Telephone": "0234471650",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.443369,7.194065",
  "id": "51.443369,7.194065"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Straße 22",
  "PostCode": 58339,
  "City": "Breckerfeld",
  "Latitude": "51.257.300",
  "Longitude": "07.469.240",
  "Telephone": "023389155480",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.2573,7.46924",
  "id": "51.2573,7.46924"
}, {
  "Brand": "ARAL",
  "Street": "Lindemannstraße 65",
  "PostCode": 44137,
  "City": "Dortmund",
  "Latitude": "51.501.486",
  "Longitude": "07.451.744",
  "Telephone": "02319003692",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.501486,7.451744",
  "id": "51.501486,7.451744"
}, {
  "Brand": "ARAL",
  "Street": "Weseler Straße 90",
  "PostCode": 45721,
  "City": "Haltern",
  "Latitude": "51.740.000",
  "Longitude": "07.174.035",
  "Telephone": "023643624",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.74,7.174035",
  "id": "51.74,7.174035"
}, {
  "Brand": "ARAL",
  "Street": "Heedfelder Straße14",
  "PostCode": 58579,
  "City": "Schalksmühle",
  "Latitude": "51.256.003",
  "Longitude": "07.583.629",
  "Telephone": "023516759180",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.256003,7.583629",
  "id": "51.256003,7.583629"
}, {
  "Brand": "ARAL",
  "Street": "Heinrich-Lübke-Straße 66",
  "PostCode": 59759,
  "City": "Arnsberg",
  "Latitude": "51.423.572",
  "Longitude": "07.989.389",
  "Telephone": "0293231576",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.423572,7.989389",
  "id": "51.423572,7.989389"
}, {
  "Brand": "ARAL",
  "Street": "Schaphusstraße 9",
  "PostCode": 44359,
  "City": "Dortmund",
  "Latitude": "51.573.748",
  "Longitude": "07.387.133",
  "Telephone": "0231333191",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.573748,7.387133",
  "id": "51.573748,7.387133"
}, {
  "Brand": "ARAL",
  "Street": "Martener Straße 276",
  "PostCode": 44379,
  "City": "Dortmund",
  "Latitude": "51.508.762",
  "Longitude": "07.388.477",
  "Telephone": "0231613466",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.508762,7.388477",
  "id": "51.508762,7.388477"
}, {
  "Brand": "ARAL",
  "Street": "Bochumer Straße 173",
  "PostCode": 45661,
  "City": "Recklinghausen",
  "Latitude": "51.567.600",
  "Longitude": "07.211.870",
  "Telephone": "0236163663",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.5676,7.21187",
  "id": "51.5676,7.21187"
}, {
  "Brand": "ARAL",
  "Street": "Barmer Straße 44",
  "PostCode": 58332,
  "City": "Schwelm",
  "Latitude": "51.280.605",
  "Longitude": "07.278.696",
  "Telephone": "023364089979",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.280605,7.278696",
  "id": "51.280605,7.278696"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 78",
  "PostCode": 59872,
  "City": "Meschede",
  "Latitude": "51.365.244",
  "Longitude": "08.172.538",
  "Telephone": "0290397120",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "04:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.365244,8.172538",
  "id": "51.365244,8.172538"
}, {
  "Brand": "ARAL",
  "Street": "Eppenhauser Straße 134",
  "PostCode": 58093,
  "City": "Hagen",
  "Latitude": "51.360.960",
  "Longitude": "07.506.292",
  "Telephone": "023317878512",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.36096,7.506292",
  "id": "51.36096,7.506292"
}, {
  "Brand": "ARAL",
  "Street": "Hagener Straße 10",
  "PostCode": 57223,
  "City": "Kreuztal",
  "Latitude": "50.960.927",
  "Longitude": "07.988.881",
  "Telephone": "02732765532",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.960927,7.988881",
  "id": "50.960927,7.988881"
}, {
  "Brand": "ARAL",
  "Street": "Mindener Straße 14",
  "PostCode": 59889,
  "City": "Eslohe",
  "Latitude": "51.250.439",
  "Longitude": "08.202.831",
  "Telephone": "02973571",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "07:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.250439,8.202831",
  "id": "51.250439,8.202831"
}, {
  "Brand": "ARAL",
  "Street": "Talstraße 161",
  "PostCode": 58515,
  "City": "Lüdenscheid",
  "Latitude": "51.205.544",
  "Longitude": "07.582.653",
  "Telephone": "0235171177",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.205544,7.582653",
  "id": "51.205544,7.582653"
}, {
  "Brand": "ARAL",
  "Street": "Wehrscheid 7",
  "PostCode": 57392,
  "City": "Schmallenberg",
  "Latitude": "51.185.118",
  "Longitude": "08.313.642",
  "Telephone": "0297496660",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.185118,8.313642",
  "id": "51.185118,8.313642"
}, {
  "Brand": "ARAL",
  "Street": "Bräukerweg 122 b",
  "PostCode": 58708,
  "City": "Menden",
  "Latitude": "51.437.425",
  "Longitude": "07.761.429",
  "Telephone": "0237360775",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.437425,7.761429",
  "id": "51.437425,7.761429"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 210",
  "PostCode": 59846,
  "City": "Sundern",
  "Latitude": "51.336.417",
  "Longitude": "07.999.383",
  "Telephone": "029339837150",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.336417,7.999383",
  "id": "51.336417,7.999383"
}, {
  "Brand": "ARAL",
  "Street": "Hagener Straße 19",
  "PostCode": 57234,
  "City": "Wilnsdorf",
  "Latitude": "50.817.705",
  "Longitude": "08.106.176",
  "Telephone": "0273987330",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:30",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.817705,8.106176",
  "id": "50.817705,8.106176"
}, {
  "Brand": "ARAL",
  "Street": "Hundemstraße 75",
  "PostCode": 57368,
  "City": "Lennestadt",
  "Latitude": "51.101.167",
  "Longitude": "08.070.988",
  "Telephone": "027236200",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.101167,8.070988",
  "id": "51.101167,8.070988"
}, {
  "Brand": "ARAL",
  "Street": "Altenaer Straße 29",
  "PostCode": 58769,
  "City": "Nachrodt-Wiblingwerde",
  "Latitude": "51.333.890",
  "Longitude": "07.649.726",
  "Telephone": "023523515",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "09:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.33389,7.649726",
  "id": "51.33389,7.649726"
}, {
  "Brand": "ARAL",
  "Street": "Hörder Straße 89",
  "PostCode": 58239,
  "City": "Schwerte",
  "Latitude": "51.452.750",
  "Longitude": "07.560.858",
  "Telephone": "0230412930",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.45275,7.560858",
  "id": "51.45275,7.560858"
}, {
  "Brand": "ARAL",
  "Street": "Kölner Straße 154",
  "PostCode": 58566,
  "City": "Kierspe",
  "Latitude": "51.124.574",
  "Longitude": "07.603.350",
  "Telephone": "023592057",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.124574,7.60335",
  "id": "51.124574,7.60335"
}, {
  "Brand": "ARAL",
  "Street": "Herdecker Bach 37 A",
  "PostCode": 58313,
  "City": "Herdecke",
  "Latitude": "51.415.510",
  "Longitude": "07.432.201",
  "Telephone": "023302878",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.41551,7.432201",
  "id": "51.41551,7.432201"
}, {
  "Brand": "ARAL",
  "Street": "Fröndenberger Straße 291",
  "PostCode": 58708,
  "City": "Menden",
  "Latitude": "51.468.478",
  "Longitude": "07.768.807",
  "Telephone": "02373390839",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "51.468478,7.768807",
  "id": "51.468478,7.768807"
}, {
  "Brand": "ARAL",
  "Street": "Siegstraße 43",
  "PostCode": 57076,
  "City": "Siegen",
  "Latitude": "50.899.910",
  "Longitude": "08.034.391",
  "Telephone": "027173043",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.89991,8.034391",
  "id": "50.89991,8.034391"
}, {
  "Brand": "ARAL",
  "Street": "Eilper Straße 40",
  "PostCode": 58091,
  "City": "Hagen",
  "Latitude": "51.346.934",
  "Longitude": "07.494.860",
  "Telephone": "0233177020",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.346934,7.49486",
  "id": "51.346934,7.49486"
}, {
  "Brand": "ARAL",
  "Street": "Warsteiner Straße 46",
  "PostCode": 59872,
  "City": "Meschede",
  "Latitude": "51.352.973",
  "Longitude": "08.281.412",
  "Telephone": "02912306",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.352973,8.281412",
  "id": "51.352973,8.281412"
}, {
  "Brand": "ARAL",
  "Street": "Möhneufer 2",
  "PostCode": 59755,
  "City": "Arnsberg",
  "Latitude": "51.456.361",
  "Longitude": "07.961.709",
  "Telephone": "0293222223",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.456361,7.961709",
  "id": "51.456361,7.961709"
}, {
  "Brand": "ARAL",
  "Street": "Westenfelder Straße 20",
  "PostCode": 59846,
  "City": "Sundern",
  "Latitude": "51.325.678",
  "Longitude": "08.038.365",
  "Telephone": "02933976818",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:30",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "20:30",
  "SundayFrom": "07:30",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.325678,8.038365",
  "id": "51.325678,8.038365"
}, {
  "Brand": "ARAL",
  "Street": "Koblenzer Straße 82",
  "PostCode": 57072,
  "City": "Siegen",
  "Latitude": "50.870.854",
  "Longitude": "08.014.519",
  "Telephone": "0271331480",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.870854,8.014519",
  "id": "50.870854,8.014519"
}, {
  "Brand": "ARAL",
  "Street": "Brackeler Hellweg 160",
  "PostCode": 44309,
  "City": "Dortmund",
  "Latitude": "51.523.990",
  "Longitude": "07.551.552",
  "Telephone": "0231257429",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.52399,7.551552",
  "id": "51.52399,7.551552"
}, {
  "Brand": "ARAL",
  "Street": "Hagener Straße 156",
  "PostCode": 44225,
  "City": "Dortmund",
  "Latitude": "51.467.394",
  "Longitude": "07.460.269",
  "Telephone": "0231731257",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.467394,7.460269",
  "id": "51.467394,7.460269"
}, {
  "Brand": "ARAL",
  "Street": "Marburger Straße 25",
  "PostCode": 57339,
  "City": "Erndtebrück",
  "Latitude": "50.991.046",
  "Longitude": "08.258.931",
  "Telephone": "027532085",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.991046,8.258931",
  "id": "50.991046,8.258931"
}, {
  "Brand": "ARAL",
  "Street": "Hagener Straße 121",
  "PostCode": 58099,
  "City": "Hagen",
  "Latitude": "51.386.465",
  "Longitude": "07.477.907",
  "Telephone": "0233161999",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.386465,7.477907",
  "id": "51.386465,7.477907"
}, {
  "Brand": "ARAL",
  "Street": "Lichtendorfer Straße 136",
  "PostCode": 58239,
  "City": "Schwerte",
  "Latitude": "51.467.338",
  "Longitude": "07.595.074",
  "Telephone": "0230440366",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.467338,7.595074",
  "id": "51.467338,7.595074"
}, {
  "Brand": "ARAL",
  "Street": "Preußenstraße 49",
  "PostCode": 44532,
  "City": "Lünen",
  "Latitude": "51.592.756",
  "Longitude": "07.548.283",
  "Telephone": "0230641069",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.592756,7.548283",
  "id": "51.592756,7.548283"
}, {
  "Brand": "ARAL",
  "Street": "Alfredstraße 121",
  "PostCode": 45131,
  "City": "Essen",
  "Latitude": "51.432.069",
  "Longitude": "07.001.987",
  "Telephone": "0201772604",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.432069,7.001987",
  "id": "51.432069,7.001987"
}, {
  "Brand": "ARAL",
  "Street": "Dortmunder Straße 20",
  "PostCode": 45665,
  "City": "Recklinghausen",
  "Latitude": "51.612.916",
  "Longitude": "07.204.719",
  "Telephone": "023619378150",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.612916,7.204719",
  "id": "51.612916,7.204719"
}, {
  "Brand": "ARAL",
  "Street": "Hermannstraße 100",
  "PostCode": 45964,
  "City": "Gladbeck",
  "Latitude": "51.574.673",
  "Longitude": "06.982.006",
  "Telephone": "0204325991",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.574673,6.982006",
  "id": "51.574673,6.982006"
}, {
  "Brand": "ARAL",
  "Street": "Steeler Straße 281",
  "PostCode": 45138,
  "City": "Essen",
  "Latitude": "51.447.200",
  "Longitude": "07.045.870",
  "Telephone": "0201287957",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.4472,7.04587",
  "id": "51.4472,7.04587"
}, {
  "Brand": "ARAL",
  "Street": "Holsterhauser Straße 2",
  "PostCode": 45147,
  "City": "Essen",
  "Latitude": "51.446.254",
  "Longitude": "07.000.578",
  "Telephone": "0201777426",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.446254,7.000578",
  "id": "51.446254,7.000578"
}, {
  "Brand": "ARAL",
  "Street": "Heisinger Straße 487",
  "PostCode": 45259,
  "City": "Essen",
  "Latitude": "51.404.454",
  "Longitude": "07.065.455",
  "Telephone": "0201460863",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "10:00",
  "SundayTo": "17:00",
  "IsOpen24Hours": "Nein",
  "location": "51.404454,7.065455",
  "id": "51.404454,7.065455"
}, {
  "Brand": "ARAL",
  "Street": "Kölner Straße 70",
  "PostCode": 41334,
  "City": "Nettetal",
  "Latitude": "51.310.836",
  "Longitude": "06.204.854",
  "Telephone": "021576169",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.310836,6.204854",
  "id": "51.310836,6.204854"
}, {
  "Brand": "ARAL",
  "Street": "Hülser Straße 299",
  "PostCode": 47803,
  "City": "Krefeld",
  "Latitude": "51.347.965",
  "Longitude": "06.543.426",
  "Telephone": "02151753235",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.347965,6.543426",
  "id": "51.347965,6.543426"
}, {
  "Brand": "ARAL",
  "Street": "Klosterstraße 1",
  "PostCode": 41379,
  "City": "Brüggen",
  "Latitude": "51.237.852",
  "Longitude": "06.189.237",
  "Telephone": "021635534",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.237852,6.189237",
  "id": "51.237852,6.189237"
}, {
  "Brand": "ARAL",
  "Street": "Umgehungsstraße 1",
  "PostCode": 47647,
  "City": "Kerken",
  "Latitude": "51.442.620",
  "Longitude": "06.414.314",
  "Telephone": "028337594",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.44262,6.414314",
  "id": "51.44262,6.414314"
}, {
  "Brand": "ARAL",
  "Street": "Krefelder Straße 10",
  "PostCode": 47441,
  "City": "Moers",
  "Latitude": "51.452.067",
  "Longitude": "06.620.324",
  "Telephone": "0284129441",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "07:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.452067,6.620324",
  "id": "51.452067,6.620324"
}, {
  "Brand": "ARAL",
  "Street": "Röntgenstraße 4",
  "PostCode": 45470,
  "City": "Mülheim",
  "Latitude": "51.417.480",
  "Longitude": "06.895.428",
  "Telephone": "0208374546",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.41748,6.895428",
  "id": "51.41748,6.895428"
}, {
  "Brand": "ARAL",
  "Street": "Klever Straße 85",
  "PostCode": 47559,
  "City": "Kranenburg",
  "Latitude": "51.783.402",
  "Longitude": "06.022.668",
  "Telephone": "02826437",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.783402,6.022668",
  "id": "51.783402,6.022668"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 48",
  "PostCode": 41366,
  "City": "Schwalmtal",
  "Latitude": "51.232.708",
  "Longitude": "06.240.123",
  "Telephone": "0216320442",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "18:00",
  "SundayFrom": "09:00",
  "SundayTo": "18:00",
  "IsOpen24Hours": "Nein",
  "location": "51.232708,6.240123",
  "id": "51.232708,6.240123"
}, {
  "Brand": "ARAL",
  "Street": "Schottelstraße55",
  "PostCode": 47877,
  "City": "Willich",
  "Latitude": "51.280.864",
  "Longitude": "06.467.419",
  "Telephone": "0215691870",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.280864,6.467419",
  "id": "51.280864,6.467419"
}, {
  "Brand": "ARAL",
  "Street": "Dorstener Straße  84",
  "PostCode": 44809,
  "City": "Bochum",
  "Latitude": "51.489.437",
  "Longitude": "07.208.033",
  "Telephone": "023495544430",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.489437,7.208033",
  "id": "51.489437,7.208033"
}, {
  "Brand": "ARAL",
  "Street": "Bismarckstraße 280",
  "PostCode": 45889,
  "City": "Gelsenkirchen",
  "Latitude": "51.533.143",
  "Longitude": "07.110.759",
  "Telephone": "020985906",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.533143,7.110759",
  "id": "51.533143,7.110759"
}, {
  "Brand": "ARAL",
  "Street": "Frintroper Straße 66-68",
  "PostCode": 45359,
  "City": "Essen",
  "Latitude": "51.462.675",
  "Longitude": "06.941.557",
  "Telephone": "0201672659",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.462675,6.941557",
  "id": "51.462675,6.941557"
}, {
  "Brand": "ARAL",
  "Street": "Klever Straße 165",
  "PostCode": 47839,
  "City": "Krefeld",
  "Latitude": "51.382.843",
  "Longitude": "06.504.978",
  "Telephone": "02151730295",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.382843,6.504978",
  "id": "51.382843,6.504978"
}, {
  "Brand": "ARAL",
  "Street": "Viersener Straße 142",
  "PostCode": 41751,
  "City": "Viersen",
  "Latitude": "51.254.489",
  "Longitude": "06.347.575",
  "Telephone": "0216242285",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.254489,6.347575",
  "id": "51.254489,6.347575"
}, {
  "Brand": "ARAL",
  "Street": "Wankumer Straße 18",
  "PostCode": 47669,
  "City": "Wachtendonk",
  "Latitude": "51.406.856",
  "Longitude": "06.327.047",
  "Telephone": "028369726510",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.406856,6.327047",
  "id": "51.406856,6.327047"
}, {
  "Brand": "ARAL",
  "Street": "Polsumer Straße 152",
  "PostCode": 45896,
  "City": "Gelsenkirchen",
  "Latitude": "51.597.856",
  "Longitude": "07.051.530",
  "Telephone": "0209630444",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.597856,7.05153",
  "id": "51.597856,7.05153"
}, {
  "Brand": "ARAL",
  "Street": "Wittener Straße 71",
  "PostCode": 58456,
  "City": "Witten",
  "Latitude": "51.418.075",
  "Longitude": "07.273.876",
  "Telephone": "02302760298",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.418075,7.273876",
  "id": "51.418075,7.273876"
}, {
  "Brand": "ARAL",
  "Street": "Im Hammertal 1",
  "PostCode": 58456,
  "City": "Witten",
  "Latitude": "51.405.451",
  "Longitude": "07.252.130",
  "Telephone": "0232430592",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.405451,7.25213",
  "id": "51.405451,7.25213"
}, {
  "Brand": "ARAL",
  "Street": "Weseler Straße 90",
  "PostCode": 47661,
  "City": "Issum",
  "Latitude": "51.540.248",
  "Longitude": "06.437.934",
  "Telephone": "028353864",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.540248,6.437934",
  "id": "51.540248,6.437934"
}, {
  "Brand": "ARAL",
  "Street": "Halterner Straße 17",
  "PostCode": 45657,
  "City": "Recklinghausen",
  "Latitude": "51.619.394",
  "Longitude": "07.197.811",
  "Telephone": "0236126261",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.619394,7.197811",
  "id": "51.619394,7.197811"
}, {
  "Brand": "ARAL",
  "Street": "Horster Straße 121",
  "PostCode": 45897,
  "City": "Gelsenkirchen",
  "Latitude": "51.567.712",
  "Longitude": "07.046.876",
  "Telephone": "0209597722",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "05:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "51.567712,7.046876",
  "id": "51.567712,7.046876"
}, {
  "Brand": "ARAL",
  "Street": "Königsallee 142",
  "PostCode": 44789,
  "City": "Bochum",
  "Latitude": "51.461.155",
  "Longitude": "07.220.608",
  "Telephone": "0234337046",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.461155,7.220608",
  "id": "51.461155,7.220608"
}, {
  "Brand": "ARAL",
  "Street": "Castroper Straße 190",
  "PostCode": 44791,
  "City": "Bochum",
  "Latitude": "51.490.716",
  "Longitude": "07.241.519",
  "Telephone": "0234591182",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.490716,7.241519",
  "id": "51.490716,7.241519"
}, {
  "Brand": "ARAL",
  "Street": "Rellinghauser Straße 312",
  "PostCode": 45136,
  "City": "Essen",
  "Latitude": "51.434.878",
  "Longitude": "07.033.927",
  "Telephone": "0201250191",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.434878,7.033927",
  "id": "51.434878,7.033927"
}, {
  "Brand": "ARAL",
  "Street": "Hölkeskampring 109",
  "PostCode": 44625,
  "City": "Herne",
  "Latitude": "51.533.312",
  "Longitude": "07.234.871",
  "Telephone": "0232340037",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.533312,7.234871",
  "id": "51.533312,7.234871"
}, {
  "Brand": "ARAL",
  "Street": "Alleestraße 79",
  "PostCode": 44793,
  "City": "Bochum",
  "Latitude": "51.479.328",
  "Longitude": "07.204.710",
  "Telephone": "023466401",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.479328,7.20471",
  "id": "51.479328,7.20471"
}, {
  "Brand": "ARAL",
  "Street": "Niederrheinallee 48",
  "PostCode": 47506,
  "City": "Neukirchen-Vluyn",
  "Latitude": "51.446.697",
  "Longitude": "06.576.852",
  "Telephone": "028454465",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.446697,6.576852",
  "id": "51.446697,6.576852"
}, {
  "Brand": "ARAL",
  "Street": "Brechtstraße 12",
  "PostCode": 45739,
  "City": "Oer-Erkenschwick",
  "Latitude": "51.640.579",
  "Longitude": "07.254.754",
  "Telephone": "023681536",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.640579,7.254754",
  "id": "51.640579,7.254754"
}, {
  "Brand": "ARAL",
  "Street": "Krayer Straße 188",
  "PostCode": 45307,
  "City": "Essen",
  "Latitude": "51.462.059",
  "Longitude": "07.078.826",
  "Telephone": "0201590516",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.462059,7.078826",
  "id": "51.462059,7.078826"
}, {
  "Brand": "ARAL",
  "Street": "Bochumer Straße 177",
  "PostCode": 45886,
  "City": "Gelsenkirchen",
  "Latitude": "51.496.242",
  "Longitude": "07.114.437",
  "Telephone": "0209204182",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "09:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.496242,7.114437",
  "id": "51.496242,7.114437"
}, {
  "Brand": "ARAL",
  "Street": "Kemnader Straße328",
  "PostCode": 44797,
  "City": "Bochum",
  "Latitude": "51.428.253",
  "Longitude": "07.243.780",
  "Telephone": "0234791759",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.428253,7.24378",
  "id": "51.428253,7.24378"
}, {
  "Brand": "ARAL",
  "Street": "Brassertstraße 124",
  "PostCode": 45768,
  "City": "Marl",
  "Latitude": "51.663.160",
  "Longitude": "07.080.540",
  "Telephone": "0236566398",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.66316,7.08054",
  "id": "51.66316,7.08054"
}, {
  "Brand": "ARAL",
  "Street": "Ewaldstraße 81",
  "PostCode": 45892,
  "City": "Gelsenkirchen",
  "Latitude": "51.575.117",
  "Longitude": "07.116.441",
  "Telephone": "020975527",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:30",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:30",
  "SundayFrom": "09:00",
  "SundayTo": "21:30",
  "IsOpen24Hours": "Nein",
  "location": "51.575117,7.116441",
  "id": "51.575117,7.116441"
}, {
  "Brand": "ARAL",
  "Street": "Hans-Sachs-Straße 64",
  "PostCode": 46236,
  "City": "Bottrop",
  "Latitude": "51.530.323",
  "Longitude": "06.924.594",
  "Telephone": "020416505",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.530323,6.924594",
  "id": "51.530323,6.924594"
}, {
  "Brand": "ARAL",
  "Street": "Brabanter Straße 85",
  "PostCode": 41751,
  "City": "Viersen",
  "Latitude": "51.260.919",
  "Longitude": "06.334.866",
  "Telephone": "0216251369",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "08:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "51.260919,6.334866",
  "id": "51.260919,6.334866"
}, {
  "Brand": "ARAL",
  "Street": "Friedrich-Ebert-Straße 117",
  "PostCode": 47226,
  "City": "Duisburg",
  "Latitude": "51.404.989",
  "Longitude": "06.711.944",
  "Telephone": "02065313826",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.404989,6.711944",
  "id": "51.404989,6.711944"
}, {
  "Brand": "ARAL",
  "Street": "Triftstraße 118-120",
  "PostCode": 47533,
  "City": "Kleve",
  "Latitude": "51.773.785",
  "Longitude": "06.132.118",
  "Telephone": "0282140960",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.773785,6.132118",
  "id": "51.773785,6.132118"
}, {
  "Brand": "ARAL",
  "Street": "Ostring 201",
  "PostCode": 46238,
  "City": "Bottrop",
  "Latitude": "51.521.896",
  "Longitude": "06.952.606",
  "Telephone": "0204163364",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "09:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.521896,6.952606",
  "id": "51.521896,6.952606"
}, {
  "Brand": "ARAL",
  "Street": "Heidhauser Straße 1",
  "PostCode": 45239,
  "City": "Essen",
  "Latitude": "51.388.102",
  "Longitude": "07.022.600",
  "Telephone": "0201404066",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.388102,7.0226",
  "id": "51.388102,7.0226"
}, {
  "Brand": "ARAL",
  "Street": "Römerstraße 454",
  "PostCode": 47441,
  "City": "Moers",
  "Latitude": "51.443.892",
  "Longitude": "06.661.056",
  "Telephone": "0284151105",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.443892,6.661056",
  "id": "51.443892,6.661056"
}, {
  "Brand": "ARAL",
  "Street": "Holsterhauser Straße 301",
  "PostCode": 44625,
  "City": "Herne",
  "Latitude": "51.533.059",
  "Longitude": "07.214.134",
  "Telephone": "0232354548",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.533059,7.214134",
  "id": "51.533059,7.214134"
}, {
  "Brand": "ARAL",
  "Street": "Mülheimer Straße 278-284",
  "PostCode": 46045,
  "City": "Oberhausen",
  "Latitude": "51.466.988",
  "Longitude": "06.864.604",
  "Telephone": "02088830797",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.466988,6.864604",
  "id": "51.466988,6.864604"
}, {
  "Brand": "ARAL",
  "Street": "Schermbecker Landstr. 34",
  "PostCode": 46569,
  "City": "Hünxe",
  "Latitude": "51.668.154",
  "Longitude": "06.741.804",
  "Telephone": "028582468",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.668154,6.741804",
  "id": "51.668154,6.741804"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Straße 343",
  "PostCode": 46562,
  "City": "Voerde",
  "Latitude": "51.599.175",
  "Longitude": "06.663.943",
  "Telephone": "028556574",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.599175,6.663943",
  "id": "51.599175,6.663943"
}, {
  "Brand": "ARAL",
  "Street": "Spellenerstraße 13",
  "PostCode": 46562,
  "City": "Voerde",
  "Latitude": "51.629.196",
  "Longitude": "06.658.793",
  "Telephone": "028141301",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.629196,6.658793",
  "id": "51.629196,6.658793"
}, {
  "Brand": "ARAL",
  "Street": "Kupferdreher Straße 61",
  "PostCode": 45257,
  "City": "Essen",
  "Latitude": "51.396.836",
  "Longitude": "07.085.488",
  "Telephone": "0201482034",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.396836,7.085488",
  "id": "51.396836,7.085488"
}, {
  "Brand": "ARAL",
  "Street": "Teutoburger Straße 1-3",
  "PostCode": 46145,
  "City": "Oberhausen",
  "Latitude": "51.516.623",
  "Longitude": "06.857.246",
  "Telephone": "0208667060",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.516623,6.857246",
  "id": "51.516623,6.857246"
}, {
  "Brand": "ARAL",
  "Street": "Wüstrathstraße 6",
  "PostCode": 47829,
  "City": "Krefeld",
  "Latitude": "51.353.437",
  "Longitude": "06.642.740",
  "Telephone": "0215146632",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "05:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.353437,6.64274",
  "id": "51.353437,6.64274"
}, {
  "Brand": "ARAL",
  "Street": "Bochumer Straße 4",
  "PostCode": 45549,
  "City": "Sprockhövel",
  "Latitude": "51.366.840",
  "Longitude": "07.252.376",
  "Telephone": "0232474188",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.36684,7.252376",
  "id": "51.36684,7.252376"
}, {
  "Brand": "ARAL",
  "Street": "Talstraße 10",
  "PostCode": 58332,
  "City": "Schwelm",
  "Latitude": "51.292.955",
  "Longitude": "07.286.260",
  "Telephone": "023363645",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.292955,7.28626",
  "id": "51.292955,7.28626"
}, {
  "Brand": "ARAL",
  "Street": "Hüstener Straße 44",
  "PostCode": 59821,
  "City": "Arnsberg",
  "Latitude": "51.410.496",
  "Longitude": "08.054.933",
  "Telephone": "0293177251",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.410496,8.054933",
  "id": "51.410496,8.054933"
}, {
  "Brand": "ARAL",
  "Street": "A45",
  "PostCode": 57258,
  "City": "Freudenberg",
  "Latitude": "50.881.733",
  "Longitude": "07.934.100",
  "Telephone": "02734495780",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.881733,7.9341",
  "id": "50.881733,7.9341"
}, {
  "Brand": "ARAL",
  "Street": "Werdohler Landstraße 4",
  "PostCode": 58511,
  "City": "Lüdenscheid",
  "Latitude": "51.228.590",
  "Longitude": "07.651.420",
  "Telephone": "0235192730",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.22859,7.65142",
  "id": "51.22859,7.65142"
}, {
  "Brand": "ARAL",
  "Street": "A45",
  "PostCode": 58513,
  "City": "Lüdenscheid",
  "Latitude": "51.222.214",
  "Longitude": "07.669.095",
  "Telephone": "023514328972",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.222214,7.669095",
  "id": "51.222214,7.669095"
}, {
  "Brand": "ARAL",
  "Street": "Flughafenstraße 425",
  "PostCode": 44328,
  "City": "Dortmund",
  "Latitude": "51.549.128",
  "Longitude": "07.538.585",
  "Telephone": "0231235201",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.549128,7.538585",
  "id": "51.549128,7.538585"
}, {
  "Brand": "ARAL",
  "Street": "Dortmunder Straße 110",
  "PostCode": 58638,
  "City": "Iserlohn",
  "Latitude": "51.380.569",
  "Longitude": "07.681.890",
  "Telephone": "0237127435",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.380569,7.68189",
  "id": "51.380569,7.68189"
}, {
  "Brand": "ARAL",
  "Street": "Hohenlimburger Straße 191",
  "PostCode": 58119,
  "City": "Hagen",
  "Latitude": "51.355.959",
  "Longitude": "07.556.166",
  "Telephone": "0233445186",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.355959,7.556166",
  "id": "51.355959,7.556166"
}, {
  "Brand": "ARAL",
  "Street": "Brechtener Straße 26",
  "PostCode": 44536,
  "City": "Lünen",
  "Latitude": "51.592.867",
  "Longitude": "07.440.023",
  "Telephone": "0231871659",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.592867,7.440023",
  "id": "51.592867,7.440023"
}, {
  "Brand": "ARAL",
  "Street": "Bebelstraße 69 a",
  "PostCode": 46049,
  "City": "Oberhausen",
  "Latitude": "51.471.525",
  "Longitude": "06.837.610",
  "Telephone": "020825434",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.471525,6.83761",
  "id": "51.471525,6.83761"
}, {
  "Brand": "ARAL",
  "Street": "Horster Straße 370",
  "PostCode": 45968,
  "City": "Gladbeck",
  "Latitude": "51.546.558",
  "Longitude": "07.012.883",
  "Telephone": "0204334859",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.546558,7.012883",
  "id": "51.546558,7.012883"
}, {
  "Brand": "ARAL",
  "Street": "Mülheimer Straße 155",
  "PostCode": 46045,
  "City": "Oberhausen",
  "Latitude": "51.472.761",
  "Longitude": "06.863.527",
  "Telephone": "020821691",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.472761,6.863527",
  "id": "51.472761,6.863527"
}, {
  "Brand": "ARAL",
  "Street": "Krefelder Straße 101",
  "PostCode": 41539,
  "City": "Dormagen",
  "Latitude": "51.108.370",
  "Longitude": "06.825.619",
  "Telephone": "021333310",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.10837,6.825619",
  "id": "51.10837,6.825619"
}, {
  "Brand": "ARAL",
  "Street": "Athener Ring 1",
  "PostCode": 50765,
  "City": "Köln",
  "Latitude": "51.019.199",
  "Longitude": "06.896.581",
  "Telephone": "02217089582",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.019199,6.896581",
  "id": "51.019199,6.896581"
}, {
  "Brand": "ARAL",
  "Street": "Friedrich-Ebert-Str. 113",
  "PostCode": 46236,
  "City": "Bottrop",
  "Latitude": "51.519.913",
  "Longitude": "06.931.138",
  "Telephone": "0204122389",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.519913,6.931138",
  "id": "51.519913,6.931138"
}, {
  "Brand": "ARAL",
  "Street": "Lüdinghauserstr 60",
  "PostCode": 59394,
  "City": "Nordkirchen",
  "Latitude": "51.742.007",
  "Longitude": "07.516.944",
  "Telephone": "0259698557",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:30",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:30",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.742007,7.516944",
  "id": "51.742007,7.516944"
}, {
  "Brand": "ARAL",
  "Street": "Hülser Straße 45",
  "PostCode": 47906,
  "City": "Kempen",
  "Latitude": "51.364.553",
  "Longitude": "06.430.604",
  "Telephone": "021523439",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.364553,6.430604",
  "id": "51.364553,6.430604"
}, {
  "Brand": "ARAL",
  "Street": "Siegesstraße 140",
  "PostCode": 46147,
  "City": "Oberhausen",
  "Latitude": "51.521.149",
  "Longitude": "06.787.809",
  "Telephone": "0208688849",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.521149,6.787809",
  "id": "51.521149,6.787809"
}, {
  "Brand": "ARAL",
  "Street": "Koblenzer Straße 171",
  "PostCode": 53177,
  "City": "Bonn",
  "Latitude": "50.677.611",
  "Longitude": "07.168.500",
  "Telephone": "0228333097",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.677611,7.1685",
  "id": "50.677611,7.1685"
}, {
  "Brand": "ARAL",
  "Street": "Roermonder Straße 25-29",
  "PostCode": 52134,
  "City": "Herzogenrath",
  "Latitude": "50.829.493",
  "Longitude": "06.078.054",
  "Telephone": "0240718113",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.829493,6.078054",
  "id": "50.829493,6.078054"
}, {
  "Brand": "ARAL",
  "Street": "Märkische Straße 29",
  "PostCode": 42281,
  "City": "Wuppertal",
  "Latitude": "51.284.764",
  "Longitude": "07.208.179",
  "Telephone": "0202520277",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.284764,7.208179",
  "id": "51.284764,7.208179"
}, {
  "Brand": "ARAL",
  "Street": "Am Hagenblech 60",
  "PostCode": 59955,
  "City": "Winterberg",
  "Latitude": "51.200.444",
  "Longitude": "08.530.926",
  "Telephone": "0298192600",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.200444,8.530926",
  "id": "51.200444,8.530926"
}, {
  "Brand": "ARAL",
  "Street": "Nordring 178",
  "PostCode": 46325,
  "City": "Borken",
  "Latitude": "51.844.697",
  "Longitude": "06.843.055",
  "Telephone": "0286164950",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.844697,6.843055",
  "id": "51.844697,6.843055"
}, {
  "Brand": "ARAL",
  "Street": "Kölner Straße 316",
  "PostCode": 47807,
  "City": "Krefeld",
  "Latitude": "51.313.477",
  "Longitude": "06.579.583",
  "Telephone": "02151315131",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.313477,6.579583",
  "id": "51.313477,6.579583"
}, {
  "Brand": "ARAL",
  "Street": "Herner Straße 256",
  "PostCode": 44809,
  "City": "Bochum",
  "Latitude": "51.501.622",
  "Longitude": "07.212.700",
  "Telephone": "02349530941",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.501622,7.2127",
  "id": "51.501622,7.2127"
}, {
  "Brand": "ARAL",
  "Street": "Friedrich-Ebert-Straße 14",
  "PostCode": 45659,
  "City": "Recklinghausen",
  "Latitude": "51.602.707",
  "Longitude": "07.185.095",
  "Telephone": "0236126544",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.602707,7.185095",
  "id": "51.602707,7.185095"
}, {
  "Brand": "ARAL",
  "Street": "Hafenstraße 43 A",
  "PostCode": 47119,
  "City": "Duisburg",
  "Latitude": "51.453.208",
  "Longitude": "06.740.333",
  "Telephone": "0203873020",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.453208,6.740333",
  "id": "51.453208,6.740333"
}, {
  "Brand": "ARAL",
  "Street": "Neustraße 19",
  "PostCode": 58256,
  "City": "Ennepetal",
  "Latitude": "51.296.870",
  "Longitude": "07.360.476",
  "Telephone": "0233388816",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.29687,7.360476",
  "id": "51.29687,7.360476"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 2",
  "PostCode": 50169,
  "City": "Kerpen",
  "Latitude": "50.924.250",
  "Longitude": "06.705.449",
  "Telephone": "022731002",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.92425,6.705449",
  "id": "50.92425,6.705449"
}, {
  "Brand": "ARAL",
  "Street": "Frauenberger Straße 168",
  "PostCode": 53879,
  "City": "Euskirchen",
  "Latitude": "50.670.130",
  "Longitude": "06.772.548",
  "Telephone": "02251782560",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.67013,6.772548",
  "id": "50.67013,6.772548"
}, {
  "Brand": "ARAL",
  "Street": "Overweg 33",
  "PostCode": 59494,
  "City": "Soest",
  "Latitude": "51.553.549",
  "Longitude": "08.158.549",
  "Telephone": "02921944064",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.553549,8.158549",
  "id": "51.553549,8.158549"
}, {
  "Brand": "ARAL",
  "Street": "Untergath 210",
  "PostCode": 47805,
  "City": "Krefeld",
  "Latitude": "51.321.978",
  "Longitude": "06.590.365",
  "Telephone": "02151557780",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.321978,6.590365",
  "id": "51.321978,6.590365"
}, {
  "Brand": "ARAL",
  "Street": "Mülheimer Straße 15",
  "PostCode": 53840,
  "City": "Troisdorf",
  "Latitude": "50.819.062",
  "Longitude": "07.142.635",
  "Telephone": "02241809505",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.819062,7.142635",
  "id": "50.819062,7.142635"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Straße 96",
  "PostCode": 44867,
  "City": "Bochum",
  "Latitude": "51.465.261",
  "Longitude": "07.131.056",
  "Telephone": "02327939497",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.465261,7.131056",
  "id": "51.465261,7.131056"
}, {
  "Brand": "ARAL",
  "Street": "Kardinal-Galen-Straße 106",
  "PostCode": 47058,
  "City": "Duisburg",
  "Latitude": "51.437.432",
  "Longitude": "06.776.241",
  "Telephone": "0203331435",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.437432,6.776241",
  "id": "51.437432,6.776241"
}, {
  "Brand": "ARAL",
  "Street": "Neuenkamper Straße 2-4",
  "PostCode": 42855,
  "City": "Remscheid",
  "Latitude": "51.177.040",
  "Longitude": "07.211.503",
  "Telephone": "02191387558",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.17704,7.211503",
  "id": "51.17704,7.211503"
}, {
  "Brand": "ARAL",
  "Street": "Alter Deutzer Postweg 102",
  "PostCode": 51149,
  "City": "Köln",
  "Latitude": "50.918.621",
  "Longitude": "07.049.332",
  "Telephone": "02203371622",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.918621,7.049332",
  "id": "50.918621,7.049332"
}, {
  "Brand": "ARAL",
  "Street": "Castroper Hellweg 111",
  "PostCode": 44805,
  "City": "Bochum",
  "Latitude": "51.501.691",
  "Longitude": "07.258.643",
  "Telephone": "02349508090",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.501691,7.258643",
  "id": "51.501691,7.258643"
}, {
  "Brand": "ARAL",
  "Street": "Bürener Straße 155",
  "PostCode": 59590,
  "City": "Geseke",
  "Latitude": "51.597.761",
  "Longitude": "08.515.720",
  "Telephone": "02942574916",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.597761,8.51572",
  "id": "51.597761,8.51572"
}, {
  "Brand": "ARAL",
  "Street": "Nürnberger Straße 33",
  "PostCode": 40599,
  "City": "Düsseldorf",
  "Latitude": "51.170.425",
  "Longitude": "06.859.408",
  "Telephone": "02119991725",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.170425,6.859408",
  "id": "51.170425,6.859408"
}, {
  "Brand": "ARAL",
  "Street": "Wittgensteiner Straße 8 -",
  "PostCode": 57271,
  "City": "Hilchenbach",
  "Latitude": "50.986.885",
  "Longitude": "08.102.047",
  "Telephone": "027332686",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.986885,8.102047",
  "id": "50.986885,8.102047"
}, {
  "Brand": "ARAL",
  "Street": "Siegener Straße 449",
  "PostCode": 57258,
  "City": "Freudenberg - Linden",
  "Latitude": "50.901.309",
  "Longitude": "07.926.670",
  "Telephone": "027347192",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.901309,7.92667",
  "id": "50.901309,7.92667"
}, {
  "Brand": "ARAL",
  "Street": "Ernestinenstraße 157",
  "PostCode": 45141,
  "City": "Essen",
  "Latitude": "51.467.783",
  "Longitude": "07.045.453",
  "Telephone": "02012018242",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.467783,7.045453",
  "id": "51.467783,7.045453"
}, {
  "Brand": "ARAL",
  "Street": "Oskar-Schindler-Straße 1",
  "PostCode": 50769,
  "City": "Köln",
  "Latitude": "51.032.967",
  "Longitude": "06.919.921",
  "Telephone": "022170995092",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.032967,6.919921",
  "id": "51.032967,6.919921"
}, {
  "Brand": "ARAL",
  "Street": "Roermonder Straße 18",
  "PostCode": 41836,
  "City": "Hückelhoven",
  "Latitude": "51.052.715",
  "Longitude": "06.217.374",
  "Telephone": "02433938733",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.052715,6.217374",
  "id": "51.052715,6.217374"
}, {
  "Brand": "ARAL",
  "Street": "Marburger Straße 61",
  "PostCode": 35083,
  "City": "Wetter",
  "Latitude": "50.900.984",
  "Longitude": "08.736.279",
  "Telephone": "0642392110",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.900984,8.736279",
  "id": "50.900984,8.736279"
}, {
  "Brand": "ARAL",
  "Street": "Widdersdorfer Straße 427-",
  "PostCode": 50933,
  "City": "Köln",
  "Latitude": "50.946.877",
  "Longitude": "06.880.500",
  "Telephone": "02215894077",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.946877,6.8805",
  "id": "50.946877,6.8805"
}, {
  "Brand": "ARAL",
  "Street": "Espenhof 1",
  "PostCode": 49549,
  "City": "Ladbergen",
  "Latitude": "52.146.488",
  "Longitude": "07.736.124",
  "Telephone": "05485831620",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.146488,7.736124",
  "id": "52.146488,7.736124"
}, {
  "Brand": "ARAL",
  "Street": "Balinger Straße 26",
  "PostCode": 78727,
  "City": "Oberndorf",
  "Latitude": "48.300.168",
  "Longitude": "08.611.752",
  "Telephone": "074233558",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.300168,8.611752",
  "id": "48.300168,8.611752"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Straße 78",
  "PostCode": 53757,
  "City": "Sankt Augustin",
  "Latitude": "50.783.231",
  "Longitude": "07.235.983",
  "Telephone": "02241590763",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.783231,7.235983",
  "id": "50.783231,7.235983"
}, {
  "Brand": "ARAL",
  "Street": "Hohenstaufenstraße 5",
  "PostCode": 73033,
  "City": "Göppingen",
  "Latitude": "48.702.837",
  "Longitude": "09.661.701",
  "Telephone": "0716175454",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.702837,9.661701",
  "id": "48.702837,9.661701"
}, {
  "Brand": "ARAL",
  "Street": "Wilhelm-Leuschner-Str. 14",
  "PostCode": 74080,
  "City": "Heilbronn",
  "Latitude": "49.141.443",
  "Longitude": "09.196.302",
  "Telephone": "0713142113",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.141443,9.196302",
  "id": "49.141443,9.196302"
}, {
  "Brand": "ARAL",
  "Street": "Reutlinger Straße 72",
  "PostCode": 72072,
  "City": "Tübingen",
  "Latitude": "48.512.952",
  "Longitude": "09.073.625",
  "Telephone": "0707131941",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.512952,9.073625",
  "id": "48.512952,9.073625"
}, {
  "Brand": "ARAL",
  "Street": "Waiblinger Straße 23-25",
  "PostCode": 70372,
  "City": "Stuttgart",
  "Latitude": "48.804.345",
  "Longitude": "09.220.273",
  "Telephone": "07119561039",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.804345,9.220273",
  "id": "48.804345,9.220273"
}, {
  "Brand": "ARAL",
  "Street": "An der B 14/Sulzbach.-Eck",
  "PostCode": 71560,
  "City": "Sulzbach",
  "Latitude": "49.005.061",
  "Longitude": "09.501.942",
  "Telephone": "071936104",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.005061,9.501942",
  "id": "49.005061,9.501942"
}, {
  "Brand": "ARAL",
  "Street": "Ringstraße 20",
  "PostCode": 71364,
  "City": "Winnenden",
  "Latitude": "48.878.329",
  "Longitude": "09.397.637",
  "Telephone": "07195946149",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.878329,9.397637",
  "id": "48.878329,9.397637"
}, {
  "Brand": "ARAL",
  "Street": "Entenstraße 18",
  "PostCode": 74199,
  "City": "Untergruppenbach",
  "Latitude": "49.086.725",
  "Longitude": "09.275.560",
  "Telephone": "07131976124",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.086725,9.27556",
  "id": "49.086725,9.27556"
}, {
  "Brand": "ARAL",
  "Street": "Neuenstädter Straße 93",
  "PostCode": 74172,
  "City": "Neckarsulm",
  "Latitude": "49.198.907",
  "Longitude": "09.232.369",
  "Telephone": "0713217709",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.198907,9.232369",
  "id": "49.198907,9.232369"
}, {
  "Brand": "ARAL",
  "Street": "Neue Unlinger St. 4",
  "PostCode": 88499,
  "City": "Riedlingen",
  "Latitude": "48.148.290",
  "Longitude": "09.485.131",
  "Telephone": "0737110286",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.14829,9.485131",
  "id": "48.14829,9.485131"
}, {
  "Brand": "ARAL",
  "Street": "Mergentheimer Straße 2/1",
  "PostCode": 74653,
  "City": "Künzelsau",
  "Latitude": "49.281.938",
  "Longitude": "09.691.381",
  "Telephone": "079402221",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.281938,9.691381",
  "id": "49.281938,9.691381"
}, {
  "Brand": "ARAL",
  "Street": "Heilbronner Straße 17",
  "PostCode": 74613,
  "City": "Öhringen",
  "Latitude": "49.202.223",
  "Longitude": "09.495.108",
  "Telephone": "0794134945",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.202223,9.495108",
  "id": "49.202223,9.495108"
}, {
  "Brand": "ARAL",
  "Street": "Stuttgarter Straße 30",
  "PostCode": 72172,
  "City": "Sulz",
  "Latitude": "48.367.181",
  "Longitude": "08.636.595",
  "Telephone": "074549767037",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.367181,8.636595",
  "id": "48.367181,8.636595"
}, {
  "Brand": "ARAL",
  "Street": "Herbertinger Straße 1",
  "PostCode": 88348,
  "City": "Bad Saulgau",
  "Latitude": "48.019.110",
  "Longitude": "09.497.070",
  "Telephone": "07581488636",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:30",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "48.01911,9.49707",
  "id": "48.01911,9.49707"
}, {
  "Brand": "ARAL",
  "Street": "Kochendorfer Straße 2",
  "PostCode": 74196,
  "City": "Neuenstadt",
  "Latitude": "49.236.569",
  "Longitude": "09.324.672",
  "Telephone": "071391382",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "19:00",
  "SundayFrom": "09:00",
  "SundayTo": "17:00",
  "IsOpen24Hours": "Nein",
  "location": "49.236569,9.324672",
  "id": "49.236569,9.324672"
}, {
  "Brand": "ARAL",
  "Street": "Leipheimer Straße 34",
  "PostCode": 89233,
  "City": "Neu-Ulm",
  "Latitude": "48.404.406",
  "Longitude": "10.033.940",
  "Telephone": "0731711295",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "48.404406,10.03394",
  "id": "48.404406,10.03394"
}, {
  "Brand": "ARAL",
  "Street": "Haller Straße 18",
  "PostCode": 74248,
  "City": "Ellhofen",
  "Latitude": "49.148.811",
  "Longitude": "09.321.914",
  "Telephone": "07134916854",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "09:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "49.148811,9.321914",
  "id": "49.148811,9.321914"
}, {
  "Brand": "ARAL",
  "Street": "Crailsheimer Straße 9",
  "PostCode": 74585,
  "City": "Rot am See",
  "Latitude": "49.247.070",
  "Longitude": "10.028.508",
  "Telephone": "079552294",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.24707,10.028508",
  "id": "49.24707,10.028508"
}, {
  "Brand": "ARAL",
  "Street": "Herdweg 52",
  "PostCode": 74523,
  "City": "Schwäbisch Hall",
  "Latitude": "49.093.279",
  "Longitude": "09.838.456",
  "Telephone": "079072277",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:30",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "49.093279,9.838456",
  "id": "49.093279,9.838456"
}, {
  "Brand": "ARAL",
  "Street": "Ludwigsburger Straße 15",
  "PostCode": 74343,
  "City": "Sachsenheim",
  "Latitude": "48.959.629",
  "Longitude": "09.068.952",
  "Telephone": "071476360",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.959629,9.068952",
  "id": "48.959629,9.068952"
}, {
  "Brand": "ARAL",
  "Street": "Leonberger Straße 76",
  "PostCode": 71272,
  "City": "Renningen",
  "Latitude": "48.767.975",
  "Longitude": "08.944.295",
  "Telephone": "07159800881",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.767975,8.944295",
  "id": "48.767975,8.944295"
}, {
  "Brand": "ARAL",
  "Street": "Stuttgarter Straße 75",
  "PostCode": 74348,
  "City": "Lauffen",
  "Latitude": "49.070.317",
  "Longitude": "09.158.054",
  "Telephone": "071336000",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.070317,9.158054",
  "id": "49.070317,9.158054"
}, {
  "Brand": "ARAL",
  "Street": "Gosbertstraße 24",
  "PostCode": 72108,
  "City": "Rottenburg",
  "Latitude": "48.495.669",
  "Longitude": "08.813.009",
  "Telephone": "0745791655",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.495669,8.813009",
  "id": "48.495669,8.813009"
}, {
  "Brand": "ARAL",
  "Street": "Jakob-Schüle-Straße 60",
  "PostCode": 73655,
  "City": "Plüderhausen",
  "Latitude": "48.801.836",
  "Longitude": "09.591.705",
  "Telephone": "071819940325",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.801836,9.591705",
  "id": "48.801836,9.591705"
}, {
  "Brand": "ARAL",
  "Street": "Laizerstraße3",
  "PostCode": 72488,
  "City": "Sigmaringen",
  "Latitude": "48.086.857",
  "Longitude": "09.211.907",
  "Telephone": "075713198",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.086857,9.211907",
  "id": "48.086857,9.211907"
}, {
  "Brand": "ARAL",
  "Street": "Sigmaringer Straße 40",
  "PostCode": 72501,
  "City": "Gammertingen",
  "Latitude": "48.246.159",
  "Longitude": "09.222.872",
  "Telephone": "075749350076",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.246159,9.222872",
  "id": "48.246159,9.222872"
}, {
  "Brand": "ARAL",
  "Street": "Ritterstraße14",
  "PostCode": 73650,
  "City": "Winterbach",
  "Latitude": "48.804.879",
  "Longitude": "09.478.821",
  "Telephone": "0718141154",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "48.804879,9.478821",
  "id": "48.804879,9.478821"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 30",
  "PostCode": 88427,
  "City": "Bad Schussenried",
  "Latitude": "48.000.251",
  "Longitude": "09.660.136",
  "Telephone": "075832413",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.000251,9.660136",
  "id": "48.000251,9.660136"
}, {
  "Brand": "ARAL",
  "Street": "Neckarsulmer Straße 91",
  "PostCode": 74076,
  "City": "Heilbronn",
  "Latitude": "49.156.403",
  "Longitude": "09.224.244",
  "Telephone": "07131953061",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.156403,9.224244",
  "id": "49.156403,9.224244"
}, {
  "Brand": "ARAL",
  "Street": "Ehinger Straße 11",
  "PostCode": 89601,
  "City": "Schelklingen",
  "Latitude": "48.370.829",
  "Longitude": "09.732.629",
  "Telephone": "073942346",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.370829,9.732629",
  "id": "48.370829,9.732629"
}, {
  "Brand": "ARAL",
  "Street": "Schwieberdinger Straße 70",
  "PostCode": 71636,
  "City": "Ludwigsburg",
  "Latitude": "48.890.533",
  "Longitude": "09.173.835",
  "Telephone": "07141462453",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.890533,9.173835",
  "id": "48.890533,9.173835"
}, {
  "Brand": "ARAL",
  "Street": "Siemensstraße 21",
  "PostCode": 71254,
  "City": "Ditzingen",
  "Latitude": "48.822.902",
  "Longitude": "09.069.422",
  "Telephone": "071566437",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.822902,9.069422",
  "id": "48.822902,9.069422"
}, {
  "Brand": "ARAL",
  "Street": "Marbacher Straße 36",
  "PostCode": 74385,
  "City": "Pleidelsheim",
  "Latitude": "48.959.220",
  "Longitude": "09.210.152",
  "Telephone": "07144281777",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.95922,9.210152",
  "id": "48.95922,9.210152"
}, {
  "Brand": "ARAL",
  "Street": "Kirchheimer Straße 108",
  "PostCode": 70619,
  "City": "Stuttgart",
  "Latitude": "48.741.936",
  "Longitude": "09.217.338",
  "Telephone": "07114799609",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.741936,9.217338",
  "id": "48.741936,9.217338"
}, {
  "Brand": "ARAL",
  "Street": "Murgtalstraße 47",
  "PostCode": 72270,
  "City": "Baiersbronn",
  "Latitude": "48.512.495",
  "Longitude": "08.377.171",
  "Telephone": "074422455",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.512495,8.377171",
  "id": "48.512495,8.377171"
}, {
  "Brand": "ARAL",
  "Street": "Ulmer Straße 13",
  "PostCode": 89264,
  "City": "Weißenhorn",
  "Latitude": "48.307.509",
  "Longitude": "10.152.489",
  "Telephone": "073092751",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.307509,10.152489",
  "id": "48.307509,10.152489"
}, {
  "Brand": "ARAL",
  "Street": "Weilimdorfer Straße 25",
  "PostCode": 70839,
  "City": "Gerlingen",
  "Latitude": "48.800.904",
  "Longitude": "09.066.810",
  "Telephone": "0715621349",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.800904,9.06681",
  "id": "48.800904,9.06681"
}, {
  "Brand": "ARAL",
  "Street": "Wiener Straße 39",
  "PostCode": 70469,
  "City": "Stuttgart",
  "Latitude": "48.813.231",
  "Longitude": "09.165.155",
  "Telephone": "07111353230",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.813231,9.165155",
  "id": "48.813231,9.165155"
}, {
  "Brand": "ARAL",
  "Street": "Esslinger Straße 43",
  "PostCode": 73776,
  "City": "Altbach",
  "Latitude": "48.723.648",
  "Longitude": "09.377.717",
  "Telephone": "0715327370",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.723648,9.377717",
  "id": "48.723648,9.377717"
}, {
  "Brand": "ARAL",
  "Street": "Wieslauftalstraße 72",
  "PostCode": 73614,
  "City": "Schorndorf",
  "Latitude": "48.826.994",
  "Longitude": "09.549.957",
  "Telephone": "071815423",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:30",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "17:30",
  "SundayFrom": "08:30",
  "SundayTo": "13:00",
  "IsOpen24Hours": "Nein",
  "location": "48.826994,9.549957",
  "id": "48.826994,9.549957"
}, {
  "Brand": "ARAL",
  "Street": "Schillerstraße 34",
  "PostCode": 73312,
  "City": "Geislingen",
  "Latitude": "48.609.757",
  "Longitude": "09.842.613",
  "Telephone": "0733142173",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.609757,9.842613",
  "id": "48.609757,9.842613"
}, {
  "Brand": "ARAL",
  "Street": "Kemptener Straße 61",
  "PostCode": 89250,
  "City": "Senden",
  "Latitude": "48.320.287",
  "Longitude": "10.049.629",
  "Telephone": "073075217",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.320287,10.049629",
  "id": "48.320287,10.049629"
}, {
  "Brand": "ARAL",
  "Street": "Stuttgarter Straße 67",
  "PostCode": 71384,
  "City": "Weinstadt",
  "Latitude": "48.805.327",
  "Longitude": "09.381.961",
  "Telephone": "0715165446",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.805327,9.381961",
  "id": "48.805327,9.381961"
}, {
  "Brand": "ARAL",
  "Street": "Hohenheimer Straße 49",
  "PostCode": 70794,
  "City": "Filderstadt",
  "Latitude": "48.659.377",
  "Longitude": "09.197.765",
  "Telephone": "07117705710",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.659377,9.197765",
  "id": "48.659377,9.197765"
}, {
  "Brand": "ARAL",
  "Street": "Stuttgarter Straße 36",
  "PostCode": 73614,
  "City": "Schorndorf",
  "Latitude": "48.805.699",
  "Longitude": "09.515.002",
  "Telephone": "07181979888",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.805699,9.515002",
  "id": "48.805699,9.515002"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 51",
  "PostCode": 71706,
  "City": "Markgröningen",
  "Latitude": "48.905.016",
  "Longitude": "09.091.017",
  "Telephone": "071454642",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.905016,9.091017",
  "id": "48.905016,9.091017"
}, {
  "Brand": "ARAL",
  "Street": "Ludwigsburger Straße 20",
  "PostCode": 71696,
  "City": "Möglingen",
  "Latitude": "48.887.779",
  "Longitude": "09.128.691",
  "Telephone": "07141484326",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.887779,9.128691",
  "id": "48.887779,9.128691"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 84",
  "PostCode": 72411,
  "City": "Bodelshausen",
  "Latitude": "48.387.321",
  "Longitude": "08.979.032",
  "Telephone": "074717511",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.387321,8.979032",
  "id": "48.387321,8.979032"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 18",
  "PostCode": 73072,
  "City": "Donzdorf",
  "Latitude": "48.685.977",
  "Longitude": "09.803.848",
  "Telephone": "0716229869",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.685977,9.803848",
  "id": "48.685977,9.803848"
}, {
  "Brand": "ARAL",
  "Street": "Messkircher Straße 2a",
  "PostCode": 72488,
  "City": "Sigmaringen",
  "Latitude": "48.075.634",
  "Longitude": "09.193.497",
  "Telephone": "0757151988",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.075634,9.193497",
  "id": "48.075634,9.193497"
}, {
  "Brand": "ARAL",
  "Street": "Mahdentalstraße 86",
  "PostCode": 71065,
  "City": "Sindelfingen",
  "Latitude": "48.706.791",
  "Longitude": "09.027.986",
  "Telephone": "07031811966",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.706791,9.027986",
  "id": "48.706791,9.027986"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Straße 55",
  "PostCode": 72458,
  "City": "Albstadt",
  "Latitude": "48.205.603",
  "Longitude": "09.037.156",
  "Telephone": "074312697",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.205603,9.037156",
  "id": "48.205603,9.037156"
}, {
  "Brand": "ARAL",
  "Street": "Kiesstraße 4-6",
  "PostCode": 74348,
  "City": "Lauffen",
  "Latitude": "49.077.072",
  "Longitude": "09.152.936",
  "Telephone": "071337261",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "08:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "49.077072,9.152936",
  "id": "49.077072,9.152936"
}, {
  "Brand": "ARAL",
  "Street": "Heilbronner Straße 70",
  "PostCode": 74363,
  "City": "Güglingen",
  "Latitude": "49.066.403",
  "Longitude": "09.011.304",
  "Telephone": "071358420",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "19:30",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "49.066403,9.011304",
  "id": "49.066403,9.011304"
}, {
  "Brand": "ARAL",
  "Street": "Marbacher Straße 21",
  "PostCode": 71729,
  "City": "Erdmannhausen",
  "Latitude": "48.941.889",
  "Longitude": "09.291.132",
  "Telephone": "071446651",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.941889,9.291132",
  "id": "48.941889,9.291132"
}, {
  "Brand": "ARAL",
  "Street": "Lindauer Straße 50",
  "PostCode": 88316,
  "City": "Isny",
  "Latitude": "47.689.600",
  "Longitude": "10.032.856",
  "Telephone": "075628117",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.6896,10.032856",
  "id": "47.6896,10.032856"
}, {
  "Brand": "ARAL",
  "Street": "Stuttgarter Straße43",
  "PostCode": 71083,
  "City": "Herrenberg",
  "Latitude": "48.600.553",
  "Longitude": "08.873.095",
  "Telephone": "0703224366",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.600553,8.873095",
  "id": "48.600553,8.873095"
}, {
  "Brand": "ARAL",
  "Street": "Stuttgarter Straße 97A",
  "PostCode": 73430,
  "City": "Aalen",
  "Latitude": "48.832.023",
  "Longitude": "10.087.991",
  "Telephone": "0736164525",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.832023,10.087991",
  "id": "48.832023,10.087991"
}, {
  "Brand": "ARAL",
  "Street": "Löchgauer Straße 77-79",
  "PostCode": 74321,
  "City": "Bietigheim-Bissingen",
  "Latitude": "48.962.110",
  "Longitude": "09.121.924",
  "Telephone": "07142987935",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.96211,9.121924",
  "id": "48.96211,9.121924"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 159",
  "PostCode": 70771,
  "City": "Leinfelden-Echterdin",
  "Latitude": "48.696.706",
  "Longitude": "09.166.695",
  "Telephone": "0711793779",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.696706,9.166695",
  "id": "48.696706,9.166695"
}, {
  "Brand": "ARAL",
  "Street": "Haller Straße 5",
  "PostCode": 74582,
  "City": "Gerabronn",
  "Latitude": "49.247.807",
  "Longitude": "09.920.046",
  "Telephone": "079525059",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "08:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "49.247807,9.920046",
  "id": "49.247807,9.920046"
}, {
  "Brand": "ARAL",
  "Street": "Appenweierer Straße 27",
  "PostCode": 77704,
  "City": "Oberkirch",
  "Latitude": "48.533.676",
  "Longitude": "08.072.487",
  "Telephone": "078024446",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.533676,8.072487",
  "id": "48.533676,8.072487"
}, {
  "Brand": "ARAL",
  "Street": "Albstraße 82",
  "PostCode": 72764,
  "City": "Reutlingen",
  "Latitude": "48.485.059",
  "Longitude": "09.222.500",
  "Telephone": "07121270312",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.485059,9.2225",
  "id": "48.485059,9.2225"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstr. 156",
  "PostCode": 70563,
  "City": "Stuttgart",
  "Latitude": "48.730.937",
  "Longitude": "09.095.362",
  "Telephone": "0711731748",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.730937,9.095362",
  "id": "48.730937,9.095362"
}, {
  "Brand": "ARAL",
  "Street": "Wilhelm-Kraut-Straße111",
  "PostCode": 72336,
  "City": "Balingen",
  "Latitude": "48.264.203",
  "Longitude": "08.852.485",
  "Telephone": "074334278",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.264203,8.852485",
  "id": "48.264203,8.852485"
}, {
  "Brand": "ARAL",
  "Street": "Ernst-Haller-Straße 62",
  "PostCode": 78647,
  "City": "Trossingen",
  "Latitude": "48.066.772",
  "Longitude": "08.639.078",
  "Telephone": "074256197",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.066772,8.639078",
  "id": "48.066772,8.639078"
}, {
  "Brand": "ARAL",
  "Street": "Nürtinger Straße 49",
  "PostCode": 72649,
  "City": "Wolfschlugen",
  "Latitude": "48.651.158",
  "Longitude": "09.295.594",
  "Telephone": "07022953580",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.651158,9.295594",
  "id": "48.651158,9.295594"
}, {
  "Brand": "ARAL",
  "Street": "Bundesstraße 19A",
  "PostCode": 77652,
  "City": "Offenburg",
  "Latitude": "48.490.497",
  "Longitude": "07.950.969",
  "Telephone": "078125793",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "48.490497,7.950969",
  "id": "48.490497,7.950969"
}, {
  "Brand": "ARAL",
  "Street": "Bestenheider Landstraße 7",
  "PostCode": 97877,
  "City": "Wertheim",
  "Latitude": "49.781.713",
  "Longitude": "09.504.071",
  "Telephone": "093429347282",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.781713,9.504071",
  "id": "49.781713,9.504071"
}, {
  "Brand": "ARAL",
  "Street": "Berghauptener Str. 21",
  "PostCode": 77723,
  "City": "Gengenbach",
  "Latitude": "48.400.053",
  "Longitude": "08.007.424",
  "Telephone": "07803922517",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.400053,8.007424",
  "id": "48.400053,8.007424"
}, {
  "Brand": "ARAL",
  "Street": "Vaihinger Straße 70",
  "PostCode": 70567,
  "City": "Stuttgart",
  "Latitude": "48.727.744",
  "Longitude": "09.142.674",
  "Telephone": "0711719300",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.727744,9.142674",
  "id": "48.727744,9.142674"
}, {
  "Brand": "ARAL",
  "Street": "Holger-Crafoord-Straße 1",
  "PostCode": 72379,
  "City": "Hechingen",
  "Latitude": "48.352.099",
  "Longitude": "08.975.689",
  "Telephone": "074714950",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.352099,8.975689",
  "id": "48.352099,8.975689"
}, {
  "Brand": "ARAL",
  "Street": "Herrenberger Straße 50",
  "PostCode": 71034,
  "City": "Böblingen",
  "Latitude": "48.683.029",
  "Longitude": "09.001.526",
  "Telephone": "07031225353",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.683029,9.001526",
  "id": "48.683029,9.001526"
}, {
  "Brand": "ARAL",
  "Street": "Raiffeisenstraße 1",
  "PostCode": 89079,
  "City": "Ulm",
  "Latitude": "48.358.242",
  "Longitude": "09.970.291",
  "Telephone": "073144488",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.358242,9.970291",
  "id": "48.358242,9.970291"
}, {
  "Brand": "ARAL",
  "Street": "Nördlinger Straße 72",
  "PostCode": 89520,
  "City": "Heidenheim",
  "Latitude": "48.690.636",
  "Longitude": "10.165.861",
  "Telephone": "0732125375",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.690636,10.165861",
  "id": "48.690636,10.165861"
}, {
  "Brand": "ARAL",
  "Street": "Karlsruher Straße 26A",
  "PostCode": 75179,
  "City": "Pforzheim",
  "Latitude": "48.902.054",
  "Longitude": "08.661.044",
  "Telephone": "07231106050",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.902054,8.661044",
  "id": "48.902054,8.661044"
}, {
  "Brand": "ARAL",
  "Street": "Neuenstädter Straße 96",
  "PostCode": 74172,
  "City": "Neckarsulm",
  "Latitude": "49.199.271",
  "Longitude": "09.232.950",
  "Telephone": "07132980222",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.199271,9.23295",
  "id": "49.199271,9.23295"
}, {
  "Brand": "ARAL",
  "Street": "Schwieberdinger Straße 85",
  "PostCode": 71636,
  "City": "Ludwigsburg",
  "Latitude": "48.890.016",
  "Longitude": "09.168.827",
  "Telephone": "07141446250",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.890016,9.168827",
  "id": "48.890016,9.168827"
}, {
  "Brand": "ARAL",
  "Street": "Heidenheimer Straße 116",
  "PostCode": 73447,
  "City": "Oberkochen",
  "Latitude": "48.775.676",
  "Longitude": "10.098.891",
  "Telephone": "07364921880",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:30",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:30",
  "SundayFrom": "08:00",
  "SundayTo": "21:30",
  "IsOpen24Hours": "Nein",
  "location": "48.775676,10.098891",
  "id": "48.775676,10.098891"
}, {
  "Brand": "ARAL",
  "Street": "Rudolf-Diesel-Str. 2.",
  "PostCode": 85235,
  "City": "Odelzhausen",
  "Latitude": "48.307.089",
  "Longitude": "11.203.779",
  "Telephone": "08134555673",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.307089,11.203779",
  "id": "48.307089,11.203779"
}, {
  "Brand": "ARAL",
  "Street": "Jahnstraße 40",
  "PostCode": 88214,
  "City": "Ravensburg",
  "Latitude": "47.775.666",
  "Longitude": "09.603.938",
  "Telephone": "07513529743",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "05:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "47.775666,9.603938",
  "id": "47.775666,9.603938"
}, {
  "Brand": "ARAL",
  "Street": "Nellinger Straße 24",
  "PostCode": 89188,
  "City": "Merklingen",
  "Latitude": "48.516.101",
  "Longitude": "09.759.690",
  "Telephone": "073379233029",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.516101,9.75969",
  "id": "48.516101,9.75969"
}, {
  "Brand": "ARAL",
  "Street": "Robert-Bosch-Straße 9-11",
  "PostCode": 72622,
  "City": "Nürtingen",
  "Latitude": "48.615.639",
  "Longitude": "09.331.526",
  "Telephone": "070229211645",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "48.615639,9.331526",
  "id": "48.615639,9.331526"
}, {
  "Brand": "ARAL",
  "Street": "Marie-Curie-Straße 2",
  "PostCode": 73770,
  "City": "Denkendorf",
  "Latitude": "48.707.259",
  "Longitude": "09.305.527",
  "Telephone": "07113482625",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.707259,9.305527",
  "id": "48.707259,9.305527"
}, {
  "Brand": "ARAL",
  "Street": "Mercedesstraße 51",
  "PostCode": 72108,
  "City": "Rottenburg/Ergenzing",
  "Latitude": "48.505.305",
  "Longitude": "08.826.744",
  "Telephone": "07457931293",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.505305,8.826744",
  "id": "48.505305,8.826744"
}, {
  "Brand": "ARAL",
  "Street": "Ulmer Straße 91",
  "PostCode": 88471,
  "City": "Laupheim",
  "Latitude": "48.237.538",
  "Longitude": "09.882.225",
  "Telephone": "07392168623",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.237538,9.882225",
  "id": "48.237538,9.882225"
}, {
  "Brand": "ARAL",
  "Street": "Werderstraße 105",
  "PostCode": 79379,
  "City": "Müllheim",
  "Latitude": "47.809.064",
  "Longitude": "07.639.596",
  "Telephone": "076313882",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.809064,7.639596",
  "id": "47.809064,7.639596"
}, {
  "Brand": "ARAL",
  "Street": "Zeppelinweg 2",
  "PostCode": 89542,
  "City": "Herbrechtingen",
  "Latitude": "48.627.676",
  "Longitude": "10.187.180",
  "Telephone": "073249833735",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.627676,10.18718",
  "id": "48.627676,10.18718"
}, {
  "Brand": "ARAL",
  "Street": "Besanconallee 30",
  "PostCode": 79111,
  "City": "Freiburg",
  "Latitude": "47.990.662",
  "Longitude": "07.797.461",
  "Telephone": "076145366413",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.990662,7.797461",
  "id": "47.990662,7.797461"
}, {
  "Brand": "ARAL",
  "Street": "An der Tagweide 2",
  "PostCode": 76139,
  "City": "Karlsruhe",
  "Latitude": "49.020.278",
  "Longitude": "08.459.429",
  "Telephone": "0721616522",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "18:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "49.020278,8.459429",
  "id": "49.020278,8.459429"
}, {
  "Brand": "ARAL",
  "Street": "Am Leineufer 52",
  "PostCode": 30419,
  "City": "Hannover",
  "Latitude": "52.418.532",
  "Longitude": "09.635.381",
  "Telephone": "0511794933",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.418532,9.635381",
  "id": "52.418532,9.635381"
}, {
  "Brand": "ARAL",
  "Street": "Hannoversche Straße 41-43",
  "PostCode": 29664,
  "City": "Walsrode",
  "Latitude": "52.854.727",
  "Longitude": "09.596.444",
  "Telephone": "051615971",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.854727,9.596444",
  "id": "52.854727,9.596444"
}, {
  "Brand": "ARAL",
  "Street": "Schwarzer Weg 39",
  "PostCode": 31224,
  "City": "Peine",
  "Latitude": "52.330.412",
  "Longitude": "10.234.947",
  "Telephone": "051716108",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.330412,10.234947",
  "id": "52.330412,10.234947"
}, {
  "Brand": "ARAL",
  "Street": "Düshorner Straße 2 D",
  "PostCode": 29683,
  "City": "Fallingbostel",
  "Latitude": "52.864.689",
  "Longitude": "09.690.716",
  "Telephone": "051622183",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.864689,9.690716",
  "id": "52.864689,9.690716"
}, {
  "Brand": "ARAL",
  "Street": "Messeschnellweg-West",
  "PostCode": 30521,
  "City": "Hannover",
  "Latitude": "52.329.613",
  "Longitude": "09.812.187",
  "Telephone": "0511871830",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.329613,9.812187",
  "id": "52.329613,9.812187"
}, {
  "Brand": "ARAL",
  "Street": "Auf der Bünte 1",
  "PostCode": 31737,
  "City": "Rinteln",
  "Latitude": "52.194.334",
  "Longitude": "09.081.367",
  "Telephone": "057515275",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:30",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:30",
  "IsOpen24Hours": "Nein",
  "location": "52.194334,9.081367",
  "id": "52.194334,9.081367"
}, {
  "Brand": "ARAL",
  "Street": "Vornhäger Straße 35",
  "PostCode": 31655,
  "City": "Stadthagen",
  "Latitude": "52.327.977",
  "Longitude": "09.217.370",
  "Telephone": "057213360",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.327977,9.21737",
  "id": "52.327977,9.21737"
}, {
  "Brand": "ARAL",
  "Street": "Iltener Straße 8",
  "PostCode": 31319,
  "City": "Sehnde",
  "Latitude": "52.317.362",
  "Longitude": "09.959.905",
  "Telephone": "051384746",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.317362,9.959905",
  "id": "52.317362,9.959905"
}, {
  "Brand": "ARAL",
  "Street": "Alte Heerstraße 18",
  "PostCode": 31863,
  "City": "Coppenbrügge",
  "Latitude": "52.115.959",
  "Longitude": "09.555.225",
  "Telephone": "05156780080",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "52.115959,9.555225",
  "id": "52.115959,9.555225"
}, {
  "Brand": "ARAL",
  "Street": "Burgdorfer Straße29",
  "PostCode": 31311,
  "City": "Uetze",
  "Latitude": "52.464.146",
  "Longitude": "10.195.447",
  "Telephone": "051734649064",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "08:30",
  "SaturdayTo": "19:00",
  "SundayFrom": "08:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "52.464146,10.195447",
  "id": "52.464146,10.195447"
}, {
  "Brand": "ARAL",
  "Street": "Robert-Koch-Straße 2",
  "PostCode": 30900,
  "City": "Wedemark",
  "Latitude": "52.577.071",
  "Longitude": "09.728.123",
  "Telephone": "051305845432",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "52.577071,9.728123",
  "id": "52.577071,9.728123"
}, {
  "Brand": "ARAL",
  "Street": "Hann. Heerstraße 44",
  "PostCode": 29227,
  "City": "Celle",
  "Latitude": "52.609.279",
  "Longitude": "10.075.801",
  "Telephone": "0514124511",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.609279,10.075801",
  "id": "52.609279,10.075801"
}, {
  "Brand": "ARAL",
  "Street": "Wunstorfer Straße 64",
  "PostCode": 31535,
  "City": "Neustadt a. Rbge.",
  "Latitude": "52.495.380",
  "Longitude": "09.456.242",
  "Telephone": "0503263993",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.49538,9.456242",
  "id": "52.49538,9.456242"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 25",
  "PostCode": 31079,
  "City": "Sibbesse",
  "Latitude": "52.054.875",
  "Longitude": "09.902.859",
  "Telephone": "05065415",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.054875,9.902859",
  "id": "52.054875,9.902859"
}, {
  "Brand": "ARAL",
  "Street": "Göttinger Chaussee  80",
  "PostCode": 30459,
  "City": "Hannover",
  "Latitude": "52.345.738",
  "Longitude": "09.716.903",
  "Telephone": "0511421873",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.345738,9.716903",
  "id": "52.345738,9.716903"
}, {
  "Brand": "ARAL",
  "Street": "Deisterstraße 13",
  "PostCode": 31785,
  "City": "Hameln",
  "Latitude": "52.104.340",
  "Longitude": "09.365.886",
  "Telephone": "0515141626",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.10434,9.365886",
  "id": "52.10434,9.365886"
}, {
  "Brand": "ARAL",
  "Street": "Wagenzeller Straße 14",
  "PostCode": 30855,
  "City": "Langenhagen",
  "Latitude": "52.474.811",
  "Longitude": "09.734.629",
  "Telephone": "0511778059",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.474811,9.734629",
  "id": "52.474811,9.734629"
}, {
  "Brand": "ARAL",
  "Street": "Welseder Straße 20",
  "PostCode": 31840,
  "City": "Hessisch Oldendorf",
  "Latitude": "52.171.137",
  "Longitude": "09.238.018",
  "Telephone": "051524715",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.171137,9.238018",
  "id": "52.171137,9.238018"
}, {
  "Brand": "ARAL",
  "Street": "Gerhardstraße 54",
  "PostCode": 31241,
  "City": "Ilsede",
  "Latitude": "52.263.190",
  "Longitude": "10.220.637",
  "Telephone": "051728760",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.26319,10.220637",
  "id": "52.26319,10.220637"
}, {
  "Brand": "ARAL",
  "Street": "Gutenbergstraße 1",
  "PostCode": 30966,
  "City": "Hemmingen",
  "Latitude": "52.322.750",
  "Longitude": "09.724.928",
  "Telephone": "0511423980",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.32275,9.724928",
  "id": "52.32275,9.724928"
}, {
  "Brand": "ARAL",
  "Street": "Hamelner Straße23",
  "PostCode": 31855,
  "City": "Aerzen",
  "Latitude": "52.069.667",
  "Longitude": "09.308.468",
  "Telephone": "051547098328",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.069667,9.308468",
  "id": "52.069667,9.308468"
}, {
  "Brand": "ARAL",
  "Street": "Otternhägener Straße 4-6",
  "PostCode": 30826,
  "City": "Garbsen",
  "Latitude": "52.461.799",
  "Longitude": "09.534.244",
  "Telephone": "0513152737",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:55",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:55",
  "SundayFrom": "08:00",
  "SundayTo": "20:55",
  "IsOpen24Hours": "Nein",
  "location": "52.461799,9.534244",
  "id": "52.461799,9.534244"
}, {
  "Brand": "ARAL",
  "Street": "A7",
  "PostCode": 29690,
  "City": "Essel",
  "Latitude": "52.695.823",
  "Longitude": "09.685.461",
  "Telephone": "0507196260",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.695823,9.685461",
  "id": "52.695823,9.685461"
}, {
  "Brand": "ARAL",
  "Street": "Calenberger Allee 1",
  "PostCode": 31020,
  "City": "Salzhemmendorf",
  "Latitude": "52.066.205",
  "Longitude": "09.584.756",
  "Telephone": "051535262",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "52.066205,9.584756",
  "id": "52.066205,9.584756"
}, {
  "Brand": "ARAL",
  "Street": "Bevenroder Straße 24",
  "PostCode": 38108,
  "City": "Braunschweig",
  "Latitude": "52.289.660",
  "Longitude": "10.561.611",
  "Telephone": "0531371472",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.28966,10.561611",
  "id": "52.28966,10.561611"
}, {
  "Brand": "ARAL",
  "Street": "Wolfsburger Straße 41",
  "PostCode": 38448,
  "City": "Wolfsburg",
  "Latitude": "52.438.275",
  "Longitude": "10.830.318",
  "Telephone": "0536373136",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "04:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "05:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.438275,10.830318",
  "id": "52.438275,10.830318"
}, {
  "Brand": "ARAL",
  "Street": "Marientaler Straße 52 b",
  "PostCode": 38350,
  "City": "Helmstedt",
  "Latitude": "52.238.707",
  "Longitude": "11.000.150",
  "Telephone": "0535134766",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.238707,11.00015",
  "id": "52.238707,11.00015"
}, {
  "Brand": "ARAL",
  "Street": "Hannoversche Straße 52",
  "PostCode": 30855,
  "City": "Langenhagen",
  "Latitude": "52.436.544",
  "Longitude": "09.677.426",
  "Telephone": "0511784433",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "52.436544,9.677426",
  "id": "52.436544,9.677426"
}, {
  "Brand": "ARAL",
  "Street": "Fuhrberger Landstraße 39",
  "PostCode": 29225,
  "City": "Celle",
  "Latitude": "52.604.707",
  "Longitude": "10.018.757",
  "Telephone": "0514141463",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.604707,10.018757",
  "id": "52.604707,10.018757"
}, {
  "Brand": "ARAL",
  "Street": "Pyrmonter Straße 32",
  "PostCode": 31789,
  "City": "Hameln",
  "Latitude": "52.098.497",
  "Longitude": "09.350.625",
  "Telephone": "05151980890",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "52.098497,9.350625",
  "id": "52.098497,9.350625"
}, {
  "Brand": "ARAL",
  "Street": "Dorfstraße 7",
  "PostCode": 31303,
  "City": "Burgdorf",
  "Latitude": "52.444.409",
  "Longitude": "09.981.965",
  "Telephone": "051363920",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "52.444409,9.981965",
  "id": "52.444409,9.981965"
}, {
  "Brand": "ARAL",
  "Street": "Messeschnellweg Ost",
  "PostCode": 30539,
  "City": "Hannover",
  "Latitude": "52.322.820",
  "Longitude": "09.814.134",
  "Telephone": "0511861335",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.32282,9.814134",
  "id": "52.32282,9.814134"
}, {
  "Brand": "ARAL",
  "Street": "Auf der Horst 64",
  "PostCode": 30823,
  "City": "Garbsen",
  "Latitude": "52.421.219",
  "Longitude": "09.595.494",
  "Telephone": "0513771234",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.421219,9.595494",
  "id": "52.421219,9.595494"
}, {
  "Brand": "ARAL",
  "Street": "Celler Straße 57",
  "PostCode": 29308,
  "City": "Winsen",
  "Latitude": "52.676.450",
  "Longitude": "09.920.086",
  "Telephone": "051438187",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "52.67645,9.920086",
  "id": "52.67645,9.920086"
}, {
  "Brand": "ARAL",
  "Street": "Lemmier Straße 16 A",
  "PostCode": 30989,
  "City": "Gehrden",
  "Latitude": "52.307.570",
  "Longitude": "09.607.053",
  "Telephone": "051084884",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.30757,9.607053",
  "id": "52.30757,9.607053"
}, {
  "Brand": "ARAL",
  "Street": "Lehrter Str. 86 (B 65 Nor",
  "PostCode": 30559,
  "City": "Hannover",
  "Latitude": "52.358.118",
  "Longitude": "09.861.877",
  "Telephone": "0511527428",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.358118,9.861877",
  "id": "52.358118,9.861877"
}, {
  "Brand": "ARAL",
  "Street": "Hildesheimer Straße 32",
  "PostCode": 38114,
  "City": "Braunschweig",
  "Latitude": "52.271.882",
  "Longitude": "10.496.279",
  "Telephone": "053152826",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.271882,10.496279",
  "id": "52.271882,10.496279"
}, {
  "Brand": "ARAL",
  "Street": "Heinr.-Nordhoff-Str. 117",
  "PostCode": 38440,
  "City": "Wolfsburg",
  "Latitude": "52.427.196",
  "Longitude": "10.771.580",
  "Telephone": "0536113248",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.427196,10.77158",
  "id": "52.427196,10.77158"
}, {
  "Brand": "ARAL",
  "Street": "Wolfenbütteler Straße 51",
  "PostCode": 38124,
  "City": "Braunschweig",
  "Latitude": "52.234.983",
  "Longitude": "10.527.750",
  "Telephone": "0531601278",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.234983,10.52775",
  "id": "52.234983,10.52775"
}, {
  "Brand": "ARAL",
  "Street": "Schwarzer Weg 1",
  "PostCode": 38170,
  "City": "Schöppenstedt",
  "Latitude": "52.151.831",
  "Longitude": "10.768.843",
  "Telephone": "053329685327",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.151831,10.768843",
  "id": "52.151831,10.768843"
}, {
  "Brand": "ARAL",
  "Street": "Braunschweiger Straße 167",
  "PostCode": 38259,
  "City": "Salzgitter",
  "Latitude": "52.053.898",
  "Longitude": "10.380.485",
  "Telephone": "0534131260",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.053898,10.380485",
  "id": "52.053898,10.380485"
}, {
  "Brand": "ARAL",
  "Street": "Wolfshagener Straße 2a",
  "PostCode": 38685,
  "City": "Langelsheim",
  "Latitude": "51.933.447",
  "Longitude": "10.345.752",
  "Telephone": "0532686086",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.933447,10.345752",
  "id": "51.933447,10.345752"
}, {
  "Brand": "ARAL",
  "Street": "Salzdahlumer Straße 188",
  "PostCode": 38126,
  "City": "Braunschweig",
  "Latitude": "52.240.507",
  "Longitude": "10.537.416",
  "Telephone": "053166961",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.240507,10.537416",
  "id": "52.240507,10.537416"
}, {
  "Brand": "ARAL",
  "Street": "Lafferder Straße 7",
  "PostCode": 38268,
  "City": "Lengede",
  "Latitude": "52.206.492",
  "Longitude": "10.303.588",
  "Telephone": "053447666",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:30",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:30",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.206492,10.303588",
  "id": "52.206492,10.303588"
}, {
  "Brand": "ARAL",
  "Street": "Sackring 8",
  "PostCode": 38118,
  "City": "Braunschweig",
  "Latitude": "52.263.996",
  "Longitude": "10.500.852",
  "Telephone": "0531577440",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "52.263996,10.500852",
  "id": "52.263996,10.500852"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Straße 48",
  "PostCode": 38165,
  "City": "Lehre",
  "Latitude": "52.326.917",
  "Longitude": "10.666.069",
  "Telephone": "053081588",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.326917,10.666069",
  "id": "52.326917,10.666069"
}, {
  "Brand": "ARAL",
  "Street": "Adersheimer Straße 25",
  "PostCode": 38304,
  "City": "Wolfenbüttel",
  "Latitude": "52.161.253",
  "Longitude": "10.517.295",
  "Telephone": "053314738",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.161253,10.517295",
  "id": "52.161253,10.517295"
}, {
  "Brand": "ARAL",
  "Street": "Helmstedter Straße 33",
  "PostCode": 38364,
  "City": "Schöningen",
  "Latitude": "52.145.306",
  "Longitude": "10.967.413",
  "Telephone": "0535259024",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.145306,10.967413",
  "id": "52.145306,10.967413"
}, {
  "Brand": "ARAL",
  "Street": "Vor dem Dorfe 30",
  "PostCode": 38229,
  "City": "Salzgitter",
  "Latitude": "52.140.024",
  "Longitude": "10.334.923",
  "Telephone": "0534141780",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.140024,10.334923",
  "id": "52.140024,10.334923"
}, {
  "Brand": "ARAL",
  "Street": "Reiseckenweg 7",
  "PostCode": 38640,
  "City": "Goslar",
  "Latitude": "51.907.543",
  "Longitude": "10.438.619",
  "Telephone": "0532122292",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.907543,10.438619",
  "id": "51.907543,10.438619"
}, {
  "Brand": "ARAL",
  "Street": "B 65 -Südseite-",
  "PostCode": 30559,
  "City": "Hannover",
  "Latitude": "52.357.767",
  "Longitude": "09.862.247",
  "Telephone": "05119508955",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.357767,9.862247",
  "id": "52.357767,9.862247"
}, {
  "Brand": "ARAL",
  "Street": "Kennedydamm 200 Ost",
  "PostCode": 31135,
  "City": "Hildesheim",
  "Latitude": "52.179.520",
  "Longitude": "09.964.843",
  "Telephone": "0512155951",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.17952,9.964843",
  "id": "52.17952,9.964843"
}, {
  "Brand": "ARAL",
  "Street": "A2",
  "PostCode": 31749,
  "City": "Auetal",
  "Latitude": "52.225.178",
  "Longitude": "09.229.529",
  "Telephone": "057529296620",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.225178,9.229529",
  "id": "52.225178,9.229529"
}, {
  "Brand": "ARAL",
  "Street": "Veerser Straße 98",
  "PostCode": 29525,
  "City": "Uelzen",
  "Latitude": "52.957.635",
  "Longitude": "10.551.173",
  "Telephone": "058174212",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.957635,10.551173",
  "id": "52.957635,10.551173"
}, {
  "Brand": "ARAL",
  "Street": "Bodenburger Straße 55",
  "PostCode": 31162,
  "City": "Bad Salzdetfurth",
  "Latitude": "52.054.295",
  "Longitude": "10.002.797",
  "Telephone": "050632573",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.054295,10.002797",
  "id": "52.054295,10.002797"
}, {
  "Brand": "ARAL",
  "Street": "Dr.-Heinrich-Jasper-Str.",
  "PostCode": 38667,
  "City": "Bad Harzburg",
  "Latitude": "51.890.441",
  "Longitude": "10.543.603",
  "Telephone": "0532286471",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.890441,10.543603",
  "id": "51.890441,10.543603"
}, {
  "Brand": "ARAL",
  "Street": "Hildesheimer Straße 18",
  "PostCode": 38640,
  "City": "Goslar",
  "Latitude": "51.912.696",
  "Longitude": "10.421.956",
  "Telephone": "0532122135",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.912696,10.421956",
  "id": "51.912696,10.421956"
}, {
  "Brand": "ARAL",
  "Street": "Altenbekener Damm 3",
  "PostCode": 30173,
  "City": "Hannover",
  "Latitude": "52.357.844",
  "Longitude": "09.767.514",
  "Telephone": "0511885849",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "52.357844,9.767514",
  "id": "52.357844,9.767514"
}, {
  "Brand": "ARAL",
  "Street": "Am Welfenplatz 19",
  "PostCode": 30161,
  "City": "Hannover",
  "Latitude": "52.383.431",
  "Longitude": "09.739.919",
  "Telephone": "0511314425",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.383431,9.739919",
  "id": "52.383431,9.739919"
}, {
  "Brand": "ARAL",
  "Street": "Marienstraße 108",
  "PostCode": 30171,
  "City": "Hannover",
  "Latitude": "52.370.188",
  "Longitude": "09.758.919",
  "Telephone": "0511858508",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.370188,9.758919",
  "id": "52.370188,9.758919"
}, {
  "Brand": "ARAL",
  "Street": "Poststraße 5",
  "PostCode": 38350,
  "City": "Helmstedt",
  "Latitude": "52.224.347",
  "Longitude": "11.013.880",
  "Telephone": "053517442",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.224347,11.01388",
  "id": "52.224347,11.01388"
}, {
  "Brand": "ARAL",
  "Street": "Alfelder Straße 99",
  "PostCode": 31139,
  "City": "Hildesheim",
  "Latitude": "52.147.231",
  "Longitude": "09.938.522",
  "Telephone": "0512143480",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.147231,9.938522",
  "id": "52.147231,9.938522"
}, {
  "Brand": "ARAL",
  "Street": "Allersheimer Straße 66",
  "PostCode": 37603,
  "City": "Holzminden",
  "Latitude": "51.836.518",
  "Longitude": "09.463.570",
  "Telephone": "0553193510",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "51.836518,9.46357",
  "id": "51.836518,9.46357"
}, {
  "Brand": "ARAL",
  "Street": "Burgdorfer Straße 115",
  "PostCode": 31275,
  "City": "Lehrte",
  "Latitude": "52.387.399",
  "Longitude": "09.970.479",
  "Telephone": "0513282960",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.387399,9.970479",
  "id": "52.387399,9.970479"
}, {
  "Brand": "ARAL",
  "Street": "Wolfsburger Straße 24",
  "PostCode": 38554,
  "City": "Weyhausen",
  "Latitude": "52.462.612",
  "Longitude": "10.718.878",
  "Telephone": "053627479",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.462612,10.718878",
  "id": "52.462612,10.718878"
}, {
  "Brand": "ARAL",
  "Street": "Dahlenburg. Landstraße 34",
  "PostCode": 21337,
  "City": "Lüneburg",
  "Latitude": "53.244.234",
  "Longitude": "10.444.590",
  "Telephone": "0413151560",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.244234,10.44459",
  "id": "53.244234,10.44459"
}, {
  "Brand": "ARAL",
  "Street": "Bültenweg 40",
  "PostCode": 38106,
  "City": "Braunschweig",
  "Latitude": "52.281.498",
  "Longitude": "10.536.907",
  "Telephone": "0531347887",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.281498,10.536907",
  "id": "52.281498,10.536907"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 58",
  "PostCode": 33102,
  "City": "Paderborn",
  "Latitude": "51.712.669",
  "Longitude": "08.736.330",
  "Telephone": "0525137111",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.712669,8.73633",
  "id": "51.712669,8.73633"
}, {
  "Brand": "ARAL",
  "Street": "Hannoversche Straße 142",
  "PostCode": 28309,
  "City": "Bremen",
  "Latitude": "53.048.427",
  "Longitude": "08.891.530",
  "Telephone": "0421452290",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.048427,8.89153",
  "id": "53.048427,8.89153"
}, {
  "Brand": "ARAL",
  "Street": "Habichtstraße 72-78",
  "PostCode": 22305,
  "City": "Hamburg",
  "Latitude": "53.592.307",
  "Longitude": "10.054.916",
  "Telephone": "040611041",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.592307,10.054916",
  "id": "53.592307,10.054916"
}, {
  "Brand": "ARAL",
  "Street": "Stapelfeldtstraße 4",
  "PostCode": 28237,
  "City": "Bremen",
  "Latitude": "53.111.003",
  "Longitude": "08.753.271",
  "Telephone": "04216163511",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.111003,8.753271",
  "id": "53.111003,8.753271"
}, {
  "Brand": "ARAL",
  "Street": "Herforder Str. 147 - 151",
  "PostCode": 32257,
  "City": "Bünde",
  "Latitude": "52.184.992",
  "Longitude": "08.604.273",
  "Telephone": "05223878088",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.184992,8.604273",
  "id": "52.184992,8.604273"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 205",
  "PostCode": 26639,
  "City": "Wiesmoor",
  "Latitude": "53.409.412",
  "Longitude": "07.727.903",
  "Telephone": "049445150",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "04:30",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "53.409412,7.727903",
  "id": "53.409412,7.727903"
}, {
  "Brand": "ARAL",
  "Street": "Vahrenwalder Str. 323",
  "PostCode": 30851,
  "City": "Langenhagen",
  "Latitude": "52.423.851",
  "Longitude": "09.732.302",
  "Telephone": "0511632212",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.423851,9.732302",
  "id": "52.423851,9.732302"
}, {
  "Brand": "ARAL",
  "Street": "Albaxer Straße 63",
  "PostCode": 37671,
  "City": "Höxter",
  "Latitude": "51.786.020",
  "Longitude": "09.387.016",
  "Telephone": "05271920071",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.78602,9.387016",
  "id": "51.78602,9.387016"
}, {
  "Brand": "ARAL",
  "Street": "Westerlange 2",
  "PostCode": 37154,
  "City": "Northeim",
  "Latitude": "51.730.902",
  "Longitude": "09.972.827",
  "Telephone": "05551909730",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.730902,9.972827",
  "id": "51.730902,9.972827"
}, {
  "Brand": "ARAL",
  "Street": "B65 / Hauptstraße 1",
  "PostCode": 31683,
  "City": "Obernkirchen",
  "Latitude": "52.284.253",
  "Longitude": "09.134.329",
  "Telephone": "05724902984",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.284253,9.134329",
  "id": "52.284253,9.134329"
}, {
  "Brand": "ARAL",
  "Street": "Kirchhorster Straße 24",
  "PostCode": 30659,
  "City": "Hannover",
  "Latitude": "52.414.372",
  "Longitude": "09.826.168",
  "Telephone": "05116139075",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.414372,9.826168",
  "id": "52.414372,9.826168"
}, {
  "Brand": "ARAL",
  "Street": "Friedrich-Tietjen-Str. 15",
  "PostCode": 27232,
  "City": "Sulingen",
  "Latitude": "52.685.760",
  "Longitude": "08.783.040",
  "Telephone": "0427193190",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.68576,8.78304",
  "id": "52.68576,8.78304"
}, {
  "Brand": "ARAL",
  "Street": "Mindener Straße 17",
  "PostCode": 32602,
  "City": "Vlotho",
  "Latitude": "52.173.933",
  "Longitude": "08.868.517",
  "Telephone": "0573380813",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.173933,8.868517",
  "id": "52.173933,8.868517"
}, {
  "Brand": "ARAL",
  "Street": "Vördener Straße 23",
  "PostCode": 49401,
  "City": "Damme",
  "Latitude": "52.515.605",
  "Longitude": "08.186.932",
  "Telephone": "054915412",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:30",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:30",
  "SundayFrom": "07:00",
  "SundayTo": "22:30",
  "IsOpen24Hours": "Nein",
  "location": "52.515605,8.186932",
  "id": "52.515605,8.186932"
}, {
  "Brand": "ARAL",
  "Street": "Bielefelder Straße 56",
  "PostCode": 32051,
  "City": "Herford",
  "Latitude": "52.109.947",
  "Longitude": "08.658.077",
  "Telephone": "05221169742",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.109947,8.658077",
  "id": "52.109947,8.658077"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 48",
  "PostCode": 49439,
  "City": "Steinfeld",
  "Latitude": "52.590.203",
  "Longitude": "08.203.203",
  "Telephone": "054927608",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "52.590203,8.203203",
  "id": "52.590203,8.203203"
}, {
  "Brand": "ARAL",
  "Street": "Petershütter Allee 2",
  "PostCode": 37520,
  "City": "Osterode",
  "Latitude": "51.732.411",
  "Longitude": "10.238.926",
  "Telephone": "05522317986",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.732411,10.238926",
  "id": "51.732411,10.238926"
}, {
  "Brand": "ARAL",
  "Street": "Borchener Straße336",
  "PostCode": 33106,
  "City": "Paderborn",
  "Latitude": "51.683.275",
  "Longitude": "08.721.109",
  "Telephone": "05251-892905",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.683275,8.721109",
  "id": "51.683275,8.721109"
}, {
  "Brand": "ARAL",
  "Street": "Hansestraße 47 A",
  "PostCode": 38112,
  "City": "Braunschweig",
  "Latitude": "52.322.011",
  "Longitude": "10.478.815",
  "Telephone": "05313102198",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.322011,10.478815",
  "id": "52.322011,10.478815"
}, {
  "Brand": "ARAL",
  "Street": "Leipziger Straße 201",
  "PostCode": 37235,
  "City": "Hessisch Lichtenau",
  "Latitude": "51.198.117",
  "Longitude": "09.746.010",
  "Telephone": "05602914088",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "51.198117,9.74601",
  "id": "51.198117,9.74601"
}, {
  "Brand": "ARAL",
  "Street": "Am Mühlenhop  1",
  "PostCode": 38154,
  "City": "Königslutter",
  "Latitude": "52.311.557",
  "Longitude": "10.823.885",
  "Telephone": "05365941703",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.311557,10.823885",
  "id": "52.311557,10.823885"
}, {
  "Brand": "ARAL",
  "Street": "Lüneburger Straße 14a",
  "PostCode": 30880,
  "City": "Laatzen",
  "Latitude": "52.294.130",
  "Longitude": "09.825.485",
  "Telephone": "05102916693",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.29413,9.825485",
  "id": "52.29413,9.825485"
}, {
  "Brand": "ARAL",
  "Street": "Leuschner Straße 81",
  "PostCode": 34134,
  "City": "Kassel",
  "Latitude": "51.291.270",
  "Longitude": "09.455.690",
  "Telephone": "0561402572",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.29127,9.45569",
  "id": "51.29127,9.45569"
}, {
  "Brand": "ARAL",
  "Street": "Oberrege 6",
  "PostCode": 26931,
  "City": "Elsfleth",
  "Latitude": "53.235.536",
  "Longitude": "08.455.668",
  "Telephone": "04404951488",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.235536,8.455668",
  "id": "53.235536,8.455668"
}, {
  "Brand": "ARAL",
  "Street": "Sieker Landstraße 128",
  "PostCode": 22143,
  "City": "Hamburg",
  "Latitude": "53.608.549",
  "Longitude": "10.190.714",
  "Telephone": "04067563128",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.608549,10.190714",
  "id": "53.608549,10.190714"
}, {
  "Brand": "ARAL",
  "Street": "An der B5 3",
  "PostCode": 25920,
  "City": "Risum-Lindholm",
  "Latitude": "54.760.199",
  "Longitude": "08.878.096",
  "Telephone": "04661941159",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "54.760199,8.878096",
  "id": "54.760199,8.878096"
}, {
  "Brand": "ARAL",
  "Street": "Korbacherstraße 2",
  "PostCode": 34454,
  "City": "Bad Arolsen",
  "Latitude": "51.381.158",
  "Longitude": "08.998.941",
  "Telephone": "056913584",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.381158,8.998941",
  "id": "51.381158,8.998941"
}, {
  "Brand": "ARAL",
  "Street": "Schwanallee 56",
  "PostCode": 35037,
  "City": "Marburg",
  "Latitude": "50.799.101",
  "Longitude": "08.762.429",
  "Telephone": "06421167089",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.799101,8.762429",
  "id": "50.799101,8.762429"
}, {
  "Brand": "ARAL",
  "Street": "Bredelarer Straße 50",
  "PostCode": 34431,
  "City": "Marsberg",
  "Latitude": "51.455.481",
  "Longitude": "08.840.655",
  "Telephone": "029928040",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.455481,8.840655",
  "id": "51.455481,8.840655"
}, {
  "Brand": "ARAL",
  "Street": "Briloner Straße 47",
  "PostCode": 34508,
  "City": "Willingen",
  "Latitude": "51.293.936",
  "Longitude": "08.604.794",
  "Telephone": "056326397",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "08:30",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "51.293936,8.604794",
  "id": "51.293936,8.604794"
}, {
  "Brand": "ARAL",
  "Street": "Burger Hauptstraße 10-12",
  "PostCode": 35745,
  "City": "Herborn-Burg",
  "Latitude": "50.693.525",
  "Longitude": "08.302.755",
  "Telephone": "027722675",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.693525,8.302755",
  "id": "50.693525,8.302755"
}, {
  "Brand": "ARAL",
  "Street": "Königsallee 245",
  "PostCode": 37079,
  "City": "Göttingen",
  "Latitude": "51.548.755",
  "Longitude": "09.909.477",
  "Telephone": "055162445",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.548755,9.909477",
  "id": "51.548755,9.909477"
}, {
  "Brand": "ARAL",
  "Street": "Giessener Straße 29",
  "PostCode": 35423,
  "City": "Lich",
  "Latitude": "50.524.535",
  "Longitude": "08.817.428",
  "Telephone": "06404950220",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.524535,8.817428",
  "id": "50.524535,8.817428"
}, {
  "Brand": "ARAL",
  "Street": "Niestetalstraße 10",
  "PostCode": 34266,
  "City": "Niestetal",
  "Latitude": "51.309.105",
  "Longitude": "09.570.691",
  "Telephone": "05619209547",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.309105,9.570691",
  "id": "51.309105,9.570691"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 32",
  "PostCode": 34593,
  "City": "Knüllwald",
  "Latitude": "51.006.637",
  "Longitude": "09.472.994",
  "Telephone": "056812872",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.006637,9.472994",
  "id": "51.006637,9.472994"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 85",
  "PostCode": 35236,
  "City": "Breidenbach",
  "Latitude": "50.890.115",
  "Longitude": "08.453.210",
  "Telephone": "06465913004",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:30",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.890115,8.45321",
  "id": "50.890115,8.45321"
}, {
  "Brand": "ARAL",
  "Street": "Korbacher Straße 24",
  "PostCode": 34513,
  "City": "Waldeck-Sachsenhause",
  "Latitude": "51.246.795",
  "Longitude": "09.001.947",
  "Telephone": "056341260",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "51.246795,9.001947",
  "id": "51.246795,9.001947"
}, {
  "Brand": "ARAL",
  "Street": "Hannoversche Str. 45",
  "PostCode": 37075,
  "City": "Göttingen",
  "Latitude": "51.554.009",
  "Longitude": "09.930.994",
  "Telephone": "055132518",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.554009,9.930994",
  "id": "51.554009,9.930994"
}, {
  "Brand": "ARAL",
  "Street": "Ederstraße 18",
  "PostCode": 35066,
  "City": "Frankenberg",
  "Latitude": "51.064.271",
  "Longitude": "08.804.600",
  "Telephone": "064514412",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.064271,8.8046",
  "id": "51.064271,8.8046"
}, {
  "Brand": "ARAL",
  "Street": "Nürnberger Straße 106",
  "PostCode": 34123,
  "City": "Kassel",
  "Latitude": "51.294.464",
  "Longitude": "09.511.655",
  "Telephone": "056159182",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.294464,9.511655",
  "id": "51.294464,9.511655"
}, {
  "Brand": "ARAL",
  "Street": "Schlitzer Straße 105",
  "PostCode": 36039,
  "City": "Fulda",
  "Latitude": "50.565.528",
  "Longitude": "09.657.295",
  "Telephone": "066151681",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.565528,9.657295",
  "id": "50.565528,9.657295"
}, {
  "Brand": "ARAL",
  "Street": "Löhrstraße 6",
  "PostCode": 35708,
  "City": "Haiger",
  "Latitude": "50.741.588",
  "Longitude": "08.207.649",
  "Telephone": "027735895",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.741588,8.207649",
  "id": "50.741588,8.207649"
}, {
  "Brand": "ARAL",
  "Street": "Herzberger Landstraße 3",
  "PostCode": 37434,
  "City": "Gieboldehausen",
  "Latitude": "51.615.622",
  "Longitude": "10.218.676",
  "Telephone": "05528200891",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.615622,10.218676",
  "id": "51.615622,10.218676"
}, {
  "Brand": "ARAL",
  "Street": "Hedemündener Straße 10",
  "PostCode": 34346,
  "City": "Hann. Münden",
  "Latitude": "51.417.840",
  "Longitude": "09.667.729",
  "Telephone": "055411859",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.41784,9.667729",
  "id": "51.41784,9.667729"
}, {
  "Brand": "ARAL",
  "Street": "Geismarlandstraße 63",
  "PostCode": 37083,
  "City": "Göttingen",
  "Latitude": "51.521.622",
  "Longitude": "09.950.926",
  "Telephone": "055173348",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "07:30",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.521622,9.950926",
  "id": "51.521622,9.950926"
}, {
  "Brand": "ARAL",
  "Street": "Alsfelder Straße 12",
  "PostCode": 36251,
  "City": "Bad Hersfeld",
  "Latitude": "50.836.174",
  "Longitude": "09.668.664",
  "Telephone": "0662172294",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.836174,9.668664",
  "id": "50.836174,9.668664"
}, {
  "Brand": "ARAL",
  "Street": "Karl-Kellner-Ring 2",
  "PostCode": 35576,
  "City": "Wetzlar",
  "Latitude": "50.553.514",
  "Longitude": "08.498.047",
  "Telephone": "06441921390",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.553514,8.498047",
  "id": "50.553514,8.498047"
}, {
  "Brand": "ARAL",
  "Street": "Grenzweg 2",
  "PostCode": 35415,
  "City": "Pohlheim",
  "Latitude": "50.541.696",
  "Longitude": "08.745.063",
  "Telephone": "064145427",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "18:00",
  "SundayFrom": "09:00",
  "SundayTo": "13:00",
  "IsOpen24Hours": "Nein",
  "location": "50.541696,8.745063",
  "id": "50.541696,8.745063"
}, {
  "Brand": "ARAL",
  "Street": "Marburger Straße 28",
  "PostCode": 35315,
  "City": "Homberg",
  "Latitude": "50.730.889",
  "Longitude": "08.994.660",
  "Telephone": "06633363",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.730889,8.99466",
  "id": "50.730889,8.99466"
}, {
  "Brand": "ARAL",
  "Street": "Marburger Str. 15",
  "PostCode": 35745,
  "City": "Herborn",
  "Latitude": "50.700.498",
  "Longitude": "08.337.595",
  "Telephone": "0277261240",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:30",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.700498,8.337595",
  "id": "50.700498,8.337595"
}, {
  "Brand": "ARAL",
  "Street": "Marburger Straße 229",
  "PostCode": 35396,
  "City": "Gießen",
  "Latitude": "50.606.453",
  "Longitude": "08.695.725",
  "Telephone": "06419502133",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.606453,8.695725",
  "id": "50.606453,8.695725"
}, {
  "Brand": "ARAL",
  "Street": "Leipziger Straße 266",
  "PostCode": 34260,
  "City": "Kaufungen",
  "Latitude": "51.284.221",
  "Longitude": "09.602.628",
  "Telephone": "056052524",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.284221,9.602628",
  "id": "51.284221,9.602628"
}, {
  "Brand": "ARAL",
  "Street": "Fritzlarer Straße 61",
  "PostCode": 34212,
  "City": "Melsungen",
  "Latitude": "51.128.955",
  "Longitude": "09.537.404",
  "Telephone": "05661920715",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.128955,9.537404",
  "id": "51.128955,9.537404"
}, {
  "Brand": "ARAL",
  "Street": "Langenbergstraße 9",
  "PostCode": 34225,
  "City": "Baunatal",
  "Latitude": "51.256.090",
  "Longitude": "09.413.223",
  "Telephone": "0561492256",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.25609,9.413223",
  "id": "51.25609,9.413223"
}, {
  "Brand": "ARAL",
  "Street": "Westerwaldstraße 26",
  "PostCode": 35745,
  "City": "Herborn",
  "Latitude": "50.678.465",
  "Longitude": "08.301.350",
  "Telephone": "0277282520",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.678465,8.30135",
  "id": "50.678465,8.30135"
}, {
  "Brand": "ARAL",
  "Street": "Petersburg  6",
  "PostCode": 35075,
  "City": "Gladenbach",
  "Latitude": "50.753.283",
  "Longitude": "08.546.959",
  "Telephone": "064621086",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.753283,8.546959",
  "id": "50.753283,8.546959"
}, {
  "Brand": "ARAL",
  "Street": "Fuldaer Straße 27",
  "PostCode": 36088,
  "City": "Hünfeld",
  "Latitude": "50.667.382",
  "Longitude": "09.762.146",
  "Telephone": "066522705",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Ja",
  "location": "50.667382,9.762146",
  "id": "50.667382,9.762146"
}, {
  "Brand": "ARAL",
  "Street": "Kohlenstraße 60",
  "PostCode": 34121,
  "City": "Kassel",
  "Latitude": "51.309.211",
  "Longitude": "09.462.022",
  "Telephone": "056124343",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.309211,9.462022",
  "id": "51.309211,9.462022"
}, {
  "Brand": "ARAL",
  "Street": "Herzberger Straße 1",
  "PostCode": 37115,
  "City": "Duderstadt",
  "Latitude": "51.515.854",
  "Longitude": "10.260.760",
  "Telephone": "055272838",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.515854,10.26076",
  "id": "51.515854,10.26076"
}, {
  "Brand": "ARAL",
  "Street": "Kasseler Straße 21",
  "PostCode": 35091,
  "City": "Cölbe",
  "Latitude": "50.847.007",
  "Longitude": "08.776.021",
  "Telephone": "0642182645",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.847007,8.776021",
  "id": "50.847007,8.776021"
}, {
  "Brand": "ARAL",
  "Street": "Kasseler Straße 32",
  "PostCode": 35683,
  "City": "Dillenburg",
  "Latitude": "50.749.956",
  "Longitude": "08.276.847",
  "Telephone": "02771812117",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "05:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.749956,8.276847",
  "id": "50.749956,8.276847"
}, {
  "Brand": "ARAL",
  "Street": "Westrandstraße 1",
  "PostCode": 34582,
  "City": "Borken",
  "Latitude": "51.049.110",
  "Longitude": "09.273.020",
  "Telephone": "056822433",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.04911,9.27302",
  "id": "51.04911,9.27302"
}, {
  "Brand": "ARAL",
  "Street": "Kasseler Straße 53",
  "PostCode": 34253,
  "City": "Lohfelden",
  "Latitude": "51.268.222",
  "Longitude": "09.561.636",
  "Telephone": "0560891807",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:30",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:30",
  "SundayFrom": "08:00",
  "SundayTo": "21:30",
  "IsOpen24Hours": "Nein",
  "location": "51.268222,9.561636",
  "id": "51.268222,9.561636"
}, {
  "Brand": "ARAL",
  "Street": "Magdeburger Straße 47",
  "PostCode": 36037,
  "City": "Fulda",
  "Latitude": "50.558.826",
  "Longitude": "09.684.966",
  "Telephone": "0661604123",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.558826,9.684966",
  "id": "50.558826,9.684966"
}, {
  "Brand": "ARAL",
  "Street": "Brückenstraße 2",
  "PostCode": 35638,
  "City": "Leun",
  "Latitude": "50.548.085",
  "Longitude": "08.358.471",
  "Telephone": "064738434",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.548085,8.358471",
  "id": "50.548085,8.358471"
}, {
  "Brand": "ARAL",
  "Street": "BAB 49/Westseite",
  "PostCode": 34295,
  "City": "Edermünde",
  "Latitude": "51.223.765",
  "Longitude": "09.419.553",
  "Telephone": "056656388",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.223765,9.419553",
  "id": "51.223765,9.419553"
}, {
  "Brand": "ARAL",
  "Street": "Driburger Straße 2",
  "PostCode": 33034,
  "City": "Brakel",
  "Latitude": "51.712.546",
  "Longitude": "09.177.966",
  "Telephone": "052723920240",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.712546,9.177966",
  "id": "51.712546,9.177966"
}, {
  "Brand": "ARAL",
  "Street": "Edertalstraße 60",
  "PostCode": 35108,
  "City": "Allendorf",
  "Latitude": "51.021.558",
  "Longitude": "08.663.096",
  "Telephone": "064529110251",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "09:00",
  "SundayTo": "13:00",
  "IsOpen24Hours": "Nein",
  "location": "51.021558,8.663096",
  "id": "51.021558,8.663096"
}, {
  "Brand": "ARAL",
  "Street": "Möhnestraße 22",
  "PostCode": 59929,
  "City": "Brilon",
  "Latitude": "51.403.220",
  "Longitude": "08.571.145",
  "Telephone": "029618601",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.40322,8.571145",
  "id": "51.40322,8.571145"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstrasse 1",
  "PostCode": 38700,
  "City": "Braunlage",
  "Latitude": "51.720.189",
  "Longitude": "10.612.492",
  "Telephone": "05520923222",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.720189,10.612492",
  "id": "51.720189,10.612492"
}, {
  "Brand": "ARAL",
  "Street": "A7",
  "PostCode": 36148,
  "City": "Kalbach",
  "Latitude": "50.419.785",
  "Longitude": "09.735.476",
  "Telephone": "09742204",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.419785,9.735476",
  "id": "50.419785,9.735476"
}, {
  "Brand": "ARAL",
  "Street": "Borchener Straße 42",
  "PostCode": 33098,
  "City": "Paderborn",
  "Latitude": "51.710.860",
  "Longitude": "08.746.393",
  "Telephone": "0525172335",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.71086,8.746393",
  "id": "51.71086,8.746393"
}, {
  "Brand": "ARAL",
  "Street": "Röddenauer Straße 31",
  "PostCode": 35066,
  "City": "Frankenberg",
  "Latitude": "51.058.242",
  "Longitude": "08.783.910",
  "Telephone": "064516152",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.058242,8.78391",
  "id": "51.058242,8.78391"
}, {
  "Brand": "ARAL",
  "Street": "Reinhäuser Landstraße 108",
  "PostCode": 37083,
  "City": "Göttingen",
  "Latitude": "51.520.218",
  "Longitude": "09.940.411",
  "Telephone": "055131705",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.520218,9.940411",
  "id": "51.520218,9.940411"
}, {
  "Brand": "ARAL",
  "Street": "Weender Landstraße 62A",
  "PostCode": 37075,
  "City": "Göttingen",
  "Latitude": "51.544.402",
  "Longitude": "09.932.306",
  "Telephone": "055131444",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.544402,9.932306",
  "id": "51.544402,9.932306"
}, {
  "Brand": "ARAL",
  "Street": "Veckerhagener Straße19",
  "PostCode": 34233,
  "City": "Fuldatal",
  "Latitude": "51.347.365",
  "Longitude": "09.523.922",
  "Telephone": "0561811447",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.347365,9.523922",
  "id": "51.347365,9.523922"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Straße 90",
  "PostCode": 34121,
  "City": "Kassel",
  "Latitude": "51.303.697",
  "Longitude": "09.483.775",
  "Telephone": "056123104",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.303697,9.483775",
  "id": "51.303697,9.483775"
}, {
  "Brand": "ARAL",
  "Street": "Holländische Straße 142",
  "PostCode": 34127,
  "City": "Kassel",
  "Latitude": "51.333.741",
  "Longitude": "09.495.671",
  "Telephone": "05619893813",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.333741,9.495671",
  "id": "51.333741,9.495671"
}, {
  "Brand": "ARAL",
  "Street": "Krummbogen 4",
  "PostCode": 35039,
  "City": "Marburg",
  "Latitude": "50.817.594",
  "Longitude": "08.774.307",
  "Telephone": "0642161348",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.817594,8.774307",
  "id": "50.817594,8.774307"
}, {
  "Brand": "ARAL",
  "Street": "Großseelheimer Straße 93",
  "PostCode": 35039,
  "City": "Marburg",
  "Latitude": "50.799.606",
  "Longitude": "08.788.718",
  "Telephone": "0642124595",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.799606,8.788718",
  "id": "50.799606,8.788718"
}, {
  "Brand": "ARAL",
  "Street": "Walburger Straße 51",
  "PostCode": 37213,
  "City": "Witzenhausen",
  "Latitude": "51.338.037",
  "Longitude": "09.859.472",
  "Telephone": "055422348",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.338037,9.859472",
  "id": "51.338037,9.859472"
}, {
  "Brand": "ARAL",
  "Street": "Ippinghäuser Straße 10",
  "PostCode": 34466,
  "City": "Wolfhagen",
  "Latitude": "51.323.470",
  "Longitude": "09.173.734",
  "Telephone": "056929876100",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.32347,9.173734",
  "id": "51.32347,9.173734"
}, {
  "Brand": "ARAL",
  "Street": "Geseker Straße 21",
  "PostCode": 33154,
  "City": "Salzkotten",
  "Latitude": "51.668.236",
  "Longitude": "08.594.090",
  "Telephone": "05258935377",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "09:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.668236,8.59409",
  "id": "51.668236,8.59409"
}, {
  "Brand": "ARAL",
  "Street": "Paderborner Straße 22",
  "PostCode": 33161,
  "City": "Hövelhof",
  "Latitude": "51.818.751",
  "Longitude": "08.659.243",
  "Telephone": "052572631",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.818751,8.659243",
  "id": "51.818751,8.659243"
}, {
  "Brand": "ARAL",
  "Street": "Scharzfelder Str. 6",
  "PostCode": 37412,
  "City": "Herzberg",
  "Latitude": "51.649.602",
  "Longitude": "10.346.567",
  "Telephone": "055213071",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.649602,10.346567",
  "id": "51.649602,10.346567"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 116",
  "PostCode": 67346,
  "City": "Speyer",
  "Latitude": "49.327.270",
  "Longitude": "08.429.358",
  "Telephone": "0623276218",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.32727,8.429358",
  "id": "49.32727,8.429358"
}, {
  "Brand": "ARAL",
  "Street": "Zweibrücker Straße 130",
  "PostCode": 66954,
  "City": "Pirmasens",
  "Latitude": "49.213.939",
  "Longitude": "07.597.454",
  "Telephone": "0633165954",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.213939,7.597454",
  "id": "49.213939,7.597454"
}, {
  "Brand": "ARAL",
  "Street": "Rheinstraße 69",
  "PostCode": 64295,
  "City": "Darmstadt",
  "Latitude": "49.871.594",
  "Longitude": "08.639.391",
  "Telephone": "06151891309",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.871594,8.639391",
  "id": "49.871594,8.639391"
}, {
  "Brand": "ARAL",
  "Street": "Bergheimer Straße 90",
  "PostCode": 69115,
  "City": "Heidelberg",
  "Latitude": "49.408.024",
  "Longitude": "08.678.280",
  "Telephone": "06221163048",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.408024,8.67828",
  "id": "49.408024,8.67828"
}, {
  "Brand": "ARAL",
  "Street": "Nibelungenstraße 197",
  "PostCode": 68642,
  "City": "Bürstadt",
  "Latitude": "49.645.725",
  "Longitude": "08.473.277",
  "Telephone": "06206963564",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.645725,8.473277",
  "id": "49.645725,8.473277"
}, {
  "Brand": "ARAL",
  "Street": "Rheinstraße 47-49",
  "PostCode": 67574,
  "City": "Osthofen",
  "Latitude": "49.704.644",
  "Longitude": "08.333.475",
  "Telephone": "062425028777",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "49.704644,8.333475",
  "id": "49.704644,8.333475"
}, {
  "Brand": "ARAL",
  "Street": "Saarbrücker Straße 56",
  "PostCode": 67742,
  "City": "Lauterecken",
  "Latitude": "49.650.055",
  "Longitude": "07.584.230",
  "Telephone": "06382505",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.650055,7.58423",
  "id": "49.650055,7.58423"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 71",
  "PostCode": 67714,
  "City": "Waldfischbach-Burgal",
  "Latitude": "49.286.531",
  "Longitude": "07.649.620",
  "Telephone": "06333274825",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.286531,7.64962",
  "id": "49.286531,7.64962"
}, {
  "Brand": "ARAL",
  "Street": "Schaffhäuser Straße 2-6",
  "PostCode": 55232,
  "City": "Alzey",
  "Latitude": "49.750.778",
  "Longitude": "08.119.211",
  "Telephone": "0673142299",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.750778,8.119211",
  "id": "49.750778,8.119211"
}, {
  "Brand": "ARAL",
  "Street": "Ludwigstraße 21-25",
  "PostCode": 69483,
  "City": "Wald-Michelbach",
  "Latitude": "49.573.554",
  "Longitude": "08.823.318",
  "Telephone": "0620794170",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "09:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.573554,8.823318",
  "id": "49.573554,8.823318"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 77a",
  "PostCode": 67685,
  "City": "Weilerbach",
  "Latitude": "49.477.554",
  "Longitude": "07.637.979",
  "Telephone": "063741285",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.477554,7.637979",
  "id": "49.477554,7.637979"
}, {
  "Brand": "ARAL",
  "Street": "Möhlstraße 1",
  "PostCode": 68165,
  "City": "Mannheim",
  "Latitude": "49.475.710",
  "Longitude": "08.487.958",
  "Telephone": "0621407238",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.47571,8.487958",
  "id": "49.47571,8.487958"
}, {
  "Brand": "ARAL",
  "Street": "Aschaffenburger Straße 3",
  "PostCode": 64832,
  "City": "Babenhausen",
  "Latitude": "49.957.428",
  "Longitude": "08.958.051",
  "Telephone": "0607362912",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.957428,8.958051",
  "id": "49.957428,8.958051"
}, {
  "Brand": "ARAL",
  "Street": "Friedrichsdorfer Landstra",
  "PostCode": 69412,
  "City": "Eberbach",
  "Latitude": "49.470.536",
  "Longitude": "08.994.548",
  "Telephone": "06271921821",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.470536,8.994548",
  "id": "49.470536,8.994548"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Landstraße 257",
  "PostCode": 64291,
  "City": "Darmstadt",
  "Latitude": "49.918.192",
  "Longitude": "08.654.759",
  "Telephone": "061511015810",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.918192,8.654759",
  "id": "49.918192,8.654759"
}, {
  "Brand": "ARAL",
  "Street": "Neckarsteinacher Str. 42",
  "PostCode": 69250,
  "City": "Schönau",
  "Latitude": "49.429.992",
  "Longitude": "08.812.239",
  "Telephone": "06228913878",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.429992,8.812239",
  "id": "49.429992,8.812239"
}, {
  "Brand": "ARAL",
  "Street": "Speyerer-Straße 105 a",
  "PostCode": 67117,
  "City": "Limburgerhof",
  "Latitude": "49.421.961",
  "Longitude": "08.394.073",
  "Telephone": "0623667805",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "04:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "04:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "49.421961,8.394073",
  "id": "49.421961,8.394073"
}, {
  "Brand": "ARAL",
  "Street": "Kirchheimer Straße 51-53",
  "PostCode": 67269,
  "City": "Grünstadt",
  "Latitude": "49.559.289",
  "Longitude": "08.170.279",
  "Telephone": "063593194",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "05:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "49.559289,8.170279",
  "id": "49.559289,8.170279"
}, {
  "Brand": "ARAL",
  "Street": "Relaisstraße 1-3",
  "PostCode": 68219,
  "City": "Mannheim",
  "Latitude": "49.438.393",
  "Longitude": "08.523.771",
  "Telephone": "0621891606",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.438393,8.523771",
  "id": "49.438393,8.523771"
}, {
  "Brand": "ARAL",
  "Street": "Kaiserstraße 110",
  "PostCode": 66849,
  "City": "Landstuhl",
  "Latitude": "49.414.005",
  "Longitude": "07.582.463",
  "Telephone": "0637112115",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:30",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.414005,7.582463",
  "id": "49.414005,7.582463"
}, {
  "Brand": "ARAL",
  "Street": "Bischheimer Straße 9",
  "PostCode": 67292,
  "City": "Kirchheimbolanden",
  "Latitude": "49.668.573",
  "Longitude": "08.020.080",
  "Telephone": "063525016",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.668573,8.02008",
  "id": "49.668573,8.02008"
}, {
  "Brand": "ARAL",
  "Street": "Frankenthaler Straße 86",
  "PostCode": 68307,
  "City": "Mannheim",
  "Latitude": "49.550.115",
  "Longitude": "08.449.075",
  "Telephone": "0621771755",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.550115,8.449075",
  "id": "49.550115,8.449075"
}, {
  "Brand": "ARAL",
  "Street": "Trippstadter Straße 69",
  "PostCode": 67663,
  "City": "Kaiserslautern",
  "Latitude": "49.433.753",
  "Longitude": "07.757.465",
  "Telephone": "063128680",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.433753,7.757465",
  "id": "49.433753,7.757465"
}, {
  "Brand": "ARAL",
  "Street": "Maximilianstraße 45",
  "PostCode": 76534,
  "City": "Baden-Baden",
  "Latitude": "48.749.844",
  "Longitude": "08.254.592",
  "Telephone": "072217438",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.749844,8.254592",
  "id": "48.749844,8.254592"
}, {
  "Brand": "ARAL",
  "Street": "Zeiskamer Straße 63",
  "PostCode": 76756,
  "City": "Bellheim",
  "Latitude": "49.195.579",
  "Longitude": "08.277.498",
  "Telephone": "0727293110",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.195579,8.277498",
  "id": "49.195579,8.277498"
}, {
  "Brand": "ARAL",
  "Street": "Pforzheimer Straße69",
  "PostCode": 75015,
  "City": "Bretten",
  "Latitude": "49.032.837",
  "Longitude": "08.706.649",
  "Telephone": "072527327",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.032837,8.706649",
  "id": "49.032837,8.706649"
}, {
  "Brand": "ARAL",
  "Street": "Niederramstädter Str. 170",
  "PostCode": 64285,
  "City": "Darmstadt",
  "Latitude": "49.856.413",
  "Longitude": "08.669.227",
  "Telephone": "0615148696",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.856413,8.669227",
  "id": "49.856413,8.669227"
}, {
  "Brand": "ARAL",
  "Street": "Hochspeyerer-Straße 53",
  "PostCode": 67677,
  "City": "Enkenbach-Alsenborn",
  "Latitude": "49.483.675",
  "Longitude": "07.900.407",
  "Telephone": "063034364",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.483675,7.900407",
  "id": "49.483675,7.900407"
}, {
  "Brand": "ARAL",
  "Street": "Darmstädter Straße122",
  "PostCode": 64331,
  "City": "Weiterstadt",
  "Latitude": "49.902.250",
  "Longitude": "08.594.787",
  "Telephone": "061502190",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.90225,8.594787",
  "id": "49.90225,8.594787"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 10",
  "PostCode": 67697,
  "City": "Otterberg",
  "Latitude": "49.499.206",
  "Longitude": "07.772.772",
  "Telephone": "063019778",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.499206,7.772772",
  "id": "49.499206,7.772772"
}, {
  "Brand": "ARAL",
  "Street": "Neckarstraße 40",
  "PostCode": 64711,
  "City": "Erbach",
  "Latitude": "49.652.422",
  "Longitude": "08.995.297",
  "Telephone": "060623565",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.652422,8.995297",
  "id": "49.652422,8.995297"
}, {
  "Brand": "ARAL",
  "Street": "Heidelberger Straße 9",
  "PostCode": 69207,
  "City": "Sandhausen",
  "Latitude": "49.345.916",
  "Longitude": "08.660.079",
  "Telephone": "062243159",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "49.345916,8.660079",
  "id": "49.345916,8.660079"
}, {
  "Brand": "ARAL",
  "Street": "Gaustraße 156",
  "PostCode": 67549,
  "City": "Worms",
  "Latitude": "49.646.155",
  "Longitude": "08.349.439",
  "Telephone": "0624152310",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:30",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.646155,8.349439",
  "id": "49.646155,8.349439"
}, {
  "Brand": "ARAL",
  "Street": "Lauterstraße 1",
  "PostCode": 67731,
  "City": "Otterbach",
  "Latitude": "49.482.837",
  "Longitude": "07.730.862",
  "Telephone": "063019877",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.482837,7.730862",
  "id": "49.482837,7.730862"
}, {
  "Brand": "ARAL",
  "Street": "Wormser Straße 53",
  "PostCode": 64625,
  "City": "Bensheim",
  "Latitude": "49.676.285",
  "Longitude": "08.611.732",
  "Telephone": "062512416",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.676285,8.611732",
  "id": "49.676285,8.611732"
}, {
  "Brand": "ARAL",
  "Street": "Neustadter Straße 47",
  "PostCode": 68309,
  "City": "Mannheim",
  "Latitude": "49.499.635",
  "Longitude": "08.506.895",
  "Telephone": "0621735151",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.499635,8.506895",
  "id": "49.499635,8.506895"
}, {
  "Brand": "ARAL",
  "Street": "Branchweilerhofstraße 104",
  "PostCode": 67433,
  "City": "Neustadt",
  "Latitude": "49.350.710",
  "Longitude": "08.163.982",
  "Telephone": "0632112320",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.35071,8.163982",
  "id": "49.35071,8.163982"
}, {
  "Brand": "ARAL",
  "Street": "Rüdesheimer Straße 114",
  "PostCode": 64295,
  "City": "Darmstadt",
  "Latitude": "49.854.019",
  "Longitude": "08.641.989",
  "Telephone": "0615163545",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.854019,8.641989",
  "id": "49.854019,8.641989"
}, {
  "Brand": "ARAL",
  "Street": "Staatsstraße 101",
  "PostCode": 64668,
  "City": "Rimbach",
  "Latitude": "49.630.154",
  "Longitude": "08.759.950",
  "Telephone": "062536484",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.630154,8.75995",
  "id": "49.630154,8.75995"
}, {
  "Brand": "ARAL",
  "Street": "Saar-Pfalz-Straße",
  "PostCode": 66440,
  "City": "Blieskastel-Aßweiler",
  "Latitude": "49.213.016",
  "Longitude": "07.181.737",
  "Telephone": "068038433",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.213016,7.181737",
  "id": "49.213016,7.181737"
}, {
  "Brand": "ARAL",
  "Street": "Grülingstraße",
  "PostCode": 66280,
  "City": "Sulzbach",
  "Latitude": "49.306.551",
  "Longitude": "07.056.207",
  "Telephone": "068972307",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.306551,7.056207",
  "id": "49.306551,7.056207"
}, {
  "Brand": "ARAL",
  "Street": "Alsweiler Straße 50",
  "PostCode": 66646,
  "City": "Marpingen",
  "Latitude": "49.455.796",
  "Longitude": "07.060.740",
  "Telephone": "068535107",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.455796,7.06074",
  "id": "49.455796,7.06074"
}, {
  "Brand": "ARAL",
  "Street": "Trierer Straße 90",
  "PostCode": 66839,
  "City": "Schmelz",
  "Latitude": "49.463.000",
  "Longitude": "06.851.665",
  "Telephone": "0688788393",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.463,6.851665",
  "id": "49.463,6.851665"
}, {
  "Brand": "ARAL",
  "Street": "Glashüttenstraße 4",
  "PostCode": 66287,
  "City": "Quierschied",
  "Latitude": "49.329.715",
  "Longitude": "07.056.714",
  "Telephone": "0689761902",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.329715,7.056714",
  "id": "49.329715,7.056714"
}, {
  "Brand": "ARAL",
  "Street": "Wallerfanger Straße 69",
  "PostCode": 66780,
  "City": "Rehlingen-Siersburg",
  "Latitude": "49.365.029",
  "Longitude": "06.687.149",
  "Telephone": "068357591",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.365029,6.687149",
  "id": "49.365029,6.687149"
}, {
  "Brand": "ARAL",
  "Street": "Mommstraße 1",
  "PostCode": 66606,
  "City": "St. Wendel",
  "Latitude": "49.469.793",
  "Longitude": "07.167.429",
  "Telephone": "068512753",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.469793,7.167429",
  "id": "49.469793,7.167429"
}, {
  "Brand": "ARAL",
  "Street": "Poststraße 33",
  "PostCode": 66687,
  "City": "Wadern",
  "Latitude": "49.535.001",
  "Longitude": "06.895.406",
  "Telephone": "06871902924",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.535001,6.895406",
  "id": "49.535001,6.895406"
}, {
  "Brand": "ARAL",
  "Street": "Straße des 13. Januar 197",
  "PostCode": 66333,
  "City": "Völklingen",
  "Latitude": "49.249.077",
  "Longitude": "06.898.475",
  "Telephone": "0689881668",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.249077,6.898475",
  "id": "49.249077,6.898475"
}, {
  "Brand": "ARAL",
  "Street": "Haustadter-Tal-Straße 110",
  "PostCode": 66701,
  "City": "Beckingen",
  "Latitude": "49.409.617",
  "Longitude": "06.717.799",
  "Telephone": "068357569",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.409617,6.717799",
  "id": "49.409617,6.717799"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 22",
  "PostCode": 66740,
  "City": "Saarlouis",
  "Latitude": "49.325.216",
  "Longitude": "06.757.746",
  "Telephone": "0683181203",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.325216,6.757746",
  "id": "49.325216,6.757746"
}, {
  "Brand": "ARAL",
  "Street": "von-Boch-Liebig-Straße 21",
  "PostCode": 66693,
  "City": "Mettlach",
  "Latitude": "49.495.053",
  "Longitude": "06.586.036",
  "Telephone": "0686493532",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.495053,6.586036",
  "id": "49.495053,6.586036"
}, {
  "Brand": "ARAL",
  "Street": "Karolingerstraße 5",
  "PostCode": 66333,
  "City": "Völklingen",
  "Latitude": "49.247.547",
  "Longitude": "06.859.246",
  "Telephone": "0689823327",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.247547,6.859246",
  "id": "49.247547,6.859246"
}, {
  "Brand": "ARAL",
  "Street": "Lindenstraße 7",
  "PostCode": 66583,
  "City": "Spiesen-Elversberg",
  "Latitude": "49.317.800",
  "Longitude": "07.121.840",
  "Telephone": "0682171436",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.3178,7.12184",
  "id": "49.3178,7.12184"
}, {
  "Brand": "ARAL",
  "Street": "St. Johanner Straße 105",
  "PostCode": 66115,
  "City": "Saarbrücken",
  "Latitude": "49.239.022",
  "Longitude": "06.976.791",
  "Telephone": "068143798",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.239022,6.976791",
  "id": "49.239022,6.976791"
}, {
  "Brand": "ARAL",
  "Street": "Höllerstraße 16",
  "PostCode": 55597,
  "City": "Wöllstein",
  "Latitude": "49.816.959",
  "Longitude": "07.960.743",
  "Telephone": "06703960203",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.816959,7.960743",
  "id": "49.816959,7.960743"
}, {
  "Brand": "ARAL",
  "Street": "Chausseehaus",
  "PostCode": 55291,
  "City": "Saulheim",
  "Latitude": "49.869.474",
  "Longitude": "08.146.201",
  "Telephone": "067324317",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "19:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "49.869474,8.146201",
  "id": "49.869474,8.146201"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 129",
  "PostCode": 77815,
  "City": "Bühl",
  "Latitude": "48.702.100",
  "Longitude": "08.139.317",
  "Telephone": "072234364",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.7021,8.139317",
  "id": "48.7021,8.139317"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 166 A",
  "PostCode": 76448,
  "City": "Durmersheim",
  "Latitude": "48.936.479",
  "Longitude": "08.272.350",
  "Telephone": "072457956",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.936479,8.27235",
  "id": "48.936479,8.27235"
}, {
  "Brand": "ARAL",
  "Street": "Brettener Straße 68",
  "PostCode": 75031,
  "City": "Eppingen",
  "Latitude": "49.134.961",
  "Longitude": "08.901.309",
  "Telephone": "07262912086",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.134961,8.901309",
  "id": "49.134961,8.901309"
}, {
  "Brand": "ARAL",
  "Street": "Wiesenstraße 30",
  "PostCode": 76228,
  "City": "Karlsruhe",
  "Latitude": "48.952.746",
  "Longitude": "08.470.857",
  "Telephone": "0721450101",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.952746,8.470857",
  "id": "48.952746,8.470857"
}, {
  "Brand": "ARAL",
  "Street": "Friedenstraße 2a",
  "PostCode": 76767,
  "City": "Hagenbach",
  "Latitude": "49.022.048",
  "Longitude": "08.251.543",
  "Telephone": "072734518",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.022048,8.251543",
  "id": "49.022048,8.251543"
}, {
  "Brand": "ARAL",
  "Street": "Hindenburgstraße  75",
  "PostCode": 75339,
  "City": "Höfen",
  "Latitude": "48.808.028",
  "Longitude": "08.583.970",
  "Telephone": "070815286",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.808028,8.58397",
  "id": "48.808028,8.58397"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 1",
  "PostCode": 76549,
  "City": "Hügelsheim",
  "Latitude": "48.799.102",
  "Longitude": "08.115.871",
  "Telephone": "07229181765",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.799102,8.115871",
  "id": "48.799102,8.115871"
}, {
  "Brand": "ARAL",
  "Street": "Rheinstraße 128",
  "PostCode": 76870,
  "City": "Kandel",
  "Latitude": "49.085.265",
  "Longitude": "08.210.204",
  "Telephone": "0727595670",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.085265,8.210204",
  "id": "49.085265,8.210204"
}, {
  "Brand": "ARAL",
  "Street": "Durlacher Allee 46",
  "PostCode": 76131,
  "City": "Karlsruhe",
  "Latitude": "49.007.631",
  "Longitude": "08.422.850",
  "Telephone": "0721696022",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "49.007631,8.42285",
  "id": "49.007631,8.42285"
}, {
  "Brand": "ARAL",
  "Street": "Amalienstraße 55/59",
  "PostCode": 76133,
  "City": "Karlsruhe",
  "Latitude": "49.009.215",
  "Longitude": "08.390.873",
  "Telephone": "072126224",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.009215,8.390873",
  "id": "49.009215,8.390873"
}, {
  "Brand": "ARAL",
  "Street": "Kaiserallee 12 G",
  "PostCode": 76133,
  "City": "Karlsruhe",
  "Latitude": "49.011.316",
  "Longitude": "08.375.062",
  "Telephone": "0721842649",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.011316,8.375062",
  "id": "49.011316,8.375062"
}, {
  "Brand": "ARAL",
  "Street": "Ebertstraße 32",
  "PostCode": 76137,
  "City": "Karlsruhe",
  "Latitude": "48.995.142",
  "Longitude": "08.389.539",
  "Telephone": "0721817516",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "48.995142,8.389539",
  "id": "48.995142,8.389539"
}, {
  "Brand": "ARAL",
  "Street": "Ankerstraße 64",
  "PostCode": 75203,
  "City": "Königsbach-Stein",
  "Latitude": "48.966.665",
  "Longitude": "08.603.625",
  "Telephone": "072325595",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:30",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:30",
  "SundayFrom": "08:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "48.966665,8.603625",
  "id": "48.966665,8.603625"
}, {
  "Brand": "ARAL",
  "Street": "Ettlinger-Straße35",
  "PostCode": 76307,
  "City": "Karlsbad",
  "Latitude": "48.917.312",
  "Longitude": "08.499.941",
  "Telephone": "072023233",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.917312,8.499941",
  "id": "48.917312,8.499941"
}, {
  "Brand": "ARAL",
  "Street": "Pforzheimer Straße 55",
  "PostCode": 75417,
  "City": "Mühlacker",
  "Latitude": "48.948.279",
  "Longitude": "08.834.584",
  "Telephone": "070414840",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.948279,8.834584",
  "id": "48.948279,8.834584"
}, {
  "Brand": "ARAL",
  "Street": "Wildbader Straße 55",
  "PostCode": 75394,
  "City": "Oberreichenbach",
  "Latitude": "48.734.192",
  "Longitude": "08.662.784",
  "Telephone": "07051968683",
  "WeekDayFrom": "06:45",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "08:30",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "48.734192,8.662784",
  "id": "48.734192,8.662784"
}, {
  "Brand": "ARAL",
  "Street": "Landauer Straße 11",
  "PostCode": 76877,
  "City": "Offenbach",
  "Latitude": "49.194.376",
  "Longitude": "08.187.527",
  "Telephone": "063486473",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "09:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.194376,8.187527",
  "id": "49.194376,8.187527"
}, {
  "Brand": "ARAL",
  "Street": "Kaiser-Friedrich-Straße 3",
  "PostCode": 75172,
  "City": "Pforzheim",
  "Latitude": "48.889.079",
  "Longitude": "08.692.441",
  "Telephone": "07231927913",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.889079,8.692441",
  "id": "48.889079,8.692441"
}, {
  "Brand": "ARAL",
  "Street": "Alexanderplatz",
  "PostCode": 75015,
  "City": "Bretten",
  "Latitude": "49.037.842",
  "Longitude": "08.687.745",
  "Telephone": "07252958220",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.037842,8.687745",
  "id": "49.037842,8.687745"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße",
  "PostCode": 75056,
  "City": "Sulzfeld",
  "Latitude": "49.109.529",
  "Longitude": "08.855.131",
  "Telephone": "07269919130",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.109529,8.855131",
  "id": "49.109529,8.855131"
}, {
  "Brand": "ARAL",
  "Street": "Weingartener Straße 99",
  "PostCode": 76646,
  "City": "Bruchsal",
  "Latitude": "49.080.138",
  "Longitude": "08.549.252",
  "Telephone": "07257911412",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:30",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.080138,8.549252",
  "id": "49.080138,8.549252"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße2",
  "PostCode": 75196,
  "City": "Remchingen",
  "Latitude": "48.952.996",
  "Longitude": "08.570.688",
  "Telephone": "0723271202",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.952996,8.570688",
  "id": "48.952996,8.570688"
}, {
  "Brand": "ARAL",
  "Street": "Stuttgarter Straße 139",
  "PostCode": 71665,
  "City": "Vaihingen",
  "Latitude": "48.922.426",
  "Longitude": "08.974.054",
  "Telephone": "0704217154",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.922426,8.974054",
  "id": "48.922426,8.974054"
}, {
  "Brand": "ARAL",
  "Street": "Haiterbacher Straße 24-26",
  "PostCode": 72202,
  "City": "Nagold",
  "Latitude": "48.548.875",
  "Longitude": "08.726.787",
  "Telephone": "074523562",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.548875,8.726787",
  "id": "48.548875,8.726787"
}, {
  "Brand": "ARAL",
  "Street": "Groß-Umstädter Straße 1",
  "PostCode": 64807,
  "City": "Dieburg",
  "Latitude": "49.899.100",
  "Longitude": "08.846.725",
  "Telephone": "06071499697",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.8991,8.846725",
  "id": "49.8991,8.846725"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 90",
  "PostCode": 76669,
  "City": "Bad Schönborn",
  "Latitude": "49.200.582",
  "Longitude": "08.646.234",
  "Telephone": "072539609997",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.200582,8.646234",
  "id": "49.200582,8.646234"
}, {
  "Brand": "ARAL",
  "Street": "Lange Straße 122",
  "PostCode": 76530,
  "City": "Baden-Baden",
  "Latitude": "48.770.545",
  "Longitude": "08.228.437",
  "Telephone": "0722125331",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.770545,8.228437",
  "id": "48.770545,8.228437"
}, {
  "Brand": "ARAL",
  "Street": "Baumholderstraße 47-51",
  "PostCode": 66629,
  "City": "Freisen",
  "Latitude": "49.554.605",
  "Longitude": "07.256.143",
  "Telephone": "06855920017",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.554605,7.256143",
  "id": "49.554605,7.256143"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 73",
  "PostCode": 67734,
  "City": "Katzweiler",
  "Latitude": "49.504.651",
  "Longitude": "07.692.398",
  "Telephone": "063019140",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "49.504651,7.692398",
  "id": "49.504651,7.692398"
}, {
  "Brand": "ARAL",
  "Street": "Kaiserstraße 1 E",
  "PostCode": 66459,
  "City": "Kirkel",
  "Latitude": "49.289.744",
  "Longitude": "07.238.459",
  "Telephone": "06849551",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:30",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.289744,7.238459",
  "id": "49.289744,7.238459"
}, {
  "Brand": "ARAL",
  "Street": "Brandenburger Str. 13-15",
  "PostCode": 68309,
  "City": "Mannheim",
  "Latitude": "49.512.068",
  "Longitude": "08.529.542",
  "Telephone": "0621706602",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.512068,8.529542",
  "id": "49.512068,8.529542"
}, {
  "Brand": "ARAL",
  "Street": "Richard-Müller-Straße 7 a",
  "PostCode": 67823,
  "City": "Obermoschel",
  "Latitude": "49.729.474",
  "Longitude": "07.776.979",
  "Telephone": "06362993170",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.729474,7.776979",
  "id": "49.729474,7.776979"
}, {
  "Brand": "ARAL",
  "Street": "Feldmannstraße 69-73",
  "PostCode": 66119,
  "City": "Saarbrücken",
  "Latitude": "49.223.519",
  "Longitude": "06.993.105",
  "Telephone": "068156485",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.223519,6.993105",
  "id": "49.223519,6.993105"
}, {
  "Brand": "ARAL",
  "Street": "Ensheimer Straße 152",
  "PostCode": 66386,
  "City": "St. Ingbert",
  "Latitude": "49.263.135",
  "Longitude": "07.102.876",
  "Telephone": "068946874",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.263135,7.102876",
  "id": "49.263135,7.102876"
}, {
  "Brand": "ARAL",
  "Street": "Neues Sträßel 4",
  "PostCode": 69168,
  "City": "Wiesloch",
  "Latitude": "49.289.530",
  "Longitude": "08.678.427",
  "Telephone": "062221038",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.28953,8.678427",
  "id": "49.28953,8.678427"
}, {
  "Brand": "ARAL",
  "Street": "Fautenbacher Straße 22",
  "PostCode": 77855,
  "City": "Achern",
  "Latitude": "48.628.514",
  "Longitude": "08.063.374",
  "Telephone": "0784124099",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Ja",
  "location": "48.628514,8.063374",
  "id": "48.628514,8.063374"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 114",
  "PostCode": 77833,
  "City": "Ottersweier",
  "Latitude": "48.673.590",
  "Longitude": "08.120.672",
  "Telephone": "072232818707",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.67359,8.120672",
  "id": "48.67359,8.120672"
}, {
  "Brand": "ARAL",
  "Street": "Strassburger Straße 11a",
  "PostCode": 77694,
  "City": "Kehl",
  "Latitude": "48.576.097",
  "Longitude": "07.818.007",
  "Telephone": "07851958081",
  "WeekDayFrom": "03:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "03:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "03:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "48.576097,7.818007",
  "id": "48.576097,7.818007"
}, {
  "Brand": "ARAL",
  "Street": "A5",
  "PostCode": 77767,
  "City": "Appenweier",
  "Latitude": "48.563.791",
  "Longitude": "07.956.917",
  "Telephone": "078053402",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.563791,7.956917",
  "id": "48.563791,7.956917"
}, {
  "Brand": "ARAL",
  "Street": "Spießstraße 2",
  "PostCode": 66892,
  "City": "Bruchmühlbach-Miesau",
  "Latitude": "49.392.177",
  "Longitude": "07.437.914",
  "Telephone": "06372993290",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.392177,7.437914",
  "id": "49.392177,7.437914"
}, {
  "Brand": "ARAL",
  "Street": "Saarlandstraße 2",
  "PostCode": 66482,
  "City": "Zweibrücken",
  "Latitude": "49.249.568",
  "Longitude": "07.370.192",
  "Telephone": "06332472572",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.249568,7.370192",
  "id": "49.249568,7.370192"
}, {
  "Brand": "ARAL",
  "Street": "Am Götzenberg 13",
  "PostCode": 69126,
  "City": "Heidelberg",
  "Latitude": "49.370.401",
  "Longitude": "08.701.226",
  "Telephone": "06221383813",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.370401,8.701226",
  "id": "49.370401,8.701226"
}, {
  "Brand": "ARAL",
  "Street": "A81",
  "PostCode": 78661,
  "City": "Dietingen",
  "Latitude": "48.206.218",
  "Longitude": "08.622.761",
  "Telephone": "07417453",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.206218,8.622761",
  "id": "48.206218,8.622761"
}, {
  "Brand": "ARAL",
  "Street": "Lochäckerstraße / B 294",
  "PostCode": 75177,
  "City": "Pforzheim",
  "Latitude": "48.910.001",
  "Longitude": "08.718.911",
  "Telephone": "072315660680",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.910001,8.718911",
  "id": "48.910001,8.718911"
}, {
  "Brand": "ARAL",
  "Street": "Rosenheimer Straße 138",
  "PostCode": 81669,
  "City": "München",
  "Latitude": "48.119.206",
  "Longitude": "11.607.772",
  "Telephone": "089401258",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.119206,11.607772",
  "id": "48.119206,11.607772"
}, {
  "Brand": "ARAL",
  "Street": "Münchener Straße 7",
  "PostCode": 83512,
  "City": "Wasserburg",
  "Latitude": "48.061.268",
  "Longitude": "12.209.808",
  "Telephone": "080719225808",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.061268,12.209808",
  "id": "48.061268,12.209808"
}, {
  "Brand": "ARAL",
  "Street": "Münchener Straße 13",
  "PostCode": 82054,
  "City": "Sauerlach",
  "Latitude": "47.973.675",
  "Longitude": "11.653.654",
  "Telephone": "081049756",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.973675,11.653654",
  "id": "47.973675,11.653654"
}, {
  "Brand": "ARAL",
  "Street": "Lochhausener Straße 229",
  "PostCode": 81249,
  "City": "München",
  "Latitude": "48.178.951",
  "Longitude": "11.405.146",
  "Telephone": "08989711640",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.178951,11.405146",
  "id": "48.178951,11.405146"
}, {
  "Brand": "ARAL",
  "Street": "An der B20 Nr.2",
  "PostCode": 83404,
  "City": "Ainring",
  "Latitude": "47.805.758",
  "Longitude": "12.957.121",
  "Telephone": "086547725240",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "05:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.805758,12.957121",
  "id": "47.805758,12.957121"
}, {
  "Brand": "ARAL",
  "Street": "Riemer Straße 360",
  "PostCode": 81829,
  "City": "München",
  "Latitude": "48.140.631",
  "Longitude": "11.683.916",
  "Telephone": "089907116",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.140631,11.683916",
  "id": "48.140631,11.683916"
}, {
  "Brand": "ARAL",
  "Street": "Allgäuer Straße 34",
  "PostCode": 87700,
  "City": "Memmingen",
  "Latitude": "47.972.288",
  "Longitude": "10.182.645",
  "Telephone": "0833189251",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.972288,10.182645",
  "id": "47.972288,10.182645"
}, {
  "Brand": "ARAL",
  "Street": "Aiblinger Au 52 a",
  "PostCode": 83059,
  "City": "Kolbermoor",
  "Latitude": "47.851.146",
  "Longitude": "12.017.452",
  "Telephone": "080614695",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.851146,12.017452",
  "id": "47.851146,12.017452"
}, {
  "Brand": "ARAL",
  "Street": "Kufsteiner Straße 57",
  "PostCode": 83022,
  "City": "Rosenheim",
  "Latitude": "47.848.767",
  "Longitude": "12.127.539",
  "Telephone": "0803113256",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.848767,12.127539",
  "id": "47.848767,12.127539"
}, {
  "Brand": "ARAL",
  "Street": "Schongauer Straße 19",
  "PostCode": 86971,
  "City": "Peiting",
  "Latitude": "47.798.668",
  "Longitude": "10.921.203",
  "Telephone": "08861693041",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.798668,10.921203",
  "id": "47.798668,10.921203"
}, {
  "Brand": "ARAL",
  "Street": "Augsburger Straße 25",
  "PostCode": 86477,
  "City": "Adelsried",
  "Latitude": "48.423.658",
  "Longitude": "10.720.173",
  "Telephone": "082948607430",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:30",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.423658,10.720173",
  "id": "48.423658,10.720173"
}, {
  "Brand": "ARAL",
  "Street": "Schleißheimer Straße 257",
  "PostCode": 80809,
  "City": "München",
  "Latitude": "48.178.788",
  "Longitude": "11.567.097",
  "Telephone": "0893508370",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.178788,11.567097",
  "id": "48.178788,11.567097"
}, {
  "Brand": "ARAL",
  "Street": "Augsburger Straße 66",
  "PostCode": 86830,
  "City": "Schwabmünchen",
  "Latitude": "48.193.657",
  "Longitude": "10.754.610",
  "Telephone": "082324790",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.193657,10.75461",
  "id": "48.193657,10.75461"
}, {
  "Brand": "ARAL",
  "Street": "Münchener Straße 42",
  "PostCode": 82131,
  "City": "Gauting",
  "Latitude": "48.067.952",
  "Longitude": "11.387.237",
  "Telephone": "08996031470",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.067952,11.387237",
  "id": "48.067952,11.387237"
}, {
  "Brand": "ARAL",
  "Street": "Münchner Straße 10",
  "PostCode": 85635,
  "City": "Höhenkirchen-Siegert",
  "Latitude": "48.019.726",
  "Longitude": "11.709.872",
  "Telephone": "081028284",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.019726,11.709872",
  "id": "48.019726,11.709872"
}, {
  "Brand": "ARAL",
  "Street": "Salzburger Straße 49",
  "PostCode": 83329,
  "City": "Waging",
  "Latitude": "47.934.114",
  "Longitude": "12.741.812",
  "Telephone": "086814379",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.934114,12.741812",
  "id": "47.934114,12.741812"
}, {
  "Brand": "ARAL",
  "Street": "Haunstetter Straße 139 1/",
  "PostCode": 86161,
  "City": "Augsburg",
  "Latitude": "48.333.732",
  "Longitude": "10.908.119",
  "Telephone": "0821573303",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.333732,10.908119",
  "id": "48.333732,10.908119"
}, {
  "Brand": "ARAL",
  "Street": "Garmischer Straße 197",
  "PostCode": 81377,
  "City": "München",
  "Latitude": "48.121.346",
  "Longitude": "11.518.817",
  "Telephone": "0897608904",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.121346,11.518817",
  "id": "48.121346,11.518817"
}, {
  "Brand": "ARAL",
  "Street": "Schongauer Straße 7",
  "PostCode": 86899,
  "City": "Landsberg",
  "Latitude": "48.038.910",
  "Longitude": "10.864.785",
  "Telephone": "081912727",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.03891,10.864785",
  "id": "48.03891,10.864785"
}, {
  "Brand": "ARAL",
  "Street": "Plinganser Str. 70",
  "PostCode": 81369,
  "City": "München",
  "Latitude": "48.112.274",
  "Longitude": "11.539.182",
  "Telephone": "089761566",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.112274,11.539182",
  "id": "48.112274,11.539182"
}, {
  "Brand": "ARAL",
  "Street": "Münchener Straße 66",
  "PostCode": 87700,
  "City": "Memmingen",
  "Latitude": "47.996.337",
  "Longitude": "10.199.143",
  "Telephone": "083315724",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.996337,10.199143",
  "id": "47.996337,10.199143"
}, {
  "Brand": "ARAL",
  "Street": "Untere Hauptstraße 11",
  "PostCode": 85386,
  "City": "Eching",
  "Latitude": "48.298.179",
  "Longitude": "11.625.084",
  "Telephone": "0893192811",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.298179,11.625084",
  "id": "48.298179,11.625084"
}, {
  "Brand": "ARAL",
  "Street": "Manchinger Straße 76",
  "PostCode": 85053,
  "City": "Ingolstadt",
  "Latitude": "48.753.933",
  "Longitude": "11.449.887",
  "Telephone": "084168555",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.753933,11.449887",
  "id": "48.753933,11.449887"
}, {
  "Brand": "ARAL",
  "Street": "Wasserburger Landstr. 51",
  "PostCode": 81825,
  "City": "München",
  "Latitude": "48.119.722",
  "Longitude": "11.677.734",
  "Telephone": "089424843",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.119722,11.677734",
  "id": "48.119722,11.677734"
}, {
  "Brand": "ARAL",
  "Street": "Rosenheimer Straße 83",
  "PostCode": 83064,
  "City": "Raubling",
  "Latitude": "47.804.791",
  "Longitude": "12.119.877",
  "Telephone": "080352766",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.804791,12.119877",
  "id": "47.804791,12.119877"
}, {
  "Brand": "ARAL",
  "Street": "Grüneckerstraße 6",
  "PostCode": 85375,
  "City": "Neufahrn",
  "Latitude": "48.313.086",
  "Longitude": "11.665.198",
  "Telephone": "081654610",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.313086,11.665198",
  "id": "48.313086,11.665198"
}, {
  "Brand": "ARAL",
  "Street": "Salzburger Straße 64",
  "PostCode": 83071,
  "City": "Stephanskirchen",
  "Latitude": "47.860.393",
  "Longitude": "12.154.340",
  "Telephone": "0803171081",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.860393,12.15434",
  "id": "47.860393,12.15434"
}, {
  "Brand": "ARAL",
  "Street": "Niedermayerstraße 54",
  "PostCode": 84036,
  "City": "Landshut",
  "Latitude": "48.546.207",
  "Longitude": "12.181.083",
  "Telephone": "087152916",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.546207,12.181083",
  "id": "48.546207,12.181083"
}, {
  "Brand": "ARAL",
  "Street": "Augsburger Straße 32",
  "PostCode": 87600,
  "City": "Kaufbeuren",
  "Latitude": "47.884.540",
  "Longitude": "10.636.712",
  "Telephone": "083414859",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.88454,10.636712",
  "id": "47.88454,10.636712"
}, {
  "Brand": "ARAL",
  "Street": "Landshuter Allee 163",
  "PostCode": 80637,
  "City": "München",
  "Latitude": "48.163.555",
  "Longitude": "11.537.343",
  "Telephone": "089156225",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.163555,11.537343",
  "id": "48.163555,11.537343"
}, {
  "Brand": "ARAL",
  "Street": "Miesbacher Straße 33",
  "PostCode": 83607,
  "City": "Holzkirchen",
  "Latitude": "47.875.805",
  "Longitude": "11.710.166",
  "Telephone": "08024998184",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.875805,11.710166",
  "id": "47.875805,11.710166"
}, {
  "Brand": "ARAL",
  "Street": "Augsburger Straße 42",
  "PostCode": 86381,
  "City": "Krumbach",
  "Latitude": "48.243.901",
  "Longitude": "10.372.405",
  "Telephone": "082823059",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "48.243901,10.372405",
  "id": "48.243901,10.372405"
}, {
  "Brand": "ARAL",
  "Street": "Loferer Straße 29",
  "PostCode": 83435,
  "City": "Bad Reichenhall",
  "Latitude": "47.733.864",
  "Longitude": "12.877.083",
  "Telephone": "086512325",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.733864,12.877083",
  "id": "47.733864,12.877083"
}, {
  "Brand": "ARAL",
  "Street": "Donauwörther Straße 15",
  "PostCode": 86462,
  "City": "Langweid",
  "Latitude": "48.465.298",
  "Longitude": "10.866.053",
  "Telephone": "0821492625",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "48.465298,10.866053",
  "id": "48.465298,10.866053"
}, {
  "Brand": "ARAL",
  "Street": "Leopoldstraße 208",
  "PostCode": 80804,
  "City": "München",
  "Latitude": "48.175.327",
  "Longitude": "11.585.965",
  "Telephone": "089363171",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.175327,11.585965",
  "id": "48.175327,11.585965"
}, {
  "Brand": "ARAL",
  "Street": "A9",
  "PostCode": 85301,
  "City": "Geisenhausen",
  "Latitude": "48.556.550",
  "Longitude": "11.587.913",
  "Telephone": "08441801573",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.55655,11.587913",
  "id": "48.55655,11.587913"
}, {
  "Brand": "ARAL",
  "Street": "A9",
  "PostCode": 85120,
  "City": "Hepberg",
  "Latitude": "48.836.148",
  "Longitude": "11.471.164",
  "Telephone": "084051336",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.836148,11.471164",
  "id": "48.836148,11.471164"
}, {
  "Brand": "ARAL",
  "Street": "Schenkendorfstraße 15",
  "PostCode": 80807,
  "City": "München",
  "Latitude": "48.177.293",
  "Longitude": "11.589.353",
  "Telephone": "089364016",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.177293,11.589353",
  "id": "48.177293,11.589353"
}, {
  "Brand": "ARAL",
  "Street": "Schumacherring 110",
  "PostCode": 87437,
  "City": "Kempten",
  "Latitude": "47.733.469",
  "Longitude": "10.325.349",
  "Telephone": "083173262",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.733469,10.325349",
  "id": "47.733469,10.325349"
}, {
  "Brand": "ARAL",
  "Street": "Rosenheimer Straße 30 a",
  "PostCode": 83059,
  "City": "Kolbermoor",
  "Latitude": "47.849.876",
  "Longitude": "12.067.706",
  "Telephone": "0803191354",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.849876,12.067706",
  "id": "47.849876,12.067706"
}, {
  "Brand": "ARAL",
  "Street": "Boschetsrieder Straße 127",
  "PostCode": 81379,
  "City": "München",
  "Latitude": "48.098.463",
  "Longitude": "11.518.578",
  "Telephone": "089786153",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.098463,11.518578",
  "id": "48.098463,11.518578"
}, {
  "Brand": "ARAL",
  "Street": "Dülferstraße 3",
  "PostCode": 80933,
  "City": "München",
  "Latitude": "48.212.649",
  "Longitude": "11.560.139",
  "Telephone": "08931207480",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "48.212649,11.560139",
  "id": "48.212649,11.560139"
}, {
  "Brand": "ARAL",
  "Street": "Heinrich-Wieland-Straße 2",
  "PostCode": 81735,
  "City": "München",
  "Latitude": "48.118.328",
  "Longitude": "11.631.080",
  "Telephone": "089401320",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.118328,11.63108",
  "id": "48.118328,11.63108"
}, {
  "Brand": "ARAL",
  "Street": "Tegernseer Landstraße 174",
  "PostCode": 81539,
  "City": "München",
  "Latitude": "48.107.742",
  "Longitude": "11.579.532",
  "Telephone": "0896202810",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.107742,11.579532",
  "id": "48.107742,11.579532"
}, {
  "Brand": "ARAL",
  "Street": "Äussere Rosenheimerstr23",
  "PostCode": 83278,
  "City": "Traunstein",
  "Latitude": "47.867.060",
  "Longitude": "12.633.873",
  "Telephone": "08613132",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "05:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "47.86706,12.633873",
  "id": "47.86706,12.633873"
}, {
  "Brand": "ARAL",
  "Street": "Wasserburger Landstraße 3",
  "PostCode": 85591,
  "City": "Vaterstetten",
  "Latitude": "48.096.269",
  "Longitude": "11.771.361",
  "Telephone": "08106307446",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.096269,11.771361",
  "id": "48.096269,11.771361"
}, {
  "Brand": "ARAL",
  "Street": "Landshuter Straße 9",
  "PostCode": 84137,
  "City": "Vilsbiburg",
  "Latitude": "48.448.663",
  "Longitude": "12.351.033",
  "Telephone": "08741926794",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.448663,12.351033",
  "id": "48.448663,12.351033"
}, {
  "Brand": "ARAL",
  "Street": "Gilchingerstraße 31",
  "PostCode": 82239,
  "City": "Alling",
  "Latitude": "48.138.099",
  "Longitude": "11.299.389",
  "Telephone": "0814171979",
  "WeekDayFrom": "06:45",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "19:00",
  "SundayFrom": "08:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "48.138099,11.299389",
  "id": "48.138099,11.299389"
}, {
  "Brand": "ARAL",
  "Street": "Kemptener Straße 43",
  "PostCode": 87452,
  "City": "Altusried",
  "Latitude": "47.801.887",
  "Longitude": "10.225.011",
  "Telephone": "08373935375",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "47.801887,10.225011",
  "id": "47.801887,10.225011"
}, {
  "Brand": "ARAL",
  "Street": "Stätzlinger Straße 86",
  "PostCode": 86165,
  "City": "Augsburg",
  "Latitude": "48.378.201",
  "Longitude": "10.937.471",
  "Telephone": "0821713575",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "48.378201,10.937471",
  "id": "48.378201,10.937471"
}, {
  "Brand": "ARAL",
  "Street": "Sachsenkamer Straße 23",
  "PostCode": 83646,
  "City": "Bad Tölz",
  "Latitude": "47.759.593",
  "Longitude": "11.573.566",
  "Telephone": "080415303",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.759593,11.573566",
  "id": "47.759593,11.573566"
}, {
  "Brand": "ARAL",
  "Street": "Kemptener Straße 23",
  "PostCode": 87634,
  "City": "Obergünzburg",
  "Latitude": "47.843.043",
  "Longitude": "10.416.526",
  "Telephone": "08372496",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.843043,10.416526",
  "id": "47.843043,10.416526"
}, {
  "Brand": "ARAL",
  "Street": "Allgäuerstraße 39",
  "PostCode": 87459,
  "City": "Pfronten",
  "Latitude": "47.584.624",
  "Longitude": "10.559.278",
  "Telephone": "08363296",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.584624,10.559278",
  "id": "47.584624,10.559278"
}, {
  "Brand": "ARAL",
  "Street": "Freischützstraße 4",
  "PostCode": 81927,
  "City": "München",
  "Latitude": "48.157.569",
  "Longitude": "11.642.239",
  "Telephone": "089935312",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.157569,11.642239",
  "id": "48.157569,11.642239"
}, {
  "Brand": "ARAL",
  "Street": "Münchener Straße 16",
  "PostCode": 85540,
  "City": "Haar",
  "Latitude": "48.108.538",
  "Longitude": "11.722.106",
  "Telephone": "089467300",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.108538,11.722106",
  "id": "48.108538,11.722106"
}, {
  "Brand": "ARAL",
  "Street": "Georg-Brauchle-Ring 27",
  "PostCode": 80992,
  "City": "München",
  "Latitude": "48.176.104",
  "Longitude": "11.535.616",
  "Telephone": "089155116",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.176104,11.535616",
  "id": "48.176104,11.535616"
}, {
  "Brand": "ARAL",
  "Street": "Richard Strauss-Straße 70",
  "PostCode": 81679,
  "City": "München",
  "Latitude": "48.145.718",
  "Longitude": "11.616.339",
  "Telephone": "089915079",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.145718,11.616339",
  "id": "48.145718,11.616339"
}, {
  "Brand": "ARAL",
  "Street": "Isarstraße 3",
  "PostCode": 85356,
  "City": "Freising",
  "Latitude": "48.397.459",
  "Longitude": "11.759.290",
  "Telephone": "0816183048",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.397459,11.75929",
  "id": "48.397459,11.75929"
}, {
  "Brand": "ARAL",
  "Street": "Josef-Greschniok-Straße 1",
  "PostCode": 94051,
  "City": "Hauzenberg",
  "Latitude": "48.654.014",
  "Longitude": "13.620.601",
  "Telephone": "085861342",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.654014,13.620601",
  "id": "48.654014,13.620601"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 20",
  "PostCode": 82467,
  "City": "Garmisch-Partenkirch",
  "Latitude": "47.500.453",
  "Longitude": "11.101.443",
  "Telephone": "0882152733",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:30",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "05:30",
  "SundayTo": "22:30",
  "IsOpen24Hours": "Nein",
  "location": "47.500453,11.101443",
  "id": "47.500453,11.101443"
}, {
  "Brand": "ARAL",
  "Street": "Bernecker Straße 49",
  "PostCode": 95448,
  "City": "Bayreuth",
  "Latitude": "49.954.797",
  "Longitude": "11.600.094",
  "Telephone": "092113046",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.954797,11.600094",
  "id": "49.954797,11.600094"
}, {
  "Brand": "ARAL",
  "Street": "Oberstenfelder Straße 24",
  "PostCode": 71717,
  "City": "Beilstein",
  "Latitude": "49.034.816",
  "Longitude": "09.316.273",
  "Telephone": "070625670",
  "WeekDayFrom": "08:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "08:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "49.034816,9.316273",
  "id": "49.034816,9.316273"
}, {
  "Brand": "ARAL",
  "Street": "Bärnzeller Straße 5",
  "PostCode": 94227,
  "City": "Zwiesel",
  "Latitude": "49.001.061",
  "Longitude": "13.220.335",
  "Telephone": "09922802644",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.001061,13.220335",
  "id": "49.001061,13.220335"
}, {
  "Brand": "ARAL",
  "Street": "Teplitzer Straße 24",
  "PostCode": 84478,
  "City": "Waldkraiburg",
  "Latitude": "48.211.636",
  "Longitude": "12.417.323",
  "Telephone": "086384185",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.211636,12.417323",
  "id": "48.211636,12.417323"
}, {
  "Brand": "ARAL",
  "Street": "Passauer Straße 39",
  "PostCode": 94081,
  "City": "Fürstenzell",
  "Latitude": "48.525.187",
  "Longitude": "13.327.044",
  "Telephone": "085021313",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "05:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.525187,13.327044",
  "id": "48.525187,13.327044"
}, {
  "Brand": "ARAL",
  "Street": "Gleiwitzer Straße 8",
  "PostCode": 93073,
  "City": "Neutraubling",
  "Latitude": "48.988.226",
  "Longitude": "12.189.362",
  "Telephone": "0940179060",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.988226,12.189362",
  "id": "48.988226,12.189362"
}, {
  "Brand": "ARAL",
  "Street": "Ingolstädter Str. 164-166",
  "PostCode": 80939,
  "City": "München",
  "Latitude": "48.201.777",
  "Longitude": "11.586.149",
  "Telephone": "08931699843",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.201777,11.586149",
  "id": "48.201777,11.586149"
}, {
  "Brand": "ARAL",
  "Street": "Hofer Straße 2 / B 303",
  "PostCode": 95502,
  "City": "Himmelkron",
  "Latitude": "50.054.613",
  "Longitude": "11.622.785",
  "Telephone": "0927357337",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.054613,11.622785",
  "id": "50.054613,11.622785"
}, {
  "Brand": "ARAL",
  "Street": "Freisinger Straße 21",
  "PostCode": 84048,
  "City": "Mainburg",
  "Latitude": "48.635.765",
  "Longitude": "11.779.198",
  "Telephone": "087515838",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Ja",
  "location": "48.635765,11.779198",
  "id": "48.635765,11.779198"
}, {
  "Brand": "ARAL",
  "Street": "Heinrich-Lanz-Straße 1",
  "PostCode": 70825,
  "City": "Korntal-Münchingen",
  "Latitude": "48.850.588",
  "Longitude": "09.105.471",
  "Telephone": "07150959920",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.850588,9.105471",
  "id": "48.850588,9.105471"
}, {
  "Brand": "ARAL",
  "Street": "Elbestraße 23",
  "PostCode": 82538,
  "City": "Geretsried",
  "Latitude": "47.880.446",
  "Longitude": "11.460.822",
  "Telephone": "0817164255",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.880446,11.460822",
  "id": "47.880446,11.460822"
}, {
  "Brand": "ARAL",
  "Street": "Forstenrieder Straße 26",
  "PostCode": 82061,
  "City": "Neuried",
  "Latitude": "48.091.406",
  "Longitude": "11.468.299",
  "Telephone": "08975070710",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.091406,11.468299",
  "id": "48.091406,11.468299"
}, {
  "Brand": "ARAL",
  "Street": "Sigwolfstraße 2",
  "PostCode": 85435,
  "City": "Erding",
  "Latitude": "48.314.870",
  "Longitude": "11.892.567",
  "Telephone": "08122902786",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.31487,11.892567",
  "id": "48.31487,11.892567"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 60",
  "PostCode": 74360,
  "City": "Ilsfeld",
  "Latitude": "49.058.882",
  "Longitude": "09.271.201",
  "Telephone": "0706295220",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.058882,9.271201",
  "id": "49.058882,9.271201"
}, {
  "Brand": "ARAL",
  "Street": "Gottlieb-Daimler-Straße 4",
  "PostCode": 95666,
  "City": "Mitterteich",
  "Latitude": "49.936.710",
  "Longitude": "12.227.068",
  "Telephone": "096334000029",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.93671,12.227068",
  "id": "49.93671,12.227068"
}, {
  "Brand": "ARAL",
  "Street": "Landshuter Straße 42",
  "PostCode": 85368,
  "City": "Moosburg",
  "Latitude": "48.463.953",
  "Longitude": "11.939.814",
  "Telephone": "08761729846",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.463953,11.939814",
  "id": "48.463953,11.939814"
}, {
  "Brand": "ARAL",
  "Street": "Pragstraße 138 A",
  "PostCode": 70376,
  "City": "Stuttgart",
  "Latitude": "48.807.538",
  "Longitude": "09.194.154",
  "Telephone": "07118560500",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.807538,9.194154",
  "id": "48.807538,9.194154"
}, {
  "Brand": "ARAL",
  "Street": "Stuttgarter Straße 119",
  "PostCode": 73054,
  "City": "Eislingen",
  "Latitude": "48.696.419",
  "Longitude": "09.690.237",
  "Telephone": "07161990220",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.696419,9.690237",
  "id": "48.696419,9.690237"
}, {
  "Brand": "ARAL",
  "Street": "Plieninger Straße 35",
  "PostCode": 73765,
  "City": "Neuhausen",
  "Latitude": "48.691.251",
  "Longitude": "09.265.736",
  "Telephone": "07158940101",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.691251,9.265736",
  "id": "48.691251,9.265736"
}, {
  "Brand": "ARAL",
  "Street": "Laimeringer Straße 2",
  "PostCode": 86453,
  "City": "Dasing",
  "Latitude": "48.389.962",
  "Longitude": "11.063.402",
  "Telephone": "08205969083",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.389962,11.063402",
  "id": "48.389962,11.063402"
}, {
  "Brand": "ARAL",
  "Street": "Enkeringer Straße 9",
  "PostCode": 85125,
  "City": "Kinding",
  "Latitude": "48.998.172",
  "Longitude": "11.377.887",
  "Telephone": "08467805892",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.998172,11.377887",
  "id": "48.998172,11.377887"
}, {
  "Brand": "ARAL",
  "Street": "Zadarstrasse 11",
  "PostCode": 82256,
  "City": "Fürstenfeldbruck",
  "Latitude": "48.177.534",
  "Longitude": "11.227.286",
  "Telephone": "08141355400",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.177534,11.227286",
  "id": "48.177534,11.227286"
}, {
  "Brand": "ARAL",
  "Street": "Alte Römerstraße 50",
  "PostCode": 85221,
  "City": "Dachau",
  "Latitude": "48.259.686",
  "Longitude": "11.469.937",
  "Telephone": "08131333352",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.259686,11.469937",
  "id": "48.259686,11.469937"
}, {
  "Brand": "ARAL",
  "Street": "Friedrichstraße 160",
  "PostCode": 71638,
  "City": "Ludwigsburg",
  "Latitude": "48.888.661",
  "Longitude": "09.213.550",
  "Telephone": "07141890454",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.888661,9.21355",
  "id": "48.888661,9.21355"
}, {
  "Brand": "ARAL",
  "Street": "Heilbronner Straße 289",
  "PostCode": 70469,
  "City": "Stuttgart",
  "Latitude": "48.812.611",
  "Longitude": "09.179.996",
  "Telephone": "07118560056",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.812611,9.179996",
  "id": "48.812611,9.179996"
}, {
  "Brand": "ARAL",
  "Street": "Binderfeld 2",
  "PostCode": 84518,
  "City": "Garching",
  "Latitude": "48.125.935",
  "Longitude": "12.574.169",
  "Telephone": "08634400",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.125935,12.574169",
  "id": "48.125935,12.574169"
}, {
  "Brand": "ARAL",
  "Street": "A8",
  "PostCode": 71067,
  "City": "Sindelfingen",
  "Latitude": "48.742.578",
  "Longitude": "09.034.182",
  "Telephone": "0703170810",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.742578,9.034182",
  "id": "48.742578,9.034182"
}, {
  "Brand": "ARAL",
  "Street": "Münchener Straße 43",
  "PostCode": 83707,
  "City": "Bad Wiessee",
  "Latitude": "47.717.837",
  "Longitude": "11.718.955",
  "Telephone": "08022857950",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "47.717837,11.718955",
  "id": "47.717837,11.718955"
}, {
  "Brand": "ARAL",
  "Street": "Nördlinger Straße 18-22",
  "PostCode": 73450,
  "City": "Neresheim",
  "Latitude": "48.754.295",
  "Longitude": "10.336.607",
  "Telephone": "073269644533",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.754295,10.336607",
  "id": "48.754295,10.336607"
}, {
  "Brand": "ARAL",
  "Street": "Schwarzleite 2",
  "PostCode": 91220,
  "City": "Schnaittach",
  "Latitude": "49.567.291",
  "Longitude": "11.327.495",
  "Telephone": "091539229892",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.567291,11.327495",
  "id": "49.567291,11.327495"
}, {
  "Brand": "ARAL",
  "Street": "Weißenbachstr. 2",
  "PostCode": 87730,
  "City": "Bad Grönenbach",
  "Latitude": "47.883.913",
  "Longitude": "10.248.217",
  "Telephone": "08334986108",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.883913,10.248217",
  "id": "47.883913,10.248217"
}, {
  "Brand": "ARAL",
  "Street": "Am Südpark 2",
  "PostCode": 86633,
  "City": "Neuburg",
  "Latitude": "48.720.188",
  "Longitude": "11.172.039",
  "Telephone": "08431430346",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.720188,11.172039",
  "id": "48.720188,11.172039"
}, {
  "Brand": "ARAL",
  "Street": "Max-Planck-Straße 8",
  "PostCode": 93055,
  "City": "Regensburg",
  "Latitude": "49.000.164",
  "Longitude": "12.148.321",
  "Telephone": "09417995752",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.000164,12.148321",
  "id": "49.000164,12.148321"
}, {
  "Brand": "ARAL",
  "Street": "Linderhof 8",
  "PostCode": 88079,
  "City": "Kressbronn",
  "Latitude": "47.607.814",
  "Longitude": "09.586.966",
  "Telephone": "07543953347",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "05:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.607814,9.586966",
  "id": "47.607814,9.586966"
}, {
  "Brand": "ARAL",
  "Street": "Arnbrucker Straße 1 a",
  "PostCode": 93444,
  "City": "Bad Kötzting",
  "Latitude": "49.171.260",
  "Longitude": "12.863.188",
  "Telephone": "09941904901",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.17126,12.863188",
  "id": "49.17126,12.863188"
}, {
  "Brand": "ARAL",
  "Street": "Gewerbepark Spörer Au 1",
  "PostCode": 85368,
  "City": "Wang bei Moosburg",
  "Latitude": "48.482.743",
  "Longitude": "12.000.579",
  "Telephone": "08709263168",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.482743,12.000579",
  "id": "48.482743,12.000579"
}, {
  "Brand": "ARAL",
  "Street": "Aindlinger Strasse 11",
  "PostCode": 86167,
  "City": "Augsburg",
  "Latitude": "48.392.692",
  "Longitude": "10.933.418",
  "Telephone": "08217472450",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.392692,10.933418",
  "id": "48.392692,10.933418"
}, {
  "Brand": "ARAL",
  "Street": "Rodezstraße 2 a",
  "PostCode": 96052,
  "City": "Bamberg",
  "Latitude": "49.914.137",
  "Longitude": "10.908.761",
  "Telephone": "09513090155",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.914137,10.908761",
  "id": "49.914137,10.908761"
}, {
  "Brand": "ARAL",
  "Street": "Am Eichelberg 2",
  "PostCode": 91567,
  "City": "Herrieden",
  "Latitude": "49.258.752",
  "Longitude": "10.501.871",
  "Telephone": "09825929790",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.258752,10.501871",
  "id": "49.258752,10.501871"
}, {
  "Brand": "ARAL",
  "Street": "An der Autobahn 1",
  "PostCode": 91126,
  "City": "Kammerstein",
  "Latitude": "49.311.360",
  "Longitude": "11.002.243",
  "Telephone": "09122879822",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.31136,11.002243",
  "id": "49.31136,11.002243"
}, {
  "Brand": "ARAL",
  "Street": "Ohmstraße 1",
  "PostCode": 86415,
  "City": "Mering",
  "Latitude": "48.274.136",
  "Longitude": "10.977.898",
  "Telephone": "08233781227",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.274136,10.977898",
  "id": "48.274136,10.977898"
}, {
  "Brand": "ARAL",
  "Street": "Hochstraße12",
  "PostCode": 91325,
  "City": "Adelsdorf",
  "Latitude": "49.703.501",
  "Longitude": "10.881.012",
  "Telephone": "09195925772",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.703501,10.881012",
  "id": "49.703501,10.881012"
}, {
  "Brand": "ARAL",
  "Street": "Gruber Straße 22",
  "PostCode": 96271,
  "City": "Grub am Forst",
  "Latitude": "50.222.629",
  "Longitude": "11.042.767",
  "Telephone": "095609816715",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.222629,11.042767",
  "id": "50.222629,11.042767"
}, {
  "Brand": "ARAL",
  "Street": "Schleißheimer Straße 124",
  "PostCode": 85748,
  "City": "Garching",
  "Latitude": "48.250.399",
  "Longitude": "11.606.700",
  "Telephone": "08932666948",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.250399,11.6067",
  "id": "48.250399,11.6067"
}, {
  "Brand": "ARAL",
  "Street": "Schroßlacher Straße 2",
  "PostCode": 85391,
  "City": "Allershausen",
  "Latitude": "48.428.426",
  "Longitude": "11.585.441",
  "Telephone": "08166993709",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.428426,11.585441",
  "id": "48.428426,11.585441"
}, {
  "Brand": "ARAL",
  "Street": "Am Jackinger Berg",
  "PostCode": 94113,
  "City": "Tiefenbach",
  "Latitude": "48.620.407",
  "Longitude": "13.416.174",
  "Telephone": "08509937774",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.620407,13.416174",
  "id": "48.620407,13.416174"
}, {
  "Brand": "ARAL",
  "Street": "Bruckerstraße 34 a",
  "PostCode": 82266,
  "City": "Inning",
  "Latitude": "48.088.772",
  "Longitude": "11.150.987",
  "Telephone": "08143997503",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.088772,11.150987",
  "id": "48.088772,11.150987"
}, {
  "Brand": "ARAL",
  "Street": "Reichsgrafenstraße 35/A3",
  "PostCode": 94036,
  "City": "Passau",
  "Latitude": "48.587.579",
  "Longitude": "13.365.938",
  "Telephone": "085175688830",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.587579,13.365938",
  "id": "48.587579,13.365938"
}, {
  "Brand": "ARAL",
  "Street": "Kobelweg 64 1/4",
  "PostCode": 86156,
  "City": "Augsburg",
  "Latitude": "48.385.634",
  "Longitude": "10.851.188",
  "Telephone": "08212401234",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "23:00",
  "SundayFrom": "05:30",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "48.385634,10.851188",
  "id": "48.385634,10.851188"
}, {
  "Brand": "ARAL",
  "Street": "Inninger Straße 99",
  "PostCode": 86179,
  "City": "Augsburg",
  "Latitude": "48.309.544",
  "Longitude": "10.890.455",
  "Telephone": "08216084769",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.309544,10.890455",
  "id": "48.309544,10.890455"
}, {
  "Brand": "ARAL",
  "Street": "A3",
  "PostCode": 97084,
  "City": "Würzburg",
  "Latitude": "49.752.156",
  "Longitude": "09.962.798",
  "Telephone": "093165907",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.752156,9.962798",
  "id": "49.752156,9.962798"
}, {
  "Brand": "ARAL",
  "Street": "Pingsdorfer Straße 106",
  "PostCode": 50321,
  "City": "Brühl",
  "Latitude": "50.820.612",
  "Longitude": "06.895.321",
  "Telephone": "02232941130",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.820612,6.895321",
  "id": "50.820612,6.895321"
}, {
  "Brand": "ARAL",
  "Street": "Neusser Straße 45",
  "PostCode": 52428,
  "City": "Jülich",
  "Latitude": "50.928.046",
  "Longitude": "06.365.275",
  "Telephone": "024612180",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.928046,6.365275",
  "id": "50.928046,6.365275"
}, {
  "Brand": "ARAL",
  "Street": "Aachener Straße 25",
  "PostCode": 50126,
  "City": "Bergheim",
  "Latitude": "50.950.449",
  "Longitude": "06.625.508",
  "Telephone": "0227141568",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.950449,6.625508",
  "id": "50.950449,6.625508"
}, {
  "Brand": "ARAL",
  "Street": "Schleidener Straße 152",
  "PostCode": 52076,
  "City": "Aachen",
  "Latitude": "50.706.352",
  "Longitude": "06.181.828",
  "Telephone": "024081460290",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.706352,6.181828",
  "id": "50.706352,6.181828"
}, {
  "Brand": "ARAL",
  "Street": "Aachener Straße 1100",
  "PostCode": 50858,
  "City": "Köln",
  "Latitude": "50.938.390",
  "Longitude": "06.848.411",
  "Telephone": "02234943601",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.93839,6.848411",
  "id": "50.93839,6.848411"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 275",
  "PostCode": 52379,
  "City": "Langerwehe",
  "Latitude": "50.816.628",
  "Longitude": "06.371.937",
  "Telephone": "024231745",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.816628,6.371937",
  "id": "50.816628,6.371937"
}, {
  "Brand": "ARAL",
  "Street": "Berg.-Gladbacher Str. 417",
  "PostCode": 51067,
  "City": "Köln",
  "Latitude": "50.967.794",
  "Longitude": "07.033.921",
  "Telephone": "0221632555",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.967794,7.033921",
  "id": "50.967794,7.033921"
}, {
  "Brand": "ARAL",
  "Street": "Luxemburger Straße 58",
  "PostCode": 50354,
  "City": "Hürth",
  "Latitude": "50.894.856",
  "Longitude": "06.905.027",
  "Telephone": "0223365260",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.894856,6.905027",
  "id": "50.894856,6.905027"
}, {
  "Brand": "ARAL",
  "Street": "Kölner Straße 138-166",
  "PostCode": 53902,
  "City": "Bad Münstereifel",
  "Latitude": "50.567.129",
  "Longitude": "06.764.012",
  "Telephone": "022534010",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.567129,6.764012",
  "id": "50.567129,6.764012"
}, {
  "Brand": "ARAL",
  "Street": "Geierstraße 55",
  "PostCode": 53881,
  "City": "Euskirchen",
  "Latitude": "50.629.393",
  "Longitude": "06.845.624",
  "Telephone": "022556284",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.629393,6.845624",
  "id": "50.629393,6.845624"
}, {
  "Brand": "ARAL",
  "Street": "Am Verteilerkreis Westsei",
  "PostCode": 50968,
  "City": "Köln",
  "Latitude": "50.892.089",
  "Longitude": "06.966.327",
  "Telephone": "0221381718",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.892089,6.966327",
  "id": "50.892089,6.966327"
}, {
  "Brand": "ARAL",
  "Street": "Potsdamer Platz 2",
  "PostCode": 53119,
  "City": "Bonn",
  "Latitude": "50.743.065",
  "Longitude": "07.078.459",
  "Telephone": "02287668731",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.743065,7.078459",
  "id": "50.743065,7.078459"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstr. 43",
  "PostCode": 53783,
  "City": "Eitorf",
  "Latitude": "50.773.706",
  "Longitude": "07.444.756",
  "Telephone": "022433133",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:30",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.773706,7.444756",
  "id": "50.773706,7.444756"
}, {
  "Brand": "ARAL",
  "Street": "Kölner Straße 74",
  "PostCode": 51429,
  "City": "Bergisch Gladbach",
  "Latitude": "50.962.031",
  "Longitude": "07.148.300",
  "Telephone": "0220453818",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:30",
  "SundayFrom": "07:30",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.962031,7.1483",
  "id": "50.962031,7.1483"
}, {
  "Brand": "ARAL",
  "Street": "Trierer Straße 703",
  "PostCode": 52078,
  "City": "Aachen",
  "Latitude": "50.751.980",
  "Longitude": "06.159.320",
  "Telephone": "0241522087",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.75198,6.15932",
  "id": "50.75198,6.15932"
}, {
  "Brand": "ARAL",
  "Street": "Römerstraße 102-110",
  "PostCode": 53111,
  "City": "Bonn",
  "Latitude": "50.746.575",
  "Longitude": "07.099.609",
  "Telephone": "0228636321",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.746575,7.099609",
  "id": "50.746575,7.099609"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 384",
  "PostCode": 52146,
  "City": "Würselen",
  "Latitude": "50.809.385",
  "Longitude": "06.144.159",
  "Telephone": "0240592738",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.809385,6.144159",
  "id": "50.809385,6.144159"
}, {
  "Brand": "ARAL",
  "Street": "Rottbitzer Straße 32",
  "PostCode": 53604,
  "City": "Bad Honnef",
  "Latitude": "50.646.697",
  "Longitude": "07.314.059",
  "Telephone": "0222480045",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.646697,7.314059",
  "id": "50.646697,7.314059"
}, {
  "Brand": "ARAL",
  "Street": "Kölner Straße 13",
  "PostCode": 53937,
  "City": "Schleiden",
  "Latitude": "50.570.695",
  "Longitude": "06.506.120",
  "Telephone": "024442395",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:30",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:30",
  "SundayFrom": "09:00",
  "SundayTo": "21:30",
  "IsOpen24Hours": "Nein",
  "location": "50.570695,6.50612",
  "id": "50.570695,6.50612"
}, {
  "Brand": "ARAL",
  "Street": "Altenberger Dom-Straße 2",
  "PostCode": 51519,
  "City": "Odenthal",
  "Latitude": "51.031.389",
  "Longitude": "07.111.378",
  "Telephone": "0220271845",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.031389,7.111378",
  "id": "51.031389,7.111378"
}, {
  "Brand": "ARAL",
  "Street": "Odenthaler Straße 205",
  "PostCode": 51467,
  "City": "Bergisch Gladbach",
  "Latitude": "51.000.198",
  "Longitude": "07.131.204",
  "Telephone": "0220235627",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.000198,7.131204",
  "id": "51.000198,7.131204"
}, {
  "Brand": "ARAL",
  "Street": "Königswinterer Straße 273",
  "PostCode": 53639,
  "City": "Königswinter",
  "Latitude": "50.680.975",
  "Longitude": "07.272.625",
  "Telephone": "02223900971",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.680975,7.272625",
  "id": "50.680975,7.272625"
}, {
  "Brand": "ARAL",
  "Street": "Heerstraße 88",
  "PostCode": 53894,
  "City": "Mechernich",
  "Latitude": "50.588.352",
  "Longitude": "06.647.133",
  "Telephone": "0244331365",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.588352,6.647133",
  "id": "50.588352,6.647133"
}, {
  "Brand": "ARAL",
  "Street": "Krefelder Straße 17",
  "PostCode": 52146,
  "City": "Würselen",
  "Latitude": "50.826.754",
  "Longitude": "06.128.096",
  "Telephone": "0240582575",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.826754,6.128096",
  "id": "50.826754,6.128096"
}, {
  "Brand": "ARAL",
  "Street": "Landsberger Straße 2",
  "PostCode": 53119,
  "City": "Bonn",
  "Latitude": "50.747.492",
  "Longitude": "07.067.335",
  "Telephone": "0228661806",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.747492,7.067335",
  "id": "50.747492,7.067335"
}, {
  "Brand": "ARAL",
  "Street": "Rösrather Straße 521B/ Lü",
  "PostCode": 51107,
  "City": "Köln",
  "Latitude": "50.923.607",
  "Longitude": "07.079.232",
  "Telephone": "0221868077",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.923607,7.079232",
  "id": "50.923607,7.079232"
}, {
  "Brand": "ARAL",
  "Street": "Friedrich-Ebert-Allee 97",
  "PostCode": 52066,
  "City": "Aachen",
  "Latitude": "50.762.700",
  "Longitude": "06.096.954",
  "Telephone": "024166204",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.7627,6.096954",
  "id": "50.7627,6.096954"
}, {
  "Brand": "ARAL",
  "Street": "Kölnstraße 1",
  "PostCode": 50999,
  "City": "Köln",
  "Latitude": "50.869.795",
  "Longitude": "07.005.583",
  "Telephone": "02236962590",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.869795,7.005583",
  "id": "50.869795,7.005583"
}, {
  "Brand": "ARAL",
  "Street": "Venloer Straße 39",
  "PostCode": 50259,
  "City": "Pulheim",
  "Latitude": "50.995.372",
  "Longitude": "06.810.329",
  "Telephone": "0223854747",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.995372,6.810329",
  "id": "50.995372,6.810329"
}, {
  "Brand": "ARAL",
  "Street": "Amsterdamer Straße 194",
  "PostCode": 50735,
  "City": "Köln",
  "Latitude": "50.974.747",
  "Longitude": "06.970.322",
  "Telephone": "02217127826",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.974747,6.970322",
  "id": "50.974747,6.970322"
}, {
  "Brand": "ARAL",
  "Street": "Bonner Straße 2",
  "PostCode": 51379,
  "City": "Leverkusen",
  "Latitude": "51.066.892",
  "Longitude": "06.991.081",
  "Telephone": "02171715830",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.066892,6.991081",
  "id": "51.066892,6.991081"
}, {
  "Brand": "ARAL",
  "Street": "Dünweg 55",
  "PostCode": 51399,
  "City": "Burscheid",
  "Latitude": "51.093.802",
  "Longitude": "07.145.046",
  "Telephone": "02174786368",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.093802,7.145046",
  "id": "51.093802,7.145046"
}, {
  "Brand": "ARAL",
  "Street": "Römerstraße 30",
  "PostCode": 52382,
  "City": "Niederzier",
  "Latitude": "50.866.646",
  "Longitude": "06.434.919",
  "Telephone": "024283021",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.866646,6.434919",
  "id": "50.866646,6.434919"
}, {
  "Brand": "ARAL",
  "Street": "Longericher Straße 543",
  "PostCode": 50739,
  "City": "Köln",
  "Latitude": "50.998.909",
  "Longitude": "06.912.042",
  "Telephone": "02215992601",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.998909,6.912042",
  "id": "50.998909,6.912042"
}, {
  "Brand": "ARAL",
  "Street": "Willy-Brandt-Ring  101",
  "PostCode": 51375,
  "City": "Leverkusen",
  "Latitude": "51.027.338",
  "Longitude": "07.042.083",
  "Telephone": "021453024",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.027338,7.042083",
  "id": "51.027338,7.042083"
}, {
  "Brand": "ARAL",
  "Street": "Kerpener Straße  185",
  "PostCode": 50170,
  "City": "Kerpen",
  "Latitude": "50.897.337",
  "Longitude": "06.683.757",
  "Telephone": "0227351765",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.897337,6.683757",
  "id": "50.897337,6.683757"
}, {
  "Brand": "ARAL",
  "Street": "Im Wingert 20",
  "PostCode": 53115,
  "City": "Bonn",
  "Latitude": "50.718.098",
  "Longitude": "07.091.064",
  "Telephone": "0228212348",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.718098,7.091064",
  "id": "50.718098,7.091064"
}, {
  "Brand": "ARAL",
  "Street": "Waldnieler Straße 60",
  "PostCode": 41068,
  "City": "Mönchengladbach",
  "Latitude": "51.194.829",
  "Longitude": "06.419.637",
  "Telephone": "02161837907",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.194829,6.419637",
  "id": "51.194829,6.419637"
}, {
  "Brand": "ARAL",
  "Street": "Ringstraße 30",
  "PostCode": 45219,
  "City": "Essen",
  "Latitude": "51.361.393",
  "Longitude": "06.943.623",
  "Telephone": "020545132",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.361393,6.943623",
  "id": "51.361393,6.943623"
}, {
  "Brand": "ARAL",
  "Street": "Kölner Straße 107",
  "PostCode": 41199,
  "City": "Mönchengladbach",
  "Latitude": "51.127.934",
  "Longitude": "06.453.592",
  "Telephone": "02166601018",
  "WeekDayFrom": "08:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "09:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.127934,6.453592",
  "id": "51.127934,6.453592"
}, {
  "Brand": "ARAL",
  "Street": "Eickener Straße 357",
  "PostCode": 41063,
  "City": "Mönchengladbach",
  "Latitude": "51.212.324",
  "Longitude": "06.448.193",
  "Telephone": "02161184615",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.212324,6.448193",
  "id": "51.212324,6.448193"
}, {
  "Brand": "ARAL",
  "Street": "Roermonder Straße 2",
  "PostCode": 41372,
  "City": "Niederkrüchten",
  "Latitude": "51.210.776",
  "Longitude": "06.143.233",
  "Telephone": "0216382397",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.210776,6.143233",
  "id": "51.210776,6.143233"
}, {
  "Brand": "ARAL",
  "Street": "Lauerstraße 12",
  "PostCode": 41812,
  "City": "Erkelenz",
  "Latitude": "51.096.317",
  "Longitude": "06.215.489",
  "Telephone": "024326252",
  "WeekDayFrom": "04:45",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "19:00",
  "SundayFrom": "08:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "51.096317,6.215489",
  "id": "51.096317,6.215489"
}, {
  "Brand": "ARAL",
  "Street": "Gladbacher Straße 21",
  "PostCode": 41849,
  "City": "Wassenberg",
  "Latitude": "51.107.243",
  "Longitude": "06.178.586",
  "Telephone": "0243220725",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.107243,6.178586",
  "id": "51.107243,6.178586"
}, {
  "Brand": "ARAL",
  "Street": "Liedberger Straße 25",
  "PostCode": 41238,
  "City": "Mönchengladbach",
  "Latitude": "51.156.362",
  "Longitude": "06.507.381",
  "Telephone": "0216682959",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.156362,6.507381",
  "id": "51.156362,6.507381"
}, {
  "Brand": "ARAL",
  "Street": "Beecker Straße 79",
  "PostCode": 41844,
  "City": "Wegberg",
  "Latitude": "51.138.192",
  "Longitude": "06.287.354",
  "Telephone": "024343371",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.138192,6.287354",
  "id": "51.138192,6.287354"
}, {
  "Brand": "ARAL",
  "Street": "Vorster Straße 384",
  "PostCode": 41169,
  "City": "Mönchengladbach",
  "Latitude": "51.201.265",
  "Longitude": "06.348.630",
  "Telephone": "02161559241",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.201265,6.34863",
  "id": "51.201265,6.34863"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße2",
  "PostCode": 51674,
  "City": "Wiehl",
  "Latitude": "50.949.220",
  "Longitude": "07.545.073",
  "Telephone": "022627074607",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.94922,7.545073",
  "id": "50.94922,7.545073"
}, {
  "Brand": "ARAL",
  "Street": "Krefelder Straße 472",
  "PostCode": 41066,
  "City": "Mönchengladbach",
  "Latitude": "51.218.290",
  "Longitude": "06.477.363",
  "Telephone": "02161602426",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.21829,6.477363",
  "id": "51.21829,6.477363"
}, {
  "Brand": "ARAL",
  "Street": "Marienburger Straße 3",
  "PostCode": 41063,
  "City": "Mönchengladbach",
  "Latitude": "51.203.104",
  "Longitude": "06.418.979",
  "Telephone": "0216186849",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.203104,6.418979",
  "id": "51.203104,6.418979"
}, {
  "Brand": "ARAL",
  "Street": "Brückenstraße 83",
  "PostCode": 51643,
  "City": "Gummersbach",
  "Latitude": "51.023.703",
  "Longitude": "07.555.455",
  "Telephone": "0226165147",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.023703,7.555455",
  "id": "51.023703,7.555455"
}, {
  "Brand": "ARAL",
  "Street": "Hardt 76",
  "PostCode": 40764,
  "City": "Langenfeld",
  "Latitude": "51.119.474",
  "Longitude": "06.973.570",
  "Telephone": "02173907790",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.119474,6.97357",
  "id": "51.119474,6.97357"
}, {
  "Brand": "ARAL",
  "Street": "Ahnfeldstraße 60",
  "PostCode": 40239,
  "City": "Düsseldorf",
  "Latitude": "51.241.591",
  "Longitude": "06.797.076",
  "Telephone": "0211627610",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.241591,6.797076",
  "id": "51.241591,6.797076"
}, {
  "Brand": "ARAL",
  "Street": "Linde 19",
  "PostCode": 42287,
  "City": "Wuppertal",
  "Latitude": "51.233.680",
  "Longitude": "07.236.260",
  "Telephone": "0202464793",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.23368,7.23626",
  "id": "51.23368,7.23626"
}, {
  "Brand": "ARAL",
  "Street": "Westring 83",
  "PostCode": 42329,
  "City": "Wuppertal",
  "Latitude": "51.229.879",
  "Longitude": "07.074.273",
  "Telephone": "0202781423",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.229879,7.074273",
  "id": "51.229879,7.074273"
}, {
  "Brand": "ARAL",
  "Street": "Venloer Straße 143",
  "PostCode": 41569,
  "City": "Rommerskirchen",
  "Latitude": "51.036.558",
  "Longitude": "06.677.627",
  "Telephone": "021837079",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.036558,6.677627",
  "id": "51.036558,6.677627"
}, {
  "Brand": "ARAL",
  "Street": "Cronenberger Straße 388",
  "PostCode": 42349,
  "City": "Wuppertal",
  "Latitude": "51.229.010",
  "Longitude": "07.149.388",
  "Telephone": "02024087581",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.22901,7.149388",
  "id": "51.22901,7.149388"
}, {
  "Brand": "ARAL",
  "Street": "Königswinterer Straße 409",
  "PostCode": 53227,
  "City": "Bonn",
  "Latitude": "50.724.429",
  "Longitude": "07.152.956",
  "Telephone": "0228442219",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "08:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "50.724429,7.152956",
  "id": "50.724429,7.152956"
}, {
  "Brand": "ARAL",
  "Street": "Kaarster Straße 1",
  "PostCode": 41564,
  "City": "Kaarst",
  "Latitude": "51.218.434",
  "Longitude": "06.605.988",
  "Telephone": "02131605076",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.218434,6.605988",
  "id": "51.218434,6.605988"
}, {
  "Brand": "ARAL",
  "Street": "In den Kötten 9",
  "PostCode": 40627,
  "City": "Düsseldorf",
  "Latitude": "51.212.410",
  "Longitude": "06.857.821",
  "Telephone": "0211279993",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.21241,6.857821",
  "id": "51.21241,6.857821"
}, {
  "Brand": "ARAL",
  "Street": "Hochdahler Straße 130",
  "PostCode": 42781,
  "City": "Haan",
  "Latitude": "51.193.776",
  "Longitude": "06.987.960",
  "Telephone": "02129342673",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.193776,6.98796",
  "id": "51.193776,6.98796"
}, {
  "Brand": "ARAL",
  "Street": "Volkardeyer Straße 54",
  "PostCode": 40878,
  "City": "Ratingen",
  "Latitude": "51.288.663",
  "Longitude": "06.827.508",
  "Telephone": "0210243980",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.288663,6.827508",
  "id": "51.288663,6.827508"
}, {
  "Brand": "ARAL",
  "Street": "Norfer Straße 54",
  "PostCode": 41468,
  "City": "Neuss",
  "Latitude": "51.174.276",
  "Longitude": "06.737.947",
  "Telephone": "02131364947",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.174276,6.737947",
  "id": "51.174276,6.737947"
}, {
  "Brand": "ARAL",
  "Street": "Schulstraße 83",
  "PostCode": 41363,
  "City": "Jüchen",
  "Latitude": "51.114.059",
  "Longitude": "06.550.157",
  "Telephone": "021817069775",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.114059,6.550157",
  "id": "51.114059,6.550157"
}, {
  "Brand": "ARAL",
  "Street": "Elberfelder Straße 99",
  "PostCode": 42553,
  "City": "Velbert",
  "Latitude": "51.307.914",
  "Longitude": "07.087.610",
  "Telephone": "02053923666",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.307914,7.08761",
  "id": "51.307914,7.08761"
}, {
  "Brand": "ARAL",
  "Street": "A3",
  "PostCode": 42697,
  "City": "Solingen",
  "Latitude": "51.158.961",
  "Longitude": "06.961.062",
  "Telephone": "021274210",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.158961,6.961062",
  "id": "51.158961,6.961062"
}, {
  "Brand": "ARAL",
  "Street": "Friesdorfer Straße 195",
  "PostCode": 53175,
  "City": "Bonn",
  "Latitude": "50.691.947",
  "Longitude": "07.136.983",
  "Telephone": "0228310151",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.691947,7.136983",
  "id": "50.691947,7.136983"
}, {
  "Brand": "ARAL",
  "Street": "Dellbrücker Straße 2-4",
  "PostCode": 51469,
  "City": "Bergisch Gladbach",
  "Latitude": "50.988.156",
  "Longitude": "07.087.447",
  "Telephone": "0220251865",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.988156,7.087447",
  "id": "50.988156,7.087447"
}, {
  "Brand": "ARAL",
  "Street": "Ratinger Straße 19",
  "PostCode": 42579,
  "City": "Heiligenhaus",
  "Latitude": "51.321.982",
  "Longitude": "06.955.689",
  "Telephone": "0205693030",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.321982,6.955689",
  "id": "51.321982,6.955689"
}, {
  "Brand": "ARAL",
  "Street": "Volksbadstraße 1",
  "PostCode": 41065,
  "City": "Mönchengladbach",
  "Latitude": "51.203.676",
  "Longitude": "06.474.836",
  "Telephone": "02161601788",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.203676,6.474836",
  "id": "51.203676,6.474836"
}, {
  "Brand": "ARAL",
  "Street": "Bergerstraße 108",
  "PostCode": 51145,
  "City": "Köln",
  "Latitude": "50.890.974",
  "Longitude": "07.067.850",
  "Telephone": "0220332882",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.890974,7.06785",
  "id": "50.890974,7.06785"
}, {
  "Brand": "ARAL",
  "Street": "Bonner Straße 134",
  "PostCode": 53757,
  "City": "Sankt Augustin",
  "Latitude": "50.778.405",
  "Longitude": "07.190.881",
  "Telephone": "02241203231",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.778405,7.190881",
  "id": "50.778405,7.190881"
}, {
  "Brand": "ARAL",
  "Street": "Moselstraße 2",
  "PostCode": 53842,
  "City": "Troisdorf",
  "Latitude": "50.810.069",
  "Longitude": "07.157.805",
  "Telephone": "02241809276",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.810069,7.157805",
  "id": "50.810069,7.157805"
}, {
  "Brand": "ARAL",
  "Street": "Heinsberger Straße 77",
  "PostCode": 52511,
  "City": "Geilenkirchen",
  "Latitude": "50.970.737",
  "Longitude": "06.119.667",
  "Telephone": "024519149696",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.970737,6.119667",
  "id": "50.970737,6.119667"
}, {
  "Brand": "ARAL",
  "Street": "Vogelsanger Weg 46",
  "PostCode": 50858,
  "City": "Köln",
  "Latitude": "50.935.492",
  "Longitude": "06.853.472",
  "Telephone": "0221486151",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "50.935492,6.853472",
  "id": "50.935492,6.853472"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Straße 390",
  "PostCode": 51103,
  "City": "Köln",
  "Latitude": "50.942.747",
  "Longitude": "07.027.255",
  "Telephone": "0221871436",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.942747,7.027255",
  "id": "50.942747,7.027255"
}, {
  "Brand": "ARAL",
  "Street": "Neusser Straße 856",
  "PostCode": 50737,
  "City": "Köln",
  "Latitude": "50.993.198",
  "Longitude": "06.940.570",
  "Telephone": "02219742284",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.993198,6.94057",
  "id": "50.993198,6.94057"
}, {
  "Brand": "ARAL",
  "Street": "Olpener Straße 1026",
  "PostCode": 51109,
  "City": "Köln",
  "Latitude": "50.946.881",
  "Longitude": "07.085.226",
  "Telephone": "0221840873",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.946881,7.085226",
  "id": "50.946881,7.085226"
}, {
  "Brand": "ARAL",
  "Street": "Riehler Straße 12-14",
  "PostCode": 50668,
  "City": "Köln",
  "Latitude": "50.952.379",
  "Longitude": "06.961.644",
  "Telephone": "02219726040",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.952379,6.961644",
  "id": "50.952379,6.961644"
}, {
  "Brand": "ARAL",
  "Street": "Rolshover Straße 183",
  "PostCode": 51105,
  "City": "Köln",
  "Latitude": "50.927.277",
  "Longitude": "07.000.214",
  "Telephone": "0221836527",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.927277,7.000214",
  "id": "50.927277,7.000214"
}, {
  "Brand": "ARAL",
  "Street": "Siegburger Straße 116",
  "PostCode": 50679,
  "City": "Köln",
  "Latitude": "50.922.994",
  "Longitude": "06.980.695",
  "Telephone": "0221812107",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.922994,6.980695",
  "id": "50.922994,6.980695"
}, {
  "Brand": "ARAL",
  "Street": "Zollstockgürtel 39",
  "PostCode": 50969,
  "City": "Köln",
  "Latitude": "50.905.784",
  "Longitude": "06.937.863",
  "Telephone": "0221364366",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.905784,6.937863",
  "id": "50.905784,6.937863"
}, {
  "Brand": "ARAL",
  "Street": "Berghauser Straße 10",
  "PostCode": 42349,
  "City": "Wuppertal",
  "Latitude": "51.202.695",
  "Longitude": "07.131.114",
  "Telephone": "02022471654",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.202695,7.131114",
  "id": "51.202695,7.131114"
}, {
  "Brand": "ARAL",
  "Street": "Roermonder Straße 41",
  "PostCode": 52525,
  "City": "Heinsberg",
  "Latitude": "51.094.157",
  "Longitude": "06.081.970",
  "Telephone": "024527601",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.094157,6.08197",
  "id": "51.094157,6.08197"
}, {
  "Brand": "ARAL",
  "Street": "Südring 115",
  "PostCode": 40221,
  "City": "Düsseldorf",
  "Latitude": "51.201.012",
  "Longitude": "06.763.259",
  "Telephone": "0211392503",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.201012,6.763259",
  "id": "51.201012,6.763259"
}, {
  "Brand": "ARAL",
  "Street": "Dormagener Straße 20",
  "PostCode": 50129,
  "City": "Bergheim",
  "Latitude": "50.986.501",
  "Longitude": "06.671.675",
  "Telephone": "022716785851",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.986501,6.671675",
  "id": "50.986501,6.671675"
}, {
  "Brand": "ARAL",
  "Street": "Nordheide 21",
  "PostCode": 27798,
  "City": "Hude",
  "Latitude": "53.109.847",
  "Longitude": "08.466.042",
  "Telephone": "04408939918",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "53.109847,8.466042",
  "id": "53.109847,8.466042"
}, {
  "Brand": "ARAL",
  "Street": "Osnabrücker Straße 13",
  "PostCode": 49525,
  "City": "Lengerich",
  "Latitude": "52.186.098",
  "Longitude": "07.861.557",
  "Telephone": "054814793",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.186098,7.861557",
  "id": "52.186098,7.861557"
}, {
  "Brand": "ARAL",
  "Street": "Bergische Landstraße 605",
  "PostCode": 40629,
  "City": "Düsseldorf",
  "Latitude": "51.260.509",
  "Longitude": "06.908.724",
  "Telephone": "02112913810",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.260509,6.908724",
  "id": "51.260509,6.908724"
}, {
  "Brand": "ARAL",
  "Street": "Bottroper Straße 298 a",
  "PostCode": 45356,
  "City": "Essen",
  "Latitude": "51.484.366",
  "Longitude": "06.975.781",
  "Telephone": "02016143248",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "51.484366,6.975781",
  "id": "51.484366,6.975781"
}, {
  "Brand": "ARAL",
  "Street": "Wuppertaler Straße 110",
  "PostCode": 42653,
  "City": "Solingen",
  "Latitude": "51.199.320",
  "Longitude": "07.075.124",
  "Telephone": "02122571905",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.19932,7.075124",
  "id": "51.19932,7.075124"
}, {
  "Brand": "ARAL",
  "Street": "Sachsstraße 2",
  "PostCode": 50259,
  "City": "Pulheim",
  "Latitude": "50.957.461",
  "Longitude": "06.801.279",
  "Telephone": "02234698914",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.957461,6.801279",
  "id": "50.957461,6.801279"
}, {
  "Brand": "ARAL",
  "Street": "Kempener Straße 83",
  "PostCode": 41334,
  "City": "Nettetal",
  "Latitude": "51.310.413",
  "Longitude": "06.291.814",
  "Telephone": "021532387",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.310413,6.291814",
  "id": "51.310413,6.291814"
}, {
  "Brand": "ARAL",
  "Street": "Nordstraße 176",
  "PostCode": 52353,
  "City": "Düren",
  "Latitude": "50.832.429",
  "Longitude": "06.458.606",
  "Telephone": "02421204542",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.832429,6.458606",
  "id": "50.832429,6.458606"
}, {
  "Brand": "ARAL",
  "Street": "A 61 Ost",
  "PostCode": 50181,
  "City": "Bedburg",
  "Latitude": "51.004.863",
  "Longitude": "06.522.144",
  "Telephone": "02272904330",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.004863,6.522144",
  "id": "51.004863,6.522144"
}, {
  "Brand": "ARAL",
  "Street": "Kurfürstenallee 49",
  "PostCode": 28329,
  "City": "Bremen",
  "Latitude": "53.082.046",
  "Longitude": "08.855.155",
  "Telephone": "0421444244",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.082046,8.855155",
  "id": "53.082046,8.855155"
}, {
  "Brand": "ARAL",
  "Street": "Bremer Straße 72",
  "PostCode": 26135,
  "City": "Oldenburg",
  "Latitude": "53.129.261",
  "Longitude": "08.227.242",
  "Telephone": "04419987199",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.129261,8.227242",
  "id": "53.129261,8.227242"
}, {
  "Brand": "ARAL",
  "Street": "Ostertorstraße 45",
  "PostCode": 26670,
  "City": "Uplengen-Remels",
  "Latitude": "53.305.317",
  "Longitude": "07.746.361",
  "Telephone": "04956927799",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "04:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "04:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "53.305317,7.746361",
  "id": "53.305317,7.746361"
}, {
  "Brand": "ARAL",
  "Street": "Leerer Landstraße 27",
  "PostCode": 26603,
  "City": "Aurich",
  "Latitude": "53.464.677",
  "Longitude": "07.487.707",
  "Telephone": "049412385",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "53.464677,7.487707",
  "id": "53.464677,7.487707"
}, {
  "Brand": "ARAL",
  "Street": "Neuschanzer Straße 37",
  "PostCode": 26831,
  "City": "Bunde",
  "Latitude": "53.182.108",
  "Longitude": "07.261.336",
  "Telephone": "04953406",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.182108,7.261336",
  "id": "53.182108,7.261336"
}, {
  "Brand": "ARAL",
  "Street": "Grosse Straße 2",
  "PostCode": 49134,
  "City": "Wallenhorst",
  "Latitude": "52.347.341",
  "Longitude": "08.018.324",
  "Telephone": "0540739522",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "52.347341,8.018324",
  "id": "52.347341,8.018324"
}, {
  "Brand": "ARAL",
  "Street": "An der alten Bundessstraße 1a",
  "PostCode": 26419,
  "City": "Schortens",
  "Latitude": "53.542.262",
  "Longitude": "07.952.522",
  "Telephone": "044618634",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "08:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "53.542262,7.952522",
  "id": "53.542262,7.952522"
}, {
  "Brand": "ARAL",
  "Street": "Meller Landstraße 66",
  "PostCode": 49086,
  "City": "Osnabrück",
  "Latitude": "52.250.728",
  "Longitude": "08.103.604",
  "Telephone": "0541389458",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:30",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:30",
  "SundayFrom": "08:00",
  "SundayTo": "21:30",
  "IsOpen24Hours": "Nein",
  "location": "52.250728,8.103604",
  "id": "52.250728,8.103604"
}, {
  "Brand": "ARAL",
  "Street": "Clemens-August-Straße 52",
  "PostCode": 49751,
  "City": "Sögel",
  "Latitude": "52.839.785",
  "Longitude": "07.525.826",
  "Telephone": "059521433",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "52.839785,7.525826",
  "id": "52.839785,7.525826"
}, {
  "Brand": "ARAL",
  "Street": "Auricher Landstraße 16",
  "PostCode": 26629,
  "City": "Großefehn",
  "Latitude": "53.396.711",
  "Longitude": "07.568.814",
  "Telephone": "04943990303",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.396711,7.568814",
  "id": "53.396711,7.568814"
}, {
  "Brand": "ARAL",
  "Street": "Jeverstraße 9",
  "PostCode": 26409,
  "City": "Wittmund",
  "Latitude": "53.576.378",
  "Longitude": "07.792.324",
  "Telephone": "044625511",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.576378,7.792324",
  "id": "53.576378,7.792324"
}, {
  "Brand": "ARAL",
  "Street": "Huchtinger Heerstraße 4",
  "PostCode": 28259,
  "City": "Bremen",
  "Latitude": "53.053.781",
  "Longitude": "08.740.779",
  "Telephone": "0421581445",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.053781,8.740779",
  "id": "53.053781,8.740779"
}, {
  "Brand": "ARAL",
  "Street": "Malgartenerstraße 2",
  "PostCode": 49565,
  "City": "Bramsche",
  "Latitude": "52.405.021",
  "Longitude": "07.988.431",
  "Telephone": "054619951818",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.405021,7.988431",
  "id": "52.405021,7.988431"
}, {
  "Brand": "ARAL",
  "Street": "Lange Straße 65",
  "PostCode": 27318,
  "City": "Hoya",
  "Latitude": "52.804.958",
  "Longitude": "09.135.822",
  "Telephone": "042512800",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "52.804958,9.135822",
  "id": "52.804958,9.135822"
}, {
  "Brand": "ARAL",
  "Street": "Weserstraße 46",
  "PostCode": 32547,
  "City": "Bad Oeynhausen",
  "Latitude": "52.197.994",
  "Longitude": "08.809.394",
  "Telephone": "057313848",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.197994,8.809394",
  "id": "52.197994,8.809394"
}, {
  "Brand": "ARAL",
  "Street": "Esenser Straße 109-111",
  "PostCode": 26607,
  "City": "Aurich",
  "Latitude": "53.482.400",
  "Longitude": "07.490.395",
  "Telephone": "0494171435",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.4824,7.490395",
  "id": "53.4824,7.490395"
}, {
  "Brand": "ARAL",
  "Street": "Meppener Straße 69",
  "PostCode": 49808,
  "City": "Lingen",
  "Latitude": "52.531.377",
  "Longitude": "07.310.046",
  "Telephone": "059163082",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.531377,7.310046",
  "id": "52.531377,7.310046"
}, {
  "Brand": "ARAL",
  "Street": "Auricher Straße 2",
  "PostCode": 26835,
  "City": "Hesel",
  "Latitude": "53.305.665",
  "Longitude": "07.593.839",
  "Telephone": "049502034",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.305665,7.593839",
  "id": "53.305665,7.593839"
}, {
  "Brand": "ARAL",
  "Street": "Grevener Damm 78",
  "PostCode": 48282,
  "City": "Emsdetten",
  "Latitude": "52.167.247",
  "Longitude": "07.542.205",
  "Telephone": "025722540",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "52.167247,7.542205",
  "id": "52.167247,7.542205"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 204",
  "PostCode": 26842,
  "City": "Ostrhauderfehn",
  "Latitude": "53.140.110",
  "Longitude": "07.638.033",
  "Telephone": "049525236",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "53.14011,7.638033",
  "id": "53.14011,7.638033"
}, {
  "Brand": "ARAL",
  "Street": "Stedinger Straße 174",
  "PostCode": 27753,
  "City": "Delmenhorst",
  "Latitude": "53.065.474",
  "Longitude": "08.633.312",
  "Telephone": "0422151566",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.065474,8.633312",
  "id": "53.065474,8.633312"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 78",
  "PostCode": 49824,
  "City": "Emlichheim",
  "Latitude": "52.607.429",
  "Longitude": "06.839.073",
  "Telephone": "059431946",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.607429,6.839073",
  "id": "52.607429,6.839073"
}, {
  "Brand": "ARAL",
  "Street": "Sudweyher Straße 78",
  "PostCode": 28844,
  "City": "Weyhe",
  "Latitude": "52.983.067",
  "Longitude": "08.877.803",
  "Telephone": "042036162",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "52.983067,8.877803",
  "id": "52.983067,8.877803"
}, {
  "Brand": "ARAL",
  "Street": "Gökerstraße/Ecke Peterstr",
  "PostCode": 26384,
  "City": "Wilhelmshaven",
  "Latitude": "53.520.422",
  "Longitude": "08.123.590",
  "Telephone": "04421994353",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.520422,8.12359",
  "id": "53.520422,8.12359"
}, {
  "Brand": "ARAL",
  "Street": "Dr.Warsing-Straße 209",
  "PostCode": 26802,
  "City": "Moormerland",
  "Latitude": "53.308.650",
  "Longitude": "07.504.725",
  "Telephone": "049544228",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "53.30865,7.504725",
  "id": "53.30865,7.504725"
}, {
  "Brand": "ARAL",
  "Street": "Prinzessinweg  2",
  "PostCode": 26122,
  "City": "Oldenburg",
  "Latitude": "53.143.358",
  "Longitude": "08.191.774",
  "Telephone": "0441971971",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.143358,8.191774",
  "id": "53.143358,8.191774"
}, {
  "Brand": "ARAL",
  "Street": "Bünder Straße 343",
  "PostCode": 32120,
  "City": "Hiddenhausen",
  "Latitude": "52.163.329",
  "Longitude": "08.634.844",
  "Telephone": "0522161977",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.163329,8.634844",
  "id": "52.163329,8.634844"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 13",
  "PostCode": 31603,
  "City": "Diepenau-Lavelsloh",
  "Latitude": "52.421.301",
  "Longitude": "08.739.635",
  "Telephone": "057751020",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "52.421301,8.739635",
  "id": "52.421301,8.739635"
}, {
  "Brand": "ARAL",
  "Street": "Bremerhavener Heerstr. 48",
  "PostCode": 28717,
  "City": "Bremen",
  "Latitude": "53.173.102",
  "Longitude": "08.703.832",
  "Telephone": "0421633622",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.173102,8.703832",
  "id": "53.173102,8.703832"
}, {
  "Brand": "ARAL",
  "Street": "Osterfeuerberger Ring 65",
  "PostCode": 28219,
  "City": "Bremen",
  "Latitude": "53.102.758",
  "Longitude": "08.797.215",
  "Telephone": "0421385309",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "53.102758,8.797215",
  "id": "53.102758,8.797215"
}, {
  "Brand": "ARAL",
  "Street": "Mindener Straße 89",
  "PostCode": 32547,
  "City": "Bad Oeynhausen",
  "Latitude": "52.212.491",
  "Longitude": "08.815.242",
  "Telephone": "0573129688",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.212491,8.815242",
  "id": "52.212491,8.815242"
}, {
  "Brand": "ARAL",
  "Street": "Adelheider Straße 77",
  "PostCode": 27755,
  "City": "Delmenhorst",
  "Latitude": "53.032.145",
  "Longitude": "08.621.628",
  "Telephone": "0422123577",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.032145,8.621628",
  "id": "53.032145,8.621628"
}, {
  "Brand": "ARAL",
  "Street": "Bramscher Straße 267",
  "PostCode": 49090,
  "City": "Osnabrück",
  "Latitude": "52.301.098",
  "Longitude": "08.034.664",
  "Telephone": "054163204",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "52.301098,8.034664",
  "id": "52.301098,8.034664"
}, {
  "Brand": "ARAL",
  "Street": "Oldenburger Straße 99",
  "PostCode": 28259,
  "City": "Bremen",
  "Latitude": "53.049.093",
  "Longitude": "08.713.773",
  "Telephone": "0421583475",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.049093,8.713773",
  "id": "53.049093,8.713773"
}, {
  "Brand": "ARAL",
  "Street": "Auricher Straße 216",
  "PostCode": 26721,
  "City": "Emden",
  "Latitude": "53.391.055",
  "Longitude": "07.207.846",
  "Telephone": "0492142563",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "04:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "04:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "53.391055,7.207846",
  "id": "53.391055,7.207846"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 72",
  "PostCode": 49509,
  "City": "Recke",
  "Latitude": "52.362.743",
  "Longitude": "07.718.779",
  "Telephone": "054533410",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.362743,7.718779",
  "id": "52.362743,7.718779"
}, {
  "Brand": "ARAL",
  "Street": "Hundsmühler Straße 86",
  "PostCode": 26131,
  "City": "Oldenburg",
  "Latitude": "53.125.875",
  "Longitude": "08.190.065",
  "Telephone": "04419558927",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.125875,8.190065",
  "id": "53.125875,8.190065"
}, {
  "Brand": "ARAL",
  "Street": "Glückaufstraße 153",
  "PostCode": 49124,
  "City": "Georgsmarienhütte",
  "Latitude": "52.203.380",
  "Longitude": "08.109.895",
  "Telephone": "054015469",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.20338,8.109895",
  "id": "52.20338,8.109895"
}, {
  "Brand": "ARAL",
  "Street": "Gütersloher Straße 123",
  "PostCode": 33649,
  "City": "Bielefeld",
  "Latitude": "51.990.402",
  "Longitude": "08.492.146",
  "Telephone": "0521442240",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:30",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:30",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.990402,8.492146",
  "id": "51.990402,8.492146"
}, {
  "Brand": "ARAL",
  "Street": "Paderborner Straße 324",
  "PostCode": 33689,
  "City": "Bielefeld",
  "Latitude": "51.932.147",
  "Longitude": "08.606.917",
  "Telephone": "052057292118",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.932147,8.606917",
  "id": "51.932147,8.606917"
}, {
  "Brand": "ARAL",
  "Street": "Münsterstraße63",
  "PostCode": 33775,
  "City": "Versmold",
  "Latitude": "52.038.646",
  "Longitude": "08.142.515",
  "Telephone": "0542394330",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.038646,8.142515",
  "id": "52.038646,8.142515"
}, {
  "Brand": "ARAL",
  "Street": "Enger Straße 14",
  "PostCode": 32139,
  "City": "Spenge",
  "Latitude": "52.140.856",
  "Longitude": "08.492.113",
  "Telephone": "05225859898",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:30",
  "IsOpen24Hours": "Nein",
  "location": "52.140856,8.492113",
  "id": "52.140856,8.492113"
}, {
  "Brand": "ARAL",
  "Street": "Jöllenbecker Straße 565",
  "PostCode": 33739,
  "City": "Bielefeld",
  "Latitude": "52.095.025",
  "Longitude": "08.513.664",
  "Telephone": "052063110",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.095025,8.513664",
  "id": "52.095025,8.513664"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 44",
  "PostCode": 33803,
  "City": "Steinhagen",
  "Latitude": "52.009.148",
  "Longitude": "08.413.793",
  "Telephone": "05204921937",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.009148,8.413793",
  "id": "52.009148,8.413793"
}, {
  "Brand": "ARAL",
  "Street": "Heeper Straße 77",
  "PostCode": 33607,
  "City": "Bielefeld",
  "Latitude": "52.021.724",
  "Longitude": "08.548.129",
  "Telephone": "052169507",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.021724,8.548129",
  "id": "52.021724,8.548129"
}, {
  "Brand": "ARAL",
  "Street": "Lange Straße 100",
  "PostCode": 32139,
  "City": "Spenge",
  "Latitude": "52.148.073",
  "Longitude": "08.485.003",
  "Telephone": "0522587720",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.148073,8.485003",
  "id": "52.148073,8.485003"
}, {
  "Brand": "ARAL",
  "Street": "Bielefelder Straße 108",
  "PostCode": 33790,
  "City": "Halle",
  "Latitude": "52.050.865",
  "Longitude": "08.376.857",
  "Telephone": "052019641",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.050865,8.376857",
  "id": "52.050865,8.376857"
}, {
  "Brand": "ARAL",
  "Street": "Hellweg 2 b",
  "PostCode": 32825,
  "City": "Blomberg",
  "Latitude": "51.939.535",
  "Longitude": "09.090.921",
  "Telephone": "052358089",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.939535,9.090921",
  "id": "51.939535,9.090921"
}, {
  "Brand": "ARAL",
  "Street": "Bielefelder Straße 36",
  "PostCode": 33824,
  "City": "Werther",
  "Latitude": "52.070.366",
  "Longitude": "08.413.767",
  "Telephone": "052033331",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.070366,8.413767",
  "id": "52.070366,8.413767"
}, {
  "Brand": "ARAL",
  "Street": "Potsdamer Straße 7",
  "PostCode": 33719,
  "City": "Bielefeld",
  "Latitude": "52.027.828",
  "Longitude": "08.603.513",
  "Telephone": "0521331355",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.027828,8.603513",
  "id": "52.027828,8.603513"
}, {
  "Brand": "ARAL",
  "Street": "A1",
  "PostCode": 49545,
  "City": "Tecklenburg",
  "Latitude": "52.232.534",
  "Longitude": "07.878.547",
  "Telephone": "05456935801",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.232534,7.878547",
  "id": "52.232534,7.878547"
}, {
  "Brand": "ARAL",
  "Street": "Stieghorster Straße 42",
  "PostCode": 33605,
  "City": "Bielefeld",
  "Latitude": "52.001.865",
  "Longitude": "08.589.285",
  "Telephone": "0521200809",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "52.001865,8.589285",
  "id": "52.001865,8.589285"
}, {
  "Brand": "ARAL",
  "Street": "Am Lehester Deich 62",
  "PostCode": 28357,
  "City": "Bremen",
  "Latitude": "53.116.367",
  "Longitude": "08.899.966",
  "Telephone": "0421270076",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.116367,8.899966",
  "id": "53.116367,8.899966"
}, {
  "Brand": "ARAL",
  "Street": "Heidenoldendorfer Str.16",
  "PostCode": 32758,
  "City": "Detmold",
  "Latitude": "51.941.367",
  "Longitude": "08.859.417",
  "Telephone": "0523168884",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.941367,8.859417",
  "id": "51.941367,8.859417"
}, {
  "Brand": "ARAL",
  "Street": "Burgstraße 89",
  "PostCode": 49413,
  "City": "Dinklage",
  "Latitude": "52.660.200",
  "Longitude": "08.136.245",
  "Telephone": "04443736",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:30",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:30",
  "SundayFrom": "09:00",
  "SundayTo": "21:30",
  "IsOpen24Hours": "Nein",
  "location": "52.6602,8.136245",
  "id": "52.6602,8.136245"
}, {
  "Brand": "ARAL",
  "Street": "Heisfelder Straße 212",
  "PostCode": 26789,
  "City": "Leer",
  "Latitude": "53.247.346",
  "Longitude": "07.458.482",
  "Telephone": "04913584",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.247346,7.458482",
  "id": "53.247346,7.458482"
}, {
  "Brand": "ARAL",
  "Street": "Bremer Straße 20",
  "PostCode": 49624,
  "City": "Löningen",
  "Latitude": "52.738.201",
  "Longitude": "07.760.490",
  "Telephone": "054323228",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:30",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "52.738201,7.76049",
  "id": "52.738201,7.76049"
}, {
  "Brand": "ARAL",
  "Street": "Denekamper Straße 15",
  "PostCode": 48529,
  "City": "Nordhorn",
  "Latitude": "52.428.975",
  "Longitude": "07.062.336",
  "Telephone": "059215341",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.428975,7.062336",
  "id": "52.428975,7.062336"
}, {
  "Brand": "ARAL",
  "Street": "Alte Poststraße 2",
  "PostCode": 49074,
  "City": "Osnabrück",
  "Latitude": "52.276.945",
  "Longitude": "08.055.264",
  "Telephone": "054121730",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.276945,8.055264",
  "id": "52.276945,8.055264"
}, {
  "Brand": "ARAL",
  "Street": "Stolzenauer Landstraße 10",
  "PostCode": 31595,
  "City": "Steyerberg",
  "Latitude": "52.563.792",
  "Longitude": "09.028.706",
  "Telephone": "05764314",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "52.563792,9.028706",
  "id": "52.563792,9.028706"
}, {
  "Brand": "ARAL",
  "Street": "Bismarckstraße 147",
  "PostCode": 26382,
  "City": "Wilhelmshaven",
  "Latitude": "53.528.244",
  "Longitude": "08.114.908",
  "Telephone": "0442133455",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.528244,8.114908",
  "id": "53.528244,8.114908"
}, {
  "Brand": "ARAL",
  "Street": "Kirchdornberger Straße 85",
  "PostCode": 33619,
  "City": "Bielefeld",
  "Latitude": "52.048.900",
  "Longitude": "08.452.410",
  "Telephone": "0521101266",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "52.0489,8.45241",
  "id": "52.0489,8.45241"
}, {
  "Brand": "ARAL",
  "Street": "Mindener Straße 115",
  "PostCode": 32049,
  "City": "Herford",
  "Latitude": "52.129.069",
  "Longitude": "08.686.750",
  "Telephone": "0522124623",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.129069,8.68675",
  "id": "52.129069,8.68675"
}, {
  "Brand": "ARAL",
  "Street": "Königstraße 57",
  "PostCode": 32584,
  "City": "Löhne",
  "Latitude": "52.191.102",
  "Longitude": "08.714.315",
  "Telephone": "057322225",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.191102,8.714315",
  "id": "52.191102,8.714315"
}, {
  "Brand": "ARAL",
  "Street": "Wüllener Straße 85",
  "PostCode": 48683,
  "City": "Ahaus",
  "Latitude": "52.072.900",
  "Longitude": "06.995.821",
  "Telephone": "02561971191",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "52.0729,6.995821",
  "id": "52.0729,6.995821"
}, {
  "Brand": "ARAL",
  "Street": "Altenberger Straße 34",
  "PostCode": 48161,
  "City": "Münster",
  "Latitude": "51.999.710",
  "Longitude": "07.558.383",
  "Telephone": "025339355140",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.99971,7.558383",
  "id": "51.99971,7.558383"
}, {
  "Brand": "ARAL",
  "Street": "Wisbyring 15",
  "PostCode": 59494,
  "City": "Soest",
  "Latitude": "51.562.734",
  "Longitude": "08.102.926",
  "Telephone": "0292173330",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.562734,8.102926",
  "id": "51.562734,8.102926"
}, {
  "Brand": "ARAL",
  "Street": "Steinfurter Straße 1-3",
  "PostCode": 48149,
  "City": "Münster",
  "Latitude": "51.967.265",
  "Longitude": "07.614.308",
  "Telephone": "025122822",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.967265,7.614308",
  "id": "51.967265,7.614308"
}, {
  "Brand": "ARAL",
  "Street": "Alleestraße 92",
  "PostCode": 59065,
  "City": "Hamm",
  "Latitude": "51.672.103",
  "Longitude": "07.814.706",
  "Telephone": "0238151773",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.672103,7.814706",
  "id": "51.672103,7.814706"
}, {
  "Brand": "ARAL",
  "Street": "Werner Straße 25",
  "PostCode": 59387,
  "City": "Ascheberg",
  "Latitude": "51.735.252",
  "Longitude": "07.662.861",
  "Telephone": "02599449",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.735252,7.662861",
  "id": "51.735252,7.662861"
}, {
  "Brand": "ARAL",
  "Street": "Werler Straße 390",
  "PostCode": 59069,
  "City": "Hamm",
  "Latitude": "51.616.790",
  "Longitude": "07.848.900",
  "Telephone": "023852393",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.61679,7.8489",
  "id": "51.61679,7.8489"
}, {
  "Brand": "ARAL",
  "Street": "Hammer Straße 231",
  "PostCode": 59075,
  "City": "Hamm",
  "Latitude": "51.689.995",
  "Longitude": "07.775.106",
  "Telephone": "02381788669",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.689995,7.775106",
  "id": "51.689995,7.775106"
}, {
  "Brand": "ARAL",
  "Street": "Lippstädter Straße 23",
  "PostCode": 59329,
  "City": "Wadersloh",
  "Latitude": "51.708.840",
  "Longitude": "08.260.127",
  "Telephone": "025238474",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.70884,8.260127",
  "id": "51.70884,8.260127"
}, {
  "Brand": "ARAL",
  "Street": "Münsterstraße 60",
  "PostCode": 48727,
  "City": "Billerbeck",
  "Latitude": "51.974.226",
  "Longitude": "07.298.036",
  "Telephone": "02543232393",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "09:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.974226,7.298036",
  "id": "51.974226,7.298036"
}, {
  "Brand": "ARAL",
  "Street": "Clarholzer Straße 73",
  "PostCode": 33442,
  "City": "Herzebrock-Clarholz",
  "Latitude": "51.883.402",
  "Longitude": "08.232.216",
  "Telephone": "052452066",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.883402,8.232216",
  "id": "51.883402,8.232216"
}, {
  "Brand": "ARAL",
  "Street": "Neubeckumer Straße 72",
  "PostCode": 59269,
  "City": "Beckum",
  "Latitude": "51.765.658",
  "Longitude": "08.042.467",
  "Telephone": "025213605",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.765658,8.042467",
  "id": "51.765658,8.042467"
}, {
  "Brand": "ARAL",
  "Street": "Lünener Straße 31",
  "PostCode": 59368,
  "City": "Werne",
  "Latitude": "51.657.573",
  "Longitude": "07.628.648",
  "Telephone": "023894791",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.657573,7.628648",
  "id": "51.657573,7.628648"
}, {
  "Brand": "ARAL",
  "Street": "Delbrücker Straße 22",
  "PostCode": 33397,
  "City": "Rietberg",
  "Latitude": "51.804.138",
  "Longitude": "08.425.995",
  "Telephone": "052448817",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.804138,8.425995",
  "id": "51.804138,8.425995"
}, {
  "Brand": "ARAL",
  "Street": "Bundesstraße 1",
  "PostCode": 59423,
  "City": "Unna",
  "Latitude": "51.532.106",
  "Longitude": "07.701.102",
  "Telephone": "02303772435",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.532106,7.701102",
  "id": "51.532106,7.701102"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Ring 21",
  "PostCode": 59302,
  "City": "Oelde",
  "Latitude": "51.831.344",
  "Longitude": "08.150.928",
  "Telephone": "025223621",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.831344,8.150928",
  "id": "51.831344,8.150928"
}, {
  "Brand": "ARAL",
  "Street": "Bürener Straße 15",
  "PostCode": 59590,
  "City": "Geseke",
  "Latitude": "51.631.063",
  "Longitude": "08.512.723",
  "Telephone": "029426061",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.631063,8.512723",
  "id": "51.631063,8.512723"
}, {
  "Brand": "ARAL",
  "Street": "Wolfsberger Straße 39",
  "PostCode": 59348,
  "City": "Lüdinghausen",
  "Latitude": "51.765.258",
  "Longitude": "07.442.895",
  "Telephone": "0259122670",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.765258,7.442895",
  "id": "51.765258,7.442895"
}, {
  "Brand": "ARAL",
  "Street": "Cappenberger Straße 101",
  "PostCode": 44534,
  "City": "Lünen",
  "Latitude": "51.629.779",
  "Longitude": "07.526.579",
  "Telephone": "0230654268",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.629779,7.526579",
  "id": "51.629779,7.526579"
}, {
  "Brand": "ARAL",
  "Street": "Beckumer Straße 109",
  "PostCode": 59229,
  "City": "Ahlen",
  "Latitude": "51.764.942",
  "Longitude": "07.912.292",
  "Telephone": "0238263484",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.764942,7.912292",
  "id": "51.764942,7.912292"
}, {
  "Brand": "ARAL",
  "Street": "Nürnberger Straße 131",
  "PostCode": 90762,
  "City": "Fürth",
  "Latitude": "49.466.534",
  "Longitude": "11.010.082",
  "Telephone": "0911706607",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "49.466534,11.010082",
  "id": "49.466534,11.010082"
}, {
  "Brand": "ARAL",
  "Street": "Wörthstraße 1A",
  "PostCode": 97082,
  "City": "Würzburg",
  "Latitude": "49.793.041",
  "Longitude": "09.916.193",
  "Telephone": "093142468",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "05:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.793041,9.916193",
  "id": "49.793041,9.916193"
}, {
  "Brand": "ARAL",
  "Street": "Memmelsdorfer Straße 80",
  "PostCode": 96052,
  "City": "Bamberg",
  "Latitude": "49.905.601",
  "Longitude": "10.896.485",
  "Telephone": "095131562",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.905601,10.896485",
  "id": "49.905601,10.896485"
}, {
  "Brand": "ARAL",
  "Street": "Ansbacher Straße 35",
  "PostCode": 91560,
  "City": "Heilsbronn",
  "Latitude": "49.334.026",
  "Longitude": "10.782.558",
  "Telephone": "09872955750",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.334026,10.782558",
  "id": "49.334026,10.782558"
}, {
  "Brand": "ARAL",
  "Street": "Äußere Sulzbacher Straße 117",
  "PostCode": 90491,
  "City": "Nürnberg",
  "Latitude": "49.464.248",
  "Longitude": "11.117.947",
  "Telephone": "09115979448",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.464248,11.117947",
  "id": "49.464248,11.117947"
}, {
  "Brand": "ARAL",
  "Street": "Nürnberger Straße 44",
  "PostCode": 91781,
  "City": "Weißenburg",
  "Latitude": "49.038.826",
  "Longitude": "10.970.908",
  "Telephone": "0914182835",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.038826,10.970908",
  "id": "49.038826,10.970908"
}, {
  "Brand": "ARAL",
  "Street": "Würzburger Straße 76",
  "PostCode": 96049,
  "City": "Bamberg",
  "Latitude": "49.877.672",
  "Longitude": "10.884.029",
  "Telephone": "095153020",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.877672,10.884029",
  "id": "49.877672,10.884029"
}, {
  "Brand": "ARAL",
  "Street": "Ansbacher Straße 38",
  "PostCode": 91541,
  "City": "Rothenburg",
  "Latitude": "49.375.102",
  "Longitude": "10.190.799",
  "Telephone": "098616052",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.375102,10.190799",
  "id": "49.375102,10.190799"
}, {
  "Brand": "ARAL",
  "Street": "Nürnberger Straße 96",
  "PostCode": 96114,
  "City": "Hirschaid",
  "Latitude": "49.811.197",
  "Longitude": "10.997.809",
  "Telephone": "095431640",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "05:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "49.811197,10.997809",
  "id": "49.811197,10.997809"
}, {
  "Brand": "ARAL",
  "Street": "Oberstreuer Straße 13",
  "PostCode": 97638,
  "City": "Mellrichstadt",
  "Latitude": "50.424.482",
  "Longitude": "10.297.077",
  "Telephone": "09776815021",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.424482,10.297077",
  "id": "50.424482,10.297077"
}, {
  "Brand": "ARAL",
  "Street": "Nürnberger Str. 39",
  "PostCode": 91257,
  "City": "Pegnitz",
  "Latitude": "49.749.701",
  "Longitude": "11.539.701",
  "Telephone": "092412713",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.749701,11.539701",
  "id": "49.749701,11.539701"
}, {
  "Brand": "ARAL",
  "Street": "Tirschenreuther Straße 20",
  "PostCode": 92681,
  "City": "Erbendorf",
  "Latitude": "49.835.548",
  "Longitude": "12.054.758",
  "Telephone": "096821733",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "12:00",
  "IsOpen24Hours": "Nein",
  "location": "49.835548,12.054758",
  "id": "49.835548,12.054758"
}, {
  "Brand": "ARAL",
  "Street": "Regensburger Straße70 a",
  "PostCode": 92224,
  "City": "Amberg",
  "Latitude": "49.437.984",
  "Longitude": "11.878.823",
  "Telephone": "0962183744",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.437984,11.878823",
  "id": "49.437984,11.878823"
}, {
  "Brand": "ARAL",
  "Street": "Gunzenhausener Straße 9",
  "PostCode": 91732,
  "City": "Merkendorf",
  "Latitude": "49.198.463",
  "Longitude": "10.701.910",
  "Telephone": "09826655222",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.198463,10.70191",
  "id": "49.198463,10.70191"
}, {
  "Brand": "ARAL",
  "Street": "Meininger Straße 1",
  "PostCode": 97702,
  "City": "Münnerstadt",
  "Latitude": "50.251.738",
  "Longitude": "10.195.887",
  "Telephone": "097333130",
  "WeekDayFrom": "07:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "09:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.251738,10.195887",
  "id": "50.251738,10.195887"
}, {
  "Brand": "ARAL",
  "Street": "Coburger Straße 64",
  "PostCode": 96215,
  "City": "Lichtenfels",
  "Latitude": "50.151.047",
  "Longitude": "11.059.260",
  "Telephone": "095712633",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.151047,11.05926",
  "id": "50.151047,11.05926"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 8",
  "PostCode": 85095,
  "City": "Denkendorf",
  "Latitude": "48.929.023",
  "Longitude": "11.459.235",
  "Telephone": "084669519584",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.929023,11.459235",
  "id": "48.929023,11.459235"
}, {
  "Brand": "ARAL",
  "Street": "Reuther Straße 58",
  "PostCode": 91301,
  "City": "Forchheim",
  "Latitude": "49.717.754",
  "Longitude": "11.104.472",
  "Telephone": "0919194241",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.717754,11.104472",
  "id": "49.717754,11.104472"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 6",
  "PostCode": 97633,
  "City": "Saal",
  "Latitude": "50.317.464",
  "Longitude": "10.352.696",
  "Telephone": "09762357",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.317464,10.352696",
  "id": "50.317464,10.352696"
}, {
  "Brand": "ARAL",
  "Street": "Herrnstraße 27",
  "PostCode": 90763,
  "City": "Fürth",
  "Latitude": "49.464.158",
  "Longitude": "10.987.908",
  "Telephone": "0911775990",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.464158,10.987908",
  "id": "49.464158,10.987908"
}, {
  "Brand": "ARAL",
  "Street": "Erlanger Straße 33",
  "PostCode": 91080,
  "City": "Uttenreuth",
  "Latitude": "49.596.263",
  "Longitude": "11.068.797",
  "Telephone": "0913157088",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "17:00",
  "IsOpen24Hours": "Nein",
  "location": "49.596263,11.068797",
  "id": "49.596263,11.068797"
}, {
  "Brand": "ARAL",
  "Street": "Bamberger Straße 17",
  "PostCode": 97483,
  "City": "Eltmann",
  "Latitude": "49.971.124",
  "Longitude": "10.670.604",
  "Telephone": "09522271",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "18:30",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "18:30",
  "SundayFrom": "10:00",
  "SundayTo": "18:30",
  "IsOpen24Hours": "Nein",
  "location": "49.971124,10.670604",
  "id": "49.971124,10.670604"
}, {
  "Brand": "ARAL",
  "Street": "Klein-Nürnberg 24",
  "PostCode": 96106,
  "City": "Ebern",
  "Latitude": "50.090.523",
  "Longitude": "10.795.552",
  "Telephone": "095315560",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.090523,10.795552",
  "id": "50.090523,10.795552"
}, {
  "Brand": "ARAL",
  "Street": "Schweinfurter Straße36",
  "PostCode": 97488,
  "City": "Stadtlauringen",
  "Latitude": "50.183.213",
  "Longitude": "10.355.032",
  "Telephone": "09724545",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.183213,10.355032",
  "id": "50.183213,10.355032"
}, {
  "Brand": "ARAL",
  "Street": "Schweinfurter Straße  78",
  "PostCode": 97525,
  "City": "Schwebheim",
  "Latitude": "50.004.266",
  "Longitude": "10.242.368",
  "Telephone": "097231569",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.004266,10.242368",
  "id": "50.004266,10.242368"
}, {
  "Brand": "ARAL",
  "Street": "Erlanger Straße 98",
  "PostCode": 90765,
  "City": "Fürth",
  "Latitude": "49.485.776",
  "Longitude": "10.996.485",
  "Telephone": "09117906217",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.485776,10.996485",
  "id": "49.485776,10.996485"
}, {
  "Brand": "ARAL",
  "Street": "Würzburger Straße 3-5",
  "PostCode": 97753,
  "City": "Karlstadt",
  "Latitude": "49.955.634",
  "Longitude": "09.772.477",
  "Telephone": "093532489",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.955634,9.772477",
  "id": "49.955634,9.772477"
}, {
  "Brand": "ARAL",
  "Street": "Äuss. Bayreuther Str. 137",
  "PostCode": 90411,
  "City": "Nürnberg",
  "Latitude": "49.474.647",
  "Longitude": "11.103.074",
  "Telephone": "0911511470",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.474647,11.103074",
  "id": "49.474647,11.103074"
}, {
  "Brand": "ARAL",
  "Street": "Fürther Straße 1",
  "PostCode": 91315,
  "City": "Höchstadt",
  "Latitude": "49.697.980",
  "Longitude": "10.803.122",
  "Telephone": "091938286",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Ja",
  "location": "49.69798,10.803122",
  "id": "49.69798,10.803122"
}, {
  "Brand": "ARAL",
  "Street": "Ernst-Faber-Straße 19",
  "PostCode": 96450,
  "City": "Coburg",
  "Latitude": "50.248.110",
  "Longitude": "10.965.753",
  "Telephone": "0956110345",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.24811,10.965753",
  "id": "50.24811,10.965753"
}, {
  "Brand": "ARAL",
  "Street": "Bamberger Straße 4",
  "PostCode": 97631,
  "City": "Bad Königshofen",
  "Latitude": "50.298.095",
  "Longitude": "10.468.471",
  "Telephone": "097616451",
  "WeekDayFrom": "07:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.298095,10.468471",
  "id": "50.298095,10.468471"
}, {
  "Brand": "ARAL",
  "Street": "Stammbacher Straße 45",
  "PostCode": 95213,
  "City": "Münchberg",
  "Latitude": "50.190.262",
  "Longitude": "11.778.400",
  "Telephone": "0925180710",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.190262,11.7784",
  "id": "50.190262,11.7784"
}, {
  "Brand": "ARAL",
  "Street": "Nürnberger Straße2",
  "PostCode": 92224,
  "City": "Amberg",
  "Latitude": "49.444.489",
  "Longitude": "11.840.918",
  "Telephone": "09621320040",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.444489,11.840918",
  "id": "49.444489,11.840918"
}, {
  "Brand": "ARAL",
  "Street": "Drausnickstraße 60",
  "PostCode": 91052,
  "City": "Erlangen",
  "Latitude": "49.595.761",
  "Longitude": "11.031.701",
  "Telephone": "0913152370",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.595761,11.031701",
  "id": "49.595761,11.031701"
}, {
  "Brand": "ARAL",
  "Street": "Äuß. Brucker Straße 171",
  "PostCode": 91058,
  "City": "Erlangen",
  "Latitude": "49.577.007",
  "Longitude": "10.986.522",
  "Telephone": "09131640702",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.577007,10.986522",
  "id": "49.577007,10.986522"
}, {
  "Brand": "ARAL",
  "Street": "Rodacher Straße 69",
  "PostCode": 96450,
  "City": "Coburg",
  "Latitude": "50.274.048",
  "Longitude": "10.957.376",
  "Telephone": "0956166036",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.274048,10.957376",
  "id": "50.274048,10.957376"
}, {
  "Brand": "ARAL",
  "Street": "A3 /Am Katzenberg",
  "PostCode": 97084,
  "City": "Würzburg",
  "Latitude": "49.754.668",
  "Longitude": "09.961.424",
  "Telephone": "09316158090",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.754668,9.961424",
  "id": "49.754668,9.961424"
}, {
  "Brand": "ARAL",
  "Street": "Fabrikzeile 5",
  "PostCode": 95028,
  "City": "Hof",
  "Latitude": "50.319.373",
  "Longitude": "11.921.987",
  "Telephone": "092818402310",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.319373,11.921987",
  "id": "50.319373,11.921987"
}, {
  "Brand": "ARAL",
  "Street": "Bamberger Straße 74",
  "PostCode": 91413,
  "City": "Neustadt a. d. Aisch",
  "Latitude": "49.584.064",
  "Longitude": "10.616.671",
  "Telephone": "09161874225",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.584064,10.616671",
  "id": "49.584064,10.616671"
}, {
  "Brand": "ARAL",
  "Street": "Mainstraße",
  "PostCode": 97340,
  "City": "Marktbreit",
  "Latitude": "49.669.033",
  "Longitude": "10.145.292",
  "Telephone": "093321303",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.669033,10.145292",
  "id": "49.669033,10.145292"
}, {
  "Brand": "ARAL",
  "Street": "Maintalstraße 159",
  "PostCode": 95460,
  "City": "Bad Berneck",
  "Latitude": "50.037.669",
  "Longitude": "11.677.459",
  "Telephone": "09273502042",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.037669,11.677459",
  "id": "50.037669,11.677459"
}, {
  "Brand": "ARAL",
  "Street": "Amberger Straße 18",
  "PostCode": 92318,
  "City": "Neumarkt",
  "Latitude": "49.285.191",
  "Longitude": "11.465.289",
  "Telephone": "0918143441",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.285191,11.465289",
  "id": "49.285191,11.465289"
}, {
  "Brand": "ARAL",
  "Street": "Jakob-Degen-Straße 21",
  "PostCode": 96346,
  "City": "Wallenfels",
  "Latitude": "50.266.022",
  "Longitude": "11.475.579",
  "Telephone": "09262993655",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "14:00",
  "SundayFrom": "09:00",
  "SundayTo": "00:01",
  "IsOpen24Hours": "Nein",
  "location": "50.266022,11.475579",
  "id": "50.266022,11.475579"
}, {
  "Brand": "ARAL",
  "Street": "Attelsdorf 19",
  "PostCode": 96132,
  "City": "Schlüsselfeld",
  "Latitude": "49.745.809",
  "Longitude": "10.634.290",
  "Telephone": "09552444",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.745809,10.63429",
  "id": "49.745809,10.63429"
}, {
  "Brand": "ARAL",
  "Street": "Kitzinger Straße38",
  "PostCode": 97359,
  "City": "Schwarzach",
  "Latitude": "49.789.618",
  "Longitude": "10.215.601",
  "Telephone": "0932499809",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.789618,10.215601",
  "id": "49.789618,10.215601"
}, {
  "Brand": "ARAL",
  "Street": "Nürnberger Straße 25",
  "PostCode": 90518,
  "City": "Altdorf",
  "Latitude": "49.383.969",
  "Longitude": "11.348.050",
  "Telephone": "091871515",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.383969,11.34805",
  "id": "49.383969,11.34805"
}, {
  "Brand": "ARAL",
  "Street": "Würzburger Straße 1u.3",
  "PostCode": 97241,
  "City": "Bergtheim",
  "Latitude": "49.897.438",
  "Longitude": "10.067.211",
  "Telephone": "09367522",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "49.897438,10.067211",
  "id": "49.897438,10.067211"
}, {
  "Brand": "ARAL",
  "Street": "Bamberger Straße 23",
  "PostCode": 97337,
  "City": "Dettelbach",
  "Latitude": "49.801.515",
  "Longitude": "10.165.631",
  "Telephone": "093241468",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.801515,10.165631",
  "id": "49.801515,10.165631"
}, {
  "Brand": "ARAL",
  "Street": "Lichtenfelser Straße 38",
  "PostCode": 96231,
  "City": "Bad Staffelstein",
  "Latitude": "50.103.878",
  "Longitude": "11.005.794",
  "Telephone": "095735700",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.103878,11.005794",
  "id": "50.103878,11.005794"
}, {
  "Brand": "ARAL",
  "Street": "Würzburger Straße 59",
  "PostCode": 63743,
  "City": "Aschaffenburg",
  "Latitude": "49.969.923",
  "Longitude": "09.163.068",
  "Telephone": "06021930563",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.969923,9.163068",
  "id": "49.969923,9.163068"
}, {
  "Brand": "ARAL",
  "Street": "Schweinauer Hauptstr. 92",
  "PostCode": 90441,
  "City": "Nürnberg",
  "Latitude": "49.429.339",
  "Longitude": "11.043.851",
  "Telephone": "09116266537",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.429339,11.043851",
  "id": "49.429339,11.043851"
}, {
  "Brand": "ARAL",
  "Street": "Ulmenstraße 31",
  "PostCode": 90443,
  "City": "Nürnberg",
  "Latitude": "49.429.776",
  "Longitude": "11.070.766",
  "Telephone": "0911414155",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.429776,11.070766",
  "id": "49.429776,11.070766"
}, {
  "Brand": "ARAL",
  "Street": "Röthenbacher Straße 4",
  "PostCode": 90571,
  "City": "Schwaig",
  "Latitude": "49.472.108",
  "Longitude": "11.201.648",
  "Telephone": "0911500169",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.472108,11.201648",
  "id": "49.472108,11.201648"
}, {
  "Brand": "ARAL",
  "Street": "Fichtelgebirgsstraße 2",
  "PostCode": 95709,
  "City": "Tröstau",
  "Latitude": "50.015.959",
  "Longitude": "11.958.745",
  "Telephone": "092326193",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "08:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "50.015959,11.958745",
  "id": "50.015959,11.958745"
}, {
  "Brand": "ARAL",
  "Street": "Wodanstraße 27",
  "PostCode": 90461,
  "City": "Nürnberg",
  "Latitude": "49.437.191",
  "Longitude": "11.094.202",
  "Telephone": "0911460780",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.437191,11.094202",
  "id": "49.437191,11.094202"
}, {
  "Brand": "ARAL",
  "Street": "Römerstraße 98",
  "PostCode": 63785,
  "City": "Obernburg",
  "Latitude": "49.846.627",
  "Longitude": "09.151.591",
  "Telephone": "0602272033",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.846627,9.151591",
  "id": "49.846627,9.151591"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 163",
  "PostCode": 63768,
  "City": "Hösbach",
  "Latitude": "50.005.175",
  "Longitude": "09.211.158",
  "Telephone": "060215825180",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.005175,9.211158",
  "id": "50.005175,9.211158"
}, {
  "Brand": "ARAL",
  "Street": "B 169",
  "PostCode": 4910,
  "City": "Elsterwerda",
  "Latitude": "51.467.180",
  "Longitude": "13.537.833",
  "Telephone": "035334281",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "51.46718,13.537833",
  "id": "51.46718,13.537833"
}, {
  "Brand": "ARAL",
  "Street": "Aschaffenburger Straße 86",
  "PostCode": 63801,
  "City": "Kleinostheim",
  "Latitude": "49.994.607",
  "Longitude": "09.069.710",
  "Telephone": "060279026",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.994607,9.06971",
  "id": "49.994607,9.06971"
}, {
  "Brand": "ARAL",
  "Street": "Mainzer Straße 42",
  "PostCode": 63897,
  "City": "Miltenberg",
  "Latitude": "49.704.222",
  "Longitude": "09.236.841",
  "Telephone": "093712466",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.704222,9.236841",
  "id": "49.704222,9.236841"
}, {
  "Brand": "ARAL",
  "Street": "Wintersbacher Straße 7",
  "PostCode": 63874,
  "City": "Dammbach",
  "Latitude": "49.860.261",
  "Longitude": "09.291.362",
  "Telephone": "060921539",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "07:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "49.860261,9.291362",
  "id": "49.860261,9.291362"
}, {
  "Brand": "ARAL",
  "Street": "Aschaffenburger Straße33",
  "PostCode": 63762,
  "City": "Großostheim",
  "Latitude": "49.926.198",
  "Longitude": "09.079.335",
  "Telephone": "060269779501",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.926198,9.079335",
  "id": "49.926198,9.079335"
}, {
  "Brand": "ARAL",
  "Street": "Kahlgrundstraße 137",
  "PostCode": 63776,
  "City": "Mömbris",
  "Latitude": "50.054.511",
  "Longitude": "09.194.303",
  "Telephone": "060291654",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.054511,9.194303",
  "id": "50.054511,9.194303"
}, {
  "Brand": "ARAL",
  "Street": "Georg-Ledebour-Straße 4",
  "PostCode": 90473,
  "City": "Nürnberg",
  "Latitude": "49.401.334",
  "Longitude": "11.150.818",
  "Telephone": "09119898970",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.401334,11.150818",
  "id": "49.401334,11.150818"
}, {
  "Brand": "ARAL",
  "Street": "Am Europakanal 26",
  "PostCode": 91056,
  "City": "Erlangen",
  "Latitude": "49.592.960",
  "Longitude": "10.970.050",
  "Telephone": "0913147599",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.59296,10.97005",
  "id": "49.59296,10.97005"
}, {
  "Brand": "ARAL",
  "Street": "A9",
  "PostCode": 95180,
  "City": "Berg",
  "Latitude": "50.406.671",
  "Longitude": "11.774.050",
  "Telephone": "09293940940",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.406671,11.77405",
  "id": "50.406671,11.77405"
}, {
  "Brand": "ARAL",
  "Street": "Kantstraße 24",
  "PostCode": 97074,
  "City": "Würzburg",
  "Latitude": "49.778.493",
  "Longitude": "09.942.680",
  "Telephone": "0931883082",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.778493,9.94268",
  "id": "49.778493,9.94268"
}, {
  "Brand": "ARAL",
  "Street": "Kronenstraße 42",
  "PostCode": 79211,
  "City": "Denzlingen",
  "Latitude": "48.065.614",
  "Longitude": "07.868.078",
  "Telephone": "076669446813",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.065614,7.868078",
  "id": "48.065614,7.868078"
}, {
  "Brand": "ARAL",
  "Street": "Freiburger Straße 11",
  "PostCode": 77933,
  "City": "Lahr",
  "Latitude": "48.340.505",
  "Longitude": "07.841.934",
  "Telephone": "0782142263",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.340505,7.841934",
  "id": "48.340505,7.841934"
}, {
  "Brand": "ARAL",
  "Street": "Neckarstraße 68",
  "PostCode": 78056,
  "City": "V.S.-Schwenningen",
  "Latitude": "48.057.713",
  "Longitude": "08.535.515",
  "Telephone": "07720956960",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.057713,8.535515",
  "id": "48.057713,8.535515"
}, {
  "Brand": "ARAL",
  "Street": "Schönauer Straße 22",
  "PostCode": 79674,
  "City": "Todtnau",
  "Latitude": "47.828.035",
  "Longitude": "07.945.025",
  "Telephone": "076717499973",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "19:30",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:30",
  "SundayFrom": "07:30",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "47.828035,7.945025",
  "id": "47.828035,7.945025"
}, {
  "Brand": "ARAL",
  "Street": "Leutkircher Straße 21",
  "PostCode": 88410,
  "City": "Bad Wurzach",
  "Latitude": "47.905.077",
  "Longitude": "09.895.639",
  "Telephone": "075642364",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.905077,9.895639",
  "id": "47.905077,9.895639"
}, {
  "Brand": "ARAL",
  "Street": "Bregstraße  40a",
  "PostCode": 78120,
  "City": "Furtwangen",
  "Latitude": "48.045.353",
  "Longitude": "08.196.713",
  "Telephone": "077234460",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "19:00",
  "SundayFrom": "05:30",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "48.045353,8.196713",
  "id": "48.045353,8.196713"
}, {
  "Brand": "ARAL",
  "Street": "Schrahöfe 3",
  "PostCode": 79215,
  "City": "Elzach",
  "Latitude": "48.184.721",
  "Longitude": "08.085.626",
  "Telephone": "076829209402",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.184721,8.085626",
  "id": "48.184721,8.085626"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 34",
  "PostCode": 78132,
  "City": "Hornberg",
  "Latitude": "48.213.820",
  "Longitude": "08.227.461",
  "Telephone": "07833363",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.21382,8.227461",
  "id": "48.21382,8.227461"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 106",
  "PostCode": 79336,
  "City": "Herbolzheim",
  "Latitude": "48.216.332",
  "Longitude": "07.779.243",
  "Telephone": "076434230",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:30",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:30",
  "SundayFrom": "07:00",
  "SundayTo": "21:30",
  "IsOpen24Hours": "Nein",
  "location": "48.216332,7.779243",
  "id": "48.216332,7.779243"
}, {
  "Brand": "ARAL",
  "Street": "Freiburger Landstraße 23",
  "PostCode": 79112,
  "City": "Freiburg",
  "Latitude": "47.981.943",
  "Longitude": "07.717.552",
  "Telephone": "076644846",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.981943,7.717552",
  "id": "47.981943,7.717552"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 79",
  "PostCode": 88161,
  "City": "Lindenberg",
  "Latitude": "47.599.593",
  "Longitude": "09.884.206",
  "Telephone": "08381927740",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.599593,9.884206",
  "id": "47.599593,9.884206"
}, {
  "Brand": "ARAL",
  "Street": "Turmstraße 33",
  "PostCode": 77933,
  "City": "Lahr",
  "Latitude": "48.341.746",
  "Longitude": "07.876.973",
  "Telephone": "0782125474",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.341746,7.876973",
  "id": "48.341746,7.876973"
}, {
  "Brand": "ARAL",
  "Street": "Vöhrenbacherstraße 38",
  "PostCode": 78050,
  "City": "V.S.-Villingen",
  "Latitude": "48.058.018",
  "Longitude": "08.443.937",
  "Telephone": "0772153511",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.058018,8.443937",
  "id": "48.058018,8.443937"
}, {
  "Brand": "ARAL",
  "Street": "Tettnanger Straße 150",
  "PostCode": 88069,
  "City": "Tettnang",
  "Latitude": "47.668.537",
  "Longitude": "09.561.140",
  "Telephone": "075424073599",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.668537,9.56114",
  "id": "47.668537,9.56114"
}, {
  "Brand": "ARAL",
  "Street": "Lippertsreuterstraße33",
  "PostCode": 88662,
  "City": "Überlingen",
  "Latitude": "47.771.758",
  "Longitude": "09.173.977",
  "Telephone": "075514767",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "05:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.771758,9.173977",
  "id": "47.771758,9.173977"
}, {
  "Brand": "ARAL",
  "Street": "Bei der Pürschmark 4",
  "PostCode": 78532,
  "City": "Tuttlingen",
  "Latitude": "47.967.969",
  "Longitude": "08.787.334",
  "Telephone": "07461164574",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "05:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.967969,8.787334",
  "id": "47.967969,8.787334"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 49",
  "PostCode": 79591,
  "City": "Eimeldingen",
  "Latitude": "47.633.867",
  "Longitude": "07.594.061",
  "Telephone": "076216908035",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "18:00",
  "SaturdayFrom": "09:00",
  "SaturdayTo": "16:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "47.633867,7.594061",
  "id": "47.633867,7.594061"
}, {
  "Brand": "ARAL",
  "Street": "Titiseestraße 21",
  "PostCode": 79822,
  "City": "Titisee-Neustadt",
  "Latitude": "47.919.573",
  "Longitude": "08.205.113",
  "Telephone": "07651/9361864",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "05:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.919573,8.205113",
  "id": "47.919573,8.205113"
}, {
  "Brand": "ARAL",
  "Street": "Überlinger Straße 58",
  "PostCode": 88630,
  "City": "Pfullendorf",
  "Latitude": "47.927.663",
  "Longitude": "09.243.076",
  "Telephone": "075528625",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "04:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "05:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "47.927663,9.243076",
  "id": "47.927663,9.243076"
}, {
  "Brand": "ARAL",
  "Street": "Schwarzwaldstrasse 246",
  "PostCode": 79117,
  "City": "Freiburg",
  "Latitude": "47.987.008",
  "Longitude": "07.895.689",
  "Telephone": "076169077",
  "WeekDayFrom": "08:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.987008,7.895689",
  "id": "47.987008,7.895689"
}, {
  "Brand": "ARAL",
  "Street": "Leo-Wohleb-Straße 2",
  "PostCode": 79098,
  "City": "Freiburg",
  "Latitude": "47.990.339",
  "Longitude": "07.857.909",
  "Telephone": "0761709731",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.990339,7.857909",
  "id": "47.990339,7.857909"
}, {
  "Brand": "ARAL",
  "Street": "Waldseer Straße64",
  "PostCode": 88250,
  "City": "Weingarten",
  "Latitude": "47.817.707",
  "Longitude": "09.642.318",
  "Telephone": "0751561310",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "05:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "47.817707,9.642318",
  "id": "47.817707,9.642318"
}, {
  "Brand": "ARAL",
  "Street": "Nordwerk 10",
  "PostCode": 78176,
  "City": "Blumberg",
  "Latitude": "47.846.209",
  "Longitude": "08.554.895",
  "Telephone": "077025783",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "05:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Ja",
  "location": "47.846209,8.554895",
  "id": "47.846209,8.554895"
}, {
  "Brand": "ARAL",
  "Street": "Friedrichstraße 57",
  "PostCode": 79618,
  "City": "Rheinfelden",
  "Latitude": "47.562.824",
  "Longitude": "07.792.269",
  "Telephone": "076231286",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.562824,7.792269",
  "id": "47.562824,7.792269"
}, {
  "Brand": "ARAL",
  "Street": "Bundesstraße 7",
  "PostCode": 79780,
  "City": "Stühlingen",
  "Latitude": "47.751.054",
  "Longitude": "08.458.030",
  "Telephone": "077445211",
  "WeekDayFrom": "07:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "47.751054,8.45803",
  "id": "47.751054,8.45803"
}, {
  "Brand": "ARAL",
  "Street": "Augsburger Straße 32",
  "PostCode": 93051,
  "City": "Regensburg",
  "Latitude": "49.003.172",
  "Longitude": "12.081.405",
  "Telephone": "094196564",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "49.003172,12.081405",
  "id": "49.003172,12.081405"
}, {
  "Brand": "ARAL",
  "Street": "Amberger Straße 31",
  "PostCode": 92521,
  "City": "Schwarzenfeld",
  "Latitude": "49.388.114",
  "Longitude": "12.134.007",
  "Telephone": "09435501210",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.388114,12.134007",
  "id": "49.388114,12.134007"
}, {
  "Brand": "ARAL",
  "Street": "Viechtacher Straße 4",
  "PostCode": 94239,
  "City": "Ruhmannsfelden",
  "Latitude": "48.984.764",
  "Longitude": "12.980.859",
  "Telephone": "099291041",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "19:30",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:30",
  "SundayFrom": "09:00",
  "SundayTo": "18:00",
  "IsOpen24Hours": "Nein",
  "location": "48.984764,12.980859",
  "id": "48.984764,12.980859"
}, {
  "Brand": "ARAL",
  "Street": "Chamer Straße 52",
  "PostCode": 93426,
  "City": "Roding",
  "Latitude": "49.199.259",
  "Longitude": "12.524.314",
  "Telephone": "094612109",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.199259,12.524314",
  "id": "49.199259,12.524314"
}, {
  "Brand": "ARAL",
  "Street": "Bernauer Straße 20",
  "PostCode": 94356,
  "City": "Kirchroth",
  "Latitude": "48.947.363",
  "Longitude": "12.545.309",
  "Telephone": "09428226",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.947363,12.545309",
  "id": "48.947363,12.545309"
}, {
  "Brand": "ARAL",
  "Street": "Landshuter Straße 20",
  "PostCode": 84061,
  "City": "Ergoldsbach",
  "Latitude": "48.684.720",
  "Longitude": "12.206.087",
  "Telephone": "087711345",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.68472,12.206087",
  "id": "48.68472,12.206087"
}, {
  "Brand": "ARAL",
  "Street": "Puricellistraße 3",
  "PostCode": 93049,
  "City": "Regensburg",
  "Latitude": "49.022.498",
  "Longitude": "12.062.694",
  "Telephone": "094123371",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.022498,12.062694",
  "id": "49.022498,12.062694"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 18",
  "PostCode": 84130,
  "City": "Dingolfing",
  "Latitude": "48.636.243",
  "Longitude": "12.495.942",
  "Telephone": "0873173537",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.636243,12.495942",
  "id": "48.636243,12.495942"
}, {
  "Brand": "ARAL",
  "Street": "Passauer Straße 18",
  "PostCode": 94121,
  "City": "Salzweg",
  "Latitude": "48.618.297",
  "Longitude": "13.483.034",
  "Telephone": "085142693",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:30",
  "SundayFrom": "08:00",
  "SundayTo": "19:30",
  "IsOpen24Hours": "Nein",
  "location": "48.618297,13.483034",
  "id": "48.618297,13.483034"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 61",
  "PostCode": 92345,
  "City": "Dietfurt",
  "Latitude": "49.033.131",
  "Longitude": "11.592.952",
  "Telephone": "08464602953",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.033131,11.592952",
  "id": "49.033131,11.592952"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Straße 1",
  "PostCode": 1979,
  "City": "Lauchhammer",
  "Latitude": "51.468.566",
  "Longitude": "13.739.294",
  "Telephone": "035747258",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.468566,13.739294",
  "id": "51.468566,13.739294"
}, {
  "Brand": "ARAL",
  "Street": "Nürnbergerstraße 35",
  "PostCode": 92533,
  "City": "Wernberg-Köblitz",
  "Latitude": "49.537.968",
  "Longitude": "12.156.044",
  "Telephone": "096042272",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "08:00",
  "SundayTo": "18:00",
  "IsOpen24Hours": "Nein",
  "location": "49.537968,12.156044",
  "id": "49.537968,12.156044"
}, {
  "Brand": "ARAL",
  "Street": "Dornbergsweg 49",
  "PostCode": 38855,
  "City": "Wernigerode",
  "Latitude": "51.852.873",
  "Longitude": "10.789.973",
  "Telephone": "03943533318",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.852873,10.789973",
  "id": "51.852873,10.789973"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Straße 39",
  "PostCode": 6886,
  "City": "Lutherstadt Wittenbe",
  "Latitude": "51.878.665",
  "Longitude": "12.651.871",
  "Telephone": "03491481100",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.878665,12.651871",
  "id": "51.878665,12.651871"
}, {
  "Brand": "ARAL",
  "Street": "Dessauer Straße 274 a",
  "PostCode": 6886,
  "City": "Lutherstadt Wittenbe",
  "Latitude": "51.865.645",
  "Longitude": "12.621.698",
  "Telephone": "03491667838",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.865645,12.621698",
  "id": "51.865645,12.621698"
}, {
  "Brand": "ARAL",
  "Street": "Helmestraße 109",
  "PostCode": 99734,
  "City": "Nordhausen",
  "Latitude": "51.477.266",
  "Longitude": "10.808.441",
  "Telephone": "03631602241",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.477266,10.808441",
  "id": "51.477266,10.808441"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Allee / B 2",
  "PostCode": 16303,
  "City": "Schwedt",
  "Latitude": "53.046.314",
  "Longitude": "14.265.841",
  "Telephone": "0333223206",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "53.046314,14.265841",
  "id": "53.046314,14.265841"
}, {
  "Brand": "ARAL",
  "Street": "Zollberg 70",
  "PostCode": 6449,
  "City": "Aschersleben",
  "Latitude": "51.757.477",
  "Longitude": "11.442.081",
  "Telephone": "03473226798",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.757477,11.442081",
  "id": "51.757477,11.442081"
}, {
  "Brand": "ARAL",
  "Street": "Zehistaer Straße 1",
  "PostCode": 1796,
  "City": "Pirna",
  "Latitude": "50.953.159",
  "Longitude": "13.938.441",
  "Telephone": "03501769123",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "07:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "50.953159,13.938441",
  "id": "50.953159,13.938441"
}, {
  "Brand": "ARAL",
  "Street": "Castrop-Rauxel-Allee 2",
  "PostCode": 16792,
  "City": "Zehdenick",
  "Latitude": "52.979.558",
  "Longitude": "13.344.590",
  "Telephone": "033072291",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.979558,13.34459",
  "id": "52.979558,13.34459"
}, {
  "Brand": "ARAL",
  "Street": "Stralsunder Chaussee 23",
  "PostCode": 18528,
  "City": "Bergen",
  "Latitude": "54.407.520",
  "Longitude": "13.416.135",
  "Telephone": "03838251820",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "54.40752,13.416135",
  "id": "54.40752,13.416135"
}, {
  "Brand": "ARAL",
  "Street": "Neubrandenburger Str. 10",
  "PostCode": 17098,
  "City": "Friedland",
  "Latitude": "53.660.491",
  "Longitude": "13.541.973",
  "Telephone": "03960120313",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "05:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "53.660491,13.541973",
  "id": "53.660491,13.541973"
}, {
  "Brand": "ARAL",
  "Street": "Schönwalder Landstraße 1",
  "PostCode": 17489,
  "City": "Greifswald",
  "Latitude": "54.072.864",
  "Longitude": "13.383.331",
  "Telephone": "03834500709",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "54.072864,13.383331",
  "id": "54.072864,13.383331"
}, {
  "Brand": "ARAL",
  "Street": "An der Karlskuppe 1",
  "PostCode": 99817,
  "City": "Eisenach",
  "Latitude": "50.994.186",
  "Longitude": "10.292.966",
  "Telephone": "03691818095",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.994186,10.292966",
  "id": "50.994186,10.292966"
}, {
  "Brand": "ARAL",
  "Street": "Olvenstedter Graseweg",
  "PostCode": 39128,
  "City": "Magdeburg",
  "Latitude": "52.156.639",
  "Longitude": "11.588.091",
  "Telephone": "03917226129",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.156639,11.588091",
  "id": "52.156639,11.588091"
}, {
  "Brand": "ARAL",
  "Street": "Industriestraße 1",
  "PostCode": 37339,
  "City": "Worbis",
  "Latitude": "51.413.226",
  "Longitude": "10.357.825",
  "Telephone": "03607430316",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.413226,10.357825",
  "id": "51.413226,10.357825"
}, {
  "Brand": "ARAL",
  "Street": "Alte Klockenhäger Landstraße 1",
  "PostCode": 18311,
  "City": "Ribnitz-Damgarten",
  "Latitude": "54.239.377",
  "Longitude": "12.407.908",
  "Telephone": "03821811922",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.239377,12.407908",
  "id": "54.239377,12.407908"
}, {
  "Brand": "ARAL",
  "Street": "Hohensteiner Chaussee",
  "PostCode": 15344,
  "City": "Strausberg",
  "Latitude": "52.571.617",
  "Longitude": "13.902.983",
  "Telephone": "0334122321",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.571617,13.902983",
  "id": "52.571617,13.902983"
}, {
  "Brand": "ARAL",
  "Street": "Lychener Straße 15",
  "PostCode": 17268,
  "City": "Templin",
  "Latitude": "53.128.937",
  "Longitude": "13.489.475",
  "Telephone": "039876822",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.128937,13.489475",
  "id": "53.128937,13.489475"
}, {
  "Brand": "ARAL",
  "Street": "An der B 167 Nr. 4",
  "PostCode": 16244,
  "City": "Finowfurt",
  "Latitude": "52.849.858",
  "Longitude": "13.684.941",
  "Telephone": "033357308",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.849858,13.684941",
  "id": "52.849858,13.684941"
}, {
  "Brand": "ARAL",
  "Street": "Freienhufener Straße 21",
  "PostCode": 1983,
  "City": "Großräschen",
  "Latitude": "51.585.775",
  "Longitude": "14.002.684",
  "Telephone": "0357533140",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.585775,14.002684",
  "id": "51.585775,14.002684"
}, {
  "Brand": "ARAL",
  "Street": "Thomas-Müntzer-Strasse 2",
  "PostCode": 6217,
  "City": "Merseburg",
  "Latitude": "51.342.787",
  "Longitude": "11.989.200",
  "Telephone": "03461525570",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.342787,11.9892",
  "id": "51.342787,11.9892"
}, {
  "Brand": "ARAL",
  "Street": "Friedländer Landstr. 21 a",
  "PostCode": 17389,
  "City": "Anklam",
  "Latitude": "53.843.132",
  "Longitude": "13.684.718",
  "Telephone": "03971214444",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.843132,13.684718",
  "id": "53.843132,13.684718"
}, {
  "Brand": "ARAL",
  "Street": "Poststrasse 21",
  "PostCode": 17139,
  "City": "Malchin",
  "Latitude": "53.741.630",
  "Longitude": "12.766.596",
  "Telephone": "03994222308",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.74163,12.766596",
  "id": "53.74163,12.766596"
}, {
  "Brand": "ARAL",
  "Street": "Berstetal/A 13",
  "PostCode": 15926,
  "City": "Luckau",
  "Latitude": "51.910.900",
  "Longitude": "13.796.500",
  "Telephone": "0354566820",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.9109,13.7965",
  "id": "51.9109,13.7965"
}, {
  "Brand": "ARAL",
  "Street": "Rüblingsheide/A 13",
  "PostCode": 15926,
  "City": "Luckau",
  "Latitude": "51.888.749",
  "Longitude": "13.837.191",
  "Telephone": "0354566830",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.888749,13.837191",
  "id": "51.888749,13.837191"
}, {
  "Brand": "ARAL",
  "Street": "Laugkfeld 30",
  "PostCode": 1968,
  "City": "Senftenberg",
  "Latitude": "51.527.646",
  "Longitude": "14.011.302",
  "Telephone": "03573794952",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Ja",
  "location": "51.527646,14.011302",
  "id": "51.527646,14.011302"
}, {
  "Brand": "ARAL",
  "Street": "Camburger Straße 64",
  "PostCode": 7743,
  "City": "Jena",
  "Latitude": "50.942.558",
  "Longitude": "11.597.068",
  "Telephone": "03641424000",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.942558,11.597068",
  "id": "50.942558,11.597068"
}, {
  "Brand": "ARAL",
  "Street": "Triftstraße 36",
  "PostCode": 15517,
  "City": "Fürstenwalde",
  "Latitude": "52.378.354",
  "Longitude": "14.073.492",
  "Telephone": "03361349966",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.378354,14.073492",
  "id": "52.378354,14.073492"
}, {
  "Brand": "ARAL",
  "Street": "Dahlener Straße 3",
  "PostCode": 4860,
  "City": "Torgau",
  "Latitude": "51.555.118",
  "Longitude": "13.000.817",
  "Telephone": "03421712851",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.555118,13.000817",
  "id": "51.555118,13.000817"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Chaussee 22",
  "PostCode": 15234,
  "City": "Frankfurt",
  "Latitude": "52.365.169",
  "Longitude": "14.522.379",
  "Telephone": "033564190",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.365169,14.522379",
  "id": "52.365169,14.522379"
}, {
  "Brand": "ARAL",
  "Street": "Doberaner Straße/Gewerbegebiet",
  "PostCode": 18225,
  "City": "Kühlungsborn",
  "Latitude": "54.144.393",
  "Longitude": "11.768.801",
  "Telephone": "0382936111",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "54.144393,11.768801",
  "id": "54.144393,11.768801"
}, {
  "Brand": "ARAL",
  "Street": "Langensalzaer Landstraße",
  "PostCode": 99974,
  "City": "Mühlhausen",
  "Latitude": "51.198.577",
  "Longitude": "10.478.381",
  "Telephone": "03601440450",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.198577,10.478381",
  "id": "51.198577,10.478381"
}, {
  "Brand": "ARAL",
  "Street": "Pritzwalker Straße",
  "PostCode": 16909,
  "City": "Wittstock",
  "Latitude": "53.160.360",
  "Longitude": "12.467.420",
  "Telephone": "03394433198",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.16036,12.46742",
  "id": "53.16036,12.46742"
}, {
  "Brand": "ARAL",
  "Street": "Am Grünen Tal 10",
  "PostCode": 19063,
  "City": "Schwerin",
  "Latitude": "53.597.979",
  "Longitude": "11.438.370",
  "Telephone": "03853941059",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.597979,11.43837",
  "id": "53.597979,11.43837"
}, {
  "Brand": "ARAL",
  "Street": "Münsaer Straße 65  /B 180",
  "PostCode": 4600,
  "City": "Altenburg",
  "Latitude": "50.983.298",
  "Longitude": "12.456.116",
  "Telephone": "03447579249",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.983298,12.456116",
  "id": "50.983298,12.456116"
}, {
  "Brand": "ARAL",
  "Street": "Glauchauer Landstraße 70",
  "PostCode": 8451,
  "City": "Crimmitschau",
  "Latitude": "50.819.565",
  "Longitude": "12.405.028",
  "Telephone": "037625374",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "06:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.819565,12.405028",
  "id": "50.819565,12.405028"
}, {
  "Brand": "ARAL",
  "Street": "Äußere Dresdner Straße",
  "PostCode": 8066,
  "City": "Zwickau",
  "Latitude": "50.718.671",
  "Longitude": "12.515.612",
  "Telephone": "0375477462",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.718671,12.515612",
  "id": "50.718671,12.515612"
}, {
  "Brand": "ARAL",
  "Street": "Plauer Chaussee 18",
  "PostCode": 19386,
  "City": "Lübz",
  "Latitude": "53.463.260",
  "Longitude": "12.046.955",
  "Telephone": "03873122205",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "05:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.46326,12.046955",
  "id": "53.46326,12.046955"
}, {
  "Brand": "ARAL",
  "Street": "Rövershäger Chaussee 11",
  "PostCode": 18146,
  "City": "Rostock",
  "Latitude": "54.099.553",
  "Longitude": "12.176.054",
  "Telephone": "0381690210",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.099553,12.176054",
  "id": "54.099553,12.176054"
}, {
  "Brand": "ARAL",
  "Street": "Sternstraße 10",
  "PostCode": 38820,
  "City": "Halberstadt",
  "Latitude": "51.894.167",
  "Longitude": "11.034.996",
  "Telephone": "03941441180",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.894167,11.034996",
  "id": "51.894167,11.034996"
}, {
  "Brand": "ARAL",
  "Street": "Neue Halberstädter Str.67",
  "PostCode": 38889,
  "City": "Blankenburg",
  "Latitude": "51.799.814",
  "Longitude": "10.949.071",
  "Telephone": "03944365438",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.799814,10.949071",
  "id": "51.799814,10.949071"
}, {
  "Brand": "ARAL",
  "Street": "Ohrdrufer Straße",
  "PostCode": 99867,
  "City": "Gotha",
  "Latitude": "50.936.511",
  "Longitude": "10.716.086",
  "Telephone": "03621709631",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.936511,10.716086",
  "id": "50.936511,10.716086"
}, {
  "Brand": "ARAL",
  "Street": "Stadtrodaer Straße 102",
  "PostCode": 7747,
  "City": "Jena",
  "Latitude": "50.877.387",
  "Longitude": "11.621.981",
  "Telephone": "03641334142",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.877387,11.621981",
  "id": "50.877387,11.621981"
}, {
  "Brand": "ARAL",
  "Street": "Gothaer Straße 158",
  "PostCode": 98528,
  "City": "Suhl",
  "Latitude": "50.636.489",
  "Longitude": "10.697.485",
  "Telephone": "03681461595",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.636489,10.697485",
  "id": "50.636489,10.697485"
}, {
  "Brand": "ARAL",
  "Street": "Essener Straße 31",
  "PostCode": 4357,
  "City": "Leipzig",
  "Latitude": "51.376.576",
  "Longitude": "12.399.026",
  "Telephone": "03416022765",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.376576,12.399026",
  "id": "51.376576,12.399026"
}, {
  "Brand": "ARAL",
  "Street": "Hammerstraße 65",
  "PostCode": 8523,
  "City": "Plauen",
  "Latitude": "50.499.787",
  "Longitude": "12.147.388",
  "Telephone": "03741223173",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.499787,12.147388",
  "id": "50.499787,12.147388"
}, {
  "Brand": "ARAL",
  "Street": "Chemnitzer Straße 8a",
  "PostCode": 8294,
  "City": "Lößnitz",
  "Latitude": "50.631.517",
  "Longitude": "12.736.795",
  "Telephone": "0377135357",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.631517,12.736795",
  "id": "50.631517,12.736795"
}, {
  "Brand": "ARAL",
  "Street": "Hallesche Straße 101",
  "PostCode": 99734,
  "City": "Nordhausen",
  "Latitude": "51.493.673",
  "Longitude": "10.816.218",
  "Telephone": "03631603191",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.493673,10.816218",
  "id": "51.493673,10.816218"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 45/47",
  "PostCode": 15806,
  "City": "Zossen",
  "Latitude": "52.217.103",
  "Longitude": "13.441.134",
  "Telephone": "03377302403",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.217103,13.441134",
  "id": "52.217103,13.441134"
}, {
  "Brand": "ARAL",
  "Street": "Poggestraße 17",
  "PostCode": 17166,
  "City": "Teterow",
  "Latitude": "53.776.953",
  "Longitude": "12.563.496",
  "Telephone": "03996187931",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.776953,12.563496",
  "id": "53.776953,12.563496"
}, {
  "Brand": "ARAL",
  "Street": "Heeresbergstraße 9",
  "PostCode": 7549,
  "City": "Gera",
  "Latitude": "50.842.231",
  "Longitude": "12.076.788",
  "Telephone": "036533022",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.842231,12.076788",
  "id": "50.842231,12.076788"
}, {
  "Brand": "ARAL",
  "Street": "Weimarische Straße 26",
  "PostCode": 99438,
  "City": "Bad Berka",
  "Latitude": "50.904.459",
  "Longitude": "11.278.268",
  "Telephone": "03645841155",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.904459,11.278268",
  "id": "50.904459,11.278268"
}, {
  "Brand": "ARAL",
  "Street": "Hohenzieritzer Straße 34",
  "PostCode": 17235,
  "City": "Neustrelitz",
  "Latitude": "53.372.415",
  "Longitude": "13.074.242",
  "Telephone": "03981203295",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.372415,13.074242",
  "id": "53.372415,13.074242"
}, {
  "Brand": "ARAL",
  "Street": "Salzunger Straße 27",
  "PostCode": 36433,
  "City": "Leimbach",
  "Latitude": "50.813.589",
  "Longitude": "10.204.170",
  "Telephone": "03695628240",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.813589,10.20417",
  "id": "50.813589,10.20417"
}, {
  "Brand": "ARAL",
  "Street": "Potsdamer Straße 82",
  "PostCode": 14542,
  "City": "Werder",
  "Latitude": "52.364.491",
  "Longitude": "12.940.611",
  "Telephone": "0332745835",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.364491,12.940611",
  "id": "52.364491,12.940611"
}, {
  "Brand": "ARAL",
  "Street": "Plauensche Straße 65",
  "PostCode": 8606,
  "City": "Oelsnitz",
  "Latitude": "50.426.245",
  "Longitude": "12.176.679",
  "Telephone": "03742122180",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.426245,12.176679",
  "id": "50.426245,12.176679"
}, {
  "Brand": "ARAL",
  "Street": "Löderburger Straße",
  "PostCode": 39418,
  "City": "Staßfurt",
  "Latitude": "51.867.405",
  "Longitude": "11.556.805",
  "Telephone": "03925621597",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "05:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.867405,11.556805",
  "id": "51.867405,11.556805"
}, {
  "Brand": "ARAL",
  "Street": "Eislebener Chaussee 200",
  "PostCode": 6126,
  "City": "Halle",
  "Latitude": "51.471.557",
  "Longitude": "11.904.981",
  "Telephone": "03456903263",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.471557,11.904981",
  "id": "51.471557,11.904981"
}, {
  "Brand": "ARAL",
  "Street": "Stralsunder Straße 46",
  "PostCode": 18507,
  "City": "Grimmen",
  "Latitude": "54.117.768",
  "Longitude": "13.043.636",
  "Telephone": "0383262443",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "54.117768,13.043636",
  "id": "54.117768,13.043636"
}, {
  "Brand": "ARAL",
  "Street": "Leipziger  Straße 43",
  "PostCode": 9599,
  "City": "Freiberg",
  "Latitude": "50.928.840",
  "Longitude": "13.327.974",
  "Telephone": "0373133699",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:30",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "21:30",
  "SundayFrom": "05:30",
  "SundayTo": "21:30",
  "IsOpen24Hours": "Nein",
  "location": "50.92884,13.327974",
  "id": "50.92884,13.327974"
}, {
  "Brand": "ARAL",
  "Street": "HalberstädterChaussee 196",
  "PostCode": 39116,
  "City": "Magdeburg",
  "Latitude": "52.105.344",
  "Longitude": "11.590.098",
  "Telephone": "039161193822",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.105344,11.590098",
  "id": "52.105344,11.590098"
}, {
  "Brand": "ARAL",
  "Street": "Schilddorf 10",
  "PostCode": 39606,
  "City": "Osterburg",
  "Latitude": "52.768.133",
  "Longitude": "11.754.884",
  "Telephone": "0393783756",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.768133,11.754884",
  "id": "52.768133,11.754884"
}, {
  "Brand": "ARAL",
  "Street": "Ratzeburger Chaussee 3",
  "PostCode": 19205,
  "City": "Gadebusch",
  "Latitude": "53.697.076",
  "Longitude": "11.102.248",
  "Telephone": "03886712717",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.697076,11.102248",
  "id": "53.697076,11.102248"
}, {
  "Brand": "ARAL",
  "Street": "Kreckwitzer Straße 2",
  "PostCode": 2625,
  "City": "Bautzen",
  "Latitude": "51.190.178",
  "Longitude": "14.451.673",
  "Telephone": "03591211090",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Ja",
  "location": "51.190178,14.451673",
  "id": "51.190178,14.451673"
}, {
  "Brand": "ARAL",
  "Street": "Annaberger Straße 94",
  "PostCode": 9120,
  "City": "Chemnitz",
  "Latitude": "50.814.593",
  "Longitude": "12.913.010",
  "Telephone": "03712563997",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.814593,12.91301",
  "id": "50.814593,12.91301"
}, {
  "Brand": "ARAL",
  "Street": "Friedenstraße 36",
  "PostCode": 15517,
  "City": "Fürstenwalde",
  "Latitude": "52.335.668",
  "Longitude": "14.073.532",
  "Telephone": "0336157368",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.335668,14.073532",
  "id": "52.335668,14.073532"
}, {
  "Brand": "ARAL",
  "Street": "Kamenzer Bogen 16",
  "PostCode": 2977,
  "City": "Hoyerswerda",
  "Latitude": "51.424.453",
  "Longitude": "14.225.092",
  "Telephone": "03571406537",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.424453,14.225092",
  "id": "51.424453,14.225092"
}, {
  "Brand": "ARAL",
  "Street": "Triptiser Straße 36",
  "PostCode": 7806,
  "City": "Neustadt",
  "Latitude": "50.738.785",
  "Longitude": "11.768.153",
  "Telephone": "03648122249",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.738785,11.768153",
  "id": "50.738785,11.768153"
}, {
  "Brand": "ARAL",
  "Street": "Freienwalder Straße",
  "PostCode": 16356,
  "City": "Werneuchen",
  "Latitude": "52.635.677",
  "Longitude": "13.744.871",
  "Telephone": "0333987407",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.635677,13.744871",
  "id": "52.635677,13.744871"
}, {
  "Brand": "ARAL",
  "Street": "Rostocker Straße 15",
  "PostCode": 17213,
  "City": "Malchow",
  "Latitude": "53.481.403",
  "Longitude": "12.421.478",
  "Telephone": "03993214757",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.481403,12.421478",
  "id": "53.481403,12.421478"
}, {
  "Brand": "ARAL",
  "Street": "Nieskyer Straße 9",
  "PostCode": 2828,
  "City": "Görlitz",
  "Latitude": "51.163.470",
  "Longitude": "14.972.685",
  "Telephone": "03581316410",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.16347,14.972685",
  "id": "51.16347,14.972685"
}, {
  "Brand": "ARAL",
  "Street": "Anderslebener Straße 42 a",
  "PostCode": 39387,
  "City": "Oschersleben",
  "Latitude": "52.028.823",
  "Longitude": "11.245.980",
  "Telephone": "0394995959",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.028823,11.24598",
  "id": "52.028823,11.24598"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Straße 125",
  "PostCode": 15299,
  "City": "Müllrose",
  "Latitude": "52.258.366",
  "Longitude": "14.426.741",
  "Telephone": "0336064662",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.258366,14.426741",
  "id": "52.258366,14.426741"
}, {
  "Brand": "ARAL",
  "Street": "Kummersdorfer Straße 13 d",
  "PostCode": 15859,
  "City": "Storkow",
  "Latitude": "52.263.618",
  "Longitude": "13.935.584",
  "Telephone": "03367867991",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.263618,13.935584",
  "id": "52.263618,13.935584"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße",
  "PostCode": 99831,
  "City": "Creuzburg",
  "Latitude": "51.050.454",
  "Longitude": "10.226.457",
  "Telephone": "03692698488",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "07:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.050454,10.226457",
  "id": "51.050454,10.226457"
}, {
  "Brand": "ARAL",
  "Street": "Karl-Marx-Straße",
  "PostCode": 15890,
  "City": "Eisenhüttenstadt",
  "Latitude": "52.140.165",
  "Longitude": "14.634.248",
  "Telephone": "0336444953",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.140165,14.634248",
  "id": "52.140165,14.634248"
}, {
  "Brand": "ARAL",
  "Street": "Alvensleber Landstraße 5",
  "PostCode": 39340,
  "City": "Haldensleben",
  "Latitude": "52.276.306",
  "Longitude": "11.389.788",
  "Telephone": "0390443232",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.276306,11.389788",
  "id": "52.276306,11.389788"
}, {
  "Brand": "ARAL",
  "Street": "Neustrelitzer Straße 118",
  "PostCode": 17033,
  "City": "Neubrandenburg",
  "Latitude": "53.530.213",
  "Longitude": "13.261.818",
  "Telephone": "03953687733",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.530213,13.261818",
  "id": "53.530213,13.261818"
}, {
  "Brand": "ARAL",
  "Street": "Neustadter Straße 137",
  "PostCode": 96515,
  "City": "Sonneberg",
  "Latitude": "50.349.146",
  "Longitude": "11.152.811",
  "Telephone": "03675802960",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:30",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "22:30",
  "SundayFrom": "05:30",
  "SundayTo": "22:30",
  "IsOpen24Hours": "Nein",
  "location": "50.349146,11.152811",
  "id": "50.349146,11.152811"
}, {
  "Brand": "ARAL",
  "Street": "Altenburger Straße 79",
  "PostCode": 4539,
  "City": "Groitzsch",
  "Latitude": "51.151.018",
  "Longitude": "12.286.598",
  "Telephone": "03429642265",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "05:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.151018,12.286598",
  "id": "51.151018,12.286598"
}, {
  "Brand": "ARAL",
  "Street": "Luckenwalder Strasse 111",
  "PostCode": 15711,
  "City": "Königs Wusterhausen",
  "Latitude": "52.283.820",
  "Longitude": "13.614.533",
  "Telephone": "03375242010",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.28382,13.614533",
  "id": "52.28382,13.614533"
}, {
  "Brand": "ARAL",
  "Street": "Ludwigsluster Chaussee 24",
  "PostCode": 19370,
  "City": "Parchim",
  "Latitude": "53.426.446",
  "Longitude": "11.819.270",
  "Telephone": "03871443141",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.426446,11.81927",
  "id": "53.426446,11.81927"
}, {
  "Brand": "ARAL",
  "Street": "Zschopauer Straße 234",
  "PostCode": 9126,
  "City": "Chemnitz",
  "Latitude": "50.814.158",
  "Longitude": "12.954.828",
  "Telephone": "037133471632",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.814158,12.954828",
  "id": "50.814158,12.954828"
}, {
  "Brand": "ARAL",
  "Street": "Suhler Straße 5 a",
  "PostCode": 99885,
  "City": "Ohrdruf",
  "Latitude": "50.816.570",
  "Longitude": "10.729.292",
  "Telephone": "03624312755",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.81657,10.729292",
  "id": "50.81657,10.729292"
}, {
  "Brand": "ARAL",
  "Street": "Dresdener Straße 30 t",
  "PostCode": 4720,
  "City": "Döbeln",
  "Latitude": "51.120.479",
  "Longitude": "13.149.826",
  "Telephone": "03431702392",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.120479,13.149826",
  "id": "51.120479,13.149826"
}, {
  "Brand": "ARAL",
  "Street": "Leipziger Straße 95",
  "PostCode": 4668,
  "City": "Grimma",
  "Latitude": "51.231.318",
  "Longitude": "12.703.964",
  "Telephone": "03437917940",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.231318,12.703964",
  "id": "51.231318,12.703964"
}, {
  "Brand": "ARAL",
  "Street": "Jenaer Straße 13",
  "PostCode": 7607,
  "City": "Eisenberg",
  "Latitude": "50.970.106",
  "Longitude": "11.890.035",
  "Telephone": "03669152900",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.970106,11.890035",
  "id": "50.970106,11.890035"
}, {
  "Brand": "ARAL",
  "Street": "Berkaer Straße 71",
  "PostCode": 99425,
  "City": "Weimar",
  "Latitude": "50.960.726",
  "Longitude": "11.316.404",
  "Telephone": "03643905891",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.960726,11.316404",
  "id": "50.960726,11.316404"
}, {
  "Brand": "ARAL",
  "Street": "Magdeburg-Leipziger Chaus",
  "PostCode": 39443,
  "City": "Staßfurt",
  "Latitude": "51.917.186",
  "Longitude": "11.610.609",
  "Telephone": "03926650130",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.917186,11.610609",
  "id": "51.917186,11.610609"
}, {
  "Brand": "ARAL",
  "Street": "Badstraße 69",
  "PostCode": 1454,
  "City": "Radeberg",
  "Latitude": "51.130.991",
  "Longitude": "13.916.689",
  "Telephone": "03528442320",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.130991,13.916689",
  "id": "51.130991,13.916689"
}, {
  "Brand": "ARAL",
  "Street": "Steinfurther Straße/B 184",
  "PostCode": 6766,
  "City": "Wolfen",
  "Latitude": "51.683.379",
  "Longitude": "12.273.757",
  "Telephone": "0349422926",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "06:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.683379,12.273757",
  "id": "51.683379,12.273757"
}, {
  "Brand": "ARAL",
  "Street": "Woldegker Straße 39",
  "PostCode": 17036,
  "City": "Neubrandenburg",
  "Latitude": "53.557.102",
  "Longitude": "13.310.935",
  "Telephone": "03957792285",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.557102,13.310935",
  "id": "53.557102,13.310935"
}, {
  "Brand": "ARAL",
  "Street": "Leipziger Straße 20",
  "PostCode": 4916,
  "City": "Herzberg",
  "Latitude": "51.690.245",
  "Longitude": "13.220.948",
  "Telephone": "0353521440",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.690245,13.220948",
  "id": "51.690245,13.220948"
}, {
  "Brand": "ARAL",
  "Street": "Fischhausstraße 15",
  "PostCode": 1099,
  "City": "Dresden",
  "Latitude": "51.070.460",
  "Longitude": "13.788.781",
  "Telephone": "03518043050",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.07046,13.788781",
  "id": "51.07046,13.788781"
}, {
  "Brand": "ARAL",
  "Street": "Am Eicheneck 7",
  "PostCode": 18273,
  "City": "Güstrow",
  "Latitude": "53.809.594",
  "Longitude": "12.189.835",
  "Telephone": "0384322122",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.809594,12.189835",
  "id": "53.809594,12.189835"
}, {
  "Brand": "ARAL",
  "Street": "An der Hochstraße 16",
  "PostCode": 2906,
  "City": "Niesky",
  "Latitude": "51.280.037",
  "Longitude": "14.818.502",
  "Telephone": "03588206921",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.280037,14.818502",
  "id": "51.280037,14.818502"
}, {
  "Brand": "ARAL",
  "Street": "Am Wilhelmschacht 17",
  "PostCode": 4552,
  "City": "Borna",
  "Latitude": "51.116.192",
  "Longitude": "12.485.927",
  "Telephone": "03433208803",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.116192,12.485927",
  "id": "51.116192,12.485927"
}, {
  "Brand": "ARAL",
  "Street": "Weinböhlaer Straße 46",
  "PostCode": 1640,
  "City": "Coswig",
  "Latitude": "51.135.477",
  "Longitude": "13.576.879",
  "Telephone": "0352373629",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.135477,13.576879",
  "id": "51.135477,13.576879"
}, {
  "Brand": "ARAL",
  "Street": "An der Crivitzer Chaussee",
  "PostCode": 19063,
  "City": "Schwerin",
  "Latitude": "53.600.318",
  "Longitude": "11.465.076",
  "Telephone": "03852072484",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.600318,11.465076",
  "id": "53.600318,11.465076"
}, {
  "Brand": "ARAL",
  "Street": "Dohnaer Straße 103",
  "PostCode": 1219,
  "City": "Dresden",
  "Latitude": "51.013.756",
  "Longitude": "13.774.790",
  "Telephone": "03512842533",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "19:30",
  "SaturdayFrom": "09:00",
  "SaturdayTo": "17:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "51.013756,13.77479",
  "id": "51.013756,13.77479"
}, {
  "Brand": "ARAL",
  "Street": "Geuzer Straße / B 185",
  "PostCode": 6366,
  "City": "Köthen",
  "Latitude": "51.754.600",
  "Longitude": "11.948.810",
  "Telephone": "03496550126",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.7546,11.94881",
  "id": "51.7546,11.94881"
}, {
  "Brand": "ARAL",
  "Street": "Augustenstraße 88",
  "PostCode": 6493,
  "City": "Harzgerode",
  "Latitude": "51.634.599",
  "Longitude": "11.147.129",
  "Telephone": "0394843130",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Ja",
  "location": "51.634599,11.147129",
  "id": "51.634599,11.147129"
}, {
  "Brand": "ARAL",
  "Street": "Schwarzenberger Straße 4",
  "PostCode": 8340,
  "City": "Schwarzenberg",
  "Latitude": "50.531.156",
  "Longitude": "12.811.705",
  "Telephone": "0377486350",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.531156,12.811705",
  "id": "50.531156,12.811705"
}, {
  "Brand": "ARAL",
  "Street": "Arneburger Straße 47/B 18",
  "PostCode": 39576,
  "City": "Stendal",
  "Latitude": "52.618.061",
  "Longitude": "11.868.661",
  "Telephone": "03931796822",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "04:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "04:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Ja",
  "location": "52.618061,11.868661",
  "id": "52.618061,11.868661"
}, {
  "Brand": "ARAL",
  "Street": "Kösener Straße",
  "PostCode": 6618,
  "City": "Naumburg",
  "Latitude": "51.150.973",
  "Longitude": "11.783.996",
  "Telephone": "03445778420",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.150973,11.783996",
  "id": "51.150973,11.783996"
}, {
  "Brand": "ARAL",
  "Street": "Brielower Landstraße 17",
  "PostCode": 14772,
  "City": "Brandenburg",
  "Latitude": "52.430.302",
  "Longitude": "12.553.348",
  "Telephone": "03381700164",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.430302,12.553348",
  "id": "52.430302,12.553348"
}, {
  "Brand": "ARAL",
  "Street": "Wittenberger Straße",
  "PostCode": 6749,
  "City": "Bitterfeld",
  "Latitude": "51.627.109",
  "Longitude": "12.336.667",
  "Telephone": "0349324717",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.627109,12.336667",
  "id": "51.627109,12.336667"
}, {
  "Brand": "ARAL",
  "Street": "Vor dem Rittertor",
  "PostCode": 39164,
  "City": "Wanzleben",
  "Latitude": "52.061.450",
  "Longitude": "11.432.963",
  "Telephone": "03920942078",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "05:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.06145,11.432963",
  "id": "52.06145,11.432963"
}, {
  "Brand": "ARAL",
  "Street": "R.-Luxemburg-Straße 105",
  "PostCode": 6917,
  "City": "Jessen",
  "Latitude": "51.796.673",
  "Longitude": "12.974.008",
  "Telephone": "03537213597",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.796673,12.974008",
  "id": "51.796673,12.974008"
}, {
  "Brand": "ARAL",
  "Street": "Großenhainer Straße 44",
  "PostCode": 1127,
  "City": "Dresden",
  "Latitude": "51.076.612",
  "Longitude": "13.733.248",
  "Telephone": "03518495448",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.076612,13.733248",
  "id": "51.076612,13.733248"
}, {
  "Brand": "ARAL",
  "Street": "Leipziger Straße 206",
  "PostCode": 9114,
  "City": "Chemnitz",
  "Latitude": "50.856.868",
  "Longitude": "12.875.218",
  "Telephone": "0371372415",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.856868,12.875218",
  "id": "50.856868,12.875218"
}, {
  "Brand": "ARAL",
  "Street": "Genthiner Straße 65 a",
  "PostCode": 14712,
  "City": "Rathenow",
  "Latitude": "52.599.503",
  "Longitude": "12.299.260",
  "Telephone": "03385516278",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:30",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Ja",
  "location": "52.599503,12.29926",
  "id": "52.599503,12.29926"
}, {
  "Brand": "ARAL",
  "Street": "McDonalds-Straße 1",
  "PostCode": 16356,
  "City": "Blumberg",
  "Latitude": "52.582.424",
  "Longitude": "13.587.225",
  "Telephone": "0333945150",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.582424,13.587225",
  "id": "52.582424,13.587225"
}, {
  "Brand": "ARAL",
  "Street": "Prenzlauer Chaussee 76",
  "PostCode": 16348,
  "City": "Wandlitz",
  "Latitude": "52.743.148",
  "Longitude": "13.458.450",
  "Telephone": "03339781748",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.743148,13.45845",
  "id": "52.743148,13.45845"
}, {
  "Brand": "ARAL",
  "Street": "Harzweg 19",
  "PostCode": 6484,
  "City": "Quedlinburg",
  "Latitude": "51.783.042",
  "Longitude": "11.146.766",
  "Telephone": "03946701128",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.783042,11.146766",
  "id": "51.783042,11.146766"
}, {
  "Brand": "ARAL",
  "Street": "Straße des Friedens 40",
  "PostCode": 3222,
  "City": "Lübbenau",
  "Latitude": "51.853.334",
  "Longitude": "13.949.292",
  "Telephone": "0354244848",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.853334,13.949292",
  "id": "51.853334,13.949292"
}, {
  "Brand": "ARAL",
  "Street": "Röxer Straße 16",
  "PostCode": 39576,
  "City": "Stendal",
  "Latitude": "52.594.595",
  "Longitude": "11.842.558",
  "Telephone": "03931715620",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.594595,11.842558",
  "id": "52.594595,11.842558"
}, {
  "Brand": "ARAL",
  "Street": "Am Heerweg 33",
  "PostCode": 7768,
  "City": "Kahla",
  "Latitude": "50.809.309",
  "Longitude": "11.577.449",
  "Telephone": "03642424005",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.809309,11.577449",
  "id": "50.809309,11.577449"
}, {
  "Brand": "ARAL",
  "Street": "Zwickauer Straße 240",
  "PostCode": 8468,
  "City": "Reichenbach",
  "Latitude": "50.636.226",
  "Longitude": "12.323.708",
  "Telephone": "0376512016",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "07:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.636226,12.323708",
  "id": "50.636226,12.323708"
}, {
  "Brand": "ARAL",
  "Street": "Trockentalstraße 64",
  "PostCode": 8527,
  "City": "Plauen",
  "Latitude": "50.490.160",
  "Longitude": "12.130.948",
  "Telephone": "03741137066",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.49016,12.130948",
  "id": "50.49016,12.130948"
}, {
  "Brand": "ARAL",
  "Street": "Bockauer Talstraße 20",
  "PostCode": 8280,
  "City": "Aue",
  "Latitude": "50.579.574",
  "Longitude": "12.692.009",
  "Telephone": "0377120368",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Ja",
  "location": "50.579574,12.692009",
  "id": "50.579574,12.692009"
}, {
  "Brand": "ARAL",
  "Street": "Plauensche Straße 70",
  "PostCode": 8223,
  "City": "Falkenstein",
  "Latitude": "50.474.174",
  "Longitude": "12.354.368",
  "Telephone": "0374571566",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "06:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.474174,12.354368",
  "id": "50.474174,12.354368"
}, {
  "Brand": "ARAL",
  "Street": "An der Stadtautobahn 60",
  "PostCode": 18119,
  "City": "Rostock",
  "Latitude": "54.168.422",
  "Longitude": "12.079.958",
  "Telephone": "03815016",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.168422,12.079958",
  "id": "54.168422,12.079958"
}, {
  "Brand": "ARAL",
  "Street": "Chemnitzer Straße 34",
  "PostCode": 9456,
  "City": "Annaberg-Buchholz",
  "Latitude": "50.591.124",
  "Longitude": "13.011.043",
  "Telephone": "0373351015",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.591124,13.011043",
  "id": "50.591124,13.011043"
}, {
  "Brand": "ARAL",
  "Street": "Magdeburger Chaussee 2",
  "PostCode": 6118,
  "City": "Halle",
  "Latitude": "51.521.878",
  "Longitude": "11.953.743",
  "Telephone": "03455210836",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.521878,11.953743",
  "id": "51.521878,11.953743"
}, {
  "Brand": "ARAL",
  "Street": "Reichenbacher Straße 89",
  "PostCode": 2827,
  "City": "Görlitz",
  "Latitude": "51.147.488",
  "Longitude": "14.941.448",
  "Telephone": "0358178180",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.147488,14.941448",
  "id": "51.147488,14.941448"
}, {
  "Brand": "ARAL",
  "Street": "Chemnitzer Straße 47 d",
  "PostCode": 9380,
  "City": "Thalheim",
  "Latitude": "50.710.053",
  "Longitude": "12.865.768",
  "Telephone": "0372138992",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.710053,12.865768",
  "id": "50.710053,12.865768"
}, {
  "Brand": "ARAL",
  "Street": "Merzdorfer Weg 1 B",
  "PostCode": 3042,
  "City": "Cottbus",
  "Latitude": "51.767.249",
  "Longitude": "14.359.060",
  "Telephone": "0355722208",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.767249,14.35906",
  "id": "51.767249,14.35906"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Straße 25a",
  "PostCode": 3226,
  "City": "Vetschau",
  "Latitude": "51.784.779",
  "Longitude": "14.066.966",
  "Telephone": "03543371111",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.784779,14.066966",
  "id": "51.784779,14.066966"
}, {
  "Brand": "ARAL",
  "Street": "Arthur-Scheunert-Allee 73",
  "PostCode": 14558,
  "City": "Nuthetal",
  "Latitude": "52.350.942",
  "Longitude": "13.103.456",
  "Telephone": "03320083903",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.350942,13.103456",
  "id": "52.350942,13.103456"
}, {
  "Brand": "ARAL",
  "Street": "Rumburger Straße 38",
  "PostCode": 2730,
  "City": "Ebersbach",
  "Latitude": "50.997.968",
  "Longitude": "14.616.520",
  "Telephone": "03586300091",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.997968,14.61652",
  "id": "50.997968,14.61652"
}, {
  "Brand": "ARAL",
  "Street": "Weißenfelser Straße 98",
  "PostCode": 6712,
  "City": "Zeitz",
  "Latitude": "51.069.362",
  "Longitude": "12.121.917",
  "Telephone": "03441214095",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:30",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:30",
  "SundayFrom": "07:00",
  "SundayTo": "21:30",
  "IsOpen24Hours": "Ja",
  "location": "51.069362,12.121917",
  "id": "51.069362,12.121917"
}, {
  "Brand": "ARAL",
  "Street": "Kranichfelder Straße 2",
  "PostCode": 99097,
  "City": "Erfurt",
  "Latitude": "50.958.448",
  "Longitude": "11.053.105",
  "Telephone": "03613465366",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.958448,11.053105",
  "id": "50.958448,11.053105"
}, {
  "Brand": "ARAL",
  "Street": "Ludwig-Hupfeld-Straße 15",
  "PostCode": 4178,
  "City": "Leipzig",
  "Latitude": "51.348.533",
  "Longitude": "12.301.473",
  "Telephone": "03414418938",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.348533,12.301473",
  "id": "51.348533,12.301473"
}, {
  "Brand": "ARAL",
  "Street": "Merseburger Straße",
  "PostCode": 6242,
  "City": "Braunsbedra",
  "Latitude": "51.288.618",
  "Longitude": "11.904.674",
  "Telephone": "03463321881",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.288618,11.904674",
  "id": "51.288618,11.904674"
}, {
  "Brand": "ARAL",
  "Street": "Erfurter Straße 35",
  "PostCode": 6526,
  "City": "Sangerhausen",
  "Latitude": "51.456.513",
  "Longitude": "11.300.216",
  "Telephone": "03464517900",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.456513,11.300216",
  "id": "51.456513,11.300216"
}, {
  "Brand": "ARAL",
  "Street": "Merseburger Straße 111",
  "PostCode": 4177,
  "City": "Leipzig",
  "Latitude": "51.338.168",
  "Longitude": "12.325.599",
  "Telephone": "03414801898",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.338168,12.325599",
  "id": "51.338168,12.325599"
}, {
  "Brand": "ARAL",
  "Street": "Löbauer Straße 65",
  "PostCode": 2625,
  "City": "Bautzen",
  "Latitude": "51.178.630",
  "Longitude": "14.453.080",
  "Telephone": "03591211102",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.17863,14.45308",
  "id": "51.17863,14.45308"
}, {
  "Brand": "ARAL",
  "Street": "Pasewalker Straße 110",
  "PostCode": 13127,
  "City": "Berlin",
  "Latitude": "52.586.153",
  "Longitude": "13.429.928",
  "Telephone": "0304767080",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.586153,13.429928",
  "id": "52.586153,13.429928"
}, {
  "Brand": "ARAL",
  "Street": "Mansfelder Straße",
  "PostCode": 6333,
  "City": "Hettstedt",
  "Latitude": "51.635.302",
  "Longitude": "11.506.106",
  "Telephone": "03476810278",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.635302,11.506106",
  "id": "51.635302,11.506106"
}, {
  "Brand": "ARAL",
  "Street": "Bitterfelder Straße 51",
  "PostCode": 6780,
  "City": "Zörbig",
  "Latitude": "51.625.484",
  "Longitude": "12.133.721",
  "Telephone": "034956399202",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.625484,12.133721",
  "id": "51.625484,12.133721"
}, {
  "Brand": "ARAL",
  "Street": "Leipziger Straße 63 a",
  "PostCode": 6231,
  "City": "Bad Dürrenberg",
  "Latitude": "51.290.899",
  "Longitude": "12.076.298",
  "Telephone": "0346282328",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.290899,12.076298",
  "id": "51.290899,12.076298"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Straße 101",
  "PostCode": 15907,
  "City": "Lübben",
  "Latitude": "51.942.419",
  "Longitude": "13.910.870",
  "Telephone": "035468456",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.942419,13.91087",
  "id": "51.942419,13.91087"
}, {
  "Brand": "ARAL",
  "Street": "Albrechtstraße 35",
  "PostCode": 6844,
  "City": "Dessau",
  "Latitude": "51.848.343",
  "Longitude": "12.241.853",
  "Telephone": "03402210103",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.848343,12.241853",
  "id": "51.848343,12.241853"
}, {
  "Brand": "ARAL",
  "Street": "Lüttgenröder Straße 1",
  "PostCode": 38835,
  "City": "Osterwieck",
  "Latitude": "51.968.684",
  "Longitude": "10.699.707",
  "Telephone": "0394216140",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "08:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "51.968684,10.699707",
  "id": "51.968684,10.699707"
}, {
  "Brand": "ARAL",
  "Street": "Lübsche Straße 161",
  "PostCode": 23966,
  "City": "Wismar",
  "Latitude": "53.894.485",
  "Longitude": "11.440.239",
  "Telephone": "03841704718",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.894485,11.440239",
  "id": "53.894485,11.440239"
}, {
  "Brand": "ARAL",
  "Street": "Leipziger Straße 208",
  "PostCode": 8058,
  "City": "Zwickau",
  "Latitude": "50.742.686",
  "Longitude": "12.488.169",
  "Telephone": "0375282684",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.742686,12.488169",
  "id": "50.742686,12.488169"
}, {
  "Brand": "ARAL",
  "Street": "Maximilianallee 70",
  "PostCode": 4129,
  "City": "Leipzig",
  "Latitude": "51.385.800",
  "Longitude": "12.389.745",
  "Telephone": "03419129096",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.3858,12.389745",
  "id": "51.3858,12.389745"
}, {
  "Brand": "ARAL",
  "Street": "Heidestraße 348",
  "PostCode": 6849,
  "City": "Dessau",
  "Latitude": "51.797.997",
  "Longitude": "12.240.115",
  "Telephone": "03408546482",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.797997,12.240115",
  "id": "51.797997,12.240115"
}, {
  "Brand": "ARAL",
  "Street": "Planitzer Straße 3-5",
  "PostCode": 8056,
  "City": "Zwickau",
  "Latitude": "50.704.439",
  "Longitude": "12.493.827",
  "Telephone": "0375282580",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.704439,12.493827",
  "id": "50.704439,12.493827"
}, {
  "Brand": "ARAL",
  "Street": "Rosenfelder Straße 13",
  "PostCode": 6116,
  "City": "Halle",
  "Latitude": "51.502.248",
  "Longitude": "12.033.682",
  "Telephone": "03455606492",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.502248,12.033682",
  "id": "51.502248,12.033682"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Straße 11",
  "PostCode": 15537,
  "City": "Erkner",
  "Latitude": "52.428.055",
  "Longitude": "13.744.191",
  "Telephone": "0336224701",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Ja",
  "location": "52.428055,13.744191",
  "id": "52.428055,13.744191"
}, {
  "Brand": "ARAL",
  "Street": "An der B 100 32",
  "PostCode": 6796,
  "City": "Brehna",
  "Latitude": "51.553.736",
  "Longitude": "12.196.659",
  "Telephone": "03495448837",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.553736,12.196659",
  "id": "51.553736,12.196659"
}, {
  "Brand": "ARAL",
  "Street": "Ernst-Thälmann-Straße 2a",
  "PostCode": 29410,
  "City": "Salzwedel",
  "Latitude": "52.848.653",
  "Longitude": "11.162.870",
  "Telephone": "0390134000",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.848653,11.16287",
  "id": "52.848653,11.16287"
}, {
  "Brand": "ARAL",
  "Street": "Straße des 17. Juni 9",
  "PostCode": 1257,
  "City": "Dresden",
  "Latitude": "51.006.626",
  "Longitude": "13.819.407",
  "Telephone": "03512015155",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "08:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "51.006626,13.819407",
  "id": "51.006626,13.819407"
}, {
  "Brand": "ARAL",
  "Street": "Brander Straße 55",
  "PostCode": 9599,
  "City": "Freiberg",
  "Latitude": "50.903.164",
  "Longitude": "13.337.929",
  "Telephone": "03731767710",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "05:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.903164,13.337929",
  "id": "50.903164,13.337929"
}, {
  "Brand": "ARAL",
  "Street": "Toitenwinkler Allee 1",
  "PostCode": 18147,
  "City": "Rostock",
  "Latitude": "54.113.535",
  "Longitude": "12.161.486",
  "Telephone": "0381690347",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.113535,12.161486",
  "id": "54.113535,12.161486"
}, {
  "Brand": "ARAL",
  "Street": "Hamburger Straße 88 c",
  "PostCode": 1157,
  "City": "Dresden",
  "Latitude": "51.062.807",
  "Longitude": "13.679.735",
  "Telephone": "03514226969",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.062807,13.679735",
  "id": "51.062807,13.679735"
}, {
  "Brand": "ARAL",
  "Street": "Potsdamer Straße 55",
  "PostCode": 14469,
  "City": "Potsdam",
  "Latitude": "52.419.578",
  "Longitude": "13.012.795",
  "Telephone": "03315053806",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.419578,13.012795",
  "id": "52.419578,13.012795"
}, {
  "Brand": "ARAL",
  "Street": "Permoserstraße 32",
  "PostCode": 4318,
  "City": "Leipzig",
  "Latitude": "51.350.964",
  "Longitude": "12.434.185",
  "Telephone": "03412323185",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Ja",
  "location": "51.350964,12.434185",
  "id": "51.350964,12.434185"
}, {
  "Brand": "ARAL",
  "Street": "Wittenburger Straße 121",
  "PostCode": 19059,
  "City": "Schwerin",
  "Latitude": "53.628.492",
  "Longitude": "11.389.790",
  "Telephone": "03857851496",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.628492,11.38979",
  "id": "53.628492,11.38979"
}, {
  "Brand": "ARAL",
  "Street": "Königsbrücker  Straße 136",
  "PostCode": 1099,
  "City": "Dresden",
  "Latitude": "51.093.858",
  "Longitude": "13.766.493",
  "Telephone": "03518807875",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "17:00",
  "SaturdayFrom": "09:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "09:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "51.093858,13.766493",
  "id": "51.093858,13.766493"
}, {
  "Brand": "ARAL",
  "Street": "Mühlenstraße 26",
  "PostCode": 13187,
  "City": "Berlin",
  "Latitude": "52.563.835",
  "Longitude": "13.408.001",
  "Telephone": "03047302020",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.563835,13.408001",
  "id": "52.563835,13.408001"
}, {
  "Brand": "ARAL",
  "Street": "Köpenicker Landstr. 294,",
  "PostCode": 12437,
  "City": "Berlin",
  "Latitude": "52.464.704",
  "Longitude": "13.496.992",
  "Telephone": "03063979990",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.464704,13.496992",
  "id": "52.464704,13.496992"
}, {
  "Brand": "ARAL",
  "Street": "Werftstraße 13",
  "PostCode": 1139,
  "City": "Dresden",
  "Latitude": "51.068.993",
  "Longitude": "13.689.975",
  "Telephone": "03518496290",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.068993,13.689975",
  "id": "51.068993,13.689975"
}, {
  "Brand": "ARAL",
  "Street": "Oranienburger Chaussee 34",
  "PostCode": 16548,
  "City": "Glienicke",
  "Latitude": "52.636.284",
  "Longitude": "13.306.894",
  "Telephone": "03305677500",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.636284,13.306894",
  "id": "52.636284,13.306894"
}, {
  "Brand": "ARAL",
  "Street": "Tonnaer Straße 30 b",
  "PostCode": 99947,
  "City": "Bad Langensalza",
  "Latitude": "51.104.000",
  "Longitude": "10.663.811",
  "Telephone": "03603815726",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.104,10.663811",
  "id": "51.104,10.663811"
}, {
  "Brand": "ARAL",
  "Street": "Adlergestell 748",
  "PostCode": 12527,
  "City": "Berlin",
  "Latitude": "52.378.810",
  "Longitude": "13.644.191",
  "Telephone": "03067549184",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.37881,13.644191",
  "id": "52.37881,13.644191"
}, {
  "Brand": "ARAL",
  "Street": "FINKENKRUGER STRAßE 57",
  "PostCode": 14612,
  "City": "FALKENSEE",
  "Latitude": "52.562.697",
  "Longitude": "13.074.424",
  "Telephone": "03322240134",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.562697,13.074424",
  "id": "52.562697,13.074424"
}, {
  "Brand": "ARAL",
  "Street": "Hamburger Straße 34",
  "PostCode": 14641,
  "City": "Nauen",
  "Latitude": "52.607.682",
  "Longitude": "12.867.791",
  "Telephone": "03321450373",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.607682,12.867791",
  "id": "52.607682,12.867791"
}, {
  "Brand": "ARAL",
  "Street": "Max-Liebermann-Straße 101",
  "PostCode": 4157,
  "City": "Leipzig",
  "Latitude": "51.376.073",
  "Longitude": "12.377.660",
  "Telephone": "03419121670",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.376073,12.37766",
  "id": "51.376073,12.37766"
}, {
  "Brand": "ARAL",
  "Street": "Blankenauer Straße 70",
  "PostCode": 9113,
  "City": "Chemnitz",
  "Latitude": "50.856.329",
  "Longitude": "12.929.480",
  "Telephone": "0371449293",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.856329,12.92948",
  "id": "50.856329,12.92948"
}, {
  "Brand": "ARAL",
  "Street": "Wildenfelser Straße 15",
  "PostCode": 8056,
  "City": "Zwickau",
  "Latitude": "50.702.093",
  "Longitude": "12.508.305",
  "Telephone": "0375287020",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.702093,12.508305",
  "id": "50.702093,12.508305"
}, {
  "Brand": "ARAL",
  "Street": "Siemensstraße 58",
  "PostCode": 7546,
  "City": "Gera",
  "Latitude": "50.912.259",
  "Longitude": "12.064.824",
  "Telephone": "036577349500",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.912259,12.064824",
  "id": "50.912259,12.064824"
}, {
  "Brand": "ARAL",
  "Street": "Dresdner Straße 164",
  "PostCode": 1705,
  "City": "Freital",
  "Latitude": "51.001.763",
  "Longitude": "13.651.485",
  "Telephone": "03514601502",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.001763,13.651485",
  "id": "51.001763,13.651485"
}, {
  "Brand": "ARAL",
  "Street": "Osdorfer Ring 33-35",
  "PostCode": 14979,
  "City": "Großbeeren",
  "Latitude": "52.378.506",
  "Longitude": "13.312.922",
  "Telephone": "03370190587",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.378506,13.312922",
  "id": "52.378506,13.312922"
}, {
  "Brand": "ARAL",
  "Street": "Aralallee 1",
  "PostCode": 99441,
  "City": "Mellingen",
  "Latitude": "50.934.670",
  "Longitude": "11.389.635",
  "Telephone": "03645380630",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.93467,11.389635",
  "id": "50.93467,11.389635"
}, {
  "Brand": "ARAL",
  "Street": "Alter Spandauer Weg 5",
  "PostCode": 14641,
  "City": "Wustermark",
  "Latitude": "52.540.085",
  "Longitude": "12.977.601",
  "Telephone": "03323488174",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.540085,12.977601",
  "id": "52.540085,12.977601"
}, {
  "Brand": "ARAL",
  "Street": "Hallesche Straße 41/Schwa",
  "PostCode": 6686,
  "City": "Lützen / OT Zorbau",
  "Latitude": "51.177.707",
  "Longitude": "12.010.184",
  "Telephone": "03444192234",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.177707,12.010184",
  "id": "51.177707,12.010184"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Chaussee 68 /",
  "PostCode": 15370,
  "City": "Vogelsdorf",
  "Latitude": "52.499.556",
  "Longitude": "13.743.801",
  "Telephone": "03343965601",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.499556,13.743801",
  "id": "52.499556,13.743801"
}, {
  "Brand": "ARAL",
  "Street": "Märkische Allee 155",
  "PostCode": 12681,
  "City": "Berlin",
  "Latitude": "52.532.026",
  "Longitude": "13.536.305",
  "Telephone": "03054397011",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "52.532026,13.536305",
  "id": "52.532026,13.536305"
}, {
  "Brand": "ARAL",
  "Street": "Spenglerstr. 2",
  "PostCode": 16356,
  "City": "Ahrensfelde",
  "Latitude": "52.608.108",
  "Longitude": "13.528.880",
  "Telephone": "03094113252",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.608108,13.52888",
  "id": "52.608108,13.52888"
}, {
  "Brand": "ARAL",
  "Street": "Rudolf-Diesel-Straße 02",
  "PostCode": 19243,
  "City": "Wittenburg",
  "Latitude": "53.502.251",
  "Longitude": "11.097.956",
  "Telephone": "03885252182",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.502251,11.097956",
  "id": "53.502251,11.097956"
}, {
  "Brand": "ARAL",
  "Street": "Güstrower Straße 32",
  "PostCode": 19055,
  "City": "Schwerin",
  "Latitude": "53.643.448",
  "Longitude": "11.424.785",
  "Telephone": "03855815854",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.643448,11.424785",
  "id": "53.643448,11.424785"
}, {
  "Brand": "ARAL",
  "Street": "Nordstr. 1/B 71",
  "PostCode": 6420,
  "City": "Könnern",
  "Latitude": "51.689.058",
  "Longitude": "11.767.214",
  "Telephone": "03469151256",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.689058,11.767214",
  "id": "51.689058,11.767214"
}, {
  "Brand": "ARAL",
  "Street": "Lichtenseer Str.  18",
  "PostCode": 1619,
  "City": "Zeithain",
  "Latitude": "51.340.408",
  "Longitude": "13.347.722",
  "Telephone": "03525761326",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.340408,13.347722",
  "id": "51.340408,13.347722"
}, {
  "Brand": "ARAL",
  "Street": "Bergstr. 121",
  "PostCode": 1217,
  "City": "Dresden",
  "Latitude": "51.019.833",
  "Longitude": "13.730.003",
  "Telephone": "03514016150",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.019833,13.730003",
  "id": "51.019833,13.730003"
}, {
  "Brand": "ARAL",
  "Street": "Grabower Straße 2",
  "PostCode": 39126,
  "City": "Magdeburg",
  "Latitude": "52.204.878",
  "Longitude": "11.672.212",
  "Telephone": "039125443057",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.204878,11.672212",
  "id": "52.204878,11.672212"
}, {
  "Brand": "ARAL",
  "Street": "Weißenseer Straße 68",
  "PostCode": 99610,
  "City": "Sömmerda",
  "Latitude": "51.163.151",
  "Longitude": "11.106.833",
  "Telephone": "03634612704",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.163151,11.106833",
  "id": "51.163151,11.106833"
}, {
  "Brand": "ARAL",
  "Street": "Schützenstraße 11",
  "PostCode": 12529,
  "City": "Schönefeld",
  "Latitude": "52.394.826",
  "Longitude": "13.533.624",
  "Telephone": "03063497445",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.394826,13.533624",
  "id": "52.394826,13.533624"
}, {
  "Brand": "ARAL",
  "Street": "Treuenbrietzener Straße 64",
  "PostCode": 14913,
  "City": "Niedergörsdorf",
  "Latitude": "52.008.994",
  "Longitude": "13.012.000",
  "Telephone": "033724429633",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.008994,13.012",
  "id": "52.008994,13.012"
}, {
  "Brand": "ARAL",
  "Street": "Am Lückefeld 2",
  "PostCode": 15831,
  "City": "Mahlow",
  "Latitude": "52.358.172",
  "Longitude": "13.432.388",
  "Telephone": "03379379701",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.358172,13.432388",
  "id": "52.358172,13.432388"
}, {
  "Brand": "ARAL",
  "Street": "Im Gewerbegebiet 1",
  "PostCode": 14778,
  "City": "Wollin b.Brandenburg",
  "Latitude": "52.289.088",
  "Longitude": "12.464.309",
  "Telephone": "03383371843",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.289088,12.464309",
  "id": "52.289088,12.464309"
}, {
  "Brand": "ARAL",
  "Street": "Robert-Guthmann-Straße 1",
  "PostCode": 15713,
  "City": "Königs Wusterhausen",
  "Latitude": "52.314.496",
  "Longitude": "13.662.701",
  "Telephone": "03375507127",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.314496,13.662701",
  "id": "52.314496,13.662701"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Allee 41",
  "PostCode": 15806,
  "City": "Zossen / OT Wünsdorf",
  "Latitude": "52.170.955",
  "Longitude": "13.471.456",
  "Telephone": "03370266125",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.170955,13.471456",
  "id": "52.170955,13.471456"
}, {
  "Brand": "ARAL",
  "Street": "Ahornstraße 1",
  "PostCode": 19077,
  "City": "Rastow",
  "Latitude": "53.438.969",
  "Longitude": "11.489.584",
  "Telephone": "03875388842",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.438969,11.489584",
  "id": "53.438969,11.489584"
}, {
  "Brand": "ARAL",
  "Street": "Altlandsberger Chaussee27",
  "PostCode": 15366,
  "City": "Hoppegarten",
  "Latitude": "52.544.051",
  "Longitude": "13.646.990",
  "Telephone": "03342423763",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "05:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.544051,13.64699",
  "id": "52.544051,13.64699"
}, {
  "Brand": "ARAL",
  "Street": "Zuckerfabrik 1",
  "PostCode": 39343,
  "City": "Alleringersleben",
  "Latitude": "52.209.417",
  "Longitude": "11.133.019",
  "Telephone": "03940092246",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.209417,11.133019",
  "id": "52.209417,11.133019"
}, {
  "Brand": "ARAL",
  "Street": "Hengstbergstraße 11",
  "PostCode": 4668,
  "City": "Grimma",
  "Latitude": "51.256.147",
  "Longitude": "12.725.392",
  "Telephone": "03437760812",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.256147,12.725392",
  "id": "51.256147,12.725392"
}, {
  "Brand": "ARAL",
  "Street": "Suhler Straße 5 b",
  "PostCode": 99885,
  "City": "Ohrdruf",
  "Latitude": "50.815.165",
  "Longitude": "10.729.685",
  "Telephone": "03624313753",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "18:00",
  "SundayFrom": "08:00",
  "SundayTo": "18:00",
  "IsOpen24Hours": "Nein",
  "location": "50.815165,10.729685",
  "id": "50.815165,10.729685"
}, {
  "Brand": "ARAL",
  "Street": "SIEDLERWEG 1",
  "PostCode": 2923,
  "City": "Kodersdorf",
  "Latitude": "51.225.674",
  "Longitude": "14.902.096",
  "Telephone": "03582562337",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.225674,14.902096",
  "id": "51.225674,14.902096"
}, {
  "Brand": "ARAL",
  "Street": "Neue Schichtstraße 16",
  "PostCode": 9366,
  "City": "Niederdorf",
  "Latitude": "50.724.486",
  "Longitude": "12.778.826",
  "Telephone": "03729692441",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.724486,12.778826",
  "id": "50.724486,12.778826"
}, {
  "Brand": "ARAL",
  "Street": "Brücker Landstraße 22 a",
  "PostCode": 14806,
  "City": "Belzig",
  "Latitude": "52.144.699",
  "Longitude": "12.612.856",
  "Telephone": "03384130222",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.144699,12.612856",
  "id": "52.144699,12.612856"
}, {
  "Brand": "ARAL",
  "Street": "Geltestraße 1",
  "PostCode": 6184,
  "City": "Dölbau",
  "Latitude": "51.476.333",
  "Longitude": "12.086.264",
  "Telephone": "03460252411",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.476333,12.086264",
  "id": "51.476333,12.086264"
}, {
  "Brand": "ARAL",
  "Street": "Liebenwalder Straße 10 a",
  "PostCode": 16567,
  "City": "Mühlenbeck",
  "Latitude": "52.669.296",
  "Longitude": "13.377.538",
  "Telephone": "03305682856",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.669296,13.377538",
  "id": "52.669296,13.377538"
}, {
  "Brand": "ARAL",
  "Street": "A4",
  "PostCode": 9244,
  "City": "Oberlichtenau",
  "Latitude": "50.893.469",
  "Longitude": "12.945.990",
  "Telephone": "03720883523",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.893469,12.94599",
  "id": "50.893469,12.94599"
}, {
  "Brand": "ARAL",
  "Street": "Graf-York-Straße 23",
  "PostCode": 19061,
  "City": "Schwerin",
  "Latitude": "53.590.834",
  "Longitude": "11.430.641",
  "Telephone": "03853921301",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.590834,11.430641",
  "id": "53.590834,11.430641"
}, {
  "Brand": "ARAL",
  "Street": "Werner-von-Siemens-Straße",
  "PostCode": 16321,
  "City": "Bernau",
  "Latitude": "52.686.121",
  "Longitude": "13.570.194",
  "Telephone": "03338758845",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.686121,13.570194",
  "id": "52.686121,13.570194"
}, {
  "Brand": "ARAL",
  "Street": "BAB  A 20 - Fuchsberg Nor",
  "PostCode": 23992,
  "City": "Glasin",
  "Latitude": "53.910.784",
  "Longitude": "11.758.571",
  "Telephone": "038429446611",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.910784,11.758571",
  "id": "53.910784,11.758571"
}, {
  "Brand": "ARAL",
  "Street": "BAB A 20 - Fuchsberg Süd",
  "PostCode": 23992,
  "City": "Glasin",
  "Latitude": "53.908.977",
  "Longitude": "11.756.738",
  "Telephone": "038429446620",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.908977,11.756738",
  "id": "53.908977,11.756738"
}, {
  "Brand": "ARAL",
  "Street": "Holzmarktstraße 12/14",
  "PostCode": 10179,
  "City": "Berlin",
  "Latitude": "52.514.151",
  "Longitude": "13.421.487",
  "Telephone": "03024720748",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.514151,13.421487",
  "id": "52.514151,13.421487"
}, {
  "Brand": "ARAL",
  "Street": "Elsterwerdaer Straße 19 a",
  "PostCode": 1558,
  "City": "Großenhain",
  "Latitude": "51.298.017",
  "Longitude": "13.533.796",
  "Telephone": "0352238555",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.298017,13.533796",
  "id": "51.298017,13.533796"
}, {
  "Brand": "ARAL",
  "Street": "Hochuferstraße 11",
  "PostCode": 1662,
  "City": "Meißen",
  "Latitude": "51.169.111",
  "Longitude": "13.471.323",
  "Telephone": "03521453101",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.169111,13.471323",
  "id": "51.169111,13.471323"
}, {
  "Brand": "ARAL",
  "Street": "Radeberger Straße 18",
  "PostCode": 1471,
  "City": "Radeburg",
  "Latitude": "51.214.191",
  "Longitude": "13.730.580",
  "Telephone": "0352082321",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.214191,13.73058",
  "id": "51.214191,13.73058"
}, {
  "Brand": "ARAL",
  "Street": "Dresdner Tor Nord / A 4",
  "PostCode": 1723,
  "City": "Wilsdruff",
  "Latitude": "51.062.505",
  "Longitude": "13.575.056",
  "Telephone": "0352049420",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.062505,13.575056",
  "id": "51.062505,13.575056"
}, {
  "Brand": "ARAL",
  "Street": "Dresdner Tor Süd / A 4",
  "PostCode": 1723,
  "City": "Wilsdruff",
  "Latitude": "51.061.136",
  "Longitude": "13.568.827",
  "Telephone": "0352049430",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.061136,13.568827",
  "id": "51.061136,13.568827"
}, {
  "Brand": "ARAL",
  "Street": "Alte Altenberger Str. 40A",
  "PostCode": 1744,
  "City": "Dippoldiswalde",
  "Latitude": "50.886.876",
  "Longitude": "13.670.973",
  "Telephone": "03504612652",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.886876,13.670973",
  "id": "50.886876,13.670973"
}, {
  "Brand": "ARAL",
  "Street": "Schopperstraße 83",
  "PostCode": 7937,
  "City": "Zeulenroda",
  "Latitude": "50.655.651",
  "Longitude": "11.993.255",
  "Telephone": "03662882710",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.655651,11.993255",
  "id": "50.655651,11.993255"
}, {
  "Brand": "ARAL",
  "Street": "Demminer Straße 7",
  "PostCode": 99091,
  "City": "Erfurt",
  "Latitude": "51.013.167",
  "Longitude": "10.987.543",
  "Telephone": "03617914006",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.013167,10.987543",
  "id": "51.013167,10.987543"
}, {
  "Brand": "ARAL",
  "Street": "Marschnerstraße 50",
  "PostCode": 4109,
  "City": "Leipzig",
  "Latitude": "51.340.281",
  "Longitude": "12.354.341",
  "Telephone": "03419808809",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.340281,12.354341",
  "id": "51.340281,12.354341"
}, {
  "Brand": "ARAL",
  "Street": "Straße des Friedens",
  "PostCode": 4668,
  "City": "Grimma",
  "Latitude": "51.237.715",
  "Longitude": "12.723.290",
  "Telephone": "03437910258",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.237715,12.72329",
  "id": "51.237715,12.72329"
}, {
  "Brand": "ARAL",
  "Street": "An der Tabaksmühle",
  "PostCode": 4277,
  "City": "Leipzig",
  "Latitude": "51.313.577",
  "Longitude": "12.404.612",
  "Telephone": "0341868480",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.313577,12.404612",
  "id": "51.313577,12.404612"
}, {
  "Brand": "ARAL",
  "Street": "Bautzener Straße 78",
  "PostCode": 2681,
  "City": "Schirgiswalde-Kirsch",
  "Latitude": "51.096.552",
  "Longitude": "14.430.656",
  "Telephone": "03592380130",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.096552,14.430656",
  "id": "51.096552,14.430656"
}, {
  "Brand": "ARAL",
  "Street": "Elbstraße 8",
  "PostCode": 1814,
  "City": "Rathmannsdorf",
  "Latitude": "50.923.519",
  "Longitude": "14.134.711",
  "Telephone": "03502243088",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "04:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "04:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.923519,14.134711",
  "id": "50.923519,14.134711"
}, {
  "Brand": "ARAL",
  "Street": "Außenring 8",
  "PostCode": 4860,
  "City": "Torgau",
  "Latitude": "51.561.306",
  "Longitude": "12.973.615",
  "Telephone": "03421711855",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.561306,12.973615",
  "id": "51.561306,12.973615"
}, {
  "Brand": "ARAL",
  "Street": "Leipziger Straße 31",
  "PostCode": 4758,
  "City": "Oschatz",
  "Latitude": "51.304.055",
  "Longitude": "13.093.789",
  "Telephone": "03435926583",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.304055,13.093789",
  "id": "51.304055,13.093789"
}, {
  "Brand": "ARAL",
  "Street": "Ostring 250-254",
  "PostCode": 24148,
  "City": "Kiel",
  "Latitude": "54.313.558",
  "Longitude": "10.162.886",
  "Telephone": "0431724260",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.313558,10.162886",
  "id": "54.313558,10.162886"
}, {
  "Brand": "ARAL",
  "Street": "Hamburger Straße 35",
  "PostCode": 22941,
  "City": "Bargteheide",
  "Latitude": "53.724.256",
  "Longitude": "10.255.379",
  "Telephone": "04532501590",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.724256,10.255379",
  "id": "53.724256,10.255379"
}, {
  "Brand": "ARAL",
  "Street": "Schoenningstedter Str. 1",
  "PostCode": 21521,
  "City": "Aumühle",
  "Latitude": "53.530.083",
  "Longitude": "10.311.946",
  "Telephone": "04104969968",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.530083,10.311946",
  "id": "53.530083,10.311946"
}, {
  "Brand": "ARAL",
  "Street": "Flensburger Straße 1",
  "PostCode": 24340,
  "City": "Eckernförde",
  "Latitude": "54.465.046",
  "Longitude": "09.834.287",
  "Telephone": "04351470645",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.465046,9.834287",
  "id": "54.465046,9.834287"
}, {
  "Brand": "ARAL",
  "Street": "Husumer Straße 30",
  "PostCode": 24941,
  "City": "Flensburg",
  "Latitude": "54.775.036",
  "Longitude": "09.426.041",
  "Telephone": "046197312",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.775036,9.426041",
  "id": "54.775036,9.426041"
}, {
  "Brand": "ARAL",
  "Street": "Geesthachter Straße 24-28",
  "PostCode": 21502,
  "City": "Geesthacht",
  "Latitude": "53.440.600",
  "Longitude": "10.365.622",
  "Telephone": "041522591",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.4406,10.365622",
  "id": "53.4406,10.365622"
}, {
  "Brand": "ARAL",
  "Street": "Hansastraße 44",
  "PostCode": 21502,
  "City": "Geesthacht",
  "Latitude": "53.440.435",
  "Longitude": "10.393.030",
  "Telephone": "04152847546",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "53.440435,10.39303",
  "id": "53.440435,10.39303"
}, {
  "Brand": "ARAL",
  "Street": "Sieker Landstraße 235",
  "PostCode": 22927,
  "City": "Großhansdorf",
  "Latitude": "53.644.259",
  "Longitude": "10.278.794",
  "Telephone": "04102697052",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.644259,10.278794",
  "id": "53.644259,10.278794"
}, {
  "Brand": "ARAL",
  "Street": "Steenbeker Weg 58",
  "PostCode": 24106,
  "City": "Kiel",
  "Latitude": "54.355.776",
  "Longitude": "10.105.031",
  "Telephone": "0431331991",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "06:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "54.355776,10.105031",
  "id": "54.355776,10.105031"
}, {
  "Brand": "ARAL",
  "Street": "Rautenbergstraße 37A",
  "PostCode": 24306,
  "City": "Plön",
  "Latitude": "54.157.166",
  "Longitude": "10.440.925",
  "Telephone": "04522760176",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.157166,10.440925",
  "id": "54.157166,10.440925"
}, {
  "Brand": "ARAL",
  "Street": "Goehrdestraße 1 A",
  "PostCode": 29549,
  "City": "Bad Bevensen",
  "Latitude": "53.079.469",
  "Longitude": "10.588.883",
  "Telephone": "058212724",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "53.079469,10.588883",
  "id": "53.079469,10.588883"
}, {
  "Brand": "ARAL",
  "Street": "Lueneburger Straße 4",
  "PostCode": 21354,
  "City": "Bleckede",
  "Latitude": "53.289.697",
  "Longitude": "10.730.439",
  "Telephone": "05852453",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "53.289697,10.730439",
  "id": "53.289697,10.730439"
}, {
  "Brand": "ARAL",
  "Street": "Winsener Straße 38",
  "PostCode": 21077,
  "City": "Hamburg",
  "Latitude": "53.448.477",
  "Longitude": "09.988.215",
  "Telephone": "04076411998",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.448477,9.988215",
  "id": "53.448477,9.988215"
}, {
  "Brand": "ARAL",
  "Street": "Hoheluftchaussee 78",
  "PostCode": 20253,
  "City": "Hamburg",
  "Latitude": "53.582.896",
  "Longitude": "09.971.292",
  "Telephone": "040483601",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.582896,9.971292",
  "id": "53.582896,9.971292"
}, {
  "Brand": "ARAL",
  "Street": "Bundesstraße 38",
  "PostCode": 20146,
  "City": "Hamburg",
  "Latitude": "53.565.970",
  "Longitude": "09.979.668",
  "Telephone": "040456460",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.56597,9.979668",
  "id": "53.56597,9.979668"
}, {
  "Brand": "ARAL",
  "Street": "Kieler Straße 314",
  "PostCode": 22525,
  "City": "Hamburg",
  "Latitude": "53.585.860",
  "Longitude": "09.933.305",
  "Telephone": "04054766700",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.58586,9.933305",
  "id": "53.58586,9.933305"
}, {
  "Brand": "ARAL",
  "Street": "Otto-Brenner-Straße 6",
  "PostCode": 21109,
  "City": "Hamburg",
  "Latitude": "53.490.015",
  "Longitude": "10.019.005",
  "Telephone": "0407542478",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.490015,10.019005",
  "id": "53.490015,10.019005"
}, {
  "Brand": "ARAL",
  "Street": "Kieler Straße 560",
  "PostCode": 22525,
  "City": "Hamburg",
  "Latitude": "53.599.940",
  "Longitude": "09.917.373",
  "Telephone": "040545652",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.59994,9.917373",
  "id": "53.59994,9.917373"
}, {
  "Brand": "ARAL",
  "Street": "Sülldorfer Landstraße 91",
  "PostCode": 22589,
  "City": "Hamburg",
  "Latitude": "53.577.961",
  "Longitude": "09.809.536",
  "Telephone": "040873813",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.577961,9.809536",
  "id": "53.577961,9.809536"
}, {
  "Brand": "ARAL",
  "Street": "Königstraße 9",
  "PostCode": 22767,
  "City": "Hamburg",
  "Latitude": "53.549.591",
  "Longitude": "09.952.718",
  "Telephone": "040313481",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.549591,9.952718",
  "id": "53.549591,9.952718"
}, {
  "Brand": "ARAL",
  "Street": "Amsinckstraße 48",
  "PostCode": 20097,
  "City": "Hamburg",
  "Latitude": "53.545.969",
  "Longitude": "10.015.121",
  "Telephone": "040230646",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.545969,10.015121",
  "id": "53.545969,10.015121"
}, {
  "Brand": "ARAL",
  "Street": "Dehnhaide 49",
  "PostCode": 22081,
  "City": "Hamburg",
  "Latitude": "53.579.313",
  "Longitude": "10.045.245",
  "Telephone": "040295490",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.579313,10.045245",
  "id": "53.579313,10.045245"
}, {
  "Brand": "ARAL",
  "Street": "Meiendorfer Mühlenweg 6A",
  "PostCode": 22393,
  "City": "Hamburg",
  "Latitude": "53.644.497",
  "Longitude": "10.122.445",
  "Telephone": "0406018712",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.644497,10.122445",
  "id": "53.644497,10.122445"
}, {
  "Brand": "ARAL",
  "Street": "Rahlstedter Straße 87",
  "PostCode": 22149,
  "City": "Hamburg",
  "Latitude": "53.597.735",
  "Longitude": "10.152.924",
  "Telephone": "04067561379",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.597735,10.152924",
  "id": "53.597735,10.152924"
}, {
  "Brand": "ARAL",
  "Street": "Hummelsbuettler Hauptstraße 14",
  "PostCode": 22339,
  "City": "Hamburg",
  "Latitude": "53.637.209",
  "Longitude": "10.034.799",
  "Telephone": "04053897550",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.637209,10.034799",
  "id": "53.637209,10.034799"
}, {
  "Brand": "ARAL",
  "Street": "Habichtstraße 39",
  "PostCode": 22305,
  "City": "Hamburg",
  "Latitude": "53.590.963",
  "Longitude": "10.056.518",
  "Telephone": "040615874",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.590963,10.056518",
  "id": "53.590963,10.056518"
}, {
  "Brand": "ARAL",
  "Street": "Soltauer Straße 22",
  "PostCode": 21335,
  "City": "Lüneburg",
  "Latitude": "53.241.030",
  "Longitude": "10.400.814",
  "Telephone": "04131732511",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.24103,10.400814",
  "id": "53.24103,10.400814"
}, {
  "Brand": "ARAL",
  "Street": "Universitätsallee 4",
  "PostCode": 21335,
  "City": "Lüneburg",
  "Latitude": "53.226.720",
  "Longitude": "10.405.972",
  "Telephone": "04131732501",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.22672,10.405972",
  "id": "53.22672,10.405972"
}, {
  "Brand": "ARAL",
  "Street": "Eichholzerstraße 2",
  "PostCode": 21436,
  "City": "Marschacht",
  "Latitude": "53.416.729",
  "Longitude": "10.367.960",
  "Telephone": "04176246",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "18:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "53.416729,10.36796",
  "id": "53.416729,10.36796"
}, {
  "Brand": "ARAL",
  "Street": "Bismarckstraße 355",
  "PostCode": 28205,
  "City": "Bremen",
  "Latitude": "53.073.599",
  "Longitude": "08.851.597",
  "Telephone": "0421447691",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.073599,8.851597",
  "id": "53.073599,8.851597"
}, {
  "Brand": "ARAL",
  "Street": "Bremer Straße 53",
  "PostCode": 28816,
  "City": "Stuhr",
  "Latitude": "53.017.893",
  "Longitude": "08.789.342",
  "Telephone": "0421891063",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.017893,8.789342",
  "id": "53.017893,8.789342"
}, {
  "Brand": "ARAL",
  "Street": "Norddeicher Straße 31-32",
  "PostCode": 26506,
  "City": "Norden",
  "Latitude": "53.600.010",
  "Longitude": "07.192.554",
  "Telephone": "049314256",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "53.60001,7.192554",
  "id": "53.60001,7.192554"
}, {
  "Brand": "ARAL",
  "Street": "Alexanderstraße 202",
  "PostCode": 26121,
  "City": "Oldenburg",
  "Latitude": "53.161.307",
  "Longitude": "08.207.715",
  "Telephone": "0441883268",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.161307,8.207715",
  "id": "53.161307,8.207715"
}, {
  "Brand": "ARAL",
  "Street": "Nadorster Straße 288",
  "PostCode": 26125,
  "City": "Oldenburg",
  "Latitude": "53.166.528",
  "Longitude": "08.227.213",
  "Telephone": "044137770",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:30",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "53.166528,8.227213",
  "id": "53.166528,8.227213"
}, {
  "Brand": "ARAL",
  "Street": "Herforder Straße 432",
  "PostCode": 33609,
  "City": "Bielefeld",
  "Latitude": "52.049.747",
  "Longitude": "08.587.109",
  "Telephone": "05219729855",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.049747,8.587109",
  "id": "52.049747,8.587109"
}, {
  "Brand": "ARAL",
  "Street": "Jöllenbecker Straße 284",
  "PostCode": 33613,
  "City": "Bielefeld",
  "Latitude": "52.052.393",
  "Longitude": "08.520.209",
  "Telephone": "0521888330",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "52.052393,8.520209",
  "id": "52.052393,8.520209"
}, {
  "Brand": "ARAL",
  "Street": "Detmolder Straße 490",
  "PostCode": 33605,
  "City": "Bielefeld",
  "Latitude": "51.995.687",
  "Longitude": "08.594.726",
  "Telephone": "0521203090",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.995687,8.594726",
  "id": "51.995687,8.594726"
}, {
  "Brand": "ARAL",
  "Street": "Guetersloher Straße 240",
  "PostCode": 33649,
  "City": "Bielefeld",
  "Latitude": "51.980.169",
  "Longitude": "08.477.516",
  "Telephone": "0521412962",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.980169,8.477516",
  "id": "51.980169,8.477516"
}, {
  "Brand": "ARAL",
  "Street": "Wasserbreite 105",
  "PostCode": 32257,
  "City": "Bünde",
  "Latitude": "52.203.856",
  "Longitude": "08.588.700",
  "Telephone": "052235538",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "52.203856,8.5887",
  "id": "52.203856,8.5887"
}, {
  "Brand": "ARAL",
  "Street": "Nordring 107",
  "PostCode": 33330,
  "City": "Gütersloh",
  "Latitude": "51.912.780",
  "Longitude": "08.366.754",
  "Telephone": "0524114110",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.91278,8.366754",
  "id": "51.91278,8.366754"
}, {
  "Brand": "ARAL",
  "Street": "Gildehauser Straße 154",
  "PostCode": 48599,
  "City": "Gronau",
  "Latitude": "52.221.413",
  "Longitude": "07.042.137",
  "Telephone": "025629919331",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.221413,7.042137",
  "id": "52.221413,7.042137"
}, {
  "Brand": "ARAL",
  "Street": "Bielefelder Straße 23",
  "PostCode": 33813,
  "City": "Oerlinghausen",
  "Latitude": "51.968.923",
  "Longitude": "08.691.772",
  "Telephone": "052024079",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "09:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.968923,8.691772",
  "id": "51.968923,8.691772"
}, {
  "Brand": "ARAL",
  "Street": "Salzufler Straße 111",
  "PostCode": 32052,
  "City": "Herford",
  "Latitude": "52.107.378",
  "Longitude": "08.690.386",
  "Telephone": "05221981593",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.107378,8.690386",
  "id": "52.107378,8.690386"
}, {
  "Brand": "ARAL",
  "Street": "Hitzepohl-West 1",
  "PostCode": 32457,
  "City": "Porta- Westfalica",
  "Latitude": "52.219.282",
  "Longitude": "08.884.790",
  "Telephone": "057171208",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "52.219282,8.88479",
  "id": "52.219282,8.88479"
}, {
  "Brand": "ARAL",
  "Street": "Coesfelder Straße 10",
  "PostCode": 48653,
  "City": "Coesfeld",
  "Latitude": "51.903.702",
  "Longitude": "07.187.280",
  "Telephone": "02546340",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "10:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.903702,7.18728",
  "id": "51.903702,7.18728"
}, {
  "Brand": "ARAL",
  "Street": "Osnabrücker Straße 82",
  "PostCode": 32312,
  "City": "Lübbecke",
  "Latitude": "52.302.068",
  "Longitude": "08.596.580",
  "Telephone": "057418470",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.302068,8.59658",
  "id": "52.302068,8.59658"
}, {
  "Brand": "ARAL",
  "Street": "Wolbecker Straße 140 A",
  "PostCode": 48155,
  "City": "Münster",
  "Latitude": "51.954.667",
  "Longitude": "07.652.106",
  "Telephone": "025165364",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.954667,7.652106",
  "id": "51.954667,7.652106"
}, {
  "Brand": "ARAL",
  "Street": "Hammer Straße 311-313",
  "PostCode": 48153,
  "City": "Münster",
  "Latitude": "51.931.736",
  "Longitude": "07.626.874",
  "Telephone": "025178109",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.931736,7.626874",
  "id": "51.931736,7.626874"
}, {
  "Brand": "ARAL",
  "Street": "Lemfoerder Straße 4",
  "PostCode": 32369,
  "City": "Rahden",
  "Latitude": "52.432.853",
  "Longitude": "08.609.895",
  "Telephone": "05771968092",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "52.432853,8.609895",
  "id": "52.432853,8.609895"
}, {
  "Brand": "ARAL",
  "Street": "Sassenberger Straße 71",
  "PostCode": 48231,
  "City": "Warendorf",
  "Latitude": "51.956.971",
  "Longitude": "08.006.905",
  "Telephone": "025818091",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:30",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.956971,8.006905",
  "id": "51.956971,8.006905"
}, {
  "Brand": "ARAL",
  "Street": "Osnabruecker Straße 304",
  "PostCode": 49152,
  "City": "Bad Essen",
  "Latitude": "52.332.365",
  "Longitude": "08.314.482",
  "Telephone": "054722150",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "07:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "52.332365,8.314482",
  "id": "52.332365,8.314482"
}, {
  "Brand": "ARAL",
  "Street": "August-Kuempers-Straße 6",
  "PostCode": 48493,
  "City": "Wettringen",
  "Latitude": "52.210.966",
  "Longitude": "07.325.445",
  "Telephone": "02557927752",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "52.210966,7.325445",
  "id": "52.210966,7.325445"
}, {
  "Brand": "ARAL",
  "Street": "Harburger Straße 74",
  "PostCode": 29223,
  "City": "Celle",
  "Latitude": "52.634.177",
  "Longitude": "10.080.998",
  "Telephone": "05141930909",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.634177,10.080998",
  "id": "52.634177,10.080998"
}, {
  "Brand": "ARAL",
  "Street": "Goslarsche Straße 64",
  "PostCode": 38678,
  "City": "Clausthal-Zellerfeld",
  "Latitude": "51.821.126",
  "Longitude": "10.342.064",
  "Telephone": "05323982818",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.821126,10.342064",
  "id": "51.821126,10.342064"
}, {
  "Brand": "ARAL",
  "Street": "Hinterm Hagen 21",
  "PostCode": 38442,
  "City": "Wolfsburg",
  "Latitude": "52.419.117",
  "Longitude": "10.721.299",
  "Telephone": "0536262204",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.419117,10.721299",
  "id": "52.419117,10.721299"
}, {
  "Brand": "ARAL",
  "Street": "Robert-Koch-Straße 1",
  "PostCode": 38642,
  "City": "Goslar",
  "Latitude": "51.922.003",
  "Longitude": "10.438.066",
  "Telephone": "0532181468",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.922003,10.438066",
  "id": "51.922003,10.438066"
}, {
  "Brand": "ARAL",
  "Street": "Marienburger Straße 85",
  "PostCode": 31141,
  "City": "Hildesheim",
  "Latitude": "52.136.567",
  "Longitude": "09.970.387",
  "Telephone": "0512184438",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.136567,9.970387",
  "id": "52.136567,9.970387"
}, {
  "Brand": "ARAL",
  "Street": "Ferdinand-Wallbrecht-Stra",
  "PostCode": 30163,
  "City": "Hannover",
  "Latitude": "52.398.249",
  "Longitude": "09.741.357",
  "Telephone": "05113946245",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.398249,9.741357",
  "id": "52.398249,9.741357"
}, {
  "Brand": "ARAL",
  "Street": "Münsterstraße 208",
  "PostCode": 46397,
  "City": "Bocholt",
  "Latitude": "51.837.269",
  "Longitude": "06.642.715",
  "Telephone": "02871227206",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "51.837269,6.642715",
  "id": "51.837269,6.642715"
}, {
  "Brand": "ARAL",
  "Street": "Duisburger Straße 121",
  "PostCode": 47166,
  "City": "Duisburg",
  "Latitude": "51.489.325",
  "Longitude": "06.784.194",
  "Telephone": "02039948050",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.489325,6.784194",
  "id": "51.489325,6.784194"
}, {
  "Brand": "ARAL",
  "Street": "Weseler Straße 261",
  "PostCode": 47169,
  "City": "Duisburg",
  "Latitude": "51.513.927",
  "Longitude": "06.741.863",
  "Telephone": "0203990888",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.513927,6.741863",
  "id": "51.513927,6.741863"
}, {
  "Brand": "ARAL",
  "Street": "Alfredstraße 156-160",
  "PostCode": 45131,
  "City": "Essen",
  "Latitude": "51.428.574",
  "Longitude": "07.000.317",
  "Telephone": "0201411545",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.428574,7.000317",
  "id": "51.428574,7.000317"
}, {
  "Brand": "ARAL",
  "Street": "Vom-Stein-Straße 40",
  "PostCode": 45894,
  "City": "Gelsenkirchen",
  "Latitude": "51.574.377",
  "Longitude": "07.060.192",
  "Telephone": "020932900",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.574377,7.060192",
  "id": "51.574377,7.060192"
}, {
  "Brand": "ARAL",
  "Street": "Dorstener Straße 666",
  "PostCode": 45721,
  "City": "Haltern am See",
  "Latitude": "51.714.005",
  "Longitude": "07.094.333",
  "Telephone": "02360901910",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.714005,7.094333",
  "id": "51.714005,7.094333"
}, {
  "Brand": "ARAL",
  "Street": "Aktienstraße 226",
  "PostCode": 45473,
  "City": "Mülheim",
  "Latitude": "51.444.463",
  "Longitude": "06.905.406",
  "Telephone": "02089901013",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.444463,6.905406",
  "id": "51.444463,6.905406"
}, {
  "Brand": "ARAL",
  "Street": "Bottroper Straße 211",
  "PostCode": 46117,
  "City": "Oberhausen",
  "Latitude": "51.502.726",
  "Longitude": "06.892.161",
  "Telephone": "0208890648",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.502726,6.892161",
  "id": "51.502726,6.892161"
}, {
  "Brand": "ARAL",
  "Street": "Vestische Straße 157",
  "PostCode": 46117,
  "City": "Oberhausen",
  "Latitude": "51.509.498",
  "Longitude": "06.865.255",
  "Telephone": "0208632609",
  "WeekDayFrom": "07:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.509498,6.865255",
  "id": "51.509498,6.865255"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 139",
  "PostCode": 46562,
  "City": "Voerde",
  "Latitude": "51.600.730",
  "Longitude": "06.697.079",
  "Telephone": "0285593022",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.60073,6.697079",
  "id": "51.60073,6.697079"
}, {
  "Brand": "ARAL",
  "Street": "Bochumer Straße 6",
  "PostCode": 44866,
  "City": "Bochum",
  "Latitude": "51.479.443",
  "Longitude": "07.149.880",
  "Telephone": "0232788617",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.479443,7.14988",
  "id": "51.479443,7.14988"
}, {
  "Brand": "ARAL",
  "Street": "Sprockhoeveler Straße 1",
  "PostCode": 58455,
  "City": "Witten",
  "Latitude": "51.450.427",
  "Longitude": "07.325.600",
  "Telephone": "0230255410",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.450427,7.3256",
  "id": "51.450427,7.3256"
}, {
  "Brand": "ARAL",
  "Street": "Reeser Landstraße 20",
  "PostCode": 46483,
  "City": "Wesel",
  "Latitude": "51.664.252",
  "Longitude": "06.609.866",
  "Telephone": "0281331402",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.664252,6.609866",
  "id": "51.664252,6.609866"
}, {
  "Brand": "ARAL",
  "Street": "Duelmener Straße 8",
  "PostCode": 46286,
  "City": "Dorsten",
  "Latitude": "51.721.643",
  "Longitude": "07.012.792",
  "Telephone": "023694240",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.721643,7.012792",
  "id": "51.721643,7.012792"
}, {
  "Brand": "ARAL",
  "Street": "Steinfurter Straße 72",
  "PostCode": 59387,
  "City": "Ascheberg",
  "Latitude": "51.800.417",
  "Longitude": "07.649.596",
  "Telephone": "025935536",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.800417,7.649596",
  "id": "51.800417,7.649596"
}, {
  "Brand": "ARAL",
  "Street": "Werler Straße 56",
  "PostCode": 59469,
  "City": "Ense",
  "Latitude": "51.503.810",
  "Longitude": "07.969.485",
  "Telephone": "0293849000",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "18:00",
  "SundayFrom": "10:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "51.50381,7.969485",
  "id": "51.50381,7.969485"
}, {
  "Brand": "ARAL",
  "Street": "Oststraße 28",
  "PostCode": 33129,
  "City": "Delbrück",
  "Latitude": "51.763.404",
  "Longitude": "08.568.653",
  "Telephone": "05250933286",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.763404,8.568653",
  "id": "51.763404,8.568653"
}, {
  "Brand": "ARAL",
  "Street": "Stockumer Straße 168",
  "PostCode": 44225,
  "City": "Dortmund",
  "Latitude": "51.481.626",
  "Longitude": "07.438.967",
  "Telephone": "0231711234",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.481626,7.438967",
  "id": "51.481626,7.438967"
}, {
  "Brand": "ARAL",
  "Street": "Heiliger Weg / Löwenstr.",
  "PostCode": 44135,
  "City": "Dortmund",
  "Latitude": "51.509.497",
  "Longitude": "07.473.947",
  "Telephone": "0231524830",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.509497,7.473947",
  "id": "51.509497,7.473947"
}, {
  "Brand": "ARAL",
  "Street": "Mengeder Straße 432",
  "PostCode": 44359,
  "City": "Dortmund",
  "Latitude": "51.559.425",
  "Longitude": "07.397.122",
  "Telephone": "0231350380",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.559425,7.397122",
  "id": "51.559425,7.397122"
}, {
  "Brand": "ARAL",
  "Street": "Erwitterstraße 13",
  "PostCode": 59590,
  "City": "Geseke",
  "Latitude": "51.635.704",
  "Longitude": "08.505.503",
  "Telephone": "029421371",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "09:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.635704,8.505503",
  "id": "51.635704,8.505503"
}, {
  "Brand": "ARAL",
  "Street": "Herdecker Straße 14",
  "PostCode": 58089,
  "City": "Hagen",
  "Latitude": "51.385.832",
  "Longitude": "07.447.328",
  "Telephone": "02331932809",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.385832,7.447328",
  "id": "51.385832,7.447328"
}, {
  "Brand": "ARAL",
  "Street": "Neuenrader Straße 2",
  "PostCode": 58802,
  "City": "Balve",
  "Latitude": "51.314.659",
  "Longitude": "07.875.074",
  "Telephone": "02375910443",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.314659,7.875074",
  "id": "51.314659,7.875074"
}, {
  "Brand": "ARAL",
  "Street": "Dortmunder Straße 51",
  "PostCode": 44536,
  "City": "Lünen",
  "Latitude": "51.609.942",
  "Longitude": "07.508.481",
  "Telephone": "0230612366",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.609942,7.508481",
  "id": "51.609942,7.508481"
}, {
  "Brand": "ARAL",
  "Street": "Briloner Straße 44",
  "PostCode": 59872,
  "City": "Meschede",
  "Latitude": "51.346.872",
  "Longitude": "08.296.652",
  "Telephone": "029151412",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.346872,8.296652",
  "id": "51.346872,8.296652"
}, {
  "Brand": "ARAL",
  "Street": "Marienloher Straße 87",
  "PostCode": 33104,
  "City": "Paderborn",
  "Latitude": "51.745.707",
  "Longitude": "08.741.618",
  "Telephone": "052542430",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:30",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:30",
  "SundayFrom": "07:00",
  "SundayTo": "22:30",
  "IsOpen24Hours": "Nein",
  "location": "51.745707,8.741618",
  "id": "51.745707,8.741618"
}, {
  "Brand": "ARAL",
  "Street": "Kleiner Hellweg 2",
  "PostCode": 33154,
  "City": "Salzkotten",
  "Latitude": "51.641.287",
  "Longitude": "08.664.817",
  "Telephone": "05258940605",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.641287,8.664817",
  "id": "51.641287,8.664817"
}, {
  "Brand": "ARAL",
  "Street": "Warburger Straße 120",
  "PostCode": 33100,
  "City": "Paderborn",
  "Latitude": "51.706.264",
  "Longitude": "08.777.121",
  "Telephone": "0525162703",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.706264,8.777121",
  "id": "51.706264,8.777121"
}, {
  "Brand": "ARAL",
  "Street": "Hellweg 34 A",
  "PostCode": 59192,
  "City": "Bergkamen",
  "Latitude": "51.651.335",
  "Longitude": "07.662.784",
  "Telephone": "023899260320",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.651335,7.662784",
  "id": "51.651335,7.662784"
}, {
  "Brand": "ARAL",
  "Street": "Hansastraße 145",
  "PostCode": 59427,
  "City": "Unna",
  "Latitude": "51.540.280",
  "Longitude": "07.655.711",
  "Telephone": "02303591025",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.54028,7.655711",
  "id": "51.54028,7.655711"
}, {
  "Brand": "ARAL",
  "Street": "Engelbertstraße",
  "PostCode": 41462,
  "City": "Neuss",
  "Latitude": "51.207.104",
  "Longitude": "06.671.111",
  "Telephone": "02131561102",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.207104,6.671111",
  "id": "51.207104,6.671111"
}, {
  "Brand": "ARAL",
  "Street": "Freiligrathplatz 32",
  "PostCode": 40474,
  "City": "Düsseldorf",
  "Latitude": "51.265.086",
  "Longitude": "06.753.424",
  "Telephone": "02114350080",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.265086,6.753424",
  "id": "51.265086,6.753424"
}, {
  "Brand": "ARAL",
  "Street": "Brehmstraße 90",
  "PostCode": 40239,
  "City": "Düsseldorf",
  "Latitude": "51.247.919",
  "Longitude": "06.801.548",
  "Telephone": "0211627301",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "05:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "51.247919,6.801548",
  "id": "51.247919,6.801548"
}, {
  "Brand": "ARAL",
  "Street": "Pariser Straße 82",
  "PostCode": 40549,
  "City": "Düsseldorf",
  "Latitude": "51.233.334",
  "Longitude": "06.727.858",
  "Telephone": "0211501372",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.233334,6.727858",
  "id": "51.233334,6.727858"
}, {
  "Brand": "ARAL",
  "Street": "Gerresheimer Straße 122",
  "PostCode": 40721,
  "City": "Hilden",
  "Latitude": "51.176.566",
  "Longitude": "06.927.530",
  "Telephone": "02103390049",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.176566,6.92753",
  "id": "51.176566,6.92753"
}, {
  "Brand": "ARAL",
  "Street": "Haaner Straße 77",
  "PostCode": 40699,
  "City": "Erkrath",
  "Latitude": "51.204.407",
  "Longitude": "06.970.230",
  "Telephone": "02104948379",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.204407,6.97023",
  "id": "51.204407,6.97023"
}, {
  "Brand": "ARAL",
  "Street": "Gladbacher Straße 313-331",
  "PostCode": 47805,
  "City": "Krefeld",
  "Latitude": "51.314.466",
  "Longitude": "06.557.782",
  "Telephone": "02151399027",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.314466,6.557782",
  "id": "51.314466,6.557782"
}, {
  "Brand": "ARAL",
  "Street": "Am Krummenweg 9",
  "PostCode": 40885,
  "City": "Ratingen",
  "Latitude": "51.338.451",
  "Longitude": "06.869.088",
  "Telephone": "0210218051",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.338451,6.869088",
  "id": "51.338451,6.869088"
}, {
  "Brand": "ARAL",
  "Street": "Bergheimer Straße 252",
  "PostCode": 41464,
  "City": "Neuss",
  "Latitude": "51.181.640",
  "Longitude": "06.688.258",
  "Telephone": "0213141655",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.18164,6.688258",
  "id": "51.18164,6.688258"
}, {
  "Brand": "ARAL",
  "Street": "Nievenheimer Straße 6",
  "PostCode": 41469,
  "City": "Neuss",
  "Latitude": "51.157.718",
  "Longitude": "06.731.179",
  "Telephone": "021372257",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.157718,6.731179",
  "id": "51.157718,6.731179"
}, {
  "Brand": "ARAL",
  "Street": "Heidestraße 116-120",
  "PostCode": 42549,
  "City": "Velbert",
  "Latitude": "51.331.862",
  "Longitude": "07.038.549",
  "Telephone": "02051255786",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.331862,7.038549",
  "id": "51.331862,7.038549"
}, {
  "Brand": "ARAL",
  "Street": "Krefelder Straße 136",
  "PostCode": 47877,
  "City": "Willich",
  "Latitude": "51.273.348",
  "Longitude": "06.555.031",
  "Telephone": "021543045",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.273348,6.555031",
  "id": "51.273348,6.555031"
}, {
  "Brand": "ARAL",
  "Street": "Langerfelder Straße 121",
  "PostCode": 42389,
  "City": "Wuppertal",
  "Latitude": "51.274.135",
  "Longitude": "07.238.901",
  "Telephone": "02022602549",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.274135,7.238901",
  "id": "51.274135,7.238901"
}, {
  "Brand": "ARAL",
  "Street": "Uellendahler Straße 264",
  "PostCode": 42109,
  "City": "Wuppertal",
  "Latitude": "51.276.099",
  "Longitude": "07.155.494",
  "Telephone": "0202750411",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.276099,7.155494",
  "id": "51.276099,7.155494"
}, {
  "Brand": "ARAL",
  "Street": "Nevigeser Straße 31",
  "PostCode": 42113,
  "City": "Wuppertal",
  "Latitude": "51.268.726",
  "Longitude": "07.126.851",
  "Telephone": "02022761355",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.268726,7.126851",
  "id": "51.268726,7.126851"
}, {
  "Brand": "ARAL",
  "Street": "Carnaper Straße 70",
  "PostCode": 42283,
  "City": "Wuppertal",
  "Latitude": "51.276.312",
  "Longitude": "07.189.870",
  "Telephone": "0202507341",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.276312,7.18987",
  "id": "51.276312,7.18987"
}, {
  "Brand": "ARAL",
  "Street": "Roermonder Straße 33",
  "PostCode": 52072,
  "City": "Aachen",
  "Latitude": "50.783.904",
  "Longitude": "06.074.591",
  "Telephone": "0241872088",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.783904,6.074591",
  "id": "50.783904,6.074591"
}, {
  "Brand": "ARAL",
  "Street": "Joseph-von-Görres Str. 53",
  "PostCode": 52068,
  "City": "Aachen",
  "Latitude": "50.779.193",
  "Longitude": "06.109.724",
  "Telephone": "0241502672",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.779193,6.109724",
  "id": "50.779193,6.109724"
}, {
  "Brand": "ARAL",
  "Street": "An der Bundesstraße 258",
  "PostCode": 53534,
  "City": "Barweiler",
  "Latitude": "50.357.459",
  "Longitude": "06.857.173",
  "Telephone": "02691930070",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "07:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "50.357459,6.857173",
  "id": "50.357459,6.857173"
}, {
  "Brand": "ARAL",
  "Street": "Am Markt 2",
  "PostCode": 54634,
  "City": "Bitburg",
  "Latitude": "49.973.065",
  "Longitude": "06.521.433",
  "Telephone": "065619464660",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.973065,6.521433",
  "id": "49.973065,6.521433"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 26",
  "PostCode": 56823,
  "City": "Büchel",
  "Latitude": "50.173.923",
  "Longitude": "07.082.313",
  "Telephone": "02678801",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.173923,7.082313",
  "id": "50.173923,7.082313"
}, {
  "Brand": "ARAL",
  "Street": "Bonner Straße 16",
  "PostCode": 54550,
  "City": "Daun",
  "Latitude": "50.203.225",
  "Longitude": "06.826.713",
  "Telephone": "06592985318",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.203225,6.826713",
  "id": "50.203225,6.826713"
}, {
  "Brand": "ARAL",
  "Street": "Rochusstraße 46",
  "PostCode": 53123,
  "City": "Bonn",
  "Latitude": "50.719.192",
  "Longitude": "07.056.780",
  "Telephone": "0228624902",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.719192,7.05678",
  "id": "50.719192,7.05678"
}, {
  "Brand": "ARAL",
  "Street": "Kölner Straße 177",
  "PostCode": 53879,
  "City": "Euskirchen",
  "Latitude": "50.662.270",
  "Longitude": "06.805.835",
  "Telephone": "022512190",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.66227,6.805835",
  "id": "50.66227,6.805835"
}, {
  "Brand": "ARAL",
  "Street": "Gerolsteiner Straße 47",
  "PostCode": 54570,
  "City": "Pelm",
  "Latitude": "50.224.596",
  "Longitude": "06.678.783",
  "Telephone": "065913700",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.224596,6.678783",
  "id": "50.224596,6.678783"
}, {
  "Brand": "ARAL",
  "Street": "Godesberger Allee 131",
  "PostCode": 53175,
  "City": "Bonn",
  "Latitude": "50.701.407",
  "Longitude": "07.136.586",
  "Telephone": "0228376016",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.701407,7.136586",
  "id": "50.701407,7.136586"
}, {
  "Brand": "ARAL",
  "Street": "Hengebachstr. 1",
  "PostCode": 52396,
  "City": "Heimbach",
  "Latitude": "50.635.387",
  "Longitude": "06.474.189",
  "Telephone": "02446444",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.635387,6.474189",
  "id": "50.635387,6.474189"
}, {
  "Brand": "ARAL",
  "Street": "Wittlicher Straße 1",
  "PostCode": 54523,
  "City": "Hetzerath",
  "Latitude": "49.878.967",
  "Longitude": "06.814.709",
  "Telephone": "06508918825",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.878967,6.814709",
  "id": "49.878967,6.814709"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 144",
  "PostCode": 52156,
  "City": "Monschau",
  "Latitude": "50.529.719",
  "Longitude": "06.262.684",
  "Telephone": "024723401",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.529719,6.262684",
  "id": "50.529719,6.262684"
}, {
  "Brand": "ARAL",
  "Street": "Erftstraße 127",
  "PostCode": 50170,
  "City": "Kerpen-Sindorf",
  "Latitude": "50.911.672",
  "Longitude": "06.683.405",
  "Telephone": "02273570436",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.911672,6.683405",
  "id": "50.911672,6.683405"
}, {
  "Brand": "ARAL",
  "Street": "Moselstraße 6",
  "PostCode": 56253,
  "City": "Treis-Karden",
  "Latitude": "50.185.471",
  "Longitude": "07.305.253",
  "Telephone": "026722682",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.185471,7.305253",
  "id": "50.185471,7.305253"
}, {
  "Brand": "ARAL",
  "Street": "Berg.-Gladbacher Str. 781",
  "PostCode": 51069,
  "City": "Köln",
  "Latitude": "50.974.559",
  "Longitude": "07.057.847",
  "Telephone": "0221681487",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.974559,7.057847",
  "id": "50.974559,7.057847"
}, {
  "Brand": "ARAL",
  "Street": "Olpener Straße 498",
  "PostCode": 51109,
  "City": "Köln",
  "Latitude": "50.942.745",
  "Longitude": "07.050.981",
  "Telephone": "0221895143",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.942745,7.050981",
  "id": "50.942745,7.050981"
}, {
  "Brand": "ARAL",
  "Street": "Mülheimer Zubringer 140/S",
  "PostCode": 51063,
  "City": "Köln",
  "Latitude": "50.977.127",
  "Longitude": "07.007.043",
  "Telephone": "02219641905",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.977127,7.007043",
  "id": "50.977127,7.007043"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Straße 198",
  "PostCode": 51065,
  "City": "Köln",
  "Latitude": "50.950.485",
  "Longitude": "07.016.565",
  "Telephone": "0221693137",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.950485,7.016565",
  "id": "50.950485,7.016565"
}, {
  "Brand": "ARAL",
  "Street": "Wingert 43",
  "PostCode": 53894,
  "City": "Mechernich",
  "Latitude": "50.618.108",
  "Longitude": "06.651.867",
  "Telephone": "02443911405",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.618108,6.651867",
  "id": "50.618108,6.651867"
}, {
  "Brand": "ARAL",
  "Street": "Bonner Straße 6",
  "PostCode": 53340,
  "City": "Meckenheim",
  "Latitude": "50.626.234",
  "Longitude": "07.020.345",
  "Telephone": "022255013",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.626234,7.020345",
  "id": "50.626234,7.020345"
}, {
  "Brand": "ARAL",
  "Street": "Heerstraße 51",
  "PostCode": 53474,
  "City": "Bad Neuenahr-Ahr.",
  "Latitude": "50.548.185",
  "Longitude": "07.138.910",
  "Telephone": "026411594",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.548185,7.13891",
  "id": "50.548185,7.13891"
}, {
  "Brand": "ARAL",
  "Street": "Im Altwerk 27",
  "PostCode": 52385,
  "City": "Nideggen",
  "Latitude": "50.692.228",
  "Longitude": "06.483.995",
  "Telephone": "024271309",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.692228,6.483995",
  "id": "50.692228,6.483995"
}, {
  "Brand": "ARAL",
  "Street": "Koblenzer Straße 36",
  "PostCode": 56299,
  "City": "Ochtendung",
  "Latitude": "50.350.525",
  "Longitude": "07.394.450",
  "Telephone": "026259592411",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.350525,7.39445",
  "id": "50.350525,7.39445"
}, {
  "Brand": "ARAL",
  "Street": "Heerlener Straße 145",
  "PostCode": 52531,
  "City": "Übach-Palenberg",
  "Latitude": "50.928.336",
  "Longitude": "06.067.247",
  "Telephone": "02451909327",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "18:00",
  "SundayFrom": "08:00",
  "SundayTo": "18:00",
  "IsOpen24Hours": "Nein",
  "location": "50.928336,6.067247",
  "id": "50.928336,6.067247"
}, {
  "Brand": "ARAL",
  "Street": "Auelstraße 27-29",
  "PostCode": 54589,
  "City": "Stadtkyll",
  "Latitude": "50.353.611",
  "Longitude": "06.524.125",
  "Telephone": "06597961106",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "07:30",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.353611,6.524125",
  "id": "50.353611,6.524125"
}, {
  "Brand": "ARAL",
  "Street": "Brandenburg 1",
  "PostCode": 56856,
  "City": "Zell",
  "Latitude": "50.017.049",
  "Longitude": "07.180.465",
  "Telephone": "06542960225",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.017049,7.180465",
  "id": "50.017049,7.180465"
}, {
  "Brand": "ARAL",
  "Street": "Am Wassertor 17",
  "PostCode": 57439,
  "City": "Attendorn",
  "Latitude": "51.122.740",
  "Longitude": "07.907.254",
  "Telephone": "027224323",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.12274,7.907254",
  "id": "51.12274,7.907254"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 170",
  "PostCode": 56170,
  "City": "Bendorf",
  "Latitude": "50.426.868",
  "Longitude": "07.571.692",
  "Telephone": "02622923413",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.426868,7.571692",
  "id": "50.426868,7.571692"
}, {
  "Brand": "ARAL",
  "Street": "Kölner Straße 290",
  "PostCode": 51702,
  "City": "Bergneustadt",
  "Latitude": "51.021.679",
  "Longitude": "07.658.899",
  "Telephone": "01753325167",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.021679,7.658899",
  "id": "51.021679,7.658899"
}, {
  "Brand": "ARAL",
  "Street": "Burscheider Straße 154",
  "PostCode": 51381,
  "City": "Leverkusen",
  "Latitude": "51.079.371",
  "Longitude": "07.037.260",
  "Telephone": "02171734035",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.079371,7.03726",
  "id": "51.079371,7.03726"
}, {
  "Brand": "ARAL",
  "Street": "Am Großen Garten 7a / B 2",
  "PostCode": 56462,
  "City": "Höhn",
  "Latitude": "50.619.165",
  "Longitude": "07.990.604",
  "Telephone": "02661949598",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.619165,7.990604",
  "id": "50.619165,7.990604"
}, {
  "Brand": "ARAL",
  "Street": "Hoehestraße 51",
  "PostCode": 51399,
  "City": "Burscheid",
  "Latitude": "51.084.205",
  "Longitude": "07.127.536",
  "Telephone": "02174789535",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.084205,7.127536",
  "id": "51.084205,7.127536"
}, {
  "Brand": "ARAL",
  "Street": "Hagener Straße 67",
  "PostCode": 57489,
  "City": "Drolshagen",
  "Latitude": "51.027.881",
  "Longitude": "07.772.420",
  "Telephone": "02761979408",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.027881,7.77242",
  "id": "51.027881,7.77242"
}, {
  "Brand": "ARAL",
  "Street": "Koblenzer Straße 3",
  "PostCode": 57629,
  "City": "Höchstenbach",
  "Latitude": "50.633.737",
  "Longitude": "07.746.070",
  "Telephone": "02680442",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.633737,7.74607",
  "id": "50.633737,7.74607"
}, {
  "Brand": "ARAL",
  "Street": "Römerstraße 221",
  "PostCode": 56075,
  "City": "Koblenz",
  "Latitude": "50.334.287",
  "Longitude": "07.582.917",
  "Telephone": "026132520",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.334287,7.582917",
  "id": "50.334287,7.582917"
}, {
  "Brand": "ARAL",
  "Street": "Willy-Brandt-Ring 20",
  "PostCode": 51373,
  "City": "Leverkusen",
  "Latitude": "51.022.990",
  "Longitude": "07.003.646",
  "Telephone": "0214830450",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.02299,7.003646",
  "id": "51.02299,7.003646"
}, {
  "Brand": "ARAL",
  "Street": "Solinger Straße 67",
  "PostCode": 51371,
  "City": "Leverkusen",
  "Latitude": "51.056.073",
  "Longitude": "06.953.897",
  "Telephone": "021421349",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.056073,6.953897",
  "id": "51.056073,6.953897"
}, {
  "Brand": "ARAL",
  "Street": "Neukirchener Straße 10",
  "PostCode": 42799,
  "City": "Leichlingen",
  "Latitude": "51.104.453",
  "Longitude": "07.014.947",
  "Telephone": "02175898083",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.104453,7.014947",
  "id": "51.104453,7.014947"
}, {
  "Brand": "ARAL",
  "Street": "Koelner Straße 23",
  "PostCode": 51789,
  "City": "Lindlar",
  "Latitude": "51.017.627",
  "Longitude": "07.372.177",
  "Telephone": "022666075",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.017627,7.372177",
  "id": "51.017627,7.372177"
}, {
  "Brand": "ARAL",
  "Street": "Rheinstraße 35",
  "PostCode": 56424,
  "City": "Mogendorf",
  "Latitude": "50.488.943",
  "Longitude": "07.756.060",
  "Telephone": "026235662",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.488943,7.75606",
  "id": "50.488943,7.75606"
}, {
  "Brand": "ARAL",
  "Street": "Gartzenweg 58",
  "PostCode": 40789,
  "City": "Monheim",
  "Latitude": "51.091.676",
  "Longitude": "06.897.396",
  "Telephone": "0217356997",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.091676,6.897396",
  "id": "51.091676,6.897396"
}, {
  "Brand": "ARAL",
  "Street": "Waldbrölerstraße 61",
  "PostCode": 51597,
  "City": "Morsbach",
  "Latitude": "50.868.476",
  "Longitude": "07.726.046",
  "Telephone": "022948197",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.868476,7.726046",
  "id": "50.868476,7.726046"
}, {
  "Brand": "ARAL",
  "Street": "Marienfelder Straße 2",
  "PostCode": 53804,
  "City": "Much",
  "Latitude": "50.905.190",
  "Longitude": "07.411.584",
  "Telephone": "022455580",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.90519,7.411584",
  "id": "50.90519,7.411584"
}, {
  "Brand": "ARAL",
  "Street": "Broelstraße 29",
  "PostCode": 53809,
  "City": "Ruppichteroth",
  "Latitude": "50.847.741",
  "Longitude": "07.500.641",
  "Telephone": "02295920120",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.847741,7.500641",
  "id": "50.847741,7.500641"
}, {
  "Brand": "ARAL",
  "Street": "Steinbuecheler Straße 48",
  "PostCode": 51377,
  "City": "Leverkusen",
  "Latitude": "51.047.945",
  "Longitude": "07.060.423",
  "Telephone": "02148505923",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.047945,7.060423",
  "id": "51.047945,7.060423"
}, {
  "Brand": "ARAL",
  "Street": "Barmer Straße 70",
  "PostCode": 42899,
  "City": "Remscheid",
  "Latitude": "51.219.449",
  "Longitude": "07.241.142",
  "Telephone": "02191953342",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.219449,7.241142",
  "id": "51.219449,7.241142"
}, {
  "Brand": "ARAL",
  "Street": "Bonner Straße 32-34",
  "PostCode": 53721,
  "City": "Siegburg",
  "Latitude": "50.791.351",
  "Longitude": "07.204.606",
  "Telephone": "0224162409",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.791351,7.204606",
  "id": "50.791351,7.204606"
}, {
  "Brand": "ARAL",
  "Street": "Schlagbaumer Straße 66",
  "PostCode": 42653,
  "City": "Solingen",
  "Latitude": "51.185.083",
  "Longitude": "07.079.738",
  "Telephone": "02122541192",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.185083,7.079738",
  "id": "51.185083,7.079738"
}, {
  "Brand": "ARAL",
  "Street": "Hohe Straße 1",
  "PostCode": 56244,
  "City": "Steinen",
  "Latitude": "50.574.775",
  "Longitude": "07.809.739",
  "Telephone": "02666632",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.574775,7.809739",
  "id": "50.574775,7.809739"
}, {
  "Brand": "ARAL",
  "Street": "Willmenroder Straße 32",
  "PostCode": 56457,
  "City": "Westerburg",
  "Latitude": "50.556.685",
  "Longitude": "07.973.047",
  "Telephone": "026634844",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.556685,7.973047",
  "id": "50.556685,7.973047"
}, {
  "Brand": "ARAL",
  "Street": "Siegener Straße 19",
  "PostCode": 51580,
  "City": "Reichshof",
  "Latitude": "50.940.817",
  "Longitude": "07.744.051",
  "Telephone": "02297359",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.940817,7.744051",
  "id": "50.940817,7.744051"
}, {
  "Brand": "ARAL",
  "Street": "Leiersmühle 5",
  "PostCode": 51688,
  "City": "Wipperfürth",
  "Latitude": "51.121.847",
  "Longitude": "07.410.305",
  "Telephone": "022671847",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.121847,7.410305",
  "id": "51.121847,7.410305"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 89",
  "PostCode": 42799,
  "City": "Leichlingen",
  "Latitude": "51.118.133",
  "Longitude": "07.125.080",
  "Telephone": "0217438058",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:30",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.118133,7.12508",
  "id": "51.118133,7.12508"
}, {
  "Brand": "ARAL",
  "Street": "Kasseler Straße 28",
  "PostCode": 34479,
  "City": "Breuna",
  "Latitude": "51.417.384",
  "Longitude": "09.190.476",
  "Telephone": "05693995195",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "51.417384,9.190476",
  "id": "51.417384,9.190476"
}, {
  "Brand": "ARAL",
  "Street": "Leipziger Straße 126",
  "PostCode": 37235,
  "City": "Hessisch Lichtenau",
  "Latitude": "51.199.149",
  "Longitude": "09.726.851",
  "Telephone": "056022462",
  "WeekDayFrom": "07:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.199149,9.726851",
  "id": "51.199149,9.726851"
}, {
  "Brand": "ARAL",
  "Street": "Fuldatalstraße 1",
  "PostCode": 34125,
  "City": "Kassel",
  "Latitude": "51.323.519",
  "Longitude": "09.513.459",
  "Telephone": "0561873851",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.323519,9.513459",
  "id": "51.323519,9.513459"
}, {
  "Brand": "ARAL",
  "Street": "Northeimer Straße 31",
  "PostCode": 37186,
  "City": "Moringen",
  "Latitude": "51.703.114",
  "Longitude": "09.875.096",
  "Telephone": "05554400",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "51.703114,9.875096",
  "id": "51.703114,9.875096"
}, {
  "Brand": "ARAL",
  "Street": "Lindenplatz 1",
  "PostCode": 36119,
  "City": "Neuhof",
  "Latitude": "50.454.747",
  "Longitude": "09.614.162",
  "Telephone": "066552241",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.454747,9.614162",
  "id": "50.454747,9.614162"
}, {
  "Brand": "ARAL",
  "Street": "Niederrheinische Str. 27",
  "PostCode": 34626,
  "City": "Neukirchen",
  "Latitude": "50.871.994",
  "Longitude": "09.337.172",
  "Telephone": "066947878",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.871994,9.337172",
  "id": "50.871994,9.337172"
}, {
  "Brand": "ARAL",
  "Street": "Marburger Straße 42",
  "PostCode": 35279,
  "City": "Neustadt",
  "Latitude": "50.851.163",
  "Longitude": "09.107.086",
  "Telephone": "06692919662",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.851163,9.107086",
  "id": "50.851163,9.107086"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Straße 2",
  "PostCode": 34286,
  "City": "Spangenberg",
  "Latitude": "51.116.439",
  "Longitude": "09.657.353",
  "Telephone": "056636310",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.116439,9.657353",
  "id": "51.116439,9.657353"
}, {
  "Brand": "ARAL",
  "Street": "Landgrafenstraße 5",
  "PostCode": 34590,
  "City": "Wabern",
  "Latitude": "51.102.165",
  "Longitude": "09.349.691",
  "Telephone": "05683930315",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.102165,9.349691",
  "id": "51.102165,9.349691"
}, {
  "Brand": "ARAL",
  "Street": "Neckarstraße 19",
  "PostCode": 64283,
  "City": "Darmstadt",
  "Latitude": "49.868.530",
  "Longitude": "08.645.511",
  "Telephone": "06151315733",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.86853,8.645511",
  "id": "49.86853,8.645511"
}, {
  "Brand": "ARAL",
  "Street": "Reuterallee 51",
  "PostCode": 64297,
  "City": "Darmstadt",
  "Latitude": "49.821.703",
  "Longitude": "08.641.593",
  "Telephone": "0615152527",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.821703,8.641593",
  "id": "49.821703,8.641593"
}, {
  "Brand": "ARAL",
  "Street": "Heinrichstraße 241",
  "PostCode": 64287,
  "City": "Darmstadt",
  "Latitude": "49.867.749",
  "Longitude": "08.681.954",
  "Telephone": "0615147843",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.867749,8.681954",
  "id": "49.867749,8.681954"
}, {
  "Brand": "ARAL",
  "Street": "Hanauer Landstraße 34-40",
  "PostCode": 60314,
  "City": "Frankfurt",
  "Latitude": "50.112.922",
  "Longitude": "08.699.732",
  "Telephone": "069491523",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.112922,8.699732",
  "id": "50.112922,8.699732"
}, {
  "Brand": "ARAL",
  "Street": "Oberer Kalbacher Weg 35",
  "PostCode": 60437,
  "City": "Frankfurt",
  "Latitude": "50.183.502",
  "Longitude": "08.659.846",
  "Telephone": "06995059127",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.183502,8.659846",
  "id": "50.183502,8.659846"
}, {
  "Brand": "ARAL",
  "Street": "Grueneburgweg 67",
  "PostCode": 60323,
  "City": "Frankfurt",
  "Latitude": "50.121.970",
  "Longitude": "08.669.096",
  "Telephone": "069723810",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:45",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:45",
  "SundayFrom": "08:00",
  "SundayTo": "21:45",
  "IsOpen24Hours": "Nein",
  "location": "50.12197,8.669096",
  "id": "50.12197,8.669096"
}, {
  "Brand": "ARAL",
  "Street": "Lauterbacher Straße 68",
  "PostCode": 63688,
  "City": "Gedern",
  "Latitude": "50.429.139",
  "Longitude": "09.196.706",
  "Telephone": "060451369",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.429139,9.196706",
  "id": "50.429139,9.196706"
}, {
  "Brand": "ARAL",
  "Street": "Waldgirmeser Straße 6",
  "PostCode": 35633,
  "City": "Lahnau",
  "Latitude": "50.578.194",
  "Longitude": "08.564.324",
  "Telephone": "0644196470",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.578194,8.564324",
  "id": "50.578194,8.564324"
}, {
  "Brand": "ARAL",
  "Street": "Lichtenberger Straße 66",
  "PostCode": 64401,
  "City": "Groß-Bieberau",
  "Latitude": "49.791.730",
  "Longitude": "08.821.302",
  "Telephone": "06162931427",
  "WeekDayFrom": "05:45",
  "WeekDayTo": "20:30",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:30",
  "SundayFrom": "08:00",
  "SundayTo": "20:30",
  "IsOpen24Hours": "Nein",
  "location": "49.79173,8.821302",
  "id": "49.79173,8.821302"
}, {
  "Brand": "ARAL",
  "Street": "Darmstaedter Straße 49A",
  "PostCode": 64846,
  "City": "Groß-Zimmern",
  "Latitude": "49.873.131",
  "Longitude": "08.820.814",
  "Telephone": "06071951360",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.873131,8.820814",
  "id": "49.873131,8.820814"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Straße 12",
  "PostCode": 35625,
  "City": "Hüttenberg",
  "Latitude": "50.521.868",
  "Longitude": "08.572.381",
  "Telephone": "0644174273",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.521868,8.572381",
  "id": "50.521868,8.572381"
}, {
  "Brand": "ARAL",
  "Street": "Saalburgstraße 114",
  "PostCode": 61350,
  "City": "Bad Homburg",
  "Latitude": "50.236.995",
  "Longitude": "08.593.014",
  "Telephone": "0617237205",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.236995,8.593014",
  "id": "50.236995,8.593014"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Straße 19",
  "PostCode": 63225,
  "City": "Langen",
  "Latitude": "49.991.924",
  "Longitude": "08.680.595",
  "Telephone": "0610321524",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "49.991924,8.680595",
  "id": "49.991924,8.680595"
}, {
  "Brand": "ARAL",
  "Street": "Limburger Straße 34",
  "PostCode": 65232,
  "City": "Taunusstein",
  "Latitude": "50.170.395",
  "Longitude": "08.211.591",
  "Telephone": "0612872462",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.170395,8.211591",
  "id": "50.170395,8.211591"
}, {
  "Brand": "ARAL",
  "Street": "Friedhofstraße 11",
  "PostCode": 63263,
  "City": "Neu-Isenburg",
  "Latitude": "50.047.976",
  "Longitude": "08.697.337",
  "Telephone": "0610239307",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.047976,8.697337",
  "id": "50.047976,8.697337"
}, {
  "Brand": "ARAL",
  "Street": "Limburger Straße 15-19",
  "PostCode": 65611,
  "City": "Brechen",
  "Latitude": "50.361.537",
  "Longitude": "08.170.562",
  "Telephone": "06438836974",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.361537,8.170562",
  "id": "50.361537,8.170562"
}, {
  "Brand": "ARAL",
  "Street": "Odenwaldstraße 13",
  "PostCode": 64367,
  "City": "Mühltal",
  "Latitude": "49.829.868",
  "Longitude": "08.706.072",
  "Telephone": "0615114269",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.829868,8.706072",
  "id": "49.829868,8.706072"
}, {
  "Brand": "ARAL",
  "Street": "Sodener Straße 29",
  "PostCode": 61476,
  "City": "Kronberg",
  "Latitude": "50.179.907",
  "Longitude": "08.538.894",
  "Telephone": "0617364545",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.179907,8.538894",
  "id": "50.179907,8.538894"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Straße 81",
  "PostCode": 61440,
  "City": "Oberursel",
  "Latitude": "50.205.956",
  "Longitude": "08.579.959",
  "Telephone": "061714475",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.205956,8.579959",
  "id": "50.205956,8.579959"
}, {
  "Brand": "ARAL",
  "Street": "Kurt-Schumacher-Ring 138",
  "PostCode": 63303,
  "City": "Dreieich",
  "Latitude": "50.034.562",
  "Longitude": "08.694.491",
  "Telephone": "06103373437",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "50.034562,8.694491",
  "id": "50.034562,8.694491"
}, {
  "Brand": "ARAL",
  "Street": "Bergstraße 4",
  "PostCode": 35578,
  "City": "Wetzlar",
  "Latitude": "50.550.764",
  "Longitude": "08.502.649",
  "Telephone": "0644122440",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.550764,8.502649",
  "id": "50.550764,8.502649"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofsplatz",
  "PostCode": 65189,
  "City": "Wiesbaden",
  "Latitude": "50.071.471",
  "Longitude": "08.245.563",
  "Telephone": "0611719188",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.071471,8.245563",
  "id": "50.071471,8.245563"
}, {
  "Brand": "ARAL",
  "Street": "Weinstraße 90",
  "PostCode": 67278,
  "City": "Bockenheim",
  "Latitude": "49.608.469",
  "Longitude": "08.181.643",
  "Telephone": "063599615906",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.608469,8.181643",
  "id": "49.608469,8.181643"
}, {
  "Brand": "ARAL",
  "Street": "Am Rathausplatz 22",
  "PostCode": 67125,
  "City": "Dannstadt-Schauernhe",
  "Latitude": "49.431.867",
  "Longitude": "08.309.247",
  "Telephone": "06231403752",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.431867,8.309247",
  "id": "49.431867,8.309247"
}, {
  "Brand": "ARAL",
  "Street": "Mannheimer Straße 112",
  "PostCode": 67098,
  "City": "Bad Dürkheim",
  "Latitude": "49.463.229",
  "Longitude": "08.186.616",
  "Telephone": "06322943317",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "08:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "49.463229,8.186616",
  "id": "49.463229,8.186616"
}, {
  "Brand": "ARAL",
  "Street": "Karlsruher Straße 50a",
  "PostCode": 76275,
  "City": "Ettlingen",
  "Latitude": "48.957.374",
  "Longitude": "08.406.625",
  "Telephone": "0724312372",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.957374,8.406625",
  "id": "48.957374,8.406625"
}, {
  "Brand": "ARAL",
  "Street": "Mahlastraße 37",
  "PostCode": 67227,
  "City": "Frankenthal",
  "Latitude": "49.526.050",
  "Longitude": "08.354.932",
  "Telephone": "06233349208",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.52605,8.354932",
  "id": "49.52605,8.354932"
}, {
  "Brand": "ARAL",
  "Street": "A 6",
  "PostCode": 68766,
  "City": "Hockenheim",
  "Latitude": "49.315.262",
  "Longitude": "08.577.630",
  "Telephone": "0620513513",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.315262,8.57763",
  "id": "49.315262,8.57763"
}, {
  "Brand": "ARAL",
  "Street": "Mannheimer Straße 300-302",
  "PostCode": 69123,
  "City": "Heidelberg",
  "Latitude": "49.429.585",
  "Longitude": "08.645.405",
  "Telephone": "06221831614",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.429585,8.645405",
  "id": "49.429585,8.645405"
}, {
  "Brand": "ARAL",
  "Street": "Haid-und-Neu-Straße 60",
  "PostCode": 76131,
  "City": "Karlsruhe",
  "Latitude": "49.015.208",
  "Longitude": "08.435.365",
  "Telephone": "0721615570",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.015208,8.435365",
  "id": "49.015208,8.435365"
}, {
  "Brand": "ARAL",
  "Street": "Siegfriedstraße 64",
  "PostCode": 64385,
  "City": "Reichelsheim",
  "Latitude": "49.722.496",
  "Longitude": "08.868.194",
  "Telephone": "061641214",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.722496,8.868194",
  "id": "49.722496,8.868194"
}, {
  "Brand": "ARAL",
  "Street": "Hechtsheimer Straße 4",
  "PostCode": 55131,
  "City": "Mainz",
  "Latitude": "49.982.790",
  "Longitude": "08.280.189",
  "Telephone": "061315708360",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.98279,8.280189",
  "id": "49.98279,8.280189"
}, {
  "Brand": "ARAL",
  "Street": "Hafenstraße 19-21",
  "PostCode": 68159,
  "City": "Mannheim",
  "Latitude": "49.494.056",
  "Longitude": "08.457.063",
  "Telephone": "062124207",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.494056,8.457063",
  "id": "49.494056,8.457063"
}, {
  "Brand": "ARAL",
  "Street": "Oppauer Straße 125",
  "PostCode": 67069,
  "City": "Ludwigshafen",
  "Latitude": "49.534.019",
  "Longitude": "08.388.857",
  "Telephone": "0621661459",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.534019,8.388857",
  "id": "49.534019,8.388857"
}, {
  "Brand": "ARAL",
  "Street": "Heinigstraße 69",
  "PostCode": 67059,
  "City": "Ludwigshafen",
  "Latitude": "49.481.501",
  "Longitude": "08.439.371",
  "Telephone": "0621514933",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.481501,8.439371",
  "id": "49.481501,8.439371"
}, {
  "Brand": "ARAL",
  "Street": "Friedrich-Ebert-Straße 12",
  "PostCode": 68167,
  "City": "Mannheim",
  "Latitude": "49.496.583",
  "Longitude": "08.487.706",
  "Telephone": "0621332213",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.496583,8.487706",
  "id": "49.496583,8.487706"
}, {
  "Brand": "ARAL",
  "Street": "Zuzenhaeuser Straße 2",
  "PostCode": 74909,
  "City": "Meckesheim",
  "Latitude": "49.319.861",
  "Longitude": "08.814.378",
  "Telephone": "06226990562",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "49.319861,8.814378",
  "id": "49.319861,8.814378"
}, {
  "Brand": "ARAL",
  "Street": "Steubenstraße 48",
  "PostCode": 68163,
  "City": "Mannheim",
  "Latitude": "49.460.739",
  "Longitude": "08.478.127",
  "Telephone": "06218322965",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "06:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.460739,8.478127",
  "id": "49.460739,8.478127"
}, {
  "Brand": "ARAL",
  "Street": "Zellerstraße 34",
  "PostCode": 56288,
  "City": "Kastellaun",
  "Latitude": "50.068.868",
  "Longitude": "07.439.397",
  "Telephone": "067625599",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.068868,7.439397",
  "id": "50.068868,7.439397"
}, {
  "Brand": "ARAL",
  "Street": "Römerstraße 1",
  "PostCode": 69198,
  "City": "Schriesheim",
  "Latitude": "49.474.939",
  "Longitude": "08.658.518",
  "Telephone": "0620361422",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "04:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "04:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "49.474939,8.658518",
  "id": "49.474939,8.658518"
}, {
  "Brand": "ARAL",
  "Street": "Landauer Straße 65",
  "PostCode": 67346,
  "City": "Speyer",
  "Latitude": "49.308.935",
  "Longitude": "08.423.943",
  "Telephone": "0623275427",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "05:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.308935,8.423943",
  "id": "49.308935,8.423943"
}, {
  "Brand": "ARAL",
  "Street": "Mannheimerstraße 77",
  "PostCode": 69469,
  "City": "Weinheim",
  "Latitude": "49.549.426",
  "Longitude": "08.654.739",
  "Telephone": "0620114295",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.549426,8.654739",
  "id": "49.549426,8.654739"
}, {
  "Brand": "ARAL",
  "Street": "Alzeyer Straße 58",
  "PostCode": 67549,
  "City": "Worms",
  "Latitude": "49.631.911",
  "Longitude": "08.347.308",
  "Telephone": "0624158314",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "49.631911,8.347308",
  "id": "49.631911,8.347308"
}, {
  "Brand": "ARAL",
  "Street": "Hochwaldstraße 2",
  "PostCode": 55765,
  "City": "Birkenfeld",
  "Latitude": "49.650.758",
  "Longitude": "07.157.486",
  "Telephone": "0678299390",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.650758,7.157486",
  "id": "49.650758,7.157486"
}, {
  "Brand": "ARAL",
  "Street": "Lindenstraße 1",
  "PostCode": 66583,
  "City": "Spiesen-Elversberg",
  "Latitude": "49.317.689",
  "Longitude": "07.122.482",
  "Telephone": "06821790981",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.317689,7.122482",
  "id": "49.317689,7.122482"
}, {
  "Brand": "ARAL",
  "Street": "Merziger Straße 106",
  "PostCode": 66763,
  "City": "Dillingen",
  "Latitude": "49.356.689",
  "Longitude": "06.720.603",
  "Telephone": "0683171417",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.356689,6.720603",
  "id": "49.356689,6.720603"
}, {
  "Brand": "ARAL",
  "Street": "Im Erfenbacher Tal 15",
  "PostCode": 67661,
  "City": "Kaiserslautern",
  "Latitude": "49.421.475",
  "Longitude": "07.703.652",
  "Telephone": "06313503528",
  "WeekDayFrom": "05:15",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "09:30",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.421475,7.703652",
  "id": "49.421475,7.703652"
}, {
  "Brand": "ARAL",
  "Street": "Primsweiler Straße 9",
  "PostCode": 66839,
  "City": "Schmelz",
  "Latitude": "49.418.601",
  "Longitude": "06.845.031",
  "Telephone": "068877474",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.418601,6.845031",
  "id": "49.418601,6.845031"
}, {
  "Brand": "ARAL",
  "Street": "Saarbruecker Straße 100",
  "PostCode": 66424,
  "City": "Homburg",
  "Latitude": "49.318.678",
  "Longitude": "07.327.816",
  "Telephone": "06841120865",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.318678,7.327816",
  "id": "49.318678,7.327816"
}, {
  "Brand": "ARAL",
  "Street": "Kreisstraße 26",
  "PostCode": 66578,
  "City": "Schiffweiler",
  "Latitude": "49.355.593",
  "Longitude": "07.129.624",
  "Telephone": "06821963739",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.355593,7.129624",
  "id": "49.355593,7.129624"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 243",
  "PostCode": 66589,
  "City": "Merchweiler",
  "Latitude": "49.351.048",
  "Longitude": "07.050.832",
  "Telephone": "068255037",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.351048,7.050832",
  "id": "49.351048,7.050832"
}, {
  "Brand": "ARAL",
  "Street": "Mainzer Straße 273",
  "PostCode": 66121,
  "City": "Saarbrücken",
  "Latitude": "49.225.500",
  "Longitude": "07.029.655",
  "Telephone": "068165358",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.2255,7.029655",
  "id": "49.2255,7.029655"
}, {
  "Brand": "ARAL",
  "Street": "Metzer Straße 44",
  "PostCode": 66740,
  "City": "Saarlouis",
  "Latitude": "49.305.459",
  "Longitude": "06.740.080",
  "Telephone": "0683140414",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.305459,6.74008",
  "id": "49.305459,6.74008"
}, {
  "Brand": "ARAL",
  "Street": "Saarbrücker Straße 6 a",
  "PostCode": 66386,
  "City": "St. Ingbert",
  "Latitude": "49.275.611",
  "Longitude": "07.110.575",
  "Telephone": "068944468",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.275611,7.110575",
  "id": "49.275611,7.110575"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 2 C",
  "PostCode": 66802,
  "City": "Überherrn",
  "Latitude": "49.248.609",
  "Longitude": "06.697.189",
  "Telephone": "0683692570",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "23:30",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "23:30",
  "SundayFrom": "05:30",
  "SundayTo": "23:30",
  "IsOpen24Hours": "Nein",
  "location": "49.248609,6.697189",
  "id": "49.248609,6.697189"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 242",
  "PostCode": 55743,
  "City": "Idar-Oberstein",
  "Latitude": "49.709.512",
  "Longitude": "07.315.356",
  "Telephone": "0678125120",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.709512,7.315356",
  "id": "49.709512,7.315356"
}, {
  "Brand": "ARAL",
  "Street": "Trossinger Straße 80",
  "PostCode": 78554,
  "City": "Aldingen",
  "Latitude": "48.091.747",
  "Longitude": "08.690.419",
  "Telephone": "074241717",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "18:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "48.091747,8.690419",
  "id": "48.091747,8.690419"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 41",
  "PostCode": 77781,
  "City": "Biberach",
  "Latitude": "48.340.320",
  "Longitude": "08.024.665",
  "Telephone": "078353925",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "18:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "48.34032,8.024665",
  "id": "48.34032,8.024665"
}, {
  "Brand": "ARAL",
  "Street": "Lenzkircher Straße 24",
  "PostCode": 79848,
  "City": "Bonndorf",
  "Latitude": "47.818.336",
  "Longitude": "08.330.662",
  "Telephone": "0770393780",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "08:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "47.818336,8.330662",
  "id": "47.818336,8.330662"
}, {
  "Brand": "ARAL",
  "Street": "Koenigschaffhauser Straße",
  "PostCode": 79346,
  "City": "Endingen",
  "Latitude": "48.143.505",
  "Longitude": "07.695.344",
  "Telephone": "07642920880",
  "WeekDayFrom": "07:30",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "19:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "48.143505,7.695344",
  "id": "48.143505,7.695344"
}, {
  "Brand": "ARAL",
  "Street": "Adlerstraße 29",
  "PostCode": 77948,
  "City": "Friesenheim",
  "Latitude": "48.370.685",
  "Longitude": "07.872.607",
  "Telephone": "07821997535",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "48.370685,7.872607",
  "id": "48.370685,7.872607"
}, {
  "Brand": "ARAL",
  "Street": "Steinacher Straße 7",
  "PostCode": 77716,
  "City": "Haslach",
  "Latitude": "48.277.731",
  "Longitude": "08.084.292",
  "Telephone": "078322404",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.277731,8.084292",
  "id": "48.277731,8.084292"
}, {
  "Brand": "ARAL",
  "Street": "Talheimer Straße 21",
  "PostCode": 72393,
  "City": "Burladingen - Melchi",
  "Latitude": "48.361.061",
  "Longitude": "09.145.284",
  "Telephone": "071261583",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.361061,9.145284",
  "id": "48.361061,9.145284"
}, {
  "Brand": "ARAL",
  "Street": "Hanauer Straße 64",
  "PostCode": 63739,
  "City": "Aschaffenburg",
  "Latitude": "49.980.452",
  "Longitude": "09.132.444",
  "Telephone": "0602127874",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.980452,9.132444",
  "id": "49.980452,9.132444"
}, {
  "Brand": "ARAL",
  "Street": "Am Luitpoldhain 1 A",
  "PostCode": 96050,
  "City": "Bamberg",
  "Latitude": "49.886.665",
  "Longitude": "10.906.391",
  "Telephone": "0951130725",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.886665,10.906391",
  "id": "49.886665,10.906391"
}, {
  "Brand": "ARAL",
  "Street": "An der Saale 1a",
  "PostCode": 97717,
  "City": "Euerdorf",
  "Latitude": "50.151.924",
  "Longitude": "10.026.564",
  "Telephone": "097047580",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "19:00",
  "SundayFrom": "09:30",
  "SundayTo": "18:30",
  "IsOpen24Hours": "Nein",
  "location": "50.151924,10.026564",
  "id": "50.151924,10.026564"
}, {
  "Brand": "ARAL",
  "Street": "Erlanger Straße 41",
  "PostCode": 91074,
  "City": "Herzogenaurach",
  "Latitude": "49.566.982",
  "Longitude": "10.892.825",
  "Telephone": "09132797981",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.566982,10.892825",
  "id": "49.566982,10.892825"
}, {
  "Brand": "ARAL",
  "Street": "Forststraße 3 - 5",
  "PostCode": 63796,
  "City": "Kahl",
  "Latitude": "50.075.266",
  "Longitude": "09.001.623",
  "Telephone": "06188900819",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:30",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.075266,9.001623",
  "id": "50.075266,9.001623"
}, {
  "Brand": "ARAL",
  "Street": "Kanzleistraße 1",
  "PostCode": 96328,
  "City": "Küps",
  "Latitude": "50.210.724",
  "Longitude": "11.290.923",
  "Telephone": "092649959073",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.210724,11.290923",
  "id": "50.210724,11.290923"
}, {
  "Brand": "ARAL",
  "Street": "Mergentheimer Straße 126",
  "PostCode": 74653,
  "City": "Künzelsau",
  "Latitude": "49.284.447",
  "Longitude": "09.684.491",
  "Telephone": "07940910821",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.284447,9.684491",
  "id": "49.284447,9.684491"
}, {
  "Brand": "ARAL",
  "Street": "Kronacher Straße 40",
  "PostCode": 96268,
  "City": "Mitwitz",
  "Latitude": "50.249.730",
  "Longitude": "11.214.630",
  "Telephone": "09266254",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "18:30",
  "IsOpen24Hours": "Nein",
  "location": "50.24973,11.21463",
  "id": "50.24973,11.21463"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 7",
  "PostCode": 69427,
  "City": "Mudau",
  "Latitude": "49.529.972",
  "Longitude": "09.204.745",
  "Telephone": "0628495025",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.529972,9.204745",
  "id": "49.529972,9.204745"
}, {
  "Brand": "ARAL",
  "Street": "Würzburger Straße 44",
  "PostCode": 97424,
  "City": "Schweinfurt",
  "Latitude": "50.024.444",
  "Longitude": "10.196.102",
  "Telephone": "0972181466",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:59",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:59",
  "SundayFrom": "05:00",
  "SundayTo": "22:59",
  "IsOpen24Hours": "Nein",
  "location": "50.024444,10.196102",
  "id": "50.024444,10.196102"
}, {
  "Brand": "ARAL",
  "Street": "Wuerzburger Straße 34",
  "PostCode": 97215,
  "City": "Uffenheim",
  "Latitude": "49.546.688",
  "Longitude": "10.227.066",
  "Telephone": "09842415",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "09:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.546688,10.227066",
  "id": "49.546688,10.227066"
}, {
  "Brand": "ARAL",
  "Street": "Sommeracher Straße 35",
  "PostCode": 97332,
  "City": "Volkach",
  "Latitude": "49.860.187",
  "Longitude": "10.227.348",
  "Telephone": "09381809616",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.860187,10.227348",
  "id": "49.860187,10.227348"
}, {
  "Brand": "ARAL",
  "Street": "Kronacher Straße 2",
  "PostCode": 96465,
  "City": "Neustadt b. Coburg",
  "Latitude": "50.276.108",
  "Longitude": "11.159.534",
  "Telephone": "095682449",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "18:30",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "15:00",
  "SundayFrom": "08:00",
  "SundayTo": "12:00",
  "IsOpen24Hours": "Nein",
  "location": "50.276108,11.159534",
  "id": "50.276108,11.159534"
}, {
  "Brand": "ARAL",
  "Street": "Nuernberger Straße 23",
  "PostCode": 90518,
  "City": "Altdorf",
  "Latitude": "49.384.428",
  "Longitude": "11.348.847",
  "Telephone": "0918790180",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.384428,11.348847",
  "id": "49.384428,11.348847"
}, {
  "Brand": "ARAL",
  "Street": "Ansbacher Straße 20",
  "PostCode": 91595,
  "City": "Burgoberbach",
  "Latitude": "49.239.043",
  "Longitude": "10.592.749",
  "Telephone": "09805275",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "08:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "49.239043,10.592749",
  "id": "49.239043,10.592749"
}, {
  "Brand": "ARAL",
  "Street": "Forther Hauptstraße 2",
  "PostCode": 90542,
  "City": "Eckental",
  "Latitude": "49.589.256",
  "Longitude": "11.217.015",
  "Telephone": "09126259515",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.589256,11.217015",
  "id": "49.589256,11.217015"
}, {
  "Brand": "ARAL",
  "Street": "Bamberger Straße 91",
  "PostCode": 91301,
  "City": "Forchheim",
  "Latitude": "49.733.900",
  "Longitude": "11.056.936",
  "Telephone": "09191727212",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.7339,11.056936",
  "id": "49.7339,11.056936"
}, {
  "Brand": "ARAL",
  "Street": "Nuernberger Straße 126",
  "PostCode": 90762,
  "City": "Fürth",
  "Latitude": "49.467.563",
  "Longitude": "11.005.729",
  "Telephone": "0911706709",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.467563,11.005729",
  "id": "49.467563,11.005729"
}, {
  "Brand": "ARAL",
  "Street": "Ansbacher Straße 34",
  "PostCode": 91586,
  "City": "Lichtenau",
  "Latitude": "49.281.171",
  "Longitude": "10.679.745",
  "Telephone": "09827250",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "09:00",
  "SundayTo": "12:00",
  "IsOpen24Hours": "Nein",
  "location": "49.281171,10.679745",
  "id": "49.281171,10.679745"
}, {
  "Brand": "ARAL",
  "Street": "Münchener Straße 401",
  "PostCode": 90471,
  "City": "Nürnberg",
  "Latitude": "49.401.853",
  "Longitude": "11.115.467",
  "Telephone": "0911806266",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.401853,11.115467",
  "id": "49.401853,11.115467"
}, {
  "Brand": "ARAL",
  "Street": "Allersberger Straße 33",
  "PostCode": 91154,
  "City": "Roth",
  "Latitude": "49.249.778",
  "Longitude": "11.095.588",
  "Telephone": "091712530",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.249778,11.095588",
  "id": "49.249778,11.095588"
}, {
  "Brand": "ARAL",
  "Street": "Schwandorfer Straße 10",
  "PostCode": 93426,
  "City": "Roding",
  "Latitude": "49.205.821",
  "Longitude": "12.486.366",
  "Telephone": "094611233",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.205821,12.486366",
  "id": "49.205821,12.486366"
}, {
  "Brand": "ARAL",
  "Street": "Deggendorfer Straße 21",
  "PostCode": 94535,
  "City": "Eging",
  "Latitude": "48.714.400",
  "Longitude": "13.266.438",
  "Telephone": "08544327",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "08:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "48.7144,13.266438",
  "id": "48.7144,13.266438"
}, {
  "Brand": "ARAL",
  "Street": "Deggendorfer Straße 48",
  "PostCode": 94491,
  "City": "Hengersberg",
  "Latitude": "48.780.168",
  "Longitude": "13.052.830",
  "Telephone": "0990193470",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.780168,13.05283",
  "id": "48.780168,13.05283"
}, {
  "Brand": "ARAL",
  "Street": "Neuburger Straße 141",
  "PostCode": 94036,
  "City": "Passau",
  "Latitude": "48.558.993",
  "Longitude": "13.421.607",
  "Telephone": "085153174",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.558993,13.421607",
  "id": "48.558993,13.421607"
}, {
  "Brand": "ARAL",
  "Street": "Ruselstraße 37",
  "PostCode": 94209,
  "City": "Regen",
  "Latitude": "48.966.408",
  "Longitude": "13.118.874",
  "Telephone": "099212431",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.966408,13.118874",
  "id": "48.966408,13.118874"
}, {
  "Brand": "ARAL",
  "Street": "Muenchner Straße 50",
  "PostCode": 84359,
  "City": "Simbach",
  "Latitude": "48.262.976",
  "Longitude": "13.016.449",
  "Telephone": "085712006",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:30",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "48.262976,13.016449",
  "id": "48.262976,13.016449"
}, {
  "Brand": "ARAL",
  "Street": "Landshuter Straße 16",
  "PostCode": 84149,
  "City": "Velden",
  "Latitude": "48.368.626",
  "Longitude": "12.251.242",
  "Telephone": "08742919288",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.368626,12.251242",
  "id": "48.368626,12.251242"
}, {
  "Brand": "ARAL",
  "Street": "Sauerlacher Straße 64",
  "PostCode": 82515,
  "City": "Wolfratshausen",
  "Latitude": "47.913.915",
  "Longitude": "11.432.373",
  "Telephone": "0817120793",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.913915,11.432373",
  "id": "47.913915,11.432373"
}, {
  "Brand": "ARAL",
  "Street": "Suedl.Muenchner Straße 28",
  "PostCode": 82031,
  "City": "Grünwald",
  "Latitude": "48.045.424",
  "Longitude": "11.529.082",
  "Telephone": "0896412061",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "48.045424,11.529082",
  "id": "48.045424,11.529082"
}, {
  "Brand": "ARAL",
  "Street": "Nymphenburger Straße 205",
  "PostCode": 80639,
  "City": "München",
  "Latitude": "48.157.731",
  "Longitude": "11.528.273",
  "Telephone": "089160379",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.157731,11.528273",
  "id": "48.157731,11.528273"
}, {
  "Brand": "ARAL",
  "Street": "Kapuzinerstraße 46",
  "PostCode": 80469,
  "City": "München",
  "Latitude": "48.123.890",
  "Longitude": "11.564.831",
  "Telephone": "089761224",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.12389,11.564831",
  "id": "48.12389,11.564831"
}, {
  "Brand": "ARAL",
  "Street": "Waldwiesenstraße 31",
  "PostCode": 81375,
  "City": "München",
  "Latitude": "48.119.009",
  "Longitude": "11.478.235",
  "Telephone": "08974029166",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "48.119009,11.478235",
  "id": "48.119009,11.478235"
}, {
  "Brand": "ARAL",
  "Street": "Berg-am-Laim-Straße 60",
  "PostCode": 81673,
  "City": "München",
  "Latitude": "48.130.509",
  "Longitude": "11.617.173",
  "Telephone": "08945409628",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.130509,11.617173",
  "id": "48.130509,11.617173"
}, {
  "Brand": "ARAL",
  "Street": "Grünwalder Straße 175 C",
  "PostCode": 81545,
  "City": "München",
  "Latitude": "48.096.381",
  "Longitude": "11.564.114",
  "Telephone": "08964270493",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.096381,11.564114",
  "id": "48.096381,11.564114"
}, {
  "Brand": "ARAL",
  "Street": "Aichacher Straße 26",
  "PostCode": 86316,
  "City": "Friedberg",
  "Latitude": "48.361.405",
  "Longitude": "10.987.790",
  "Telephone": "0821601885",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.361405,10.98779",
  "id": "48.361405,10.98779"
}, {
  "Brand": "ARAL",
  "Street": "Lachener Straße 56",
  "PostCode": 86911,
  "City": "Dießen",
  "Latitude": "47.957.967",
  "Longitude": "11.102.196",
  "Telephone": "088078776",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "47.957967,11.102196",
  "id": "47.957967,11.102196"
}, {
  "Brand": "ARAL",
  "Street": "Münchener Straße 37",
  "PostCode": 85567,
  "City": "Grafing",
  "Latitude": "48.049.402",
  "Longitude": "11.965.836",
  "Telephone": "080921822",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "15:00",
  "SundayFrom": "10:00",
  "SundayTo": "14:00",
  "IsOpen24Hours": "Nein",
  "location": "48.049402,11.965836",
  "id": "48.049402,11.965836"
}, {
  "Brand": "ARAL",
  "Street": "Schondorfer Straße 12",
  "PostCode": 86919,
  "City": "Utting am Ammersee",
  "Latitude": "48.027.708",
  "Longitude": "11.085.583",
  "Telephone": "088067267",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.027708,11.085583",
  "id": "48.027708,11.085583"
}, {
  "Brand": "ARAL",
  "Street": "Stuttgarter Straße 141",
  "PostCode": 71522,
  "City": "Backnang",
  "Latitude": "48.936.069",
  "Longitude": "09.437.789",
  "Telephone": "0719163813",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "48.936069,9.437789",
  "id": "48.936069,9.437789"
}, {
  "Brand": "ARAL",
  "Street": "Cannstatter Straße 46",
  "PostCode": 70190,
  "City": "Stuttgart",
  "Latitude": "48.789.219",
  "Longitude": "09.192.324",
  "Telephone": "07112625648",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.789219,9.192324",
  "id": "48.789219,9.192324"
}, {
  "Brand": "ARAL",
  "Street": "Deinbacher Straße 5",
  "PostCode": 73527,
  "City": "Schwäbisch Gmünd",
  "Latitude": "48.816.456",
  "Longitude": "09.778.075",
  "Telephone": "0717174516",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.816456,9.778075",
  "id": "48.816456,9.778075"
}, {
  "Brand": "ARAL",
  "Street": "Remsstraße 10",
  "PostCode": 73525,
  "City": "Schwäbisch Gmünd",
  "Latitude": "48.802.795",
  "Longitude": "09.797.567",
  "Telephone": "0717136288",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.802795,9.797567",
  "id": "48.802795,9.797567"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 4-8",
  "PostCode": 73342,
  "City": "Bad Ditzenbach",
  "Latitude": "48.581.652",
  "Longitude": "09.684.212",
  "Telephone": "073355304",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:30",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.581652,9.684212",
  "id": "48.581652,9.684212"
}, {
  "Brand": "ARAL",
  "Street": "Oststraße 72",
  "PostCode": 74072,
  "City": "Heilbronn",
  "Latitude": "49.140.013",
  "Longitude": "09.229.914",
  "Telephone": "07131177475",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.140013,9.229914",
  "id": "49.140013,9.229914"
}, {
  "Brand": "ARAL",
  "Street": "Theodor Heuss Straße 20",
  "PostCode": 72636,
  "City": "Frickenhausen",
  "Latitude": "48.582.309",
  "Longitude": "09.368.684",
  "Telephone": "070252555",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:30",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:30",
  "SundayFrom": "08:30",
  "SundayTo": "21:30",
  "IsOpen24Hours": "Nein",
  "location": "48.582309,9.368684",
  "id": "48.582309,9.368684"
}, {
  "Brand": "ARAL",
  "Street": "Heilbronner Straße 2",
  "PostCode": 74535,
  "City": "Mainhardt",
  "Latitude": "49.076.872",
  "Longitude": "09.563.253",
  "Telephone": "079031081",
  "WeekDayFrom": "07:30",
  "WeekDayTo": "19:30",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "18:00",
  "SundayFrom": "09:00",
  "SundayTo": "18:00",
  "IsOpen24Hours": "Nein",
  "location": "49.076872,9.563253",
  "id": "49.076872,9.563253"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 2",
  "PostCode": 73563,
  "City": "Mögglingen",
  "Latitude": "48.823.385",
  "Longitude": "09.957.800",
  "Telephone": "07174316",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "07:30",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.823385,9.9578",
  "id": "48.823385,9.9578"
}, {
  "Brand": "ARAL",
  "Street": "Plieninger Straße 96",
  "PostCode": 70567,
  "City": "Stuttgart",
  "Latitude": "48.723.202",
  "Longitude": "09.159.887",
  "Telephone": "07117285215",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.723202,9.159887",
  "id": "48.723202,9.159887"
}, {
  "Brand": "ARAL",
  "Street": "Wiener Straße 129",
  "PostCode": 70469,
  "City": "Stuttgart",
  "Latitude": "48.810.096",
  "Longitude": "09.154.195",
  "Telephone": "0711852430",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.810096,9.154195",
  "id": "48.810096,9.154195"
}, {
  "Brand": "ARAL",
  "Street": "Robert-Koch-Straße 64",
  "PostCode": 70563,
  "City": "Stuttgart",
  "Latitude": "48.724.877",
  "Longitude": "09.106.560",
  "Telephone": "07119019518",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.724877,9.10656",
  "id": "48.724877,9.10656"
}, {
  "Brand": "ARAL",
  "Street": "Rollinstraße 2",
  "PostCode": 88400,
  "City": "Biberach",
  "Latitude": "48.096.917",
  "Longitude": "09.791.901",
  "Telephone": "073519320",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.096917,9.791901",
  "id": "48.096917,9.791901"
}, {
  "Brand": "ARAL",
  "Street": "Messkircher Straße 31",
  "PostCode": 88512,
  "City": "Mengen",
  "Latitude": "48.044.291",
  "Longitude": "09.322.006",
  "Telephone": "075725670",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.044291,9.322006",
  "id": "48.044291,9.322006"
}, {
  "Brand": "ARAL",
  "Street": "Illerstraße 2",
  "PostCode": 89077,
  "City": "Ulm",
  "Latitude": "48.389.088",
  "Longitude": "09.978.195",
  "Telephone": "073130306",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.389088,9.978195",
  "id": "48.389088,9.978195"
}, {
  "Brand": "ARAL",
  "Street": "Karlstraße 77",
  "PostCode": 89073,
  "City": "Ulm",
  "Latitude": "48.406.403",
  "Longitude": "09.996.263",
  "Telephone": "073123019",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "48.406403,9.996263",
  "id": "48.406403,9.996263"
}, {
  "Brand": "ARAL",
  "Street": "Bismarckstraße 2",
  "PostCode": 10625,
  "City": "Berlin",
  "Latitude": "52.512.811",
  "Longitude": "13.319.742",
  "Telephone": "03034707002",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.512811,13.319742",
  "id": "52.512811,13.319742"
}, {
  "Brand": "ARAL",
  "Street": "Kaiser-Friedrich-Str. 45 B",
  "PostCode": 10627,
  "City": "Berlin",
  "Latitude": "52.507.780",
  "Longitude": "13.301.586",
  "Telephone": "03032701495",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.50778,13.301586",
  "id": "52.50778,13.301586"
}, {
  "Brand": "ARAL",
  "Street": "Messedamm 6",
  "PostCode": 14057,
  "City": "Berlin",
  "Latitude": "52.509.603",
  "Longitude": "13.281.325",
  "Telephone": "0303025484",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.509603,13.281325",
  "id": "52.509603,13.281325"
}, {
  "Brand": "ARAL",
  "Street": "Heerstraße 136",
  "PostCode": 14055,
  "City": "Berlin",
  "Latitude": "52.507.079",
  "Longitude": "13.227.781",
  "Telephone": "03030810202",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.507079,13.227781",
  "id": "52.507079,13.227781"
}, {
  "Brand": "ARAL",
  "Street": "Skalitzer Straße 26",
  "PostCode": 10999,
  "City": "Berlin",
  "Latitude": "52.498.703",
  "Longitude": "13.422.538",
  "Telephone": "03061702190",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.498703,13.422538",
  "id": "52.498703,13.422538"
}, {
  "Brand": "ARAL",
  "Street": "Neukoellner Str. 250-256",
  "PostCode": 12357,
  "City": "Berlin",
  "Latitude": "52.425.789",
  "Longitude": "13.482.354",
  "Telephone": "0306613947",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.425789,13.482354",
  "id": "52.425789,13.482354"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Straße 67",
  "PostCode": 13507,
  "City": "Berlin",
  "Latitude": "52.581.386",
  "Longitude": "13.291.377",
  "Telephone": "03043551049",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.581386,13.291377",
  "id": "52.581386,13.291377"
}, {
  "Brand": "ARAL",
  "Street": "Kaiser-Wilhelm-Straße 117",
  "PostCode": 12247,
  "City": "Berlin",
  "Latitude": "52.439.832",
  "Longitude": "13.351.252",
  "Telephone": "03076680128",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.439832,13.351252",
  "id": "52.439832,13.351252"
}, {
  "Brand": "ARAL",
  "Street": "Drakestraße 26a",
  "PostCode": 12205,
  "City": "Berlin",
  "Latitude": "52.440.849",
  "Longitude": "13.299.431",
  "Telephone": "03084309770",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.440849,13.299431",
  "id": "52.440849,13.299431"
}, {
  "Brand": "ARAL",
  "Street": "Dudenstraße 19",
  "PostCode": 10965,
  "City": "Berlin",
  "Latitude": "52.484.893",
  "Longitude": "13.381.366",
  "Telephone": "03078913831",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.484893,13.381366",
  "id": "52.484893,13.381366"
}, {
  "Brand": "ARAL",
  "Street": "Mariendorfer Damm 341",
  "PostCode": 12107,
  "City": "Berlin",
  "Latitude": "52.423.045",
  "Longitude": "13.396.742",
  "Telephone": "0307411920",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.423045,13.396742",
  "id": "52.423045,13.396742"
}, {
  "Brand": "ARAL",
  "Street": "Beusselstraße 55",
  "PostCode": 10553,
  "City": "Berlin",
  "Latitude": "52.530.855",
  "Longitude": "13.328.394",
  "Telephone": "0303914404",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.530855,13.328394",
  "id": "52.530855,13.328394"
}, {
  "Brand": "ARAL",
  "Street": "Suedwestkorso 36 A",
  "PostCode": 14197,
  "City": "Berlin",
  "Latitude": "52.468.439",
  "Longitude": "13.311.289",
  "Telephone": "0308211291",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.468439,13.311289",
  "id": "52.468439,13.311289"
}, {
  "Brand": "ARAL",
  "Street": "Blissestraße 21",
  "PostCode": 10713,
  "City": "Berlin",
  "Latitude": "52.484.325",
  "Longitude": "13.320.671",
  "Telephone": "0308738323",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.484325,13.320671",
  "id": "52.484325,13.320671"
}, {
  "Brand": "ARAL",
  "Street": "Hohenzollerndamm 97",
  "PostCode": 14199,
  "City": "Berlin",
  "Latitude": "52.475.887",
  "Longitude": "13.279.162",
  "Telephone": "0308256179",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.475887,13.279162",
  "id": "52.475887,13.279162"
}, {
  "Brand": "ARAL",
  "Street": "Westfaelische Straße 9-10",
  "PostCode": 10709,
  "City": "Berlin",
  "Latitude": "52.492.513",
  "Longitude": "13.309.285",
  "Telephone": "03086409379",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.492513,13.309285",
  "id": "52.492513,13.309285"
}, {
  "Brand": "ARAL",
  "Street": "Kurfuerstendamm 128",
  "PostCode": 10711,
  "City": "Berlin",
  "Latitude": "52.495.804",
  "Longitude": "13.287.605",
  "Telephone": "03089096972",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.495804,13.287605",
  "id": "52.495804,13.287605"
}, {
  "Brand": "ARAL",
  "Street": "Königstraße 58",
  "PostCode": 14109,
  "City": "Berlin",
  "Latitude": "52.419.210",
  "Longitude": "13.158.542",
  "Telephone": "0308051018",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.41921,13.158542",
  "id": "52.41921,13.158542"
}, {
  "Brand": "ARAL",
  "Street": "Angermuehle 8",
  "PostCode": 94469,
  "City": "Deggendorf",
  "Latitude": "48.835.113",
  "Longitude": "12.958.976",
  "Telephone": "099138300112",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.835113,12.958976",
  "id": "48.835113,12.958976"
}, {
  "Brand": "ARAL",
  "Street": "Konrad-Adenauer-Damm 100",
  "PostCode": 24143,
  "City": "Kiel",
  "Latitude": "54.296.963",
  "Longitude": "10.164.891",
  "Telephone": "0431782778",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.296963,10.164891",
  "id": "54.296963,10.164891"
}, {
  "Brand": "ARAL",
  "Street": "Weisendorfer Straße 16",
  "PostCode": 91056,
  "City": "Erlangen",
  "Latitude": "49.625.682",
  "Longitude": "10.940.643",
  "Telephone": "09135729895",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "04:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "04:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.625682,10.940643",
  "id": "49.625682,10.940643"
}, {
  "Brand": "ARAL",
  "Street": "Erlanger Straße 40",
  "PostCode": 90425,
  "City": "Nürnberg",
  "Latitude": "49.473.685",
  "Longitude": "11.064.863",
  "Telephone": "0911341045",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.473685,11.064863",
  "id": "49.473685,11.064863"
}, {
  "Brand": "ARAL",
  "Street": "Schiffbeker Weg 228",
  "PostCode": 22119,
  "City": "Hamburg",
  "Latitude": "53.561.980",
  "Longitude": "10.119.775",
  "Telephone": "0406532765",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.56198,10.119775",
  "id": "53.56198,10.119775"
}, {
  "Brand": "ARAL",
  "Street": "Gewerkenstraße 63",
  "PostCode": 45881,
  "City": "Gelsenkirchen",
  "Latitude": "51.519.656",
  "Longitude": "07.081.889",
  "Telephone": "02094082034",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.519656,7.081889",
  "id": "51.519656,7.081889"
}, {
  "Brand": "ARAL",
  "Street": "Buschkrugallee 84-92",
  "PostCode": 12359,
  "City": "Berlin",
  "Latitude": "52.455.891",
  "Longitude": "13.449.641",
  "Telephone": "0306061011",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.455891,13.449641",
  "id": "52.455891,13.449641"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 32",
  "PostCode": 58762,
  "City": "Altena",
  "Latitude": "51.302.346",
  "Longitude": "07.667.049",
  "Telephone": "0235225355",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.302346,7.667049",
  "id": "51.302346,7.667049"
}, {
  "Brand": "ARAL",
  "Street": "Regensburger Straße 30",
  "PostCode": 85088,
  "City": "Vohburg",
  "Latitude": "48.768.766",
  "Longitude": "11.623.462",
  "Telephone": "0845792960",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "18:30",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "13:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "48.768766,11.623462",
  "id": "48.768766,11.623462"
}, {
  "Brand": "ARAL",
  "Street": "Schlossstraße 63-64",
  "PostCode": 12165,
  "City": "Berlin",
  "Latitude": "52.453.079",
  "Longitude": "13.315.076",
  "Telephone": "0308344870",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.453079,13.315076",
  "id": "52.453079,13.315076"
}, {
  "Brand": "ARAL",
  "Street": "Kölner Straße 356",
  "PostCode": 40227,
  "City": "Düsseldorf",
  "Latitude": "51.209.536",
  "Longitude": "06.810.053",
  "Telephone": "021199614011",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.209536,6.810053",
  "id": "51.209536,6.810053"
}, {
  "Brand": "ARAL",
  "Street": "Stresemannstraße 310",
  "PostCode": 27580,
  "City": "Bremerhaven",
  "Latitude": "53.570.573",
  "Longitude": "08.598.823",
  "Telephone": "047185150",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.570573,8.598823",
  "id": "53.570573,8.598823"
}, {
  "Brand": "ARAL",
  "Street": "Vogelsbergstraße 25",
  "PostCode": 63679,
  "City": "Schotten",
  "Latitude": "50.495.586",
  "Longitude": "09.113.383",
  "Telephone": "060441524",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.495586,9.113383",
  "id": "50.495586,9.113383"
}, {
  "Brand": "ARAL",
  "Street": "A 2",
  "PostCode": 31275,
  "City": "Lehrte",
  "Latitude": "52.388.297",
  "Longitude": "09.997.808",
  "Telephone": "051324888",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.388297,9.997808",
  "id": "52.388297,9.997808"
}, {
  "Brand": "ARAL",
  "Street": "Lagesche Straße 60-64",
  "PostCode": 32657,
  "City": "Lemgo",
  "Latitude": "52.022.535",
  "Longitude": "08.885.877",
  "Telephone": "0526117044",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "52.022535,8.885877",
  "id": "52.022535,8.885877"
}, {
  "Brand": "ARAL",
  "Street": "Mergentheimer Straße 31",
  "PostCode": 97084,
  "City": "Würzburg",
  "Latitude": "49.768.126",
  "Longitude": "09.940.464",
  "Telephone": "0931611213",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.768126,9.940464",
  "id": "49.768126,9.940464"
}, {
  "Brand": "ARAL",
  "Street": "Westend 52",
  "PostCode": 46399,
  "City": "Bocholt",
  "Latitude": "51.838.627",
  "Longitude": "06.601.342",
  "Telephone": "02871233642",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.838627,6.601342",
  "id": "51.838627,6.601342"
}, {
  "Brand": "ARAL",
  "Street": "Wittenduener Allee 11",
  "PostCode": 25826,
  "City": "St Peter-Ording",
  "Latitude": "54.302.244",
  "Longitude": "08.651.125",
  "Telephone": "048633007",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "54.302244,8.651125",
  "id": "54.302244,8.651125"
}, {
  "Brand": "ARAL",
  "Street": "Bahnstraße 131",
  "PostCode": 46147,
  "City": "Oberhausen",
  "Latitude": "51.529.583",
  "Longitude": "06.797.949",
  "Telephone": "0208680549",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "10:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.529583,6.797949",
  "id": "51.529583,6.797949"
}, {
  "Brand": "ARAL",
  "Street": "Weismainer Straße 58",
  "PostCode": 96264,
  "City": "Altenkunstadt",
  "Latitude": "50.127.996",
  "Longitude": "11.239.743",
  "Telephone": "095721627",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.127996,11.239743",
  "id": "50.127996,11.239743"
}, {
  "Brand": "ARAL",
  "Street": "Curslacker Neuer Deich 34",
  "PostCode": 21029,
  "City": "Hamburg",
  "Latitude": "53.481.775",
  "Longitude": "10.208.122",
  "Telephone": "04072698676",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.481775,10.208122",
  "id": "53.481775,10.208122"
}, {
  "Brand": "ARAL",
  "Street": "Charlottenburger Straße 9",
  "PostCode": 37115,
  "City": "Duderstadt",
  "Latitude": "51.516.367",
  "Longitude": "10.242.981",
  "Telephone": "05527941535",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.516367,10.242981",
  "id": "51.516367,10.242981"
}, {
  "Brand": "ARAL",
  "Street": "Wilhelmstraße 24",
  "PostCode": 42489,
  "City": "Wülfrath",
  "Latitude": "51.285.329",
  "Longitude": "07.047.494",
  "Telephone": "0205874220",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.285329,7.047494",
  "id": "51.285329,7.047494"
}, {
  "Brand": "ARAL",
  "Street": "Regentalstraße 10",
  "PostCode": 93474,
  "City": "Arrach",
  "Latitude": "49.196.611",
  "Longitude": "13.008.035",
  "Telephone": "099433657",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.196611,13.008035",
  "id": "49.196611,13.008035"
}, {
  "Brand": "ARAL",
  "Street": "Erdinger Straße 25",
  "PostCode": 85570,
  "City": "Markt Schwaben",
  "Latitude": "48.193.274",
  "Longitude": "11.872.268",
  "Telephone": "081216010",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.193274,11.872268",
  "id": "48.193274,11.872268"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Straße 323",
  "PostCode": 40595,
  "City": "Düsseldorf",
  "Latitude": "51.138.005",
  "Longitude": "06.904.963",
  "Telephone": "02117053523",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.138005,6.904963",
  "id": "51.138005,6.904963"
}, {
  "Brand": "ARAL",
  "Street": "Donatusstraße 20",
  "PostCode": 50767,
  "City": "Köln",
  "Latitude": "51.003.404",
  "Longitude": "06.875.087",
  "Telephone": "02219591032",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "08:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "51.003404,6.875087",
  "id": "51.003404,6.875087"
}, {
  "Brand": "ARAL",
  "Street": "Werner-von-Siemens-Straße 2",
  "PostCode": 30880,
  "City": "Laatzen",
  "Latitude": "52.311.670",
  "Longitude": "09.798.237",
  "Telephone": "05118790787",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "52.31167,9.798237",
  "id": "52.31167,9.798237"
}, {
  "Brand": "ARAL",
  "Street": "In der Schwärzgrub 20",
  "PostCode": 55774,
  "City": "Baumholder",
  "Latitude": "49.609.330",
  "Longitude": "07.328.758",
  "Telephone": "067837701",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.60933,7.328758",
  "id": "49.60933,7.328758"
}, {
  "Brand": "ARAL",
  "Street": "Brühler Straße 160",
  "PostCode": 50389,
  "City": "Wesseling",
  "Latitude": "50.830.895",
  "Longitude": "06.956.559",
  "Telephone": "02232942668",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.830895,6.956559",
  "id": "50.830895,6.956559"
}, {
  "Brand": "ARAL",
  "Street": "Schönberger Straße 7-16",
  "PostCode": 74405,
  "City": "Gaildorf",
  "Latitude": "48.979.346",
  "Longitude": "09.779.330",
  "Telephone": "079716005",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.979346,9.77933",
  "id": "48.979346,9.77933"
}, {
  "Brand": "ARAL",
  "Street": "Krempelsdorfer Allee 63",
  "PostCode": 23556,
  "City": "Lübeck",
  "Latitude": "53.884.475",
  "Longitude": "10.653.804",
  "Telephone": "0451492396",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.884475,10.653804",
  "id": "53.884475,10.653804"
}, {
  "Brand": "ARAL",
  "Street": "Kölner Straße 255",
  "PostCode": 51149,
  "City": "Köln",
  "Latitude": "50.903.592",
  "Longitude": "07.020.729",
  "Telephone": "0220317951",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.903592,7.020729",
  "id": "50.903592,7.020729"
}, {
  "Brand": "ARAL",
  "Street": "Untermühlaustraße 50",
  "PostCode": 68169,
  "City": "Mannheim",
  "Latitude": "49.506.178",
  "Longitude": "08.463.967",
  "Telephone": "0621316970",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.506178,8.463967",
  "id": "49.506178,8.463967"
}, {
  "Brand": "ARAL",
  "Street": "Holsatenring 38",
  "PostCode": 24539,
  "City": "Neumünster",
  "Latitude": "54.063.387",
  "Longitude": "09.983.743",
  "Telephone": "0432141297",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.063387,9.983743",
  "id": "54.063387,9.983743"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 13",
  "PostCode": 67691,
  "City": "Hochspeyer",
  "Latitude": "49.443.363",
  "Longitude": "07.882.809",
  "Telephone": "06305715343",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.443363,7.882809",
  "id": "49.443363,7.882809"
}, {
  "Brand": "ARAL",
  "Street": "Mühlheimer Straße111-115",
  "PostCode": 63075,
  "City": "Offenbach",
  "Latitude": "50.105.589",
  "Longitude": "08.785.781",
  "Telephone": "06986781191",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.105589,8.785781",
  "id": "50.105589,8.785781"
}, {
  "Brand": "ARAL",
  "Street": "Hagenburger Straße 8",
  "PostCode": 31515,
  "City": "Wunstorf",
  "Latitude": "52.429.590",
  "Longitude": "09.423.001",
  "Telephone": "05031913719",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.42959,9.423001",
  "id": "52.42959,9.423001"
}, {
  "Brand": "ARAL",
  "Street": "Stader Straße 294-296",
  "PostCode": 21075,
  "City": "Hamburg",
  "Latitude": "53.470.872",
  "Longitude": "09.923.733",
  "Telephone": "0407928704",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.470872,9.923733",
  "id": "53.470872,9.923733"
}, {
  "Brand": "ARAL",
  "Street": "Werler Straße 30",
  "PostCode": 32105,
  "City": "Bad Salzuflen",
  "Latitude": "52.084.386",
  "Longitude": "08.736.496",
  "Telephone": "052224410",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.084386,8.736496",
  "id": "52.084386,8.736496"
}, {
  "Brand": "ARAL",
  "Street": "Schollstraße 2",
  "PostCode": 85055,
  "City": "Ingolstadt",
  "Latitude": "48.775.251",
  "Longitude": "11.459.352",
  "Telephone": "08412294",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.775251,11.459352",
  "id": "48.775251,11.459352"
}, {
  "Brand": "ARAL",
  "Street": "Dekkers Waide 1",
  "PostCode": 46419,
  "City": "Isselburg",
  "Latitude": "51.818.341",
  "Longitude": "06.442.688",
  "Telephone": "0287495672",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.818341,6.442688",
  "id": "51.818341,6.442688"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 32",
  "PostCode": 24217,
  "City": "Schönberg",
  "Latitude": "54.388.788",
  "Longitude": "10.373.199",
  "Telephone": "04344411144",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "54.388788,10.373199",
  "id": "54.388788,10.373199"
}, {
  "Brand": "ARAL",
  "Street": "Kurt-Schumacher-Straße155",
  "PostCode": 45881,
  "City": "Gelsenkirchen",
  "Latitude": "51.532.884",
  "Longitude": "07.077.537",
  "Telephone": "02099443052",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.532884,7.077537",
  "id": "51.532884,7.077537"
}, {
  "Brand": "ARAL",
  "Street": "Ploener Landstraße 7",
  "PostCode": 23701,
  "City": "Eutin",
  "Latitude": "54.128.960",
  "Longitude": "10.580.020",
  "Telephone": "045214016302",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "54.12896,10.58002",
  "id": "54.12896,10.58002"
}, {
  "Brand": "ARAL",
  "Street": "Victoriastraße 174",
  "PostCode": 45772,
  "City": "Marl",
  "Latitude": "51.665.641",
  "Longitude": "07.150.652",
  "Telephone": "02365415204",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.665641,7.150652",
  "id": "51.665641,7.150652"
}, {
  "Brand": "ARAL",
  "Street": "Alte Bundesstraße 25",
  "PostCode": 71332,
  "City": "Waiblingen",
  "Latitude": "48.824.863",
  "Longitude": "09.319.770",
  "Telephone": "07151905014",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.824863,9.31977",
  "id": "48.824863,9.31977"
}, {
  "Brand": "ARAL",
  "Street": "Nikolaus-Otto-Straße 7-9",
  "PostCode": 65582,
  "City": "Diez",
  "Latitude": "50.372.392",
  "Longitude": "08.036.092",
  "Telephone": "06432910915",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.372392,8.036092",
  "id": "50.372392,8.036092"
}, {
  "Brand": "ARAL",
  "Street": "Neuwieder Straße 38-40",
  "PostCode": 56269,
  "City": "Dierdorf",
  "Latitude": "50.547.550",
  "Longitude": "07.647.419",
  "Telephone": "026892038",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.54755,7.647419",
  "id": "50.54755,7.647419"
}, {
  "Brand": "ARAL",
  "Street": "Otto-Renner-Straße 1",
  "PostCode": 89231,
  "City": "Neu-Ulm",
  "Latitude": "48.386.472",
  "Longitude": "10.035.743",
  "Telephone": "07317253429",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.386472,10.035743",
  "id": "48.386472,10.035743"
}, {
  "Brand": "ARAL",
  "Street": "Hoehenweg 23-25",
  "PostCode": 46147,
  "City": "Oberhausen",
  "Latitude": "51.548.381",
  "Longitude": "06.851.153",
  "Telephone": "0208627088",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.548381,6.851153",
  "id": "51.548381,6.851153"
}, {
  "Brand": "ARAL",
  "Street": "Forstmeisterstraße 53",
  "PostCode": 97705,
  "City": "Burkardroth",
  "Latitude": "50.263.128",
  "Longitude": "09.994.378",
  "Telephone": "097349293",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.263128,9.994378",
  "id": "50.263128,9.994378"
}, {
  "Brand": "ARAL",
  "Street": "Schorndorfer Straße 49",
  "PostCode": 73660,
  "City": "Urbach",
  "Latitude": "48.809.126",
  "Longitude": "09.570.530",
  "Telephone": "07181981808",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.809126,9.57053",
  "id": "48.809126,9.57053"
}, {
  "Brand": "ARAL",
  "Street": "Alleestraße 18",
  "PostCode": 56410,
  "City": "Montabaur",
  "Latitude": "50.440.110",
  "Longitude": "07.830.385",
  "Telephone": "02602917369",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.44011,7.830385",
  "id": "50.44011,7.830385"
}, {
  "Brand": "ARAL",
  "Street": "Am Augarten 1",
  "PostCode": 35435,
  "City": "Wettenberg",
  "Latitude": "50.611.391",
  "Longitude": "08.648.171",
  "Telephone": "06419805045",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.611391,8.648171",
  "id": "50.611391,8.648171"
}, {
  "Brand": "ARAL",
  "Street": "Fischbacher Straße 1",
  "PostCode": 55743,
  "City": "Idar-Oberstein",
  "Latitude": "49.738.978",
  "Longitude": "07.408.038",
  "Telephone": "0678499800",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.738978,7.408038",
  "id": "49.738978,7.408038"
}, {
  "Brand": "ARAL",
  "Street": "Osnabruecker Straße 36 A",
  "PostCode": 49205,
  "City": "Hasbergen",
  "Latitude": "52.236.850",
  "Longitude": "07.962.840",
  "Telephone": "054053525",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.23685,7.96284",
  "id": "52.23685,7.96284"
}, {
  "Brand": "ARAL",
  "Street": "Waldstraße 48-56",
  "PostCode": 68305,
  "City": "Mannheim",
  "Latitude": "49.528.229",
  "Longitude": "08.489.984",
  "Telephone": "0621751361",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "49.528229,8.489984",
  "id": "49.528229,8.489984"
}, {
  "Brand": "ARAL",
  "Street": "Rue de Wattrelos 11",
  "PostCode": 52249,
  "City": "Eschweiler",
  "Latitude": "50.825.320",
  "Longitude": "06.247.152",
  "Telephone": "02403830005",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.82532,6.247152",
  "id": "50.82532,6.247152"
}, {
  "Brand": "ARAL",
  "Street": "Barkhausenstraße 50",
  "PostCode": 27568,
  "City": "Bremerhaven",
  "Latitude": "53.551.878",
  "Longitude": "08.568.498",
  "Telephone": "04719413835",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.551878,8.568498",
  "id": "53.551878,8.568498"
}, {
  "Brand": "ARAL",
  "Street": "Kappeler Straße 50",
  "PostCode": 55481,
  "City": "Kirchberg",
  "Latitude": "49.950.477",
  "Longitude": "07.404.095",
  "Telephone": "06763960706",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.950477,7.404095",
  "id": "49.950477,7.404095"
}, {
  "Brand": "ARAL",
  "Street": "Industriestraße 43",
  "PostCode": 47652,
  "City": "Weeze",
  "Latitude": "51.620.177",
  "Longitude": "06.209.968",
  "Telephone": "02837962551",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.620177,6.209968",
  "id": "51.620177,6.209968"
}, {
  "Brand": "ARAL",
  "Street": "Industriestraße 1",
  "PostCode": 64546,
  "City": "Mörfelden",
  "Latitude": "49.979.076",
  "Longitude": "08.581.539",
  "Telephone": "06105921766",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.979076,8.581539",
  "id": "49.979076,8.581539"
}, {
  "Brand": "ARAL",
  "Street": "Koblenzer Straße 237",
  "PostCode": 56154,
  "City": "Boppard",
  "Latitude": "50.236.740",
  "Longitude": "07.577.054",
  "Telephone": "067422447",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.23674,7.577054",
  "id": "50.23674,7.577054"
}, {
  "Brand": "ARAL",
  "Street": "Boeblinger Straße 69",
  "PostCode": 71088,
  "City": "Holzgerlingen",
  "Latitude": "48.645.636",
  "Longitude": "09.009.776",
  "Telephone": "07031605288",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "48.645636,9.009776",
  "id": "48.645636,9.009776"
}, {
  "Brand": "ARAL",
  "Street": "Kirchmeierstraße 20",
  "PostCode": 93051,
  "City": "Regensburg",
  "Latitude": "49.011.024",
  "Longitude": "12.071.235",
  "Telephone": "09413998303",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.011024,12.071235",
  "id": "49.011024,12.071235"
}, {
  "Brand": "ARAL",
  "Street": "Baerenbruch 135",
  "PostCode": 44379,
  "City": "Dortmund",
  "Latitude": "51.519.744",
  "Longitude": "07.372.563",
  "Telephone": "02316102302",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.519744,7.372563",
  "id": "51.519744,7.372563"
}, {
  "Brand": "ARAL",
  "Street": "Schöneberger Str. / Alboi",
  "PostCode": 12103,
  "City": "Berlin",
  "Latitude": "52.470.026",
  "Longitude": "13.371.043",
  "Telephone": "03075446987",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.470026,13.371043",
  "id": "52.470026,13.371043"
}, {
  "Brand": "ARAL",
  "Street": "Garmischer Straße 138",
  "PostCode": 80686,
  "City": "München",
  "Latitude": "48.127.150",
  "Longitude": "11.522.233",
  "Telephone": "08957868298",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.12715,11.522233",
  "id": "48.12715,11.522233"
}, {
  "Brand": "ARAL",
  "Street": "Obernburger Straße 127-13",
  "PostCode": 63811,
  "City": "Stockstadt",
  "Latitude": "49.965.195",
  "Longitude": "09.075.168",
  "Telephone": "06027401675",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.965195,9.075168",
  "id": "49.965195,9.075168"
}, {
  "Brand": "ARAL",
  "Street": "Rübhofstraße 27",
  "PostCode": 27711,
  "City": "Osterholz-Scharmbeck",
  "Latitude": "53.228.002",
  "Longitude": "08.811.051",
  "Telephone": "04791982119",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.228002,8.811051",
  "id": "53.228002,8.811051"
}, {
  "Brand": "ARAL",
  "Street": "Radeburger Straße/Hellerhof",
  "PostCode": 1129,
  "City": "Dresden",
  "Latitude": "51.094.532",
  "Longitude": "13.736.135",
  "Telephone": "03518498840",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.094532,13.736135",
  "id": "51.094532,13.736135"
}, {
  "Brand": "ARAL",
  "Street": "BAB24 Nordseite",
  "PostCode": 16833,
  "City": "Linum",
  "Latitude": "52.754.541",
  "Longitude": "12.855.390",
  "Telephone": "03392250538",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.754541,12.85539",
  "id": "52.754541,12.85539"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Straße 197",
  "PostCode": 16515,
  "City": "Oranienburg",
  "Latitude": "52.728.338",
  "Longitude": "13.246.281",
  "Telephone": "03301530720",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.728338,13.246281",
  "id": "52.728338,13.246281"
}, {
  "Brand": "ARAL",
  "Street": "Leinastraße 80",
  "PostCode": 99867,
  "City": "Gotha",
  "Latitude": "50.934.120",
  "Longitude": "10.676.027",
  "Telephone": "03621702636",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.93412,10.676027",
  "id": "50.93412,10.676027"
}, {
  "Brand": "ARAL",
  "Street": "Dresdener Straße 49C",
  "PostCode": 2625,
  "City": "Bautzen",
  "Latitude": "51.179.229",
  "Longitude": "14.408.396",
  "Telephone": "03591301677",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.179229,14.408396",
  "id": "51.179229,14.408396"
}, {
  "Brand": "ARAL",
  "Street": "Theodor-Koerner-Allee 2",
  "PostCode": 2763,
  "City": "Zittau",
  "Latitude": "50.893.853",
  "Longitude": "14.801.810",
  "Telephone": "03583510275",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.893853,14.80181",
  "id": "50.893853,14.80181"
}, {
  "Brand": "ARAL",
  "Street": "Sonnenstraße 1",
  "PostCode": 9648,
  "City": "Mittweida",
  "Latitude": "50.993.401",
  "Longitude": "12.965.552",
  "Telephone": "0372790602",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:30",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "21:30",
  "SundayFrom": "05:30",
  "SundayTo": "21:30",
  "IsOpen24Hours": "Nein",
  "location": "50.993401,12.965552",
  "id": "50.993401,12.965552"
}, {
  "Brand": "ARAL",
  "Street": "Buttstaedter Straße 50",
  "PostCode": 99510,
  "City": "Apolda",
  "Latitude": "51.040.082",
  "Longitude": "11.511.237",
  "Telephone": "03644555516",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.040082,11.511237",
  "id": "51.040082,11.511237"
}, {
  "Brand": "ARAL",
  "Street": "Gelsenkirchener Allee 30",
  "PostCode": 3050,
  "City": "Cottbus",
  "Latitude": "51.726.583",
  "Longitude": "14.336.133",
  "Telephone": "0355524021",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.726583,14.336133",
  "id": "51.726583,14.336133"
}, {
  "Brand": "ARAL",
  "Street": "Rudolstaedter Straße 58",
  "PostCode": 99099,
  "City": "Erfurt",
  "Latitude": "50.969.068",
  "Longitude": "11.061.260",
  "Telephone": "03614211782",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "07:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "50.969068,11.06126",
  "id": "50.969068,11.06126"
}, {
  "Brand": "ARAL",
  "Street": "Leipziger Str. 129",
  "PostCode": 4425,
  "City": "Taucha",
  "Latitude": "51.372.120",
  "Longitude": "12.474.988",
  "Telephone": "03429834595",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.37212,12.474988",
  "id": "51.37212,12.474988"
}, {
  "Brand": "ARAL",
  "Street": "Jerichower Straße 24",
  "PostCode": 39114,
  "City": "Magdeburg",
  "Latitude": "52.131.939",
  "Longitude": "11.665.182",
  "Telephone": "03918110060",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.131939,11.665182",
  "id": "52.131939,11.665182"
}, {
  "Brand": "ARAL",
  "Street": "Leipziger Straße 257",
  "PostCode": 9114,
  "City": "Chemnitz",
  "Latitude": "50.858.133",
  "Longitude": "12.869.132",
  "Telephone": "03713301680",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.858133,12.869132",
  "id": "50.858133,12.869132"
}, {
  "Brand": "ARAL",
  "Street": "Merseburger Straße 355",
  "PostCode": 6132,
  "City": "Halle",
  "Latitude": "51.440.122",
  "Longitude": "11.984.517",
  "Telephone": "03457758492",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "51.440122,11.984517",
  "id": "51.440122,11.984517"
}, {
  "Brand": "ARAL",
  "Street": "BAB24 Südseite",
  "PostCode": 16833,
  "City": "Linum",
  "Latitude": "52.753.324",
  "Longitude": "12.852.629",
  "Telephone": "03392250546",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.753324,12.852629",
  "id": "52.753324,12.852629"
}, {
  "Brand": "ARAL",
  "Street": "Eberswalder Straße 37",
  "PostCode": 16227,
  "City": "Eberswalde-Finow",
  "Latitude": "52.839.057",
  "Longitude": "13.754.600",
  "Telephone": "03334352814",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.839057,13.7546",
  "id": "52.839057,13.7546"
}, {
  "Brand": "ARAL",
  "Street": "Potsdamer Straße 22",
  "PostCode": 14776,
  "City": "Brandenburg",
  "Latitude": "52.406.107",
  "Longitude": "12.575.959",
  "Telephone": "03381524079",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.406107,12.575959",
  "id": "52.406107,12.575959"
}, {
  "Brand": "ARAL",
  "Street": "Koburger Straße 52",
  "PostCode": 4416,
  "City": "Markkleeberg",
  "Latitude": "51.286.089",
  "Longitude": "12.366.098",
  "Telephone": "03413582448",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.286089,12.366098",
  "id": "51.286089,12.366098"
}, {
  "Brand": "ARAL",
  "Street": "Krietzschwitzer Straße 7",
  "PostCode": 1796,
  "City": "Pirna",
  "Latitude": "50.957.801",
  "Longitude": "13.949.456",
  "Telephone": "03501761642",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.957801,13.949456",
  "id": "50.957801,13.949456"
}, {
  "Brand": "ARAL",
  "Street": "Gerlachstraße 8",
  "PostCode": 14480,
  "City": "Potsdam",
  "Latitude": "52.367.852",
  "Longitude": "13.128.570",
  "Telephone": "0331613452",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.367852,13.12857",
  "id": "52.367852,13.12857"
}, {
  "Brand": "ARAL",
  "Street": "Nikolaus-Otto-Straße 1",
  "PostCode": 6667,
  "City": "Weißenfels",
  "Latitude": "51.188.240",
  "Longitude": "11.989.125",
  "Telephone": "03443302222",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.18824,11.989125",
  "id": "51.18824,11.989125"
}, {
  "Brand": "ARAL",
  "Street": "Drewitzer Straße 20 A",
  "PostCode": 14478,
  "City": "Potsdam",
  "Latitude": "52.374.110",
  "Longitude": "13.088.152",
  "Telephone": "0331864635",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.37411,13.088152",
  "id": "52.37411,13.088152"
}, {
  "Brand": "ARAL",
  "Street": "Schnellerstraße 20",
  "PostCode": 12439,
  "City": "Berlin",
  "Latitude": "52.459.819",
  "Longitude": "13.504.415",
  "Telephone": "0306316974",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.459819,13.504415",
  "id": "52.459819,13.504415"
}, {
  "Brand": "ARAL",
  "Street": "Riestedter Straße 55",
  "PostCode": 6526,
  "City": "Sangerhausen",
  "Latitude": "51.480.374",
  "Longitude": "11.317.881",
  "Telephone": "03464572843",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.480374,11.317881",
  "id": "51.480374,11.317881"
}, {
  "Brand": "ARAL",
  "Street": "Magdeburger Chaussee 7 A",
  "PostCode": 38820,
  "City": "Halberstadt",
  "Latitude": "51.901.065",
  "Longitude": "11.073.988",
  "Telephone": "03941600245",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.901065,11.073988",
  "id": "51.901065,11.073988"
}, {
  "Brand": "ARAL",
  "Street": "Kötzschenbroder Str. 188",
  "PostCode": 1139,
  "City": "Dresden",
  "Latitude": "51.090.615",
  "Longitude": "13.675.574",
  "Telephone": "03518301510",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "07:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.090615,13.675574",
  "id": "51.090615,13.675574"
}, {
  "Brand": "ARAL",
  "Street": "Neefestraße 145",
  "PostCode": 9116,
  "City": "Chemnitz",
  "Latitude": "50.816.450",
  "Longitude": "12.882.990",
  "Telephone": "03712806239",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.81645,12.88299",
  "id": "50.81645,12.88299"
}, {
  "Brand": "ARAL",
  "Street": "Märkische Allee 248",
  "PostCode": 12679,
  "City": "Berlin",
  "Latitude": "52.553.732",
  "Longitude": "13.551.587",
  "Telephone": "0309312140",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.553732,13.551587",
  "id": "52.553732,13.551587"
}, {
  "Brand": "ARAL",
  "Street": "Nordpark 3",
  "PostCode": 6237,
  "City": "Leuna OT Kötschlitz",
  "Latitude": "51.347.609",
  "Longitude": "12.175.212",
  "Telephone": "03463820984",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.347609,12.175212",
  "id": "51.347609,12.175212"
}, {
  "Brand": "ARAL",
  "Street": "Stadtrodaer Straße 11",
  "PostCode": 7749,
  "City": "Jena",
  "Latitude": "50.906.034",
  "Longitude": "11.589.875",
  "Telephone": "03641394893",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.906034,11.589875",
  "id": "50.906034,11.589875"
}, {
  "Brand": "ARAL",
  "Street": "Ichtershaeuser Straße 84",
  "PostCode": 99310,
  "City": "Arnstadt",
  "Latitude": "50.856.769",
  "Longitude": "10.958.926",
  "Telephone": "0362878340",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.856769,10.958926",
  "id": "50.856769,10.958926"
}, {
  "Brand": "ARAL",
  "Street": "Dessauer Platz 4",
  "PostCode": 6118,
  "City": "Halle",
  "Latitude": "51.497.411",
  "Longitude": "11.981.950",
  "Telephone": "03452909012",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.497411,11.98195",
  "id": "51.497411,11.98195"
}, {
  "Brand": "ARAL",
  "Street": "Tessiner Straße 68",
  "PostCode": 18055,
  "City": "Rostock",
  "Latitude": "54.081.197",
  "Longitude": "12.194.307",
  "Telephone": "0381690324",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.081197,12.194307",
  "id": "54.081197,12.194307"
}, {
  "Brand": "ARAL",
  "Street": "Marienthaler Straße 2",
  "PostCode": 8060,
  "City": "Zwickau",
  "Latitude": "50.720.628",
  "Longitude": "12.473.056",
  "Telephone": "0375523928",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.720628,12.473056",
  "id": "50.720628,12.473056"
}, {
  "Brand": "ARAL",
  "Street": "Junkersstraße/Weststraße",
  "PostCode": 6847,
  "City": "Dessau",
  "Latitude": "51.826.171",
  "Longitude": "12.214.941",
  "Telephone": "0340517101",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.826171,12.214941",
  "id": "51.826171,12.214941"
}, {
  "Brand": "ARAL",
  "Street": "BAB A10 Nordseite",
  "PostCode": 15749,
  "City": "Brusendorf",
  "Latitude": "52.318.454",
  "Longitude": "13.498.319",
  "Telephone": "033764726026",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.318454,13.498319",
  "id": "52.318454,13.498319"
}, {
  "Brand": "ARAL",
  "Street": "BAB A10 Suedseite",
  "PostCode": 15749,
  "City": "Brusendorf",
  "Latitude": "52.315.623",
  "Longitude": "13.493.713",
  "Telephone": "033764726022",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.315623,13.493713",
  "id": "52.315623,13.493713"
}, {
  "Brand": "ARAL",
  "Street": "Grossbeerenstraße 121",
  "PostCode": 14482,
  "City": "Potsdam",
  "Latitude": "52.386.085",
  "Longitude": "13.106.655",
  "Telephone": "03317482335",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.386085,13.106655",
  "id": "52.386085,13.106655"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Allee 214",
  "PostCode": 10365,
  "City": "Berlin",
  "Latitude": "52.511.439",
  "Longitude": "13.493.861",
  "Telephone": "0305578472",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.511439,13.493861",
  "id": "52.511439,13.493861"
}, {
  "Brand": "ARAL",
  "Street": "Greifswalder Chaussee 62",
  "PostCode": 18439,
  "City": "Stralsund",
  "Latitude": "54.276.688",
  "Longitude": "13.106.870",
  "Telephone": "03831270126",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "54.276688,13.10687",
  "id": "54.276688,13.10687"
}, {
  "Brand": "ARAL",
  "Street": "Schoenebecker Straße 74",
  "PostCode": 39104,
  "City": "Magdeburg",
  "Latitude": "52.099.175",
  "Longitude": "11.647.769",
  "Telephone": "03914015407",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.099175,11.647769",
  "id": "52.099175,11.647769"
}, {
  "Brand": "ARAL",
  "Street": "Zeppelinstraße 2",
  "PostCode": 12529,
  "City": "Schönefeld",
  "Latitude": "52.367.812",
  "Longitude": "13.560.766",
  "Telephone": "03063311794",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.367812,13.560766",
  "id": "52.367812,13.560766"
}, {
  "Brand": "ARAL",
  "Street": "Kasseler Straße 50",
  "PostCode": 99817,
  "City": "Eisenach",
  "Latitude": "50.981.121",
  "Longitude": "10.302.172",
  "Telephone": "0369177024",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Ja",
  "location": "50.981121,10.302172",
  "id": "50.981121,10.302172"
}, {
  "Brand": "ARAL",
  "Street": "Alt-Friedrichsfelde 64",
  "PostCode": 12683,
  "City": "Berlin",
  "Latitude": "52.510.314",
  "Longitude": "13.538.487",
  "Telephone": "0305133078",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.510314,13.538487",
  "id": "52.510314,13.538487"
}, {
  "Brand": "ARAL",
  "Street": "Ziolkowskistraße 14",
  "PostCode": 39126,
  "City": "Magdeburg",
  "Latitude": "52.175.453",
  "Longitude": "11.629.103",
  "Telephone": "03912515281",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.175453,11.629103",
  "id": "52.175453,11.629103"
}, {
  "Brand": "ARAL",
  "Street": "Großenhainer Straße 41",
  "PostCode": 1662,
  "City": "Meißen",
  "Latitude": "51.165.799",
  "Longitude": "13.486.726",
  "Telephone": "03521739910",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.165799,13.486726",
  "id": "51.165799,13.486726"
}, {
  "Brand": "ARAL",
  "Street": "An der Wuhlheide 240-242",
  "PostCode": 12459,
  "City": "Berlin",
  "Latitude": "52.455.211",
  "Longitude": "13.555.774",
  "Telephone": "03053790502",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.455211,13.555774",
  "id": "52.455211,13.555774"
}, {
  "Brand": "ARAL",
  "Street": "Landsberger Straße 116",
  "PostCode": 4157,
  "City": "Leipzig",
  "Latitude": "51.379.495",
  "Longitude": "12.358.635",
  "Telephone": "03419129891",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.379495,12.358635",
  "id": "51.379495,12.358635"
}, {
  "Brand": "ARAL",
  "Street": "Volkmannstraße 19",
  "PostCode": 6112,
  "City": "Halle",
  "Latitude": "51.487.914",
  "Longitude": "11.984.189",
  "Telephone": "03455126523",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.487914,11.984189",
  "id": "51.487914,11.984189"
}, {
  "Brand": "ARAL",
  "Street": "Groepelinger Heerstr. 237",
  "PostCode": 28239,
  "City": "Bremen",
  "Latitude": "53.118.219",
  "Longitude": "08.756.886",
  "Telephone": "0421612980",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.118219,8.756886",
  "id": "53.118219,8.756886"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Straße 170",
  "PostCode": 45144,
  "City": "Essen",
  "Latitude": "51.452.452",
  "Longitude": "06.972.793",
  "Telephone": "02018761280",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.452452,6.972793",
  "id": "51.452452,6.972793"
}, {
  "Brand": "ARAL",
  "Street": "Siemensstraße 37",
  "PostCode": 60594,
  "City": "Frankfurt",
  "Latitude": "50.102.360",
  "Longitude": "08.695.524",
  "Telephone": "069622953",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.10236,8.695524",
  "id": "50.10236,8.695524"
}, {
  "Brand": "ARAL",
  "Street": "Bundesstraße 150",
  "PostCode": 52538,
  "City": "Gangelt",
  "Latitude": "50.988.632",
  "Longitude": "06.033.085",
  "Telephone": "024547458",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "07:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.988632,6.033085",
  "id": "50.988632,6.033085"
}, {
  "Brand": "ARAL",
  "Street": "Kaiserstraße 145",
  "PostCode": 45699,
  "City": "Herten",
  "Latitude": "51.599.208",
  "Longitude": "07.148.233",
  "Telephone": "0236637188",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "04:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "05:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "51.599208,7.148233",
  "id": "51.599208,7.148233"
}, {
  "Brand": "ARAL",
  "Street": "Saarbruecker Straße 51",
  "PostCode": 66822,
  "City": "Lebach",
  "Latitude": "49.402.546",
  "Longitude": "06.916.348",
  "Telephone": "068813253",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.402546,6.916348",
  "id": "49.402546,6.916348"
}, {
  "Brand": "ARAL",
  "Street": "Ostlandstraße 65",
  "PostCode": 59556,
  "City": "Lippstadt",
  "Latitude": "51.705.443",
  "Longitude": "08.340.492",
  "Telephone": "0294180139",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.705443,8.340492",
  "id": "51.705443,8.340492"
}, {
  "Brand": "ARAL",
  "Street": "Luebbecker Straße 166",
  "PostCode": 32584,
  "City": "Löhne",
  "Latitude": "52.224.907",
  "Longitude": "08.706.377",
  "Telephone": "05732972802",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.224907,8.706377",
  "id": "52.224907,8.706377"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 166",
  "PostCode": 69242,
  "City": "Mühlhausen",
  "Latitude": "49.248.049",
  "Longitude": "08.735.546",
  "Telephone": "0622262546",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.248049,8.735546",
  "id": "49.248049,8.735546"
}, {
  "Brand": "ARAL",
  "Street": "Grossenbaumer Straße 67",
  "PostCode": 45481,
  "City": "Mülheim",
  "Latitude": "51.409.512",
  "Longitude": "06.853.948",
  "Telephone": "0208423854",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.409512,6.853948",
  "id": "51.409512,6.853948"
}, {
  "Brand": "ARAL",
  "Street": "Niendorfer Straße 50",
  "PostCode": 22848,
  "City": "Norderstedt",
  "Latitude": "53.672.500",
  "Longitude": "09.968.369",
  "Telephone": "04052876152",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.6725,9.968369",
  "id": "53.6725,9.968369"
}, {
  "Brand": "ARAL",
  "Street": "Kreuzwiese 1",
  "PostCode": 67806,
  "City": "Rockenhausen",
  "Latitude": "49.642.302",
  "Longitude": "07.826.909",
  "Telephone": "063619946820",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.642302,7.826909",
  "id": "49.642302,7.826909"
}, {
  "Brand": "ARAL",
  "Street": "Mainzer Straße 97",
  "PostCode": 66121,
  "City": "Saarbrücken",
  "Latitude": "49.229.480",
  "Longitude": "07.007.818",
  "Telephone": "068161887",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.22948,7.007818",
  "id": "49.22948,7.007818"
}, {
  "Brand": "ARAL",
  "Street": "Großblittersdorfer Straße 285",
  "PostCode": 66119,
  "City": "Saarbrücken",
  "Latitude": "49.195.240",
  "Longitude": "07.023.590",
  "Telephone": "0681872270",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.19524,7.02359",
  "id": "49.19524,7.02359"
}, {
  "Brand": "ARAL",
  "Street": "Heiligenbronner Straße 10",
  "PostCode": 78713,
  "City": "Schramberg",
  "Latitude": "48.228.911",
  "Longitude": "08.421.462",
  "Telephone": "074229921710",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.228911,8.421462",
  "id": "48.228911,8.421462"
}, {
  "Brand": "ARAL",
  "Street": "Bremer Straße 55",
  "PostCode": 27367,
  "City": "Sottrum",
  "Latitude": "53.112.694",
  "Longitude": "09.226.717",
  "Telephone": "0426499110",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "53.112694,9.226717",
  "id": "53.112694,9.226717"
}, {
  "Brand": "ARAL",
  "Street": "A 1 / Westseite",
  "PostCode": 22965,
  "City": "Todendorf-W",
  "Latitude": "53.693.623",
  "Longitude": "10.321.713",
  "Telephone": "04534292005",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.693623,10.321713",
  "id": "53.693623,10.321713"
}, {
  "Brand": "ARAL",
  "Street": "Kaltenweider Straße 10",
  "PostCode": 30900,
  "City": "Wedemark",
  "Latitude": "52.542.359",
  "Longitude": "09.727.444",
  "Telephone": "051303554",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "10:00",
  "SundayTo": "17:00",
  "IsOpen24Hours": "Nein",
  "location": "52.542359,9.727444",
  "id": "52.542359,9.727444"
}, {
  "Brand": "ARAL",
  "Street": "Cranger Straße 243",
  "PostCode": 45891,
  "City": "Gelsenkirchen",
  "Latitude": "51.562.224",
  "Longitude": "07.087.587",
  "Telephone": "020975561",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.562224,7.087587",
  "id": "51.562224,7.087587"
}, {
  "Brand": "ARAL",
  "Street": "Kirchheimer Straße 97",
  "PostCode": 73230,
  "City": "Kirchheim",
  "Latitude": "48.641.695",
  "Longitude": "09.476.037",
  "Telephone": "0702181775",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.641695,9.476037",
  "id": "48.641695,9.476037"
}, {
  "Brand": "ARAL",
  "Street": "Luetticher Straße 100",
  "PostCode": 40547,
  "City": "Düsseldorf",
  "Latitude": "51.240.522",
  "Longitude": "06.743.853",
  "Telephone": "0211552322",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.240522,6.743853",
  "id": "51.240522,6.743853"
}, {
  "Brand": "ARAL",
  "Street": "Schullendamm 51",
  "PostCode": 49716,
  "City": "Meppen",
  "Latitude": "52.691.659",
  "Longitude": "07.275.380",
  "Telephone": "0593112459",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "52.691659,7.27538",
  "id": "52.691659,7.27538"
}, {
  "Brand": "ARAL",
  "Street": "Moselstraße 1",
  "PostCode": 56332,
  "City": "Löf",
  "Latitude": "50.239.589",
  "Longitude": "07.441.824",
  "Telephone": "02605952325",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.239589,7.441824",
  "id": "50.239589,7.441824"
}, {
  "Brand": "ARAL",
  "Street": "Trautenfeldstraße 38",
  "PostCode": 68535,
  "City": "Edingen-Neckarhausen",
  "Latitude": "49.445.287",
  "Longitude": "08.580.330",
  "Telephone": "0621471739",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.445287,8.58033",
  "id": "49.445287,8.58033"
}, {
  "Brand": "ARAL",
  "Street": "Albtalstraße 18",
  "PostCode": 76359,
  "City": "Marxzell",
  "Latitude": "48.863.818",
  "Longitude": "08.447.864",
  "Telephone": "072486939",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.863818,8.447864",
  "id": "48.863818,8.447864"
}, {
  "Brand": "ARAL",
  "Street": "Darmstaedter Landstr. 63",
  "PostCode": 64331,
  "City": "Weiterstadt",
  "Latitude": "49.926.479",
  "Longitude": "08.602.613",
  "Telephone": "061505457360",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.926479,8.602613",
  "id": "49.926479,8.602613"
}, {
  "Brand": "ARAL",
  "Street": "Luebberstedter Straße 36",
  "PostCode": 21272,
  "City": "Egestorf",
  "Latitude": "53.194.046",
  "Longitude": "10.072.126",
  "Telephone": "04175587",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "53.194046,10.072126",
  "id": "53.194046,10.072126"
}, {
  "Brand": "ARAL",
  "Street": "Burgstraße 2",
  "PostCode": 26736,
  "City": "Krummhörn",
  "Latitude": "53.436.115",
  "Longitude": "07.093.333",
  "Telephone": "04923990108",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.436115,7.093333",
  "id": "53.436115,7.093333"
}, {
  "Brand": "ARAL",
  "Street": "Lange Straße 32",
  "PostCode": 26935,
  "City": "Stadland-Rodenkirche",
  "Latitude": "53.405.734",
  "Longitude": "08.459.758",
  "Telephone": "047322663",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:30",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "53.405734,8.459758",
  "id": "53.405734,8.459758"
}, {
  "Brand": "ARAL",
  "Street": "Bremer Straße 34",
  "PostCode": 34388,
  "City": "Trendelburg",
  "Latitude": "51.590.721",
  "Longitude": "09.409.088",
  "Telephone": "05675725292",
  "WeekDayFrom": "08:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.590721,9.409088",
  "id": "51.590721,9.409088"
}, {
  "Brand": "ARAL",
  "Street": "Schuelperstraße 27",
  "PostCode": 25764,
  "City": "Wesselburen",
  "Latitude": "54.214.361",
  "Longitude": "08.923.417",
  "Telephone": "048332373",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "05:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "54.214361,8.923417",
  "id": "54.214361,8.923417"
}, {
  "Brand": "ARAL",
  "Street": "Würzburger Straße 190",
  "PostCode": 63743,
  "City": "Aschaffenburg",
  "Latitude": "49.961.464",
  "Longitude": "09.177.972",
  "Telephone": "060218629290",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.961464,9.177972",
  "id": "49.961464,9.177972"
}, {
  "Brand": "ARAL",
  "Street": "Ulmer Straße 33",
  "PostCode": 89547,
  "City": "Gerstetten",
  "Latitude": "48.617.495",
  "Longitude": "10.024.707",
  "Telephone": "0732396240",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "07:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "48.617495,10.024707",
  "id": "48.617495,10.024707"
}, {
  "Brand": "ARAL",
  "Street": "Friedrichstraße 23",
  "PostCode": 89568,
  "City": "Hermaringen",
  "Latitude": "48.597.607",
  "Longitude": "10.256.253",
  "Telephone": "07322919572",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.597607,10.256253",
  "id": "48.597607,10.256253"
}, {
  "Brand": "ARAL",
  "Street": "Soegeler Straße 7",
  "PostCode": 49762,
  "City": "Lathen",
  "Latitude": "52.860.882",
  "Longitude": "07.328.503",
  "Telephone": "059331341",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "09:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.860882,7.328503",
  "id": "52.860882,7.328503"
}, {
  "Brand": "ARAL",
  "Street": "Schwetzinger Straße 4",
  "PostCode": 69214,
  "City": "Eppelheim",
  "Latitude": "49.399.130",
  "Longitude": "08.624.707",
  "Telephone": "06221763291",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.39913,8.624707",
  "id": "49.39913,8.624707"
}, {
  "Brand": "ARAL",
  "Street": "Blumenstraße 39",
  "PostCode": 71106,
  "City": "Magstadt",
  "Latitude": "48.744.829",
  "Longitude": "08.971.171",
  "Telephone": "07159949619",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "18:30",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "14:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "48.744829,8.971171",
  "id": "48.744829,8.971171"
}, {
  "Brand": "ARAL",
  "Street": "Karnaper Straße 44",
  "PostCode": 45329,
  "City": "Essen",
  "Latitude": "51.517.370",
  "Longitude": "07.007.558",
  "Telephone": "02014366875",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.51737,7.007558",
  "id": "51.51737,7.007558"
}, {
  "Brand": "ARAL",
  "Street": "Wittener Straße 68",
  "PostCode": 45549,
  "City": "Sprockhövel",
  "Latitude": "51.340.392",
  "Longitude": "07.289.735",
  "Telephone": "023392796",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.340392,7.289735",
  "id": "51.340392,7.289735"
}, {
  "Brand": "ARAL",
  "Street": "Aachener Straße 203-209",
  "PostCode": 50931,
  "City": "Köln",
  "Latitude": "50.936.368",
  "Longitude": "06.922.341",
  "Telephone": "02219402524",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.936368,6.922341",
  "id": "50.936368,6.922341"
}, {
  "Brand": "ARAL",
  "Street": "Homburger Landstraße 42",
  "PostCode": 60435,
  "City": "Frankfurt",
  "Latitude": "50.142.978",
  "Longitude": "08.696.452",
  "Telephone": "069541237",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:30",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:30",
  "SundayFrom": "08:00",
  "SundayTo": "20:30",
  "IsOpen24Hours": "Nein",
  "location": "50.142978,8.696452",
  "id": "50.142978,8.696452"
}, {
  "Brand": "ARAL",
  "Street": "Hofheimer Straße 24",
  "PostCode": 65439,
  "City": "Flörsheim",
  "Latitude": "50.047.755",
  "Longitude": "08.434.482",
  "Telephone": "0614531103",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.047755,8.434482",
  "id": "50.047755,8.434482"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 10",
  "PostCode": 69226,
  "City": "Nußloch",
  "Latitude": "49.329.301",
  "Longitude": "08.693.337",
  "Telephone": "0622410320",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.329301,8.693337",
  "id": "49.329301,8.693337"
}, {
  "Brand": "ARAL",
  "Street": "Silberburgstraße 21-23",
  "PostCode": 72764,
  "City": "Reutlingen",
  "Latitude": "48.498.342",
  "Longitude": "09.216.559",
  "Telephone": "0712117691",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "17:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "48.498342,9.216559",
  "id": "48.498342,9.216559"
}, {
  "Brand": "ARAL",
  "Street": "Dubelohstraße 143",
  "PostCode": 33104,
  "City": "Paderborn",
  "Latitude": "51.745.679",
  "Longitude": "08.731.283",
  "Telephone": "05254939178",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.745679,8.731283",
  "id": "51.745679,8.731283"
}, {
  "Brand": "ARAL",
  "Street": "B 49/Nordseite",
  "PostCode": 65614,
  "City": "Beselich",
  "Latitude": "50.469.120",
  "Longitude": "08.137.242",
  "Telephone": "06484890071",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.46912,8.137242",
  "id": "50.46912,8.137242"
}, {
  "Brand": "ARAL",
  "Street": "Werner-von-Siemens-Straße",
  "PostCode": 25337,
  "City": "Elmshorn",
  "Latitude": "53.745.524",
  "Longitude": "09.705.794",
  "Telephone": "041215796687",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.745524,9.705794",
  "id": "53.745524,9.705794"
}, {
  "Brand": "ARAL",
  "Street": "Kölnstraße 272",
  "PostCode": 50321,
  "City": "Brühl",
  "Latitude": "50.845.304",
  "Longitude": "06.915.569",
  "Telephone": "02232411019",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.845304,6.915569",
  "id": "50.845304,6.915569"
}, {
  "Brand": "ARAL",
  "Street": "A 5",
  "PostCode": 79576,
  "City": "Weil am Rhein",
  "Latitude": "47.600.207",
  "Longitude": "07.603.025",
  "Telephone": "0762116361980",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.600207,7.603025",
  "id": "47.600207,7.603025"
}, {
  "Brand": "ARAL",
  "Street": "Dresdner Straße 36",
  "PostCode": 1936,
  "City": "Königsbrück",
  "Latitude": "51.257.701",
  "Longitude": "13.891.425",
  "Telephone": "03579536372",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.257701,13.891425",
  "id": "51.257701,13.891425"
}, {
  "Brand": "ARAL",
  "Street": "Herner Straße 403",
  "PostCode": 44807,
  "City": "Bochum",
  "Latitude": "51.512.546",
  "Longitude": "07.210.151",
  "Telephone": "0234532070",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.512546,7.210151",
  "id": "51.512546,7.210151"
}, {
  "Brand": "ARAL",
  "Street": "A 60",
  "PostCode": 55262,
  "City": "Heidesheim",
  "Latitude": "50.002.391",
  "Longitude": "08.109.259",
  "Telephone": "0613256539",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.002391,8.109259",
  "id": "50.002391,8.109259"
}, {
  "Brand": "ARAL",
  "Street": "Mainzer Straße 116a",
  "PostCode": 64572,
  "City": "Büttelborn",
  "Latitude": "49.906.551",
  "Longitude": "08.503.033",
  "Telephone": "061529879262",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.906551,8.503033",
  "id": "49.906551,8.503033"
}, {
  "Brand": "ARAL",
  "Street": "Westring 19",
  "PostCode": 24850,
  "City": "Schuby",
  "Latitude": "54.518.852",
  "Longitude": "09.464.748",
  "Telephone": "04621949406",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.518852,9.464748",
  "id": "54.518852,9.464748"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 263",
  "PostCode": 89343,
  "City": "Jettingen-Scheppach",
  "Latitude": "48.409.452",
  "Longitude": "10.444.741",
  "Telephone": "08225959090",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.409452,10.444741",
  "id": "48.409452,10.444741"
}, {
  "Brand": "ARAL",
  "Street": "Kiewer Straße 31",
  "PostCode": 4205,
  "City": "Leipzig",
  "Latitude": "51.320.227",
  "Longitude": "12.276.573",
  "Telephone": "03414229713",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.320227,12.276573",
  "id": "51.320227,12.276573"
}, {
  "Brand": "ARAL",
  "Street": "Zeppelinstraße 2",
  "PostCode": 91187,
  "City": "Röttenbach",
  "Latitude": "49.157.415",
  "Longitude": "11.041.153",
  "Telephone": "091726859440",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.157415,11.041153",
  "id": "49.157415,11.041153"
}, {
  "Brand": "ARAL",
  "Street": "Ulmer Straße 50",
  "PostCode": 89269,
  "City": "Vöhringen",
  "Latitude": "48.283.359",
  "Longitude": "10.078.062",
  "Telephone": "073069533212",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.283359,10.078062",
  "id": "48.283359,10.078062"
}, {
  "Brand": "ARAL",
  "Street": "Fritzlarer Straße 39",
  "PostCode": 34281,
  "City": "Gudensberg",
  "Latitude": "51.176.050",
  "Longitude": "09.356.393",
  "Telephone": "056032302",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "18:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "51.17605,9.356393",
  "id": "51.17605,9.356393"
}, {
  "Brand": "ARAL",
  "Street": "Römerallee 78",
  "PostCode": 53909,
  "City": "Zülpich",
  "Latitude": "50.703.644",
  "Longitude": "06.666.699",
  "Telephone": "022528306140",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.703644,6.666699",
  "id": "50.703644,6.666699"
}, {
  "Brand": "ARAL",
  "Street": "Hedelfinger Straße 25 A",
  "PostCode": 70327,
  "City": "Stuttgart",
  "Latitude": "48.769.526",
  "Longitude": "09.248.199",
  "Telephone": "07114077791",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.769526,9.248199",
  "id": "48.769526,9.248199"
}, {
  "Brand": "ARAL",
  "Street": "Stuttgarter Straße 80",
  "PostCode": 73033,
  "City": "Göppingen",
  "Latitude": "48.708.973",
  "Longitude": "09.633.905",
  "Telephone": "07161922495",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.708973,9.633905",
  "id": "48.708973,9.633905"
}, {
  "Brand": "ARAL",
  "Street": "Würzburger Straße 1",
  "PostCode": 97230,
  "City": "Estenfeld",
  "Latitude": "49.828.686",
  "Longitude": "09.999.343",
  "Telephone": "09305218",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.828686,9.999343",
  "id": "49.828686,9.999343"
}, {
  "Brand": "ARAL",
  "Street": "Grifter Straße 2",
  "PostCode": 34302,
  "City": "Guxhagen",
  "Latitude": "51.203.050",
  "Longitude": "09.468.590",
  "Telephone": "05665800070",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.20305,9.46859",
  "id": "51.20305,9.46859"
}, {
  "Brand": "ARAL",
  "Street": "Ovelgönne 20",
  "PostCode": 49356,
  "City": "Diepholz",
  "Latitude": "52.618.299",
  "Longitude": "08.365.318",
  "Telephone": "05441929980",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.618299,8.365318",
  "id": "52.618299,8.365318"
}, {
  "Brand": "ARAL",
  "Street": "Landsberger Straße 423-425",
  "PostCode": 81241,
  "City": "München",
  "Latitude": "48.145.110",
  "Longitude": "11.478.615",
  "Telephone": "08982072936",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "48.14511,11.478615",
  "id": "48.14511,11.478615"
}, {
  "Brand": "ARAL",
  "Street": "Berghauptener Straße 21b",
  "PostCode": 77723,
  "City": "Gengenbach",
  "Latitude": "48.399.732",
  "Longitude": "08.007.818",
  "Telephone": "078037759",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.399732,8.007818",
  "id": "48.399732,8.007818"
}, {
  "Brand": "ARAL",
  "Street": "Zeilstraße 27",
  "PostCode": 72793,
  "City": "Pfullingen",
  "Latitude": "48.473.260",
  "Longitude": "09.233.511",
  "Telephone": "07121312580",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.47326,9.233511",
  "id": "48.47326,9.233511"
}, {
  "Brand": "ARAL",
  "Street": "an der B 413",
  "PostCode": 56269,
  "City": "Dierdorf",
  "Latitude": "50.549.335",
  "Longitude": "07.656.307",
  "Telephone": "026893558",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.549335,7.656307",
  "id": "50.549335,7.656307"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 14",
  "PostCode": 51588,
  "City": "Nümbrecht",
  "Latitude": "50.906.230",
  "Longitude": "07.542.581",
  "Telephone": "022937380",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.90623,7.542581",
  "id": "50.90623,7.542581"
}, {
  "Brand": "ARAL",
  "Street": "A 1",
  "PostCode": 27367,
  "City": "Sottrum",
  "Latitude": "53.090.109",
  "Longitude": "09.179.623",
  "Telephone": "04205491",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.090109,9.179623",
  "id": "53.090109,9.179623"
}, {
  "Brand": "ARAL",
  "Street": "Am Knückel 1",
  "PostCode": 63843,
  "City": "Niedernberg",
  "Latitude": "49.914.553",
  "Longitude": "09.121.742",
  "Telephone": "060283078950",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.914553,9.121742",
  "id": "49.914553,9.121742"
}, {
  "Brand": "ARAL",
  "Street": "Max-Pollin-Strasse 2",
  "PostCode": 85104,
  "City": "Pförring",
  "Latitude": "48.816.767",
  "Longitude": "11.693.572",
  "Telephone": "08403927825",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.816767,11.693572",
  "id": "48.816767,11.693572"
}, {
  "Brand": "ARAL",
  "Street": "Aurea 1-3",
  "PostCode": 33378,
  "City": "Rheda-Wiedenbrück",
  "Latitude": "51.831.232",
  "Longitude": "08.218.466",
  "Telephone": "025228343732",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.831232,8.218466",
  "id": "51.831232,8.218466"
}, {
  "Brand": "ARAL",
  "Street": "Stolberger Straße 64",
  "PostCode": 52249,
  "City": "Eschweiler",
  "Latitude": "50.799.015",
  "Longitude": "06.236.251",
  "Telephone": "02403504360",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.799015,6.236251",
  "id": "50.799015,6.236251"
}, {
  "Brand": "ARAL",
  "Street": "Landsberger Straße 2",
  "PostCode": 82110,
  "City": "Germering",
  "Latitude": "48.136.807",
  "Longitude": "11.390.912",
  "Telephone": "089847250",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.136807,11.390912",
  "id": "48.136807,11.390912"
}, {
  "Brand": "ARAL",
  "Street": "Overhagener Straße 78",
  "PostCode": 59557,
  "City": "Lippstadt",
  "Latitude": "51.661.399",
  "Longitude": "08.338.059",
  "Telephone": "0294110515",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.661399,8.338059",
  "id": "51.661399,8.338059"
}, {
  "Brand": "ARAL",
  "Street": "Gressenicher Straße 85",
  "PostCode": 52224,
  "City": "Stolberg",
  "Latitude": "50.761.343",
  "Longitude": "06.282.833",
  "Telephone": "02402764757",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.761343,6.282833",
  "id": "50.761343,6.282833"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 4",
  "PostCode": 89143,
  "City": "Blaubeuren",
  "Latitude": "48.400.189",
  "Longitude": "09.796.913",
  "Telephone": "073449528412",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.400189,9.796913",
  "id": "48.400189,9.796913"
}, {
  "Brand": "ARAL",
  "Street": "Konrad-Adenauer-Straße 25",
  "PostCode": 35745,
  "City": "Herborn",
  "Latitude": "50.675.334",
  "Longitude": "08.306.522",
  "Telephone": "02772957192",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.675334,8.306522",
  "id": "50.675334,8.306522"
}, {
  "Brand": "ARAL",
  "Street": "Schlossstraße 1",
  "PostCode": 57537,
  "City": "Wissen",
  "Latitude": "50.781.479",
  "Longitude": "07.742.202",
  "Telephone": "027422101",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.781479,7.742202",
  "id": "50.781479,7.742202"
}, {
  "Brand": "ARAL",
  "Street": "Graf-Heinrich-Straße 18",
  "PostCode": 57627,
  "City": "Hachenburg",
  "Latitude": "50.663.724",
  "Longitude": "07.818.036",
  "Telephone": "026624455",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "05:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.663724,7.818036",
  "id": "50.663724,7.818036"
}, {
  "Brand": "ARAL",
  "Street": "Triebstraße 11e",
  "PostCode": 80993,
  "City": "München",
  "Latitude": "48.187.634",
  "Longitude": "11.530.851",
  "Telephone": "0891415222",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.187634,11.530851",
  "id": "48.187634,11.530851"
}, {
  "Brand": "ARAL",
  "Street": "Gottlieb-Braun-Straße 33 / B 295",
  "PostCode": 75382,
  "City": "Althengstett",
  "Latitude": "48.726.751",
  "Longitude": "08.786.109",
  "Telephone": "07051922290",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.726751,8.786109",
  "id": "48.726751,8.786109"
}, {
  "Brand": "ENI",
  "Street": "Vilsecker Str. 2",
  "PostCode": 93057,
  "City": "Regensburg",
  "Latitude": "49.016.430",
  "Longitude": "12.101.760",
  "Telephone": "0941/46705847",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.01643,12.10176",
  "id": "49.01643,12.10176"
}, {
  "Brand": "ENI",
  "Street": "Umgehungsstrasse 65",
  "PostCode": 71144,
  "City": "Steinenbronn",
  "Latitude": "48.666.260",
  "Longitude": "09.124.090",
  "Telephone": "+49 (0) 71572750",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.66626,9.12409",
  "id": "48.66626,9.12409"
}, {
  "Brand": "OMV",
  "Street": "Hauptstr. 156",
  "PostCode": 77830,
  "City": "Bühlertal",
  "Latitude": "48.679.750",
  "Longitude": "08.195.300",
  "Telephone": "+49-7223-72592",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.67975,8.1953",
  "id": "48.67975,8.1953"
}, {
  "Brand": "ARAL",
  "Street": "August-Horch-Straße 1-3",
  "PostCode": 56076,
  "City": "Koblenz",
  "Latitude": "50.389.276",
  "Longitude": "07.566.403",
  "Telephone": "026198859626",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.389276,7.566403",
  "id": "50.389276,7.566403"
}, {
  "Brand": "Westfalen",
  "Street": "Weseler Str. 269-271",
  "PostCode": 48151,
  "City": "MUENSTER",
  "Latitude": "51.940.000",
  "Longitude": "07.608.930",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.94,7.60893",
  "id": "51.94,7.60893"
}, {
  "Brand": "Westfalen",
  "Street": "Badenstedter Str. 145",
  "PostCode": 30455,
  "City": "HANNOVER",
  "Latitude": "52.359.000",
  "Longitude": "09.677.770",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.359,9.67777",
  "id": "52.359,9.67777"
}, {
  "Brand": "Westfalen",
  "Street": "Wilhelm-Hess-Str. 25",
  "PostCode": 30890,
  "City": "BARSINGHAUSEN",
  "Latitude": "52.307.400",
  "Longitude": "09.452.260",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.3074,9.45226",
  "id": "52.3074,9.45226"
}, {
  "Brand": "ENI",
  "Street": "Siemensstrasse 1",
  "PostCode": 35460,
  "City": "Staufenberg",
  "Latitude": "50.655.002",
  "Longitude": "08.732.711",
  "Telephone": "+49 (0) 64037785",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.655002,8.732711",
  "id": "50.655002,8.732711"
}, {
  "Brand": "OMV",
  "Street": "Schönberger Straße 8",
  "PostCode": 94513,
  "City": "Schönberg-Eberhardsreuth",
  "Latitude": "48.816.450",
  "Longitude": "13.357.500",
  "Telephone": "+49-8554-8969010",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.81645,13.3575",
  "id": "48.81645,13.3575"
}, {
  "Brand": "ENI",
  "Street": "Friedrich-August-Str. 22",
  "PostCode": 55765,
  "City": "Birkenfeld",
  "Latitude": "49.649.070",
  "Longitude": "07.161.190",
  "Telephone": "+49 (0) 67825529",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.64907,7.16119",
  "id": "49.64907,7.16119"
}, {
  "Brand": "ARAL",
  "Street": "Schehnberger Weg 11",
  "PostCode": 26203,
  "City": "Wardenburg",
  "Latitude": "53.050.524",
  "Longitude": "08.211.301",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.050524,8.211301",
  "id": "53.050524,8.211301"
}, {
  "Brand": "ARAL",
  "Street": "Ulmer Straße 19",
  "PostCode": 89257,
  "City": "Illertissen",
  "Latitude": "48.225.060",
  "Longitude": "10.102.634",
  "Telephone": "073036092871",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.22506,10.102634",
  "id": "48.22506,10.102634"
}, {
  "Brand": "ENI",
  "Street": "Muenchner Strasse 54",
  "PostCode": 82131,
  "City": "Gauting",
  "Latitude": "48.068.959",
  "Longitude": "11.388.617",
  "Telephone": "+49 (0) 89850512",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.068959,11.388617",
  "id": "48.068959,11.388617"
}, {
  "Brand": "TOTAL",
  "Street": "PADERBORNER TOR 164",
  "PostCode": 34414,
  "City": "WARBURG",
  "Latitude": "51.492.236",
  "Longitude": "09.130.931",
  "Telephone": "05641-8530",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.492236,9.130931",
  "id": "51.492236,9.130931"
}, {
  "Brand": "Westfalen",
  "Street": "Alexanderstr. 420",
  "PostCode": 26127,
  "City": "OLDENBURG",
  "Latitude": "53.175.200",
  "Longitude": "08.191.380",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.1752,8.19138",
  "id": "53.1752,8.19138"
}, {
  "Brand": "OMV",
  "Street": "Marktstr. 32",
  "PostCode": 97355,
  "City": "Rüdenhausen",
  "Latitude": "49.768.110",
  "Longitude": "10.341.760",
  "Telephone": "+49-9383-1232",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.76811,10.34176",
  "id": "49.76811,10.34176"
}, {
  "Brand": "ENI",
  "Street": "Julius-Echter-Str. 26",
  "PostCode": 97450,
  "City": "Arnstein-Heugrumbach",
  "Latitude": "49.978.480",
  "Longitude": "09.953.740",
  "Telephone": "+49 (0) 93631212",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.97848,9.95374",
  "id": "49.97848,9.95374"
}, {
  "Brand": "OMV",
  "Street": "Esslinger Str. 136",
  "PostCode": 70734,
  "City": "Fellbach",
  "Latitude": "48.804.520",
  "Longitude": "09.268.960",
  "Telephone": "+49-711-589637",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.80452,9.26896",
  "id": "48.80452,9.26896"
}, {
  "Brand": "TOTAL",
  "Street": "RUPPERTSWIES 6",
  "PostCode": 85092,
  "City": "KOESCHING",
  "Latitude": "48.811.141",
  "Longitude": "11.479.088",
  "Telephone": "08456-9698530",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.811141,11.479088",
  "id": "48.811141,11.479088"
}, {
  "Brand": "ARAL",
  "Street": "Heinestr. 10-12",
  "PostCode": 48703,
  "City": "Stadtlohn",
  "Latitude": "51.992.801",
  "Longitude": "06.889.609",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.992801,6.889609",
  "id": "51.992801,6.889609"
}, {
  "Brand": "TOTAL",
  "Street": "PLAUER CHAUSSEE 26",
  "PostCode": 18292,
  "City": "KRAKOW AM SEE",
  "Latitude": "53.647.913",
  "Longitude": "12.265.485",
  "Telephone": "038457-24645",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.647913,12.265485",
  "id": "53.647913,12.265485"
}, {
  "Brand": "TOTAL",
  "Street": "JOSEF-NEUMEIER-STR. 1",
  "PostCode": 85664,
  "City": "HOHENLINDEN",
  "Latitude": "48.155.687",
  "Longitude": "11.986.555",
  "Telephone": "08124/444842",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.155687,11.986555",
  "id": "48.155687,11.986555"
}, {
  "Brand": "ARAL",
  "Street": "Rostockerstr.",
  "PostCode": 14641,
  "City": "Wustermark",
  "Latitude": "52.560.112",
  "Longitude": "12.970.850",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.560112,12.97085",
  "id": "52.560112,12.97085"
}, {
  "Brand": "ARAL",
  "Street": "Haunstetter Straße 75",
  "PostCode": 86161,
  "City": "Augsburg",
  "Latitude": "48.346.277",
  "Longitude": "10.905.344",
  "Telephone": "082132856518",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.346277,10.905344",
  "id": "48.346277,10.905344"
}, {
  "Brand": "ARAL",
  "Street": "Hindenburgstraße 41",
  "PostCode": 89129,
  "City": "Langenau",
  "Latitude": "48.495.647",
  "Longitude": "10.117.445",
  "Telephone": "073457150",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.495647,10.117445",
  "id": "48.495647,10.117445"
}, {
  "Brand": "ARAL",
  "Street": "A 2",
  "PostCode": 33719,
  "City": "Bielefeld",
  "Latitude": "52.009.959",
  "Longitude": "08.629.997",
  "Telephone": "052089592215",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.009959,8.629997",
  "id": "52.009959,8.629997"
}, {
  "Brand": "ENI",
  "Street": "Oberwinkl 4",
  "PostCode": 83355,
  "City": "Grabenstaett",
  "Latitude": "47.829.130",
  "Longitude": "12.526.360",
  "Telephone": "08661/982510",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "47.82913,12.52636",
  "id": "47.82913,12.52636"
}, {
  "Brand": "Westfalen",
  "Street": "Coesfelder Str. 182",
  "PostCode": 48249,
  "City": "DUELMEN",
  "Latitude": "51.839.600",
  "Longitude": "07.265.970",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8396,7.26597",
  "id": "51.8396,7.26597"
}, {
  "Brand": "ENI",
  "Street": "Berliner Strasse 52/1",
  "PostCode": 71229,
  "City": "Leonberg",
  "Latitude": "48.794.700",
  "Longitude": "09.017.100",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.7947,9.0171",
  "id": "48.7947,9.0171"
}, {
  "Brand": "ARAL",
  "Street": "Schulenburger Landstraße 128",
  "PostCode": 30165,
  "City": "Hannover",
  "Latitude": "52.406.550",
  "Longitude": "09.711.180",
  "Telephone": "051167662626",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.40655,9.71118",
  "id": "52.40655,9.71118"
}, {
  "Brand": "Westfalen",
  "Street": "Hauptstr. 63",
  "PostCode": 31559,
  "City": "HASTE",
  "Latitude": "52.383.900",
  "Longitude": "09.395.740",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.3839,9.39574",
  "id": "52.3839,9.39574"
}, {
  "Brand": "TOTAL",
  "Street": "HORSTHAUSER STR.213",
  "PostCode": 44628,
  "City": "HERNE",
  "Latitude": "51.560.818",
  "Longitude": "07.231.214",
  "Telephone": "02323-8603",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.560818,7.231214",
  "id": "51.560818,7.231214"
}, {
  "Brand": "OMV",
  "Street": "Erlanger Str. 56",
  "PostCode": 91074,
  "City": "Herzogenaurach",
  "Latitude": "49.566.480",
  "Longitude": "10.893.520",
  "Telephone": "+49-9132-9343",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.56648,10.89352",
  "id": "49.56648,10.89352"
}, {
  "Brand": "ENI",
  "Street": "Stuttgarter Strasse 92",
  "PostCode": 75365,
  "City": "Calw",
  "Latitude": "48.711.182",
  "Longitude": "08.756.319",
  "Telephone": "+49 (0) 70513027",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.711182,8.756319",
  "id": "48.711182,8.756319"
}, {
  "Brand": "Westfalen",
  "Street": "Darfelder Str. 14",
  "PostCode": 48727,
  "City": "BILLERBECK",
  "Latitude": "51.982.500",
  "Longitude": "07.293.680",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9825,7.29368",
  "id": "51.9825,7.29368"
}, {
  "Brand": "ARAL",
  "Street": "Broichhofstrasse 8 (A52)",
  "PostCode": 40880,
  "City": "Ratingen",
  "Latitude": "51.290.548",
  "Longitude": "06.804.400",
  "Telephone": "0",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.290548,6.8044",
  "id": "51.290548,6.8044"
}, {
  "Brand": "TOTAL",
  "Street": "KORNWEG 1 / L1140",
  "PostCode": 71409,
  "City": "SCHWAIKHEIM",
  "Latitude": "48.874.866",
  "Longitude": "09.343.257",
  "Telephone": "07195-135491",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.874866,9.343257",
  "id": "48.874866,9.343257"
}, {
  "Brand": "ARAL",
  "Street": "Berchtesgardener Straße 2",
  "PostCode": 83435,
  "City": "Bad Reichenhall",
  "Latitude": "47.717.601",
  "Longitude": "12.875.201",
  "Telephone": "0865167220",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.717601,12.875201",
  "id": "47.717601,12.875201"
}, {
  "Brand": "ARAL",
  "Street": "Geisental",
  "PostCode": 44791,
  "City": "Bochum",
  "Latitude": "51.497.712",
  "Longitude": "07.260.680",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.497712,7.26068",
  "id": "51.497712,7.26068"
}, {
  "Brand": "TOTAL",
  "Street": "AUTOHOF ELKERSBERG 2",
  "PostCode": 57234,
  "City": "WILNSDORF",
  "Latitude": "50.814.730",
  "Longitude": "08.096.650",
  "Telephone": "02739-40339-0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.81473,8.09665",
  "id": "50.81473,8.09665"
}, {
  "Brand": "ARAL",
  "Street": "Giessener Straße 10",
  "PostCode": 35325,
  "City": "Mücke",
  "Latitude": "50.613.042",
  "Longitude": "09.027.333",
  "Telephone": "06400951223",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "05:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.613042,9.027333",
  "id": "50.613042,9.027333"
}, {
  "Brand": "TOTAL",
  "Street": "MARXZELLER STR. 90",
  "PostCode": 75305,
  "City": "NEUENBUERG",
  "Latitude": "48.852.942",
  "Longitude": "08.582.141",
  "Telephone": "07082-413024",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.852942,8.582141",
  "id": "48.852942,8.582141"
}, {
  "Brand": "TOTAL",
  "Street": "KLINKENBERG 28",
  "PostCode": 17126,
  "City": "JARMEN",
  "Latitude": "53.921.807",
  "Longitude": "13.347.471",
  "Telephone": "039997-10404",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.921807,13.347471",
  "id": "53.921807,13.347471"
}, {
  "Brand": "TOTAL",
  "Street": "DUESSELDORFER STR.51",
  "PostCode": 47239,
  "City": "DUISBURG",
  "Latitude": "51.396.448",
  "Longitude": "06.657.844",
  "Telephone": "02151-406010",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.396448,6.657844",
  "id": "51.396448,6.657844"
}, {
  "Brand": "Westfalen",
  "Street": "Lingener Damm 26",
  "PostCode": 48429,
  "City": "RHEINE",
  "Latitude": "52.288.200",
  "Longitude": "07.440.720",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.2882,7.44072",
  "id": "52.2882,7.44072"
}, {
  "Brand": "TOTAL",
  "Street": "KAMENZER STR. 27",
  "PostCode": 1561,
  "City": "THIENDORF",
  "Latitude": "51.294.494",
  "Longitude": "13.736.175",
  "Telephone": "035248-81325",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.294494,13.736175",
  "id": "51.294494,13.736175"
}, {
  "Brand": "TOTAL",
  "Street": "KOELNER STR. 17 A",
  "PostCode": 53489,
  "City": "SINZIG",
  "Latitude": "50.548.412",
  "Longitude": "07.245.048",
  "Telephone": "02642-409200",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.548412,7.245048",
  "id": "50.548412,7.245048"
}, {
  "Brand": "TOTAL",
  "Street": "FRIEDENSSTR. 88",
  "PostCode": 23942,
  "City": "DASSOW",
  "Latitude": "53.913.089",
  "Longitude": "10.977.469",
  "Telephone": "038826-80219",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.913089,10.977469",
  "id": "53.913089,10.977469"
}, {
  "Brand": "TOTAL",
  "Street": "WERNSTEDTER STR 2",
  "PostCode": 39624,
  "City": "KALBE",
  "Latitude": "52.657.330",
  "Longitude": "11.383.276",
  "Telephone": "039080-40538",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.65733,11.383276",
  "id": "52.65733,11.383276"
}, {
  "Brand": "ARAL",
  "Street": "Pferdebahnstr. 40",
  "PostCode": 45141,
  "City": "Essen",
  "Latitude": "51.465.785",
  "Longitude": "06.991.910",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.465785,6.99191",
  "id": "51.465785,6.99191"
}, {
  "Brand": "TOTAL",
  "Street": "SULZBACHTALSTR. 19",
  "PostCode": 66125,
  "City": "SAARBRUECKEN",
  "Latitude": "49.267.114",
  "Longitude": "07.011.219",
  "Telephone": "0681-3908027",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.267114,7.011219",
  "id": "49.267114,7.011219"
}, {
  "Brand": "TOTAL",
  "Street": "FRIEDHOFSTR. 7",
  "PostCode": 77694,
  "City": "KEHL",
  "Latitude": "48.570.851",
  "Longitude": "07.820.421",
  "Telephone": "07851-640",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.570851,7.820421",
  "id": "48.570851,7.820421"
}, {
  "Brand": "ARAL",
  "Street": "Am Sülzenbrückener Weg",
  "PostCode": 99334,
  "City": "Ichtershausen",
  "Latitude": "50.885.609",
  "Longitude": "10.931.647",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.885609,10.931647",
  "id": "50.885609,10.931647"
}, {
  "Brand": "ARAL",
  "Street": "Edisonstraße 14 a",
  "PostCode": 86199,
  "City": "Augsburg",
  "Latitude": "48.344.737",
  "Longitude": "10.885.279",
  "Telephone": "08215998707",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.344737,10.885279",
  "id": "48.344737,10.885279"
}, {
  "Brand": "ARAL",
  "Street": "Seefelder Straße 28",
  "PostCode": 82211,
  "City": "Herrsching",
  "Latitude": "48.002.339",
  "Longitude": "11.181.872",
  "Telephone": "081528861",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:30",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.002339,11.181872",
  "id": "48.002339,11.181872"
}, {
  "Brand": "ARAL",
  "Street": "Im Taubental 50",
  "PostCode": 41468,
  "City": "Neuss",
  "Latitude": "51.169.706",
  "Longitude": "06.747.162",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.169706,6.747162",
  "id": "51.169706,6.747162"
}, {
  "Brand": "TOTAL",
  "Street": "FREIENWALDER STR. 26",
  "PostCode": 16269,
  "City": "WRIEZEN",
  "Latitude": "52.728.600",
  "Longitude": "14.117.792",
  "Telephone": "033456-3113",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.7286,14.117792",
  "id": "52.7286,14.117792"
}, {
  "Brand": "TOTAL",
  "Street": "RIBNITZER STR. 42",
  "PostCode": 13051,
  "City": "BERLIN",
  "Latitude": "52.565.599",
  "Longitude": "13.491.795",
  "Telephone": "030-9294032",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.565599,13.491795",
  "id": "52.565599,13.491795"
}, {
  "Brand": "Westfalen",
  "Street": "Roemerstr. 17",
  "PostCode": 52382,
  "City": "NIEDERZIER",
  "Latitude": "50.866.000",
  "Longitude": "06.434.560",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.866,6.43456",
  "id": "50.866,6.43456"
}, {
  "Brand": "Westfalen",
  "Street": "Wandsbecker Zollstrasse 117-123",
  "PostCode": 22041,
  "City": "HAMBURG",
  "Latitude": "53.577.100",
  "Longitude": "10.083.800",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.5771,10.0838",
  "id": "53.5771,10.0838"
}, {
  "Brand": "TOTAL",
  "Street": "PUSCHKINALLEE 3 - 5",
  "PostCode": 99867,
  "City": "GOTHA",
  "Latitude": "50.942.403",
  "Longitude": "10.700.660",
  "Telephone": "03621-853772",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.942403,10.70066",
  "id": "50.942403,10.70066"
}, {
  "Brand": "ENI",
  "Street": "An der B 4",
  "PostCode": 98673,
  "City": "Eisfeld (Guest)",
  "Latitude": "50.394.442",
  "Longitude": "10.921.502",
  "Telephone": "+49 (0) 36863092",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.394442,10.921502",
  "id": "50.394442,10.921502"
}, {
  "Brand": "OMV",
  "Street": "Ruselstr. 9",
  "PostCode": 94209,
  "City": "Regen",
  "Latitude": "48.968.030",
  "Longitude": "13.127.500",
  "Telephone": "+49-9921-904015",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.96803,13.1275",
  "id": "48.96803,13.1275"
}, {
  "Brand": "ARAL",
  "Street": "Freiherr-von-Varnbüler Str. 1",
  "PostCode": 71282,
  "City": "Hemmingen",
  "Latitude": "48.864.815",
  "Longitude": "09.039.389",
  "Telephone": "071509185010",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.864815,9.039389",
  "id": "48.864815,9.039389"
}, {
  "Brand": "ARAL",
  "Street": "Dürener Straße 407",
  "PostCode": 50858,
  "City": "Köln",
  "Latitude": "50.923.318",
  "Longitude": "06.869.527",
  "Telephone": "022117092660",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.923318,6.869527",
  "id": "50.923318,6.869527"
}, {
  "Brand": "OMV",
  "Street": "Mühlfelderstr. 44",
  "PostCode": 82211,
  "City": "Herrsching",
  "Latitude": "47.993.790",
  "Longitude": "11.172.990",
  "Telephone": "+49-8152-399177",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.99379,11.17299",
  "id": "47.99379,11.17299"
}, {
  "Brand": "ENI",
  "Street": "Brandenburger Strasse 1",
  "PostCode": 67663,
  "City": "Kaiserslautern",
  "Latitude": "49.433.502",
  "Longitude": "07.750.224",
  "Telephone": "+49 (0) 63130395",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.433502,7.750224",
  "id": "49.433502,7.750224"
}, {
  "Brand": "TOTAL",
  "Street": "SEGEBERGER STR. 55 A",
  "PostCode": 23617,
  "City": "STOCKELSDORF",
  "Latitude": "53.887.794",
  "Longitude": "10.644.622",
  "Telephone": "0451-495676",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.887794,10.644622",
  "id": "53.887794,10.644622"
}, {
  "Brand": "ARAL",
  "Street": "Forchheimer Str. 15",
  "PostCode": 79359,
  "City": "Riegel am Kaiserstuh",
  "Latitude": "48.156.771",
  "Longitude": "07.744.626",
  "Telephone": "076429078674",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.156771,7.744626",
  "id": "48.156771,7.744626"
}, {
  "Brand": "TOTAL",
  "Street": "BUNDESSTR. 80",
  "PostCode": 6179,
  "City": "LANGENBOGEN",
  "Latitude": "51.479.283",
  "Longitude": "11.784.206",
  "Telephone": "034601-23164",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.479283,11.784206",
  "id": "51.479283,11.784206"
}, {
  "Brand": "TOTAL",
  "Street": "HAUSACHER STR. 13",
  "PostCode": 77793,
  "City": "GUTACH",
  "Latitude": "48.283.339",
  "Longitude": "08.193.928",
  "Telephone": "07831-969488",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.283339,8.193928",
  "id": "48.283339,8.193928"
}, {
  "Brand": "ARAL",
  "Street": "Essener Straße 225",
  "PostCode": 45472,
  "City": "Mülheim",
  "Latitude": "51.430.686",
  "Longitude": "06.923.581",
  "Telephone": "0208431410",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.430686,6.923581",
  "id": "51.430686,6.923581"
}, {
  "Brand": "TOTAL",
  "Street": "MUENCHNER STR. 16",
  "PostCode": 82319,
  "City": "STARNBERG",
  "Latitude": "48.001.390",
  "Longitude": "11.349.735",
  "Telephone": "08151-16785",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.00139,11.349735",
  "id": "48.00139,11.349735"
}, {
  "Brand": "TOTAL",
  "Street": "ZEPERNICKER STR. 9-10",
  "PostCode": 13125,
  "City": "BERLIN",
  "Latitude": "52.640.982",
  "Longitude": "13.514.269",
  "Telephone": "030-9497581",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.640982,13.514269",
  "id": "52.640982,13.514269"
}, {
  "Brand": "TOTAL",
  "Street": "LIMBACHER STR. 218-220",
  "PostCode": 9116,
  "City": "CHEMNITZ",
  "Latitude": "50.832.897",
  "Longitude": "12.873.383",
  "Telephone": "0371-364046",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.832897,12.873383",
  "id": "50.832897,12.873383"
}, {
  "Brand": "ARAL",
  "Street": "Jahnstraße 88",
  "PostCode": 73037,
  "City": "Göppingen",
  "Latitude": "48.696.904",
  "Longitude": "09.659.792",
  "Telephone": "0716173548",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.696904,9.659792",
  "id": "48.696904,9.659792"
}, {
  "Brand": "TOTAL",
  "Street": "MARKENDORFER STR. 15",
  "PostCode": 15234,
  "City": "FRANKFURT",
  "Latitude": "52.340.958",
  "Longitude": "14.529.148",
  "Telephone": "0335-4000187",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.340958,14.529148",
  "id": "52.340958,14.529148"
}, {
  "Brand": "Westfalen",
  "Street": "Resser Weg 20",
  "PostCode": 45699,
  "City": "HERTEN",
  "Latitude": "51.594.700",
  "Longitude": "07.129.810",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.5947,7.12981",
  "id": "51.5947,7.12981"
}, {
  "Brand": "TOTAL",
  "Street": "AN DER B 49",
  "PostCode": 35447,
  "City": "REISKIRCHEN",
  "Latitude": "50.596.135",
  "Longitude": "08.817.457",
  "Telephone": "06408549756",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.596135,8.817457",
  "id": "50.596135,8.817457"
}, {
  "Brand": "Westfalen",
  "Street": "Stadtholzstr. 250",
  "PostCode": 32052,
  "City": "BAT HERFORD-NORD",
  "Latitude": "52.103.400",
  "Longitude": "08.720.860",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.1034,8.72086",
  "id": "52.1034,8.72086"
}, {
  "Brand": "ARAL",
  "Street": "Nordstraße 2",
  "PostCode": 57578,
  "City": "Elkenroth",
  "Latitude": "50.735.690",
  "Longitude": "07.877.913",
  "Telephone": "027479129748",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.73569,7.877913",
  "id": "50.73569,7.877913"
}, {
  "Brand": "ARAL",
  "Street": "A 44",
  "PostCode": 59457,
  "City": "Werl",
  "Latitude": "51.532.383",
  "Longitude": "07.861.264",
  "Telephone": "029225399",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.532383,7.861264",
  "id": "51.532383,7.861264"
}, {
  "Brand": "TOTAL",
  "Street": "ALTRUPPINER ALLEE 25",
  "PostCode": 16816,
  "City": "NEURUPPIN",
  "Latitude": "52.939.014",
  "Longitude": "12.818.679",
  "Telephone": "03391-3291",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.939014,12.818679",
  "id": "52.939014,12.818679"
}, {
  "Brand": "TOTAL",
  "Street": "OSTERFELDSTR. 60",
  "PostCode": 58300,
  "City": "WETTER",
  "Latitude": "51.396.756",
  "Longitude": "07.351.860",
  "Telephone": "02335-70130",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.396756,7.35186",
  "id": "51.396756,7.35186"
}, {
  "Brand": "ARAL",
  "Street": "Am Heidfuchsberg 1",
  "PostCode": 39443,
  "City": "Staßfurt / OT Brumby",
  "Latitude": "51.896.468",
  "Longitude": "11.691.975",
  "Telephone": "039291463920",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.896468,11.691975",
  "id": "51.896468,11.691975"
}, {
  "Brand": "TOTAL",
  "Street": "WORMSER STR. 2",
  "PostCode": 68519,
  "City": "VIERNHEIM",
  "Latitude": "49.543.460",
  "Longitude": "08.574.265",
  "Telephone": "06204-76161",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.54346,8.574265",
  "id": "49.54346,8.574265"
}, {
  "Brand": "ARAL",
  "Street": "Rotweg 28 - 32",
  "PostCode": 70437,
  "City": "Stuttgart",
  "Latitude": "48.833.731",
  "Longitude": "09.182.566",
  "Telephone": "0711871958",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "48.833731,9.182566",
  "id": "48.833731,9.182566"
}, {
  "Brand": "OMV",
  "Street": "Neumarkter Str. 23",
  "PostCode": 84546,
  "City": "Egglkofen",
  "Latitude": "48.391.730",
  "Longitude": "12.452.940",
  "Telephone": "+49-8639-708676",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.39173,12.45294",
  "id": "48.39173,12.45294"
}, {
  "Brand": "TOTAL",
  "Street": "SANDBERG 30",
  "PostCode": 4178,
  "City": "LEIPZIG",
  "Latitude": "51.346.097",
  "Longitude": "12.260.850",
  "Telephone": "0341-9452118",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.346097,12.26085",
  "id": "51.346097,12.26085"
}, {
  "Brand": "ARAL",
  "Street": "Landsberger Straße 20",
  "PostCode": 86807,
  "City": "Buchloe",
  "Latitude": "48.035.940",
  "Longitude": "10.732.483",
  "Telephone": "08241911291",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.03594,10.732483",
  "id": "48.03594,10.732483"
}, {
  "Brand": "Westfalen",
  "Street": "Haller Str. 69",
  "PostCode": 33803,
  "City": "STEINHAGEN",
  "Latitude": "52.028.500",
  "Longitude": "08.406.640",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0285,8.40664",
  "id": "52.0285,8.40664"
}, {
  "Brand": "OMV",
  "Street": "Zugspitzstrasse 42",
  "PostCode": 82467,
  "City": "Garmisch-Partenkirchen",
  "Latitude": "47.490.580",
  "Longitude": "11.076.740",
  "Telephone": "+49-8821-51662",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.49058,11.07674",
  "id": "47.49058,11.07674"
}, {
  "Brand": "ENI",
  "Street": "Neuburger Str. 104",
  "PostCode": 94036,
  "City": "Passau",
  "Latitude": "48.563.783",
  "Longitude": "13.429.236",
  "Telephone": "+49 (0) 85158991",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.563783,13.429236",
  "id": "48.563783,13.429236"
}, {
  "Brand": "TOTAL",
  "Street": "WESTFALENDAMM 272",
  "PostCode": 44141,
  "City": "DORTMUND",
  "Latitude": "51.504.121",
  "Longitude": "07.510.754",
  "Telephone": "0231-596931",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.504121,7.510754",
  "id": "51.504121,7.510754"
}, {
  "Brand": "ENI",
  "Street": "Rathausstr. 1",
  "PostCode": 83734,
  "City": "Hausham",
  "Latitude": "47.759.697",
  "Longitude": "11.828.361",
  "Telephone": "+49 (0) 80269226",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.759697,11.828361",
  "id": "47.759697,11.828361"
}, {
  "Brand": "ENI",
  "Street": "Freiburger Strasse 9",
  "PostCode": 77933,
  "City": "Lahr",
  "Latitude": "48.341.510",
  "Longitude": "07.841.499",
  "Telephone": "+49 (0) 78219818",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.34151,7.841499",
  "id": "48.34151,7.841499"
}, {
  "Brand": "ARAL",
  "Street": "Johann-G.-Gutenbergstraße 1",
  "PostCode": 82140,
  "City": "Olching",
  "Latitude": "48.199.510",
  "Longitude": "11.335.941",
  "Telephone": "081423060",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.19951,11.335941",
  "id": "48.19951,11.335941"
}, {
  "Brand": "TOTAL",
  "Street": "OLOF-PALME-ALLEE 5",
  "PostCode": 25541,
  "City": "BRUNSBUETTEL",
  "Latitude": "53.902.145",
  "Longitude": "09.122.195",
  "Telephone": "04852-9405010",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.902145,9.122195",
  "id": "53.902145,9.122195"
}, {
  "Brand": "OMV",
  "Street": "Jesinger Str. 50",
  "PostCode": 73230,
  "City": "Kirchheim",
  "Latitude": "48.645.290",
  "Longitude": "09.459.300",
  "Telephone": "+49-7021-2848",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.64529,9.4593",
  "id": "48.64529,9.4593"
}, {
  "Brand": "Westfalen",
  "Street": "Siemensallee 77",
  "PostCode": 76187,
  "City": "KARLSRUHE",
  "Latitude": "49.029.300",
  "Longitude": "08.347.000",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.0293,8.347",
  "id": "49.0293,8.347"
}, {
  "Brand": "TOTAL",
  "Street": "VOGELSTANGE 17",
  "PostCode": 4741,
  "City": "ROSSWEIN",
  "Latitude": "51.072.199",
  "Longitude": "13.184.588",
  "Telephone": "034322-43775",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.072199,13.184588",
  "id": "51.072199,13.184588"
}, {
  "Brand": "TOTAL",
  "Street": "OSTERWALDER STR. 63",
  "PostCode": 30827,
  "City": "GARBSEN",
  "Latitude": "52.443.670",
  "Longitude": "09.608.598",
  "Telephone": "05131-91637",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.44367,9.608598",
  "id": "52.44367,9.608598"
}, {
  "Brand": "TOTAL",
  "Street": "PENIGER STR. 1B",
  "PostCode": 4643,
  "City": "GEITHAIN",
  "Latitude": "51.051.394",
  "Longitude": "12.706.932",
  "Telephone": "034341-43567",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.051394,12.706932",
  "id": "51.051394,12.706932"
}, {
  "Brand": "TOTAL",
  "Street": "ERLACHSEEWEG 10",
  "PostCode": 76227,
  "City": "KARLSRUHE",
  "Latitude": "48.985.913",
  "Longitude": "08.447.617",
  "Telephone": "0721-56867276",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.985913,8.447617",
  "id": "48.985913,8.447617"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER STR. 135",
  "PostCode": 3046,
  "City": "COTTBUS",
  "Latitude": "51.761.389",
  "Longitude": "14.324.722",
  "Telephone": "0355-4946930",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.761389,14.324722",
  "id": "51.761389,14.324722"
}, {
  "Brand": "ARAL",
  "Street": "Chausseestraße 71 a",
  "PostCode": 17373,
  "City": "Ueckermünde",
  "Latitude": "53.732.570",
  "Longitude": "14.029.977",
  "Telephone": "03977127044",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.73257,14.029977",
  "id": "53.73257,14.029977"
}, {
  "Brand": "ARAL",
  "Street": "Haydnstraße 3",
  "PostCode": 89250,
  "City": "Senden",
  "Latitude": "48.328.298",
  "Longitude": "10.042.008",
  "Telephone": "07307939282",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.328298,10.042008",
  "id": "48.328298,10.042008"
}, {
  "Brand": "TOTAL",
  "Street": "KESSENICHER STR. 212",
  "PostCode": 53129,
  "City": "BONN-DOTTENDORF",
  "Latitude": "50.702.771",
  "Longitude": "07.116.055",
  "Telephone": "0228-231116",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.702771,7.116055",
  "id": "50.702771,7.116055"
}, {
  "Brand": "TOTAL",
  "Street": "EUREN STRASSE 92",
  "PostCode": 54294,
  "City": "TRIER EUREN",
  "Latitude": "49.745.058",
  "Longitude": "06.614.294",
  "Telephone": "06519982926",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.745058,6.614294",
  "id": "49.745058,6.614294"
}, {
  "Brand": "TOTAL",
  "Street": "ASCHERSLEBENER STR. 60",
  "PostCode": 6333,
  "City": "HETTSTEDT",
  "Latitude": "51.657.124",
  "Longitude": "11.495.127",
  "Telephone": "03476-851065",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.657124,11.495127",
  "id": "51.657124,11.495127"
}, {
  "Brand": "Westfalen",
  "Street": "Olpener Str. 27",
  "PostCode": 51103,
  "City": "KOELN",
  "Latitude": "50.940.300",
  "Longitude": "07.018.870",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.9403,7.01887",
  "id": "50.9403,7.01887"
}, {
  "Brand": "TOTAL",
  "Street": "VOSSWALDESTR. 1",
  "PostCode": 63457,
  "City": "HANAU",
  "Latitude": "50.108.268",
  "Longitude": "08.968.889",
  "Telephone": "06181-5206287",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.108268,8.968889",
  "id": "50.108268,8.968889"
}, {
  "Brand": "TOTAL",
  "Street": "KOHLENSTR. 51",
  "PostCode": 54296,
  "City": "TRIER-TARFORST",
  "Latitude": "49.745.128",
  "Longitude": "06.691.606",
  "Telephone": "0651-9990133",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.745128,6.691606",
  "id": "49.745128,6.691606"
}, {
  "Brand": "TOTAL",
  "Street": "BAB WEST/A 11",
  "PostCode": 16244,
  "City": "SCHORFHEIDE-BUCKOWSEE",
  "Latitude": "52.883.030",
  "Longitude": "13.701.890",
  "Telephone": "033363-46210",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.88303,13.70189",
  "id": "52.88303,13.70189"
}, {
  "Brand": "ARAL",
  "Street": "Lindenstraße 60",
  "PostCode": 99718,
  "City": "Greußen",
  "Latitude": "51.222.967",
  "Longitude": "10.947.146",
  "Telephone": "03636701255",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.222967,10.947146",
  "id": "51.222967,10.947146"
}, {
  "Brand": "ENI",
  "Street": "Bruchkoebeler Landstrasse 53",
  "PostCode": 63452,
  "City": "Hanau",
  "Latitude": "50.146.175",
  "Longitude": "08.909.290",
  "Telephone": "+49 (0) 61818408",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.146175,8.90929",
  "id": "50.146175,8.90929"
}, {
  "Brand": "TOTAL",
  "Street": "FRANKFURTER STR.14",
  "PostCode": 4916,
  "City": "HERZBERG",
  "Latitude": "51.697.164",
  "Longitude": "13.251.557",
  "Telephone": "03535-247650",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.697164,13.251557",
  "id": "51.697164,13.251557"
}, {
  "Brand": "TOTAL",
  "Street": "HUENFELDER STR. 4",
  "PostCode": 36282,
  "City": "HAUNECK",
  "Latitude": "50.824.224",
  "Longitude": "09.732.466",
  "Telephone": "06621-75264",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.824224,9.732466",
  "id": "50.824224,9.732466"
}, {
  "Brand": "TOTAL",
  "Street": "KARLSRUHER STR. 16",
  "PostCode": 76437,
  "City": "RASTATT",
  "Latitude": "48.863.835",
  "Longitude": "08.217.852",
  "Telephone": "07222-150160",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.863835,8.217852",
  "id": "48.863835,8.217852"
}, {
  "Brand": "TOTAL",
  "Street": "BAB-BROHLTAL-OST/A 61",
  "PostCode": 56651,
  "City": "NIEDERZISSEN",
  "Latitude": "50.439.823",
  "Longitude": "07.226.107",
  "Telephone": "02636-9419460",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.439823,7.226107",
  "id": "50.439823,7.226107"
}, {
  "Brand": "TOTAL",
  "Street": "EGERLAENDER WEG 2",
  "PostCode": 74523,
  "City": "SCHWAEBISCH HALL",
  "Latitude": "49.106.472",
  "Longitude": "09.718.823",
  "Telephone": "0791-52138",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.106472,9.718823",
  "id": "49.106472,9.718823"
}, {
  "Brand": "TOTAL",
  "Street": "BILLSTEDTER HAUPTSTRASSE 21",
  "PostCode": 22111,
  "City": "HAMBURG",
  "Latitude": "53.541.648",
  "Longitude": "10.098.817",
  "Telephone": "040-7314835",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.541648,10.098817",
  "id": "53.541648,10.098817"
}, {
  "Brand": "TOTAL",
  "Street": "BOBLITZER CHAUSSEESTR. 2",
  "PostCode": 3222,
  "City": "LUEBBENAU/OT BOBLITZ",
  "Latitude": "51.846.694",
  "Longitude": "13.972.428",
  "Telephone": "03542-44138",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.846694,13.972428",
  "id": "51.846694,13.972428"
}, {
  "Brand": "TOTAL",
  "Street": "ZEILSHEIMER STR. 37-39",
  "PostCode": 65719,
  "City": "HOFHEIM",
  "Latitude": "50.088.542",
  "Longitude": "08.454.397",
  "Telephone": "06192-6776",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.088542,8.454397",
  "id": "50.088542,8.454397"
}, {
  "Brand": "ENI",
  "Street": "An Der A 9",
  "PostCode": 7927,
  "City": "Hirschberg",
  "Latitude": "50.430.219",
  "Longitude": "11.798.814",
  "Telephone": "+49 (0) 36644260",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.430219,11.798814",
  "id": "50.430219,11.798814"
}, {
  "Brand": "TOTAL",
  "Street": "REICHSSTR. 86",
  "PostCode": 14052,
  "City": "BERLIN",
  "Latitude": "52.514.988",
  "Longitude": "13.262.316",
  "Telephone": "030-3046042",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.514988,13.262316",
  "id": "52.514988,13.262316"
}, {
  "Brand": "TOTAL",
  "Street": "HOHER RAIN 2",
  "PostCode": 73770,
  "City": "DENKENDORF",
  "Latitude": "48.692.900",
  "Longitude": "09.304.930",
  "Telephone": "071193445340",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.6929,9.30493",
  "id": "48.6929,9.30493"
}, {
  "Brand": "Westfalen",
  "Street": "Heisenbergstr. 2a",
  "PostCode": 50169,
  "City": "KERPEN",
  "Latitude": "50.869.900",
  "Longitude": "06.757.380",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.8699,6.75738",
  "id": "50.8699,6.75738"
}, {
  "Brand": "ENI",
  "Street": "Kemptener Strasse 61",
  "PostCode": 87600,
  "City": "Kaufbeuren",
  "Latitude": "47.878.275",
  "Longitude": "10.611.693",
  "Telephone": "+49 (0) 83418281",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.878275,10.611693",
  "id": "47.878275,10.611693"
}, {
  "Brand": "TOTAL",
  "Street": "ALTE LANDSTR.23-29",
  "PostCode": 77972,
  "City": "MAHLBERG-ORSCHWEIER",
  "Latitude": "48.274.934",
  "Longitude": "07.786.951",
  "Telephone": "07822-4349649",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.274934,7.786951",
  "id": "48.274934,7.786951"
}, {
  "Brand": "Westfalen",
  "Street": "Lipprahmsdorfer 6",
  "PostCode": 46282,
  "City": "DORSTEN",
  "Latitude": "51.760.400",
  "Longitude": "07.000.150",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.7604,7.00015",
  "id": "51.7604,7.00015"
}, {
  "Brand": "OMV",
  "Street": "Oberpfälzer Alb Süd A 6",
  "PostCode": 92278,
  "City": "Illschwang",
  "Latitude": "49.423.630",
  "Longitude": "11.686.460",
  "Telephone": "+49-9666-951680",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.42363,11.68646",
  "id": "49.42363,11.68646"
}, {
  "Brand": "TOTAL",
  "Street": "ANSBACHER STR. 42",
  "PostCode": 91589,
  "City": "AURACH",
  "Latitude": "49.252.338",
  "Longitude": "10.434.523",
  "Telephone": "09804-9391922",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.252338,10.434523",
  "id": "49.252338,10.434523"
}, {
  "Brand": "OMV",
  "Street": "Luitpoldstr. 34",
  "PostCode": 84034,
  "City": "Landshut",
  "Latitude": "48.540.850",
  "Longitude": "12.141.450",
  "Telephone": "+49-871-62445",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.54085,12.14145",
  "id": "48.54085,12.14145"
}, {
  "Brand": "ARAL",
  "Street": "Pariser Straße 140",
  "PostCode": 55268,
  "City": "Nieder-Olm",
  "Latitude": "49.907.236",
  "Longitude": "08.198.930",
  "Telephone": "061369538615",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "49.907236,8.19893",
  "id": "49.907236,8.19893"
}, {
  "Brand": "Westfalen",
  "Street": "Tiergartenstr. 84",
  "PostCode": 26349,
  "City": "JADE",
  "Latitude": "53.328.900",
  "Longitude": "08.186.890",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.3289,8.18689",
  "id": "53.3289,8.18689"
}, {
  "Brand": "TOTAL",
  "Street": "SCHWARMSTEDTER STR. 14",
  "PostCode": 29690,
  "City": "BUCHHOLZ",
  "Latitude": "52.676.200",
  "Longitude": "09.681.110",
  "Telephone": "05071-4380",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.6762,9.68111",
  "id": "52.6762,9.68111"
}, {
  "Brand": "Westfalen",
  "Street": "Hauptstr. 372-376",
  "PostCode": 33818,
  "City": "LEOPOLDSHOEHE",
  "Latitude": "51.975.600",
  "Longitude": "08.665.390",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9756,8.66539",
  "id": "51.9756,8.66539"
}, {
  "Brand": "ENI",
  "Street": "Mercedesring 2",
  "PostCode": 86368,
  "City": "Gersthofen",
  "Latitude": "48.436.790",
  "Longitude": "10.868.980",
  "Telephone": "+49 (0) 82146094",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.43679,10.86898",
  "id": "48.43679,10.86898"
}, {
  "Brand": "ENI",
  "Street": "Wittstrasse 15",
  "PostCode": 84036,
  "City": "Landshut",
  "Latitude": "48.528.880",
  "Longitude": "12.141.030",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.52888,12.14103",
  "id": "48.52888,12.14103"
}, {
  "Brand": "Westfalen",
  "Street": "Pfarrer-Kraus-Str. 154",
  "PostCode": 56077,
  "City": "KOBLENZ",
  "Latitude": "50.369.700",
  "Longitude": "07.659.800",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.3697,7.6598",
  "id": "50.3697,7.6598"
}, {
  "Brand": "Westfalen",
  "Street": "Donnerschweer 215",
  "PostCode": 26123,
  "City": "OLDENBURG",
  "Latitude": "53.151.300",
  "Longitude": "08.236.530",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.1513,8.23653",
  "id": "53.1513,8.23653"
}, {
  "Brand": "ARAL",
  "Street": "Oskar-Arnold-Straße 14",
  "PostCode": 8056,
  "City": "Zwickau",
  "Latitude": "50.706.535",
  "Longitude": "12.501.094",
  "Telephone": "03752040053",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.706535,12.501094",
  "id": "50.706535,12.501094"
}, {
  "Brand": "ARAL",
  "Street": "Daimlerstraße 2",
  "PostCode": 35260,
  "City": "Stadtallendorf",
  "Latitude": "50.835.262",
  "Longitude": "09.038.366",
  "Telephone": "064284483606",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Ja",
  "location": "50.835262,9.038366",
  "id": "50.835262,9.038366"
}, {
  "Brand": "TOTAL",
  "Street": "SCHAFFELDSTR. 1",
  "PostCode": 46395,
  "City": "BOCHOLT",
  "Latitude": "51.820.908",
  "Longitude": "06.613.598",
  "Telephone": "02871-15001",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.820908,6.613598",
  "id": "51.820908,6.613598"
}, {
  "Brand": "TOTAL",
  "Street": "LANGE STR.159",
  "PostCode": 44581,
  "City": "CASTROP-RAUXEL",
  "Latitude": "51.587.316",
  "Longitude": "07.326.022",
  "Telephone": "02305-79420",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.587316,7.326022",
  "id": "51.587316,7.326022"
}, {
  "Brand": "ARAL",
  "Street": "Theresienstraße 2",
  "PostCode": 83278,
  "City": "Traunstein",
  "Latitude": "47.872.108",
  "Longitude": "12.638.233",
  "Telephone": "08612681",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "18:00",
  "IsOpen24Hours": "Nein",
  "location": "47.872108,12.638233",
  "id": "47.872108,12.638233"
}, {
  "Brand": "TOTAL",
  "Street": "SUESSWIESENSTR. 2",
  "PostCode": 74549,
  "City": "WOLPERTSHAUSEN",
  "Latitude": "49.170.615",
  "Longitude": "09.859.743",
  "Telephone": "07904-9435899",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.170615,9.859743",
  "id": "49.170615,9.859743"
}, {
  "Brand": "TOTAL",
  "Street": "ACHERINGER HAUPTSTR. 20",
  "PostCode": 85354,
  "City": "FREISING",
  "Latitude": "48.344.397",
  "Longitude": "11.709.186",
  "Telephone": "08165-64290",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.344397,11.709186",
  "id": "48.344397,11.709186"
}, {
  "Brand": "ARAL",
  "Street": "Märkische Straße 14",
  "PostCode": 58675,
  "City": "Hemer",
  "Latitude": "51.393.113",
  "Longitude": "07.758.487",
  "Telephone": "0237210628",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.393113,7.758487",
  "id": "51.393113,7.758487"
}, {
  "Brand": "ENI",
  "Street": "Zellers 6",
  "PostCode": 87509,
  "City": "Immenstadt",
  "Latitude": "47.602.016",
  "Longitude": "10.255.261",
  "Telephone": "+49 (0) 83797176",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.602016,10.255261",
  "id": "47.602016,10.255261"
}, {
  "Brand": "TOTAL",
  "Street": "VOCCARTSTR. 80",
  "PostCode": 52134,
  "City": "HERZOGENRATH",
  "Latitude": "50.850.548",
  "Longitude": "06.076.434",
  "Telephone": "02406-2480",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.850548,6.076434",
  "id": "50.850548,6.076434"
}, {
  "Brand": "ARAL",
  "Street": "Neckaraue 2",
  "PostCode": 71686,
  "City": "Remseck",
  "Latitude": "48.890.196",
  "Longitude": "09.271.213",
  "Telephone": "0714643532",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "09:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.890196,9.271213",
  "id": "48.890196,9.271213"
}, {
  "Brand": "OMV",
  "Street": "Friesenheimer Str. 47",
  "PostCode": 67069,
  "City": "Ludwigshafen",
  "Latitude": "49.515.190",
  "Longitude": "08.406.240",
  "Telephone": "+49-621-651636",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.51519,8.40624",
  "id": "49.51519,8.40624"
}, {
  "Brand": "OMV",
  "Street": "Landshuter Str. 42",
  "PostCode": 84416,
  "City": "Taufkirchen",
  "Latitude": "48.352.320",
  "Longitude": "12.129.700",
  "Telephone": "+49-8084-946444",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.35232,12.1297",
  "id": "48.35232,12.1297"
}, {
  "Brand": "ENI",
  "Street": "Burgstr. 16",
  "PostCode": 82467,
  "City": "Garmisch-Partenk.",
  "Latitude": "47.498.931",
  "Longitude": "11.087.982",
  "Telephone": "+49 (0) 88219470",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.498931,11.087982",
  "id": "47.498931,11.087982"
}, {
  "Brand": "ENI",
  "Street": "Am Hochfeldweg 2",
  "PostCode": 85051,
  "City": "Ingolstadt-Zuchering",
  "Latitude": "48.703.313",
  "Longitude": "11.418.645",
  "Telephone": "+49 (0) 84509286",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.703313,11.418645",
  "id": "48.703313,11.418645"
}, {
  "Brand": "Westfalen",
  "Street": "Hansastr. 45a",
  "PostCode": 49090,
  "City": "OSNABRUECK",
  "Latitude": "52.287.900",
  "Longitude": "08.038.100",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.2879,8.0381",
  "id": "52.2879,8.0381"
}, {
  "Brand": "Westfalen",
  "Street": "Luecker Str. 1",
  "PostCode": 41540,
  "City": "DORMAGEN",
  "Latitude": "51.094.900",
  "Longitude": "06.813.090",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.0949,6.81309",
  "id": "51.0949,6.81309"
}, {
  "Brand": "Westfalen",
  "Street": "Polsumer Str. 38 - 40",
  "PostCode": 45894,
  "City": "GELSENKIRCHEN",
  "Latitude": "51.588.500",
  "Longitude": "07.050.460",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.5885,7.05046",
  "id": "51.5885,7.05046"
}, {
  "Brand": "Westfalen",
  "Street": "Hauptstrasse 19",
  "PostCode": 59227,
  "City": "AHLEN",
  "Latitude": "51.800.500",
  "Longitude": "07.953.150",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8005,7.95315",
  "id": "51.8005,7.95315"
}, {
  "Brand": "TOTAL",
  "Street": "MEISSNER STR.41",
  "PostCode": 1623,
  "City": "LOMMATZSCH",
  "Latitude": "51.196.899",
  "Longitude": "13.314.181",
  "Telephone": "035241-58631",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.196899,13.314181",
  "id": "51.196899,13.314181"
}, {
  "Brand": "ARAL",
  "Street": "A 61",
  "PostCode": 56332,
  "City": "Dieblich",
  "Latitude": "50.267.531",
  "Longitude": "07.509.913",
  "Telephone": "02607963465",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.267531,7.509913",
  "id": "50.267531,7.509913"
}, {
  "Brand": "TOTAL",
  "Street": "AN DER STADTAUTOBAHN 70",
  "PostCode": 18107,
  "City": "ROSTOCK-LUETTENKLEIN",
  "Latitude": "54.131.519",
  "Longitude": "12.062.964",
  "Telephone": "0381-712092",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.131519,12.062964",
  "id": "54.131519,12.062964"
}, {
  "Brand": "OMV",
  "Street": "Elly-Beinhornstraße 2",
  "PostCode": 73760,
  "City": "Ostfildern - Scharnhausen",
  "Latitude": "48.704.700",
  "Longitude": "09.248.700",
  "Telephone": "+49-7158-9878513",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.7047,9.2487",
  "id": "48.7047,9.2487"
}, {
  "Brand": "TOTAL",
  "Street": "BAHNHOFSTR. 45",
  "PostCode": 76744,
  "City": "WOERTH",
  "Latitude": "49.046.448",
  "Longitude": "08.273.192",
  "Telephone": "07271-7006",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.046448,8.273192",
  "id": "49.046448,8.273192"
}, {
  "Brand": "TOTAL",
  "Street": "WASBEKER STR. 361",
  "PostCode": 24537,
  "City": "NEUMUENSTER",
  "Latitude": "54.068.480",
  "Longitude": "09.937.957",
  "Telephone": "04321-61092",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.06848,9.937957",
  "id": "54.06848,9.937957"
}, {
  "Brand": "TOTAL",
  "Street": "ESSBAUM 2",
  "PostCode": 83122,
  "City": "SAMERBERG",
  "Latitude": "47.764.300",
  "Longitude": "12.197.300",
  "Telephone": "08032/8826",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.7643,12.1973",
  "id": "47.7643,12.1973"
}, {
  "Brand": "TOTAL",
  "Street": "BINGER LANDSTR. 25",
  "PostCode": 55606,
  "City": "HOCHSTETTEN",
  "Latitude": "49.799.247",
  "Longitude": "07.509.407",
  "Telephone": "06752-2066",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.799247,7.509407",
  "id": "49.799247,7.509407"
}, {
  "Brand": "ENI",
  "Street": "Schwindgraben 2",
  "PostCode": 93083,
  "City": "Obertraubling",
  "Latitude": "48.971.250",
  "Longitude": "12.175.050",
  "Telephone": "+49 (0) 94015102",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.97125,12.17505",
  "id": "48.97125,12.17505"
}, {
  "Brand": "ARAL",
  "Street": "Alpenstraße 4",
  "PostCode": 83242,
  "City": "Reit im Winkl",
  "Latitude": "47.668.839",
  "Longitude": "12.494.652",
  "Telephone": "086408237",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "47.668839,12.494652",
  "id": "47.668839,12.494652"
}, {
  "Brand": "Westfalen",
  "Street": "Raiffeisenstr. 31 - 33",
  "PostCode": 48231,
  "City": "WARENDORF",
  "Latitude": "51.874.100",
  "Longitude": "07.914.280",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8741,7.91428",
  "id": "51.8741,7.91428"
}, {
  "Brand": "ARAL",
  "Street": "Sandweierer Straße 47",
  "PostCode": 76532,
  "City": "Baden-Baden",
  "Latitude": "48.811.201",
  "Longitude": "08.195.391",
  "Telephone": "07221809171",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "07:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.811201,8.195391",
  "id": "48.811201,8.195391"
}, {
  "Brand": "TOTAL",
  "Street": "ROSENTHALER STR. 2",
  "PostCode": 67304,
  "City": "EISENBERG",
  "Latitude": "49.564.165",
  "Longitude": "08.062.270",
  "Telephone": "06351-5164",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.564165,8.06227",
  "id": "49.564165,8.06227"
}, {
  "Brand": "TOTAL",
  "Street": "AUTOHOF STRASSBURGER STR. 4",
  "PostCode": 31275,
  "City": "LEHRTE",
  "Latitude": "52.385.398",
  "Longitude": "09.952.765",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.385398,9.952765",
  "id": "52.385398,9.952765"
}, {
  "Brand": "TOTAL",
  "Street": "OBERE LAUTER STR. 37",
  "PostCode": 67731,
  "City": "OTTERBACH",
  "Latitude": "49.478.785",
  "Longitude": "07.735.516",
  "Telephone": "06301-8258",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.478785,7.735516",
  "id": "49.478785,7.735516"
}, {
  "Brand": "TOTAL",
  "Street": "ALEMANNENSTRASSE 1",
  "PostCode": 84130,
  "City": "DINGOLFING",
  "Latitude": "48.641.057",
  "Longitude": "12.463.258",
  "Telephone": "087313099830",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.641057,12.463258",
  "id": "48.641057,12.463258"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER STR. 23",
  "PostCode": 3226,
  "City": "VETSCHAU",
  "Latitude": "51.786.099",
  "Longitude": "14.065.982",
  "Telephone": "035433-2350",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.786099,14.065982",
  "id": "51.786099,14.065982"
}, {
  "Brand": "TOTAL",
  "Street": "JAKOBSTR. 43",
  "PostCode": 67722,
  "City": "WINNWEILER",
  "Latitude": "49.564.805",
  "Longitude": "07.856.710",
  "Telephone": "06302-2187",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.564805,7.85671",
  "id": "49.564805,7.85671"
}, {
  "Brand": "ARAL",
  "Street": "Lange Göhren",
  "PostCode": 39171,
  "City": "Osterweddingen",
  "Latitude": "52.052.527",
  "Longitude": "11.589.095",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.052527,11.589095",
  "id": "52.052527,11.589095"
}, {
  "Brand": "ARAL",
  "Street": "König-Ludwig-Straße 2",
  "PostCode": 87645,
  "City": "Schwangau",
  "Latitude": "47.579.327",
  "Longitude": "10.741.941",
  "Telephone": "08362982721",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "06:30",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "47.579327,10.741941",
  "id": "47.579327,10.741941"
}, {
  "Brand": "TOTAL",
  "Street": "ANTON-AULKE-RING 10",
  "PostCode": 48308,
  "City": "SENDEN",
  "Latitude": "51.855.365",
  "Longitude": "07.492.069",
  "Telephone": "02597-5547",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.855365,7.492069",
  "id": "51.855365,7.492069"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER STR 15A",
  "PostCode": 14169,
  "City": "BERLIN",
  "Latitude": "52.436.134",
  "Longitude": "13.265.840",
  "Telephone": "030-81059385",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.436134,13.26584",
  "id": "52.436134,13.26584"
}, {
  "Brand": "ARAL",
  "Street": "Äußere Moosburger Str. 1",
  "PostCode": 85276,
  "City": "Pfaffenhofen",
  "Latitude": "48.522.353",
  "Longitude": "11.543.634",
  "Telephone": "08441499190",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.522353,11.543634",
  "id": "48.522353,11.543634"
}, {
  "Brand": "ARAL",
  "Street": "Dycker Feld",
  "PostCode": 42653,
  "City": "Solingen",
  "Latitude": "51.201.320",
  "Longitude": "07.077.690",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.20132,7.07769",
  "id": "51.20132,7.07769"
}, {
  "Brand": "ARAL",
  "Street": "Chiemseestraße 50",
  "PostCode": 83278,
  "City": "Traunstein",
  "Latitude": "47.868.101",
  "Longitude": "12.622.201",
  "Telephone": "086112973",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:30",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:30",
  "SundayFrom": "07:00",
  "SundayTo": "21:30",
  "IsOpen24Hours": "Nein",
  "location": "47.868101,12.622201",
  "id": "47.868101,12.622201"
}, {
  "Brand": "OMV",
  "Street": "Stuttgarter Strasse 54",
  "PostCode": 72141,
  "City": "Walddorfhäslach",
  "Latitude": "48.588.030",
  "Longitude": "09.190.240",
  "Telephone": "+49-7127-9774946",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.58803,9.19024",
  "id": "48.58803,9.19024"
}, {
  "Brand": "ENI",
  "Street": "Hofgartenstrasse 10",
  "PostCode": 72379,
  "City": "Hechingen",
  "Latitude": "48.356.921",
  "Longitude": "08.964.652",
  "Telephone": "+49 (0) 74715315",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.356921,8.964652",
  "id": "48.356921,8.964652"
}, {
  "Brand": "OMV",
  "Street": "Ingolstädter Str. 7 a",
  "PostCode": 85101,
  "City": "Lenting",
  "Latitude": "48.806.090",
  "Longitude": "11.462.500",
  "Telephone": "+49-8456-2223",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.80609,11.4625",
  "id": "48.80609,11.4625"
}, {
  "Brand": "ENI",
  "Street": "Robert-Bosch-Str. 2-4",
  "PostCode": 72525,
  "City": "Muensingen",
  "Latitude": "48.415.390",
  "Longitude": "09.474.010",
  "Telephone": "+49 (0) 73815013",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.41539,9.47401",
  "id": "48.41539,9.47401"
}, {
  "Brand": "OMV",
  "Street": "Landshuter Str. 101",
  "PostCode": 94315,
  "City": "Straubing",
  "Latitude": "48.867.220",
  "Longitude": "12.574.130",
  "Telephone": "+49-9421-530190",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.86722,12.57413",
  "id": "48.86722,12.57413"
}, {
  "Brand": "ENI",
  "Street": "Lappersdorfer Strasse 110",
  "PostCode": 93059,
  "City": "Regensburg",
  "Latitude": "49.040.510",
  "Longitude": "12.094.640",
  "Telephone": "+49 (0) 94182903",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.04051,12.09464",
  "id": "49.04051,12.09464"
}, {
  "Brand": "Westfalen",
  "Street": "Westtor 40",
  "PostCode": 48324,
  "City": "SENDENHORST",
  "Latitude": "51.843.200",
  "Longitude": "07.814.610",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8432,7.81461",
  "id": "51.8432,7.81461"
}, {
  "Brand": "ARAL",
  "Street": "Gothaer Straße 40",
  "PostCode": 99094,
  "City": "Erfurt",
  "Latitude": "50.964.982",
  "Longitude": "10.999.120",
  "Telephone": "036165319607",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.964982,10.99912",
  "id": "50.964982,10.99912"
}, {
  "Brand": "OMV",
  "Street": "Nürnberger Str. 16 a",
  "PostCode": 95448,
  "City": "Bayreuth",
  "Latitude": "49.936.660",
  "Longitude": "11.590.290",
  "Telephone": "+49-921-65514",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.93666,11.59029",
  "id": "49.93666,11.59029"
}, {
  "Brand": "OMV",
  "Street": "Eibacher Hauptstr. 109",
  "PostCode": 90451,
  "City": "Nürnberg",
  "Latitude": "49.399.110",
  "Longitude": "11.037.790",
  "Telephone": "+49-911-6494910",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.39911,11.03779",
  "id": "49.39911,11.03779"
}, {
  "Brand": "TOTAL",
  "Street": "MUSKAUER STR. 32",
  "PostCode": 3159,
  "City": "DOEBERN",
  "Latitude": "51.606.671",
  "Longitude": "14.608.634",
  "Telephone": "035600-23083",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.606671,14.608634",
  "id": "51.606671,14.608634"
}, {
  "Brand": "TOTAL",
  "Street": "DANZIGER STR. 69",
  "PostCode": 29633,
  "City": "MUNSTER",
  "Latitude": "52.989.216",
  "Longitude": "10.081.495",
  "Telephone": "05192-6267",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.989216,10.081495",
  "id": "52.989216,10.081495"
}, {
  "Brand": "ARAL",
  "Street": "Nebelhornstraße 8",
  "PostCode": 87719,
  "City": "Mindelheim",
  "Latitude": "48.036.085",
  "Longitude": "10.501.371",
  "Telephone": "0826120100",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "48.036085,10.501371",
  "id": "48.036085,10.501371"
}, {
  "Brand": "TOTAL",
  "Street": "O.-v.-NELL-BREUNING 4",
  "PostCode": 51503,
  "City": "ROESRATH",
  "Latitude": "50.884.422",
  "Longitude": "07.186.394",
  "Telephone": "02205-899802",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.884422,7.186394",
  "id": "50.884422,7.186394"
}, {
  "Brand": "TOTAL",
  "Street": "AN DER B 96",
  "PostCode": 15938,
  "City": "GOLSSEN",
  "Latitude": "51.977.556",
  "Longitude": "13.589.563",
  "Telephone": "035452-432",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.977556,13.589563",
  "id": "51.977556,13.589563"
}, {
  "Brand": "TOTAL",
  "Street": "ROSTOCKER STR. 42",
  "PostCode": 17213,
  "City": "MALCHOW",
  "Latitude": "53.480.994",
  "Longitude": "12.421.106",
  "Telephone": "039932-12695",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.480994,12.421106",
  "id": "53.480994,12.421106"
}, {
  "Brand": "TOTAL",
  "Street": "HARBURGER BERGE WEST/ A7",
  "PostCode": 21077,
  "City": "HAMBURG",
  "Latitude": "53.424.393",
  "Longitude": "09.962.937",
  "Telephone": "040-7001010",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.424393,9.962937",
  "id": "53.424393,9.962937"
}, {
  "Brand": "TOTAL",
  "Street": "OFFENBURGER STR. 22",
  "PostCode": 79341,
  "City": "KENZINGEN",
  "Latitude": "48.195.576",
  "Longitude": "07.773.943",
  "Telephone": "07644-2557940",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.195576,7.773943",
  "id": "48.195576,7.773943"
}, {
  "Brand": "ARAL",
  "Street": "An der Autobahn 1",
  "PostCode": 29690,
  "City": "Buchholz/Aller",
  "Latitude": "52.673.809",
  "Longitude": "09.689.094",
  "Telephone": "050718060",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.673809,9.689094",
  "id": "52.673809,9.689094"
}, {
  "Brand": "TOTAL",
  "Street": "PREINSTR. 126",
  "PostCode": 44265,
  "City": "DORTMUND",
  "Latitude": "51.468.183",
  "Longitude": "07.492.734",
  "Telephone": "0231-7246591",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.468183,7.492734",
  "id": "51.468183,7.492734"
}, {
  "Brand": "OMV",
  "Street": "Windsbacher Str. 39",
  "PostCode": 91174,
  "City": "Spalt",
  "Latitude": "49.177.470",
  "Longitude": "10.918.110",
  "Telephone": "+49-9175-790990",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.17747,10.91811",
  "id": "49.17747,10.91811"
}, {
  "Brand": "TOTAL",
  "Street": "CASTROPER STR. 123",
  "PostCode": 45665,
  "City": "RECKLINGHAUSEN",
  "Latitude": "51.604.639",
  "Longitude": "07.214.727",
  "Telephone": "02361-484773",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.604639,7.214727",
  "id": "51.604639,7.214727"
}, {
  "Brand": "ARAL",
  "Street": "Wasseralfinger Straße 66",
  "PostCode": 73434,
  "City": "Aalen-Fachsenfeld",
  "Latitude": "48.878.487",
  "Longitude": "10.049.996",
  "Telephone": "07366922998",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.878487,10.049996",
  "id": "48.878487,10.049996"
}, {
  "Brand": "TOTAL",
  "Street": "MELDORFER STR. 143",
  "PostCode": 25746,
  "City": "HEIDE",
  "Latitude": "54.184.383",
  "Longitude": "09.090.378",
  "Telephone": "0481-69881555",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.184383,9.090378",
  "id": "54.184383,9.090378"
}, {
  "Brand": "TOTAL",
  "Street": "HERMANN-HESSE-STR. 1",
  "PostCode": 39118,
  "City": "MAGDEBURG",
  "Latitude": "52.094.863",
  "Longitude": "11.605.684",
  "Telephone": "0391-616397",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.094863,11.605684",
  "id": "52.094863,11.605684"
}, {
  "Brand": "TOTAL",
  "Street": "AM WAETHERING NR. 2",
  "PostCode": 17335,
  "City": "STRASBURG",
  "Latitude": "53.502.069",
  "Longitude": "13.733.866",
  "Telephone": "039753-22077",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.502069,13.733866",
  "id": "53.502069,13.733866"
}, {
  "Brand": "TOTAL",
  "Street": "SOESTER BOERDE NORD, A44",
  "PostCode": 59494,
  "City": "SOEST",
  "Latitude": "51.548.508",
  "Longitude": "08.133.120",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.548508,8.13312",
  "id": "51.548508,8.13312"
}, {
  "Brand": "ARAL",
  "Street": "Scherzachstraße 4",
  "PostCode": 88281,
  "City": "Schlier",
  "Latitude": "47.772.072",
  "Longitude": "09.670.640",
  "Telephone": "07529912202",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "18:00",
  "IsOpen24Hours": "Nein",
  "location": "47.772072,9.67064",
  "id": "47.772072,9.67064"
}, {
  "Brand": "ARAL",
  "Street": "Monzenbend 8",
  "PostCode": 53894,
  "City": "Mechernich",
  "Latitude": "50.618.682",
  "Longitude": "06.651.707",
  "Telephone": "02443310120",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.618682,6.651707",
  "id": "50.618682,6.651707"
}, {
  "Brand": "OMV",
  "Street": "Priener Str. 10",
  "PostCode": 83233,
  "City": "Bernau",
  "Latitude": "47.813.290",
  "Longitude": "12.374.370",
  "Telephone": "+49-8051-89297",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.81329,12.37437",
  "id": "47.81329,12.37437"
}, {
  "Brand": "ARAL",
  "Street": "BILDECHINGER STEIGE 64",
  "PostCode": 72160,
  "City": "HORB AM NECKAR",
  "Latitude": "48.455.575",
  "Longitude": "08.699.015",
  "Telephone": "074515506927",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.455575,8.699015",
  "id": "48.455575,8.699015"
}, {
  "Brand": "ARAL",
  "Street": "Werner-von-Siemens-Straße 2",
  "PostCode": 63150,
  "City": "Heusenstamm",
  "Latitude": "50.048.449",
  "Longitude": "08.799.238",
  "Telephone": "06104/8008838",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "50.048449,8.799238",
  "id": "50.048449,8.799238"
}, {
  "Brand": "ENI",
  "Street": "Guelzower Str. 15A",
  "PostCode": 12619,
  "City": "Berlin",
  "Latitude": "52.514.442",
  "Longitude": "13.581.901",
  "Telephone": "+49 (0) 30562931",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "52.514442,13.581901",
  "id": "52.514442,13.581901"
}, {
  "Brand": "OMV",
  "Street": "Fürther Str. 60",
  "PostCode": 90587,
  "City": "Veitsbronn",
  "Latitude": "49.504.400",
  "Longitude": "10.893.780",
  "Telephone": "+49-911-7540929",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.5044,10.89378",
  "id": "49.5044,10.89378"
}, {
  "Brand": "OMV",
  "Street": "Franz-Ludwig-Str. 14",
  "PostCode": 97072,
  "City": "Würzburg",
  "Latitude": "49.783.760",
  "Longitude": "09.938.940",
  "Telephone": "+49-931-74128",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.78376,9.93894",
  "id": "49.78376,9.93894"
}, {
  "Brand": "OMV",
  "Street": "Bahnhofstr. 10",
  "PostCode": 73240,
  "City": "Wendlingen",
  "Latitude": "48.669.930",
  "Longitude": "09.373.020",
  "Telephone": "+49-7024-7152",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.66993,9.37302",
  "id": "48.66993,9.37302"
}, {
  "Brand": "ARAL",
  "Street": "A44",
  "PostCode": 59457,
  "City": "Werl",
  "Latitude": "51.533.429",
  "Longitude": "07.863.355",
  "Telephone": "029225299",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.533429,7.863355",
  "id": "51.533429,7.863355"
}, {
  "Brand": "ARAL",
  "Street": "Im langen Feld 17",
  "PostCode": 71711,
  "City": "Murr",
  "Latitude": "48.957.129",
  "Longitude": "09.243.526",
  "Telephone": "0714429235",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.957129,9.243526",
  "id": "48.957129,9.243526"
}, {
  "Brand": "ARAL",
  "Street": "Kleiststraße 2",
  "PostCode": 65232,
  "City": "Taunusstein",
  "Latitude": "50.138.350",
  "Longitude": "08.154.682",
  "Telephone": "06128/9372380",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "50.13835,8.154682",
  "id": "50.13835,8.154682"
}, {
  "Brand": "ENI",
  "Street": "Bernecker Str.52",
  "PostCode": 95448,
  "City": "Bayreuth",
  "Latitude": "49.954.700",
  "Longitude": "11.600.950",
  "Telephone": "0921/78519303",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.9547,11.60095",
  "id": "49.9547,11.60095"
}, {
  "Brand": "TOTAL",
  "Street": "HERMANN-LOENS-WEG 1",
  "PostCode": 25462,
  "City": "RELLINGEN",
  "Latitude": "53.638.707",
  "Longitude": "09.893.509",
  "Telephone": "04101-2163907",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.638707,9.893509",
  "id": "53.638707,9.893509"
}, {
  "Brand": "Westfalen",
  "Street": "Alte Wittener Str. 40",
  "PostCode": 44803,
  "City": "BOCHUM",
  "Latitude": "51.468.118",
  "Longitude": "07.271.057",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.468118,7.271057",
  "id": "51.468118,7.271057"
}, {
  "Brand": "ARAL",
  "Street": "Auf der Held 2",
  "PostCode": 56305,
  "City": "Puderbach",
  "Latitude": "50.593.321",
  "Longitude": "07.607.490",
  "Telephone": "02684978878",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.593321,7.60749",
  "id": "50.593321,7.60749"
}, {
  "Brand": "Westfalen",
  "Street": "Gahlenstr. 105",
  "PostCode": 46286,
  "City": "DORSTEN",
  "Latitude": "51.656.800",
  "Longitude": "06.949.000",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.6568,6.949",
  "id": "51.6568,6.949"
}, {
  "Brand": "TOTAL",
  "Street": "BAB BIGGENKOPF SUED",
  "PostCode": 34474,
  "City": "DIEMELSTADT",
  "Latitude": "51.499.488",
  "Longitude": "08.983.397",
  "Telephone": "05642-98100",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.499488,8.983397",
  "id": "51.499488,8.983397"
}, {
  "Brand": "Westfalen",
  "Street": "Buchenweg 107",
  "PostCode": 46147,
  "City": "OBERHAUSEN",
  "Latitude": "51.545.500",
  "Longitude": "06.829.150",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.5455,6.82915",
  "id": "51.5455,6.82915"
}, {
  "Brand": "TOTAL",
  "Street": "HEIDESTR. 5",
  "PostCode": 39291,
  "City": "GENTHIN OT SCHOPSDORF",
  "Latitude": "52.247.771",
  "Longitude": "12.249.149",
  "Telephone": "039225395990",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.247771,12.249149",
  "id": "52.247771,12.249149"
}, {
  "Brand": "TOTAL",
  "Street": "ALDINGER STR. 8",
  "PostCode": 71686,
  "City": "REMSECK",
  "Latitude": "48.875.278",
  "Longitude": "09.270.556",
  "Telephone": "07146-810047",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.875278,9.270556",
  "id": "48.875278,9.270556"
}, {
  "Brand": "TOTAL",
  "Street": "EUROPAALLEE 5A",
  "PostCode": 28309,
  "City": "BREMEN",
  "Latitude": "53.034.854",
  "Longitude": "08.886.761",
  "Telephone": "0421-48536565",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.034854,8.886761",
  "id": "53.034854,8.886761"
}, {
  "Brand": "TOTAL",
  "Street": "GENTHINER STR. 59",
  "PostCode": 14712,
  "City": "RATHENOW",
  "Latitude": "52.599.400",
  "Longitude": "12.297.083",
  "Telephone": "03385-512175",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.5994,12.297083",
  "id": "52.5994,12.297083"
}, {
  "Brand": "ARAL",
  "Street": "Wörishofer Straße 23",
  "PostCode": 86842,
  "City": "Türkheim",
  "Latitude": "48.055.426",
  "Longitude": "10.636.916",
  "Telephone": "08245962111",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:30",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.055426,10.636916",
  "id": "48.055426,10.636916"
}, {
  "Brand": "ARAL",
  "Street": "Weißenhorner Straße 56",
  "PostCode": 89297,
  "City": "Roggenburg-Biberach",
  "Latitude": "48.288.064",
  "Longitude": "10.213.991",
  "Telephone": "073005184",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "48.288064,10.213991",
  "id": "48.288064,10.213991"
}, {
  "Brand": "TOTAL",
  "Street": "COTTBUSER STRASSE 12 A",
  "PostCode": 3185,
  "City": "PEITZ",
  "Latitude": "51.853.187",
  "Longitude": "14.406.464",
  "Telephone": "035601-80645",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.853187,14.406464",
  "id": "51.853187,14.406464"
}, {
  "Brand": "TOTAL",
  "Street": "WERLER STR. 370",
  "PostCode": 59069,
  "City": "HAMM",
  "Latitude": "51.645.934",
  "Longitude": "07.842.771",
  "Telephone": "02381-51543",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.645934,7.842771",
  "id": "51.645934,7.842771"
}, {
  "Brand": "TOTAL",
  "Street": "NECKARBURG OST A81",
  "PostCode": 78661,
  "City": "DIETINGEN",
  "Latitude": "48.205.810",
  "Longitude": "08.625.130",
  "Telephone": "0741 8894",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.20581,8.62513",
  "id": "48.20581,8.62513"
}, {
  "Brand": "ENI",
  "Street": "Thomas Muentzer Str. 74",
  "PostCode": 6217,
  "City": "Merseburg",
  "Latitude": "51.352.630",
  "Longitude": "11.980.540",
  "Telephone": "034618219700",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.35263,11.98054",
  "id": "51.35263,11.98054"
}, {
  "Brand": "TOTAL",
  "Street": "RUDOLF-DIESEL-STR. 3-5",
  "PostCode": 56566,
  "City": "NEUWIED",
  "Latitude": "50.443.414",
  "Longitude": "07.498.813",
  "Telephone": "02631-9427150",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.443414,7.498813",
  "id": "50.443414,7.498813"
}, {
  "Brand": "ENI",
  "Street": "Bayerstrasse 4",
  "PostCode": 83308,
  "City": "Trostberg",
  "Latitude": "48.027.071",
  "Longitude": "12.556.434",
  "Telephone": "+49 (0) 86212419",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.027071,12.556434",
  "id": "48.027071,12.556434"
}, {
  "Brand": "TOTAL",
  "Street": "AN DER BORNHOHL 56",
  "PostCode": 60437,
  "City": "FRANKFURT",
  "Latitude": "50.196.435",
  "Longitude": "08.666.941",
  "Telephone": "069-5071584",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.196435,8.666941",
  "id": "50.196435,8.666941"
}, {
  "Brand": "ARAL",
  "Street": "Dechenstraße 17",
  "PostCode": 44147,
  "City": "Dortmund",
  "Latitude": "51.519.828",
  "Longitude": "07.440.083",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.519828,7.440083",
  "id": "51.519828,7.440083"
}, {
  "Brand": "ARAL",
  "Street": "Bunsenstr. 3",
  "PostCode": 85053,
  "City": "Ingolstadt",
  "Latitude": "48.742.606",
  "Longitude": "11.473.910",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.742606,11.47391",
  "id": "48.742606,11.47391"
}, {
  "Brand": "ARAL",
  "Street": "Franziusstr. 25",
  "PostCode": 60314,
  "City": "Frankfurt",
  "Latitude": "50.111.592",
  "Longitude": "08.728.689",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.111592,8.728689",
  "id": "50.111592,8.728689"
}, {
  "Brand": "ENI",
  "Street": "Kamenzer Strasse 34",
  "PostCode": 1896,
  "City": "Pulsnitz",
  "Latitude": "51.200.229",
  "Longitude": "14.022.583",
  "Telephone": "+49 (0) 35955442",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.200229,14.022583",
  "id": "51.200229,14.022583"
}, {
  "Brand": "ENI",
  "Street": "Oberthann 9",
  "PostCode": 85301,
  "City": "Schweitenkirchen",
  "Latitude": "48.507.470",
  "Longitude": "11.585.370",
  "Telephone": "+49 (0) 8444333",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.50747,11.58537",
  "id": "48.50747,11.58537"
}, {
  "Brand": "ENI",
  "Street": "Potsdamer Strasse 31-32",
  "PostCode": 14163,
  "City": "Berlin",
  "Latitude": "52.434.331",
  "Longitude": "13.249.117",
  "Telephone": "+49 (0) 30802968",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.434331,13.249117",
  "id": "52.434331,13.249117"
}, {
  "Brand": "OMV",
  "Street": "Katzwanger Str. 20",
  "PostCode": 91126,
  "City": "Schwabach-Limbach",
  "Latitude": "49.342.470",
  "Longitude": "11.046.980",
  "Telephone": "+49-9122-693232",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.34247,11.04698",
  "id": "49.34247,11.04698"
}, {
  "Brand": "OMV",
  "Street": "Renninger Strasse 24",
  "PostCode": 71277,
  "City": "Rutesheim",
  "Latitude": "48.807.040",
  "Longitude": "08.942.160",
  "Telephone": "+49-7152-99330",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.80704,8.94216",
  "id": "48.80704,8.94216"
}, {
  "Brand": "Westfalen",
  "Street": "Gesmolder Str. 61",
  "PostCode": 49324,
  "City": "MELLE",
  "Latitude": "52.199.600",
  "Longitude": "08.325.360",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.1996,8.32536",
  "id": "52.1996,8.32536"
}, {
  "Brand": "OMV",
  "Street": "Mannheimer Strasse 131",
  "PostCode": 68723,
  "City": "Schwetzingen",
  "Latitude": "49.392.140",
  "Longitude": "08.564.550",
  "Telephone": "+49-6202-4443",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.39214,8.56455",
  "id": "49.39214,8.56455"
}, {
  "Brand": "ENI",
  "Street": "Buergermeister-Hefele-Strasse 1",
  "PostCode": 86609,
  "City": "Donauwoerth",
  "Latitude": "48.719.450",
  "Longitude": "10.776.740",
  "Telephone": "+49 (0) 90670556",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.71945,10.77674",
  "id": "48.71945,10.77674"
}, {
  "Brand": "ARAL",
  "Street": "Dieselstr. 3",
  "PostCode": 63110,
  "City": "Rodgau-Niederroden",
  "Latitude": "49.987.906",
  "Longitude": "08.868.004",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.987906,8.868004",
  "id": "49.987906,8.868004"
}, {
  "Brand": "Westfalen",
  "Street": "Oldenburger Str. 87",
  "PostCode": 27753,
  "City": "DELMENHORST",
  "Latitude": "53.049.200",
  "Longitude": "08.605.490",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.0492,8.60549",
  "id": "53.0492,8.60549"
}, {
  "Brand": "Westfalen",
  "Street": "Schoetmarsche Str. 356",
  "PostCode": 32791,
  "City": "LAGE",
  "Latitude": "52.023.400",
  "Longitude": "08.761.420",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0234,8.76142",
  "id": "52.0234,8.76142"
}, {
  "Brand": "TOTAL",
  "Street": "ROEDGENER STR.3",
  "PostCode": 4838,
  "City": "EILENBURG",
  "Latitude": "51.458.100",
  "Longitude": "12.614.588",
  "Telephone": "03423-602911",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.4581,12.614588",
  "id": "51.4581,12.614588"
}, {
  "Brand": "TOTAL",
  "Street": "WIENER STR. 39",
  "PostCode": 1069,
  "City": "DRESDEN",
  "Latitude": "51.036.099",
  "Longitude": "13.748.482",
  "Telephone": "0351-46927235",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.036099,13.748482",
  "id": "51.036099,13.748482"
}, {
  "Brand": "ENI",
  "Street": "Rheinstr. 1a",
  "PostCode": 56242,
  "City": "Selters (Westerwald)",
  "Latitude": "50.528.310",
  "Longitude": "07.758.150",
  "Telephone": "02626/5208",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.52831,7.75815",
  "id": "50.52831,7.75815"
}, {
  "Brand": "TOTAL",
  "Street": "FREIENHUFENER ECK-OST/ A13",
  "PostCode": 1994,
  "City": "DROCHOW",
  "Latitude": "51.558.599",
  "Longitude": "13.920.292",
  "Telephone": "0357543650",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.558599,13.920292",
  "id": "51.558599,13.920292"
}, {
  "Brand": "OMV",
  "Street": "Fürther Str. 2",
  "PostCode": 90574,
  "City": "Roßtal",
  "Latitude": "49.399.350",
  "Longitude": "10.886.380",
  "Telephone": "+49-9127-9079612",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.39935,10.88638",
  "id": "49.39935,10.88638"
}, {
  "Brand": "TOTAL",
  "Street": "KAELBERAUER STR. 12",
  "PostCode": 63755,
  "City": "ALZENAU",
  "Latitude": "50.098.770",
  "Longitude": "09.100.041",
  "Telephone": "06023-1829",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.09877,9.100041",
  "id": "50.09877,9.100041"
}, {
  "Brand": "TOTAL",
  "Street": "AM KREUZSEE 1",
  "PostCode": 19412,
  "City": "BRUEEL",
  "Latitude": "53.729.394",
  "Longitude": "11.690.909",
  "Telephone": "038483-22231",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.729394,11.690909",
  "id": "53.729394,11.690909"
}, {
  "Brand": "ARAL",
  "Street": "Wittener Straße 66",
  "PostCode": 44789,
  "City": "Bochum",
  "Latitude": "51.477.485",
  "Longitude": "07.227.767",
  "Telephone": "0234335915",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.477485,7.227767",
  "id": "51.477485,7.227767"
}, {
  "Brand": "ARAL",
  "Street": "Landdammstr. 44-48",
  "PostCode": 65462,
  "City": "Ginsheim",
  "Latitude": "49.990.673",
  "Longitude": "08.313.866",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.990673,8.313866",
  "id": "49.990673,8.313866"
}, {
  "Brand": "TOTAL",
  "Street": "MINKELDONK 1",
  "PostCode": 47495,
  "City": "RHEINBERG",
  "Latitude": "51.534.471",
  "Longitude": "06.588.898",
  "Telephone": "02843-9596456",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.534471,6.588898",
  "id": "51.534471,6.588898"
}, {
  "Brand": "TOTAL",
  "Street": "OSTERHOFENER STR. 1",
  "PostCode": 93055,
  "City": "REGENSBURG",
  "Latitude": "49.013.185",
  "Longitude": "12.139.661",
  "Telephone": "0941 59874567",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.013185,12.139661",
  "id": "49.013185,12.139661"
}, {
  "Brand": "ENI",
  "Street": "Ahrensfelder Chaussee 152",
  "PostCode": 12689,
  "City": "Berlin-Marzahn",
  "Latitude": "52.572.288",
  "Longitude": "13.564.907",
  "Telephone": "+49 (0) 30937439",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "52.572288,13.564907",
  "id": "52.572288,13.564907"
}, {
  "Brand": "ENI",
  "Street": "Chamer Strasse 48",
  "PostCode": 94315,
  "City": "Straubing",
  "Latitude": "48.887.670",
  "Longitude": "12.574.000",
  "Telephone": "+49 (0) 94211236",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.88767,12.574",
  "id": "48.88767,12.574"
}, {
  "Brand": "TOTAL",
  "Street": "KOENIGSBRUECKER STR. 104",
  "PostCode": 1099,
  "City": "DRESDEN",
  "Latitude": "51.083.130",
  "Longitude": "13.761.560",
  "Telephone": "0351-8106054",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.08313,13.76156",
  "id": "51.08313,13.76156"
}, {
  "Brand": "OMV",
  "Street": "Regensburger Str. 85",
  "PostCode": 90478,
  "City": "Nürnberg",
  "Latitude": "49.443.320",
  "Longitude": "11.103.940",
  "Telephone": "+49-911-465486",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.44332,11.10394",
  "id": "49.44332,11.10394"
}, {
  "Brand": "ARAL",
  "Street": "Hamburger Str. 5",
  "PostCode": 40221,
  "City": "Düsseldorf",
  "Latitude": "51.215.020",
  "Longitude": "06.738.773",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.21502,6.738773",
  "id": "51.21502,6.738773"
}, {
  "Brand": "TOTAL",
  "Street": "METZER STR. 123",
  "PostCode": 66117,
  "City": "SAARBRUECKEN",
  "Latitude": "49.209.499",
  "Longitude": "06.963.703",
  "Telephone": "0681-5846632",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.209499,6.963703",
  "id": "49.209499,6.963703"
}, {
  "Brand": "TOTAL",
  "Street": "OBERROEDERSTR.29",
  "PostCode": 64859,
  "City": "EPPERTSHAUSEN",
  "Latitude": "49.950.335",
  "Longitude": "08.840.426",
  "Telephone": "06071-31359",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.950335,8.840426",
  "id": "49.950335,8.840426"
}, {
  "Brand": "ARAL",
  "Street": "Raiffeisenstraße 2",
  "PostCode": 86720,
  "City": "Nördlingen",
  "Latitude": "48.856.853",
  "Longitude": "10.478.428",
  "Telephone": "09081801092",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:30",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "00:00",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "48.856853,10.478428",
  "id": "48.856853,10.478428"
}, {
  "Brand": "Westfalen",
  "Street": "Olfener Str. 11",
  "PostCode": 59379,
  "City": "SELM",
  "Latitude": "51.705.900",
  "Longitude": "07.455.150",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.7059,7.45515",
  "id": "51.7059,7.45515"
}, {
  "Brand": "TOTAL",
  "Street": "RIEDLINGER STR. 52",
  "PostCode": 88400,
  "City": "BIBERACH",
  "Latitude": "48.095.749",
  "Longitude": "09.779.529",
  "Telephone": "07351-10022",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.095749,9.779529",
  "id": "48.095749,9.779529"
}, {
  "Brand": "TOTAL",
  "Street": "WUELFERODE OST",
  "PostCode": 30539,
  "City": "HANNOVER",
  "Latitude": "52.333.294",
  "Longitude": "09.864.092",
  "Telephone": "0511-9545544",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.333294,9.864092",
  "id": "52.333294,9.864092"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße. 34",
  "PostCode": 86459,
  "City": "Gessertshausen",
  "Latitude": "48.329.372",
  "Longitude": "10.730.330",
  "Telephone": "0823896130",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.329372,10.73033",
  "id": "48.329372,10.73033"
}, {
  "Brand": "ARAL",
  "Street": "Hohenzollernstraße 11",
  "PostCode": 88356,
  "City": "Ostrach",
  "Latitude": "47.951.613",
  "Longitude": "09.383.575",
  "Telephone": "0",
  "WeekDayFrom": "07:30",
  "WeekDayTo": "17:30",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "11:30",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "47.951613,9.383575",
  "id": "47.951613,9.383575"
}, {
  "Brand": "Westfalen",
  "Street": "An der Leimkaul 1",
  "PostCode": 52428,
  "City": "JUELICH",
  "Latitude": "50.926.800",
  "Longitude": "06.371.950",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.9268,6.37195",
  "id": "50.9268,6.37195"
}, {
  "Brand": "ENI",
  "Street": "Preysingstr. 58",
  "PostCode": 85283,
  "City": "Wolnzach",
  "Latitude": "48.597.988",
  "Longitude": "11.630.002",
  "Telephone": "+49 (0) 84429119",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.597988,11.630002",
  "id": "48.597988,11.630002"
}, {
  "Brand": "TOTAL",
  "Street": "AN DER B 247",
  "PostCode": 99947,
  "City": "SCHOENSTEDT",
  "Latitude": "51.124.818",
  "Longitude": "10.581.590",
  "Telephone": "036022-91919",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.124818,10.58159",
  "id": "51.124818,10.58159"
}, {
  "Brand": "ENI",
  "Street": "Spitalhofstrasse 84",
  "PostCode": 94032,
  "City": "Passau",
  "Latitude": "48.570.203",
  "Longitude": "13.426.984",
  "Telephone": "+49 (0) 85170166",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.570203,13.426984",
  "id": "48.570203,13.426984"
}, {
  "Brand": "ENI",
  "Street": "Bernlocher Str. 17",
  "PostCode": 72829,
  "City": "Engstingen",
  "Latitude": "48.385.746",
  "Longitude": "09.300.563",
  "Telephone": "+49 (0) 71293318",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.385746,9.300563",
  "id": "48.385746,9.300563"
}, {
  "Brand": "ENI",
  "Street": "Kocheler Str. 11",
  "PostCode": 83673,
  "City": "Bichl",
  "Latitude": "47.714.883",
  "Longitude": "11.413.618",
  "Telephone": "+49 (0) 88571263",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.714883,11.413618",
  "id": "47.714883,11.413618"
}, {
  "Brand": "OMV",
  "Street": "Mühldorfer Str. 72",
  "PostCode": 84419,
  "City": "Schwindegg",
  "Latitude": "48.271.620",
  "Longitude": "12.268.400",
  "Telephone": "+49-8082-8029",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.27162,12.2684",
  "id": "48.27162,12.2684"
}, {
  "Brand": "ENI",
  "Street": "Eichendorffstr. 20",
  "PostCode": 85609,
  "City": "Aschheim",
  "Latitude": "48.165.184",
  "Longitude": "11.720.583",
  "Telephone": "+49 (0) 89909375",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.165184,11.720583",
  "id": "48.165184,11.720583"
}, {
  "Brand": "TOTAL",
  "Street": "AN DER BUNDESSTR. 19",
  "PostCode": 97234,
  "City": "REICHENBERG/ALBERTSHAUSEN",
  "Latitude": "49.691.981",
  "Longitude": "09.941.940",
  "Telephone": "09334-355",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.691981,9.94194",
  "id": "49.691981,9.94194"
}, {
  "Brand": "ARAL",
  "Street": "A7",
  "PostCode": 97262,
  "City": "Hausen bei Würzburg",
  "Latitude": "49.946.846",
  "Longitude": "10.021.016",
  "Telephone": "0936390820",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.946846,10.021016",
  "id": "49.946846,10.021016"
}, {
  "Brand": "ARAL",
  "Street": "Manfred-Roth-Straße 3",
  "PostCode": 18196,
  "City": "Dummerstorf",
  "Latitude": "54.021.701",
  "Longitude": "12.231.856",
  "Telephone": "038208828266",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "54.021701,12.231856",
  "id": "54.021701,12.231856"
}, {
  "Brand": "TOTAL",
  "Street": "MAINBERGER STR. 22",
  "PostCode": 97422,
  "City": "SCHWEINFURT",
  "Latitude": "50.048.764",
  "Longitude": "10.245.451",
  "Telephone": "09721-3709616",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.048764,10.245451",
  "id": "50.048764,10.245451"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER STR. 54 A",
  "PostCode": 38104,
  "City": "BRAUNSCHWEIG",
  "Latitude": "52.280.475",
  "Longitude": "10.573.746",
  "Telephone": "0531-374141",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.280475,10.573746",
  "id": "52.280475,10.573746"
}, {
  "Brand": "ARAL",
  "Street": "Marie-Curie-Straße 2-6",
  "PostCode": 46446,
  "City": "Emmerich",
  "Latitude": "51.842.421",
  "Longitude": "06.275.239",
  "Telephone": "0282297016",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.842421,6.275239",
  "id": "51.842421,6.275239"
}, {
  "Brand": "ENI",
  "Street": "Langenwahler Str. 19",
  "PostCode": 15517,
  "City": "Fuerstenwalde",
  "Latitude": "52.351.283",
  "Longitude": "14.072.318",
  "Telephone": "+49 (0) 33615020",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.351283,14.072318",
  "id": "52.351283,14.072318"
}, {
  "Brand": "ARAL",
  "Street": "Ingolstädter Straße 59",
  "PostCode": 80939,
  "City": "München",
  "Latitude": "48.192.959",
  "Longitude": "11.583.640",
  "Telephone": "0893185530",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.192959,11.58364",
  "id": "48.192959,11.58364"
}, {
  "Brand": "TOTAL",
  "Street": "ANDREAS MEYER STRASSE 63",
  "PostCode": 22113,
  "City": "HAMBURG",
  "Latitude": "53.512.350",
  "Longitude": "10.088.850",
  "Telephone": "040-53022628",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.51235,10.08885",
  "id": "53.51235,10.08885"
}, {
  "Brand": "TOTAL",
  "Street": "DUERENER STR. 38",
  "PostCode": 52372,
  "City": "KREUZAU",
  "Latitude": "50.748.786",
  "Longitude": "06.490.989",
  "Telephone": "02422-503234",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.748786,6.490989",
  "id": "50.748786,6.490989"
}, {
  "Brand": "OMV",
  "Street": "Straubinger Str. 9",
  "PostCode": 94405,
  "City": "Landau",
  "Latitude": "48.676.980",
  "Longitude": "12.689.420",
  "Telephone": "+49-9951-590121",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.67698,12.68942",
  "id": "48.67698,12.68942"
}, {
  "Brand": "OMV",
  "Street": "Sudetenstr. 67",
  "PostCode": 82538,
  "City": "Geretsried",
  "Latitude": "47.853.580",
  "Longitude": "11.494.940",
  "Telephone": "+49-8171-90547",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.85358,11.49494",
  "id": "47.85358,11.49494"
}, {
  "Brand": "TOTAL",
  "Street": "CUXHAVENER STR. 380",
  "PostCode": 21149,
  "City": "HAMBURG",
  "Latitude": "53.473.037",
  "Longitude": "09.846.765",
  "Telephone": "040-70380510",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.473037,9.846765",
  "id": "53.473037,9.846765"
}, {
  "Brand": "TOTAL",
  "Street": "SONNEBERGER STR. 86",
  "PostCode": 96523,
  "City": "STEINACH",
  "Latitude": "50.422.285",
  "Longitude": "11.166.052",
  "Telephone": "036762-31911",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.422285,11.166052",
  "id": "50.422285,11.166052"
}, {
  "Brand": "Westfalen",
  "Street": "Raesfelder Str. 24",
  "PostCode": 46325,
  "City": "BORKEN",
  "Latitude": "51.838.500",
  "Longitude": "06.858.220",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8385,6.85822",
  "id": "51.8385,6.85822"
}, {
  "Brand": "TOTAL",
  "Street": "BAMBERGER STR. 6",
  "PostCode": 96117,
  "City": "MEMMELSDORF",
  "Latitude": "49.931.393",
  "Longitude": "10.950.915",
  "Telephone": "0951-44291",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.931393,10.950915",
  "id": "49.931393,10.950915"
}, {
  "Brand": "TOTAL",
  "Street": "MUECHELNER STR. 04",
  "PostCode": 6242,
  "City": "BRAUNSBEDRA",
  "Latitude": "51.283.730",
  "Longitude": "11.875.212",
  "Telephone": "034633-22213",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.28373,11.875212",
  "id": "51.28373,11.875212"
}, {
  "Brand": "TOTAL",
  "Street": "HAUPTSTR. 56",
  "PostCode": 31860,
  "City": "EMMERTHAL",
  "Latitude": "52.048.264",
  "Longitude": "09.389.950",
  "Telephone": "05155-9199900",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.048264,9.38995",
  "id": "52.048264,9.38995"
}, {
  "Brand": "ARAL",
  "Street": "Schmidener Straße 255",
  "PostCode": 70374,
  "City": "Stuttgart",
  "Latitude": "48.819.759",
  "Longitude": "09.243.494",
  "Telephone": "07116529222",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.819759,9.243494",
  "id": "48.819759,9.243494"
}, {
  "Brand": "TOTAL",
  "Street": "CALWER STR. 304",
  "PostCode": 72218,
  "City": "WILDBERG",
  "Latitude": "48.636.672",
  "Longitude": "08.746.805",
  "Telephone": "07054-365",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.636672,8.746805",
  "id": "48.636672,8.746805"
}, {
  "Brand": "TOTAL",
  "Street": "AHMSER STR. 95",
  "PostCode": 32052,
  "City": "HERFORD",
  "Latitude": "52.102.355",
  "Longitude": "08.682.120",
  "Telephone": "05221-71087",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.102355,8.68212",
  "id": "52.102355,8.68212"
}, {
  "Brand": "ARAL",
  "Street": "Lindigstraße 6",
  "PostCode": 63801,
  "City": "Kleinostheim",
  "Latitude": "50.024.415",
  "Longitude": "09.041.651",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.024415,9.041651",
  "id": "50.024415,9.041651"
}, {
  "Brand": "TOTAL",
  "Street": "WERNBERGER STR. 34",
  "PostCode": 92536,
  "City": "PFREIMD",
  "Latitude": "49.499.364",
  "Longitude": "12.184.555",
  "Telephone": "09606-91277",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.499364,12.184555",
  "id": "49.499364,12.184555"
}, {
  "Brand": "TOTAL",
  "Street": "AM PLATZ DER JUGEND 21",
  "PostCode": 6406,
  "City": "BERNBURG",
  "Latitude": "51.804.784",
  "Longitude": "11.737.749",
  "Telephone": "03471-350353",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.804784,11.737749",
  "id": "51.804784,11.737749"
}, {
  "Brand": "TOTAL",
  "Street": "WEIMARISCHE STR. 31",
  "PostCode": 99438,
  "City": "BAD BERKA",
  "Latitude": "50.903.827",
  "Longitude": "11.278.862",
  "Telephone": "036458-42107",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.903827,11.278862",
  "id": "50.903827,11.278862"
}, {
  "Brand": "Westfalen",
  "Street": "Schullendamm 63",
  "PostCode": 49716,
  "City": "MEPPEN",
  "Latitude": "52.691.600",
  "Longitude": "07.272.880",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.6916,7.27288",
  "id": "52.6916,7.27288"
}, {
  "Brand": "OMV",
  "Street": "Bayreuther Str. 2",
  "PostCode": 95615,
  "City": "Marktredwitz",
  "Latitude": "50.003.060",
  "Longitude": "12.077.910",
  "Telephone": "+49-9231-81106",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.00306,12.07791",
  "id": "50.00306,12.07791"
}, {
  "Brand": "TOTAL",
  "Street": "BISMARKER STR.82",
  "PostCode": 39606,
  "City": "OSTERBURG",
  "Latitude": "52.787.241",
  "Longitude": "11.742.624",
  "Telephone": "03937-82928",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.787241,11.742624",
  "id": "52.787241,11.742624"
}, {
  "Brand": "Westfalen",
  "Street": "Grevener Str. 395",
  "PostCode": 48159,
  "City": "MUENSTER",
  "Latitude": "52.001.500",
  "Longitude": "07.614.210",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0015,7.61421",
  "id": "52.0015,7.61421"
}, {
  "Brand": "ENI",
  "Street": "Mengener Str. 37",
  "PostCode": 88605,
  "City": "Messkirch",
  "Latitude": "47.993.060",
  "Longitude": "09.117.260",
  "Telephone": "+49 (0) 75759263",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.99306,9.11726",
  "id": "47.99306,9.11726"
}, {
  "Brand": "Westfalen",
  "Street": "Muensterstr. 14",
  "PostCode": 49219,
  "City": "GLANDORF",
  "Latitude": "52.081.400",
  "Longitude": "07.998.600",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0814,7.9986",
  "id": "52.0814,7.9986"
}, {
  "Brand": "ENI",
  "Street": "Paunsdorfer Str. 70",
  "PostCode": 4316,
  "City": "Leipzig",
  "Latitude": "51.342.360",
  "Longitude": "12.445.190",
  "Telephone": "+49 (0) 34165151",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.34236,12.44519",
  "id": "51.34236,12.44519"
}, {
  "Brand": "ARAL",
  "Street": "Gernotstr. 3",
  "PostCode": 44319,
  "City": "Dortmund",
  "Latitude": "51.532.055",
  "Longitude": "07.628.690",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.532055,7.62869",
  "id": "51.532055,7.62869"
}, {
  "Brand": "ENI",
  "Street": "Gangkofener Str. 73",
  "PostCode": 84160,
  "City": "Frontenhausen",
  "Latitude": "48.545.164",
  "Longitude": "12.528.439",
  "Telephone": "+49 (0) 87321349",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.545164,12.528439",
  "id": "48.545164,12.528439"
}, {
  "Brand": "ENI",
  "Street": "Roemerstrasse 76-78",
  "PostCode": 68623,
  "City": "Lampertheim",
  "Latitude": "49.592.400",
  "Longitude": "08.472.000",
  "Telephone": "+49 (0) 62069109",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.5924,8.472",
  "id": "49.5924,8.472"
}, {
  "Brand": "ENI",
  "Street": "Further Strasse 8",
  "PostCode": 93413,
  "City": "Cham",
  "Latitude": "49.223.730",
  "Longitude": "12.670.870",
  "Telephone": "+49 (0) 99719270",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.22373,12.67087",
  "id": "49.22373,12.67087"
}, {
  "Brand": "ARAL",
  "Street": "Cloppenburger Straße 239",
  "PostCode": 26133,
  "City": "Oldenburg",
  "Latitude": "53.116.574",
  "Longitude": "08.214.318",
  "Telephone": "044142648",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "53.116574,8.214318",
  "id": "53.116574,8.214318"
}, {
  "Brand": "ARAL",
  "Street": "Oberstdorfer Straße 13",
  "PostCode": 87435,
  "City": "Kempten",
  "Latitude": "47.706.130",
  "Longitude": "10.311.661",
  "Telephone": "0831523530",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "47.70613,10.311661",
  "id": "47.70613,10.311661"
}, {
  "Brand": "ARAL",
  "Street": "Junkersstraße",
  "PostCode": 4435,
  "City": "Schkeuditz-Glesien",
  "Latitude": "51.444.067",
  "Longitude": "12.239.132",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.444067,12.239132",
  "id": "51.444067,12.239132"
}, {
  "Brand": "TOTAL",
  "Street": "SCHWERINSTR. 9-12",
  "PostCode": 3149,
  "City": "FORST",
  "Latitude": "51.729.037",
  "Longitude": "14.628.644",
  "Telephone": "03562-8334",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.729037,14.628644",
  "id": "51.729037,14.628644"
}, {
  "Brand": "ARAL",
  "Street": "Ringstraße 3",
  "PostCode": 4827,
  "City": "Gerichshain",
  "Latitude": "51.355.542",
  "Longitude": "12.569.214",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.355542,12.569214",
  "id": "51.355542,12.569214"
}, {
  "Brand": "OMV",
  "Street": "Augsburger Str. 42",
  "PostCode": 86720,
  "City": "Nördlingen",
  "Latitude": "48.845.200",
  "Longitude": "10.499.900",
  "Telephone": "+49-9081-22128",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.8452,10.4999",
  "id": "48.8452,10.4999"
}, {
  "Brand": "ARAL",
  "Street": "Am alten Bahnhof 1",
  "PostCode": 51645,
  "City": "Gummersbach",
  "Latitude": "51.003.345",
  "Longitude": "07.575.883",
  "Telephone": "02261789440",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.003345,7.575883",
  "id": "51.003345,7.575883"
}, {
  "Brand": "Westfalen",
  "Street": "Sachsenstr. 149",
  "PostCode": 45665,
  "City": "RECKLINGHAUSEN",
  "Latitude": "51.604.300",
  "Longitude": "07.273.290",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.6043,7.27329",
  "id": "51.6043,7.27329"
}, {
  "Brand": "TOTAL",
  "Street": "SCHOELLERSTR. 155",
  "PostCode": 52351,
  "City": "DUEREN",
  "Latitude": "50.813.242",
  "Longitude": "06.488.431",
  "Telephone": "02421-14252",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.813242,6.488431",
  "id": "50.813242,6.488431"
}, {
  "Brand": "ENI",
  "Street": "Traunsteiner Strasse 19",
  "PostCode": 83313,
  "City": "Siegsdorf",
  "Latitude": "47.825.001",
  "Longitude": "12.638.131",
  "Telephone": "+49 (0) 86626682",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.825001,12.638131",
  "id": "47.825001,12.638131"
}, {
  "Brand": "ARAL",
  "Street": "Saarbrücker Straße 7 c",
  "PostCode": 66292,
  "City": "Riegelsberg",
  "Latitude": "49.297.540",
  "Longitude": "06.947.970",
  "Telephone": "0680644748",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.29754,6.94797",
  "id": "49.29754,6.94797"
}, {
  "Brand": "ARAL",
  "Street": "Neue Regensburger Straße 37",
  "PostCode": 84030,
  "City": "Ergolding",
  "Latitude": "48.558.857",
  "Longitude": "12.165.483",
  "Telephone": "087197471620",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.558857,12.165483",
  "id": "48.558857,12.165483"
}, {
  "Brand": "TOTAL",
  "Street": "TROSSINGER STR. 41",
  "PostCode": 78609,
  "City": "TUNINGEN",
  "Latitude": "48.034.742",
  "Longitude": "08.609.986",
  "Telephone": "07464-529989-0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.034742,8.609986",
  "id": "48.034742,8.609986"
}, {
  "Brand": "ARAL",
  "Street": "A 3 / Autobahnraststätte Ohligser H",
  "PostCode": 42697,
  "City": "Solingen",
  "Latitude": "51.158.986",
  "Longitude": "06.961.083",
  "Telephone": "0212645370",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.158986,6.961083",
  "id": "51.158986,6.961083"
}, {
  "Brand": "OMV",
  "Street": "Landshuter Strasse 61",
  "PostCode": 84137,
  "City": "Vilsbiburg",
  "Latitude": "48.454.490",
  "Longitude": "12.334.710",
  "Telephone": "+49-8741-967284",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.45449,12.33471",
  "id": "48.45449,12.33471"
}, {
  "Brand": "Westfalen",
  "Street": "Herner Strasse 249",
  "PostCode": 45659,
  "City": "RECKLINGHAUSEN",
  "Latitude": "51.583.400",
  "Longitude": "07.212.600",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.5834,7.2126",
  "id": "51.5834,7.2126"
}, {
  "Brand": "ENI",
  "Street": "Spremberger Str.8/ B169 (Gewerbegeb",
  "PostCode": 3116,
  "City": "Drebkau",
  "Latitude": "51.646.239",
  "Longitude": "14.237.595",
  "Telephone": "+49 (0) 35602510",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.646239,14.237595",
  "id": "51.646239,14.237595"
}, {
  "Brand": "ARAL",
  "Street": "Wilhelm-Hauff-Straße 43",
  "PostCode": 74906,
  "City": "Bad Rappenau",
  "Latitude": "49.210.829",
  "Longitude": "09.070.105",
  "Telephone": "07066915353",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.210829,9.070105",
  "id": "49.210829,9.070105"
}, {
  "Brand": "ENI",
  "Street": "Rothenburger Strasse 32",
  "PostCode": 90522,
  "City": "Oberasbach",
  "Latitude": "49.435.940",
  "Longitude": "10.977.380",
  "Telephone": "+49 (0) 91169221",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.43594,10.97738",
  "id": "49.43594,10.97738"
}, {
  "Brand": "OMV",
  "Street": "Mitterteicher Strasse 11",
  "PostCode": 95643,
  "City": "Tirschenreuth",
  "Latitude": "49.882.320",
  "Longitude": "12.326.480",
  "Telephone": "+49-9631-1706",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.88232,12.32648",
  "id": "49.88232,12.32648"
}, {
  "Brand": "ENI",
  "Street": "Buergermeister-Schlosser-Str. 2",
  "PostCode": 86199,
  "City": "Augsburg",
  "Latitude": "48.318.780",
  "Longitude": "10.861.280",
  "Telephone": "0821/99863460",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.31878,10.86128",
  "id": "48.31878,10.86128"
}, {
  "Brand": "OMV",
  "Street": "Wiesbadener Str. 43",
  "PostCode": 90427,
  "City": "Nürnberg",
  "Latitude": "49.522.860",
  "Longitude": "11.007.140",
  "Telephone": "+49-911-9301099",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.52286,11.00714",
  "id": "49.52286,11.00714"
}, {
  "Brand": "ARAL",
  "Street": "Rudolf-Diesel-Str.",
  "PostCode": 34123,
  "City": "Fuldabrueck",
  "Latitude": "51.270.920",
  "Longitude": "09.517.312",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.27092,9.517312",
  "id": "51.27092,9.517312"
}, {
  "Brand": "Westfalen",
  "Street": "Astruper Str. 168",
  "PostCode": 26203,
  "City": "BAT HUNTETAL WEST WARDENBURG",
  "Latitude": "53.052.500",
  "Longitude": "08.206.100",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.0525,8.2061",
  "id": "53.0525,8.2061"
}, {
  "Brand": "ENI",
  "Street": "Gelnhaeuser Str. 1-3",
  "PostCode": 63517,
  "City": "Rodenbach",
  "Latitude": "50.147.296",
  "Longitude": "09.025.037",
  "Telephone": "+49 (0) 61845050",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.147296,9.025037",
  "id": "50.147296,9.025037"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Straße 7a",
  "PostCode": 53773,
  "City": "Hennef",
  "Latitude": "50.771.064",
  "Longitude": "07.295.923",
  "Telephone": "",
  "WeekDayFrom": "05:45",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "05:45",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "50.771064,7.295923",
  "id": "50.771064,7.295923"
}, {
  "Brand": "Westfalen",
  "Street": "Wanheimer Str. 225",
  "PostCode": 47053,
  "City": "DUISBURG",
  "Latitude": "51.243.516",
  "Longitude": "06.450.886",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.243516,6.450886",
  "id": "51.243516,6.450886"
}, {
  "Brand": "TOTAL",
  "Street": "ROENTGENSTR. 1",
  "PostCode": 63526,
  "City": "ERLENSEE",
  "Latitude": "50.182.387",
  "Longitude": "09.007.049",
  "Telephone": "06183-8007810",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.182387,9.007049",
  "id": "50.182387,9.007049"
}, {
  "Brand": "Westfalen",
  "Street": "Hamelner Strasse 26",
  "PostCode": 32657,
  "City": "LEMGO",
  "Latitude": "52.026.000",
  "Longitude": "08.916.490",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.026,8.91649",
  "id": "52.026,8.91649"
}, {
  "Brand": "ENI",
  "Street": "Tuerkenfelder Strasse 2",
  "PostCode": 82272,
  "City": "Moorenweis",
  "Latitude": "48.146.940",
  "Longitude": "11.072.820",
  "Telephone": "081469979172",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.14694,11.07282",
  "id": "48.14694,11.07282"
}, {
  "Brand": "TOTAL",
  "Street": "FRANKFURTER STR. 92",
  "PostCode": 65830,
  "City": "KRIFTEL",
  "Latitude": "50.088.748",
  "Longitude": "08.477.606",
  "Telephone": "06192-46296",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.088748,8.477606",
  "id": "50.088748,8.477606"
}, {
  "Brand": "TOTAL",
  "Street": "UNTERSTRASSE 100",
  "PostCode": 44892,
  "City": "BOCHUM",
  "Latitude": "51.469.916",
  "Longitude": "07.306.949",
  "Telephone": "0234-9272073",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.469916,7.306949",
  "id": "51.469916,7.306949"
}, {
  "Brand": "ARAL",
  "Street": "Martin-Schleyer-Straße 6",
  "PostCode": 78465,
  "City": "Konstanz",
  "Latitude": "47.712.323",
  "Longitude": "09.173.429",
  "Telephone": "0",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.712323,9.173429",
  "id": "47.712323,9.173429"
}, {
  "Brand": "ENI",
  "Street": "Landstr. 3",
  "PostCode": 84364,
  "City": "Bad Birnbach",
  "Latitude": "48.447.490",
  "Longitude": "13.040.080",
  "Telephone": "08563/825",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.44749,13.04008",
  "id": "48.44749,13.04008"
}, {
  "Brand": "ARAL",
  "Street": "Ferdinand-Porsche-Ring 18",
  "PostCode": 63110,
  "City": "Rodgau",
  "Latitude": "50.019.379",
  "Longitude": "08.894.631",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.019379,8.894631",
  "id": "50.019379,8.894631"
}, {
  "Brand": "ARAL",
  "Street": "Schwere-Reiter-Straße 26",
  "PostCode": 80797,
  "City": "München",
  "Latitude": "48.161.003",
  "Longitude": "11.558.504",
  "Telephone": "089188503",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.161003,11.558504",
  "id": "48.161003,11.558504"
}, {
  "Brand": "TOTAL",
  "Street": "AM KESSELTAL 4",
  "PostCode": 55129,
  "City": "MAINZ-EBERSHEIM",
  "Latitude": "49.922.365",
  "Longitude": "08.265.815",
  "Telephone": "06136-954195",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.922365,8.265815",
  "id": "49.922365,8.265815"
}, {
  "Brand": "TOTAL",
  "Street": "BAUTZNER STR. 72",
  "PostCode": 1099,
  "City": "DRESDEN",
  "Latitude": "51.064.376",
  "Longitude": "13.763.571",
  "Telephone": "0351-8049180",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.064376,13.763571",
  "id": "51.064376,13.763571"
}, {
  "Brand": "OMV",
  "Street": "Heiligenfelder Allee 2",
  "PostCode": 97688,
  "City": "Bad Kissingen",
  "Latitude": "50.191.380",
  "Longitude": "10.061.720",
  "Telephone": "+49-971-7852506",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.19138,10.06172",
  "id": "50.19138,10.06172"
}, {
  "Brand": "ARAL",
  "Street": "Frankfurter Straße 102 A",
  "PostCode": 63500,
  "City": "Seligenstadt",
  "Latitude": "50.045.104",
  "Longitude": "08.961.201",
  "Telephone": "061828206288",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.045104,8.961201",
  "id": "50.045104,8.961201"
}, {
  "Brand": "ARAL",
  "Street": "Walther-Rathenau-Allee 24",
  "PostCode": 64720,
  "City": "Michelstadt",
  "Latitude": "49.691.073",
  "Longitude": "09.003.704",
  "Telephone": "015161573988",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "49.691073,9.003704",
  "id": "49.691073,9.003704"
}, {
  "Brand": "ARAL",
  "Street": "Österreicher Straße 64",
  "PostCode": 1279,
  "City": "Dresden",
  "Latitude": "51.021.001",
  "Longitude": "13.840.501",
  "Telephone": "03512514937",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.021001,13.840501",
  "id": "51.021001,13.840501"
}, {
  "Brand": "OMV",
  "Street": "Alte Str. 39",
  "PostCode": 94034,
  "City": "Passau",
  "Latitude": "48.587.610",
  "Longitude": "13.482.780",
  "Telephone": "+49-851-42985",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.58761,13.48278",
  "id": "48.58761,13.48278"
}, {
  "Brand": "TOTAL",
  "Street": "KAPELLENSTR. 29",
  "PostCode": 50997,
  "City": "KOELN RONDORF",
  "Latitude": "50.874.529",
  "Longitude": "06.949.756",
  "Telephone": "02233-23640",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.874529,6.949756",
  "id": "50.874529,6.949756"
}, {
  "Brand": "TOTAL",
  "Street": "ALTENDERNER STR.90",
  "PostCode": 44329,
  "City": "DORTMUND",
  "Latitude": "51.569.289",
  "Longitude": "07.511.427",
  "Telephone": "0231-7289051",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.569289,7.511427",
  "id": "51.569289,7.511427"
}, {
  "Brand": "ARAL",
  "Street": "Siemensstraße 9",
  "PostCode": 71263,
  "City": "Weil der Stadt",
  "Latitude": "48.756.123",
  "Longitude": "08.867.436",
  "Telephone": "070336005",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.756123,8.867436",
  "id": "48.756123,8.867436"
}, {
  "Brand": "ENI",
  "Street": "Tuebinger Str. 26",
  "PostCode": 72108,
  "City": "Rottenburg",
  "Latitude": "48.473.320",
  "Longitude": "08.938.710",
  "Telephone": "+49 (0) 74729475",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.47332,8.93871",
  "id": "48.47332,8.93871"
}, {
  "Brand": "ARAL",
  "Street": "Hallerstraße 1",
  "PostCode": 66333,
  "City": "Völklingen-Wehrden",
  "Latitude": "49.243.826",
  "Longitude": "06.839.780",
  "Telephone": "0689829375",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.243826,6.83978",
  "id": "49.243826,6.83978"
}, {
  "Brand": "OMV",
  "Street": "Christian-Seltmann-Str. 36",
  "PostCode": 92637,
  "City": "Weiden",
  "Latitude": "49.680.010",
  "Longitude": "12.152.900",
  "Telephone": "+49-961-22329",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.68001,12.1529",
  "id": "49.68001,12.1529"
}, {
  "Brand": "ARAL",
  "Street": "Hörnleinsgrund 1",
  "PostCode": 96450,
  "City": "Coburg",
  "Latitude": "50.267.603",
  "Longitude": "10.947.755",
  "Telephone": "0956130721",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.267603,10.947755",
  "id": "50.267603,10.947755"
}, {
  "Brand": "Westfalen",
  "Street": "Steinfurter Str. 166",
  "PostCode": 48159,
  "City": "MUENSTER",
  "Latitude": "51.976.700",
  "Longitude": "07.601.320",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9767,7.60132",
  "id": "51.9767,7.60132"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER ALLEE 12",
  "PostCode": 63225,
  "City": "LANGEN",
  "Latitude": "49.992.269",
  "Longitude": "08.649.521",
  "Telephone": "06103-71010",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.992269,8.649521",
  "id": "49.992269,8.649521"
}, {
  "Brand": "ARAL",
  "Street": "Zeller Straße 15",
  "PostCode": 73235,
  "City": "Weilheim",
  "Latitude": "48.620.371",
  "Longitude": "09.540.233",
  "Telephone": "070239564184",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.620371,9.540233",
  "id": "48.620371,9.540233"
}, {
  "Brand": "ENI",
  "Street": "Heilbronner Str. 75",
  "PostCode": 73635,
  "City": "Rudersberg",
  "Latitude": "48.878.640",
  "Longitude": "09.530.080",
  "Telephone": "+49 (0) 71833022",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.87864,9.53008",
  "id": "48.87864,9.53008"
}, {
  "Brand": "ARAL",
  "Street": "Bierweg 6",
  "PostCode": 4435,
  "City": "Schkeuditz",
  "Latitude": "51.398.006",
  "Longitude": "12.188.955",
  "Telephone": "03420465724",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.398006,12.188955",
  "id": "51.398006,12.188955"
}, {
  "Brand": "TOTAL",
  "Street": "UBIERSTR. 8",
  "PostCode": 53173,
  "City": "BONN",
  "Latitude": "50.694.603",
  "Longitude": "07.161.795",
  "Telephone": "0228-363298",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.694603,7.161795",
  "id": "50.694603,7.161795"
}, {
  "Brand": "TOTAL",
  "Street": "HAUPTSTR. 279",
  "PostCode": 44892,
  "City": "BOCHUM-LANGENDREER",
  "Latitude": "51.464.658",
  "Longitude": "07.321.221",
  "Telephone": "0234-286628",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.464658,7.321221",
  "id": "51.464658,7.321221"
}, {
  "Brand": "TOTAL",
  "Street": "AM KEESBOOM 2",
  "PostCode": 19258,
  "City": "BOIZENBURG",
  "Latitude": "53.385.834",
  "Longitude": "10.735.236",
  "Telephone": "038847-38152",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.385834,10.735236",
  "id": "53.385834,10.735236"
}, {
  "Brand": "ARAL",
  "Street": "Düsseldorfer Straße 491",
  "PostCode": 51061,
  "City": "Köln",
  "Latitude": "50.989.542",
  "Longitude": "06.998.822",
  "Telephone": "0221664829",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.989542,6.998822",
  "id": "50.989542,6.998822"
}, {
  "Brand": "TOTAL",
  "Street": "FUERSTENBERGER STR. 1-3",
  "PostCode": 33142,
  "City": "BUEREN",
  "Latitude": "51.548.447",
  "Longitude": "08.563.832",
  "Telephone": "02951-2902",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.548447,8.563832",
  "id": "51.548447,8.563832"
}, {
  "Brand": "ARAL",
  "Street": "Alpenstraße 5",
  "PostCode": 82362,
  "City": "Weilheim",
  "Latitude": "47.835.671",
  "Longitude": "11.143.747",
  "Telephone": "0881417593",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.835671,11.143747",
  "id": "47.835671,11.143747"
}, {
  "Brand": "ENI",
  "Street": "Wunsiedler Str. 11",
  "PostCode": 95032,
  "City": "Hof",
  "Latitude": "50.304.739",
  "Longitude": "11.919.686",
  "Telephone": "+49 (0) 92819701",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.304739,11.919686",
  "id": "50.304739,11.919686"
}, {
  "Brand": "TOTAL",
  "Street": "KEMNADER-STR. 14",
  "PostCode": 44795,
  "City": "BOCHUM",
  "Latitude": "51.439.863",
  "Longitude": "07.214.262",
  "Telephone": "0234-472286",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.439863,7.214262",
  "id": "51.439863,7.214262"
}, {
  "Brand": "TOTAL",
  "Street": "KARLSRUHER STR. 61A",
  "PostCode": 76351,
  "City": "LINKENHEIM-HOCHSTETTEN",
  "Latitude": "49.125.293",
  "Longitude": "08.408.952",
  "Telephone": "07247-4271",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.125293,8.408952",
  "id": "49.125293,8.408952"
}, {
  "Brand": "TOTAL",
  "Street": "AUTOHOF A14 POSTSTRASSE 3",
  "PostCode": 4158,
  "City": "LEIPZIG",
  "Latitude": "51.413.589",
  "Longitude": "12.308.158",
  "Telephone": "0341-4616662",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.413589,12.308158",
  "id": "51.413589,12.308158"
}, {
  "Brand": "ARAL",
  "Street": "Illertisser Straße 40",
  "PostCode": 89165,
  "City": "Dietenheim",
  "Latitude": "48.210.491",
  "Longitude": "10.077.365",
  "Telephone": "073477593",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.210491,10.077365",
  "id": "48.210491,10.077365"
}, {
  "Brand": "TOTAL",
  "Street": "BAMBERGER STR. 59/61",
  "PostCode": 91456,
  "City": "DIESPECK",
  "Latitude": "49.600.769",
  "Longitude": "10.625.707",
  "Telephone": "09161-885858",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.600769,10.625707",
  "id": "49.600769,10.625707"
}, {
  "Brand": "ARAL",
  "Street": "Günzburger Straße 23",
  "PostCode": 89362,
  "City": "Offingen",
  "Latitude": "48.478.521",
  "Longitude": "10.363.052",
  "Telephone": "08224967490",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.478521,10.363052",
  "id": "48.478521,10.363052"
}, {
  "Brand": "OMV",
  "Street": "Pörnbacher Str. 2",
  "PostCode": 86558,
  "City": "Hohenwart",
  "Latitude": "48.602.310",
  "Longitude": "11.423.850",
  "Telephone": "+49-8443-385",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.60231,11.42385",
  "id": "48.60231,11.42385"
}, {
  "Brand": "ARAL",
  "Street": "BAB A 19 - West",
  "PostCode": 18276,
  "City": "Recknitz",
  "Latitude": "53.840.037",
  "Longitude": "12.291.249",
  "Telephone": "03845520962",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.840037,12.291249",
  "id": "53.840037,12.291249"
}, {
  "Brand": "OMV",
  "Street": "Albrecht-Dürer-Str. 1/2",
  "PostCode": 95448,
  "City": "Bayreuth",
  "Latitude": "49.946.140",
  "Longitude": "11.583.200",
  "Telephone": "+49-921-62122",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.94614,11.5832",
  "id": "49.94614,11.5832"
}, {
  "Brand": "Westfalen",
  "Street": "Am alten Bahnhof",
  "PostCode": 52457,
  "City": "ALDENHOVEN",
  "Latitude": "50.890.900",
  "Longitude": "06.275.120",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.8909,6.27512",
  "id": "50.8909,6.27512"
}, {
  "Brand": "TOTAL",
  "Street": "ALTER UENTROPER W.62",
  "PostCode": 59071,
  "City": "HAMM",
  "Latitude": "51.691.042",
  "Longitude": "07.869.494",
  "Telephone": "02381-80632",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.691042,7.869494",
  "id": "51.691042,7.869494"
}, {
  "Brand": "ARAL",
  "Street": "Dieselstraße 16",
  "PostCode": 84069,
  "City": "Schierling",
  "Latitude": "48.838.273",
  "Longitude": "12.123.005",
  "Telephone": "094519489202",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.838273,12.123005",
  "id": "48.838273,12.123005"
}, {
  "Brand": "Westfalen",
  "Street": "Wilhelmstr. 184",
  "PostCode": 59067,
  "City": "HAMM",
  "Latitude": "51.666.400",
  "Longitude": "07.787.810",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.6664,7.78781",
  "id": "51.6664,7.78781"
}, {
  "Brand": "ENI",
  "Street": "Welfenstrasse 32",
  "PostCode": 70599,
  "City": "Stuttgart",
  "Latitude": "48.719.580",
  "Longitude": "09.208.120",
  "Telephone": "+49 (0) 71145348",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.71958,9.20812",
  "id": "48.71958,9.20812"
}, {
  "Brand": "ARAL",
  "Street": "A3",
  "PostCode": 65549,
  "City": "Limburg",
  "Latitude": "50.385.351",
  "Longitude": "08.080.848",
  "Telephone": "0643171026",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.385351,8.080848",
  "id": "50.385351,8.080848"
}, {
  "Brand": "ENI",
  "Street": "Ansbacher Strasse 34",
  "PostCode": 91575,
  "City": "Windsbach",
  "Latitude": "49.245.914",
  "Longitude": "10.823.093",
  "Telephone": "+49 (0) 9871246",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.245914,10.823093",
  "id": "49.245914,10.823093"
}, {
  "Brand": "TOTAL",
  "Street": "IM RIEDLE 1",
  "PostCode": 74572,
  "City": "BLAUFELDEN",
  "Latitude": "49.295.903",
  "Longitude": "09.978.714",
  "Telephone": "07953-6249980",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.295903,9.978714",
  "id": "49.295903,9.978714"
}, {
  "Brand": "TOTAL",
  "Street": "JUETERBOGER CH. 8",
  "PostCode": 15936,
  "City": "DAHME",
  "Latitude": "51.868.746",
  "Longitude": "13.413.132",
  "Telephone": "035451-8308",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.868746,13.413132",
  "id": "51.868746,13.413132"
}, {
  "Brand": "ARAL",
  "Street": "Wolfegger Straße 2",
  "PostCode": 88267,
  "City": "Vogt",
  "Latitude": "47.775.632",
  "Longitude": "09.770.919",
  "Telephone": "07529912991",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.775632,9.770919",
  "id": "47.775632,9.770919"
}, {
  "Brand": "TOTAL",
  "Street": "GLINDENBERGER WEG 3",
  "PostCode": 39126,
  "City": "MAGDEBURG",
  "Latitude": "52.211.288",
  "Longitude": "11.670.514",
  "Telephone": "0391-59818744",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.211288,11.670514",
  "id": "52.211288,11.670514"
}, {
  "Brand": "ARAL",
  "Street": "Kurt-Schumacher-Damm 12",
  "PostCode": 49078,
  "City": "Osnabrück",
  "Latitude": "52.270.623",
  "Longitude": "08.024.833",
  "Telephone": "05414095309",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.270623,8.024833",
  "id": "52.270623,8.024833"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER CH.",
  "PostCode": 15749,
  "City": "MITTENWALDE",
  "Latitude": "52.270.299",
  "Longitude": "13.536.795",
  "Telephone": "033764-20389",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.270299,13.536795",
  "id": "52.270299,13.536795"
}, {
  "Brand": "Westfalen",
  "Street": "In den Bremen 48",
  "PostCode": 52152,
  "City": "SIMMERATH",
  "Latitude": "50.607.472",
  "Longitude": "06.312.293",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.607472,6.312293",
  "id": "50.607472,6.312293"
}, {
  "Brand": "TOTAL",
  "Street": "NORDRING 4",
  "PostCode": 6536,
  "City": "SUEDHARZ OT ROSSLA",
  "Latitude": "51.463.125",
  "Longitude": "11.055.666",
  "Telephone": "034651-2548",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.463125,11.055666",
  "id": "51.463125,11.055666"
}, {
  "Brand": "OMV",
  "Street": "Landsberger Straße 47 1/3",
  "PostCode": 86343,
  "City": "Königsbrunn",
  "Latitude": "48.256.790",
  "Longitude": "10.882.520",
  "Telephone": "+49-8231-347635",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.25679,10.88252",
  "id": "48.25679,10.88252"
}, {
  "Brand": "TOTAL",
  "Street": "VALDERSWEG 99",
  "PostCode": 52399,
  "City": "MERZENICH",
  "Latitude": "50.819.760",
  "Longitude": "06.532.959",
  "Telephone": "02421-393988",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.81976,6.532959",
  "id": "50.81976,6.532959"
}, {
  "Brand": "TOTAL",
  "Street": "SINZIGER STR. 15",
  "PostCode": 53424,
  "City": "REMAGEN",
  "Latitude": "50.572.904",
  "Longitude": "07.235.282",
  "Telephone": "02642-22045",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.572904,7.235282",
  "id": "50.572904,7.235282"
}, {
  "Brand": "TOTAL",
  "Street": "AMBERGER STRASSE 37",
  "PostCode": 92431,
  "City": "NEUNBURG V.W.",
  "Latitude": "49.346.111",
  "Longitude": "12.373.611",
  "Telephone": "09672-3493",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.346111,12.373611",
  "id": "49.346111,12.373611"
}, {
  "Brand": "TOTAL",
  "Street": "ARNSTAEDTER STR. 36",
  "PostCode": 99096,
  "City": "ERFURT",
  "Latitude": "50.962.852",
  "Longitude": "11.033.377",
  "Telephone": "0361-3460738",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.962852,11.033377",
  "id": "50.962852,11.033377"
}, {
  "Brand": "TOTAL",
  "Street": "WIESBADENER STR. 77",
  "PostCode": 65510,
  "City": "IDSTEIN",
  "Latitude": "50.216.822",
  "Longitude": "08.253.954",
  "Telephone": "06126-953888",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.216822,8.253954",
  "id": "50.216822,8.253954"
}, {
  "Brand": "OMV",
  "Street": "Friedrich-Eckart-Str. 39",
  "PostCode": 81929,
  "City": "München",
  "Latitude": "48.144.900",
  "Longitude": "11.639.510",
  "Telephone": "+49-89-931717",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.1449,11.63951",
  "id": "48.1449,11.63951"
}, {
  "Brand": "TOTAL",
  "Street": "CHEMNITZER STR. 28f",
  "PostCode": 9648,
  "City": "MITTWEIDA",
  "Latitude": "50.979.200",
  "Longitude": "12.981.795",
  "Telephone": "03727-611392",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.9792,12.981795",
  "id": "50.9792,12.981795"
}, {
  "Brand": "TOTAL",
  "Street": "USEDOMER STR. 1",
  "PostCode": 1968,
  "City": "SENFTENBERG",
  "Latitude": "51.509.960",
  "Longitude": "13.981.811",
  "Telephone": "03573-61085",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.50996,13.981811",
  "id": "51.50996,13.981811"
}, {
  "Brand": "ENI",
  "Street": "Haller Str. 210",
  "PostCode": 74564,
  "City": "Crailsheim-Rossfeld",
  "Latitude": "49.141.360",
  "Longitude": "10.060.940",
  "Telephone": "+49 (0) 79512297",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.14136,10.06094",
  "id": "49.14136,10.06094"
}, {
  "Brand": "TOTAL",
  "Street": "LEIPZIGER STR. 152",
  "PostCode": 14929,
  "City": "TREUENBRIETZEN",
  "Latitude": "52.087.426",
  "Longitude": "12.849.454",
  "Telephone": "033748-15429",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.087426,12.849454",
  "id": "52.087426,12.849454"
}, {
  "Brand": "ARAL",
  "Street": "Solmser Gewerbepark 4",
  "PostCode": 35606,
  "City": "Solms",
  "Latitude": "50.543.490",
  "Longitude": "08.411.122",
  "Telephone": "06442927192",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.54349,8.411122",
  "id": "50.54349,8.411122"
}, {
  "Brand": "ARAL",
  "Street": "Böttcherweg/Sattlerweg",
  "PostCode": 4158,
  "City": "Leipzig",
  "Latitude": "51.408.102",
  "Longitude": "12.303.432",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.408102,12.303432",
  "id": "51.408102,12.303432"
}, {
  "Brand": "ARAL",
  "Street": "Lange Göhren",
  "PostCode": 39171,
  "City": "Osterweddingen",
  "Latitude": "52.051.212",
  "Longitude": "11.600.594",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.051212,11.600594",
  "id": "52.051212,11.600594"
}, {
  "Brand": "TOTAL",
  "Street": "WORPHAUSER LANDSTR. 38",
  "PostCode": 28865,
  "City": "LILIENTHAL",
  "Latitude": "53.199.154",
  "Longitude": "08.935.516",
  "Telephone": "04792-688",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.199154,8.935516",
  "id": "53.199154,8.935516"
}, {
  "Brand": "TOTAL",
  "Street": "TROTHAER STRASSE 40",
  "PostCode": 6118,
  "City": "HALLE",
  "Latitude": "51.519.193",
  "Longitude": "11.955.040",
  "Telephone": "0345-5234122",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.519193,11.95504",
  "id": "51.519193,11.95504"
}, {
  "Brand": "ARAL",
  "Street": "A5",
  "PostCode": 61239,
  "City": "Ober-Mörlen",
  "Latitude": "50.357.601",
  "Longitude": "08.694.081",
  "Telephone": "06002939786",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.357601,8.694081",
  "id": "50.357601,8.694081"
}, {
  "Brand": "ARAL",
  "Street": "In der Quelle 74",
  "PostCode": 63303,
  "City": "Dreieich-Offenthal",
  "Latitude": "49.980.222",
  "Longitude": "08.736.530",
  "Telephone": "060748035221",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.980222,8.73653",
  "id": "49.980222,8.73653"
}, {
  "Brand": "Westfalen",
  "Street": "Heeper Str. 413",
  "PostCode": 33719,
  "City": "BIELEFELD",
  "Latitude": "52.031.900",
  "Longitude": "08.598.850",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0319,8.59885",
  "id": "52.0319,8.59885"
}, {
  "Brand": "ARAL",
  "Street": "Bliesaue 3",
  "PostCode": 66440,
  "City": "Blieskastel",
  "Latitude": "49.235.657",
  "Longitude": "07.269.783",
  "Telephone": "068429219763",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.235657,7.269783",
  "id": "49.235657,7.269783"
}, {
  "Brand": "Westfalen",
  "Street": "Detmolder 112",
  "PostCode": 33100,
  "City": "PADERBORN",
  "Latitude": "51.734.700",
  "Longitude": "08.766.900",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.7347,8.7669",
  "id": "51.7347,8.7669"
}, {
  "Brand": "OMV",
  "Street": "Industriestr. 16",
  "PostCode": 94315,
  "City": "Straubing",
  "Latitude": "48.873.430",
  "Longitude": "12.575.650",
  "Telephone": "+49-9421-55270",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.87343,12.57565",
  "id": "48.87343,12.57565"
}, {
  "Brand": "ENI",
  "Street": "Wuerzburger Str.9",
  "PostCode": 74731,
  "City": "Wallduern",
  "Latitude": "49.581.590",
  "Longitude": "09.374.500",
  "Telephone": "062829289646",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.58159,9.3745",
  "id": "49.58159,9.3745"
}, {
  "Brand": "ARAL",
  "Street": "A 44",
  "PostCode": 34289,
  "City": "Zierenberg",
  "Latitude": "51.381.799",
  "Longitude": "09.236.841",
  "Telephone": "0560656367981",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.381799,9.236841",
  "id": "51.381799,9.236841"
}, {
  "Brand": "ARAL",
  "Street": "Bonner Str. 49",
  "PostCode": 53919,
  "City": "Weilerswist",
  "Latitude": "50.749.863",
  "Longitude": "06.850.606",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.749863,6.850606",
  "id": "50.749863,6.850606"
}, {
  "Brand": "ARAL",
  "Street": "A3",
  "PostCode": 56412,
  "City": "Heiligenroth",
  "Latitude": "50.454.208",
  "Longitude": "07.866.816",
  "Telephone": "026029163484",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.454208,7.866816",
  "id": "50.454208,7.866816"
}, {
  "Brand": "ARAL",
  "Street": "Kraftsdorfer Straße 2",
  "PostCode": 7629,
  "City": "Hermsdorf",
  "Latitude": "50.889.295",
  "Longitude": "11.871.518",
  "Telephone": "03660154012",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.889295,11.871518",
  "id": "50.889295,11.871518"
}, {
  "Brand": "Westfalen",
  "Street": "Nadorster 287",
  "PostCode": 26125,
  "City": "OLDENBURG",
  "Latitude": "53.166.300",
  "Longitude": "08.226.720",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.1663,8.22672",
  "id": "53.1663,8.22672"
}, {
  "Brand": "ARAL",
  "Street": "Luisenstraße 77",
  "PostCode": 52477,
  "City": "Alsdorf",
  "Latitude": "50.874.326",
  "Longitude": "06.169.732",
  "Telephone": "024046777544",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.874326,6.169732",
  "id": "50.874326,6.169732"
}, {
  "Brand": "ENI",
  "Street": "Am Kletthamer Feld 2",
  "PostCode": 85435,
  "City": "Erding",
  "Latitude": "48.309.203",
  "Longitude": "11.883.366",
  "Telephone": "+49 (0) 81222274",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.309203,11.883366",
  "id": "48.309203,11.883366"
}, {
  "Brand": "OMV",
  "Street": "Aalener Str. 74",
  "PostCode": 73432,
  "City": "Aalen",
  "Latitude": "48.823.090",
  "Longitude": "10.121.020",
  "Telephone": "+49-7361-87254",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.82309,10.12102",
  "id": "48.82309,10.12102"
}, {
  "Brand": "OMV",
  "Street": "Erdinger Allee 2",
  "PostCode": 85356,
  "City": "München Flughafen",
  "Latitude": "48.359.720",
  "Longitude": "11.823.880",
  "Telephone": "+49-89-97051461",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.35972,11.82388",
  "id": "48.35972,11.82388"
}, {
  "Brand": "Westfalen",
  "Street": "Windelsbleicher 101",
  "PostCode": 33647,
  "City": "BIELEFELD",
  "Latitude": "51.976.600",
  "Longitude": "08.518.320",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9766,8.51832",
  "id": "51.9766,8.51832"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 41",
  "PostCode": 19230,
  "City": "Bandenitz",
  "Latitude": "53.491.340",
  "Longitude": "11.282.250",
  "Telephone": "0388505344",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.49134,11.28225",
  "id": "53.49134,11.28225"
}, {
  "Brand": "OMV",
  "Street": "Bernauer Str. 49",
  "PostCode": 83209,
  "City": "Prien",
  "Latitude": "47.849.370",
  "Longitude": "12.345.090",
  "Telephone": "+49-8051-9639722",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.84937,12.34509",
  "id": "47.84937,12.34509"
}, {
  "Brand": "TOTAL",
  "Street": "OSTERLANGE 1",
  "PostCode": 99189,
  "City": "ELXLEBEN",
  "Latitude": "51.048.005",
  "Longitude": "10.942.121",
  "Telephone": "036201-86263",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.048005,10.942121",
  "id": "51.048005,10.942121"
}, {
  "Brand": "OMV",
  "Street": "An der B36 101",
  "PostCode": 76287,
  "City": "Rheinstetten",
  "Latitude": "48.986.990",
  "Longitude": "08.333.290",
  "Telephone": "+49-721-510165",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.98699,8.33329",
  "id": "48.98699,8.33329"
}, {
  "Brand": "ENI",
  "Street": "Badenstr. 6",
  "PostCode": 76467,
  "City": "Bietigheim",
  "Latitude": "48.900.252",
  "Longitude": "08.249.042",
  "Telephone": "+49 (0) 72459381",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.900252,8.249042",
  "id": "48.900252,8.249042"
}, {
  "Brand": "TOTAL",
  "Street": "LOESSNITZER STR. 87",
  "PostCode": 8280,
  "City": "AUE",
  "Latitude": "50.596.535",
  "Longitude": "12.707.197",
  "Telephone": "03771-2765355",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.596535,12.707197",
  "id": "50.596535,12.707197"
}, {
  "Brand": "TOTAL",
  "Street": "DRESDENER STR. 95",
  "PostCode": 4758,
  "City": "OSCHATZ",
  "Latitude": "51.291.044",
  "Longitude": "13.133.204",
  "Telephone": "03435-929730",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.291044,13.133204",
  "id": "51.291044,13.133204"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 104",
  "PostCode": 86381,
  "City": "Krumbach",
  "Latitude": "48.251.787",
  "Longitude": "10.356.684",
  "Telephone": "082822492",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.251787,10.356684",
  "id": "48.251787,10.356684"
}, {
  "Brand": "ARAL",
  "Street": "Heedfelder Straße 92",
  "PostCode": 58509,
  "City": "Lüdenscheid",
  "Latitude": "51.229.366",
  "Longitude": "07.611.857",
  "Telephone": "023516913",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.229366,7.611857",
  "id": "51.229366,7.611857"
}, {
  "Brand": "ENI",
  "Street": "Zur Eisenschmiede 2",
  "PostCode": 87480,
  "City": "Weitnau/Hofen",
  "Latitude": "47.656.080",
  "Longitude": "10.088.560",
  "Telephone": "08375/975773",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "47.65608,10.08856",
  "id": "47.65608,10.08856"
}, {
  "Brand": "TOTAL",
  "Street": "MARIENBORNER STR.204",
  "PostCode": 57074,
  "City": "SIEGEN",
  "Latitude": "50.876.417",
  "Longitude": "08.039.582",
  "Telephone": "0271-62105",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.876417,8.039582",
  "id": "50.876417,8.039582"
}, {
  "Brand": "TOTAL",
  "Street": "HOCKENHEIMER STR. 108",
  "PostCode": 68775,
  "City": "KETSCH",
  "Latitude": "49.360.433",
  "Longitude": "08.526.936",
  "Telephone": "06202-692190",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.360433,8.526936",
  "id": "49.360433,8.526936"
}, {
  "Brand": "TOTAL",
  "Street": "DORTMUNDER STR. 174",
  "PostCode": 59077,
  "City": "HAMM",
  "Latitude": "51.660.755",
  "Longitude": "07.736.130",
  "Telephone": "02381-461785",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.660755,7.73613",
  "id": "51.660755,7.73613"
}, {
  "Brand": "TOTAL",
  "Street": "NUERNBERGER STR. 61",
  "PostCode": 86720,
  "City": "NOERDLINGEN",
  "Latitude": "48.860.782",
  "Longitude": "10.502.197",
  "Telephone": "09081-6011",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.860782,10.502197",
  "id": "48.860782,10.502197"
}, {
  "Brand": "TOTAL",
  "Street": "SCHWERINER CHAUSSEE 45",
  "PostCode": 19370,
  "City": "PARCHIM",
  "Latitude": "53.439.546",
  "Longitude": "11.845.423",
  "Telephone": "03871-267297",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.439546,11.845423",
  "id": "53.439546,11.845423"
}, {
  "Brand": "TOTAL",
  "Street": "EMMERICHER STRASSE 99",
  "PostCode": 47533,
  "City": "KLEVE",
  "Latitude": "51.800.822",
  "Longitude": "06.162.648",
  "Telephone": "02821-980082",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.800822,6.162648",
  "id": "51.800822,6.162648"
}, {
  "Brand": "TOTAL",
  "Street": "MILOWER LANDSTR.35b",
  "PostCode": 14712,
  "City": "RATHENOW",
  "Latitude": "52.595.299",
  "Longitude": "12.338.080",
  "Telephone": "03385-511431",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.595299,12.33808",
  "id": "52.595299,12.33808"
}, {
  "Brand": "TOTAL",
  "Street": "HAART 225",
  "PostCode": 24539,
  "City": "NEUMUENSTER",
  "Latitude": "54.060.944",
  "Longitude": "10.010.858",
  "Telephone": "04321-71272",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.060944,10.010858",
  "id": "54.060944,10.010858"
}, {
  "Brand": "TOTAL",
  "Street": "JESINGHAUSER STR. 110",
  "PostCode": 42389,
  "City": "WUPPERTAL",
  "Latitude": "51.282.761",
  "Longitude": "07.256.123",
  "Telephone": "0202-70516555",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.282761,7.256123",
  "id": "51.282761,7.256123"
}, {
  "Brand": "TOTAL",
  "Street": "VOERSTETTER STR. 40",
  "PostCode": 79194,
  "City": "GUNDELFINGEN",
  "Latitude": "48.047.007",
  "Longitude": "07.863.460",
  "Telephone": "0761-585928",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.047007,7.86346",
  "id": "48.047007,7.86346"
}, {
  "Brand": "ARAL",
  "Street": "Siemensstraße 57",
  "PostCode": 7546,
  "City": "Gera",
  "Latitude": "50.907.794",
  "Longitude": "12.066.530",
  "Telephone": "0365415045",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.907794,12.06653",
  "id": "50.907794,12.06653"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 46-48",
  "PostCode": 69488,
  "City": "Birkenau",
  "Latitude": "49.561.659",
  "Longitude": "08.699.699",
  "Telephone": "0620131714",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.561659,8.699699",
  "id": "49.561659,8.699699"
}, {
  "Brand": "TOTAL",
  "Street": "AUTOHOF BAB 7 / AS LUTTERBERG",
  "PostCode": 34355,
  "City": "STAUFENBERG",
  "Latitude": "51.371.034",
  "Longitude": "09.633.298",
  "Telephone": "05543-302710",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.371034,9.633298",
  "id": "51.371034,9.633298"
}, {
  "Brand": "TOTAL",
  "Street": "GEWERBEPARK 14",
  "PostCode": 84513,
  "City": "ERHARTING",
  "Latitude": "48.272.625",
  "Longitude": "12.549.562",
  "Telephone": "08631-9895512",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.272625,12.549562",
  "id": "48.272625,12.549562"
}, {
  "Brand": "ENI",
  "Street": "Gewerbestrasse 3",
  "PostCode": 84427,
  "City": "St Wolfgang",
  "Latitude": "48.212.354",
  "Longitude": "12.134.124",
  "Telephone": "+49 (0) 80851208",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.212354,12.134124",
  "id": "48.212354,12.134124"
}, {
  "Brand": "ARAL",
  "Street": "Bundesstraße 42",
  "PostCode": 53572,
  "City": "Unkel",
  "Latitude": "50.601.943",
  "Longitude": "07.221.923",
  "Telephone": "022249879024",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.601943,7.221923",
  "id": "50.601943,7.221923"
}, {
  "Brand": "ARAL",
  "Street": "Am Kruppwald",
  "PostCode": 46238,
  "City": "Bottrop",
  "Latitude": "51.509.457",
  "Longitude": "06.955.618",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.509457,6.955618",
  "id": "51.509457,6.955618"
}, {
  "Brand": "ENI",
  "Street": "Quiddestrae 31",
  "PostCode": 81735,
  "City": "Muenchen",
  "Latitude": "48.108.980",
  "Longitude": "11.643.260",
  "Telephone": "+49 (0) 89638498",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.10898,11.64326",
  "id": "48.10898,11.64326"
}, {
  "Brand": "Westfalen",
  "Street": "Hauptstr. 134",
  "PostCode": 58332,
  "City": "SCHWELM",
  "Latitude": "51.287.900",
  "Longitude": "07.300.580",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.2879,7.30058",
  "id": "51.2879,7.30058"
}, {
  "Brand": "TOTAL",
  "Street": "KIRCHSTR. 1",
  "PostCode": 47198,
  "City": "DUISBURG",
  "Latitude": "51.443.872",
  "Longitude": "06.675.952",
  "Telephone": "02066-34083",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.443872,6.675952",
  "id": "51.443872,6.675952"
}, {
  "Brand": "ARAL",
  "Street": "Neurieder Weg 33",
  "PostCode": 86609,
  "City": "Donauwörth",
  "Latitude": "48.719.782",
  "Longitude": "10.761.465",
  "Telephone": "09063060",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.719782,10.761465",
  "id": "48.719782,10.761465"
}, {
  "Brand": "TOTAL",
  "Street": "SAARBRUECKER STR. 67",
  "PostCode": 66359,
  "City": "BOUS",
  "Latitude": "49.273.223",
  "Longitude": "06.801.994",
  "Telephone": "06834-3630",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.273223,6.801994",
  "id": "49.273223,6.801994"
}, {
  "Brand": "TOTAL",
  "Street": "ADLERGESTELL 543",
  "PostCode": 12527,
  "City": "BERLIN",
  "Latitude": "52.414.343",
  "Longitude": "13.572.973",
  "Telephone": "030-67489152",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.414343,13.572973",
  "id": "52.414343,13.572973"
}, {
  "Brand": "Westfalen",
  "Street": "Dolberger Str. 136",
  "PostCode": 59229,
  "City": "AHLEN",
  "Latitude": "51.750.300",
  "Longitude": "07.897.720",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.7503,7.89772",
  "id": "51.7503,7.89772"
}, {
  "Brand": "ARAL",
  "Street": "A1",
  "PostCode": 27801,
  "City": "Dötlingen",
  "Latitude": "52.914.935",
  "Longitude": "08.426.035",
  "Telephone": "0443193920",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.914935,8.426035",
  "id": "52.914935,8.426035"
}, {
  "Brand": "ENI",
  "Street": "Rommelshauser Strasse 30",
  "PostCode": 70734,
  "City": "Fellbach",
  "Latitude": "48.805.686",
  "Longitude": "09.305.151",
  "Telephone": "+49 (0) 71158171",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.805686,9.305151",
  "id": "48.805686,9.305151"
}, {
  "Brand": "Westfalen",
  "Street": "Nordloher Str. 31",
  "PostCode": 26689,
  "City": "APEN",
  "Latitude": "53.175.700",
  "Longitude": "07.823.450",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.1757,7.82345",
  "id": "53.1757,7.82345"
}, {
  "Brand": "ENI",
  "Street": "Berliner Allee 14",
  "PostCode": 16833,
  "City": "Fehrbellin",
  "Latitude": "52.805.891",
  "Longitude": "12.785.748",
  "Telephone": "+49 (0) 33932724",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.805891,12.785748",
  "id": "52.805891,12.785748"
}, {
  "Brand": "ARAL",
  "Street": "Clemensstraße 19",
  "PostCode": 99817,
  "City": "Eisenach",
  "Latitude": "50.977.990",
  "Longitude": "10.331.360",
  "Telephone": "036918819798",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.97799,10.33136",
  "id": "50.97799,10.33136"
}, {
  "Brand": "ENI",
  "Street": "Dieselstrasse 16",
  "PostCode": 85386,
  "City": "Eching",
  "Latitude": "48.308.590",
  "Longitude": "11.641.200",
  "Telephone": "08165/9087290",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.30859,11.6412",
  "id": "48.30859,11.6412"
}, {
  "Brand": "ENI",
  "Street": "Lippertsreuter Strasse 13",
  "PostCode": 88662,
  "City": "Ueberlingen",
  "Latitude": "47.771.216",
  "Longitude": "09.173.262",
  "Telephone": "+49 (0) 75514590",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.771216,9.173262",
  "id": "47.771216,9.173262"
}, {
  "Brand": "TOTAL",
  "Street": "AN DER A 8",
  "PostCode": 89340,
  "City": "LEIPHEIM",
  "Latitude": "48.438.855",
  "Longitude": "10.212.878",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.438855,10.212878",
  "id": "48.438855,10.212878"
}, {
  "Brand": "Westfalen",
  "Street": "Bornstr. 31 - 33",
  "PostCode": 44145,
  "City": "DORTMUND",
  "Latitude": "51.521.000",
  "Longitude": "07.470.310",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.521,7.47031",
  "id": "51.521,7.47031"
}, {
  "Brand": "ENI",
  "Street": "Schwetzinger Str. 125",
  "PostCode": 69168,
  "City": "Wiesloch",
  "Latitude": "49.291.425",
  "Longitude": "08.684.190",
  "Telephone": "+49 (0) 62225276",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.291425,8.68419",
  "id": "49.291425,8.68419"
}, {
  "Brand": "ENI",
  "Street": "Ansbacher Strasse 102",
  "PostCode": 90449,
  "City": "Nuernberg",
  "Latitude": "49.420.690",
  "Longitude": "11.032.720",
  "Telephone": "+49 (0) 91167656",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.42069,11.03272",
  "id": "49.42069,11.03272"
}, {
  "Brand": "TOTAL",
  "Street": "NUERNBERGER STR. 1A",
  "PostCode": 91452,
  "City": "WILHERMSDORF",
  "Latitude": "49.483.401",
  "Longitude": "10.725.491",
  "Telephone": "09102-993950",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.483401,10.725491",
  "id": "49.483401,10.725491"
}, {
  "Brand": "TOTAL",
  "Street": "DURLACHER ALLEE 102",
  "PostCode": 76137,
  "City": "KARLSRUHE",
  "Latitude": "49.004.663",
  "Longitude": "08.438.749",
  "Telephone": "0721 20440908",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.004663,8.438749",
  "id": "49.004663,8.438749"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER STR. 31",
  "PostCode": 19348,
  "City": "PERLEBERG",
  "Latitude": "53.070.305",
  "Longitude": "11.876.505",
  "Telephone": "03876-612301",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.070305,11.876505",
  "id": "53.070305,11.876505"
}, {
  "Brand": "TOTAL",
  "Street": "SCHOENEBERGER STR. 17",
  "PostCode": 12103,
  "City": "BERLIN",
  "Latitude": "52.470.148",
  "Longitude": "13.371.984",
  "Telephone": "030/43208844",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.470148,13.371984",
  "id": "52.470148,13.371984"
}, {
  "Brand": "TOTAL",
  "Street": "BAB VIERWINDEN-NORD / A46",
  "PostCode": 41516,
  "City": "GREVENBROICH",
  "Latitude": "51.129.950",
  "Longitude": "06.604.146",
  "Telephone": "02182-1468",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.12995,6.604146",
  "id": "51.12995,6.604146"
}, {
  "Brand": "TOTAL",
  "Street": "HAUPTSTR. 40",
  "PostCode": 85659,
  "City": "FORSTERN",
  "Latitude": "48.186.555",
  "Longitude": "11.975.332",
  "Telephone": "08124-527059",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.186555,11.975332",
  "id": "48.186555,11.975332"
}, {
  "Brand": "ARAL",
  "Street": "Gablinger Weg 83",
  "PostCode": 86154,
  "City": "Augsburg",
  "Latitude": "48.398.774",
  "Longitude": "10.868.669",
  "Telephone": "082145309480",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.398774,10.868669",
  "id": "48.398774,10.868669"
}, {
  "Brand": "TOTAL",
  "Street": "GERMERSHEIMER STR. 2A",
  "PostCode": 67354,
  "City": "ROEMERBERG",
  "Latitude": "49.299.267",
  "Longitude": "08.410.588",
  "Telephone": "06232-82636",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.299267,8.410588",
  "id": "49.299267,8.410588"
}, {
  "Brand": "TOTAL",
  "Street": "ALTER LANDWEG 5",
  "PostCode": 17258,
  "City": "FELDBERG",
  "Latitude": "53.331.734",
  "Longitude": "13.431.131",
  "Telephone": "039831-20034",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.331734,13.431131",
  "id": "53.331734,13.431131"
}, {
  "Brand": "OMV",
  "Street": "Oberotterbacher Str. 1",
  "PostCode": 84056,
  "City": "Rottenburg",
  "Latitude": "48.701.810",
  "Longitude": "12.036.810",
  "Telephone": "+49-8781-201500",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.70181,12.03681",
  "id": "48.70181,12.03681"
}, {
  "Brand": "TOTAL",
  "Street": "PIRMASENSER STR. 93",
  "PostCode": 66497,
  "City": "CONTWIG",
  "Latitude": "49.248.422",
  "Longitude": "07.443.060",
  "Telephone": "06332-5833",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.248422,7.44306",
  "id": "49.248422,7.44306"
}, {
  "Brand": "ENI",
  "Street": "Neuglosberg 11",
  "PostCode": 96317,
  "City": "Kronach-Glosberg",
  "Latitude": "50.288.356",
  "Longitude": "11.309.289",
  "Telephone": "+49 (0) 92619409",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.288356,11.309289",
  "id": "50.288356,11.309289"
}, {
  "Brand": "Westfalen",
  "Street": "Hertener Weg 30-38",
  "PostCode": 45701,
  "City": "HERTEN",
  "Latitude": "51.600.000",
  "Longitude": "07.093.320",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.6,7.09332",
  "id": "51.6,7.09332"
}, {
  "Brand": "ENI",
  "Street": "Woelfershaeuser Strasse 49",
  "PostCode": 36266,
  "City": "Heringen",
  "Latitude": "50.884.966",
  "Longitude": "09.987.643",
  "Telephone": "+49 (0) 66241703",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.884966,9.987643",
  "id": "50.884966,9.987643"
}, {
  "Brand": "TOTAL",
  "Street": "AN DER LANDSTR. 73",
  "PostCode": 6429,
  "City": "NIENBURG",
  "Latitude": "51.840.122",
  "Longitude": "11.753.641",
  "Telephone": "034721-22838",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.840122,11.753641",
  "id": "51.840122,11.753641"
}, {
  "Brand": "ARAL",
  "Street": "Josef-Bautz-Str. 34",
  "PostCode": 63450,
  "City": "Hanau",
  "Latitude": "50.114.393",
  "Longitude": "08.926.856",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.114393,8.926856",
  "id": "50.114393,8.926856"
}, {
  "Brand": "OMV",
  "Street": "Weissenburger Str. 80",
  "PostCode": 91710,
  "City": "Gunzenhausen",
  "Latitude": "49.107.970",
  "Longitude": "10.758.300",
  "Telephone": "+49-9831-2311",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.10797,10.7583",
  "id": "49.10797,10.7583"
}, {
  "Brand": "TOTAL",
  "Street": "HEIDELBERGER STR. 55",
  "PostCode": 64285,
  "City": "DARMSTADT",
  "Latitude": "49.861.427",
  "Longitude": "08.646.803",
  "Telephone": "06151-316459",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.861427,8.646803",
  "id": "49.861427,8.646803"
}, {
  "Brand": "TOTAL",
  "Street": "EGGLKOFENSTR. 2",
  "PostCode": 84453,
  "City": "MUEHLDORF",
  "Latitude": "48.256.443",
  "Longitude": "12.533.697",
  "Telephone": "08631-378121",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.256443,12.533697",
  "id": "48.256443,12.533697"
}, {
  "Brand": "TOTAL",
  "Street": "THEODORSTR. 282",
  "PostCode": 40472,
  "City": "DUESSELDORF",
  "Latitude": "51.271.852",
  "Longitude": "06.816.438",
  "Telephone": "0211-6549816",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.271852,6.816438",
  "id": "51.271852,6.816438"
}, {
  "Brand": "ARAL",
  "Street": "Industriestraße 2",
  "PostCode": 94559,
  "City": "Niederwinkling",
  "Latitude": "48.893.933",
  "Longitude": "12.800.573",
  "Telephone": "09962912020",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.893933,12.800573",
  "id": "48.893933,12.800573"
}, {
  "Brand": "ARAL",
  "Street": "Kaufbeurer Straße 5",
  "PostCode": 86830,
  "City": "Schwabmünchen",
  "Latitude": "48.175.881",
  "Longitude": "10.757.688",
  "Telephone": "082324773",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.175881,10.757688",
  "id": "48.175881,10.757688"
}, {
  "Brand": "TOTAL",
  "Street": "PRAEMIENSTR. 30",
  "PostCode": 52223,
  "City": "STOLBERG",
  "Latitude": "50.765.300",
  "Longitude": "06.217.083",
  "Telephone": "02402-24678",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.7653,6.217083",
  "id": "50.7653,6.217083"
}, {
  "Brand": "ENI",
  "Street": "Untermuehlaustr. 105-107",
  "PostCode": 68169,
  "City": "Mannheim",
  "Latitude": "49.505.570",
  "Longitude": "08.463.130",
  "Telephone": "+49 (0) 62131650",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.50557,8.46313",
  "id": "49.50557,8.46313"
}, {
  "Brand": "Westfalen",
  "Street": "Reichsstr. 3 - 5",
  "PostCode": 53125,
  "City": "BONN",
  "Latitude": "50.683.200",
  "Longitude": "07.075.740",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.6832,7.07574",
  "id": "50.6832,7.07574"
}, {
  "Brand": "OMV",
  "Street": "Gmünder Str. 19",
  "PostCode": 73557,
  "City": "Mutlangen",
  "Latitude": "48.820.700",
  "Longitude": "09.795.120",
  "Telephone": "+49-7171-71557",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.8207,9.79512",
  "id": "48.8207,9.79512"
}, {
  "Brand": "TOTAL",
  "Street": "DESSAUER STR. 13",
  "PostCode": 6886,
  "City": "WITTENBERG",
  "Latitude": "51.865.663",
  "Longitude": "12.615.115",
  "Telephone": "03491-666986",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.865663,12.615115",
  "id": "51.865663,12.615115"
}, {
  "Brand": "OMV",
  "Street": "Eschholzstr. 112",
  "PostCode": 79115,
  "City": "Freiburg",
  "Latitude": "47.992.410",
  "Longitude": "07.831.460",
  "Telephone": "+49-761-492979",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.99241,7.83146",
  "id": "47.99241,7.83146"
}, {
  "Brand": "ARAL",
  "Street": "Amalienburgstraße 20",
  "PostCode": 81247,
  "City": "München",
  "Latitude": "48.164.318",
  "Longitude": "11.488.541",
  "Telephone": "08989160247",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.164318,11.488541",
  "id": "48.164318,11.488541"
}, {
  "Brand": "TOTAL",
  "Street": "STAATSSTR. 47A",
  "PostCode": 67483,
  "City": "EDESHEIM",
  "Latitude": "49.265.830",
  "Longitude": "08.133.551",
  "Telephone": "06323-2876",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.26583,8.133551",
  "id": "49.26583,8.133551"
}, {
  "Brand": "TOTAL",
  "Street": "ALTE STEINER STR. 43",
  "PostCode": 75239,
  "City": "EISINGEN",
  "Latitude": "48.949.397",
  "Longitude": "08.664.782",
  "Telephone": "07232-8994",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.949397,8.664782",
  "id": "48.949397,8.664782"
}, {
  "Brand": "ARAL",
  "Street": "Tuttlinger Straße 38",
  "PostCode": 78628,
  "City": "Rottweil",
  "Latitude": "48.153.309",
  "Longitude": "08.639.625",
  "Telephone": "074121398",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.153309,8.639625",
  "id": "48.153309,8.639625"
}, {
  "Brand": "TOTAL",
  "Street": "NORDALLEE 2",
  "PostCode": 51147,
  "City": "KOELN",
  "Latitude": "50.881.844",
  "Longitude": "07.113.107",
  "Telephone": "02203-591642",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.881844,7.113107",
  "id": "50.881844,7.113107"
}, {
  "Brand": "ARAL",
  "Street": "Bregenzer Straße 43 - 45",
  "PostCode": 88131,
  "City": "Lindau",
  "Latitude": "47.553.018",
  "Longitude": "09.701.445",
  "Telephone": "0",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:30",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:30",
  "SundayFrom": "06:30",
  "SundayTo": "22:30",
  "IsOpen24Hours": "Nein",
  "location": "47.553018,9.701445",
  "id": "47.553018,9.701445"
}, {
  "Brand": "OMV",
  "Street": "Münchener Str. 49",
  "PostCode": 85748,
  "City": "Garching",
  "Latitude": "48.244.050",
  "Longitude": "11.647.230",
  "Telephone": "+49-89-32649709",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.24405,11.64723",
  "id": "48.24405,11.64723"
}, {
  "Brand": "TOTAL",
  "Street": "LUDWIG-ERHARD-STR. 36",
  "PostCode": 4103,
  "City": "LEIPZIG",
  "Latitude": "51.343.231",
  "Longitude": "12.395.467",
  "Telephone": "0341-6810411",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.343231,12.395467",
  "id": "51.343231,12.395467"
}, {
  "Brand": "ENI",
  "Street": "Zwieseler Strasse 44",
  "PostCode": 94209,
  "City": "Regen",
  "Latitude": "48.971.883",
  "Longitude": "13.138.086",
  "Telephone": "+49 (0) 99211852",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.971883,13.138086",
  "id": "48.971883,13.138086"
}, {
  "Brand": "TOTAL",
  "Street": "W.-KAULISCH-STR.49",
  "PostCode": 1844,
  "City": "NEUSTADT",
  "Latitude": "51.028.819",
  "Longitude": "14.208.620",
  "Telephone": "03596-604238",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.028819,14.20862",
  "id": "51.028819,14.20862"
}, {
  "Brand": "TOTAL",
  "Street": "ELLY-BEINHORN-RING 2 A",
  "PostCode": 12529,
  "City": "SCHOENEFELD",
  "Latitude": "52.370.372",
  "Longitude": "13.527.195",
  "Telephone": "030-609156230",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.370372,13.527195",
  "id": "52.370372,13.527195"
}, {
  "Brand": "TOTAL",
  "Street": "WERTHEIMER STR. 50",
  "PostCode": 97941,
  "City": "TAUBERBISCHOFSHEIM",
  "Latitude": "49.630.185",
  "Longitude": "09.666.140",
  "Telephone": "0934195164",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.630185,9.66614",
  "id": "49.630185,9.66614"
}, {
  "Brand": "Westfalen",
  "Street": "Von-Galen-Str. 2",
  "PostCode": 48727,
  "City": "BILLERBECK",
  "Latitude": "51.970.400",
  "Longitude": "07.280.700",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9704,7.2807",
  "id": "51.9704,7.2807"
}, {
  "Brand": "OMV",
  "Street": "Traunsteiner Str. 13",
  "PostCode": 83317,
  "City": "Teisendorf",
  "Latitude": "47.849.660",
  "Longitude": "12.816.900",
  "Telephone": "+49-8666-6898",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.84966,12.8169",
  "id": "47.84966,12.8169"
}, {
  "Brand": "OMV",
  "Street": "Owinger Str. 11-13",
  "PostCode": 72401,
  "City": "Haigerloch-Stetten",
  "Latitude": "48.351.820",
  "Longitude": "08.817.680",
  "Telephone": "+49-7474-9551971",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.35182,8.81768",
  "id": "48.35182,8.81768"
}, {
  "Brand": "TOTAL",
  "Street": "DEUBACHER STR.1",
  "PostCode": 97922,
  "City": "LAUDA-KOENIGSHOFEN",
  "Latitude": "49.541.334",
  "Longitude": "09.736.617",
  "Telephone": "0934365440",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.541334,9.736617",
  "id": "49.541334,9.736617"
}, {
  "Brand": "Westfalen",
  "Street": "Beckumer Str. 133",
  "PostCode": 59555,
  "City": "LIPPSTADT",
  "Latitude": "51.680.500",
  "Longitude": "08.311.550",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.6805,8.31155",
  "id": "51.6805,8.31155"
}, {
  "Brand": "Westfalen",
  "Street": "Metelener Stra? 40",
  "PostCode": 48493,
  "City": "WETTRINGEN",
  "Latitude": "52.206.700",
  "Longitude": "07.310.310",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.2067,7.31031",
  "id": "52.2067,7.31031"
}, {
  "Brand": "ENI",
  "Street": "Adlergestell 289 A",
  "PostCode": 12489,
  "City": "Berlin",
  "Latitude": "52.431.974",
  "Longitude": "13.546.519",
  "Telephone": "+49 (0) 30671329",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "52.431974,13.546519",
  "id": "52.431974,13.546519"
}, {
  "Brand": "Westfalen",
  "Street": "Muensterstr. 52",
  "PostCode": 48308,
  "City": "SENDEN",
  "Latitude": "51.861.700",
  "Longitude": "07.492.280",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8617,7.49228",
  "id": "51.8617,7.49228"
}, {
  "Brand": "ARAL",
  "Street": "Luetticher Straße 108",
  "PostCode": 40547,
  "City": "Düsseldorf",
  "Latitude": "51.241.120",
  "Longitude": "06.743.643",
  "Telephone": "0211552422",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.24112,6.743643",
  "id": "51.24112,6.743643"
}, {
  "Brand": "ARAL",
  "Street": "Bismarckstraße 7",
  "PostCode": 33775,
  "City": "Versmold",
  "Latitude": "52.023.836",
  "Longitude": "08.122.420",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.023836,8.12242",
  "id": "52.023836,8.12242"
}, {
  "Brand": "TOTAL",
  "Street": "FERDINAND-EGELINSKI-STR. 3",
  "PostCode": 17419,
  "City": "AHLBECK",
  "Latitude": "53.934.109",
  "Longitude": "14.194.510",
  "Telephone": "038378-28415",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.934109,14.19451",
  "id": "53.934109,14.19451"
}, {
  "Brand": "ENI",
  "Street": "Bahnhofstrasse 10A",
  "PostCode": 94327,
  "City": "Bogen",
  "Latitude": "48.909.490",
  "Longitude": "12.691.030",
  "Telephone": "+49 (0) 94221864",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.90949,12.69103",
  "id": "48.90949,12.69103"
}, {
  "Brand": "ARAL",
  "Street": "Schneeberger Straße 30",
  "PostCode": 8309,
  "City": "Eibenstock",
  "Latitude": "50.501.301",
  "Longitude": "12.603.101",
  "Telephone": "0377523145",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.501301,12.603101",
  "id": "50.501301,12.603101"
}, {
  "Brand": "ARAL",
  "Street": "Industriestraße",
  "PostCode": 26892,
  "City": "Dörpen",
  "Latitude": "52.970.023",
  "Longitude": "07.360.813",
  "Telephone": "0496391280",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "52.970023,7.360813",
  "id": "52.970023,7.360813"
}, {
  "Brand": "OMV",
  "Street": "Flughafenstr. 70",
  "PostCode": 70629,
  "City": "Stuttgart",
  "Latitude": "48.692.770",
  "Longitude": "09.198.990",
  "Telephone": "+49-711-2206610",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.69277,9.19899",
  "id": "48.69277,9.19899"
}, {
  "Brand": "TOTAL",
  "Street": "NEUE JUELICHER STR. 45",
  "PostCode": 52353,
  "City": "DUEREN",
  "Latitude": "50.812.335",
  "Longitude": "06.474.528",
  "Telephone": "02421-941417",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.812335,6.474528",
  "id": "50.812335,6.474528"
}, {
  "Brand": "ARAL",
  "Street": "A 1",
  "PostCode": 54421,
  "City": "Reinsfeld",
  "Latitude": "49.689.705",
  "Longitude": "06.899.990",
  "Telephone": "065861011",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.689705,6.89999",
  "id": "49.689705,6.89999"
}, {
  "Brand": "ARAL",
  "Street": "HAUPTSTRAßE 100",
  "PostCode": 44894,
  "City": "Bochum",
  "Latitude": "51.480.763",
  "Longitude": "07.323.950",
  "Telephone": "023441747848",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.480763,7.32395",
  "id": "51.480763,7.32395"
}, {
  "Brand": "TOTAL",
  "Street": "BAHNHOFSTR. 23",
  "PostCode": 73257,
  "City": "KOENGEN",
  "Latitude": "48.677.472",
  "Longitude": "09.367.057",
  "Telephone": "07024-84995",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.677472,9.367057",
  "id": "48.677472,9.367057"
}, {
  "Brand": "Westfalen",
  "Street": "Bremer Str. 46",
  "PostCode": 27239,
  "City": "TWISTRINGEN",
  "Latitude": "52.804.500",
  "Longitude": "08.647.040",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.8045,8.64704",
  "id": "52.8045,8.64704"
}, {
  "Brand": "OMV",
  "Street": "Höhenrain West  A95",
  "PostCode": 82335,
  "City": "Berg",
  "Latitude": "47.928.580",
  "Longitude": "11.401.970",
  "Telephone": "+49-8171-21920",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.92858,11.40197",
  "id": "47.92858,11.40197"
}, {
  "Brand": "OMV",
  "Street": "Ettlinger Str. 38",
  "PostCode": 76332,
  "City": "Bad Herrenalb",
  "Latitude": "48.805.060",
  "Longitude": "08.444.900",
  "Telephone": "+49-7083-2494",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.80506,8.4449",
  "id": "48.80506,8.4449"
}, {
  "Brand": "TOTAL",
  "Street": "SERVAISSTRASSE 1",
  "PostCode": 54293,
  "City": "TRIER- EHRANG",
  "Latitude": "49.806.370",
  "Longitude": "06.685.430",
  "Telephone": "06519680914",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.80637,6.68543",
  "id": "49.80637,6.68543"
}, {
  "Brand": "OMV",
  "Street": "Stadtgraben 104",
  "PostCode": 94315,
  "City": "Straubing",
  "Latitude": "48.883.110",
  "Longitude": "12.574.260",
  "Telephone": "+49-9421-1380",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.88311,12.57426",
  "id": "48.88311,12.57426"
}, {
  "Brand": "Westfalen",
  "Street": "Bahnhofstr. 37/39",
  "PostCode": 48691,
  "City": "VREDEN",
  "Latitude": "52.030.100",
  "Longitude": "06.823.170",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0301,6.82317",
  "id": "52.0301,6.82317"
}, {
  "Brand": "ENI",
  "Street": "Vorjurastrasse 180",
  "PostCode": 90453,
  "City": "Nuernberg",
  "Latitude": "49.368.630",
  "Longitude": "11.045.590",
  "Telephone": "+49 (0) 91163787",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.36863,11.04559",
  "id": "49.36863,11.04559"
}, {
  "Brand": "ARAL",
  "Street": "A4",
  "PostCode": 2625,
  "City": "Bautzen",
  "Latitude": "51.193.185",
  "Longitude": "14.418.596",
  "Telephone": "03593780308",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.193185,14.418596",
  "id": "51.193185,14.418596"
}, {
  "Brand": "OMV",
  "Street": "Auerfeldstr. 21",
  "PostCode": 81541,
  "City": "München",
  "Latitude": "48.123.620",
  "Longitude": "11.595.450",
  "Telephone": "+49-89-487038",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.12362,11.59545",
  "id": "48.12362,11.59545"
}, {
  "Brand": "TOTAL",
  "Street": "LINDLARER STR.143",
  "PostCode": 51491,
  "City": "OVERATH",
  "Latitude": "50.962.701",
  "Longitude": "07.244.351",
  "Telephone": "02204-74445",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.962701,7.244351",
  "id": "50.962701,7.244351"
}, {
  "Brand": "TOTAL",
  "Street": "GEWERBEPARK 3",
  "PostCode": 18546,
  "City": "SASSNITZ",
  "Latitude": "54.513.303",
  "Longitude": "13.603.129",
  "Telephone": "038392-32620",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.513303,13.603129",
  "id": "54.513303,13.603129"
}, {
  "Brand": "ENI",
  "Street": "Heidenheimer Strasse 47",
  "PostCode": 89537,
  "City": "Giengen",
  "Latitude": "48.623.680",
  "Longitude": "10.242.190",
  "Telephone": "+49 (0) 73229195",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.62368,10.24219",
  "id": "48.62368,10.24219"
}, {
  "Brand": "TOTAL",
  "Street": "GRABENSTR. 2",
  "PostCode": 19061,
  "City": "SCHWERIN",
  "Latitude": "53.601.225",
  "Longitude": "11.391.104",
  "Telephone": "0385-6666666",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.601225,11.391104",
  "id": "53.601225,11.391104"
}, {
  "Brand": "TOTAL",
  "Street": "WATTENHEIMER STR. 28",
  "PostCode": 67310,
  "City": "HETTENLEIDELHEIM",
  "Latitude": "49.531.580",
  "Longitude": "08.066.086",
  "Telephone": "06351-122421",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.53158,8.066086",
  "id": "49.53158,8.066086"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER STR. 25",
  "PostCode": 51377,
  "City": "LEVERKUSEN",
  "Latitude": "51.043.054",
  "Longitude": "07.072.339",
  "Telephone": "02148-909415",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.043054,7.072339",
  "id": "51.043054,7.072339"
}, {
  "Brand": "TOTAL",
  "Street": "LANDSBERGER ALLEE 376",
  "PostCode": 12681,
  "City": "BERLIN",
  "Latitude": "52.535.395",
  "Longitude": "13.522.849",
  "Telephone": "030-5437728",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.535395,13.522849",
  "id": "52.535395,13.522849"
}, {
  "Brand": "ARAL",
  "Street": "Münchener Straße 300 / It",
  "PostCode": 40589,
  "City": "Düsseldorf",
  "Latitude": "51.169.051",
  "Longitude": "06.822.734",
  "Telephone": "021170089550",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.169051,6.822734",
  "id": "51.169051,6.822734"
}, {
  "Brand": "TOTAL",
  "Street": "HAGENER STR. 239",
  "PostCode": 57223,
  "City": "KREUZTAL",
  "Latitude": "50.988.082",
  "Longitude": "07.959.252",
  "Telephone": "02732-5531848",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.988082,7.959252",
  "id": "50.988082,7.959252"
}, {
  "Brand": "TOTAL",
  "Street": "BRANDENBURGER STR.",
  "PostCode": 14806,
  "City": "BAD BELZIG",
  "Latitude": "52.150.539",
  "Longitude": "12.600.528",
  "Telephone": "033841-35772",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.150539,12.600528",
  "id": "52.150539,12.600528"
}, {
  "Brand": "ARAL",
  "Street": "Donauwörther Straße 7",
  "PostCode": 86405,
  "City": "Meitingen",
  "Latitude": "48.548.103",
  "Longitude": "10.853.067",
  "Telephone": "082718132409",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "48.548103,10.853067",
  "id": "48.548103,10.853067"
}, {
  "Brand": "ARAL",
  "Street": "Am Bahnhof",
  "PostCode": 15562,
  "City": "Rüdersdorf",
  "Latitude": "52.489.008",
  "Longitude": "13.780.063",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.489008,13.780063",
  "id": "52.489008,13.780063"
}, {
  "Brand": "TOTAL",
  "Street": "FRIEDEBURGER HAUPTSTR. 104",
  "PostCode": 26446,
  "City": "FRIEDEBURG",
  "Latitude": "53.457.410",
  "Longitude": "07.836.680",
  "Telephone": "04465-6249958",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.45741,7.83668",
  "id": "53.45741,7.83668"
}, {
  "Brand": "TOTAL",
  "Street": "UNTERE SCHLOSSSTRASSE 37",
  "PostCode": 9573,
  "City": "AUGUSTUSBURG",
  "Latitude": "50.818.268",
  "Longitude": "13.100.932",
  "Telephone": "037291-6567",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.818268,13.100932",
  "id": "50.818268,13.100932"
}, {
  "Brand": "TOTAL",
  "Street": "WISMARSCHE STR.153 A",
  "PostCode": 23936,
  "City": "GREVESMUEHLEN",
  "Latitude": "53.865.385",
  "Longitude": "11.209.500",
  "Telephone": "03881-2729",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.865385,11.2095",
  "id": "53.865385,11.2095"
}, {
  "Brand": "ARAL",
  "Street": "Motorstr. 21",
  "PostCode": 70499,
  "City": "Stuttgart",
  "Latitude": "48.828.118",
  "Longitude": "09.109.100",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.828118,9.1091",
  "id": "48.828118,9.1091"
}, {
  "Brand": "TOTAL",
  "Street": "HAMBURGER STR.44",
  "PostCode": 1067,
  "City": "DRESDEN",
  "Latitude": "51.061.286",
  "Longitude": "13.693.845",
  "Telephone": "0351-4942558",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.061286,13.693845",
  "id": "51.061286,13.693845"
}, {
  "Brand": "TOTAL",
  "Street": "LUETJENBURGER STR. 61",
  "PostCode": 24306,
  "City": "PLOEN",
  "Latitude": "54.170.135",
  "Longitude": "10.435.575",
  "Telephone": "04522-749000",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.170135,10.435575",
  "id": "54.170135,10.435575"
}, {
  "Brand": "ARAL",
  "Street": "A4",
  "PostCode": 51491,
  "City": "Overath",
  "Latitude": "50.953.128",
  "Longitude": "07.319.019",
  "Telephone": "022062201",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.953128,7.319019",
  "id": "50.953128,7.319019"
}, {
  "Brand": "ARAL",
  "Street": "Königswinterer Straße 106",
  "PostCode": 53639,
  "City": "Königswinter",
  "Latitude": "50.698.908",
  "Longitude": "07.277.707",
  "Telephone": "02244876519",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.698908,7.277707",
  "id": "50.698908,7.277707"
}, {
  "Brand": "ARAL",
  "Street": "Berghamer Straße 3",
  "PostCode": 85435,
  "City": "Erding",
  "Latitude": "48.276.604",
  "Longitude": "11.899.183",
  "Telephone": "0812293447",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "00:00",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "48.276604,11.899183",
  "id": "48.276604,11.899183"
}, {
  "Brand": "TOTAL",
  "Street": "LEIPZIGER STR. 12",
  "PostCode": 1589,
  "City": "RIESA",
  "Latitude": "51.289.399",
  "Longitude": "13.289.583",
  "Telephone": "03525-731747",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.289399,13.289583",
  "id": "51.289399,13.289583"
}, {
  "Brand": "ENI",
  "Street": "Silbergstrasse 91 A/B 20",
  "PostCode": 83483,
  "City": "Bischofswiesen - Strub",
  "Latitude": "47.629.647",
  "Longitude": "12.960.031",
  "Telephone": "+49 (0) 86524757",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.629647,12.960031",
  "id": "47.629647,12.960031"
}, {
  "Brand": "OMV",
  "Street": "Waldstr. 255",
  "PostCode": 63071,
  "City": "Offenbach",
  "Latitude": "50.082.780",
  "Longitude": "08.775.110",
  "Telephone": "+49-69-83071274",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.08278,8.77511",
  "id": "50.08278,8.77511"
}, {
  "Brand": "ENI",
  "Street": "Kossaerstr./Maximilianallee",
  "PostCode": 4356,
  "City": "Leipzig-Podelwitz",
  "Latitude": "51.410.611",
  "Longitude": "12.394.067",
  "Telephone": "+49 (0) 34152172",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.410611,12.394067",
  "id": "51.410611,12.394067"
}, {
  "Brand": "ENI",
  "Street": "Stuttgarter Str. 144",
  "PostCode": 73230,
  "City": "Kirchheim",
  "Latitude": "48.655.650",
  "Longitude": "09.427.650",
  "Telephone": "+49 (0) 70213054",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.65565,9.42765",
  "id": "48.65565,9.42765"
}, {
  "Brand": "Westfalen",
  "Street": "Grosse Str. 22",
  "PostCode": 49434,
  "City": "NEUENKIRCHEN",
  "Latitude": "52.509.600",
  "Longitude": "08.068.540",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.5096,8.06854",
  "id": "52.5096,8.06854"
}, {
  "Brand": "Westfalen",
  "Street": "Im Babenbecker Feld 56",
  "PostCode": 32051,
  "City": "HERFORD",
  "Latitude": "52.129.500",
  "Longitude": "08.643.470",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.1295,8.64347",
  "id": "52.1295,8.64347"
}, {
  "Brand": "ENI",
  "Street": "Hallesche Str. 182",
  "PostCode": 4159,
  "City": "Leipzig",
  "Latitude": "51.384.673",
  "Longitude": "12.273.064",
  "Telephone": "+49 (0) 34146157",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.384673,12.273064",
  "id": "51.384673,12.273064"
}, {
  "Brand": "Westfalen",
  "Street": "Hildesheimer 407",
  "PostCode": 30519,
  "City": "HANNOVER",
  "Latitude": "52.326.100",
  "Longitude": "09.781.820",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.3261,9.78182",
  "id": "52.3261,9.78182"
}, {
  "Brand": "TOTAL",
  "Street": "HAUPTSTR. 257",
  "PostCode": 67065,
  "City": "LUDWIGSHAFEN",
  "Latitude": "49.450.165",
  "Longitude": "08.423.482",
  "Telephone": "0621-542476",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.450165,8.423482",
  "id": "49.450165,8.423482"
}, {
  "Brand": "OMV",
  "Street": "Münchner Str. 11",
  "PostCode": 85646,
  "City": "Anzing",
  "Latitude": "48.151.240",
  "Longitude": "11.851.080",
  "Telephone": "+49-8121-3848",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.15124,11.85108",
  "id": "48.15124,11.85108"
}, {
  "Brand": "ARAL",
  "Street": "Friedrich-Ebert-Str. 100",
  "PostCode": 49811,
  "City": "Lingen",
  "Latitude": "52.543.677",
  "Longitude": "07.342.816",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.543677,7.342816",
  "id": "52.543677,7.342816"
}, {
  "Brand": "TOTAL",
  "Street": "BLOCKDAMMWEG 6",
  "PostCode": 10317,
  "City": "BERLIN",
  "Latitude": "52.482.910",
  "Longitude": "13.502.177",
  "Telephone": "030-5099806",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.48291,13.502177",
  "id": "52.48291,13.502177"
}, {
  "Brand": "TOTAL",
  "Street": "MARKTFLECKEN 17",
  "PostCode": 17498,
  "City": "NEUENKIRCHEN",
  "Latitude": "54.117.154",
  "Longitude": "13.363.749",
  "Telephone": "03834-2340",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.117154,13.363749",
  "id": "54.117154,13.363749"
}, {
  "Brand": "TOTAL",
  "Street": "BAB EIFEL-OST / A48",
  "PostCode": 54533,
  "City": "NIEDEROEFFLINGEN",
  "Latitude": "50.069.233",
  "Longitude": "06.881.855",
  "Telephone": "06574-200",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.069233,6.881855",
  "id": "50.069233,6.881855"
}, {
  "Brand": "TOTAL",
  "Street": "NEUKOELLNER STR. 294",
  "PostCode": 12357,
  "City": "BERLIN",
  "Latitude": "52.422.200",
  "Longitude": "13.487.083",
  "Telephone": "030-6613037",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.4222,13.487083",
  "id": "52.4222,13.487083"
}, {
  "Brand": "ENI",
  "Street": "Von-Kahr-Str. 62",
  "PostCode": 80999,
  "City": "Muenchen",
  "Latitude": "48.177.590",
  "Longitude": "11.467.440",
  "Telephone": "+49 (0) 89812118",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.17759,11.46744",
  "id": "48.17759,11.46744"
}, {
  "Brand": "TOTAL",
  "Street": "HOFER STR. 38",
  "PostCode": 7926,
  "City": "GEFELL",
  "Latitude": "50.435.176",
  "Longitude": "11.855.584",
  "Telephone": "036649-82258",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.435176,11.855584",
  "id": "50.435176,11.855584"
}, {
  "Brand": "TOTAL",
  "Street": "GREIFSWALDER CHAUSSEE 122",
  "PostCode": 18439,
  "City": "STRALSUND",
  "Latitude": "54.300.851",
  "Longitude": "13.094.701",
  "Telephone": "03831-2031038",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.300851,13.094701",
  "id": "54.300851,13.094701"
}, {
  "Brand": "ARAL",
  "Street": "Gartenallee 2",
  "PostCode": 4827,
  "City": "Machern",
  "Latitude": "51.359.901",
  "Longitude": "12.616.976",
  "Telephone": "034292869790",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Ja",
  "location": "51.359901,12.616976",
  "id": "51.359901,12.616976"
}, {
  "Brand": "ARAL",
  "Street": "Wöllsteiner Straße 11",
  "PostCode": 55599,
  "City": "Gau-Bickelheim",
  "Latitude": "49.834.917",
  "Longitude": "08.015.878",
  "Telephone": "06701961510",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.834917,8.015878",
  "id": "49.834917,8.015878"
}, {
  "Brand": "Westfalen",
  "Street": "Hervester Str. 43 - 45",
  "PostCode": 46286,
  "City": "DORSTEN",
  "Latitude": "51.713.700",
  "Longitude": "07.011.820",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.7137,7.01182",
  "id": "51.7137,7.01182"
}, {
  "Brand": "ARAL",
  "Street": "Zum Autohof 4",
  "PostCode": 32457,
  "City": "Porta Westfalica",
  "Latitude": "52.210.124",
  "Longitude": "08.875.030",
  "Telephone": "05731156930",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.210124,8.87503",
  "id": "52.210124,8.87503"
}, {
  "Brand": "ARAL",
  "Street": "Escher Straße 273",
  "PostCode": 50739,
  "City": "Köln",
  "Latitude": "50.969.443",
  "Longitude": "06.927.176",
  "Telephone": "0221171612",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.969443,6.927176",
  "id": "50.969443,6.927176"
}, {
  "Brand": "OMV",
  "Street": "Bayerwaldstr. 10",
  "PostCode": 94559,
  "City": "Niederwinkling",
  "Latitude": "48.888.140",
  "Longitude": "12.798.750",
  "Telephone": "+49-9962-2030792",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.88814,12.79875",
  "id": "48.88814,12.79875"
}, {
  "Brand": "ARAL",
  "Street": "A 57",
  "PostCode": 47809,
  "City": "Krefeld",
  "Latitude": "51.311.197",
  "Longitude": "06.634.408",
  "Telephone": "02151544940",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.311197,6.634408",
  "id": "51.311197,6.634408"
}, {
  "Brand": "ARAL",
  "Street": "Kronprinzessinnenweg 120",
  "PostCode": 14129,
  "City": "Berlin",
  "Latitude": "52.434.178",
  "Longitude": "13.190.753",
  "Telephone": "0308037233",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.434178,13.190753",
  "id": "52.434178,13.190753"
}, {
  "Brand": "Westfalen",
  "Street": "Herforder Str. 54",
  "PostCode": 32257,
  "City": "BUENDE",
  "Latitude": "52.192.200",
  "Longitude": "08.593.860",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.1922,8.59386",
  "id": "52.1922,8.59386"
}, {
  "Brand": "TOTAL",
  "Street": "ROEMERSTR. 1",
  "PostCode": 64560,
  "City": "RIEDSTADT-GODDELAU",
  "Latitude": "49.834.679",
  "Longitude": "08.484.132",
  "Telephone": "06158-184056",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.834679,8.484132",
  "id": "49.834679,8.484132"
}, {
  "Brand": "ARAL",
  "Street": "Heilbronner Straße 14-15",
  "PostCode": 10711,
  "City": "Berlin",
  "Latitude": "52.501.831",
  "Longitude": "13.294.388",
  "Telephone": "0308913017",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "18:00",
  "SundayFrom": "00:00",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "52.501831,13.294388",
  "id": "52.501831,13.294388"
}, {
  "Brand": "ARAL",
  "Street": "Neukamp 4",
  "PostCode": 26655,
  "City": "Westerstede",
  "Latitude": "53.268.501",
  "Longitude": "07.945.108",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.268501,7.945108",
  "id": "53.268501,7.945108"
}, {
  "Brand": "ENI",
  "Street": "Jagsttal-Ost",
  "PostCode": 74259,
  "City": "Widdern",
  "Latitude": "49.315.915",
  "Longitude": "09.419.485",
  "Telephone": "+49 (0) 62985646",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.315915,9.419485",
  "id": "49.315915,9.419485"
}, {
  "Brand": "TOTAL",
  "Street": "MARTIN-LUTHER-STR. 11",
  "PostCode": 71636,
  "City": "LUDWIGSBURG",
  "Latitude": "48.896.111",
  "Longitude": "09.180.000",
  "Telephone": "07141-923171",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.896111,9.18",
  "id": "48.896111,9.18"
}, {
  "Brand": "ARAL",
  "Street": "Robert-Bosch-Str. 1",
  "PostCode": 73257,
  "City": "Köngen",
  "Latitude": "48.685.089",
  "Longitude": "09.373.676",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.685089,9.373676",
  "id": "48.685089,9.373676"
}, {
  "Brand": "TOTAL",
  "Street": "PROVINZIALSTR. 55",
  "PostCode": 53127,
  "City": "BONN",
  "Latitude": "50.711.946",
  "Longitude": "07.067.286",
  "Telephone": "0228-254392",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.711946,7.067286",
  "id": "50.711946,7.067286"
}, {
  "Brand": "TOTAL",
  "Street": "ALTE FRANKFURTER STR. 92",
  "PostCode": 61118,
  "City": "BAD VILBEL",
  "Latitude": "50.165.029",
  "Longitude": "08.724.259",
  "Telephone": "06101-85770",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.165029,8.724259",
  "id": "50.165029,8.724259"
}, {
  "Brand": "ARAL",
  "Street": "Gerberstraße",
  "PostCode": 51789,
  "City": "Lindlar",
  "Latitude": "51.026.487",
  "Longitude": "07.416.176",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.026487,7.416176",
  "id": "51.026487,7.416176"
}, {
  "Brand": "ARAL",
  "Street": "Frankenstr.",
  "PostCode": 97762,
  "City": "Hammelburg",
  "Latitude": "50.128.079",
  "Longitude": "09.936.364",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.128079,9.936364",
  "id": "50.128079,9.936364"
}, {
  "Brand": "ARAL",
  "Street": "Friedberger Landstr. 325",
  "PostCode": 60389,
  "City": "Frankfurt",
  "Latitude": "50.140.379",
  "Longitude": "08.698.532",
  "Telephone": "06913025453",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.140379,8.698532",
  "id": "50.140379,8.698532"
}, {
  "Brand": "TOTAL",
  "Street": "WOLDEGKER STR. 40",
  "PostCode": 17036,
  "City": "NEUBRANDENBURG",
  "Latitude": "53.556.247",
  "Longitude": "13.312.756",
  "Telephone": "0395-37937301",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.556247,13.312756",
  "id": "53.556247,13.312756"
}, {
  "Brand": "TOTAL",
  "Street": "LEIPHEIMER STR. 60",
  "PostCode": 89233,
  "City": "NEU-ULM",
  "Latitude": "48.405.597",
  "Longitude": "10.038.080",
  "Telephone": "0731-714728",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.405597,10.03808",
  "id": "48.405597,10.03808"
}, {
  "Brand": "OMV",
  "Street": "Renkenweg 1",
  "PostCode": 83026,
  "City": "Rosenheim",
  "Latitude": "47.843.310",
  "Longitude": "12.081.960",
  "Telephone": "+49-8031-463206",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "47.84331,12.08196",
  "id": "47.84331,12.08196"
}, {
  "Brand": "ARAL",
  "Street": "Sonnenfeld 2",
  "PostCode": 6766,
  "City": "Bitterfeld",
  "Latitude": "51.635.574",
  "Longitude": "12.199.639",
  "Telephone": "0349397570",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.635574,12.199639",
  "id": "51.635574,12.199639"
}, {
  "Brand": "ARAL",
  "Street": "Carl Benz Straße 11",
  "PostCode": 57299,
  "City": "Burbach",
  "Latitude": "50.750.400",
  "Longitude": "08.100.061",
  "Telephone": "027366891",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "17:00",
  "SundayFrom": "00:00",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "50.7504,8.100061",
  "id": "50.7504,8.100061"
}, {
  "Brand": "TOTAL",
  "Street": "MOOSACHER STR.36",
  "PostCode": 80809,
  "City": "MUENCHEN",
  "Latitude": "48.186.452",
  "Longitude": "11.556.034",
  "Telephone": "089/35756644",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.186452,11.556034",
  "id": "48.186452,11.556034"
}, {
  "Brand": "ENI",
  "Street": "Platanenallee 11",
  "PostCode": 63739,
  "City": "Aschaffenburg",
  "Latitude": "49.976.890",
  "Longitude": "09.152.580",
  "Telephone": "+49 (0) 60214532",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.97689,9.15258",
  "id": "49.97689,9.15258"
}, {
  "Brand": "ENI",
  "Street": "Roemeraecker 5",
  "PostCode": 76351,
  "City": "Linkenheim-Hochstetten",
  "Latitude": "49.137.682",
  "Longitude": "08.427.132",
  "Telephone": "07247/9544915",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.137682,8.427132",
  "id": "49.137682,8.427132"
}, {
  "Brand": "Westfalen",
  "Street": "Werner Hellweg 526",
  "PostCode": 44894,
  "City": "BOCHUM",
  "Latitude": "51.492.400",
  "Longitude": "07.310.720",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.4924,7.31072",
  "id": "51.4924,7.31072"
}, {
  "Brand": "OMV",
  "Street": "Burgkirchener Str. 11",
  "PostCode": 84489,
  "City": "Burghausen",
  "Latitude": "48.175.700",
  "Longitude": "12.824.440",
  "Telephone": "+49-8677-66198",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.1757,12.82444",
  "id": "48.1757,12.82444"
}, {
  "Brand": "ENI",
  "Street": "Kantstrasse 64",
  "PostCode": 67663,
  "City": "Kaiserslautern",
  "Latitude": "49.432.160",
  "Longitude": "07.781.050",
  "Telephone": "+49 (0) 63131152",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.43216,7.78105",
  "id": "49.43216,7.78105"
}, {
  "Brand": "OMV",
  "Street": "Heinrich-Wieland-Str. 97",
  "PostCode": 81735,
  "City": "München",
  "Latitude": "48.112.820",
  "Longitude": "11.648.060",
  "Telephone": "+49-89-42721395",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.11282,11.64806",
  "id": "48.11282,11.64806"
}, {
  "Brand": "OMV",
  "Street": "Garmischer Str. 5",
  "PostCode": 82418,
  "City": "Murnau",
  "Latitude": "47.672.050",
  "Longitude": "11.201.290",
  "Telephone": "+49-8841-1285",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.67205,11.20129",
  "id": "47.67205,11.20129"
}, {
  "Brand": "ENI",
  "Street": "Hohenkirchnerstr. 9",
  "PostCode": 34376,
  "City": "Immenhausen",
  "Latitude": "51.424.360",
  "Longitude": "09.480.250",
  "Telephone": "+49 (0) 56732573",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.42436,9.48025",
  "id": "51.42436,9.48025"
}, {
  "Brand": "TOTAL",
  "Street": "WEISSENBERGER STR. 10",
  "PostCode": 2708,
  "City": "LOEBAU",
  "Latitude": "51.103.302",
  "Longitude": "14.670.160",
  "Telephone": "03585-860090",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.103302,14.67016",
  "id": "51.103302,14.67016"
}, {
  "Brand": "OMV",
  "Street": "Nürnberger Str. 210",
  "PostCode": 90579,
  "City": "Langenzenn",
  "Latitude": "49.494.250",
  "Longitude": "10.817.670",
  "Telephone": "+49-9101-6594",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.49425,10.81767",
  "id": "49.49425,10.81767"
}, {
  "Brand": "Westfalen",
  "Street": "Bottroper 331",
  "PostCode": 45356,
  "City": "ESSEN",
  "Latitude": "51.484.900",
  "Longitude": "06.972.060",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.4849,6.97206",
  "id": "51.4849,6.97206"
}, {
  "Brand": "ENI",
  "Street": "Am Kreuzstein 55",
  "PostCode": 63477,
  "City": "Maintal",
  "Latitude": "50.148.110",
  "Longitude": "08.807.830",
  "Telephone": "+49 (0) 61096163",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.14811,8.80783",
  "id": "50.14811,8.80783"
}, {
  "Brand": "TOTAL",
  "Street": "BONNER STR. 71",
  "PostCode": 53332,
  "City": "BORNHEIM",
  "Latitude": "50.756.348",
  "Longitude": "07.008.644",
  "Telephone": "02222-9890200",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.756348,7.008644",
  "id": "50.756348,7.008644"
}, {
  "Brand": "ARAL",
  "Street": "Zum Apelstein 1",
  "PostCode": 4158,
  "City": "Leipzig / Lindenthal",
  "Latitude": "51.393.551",
  "Longitude": "12.341.964",
  "Telephone": "03414618761",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "05:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.393551,12.341964",
  "id": "51.393551,12.341964"
}, {
  "Brand": "TOTAL",
  "Street": "GOERLITZER STR.36",
  "PostCode": 2906,
  "City": "NIESKY",
  "Latitude": "51.288.299",
  "Longitude": "14.820.580",
  "Telephone": "03588-205610",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.288299,14.82058",
  "id": "51.288299,14.82058"
}, {
  "Brand": "TOTAL",
  "Street": "VLAEMISCHE STR. 40",
  "PostCode": 49688,
  "City": "LASTRUP",
  "Latitude": "52.797.332",
  "Longitude": "07.865.608",
  "Telephone": "04472-1450",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.797332,7.865608",
  "id": "52.797332,7.865608"
}, {
  "Brand": "TOTAL",
  "Street": "HERMANNSTEINERSTR. 30",
  "PostCode": 35576,
  "City": "WETZLAR",
  "Latitude": "50.568.340",
  "Longitude": "08.499.740",
  "Telephone": "06441-36333",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.56834,8.49974",
  "id": "50.56834,8.49974"
}, {
  "Brand": "ARAL",
  "Street": "Zur Oschütz 1",
  "PostCode": 7338,
  "City": "Kaulsdorf",
  "Latitude": "50.617.237",
  "Longitude": "11.428.040",
  "Telephone": "03673332630",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.617237,11.42804",
  "id": "50.617237,11.42804"
}, {
  "Brand": "TOTAL",
  "Street": "U.-VON-HUTTEN-STR. 7a",
  "PostCode": 18069,
  "City": "ROSTOCK",
  "Latitude": "54.095.380",
  "Longitude": "12.083.140",
  "Telephone": "0381-8083459",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.09538,12.08314",
  "id": "54.09538,12.08314"
}, {
  "Brand": "ARAL",
  "Street": "A3",
  "PostCode": 91056,
  "City": "Erlangen",
  "Latitude": "49.580.301",
  "Longitude": "10.930.001",
  "Telephone": "09131758670",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.580301,10.930001",
  "id": "49.580301,10.930001"
}, {
  "Brand": "TOTAL",
  "Street": "ECKENHEIMER LANDSTR. 181",
  "PostCode": 60320,
  "City": "FRANKFURT",
  "Latitude": "50.132.180",
  "Longitude": "08.683.576",
  "Telephone": "06927290055",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.13218,8.683576",
  "id": "50.13218,8.683576"
}, {
  "Brand": "ENI",
  "Street": "Bahnhofstr. 18",
  "PostCode": 77709,
  "City": "Wolfach",
  "Latitude": "48.294.150",
  "Longitude": "08.221.185",
  "Telephone": "+49 (0) 78344041",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.29415,8.221185",
  "id": "48.29415,8.221185"
}, {
  "Brand": "ARAL",
  "Street": "Unterhachinger Straße 28",
  "PostCode": 85521,
  "City": "Ottobrunn",
  "Latitude": "48.063.145",
  "Longitude": "11.652.012",
  "Telephone": "0896093130",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.063145,11.652012",
  "id": "48.063145,11.652012"
}, {
  "Brand": "ARAL",
  "Street": "Portitzer Straße 71",
  "PostCode": 4425,
  "City": "Taucha",
  "Latitude": "51.382.079",
  "Longitude": "12.477.612",
  "Telephone": "03429863646",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "06:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.382079,12.477612",
  "id": "51.382079,12.477612"
}, {
  "Brand": "TOTAL",
  "Street": "KLINGENTHALER STR. 3",
  "PostCode": 8261,
  "City": "SCHOENECK",
  "Latitude": "50.394.992",
  "Longitude": "12.340.570",
  "Telephone": "037464-82595",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.394992,12.34057",
  "id": "50.394992,12.34057"
}, {
  "Brand": "TOTAL",
  "Street": "VELTENER STR. 22",
  "PostCode": 16761,
  "City": "HENNIGSDORF",
  "Latitude": "52.653.899",
  "Longitude": "13.204.295",
  "Telephone": "03302-810824",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.653899,13.204295",
  "id": "52.653899,13.204295"
}, {
  "Brand": "TOTAL",
  "Street": "BODENBACHER STR. 64",
  "PostCode": 1277,
  "City": "DRESDEN",
  "Latitude": "51.029.399",
  "Longitude": "13.790.689",
  "Telephone": "0351-2561098",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.029399,13.790689",
  "id": "51.029399,13.790689"
}, {
  "Brand": "TOTAL",
  "Street": "RHEINBERGER STR. 15",
  "PostCode": 46509,
  "City": "XANTEN",
  "Latitude": "51.654.000",
  "Longitude": "06.470.730",
  "Telephone": "02801-5360",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.654,6.47073",
  "id": "51.654,6.47073"
}, {
  "Brand": "TOTAL",
  "Street": "DUERENER STR. 308",
  "PostCode": 52249,
  "City": "ESCHWEILER",
  "Latitude": "50.819.706",
  "Longitude": "06.292.115",
  "Telephone": "02403-21572",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.819706,6.292115",
  "id": "50.819706,6.292115"
}, {
  "Brand": "TOTAL",
  "Street": "BAB OST/A 11",
  "PostCode": 16244,
  "City": "SCHORFHEIDE-BUCKOWSEE",
  "Latitude": "52.883.315",
  "Longitude": "13.704.688",
  "Telephone": "033363-46204",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.883315,13.704688",
  "id": "52.883315,13.704688"
}, {
  "Brand": "ARAL",
  "Street": "Lindauer Straße 26",
  "PostCode": 88069,
  "City": "Tettnang",
  "Latitude": "47.668.518",
  "Longitude": "09.591.257",
  "Telephone": "075428456",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.668518,9.591257",
  "id": "47.668518,9.591257"
}, {
  "Brand": "ARAL",
  "Street": "An der Autobahn 8",
  "PostCode": 49733,
  "City": "Haren",
  "Latitude": "52.755.852",
  "Longitude": "07.164.320",
  "Telephone": "059358099050",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.755852,7.16432",
  "id": "52.755852,7.16432"
}, {
  "Brand": "ARAL",
  "Street": "Rheinlanddamm 103",
  "PostCode": 44139,
  "City": "Dortmund",
  "Latitude": "51.499.353",
  "Longitude": "07.471.720",
  "Telephone": "02319128872",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.499353,7.47172",
  "id": "51.499353,7.47172"
}, {
  "Brand": "Westfalen",
  "Street": "Elberfelder 148",
  "PostCode": 42477,
  "City": "RADEVORMWALD",
  "Latitude": "51.202.200",
  "Longitude": "07.326.060",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.2022,7.32606",
  "id": "51.2022,7.32606"
}, {
  "Brand": "TOTAL",
  "Street": "KURHESSENSTR. 111",
  "PostCode": 60431,
  "City": "FRANKFURT",
  "Latitude": "50.148.045",
  "Longitude": "08.656.484",
  "Telephone": "069-512131",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.148045,8.656484",
  "id": "50.148045,8.656484"
}, {
  "Brand": "ARAL",
  "Street": "Holsterfeld 2",
  "PostCode": 48499,
  "City": "Salzbergen",
  "Latitude": "52.326.720",
  "Longitude": "07.429.440",
  "Telephone": "0597197260",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.32672,7.42944",
  "id": "52.32672,7.42944"
}, {
  "Brand": "ENI",
  "Street": "Damaschkeweg 64",
  "PostCode": 15234,
  "City": "Frankfurt/Oder",
  "Latitude": "52.336.908",
  "Longitude": "14.520.942",
  "Telephone": "+49 (0) 33550000",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.336908,14.520942",
  "id": "52.336908,14.520942"
}, {
  "Brand": "ARAL",
  "Street": "A31",
  "PostCode": 49835,
  "City": "Wietmarschen",
  "Latitude": "52.501.797",
  "Longitude": "07.201.255",
  "Telephone": "05925905968",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.501797,7.201255",
  "id": "52.501797,7.201255"
}, {
  "Brand": "ARAL",
  "Street": "A 38",
  "PostCode": 6542,
  "City": "Allstedt",
  "Latitude": "51.429.313",
  "Longitude": "11.393.849",
  "Telephone": "03465267690",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.429313,11.393849",
  "id": "51.429313,11.393849"
}, {
  "Brand": "Westfalen",
  "Street": "Lennestr. 17",
  "PostCode": 58791,
  "City": "WERDOHL",
  "Latitude": "51.257.100",
  "Longitude": "07.757.460",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.2571,7.75746",
  "id": "51.2571,7.75746"
}, {
  "Brand": "ARAL",
  "Street": "Tiergartenstraße 5",
  "PostCode": 64646,
  "City": "Heppenheim",
  "Latitude": "49.637.095",
  "Longitude": "08.618.700",
  "Telephone": "06252/9429340",
  "WeekDayFrom": "08:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "49.637095,8.6187",
  "id": "49.637095,8.6187"
}, {
  "Brand": "ARAL",
  "Street": "A 27",
  "PostCode": 27299,
  "City": "Langwedel",
  "Latitude": "52.999.475",
  "Longitude": "09.181.822",
  "Telephone": "04232306040",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.999475,9.181822",
  "id": "52.999475,9.181822"
}, {
  "Brand": "ENI",
  "Street": "Haenchener Strasse 4",
  "PostCode": 3050,
  "City": "Cottbus",
  "Latitude": "51.720.713",
  "Longitude": "14.344.494",
  "Telephone": "+49 (0) 35553408",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.720713,14.344494",
  "id": "51.720713,14.344494"
}, {
  "Brand": "ARAL",
  "Street": "Amandus-Stubbe-Straße 6",
  "PostCode": 22113,
  "City": "Hamburg",
  "Latitude": "53.505.743",
  "Longitude": "10.089.665",
  "Telephone": "04082245990",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.505743,10.089665",
  "id": "53.505743,10.089665"
}, {
  "Brand": "ARAL",
  "Street": "Gewerbeweg 8",
  "PostCode": 49751,
  "City": "Sögel",
  "Latitude": "52.834.241",
  "Longitude": "07.515.321",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.834241,7.515321",
  "id": "52.834241,7.515321"
}, {
  "Brand": "TOTAL",
  "Street": "THOMAS-MUENTZER-STR. 5",
  "PostCode": 6217,
  "City": "MERSEBURG",
  "Latitude": "51.344.918",
  "Longitude": "11.987.999",
  "Telephone": "03461-503727",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.344918,11.987999",
  "id": "51.344918,11.987999"
}, {
  "Brand": "TOTAL",
  "Street": "ELSTERWERDAER STR. 35",
  "PostCode": 1990,
  "City": "ORTRAND",
  "Latitude": "51.375.599",
  "Longitude": "13.749.588",
  "Telephone": "035755-53819",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.375599,13.749588",
  "id": "51.375599,13.749588"
}, {
  "Brand": "TOTAL",
  "Street": "STUTTGARTER STR. 62",
  "PostCode": 73614,
  "City": "SCHORNDORF",
  "Latitude": "48.805.717",
  "Longitude": "09.508.026",
  "Telephone": "07181-75965",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.805717,9.508026",
  "id": "48.805717,9.508026"
}, {
  "Brand": "TOTAL",
  "Street": "FRANKFURTER STR. 37",
  "PostCode": 15907,
  "City": "LUEBBEN",
  "Latitude": "51.943.099",
  "Longitude": "13.913.482",
  "Telephone": "03546-3106",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.943099,13.913482",
  "id": "51.943099,13.913482"
}, {
  "Brand": "Westfalen",
  "Street": "Brueckenstr. 85",
  "PostCode": 51643,
  "City": "GUMMERSBACH",
  "Latitude": "51.023.600",
  "Longitude": "07.554.630",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.0236,7.55463",
  "id": "51.0236,7.55463"
}, {
  "Brand": "ENI",
  "Street": "Reutlinger Strasse 68",
  "PostCode": 72766,
  "City": "Reutlingen-Sondelf.",
  "Latitude": "48.515.040",
  "Longitude": "09.225.980",
  "Telephone": "+49 (0) 71214918",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.51504,9.22598",
  "id": "48.51504,9.22598"
}, {
  "Brand": "TOTAL",
  "Street": "BREMIGSWIESE 2",
  "PostCode": 51674,
  "City": "WIEHL",
  "Latitude": "50.944.154",
  "Longitude": "07.573.806",
  "Telephone": "02262-7084871",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.944154,7.573806",
  "id": "50.944154,7.573806"
}, {
  "Brand": "ARAL",
  "Street": "Otto-Perutz-Straße 1-3",
  "PostCode": 81829,
  "City": "München",
  "Latitude": "48.137.948",
  "Longitude": "11.712.750",
  "Telephone": "089460990620",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.137948,11.71275",
  "id": "48.137948,11.71275"
}, {
  "Brand": "ENI",
  "Street": "Puetrichstrasse 29",
  "PostCode": 82362,
  "City": "Weilheim",
  "Latitude": "47.843.850",
  "Longitude": "11.147.610",
  "Telephone": "+49 (0) 8812885",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.84385,11.14761",
  "id": "47.84385,11.14761"
}, {
  "Brand": "ARAL",
  "Street": "A 39 Salzgitterhüttenblick Nord",
  "PostCode": 38239,
  "City": "Salzgitter",
  "Latitude": "52.191.301",
  "Longitude": "10.399.207",
  "Telephone": "053009304490",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.191301,10.399207",
  "id": "52.191301,10.399207"
}, {
  "Brand": "ENI",
  "Street": "Pockinger Strasse 127",
  "PostCode": 94072,
  "City": "Bad Fuessing",
  "Latitude": "48.358.510",
  "Longitude": "13.311.838",
  "Telephone": "+49 (0) 85319800",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.35851,13.311838",
  "id": "48.35851,13.311838"
}, {
  "Brand": "Westfalen",
  "Street": "Horster Str. 21",
  "PostCode": 59368,
  "City": "WERNE",
  "Latitude": "51.668.100",
  "Longitude": "07.640.410",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.6681,7.64041",
  "id": "51.6681,7.64041"
}, {
  "Brand": "ARAL",
  "Street": "Elseyer Straße 43",
  "PostCode": 58119,
  "City": "Hagen",
  "Latitude": "51.364.132",
  "Longitude": "07.553.350",
  "Telephone": "0233451418",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.364132,7.55335",
  "id": "51.364132,7.55335"
}, {
  "Brand": "OMV",
  "Street": "Wackersdorfer Str. 52",
  "PostCode": 92421,
  "City": "Schwandorf",
  "Latitude": "49.322.640",
  "Longitude": "12.121.560",
  "Telephone": "+49-9431-2521",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.32264,12.12156",
  "id": "49.32264,12.12156"
}, {
  "Brand": "Westfalen",
  "Street": "Wüllener Str. 64",
  "PostCode": 48683,
  "City": "AHAUS",
  "Latitude": "52.073.800",
  "Longitude": "06.997.240",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0738,6.99724",
  "id": "52.0738,6.99724"
}, {
  "Brand": "Westfalen",
  "Street": "Schiffahrter Damm 29",
  "PostCode": 48145,
  "City": "MUENSTER",
  "Latitude": "51.969.100",
  "Longitude": "07.656.040",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9691,7.65604",
  "id": "51.9691,7.65604"
}, {
  "Brand": "Westfalen",
  "Street": "Kirchsiek 11",
  "PostCode": 32457,
  "City": "PORTA WESTFALICA",
  "Latitude": "52.239.400",
  "Longitude": "08.925.170",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.2394,8.92517",
  "id": "52.2394,8.92517"
}, {
  "Brand": "ENI",
  "Street": "Karlstr. 60",
  "PostCode": 71394,
  "City": "Kernen",
  "Latitude": "48.808.700",
  "Longitude": "09.325.290",
  "Telephone": "+49 (0) 7151-206",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.8087,9.32529",
  "id": "48.8087,9.32529"
}, {
  "Brand": "ENI",
  "Street": "Bayreuther Str. 46",
  "PostCode": 92224,
  "City": "Amberg",
  "Latitude": "49.457.070",
  "Longitude": "11.848.740",
  "Telephone": "+49 (0) 96216202",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.45707,11.84874",
  "id": "49.45707,11.84874"
}, {
  "Brand": "OMV",
  "Street": "Hauptstr. 87",
  "PostCode": 72636,
  "City": "Frickenhausen",
  "Latitude": "48.586.360",
  "Longitude": "09.365.520",
  "Telephone": "+49-7022-949270",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.58636,9.36552",
  "id": "48.58636,9.36552"
}, {
  "Brand": "Westfalen",
  "Street": "Altenberger 128",
  "PostCode": 48565,
  "City": "STEINFURT",
  "Latitude": "52.111.000",
  "Longitude": "07.401.070",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.111,7.40107",
  "id": "52.111,7.40107"
}, {
  "Brand": "OMV",
  "Street": "Hauptstr. 30",
  "PostCode": 91183,
  "City": "Wassermungenau",
  "Latitude": "49.216.780",
  "Longitude": "10.884.440",
  "Telephone": "+49-9873-257",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.21678,10.88444",
  "id": "49.21678,10.88444"
}, {
  "Brand": "ENI",
  "Street": "Schwetzinger Strasse 138",
  "PostCode": 69124,
  "City": "Heidelberg",
  "Latitude": "49.384.940",
  "Longitude": "08.672.950",
  "Telephone": "+49 (0) 62217855",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.38494,8.67295",
  "id": "49.38494,8.67295"
}, {
  "Brand": "Westfalen",
  "Street": "Holtwicker Str. 31",
  "PostCode": 48653,
  "City": "COESFELD",
  "Latitude": "51.952.800",
  "Longitude": "07.163.960",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9528,7.16396",
  "id": "51.9528,7.16396"
}, {
  "Brand": "OMV",
  "Street": "Altdorfer Str. 15",
  "PostCode": 90537,
  "City": "Feucht",
  "Latitude": "49.377.140",
  "Longitude": "11.215.550",
  "Telephone": "+49-9128-3594",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.37714,11.21555",
  "id": "49.37714,11.21555"
}, {
  "Brand": "ENI",
  "Street": "Kammermoosstr. 20",
  "PostCode": 88339,
  "City": "Bad Waldsee",
  "Latitude": "47.902.260",
  "Longitude": "09.698.239",
  "Telephone": "+49 (0) 75243111",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.90226,9.698239",
  "id": "47.90226,9.698239"
}, {
  "Brand": "TOTAL",
  "Street": "HOMBURGER STR. 50",
  "PostCode": 61184,
  "City": "KARBEN",
  "Latitude": "50.230.880",
  "Longitude": "08.770.400",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.23088,8.7704",
  "id": "50.23088,8.7704"
}, {
  "Brand": "TOTAL",
  "Street": "STRALSUNDER CH. 18",
  "PostCode": 18528,
  "City": "BERGEN",
  "Latitude": "54.407.479",
  "Longitude": "13.418.115",
  "Telephone": "03838-22354",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.407479,13.418115",
  "id": "54.407479,13.418115"
}, {
  "Brand": "TOTAL",
  "Street": "INDUSTRIESTRASSE 2",
  "PostCode": 56412,
  "City": "HEILIGENROTH",
  "Latitude": "50.451.513",
  "Longitude": "07.848.464",
  "Telephone": "02602-8387954",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.451513,7.848464",
  "id": "50.451513,7.848464"
}, {
  "Brand": "TOTAL",
  "Street": "APFELWEG 2",
  "PostCode": 38871,
  "City": "ILSENBURG",
  "Latitude": "51.873.069",
  "Longitude": "10.692.185",
  "Telephone": "039452-49262",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.873069,10.692185",
  "id": "51.873069,10.692185"
}, {
  "Brand": "TOTAL",
  "Street": "SCHORNDORFER STR. 10",
  "PostCode": 71384,
  "City": "WEINSTADT",
  "Latitude": "48.814.110",
  "Longitude": "09.369.070",
  "Telephone": "0715161243",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.81411,9.36907",
  "id": "48.81411,9.36907"
}, {
  "Brand": "ARAL",
  "Street": "Am Weidendamm",
  "PostCode": 15831,
  "City": "Groß Kienitz",
  "Latitude": "52.318.993",
  "Longitude": "13.470.911",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.318993,13.470911",
  "id": "52.318993,13.470911"
}, {
  "Brand": "ARAL",
  "Street": "Bochumer Straße 1",
  "PostCode": 45663,
  "City": "Recklinghausen",
  "Latitude": "51.582.787",
  "Longitude": "07.213.093",
  "Telephone": "0236134084",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.582787,7.213093",
  "id": "51.582787,7.213093"
}, {
  "Brand": "ARAL",
  "Street": "Maurerstraße 37",
  "PostCode": 52477,
  "City": "Alsdorf",
  "Latitude": "50.879.033",
  "Longitude": "06.183.691",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.879033,6.183691",
  "id": "50.879033,6.183691"
}, {
  "Brand": "ENI",
  "Street": "Lindauer Str. 43",
  "PostCode": 88069,
  "City": "Tettnang",
  "Latitude": "47.666.620",
  "Longitude": "09.593.750",
  "Telephone": "+49 (0) 75427400",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.66662,9.59375",
  "id": "47.66662,9.59375"
}, {
  "Brand": "Westfalen",
  "Street": "Schneidemuehler Str. 9",
  "PostCode": 48157,
  "City": "MUENSTER",
  "Latitude": "51.994.600",
  "Longitude": "07.649.460",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9946,7.64946",
  "id": "51.9946,7.64946"
}, {
  "Brand": "ARAL",
  "Street": "Im Seewinkel 26",
  "PostCode": 77652,
  "City": "Offenburg",
  "Latitude": "48.481.575",
  "Longitude": "07.936.881",
  "Telephone": "078193226252",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:30",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.481575,7.936881",
  "id": "48.481575,7.936881"
}, {
  "Brand": "ARAL",
  "Street": "Bautzner Landstraße 246",
  "PostCode": 1328,
  "City": "Dresden OT Weißig",
  "Latitude": "51.060.353",
  "Longitude": "13.874.702",
  "Telephone": "03512690772",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.060353,13.874702",
  "id": "51.060353,13.874702"
}, {
  "Brand": "TOTAL",
  "Street": "AUGSBURGER STRASSE 16",
  "PostCode": 86609,
  "City": "DONAUWOERTH",
  "Latitude": "48.710.815",
  "Longitude": "10.787.838",
  "Telephone": "0906-4494",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.710815,10.787838",
  "id": "48.710815,10.787838"
}, {
  "Brand": "ARAL",
  "Street": "A7",
  "PostCode": 34253,
  "City": "Lohfelden",
  "Latitude": "51.277.405",
  "Longitude": "09.531.849",
  "Telephone": "056158580000",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.277405,9.531849",
  "id": "51.277405,9.531849"
}, {
  "Brand": "Westfalen",
  "Street": "Appelhuelsener Str. 16",
  "PostCode": 48301,
  "City": "NOTTULN",
  "Latitude": "51.928.400",
  "Longitude": "07.361.530",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9284,7.36153",
  "id": "51.9284,7.36153"
}, {
  "Brand": "TOTAL",
  "Street": "COLBITZER STR.",
  "PostCode": 39326,
  "City": "WOLMIRSTEDT",
  "Latitude": "52.257.223",
  "Longitude": "11.628.863",
  "Telephone": "039201-21376",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.257223,11.628863",
  "id": "52.257223,11.628863"
}, {
  "Brand": "ARAL",
  "Street": "Gewerbestraße 1",
  "PostCode": 18233,
  "City": "Neubukow",
  "Latitude": "54.039.041",
  "Longitude": "11.691.152",
  "Telephone": "03829416840",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "54.039041,11.691152",
  "id": "54.039041,11.691152"
}, {
  "Brand": "ENI",
  "Street": "Heilbronner Strasse 31",
  "PostCode": 74177,
  "City": "Bad Friedrichshall",
  "Latitude": "49.219.150",
  "Longitude": "09.215.130",
  "Telephone": "+49 (0) 71362371",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.21915,9.21513",
  "id": "49.21915,9.21513"
}, {
  "Brand": "ENI",
  "Street": "Enderstrasse 92 e",
  "PostCode": 1277,
  "City": "Dresden",
  "Latitude": "51.033.510",
  "Longitude": "13.806.040",
  "Telephone": "0351 / 25020192",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.03351,13.80604",
  "id": "51.03351,13.80604"
}, {
  "Brand": "ARAL",
  "Street": "Schäferstrasse 51",
  "PostCode": 44147,
  "City": "Dortmund",
  "Latitude": "51.531.412",
  "Longitude": "07.440.737",
  "Telephone": "023118983645",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "51.531412,7.440737",
  "id": "51.531412,7.440737"
}, {
  "Brand": "TOTAL",
  "Street": "ADENAUER ALLEE 10",
  "PostCode": 25524,
  "City": "ITZEHOE",
  "Latitude": "53.921.710",
  "Longitude": "09.512.394",
  "Telephone": "04821-3333",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.92171,9.512394",
  "id": "53.92171,9.512394"
}, {
  "Brand": "TOTAL",
  "Street": "AUTOHOF WILHELM-MAYBACH-STR. 3",
  "PostCode": 89312,
  "City": "GUENZBURG",
  "Latitude": "48.427.778",
  "Longitude": "10.288.333",
  "Telephone": "08221-2044013",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.427778,10.288333",
  "id": "48.427778,10.288333"
}, {
  "Brand": "ENI",
  "Street": "Wuerzburger Strasse 4",
  "PostCode": 97218,
  "City": "Gerbrunn",
  "Latitude": "49.781.458",
  "Longitude": "09.978.483",
  "Telephone": "+49 (0) 93170768",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.781458,9.978483",
  "id": "49.781458,9.978483"
}, {
  "Brand": "ARAL",
  "Street": "A3",
  "PostCode": 63879,
  "City": "Weibersbrunn",
  "Latitude": "49.896.365",
  "Longitude": "09.395.583",
  "Telephone": "06094",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.896365,9.395583",
  "id": "49.896365,9.395583"
}, {
  "Brand": "OMV",
  "Street": "Allersberger Str. 22",
  "PostCode": 91161,
  "City": "Hilpoltstein",
  "Latitude": "49.193.710",
  "Longitude": "11.189.060",
  "Telephone": "+49-9174-1279",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.19371,11.18906",
  "id": "49.19371,11.18906"
}, {
  "Brand": "TOTAL",
  "Street": "HARTH.HAUPTSTR. 50-52",
  "PostCode": 70794,
  "City": "FILDERSTADT-HARTH",
  "Latitude": "48.651.634",
  "Longitude": "09.246.344",
  "Telephone": "07158-982131",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.651634,9.246344",
  "id": "48.651634,9.246344"
}, {
  "Brand": "Westfalen",
  "Street": "Osnabruecker Str. 4",
  "PostCode": 32289,
  "City": "ROEDINGHAUSEN",
  "Latitude": "52.198.700",
  "Longitude": "08.461.480",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.1987,8.46148",
  "id": "52.1987,8.46148"
}, {
  "Brand": "ENI",
  "Street": "Im Gewerbepark",
  "PostCode": 96155,
  "City": "Buttenheim",
  "Latitude": "49.798.570",
  "Longitude": "11.027.480",
  "Telephone": "+49 (0) 95454455",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.79857,11.02748",
  "id": "49.79857,11.02748"
}, {
  "Brand": "ENI",
  "Street": "Frontenhausener Str. 40",
  "PostCode": 84140,
  "City": "Gangkofen",
  "Latitude": "48.438.501",
  "Longitude": "12.562.363",
  "Telephone": "+49 (0) 8722481",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.438501,12.562363",
  "id": "48.438501,12.562363"
}, {
  "Brand": "Westfalen",
  "Street": "Grevener Str. 5",
  "PostCode": 49549,
  "City": "LADBERGEN",
  "Latitude": "52.136.000",
  "Longitude": "07.736.310",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.136,7.73631",
  "id": "52.136,7.73631"
}, {
  "Brand": "ENI",
  "Street": "Wittstocker Kreuz, Dorfstrae 67",
  "PostCode": 16909,
  "City": "Liebenthal",
  "Latitude": "53.148.742",
  "Longitude": "12.397.238",
  "Telephone": "+49 (0) 33962509",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "53.148742,12.397238",
  "id": "53.148742,12.397238"
}, {
  "Brand": "ENI",
  "Street": "Hofheimer Str. 61",
  "PostCode": 65719,
  "City": "Hofheim-Lorsbach",
  "Latitude": "50.112.312",
  "Longitude": "08.426.274",
  "Telephone": "+49 (0) 61929019",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.112312,8.426274",
  "id": "50.112312,8.426274"
}, {
  "Brand": "OMV",
  "Street": "Heidelberger Str. 49",
  "PostCode": 76646,
  "City": "Bruchsal",
  "Latitude": "49.136.130",
  "Longitude": "08.604.290",
  "Telephone": "+49-7251-300691",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.13613,8.60429",
  "id": "49.13613,8.60429"
}, {
  "Brand": "ARAL",
  "Street": "Flughafenstraße 7",
  "PostCode": 64347,
  "City": "Griesheim",
  "Latitude": "49.861.775",
  "Longitude": "08.587.139",
  "Telephone": "061558319360",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "49.861775,8.587139",
  "id": "49.861775,8.587139"
}, {
  "Brand": "Westfalen",
  "Street": "Dufkamp 87",
  "PostCode": 48703,
  "City": "STADTLOHN",
  "Latitude": "51.992.500",
  "Longitude": "06.927.300",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9925,6.9273",
  "id": "51.9925,6.9273"
}, {
  "Brand": "TOTAL",
  "Street": "BRUCHSALER STR. 15",
  "PostCode": 76698,
  "City": "UBSTADT",
  "Latitude": "49.155.665",
  "Longitude": "08.626.797",
  "Telephone": "07251-967011",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.155665,8.626797",
  "id": "49.155665,8.626797"
}, {
  "Brand": "TOTAL",
  "Street": "WEILRODER STR. 1",
  "PostCode": 61389,
  "City": "SCHMITTEN",
  "Latitude": "50.289.255",
  "Longitude": "08.461.242",
  "Telephone": "060842377",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.289255,8.461242",
  "id": "50.289255,8.461242"
}, {
  "Brand": "ENI",
  "Street": "Bat A 111 Stolper Heide",
  "PostCode": 16540,
  "City": "Hohen-Neuendorf",
  "Latitude": "52.651.383",
  "Longitude": "13.244.027",
  "Telephone": "+49 (0) 33024938",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "52.651383,13.244027",
  "id": "52.651383,13.244027"
}, {
  "Brand": "Westfalen",
  "Street": "Bahnhofstr. 30",
  "PostCode": 48317,
  "City": "DRENSTEINFURT",
  "Latitude": "51.800.000",
  "Longitude": "07.733.690",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8,7.73369",
  "id": "51.8,7.73369"
}, {
  "Brand": "ENI",
  "Street": "Oelsnitzer Str. 10-12",
  "PostCode": 8527,
  "City": "Plauen",
  "Latitude": "50.484.988",
  "Longitude": "12.139.003",
  "Telephone": "+49 (0) 37412248",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.484988,12.139003",
  "id": "50.484988,12.139003"
}, {
  "Brand": "ARAL",
  "Street": "Heisinger Straße 25",
  "PostCode": 87463,
  "City": "Dietmannsried",
  "Latitude": "47.806.843",
  "Longitude": "10.296.286",
  "Telephone": "083746710",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.806843,10.296286",
  "id": "47.806843,10.296286"
}, {
  "Brand": "ARAL",
  "Street": "Industriering 5",
  "PostCode": 49393,
  "City": "Lohne",
  "Latitude": "52.658.332",
  "Longitude": "08.200.740",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.658332,8.20074",
  "id": "52.658332,8.20074"
}, {
  "Brand": "ARAL",
  "Street": "Werner-von-Siemens-Straße 3",
  "PostCode": 83301,
  "City": "Traunreut",
  "Latitude": "47.966.321",
  "Longitude": "12.591.563",
  "Telephone": "086692368",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:30",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:30",
  "SundayTo": "22:30",
  "IsOpen24Hours": "Nein",
  "location": "47.966321,12.591563",
  "id": "47.966321,12.591563"
}, {
  "Brand": "TOTAL",
  "Street": "INNERE HERLASGRUENER STR. 17",
  "PostCode": 8233,
  "City": "TREUEN",
  "Latitude": "50.544.184",
  "Longitude": "12.299.170",
  "Telephone": "037468-2639",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.544184,12.29917",
  "id": "50.544184,12.29917"
}, {
  "Brand": "TOTAL",
  "Street": "AM STEINERNEN KREUZ 1-3",
  "PostCode": 96110,
  "City": "SCHESSLITZ",
  "Latitude": "49.979.998",
  "Longitude": "11.042.786",
  "Telephone": "0954-2942020",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.979998,11.042786",
  "id": "49.979998,11.042786"
}, {
  "Brand": "TOTAL",
  "Street": "AUGSBURGER STR. 29",
  "PostCode": 89331,
  "City": "BURGAU",
  "Latitude": "48.425.600",
  "Longitude": "10.413.850",
  "Telephone": "08222-410209",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.4256,10.41385",
  "id": "48.4256,10.41385"
}, {
  "Brand": "TOTAL",
  "Street": "WOLFHAGER STRASSE 276",
  "PostCode": 34128,
  "City": "KASSEL",
  "Latitude": "51.331.399",
  "Longitude": "09.455.723",
  "Telephone": "0561-882422",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.331399,9.455723",
  "id": "51.331399,9.455723"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 73",
  "PostCode": 67487,
  "City": "Maikammer",
  "Latitude": "49.306.461",
  "Longitude": "08.142.164",
  "Telephone": "063215222",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.306461,8.142164",
  "id": "49.306461,8.142164"
}, {
  "Brand": "TOTAL",
  "Street": "DEHMER STR. 52",
  "PostCode": 32549,
  "City": "BAD OEYNHAUSEN",
  "Latitude": "52.233.714",
  "Longitude": "08.833.370",
  "Telephone": "05731-4989381",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.233714,8.83337",
  "id": "52.233714,8.83337"
}, {
  "Brand": "TOTAL",
  "Street": "MERSEBURGER STR. 359",
  "PostCode": 6132,
  "City": "HALLE",
  "Latitude": "51.438.264",
  "Longitude": "11.984.794",
  "Telephone": "0345-7809911",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.438264,11.984794",
  "id": "51.438264,11.984794"
}, {
  "Brand": "TOTAL",
  "Street": "AUF DER BROOKE 2",
  "PostCode": 27404,
  "City": "ZEVEN-ELSDORF",
  "Latitude": "53.230.603",
  "Longitude": "09.342.874",
  "Telephone": "04286-9266416",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.230603,9.342874",
  "id": "53.230603,9.342874"
}, {
  "Brand": "ARAL",
  "Street": "Winckeläckerstr.",
  "PostCode": 86807,
  "City": "Buchloe",
  "Latitude": "48.028.308",
  "Longitude": "10.706.770",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.028308,10.70677",
  "id": "48.028308,10.70677"
}, {
  "Brand": "OMV",
  "Street": "Europastr. 3",
  "PostCode": 87700,
  "City": "Memmingen",
  "Latitude": "47.999.590",
  "Longitude": "10.152.820",
  "Telephone": "+49-8331-928485",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "47.99959,10.15282",
  "id": "47.99959,10.15282"
}, {
  "Brand": "TOTAL",
  "Street": "BURBACHER STR. 50",
  "PostCode": 66117,
  "City": "SAARBRUECKEN",
  "Latitude": "49.236.430",
  "Longitude": "06.942.589",
  "Telephone": "0681-703332",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.23643,6.942589",
  "id": "49.23643,6.942589"
}, {
  "Brand": "TOTAL",
  "Street": "BAB A5/MAHLBERG-OST",
  "PostCode": 77972,
  "City": "MAHLBERG",
  "Latitude": "48.306.630",
  "Longitude": "07.790.957",
  "Telephone": "07825-849631",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.30663,7.790957",
  "id": "48.30663,7.790957"
}, {
  "Brand": "TOTAL",
  "Street": "FELDSTR. 159-161",
  "PostCode": 45699,
  "City": "HERTEN",
  "Latitude": "51.607.199",
  "Longitude": "07.124.186",
  "Telephone": "02366-937333",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.607199,7.124186",
  "id": "51.607199,7.124186"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER STR. 107",
  "PostCode": 14547,
  "City": "BEELITZ",
  "Latitude": "52.252.376",
  "Longitude": "12.986.100",
  "Telephone": "033204-35508",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.252376,12.9861",
  "id": "52.252376,12.9861"
}, {
  "Brand": "TOTAL",
  "Street": "BAB WESTSEITE",
  "PostCode": 91620,
  "City": "OHRENBACH",
  "Latitude": "49.485.222",
  "Longitude": "10.210.361",
  "Telephone": "09865-986113",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.485222,10.210361",
  "id": "49.485222,10.210361"
}, {
  "Brand": "ENI",
  "Street": "Arneburger Strasse",
  "PostCode": 39576,
  "City": "Stendal",
  "Latitude": "52.612.140",
  "Longitude": "11.863.060",
  "Telephone": "+49 (0) 39317110",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "52.61214,11.86306",
  "id": "52.61214,11.86306"
}, {
  "Brand": "TOTAL",
  "Street": "KOECKERN OST / BAB 9",
  "PostCode": 6794,
  "City": "SANDERSDORF-BREHNA",
  "Latitude": "51.606.400",
  "Longitude": "12.185.646",
  "Telephone": "034954-39237",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.6064,12.185646",
  "id": "51.6064,12.185646"
}, {
  "Brand": "ARAL",
  "Street": "Beuchaer Straße 73",
  "PostCode": 4821,
  "City": "Brandis",
  "Latitude": "51.329.914",
  "Longitude": "12.592.826",
  "Telephone": "03429274221",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Ja",
  "location": "51.329914,12.592826",
  "id": "51.329914,12.592826"
}, {
  "Brand": "TOTAL",
  "Street": "BRANDISERSTR.1",
  "PostCode": 4916,
  "City": "SCHOENEWALDE",
  "Latitude": "51.813.387",
  "Longitude": "13.208.991",
  "Telephone": "0353626060",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.813387,13.208991",
  "id": "51.813387,13.208991"
}, {
  "Brand": "TOTAL",
  "Street": "SANDBREITE 1",
  "PostCode": 6869,
  "City": "COSWIG",
  "Latitude": "51.889.016",
  "Longitude": "12.403.235",
  "Telephone": "034903472774",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.889016,12.403235",
  "id": "51.889016,12.403235"
}, {
  "Brand": "TOTAL",
  "Street": "BINGERSTR. 27",
  "PostCode": 55131,
  "City": "MAINZ",
  "Latitude": "49.997.663",
  "Longitude": "08.256.677",
  "Telephone": "06131-1432888",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.997663,8.256677",
  "id": "49.997663,8.256677"
}, {
  "Brand": "TOTAL",
  "Street": "AM AUTOHOF 1",
  "PostCode": 6188,
  "City": "LANDSBERG",
  "Latitude": "51.544.789",
  "Longitude": "12.014.270",
  "Telephone": "034604/24690",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.544789,12.01427",
  "id": "51.544789,12.01427"
}, {
  "Brand": "TOTAL",
  "Street": "STREITSTR. 84",
  "PostCode": 13587,
  "City": "BERLIN",
  "Latitude": "52.554.240",
  "Longitude": "13.207.408",
  "Telephone": "030/74782807",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.55424,13.207408",
  "id": "52.55424,13.207408"
}, {
  "Brand": "TOTAL",
  "Street": "KALTHOFER FELD 1",
  "PostCode": 58640,
  "City": "ISERLOHN",
  "Latitude": "51.426.045",
  "Longitude": "07.675.567",
  "Telephone": "02371-44346",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.426045,7.675567",
  "id": "51.426045,7.675567"
}, {
  "Brand": "TOTAL",
  "Street": "HAGENOWER CHAUSSEE 1",
  "PostCode": 19243,
  "City": "WITTENBURG",
  "Latitude": "53.505.747",
  "Longitude": "11.079.898",
  "Telephone": "038852-50132",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.505747,11.079898",
  "id": "53.505747,11.079898"
}, {
  "Brand": "TOTAL",
  "Street": "TODDINER CH. 5",
  "PostCode": 19230,
  "City": "HAGENOW",
  "Latitude": "53.436.633",
  "Longitude": "11.174.675",
  "Telephone": "03883-722207",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.436633,11.174675",
  "id": "53.436633,11.174675"
}, {
  "Brand": "TOTAL",
  "Street": "BURGALTENDORFER STR.3",
  "PostCode": 45529,
  "City": "HATTINGEN",
  "Latitude": "51.405.599",
  "Longitude": "07.139.186",
  "Telephone": "02324-40756",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.405599,7.139186",
  "id": "51.405599,7.139186"
}, {
  "Brand": "ENI",
  "Street": "Augsburger Str. 23",
  "PostCode": 86899,
  "City": "Landsberg",
  "Latitude": "48.057.696",
  "Longitude": "10.868.000",
  "Telephone": "+49 (0) 81912483",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.057696,10.868",
  "id": "48.057696,10.868"
}, {
  "Brand": "ARAL",
  "Street": "Bamenohler Straße 113",
  "PostCode": 57413,
  "City": "Finnentrop",
  "Latitude": "51.168.472",
  "Longitude": "07.977.023",
  "Telephone": "0272150879",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.168472,7.977023",
  "id": "51.168472,7.977023"
}, {
  "Brand": "OMV",
  "Street": "Auchtertstr. 19",
  "PostCode": 72555,
  "City": "Metzingen",
  "Latitude": "48.532.270",
  "Longitude": "09.289.270",
  "Telephone": "+49-7123-165553",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.53227,9.28927",
  "id": "48.53227,9.28927"
}, {
  "Brand": "TOTAL",
  "Street": "BAB SAMERBERG/SUED A8",
  "PostCode": 83101,
  "City": "ROHRDORF",
  "Latitude": "47.802.010",
  "Longitude": "12.175.430",
  "Telephone": "08032-98930",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.80201,12.17543",
  "id": "47.80201,12.17543"
}, {
  "Brand": "TOTAL",
  "Street": "KARLSRUHER STR. 82",
  "PostCode": 76139,
  "City": "KARLSRUHE",
  "Latitude": "49.025.306",
  "Longitude": "08.448.921",
  "Telephone": "0721-681250",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.025306,8.448921",
  "id": "49.025306,8.448921"
}, {
  "Brand": "TOTAL",
  "Street": "HOEHERWEG 202",
  "PostCode": 40233,
  "City": "DUESSELDORF",
  "Latitude": "51.222.132",
  "Longitude": "06.820.284",
  "Telephone": "0211-5072244",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.222132,6.820284",
  "id": "51.222132,6.820284"
}, {
  "Brand": "OMV",
  "Street": "Raiffeisenallee 7",
  "PostCode": 82041,
  "City": "Oberhaching",
  "Latitude": "48.032.340",
  "Longitude": "11.584.620",
  "Telephone": "+49-89-66665010",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.03234,11.58462",
  "id": "48.03234,11.58462"
}, {
  "Brand": "OMV",
  "Street": "Berger Weg 15",
  "PostCode": 87538,
  "City": "Fischen",
  "Latitude": "47.461.820",
  "Longitude": "10.267.960",
  "Telephone": "+49-8326-7922",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.46182,10.26796",
  "id": "47.46182,10.26796"
}, {
  "Brand": "OMV",
  "Street": "Stuttgarter Str. 150",
  "PostCode": 74321,
  "City": "Bietigheim-Bissingen",
  "Latitude": "48.944.550",
  "Longitude": "09.139.120",
  "Telephone": "+49-7142-773636",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.94455,9.13912",
  "id": "48.94455,9.13912"
}, {
  "Brand": "ARAL",
  "Street": "Westerwaldstraße 8",
  "PostCode": 56587,
  "City": "Oberhonnefeld-Gierend",
  "Latitude": "50.560.718",
  "Longitude": "07.527.342",
  "Telephone": "026349231614",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.560718,7.527342",
  "id": "50.560718,7.527342"
}, {
  "Brand": "Westfalen",
  "Street": "Am Rohrbusch 52",
  "PostCode": 48161,
  "City": "BAT MUENSTERLAND OST MÜNSTER",
  "Latitude": "51.940.200",
  "Longitude": "07.551.760",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9402,7.55176",
  "id": "51.9402,7.55176"
}, {
  "Brand": "OMV",
  "Street": "Überlinger Strasse 13",
  "PostCode": 78351,
  "City": "Bodman-Ludwigshafen",
  "Latitude": "47.815.980",
  "Longitude": "09.061.430",
  "Telephone": "+49-7773-5725",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "47.81598,9.06143",
  "id": "47.81598,9.06143"
}, {
  "Brand": "ARAL",
  "Street": "A8",
  "PostCode": 83101,
  "City": "Rohrdorf",
  "Latitude": "47.802.957",
  "Longitude": "12.178.136",
  "Telephone": "08032956910",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.802957,12.178136",
  "id": "47.802957,12.178136"
}, {
  "Brand": "OMV",
  "Street": "Niorter Str. 4",
  "PostCode": 96450,
  "City": "Coburg",
  "Latitude": "50.286.680",
  "Longitude": "10.980.280",
  "Telephone": "+49-9561-235668",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.28668,10.98028",
  "id": "50.28668,10.98028"
}, {
  "Brand": "OMV",
  "Street": "Steinweg 20",
  "PostCode": 91578,
  "City": "Leutershausen",
  "Latitude": "49.294.780",
  "Longitude": "10.411.870",
  "Telephone": "+49-9823-927829",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.29478,10.41187",
  "id": "49.29478,10.41187"
}, {
  "Brand": "OMV",
  "Street": "Haide 2",
  "PostCode": 95233,
  "City": "Helmbrechts",
  "Latitude": "50.231.930",
  "Longitude": "11.735.160",
  "Telephone": "+49-9252-3509800",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.23193,11.73516",
  "id": "50.23193,11.73516"
}, {
  "Brand": "OMV",
  "Street": "Südeinfahrt 4",
  "PostCode": 84347,
  "City": "Pfarrkirchen",
  "Latitude": "48.421.270",
  "Longitude": "12.942.540",
  "Telephone": "+49-8561-911615",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.42127,12.94254",
  "id": "48.42127,12.94254"
}, {
  "Brand": "Westfalen",
  "Street": "Kasseler Str. 38",
  "PostCode": 34560,
  "City": "FRITZLAR",
  "Latitude": "51.135.700",
  "Longitude": "09.272.500",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.1357,9.2725",
  "id": "51.1357,9.2725"
}, {
  "Brand": "ENI",
  "Street": "Ortenburger Strasse 40",
  "PostCode": 94474,
  "City": "Vilshofen",
  "Latitude": "48.626.991",
  "Longitude": "13.189.829",
  "Telephone": "+49 (0) 85418595",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.626991,13.189829",
  "id": "48.626991,13.189829"
}, {
  "Brand": "OMV",
  "Street": "Memminger Str. 64",
  "PostCode": 88299,
  "City": "Leutkirch",
  "Latitude": "47.833.170",
  "Longitude": "10.019.760",
  "Telephone": "+49-7561-3666",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.83317,10.01976",
  "id": "47.83317,10.01976"
}, {
  "Brand": "ENI",
  "Street": "Berliner Str. 30",
  "PostCode": 72458,
  "City": "Albstadt",
  "Latitude": "48.206.171",
  "Longitude": "09.034.453",
  "Telephone": "+49 (0) 74315116",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.206171,9.034453",
  "id": "48.206171,9.034453"
}, {
  "Brand": "TOTAL",
  "Street": "BUECKEBERGSTR. 1",
  "PostCode": 31137,
  "City": "HILDESHEIM",
  "Latitude": "52.153.883",
  "Longitude": "09.929.226",
  "Telephone": "05121-43777",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.153883,9.929226",
  "id": "52.153883,9.929226"
}, {
  "Brand": "OMV",
  "Street": "Regensburger Strasse 58",
  "PostCode": 93133,
  "City": "Burglengenfeld",
  "Latitude": "49.198.310",
  "Longitude": "12.056.950",
  "Telephone": "+49-9471-80105",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.19831,12.05695",
  "id": "49.19831,12.05695"
}, {
  "Brand": "Westfalen",
  "Street": "Ringer Str. 10",
  "PostCode": 49824,
  "City": "EMLICHHEIM",
  "Latitude": "52.606.900",
  "Longitude": "06.854.820",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.6069,6.85482",
  "id": "52.6069,6.85482"
}, {
  "Brand": "ARAL",
  "Street": "Bonner Straße 70",
  "PostCode": 53919,
  "City": "Weilerswist",
  "Latitude": "00.000.000",
  "Longitude": "00.000.000",
  "Telephone": "022549690747",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "0,0",
  "id": "0,0"
}, {
  "Brand": "ENI",
  "Street": "Bahnhofstrasse 6",
  "PostCode": 82487,
  "City": "Oberammergau",
  "Latitude": "47.598.809",
  "Longitude": "11.059.297",
  "Telephone": "+49 (0) 88229306",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.598809,11.059297",
  "id": "47.598809,11.059297"
}, {
  "Brand": "ARAL",
  "Street": "Münchener Straße 32",
  "PostCode": 83629,
  "City": "Weyarn",
  "Latitude": "47.863.983",
  "Longitude": "11.795.796",
  "Telephone": "08020904680",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.863983,11.795796",
  "id": "47.863983,11.795796"
}, {
  "Brand": "OMV",
  "Street": "Kammerforststr. (An der B35) 4",
  "PostCode": 76646,
  "City": "Bruchsal / Nordseite",
  "Latitude": "49.138.070",
  "Longitude": "08.564.550",
  "Telephone": "+49-7251-91270",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.13807,8.56455",
  "id": "49.13807,8.56455"
}, {
  "Brand": "ARAL",
  "Street": "A7 - Ost",
  "PostCode": 36151,
  "City": "Burghaun",
  "Latitude": "50.712.969",
  "Longitude": "09.662.551",
  "Telephone": "06653292",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.712969,9.662551",
  "id": "50.712969,9.662551"
}, {
  "Brand": "ENI",
  "Street": "Muenchner Strasse 284",
  "PostCode": 85051,
  "City": "Ingolstadt",
  "Latitude": "48.722.739",
  "Longitude": "11.440.462",
  "Telephone": "+49 (0) 84112945",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.722739,11.440462",
  "id": "48.722739,11.440462"
}, {
  "Brand": "TOTAL",
  "Street": "SAARBRUECKER STR. 1b",
  "PostCode": 66299,
  "City": "FRIEDRICHSTHAL",
  "Latitude": "49.316.973",
  "Longitude": "07.087.744",
  "Telephone": "06897-89213",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.316973,7.087744",
  "id": "49.316973,7.087744"
}, {
  "Brand": "ARAL",
  "Street": "Bodenseestraße 40",
  "PostCode": 87700,
  "City": "Memmingen",
  "Latitude": "47.979.969",
  "Longitude": "10.171.886",
  "Telephone": "0833171532",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.979969,10.171886",
  "id": "47.979969,10.171886"
}, {
  "Brand": "TOTAL",
  "Street": "NESSERLANDER STR. 21",
  "PostCode": 26721,
  "City": "EMDEN",
  "Latitude": "53.360.669",
  "Longitude": "07.200.787",
  "Telephone": "04921-22928",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.360669,7.200787",
  "id": "53.360669,7.200787"
}, {
  "Brand": "Westfalen",
  "Street": "Zum Bauverein 44",
  "PostCode": 45899,
  "City": "GELSENKIRCHEN",
  "Latitude": "51.526.900",
  "Longitude": "07.018.360",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.5269,7.01836",
  "id": "51.5269,7.01836"
}, {
  "Brand": "TOTAL",
  "Street": "AN DER B 102 NR. 2",
  "PostCode": 14727,
  "City": "PREMNITZ",
  "Latitude": "52.524.700",
  "Longitude": "12.358.189",
  "Telephone": "03386-280770",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.5247,12.358189",
  "id": "52.5247,12.358189"
}, {
  "Brand": "TOTAL",
  "Street": "WERFTSTR. 13",
  "PostCode": 18439,
  "City": "STRALSUND",
  "Latitude": "54.302.192",
  "Longitude": "13.100.592",
  "Telephone": "03831-292749",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.302192,13.100592",
  "id": "54.302192,13.100592"
}, {
  "Brand": "TOTAL",
  "Street": "RHEINSTR. 38",
  "PostCode": 67574,
  "City": "OSTHOFEN",
  "Latitude": "49.704.717",
  "Longitude": "08.331.841",
  "Telephone": "06242-6258",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.704717,8.331841",
  "id": "49.704717,8.331841"
}, {
  "Brand": "ARAL",
  "Street": "A 2/Ostendorfstraße 60",
  "PostCode": 59069,
  "City": "Hamm",
  "Latitude": "51.632.312",
  "Longitude": "07.873.946",
  "Telephone": "023852577",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.632312,7.873946",
  "id": "51.632312,7.873946"
}, {
  "Brand": "TOTAL",
  "Street": "HANAUER LANDSTR.114",
  "PostCode": 63791,
  "City": "KARLSTEIN",
  "Latitude": "50.037.022",
  "Longitude": "09.036.114",
  "Telephone": "06188-990599/98",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.037022,9.036114",
  "id": "50.037022,9.036114"
}, {
  "Brand": "OMV",
  "Street": "Riedenburger Str. 37",
  "PostCode": 93309,
  "City": "Kelheim",
  "Latitude": "48.923.080",
  "Longitude": "11.869.250",
  "Telephone": "+49-9441-3540",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.92308,11.86925",
  "id": "48.92308,11.86925"
}, {
  "Brand": "OMV",
  "Street": "Rannetsreit 4 1/2",
  "PostCode": 94535,
  "City": "Eging am See",
  "Latitude": "48.689.680",
  "Longitude": "13.213.000",
  "Telephone": "+49-8544-91141",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.68968,13.213",
  "id": "48.68968,13.213"
}, {
  "Brand": "Westfalen",
  "Street": "Westfälische Str. 21",
  "PostCode": 44869,
  "City": "BOCHUM",
  "Latitude": "51.462.100",
  "Longitude": "07.154.500",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.4621,7.1545",
  "id": "51.4621,7.1545"
}, {
  "Brand": "ARAL",
  "Street": "Günzburger Straße 54",
  "PostCode": 89264,
  "City": "Weißenhorn",
  "Latitude": "48.311.829",
  "Longitude": "10.157.852",
  "Telephone": "07309969820",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:30",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.311829,10.157852",
  "id": "48.311829,10.157852"
}, {
  "Brand": "TOTAL",
  "Street": "LANGENDORFER STR. 52",
  "PostCode": 56564,
  "City": "NEUWIED",
  "Latitude": "50.433.960",
  "Longitude": "07.455.120",
  "Telephone": "02631-9549451",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.43396,7.45512",
  "id": "50.43396,7.45512"
}, {
  "Brand": "ENI",
  "Street": "Stuttgarter Strasse 24",
  "PostCode": 70839,
  "City": "Gerlingen",
  "Latitude": "48.789.950",
  "Longitude": "09.046.180",
  "Telephone": "+49 (0) 71562530",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.78995,9.04618",
  "id": "48.78995,9.04618"
}, {
  "Brand": "TOTAL",
  "Street": "BAB SUEDSEITE / A4",
  "PostCode": 99428,
  "City": "GRAMMETAL, OT EICHELBORN",
  "Latitude": "50.948.153",
  "Longitude": "11.193.944",
  "Telephone": "036209432950",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.948153,11.193944",
  "id": "50.948153,11.193944"
}, {
  "Brand": "TOTAL",
  "Street": "BAB NORDSEITE / A4",
  "PostCode": 99428,
  "City": "GRAMMETAL, OT EICHELBORN",
  "Latitude": "50.950.035",
  "Longitude": "11.197.363",
  "Telephone": "036209-40228",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.950035,11.197363",
  "id": "50.950035,11.197363"
}, {
  "Brand": "OMV",
  "Street": "Innsbrucker Ring 153",
  "PostCode": 81669,
  "City": "München",
  "Latitude": "48.113.630",
  "Longitude": "11.616.970",
  "Telephone": "+49-89-6806548",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.11363,11.61697",
  "id": "48.11363,11.61697"
}, {
  "Brand": "TOTAL",
  "Street": "NIEVENHEIM WEST A57",
  "PostCode": 41542,
  "City": "DORMAGEN",
  "Latitude": "51.123.351",
  "Longitude": "06.795.945",
  "Telephone": "02133-70550",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.123351,6.795945",
  "id": "51.123351,6.795945"
}, {
  "Brand": "ARAL",
  "Street": "Blaubeurer Straße 21",
  "PostCode": 89077,
  "City": "Ulm",
  "Latitude": "48.401.622",
  "Longitude": "09.975.566",
  "Telephone": "07313988710",
  "WeekDayFrom": "09:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "09:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "48.401622,9.975566",
  "id": "48.401622,9.975566"
}, {
  "Brand": "ARAL",
  "Street": "Kölner Straße 112",
  "PostCode": 57368,
  "City": "Lennestadt-Grevenbrü",
  "Latitude": "51.142.929",
  "Longitude": "08.017.814",
  "Telephone": "02721718258",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:30",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.142929,8.017814",
  "id": "51.142929,8.017814"
}, {
  "Brand": "TOTAL",
  "Street": "FRITZ-REUTER-STRASSE 21",
  "PostCode": 9423,
  "City": "GELENAU",
  "Latitude": "50.699.649",
  "Longitude": "12.939.698",
  "Telephone": "037297-5055",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.699649,12.939698",
  "id": "50.699649,12.939698"
}, {
  "Brand": "TOTAL",
  "Street": "AEUSS.WOLKENSTEINER STR. 3",
  "PostCode": 9496,
  "City": "MARIENBERG",
  "Latitude": "50.649.699",
  "Longitude": "13.157.792",
  "Telephone": "03735-22485",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.649699,13.157792",
  "id": "50.649699,13.157792"
}, {
  "Brand": "ARAL",
  "Street": "Amselstraße 50",
  "PostCode": 75180,
  "City": "Pforzheim",
  "Latitude": "48.861.790",
  "Longitude": "08.661.948",
  "Telephone": "0723171240",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.86179,8.661948",
  "id": "48.86179,8.661948"
}, {
  "Brand": "ENI",
  "Street": "Buecheloher Str. B 87",
  "PostCode": 98693,
  "City": "Ilmenau",
  "Latitude": "50.690.343",
  "Longitude": "10.940.273",
  "Telephone": "+49 (0) 36772049",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.690343,10.940273",
  "id": "50.690343,10.940273"
}, {
  "Brand": "Westfalen",
  "Street": "Zur Obersburg 2",
  "PostCode": 31749,
  "City": "AUETAL",
  "Latitude": "52.231.000",
  "Longitude": "09.236.890",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.231,9.23689",
  "id": "52.231,9.23689"
}, {
  "Brand": "TOTAL",
  "Street": "CZERNYRING 1",
  "PostCode": 69115,
  "City": "HEIDELBERG",
  "Latitude": "49.407.414",
  "Longitude": "08.672.251",
  "Telephone": "06221-29888",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.407414,8.672251",
  "id": "49.407414,8.672251"
}, {
  "Brand": "OMV",
  "Street": "Feucht-West A 9",
  "PostCode": 90537,
  "City": "Feucht",
  "Latitude": "49.360.510",
  "Longitude": "11.202.010",
  "Telephone": "+49-9128-920980",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.36051,11.20201",
  "id": "49.36051,11.20201"
}, {
  "Brand": "ENI",
  "Street": "Roemerstr. 50",
  "PostCode": 85055,
  "City": "Ingolstadt",
  "Latitude": "48.776.410",
  "Longitude": "11.455.480",
  "Telephone": "+49 (0) 84195653",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.77641,11.45548",
  "id": "48.77641,11.45548"
}, {
  "Brand": "OMV",
  "Street": "Bundesstr. 23",
  "PostCode": 79787,
  "City": "Lauchringen",
  "Latitude": "47.632.480",
  "Longitude": "08.299.850",
  "Telephone": "+49-7741-2632",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.63248,8.29985",
  "id": "47.63248,8.29985"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER STR. 37",
  "PostCode": 44866,
  "City": "BOCHUM",
  "Latitude": "51.478.273",
  "Longitude": "07.126.710",
  "Telephone": "02327-31229",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.478273,7.12671",
  "id": "51.478273,7.12671"
}, {
  "Brand": "TOTAL",
  "Street": "REICHERSDORFER STR. 9",
  "PostCode": 4651,
  "City": "BAD LAUSICK",
  "Latitude": "51.140.672",
  "Longitude": "12.650.120",
  "Telephone": "034345-22864",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.140672,12.65012",
  "id": "51.140672,12.65012"
}, {
  "Brand": "TOTAL",
  "Street": "PEGNITZER STRASSE 31",
  "PostCode": 91278,
  "City": "POTTENSTEIN",
  "Latitude": "49.768.495",
  "Longitude": "11.409.829",
  "Telephone": "09243-312",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.768495,11.409829",
  "id": "49.768495,11.409829"
}, {
  "Brand": "OMV",
  "Street": "Herbolzheimer Str. 1",
  "PostCode": 77975,
  "City": "Ringsheim",
  "Latitude": "48.250.210",
  "Longitude": "07.766.310",
  "Telephone": "+49-7822-4489941",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.25021,7.76631",
  "id": "48.25021,7.76631"
}, {
  "Brand": "ARAL",
  "Street": "Kronprinzenstraße 23",
  "PostCode": 57250,
  "City": "Netphen",
  "Latitude": "50.915.235",
  "Longitude": "08.096.135",
  "Telephone": "027381263",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.915235,8.096135",
  "id": "50.915235,8.096135"
}, {
  "Brand": "OMV",
  "Street": "Ausseestrasse 4",
  "PostCode": 83543,
  "City": "Rott",
  "Latitude": "47.978.700",
  "Longitude": "12.139.040",
  "Telephone": "+49-8039-409694",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.9787,12.13904",
  "id": "47.9787,12.13904"
}, {
  "Brand": "ENI",
  "Street": "Kulmbacher Str. 71",
  "PostCode": 7318,
  "City": "Saalfeld",
  "Latitude": "50.648.893",
  "Longitude": "11.373.510",
  "Telephone": "+49 (0) 36715205",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.648893,11.37351",
  "id": "50.648893,11.37351"
}, {
  "Brand": "Westfalen",
  "Street": "Gehrdener Str. 5",
  "PostCode": 30989,
  "City": "GEHRDEN",
  "Latitude": "52.322.900",
  "Longitude": "09.552.890",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.3229,9.55289",
  "id": "52.3229,9.55289"
}, {
  "Brand": "OMV",
  "Street": "Freisinger Landstr. 26",
  "PostCode": 80939,
  "City": "München",
  "Latitude": "48.191.130",
  "Longitude": "11.620.360",
  "Telephone": "+49-89-3226636",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.19113,11.62036",
  "id": "48.19113,11.62036"
}, {
  "Brand": "ARAL",
  "Street": "Egelseestraße 33",
  "PostCode": 88339,
  "City": "Bad Waldsee",
  "Latitude": "47.877.630",
  "Longitude": "09.704.620",
  "Telephone": "075244644900",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "05:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "47.87763,9.70462",
  "id": "47.87763,9.70462"
}, {
  "Brand": "OMV",
  "Street": "Donautal West A 3",
  "PostCode": 94036,
  "City": "Passau",
  "Latitude": "48.589.860",
  "Longitude": "13.366.080",
  "Telephone": "+49-851-96618880",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.58986,13.36608",
  "id": "48.58986,13.36608"
}, {
  "Brand": "Westfalen",
  "Street": "Farger Str. 16",
  "PostCode": 28777,
  "City": "BREMEN",
  "Latitude": "00.000.000",
  "Longitude": "08.532.320",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "0,8.53232",
  "id": "0,8.53232"
}, {
  "Brand": "ARAL",
  "Street": "A 2",
  "PostCode": 38350,
  "City": "Helmstedt",
  "Latitude": "52.220.212",
  "Longitude": "11.058.335",
  "Telephone": "053512077",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.220212,11.058335",
  "id": "52.220212,11.058335"
}, {
  "Brand": "ARAL",
  "Street": "Königskamp 4",
  "PostCode": 52428,
  "City": "Jülich",
  "Latitude": "50.912.276",
  "Longitude": "06.378.780",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.912276,6.37878",
  "id": "50.912276,6.37878"
}, {
  "Brand": "Westfalen",
  "Street": "Duelmener Str. 26",
  "PostCode": 48653,
  "City": "COESFELD",
  "Latitude": "51.937.400",
  "Longitude": "07.168.220",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9374,7.16822",
  "id": "51.9374,7.16822"
}, {
  "Brand": "TOTAL",
  "Street": "WILHELMSDORFER LANDSTR. 36",
  "PostCode": 14776,
  "City": "BRANDENBURG",
  "Latitude": "52.395.213",
  "Longitude": "12.540.529",
  "Telephone": "03381-795506",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.395213,12.540529",
  "id": "52.395213,12.540529"
}, {
  "Brand": "TOTAL",
  "Street": "WANDHOFENER STR. 9",
  "PostCode": 58099,
  "City": "HAGEN",
  "Latitude": "51.406.552",
  "Longitude": "07.478.107",
  "Telephone": "02331-9235959",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.406552,7.478107",
  "id": "51.406552,7.478107"
}, {
  "Brand": "ENI",
  "Street": "Marburger Strasse 30",
  "PostCode": 35075,
  "City": "Gladenbach",
  "Latitude": "50.771.660",
  "Longitude": "08.604.340",
  "Telephone": "+49 (0) 64629116",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.77166,8.60434",
  "id": "50.77166,8.60434"
}, {
  "Brand": "TOTAL",
  "Street": "LANGE STR. 147",
  "PostCode": 33397,
  "City": "RIETBERG",
  "Latitude": "51.832.478",
  "Longitude": "08.445.303",
  "Telephone": "05244-2318",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.832478,8.445303",
  "id": "51.832478,8.445303"
}, {
  "Brand": "TOTAL",
  "Street": "SCHWERTER STR. 161",
  "PostCode": 58099,
  "City": "HAGEN-BOELE",
  "Latitude": "51.398.479",
  "Longitude": "07.472.529",
  "Telephone": "02331-65197",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.398479,7.472529",
  "id": "51.398479,7.472529"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER STR. 1",
  "PostCode": 15926,
  "City": "LUCKAU",
  "Latitude": "51.851.696",
  "Longitude": "13.703.166",
  "Telephone": "03544-2347",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.851696,13.703166",
  "id": "51.851696,13.703166"
}, {
  "Brand": "TOTAL",
  "Street": "KOENIGSTR. 52",
  "PostCode": 73326,
  "City": "DEGGINGEN",
  "Latitude": "48.596.781",
  "Longitude": "09.715.919",
  "Telephone": "07334-5408",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.596781,9.715919",
  "id": "48.596781,9.715919"
}, {
  "Brand": "TOTAL",
  "Street": "ZUR THUERINGER PFORTE 10",
  "PostCode": 6577,
  "City": "HELDRUNGEN",
  "Latitude": "51.299.696",
  "Longitude": "11.202.084",
  "Telephone": "034673-779718",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.299696,11.202084",
  "id": "51.299696,11.202084"
}, {
  "Brand": "TOTAL",
  "Street": "RINGSTR. 12",
  "PostCode": 69115,
  "City": "HEIDELBERG",
  "Latitude": "49.404.265",
  "Longitude": "08.682.606",
  "Telephone": "06221-23773",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.404265,8.682606",
  "id": "49.404265,8.682606"
}, {
  "Brand": "ARAL",
  "Street": "Landsberger Straße 38a",
  "PostCode": 86179,
  "City": "Augsburg",
  "Latitude": "48.312.376",
  "Longitude": "10.907.857",
  "Telephone": "08218150158",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.312376,10.907857",
  "id": "48.312376,10.907857"
}, {
  "Brand": "ARAL",
  "Street": "Rudolf-Diesel-Str. 13",
  "PostCode": 56220,
  "City": "Urmitz",
  "Latitude": "50.400.498",
  "Longitude": "07.516.720",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.400498,7.51672",
  "id": "50.400498,7.51672"
}, {
  "Brand": "Westfalen",
  "Street": "Cloppenburger 224",
  "PostCode": 26133,
  "City": "OLDENBURG",
  "Latitude": "53.117.600",
  "Longitude": "08.214.530",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.1176,8.21453",
  "id": "53.1176,8.21453"
}, {
  "Brand": "ARAL",
  "Street": "Otto-Brenner-Straße 8",
  "PostCode": 21337,
  "City": "Lüneburg",
  "Latitude": "53.248.791",
  "Longitude": "10.474.155",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.248791,10.474155",
  "id": "53.248791,10.474155"
}, {
  "Brand": "TOTAL",
  "Street": "KARLSTR. 44",
  "PostCode": 44649,
  "City": "HERNE",
  "Latitude": "51.537.115",
  "Longitude": "07.149.212",
  "Telephone": "02325-977166",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.537115,7.149212",
  "id": "51.537115,7.149212"
}, {
  "Brand": "ARAL",
  "Street": "Jenaische Straße 103c",
  "PostCode": 7407,
  "City": "Rudolstadt",
  "Latitude": "50.727.196",
  "Longitude": "11.364.309",
  "Telephone": "03672424471",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "05:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.727196,11.364309",
  "id": "50.727196,11.364309"
}, {
  "Brand": "OMV",
  "Street": "Triebstr. 35 a",
  "PostCode": 80993,
  "City": "München",
  "Latitude": "48.187.190",
  "Longitude": "11.535.300",
  "Telephone": "+49-89-14322935",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.18719,11.5353",
  "id": "48.18719,11.5353"
}, {
  "Brand": "ENI",
  "Street": "Blankenburger Heerstr. 1 A",
  "PostCode": 38820,
  "City": "Halberstadt",
  "Latitude": "51.885.190",
  "Longitude": "11.031.090",
  "Telephone": "+49 (0) 39412615",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.88519,11.03109",
  "id": "51.88519,11.03109"
}, {
  "Brand": "TOTAL",
  "Street": "WORMSER LANDSTR. 255",
  "PostCode": 67346,
  "City": "SPEYER",
  "Latitude": "49.337.856",
  "Longitude": "08.429.663",
  "Telephone": "06232-34051",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.337856,8.429663",
  "id": "49.337856,8.429663"
}, {
  "Brand": "TOTAL",
  "Street": "PLAUER CHAUSSEE 2",
  "PostCode": 18273,
  "City": "GUESTROW",
  "Latitude": "53.785.019",
  "Longitude": "12.196.471",
  "Telephone": "03843-82149",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.785019,12.196471",
  "id": "53.785019,12.196471"
}, {
  "Brand": "ENI",
  "Street": "Friedrich-Bauer-Str. 31",
  "PostCode": 73642,
  "City": "Welzheim",
  "Latitude": "48.870.239",
  "Longitude": "09.623.648",
  "Telephone": "+49 (0) 71829293",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.870239,9.623648",
  "id": "48.870239,9.623648"
}, {
  "Brand": "TOTAL",
  "Street": "NEUSTAEDTER STR. 162",
  "PostCode": 7381,
  "City": "POESSNECK",
  "Latitude": "50.701.001",
  "Longitude": "11.614.169",
  "Telephone": "03647-425370",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.701001,11.614169",
  "id": "50.701001,11.614169"
}, {
  "Brand": "TOTAL",
  "Street": "FR.-EBERT-STR. 14-16",
  "PostCode": 68167,
  "City": "MANNHEIM",
  "Latitude": "49.497.207",
  "Longitude": "08.488.092",
  "Telephone": "0621-333545",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.497207,8.488092",
  "id": "49.497207,8.488092"
}, {
  "Brand": "TOTAL",
  "Street": "KOLKWITZER STR. 66A",
  "PostCode": 3046,
  "City": "COTTBUS",
  "Latitude": "51.753.051",
  "Longitude": "14.297.501",
  "Telephone": "0355-31481",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.753051,14.297501",
  "id": "51.753051,14.297501"
}, {
  "Brand": "ENI",
  "Street": "Donaustrasse 2",
  "PostCode": 76437,
  "City": "Rastatt",
  "Latitude": "48.838.700",
  "Longitude": "08.201.400",
  "Telephone": "+49 (0) 72223402",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.8387,8.2014",
  "id": "48.8387,8.2014"
}, {
  "Brand": "TOTAL",
  "Street": "BAB A 620 SUEDSEITE",
  "PostCode": 66740,
  "City": "SAARLOUIS",
  "Latitude": "49.299.797",
  "Longitude": "06.748.377",
  "Telephone": "06831-2060",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.299797,6.748377",
  "id": "49.299797,6.748377"
}, {
  "Brand": "ARAL",
  "Street": "Schomburger Straße 1",
  "PostCode": 88279,
  "City": "Amtzell",
  "Latitude": "47.695.372",
  "Longitude": "09.758.986",
  "Telephone": "075206126",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.695372,9.758986",
  "id": "47.695372,9.758986"
}, {
  "Brand": "ARAL",
  "Street": "Stader Straße 30",
  "PostCode": 27419,
  "City": "Sittensen",
  "Latitude": "53.288.559",
  "Longitude": "09.507.740",
  "Telephone": "04282592874",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.288559,9.50774",
  "id": "53.288559,9.50774"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER TOR 2",
  "PostCode": 14959,
  "City": "TREBBIN",
  "Latitude": "52.222.036",
  "Longitude": "13.219.567",
  "Telephone": "033731-15271",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.222036,13.219567",
  "id": "52.222036,13.219567"
}, {
  "Brand": "ARAL",
  "Street": "A3",
  "PostCode": 56307,
  "City": "Dernbach",
  "Latitude": "50.547.978",
  "Longitude": "07.585.230",
  "Telephone": "02689979680",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.547978,7.58523",
  "id": "50.547978,7.58523"
}, {
  "Brand": "ARAL",
  "Street": "Ferdinand-Porsche-Straße",
  "PostCode": 21684,
  "City": "Stade",
  "Latitude": "53.577.212",
  "Longitude": "09.495.691",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.577212,9.495691",
  "id": "53.577212,9.495691"
}, {
  "Brand": "TOTAL",
  "Street": "FLUGPLATZSTR. 26",
  "PostCode": 55126,
  "City": "MAINZ-FINTHEN",
  "Latitude": "49.983.180",
  "Longitude": "08.170.831",
  "Telephone": "06131-40805",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.98318,8.170831",
  "id": "49.98318,8.170831"
}, {
  "Brand": "ARAL",
  "Street": "Lützner Straße 536",
  "PostCode": 4205,
  "City": "Leipzig",
  "Latitude": "51.317.336",
  "Longitude": "12.270.607",
  "Telephone": "03414225520",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.317336,12.270607",
  "id": "51.317336,12.270607"
}, {
  "Brand": "TOTAL",
  "Street": "PASEWALKER ALLEE 95 A",
  "PostCode": 17389,
  "City": "ANKLAM",
  "Latitude": "53.836.485",
  "Longitude": "13.715.871",
  "Telephone": "03971-210189",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.836485,13.715871",
  "id": "53.836485,13.715871"
}, {
  "Brand": "TOTAL",
  "Street": "RACKWITZER STRASSE 26",
  "PostCode": 4347,
  "City": "LEIPZIG",
  "Latitude": "51.354.444",
  "Longitude": "12.395.000",
  "Telephone": "0341-2345861",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.354444,12.395",
  "id": "51.354444,12.395"
}, {
  "Brand": "TOTAL",
  "Street": "LUEBECKER STR. 283",
  "PostCode": 19059,
  "City": "SCHWERIN",
  "Latitude": "53.645.703",
  "Longitude": "11.382.095",
  "Telephone": "0385-4864112",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.645703,11.382095",
  "id": "53.645703,11.382095"
}, {
  "Brand": "TOTAL",
  "Street": "POISENTALSTR. 18",
  "PostCode": 1705,
  "City": "FREITAL",
  "Latitude": "50.996.099",
  "Longitude": "13.652.083",
  "Telephone": "0351-6491861",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.996099,13.652083",
  "id": "50.996099,13.652083"
}, {
  "Brand": "TOTAL",
  "Street": "BOTTROPER STR. 18-20",
  "PostCode": 46244,
  "City": "BOTTROP",
  "Latitude": "51.600.596",
  "Longitude": "06.914.389",
  "Telephone": "02045-2128",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.600596,6.914389",
  "id": "51.600596,6.914389"
}, {
  "Brand": "Westfalen",
  "Street": "Huelsdonker Str. 117",
  "PostCode": 47441,
  "City": "MOERS",
  "Latitude": "51.451.000",
  "Longitude": "06.608.710",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.451,6.60871",
  "id": "51.451,6.60871"
}, {
  "Brand": "OMV",
  "Street": "Burghauser Str. 79",
  "PostCode": 84503,
  "City": "Altötting",
  "Latitude": "48.222.380",
  "Longitude": "12.688.150",
  "Telephone": "+49-8671-881466",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.22238,12.68815",
  "id": "48.22238,12.68815"
}, {
  "Brand": "ENI",
  "Street": "Perger Strasse 2",
  "PostCode": 84489,
  "City": "Burghausen",
  "Latitude": "48.166.382",
  "Longitude": "12.827.794",
  "Telephone": "+49 (0) 86776516",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.166382,12.827794",
  "id": "48.166382,12.827794"
}, {
  "Brand": "TOTAL",
  "Street": "DOERRENBACHER STR. 22",
  "PostCode": 66564,
  "City": "OTTWEILER",
  "Latitude": "49.423.994",
  "Longitude": "07.236.086",
  "Telephone": "06858-332",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.423994,7.236086",
  "id": "49.423994,7.236086"
}, {
  "Brand": "ARAL",
  "Street": "A5",
  "PostCode": 77972,
  "City": "Mahlberg / Baden",
  "Latitude": "48.309.474",
  "Longitude": "07.789.156",
  "Telephone": "07825877611",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.309474,7.789156",
  "id": "48.309474,7.789156"
}, {
  "Brand": "OMV",
  "Street": "Gewerbegebiet 10",
  "PostCode": 83093,
  "City": "Bad Endorf",
  "Latitude": "47.917.270",
  "Longitude": "12.293.600",
  "Telephone": "+49-8053-4224",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.91727,12.2936",
  "id": "47.91727,12.2936"
}, {
  "Brand": "ENI",
  "Street": "Waldseer Str. 107",
  "PostCode": 88400,
  "City": "Biberach",
  "Latitude": "48.085.920",
  "Longitude": "09.795.900",
  "Telephone": "+49 (0) 73512108",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.08592,9.7959",
  "id": "48.08592,9.7959"
}, {
  "Brand": "Westfalen",
  "Street": "Eckendorfer Str. 32",
  "PostCode": 33609,
  "City": "BIELEFELD",
  "Latitude": "52.033.300",
  "Longitude": "08.557.440",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0333,8.55744",
  "id": "52.0333,8.55744"
}, {
  "Brand": "Westfalen",
  "Street": "Werler Str. 238-240",
  "PostCode": 59063,
  "City": "HAMM",
  "Latitude": "51.662.800",
  "Longitude": "07.834.130",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.6628,7.83413",
  "id": "51.6628,7.83413"
}, {
  "Brand": "Westfalen",
  "Street": "Coesfelder Str. 91",
  "PostCode": 48653,
  "City": "COESFELD",
  "Latitude": "51.896.000",
  "Longitude": "07.193.370",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.896,7.19337",
  "id": "51.896,7.19337"
}, {
  "Brand": "ENI",
  "Street": "Helmstedt Sued / BAB A2",
  "PostCode": 38350,
  "City": "Helmstedt",
  "Latitude": "52.224.470",
  "Longitude": "11.011.150",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "52.22447,11.01115",
  "id": "52.22447,11.01115"
}, {
  "Brand": "Westfalen",
  "Street": "Kopenhagener Straße 19",
  "PostCode": 48163,
  "City": "Münster",
  "Latitude": "51.562.030",
  "Longitude": "07.355.878",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.56203,7.355878",
  "id": "51.56203,7.355878"
}, {
  "Brand": "OMV",
  "Street": "Brüdener Strasse 16",
  "PostCode": 71554,
  "City": "Weissach",
  "Latitude": "48.931.360",
  "Longitude": "09.481.910",
  "Telephone": "+49-7191-310610",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.93136,9.48191",
  "id": "48.93136,9.48191"
}, {
  "Brand": "ENI",
  "Street": "Schnellstrasse West",
  "PostCode": 55130,
  "City": "Mainz",
  "Latitude": "50.004.372",
  "Longitude": "08.262.588",
  "Telephone": "+49 (0) 61318648",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.004372,8.262588",
  "id": "50.004372,8.262588"
}, {
  "Brand": "Westfalen",
  "Street": "Puesselbuerener Damm 16",
  "PostCode": 49477,
  "City": "IBBENBUEREN",
  "Latitude": "52.281.700",
  "Longitude": "07.706.570",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.2817,7.70657",
  "id": "52.2817,7.70657"
}, {
  "Brand": "Westfalen",
  "Street": "Kölner Str. 124",
  "PostCode": 51702,
  "City": "BERGNEUSTADT",
  "Latitude": "51.017.100",
  "Longitude": "07.639.310",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.0171,7.63931",
  "id": "51.0171,7.63931"
}, {
  "Brand": "OMV",
  "Street": "Jura Ost A3",
  "PostCode": 92355,
  "City": "Velburg",
  "Latitude": "49.270.420",
  "Longitude": "11.602.520",
  "Telephone": "+49-9182-9313-0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.27042,11.60252",
  "id": "49.27042,11.60252"
}, {
  "Brand": "TOTAL",
  "Street": "HAUPTSTR.23",
  "PostCode": 56767,
  "City": "UERSFELD",
  "Latitude": "50.255.816",
  "Longitude": "07.013.570",
  "Telephone": "02657-247",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.255816,7.01357",
  "id": "50.255816,7.01357"
}, {
  "Brand": "TOTAL",
  "Street": "LANGENAUER STR. 1",
  "PostCode": 9618,
  "City": "BRAND-ERBISDORF",
  "Latitude": "50.857.879",
  "Longitude": "13.319.008",
  "Telephone": "037322-2271",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.857879,13.319008",
  "id": "50.857879,13.319008"
}, {
  "Brand": "TOTAL",
  "Street": "GROTHUSSTR. 4",
  "PostCode": 45881,
  "City": "GELSENKIRCHEN",
  "Latitude": "51.520.280",
  "Longitude": "07.078.772",
  "Telephone": "0209-498552",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.52028,7.078772",
  "id": "51.52028,7.078772"
}, {
  "Brand": "TOTAL",
  "Street": "ALZEYER STR. 73",
  "PostCode": 55543,
  "City": "BAD KREUZNACH",
  "Latitude": "49.836.608",
  "Longitude": "07.877.203",
  "Telephone": "0671-68373",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.836608,7.877203",
  "id": "49.836608,7.877203"
}, {
  "Brand": "ARAL",
  "Street": "Stettener Straße 112",
  "PostCode": 73732,
  "City": "Esslingen-Wäldenbron",
  "Latitude": "48.759.401",
  "Longitude": "09.317.381",
  "Telephone": "07119385399",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.759401,9.317381",
  "id": "48.759401,9.317381"
}, {
  "Brand": "TOTAL",
  "Street": "BUCHAECKERRING 40",
  "PostCode": 74906,
  "City": "BAD RAPPENAU",
  "Latitude": "49.213.020",
  "Longitude": "09.078.999",
  "Telephone": "07066-9158824",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.21302,9.078999",
  "id": "49.21302,9.078999"
}, {
  "Brand": "TOTAL",
  "Street": "BONNER STR. 417-425",
  "PostCode": 50968,
  "City": "KOELN",
  "Latitude": "50.900.906",
  "Longitude": "06.965.748",
  "Telephone": "0221-372137",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.900906,6.965748",
  "id": "50.900906,6.965748"
}, {
  "Brand": "ENI",
  "Street": "An der A 3",
  "PostCode": 97355,
  "City": "Kleinlangheim - Haidt",
  "Latitude": "49.780.548",
  "Longitude": "10.244.798",
  "Telephone": "+49 (0) 9325200",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.780548,10.244798",
  "id": "49.780548,10.244798"
}, {
  "Brand": "TOTAL",
  "Street": "HEERESSTR. 27",
  "PostCode": 66822,
  "City": "LEBACH",
  "Latitude": "49.413.102",
  "Longitude": "06.894.295",
  "Telephone": "06881-52861",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.413102,6.894295",
  "id": "49.413102,6.894295"
}, {
  "Brand": "Westfalen",
  "Street": "Detmolder Straße 230",
  "PostCode": 33175,
  "City": "Bad Lippspringe",
  "Latitude": "51.789.614",
  "Longitude": "08.826.766",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.789614,8.826766",
  "id": "51.789614,8.826766"
}, {
  "Brand": "OMV",
  "Street": "Verdistr. 68",
  "PostCode": 81247,
  "City": "München",
  "Latitude": "48.164.900",
  "Longitude": "11.471.900",
  "Telephone": "+49-89-8112537",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.1649,11.4719",
  "id": "48.1649,11.4719"
}, {
  "Brand": "TOTAL",
  "Street": "DORFGUETINGEN 43",
  "PostCode": 91555,
  "City": "FEUCHTWANGEN",
  "Latitude": "49.216.330",
  "Longitude": "10.300.219",
  "Telephone": "09852-768",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.21633,10.300219",
  "id": "49.21633,10.300219"
}, {
  "Brand": "TOTAL",
  "Street": "ERLIGHEIMER STR. 20",
  "PostCode": 74369,
  "City": "LOECHGAU",
  "Latitude": "49.003.447",
  "Longitude": "09.104.392",
  "Telephone": "07143-88302-0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.003447,9.104392",
  "id": "49.003447,9.104392"
}, {
  "Brand": "TOTAL",
  "Street": "KASSELER STR. 49-51",
  "PostCode": 37247,
  "City": "GROSSALMERODE",
  "Latitude": "51.258.345",
  "Longitude": "09.778.154",
  "Telephone": "05604-6386",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.258345,9.778154",
  "id": "51.258345,9.778154"
}, {
  "Brand": "TOTAL",
  "Street": "RODGAUSTRASSE 6",
  "PostCode": 63457,
  "City": "HANAU",
  "Latitude": "50.116.658",
  "Longitude": "08.928.875",
  "Telephone": "06181-399572",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.116658,8.928875",
  "id": "50.116658,8.928875"
}, {
  "Brand": "ARAL",
  "Street": "Siegener Straße 58",
  "PostCode": 57610,
  "City": "Altenkirchen",
  "Latitude": "50.693.403",
  "Longitude": "07.654.354",
  "Telephone": "026815186",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:30",
  "SaturdayTo": "19:00",
  "SundayFrom": "09:30",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "50.693403,7.654354",
  "id": "50.693403,7.654354"
}, {
  "Brand": "ARAL",
  "Street": "Zielstattstraße 10",
  "PostCode": 81379,
  "City": "München",
  "Latitude": "48.103.244",
  "Longitude": "11.533.475",
  "Telephone": "089788232",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "18:00",
  "SundayFrom": "00:00",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "48.103244,11.533475",
  "id": "48.103244,11.533475"
}, {
  "Brand": "TOTAL",
  "Street": "BAB BROHLTAL WEST/A 61",
  "PostCode": 56651,
  "City": "NIEDERZISSEN",
  "Latitude": "50.441.791",
  "Longitude": "07.224.669",
  "Telephone": "02636-970077",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.441791,7.224669",
  "id": "50.441791,7.224669"
}, {
  "Brand": "TOTAL",
  "Street": "STOCKUMER STR. 200",
  "PostCode": 44225,
  "City": "DORTMUND",
  "Latitude": "51.481.830",
  "Longitude": "07.436.307",
  "Telephone": "0231-711950",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.48183,7.436307",
  "id": "51.48183,7.436307"
}, {
  "Brand": "ARAL",
  "Street": "Friedberger Straße 47 1/4",
  "PostCode": 86161,
  "City": "Augsburg",
  "Latitude": "48.355.628",
  "Longitude": "10.919.002",
  "Telephone": "0821551677",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.355628,10.919002",
  "id": "48.355628,10.919002"
}, {
  "Brand": "ENI",
  "Street": "Hoefener Strasse 44",
  "PostCode": 90763,
  "City": "Fuerth",
  "Latitude": "49.448.965",
  "Longitude": "11.000.430",
  "Telephone": "+49 (0) 91170571",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.448965,11.00043",
  "id": "49.448965,11.00043"
}, {
  "Brand": "TOTAL",
  "Street": "DRESDNER STR. 5",
  "PostCode": 9456,
  "City": "ANNABERG-BUCHHOLZ",
  "Latitude": "50.590.260",
  "Longitude": "13.013.472",
  "Telephone": "03733-53417",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.59026,13.013472",
  "id": "50.59026,13.013472"
}, {
  "Brand": "TOTAL",
  "Street": "AM ISAAK 46",
  "PostCode": 96515,
  "City": "SONNEBERG",
  "Latitude": "50.365.715",
  "Longitude": "11.143.276",
  "Telephone": "03675-702932",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.365715,11.143276",
  "id": "50.365715,11.143276"
}, {
  "Brand": "TOTAL",
  "Street": "LORSBACHER STR.14",
  "PostCode": 65817,
  "City": "EPPSTEIN",
  "Latitude": "50.135.286",
  "Longitude": "08.400.434",
  "Telephone": "06198-349178",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.135286,8.400434",
  "id": "50.135286,8.400434"
}, {
  "Brand": "ENI",
  "Street": "Halbendorfer Weg 9",
  "PostCode": 2943,
  "City": "Weisswasser",
  "Latitude": "51.522.043",
  "Longitude": "14.606.009",
  "Telephone": "+49 (0) 35762053",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.522043,14.606009",
  "id": "51.522043,14.606009"
}, {
  "Brand": "TOTAL",
  "Street": "AUTOHOF REIMERSHECK 3",
  "PostCode": 56424,
  "City": "MOGENDORF",
  "Latitude": "50.482.766",
  "Longitude": "07.749.125",
  "Telephone": "02623-9262132",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.482766,7.749125",
  "id": "50.482766,7.749125"
}, {
  "Brand": "Westfalen",
  "Street": "Roemerstr. 28",
  "PostCode": 44289,
  "City": "BAT LICHTENDORF NORD DORTMUND",
  "Latitude": "51.468.800",
  "Longitude": "07.593.180",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.4688,7.59318",
  "id": "51.4688,7.59318"
}, {
  "Brand": "ENI",
  "Street": "Freiburger Str. 3",
  "PostCode": 79189,
  "City": "Bad Krozingen",
  "Latitude": "47.921.337",
  "Longitude": "07.706.354",
  "Telephone": "+49 (0) 76339088",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.921337,7.706354",
  "id": "47.921337,7.706354"
}, {
  "Brand": "OMV",
  "Street": "Bahnhofstr. 6",
  "PostCode": 92660,
  "City": "Neustadt",
  "Latitude": "49.728.120",
  "Longitude": "12.165.440",
  "Telephone": "+49-9602-1496",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.72812,12.16544",
  "id": "49.72812,12.16544"
}, {
  "Brand": "ENI",
  "Street": "Albert-Schweitzer-Str. 68",
  "PostCode": 82008,
  "City": "Unterhaching",
  "Latitude": "48.082.864",
  "Longitude": "11.602.323",
  "Telephone": "+49 (0) 89617016",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.082864,11.602323",
  "id": "48.082864,11.602323"
}, {
  "Brand": "ENI",
  "Street": "Schmidstrasse 2",
  "PostCode": 94234,
  "City": "Viechtach",
  "Latitude": "49.078.630",
  "Longitude": "12.879.560",
  "Telephone": "+49 (0) 99429042",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.07863,12.87956",
  "id": "49.07863,12.87956"
}, {
  "Brand": "ENI",
  "Street": "Witschelstr. 91",
  "PostCode": 90431,
  "City": "Nuernberg",
  "Latitude": "49.447.810",
  "Longitude": "11.038.980",
  "Telephone": "+49 (0) 91194062",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.44781,11.03898",
  "id": "49.44781,11.03898"
}, {
  "Brand": "Westfalen",
  "Street": "Melanchthonstr. 40",
  "PostCode": 30165,
  "City": "HANNOVER",
  "Latitude": "52.398.200",
  "Longitude": "09.730.020",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.3982,9.73002",
  "id": "52.3982,9.73002"
}, {
  "Brand": "OMV",
  "Street": "Straubinger Str. 47",
  "PostCode": 94333,
  "City": "Geiselhöring",
  "Latitude": "48.829.510",
  "Longitude": "12.400.660",
  "Telephone": "+49-9423-903997",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.82951,12.40066",
  "id": "48.82951,12.40066"
}, {
  "Brand": "OMV",
  "Street": "Am Zubringer 6",
  "PostCode": 76437,
  "City": "Rastatt",
  "Latitude": "48.859.800",
  "Longitude": "08.254.700",
  "Telephone": "+49-7222-985758",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.8598,8.2547",
  "id": "48.8598,8.2547"
}, {
  "Brand": "Westfalen",
  "Street": "Elsa-Brandstroem-Str. 137",
  "PostCode": 44869,
  "City": "BOCHUM",
  "Latitude": "51.456.300",
  "Longitude": "07.176.080",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.4563,7.17608",
  "id": "51.4563,7.17608"
}, {
  "Brand": "ENI",
  "Street": "Dachauer Strasse 469",
  "PostCode": 80993,
  "City": "Muenchen",
  "Latitude": "48.202.457",
  "Longitude": "11.490.697",
  "Telephone": "+49 (0) 89148397",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.202457,11.490697",
  "id": "48.202457,11.490697"
}, {
  "Brand": "ARAL",
  "Street": "Titiseestraße 26",
  "PostCode": 79822,
  "City": "Titisee-Neustadt",
  "Latitude": "47.919.362",
  "Longitude": "08.205.026",
  "Telephone": "076519361997",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.919362,8.205026",
  "id": "47.919362,8.205026"
}, {
  "Brand": "ARAL",
  "Street": "Trochtelfinger Straße 38",
  "PostCode": 72829,
  "City": "Engstingen",
  "Latitude": "48.383.272",
  "Longitude": "09.282.753",
  "Telephone": "071299362420",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:30",
  "SundayFrom": "08:30",
  "SundayTo": "20:30",
  "IsOpen24Hours": "Nein",
  "location": "48.383272,9.282753",
  "id": "48.383272,9.282753"
}, {
  "Brand": "Westfalen",
  "Street": "Am Dreieck 16",
  "PostCode": 51580,
  "City": "REICHSHOF",
  "Latitude": "50.928.100",
  "Longitude": "07.644.010",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.9281,7.64401",
  "id": "50.9281,7.64401"
}, {
  "Brand": "TOTAL",
  "Street": "WESTRING 40",
  "PostCode": 19370,
  "City": "PARCHIM",
  "Latitude": "53.427.507",
  "Longitude": "11.827.283",
  "Telephone": "03871-441021",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.427507,11.827283",
  "id": "53.427507,11.827283"
}, {
  "Brand": "TOTAL",
  "Street": "AUTOHOF EYTHSTRASSE 16 A",
  "PostCode": 72189,
  "City": "VOEHRINGEN",
  "Latitude": "48.343.383",
  "Longitude": "08.663.085",
  "Telephone": "07454-96040",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.343383,8.663085",
  "id": "48.343383,8.663085"
}, {
  "Brand": "TOTAL",
  "Street": "AHRSTR. 24",
  "PostCode": 53925,
  "City": "KALL",
  "Latitude": "50.470.835",
  "Longitude": "06.530.862",
  "Telephone": "02447-95050",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.470835,6.530862",
  "id": "50.470835,6.530862"
}, {
  "Brand": "TOTAL",
  "Street": "NORDHAEUSER STR. 19",
  "PostCode": 38899,
  "City": "OBERHARZ AM BROCKEN",
  "Latitude": "51.688.948",
  "Longitude": "10.844.776",
  "Telephone": "039459-71276",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.688948,10.844776",
  "id": "51.688948,10.844776"
}, {
  "Brand": "ARAL",
  "Street": "Alzeyer Straße 71",
  "PostCode": 67549,
  "City": "Worms",
  "Latitude": "49.632.292",
  "Longitude": "08.341.961",
  "Telephone": "06241209415",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.632292,8.341961",
  "id": "49.632292,8.341961"
}, {
  "Brand": "Westfalen",
  "Street": "Ammerländer Str. 8",
  "PostCode": 26676,
  "City": "BARSSEL",
  "Latitude": "53.169.200",
  "Longitude": "07.746.290",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.1692,7.74629",
  "id": "53.1692,7.74629"
}, {
  "Brand": "TOTAL",
  "Street": "ZIEROWER LANDSTRASSE 1",
  "PostCode": 23968,
  "City": "WISMAR",
  "Latitude": "53.902.733",
  "Longitude": "11.404.688",
  "Telephone": "03841-643704",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.902733,11.404688",
  "id": "53.902733,11.404688"
}, {
  "Brand": "TOTAL",
  "Street": "DESSAUER STR.",
  "PostCode": 6366,
  "City": "KOETHEN",
  "Latitude": "51.765.038",
  "Longitude": "11.987.581",
  "Telephone": "03496-216047",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.765038,11.987581",
  "id": "51.765038,11.987581"
}, {
  "Brand": "ARAL",
  "Street": "Knorrstr. 24",
  "PostCode": 34134,
  "City": "Kassel",
  "Latitude": "51.289.184",
  "Longitude": "09.474.662",
  "Telephone": "05619413797",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:30",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "51.289184,9.474662",
  "id": "51.289184,9.474662"
}, {
  "Brand": "ARAL",
  "Street": "Sonnenallee 113",
  "PostCode": 12045,
  "City": "Berlin",
  "Latitude": "52.481.479",
  "Longitude": "13.441.370",
  "Telephone": "0306811313",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.481479,13.44137",
  "id": "52.481479,13.44137"
}, {
  "Brand": "TOTAL",
  "Street": "DAHLENER STR. 16",
  "PostCode": 39576,
  "City": "STENDAL",
  "Latitude": "52.586.291",
  "Longitude": "11.838.674",
  "Telephone": "03931-414079",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.586291,11.838674",
  "id": "52.586291,11.838674"
}, {
  "Brand": "TOTAL",
  "Street": "GERICKESTR. 108",
  "PostCode": 39340,
  "City": "HALDENSLEBEN",
  "Latitude": "52.280.500",
  "Longitude": "11.431.370",
  "Telephone": "03904-65040",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.2805,11.43137",
  "id": "52.2805,11.43137"
}, {
  "Brand": "TOTAL",
  "Street": "AN DER A 3",
  "PostCode": 93164,
  "City": "BRUNN",
  "Latitude": "49.091.008",
  "Longitude": "11.888.473",
  "Telephone": "09498/9077889",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.091008,11.888473",
  "id": "49.091008,11.888473"
}, {
  "Brand": "TOTAL",
  "Street": "OSTSTR. 5",
  "PostCode": 42651,
  "City": "SOLINGEN",
  "Latitude": "51.171.938",
  "Longitude": "07.089.516",
  "Telephone": "0212-202894",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.171938,7.089516",
  "id": "51.171938,7.089516"
}, {
  "Brand": "TOTAL",
  "Street": "BENSBERGER STR. 240",
  "PostCode": 51469,
  "City": "BERGISCH GLADBACH",
  "Latitude": "50.975.315",
  "Longitude": "07.140.252",
  "Telephone": "02202-34120",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.975315,7.140252",
  "id": "50.975315,7.140252"
}, {
  "Brand": "TOTAL",
  "Street": "NORDHAEUSER STR.9",
  "PostCode": 99752,
  "City": "BLEICHERODE",
  "Latitude": "51.447.369",
  "Longitude": "10.584.102",
  "Telephone": "036338-43505",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.447369,10.584102",
  "id": "51.447369,10.584102"
}, {
  "Brand": "TOTAL",
  "Street": "COBURGER STR. 42",
  "PostCode": 96476,
  "City": "BAD RODACH",
  "Latitude": "50.340.853",
  "Longitude": "10.784.046",
  "Telephone": "09564-4833",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.340853,10.784046",
  "id": "50.340853,10.784046"
}, {
  "Brand": "Westfalen",
  "Street": "Edertalstrasse 6",
  "PostCode": 34549,
  "City": "EDERTAL",
  "Latitude": "51.143.500",
  "Longitude": "09.176.210",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.1435,9.17621",
  "id": "51.1435,9.17621"
}, {
  "Brand": "TOTAL",
  "Street": "AN DER LEIBI 1",
  "PostCode": 89278,
  "City": "NERSINGEN",
  "Latitude": "48.416.360",
  "Longitude": "10.100.961",
  "Telephone": "07308-9240280",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.41636,10.100961",
  "id": "48.41636,10.100961"
}, {
  "Brand": "ENI",
  "Street": "Reichenhaller Str. 24",
  "PostCode": 83334,
  "City": "Inzell",
  "Latitude": "47.761.119",
  "Longitude": "12.748.369",
  "Telephone": "+49 (0) 86659293",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.761119,12.748369",
  "id": "47.761119,12.748369"
}, {
  "Brand": "ENI",
  "Street": "Muenchner Strasse 40",
  "PostCode": 83043,
  "City": "Bad Aibling",
  "Latitude": "47.872.681",
  "Longitude": "11.980.675",
  "Telephone": "+49 (0) 80618508",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.872681,11.980675",
  "id": "47.872681,11.980675"
}, {
  "Brand": "ENI",
  "Street": "Ignaz-Schoen-Str. 1",
  "PostCode": 97421,
  "City": "Schweinfurt",
  "Latitude": "50.044.910",
  "Longitude": "10.217.850",
  "Telephone": "+49 (0) 97218232",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.04491,10.21785",
  "id": "50.04491,10.21785"
}, {
  "Brand": "ENI",
  "Street": "Kleinenztalstrasse 39",
  "PostCode": 75323,
  "City": "Bad Wildbad-Calmbach",
  "Latitude": "48.771.255",
  "Longitude": "08.583.785",
  "Telephone": "+49 (0) 70817554",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.771255,8.583785",
  "id": "48.771255,8.583785"
}, {
  "Brand": "ENI",
  "Street": "Nuernberger Strasse 2A",
  "PostCode": 92224,
  "City": "Amberg",
  "Latitude": "49.445.683",
  "Longitude": "11.834.792",
  "Telephone": "+49 (0) 96212247",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.445683,11.834792",
  "id": "49.445683,11.834792"
}, {
  "Brand": "OMV",
  "Street": "Ingolstädter Str. 28",
  "PostCode": 85080,
  "City": "Gaimersheim",
  "Latitude": "48.802.020",
  "Longitude": "11.369.870",
  "Telephone": "+49-8458-5474",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.80202,11.36987",
  "id": "48.80202,11.36987"
}, {
  "Brand": "OMV",
  "Street": "Donauwörther Str. 197",
  "PostCode": 86154,
  "City": "Augsburg",
  "Latitude": "48.397.470",
  "Longitude": "10.881.370",
  "Telephone": "+49-821-414815",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.39747,10.88137",
  "id": "48.39747,10.88137"
}, {
  "Brand": "TOTAL",
  "Street": "TUNIBERGSTR. 2",
  "PostCode": 79189,
  "City": "BAD KROZINGEN-HAUSEN",
  "Latitude": "47.953.706",
  "Longitude": "07.669.774",
  "Telephone": "07633-14592",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.953706,7.669774",
  "id": "47.953706,7.669774"
}, {
  "Brand": "ARAL",
  "Street": "Am Lachbach 1",
  "PostCode": 63546,
  "City": "Hammersbach",
  "Latitude": "50.243.066",
  "Longitude": "08.997.957",
  "Telephone": "06185893027",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.243066,8.997957",
  "id": "50.243066,8.997957"
}, {
  "Brand": "TOTAL",
  "Street": "34 BERGSTR.",
  "PostCode": 67067,
  "City": "LUDWIGSHAFEN-MAUDACH",
  "Latitude": "49.453.446",
  "Longitude": "08.375.457",
  "Telephone": "0621-553424",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.453446,8.375457",
  "id": "49.453446,8.375457"
}, {
  "Brand": "TOTAL",
  "Street": "BAB SUED / A10",
  "PostCode": 14552,
  "City": "MICHENDORF",
  "Latitude": "52.302.624",
  "Longitude": "13.014.763",
  "Telephone": "033205-46699",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.302624,13.014763",
  "id": "52.302624,13.014763"
}, {
  "Brand": "TOTAL",
  "Street": "CELLER STRASSE 65",
  "PostCode": 38114,
  "City": "BRAUNSCHWEIG",
  "Latitude": "52.279.604",
  "Longitude": "10.505.423",
  "Telephone": "053121931707",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.279604,10.505423",
  "id": "52.279604,10.505423"
}, {
  "Brand": "OMV",
  "Street": "Autobahn Süd A3",
  "PostCode": 91074,
  "City": "Herzogenaurach",
  "Latitude": "49.582.640",
  "Longitude": "10.926.850",
  "Telephone": "+49-9131-41595",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.58264,10.92685",
  "id": "49.58264,10.92685"
}, {
  "Brand": "ARAL",
  "Street": "A38, Kirchworbiser Wiesen 2",
  "PostCode": 37339,
  "City": "Kirchworbis",
  "Latitude": "51.402.786",
  "Longitude": "10.414.647",
  "Telephone": "",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.402786,10.414647",
  "id": "51.402786,10.414647"
}, {
  "Brand": "TOTAL",
  "Street": "FRIEDRICH-ECKARDT-STRASSE 2",
  "PostCode": 8529,
  "City": "PLAUEN",
  "Latitude": "50.508.046",
  "Longitude": "12.158.484",
  "Telephone": "03741-442003",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.508046,12.158484",
  "id": "50.508046,12.158484"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 57",
  "PostCode": 52388,
  "City": "Nörvenich",
  "Latitude": "50.809.733",
  "Longitude": "06.636.635",
  "Telephone": "02426901818",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.809733,6.636635",
  "id": "50.809733,6.636635"
}, {
  "Brand": "TOTAL",
  "Street": "BGM.-PUETZ-STRASSE 8",
  "PostCode": 47137,
  "City": "DUISBURG",
  "Latitude": "51.463.548",
  "Longitude": "06.773.462",
  "Telephone": "0203-446384",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.463548,6.773462",
  "id": "51.463548,6.773462"
}, {
  "Brand": "TOTAL",
  "Street": "BAB WESTSEITE / A 61",
  "PostCode": 67125,
  "City": "DANNSTADT-SCHAUERNHEIM",
  "Latitude": "49.410.300",
  "Longitude": "08.339.999",
  "Telephone": "06231-2542",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.4103,8.339999",
  "id": "49.4103,8.339999"
}, {
  "Brand": "TOTAL",
  "Street": "INDUSTRIESTR. 19",
  "PostCode": 64546,
  "City": "MOERFELDEN-WALLDORF",
  "Latitude": "49.983.596",
  "Longitude": "08.579.311",
  "Telephone": "06105-21069",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.983596,8.579311",
  "id": "49.983596,8.579311"
}, {
  "Brand": "ARAL",
  "Street": "Göteborger Straße 12 a",
  "PostCode": 6686,
  "City": "Lützen",
  "Latitude": "51.251.734",
  "Longitude": "12.137.886",
  "Telephone": "03444420030",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.251734,12.137886",
  "id": "51.251734,12.137886"
}, {
  "Brand": "OMV",
  "Street": "Hauptstr. 215",
  "PostCode": 76297,
  "City": "Stutensee",
  "Latitude": "49.058.880",
  "Longitude": "08.468.420",
  "Telephone": "+49-7244-91994",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.05888,8.46842",
  "id": "49.05888,8.46842"
}, {
  "Brand": "Westfalen",
  "Street": "Soester Str. 43",
  "PostCode": 59457,
  "City": "WERL",
  "Latitude": "51.549.600",
  "Longitude": "07.922.610",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.5496,7.92261",
  "id": "51.5496,7.92261"
}, {
  "Brand": "ENI",
  "Street": "Frankenhoehe Nord",
  "PostCode": 91589,
  "City": "Aurach",
  "Latitude": "49.242.340",
  "Longitude": "10.357.620",
  "Telephone": "+49 (0) 98049191",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.24234,10.35762",
  "id": "49.24234,10.35762"
}, {
  "Brand": "OMV",
  "Street": "Dieselstr. 11",
  "PostCode": 73072,
  "City": "Donzdorf",
  "Latitude": "48.686.150",
  "Longitude": "09.790.360",
  "Telephone": "+49-7162-9413430",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.68615,9.79036",
  "id": "48.68615,9.79036"
}, {
  "Brand": "ARAL",
  "Street": "Im Schiffels 1",
  "PostCode": 55491,
  "City": "Büchenbeuren",
  "Latitude": "49.927.260",
  "Longitude": "07.279.584",
  "Telephone": "065435005438",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.92726,7.279584",
  "id": "49.92726,7.279584"
}, {
  "Brand": "ENI",
  "Street": "Salierstrasse 62",
  "PostCode": 67105,
  "City": "Schifferstadt",
  "Latitude": "49.383.070",
  "Longitude": "08.388.410",
  "Telephone": "+49 (0) 62359886",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.38307,8.38841",
  "id": "49.38307,8.38841"
}, {
  "Brand": "TOTAL",
  "Street": "LENGGRIESER STR. 87",
  "PostCode": 83646,
  "City": "BAD TOELZ",
  "Latitude": "47.747.856",
  "Longitude": "11.565.409",
  "Telephone": "08041-8445",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.747856,11.565409",
  "id": "47.747856,11.565409"
}, {
  "Brand": "TOTAL",
  "Street": "ALUMINIUMSTR. 5",
  "PostCode": 21129,
  "City": "HAMBURG",
  "Latitude": "53.517.282",
  "Longitude": "09.890.250",
  "Telephone": "040-30239835",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.517282,9.89025",
  "id": "53.517282,9.89025"
}, {
  "Brand": "ARAL",
  "Street": "Donauwörther Straße 62",
  "PostCode": 86633,
  "City": "Neuburg",
  "Latitude": "48.733.558",
  "Longitude": "11.167.111",
  "Telephone": "084312790",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.733558,11.167111",
  "id": "48.733558,11.167111"
}, {
  "Brand": "ARAL",
  "Street": "A4",
  "PostCode": 51491,
  "City": "Overath",
  "Latitude": "50.950.560",
  "Longitude": "07.319.641",
  "Telephone": "0220695470",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.95056,7.319641",
  "id": "50.95056,7.319641"
}, {
  "Brand": "Westfalen",
  "Street": "Kamper Str. 11",
  "PostCode": 42699,
  "City": "SOLINGEN",
  "Latitude": "51.158.600",
  "Longitude": "07.005.360",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.1586,7.00536",
  "id": "51.1586,7.00536"
}, {
  "Brand": "ARAL",
  "Street": "Europaallee 16-18",
  "PostCode": 50226,
  "City": "Frechen",
  "Latitude": "50.920.762",
  "Longitude": "06.833.448",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.920762,6.833448",
  "id": "50.920762,6.833448"
}, {
  "Brand": "TOTAL",
  "Street": "SCHUETZENSTR.18",
  "PostCode": 58239,
  "City": "SCHWERTE",
  "Latitude": "51.444.399",
  "Longitude": "07.577.083",
  "Telephone": "02304-13152",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.444399,7.577083",
  "id": "51.444399,7.577083"
}, {
  "Brand": "ARAL",
  "Street": "Stockholmer Str. 17",
  "PostCode": 67346,
  "City": "Speyer",
  "Latitude": "49.293.814",
  "Longitude": "08.477.264",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.293814,8.477264",
  "id": "49.293814,8.477264"
}, {
  "Brand": "TOTAL",
  "Street": "BASLER STR. 57",
  "PostCode": 79100,
  "City": "FREIBURG",
  "Latitude": "47.987.067",
  "Longitude": "07.838.608",
  "Telephone": "0761402902",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.987067,7.838608",
  "id": "47.987067,7.838608"
}, {
  "Brand": "TOTAL",
  "Street": "AUTOHOF STRAENGENBACH 1",
  "PostCode": 59510,
  "City": "LIPPETAL",
  "Latitude": "51.695.657",
  "Longitude": "07.967.105",
  "Telephone": "02388-307400",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.695657,7.967105",
  "id": "51.695657,7.967105"
}, {
  "Brand": "Westfalen",
  "Street": "Karl-Benz-Str. 2",
  "PostCode": 48565,
  "City": "STEINFURT",
  "Latitude": "52.159.700",
  "Longitude": "07.318.720",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.1597,7.31872",
  "id": "52.1597,7.31872"
}, {
  "Brand": "ENI",
  "Street": "Allersberger Str. 81",
  "PostCode": 91154,
  "City": "Roth",
  "Latitude": "49.246.740",
  "Longitude": "11.106.390",
  "Telephone": "+49 (0) 91716264",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.24674,11.10639",
  "id": "49.24674,11.10639"
}, {
  "Brand": "ENI",
  "Street": "Unterislinger Weg 13",
  "PostCode": 93053,
  "City": "Regensburg",
  "Latitude": "48.999.720",
  "Longitude": "12.110.960",
  "Telephone": "+49 (0) 94172181",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.99972,12.11096",
  "id": "48.99972,12.11096"
}, {
  "Brand": "TOTAL",
  "Street": "DODOWEG 29",
  "PostCode": 26386,
  "City": "WILHELMSHAVEN",
  "Latitude": "53.558.470",
  "Longitude": "08.099.270",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.55847,8.09927",
  "id": "53.55847,8.09927"
}, {
  "Brand": "ENI",
  "Street": "Mindelheimer Str. 78",
  "PostCode": 87600,
  "City": "Kaufbeuren",
  "Latitude": "47.906.063",
  "Longitude": "10.608.477",
  "Telephone": "+49 (0) 83411222",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.906063,10.608477",
  "id": "47.906063,10.608477"
}, {
  "Brand": "ENI",
  "Street": "Eberstaedter Strasse 115",
  "PostCode": 64319,
  "City": "Pfungstadt",
  "Latitude": "49.804.360",
  "Longitude": "08.602.010",
  "Telephone": "+49 (0) 61578656",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.80436,8.60201",
  "id": "49.80436,8.60201"
}, {
  "Brand": "ENI",
  "Street": "Magdeburger Str. 262",
  "PostCode": 39218,
  "City": "Schoenebeck",
  "Latitude": "52.034.200",
  "Longitude": "11.705.330",
  "Telephone": "+49 (0) 39288435",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "52.0342,11.70533",
  "id": "52.0342,11.70533"
}, {
  "Brand": "TOTAL",
  "Street": "ZURMAIENERSTRASSE 155-157",
  "PostCode": 54292,
  "City": "TRIER",
  "Latitude": "49.771.696",
  "Longitude": "06.657.853",
  "Telephone": "06514368353",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.771696,6.657853",
  "id": "49.771696,6.657853"
}, {
  "Brand": "Westfalen",
  "Street": "Danebrocker Str. 10",
  "PostCode": 49492,
  "City": "WESTERKAPPELN S",
  "Latitude": "52.260.647",
  "Longitude": "07.849.282",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.260647,7.849282",
  "id": "52.260647,7.849282"
}, {
  "Brand": "ARAL",
  "Street": "Bremer Straße 32",
  "PostCode": 27367,
  "City": "Sottrum",
  "Latitude": "53.113.645",
  "Longitude": "09.231.325",
  "Telephone": "042648366330",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "05:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.113645,9.231325",
  "id": "53.113645,9.231325"
}, {
  "Brand": "Westfalen",
  "Street": "Lingener Str. 106",
  "PostCode": 48531,
  "City": "NORDHORN",
  "Latitude": "52.440.600",
  "Longitude": "07.086.320",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.4406,7.08632",
  "id": "52.4406,7.08632"
}, {
  "Brand": "ENI",
  "Street": "Bergstrae 39",
  "PostCode": 37339,
  "City": "Teistungen",
  "Latitude": "51.469.980",
  "Longitude": "10.269.710",
  "Telephone": "+49 (0) 36071872",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.46998,10.26971",
  "id": "51.46998,10.26971"
}, {
  "Brand": "Westfalen",
  "Street": "Dortmunder Str. 239",
  "PostCode": 45665,
  "City": "RECKLINGHAUSEN",
  "Latitude": "51.622.500",
  "Longitude": "07.229.100",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.6225,7.2291",
  "id": "51.6225,7.2291"
}, {
  "Brand": "OMV",
  "Street": "Illesheimer Str. 3",
  "PostCode": 91438,
  "City": "Bad Windsheim",
  "Latitude": "49.497.740",
  "Longitude": "10.409.060",
  "Telephone": "+49-9841-6601082",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.49774,10.40906",
  "id": "49.49774,10.40906"
}, {
  "Brand": "ARAL",
  "Street": "An der B 7",
  "PostCode": 7580,
  "City": "Ronneburg",
  "Latitude": "50.868.725",
  "Longitude": "12.216.167",
  "Telephone": "036602519387",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.868725,12.216167",
  "id": "50.868725,12.216167"
}, {
  "Brand": "OMV",
  "Street": "Gadastraße 1",
  "PostCode": 85232,
  "City": "Bergkirchen",
  "Latitude": "48.237.380",
  "Longitude": "11.352.830",
  "Telephone": "+49-8142-2842072",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.23738,11.35283",
  "id": "48.23738,11.35283"
}, {
  "Brand": "ARAL",
  "Street": "Hirschorner Straße 41",
  "PostCode": 69239,
  "City": "Neckarsteinach",
  "Latitude": "49.399.082",
  "Longitude": "08.845.576",
  "Telephone": "06229930635",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.399082,8.845576",
  "id": "49.399082,8.845576"
}, {
  "Brand": "ENI",
  "Street": "Lindauer Str. 17",
  "PostCode": 87509,
  "City": "Immenstadt",
  "Latitude": "47.566.860",
  "Longitude": "10.193.030",
  "Telephone": "+49 (0) 83231855",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.56686,10.19303",
  "id": "47.56686,10.19303"
}, {
  "Brand": "OMV",
  "Street": "Bamberger Str. 31",
  "PostCode": 96129,
  "City": "Strullendorf",
  "Latitude": "49.847.060",
  "Longitude": "10.964.440",
  "Telephone": "+49-9543-258",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.84706,10.96444",
  "id": "49.84706,10.96444"
}, {
  "Brand": "Westfalen",
  "Street": "Stieghorster Str. 108",
  "PostCode": 33605,
  "City": "BIELEFELD",
  "Latitude": "52.007.300",
  "Longitude": "08.589.540",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0073,8.58954",
  "id": "52.0073,8.58954"
}, {
  "Brand": "OMV",
  "Street": "Haller Str. 15",
  "PostCode": 73479,
  "City": "Ellwangen",
  "Latitude": "48.965.030",
  "Longitude": "10.127.320",
  "Telephone": "+49-7961-562927",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.96503,10.12732",
  "id": "48.96503,10.12732"
}, {
  "Brand": "ARAL",
  "Street": "Am Flurgraben 1-11",
  "PostCode": 65462,
  "City": "Ginsheim-Gustavsburg",
  "Latitude": "49.987.533",
  "Longitude": "08.327.749",
  "Telephone": "061345678996",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "49.987533,8.327749",
  "id": "49.987533,8.327749"
}, {
  "Brand": "TOTAL",
  "Street": "MARKSTR. 119",
  "PostCode": 44803,
  "City": "BOCHUM",
  "Latitude": "51.455.461",
  "Longitude": "07.250.342",
  "Telephone": "0234-38619",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.455461,7.250342",
  "id": "51.455461,7.250342"
}, {
  "Brand": "TOTAL",
  "Street": "KIRCHSTR. 23",
  "PostCode": 40699,
  "City": "ERKRATH",
  "Latitude": "51.223.767",
  "Longitude": "06.916.481",
  "Telephone": "0211-9003030",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.223767,6.916481",
  "id": "51.223767,6.916481"
}, {
  "Brand": "TOTAL",
  "Street": "EICHBORNDAMM 91",
  "PostCode": 13403,
  "City": "BERLIN",
  "Latitude": "52.576.415",
  "Longitude": "13.314.502",
  "Telephone": "030-41703023",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.576415,13.314502",
  "id": "52.576415,13.314502"
}, {
  "Brand": "TOTAL",
  "Street": "PARKSTR. 90 A",
  "PostCode": 19322,
  "City": "WITTENBERGE",
  "Latitude": "53.001.296",
  "Longitude": "11.746.876",
  "Telephone": "03877-402317",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.001296,11.746876",
  "id": "53.001296,11.746876"
}, {
  "Brand": "ARAL",
  "Street": "Bräukerweg 132",
  "PostCode": 58708,
  "City": "Menden",
  "Latitude": "51.436.793",
  "Longitude": "07.758.082",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.436793,7.758082",
  "id": "51.436793,7.758082"
}, {
  "Brand": "TOTAL",
  "Street": "AM ANGER",
  "PostCode": 38855,
  "City": "WERNIGERODE",
  "Latitude": "51.837.870",
  "Longitude": "10.794.663",
  "Telephone": "03943-23094",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.83787,10.794663",
  "id": "51.83787,10.794663"
}, {
  "Brand": "TOTAL",
  "Street": "INN.ZWICKAUER STR.29",
  "PostCode": 8062,
  "City": "ZWICKAU",
  "Latitude": "50.694.747",
  "Longitude": "12.479.166",
  "Telephone": "0375-787047",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.694747,12.479166",
  "id": "50.694747,12.479166"
}, {
  "Brand": "TOTAL",
  "Street": "GOTTENHEIMER STR.16",
  "PostCode": 79268,
  "City": "BOETZINGEN",
  "Latitude": "48.070.138",
  "Longitude": "07.720.078",
  "Telephone": "076639427420",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.070138,7.720078",
  "id": "48.070138,7.720078"
}, {
  "Brand": "TOTAL",
  "Street": "WUERZBURGER STR. 26",
  "PostCode": 1187,
  "City": "DRESDEN",
  "Latitude": "51.032.199",
  "Longitude": "13.710.689",
  "Telephone": "0351-4717777",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.032199,13.710689",
  "id": "51.032199,13.710689"
}, {
  "Brand": "TOTAL",
  "Street": "ALTENLINDE 1",
  "PostCode": 51789,
  "City": "LINDLAR",
  "Latitude": "51.023.488",
  "Longitude": "07.370.519",
  "Telephone": "02266-5556",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.023488,7.370519",
  "id": "51.023488,7.370519"
}, {
  "Brand": "TOTAL",
  "Street": "LEIBNIZSTR. 74",
  "PostCode": 7548,
  "City": "GERA",
  "Latitude": "50.891.055",
  "Longitude": "12.068.623",
  "Telephone": "0365-8321474",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.891055,12.068623",
  "id": "50.891055,12.068623"
}, {
  "Brand": "TOTAL",
  "Street": "KEMBERGER STR. 9",
  "PostCode": 6905,
  "City": "BAD SCHMIEDEBERG",
  "Latitude": "51.693.899",
  "Longitude": "12.737.083",
  "Telephone": "034925-71176",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.693899,12.737083",
  "id": "51.693899,12.737083"
}, {
  "Brand": "Westfalen",
  "Street": "Warendorfer Str. 12",
  "PostCode": 48361,
  "City": "BEELEN",
  "Latitude": "51.929.700",
  "Longitude": "08.120.680",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9297,8.12068",
  "id": "51.9297,8.12068"
}, {
  "Brand": "ARAL",
  "Street": "Göttinger Straße 45",
  "PostCode": 37120,
  "City": "Bovenden",
  "Latitude": "51.586.568",
  "Longitude": "09.926.590",
  "Telephone": "05518768",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.586568,9.92659",
  "id": "51.586568,9.92659"
}, {
  "Brand": "ARAL",
  "Street": "Stettiner Chaussee",
  "PostCode": 17309,
  "City": "Pasewalk",
  "Latitude": "53.503.341",
  "Longitude": "14.000.396",
  "Telephone": "03973214214",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.503341,14.000396",
  "id": "53.503341,14.000396"
}, {
  "Brand": "TOTAL",
  "Street": "OLCHINGER STR. 82",
  "PostCode": 82194,
  "City": "GROEBENZELL",
  "Latitude": "48.186.099",
  "Longitude": "11.384.181",
  "Telephone": "08142-58627",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.186099,11.384181",
  "id": "48.186099,11.384181"
}, {
  "Brand": "ENI",
  "Street": "Regensburger Strasse 68/A",
  "PostCode": 92224,
  "City": "Amberg",
  "Latitude": "49.439.730",
  "Longitude": "11.877.100",
  "Telephone": "+49 (0) 96217814",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.43973,11.8771",
  "id": "49.43973,11.8771"
}, {
  "Brand": "TOTAL",
  "Street": "SEBASTIANSTR. 28",
  "PostCode": 91058,
  "City": "ERLANGEN",
  "Latitude": "49.545.202",
  "Longitude": "11.029.889",
  "Telephone": "09131/602151",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.545202,11.029889",
  "id": "49.545202,11.029889"
}, {
  "Brand": "TOTAL",
  "Street": "GLETZOWER STR. 62 B",
  "PostCode": 19217,
  "City": "REHNA",
  "Latitude": "53.785.438",
  "Longitude": "11.044.110",
  "Telephone": "038872-53341",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.785438,11.04411",
  "id": "53.785438,11.04411"
}, {
  "Brand": "TOTAL",
  "Street": "BOEBLINGER STR. 6",
  "PostCode": 71116,
  "City": "GAERTRINGEN",
  "Latitude": "48.638.912",
  "Longitude": "08.909.811",
  "Telephone": "07034-285065",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.638912,8.909811",
  "id": "48.638912,8.909811"
}, {
  "Brand": "ENI",
  "Street": "Dieburger Strasse 117",
  "PostCode": 63322,
  "City": "Roedermark-Oberroden",
  "Latitude": "49.978.245",
  "Longitude": "08.761.750",
  "Telephone": "+49 (0) 60748816",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.978245,8.76175",
  "id": "49.978245,8.76175"
}, {
  "Brand": "TOTAL",
  "Street": "KLOETZER STR. 12a",
  "PostCode": 1587,
  "City": "RIESA",
  "Latitude": "51.305.360",
  "Longitude": "13.297.558",
  "Telephone": "03525/7770310",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.30536,13.297558",
  "id": "51.30536,13.297558"
}, {
  "Brand": "TOTAL",
  "Street": "PROSPERSTR. 440",
  "PostCode": 46238,
  "City": "BOTTROP",
  "Latitude": "51.522.289",
  "Longitude": "06.983.473",
  "Telephone": "02041-7763253",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.522289,6.983473",
  "id": "51.522289,6.983473"
}, {
  "Brand": "ARAL",
  "Street": "A7",
  "PostCode": 37124,
  "City": "Rosdorf",
  "Latitude": "51.489.334",
  "Longitude": "09.877.629",
  "Telephone": "0550992500",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.489334,9.877629",
  "id": "51.489334,9.877629"
}, {
  "Brand": "TOTAL",
  "Street": "LORCHER STR. 199",
  "PostCode": 73529,
  "City": "SCHWAEBISCH-GMUEND",
  "Latitude": "48.788.633",
  "Longitude": "09.763.438",
  "Telephone": "07171-9998895",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.788633,9.763438",
  "id": "48.788633,9.763438"
}, {
  "Brand": "TOTAL",
  "Street": "LIECKER STR. 62",
  "PostCode": 52525,
  "City": "HEINSBERG",
  "Latitude": "51.066.445",
  "Longitude": "06.088.018",
  "Telephone": "02452-155476",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.066445,6.088018",
  "id": "51.066445,6.088018"
}, {
  "Brand": "ENI",
  "Street": "Nicola-Tesla-Strae",
  "PostCode": 77972,
  "City": "Mahlberg",
  "Latitude": "48.275.362",
  "Longitude": "07.784.657",
  "Telephone": "+49 (0) 78224405",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.275362,7.784657",
  "id": "48.275362,7.784657"
}, {
  "Brand": "TOTAL",
  "Street": "BANTER WEG 121",
  "PostCode": 26389,
  "City": "WILHELMSHAVEN",
  "Latitude": "53.522.372",
  "Longitude": "08.091.551",
  "Telephone": "04421-9644884",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.522372,8.091551",
  "id": "53.522372,8.091551"
}, {
  "Brand": "TOTAL",
  "Street": "KAPPEL 2",
  "PostCode": 95473,
  "City": "CREUSSEN",
  "Latitude": "49.841.300",
  "Longitude": "11.623.887",
  "Telephone": "09270378",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.8413,11.623887",
  "id": "49.8413,11.623887"
}, {
  "Brand": "Westfalen",
  "Street": "Westkirchener 7-9",
  "PostCode": 59320,
  "City": "ENNIGERLOH",
  "Latitude": "51.839.600",
  "Longitude": "08.025.760",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8396,8.02576",
  "id": "51.8396,8.02576"
}, {
  "Brand": "TOTAL",
  "Street": "NECKARSTR. 47",
  "PostCode": 71334,
  "City": "WAIBLINGEN",
  "Latitude": "48.852.901",
  "Longitude": "09.296.984",
  "Telephone": "07151-52837",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.852901,9.296984",
  "id": "48.852901,9.296984"
}, {
  "Brand": "TOTAL",
  "Street": "MUENCHNERSTR. 11A",
  "PostCode": 85375,
  "City": "NEUFAHRN",
  "Latitude": "48.318.361",
  "Longitude": "11.691.502",
  "Telephone": "08165-66442",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.318361,11.691502",
  "id": "48.318361,11.691502"
}, {
  "Brand": "ARAL",
  "Street": "Eppelheimer Straße 78",
  "PostCode": 69123,
  "City": "Heidelberg",
  "Latitude": "49.403.431",
  "Longitude": "08.646.197",
  "Telephone": "062217569",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "18:00",
  "IsOpen24Hours": "Nein",
  "location": "49.403431,8.646197",
  "id": "49.403431,8.646197"
}, {
  "Brand": "ARAL",
  "Street": "Feldstraße 2",
  "PostCode": 64347,
  "City": "Griesheim",
  "Latitude": "49.867.487",
  "Longitude": "08.554.532",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.867487,8.554532",
  "id": "49.867487,8.554532"
}, {
  "Brand": "ARAL",
  "Street": "Rosenheimer Landstraße 26",
  "PostCode": 85521,
  "City": "Ottobrunn",
  "Latitude": "48.068.506",
  "Longitude": "11.657.963",
  "Telephone": "08960850875",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.068506,11.657963",
  "id": "48.068506,11.657963"
}, {
  "Brand": "Westfalen",
  "Street": "Paderborner Str. 319",
  "PostCode": 33689,
  "City": "BIELEFELD",
  "Latitude": "51.932.400",
  "Longitude": "08.606.900",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9324,8.6069",
  "id": "51.9324,8.6069"
}, {
  "Brand": "TOTAL",
  "Street": "GOETELSTR. 132-140",
  "PostCode": 13595,
  "City": "BERLIN",
  "Latitude": "52.521.435",
  "Longitude": "13.203.645",
  "Telephone": "030/22325440",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.521435,13.203645",
  "id": "52.521435,13.203645"
}, {
  "Brand": "Westfalen",
  "Street": "Hauptstr. 58",
  "PostCode": 31712,
  "City": "WILHELMSHAVEN",
  "Latitude": "53.592.600",
  "Longitude": "08.045.190",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.5926,8.04519",
  "id": "53.5926,8.04519"
}, {
  "Brand": "TOTAL",
  "Street": "KRAIBURGER STR. 13",
  "PostCode": 84478,
  "City": "WALDKRAIBURG",
  "Latitude": "48.207.642",
  "Longitude": "12.417.865",
  "Telephone": "08631-378131",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.207642,12.417865",
  "id": "48.207642,12.417865"
}, {
  "Brand": "TOTAL",
  "Street": "FRIEDRICH-FRANZ-STR. 2",
  "PostCode": 14770,
  "City": "BRANDENBURG",
  "Latitude": "52.413.665",
  "Longitude": "12.524.106",
  "Telephone": "03381-301295",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.413665,12.524106",
  "id": "52.413665,12.524106"
}, {
  "Brand": "Westfalen",
  "Street": "Bielefelder Str. 107",
  "PostCode": 33378,
  "City": "RHEDA-WIEDENBRUECK",
  "Latitude": "51.845.900",
  "Longitude": "08.320.750",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8459,8.32075",
  "id": "51.8459,8.32075"
}, {
  "Brand": "ARAL",
  "Street": "Boltensternstraße 350",
  "PostCode": 50735,
  "City": "Köln",
  "Latitude": "50.980.505",
  "Longitude": "06.969.038",
  "Telephone": "02219775353",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.980505,6.969038",
  "id": "50.980505,6.969038"
}, {
  "Brand": "Westfalen",
  "Street": "Cranger Str. 215",
  "PostCode": 45891,
  "City": "GELSENKIRCHEN",
  "Latitude": "51.563.600",
  "Longitude": "07.085.380",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.5636,7.08538",
  "id": "51.5636,7.08538"
}, {
  "Brand": "TOTAL",
  "Street": "KASSELER STR. 30",
  "PostCode": 35683,
  "City": "DILLENBURG",
  "Latitude": "50.749.592",
  "Longitude": "08.276.962",
  "Telephone": "02771-812042",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.749592,8.276962",
  "id": "50.749592,8.276962"
}, {
  "Brand": "ENI",
  "Street": "Berliner Allee 67",
  "PostCode": 16303,
  "City": "Schwedt/Oder",
  "Latitude": "53.052.556",
  "Longitude": "14.280.445",
  "Telephone": "+49 (0) 33322348",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "53.052556,14.280445",
  "id": "53.052556,14.280445"
}, {
  "Brand": "ENI",
  "Street": "Heidensteilstrasse 8",
  "PostCode": 55743,
  "City": "Idar-Oberstein",
  "Latitude": "49.727.190",
  "Longitude": "07.312.220",
  "Telephone": "+49 (0) 67814313",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.72719,7.31222",
  "id": "49.72719,7.31222"
}, {
  "Brand": "OMV",
  "Street": "Kronacher Str. 65",
  "PostCode": 96349,
  "City": "Steinwiesen",
  "Latitude": "50.291.450",
  "Longitude": "11.452.180",
  "Telephone": "+49-9262-478",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.29145,11.45218",
  "id": "50.29145,11.45218"
}, {
  "Brand": "ENI",
  "Street": "Heidelberger Strasse 35",
  "PostCode": 64342,
  "City": "Seeheim-Jugenheim",
  "Latitude": "49.771.380",
  "Longitude": "08.645.980",
  "Telephone": "+49 (0) 62579629",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.77138,8.64598",
  "id": "49.77138,8.64598"
}, {
  "Brand": "TOTAL",
  "Street": "23 VINNHORSTER WEG",
  "PostCode": 30419,
  "City": "HANNOVER",
  "Latitude": "52.400.800",
  "Longitude": "09.693.550",
  "Telephone": "0511-753165",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.4008,9.69355",
  "id": "52.4008,9.69355"
}, {
  "Brand": "ENI",
  "Street": "Leistenstrasse 2",
  "PostCode": 97204,
  "City": "Hoechberg",
  "Latitude": "49.788.421",
  "Longitude": "09.898.763",
  "Telephone": "+49 (0) 93149266",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.788421,9.898763",
  "id": "49.788421,9.898763"
}, {
  "Brand": "OMV",
  "Street": "Freyunger Str. 11",
  "PostCode": 94151,
  "City": "Mauth",
  "Latitude": "48.884.550",
  "Longitude": "13.582.490",
  "Telephone": "+49-8557-448",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.88455,13.58249",
  "id": "48.88455,13.58249"
}, {
  "Brand": "ENI",
  "Street": "Zwingenberger Strasse 25",
  "PostCode": 64342,
  "City": "Seeheim-Jugenheim",
  "Latitude": "49.751.854",
  "Longitude": "08.631.091",
  "Telephone": "+49 (0) 62579036",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.751854,8.631091",
  "id": "49.751854,8.631091"
}, {
  "Brand": "Westfalen",
  "Street": "Roitzheimer Str. 117",
  "PostCode": 53879,
  "City": "EUSKIRCHEN",
  "Latitude": "50.652.200",
  "Longitude": "06.801.020",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.6522,6.80102",
  "id": "50.6522,6.80102"
}, {
  "Brand": "ENI",
  "Street": "Plauensche Str. 2",
  "PostCode": 7973,
  "City": "Greiz",
  "Latitude": "50.647.206",
  "Longitude": "12.194.694",
  "Telephone": "+49 (0) 36616352",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.647206,12.194694",
  "id": "50.647206,12.194694"
}, {
  "Brand": "ARAL",
  "Street": "Königsteiner Straße 23",
  "PostCode": 65812,
  "City": "Bad Soden",
  "Latitude": "50.140.240",
  "Longitude": "08.505.094",
  "Telephone": "06196671777",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "01:00",
  "SundayFrom": "05:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "50.14024,8.505094",
  "id": "50.14024,8.505094"
}, {
  "Brand": "TOTAL",
  "Street": "THEELTALSTRASSE 50",
  "PostCode": 66636,
  "City": "THOLEY-HASBORN",
  "Latitude": "49.487.650",
  "Longitude": "06.984.632",
  "Telephone": "068538540045",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.48765,6.984632",
  "id": "49.48765,6.984632"
}, {
  "Brand": "Westfalen",
  "Street": "Bruchstr. 18 - 21",
  "PostCode": 45468,
  "City": "MUELHEIM",
  "Latitude": "51.435.700",
  "Longitude": "06.890.930",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.4357,6.89093",
  "id": "51.4357,6.89093"
}, {
  "Brand": "ARAL",
  "Street": "Friedr.-Engels-Allee 237",
  "PostCode": 42285,
  "City": "Wuppertal",
  "Latitude": "51.263.429",
  "Longitude": "07.177.795",
  "Telephone": "020281457",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.263429,7.177795",
  "id": "51.263429,7.177795"
}, {
  "Brand": "TOTAL",
  "Street": "DRESDNER STR.53",
  "PostCode": 1458,
  "City": "OTTENDORF-OKRILLA",
  "Latitude": "51.174.856",
  "Longitude": "13.825.185",
  "Telephone": "035205-54211",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.174856,13.825185",
  "id": "51.174856,13.825185"
}, {
  "Brand": "TOTAL",
  "Street": "TONNAER STR. 28",
  "PostCode": 99947,
  "City": "BAD LANGENSALZA",
  "Latitude": "51.104.253",
  "Longitude": "10.664.366",
  "Telephone": "03603-842355",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.104253,10.664366",
  "id": "51.104253,10.664366"
}, {
  "Brand": "TOTAL",
  "Street": "HECHTSBERG 8",
  "PostCode": 77756,
  "City": "HAUSACH",
  "Latitude": "48.281.860",
  "Longitude": "08.137.154",
  "Telephone": "078314149988",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.28186,8.137154",
  "id": "48.28186,8.137154"
}, {
  "Brand": "ARAL",
  "Street": "Greizer Straße 1",
  "PostCode": 8412,
  "City": "Werdau",
  "Latitude": "50.723.501",
  "Longitude": "12.374.233",
  "Telephone": "037615209",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "05:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.723501,12.374233",
  "id": "50.723501,12.374233"
}, {
  "Brand": "TOTAL",
  "Street": "SCHUETTENDELLE 27",
  "PostCode": 42857,
  "City": "REMSCHEID",
  "Latitude": "51.181.043",
  "Longitude": "07.174.071",
  "Telephone": "02191-2099115",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.181043,7.174071",
  "id": "51.181043,7.174071"
}, {
  "Brand": "TOTAL",
  "Street": "LEHRTE STR. 20",
  "PostCode": 31319,
  "City": "SEHNDE",
  "Latitude": "52.317.780",
  "Longitude": "09.966.101",
  "Telephone": "05138-7099202",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.31778,9.966101",
  "id": "52.31778,9.966101"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 88",
  "PostCode": 88074,
  "City": "Meckenbeuren",
  "Latitude": "47.693.377",
  "Longitude": "09.559.551",
  "Telephone": "075424251",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.693377,9.559551",
  "id": "47.693377,9.559551"
}, {
  "Brand": "Westfalen",
  "Street": "Asberger Str. 188",
  "PostCode": 47441,
  "City": "MOERS",
  "Latitude": "51.438.700",
  "Longitude": "06.657.600",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.4387,6.6576",
  "id": "51.4387,6.6576"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 287",
  "PostCode": 58675,
  "City": "Hemer",
  "Latitude": "51.390.950",
  "Longitude": "07.762.034",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.39095,7.762034",
  "id": "51.39095,7.762034"
}, {
  "Brand": "TOTAL",
  "Street": "B2",
  "PostCode": 86695,
  "City": "NORDENDORF",
  "Latitude": "48.596.612",
  "Longitude": "10.840.520",
  "Telephone": "08273/9969887",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.596612,10.84052",
  "id": "48.596612,10.84052"
}, {
  "Brand": "TOTAL",
  "Street": "UMGEHUNGSSTRASSE 1",
  "PostCode": 18556,
  "City": "ALTENKIRCHEN",
  "Latitude": "54.631.785",
  "Longitude": "13.347.598",
  "Telephone": "038391-231",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.631785,13.347598",
  "id": "54.631785,13.347598"
}, {
  "Brand": "TOTAL",
  "Street": "DUESSELDORFER STR. 28",
  "PostCode": 42781,
  "City": "HAAN",
  "Latitude": "51.188.318",
  "Longitude": "06.994.868",
  "Telephone": "02129-959770",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.188318,6.994868",
  "id": "51.188318,6.994868"
}, {
  "Brand": "TOTAL",
  "Street": "BRUEELER CH. 12",
  "PostCode": 19406,
  "City": "STERNBERG",
  "Latitude": "53.708.883",
  "Longitude": "11.806.677",
  "Telephone": "03847-2348",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.708883,11.806677",
  "id": "53.708883,11.806677"
}, {
  "Brand": "OMV",
  "Street": "Erlanger Str. 3",
  "PostCode": 90425,
  "City": "Nürnberg",
  "Latitude": "49.469.950",
  "Longitude": "11.067.200",
  "Telephone": "+49-911-341430",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.46995,11.0672",
  "id": "49.46995,11.0672"
}, {
  "Brand": "TOTAL",
  "Street": "SCHWANEBECKER CHAUSSEE 29",
  "PostCode": 16321,
  "City": "BERNAU",
  "Latitude": "52.668.060",
  "Longitude": "13.582.340",
  "Telephone": "03338-5500",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.66806,13.58234",
  "id": "52.66806,13.58234"
}, {
  "Brand": "Westfalen",
  "Street": "Schulstrasse 32",
  "PostCode": 59192,
  "City": "BERGKAMEN",
  "Latitude": "51.606.000",
  "Longitude": "07.618.780",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.606,7.61878",
  "id": "51.606,7.61878"
}, {
  "Brand": "TOTAL",
  "Street": "DACHSBACHER STR. 1",
  "PostCode": 91466,
  "City": "GERHARDSHOFEN",
  "Latitude": "49.632.327",
  "Longitude": "10.692.724",
  "Telephone": "09163997290",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.632327,10.692724",
  "id": "49.632327,10.692724"
}, {
  "Brand": "ARAL",
  "Street": "Nußdorfer Straße 101",
  "PostCode": 88662,
  "City": "Überlingen",
  "Latitude": "47.754.091",
  "Longitude": "09.190.197",
  "Telephone": "075513550",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.754091,9.190197",
  "id": "47.754091,9.190197"
}, {
  "Brand": "OMV",
  "Street": "Siemensstr. 19",
  "PostCode": 84030,
  "City": "Landshut",
  "Latitude": "48.556.880",
  "Longitude": "12.153.490",
  "Telephone": "+49-871-140007",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.55688,12.15349",
  "id": "48.55688,12.15349"
}, {
  "Brand": "TOTAL",
  "Street": "AUTOHOF BAB 10 /B1",
  "PostCode": 14542,
  "City": "PLOETZIN",
  "Latitude": "52.390.854",
  "Longitude": "12.844.964",
  "Telephone": "03327-730313",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.390854,12.844964",
  "id": "52.390854,12.844964"
}, {
  "Brand": "TOTAL",
  "Street": "ERICH-SCHLESINGER-STR.26",
  "PostCode": 18059,
  "City": "ROSTOCK",
  "Latitude": "54.076.621",
  "Longitude": "12.119.421",
  "Telephone": "0381-442084",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.076621,12.119421",
  "id": "54.076621,12.119421"
}, {
  "Brand": "TOTAL",
  "Street": "HALBERSTAEDTER STR.21 B",
  "PostCode": 39435,
  "City": "EGELN",
  "Latitude": "51.940.555",
  "Longitude": "11.418.087",
  "Telephone": "039268-2235",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.940555,11.418087",
  "id": "51.940555,11.418087"
}, {
  "Brand": "TOTAL",
  "Street": "HAUPTSTR.149",
  "PostCode": 47877,
  "City": "WILLICH",
  "Latitude": "51.257.070",
  "Longitude": "06.486.893",
  "Telephone": "02156-60714",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.25707,6.486893",
  "id": "51.25707,6.486893"
}, {
  "Brand": "ARAL",
  "Street": "A29",
  "PostCode": 26203,
  "City": "Wardenburg",
  "Latitude": "53.040.952",
  "Longitude": "08.220.862",
  "Telephone": "0440791640",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.040952,8.220862",
  "id": "53.040952,8.220862"
}, {
  "Brand": "TOTAL",
  "Street": "CHEMNITZER STR. 38 B",
  "PostCode": 9232,
  "City": "HARTMANNSDORF",
  "Latitude": "50.878.548",
  "Longitude": "12.821.760",
  "Telephone": "03722-96464",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.878548,12.82176",
  "id": "50.878548,12.82176"
}, {
  "Brand": "ENI",
  "Street": "Bahnhofsallee",
  "PostCode": 3253,
  "City": "Doberlug-Kirchhain",
  "Latitude": "51.613.890",
  "Longitude": "13.556.390",
  "Telephone": "+49 (0) 35322451",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.61389,13.55639",
  "id": "51.61389,13.55639"
}, {
  "Brand": "TOTAL",
  "Street": "HASTENERSTR. 33",
  "PostCode": 42855,
  "City": "REMSCHEID",
  "Latitude": "51.191.450",
  "Longitude": "07.170.787",
  "Telephone": "02191-81588",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.19145,7.170787",
  "id": "51.19145,7.170787"
}, {
  "Brand": "TOTAL",
  "Street": "TALSTR. 60",
  "PostCode": 98544,
  "City": "ZELLA-MEHLIS",
  "Latitude": "50.654.190",
  "Longitude": "10.660.536",
  "Telephone": "03682-486011",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.65419,10.660536",
  "id": "50.65419,10.660536"
}, {
  "Brand": "ARAL",
  "Street": "Ulmer Straße 13",
  "PostCode": 89340,
  "City": "Leipheim",
  "Latitude": "48.445.883",
  "Longitude": "10.212.540",
  "Telephone": "0822171065",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "48.445883,10.21254",
  "id": "48.445883,10.21254"
}, {
  "Brand": "ARAL",
  "Street": "Raiffeisenstraße 17",
  "PostCode": 56594,
  "City": "Willroth",
  "Latitude": "50.565.968",
  "Longitude": "07.526.535",
  "Telephone": "02687929100",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.565968,7.526535",
  "id": "50.565968,7.526535"
}, {
  "Brand": "TOTAL",
  "Street": "BAB OST A 67",
  "PostCode": 64653,
  "City": "LORSCH",
  "Latitude": "49.642.589",
  "Longitude": "08.553.434",
  "Telephone": "06251-175180",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.642589,8.553434",
  "id": "49.642589,8.553434"
}, {
  "Brand": "ARAL",
  "Street": "Harburger Straße 39",
  "PostCode": 27383,
  "City": "Scheeßel",
  "Latitude": "53.175.610",
  "Longitude": "09.493.340",
  "Telephone": "",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "05:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.17561,9.49334",
  "id": "53.17561,9.49334"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER ALLEE 2a",
  "PostCode": 14662,
  "City": "FRIESACK",
  "Latitude": "52.729.204",
  "Longitude": "12.583.189",
  "Telephone": "033235-1592",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.729204,12.583189",
  "id": "52.729204,12.583189"
}, {
  "Brand": "Westfalen",
  "Street": "Hauptstrasse 1 a",
  "PostCode": 34590,
  "City": "WABERN",
  "Latitude": "51.082.400",
  "Longitude": "09.374.240",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.0824,9.37424",
  "id": "51.0824,9.37424"
}, {
  "Brand": "TOTAL",
  "Street": "GUNDELFINGER STR. 27",
  "PostCode": 79108,
  "City": "FREIBURG",
  "Latitude": "48.030.304",
  "Longitude": "07.862.799",
  "Telephone": "0761-552674",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.030304,7.862799",
  "id": "48.030304,7.862799"
}, {
  "Brand": "TOTAL",
  "Street": "NUERTINGER STR. 64",
  "PostCode": 72555,
  "City": "METZINGEN",
  "Latitude": "48.543.652",
  "Longitude": "09.290.245",
  "Telephone": "07123-200830",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.543652,9.290245",
  "id": "48.543652,9.290245"
}, {
  "Brand": "TOTAL",
  "Street": "EICKELER BRUCH 143",
  "PostCode": 44652,
  "City": "HERNE",
  "Latitude": "51.522.241",
  "Longitude": "07.159.934",
  "Telephone": "02325-33436",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.522241,7.159934",
  "id": "51.522241,7.159934"
}, {
  "Brand": "Westfalen",
  "Street": "Koelner Landstr. 417",
  "PostCode": 52351,
  "City": "DUEREN",
  "Latitude": "50.813.700",
  "Longitude": "06.517.020",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.8137,6.51702",
  "id": "50.8137,6.51702"
}, {
  "Brand": "Westfalen",
  "Street": "Dollersweg 103",
  "PostCode": 44319,
  "City": "DORTMUND",
  "Latitude": "51.537.600",
  "Longitude": "07.627.270",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.5376,7.62727",
  "id": "51.5376,7.62727"
}, {
  "Brand": "ENI",
  "Street": "Flurteil Eule 2",
  "PostCode": 4680,
  "City": "Colditz",
  "Latitude": "51.137.269",
  "Longitude": "12.791.511",
  "Telephone": "+49 (0) 34381431",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.137269,12.791511",
  "id": "51.137269,12.791511"
}, {
  "Brand": "ENI",
  "Street": "Blumberger Chaussee",
  "PostCode": 16356,
  "City": "Ahrensfelde",
  "Latitude": "52.584.158",
  "Longitude": "13.588.933",
  "Telephone": "+49 (0) 30937982",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "52.584158,13.588933",
  "id": "52.584158,13.588933"
}, {
  "Brand": "ENI",
  "Street": "Obere Hauptstr. 5",
  "PostCode": 85084,
  "City": "Reichertshofen/Wind.",
  "Latitude": "48.645.147",
  "Longitude": "11.513.953",
  "Telephone": "+49 (0) 84533306",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.645147,11.513953",
  "id": "48.645147,11.513953"
}, {
  "Brand": "TOTAL",
  "Street": "CARL-BENZ-STR. 3",
  "PostCode": 36251,
  "City": "BAD HERSFELD",
  "Latitude": "50.858.249",
  "Longitude": "09.722.719",
  "Telephone": "06621-919744",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.858249,9.722719",
  "id": "50.858249,9.722719"
}, {
  "Brand": "OMV",
  "Street": "Ludwigstr. 54",
  "PostCode": 85399,
  "City": "Hallbergmoos",
  "Latitude": "48.333.720",
  "Longitude": "11.731.430",
  "Telephone": "+49-811-9999889",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.33372,11.73143",
  "id": "48.33372,11.73143"
}, {
  "Brand": "Westfalen",
  "Street": "Bachackerweg 1",
  "PostCode": 45772,
  "City": "MARL",
  "Latitude": "51.692.200",
  "Longitude": "07.118.650",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.6922,7.11865",
  "id": "51.6922,7.11865"
}, {
  "Brand": "ARAL",
  "Street": "Riedlinger Straße 67",
  "PostCode": 88422,
  "City": "Bad Buchau",
  "Latitude": "48.059.896",
  "Longitude": "09.594.575",
  "Telephone": "0758291015",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:30",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.059896,9.594575",
  "id": "48.059896,9.594575"
}, {
  "Brand": "TOTAL",
  "Street": "HOLZMARKT 49",
  "PostCode": 50676,
  "City": "KOELN",
  "Latitude": "50.931.279",
  "Longitude": "06.962.356",
  "Telephone": "0221-218144",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.931279,6.962356",
  "id": "50.931279,6.962356"
}, {
  "Brand": "ARAL",
  "Street": "Ludwig-Erhard-Straße 25",
  "PostCode": 28197,
  "City": "Bremen",
  "Latitude": "53.087.631",
  "Longitude": "08.712.169",
  "Telephone": "0421-529580",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.087631,8.712169",
  "id": "53.087631,8.712169"
}, {
  "Brand": "TOTAL",
  "Street": "SCHEFFELSTR. 13",
  "PostCode": 78073,
  "City": "BAD DUERRHEIM",
  "Latitude": "48.021.233",
  "Longitude": "08.527.893",
  "Telephone": "07726-7545",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.021233,8.527893",
  "id": "48.021233,8.527893"
}, {
  "Brand": "TOTAL",
  "Street": "KOEPPELSDORFER STR. 69",
  "PostCode": 96515,
  "City": "SONNEBERG",
  "Latitude": "50.356.192",
  "Longitude": "11.180.036",
  "Telephone": "03675-427258",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.356192,11.180036",
  "id": "50.356192,11.180036"
}, {
  "Brand": "TOTAL",
  "Street": "VENNHAUSER ALLEE 266",
  "PostCode": 40627,
  "City": "DUESSELDORF",
  "Latitude": "51.214.427",
  "Longitude": "06.864.853",
  "Telephone": "0211-279666",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.214427,6.864853",
  "id": "51.214427,6.864853"
}, {
  "Brand": "TOTAL",
  "Street": "PLOENER STR. 2",
  "PostCode": 19395,
  "City": "PLAU AM SEE",
  "Latitude": "53.470.368",
  "Longitude": "12.264.311",
  "Telephone": "038735-40270",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.470368,12.264311",
  "id": "53.470368,12.264311"
}, {
  "Brand": "TOTAL",
  "Street": "TAUBENSTR. 40",
  "PostCode": 48282,
  "City": "EMSDETTEN",
  "Latitude": "52.164.898",
  "Longitude": "07.509.440",
  "Telephone": "02572-8771733",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.164898,7.50944",
  "id": "52.164898,7.50944"
}, {
  "Brand": "ARAL",
  "Street": "Münchener Straße 400",
  "PostCode": 90471,
  "City": "Nürnberg",
  "Latitude": "49.402.082",
  "Longitude": "11.114.833",
  "Telephone": "0911805503",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.402082,11.114833",
  "id": "49.402082,11.114833"
}, {
  "Brand": "TOTAL",
  "Street": "HANNOVERSCHE STR. 29",
  "PostCode": 30916,
  "City": "ISERNHAGEN",
  "Latitude": "52.434.059",
  "Longitude": "09.863.619",
  "Telephone": "0511-612900",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.434059,9.863619",
  "id": "52.434059,9.863619"
}, {
  "Brand": "ARAL",
  "Street": "Kientenstraße 6",
  "PostCode": 72458,
  "City": "Albstadt-Ebingen",
  "Latitude": "48.209.977",
  "Longitude": "09.018.705",
  "Telephone": "0743158816",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "00:00",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "48.209977,9.018705",
  "id": "48.209977,9.018705"
}, {
  "Brand": "TOTAL",
  "Street": "SAARLANDSTR. 40-42",
  "PostCode": 16515,
  "City": "ORANIENBURG",
  "Latitude": "52.738.611",
  "Longitude": "13.248.611",
  "Telephone": "03301-3243",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.738611,13.248611",
  "id": "52.738611,13.248611"
}, {
  "Brand": "TOTAL",
  "Street": "OESTINGHAUSER STR.14",
  "PostCode": 59494,
  "City": "SOEST",
  "Latitude": "51.580.547",
  "Longitude": "08.111.727",
  "Telephone": "02921-80663",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.580547,8.111727",
  "id": "51.580547,8.111727"
}, {
  "Brand": "ARAL",
  "Street": "Evinger Straße 156",
  "PostCode": 44339,
  "City": "Dortmund",
  "Latitude": "51.546.277",
  "Longitude": "07.460.379",
  "Telephone": "02318091592",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.546277,7.460379",
  "id": "51.546277,7.460379"
}, {
  "Brand": "TOTAL",
  "Street": "BAHNHOFSTR. 36",
  "PostCode": 27404,
  "City": "ZEVEN",
  "Latitude": "53.287.222",
  "Longitude": "09.276.944",
  "Telephone": "04281-80380",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.287222,9.276944",
  "id": "53.287222,9.276944"
}, {
  "Brand": "TOTAL",
  "Street": "BIEFANGSTR. 92",
  "PostCode": 46149,
  "City": "OBERHAUSEN",
  "Latitude": "51.513.599",
  "Longitude": "06.827.426",
  "Telephone": "0208-669830",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.513599,6.827426",
  "id": "51.513599,6.827426"
}, {
  "Brand": "TOTAL",
  "Street": "IM HAG 2-4",
  "PostCode": 73230,
  "City": "KIRCHHEIM/TECK",
  "Latitude": "48.642.172",
  "Longitude": "09.433.691",
  "Telephone": "07021-863650",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.642172,9.433691",
  "id": "48.642172,9.433691"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 51",
  "PostCode": 86470,
  "City": "Thannhausen",
  "Latitude": "48.286.152",
  "Longitude": "10.467.677",
  "Telephone": "082814763",
  "WeekDayFrom": "06:45",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.286152,10.467677",
  "id": "48.286152,10.467677"
}, {
  "Brand": "TOTAL",
  "Street": "DUESSELDORFER STR. 66",
  "PostCode": 40721,
  "City": "HILDEN",
  "Latitude": "51.164.699",
  "Longitude": "06.917.088",
  "Telephone": "02103-55579",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.164699,6.917088",
  "id": "51.164699,6.917088"
}, {
  "Brand": "TOTAL",
  "Street": "PAXTONSTR. 2",
  "PostCode": 48488,
  "City": "EMSBUEREN",
  "Latitude": "52.358.815",
  "Longitude": "07.261.105",
  "Telephone": "05903-9321345",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.358815,7.261105",
  "id": "52.358815,7.261105"
}, {
  "Brand": "TOTAL",
  "Street": "1 PULVERHAUSWEG",
  "PostCode": 99734,
  "City": "NORDHAUSEN",
  "Latitude": "51.494.437",
  "Longitude": "10.814.670",
  "Telephone": "03631-601162",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.494437,10.81467",
  "id": "51.494437,10.81467"
}, {
  "Brand": "OMV",
  "Street": "Gewerbehof 11",
  "PostCode": 84130,
  "City": "Dingolfing",
  "Latitude": "48.645.230",
  "Longitude": "12.484.350",
  "Telephone": "+49-8731-73034",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.64523,12.48435",
  "id": "48.64523,12.48435"
}, {
  "Brand": "TOTAL",
  "Street": "HEERSTR. 324",
  "PostCode": 13593,
  "City": "BERLIN",
  "Latitude": "52.516.812",
  "Longitude": "13.180.058",
  "Telephone": "030-30124300",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.516812,13.180058",
  "id": "52.516812,13.180058"
}, {
  "Brand": "ARAL",
  "Street": "Max-Holder-Straße 2",
  "PostCode": 60437,
  "City": "Frankfurt",
  "Latitude": "50.189.206",
  "Longitude": "08.651.428",
  "Telephone": "06995091947",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.189206,8.651428",
  "id": "50.189206,8.651428"
}, {
  "Brand": "OMV",
  "Street": "Autobahn Süd A8",
  "PostCode": 73344,
  "City": "Gruibingen",
  "Latitude": "48.607.420",
  "Longitude": "09.630.810",
  "Telephone": "+49-7335-1849865",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.60742,9.63081",
  "id": "48.60742,9.63081"
}, {
  "Brand": "Westfalen",
  "Street": "Attenser Allee 141",
  "PostCode": 26954,
  "City": "NORDENHAM",
  "Latitude": "53.502.600",
  "Longitude": "08.477.920",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.5026,8.47792",
  "id": "53.5026,8.47792"
}, {
  "Brand": "OMV",
  "Street": "Pockinger Str. 19",
  "PostCode": 94072,
  "City": "Bad Füssing",
  "Latitude": "48.361.660",
  "Longitude": "13.311.810",
  "Telephone": "+49-8531-981304",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.36166,13.31181",
  "id": "48.36166,13.31181"
}, {
  "Brand": "ENI",
  "Street": "Freiburger Str. 17B",
  "PostCode": 79215,
  "City": "Elzach",
  "Latitude": "48.167.067",
  "Longitude": "08.059.317",
  "Telephone": "+49 (0) 76827766",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.167067,8.059317",
  "id": "48.167067,8.059317"
}, {
  "Brand": "Westfalen",
  "Street": "Halterner Str. 61",
  "PostCode": 48249,
  "City": "DUELMEN",
  "Latitude": "51.825.500",
  "Longitude": "07.272.790",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8255,7.27279",
  "id": "51.8255,7.27279"
}, {
  "Brand": "ENI",
  "Street": "Neurieder Strasse 18",
  "PostCode": 81475,
  "City": "Muenchen",
  "Latitude": "48.089.140",
  "Longitude": "11.474.390",
  "Telephone": "+49 (0) 89751333",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.08914,11.47439",
  "id": "48.08914,11.47439"
}, {
  "Brand": "ARAL",
  "Street": "Augsburger Straße 38",
  "PostCode": 86899,
  "City": "Landsberg",
  "Latitude": "48.059.029",
  "Longitude": "10.868.068",
  "Telephone": "0819159655",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Ja",
  "location": "48.059029,10.868068",
  "id": "48.059029,10.868068"
}, {
  "Brand": "TOTAL",
  "Street": "HAUPTSTR. 180",
  "PostCode": 69168,
  "City": "WIESLOCH",
  "Latitude": "49.283.479",
  "Longitude": "08.686.314",
  "Telephone": "06222-50637",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.283479,8.686314",
  "id": "49.283479,8.686314"
}, {
  "Brand": "ENI",
  "Street": "Hirsauer Strasse 216",
  "PostCode": 75180,
  "City": "Pforzheim",
  "Latitude": "48.871.520",
  "Longitude": "08.678.590",
  "Telephone": "+49 (0) 72317400",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.87152,8.67859",
  "id": "48.87152,8.67859"
}, {
  "Brand": "ARAL",
  "Street": "Neuenkirchener Straße  44",
  "PostCode": 48431,
  "City": "Rheine",
  "Latitude": "52.279.282",
  "Longitude": "07.429.330",
  "Telephone": "059713626",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "52.279282,7.42933",
  "id": "52.279282,7.42933"
}, {
  "Brand": "TOTAL",
  "Street": "TAUNUSSTR. 1",
  "PostCode": 63811,
  "City": "STOCKSTADT",
  "Latitude": "49.960.854",
  "Longitude": "09.080.667",
  "Telephone": "060279795656",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.960854,9.080667",
  "id": "49.960854,9.080667"
}, {
  "Brand": "ARAL",
  "Street": "A 39 Salzgitterhüttenblick Süd",
  "PostCode": 38239,
  "City": "Salzgitter",
  "Latitude": "52.188.897",
  "Longitude": "10.396.215",
  "Telephone": "053009338093",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.188897,10.396215",
  "id": "52.188897,10.396215"
}, {
  "Brand": "Westfalen",
  "Street": "Bernard-Krone-Str. 1",
  "PostCode": 48480,
  "City": "SPELLE",
  "Latitude": "52.366.000",
  "Longitude": "07.455.580",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.366,7.45558",
  "id": "52.366,7.45558"
}, {
  "Brand": "ENI",
  "Street": "Olvenstedter Chaussee 22",
  "PostCode": 39130,
  "City": "Magdeburg",
  "Latitude": "52.152.760",
  "Longitude": "11.573.650",
  "Telephone": "+49 (0) 39172147",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "52.15276,11.57365",
  "id": "52.15276,11.57365"
}, {
  "Brand": "ENI",
  "Street": "Clemensstrasse 12",
  "PostCode": 56218,
  "City": "Muelheim-Kaerlich",
  "Latitude": "50.390.130",
  "Longitude": "07.486.850",
  "Telephone": "+49 (0) 26301817",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.39013,7.48685",
  "id": "50.39013,7.48685"
}, {
  "Brand": "OMV",
  "Street": "Gottmannshofer Str. 9",
  "PostCode": 86637,
  "City": "Wertingen",
  "Latitude": "48.556.530",
  "Longitude": "10.690.810",
  "Telephone": "+49-8272-5100",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.55653,10.69081",
  "id": "48.55653,10.69081"
}, {
  "Brand": "ENI",
  "Street": "Illinger Eck B 10/B 35",
  "PostCode": 75428,
  "City": "Illingen",
  "Latitude": "48.953.287",
  "Longitude": "08.912.651",
  "Telephone": "+49 (0) 70422050",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.953287,8.912651",
  "id": "48.953287,8.912651"
}, {
  "Brand": "OMV",
  "Street": "Kaiser- Karl- V.- Allee 60",
  "PostCode": 93077,
  "City": "Bad Abbach",
  "Latitude": "48.930.710",
  "Longitude": "12.032.450",
  "Telephone": "+49-9405-1398",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.93071,12.03245",
  "id": "48.93071,12.03245"
}, {
  "Brand": "Westfalen",
  "Street": "Reher Weg 36 a",
  "PostCode": 31855,
  "City": "AERZEN",
  "Latitude": "52.042.900",
  "Longitude": "09.258.200",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0429,9.2582",
  "id": "52.0429,9.2582"
}, {
  "Brand": "ARAL",
  "Street": "Am halben Weg 2",
  "PostCode": 91301,
  "City": "Forchheim",
  "Latitude": "49.698.383",
  "Longitude": "11.064.975",
  "Telephone": "091916219620",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.698383,11.064975",
  "id": "49.698383,11.064975"
}, {
  "Brand": "ARAL",
  "Street": "Eugen-Sänger-Ring 1",
  "PostCode": 85649,
  "City": "Brunnthal",
  "Latitude": "48.043.035",
  "Longitude": "11.664.318",
  "Telephone": "08960807989",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Ja",
  "location": "48.043035,11.664318",
  "id": "48.043035,11.664318"
}, {
  "Brand": "ENI",
  "Street": "Kissinger Strasse 35",
  "PostCode": 97769,
  "City": "Bad Brueckenau",
  "Latitude": "50.311.874",
  "Longitude": "09.793.832",
  "Telephone": "+49 (0) 97412020",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.311874,9.793832",
  "id": "50.311874,9.793832"
}, {
  "Brand": "ARAL",
  "Street": "A8",
  "PostCode": 86368,
  "City": "Gersthofen-Edenberge",
  "Latitude": "48.424.983",
  "Longitude": "10.808.122",
  "Telephone": "082148685416",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.424983,10.808122",
  "id": "48.424983,10.808122"
}, {
  "Brand": "ENI",
  "Street": "Kriegsbergstrasse 55 A",
  "PostCode": 70174,
  "City": "Stuttgart",
  "Latitude": "48.782.290",
  "Longitude": "09.171.490",
  "Telephone": "+49 (0) 71129502",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.78229,9.17149",
  "id": "48.78229,9.17149"
}, {
  "Brand": "ENI",
  "Street": "Ittlinger Str. 226",
  "PostCode": 94315,
  "City": "Straubing",
  "Latitude": "48.884.130",
  "Longitude": "12.593.160",
  "Telephone": "+49 (0) 94216113",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.88413,12.59316",
  "id": "48.88413,12.59316"
}, {
  "Brand": "OMV",
  "Street": "Maitz 3",
  "PostCode": 83607,
  "City": "Holzkirchen",
  "Latitude": "47.888.550",
  "Longitude": "11.684.850",
  "Telephone": "+49-8024-993463",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "47.88855,11.68485",
  "id": "47.88855,11.68485"
}, {
  "Brand": "ENI",
  "Street": "Stettiner Chaussee",
  "PostCode": 17309,
  "City": "Pasewalk",
  "Latitude": "53.502.877",
  "Longitude": "14.003.240",
  "Telephone": "+49 (0) 39732101",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "53.502877,14.00324",
  "id": "53.502877,14.00324"
}, {
  "Brand": "ENI",
  "Street": "Dannenwalder Weg 69",
  "PostCode": 13439,
  "City": "Berlin",
  "Latitude": "52.591.691",
  "Longitude": "13.346.441",
  "Telephone": "+49 (0) 30416520",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.591691,13.346441",
  "id": "52.591691,13.346441"
}, {
  "Brand": "OMV",
  "Street": "Wilhelmstr. 57",
  "PostCode": 73433,
  "City": "Aalen",
  "Latitude": "48.861.110",
  "Longitude": "10.102.770",
  "Telephone": "+49-7361-71268",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.86111,10.10277",
  "id": "48.86111,10.10277"
}, {
  "Brand": "ENI",
  "Street": "Seeonerstrasse 1",
  "PostCode": 83119,
  "City": "Obing",
  "Latitude": "47.999.776",
  "Longitude": "12.410.161",
  "Telephone": "+49 (0) 86241450",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.999776,12.410161",
  "id": "47.999776,12.410161"
}, {
  "Brand": "TOTAL",
  "Street": "LANDSBERGER ALLEE 526",
  "PostCode": 12681,
  "City": "BERLIN",
  "Latitude": "52.544.860",
  "Longitude": "13.569.022",
  "Telephone": "030-54376273/74",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.54486,13.569022",
  "id": "52.54486,13.569022"
}, {
  "Brand": "ARAL",
  "Street": "Günzburger Straße 63",
  "PostCode": 89423,
  "City": "Gundelfingen",
  "Latitude": "48.543.905",
  "Longitude": "10.367.534",
  "Telephone": "09073800590",
  "WeekDayFrom": "06:45",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.543905,10.367534",
  "id": "48.543905,10.367534"
}, {
  "Brand": "TOTAL",
  "Street": "HICKLSTR. 2",
  "PostCode": 85764,
  "City": "OBERSCHLEISSHEIM",
  "Latitude": "48.265.174",
  "Longitude": "11.593.216",
  "Telephone": "089-21894466",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.265174,11.593216",
  "id": "48.265174,11.593216"
}, {
  "Brand": "TOTAL",
  "Street": "MANCHINGER STR. 84",
  "PostCode": 85053,
  "City": "INGOLSTADT",
  "Latitude": "48.752.958",
  "Longitude": "11.451.818",
  "Telephone": "0841/99389815",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.752958,11.451818",
  "id": "48.752958,11.451818"
}, {
  "Brand": "TOTAL",
  "Street": "DUERENER STR. 168-172",
  "PostCode": 52249,
  "City": "ESCHWEILER",
  "Latitude": "50.818.873",
  "Longitude": "06.282.095",
  "Telephone": "02403-4600",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.818873,6.282095",
  "id": "50.818873,6.282095"
}, {
  "Brand": "Westfalen",
  "Street": "Rekener Str. 100",
  "PostCode": 48249,
  "City": "DUELMEN",
  "Latitude": "51.848.400",
  "Longitude": "07.193.560",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8484,7.19356",
  "id": "51.8484,7.19356"
}, {
  "Brand": "TOTAL",
  "Street": "BAB- WUNNENSTEIN OST/A 81",
  "PostCode": 74360,
  "City": "ILSFELD",
  "Latitude": "49.043.009",
  "Longitude": "09.262.472",
  "Telephone": "07062-4170",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.043009,9.262472",
  "id": "49.043009,9.262472"
}, {
  "Brand": "ARAL",
  "Street": "Altenberger Straße 93",
  "PostCode": 42929,
  "City": "Wermelskirchen",
  "Latitude": "51.091.155",
  "Longitude": "07.186.871",
  "Telephone": "021934691",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "10:00",
  "SundayTo": "18:00",
  "IsOpen24Hours": "Nein",
  "location": "51.091155,7.186871",
  "id": "51.091155,7.186871"
}, {
  "Brand": "Westfalen",
  "Street": "Industriestrasse 1",
  "PostCode": 46354,
  "City": "SUEDLOHN",
  "Latitude": "51.935.400",
  "Longitude": "06.820.160",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9354,6.82016",
  "id": "51.9354,6.82016"
}, {
  "Brand": "OMV",
  "Street": "Hindenburgstr. 52",
  "PostCode": 76297,
  "City": "Stutensee",
  "Latitude": "49.103.810",
  "Longitude": "08.481.810",
  "Telephone": "+49-7249-3460",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.10381,8.48181",
  "id": "49.10381,8.48181"
}, {
  "Brand": "Westfalen",
  "Street": "Laurenzstr. 108",
  "PostCode": 48607,
  "City": "OCHTRUP",
  "Latitude": "52.206.700",
  "Longitude": "07.207.700",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.2067,7.2077",
  "id": "52.2067,7.2077"
}, {
  "Brand": "OMV",
  "Street": "Würzburger Str. 21 a",
  "PostCode": 97292,
  "City": "Üttingen",
  "Latitude": "49.796.670",
  "Longitude": "09.735.470",
  "Telephone": "+49-9369-1843",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.79667,9.73547",
  "id": "49.79667,9.73547"
}, {
  "Brand": "Westfalen",
  "Street": "Heerstr. 166",
  "PostCode": 53474,
  "City": "BAD NEUENAHR",
  "Latitude": "50.549.300",
  "Longitude": "07.130.800",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.5493,7.1308",
  "id": "50.5493,7.1308"
}, {
  "Brand": "OMV",
  "Street": "Bahnhofstr. 43",
  "PostCode": 97346,
  "City": "Iphofen",
  "Latitude": "49.698.770",
  "Longitude": "10.259.950",
  "Telephone": "+49-9323-3377",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.69877,10.25995",
  "id": "49.69877,10.25995"
}, {
  "Brand": "ARAL",
  "Street": "Oberwerrner Weg 10",
  "PostCode": 97502,
  "City": "Euerbach",
  "Latitude": "50.062.846",
  "Longitude": "10.144.527",
  "Telephone": "09726/9052785",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.062846,10.144527",
  "id": "50.062846,10.144527"
}, {
  "Brand": "ARAL",
  "Street": "Zeppelinstraße 14 - 16",
  "PostCode": 88239,
  "City": "Wangen",
  "Latitude": "47.690.181",
  "Longitude": "09.828.876",
  "Telephone": "0",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "14:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "47.690181,9.828876",
  "id": "47.690181,9.828876"
}, {
  "Brand": "TOTAL",
  "Street": "AM DORN 5-7",
  "PostCode": 48308,
  "City": "SENDEN",
  "Latitude": "51.904.118",
  "Longitude": "07.488.412",
  "Telephone": "02536-345620",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.904118,7.488412",
  "id": "51.904118,7.488412"
}, {
  "Brand": "TOTAL",
  "Street": "AUTOHOF AM ESCHENBACH 1",
  "PostCode": 97440,
  "City": "WERNECK",
  "Latitude": "50.003.346",
  "Longitude": "10.117.968",
  "Telephone": "09722-94620",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.003346,10.117968",
  "id": "50.003346,10.117968"
}, {
  "Brand": "ARAL",
  "Street": "Bernburger Str. 4a",
  "PostCode": 39439,
  "City": "Güsten",
  "Latitude": "51.801.557",
  "Longitude": "11.620.835",
  "Telephone": "03926260005",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.801557,11.620835",
  "id": "51.801557,11.620835"
}, {
  "Brand": "ENI",
  "Street": "Nuernberger Strasse 51",
  "PostCode": 91781,
  "City": "Weissenburg",
  "Latitude": "49.034.060",
  "Longitude": "10.971.858",
  "Telephone": "+49 (0) 91418737",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.03406,10.971858",
  "id": "49.03406,10.971858"
}, {
  "Brand": "ARAL",
  "Street": "Ludwig-Erhard-Straße 25",
  "PostCode": 28197,
  "City": "Bremen",
  "Latitude": "53.087.439",
  "Longitude": "08.712.351",
  "Telephone": "0421529580",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "18:00",
  "SundayFrom": "12:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "53.087439,8.712351",
  "id": "53.087439,8.712351"
}, {
  "Brand": "OMV",
  "Street": "Florianstraße 31",
  "PostCode": 85551,
  "City": "Kirchheim b. München",
  "Latitude": "48.175.620",
  "Longitude": "11.764.290",
  "Telephone": "+49-89-90476622",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.17562,11.76429",
  "id": "48.17562,11.76429"
}, {
  "Brand": "ARAL",
  "Street": "A5",
  "PostCode": 61239,
  "City": "Ober-Mörlen",
  "Latitude": "50.355.660",
  "Longitude": "08.694.560",
  "Telephone": "060027915",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.35566,8.69456",
  "id": "50.35566,8.69456"
}, {
  "Brand": "ARAL",
  "Street": "Rhein-Taunus-Straße 52",
  "PostCode": 56340,
  "City": "Dachsenhausen",
  "Latitude": "50.253.551",
  "Longitude": "07.733.114",
  "Telephone": "067761356",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.253551,7.733114",
  "id": "50.253551,7.733114"
}, {
  "Brand": "TOTAL",
  "Street": "SIEMENSSTR. 2",
  "PostCode": 64289,
  "City": "DARMSTADT",
  "Latitude": "49.896.099",
  "Longitude": "08.680.580",
  "Telephone": "06151-4929866",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.896099,8.68058",
  "id": "49.896099,8.68058"
}, {
  "Brand": "Westfalen",
  "Street": "Westfalenstr. 124-126",
  "PostCode": 48165,
  "City": "MUENSTER",
  "Latitude": "51.907.100",
  "Longitude": "07.633.650",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9071,7.63365",
  "id": "51.9071,7.63365"
}, {
  "Brand": "ENI",
  "Street": "Cosimastrasse 234",
  "PostCode": 81927,
  "City": "Muenchen",
  "Latitude": "48.172.278",
  "Longitude": "11.636.868",
  "Telephone": "+49 (0) 89951631",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.172278,11.636868",
  "id": "48.172278,11.636868"
}, {
  "Brand": "Westfalen",
  "Street": "Stadtholzstr. 252",
  "PostCode": 32052,
  "City": "BAT HERFORD-S?",
  "Latitude": "52.101.500",
  "Longitude": "08.720.130",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.1015,8.72013",
  "id": "52.1015,8.72013"
}, {
  "Brand": "OMV",
  "Street": "Memminger Str. 60",
  "PostCode": 87439,
  "City": "Kempten",
  "Latitude": "47.734.500",
  "Longitude": "10.310.270",
  "Telephone": "+49-831-23976",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.7345,10.31027",
  "id": "47.7345,10.31027"
}, {
  "Brand": "OMV",
  "Street": "Winterbruckenweg 53",
  "PostCode": 86316,
  "City": "Derching",
  "Latitude": "48.406.930",
  "Longitude": "10.950.670",
  "Telephone": "+49-821-78094626",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.40693,10.95067",
  "id": "48.40693,10.95067"
}, {
  "Brand": "TOTAL",
  "Street": "NUERNBERGER STR. 9",
  "PostCode": 70374,
  "City": "STUTTGART",
  "Latitude": "48.806.115",
  "Longitude": "09.232.400",
  "Telephone": "0711-522550",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.806115,9.2324",
  "id": "48.806115,9.2324"
}, {
  "Brand": "TOTAL",
  "Street": "IM NEYL 30",
  "PostCode": 59823,
  "City": "ARNSBERG",
  "Latitude": "51.390.600",
  "Longitude": "08.130.287",
  "Telephone": "02937-6473",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.3906,8.130287",
  "id": "51.3906,8.130287"
}, {
  "Brand": "ARAL",
  "Street": "Saynstraße 44",
  "PostCode": 57627,
  "City": "Hachenburg",
  "Latitude": "50.667.175",
  "Longitude": "07.808.867",
  "Telephone": "02662939200",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.667175,7.808867",
  "id": "50.667175,7.808867"
}, {
  "Brand": "TOTAL",
  "Street": "BUNDESSTR.HAUS NR. 7",
  "PostCode": 52159,
  "City": "ROETGEN",
  "Latitude": "50.646.891",
  "Longitude": "06.185.342",
  "Telephone": "02471-2510",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.646891,6.185342",
  "id": "50.646891,6.185342"
}, {
  "Brand": "TOTAL",
  "Street": "IN DER WAAGE 1",
  "PostCode": 73463,
  "City": "WESTHAUSEN",
  "Latitude": "48.883.055",
  "Longitude": "10.174.955",
  "Telephone": "07363-9547097",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.883055,10.174955",
  "id": "48.883055,10.174955"
}, {
  "Brand": "ARAL",
  "Street": "Wegelnburgstraße 27",
  "PostCode": 67065,
  "City": "Ludwigshafen",
  "Latitude": "49.457.106",
  "Longitude": "08.431.821",
  "Telephone": "062157240520",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.457106,8.431821",
  "id": "49.457106,8.431821"
}, {
  "Brand": "TOTAL",
  "Street": "SENNER STR. 8",
  "PostCode": 33335,
  "City": "GUETERSLOH",
  "Latitude": "51.940.140",
  "Longitude": "08.486.962",
  "Telephone": "05209-980818",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.94014,8.486962",
  "id": "51.94014,8.486962"
}, {
  "Brand": "TOTAL",
  "Street": "ARNO-NITZSCHE STRASSE 32",
  "PostCode": 4277,
  "City": "LEIPZIG",
  "Latitude": "51.308.920",
  "Longitude": "12.389.452",
  "Telephone": "0341-3018122",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.30892,12.389452",
  "id": "51.30892,12.389452"
}, {
  "Brand": "TOTAL",
  "Street": "AUTOHOF VOGELHERD 4",
  "PostCode": 91236,
  "City": "ALFELD",
  "Latitude": "49.410.373",
  "Longitude": "11.544.802",
  "Telephone": "09157-95170",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.410373,11.544802",
  "id": "49.410373,11.544802"
}, {
  "Brand": "TOTAL",
  "Street": "SCHIMMELBUSCHSTR. 27",
  "PostCode": 40699,
  "City": "ERKRATH-HOCHDAHL",
  "Latitude": "51.215.475",
  "Longitude": "06.948.487",
  "Telephone": "02104-39604",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.215475,6.948487",
  "id": "51.215475,6.948487"
}, {
  "Brand": "TOTAL",
  "Street": "AUTOHOF AN DER AUTOBAHN K4",
  "PostCode": 91161,
  "City": "HILPOLTSTEIN",
  "Latitude": "49.160.892",
  "Longitude": "11.262.227",
  "Telephone": "09174-976666",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.160892,11.262227",
  "id": "49.160892,11.262227"
}, {
  "Brand": "ENI",
  "Street": "In der Au 1",
  "PostCode": 96224,
  "City": "Burgkunstadt",
  "Latitude": "50.137.197",
  "Longitude": "11.250.892",
  "Telephone": "+49 (0) 95721544",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.137197,11.250892",
  "id": "50.137197,11.250892"
}, {
  "Brand": "ARAL",
  "Street": "An der Autobahn 1",
  "PostCode": 95512,
  "City": "Neudrossenfeld",
  "Latitude": "50.037.347",
  "Longitude": "11.490.633",
  "Telephone": "09203688996",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.037347,11.490633",
  "id": "50.037347,11.490633"
}, {
  "Brand": "ARAL",
  "Street": "A 71",
  "PostCode": 97638,
  "City": "Mellrichstadt",
  "Latitude": "50.423.602",
  "Longitude": "10.351.844",
  "Telephone": "097767094943",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.423602,10.351844",
  "id": "50.423602,10.351844"
}, {
  "Brand": "ARAL",
  "Street": "Engesserstraße 13",
  "PostCode": 79108,
  "City": "Freiburg im Breisgau",
  "Latitude": "48.018.670",
  "Longitude": "07.842.227",
  "Telephone": "076168002380",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "48.01867,7.842227",
  "id": "48.01867,7.842227"
}, {
  "Brand": "TOTAL",
  "Street": "KOMMERNER STR. 147",
  "PostCode": 53879,
  "City": "EUSKIRCHEN",
  "Latitude": "50.656.601",
  "Longitude": "06.768.086",
  "Telephone": "02251-929103",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.656601,6.768086",
  "id": "50.656601,6.768086"
}, {
  "Brand": "TOTAL",
  "Street": "BRAUNSCHWEIGER STR. 45 - 47",
  "PostCode": 38518,
  "City": "GIFHORN",
  "Latitude": "52.476.713",
  "Longitude": "10.544.206",
  "Telephone": "05371-13613",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.476713,10.544206",
  "id": "52.476713,10.544206"
}, {
  "Brand": "TOTAL",
  "Street": "SCHUETZENSTR. 1A",
  "PostCode": 98527,
  "City": "SUHL",
  "Latitude": "50.588.090",
  "Longitude": "10.719.190",
  "Telephone": "03681-807271",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.58809,10.71919",
  "id": "50.58809,10.71919"
}, {
  "Brand": "TOTAL",
  "Street": "OSTERDEICH 124-125",
  "PostCode": 28205,
  "City": "BREMEN",
  "Latitude": "53.066.935",
  "Longitude": "08.844.334",
  "Telephone": "0421-440704",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.066935,8.844334",
  "id": "53.066935,8.844334"
}, {
  "Brand": "ARAL",
  "Street": "Wetzlarer Straße 32",
  "PostCode": 57250,
  "City": "Netphen",
  "Latitude": "50.862.037",
  "Longitude": "08.200.172",
  "Telephone": "027372185959",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "18:00",
  "SundayFrom": "09:00",
  "SundayTo": "18:00",
  "IsOpen24Hours": "Nein",
  "location": "50.862037,8.200172",
  "id": "50.862037,8.200172"
}, {
  "Brand": "TOTAL",
  "Street": "BOLTENHAGENER STR. 11b",
  "PostCode": 23948,
  "City": "KLUETZ",
  "Latitude": "53.970.639",
  "Longitude": "11.174.140",
  "Telephone": "038825-22578",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.970639,11.17414",
  "id": "53.970639,11.17414"
}, {
  "Brand": "Westfalen",
  "Street": "Herner Str. 269",
  "PostCode": 44809,
  "City": "BOCHUM",
  "Latitude": "51.502.500",
  "Longitude": "07.212.280",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.5025,7.21228",
  "id": "51.5025,7.21228"
}, {
  "Brand": "Westfalen",
  "Street": "Holsterhauser",
  "PostCode": 44625,
  "City": "HERNE",
  "Latitude": "51.531.645",
  "Longitude": "07.205.456",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.531645,7.205456",
  "id": "51.531645,7.205456"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 62",
  "PostCode": 35428,
  "City": "Langgöns-Oberkleen",
  "Latitude": "50.461.672",
  "Longitude": "08.584.646",
  "Telephone": "06447887360",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.461672,8.584646",
  "id": "50.461672,8.584646"
}, {
  "Brand": "ARAL",
  "Street": "Bamberger Straße 27",
  "PostCode": 96328,
  "City": "Küps",
  "Latitude": "50.191.616",
  "Longitude": "11.268.119",
  "Telephone": "0926480269",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "18:00",
  "SundayFrom": "09:00",
  "SundayTo": "18:00",
  "IsOpen24Hours": "Nein",
  "location": "50.191616,11.268119",
  "id": "50.191616,11.268119"
}, {
  "Brand": "ARAL",
  "Street": "Aiterhofener Straße 215",
  "PostCode": 94315,
  "City": "Straubing",
  "Latitude": "48.872.891",
  "Longitude": "12.631.675",
  "Telephone": "094211862815",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.872891,12.631675",
  "id": "48.872891,12.631675"
}, {
  "Brand": "OMV",
  "Street": "Brucker Str. 79",
  "PostCode": 85221,
  "City": "Dachau",
  "Latitude": "48.256.870",
  "Longitude": "11.419.020",
  "Telephone": "+49-8131-56027",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.25687,11.41902",
  "id": "48.25687,11.41902"
}, {
  "Brand": "ENI",
  "Street": "Killisfeldstrasse 44",
  "PostCode": 76227,
  "City": "Karlsruhe",
  "Latitude": "48.996.460",
  "Longitude": "08.454.720",
  "Telephone": "+49 (0) 72144175",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.99646,8.45472",
  "id": "48.99646,8.45472"
}, {
  "Brand": "OMV",
  "Street": "Ooser Hauptstr. 6",
  "PostCode": 76532,
  "City": "Baden-Baden",
  "Latitude": "48.788.210",
  "Longitude": "08.194.380",
  "Telephone": "+49-7221-61035",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.78821,8.19438",
  "id": "48.78821,8.19438"
}, {
  "Brand": "ENI",
  "Street": "Lindenring 5",
  "PostCode": 82024,
  "City": "Taufkirchen",
  "Latitude": "48.052.100",
  "Longitude": "11.605.750",
  "Telephone": "+49 (0) 89612171",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.0521,11.60575",
  "id": "48.0521,11.60575"
}, {
  "Brand": "ARAL",
  "Street": "Dieskaustraße 483 a",
  "PostCode": 4249,
  "City": "Leipzig",
  "Latitude": "51.272.811",
  "Longitude": "12.308.377",
  "Telephone": "03414240640",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.272811,12.308377",
  "id": "51.272811,12.308377"
}, {
  "Brand": "Westfalen",
  "Street": "Osnabruecker Str. 18",
  "PostCode": 49492,
  "City": "WESTERKAPPELN",
  "Latitude": "52.312.900",
  "Longitude": "07.881.540",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.3129,7.88154",
  "id": "52.3129,7.88154"
}, {
  "Brand": "ENI",
  "Street": "Konrad-Adenauer-Str. 71",
  "PostCode": 63322,
  "City": "Roedermark-Urberrach",
  "Latitude": "49.974.386",
  "Longitude": "08.789.351",
  "Telephone": "+49 (0) 60748618",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.974386,8.789351",
  "id": "49.974386,8.789351"
}, {
  "Brand": "ENI",
  "Street": "Peisel",
  "PostCode": 53797,
  "City": "Lohmar",
  "Latitude": "50.868.830",
  "Longitude": "07.252.440",
  "Telephone": "+49 (0) 22469126",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.86883,7.25244",
  "id": "50.86883,7.25244"
}, {
  "Brand": "TOTAL",
  "Street": "LANDSTRASSE 64",
  "PostCode": 42781,
  "City": "HAAN",
  "Latitude": "51.204.903",
  "Longitude": "07.041.496",
  "Telephone": "02129-3456929",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.204903,7.041496",
  "id": "51.204903,7.041496"
}, {
  "Brand": "TOTAL",
  "Street": "BAHNHOFSTR.60",
  "PostCode": 38486,
  "City": "KLOETZE",
  "Latitude": "52.630.039",
  "Longitude": "11.153.438",
  "Telephone": "03909-3890",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.630039,11.153438",
  "id": "52.630039,11.153438"
}, {
  "Brand": "TOTAL",
  "Street": "TIEFSTADT 10",
  "PostCode": 84307,
  "City": "EGGENFELDEN",
  "Latitude": "48.395.753",
  "Longitude": "12.766.098",
  "Telephone": "08721/508143",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.395753,12.766098",
  "id": "48.395753,12.766098"
}, {
  "Brand": "TOTAL",
  "Street": "ILMENAUER STR.2",
  "PostCode": 99326,
  "City": "STADTILM",
  "Latitude": "50.767.666",
  "Longitude": "11.064.826",
  "Telephone": "03629-3172",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.767666,11.064826",
  "id": "50.767666,11.064826"
}, {
  "Brand": "TOTAL",
  "Street": "HAUPTSTR. 72",
  "PostCode": 74869,
  "City": "SCHWARZACH",
  "Latitude": "49.366.603",
  "Longitude": "08.981.484",
  "Telephone": "06262-6294",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.366603,8.981484",
  "id": "49.366603,8.981484"
}, {
  "Brand": "TOTAL",
  "Street": "1 BOUXWILLER STRASSE",
  "PostCode": 64832,
  "City": "BABENHAUSEN",
  "Latitude": "49.969.769",
  "Longitude": "08.949.254",
  "Telephone": "06073-723376",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.969769,8.949254",
  "id": "49.969769,8.949254"
}, {
  "Brand": "TOTAL",
  "Street": "KOECKERN WEST/BAB 9",
  "PostCode": 6794,
  "City": "SANDERSDORF-BREHNA",
  "Latitude": "51.608.160",
  "Longitude": "12.184.530",
  "Telephone": "034954-39317",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.60816,12.18453",
  "id": "51.60816,12.18453"
}, {
  "Brand": "TOTAL",
  "Street": "HAUPTSTR.21",
  "PostCode": 85095,
  "City": "DENKENDORF",
  "Latitude": "48.927.990",
  "Longitude": "11.456.990",
  "Telephone": "08466-458",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.92799,11.45699",
  "id": "48.92799,11.45699"
}, {
  "Brand": "OMV",
  "Street": "Würzburger Str. 20",
  "PostCode": 97199,
  "City": "Ochsenfurt",
  "Latitude": "49.669.890",
  "Longitude": "10.058.990",
  "Telephone": "+49-9331-2514",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.66989,10.05899",
  "id": "49.66989,10.05899"
}, {
  "Brand": "ARAL",
  "Street": "Schützstraße 2",
  "PostCode": 56242,
  "City": "Selters",
  "Latitude": "50.537.626",
  "Longitude": "07.759.510",
  "Telephone": "02626921605",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:30",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.537626,7.75951",
  "id": "50.537626,7.75951"
}, {
  "Brand": "Westfalen",
  "Street": "Werler Str. 8",
  "PostCode": 59755,
  "City": "ARNSBERG",
  "Latitude": "51.454.900",
  "Longitude": "07.959.110",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.4549,7.95911",
  "id": "51.4549,7.95911"
}, {
  "Brand": "ENI",
  "Street": "Huettenfelder Str. 46",
  "PostCode": 69502,
  "City": "Hemsbach",
  "Latitude": "49.593.016",
  "Longitude": "08.639.711",
  "Telephone": "+49 (0) 62017144",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.593016,8.639711",
  "id": "49.593016,8.639711"
}, {
  "Brand": "ENI",
  "Street": "Robert-Bosch-Strasse 14",
  "PostCode": 35440,
  "City": "Linden",
  "Latitude": "50.537.630",
  "Longitude": "08.653.850",
  "Telephone": "+49 (0) 64037202",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.53763,8.65385",
  "id": "50.53763,8.65385"
}, {
  "Brand": "ARAL",
  "Street": "Hafenstr. 124",
  "PostCode": 59067,
  "City": "Hamm",
  "Latitude": "51.676.712",
  "Longitude": "07.775.468",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.676712,7.775468",
  "id": "51.676712,7.775468"
}, {
  "Brand": "TOTAL",
  "Street": "HESSENSTR. 79",
  "PostCode": 36358,
  "City": "HERBSTEIN",
  "Latitude": "50.556.520",
  "Longitude": "09.350.362",
  "Telephone": "06643-1347",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.55652,9.350362",
  "id": "50.55652,9.350362"
}, {
  "Brand": "TOTAL",
  "Street": "FRANKFURTER STR. 85",
  "PostCode": 58553,
  "City": "HALVER",
  "Latitude": "51.184.418",
  "Longitude": "07.509.619",
  "Telephone": "02353-3322",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.184418,7.509619",
  "id": "51.184418,7.509619"
}, {
  "Brand": "TOTAL",
  "Street": "BAHNSTR. 174",
  "PostCode": 41069,
  "City": "MOENCHENGLADBACH",
  "Latitude": "51.181.751",
  "Longitude": "06.411.782",
  "Telephone": "02161-592993",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.181751,6.411782",
  "id": "51.181751,6.411782"
}, {
  "Brand": "ARAL",
  "Street": "A 7",
  "PostCode": 38723,
  "City": "Seesen",
  "Latitude": "51.925.588",
  "Longitude": "10.142.902",
  "Telephone": "05384969800",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.925588,10.142902",
  "id": "51.925588,10.142902"
}, {
  "Brand": "TOTAL",
  "Street": "NEUE REIHE 94C",
  "PostCode": 18225,
  "City": "KUEHLUNGSBORN",
  "Latitude": "54.144.871",
  "Longitude": "11.735.626",
  "Telephone": "038293-7608",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.144871,11.735626",
  "id": "54.144871,11.735626"
}, {
  "Brand": "ENI",
  "Street": "Schumacherring 96",
  "PostCode": 87437,
  "City": "Kempten",
  "Latitude": "47.731.110",
  "Longitude": "10.326.340",
  "Telephone": "+49 (0) 83175510",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "47.73111,10.32634",
  "id": "47.73111,10.32634"
}, {
  "Brand": "ARAL",
  "Street": "Traunwalchner Straße 12",
  "PostCode": 83301,
  "City": "Traunreut",
  "Latitude": "47.957.043",
  "Longitude": "12.596.248",
  "Telephone": "086697897373",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.957043,12.596248",
  "id": "47.957043,12.596248"
}, {
  "Brand": "ARAL",
  "Street": "A7",
  "PostCode": 38723,
  "City": "Seesen",
  "Latitude": "51.928.528",
  "Longitude": "10.142.306",
  "Telephone": "05384969502",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.928528,10.142306",
  "id": "51.928528,10.142306"
}, {
  "Brand": "TOTAL",
  "Street": "ARTILLERIEPARK 5",
  "PostCode": 14624,
  "City": "DALLGOW",
  "Latitude": "52.533.461",
  "Longitude": "13.059.905",
  "Telephone": "03322-2137842/43",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.533461,13.059905",
  "id": "52.533461,13.059905"
}, {
  "Brand": "TOTAL",
  "Street": "VAHRENER DAMM 10",
  "PostCode": 49661,
  "City": "CLOPPENBURG",
  "Latitude": "52.827.153",
  "Longitude": "08.000.313",
  "Telephone": "04471-7012924",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.827153,8.000313",
  "id": "52.827153,8.000313"
}, {
  "Brand": "OMV",
  "Street": "Pfarrkirchener Str. 2",
  "PostCode": 94099,
  "City": "Ruhstorf",
  "Latitude": "48.432.020",
  "Longitude": "13.332.800",
  "Telephone": "+49-8531-3336",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.43202,13.3328",
  "id": "48.43202,13.3328"
}, {
  "Brand": "ARAL",
  "Street": "Bodenseestraße 317",
  "PostCode": 81249,
  "City": "München",
  "Latitude": "48.141.184",
  "Longitude": "11.413.955",
  "Telephone": "08985796463",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:45",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:45",
  "SundayFrom": "05:00",
  "SundayTo": "23:45",
  "IsOpen24Hours": "Nein",
  "location": "48.141184,11.413955",
  "id": "48.141184,11.413955"
}, {
  "Brand": "TOTAL",
  "Street": "ISERLOHNER STR.45",
  "PostCode": 59423,
  "City": "UNNA",
  "Latitude": "51.526.759",
  "Longitude": "07.694.850",
  "Telephone": "02303-80000",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.526759,7.69485",
  "id": "51.526759,7.69485"
}, {
  "Brand": "ENI",
  "Street": "Jakob-Schuele-Str. 52",
  "PostCode": 73655,
  "City": "Pluederhausen",
  "Latitude": "48.800.590",
  "Longitude": "09.592.860",
  "Telephone": "07181/82667",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.80059,9.59286",
  "id": "48.80059,9.59286"
}, {
  "Brand": "ENI",
  "Street": "Hauptstrasse 5-7",
  "PostCode": 90607,
  "City": "Rueckersdorf",
  "Latitude": "49.492.030",
  "Longitude": "11.236.760",
  "Telephone": "+49 (0) 91157938",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.49203,11.23676",
  "id": "49.49203,11.23676"
}, {
  "Brand": "OMV",
  "Street": "Hofmark-Aich-Str. 22",
  "PostCode": 84030,
  "City": "Landshut",
  "Latitude": "48.548.490",
  "Longitude": "12.148.260",
  "Telephone": "+49-871-1431757",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.54849,12.14826",
  "id": "48.54849,12.14826"
}, {
  "Brand": "TOTAL",
  "Street": "AUTOHOF AN DER BAB 61",
  "PostCode": 55444,
  "City": "WALDLAUBERSHEIM",
  "Latitude": "49.924.245",
  "Longitude": "07.824.723",
  "Telephone": "06707 242984863",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.924245,7.824723",
  "id": "49.924245,7.824723"
}, {
  "Brand": "TOTAL",
  "Street": "FRIEDERIKENHOFER STR. 1",
  "PostCode": 14979,
  "City": "GROSSBEEREN",
  "Latitude": "52.390.341",
  "Longitude": "13.340.665",
  "Telephone": "033701-365783",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.390341,13.340665",
  "id": "52.390341,13.340665"
}, {
  "Brand": "ARAL",
  "Street": "Hochstraße / Bergheimer S",
  "PostCode": 47228,
  "City": "Duisburg",
  "Latitude": "51.419.617",
  "Longitude": "06.696.159",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.419617,6.696159",
  "id": "51.419617,6.696159"
}, {
  "Brand": "ARAL",
  "Street": "Gerresheimer Landstr. 67",
  "PostCode": 40627,
  "City": "Düsseldorf",
  "Latitude": "51.201.610",
  "Longitude": "06.904.797",
  "Telephone": "0211201996",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.20161,6.904797",
  "id": "51.20161,6.904797"
}, {
  "Brand": "TOTAL",
  "Street": "WENDEWEHRSTR. 129",
  "PostCode": 99974,
  "City": "MUEHLHAUSEN",
  "Latitude": "51.216.242",
  "Longitude": "10.463.415",
  "Telephone": "03601-815176",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.216242,10.463415",
  "id": "51.216242,10.463415"
}, {
  "Brand": "TOTAL",
  "Street": "BRAMFELDER STR. 86a",
  "PostCode": 22305,
  "City": "HAMBURG",
  "Latitude": "53.587.929",
  "Longitude": "10.052.757",
  "Telephone": "040-22605444",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.587929,10.052757",
  "id": "53.587929,10.052757"
}, {
  "Brand": "ARAL",
  "Street": "Gutenbergstraße 2",
  "PostCode": 28816,
  "City": "Stuhr",
  "Latitude": "53.019.613",
  "Longitude": "08.771.348",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.019613,8.771348",
  "id": "53.019613,8.771348"
}, {
  "Brand": "ARAL",
  "Street": "A 57",
  "PostCode": 47652,
  "City": "Weeze",
  "Latitude": "51.652.277",
  "Longitude": "06.198.259",
  "Telephone": "02837664418",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.652277,6.198259",
  "id": "51.652277,6.198259"
}, {
  "Brand": "ENI",
  "Street": "Hindemithstr. 4 a",
  "PostCode": 55127,
  "City": "Mainz-Lerchenberg",
  "Latitude": "49.959.250",
  "Longitude": "08.200.130",
  "Telephone": "+49 (0) 61317152",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.95925,8.20013",
  "id": "49.95925,8.20013"
}, {
  "Brand": "TOTAL",
  "Street": "ROELCKESTR. 74-76",
  "PostCode": 13088,
  "City": "BERLIN",
  "Latitude": "52.562.493",
  "Longitude": "13.459.476",
  "Telephone": "030-92799956",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.562493,13.459476",
  "id": "52.562493,13.459476"
}, {
  "Brand": "ENI",
  "Street": "Hauptstr. 57",
  "PostCode": 77960,
  "City": "Seelbach",
  "Latitude": "48.315.761",
  "Longitude": "07.938.536",
  "Telephone": "+49 (0) 78239663",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.315761,7.938536",
  "id": "48.315761,7.938536"
}, {
  "Brand": "OMV",
  "Street": "Hauptstr. 38",
  "PostCode": 91334,
  "City": "Hemhofen",
  "Latitude": "49.686.290",
  "Longitude": "10.938.840",
  "Telephone": "+49-9195-992296",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.68629,10.93884",
  "id": "49.68629,10.93884"
}, {
  "Brand": "ENI",
  "Street": "Friesenstrasse 38",
  "PostCode": 71640,
  "City": "Ludwigsburg",
  "Latitude": "48.893.220",
  "Longitude": "09.223.300",
  "Telephone": "+49 (0) 71412955",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.89322,9.2233",
  "id": "48.89322,9.2233"
}, {
  "Brand": "Westfalen",
  "Street": "Davertstr. 53",
  "PostCode": 48163,
  "City": "MUENSTER",
  "Latitude": "51.882.900",
  "Longitude": "07.606.340",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8829,7.60634",
  "id": "51.8829,7.60634"
}, {
  "Brand": "OMV",
  "Street": "Augsburger Str. 187",
  "PostCode": 89231,
  "City": "Neu-Ulm",
  "Latitude": "48.401.230",
  "Longitude": "10.022.250",
  "Telephone": "+49-731-73711",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.40123,10.02225",
  "id": "48.40123,10.02225"
}, {
  "Brand": "Westfalen",
  "Street": "Pyrmonter Str. 35",
  "PostCode": 31789,
  "City": "HAMELN",
  "Latitude": "52.099.600",
  "Longitude": "09.350.250",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0996,9.35025",
  "id": "52.0996,9.35025"
}, {
  "Brand": "TOTAL",
  "Street": "KOLONIESTR. 141",
  "PostCode": 47057,
  "City": "DUISBURG",
  "Latitude": "51.419.762",
  "Longitude": "06.786.564",
  "Telephone": "0203-352727",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.419762,6.786564",
  "id": "51.419762,6.786564"
}, {
  "Brand": "TOTAL",
  "Street": "DURMERSHEIMER STR. 14a",
  "PostCode": 76316,
  "City": "MALSCH",
  "Latitude": "48.902.002",
  "Longitude": "08.310.792",
  "Telephone": "07246-8484",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.902002,8.310792",
  "id": "48.902002,8.310792"
}, {
  "Brand": "OMV",
  "Street": "Freihofstr. 77",
  "PostCode": 70439,
  "City": "Stuttgart",
  "Latitude": "48.843.910",
  "Longitude": "09.158.490",
  "Telephone": "+49-711-801139",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.84391,9.15849",
  "id": "48.84391,9.15849"
}, {
  "Brand": "Westfalen",
  "Street": "Roxeler Str. 556",
  "PostCode": 48161,
  "City": "MUENSTER",
  "Latitude": "51.955.000",
  "Longitude": "07.537.400",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.955,7.5374",
  "id": "51.955,7.5374"
}, {
  "Brand": "ENI",
  "Street": "Neuoettinger Str. 36",
  "PostCode": 84543,
  "City": "Winhoering",
  "Latitude": "48.265.726",
  "Longitude": "12.651.339",
  "Telephone": "+49 (0) 86718820",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.265726,12.651339",
  "id": "48.265726,12.651339"
}, {
  "Brand": "ENI",
  "Street": "Schweinfurter Strasse 1",
  "PostCode": 93059,
  "City": "Regensburg",
  "Latitude": "49.028.850",
  "Longitude": "12.085.250",
  "Telephone": "+49 (0) 94184667",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.02885,12.08525",
  "id": "49.02885,12.08525"
}, {
  "Brand": "Westfalen",
  "Street": "Frettholz 5",
  "PostCode": 32683,
  "City": "BARNTRUP",
  "Latitude": "51.988.651",
  "Longitude": "09.135.773",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.988651,9.135773",
  "id": "51.988651,9.135773"
}, {
  "Brand": "ENI",
  "Street": "Holzweg 21",
  "PostCode": 86156,
  "City": "Augsburg",
  "Latitude": "48.389.960",
  "Longitude": "10.866.530",
  "Telephone": "+49 (0) 82146136",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.38996,10.86653",
  "id": "48.38996,10.86653"
}, {
  "Brand": "ENI",
  "Street": "Reichenaustrasse 43 A",
  "PostCode": 78467,
  "City": "Konstanz",
  "Latitude": "47.671.760",
  "Longitude": "09.166.500",
  "Telephone": "+49 (0) 75316223",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.67176,9.1665",
  "id": "47.67176,9.1665"
}, {
  "Brand": "ARAL",
  "Street": "Carl-Bosch Straße 1A",
  "PostCode": 76829,
  "City": "Landau",
  "Latitude": "49.192.556",
  "Longitude": "08.142.516",
  "Telephone": "063415590250",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.192556,8.142516",
  "id": "49.192556,8.142516"
}, {
  "Brand": "ENI",
  "Street": "Industriepark Hoechst",
  "PostCode": 65926,
  "City": "Frankfurt",
  "Latitude": "50.082.320",
  "Longitude": "08.543.540",
  "Telephone": "+49 (0) 69300928",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.08232,8.54354",
  "id": "50.08232,8.54354"
}, {
  "Brand": "OMV",
  "Street": "Epplestr. 55",
  "PostCode": 70597,
  "City": "Stuttgart",
  "Latitude": "48.744.070",
  "Longitude": "09.169.110",
  "Telephone": "+49-711-7655530",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.74407,9.16911",
  "id": "48.74407,9.16911"
}, {
  "Brand": "OMV",
  "Street": "Bayerwaldstr. 2",
  "PostCode": 94362,
  "City": "Neukirchen",
  "Latitude": "48.974.000",
  "Longitude": "12.752.560",
  "Telephone": "+49-9961-94090",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.974,12.75256",
  "id": "48.974,12.75256"
}, {
  "Brand": "Westfalen",
  "Street": "Stiftsallee 36",
  "PostCode": 32425,
  "City": "MINDEN",
  "Latitude": "52.303.900",
  "Longitude": "08.904.410",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.3039,8.90441",
  "id": "52.3039,8.90441"
}, {
  "Brand": "ARAL",
  "Street": "A7",
  "PostCode": 91620,
  "City": "Ohrenbach",
  "Latitude": "49.486.030",
  "Longitude": "10.211.815",
  "Telephone": "0986597770",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.48603,10.211815",
  "id": "49.48603,10.211815"
}, {
  "Brand": "ENI",
  "Street": "Morsbacher Str. 13",
  "PostCode": 57537,
  "City": "Wissen / Sieg",
  "Latitude": "50.786.910",
  "Longitude": "07.729.130",
  "Telephone": "02742/2876",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.78691,7.72913",
  "id": "50.78691,7.72913"
}, {
  "Brand": "TOTAL",
  "Street": "DORFSTR.37a",
  "PostCode": 16356,
  "City": "AHRENSFELDE",
  "Latitude": "52.577.505",
  "Longitude": "13.584.722",
  "Telephone": "030-93497825",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.577505,13.584722",
  "id": "52.577505,13.584722"
}, {
  "Brand": "TOTAL",
  "Street": "ROTTWEILER STR. 2",
  "PostCode": 78727,
  "City": "OBERNDORF",
  "Latitude": "48.286.140",
  "Longitude": "08.578.105",
  "Telephone": "074238758974",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.28614,8.578105",
  "id": "48.28614,8.578105"
}, {
  "Brand": "ENI",
  "Street": "Frankfurter Strasse 50",
  "PostCode": 64839,
  "City": "Muenster",
  "Latitude": "49.929.209",
  "Longitude": "08.857.504",
  "Telephone": "+49 (0) 60713054",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.929209,8.857504",
  "id": "49.929209,8.857504"
}, {
  "Brand": "OMV",
  "Street": "Ernst-Reuter-Str. 63",
  "PostCode": 95030,
  "City": "Hof",
  "Latitude": "50.311.500",
  "Longitude": "11.907.450",
  "Telephone": "+49-9281-91118",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.3115,11.90745",
  "id": "50.3115,11.90745"
}, {
  "Brand": "TOTAL",
  "Street": "OBERNDORFER STR. 23a",
  "PostCode": 84032,
  "City": "LANDSHUT",
  "Latitude": "48.547.993",
  "Longitude": "12.131.780",
  "Telephone": "0871-40471787",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.547993,12.13178",
  "id": "48.547993,12.13178"
}, {
  "Brand": "ARAL",
  "Street": "Limburger Straße 12",
  "PostCode": 35759,
  "City": "Driedorf",
  "Latitude": "50.618.180",
  "Longitude": "08.192.196",
  "Telephone": "027757224",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:30",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:30",
  "SundayFrom": "08:00",
  "SundayTo": "21:30",
  "IsOpen24Hours": "Nein",
  "location": "50.61818,8.192196",
  "id": "50.61818,8.192196"
}, {
  "Brand": "ARAL",
  "Street": "Robert-Bosch-Straße 10",
  "PostCode": 71701,
  "City": "Schwieberdingen",
  "Latitude": "48.881.901",
  "Longitude": "09.079.281",
  "Telephone": "0715033403",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.881901,9.079281",
  "id": "48.881901,9.079281"
}, {
  "Brand": "TOTAL",
  "Street": "EILENBURGER STR.",
  "PostCode": 4849,
  "City": "BAD DUEBEN",
  "Latitude": "51.584.499",
  "Longitude": "12.592.266",
  "Telephone": "034243-23151",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.584499,12.592266",
  "id": "51.584499,12.592266"
}, {
  "Brand": "ARAL",
  "Street": "Bödigheimer Straße 24",
  "PostCode": 74722,
  "City": "Buchen",
  "Latitude": "49.515.287",
  "Longitude": "09.321.544",
  "Telephone": "0628152150",
  "WeekDayFrom": "06:45",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:45",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.515287,9.321544",
  "id": "49.515287,9.321544"
}, {
  "Brand": "TOTAL",
  "Street": "SCHLACHTHOFSTR. 88",
  "PostCode": 56073,
  "City": "KOBLENZ",
  "Latitude": "50.362.534",
  "Longitude": "07.571.578",
  "Telephone": "0261-43111",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.362534,7.571578",
  "id": "50.362534,7.571578"
}, {
  "Brand": "TOTAL",
  "Street": "HAMBURGER STR. 18",
  "PostCode": 19348,
  "City": "PERLEBERG",
  "Latitude": "53.082.701",
  "Longitude": "11.858.818",
  "Telephone": "03876-785150",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.082701,11.858818",
  "id": "53.082701,11.858818"
}, {
  "Brand": "ARAL",
  "Street": "Heerstraße 41",
  "PostCode": 29693,
  "City": "Hodenhagen",
  "Latitude": "52.762.715",
  "Longitude": "09.583.025",
  "Telephone": "05164682",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.762715,9.583025",
  "id": "52.762715,9.583025"
}, {
  "Brand": "TOTAL",
  "Street": "ALTLUSSHEIMER STR. 54",
  "PostCode": 68809,
  "City": "NEULUSSHEIM",
  "Latitude": "49.294.434",
  "Longitude": "08.516.860",
  "Telephone": "06205-31735",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.294434,8.51686",
  "id": "49.294434,8.51686"
}, {
  "Brand": "TOTAL",
  "Street": "LAUENFOERDERSTR. 3",
  "PostCode": 37176,
  "City": "NOERTEN-HARDENBERG",
  "Latitude": "51.626.505",
  "Longitude": "09.934.207",
  "Telephone": "05503-9159377",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.626505,9.934207",
  "id": "51.626505,9.934207"
}, {
  "Brand": "Westfalen",
  "Street": "Hauptstrasse 58",
  "PostCode": 31712,
  "City": "NIEDERNWOEHREN",
  "Latitude": "52.355.500",
  "Longitude": "09.142.270",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.3555,9.14227",
  "id": "52.3555,9.14227"
}, {
  "Brand": "TOTAL",
  "Street": "38 WESTERHAEUSER STR.",
  "PostCode": 6484,
  "City": "QUEDLINBURG",
  "Latitude": "51.792.288",
  "Longitude": "11.129.096",
  "Telephone": "03946-702014",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.792288,11.129096",
  "id": "51.792288,11.129096"
}, {
  "Brand": "ARAL",
  "Street": "In der Pützgewann 3 b",
  "PostCode": 56218,
  "City": "Mülheim Kärlich",
  "Latitude": "50.396.488",
  "Longitude": "07.512.766",
  "Telephone": "026309669283",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.396488,7.512766",
  "id": "50.396488,7.512766"
}, {
  "Brand": "OMV",
  "Street": "Füssener Str. 37",
  "PostCode": 87484,
  "City": "Nesselwang",
  "Latitude": "47.620.390",
  "Longitude": "10.506.260",
  "Telephone": "+49-8361-731",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.62039,10.50626",
  "id": "47.62039,10.50626"
}, {
  "Brand": "OMV",
  "Street": "Brucker Str. 16",
  "PostCode": 93149,
  "City": "Nittenau",
  "Latitude": "49.202.080",
  "Longitude": "12.280.100",
  "Telephone": "+49-9436-903231",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.20208,12.2801",
  "id": "49.20208,12.2801"
}, {
  "Brand": "TOTAL",
  "Street": "EDISONSTR. 44",
  "PostCode": 24145,
  "City": "KIEL",
  "Latitude": "54.277.443",
  "Longitude": "10.161.049",
  "Telephone": "0431-717570",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.277443,10.161049",
  "id": "54.277443,10.161049"
}, {
  "Brand": "TOTAL",
  "Street": "BORNAISCHE STR. 227",
  "PostCode": 4279,
  "City": "LEIPZIG",
  "Latitude": "51.284.905",
  "Longitude": "12.394.645",
  "Telephone": "0341-3389322",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.284905,12.394645",
  "id": "51.284905,12.394645"
}, {
  "Brand": "ENI",
  "Street": "Gymnasiumstrasse 71",
  "PostCode": 74072,
  "City": "Heilbronn",
  "Latitude": "49.141.890",
  "Longitude": "09.225.690",
  "Telephone": "+49 (0) 71318049",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.14189,9.22569",
  "id": "49.14189,9.22569"
}, {
  "Brand": "ARAL",
  "Street": "An der Haune 1 a",
  "PostCode": 36251,
  "City": "Bad Hersfeld",
  "Latitude": "50.862.171",
  "Longitude": "09.722.813",
  "Telephone": "06621919511",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.862171,9.722813",
  "id": "50.862171,9.722813"
}, {
  "Brand": "ENI",
  "Street": "Eutighofer Str. 124",
  "PostCode": 73525,
  "City": "Schwaebisch-Gmuend",
  "Latitude": "48.786.040",
  "Longitude": "09.768.230",
  "Telephone": "+49 (0) 71713082",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.78604,9.76823",
  "id": "48.78604,9.76823"
}, {
  "Brand": "TOTAL",
  "Street": "SCHIPKAUER STR.20",
  "PostCode": 1987,
  "City": "SCHWARZHEIDE",
  "Latitude": "51.465.799",
  "Longitude": "13.868.194",
  "Telephone": "035752-50893",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.465799,13.868194",
  "id": "51.465799,13.868194"
}, {
  "Brand": "TOTAL",
  "Street": "BRESLAUER STR. 1",
  "PostCode": 75181,
  "City": "PFORZHEIM",
  "Latitude": "48.884.801",
  "Longitude": "08.727.290",
  "Telephone": "07231-63949",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.884801,8.72729",
  "id": "48.884801,8.72729"
}, {
  "Brand": "Westfalen",
  "Street": "Bieste 2",
  "PostCode": 49434,
  "City": "NEUENKIRCHEN",
  "Latitude": "52.521.000",
  "Longitude": "08.039.230",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.521,8.03923",
  "id": "52.521,8.03923"
}, {
  "Brand": "TOTAL",
  "Street": "VOLMESTR. 49",
  "PostCode": 58579,
  "City": "SCHALKSMUEHLE",
  "Latitude": "51.245.303",
  "Longitude": "07.523.955",
  "Telephone": "02355-1404",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.245303,7.523955",
  "id": "51.245303,7.523955"
}, {
  "Brand": "TOTAL",
  "Street": "FREIBERGER STR. 72",
  "PostCode": 9569,
  "City": "OEDERAN",
  "Latitude": "50.864.882",
  "Longitude": "13.178.886",
  "Telephone": "037292-60273",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.864882,13.178886",
  "id": "50.864882,13.178886"
}, {
  "Brand": "ARAL",
  "Street": "Carl-Benz-Str. 10",
  "PostCode": 60386,
  "City": "Frankfurt",
  "Latitude": "50.121.764",
  "Longitude": "08.749.124",
  "Telephone": "06940809098",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.121764,8.749124",
  "id": "50.121764,8.749124"
}, {
  "Brand": "TOTAL",
  "Street": "DAHLENER STR.520",
  "PostCode": 41239,
  "City": "MOENCHENGLADBACH",
  "Latitude": "51.159.478",
  "Longitude": "06.415.267",
  "Telephone": "02166-31582",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.159478,6.415267",
  "id": "51.159478,6.415267"
}, {
  "Brand": "TOTAL",
  "Street": "AN DER B87",
  "PostCode": 6628,
  "City": "BAD KOESEN",
  "Latitude": "51.138.946",
  "Longitude": "11.730.228",
  "Telephone": "034463-26808",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.138946,11.730228",
  "id": "51.138946,11.730228"
}, {
  "Brand": "TOTAL",
  "Street": "IN DEN ENGEMATTEN 2",
  "PostCode": 79286,
  "City": "GLOTTERTAL",
  "Latitude": "48.054.533",
  "Longitude": "07.922.698",
  "Telephone": "07684-909083",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.054533,7.922698",
  "id": "48.054533,7.922698"
}, {
  "Brand": "TOTAL",
  "Street": "ANDERLEBENER STR. 53",
  "PostCode": 39387,
  "City": "OSCHERSLEBEN",
  "Latitude": "52.028.477",
  "Longitude": "11.246.092",
  "Telephone": "03949-81693",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.028477,11.246092",
  "id": "52.028477,11.246092"
}, {
  "Brand": "TOTAL",
  "Street": "RASTANLAGE 10/ B31",
  "PostCode": 79877,
  "City": "FRIEDENWEILER",
  "Latitude": "47.897.916",
  "Longitude": "08.273.432",
  "Telephone": "076546829903",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.897916,8.273432",
  "id": "47.897916,8.273432"
}, {
  "Brand": "TOTAL",
  "Street": "PRENZLAUER ALLEE 1-4",
  "PostCode": 10405,
  "City": "BERLIN",
  "Latitude": "52.528.899",
  "Longitude": "13.418.080",
  "Telephone": "030-4425643",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.528899,13.41808",
  "id": "52.528899,13.41808"
}, {
  "Brand": "TOTAL",
  "Street": "HOLZMARKTSTR. 36-42",
  "PostCode": 10243,
  "City": "BERLIN",
  "Latitude": "52.511.399",
  "Longitude": "13.428.477",
  "Telephone": "0172/3182393",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.511399,13.428477",
  "id": "52.511399,13.428477"
}, {
  "Brand": "TOTAL",
  "Street": "CELLER STR. 27",
  "PostCode": 29313,
  "City": "HAMBUEHREN",
  "Latitude": "52.634.042",
  "Longitude": "09.940.472",
  "Telephone": "05084-6260",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.634042,9.940472",
  "id": "52.634042,9.940472"
}, {
  "Brand": "ARAL",
  "Street": "An der Bundesstraße 5",
  "PostCode": 71543,
  "City": "Wüstenrot",
  "Latitude": "49.090.451",
  "Longitude": "09.490.995",
  "Telephone": "0794591910",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.090451,9.490995",
  "id": "49.090451,9.490995"
}, {
  "Brand": "ARAL",
  "Street": "Harxheimer Straße 1",
  "PostCode": 67591,
  "City": "Wachenheim",
  "Latitude": "49.636.686",
  "Longitude": "08.164.311",
  "Telephone": "06243317",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:30",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "16:00",
  "IsOpen24Hours": "Nein",
  "location": "49.636686,8.164311",
  "id": "49.636686,8.164311"
}, {
  "Brand": "ENI",
  "Street": "Sonthofener Strasse 16",
  "PostCode": 87561,
  "City": "Oberstdorf",
  "Latitude": "47.412.920",
  "Longitude": "10.275.360",
  "Telephone": "+49 (0) 83229626",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.41292,10.27536",
  "id": "47.41292,10.27536"
}, {
  "Brand": "ARAL",
  "Street": "Daimler Straße 2",
  "PostCode": 48308,
  "City": "Senden",
  "Latitude": "51.846.738",
  "Longitude": "07.484.689",
  "Telephone": "02597960750",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:30",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "22:30",
  "SundayFrom": "09:00",
  "SundayTo": "22:30",
  "IsOpen24Hours": "Nein",
  "location": "51.846738,7.484689",
  "id": "51.846738,7.484689"
}, {
  "Brand": "Westfalen",
  "Street": "Zur alten Kolonie 1",
  "PostCode": 59439,
  "City": "HOLZWICKEDE",
  "Latitude": "51.514.800",
  "Longitude": "07.620.530",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.5148,7.62053",
  "id": "51.5148,7.62053"
}, {
  "Brand": "OMV",
  "Street": "Igersheimer Strasse 16",
  "PostCode": 97980,
  "City": "Bad Mergentheim",
  "Latitude": "49.490.570",
  "Longitude": "09.779.100",
  "Telephone": "+49-7931-2378",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.49057,9.7791",
  "id": "49.49057,9.7791"
}, {
  "Brand": "TOTAL",
  "Street": "SALZUFLER ALLEE 40",
  "PostCode": 14943,
  "City": "LUCKENWALDE",
  "Latitude": "52.088.512",
  "Longitude": "13.179.167",
  "Telephone": "03371-622696",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.088512,13.179167",
  "id": "52.088512,13.179167"
}, {
  "Brand": "ENI",
  "Street": "Haager Strasse 34",
  "PostCode": 85435,
  "City": "Erding",
  "Latitude": "48.301.690",
  "Longitude": "11.908.378",
  "Telephone": "+49 (0) 81229026",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.30169,11.908378",
  "id": "48.30169,11.908378"
}, {
  "Brand": "ENI",
  "Street": "Oberopfinger Str. 25",
  "PostCode": 88450,
  "City": "Berkheim",
  "Latitude": "48.042.670",
  "Longitude": "10.084.076",
  "Telephone": "+49 (0) 8395658",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.04267,10.084076",
  "id": "48.04267,10.084076"
}, {
  "Brand": "ARAL",
  "Street": "Baarstraße 162",
  "PostCode": 58636,
  "City": "Iserlohn",
  "Latitude": "51.400.465",
  "Longitude": "07.697.738",
  "Telephone": "0237142200",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "12:01",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.400465,7.697738",
  "id": "51.400465,7.697738"
}, {
  "Brand": "Westfalen",
  "Street": "Goekerstr. 125 a",
  "PostCode": 26384,
  "City": "WILHELMSHAVEN",
  "Latitude": "53.538.500",
  "Longitude": "08.123.520",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.5385,8.12352",
  "id": "53.5385,8.12352"
}, {
  "Brand": "ARAL",
  "Street": "A8 / Schmiedstraße  16",
  "PostCode": 83607,
  "City": "Holzkirchen",
  "Latitude": "47.907.267",
  "Longitude": "11.717.336",
  "Telephone": "080246080990",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.907267,11.717336",
  "id": "47.907267,11.717336"
}, {
  "Brand": "OMV",
  "Street": "Rosenheimer Str. 40",
  "PostCode": 83064,
  "City": "Raubling",
  "Latitude": "47.796.790",
  "Longitude": "12.120.350",
  "Telephone": "+49-8035-873689",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "47.79679,12.12035",
  "id": "47.79679,12.12035"
}, {
  "Brand": "ENI",
  "Street": "Goeltzschtalstr. 75",
  "PostCode": 8209,
  "City": "Auerbach",
  "Latitude": "50.495.229",
  "Longitude": "12.396.537",
  "Telephone": "+49 (0) 37441711",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.495229,12.396537",
  "id": "50.495229,12.396537"
}, {
  "Brand": "OMV",
  "Street": "Am Oberfeld 1",
  "PostCode": 86707,
  "City": "Westendorf",
  "Latitude": "48.557.920",
  "Longitude": "10.852.590",
  "Telephone": "+49-8271-420425",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.55792,10.85259",
  "id": "48.55792,10.85259"
}, {
  "Brand": "ENI",
  "Street": "Raffineriestr. 119",
  "PostCode": 93333,
  "City": "Neustadt/Donau",
  "Latitude": "48.783.669",
  "Longitude": "11.769.583",
  "Telephone": "+49 (0) 94457502",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.783669,11.769583",
  "id": "48.783669,11.769583"
}, {
  "Brand": "ENI",
  "Street": "Metzer Str. 114",
  "PostCode": 66117,
  "City": "Saarbruecken",
  "Latitude": "49.217.653",
  "Longitude": "06.968.336",
  "Telephone": "+49 (0) 68154240",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.217653,6.968336",
  "id": "49.217653,6.968336"
}, {
  "Brand": "ENI",
  "Street": "Hersbrucker Str. 64",
  "PostCode": 91207,
  "City": "Lauf",
  "Latitude": "49.515.970",
  "Longitude": "11.290.610",
  "Telephone": "+49 (0) 91238446",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.51597,11.29061",
  "id": "49.51597,11.29061"
}, {
  "Brand": "OMV",
  "Street": "Ottenhof 51",
  "PostCode": 91287,
  "City": "Plech",
  "Latitude": "49.666.470",
  "Longitude": "11.465.950",
  "Telephone": "+49-9244-9251015",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.66647,11.46595",
  "id": "49.66647,11.46595"
}, {
  "Brand": "Westfalen",
  "Street": "Jöllenbecker 526a",
  "PostCode": 33739,
  "City": "BIELEFELD",
  "Latitude": "52.895.000",
  "Longitude": "08.515.150",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.895,8.51515",
  "id": "52.895,8.51515"
}, {
  "Brand": "TOTAL",
  "Street": "2 HATTSTEINSTRASSE",
  "PostCode": 63939,
  "City": "WOERTH AM MAIN",
  "Latitude": "49.783.495",
  "Longitude": "09.169.957",
  "Telephone": "093727063484",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.783495,9.169957",
  "id": "49.783495,9.169957"
}, {
  "Brand": "ENI",
  "Street": "Fuerther Strasse 51",
  "PostCode": 90513,
  "City": "Zirndorf",
  "Latitude": "49.448.649",
  "Longitude": "10.962.630",
  "Telephone": "+49 (0) 91160448",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.448649,10.96263",
  "id": "49.448649,10.96263"
}, {
  "Brand": "OMV",
  "Street": "Köferinger Str. 2",
  "PostCode": 92224,
  "City": "Amberg",
  "Latitude": "49.436.970",
  "Longitude": "11.857.640",
  "Telephone": "+49-9621-82420",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.43697,11.85764",
  "id": "49.43697,11.85764"
}, {
  "Brand": "ENI",
  "Street": "Riedfelder Ortsstrasse 2A",
  "PostCode": 91413,
  "City": "Neustadt a. d. Aisch",
  "Latitude": "49.582.199",
  "Longitude": "10.597.295",
  "Telephone": "+49 (0) 91612336",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.582199,10.597295",
  "id": "49.582199,10.597295"
}, {
  "Brand": "ARAL",
  "Street": "A44",
  "PostCode": 34289,
  "City": "Zierenberg",
  "Latitude": "51.356.850",
  "Longitude": "09.278.929",
  "Telephone": "056068124",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.35685,9.278929",
  "id": "51.35685,9.278929"
}, {
  "Brand": "TOTAL",
  "Street": "AUGUST-BEBEL-STR. 98",
  "PostCode": 36433,
  "City": "BAD SALZUNGEN",
  "Latitude": "50.808.279",
  "Longitude": "10.258.420",
  "Telephone": "03695-622489",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.808279,10.25842",
  "id": "50.808279,10.25842"
}, {
  "Brand": "Westfalen",
  "Street": "Lange Str. 100",
  "PostCode": 49632,
  "City": "ESSEN/OLDB.",
  "Latitude": "52.715.900",
  "Longitude": "07.938.390",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.7159,7.93839",
  "id": "52.7159,7.93839"
}, {
  "Brand": "ARAL",
  "Street": "Im Mühlenbruch 2",
  "PostCode": 53639,
  "City": "Königswinter",
  "Latitude": "50.702.560",
  "Longitude": "07.177.107",
  "Telephone": "022237009181",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.70256,7.177107",
  "id": "50.70256,7.177107"
}, {
  "Brand": "Westfalen",
  "Street": "Bocholter Str. 30a",
  "PostCode": 46499,
  "City": "HAMMINKELN",
  "Latitude": "51.777.100",
  "Longitude": "06.612.250",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.7771,6.61225",
  "id": "51.7771,6.61225"
}, {
  "Brand": "OMV",
  "Street": "Tannenberger Str. 2",
  "PostCode": 86956,
  "City": "Schongau",
  "Latitude": "47.807.000",
  "Longitude": "10.870.440",
  "Telephone": "+49-8861-2561494",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "47.807,10.87044",
  "id": "47.807,10.87044"
}, {
  "Brand": "ENI",
  "Street": "Bahnhofstr./Am Lohpfad 1",
  "PostCode": 35260,
  "City": "Stadtallendorf",
  "Latitude": "50.828.950",
  "Longitude": "09.020.610",
  "Telephone": "+49 (0) 64289212",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.82895,9.02061",
  "id": "50.82895,9.02061"
}, {
  "Brand": "ENI",
  "Street": "Alt Mahlsdorf 94",
  "PostCode": 12623,
  "City": "Berlin-Hellersdorf",
  "Latitude": "52.504.148",
  "Longitude": "13.619.813",
  "Telephone": "+49 (0) 30566781",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "52.504148,13.619813",
  "id": "52.504148,13.619813"
}, {
  "Brand": "TOTAL",
  "Street": "BAHNHOFSTR. 22",
  "PostCode": 89168,
  "City": "NIEDERSTOTZINGEN",
  "Latitude": "48.536.130",
  "Longitude": "10.236.988",
  "Telephone": "07325/919070",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.53613,10.236988",
  "id": "48.53613,10.236988"
}, {
  "Brand": "OMV",
  "Street": "Nördlinger Str. 9 c",
  "PostCode": 91126,
  "City": "Schwabach",
  "Latitude": "49.321.830",
  "Longitude": "11.016.040",
  "Telephone": "+49-9122-4531",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.32183,11.01604",
  "id": "49.32183,11.01604"
}, {
  "Brand": "TOTAL",
  "Street": "LEININGER STR. 39-41",
  "PostCode": 67269,
  "City": "GRUENSTADT",
  "Latitude": "49.546.928",
  "Longitude": "08.159.311",
  "Telephone": "06359-92214",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.546928,8.159311",
  "id": "49.546928,8.159311"
}, {
  "Brand": "TOTAL",
  "Street": "VELBURGER STR. 1",
  "PostCode": 92331,
  "City": "PARSBERG",
  "Latitude": "49.162.543",
  "Longitude": "11.716.105",
  "Telephone": "09492954590",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.162543,11.716105",
  "id": "49.162543,11.716105"
}, {
  "Brand": "ARAL",
  "Street": "Pulsnitzer Straße 31",
  "PostCode": 1900,
  "City": "Großröhrsdorf",
  "Latitude": "51.151.355",
  "Longitude": "14.020.287",
  "Telephone": "03595231554",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:30",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.151355,14.020287",
  "id": "51.151355,14.020287"
}, {
  "Brand": "TOTAL",
  "Street": "BLOCKSBERGSTR. 55",
  "PostCode": 66955,
  "City": "PIRMASENS",
  "Latitude": "49.191.867",
  "Longitude": "07.599.652",
  "Telephone": "06331-75557",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.191867,7.599652",
  "id": "49.191867,7.599652"
}, {
  "Brand": "ENI",
  "Street": "Augsburger Strasse 133",
  "PostCode": 86633,
  "City": "Neuburg / Donau",
  "Latitude": "48.719.543",
  "Longitude": "11.177.409",
  "Telephone": "+49 (0) 84311329",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.719543,11.177409",
  "id": "48.719543,11.177409"
}, {
  "Brand": "ARAL",
  "Street": "Im breiten Pfuhl 5",
  "PostCode": 67365,
  "City": "Schwegenheim",
  "Latitude": "49.271.938",
  "Longitude": "08.338.524",
  "Telephone": "063445074933",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.271938,8.338524",
  "id": "49.271938,8.338524"
}, {
  "Brand": "ARAL",
  "Street": "GREBENSTEINER STRAßE",
  "PostCode": 34369,
  "City": "HOFGEISMAR",
  "Latitude": "51.481.432",
  "Longitude": "09.389.930",
  "Telephone": "056715070246",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.481432,9.38993",
  "id": "51.481432,9.38993"
}, {
  "Brand": "OMV",
  "Street": "Hauptstr. 84",
  "PostCode": 85579,
  "City": "Neubiberg",
  "Latitude": "48.074.560",
  "Longitude": "11.677.410",
  "Telephone": "+49-89-604970",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.07456,11.67741",
  "id": "48.07456,11.67741"
}, {
  "Brand": "Westfalen",
  "Street": "Ahlener Str. 118",
  "PostCode": 59073,
  "City": "HAMM",
  "Latitude": "51.707.500",
  "Longitude": "07.832.460",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.7075,7.83246",
  "id": "51.7075,7.83246"
}, {
  "Brand": "ENI",
  "Street": "Schlater Strasse 39",
  "PostCode": 73037,
  "City": "Goeppingen",
  "Latitude": "48.681.810",
  "Longitude": "09.687.730",
  "Telephone": "+49 (0) 71618118",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.68181,9.68773",
  "id": "48.68181,9.68773"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 44",
  "PostCode": 88175,
  "City": "Scheidegg",
  "Latitude": "47.584.783",
  "Longitude": "09.854.433",
  "Telephone": "0838181717",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "47.584783,9.854433",
  "id": "47.584783,9.854433"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 155",
  "PostCode": 57518,
  "City": "Alsdorf",
  "Latitude": "50.781.266",
  "Longitude": "07.890.600",
  "Telephone": "027419374781",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.781266,7.8906",
  "id": "50.781266,7.8906"
}, {
  "Brand": "ENI",
  "Street": "Am Bahnhof 1-10",
  "PostCode": 4571,
  "City": "Espenhain",
  "Latitude": "51.195.161",
  "Longitude": "12.460.179",
  "Telephone": "+49 (0) 34206721",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.195161,12.460179",
  "id": "51.195161,12.460179"
}, {
  "Brand": "ENI",
  "Street": "Radolfzeller Strasse 35",
  "PostCode": 78333,
  "City": "Stockach",
  "Latitude": "47.845.046",
  "Longitude": "08.999.271",
  "Telephone": "+49 (0) 77712625",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.845046,8.999271",
  "id": "47.845046,8.999271"
}, {
  "Brand": "TOTAL",
  "Street": "RHINSTRASSE 52",
  "PostCode": 12681,
  "City": "BERLIN",
  "Latitude": "52.518.378",
  "Longitude": "13.520.045",
  "Telephone": "030-54376256",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.518378,13.520045",
  "id": "52.518378,13.520045"
}, {
  "Brand": "ENI",
  "Street": "Nuertinger Strasse 30",
  "PostCode": 72661,
  "City": "Grafenberg",
  "Latitude": "48.573.690",
  "Longitude": "09.318.420",
  "Telephone": "07123/939610",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.57369,9.31842",
  "id": "48.57369,9.31842"
}, {
  "Brand": "TOTAL",
  "Street": "MEMMINGER HAU 1",
  "PostCode": 89564,
  "City": "NATTHEIM",
  "Latitude": "48.689.388",
  "Longitude": "10.213.342",
  "Telephone": "073217478314",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.689388,10.213342",
  "id": "48.689388,10.213342"
}, {
  "Brand": "Westfalen",
  "Street": "Albersloher Weg 580",
  "PostCode": 48167,
  "City": "MUENSTER",
  "Latitude": "51.922.400",
  "Longitude": "07.674.000",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9224,7.674",
  "id": "51.9224,7.674"
}, {
  "Brand": "TOTAL",
  "Street": "MUENDELHEIMER STR. 106",
  "PostCode": 47259,
  "City": "DUISBURG",
  "Latitude": "51.369.910",
  "Longitude": "06.739.864",
  "Telephone": "0203-781405",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.36991,6.739864",
  "id": "51.36991,6.739864"
}, {
  "Brand": "ENI",
  "Street": "Salzburger Strasse 1 b",
  "PostCode": 83278,
  "City": "Traunstein",
  "Latitude": "47.869.020",
  "Longitude": "12.668.560",
  "Telephone": "08611/2512",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.86902,12.66856",
  "id": "47.86902,12.66856"
}, {
  "Brand": "TOTAL",
  "Street": "AM REMBERG 94",
  "PostCode": 44269,
  "City": "DORTMUND",
  "Latitude": "51.493.702",
  "Longitude": "07.520.649",
  "Telephone": "0231-42786733",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.493702,7.520649",
  "id": "51.493702,7.520649"
}, {
  "Brand": "TOTAL",
  "Street": "STORLACHSTR. 2",
  "PostCode": 72760,
  "City": "REUTLINGEN",
  "Latitude": "48.503.970",
  "Longitude": "09.212.350",
  "Telephone": "07121-334290",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.50397,9.21235",
  "id": "48.50397,9.21235"
}, {
  "Brand": "ARAL",
  "Street": "Jungenthaler Straße 75",
  "PostCode": 57548,
  "City": "Kirchen",
  "Latitude": "50.810.948",
  "Longitude": "07.864.885",
  "Telephone": "027417278",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.810948,7.864885",
  "id": "50.810948,7.864885"
}, {
  "Brand": "ENI",
  "Street": "Regensburger Str. 65",
  "PostCode": 85055,
  "City": "Ingolstadt",
  "Latitude": "48.767.820",
  "Longitude": "11.447.510",
  "Telephone": "+49 (0) 84158198",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.76782,11.44751",
  "id": "48.76782,11.44751"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 11",
  "PostCode": 83128,
  "City": "Halfing",
  "Latitude": "47.952.698",
  "Longitude": "12.278.975",
  "Telephone": "080558765",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "17:00",
  "SundayFrom": "09:00",
  "SundayTo": "14:00",
  "IsOpen24Hours": "Nein",
  "location": "47.952698,12.278975",
  "id": "47.952698,12.278975"
}, {
  "Brand": "ARAL",
  "Street": "A115",
  "PostCode": 14055,
  "City": "Berlin",
  "Latitude": "52.501.839",
  "Longitude": "13.278.117",
  "Telephone": "0303024929",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:59",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "16:59",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "52.501839,13.278117",
  "id": "52.501839,13.278117"
}, {
  "Brand": "TOTAL",
  "Street": "GRUNDSTR. 99",
  "PostCode": 1326,
  "City": "DRESDEN",
  "Latitude": "51.056.899",
  "Longitude": "13.821.686",
  "Telephone": "0351-2683294",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.056899,13.821686",
  "id": "51.056899,13.821686"
}, {
  "Brand": "TOTAL",
  "Street": "KIELER STR. 387-391",
  "PostCode": 24536,
  "City": "NEUMUENSTER",
  "Latitude": "54.102.776",
  "Longitude": "09.990.301",
  "Telephone": "04321-31282",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.102776,9.990301",
  "id": "54.102776,9.990301"
}, {
  "Brand": "TOTAL",
  "Street": "SPREMBERGER STR. 30",
  "PostCode": 3103,
  "City": "NEUPETERSHAIN",
  "Latitude": "51.592.107",
  "Longitude": "14.166.610",
  "Telephone": "035751/2100",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.592107,14.16661",
  "id": "51.592107,14.16661"
}, {
  "Brand": "OMV",
  "Street": "Bruckwiesen 12",
  "PostCode": 91088,
  "City": "Bubenreuth",
  "Latitude": "49.628.080",
  "Longitude": "11.009.250",
  "Telephone": "+49-9131-9742930",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.62808,11.00925",
  "id": "49.62808,11.00925"
}, {
  "Brand": "TOTAL",
  "Street": "AM HELLENKREUZ 1",
  "PostCode": 53332,
  "City": "BORNHEIM",
  "Latitude": "50.762.397",
  "Longitude": "06.975.811",
  "Telephone": "02222-9891379",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.762397,6.975811",
  "id": "50.762397,6.975811"
}, {
  "Brand": "TOTAL",
  "Street": "LINDENSTR. 9-10",
  "PostCode": 17358,
  "City": "TORGELOW",
  "Latitude": "53.629.923",
  "Longitude": "13.999.691",
  "Telephone": "03976-202336",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.629923,13.999691",
  "id": "53.629923,13.999691"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER CH. 103",
  "PostCode": 39288,
  "City": "BURG",
  "Latitude": "52.273.381",
  "Longitude": "11.872.191",
  "Telephone": "03921-944029",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.273381,11.872191",
  "id": "52.273381,11.872191"
}, {
  "Brand": "ENI",
  "Street": "Ludwigsburger Str. 29-33",
  "PostCode": 74343,
  "City": "Sachsenheim",
  "Latitude": "48.958.360",
  "Longitude": "09.071.230",
  "Telephone": "+49 (0) 71477621",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.95836,9.07123",
  "id": "48.95836,9.07123"
}, {
  "Brand": "ARAL",
  "Street": "Fittingstraße 99",
  "PostCode": 78224,
  "City": "Singen",
  "Latitude": "47.754.134",
  "Longitude": "08.852.683",
  "Telephone": "077319269262",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.754134,8.852683",
  "id": "47.754134,8.852683"
}, {
  "Brand": "ENI",
  "Street": "Godesberger Allee 63-65",
  "PostCode": 53175,
  "City": "Bonn",
  "Latitude": "50.697.020",
  "Longitude": "07.141.420",
  "Telephone": "+49 (0) 22837403",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.69702,7.14142",
  "id": "50.69702,7.14142"
}, {
  "Brand": "ARAL",
  "Street": "Michelsrombacher Straße 6",
  "PostCode": 36039,
  "City": "Fulda",
  "Latitude": "50.581.183",
  "Longitude": "09.705.997",
  "Telephone": "066196211314",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "50.581183,9.705997",
  "id": "50.581183,9.705997"
}, {
  "Brand": "TOTAL",
  "Street": "LAUFAMHOLZSTR. 146",
  "PostCode": 90482,
  "City": "NUERNBERG",
  "Latitude": "49.465.195",
  "Longitude": "11.152.628",
  "Telephone": "0911-501968",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.465195,11.152628",
  "id": "49.465195,11.152628"
}, {
  "Brand": "ENI",
  "Street": "Nuernberger Str. 45",
  "PostCode": 91154,
  "City": "Roth",
  "Latitude": "49.249.950",
  "Longitude": "11.089.980",
  "Telephone": "+49 (0) 91716362",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.24995,11.08998",
  "id": "49.24995,11.08998"
}, {
  "Brand": "ENI",
  "Street": "Darmstaedter Strasse 136",
  "PostCode": 64625,
  "City": "Bensheim",
  "Latitude": "49.683.252",
  "Longitude": "08.621.709",
  "Telephone": "+49 (0) 62519837",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.683252,8.621709",
  "id": "49.683252,8.621709"
}, {
  "Brand": "ENI",
  "Street": "Heilbronner Str. 390",
  "PostCode": 70469,
  "City": "Stuttgart",
  "Latitude": "48.804.420",
  "Longitude": "09.183.450",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.80442,9.18345",
  "id": "48.80442,9.18345"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER STR. 123",
  "PostCode": 14641,
  "City": "NAUEN",
  "Latitude": "52.595.670",
  "Longitude": "12.887.093",
  "Telephone": "03321-455369",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.59567,12.887093",
  "id": "52.59567,12.887093"
}, {
  "Brand": "ARAL",
  "Street": "Brannenburger Straße 12",
  "PostCode": 83131,
  "City": "Nußdorf",
  "Latitude": "47.743.358",
  "Longitude": "12.154.207",
  "Telephone": "08034904618",
  "WeekDayFrom": "07:30",
  "WeekDayTo": "19:30",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "19:30",
  "SundayFrom": "09:00",
  "SundayTo": "19:30",
  "IsOpen24Hours": "Nein",
  "location": "47.743358,12.154207",
  "id": "47.743358,12.154207"
}, {
  "Brand": "TOTAL",
  "Street": "KOELNER STR. 451",
  "PostCode": 45481,
  "City": "MUELHEIM",
  "Latitude": "51.363.467",
  "Longitude": "06.863.279",
  "Telephone": "0208-480146",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.363467,6.863279",
  "id": "51.363467,6.863279"
}, {
  "Brand": "ARAL",
  "Street": "Albert-Einstein-Straße",
  "PostCode": 31135,
  "City": "Hildesheim",
  "Latitude": "52.160.312",
  "Longitude": "09.997.376",
  "Telephone": "051216908322",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.160312,9.997376",
  "id": "52.160312,9.997376"
}, {
  "Brand": "ARAL",
  "Street": "Im Emscherbruch 10",
  "PostCode": 45699,
  "City": "Herten",
  "Latitude": "51.554.433",
  "Longitude": "07.163.481",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.554433,7.163481",
  "id": "51.554433,7.163481"
}, {
  "Brand": "OMV",
  "Street": "Münchener Str. 13",
  "PostCode": 85604,
  "City": "Zorneding",
  "Latitude": "48.083.040",
  "Longitude": "11.822.190",
  "Telephone": "+49-8106-247110",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.08304,11.82219",
  "id": "48.08304,11.82219"
}, {
  "Brand": "TOTAL",
  "Street": "HARTENSTEINER STR. 11",
  "PostCode": 8297,
  "City": "ZWOENITZ",
  "Latitude": "50.634.353",
  "Longitude": "12.794.047",
  "Telephone": "037754-5536",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.634353,12.794047",
  "id": "50.634353,12.794047"
}, {
  "Brand": "ARAL",
  "Street": "Monschauer Straße 33",
  "PostCode": 41068,
  "City": "Mönchengladbach",
  "Latitude": "51.192.840",
  "Longitude": "06.409.079",
  "Telephone": "021614770840",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.19284,6.409079",
  "id": "51.19284,6.409079"
}, {
  "Brand": "ARAL",
  "Street": "Europa-Allee 75",
  "PostCode": 54343,
  "City": "Föhren",
  "Latitude": "49.863.470",
  "Longitude": "06.791.818",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.86347,6.791818",
  "id": "49.86347,6.791818"
}, {
  "Brand": "ARAL",
  "Street": "Grenzallee 6",
  "PostCode": 12057,
  "City": "Berlin",
  "Latitude": "52.469.042",
  "Longitude": "13.461.875",
  "Telephone": "03063227487",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.469042,13.461875",
  "id": "52.469042,13.461875"
}, {
  "Brand": "ARAL",
  "Street": "Max-Planck-Str. 28",
  "PostCode": 50354,
  "City": "Hürth",
  "Latitude": "50.884.654",
  "Longitude": "06.906.917",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.884654,6.906917",
  "id": "50.884654,6.906917"
}, {
  "Brand": "OMV",
  "Street": "Äußere Parkstr. 21",
  "PostCode": 84032,
  "City": "Altdorf",
  "Latitude": "48.546.920",
  "Longitude": "12.112.490",
  "Telephone": "+49-871-35904",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.54692,12.11249",
  "id": "48.54692,12.11249"
}, {
  "Brand": "TOTAL",
  "Street": "BLUMENAUER STR.9",
  "PostCode": 9526,
  "City": "OLBERNHAU",
  "Latitude": "50.662.199",
  "Longitude": "13.329.181",
  "Telephone": "037360-74280",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.662199,13.329181",
  "id": "50.662199,13.329181"
}, {
  "Brand": "ARAL",
  "Street": "Tilsiter Straße 15",
  "PostCode": 71065,
  "City": "Sindelfingen",
  "Latitude": "48.703.160",
  "Longitude": "09.029.866",
  "Telephone": "07031633658",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "00:00",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "48.70316,9.029866",
  "id": "48.70316,9.029866"
}, {
  "Brand": "TOTAL",
  "Street": "WILLY-BRANDT-STR. 206",
  "PostCode": 46535,
  "City": "DINSLAKEN",
  "Latitude": "51.549.561",
  "Longitude": "06.728.918",
  "Telephone": "02064-732480",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.549561,6.728918",
  "id": "51.549561,6.728918"
}, {
  "Brand": "Westfalen",
  "Street": "Augustdorfer Str. 67",
  "PostCode": 32758,
  "City": "DETMOLD",
  "Latitude": "51.937.700",
  "Longitude": "08.778.630",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9377,8.77863",
  "id": "51.9377,8.77863"
}, {
  "Brand": "Westfalen",
  "Street": "Nienborger Str. 28",
  "PostCode": 48599,
  "City": "GRONAU",
  "Latitude": "52.175.700",
  "Longitude": "07.048.980",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.1757,7.04898",
  "id": "52.1757,7.04898"
}, {
  "Brand": "Westfalen",
  "Street": "In der Geist 110",
  "PostCode": 59302,
  "City": "OELDE",
  "Latitude": "51.811.900",
  "Longitude": "08.134.510",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8119,8.13451",
  "id": "51.8119,8.13451"
}, {
  "Brand": "TOTAL",
  "Street": "RUDOLSTAEDTER STR. 13",
  "PostCode": 7318,
  "City": "SAALFELD",
  "Latitude": "50.657.426",
  "Longitude": "11.347.354",
  "Telephone": "03671-2264",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.657426,11.347354",
  "id": "50.657426,11.347354"
}, {
  "Brand": "Westfalen",
  "Street": "Zum Eistruper Feld 2",
  "PostCode": 49143,
  "City": "BISSENDORF",
  "Latitude": "52.239.800",
  "Longitude": "08.161.330",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.2398,8.16133",
  "id": "52.2398,8.16133"
}, {
  "Brand": "TOTAL",
  "Street": "DR.-LEBER-STR. 8",
  "PostCode": 23966,
  "City": "WISMAR",
  "Latitude": "53.889.796",
  "Longitude": "11.471.355",
  "Telephone": "03841-40523",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.889796,11.471355",
  "id": "53.889796,11.471355"
}, {
  "Brand": "ARAL",
  "Street": "Friedrichshafener Straße 73",
  "PostCode": 88214,
  "City": "Ravensburg",
  "Latitude": "47.738.571",
  "Longitude": "09.598.375",
  "Telephone": "075162384",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "47.738571,9.598375",
  "id": "47.738571,9.598375"
}, {
  "Brand": "ARAL",
  "Street": "Schalmeistraße 2",
  "PostCode": 86399,
  "City": "Bobingen",
  "Latitude": "48.279.162",
  "Longitude": "10.840.743",
  "Telephone": "082348771",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.279162,10.840743",
  "id": "48.279162,10.840743"
}, {
  "Brand": "ENI",
  "Street": "Ingolstaedter Str. 44 A",
  "PostCode": 85072,
  "City": "Eichstaett",
  "Latitude": "48.882.020",
  "Longitude": "11.196.530",
  "Telephone": "+49 (0) 84212865",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.88202,11.19653",
  "id": "48.88202,11.19653"
}, {
  "Brand": "ARAL",
  "Street": "BAB A 19 - Ost",
  "PostCode": 18276,
  "City": "Recknitz",
  "Latitude": "53.839.943",
  "Longitude": "12.287.809",
  "Telephone": "03845520952",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.839943,12.287809",
  "id": "53.839943,12.287809"
}, {
  "Brand": "ARAL",
  "Street": "Südstraße 74",
  "PostCode": 71404,
  "City": "Korb",
  "Latitude": "48.838.409",
  "Longitude": "09.366.056",
  "Telephone": "0715131100",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "14:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "48.838409,9.366056",
  "id": "48.838409,9.366056"
}, {
  "Brand": "ENI",
  "Street": "Neureuter Str. 5",
  "PostCode": 76185,
  "City": "Karlsruhe",
  "Latitude": "49.017.330",
  "Longitude": "08.354.050",
  "Telephone": "+49 (0) 72156801",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.01733,8.35405",
  "id": "49.01733,8.35405"
}, {
  "Brand": "ARAL",
  "Street": "Moerser Straße 470-472",
  "PostCode": 47475,
  "City": "Kamp-Lintfort",
  "Latitude": "51.499.149",
  "Longitude": "06.568.116",
  "Telephone": "0284293567",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.499149,6.568116",
  "id": "51.499149,6.568116"
}, {
  "Brand": "ARAL",
  "Street": "A67",
  "PostCode": 64319,
  "City": "Pfungstadt",
  "Latitude": "49.813.422",
  "Longitude": "08.578.055",
  "Telephone": "0615785531",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.813422,8.578055",
  "id": "49.813422,8.578055"
}, {
  "Brand": "ARAL",
  "Street": "Robert-Bosch-Straße 40",
  "PostCode": 88131,
  "City": "Lindau",
  "Latitude": "47.547.694",
  "Longitude": "09.728.079",
  "Telephone": "0838278767",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.547694,9.728079",
  "id": "47.547694,9.728079"
}, {
  "Brand": "ARAL",
  "Street": "Im Schiffelland 24",
  "PostCode": 66386,
  "City": "St. Ingbert",
  "Latitude": "49.269.297",
  "Longitude": "07.130.715",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.269297,7.130715",
  "id": "49.269297,7.130715"
}, {
  "Brand": "ARAL",
  "Street": "Darmstädter Straße 2",
  "PostCode": 64319,
  "City": "Pfungstadt",
  "Latitude": "49.807.423",
  "Longitude": "08.564.181",
  "Telephone": "061579896791",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.807423,8.564181",
  "id": "49.807423,8.564181"
}, {
  "Brand": "ARAL",
  "Street": "An der Aspe 1",
  "PostCode": 36137,
  "City": "Großenlüder",
  "Latitude": "50.581.469",
  "Longitude": "09.543.857",
  "Telephone": "066487128",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.581469,9.543857",
  "id": "50.581469,9.543857"
}, {
  "Brand": "TOTAL",
  "Street": "BOTTROPER STR. 248",
  "PostCode": 45964,
  "City": "GLADBECK",
  "Latitude": "51.566.174",
  "Longitude": "06.964.711",
  "Telephone": "02043-489074",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.566174,6.964711",
  "id": "51.566174,6.964711"
}, {
  "Brand": "ARAL",
  "Street": "Am Nienhaushof 18",
  "PostCode": 47139,
  "City": "Duisburg",
  "Latitude": "51.477.264",
  "Longitude": "06.719.509",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.477264,6.719509",
  "id": "51.477264,6.719509"
}, {
  "Brand": "ENI",
  "Street": "Dammmuehlenweg",
  "PostCode": 39340,
  "City": "Haldensleben",
  "Latitude": "52.277.689",
  "Longitude": "11.442.309",
  "Telephone": "+49 (0) 39044102",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "52.277689,11.442309",
  "id": "52.277689,11.442309"
}, {
  "Brand": "OMV",
  "Street": "Ettlinger Str. 2",
  "PostCode": 76337,
  "City": "Waldbronn",
  "Latitude": "48.930.940",
  "Longitude": "08.456.190",
  "Telephone": "+49-7243-572557",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.93094,8.45619",
  "id": "48.93094,8.45619"
}, {
  "Brand": "Westfalen",
  "Street": "Strangenhäuschen 10",
  "PostCode": 52070,
  "City": "AACHEN",
  "Latitude": "50.801.800",
  "Longitude": "06.110.780",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.8018,6.11078",
  "id": "50.8018,6.11078"
}, {
  "Brand": "ENI",
  "Street": "Kaiser-Friedrich-Str. 1a",
  "PostCode": 35619,
  "City": "Braunfels/Lahn",
  "Latitude": "50.517.720",
  "Longitude": "08.391.220",
  "Telephone": "06442/9320047",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.51772,8.39122",
  "id": "50.51772,8.39122"
}, {
  "Brand": "TOTAL",
  "Street": "LEITSCHAECKER 2",
  "PostCode": 89257,
  "City": "ILLERTISSEN",
  "Latitude": "48.219.888",
  "Longitude": "10.125.081",
  "Telephone": "07303/1579395",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.219888,10.125081",
  "id": "48.219888,10.125081"
}, {
  "Brand": "ARAL",
  "Street": "Freiburger Strasse 9",
  "PostCode": 79189,
  "City": "Bad Krozingen",
  "Latitude": "47.918.693",
  "Longitude": "07.703.540",
  "Telephone": "076333536",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.918693,7.70354",
  "id": "47.918693,7.70354"
}, {
  "Brand": "OMV",
  "Street": "Bannholzstr. 31",
  "PostCode": 94065,
  "City": "Waldkirchen",
  "Latitude": "48.732.230",
  "Longitude": "13.608.690",
  "Telephone": "+49-8581-96480",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.73223,13.60869",
  "id": "48.73223,13.60869"
}, {
  "Brand": "TOTAL",
  "Street": "ROSTOCKER STR . 72",
  "PostCode": 18190,
  "City": "SANITZ",
  "Latitude": "54.077.740",
  "Longitude": "12.367.252",
  "Telephone": "038209-308",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.07774,12.367252",
  "id": "54.07774,12.367252"
}, {
  "Brand": "TOTAL",
  "Street": "PAFFRATHER STR. 215",
  "PostCode": 51469,
  "City": "BERGISCH GLADBACH",
  "Latitude": "50.995.278",
  "Longitude": "07.109.758",
  "Telephone": "02202-920265",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.995278,7.109758",
  "id": "50.995278,7.109758"
}, {
  "Brand": "TOTAL",
  "Street": "JULIUS-LEBER-STR. 2c",
  "PostCode": 53123,
  "City": "BONN",
  "Latitude": "50.709.315",
  "Longitude": "07.054.633",
  "Telephone": "0228-96285704",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.709315,7.054633",
  "id": "50.709315,7.054633"
}, {
  "Brand": "ARAL",
  "Street": "Carl-Schurz-Straße 160",
  "PostCode": 50374,
  "City": "Erftstadt",
  "Latitude": "50.810.398",
  "Longitude": "06.808.512",
  "Telephone": "0223545711",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.810398,6.808512",
  "id": "50.810398,6.808512"
}, {
  "Brand": "OMV",
  "Street": "Bamberger Str. 54",
  "PostCode": 96149,
  "City": "Breitengüssbach",
  "Latitude": "49.966.950",
  "Longitude": "10.884.230",
  "Telephone": "+49-9544-950348",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.96695,10.88423",
  "id": "49.96695,10.88423"
}, {
  "Brand": "ARAL",
  "Street": "Manfred-von-Ardenne-Allee 2",
  "PostCode": 71522,
  "City": "Backnang",
  "Latitude": "48.959.211",
  "Longitude": "09.423.126",
  "Telephone": "07191345500",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.959211,9.423126",
  "id": "48.959211,9.423126"
}, {
  "Brand": "ARAL",
  "Street": "Siemensstr. 5",
  "PostCode": 42551,
  "City": "Velbert",
  "Latitude": "51.347.862",
  "Longitude": "07.063.237",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.347862,7.063237",
  "id": "51.347862,7.063237"
}, {
  "Brand": "TOTAL",
  "Street": "BRESLAUER STR.45",
  "PostCode": 38440,
  "City": "WOLFSBURG",
  "Latitude": "52.419.160",
  "Longitude": "10.754.166",
  "Telephone": "05361-39000",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.41916,10.754166",
  "id": "52.41916,10.754166"
}, {
  "Brand": "ARAL",
  "Street": "Kieler Straße 51-53",
  "PostCode": 24568,
  "City": "Kaltenkirchen",
  "Latitude": "53.840.726",
  "Longitude": "09.947.619",
  "Telephone": "041919528830",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "05:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "53.840726,9.947619",
  "id": "53.840726,9.947619"
}, {
  "Brand": "ARAL",
  "Street": "Im Höning",
  "PostCode": 63820,
  "City": "Elsenfeld",
  "Latitude": "49.856.072",
  "Longitude": "09.167.702",
  "Telephone": "060225062265",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.856072,9.167702",
  "id": "49.856072,9.167702"
}, {
  "Brand": "ARAL",
  "Street": "Landstraße 16",
  "PostCode": 76547,
  "City": "Sinzheim",
  "Latitude": "48.760.111",
  "Longitude": "08.168.860",
  "Telephone": "072218035625",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.760111,8.16886",
  "id": "48.760111,8.16886"
}, {
  "Brand": "TOTAL",
  "Street": "PORTASTR. 44",
  "PostCode": 32457,
  "City": "PORTA WESTFALICA",
  "Latitude": "52.253.158",
  "Longitude": "08.909.657",
  "Telephone": "0571-51142",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.253158,8.909657",
  "id": "52.253158,8.909657"
}, {
  "Brand": "TOTAL",
  "Street": "SAARSTR. 6",
  "PostCode": 55122,
  "City": "MAINZ",
  "Latitude": "49.997.709",
  "Longitude": "08.253.618",
  "Telephone": "06131-381434",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.997709,8.253618",
  "id": "49.997709,8.253618"
}, {
  "Brand": "TOTAL",
  "Street": "WENSCHTSTR. 7",
  "PostCode": 57078,
  "City": "SIEGEN",
  "Latitude": "50.912.509",
  "Longitude": "08.007.419",
  "Telephone": "0271-74113512",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.912509,8.007419",
  "id": "50.912509,8.007419"
}, {
  "Brand": "Westfalen",
  "Street": "Kurt-Schumacher Str. 236",
  "PostCode": 46539,
  "City": "DINSLAKEN",
  "Latitude": "51.557.500",
  "Longitude": "06.796.470",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.5575,6.79647",
  "id": "51.5575,6.79647"
}, {
  "Brand": "Westfalen",
  "Street": "Hannoversche Str. 20",
  "PostCode": 49084,
  "City": "OSNABRUECK",
  "Latitude": "52.264.000",
  "Longitude": "08.066.110",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.264,8.06611",
  "id": "52.264,8.06611"
}, {
  "Brand": "TOTAL",
  "Street": "WARNOWUFER 53",
  "PostCode": 18057,
  "City": "ROSTOCK",
  "Latitude": "54.093.524",
  "Longitude": "12.115.502",
  "Telephone": "0381-4903119",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.093524,12.115502",
  "id": "54.093524,12.115502"
}, {
  "Brand": "ARAL",
  "Street": "Peter-Henlein-Str.",
  "PostCode": 89312,
  "City": "Günzburg",
  "Latitude": "48.473.433",
  "Longitude": "10.273.352",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.473433,10.273352",
  "id": "48.473433,10.273352"
}, {
  "Brand": "Westfalen",
  "Street": "Toepferstrasse 2",
  "PostCode": 53343,
  "City": "WACHTBERG",
  "Latitude": "50.612.800",
  "Longitude": "07.053.470",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.6128,7.05347",
  "id": "50.6128,7.05347"
}, {
  "Brand": "TOTAL",
  "Street": "MAGDEBURGER STR. 84",
  "PostCode": 39261,
  "City": "ZERBST",
  "Latitude": "51.975.019",
  "Longitude": "12.066.033",
  "Telephone": "03923-780437",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.975019,12.066033",
  "id": "51.975019,12.066033"
}, {
  "Brand": "ARAL",
  "Street": "Neuenhöhe 20",
  "PostCode": 42929,
  "City": "Wermelskirchen",
  "Latitude": "51.146.157",
  "Longitude": "07.236.031",
  "Telephone": "02196731850",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.146157,7.236031",
  "id": "51.146157,7.236031"
}, {
  "Brand": "TOTAL",
  "Street": "MOORREDDER 1",
  "PostCode": 23570,
  "City": "LUEBECK",
  "Latitude": "53.962.734",
  "Longitude": "10.861.101",
  "Telephone": "04502-3510",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.962734,10.861101",
  "id": "53.962734,10.861101"
}, {
  "Brand": "TOTAL",
  "Street": "HAFENSTR. 41",
  "PostCode": 55276,
  "City": "OPPENHEIM",
  "Latitude": "49.860.597",
  "Longitude": "08.356.819",
  "Telephone": "06133-9427",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.860597,8.356819",
  "id": "49.860597,8.356819"
}, {
  "Brand": "ARAL",
  "Street": "Zehentbauernstraße 7",
  "PostCode": 81539,
  "City": "München",
  "Latitude": "48.114.278",
  "Longitude": "11.576.283",
  "Telephone": "0896972174",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:30",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:30",
  "SundayFrom": "07:00",
  "SundayTo": "22:30",
  "IsOpen24Hours": "Nein",
  "location": "48.114278,11.576283",
  "id": "48.114278,11.576283"
}, {
  "Brand": "ENI",
  "Street": "Bayreuther Str. 26",
  "PostCode": 92237,
  "City": "Sulzbach-Rosenberg",
  "Latitude": "49.504.220",
  "Longitude": "11.743.210",
  "Telephone": "+49 (0) 96615313",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.50422,11.74321",
  "id": "49.50422,11.74321"
}, {
  "Brand": "TOTAL",
  "Street": "MAGDEBURGER STR. 39-45",
  "PostCode": 38820,
  "City": "HALBERSTADT",
  "Latitude": "51.898.030",
  "Longitude": "11.063.398",
  "Telephone": "03941-600139",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.89803,11.063398",
  "id": "51.89803,11.063398"
}, {
  "Brand": "TOTAL",
  "Street": "GUTENBERGSTRASSE 2",
  "PostCode": 87781,
  "City": "UNGERHAUSEN",
  "Latitude": "48.018.255",
  "Longitude": "10.273.633",
  "Telephone": "08393-9439170",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.018255,10.273633",
  "id": "48.018255,10.273633"
}, {
  "Brand": "TOTAL",
  "Street": "BAB BOERDE SUED / A2",
  "PostCode": 39343,
  "City": "GROSS SANTERSLEBEN",
  "Latitude": "52.184.845",
  "Longitude": "11.448.950",
  "Telephone": "039206-68764",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.184845,11.44895",
  "id": "52.184845,11.44895"
}, {
  "Brand": "TOTAL",
  "Street": "BAB 4 / ALTENBURGER LAND-SUED",
  "PostCode": 4626,
  "City": "SCHMOELLN OT WEISSBACH",
  "Latitude": "50.856.747",
  "Longitude": "12.313.485",
  "Telephone": "034491/554813",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.856747,12.313485",
  "id": "50.856747,12.313485"
}, {
  "Brand": "ARAL",
  "Street": "Ulmer Straße 39",
  "PostCode": 73066,
  "City": "Uhingen",
  "Latitude": "48.707.301",
  "Longitude": "09.589.791",
  "Telephone": "071619838726",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.707301,9.589791",
  "id": "48.707301,9.589791"
}, {
  "Brand": "ARAL",
  "Street": "Bezirksstraße 16",
  "PostCode": 67122,
  "City": "Altrip",
  "Latitude": "49.434.966",
  "Longitude": "08.484.531",
  "Telephone": "062362685",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:30",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.434966,8.484531",
  "id": "49.434966,8.484531"
}, {
  "Brand": "ARAL",
  "Street": "Innstraße 16",
  "PostCode": 84453,
  "City": "Mühldorf",
  "Latitude": "48.241.777",
  "Longitude": "12.519.261",
  "Telephone": "086319866933",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.241777,12.519261",
  "id": "48.241777,12.519261"
}, {
  "Brand": "TOTAL",
  "Street": "GRUBER STR. 14",
  "PostCode": 85599,
  "City": "PARSDORF",
  "Latitude": "48.147.743",
  "Longitude": "11.787.822",
  "Telephone": "08932601090",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.147743,11.787822",
  "id": "48.147743,11.787822"
}, {
  "Brand": "Westfalen",
  "Street": "Rheinberger Str. 306",
  "PostCode": 47445,
  "City": "MOERS",
  "Latitude": "51.488.000",
  "Longitude": "06.633.180",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.488,6.63318",
  "id": "51.488,6.63318"
}, {
  "Brand": "ENI",
  "Street": "Bayreuther Str. 22",
  "PostCode": 91301,
  "City": "Forchheim",
  "Latitude": "49.715.881",
  "Longitude": "11.077.258",
  "Telephone": "+49 (0) 91917277",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.715881,11.077258",
  "id": "49.715881,11.077258"
}, {
  "Brand": "TOTAL",
  "Street": "PROBSTZELLAER STR. 15",
  "PostCode": 98743,
  "City": "GRAEFENTHAL",
  "Latitude": "50.527.265",
  "Longitude": "11.312.678",
  "Telephone": "036703-80739",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.527265,11.312678",
  "id": "50.527265,11.312678"
}, {
  "Brand": "ENI",
  "Street": "Am Gewerbepark 6",
  "PostCode": 90552,
  "City": "Roethenbach",
  "Latitude": "49.450.430",
  "Longitude": "11.080.680",
  "Telephone": "0911/54099439",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.45043,11.08068",
  "id": "49.45043,11.08068"
}, {
  "Brand": "ENI",
  "Street": "Oelsnitzer Str.",
  "PostCode": 8626,
  "City": "Adorf",
  "Latitude": "50.326.496",
  "Longitude": "12.256.525",
  "Telephone": "+49 (0) 37423229",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.326496,12.256525",
  "id": "50.326496,12.256525"
}, {
  "Brand": "ENI",
  "Street": "Muenchner Str. 121A",
  "PostCode": 82008,
  "City": "Unterhaching",
  "Latitude": "48.074.180",
  "Longitude": "11.604.504",
  "Telephone": "+49 (0) 89617338",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.07418,11.604504",
  "id": "48.07418,11.604504"
}, {
  "Brand": "Westfalen",
  "Street": "Vardingholter Str. 10",
  "PostCode": 46414,
  "City": "RHEDE",
  "Latitude": "51.844.300",
  "Longitude": "06.694.830",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8443,6.69483",
  "id": "51.8443,6.69483"
}, {
  "Brand": "OMV",
  "Street": "An den Sandwellen 1",
  "PostCode": 93326,
  "City": "Abensberg",
  "Latitude": "48.810.870",
  "Longitude": "11.858.960",
  "Telephone": "+49-9443-905423",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.81087,11.85896",
  "id": "48.81087,11.85896"
}, {
  "Brand": "ENI",
  "Street": "Laibacher Weg 1",
  "PostCode": 95469,
  "City": "Speichersdorf",
  "Latitude": "49.870.470",
  "Longitude": "11.772.870",
  "Telephone": "09275/6059362",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.87047,11.77287",
  "id": "49.87047,11.77287"
}, {
  "Brand": "ARAL",
  "Street": "Forchheimer Straße 44",
  "PostCode": 91320,
  "City": "Ebermannstadt",
  "Latitude": "49.776.901",
  "Longitude": "11.177.896",
  "Telephone": "09194737016",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.776901,11.177896",
  "id": "49.776901,11.177896"
}, {
  "Brand": "Westfalen",
  "Street": "Riesenbecker Str. 27",
  "PostCode": 48477,
  "City": "HOESTEL",
  "Latitude": "52.271.500",
  "Longitude": "07.589.770",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.2715,7.58977",
  "id": "52.2715,7.58977"
}, {
  "Brand": "ENI",
  "Street": "Rosenauer Strasse 6",
  "PostCode": 94481,
  "City": "Grafenau",
  "Latitude": "48.859.638",
  "Longitude": "13.398.414",
  "Telephone": "+49 (0) 85523488",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.859638,13.398414",
  "id": "48.859638,13.398414"
}, {
  "Brand": "ARAL",
  "Street": "Nikola-Tesla-Straße 1",
  "PostCode": 77955,
  "City": "Ettenheim",
  "Latitude": "48.274.796",
  "Longitude": "07.785.564",
  "Telephone": "07822/3005688",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.274796,7.785564",
  "id": "48.274796,7.785564"
}, {
  "Brand": "Westfalen",
  "Street": "Steinfurter Str. 24",
  "PostCode": 59387,
  "City": "ASCHEBERG",
  "Latitude": "51.792.600",
  "Longitude": "07.629.220",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.7926,7.62922",
  "id": "51.7926,7.62922"
}, {
  "Brand": "ARAL",
  "Street": "Ferdinand-Porsche-Str. 9",
  "PostCode": 60386,
  "City": "Frankfurt",
  "Latitude": "50.121.941",
  "Longitude": "08.757.044",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.121941,8.757044",
  "id": "50.121941,8.757044"
}, {
  "Brand": "Westfalen",
  "Street": "Ennigerloher Str. 13",
  "PostCode": 59269,
  "City": "BECKUM",
  "Latitude": "51.806.200",
  "Longitude": "08.025.600",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8062,8.0256",
  "id": "51.8062,8.0256"
}, {
  "Brand": "TOTAL",
  "Street": "HANS-GUENTER-BOCK-STR. 8",
  "PostCode": 16798,
  "City": "FUERSTENBERG",
  "Latitude": "53.171.690",
  "Longitude": "13.140.833",
  "Telephone": "033093-32106",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.17169,13.140833",
  "id": "53.17169,13.140833"
}, {
  "Brand": "Westfalen",
  "Street": "Bohlweg 70-72",
  "PostCode": 48147,
  "City": "MUENSTER",
  "Latitude": "51.967.300",
  "Longitude": "07.643.060",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9673,7.64306",
  "id": "51.9673,7.64306"
}, {
  "Brand": "ENI",
  "Street": "Halberstaedter Chaussee 25",
  "PostCode": 39116,
  "City": "Magdeburg",
  "Latitude": "52.089.100",
  "Longitude": "11.568.700",
  "Telephone": "+49 (0) 39163133",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "52.0891,11.5687",
  "id": "52.0891,11.5687"
}, {
  "Brand": "Westfalen",
  "Street": "Roisdorfer Str.",
  "PostCode": 53332,
  "City": "BORNHEIM",
  "Latitude": "50.761.000",
  "Longitude": "07.026.310",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.761,7.02631",
  "id": "50.761,7.02631"
}, {
  "Brand": "TOTAL",
  "Street": "BRUNOSTRASSE 34",
  "PostCode": 54329,
  "City": "KONZ",
  "Latitude": "49.710.636",
  "Longitude": "06.591.084",
  "Telephone": "06501607546",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.710636,6.591084",
  "id": "49.710636,6.591084"
}, {
  "Brand": "ARAL",
  "Street": "Hohberger Straße 19",
  "PostCode": 71106,
  "City": "Magstadt",
  "Latitude": "48.738.601",
  "Longitude": "08.966.261",
  "Telephone": "0715994740",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "18:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "13:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "48.738601,8.966261",
  "id": "48.738601,8.966261"
}, {
  "Brand": "OMV",
  "Street": "Nürnberger Strasse 17 a",
  "PostCode": 92237,
  "City": "Sulzbach-Rosenberg",
  "Latitude": "49.506.040",
  "Longitude": "11.729.530",
  "Telephone": "+49-9661-4345",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.50604,11.72953",
  "id": "49.50604,11.72953"
}, {
  "Brand": "OMV",
  "Street": "Bahnhofstr. 5",
  "PostCode": 97769,
  "City": "Bad Brückenau",
  "Latitude": "50.307.380",
  "Longitude": "09.786.520",
  "Telephone": "+49-9741-2525",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.30738,9.78652",
  "id": "50.30738,9.78652"
}, {
  "Brand": "TOTAL",
  "Street": "BAB HOLMMOORWEG WEST/A 7",
  "PostCode": 25451,
  "City": "QUICKBORN",
  "Latitude": "53.717.743",
  "Longitude": "09.937.974",
  "Telephone": "04106-9769520",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.717743,9.937974",
  "id": "53.717743,9.937974"
}, {
  "Brand": "TOTAL",
  "Street": "RHINSTRASSE 175",
  "PostCode": 13053,
  "City": "BERLIN",
  "Latitude": "52.536.691",
  "Longitude": "13.515.410",
  "Telephone": "030-9824536",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.536691,13.51541",
  "id": "52.536691,13.51541"
}, {
  "Brand": "ARAL",
  "Street": "Hans-Böckler-Allee 33",
  "PostCode": 30173,
  "City": "Hannover",
  "Latitude": "52.371.392",
  "Longitude": "09.773.765",
  "Telephone": "05118112116",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.371392,9.773765",
  "id": "52.371392,9.773765"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER STR. 23",
  "PostCode": 14712,
  "City": "RATHENOW",
  "Latitude": "52.603.594",
  "Longitude": "12.351.393",
  "Telephone": "03385-5200351",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.603594,12.351393",
  "id": "52.603594,12.351393"
}, {
  "Brand": "TOTAL",
  "Street": "ERFURTER STR. 60",
  "PostCode": 98693,
  "City": "ILMENAU",
  "Latitude": "50.691.333",
  "Longitude": "10.904.988",
  "Telephone": "03677-840890",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.691333,10.904988",
  "id": "50.691333,10.904988"
}, {
  "Brand": "TOTAL",
  "Street": "LIMBACHER STR. 58",
  "PostCode": 9113,
  "City": "CHEMNITZ",
  "Latitude": "50.837.992",
  "Longitude": "12.896.985",
  "Telephone": "0371-3363873",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.837992,12.896985",
  "id": "50.837992,12.896985"
}, {
  "Brand": "TOTAL",
  "Street": "LINDENSTRASSE 25",
  "PostCode": 54662,
  "City": "HERFORST",
  "Latitude": "49.946.297",
  "Longitude": "06.690.504",
  "Telephone": "06562930304",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.946297,6.690504",
  "id": "49.946297,6.690504"
}, {
  "Brand": "ARAL",
  "Street": "Kölner Straße 36-40",
  "PostCode": 50226,
  "City": "Frechen",
  "Latitude": "50.910.591",
  "Longitude": "06.817.882",
  "Telephone": "0223414646",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.910591,6.817882",
  "id": "50.910591,6.817882"
}, {
  "Brand": "TOTAL",
  "Street": "DEGGENDORFER STRASSE 61",
  "PostCode": 94447,
  "City": "PLATTLING",
  "Latitude": "48.782.849",
  "Longitude": "12.880.382",
  "Telephone": "09931-3696",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.782849,12.880382",
  "id": "48.782849,12.880382"
}, {
  "Brand": "ARAL",
  "Street": "Erzbergerstraße 61",
  "PostCode": 88239,
  "City": "Wangen",
  "Latitude": "47.677.638",
  "Longitude": "09.840.585",
  "Telephone": "0",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.677638,9.840585",
  "id": "47.677638,9.840585"
}, {
  "Brand": "TOTAL",
  "Street": "STAENDENHOF 1, B10",
  "PostCode": 66957,
  "City": "RUPPERTSWEILER",
  "Latitude": "49.204.063",
  "Longitude": "07.696.084",
  "Telephone": "06395-6280",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.204063,7.696084",
  "id": "49.204063,7.696084"
}, {
  "Brand": "TOTAL",
  "Street": "KROHNSTIEG 71-75",
  "PostCode": 22415,
  "City": "HAMBURG",
  "Latitude": "53.648.328",
  "Longitude": "10.005.961",
  "Telephone": "040-5319690",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.648328,10.005961",
  "id": "53.648328,10.005961"
}, {
  "Brand": "TOTAL",
  "Street": "POSTSTR. 12",
  "PostCode": 98559,
  "City": "OBERHOF",
  "Latitude": "50.704.994",
  "Longitude": "10.731.859",
  "Telephone": "036842-22598",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.704994,10.731859",
  "id": "50.704994,10.731859"
}, {
  "Brand": "OMV",
  "Street": "Hauptstr. 1",
  "PostCode": 85414,
  "City": "Kirchdorf",
  "Latitude": "48.458.410",
  "Longitude": "11.652.600",
  "Telephone": "+49-8166-9668",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.45841,11.6526",
  "id": "48.45841,11.6526"
}, {
  "Brand": "TOTAL",
  "Street": "NEUHOEFER STR. 28",
  "PostCode": 21107,
  "City": "HAMBURG",
  "Latitude": "53.510.792",
  "Longitude": "09.984.535",
  "Telephone": "040-7532295",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.510792,9.984535",
  "id": "53.510792,9.984535"
}, {
  "Brand": "TOTAL",
  "Street": "FRANKENBERGER STR. 56",
  "PostCode": 9661,
  "City": "HAINICHEN",
  "Latitude": "50.968.002",
  "Longitude": "13.105.072",
  "Telephone": "037207-2588",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.968002,13.105072",
  "id": "50.968002,13.105072"
}, {
  "Brand": "TOTAL",
  "Street": "GRAVENHORSTER STR.21",
  "PostCode": 49477,
  "City": "IBBENBUEREN",
  "Latitude": "52.274.546",
  "Longitude": "07.704.868",
  "Telephone": "05451-894751",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.274546,7.704868",
  "id": "52.274546,7.704868"
}, {
  "Brand": "ARAL",
  "Street": "BAB A 1",
  "PostCode": 27367,
  "City": "Sottrum",
  "Latitude": "53.090.887",
  "Longitude": "09.177.942",
  "Telephone": "04205494",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.090887,9.177942",
  "id": "53.090887,9.177942"
}, {
  "Brand": "TOTAL",
  "Street": "TURNSTR. 3",
  "PostCode": 25335,
  "City": "ELMSHORN",
  "Latitude": "53.754.605",
  "Longitude": "09.641.849",
  "Telephone": "04121-3232",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.754605,9.641849",
  "id": "53.754605,9.641849"
}, {
  "Brand": "TOTAL",
  "Street": "GOESCHWITZER STR. 1",
  "PostCode": 7745,
  "City": "JENA",
  "Latitude": "50.898.015",
  "Longitude": "11.593.384",
  "Telephone": "03641-215080",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.898015,11.593384",
  "id": "50.898015,11.593384"
}, {
  "Brand": "OMV",
  "Street": "Würzburger Str. 45",
  "PostCode": 97424,
  "City": "Schweinfurt",
  "Latitude": "50.024.380",
  "Longitude": "10.196.510",
  "Telephone": "+49-9721-82873",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.02438,10.19651",
  "id": "50.02438,10.19651"
}, {
  "Brand": "ARAL",
  "Street": "Dreher Str. 18",
  "PostCode": 42899,
  "City": "Remscheid",
  "Latitude": "51.222.835",
  "Longitude": "07.229.229",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.222835,7.229229",
  "id": "51.222835,7.229229"
}, {
  "Brand": "TOTAL",
  "Street": "DUNDENHEIMER STR. 31",
  "PostCode": 77743,
  "City": "NEURIED",
  "Latitude": "48.450.910",
  "Longitude": "07.816.408",
  "Telephone": "07807-887",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.45091,7.816408",
  "id": "48.45091,7.816408"
}, {
  "Brand": "TOTAL",
  "Street": "LINDENTHALER STR.61",
  "PostCode": 4155,
  "City": "LEIPZIG",
  "Latitude": "51.366.007",
  "Longitude": "12.366.082",
  "Telephone": "0341-5832105",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.366007,12.366082",
  "id": "51.366007,12.366082"
}, {
  "Brand": "TOTAL",
  "Street": "MURGTALSTR.89",
  "PostCode": 76571,
  "City": "GAGGENAU",
  "Latitude": "48.810.308",
  "Longitude": "08.310.143",
  "Telephone": "07225-5603",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.810308,8.310143",
  "id": "48.810308,8.310143"
}, {
  "Brand": "OMV",
  "Street": "Regensburger Str. 68",
  "PostCode": 84061,
  "City": "Ergoldsbach",
  "Latitude": "48.708.100",
  "Longitude": "12.194.390",
  "Telephone": "+49-8771-1329",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.7081,12.19439",
  "id": "48.7081,12.19439"
}, {
  "Brand": "ARAL",
  "Street": "Longericher Str. 207",
  "PostCode": 50739,
  "City": "Köln",
  "Latitude": "50.982.458",
  "Longitude": "06.919.810",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.982458,6.91981",
  "id": "50.982458,6.91981"
}, {
  "Brand": "TOTAL",
  "Street": "UERDINGER STR. 130",
  "PostCode": 47259,
  "City": "DUISBURG",
  "Latitude": "51.358.706",
  "Longitude": "06.687.479",
  "Telephone": "0203-781709",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.358706,6.687479",
  "id": "51.358706,6.687479"
}, {
  "Brand": "OMV",
  "Street": "Burgkirchener Str. 14",
  "PostCode": 84489,
  "City": "Burghausen",
  "Latitude": "48.177.550",
  "Longitude": "12.825.410",
  "Telephone": "+49-8677-2674",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.17755,12.82541",
  "id": "48.17755,12.82541"
}, {
  "Brand": "TOTAL",
  "Street": "LAUCHHAMMERSTR. 12",
  "PostCode": 1591,
  "City": "RIESA",
  "Latitude": "51.313.278",
  "Longitude": "13.289.316",
  "Telephone": "03525-733388",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.313278,13.289316",
  "id": "51.313278,13.289316"
}, {
  "Brand": "ARAL",
  "Street": "Borchener Straße336",
  "PostCode": 33106,
  "City": "Paderborn",
  "Latitude": "51.683.275",
  "Longitude": "08.721.109",
  "Telephone": "05251892905",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.683275,8.721109",
  "id": "51.683275,8.721109"
}, {
  "Brand": "ARAL",
  "Street": "A2",
  "PostCode": 33334,
  "City": "Gütersloh",
  "Latitude": "51.875.862",
  "Longitude": "08.418.065",
  "Telephone": "0524151600",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.875862,8.418065",
  "id": "51.875862,8.418065"
}, {
  "Brand": "OMV",
  "Street": "Bahnhofstr. 6 a",
  "PostCode": 97500,
  "City": "Ebelsbach",
  "Latitude": "49.984.540",
  "Longitude": "10.668.730",
  "Telephone": "+49-9522-708014",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.98454,10.66873",
  "id": "49.98454,10.66873"
}, {
  "Brand": "ENI",
  "Street": "Suedl.Muenchener Str. 24 A",
  "PostCode": 82031,
  "City": "Gruenwald",
  "Latitude": "48.044.654",
  "Longitude": "11.528.160",
  "Telephone": "+49 (0) 89641387",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.044654,11.52816",
  "id": "48.044654,11.52816"
}, {
  "Brand": "ENI",
  "Street": "Bamberger Strasse 39",
  "PostCode": 96135,
  "City": "Stegaurach",
  "Latitude": "49.862.959",
  "Longitude": "10.846.465",
  "Telephone": "+49 (0) 95129333",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.862959,10.846465",
  "id": "49.862959,10.846465"
}, {
  "Brand": "ENI",
  "Street": "Radeburger Str. 150",
  "PostCode": 1558,
  "City": "Grossenhain",
  "Latitude": "51.295.135",
  "Longitude": "13.543.415",
  "Telephone": "+49 (0) 35225023",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.295135,13.543415",
  "id": "51.295135,13.543415"
}, {
  "Brand": "TOTAL",
  "Street": "AM SCHACHENFELD 1",
  "PostCode": 83527,
  "City": "HAAG",
  "Latitude": "48.165.184",
  "Longitude": "12.163.585",
  "Telephone": "08072/370722",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.165184,12.163585",
  "id": "48.165184,12.163585"
}, {
  "Brand": "TOTAL",
  "Street": "BRANDENBURGISCHE STR. 3",
  "PostCode": 14974,
  "City": "LUDWIGSFELDE",
  "Latitude": "52.311.758",
  "Longitude": "13.243.392",
  "Telephone": "03378-870107",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.311758,13.243392",
  "id": "52.311758,13.243392"
}, {
  "Brand": "ARAL",
  "Street": "Carl-Benz-Straße 1",
  "PostCode": 61191,
  "City": "Rosbach vor der Höhe",
  "Latitude": "50.293.452",
  "Longitude": "08.693.699",
  "Telephone": "060038269661",
  "WeekDayFrom": "08:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.293452,8.693699",
  "id": "50.293452,8.693699"
}, {
  "Brand": "TOTAL",
  "Street": "PRORAER CHAUSSEE 8",
  "PostCode": 18609,
  "City": "BINZ",
  "Latitude": "54.402.414",
  "Longitude": "13.602.854",
  "Telephone": "0383931-44145",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.402414,13.602854",
  "id": "54.402414,13.602854"
}, {
  "Brand": "ARAL",
  "Street": "Paradiesstr. 17",
  "PostCode": 52349,
  "City": "Düren",
  "Latitude": "50.806.112",
  "Longitude": "06.472.191",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.806112,6.472191",
  "id": "50.806112,6.472191"
}, {
  "Brand": "TOTAL",
  "Street": "FRAUNHOFER STR.3",
  "PostCode": 93092,
  "City": "BARBING",
  "Latitude": "48.995.680",
  "Longitude": "12.235.221",
  "Telephone": "09401-5278911",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.99568,12.235221",
  "id": "48.99568,12.235221"
}, {
  "Brand": "ARAL",
  "Street": "Benzstraße 7",
  "PostCode": 64646,
  "City": "Heppenheim",
  "Latitude": "49.647.519",
  "Longitude": "08.624.698",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.647519,8.624698",
  "id": "49.647519,8.624698"
}, {
  "Brand": "TOTAL",
  "Street": "HAUPTSTR. 258",
  "PostCode": 66287,
  "City": "QUIERSCHIED",
  "Latitude": "49.341.651",
  "Longitude": "07.020.608",
  "Telephone": "06825-5306",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.341651,7.020608",
  "id": "49.341651,7.020608"
}, {
  "Brand": "TOTAL",
  "Street": "HILDBURGHAUSER STR. 222",
  "PostCode": 12209,
  "City": "BERLIN",
  "Latitude": "52.419.154",
  "Longitude": "13.319.371",
  "Telephone": "030-76687462",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.419154,13.319371",
  "id": "52.419154,13.319371"
}, {
  "Brand": "TOTAL",
  "Street": "HAUPTSTRASSE 121",
  "PostCode": 98553,
  "City": "SCHLEUSINGEN OT WALDAU",
  "Latitude": "50.504.367",
  "Longitude": "10.833.668",
  "Telephone": "036878-61521",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.504367,10.833668",
  "id": "50.504367,10.833668"
}, {
  "Brand": "OMV",
  "Street": "Mühldorfer Str. 45",
  "PostCode": 84539,
  "City": "Ampfing",
  "Latitude": "48.256.610",
  "Longitude": "12.424.400",
  "Telephone": "+49-8636-1051",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.25661,12.4244",
  "id": "48.25661,12.4244"
}, {
  "Brand": "TOTAL",
  "Street": "BORNSTR.266",
  "PostCode": 44145,
  "City": "DORTMUND",
  "Latitude": "51.535.715",
  "Longitude": "07.474.473",
  "Telephone": "0231-818218",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.535715,7.474473",
  "id": "51.535715,7.474473"
}, {
  "Brand": "TOTAL",
  "Street": "WALLDORFER STRASSE 59",
  "PostCode": 98617,
  "City": "HERPF",
  "Latitude": "50.583.495",
  "Longitude": "10.339.036",
  "Telephone": "036943-63204",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.583495,10.339036",
  "id": "50.583495,10.339036"
}, {
  "Brand": "ARAL",
  "Street": "Freisinger Str. 27",
  "PostCode": 85386,
  "City": "Eching",
  "Latitude": "48.307.656",
  "Longitude": "11.647.054",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.307656,11.647054",
  "id": "48.307656,11.647054"
}, {
  "Brand": "TOTAL",
  "Street": "GROSSBEERENSTR. 137",
  "PostCode": 14482,
  "City": "POTSDAM",
  "Latitude": "52.385.799",
  "Longitude": "13.108.893",
  "Telephone": "0331-7482098",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.385799,13.108893",
  "id": "52.385799,13.108893"
}, {
  "Brand": "ARAL",
  "Street": "Äußere-Kanal-Straße 90",
  "PostCode": 50827,
  "City": "Köln",
  "Latitude": "50.958.536",
  "Longitude": "06.905.212",
  "Telephone": "0221551000",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.958536,6.905212",
  "id": "50.958536,6.905212"
}, {
  "Brand": "ARAL",
  "Street": "Kleine Eichen 1 a",
  "PostCode": 34355,
  "City": "Staufenberg",
  "Latitude": "51.375.357",
  "Longitude": "09.628.774",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.375357,9.628774",
  "id": "51.375357,9.628774"
}, {
  "Brand": "TOTAL",
  "Street": "A71 RASTANLAGE MELLRICHSTAEDTER HOE",
  "PostCode": 97638,
  "City": "MELLRICHSTADT",
  "Latitude": "50.421.360",
  "Longitude": "10.349.020",
  "Telephone": "097767090343",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.42136,10.34902",
  "id": "50.42136,10.34902"
}, {
  "Brand": "ARAL",
  "Street": "Nikolaus-Otto-Str.",
  "PostCode": 41515,
  "City": "Grevenbroich",
  "Latitude": "51.085.660",
  "Longitude": "06.618.867",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.08566,6.618867",
  "id": "51.08566,6.618867"
}, {
  "Brand": "TOTAL",
  "Street": "LEIPZIGER STR. 87",
  "PostCode": 4600,
  "City": "ALTENBURG",
  "Latitude": "51.008.008",
  "Longitude": "12.453.298",
  "Telephone": "03447-836804",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.008008,12.453298",
  "id": "51.008008,12.453298"
}, {
  "Brand": "Westfalen",
  "Street": "Rolshover Str. 380",
  "PostCode": 51105,
  "City": "KOELN",
  "Latitude": "50.920.500",
  "Longitude": "06.998.830",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.9205,6.99883",
  "id": "50.9205,6.99883"
}, {
  "Brand": "TOTAL",
  "Street": "DELITZSCHER STR. 36",
  "PostCode": 6112,
  "City": "HALLE",
  "Latitude": "51.478.518",
  "Longitude": "11.996.009",
  "Telephone": "0345-5606281",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.478518,11.996009",
  "id": "51.478518,11.996009"
}, {
  "Brand": "TOTAL",
  "Street": "HEERSTR.270",
  "PostCode": 47053,
  "City": "DUISBURG",
  "Latitude": "51.415.090",
  "Longitude": "06.762.589",
  "Telephone": "0203-63355",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.41509,6.762589",
  "id": "51.41509,6.762589"
}, {
  "Brand": "Westfalen",
  "Street": "Pothof 14",
  "PostCode": 48301,
  "City": "NOTTULN",
  "Latitude": "51.927.900",
  "Longitude": "07.353.340",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9279,7.35334",
  "id": "51.9279,7.35334"
}, {
  "Brand": "Westfalen",
  "Street": "Rheindeichstr. 20",
  "PostCode": 47198,
  "City": "DUISBURG",
  "Latitude": "51.457.200",
  "Longitude": "06.713.490",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.4572,6.71349",
  "id": "51.4572,6.71349"
}, {
  "Brand": "ARAL",
  "Street": "Siegener Straße 10",
  "PostCode": 57548,
  "City": "Kirchen",
  "Latitude": "50.812.199",
  "Longitude": "07.880.153",
  "Telephone": "0274161497",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "08:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.812199,7.880153",
  "id": "50.812199,7.880153"
}, {
  "Brand": "OMV",
  "Street": "Crailsheimer Straße 32",
  "PostCode": 74532,
  "City": "Ilshofen",
  "Latitude": "49.171.290",
  "Longitude": "09.926.720",
  "Telephone": "+49-7904-237",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.17129,9.92672",
  "id": "49.17129,9.92672"
}, {
  "Brand": "OMV",
  "Street": "Landsberger Str. 43",
  "PostCode": 86179,
  "City": "Augsburg",
  "Latitude": "48.311.280",
  "Longitude": "10.906.830",
  "Telephone": "+49-821-83811",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.31128,10.90683",
  "id": "48.31128,10.90683"
}, {
  "Brand": "TOTAL",
  "Street": "AEPPELALLEE 39",
  "PostCode": 65203,
  "City": "WIESBADEN",
  "Latitude": "50.047.652",
  "Longitude": "08.231.238",
  "Telephone": "06116010537",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.047652,8.231238",
  "id": "50.047652,8.231238"
}, {
  "Brand": "ARAL",
  "Street": "Hermann-Böcker-Straße 13",
  "PostCode": 82140,
  "City": "Olching",
  "Latitude": "48.221.370",
  "Longitude": "11.329.926",
  "Telephone": "081424101927",
  "WeekDayFrom": "07:30",
  "WeekDayTo": "20:30",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:30",
  "SundayFrom": "00:00",
  "SundayTo": "00:01",
  "IsOpen24Hours": "Nein",
  "location": "48.22137,11.329926",
  "id": "48.22137,11.329926"
}, {
  "Brand": "ARAL",
  "Street": "Alter Postweg 4-6",
  "PostCode": 68309,
  "City": "Mannheim",
  "Latitude": "49.515.964",
  "Longitude": "08.520.571",
  "Telephone": "06217163939",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.515964,8.520571",
  "id": "49.515964,8.520571"
}, {
  "Brand": "TOTAL",
  "Street": "BAB A9 PEGNITZ WEST",
  "PostCode": 91257,
  "City": "PEGNITZ",
  "Latitude": "49.749.645",
  "Longitude": "11.510.718",
  "Telephone": "092414944539",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.749645,11.510718",
  "id": "49.749645,11.510718"
}, {
  "Brand": "ARAL",
  "Street": "Otto-Hahn-Str. 16",
  "PostCode": 35510,
  "City": "Butzbach",
  "Latitude": "50.430.671",
  "Longitude": "08.687.623",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.430671,8.687623",
  "id": "50.430671,8.687623"
}, {
  "Brand": "TOTAL",
  "Street": "BRITZER DAMM 60-62",
  "PostCode": 12347,
  "City": "BERLIN",
  "Latitude": "52.457.048",
  "Longitude": "13.436.356",
  "Telephone": "030/62981575",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.457048,13.436356",
  "id": "52.457048,13.436356"
}, {
  "Brand": "ARAL",
  "Street": "Carl-Benz-Straße 20-24",
  "PostCode": 78467,
  "City": "Konstanz",
  "Latitude": "47.677.586",
  "Longitude": "09.147.051",
  "Telephone": "07531694090",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "47.677586,9.147051",
  "id": "47.677586,9.147051"
}, {
  "Brand": "TOTAL",
  "Street": "PADERBORNER STR. 78",
  "PostCode": 33189,
  "City": "SCHLANGEN",
  "Latitude": "51.800.774",
  "Longitude": "08.837.084",
  "Telephone": "05252-7109",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.800774,8.837084",
  "id": "51.800774,8.837084"
}, {
  "Brand": "TOTAL",
  "Street": "NEBELSCHUETZER STR.4",
  "PostCode": 1917,
  "City": "KAMENZ",
  "Latitude": "51.263.299",
  "Longitude": "14.116.393",
  "Telephone": "03578/309435",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.263299,14.116393",
  "id": "51.263299,14.116393"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Straße 111",
  "PostCode": 51063,
  "City": "Köln",
  "Latitude": "50.973.797",
  "Longitude": "07.012.554",
  "Telephone": "022196426619",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.973797,7.012554",
  "id": "50.973797,7.012554"
}, {
  "Brand": "ENI",
  "Street": "Leinstrae 11",
  "PostCode": 30159,
  "City": "Hannover",
  "Latitude": "52.369.609",
  "Longitude": "09.735.563",
  "Telephone": "+49 (0) 51136373",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.369609,9.735563",
  "id": "52.369609,9.735563"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 79-81",
  "PostCode": 69151,
  "City": "Neckargemünd",
  "Latitude": "49.392.458",
  "Longitude": "08.779.826",
  "Telephone": "062231244",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "05:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.392458,8.779826",
  "id": "49.392458,8.779826"
}, {
  "Brand": "TOTAL",
  "Street": "HUEBLERSTR. 36",
  "PostCode": 1309,
  "City": "DRESDEN",
  "Latitude": "51.049.195",
  "Longitude": "13.793.189",
  "Telephone": "0351-40425070",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.049195,13.793189",
  "id": "51.049195,13.793189"
}, {
  "Brand": "ENI",
  "Street": "Wuerzburger Strasse 37",
  "PostCode": 97199,
  "City": "Ochsenfurt",
  "Latitude": "49.668.672",
  "Longitude": "10.064.886",
  "Telephone": "+49 (0) 93313125",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.668672,10.064886",
  "id": "49.668672,10.064886"
}, {
  "Brand": "OMV",
  "Street": "Ungererstr. 46-50",
  "PostCode": 80802,
  "City": "München",
  "Latitude": "48.166.050",
  "Longitude": "11.590.020",
  "Telephone": "+49-89-393888",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.16605,11.59002",
  "id": "48.16605,11.59002"
}, {
  "Brand": "OMV",
  "Street": "Hindenburgring 40",
  "PostCode": 61348,
  "City": "Bad Homburg",
  "Latitude": "50.227.320",
  "Longitude": "08.605.440",
  "Telephone": "+49-6172-934712",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.22732,8.60544",
  "id": "50.22732,8.60544"
}, {
  "Brand": "ARAL",
  "Street": "Bertha-Benz-Straße",
  "PostCode": 26160,
  "City": "Bad Zwischenahn",
  "Latitude": "53.182.925",
  "Longitude": "08.073.517",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.182925,8.073517",
  "id": "53.182925,8.073517"
}, {
  "Brand": "TOTAL",
  "Street": "DAIMLER STR. 1",
  "PostCode": 71546,
  "City": "ASPACH",
  "Latitude": "48.971.858",
  "Longitude": "09.387.147",
  "Telephone": "07191-3678024",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.971858,9.387147",
  "id": "48.971858,9.387147"
}, {
  "Brand": "ENI",
  "Street": "Leipziger Str./B 93",
  "PostCode": 4600,
  "City": "Altenburg",
  "Latitude": "51.011.086",
  "Longitude": "12.454.124",
  "Telephone": "+49 (0) 34478343",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.011086,12.454124",
  "id": "51.011086,12.454124"
}, {
  "Brand": "Westfalen",
  "Street": "Duerener Str. 388-392",
  "PostCode": 50935,
  "City": "KOELN",
  "Latitude": "50.925.500",
  "Longitude": "06.895.430",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.9255,6.89543",
  "id": "50.9255,6.89543"
}, {
  "Brand": "Westfalen",
  "Street": "Lemgoer Str. 15",
  "PostCode": 32756,
  "City": "DETMOLD",
  "Latitude": "51.943.600",
  "Longitude": "08.878.370",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9436,8.87837",
  "id": "51.9436,8.87837"
}, {
  "Brand": "ENI",
  "Street": "Freyburger Str. 17",
  "PostCode": 6132,
  "City": "Halle",
  "Latitude": "51.444.690",
  "Longitude": "11.969.940",
  "Telephone": "+49 (0) 34577030",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.44469,11.96994",
  "id": "51.44469,11.96994"
}, {
  "Brand": "ENI",
  "Street": "Rangaustr. 4A",
  "PostCode": 90530,
  "City": "Wendelstein",
  "Latitude": "49.345.870",
  "Longitude": "11.111.180",
  "Telephone": "+49 (0) 91298929",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.34587,11.11118",
  "id": "49.34587,11.11118"
}, {
  "Brand": "ENI",
  "Street": "Hauptstr.",
  "PostCode": 73553,
  "City": "Alfdorf",
  "Latitude": "48.842.520",
  "Longitude": "09.708.996",
  "Telephone": "+49 (0) 71729399",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.84252,9.708996",
  "id": "48.84252,9.708996"
}, {
  "Brand": "OMV",
  "Street": "Münchner Str. 17",
  "PostCode": 85391,
  "City": "Allershausen",
  "Latitude": "48.430.060",
  "Longitude": "11.594.280",
  "Telephone": "+49-8166-1604",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.43006,11.59428",
  "id": "48.43006,11.59428"
}, {
  "Brand": "ENI",
  "Street": "Hedelfinger Str. 73",
  "PostCode": 70327,
  "City": "Stuttgart",
  "Latitude": "48.766.080",
  "Longitude": "09.249.850",
  "Telephone": "+49 (0) 71142246",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.76608,9.24985",
  "id": "48.76608,9.24985"
}, {
  "Brand": "Westfalen",
  "Street": "Dinxperloer Str. 55",
  "PostCode": 46399,
  "City": "BOCHOLT",
  "Latitude": "51.842.900",
  "Longitude": "06.605.890",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8429,6.60589",
  "id": "51.8429,6.60589"
}, {
  "Brand": "Westfalen",
  "Street": "Breite Str. 173 a",
  "PostCode": 26919,
  "City": "BRAKE",
  "Latitude": "53.325.000",
  "Longitude": "08.464.380",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.325,8.46438",
  "id": "53.325,8.46438"
}, {
  "Brand": "ENI",
  "Street": "Leibnizstr. 24",
  "PostCode": 74211,
  "City": "Leingarten",
  "Latitude": "49.143.830",
  "Longitude": "09.126.610",
  "Telephone": "07131-3994325",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.14383,9.12661",
  "id": "49.14383,9.12661"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 1",
  "PostCode": 83324,
  "City": "Ruhpolding",
  "Latitude": "47.766.101",
  "Longitude": "12.640.801",
  "Telephone": "08663419603",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.766101,12.640801",
  "id": "47.766101,12.640801"
}, {
  "Brand": "OMV",
  "Street": "Wendling 14",
  "PostCode": 83737,
  "City": "Irschenberg",
  "Latitude": "47.826.750",
  "Longitude": "11.898.250",
  "Telephone": "+49-8025-281103",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "47.82675,11.89825",
  "id": "47.82675,11.89825"
}, {
  "Brand": "ENI",
  "Street": "An der B80",
  "PostCode": 6313,
  "City": "Wimmelburg",
  "Latitude": "51.519.300",
  "Longitude": "11.510.520",
  "Telephone": "03475/637393",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.5193,11.51052",
  "id": "51.5193,11.51052"
}, {
  "Brand": "Westfalen",
  "Street": "Stedinger Landstr. 21",
  "PostCode": 27753,
  "City": "DELMENHORST",
  "Latitude": "53.086.000",
  "Longitude": "08.650.380",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.086,8.65038",
  "id": "53.086,8.65038"
}, {
  "Brand": "TOTAL",
  "Street": "GREIFSWALDER CHAUSSEE 4",
  "PostCode": 18507,
  "City": "GRIMMEN",
  "Latitude": "54.109.253",
  "Longitude": "13.056.230",
  "Telephone": "038326-2134",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.109253,13.05623",
  "id": "54.109253,13.05623"
}, {
  "Brand": "ARAL",
  "Street": "Gießener Straße 60",
  "PostCode": 34560,
  "City": "Fritzlar",
  "Latitude": "51.120.846",
  "Longitude": "09.277.641",
  "Telephone": "05622930418",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.120846,9.277641",
  "id": "51.120846,9.277641"
}, {
  "Brand": "TOTAL",
  "Street": "MALCHINER STR. 81",
  "PostCode": 17153,
  "City": "STAVENHAGEN",
  "Latitude": "53.700.237",
  "Longitude": "12.897.694",
  "Telephone": "039954-22175",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.700237,12.897694",
  "id": "53.700237,12.897694"
}, {
  "Brand": "ARAL",
  "Street": "Landstraße 32/B 57",
  "PostCode": 52441,
  "City": "Linnich",
  "Latitude": "50.959.829",
  "Longitude": "06.224.484",
  "Telephone": "024628420",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.959829,6.224484",
  "id": "50.959829,6.224484"
}, {
  "Brand": "ENI",
  "Street": "Lenggrieser Strasse 46",
  "PostCode": 83646,
  "City": "Bad Toelz",
  "Latitude": "47.746.501",
  "Longitude": "11.566.682",
  "Telephone": "+49 (0) 80418147",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.746501,11.566682",
  "id": "47.746501,11.566682"
}, {
  "Brand": "ARAL",
  "Street": "Albert-Vietel-Straße 4",
  "PostCode": 9217,
  "City": "Burgstädt",
  "Latitude": "50.898.432",
  "Longitude": "12.816.212",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.898432,12.816212",
  "id": "50.898432,12.816212"
}, {
  "Brand": "ENI",
  "Street": "Westhafenstrasse 1",
  "PostCode": 13353,
  "City": "Berlin",
  "Latitude": "52.538.284",
  "Longitude": "13.344.087",
  "Telephone": "+49 (0) 30395361",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.538284,13.344087",
  "id": "52.538284,13.344087"
}, {
  "Brand": "Westfalen",
  "Street": "Bottroper Str. 184",
  "PostCode": 46244,
  "City": "BOTTROP",
  "Latitude": "51.570.200",
  "Longitude": "06.910.890",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.5702,6.91089",
  "id": "51.5702,6.91089"
}, {
  "Brand": "Westfalen",
  "Street": "Teutoburger-Wald-Str. 45",
  "PostCode": 49124,
  "City": "GEORGSMARIENHUETTE",
  "Latitude": "52.190.100",
  "Longitude": "08.068.730",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.1901,8.06873",
  "id": "52.1901,8.06873"
}, {
  "Brand": "TOTAL",
  "Street": "NIROSTASTR. 10",
  "PostCode": 47807,
  "City": "KREFELD",
  "Latitude": "51.289.043",
  "Longitude": "06.554.007",
  "Telephone": "02151-9372354",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.289043,6.554007",
  "id": "51.289043,6.554007"
}, {
  "Brand": "ARAL",
  "Street": "Kurt-Schumacher-Ring 4",
  "PostCode": 63329,
  "City": "Egelsbach",
  "Latitude": "49.961.108",
  "Longitude": "08.671.470",
  "Telephone": "06103/8311830",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "49.961108,8.67147",
  "id": "49.961108,8.67147"
}, {
  "Brand": "ENI",
  "Street": "Grossingersheimer Strasse",
  "PostCode": 74321,
  "City": "Bietigheim-Bissingen",
  "Latitude": "48.959.153",
  "Longitude": "09.133.435",
  "Telephone": "+49 (0) 71425343",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.959153,9.133435",
  "id": "48.959153,9.133435"
}, {
  "Brand": "Westfalen",
  "Street": "Schiffahrter Damm 506",
  "PostCode": 48157,
  "City": "MUENSTER",
  "Latitude": "52.010.400",
  "Longitude": "07.679.150",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0104,7.67915",
  "id": "52.0104,7.67915"
}, {
  "Brand": "Westfalen",
  "Street": "Weseler Str. 212-216",
  "PostCode": 48151,
  "City": "MUENSTER",
  "Latitude": "51.944.000",
  "Longitude": "07.611.720",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.944,7.61172",
  "id": "51.944,7.61172"
}, {
  "Brand": "ARAL",
  "Street": "Rheinstraße 50",
  "PostCode": 57632,
  "City": "Flammersfeld",
  "Latitude": "50.648.258",
  "Longitude": "07.527.139",
  "Telephone": "026851508",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.648258,7.527139",
  "id": "50.648258,7.527139"
}, {
  "Brand": "TOTAL",
  "Street": "5 AM BAHNHOF LIENEWITZ",
  "PostCode": 14548,
  "City": "SCHWIELOWSEE / OT FERCH",
  "Latitude": "52.303.567",
  "Longitude": "12.970.627",
  "Telephone": "033205757972",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.303567,12.970627",
  "id": "52.303567,12.970627"
}, {
  "Brand": "TOTAL",
  "Street": "AUTOHOF KRONSKAMP 3",
  "PostCode": 30890,
  "City": "BARSINGHAUSEN",
  "Latitude": "52.333.197",
  "Longitude": "09.414.628",
  "Telephone": "05105-514923",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.333197,9.414628",
  "id": "52.333197,9.414628"
}, {
  "Brand": "ARAL",
  "Street": "Am Schornacker 47",
  "PostCode": 46485,
  "City": "Wesel",
  "Latitude": "51.661.999",
  "Longitude": "06.701.407",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.661999,6.701407",
  "id": "51.661999,6.701407"
}, {
  "Brand": "TOTAL",
  "Street": "HOMBERGER STR. 5",
  "PostCode": 36251,
  "City": "BAD HERSFELD",
  "Latitude": "50.874.171",
  "Longitude": "09.705.140",
  "Telephone": "06621-917756",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.874171,9.70514",
  "id": "50.874171,9.70514"
}, {
  "Brand": "TOTAL",
  "Street": "DAETZINGER STR. 36",
  "PostCode": 71120,
  "City": "GRAFENAU",
  "Latitude": "48.714.914",
  "Longitude": "08.900.578",
  "Telephone": "07033-43950",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.714914,8.900578",
  "id": "48.714914,8.900578"
}, {
  "Brand": "TOTAL",
  "Street": "VOGELSANGER STR.37",
  "PostCode": 58300,
  "City": "WETTER",
  "Latitude": "51.360.818",
  "Longitude": "07.375.968",
  "Telephone": "02335-60371",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.360818,7.375968",
  "id": "51.360818,7.375968"
}, {
  "Brand": "TOTAL",
  "Street": "SUEDRING 60",
  "PostCode": 40223,
  "City": "DUESSELDORF",
  "Latitude": "51.200.071",
  "Longitude": "06.767.971",
  "Telephone": "0211-151643",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.200071,6.767971",
  "id": "51.200071,6.767971"
}, {
  "Brand": "TOTAL",
  "Street": "ZOSSENER DAMM 19",
  "PostCode": 15827,
  "City": "BLANKENFELDE",
  "Latitude": "52.330.122",
  "Longitude": "13.404.113",
  "Telephone": "03379-379940",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.330122,13.404113",
  "id": "52.330122,13.404113"
}, {
  "Brand": "OMV",
  "Street": "Richtfeld 1",
  "PostCode": 92442,
  "City": "Wackersdorf",
  "Latitude": "49.313.590",
  "Longitude": "12.158.620",
  "Telephone": "+49-9431-7979145",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.31359,12.15862",
  "id": "49.31359,12.15862"
}, {
  "Brand": "TOTAL",
  "Street": "UMGEHUNGSSTR. 18",
  "PostCode": 1723,
  "City": "WILSDRUFF",
  "Latitude": "51.054.980",
  "Longitude": "13.538.353",
  "Telephone": "035204-61992",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.05498,13.538353",
  "id": "51.05498,13.538353"
}, {
  "Brand": "TOTAL",
  "Street": "DUDWEILER STR. 111",
  "PostCode": 66111,
  "City": "SAARBRUECKEN",
  "Latitude": "49.240.977",
  "Longitude": "07.001.475",
  "Telephone": "0681-34507",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.240977,7.001475",
  "id": "49.240977,7.001475"
}, {
  "Brand": "OMV",
  "Street": "Hauptstr. 61",
  "PostCode": 73466,
  "City": "Lauchheim",
  "Latitude": "48.871.080",
  "Longitude": "10.241.360",
  "Telephone": "+49-7363-5341",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.87108,10.24136",
  "id": "48.87108,10.24136"
}, {
  "Brand": "TOTAL",
  "Street": "FRIEDRICHSTR.1",
  "PostCode": 99310,
  "City": "ARNSTADT",
  "Latitude": "50.840.320",
  "Longitude": "10.956.505",
  "Telephone": "03628-76218",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.84032,10.956505",
  "id": "50.84032,10.956505"
}, {
  "Brand": "ARAL",
  "Street": "A3",
  "PostCode": 46569,
  "City": "Hünxe",
  "Latitude": "51.635.378",
  "Longitude": "06.747.470",
  "Telephone": "02858503",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.635378,6.74747",
  "id": "51.635378,6.74747"
}, {
  "Brand": "ENI",
  "Street": "Bachstr. 1",
  "PostCode": 93455,
  "City": "Traitsching",
  "Latitude": "49.152.760",
  "Longitude": "12.645.940",
  "Telephone": "+49 (0) 9974395",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.15276,12.64594",
  "id": "49.15276,12.64594"
}, {
  "Brand": "OMV",
  "Street": "Ernst-Reuter-Str. 166",
  "PostCode": 95030,
  "City": "Hof/Saale",
  "Latitude": "50.326.100",
  "Longitude": "11.892.880",
  "Telephone": "+49-9281-64899",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.3261,11.89288",
  "id": "50.3261,11.89288"
}, {
  "Brand": "Westfalen",
  "Street": "Armlandstrasse 22",
  "PostCode": 48712,
  "City": "GESCHER",
  "Latitude": "51.957.000",
  "Longitude": "07.001.320",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.957,7.00132",
  "id": "51.957,7.00132"
}, {
  "Brand": "ARAL",
  "Street": "An der Kleinbahn 5",
  "PostCode": 41334,
  "City": "Nettetal",
  "Latitude": "51.328.647",
  "Longitude": "06.196.333",
  "Telephone": "021578958775",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.328647,6.196333",
  "id": "51.328647,6.196333"
}, {
  "Brand": "TOTAL",
  "Street": "GOLDBACH SUED/ A 27",
  "PostCode": 27299,
  "City": "LANGWEDEL",
  "Latitude": "52.999.166",
  "Longitude": "09.180.315",
  "Telephone": "04232-306020",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.999166,9.180315",
  "id": "52.999166,9.180315"
}, {
  "Brand": "Westfalen",
  "Street": "Brockbachstr. 20",
  "PostCode": 49492,
  "City": "WESTERKAPPELN N",
  "Latitude": "52.261.223",
  "Longitude": "07.846.699",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.261223,7.846699",
  "id": "52.261223,7.846699"
}, {
  "Brand": "TOTAL",
  "Street": "KOELNER STR. 86",
  "PostCode": 57439,
  "City": "ATTENDORN",
  "Latitude": "51.118.728",
  "Longitude": "07.893.328",
  "Telephone": "02722-3235",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.118728,7.893328",
  "id": "51.118728,7.893328"
}, {
  "Brand": "TOTAL",
  "Street": "GEWERBESTR. 4",
  "PostCode": 99880,
  "City": "LAUCHA",
  "Latitude": "50.922.497",
  "Longitude": "10.552.224",
  "Telephone": "03622-440211",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.922497,10.552224",
  "id": "50.922497,10.552224"
}, {
  "Brand": "TOTAL",
  "Street": "BRAUNSCHWEIGER STR.137",
  "PostCode": 38518,
  "City": "GIFHORN",
  "Latitude": "52.465.322",
  "Longitude": "10.542.578",
  "Telephone": "05371-3455",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.465322,10.542578",
  "id": "52.465322,10.542578"
}, {
  "Brand": "TOTAL",
  "Street": "TAUNUSSTR. 1",
  "PostCode": 61191,
  "City": "ROSBACH",
  "Latitude": "50.307.008",
  "Longitude": "08.697.208",
  "Telephone": "06003-1203",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.307008,8.697208",
  "id": "50.307008,8.697208"
}, {
  "Brand": "Westfalen",
  "Street": "Polsumer Str. 236",
  "PostCode": 45896,
  "City": "GELSENKIRCHEN",
  "Latitude": "51.607.400",
  "Longitude": "07.048.980",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.6074,7.04898",
  "id": "51.6074,7.04898"
}, {
  "Brand": "TOTAL",
  "Street": "DARMSTAEDTER LANDSTR 2",
  "PostCode": 64569,
  "City": "NAUHEIM",
  "Latitude": "49.939.078",
  "Longitude": "08.448.889",
  "Telephone": "06152-64953",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.939078,8.448889",
  "id": "49.939078,8.448889"
}, {
  "Brand": "TOTAL",
  "Street": "ZUM HANSA-CENTER 2",
  "PostCode": 49451,
  "City": "HOLDORF",
  "Latitude": "52.597.440",
  "Longitude": "08.118.027",
  "Telephone": "05494-9159243",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.59744,8.118027",
  "id": "52.59744,8.118027"
}, {
  "Brand": "ARAL",
  "Street": "Donauwörther Straße 63",
  "PostCode": 86653,
  "City": "Monheim",
  "Latitude": "48.836.726",
  "Longitude": "10.845.195",
  "Telephone": "090911863",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:30",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.836726,10.845195",
  "id": "48.836726,10.845195"
}, {
  "Brand": "TOTAL",
  "Street": "PRAGER STRASSE 420",
  "PostCode": 4288,
  "City": "LEIPZIG",
  "Latitude": "51.290.829",
  "Longitude": "12.444.689",
  "Telephone": "034297-42240",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.290829,12.444689",
  "id": "51.290829,12.444689"
}, {
  "Brand": "ARAL",
  "Street": "Gewerbepark B 3",
  "PostCode": 93086,
  "City": "Wörth an der Donau",
  "Latitude": "49.000.640",
  "Longitude": "12.364.157",
  "Telephone": "094829080210",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.00064,12.364157",
  "id": "49.00064,12.364157"
}, {
  "Brand": "ARAL",
  "Street": "Friedrich-Pogge-Weg 60",
  "PostCode": 18273,
  "City": "Güstrow",
  "Latitude": "53.772.872",
  "Longitude": "12.166.508",
  "Telephone": "03843334848",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "53.772872,12.166508",
  "id": "53.772872,12.166508"
}, {
  "Brand": "Westfalen",
  "Street": "Muensterdamm 35",
  "PostCode": 48366,
  "City": "LAER",
  "Latitude": "52.056.800",
  "Longitude": "07.364.720",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0568,7.36472",
  "id": "52.0568,7.36472"
}, {
  "Brand": "TOTAL",
  "Street": "AUGUST-BEBEL-STR. 31",
  "PostCode": 7743,
  "City": "JENA",
  "Latitude": "50.932.023",
  "Longitude": "11.572.180",
  "Telephone": "03641-820879",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.932023,11.57218",
  "id": "50.932023,11.57218"
}, {
  "Brand": "ENI",
  "Street": "Wolfratshauser Str. 232",
  "PostCode": 81479,
  "City": "Muenchen",
  "Latitude": "48.075.759",
  "Longitude": "11.523.800",
  "Telephone": "+49 (0) 89791228",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.075759,11.5238",
  "id": "48.075759,11.5238"
}, {
  "Brand": "TOTAL",
  "Street": "FLOEHATALSTR. 2A",
  "PostCode": 9509,
  "City": "POCKAU-LENGEFELD",
  "Latitude": "50.708.702",
  "Longitude": "13.219.793",
  "Telephone": "037367-9659",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.708702,13.219793",
  "id": "50.708702,13.219793"
}, {
  "Brand": "ENI",
  "Street": "Hauptstr. 40",
  "PostCode": 96275,
  "City": "Marktzeuln",
  "Latitude": "50.160.350",
  "Longitude": "11.184.670",
  "Telephone": "09574/505",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.16035,11.18467",
  "id": "50.16035,11.18467"
}, {
  "Brand": "ENI",
  "Street": "Loewenberger Strasse 135",
  "PostCode": 90475,
  "City": "Nuernberg",
  "Latitude": "49.411.120",
  "Longitude": "11.171.170",
  "Telephone": "+49 (0) 91183475",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.41112,11.17117",
  "id": "49.41112,11.17117"
}, {
  "Brand": "ARAL",
  "Street": "A8",
  "PostCode": 83451,
  "City": "Piding",
  "Latitude": "47.768.054",
  "Longitude": "12.942.177",
  "Telephone": "086512750",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.768054,12.942177",
  "id": "47.768054,12.942177"
}, {
  "Brand": "Westfalen",
  "Street": "Horster Str. 189",
  "PostCode": 45897,
  "City": "GELSENKIRCHEN",
  "Latitude": "51.561.400",
  "Longitude": "07.046.440",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.5614,7.04644",
  "id": "51.5614,7.04644"
}, {
  "Brand": "ENI",
  "Street": "Pacelli-Allee 1 A",
  "PostCode": 36043,
  "City": "Fulda",
  "Latitude": "50.547.080",
  "Longitude": "09.702.890",
  "Telephone": "+49 (0) 66163985",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.54708,9.70289",
  "id": "50.54708,9.70289"
}, {
  "Brand": "OMV",
  "Street": "Weichengereuth 23",
  "PostCode": 96450,
  "City": "Coburg",
  "Latitude": "50.247.120",
  "Longitude": "10.960.840",
  "Telephone": "+49-9561-39474",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.24712,10.96084",
  "id": "50.24712,10.96084"
}, {
  "Brand": "ENI",
  "Street": "Dillinger Strasse 34",
  "PostCode": 86660,
  "City": "Tapfheim",
  "Latitude": "48.679.822",
  "Longitude": "10.699.827",
  "Telephone": "+49 (0) 90709217",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.679822,10.699827",
  "id": "48.679822,10.699827"
}, {
  "Brand": "ENI",
  "Street": "Waiblinger Strasse 67",
  "PostCode": 71364,
  "City": "Winnenden",
  "Latitude": "48.873.200",
  "Longitude": "09.388.950",
  "Telephone": "+49 (0) 71951740",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.8732,9.38895",
  "id": "48.8732,9.38895"
}, {
  "Brand": "ENI",
  "Street": "Nuernberger Strasse 18",
  "PostCode": 70374,
  "City": "Stuttgart",
  "Latitude": "48.809.840",
  "Longitude": "09.251.330",
  "Telephone": "+49 (0) 71152745",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.80984,9.25133",
  "id": "48.80984,9.25133"
}, {
  "Brand": "TOTAL",
  "Street": "ULMER STR. 206",
  "PostCode": 70188,
  "City": "STUTTGART",
  "Latitude": "48.779.397",
  "Longitude": "09.230.689",
  "Telephone": "0711-423388",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.779397,9.230689",
  "id": "48.779397,9.230689"
}, {
  "Brand": "ARAL",
  "Street": "Mindelheimer Straße 1",
  "PostCode": 86842,
  "City": "Türkheim",
  "Latitude": "48.043.158",
  "Longitude": "10.620.659",
  "Telephone": "08245904530",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.043158,10.620659",
  "id": "48.043158,10.620659"
}, {
  "Brand": "ARAL",
  "Street": "ALBERT-EINSTEIN-STR. 1 a",
  "PostCode": 97080,
  "City": "WÜRZBURG",
  "Latitude": "49.808.427",
  "Longitude": "09.886.323",
  "Telephone": "093140409720",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.808427,9.886323",
  "id": "49.808427,9.886323"
}, {
  "Brand": "TOTAL",
  "Street": "HEIDELBERGER STR.71",
  "PostCode": 64385,
  "City": "REICHELSHEIM",
  "Latitude": "49.710.188",
  "Longitude": "08.833.537",
  "Telephone": "06164-913955",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.710188,8.833537",
  "id": "49.710188,8.833537"
}, {
  "Brand": "ENI",
  "Street": "Pfaenderstr. 15 a",
  "PostCode": 88161,
  "City": "Lindenberg",
  "Latitude": "47.597.710",
  "Longitude": "09.877.935",
  "Telephone": "+49 (0) 83819298",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.59771,9.877935",
  "id": "47.59771,9.877935"
}, {
  "Brand": "ENI",
  "Street": "Gustav-Schwab-Strasse 41",
  "PostCode": 72762,
  "City": "Reutlingen",
  "Latitude": "48.489.380",
  "Longitude": "09.192.470",
  "Telephone": "+49 (0) 71212392",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.48938,9.19247",
  "id": "48.48938,9.19247"
}, {
  "Brand": "Westfalen",
  "Street": "Becheltestr. 56",
  "PostCode": 58089,
  "City": "HAGEN",
  "Latitude": "51.378.000",
  "Longitude": "07.451.770",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.378,7.45177",
  "id": "51.378,7.45177"
}, {
  "Brand": "TOTAL",
  "Street": "SCHWABMUENCHNER STR. 16",
  "PostCode": 86836,
  "City": "KLOSTERLECHFELD",
  "Latitude": "48.159.048",
  "Longitude": "10.828.160",
  "Telephone": "08232-8088112",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.159048,10.82816",
  "id": "48.159048,10.82816"
}, {
  "Brand": "OMV",
  "Street": "Feuchtwanger Str.  111",
  "PostCode": 91522,
  "City": "Ansbach",
  "Latitude": "49.291.870",
  "Longitude": "10.555.680",
  "Telephone": "+49-981-61227",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.29187,10.55568",
  "id": "49.29187,10.55568"
}, {
  "Brand": "Westfalen",
  "Street": "Auf dem Quellberg 18",
  "PostCode": 48249,
  "City": "DUELMEN",
  "Latitude": "51.843.500",
  "Longitude": "07.294.600",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8435,7.2946",
  "id": "51.8435,7.2946"
}, {
  "Brand": "ARAL",
  "Street": "A2 / Brockweg 300",
  "PostCode": 33334,
  "City": "Gütersloh",
  "Latitude": "51.866.303",
  "Longitude": "08.369.209",
  "Telephone": "0524151100",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.866303,8.369209",
  "id": "51.866303,8.369209"
}, {
  "Brand": "Westfalen",
  "Street": "Meller Str. 41",
  "PostCode": 49143,
  "City": "BISSENDORF",
  "Latitude": "52.232.500",
  "Longitude": "08.174.240",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.2325,8.17424",
  "id": "52.2325,8.17424"
}, {
  "Brand": "OMV",
  "Street": "Hallstadter Str. 103",
  "PostCode": 96052,
  "City": "Bamberg",
  "Latitude": "49.910.600",
  "Longitude": "10.881.810",
  "Telephone": "+49-951-70096715",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.9106,10.88181",
  "id": "49.9106,10.88181"
}, {
  "Brand": "TOTAL",
  "Street": "POINGER STR. 2",
  "PostCode": 85570,
  "City": "MARKT SCHWABEN",
  "Latitude": "48.190.295",
  "Longitude": "11.848.462",
  "Telephone": "08121-4767555",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.190295,11.848462",
  "id": "48.190295,11.848462"
}, {
  "Brand": "ARAL",
  "Street": "Buxheimer Straße 113",
  "PostCode": 87700,
  "City": "Memmingen",
  "Latitude": "47.991.688",
  "Longitude": "10.160.623",
  "Telephone": "0833161722",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.991688,10.160623",
  "id": "47.991688,10.160623"
}, {
  "Brand": "TOTAL",
  "Street": "CALBERLAHER DAMM 54",
  "PostCode": 38518,
  "City": "GIFHORN",
  "Latitude": "52.475.355",
  "Longitude": "10.556.442",
  "Telephone": "05371-12646",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.475355,10.556442",
  "id": "52.475355,10.556442"
}, {
  "Brand": "ARAL",
  "Street": "Robert-Bosch-Str. 4",
  "PostCode": 64319,
  "City": "Pfungstadt",
  "Latitude": "49.814.591",
  "Longitude": "08.614.426",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.814591,8.614426",
  "id": "49.814591,8.614426"
}, {
  "Brand": "TOTAL",
  "Street": "ST. WENDELER STR. 33",
  "PostCode": 66606,
  "City": "ST. WENDEL",
  "Latitude": "49.493.333",
  "Longitude": "07.114.444",
  "Telephone": "06854-8654",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.493333,7.114444",
  "id": "49.493333,7.114444"
}, {
  "Brand": "Westfalen",
  "Street": "Weseler Str. 383",
  "PostCode": 48163,
  "City": "MUENSTER",
  "Latitude": "51.933.100",
  "Longitude": "07.597.540",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9331,7.59754",
  "id": "51.9331,7.59754"
}, {
  "Brand": "Westfalen",
  "Street": "BAT Lipperland",
  "PostCode": 32107,
  "City": "BAT LIPPERLAND",
  "Latitude": "52.073.474",
  "Longitude": "08.667.235",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.073474,8.667235",
  "id": "52.073474,8.667235"
}, {
  "Brand": "ARAL",
  "Street": "A 71",
  "PostCode": 98716,
  "City": "Geraberg",
  "Latitude": "50.725.311",
  "Longitude": "10.845.811",
  "Telephone": "036776894953",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.725311,10.845811",
  "id": "50.725311,10.845811"
}, {
  "Brand": "TOTAL",
  "Street": "UETTERLINGSER STR.39",
  "PostCode": 58791,
  "City": "WERDOHL",
  "Latitude": "51.254.486",
  "Longitude": "07.745.862",
  "Telephone": "02392-180326",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.254486,7.745862",
  "id": "51.254486,7.745862"
}, {
  "Brand": "TOTAL",
  "Street": "BIELEFELDER STR.81",
  "PostCode": 33803,
  "City": "STEINHAGEN",
  "Latitude": "52.013.299",
  "Longitude": "08.440.977",
  "Telephone": "05204-4634",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.013299,8.440977",
  "id": "52.013299,8.440977"
}, {
  "Brand": "TOTAL",
  "Street": "HEERSTR. 35-37",
  "PostCode": 14055,
  "City": "BERLIN",
  "Latitude": "52.508.358",
  "Longitude": "13.260.575",
  "Telephone": "030-50569275",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.508358,13.260575",
  "id": "52.508358,13.260575"
}, {
  "Brand": "OMV",
  "Street": "Regensburger Str. 30",
  "PostCode": 93128,
  "City": "Regenstauf",
  "Latitude": "49.119.120",
  "Longitude": "12.126.030",
  "Telephone": "+49-9402-1369",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.11912,12.12603",
  "id": "49.11912,12.12603"
}, {
  "Brand": "TOTAL",
  "Street": "HANAUERSTR. 61",
  "PostCode": 63579,
  "City": "FREIGERICHT",
  "Latitude": "50.147.092",
  "Longitude": "09.111.798",
  "Telephone": "06055-915217",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.147092,9.111798",
  "id": "50.147092,9.111798"
}, {
  "Brand": "ARAL",
  "Street": "Siemensstraße 2",
  "PostCode": 84109,
  "City": "Wörth a. d. Isar",
  "Latitude": "48.625.107",
  "Longitude": "12.322.628",
  "Telephone": "08702949351",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.625107,12.322628",
  "id": "48.625107,12.322628"
}, {
  "Brand": "OMV",
  "Street": "Logistikring 8",
  "PostCode": 85084,
  "City": "Reichertshofen",
  "Latitude": "48.643.400",
  "Longitude": "11.519.600",
  "Telephone": "+49-8453-334 68",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.6434,11.5196",
  "id": "48.6434,11.5196"
}, {
  "Brand": "ENI",
  "Street": "Reichenhaller Strasse 20",
  "PostCode": 83483,
  "City": "Bischofswiesen",
  "Latitude": "47.669.300",
  "Longitude": "12.944.020",
  "Telephone": "08652/985950",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.6693,12.94402",
  "id": "47.6693,12.94402"
}, {
  "Brand": "Westfalen",
  "Street": "Schlingstr. 26",
  "PostCode": 49406,
  "City": "BARNSTORF",
  "Latitude": "52.711.500",
  "Longitude": "08.509.640",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.7115,8.50964",
  "id": "52.7115,8.50964"
}, {
  "Brand": "ARAL",
  "Street": "A61",
  "PostCode": 56332,
  "City": "Dieblich",
  "Latitude": "50.266.425",
  "Longitude": "07.512.821",
  "Telephone": "02607768",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.266425,7.512821",
  "id": "50.266425,7.512821"
}, {
  "Brand": "ARAL",
  "Street": "Saarstraße 22-26",
  "PostCode": 61169,
  "City": "Friedberg",
  "Latitude": "50.331.572",
  "Longitude": "08.756.227",
  "Telephone": "060311660487",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.331572,8.756227",
  "id": "50.331572,8.756227"
}, {
  "Brand": "ENI",
  "Street": "Impler Str. 21",
  "PostCode": 81371,
  "City": "Muenchen",
  "Latitude": "48.122.378",
  "Longitude": "11.548.642",
  "Telephone": "+49 (0) 89725771",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.122378,11.548642",
  "id": "48.122378,11.548642"
}, {
  "Brand": "TOTAL",
  "Street": "OLPENER STR. 690",
  "PostCode": 51109,
  "City": "KOELN",
  "Latitude": "50.944.990",
  "Longitude": "07.062.567",
  "Telephone": "0221-6908711",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.94499,7.062567",
  "id": "50.94499,7.062567"
}, {
  "Brand": "OMV",
  "Street": "Unterhirschberg 4 a",
  "PostCode": 82396,
  "City": "Pähl",
  "Latitude": "47.897.100",
  "Longitude": "11.188.320",
  "Telephone": "+49-8808-921354",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "47.8971,11.18832",
  "id": "47.8971,11.18832"
}, {
  "Brand": "TOTAL",
  "Street": "KESSELSDORFER STR. 214",
  "PostCode": 1169,
  "City": "DRESDEN",
  "Latitude": "51.039.847",
  "Longitude": "13.657.067",
  "Telephone": "0351-4126824",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.039847,13.657067",
  "id": "51.039847,13.657067"
}, {
  "Brand": "TOTAL",
  "Street": "WESTSTR. 10",
  "PostCode": 58089,
  "City": "HAGEN",
  "Latitude": "51.385.874",
  "Longitude": "07.437.439",
  "Telephone": "02331-3483454",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.385874,7.437439",
  "id": "51.385874,7.437439"
}, {
  "Brand": "OMV",
  "Street": "Solitudestr. 207",
  "PostCode": 70499,
  "City": "Stuttgart",
  "Latitude": "48.812.980",
  "Longitude": "09.110.560",
  "Telephone": "+49-711-8873880",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.81298,9.11056",
  "id": "48.81298,9.11056"
}, {
  "Brand": "TOTAL",
  "Street": "DONAUWOERTHER STR. 102",
  "PostCode": 86154,
  "City": "AUGSBURG",
  "Latitude": "48.389.149",
  "Longitude": "10.881.633",
  "Telephone": "0821-414291",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.389149,10.881633",
  "id": "48.389149,10.881633"
}, {
  "Brand": "Westfalen",
  "Street": "Rheiner Landstr. 69",
  "PostCode": 49078,
  "City": "OSNABRUECK",
  "Latitude": "52.274.400",
  "Longitude": "08.022.460",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.2744,8.02246",
  "id": "52.2744,8.02246"
}, {
  "Brand": "ARAL",
  "Street": "Industriestraße 12",
  "PostCode": 63920,
  "City": "Großheubach",
  "Latitude": "49.718.482",
  "Longitude": "09.230.565",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.718482,9.230565",
  "id": "49.718482,9.230565"
}, {
  "Brand": "OMV",
  "Street": "Raiffeisenstr. 1",
  "PostCode": 93173,
  "City": "Wenzenbach",
  "Latitude": "49.070.740",
  "Longitude": "12.184.560",
  "Telephone": "+49-9407-90367",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.07074,12.18456",
  "id": "49.07074,12.18456"
}, {
  "Brand": "ARAL",
  "Street": "Henleinstraße 35",
  "PostCode": 86368,
  "City": "Gersthofen",
  "Latitude": "48.442.223",
  "Longitude": "10.874.033",
  "Telephone": "082142092000",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.442223,10.874033",
  "id": "48.442223,10.874033"
}, {
  "Brand": "TOTAL",
  "Street": "RITTER-TUSCHL-STR. 39",
  "PostCode": 94501,
  "City": "ALDERSBACH",
  "Latitude": "48.590.693",
  "Longitude": "13.093.868",
  "Telephone": "08543916298",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.590693,13.093868",
  "id": "48.590693,13.093868"
}, {
  "Brand": "ARAL",
  "Street": "Brauckstraße 41",
  "PostCode": 58454,
  "City": "Witten",
  "Latitude": "51.458.459",
  "Longitude": "07.390.843",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.458459,7.390843",
  "id": "51.458459,7.390843"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 49",
  "PostCode": 53506,
  "City": "Ahrbrück",
  "Latitude": "50.485.544",
  "Longitude": "06.973.878",
  "Telephone": "02643/6513",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.485544,6.973878",
  "id": "50.485544,6.973878"
}, {
  "Brand": "OMV",
  "Street": "Gleiwitzer Str. 220",
  "PostCode": 90475,
  "City": "Nürnberg",
  "Latitude": "49.410.650",
  "Longitude": "11.146.770",
  "Telephone": "+49-911-805572",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.41065,11.14677",
  "id": "49.41065,11.14677"
}, {
  "Brand": "ENI",
  "Street": "Rosenheimer Str. 44",
  "PostCode": 83101,
  "City": "Rohrdorf",
  "Latitude": "47.824.535",
  "Longitude": "12.153.534",
  "Telephone": "+49 (0) 80317113",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.824535,12.153534",
  "id": "47.824535,12.153534"
}, {
  "Brand": "Westfalen",
  "Street": "Emschertalstr. 131",
  "PostCode": 44289,
  "City": "DORTMUND",
  "Latitude": "51.506.100",
  "Longitude": "07.589.150",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.5061,7.58915",
  "id": "51.5061,7.58915"
}, {
  "Brand": "ENI",
  "Street": "Homberger Str. 24",
  "PostCode": 35325,
  "City": "Muecke/Bernsfeld",
  "Latitude": "50.667.660",
  "Longitude": "08.994.120",
  "Telephone": "+49 (0) 66348856",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.66766,8.99412",
  "id": "50.66766,8.99412"
}, {
  "Brand": "Westfalen",
  "Street": "Von-Steuben-Str. 13",
  "PostCode": 48143,
  "City": "MUENSTER",
  "Latitude": "51.954.900",
  "Longitude": "07.632.260",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9549,7.63226",
  "id": "51.9549,7.63226"
}, {
  "Brand": "TOTAL",
  "Street": "RUDOLF-BREITSCHEID-STR. 9b",
  "PostCode": 15848,
  "City": "BEESKOW",
  "Latitude": "52.166.157",
  "Longitude": "14.240.210",
  "Telephone": "03366-20497",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.166157,14.24021",
  "id": "52.166157,14.24021"
}, {
  "Brand": "ENI",
  "Street": "Aeuss.Zwickauer Str. 16-20",
  "PostCode": 9350,
  "City": "Lichtenstein",
  "Latitude": "50.752.736",
  "Longitude": "12.621.102",
  "Telephone": "+49 (0) 37204213",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.752736,12.621102",
  "id": "50.752736,12.621102"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 80",
  "PostCode": 64401,
  "City": "Groß-Bieberau",
  "Latitude": "49.807.314",
  "Longitude": "08.825.220",
  "Telephone": "06162940610",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "49.807314,8.82522",
  "id": "49.807314,8.82522"
}, {
  "Brand": "ARAL",
  "Street": "Schussenstraße 10",
  "PostCode": 88250,
  "City": "Weingarten",
  "Latitude": "47.812.050",
  "Longitude": "09.640.760",
  "Telephone": "075145623",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.81205,9.64076",
  "id": "47.81205,9.64076"
}, {
  "Brand": "TOTAL",
  "Street": "ROEMERSTR.16",
  "PostCode": 63486,
  "City": "BRUCHKOEBEL",
  "Latitude": "50.179.423",
  "Longitude": "08.909.233",
  "Telephone": "06181-576095",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.179423,8.909233",
  "id": "50.179423,8.909233"
}, {
  "Brand": "ARAL",
  "Street": "Kaiserwörthdamm 3",
  "PostCode": 67065,
  "City": "Ludwigshafen",
  "Latitude": "49.461.359",
  "Longitude": "08.434.561",
  "Telephone": "-",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "17:00",
  "SaturdayFrom": "0",
  "SaturdayTo": "0",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "49.461359,8.434561",
  "id": "49.461359,8.434561"
}, {
  "Brand": "ENI",
  "Street": "Hubertusstrasse 9",
  "PostCode": 83022,
  "City": "Rosenheim",
  "Latitude": "47.855.972",
  "Longitude": "12.110.580",
  "Telephone": "+49 (0) 80313710",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.855972,12.11058",
  "id": "47.855972,12.11058"
}, {
  "Brand": "ENI",
  "Street": "Schnellstrasse Ost",
  "PostCode": 55130,
  "City": "Mainz",
  "Latitude": "50.004.372",
  "Longitude": "08.262.588",
  "Telephone": "+49 (0) 61316982",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.004372,8.262588",
  "id": "50.004372,8.262588"
}, {
  "Brand": "ARAL",
  "Street": "Dibberser Strasse 30",
  "PostCode": 21244,
  "City": "Buchholz",
  "Latitude": "53.362.712",
  "Longitude": "09.873.121",
  "Telephone": "041815110",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.362712,9.873121",
  "id": "53.362712,9.873121"
}, {
  "Brand": "TOTAL",
  "Street": "KUPFERHUETTE 7",
  "PostCode": 6526,
  "City": "SANGERHAUSEN",
  "Latitude": "51.486.026",
  "Longitude": "11.308.634",
  "Telephone": "03464-572685",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.486026,11.308634",
  "id": "51.486026,11.308634"
}, {
  "Brand": "TOTAL",
  "Street": "AUTOHOF PFEFFERHOEHE 18",
  "PostCode": 36304,
  "City": "ALSFELD",
  "Latitude": "50.733.436",
  "Longitude": "09.241.473",
  "Telephone": "06631-800850",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.733436,9.241473",
  "id": "50.733436,9.241473"
}, {
  "Brand": "ARAL",
  "Street": "Bornstraße 221",
  "PostCode": 44145,
  "City": "Dortmund",
  "Latitude": "51.531.679",
  "Longitude": "07.473.619",
  "Telephone": "0231810342",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.531679,7.473619",
  "id": "51.531679,7.473619"
}, {
  "Brand": "ARAL",
  "Street": "Essener Straße 29",
  "PostCode": 57234,
  "City": "Wilnsdorf",
  "Latitude": "50.823.682",
  "Longitude": "08.078.500",
  "Telephone": "027394793763",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "18:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "12:00",
  "SundayFrom": "00:00",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "50.823682,8.0785",
  "id": "50.823682,8.0785"
}, {
  "Brand": "ARAL",
  "Street": "Bertha-Benz-Str.",
  "PostCode": 74343,
  "City": "Sachsenheim",
  "Latitude": "48.957.879",
  "Longitude": "09.081.767",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.957879,9.081767",
  "id": "48.957879,9.081767"
}, {
  "Brand": "TOTAL",
  "Street": "VON-SAUERSTR. 27",
  "PostCode": 22761,
  "City": "HAMBURG",
  "Latitude": "53.565.573",
  "Longitude": "09.906.537",
  "Telephone": "040-89064795",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.565573,9.906537",
  "id": "53.565573,9.906537"
}, {
  "Brand": "TOTAL",
  "Street": "BAHNHOFSTR. 68-72",
  "PostCode": 2826,
  "City": "GOERLITZ",
  "Latitude": "51.146.099",
  "Longitude": "14.983.898",
  "Telephone": "03581-409306",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.146099,14.983898",
  "id": "51.146099,14.983898"
}, {
  "Brand": "TOTAL",
  "Street": "MAX-PLANCK-STR. B3/B33",
  "PostCode": 77656,
  "City": "OFFENBURG",
  "Latitude": "48.472.510",
  "Longitude": "07.927.314",
  "Telephone": "0781-20396585",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.47251,7.927314",
  "id": "48.47251,7.927314"
}, {
  "Brand": "OMV",
  "Street": "Lonetal West A7",
  "PostCode": 89537,
  "City": "Giengen",
  "Latitude": "48.585.040",
  "Longitude": "10.177.250",
  "Telephone": "+49-7324-980064",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.58504,10.17725",
  "id": "48.58504,10.17725"
}, {
  "Brand": "ENI",
  "Street": "Josephspitalstr. 12",
  "PostCode": 80331,
  "City": "Muenchen",
  "Latitude": "48.136.500",
  "Longitude": "11.565.930",
  "Telephone": "+49 (0) 89558692",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.1365,11.56593",
  "id": "48.1365,11.56593"
}, {
  "Brand": "ARAL",
  "Street": "Königsberger Str.",
  "PostCode": 41460,
  "City": "Neuss",
  "Latitude": "51.215.215",
  "Longitude": "06.703.529",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.215215,6.703529",
  "id": "51.215215,6.703529"
}, {
  "Brand": "ARAL",
  "Street": "A7 / E45",
  "PostCode": 31174,
  "City": "Schellerten",
  "Latitude": "52.126.199",
  "Longitude": "10.051.826",
  "Telephone": "0512133322",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.126199,10.051826",
  "id": "52.126199,10.051826"
}, {
  "Brand": "ARAL",
  "Street": "Asbacher Straße 17 b",
  "PostCode": 98574,
  "City": "Schmalkalden",
  "Latitude": "50.719.344",
  "Longitude": "10.473.489",
  "Telephone": "036834650680",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.719344,10.473489",
  "id": "50.719344,10.473489"
}, {
  "Brand": "Westfalen",
  "Street": "Am Zubringer 2",
  "PostCode": 32107,
  "City": "BAD SALZUFLEN",
  "Latitude": "52.082.600",
  "Longitude": "08.717.130",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0826,8.71713",
  "id": "52.0826,8.71713"
}, {
  "Brand": "ENI",
  "Street": "Dreisesselstr. 39",
  "PostCode": 94089,
  "City": "Neureichenau",
  "Latitude": "48.744.444",
  "Longitude": "13.749.800",
  "Telephone": "+49 (0) 85839607",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.744444,13.7498",
  "id": "48.744444,13.7498"
}, {
  "Brand": "ENI",
  "Street": "Abtsgmuender Strasse 20",
  "PostCode": 73433,
  "City": "Aalen",
  "Latitude": "48.869.310",
  "Longitude": "10.106.660",
  "Telephone": "+49 (0) 73617409",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.86931,10.10666",
  "id": "48.86931,10.10666"
}, {
  "Brand": "TOTAL",
  "Street": "AM RHEINUEBERGANG",
  "PostCode": 77866,
  "City": "RHEINAU-FREISTETT",
  "Latitude": "48.680.519",
  "Longitude": "07.922.238",
  "Telephone": "07844-991122/24",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.680519,7.922238",
  "id": "48.680519,7.922238"
}, {
  "Brand": "TOTAL",
  "Street": "DETMOLDSTR. 1",
  "PostCode": 80935,
  "City": "MUENCHEN",
  "Latitude": "48.192.311",
  "Longitude": "11.564.284",
  "Telephone": "089-35043040",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.192311,11.564284",
  "id": "48.192311,11.564284"
}, {
  "Brand": "OMV",
  "Street": "Rudolf-Diesel-Str. 1",
  "PostCode": 91625,
  "City": "Schnelldorf",
  "Latitude": "49.171.410",
  "Longitude": "10.240.990",
  "Telephone": "+49-7950-8010",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.17141,10.24099",
  "id": "49.17141,10.24099"
}, {
  "Brand": "Westfalen",
  "Street": "Wessumer Str. 63",
  "PostCode": 48683,
  "City": "AHAUS",
  "Latitude": "52.082.300",
  "Longitude": "07.002.630",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0823,7.00263",
  "id": "52.0823,7.00263"
}, {
  "Brand": "TOTAL",
  "Street": "BAB SUED/A 24",
  "PostCode": 19372,
  "City": "STOLPE",
  "Latitude": "53.366.965",
  "Longitude": "11.727.052",
  "Telephone": "038725-20216",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.366965,11.727052",
  "id": "53.366965,11.727052"
}, {
  "Brand": "TOTAL",
  "Street": "ALBRECHTSTR. 95",
  "PostCode": 6844,
  "City": "DESSAU",
  "Latitude": "51.848.763",
  "Longitude": "12.240.352",
  "Telephone": "0340-214417",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.848763,12.240352",
  "id": "51.848763,12.240352"
}, {
  "Brand": "Westfalen",
  "Street": "Rheiner Str. 121",
  "PostCode": 48282,
  "City": "EMSDETTEN",
  "Latitude": "52.182.500",
  "Longitude": "07.520.760",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.1825,7.52076",
  "id": "52.1825,7.52076"
}, {
  "Brand": "ARAL",
  "Street": "Maarweg 126",
  "PostCode": 50825,
  "City": "Köln",
  "Latitude": "50.943.089",
  "Longitude": "06.898.796",
  "Telephone": "0221541909",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.943089,6.898796",
  "id": "50.943089,6.898796"
}, {
  "Brand": "TOTAL",
  "Street": "DIEDERSDORFER STR. 2",
  "PostCode": 15306,
  "City": "SEELOW",
  "Latitude": "52.529.880",
  "Longitude": "14.370.460",
  "Telephone": "03346-316",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.52988,14.37046",
  "id": "52.52988,14.37046"
}, {
  "Brand": "TOTAL",
  "Street": "HAMMER LANDSTR. 204",
  "PostCode": 20537,
  "City": "HAMBURG",
  "Latitude": "53.554.041",
  "Longitude": "10.063.172",
  "Telephone": "040-211533",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.554041,10.063172",
  "id": "53.554041,10.063172"
}, {
  "Brand": "ARAL",
  "Street": "Ober-Saulheimer Straße 17",
  "PostCode": 55286,
  "City": "Wörrstadt",
  "Latitude": "49.852.648",
  "Longitude": "08.123.193",
  "Telephone": "067321403",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "49.852648,8.123193",
  "id": "49.852648,8.123193"
}, {
  "Brand": "ARAL",
  "Street": "Nicolaus-Otto-Straße",
  "PostCode": 56727,
  "City": "Mayen",
  "Latitude": "50.333.097",
  "Longitude": "07.261.815",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.333097,7.261815",
  "id": "50.333097,7.261815"
}, {
  "Brand": "TOTAL",
  "Street": "KARL-LIEBKNECHT-STR. 155",
  "PostCode": 15711,
  "City": "ZEESEN",
  "Latitude": "52.275.299",
  "Longitude": "13.631.795",
  "Telephone": "03375-900332",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.275299,13.631795",
  "id": "52.275299,13.631795"
}, {
  "Brand": "TOTAL",
  "Street": "MELLINGHOFER STR.110",
  "PostCode": 45473,
  "City": "MUELHEIM",
  "Latitude": "51.445.336",
  "Longitude": "06.884.433",
  "Telephone": "0208-995570",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.445336,6.884433",
  "id": "51.445336,6.884433"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 23",
  "PostCode": 83339,
  "City": "Chieming",
  "Latitude": "47.890.352",
  "Longitude": "12.537.200",
  "Telephone": "08664927124",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.890352,12.5372",
  "id": "47.890352,12.5372"
}, {
  "Brand": "TOTAL",
  "Street": "ARNO-SCHMIDT-STR. 28 A",
  "PostCode": 8134,
  "City": "WILDENFELS",
  "Latitude": "50.680.678",
  "Longitude": "12.592.253",
  "Telephone": "037603-550711",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.680678,12.592253",
  "id": "50.680678,12.592253"
}, {
  "Brand": "Westfalen",
  "Street": "Bursdamm 1",
  "PostCode": 33129,
  "City": "DELBRUECK",
  "Latitude": "51.782.200",
  "Longitude": "08.624.020",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.7822,8.62402",
  "id": "51.7822,8.62402"
}, {
  "Brand": "OMV",
  "Street": "Frankfurter Str. 14",
  "PostCode": 97737,
  "City": "Gemünden",
  "Latitude": "50.059.390",
  "Longitude": "09.686.240",
  "Telephone": "+49-9351-3471",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.05939,9.68624",
  "id": "50.05939,9.68624"
}, {
  "Brand": "ENI",
  "Street": "Gerolsbacher Str. 9",
  "PostCode": 86529,
  "City": "Schrobenhausen",
  "Latitude": "48.555.460",
  "Longitude": "11.268.060",
  "Telephone": "+49 (0) 82528877",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.55546,11.26806",
  "id": "48.55546,11.26806"
}, {
  "Brand": "TOTAL",
  "Street": "MUENSTERSTR. 51",
  "PostCode": 49477,
  "City": "IBBENBUEREN",
  "Latitude": "52.269.119",
  "Longitude": "07.717.478",
  "Telephone": "05451-894755",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.269119,7.717478",
  "id": "52.269119,7.717478"
}, {
  "Brand": "ARAL",
  "Street": "Grotwisch 2",
  "PostCode": 24589,
  "City": "Dätgen",
  "Latitude": "54.180.057",
  "Longitude": "09.944.170",
  "Telephone": "043299136038",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "54.180057,9.94417",
  "id": "54.180057,9.94417"
}, {
  "Brand": "TOTAL",
  "Street": "GOTHAER STR.9",
  "PostCode": 99880,
  "City": "WALTERSHAUSEN",
  "Latitude": "50.901.788",
  "Longitude": "10.566.490",
  "Telephone": "03622-68444",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.901788,10.56649",
  "id": "50.901788,10.56649"
}, {
  "Brand": "TOTAL",
  "Street": "AHLBECKER STR. 37",
  "PostCode": 17454,
  "City": "ZINNOWITZ",
  "Latitude": "54.067.402",
  "Longitude": "13.919.616",
  "Telephone": "038377-42728",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.067402,13.919616",
  "id": "54.067402,13.919616"
}, {
  "Brand": "ARAL",
  "Street": "Brückenstraße 6",
  "PostCode": 87616,
  "City": "Marktoberdorf-Thalho",
  "Latitude": "47.773.577",
  "Longitude": "10.603.584",
  "Telephone": "083425352",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "47.773577,10.603584",
  "id": "47.773577,10.603584"
}, {
  "Brand": "TOTAL",
  "Street": "URBANSTR. 65",
  "PostCode": 73728,
  "City": "ESSLINGEN",
  "Latitude": "48.739.577",
  "Longitude": "09.319.722",
  "Telephone": "0711-9319777",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.739577,9.319722",
  "id": "48.739577,9.319722"
}, {
  "Brand": "TOTAL",
  "Street": "FRAUENLOBSTR.112",
  "PostCode": 44805,
  "City": "BOCHUM",
  "Latitude": "51.515.299",
  "Longitude": "07.258.893",
  "Telephone": "0234-850992",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.515299,7.258893",
  "id": "51.515299,7.258893"
}, {
  "Brand": "TOTAL",
  "Street": "RHEINLANDSTR. 28-30",
  "PostCode": 72070,
  "City": "TUEBINGEN",
  "Latitude": "48.523.507",
  "Longitude": "09.039.299",
  "Telephone": "07071-45366",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.523507,9.039299",
  "id": "48.523507,9.039299"
}, {
  "Brand": "TOTAL",
  "Street": "DINGELSTAEDTER STRASSE 33",
  "PostCode": 37308,
  "City": "HEILIGENSTADT",
  "Latitude": "51.375.330",
  "Longitude": "10.148.411",
  "Telephone": "03606-608552",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.37533,10.148411",
  "id": "51.37533,10.148411"
}, {
  "Brand": "Westfalen",
  "Street": "Sternbuschweg 103a",
  "PostCode": 47057,
  "City": "DUISBURG",
  "Latitude": "51.426.700",
  "Longitude": "06.791.220",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.4267,6.79122",
  "id": "51.4267,6.79122"
}, {
  "Brand": "ENI",
  "Street": "Detter Strasse 1A",
  "PostCode": 94469,
  "City": "Deggendorf",
  "Latitude": "48.841.260",
  "Longitude": "12.956.840",
  "Telephone": "+49 (0) 99138308",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.84126,12.95684",
  "id": "48.84126,12.95684"
}, {
  "Brand": "Westfalen",
  "Street": "Borsteler Landstr. 47",
  "PostCode": 28832,
  "City": "ACHIM",
  "Latitude": "53.017.500",
  "Longitude": "09.038.670",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.0175,9.03867",
  "id": "53.0175,9.03867"
}, {
  "Brand": "ARAL",
  "Street": "Am Warthweg 1-5",
  "PostCode": 61184,
  "City": "Karben",
  "Latitude": "50.238.027",
  "Longitude": "08.748.688",
  "Telephone": "06039/9391083",
  "WeekDayFrom": "08:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "50.238027,8.748688",
  "id": "50.238027,8.748688"
}, {
  "Brand": "ARAL",
  "Street": "Eisenbahnstraße 8",
  "PostCode": 78315,
  "City": "Radolfzell",
  "Latitude": "47.741.594",
  "Longitude": "08.954.780",
  "Telephone": "07732988268",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.741594,8.95478",
  "id": "47.741594,8.95478"
}, {
  "Brand": "ARAL",
  "Street": "Züttlinger Straße 5",
  "PostCode": 74219,
  "City": "Möckmühl",
  "Latitude": "49.321.930",
  "Longitude": "09.363.825",
  "Telephone": "062982509",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "04:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.32193,9.363825",
  "id": "49.32193,9.363825"
}, {
  "Brand": "ARAL",
  "Street": "Burghausener Straße 7",
  "PostCode": 84558,
  "City": "Kirchweidach",
  "Latitude": "48.087.753",
  "Longitude": "12.645.767",
  "Telephone": "08623240",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "19:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "19:00",
  "SundayFrom": "09:00",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "48.087753,12.645767",
  "id": "48.087753,12.645767"
}, {
  "Brand": "ARAL",
  "Street": "Lessingstraße 2 a",
  "PostCode": 8294,
  "City": "Lößnitz",
  "Latitude": "50.611.523",
  "Longitude": "12.720.064",
  "Telephone": "0377133411",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:30",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.611523,12.720064",
  "id": "50.611523,12.720064"
}, {
  "Brand": "ENI",
  "Street": "Heidelberger Strasse 49",
  "PostCode": 74821,
  "City": "Mosbach",
  "Latitude": "49.343.312",
  "Longitude": "09.105.296",
  "Telephone": "+49 (0) 62617767",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.343312,9.105296",
  "id": "49.343312,9.105296"
}, {
  "Brand": "ARAL",
  "Street": "An der BAB A5 Ost",
  "PostCode": 76694,
  "City": "Forst",
  "Latitude": "49.161.002",
  "Longitude": "08.569.126",
  "Telephone": "072512200",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.161002,8.569126",
  "id": "49.161002,8.569126"
}, {
  "Brand": "Westfalen",
  "Street": "Zum Heubach 3",
  "PostCode": 48734,
  "City": "REKEN",
  "Latitude": "51.841.100",
  "Longitude": "07.095.370",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8411,7.09537",
  "id": "51.8411,7.09537"
}, {
  "Brand": "TOTAL",
  "Street": "HARENBERGER MEILE 39",
  "PostCode": 30926,
  "City": "SEELZE",
  "Latitude": "52.377.810",
  "Longitude": "09.618.669",
  "Telephone": "05137-877106",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.37781,9.618669",
  "id": "52.37781,9.618669"
}, {
  "Brand": "OMV",
  "Street": "Überkinger Str. 50",
  "PostCode": 73312,
  "City": "Geislingen",
  "Latitude": "48.622.330",
  "Longitude": "09.822.860",
  "Telephone": "+49-7331-60763",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.62233,9.82286",
  "id": "48.62233,9.82286"
}, {
  "Brand": "ENI",
  "Street": "Donaustaufer Str. 316",
  "PostCode": 93055,
  "City": "Regensburg",
  "Latitude": "49.025.030",
  "Longitude": "12.147.750",
  "Telephone": "+49 (0) 94145318",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.02503,12.14775",
  "id": "49.02503,12.14775"
}, {
  "Brand": "ARAL",
  "Street": "A 45",
  "PostCode": 57258,
  "City": "Freudenberg",
  "Latitude": "50.876.623",
  "Longitude": "07.948.244",
  "Telephone": "027345471",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.876623,7.948244",
  "id": "50.876623,7.948244"
}, {
  "Brand": "OMV",
  "Street": "Lindauer Str. 12",
  "PostCode": 88079,
  "City": "Kressbronn",
  "Latitude": "47.592.600",
  "Longitude": "09.602.580",
  "Telephone": "+49-7543-8034",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.5926,9.60258",
  "id": "47.5926,9.60258"
}, {
  "Brand": "ARAL",
  "Street": "Geschwister-Scholl-Straße 32",
  "PostCode": 39307,
  "City": "Genthin",
  "Latitude": "52.408.785",
  "Longitude": "12.162.745",
  "Telephone": "039339482699",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.408785,12.162745",
  "id": "52.408785,12.162745"
}, {
  "Brand": "ARAL",
  "Street": "Marktstraße 34",
  "PostCode": 36132,
  "City": "Eiterfeld",
  "Latitude": "50.762.190",
  "Longitude": "09.792.048",
  "Telephone": "066727089",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "50.76219,9.792048",
  "id": "50.76219,9.792048"
}, {
  "Brand": "OMV",
  "Street": "Ottobrunner Str. 116",
  "PostCode": 81737,
  "City": "München",
  "Latitude": "48.102.690",
  "Longitude": "11.625.370",
  "Telephone": "+49-89-63894012",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.10269,11.62537",
  "id": "48.10269,11.62537"
}, {
  "Brand": "ARAL",
  "Street": "A31",
  "PostCode": 49835,
  "City": "Wietmarschen",
  "Latitude": "52.528.611",
  "Longitude": "07.196.889",
  "Telephone": "05925998264",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.528611,7.196889",
  "id": "52.528611,7.196889"
}, {
  "Brand": "OMV",
  "Street": "Hauptstr. 89",
  "PostCode": 63322,
  "City": "Rödermark",
  "Latitude": "50.004.290",
  "Longitude": "08.812.900",
  "Telephone": "+49-6074-90585",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.00429,8.8129",
  "id": "50.00429,8.8129"
}, {
  "Brand": "Westfalen",
  "Street": "Benninghofer Str. 265",
  "PostCode": 44267,
  "City": "DORTMUND",
  "Latitude": "51.468.700",
  "Longitude": "07.515.740",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.4687,7.51574",
  "id": "51.4687,7.51574"
}, {
  "Brand": "ARAL",
  "Street": "Niedersachsenweg/Unterste",
  "PostCode": 44309,
  "City": "Dortmund",
  "Latitude": "51.529.089",
  "Longitude": "07.527.660",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.529089,7.52766",
  "id": "51.529089,7.52766"
}, {
  "Brand": "ARAL",
  "Street": "Altenessener Straße 288",
  "PostCode": 45326,
  "City": "Essen",
  "Latitude": "51.492.365",
  "Longitude": "07.009.831",
  "Telephone": "02018378311",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.492365,7.009831",
  "id": "51.492365,7.009831"
}, {
  "Brand": "TOTAL",
  "Street": "ERLENWIESE 21",
  "PostCode": 35794,
  "City": "MENGERSKIRCHEN",
  "Latitude": "50.531.680",
  "Longitude": "08.143.343",
  "Telephone": "06476-411250",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.53168,8.143343",
  "id": "50.53168,8.143343"
}, {
  "Brand": "TOTAL",
  "Street": "HAUPTSTR.286",
  "PostCode": 97896,
  "City": "FREUDENBERG",
  "Latitude": "49.750.440",
  "Longitude": "09.325.976",
  "Telephone": "09375-306",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.75044,9.325976",
  "id": "49.75044,9.325976"
}, {
  "Brand": "OMV",
  "Street": "Konstanzer Str. 8",
  "PostCode": 79761,
  "City": "Waldshut-Tiengen",
  "Latitude": "47.610.760",
  "Longitude": "08.230.310",
  "Telephone": "+49-7751-3027",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.61076,8.23031",
  "id": "47.61076,8.23031"
}, {
  "Brand": "TOTAL",
  "Street": "SCHERMBECKER LSTR.28",
  "PostCode": 46485,
  "City": "WESEL",
  "Latitude": "51.659.519",
  "Longitude": "06.638.875",
  "Telephone": "0281-50167",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.659519,6.638875",
  "id": "51.659519,6.638875"
}, {
  "Brand": "Westfalen",
  "Street": "Schlosser Str 2",
  "PostCode": 59399,
  "City": "OLFEN",
  "Latitude": "51.706.752",
  "Longitude": "07.392.409",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.706752,7.392409",
  "id": "51.706752,7.392409"
}, {
  "Brand": "TOTAL",
  "Street": "STEGENWALDHAUSER STR. 1",
  "PostCode": 95152,
  "City": "SELBITZ OT SELLANGER",
  "Latitude": "50.324.341",
  "Longitude": "11.784.478",
  "Telephone": "09280953550",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.324341,11.784478",
  "id": "50.324341,11.784478"
}, {
  "Brand": "ENI",
  "Street": "Stettener Str. 51",
  "PostCode": 88709,
  "City": "Meersburg",
  "Latitude": "47.693.554",
  "Longitude": "09.279.391",
  "Telephone": "+49 (0) 75329618",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.693554,9.279391",
  "id": "47.693554,9.279391"
}, {
  "Brand": "TOTAL",
  "Street": "HEERSTR. 78",
  "PostCode": 60488,
  "City": "FRANKFURT",
  "Latitude": "50.147.081",
  "Longitude": "08.612.149",
  "Telephone": "069-762965",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.147081,8.612149",
  "id": "50.147081,8.612149"
}, {
  "Brand": "TOTAL",
  "Street": "HAUPTSTR. 103",
  "PostCode": 65843,
  "City": "SULZBACH",
  "Latitude": "50.137.800",
  "Longitude": "08.519.186",
  "Telephone": "06196-71144",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.1378,8.519186",
  "id": "50.1378,8.519186"
}, {
  "Brand": "ARAL",
  "Street": "Ganseburg 7",
  "PostCode": 35418,
  "City": "Buseck",
  "Latitude": "50.591.518",
  "Longitude": "08.781.615",
  "Telephone": "064085034120",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.591518,8.781615",
  "id": "50.591518,8.781615"
}, {
  "Brand": "ENI",
  "Street": "Schlierseer Strasse 9",
  "PostCode": 83734,
  "City": "Hausham",
  "Latitude": "47.746.360",
  "Longitude": "11.844.330",
  "Telephone": "+49 (0) 80269327",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.74636,11.84433",
  "id": "47.74636,11.84433"
}, {
  "Brand": "ENI",
  "Street": "Oeslauer Strasse 13",
  "PostCode": 96472,
  "City": "Roedental",
  "Latitude": "50.294.850",
  "Longitude": "11.047.860",
  "Telephone": "+49 (0) 95638252",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.29485,11.04786",
  "id": "50.29485,11.04786"
}, {
  "Brand": "ARAL",
  "Street": "Würzburger Straße 64",
  "PostCode": 74736,
  "City": "Hardheim",
  "Latitude": "49.611.774",
  "Longitude": "09.487.301",
  "Telephone": "0628350531",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "07:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.611774,9.487301",
  "id": "49.611774,9.487301"
}, {
  "Brand": "TOTAL",
  "Street": "SAARBRUECKENER STR. 41",
  "PostCode": 74078,
  "City": "HEILBRONN",
  "Latitude": "49.155.181",
  "Longitude": "09.176.363",
  "Telephone": "07131-42829",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.155181,9.176363",
  "id": "49.155181,9.176363"
}, {
  "Brand": "ARAL",
  "Street": "Stuttgarter Straße 112 (B",
  "PostCode": 73054,
  "City": "Eislingen",
  "Latitude": "48.696.090",
  "Longitude": "09.695.035",
  "Telephone": "07161821404",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.69609,9.695035",
  "id": "48.69609,9.695035"
}, {
  "Brand": "ARAL",
  "Street": "A6",
  "PostCode": 92278,
  "City": "Illschwang",
  "Latitude": "49.423.700",
  "Longitude": "11.686.350",
  "Telephone": "",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.4237,11.68635",
  "id": "49.4237,11.68635"
}, {
  "Brand": "OMV",
  "Street": "Bahnhofstr. 20",
  "PostCode": 91171,
  "City": "Greding",
  "Latitude": "49.043.640",
  "Longitude": "11.349.970",
  "Telephone": "+49-8463-605152",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.04364,11.34997",
  "id": "49.04364,11.34997"
}, {
  "Brand": "TOTAL",
  "Street": "ASBACHER STR. 55",
  "PostCode": 53545,
  "City": "LINZ",
  "Latitude": "50.569.706",
  "Longitude": "07.286.899",
  "Telephone": "02644-2749",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.569706,7.286899",
  "id": "50.569706,7.286899"
}, {
  "Brand": "ARAL",
  "Street": "Hauptstraße 12",
  "PostCode": 87656,
  "City": "Germaringen",
  "Latitude": "47.924.224",
  "Longitude": "10.668.928",
  "Telephone": "0834162283",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "20:30",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:30",
  "SundayFrom": "08:00",
  "SundayTo": "20:30",
  "IsOpen24Hours": "Nein",
  "location": "47.924224,10.668928",
  "id": "47.924224,10.668928"
}, {
  "Brand": "TOTAL",
  "Street": "GRIEDELER STR.72",
  "PostCode": 35510,
  "City": "BUTZBACH",
  "Latitude": "50.436.532",
  "Longitude": "08.681.493",
  "Telephone": "06033-60763",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.436532,8.681493",
  "id": "50.436532,8.681493"
}, {
  "Brand": "TOTAL",
  "Street": "STEINSTR.11",
  "PostCode": 16303,
  "City": "SCHWEDT",
  "Latitude": "53.071.391",
  "Longitude": "14.254.327",
  "Telephone": "03332-417992",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.071391,14.254327",
  "id": "53.071391,14.254327"
}, {
  "Brand": "TOTAL",
  "Street": "HAUPTSTR. 200",
  "PostCode": 16547,
  "City": "BIRKENWERDER",
  "Latitude": "52.702.871",
  "Longitude": "13.272.647",
  "Telephone": "03303-501693",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.702871,13.272647",
  "id": "52.702871,13.272647"
}, {
  "Brand": "TOTAL",
  "Street": "AUTOHOF ROSENGARTENWEG 1",
  "PostCode": 67281,
  "City": "KIRCHHEIM",
  "Latitude": "49.554.165",
  "Longitude": "08.185.635",
  "Telephone": "06359-9692132",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.554165,8.185635",
  "id": "49.554165,8.185635"
}, {
  "Brand": "TOTAL",
  "Street": "DOTZHEIMER STR. 184",
  "PostCode": 65197,
  "City": "WIESBADEN",
  "Latitude": "50.076.068",
  "Longitude": "08.208.610",
  "Telephone": "0611-420626",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.076068,8.20861",
  "id": "50.076068,8.20861"
}, {
  "Brand": "Westfalen",
  "Street": "Luebecker Str. 55",
  "PostCode": 32429,
  "City": "MINDEN",
  "Latitude": "52.274.600",
  "Longitude": "08.892.660",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.2746,8.89266",
  "id": "52.2746,8.89266"
}, {
  "Brand": "TOTAL",
  "Street": "AN DER B 255",
  "PostCode": 35756,
  "City": "MITTENAAR",
  "Latitude": "50.698.278",
  "Longitude": "08.408.910",
  "Telephone": "02778-913110",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.698278,8.40891",
  "id": "50.698278,8.40891"
}, {
  "Brand": "TOTAL",
  "Street": "ALFREDSTR. 248",
  "PostCode": 45133,
  "City": "ESSEN",
  "Latitude": "51.421.944",
  "Longitude": "06.998.477",
  "Telephone": "0201-410115",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.421944,6.998477",
  "id": "51.421944,6.998477"
}, {
  "Brand": "Westfalen",
  "Street": "Westheider Weg 46 - 48",
  "PostCode": 33775,
  "City": "VERSMOLD",
  "Latitude": "52.043.900",
  "Longitude": "08.149.200",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0439,8.1492",
  "id": "52.0439,8.1492"
}, {
  "Brand": "ARAL",
  "Street": "Lindenstraße 5",
  "PostCode": 38312,
  "City": "Flöthe",
  "Latitude": "52.076.215",
  "Longitude": "10.495.459",
  "Telephone": "05339928366",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "04:30",
  "SaturdayTo": "23:59",
  "SundayFrom": "04:30",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.076215,10.495459",
  "id": "52.076215,10.495459"
}, {
  "Brand": "TOTAL",
  "Street": "SCHMALKALDER STRASSE 13",
  "PostCode": 45665,
  "City": "RECKLINGHAUSEN",
  "Latitude": "51.597.761",
  "Longitude": "07.245.138",
  "Telephone": "02361-9387600",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.597761,7.245138",
  "id": "51.597761,7.245138"
}, {
  "Brand": "ARAL",
  "Street": "Lindenstraße 2",
  "PostCode": 9241,
  "City": "Mühlau",
  "Latitude": "50.898.567",
  "Longitude": "12.774.261",
  "Telephone": "037226157",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Ja",
  "location": "50.898567,12.774261",
  "id": "50.898567,12.774261"
}, {
  "Brand": "ARAL",
  "Street": "Molkerei-/Kervenheimer St",
  "PostCode": 47589,
  "City": "Uedem",
  "Latitude": "51.658.244",
  "Longitude": "06.275.204",
  "Telephone": "02825539955",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.658244,6.275204",
  "id": "51.658244,6.275204"
}, {
  "Brand": "TOTAL",
  "Street": "MAGDEBURGER STR. 28",
  "PostCode": 39245,
  "City": "GOMMERN",
  "Latitude": "52.081.546",
  "Longitude": "11.815.554",
  "Telephone": "039200-51317",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.081546,11.815554",
  "id": "52.081546,11.815554"
}, {
  "Brand": "TOTAL",
  "Street": "BAB A8 - AUGSBURG OST",
  "PostCode": 86169,
  "City": "AUGSBURG",
  "Latitude": "48.412.052",
  "Longitude": "10.912.778",
  "Telephone": "082148044019",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.412052,10.912778",
  "id": "48.412052,10.912778"
}, {
  "Brand": "TOTAL",
  "Street": "HORSTER STR. 69",
  "PostCode": 45897,
  "City": "GELSENKIRCHEN",
  "Latitude": "51.573.520",
  "Longitude": "07.048.020",
  "Telephone": "0209-594033",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.57352,7.04802",
  "id": "51.57352,7.04802"
}, {
  "Brand": "ENI",
  "Street": "Allguer Str. 7",
  "PostCode": 87642,
  "City": "Halblech",
  "Latitude": "47.638.060",
  "Longitude": "10.825.670",
  "Telephone": "+49 (0) 83689404",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.63806,10.82567",
  "id": "47.63806,10.82567"
}, {
  "Brand": "Westfalen",
  "Street": "Butjadinger Str. 6",
  "PostCode": 26954,
  "City": "NORDENHAM",
  "Latitude": "53.477.800",
  "Longitude": "08.450.390",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.4778,8.45039",
  "id": "53.4778,8.45039"
}, {
  "Brand": "Westfalen",
  "Street": "Wilhelm-Tenhagen-Str. 1",
  "PostCode": 46240,
  "City": "BOTTROP",
  "Latitude": "51.535.300",
  "Longitude": "06.978.380",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.5353,6.97838",
  "id": "51.5353,6.97838"
}, {
  "Brand": "ENI",
  "Street": "Grosse Allee 17",
  "PostCode": 89407,
  "City": "Dillingen",
  "Latitude": "48.580.510",
  "Longitude": "10.491.730",
  "Telephone": "+49 (0) 90718062",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.58051,10.49173",
  "id": "48.58051,10.49173"
}, {
  "Brand": "ARAL",
  "Street": "A 7",
  "PostCode": 29646,
  "City": "Bispingen",
  "Latitude": "53.108.685",
  "Longitude": "09.982.882",
  "Telephone": "0519498550",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.108685,9.982882",
  "id": "53.108685,9.982882"
}, {
  "Brand": "TOTAL",
  "Street": "HEITERER BLICK 2",
  "PostCode": 4720,
  "City": "GROSSWEITZSCHEN",
  "Latitude": "51.152.499",
  "Longitude": "13.120.292",
  "Telephone": "03431-729730",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.152499,13.120292",
  "id": "51.152499,13.120292"
}, {
  "Brand": "TOTAL",
  "Street": "OFFENBURGER STR. 36",
  "PostCode": 77855,
  "City": "ACHERN",
  "Latitude": "48.605.389",
  "Longitude": "08.032.417",
  "Telephone": "07841-21364",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.605389,8.032417",
  "id": "48.605389,8.032417"
}, {
  "Brand": "Westfalen",
  "Street": "Friesoyther Str. 2",
  "PostCode": 26219,
  "City": "BOESEL",
  "Latitude": "53.006.100",
  "Longitude": "07.954.180",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.0061,7.95418",
  "id": "53.0061,7.95418"
}, {
  "Brand": "Westfalen",
  "Street": "Lindenstr. 35",
  "PostCode": 49586,
  "City": "NEUENKIRCHEN",
  "Latitude": "52.419.400",
  "Longitude": "07.838.100",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.4194,7.8381",
  "id": "52.4194,7.8381"
}, {
  "Brand": "TOTAL",
  "Street": "HOENOWER CHAUSSEE 1 A",
  "PostCode": 15345,
  "City": "ALTLANDSBERG",
  "Latitude": "52.556.613",
  "Longitude": "13.714.170",
  "Telephone": "033438-61811",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.556613,13.71417",
  "id": "52.556613,13.71417"
}, {
  "Brand": "TOTAL",
  "Street": "ALTE HEERSTR.2-4",
  "PostCode": 49124,
  "City": "GEORGSMARIENHUETTE",
  "Latitude": "52.222.064",
  "Longitude": "08.083.536",
  "Telephone": "05401-43174",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.222064,8.083536",
  "id": "52.222064,8.083536"
}, {
  "Brand": "TOTAL",
  "Street": "BARLEBER CH. 3",
  "PostCode": 39126,
  "City": "MAGDEBURG",
  "Latitude": "52.178.560",
  "Longitude": "11.629.122",
  "Telephone": "0391-2530930",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.17856,11.629122",
  "id": "52.17856,11.629122"
}, {
  "Brand": "TOTAL",
  "Street": "SCHATTHAEUSER STR. 44",
  "PostCode": 69168,
  "City": "WIESLOCH",
  "Latitude": "49.303.297",
  "Longitude": "08.738.080",
  "Telephone": "06222-772652",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.303297,8.73808",
  "id": "49.303297,8.73808"
}, {
  "Brand": "ARAL",
  "Street": "Werner-von-Siemens-Str.",
  "PostCode": 28832,
  "City": "Achim",
  "Latitude": "53.023.048",
  "Longitude": "09.066.820",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.023048,9.06682",
  "id": "53.023048,9.06682"
}, {
  "Brand": "ENI",
  "Street": "Verdistrasse 141",
  "PostCode": 81247,
  "City": "Muenchen",
  "Latitude": "48.164.472",
  "Longitude": "11.459.229",
  "Telephone": "+49 (0) 89811133",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.164472,11.459229",
  "id": "48.164472,11.459229"
}, {
  "Brand": "ENI",
  "Street": "Herterichstrasse 111",
  "PostCode": 81477,
  "City": "Muenchen",
  "Latitude": "48.075.130",
  "Longitude": "11.510.920",
  "Telephone": "+49 (0) 89795346",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.07513,11.51092",
  "id": "48.07513,11.51092"
}, {
  "Brand": "TOTAL",
  "Street": "MUNDSBURGER DAMM 47",
  "PostCode": 22087,
  "City": "HAMBURG",
  "Latitude": "53.568.682",
  "Longitude": "10.024.122",
  "Telephone": "040-22739104",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.568682,10.024122",
  "id": "53.568682,10.024122"
}, {
  "Brand": "Westfalen",
  "Street": "Mittelstr. 20",
  "PostCode": 32805,
  "City": "HORN",
  "Latitude": "51.871.300",
  "Longitude": "08.940.230",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8713,8.94023",
  "id": "51.8713,8.94023"
}, {
  "Brand": "TOTAL",
  "Street": "STETTINER STR. 100",
  "PostCode": 17291,
  "City": "PRENZLAU",
  "Latitude": "53.329.198",
  "Longitude": "13.867.499",
  "Telephone": "03984-2888",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.329198,13.867499",
  "id": "53.329198,13.867499"
}, {
  "Brand": "TOTAL",
  "Street": "SCHLACHTHOFSTR. 14",
  "PostCode": 99085,
  "City": "ERFURT",
  "Latitude": "50.986.245",
  "Longitude": "11.038.236",
  "Telephone": "0361-5623517",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.986245,11.038236",
  "id": "50.986245,11.038236"
}, {
  "Brand": "TOTAL",
  "Street": "PENIGER STR.40",
  "PostCode": 4654,
  "City": "FROHBURG",
  "Latitude": "51.049.725",
  "Longitude": "12.556.274",
  "Telephone": "034348-51177",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.049725,12.556274",
  "id": "51.049725,12.556274"
}, {
  "Brand": "ARAL",
  "Street": "Daniel-Eckhard-Str. 8",
  "PostCode": 45356,
  "City": "Essen",
  "Latitude": "51.499.862",
  "Longitude": "06.981.355",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.499862,6.981355",
  "id": "51.499862,6.981355"
}, {
  "Brand": "TOTAL",
  "Street": "MAINZER STR. 4",
  "PostCode": 65550,
  "City": "LIMBURG-LINTER",
  "Latitude": "50.370.873",
  "Longitude": "08.084.690",
  "Telephone": "06431-47600",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.370873,8.08469",
  "id": "50.370873,8.08469"
}, {
  "Brand": "Westfalen",
  "Street": "Bockhorner Str. 78",
  "PostCode": 26316,
  "City": "VAREL",
  "Latitude": "53.384.900",
  "Longitude": "08.057.110",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.3849,8.05711",
  "id": "53.3849,8.05711"
}, {
  "Brand": "OMV",
  "Street": "Neuenstädter Str. 42",
  "PostCode": 74172,
  "City": "Neckarsulm",
  "Latitude": "49.195.750",
  "Longitude": "09.229.820",
  "Telephone": "+49-7132-6506",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.19575,9.22982",
  "id": "49.19575,9.22982"
}, {
  "Brand": "OMV",
  "Street": "Bodenseestr. 9",
  "PostCode": 88048,
  "City": "Friedrichshafen",
  "Latitude": "47.687.060",
  "Longitude": "09.490.840",
  "Telephone": "+49-7541-52410",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.68706,9.49084",
  "id": "47.68706,9.49084"
}, {
  "Brand": "ENI",
  "Street": "Welzheimer Strasse 22",
  "PostCode": 73614,
  "City": "Schorndorf",
  "Latitude": "48.814.250",
  "Longitude": "09.529.720",
  "Telephone": "+49 (0) 71819797",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.81425,9.52972",
  "id": "48.81425,9.52972"
}, {
  "Brand": "ARAL",
  "Street": "Salinenstraße 56",
  "PostCode": 99085,
  "City": "Erfurt",
  "Latitude": "51.006.840",
  "Longitude": "11.045.409",
  "Telephone": "036126232610",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.00684,11.045409",
  "id": "51.00684,11.045409"
}, {
  "Brand": "ARAL",
  "Street": "Hermannstraße 105-109",
  "PostCode": 44263,
  "City": "Dortmund",
  "Latitude": "51.487.391",
  "Longitude": "07.510.437",
  "Telephone": "0231422063",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "51.487391,7.510437",
  "id": "51.487391,7.510437"
}, {
  "Brand": "ENI",
  "Street": "Ostpreussendamm 89",
  "PostCode": 12207,
  "City": "Berlin",
  "Latitude": "52.415.032",
  "Longitude": "13.302.594",
  "Telephone": "+49 (0) 30755197",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "52.415032,13.302594",
  "id": "52.415032,13.302594"
}, {
  "Brand": "ARAL",
  "Street": "Drachenloch 3",
  "PostCode": 78052,
  "City": "Villingen-Schwenningen",
  "Latitude": "48.075.766",
  "Longitude": "08.491.648",
  "Telephone": "07721/9959187",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.075766,8.491648",
  "id": "48.075766,8.491648"
}, {
  "Brand": "OMV",
  "Street": "Am Aicher Feld 1",
  "PostCode": 84032,
  "City": "Altdorf",
  "Latitude": "48.565.820",
  "Longitude": "12.100.930",
  "Telephone": "+49-871-95389336",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.56582,12.10093",
  "id": "48.56582,12.10093"
}, {
  "Brand": "TOTAL",
  "Street": "CHAUSSEESTR. 61",
  "PostCode": 10115,
  "City": "BERLIN",
  "Latitude": "52.537.242",
  "Longitude": "13.375.376",
  "Telephone": "030-25762994",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.537242,13.375376",
  "id": "52.537242,13.375376"
}, {
  "Brand": "ARAL",
  "Street": "Friedrich-Engels-Straße 2",
  "PostCode": 51545,
  "City": "Waldbröl",
  "Latitude": "50.890.570",
  "Longitude": "07.647.413",
  "Telephone": "022912244",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.89057,7.647413",
  "id": "50.89057,7.647413"
}, {
  "Brand": "TOTAL",
  "Street": "INDUSTRIESTR.22",
  "PostCode": 67227,
  "City": "FRANKENTHAL",
  "Latitude": "49.551.375",
  "Longitude": "08.347.798",
  "Telephone": "06233-7370237",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.551375,8.347798",
  "id": "49.551375,8.347798"
}, {
  "Brand": "TOTAL",
  "Street": "HEINRICHPLATZ 3",
  "PostCode": 45888,
  "City": "GELSENKIRCHEN",
  "Latitude": "51.512.288",
  "Longitude": "07.111.116",
  "Telephone": "0209-1659850",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.512288,7.111116",
  "id": "51.512288,7.111116"
}, {
  "Brand": "TOTAL",
  "Street": "WERLER STR. 60",
  "PostCode": 58706,
  "City": "MENDEN",
  "Latitude": "51.447.844",
  "Longitude": "07.786.557",
  "Telephone": "02373-3544",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.447844,7.786557",
  "id": "51.447844,7.786557"
}, {
  "Brand": "TOTAL",
  "Street": "OLPER STR.41",
  "PostCode": 51491,
  "City": "OVERATH",
  "Latitude": "50.946.921",
  "Longitude": "07.217.666",
  "Telephone": "02204-73484",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.946921,7.217666",
  "id": "50.946921,7.217666"
}, {
  "Brand": "TOTAL",
  "Street": "BAB A 620 NORDSEITE",
  "PostCode": 66740,
  "City": "SAARLOUIS",
  "Latitude": "49.299.819",
  "Longitude": "06.749.409",
  "Telephone": "06831-1585",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.299819,6.749409",
  "id": "49.299819,6.749409"
}, {
  "Brand": "TOTAL",
  "Street": "OSTRING 140",
  "PostCode": 47574,
  "City": "GOCH",
  "Latitude": "51.672.499",
  "Longitude": "06.172.083",
  "Telephone": "02823-7406",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.672499,6.172083",
  "id": "51.672499,6.172083"
}, {
  "Brand": "ARAL",
  "Street": "Kocheler Straße 26",
  "PostCode": 83670,
  "City": "Bad Heilbrunn",
  "Latitude": "47.741.327",
  "Longitude": "11.452.968",
  "Telephone": "08046188045",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.741327,11.452968",
  "id": "47.741327,11.452968"
}, {
  "Brand": "TOTAL",
  "Street": "GRENZHOEFER WEG 35",
  "PostCode": 69123,
  "City": "HEIDELBERG",
  "Latitude": "49.424.057",
  "Longitude": "08.638.456",
  "Telephone": "06221-705929",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.424057,8.638456",
  "id": "49.424057,8.638456"
}, {
  "Brand": "TOTAL",
  "Street": "FUESSENER STR. 2A",
  "PostCode": 87471,
  "City": "DURACH",
  "Latitude": "47.699.386",
  "Longitude": "10.341.500",
  "Telephone": "0831-63279",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.699386,10.3415",
  "id": "47.699386,10.3415"
}, {
  "Brand": "ARAL",
  "Street": "Naheweinstraße 182",
  "PostCode": 55450,
  "City": "Langenlonsheim",
  "Latitude": "49.901.101",
  "Longitude": "07.899.361",
  "Telephone": "06704961595",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.901101,7.899361",
  "id": "49.901101,7.899361"
}, {
  "Brand": "ENI",
  "Street": "Kreisstrasse",
  "PostCode": 66578,
  "City": "Schiffweiler",
  "Latitude": "49.353.910",
  "Longitude": "07.139.620",
  "Telephone": "+49 (0) 68219637",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.35391,7.13962",
  "id": "49.35391,7.13962"
}, {
  "Brand": "TOTAL",
  "Street": "UNTERE WIESEN 2",
  "PostCode": 76437,
  "City": "RASTATT",
  "Latitude": "48.861.316",
  "Longitude": "08.239.667",
  "Telephone": "07222-830839",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.861316,8.239667",
  "id": "48.861316,8.239667"
}, {
  "Brand": "Westfalen",
  "Street": "Dorfstr. 4",
  "PostCode": 26427,
  "City": "NEUHARLINGERSIEL",
  "Latitude": "53.684.100",
  "Longitude": "07.686.460",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.6841,7.68646",
  "id": "53.6841,7.68646"
}, {
  "Brand": "ARAL",
  "Street": "Schellweilerstraße 1",
  "PostCode": 66871,
  "City": "Konken",
  "Latitude": "49.513.780",
  "Longitude": "07.372.080",
  "Telephone": "063849933155",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "49.51378,7.37208",
  "id": "49.51378,7.37208"
}, {
  "Brand": "TOTAL",
  "Street": "AM ETZFELD 2",
  "PostCode": 85669,
  "City": "PASTETTEN",
  "Latitude": "48.210.910",
  "Longitude": "11.954.200",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.21091,11.9542",
  "id": "48.21091,11.9542"
}, {
  "Brand": "ENI",
  "Street": "Herborner Str. 34",
  "PostCode": 35096,
  "City": "Weimar/Nieder-Weimar",
  "Latitude": "50.760.005",
  "Longitude": "08.733.406",
  "Telephone": "+49 (0) 64217615",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.760005,8.733406",
  "id": "50.760005,8.733406"
}, {
  "Brand": "TOTAL",
  "Street": "HOHENSTEINER STR.44",
  "PostCode": 9385,
  "City": "LUGAU",
  "Latitude": "50.746.735",
  "Longitude": "12.741.940",
  "Telephone": "037295-41978",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.746735,12.74194",
  "id": "50.746735,12.74194"
}, {
  "Brand": "TOTAL",
  "Street": "FORSTSTRASSE 15",
  "PostCode": 40597,
  "City": "DUESSELDORF",
  "Latitude": "51.168.945",
  "Longitude": "06.875.034",
  "Telephone": "0211-7100357",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.168945,6.875034",
  "id": "51.168945,6.875034"
}, {
  "Brand": "ENI",
  "Street": "Mannheimer Str. 2",
  "PostCode": 67134,
  "City": "Birkenheide",
  "Latitude": "49.479.830",
  "Longitude": "08.264.470",
  "Telephone": "+49 (0) 62373301",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.47983,8.26447",
  "id": "49.47983,8.26447"
}, {
  "Brand": "TOTAL",
  "Street": "KOLPINGSTR.32",
  "PostCode": 74081,
  "City": "HEILBRONN",
  "Latitude": "49.122.806",
  "Longitude": "09.195.898",
  "Telephone": "07131-255313",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.122806,9.195898",
  "id": "49.122806,9.195898"
}, {
  "Brand": "TOTAL",
  "Street": "MARGARETE-SOMMER-STR. 2",
  "PostCode": 10407,
  "City": "BERLIN",
  "Latitude": "52.530.831",
  "Longitude": "13.440.946",
  "Telephone": "030-42852514",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.530831,13.440946",
  "id": "52.530831,13.440946"
}, {
  "Brand": "ARAL",
  "Street": "Ilexstraße",
  "PostCode": 26639,
  "City": "Wiesmoor",
  "Latitude": "53.375.628",
  "Longitude": "07.749.806",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.375628,7.749806",
  "id": "53.375628,7.749806"
}, {
  "Brand": "TOTAL",
  "Street": "LEHER HEERSTR. 98",
  "PostCode": 28359,
  "City": "BREMEN",
  "Latitude": "53.101.569",
  "Longitude": "08.878.291",
  "Telephone": "0421-234565",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.101569,8.878291",
  "id": "53.101569,8.878291"
}, {
  "Brand": "TOTAL",
  "Street": "BAHNHOFSTR. 30",
  "PostCode": 9366,
  "City": "STOLLBERG",
  "Latitude": "50.699.668",
  "Longitude": "12.766.919",
  "Telephone": "037296884265",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.699668,12.766919",
  "id": "50.699668,12.766919"
}, {
  "Brand": "ARAL",
  "Street": "An der Westumgehung 1",
  "PostCode": 85521,
  "City": "Ottobrunn",
  "Latitude": "48.072.033",
  "Longitude": "11.652.264",
  "Telephone": "08962749631",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "05:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.072033,11.652264",
  "id": "48.072033,11.652264"
}, {
  "Brand": "TOTAL",
  "Street": "BINDERSLEBENER LANDSTR. 98",
  "PostCode": 99092,
  "City": "ERFURT",
  "Latitude": "50.972.397",
  "Longitude": "10.972.779",
  "Telephone": "0361-2229393",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.972397,10.972779",
  "id": "50.972397,10.972779"
}, {
  "Brand": "ENI",
  "Street": "Magdeburger Chaussee 83",
  "PostCode": 39288,
  "City": "Burg",
  "Latitude": "52.264.640",
  "Longitude": "11.846.450",
  "Telephone": "+49 (0) 39219448",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.26464,11.84645",
  "id": "52.26464,11.84645"
}, {
  "Brand": "TOTAL",
  "Street": "An der B 62 Nr. 2",
  "PostCode": 36469,
  "City": "BAD SALZUNGEN",
  "Latitude": "50.825.771",
  "Longitude": "10.160.083",
  "Telephone": "03695-82010",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.825771,10.160083",
  "id": "50.825771,10.160083"
}, {
  "Brand": "TOTAL",
  "Street": "PFORTZER STR. 1",
  "PostCode": 76744,
  "City": "WOERTH",
  "Latitude": "49.038.298",
  "Longitude": "08.289.295",
  "Telephone": "07271-41041",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.038298,8.289295",
  "id": "49.038298,8.289295"
}, {
  "Brand": "TOTAL",
  "Street": "SPAETHSTR. 158",
  "PostCode": 12359,
  "City": "BERLIN",
  "Latitude": "52.453.355",
  "Longitude": "13.457.640",
  "Telephone": "030/43208977",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.453355,13.45764",
  "id": "52.453355,13.45764"
}, {
  "Brand": "TOTAL",
  "Street": "ROSSERTSTR. 2",
  "PostCode": 65451,
  "City": "KELSTERBACH",
  "Latitude": "50.066.025",
  "Longitude": "08.537.291",
  "Telephone": "06107-3013911",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.066025,8.537291",
  "id": "50.066025,8.537291"
}, {
  "Brand": "ENI",
  "Street": "Bezirksstrae 29",
  "PostCode": 85716,
  "City": "Unterschleiheim",
  "Latitude": "48.281.760",
  "Longitude": "11.575.789",
  "Telephone": "+49 (0) 89310511",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.28176,11.575789",
  "id": "48.28176,11.575789"
}, {
  "Brand": "TOTAL",
  "Street": "FRIEDRICHSTR. 41",
  "PostCode": 54516,
  "City": "WITTLICH",
  "Latitude": "49.988.330",
  "Longitude": "06.899.069",
  "Telephone": "06571-7277",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.98833,6.899069",
  "id": "49.98833,6.899069"
}, {
  "Brand": "TOTAL",
  "Street": "KUPFERDREHERSTR.50-52",
  "PostCode": 45257,
  "City": "ESSEN",
  "Latitude": "51.397.817",
  "Longitude": "07.085.411",
  "Telephone": "0201-43709970",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.397817,7.085411",
  "id": "51.397817,7.085411"
}, {
  "Brand": "OMV",
  "Street": "Kastenseestr. 6",
  "PostCode": 85625,
  "City": "Glonn",
  "Latitude": "47.987.770",
  "Longitude": "11.863.130",
  "Telephone": "+49-8093-5320",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.98777,11.86313",
  "id": "47.98777,11.86313"
}, {
  "Brand": "Westfalen",
  "Street": "Braukstr. 160",
  "PostCode": 45892,
  "City": "BAT RESSER MARK NORD GELSENKIRCHEN",
  "Latitude": "51.570.900",
  "Longitude": "07.104.510",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.5709,7.10451",
  "id": "51.5709,7.10451"
}, {
  "Brand": "OMV",
  "Street": "Nürnberger Str. 10",
  "PostCode": 86609,
  "City": "Donauwörth",
  "Latitude": "48.730.490",
  "Longitude": "10.778.380",
  "Telephone": "+49-906-9998254",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.73049,10.77838",
  "id": "48.73049,10.77838"
}, {
  "Brand": "ARAL",
  "Street": "A1",
  "PostCode": 21109,
  "City": "Hamburg",
  "Latitude": "53.493.810",
  "Longitude": "10.033.953",
  "Telephone": "0407544131",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.49381,10.033953",
  "id": "53.49381,10.033953"
}, {
  "Brand": "ARAL",
  "Street": "A5",
  "PostCode": 77767,
  "City": "Appenweier",
  "Latitude": "48.561.717",
  "Longitude": "07.956.888",
  "Telephone": "078052949",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.561717,7.956888",
  "id": "48.561717,7.956888"
}, {
  "Brand": "TOTAL",
  "Street": "AN DER MAGISTRALE 2",
  "PostCode": 6124,
  "City": "HALLE",
  "Latitude": "51.481.699",
  "Longitude": "11.945.292",
  "Telephone": "0345-8044362",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.481699,11.945292",
  "id": "51.481699,11.945292"
}, {
  "Brand": "ARAL",
  "Street": "A7",
  "PostCode": 21077,
  "City": "Hamburg",
  "Latitude": "53.423.469",
  "Longitude": "09.967.088",
  "Telephone": "04076115317",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:50",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:50",
  "IsOpen24Hours": "Ja",
  "location": "53.423469,9.967088",
  "id": "53.423469,9.967088"
}, {
  "Brand": "TOTAL",
  "Street": "LORCHER STR. 67",
  "PostCode": 73033,
  "City": "GOEPPINGEN",
  "Latitude": "48.711.395",
  "Longitude": "09.648.948",
  "Telephone": "07161-25770",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.711395,9.648948",
  "id": "48.711395,9.648948"
}, {
  "Brand": "ARAL",
  "Street": "IM BIRKENGRUND",
  "PostCode": 63179,
  "City": "OBERTSHAUSEN",
  "Latitude": "50.062.576",
  "Longitude": "08.829.700",
  "Telephone": "06104/4052680",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.062576,8.8297",
  "id": "50.062576,8.8297"
}, {
  "Brand": "ENI",
  "Street": "Wallensteinstrasse 90",
  "PostCode": 90431,
  "City": "Nuernberg",
  "Latitude": "49.437.500",
  "Longitude": "11.031.400",
  "Telephone": "+49 (0) 91161422",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.4375,11.0314",
  "id": "49.4375,11.0314"
}, {
  "Brand": "ARAL",
  "Street": "Hixberger Straße 28 a",
  "PostCode": 66292,
  "City": "Riegelsberg",
  "Latitude": "49.295.276",
  "Longitude": "06.934.298",
  "Telephone": "068063660",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.295276,6.934298",
  "id": "49.295276,6.934298"
}, {
  "Brand": "OMV",
  "Street": "Römerstr. 1",
  "PostCode": 71296,
  "City": "Heimsheim",
  "Latitude": "48.824.220",
  "Longitude": "08.868.530",
  "Telephone": "+49-7033-138995",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.82422,8.86853",
  "id": "48.82422,8.86853"
}, {
  "Brand": "ARAL",
  "Street": "Berliner Allee 7",
  "PostCode": 39326,
  "City": "Hohenwarsleben",
  "Latitude": "52.177.071",
  "Longitude": "11.490.125",
  "Telephone": "03920460849",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "0",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "0",
  "SundayFrom": "00:00",
  "SundayTo": "0",
  "IsOpen24Hours": "Ja",
  "location": "52.177071,11.490125",
  "id": "52.177071,11.490125"
}, {
  "Brand": "ARAL",
  "Street": "Viktoriastraße 5",
  "PostCode": 42929,
  "City": "Wermelskirchen",
  "Latitude": "51.134.884",
  "Longitude": "07.199.846",
  "Telephone": "01786334749",
  "WeekDayFrom": "08:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "08:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "51.134884,7.199846",
  "id": "51.134884,7.199846"
}, {
  "Brand": "TOTAL",
  "Street": "RHEINSTR. 31",
  "PostCode": 56179,
  "City": "VALLENDAR",
  "Latitude": "50.393.150",
  "Longitude": "07.616.820",
  "Telephone": "0261-96371544",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.39315,7.61682",
  "id": "50.39315,7.61682"
}, {
  "Brand": "ENI",
  "Street": "Radmeisterstrasse 1",
  "PostCode": 90455,
  "City": "Nuernberg",
  "Latitude": "49.370.930",
  "Longitude": "11.077.740",
  "Telephone": "+49 (0) 91188318",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.37093,11.07774",
  "id": "49.37093,11.07774"
}, {
  "Brand": "ENI",
  "Street": "Freiherr-vom-Stein-Str. 30",
  "PostCode": 99734,
  "City": "Nordhausen",
  "Latitude": "51.495.460",
  "Longitude": "10.778.230",
  "Telephone": "+49 (0) 36319029",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.49546,10.77823",
  "id": "51.49546,10.77823"
}, {
  "Brand": "OMV",
  "Street": "Spessartstr. 74",
  "PostCode": 63743,
  "City": "Aschaffenburg",
  "Latitude": "49.964.770",
  "Longitude": "09.151.190",
  "Telephone": "+49-6021-91724",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.96477,9.15119",
  "id": "49.96477,9.15119"
}, {
  "Brand": "TOTAL",
  "Street": "AUTOHOF - AM SCHLUETERSHOF 27",
  "PostCode": 47059,
  "City": "DUISBURG",
  "Latitude": "51.437.949",
  "Longitude": "06.738.798",
  "Telephone": "0203-314086",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.437949,6.738798",
  "id": "51.437949,6.738798"
}, {
  "Brand": "ARAL",
  "Street": "A 30",
  "PostCode": 49328,
  "City": "Melle",
  "Latitude": "52.196.053",
  "Longitude": "08.384.381",
  "Telephone": "0542242010",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.196053,8.384381",
  "id": "52.196053,8.384381"
}, {
  "Brand": "OMV",
  "Street": "Kemptener Str. 14-16",
  "PostCode": 88131,
  "City": "Lindau",
  "Latitude": "47.554.810",
  "Longitude": "09.704.920",
  "Telephone": "+49-8382-73558",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "47.55481,9.70492",
  "id": "47.55481,9.70492"
}, {
  "Brand": "ENI",
  "Street": "Innsbrucker Ring 149",
  "PostCode": 81669,
  "City": "Muenchen",
  "Latitude": "48.114.351",
  "Longitude": "11.617.610",
  "Telephone": "+49 (0) 89689199",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.114351,11.61761",
  "id": "48.114351,11.61761"
}, {
  "Brand": "TOTAL",
  "Street": "BAEUMCHEN 15",
  "PostCode": 6667,
  "City": "WEISSENFELS",
  "Latitude": "51.268.721",
  "Longitude": "11.978.313",
  "Telephone": "03443-3383245",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.268721,11.978313",
  "id": "51.268721,11.978313"
}, {
  "Brand": "TOTAL",
  "Street": "EICHENBUEHLER STR. 52",
  "PostCode": 63897,
  "City": "MILTENBERG",
  "Latitude": "49.704.410",
  "Longitude": "09.267.278",
  "Telephone": "09371-3885",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.70441,9.267278",
  "id": "49.70441,9.267278"
}, {
  "Brand": "ARAL",
  "Street": "Lütticher Str. 1",
  "PostCode": 53842,
  "City": "Troisdorf",
  "Latitude": "50.838.468",
  "Longitude": "07.113.518",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.838468,7.113518",
  "id": "50.838468,7.113518"
}, {
  "Brand": "ARAL",
  "Street": "B 3 / Am Krekel 3",
  "PostCode": 35039,
  "City": "Marburg",
  "Latitude": "50.793.705",
  "Longitude": "08.760.760",
  "Telephone": "06421165036",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.793705,8.76076",
  "id": "50.793705,8.76076"
}, {
  "Brand": "TOTAL",
  "Street": "AM SPORTPARK 32",
  "PostCode": 58097,
  "City": "HAGEN",
  "Latitude": "51.368.975",
  "Longitude": "07.475.240",
  "Telephone": "02331-81616",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.368975,7.47524",
  "id": "51.368975,7.47524"
}, {
  "Brand": "TOTAL",
  "Street": "B14/MARBACHER STR.",
  "PostCode": 71364,
  "City": "WINNENDEN",
  "Latitude": "48.882.440",
  "Longitude": "09.386.690",
  "Telephone": "071955-988995",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.88244,9.38669",
  "id": "48.88244,9.38669"
}, {
  "Brand": "ARAL",
  "Street": "Gewerbepark 2",
  "PostCode": 91350,
  "City": "Gremsdorf",
  "Latitude": "49.698.492",
  "Longitude": "10.854.734",
  "Telephone": "09193504495",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.698492,10.854734",
  "id": "49.698492,10.854734"
}, {
  "Brand": "Westfalen",
  "Street": "Düsseldorfer Str. 2",
  "PostCode": 47918,
  "City": "TOENISVORST",
  "Latitude": "51.323.100",
  "Longitude": "06.472.720",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.3231,6.47272",
  "id": "51.3231,6.47272"
}, {
  "Brand": "TOTAL",
  "Street": "BAB A6/FRANKENHOEHE SUED",
  "PostCode": 91589,
  "City": "AURACH",
  "Latitude": "49.241.490",
  "Longitude": "10.352.200",
  "Telephone": "09804-919150",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.24149,10.3522",
  "id": "49.24149,10.3522"
}, {
  "Brand": "OMV",
  "Street": "Hauptstr. 10",
  "PostCode": 82490,
  "City": "Farchant",
  "Latitude": "47.531.280",
  "Longitude": "11.112.620",
  "Telephone": "+49-8821-6466",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.53128,11.11262",
  "id": "47.53128,11.11262"
}, {
  "Brand": "ARAL",
  "Street": "Illerstraße 12",
  "PostCode": 87527,
  "City": "Sonthofen",
  "Latitude": "47.523.716",
  "Longitude": "10.262.306",
  "Telephone": "083213322",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.523716,10.262306",
  "id": "47.523716,10.262306"
}, {
  "Brand": "TOTAL",
  "Street": "FRIEDRICH-EBERT-STR. 12-14",
  "PostCode": 42549,
  "City": "VELBERT",
  "Latitude": "51.334.795",
  "Longitude": "07.048.918",
  "Telephone": "02051-255409",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.334795,7.048918",
  "id": "51.334795,7.048918"
}, {
  "Brand": "ENI",
  "Street": "Manchinger Str. 115",
  "PostCode": 85053,
  "City": "Ingolstadt",
  "Latitude": "48.744.920",
  "Longitude": "11.470.450",
  "Telephone": "+49 (0) 84162883",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.74492,11.47045",
  "id": "48.74492,11.47045"
}, {
  "Brand": "TOTAL",
  "Street": "OBOTRITENRING 19 A",
  "PostCode": 19053,
  "City": "SCHWERIN",
  "Latitude": "53.638.696",
  "Longitude": "11.404.074",
  "Telephone": "0385-77313",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.638696,11.404074",
  "id": "53.638696,11.404074"
}, {
  "Brand": "ARAL",
  "Street": "Meraner Straße 11",
  "PostCode": 86165,
  "City": "Augsburg",
  "Latitude": "48.381.623",
  "Longitude": "10.932.425",
  "Telephone": "08217292985",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.381623,10.932425",
  "id": "48.381623,10.932425"
}, {
  "Brand": "ARAL",
  "Street": "Nickerner Weg 8",
  "PostCode": 1257,
  "City": "Dresden",
  "Latitude": "50.993.878",
  "Longitude": "13.797.370",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.993878,13.79737",
  "id": "50.993878,13.79737"
}, {
  "Brand": "TOTAL",
  "Street": "VON KETTELER STR. 13",
  "PostCode": 33106,
  "City": "PADERBORN",
  "Latitude": "51.733.849",
  "Longitude": "08.689.568",
  "Telephone": "05254-5150",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.733849,8.689568",
  "id": "51.733849,8.689568"
}, {
  "Brand": "OMV",
  "Street": "Regensburgerstr. 91",
  "PostCode": 94315,
  "City": "Straubing",
  "Latitude": "48.880.250",
  "Longitude": "12.552.740",
  "Telephone": "+49-9421-22302",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.88025,12.55274",
  "id": "48.88025,12.55274"
}, {
  "Brand": "ENI",
  "Street": "Maroldstrasse 64",
  "PostCode": 85229,
  "City": "Markt Indersdorf",
  "Latitude": "48.354.920",
  "Longitude": "11.387.830",
  "Telephone": "+49 (0) 81366479",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.35492,11.38783",
  "id": "48.35492,11.38783"
}, {
  "Brand": "TOTAL",
  "Street": "LANGER WEG 17",
  "PostCode": 1257,
  "City": "DRESDEN",
  "Latitude": "51.006.899",
  "Longitude": "13.809.999",
  "Telephone": "0351-2753023",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.006899,13.809999",
  "id": "51.006899,13.809999"
}, {
  "Brand": "OMV",
  "Street": "Albert-Mayer-Str. 22",
  "PostCode": 83052,
  "City": "Bruckmühl",
  "Latitude": "47.882.080",
  "Longitude": "11.932.920",
  "Telephone": "+49-8062-7187",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.88208,11.93292",
  "id": "47.88208,11.93292"
}, {
  "Brand": "OMV",
  "Street": "Waldhäuser Str. 122",
  "PostCode": 72076,
  "City": "Tübingen",
  "Latitude": "48.545.590",
  "Longitude": "09.056.060",
  "Telephone": "+49-7071-63299",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.54559,9.05606",
  "id": "48.54559,9.05606"
}, {
  "Brand": "OMV",
  "Street": "Wilhelmstr. 130",
  "PostCode": 72461,
  "City": "Albstadt",
  "Latitude": "48.276.370",
  "Longitude": "09.000.090",
  "Telephone": "+49-7432-21717",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.27637,9.00009",
  "id": "48.27637,9.00009"
}, {
  "Brand": "Westfalen",
  "Street": "Neue Ritterstr. 41",
  "PostCode": 47805,
  "City": "KREFELD",
  "Latitude": "51.328.900",
  "Longitude": "06.586.390",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.3289,6.58639",
  "id": "51.3289,6.58639"
}, {
  "Brand": "ARAL",
  "Street": "Magdeburger Landstraße 14 a",
  "PostCode": 39638,
  "City": "Gardelegen",
  "Latitude": "52.517.836",
  "Longitude": "11.412.474",
  "Telephone": "039077799377",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.517836,11.412474",
  "id": "52.517836,11.412474"
}, {
  "Brand": "ARAL",
  "Street": "Lindenstraße 1",
  "PostCode": 89284,
  "City": "Pfaffenhofen",
  "Latitude": "48.358.701",
  "Longitude": "10.160.148",
  "Telephone": "07302919086",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "48.358701,10.160148",
  "id": "48.358701,10.160148"
}, {
  "Brand": "ARAL",
  "Street": "Breslauer Straße 2",
  "PostCode": 67245,
  "City": "Lambsheim",
  "Latitude": "49.504.823",
  "Longitude": "08.291.397",
  "Telephone": "06233579820",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.504823,8.291397",
  "id": "49.504823,8.291397"
}, {
  "Brand": "TOTAL",
  "Street": "MERSEBURGER STR.31 b",
  "PostCode": 6632,
  "City": "FREYBURG",
  "Latitude": "51.220.685",
  "Longitude": "11.772.799",
  "Telephone": "034464-28271",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.220685,11.772799",
  "id": "51.220685,11.772799"
}, {
  "Brand": "TOTAL",
  "Street": "BOSCHSTR. 2",
  "PostCode": 55283,
  "City": "NIERSTEIN",
  "Latitude": "49.863.279",
  "Longitude": "08.332.962",
  "Telephone": "06133-491802",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.863279,8.332962",
  "id": "49.863279,8.332962"
}, {
  "Brand": "ARAL",
  "Street": "A 1",
  "PostCode": 50354,
  "City": "Hürth",
  "Latitude": "50.857.101",
  "Longitude": "06.806.151",
  "Telephone": "022371335",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.857101,6.806151",
  "id": "50.857101,6.806151"
}, {
  "Brand": "ARAL",
  "Street": "Burggrafenstraße",
  "PostCode": 45139,
  "City": "Essen",
  "Latitude": "51.462.167",
  "Longitude": "07.030.121",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.462167,7.030121",
  "id": "51.462167,7.030121"
}, {
  "Brand": "TOTAL",
  "Street": "ROERMONDER STR. 315",
  "PostCode": 52072,
  "City": "AACHEN",
  "Latitude": "50.796.132",
  "Longitude": "06.063.573",
  "Telephone": "0241-12773",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.796132,6.063573",
  "id": "50.796132,6.063573"
}, {
  "Brand": "TOTAL",
  "Street": "WACKENBERGSTR. 2-6",
  "PostCode": 13156,
  "City": "BERLIN",
  "Latitude": "52.582.894",
  "Longitude": "13.404.284",
  "Telephone": "030-4765891",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.582894,13.404284",
  "id": "52.582894,13.404284"
}, {
  "Brand": "TOTAL",
  "Street": "PETUELRING 92",
  "PostCode": 80807,
  "City": "MUENCHEN",
  "Latitude": "48.178.160",
  "Longitude": "11.572.129",
  "Telephone": "089-3595277",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.17816,11.572129",
  "id": "48.17816,11.572129"
}, {
  "Brand": "TOTAL",
  "Street": "WEILSTRASSE 112",
  "PostCode": 35789,
  "City": "WEILMUENSTER",
  "Latitude": "50.434.100",
  "Longitude": "08.374.490",
  "Telephone": "06472-8313603",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.4341,8.37449",
  "id": "50.4341,8.37449"
}, {
  "Brand": "ARAL",
  "Street": "Junginger Straße 11",
  "PostCode": 89081,
  "City": "Ulm",
  "Latitude": "48.436.443",
  "Longitude": "09.970.989",
  "Telephone": "07319609671",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "19:30",
  "SundayFrom": "08:00",
  "SundayTo": "18:30",
  "IsOpen24Hours": "Nein",
  "location": "48.436443,9.970989",
  "id": "48.436443,9.970989"
}, {
  "Brand": "ARAL",
  "Street": "Siemensring 41",
  "PostCode": 47877,
  "City": "Willich",
  "Latitude": "51.270.118",
  "Longitude": "06.512.013",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.270118,6.512013",
  "id": "51.270118,6.512013"
}, {
  "Brand": "TOTAL",
  "Street": "ROSA-LUXEMBURG-STR. 133",
  "PostCode": 6917,
  "City": "JESSEN",
  "Latitude": "51.797.243",
  "Longitude": "12.987.430",
  "Telephone": "03537-212408",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.797243,12.98743",
  "id": "51.797243,12.98743"
}, {
  "Brand": "TOTAL",
  "Street": "RUHRORTER STR. 65",
  "PostCode": 46049,
  "City": "OBERHAUSEN",
  "Latitude": "51.477.426",
  "Longitude": "06.830.007",
  "Telephone": "0208-807951",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.477426,6.830007",
  "id": "51.477426,6.830007"
}, {
  "Brand": "ARAL",
  "Street": "SIMBACHER STRAßE 36",
  "PostCode": 84524,
  "City": "NEUÖTTING",
  "Latitude": "48.239.864",
  "Longitude": "12.710.528",
  "Telephone": "086718839794",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.239864,12.710528",
  "id": "48.239864,12.710528"
}, {
  "Brand": "TOTAL",
  "Street": "BAHNHOFSTR.23",
  "PostCode": 19273,
  "City": "NEUHAUS",
  "Latitude": "53.286.863",
  "Longitude": "10.936.809",
  "Telephone": "038841-20733",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.286863,10.936809",
  "id": "53.286863,10.936809"
}, {
  "Brand": "TOTAL",
  "Street": "CHAUSSEESTRASSE 79",
  "PostCode": 18356,
  "City": "BARTH",
  "Latitude": "54.353.473",
  "Longitude": "12.724.514",
  "Telephone": "038231-2023",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.353473,12.724514",
  "id": "54.353473,12.724514"
}, {
  "Brand": "ARAL",
  "Street": "Riemer Straße 339",
  "PostCode": 81829,
  "City": "München",
  "Latitude": "48.140.538",
  "Longitude": "11.679.848",
  "Telephone": "089908681",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.140538,11.679848",
  "id": "48.140538,11.679848"
}, {
  "Brand": "TOTAL",
  "Street": "DARMSTAEDTER STR. 92",
  "PostCode": 63303,
  "City": "DREIEICH",
  "Latitude": "50.008.819",
  "Longitude": "08.689.436",
  "Telephone": "06103-697050",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.008819,8.689436",
  "id": "50.008819,8.689436"
}, {
  "Brand": "ARAL",
  "Street": "Rheiner Str. 176 A",
  "PostCode": 49809,
  "City": "Lingen",
  "Latitude": "52.497.175",
  "Longitude": "07.328.970",
  "Telephone": "059161047865",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.497175,7.32897",
  "id": "52.497175,7.32897"
}, {
  "Brand": "ARAL",
  "Street": "Zeche Crone 2",
  "PostCode": 44265,
  "City": "Dortmund",
  "Latitude": "51.472.330",
  "Longitude": "07.485.391",
  "Telephone": "023142788770",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.47233,7.485391",
  "id": "51.47233,7.485391"
}, {
  "Brand": "ENI",
  "Street": "Flughafen Nordallee 45",
  "PostCode": 85356,
  "City": "Muenchen",
  "Latitude": "48.353.954",
  "Longitude": "11.749.376",
  "Telephone": "+49 (0) 89970518",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.353954,11.749376",
  "id": "48.353954,11.749376"
}, {
  "Brand": "OMV",
  "Street": "Bahnhofstr. 53",
  "PostCode": 87435,
  "City": "Kempten",
  "Latitude": "47.718.090",
  "Longitude": "10.313.980",
  "Telephone": "+49-831-22707",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.71809,10.31398",
  "id": "47.71809,10.31398"
}, {
  "Brand": "ARAL",
  "Street": "Schneeberger Straße 83 a",
  "PostCode": 8112,
  "City": "Wilkau-Haßlau",
  "Latitude": "50.673.066",
  "Longitude": "12.528.272",
  "Telephone": "0375661265",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.673066,12.528272",
  "id": "50.673066,12.528272"
}, {
  "Brand": "TOTAL",
  "Street": "ESCHOLLBRUECKER STR.47",
  "PostCode": 64319,
  "City": "PFUNGSTADT",
  "Latitude": "49.804.243",
  "Longitude": "08.583.557",
  "Telephone": "061579390703",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.804243,8.583557",
  "id": "49.804243,8.583557"
}, {
  "Brand": "TOTAL",
  "Street": "SCHUTTERSTR. 3",
  "PostCode": 77746,
  "City": "SCHUTTERWALD",
  "Latitude": "48.465.678",
  "Longitude": "07.879.933",
  "Telephone": "0781-96560",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.465678,7.879933",
  "id": "48.465678,7.879933"
}, {
  "Brand": "ARAL",
  "Street": "Lütgendortmunder Hellweg 200/a.d A4",
  "PostCode": 44892,
  "City": "Bochum",
  "Latitude": "51.494.024",
  "Longitude": "07.339.435",
  "Telephone": "023432589808",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.494024,7.339435",
  "id": "51.494024,7.339435"
}, {
  "Brand": "TOTAL",
  "Street": "OBERNEULANDER HEERSTR. 38",
  "PostCode": 28355,
  "City": "BREMEN",
  "Latitude": "53.100.367",
  "Longitude": "08.902.807",
  "Telephone": "0421-251443",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.100367,8.902807",
  "id": "53.100367,8.902807"
}, {
  "Brand": "TOTAL",
  "Street": "EICHSTAEDTER CHAUSSEE 4",
  "PostCode": 16727,
  "City": "OBERKRAEMER OT VEHLEFANZ",
  "Latitude": "52.708.687",
  "Longitude": "13.106.989",
  "Telephone": "03304-502308",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.708687,13.106989",
  "id": "52.708687,13.106989"
}, {
  "Brand": "ARAL",
  "Street": "Gewerbegebiet Am Dachsberg 8",
  "PostCode": 53604,
  "City": "Bad Honnef",
  "Latitude": "50.647.984",
  "Longitude": "07.334.606",
  "Telephone": "022249897088",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.647984,7.334606",
  "id": "50.647984,7.334606"
}, {
  "Brand": "ARAL",
  "Street": "Situlistraße 36",
  "PostCode": 80939,
  "City": "München",
  "Latitude": "48.190.668",
  "Longitude": "11.616.557",
  "Telephone": "08932195426",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.190668,11.616557",
  "id": "48.190668,11.616557"
}, {
  "Brand": "TOTAL",
  "Street": "MITTELHAEUSER STR. 69",
  "PostCode": 99089,
  "City": "ERFURT",
  "Latitude": "51.009.575",
  "Longitude": "11.018.028",
  "Telephone": "0361-7920622",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.009575,11.018028",
  "id": "51.009575,11.018028"
}, {
  "Brand": "TOTAL",
  "Street": "VENLOER STR. 46",
  "PostCode": 41569,
  "City": "ROMMERSKIRCHEN",
  "Latitude": "51.034.381",
  "Longitude": "06.687.699",
  "Telephone": "02183-9530",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.034381,6.687699",
  "id": "51.034381,6.687699"
}, {
  "Brand": "TOTAL",
  "Street": "AUTOHOF NORD-LIPPE STR. 44",
  "PostCode": 59368,
  "City": "WERNE-STOCKUM",
  "Latitude": "51.698.549",
  "Longitude": "07.673.591",
  "Telephone": "02389-780256",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.698549,7.673591",
  "id": "51.698549,7.673591"
}, {
  "Brand": "TOTAL",
  "Street": "ZUR KUHBRUECKE 1",
  "PostCode": 1458,
  "City": "OTTENDORF-OKRILLA",
  "Latitude": "51.174.726",
  "Longitude": "13.854.034",
  "Telephone": "03520/5189791",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.174726,13.854034",
  "id": "51.174726,13.854034"
}, {
  "Brand": "TOTAL",
  "Street": "WERRASTR. 1",
  "PostCode": 98617,
  "City": "MEININGEN",
  "Latitude": "50.559.459",
  "Longitude": "10.413.132",
  "Telephone": "03693-476431",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.559459,10.413132",
  "id": "50.559459,10.413132"
}, {
  "Brand": "ARAL",
  "Street": "Langenbacher Straße 18",
  "PostCode": 56470,
  "City": "Bad Marienberg",
  "Latitude": "50.648.029",
  "Longitude": "07.953.069",
  "Telephone": "02661931012",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.648029,7.953069",
  "id": "50.648029,7.953069"
}, {
  "Brand": "TOTAL",
  "Street": "TESSINER STR. 98",
  "PostCode": 18055,
  "City": "ROSTOCK",
  "Latitude": "54.080.731",
  "Longitude": "12.189.121",
  "Telephone": "0381-683783",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.080731,12.189121",
  "id": "54.080731,12.189121"
}, {
  "Brand": "ENI",
  "Street": "Lise-Meitner-Strasse 2",
  "PostCode": 82110,
  "City": "Germering",
  "Latitude": "48.140.670",
  "Longitude": "11.368.560",
  "Telephone": "089/89408492",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.14067,11.36856",
  "id": "48.14067,11.36856"
}, {
  "Brand": "Westfalen",
  "Street": "Hauptstr. 455",
  "PostCode": 26689,
  "City": "APEN",
  "Latitude": "53.216.600",
  "Longitude": "07.762.520",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.2166,7.76252",
  "id": "53.2166,7.76252"
}, {
  "Brand": "OMV",
  "Street": "Hauptstr. 17",
  "PostCode": 90596,
  "City": "Schwanstetten",
  "Latitude": "49.324.860",
  "Longitude": "11.123.150",
  "Telephone": "+49-9170-977324",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.32486,11.12315",
  "id": "49.32486,11.12315"
}, {
  "Brand": "ENI",
  "Street": "Augsburger Strasse 231",
  "PostCode": 70327,
  "City": "Stuttgart",
  "Latitude": "48.791.240",
  "Longitude": "09.243.890",
  "Telephone": "0711/32770830",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.79124,9.24389",
  "id": "48.79124,9.24389"
}, {
  "Brand": "TOTAL",
  "Street": "FRANKFURTER STR.2",
  "PostCode": 35440,
  "City": "LINDEN",
  "Latitude": "50.532.499",
  "Longitude": "08.649.999",
  "Telephone": "06403-2522",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.532499,8.649999",
  "id": "50.532499,8.649999"
}, {
  "Brand": "Westfalen",
  "Street": "Rheiner Str.5",
  "PostCode": 48455,
  "City": "BAD BENTHEIM",
  "Latitude": "52.304.700",
  "Longitude": "07.150.610",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.3047,7.15061",
  "id": "52.3047,7.15061"
}, {
  "Brand": "ENI",
  "Street": "Frankfurter Strasse 110",
  "PostCode": 63150,
  "City": "Heusenstamm",
  "Latitude": "50.058.590",
  "Longitude": "08.794.070",
  "Telephone": "+49 (0) 61046182",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.05859,8.79407",
  "id": "50.05859,8.79407"
}, {
  "Brand": "ARAL",
  "Street": "Moosbronner Straße 37",
  "PostCode": 76275,
  "City": "Ettlingen OT Schöllb",
  "Latitude": "48.894.990",
  "Longitude": "08.420.843",
  "Telephone": "072431855041",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "08:30",
  "SaturdayTo": "19:00",
  "SundayFrom": "08:30",
  "SundayTo": "19:00",
  "IsOpen24Hours": "Nein",
  "location": "48.89499,8.420843",
  "id": "48.89499,8.420843"
}, {
  "Brand": "ARAL",
  "Street": "BAB 659",
  "PostCode": 68519,
  "City": "Viernheim",
  "Latitude": "49.542.497",
  "Longitude": "08.608.754",
  "Telephone": "06204919183",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.542497,8.608754",
  "id": "49.542497,8.608754"
}, {
  "Brand": "ARAL",
  "Street": "Odenwaldstraße 7",
  "PostCode": 67551,
  "City": "Worms-Pfeddersheim",
  "Latitude": "49.634.336",
  "Longitude": "08.280.931",
  "Telephone": "06247905314",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.634336,8.280931",
  "id": "49.634336,8.280931"
}, {
  "Brand": "OMV",
  "Street": "Kriegsstr. 139",
  "PostCode": 76135,
  "City": "Karlsruhe",
  "Latitude": "49.005.460",
  "Longitude": "08.379.160",
  "Telephone": "+49-721-848162",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.00546,8.37916",
  "id": "49.00546,8.37916"
}, {
  "Brand": "ENI",
  "Street": "Stuttgarter Strasse 144",
  "PostCode": 71522,
  "City": "Backnang",
  "Latitude": "48.935.280",
  "Longitude": "09.437.490",
  "Telephone": "+49 (0) 71916267",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.93528,9.43749",
  "id": "48.93528,9.43749"
}, {
  "Brand": "ENI",
  "Street": "Magdeburger Strasse",
  "PostCode": 68309,
  "City": "Mannheim",
  "Latitude": "49.510.460",
  "Longitude": "08.551.890",
  "Telephone": "+49 (0) 62170620",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.51046,8.55189",
  "id": "49.51046,8.55189"
}, {
  "Brand": "ENI",
  "Street": "Haager Strasse 41",
  "PostCode": 84405,
  "City": "Dorfen",
  "Latitude": "48.269.143",
  "Longitude": "12.154.741",
  "Telephone": "+49 (0) 8081589",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.269143,12.154741",
  "id": "48.269143,12.154741"
}, {
  "Brand": "ENI",
  "Street": "Scheidter Strasse 260-262",
  "PostCode": 66125,
  "City": "Saarbruecken(Dudw.)",
  "Latitude": "49.268.020",
  "Longitude": "07.049.730",
  "Telephone": "+49 (0) 68977336",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.26802,7.04973",
  "id": "49.26802,7.04973"
}, {
  "Brand": "Westfalen",
  "Street": "Osttor 63",
  "PostCode": 48324,
  "City": "SENDENHORST",
  "Latitude": "51.841.200",
  "Longitude": "07.842.990",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8412,7.84299",
  "id": "51.8412,7.84299"
}, {
  "Brand": "ARAL",
  "Street": "Am Römerfeld 1",
  "PostCode": 71149,
  "City": "Bondorf",
  "Latitude": "48.506.760",
  "Longitude": "08.835.850",
  "Telephone": "074575959090",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.50676,8.83585",
  "id": "48.50676,8.83585"
}, {
  "Brand": "OMV",
  "Street": "Bayerweg 39",
  "PostCode": 94379,
  "City": "St. Englmar",
  "Latitude": "49.002.580",
  "Longitude": "12.822.680",
  "Telephone": "+49-9965-268",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.00258,12.82268",
  "id": "49.00258,12.82268"
}, {
  "Brand": "ENI",
  "Street": "Muenchner Strasse 28",
  "PostCode": 86641,
  "City": "Rain am Lech",
  "Latitude": "48.689.987",
  "Longitude": "10.921.034",
  "Telephone": "09090 / 9594877",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.689987,10.921034",
  "id": "48.689987,10.921034"
}, {
  "Brand": "ENI",
  "Street": "Meitinger Str. 16-18",
  "PostCode": 86672,
  "City": "Thierhaupten",
  "Latitude": "48.566.770",
  "Longitude": "10.898.260",
  "Telephone": "+49 (0) 82712426",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.56677,10.89826",
  "id": "48.56677,10.89826"
}, {
  "Brand": "OMV",
  "Street": "Freisinger Landstr. 9",
  "PostCode": 80939,
  "City": "München",
  "Latitude": "48.190.440",
  "Longitude": "11.617.790",
  "Telephone": "+49-89-3232447",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.19044,11.61779",
  "id": "48.19044,11.61779"
}, {
  "Brand": "ARAL",
  "Street": "Friedberger Straße 22",
  "PostCode": 35410,
  "City": "Hungen",
  "Latitude": "50.472.348",
  "Longitude": "08.898.378",
  "Telephone": "064028093512",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.472348,8.898378",
  "id": "50.472348,8.898378"
}, {
  "Brand": "TOTAL",
  "Street": "WINDMUEHLE B51",
  "PostCode": 54298,
  "City": "WELSCHBILLIG",
  "Latitude": "49.828.256",
  "Longitude": "06.564.298",
  "Telephone": "0650699044",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.828256,6.564298",
  "id": "49.828256,6.564298"
}, {
  "Brand": "OMV",
  "Street": "Neumarkter Str. 41",
  "PostCode": 92348,
  "City": "Berg",
  "Latitude": "49.325.940",
  "Longitude": "11.443.800",
  "Telephone": "+49-9189-396",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.32594,11.4438",
  "id": "49.32594,11.4438"
}, {
  "Brand": "ENI",
  "Street": "Heerstr. 106",
  "PostCode": 94315,
  "City": "Straubing",
  "Latitude": "48.882.820",
  "Longitude": "12.585.700",
  "Telephone": "+49 (0) 94212114",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.88282,12.5857",
  "id": "48.88282,12.5857"
}, {
  "Brand": "ENI",
  "Street": "Wuerzburger Landstr. 47",
  "PostCode": 91522,
  "City": "Ansbach",
  "Latitude": "49.309.370",
  "Longitude": "10.561.082",
  "Telephone": "+49 (0) 98187221",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.30937,10.561082",
  "id": "49.30937,10.561082"
}, {
  "Brand": "ENI",
  "Street": "Koelschhaeuser Strasse 25",
  "PostCode": 35630,
  "City": "Ehringshausen",
  "Latitude": "50.607.457",
  "Longitude": "08.383.106",
  "Telephone": "+49 (0) 64438112",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.607457,8.383106",
  "id": "50.607457,8.383106"
}, {
  "Brand": "TOTAL",
  "Street": "SCHWABENSTR. 41",
  "PostCode": 68239,
  "City": "MANNHEIM",
  "Latitude": "49.460.773",
  "Longitude": "08.569.707",
  "Telephone": "0621-472576",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.460773,8.569707",
  "id": "49.460773,8.569707"
}, {
  "Brand": "TOTAL",
  "Street": "SCHEINFELDER STR. 15",
  "PostCode": 96160,
  "City": "GEISELWIND",
  "Latitude": "49.769.437",
  "Longitude": "10.470.366",
  "Telephone": "09556-180",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.769437,10.470366",
  "id": "49.769437,10.470366"
}, {
  "Brand": "TOTAL",
  "Street": "GOECKINGKSTR.",
  "PostCode": 99755,
  "City": "ELLRICH",
  "Latitude": "51.579.850",
  "Longitude": "10.677.402",
  "Telephone": "036332-20236",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.57985,10.677402",
  "id": "51.57985,10.677402"
}, {
  "Brand": "ARAL",
  "Street": "Bruchfeld 80",
  "PostCode": 47809,
  "City": "Krefeld",
  "Latitude": "51.342.924",
  "Longitude": "06.627.885",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.342924,6.627885",
  "id": "51.342924,6.627885"
}, {
  "Brand": "OMV",
  "Street": "Neumarkter Str. 10",
  "PostCode": 92355,
  "City": "Velburg",
  "Latitude": "49.234.720",
  "Longitude": "11.668.340",
  "Telephone": "+49-9182-2580",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.23472,11.66834",
  "id": "49.23472,11.66834"
}, {
  "Brand": "OMV",
  "Street": "Hirtenwiesen 2 a",
  "PostCode": 96364,
  "City": "Marktrodach",
  "Latitude": "50.252.170",
  "Longitude": "11.392.460",
  "Telephone": "+49-9261-501648",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.25217,11.39246",
  "id": "50.25217,11.39246"
}, {
  "Brand": "ENI",
  "Street": "Engersgaustrasse 67",
  "PostCode": 56566,
  "City": "Neuwied",
  "Latitude": "50.442.100",
  "Longitude": "07.545.270",
  "Telephone": "+49 (0) 26228322",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.4421,7.54527",
  "id": "50.4421,7.54527"
}, {
  "Brand": "OMV",
  "Street": "Ingolstädter Strasse 24",
  "PostCode": 85084,
  "City": "Reichertshofen",
  "Latitude": "48.659.980",
  "Longitude": "11.464.240",
  "Telephone": "+49-8453-446",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.65998,11.46424",
  "id": "48.65998,11.46424"
}, {
  "Brand": "ARAL",
  "Street": "A93",
  "PostCode": 92670,
  "City": "Windischeschenbach",
  "Latitude": "49.816.969",
  "Longitude": "12.170.666",
  "Telephone": "096819199770",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.816969,12.170666",
  "id": "49.816969,12.170666"
}, {
  "Brand": "Westfalen",
  "Street": "Sindorfer Str. 52",
  "PostCode": 50171,
  "City": "KERPEN II",
  "Latitude": "50.882.600",
  "Longitude": "06.694.560",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.8826,6.69456",
  "id": "50.8826,6.69456"
}, {
  "Brand": "ARAL",
  "Street": "Stuttgarter Straße 85",
  "PostCode": 73061,
  "City": "Ebersbach a. d. Fils",
  "Latitude": "48.713.698",
  "Longitude": "09.513.178",
  "Telephone": "07163532228",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.713698,9.513178",
  "id": "48.713698,9.513178"
}, {
  "Brand": "TOTAL",
  "Street": "NUERNBERGER STRASSE 67",
  "PostCode": 36199,
  "City": "ROTENBURG-LISPENHAUSEN",
  "Latitude": "50.990.655",
  "Longitude": "09.775.772",
  "Telephone": "06623-919323",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.990655,9.775772",
  "id": "50.990655,9.775772"
}, {
  "Brand": "ARAL",
  "Street": "Rombacher Hütte 6-10",
  "PostCode": 44795,
  "City": "Bochum",
  "Latitude": "51.465.585",
  "Longitude": "07.192.702",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.465585,7.192702",
  "id": "51.465585,7.192702"
}, {
  "Brand": "TOTAL",
  "Street": "RHOENHOF 5",
  "PostCode": 36124,
  "City": "EICHENZELL",
  "Latitude": "50.487.897",
  "Longitude": "09.707.698",
  "Telephone": "06659-915450",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.487897,9.707698",
  "id": "50.487897,9.707698"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER CH.",
  "PostCode": 39307,
  "City": "GENTHIN",
  "Latitude": "52.408.178",
  "Longitude": "12.175.533",
  "Telephone": "03933-803515",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.408178,12.175533",
  "id": "52.408178,12.175533"
}, {
  "Brand": "TOTAL",
  "Street": "MUENCHNER STR. 87",
  "PostCode": 85221,
  "City": "DACHAU",
  "Latitude": "48.245.212",
  "Longitude": "11.448.775",
  "Telephone": "08131-5150350",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.245212,11.448775",
  "id": "48.245212,11.448775"
}, {
  "Brand": "TOTAL",
  "Street": "ALTONAER STR. 377",
  "PostCode": 25462,
  "City": "RELLINGEN",
  "Latitude": "53.622.226",
  "Longitude": "09.884.988",
  "Telephone": "04101-32439",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.622226,9.884988",
  "id": "53.622226,9.884988"
}, {
  "Brand": "TOTAL",
  "Street": "DARMSTAEDTER STR. 143",
  "PostCode": 68647,
  "City": "BIBLIS",
  "Latitude": "49.695.014",
  "Longitude": "08.465.266",
  "Telephone": "06245-2007479",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.695014,8.465266",
  "id": "49.695014,8.465266"
}, {
  "Brand": "TOTAL",
  "Street": "BAB-WUNNENSTEIN-WEST/A 81",
  "PostCode": 74360,
  "City": "ILSFELD",
  "Latitude": "49.046.778",
  "Longitude": "09.265.634",
  "Telephone": "07062-267330",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.046778,9.265634",
  "id": "49.046778,9.265634"
}, {
  "Brand": "ARAL",
  "Street": "Hannoversche Straße 2 a",
  "PostCode": 34225,
  "City": "Baunatal",
  "Latitude": "51.242.148",
  "Longitude": "09.438.314",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.242148,9.438314",
  "id": "51.242148,9.438314"
}, {
  "Brand": "TOTAL",
  "Street": "12b WANZLEBENER Allee",
  "PostCode": 39164,
  "City": "WANZLEBEN-BOERDE",
  "Latitude": "52.096.721",
  "Longitude": "11.307.683",
  "Telephone": "039407-5080",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.096721,11.307683",
  "id": "52.096721,11.307683"
}, {
  "Brand": "ARAL",
  "Street": "Seestraße 1",
  "PostCode": 83471,
  "City": "Schönau am Königssee",
  "Latitude": "47.592.761",
  "Longitude": "12.987.441",
  "Telephone": "0865295630",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:30",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.592761,12.987441",
  "id": "47.592761,12.987441"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER CH. 70",
  "PostCode": 6886,
  "City": "WITTENBERG",
  "Latitude": "51.890.654",
  "Longitude": "12.665.478",
  "Telephone": "03491-443011",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.890654,12.665478",
  "id": "51.890654,12.665478"
}, {
  "Brand": "ARAL",
  "Street": "Kattegatweg 1",
  "PostCode": 46446,
  "City": "Emmerich",
  "Latitude": "51.880.266",
  "Longitude": "06.173.678",
  "Telephone": "02828903520",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.880266,6.173678",
  "id": "51.880266,6.173678"
}, {
  "Brand": "Westfalen",
  "Street": "Ostenallee 48",
  "PostCode": 59063,
  "City": "HAMM",
  "Latitude": "51.685.800",
  "Longitude": "07.834.500",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.6858,7.8345",
  "id": "51.6858,7.8345"
}, {
  "Brand": "OMV",
  "Street": "Gruber Str. 80",
  "PostCode": 85586,
  "City": "Poing",
  "Latitude": "48.168.620",
  "Longitude": "11.792.300",
  "Telephone": "+49-8121-25440",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.16862,11.7923",
  "id": "48.16862,11.7923"
}, {
  "Brand": "Westfalen",
  "Street": "Petzer Strasse 6 c",
  "PostCode": 31675,
  "City": "BUEKEBURG",
  "Latitude": "52.262.500",
  "Longitude": "09.037.670",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.2625,9.03767",
  "id": "52.2625,9.03767"
}, {
  "Brand": "ARAL",
  "Street": "Pflaumenallee 1",
  "PostCode": 39343,
  "City": "Uhrsleben",
  "Latitude": "52.193.805",
  "Longitude": "11.263.491",
  "Telephone": "0390529600",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.193805,11.263491",
  "id": "52.193805,11.263491"
}, {
  "Brand": "ENI",
  "Street": "Mueldorferstr. 67",
  "PostCode": 84503,
  "City": "Altoetting",
  "Latitude": "48.226.088",
  "Longitude": "12.659.102",
  "Telephone": "+49 (0) 86719698",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.226088,12.659102",
  "id": "48.226088,12.659102"
}, {
  "Brand": "TOTAL",
  "Street": "HAFENBAHNSTR 74",
  "PostCode": 68305,
  "City": "MANNHEIM",
  "Latitude": "49.513.342",
  "Longitude": "08.484.253",
  "Telephone": "0621-7621810",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.513342,8.484253",
  "id": "49.513342,8.484253"
}, {
  "Brand": "ENI",
  "Street": "Kastanienallee 5",
  "PostCode": 39261,
  "City": "Zerbst",
  "Latitude": "51.957.520",
  "Longitude": "12.082.170",
  "Telephone": "+49 (0) 39237720",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.95752,12.08217",
  "id": "51.95752,12.08217"
}, {
  "Brand": "TOTAL",
  "Street": "GIESSENER STR.120",
  "PostCode": 35452,
  "City": "HEUCHELHEIM",
  "Latitude": "50.586.323",
  "Longitude": "08.641.411",
  "Telephone": "0641-63624",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.586323,8.641411",
  "id": "50.586323,8.641411"
}, {
  "Brand": "Westfalen",
  "Street": "Knallhuetter Str. 43",
  "PostCode": 34225,
  "City": "BAUNATAL",
  "Latitude": "51.258.300",
  "Longitude": "09.448.330",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.2583,9.44833",
  "id": "51.2583,9.44833"
}, {
  "Brand": "ARAL",
  "Street": "Konrad-Adenauer-Straße 192",
  "PostCode": 57572,
  "City": "Niederfischbach",
  "Latitude": "50.862.115",
  "Longitude": "07.885.619",
  "Telephone": "02734571767",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.862115,7.885619",
  "id": "50.862115,7.885619"
}, {
  "Brand": "TOTAL",
  "Street": "MUENCHENER STR. 70",
  "PostCode": 84453,
  "City": "MUEHLDORF",
  "Latitude": "48.245.720",
  "Longitude": "12.510.453",
  "Telephone": "08631/37810",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.24572,12.510453",
  "id": "48.24572,12.510453"
}, {
  "Brand": "TOTAL",
  "Street": "WISMARSCHE STR. 13B",
  "PostCode": 18246,
  "City": "BUETZOW",
  "Latitude": "53.846.768",
  "Longitude": "11.958.524",
  "Telephone": "038461-67157",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.846768,11.958524",
  "id": "53.846768,11.958524"
}, {
  "Brand": "TOTAL",
  "Street": "I.-GANDHI-STR. 106-109",
  "PostCode": 13053,
  "City": "BERLIN",
  "Latitude": "52.547.499",
  "Longitude": "13.468.080",
  "Telephone": "030-96064977",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.547499,13.46808",
  "id": "52.547499,13.46808"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 19",
  "PostCode": 63691,
  "City": "Ranstadt",
  "Latitude": "50.355.052",
  "Longitude": "08.982.898",
  "Telephone": "06041828272",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.355052,8.982898",
  "id": "50.355052,8.982898"
}, {
  "Brand": "ARAL",
  "Street": "Herzog-Otto-Straße 11",
  "PostCode": 83308,
  "City": "Trostberg",
  "Latitude": "48.025.280",
  "Longitude": "12.541.666",
  "Telephone": "0862161692",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "09:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.02528,12.541666",
  "id": "48.02528,12.541666"
}, {
  "Brand": "ARAL",
  "Street": "Schlesische Straße 75",
  "PostCode": 58636,
  "City": "Iserlohn",
  "Latitude": "51.377.995",
  "Longitude": "07.728.409",
  "Telephone": "0237162222",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.377995,7.728409",
  "id": "51.377995,7.728409"
}, {
  "Brand": "TOTAL",
  "Street": "ZSCHOCHERSCHE STR. 84",
  "PostCode": 4229,
  "City": "LEIPZIG",
  "Latitude": "51.322.660",
  "Longitude": "12.331.900",
  "Telephone": "0341-4803837",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.32266,12.3319",
  "id": "51.32266,12.3319"
}, {
  "Brand": "TOTAL",
  "Street": "HAUPTSTR. 25",
  "PostCode": 77880,
  "City": "SASBACH",
  "Latitude": "48.635.478",
  "Longitude": "08.088.090",
  "Telephone": "07841-664138",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.635478,8.08809",
  "id": "48.635478,8.08809"
}, {
  "Brand": "ARAL",
  "Street": "August-Horch-Straße 15",
  "PostCode": 56070,
  "City": "Koblenz",
  "Latitude": "50.393.044",
  "Longitude": "07.571.937",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.393044,7.571937",
  "id": "50.393044,7.571937"
}, {
  "Brand": "Westfalen",
  "Street": "Nordener Str. 5",
  "PostCode": 26556,
  "City": "WESTERHOLT",
  "Latitude": "53.589.700",
  "Longitude": "07.455.390",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.5897,7.45539",
  "id": "53.5897,7.45539"
}, {
  "Brand": "OMV",
  "Street": "Egerlandstr. 2",
  "PostCode": 96342,
  "City": "Stockheim",
  "Latitude": "50.304.230",
  "Longitude": "11.283.930",
  "Telephone": "+49-9265-439",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.30423,11.28393",
  "id": "50.30423,11.28393"
}, {
  "Brand": "OMV",
  "Street": "Krappenrother Str. 2",
  "PostCode": 96215,
  "City": "Lichtenfels",
  "Latitude": "50.150.090",
  "Longitude": "11.081.890",
  "Telephone": "+49-9571-757856",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.15009,11.08189",
  "id": "50.15009,11.08189"
}, {
  "Brand": "ENI",
  "Street": "Sulzbacher Str. 192/1",
  "PostCode": 71522,
  "City": "Backnang",
  "Latitude": "48.960.560",
  "Longitude": "09.430.780",
  "Telephone": "+49 (0) 71919543",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.96056,9.43078",
  "id": "48.96056,9.43078"
}, {
  "Brand": "ENI",
  "Street": "Bamberger Strasse 30",
  "PostCode": 95445,
  "City": "Bayreuth",
  "Latitude": "49.936.940",
  "Longitude": "11.554.210",
  "Telephone": "+49 (0) 92162678",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.93694,11.55421",
  "id": "49.93694,11.55421"
}, {
  "Brand": "ENI",
  "Street": "Nuernberger Str. 109",
  "PostCode": 91710,
  "City": "Gunzenhausen",
  "Latitude": "49.127.198",
  "Longitude": "10.768.249",
  "Telephone": "+49 (0) 98312504",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.127198,10.768249",
  "id": "49.127198,10.768249"
}, {
  "Brand": "OMV",
  "Street": "Regensburger Str. 2 a",
  "PostCode": 90592,
  "City": "Schwarzenbruck",
  "Latitude": "49.359.620",
  "Longitude": "11.253.110",
  "Telephone": "+49-9128-723003",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.35962,11.25311",
  "id": "49.35962,11.25311"
}, {
  "Brand": "ENI",
  "Street": "In Laisen 14",
  "PostCode": 72766,
  "City": "Reutlingen",
  "Latitude": "48.503.960",
  "Longitude": "09.220.340",
  "Telephone": "+49 (0) 71214902",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.50396,9.22034",
  "id": "48.50396,9.22034"
}, {
  "Brand": "OMV",
  "Street": "Werner-v.-Siemens-Str. 45",
  "PostCode": 91052,
  "City": "Erlangen",
  "Latitude": "49.592.700",
  "Longitude": "11.014.300",
  "Telephone": "+49-9131-24262",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.5927,11.0143",
  "id": "49.5927,11.0143"
}, {
  "Brand": "TOTAL",
  "Street": "WALDENBURGER STR. 115",
  "PostCode": 8371,
  "City": "GLAUCHAU",
  "Latitude": "50.840.243",
  "Longitude": "12.542.515",
  "Telephone": "03763-15022",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.840243,12.542515",
  "id": "50.840243,12.542515"
}, {
  "Brand": "TOTAL",
  "Street": "LEONHARDSBERG 17",
  "PostCode": 86150,
  "City": "AUGSBURG",
  "Latitude": "48.370.697",
  "Longitude": "10.900.372",
  "Telephone": "0821-514662",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.370697,10.900372",
  "id": "48.370697,10.900372"
}, {
  "Brand": "ENI",
  "Street": "Boschetsrieder Str. 162",
  "PostCode": 81379,
  "City": "Muenchen",
  "Latitude": "48.111.070",
  "Longitude": "11.536.580",
  "Telephone": "+49 (0) 89748794",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.11107,11.53658",
  "id": "48.11107,11.53658"
}, {
  "Brand": "ENI",
  "Street": "Schongauer Str. 22",
  "PostCode": 82380,
  "City": "Peissenberg",
  "Latitude": "47.793.153",
  "Longitude": "11.062.879",
  "Telephone": "+49 (0) 88032091",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.793153,11.062879",
  "id": "47.793153,11.062879"
}, {
  "Brand": "TOTAL",
  "Street": "BAB NORD/A 24",
  "PostCode": 19372,
  "City": "STOLPE",
  "Latitude": "53.367.656",
  "Longitude": "11.731.897",
  "Telephone": "038725-169975",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.367656,11.731897",
  "id": "53.367656,11.731897"
}, {
  "Brand": "ENI",
  "Street": "Laufamholz Strasse 294",
  "PostCode": 90482,
  "City": "Nuernberg",
  "Latitude": "49.468.950",
  "Longitude": "11.167.050",
  "Telephone": "+49 (0) 91150128",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.46895,11.16705",
  "id": "49.46895,11.16705"
}, {
  "Brand": "OMV",
  "Street": "Brettergartenstr. 76",
  "PostCode": 90427,
  "City": "Nürnberg",
  "Latitude": "49.470.430",
  "Longitude": "11.026.150",
  "Telephone": "+49-911-316477",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.47043,11.02615",
  "id": "49.47043,11.02615"
}, {
  "Brand": "ARAL",
  "Street": "Grünwalder Weg 42",
  "PostCode": 82008,
  "City": "Unterhaching",
  "Latitude": "48.056.847",
  "Longitude": "11.599.591",
  "Telephone": "08961100544",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.056847,11.599591",
  "id": "48.056847,11.599591"
}, {
  "Brand": "OMV",
  "Street": "Ortsstr. 35",
  "PostCode": 89278,
  "City": "Nersingen",
  "Latitude": "48.418.440",
  "Longitude": "10.133.310",
  "Telephone": "+49-7308-3472",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.41844,10.13331",
  "id": "48.41844,10.13331"
}, {
  "Brand": "ENI",
  "Street": "Aeussere Bautzener Str. 38",
  "PostCode": 2708,
  "City": "Loebau",
  "Latitude": "51.105.695",
  "Longitude": "14.652.935",
  "Telephone": "+49 (0) 35858606",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.105695,14.652935",
  "id": "51.105695,14.652935"
}, {
  "Brand": "ARAL",
  "Street": "Poststraße 40",
  "PostCode": 76534,
  "City": "Baden-Baden",
  "Latitude": "48.729.295",
  "Longitude": "08.156.018",
  "Telephone": "0722396420",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.729295,8.156018",
  "id": "48.729295,8.156018"
}, {
  "Brand": "ARAL",
  "Street": "Krumbacher Straße 54",
  "PostCode": 89335,
  "City": "Ichenhausen",
  "Latitude": "48.365.807",
  "Longitude": "10.305.967",
  "Telephone": "082235222",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.365807,10.305967",
  "id": "48.365807,10.305967"
}, {
  "Brand": "ENI",
  "Street": "Gewerbegeb. Langweid a.d. B301",
  "PostCode": 84094,
  "City": "Elsendorf",
  "Latitude": "48.715.800",
  "Longitude": "11.802.228",
  "Telephone": "+49 (0) 87539674",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.7158,11.802228",
  "id": "48.7158,11.802228"
}, {
  "Brand": "TOTAL",
  "Street": "KOENIGSBRUECKER LANDSTR. 265",
  "PostCode": 1108,
  "City": "DRESDEN-WEIXDORF",
  "Latitude": "51.140.392",
  "Longitude": "13.797.571",
  "Telephone": "0351-8802301",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.140392,13.797571",
  "id": "51.140392,13.797571"
}, {
  "Brand": "TOTAL",
  "Street": "TOEPELSTR.",
  "PostCode": 4746,
  "City": "HARTHA",
  "Latitude": "51.104.349",
  "Longitude": "12.962.298",
  "Telephone": "034328-60424",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.104349,12.962298",
  "id": "51.104349,12.962298"
}, {
  "Brand": "TOTAL",
  "Street": "FOLTZRING 38-40",
  "PostCode": 67227,
  "City": "FRANKENTHAL",
  "Latitude": "49.537.769",
  "Longitude": "08.359.031",
  "Telephone": "06233-66197",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.537769,8.359031",
  "id": "49.537769,8.359031"
}, {
  "Brand": "TOTAL",
  "Street": "WUERZBURGER STR. 55",
  "PostCode": 97318,
  "City": "BIEBELRIED",
  "Latitude": "49.766.837",
  "Longitude": "10.075.818",
  "Telephone": "09302-6498750",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.766837,10.075818",
  "id": "49.766837,10.075818"
}, {
  "Brand": "TOTAL",
  "Street": "DUEBENER STR. 133",
  "PostCode": 4509,
  "City": "DELITZSCH",
  "Latitude": "51.531.904",
  "Longitude": "12.357.294",
  "Telephone": "034202/969969",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.531904,12.357294",
  "id": "51.531904,12.357294"
}, {
  "Brand": "OMV",
  "Street": "Münchner Str. 47",
  "PostCode": 85737,
  "City": "Ismaning",
  "Latitude": "48.225.600",
  "Longitude": "11.671.380",
  "Telephone": "+49-89-969539",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.2256,11.67138",
  "id": "48.2256,11.67138"
}, {
  "Brand": "Westfalen",
  "Street": "Stresemannstr. 253",
  "PostCode": 27576,
  "City": "BREMERHAVEN",
  "Latitude": "53.565.100",
  "Longitude": "08.595.090",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.5651,8.59509",
  "id": "53.5651,8.59509"
}, {
  "Brand": "ARAL",
  "Street": "Kaiserstraße 11",
  "PostCode": 67816,
  "City": "Standenbühl",
  "Latitude": "49.594.053",
  "Longitude": "07.985.273",
  "Telephone": "06357989117",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.594053,7.985273",
  "id": "49.594053,7.985273"
}, {
  "Brand": "ARAL",
  "Street": "Vardeler Weg 1/B69",
  "PostCode": 49377,
  "City": "Vechta",
  "Latitude": "52.756.030",
  "Longitude": "08.274.148",
  "Telephone": "044418545212",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.75603,8.274148",
  "id": "52.75603,8.274148"
}, {
  "Brand": "ENI",
  "Street": "Haidenbucher Strasse 2",
  "PostCode": 86916,
  "City": "Kaufering",
  "Latitude": "48.087.729",
  "Longitude": "10.856.195",
  "Telephone": "+49 (0) 81919662",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.087729,10.856195",
  "id": "48.087729,10.856195"
}, {
  "Brand": "ARAL",
  "Street": "Gustav-Stresemann-Straße",
  "PostCode": 55411,
  "City": "Bingen",
  "Latitude": "49.926.162",
  "Longitude": "07.933.971",
  "Telephone": "067219628623",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.926162,7.933971",
  "id": "49.926162,7.933971"
}, {
  "Brand": "OMV",
  "Street": "Weissenburger Str. 24",
  "PostCode": 85072,
  "City": "Eichstätt",
  "Latitude": "48.895.070",
  "Longitude": "11.176.130",
  "Telephone": "+49-8421-2675",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.89507,11.17613",
  "id": "48.89507,11.17613"
}, {
  "Brand": "ARAL",
  "Street": "Am Weidenbach 31",
  "PostCode": 53229,
  "City": "Bonn-Beuel",
  "Latitude": "50.742.904",
  "Longitude": "07.158.038",
  "Telephone": "017672608265",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "50.742904,7.158038",
  "id": "50.742904,7.158038"
}, {
  "Brand": "OMV",
  "Street": "Bayerwaldstr. 12",
  "PostCode": 94327,
  "City": "Bogen",
  "Latitude": "48.911.380",
  "Longitude": "12.695.000",
  "Telephone": "+49-9422-805096",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.91138,12.695",
  "id": "48.91138,12.695"
}, {
  "Brand": "ARAL",
  "Street": "Feldstraße 46",
  "PostCode": 63110,
  "City": "Rodgau",
  "Latitude": "50.002.987",
  "Longitude": "08.885.198",
  "Telephone": "06106/7795031",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "50.002987,8.885198",
  "id": "50.002987,8.885198"
}, {
  "Brand": "ARAL",
  "Street": "In den Siebenmorgen 2c",
  "PostCode": 56077,
  "City": "Koblenz",
  "Latitude": "50.371.252",
  "Longitude": "07.645.431",
  "Telephone": "02616710307",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.371252,7.645431",
  "id": "50.371252,7.645431"
}, {
  "Brand": "ARAL",
  "Street": "Im Stöckmädle 29",
  "PostCode": 76307,
  "City": "Karlsbad-Ittersbach",
  "Latitude": "48.880.420",
  "Longitude": "08.510.005",
  "Telephone": "07248934160",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "48.88042,8.510005",
  "id": "48.88042,8.510005"
}, {
  "Brand": "Westfalen",
  "Street": "Koelner Str. 188",
  "PostCode": 51645,
  "City": "GUMMERSBACH",
  "Latitude": "51.004.200",
  "Longitude": "07.595.170",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.0042,7.59517",
  "id": "51.0042,7.59517"
}, {
  "Brand": "ARAL",
  "Street": "Roedernallee 179",
  "PostCode": 13407,
  "City": "Berlin",
  "Latitude": "52.579.129",
  "Longitude": "13.346.392",
  "Telephone": "03040910920",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.579129,13.346392",
  "id": "52.579129,13.346392"
}, {
  "Brand": "ARAL",
  "Street": "Neunkirchener Straße 2",
  "PostCode": 7952,
  "City": "Pausa",
  "Latitude": "50.587.923",
  "Longitude": "11.998.185",
  "Telephone": "0374327739",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "09:00",
  "SundayTo": "17:00",
  "IsOpen24Hours": "Nein",
  "location": "50.587923,11.998185",
  "id": "50.587923,11.998185"
}, {
  "Brand": "TOTAL",
  "Street": "HAUPTSTRASSE / KORNBLUMENWEG 43",
  "PostCode": 78244,
  "City": "GOTTMADINGEN",
  "Latitude": "47.736.560",
  "Longitude": "08.788.900",
  "Telephone": "07731-9072490",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.73656,8.7889",
  "id": "47.73656,8.7889"
}, {
  "Brand": "TOTAL",
  "Street": "KAISERSLAUTERER STR.37",
  "PostCode": 66424,
  "City": "HOMBURG-BRUCHHOF",
  "Latitude": "49.343.021",
  "Longitude": "07.358.553",
  "Telephone": "06841-64264",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.343021,7.358553",
  "id": "49.343021,7.358553"
}, {
  "Brand": "TOTAL",
  "Street": "WESTICKER STR. 58",
  "PostCode": 58730,
  "City": "FROENDENBERG",
  "Latitude": "51.475.344",
  "Longitude": "07.788.722",
  "Telephone": "02373-76259",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.475344,7.788722",
  "id": "51.475344,7.788722"
}, {
  "Brand": "Westfalen",
  "Street": "Ritter-Heinrich-Str.",
  "PostCode": 56766,
  "City": "ULMEN",
  "Latitude": "50.214.200",
  "Longitude": "06.972.230",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.2142,6.97223",
  "id": "50.2142,6.97223"
}, {
  "Brand": "TOTAL",
  "Street": "ARNULFSTR. 98-99",
  "PostCode": 12105,
  "City": "BERLIN",
  "Latitude": "52.455.578",
  "Longitude": "13.367.255",
  "Telephone": "030-75489500",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.455578,13.367255",
  "id": "52.455578,13.367255"
}, {
  "Brand": "TOTAL",
  "Street": "AN DER AUTOBAHN 4",
  "PostCode": 28876,
  "City": "OYTEN",
  "Latitude": "53.053.350",
  "Longitude": "09.031.812",
  "Telephone": "04207-7081",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.05335,9.031812",
  "id": "53.05335,9.031812"
}, {
  "Brand": "ARAL",
  "Street": "An der B9",
  "PostCode": 56656,
  "City": "Brohl-Lützing",
  "Latitude": "50.486.287",
  "Longitude": "07.329.060",
  "Telephone": "026334729318",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.486287,7.32906",
  "id": "50.486287,7.32906"
}, {
  "Brand": "ARAL",
  "Street": "Steinkampstraße 50",
  "PostCode": 45476,
  "City": "Mülheim an der Ruhr",
  "Latitude": "51.447.488",
  "Longitude": "06.845.953",
  "Telephone": "020837749841",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.447488,6.845953",
  "id": "51.447488,6.845953"
}, {
  "Brand": "OMV",
  "Street": "Ammerholz 3",
  "PostCode": 93080,
  "City": "Pentling",
  "Latitude": "48.972.900",
  "Longitude": "12.065.080",
  "Telephone": "+49-9405-5009024",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.9729,12.06508",
  "id": "48.9729,12.06508"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 8 a",
  "PostCode": 86981,
  "City": "Kinsau",
  "Latitude": "47.881.625",
  "Longitude": "10.891.495",
  "Telephone": "08869228",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.881625,10.891495",
  "id": "47.881625,10.891495"
}, {
  "Brand": "ARAL",
  "Street": "Stuttgarter Straße 54",
  "PostCode": 75417,
  "City": "Mühlacker",
  "Latitude": "48.946.531",
  "Longitude": "08.846.422",
  "Telephone": "070413100",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "05:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.946531,8.846422",
  "id": "48.946531,8.846422"
}, {
  "Brand": "TOTAL",
  "Street": "SALEMER STR. 47",
  "PostCode": 88697,
  "City": "BERMATINGEN",
  "Latitude": "47.735.831",
  "Longitude": "09.341.367",
  "Telephone": "07544-741930",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.735831,9.341367",
  "id": "47.735831,9.341367"
}, {
  "Brand": "ARAL",
  "Street": "Im Gewerbepark 2",
  "PostCode": 49326,
  "City": "Melle",
  "Latitude": "52.219.127",
  "Longitude": "08.272.610",
  "Telephone": "054227097391",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "52.219127,8.27261",
  "id": "52.219127,8.27261"
}, {
  "Brand": "ENI",
  "Street": "Vacher Strasse 417",
  "PostCode": 90768,
  "City": "Fuerth",
  "Latitude": "49.521.060",
  "Longitude": "10.964.060",
  "Telephone": "+49 (0) 91176138",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.52106,10.96406",
  "id": "49.52106,10.96406"
}, {
  "Brand": "ENI",
  "Street": "Am Moos 24",
  "PostCode": 96465,
  "City": "Neustadt",
  "Latitude": "50.332.234",
  "Longitude": "11.114.064",
  "Telephone": "+49 (0) 95686114",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.332234,11.114064",
  "id": "50.332234,11.114064"
}, {
  "Brand": "ENI",
  "Street": "Bahnhofstrasse 47",
  "PostCode": 84494,
  "City": "Neumarkt/St.Veit",
  "Latitude": "48.359.317",
  "Longitude": "12.501.803",
  "Telephone": "+49 (0) 86397098",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.359317,12.501803",
  "id": "48.359317,12.501803"
}, {
  "Brand": "OMV",
  "Street": "Münchener Str. 45",
  "PostCode": 85221,
  "City": "Dachau",
  "Latitude": "48.251.830",
  "Longitude": "11.441.190",
  "Telephone": "+49-8131-83758",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.25183,11.44119",
  "id": "48.25183,11.44119"
}, {
  "Brand": "TOTAL",
  "Street": "WAIBSTAEDTER STR. 35",
  "PostCode": 74924,
  "City": "NECKARBISCHOFSHEIM",
  "Latitude": "49.295.791",
  "Longitude": "08.954.440",
  "Telephone": "07263-969900",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.295791,8.95444",
  "id": "49.295791,8.95444"
}, {
  "Brand": "ARAL",
  "Street": "Salzer Straße 18 d",
  "PostCode": 39240,
  "City": "Calbe",
  "Latitude": "51.912.402",
  "Longitude": "11.777.568",
  "Telephone": "03929177800",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.912402,11.777568",
  "id": "51.912402,11.777568"
}, {
  "Brand": "ENI",
  "Street": "Ettaler Str. 3",
  "PostCode": 82496,
  "City": "Oberau",
  "Latitude": "47.559.640",
  "Longitude": "11.133.680",
  "Telephone": "+49 (0) 88249305",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.55964,11.13368",
  "id": "47.55964,11.13368"
}, {
  "Brand": "ARAL",
  "Street": "Fauerbacher Straße 95",
  "PostCode": 61169,
  "City": "Friedberg-Fauerbach",
  "Latitude": "50.330.224",
  "Longitude": "08.768.519",
  "Telephone": "06031719730",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:00",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "50.330224,8.768519",
  "id": "50.330224,8.768519"
}, {
  "Brand": "ARAL",
  "Street": "Am Tannenwald 4",
  "PostCode": 66459,
  "City": "Kirkel",
  "Latitude": "49.287.800",
  "Longitude": "07.217.860",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.2878,7.21786",
  "id": "49.2878,7.21786"
}, {
  "Brand": "Westfalen",
  "Street": "Brautäcker 2",
  "PostCode": 34560,
  "City": "FRITZLAR",
  "Latitude": "51.140.017",
  "Longitude": "09.274.789",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.140017,9.274789",
  "id": "51.140017,9.274789"
}, {
  "Brand": "OMV",
  "Street": "Alteburgstr. 79",
  "PostCode": 72762,
  "City": "Reutlingen",
  "Latitude": "48.482.940",
  "Longitude": "09.200.880",
  "Telephone": "+49-7121-270778",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.48294,9.20088",
  "id": "48.48294,9.20088"
}, {
  "Brand": "OMV",
  "Street": "Aichacher Str. 13",
  "PostCode": 85250,
  "City": "Altomünster",
  "Latitude": "48.384.670",
  "Longitude": "11.250.650",
  "Telephone": "+49-8254-8213",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.38467,11.25065",
  "id": "48.38467,11.25065"
}, {
  "Brand": "OMV",
  "Street": "Kaufbeurer Str. 38",
  "PostCode": 86825,
  "City": "Bad Wörishofen",
  "Latitude": "48.002.270",
  "Longitude": "10.604.230",
  "Telephone": "+49-8247-2331",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.00227,10.60423",
  "id": "48.00227,10.60423"
}, {
  "Brand": "ARAL",
  "Street": "A 61",
  "PostCode": 67105,
  "City": "Schifferstadt",
  "Latitude": "49.409.262",
  "Longitude": "08.341.517",
  "Telephone": "06231915156",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.409262,8.341517",
  "id": "49.409262,8.341517"
}, {
  "Brand": "ENI",
  "Street": "Zwickauer Strasse 16",
  "PostCode": 8485,
  "City": "Lengenfeld",
  "Latitude": "50.574.228",
  "Longitude": "12.374.294",
  "Telephone": "+49 (0) 37606378",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.574228,12.374294",
  "id": "50.574228,12.374294"
}, {
  "Brand": "TOTAL",
  "Street": "FABRIKSTR. 17",
  "PostCode": 1683,
  "City": "NOSSEN",
  "Latitude": "51.061.700",
  "Longitude": "13.289.999",
  "Telephone": "035242-68259",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.0617,13.289999",
  "id": "51.0617,13.289999"
}, {
  "Brand": "TOTAL",
  "Street": "HEIDENHEIMER STR. 17",
  "PostCode": 73450,
  "City": "NERESHEIM",
  "Latitude": "48.754.974",
  "Longitude": "10.328.288",
  "Telephone": "07326389",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.754974,10.328288",
  "id": "48.754974,10.328288"
}, {
  "Brand": "ARAL",
  "Street": "Mockstädter Straße 2",
  "PostCode": 61197,
  "City": "Florstadt-Staden",
  "Latitude": "50.329.955",
  "Longitude": "08.914.125",
  "Telephone": "060357114",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.329955,8.914125",
  "id": "50.329955,8.914125"
}, {
  "Brand": "ENI",
  "Street": "Loebauer Str. 151",
  "PostCode": 2625,
  "City": "Bautzen",
  "Latitude": "51.179.105",
  "Longitude": "14.450.510",
  "Telephone": "+49 (0) 35912419",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.179105,14.45051",
  "id": "51.179105,14.45051"
}, {
  "Brand": "ENI",
  "Street": "Hauptstr. 23",
  "PostCode": 83329,
  "City": "Waging",
  "Latitude": "47.956.830",
  "Longitude": "12.757.980",
  "Telephone": "08681/4631",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.95683,12.75798",
  "id": "47.95683,12.75798"
}, {
  "Brand": "Westfalen",
  "Street": "Preussnstr. 2 a",
  "PostCode": 26388,
  "City": "WILHELMSHAVEN",
  "Latitude": "53.566.900",
  "Longitude": "08.098.680",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.5669,8.09868",
  "id": "53.5669,8.09868"
}, {
  "Brand": "Westfalen",
  "Street": "Koenigstr. 69",
  "PostCode": 48268,
  "City": "GREVEN",
  "Latitude": "52.093.400",
  "Longitude": "07.623.400",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0934,7.6234",
  "id": "52.0934,7.6234"
}, {
  "Brand": "OMV",
  "Street": "Versbacher Str. 106",
  "PostCode": 97078,
  "City": "Würzburg",
  "Latitude": "49.816.090",
  "Longitude": "09.963.340",
  "Telephone": "+49-931-21585",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.81609,9.96334",
  "id": "49.81609,9.96334"
}, {
  "Brand": "Westfalen",
  "Street": "Osnabruecker Str. 6",
  "PostCode": 49201,
  "City": "DISSEN",
  "Latitude": "52.115.700",
  "Longitude": "08.198.040",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.1157,8.19804",
  "id": "52.1157,8.19804"
}, {
  "Brand": "TOTAL",
  "Street": "ADLERGESTELL 623",
  "PostCode": 12527,
  "City": "BERLIN",
  "Latitude": "52.387.774",
  "Longitude": "13.630.654",
  "Telephone": "030-6758108",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.387774,13.630654",
  "id": "52.387774,13.630654"
}, {
  "Brand": "OMV",
  "Street": "Sulzbacher Str. 24 - 26",
  "PostCode": 90489,
  "City": "Nürnberg",
  "Latitude": "49.457.880",
  "Longitude": "11.093.290",
  "Telephone": "+49-911-5882711",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.45788,11.09329",
  "id": "49.45788,11.09329"
}, {
  "Brand": "Westfalen",
  "Street": "Niedringhausener Str. 130",
  "PostCode": 32609,
  "City": "HUELLHORST",
  "Latitude": "52.269.300",
  "Longitude": "08.635.580",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.2693,8.63558",
  "id": "52.2693,8.63558"
}, {
  "Brand": "ENI",
  "Street": "Stettfelder Str. 56",
  "PostCode": 76698,
  "City": "Ubstadt-Weiher",
  "Latitude": "49.163.811",
  "Longitude": "08.634.579",
  "Telephone": "+49 (0) 72519616",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.163811,8.634579",
  "id": "49.163811,8.634579"
}, {
  "Brand": "TOTAL",
  "Street": "AARSTR. 212",
  "PostCode": 65232,
  "City": "TAUNUSSTEIN",
  "Latitude": "50.152.839",
  "Longitude": "08.180.473",
  "Telephone": "06128-982542",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.152839,8.180473",
  "id": "50.152839,8.180473"
}, {
  "Brand": "ARAL",
  "Street": "Peter-Hurst-Str.",
  "PostCode": 38448,
  "City": "Wolfsburg",
  "Latitude": "52.371.846",
  "Longitude": "10.737.253",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.371846,10.737253",
  "id": "52.371846,10.737253"
}, {
  "Brand": "TOTAL",
  "Street": "NOERDLINGER STR. 11",
  "PostCode": 86757,
  "City": "WALLERSTEIN",
  "Latitude": "48.883.580",
  "Longitude": "10.476.118",
  "Telephone": "09081-7087",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.88358,10.476118",
  "id": "48.88358,10.476118"
}, {
  "Brand": "TOTAL",
  "Street": "BENTHEIMER STR. 233",
  "PostCode": 48529,
  "City": "NORDHORN",
  "Latitude": "52.419.552",
  "Longitude": "07.083.555",
  "Telephone": "05921-8274720",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.419552,7.083555",
  "id": "52.419552,7.083555"
}, {
  "Brand": "TOTAL",
  "Street": "BULGARISCHE STR. 9",
  "PostCode": 12435,
  "City": "BERLIN",
  "Latitude": "52.483.773",
  "Longitude": "13.478.669",
  "Telephone": "030-5337955",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.483773,13.478669",
  "id": "52.483773,13.478669"
}, {
  "Brand": "ARAL",
  "Street": "A 7",
  "PostCode": 34593,
  "City": "Knüllwald",
  "Latitude": "51.036.835",
  "Longitude": "09.487.760",
  "Telephone": "05685922290",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.036835,9.48776",
  "id": "51.036835,9.48776"
}, {
  "Brand": "ENI",
  "Street": "Am Huenstein 65",
  "PostCode": 99735,
  "City": "Nohra",
  "Latitude": "51.428.750",
  "Longitude": "10.714.150",
  "Telephone": "+49 (0) 36334538",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.42875,10.71415",
  "id": "51.42875,10.71415"
}, {
  "Brand": "TOTAL",
  "Street": "SCHLACHTHAUSSTR. 52",
  "PostCode": 89312,
  "City": "GUENZBURG",
  "Latitude": "48.458.620",
  "Longitude": "10.276.700",
  "Telephone": "08221-2039255",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.45862,10.2767",
  "id": "48.45862,10.2767"
}, {
  "Brand": "ARAL",
  "Street": "Landsberger Straße 2",
  "PostCode": 86836,
  "City": "Graben",
  "Latitude": "48.198.674",
  "Longitude": "10.853.949",
  "Telephone": "082322230",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.198674,10.853949",
  "id": "48.198674,10.853949"
}, {
  "Brand": "Westfalen",
  "Street": "Osningstr. 15",
  "PostCode": 33605,
  "City": "BIELEFELD",
  "Latitude": "52.033.400",
  "Longitude": "08.558.880",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0334,8.55888",
  "id": "52.0334,8.55888"
}, {
  "Brand": "ENI",
  "Street": "Weinstrasse Nord 57",
  "PostCode": 67098,
  "City": "Bad Duerkheim",
  "Latitude": "49.460.980",
  "Longitude": "08.162.380",
  "Telephone": "+49 (0) 63226601",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.46098,8.16238",
  "id": "49.46098,8.16238"
}, {
  "Brand": "Westfalen",
  "Street": "Wittener Str. 19",
  "PostCode": 58285,
  "City": "GEVELSBERG",
  "Latitude": "51.321.700",
  "Longitude": "07.337.940",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.3217,7.33794",
  "id": "51.3217,7.33794"
}, {
  "Brand": "ARAL",
  "Street": "Hoeber-u.Mandelbaumstr. 1",
  "PostCode": 68794,
  "City": "Oberhausen-Rheinhaus",
  "Latitude": "49.250.455",
  "Longitude": "08.500.838",
  "Telephone": "072542031920",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.250455,8.500838",
  "id": "49.250455,8.500838"
}, {
  "Brand": "Westfalen",
  "Street": "Mettinger Str. 33",
  "PostCode": 49479,
  "City": "IBBENBUEREN",
  "Latitude": "52.266.500",
  "Longitude": "07.788.470",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.2665,7.78847",
  "id": "52.2665,7.78847"
}, {
  "Brand": "TOTAL",
  "Street": "RUHMRISCHKAMP 3",
  "PostCode": 38179,
  "City": "SCHWUELPER",
  "Latitude": "52.337.642",
  "Longitude": "10.417.812",
  "Telephone": "05303-9709854",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.337642,10.417812",
  "id": "52.337642,10.417812"
}, {
  "Brand": "Westfalen",
  "Street": "Roentgenstr. 2/2a",
  "PostCode": 32052,
  "City": "HERFORD",
  "Latitude": "52.060.800",
  "Longitude": "08.644.210",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0608,8.64421",
  "id": "52.0608,8.64421"
}, {
  "Brand": "Westfalen",
  "Street": "Jacob-Borchers-Str. 49 a",
  "PostCode": 26340,
  "City": "ZETEL",
  "Latitude": "53.421.700",
  "Longitude": "07.979.620",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.4217,7.97962",
  "id": "53.4217,7.97962"
}, {
  "Brand": "Westfalen",
  "Street": "Luebbecker Str.115",
  "PostCode": 32278,
  "City": "KIRCHLENGERN",
  "Latitude": "52.204.200",
  "Longitude": "08.640.260",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.2042,8.64026",
  "id": "52.2042,8.64026"
}, {
  "Brand": "TOTAL",
  "Street": "ALT MAHLSDORF 60",
  "PostCode": 12623,
  "City": "BERLIN",
  "Latitude": "52.504.209",
  "Longitude": "13.624.332",
  "Telephone": "030-5677297",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.504209,13.624332",
  "id": "52.504209,13.624332"
}, {
  "Brand": "ARAL",
  "Street": "Auf der Gänsmatt 14",
  "PostCode": 79650,
  "City": "Schopfheim",
  "Latitude": "47.646.901",
  "Longitude": "07.807.144",
  "Telephone": "076224900",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.646901,7.807144",
  "id": "47.646901,7.807144"
}, {
  "Brand": "TOTAL",
  "Street": "ALBERT-VATER-STR. 30",
  "PostCode": 39108,
  "City": "MAGDEBURG",
  "Latitude": "52.141.251",
  "Longitude": "11.623.434",
  "Telephone": "0391-2511279",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.141251,11.623434",
  "id": "52.141251,11.623434"
}, {
  "Brand": "ARAL",
  "Street": "Kölner Straße 35",
  "PostCode": 57610,
  "City": "Altenkirchen",
  "Latitude": "50.686.808",
  "Longitude": "07.634.016",
  "Telephone": "02681987630",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.686808,7.634016",
  "id": "50.686808,7.634016"
}, {
  "Brand": "ARAL",
  "Street": "Colditzer Straße 7 c",
  "PostCode": 9306,
  "City": "Rochlitz",
  "Latitude": "51.061.148",
  "Longitude": "12.786.807",
  "Telephone": "0373741351",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.061148,12.786807",
  "id": "51.061148,12.786807"
}, {
  "Brand": "ARAL",
  "Street": "Von-Ketteler-Straße 4",
  "PostCode": 96049,
  "City": "Bamberg",
  "Latitude": "49.886.599",
  "Longitude": "10.921.848",
  "Telephone": "09519172722",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.886599,10.921848",
  "id": "49.886599,10.921848"
}, {
  "Brand": "TOTAL",
  "Street": "BAB BOERDE NORD / A2",
  "PostCode": 39343,
  "City": "GROSS SANTERSLEBEN",
  "Latitude": "52.183.716",
  "Longitude": "11.455.750",
  "Telephone": "039206-68351",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.183716,11.45575",
  "id": "52.183716,11.45575"
}, {
  "Brand": "TOTAL",
  "Street": "UNGERATH 301",
  "PostCode": 41366,
  "City": "SCHWALMTAL",
  "Latitude": "51.208.558",
  "Longitude": "06.280.830",
  "Telephone": "02163-943140",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.208558,6.28083",
  "id": "51.208558,6.28083"
}, {
  "Brand": "TOTAL",
  "Street": "OHMSTR. 25",
  "PostCode": 70736,
  "City": "FELLBACH",
  "Latitude": "48.822.349",
  "Longitude": "09.285.043",
  "Telephone": "0711-54041707",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.822349,9.285043",
  "id": "48.822349,9.285043"
}, {
  "Brand": "TOTAL",
  "Street": "MAINZER STR. 140",
  "PostCode": 67657,
  "City": "KAISERSLAUTERN",
  "Latitude": "49.457.298",
  "Longitude": "07.801.527",
  "Telephone": "0631-40226",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.457298,7.801527",
  "id": "49.457298,7.801527"
}, {
  "Brand": "OMV",
  "Street": "Gewerbepark 2",
  "PostCode": 96149,
  "City": "Breitengüssbach",
  "Latitude": "49.973.410",
  "Longitude": "10.875.340",
  "Telephone": "+49-9544-982090",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.97341,10.87534",
  "id": "49.97341,10.87534"
}, {
  "Brand": "TOTAL",
  "Street": "BERLINER STR. 31",
  "PostCode": 16244,
  "City": "SCHORFHEIDE",
  "Latitude": "52.903.099",
  "Longitude": "13.535.292",
  "Telephone": "033393-516",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.903099,13.535292",
  "id": "52.903099,13.535292"
}, {
  "Brand": "TOTAL",
  "Street": "GRABOWER ALLEE 31",
  "PostCode": 19288,
  "City": "LUDWIGSLUST",
  "Latitude": "53.316.070",
  "Longitude": "11.506.477",
  "Telephone": "03874-61143/45",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.31607,11.506477",
  "id": "53.31607,11.506477"
}, {
  "Brand": "TOTAL",
  "Street": "HARPENER HELLWEG 88",
  "PostCode": 44805,
  "City": "BOCHUM-HARPEN",
  "Latitude": "51.499.297",
  "Longitude": "07.276.438",
  "Telephone": "0234-231498",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.499297,7.276438",
  "id": "51.499297,7.276438"
}, {
  "Brand": "TOTAL",
  "Street": "LANGES GEWAND 3",
  "PostCode": 78604,
  "City": "RIETHEIM-WEILHEIM",
  "Latitude": "48.030.600",
  "Longitude": "08.776.300",
  "Telephone": "07461-969725100",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.0306,8.7763",
  "id": "48.0306,8.7763"
}, {
  "Brand": "TOTAL",
  "Street": "WEILBURGER STR. 30/33",
  "PostCode": 61250,
  "City": "USINGEN",
  "Latitude": "50.340.076",
  "Longitude": "08.534.939",
  "Telephone": "06081-2680",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.340076,8.534939",
  "id": "50.340076,8.534939"
}, {
  "Brand": "ARAL",
  "Street": "Pasinger Straße 50",
  "PostCode": 82166,
  "City": "Gräfelfing",
  "Latitude": "48.118.098",
  "Longitude": "11.442.665",
  "Telephone": "0898543576",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.118098,11.442665",
  "id": "48.118098,11.442665"
}, {
  "Brand": "TOTAL",
  "Street": "NAUMBURGER STRASSE 26",
  "PostCode": 6667,
  "City": "WEISSENFELS",
  "Latitude": "51.198.265",
  "Longitude": "11.963.868",
  "Telephone": "03443-334566",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.198265,11.963868",
  "id": "51.198265,11.963868"
}, {
  "Brand": "ARAL",
  "Street": "Memminger Straße 182",
  "PostCode": 89231,
  "City": "Neu-Ulm",
  "Latitude": "48.366.250",
  "Longitude": "10.009.852",
  "Telephone": "073183034",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.36625,10.009852",
  "id": "48.36625,10.009852"
}, {
  "Brand": "TOTAL",
  "Street": "EDINGER RIEDWEG 8-12",
  "PostCode": 68219,
  "City": "MANNHEIM",
  "Latitude": "49.421.773",
  "Longitude": "08.534.002",
  "Telephone": "0621-8019209",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.421773,8.534002",
  "id": "49.421773,8.534002"
}, {
  "Brand": "OMV",
  "Street": "Hauptstr. 19",
  "PostCode": 94571,
  "City": "Schaufling",
  "Latitude": "48.842.170",
  "Longitude": "13.071.110",
  "Telephone": "+49-9904-503",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.84217,13.07111",
  "id": "48.84217,13.07111"
}, {
  "Brand": "ARAL",
  "Street": "Mindelheimer Straße 19",
  "PostCode": 87772,
  "City": "Pfaffenhausen",
  "Latitude": "48.120.366",
  "Longitude": "10.462.015",
  "Telephone": "082657332333",
  "WeekDayFrom": "06:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:30",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.120366,10.462015",
  "id": "48.120366,10.462015"
}, {
  "Brand": "OMV",
  "Street": "Köschinger Forst West A 9",
  "PostCode": 85120,
  "City": "Hepberg",
  "Latitude": "48.838.580",
  "Longitude": "11.468.480",
  "Telephone": "+49-8405-304",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.83858,11.46848",
  "id": "48.83858,11.46848"
}, {
  "Brand": "OMV",
  "Street": "Erlanger Str. 1",
  "PostCode": 91093,
  "City": "Hessdorf",
  "Latitude": "49.628.660",
  "Longitude": "10.911.820",
  "Telephone": "+49-9135-799838",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.62866,10.91182",
  "id": "49.62866,10.91182"
}, {
  "Brand": "ARAL",
  "Street": "Dillinger Straße 4 1/2",
  "PostCode": 89415,
  "City": "Lauingen",
  "Latitude": "48.573.223",
  "Longitude": "10.434.393",
  "Telephone": "090722423",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "07:30",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Nein",
  "location": "48.573223,10.434393",
  "id": "48.573223,10.434393"
}, {
  "Brand": "TOTAL",
  "Street": "QUERFURTER STR. 4A",
  "PostCode": 6217,
  "City": "MERSEBURG",
  "Latitude": "51.371.793",
  "Longitude": "11.982.872",
  "Telephone": "03461-204531",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.371793,11.982872",
  "id": "51.371793,11.982872"
}, {
  "Brand": "TOTAL",
  "Street": "KREUZBERGSTR. 65",
  "PostCode": 42899,
  "City": "REMSCHEID",
  "Latitude": "51.208.645",
  "Longitude": "07.231.365",
  "Telephone": "02191-462832",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.208645,7.231365",
  "id": "51.208645,7.231365"
}, {
  "Brand": "ARAL",
  "Street": "Dammfeldstraße 1",
  "PostCode": 31275,
  "City": "Lehrte",
  "Latitude": "52.398.260",
  "Longitude": "09.973.600",
  "Telephone": "051323523",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.39826,9.9736",
  "id": "52.39826,9.9736"
}, {
  "Brand": "TOTAL",
  "Street": "PARKSTR. 16",
  "PostCode": 47877,
  "City": "WILLICH",
  "Latitude": "51.266.699",
  "Longitude": "06.551.393",
  "Telephone": "02154-205770",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.266699,6.551393",
  "id": "51.266699,6.551393"
}, {
  "Brand": "ENI",
  "Street": "Bahnhofstrasse 37",
  "PostCode": 83253,
  "City": "Rimsting",
  "Latitude": "47.890.132",
  "Longitude": "12.344.398",
  "Telephone": "+49 (0) 80516109",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.890132,12.344398",
  "id": "47.890132,12.344398"
}, {
  "Brand": "TOTAL",
  "Street": "SCHULTH.-KOLLEI-STR. 23",
  "PostCode": 55411,
  "City": "BINGEN",
  "Latitude": "49.950.114",
  "Longitude": "07.903.223",
  "Telephone": "06721-44713",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.950114,7.903223",
  "id": "49.950114,7.903223"
}, {
  "Brand": "ARAL",
  "Street": "Zeppelinstraße 9-11",
  "PostCode": 61118,
  "City": "Bad Vilbel",
  "Latitude": "50.193.944",
  "Longitude": "08.724.966",
  "Telephone": "06101557525",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.193944,8.724966",
  "id": "50.193944,8.724966"
}, {
  "Brand": "TOTAL",
  "Street": "HAUPERSWEILER STR. 1",
  "PostCode": 66629,
  "City": "FREISEN-OBERKIRCHEN",
  "Latitude": "49.524.975",
  "Longitude": "07.262.966",
  "Telephone": "06855-7100",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.524975,7.262966",
  "id": "49.524975,7.262966"
}, {
  "Brand": "TOTAL",
  "Street": "IDSTEINER STR. 50",
  "PostCode": 65527,
  "City": "NIEDERNHAUSEN",
  "Latitude": "50.164.330",
  "Longitude": "08.312.604",
  "Telephone": "06127-903620",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.16433,8.312604",
  "id": "50.16433,8.312604"
}, {
  "Brand": "ARAL",
  "Street": "Sondershäuser Straße 2",
  "PostCode": 99947,
  "City": "Bad Langensalza",
  "Latitude": "51.113.495",
  "Longitude": "10.659.204",
  "Telephone": "036038953177",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "51.113495,10.659204",
  "id": "51.113495,10.659204"
}, {
  "Brand": "ARAL",
  "Street": "Beisenbusch 2",
  "PostCode": 48301,
  "City": "Nottuln",
  "Latitude": "51.912.118",
  "Longitude": "07.399.278",
  "Telephone": "02509990460",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.912118,7.399278",
  "id": "51.912118,7.399278"
}, {
  "Brand": "Westfalen",
  "Street": "Gerdener Str. 4",
  "PostCode": 49326,
  "City": "MELLE",
  "Latitude": "52.142.700",
  "Longitude": "08.387.470",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.1427,8.38747",
  "id": "52.1427,8.38747"
}, {
  "Brand": "ENI",
  "Street": "Leipziger Str. 51",
  "PostCode": 4828,
  "City": "Bennewitz",
  "Latitude": "51.362.415",
  "Longitude": "12.697.060",
  "Telephone": "+49 (0) 34258109",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.362415,12.69706",
  "id": "51.362415,12.69706"
}, {
  "Brand": "ENI",
  "Street": "Friedensstrasse 108",
  "PostCode": 63165,
  "City": "Muehlheim",
  "Latitude": "50.119.900",
  "Longitude": "08.824.740",
  "Telephone": "+49 (0) 61087280",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.1199,8.82474",
  "id": "50.1199,8.82474"
}, {
  "Brand": "ENI",
  "Street": "Mahlower Str. 241",
  "PostCode": 14513,
  "City": "Teltow",
  "Latitude": "52.386.958",
  "Longitude": "13.304.939",
  "Telephone": "+49 (0) 33284718",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "52.386958,13.304939",
  "id": "52.386958,13.304939"
}, {
  "Brand": "TOTAL",
  "Street": "ZUM DOENGES 2",
  "PostCode": 36208,
  "City": "WILDECK-OBERSUHL",
  "Latitude": "50.955.079",
  "Longitude": "10.022.439",
  "Telephone": "06626-92100",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.955079,10.022439",
  "id": "50.955079,10.022439"
}, {
  "Brand": "ARAL",
  "Street": "Opelstraße 1",
  "PostCode": 78467,
  "City": "Konstanz",
  "Latitude": "47.676.090",
  "Longitude": "09.153.130",
  "Telephone": "07531/1272460",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "47.67609,9.15313",
  "id": "47.67609,9.15313"
}, {
  "Brand": "Westfalen",
  "Street": "Bahnhofstr. 41",
  "PostCode": 26441,
  "City": "JEVER",
  "Latitude": "53.570.600",
  "Longitude": "07.892.320",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.5706,7.89232",
  "id": "53.5706,7.89232"
}, {
  "Brand": "ARAL",
  "Street": "Königsbrücker Straße 25",
  "PostCode": 1561,
  "City": "Schönfeld",
  "Latitude": "51.295.416",
  "Longitude": "13.730.064",
  "Telephone": "03524888447",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.295416,13.730064",
  "id": "51.295416,13.730064"
}, {
  "Brand": "TOTAL",
  "Street": "NAUENER STR. 116",
  "PostCode": 14612,
  "City": "FALKENSEE",
  "Latitude": "52.586.399",
  "Longitude": "13.064.999",
  "Telephone": "03322-22701",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.586399,13.064999",
  "id": "52.586399,13.064999"
}, {
  "Brand": "TOTAL",
  "Street": "HORSTWEG 53c",
  "PostCode": 14478,
  "City": "POTSDAM",
  "Latitude": "52.382.194",
  "Longitude": "13.089.295",
  "Telephone": "0331-87003713",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.382194,13.089295",
  "id": "52.382194,13.089295"
}, {
  "Brand": "TOTAL",
  "Street": "RICHRATHER STR. 116",
  "PostCode": 40723,
  "City": "HILDEN",
  "Latitude": "51.158.335",
  "Longitude": "06.938.557",
  "Telephone": "02103-64460",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.158335,6.938557",
  "id": "51.158335,6.938557"
}, {
  "Brand": "Westfalen",
  "Street": "Moehlenkampstr. 9",
  "PostCode": 47139,
  "City": "DUISBURG",
  "Latitude": "51.480.200",
  "Longitude": "06.743.790",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.4802,6.74379",
  "id": "51.4802,6.74379"
}, {
  "Brand": "TOTAL",
  "Street": "AACHER STR. 18",
  "PostCode": 78234,
  "City": "ENGEN",
  "Latitude": "47.855.709",
  "Longitude": "08.781.561",
  "Telephone": "07733-9399413",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.855709,8.781561",
  "id": "47.855709,8.781561"
}, {
  "Brand": "TOTAL",
  "Street": "ZOLLHAUSSTR. 10",
  "PostCode": 52353,
  "City": "DUEREN",
  "Latitude": "50.822.199",
  "Longitude": "06.461.393",
  "Telephone": "02421-81150",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.822199,6.461393",
  "id": "50.822199,6.461393"
}, {
  "Brand": "ARAL",
  "Street": "Nürtinger Straße 24",
  "PostCode": 72622,
  "City": "Nürtingen",
  "Latitude": "48.618.064",
  "Longitude": "09.313.156",
  "Telephone": "070225029310",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.618064,9.313156",
  "id": "48.618064,9.313156"
}, {
  "Brand": "Westfalen",
  "Street": "Duesseldorfer Landstr. 343",
  "PostCode": 47259,
  "City": "DUISBURG",
  "Latitude": "51.353.500",
  "Longitude": "06.748.720",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.3535,6.74872",
  "id": "51.3535,6.74872"
}, {
  "Brand": "ENI",
  "Street": "Badelacher Weg 10",
  "PostCode": 36404,
  "City": "Vacha",
  "Latitude": "50.828.470",
  "Longitude": "10.030.430",
  "Telephone": "+49 (0) 36962246",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.82847,10.03043",
  "id": "50.82847,10.03043"
}, {
  "Brand": "ENI",
  "Street": "Karlsruher Str. 85",
  "PostCode": 75179,
  "City": "Pforzheim",
  "Latitude": "48.909.880",
  "Longitude": "08.647.270",
  "Telephone": "+49 (0) 72313282",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.90988,8.64727",
  "id": "48.90988,8.64727"
}, {
  "Brand": "ENI",
  "Street": "Richard-Wagner-Ring 26",
  "PostCode": 76437,
  "City": "Rastatt",
  "Latitude": "48.866.910",
  "Longitude": "08.208.110",
  "Telephone": "+49 (0) 72222281",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.86691,8.20811",
  "id": "48.86691,8.20811"
}, {
  "Brand": "OMV",
  "Street": "Schaffhauserstr. 40",
  "PostCode": 79713,
  "City": "Bad Säckingen",
  "Latitude": "47.559.870",
  "Longitude": "07.963.610",
  "Telephone": "+49-7761-1480",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "47.55987,7.96361",
  "id": "47.55987,7.96361"
}, {
  "Brand": "ENI",
  "Street": "Am Hockheimring, Fahrerlager 3",
  "PostCode": 68766,
  "City": "Hockenheim",
  "Latitude": "49.321.170",
  "Longitude": "08.568.220",
  "Telephone": "06205/950173",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.32117,8.56822",
  "id": "49.32117,8.56822"
}, {
  "Brand": "OMV",
  "Street": "Erlanger Str. 17",
  "PostCode": 91077,
  "City": "Neunkirchen",
  "Latitude": "49.610.510",
  "Longitude": "11.127.750",
  "Telephone": "+49-9134-611",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.61051,11.12775",
  "id": "49.61051,11.12775"
}, {
  "Brand": "TOTAL",
  "Street": "FRIEDENSSTR.1",
  "PostCode": 39393,
  "City": "VOELPKE",
  "Latitude": "52.140.622",
  "Longitude": "11.105.736",
  "Telephone": "039402-9610",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.140622,11.105736",
  "id": "52.140622,11.105736"
}, {
  "Brand": "OMV",
  "Street": "BAB 6",
  "PostCode": 91126,
  "City": "Kammerstein",
  "Latitude": "49.306.350",
  "Longitude": "10.967.470",
  "Telephone": "+49-9122-60397-0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.30635,10.96747",
  "id": "49.30635,10.96747"
}, {
  "Brand": "OMV",
  "Street": "Industriestraße  2",
  "PostCode": 94315,
  "City": "Oberschneiding",
  "Latitude": "48.805.180",
  "Longitude": "12.653.290",
  "Telephone": "+49-9426-8529899",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.80518,12.65329",
  "id": "48.80518,12.65329"
}, {
  "Brand": "TOTAL",
  "Street": "ERNST-THAELMANN-STR. 1",
  "PostCode": 2994,
  "City": "BERNSDORF",
  "Latitude": "51.376.899",
  "Longitude": "14.071.393",
  "Telephone": "035723-20110",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.376899,14.071393",
  "id": "51.376899,14.071393"
}, {
  "Brand": "Westfalen",
  "Street": "Lippestr. 34",
  "PostCode": 59558,
  "City": "LIPPSTADT",
  "Latitude": "51.690.200",
  "Longitude": "08.372.740",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.6902,8.37274",
  "id": "51.6902,8.37274"
}, {
  "Brand": "ARAL",
  "Street": "Dormettinger Straße 13",
  "PostCode": 72359,
  "City": "Dotternhausen",
  "Latitude": "48.227.396",
  "Longitude": "08.783.317",
  "Telephone": "0742791015",
  "WeekDayFrom": "04:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.227396,8.783317",
  "id": "48.227396,8.783317"
}, {
  "Brand": "OMV",
  "Street": "Hauptstr. 2",
  "PostCode": 91356,
  "City": "Kirchehrenbach",
  "Latitude": "49.731.370",
  "Longitude": "11.141.160",
  "Telephone": "+49-9191-9774720",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.73137,11.14116",
  "id": "49.73137,11.14116"
}, {
  "Brand": "ENI",
  "Street": "Sandershaeuser Str. 43",
  "PostCode": 34123,
  "City": "Kassel",
  "Latitude": "51.308.890",
  "Longitude": "09.518.570",
  "Telephone": "+49 (0) 56157995",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.30889,9.51857",
  "id": "51.30889,9.51857"
}, {
  "Brand": "ENI",
  "Street": "Goethestr. 2",
  "PostCode": 88046,
  "City": "Friedrichshafen",
  "Latitude": "47.660.010",
  "Longitude": "09.487.320",
  "Telephone": "+49 (0) 75417313",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.66001,9.48732",
  "id": "47.66001,9.48732"
}, {
  "Brand": "TOTAL",
  "Street": "UNTERER KELLERMANN 1",
  "PostCode": 97273,
  "City": "KUERNACH",
  "Latitude": "49.840.000",
  "Longitude": "10.018.300",
  "Telephone": "093679889070",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.84,10.0183",
  "id": "49.84,10.0183"
}, {
  "Brand": "TOTAL",
  "Street": "HANNOEVERSCHE STR.52A",
  "PostCode": 44143,
  "City": "DORTMUND",
  "Latitude": "51.524.450",
  "Longitude": "07.511.009",
  "Telephone": "0231-5315877",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.52445,7.511009",
  "id": "51.52445,7.511009"
}, {
  "Brand": "ENI",
  "Street": "Seehauser Str. 9",
  "PostCode": 83324,
  "City": "Ruhpolding",
  "Latitude": "47.759.920",
  "Longitude": "12.650.200",
  "Telephone": "+49 (0) 86631336",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.75992,12.6502",
  "id": "47.75992,12.6502"
}, {
  "Brand": "OMV",
  "Street": "Am Forst 2",
  "PostCode": 85560,
  "City": "Ebersberg",
  "Latitude": "48.096.240",
  "Longitude": "11.962.380",
  "Telephone": "+49-8092-868300",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.09624,11.96238",
  "id": "48.09624,11.96238"
}, {
  "Brand": "ENI",
  "Street": "Bahnhofstrae 15",
  "PostCode": 84079,
  "City": "Bruckberg",
  "Latitude": "48.520.098",
  "Longitude": "11.995.037",
  "Telephone": "+49 (0) 87659204",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.520098,11.995037",
  "id": "48.520098,11.995037"
}, {
  "Brand": "TOTAL",
  "Street": "KANTSTR. 25",
  "PostCode": 97074,
  "City": "WUERZBURG",
  "Latitude": "49.777.984",
  "Longitude": "09.942.717",
  "Telephone": "0931-81701",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.777984,9.942717",
  "id": "49.777984,9.942717"
}, {
  "Brand": "TOTAL",
  "Street": "LERCHENAUERSTR. 75",
  "PostCode": 80809,
  "City": "MUENCHEN",
  "Latitude": "48.182.302",
  "Longitude": "11.553.909",
  "Telephone": "089-23540304",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.182302,11.553909",
  "id": "48.182302,11.553909"
}, {
  "Brand": "OMV",
  "Street": "Straubinger Str. 73",
  "PostCode": 93055,
  "City": "Regensburg",
  "Latitude": "49.013.700",
  "Longitude": "12.139.710",
  "Telephone": "+49-941-7994512",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.0137,12.13971",
  "id": "49.0137,12.13971"
}, {
  "Brand": "OMV",
  "Street": "Amberger Str. 42",
  "PostCode": 92318,
  "City": "Neumarkt",
  "Latitude": "49.286.630",
  "Longitude": "11.468.390",
  "Telephone": "+49-9181-43466",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.28663,11.46839",
  "id": "49.28663,11.46839"
}, {
  "Brand": "OMV",
  "Street": "Königsbrunner Str. 1 a",
  "PostCode": 86399,
  "City": "Bobingen",
  "Latitude": "48.264.640",
  "Longitude": "10.839.990",
  "Telephone": "+49-8234-6623",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.26464,10.83999",
  "id": "48.26464,10.83999"
}, {
  "Brand": "ENI",
  "Street": "Dresdner Str. 84",
  "PostCode": 9130,
  "City": "Chemnitz",
  "Latitude": "50.841.592",
  "Longitude": "12.935.633",
  "Telephone": "+49 (0) 37140450",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.841592,12.935633",
  "id": "50.841592,12.935633"
}, {
  "Brand": "ENI",
  "Street": "Blaufelder Strasse",
  "PostCode": 74564,
  "City": "Crailsheim",
  "Latitude": "49.140.643",
  "Longitude": "10.073.385",
  "Telephone": "+49 (0) 79515922",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.140643,10.073385",
  "id": "49.140643,10.073385"
}, {
  "Brand": "ENI",
  "Street": "Jenaer Str. 71",
  "PostCode": 7607,
  "City": "Eisenberg",
  "Latitude": "50.969.844",
  "Longitude": "11.858.356",
  "Telephone": "+49 (0) 36691561",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.969844,11.858356",
  "id": "50.969844,11.858356"
}, {
  "Brand": "OMV",
  "Street": "Gewerbestrasse Nord 19",
  "PostCode": 86857,
  "City": "Hurlach",
  "Latitude": "48.127.140",
  "Longitude": "10.835.440",
  "Telephone": "+49-8248-9019180",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.12714,10.83544",
  "id": "48.12714,10.83544"
}, {
  "Brand": "ENI",
  "Street": "H.-M.-Schleyer Str. 1",
  "PostCode": 71063,
  "City": "Sindelfingen",
  "Latitude": "48.703.496",
  "Longitude": "09.001.714",
  "Telephone": "+49 (0) 70318165",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.703496,9.001714",
  "id": "48.703496,9.001714"
}, {
  "Brand": "ARAL",
  "Street": "Rosensteinstraße 1",
  "PostCode": 70806,
  "City": "Kornwestheim",
  "Latitude": "48.856.301",
  "Longitude": "09.185.561",
  "Telephone": "0715422060",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.856301,9.185561",
  "id": "48.856301,9.185561"
}, {
  "Brand": "TOTAL",
  "Street": "HALTERNER STR.100",
  "PostCode": 45657,
  "City": "RECKLINGHAUSEN",
  "Latitude": "51.624.370",
  "Longitude": "07.192.956",
  "Telephone": "02361-22485",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.62437,7.192956",
  "id": "51.62437,7.192956"
}, {
  "Brand": "ARAL",
  "Street": "Glanstraße 44",
  "PostCode": 66869,
  "City": "Kusel",
  "Latitude": "49.539.760",
  "Longitude": "07.413.175",
  "Telephone": "06381/429715",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "49.53976,7.413175",
  "id": "49.53976,7.413175"
}, {
  "Brand": "TOTAL",
  "Street": "ROTHSTEINSLACHE 2",
  "PostCode": 4895,
  "City": "FALKENBERG",
  "Latitude": "51.587.042",
  "Longitude": "13.248.595",
  "Telephone": "03536-531275",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.587042,13.248595",
  "id": "51.587042,13.248595"
}, {
  "Brand": "TOTAL",
  "Street": "PLAUER LANDSTR. 3",
  "PostCode": 14772,
  "City": "BRANDENBURG",
  "Latitude": "52.416.699",
  "Longitude": "12.485.982",
  "Telephone": "03381-700066",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.416699,12.485982",
  "id": "52.416699,12.485982"
}, {
  "Brand": "ARAL",
  "Street": "Oppelner Straße 203",
  "PostCode": 90473,
  "City": "Nürnberg",
  "Latitude": "49.402.238",
  "Longitude": "11.133.597",
  "Telephone": "09119898948",
  "WeekDayFrom": "08:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "09:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "09:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "49.402238,11.133597",
  "id": "49.402238,11.133597"
}, {
  "Brand": "TOTAL",
  "Street": "SAALFELDER STR. 90",
  "PostCode": 98724,
  "City": "NEUHAUS AM RENNWEG OT LICHTE",
  "Latitude": "50.523.624",
  "Longitude": "11.216.205",
  "Telephone": "036701-60425",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.523624,11.216205",
  "id": "50.523624,11.216205"
}, {
  "Brand": "OMV",
  "Street": "Hohenlohe Süd A6",
  "PostCode": 74632,
  "City": "Neuenstein",
  "Latitude": "49.205.780",
  "Longitude": "09.638.030",
  "Telephone": "+49-7942-917116",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.20578,9.63803",
  "id": "49.20578,9.63803"
}, {
  "Brand": "ARAL",
  "Street": "Hamburger Straße 65",
  "PostCode": 38114,
  "City": "Braunschweig",
  "Latitude": "52.285.370",
  "Longitude": "10.518.550",
  "Telephone": "05312396801",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.28537,10.51855",
  "id": "52.28537,10.51855"
}, {
  "Brand": "ARAL",
  "Street": "Plohnbachstraße 2",
  "PostCode": 8485,
  "City": "Lengenfeld",
  "Latitude": "50.575.269",
  "Longitude": "12.386.975",
  "Telephone": "03760633663",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.575269,12.386975",
  "id": "50.575269,12.386975"
}, {
  "Brand": "TOTAL",
  "Street": "ROSENHEIMER STR. 116",
  "PostCode": 83080,
  "City": "NIEDERAUDORF",
  "Latitude": "47.667.480",
  "Longitude": "12.169.090",
  "Telephone": "08033/1501",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.66748,12.16909",
  "id": "47.66748,12.16909"
}, {
  "Brand": "TOTAL",
  "Street": "AN DER KLEMPNITZ 2",
  "PostCode": 16868,
  "City": "WUSTERHAUSEN/DOSSE OT WUSTERHAUSEN",
  "Latitude": "52.893.602",
  "Longitude": "12.455.119",
  "Telephone": "033979-14515/507",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.893602,12.455119",
  "id": "52.893602,12.455119"
}, {
  "Brand": "OMV",
  "Street": "Dammühlstr. 28",
  "PostCode": 76829,
  "City": "Landau",
  "Latitude": "49.201.630",
  "Longitude": "08.124.230",
  "Telephone": "+49-6341-81526",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.20163,8.12423",
  "id": "49.20163,8.12423"
}, {
  "Brand": "TOTAL",
  "Street": "DR.-WILH.-ROELEN-STR 357 a",
  "PostCode": 47179,
  "City": "DUISBURG",
  "Latitude": "51.527.171",
  "Longitude": "06.735.937",
  "Telephone": "0203-493735",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.527171,6.735937",
  "id": "51.527171,6.735937"
}, {
  "Brand": "ARAL",
  "Street": "Industriestraße",
  "PostCode": 84130,
  "City": "Dingolfing",
  "Latitude": "48.647.720",
  "Longitude": "12.453.454",
  "Telephone": "0",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.64772,12.453454",
  "id": "48.64772,12.453454"
}, {
  "Brand": "Westfalen",
  "Street": "Embser Landstr. 22",
  "PostCode": 28832,
  "City": "ACHIM",
  "Latitude": "53.019.370",
  "Longitude": "09.030.980",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.01937,9.03098",
  "id": "53.01937,9.03098"
}, {
  "Brand": "TOTAL",
  "Street": "2 MUGGENSTUMER LANDSTR.",
  "PostCode": 76467,
  "City": "BIETIGHEIM",
  "Latitude": "48.884.919",
  "Longitude": "08.269.204",
  "Telephone": "07222-150050",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.884919,8.269204",
  "id": "48.884919,8.269204"
}, {
  "Brand": "ENI",
  "Street": "Hauptstrasse 38",
  "PostCode": 73329,
  "City": "Kuchen",
  "Latitude": "48.637.630",
  "Longitude": "09.795.690",
  "Telephone": "+49 (0) 73318681",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.63763,9.79569",
  "id": "48.63763,9.79569"
}, {
  "Brand": "Westfalen",
  "Street": "Sutthauser Str. 166",
  "PostCode": 49080,
  "City": "OSNABRUECK",
  "Latitude": "52.256.100",
  "Longitude": "08.041.790",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.2561,8.04179",
  "id": "52.2561,8.04179"
}, {
  "Brand": "Westfalen",
  "Street": "Nicolaus-Otto-Str. 1",
  "PostCode": 52351,
  "City": "DUEREN",
  "Latitude": "50.779.300",
  "Longitude": "06.506.640",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.7793,6.50664",
  "id": "50.7793,6.50664"
}, {
  "Brand": "OMV",
  "Street": "Mannheimer Str. 78 a",
  "PostCode": 68782,
  "City": "Brühl",
  "Latitude": "49.402.190",
  "Longitude": "08.537.870",
  "Telephone": "+49-6202-702949",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.40219,8.53787",
  "id": "49.40219,8.53787"
}, {
  "Brand": "OMV",
  "Street": "Am Bahnhof 2",
  "PostCode": 86983,
  "City": "Lechbruck",
  "Latitude": "47.697.550",
  "Longitude": "10.791.980",
  "Telephone": "+49-8862-8498",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.69755,10.79198",
  "id": "47.69755,10.79198"
}, {
  "Brand": "TOTAL",
  "Street": "MEIERKAMP 1",
  "PostCode": 31249,
  "City": "HOHENHAMELN",
  "Latitude": "52.262.620",
  "Longitude": "10.075.070",
  "Telephone": "05128-9489910",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.26262,10.07507",
  "id": "52.26262,10.07507"
}, {
  "Brand": "Westfalen",
  "Street": "Am Anger 22",
  "PostCode": 33332,
  "City": "GUETERSLOH",
  "Latitude": "51.900.500",
  "Longitude": "08.401.110",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9005,8.40111",
  "id": "51.9005,8.40111"
}, {
  "Brand": "ARAL",
  "Street": "Südliche Ringstraße 62",
  "PostCode": 85053,
  "City": "Ingolstadt",
  "Latitude": "48.761.352",
  "Longitude": "11.440.493",
  "Telephone": "084162593",
  "WeekDayFrom": "04:30",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "23:00",
  "IsOpen24Hours": "Ja",
  "location": "48.761352,11.440493",
  "id": "48.761352,11.440493"
}, {
  "Brand": "TOTAL",
  "Street": "JAEGERHOF 10",
  "PostCode": 41516,
  "City": "GREVENBROICH",
  "Latitude": "51.121.755",
  "Longitude": "06.666.714",
  "Telephone": "02182-885034",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.121755,6.666714",
  "id": "51.121755,6.666714"
}, {
  "Brand": "ARAL",
  "Street": "Baukreativstraße 7",
  "PostCode": 91628,
  "City": "Steinsfeld-Endsee",
  "Latitude": "49.445.495",
  "Longitude": "10.245.853",
  "Telephone": "",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.445495,10.245853",
  "id": "49.445495,10.245853"
}, {
  "Brand": "TOTAL",
  "Street": "AM SAUERECK 2",
  "PostCode": 86732,
  "City": "OETTINGEN",
  "Latitude": "48.946.509",
  "Longitude": "10.595.822",
  "Telephone": "09082-1444",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.946509,10.595822",
  "id": "48.946509,10.595822"
}, {
  "Brand": "TOTAL",
  "Street": "HAUPTSTR. 107",
  "PostCode": 49205,
  "City": "HASBERGEN",
  "Latitude": "52.265.749",
  "Longitude": "07.964.343",
  "Telephone": "05405-8081564",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.265749,7.964343",
  "id": "52.265749,7.964343"
}, {
  "Brand": "ENI",
  "Street": "Friedberger Strasse 10",
  "PostCode": 61381,
  "City": "Friedrichsdorf-Koepp",
  "Latitude": "50.278.630",
  "Longitude": "08.655.290",
  "Telephone": "+49 (0) 61757067",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.27863,8.65529",
  "id": "50.27863,8.65529"
}, {
  "Brand": "ARAL",
  "Street": "Nienhagener Straße 13",
  "PostCode": 29339,
  "City": "Wathlingen",
  "Latitude": "52.540.803",
  "Longitude": "10.141.519",
  "Telephone": "05144681",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.540803,10.141519",
  "id": "52.540803,10.141519"
}, {
  "Brand": "ARAL",
  "Street": "Rheinstr. 9",
  "PostCode": 96052,
  "City": "Bamberg",
  "Latitude": "49.910.214",
  "Longitude": "10.855.707",
  "Telephone": "220578157",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.910214,10.855707",
  "id": "49.910214,10.855707"
}, {
  "Brand": "OMV",
  "Street": "Mindelheimer Str. 33",
  "PostCode": 87742,
  "City": "Dirlewang",
  "Latitude": "48.001.370",
  "Longitude": "10.506.500",
  "Telephone": "+49-8267-207",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.00137,10.5065",
  "id": "48.00137,10.5065"
}, {
  "Brand": "ARAL",
  "Street": "A9",
  "PostCode": 91257,
  "City": "Pegnitz",
  "Latitude": "49.747.347",
  "Longitude": "11.513.346",
  "Telephone": "092414944510",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.747347,11.513346",
  "id": "49.747347,11.513346"
}, {
  "Brand": "TOTAL",
  "Street": "BRAUNSCHWEIGER STR. 36 A",
  "PostCode": 38723,
  "City": "SEESEN",
  "Latitude": "51.897.853",
  "Longitude": "10.183.157",
  "Telephone": "05381-1220",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.897853,10.183157",
  "id": "51.897853,10.183157"
}, {
  "Brand": "ENI",
  "Street": "Obertuerkheimer Str. 21",
  "PostCode": 73733,
  "City": "Esslingen",
  "Latitude": "48.750.940",
  "Longitude": "09.275.600",
  "Telephone": "+49 (0) 71191833",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.75094,9.2756",
  "id": "48.75094,9.2756"
}, {
  "Brand": "TOTAL",
  "Street": "HOMBERGER STR.21",
  "PostCode": 40882,
  "City": "RATINGEN",
  "Latitude": "51.295.966",
  "Longitude": "06.866.760",
  "Telephone": "02102-843167",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.295966,6.86676",
  "id": "51.295966,6.86676"
}, {
  "Brand": "ARAL",
  "Street": "Magdeburger Straße 16",
  "PostCode": 6484,
  "City": "Quedlinburg",
  "Latitude": "51.790.060",
  "Longitude": "11.169.140",
  "Telephone": "039469072182",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "07:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.79006,11.16914",
  "id": "51.79006,11.16914"
}, {
  "Brand": "TOTAL",
  "Street": "ROHRAUER STR.38",
  "PostCode": 71154,
  "City": "NUFRINGEN",
  "Latitude": "48.620.830",
  "Longitude": "08.894.610",
  "Telephone": "07032958055",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.62083,8.89461",
  "id": "48.62083,8.89461"
}, {
  "Brand": "Westfalen",
  "Street": "Bahnhofstr. 90",
  "PostCode": 33813,
  "City": "OERLINGHAUSEN",
  "Latitude": "51.979.500",
  "Longitude": "08.714.040",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9795,8.71404",
  "id": "51.9795,8.71404"
}, {
  "Brand": "TOTAL",
  "Street": "HEIDESTRASSE 18",
  "PostCode": 10557,
  "City": "BERLIN",
  "Latitude": "52.534.217",
  "Longitude": "13.365.195",
  "Telephone": "030 308739 87",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.534217,13.365195",
  "id": "52.534217,13.365195"
}, {
  "Brand": "ENI",
  "Street": "Neckarsteinacher Str. 21",
  "PostCode": 69151,
  "City": "Neckargemuend",
  "Latitude": "49.395.783",
  "Longitude": "08.809.074",
  "Telephone": "+49 (0) 62232715",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.395783,8.809074",
  "id": "49.395783,8.809074"
}, {
  "Brand": "ARAL",
  "Street": "Oldenburger Str. 290",
  "PostCode": 26203,
  "City": "Wardenburg",
  "Latitude": "53.056.902",
  "Longitude": "08.199.596",
  "Telephone": "04407998070",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "53.056902,8.199596",
  "id": "53.056902,8.199596"
}, {
  "Brand": "ENI",
  "Street": "Callenberger Strasse 38",
  "PostCode": 96450,
  "City": "Coburg",
  "Latitude": "50.268.450",
  "Longitude": "10.954.390",
  "Telephone": "+49 (0) 95616956",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.26845,10.95439",
  "id": "50.26845,10.95439"
}, {
  "Brand": "TOTAL",
  "Street": "MERCHINGER STR.1",
  "PostCode": 74706,
  "City": "OSTERBURKEN",
  "Latitude": "49.427.937",
  "Longitude": "09.427.066",
  "Telephone": "0629164210",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.427937,9.427066",
  "id": "49.427937,9.427066"
}, {
  "Brand": "OMV",
  "Street": "Speyerer Str. 20",
  "PostCode": 69124,
  "City": "Heidelberg",
  "Latitude": "49.395.670",
  "Longitude": "08.669.300",
  "Telephone": "+49-6221-22787",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.39567,8.6693",
  "id": "49.39567,8.6693"
}, {
  "Brand": "OMV",
  "Street": "Immenstädter Str. 5",
  "PostCode": 87534,
  "City": "Oberstaufen",
  "Latitude": "47.555.290",
  "Longitude": "10.025.600",
  "Telephone": "+49-8386-960740",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.55529,10.0256",
  "id": "47.55529,10.0256"
}, {
  "Brand": "ARAL",
  "Street": "Buehlweg 1",
  "PostCode": 95482,
  "City": "Gefrees",
  "Latitude": "50.094.051",
  "Longitude": "11.731.639",
  "Telephone": "092549616926",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "06:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Nein",
  "location": "50.094051,11.731639",
  "id": "50.094051,11.731639"
}, {
  "Brand": "ARAL",
  "Street": "Bitscher Straße 20a",
  "PostCode": 66482,
  "City": "Zweibrücken",
  "Latitude": "49.235.524",
  "Longitude": "07.361.955",
  "Telephone": "06332470736",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.235524,7.361955",
  "id": "49.235524,7.361955"
}, {
  "Brand": "ARAL",
  "Street": "Holderäcker 1",
  "PostCode": 73527,
  "City": "Schwäbisch Gmünd",
  "Latitude": "48.826.300",
  "Longitude": "09.854.007",
  "Telephone": "071719718440",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "21:30",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "21:30",
  "SundayFrom": "08:00",
  "SundayTo": "21:30",
  "IsOpen24Hours": "Ja",
  "location": "48.8263,9.854007",
  "id": "48.8263,9.854007"
}, {
  "Brand": "ARAL",
  "Street": "Porschestraße 3",
  "PostCode": 71634,
  "City": "Ludwigsburg",
  "Latitude": "48.920.504",
  "Longitude": "09.150.573",
  "Telephone": "071416432890",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "00:00",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "48.920504,9.150573",
  "id": "48.920504,9.150573"
}, {
  "Brand": "TOTAL",
  "Street": "AM URBICHER KREUZ 34",
  "PostCode": 99099,
  "City": "ERFURT",
  "Latitude": "50.949.585",
  "Longitude": "11.094.185",
  "Telephone": "0361-4210828",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.949585,11.094185",
  "id": "50.949585,11.094185"
}, {
  "Brand": "TOTAL",
  "Street": "GOLDBECKSTR. 1",
  "PostCode": 69493,
  "City": "HIRSCHBERG",
  "Latitude": "49.507.398",
  "Longitude": "08.636.922",
  "Telephone": "06201-876431",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.507398,8.636922",
  "id": "49.507398,8.636922"
}, {
  "Brand": "Westfalen",
  "Street": "Nobelstraße",
  "PostCode": 48477,
  "City": "HOERSTEL",
  "Latitude": "52.309.080",
  "Longitude": "07.616.140",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.30908,7.61614",
  "id": "52.30908,7.61614"
}, {
  "Brand": "ENI",
  "Street": "Niederwerrner Str. 4  1/3",
  "PostCode": 97421,
  "City": "Schweinfurt",
  "Latitude": "50.048.400",
  "Longitude": "10.228.930",
  "Telephone": "+49 (0) 97211865",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.0484,10.22893",
  "id": "50.0484,10.22893"
}, {
  "Brand": "TOTAL",
  "Street": "BORSCHER STRASSE 21",
  "PostCode": 36419,
  "City": "GEISA",
  "Latitude": "50.722.452",
  "Longitude": "09.958.470",
  "Telephone": "036967-76511",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.722452,9.95847",
  "id": "50.722452,9.95847"
}, {
  "Brand": "ARAL",
  "Street": "A6",
  "PostCode": 66914,
  "City": "Waldmohr",
  "Latitude": "49.372.808",
  "Longitude": "07.375.410",
  "Telephone": "063737514",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.372808,7.37541",
  "id": "49.372808,7.37541"
}, {
  "Brand": "ARAL",
  "Street": "Elmstraße 2",
  "PostCode": 38448,
  "City": "Wolfsburg",
  "Latitude": "52.342.579",
  "Longitude": "10.852.249",
  "Telephone": "053652889",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "07:30",
  "SaturdayTo": "20:30",
  "SundayFrom": "08:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "52.342579,10.852249",
  "id": "52.342579,10.852249"
}, {
  "Brand": "TOTAL",
  "Street": "SYKERSTR. 74",
  "PostCode": 27751,
  "City": "DELMENHORST",
  "Latitude": "53.050.333",
  "Longitude": "08.654.466",
  "Telephone": "04221-71253",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.050333,8.654466",
  "id": "53.050333,8.654466"
}, {
  "Brand": "ARAL",
  "Street": "In der Lehmkuhlenbreite 32",
  "PostCode": 39179,
  "City": "Barleben",
  "Latitude": "52.205.557",
  "Longitude": "11.606.582",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.205557,11.606582",
  "id": "52.205557,11.606582"
}, {
  "Brand": "TOTAL",
  "Street": "ERFURTER LANDSTRASSE 49",
  "PostCode": 99095,
  "City": "ERFURT-STOTTERNHEIM",
  "Latitude": "51.046.240",
  "Longitude": "11.042.137",
  "Telephone": "036204-52515",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.04624,11.042137",
  "id": "51.04624,11.042137"
}, {
  "Brand": "TOTAL",
  "Street": "OBERSPREESTR. 138",
  "PostCode": 12555,
  "City": "BERLIN",
  "Latitude": "52.445.800",
  "Longitude": "13.561.790",
  "Telephone": "030-6519761",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.4458,13.56179",
  "id": "52.4458,13.56179"
}, {
  "Brand": "TOTAL",
  "Street": "ZSCHOPAUER STR. 319",
  "PostCode": 9127,
  "City": "CHEMNITZ",
  "Latitude": "50.810.155",
  "Longitude": "12.965.084",
  "Telephone": "0371-7253452",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.810155,12.965084",
  "id": "50.810155,12.965084"
}, {
  "Brand": "OMV",
  "Street": "An der B 25 / Luitpoldstr. 23",
  "PostCode": 91550,
  "City": "Dinkelsbühl",
  "Latitude": "49.067.810",
  "Longitude": "10.328.190",
  "Telephone": "+49-9851-552309",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.06781,10.32819",
  "id": "49.06781,10.32819"
}, {
  "Brand": "TOTAL",
  "Street": "ROSTOCKER STR. 154",
  "PostCode": 17166,
  "City": "TETEROW",
  "Latitude": "53.785.857",
  "Longitude": "12.570.469",
  "Telephone": "03996-172306",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.785857,12.570469",
  "id": "53.785857,12.570469"
}, {
  "Brand": "ARAL",
  "Street": "Kötzschenbroder Str. 193",
  "PostCode": 1139,
  "City": "Dresden",
  "Latitude": "51.092.601",
  "Longitude": "13.670.501",
  "Telephone": "03518371511",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "07:00",
  "SundayTo": "20:00",
  "IsOpen24Hours": "Nein",
  "location": "51.092601,13.670501",
  "id": "51.092601,13.670501"
}, {
  "Brand": "TOTAL",
  "Street": "HEEKER STR. 9",
  "PostCode": 48629,
  "City": "METELEN",
  "Latitude": "52.143.433",
  "Longitude": "07.207.813",
  "Telephone": "02556-98775",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.143433,7.207813",
  "id": "52.143433,7.207813"
}, {
  "Brand": "ARAL",
  "Street": "Im Seifental",
  "PostCode": 53498,
  "City": "Bad Breisig",
  "Latitude": "50.534.353",
  "Longitude": "07.268.382",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.534353,7.268382",
  "id": "50.534353,7.268382"
}, {
  "Brand": "ARAL",
  "Street": "Sonnenfeld 2",
  "PostCode": 83395,
  "City": "Freilassing",
  "Latitude": "47.842.901",
  "Longitude": "12.970.901",
  "Telephone": "086547778780",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.842901,12.970901",
  "id": "47.842901,12.970901"
}, {
  "Brand": "ARAL",
  "Street": "Kleinsendelbacher Str. 12",
  "PostCode": 91077,
  "City": "Neunkirchen am Brand",
  "Latitude": "49.604.142",
  "Longitude": "11.138.034",
  "Telephone": "09134906906",
  "WeekDayFrom": "05:30",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:30",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.604142,11.138034",
  "id": "49.604142,11.138034"
}, {
  "Brand": "Westfalen",
  "Street": "Weseler Str. 3",
  "PostCode": 48249,
  "City": "DUELMEN",
  "Latitude": "51.864.800",
  "Longitude": "07.360.280",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8648,7.36028",
  "id": "51.8648,7.36028"
}, {
  "Brand": "ARAL",
  "Street": "A 61 West",
  "PostCode": 50181,
  "City": "Bedburg",
  "Latitude": "51.005.141",
  "Longitude": "06.522.270",
  "Telephone": "02272407650",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.005141,6.52227",
  "id": "51.005141,6.52227"
}, {
  "Brand": "ARAL",
  "Street": "Neukamp 4",
  "PostCode": 26655,
  "City": "Westerstede",
  "Latitude": "53.257.068",
  "Longitude": "07.927.945",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "53.257068,7.927945",
  "id": "53.257068,7.927945"
}, {
  "Brand": "TOTAL",
  "Street": "MARTIN-LUTHER-STR. 21",
  "PostCode": 66111,
  "City": "SAARBRUECKEN",
  "Latitude": "49.237.582",
  "Longitude": "07.004.841",
  "Telephone": "0681-398444",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.237582,7.004841",
  "id": "49.237582,7.004841"
}, {
  "Brand": "OMV",
  "Street": "Augsburger Str. 7",
  "PostCode": 86497,
  "City": "Horgau",
  "Latitude": "48.394.530",
  "Longitude": "10.694.460",
  "Telephone": "+49-8294-470",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.39453,10.69446",
  "id": "48.39453,10.69446"
}, {
  "Brand": "TOTAL",
  "Street": "WALDSTR. 20",
  "PostCode": 37520,
  "City": "OSTERODE",
  "Latitude": "51.725.941",
  "Longitude": "10.261.293",
  "Telephone": "05522-2522",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.725941,10.261293",
  "id": "51.725941,10.261293"
}, {
  "Brand": "ENI",
  "Street": "An Der B 12",
  "PostCode": 83558,
  "City": "Maitenbeth-Thal",
  "Latitude": "48.152.753",
  "Longitude": "12.089.515",
  "Telephone": "+49 (0) 8076631",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.152753,12.089515",
  "id": "48.152753,12.089515"
}, {
  "Brand": "ENI",
  "Street": "Herfaer Str. 4",
  "PostCode": 36289,
  "City": "Friedewald",
  "Latitude": "50.883.900",
  "Longitude": "09.866.000",
  "Telephone": "+49 (0) 66749191",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.8839,9.866",
  "id": "50.8839,9.866"
}, {
  "Brand": "ARAL",
  "Street": "Leibnizstraße 14",
  "PostCode": 97204,
  "City": "Höchberg",
  "Latitude": "49.768.683",
  "Longitude": "09.865.780",
  "Telephone": "0931/61943190",
  "WeekDayFrom": "07:00",
  "WeekDayTo": "20:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "20:00",
  "SundayFrom": "0",
  "SundayTo": "0",
  "IsOpen24Hours": "Nein",
  "location": "49.768683,9.86578",
  "id": "49.768683,9.86578"
}, {
  "Brand": "OMV",
  "Street": "Schopflocherstr. 2",
  "PostCode": 91555,
  "City": "Feuchtwangen",
  "Latitude": "49.156.650",
  "Longitude": "10.329.870",
  "Telephone": "+49-9852-6133454",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.15665,10.32987",
  "id": "49.15665,10.32987"
}, {
  "Brand": "ARAL",
  "Street": "An der BAB 5",
  "PostCode": 79258,
  "City": "Hartheim-Bremgarten",
  "Latitude": "47.908.259",
  "Longitude": "07.592.319",
  "Telephone": "076339202116",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.908259,7.592319",
  "id": "47.908259,7.592319"
}, {
  "Brand": "TOTAL",
  "Street": "BECHLINER CHAUSSEE 18A",
  "PostCode": 16816,
  "City": "NEURUPPIN",
  "Latitude": "52.906.089",
  "Longitude": "12.751.269",
  "Telephone": "03391-3469813",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.906089,12.751269",
  "id": "52.906089,12.751269"
}, {
  "Brand": "ARAL",
  "Street": "Industriestraße 7a",
  "PostCode": 36275,
  "City": "Kirchheim",
  "Latitude": "50.833.319",
  "Longitude": "09.570.307",
  "Telephone": "06625632",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "06:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.833319,9.570307",
  "id": "50.833319,9.570307"
}, {
  "Brand": "ARAL",
  "Street": "A7",
  "PostCode": 88451,
  "City": "Dettingen an der Iller",
  "Latitude": "48.123.857",
  "Longitude": "10.112.559",
  "Telephone": "07354576",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.123857,10.112559",
  "id": "48.123857,10.112559"
}, {
  "Brand": "Westfalen",
  "Street": "Bielefelder Str. 107",
  "PostCode": 33378,
  "City": "BAT REMSCHEID-Ostseite",
  "Latitude": "51.158.600",
  "Longitude": "07.230.840",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.1586,7.23084",
  "id": "51.1586,7.23084"
}, {
  "Brand": "TOTAL",
  "Street": "OSTERHOLZER HEERSTR. 83",
  "PostCode": 28307,
  "City": "BREMEN",
  "Latitude": "53.058.803",
  "Longitude": "08.929.957",
  "Telephone": "0421-451779",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.058803,8.929957",
  "id": "53.058803,8.929957"
}, {
  "Brand": "OMV",
  "Street": "Rüdenhausenerstr. 20",
  "PostCode": 97353,
  "City": "Wiesentheid",
  "Latitude": "49.786.560",
  "Longitude": "10.340.260",
  "Telephone": "+49-9383-9099958",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.78656,10.34026",
  "id": "49.78656,10.34026"
}, {
  "Brand": "ENI",
  "Street": "Eisenacher Str. 15",
  "PostCode": 36266,
  "City": "Philippstal",
  "Latitude": "50.887.795",
  "Longitude": "09.922.655",
  "Telephone": "+49 (0) 6620425",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.887795,9.922655",
  "id": "50.887795,9.922655"
}, {
  "Brand": "ENI",
  "Street": "Flughafenstrasse 81",
  "PostCode": 1109,
  "City": "Dresden",
  "Latitude": "51.120.554",
  "Longitude": "13.762.874",
  "Telephone": "+49 (0) 35189031",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.120554,13.762874",
  "id": "51.120554,13.762874"
}, {
  "Brand": "TOTAL",
  "Street": "AN DER B 51",
  "PostCode": 54597,
  "City": "OLZHEIM",
  "Latitude": "50.268.831",
  "Longitude": "06.455.859",
  "Telephone": "06552-99110",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.268831,6.455859",
  "id": "50.268831,6.455859"
}, {
  "Brand": "ENI",
  "Street": "Schwaighofstrasse 93",
  "PostCode": 83684,
  "City": "Tegernsee",
  "Latitude": "47.694.370",
  "Longitude": "11.772.290",
  "Telephone": "+49 (0) 80225140",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.69437,11.77229",
  "id": "47.69437,11.77229"
}, {
  "Brand": "ENI",
  "Street": "Vilbeler Landstrasse 248",
  "PostCode": 60388,
  "City": "Frankfurt",
  "Latitude": "50.156.930",
  "Longitude": "08.745.170",
  "Telephone": "+49 (0) 61092760",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.15693,8.74517",
  "id": "50.15693,8.74517"
}, {
  "Brand": "OMV",
  "Street": "Lindauer Str. 10",
  "PostCode": 86845,
  "City": "Großaitingen",
  "Latitude": "48.227.470",
  "Longitude": "10.780.730",
  "Telephone": "+49-8203-961998",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.22747,10.78073",
  "id": "48.22747,10.78073"
}, {
  "Brand": "Westfalen",
  "Street": "Herforder 110-114",
  "PostCode": 33602,
  "City": "BIELEFELD",
  "Latitude": "52.032.800",
  "Longitude": "08.541.450",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0328,8.54145",
  "id": "52.0328,8.54145"
}, {
  "Brand": "Westfalen",
  "Street": "Herforder 111",
  "PostCode": 32602,
  "City": "VLOTHO",
  "Latitude": "52.157.300",
  "Longitude": "08.844.150",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.1573,8.84415",
  "id": "52.1573,8.84415"
}, {
  "Brand": "ARAL",
  "Street": "Heisenbergstraße 3-9",
  "PostCode": 50169,
  "City": "Kerpen",
  "Latitude": "50.869.824",
  "Longitude": "06.756.319",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.869824,6.756319",
  "id": "50.869824,6.756319"
}, {
  "Brand": "TOTAL",
  "Street": "SOLMITZSTRASSE 23a",
  "PostCode": 23569,
  "City": "LUEBECK",
  "Latitude": "53.915.282",
  "Longitude": "10.805.329",
  "Telephone": "0451-301413",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.915282,10.805329",
  "id": "53.915282,10.805329"
}, {
  "Brand": "TOTAL",
  "Street": "KONRAD-ADENAUER-DAMM 200",
  "PostCode": 53123,
  "City": "BONN",
  "Latitude": "50.712.222",
  "Longitude": "07.040.556",
  "Telephone": "0228-644806",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.712222,7.040556",
  "id": "50.712222,7.040556"
}, {
  "Brand": "TOTAL",
  "Street": "AM HEERBACH 1",
  "PostCode": 63857,
  "City": "WALDASCHAFF",
  "Latitude": "49.980.904",
  "Longitude": "09.275.372",
  "Telephone": "06095-3373",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.980904,9.275372",
  "id": "49.980904,9.275372"
}, {
  "Brand": "ENI",
  "Street": "Dietzgenstr. 127",
  "PostCode": 13158,
  "City": "Berlin",
  "Latitude": "52.594.684",
  "Longitude": "13.402.919",
  "Telephone": "+49 (0) 30477504",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "52.594684,13.402919",
  "id": "52.594684,13.402919"
}, {
  "Brand": "ARAL",
  "Street": "Kossaer Str. 2",
  "PostCode": 4356,
  "City": "Leipzig",
  "Latitude": "51.410.967",
  "Longitude": "12.388.024",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.410967,12.388024",
  "id": "51.410967,12.388024"
}, {
  "Brand": "ARAL",
  "Street": "Teuringer Straße 16",
  "PostCode": 88045,
  "City": "Friedrichshafen",
  "Latitude": "47.670.675",
  "Longitude": "09.480.251",
  "Telephone": "0754155599",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "47.670675,9.480251",
  "id": "47.670675,9.480251"
}, {
  "Brand": "ENI",
  "Street": "Dortelweiler Strasse 49",
  "PostCode": 60389,
  "City": "Frankfurt",
  "Latitude": "50.133.770",
  "Longitude": "08.706.420",
  "Telephone": "+49 (0) 69461333",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.13377,8.70642",
  "id": "50.13377,8.70642"
}, {
  "Brand": "OMV",
  "Street": "Türkheimer Str. 20",
  "PostCode": 86825,
  "City": "Bad Wörishofen",
  "Latitude": "48.010.010",
  "Longitude": "10.598.850",
  "Telephone": "+49-8247-34862",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.01001,10.59885",
  "id": "48.01001,10.59885"
}, {
  "Brand": "Westfalen",
  "Street": "Osnabrücker Str. 21",
  "PostCode": 49214,
  "City": "BAD ROTHENFELDE",
  "Latitude": "52.113.100",
  "Longitude": "08.162.250",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.1131,8.16225",
  "id": "52.1131,8.16225"
}, {
  "Brand": "ARAL",
  "Street": "A66",
  "PostCode": 36381,
  "City": "Schlüchtern",
  "Latitude": "50.387.552",
  "Longitude": "09.526.144",
  "Telephone": "066619164695",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "50.387552,9.526144",
  "id": "50.387552,9.526144"
}, {
  "Brand": "ENI",
  "Street": "Bahnhofstr 1",
  "PostCode": 82152,
  "City": "Planegg",
  "Latitude": "48.101.859",
  "Longitude": "11.426.278",
  "Telephone": "+49 (0) 89859415",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.101859,11.426278",
  "id": "48.101859,11.426278"
}, {
  "Brand": "OMV",
  "Street": "Hauptstr. 3",
  "PostCode": 96191,
  "City": "Viereth-Trunstadt",
  "Latitude": "49.922.980",
  "Longitude": "10.781.260",
  "Telephone": "+49-9503-7655",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.92298,10.78126",
  "id": "49.92298,10.78126"
}, {
  "Brand": "Westfalen",
  "Street": "Mondstrasse 160 - 162",
  "PostCode": 48155,
  "City": "MUENSTER",
  "Latitude": "51.955.400",
  "Longitude": "07.677.220",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.9554,7.67722",
  "id": "51.9554,7.67722"
}, {
  "Brand": "ENI",
  "Street": "Muenchner Strasse 24/26",
  "PostCode": 82256,
  "City": "Fuerstenfeldbruck",
  "Latitude": "48.176.650",
  "Longitude": "11.260.060",
  "Telephone": "+49 (0) 81416200",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.17665,11.26006",
  "id": "48.17665,11.26006"
}, {
  "Brand": "TOTAL",
  "Street": "DORSTENER STR. 203",
  "PostCode": 44652,
  "City": "HERNE",
  "Latitude": "51.527.430",
  "Longitude": "07.187.499",
  "Telephone": "02325-910170",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.52743,7.187499",
  "id": "51.52743,7.187499"
}, {
  "Brand": "TOTAL",
  "Street": "HOHERODSKOPFSTR. 2",
  "PostCode": 63667,
  "City": "NIDDA",
  "Latitude": "50.420.064",
  "Longitude": "09.009.100",
  "Telephone": "06043-7031",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.420064,9.0091",
  "id": "50.420064,9.0091"
}, {
  "Brand": "TOTAL",
  "Street": "PRAGER STRASSE 179",
  "PostCode": 4299,
  "City": "LEIPZIG",
  "Latitude": "51.313.024",
  "Longitude": "12.414.926",
  "Telephone": "0341-8629060",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.313024,12.414926",
  "id": "51.313024,12.414926"
}, {
  "Brand": "TOTAL",
  "Street": "BAB 9 AUSFAHRT DROYSSIG/ 21B",
  "PostCode": 6721,
  "City": "OSTERFELD",
  "Latitude": "51.040.224",
  "Longitude": "11.938.468",
  "Telephone": "034422-31018",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.040224,11.938468",
  "id": "51.040224,11.938468"
}, {
  "Brand": "TOTAL",
  "Street": "HABICHTSTR. 11-13",
  "PostCode": 13505,
  "City": "BERLIN",
  "Latitude": "52.585.858",
  "Longitude": "13.231.867",
  "Telephone": "030-43673233",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.585858,13.231867",
  "id": "52.585858,13.231867"
}, {
  "Brand": "TOTAL",
  "Street": "GRUNDSCHOETTELER STR 58",
  "PostCode": 58300,
  "City": "WETTER",
  "Latitude": "51.371.183",
  "Longitude": "07.369.739",
  "Telephone": "02335-69161",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.371183,7.369739",
  "id": "51.371183,7.369739"
}, {
  "Brand": "OMV",
  "Street": "An der Alten Ziegelei 4",
  "PostCode": 89269,
  "City": "Vöhringen-Illerberg",
  "Latitude": "48.285.310",
  "Longitude": "10.116.670",
  "Telephone": "+49-7306-926805",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.28531,10.11667",
  "id": "48.28531,10.11667"
}, {
  "Brand": "ARAL",
  "Street": "Günzburger Straße 55",
  "PostCode": 89335,
  "City": "Ichenhausen",
  "Latitude": "48.374.001",
  "Longitude": "10.311.301",
  "Telephone": "082231281",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "48.374001,10.311301",
  "id": "48.374001,10.311301"
}, {
  "Brand": "ARAL",
  "Street": "Hölker Feld 4",
  "PostCode": 42279,
  "City": "Wuppertal",
  "Latitude": "51.295.359",
  "Longitude": "07.247.704",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.295359,7.247704",
  "id": "51.295359,7.247704"
}, {
  "Brand": "TOTAL",
  "Street": "NIBELUNGENSTR. 129",
  "PostCode": 64653,
  "City": "LORSCH",
  "Latitude": "49.653.192",
  "Longitude": "08.557.544",
  "Telephone": "06251-51222",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.653192,8.557544",
  "id": "49.653192,8.557544"
}, {
  "Brand": "Westfalen",
  "Street": "Paderborner Str. 32",
  "PostCode": 33161,
  "City": "HOEVELHOF",
  "Latitude": "51.817.600",
  "Longitude": "08.660.840",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.8176,8.66084",
  "id": "51.8176,8.66084"
}, {
  "Brand": "TOTAL",
  "Street": "SUTELSTR. 23",
  "PostCode": 30659,
  "City": "HANNOVER",
  "Latitude": "52.414.336",
  "Longitude": "09.797.400",
  "Telephone": "0511-6497636",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.414336,9.7974",
  "id": "52.414336,9.7974"
}, {
  "Brand": "ENI",
  "Street": "Merzhauser Strasse 104",
  "PostCode": 79100,
  "City": "Freiburg",
  "Latitude": "47.979.790",
  "Longitude": "07.831.870",
  "Telephone": "+49 (0) 76140345",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.97979,7.83187",
  "id": "47.97979,7.83187"
}, {
  "Brand": "ARAL",
  "Street": "Werner-v.-Siemens-Str. 31",
  "PostCode": 89415,
  "City": "Lauingen an der Donau",
  "Latitude": "48.575.483",
  "Longitude": "10.454.610",
  "Telephone": "090727017866",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.575483,10.45461",
  "id": "48.575483,10.45461"
}, {
  "Brand": "ENI",
  "Street": "Griesheimer Str. 31",
  "PostCode": 64560,
  "City": "Riedstadt-Wolfskehlen",
  "Latitude": "49.855.295",
  "Longitude": "08.506.222",
  "Telephone": "+49 (0) 61589752",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.855295,8.506222",
  "id": "49.855295,8.506222"
}, {
  "Brand": "Westfalen",
  "Street": "Meckenheimer Str. 8",
  "PostCode": 53359,
  "City": "RHEINBACH",
  "Latitude": "50.625.000",
  "Longitude": "06.960.660",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.625,6.96066",
  "id": "50.625,6.96066"
}, {
  "Brand": "ENI",
  "Street": "Hauptstr. 1a",
  "PostCode": 67297,
  "City": "Marnheim",
  "Latitude": "49.629.360",
  "Longitude": "08.035.720",
  "Telephone": "+49 (0) 63527048",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.62936,8.03572",
  "id": "49.62936,8.03572"
}, {
  "Brand": "ARAL",
  "Street": "Magdeburger Straße 252",
  "PostCode": 39218,
  "City": "Schönebeck",
  "Latitude": "52.020.277",
  "Longitude": "11.710.357",
  "Telephone": "03928/425942",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "52.020277,11.710357",
  "id": "52.020277,11.710357"
}, {
  "Brand": "TOTAL",
  "Street": "ALMOSENBERG 4",
  "PostCode": 97877,
  "City": "WERTHEIM",
  "Latitude": "49.771.601",
  "Longitude": "09.587.548",
  "Telephone": "09342-3029802",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.771601,9.587548",
  "id": "49.771601,9.587548"
}, {
  "Brand": "OMV",
  "Street": "Inntal West A 93",
  "PostCode": 83088,
  "City": "Kiefersfelden",
  "Latitude": "47.613.600",
  "Longitude": "12.201.200",
  "Telephone": "+49-8033-302560",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "47.6136,12.2012",
  "id": "47.6136,12.2012"
}, {
  "Brand": "Westfalen",
  "Street": "Bahnhofstr. 76",
  "PostCode": 33102,
  "City": "PADERBORN",
  "Latitude": "51.712.200",
  "Longitude": "08.734.240",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.7122,8.73424",
  "id": "51.7122,8.73424"
}, {
  "Brand": "ARAL",
  "Street": "Heronger Feld  9",
  "PostCode": 47638,
  "City": "Straelen",
  "Latitude": "51.382.563",
  "Longitude": "06.245.313",
  "Telephone": "028395682873",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.382563,6.245313",
  "id": "51.382563,6.245313"
}, {
  "Brand": "Westfalen",
  "Street": "Landstr. 10",
  "PostCode": 37170,
  "City": "USLAR",
  "Latitude": "51.627.000",
  "Longitude": "09.661.060",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.627,9.66106",
  "id": "51.627,9.66106"
}, {
  "Brand": "ENI",
  "Street": "Inntal-Ost / Autobahnrastst.2",
  "PostCode": 83126,
  "City": "Kiefersfelden",
  "Latitude": "47.733.870",
  "Longitude": "12.131.470",
  "Telephone": "+49 (0) 80333045",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "47.73387,12.13147",
  "id": "47.73387,12.13147"
}, {
  "Brand": "ENI",
  "Street": "Siegener Strasse",
  "PostCode": 65589,
  "City": "Hadamar",
  "Latitude": "50.477.720",
  "Longitude": "08.059.520",
  "Telephone": "+49 (0) 64332630",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.47772,8.05952",
  "id": "50.47772,8.05952"
}, {
  "Brand": "ENI",
  "Street": "Gottl.-Daimler-Str. 44",
  "PostCode": 68165,
  "City": "Mannheim",
  "Latitude": "49.473.734",
  "Longitude": "08.498.565",
  "Telephone": "+49 (0) 62144855",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.473734,8.498565",
  "id": "49.473734,8.498565"
}, {
  "Brand": "Westfalen",
  "Street": "Schiffahrter Damm 511",
  "PostCode": 48157,
  "City": "MUENSTER",
  "Latitude": "52.010.500",
  "Longitude": "07.678.900",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0105,7.6789",
  "id": "52.0105,7.6789"
}, {
  "Brand": "TOTAL",
  "Street": "DRESDNER STR. 49",
  "PostCode": 1156,
  "City": "DRESDEN",
  "Latitude": "51.087.051",
  "Longitude": "13.640.393",
  "Telephone": "0351-4540000",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.087051,13.640393",
  "id": "51.087051,13.640393"
}, {
  "Brand": "TOTAL",
  "Street": "33 BAHNHOFSTR.",
  "PostCode": 69190,
  "City": "WALLDORF",
  "Latitude": "49.300.460",
  "Longitude": "08.645.012",
  "Telephone": "06227-890050",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.30046,8.645012",
  "id": "49.30046,8.645012"
}, {
  "Brand": "TOTAL",
  "Street": "PIRSCHHEIDE 1",
  "PostCode": 14471,
  "City": "POTSDAM",
  "Latitude": "52.375.300",
  "Longitude": "13.008.198",
  "Telephone": "0331-972922",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.3753,13.008198",
  "id": "52.3753,13.008198"
}, {
  "Brand": "ARAL",
  "Street": "A20",
  "PostCode": 17129,
  "City": "Völschov",
  "Latitude": "53.856.510",
  "Longitude": "13.334.761",
  "Telephone": "",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.85651,13.334761",
  "id": "53.85651,13.334761"
}, {
  "Brand": "OMV",
  "Street": "Kufsteiner Str. 27",
  "PostCode": 83064,
  "City": "Raubling",
  "Latitude": "47.786.990",
  "Longitude": "12.109.830",
  "Telephone": "+49-8035-98052",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.78699,12.10983",
  "id": "47.78699,12.10983"
}, {
  "Brand": "ARAL",
  "Street": "Wiesenauer Str. 12",
  "PostCode": 30851,
  "City": "Langenhagen",
  "Latitude": "52.425.104",
  "Longitude": "09.717.257",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "52.425104,9.717257",
  "id": "52.425104,9.717257"
}, {
  "Brand": "Westfalen",
  "Street": "Natruper Str. 166",
  "PostCode": 49090,
  "City": "OSNABRUECK",
  "Latitude": "52.290.500",
  "Longitude": "08.020.250",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.2905,8.02025",
  "id": "52.2905,8.02025"
}, {
  "Brand": "ARAL",
  "Street": "Amperestraße 3",
  "PostCode": 63225,
  "City": "Langen",
  "Latitude": "49.998.592",
  "Longitude": "08.652.996",
  "Telephone": "061038037198",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "23:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "07:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.998592,8.652996",
  "id": "49.998592,8.652996"
}, {
  "Brand": "TOTAL",
  "Street": "SONNEWALDER STR.91 b",
  "PostCode": 3238,
  "City": "FINSTERWALDE",
  "Latitude": "51.644.699",
  "Longitude": "13.693.893",
  "Telephone": "03531-704610",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.644699,13.693893",
  "id": "51.644699,13.693893"
}, {
  "Brand": "ENI",
  "Street": "Schoenbuchstr. 40",
  "PostCode": 71032,
  "City": "Boeblingen",
  "Latitude": "48.673.160",
  "Longitude": "09.013.530",
  "Telephone": "+49 (0) 70312797",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.67316,9.01353",
  "id": "48.67316,9.01353"
}, {
  "Brand": "TOTAL",
  "Street": "BAHNHOFSTR. 37",
  "PostCode": 57392,
  "City": "SCHMALLENBERG",
  "Latitude": "51.158.288",
  "Longitude": "08.291.456",
  "Telephone": "02972-6279",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.158288,8.291456",
  "id": "51.158288,8.291456"
}, {
  "Brand": "ARAL",
  "Street": "Würzburger Straße 29",
  "PostCode": 96049,
  "City": "Bamberg",
  "Latitude": "49.880.123",
  "Longitude": "10.883.639",
  "Telephone": "095154378",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "49.880123,10.883639",
  "id": "49.880123,10.883639"
}, {
  "Brand": "TOTAL",
  "Street": "REICHENBACHER STR. 115",
  "PostCode": 8056,
  "City": "ZWICKAU",
  "Latitude": "50.708.401",
  "Longitude": "12.464.761",
  "Telephone": "0375-216851",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.708401,12.464761",
  "id": "50.708401,12.464761"
}, {
  "Brand": "ARAL",
  "Street": "Profilstr. 7",
  "PostCode": 58091,
  "City": "Hagen",
  "Latitude": "51.388.965",
  "Longitude": "07.512.463",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.388965,7.512463",
  "id": "51.388965,7.512463"
}, {
  "Brand": "TOTAL",
  "Street": "LEIPZIGER STRASSE 2F",
  "PostCode": 4552,
  "City": "BORNA",
  "Latitude": "51.132.339",
  "Longitude": "12.505.710",
  "Telephone": "03433-208626",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.132339,12.50571",
  "id": "51.132339,12.50571"
}, {
  "Brand": "ARAL",
  "Street": "Blankenburgstraße 112",
  "PostCode": 9114,
  "City": "Chemnitz OT Borna-He",
  "Latitude": "50.863.953",
  "Longitude": "12.907.288",
  "Telephone": "03713379026",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "50.863953,12.907288",
  "id": "50.863953,12.907288"
}, {
  "Brand": "TOTAL",
  "Street": "KOELLERTALSTR. 141",
  "PostCode": 66346,
  "City": "PUETTLINGEN",
  "Latitude": "49.293.900",
  "Longitude": "06.892.088",
  "Telephone": "06806-440332",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.2939,6.892088",
  "id": "49.2939,6.892088"
}, {
  "Brand": "TOTAL",
  "Street": "VATERSTETTEN WEST A 99",
  "PostCode": 85622,
  "City": "VATERSTETTEN",
  "Latitude": "48.124.502",
  "Longitude": "11.757.973",
  "Telephone": "0810635200",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.124502,11.757973",
  "id": "48.124502,11.757973"
}, {
  "Brand": "Westfalen",
  "Street": "Ruhrtalstr. 5",
  "PostCode": 58099,
  "City": "HAGEN",
  "Latitude": "51.412.000",
  "Longitude": "07.512.930",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.412,7.51293",
  "id": "51.412,7.51293"
}, {
  "Brand": "ENI",
  "Street": "Muenchner Str. 57",
  "PostCode": 85737,
  "City": "Ismaning",
  "Latitude": "48.223.358",
  "Longitude": "11.669.466",
  "Telephone": "+49 (0) 89969238",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.223358,11.669466",
  "id": "48.223358,11.669466"
}, {
  "Brand": "OMV",
  "Street": "Schweinfurter Str. 42",
  "PostCode": 97076,
  "City": "Würzburg",
  "Latitude": "49.799.580",
  "Longitude": "09.956.140",
  "Telephone": "+49-931-23310",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.79958,9.95614",
  "id": "49.79958,9.95614"
}, {
  "Brand": "TOTAL",
  "Street": "BAB SUEDSEITE / A 4",
  "PostCode": 50226,
  "City": "FRECHEN",
  "Latitude": "50.928.560",
  "Longitude": "06.775.694",
  "Telephone": "02234-52164",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.92856,6.775694",
  "id": "50.92856,6.775694"
}, {
  "Brand": "ENI",
  "Street": "Luitpoldstrae 55",
  "PostCode": 84034,
  "City": "Landshut",
  "Latitude": "48.542.111",
  "Longitude": "12.139.597",
  "Telephone": "+49 (0) 87161836",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.542111,12.139597",
  "id": "48.542111,12.139597"
}, {
  "Brand": "OMV",
  "Street": "Erlbacher Str. 4",
  "PostCode": 84172,
  "City": "Buch am Erlbach",
  "Latitude": "48.449.680",
  "Longitude": "12.033.030",
  "Telephone": "+49-8709-9286008",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.44968,12.03303",
  "id": "48.44968,12.03303"
}, {
  "Brand": "ENI",
  "Street": "Schmarler Damm 9",
  "PostCode": 18069,
  "City": "Rostock",
  "Latitude": "54.129.272",
  "Longitude": "12.075.473",
  "Telephone": "+49 (0) 38112096",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "54.129272,12.075473",
  "id": "54.129272,12.075473"
}, {
  "Brand": "OMV",
  "Street": "Keimstr. 2",
  "PostCode": 86420,
  "City": "Diedorf",
  "Latitude": "48.363.190",
  "Longitude": "10.789.540",
  "Telephone": "+49-821-4864976",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.36319,10.78954",
  "id": "48.36319,10.78954"
}, {
  "Brand": "ENI",
  "Street": "Tegernseer Strasse 20",
  "PostCode": 83703,
  "City": "Gmund",
  "Latitude": "47.747.439",
  "Longitude": "11.738.042",
  "Telephone": "+49 (0) 80227526",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.747439,11.738042",
  "id": "47.747439,11.738042"
}, {
  "Brand": "OMV",
  "Street": "Cosimastr. 10",
  "PostCode": 81927,
  "City": "München",
  "Latitude": "48.155.630",
  "Longitude": "11.630.000",
  "Telephone": "+49-89-914754",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.15563,11.63",
  "id": "48.15563,11.63"
}, {
  "Brand": "ENI",
  "Street": "Elverdisser Str. 327",
  "PostCode": 32052,
  "City": "Herford",
  "Latitude": "52.105.776",
  "Longitude": "08.661.338",
  "Telephone": "+49 (0) 52217123",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.105776,8.661338",
  "id": "52.105776,8.661338"
}, {
  "Brand": "ARAL",
  "Street": "Am Wattengraben 2",
  "PostCode": 55276,
  "City": "Oppenheim",
  "Latitude": "49.845.664",
  "Longitude": "08.360.125",
  "Telephone": "061333869197",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "49.845664,8.360125",
  "id": "49.845664,8.360125"
}, {
  "Brand": "TOTAL",
  "Street": "SCHILLERSTR. 80",
  "PostCode": 63741,
  "City": "ASCHAFFENBURG",
  "Latitude": "49.985.794",
  "Longitude": "09.135.177",
  "Telephone": "06021-423279",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.985794,9.135177",
  "id": "49.985794,9.135177"
}, {
  "Brand": "Westfalen",
  "Street": "Fr.-Ebert-Str. 129",
  "PostCode": 42117,
  "City": "WUPPERTAL",
  "Latitude": "51.251.100",
  "Longitude": "07.128.460",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.2511,7.12846",
  "id": "51.2511,7.12846"
}, {
  "Brand": "ARAL",
  "Street": "A96",
  "PostCode": 86899,
  "City": "Landsberg Lech",
  "Latitude": "48.057.954",
  "Longitude": "10.847.476",
  "Telephone": "08191972010",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "48.057954,10.847476",
  "id": "48.057954,10.847476"
}, {
  "Brand": "TOTAL",
  "Street": "WALTER-ALTHOFFSTR. 2",
  "PostCode": 31167,
  "City": "BOCKENEM",
  "Latitude": "52.001.488",
  "Longitude": "10.144.712",
  "Telephone": "05067-7948807",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.001488,10.144712",
  "id": "52.001488,10.144712"
}, {
  "Brand": "TOTAL",
  "Street": "ALTENAER STR. 244",
  "PostCode": 58513,
  "City": "LUEDENSCHEID",
  "Latitude": "51.254.477",
  "Longitude": "07.635.161",
  "Telephone": "02351-50021",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.254477,7.635161",
  "id": "51.254477,7.635161"
}, {
  "Brand": "TOTAL",
  "Street": "GROSSENBAUMER ALLEE 75",
  "PostCode": 47269,
  "City": "DUISBURG",
  "Latitude": "51.369.542",
  "Longitude": "06.779.052",
  "Telephone": "0203-761567",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.369542,6.779052",
  "id": "51.369542,6.779052"
}, {
  "Brand": "TOTAL",
  "Street": "WEINHEIMER STR. 34",
  "PostCode": 69509,
  "City": "MOERLENBACH",
  "Latitude": "49.596.600",
  "Longitude": "08.731.650",
  "Telephone": "062093305",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.5966,8.73165",
  "id": "49.5966,8.73165"
}, {
  "Brand": "TOTAL",
  "Street": "HALBERSTAEDTER STR.87",
  "PostCode": 6484,
  "City": "QUEDLINBURG",
  "Latitude": "51.809.142",
  "Longitude": "11.128.739",
  "Telephone": "03946-9019080",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.809142,11.128739",
  "id": "51.809142,11.128739"
}, {
  "Brand": "TOTAL",
  "Street": "LUCKENWALDER STR. 19",
  "PostCode": 14913,
  "City": "JUETERBOG",
  "Latitude": "52.003.599",
  "Longitude": "13.088.080",
  "Telephone": "03372-432352",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.003599,13.08808",
  "id": "52.003599,13.08808"
}, {
  "Brand": "ENI",
  "Street": "Finsterwalder Str. 14",
  "PostCode": 3205,
  "City": "Calau",
  "Latitude": "51.743.515",
  "Longitude": "13.941.393",
  "Telephone": "+49 (0) 35418019",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "51.743515,13.941393",
  "id": "51.743515,13.941393"
}, {
  "Brand": "TOTAL",
  "Street": "KOELNER STR. 18",
  "PostCode": 54634,
  "City": "BITBURG",
  "Latitude": "49.978.780",
  "Longitude": "06.525.920",
  "Telephone": "065612395",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.97878,6.52592",
  "id": "49.97878,6.52592"
}, {
  "Brand": "TOTAL",
  "Street": "SIEGBURGER STR.490-492",
  "PostCode": 51105,
  "City": "KOELN",
  "Latitude": "50.913.178",
  "Longitude": "06.997.610",
  "Telephone": "0221-834992",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.913178,6.99761",
  "id": "50.913178,6.99761"
}, {
  "Brand": "TOTAL",
  "Street": "LEONRODSTR. 48",
  "PostCode": 80636,
  "City": "MUENCHEN",
  "Latitude": "48.157.499",
  "Longitude": "11.543.893",
  "Telephone": "089-1292340",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.157499,11.543893",
  "id": "48.157499,11.543893"
}, {
  "Brand": "ENI",
  "Street": "Talstr. 37",
  "PostCode": 66287,
  "City": "Quierschied",
  "Latitude": "49.303.800",
  "Longitude": "07.026.520",
  "Telephone": "+49 (0) 6897-841",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.3038,7.02652",
  "id": "49.3038,7.02652"
}, {
  "Brand": "TOTAL",
  "Street": "KEMNADER STR. 315",
  "PostCode": 44797,
  "City": "BOCHUM",
  "Latitude": "51.428.481",
  "Longitude": "07.242.503",
  "Telephone": "0234-791008",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.428481,7.242503",
  "id": "51.428481,7.242503"
}, {
  "Brand": "ARAL",
  "Street": "Görschlitzer Str. 7",
  "PostCode": 4849,
  "City": "Bad Düben",
  "Latitude": "51.589.969",
  "Longitude": "12.601.952",
  "Telephone": "03424325037",
  "WeekDayFrom": "05:00",
  "WeekDayTo": "21:00",
  "SaturdayFrom": "06:00",
  "SaturdayTo": "21:00",
  "SundayFrom": "06:00",
  "SundayTo": "21:00",
  "IsOpen24Hours": "Nein",
  "location": "51.589969,12.601952",
  "id": "51.589969,12.601952"
}, {
  "Brand": "OMV",
  "Street": "Alte Str. 32",
  "PostCode": 79576,
  "City": "Weil am Rhein",
  "Latitude": "47.597.760",
  "Longitude": "07.599.870",
  "Telephone": "+49-7621-72160",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "47.59776,7.59987",
  "id": "47.59776,7.59987"
}, {
  "Brand": "TOTAL",
  "Street": "SANGERHAEUSER STR.",
  "PostCode": 6556,
  "City": "ARTERN",
  "Latitude": "51.364.087",
  "Longitude": "11.287.002",
  "Telephone": "03466-740568",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.364087,11.287002",
  "id": "51.364087,11.287002"
}, {
  "Brand": "TOTAL",
  "Street": "DRESDNER STR. 30 o",
  "PostCode": 4720,
  "City": "DOEBELN",
  "Latitude": "51.120.799",
  "Longitude": "13.150.689",
  "Telephone": "03431-574700",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.120799,13.150689",
  "id": "51.120799,13.150689"
}, {
  "Brand": "ARAL",
  "Street": "Gladbecker Str. 398 A",
  "PostCode": 45326,
  "City": "Essen",
  "Latitude": "51.486.159",
  "Longitude": "06.999.299",
  "Telephone": "020143644416",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.486159,6.999299",
  "id": "51.486159,6.999299"
}, {
  "Brand": "ENI",
  "Street": "Industriestr. 9",
  "PostCode": 64653,
  "City": "Lorsch",
  "Latitude": "49.642.470",
  "Longitude": "08.571.260",
  "Telephone": "+49 (0) 6251/572",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.64247,8.57126",
  "id": "49.64247,8.57126"
}, {
  "Brand": "TOTAL",
  "Street": "BAHNHOFSTR. 8",
  "PostCode": 77781,
  "City": "BIBERACH",
  "Latitude": "48.338.685",
  "Longitude": "08.030.357",
  "Telephone": "07835-3070",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.338685,8.030357",
  "id": "48.338685,8.030357"
}, {
  "Brand": "ENI",
  "Street": "Oberfoehringer Str. 178",
  "PostCode": 81925,
  "City": "Muenchen",
  "Latitude": "48.166.253",
  "Longitude": "11.623.666",
  "Telephone": "+49 (0) 89951498",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.166253,11.623666",
  "id": "48.166253,11.623666"
}, {
  "Brand": "TOTAL",
  "Street": "AM FEUCHTEN WINKEL 11",
  "PostCode": 13127,
  "City": "BERLIN",
  "Latitude": "52.582.052",
  "Longitude": "13.432.390",
  "Telephone": "030-47484636",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.582052,13.43239",
  "id": "52.582052,13.43239"
}, {
  "Brand": "TOTAL",
  "Street": "PRITZWALKER STR. 11",
  "PostCode": 16949,
  "City": "PUTLITZ",
  "Latitude": "53.237.716",
  "Longitude": "12.055.587",
  "Telephone": "033981-80578",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.237716,12.055587",
  "id": "53.237716,12.055587"
}, {
  "Brand": "OMV",
  "Street": "Lechwiesen Nord  A96",
  "PostCode": 86899,
  "City": "Landsberg am Lech",
  "Latitude": "48.059.630",
  "Longitude": "10.846.570",
  "Telephone": "+49-8191-972010",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "48.05963,10.84657",
  "id": "48.05963,10.84657"
}, {
  "Brand": "OMV",
  "Street": "Industriestr. 11",
  "PostCode": 96114,
  "City": "Hirschaid",
  "Latitude": "49.818.930",
  "Longitude": "11.000.730",
  "Telephone": "+49-9543-850485",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.81893,11.00073",
  "id": "49.81893,11.00073"
}, {
  "Brand": "Westfalen",
  "Street": "Loburg 58",
  "PostCode": 48346,
  "City": "OSTBEVERN",
  "Latitude": "52.036.700",
  "Longitude": "07.858.740",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.0367,7.85874",
  "id": "52.0367,7.85874"
}, {
  "Brand": "TOTAL",
  "Street": "STORKOWER STR. 174",
  "PostCode": 10369,
  "City": "BERLIN",
  "Latitude": "52.527.440",
  "Longitude": "13.457.798",
  "Telephone": "030-9720385",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.52744,13.457798",
  "id": "52.52744,13.457798"
}, {
  "Brand": "TOTAL",
  "Street": "DEKAN-LAIST-STR. 56",
  "PostCode": 55129,
  "City": "MAINZ",
  "Latitude": "49.963.816",
  "Longitude": "08.253.473",
  "Telephone": "06131-9728871",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.963816,8.253473",
  "id": "49.963816,8.253473"
}, {
  "Brand": "TOTAL",
  "Street": "LANGENER LANDSTR. 245-247",
  "PostCode": 27578,
  "City": "BREMERHAVEN",
  "Latitude": "53.587.848",
  "Longitude": "08.597.189",
  "Telephone": "0471-804075",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "53.587848,8.597189",
  "id": "53.587848,8.597189"
}, {
  "Brand": "TOTAL",
  "Street": "CHAUSSEESTR. 45",
  "PostCode": 17438,
  "City": "WOLGAST",
  "Latitude": "54.046.696",
  "Longitude": "13.752.053",
  "Telephone": "03836-232995",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "54.046696,13.752053",
  "id": "54.046696,13.752053"
}, {
  "Brand": "TOTAL",
  "Street": "MUENSTERSTR. 212 A",
  "PostCode": 49479,
  "City": "IBBENBUEREN",
  "Latitude": "52.258.098",
  "Longitude": "07.711.524",
  "Telephone": "05451-936673",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.258098,7.711524",
  "id": "52.258098,7.711524"
}, {
  "Brand": "TOTAL",
  "Street": "WIMSHEIMER STR. 21",
  "PostCode": 75233,
  "City": "TIEFENBRONN",
  "Latitude": "48.828.640",
  "Longitude": "08.801.220",
  "Telephone": "07234-95150",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.82864,8.80122",
  "id": "48.82864,8.80122"
}, {
  "Brand": "TOTAL",
  "Street": "DIESELSTR. 2",
  "PostCode": 41352,
  "City": "KORSCHENBROICH",
  "Latitude": "51.167.950",
  "Longitude": "06.590.750",
  "Telephone": "02182-5272",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.16795,6.59075",
  "id": "51.16795,6.59075"
}, {
  "Brand": "TOTAL",
  "Street": "TEMPELHOFER UFER 33-35",
  "PostCode": 10963,
  "City": "BERLIN",
  "Latitude": "52.500.478",
  "Longitude": "13.376.696",
  "Telephone": "030-2621259",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "52.500478,13.376696",
  "id": "52.500478,13.376696"
}, {
  "Brand": "ENI",
  "Street": "Ingolstaedter Str. 1",
  "PostCode": 93349,
  "City": "Mindelstetten",
  "Latitude": "48.850.471",
  "Longitude": "11.641.963",
  "Telephone": "+49 (0) 84049148",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.850471,11.641963",
  "id": "48.850471,11.641963"
}, {
  "Brand": "OMV",
  "Street": "Hauptstr. 4",
  "PostCode": 94436,
  "City": "Haunersdorf / Simbach",
  "Latitude": "48.608.720",
  "Longitude": "12.717.510",
  "Telephone": "+49-9956-323",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.60872,12.71751",
  "id": "48.60872,12.71751"
}, {
  "Brand": "TOTAL",
  "Street": "MITTELBIEL 2",
  "PostCode": 35606,
  "City": "SOLMS",
  "Latitude": "50.554.398",
  "Longitude": "08.412.371",
  "Telephone": "06441-9521852",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.554398,8.412371",
  "id": "50.554398,8.412371"
}, {
  "Brand": "TOTAL",
  "Street": "AUGSBURGER STRASSE 2",
  "PostCode": 99091,
  "City": "ERFURT",
  "Latitude": "51.002.242",
  "Longitude": "11.002.569",
  "Telephone": "0361-26232699",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.002242,11.002569",
  "id": "51.002242,11.002569"
}, {
  "Brand": "ARAL",
  "Street": "Bahnhofstraße 140",
  "PostCode": 63477,
  "City": "Maintal",
  "Latitude": "50.150.239",
  "Longitude": "08.836.001",
  "Telephone": "061814980046",
  "WeekDayFrom": "06:00",
  "WeekDayTo": "22:00",
  "SaturdayFrom": "07:00",
  "SaturdayTo": "22:00",
  "SundayFrom": "08:00",
  "SundayTo": "22:00",
  "IsOpen24Hours": "Nein",
  "location": "50.150239,8.836001",
  "id": "50.150239,8.836001"
}, {
  "Brand": "TOTAL",
  "Street": "GRENZHAMMER 4",
  "PostCode": 98693,
  "City": "ILMENAU",
  "Latitude": "50.678.669",
  "Longitude": "10.935.988",
  "Telephone": "03677-842787",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.678669,10.935988",
  "id": "50.678669,10.935988"
}, {
  "Brand": "OMV",
  "Street": "Passauer Str. 31",
  "PostCode": 94104,
  "City": "Tittling",
  "Latitude": "48.724.400",
  "Longitude": "13.382.220",
  "Telephone": "+49-8504-1648",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.7244,13.38222",
  "id": "48.7244,13.38222"
}, {
  "Brand": "ARAL",
  "Street": "A5",
  "PostCode": 79415,
  "City": "Bad Bellingen",
  "Latitude": "47.738.205",
  "Longitude": "07.551.952",
  "Telephone": "0763581100",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "47.738205,7.551952",
  "id": "47.738205,7.551952"
}, {
  "Brand": "Westfalen",
  "Street": "Spenrather Weg 1",
  "PostCode": 50829,
  "City": "KOELN",
  "Latitude": "50.980.500",
  "Longitude": "06.846.610",
  "Telephone": "0",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "50.9805,6.84661",
  "id": "50.9805,6.84661"
}, {
  "Brand": "TOTAL",
  "Street": "FRANKENSTR. 241",
  "PostCode": 45134,
  "City": "ESSEN",
  "Latitude": "51.422.046",
  "Longitude": "07.028.408",
  "Telephone": "0201-50776097",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "51.422046,7.028408",
  "id": "51.422046,7.028408"
}, {
  "Brand": "ARAL",
  "Street": "A72",
  "PostCode": 8606,
  "City": "Oelsnitz/Vogtl.",
  "Latitude": "50.433.625",
  "Longitude": "12.129.679",
  "Telephone": "03742125420",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.433625,12.129679",
  "id": "50.433625,12.129679"
}, {
  "Brand": "OMV",
  "Street": "Münchner Str. 30",
  "PostCode": 85567,
  "City": "Grafing",
  "Latitude": "48.048.620",
  "Longitude": "11.966.800",
  "Telephone": "+49-8092-9254",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.04862,11.9668",
  "id": "48.04862,11.9668"
}, {
  "Brand": "ENI",
  "Street": "Mannheimer Strasse 1A",
  "PostCode": 76676,
  "City": "Graben-Neudorf",
  "Latitude": "49.169.179",
  "Longitude": "08.492.727",
  "Telephone": "+49 (0) 72557255",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Ja",
  "location": "49.169179,8.492727",
  "id": "49.169179,8.492727"
}, {
  "Brand": "ARAL",
  "Street": "Mülheimer Heide 1",
  "PostCode": 53945,
  "City": "Blankenheim",
  "Latitude": "50.449.959",
  "Longitude": "06.667.896",
  "Telephone": "02449919251",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "50.449959,6.667896",
  "id": "50.449959,6.667896"
}, {
  "Brand": "OMV",
  "Street": "Hauptstr. 114",
  "PostCode": 90562,
  "City": "Heroldsberg",
  "Latitude": "49.524.590",
  "Longitude": "11.150.740",
  "Telephone": "+49-911-5676915",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.52459,11.15074",
  "id": "49.52459,11.15074"
}, {
  "Brand": "ARAL",
  "Street": "Otto-Hahn-Strasse 3",
  "PostCode": 46569,
  "City": "Hünxe",
  "Latitude": "51.630.995",
  "Longitude": "06.690.282",
  "Telephone": "-",
  "WeekDayFrom": "00:00",
  "WeekDayTo": "23:59",
  "SaturdayFrom": "00:00",
  "SaturdayTo": "23:59",
  "SundayFrom": "00:00",
  "SundayTo": "23:59",
  "IsOpen24Hours": "Ja",
  "location": "51.630995,6.690282",
  "id": "51.630995,6.690282"
}, {
  "Brand": "OMV",
  "Street": "Immenhofer Str. 48",
  "PostCode": 70180,
  "City": "Stuttgart",
  "Latitude": "48.762.930",
  "Longitude": "09.176.580",
  "Telephone": "+49-711-609164",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.76293,9.17658",
  "id": "48.76293,9.17658"
}, {
  "Brand": "OMV",
  "Street": "Altöttinger Str. 40",
  "PostCode": 84524,
  "City": "Neuötting",
  "Latitude": "48.234.750",
  "Longitude": "12.687.070",
  "Telephone": "+49-8671-2398",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "48.23475,12.68707",
  "id": "48.23475,12.68707"
}, {
  "Brand": "OMV",
  "Street": "Prüfeninger Str. 43",
  "PostCode": 93049,
  "City": "Regensburg",
  "Latitude": "49.016.700",
  "Longitude": "12.075.160",
  "Telephone": "+49-941-22373",
  "WeekDayFrom": "",
  "WeekDayTo": "",
  "SaturdayFrom": "",
  "SaturdayTo": "",
  "SundayFrom": "",
  "SundayTo": "",
  "IsOpen24Hours": "Nein",
  "location": "49.0167,12.07516",
  "id": "49.0167,12.07516"
}];