import { setDialog } from '../store';
import { DialogOption } from './showAlert';
import { store } from '../config';

export const showWebAlert = (
  title: string,
  message: string,
  yesActionText: any = null,
  yesAction: () => void = () => {},
  noActionText: any = null,
  noAction: () => void = () => {},
  isDestructive: boolean = false
) => {
  const dialog = {
    title,
    message,
    yesActionText,
    yesAction,
    noActionText,
    noAction,
    isDestructive,
  };
  store.dispatch(setDialog(dialog));
};

export const showWebAlertWithOptions = (
  title: string,
  message: string,
  options: DialogOption[] = [],
  isDestructive: boolean = false
) => {
  const dialog = {
    title,
    message,
    options,
    isDestructive,
  };
  store.dispatch(setDialog(dialog));
};
