import carsClient from '../../clients/carsClient';
import {
  setNetworkActivity,
  setNetworkSuccess,
  setNetworkError,
} from '../networkStatus';
import { CLIENT_TYPE } from '../../@types';
import TYPES from '../../@types/redux/store/PricingTypes';
import {
  Dispatch,
  GetState,
  SetCarPriceDetails,
  ClearCarPriceDetails,
} from '../../@types';
import { APP } from '../../enums';
import { setNotification } from '../ui/notifications';
import { PricingTableData } from '../../@types';

export const setCarPriceDetails = (
  carId: string,
  priceDetails: PricingTableData
): SetCarPriceDetails => ({
  type: TYPES.SET_CAR_PRICE_DETAILS,
  payload: {
    carId,
    priceDetails,
  },
});

export const clearCarPriceDetails = (): ClearCarPriceDetails => ({
  type: TYPES.CLEAR_CAR_PRICE_DETAILS,
});

export const getCarPriceDetails =
  (
    pricingId: string,
    carId: string,
    successCallbackFunction: (pricingData: PricingTableData) => void = () => {}
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.CARS_CLIENT.GET_PRICE_DETAILS));
    const { circleId } = getState().carsSearch;
    const { notModified, data, error } = await carsClient.getCarPriceDetails(
      pricingId,
      carId,
      circleId
    );

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.CARS_CLIENT.GET_PRICE_DETAILS, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else if (!!data && !notModified) {
      dispatch(setCarPriceDetails(carId, data));

      if (typeof successCallbackFunction === 'function') {
        successCallbackFunction(data);
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.CARS_CLIENT.GET_PRICE_DETAILS));
    }
  };
