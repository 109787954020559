import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  acceptBookingOffer,
  bookCar,
  getBookings,
  getMoreBookings,
  cancelBooking,
  removeBookingOffer,
  getBooking,
} from '../store/userData/bookings';
import { setMapContent } from '../store/ui/map';
import { State } from '../@types';

export type Props = {
  bookCar: typeof bookCar;
  getBooking: typeof getBooking;
  getBookings: typeof getBookings;
  getMoreBookings: typeof getMoreBookings;
  cancelBooking: typeof cancelBooking;
  acceptBookingOffer: typeof acceptBookingOffer;
  removeBookingOffer: typeof removeBookingOffer;
  setMapContent: typeof setMapContent;
  bookings: any;
};

const withBooking: any = compose(
  connect(
    ({
      userData: {
        bookings: { bookings },
      },
    }: State) => ({
      bookings,
    }),
    {
      bookCar,
      getBooking,
      getBookings,
      getMoreBookings,
      cancelBooking,
      acceptBookingOffer,
      removeBookingOffer,
      setMapContent,
    }
  )
);
export default withBooking;
