import { makeAPICall } from './helpers';
import { APIResponse, TextsData, Language, TextGroup } from '../@types';

const textClient = {
  get: async (
    group: TextGroup,
    language: Language
  ): Promise<APIResponse<TextsData>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: `/texts/${group}`,
      headers: {
        'Accept-Language': language,
      },
    });
    return {
      notModified,
      data,
      error,
    };
  },
};
export default textClient;
