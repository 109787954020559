import { makeAPICall } from './helpers';
import {
  APIResponse,
  ConnectCarData,
  CarStatusData,
  ConnectDeviceData,
  FleetUserDataOutput,
  ConnectFuelCardData,
  ConnectInstallationData,
  InstallationInputData,
} from '../@types';
import { toByteArray } from 'base64-js';

const userClient = {
  get: async (): Promise<APIResponse<FleetUserDataOutput>> => {
    const { data, error } = await makeAPICall({
      method: 'GET',
      url: '/fleets/admin',
    });
    return {
      data,
      error,
    };
  },
  forgetPassword: async (email: string): Promise<APIResponse<null>> => {
    const { error } = await makeAPICall({
      method: 'GET',
      url: '/fleets/admin/forgot',
      params: {
        email,
      },
    });
    return {
      error,
    };
  },
  findCars: async (): Promise<APIResponse<Array<ConnectCarData>>> => {
    const { data, error } = await makeAPICall({
      method: 'GET',
      url: '/fleets/connect/cars',
    });
    return {
      data,
      error,
    };
  },
  findDevices: async (): Promise<APIResponse<Array<ConnectDeviceData>>> => {
    const { data, error } = await makeAPICall({
      method: 'GET',
      url: '/fleets/connect/devices',
    });
    return {
      data,
      error,
    };
  },
  findFuelCards: async (): Promise<APIResponse<Array<ConnectFuelCardData>>> => {
    const { data, error } = await makeAPICall({
      method: 'GET',
      url: '/fleets/connect/fuelCards',
    });
    return {
      data,
      error,
    };
  },
  fetchDeviceStatus: async (
    deviceId: string
  ): Promise<APIResponse<CarStatusData>> => {
    const { data, error } = await makeAPICall({
      method: 'GET',
      url: `/fleets/connect/devices/${deviceId}/status`,
    });
    return {
      data,
      error,
    };
  },
  lockDevice: async (deviceId: string): Promise<APIResponse<CarStatusData>> => {
    const { data, error } = await makeAPICall({
      method: 'PUT',
      url: `/fleets/connect/devices/${deviceId}/lock`,
    });
    return {
      data,
      error,
    };
  },
  unlockDevice: async (
    deviceId: string
  ): Promise<APIResponse<CarStatusData>> => {
    const { data, error } = await makeAPICall({
      method: 'PUT',
      url: `/fleets/connect/devices/${deviceId}/unlock`,
    });
    return {
      data,
      error,
    };
  },
  startCarInstallation: async (
    carId: string
  ): Promise<APIResponse<ConnectInstallationData>> => {
    const { data, error } = await makeAPICall({
      method: 'PUT',
      url: `/fleets/connect/cars/${carId}/startInstallation`,
    });
    return {
      data,
      error,
    };
  },
  updateCarInstallation: async (
    carId: string,
    inputData: InstallationInputData
  ): Promise<APIResponse<ConnectInstallationData>> => {
    const { data, error } = await makeAPICall({
      method: 'PUT',
      url: `/fleets/connect/cars/${carId}/installation`,
      data: inputData,
    });
    return {
      data,
      error,
    };
  },
  finishCarInstallation: async (
    carId: string
  ): Promise<APIResponse<ConnectInstallationData>> => {
    const { data, error } = await makeAPICall({
      method: 'PUT',
      url: `/fleets/connect/cars/${carId}/finishInstallation`,
    });
    return {
      data,
      error,
    };
  },
  cancelCarInstallation: async (carId: string): Promise<APIResponse<void>> => {
    const { error } = await makeAPICall({
      method: 'DELETE',
      url: `/fleets/connect/cars/${carId}/cancelInstallation`,
    });
    return {
      error,
    };
  },
  uninstallCar: async (carId: string): Promise<APIResponse<void>> => {
    const { error } = await makeAPICall({
      method: 'DELETE',
      url: `/fleets/connect/cars/${carId}/uninstall`,
    });
    return {
      error,
    };
  },
  uploadDocument: async (
    carId: string,
    document: string
  ): Promise<APIResponse<ConnectCarData>> => {
    const binary = toByteArray(document);
    const { data, error } = await makeAPICall({
      method: 'POST',
      url: `/fleets/connect/cars/${carId}/installation/image`,
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      data: binary,
    });
    return {
      data,
      error,
    };
  },
};
export default userClient;
