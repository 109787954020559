import axios from 'axios';
import { APICallArgs, APIResponse } from '../../@types';

const makeS3UploadAPICall = async ({
  url,
  data,
  timeout = 120 * 1000,
}: APICallArgs): Promise<APIResponse<any>> => {
  try {
    const requestConfig: any = {
      method: 'PUT',
      url,
      data,
      timeout,
      responseType: 'json',
      headers: {
        Accept: 'application/json; charset=utf-8',
        'Content-Type': 'image/jpeg',
      },
    };
    const response = await axios(requestConfig);
    return {
      data: response.data,
    };
  } catch (error: any) {
    if (error.response) {
      // Non-2xx response received
      const { data: errorData, status, headers: errorHeaders } = error.response;
      return {
        error: {
          type: 'error-response-received',
          detail: {
            data: errorData,
            status,
            headers: errorHeaders,
          },
          stack: error,
        },
      };
    }

    if (error.request) {
      // No response received
      // instanceof XMLHttpRequest
      return {
        error: {
          type: 'no-response-received',
          detail: error.request,
          stack: error,
        },
      };
    }

    // Error setting up the request
    return {
      error: {
        type: 'request-setup-failed',
        detail: {
          message: error.message,
        },
        stack: error,
      },
    };
  }
};

export default makeS3UploadAPICall;
