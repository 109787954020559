import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  setGoogleApiAccessToken,
  setApiBaseURL,
  setApiVersion,
  setBundleId,
  setAppleAppId,
  setXBrandId,
} from '../store/config';

export type Props = {
  setGoogleApiAccessToken: typeof setGoogleApiAccessToken;
  setApiBaseURL: typeof setApiBaseURL;
  setApiVersion: typeof setApiVersion;
  setBundleId: typeof setBundleId;
  setAppleAppId: typeof setAppleAppId;
  setXBrandId: typeof setXBrandId;
};

const withConfigSetter: any = compose(
  connect(null, {
    setGoogleApiAccessToken,
    setApiBaseURL,
    setApiVersion,
    setBundleId,
    setAppleAppId,
    setXBrandId,
  })
);
export default withConfigSetter;
