import { connect } from 'react-redux';
import { compose } from 'recompose';
import { State, CardState, MapContent } from '../@types';
import { setCardState, setMapContent } from '../store/ui/map';

export type Props = {
  cardState: CardState;
  mapContent: MapContent;
  setMapContent: typeof setMapContent;
  setCardState: typeof setCardState;
};

const withScrollableCard: any = compose(
  connect(
    ({
      ui: {
        map: { content: mapContent, cardState },
      },
      userData: {
        rental: { rentalData },
      },
    }: State) => ({
      isDongleRental: rentalData?.carData?.vehicleConnection === 'geoTab',
      mapContent,
      cardState,
    }),
    {
      setMapContent,
      setCardState,
    }
  )
);
export default withScrollableCard;
