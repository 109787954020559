import initialState from './InitialState';
import TYPES from '../../../@types/redux/store/FeaturesTypes';
import { FeaturesAction, FeaturesState } from '../../../@types';

export default (
  state: FeaturesState = initialState,
  action: FeaturesAction
): FeaturesState => {
  switch (action.type) {
    case TYPES.SET_FEATURES: {
      return action.payload.features;
    }

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
