import { CLIENT_TYPE, State } from '../@types';

export const selectNetworkActivities = (store: State) =>
  store.networkStatus.activities;

export const selectIsReservingCar = (store: State) =>
  store.networkStatus.activities.some((activityType: string) =>
    [CLIENT_TYPE.RENTAL_CLIENT.RESERVE_CAR].includes(activityType)
  );

export const selectIsBookingCar = (store: State) =>
  store.networkStatus.activities.some((activityType: string) =>
    [CLIENT_TYPE.BOOKING_CLIENT.BOOK_CAR].includes(activityType)
  );

export const selectIsUnlockingCar = (store: State) =>
  store.networkStatus.activities.some((activityType: string) =>
    [CLIENT_TYPE.RENTAL_CLIENT.UNLOCK_CAR].includes(activityType)
  );
