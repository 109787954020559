import { connect } from 'react-redux';
import { compose } from 'recompose';
import { State } from '../@types';
import { isRentalOngoing } from '../helpers';
import { setDeviceConnected } from '../store/device';

export type Props = {
  setDeviceConnected: typeof setDeviceConnected;
  deviceConnected: boolean;
  devicePaired: boolean;
  locationPermission: any;
  cameraPermission: any;
  bluetoothPermission: any;
  basicAuth: string;
  bluetoothStatus: any;
  activeAlert: any;
  alertBaseMode: boolean;
  inRental: boolean;
};

const withStatusAlert: any = compose(
  connect(
    ({
      userData: {
        user: { basicAuth },
        rental: { rentalData },
      },
      ui: {
        map: { activeAlert, alertBaseMode },
      },
      bluetooth: { status: bluetoothStatus },
      device: {
        status: {
          devicePaired,
          deviceConnected,
          locationPermission,
          cameraPermission,
          bluetoothPermission,
        },
      },
    }: State) => ({
      inRental:
        isRentalOngoing(rentalData) &&
        rentalData?.type !== 'plannedServiceTrip' &&
        rentalData?.carData?.vehicleConnection !== 'geoTab',
      bluetoothStatus,
      deviceConnected,
      devicePaired,
      cameraPermission,
      locationPermission,
      bluetoothPermission,
      basicAuth,
      activeAlert,
      alertBaseMode,
    }),
    {
      setDeviceConnected,
    }
  )
);
export default withStatusAlert;
