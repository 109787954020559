import { PURGE } from 'redux-persist';
import initialState from './initialState';
import TYPES from '../../../@types/redux/store/BookingsTypes';
import { BookingsAction, BookingsState } from '../../../@types/redux/store/BookingsTypes';

export default (
  state: BookingsState = initialState,
  action: BookingsAction
): BookingsState => {
  switch (action.type) {
    case TYPES.SET_BOOKINGS: {
      return { ...state, bookings: action.payload.bookings };
    }

    case TYPES.UPDATE_BOOKING: {
      return {
        ...state,
        bookings: [
          ...state.bookings.map((booking) =>
            booking.id === action.payload.booking.id
              ? action.payload.booking
              : booking
          ),
        ],
      };
    }

    case TYPES.APPEND_BOOKINGS: {
      return {
        ...state,
        bookings: [
          ...state.bookings, // This eliminates any duplicate bookings
          ...action.payload.bookings.filter(
            (actionItem) =>
              !state.bookings.filter(
                (stateItem) => stateItem.id === actionItem.id
              ).length
          ),
        ],
      };
    }

    case TYPES.SET_BOOKING_VIOLATIONS: {
      return { ...state, bookingViolations: action.payload.bookingViolations };
    }

    case TYPES.CLEAR_BOOKING_VIOLATIONS: {
      return { ...state, bookingViolations: null };
    }

    // @ts-ignore
    case PURGE:
      return initialState;

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
