import { PhoneCode } from '../../Types';

export type SelectedAddress = {
  streetName: string;
  streetNumber: string;
  city: string;
  country: string;
  postalCode: string;
};

export type PhoneType = {
  phoneNumber: string;
  phoneCode: PhoneCode;
};

export type FirstStepsState = {
  readonly address: SelectedAddress;
  readonly phone: PhoneType;
  readonly referralCode: string;
  readonly phoneCodes: Array<PhoneCode>;
  readonly circleCode: string;
};

enum FirstStepsTypes {
  SET_ADDRESS = 'ui/firstSteps/SET_ADDRESS',
  CLEAR_ADDRESS = 'ui/firstSteps/CLEAR_ADDRESS',
  SET_PHONE_NUMBER = 'ui/firstSteps/SET_PHONE_NUMBER',
  SET_PHONE_CODE = 'ui/firstSteps/SET_PHONE_CODE',
  CLEAR_PHONE_NUMBER = 'ui/firstSteps/CLEAR_PHONE_NUMBER',
  CLEAR_PHONE_CODE = 'ui/firstSteps/CLEAR_PHONE_CODE',
  SET_REFERRAL_CODE = 'ui/firstSteps/SET_REFERRAL_CODE',
  CLEAR_REFERRAL_CODE = 'ui/firstSteps/CLEAR_REFERRAL_CODE',
  SET_CIRCLE_CODE = 'ui/firstSteps/SET_CIRCLE_CODE',
  ClEAR_CIRCLE_CODE = 'ui/firstSteps/ClEAR_CIRCLE_CODE',
}

export default FirstStepsTypes;

export type SetAddress = {
  type: FirstStepsTypes.SET_ADDRESS;
  payload: {
    address: SelectedAddress;
  };
};

export type ClearAddress = {
  type: FirstStepsTypes.CLEAR_ADDRESS;
};

export type SetPhoneNumber = {
  type: FirstStepsTypes.SET_PHONE_NUMBER;
  payload: {
    phoneNumber: string;
  };
};

export type SetPhoneCode = {
  type: FirstStepsTypes.SET_PHONE_CODE;
  payload: {
    phoneCode: PhoneCode;
  };
};

export type ClearPhoneNumber = {
  type: FirstStepsTypes.CLEAR_PHONE_NUMBER;
};

export type ClearPhoneCode = {
  type: FirstStepsTypes.CLEAR_PHONE_CODE;
};

export type SetReferralCode = {
  type: FirstStepsTypes.SET_REFERRAL_CODE;
  payload: {
    referralCode: string;
  };
};

export type ClearReferralCode = {
  type: FirstStepsTypes.CLEAR_REFERRAL_CODE;
};

export type SetCircleCode = {
  type: FirstStepsTypes.SET_CIRCLE_CODE;
  payload: {
    circleCode: string;
  };
};

export type ClearCircleCode = {
  type: FirstStepsTypes.ClEAR_CIRCLE_CODE;
};

export type FirstStepsAction =
  | SetAddress
  | SetPhoneNumber
  | SetPhoneCode
  | ClearPhoneNumber
  | ClearPhoneCode
  | SetReferralCode
  | ClearReferralCode
  | SetCircleCode
  | ClearCircleCode
  | ClearAddress;
