import { makeAPICall } from './helpers';
import {
  APIResponse,
  DriverBookingData,
  OwnerBookingData,
  BookingOfferData,
  BookingData,
} from '../@types';

const bookingClient = {
  getDriverBooking: async (
    bookingId: string
  ): Promise<APIResponse<DriverBookingData>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: `/bookings/${bookingId}`,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  getDriverBookings: async (
    afterId: string | null | undefined = null,
    limit: number = 10,
    includeHistory: boolean = true
  ): Promise<APIResponse<Array<DriverBookingData>>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: '/bookings',
      params: {
        afterId,
        limit,
        includeHistory,
      },
    });
    return {
      notModified,
      data,
      error,
    };
  },
  bookCar: async (
    bookingData: BookingData
  ): Promise<APIResponse<DriverBookingData>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'POST',
      url: '/bookings',
      params: bookingData,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  cancelBookingRequest: async (
    bookingId: string
  ): Promise<APIResponse<DriverBookingData>> => {
    const { error, data } = await makeAPICall({
      method: 'DELETE',
      url: `/bookings/${bookingId}`,
    });
    return {
      error,
      data,
    };
  },
  acceptBookingOffer: async (
    bookingId: string,
    carId: string
  ): Promise<APIResponse<DriverBookingData>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'PUT',
      url: `/bookings/${bookingId}/${carId}`,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  removeBookingOffer: async (
    carId: string,
    bookingId: string
  ): Promise<APIResponse<null>> => {
    const { error } = await makeAPICall({
      method: 'DELETE',
      url: `/bookings/${bookingId}/${carId}`,
    });
    return {
      error,
    };
  },
  getOwnerBookings: async (
    carId: string
  ): Promise<APIResponse<Array<OwnerBookingData>>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: `/cars/${carId}/bookings`,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  acceptBookingRequest: async (
    bookingId: string,
    carId: string,
    bookingOffer: BookingOfferData
  ): Promise<APIResponse<OwnerBookingData>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'PUT',
      url: `cars/${carId}/bookings/${bookingId}`,
      params: bookingOffer,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  cancelBookingOffer: async (
    bookingId: string,
    carId: string
  ): Promise<APIResponse<null>> => {
    const { error } = await makeAPICall({
      method: 'POST',
      url: `cars/${carId}/bookings/${bookingId}/cancel`,
    });
    return {
      error,
    };
  },
  removeBookingRequest: async (
    carId: string,
    bookingId: string
  ): Promise<APIResponse<null>> => {
    const { error } = await makeAPICall({
      method: 'DELETE',
      url: `/cars/${carId}/bookings/${bookingId}`,
    });
    return {
      error,
    };
  },
  getCarBookings: async (
    carId: string,
    from: string | Date,
    to: string | Date
  ): Promise<APIResponse<Array<DriverBookingData>>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: `/cars/${carId}/activeBookings`,
      params: {
        from,
        to,
      },
    });
    return {
      notModified,
      data,
      error,
    };
  },
  updateBooking: async (
    bookingId: string,
    extras: Record<string, any>
  ): Promise<APIResponse<any>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'PUT',
      url: `/bookings/${bookingId}/extras`,
      data: extras,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  getBookingHandoverData: async (
    bookingId: string
  ): Promise<APIResponse<Array<DriverBookingData>>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: `/bookings/${bookingId}/handover`,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  getFleetInsurances: async (
    fleetId: string,
    circleId: string | null | undefined
  ): Promise<APIResponse<Array<any>>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: `/insurance/fleet/${fleetId}`,
      params: {
        circleId,
      },
    });
    return {
      notModified,
      data,
      error,
    };
  },
};
export default bookingClient;
