import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setSupportContacted } from '../store/appData/common';
import { State } from '../@types';

export type Props = {
  setSupportContacted: typeof setSupportContacted;
  intercomHandlingMode: string;
};

const withSubscriber: any = compose(
  connect(
    ({
      appData: {
        common: { brandSettings },
      },
    }: State) => ({
      intercomHandlingMode: brandSettings?.intercomHandlingMode,
    }),
    {
      setSupportContacted,
    }
  )
);
export default withSubscriber;
