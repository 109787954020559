import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setMapContent } from '../store/ui/map';
import { reserveCar } from '../store/userData/rental';
import { clearFilter } from '../store/carsSearch';
import { bookCar, getCarBookings } from '../store/userData/bookings';
import {
  DriverBookingData,
  DriverCarData,
  CLIENT_TYPE,
  State,
  OutputCarData,
  AreaData,
} from '../@types';

export type Props = {
  bookings: Array<DriverBookingData>;
  cars: Array<DriverCarData>;
  from?: string;
  backBy: string;
  mileage?: number;
  reserveCar: typeof reserveCar;
  bookCar: typeof bookCar;
  setMapContent: typeof setMapContent;
  reservingCar: boolean;
  bookingCar: boolean;
  clearFilter: typeof clearFilter;
  getCarBookings: typeof getCarBookings;
  selectedCar: OutputCarData;
  areas?: AreaData[];
};

const withCarProfile: any = compose(
  connect(
    ({
      userData: {
        bookings: { bookings },
      },
      carsSearch: { from, backBy, mileage, unavailableCars },
      networkStatus: { activities },
      ui: {
        map: { selectedCar },
      },
      appData: { areas },
    }: State) => ({
      bookings,
      from,
      backBy,
      mileage,
      unavailableCars,
      selectedCar,
      areas,
      reservingCar: activities.some((activityType: string) =>
        [CLIENT_TYPE.RENTAL_CLIENT.RESERVE_CAR].includes(activityType)
      ),
      bookingCar: activities.some((activityType: string) =>
        [CLIENT_TYPE.BOOKING_CLIENT.BOOK_CAR].includes(activityType)
      ),
    }),
    {
      setMapContent,
      reserveCar,
      bookCar,
      clearFilter,
      getCarBookings,
    }
  )
);
export default withCarProfile;
