import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  uploadDocument,
  setCameraImages,
  removeCameraImage,
  clearCameraImages,
  setCarCheckCameraImage,
  removeCarCheckCameraImage,
} from '../store';
import { CarCheckImages, Image, State } from '../@types';

export type Props = {
  images: Array<Image>;
  carCheckImages: CarCheckImages;
  setCameraImages: typeof setCameraImages;
  removeCameraImage: typeof removeCameraImage;
  clearCameraImages: typeof clearCameraImages;
  setCarCheckCameraImage: typeof setCarCheckCameraImage;
  removeCarCheckCameraImage: typeof removeCarCheckCameraImage;
  uploadDocument: typeof uploadDocument;
};

const withCamera: any = compose(
  connect(
    ({ camera: { images, carCheckImages } }: State) => ({
      images,
      carCheckImages,
    }),
    {
      setCameraImages,
      removeCameraImage,
      clearCameraImages,
      setCarCheckCameraImage,
      removeCarCheckCameraImage,
      uploadDocument,
    }
  )
);
export default withCamera;
