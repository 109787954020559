import { State } from '../@types';

export const selectLastKnownLocation = (store: State) =>
  store.device.lastKnownLocation;
export const selectDeviceUUID = (store: State) => store.device.deviceUUID;
export const selectDevicePaired = (store: State) =>
  store.device.status.devicePaired;
export const selectDeviceConnected = (store: State) =>
  store.device.status.deviceConnected;
export const selectIsKeyboardOpened = (store: State) =>
  store.device.isKeyboardOpened;
