import { connect } from 'react-redux';
import { compose } from 'recompose';
import { DriverBookingData, DriverCarData } from '../@types';
import { CLIENT_TYPE } from '../@types';
import { clearFilter } from '../store/carsSearch';
import { getCarBookings, bookCar } from '../store/userData/bookings';
import { reserveCar } from '../store/userData/rental';
import { setMapContent } from '../store/ui/map';
import { State } from '../@types';

export type Props = {
  bookings: Array<DriverBookingData>;
  cars: Array<DriverCarData>;
  from?: string;
  backBy: string;
  mileage?: number;
  reserveCar: typeof reserveCar;
  bookCar: typeof bookCar;
  setMapContent: typeof setMapContent;
  reservingCar: boolean;
  bookingCar: boolean;
  clearFilter: typeof clearFilter;
  categories: any;
};

const withCarCategory: any = compose(
  connect(
    ({
      userData: {
        bookings: { bookings },
      },
      carsSearch: { from, backBy, mileage, unavailableCars, categories },
      networkStatus: { activities },
    }: State) => ({
      bookings,
      from,
      backBy,
      mileage,
      categories,
      unavailableCars,
      reservingCar: activities.some((activityType) =>
        [CLIENT_TYPE.RENTAL_CLIENT.RESERVE_CAR].includes(activityType)
      ),
      bookingCar: activities.some((activityType) =>
        [CLIENT_TYPE.BOOKING_CLIENT.BOOK_CAR].includes(activityType)
      ),
    }),
    {
      setMapContent,
      reserveCar,
      bookCar,
      clearFilter,
      getCarBookings,
    }
  )
);
export default withCarCategory;
