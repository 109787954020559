import AsyncStorage from '@react-native-async-storage/async-storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const getReducerPersistBlacklist = (reducer: string): Array<string> => {
  switch (reducer) {
    case 'rental':
      return ['rentalViolations', 'finishRentalViolations'];

    case 'bookings':
      return ['bookingViolations'];

    default:
      return [];
  }
};

const getReducerPersistWhitelist = (reducer: string): Array<string> => {
  switch (reducer) {
    case 'map':
      return ['isFirstRental', 'showHomeWT', 'userAddress'];

    case 'damages':
      return ['newDamages'];

    case 'appData':
      return ['areas', 'serviceTypes', 'common'];

    case 'carsSearch':
      return ['circleId'];

    case 'networkStatus':
      return ['getHistory'];

    default:
      return [];
  }
};

export default (reducer: string): any => {
  const config: any = {
    key: reducer,
    storage: AsyncStorage,
    stateReconciler: autoMergeLevel2,
  };
  const blacklist = getReducerPersistBlacklist(reducer);
  const whitelist = getReducerPersistWhitelist(reducer);

  if (blacklist.length > 0) {
    config.blacklist = blacklist;
  }

  if (whitelist.length > 0) {
    config.whitelist = whitelist;
  }

  return config;
};
