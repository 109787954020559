import { isBefore24hrs } from './dateHelpers';
import { LocalizePropType } from '../enhancers/withTextLocalizer';
import { PaymentMethodDataOutput, UserDataOutput } from '../@types';
import { showAlert } from './showAlert';

const isMobileBrowser = (): boolean => {
  if (window) {
    return window?.matchMedia('only screen and (max-width: 760px)')?.matches;
  } else return false;
};

export const showAvailabilityDialog = (
  localize: LocalizePropType,
  timeToReturn: string,
  yesAction: () => void
) => {
  showAlert(
    localize('car.reserve.carBooked.title', timeToReturn),
    localize('car.reserve.carBooked.description'),
    localize('car.reserve.carBooked.yes.text'),
    () => yesAction(),
    localize('cancel')
  );
};

export const showBlockPaymentDialog = (
  localize: LocalizePropType,
  yesAction: () => void
) => {
  showAlert(
    localize('car.reserve.blockPayment.title'),
    localize('car.reserve.blockPayment.description'),
    localize('car.reserve.blockPayment.yes.text'),
    () => yesAction(),
    localize('cancel'),
    () => {}
  );
};

export const showRentalTypeDialog = (
  localize: LocalizePropType,
  onServiceSelected: () => void,
  onRentalSelected: () => void
) => {
  // if (isIOS()) {
  //   ActionSheetIOS.showActionSheetWithOptions(
  //     {
  //       title: localize('drivercar.rental.dialog.title'),
  //       message: localize('drivercar.rental.dialog.message'),
  //       options: [
  //         'cancel',
  //         'drivercar.rental.dialog.reserve.rental',
  //         'drivercar.rental.dialog.reserve.service',
  //       ].map((key) => localize(((key: any): string))),
  //       cancelButtonIndex: 0,
  //     },
  //     (buttonIndex) => {
  //       if (buttonIndex === 1) {
  //         onRentalSelected();
  //       }
  //       if (buttonIndex === 2) {
  //         onServiceSelected();
  //       }
  //     },
  //   );
  // } else {
  //   UIManager.showListAlert(
  //     localize('drivercar.rental.dialog.title'),
  //     [
  //       'drivercar.rental.dialog.reserve.rental',
  //       'drivercar.rental.dialog.reserve.service',
  //     ].map((key) => localize(key)),
  //     (response) => {
  //       if (response.buttonIndex) {
  //         const selectedIndex = parseInt(response.buttonIndex, 10);
  //         if (selectedIndex === 0) {
  //           onRentalSelected();
  //         } else if (selectedIndex === 1) {
  //           onServiceSelected();
  //         }
  //       }
  //     },
  //   );
  // }
};

const isPayinPayment = (paymentMethods: Array<PaymentMethodDataOutput>) => {
  const verifiedPayments = paymentMethods.filter(
    (payment) =>
      payment.status === 'verified' &&
      (!payment.paymentData || Object.keys(payment.paymentData).length === 0)
  );
  return verifiedPayments.length === 1 && 'payIn' in verifiedPayments[0];
};

export const onReserveCar = (
  localize: LocalizePropType,
  user: UserDataOutput,
  service: boolean,
  onReservationApproved: (service: boolean) => void,
  availableUntil: string,
  msToHMS: (number: number, localize: LocalizePropType) => void,
  payInBookCar: () => void
) => {
  const timeToReturn = availableUntil
    ? // @ts-ignore
      msToHMS(Math.abs(new Date(availableUntil) - new Date()), localize)
    : '';
  const shouldShowAvailabilityDialog =
    !!availableUntil && isBefore24hrs(new Date(availableUntil));

  if (service) {
    onReservationApproved(true);
  } else if (isPayinPayment(user.paymentMethods!) || !isMobileBrowser()) {
    if (shouldShowAvailabilityDialog) {
      showAvailabilityDialog(localize, timeToReturn!, () => payInBookCar());
    } else {
      payInBookCar();
    }
  } else if (shouldShowAvailabilityDialog) {
    showAvailabilityDialog(localize, timeToReturn!, () =>
      onReservationApproved(false)
    );
  } else {
    onReservationApproved(false);
  }
};

export const onReserveCarClicked = (
  localize: LocalizePropType,
  startDate: Date | null | undefined,
  availableUntil: string,
  msToHMS: () => void,
  user: UserDataOutput,
  isServiceHero: boolean,
  availableForRental: boolean,
  onBookingApproved: () => void,
  onReservationApproved: (service: boolean) => void,
  payInBookCar: () => void
) => {
  if (startDate) {
    if (availableForRental) {
      if (isPayinPayment(user?.paymentMethods!)) {
        payInBookCar();
      } else {
        onBookingApproved();
      }
    }
  } else if (isServiceHero) {
    if (availableForRental) {
      showRentalTypeDialog(
        localize,
        () =>
          onReserveCar(
            localize,
            user,
            true,
            onReservationApproved,
            availableUntil,
            msToHMS,
            payInBookCar
          ),
        () =>
          onReserveCar(
            localize,
            user,
            false,
            onReservationApproved,
            availableUntil,
            msToHMS,
            payInBookCar
          )
      );
    } else {
      onReserveCar(
        localize,
        user,
        true,
        onReservationApproved,
        availableUntil,
        msToHMS,
        payInBookCar
      );
    }
  } else {
    onReserveCar(
      localize,
      user,
      false,
      onReservationApproved,
      availableUntil,
      msToHMS,
      payInBookCar
    );
  }
};
