import {
  ConnectCarData,
  ConnectDeviceData,
  ConnectFuelCardData,
  ConnectInstallationData,
  SetConnectCars,
  SetConnectDevices,
  SetConnectFuelCards,
  SetConnectInstallation,
} from '../../../@types';
import TYPES from '../../../@types/redux/store/ConnectAppDataTypes';

export const setCars = (cars: Array<ConnectCarData>): SetConnectCars => ({
  type: TYPES.SET_CARS,
  payload: {
    cars,
  },
});

export const setDevices = (
  devices: Array<ConnectDeviceData>
): SetConnectDevices => ({
  type: TYPES.SET_DEVICES,
  payload: {
    devices,
  },
});

export const setFuelCards = (
  fuelCards: Array<ConnectFuelCardData>
): SetConnectFuelCards => ({
  type: TYPES.SET_FUEL_CARDS,
  payload: {
    fuelCards,
  },
});

export const setInstallation = (
  installation: ConnectInstallationData | null
): SetConnectInstallation => ({
  type: TYPES.SET_INSTALLATION,
  payload: {
    installation,
  },
});
