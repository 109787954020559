import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getFeatures } from '../store/appData/features';
import { State, FeaturesState } from '../@types';

export type Props = {
  features: FeaturesState;
  getFeatures: typeof getFeatures;
};

const withFeatures: any = compose(
  connect(
    ({ appData: { features } }: State) => ({
      features,
    }),
    {
      getFeatures,
    }
  )
);
export default withFeatures;
