import { $PropertyType } from 'utility-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  searchCars,
  setBackBy,
  setFrom,
  switchAccessToCircle,
  clearFilter,
  findNearestAvailableCar,
} from '../store/carsSearch';
import { State, CarsSearchState } from '../@types';

export type Props = CarsSearchState & {
  availableCars: $PropertyType<CarsSearchState, 'availableCars'>;
  unavailableCars: $PropertyType<CarsSearchState, 'unavailableCars'>;
  searchCars: typeof searchCars;
  setBackBy: typeof setBackBy;
  setFrom: typeof setFrom;
  switchAccessToCircle: typeof switchAccessToCircle;
  clearFilter: typeof clearFilter;
  findNearestAvailableCar: typeof findNearestAvailableCar;
};

const withCarsSearch: any = compose(
  connect(
    ({ carsSearch }: State) => ({
      ...carsSearch,
      searchCarsData: carsSearch.searchCars,
    }),
    {
      searchCars,
      setBackBy,
      setFrom,
      switchAccessToCircle,
      clearFilter,
      findNearestAvailableCar,
    }
  )
);
export default withCarsSearch;
