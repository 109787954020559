import { applyMiddleware, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './RootReducer';

export let injectedStore: any;
export let injectedPersistor: any;

export const configureStore = () => {
  const middlewares = [thunk];
  const store = createStore(
    rootReducer,
    undefined,
    composeWithDevTools(applyMiddleware(...middlewares))
  );
  const persistor = persistStore(store);
  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept(() => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./RootReducer');

      store.replaceReducer(nextRootReducer);
    });
  }

  return {
    store,
    persistor,
  };
};
export const injectStore = (storeToInject: any, persistorToInject: any) => {
  injectedStore = storeToInject;
  injectedPersistor = persistorToInject;
};
