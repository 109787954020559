import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// @ts-ignore
import { PersistGate } from 'redux-persist/integration/react';
import packageJson from '../package.json';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './redux/config';
import AppRoute from './routes/AppRoute';
import { Data, Setup } from './init/index';
import LoadingContainer from './ui/components/LoadingScreen/LoadingContainer';
import Notification from './ui/components/Notification/Notification';
import Dialog from './ui/components/Dialog/Dialog';
import StatusIndicator from './ui/components/StatusIndicator/StatusIndicator';
import ErrorBoundary from './ui/components/ErrorBoundary/ErrorBoundary';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.2,
  release: packageJson.version,
  environment:
    process.env.REACT_APP_STAGE === 'production' ? 'production' : 'staging',
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<LoadingContainer />} persistor={persistor}>
        <ErrorBoundary>
          <Setup>
            <Data>
              <>
                <StatusIndicator />
                <Dialog />
                <Notification />
                <AppRoute />
              </>
            </Data>
          </Setup>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
