import { FleetUserDataOutput } from '../../index';
export type AdminState = {
  readonly email: string;
  readonly admin: FleetUserDataOutput | null | undefined;
  readonly basicAuth: string | null | undefined;
};
enum AdminTypes {
  SET_ADMIN = 'connect/userData/SET_ADMIN',
  SET_AUTH = 'connect/userData/SET_AUTH',
  SET_EMAIL = 'connect/userData/SET_EMAIL',
  RESET_STORE = 'RESET_STORE',
}
export default AdminTypes;
export type SetAdmin = {
  type: AdminTypes.SET_ADMIN;
  payload: {
    admin: FleetUserDataOutput | null | undefined;
  };
};
export type SetAdminEmail = {
  type: AdminTypes.SET_EMAIL;
  payload: {
    email: string;
  };
};

export type SetAdminAuth = {
  type: AdminTypes.SET_AUTH;
  payload: {
    basicAuth: string;
  };
};

export type AdminAction = SetAdmin | SetAdminEmail | SetAdminAuth;
