import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getAreas } from '../store/appData/areas';
import { getBookings } from '../store/userData/bookings';
import { getCarEquipments } from '../store/appData/carEquipments';
import { setInitialMapContent } from '../store/ui/map';
import { getCategories } from '../store/carsSearch';
import { getServices } from '../store/service';
import { getRentalData } from '../store/userData/rental';
import { CLIENT_TYPE } from '../@types';
import { UserDataOutput, State } from '../@types';

export type Props = {
  user: UserDataOutput | null | undefined;
  getAreas: typeof getAreas;
  getRentalData: typeof getRentalData;
  getBookings: typeof getBookings;
  loadingRentalData: boolean;
  getCarEquipments: typeof getCarEquipments;
  getServices: typeof getServices;
  setInitialMapContent: typeof setInitialMapContent;
  lastKnownLocation: Location | null | undefined;
  getCategories: typeof getCategories;
};

const withData: any = compose(
  connect(
    ({
      networkStatus: { activities },
      device: { lastKnownLocation },
    }: State) => ({
      loadingRentalData: activities.some((activityType: string) =>
        [CLIENT_TYPE.RENTAL_CLIENT.GET_RENTAL_DATA].includes(activityType)
      ),
      lastKnownLocation,
    }),
    {
      getAreas,
      getRentalData,
      getBookings,
      getCarEquipments,
      getServices,
      setInitialMapContent,
      getCategories,
    }
  )
);
export default withData;
