import initialState from './InitialState';
import TYPES from '../../../@types/redux/store/CarEquipmentsTypes';
import { CarEquipmentsAction, CarEquipmentsState } from '../../../@types';

export default (
  state: CarEquipmentsState = initialState,
  action: CarEquipmentsAction
): CarEquipmentsState => {
  switch (action.type) {
    case TYPES.SET_CAR_EQUIPMENTS: {
      return action.payload.carEquipments;
    }

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
