import { APP } from '../../enums';
import { DeviceState } from '../../@types';
const initialState: DeviceState = {
  status: {
    cameraPermission: 'unavailable',
    locationPermission: 'unavailable',
    bluetoothPermission: 'granted',
    devicePaired: true,
    deviceConnected: true,
  },
  lastKnownLocation: null,
  deviceUUID: '',
  platform: APP.PLATFORM.BROWSER,
  language: APP.LANGUAGE.DE,
  model: '',
  pushToken: '',
  pushDeviceID: '',
  userAgent: '',
  orientation: '',
  totalMemory: null,
  usedMemory: null,
  isKeyboardOpened: false,
};
export default initialState;
