import IntercomManager from '../native/intercomManager';
import { getFirstStepsChallenge } from './rentalHelpers';

export const anyFirstStepsOpen = (user: any): boolean => {
  const { challenges } = user;
  const challenge = getFirstStepsChallenge(challenges);

  if (challenge) {
    return challenge.tasks.some(
      (it: { status: string }) => it.status === 'open'
    );
  }

  return true;
};

export const isFirstStepsCompleted = (user: any): boolean => {
  const { challenges } = user;
  const challenge = getFirstStepsChallenge(challenges);

  if (challenge) {
    return challenge.tasks.every(
      (it: { status: string }) => it.status === 'completed'
    );
  }

  return true;
};

export const isDocumentAddressProof = (addressOnBoardingType: any): boolean =>
  addressOnBoardingType && addressOnBoardingType === 'document';

export const handleIntercomMode = (
  intercomHandlingMode: string | null | undefined,
  user: any | null | undefined,
  basicAuth: string | null | undefined,
  supportContacted: boolean
) => {
  switch (intercomHandlingMode) {
    case 'always':
      IntercomManager.registerIntercomUser(user);
      break;

    case 'onLogin':
      if (user && basicAuth) {
        IntercomManager.registerIntercomUser(user);
      }

      break;

    case 'onContactSupport':
      if (supportContacted) {
        IntercomManager.registerIntercomUser(user);
      }

      break;

    default:
      break;
  }
};
