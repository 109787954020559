import { UserDataOutput } from '../@types';
import { Intercom } from '../config';

const IntercomManager = {
  nativeManager: null as any,

  registerIntercomUser(user: UserDataOutput | null | undefined) {
    if (Intercom) {
      if (user) {
        Intercom.registerIdentifiedUser({
          userId: user.id,
          email: user.email,
        });
        this.nativeManager?.setMarketingAttributes();
      } else {
        Intercom.registerUnidentifiedUser();
      }
    }
  },

  logout() {
    if (Intercom) {
      Intercom.logout();
    }
  },
};

export default IntercomManager;
