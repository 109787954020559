import { AreaData } from '../../index';

export type AreasState = Array<any>;

enum AreasTypes {
  SET_AREAS = 'appData/areas/SET_AREAS',
}

export default AreasTypes;

export type SetAreas = {
  type: AreasTypes.SET_AREAS;
  payload: {
    areas: Array<AreaData>;
  };
};

export type AreasAction = SetAreas;
