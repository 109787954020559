import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  setMapContent,
  setMapRegion,
  setCardState,
  setSelectedCar,
} from '../store/ui/map';
import { MapContent, MapRegion, CardState, State } from '../@types';
import { setIsCarChecked } from '../store/userData/rental';

export type Props = {
  mapContent: MapContent;
  visibleRegion: MapRegion;
  cardState: CardState;
  setMapContent: typeof setMapContent;
  setMapRegion: typeof setMapRegion;
  setCardState: typeof setCardState;
  setIsCarChecked: typeof setIsCarChecked;
  setSelectedCar: typeof setSelectedCar;
};

const withMapContent: any = compose(
  connect(
    ({
      ui: {
        map: { content: mapContent, visibleRegion, cardState },
      },
    }: State) => ({
      mapContent,
      visibleRegion,
      cardState,
    }),
    {
      setMapContent,
      setMapRegion,
      setCardState,
      setIsCarChecked,
      setSelectedCar,
    }
  )
);
export default withMapContent;
