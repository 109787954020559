import { UserState } from '../../../@types';

const initialState: UserState = {
  email: '',
  user: null,
  basicAuth: null,
  transactions: null,
  activePaymentData: null,
  paymentPublicKey: null,
  recoverPaymentAuth: false,
  rentalHistory: {
    rentals: [],
  },
  signUpCircle: null,
  jumioRedirectData: null,
};
export default initialState;
