import { makeAPICall } from './helpers';
import { APIResponse, BrandData } from '../@types';

const brandClient = {
  getSettings: async (): Promise<APIResponse<BrandData>> => {
    const { data, error } = await makeAPICall({
      method: 'GET',
      url: '/brand',
      checkModified: false,
    });
    return {
      data,
      error,
    };
  },
};
export default brandClient;
