import distance from '@turf/distance';
import { pointFromLocation } from './pointFromLocation';
import { Location } from '../@types';

export const distanceBetweenLocationsInKm = (
  location1: Location,
  location2: Location
): number => {
  if (location1 && location2) {
    const point1 = pointFromLocation(location1);
    const point2 = pointFromLocation(location2);
    const units = 'kilometers';
    // @ts-ignore
    return distance(point1, point2, units);
  }

  return 0;
};
