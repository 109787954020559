import initialState from './InitialState';
import TYPES from '../../../@types/redux/store/ServiceTypeTypes';
import { ServiceTypesAction, ServiceTypesState } from '../../../@types';

export default (
  state: ServiceTypesState = initialState,
  action: ServiceTypesAction
): ServiceTypesState => {
  switch (action.type) {
    case TYPES.SET_SERVICE_TYPES: {
      return action.payload.serviceTypes;
    }

    case TYPES.CLEAR_SERVICE_TYPES: {
      return initialState;
    }

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
