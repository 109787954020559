import { makeAPICall } from './helpers';
import {
  APIResponse,
  UserDataOutput,
  SignUpInputData,
  UpdateUserInputData,
} from '../@types';

const authClient = {
  login: async (): Promise<APIResponse<UserDataOutput>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: '/user',
    });
    return {
      notModified,
      data,
      error,
    };
  },
  signUp: async (
    signUpDataInput: SignUpInputData
  ): Promise<APIResponse<UserDataOutput>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'POST',
      url: '/user',
      data: signUpDataInput,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  forgetPassword: async (email: string): Promise<APIResponse<null>> => {
    const { error } = await makeAPICall({
      method: 'POST',
      url: '/forgot',
      data: {
        email,
      },
    });
    return {
      error,
    };
  },
  checkReferral: async (
    updateUserInputData: UpdateUserInputData
  ): Promise<APIResponse<null>> => {
    const { error } = await makeAPICall({
      method: 'POST',
      url: '/user',
      data: updateUserInputData,
    });
    return {
      error,
    };
  },
  checkCircle: async (
    updateUserInputData: UpdateUserInputData
  ): Promise<APIResponse<null>> => {
    const { error } = await makeAPICall({
      method: 'POST',
      url: '/user',
      data: updateUserInputData,
    });
    return {
      error,
    };
  },
};
export default authClient;
