import { makeAPICall } from './helpers';
import {
  APIResponse,
  ChallengeSolutionInput,
  DeviceFingerprintInput,
  UserDataOutput,
} from '../@types';

const paymentClient = {
  submitIdentityShopper: async (
    deviceFingerprintInput: DeviceFingerprintInput
  ): Promise<APIResponse<UserDataOutput>> => {
    const { data, error } = await makeAPICall({
      method: 'POST',
      url: '/payment/adyen/3ds/deviceFingerprint',
      checkModified: false,
      data: deviceFingerprintInput,
    });
    return {
      data,
      error,
    };
  },
  submitChallenge: async (
    challengeSolutionInput: ChallengeSolutionInput
  ): Promise<APIResponse<UserDataOutput>> => {
    const { data, error } = await makeAPICall({
      method: 'POST',
      url: '/payment/adyen/3ds/challengeSolution',
      checkModified: false,
      data: challengeSolutionInput,
    });
    return {
      data,
      error,
    };
  },
  isPaymentVerified: async (): Promise<APIResponse<UserDataOutput>> => {
    const { data, error } = await makeAPICall({
      method: 'PUT',
      url: '/payment/adyen/3ds/scaCheck',
      checkModified: false,
    });
    return {
      data,
      error,
    };
  },
};
export default paymentClient;
