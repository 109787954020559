import { connect } from 'react-redux';
import { compose } from 'recompose';
import { CLIENT_TYPE, State } from '../@types';
import { getCarPriceDetails } from '../store/pricing';

export type Props = {
  pricing: any;
  getCarPriceDetails: typeof getCarPriceDetails;
};

const withCarProfile: any = compose(
  connect(
    ({ pricing, networkStatus: { activities } }: State) => ({
      pricing,
      reservingCar: activities.some((activityType: string) =>
        [CLIENT_TYPE.RENTAL_CLIENT.RESERVE_CAR].includes(activityType)
      ),
      bookingCar: activities.some((activityType: string) =>
        [CLIENT_TYPE.BOOKING_CLIENT.BOOK_CAR].includes(activityType)
      ),
    }),
    {
      getCarPriceDetails,
    }
  )
);
export default withCarProfile;
