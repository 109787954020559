import {
  Dispatch,
  CLIENT_TYPE,
  ConnectCarData,
  ConnectDeviceData,
  ConnectFuelCardData,
  InstallationInputData,
} from '../../../@types';
import { APP } from '../../../enums';
import { setCars, setDevices, setFuelCards, setInstallation } from './actions';
import {
  setNetworkActivity,
  setNetworkError,
  setNetworkSuccess,
} from '../../networkStatus';
import { setNotification } from '../../ui/notifications';
import connectClient from '../../../clients/connectClient';
import {
  setSelectedConnectCar,
  setSelectedConnectDeviceStatus,
  setSelectedConnectDevice,
} from '../ui';

export const findCars =
  (successCallback: (data: Array<ConnectCarData>) => void = () => {}) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.CONNECT_CLIENT.FIND_CARS));
    const { data, error } = await connectClient.findCars();

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.CONNECT_CLIENT.FIND_CARS, error));
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      if (data) {
        dispatch(setCars(data));
        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }
      dispatch(setNetworkSuccess(CLIENT_TYPE.CONNECT_CLIENT.FIND_CARS));
    }
  };

export const findDevices =
  (successCallback: (data: Array<ConnectDeviceData>) => void = () => {}) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.CONNECT_CLIENT.FIND_DEVICES));
    const { data, error } = await connectClient.findDevices();

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.CONNECT_CLIENT.FIND_DEVICES, error));
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      if (data) {
        dispatch(setDevices(data));
        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }
      dispatch(setNetworkSuccess(CLIENT_TYPE.CONNECT_CLIENT.FIND_DEVICES));
    }
  };

export const findFuelCards =
  (successCallback: (data: Array<ConnectFuelCardData>) => void = () => {}) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.CONNECT_CLIENT.FIND_FUEL_CARDS));
    const { data, error } = await connectClient.findFuelCards();

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.CONNECT_CLIENT.FIND_FUEL_CARDS, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      if (data) {
        dispatch(setFuelCards(data));
        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }
      dispatch(setNetworkSuccess(CLIENT_TYPE.CONNECT_CLIENT.FIND_FUEL_CARDS));
    }
  };

export const startCarInstallation =
  (carId: string, successCallback: (data: any) => void = () => {}) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.CONNECT_CLIENT.START_INSTALLATION));
    const { data, error } = await connectClient.startCarInstallation(carId);

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.CONNECT_CLIENT.START_INSTALLATION, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      if (data) {
        dispatch(setInstallation(data));
        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }
      dispatch(
        setNetworkSuccess(CLIENT_TYPE.CONNECT_CLIENT.START_INSTALLATION)
      );
    }
  };

export const cancelCarInstallation =
  (carId: string, successCallback: () => void = () => {}) =>
  async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.CONNECT_CLIENT.CANCEL_INSTALLATION)
    );
    const { error } = await connectClient.cancelCarInstallation(carId);

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.CONNECT_CLIENT.CANCEL_INSTALLATION, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      dispatch(setInstallation(null));
      dispatch(setSelectedConnectDeviceStatus(null));
      dispatch(setSelectedConnectDevice(null));
      dispatch(setSelectedConnectDevice(null));
      if (typeof successCallback === 'function') {
        successCallback();
      }
      dispatch(
        setNetworkSuccess(CLIENT_TYPE.CONNECT_CLIENT.CANCEL_INSTALLATION)
      );
    }
  };

export const uninstallCar =
  (carId: string, successCallback: () => void = () => {}) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.CONNECT_CLIENT.UNINSTALL_CAR));
    const { error } = await connectClient.uninstallCar(carId);

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.CONNECT_CLIENT.UNINSTALL_CAR, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      if (typeof successCallback === 'function') {
        successCallback();
      }
      dispatch(setNetworkSuccess(CLIENT_TYPE.CONNECT_CLIENT.UNINSTALL_CAR));
    }
  };

export const updateCarInstallation =
  (
    carId: string,
    inputData: InstallationInputData,
    successCallback: (data: any) => void = () => {}
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.CONNECT_CLIENT.UPDATE_INSTALLATION)
    );
    const { data, error } = await connectClient.updateCarInstallation(
      carId,
      inputData
    );

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.CONNECT_CLIENT.UPDATE_INSTALLATION, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      if (data) {
        dispatch(setInstallation(data));
        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }
      dispatch(
        setNetworkSuccess(CLIENT_TYPE.CONNECT_CLIENT.UPDATE_INSTALLATION)
      );
    }
  };

export const finishCarInstallation =
  (carId: string, successCallback: (data: any) => void = () => {}) =>
  async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.CONNECT_CLIENT.FINISH_INSTALLATION)
    );
    const { data, error } = await connectClient.finishCarInstallation(carId);

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.CONNECT_CLIENT.FINISH_INSTALLATION, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      if (data) {
        dispatch(setInstallation(data));
        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }
      dispatch(
        setNetworkSuccess(CLIENT_TYPE.CONNECT_CLIENT.FINISH_INSTALLATION)
      );
    }
  };

export const uploadInstallationDocument =
  (
    carId: string,
    document: string,
    successCallback: (data: ConnectCarData) => void = () => {}
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.CONNECT_CLIENT.UPLOAD_DOCUMENT));
    const { data, error } = await connectClient.uploadDocument(carId, document);

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.CONNECT_CLIENT.UPLOAD_DOCUMENT, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      if (data) {
        dispatch(setSelectedConnectCar(data));
        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }
      dispatch(setNetworkSuccess(CLIENT_TYPE.CONNECT_CLIENT.UPLOAD_DOCUMENT));
    }
  };
