export default [{
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Lagerhaus Rohrbach',
  AddressLine1: 'Hanriederstr. 43',
  AddressLine2: 'OOE',
  PostCode: 4153,
  TownCity: 'Peilstein',
  Latitude: '486.205.000',
  Longitude: '138.857.000',
  Telephone: '+43728772217',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.6205,13.8857',
  id: '48.6205,13.8857'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Gewerbestrasse Nb',
  AddressLine2: 'KTN',
  PostCode: 9112,
  TownCity: 'Griffen',
  Latitude: '466.959.660',
  Longitude: '147.372.310',
  Telephone: '+43423325311',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '46.695966,14.737231',
  id: '46.695966,14.737231'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Millstaetterstrasse 85',
  AddressLine2: 'KTN',
  PostCode: 9542,
  TownCity: 'Afritz am See',
  Latitude: '467.276.610',
  Longitude: '137.854.550',
  Telephone: '+4342472747',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '46.727661,13.785455',
  id: '46.727661,13.785455'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Aussenringautobahn A21',
  AddressLine2: 'NOE',
  PostCode: 2534,
  TownCity: 'Alland',
  Latitude: '480.701.150',
  Longitude: '160.666.200',
  Telephone: '+43225876180',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.070115,16.06662',
  id: '48.070115,16.06662'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Obere Marktstrasse 45',
  AddressLine2: 'SBG',
  PostCode: 5541,
  TownCity: 'Altenmarkt im Pongau',
  Latitude: '473.810.000',
  Longitude: '134.175.000',
  Telephone: '+4364525660',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'No',
  location: '47.381,13.4175',
  id: '47.381,13.4175'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Ybbsstr.39',
  AddressLine2: 'NOE',
  PostCode: 3300,
  TownCity: 'Amstetten',
  Latitude: '481.166.141',
  Longitude: '148.716.214',
  Telephone: '+43747268222',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '48.1166141,14.8716214',
  id: '48.1166141,14.8716214'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Wirtschaftsweg 4',
  AddressLine2: 'T',
  PostCode: 6321,
  TownCity: 'Angath',
  Latitude: '475.163.800',
  Longitude: '120.669.405',
  Telephone: '+43533274371',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.51638,12.0669405',
  id: '47.51638,12.0669405'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Westautobahn A1',
  AddressLine2: 'OOE',
  PostCode: 4052,
  TownCity: 'Ansfelden',
  Latitude: '482.149.880',
  Longitude: '142.835.620',
  Telephone: '+43722987115',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.214988,14.283562',
  id: '48.214988,14.283562'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Traunuferstr.4/Freindf',
  AddressLine2: 'OOE',
  PostCode: 4052,
  TownCity: 'Ansfelden',
  Latitude: '482.308.289',
  Longitude: '143.050.653',
  Telephone: '+43732302763',
  WeekDayFrom: '05:30',
  WeekDayTo: '19:00',
  SaturdayFrom: '05:30',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '14:00',
  IsOpen24Hours: 'No',
  location: '48.2308289,14.3050653',
  id: '48.2308289,14.3050653'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'A-2 Suedautobahn',
  AddressLine2: 'STMK',
  PostCode: 8200,
  TownCity: 'Arnwiesen-Gleisdorf',
  Latitude: '471.055.950',
  Longitude: '157.689.470',
  Telephone: '+4331126744',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.105595,15.768947',
  id: '47.105595,15.768947'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wechselstrasse 15',
  AddressLine2: 'NOE',
  PostCode: 2870,
  TownCity: 'Aspang',
  Latitude: '475.673.000',
  Longitude: '160.978.000',
  Telephone: '+43264252577',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '18:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.5673,16.0978',
  id: '47.5673,16.0978'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Tullnerstrasse 2',
  AddressLine2: 'NOE',
  PostCode: 3041,
  TownCity: 'Asperhofen',
  Latitude: '482.422.000',
  Longitude: '159.257.000',
  Telephone: '+43277258266',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.2422,15.9257',
  id: '48.2422,15.9257'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Poetschenstrasse 145',
  AddressLine2: 'STMK',
  PostCode: 8990,
  TownCity: 'Bad Aussee',
  Latitude: '476.128.000',
  Longitude: '137.664.000',
  Telephone: '+43362252805',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.6128,13.7664',
  id: '47.6128,13.7664'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Voeslauerstrasse 77',
  AddressLine2: 'NOE',
  PostCode: 2500,
  TownCity: 'Baden bei Wien',
  Latitude: '479.968.127',
  Longitude: '162.279.136',
  Telephone: '+43225287181',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Yes',
  location: '47.9968127,16.2279136',
  id: '47.9968127,16.2279136'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Einzingerstrasse 17',
  AddressLine2: 'OOE',
  PostCode: 4820,
  TownCity: 'Bad Ischl',
  Latitude: '477.212.000',
  Longitude: '136.400.000',
  Telephone: '+43613224584',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.7212,13.64',
  id: '47.7212,13.64'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Lagerhaus Rohrbach',
  AddressLine1: 'Dreisesselbergstr. 18',
  AddressLine2: 'OOE',
  PostCode: 4161,
  TownCity: 'Ulrichsberg',
  Latitude: '486.793.000',
  Longitude: '139.083.000',
  Telephone: '+4372882228',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.6793,13.9083',
  id: '48.6793,13.9083'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Salzburgerstr.70',
  AddressLine2: 'SBG',
  PostCode: 5500,
  TownCity: 'Bischofshofen',
  Latitude: '474.229.426',
  Longitude: '132.163.699',
  Telephone: '+4364622501',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4229426,13.2163699',
  id: '47.4229426,13.2163699'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hainfelderstr.',
  AddressLine2: 'NOE',
  PostCode: 3071,
  TownCity: 'Boeheimkirchen',
  Latitude: '481.931.290',
  Longitude: '157.652.410',
  Telephone: '+4327433265',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:30',
  SaturdayFrom: '06:30',
  SaturdayTo: '20:30',
  SundayFrom: '07:00',
  SundayTo: '20:30',
  IsOpen24Hours: 'No',
  location: '48.193129,15.765241',
  id: '48.193129,15.765241'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Arlbergstrasse 63',
  AddressLine2: 'VBG',
  PostCode: 6900,
  TownCity: 'Bregenz',
  Latitude: '474.966.000',
  Longitude: '97.346.800',
  Telephone: '+43557444855',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.4966,9.73468',
  id: '47.4966,9.73468'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Bregenzerstr. 63',
  AddressLine2: 'VBG',
  PostCode: 6900,
  TownCity: 'Bregenz/Lochau',
  Latitude: '475.109.000',
  Longitude: '97.529.600',
  Telephone: '+43557443445',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Yes',
  location: '47.5109,9.75296',
  id: '47.5109,9.75296'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Klagenfurterstrasse 10',
  AddressLine2: 'KTN',
  PostCode: 9371,
  TownCity: 'Brueckl',
  Latitude: '467.465.819',
  Longitude: '145.315.351',
  Telephone: '+4342142508',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.7465819,14.5315351',
  id: '46.7465819,14.5315351'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Lustenauerstr',
  AddressLine2: 'VBG',
  PostCode: 6850,
  TownCity: 'Dornbirn',
  Latitude: '474.103.190',
  Longitude: '97.204.910',
  Telephone: '800080645',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.410319,9.720491',
  id: '47.410319,9.720491'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Bruennerstr./Staatsgr.',
  AddressLine2: 'NOE',
  PostCode: 2165,
  TownCity: 'Drasenhofen',
  Latitude: '487.774.040',
  Longitude: '166.407.570',
  Telephone: '+43255485523',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.777404,16.640757',
  id: '48.777404,16.640757'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Tauernautobahn A-10',
  AddressLine2: 'KTN',
  PostCode: 9710,
  TownCity: 'Feistritz an der Drau',
  Latitude: '466.836.060',
  Longitude: '136.718.740',
  Telephone: '+4342454402',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '46.683606,13.671874',
  id: '46.683606,13.671874'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Klagenfurterstrasse 23',
  AddressLine2: 'KTN',
  PostCode: 9170,
  TownCity: 'Ferlach',
  Latitude: '465.280.000',
  Longitude: '142.916.000',
  Telephone: '+43422730849',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '46.528,14.2916',
  id: '46.528,14.2916'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wr.Neustaedt.Str.120',
  AddressLine2: 'NOE',
  PostCode: 2821,
  TownCity: 'Frohsdorf',
  Latitude: '477.550.212',
  Longitude: '162.528.991',
  Telephone: '+43262745290',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Yes',
  location: '47.7550212,16.2528991',
  id: '47.7550212,16.2528991'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Wolfgangseestr. 21',
  AddressLine2: 'SBG',
  PostCode: 5330,
  TownCity: 'Fuschl am See',
  Latitude: '477.958.597',
  Longitude: '133.046.364',
  Telephone: '+4362268269',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.7958597,13.3046364',
  id: '47.7958597,13.3046364'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wienerstr. 35',
  AddressLine2: 'NOE',
  PostCode: 2191,
  TownCity: 'Gaweinstal',
  Latitude: '484.728.610',
  Longitude: '165.887.350',
  Telephone: '+4325742183',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.472861,16.588735',
  id: '48.472861,16.588735'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Pramerdorf 13',
  AddressLine2: 'OOE',
  PostCode: 4922,
  TownCity: 'Geiersberg',
  Latitude: '481.988.000',
  Longitude: '136.049.000',
  Telephone: '+4377322288',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.1988,13.6049',
  id: '48.1988,13.6049'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Leopoldauerstr. 1',
  AddressLine2: 'NOE',
  PostCode: 2201,
  TownCity: 'Gerasdorf',
  Latitude: '482.932.841',
  Longitude: '164.670.928',
  Telephone: '+4322462334',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'No',
  location: '48.2932841,16.4670928',
  id: '48.2932841,16.4670928'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Dr.Franz Thomasstrasse 22a',
  AddressLine2: 'OOE',
  PostCode: 4810,
  TownCity: 'Gmunden',
  Latitude: '479.092.000',
  Longitude: '137.842.000',
  Telephone: '+43761262130',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.9092,13.7842',
  id: '47.9092,13.7842'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Innsbrucker Str.5',
  AddressLine2: 'T',
  PostCode: 6353,
  TownCity: 'Going',
  Latitude: '475.158.713',
  Longitude: '123.186.388',
  Telephone: '+4353582246',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.5158713,12.3186388',
  id: '47.5158713,12.3186388'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Harterstrasse 8',
  AddressLine2: 'STMK',
  PostCode: 8101,
  TownCity: 'Gratkorn',
  Latitude: '471.221.000',
  Longitude: '153.604.000',
  Telephone: '+433124290291',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '47.1221,15.3604',
  id: '47.1221,15.3604'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Froehlichgasse 45',
  AddressLine2: 'STMK',
  PostCode: 8010,
  TownCity: 'Graz',
  Latitude: '470.559.000',
  Longitude: '154.483.000',
  Telephone: '+43316472595',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.0559,15.4483',
  id: '47.0559,15.4483'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Kaerntnerstrasse 291',
  AddressLine2: 'STMK',
  PostCode: 8054,
  TownCity: 'Graz',
  Latitude: '470.338.000',
  Longitude: '154.088.000',
  Telephone: '+43316281049',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '47.0338,15.4088',
  id: '47.0338,15.4088'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Balldorf 39',
  AddressLine2: 'NOE',
  PostCode: 3304,
  TownCity: 'St. Georgen',
  Latitude: '481.240.000',
  Longitude: '149.732.000',
  Telephone: '+4374736858',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.124,14.9732',
  id: '48.124,14.9732'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Ragnitzstrasse 127',
  AddressLine2: 'STMK',
  PostCode: 8047,
  TownCity: 'Graz',
  Latitude: '470.777.350',
  Longitude: '154.902.050',
  Telephone: '+43316301922',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.077735,15.490205',
  id: '47.077735,15.490205'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Ostufer',
  AddressLine2: 'T',
  PostCode: 6156,
  TownCity: 'Gries am Brenner',
  Latitude: '470.392.729',
  Longitude: '114.749.310',
  Telephone: '+43527487310',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.0392729,11.474931',
  id: '47.0392729,11.474931'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Westufer',
  AddressLine2: 'T',
  PostCode: 6156,
  TownCity: 'Gries am Brenner',
  Latitude: '470.378.548',
  Longitude: '114.732.324',
  Telephone: '+43527487288',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.0378548,11.4732324',
  id: '47.0378548,11.4732324'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Industriestrasse 29',
  AddressLine2: 'OOE',
  PostCode: 4710,
  TownCity: 'Grieskirchen',
  Latitude: '482.270.000',
  Longitude: '138.435.000',
  Telephone: '+43724862227',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.227,13.8435',
  id: '48.227,13.8435'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Autobahn A2',
  AddressLine2: 'NOE',
  PostCode: 2353,
  TownCity: 'Guntramsdorf West',
  Latitude: '480.341.287',
  Longitude: '163.379.655',
  Telephone: '+43223652003',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.0341287,16.3379655',
  id: '48.0341287,16.3379655'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Westautobahn A1',
  AddressLine2: 'NOE',
  PostCode: 3350,
  TownCity: 'Haag',
  Latitude: '481.395.876',
  Longitude: '145.789.647',
  Telephone: '+43743442153',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1395876,14.5789647',
  id: '48.1395876,14.5789647'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Pfannhausstr. 14',
  AddressLine2: 'T',
  PostCode: 6060,
  TownCity: 'Hall In Tirol',
  Latitude: '472.793.000',
  Longitude: '115.014.000',
  Telephone: '+43522345005',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.2793,11.5014',
  id: '47.2793,11.5014'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Klein Pertholz 58',
  AddressLine2: 'NOE',
  PostCode: 3860,
  TownCity: 'Heidenreichstein',
  Latitude: '488.502.630',
  Longitude: '150.965.320',
  Telephone: '+43286252127',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.850263,15.096532',
  id: '48.850263,15.096532'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Liebensdorf 36',
  AddressLine2: 'STMK',
  PostCode: 8081,
  TownCity: 'Heiligenkreuz am Waasen',
  Latitude: '469.822.000',
  Longitude: '155.648.000',
  Telephone: '+4331342313',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '12:00',
  IsOpen24Hours: 'No',
  location: '46.9822,15.5648',
  id: '46.9822,15.5648'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Schulstrasse 8',
  AddressLine2: 'KTN',
  PostCode: 9562,
  TownCity: 'Himmelberg',
  Latitude: '467.514.455',
  Longitude: '140.353.928',
  Telephone: '+4342762254',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '06:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '46.7514455,14.0353928',
  id: '46.7514455,14.0353928'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'A12',
  AddressLine2: 'T',
  PostCode: 6070,
  TownCity: 'Ampass',
  Latitude: '472.632.780',
  Longitude: '114.534.580',
  Telephone: '+43512346411',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.263278,11.453458',
  id: '47.263278,11.453458'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hallerstrasse 100',
  AddressLine2: 'T',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '472.775.880',
  Longitude: '114.242.570',
  Telephone: '+43512209094',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:59',
  SundayFrom: '06:00',
  SundayTo: '21:59',
  IsOpen24Hours: 'No',
  location: '47.277588,11.424257',
  id: '47.277588,11.424257'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hoettinger Au 45',
  AddressLine2: 'T',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '472.653.295',
  Longitude: '113.791.518',
  Telephone: '800080645',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.2653295,11.3791518',
  id: '47.2653295,11.3791518'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Kranebitter Allee 30',
  AddressLine2: 'T',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '472.645.000',
  Longitude: '113.658.000',
  Telephone: '512274514',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.2645,11.3658',
  id: '47.2645,11.3658'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Burggasse 42',
  AddressLine2: 'STMK',
  PostCode: 8750,
  TownCity: 'Judenburg',
  Latitude: '471.688.000',
  Longitude: '146.544.000',
  Telephone: '+43357283307',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '10:00',
  SaturdayTo: '19:00',
  SundayFrom: '10:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Yes',
  location: '47.1688,14.6544',
  id: '47.1688,14.6544'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Autobahn A1, Rasthausstrasse 10',
  AddressLine2: 'NOE',
  PostCode: 3373,
  TownCity: 'Kemmelbach',
  Latitude: '481.551.024',
  Longitude: '151.001.745',
  Telephone: '+43741252748',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1551024,15.1001745',
  id: '48.1551024,15.1001745'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'St Johannerstrasse',
  AddressLine2: 'T',
  PostCode: 6370,
  TownCity: 'Kitzbuehel',
  Latitude: '474.650.240',
  Longitude: '123.864.740',
  Telephone: '+43535664377',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.465024,12.386474',
  id: '47.465024,12.386474'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Suedring 315',
  AddressLine2: 'KTN',
  PostCode: 9020,
  TownCity: 'Klagenfurt',
  Latitude: '466.089.910',
  Longitude: '143.304.850',
  Telephone: '+43463319538',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '46.608991,14.330485',
  id: '46.608991,14.330485'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Nr.147',
  AddressLine2: 'OOE',
  PostCode: 4564,
  TownCity: 'Klaus',
  Latitude: '478.301.808',
  Longitude: '141.569.033',
  Telephone: '+437585249',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.8301808,14.1569033',
  id: '47.8301808,14.1569033'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Kierlinger Strasse 31d',
  AddressLine2: 'NOE',
  PostCode: 3400,
  TownCity: 'Klosterneuburg',
  Latitude: '483.067.000',
  Longitude: '163.138.000',
  Telephone: '+43224332232',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.3067,16.3138',
  id: '48.3067,16.3138'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Schubertstrasse 25',
  AddressLine2: 'T',
  PostCode: 6330,
  TownCity: 'Kufstein',
  Latitude: '475.853.000',
  Longitude: '121.617.000',
  Telephone: '+43537262977',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.5853,12.1617',
  id: '47.5853,12.1617'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Packerstrasse 144',
  AddressLine2: 'STMK',
  PostCode: 8501,
  TownCity: 'Lieboch',
  Latitude: '469.765.000',
  Longitude: '153.287.000',
  Telephone: '+43313661187',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '46.9765,15.3287',
  id: '46.9765,15.3287'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Westautobahn A1',
  AddressLine2: 'OOE',
  PostCode: 4663,
  TownCity: 'Lindach',
  Latitude: '480.040.507',
  Longitude: '138.513.135',
  Telephone: '+4376138460',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.0040507,13.8513135',
  id: '48.0040507,13.8513135'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Dauphinestrasse 27',
  AddressLine2: 'OOE',
  PostCode: 4030,
  TownCity: 'Linz-Kleinmuenchen',
  Latitude: '482.534.400',
  Longitude: '143.105.300',
  Telephone: '800080645',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Yes',
  location: '48.25344,14.31053',
  id: '48.25344,14.31053'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Industriezeile 78',
  AddressLine2: 'OOE',
  PostCode: 4020,
  TownCity: 'Linz',
  Latitude: '483.003.000',
  Longitude: '143.206.000',
  Telephone: '+43732600859',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.3003,14.3206',
  id: '48.3003,14.3206'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Stockhofstrasse 42',
  AddressLine2: 'OOE',
  PostCode: 4020,
  TownCity: 'Linz',
  Latitude: '482.946.000',
  Longitude: '142.863.000',
  Telephone: '+43732664838',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.2946,14.2863',
  id: '48.2946,14.2863'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Muenzgrabenstrasse 121',
  AddressLine2: 'STMK',
  PostCode: 8010,
  TownCity: 'Graz',
  Latitude: '470.570.368',
  Longitude: '154.532.127',
  Telephone: '+43316465444',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '47.0570368,15.4532127',
  id: '47.0570368,15.4532127'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Wienerstrasse 435-437',
  AddressLine2: 'OOE',
  PostCode: 4030,
  TownCity: 'Linz-Textil',
  Latitude: '482.512.020',
  Longitude: '143.228.580',
  Telephone: '+43732307666',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.251202,14.322858',
  id: '48.251202,14.322858'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Waldeggstrasse 44',
  AddressLine2: 'OOE',
  PostCode: 4020,
  TownCity: 'Linz',
  Latitude: '482.884.000',
  Longitude: '142.874.000',
  Telephone: '+43732657321',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.2884,14.2874',
  id: '48.2884,14.2874'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wildbergstrasse 16',
  AddressLine2: 'OOE',
  PostCode: 4040,
  TownCity: 'Linz-Urfahr',
  Latitude: '483.149.339',
  Longitude: '142.852.477',
  Telephone: '+43732732586',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.3149339,14.2852477',
  id: '48.3149339,14.2852477'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Welserstr. 54',
  AddressLine2: 'OOE',
  PostCode: 4614,
  TownCity: 'Marchtrenk',
  Latitude: '481.901.000',
  Longitude: '140.998.000',
  Telephone: '+43724351114',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1901,14.0998',
  id: '48.1901,14.0998'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Poschacherstrasse',
  AddressLine2: 'OOE',
  PostCode: 4310,
  TownCity: 'Mauthausen',
  Latitude: '482.399.213',
  Longitude: '145.271.081',
  Telephone: '+4372385364',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '05:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'No',
  location: '48.2399213,14.5271081',
  id: '48.2399213,14.5271081'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Raecking 3',
  AddressLine2: 'NOE',
  PostCode: 3390,
  TownCity: 'Melk',
  Latitude: '482.300.000',
  Longitude: '153.310.000',
  Telephone: '+43275252377',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.23,15.331',
  id: '48.23,15.331'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Stock 11',
  AddressLine2: 'NOE',
  PostCode: 3251,
  TownCity: 'Merkenstetten',
  Latitude: '480.391.270',
  Longitude: '151.437.200',
  Telephone: '+4374892481',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.039127,15.14372',
  id: '48.039127,15.14372'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Lagerhaus Rohrbach',
  AddressLine1: 'Stauffstrasse 6',
  AddressLine2: 'OOE',
  PostCode: 4712,
  TownCity: 'Michaelnbach',
  Latitude: '482.891.000',
  Longitude: '138.334.000',
  Telephone: '+4372772520',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.2891,13.8334',
  id: '48.2891,13.8334'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Bundesstrasse 279',
  AddressLine2: 'T',
  PostCode: 6543,
  TownCity: 'Nauders/Reschenpass',
  Latitude: '468.512.474',
  Longitude: '105.042.976',
  Telephone: '+43547387268',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '46.8512474,10.5042976',
  id: '46.8512474,10.5042976'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Haslau 10',
  AddressLine2: 'OOE',
  PostCode: 4715,
  TownCity: 'Taufkirchen a.d.Tr.',
  Latitude: '482.708.285',
  Longitude: '137.420.110',
  Telephone: '+43773320600',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.2708285,13.742011',
  id: '48.2708285,13.742011'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Wiener Strasse 19',
  AddressLine2: 'STMK',
  PostCode: 8820,
  TownCity: 'Neumarkt',
  Latitude: '470.792.000',
  Longitude: '144.246.000',
  Telephone: '+4335842477',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.0792,14.4246',
  id: '47.0792,14.4246'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wienerstrasse 69',
  AddressLine2: 'BGLD',
  PostCode: 7100,
  TownCity: 'Neusiedl/See',
  Latitude: '479.580.858',
  Longitude: '168.352.404',
  Telephone: '2167314412',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.9580858,16.8352404',
  id: '47.9580858,16.8352404'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Bahnhofstrasse 27',
  AddressLine2: 'OOE',
  PostCode: 4722,
  TownCity: 'Peuerbach',
  Latitude: '483.389.797',
  Longitude: '137.715.259',
  Telephone: '+4372762206',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.3389797,13.7715259',
  id: '48.3389797,13.7715259'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Tragweinerstr. 31',
  AddressLine2: 'OOE',
  PostCode: 4230,
  TownCity: 'Pregarten',
  Latitude: '483.534.000',
  Longitude: '145.359.000',
  Telephone: '+4372362230',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '06:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '48.3534,14.5359',
  id: '48.3534,14.5359'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Weitenfeld 5',
  AddressLine2: 'OOE',
  PostCode: 4048,
  TownCity: 'Puchenau',
  Latitude: '483.120.000',
  Longitude: '142.288.000',
  Telephone: '+43732221545',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.312,14.2288',
  id: '48.312,14.2288'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Tauernstrasse 27',
  AddressLine2: 'SBG',
  PostCode: 5550,
  TownCity: 'Radstadt',
  Latitude: '473.791.000',
  Longitude: '134.738.000',
  Telephone: '+43645230921',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '07:00',
  SundayTo: '18:00',
  IsOpen24Hours: 'No',
  location: '47.3791,13.4738',
  id: '47.3791,13.4738'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Nr. 197',
  AddressLine2: 'STMK',
  PostCode: 8972,
  TownCity: 'Ramsau am Dachstein',
  Latitude: '474.417.504',
  Longitude: '136.566.539',
  Telephone: '+43368781405',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'No',
  location: '47.4417504,13.6566539',
  id: '47.4417504,13.6566539'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hauptstrasse 92',
  AddressLine2: 'NOE',
  PostCode: 2651,
  TownCity: 'Reichenau an der Rax',
  Latitude: '476.979.763',
  Longitude: '158.364.560',
  Telephone: '+43266652424',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.6979763,15.836456',
  id: '47.6979763,15.836456'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Lagerhaus Rohrbach',
  AddressLine1: 'Scheiblberg 44',
  AddressLine2: 'OOE',
  PostCode: 4150,
  TownCity: 'Berg bei Rohrbach',
  Latitude: '485.596.000',
  Longitude: '139.871.000',
  Telephone: '',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '12:00',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.5596,13.9871',
  id: '48.5596,13.9871'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Aignerstrasse 22',
  AddressLine2: 'SBG',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '477.972.000',
  Longitude: '130.674.000',
  Telephone: '+43662635841',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:59',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.7972,13.0674',
  id: '47.7972,13.0674'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Innsbr. Bundesstr.97',
  AddressLine2: 'SBG',
  PostCode: 5020,
  TownCity: 'Salzburg-Flughafen',
  Latitude: '477.928.000',
  Longitude: '130.009.000',
  Telephone: '+43662856349',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.7928,13.0009',
  id: '47.7928,13.0009'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Hauptstrasse 2',
  AddressLine2: 'OOE',
  PostCode: 4642,
  TownCity: 'Sattledt',
  Latitude: '480.733.000',
  Longitude: '140.521.000',
  Telephone: '+4372448782',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.0733,14.0521',
  id: '48.0733,14.0521'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Linzerstrasse 52',
  AddressLine2: 'OOE',
  PostCode: 4780,
  TownCity: 'Schaerding',
  Latitude: '484.512.000',
  Longitude: '134.360.000',
  Telephone: '+4377122194',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.4512,13.436',
  id: '48.4512,13.436'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Kärtner Str. 2',
  AddressLine2: 'STMK',
  PostCode: 8811,
  TownCity: 'Scheifling',
  Latitude: '471.528.290',
  Longitude: '144.140.570',
  Telephone: '+4335822485',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:59',
  SundayFrom: '08:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '47.152829,14.414057',
  id: '47.152829,14.414057'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Braunauer Str. 1',
  AddressLine2: 'SBG',
  PostCode: 5122,
  TownCity: 'Ach',
  Latitude: '481.570.682',
  Longitude: '128.402.924',
  Telephone: '+4377274018',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.1570682,12.8402924',
  id: '48.1570682,12.8402924'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Steyrerstrasse 29',
  AddressLine2: 'OOE',
  PostCode: 4522,
  TownCity: 'Sierning',
  Latitude: '480.457.000',
  Longitude: '143.130.000',
  Telephone: '+4372592421',
  WeekDayFrom: '07:00',
  WeekDayTo: '18:30',
  SaturdayFrom: '07:30',
  SaturdayTo: '13:00',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.0457,14.313',
  id: '48.0457,14.313'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Arnbach 74',
  AddressLine2: 'T',
  PostCode: 9920,
  TownCity: 'Sillian',
  Latitude: '467.439.465',
  Longitude: '123.935.279',
  Telephone: '+4348426311',
  WeekDayFrom: '08:00',
  WeekDayTo: '19:30',
  SaturdayFrom: '08:00',
  SaturdayTo: '19:30',
  SundayFrom: '08:00',
  SundayTo: '19:30',
  IsOpen24Hours: 'No',
  location: '46.7439465,12.3935279',
  id: '46.7439465,12.3935279'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Mariazellerstr.182',
  AddressLine2: 'NOE',
  PostCode: 3106,
  TownCity: 'Spratzern',
  Latitude: '481.792.415',
  Longitude: '156.155.487',
  Telephone: '+43274274960',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1792415,15.6155487',
  id: '48.1792415,15.6155487'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Salzburgerstrasse 401',
  AddressLine2: 'STMK',
  PostCode: 8950,
  TownCity: 'Stainach',
  Latitude: '475.324.000',
  Longitude: '141.060.000',
  Telephone: '+43368222650',
  WeekDayFrom: '06:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Yes',
  location: '47.5324,14.106',
  id: '47.5324,14.106'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Salzburgerstr.',
  AddressLine2: 'T',
  PostCode: 6380,
  TownCity: 'St. Johann in Tirol',
  Latitude: '475.292.853',
  Longitude: '124.286.349',
  Telephone: '+43535262439',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.5292853,12.4286349',
  id: '47.5292853,12.4286349'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Adsdorf 13',
  AddressLine2: 'OOE',
  PostCode: 4113,
  TownCity: 'St. Martin im Muehlkreis',
  Latitude: '484.338.000',
  Longitude: '140.433.000',
  Telephone: '+43723222350',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '12:00',
  IsOpen24Hours: 'No',
  location: '48.4338,14.0433',
  id: '48.4338,14.0433'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Bundesstrasse 59',
  AddressLine2: 'STMK',
  PostCode: 8770,
  TownCity: 'St. Michael',
  Latitude: '473.495.000',
  Longitude: '150.088.000',
  Telephone: '+4338435116',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.3495,15.0088',
  id: '47.3495,15.0088'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Pragerstr./Hornerstr.',
  AddressLine2: 'NOE',
  PostCode: 2000,
  TownCity: 'Stockerau',
  Latitude: '483.851.000',
  Longitude: '162.083.000',
  Telephone: '+43226662438',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.3851,16.2083',
  id: '48.3851,16.2083'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Wildshut 9',
  AddressLine2: 'SBG',
  PostCode: 5120,
  TownCity: 'St.Pantaleon',
  Latitude: '480.122.000',
  Longitude: '128.616.000',
  Telephone: '+4362776285',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '18:00',
  IsOpen24Hours: 'No',
  location: '48.0122,12.8616',
  id: '48.0122,12.8616'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Eisenstrasse 85',
  AddressLine2: 'OOE',
  PostCode: 4451,
  TownCity: 'St. Ulrich/Garsten',
  Latitude: '480.011.000',
  Longitude: '144.104.000',
  Telephone: '+43725254161',
  WeekDayFrom: '04:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.0011,14.4104',
  id: '48.0011,14.4104'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Werkstrasse 4',
  AddressLine2: 'NOE',
  PostCode: 4300,
  TownCity: 'St. Valentin',
  Latitude: '481.714.000',
  Longitude: '145.122.000',
  Telephone: '+43743552224',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '07:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'No',
  location: '48.1714,14.5122',
  id: '48.1714,14.5122'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Friesacherstrasse 68',
  AddressLine2: 'KTN',
  PostCode: 9300,
  TownCity: 'St. Veit an der Glan',
  Latitude: '467.734.670',
  Longitude: '143.683.680',
  Telephone: '+4342122398',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '46.773467,14.368368',
  id: '46.773467,14.368368'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Rodlbergerstrasse 39',
  AddressLine2: 'OOE',
  PostCode: 4600,
  TownCity: 'Thalheim bei Wels',
  Latitude: '481.538.000',
  Longitude: '140.368.000',
  Telephone: '+43724244079',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.1538,14.0368',
  id: '48.1538,14.0368'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Industriepark 2',
  AddressLine2: 'STMK',
  PostCode: 8784,
  TownCity: 'Trieben',
  Latitude: '474.938.000',
  Longitude: '144.829.000',
  Telephone: '+4336155161',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.4938,14.4829',
  id: '47.4938,14.4829'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hauptstrasse 9 A',
  AddressLine2: 'STMK',
  PostCode: 8793,
  TownCity: 'Trofaiach',
  Latitude: '474.197.000',
  Longitude: '150.148.000',
  Telephone: '+4338472879',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '13:00',
  IsOpen24Hours: 'No',
  location: '47.4197,15.0148',
  id: '47.4197,15.0148'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Loeck 20',
  AddressLine2: 'T',
  PostCode: 6441,
  TownCity: 'Umhausen',
  Latitude: '471.358.680',
  Longitude: '109.273.660',
  Telephone: '+4352555472',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.135868,10.927366',
  id: '47.135868,10.927366'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Ufer 4',
  AddressLine2: 'OOE',
  PostCode: 4360,
  TownCity: 'Grein',
  Latitude: '482.229.000',
  Longitude: '148.503.000',
  Telephone: '+437268472',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.2229,14.8503',
  id: '48.2229,14.8503'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Maria Gailer Strasse',
  AddressLine2: 'KTN',
  PostCode: 9500,
  TownCity: 'Villach',
  Latitude: '466.047.380',
  Longitude: '138.648.643',
  Telephone: '+434242312706',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '46.604738,13.8648643',
  id: '46.604738,13.8648643'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Turracher Bundesstr. 12',
  AddressLine2: 'KTN',
  PostCode: 9565,
  TownCity: 'Ebene-Reichenau',
  Latitude: '468.573.210',
  Longitude: '138.921.000',
  Telephone: '+434275217',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '46.857321,13.8921',
  id: '46.857321,13.8921'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Ossiacherzeile 50 A',
  AddressLine2: 'KTN',
  PostCode: 9500,
  TownCity: 'Villach',
  Latitude: '466.122.000',
  Longitude: '138.571.000',
  Telephone: '+43424224589',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '46.6122,13.8571',
  id: '46.6122,13.8571'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Inntalautobahn',
  AddressLine2: 'T',
  PostCode: 6114,
  TownCity: 'Weer',
  Latitude: '473.190.793',
  Longitude: '116.556.023',
  Telephone: '+43522468388',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.3190793,11.6556023',
  id: '47.3190793,11.6556023'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Eferdingerstrasse 81',
  AddressLine2: 'OOE',
  PostCode: 4600,
  TownCity: 'Wels',
  Latitude: '481.810.000',
  Longitude: '140.269.000',
  Telephone: '+43724250886',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.181,14.0269',
  id: '48.181,14.0269'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Altmannsdorferstr.94',
  AddressLine2: 'W',
  PostCode: 1120,
  TownCity: 'Wien',
  Latitude: '481.621.860',
  Longitude: '163.172.537',
  Telephone: '+4318043718',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.162186,16.3172537',
  id: '48.162186,16.3172537'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Altmannsdorferstr.117',
  AddressLine2: 'W',
  PostCode: 1120,
  TownCity: 'Wien',
  Latitude: '481.609.269',
  Longitude: '163.186.078',
  Telephone: '+4316678737',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1609269,16.3186078',
  id: '48.1609269,16.3186078'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Arsenalstrasse 5b',
  AddressLine2: 'W',
  PostCode: 1030,
  TownCity: 'Wien',
  Latitude: '481.777.000',
  Longitude: '163.929.000',
  Telephone: '+4317982552',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Yes',
  location: '48.1777,16.3929',
  id: '48.1777,16.3929'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Bruennerstrasse 57c',
  AddressLine2: 'W',
  PostCode: 1210,
  TownCity: 'Wien',
  Latitude: '482.671.390',
  Longitude: '164.025.640',
  Telephone: '+4312782239',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.267139,16.402564',
  id: '48.267139,16.402564'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Glemmtaler Landesstr.386',
  AddressLine2: 'SBG',
  PostCode: 5753,
  TownCity: 'Saalbach',
  Latitude: '473.890.774',
  Longitude: '126.423.411',
  Telephone: '+4365416453',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.3890774,12.6423411',
  id: '47.3890774,12.6423411'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Donaufelderstr. 91',
  AddressLine2: 'W',
  PostCode: 1210,
  TownCity: 'Wien',
  Latitude: '482.533.570',
  Longitude: '164.184.040',
  Telephone: '800080645',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.253357,16.418404',
  id: '48.253357,16.418404'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Dresdnerstrasse 8',
  AddressLine2: 'W',
  PostCode: 1200,
  TownCity: 'Wien',
  Latitude: '482.408.000',
  Longitude: '163.746.000',
  Telephone: '+4313304231',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.2408,16.3746',
  id: '48.2408,16.3746'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Einzingergasse 2',
  AddressLine2: 'W',
  PostCode: 1210,
  TownCity: 'Wien',
  Latitude: '482.825.000',
  Longitude: '163.799.000',
  Telephone: '+4312704191',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.2825,16.3799',
  id: '48.2825,16.3799'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Erdbergerlaende 30',
  AddressLine2: 'W',
  PostCode: 1030,
  TownCity: 'Wien',
  Latitude: '482.012.000',
  Longitude: '164.037.000',
  Telephone: '+4317154826',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.2012,16.4037',
  id: '48.2012,16.4037'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Erdbergstr. 184',
  AddressLine2: 'W',
  PostCode: 1030,
  TownCity: 'Wien',
  Latitude: '481.931.740',
  Longitude: '164.103.180',
  Telephone: '17963275',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.193174,16.410318',
  id: '48.193174,16.410318'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Erzherzog Karlstr. 253',
  AddressLine2: 'W',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '482.206.000',
  Longitude: '164.799.000',
  Telephone: '+4312821480',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.2206,16.4799',
  id: '48.2206,16.4799'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Am Fasangarten 9',
  AddressLine2: 'W',
  PostCode: 1120,
  TownCity: 'Wien',
  Latitude: '481.712.181',
  Longitude: '163.047.525',
  Telephone: '+4318135374',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.1712181,16.3047525',
  id: '48.1712181,16.3047525'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Franzosengraben',
  AddressLine2: 'W',
  PostCode: 1030,
  TownCity: 'Wien',
  Latitude: '481.861.822',
  Longitude: '164.125.764',
  Telephone: '+4317988210',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.1861822,16.4125764',
  id: '48.1861822,16.4125764'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Franz Josefskai/Morzinpl',
  AddressLine2: 'W',
  PostCode: 1010,
  TownCity: 'Wien',
  Latitude: '482.128.170',
  Longitude: '163.752.560',
  Telephone: '+4315337398',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.212817,16.375256',
  id: '48.212817,16.375256'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Gablenzgasse 72',
  AddressLine2: 'W',
  PostCode: 1160,
  TownCity: 'Wien',
  Latitude: '482.059.000',
  Longitude: '163.218.000',
  Telephone: '+4314921006',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.2059,16.3218',
  id: '48.2059,16.3218'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Gaudenzdorfer Guertel 77',
  AddressLine2: 'W',
  PostCode: 1120,
  TownCity: 'Wien',
  Latitude: '481.879.000',
  Longitude: '163.389.000',
  Telephone: '+4318155177',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1879,16.3389',
  id: '48.1879,16.3389'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Geiselbergstr.47/Hauffg.35',
  AddressLine2: 'W',
  PostCode: 1110,
  TownCity: 'Wien',
  Latitude: '481.749.070',
  Longitude: '164.079.790',
  Telephone: '+4317495599',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.174907,16.407979',
  id: '48.174907,16.407979'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hernalser Hauptstr.32-34',
  AddressLine2: 'W',
  PostCode: 1170,
  TownCity: 'Wien',
  Latitude: '482.171.142',
  Longitude: '163.382.813',
  Telephone: '+4314064649',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.2171142,16.3382813',
  id: '48.2171142,16.3382813'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Handelskai 90',
  AddressLine2: 'W',
  PostCode: 1200,
  TownCity: 'Wien',
  Latitude: '482.431.000',
  Longitude: '163.841.000',
  Telephone: '800080645',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Yes',
  location: '48.2431,16.3841',
  id: '48.2431,16.3841'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Handelskai 276-280',
  AddressLine2: 'W',
  PostCode: 1020,
  TownCity: 'Wien',
  Latitude: '482.166.590',
  Longitude: '164.169.430',
  Telephone: '+4317293183',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.216659,16.416943',
  id: '48.216659,16.416943'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hadikgasse/Kefergasse',
  AddressLine2: 'W',
  PostCode: 1140,
  TownCity: 'Wien',
  Latitude: '481.927.411',
  Longitude: '162.775.969',
  Telephone: '+4319141238',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1927411,16.2775969',
  id: '48.1927411,16.2775969'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Heiligenstaedterstr.77',
  AddressLine2: 'W',
  PostCode: 1190,
  TownCity: 'Wien',
  Latitude: '482.440.263',
  Longitude: '163.615.186',
  Telephone: '+4313681494',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.2440263,16.3615186',
  id: '48.2440263,16.3615186'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hietzinger Kai 133-135',
  AddressLine2: 'W',
  PostCode: 1130,
  TownCity: 'Wien',
  Latitude: '481.915.000',
  Longitude: '162.798.000',
  Telephone: '+4318771451',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1915,16.2798',
  id: '48.1915,16.2798'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Kaiser Ebersdorferstr.248-250',
  AddressLine2: 'W',
  PostCode: 1110,
  TownCity: 'Wien',
  Latitude: '481.596.809',
  Longitude: '164.577.638',
  Telephone: '+4317671063',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.1596809,16.4577638',
  id: '48.1596809,16.4577638'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Krottenbachstr. 17',
  AddressLine2: 'W',
  PostCode: 1190,
  TownCity: 'Wien',
  Latitude: '482.403.550',
  Longitude: '163.419.650',
  Telephone: '+4313681379',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.240355,16.341965',
  id: '48.240355,16.341965'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Laxenburgerstrasse 2a',
  AddressLine2: 'W',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '481.832.890',
  Longitude: '163.734.140',
  Telephone: '+4316044132',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.183289,16.373414',
  id: '48.183289,16.373414'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Linke Wienzeile 90-92',
  AddressLine2: 'W',
  PostCode: 1060,
  TownCity: 'Wien',
  Latitude: '481.943.722',
  Longitude: '163.539.679',
  Telephone: '+4315856962',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.1943722,16.3539679',
  id: '48.1943722,16.3539679'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Linzer Str. 135-137',
  AddressLine2: 'W',
  PostCode: 1140,
  TownCity: 'Wien',
  Latitude: '481.934.000',
  Longitude: '163.017.000',
  Telephone: '+4319854327',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.1934,16.3017',
  id: '48.1934,16.3017'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Margaretenguertel 43-45',
  AddressLine2: 'W',
  PostCode: 1050,
  TownCity: 'Wien',
  Latitude: '481.798.000',
  Longitude: '163.517.000',
  Telephone: '+4315480292',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1798,16.3517',
  id: '48.1798,16.3517'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Maroltingergasse 5',
  AddressLine2: 'W',
  PostCode: 1140,
  TownCity: 'Wien',
  Latitude: '482.035.804',
  Longitude: '163.030.850',
  Telephone: '+4319143237',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.2035804,16.303085',
  id: '48.2035804,16.303085'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Klopeinersee Str. 6',
  AddressLine2: 'KTN',
  PostCode: 9122,
  TownCity: 'St. Kanzian',
  Latitude: '466.151.155',
  Longitude: '145.753.641',
  Telephone: '+4342392450',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Yes',
  location: '46.6151155,14.5753641',
  id: '46.6151155,14.5753641'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Triesterstrasse 104',
  AddressLine2: 'W',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '481.573.000',
  Longitude: '163.411.000',
  Telephone: '+4316674134',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1573,16.3411',
  id: '48.1573,16.3411'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Triesterstrasse 153',
  AddressLine2: 'W',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '481.565.000',
  Longitude: '163.416.000',
  Telephone: '+4316167464',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1565,16.3416',
  id: '48.1565,16.3416'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wienerbergstr. 29',
  AddressLine2: 'W',
  PostCode: 1120,
  TownCity: 'Wien',
  Latitude: '481.709.000',
  Longitude: '163.368.000',
  Telephone: '+4318156279',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.1709,16.3368',
  id: '48.1709,16.3368'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Gewerbeparkstr.On1 Gew.Park Stadlau',
  AddressLine2: 'W',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '482.476.153',
  Longitude: '164.658.336',
  Telephone: '+4317344935',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.2476153,16.4658336',
  id: '48.2476153,16.4658336'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Zinnergasse 4',
  AddressLine2: 'W',
  PostCode: 1110,
  TownCity: 'Wien',
  Latitude: '481.612.000',
  Longitude: '164.715.000',
  Telephone: '+4317676404',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.1612,16.4715',
  id: '48.1612,16.4715'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Zur Autobahn 2',
  AddressLine2: 'NOE',
  PostCode: 3250,
  TownCity: 'Wieselburg',
  Latitude: '481.352.250',
  Longitude: '151.416.780',
  Telephone: '+43741658788',
  WeekDayFrom: '05:30',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.135225,15.141678',
  id: '48.135225,15.141678'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Altenberger  Strasse 7',
  AddressLine2: 'OOE',
  PostCode: 4040,
  TownCity: 'Linz-Auhof',
  Latitude: '483.317.367',
  Longitude: '143.175.808',
  Telephone: '+43732245847',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.3317367,14.3175808',
  id: '48.3317367,14.3175808'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Autobahnzubringer',
  AddressLine2: 'NOE',
  PostCode: 2752,
  TownCity: 'Woellersdorf',
  Latitude: '478.597.650',
  Longitude: '161.807.520',
  Telephone: '+43263342822',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.859765,16.180752',
  id: '47.859765,16.180752'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Industriezentrum Sued Ii',
  AddressLine2: 'NOE',
  PostCode: 2351,
  TownCity: 'Wiener Neudorf',
  Latitude: '480.788.244',
  Longitude: '163.239.240',
  Telephone: '+43223662430',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.0788244,16.323924',
  id: '48.0788244,16.323924'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Wienerstrasse 114',
  AddressLine2: 'NOE',
  PostCode: 2700,
  TownCity: 'Wiener Neustadt',
  Latitude: '478.295.640',
  Longitude: '162.443.700',
  Telephone: '+43262227939',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '47.829564,16.24437',
  id: '47.829564,16.24437'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Alte Poststr.25',
  AddressLine2: 'NOE',
  PostCode: 3341,
  TownCity: 'Ybbsitz',
  Latitude: '479.483.652',
  Longitude: '148.955.621',
  Telephone: '+43744386511',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '12:00',
  SundayFrom: '08:00',
  SundayTo: '12:00',
  IsOpen24Hours: 'Yes',
  location: '47.9483652,14.8955621',
  id: '47.9483652,14.8955621'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Aussergant',
  AddressLine2: 'VBG',
  PostCode: 6791,
  TownCity: 'St. Gallenkirch',
  Latitude: '470.202.617',
  Longitude: '99.771.738',
  Telephone: '+4355576257',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.0202617,9.9771738',
  id: '47.0202617,9.9771738'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Aiglhofstrasse 20',
  AddressLine2: 'SBG',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '478.034.111',
  Longitude: '130.286.574',
  Telephone: '+43662432552',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.8034111,13.0286574',
  id: '47.8034111,13.0286574'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Dorf 48',
  AddressLine2: 'T',
  PostCode: 6373,
  TownCity: 'Jochberg',
  Latitude: '473.758.000',
  Longitude: '124.190.000',
  Telephone: '+4353555297',
  WeekDayFrom: '07:30',
  WeekDayTo: '18:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '17:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.3758,12.419',
  id: '47.3758,12.419'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Nonntaler Hauptstrasse 65',
  AddressLine2: 'SBG',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '477.845.000',
  Longitude: '130.525.000',
  Telephone: '+43662825644',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.7845,13.0525',
  id: '47.7845,13.0525'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Kaerntner Strasse',
  AddressLine2: 'STMK',
  PostCode: 8580,
  TownCity: 'Koeflach',
  Latitude: '470.620.630',
  Longitude: '150.800.660',
  Telephone: '+4331442638',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.062063,15.080066',
  id: '47.062063,15.080066'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Pass Thurn Strasse 17',
  AddressLine2: 'T',
  PostCode: 6371,
  TownCity: 'Aurach',
  Latitude: '474.185.000',
  Longitude: '124.216.000',
  Telephone: '53566402016',
  WeekDayFrom: '06:30',
  WeekDayTo: '19:00',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4185,12.4216',
  id: '47.4185,12.4216'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Gries 16',
  AddressLine2: 'T',
  PostCode: 6631,
  TownCity: 'Lermoos',
  Latitude: '474.063.172',
  Longitude: '108.700.748',
  Telephone: '+4356732197',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.4063172,10.8700748',
  id: '47.4063172,10.8700748'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wald 1',
  AddressLine2: 'T',
  PostCode: 6306,
  TownCity: 'Soell',
  Latitude: '474.994.000',
  Longitude: '121.883.000',
  Telephone: '+4353335209',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.4994,12.1883',
  id: '47.4994,12.1883'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Nr.8',
  AddressLine2: 'T',
  PostCode: 6410,
  TownCity: 'Telfs-Sagl',
  Latitude: '473.171.250',
  Longitude: '110.935.730',
  Telephone: '+43526262277',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.317125,11.093573',
  id: '47.317125,11.093573'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Stubaitalstr. 81',
  AddressLine2: 'T',
  PostCode: 6167,
  TownCity: 'Neustift',
  Latitude: '471.115.000',
  Longitude: '113.096.000',
  Telephone: '+4352263525',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.1115,11.3096',
  id: '47.1115,11.3096'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Kremstal Bundestrasse 76',
  AddressLine2: 'OOE',
  PostCode: 4050,
  TownCity: 'Traun',
  Latitude: '482.306.260',
  Longitude: '142.218.045',
  Telephone: '722961291',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.230626,14.2218045',
  id: '48.230626,14.2218045'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hauptstrasse 30',
  AddressLine2: 'KTN',
  PostCode: 9871,
  TownCity: 'Seeboden',
  Latitude: '468.166.000',
  Longitude: '135.101.000',
  Telephone: '800080645',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '46.8166,13.5101',
  id: '46.8166,13.5101'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Joh.Herbst-Str. 15',
  AddressLine2: '',
  PostCode: 5061,
  TownCity: 'Glasenbach-Elsbethen',
  Latitude: '477.695.606',
  Longitude: '130.854.034',
  Telephone: '+43662621363',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:30',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:30',
  SundayFrom: '06:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.7695606,13.0854034',
  id: '47.7695606,13.0854034'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Hauptstrasse 1b',
  AddressLine2: 'KTN',
  PostCode: 9341,
  TownCity: 'Strassburg',
  Latitude: '468.950.000',
  Longitude: '143.377.000',
  Telephone: '+4342663101',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Yes',
  location: '46.895,14.3377',
  id: '46.895,14.3377'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Ausfahrtsstrasse',
  AddressLine2: 'NOE',
  PostCode: 1300,
  TownCity: 'Schwechat-Flughafen',
  Latitude: '481.248.081',
  Longitude: '165.619.969',
  Telephone: '+431700732618',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1248081,16.5619969',
  id: '48.1248081,16.5619969'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hirschgraben',
  AddressLine2: 'STMK',
  PostCode: 8707,
  TownCity: 'Leoben-Goess',
  Latitude: '473.640.164',
  Longitude: '150.961.791',
  Telephone: '+43384222194',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.3640164,15.0961791',
  id: '47.3640164,15.0961791'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wiener Strasse 51',
  AddressLine2: 'STMK',
  PostCode: 8720,
  TownCity: 'Knittelfeld',
  Latitude: '472.202.000',
  Longitude: '148.290.000',
  Telephone: '+43351283710',
  WeekDayFrom: '06:30',
  WeekDayTo: '22:30',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:30',
  SundayFrom: '06:30',
  SundayTo: '22:30',
  IsOpen24Hours: 'No',
  location: '47.2202,14.829',
  id: '47.2202,14.829'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Passauer Strasse 29',
  AddressLine2: 'OOE',
  PostCode: 4780,
  TownCity: 'Schaerding',
  Latitude: '484.675.150',
  Longitude: '134.417.705',
  Telephone: '+43771250021',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.467515,13.4417705',
  id: '48.467515,13.4417705'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Zellerstrasse 29',
  AddressLine2: 'SBG',
  PostCode: 5751,
  TownCity: 'Maishofen',
  Latitude: '473.507.000',
  Longitude: '128.005.000',
  Telephone: '+43654268056',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.3507,12.8005',
  id: '47.3507,12.8005'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Schnellstrasse S 6',
  AddressLine2: 'NOE',
  PostCode: 2620,
  TownCity: 'Natschbach',
  Latitude: '477.067.365',
  Longitude: '161.021.590',
  Telephone: '+43263569714',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.7067365,16.102159',
  id: '47.7067365,16.102159'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Voralpenkreuz A8',
  AddressLine2: 'OOE',
  PostCode: 4642,
  TownCity: 'Sattledt',
  Latitude: '480.660.300',
  Longitude: '140.350.212',
  Telephone: '+43724420189',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.06603,14.0350212',
  id: '48.06603,14.0350212'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Mariazeller Str.264',
  AddressLine2: 'NOE',
  PostCode: 3100,
  TownCity: 'St. Poelten',
  Latitude: '481.574.248',
  Longitude: '156.142.557',
  Telephone: '+432742883738',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.1574248,15.6142557',
  id: '48.1574248,15.6142557'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Lamprechtshauserner B156',
  AddressLine2: 'SBG',
  PostCode: 5101,
  TownCity: 'Bergheim / Lengfelden',
  Latitude: '478.441.697',
  Longitude: '130.505.776',
  Telephone: '+43662453775',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.8441697,13.0505776',
  id: '47.8441697,13.0505776'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'An Der Raststation-Lkw',
  AddressLine2: 'NOE',
  PostCode: 3385,
  TownCity: 'Voellerndorf',
  Latitude: '481.760.440',
  Longitude: '155.568.980',
  Telephone: '+43274272860',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.176044,15.556898',
  id: '48.176044,15.556898'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Hochhauser',
  AddressLine1: 'BREITENFURTER STRASSE 112',
  AddressLine2: 'W',
  PostCode: 1120,
  TownCity: 'WIEN',
  Latitude: '481.605.330',
  Longitude: '163.095.340',
  Telephone: '4318023624',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.160533,16.309534',
  id: '48.160533,16.309534'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Montafoner Strasse 29',
  AddressLine2: 'VBG',
  PostCode: 6700,
  TownCity: 'Bludenz',
  Latitude: '471.418.290',
  Longitude: '98.334.120',
  Telephone: '+43555267744',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.141829,9.833412',
  id: '47.141829,9.833412'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hauptstrasse 54',
  AddressLine2: 'NOE',
  PostCode: 3021,
  TownCity: 'Pressbaum',
  Latitude: '481.803.000',
  Longitude: '160.780.000',
  Telephone: '+43223352543',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.1803,16.078',
  id: '48.1803,16.078'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Stiegstrasse 63',
  AddressLine2: 'VBG',
  PostCode: 6830,
  TownCity: 'Rankweil',
  Latitude: '472.798.000',
  Longitude: '96.497.100',
  Telephone: '+43552244581',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.2798,9.64971',
  id: '47.2798,9.64971'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wiener Strasse 58',
  AddressLine2: 'NOE',
  PostCode: 2514,
  TownCity: 'Traiskirchen',
  Latitude: '480.212.320',
  Longitude: '162.979.630',
  Telephone: '+43225252359',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.021232,16.297963',
  id: '48.021232,16.297963'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Langenlebarnerstr.41',
  AddressLine2: 'NOE',
  PostCode: 3430,
  TownCity: 'Tulln',
  Latitude: '483.313.308',
  Longitude: '160.647.689',
  Telephone: '+43227262205',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:30',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:30',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '48.3313308,16.0647689',
  id: '48.3313308,16.0647689'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Reichshofstrasse',
  AddressLine2: 'VBG',
  PostCode: 6890,
  TownCity: 'Lustenau',
  Latitude: '474.245.180',
  Longitude: '96.518.010',
  Telephone: '+43557788184',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.424518,9.651801',
  id: '47.424518,9.651801'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Haemmerlestrasse 99',
  AddressLine2: 'VBG',
  PostCode: 6805,
  TownCity: 'Feldkirch-Gisingen',
  Latitude: '472.411.000',
  Longitude: '95.910.200',
  Telephone: '+43552273455',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.2411,9.59102',
  id: '47.2411,9.59102'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Leobersdorfer Strasse 12',
  AddressLine2: 'NOE',
  PostCode: 2560,
  TownCity: 'Berndorf',
  Latitude: '479.436.000',
  Longitude: '161.115.000',
  Telephone: '+43267282579',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.9436,16.1115',
  id: '47.9436,16.1115'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Triesterstrasse 68',
  AddressLine2: 'W',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '481.656.000',
  Longitude: '163.465.000',
  Telephone: '+4316676173',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1656,16.3465',
  id: '48.1656,16.3465'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Laab 2',
  AddressLine2: 'OOE',
  PostCode: 4770,
  TownCity: 'Andorf',
  Latitude: '483.525.531',
  Longitude: '135.590.310',
  Telephone: '07766/2356',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '48.3525531,13.559031',
  id: '48.3525531,13.559031'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Untermarktstrasse 61',
  AddressLine2: 'T',
  PostCode: 6410,
  TownCity: 'Telfs',
  Latitude: '473.075.170',
  Longitude: '110.792.870',
  Telephone: '+43526262162',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.307517,11.079287',
  id: '47.307517,11.079287'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Zillertalstr. 35',
  AddressLine2: 'T',
  PostCode: 6263,
  TownCity: 'Fuegen',
  Latitude: '473.454.000',
  Longitude: '118.528.000',
  Telephone: '+43528862413',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.3454,11.8528',
  id: '47.3454,11.8528'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Bundesstrasse 292',
  AddressLine2: 'T',
  PostCode: 6543,
  TownCity: 'Nauders',
  Latitude: '468.943.430',
  Longitude: '104.983.670',
  Telephone: '+43547387336',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '46.894343,10.498367',
  id: '46.894343,10.498367'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Laakirchner Hauptstr.43',
  AddressLine2: 'OOE',
  PostCode: 4662,
  TownCity: 'Steyrermuehl',
  Latitude: '479.893.129',
  Longitude: '138.117.320',
  Telephone: '+4376132217',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Yes',
  location: '47.9893129,13.811732',
  id: '47.9893129,13.811732'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Harderstrasse 84',
  AddressLine2: 'VBG',
  PostCode: 6972,
  TownCity: 'Fussach',
  Latitude: '474.674.000',
  Longitude: '96.582.500',
  Telephone: '+43557875718',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Yes',
  location: '47.4674,9.65825',
  id: '47.4674,9.65825'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Neumarkter Strasse 32',
  AddressLine2: 'KTN',
  PostCode: 9360,
  TownCity: 'Friesach',
  Latitude: '469.600.032',
  Longitude: '144.010.809',
  Telephone: '+4342682504',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.9600032,14.4010809',
  id: '46.9600032,14.4010809'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Dorfstrasse 148',
  AddressLine2: 'T',
  PostCode: 6450,
  TownCity: 'Soelden',
  Latitude: '469.631.000',
  Longitude: '110.077.000',
  Telephone: '+4352542466',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '46.9631,11.0077',
  id: '46.9631,11.0077'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Innsbrucker Strasse 24',
  AddressLine2: 'T',
  PostCode: 6162,
  TownCity: 'Mutters',
  Latitude: '472.302.000',
  Longitude: '113.809.000',
  Telephone: '+43512548540',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:30',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Yes',
  location: '47.2302,11.3809',
  id: '47.2302,11.3809'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Moelltalstrasse 33',
  AddressLine2: 'KTN',
  PostCode: 9813,
  TownCity: 'Moellbruecke',
  Latitude: '468.367.000',
  Longitude: '133.737.000',
  Telephone: '+4347692458',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '46.8367,13.3737',
  id: '46.8367,13.3737'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Im Buch 43',
  AddressLine2: 'VBG',
  PostCode: 6840,
  TownCity: 'Goetzis',
  Latitude: '473.397.706',
  Longitude: '96.521.602',
  Telephone: '+43552362719',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.3397706,9.6521602',
  id: '47.3397706,9.6521602'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Gleisdorfer Strasse 60',
  AddressLine2: 'STMK',
  PostCode: 8160,
  TownCity: 'Weiz',
  Latitude: '472.123.000',
  Longitude: '156.293.000',
  Telephone: '+4331722073',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:30',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:30',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.2123,15.6293',
  id: '47.2123,15.6293'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Autobahn St.Poelten-Pkw',
  AddressLine2: 'NOE',
  PostCode: 3385,
  TownCity: 'Voellerndorf',
  Latitude: '481.774.881',
  Longitude: '155.527.113',
  Telephone: '+4327492511',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1774881,15.5527113',
  id: '48.1774881,15.5527113'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Bundesstrasse',
  AddressLine2: 'VBG',
  PostCode: 6863,
  TownCity: 'Egg im Bregenzerwald',
  Latitude: '474.325.160',
  Longitude: '98.953.640',
  Telephone: '+4355122530',
  WeekDayFrom: '06:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.432516,9.895364',
  id: '47.432516,9.895364'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Schrems 3',
  AddressLine2: 'STMK',
  PostCode: 8163,
  TownCity: 'Fladnitz',
  Latitude: '472.687.280',
  Longitude: '154.805.620',
  Telephone: '+4331796132',
  WeekDayFrom: '06:30',
  WeekDayTo: '19:30',
  SaturdayFrom: '06:30',
  SaturdayTo: '19:30',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'No',
  location: '47.268728,15.480562',
  id: '47.268728,15.480562'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Amraser Seestrasse 10',
  AddressLine2: 'T',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '472.609.000',
  Longitude: '114.213.000',
  Telephone: '+43512346424',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'No',
  location: '47.2609,11.4213',
  id: '47.2609,11.4213'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Reichenauer Strasse 149',
  AddressLine2: 'T',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '472.738.000',
  Longitude: '114.282.000',
  Telephone: '+435122821560',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.2738,11.4282',
  id: '47.2738,11.4282'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Flachauwinklstraße 182',
  AddressLine2: 'SBG',
  PostCode: 5542,
  TownCity: 'Flachau',
  Latitude: '472.476.280',
  Longitude: '134.251.580',
  Telephone: '+43645733737',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.247628,13.425158',
  id: '47.247628,13.425158'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Innkreisautobahn A8',
  AddressLine2: 'OOE',
  PostCode: 4676,
  TownCity: 'Aistersheim',
  Latitude: '481.781.000',
  Longitude: '137.468.912',
  Telephone: '+4377342232',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1781,13.7468912',
  id: '48.1781,13.7468912'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Linzerstrasse 62',
  AddressLine2: 'OOE',
  PostCode: 4614,
  TownCity: 'Marchtrenk',
  Latitude: '481.914.000',
  Longitude: '141.214.000',
  Telephone: '+43724354456',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1914,14.1214',
  id: '48.1914,14.1214'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Braunauer Strasse 28',
  AddressLine2: 'SBG',
  PostCode: 5204,
  TownCity: 'Strasswalchen',
  Latitude: '479.881.000',
  Longitude: '132.495.000',
  Telephone: '+4362158356',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.9881,13.2495',
  id: '47.9881,13.2495'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Silvrettastrasse 17',
  AddressLine2: 'T',
  PostCode: 6561,
  TownCity: 'Ischgl',
  Latitude: '470.157.000',
  Longitude: '102.969.000',
  Telephone: '+4354445787',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.0157,10.2969',
  id: '47.0157,10.2969'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hirtenbergerstrasse 4e',
  AddressLine2: 'NOE',
  PostCode: 2544,
  TownCity: 'Leobersdorf',
  Latitude: '479.346.820',
  Longitude: '162.169.760',
  Telephone: '+43225664606',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '05:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Yes',
  location: '47.934682,16.216976',
  id: '47.934682,16.216976'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Erlach 138',
  AddressLine2: 'T',
  PostCode: 6150,
  TownCity: 'Steinach am Brenner',
  Latitude: '470.953.000',
  Longitude: '114.655.000',
  Telephone: '+4352722260',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.0953,11.4655',
  id: '47.0953,11.4655'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wiener Str. 76',
  AddressLine2: 'NOE',
  PostCode: 3500,
  TownCity: 'Krems',
  Latitude: '484.150.000',
  Longitude: '156.175.000',
  Telephone: '+43273277998',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.415,15.6175',
  id: '48.415,15.6175'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Rosenheimer Strasse 6',
  AddressLine2: 'T',
  PostCode: 6330,
  TownCity: 'Kufstein',
  Latitude: '475.938.000',
  Longitude: '121.773.000',
  Telephone: '+43537271021',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.5938,12.1773',
  id: '47.5938,12.1773'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Bruennerstrasse 186',
  AddressLine2: 'W',
  PostCode: 1210,
  TownCity: 'Wien',
  Latitude: '482.835.000',
  Longitude: '164.134.000',
  Telephone: '+4312926869',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.2835,16.4134',
  id: '48.2835,16.4134'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Lustenauer Strasse 112a',
  AddressLine2: 'VBG',
  PostCode: 6845,
  TownCity: 'Hohenems',
  Latitude: '473.736.000',
  Longitude: '96.775.900',
  Telephone: '+43557674262',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.3736,9.67759',
  id: '47.3736,9.67759'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Ennser Strasse 10 A',
  AddressLine2: 'OOE',
  PostCode: 4400,
  TownCity: 'Steyr',
  Latitude: '480.531.000',
  Longitude: '144.216.000',
  Telephone: '+43725282255',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.0531,14.4216',
  id: '48.0531,14.4216'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Puntigamer Strasse 130',
  AddressLine2: 'STMK',
  PostCode: 8055,
  TownCity: 'Graz',
  Latitude: '470.335.000',
  Longitude: '154.427.000',
  Telephone: '+43316292492',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.0335,15.4427',
  id: '47.0335,15.4427'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Brixentalerstrasse 13',
  AddressLine2: 'T',
  PostCode: 6364,
  TownCity: 'Brixen im Thale',
  Latitude: '474.449.000',
  Longitude: '122.335.000',
  Telephone: '+4353342880',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.4449,12.2335',
  id: '47.4449,12.2335'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Niederoesterreichring 20',
  AddressLine2: 'NOE',
  PostCode: 3100,
  TownCity: 'St. Poelten',
  Latitude: '481.980.980',
  Longitude: '156.393.920',
  Telephone: '+432742230033',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '05:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.198098,15.639392',
  id: '48.198098,15.639392'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Salzburgerstrasse 383',
  AddressLine2: 'OOE',
  PostCode: 4030,
  TownCity: 'Linz',
  Latitude: '482.472.760',
  Longitude: '142.648.030',
  Telephone: '+43732372021',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.247276,14.264803',
  id: '48.247276,14.264803'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'L.V.Hoehnelgasse',
  AddressLine2: 'W',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '481.589.740',
  Longitude: '163.870.690',
  Telephone: '+4316881900',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.158974,16.387069',
  id: '48.158974,16.387069'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Neue Landstrasse 68',
  AddressLine2: 'OOE',
  PostCode: 4655,
  TownCity: 'Vorchdorf',
  Latitude: '480.083.000',
  Longitude: '139.233.000',
  Telephone: '+4376145360',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.0083,13.9233',
  id: '48.0083,13.9233'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Salzburger Strasse 146',
  AddressLine2: 'OOE',
  PostCode: 4600,
  TownCity: 'Wels',
  Latitude: '481.566.000',
  Longitude: '140.009.000',
  Telephone: '+43724244560',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.1566,14.0009',
  id: '48.1566,14.0009'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Gewerbestrasse 1',
  AddressLine2: 'SBG',
  PostCode: 5301,
  TownCity: 'Eugendorf-Strass',
  Latitude: '478.616.356',
  Longitude: '131.284.904',
  Telephone: '+4362252405',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.8616356,13.1284904',
  id: '47.8616356,13.1284904'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Iseltalerstrasse 25',
  AddressLine2: 'T',
  PostCode: 9900,
  TownCity: 'Lienz',
  Latitude: '468.298.000',
  Longitude: '127.547.000',
  Telephone: '+43485262659',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '46.8298,12.7547',
  id: '46.8298,12.7547'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Grazer Strasse 77',
  AddressLine2: 'STMK',
  PostCode: 8605,
  TownCity: 'Diemlach',
  Latitude: '474.332.207',
  Longitude: '152.721.147',
  Telephone: '+43386222112',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.4332207,15.2721147',
  id: '47.4332207,15.2721147'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Imlau  65',
  AddressLine2: 'SBG',
  PostCode: 5452,
  TownCity: 'Imlau',
  Latitude: '474.631.787',
  Longitude: '131.953.128',
  Telephone: '+4364685327',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.4631787,13.1953128',
  id: '47.4631787,13.1953128'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Salzkammergut Str. 8',
  AddressLine2: 'OOE',
  PostCode: 4844,
  TownCity: 'Regau',
  Latitude: '479.874.000',
  Longitude: '136.912.000',
  Telephone: '+43767275371',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'No',
  location: '47.9874,13.6912',
  id: '47.9874,13.6912'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wieselburger Strasse 2',
  AddressLine2: 'NOE',
  PostCode: 3281,
  TownCity: 'Oberndorf/Melk',
  Latitude: '480.604.170',
  Longitude: '152.136.130',
  Telephone: '+4374837000',
  WeekDayFrom: '06:45',
  WeekDayTo: '19:45',
  SaturdayFrom: '06:45',
  SaturdayTo: '19:45',
  SundayFrom: '08:00',
  SundayTo: '19:45',
  IsOpen24Hours: 'No',
  location: '48.060417,15.213613',
  id: '48.060417,15.213613'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Virgentalstrasse 39',
  AddressLine2: 'KTN',
  PostCode: 9972,
  TownCity: 'Virgen',
  Latitude: '470.018.000',
  Longitude: '124.643.000',
  Telephone: '+4348745267',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.0018,12.4643',
  id: '47.0018,12.4643'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Landesstrasse 1-3',
  AddressLine2: 'KTN',
  PostCode: 9130,
  TownCity: 'Poggersdorf',
  Latitude: '466.414.000',
  Longitude: '144.495.000',
  Telephone: '+43422481302',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '46.6414,14.4495',
  id: '46.6414,14.4495'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Lagerhaus Rohrbach',
  AddressLine1: 'Falkensteinstrasse 16',
  AddressLine2: 'OOE',
  PostCode: 4154,
  TownCity: 'Kollerschlag',
  Latitude: '486.023.000',
  Longitude: '138.389.000',
  Telephone: '+437287752848',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '05:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'No',
  location: '48.6023,13.8389',
  id: '48.6023,13.8389'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hauptstrasse 34',
  AddressLine2: 'NOE',
  PostCode: 3200,
  TownCity: 'Obergrafendorf',
  Latitude: '481.498.000',
  Longitude: '155.495.000',
  Telephone: '+4327472275',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '06:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.1498,15.5495',
  id: '48.1498,15.5495'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Sebersdorf 304',
  AddressLine2: 'STMK',
  PostCode: 8272,
  TownCity: 'Sebersdorf',
  Latitude: '471.826.780',
  Longitude: '159.926.910',
  Telephone: '+43333324899',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.182678,15.992691',
  id: '47.182678,15.992691'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Brennersee 231',
  AddressLine2: '',
  PostCode: 6156,
  TownCity: 'Gries am Brenner',
  Latitude: '4.701.769.000',
  Longitude: '1.150.733.000',
  Telephone: '+43-5274-87223',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.01769,11.50733',
  id: '47.01769,11.50733'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn A13 (Brenner Autobahn) 234',
  AddressLine2: '',
  PostCode: 6156,
  TownCity: 'Gries am Brenner',
  Latitude: '4.701.424.000',
  Longitude: '1.150.557.000',
  Telephone: '+43-5274-87281',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.01424,11.50557',
  id: '47.01424,11.50557'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Gewerbestraße 8',
  AddressLine2: '',
  PostCode: 3382,
  TownCity: 'Loosdorf',
  Latitude: '4.819.000.000',
  Longitude: '1.540.900.000',
  Telephone: '+43-2754-56773',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.19,15.409',
  id: '48.19,15.409'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Rasthausstraße 3',
  AddressLine2: '',
  PostCode: 4300,
  TownCity: 'Sankt Valentin',
  Latitude: '4.819.676.000',
  Longitude: '1.452.693.000',
  Telephone: '+43-7435-58885',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.19676,14.52693',
  id: '48.19676,14.52693'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Laggen 18',
  AddressLine2: '',
  PostCode: 9861,
  TownCity: 'Eisentratten',
  Latitude: '4.691.988.000',
  Longitude: '1.357.964.000',
  Telephone: '+43-4732-2884',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.91988,13.57964',
  id: '46.91988,13.57964'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn A2 (Südautobahn) KM 36,7',
  AddressLine2: '',
  PostCode: 2721,
  TownCity: 'Bad Fischau',
  Latitude: '4.783.396.000',
  Longitude: '1.618.183.000',
  Telephone: '+43-2639-2411',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.83396,16.18183',
  id: '47.83396,16.18183'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'A12 Inntalautobahn / Wirtschaftsweg',
  AddressLine2: '',
  PostCode: 6321,
  TownCity: 'Angath',
  Latitude: '4.751.950.000',
  Longitude: '1.206.600.000',
  Telephone: '+43-5332-74373',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.5195,12.066',
  id: '47.5195,12.066'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn A12 (Inntalautobahn) bei K',
  AddressLine2: '',
  PostCode: 6134,
  TownCity: 'Vomp',
  Latitude: '4.734.700.000',
  Longitude: '1.169.027.000',
  Telephone: '+43-5242-64357',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.347,11.69027',
  id: '47.347,11.69027'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Dolina 36',
  AddressLine2: '',
  PostCode: 9131,
  TownCity: 'Grafenstein',
  Latitude: '4.663.960.000',
  Longitude: '1.445.090.000',
  Telephone: '+43-4225-29008',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.6396,14.4509',
  id: '46.6396,14.4509'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn Golling Ost 31',
  AddressLine2: '',
  PostCode: 5440,
  TownCity: 'Golling an der Salzach',
  Latitude: '4.758.396.000',
  Longitude: '1.315.750.000',
  Telephone: '+43-6244-6061',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.58396,13.1575',
  id: '47.58396,13.1575'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn A4 (Ostautobahn) KM 27,5',
  AddressLine2: '',
  PostCode: 2464,
  TownCity: 'Göttlesbrunn',
  Latitude: '4.805.676.000',
  Longitude: '1.671.078.000',
  Telephone: '+43-2162-88130',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.05676,16.71078',
  id: '48.05676,16.71078'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn A2 (Südautobahn) KM 190',
  AddressLine2: '',
  PostCode: 8143,
  TownCity: 'Dobl (Kaiserwald)',
  Latitude: '4.695.939.000',
  Longitude: '1.538.155.000',
  Telephone: '+43-3136-52985',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.95939,15.38155',
  id: '46.95939,15.38155'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Brenner Autobahn West',
  AddressLine2: '',
  PostCode: 6143,
  TownCity: 'Matrei Berg',
  Latitude: '4.714.126.338',
  Longitude: '1.144.595.606',
  Telephone: '+43(0)5273/6385',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.14126338,11.44595606',
  id: '47.14126338,11.44595606'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Amraser Seestrasse 64',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck-Amrass',
  Latitude: '4.726.341.380',
  Longitude: '1.143.339.430',
  Telephone: '+43(0)512/345498',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.2634138,11.4333943',
  id: '47.2634138,11.4333943'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'A22 Autobahn/Richtung Wien',
  AddressLine2: '',
  PostCode: 2100,
  TownCity: 'Korneuburg',
  Latitude: '4.834.728.256',
  Longitude: '1.632.001.716',
  Telephone: '+43(0)2262/75349',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.34728256,16.32001716',
  id: '48.34728256,16.32001716'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'A22 Autobahn/Richtung Stockerau',
  AddressLine2: '',
  PostCode: 2100,
  TownCity: 'Korneuburg',
  Latitude: '4.834.620.469',
  Longitude: '1.631.994.529',
  Telephone: '+43(0)2262/74589',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.34620469,16.31994529',
  id: '48.34620469,16.31994529'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Brenner Autobahn Ost',
  AddressLine2: '',
  PostCode: 6143,
  TownCity: 'Matrei Tal',
  Latitude: '4.714.072.000',
  Longitude: '1.144.653.000',
  Telephone: '+43(0)5273/6385',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.14072,11.44653',
  id: '47.14072,11.44653'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'A2 Südautobahn/Pack Km 235',
  AddressLine2: '',
  PostCode: 9451,
  TownCity: 'Preitenegg',
  Latitude: '4.693.881.000',
  Longitude: '1.492.458.000',
  Telephone: '+43(0)4354/2200',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.93881,14.92458',
  id: '46.93881,14.92458'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'A1/Westautobahn',
  AddressLine2: '',
  PostCode: 3373,
  TownCity: 'Ybbs-Kemmelbach',
  Latitude: '4.815.384.620',
  Longitude: '1.509.798.498',
  Telephone: '+43(0)7412/52746',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.1538462,15.09798498',
  id: '48.1538462,15.09798498'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Lamm 113',
  AddressLine2: '',
  PostCode: 5584,
  TownCity: 'Zederhaus/Krottendorf',
  Latitude: '4.713.408.000',
  Longitude: '1.354.854.000',
  Telephone: '+43(0)6478/800',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.13408,13.54854',
  id: '47.13408,13.54854'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Waldstraße Nr.4',
  AddressLine2: '',
  PostCode: 7410,
  TownCity: 'Loipersdorf-Kitzladen',
  Latitude: '4.728.726.000',
  Longitude: '1.606.778.000',
  Telephone: '+43(0)3359/2670',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.28726,16.06778',
  id: '47.28726,16.06778'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'An der Au 1',
  AddressLine2: '',
  PostCode: 6491,
  TownCity: 'Mils bei Imst',
  Latitude: '4.721.354.000',
  Longitude: '1.069.712.000',
  Telephone: '+43(0)5418/20137',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.21354,10.69712',
  id: '47.21354,10.69712'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wientalstraße/Auhofbrücke 40',
  AddressLine2: '',
  PostCode: 1140,
  TownCity: 'Wien',
  Latitude: '4.820.186.000',
  Longitude: '1.623.616.000',
  Telephone: '+43-1-9792586',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.20186,16.23616',
  id: '48.20186,16.23616'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hütteldorfer Straße 216b',
  AddressLine2: '',
  PostCode: 1140,
  TownCity: 'Wien',
  Latitude: '4.819.788.000',
  Longitude: '1.627.994.000',
  Telephone: '+43-1-9145580',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.19788,16.27994',
  id: '48.19788,16.27994'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Gunoldstraße 7/Muthgasse 1',
  AddressLine2: '',
  PostCode: 1190,
  TownCity: 'Wien',
  Latitude: '4.824.531.000',
  Longitude: '1.636.578.000',
  Telephone: '+43-1-3676565',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.24531,16.36578',
  id: '48.24531,16.36578'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Adalbert Stifter-Straße 67',
  AddressLine2: '',
  PostCode: 1200,
  TownCity: 'Wien',
  Latitude: '4.824.309.000',
  Longitude: '1.637.535.000',
  Telephone: '+43-1-3303242',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.24309,16.37535',
  id: '48.24309,16.37535'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Brünner Straße 172',
  AddressLine2: '',
  PostCode: 1210,
  TownCity: 'Wien',
  Latitude: '4.828.114.000',
  Longitude: '1.641.181.000',
  Telephone: '+43-1-2921258',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.28114,16.41181',
  id: '48.28114,16.41181'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Prager Straße 32',
  AddressLine2: '',
  PostCode: 1210,
  TownCity: 'Wien',
  Latitude: '4.826.513.000',
  Longitude: '1.639.386.000',
  Telephone: '+43-1-2711319',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.26513,16.39386',
  id: '48.26513,16.39386'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Breitenleer Straße Ost B302 112',
  AddressLine2: '',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '4.825.141.000',
  Longitude: '1.647.211.000',
  Telephone: '+43-1-7343236',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.25141,16.47211',
  id: '48.25141,16.47211'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hirschstettner Straße 34',
  AddressLine2: '',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '4.824.285.000',
  Longitude: '1.645.645.000',
  Telephone: '+43-1-2040012',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.24285,16.45645',
  id: '48.24285,16.45645'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Laxenburger Straße 202',
  AddressLine2: '',
  PostCode: 1232,
  TownCity: 'Wien',
  Latitude: '4.814.651.000',
  Longitude: '1.636.108.000',
  Telephone: '+43-1-6161980',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.14651,16.36108',
  id: '48.14651,16.36108'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Speisinger Straße 228',
  AddressLine2: '',
  PostCode: 1232,
  TownCity: 'Wien',
  Latitude: '4.815.489.000',
  Longitude: '1.627.110.000',
  Telephone: '+43-1-8887229',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.15489,16.2711',
  id: '48.15489,16.2711'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Reichsstraße 92',
  AddressLine2: '',
  PostCode: 3300,
  TownCity: 'Amstetten',
  Latitude: '4.811.657.000',
  Longitude: '1.490.712.000',
  Telephone: '+43-7472-68585',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.11657,14.90712',
  id: '48.11657,14.90712'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Haidhofstraße 4',
  AddressLine2: '',
  PostCode: 2500,
  TownCity: 'Baden',
  Latitude: '4.799.769.000',
  Longitude: '1.625.102.000',
  Telephone: '+43-2252-88974',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.99769,16.25102',
  id: '47.99769,16.25102'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Eisenstädter Straße 69',
  AddressLine2: '',
  PostCode: 7091,
  TownCity: 'Breitenbrunn',
  Latitude: '4.793.942.000',
  Longitude: '1.672.805.000',
  Telephone: '+43-2683-7060',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.93942,16.72805',
  id: '47.93942,16.72805'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Neustädter Straße 2',
  AddressLine2: '',
  PostCode: 2483,
  TownCity: 'Ebreichsdorf',
  Latitude: '4.796.042.000',
  Longitude: '1.639.770.000',
  Telephone: '+43-2254-72226',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.96042,16.3977',
  id: '47.96042,16.3977'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Stoitzendorferstraße 8',
  AddressLine2: '',
  PostCode: 3730,
  TownCity: 'Eggenburg',
  Latitude: '4.864.370.000',
  Longitude: '1.582.231.000',
  Telephone: '+43-2984-3367',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.6437,15.82231',
  id: '48.6437,15.82231'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Mattersburger Straße 28',
  AddressLine2: '',
  PostCode: 7000,
  TownCity: 'Eisenstadt',
  Latitude: '4.783.317.000',
  Longitude: '1.652.318.000',
  Telephone: '+43-2682-62034',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.83317,16.52318',
  id: '47.83317,16.52318'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Straße 130',
  AddressLine2: '',
  PostCode: 2230,
  TownCity: 'Gänserndorf',
  Latitude: '4.833.812.000',
  Longitude: '1.673.144.000',
  Telephone: '+43-2282-2281',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.33812,16.73144',
  id: '48.33812,16.73144'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'An der Bundesstraße 4',
  AddressLine2: '',
  PostCode: 3701,
  TownCity: 'Großweikersdorf',
  Latitude: '4.847.877.000',
  Longitude: '1.597.709.000',
  Telephone: '+43-2955-70277',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.47877,15.97709',
  id: '48.47877,15.97709'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Straße zur Autobahn 5',
  AddressLine2: '',
  PostCode: 3350,
  TownCity: 'Haag',
  Latitude: '4.811.517.000',
  Longitude: '1.457.068.000',
  Telephone: '+43-7434-42294',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.11517,14.57068',
  id: '48.11517,14.57068'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Gutenhofer Straße 9',
  AddressLine2: '',
  PostCode: 2325,
  TownCity: 'Himberg',
  Latitude: '4.807.949.000',
  Longitude: '1.644.134.000',
  Telephone: '+43-2235-84389',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.07949,16.44134',
  id: '48.07949,16.44134'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Leobersdorfer Straße 96',
  AddressLine2: '',
  PostCode: 2552,
  TownCity: 'Hirtenberg',
  Latitude: '4.793.151.000',
  Longitude: '1.619.569.000',
  Telephone: '+43-2256-81158',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.93151,16.19569',
  id: '47.93151,16.19569'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Straße 29',
  AddressLine2: '',
  PostCode: 2860,
  TownCity: 'Kirchschlag/Bucklige Welt',
  Latitude: '4.749.879.000',
  Longitude: '1.628.993.000',
  Telephone: '+43-2646-2252',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.49879,16.28993',
  id: '47.49879,16.28993'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Badener Straße 1',
  AddressLine2: '',
  PostCode: 2542,
  TownCity: 'Kottingbrunn',
  Latitude: '4.794.930.000',
  Longitude: '1.622.221.000',
  Telephone: '+43-2252-76349',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.9493,16.22221',
  id: '47.9493,16.22221'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Attergauerstraße 11',
  AddressLine2: '',
  PostCode: 4880,
  TownCity: 'Sankt Georgen im Attergau',
  Latitude: '4.793.260.000',
  Longitude: '1.349.221.000',
  Telephone: '+43-7667-6488',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.9326,13.49221',
  id: '47.9326,13.49221'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: '51',
  AddressLine2: '',
  PostCode: 4273,
  TownCity: 'Unterweissenbach',
  Latitude: '4.843.733.000',
  Longitude: '1.478.153.000',
  Telephone: '+43-7956-7258',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.43733,14.78153',
  id: '48.43733,14.78153'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Mühlkreisbahnstraße 3',
  AddressLine2: '',
  PostCode: 4111,
  TownCity: 'Walding',
  Latitude: '4.834.793.000',
  Longitude: '1.415.387.000',
  Telephone: '+43-7234-82473',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.34793,14.15387',
  id: '48.34793,14.15387'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Berg-Sam 22a',
  AddressLine2: '',
  PostCode: 5101,
  TownCity: 'Bergheim',
  Latitude: '4.783.065.000',
  Longitude: '1.306.571.000',
  Telephone: '+43-662-664788',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.83065,13.06571',
  id: '47.83065,13.06571'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Weichseldorf 143',
  AddressLine2: '',
  PostCode: 5733,
  TownCity: 'Bramberg',
  Latitude: '4.726.780.000',
  Longitude: '1.233.757.000',
  Telephone: '+43-6566-7013',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.2678,12.33757',
  id: '47.2678,12.33757'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Salzburger Straße 12',
  AddressLine2: '',
  PostCode: 5112,
  TownCity: 'Lamprechtshausen',
  Latitude: '4.798.643.000',
  Longitude: '1.295.445.000',
  Telephone: '+43-6274-6354',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.98643,12.95445',
  id: '47.98643,12.95445'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Salzburger Straße 2',
  AddressLine2: '',
  PostCode: 5162,
  TownCity: 'Obertrum',
  Latitude: '4.793.554.000',
  Longitude: '1.308.069.000',
  Telephone: '+43-6219-6213',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.93554,13.08069',
  id: '47.93554,13.08069'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bundesstraße 2',
  AddressLine2: '',
  PostCode: 5760,
  TownCity: 'Saalfelden',
  Latitude: '4.742.897.000',
  Longitude: '1.284.275.000',
  Telephone: '+43-6582-72272',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.42897,12.84275',
  id: '47.42897,12.84275'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Alpenstraße 106',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.777.622.000',
  Longitude: '1.306.826.000',
  Telephone: '+43-662-625985',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.77622,13.06826',
  id: '47.77622,13.06826'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Innsbrucker Bundesstraße 142',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.779.796.000',
  Longitude: '1.300.609.000',
  Telephone: '+43-662-827420',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.79796,13.00609',
  id: '47.79796,13.00609'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Münchner Bundesstraße 85',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.782.773.000',
  Longitude: '1.300.872.000',
  Telephone: '+43-662-435330',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.82773,13.00872',
  id: '47.82773,13.00872'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Schiller Straße 28',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.782.232.000',
  Longitude: '1.303.982.000',
  Telephone: '+43-662-450124',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.82232,13.03982',
  id: '47.82232,13.03982'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Vogelweider Straße 86',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.781.696.000',
  Longitude: '1.305.364.000',
  Telephone: '+43-662-877402',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.81696,13.05364',
  id: '47.81696,13.05364'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Salzburger Straße 52',
  AddressLine2: '',
  PostCode: 5620,
  TownCity: 'Schwarzach',
  Latitude: '4.732.019.000',
  Longitude: '1.314.384.000',
  Telephone: '+43-6415-7415',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.32019,13.14384',
  id: '47.32019,13.14384'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wagrainer Straße 11',
  AddressLine2: '',
  PostCode: 5600,
  TownCity: 'Sankt Johann im Pongau',
  Latitude: '4.734.862.000',
  Longitude: '1.320.651.000',
  Telephone: '+43-6412-4284',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.34862,13.20651',
  id: '47.34862,13.20651'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bundesstraße 112',
  AddressLine2: '',
  PostCode: 5582,
  TownCity: 'Sankt Michael im Lungau',
  Latitude: '4.709.406.000',
  Longitude: '1.367.553.000',
  Telephone: '+43-6477-8908',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.09406,13.67553',
  id: '47.09406,13.67553'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Brucker Bundesstraße 110',
  AddressLine2: '',
  PostCode: 5700,
  TownCity: 'Zell am See',
  Latitude: '4.729.793.000',
  Longitude: '1.279.454.000',
  Telephone: '+43-6542-53742',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.29793,12.79454',
  id: '47.29793,12.79454'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Innsbrucker Straße 34',
  AddressLine2: '',
  PostCode: 6230,
  TownCity: 'Brixlegg',
  Latitude: '4.743.323.000',
  Longitude: '1.188.036.000',
  Telephone: '+43-5337-62350',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.43323,11.88036',
  id: '47.43323,11.88036'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Oberachen 13',
  AddressLine2: '',
  PostCode: 6352,
  TownCity: 'Ellmau',
  Latitude: '4.752.129.000',
  Longitude: '1.226.891.000',
  Telephone: '+43-5358-3661',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.52129,12.26891',
  id: '47.52129,12.26891'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bahnhofstraße 11',
  AddressLine2: '',
  PostCode: 6361,
  TownCity: 'Hopfgarten',
  Latitude: '4.745.309.000',
  Longitude: '1.215.153.000',
  Telephone: '+43-5335-3624',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.45309,12.15153',
  id: '47.45309,12.15153'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Egger-Lienz-Straße 3d',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.725.633.000',
  Longitude: '1.138.869.000',
  Telephone: '+43-512-582345',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.25633,11.38869',
  id: '47.25633,11.38869'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Innrain 104',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.725.734.000',
  Longitude: '1.137.805.000',
  Telephone: '+43-512-575303',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.25734,11.37805',
  id: '47.25734,11.37805'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Gewerbezone 11',
  AddressLine2: '',
  PostCode: 6167,
  TownCity: 'Neustift',
  Latitude: '4.712.944.000',
  Longitude: '1.133.707.000',
  Telephone: '+43-5226-3188',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.12944,11.33707',
  id: '47.12944,11.33707'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Oskar Pirlo-Straße 32',
  AddressLine2: '',
  PostCode: 6330,
  TownCity: 'Kufstein',
  Latitude: '4.758.913.000',
  Longitude: '1.217.427.000',
  Telephone: '+43-5372-64549',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.58913,12.17427',
  id: '47.58913,12.17427'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Unterlängenfeld 117b',
  AddressLine2: '',
  PostCode: 6444,
  TownCity: 'Längenfeld',
  Latitude: '4.707.863.000',
  Longitude: '1.096.764.000',
  Telephone: '+43-5253-6385',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.07863,10.96764',
  id: '47.07863,10.96764'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Brenner Straße 114',
  AddressLine2: '',
  PostCode: 6143,
  TownCity: 'Matrei',
  Latitude: '4.712.860.000',
  Longitude: '1.145.258.000',
  Telephone: '+43-5273-6317',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.1286,11.45258',
  id: '47.1286,11.45258'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Münchner Straße 24',
  AddressLine2: '',
  PostCode: 6130,
  TownCity: 'Schwaz',
  Latitude: '4.735.044.000',
  Longitude: '1.170.603.000',
  Telephone: '+43-5242-62433',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.35044,11.70603',
  id: '47.35044,11.70603'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Krinz 27',
  AddressLine2: '',
  PostCode: 6100,
  TownCity: 'Reith bei Seefeld',
  Latitude: '4.732.402.000',
  Longitude: '1.119.909.000',
  Telephone: '+43-5212-2706',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.32402,11.19909',
  id: '47.32402,11.19909'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Höf 14',
  AddressLine2: '',
  PostCode: 6675,
  TownCity: 'Tannheim',
  Latitude: '4.749.917.000',
  Longitude: '1.051.679.000',
  Telephone: '+43-5675-6252',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.49917,10.51679',
  id: '47.49917,10.51679'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Aflinger Straße 4',
  AddressLine2: '',
  PostCode: 6176,
  TownCity: 'Völs',
  Latitude: '4.725.616.000',
  Longitude: '1.131.615.000',
  Telephone: '+43-512-303578',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.25616,11.31615',
  id: '47.25616,11.31615'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Villacher Straße 50',
  AddressLine2: '',
  PostCode: 9800,
  TownCity: 'Spittal an der Drau',
  Latitude: '4.679.163.000',
  Longitude: '1.350.961.000',
  Telephone: '+43-4762-2740',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.79163,13.50961',
  id: '46.79163,13.50961'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Framrach 17',
  AddressLine2: '',
  PostCode: 9433,
  TownCity: 'Sankt Andrä',
  Latitude: '4.674.490.000',
  Longitude: '1.482.285.000',
  Telephone: '+43-4358-2451',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.7449,14.82285',
  id: '46.7449,14.82285'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Grazer Straße 7',
  AddressLine2: '',
  PostCode: 9400,
  TownCity: 'Wolfsberg',
  Latitude: '4.684.742.000',
  Longitude: '1.484.422.000',
  Telephone: '+43-4352-3195',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.84742,14.84422',
  id: '46.84742,14.84422'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Traunuferstraße 108',
  AddressLine2: '',
  PostCode: 4052,
  TownCity: 'Ansfelden',
  Latitude: '4.821.607.000',
  Longitude: '1.427.936.000',
  Telephone: '+43-7229-78989',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.21607,14.27936',
  id: '48.21607,14.27936'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'An der Bundesstraße 1 ca.1KM',
  AddressLine2: '',
  PostCode: 4481,
  TownCity: 'Asten',
  Latitude: '4.822.243.000',
  Longitude: '1.440.833.000',
  Telephone: '+43-7224-66172',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.22243,14.40833',
  id: '48.22243,14.40833'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Salzburger Straße 91',
  AddressLine2: '',
  PostCode: 4800,
  TownCity: 'Attnang-Puchheim',
  Latitude: '4.800.541.000',
  Longitude: '1.370.937.000',
  Telephone: '+43-7674-64990',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.00541,13.70937',
  id: '48.00541,13.70937'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bahnhofstraße 42',
  AddressLine2: '',
  PostCode: 4810,
  TownCity: 'Gmunden',
  Latitude: '4.792.428.000',
  Longitude: '1.378.782.000',
  Telephone: '+43-7612-63990',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.92428,13.78782',
  id: '47.92428,13.78782'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wengerstraße 16',
  AddressLine2: '',
  PostCode: 4710,
  TownCity: 'Grieskirchen',
  Latitude: '4.823.270.000',
  Longitude: '1.382.238.000',
  Telephone: '+43-7248-68281',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.2327,13.82238',
  id: '48.2327,13.82238'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Kremstalstraße 30',
  AddressLine2: '',
  PostCode: 4053,
  TownCity: 'Haid',
  Latitude: '4.820.365.000',
  Longitude: '1.424.699.000',
  Telephone: '+43-7229-88344',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.20365,14.24699',
  id: '48.20365,14.24699'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hainzenbachstraße 18',
  AddressLine2: '',
  PostCode: 4060,
  TownCity: 'Leonding',
  Latitude: '4.827.868.000',
  Longitude: '1.424.570.000',
  Telephone: '+43-732-672547',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.27868,14.2457',
  id: '48.27868,14.2457'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Makartstraße 29',
  AddressLine2: '',
  PostCode: 4020,
  TownCity: 'Linz',
  Latitude: '4.828.592.000',
  Longitude: '1.430.255.000',
  Telephone: '+43-732-653180',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.28592,14.30255',
  id: '48.28592,14.30255'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hafenstraße 8',
  AddressLine2: '',
  PostCode: 4020,
  TownCity: 'Linz',
  Latitude: '4.831.554.000',
  Longitude: '1.430.166.000',
  Telephone: '+43-732-778012',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.31554,14.30166',
  id: '48.31554,14.30166'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Warte am See 28',
  AddressLine2: '',
  PostCode: 5311,
  TownCity: 'Innerschwand am Mondsee',
  Latitude: '4.783.687.000',
  Longitude: '1.339.455.000',
  Telephone: '+43-6232-2849',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.83687,13.39455',
  id: '47.83687,13.39455'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Salzburger Straße 8',
  AddressLine2: '',
  PostCode: 5310,
  TownCity: 'Mondsee',
  Latitude: '4.785.349.000',
  Longitude: '1.334.419.000',
  Telephone: '+43-6232-2583',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.85349,13.34419',
  id: '47.85349,13.34419'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hauptstraße 32',
  AddressLine2: '',
  PostCode: 4901,
  TownCity: 'Ottnang a. H.',
  Latitude: '4.809.666.000',
  Longitude: '1.366.729.000',
  Telephone: '+43-7676-7240',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.09666,13.66729',
  id: '48.09666,13.66729'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: '283',
  AddressLine2: '',
  PostCode: 8271,
  TownCity: 'Bad Waltersdorf',
  Latitude: '4.716.299.000',
  Longitude: '1.601.469.000',
  Telephone: '+43-3333-414110',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.16299,16.01469',
  id: '47.16299,16.01469'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Leobner Straße 59',
  AddressLine2: '',
  PostCode: 8600,
  TownCity: 'Bruck an der Mur',
  Latitude: '4.740.623.000',
  Longitude: '1.526.573.000',
  Telephone: '+43-3862-51022',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.40623,15.26573',
  id: '47.40623,15.26573'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn A9 (Phyrnautobahn) KM 164',
  AddressLine2: '',
  PostCode: 8121,
  TownCity: 'Deutschfeistritz',
  Latitude: '4.720.552.000',
  Longitude: '1.531.602.000',
  Telephone: '+43-3127-40920',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.20552,15.31602',
  id: '47.20552,15.31602'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Grazer Straße 88',
  AddressLine2: '',
  PostCode: 8530,
  TownCity: 'Deutschlandsberg',
  Latitude: '4.681.593.000',
  Longitude: '1.523.460.000',
  Telephone: '+43-3462-2753',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.81593,15.2346',
  id: '46.81593,15.2346'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Heinrichstraße 91',
  AddressLine2: '',
  PostCode: 8010,
  TownCity: 'Graz',
  Latitude: '4.708.178.000',
  Longitude: '1.545.404.000',
  Telephone: '+43-316-323151',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.08178,15.45404',
  id: '47.08178,15.45404'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Seebacher Gasse 8',
  AddressLine2: '',
  PostCode: 8010,
  TownCity: 'Graz',
  Latitude: '4.707.722.000',
  Longitude: '1.545.904.000',
  Telephone: '+43-316-325385',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.07722,15.45904',
  id: '47.07722,15.45904'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hinterbüchl 224',
  AddressLine2: '',
  PostCode: 8224,
  TownCity: 'Kaindorf',
  Latitude: '4.721.909.000',
  Longitude: '1.590.144.000',
  Telephone: '+43-3334-2272',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.21909,15.90144',
  id: '47.21909,15.90144'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'St. Ulrich 6',
  AddressLine2: '',
  PostCode: 4950,
  TownCity: 'Altheim',
  Latitude: '4.825.413.000',
  Longitude: '1.323.644.000',
  Telephone: '+43-7723-42552',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.25413,13.23644',
  id: '48.25413,13.23644'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Salzburger Straße 99',
  AddressLine2: '',
  PostCode: 5280,
  TownCity: 'Braunau',
  Latitude: '4.824.673.000',
  Longitude: '1.304.030.000',
  Telephone: '+43-7722-87227',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.24673,13.0403',
  id: '48.24673,13.0403'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Reiterholz 3',
  AddressLine2: '',
  PostCode: 4663,
  TownCity: 'Laakirchen',
  Latitude: '4.800.466.000',
  Longitude: '1.385.405.000',
  Telephone: '+43-7613-3510',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.00466,13.85405',
  id: '48.00466,13.85405'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Laaer Berg-Straße 240',
  AddressLine2: '',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '4.814.818.000',
  Longitude: '1.639.664.000',
  Telephone: '+43-1-6888065',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.14818,16.39664',
  id: '48.14818,16.39664'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'St. Pöltner Straße 54',
  AddressLine2: '',
  PostCode: 3130,
  TownCity: 'Herzogenburg',
  Latitude: '4.827.876.000',
  Longitude: '1.569.564.000',
  Telephone: '+43-2782-84981',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.27876,15.69564',
  id: '48.27876,15.69564'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Waidhofner Straße 23',
  AddressLine2: '',
  PostCode: 3331,
  TownCity: 'Hilm',
  Latitude: '4.802.154.000',
  Longitude: '1.475.676.000',
  Telephone: '+43-7448-2252',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.02154,14.75676',
  id: '48.02154,14.75676'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bernsteinstraße 6',
  AddressLine2: '',
  PostCode: 2273,
  TownCity: 'Hohenau an der March',
  Latitude: '4.860.217.000',
  Longitude: '1.690.004.000',
  Telephone: '+43-2535-2130',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.60217,16.90004',
  id: '48.60217,16.90004'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Eduard Rösch-Straße 6-8',
  AddressLine2: '',
  PostCode: 2000,
  TownCity: 'Stockerau',
  Latitude: '4.838.611.000',
  Longitude: '1.621.863.000',
  Telephone: '+43-2266-62926',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.38611,16.21863',
  id: '48.38611,16.21863'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Leberstraße 112-114',
  AddressLine2: '',
  PostCode: 1110,
  TownCity: 'Wien',
  Latitude: '4.817.000.000',
  Longitude: '1.640.953.000',
  Telephone: '+43-1-7491753',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.17,16.40953',
  id: '48.17,16.40953'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Groß-Enzersdorfer Straße 24-26',
  AddressLine2: '',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '4.821.686.000',
  Longitude: '1.649.345.000',
  Telephone: '+43-1-2806469',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.21686,16.49345',
  id: '48.21686,16.49345'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Brünner Straße 31',
  AddressLine2: '',
  PostCode: 2201,
  TownCity: 'Hagenbrunn',
  Latitude: '4.833.608.000',
  Longitude: '1.646.097.000',
  Telephone: '+43-2246-2576',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.33608,16.46097',
  id: '48.33608,16.46097'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Breitenleer Straße West B302 108',
  AddressLine2: '',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '4.825.108.000',
  Longitude: '1.647.035.000',
  Telephone: '+43-1-7342707',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.25108,16.47035',
  id: '48.25108,16.47035'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Äuleweg 1',
  AddressLine2: '',
  PostCode: 6751,
  TownCity: 'Innerbraz',
  Latitude: '4.714.077.000',
  Longitude: '992.512.000',
  Telephone: '+43-5552-28012',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.14077,9.92512',
  id: '47.14077,9.92512'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Brunner Straße 77-79',
  AddressLine2: '',
  PostCode: 1230,
  TownCity: 'Wien',
  Latitude: '4.813.342.000',
  Longitude: '1.629.849.000',
  Telephone: '+43-1-8695856',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.13342,16.29849',
  id: '48.13342,16.29849'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Freistädter Straße 50',
  AddressLine2: '',
  PostCode: 4209,
  TownCity: 'Engerwitzdorf',
  Latitude: '4.834.180.000',
  Longitude: '1.441.327.000',
  Telephone: '+43-7235-67890',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.3418,14.41327',
  id: '48.3418,14.41327'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Straße 49-53',
  AddressLine2: '',
  PostCode: 3400,
  TownCity: 'Klosterneuburg',
  Latitude: '4.828.666.000',
  Longitude: '1.634.386.000',
  Telephone: '+43-2243-38882',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.28666,16.34386',
  id: '48.28666,16.34386'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Straße 6',
  AddressLine2: '',
  PostCode: 3580,
  TownCity: 'Frauenhofen',
  Latitude: '4.866.569.000',
  Longitude: '1.563.271.000',
  Telephone: '+43-2982-60162',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.66569,15.63271',
  id: '48.66569,15.63271'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Neufahrn 71',
  AddressLine2: '',
  PostCode: 5202,
  TownCity: 'Neumarkt am Wallersee',
  Latitude: '4.792.330.000',
  Longitude: '1.321.660.000',
  Telephone: '+43-6216-4748',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.9233,13.2166',
  id: '47.9233,13.2166'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Straßganger Straße 436',
  AddressLine2: '',
  PostCode: 8054,
  TownCity: 'Graz',
  Latitude: '4.703.326.000',
  Longitude: '1.539.896.000',
  Telephone: '+43-316-283868',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.03326,15.39896',
  id: '47.03326,15.39896'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Etrichstraße 42',
  AddressLine2: '',
  PostCode: 1110,
  TownCity: 'Wien',
  Latitude: '4.815.133.000',
  Longitude: '1.646.320.000',
  Telephone: '+43-1-7684044',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.15133,16.4632',
  id: '48.15133,16.4632'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Straße 91',
  AddressLine2: '',
  PostCode: 2193,
  TownCity: 'Hobersdorf',
  Latitude: '4.857.280.000',
  Longitude: '1.663.940.000',
  Telephone: '+43-2573-6010',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.5728,16.6394',
  id: '48.5728,16.6394'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Reschenstraße 299',
  AddressLine2: '',
  PostCode: 6531,
  TownCity: 'Ried im Oberinntal',
  Latitude: '4.705.741.000',
  Longitude: '1.065.375.000',
  Telephone: '+43-5472-2338',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.05741,10.65375',
  id: '47.05741,10.65375'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Alte Poststraße 234',
  AddressLine2: '',
  PostCode: 8020,
  TownCity: 'Graz',
  Latitude: '4.705.947.000',
  Longitude: '1.541.386.000',
  Telephone: '+43-316-583900',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.05947,15.41386',
  id: '47.05947,15.41386'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Oberstiftung 100',
  AddressLine2: '',
  PostCode: 4190,
  TownCity: 'Bad Leonfelden',
  Latitude: '4.851.185.000',
  Longitude: '1.429.526.000',
  Telephone: '+43-7213-6188',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.51185,14.29526',
  id: '48.51185,14.29526'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Haagerstraße 38A',
  AddressLine2: '',
  PostCode: 4400,
  TownCity: 'Steyr',
  Latitude: '4.805.521.000',
  Longitude: '1.444.928.000',
  Telephone: '+43-7252-73420',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.05521,14.44928',
  id: '48.05521,14.44928'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Straße 126',
  AddressLine2: '',
  PostCode: 2700,
  TownCity: 'Wiener Neustadt',
  Latitude: '4.784.322.000',
  Longitude: '1.624.631.000',
  Telephone: '+43-2622-89106',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.84322,16.24631',
  id: '47.84322,16.24631'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: '7. Haidequerstraße 2 KM 1,5',
  AddressLine2: '',
  PostCode: 1110,
  TownCity: 'Wien',
  Latitude: '4.818.187.000',
  Longitude: '1.644.451.000',
  Telephone: '+43-1-7685313',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.18187,16.44451',
  id: '48.18187,16.44451'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Gewerbestraße 550',
  AddressLine2: '',
  PostCode: 2534,
  TownCity: 'Alland',
  Latitude: '4.806.751.000',
  Longitude: '1.606.339.000',
  Telephone: '+43-2258-8912',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.06751,16.06339',
  id: '48.06751,16.06339'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Teichstraße 1',
  AddressLine2: '',
  PostCode: 3442,
  TownCity: 'Langenrohr/Asparn',
  Latitude: '4.832.242.000',
  Longitude: '1.602.642.000',
  Telephone: '+43-2272-81123',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.32242,16.02642',
  id: '48.32242,16.02642'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Michael Koch-Straße 65',
  AddressLine2: '',
  PostCode: 7210,
  TownCity: 'Mattersburg',
  Latitude: '4.774.067.000',
  Longitude: '1.640.867.000',
  Telephone: '+43-2626-62184',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.74067,16.40867',
  id: '47.74067,16.40867'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Straße 197',
  AddressLine2: '',
  PostCode: 2513,
  TownCity: 'Möllersdorf',
  Latitude: '4.803.909.000',
  Longitude: '1.630.361.000',
  Telephone: '+43-2252-62163',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.03909,16.30361',
  id: '48.03909,16.30361'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Tullner Straße 202',
  AddressLine2: '',
  PostCode: 3040,
  TownCity: 'Neulengbach',
  Latitude: '4.819.653.000',
  Longitude: '1.589.983.000',
  Telephone: '+43-2772-53852',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.19653,15.89983',
  id: '48.19653,15.89983'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Parz. Nr. 2164 17-18',
  AddressLine2: '',
  PostCode: 7100,
  TownCity: 'Weiden am See',
  Latitude: '4.793.750.000',
  Longitude: '1.685.532.000',
  Telephone: '+43-2167-2565',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.9375,16.85532',
  id: '47.9375,16.85532'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Stoob Süd 6',
  AddressLine2: '',
  PostCode: 7350,
  TownCity: 'Oberpullendorf',
  Latitude: '4.750.957.000',
  Longitude: '1.650.023.000',
  Telephone: '+43-2612-42982',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.50957,16.50023',
  id: '47.50957,16.50023'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Brunner Gasse 48',
  AddressLine2: '',
  PostCode: 2380,
  TownCity: 'Perchtoldsdorf',
  Latitude: '4.811.554.000',
  Longitude: '1.627.169.000',
  Telephone: '+43-1-8691300',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.11554,16.27169',
  id: '48.11554,16.27169'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Mariazeller Straße 95',
  AddressLine2: '',
  PostCode: 3100,
  TownCity: 'Sankt Pölten',
  Latitude: '4.818.769.000',
  Longitude: '1.561.753.000',
  Telephone: '+43-2742-76302',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.18769,15.61753',
  id: '48.18769,15.61753'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Dr.Adolf Schärf-Straße 1',
  AddressLine2: '',
  PostCode: 3100,
  TownCity: 'Sankt Pölten',
  Latitude: '4.822.376.000',
  Longitude: '1.563.452.000',
  Telephone: '+43-2742-348236',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.22376,15.63452',
  id: '48.22376,15.63452'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn A 22',
  AddressLine2: '',
  PostCode: 2000,
  TownCity: 'Stockerau',
  Latitude: '4.838.086.000',
  Longitude: '1.620.408.000',
  Telephone: '+43-2266-63406',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.38086,16.20408',
  id: '48.38086,16.20408'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hauptstraße 119',
  AddressLine2: '',
  PostCode: 2231,
  TownCity: 'Strasshof',
  Latitude: '4.831.458.000',
  Longitude: '1.662.560.000',
  Telephone: '+43-2287-2349',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.31458,16.6256',
  id: '48.31458,16.6256'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Straße 2a',
  AddressLine2: '',
  PostCode: 3340,
  TownCity: 'Waidhofen an der Ybbs',
  Latitude: '4.796.667.000',
  Longitude: '1.476.931.000',
  Telephone: '+43-7442-52706',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.96667,14.76931',
  id: '47.96667,14.76931'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'IZ NÖ Süd Straße 4 Objekt 9',
  AddressLine2: '',
  PostCode: 2351,
  TownCity: 'Wiener Neudorf',
  Latitude: '4.807.760.000',
  Longitude: '1.632.330.000',
  Telephone: '+43-2236-62464',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.0776,16.3233',
  id: '48.0776,16.3233'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Neudörfler Straße 70',
  AddressLine2: '',
  PostCode: 2700,
  TownCity: 'Wiener Neustadt',
  Latitude: '4.780.470.000',
  Longitude: '1.626.798.000',
  Telephone: '+43-2622-29498',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.8047,16.26798',
  id: '47.8047,16.26798'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bahnhofstraße 18',
  AddressLine2: '',
  PostCode: 3370,
  TownCity: 'Ybbs an der Donau',
  Latitude: '4.817.122.000',
  Longitude: '1.508.459.000',
  Telephone: '+43-7412-52568',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.17122,15.08459',
  id: '48.17122,15.08459'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Umfahrungsstraße 28a',
  AddressLine2: '',
  PostCode: 2225,
  TownCity: 'Zistersdorf',
  Latitude: '4.854.239.000',
  Longitude: '1.676.417.000',
  Telephone: '+43-2532-2240',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.54239,16.76417',
  id: '48.54239,16.76417'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'St.Johanner Straße 15a',
  AddressLine2: '',
  PostCode: 6370,
  TownCity: 'Kitzbühel',
  Latitude: '4.745.609.000',
  Longitude: '1.238.639.000',
  Telephone: '+43-5356-67059',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.45609,12.38639',
  id: '47.45609,12.38639'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Gablenzgasse 109',
  AddressLine2: '',
  PostCode: 1150,
  TownCity: 'Wien',
  Latitude: '4.820.685.000',
  Longitude: '1.631.374.000',
  Telephone: '+43-1-9821210',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.20685,16.31374',
  id: '48.20685,16.31374'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Fernpaß Bundesstraße 1',
  AddressLine2: '',
  PostCode: 6465,
  TownCity: 'Nassereith',
  Latitude: '4.732.720.000',
  Longitude: '1.081.980.000',
  Telephone: '+43-5265-5898',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.3272,10.8198',
  id: '47.3272,10.8198'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Langenloiser Straße 85',
  AddressLine2: '',
  PostCode: 3542,
  TownCity: 'Gföhl',
  Latitude: '4.851.208.000',
  Longitude: '1.551.044.000',
  Telephone: '+43-2716-6064',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.51208,15.51044',
  id: '48.51208,15.51044'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: '10. Oktober-Straße 23',
  AddressLine2: '',
  PostCode: 9560,
  TownCity: 'Feldkirchen',
  Latitude: '4.672.299.000',
  Longitude: '1.409.689.000',
  Telephone: '+43-4276-2230',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.72299,14.09689',
  id: '46.72299,14.09689'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Keutschacher Straße 135',
  AddressLine2: '',
  PostCode: 9073,
  TownCity: 'Viktring',
  Latitude: '4.659.189.000',
  Longitude: '1.427.158.000',
  Telephone: '+43-463-281222',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.59189,14.27158',
  id: '46.59189,14.27158'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Gonowetz 15',
  AddressLine2: '',
  PostCode: 9150,
  TownCity: 'Bleiburg',
  Latitude: '4.657.613.000',
  Longitude: '1.477.537.000',
  Telephone: '+43-4235-2007',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.57613,14.77537',
  id: '46.57613,14.77537'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: '10. Oktober-Straße 11',
  AddressLine2: '',
  PostCode: 9330,
  TownCity: 'Althofen',
  Latitude: '4.687.159.000',
  Longitude: '1.446.729.000',
  Telephone: '+43-4262-27207-3',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.87159,14.46729',
  id: '46.87159,14.46729'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Klagenfurter Straße 16',
  AddressLine2: '',
  PostCode: 9400,
  TownCity: 'Wolfsberg',
  Latitude: '4.682.775.000',
  Longitude: '1.483.911.000',
  Telephone: '+43-4352-2529',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.82775,14.83911',
  id: '46.82775,14.83911'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Grazer Straße 42',
  AddressLine2: '',
  PostCode: 8200,
  TownCity: 'Gleisdorf',
  Latitude: '4.710.570.000',
  Longitude: '1.570.097.000',
  Telephone: '+43-3112-2104',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.1057,15.70097',
  id: '47.1057,15.70097'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hauptstraße 39',
  AddressLine2: '',
  PostCode: 9871,
  TownCity: 'Seeboden',
  Latitude: '4.681.713.000',
  Longitude: '1.351.108.000',
  Telephone: '+43-4762-81324',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.81713,13.51108',
  id: '46.81713,13.51108'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Iseltaler Straße 30',
  AddressLine2: '',
  PostCode: 9900,
  TownCity: 'Lienz',
  Latitude: '4.683.172.000',
  Longitude: '1.275.363.000',
  Telephone: '+43-4852-63090',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.83172,12.75363',
  id: '46.83172,12.75363'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Tiroler Straße  10',
  AddressLine2: '',
  PostCode: 9900,
  TownCity: 'Lienz',
  Latitude: '4.683.095.000',
  Longitude: '1.277.553.000',
  Telephone: '+43-4852-63060',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.83095,12.77553',
  id: '46.83095,12.77553'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Schwefel 53',
  AddressLine2: '',
  PostCode: 6850,
  TownCity: 'Dornbirn',
  Latitude: '4.742.804.000',
  Longitude: '973.995.000',
  Telephone: '+43-5572-25763',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.42804,9.73995',
  id: '47.42804,9.73995'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wallenmahd 1',
  AddressLine2: '',
  PostCode: 6850,
  TownCity: 'Dornbirn',
  Latitude: '4.739.618.000',
  Longitude: '973.126.000',
  Telephone: '+43-5572-23532',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.39618,9.73126',
  id: '47.39618,9.73126'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Königshofstraße 61',
  AddressLine2: '',
  PostCode: 6804,
  TownCity: 'Feldkirch',
  Latitude: '4.726.779.000',
  Longitude: '961.507.000',
  Telephone: '+43-5522-73589',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.26779,9.61507',
  id: '47.26779,9.61507'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Feldkircher Straße 47',
  AddressLine2: '',
  PostCode: 6820,
  TownCity: 'Frastanz',
  Latitude: '4.722.452.000',
  Longitude: '961.744.000',
  Telephone: '+43-5522-51109',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.22452,9.61744',
  id: '47.22452,9.61744'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Danöfen 126',
  AddressLine2: '',
  PostCode: 6754,
  TownCity: 'Klösterle',
  Latitude: '4.712.939.000',
  Longitude: '1.006.077.000',
  Telephone: '+43-5582-592',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.12939,10.06077',
  id: '47.12939,10.06077'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hofriedenstraße 17',
  AddressLine2: '',
  PostCode: 6911,
  TownCity: 'Lochau',
  Latitude: '4.753.470.000',
  Longitude: '975.204.000',
  Telephone: '+43-5574-43674',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.5347,9.75204',
  id: '47.5347,9.75204'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Grindlstraße 14',
  AddressLine2: '',
  PostCode: 6890,
  TownCity: 'Lustenau',
  Latitude: '4.742.005.000',
  Longitude: '965.871.000',
  Telephone: '+43-5577-83390',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.42005,9.65871',
  id: '47.42005,9.65871'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hagstraße 27',
  AddressLine2: '',
  PostCode: 6890,
  TownCity: 'Lustenau',
  Latitude: '4.744.937.000',
  Longitude: '966.475.000',
  Telephone: '+43-5577-86782',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.44937,9.66475',
  id: '47.44937,9.66475'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bundesstraße 77',
  AddressLine2: '',
  PostCode: 6710,
  TownCity: 'Nenzing',
  Latitude: '4.718.918.000',
  Longitude: '970.154.000',
  Telephone: '+43-5525-62383',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.18918,9.70154',
  id: '47.18918,9.70154'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Walgaustraße 68',
  AddressLine2: '',
  PostCode: 6712,
  TownCity: 'Thüringen',
  Latitude: '4.719.930.000',
  Longitude: '975.582.000',
  Telephone: '+43-5550-3399',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.1993,9.75582',
  id: '47.1993,9.75582'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Dornbirner Straße 22',
  AddressLine2: '',
  PostCode: 6922,
  TownCity: 'Wolfurt',
  Latitude: '4.745.410.000',
  Longitude: '975.495.000',
  Telephone: '+43-5574-71123',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4541,9.75495',
  id: '47.4541,9.75495'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Salzburger Straße 55',
  AddressLine2: '',
  PostCode: 6300,
  TownCity: 'Wörgl',
  Latitude: '4.749.028.000',
  Longitude: '1.207.354.000',
  Telephone: '+43-5332-73609',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.49028,12.07354',
  id: '47.49028,12.07354'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'An der Umfahrungsstraße West',
  AddressLine2: '',
  PostCode: 6280,
  TownCity: 'Zell am Ziller',
  Latitude: '4.722.926.000',
  Longitude: '1.189.207.000',
  Telephone: '+43-5282-3115',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.22926,11.89207',
  id: '47.22926,11.89207'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Arlbergstraße 135',
  AddressLine2: '',
  PostCode: 6900,
  TownCity: 'Bregenz',
  Latitude: '4.748.778.000',
  Longitude: '973.324.000',
  Telephone: '+43-5574-71115',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.48778,9.73324',
  id: '47.48778,9.73324'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bahnhofstraße 133',
  AddressLine2: '',
  PostCode: 8250,
  TownCity: 'Vorau',
  Latitude: '4.740.488.000',
  Longitude: '1.588.963.000',
  Telephone: '+43-3337-3246',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.40488,15.88963',
  id: '47.40488,15.88963'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: '50',
  AddressLine2: '',
  PostCode: 8424,
  TownCity: 'Landscha an der Mur',
  Latitude: '4.676.379.000',
  Longitude: '1.558.034.000',
  Telephone: '+43-3452-8204112',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.76379,15.58034',
  id: '46.76379,15.58034'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Klagenfurter Straße 230',
  AddressLine2: '',
  PostCode: 9462,
  TownCity: 'Bad St. Leonhard im Lavanttal',
  Latitude: '4.696.214.000',
  Longitude: '1.479.496.000',
  Telephone: '+43-4350-2361',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.96214,14.79496',
  id: '46.96214,14.79496'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Gailtalstraße 12',
  AddressLine2: '',
  PostCode: 9620,
  TownCity: 'Hermagor',
  Latitude: '4.662.964.000',
  Longitude: '1.337.877.000',
  Telephone: '+43-4282-2142',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.62964,13.37877',
  id: '46.62964,13.37877'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Arnbach 75b',
  AddressLine2: '',
  PostCode: 9920,
  TownCity: 'Sillian',
  Latitude: '4.674.021.000',
  Longitude: '1.237.517.000',
  Telephone: '+43-4842-6343',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.74021,12.37517',
  id: '46.74021,12.37517'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Straße 79',
  AddressLine2: '',
  PostCode: 8605,
  TownCity: 'Kapfenberg',
  Latitude: '4.744.692.000',
  Longitude: '1.530.208.000',
  Telephone: '+43-3862-22614',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.44692,15.30208',
  id: '47.44692,15.30208'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Krottendorf 3b',
  AddressLine2: '',
  PostCode: 8605,
  TownCity: 'Kapfenberg',
  Latitude: '4.745.024.000',
  Longitude: '1.533.985.000',
  Telephone: '+43-3862-33825',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.45024,15.33985',
  id: '47.45024,15.33985'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Schwöbing 70',
  AddressLine2: '',
  PostCode: 8665,
  TownCity: 'Langenwang',
  Latitude: '4.755.876.000',
  Longitude: '1.559.092.000',
  Telephone: '+43-3854-6125',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.55876,15.59092',
  id: '47.55876,15.59092'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Packer Straße 6',
  AddressLine2: '',
  PostCode: 8501,
  TownCity: 'Lieboch',
  Latitude: '4.697.230.000',
  Longitude: '1.536.164.000',
  Telephone: '+43-3136-61808',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.9723,15.36164',
  id: '46.9723,15.36164'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Keltensiedlung 95',
  AddressLine2: '',
  PostCode: 8850,
  TownCity: 'Murau',
  Latitude: '4.711.225.000',
  Longitude: '1.418.230.000',
  Telephone: '+43-3532-3687',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.11225,14.1823',
  id: '47.11225,14.1823'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Lahnsattel Bundesstraße',
  AddressLine2: '',
  PostCode: 8692,
  TownCity: 'Neuberg',
  Latitude: '4.765.921.000',
  Longitude: '1.558.941.000',
  Telephone: '+43-3857-8274',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.65921,15.58941',
  id: '47.65921,15.58941'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Kärntner Straße 22',
  AddressLine2: '',
  PostCode: 8820,
  TownCity: 'Neumarkt',
  Latitude: '4.706.752.000',
  Longitude: '1.443.023.000',
  Telephone: '+43-3584-2167',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.06752,14.43023',
  id: '47.06752,14.43023'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Grazer Straße 123',
  AddressLine2: '',
  PostCode: 7400,
  TownCity: 'Oberwart',
  Latitude: '4.728.297.000',
  Longitude: '1.618.523.000',
  Telephone: '+43-3352-31380',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.28297,16.18523',
  id: '47.28297,16.18523'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn Schwerpunktparkplatz Weer',
  AddressLine2: '',
  PostCode: 6114,
  TownCity: 'Weer',
  Latitude: '4.731.835.000',
  Longitude: '1.165.378.000',
  Telephone: '+43-5224-67477',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.31835,11.65378',
  id: '47.31835,11.65378'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Börsegasse 11',
  AddressLine2: '',
  PostCode: 1010,
  TownCity: 'Wien',
  Latitude: '4.821.523.000',
  Longitude: '1.636.718.000',
  Telephone: '+43-1-534990',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.21523,16.36718',
  id: '48.21523,16.36718'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Passauer Straße 23',
  AddressLine2: '',
  PostCode: 4143,
  TownCity: 'Neustift im Mühlkreis',
  Latitude: '4.852.955.000',
  Longitude: '1.374.859.000',
  Telephone: '+43-7284-23350',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.52955,13.74859',
  id: '48.52955,13.74859'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Rheinstraße 24',
  AddressLine2: '',
  PostCode: 6971,
  TownCity: 'Hard',
  Latitude: '4.748.752.000',
  Longitude: '969.962.000',
  Telephone: '+43-5574-61498',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.48752,9.69962',
  id: '47.48752,9.69962'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Lastenstraße 38',
  AddressLine2: '',
  PostCode: 9300,
  TownCity: 'Sankt Veit an der Glan',
  Latitude: '4.676.591.000',
  Longitude: '1.436.587.000',
  Telephone: '+43-4212-30845',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.76591,14.36587',
  id: '46.76591,14.36587'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Minnesheimstraße 10',
  AddressLine2: '',
  PostCode: 5023,
  TownCity: 'Salzburg',
  Latitude: '4.781.309.000',
  Longitude: '1.306.950.000',
  Telephone: '+43-662-645239',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.81309,13.0695',
  id: '47.81309,13.0695'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Altenmarkt 218',
  AddressLine2: '',
  PostCode: 8280,
  TownCity: 'Fürstenfeld',
  Latitude: '4.706.559.000',
  Longitude: '1.603.680.000',
  Telephone: '+43-3382-51019',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.06559,16.0368',
  id: '47.06559,16.0368'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hall 360',
  AddressLine2: '',
  PostCode: 8911,
  TownCity: 'Admont',
  Latitude: '4.758.208.000',
  Longitude: '1.445.614.000',
  Telephone: '+43-3613-2224',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.58208,14.45614',
  id: '47.58208,14.45614'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Triester Straße 38',
  AddressLine2: '',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '4.817.740.000',
  Longitude: '1.635.653.000',
  Telephone: '+43-1-6046279',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.1774,16.35653',
  id: '48.1774,16.35653'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Eisenfeldstraße 2',
  AddressLine2: '',
  PostCode: 4600,
  TownCity: 'Wels',
  Latitude: '4.816.575.000',
  Longitude: '1.403.910.000',
  Telephone: '+43-7242-77702',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.16575,14.0391',
  id: '48.16575,14.0391'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'St. Peter Hauptstraße 228',
  AddressLine2: '',
  PostCode: 8042,
  TownCity: 'Graz',
  Latitude: '4.704.384.000',
  Longitude: '1.548.696.000',
  Telephone: '+43-316-403829',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.04384,15.48696',
  id: '47.04384,15.48696'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hauptstraße 232',
  AddressLine2: '',
  PostCode: 9210,
  TownCity: 'Pörtschach',
  Latitude: '4.663.668.000',
  Longitude: '1.413.709.000',
  Telephone: '+43-4272-2587',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.63668,14.13709',
  id: '46.63668,14.13709'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bierbaumerstraße 3',
  AddressLine2: '',
  PostCode: 8401,
  TownCity: 'Kalsdorf bei Graz',
  Latitude: '4.697.221.000',
  Longitude: '1.547.013.000',
  Telephone: '+43-3135-53343',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.97221,15.47013',
  id: '46.97221,15.47013'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wachbergstraße 3',
  AddressLine2: '',
  PostCode: 3390,
  TownCity: 'Melk',
  Latitude: '4.821.918.000',
  Longitude: '1.534.834.000',
  Telephone: '+43-2752-52933',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.21918,15.34834',
  id: '48.21918,15.34834'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Schnann-Gewerbezone 150',
  AddressLine2: '',
  PostCode: 6574,
  TownCity: 'Pettneu am Arlberg',
  Latitude: '4.714.890.000',
  Longitude: '1.037.430.000',
  Telephone: '+43-5447-20111',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.1489,10.3743',
  id: '47.1489,10.3743'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Maria-Gailer-Straße 53',
  AddressLine2: '',
  PostCode: 9500,
  TownCity: 'Villach',
  Latitude: '4.660.434.000',
  Longitude: '1.387.092.000',
  Telephone: '+43-4242-311180',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.60434,13.87092',
  id: '46.60434,13.87092'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Gleinkerseestraße 42',
  AddressLine2: '',
  PostCode: 4580,
  TownCity: 'Windischgarsten',
  Latitude: '4.771.611.000',
  Longitude: '1.431.597.000',
  Telephone: '+43-7562-20756',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.71611,14.31597',
  id: '47.71611,14.31597'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Schlöglstraße 77',
  AddressLine2: '',
  PostCode: 6060,
  TownCity: 'Hall in Tirol',
  Latitude: '4.727.348.000',
  Longitude: '1.147.347.000',
  Telephone: '+43-5223-43573',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.27348,11.47347',
  id: '47.27348,11.47347'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Ötztaler Bundesstraße 15',
  AddressLine2: '',
  PostCode: 6430,
  TownCity: 'Ötztal-Bahnhof',
  Latitude: '4.722.949.000',
  Longitude: '1.085.657.000',
  Telephone: '+43-5266-88158',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.22949,10.85657',
  id: '47.22949,10.85657'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Andechsstraße 83',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.726.531.000',
  Longitude: '1.142.629.000',
  Telephone: '+43-512-346219',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.26531,11.42629',
  id: '47.26531,11.42629'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hof 978',
  AddressLine2: '',
  PostCode: 6861,
  TownCity: 'Alberschwende',
  Latitude: '4.745.076.000',
  Longitude: '982.779.000',
  Telephone: '+43-5579-85796',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.45076,9.82779',
  id: '47.45076,9.82779'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Thalerhofstrasse 70',
  AddressLine2: '',
  PostCode: 8141,
  TownCity: 'Unterpremstätten',
  Latitude: '4.697.377.000',
  Longitude: '1.542.602.000',
  Telephone: '+43(0)3136/55474',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.97377,15.42602',
  id: '46.97377,15.42602'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Fachmarktstrasse/Gewerbepark',
  AddressLine2: '',
  PostCode: 4655,
  TownCity: 'Vorchdorf',
  Latitude: '4.801.068.000',
  Longitude: '1.392.471.000',
  Telephone: '+43(0)7614/51845',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.01068,13.92471',
  id: '48.01068,13.92471'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bahnhofstraße 11a',
  AddressLine2: '',
  PostCode: 7471,
  TownCity: 'Rechnitz',
  Latitude: '4.730.261.000',
  Longitude: '1.644.242.000',
  Telephone: '+43-3363-7936918',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.30261,16.44242',
  id: '47.30261,16.44242'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Halbenrainerstraße  34',
  AddressLine2: '',
  PostCode: 8490,
  TownCity: 'Bad Radkersburg',
  Latitude: '4.669.205.000',
  Longitude: '1.597.730.000',
  Telephone: '+43-3476-29842',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.69205,15.9773',
  id: '46.69205,15.9773'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Sterngasse 13',
  AddressLine2: '',
  PostCode: 1230,
  TownCity: 'Wien',
  Latitude: '4.814.891.000',
  Longitude: '1.633.836.000',
  Telephone: '+43-1-6672130',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.14891,16.33836',
  id: '48.14891,16.33836'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'S1 KM 9',
  AddressLine2: '',
  PostCode: 2320,
  TownCity: 'Schwechat',
  Latitude: '4.812.249.000',
  Longitude: '1.643.870.000',
  Telephone: '+43-1-7062651',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.12249,16.4387',
  id: '48.12249,16.4387'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Völkermarkter Straße 272',
  AddressLine2: '',
  PostCode: 9020,
  TownCity: 'Klagenfurt',
  Latitude: '4.662.956.000',
  Longitude: '1.435.681.000',
  Telephone: '+43-463-34302',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.62956,14.35681',
  id: '46.62956,14.35681'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Mühlfeldstraße  2',
  AddressLine2: '',
  PostCode: 3441,
  TownCity: 'Einsiedl',
  Latitude: '4.826.998.000',
  Longitude: '1.597.126.000',
  Telephone: '+43-2274-21017',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.26998,15.97126',
  id: '48.26998,15.97126'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Industriestrasse 6',
  AddressLine2: '',
  PostCode: 9601,
  TownCity: 'Arnoldstein - EuroTruck',
  Latitude: '4.655.944.000',
  Longitude: '1.369.722.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.55944,13.69722',
  id: '46.55944,13.69722'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Andiesen 13',
  AddressLine2: '',
  PostCode: 4774,
  TownCity: 'Suben - EuroTruck',
  Latitude: '4.839.518.000',
  Longitude: '1.342.651.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.39518,13.42651',
  id: '48.39518,13.42651'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bayernstraße  24',
  AddressLine2: '',
  PostCode: 5071,
  TownCity: 'Wals Siezenheim - EuroTruck',
  Latitude: '4.781.733.000',
  Longitude: '1.298.016.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.81733,12.98016',
  id: '47.81733,12.98016'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Endach 34',
  AddressLine2: '',
  PostCode: 6330,
  TownCity: 'Kufstein - EuroTruck',
  Latitude: '4.756.574.000',
  Longitude: '1.215.013.000',
  Telephone: '+43-5372-90828',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.56574,12.15013',
  id: '47.56574,12.15013'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Rasthausstraße 3',
  AddressLine2: '',
  PostCode: 4300,
  TownCity: 'Sankt Valentin - EuroTruck',
  Latitude: '4.819.676.000',
  Longitude: '1.452.632.000',
  Telephone: '+43-7435-58885',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.19676,14.52632',
  id: '48.19676,14.52632'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Dorfstrasse 29',
  AddressLine2: '',
  PostCode: 6344,
  TownCity: 'Walchsee',
  Latitude: '476.503.760',
  Longitude: '123.133.020',
  Telephone: '+435374562031',
  WeekDayFrom: '07:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.650376,12.313302',
  id: '47.650376,12.313302'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Weidlingerstrasse 3a',
  AddressLine2: 'NOE',
  PostCode: 3400,
  TownCity: 'Klosterneuburg',
  Latitude: '482.966.000',
  Longitude: '163.339.000',
  Telephone: '+43224332074',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.2966,16.3339',
  id: '48.2966,16.3339'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Huette 15',
  AddressLine2: '',
  PostCode: 6345,
  TownCity: 'Koessen',
  Latitude: '476.618.101',
  Longitude: '124.138.757',
  Telephone: '+4353756394',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.6618101,12.4138757',
  id: '47.6618101,12.4138757'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Salzburger Str.11',
  AddressLine2: 'OOE',
  PostCode: 5280,
  TownCity: 'Braunau',
  Latitude: '482.548.623',
  Longitude: '130.363.873',
  Telephone: '800080645',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Yes',
  location: '48.2548623,13.0363873',
  id: '48.2548623,13.0363873'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Grazer Vorstadt 11',
  AddressLine2: '',
  PostCode: 8570,
  TownCity: 'Voitsberg',
  Latitude: '4.704.770.000',
  Longitude: '1.515.420.000',
  Telephone: '+43(0)3142/22287',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.0477,15.1542',
  id: '47.0477,15.1542'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstraße 130',
  AddressLine2: '',
  PostCode: 2102,
  TownCity: 'Bisamberg',
  Latitude: '4.833.315.000',
  Longitude: '1.634.447.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.33315,16.34447',
  id: '48.33315,16.34447'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'S 6 Semmering Schnellstr.',
  AddressLine2: '',
  PostCode: 2641,
  TownCity: 'Schottwien',
  Latitude: '4.765.287.000',
  Longitude: '1.587.146.000',
  Telephone: '+43(0)2663/8872',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.65287,15.87146',
  id: '47.65287,15.87146'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Wolfgangseestr. 9',
  AddressLine2: '',
  PostCode: 5322,
  TownCity: 'Hof b. Salzburg',
  Latitude: '478.169.976',
  Longitude: '132.281.001',
  Telephone: '+4362292234',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '18:00',
  IsOpen24Hours: 'No',
  location: '47.8169976,13.2281001',
  id: '47.8169976,13.2281001'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstr. 87',
  AddressLine2: '',
  PostCode: 2640,
  TownCity: 'Gloggnitz',
  Latitude: '4.767.302.400',
  Longitude: '1.595.594.210',
  Telephone: '+43(0)2662/42624',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.673024,15.9559421',
  id: '47.673024,15.9559421'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: '',
  AddressLine1: 'ST.MARGARETHEN 151B',
  AddressLine2: '',
  PostCode: 6220,
  TownCity: 'BUCH BEI JENBACH.',
  Latitude: '473.833.160',
  Longitude: '117.718.310',
  Telephone: '6641011348',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.383316,11.771831',
  id: '47.383316,11.771831'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Klagenfurter Strasse 42 A',
  AddressLine2: '',
  PostCode: 9100,
  TownCity: 'Vlkermarkt',
  Latitude: '4.666.048.000',
  Longitude: '1.461.967.000',
  Telephone: '+43(0)4232/2568',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.66048,14.61967',
  id: '46.66048,14.61967'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Dorfstrasse 1',
  AddressLine2: '',
  PostCode: 6142,
  TownCity: 'Mieders',
  Latitude: '4.717.122.000',
  Longitude: '1.138.392.000',
  Telephone: '+43(0)5225/62664',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.17122,11.38392',
  id: '47.17122,11.38392'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstr. 3',
  AddressLine2: '',
  PostCode: 2301,
  TownCity: 'Wittau',
  Latitude: '4.818.391.930',
  Longitude: '1.659.570.230',
  Telephone: '+43(0)2215/3130',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1839193,16.5957023',
  id: '48.1839193,16.5957023'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Franz Josef Strasse 20',
  AddressLine2: '',
  PostCode: 8200,
  TownCity: 'Gleisdorf',
  Latitude: '4.710.214.500',
  Longitude: '1.571.351.000',
  Telephone: '+43(0)311/23225',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.102145,15.71351',
  id: '47.102145,15.71351'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstr. 2',
  AddressLine2: '',
  PostCode: 2632,
  TownCity: 'Wimpassing',
  Latitude: '4.770.430.210',
  Longitude: '1.603.503.790',
  Telephone: '+43(0)2630/38778',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.7043021,16.0350379',
  id: '47.7043021,16.0350379'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Halleiner Strasse 819',
  AddressLine2: '',
  PostCode: 5412,
  TownCity: 'Puch-Hallein',
  Latitude: '4.772.200.000',
  Longitude: '1.309.018.000',
  Telephone: '+43(0)6245/83127',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.722,13.09018',
  id: '47.722,13.09018'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Grenzackerstrasse 6-8',
  AddressLine2: '',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '4.816.209.000',
  Longitude: '1.638.060.000',
  Telephone: '+43(0)1/6073149',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.16209,16.3806',
  id: '48.16209,16.3806'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstrasse 83',
  AddressLine2: '',
  PostCode: 9587,
  TownCity: 'Hart b. Villach',
  Latitude: '4.655.596.000',
  Longitude: '1.379.205.000',
  Telephone: '+43(0)4257/2779',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.55596,13.79205',
  id: '46.55596,13.79205'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bad Eisenkappel',
  AddressLine2: '',
  PostCode: 9135,
  TownCity: 'Bad Eisenkappel 196',
  Latitude: '4.648.980.000',
  Longitude: '1.458.980.000',
  Telephone: '+43(0)4238/305',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.4898,14.5898',
  id: '46.4898,14.5898'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: '',
  AddressLine1: 'HALLERSTRASSE 164',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'INNSBRUCK',
  Latitude: '472.754.128',
  Longitude: '114.340.339',
  Telephone: '6641011348',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.2754128,11.4340339',
  id: '47.2754128,11.4340339'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Althanstrasse 14',
  AddressLine2: '',
  PostCode: 1090,
  TownCity: 'Wien',
  Latitude: '4.822.910.000',
  Longitude: '1.635.890.000',
  Telephone: '+43(0)1/3192664',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.2291,16.3589',
  id: '48.2291,16.3589'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Südautobahn Ri Italien',
  AddressLine2: '',
  PostCode: 9601,
  TownCity: 'Arnoldstein',
  Latitude: '4.655.072.000',
  Longitude: '1.368.218.000',
  Telephone: '+43(0)4255/2875',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.55072,13.68218',
  id: '46.55072,13.68218'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Industriestraße 49',
  AddressLine2: '',
  PostCode: 7400,
  TownCity: 'Oberwart',
  Latitude: '4.730.549.000',
  Longitude: '1.619.296.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.30549,16.19296',
  id: '47.30549,16.19296'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Andiesen 15',
  AddressLine2: '',
  PostCode: 4774,
  TownCity: 'St.Marienkirchen/Schärding',
  Latitude: '4.839.454.000',
  Longitude: '1.342.673.000',
  Telephone: '+43(0)7711/33045',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.39454,13.42673',
  id: '48.39454,13.42673'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Ötscherlandstrasse 48',
  AddressLine2: '',
  PostCode: 3292,
  TownCity: 'Gaming',
  Latitude: '4.792.944.000',
  Longitude: '1.508.818.000',
  Telephone: '+43(0)7485/97398',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.92944,15.08818',
  id: '47.92944,15.08818'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Zillertaler Schnellstrasse 80',
  AddressLine2: '',
  PostCode: 6272,
  TownCity: 'Stumm/Zillertal',
  Latitude: '4.727.754.000',
  Longitude: '1.188.779.000',
  Telephone: '+43(0)5283/29242',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.27754,11.88779',
  id: '47.27754,11.88779'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Stubaitalstraße 10',
  AddressLine2: '',
  PostCode: 6142,
  TownCity: 'Mieders',
  Latitude: '4.717.688.000',
  Longitude: '1.139.159.000',
  Telephone: '+43(0)5225/62893',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.17688,11.39159',
  id: '47.17688,11.39159'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Treietstrasse 25',
  AddressLine2: '',
  PostCode: 6833,
  TownCity: 'Klaus',
  Latitude: '4.730.529.000',
  Longitude: '964.300.000',
  Telephone: '+43(0)5523/62420',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.30529,9.643',
  id: '47.30529,9.643'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Birkenweg 171',
  AddressLine2: '',
  PostCode: 6460,
  TownCity: 'Imst',
  Latitude: '4.723.389.000',
  Longitude: '1.073.470.000',
  Telephone: '+43(0)5412/66889',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.23389,10.7347',
  id: '47.23389,10.7347'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Millstätter Bundesstrasse 21',
  AddressLine2: '',
  PostCode: 9545,
  TownCity: 'Radenthein',
  Latitude: '4.679.807.000',
  Longitude: '1.371.041.000',
  Telephone: '+43(0)4246/2747',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.79807,13.71041',
  id: '46.79807,13.71041'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Fürstenf.Schnellstrasse 191',
  AddressLine2: '',
  PostCode: 8280,
  TownCity: 'Fürstenfeld/Altenm.',
  Latitude: '4.704.979.600',
  Longitude: '1.607.404.400',
  Telephone: '+43(0)3382/53601',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.049796,16.074044',
  id: '47.049796,16.074044'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Autobahnstation Soellheim',
  AddressLine2: '',
  PostCode: 5300,
  TownCity: 'Hallwang',
  Latitude: '4.783.850.000',
  Longitude: '1.307.592.000',
  Telephone: '+43(0)6626/61246',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.8385,13.07592',
  id: '47.8385,13.07592'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Leopoldauerstr. 180',
  AddressLine2: '',
  PostCode: 1210,
  TownCity: 'Wien',
  Latitude: '4.826.240.000',
  Longitude: '1.643.590.000',
  Telephone: '+43(0)1/2582569',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.2624,16.4359',
  id: '48.2624,16.4359'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Simon Scheiner Str. 6',
  AddressLine2: '',
  PostCode: 2136,
  TownCity: 'Laa an der Thaya',
  Latitude: '4.871.853.000',
  Longitude: '1.639.589.000',
  Telephone: '+43(0)2522/2406',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.71853,16.39589',
  id: '48.71853,16.39589'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Jägerstrasse 60',
  AddressLine2: '',
  PostCode: 1200,
  TownCity: 'Wien',
  Latitude: '4.823.488.000',
  Longitude: '1.637.020.000',
  Telephone: '+43(0)1/3322304',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.23488,16.3702',
  id: '48.23488,16.3702'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wagramer Straße  94 DZ Garage 3',
  AddressLine2: '',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '',
  Longitude: '',
  Telephone: '+43-1-2021131',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '0,0',
  id: '0,0'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: '',
  AddressLine1: 'FOEHRENWALD 498',
  AddressLine2: '',
  PostCode: 6100,
  TownCity: 'SEEFELD',
  Latitude: '473.279.656',
  Longitude: '111.986.662',
  Telephone: '6641011348',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.3279656,11.1986662',
  id: '47.3279656,11.1986662'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Maierhof 13',
  AddressLine2: '',
  PostCode: 8443,
  TownCity: 'Gleinstätten',
  Latitude: '4.676.176.000',
  Longitude: '1.540.083.000',
  Telephone: '+43(0)3457/2247',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.76176,15.40083',
  id: '46.76176,15.40083'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wildbachlerstrae 52',
  AddressLine2: '',
  PostCode: 6341,
  TownCity: 'Ebbs',
  Latitude: '4.763.349.000',
  Longitude: '1.221.413.000',
  Telephone: '+43(0)5373/42384',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.63349,12.21413',
  id: '47.63349,12.21413'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bruggen 352',
  AddressLine2: '',
  PostCode: 6444,
  TownCity: 'Längenfeld',
  Latitude: '4.702.488.000',
  Longitude: '1.099.511.000',
  Telephone: '+43(0)5253/64881',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.02488,10.99511',
  id: '47.02488,10.99511'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 48',
  AddressLine2: '',
  PostCode: 3002,
  TownCity: 'Purkersdorf',
  Latitude: '4.820.566.000',
  Longitude: '1.619.258.000',
  Telephone: '+43(0)2231/62135',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.20566,16.19258',
  id: '48.20566,16.19258'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Liebenauer Hauptstraße 176',
  AddressLine2: '',
  PostCode: 8041,
  TownCity: 'Graz Liebenau',
  Latitude: '4.703.310.000',
  Longitude: '1.546.406.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.0331,15.46406',
  id: '47.0331,15.46406'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstrasse 14',
  AddressLine2: '',
  PostCode: 6068,
  TownCity: 'Mils',
  Latitude: '4.728.125.000',
  Longitude: '1.153.412.000',
  Telephone: '+43(0)5223/55746',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.28125,11.53412',
  id: '47.28125,11.53412'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mühltal 20',
  AddressLine2: '',
  PostCode: 6305,
  TownCity: 'Itter',
  Latitude: '4.748.211.000',
  Longitude: '1.214.705.000',
  Telephone: '+43(0)5332/23398',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.48211,12.14705',
  id: '47.48211,12.14705'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Tiroler Strasse 141',
  AddressLine2: '',
  PostCode: 9500,
  TownCity: 'Villach',
  Latitude: '4.663.186.000',
  Longitude: '1.382.397.000',
  Telephone: '+43(0)4242/55901',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.63186,13.82397',
  id: '46.63186,13.82397'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Linzerstr. 12',
  AddressLine2: '',
  PostCode: 4840,
  TownCity: 'Vöcklabruck',
  Latitude: '4.800.338.000',
  Longitude: '1.365.882.000',
  Telephone: '+43(0)7672/72350',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.00338,13.65882',
  id: '48.00338,13.65882'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Lindauerstrasse 38',
  AddressLine2: '',
  PostCode: 6911,
  TownCity: 'Lochau',
  Latitude: '4.752.680.000',
  Longitude: '974.612.000',
  Telephone: '+43(0)5574/43174',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.5268,9.74612',
  id: '47.5268,9.74612'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Salzburgerstrasse 26',
  AddressLine2: '',
  PostCode: 6112,
  TownCity: 'Wattens',
  Latitude: '4.729.203.000',
  Longitude: '1.159.930.000',
  Telephone: '+43(0)5224/52577',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.29203,11.5993',
  id: '47.29203,11.5993'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Biberhaufenweg 115',
  AddressLine2: '',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '4.820.206.000',
  Longitude: '1.647.318.000',
  Telephone: '+43(0)1/2823210',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.20206,16.47318',
  id: '48.20206,16.47318'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Ötztaler Höhe',
  AddressLine2: '',
  PostCode: 6430,
  TownCity: 'Ötztaler Bahnhof',
  Latitude: '4.723.157.000',
  Longitude: '1.085.312.000',
  Telephone: '+43(0)5266/88361',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.23157,10.85312',
  id: '47.23157,10.85312'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mönchhofer Strasse 10',
  AddressLine2: '',
  PostCode: 7132,
  TownCity: 'Frauenkirchen',
  Latitude: '4.784.322.000',
  Longitude: '1.693.024.000',
  Telephone: '+43(0)2172/7100',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.84322,16.93024',
  id: '47.84322,16.93024'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Raststation Süd-Ostautobahn A3  KM',
  AddressLine2: '',
  PostCode: 7053,
  TownCity: 'Hornstein',
  Latitude: '4.787.799.000',
  Longitude: '1.641.503.000',
  Telephone: '+43-2689-208540',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.87799,16.41503',
  id: '47.87799,16.41503'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gasteiner Bundesstrasse 167',
  AddressLine2: '',
  PostCode: 5630,
  TownCity: 'Bad Hofgastein',
  Latitude: '4.717.579.000',
  Longitude: '1.310.021.000',
  Telephone: '+43(0)6432/6123',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.17579,13.10021',
  id: '47.17579,13.10021'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Raxstrasse 38',
  AddressLine2: '',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '4.816.659.000',
  Longitude: '1.635.804.000',
  Telephone: '+43(0)1/6041060',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.16659,16.35804',
  id: '48.16659,16.35804'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Tiroler Strasse 2',
  AddressLine2: '',
  PostCode: 6424,
  TownCity: 'Silz',
  Latitude: '4.726.906.000',
  Longitude: '1.094.032.000',
  Telephone: '+43(0)5263/6921',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.26906,10.94032',
  id: '47.26906,10.94032'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Dorf 23d',
  AddressLine2: '',
  PostCode: 6200,
  TownCity: 'Wiesing',
  Latitude: '4.740.400.000',
  Longitude: '1.180.423.000',
  Telephone: '+43(0)5244/61546',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.404,11.80423',
  id: '47.404,11.80423'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Grabenweg 73',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.726.587.000',
  Longitude: '1.144.258.000',
  Telephone: '+43(0)5022776027',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.26587,11.44258',
  id: '47.26587,11.44258'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 46',
  AddressLine2: '',
  PostCode: 2100,
  TownCity: 'Korneuburg',
  Latitude: '4.833.762.000',
  Longitude: '1.633.970.000',
  Telephone: '+43(0)2262/72275',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.33762,16.3397',
  id: '48.33762,16.3397'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Schwarzach 6',
  AddressLine2: '',
  PostCode: 6351,
  TownCity: 'Scheffau-Blaiken',
  Latitude: '4.752.040.000',
  Longitude: '1.225.374.000',
  Telephone: '+43(0)5358/8464',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.5204,12.25374',
  id: '47.5204,12.25374'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Langgasse 95',
  AddressLine2: '',
  PostCode: 6460,
  TownCity: 'Imst',
  Latitude: '4.722.655.000',
  Longitude: '1.074.782.000',
  Telephone: '+43(0)5412/62730',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.22655,10.74782',
  id: '47.22655,10.74782'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Salzburgerstrasse 41',
  AddressLine2: '',
  PostCode: 4690,
  TownCity: 'Schwanenstadt',
  Latitude: '4.804.835.000',
  Longitude: '1.376.219.000',
  Telephone: '+43(0)7673/30911',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.04835,13.76219',
  id: '48.04835,13.76219'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Italienerstr. 57',
  AddressLine2: '',
  PostCode: 9500,
  TownCity: 'Villach',
  Latitude: '4.660.480.000',
  Longitude: '1.384.090.000',
  Telephone: '+43(0)4242/37200',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.6048,13.8409',
  id: '46.6048,13.8409'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Tulpenweg 2',
  AddressLine2: '',
  PostCode: 9150,
  TownCity: 'Bleiburg',
  Latitude: '4.658.571.000',
  Longitude: '1.479.439.000',
  Telephone: '+43(0)4235/2056',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.58571,14.79439',
  id: '46.58571,14.79439'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Leopoldstrasse 66',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.725.592.000',
  Longitude: '1.139.805.000',
  Telephone: '+43(0)512/584177',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.25592,11.39805',
  id: '47.25592,11.39805'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Umfahrungsstrasse',
  AddressLine2: '',
  PostCode: 5280,
  TownCity: 'Braunau',
  Latitude: '4.825.469.100',
  Longitude: '1.304.517.400',
  Telephone: '+43(0)7722/63280',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.254691,13.045174',
  id: '48.254691,13.045174'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Münchner Str. 75',
  AddressLine2: '',
  PostCode: 6100,
  TownCity: 'Seefeld',
  Latitude: '4.733.453.000',
  Longitude: '1.119.184.000',
  Telephone: '+43(0)5212/24070',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.33453,11.19184',
  id: '47.33453,11.19184'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Maria Enzersdorfer Str. 5-7',
  AddressLine2: '',
  PostCode: 2345,
  TownCity: 'Brunn am Gebirge',
  Latitude: '4.810.440.000',
  Longitude: '1.628.530.000',
  Telephone: '+43(0)2236/31122',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1044,16.2853',
  id: '48.1044,16.2853'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Innsbruckerstrasse 52A',
  AddressLine2: '',
  PostCode: 6130,
  TownCity: 'Schwaz',
  Latitude: '4.733.870.000',
  Longitude: '1.170.570.000',
  Telephone: '+43(0)5242/62582',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.3387,11.7057',
  id: '47.3387,11.7057'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstr. 182',
  AddressLine2: '',
  PostCode: 3400,
  TownCity: 'Klosterneuburg',
  Latitude: '4.829.070.000',
  Longitude: '1.633.850.000',
  Telephone: '+43(0)2243/37491',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.2907,16.3385',
  id: '48.2907,16.3385'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: '',
  AddressLine1: 'Glotzing 20',
  AddressLine2: '',
  PostCode: 4134,
  TownCity: 'Putzleinsdorf',
  Latitude: '485.044.760',
  Longitude: '138.772.980',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.504476,13.877298',
  id: '48.504476,13.877298'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Rheinstrasse',
  AddressLine2: '',
  PostCode: 6971,
  TownCity: 'Bregenz-Hard',
  Latitude: '4.748.410.000',
  Longitude: '969.251.000',
  Telephone: '+43(0)5574/72424',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.4841,9.69251',
  id: '47.4841,9.69251'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Ressavarstr. 50',
  AddressLine2: '',
  PostCode: 8230,
  TownCity: 'Hartberg',
  Latitude: '4.728.253.610',
  Longitude: '1.597.548.750',
  Telephone: '+43(0)3332/62522',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.2825361,15.9754875',
  id: '47.2825361,15.9754875'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Nr. 46',
  AddressLine2: '',
  PostCode: 9615,
  TownCity: 'Förolach',
  Latitude: '4.662.860.000',
  Longitude: '1.347.207.000',
  Telephone: '+43(0)4283/2270',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.6286,13.47207',
  id: '46.6286,13.47207'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Stockach 29A',
  AddressLine2: '',
  PostCode: 6653,
  TownCity: 'Bach im Lechtal',
  Latitude: '4.725.996.000',
  Longitude: '1.037.553.000',
  Telephone: '+43(0)5634/6156',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.25996,10.37553',
  id: '47.25996,10.37553'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Katschb. Bundesstrasse',
  AddressLine2: '',
  PostCode: 5570,
  TownCity: 'Mauterndorf',
  Latitude: '4.712.950.000',
  Longitude: '1.368.400.000',
  Telephone: '+43(0)6472/7349',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.1295,13.684',
  id: '47.1295,13.684'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Weinözttlstrasse 2',
  AddressLine2: '',
  PostCode: 8045,
  TownCity: 'Graz-Andritz',
  Latitude: '4.709.836.000',
  Longitude: '1.541.819.000',
  Telephone: '+43(0)316/683425',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.09836,15.41819',
  id: '47.09836,15.41819'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 5',
  AddressLine2: '',
  PostCode: 3400,
  TownCity: 'Klosterneuburg',
  Latitude: '4.829.999.000',
  Longitude: '1.633.266.000',
  Telephone: '+43(0)2243/32533',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.29999,16.33266',
  id: '48.29999,16.33266'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Salzburger Strasse 42',
  AddressLine2: '',
  PostCode: 4840,
  TownCity: 'Vcklabruck',
  Latitude: '4.800.389.000',
  Longitude: '1.363.893.000',
  Telephone: '+43(0)7672/23278',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.00389,13.63893',
  id: '48.00389,13.63893'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'An der Bundesstraße 37',
  AddressLine2: '',
  PostCode: 3500,
  TownCity: 'Krems',
  Latitude: '4.842.304.804',
  Longitude: '1.562.184.329',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.42304804,15.62184329',
  id: '48.42304804,15.62184329'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Klagenfurter Strasse 42',
  AddressLine2: '',
  PostCode: 9170,
  TownCity: 'Ferlach',
  Latitude: '4.652.908.000',
  Longitude: '1.428.975.000',
  Telephone: '+43(0)4227/30945',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.52908,14.28975',
  id: '46.52908,14.28975'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Achstrasse 39',
  AddressLine2: '',
  PostCode: 6922,
  TownCity: 'Wolfurt',
  Latitude: '4.748.160.000',
  Longitude: '974.579.000',
  Telephone: '+43(0)5574/72154',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4816,9.74579',
  id: '47.4816,9.74579'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Villacherstr. 143',
  AddressLine2: '',
  PostCode: 9020,
  TownCity: 'Klagenfurt',
  Latitude: '4.661.990.000',
  Longitude: '1.428.250.000',
  Telephone: '+43(0)463/22281',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.6199,14.2825',
  id: '46.6199,14.2825'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Eisenstädterstr. 7',
  AddressLine2: '',
  PostCode: 8380,
  TownCity: 'Jennersdorf',
  Latitude: '4.693.705.000',
  Longitude: '1.614.296.000',
  Telephone: '+43(0)3329/45731',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.93705,16.14296',
  id: '46.93705,16.14296'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Spielbergstrasse 4',
  AddressLine2: '',
  PostCode: 6391,
  TownCity: 'Fieberbrunn',
  Latitude: '4.747.970.000',
  Longitude: '1.253.890.000',
  Telephone: '+43(0)5354/56310',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4797,12.5389',
  id: '47.4797,12.5389'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Felberst.Visavis 102',
  AddressLine2: '',
  PostCode: 1150,
  TownCity: 'Wien',
  Latitude: '4.819.370.000',
  Longitude: '1.632.140.000',
  Telephone: '+43(0)1/9824169',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1937,16.3214',
  id: '48.1937,16.3214'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Landstrasse 114',
  AddressLine2: '',
  PostCode: 2410,
  TownCity: 'Hainburg',
  Latitude: '4.814.037.000',
  Longitude: '1.692.246.000',
  Telephone: '+43(0)2165/62562',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.14037,16.92246',
  id: '48.14037,16.92246'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Oberau 27',
  AddressLine2: '',
  PostCode: 6311,
  TownCity: 'Wildschönau',
  Latitude: '4.744.457.000',
  Longitude: '1.203.784.000',
  Telephone: '+43(0)5339/8176',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.44457,12.03784',
  id: '47.44457,12.03784'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Altenmarkt bei St. Gallen Nr. 81',
  AddressLine2: '',
  PostCode: 8934,
  TownCity: 'Altenmarkt bei St. Gallen',
  Latitude: '4.772.275.000',
  Longitude: '1.464.959.000',
  Telephone: '+43(0)3632/534',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.72275,14.64959',
  id: '47.72275,14.64959'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gewerbegebiet Süd 2',
  AddressLine2: '',
  PostCode: 8243,
  TownCity: 'Pinggau',
  Latitude: '4.744.683.100',
  Longitude: '1.606.326.900',
  Telephone: '+43(0)3339/24491',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.446831,16.063269',
  id: '47.446831,16.063269'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrasse 53',
  AddressLine2: '',
  PostCode: 3314,
  TownCity: 'Strengberg',
  Latitude: '4.814.545.000',
  Longitude: '1.464.091.000',
  Telephone: '+43(0)7432/2242',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.14545,14.64091',
  id: '48.14545,14.64091'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Katschberg-Bundesstrasse 34',
  AddressLine2: '',
  PostCode: 5550,
  TownCity: 'Radstadt',
  Latitude: '4.738.671.000',
  Longitude: '1.345.209.000',
  Telephone: '+43(0)6452/4382',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.38671,13.45209',
  id: '47.38671,13.45209'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mureckerstrasse 31',
  AddressLine2: '',
  PostCode: 8083,
  TownCity: 'St.Stefan im Rosental',
  Latitude: '4.690.101.600',
  Longitude: '1.571.373.000',
  Telephone: '+43(0)311/681254',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.901016,15.71373',
  id: '46.901016,15.71373'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Tibitsch 84',
  AddressLine2: '',
  PostCode: 9212,
  TownCity: 'Techelsberg am Wörthersee',
  Latitude: '4.663.335.000',
  Longitude: '1.410.509.000',
  Telephone: '+43(0)4272/20406',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.63335,14.10509',
  id: '46.63335,14.10509'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bahnhofstr. 29',
  AddressLine2: '',
  PostCode: 3150,
  TownCity: 'Wilhelmsburg',
  Latitude: '4.811.235.100',
  Longitude: '1.560.793.630',
  Telephone: '+43(0)2746/6053',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.112351,15.6079363',
  id: '48.112351,15.6079363'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Semmeringstr. 4',
  AddressLine2: '',
  PostCode: 2620,
  TownCity: 'Neunkirchen',
  Latitude: '4.772.613.260',
  Longitude: '1.607.312.930',
  Telephone: '+43(0)2620/62811',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.7261326,16.0731293',
  id: '47.7261326,16.0731293'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn A13 (Brenner Autobahn) 256',
  AddressLine2: '',
  PostCode: 6156,
  TownCity: 'Gries am Brenner',
  Latitude: '4.700.760.000',
  Longitude: '1.150.913.000',
  Telephone: '+43-5274-87365',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.0076,11.50913',
  id: '47.0076,11.50913'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Schieferrollstrasse 17',
  AddressLine2: '',
  PostCode: 6250,
  TownCity: 'Kundl',
  Latitude: '474.673.830',
  Longitude: '119.897.317',
  Telephone: '435338861136',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.467383,11.9897317',
  id: '47.467383,11.9897317'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Umfahrungsstrasse 29',
  AddressLine2: '',
  PostCode: 9100,
  TownCity: 'Vlkermarkt',
  Latitude: '4.666.313.000',
  Longitude: '1.463.101.000',
  Telephone: '+43(0)4232/3171',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.66313,14.63101',
  id: '46.66313,14.63101'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mitterweg/ Ecke Exelgasse',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.725.858.300',
  Longitude: '1.137.208.600',
  Telephone: '+43(0)512/272062',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.258583,11.372086',
  id: '47.258583,11.372086'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Dallingerstrasse 11',
  AddressLine2: '',
  PostCode: 4030,
  TownCity: 'Linz',
  Latitude: '4.824.226.000',
  Longitude: '1.429.119.000',
  Telephone: '+43(0)732/370019',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.24226,14.29119',
  id: '48.24226,14.29119'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: '',
  AddressLine1: 'Neue Innbrückenstrasse 2',
  AddressLine2: '',
  PostCode: 5280,
  TownCity: 'Braunau',
  Latitude: '482.485.240',
  Longitude: '130.155.320',
  Telephone: '6609181397',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.248524,13.015532',
  id: '48.248524,13.015532'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Breitenfurter Strasse 325',
  AddressLine2: '',
  PostCode: 1230,
  TownCity: 'Wien',
  Latitude: '4.814.304.000',
  Longitude: '1.629.244.000',
  Telephone: '+43(0)1/8690303',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.14304,16.29244',
  id: '48.14304,16.29244'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Grazer Strasse 21',
  AddressLine2: '',
  PostCode: 8410,
  TownCity: 'Wildon',
  Latitude: '4.689.038.000',
  Longitude: '1.549.800.000',
  Telephone: '+43(0)3182/2351',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.89038,15.498',
  id: '46.89038,15.498'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mittersillerstr. 9',
  AddressLine2: '',
  PostCode: 5722,
  TownCity: 'Niedernsill',
  Latitude: '4.728.363.000',
  Longitude: '1.263.376.000',
  Telephone: '+43(0)654/88517',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.28363,12.63376',
  id: '47.28363,12.63376'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'An der Stadlhütte 1E',
  AddressLine2: '',
  PostCode: 3002,
  TownCity: 'Purkersdorf',
  Latitude: '4.818.495.000',
  Longitude: '1.612.428.000',
  Telephone: '+43(0)2231/64668',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.18495,16.12428',
  id: '48.18495,16.12428'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mattersburger Strasse 20',
  AddressLine2: '',
  PostCode: 7000,
  TownCity: 'Eisenstadt',
  Latitude: '4.783.627.000',
  Longitude: '1.652.424.000',
  Telephone: '+43(0)2682/63142',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.83627,16.52424',
  id: '47.83627,16.52424'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mattseer Landstrasse 4',
  AddressLine2: '',
  PostCode: 5161,
  TownCity: 'Elixhausen',
  Latitude: '4.786.832.000',
  Longitude: '1.306.688.000',
  Telephone: '+43(0)662/480250',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.86832,13.06688',
  id: '47.86832,13.06688'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Bundesstrasse 49',
  AddressLine2: '',
  PostCode: 5071,
  TownCity: 'Wals',
  Latitude: '477.857.414',
  Longitude: '129.755.200',
  Telephone: '',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.7857414,12.97552',
  id: '47.7857414,12.97552'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstrasse 197',
  AddressLine2: '',
  PostCode: 6580,
  TownCity: 'St. Anton',
  Latitude: '4.713.976.000',
  Longitude: '1.028.971.000',
  Telephone: '+43(0)5446/42622',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.13976,10.28971',
  id: '47.13976,10.28971'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstrasse 28',
  AddressLine2: '',
  PostCode: 8753,
  TownCity: 'Fohnsdorf',
  Latitude: '4.719.201.000',
  Longitude: '1.470.616.000',
  Telephone: '+43(0)3572/47340',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.19201,14.70616',
  id: '47.19201,14.70616'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Urstein Nord 15',
  AddressLine2: '',
  PostCode: 5412,
  TownCity: 'Puch-Hallein',
  Latitude: '4.773.315.351',
  Longitude: '1.308.560.603',
  Telephone: '+43(0)6245/21019',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.73315351,13.08560603',
  id: '47.73315351,13.08560603'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Obere Hauptstrasse 44',
  AddressLine2: '',
  PostCode: 2425,
  TownCity: 'Nickelsdorf/Bgl.',
  Latitude: '4.794.265.000',
  Longitude: '1.706.133.000',
  Telephone: '+43(0)2146/2246',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.94265,17.06133',
  id: '47.94265,17.06133'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Rautenweg 13',
  AddressLine2: '',
  PostCode: 1222,
  TownCity: 'Wien',
  Latitude: '4.826.272.000',
  Longitude: '1.646.121.000',
  Telephone: '+43(0)1/2597962',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.26272,16.46121',
  id: '48.26272,16.46121'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gersthoferstr.geg. 13',
  AddressLine2: '',
  PostCode: 1180,
  TownCity: 'Wien',
  Latitude: '4.822.740.000',
  Longitude: '1.632.660.000',
  Telephone: '+43(0)1/4793107',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.2274,16.3266',
  id: '48.2274,16.3266'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Salzachtal Bundesstrasse 105',
  AddressLine2: '',
  PostCode: 5081,
  TownCity: 'Anif',
  Latitude: '4.773.769.000',
  Longitude: '1.306.754.000',
  Telephone: '+43(0)6246/72498',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.73769,13.06754',
  id: '47.73769,13.06754'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstrasse 311',
  AddressLine2: '',
  PostCode: 5662,
  TownCity: 'Gries/Taxenbach',
  Latitude: '4.729.578.000',
  Longitude: '1.298.227.000',
  Telephone: '+43(0)6543/8113',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.29578,12.98227',
  id: '47.29578,12.98227'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Fürstenweg 87',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.715.440.000',
  Longitude: '1.122.200.000',
  Telephone: '+43(0)512/22777',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.1544,11.222',
  id: '47.1544,11.222'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Huttengasse',
  AddressLine2: '',
  PostCode: 1160,
  TownCity: 'Wien',
  Latitude: '4.820.745.000',
  Longitude: '1.630.919.000',
  Telephone: '+43(0)1/4931672',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.20745,16.30919',
  id: '48.20745,16.30919'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Rennweg 20',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.727.650.000',
  Longitude: '1.139.950.000',
  Telephone: '+43(0)512/585400',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.2765,11.3995',
  id: '47.2765,11.3995'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstrasse 10',
  AddressLine2: '',
  PostCode: 2460,
  TownCity: 'Bruck/Leitha',
  Latitude: '4.802.955.000',
  Longitude: '1.671.775.000',
  Telephone: '+43(0)2162/62457',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.02955,16.71775',
  id: '48.02955,16.71775'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Salzburger Strasse 23',
  AddressLine2: '',
  PostCode: 6380,
  TownCity: 'St. Johann',
  Latitude: '4.752.678.000',
  Longitude: '1.242.438.000',
  Telephone: '+43(0)5352/62656',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.52678,12.42438',
  id: '47.52678,12.42438'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Münchner Bundesstrasse 29',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg-Liefering',
  Latitude: '4.781.961.000',
  Longitude: '1.301.756.000',
  Telephone: '+43(0)662/432125',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.81961,13.01756',
  id: '47.81961,13.01756'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstraße 94',
  AddressLine2: '',
  PostCode: 2620,
  TownCity: 'Neunkirchen',
  Latitude: '4.772.502.000',
  Longitude: '1.607.160.000',
  Telephone: '+43(0)2635/62341',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.72502,16.0716',
  id: '47.72502,16.0716'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Kremser Ladnstr. 33',
  AddressLine2: '',
  PostCode: 3452,
  TownCity: 'Heiligeneich',
  Latitude: '4.830.459.000',
  Longitude: '1.589.132.000',
  Telephone: '+43(0)2275/5351',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.30459,15.89132',
  id: '48.30459,15.89132'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Am Bäckerberg 17',
  AddressLine2: '',
  PostCode: 4490,
  TownCity: 'St. Florian',
  Latitude: '4.821.312.000',
  Longitude: '1.439.935.000',
  Telephone: '+43(0)7224/80446',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.21312,14.39935',
  id: '48.21312,14.39935'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Neunkirchnerstrasse 90',
  AddressLine2: '',
  PostCode: 2700,
  TownCity: 'Wr.Neustadt',
  Latitude: '477.985.984',
  Longitude: '162.249.398',
  Telephone: '+432622235910',
  WeekDayFrom: '05:30',
  WeekDayTo: '19:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '13:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.7985984,16.2249398',
  id: '47.7985984,16.2249398'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Lindenweg 21',
  AddressLine2: '',
  PostCode: 9754,
  TownCity: 'Steinfeld',
  Latitude: '4.675.275.000',
  Longitude: '1.324.998.000',
  Telephone: '+43(0)664/816094',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.75275,13.24998',
  id: '46.75275,13.24998'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Reschen Bundesstrae',
  AddressLine2: '',
  PostCode: 6522,
  TownCity: 'Prutz',
  Latitude: '4.709.181.000',
  Longitude: '1.066.737.000',
  Telephone: '+43(0)5472/20395',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.09181,10.66737',
  id: '47.09181,10.66737'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Nr. 301',
  AddressLine2: '',
  PostCode: 9613,
  TownCity: 'Feistritz an der Gail',
  Latitude: '4.657.380.000',
  Longitude: '1.360.441.000',
  Telephone: '+43(0)4256/20224',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.5738,13.60441',
  id: '46.5738,13.60441'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: '',
  AddressLine1: '',
  AddressLine2: '',
  PostCode: null,
  TownCity: 'WIENER NEUSTADT, FISCHAUERGASS',
  Latitude: '',
  Longitude: '',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '0,0',
  id: '0,0'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Arlbergstrasse 54',
  AddressLine2: '',
  PostCode: 6900,
  TownCity: 'Bregenz-Rieden',
  Latitude: '4.749.596.000',
  Longitude: '973.402.000',
  Telephone: '+43(0)5574/44231',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.49596,9.73402',
  id: '47.49596,9.73402'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Pirching 97',
  AddressLine2: '',
  PostCode: 8200,
  TownCity: 'Hofstätten an der Raab',
  Latitude: '4.708.969.000',
  Longitude: '1.573.272.000',
  Telephone: '+43(0)3112/57414',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.08969,15.73272',
  id: '47.08969,15.73272'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Johannes Humer Straße',
  AddressLine2: '',
  PostCode: 4663,
  TownCity: 'Laakirchen',
  Latitude: '4.800.553.000',
  Longitude: '1.384.661.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.00553,13.84661',
  id: '48.00553,13.84661'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Fischauer Gasse 217',
  AddressLine2: '',
  PostCode: 2700,
  TownCity: 'Wr. Neustadt',
  Latitude: '4.782.324.000',
  Longitude: '1.621.538.000',
  Telephone: '+43(0)2622/26716',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.82324,16.21538',
  id: '47.82324,16.21538'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Moosstrasse 30',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.779.344.000',
  Longitude: '1.302.920.000',
  Telephone: '+43(0)662/824825',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.79344,13.0292',
  id: '47.79344,13.0292'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Langgasse 119',
  AddressLine2: '',
  PostCode: 6830,
  TownCity: 'Rankweil',
  Latitude: '4.727.182.000',
  Longitude: '961.785.000',
  Telephone: '+43(0)5522/46805',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.27182,9.61785',
  id: '47.27182,9.61785'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Alte Poststrasse 87',
  AddressLine2: '',
  PostCode: 8020,
  TownCity: 'Graz',
  Latitude: '4.707.687.000',
  Longitude: '1.540.696.000',
  Telephone: '+43(0)316/586692',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.07687,15.40696',
  id: '47.07687,15.40696'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Salzburgerstrasse  45',
  AddressLine2: '',
  PostCode: 5550,
  TownCity: 'Radstadt',
  Latitude: '4.738.650.000',
  Longitude: '1.345.060.000',
  Telephone: '+43(0)6452/5546',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.3865,13.4506',
  id: '47.3865,13.4506'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Betriebsstrasse 1',
  AddressLine2: '',
  PostCode: 4210,
  TownCity: 'Unterweitersdorf',
  Latitude: '4.835.790.000',
  Longitude: '1.447.660.000',
  Telephone: '+43(0)7235/63850',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.3579,14.4766',
  id: '48.3579,14.4766'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstr. 96',
  AddressLine2: '',
  PostCode: 6511,
  TownCity: 'Zams',
  Latitude: '4.715.700.000',
  Longitude: '1.058.890.000',
  Telephone: '+43(0)5442/67312',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.157,10.5889',
  id: '47.157,10.5889'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'TauernAutobahn, Torren 275',
  AddressLine2: '',
  PostCode: 5440,
  TownCity: 'Golling',
  Latitude: '4.758.360.000',
  Longitude: '1.315.650.000',
  Telephone: '+43(0)6244/6084',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.5836,13.1565',
  id: '47.5836,13.1565'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Lustenauerstrasse 52',
  AddressLine2: '',
  PostCode: 6850,
  TownCity: 'Dornbirn',
  Latitude: '4.741.040.000',
  Longitude: '972.079.000',
  Telephone: '+43(0)5572/25837',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4104,9.72079',
  id: '47.4104,9.72079'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Markt 56',
  AddressLine2: '',
  PostCode: 5441,
  TownCity: 'Abtenau',
  Latitude: '4.756.645.000',
  Longitude: '1.334.052.000',
  Telephone: '+43(0)6243/20121',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.56645,13.34052',
  id: '47.56645,13.34052'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bahnhofstrae 37A',
  AddressLine2: '',
  PostCode: 6170,
  TownCity: 'Zirl',
  Latitude: '4.726.753.000',
  Longitude: '1.123.440.000',
  Telephone: '+43(0)5238/52622',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.26753,11.2344',
  id: '47.26753,11.2344'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Neusiedler Strasse 15',
  AddressLine2: '',
  PostCode: 2340,
  TownCity: 'Moedling',
  Latitude: '4.808.167.000',
  Longitude: '1.628.419.000',
  Telephone: '+43(0)2236/47109',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.08167,16.28419',
  id: '48.08167,16.28419'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Kärntnerstr. 86',
  AddressLine2: '',
  PostCode: 8053,
  TownCity: 'Graz - Wetzelsdorf',
  Latitude: '4.705.484.000',
  Longitude: '1.541.667.000',
  Telephone: '+43(0)316/272453',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.05484,15.41667',
  id: '47.05484,15.41667'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstrasse 67',
  AddressLine2: '',
  PostCode: 8472,
  TownCity: 'Strass',
  Latitude: '4.672.960.000',
  Longitude: '1.561.580.000',
  Telephone: '+43(0)3453/6160',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.7296,15.6158',
  id: '46.7296,15.6158'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mauthen 189',
  AddressLine2: '',
  PostCode: 9640,
  TownCity: 'Kötschach',
  Latitude: '4.666.567.000',
  Longitude: '1.299.836.000',
  Telephone: '+43(0)4715/408',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.66567,12.99836',
  id: '46.66567,12.99836'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Innsbruckerstr. 195',
  AddressLine2: '',
  PostCode: 6108,
  TownCity: 'Scharnitz',
  Latitude: '4.738.696.000',
  Longitude: '1.125.912.000',
  Telephone: '+43(0)5213/20296',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.38696,11.25912',
  id: '47.38696,11.25912'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wechsel-Bundesstrasse 45',
  AddressLine2: '',
  PostCode: 2840,
  TownCity: 'Grimmenstein',
  Latitude: '4.761.994.000',
  Longitude: '1.612.818.000',
  Telephone: '+43(0)2644/6024',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.61994,16.12818',
  id: '47.61994,16.12818'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrasse 166',
  AddressLine2: '',
  PostCode: 2231,
  TownCity: 'Straßhof',
  Latitude: '4.831.611.000',
  Longitude: '1.663.636.000',
  Telephone: '+43(0)2287/2306',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.31611,16.63636',
  id: '48.31611,16.63636'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Braitnerstrasse 62A',
  AddressLine2: '',
  PostCode: 2500,
  TownCity: 'Baden',
  Latitude: '4.800.204.000',
  Longitude: '1.624.167.000',
  Telephone: '+43(0)2252/87949',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.00204,16.24167',
  id: '48.00204,16.24167'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Sterneckstrasse 16',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '478.081.108',
  Longitude: '130.530.881',
  Telephone: '+43662872118',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '14:00',
  SundayFrom: '08:00',
  SundayTo: '14:00',
  IsOpen24Hours: 'No',
  location: '47.8081108,13.0530881',
  id: '47.8081108,13.0530881'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gutenbergstrasse 6',
  AddressLine2: '',
  PostCode: 6858,
  TownCity: 'Schwarzach',
  Latitude: '4.743.971.000',
  Longitude: '975.557.000',
  Telephone: '+43(0)522/776858',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.43971,9.75557',
  id: '47.43971,9.75557'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bruck Hainburgerstr. 19-21',
  AddressLine2: '',
  PostCode: 2320,
  TownCity: 'Schwechat',
  Latitude: '4.814.030.000',
  Longitude: '1.648.370.000',
  Telephone: '+43(0)1/7076159',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1403,16.4837',
  id: '48.1403,16.4837'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'S 33- Schnellstrasse',
  AddressLine2: '',
  PostCode: 3100,
  TownCity: 'St. Pölten',
  Latitude: '4.822.339.000',
  Longitude: '1.567.863.000',
  Telephone: '+43(0)2742/23007',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.22339,15.67863',
  id: '48.22339,15.67863'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstrasse 12',
  AddressLine2: '',
  PostCode: 2345,
  TownCity: 'Brunn/Geb.',
  Latitude: '4.811.532.000',
  Longitude: '1.628.873.000',
  Telephone: '+43(0)2236/37887',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.11532,16.28873',
  id: '48.11532,16.28873'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Ostbahnstraße 10',
  AddressLine2: '',
  PostCode: 8041,
  TownCity: 'Graz',
  Latitude: '4.704.195.000',
  Longitude: '1.546.420.000',
  Telephone: '+43-316-4249800',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.04195,15.4642',
  id: '47.04195,15.4642'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Handelskai 388',
  AddressLine2: '',
  PostCode: 1020,
  TownCity: 'Wien',
  Latitude: '4.820.320.000',
  Longitude: '1.643.580.000',
  Telephone: '+43(0)1/7280259',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.2032,16.4358',
  id: '48.2032,16.4358'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Tiroler Strasse 9',
  AddressLine2: '',
  PostCode: 9800,
  TownCity: 'Spittal/Drau',
  Latitude: '4.680.076.000',
  Longitude: '1.349.156.000',
  Telephone: '+43(0)4762/2986',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.80076,13.49156',
  id: '46.80076,13.49156'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wilfersdorf 104',
  AddressLine2: '',
  PostCode: 8200,
  TownCity: 'Gleisdorf',
  Latitude: '4.710.687.000',
  Longitude: '1.566.413.000',
  Telephone: '+43(0)3112/32700',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.10687,15.66413',
  id: '47.10687,15.66413'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 136',
  AddressLine2: '',
  PostCode: 2020,
  TownCity: 'Hollabrunn',
  Latitude: '4.854.932.000',
  Longitude: '1.607.915.000',
  Telephone: '+43(0)2952/4581',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.54932,16.07915',
  id: '48.54932,16.07915'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Königstetterstrasse 154',
  AddressLine2: '',
  PostCode: 3430,
  TownCity: 'Tulln - Nitzing',
  Latitude: '4.831.874.000',
  Longitude: '1.608.506.000',
  Telephone: '+43(0)2272/82685',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.31874,16.08506',
  id: '48.31874,16.08506'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'S 36 Murtal-Schnellstr. Sd',
  AddressLine2: '',
  PostCode: 8733,
  TownCity: 'St.Marein/Knittelf.',
  Latitude: '4.725.337.000',
  Longitude: '1.487.890.000',
  Telephone: '+43(0)3515/4843',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.25337,14.8789',
  id: '47.25337,14.8789'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Steinbrückl 14',
  AddressLine2: '',
  PostCode: 7531,
  TownCity: 'Kemeten',
  Latitude: '4.727.489.000',
  Longitude: '1.615.556.000',
  Telephone: '+43(0)3352/20168',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.27489,16.15556',
  id: '47.27489,16.15556'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Terminalstr. 99',
  AddressLine2: '',
  PostCode: 4600,
  TownCity: 'Wels - Pernau',
  Latitude: '4.818.990.000',
  Longitude: '1.407.330.000',
  Telephone: '+43(0)7242/78719',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.1899,14.0733',
  id: '48.1899,14.0733'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Niederland 112',
  AddressLine2: '',
  PostCode: 5091,
  TownCity: 'Unken',
  Latitude: '476.651.940',
  Longitude: '127.528.820',
  Telephone: '',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.665194,12.752882',
  id: '47.665194,12.752882'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bahnhofstrae 11',
  AddressLine2: '',
  PostCode: 6372,
  TownCity: 'Oberndorf',
  Latitude: '4.749.750.000',
  Longitude: '1.238.590.000',
  Telephone: '+43(0)5352/61515',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.4975,12.3859',
  id: '47.4975,12.3859'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstr. 45a',
  AddressLine2: '',
  PostCode: 3340,
  TownCity: 'Waidhofen/Ybbs',
  Latitude: '4.797.034.740',
  Longitude: '1.476.344.580',
  Telephone: '+43(0)7442/52562',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.9703474,14.7634458',
  id: '47.9703474,14.7634458'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gewerbepark Süd 1',
  AddressLine2: '',
  PostCode: 8504,
  TownCity: 'Preding',
  Latitude: '4.685.877.000',
  Longitude: '1.540.688.000',
  Telephone: '+43(0)3185/2311',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.85877,15.40688',
  id: '46.85877,15.40688'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Langgasse 96',
  AddressLine2: '',
  PostCode: 6460,
  TownCity: 'Imst',
  Latitude: '4.722.747.000',
  Longitude: '1.074.632.000',
  Telephone: '+43(0)5412/65151',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.22747,10.74632',
  id: '47.22747,10.74632'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 52',
  AddressLine2: '',
  PostCode: 7423,
  TownCity: 'Pinkafeld',
  Latitude: '4.737.913.000',
  Longitude: '1.612.189.000',
  Telephone: '+43(0)3357/42581',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.37913,16.12189',
  id: '47.37913,16.12189'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Sellraintalerstrasse',
  AddressLine2: '',
  PostCode: 6175,
  TownCity: 'Kematen',
  Latitude: '4.726.168.000',
  Longitude: '1.126.511.000',
  Telephone: '+43(0)5232/3322',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.26168,11.26511',
  id: '47.26168,11.26511'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wr. Neustädter Strasse 28',
  AddressLine2: '',
  PostCode: 2514,
  TownCity: 'Traiskirchen',
  Latitude: '4.800.817.000',
  Longitude: '1.626.397.000',
  Telephone: '+43(0)2252/52454',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.00817,16.26397',
  id: '48.00817,16.26397'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wagramer Straße  79 DP Garage 4',
  AddressLine2: '',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '',
  Longitude: '',
  Telephone: '+43-1-2039669',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '0,0',
  id: '0,0'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Alpenstrasse 60',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.778.372.000',
  Longitude: '1.306.697.000',
  Telephone: '+43(0)662/624903',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.78372,13.06697',
  id: '47.78372,13.06697'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Raststation Steinhäusl A1',
  AddressLine2: '',
  PostCode: 3033,
  TownCity: 'Steinhäusl / Altlengbach A1',
  Latitude: '4.815.763.000',
  Longitude: '1.594.264.000',
  Telephone: '+43-2774-28846',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.15763,15.94264',
  id: '48.15763,15.94264'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Bundesstrasse 2',
  AddressLine2: '',
  PostCode: 5600,
  TownCity: 'St. Johann',
  Latitude: '473.344.020',
  Longitude: '131.893.444',
  Telephone: '',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.334402,13.1893444',
  id: '47.334402,13.1893444'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Oiden 110, Bundesstr.1',
  AddressLine2: '',
  PostCode: 3300,
  TownCity: 'Amstetten',
  Latitude: '4.813.310.000',
  Longitude: '1.484.160.000',
  Telephone: '+43(0)7472/25197',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1331,14.8416',
  id: '48.1331,14.8416'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Andechsstrasse 69',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.726.672.000',
  Longitude: '1.142.360.000',
  Telephone: '+43(0)512/344262',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.26672,11.4236',
  id: '47.26672,11.4236'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'St. Veiter-Strasse 168',
  AddressLine2: '',
  PostCode: 9020,
  TownCity: 'Klagenfurt',
  Latitude: '4.666.661.000',
  Longitude: '1.431.578.000',
  Telephone: '+43(0)463/41709',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.66661,14.31578',
  id: '46.66661,14.31578'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Lehen 20',
  AddressLine2: '',
  PostCode: 8970,
  TownCity: 'Schladming',
  Latitude: '4.739.670.000',
  Longitude: '1.370.390.000',
  Telephone: '+43(0)3687/22201',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.3967,13.7039',
  id: '47.3967,13.7039'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Reintal 21',
  AddressLine2: '',
  PostCode: 9841,
  TownCity: 'Winklern',
  Latitude: '4.686.212.000',
  Longitude: '1.289.067.000',
  Telephone: '+43(0)4822/247',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.86212,12.89067',
  id: '46.86212,12.89067'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 304',
  AddressLine2: '',
  PostCode: 8051,
  TownCity: 'Graz',
  Latitude: '4.709.837.000',
  Longitude: '1.540.378.000',
  Telephone: '+43(0)316/683664',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.09837,15.40378',
  id: '47.09837,15.40378'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Felbertauernstr.',
  AddressLine2: '',
  PostCode: 9971,
  TownCity: 'Matrei in Osttirol',
  Latitude: '4.700.519.000',
  Longitude: '1.254.105.000',
  Telephone: '+43(0)5022/77997',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.00519,12.54105',
  id: '47.00519,12.54105'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Schattwald 29',
  AddressLine2: '',
  PostCode: 6677,
  TownCity: 'Schattwald',
  Latitude: '4.751.210.000',
  Longitude: '1.046.280.000',
  Telephone: '+43(0)5675/20379',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.5121,10.4628',
  id: '47.5121,10.4628'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Tullner Strasse 4',
  AddressLine2: '',
  PostCode: 3051,
  TownCity: 'St.Christophen',
  Latitude: '4.816.641.340',
  Longitude: '1.588.509.380',
  Telephone: '+43(0)2772/52573',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1664134,15.8850938',
  id: '48.1664134,15.8850938'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Kremstalstrasse 117',
  AddressLine2: '',
  PostCode: 4053,
  TownCity: 'Ritzlhof',
  Latitude: '4.818.722.000',
  Longitude: '1.424.927.000',
  Telephone: '+43(0)7229/88351',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.18722,14.24927',
  id: '48.18722,14.24927'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Kaiserebersdorferstrasse 48',
  AddressLine2: '',
  PostCode: 1110,
  TownCity: 'Wien',
  Latitude: '4.816.746.000',
  Longitude: '1.642.691.000',
  Telephone: '+43(0)1/7676695',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.16746,16.42691',
  id: '48.16746,16.42691'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Ketzergasse 111',
  AddressLine2: '',
  PostCode: 1230,
  TownCity: 'Wien',
  Latitude: '4.812.772.410',
  Longitude: '1.630.555.310',
  Telephone: '+43(0)1/86924840',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1277241,16.3055531',
  id: '48.1277241,16.3055531'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bahnhofstr. 21',
  AddressLine2: '',
  PostCode: 6780,
  TownCity: 'Schruns',
  Latitude: '4.708.010.000',
  Longitude: '991.687.000',
  Telephone: '+43(0)5556/72470',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.0801,9.91687',
  id: '47.0801,9.91687'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Forstbergstrasse',
  AddressLine2: '',
  PostCode: 4470,
  TownCity: 'Enns',
  Latitude: '4.820.671.000',
  Longitude: '1.447.358.000',
  Telephone: '+43(0)7223/82872',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.20671,14.47358',
  id: '48.20671,14.47358'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Ennstal-Bundestrasse 213',
  AddressLine2: '',
  PostCode: 8970,
  TownCity: 'Schladming',
  Latitude: '4.739.032.000',
  Longitude: '1.365.833.000',
  Telephone: '+43(0)3687/24659',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.39032,13.65833',
  id: '47.39032,13.65833'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Loferer Strasse 28',
  AddressLine2: '',
  PostCode: 6322,
  TownCity: 'Kirchbichl',
  Latitude: '4.749.450.000',
  Longitude: '1.209.157.000',
  Telephone: '+43(0)5332/73772',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4945,12.09157',
  id: '47.4945,12.09157'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Salurner Str. 28',
  AddressLine2: '',
  PostCode: 6330,
  TownCity: 'Kufstein',
  Latitude: '4.757.640.000',
  Longitude: '1.216.040.000',
  Telephone: '+43(0)5372/62665',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.5764,12.1604',
  id: '47.5764,12.1604'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Buchau Eden 5',
  AddressLine2: '',
  PostCode: 6212,
  TownCity: 'Maurach',
  Latitude: '4.743.310.000',
  Longitude: '1.173.550.000',
  Telephone: '+43(0)5243/6113',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4331,11.7355',
  id: '47.4331,11.7355'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Sandtal 28',
  AddressLine2: '',
  PostCode: 5144,
  TownCity: 'Handenberg',
  Latitude: '4.814.160.000',
  Longitude: '1.301.510.000',
  Telephone: '+43(0)7748/32129',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1416,13.0151',
  id: '48.1416,13.0151'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Nonntaler Hauptstraße 53a',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.778.703.000',
  Longitude: '1.305.175.000',
  Telephone: '+43-662-820939',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.78703,13.05175',
  id: '47.78703,13.05175'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wr. Neustädter Strasse 99',
  AddressLine2: '',
  PostCode: 2601,
  TownCity: 'Sollenau',
  Latitude: '4.789.730.000',
  Longitude: '1.624.994.000',
  Telephone: '+43(0)2628/62420',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.8973,16.24994',
  id: '47.8973,16.24994'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstr. 3',
  AddressLine2: '',
  PostCode: 3493,
  TownCity: 'Hadersdorf a. Kamp',
  Latitude: '4.845.960.000',
  Longitude: '1.572.520.000',
  Telephone: '+43(0)2735/2410',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.4596,15.7252',
  id: '48.4596,15.7252'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Nr. 68',
  AddressLine2: '',
  PostCode: 8262,
  TownCity: 'Nestelbach Im Ilztal',
  Latitude: '4.709.185.487',
  Longitude: '1.587.899.702',
  Telephone: '+43(0)3385/421',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.09185487,15.87899702',
  id: '47.09185487,15.87899702'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrasse 77',
  AddressLine2: '',
  PostCode: 3170,
  TownCity: 'Hainfeld',
  Latitude: '4.803.824.000',
  Longitude: '1.576.290.000',
  Telephone: '+43(0)2764/2504',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.03824,15.7629',
  id: '48.03824,15.7629'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Feldkirchnerstrasse 1',
  AddressLine2: '',
  PostCode: 9556,
  TownCity: 'Liebenfels',
  Latitude: '4.673.750.000',
  Longitude: '1.428.580.000',
  Telephone: '+43(0)4215/2221',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.7375,14.2858',
  id: '46.7375,14.2858'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Atterseestrasse',
  AddressLine2: '',
  PostCode: 4863,
  TownCity: 'Seewalchen',
  Latitude: '4.795.200.000',
  Longitude: '1.358.630.000',
  Telephone: '+43(0)7662/2463',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.952,13.5863',
  id: '47.952,13.5863'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Umfahrungsstr.',
  AddressLine2: '',
  PostCode: 4802,
  TownCity: 'Ebensee',
  Latitude: '4.780.630.000',
  Longitude: '1.378.060.000',
  Telephone: '+43(0)6133/7565',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.8063,13.7806',
  id: '47.8063,13.7806'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Salzachtalstrasse 23',
  AddressLine2: '',
  PostCode: 5400,
  TownCity: 'Hallein',
  Latitude: '4.767.970.000',
  Longitude: '1.310.450.000',
  Telephone: '+43(0)6245/83029',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.6797,13.1045',
  id: '47.6797,13.1045'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstraße 38a',
  AddressLine2: '',
  PostCode: 8684,
  TownCity: 'Spittal am Semmering',
  Latitude: '4.761.322.000',
  Longitude: '1.574.718.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.61322,15.74718',
  id: '47.61322,15.74718'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstr. 18',
  AddressLine2: '',
  PostCode: 6923,
  TownCity: 'Lauterach',
  Latitude: '4.748.220.000',
  Longitude: '973.162.000',
  Telephone: '+43(0)5574/71829',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4822,9.73162',
  id: '47.4822,9.73162'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Brennerstr. 119',
  AddressLine2: '',
  PostCode: 6156,
  TownCity: 'Gries am Brenner',
  Latitude: '4.701.436.000',
  Longitude: '1.150.802.000',
  Telephone: '+43(0)5274/86210',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.01436,11.50802',
  id: '47.01436,11.50802'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bersbuch 401',
  AddressLine2: '',
  PostCode: 6866,
  TownCity: 'Andelsbuch',
  Latitude: '4.739.740.000',
  Longitude: '985.358.000',
  Telephone: '+43(0)5512/3214',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.3974,9.85358',
  id: '47.3974,9.85358'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrasse 11',
  AddressLine2: '',
  PostCode: 2763,
  TownCity: 'Pernitz',
  Latitude: '4.789.638.000',
  Longitude: '1.596.825.000',
  Telephone: '+43(0)2632/72369',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.89638,15.96825',
  id: '47.89638,15.96825'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Innsbrucker Strae 36',
  AddressLine2: '',
  PostCode: 6300,
  TownCity: 'Wörgl',
  Latitude: '4.748.262.000',
  Longitude: '1.205.798.000',
  Telephone: '+43(0)5332/73910',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.48262,12.05798',
  id: '47.48262,12.05798'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrae 91',
  AddressLine2: '',
  PostCode: 6433,
  TownCity: 'Tumpen',
  Latitude: '4.717.610.000',
  Longitude: '1.091.020.000',
  Telephone: '+43(0)5255/50148',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.1761,10.9102',
  id: '47.1761,10.9102'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Breitenfurterstr. 215',
  AddressLine2: '',
  PostCode: 1230,
  TownCity: 'Wien',
  Latitude: '4.815.680.000',
  Longitude: '1.630.500.000',
  Telephone: '+43(0)1/8042146',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1568,16.305',
  id: '48.1568,16.305'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Ebreichsdorfer Str. 17b',
  AddressLine2: '',
  PostCode: 2522,
  TownCity: 'Oberwaltersdorf',
  Latitude: '4.797.335.900',
  Longitude: '1.632.789.900',
  Telephone: '+43(0)2253/9201',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.973359,16.327899',
  id: '47.973359,16.327899'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Grieskirchnerstr. 39',
  AddressLine2: '',
  PostCode: 4600,
  TownCity: 'Wels',
  Latitude: '4.817.173.000',
  Longitude: '1.402.365.000',
  Telephone: '+43(0)7242/44205',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.17173,14.02365',
  id: '48.17173,14.02365'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hohenemser Strasse 1',
  AddressLine2: '',
  PostCode: 6890,
  TownCity: 'Lustenau',
  Latitude: '4.741.895.000',
  Longitude: '966.087.000',
  Telephone: '+43(0)5577/83209',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.41895,9.66087',
  id: '47.41895,9.66087'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Südring 260',
  AddressLine2: '',
  PostCode: 9020,
  TownCity: 'Klagenfurt',
  Latitude: '4.660.699.000',
  Longitude: '1.430.492.000',
  Telephone: '+43(0)463/310273',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.60699,14.30492',
  id: '46.60699,14.30492'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Leitenstrasse 30',
  AddressLine2: '',
  PostCode: 4812,
  TownCity: 'Pinsdorf',
  Latitude: '4.793.485.000',
  Longitude: '1.377.700.000',
  Telephone: '+43(0)7612/64429',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.93485,13.777',
  id: '47.93485,13.777'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Schwefel 70a',
  AddressLine2: '',
  PostCode: 6850,
  TownCity: 'Dornbirn',
  Latitude: '4.742.950.000',
  Longitude: '973.976.000',
  Telephone: '+43(0)5572/24326',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4295,9.73976',
  id: '47.4295,9.73976'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 125',
  AddressLine2: '',
  PostCode: 2345,
  TownCity: 'Brunn/Geb.',
  Latitude: '4.811.221.000',
  Longitude: '1.629.956.000',
  Telephone: '+43(0)2236/31790',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.11221,16.29956',
  id: '48.11221,16.29956'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 31',
  AddressLine2: '',
  PostCode: 2120,
  TownCity: 'Wolkersdorf',
  Latitude: '4.837.860.000',
  Longitude: '1.651.037.000',
  Telephone: '+43(0)2245/2424',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.3786,16.51037',
  id: '48.3786,16.51037'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstrasse 142',
  AddressLine2: '',
  PostCode: 5230,
  TownCity: 'Mattighofen',
  Latitude: '4.810.236.000',
  Longitude: '1.315.068.000',
  Telephone: '+43(0)7742/2388',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.10236,13.15068',
  id: '48.10236,13.15068'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wolfgangseestraße 2',
  AddressLine2: '',
  PostCode: 5350,
  TownCity: 'Strobl',
  Latitude: '4.771.695.000',
  Longitude: '1.348.334.000',
  Telephone: '+43(0)6137/75752',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.71695,13.48334',
  id: '47.71695,13.48334'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Amraserseestr. 29',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.726.190.000',
  Longitude: '1.142.309.000',
  Telephone: '+43(0)512/343114',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.2619,11.42309',
  id: '47.2619,11.42309'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Steinleiten 40',
  AddressLine2: '',
  PostCode: 4890,
  TownCity: 'Frankenmarkt',
  Latitude: '4.798.556.000',
  Longitude: '1.343.298.000',
  Telephone: '+43(0)7684/6309',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.98556,13.43298',
  id: '47.98556,13.43298'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Ennser Strasse 152a',
  AddressLine2: '',
  PostCode: 4407,
  TownCity: 'Dietach',
  Latitude: '4.810.747.000',
  Longitude: '1.444.654.000',
  Telephone: '+43(0)7252/39009',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.10747,14.44654',
  id: '48.10747,14.44654'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstr. 35',
  AddressLine2: '',
  PostCode: 2640,
  TownCity: 'Gloggnitz',
  Latitude: '4.767.347.960',
  Longitude: '1.594.598.490',
  Telephone: '+43(0)2262/42524',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.6734796,15.9459849',
  id: '47.6734796,15.9459849'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Hauptsrasse 102',
  AddressLine2: '',
  PostCode: 7062,
  TownCity: 'St.Margarethen in Burgenland',
  Latitude: '478.034.233',
  Longitude: '166.139.933',
  Telephone: '+4326802232',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.8034233,16.6139933',
  id: '47.8034233,16.6139933'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Marktplatz 24',
  AddressLine2: '',
  PostCode: 8081,
  TownCity: 'Heiligenkreuz',
  Latitude: '4.695.660.000',
  Longitude: '1.558.490.000',
  Telephone: '+43(0)3134/2201',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.9566,15.5849',
  id: '46.9566,15.5849'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstrasse 38',
  AddressLine2: '',
  PostCode: 2401,
  TownCity: 'Fischamend',
  Latitude: '4.812.098.000',
  Longitude: '1.660.183.000',
  Telephone: '+43(0)2232/76251',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.12098,16.60183',
  id: '48.12098,16.60183'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gewerbepark Nord Nr. 11',
  AddressLine2: '',
  PostCode: 8430,
  TownCity: 'Gralla',
  Latitude: '4.681.861.000',
  Longitude: '1.555.269.000',
  Telephone: '+43(0)3452/76701',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.81861,15.55269',
  id: '46.81861,15.55269'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Donaustadtstrasse 43',
  AddressLine2: '',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '4.822.231.000',
  Longitude: '1.644.413.000',
  Telephone: '+43(0)1/2024043',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.22231,16.44413',
  id: '48.22231,16.44413'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Eisenstädter Strasse 19',
  AddressLine2: '',
  PostCode: 2483,
  TownCity: 'Weigelsdorf',
  Latitude: '4.794.775.000',
  Longitude: '1.640.919.000',
  Telephone: '+43(0)2254/72366',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.94775,16.40919',
  id: '47.94775,16.40919'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wachauerstrasse 4',
  AddressLine2: '',
  PostCode: 3660,
  TownCity: 'Klein Poechlarn',
  Latitude: '482.173.600',
  Longitude: '152.160.691',
  Telephone: '+4374138008',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '48.21736,15.2160691',
  id: '48.21736,15.2160691'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Maria Theresienstr. 14',
  AddressLine2: '',
  PostCode: 6890,
  TownCity: 'Lustenau',
  Latitude: '4.742.820.000',
  Longitude: '965.945.000',
  Telephone: '+43(0)5577/83066',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4282,9.65945',
  id: '47.4282,9.65945'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'S 6 Semmering-Schnellstrasse',
  AddressLine2: '',
  PostCode: 8641,
  TownCity: 'St.Marein/Mrz',
  Latitude: '4.744.806.000',
  Longitude: '1.532.654.000',
  Telephone: '+43(0)3864/3728',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.44806,15.32654',
  id: '47.44806,15.32654'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Zeller Bundesstrasse 1a',
  AddressLine2: '',
  PostCode: 5760,
  TownCity: 'Saalfelden',
  Latitude: '4.742.299.000',
  Longitude: '1.283.617.000',
  Telephone: '+43(0)6582/73258',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.42299,12.83617',
  id: '47.42299,12.83617'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Märzenkeller 3',
  AddressLine2: '',
  PostCode: 8850,
  TownCity: 'Murau',
  Latitude: '4.711.325.000',
  Longitude: '1.416.195.000',
  Telephone: '+43(0)3532/2193',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.11325,14.16195',
  id: '47.11325,14.16195'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Untere Donaulände 15',
  AddressLine2: '',
  PostCode: 4020,
  TownCity: 'Linz',
  Latitude: '4.831.378.000',
  Longitude: '1.429.957.000',
  Telephone: '+43(0)732/773051',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.31378,14.29957',
  id: '48.31378,14.29957'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gerlos Bundesstr. 169',
  AddressLine2: '',
  PostCode: 6272,
  TownCity: 'Kaltenbach',
  Latitude: '4.729.360.000',
  Longitude: '1.187.525.000',
  Telephone: '+43(0)5283/2292',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.2936,11.87525',
  id: '47.2936,11.87525'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Alfred Fröhlich Strasse 1',
  AddressLine2: '',
  PostCode: 2201,
  TownCity: 'Gerasdorf',
  Latitude: '4.833.873.000',
  Longitude: '1.646.407.000',
  Telephone: '+43(0)2246/20646',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.33873,16.46407',
  id: '48.33873,16.46407'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Oberzellerg. 2a',
  AddressLine2: '',
  PostCode: 1030,
  TownCity: 'Wien',
  Latitude: '4.819.360.000',
  Longitude: '1.639.680.000',
  Telephone: '+43(0)1/7136175',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1936,16.3968',
  id: '48.1936,16.3968'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'S1, Ost KM 38,5',
  AddressLine2: '',
  PostCode: 2232,
  TownCity: 'Deutsch-Wagram',
  Latitude: '4.831.075.000',
  Longitude: '1.651.506.000',
  Telephone: '+43-2247-51947',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.31075,16.51506',
  id: '48.31075,16.51506'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Klagenfurterstrasse 40',
  AddressLine2: '',
  PostCode: 9400,
  TownCity: 'Wolfsberg',
  Latitude: '4.682.236.000',
  Longitude: '1.483.825.000',
  Telephone: '+43(0)4352/90946',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.82236,14.83825',
  id: '46.82236,14.83825'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Windegg 4',
  AddressLine2: '',
  PostCode: 4221,
  TownCity: 'Steyregg',
  Latitude: '4.828.590.000',
  Longitude: '1.435.160.000',
  Telephone: '+43(0)732/641175',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.2859,14.3516',
  id: '48.2859,14.3516'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Grazerstr. 44',
  AddressLine2: '',
  PostCode: 8680,
  TownCity: 'Mürzzuschlag',
  Latitude: '4.759.917.550',
  Longitude: '1.566.610.720',
  Telephone: '+43(0)3852/2548',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.5991755,15.6661072',
  id: '47.5991755,15.6661072'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Floragasse 7A',
  AddressLine2: '',
  PostCode: 1040,
  TownCity: 'Wien',
  Latitude: '4.819.456.000',
  Longitude: '1.636.808.000',
  Telephone: '+43(1)5051517',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.19456,16.36808',
  id: '48.19456,16.36808'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Innsbruckerstrasse - Kreisverkehr 1',
  AddressLine2: '',
  PostCode: 6300,
  TownCity: 'Wörgl',
  Latitude: '4.748.932.000',
  Longitude: '1.206.277.000',
  Telephone: '+43(0)5332/70696',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.48932,12.06277',
  id: '47.48932,12.06277'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Ossiacher Zeile 74',
  AddressLine2: '',
  PostCode: 9500,
  TownCity: 'Villach',
  Latitude: '4.661.752.000',
  Longitude: '1.386.021.000',
  Telephone: '+43(0)4242/41978',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.61752,13.86021',
  id: '46.61752,13.86021'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Omesberg 9',
  AddressLine2: '',
  PostCode: 6764,
  TownCity: 'Lech am Arlberg',
  Latitude: '4.720.491.000',
  Longitude: '1.014.054.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.20491,10.14054',
  id: '47.20491,10.14054'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gabelsbergerstr. 1',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.780.990.000',
  Longitude: '1.304.690.000',
  Telephone: '+43(0)662/874218',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.8099,13.0469',
  id: '47.8099,13.0469'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Pachern Hauptstrasse 47',
  AddressLine2: '',
  PostCode: 8075,
  TownCity: 'Pachern',
  Latitude: '4.705.305.000',
  Longitude: '1.552.888.000',
  Telephone: '+43(0)316/491915',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.05305,15.52888',
  id: '47.05305,15.52888'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Rattendorf 7',
  AddressLine2: '',
  PostCode: 9631,
  TownCity: 'Jenig',
  Latitude: '4.662.830.000',
  Longitude: '1.325.220.000',
  Telephone: '+43(0)4285/247',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.6283,13.2522',
  id: '46.6283,13.2522'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrasse 297',
  AddressLine2: '',
  PostCode: 8311,
  TownCity: 'Markt Hartmannsdorf',
  Latitude: '4.705.735.600',
  Longitude: '1.583.250.400',
  Telephone: '+43(0)311/45107',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.057356,15.832504',
  id: '47.057356,15.832504'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrasse 21',
  AddressLine2: '',
  PostCode: 3161,
  TownCity: 'St. Veit a. d. Gölsen',
  Latitude: '4.804.289.000',
  Longitude: '1.567.186.000',
  Telephone: '+43(0)2763/3198',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.04289,15.67186',
  id: '48.04289,15.67186'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mörtschach 72',
  AddressLine2: '',
  PostCode: 9842,
  TownCity: 'Mörtschach',
  Latitude: '4.692.286.000',
  Longitude: '1.291.034.000',
  Telephone: '+43(0)4826/365',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.92286,12.91034',
  id: '46.92286,12.91034'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 65',
  AddressLine2: '',
  PostCode: 3100,
  TownCity: 'St. Pölten',
  Latitude: '4.820.356.000',
  Longitude: '1.563.767.000',
  Telephone: '+43(0)2742/25863',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.20356,15.63767',
  id: '48.20356,15.63767'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Lagerhaus Rohrbach',
  AddressLine1: 'Schwarzenberg 168',
  AddressLine2: '',
  PostCode: 4164,
  TownCity: 'Schwarzenberg',
  Latitude: '487.362.668',
  Longitude: '138.195.991',
  Telephone: '',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.7362668,13.8195991',
  id: '48.7362668,13.8195991'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mankerstr. 8',
  AddressLine2: '',
  PostCode: 3233,
  TownCity: 'Kilb',
  Latitude: '4.810.080.000',
  Longitude: '1.540.520.000',
  Telephone: '+43(0)2748/7350',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1008,15.4052',
  id: '48.1008,15.4052'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Shuttleworthstraße 10',
  AddressLine2: '',
  PostCode: 1210,
  TownCity: 'Wien',
  Latitude: '4.827.050.000',
  Longitude: '1.641.214.000',
  Telephone: '+43-1-2903740',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.2705,16.41214',
  id: '48.2705,16.41214'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Leopoldstrae 67',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.725.394.000',
  Longitude: '1.139.918.000',
  Telephone: '+43(0)512/585958',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.25394,11.39918',
  id: '47.25394,11.39918'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Drautalstrasse 14',
  AddressLine2: '',
  PostCode: 9710,
  TownCity: 'Neu Feffernitz',
  Latitude: '4.668.925.000',
  Longitude: '1.368.395.000',
  Telephone: '+43(0)4245/40001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.68925,13.68395',
  id: '46.68925,13.68395'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wr. Neustädterstraße 18',
  AddressLine2: '',
  PostCode: 2733,
  TownCity: 'Grünbach/Schneeberg',
  Latitude: '4.779.664.090',
  Longitude: '1.599.371.410',
  Telephone: '+43(0)2637/2758',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.7966409,15.9937141',
  id: '47.7966409,15.9937141'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Straße 2a',
  AddressLine2: '',
  PostCode: 7540,
  TownCity: 'Güssing',
  Latitude: '4.706.475.000',
  Longitude: '1.632.512.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.06475,16.32512',
  id: '47.06475,16.32512'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Innsbrucker Straße 68',
  AddressLine2: '',
  PostCode: 6382,
  TownCity: 'Kirchdorf in Tirol',
  Latitude: '4.753.830.000',
  Longitude: '1.244.099.000',
  Telephone: '+43-5352-61088',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.5383,12.44099',
  id: '47.5383,12.44099'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Innsbruckerstrasse 90',
  AddressLine2: '',
  PostCode: 6060,
  TownCity: 'Hall',
  Latitude: '4.727.700.000',
  Longitude: '1.147.380.000',
  Telephone: '+43(0)5223/57777',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.277,11.4738',
  id: '47.277,11.4738'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gewerbepark Süd 12',
  AddressLine2: '',
  PostCode: 6330,
  TownCity: 'Kufstein',
  Latitude: '4.756.626.000',
  Longitude: '1.215.626.000',
  Telephone: '+43(0)5372/61622',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.56626,12.15626',
  id: '47.56626,12.15626'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 87',
  AddressLine2: '',
  PostCode: 3580,
  TownCity: 'Horn',
  Latitude: '4.866.258.000',
  Longitude: '1.567.015.000',
  Telephone: '+43(0)2982/2369',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.66258,15.67015',
  id: '48.66258,15.67015'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Frauentaler Strasse 122',
  AddressLine2: '',
  PostCode: 8530,
  TownCity: 'Deutschlandsberg',
  Latitude: '4.682.068.000',
  Longitude: '1.523.672.000',
  Telephone: '+43(0)3462/30061',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.82068,15.23672',
  id: '46.82068,15.23672'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Huben 40',
  AddressLine2: '',
  PostCode: 9971,
  TownCity: 'Matrei in Osttirol',
  Latitude: '4.693.460.000',
  Longitude: '1.257.500.000',
  Telephone: '+43(0)4872/5253',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.9346,12.575',
  id: '46.9346,12.575'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Niederland 218',
  AddressLine2: '',
  PostCode: 5091,
  TownCity: 'Unken',
  Latitude: '4.765.123.000',
  Longitude: '1.273.452.000',
  Telephone: '+43(0)6589/7291',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.65123,12.73452',
  id: '47.65123,12.73452'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Lanserstrasse 2A',
  AddressLine2: '',
  PostCode: 6071,
  TownCity: 'Aldrans',
  Latitude: '4.724.823.000',
  Longitude: '1.144.406.000',
  Telephone: '+43(0)512/341234',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.24823,11.44406',
  id: '47.24823,11.44406'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Villacherstr. 44',
  AddressLine2: '',
  PostCode: 9800,
  TownCity: 'Spittal a.d. Drau',
  Latitude: '4.679.190.000',
  Longitude: '1.350.830.000',
  Telephone: '+43(0)4762/2717',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.7919,13.5083',
  id: '46.7919,13.5083'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Hausmeninger Str. 74',
  AddressLine2: '',
  PostCode: 3362,
  TownCity: 'Mauer bei Amstetten',
  Latitude: '480.825.413',
  Longitude: '148.017.456',
  Telephone: '+43747552619',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.0825413,14.8017456',
  id: '48.0825413,14.8017456'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wachauer Bundesstrasse',
  AddressLine2: '',
  PostCode: 3620,
  TownCity: 'Spitz',
  Latitude: '4.836.250.000',
  Longitude: '1.541.680.000',
  Telephone: '+43(0)2713/2111',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.3625,15.4168',
  id: '48.3625,15.4168'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bahnhofstrasse 37',
  AddressLine2: '',
  PostCode: 3370,
  TownCity: 'Ybbs an der Donau',
  Latitude: '4.816.579.000',
  Longitude: '1.509.091.000',
  Telephone: '+43(0)7412/52431',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.16579,15.09091',
  id: '48.16579,15.09091'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Triesterstr. 285-287',
  AddressLine2: '',
  PostCode: 1230,
  TownCity: 'Wien',
  Latitude: '4.814.170.000',
  Longitude: '1.632.960.000',
  Telephone: '+43(0)1/6673109',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1417,16.3296',
  id: '48.1417,16.3296'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Linzer Strasse 15',
  AddressLine2: '',
  PostCode: 4072,
  TownCity: 'Alkoven',
  Latitude: '4.828.863.000',
  Longitude: '1.411.763.000',
  Telephone: '+43(0)7274/6360',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.28863,14.11763',
  id: '48.28863,14.11763'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Südautobahn Ri Österreich',
  AddressLine2: '',
  PostCode: 9601,
  TownCity: 'Arnoldstein',
  Latitude: '4.655.109.000',
  Longitude: '1.368.326.000',
  Telephone: '+43(0)4255/2885',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.55109,13.68326',
  id: '46.55109,13.68326'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Obertrumer Landstrasse 11',
  AddressLine2: '',
  PostCode: 5201,
  TownCity: 'Seekirchen',
  Latitude: '479.036.543',
  Longitude: '131.205.386',
  Telephone: '+43624672648',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.9036543,13.1205386',
  id: '47.9036543,13.1205386'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Villacher Strasse 21',
  AddressLine2: '',
  PostCode: 9220,
  TownCity: 'Velden',
  Latitude: '4.661.364.000',
  Longitude: '1.403.563.000',
  Telephone: '+43(0)4274/2482',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.61364,14.03563',
  id: '46.61364,14.03563'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Obergäu 310',
  AddressLine2: '',
  PostCode: 5440,
  TownCity: 'Golling',
  Latitude: '4.759.194.000',
  Longitude: '1.317.540.000',
  Telephone: '+43(0)6244/21150',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.59194,13.1754',
  id: '47.59194,13.1754'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstraße 132',
  AddressLine2: '',
  PostCode: 8605,
  TownCity: 'Kapfenberg',
  Latitude: '4.745.479.000',
  Longitude: '1.531.747.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.45479,15.31747',
  id: '47.45479,15.31747'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Zellerstr. 38',
  AddressLine2: '',
  PostCode: 5730,
  TownCity: 'Mittersill',
  Latitude: '4.728.520.000',
  Longitude: '1.249.450.000',
  Telephone: '+43(0)6562/4406',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.2852,12.4945',
  id: '47.2852,12.4945'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mitschastrasse 38',
  AddressLine2: '',
  PostCode: 2130,
  TownCity: 'Mistelbach',
  Latitude: '4.856.456.000',
  Longitude: '1.657.595.000',
  Telephone: '+43(0)2572/2389',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.56456,16.57595',
  id: '48.56456,16.57595'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Seeringstraße  2',
  AddressLine2: '',
  PostCode: 4880,
  TownCity: 'St. Georgen im Attergau',
  Latitude: '4.792.917.000',
  Longitude: '1.350.204.000',
  Telephone: '+43-7667-80068',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.92917,13.50204',
  id: '47.92917,13.50204'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstr. 28-30',
  AddressLine2: '',
  PostCode: 7442,
  TownCity: 'Lockenhaus',
  Latitude: '4.741.090.000',
  Longitude: '1.641.470.000',
  Telephone: '+43(0)2616/2253',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4109,16.4147',
  id: '47.4109,16.4147'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Rosentalerstr. 196',
  AddressLine2: '',
  PostCode: 9020,
  TownCity: 'Klagenfurt',
  Latitude: '4.660.350.000',
  Longitude: '1.429.695.000',
  Telephone: '+43(0)463/23330',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.6035,14.29695',
  id: '46.6035,14.29695'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Linzer Straße 24',
  AddressLine2: '',
  PostCode: 4070,
  TownCity: 'Eferding',
  Latitude: '4.830.176.000',
  Longitude: '1.402.967.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.30176,14.02967',
  id: '48.30176,14.02967'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Murtalerstrasse 579',
  AddressLine2: '',
  PostCode: 5582,
  TownCity: 'St. Michael',
  Latitude: '470.954.278',
  Longitude: '136.385.084',
  Telephone: '',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '21:00',
  SundayFrom: '06:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.0954278,13.6385084',
  id: '47.0954278,13.6385084'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Krottenbachstr. 32-34',
  AddressLine2: '',
  PostCode: 1190,
  TownCity: 'Wien',
  Latitude: '4.824.070.000',
  Longitude: '1.633.930.000',
  Telephone: '+43(0)1/3696149',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.2407,16.3393',
  id: '48.2407,16.3393'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Leitersdorf 177',
  AddressLine2: '',
  PostCode: 8330,
  TownCity: 'Feldbach',
  Latitude: '4.694.936.000',
  Longitude: '1.594.368.000',
  Telephone: '+43(0)315/239002',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.94936,15.94368',
  id: '46.94936,15.94368'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Burgstrasse 29',
  AddressLine2: '',
  PostCode: 6091,
  TownCity: 'Götzens',
  Latitude: '4.723.915.000',
  Longitude: '1.131.189.000',
  Telephone: '+43(0)5234/33881',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.23915,11.31189',
  id: '47.23915,11.31189'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mühlgraben  22',
  AddressLine2: '',
  PostCode: 8773,
  TownCity: 'Kammern',
  Latitude: '4.738.843.000',
  Longitude: '1.490.320.000',
  Telephone: '+43(0)3844/8747',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.38843,14.9032',
  id: '47.38843,14.9032'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Talstrasse 25',
  AddressLine2: '',
  PostCode: 6284,
  TownCity: 'Ramsau',
  Latitude: '4.721.043.000',
  Longitude: '1.187.250.000',
  Telephone: '+43(0)5282/21563',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.21043,11.8725',
  id: '47.21043,11.8725'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'August-Resch-Strasse 2',
  AddressLine2: '',
  PostCode: 2542,
  TownCity: 'Kottingbrunn',
  Latitude: '4.795.510.000',
  Longitude: '1.621.933.000',
  Telephone: '+43(0)2252/25164',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.9551,16.21933',
  id: '47.9551,16.21933'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Allgäuer Strasse 66',
  AddressLine2: '',
  PostCode: 6600,
  TownCity: 'Reutte',
  Latitude: '4.749.905.000',
  Longitude: '1.072.006.000',
  Telephone: '+43(0)5672/62846',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.49905,10.72006',
  id: '47.49905,10.72006'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn A5 / Brünner Straße KM 11',
  AddressLine2: '',
  PostCode: 2123,
  TownCity: 'Wolfpassing',
  Latitude: '4.842.490.000',
  Longitude: '1.654.799.000',
  Telephone: '+43-2245-89003',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.4249,16.54799',
  id: '48.4249,16.54799'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Rosegger Strasse 15',
  AddressLine2: '',
  PostCode: 9220,
  TownCity: 'Velden',
  Latitude: '4.660.427.000',
  Longitude: '1.403.532.400',
  Telephone: '+43(0)4274/34801',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.60427,14.035324',
  id: '46.60427,14.035324'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Alpenstrasse 111',
  AddressLine2: '',
  PostCode: 5081,
  TownCity: 'Anif',
  Latitude: '477.362.928',
  Longitude: '130.507.707',
  Telephone: '',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.7362928,13.0507707',
  id: '47.7362928,13.0507707'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Industriestrasse 12',
  AddressLine2: '',
  PostCode: 6134,
  TownCity: 'Vomp',
  Latitude: '4.735.317.000',
  Longitude: '1.170.026.000',
  Telephone: '+43(0)5242/62783',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.35317,11.70026',
  id: '47.35317,11.70026'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Umfahrungsstr. 688',
  AddressLine2: '',
  PostCode: 6290,
  TownCity: 'Mayrhofen',
  Latitude: '4.716.000.000',
  Longitude: '1.184.930.000',
  Telephone: '+43(0)5285/8266',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.16,11.8493',
  id: '47.16,11.8493'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'St. Veiterstr. 61',
  AddressLine2: '',
  PostCode: 9020,
  TownCity: 'Klagenfurt',
  Latitude: '4.663.590.000',
  Longitude: '1.431.180.000',
  Telephone: '+43(0)463/440392',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.6359,14.3118',
  id: '46.6359,14.3118'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstr. 12',
  AddressLine2: '',
  PostCode: 8650,
  TownCity: 'Kindberg',
  Latitude: '4.751.208.300',
  Longitude: '1.545.672.790',
  Telephone: '+43(0)3865/2539',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.512083,15.4567279',
  id: '47.512083,15.4567279'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Am Wachtberg 5',
  AddressLine2: '',
  PostCode: 4441,
  TownCity: 'Behamberg/Steyr',
  Latitude: '4.804.618.000',
  Longitude: '1.447.219.000',
  Telephone: '+43(0)7252/80404',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.04618,14.47219',
  id: '48.04618,14.47219'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrasse 38',
  AddressLine2: '',
  PostCode: 2351,
  TownCity: 'Wr. Neudorf',
  Latitude: '4.808.638.000',
  Longitude: '1.631.786.000',
  Telephone: '+43(0)2236/62410',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.08638,16.31786',
  id: '48.08638,16.31786'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Schweizer Strasse 45',
  AddressLine2: '',
  PostCode: 6844,
  TownCity: 'Altach',
  Latitude: '4.735.316.000',
  Longitude: '963.469.000',
  Telephone: '+43(0)5523/62094',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.35316,9.63469',
  id: '47.35316,9.63469'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Liechtensteinerstrasse 115',
  AddressLine2: '',
  PostCode: 6800,
  TownCity: 'Feldkirch',
  Latitude: '4.722.187.000',
  Longitude: '957.614.000',
  Telephone: '+43(0)5522/73119',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.22187,9.57614',
  id: '47.22187,9.57614'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Riesstraße 422',
  AddressLine2: '',
  PostCode: 8010,
  TownCity: 'Kainbach',
  Latitude: '4.710.066.000',
  Longitude: '1.555.810.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.10066,15.5581',
  id: '47.10066,15.5581'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstr. 97',
  AddressLine2: '',
  PostCode: 3300,
  TownCity: 'Amstetten',
  Latitude: '4.812.269.000',
  Longitude: '1.488.671.940',
  Telephone: '+43(0)7472/62558',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.12269,14.8867194',
  id: '48.12269,14.8867194'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrasse 456',
  AddressLine2: '',
  PostCode: 8962,
  TownCity: 'Gröbming',
  Latitude: '4.744.907.000',
  Longitude: '1.390.387.000',
  Telephone: '+43(0)3685/22195',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.44907,13.90387',
  id: '47.44907,13.90387'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstraße 2',
  AddressLine2: '',
  PostCode: 8063,
  TownCity: 'Eggersdorf bei Graz',
  Latitude: '4.712.395.000',
  Longitude: '1.561.076.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.12395,15.61076',
  id: '47.12395,15.61076'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Oeynhausner Strasse 55',
  AddressLine2: '',
  PostCode: 2512,
  TownCity: 'Tribuswinkel',
  Latitude: '4.800.083.000',
  Longitude: '1.626.912.000',
  Telephone: '+43(0)2252/80317',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.00083,16.26912',
  id: '48.00083,16.26912'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gudrunstr. 116 - 118',
  AddressLine2: '',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '4.817.806.000',
  Longitude: '1.637.857.000',
  Telephone: '+43(0)1/6042372',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.17806,16.37857',
  id: '48.17806,16.37857'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Fischauergasse 34',
  AddressLine2: '',
  PostCode: 2700,
  TownCity: 'Wr. Neustadt',
  Latitude: '4.782.250.000',
  Longitude: '1.624.210.000',
  Telephone: '+43(0)2622/22884',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.8225,16.2421',
  id: '47.8225,16.2421'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Lanzendorfer Straße 471/1',
  AddressLine2: '',
  PostCode: 2481,
  TownCity: 'Achau',
  Latitude: '4.808.196.000',
  Longitude: '1.639.019.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.08196,16.39019',
  id: '48.08196,16.39019'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Neustadter Bundesstr., 135',
  AddressLine2: '',
  PostCode: 2512,
  TownCity: 'Oeynhausen',
  Latitude: '4.799.723.000',
  Longitude: '1.629.984.000',
  Telephone: '+43(0)2252/84631',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.99723,16.29984',
  id: '47.99723,16.29984'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Innsbruckerstrasse 171',
  AddressLine2: '',
  PostCode: 6230,
  TownCity: 'Brixlegg',
  Latitude: '4.743.262.000',
  Longitude: '1.187.866.000',
  Telephone: '+43(0)5337/62598',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.43262,11.87866',
  id: '47.43262,11.87866'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Färberstrasse 10',
  AddressLine2: '',
  PostCode: 5760,
  TownCity: 'Saalfelden',
  Latitude: '4.742.614.000',
  Longitude: '1.284.030.000',
  Telephone: '+43(0)6582/72620',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.42614,12.8403',
  id: '47.42614,12.8403'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Erdbergstrasse 189',
  AddressLine2: '',
  PostCode: 1110,
  TownCity: 'Wien',
  Latitude: '4.818.596.000',
  Longitude: '1.642.439.000',
  Telephone: '+43(0)1/74020463',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.18596,16.42439',
  id: '48.18596,16.42439'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Ignaz Harrerstr. 78',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.781.210.000',
  Longitude: '1.302.400.000',
  Telephone: '+43(0)662/433629',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.8121,13.024',
  id: '47.8121,13.024'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Parndorferstr. 103',
  AddressLine2: '',
  PostCode: 2460,
  TownCity: 'Bruckneudorf',
  Latitude: '4.802.100.000',
  Longitude: '1.678.390.000',
  Telephone: '+43(0)2162/62434',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.021,16.7839',
  id: '48.021,16.7839'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 63',
  AddressLine2: '',
  PostCode: 3500,
  TownCity: 'Krems',
  Latitude: '4.841.439.000',
  Longitude: '1.561.417.000',
  Telephone: '+43(0)2732/84967',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.41439,15.61417',
  id: '48.41439,15.61417'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Kärntner Strasse 84',
  AddressLine2: '',
  PostCode: 9900,
  TownCity: 'Lienz',
  Latitude: '4.683.116.000',
  Longitude: '1.278.376.000',
  Telephone: '+43(0)4852/63020',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.83116,12.78376',
  id: '46.83116,12.78376'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'S 36 Murtal-Schnellstr. Nord',
  AddressLine2: '',
  PostCode: 8733,
  TownCity: 'St.Marein/Knittelf.',
  Latitude: '4.725.337.000',
  Longitude: '1.487.890.000',
  Telephone: '+43(0)3515/4331',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.25337,14.8789',
  id: '47.25337,14.8789'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bonygasse/Ruckergasse',
  AddressLine2: '',
  PostCode: 1120,
  TownCity: 'Wien',
  Latitude: '4.817.907.900',
  Longitude: '1.632.654.200',
  Telephone: '+43(0)1/8155136',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.179079,16.326542',
  id: '48.179079,16.326542'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Anton Regner Strasse 59',
  AddressLine2: '',
  PostCode: 8720,
  TownCity: 'Knittelfeld',
  Latitude: '4.721.828.000',
  Longitude: '1.481.564.000',
  Telephone: '+43(0)3512/82180',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.21828,14.81564',
  id: '47.21828,14.81564'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Nibelungenstrasse 68',
  AddressLine2: '',
  PostCode: 3680,
  TownCity: 'Persenbeug',
  Latitude: '481.898.373',
  Longitude: '150.960.731',
  Telephone: '+43741254840',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '48.1898373,15.0960731',
  id: '48.1898373,15.0960731'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Welser Strasse 8',
  AddressLine2: '',
  PostCode: 4060,
  TownCity: 'Leonding-Gaumbg.',
  Latitude: '4.827.470.000',
  Longitude: '1.427.285.000',
  Telephone: '+43(0)732/671776',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.2747,14.27285',
  id: '48.2747,14.27285'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrasse 20',
  AddressLine2: '',
  PostCode: 6600,
  TownCity: 'Höfen',
  Latitude: '4.747.572.000',
  Longitude: '1.069.408.000',
  Telephone: '+43(0)5672/64750',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.47572,10.69408',
  id: '47.47572,10.69408'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Schallmooser Hauptstrasse 54',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.780.776.000',
  Longitude: '1.306.226.000',
  Telephone: '+43(0)662/640034',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.80776,13.06226',
  id: '47.80776,13.06226'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Tirolerstrasse 17d',
  AddressLine2: '',
  PostCode: 9900,
  TownCity: 'Lienz',
  Latitude: '4.682.770.000',
  Longitude: '1.276.640.000',
  Telephone: '+43(0)4852/63428',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.8277,12.7664',
  id: '46.8277,12.7664'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Tirolerstrasse 4',
  AddressLine2: '',
  PostCode: 9781,
  TownCity: 'Oberdrauburg',
  Latitude: '4.674.921.000',
  Longitude: '1.296.380.000',
  Telephone: '+43(0)4710/29778',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.74921,12.9638',
  id: '46.74921,12.9638'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Rasing 11',
  AddressLine2: '',
  PostCode: 8630,
  TownCity: 'Mariazell',
  Latitude: '4.776.414.000',
  Longitude: '1.530.968.000',
  Telephone: '+43(0)3882/2747',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.76414,15.30968',
  id: '47.76414,15.30968'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Schanz 1A',
  AddressLine2: '',
  PostCode: 6632,
  TownCity: 'Ehrwald',
  Latitude: '4.742.880.000',
  Longitude: '1.091.840.000',
  Telephone: '+43(0)5673/20073',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4288,10.9184',
  id: '47.4288,10.9184'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Rheinstrasse 4',
  AddressLine2: '',
  PostCode: 6900,
  TownCity: 'Bregenz',
  Latitude: '4.749.850.000',
  Longitude: '973.258.000',
  Telephone: '+43(0)5574/43760',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4985,9.73258',
  id: '47.4985,9.73258'
}];