import { PURGE } from 'redux-persist';
import clone from 'lodash.clone';
import initialState from './InitialState';
import TYPES from '../../@types/redux/store/CameraTypes';
import { CameraAction, CameraState } from '../../@types';

export default (
  state: CameraState = initialState,
  action: CameraAction
): CameraState => {
  switch (action.type) {
    case TYPES.SET_CAMERA_IMAGES: {
      return { ...state, images: [...state.images, action.payload] };
    }

    case TYPES.REMOVE_CAMERA_IMAGE: {
      return {
        ...state,
        images: state.images.filter(
          // $FlowFixMe
          (n) => n.id !== action.payload.id
        ),
      };
    }

    case TYPES.CLEAR_CAMERA_IMAGES: {
      return { ...state, images: [], carCheckImages: {} };
    }

    case TYPES.SET_CAR_CHECK_CAMERA_IMAGE: {
      const updated = clone(state.carCheckImages);
      if (action.payload.index === 1) updated.imageOne = action.payload;
      if (action.payload.index === 2) updated.imageTwo = action.payload;
      if (action.payload.index === 3) updated.imageThree = action.payload;
      if (action.payload.index === 4) updated.imageFour = action.payload;
      return { ...state, carCheckImages: updated };
    }

    case TYPES.REMOVE_CAR_CHECK_CAMERA_IMAGE: {
      const updated = clone(state.carCheckImages);
      if (action.payload.index === 1) delete updated.imageOne;
      if (action.payload.index === 2) delete updated.imageTwo;
      if (action.payload.index === 3) delete updated.imageThree;
      if (action.payload.index === 4) delete updated.imageFour;
      return { ...state, carCheckImages: updated };
    }
    // @ts-ignore
    case PURGE:
      return initialState;

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
