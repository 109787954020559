import { UserDataOutput, PaymentRequestData } from '../../index';
export type UserState = {
  readonly email: string;
  readonly user: UserDataOutput | null | undefined;
  readonly basicAuth: string | null | undefined;
  readonly transactions: any;
  readonly paymentPublicKey: string | null | undefined;
  readonly jumioRedirectData: any | null | undefined;
  readonly activePaymentData: PaymentRequestData | null | undefined;
  readonly recoverPaymentAuth: boolean;
  readonly signUpCircle: any;
  readonly rentalHistory: any;
};
enum UserTypes {
  SET_USER = 'userData/user/SET_USER',
  SET_AUTH = 'userData/user/SET_AUTH',
  SET_EMAIL = 'userData/user/SET_EMAIL',
  SET_ACTIVE_PAYMENT_DATA = 'userData/user/SET_ACTIVE_PAYMENT_DATA',
  SET_PAYMENT_PUBLIC_KEY = 'userData/user/SET_PAYMENT_PUBLIC_KEY',
  SET_JUMIO_REDIRECT_DATA = 'userData/user/SET_JUMIO_REDIRECT_DATA',
  SET_RECOVER_PAYMENT_AUTH = 'userData/user/SET_RECOVER_PAYMENT_AUTH',
  CLEAR_AUTH = 'userData/user/CLEAR_AUTH',
  SET_TRANSACTIONS = 'userData/user/SET_TRANSACTIONS',
  APPEND_TRANSACTIONS = 'userData/user/APPEND_TRANSACTIONS',
  SET_RENTAL_HISTORY = 'userData/user/SET_RENTAL_HISTORY',
  APPEND_RENTAL_HISTORY = 'userData/user/APPEND_RENTAL_HISTORY',
  SET_SIGN_UP_CIRCLE = 'userData/user/SET_SIGN_UP_CIRCLE',
  RESET_STORE = 'RESET_STORE',
}
export default UserTypes;
export type SetUser = {
  type: UserTypes.SET_USER;
  payload: {
    user: UserDataOutput | null | undefined;
  };
};
export type SetEmail = {
  type: UserTypes.SET_EMAIL;
  payload: {
    email: string;
  };
};
export type SetActivePaymentData = {
  type: UserTypes.SET_ACTIVE_PAYMENT_DATA;
  payload: {
    activePaymentData: PaymentRequestData | null | undefined;
  };
};
export type SetPaymentPublicKey = {
  type: UserTypes.SET_PAYMENT_PUBLIC_KEY;
  payload: {
    paymentPublicKey: string | null | undefined;
  };
};
export type SetJumioRedirectData = {
  type: UserTypes.SET_JUMIO_REDIRECT_DATA;
  payload: {
    jumioRedirectData: any | null | undefined;
  };
};
export type SetRecoverPaymentAuth = {
  type: UserTypes.SET_RECOVER_PAYMENT_AUTH;
  payload: {
    recoverPaymentAuth: boolean;
  };
};
export type SetAuth = {
  type: UserTypes.SET_AUTH;
  payload: {
    basicAuth: string;
  };
};
export type SetSignUpCircle = {
  type: UserTypes.SET_SIGN_UP_CIRCLE;
  payload: {
    circleCode: string;
  };
};
export type ClearAuth = {
  type: UserTypes.CLEAR_AUTH;
};
export type SetTransactions = {
  type: UserTypes.SET_TRANSACTIONS;
  payload: {
    transactions: any;
  };
};
export type AppendTransactions = {
  type: UserTypes.APPEND_TRANSACTIONS;
  payload: {
    transactions: any;
  };
};
export type SetRentalHistory = {
  type: UserTypes.SET_RENTAL_HISTORY;
  payload: {
    rentalHistory: any;
  };
};
export type AppendRentalHistory = {
  type: UserTypes.APPEND_RENTAL_HISTORY;
  payload: {
    rentalHistory: any;
  };
};
export type UserAction =
  | SetUser
  | SetEmail
  | SetAuth
  | ClearAuth
  | SetTransactions
  | SetActivePaymentData
  | SetPaymentPublicKey
  | SetJumioRedirectData
  | SetRecoverPaymentAuth
  | AppendTransactions
  | SetRentalHistory
  | AppendRentalHistory
  | SetSignUpCircle;
