import { v4 as uuidv4 } from 'uuid';
import { APIError } from '../../@types';
import TYPES from '../../@types/redux/store/NetworkStatusTypes';
import {
  SetNetworkActivity,
  SetNetworkSuccess,
  DismissNetworkError,
  ClearNetworkErrors,
  SetGetHistory,
  Dispatch,
} from '../../@types';
import { CLIENT_TYPE } from '../../@types';
import { setBookingViolations } from '../userData/bookings';
import { setRentalViolations } from '../userData/rental';
import { setFinishRentalViolations } from '../userData/rental/actions';

export const setNetworkActivity = (type: string): SetNetworkActivity => ({
  type: TYPES.SET_NETWORK_ACTIVITY,
  payload: {
    type,
  },
});
export const setNetworkSuccess = (type: string): SetNetworkSuccess => ({
  type: TYPES.SET_NETWORK_SUCCESS,
  payload: {
    type,
  },
});
export const clearNetworkErrors = (): ClearNetworkErrors => ({
  type: TYPES.CLEAR_NETWORK_ERRORS,
});
export const dismissNetworkError = (id: string): DismissNetworkError => ({
  type: TYPES.DISMISS_NETWORK_ERROR,
  payload: {
    id,
  },
});
export const setNetworkError =
  (type: string, error: APIError, message?: string) => (dispatch: Dispatch) => {
    const errorString =
      (error.detail && (error.detail.message || error.detail.data)) ||
      'Error occurred performing network request';
    const id = uuidv4();
    dispatch({
      type: TYPES.SET_NETWORK_ERROR,
      payload: {
        id,
        type,
        error,
        message: message || errorString,
      },
    });

    if (
      type === CLIENT_TYPE.BOOKING_CLIENT.BOOK_CAR ||
      type === CLIENT_TYPE.RENTAL_CLIENT.RESERVE_CAR ||
      type === CLIENT_TYPE.RENTAL_CLIENT.FINISH_RENTAL ||
      type === CLIENT_TYPE.RENTAL_CLIENT.CAN_FINISH
    ) {
      if (error.detail && error.detail.data && error.detail.data.violations) {
        const { violations } = error.detail.data;

        if (type === CLIENT_TYPE.BOOKING_CLIENT.BOOK_CAR) {
          dispatch(setBookingViolations(violations));
        } else if (type === CLIENT_TYPE.RENTAL_CLIENT.RESERVE_CAR) {
          dispatch(setRentalViolations(violations));
        } else if (type === CLIENT_TYPE.RENTAL_CLIENT.FINISH_RENTAL) {
          dispatch(setFinishRentalViolations(violations));
        } else if (type === CLIENT_TYPE.RENTAL_CLIENT.CAN_FINISH) {
          const locationViolations = Object.keys(violations)
            .filter((violation) =>
              [
                'correctLocation',
                'insideArea',
                'fuelLevel',
                'extremelyLowFuelLevel',
                'finishLocationAllowed',
                'bitLessFuelOnReturn',
              ].includes(violation)
            )
            .reduce(
              (obj, violation) => ({
                ...obj,
                [violation]: violations[violation],
              }),
              {}
            );
          dispatch(setFinishRentalViolations(locationViolations));
        }
      }
    }

    setTimeout(() => dispatch(dismissNetworkError(id)), 1000);
  };
export const setGetHistory = (
  requestHash: string,
  lastFetchedAt: string
): SetGetHistory => ({
  type: TYPES.SET_GET_HISTORY,
  payload: {
    requestHash,
    lastFetchedAt,
  },
});
