import {
  setNetworkActivity,
  setNetworkSuccess,
  setNetworkError,
} from '../networkStatus';
import { CLIENT_TYPE } from '../../@types';
import { RentalData } from '../../@types';
import TYPES from '../../@types/redux/store/ServiceTypes';
import { APP } from '../../enums';
import { setNotification } from '../ui/notifications';
import { Dispatch, GetState, SetServices } from '../../@types';
import serviceClient from '../../clients/serviceClient';

export const setServices = (services: Array<RentalData>): SetServices => ({
  type: TYPES.SET_SERVICES,
  payload: {
    services,
  },
});
export const getServices =
  (callback?: () => void) => async (dispatch: Dispatch, getState: GetState) => {
    const isServiceHero =
      getState().userData.user?.user?.serviceHero ||
      !!getState().userData.user?.user?.circles?.find(
        (circle) =>
          circle.id === getState().carsSearch.circleId &&
          circle?.circleType === 'service'
      );

    if (!isServiceHero) {
      if (typeof callback === 'function') {
        callback();
      }

      return;
    }

    dispatch(setNetworkActivity(CLIENT_TYPE.SERVICE_CLIENT.GET_SERVICES));
    // $FlowFixMe
    const { notModified, data, error } = await serviceClient.getServices();

    if (error) {
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
      dispatch(setNetworkError(CLIENT_TYPE.SERVICE_CLIENT.GET_SERVICES, error));
    } else {
      if (!!data && !notModified) {
        dispatch(setServices(data));
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.SERVICE_CLIENT.GET_SERVICES));
    }

    if (typeof callback === 'function') {
      callback();
    }
  };
