/* eslint-disable no-undef */
import { isIOS } from './isIOS';
import { isWeb } from './isWeb';
import { Linking } from '../config';

export const callPhoneNumber = async (phoneNumber: string) => {
  if (isWeb()) {
    window.open(`tel:${phoneNumber}`);
  } else {
    const clippedPhoneNumber = phoneNumber.replace(/[/\s.]/g, '');
    const url = `${isIOS() ? 'telprompt' : 'tel'}:${clippedPhoneNumber}`;
    const supported = await Linking?.canOpenURL(url);

    if (supported) {
      await Linking?.openURL(url);
    } else {
      // @ts-ignore
      if (__DEV__) {
        console.log(`Can't Open the URL: ${url}`);
      }
    }
  }
};
