import { makeAPICall } from './helpers';
import {
  APIResponse,
  UpdateUserInputData,
  UserDataOutput,
  PaymentMethodDataInput,
  Transactions,
  RentalHistoryDataList,
  PayByLinkData,
  PaymentRequestData,
} from '../@types';
import { uploadFileFromStorage } from './helpers/makeUploadFileAPICall';

const userClient = {
  get: async (): Promise<APIResponse<UserDataOutput>> => {
    const { data, error } = await makeAPICall({
      method: 'GET',
      url: '/user',
      checkModified: false,
    });
    return {
      data,
      error,
    };
  },
  updateUser: async (
    updateUserInputData: UpdateUserInputData
  ): Promise<APIResponse<UserDataOutput>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'PUT',
      url: '/user',
      data: updateUserInputData,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  getPaymentData: async (
    id: string
  ): Promise<APIResponse<PaymentRequestData>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: `/user/paymentMethods/paymentRequest/${id}`,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  addPayment: async (
    paymentMethodDataInput: any
  ): Promise<APIResponse<UserDataOutput>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'POST',
      url: '/user/paymentMethods',
      data: paymentMethodDataInput,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  updatePayment: async (
    paymentId: string,
    paymentData: any
  ): Promise<APIResponse<UserDataOutput>> => {
    const { data, error } = await makeAPICall({
      method: 'PUT',
      url: `/user/paymentMethods/${paymentId}`,
      data: paymentData,
    });
    return {
      data,
      error,
    };
  },
  deletePayment: async (
    paymentId: string
  ): Promise<APIResponse<UserDataOutput>> => {
    const { data, error } = await makeAPICall({
      method: 'DELETE',
      url: `/user/paymentMethods/${paymentId}`,
    });
    return {
      data,
      error,
    };
  },
  getTransactions: async (
    offset: number = 0,
    limit: number = 20
  ): Promise<APIResponse<Transactions>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: '/user/transactions',
      params: {
        offset,
        limit,
      },
    });
    return {
      notModified,
      data,
      error,
    };
  },
  getTransactionPaymentLink: async (
    transactionId: number
  ): Promise<APIResponse<PayByLinkData>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: `/user/transactions/${transactionId}/paymentLink`,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  terminateUser: async (): Promise<APIResponse<null>> => {
    const { error } = await makeAPICall({
      method: 'DELETE',
      url: '/user',
    });
    return {
      error,
    };
  },
  jumioDocumentUploaded: async (
    scanReference: string,
    type: string
  ): Promise<APIResponse<any>> => {
    const { notModified, error } = await makeAPICall({
      method: 'PUT',
      url: `/user/jumioImageUploaded/${scanReference}`,
      params: {
        documentType: type,
      },
    });
    return {
      notModified,
      error,
    };
  },
  schufaCheck: async (
    updateUserInputData: UpdateUserInputData
  ): Promise<APIResponse<UserDataOutput>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'PUT',
      url: '/user/schufaCheck',
      data: updateUserInputData,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  addressCheck: async (
    updateUserInputData: UpdateUserInputData
  ): Promise<APIResponse<UserDataOutput>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'PUT',
      url: '/user/addressCheck',
      data: updateUserInputData,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  sendVerificationCode: async (): Promise<APIResponse<null>> => {
    const { error } = await makeAPICall({
      method: 'GET',
      url: '/user/sendVerificationCode',
    });
    return {
      error,
    };
  },
  joinCircle: async (
    circleCode: string
  ): Promise<APIResponse<UserDataOutput>> => {
    const { data, error } = await makeAPICall({
      method: 'PUT',
      url: `/user/circle/${circleCode}`,
    });
    return {
      data,
      error,
    };
  },
  removeCircle: async (
    circleCode: string
  ): Promise<APIResponse<UserDataOutput>> => {
    const { data, error } = await makeAPICall({
      method: 'DELETE',
      url: `/user/circle/${circleCode}`,
    });
    return {
      data,
      error,
    };
  },
  getRentalHistory: async (
    afterId: string | null | undefined = null,
    limit: number = 20
  ): Promise<APIResponse<RentalHistoryDataList>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: '/user/rentals',
      params: {
        afterId,
        limit,
      },
    });
    return {
      notModified,
      data,
      error,
    };
  },
  uploadDocument: async (
    uri: string,
    type: 'ADDRESS_PROOF' | 'OTHER'
  ): Promise<APIResponse<null>> => {
    const { error } = await uploadFileFromStorage(
      `user/uploadDocument?documentType=${type}`,
      uri,
      'PUT'
    );
    return {
      error,
    };
  },
  getPaymentPublicKey: async (): Promise<APIResponse<string>> => {
    const { data, error } = await makeAPICall({
      method: 'GET',
      url: '/user/paymentMethods/adyen/publicKey',
      headers: {
        Accept: '*/*',
      },
    });
    return {
      data,
      error,
    };
  },
  initJumioForWeb: async (jumioWebInput: any): Promise<APIResponse<any>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'POST',
      url: '/user/jumio/initiateForWeb',
      data: jumioWebInput,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  verifyJumioSuccess: async (scanRef: any): Promise<APIResponse<any>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'POST',
      url: `user/verify/user/success/${scanRef}`,
    });
    return {
      notModified,
      data,
      error,
    };
  },
};
export default userClient;
