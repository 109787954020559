const initialState = {
  default: {
    latitude: 52.519783,
    longitude: 13.416321,
    latitudeDelta: 0.034956158,
    longitudeDelta: 0.02826149,
  },
  ubeeqo: {
    latitude: 48.18026139,
    longitude: 16.33216349,
    latitudeDelta: 0.034956158,
    longitudeDelta: 0.02826149,
  },
  robbenWientjes: {
    latitude: 52.519783,
    longitude: 13.416321,
    latitudeDelta: 0.034956158,
    longitudeDelta: 0.02826149,
  },
  konig: {
    latitude: 52.45888496948055,
    longitude: 13.458905238031178,
    latitudeDelta: 0.4806053102119989,
    longitudeDelta: 0.39950458680294787,
  },
};
export default initialState;
