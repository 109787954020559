import { makeAPICall } from './helpers';
import { APIResponse, RentalData } from '../@types';

const serviceClient = {
  getServices: async (): Promise<APIResponse<Array<RentalData>>> => {
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: '/services',
    });
    return {
      notModified,
      data,
      error,
    };
  },
};
export default serviceClient;
