import { isBefore } from 'date-fns';
import { throttle } from 'throttle-debounce';
import carsClient from '../../clients/carsClient';
import {
  setNetworkActivity,
  setNetworkSuccess,
  setNetworkError,
} from '../networkStatus';
import { CLIENT_TYPE } from '../../@types';
import {
  CompactCarData,
  CompactFoundCars,
  DriverCarData,
  FoundCars,
  Location,
} from '../../@types';
import TYPES from '../../@types/redux/store/CarsSearchTypes';
import {
  ClearAvailableCars,
  ClearBackBy,
  ClearMapCircleIdSearch,
  ClearUnavailableCars,
  MapCars,
  SetCategories,
  SetFrom,
  SetMapCenterLocation,
  SetMapCircleIdSearch,
  SetRadius,
  Dispatch,
  GetState,
} from '../../@types';
import {
  updateMapContentWithCar,
  setMapContent,
  memorizedSortedCars,
  resolveInitialMapContent,
} from '../ui/map';
import { APP } from '../../enums';
import { setNotification } from '../ui/notifications';
import {
  distanceBetweenLocationsInKm,
  isWeb,
  locationFromString,
} from '../../helpers';
import { AnalyticsManager } from '../../native';

export const REGION_CHANGE_THRESHOLD = 2;
const FIND_NEAREST_CAR_THRESHOLD = 5;
const reportNoCarsAround = throttle(
  3000,
  true,
  (userLocation: string, distanceFromUser: string) => {
    AnalyticsManager.event({
      event: APP.ANALYTICS.EVENT.SEARCHING_NOCARS,
      properties: {
        userLocation,
        distanceFromUser,
      },
    });
  }
);
export const setFrom = (from: any): SetFrom => ({
  type: TYPES.SET_FROM,
  payload: {
    from,
  },
});
export const clearFrom = () => ({
  type: TYPES.CLEAR_FROM,
});
export const didCarsChanged = (
  existingCars: MapCars,
  newCars: Array<DriverCarData>
): boolean =>
  newCars.some(
    ({ id, version, driverPrice, availableUntil }) =>
      !existingCars[id!] ||
      (existingCars[id!] &&
        (version! > existingCars[id!].version! ||
          driverPrice?.description !==
            existingCars[id!].driverPrice?.description ||
          availableUntil !== existingCars[id!].availableUntil))
  );

const updateCars = (
  existingCarsObj: MapCars,
  newCars: Array<DriverCarData>,
  otherCarsObj: MapCars
): MapCars => {
  const outdatedIds: any = [];
  const updatedCarsObj = { ...existingCarsObj };
  const existingCarsIds = Object.keys(existingCarsObj);
  const otherCarsIds = Object.keys(otherCarsObj);
  newCars.forEach((car) => {
    if (existingCarsIds.includes(car.id!)) {
      if (
        car.version! > existingCarsObj[car.id!].version! ||
        car?.driverPrice?.description !==
          existingCarsObj[car.id!]?.driverPrice?.description ||
        car.availableUntil !== existingCarsObj[car.id!].availableUntil
      ) {
        updatedCarsObj[car.id!] = { ...existingCarsObj[car.id!], ...car };
      }
    } else {
      if (otherCarsIds.includes(car.id!)) {
        updatedCarsObj[car.id!] = { ...otherCarsObj[car.id!], ...car };
      } else {
        updatedCarsObj[car.id!] = car;
      }

      outdatedIds.push(car.id);
    }
  });
  return {
    updatedCarsObj,
    outdatedIds,
  };
};

const deleteOutdatedCars = (
  existingCarsObj: MapCars,
  outdatedCarsIds: any
): MapCars => {
  const updatedCarsObj = { ...existingCarsObj };
  outdatedCarsIds.forEach((id: string) => {
    delete updatedCarsObj[id];
  });
  return updatedCarsObj;
};

const clearAvailableCars = (): ClearAvailableCars => ({
  type: TYPES.CLEAR_AVAILABLE_CARS,
});

const clearUnavailableCars = (): ClearUnavailableCars => ({
  type: TYPES.CLEAR_UNAVAILABLE_CARS,
});

const setCategories = (categories: any): SetCategories => ({
  type: TYPES.SET_CATEGORIES,
  payload: {
    categories,
  },
});

export const setAvailableCars =
  (cars: Array<DriverCarData | CompactCarData>) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { availableCars, unavailableCars, radius, mapCenterLocation } =
      getState().carsSearch;
    const { lastKnownLocation } = getState().device;
    const distanceFromUser: any = distanceBetweenLocationsInKm(
      mapCenterLocation,
      lastKnownLocation!
    );

    if (cars.length === 0 && radius > 1000 && distanceFromUser < 10) {
      reportNoCarsAround(
        `${lastKnownLocation?.latitude},${lastKnownLocation?.longitude}`,
        Number.parseFloat(distanceFromUser).toPrecision(4)
      );
    }

    if (didCarsChanged(availableCars, cars)) {
      const { updatedCarsObj, outdatedIds } = updateCars(
        availableCars,
        cars,
        unavailableCars
      );
      dispatch({
        type: TYPES.SET_AVAILABLE_CARS,
        payload: {
          availableCars: updatedCarsObj,
        },
      });

      if (outdatedIds?.length! > 0) {
        dispatch({
          type: TYPES.SET_UNAVAILABLE_CARS,
          payload: {
            unavailableCars: deleteOutdatedCars(unavailableCars, outdatedIds),
          },
        });
      }
    }
  };
export const setUnavailableCars =
  (cars: Array<DriverCarData>) => (dispatch: Dispatch, getState: GetState) => {
    const { unavailableCars, availableCars } = getState().carsSearch;

    if (didCarsChanged(unavailableCars, cars)) {
      const { updatedCarsObj, outdatedIds } = updateCars(
        unavailableCars,
        cars,
        availableCars
      );
      dispatch({
        type: TYPES.SET_UNAVAILABLE_CARS,
        payload: {
          unavailableCars: updatedCarsObj,
        },
      });

      if (outdatedIds?.length! > 0) {
        dispatch({
          type: TYPES.SET_AVAILABLE_CARS,
          payload: {
            availableCars: deleteOutdatedCars(availableCars, outdatedIds),
          },
        });
      }
    }
  };
const TwoHrsInMs = 7200000;

const setCars =
  (foundCars: FoundCars | CompactFoundCars) =>
  (dispatch: Dispatch, getState: GetState) => {
    const filteredCars = {
      ...foundCars,
      availableCars: foundCars?.availableCars?.filter((car) => car.position),
      unavailableCars: foundCars?.unavailableCars?.filter(
        (car) => !!car?.position
      ),
    };
    const { availableCars, unavailableCars } = filteredCars;
    const { content } = getState().ui.map;
    const { bookings } = getState().userData.bookings;
    const activeBookings = bookings.filter(
      (booking) =>
        // @ts-ignore
        Math.abs(new Date(booking.from) - new Date()) <= TwoHrsInMs &&
        booking.status === 'confirmed'
    );

    if (activeBookings.length > 0) {
      activeBookings.forEach((booking) => {
        if (booking?.accepted?.car) {
          const { id, status } = booking.accepted.car;

          if (status === 'RELEASED') {
            const unavailableCar = unavailableCars?.find(
              (car) => car.id === id
            );
            const availableCar = availableCars?.find((car) => car.id === id);

            if (unavailableCar) {
              unavailableCar.availableUntil = booking.to;
              availableCars?.push(unavailableCar);
              unavailableCars?.splice(
                unavailableCars.findIndex((car) => car.id === id),
                1
              );
            } else if (availableCar) {
              const availableCarIndex = availableCars?.findIndex(
                (car) => car.id === id
              );
              // @ts-ignore
              availableCars[availableCarIndex].availableUntil = booking.to;
            }
          }
        }
      });
    }

    // @ts-ignore
    const cars = [...availableCars, ...unavailableCars];
    dispatch(setAvailableCars(availableCars!));
    dispatch(setUnavailableCars(unavailableCars!));
    const selectedCar = cars.find(({ id }) => id === content.id);

    if (selectedCar) {
      dispatch(updateMapContentWithCar(selectedCar.id));
    }
  };

export const setMapCenterLocation = (
  mapCenterLocation: Location
): SetMapCenterLocation => ({
  type: TYPES.SET_MAP_CENTER_LOCATION,
  payload: {
    mapCenterLocation,
  },
});

export const setRadius = (radius: number): SetRadius => ({
  type: TYPES.SET_RADIUS,
  payload: {
    radius,
  },
});

export const searchCars =
  (
    forceRadius: number | null | undefined = null,
    findNearest: boolean = false,
    initialSearch: boolean = false,
    callbackFunction: () => any = () => {}
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const networkActivity = findNearest
      ? CLIENT_TYPE.CARS_CLIENT.GET_WITH_LOADER
      : CLIENT_TYPE.CARS_CLIENT.GET;
    dispatch(setNetworkActivity(networkActivity));
    const state = getState();
    let { mapCenterLocation, radius } = state.carsSearch;
    const { backBy, from: fromDate, circleId } = state.carsSearch;
    const { lastKnownLocation } = state.device;

    if (!isWeb()) {
      const { visibleRegion } = state.ui.map;
      mapCenterLocation = {
        latitude: visibleRegion.latitude,
        longitude: visibleRegion.longitude,
      };
      dispatch(setMapCenterLocation(mapCenterLocation));
      const mapEdgeLocation = {
        latitude: mapCenterLocation.latitude + visibleRegion.latitudeDelta,
        longitude: mapCenterLocation.longitude + visibleRegion.longitudeDelta,
      };
      radius = Math.ceil(
        distanceBetweenLocationsInKm(mapCenterLocation, mapEdgeLocation) * 1000
      );
      dispatch(setRadius(radius));
    }

    let from = null;

    if (fromDate && isBefore(new Date(), new Date(fromDate))) {
      from = fromDate;
    } else {
      dispatch(setFrom(null));
    }

    const { notModified, data, error } = await carsClient.get(
      findNearest ? lastKnownLocation || mapCenterLocation : mapCenterLocation,
      forceRadius || radius,
      backBy,
      from,
      circleId
    );

    if (error) {
      dispatch(setNetworkError(networkActivity, error));
    } else {
      if (!!data && !notModified) {
        dispatch(setCars(data));
        setTimeout(() => {
          callbackFunction();
        }, 250);

        if (initialSearch) {
          setTimeout(() => {
            dispatch(resolveInitialMapContent(lastKnownLocation));
          }, 1000);
        }
      }

      dispatch(setNetworkSuccess(networkActivity));
    }
  };

const setCarsCompact =
  (foundCars: CompactFoundCars) => (dispatch: Dispatch, getState: GetState) => {
    const { availableCars, unavailableCars } = getState().carsSearch;
    const existingCarsIds = [
      ...Object.keys(availableCars),
      ...Object.keys(unavailableCars),
    ];
    const newCarsIds = [
      // @ts-ignore
      ...foundCars.availableCars,
      ...foundCars.unavailableCars,
    ].map((car) => car.id);
    const newCarAdded = newCarsIds.find((id) => !existingCarsIds.includes(id));

    if (newCarAdded) {
      dispatch(searchCars());
    } else {
      dispatch(setCars(foundCars));
    }
  };

export const searchCarsCompact =
  () => async (dispatch: Dispatch, getState: GetState) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.CARS_CLIENT.GET_COMPACT));
    const state = getState();
    let { mapCenterLocation, radius } = state.carsSearch;
    const { backBy, from: fromDate, circleId } = state.carsSearch;

    if (!isWeb()) {
      const { visibleRegion } = state.ui.map;
      mapCenterLocation = {
        latitude: visibleRegion.latitude,
        longitude: visibleRegion.longitude,
      };
      dispatch(setMapCenterLocation(mapCenterLocation));
      const mapEdgeLocation = {
        latitude: mapCenterLocation.latitude + visibleRegion.latitudeDelta,
        longitude: mapCenterLocation.longitude + visibleRegion.longitudeDelta,
      };
      radius = Math.ceil(
        distanceBetweenLocationsInKm(mapCenterLocation, mapEdgeLocation) * 1000
      );
      dispatch(setRadius(radius));
    }

    let from = null;

    if (fromDate && isBefore(new Date(), new Date(fromDate))) {
      from = fromDate;
    } else {
      dispatch(setFrom(null));
    }

    const { notModified, data, error } = await carsClient.getCompact(
      mapCenterLocation,
      radius,
      backBy,
      from,
      circleId
    );

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.CARS_CLIENT.GET_COMPACT, error));
    } else {
      if (!!data && !notModified) {
        dispatch(setCarsCompact(data));
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.CARS_CLIENT.GET_COMPACT));
    }
  };
export const setBackBy = (backBy: string | Date) => (dispatch: Dispatch) => {
  dispatch({
    type: TYPES.SET_BACK_BY,
    payload: {
      backBy,
    },
  });
  dispatch(searchCars());
};
export const clearBackBy = (): ClearBackBy => ({
  type: TYPES.CLEAR_BACK_BY,
});
export const searchCarById =
  (id: string) => async (dispatch: Dispatch, getState: GetState) => {
    const { circleId } = getState().carsSearch;
    dispatch(setNetworkActivity(CLIENT_TYPE.CARS_CLIENT.GET_BY_ID));
    // $FlowFixMe
    const { notModified, data, error } = await carsClient.getById(
      id,
      circleId!
    );

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.CARS_CLIENT.GET_BY_ID, error));

      if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'car.search.notExist',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
    } else {
      if (!!data && !notModified) {
        const { availableCars, unavailableCars } = getState().carsSearch;
        const availableCarsList: any = Object.values(availableCars);
        const unavailableCarsList: any = Object.values(unavailableCars);
        const existingCars: any = [
          ...availableCarsList,
          ...unavailableCarsList,
        ];
        const carExists: any = existingCars.find(
          (car: { id: string | undefined }) => car.id === data.id
        );

        if (!carExists) {
          if (data.position) {
            switch (data.status) {
              case 'RELEASED':
              case 'BLOCKED':
                dispatch(
                  setCars({
                    availableCars: [...availableCarsList, data],
                    unavailableCars: unavailableCarsList,
                  })
                );
                break;

              case 'RELEASE_READY':
                dispatch(
                  setCars({
                    availableCars: availableCarsList,
                    unavailableCars: [...unavailableCarsList, data],
                  })
                );
                break;

              default:
                break;
            }
          } else {
            dispatch(
              setNotification({
                message: 'car.search.notExist',
                type: APP.NOTIFICATION_TYPE.ERROR,
              })
            );
          }
        }
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.CARS_CLIENT.GET_BY_ID));
    }

    const { lastKnownLocation } = getState().device;
    dispatch(resolveInitialMapContent(lastKnownLocation));
  };
export const clearFilter = () => async (dispatch: Dispatch) => {
  dispatch(clearFrom());
  dispatch(clearBackBy());
  dispatch(searchCars());
};
export const setMapCircleIdSearch = (
  circleId: string
): SetMapCircleIdSearch => ({
  type: TYPES.SET_MAP_CIRCLE_ID_SEARCH,
  payload: {
    circleId,
  },
});
export const clearMapCircleIdSearch = (): ClearMapCircleIdSearch => ({
  type: TYPES.CLEAR_MAP_CIRCLE_ID_SEARCH,
});
export const switchAccessToCircle =
  (circleId: string | null) => async (dispatch: Dispatch) => {
    if (circleId) {
      dispatch(setMapCircleIdSearch(circleId));
    } else {
      dispatch(clearMapCircleIdSearch());
    }

    dispatch(setMapContent(null, 'none'));
    dispatch(clearAvailableCars());
    dispatch(clearUnavailableCars());
    dispatch(searchCars());
  };
export const findNearestAvailableCar =
  (
    refLocation: Location | null | undefined = null,
    radius: number = 1000,
    requestLocationPermission: any
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    if (radius > FIND_NEAREST_CAR_THRESHOLD * 1000) {
      dispatch(
        setNotification({
          message: 'car.findNearest.notExist',
          type: APP.NOTIFICATION_TYPE.INFO,
        })
      );
      return;
    }

    requestLocationPermission(() => {
      dispatch(
        searchCars(radius, true, false, () => {
          const { availableCars } = getState().carsSearch;
          const { content } = getState().ui.map;
          const availableCarsList: any = Object.values(availableCars).filter(
            (item: any) => item.vehicleConnection !== 'geoTab'
          );

          if (availableCarsList.length > 0) {
            const { lastKnownLocation } = getState().device;
            const sortedCars =
              refLocation || lastKnownLocation
                ? memorizedSortedCars(
                    availableCarsList,
                    refLocation || lastKnownLocation!
                  )
                : availableCarsList;
            const distanceToNearestCar = distanceBetweenLocationsInKm(
              locationFromString(sortedCars[0].position),
              refLocation || lastKnownLocation!
            );

            if (distanceToNearestCar < FIND_NEAREST_CAR_THRESHOLD) {
              dispatch(
                setMapContent(
                  'availableCar',
                  content.cardType,
                  sortedCars[0].id
                )
              );
              dispatch(
                setMapCenterLocation(locationFromString(sortedCars[0].position))
              );
            } else {
              dispatch(
                setNotification({
                  message: 'car.findNearest.notExist',
                  type: APP.NOTIFICATION_TYPE.INFO,
                })
              );
            }
          } else {
            dispatch(
              findNearestAvailableCar(
                refLocation,
                radius + 1000,
                requestLocationPermission
              )
            );
          }
        })
      );
    });
  };
export const setRegionChangeNo = (regionChangeNo: number) => ({
  type: TYPES.SET_REGION_CHANGE_NO,
  payload: {
    regionChangeNo,
  },
});
export const getCategories =
  (callbackFunction: () => any = () => {}) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const { circleId } = getState().carsSearch;
    const networkActivity = CLIENT_TYPE.CARS_CLIENT.GET_CATEGORIES;
    dispatch(setNetworkActivity(networkActivity));
    const { notModified, data, error } = await carsClient.getCategories(
      circleId!
    );

    if (error) {
      dispatch(setNetworkError(networkActivity, error));
    } else {
      if (!!data && !notModified) {
        dispatch(setCategories(data));
        callbackFunction();
      }

      dispatch(setNetworkSuccess(networkActivity));
    }
  };
