import { isIOS } from './isIOS';
import { isWeb } from './isWeb';
import { ActionSheetIOS, store } from '../config';
import { setDialog } from '../store';
import { UIManager } from '../native';

export type DialogOption = {
  title: any;
  action?: () => void;
};

export const showAlert = (
  title: string,
  message: string,
  yesActionText: any = null,
  yesAction: () => void = () => {},
  noActionText: any = null,
  noAction: () => void = () => {},
  isDestructive: boolean = false
) => {
  if (isWeb()) {
    const dialog = {
      title,
      message,
      yesActionText,
      yesAction,
      noActionText,
      noAction,
      isDestructive,
    };
    store.dispatch(setDialog(dialog));
  } else if (isIOS()) {
    const options = [];

    if (noActionText && noActionText !== '') {
      options.push(noActionText);
    }

    if (yesActionText && yesActionText !== '') {
      options.push(yesActionText);
    }

    const defaultOptions = {
      title,
      message,
      options,
      cancelButtonIndex: 0,
    };
    const destructiveOptions = { ...defaultOptions, destructiveButtonIndex: 1 };
    ActionSheetIOS?.showActionSheetWithOptions(
      isDestructive ? destructiveOptions : defaultOptions,
      (buttonIndex: number) => {
        if (buttonIndex === 1) {
          yesAction();
        }

        if (buttonIndex === 0) {
          noAction();
        }
      }
    );
  } else {
    const androidTitle = title !== '' ? title : null;
    const androidMessage = message !== '' ? message : null;
    UIManager.showAlert(
      androidTitle,
      androidMessage,
      yesActionText,
      noActionText,
      yesAction,
      noAction
    );
  }
};

export const showAlertWithOptions = (
  title: string,
  message: string,
  options: DialogOption[] = [],
  isDestructive: boolean = false
) => {
  if (isWeb()) {
    const dialog = {
      title,
      message,
      options,
    };
    store.dispatch(setDialog(dialog));
  } else {
    const optionsTitles = options?.map((option) => option?.title);
    const optionsActions = options?.map((option) => option?.action);

    if (isIOS()) {
      const defaultOptions = {
        title,
        message,
        options: optionsTitles,
        cancelButtonIndex: 0,
      };
      const destructiveOptions = {
        ...defaultOptions,
        destructiveButtonIndex: 1,
      };
      setTimeout(
        () =>
          ActionSheetIOS?.showActionSheetWithOptions(
            isDestructive ? destructiveOptions : defaultOptions,
            (buttonIndex: number) => {
              if (typeof optionsActions[buttonIndex] === 'function') {
                // @ts-ignore
                optionsActions[buttonIndex]();
              }
            }
          ),
        50
      );
    } else {
      setTimeout(() => {
        UIManager.showListAlert(title, optionsTitles, (response) => {
          if (response.buttonIndex) {
            const selectedIndex = parseInt(response.buttonIndex, 10);

            if (typeof optionsActions[selectedIndex] === 'function') {
              // @ts-ignore
              optionsActions[selectedIndex]();
            }
          }
        });
      }, 50);
    }
  }
};
