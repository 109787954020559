import React, { useEffect } from 'react';
import {
  LocalizePropType,
  selectSelectedConnectCar,
  showAlert,
  uninstallCar,
  withTextLocalizer,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import RemovalView from './RemovalView';
import { useDispatch, useSelector } from 'react-redux';
import { navigateTo } from '../../../utils';

type Props = {
  localize: LocalizePropType;
};

const RemovalContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedCar = useSelector(selectSelectedConnectCar);

  const onPressRemove = () => {
    showAlert(
      localize('remove.cancel.dialog.title'),
      localize('remove.cancel.dialog.message'),
      localize('remove.cancel.dialog.yes'),
      () => {
        dispatch(
          uninstallCar(selectedCar?.id!, () => {
            navigateTo(history, '/select', { from: 'remove' });
          })
        );
      },
      localize('cancel'),
      () => {},
      true
    );
  };

  useEffect(() => {
    if (!selectedCar) {
      navigateTo(history, '/select');
    }
  }, [selectedCar]);

  return selectedCar ? (
    <RemovalView
      localize={localize}
      car={selectedCar!}
      onPressRemove={onPressRemove}
    />
  ) : null;
};

export default withTextLocalizer(RemovalContainer);
