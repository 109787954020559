import getawayTexts from './initialData/InitialState.default.data';
import ubqTexts from './initialData/InitialState.ubq.data';
import robbenWientjesTexts from './initialData/InitialState.robbenWientjes.data';
import mietSmartTexts from './initialData/InitialState.mietSmart.data';
import connectTexts from './initialData/InitialState.connect.data';
import konigTexts from './initialData/InitialState.konig.data';
import { TextsState } from '../../../@types';
import { isConnect } from '../../../utils/isConnect';

const emptyLanguage = {
  de: {},
  en: {},
};

const initialState: TextsState = {
  default: isConnect() ? emptyLanguage : getawayTexts,
  ubeeqo: isConnect() ? emptyLanguage : ubqTexts,
  'robben-wientjes': isConnect() ? emptyLanguage : robbenWientjesTexts,
  'miet-smart': isConnect() ? emptyLanguage : mietSmartTexts,
  konig: isConnect() ? emptyLanguage : konigTexts,
  connect: isConnect() ? connectTexts : emptyLanguage,
};

export default initialState;
