import { PURGE } from 'redux-persist';
import initialState from './InitialState';
import TYPES from '../../../@types/redux/store/NotificationTypes';
import { NotificationsAction, NotificationsState } from '../../../@types';

export default (
  state: NotificationsState = initialState,
  action: NotificationsAction
): NotificationsState => {
  switch (action.type) {
    case TYPES.SET_NOTIFICATION: {
      return [...state, { ...action.payload }];
    }

    case TYPES.DISMISS_NOTIFICATION: {
      return state.filter(
        // $FlowFixMe
        (n) => n.id !== action.payload.id
      );
    }

    case TYPES.CLEAR_NOTIFICATIONS: {
      return [];
    }

    // @ts-ignore
    case PURGE:
      return initialState;

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
