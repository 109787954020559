import { LocalizePropType } from '../@types';
import { isWeb } from './isWeb';
import { showAlert } from './showAlert';
import { Rate } from '../config';

export const showAppReviewDialog = (
  localize: LocalizePropType,
  bundleId: string,
  appleAppId: string,
  successCallback: () => void = () => {}
): void => {
  if (!isWeb()) {
    showAlert(
      localize('review.dialog.title'),
      localize('review.dialog.message'),
      localize('review.dialog.yes'),
      () => {
        Rate.rate(
          {
            AppleAppID: appleAppId,
            GooglePackageName: bundleId,
            preferInApp: true,
            openAppStoreIfInAppFails: true,
            inAppDelay: 5.0,
          },
          (success: boolean) => {
            if (success) {
              successCallback();
            }
          }
        );
      },
      localize('review.dialog.later')
    );
  }
};
