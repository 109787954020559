import { APIError } from '../../index';
type ErrorLog = {
  readonly id: string;
  readonly type: string;
  readonly error: APIError;
  readonly message?: string;
};
export type NetworkStatusState = {
  readonly activities: Array<string>;
  readonly errors: Array<ErrorLog>;
  readonly errorHistory: Array<ErrorLog>;
  readonly getHistory: Record<string, string>;
};
enum NetworkStatusTypes {
  SET_NETWORK_ACTIVITY = 'networkStatus/SET_NETWORK_ACTIVITY',
  SET_NETWORK_ERROR = 'networkStatus/SET_NETWORK_ERROR',
  DISMISS_NETWORK_ERROR = 'networkStatus/DISMISS_NETWORK_ERROR',
  CLEAR_NETWORK_ERRORS = 'networkStatus/CLEAR_NETWORK_ERRORS',
  SET_NETWORK_SUCCESS = 'networkStatus/SET_NETWORK_SUCCESS',
  SET_GET_HISTORY = 'networkStatus/SET_GET_HISTORY',
  RESET_STORE = 'RESET_STORE',
}
export default NetworkStatusTypes;
export type SetGetHistory = {
  type: NetworkStatusTypes.SET_GET_HISTORY;
  payload: {
    requestHash: string;
    lastFetchedAt: string;
  };
};
export type SetNetworkActivity = {
  type: NetworkStatusTypes.SET_NETWORK_ACTIVITY;
  payload: {
    type: string;
  };
};
export type SetNetworkSuccess = {
  type: NetworkStatusTypes.SET_NETWORK_SUCCESS;
  payload: {
    type: string;
  };
};
export type SetNetworkError = {
  type: NetworkStatusTypes.SET_NETWORK_ERROR;
  payload: ErrorLog;
};
export type DismissNetworkError = {
  type: NetworkStatusTypes.DISMISS_NETWORK_ERROR;
  payload: {
    id: string;
  };
};
export type ClearNetworkErrors = {
  type: NetworkStatusTypes.CLEAR_NETWORK_ERRORS;
};
export type NetworkStatusAction =
  | SetGetHistory
  | SetNetworkActivity
  | SetNetworkError
  | DismissNetworkError
  | ClearNetworkErrors
  | SetNetworkSuccess;
