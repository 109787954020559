import { isAndroid, isIOS } from '../helpers';
import { S3TemporaryAccessData } from '../@types';
import { Sentry } from '../config';

const S3Manager = {
  nativeManager: null as any,
  uploadFile(
    fileKey: string,
    fileUri: string,
    accessData: S3TemporaryAccessData,
    successCallback: (() => any) | undefined,
    failureCallback: ((err: any) => any) | undefined
  ) {
    const { accessKeyId, secretAccessKey, sessionToken, bucket, region } =
      accessData;
    return new Promise<void>((resolve, reject) => {
      if (isAndroid()) {
        const awsConfigurations = {
          S3TransferUtility: {
            Default: {
              Bucket: bucket,
              Region: region,
            },
          },
        };
        this.nativeManager?.uploadFile(
          fileKey,
          fileUri,
          accessKeyId,
          secretAccessKey,
          sessionToken,
          awsConfigurations,
          () => {
            resolve();
            if (typeof successCallback === 'function') {
              successCallback();
            }
          },
          (err: any) => {
            reject();
            // @ts-ignore
            // eslint-disable-next-line no-undef
            if (!__DEV__) {
              Sentry.captureException(err);
            }
            if (typeof failureCallback === 'function') {
              failureCallback(err);
            }
          }
        );
      } else if (isIOS()) {
        this.nativeManager?.uploadFile(
          fileKey,
          fileUri,
          accessKeyId,
          secretAccessKey,
          sessionToken,
          bucket,
          region,
          () => {
            resolve();
            if (typeof successCallback === 'function') {
              successCallback();
            }
          },
          (err: any) => {
            reject();
            // @ts-ignore
            // eslint-disable-next-line no-undef
            if (!__DEV__) {
              Sentry.captureException(err);
            }
            if (typeof failureCallback === 'function') {
              failureCallback(err);
            }
          }
        );
      }
    });
  },
};

export default S3Manager;
