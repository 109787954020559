import React from 'react';
import Menu from '@mui/icons-material/Menu';
import './NavigationBar.less';
import { version } from '../../../../package.json';
import BrandIcon from '../BrandIcon/BrandIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelCarInstallation,
  clearAdminAuth,
  showAlert,
  showAlertWithOptions,
  LocalizePropType,
  selectSelectedConnectCar,
  withTextLocalizer,
} from 'getaway-data-layer';

type Props = {
  localize: LocalizePropType;
  title: string;
  description?: string;
  showCloseButton?: boolean;
  showMenu?: boolean;
  onPressBack?: () => void;
  containerStyle?: any;
};

const NavigationBar = ({
  localize,
  title,
  description,
  showMenu,
  showCloseButton,
  onPressBack,
  containerStyle,
}: Props) => {
  const dispatch = useDispatch();
  const selectedCar = useSelector(selectSelectedConnectCar);

  const callPhone = () => {
    window.open(`tel:${localize('connect.getaway.support.phone')}`, '_self');
  };

  const logout = () => {
    localStorage?.clear();
    clearAdminAuth();
  };

  const onClickMenu = () => {
    showAlertWithOptions(localize('connect.getaway.name'), '', [
      {
        title: localize('settings.logout'),
        action: logout,
      },
    ]);
  };

  const onClose = () => {
    showAlert(
      localize('installation.cancel.dialog.title'),
      localize('installation.cancel.dialog.message'),
      localize('installation.cancel.dialog.yes'),
      () => {
        dispatch(cancelCarInstallation(selectedCar?.id!));
      },
      localize('cancel'),
      () => {},
      true
    );
  };

  return (
    <div className="nav-bar-wrapper" style={containerStyle}>
      <div className="nav-bar-left-wrapper">
        {onPressBack && (
          <div className="nav-bar-back" onClick={onPressBack}>
            <BrandIcon name={'arrowLeft'} style={{ width: 20, height: 20 }} />
          </div>
        )}
        {showCloseButton && (
          <div className="nav-bar-close" onClick={onClose}>
            <BrandIcon name={'closeIcon'} style={{ width: 20, height: 20 }} />
          </div>
        )}
      </div>
      <div className="nav-bar-top-right-container">
        <div className="nav-bar-app-version">{`© GETAWAY・v${version}`}</div>
        <div className="nav-bar-chat" onClick={callPhone}>
          <BrandIcon name={'call'} style={{ width: 25, height: 25 }} />
        </div>
        {showMenu && (
          <div className="nav-bar-menu" onClick={onClickMenu}>
            <Menu sx={{ color: '#D8F52B' }} />
          </div>
        )}
      </div>
      <div className="nav-bar-header-txt-container">
        {title && <p className="nav-bar-title noselect">{title}</p>}
        {description && (
          <p className="nav-bar-description noselect">{description}</p>
        )}
      </div>
    </div>
  );
};

NavigationBar.defaultProps = {
  description: '',
  showCloseButton: true,
  showMenu: true,
  onPressBack: null,
  containerStyle: {},
};

export default withTextLocalizer(NavigationBar);
