import { FirstStepsState } from '../../../@types';

const initialState: FirstStepsState = {
  address: {
    streetName: '',
    streetNumber: '',
    postalCode: '',
    city: '',
    country: '',
  },
  referralCode: '',
  phone: {
    phoneNumber: '',
    phoneCode: {
      country: 'DE',
      code: '+49',
    },
  },
  phoneCodes: [
    {
      country: 'DE',
      code: '+49',
    },
    {
      country: 'NL',
      code: '+31',
    },
    {
      country: 'BE',
      code: '+32',
    },
    {
      country: 'FR',
      code: '+33',
    },
    {
      country: 'LU',
      code: '+352',
    },
    {
      country: 'CH',
      code: '+41',
    },
    {
      country: 'AT',
      code: '+43',
    },
    {
      country: 'DK',
      code: '+45',
    },
  ],
  circleCode: '',
};
export default initialState;
