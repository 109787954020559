import { PURGE } from 'redux-persist';
import initialState from './InitialState';
import TYPES from '../../../@types/redux/store/FirstStepsTypes';
import { FirstStepsAction, FirstStepsState } from '../../../@types';

export default (
  state: FirstStepsState = initialState,
  action: FirstStepsAction
): FirstStepsState => {
  switch (action.type) {
    case TYPES.SET_ADDRESS: {
      return { ...state, address: action.payload.address };
    }

    case TYPES.CLEAR_ADDRESS: {
      return { ...state, address: initialState.address };
    }

    case TYPES.SET_PHONE_NUMBER: {
      return {
        ...state,
        phone: { ...state.phone, phoneNumber: action.payload.phoneNumber },
      };
    }

    case TYPES.CLEAR_PHONE_NUMBER: {
      return {
        ...state,
        phone: { ...state.phone, phoneNumber: initialState.phone.phoneNumber },
      };
    }

    case TYPES.SET_PHONE_CODE: {
      return {
        ...state,
        phone: { ...state.phone, phoneCode: action.payload.phoneCode },
      };
    }

    case TYPES.CLEAR_PHONE_CODE: {
      return {
        ...state,
        phone: { ...state.phone, phoneCode: initialState.phone.phoneCode },
      };
    }

    case TYPES.SET_REFERRAL_CODE: {
      return { ...state, referralCode: action.payload.referralCode };
    }

    case TYPES.CLEAR_REFERRAL_CODE: {
      return { ...state, referralCode: initialState.referralCode };
    }

    case TYPES.SET_CIRCLE_CODE: {
      return { ...state, circleCode: action.payload.circleCode };
    }

    case TYPES.ClEAR_CIRCLE_CODE: {
      return { ...state, circleCode: initialState.circleCode };
    }
    // @ts-ignore
    case PURGE:
      return initialState;

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
