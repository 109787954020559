import { RNFetchBlob, store } from '../../config';

export const uploadFileFromStorage = async (
  url: string,
  fileUri: string,
  method: 'POST' | 'PUT' = 'POST'
): Promise<any> => {
  const state = store.getState();
  const { apiBaseURL, apiVersion, xBrandId } = state.config;
  const { deviceUUID, platform, model, pushToken, pushDeviceID, userAgent } =
    state.device;

  const headers = {
    Authorization: 'Bearer access-token...',
    'Content-Type': 'application/octet-stream',
    Accept: 'application/json; charset=utf-8',
    'X-API-Version': apiVersion,
    'X-Application-Client': 'mobile',
    'Cache-Control': 'no-cache',
  };

  if (xBrandId) {
    // @ts-ignore
    headers['X-Brand-ID'] = xBrandId;
  }

  if (deviceUUID) {
    // @ts-ignore
    headers['X-Device-UUID'] = deviceUUID;
  }

  if (platform) {
    // @ts-ignore
    headers['X-Device-Platform'] = `${platform.name} ${platform.version}`;
  }

  if (model) {
    // @ts-ignore
    headers['X-Device-Model'] = model;
  }

  if (pushToken) {
    // @ts-ignore
    headers['X-Push-Token'] = pushToken;
  }

  if (pushDeviceID) {
    // @ts-ignore
    headers['X-Push-Device-ID'] = pushDeviceID;
  }

  if (userAgent) {
    // @ts-ignore
    headers['User-Agent'] = `${userAgent} React-Native`;
  }

  if (state.userData.user.basicAuth) {
    headers.Authorization = state.userData.user.basicAuth;
  }
  let wrappedPath: string;
  if (fileUri.startsWith('file://')) {
    wrappedPath = 'RNFetchBlob-' + fileUri;
  } else {
    wrappedPath = RNFetchBlob.wrap(fileUri);
  }
  const response = await RNFetchBlob.fetch(
    method,
    `${apiBaseURL}${url}`,
    headers,
    wrappedPath
  );

  if (response.respInfo.status.toString().startsWith('2')) {
    return { data: response.data };
  }
  return { error: response.respInfo.status };
};
