// @ts-ignore
export default [{
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Lagerhaus Rohrbach',
  AddressLine1: 'Hanriederstr. 43',
  AddressLine2: 'OOE',
  PostCode: 4153,
  TownCity: 'Peilstein',
  Latitude: '486.205.000',
  Longitude: '138.857.000',
  Telephone: '+43728772217',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.6205,13.8857',
  id: '48.6205,13.8857'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Gewerbestrasse Nb',
  AddressLine2: 'KTN',
  PostCode: 9112,
  TownCity: 'Griffen',
  Latitude: '466.959.660',
  Longitude: '147.372.310',
  Telephone: '+43423325311',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '46.695966,14.737231',
  id: '46.695966,14.737231'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Millstaetterstrasse 85',
  AddressLine2: 'KTN',
  PostCode: 9542,
  TownCity: 'Afritz am See',
  Latitude: '467.276.610',
  Longitude: '137.854.550',
  Telephone: '+4342472747',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '46.727661,13.785455',
  id: '46.727661,13.785455'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Aussenringautobahn A21',
  AddressLine2: 'NOE',
  PostCode: 2534,
  TownCity: 'Alland',
  Latitude: '480.701.150',
  Longitude: '160.666.200',
  Telephone: '+43225876180',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.070115,16.06662',
  id: '48.070115,16.06662'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Obere Marktstrasse 45',
  AddressLine2: 'SBG',
  PostCode: 5541,
  TownCity: 'Altenmarkt im Pongau',
  Latitude: '473.810.000',
  Longitude: '134.175.000',
  Telephone: '+4364525660',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'No',
  location: '47.381,13.4175',
  id: '47.381,13.4175'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Ybbsstr.39',
  AddressLine2: 'NOE',
  PostCode: 3300,
  TownCity: 'Amstetten',
  Latitude: '481.166.141',
  Longitude: '148.716.214',
  Telephone: '+43747268222',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '48.1166141,14.8716214',
  id: '48.1166141,14.8716214'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Wirtschaftsweg 4',
  AddressLine2: 'T',
  PostCode: 6321,
  TownCity: 'Angath',
  Latitude: '475.163.800',
  Longitude: '120.669.405',
  Telephone: '+43533274371',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.51638,12.0669405',
  id: '47.51638,12.0669405'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Westautobahn A1',
  AddressLine2: 'OOE',
  PostCode: 4052,
  TownCity: 'Ansfelden',
  Latitude: '482.149.880',
  Longitude: '142.835.620',
  Telephone: '+43722987115',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.214988,14.283562',
  id: '48.214988,14.283562'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Traunuferstr.4/Freindf',
  AddressLine2: 'OOE',
  PostCode: 4052,
  TownCity: 'Ansfelden',
  Latitude: '482.308.289',
  Longitude: '143.050.653',
  Telephone: '+43732302763',
  WeekDayFrom: '05:30',
  WeekDayTo: '19:00',
  SaturdayFrom: '05:30',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '14:00',
  IsOpen24Hours: 'No',
  location: '48.2308289,14.3050653',
  id: '48.2308289,14.3050653'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'A-2 Suedautobahn',
  AddressLine2: 'STMK',
  PostCode: 8200,
  TownCity: 'Arnwiesen-Gleisdorf',
  Latitude: '471.055.950',
  Longitude: '157.689.470',
  Telephone: '+4331126744',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.105595,15.768947',
  id: '47.105595,15.768947'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wechselstrasse 15',
  AddressLine2: 'NOE',
  PostCode: 2870,
  TownCity: 'Aspang',
  Latitude: '475.673.000',
  Longitude: '160.978.000',
  Telephone: '+43264252577',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '18:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.5673,16.0978',
  id: '47.5673,16.0978'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Tullnerstrasse 2',
  AddressLine2: 'NOE',
  PostCode: 3041,
  TownCity: 'Asperhofen',
  Latitude: '482.422.000',
  Longitude: '159.257.000',
  Telephone: '+43277258266',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.2422,15.9257',
  id: '48.2422,15.9257'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Poetschenstrasse 145',
  AddressLine2: 'STMK',
  PostCode: 8990,
  TownCity: 'Bad Aussee',
  Latitude: '476.128.000',
  Longitude: '137.664.000',
  Telephone: '+43362252805',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.6128,13.7664',
  id: '47.6128,13.7664'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Voeslauerstrasse 77',
  AddressLine2: 'NOE',
  PostCode: 2500,
  TownCity: 'Baden bei Wien',
  Latitude: '479.968.127',
  Longitude: '162.279.136',
  Telephone: '+43225287181',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Yes',
  location: '47.9968127,16.2279136',
  id: '47.9968127,16.2279136'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Einzingerstrasse 17',
  AddressLine2: 'OOE',
  PostCode: 4820,
  TownCity: 'Bad Ischl',
  Latitude: '477.212.000',
  Longitude: '136.400.000',
  Telephone: '+43613224584',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.7212,13.64',
  id: '47.7212,13.64'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Lagerhaus Rohrbach',
  AddressLine1: 'Dreisesselbergstr. 18',
  AddressLine2: 'OOE',
  PostCode: 4161,
  TownCity: 'Ulrichsberg',
  Latitude: '486.793.000',
  Longitude: '139.083.000',
  Telephone: '+4372882228',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.6793,13.9083',
  id: '48.6793,13.9083'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Salzburgerstr.70',
  AddressLine2: 'SBG',
  PostCode: 5500,
  TownCity: 'Bischofshofen',
  Latitude: '474.229.426',
  Longitude: '132.163.699',
  Telephone: '+4364622501',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4229426,13.2163699',
  id: '47.4229426,13.2163699'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hainfelderstr.',
  AddressLine2: 'NOE',
  PostCode: 3071,
  TownCity: 'Boeheimkirchen',
  Latitude: '481.931.290',
  Longitude: '157.652.410',
  Telephone: '+4327433265',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:30',
  SaturdayFrom: '06:30',
  SaturdayTo: '20:30',
  SundayFrom: '07:00',
  SundayTo: '20:30',
  IsOpen24Hours: 'No',
  location: '48.193129,15.765241',
  id: '48.193129,15.765241'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Arlbergstrasse 63',
  AddressLine2: 'VBG',
  PostCode: 6900,
  TownCity: 'Bregenz',
  Latitude: '474.966.000',
  Longitude: '97.346.800',
  Telephone: '+43557444855',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.4966,9.73468',
  id: '47.4966,9.73468'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Bregenzerstr. 63',
  AddressLine2: 'VBG',
  PostCode: 6900,
  TownCity: 'Bregenz/Lochau',
  Latitude: '475.109.000',
  Longitude: '97.529.600',
  Telephone: '+43557443445',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Yes',
  location: '47.5109,9.75296',
  id: '47.5109,9.75296'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Klagenfurterstrasse 10',
  AddressLine2: 'KTN',
  PostCode: 9371,
  TownCity: 'Brueckl',
  Latitude: '467.465.819',
  Longitude: '145.315.351',
  Telephone: '+4342142508',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.7465819,14.5315351',
  id: '46.7465819,14.5315351'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Lustenauerstr',
  AddressLine2: 'VBG',
  PostCode: 6850,
  TownCity: 'Dornbirn',
  Latitude: '474.103.190',
  Longitude: '97.204.910',
  Telephone: '800080645',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.410319,9.720491',
  id: '47.410319,9.720491'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Bruennerstr./Staatsgr.',
  AddressLine2: 'NOE',
  PostCode: 2165,
  TownCity: 'Drasenhofen',
  Latitude: '487.774.040',
  Longitude: '166.407.570',
  Telephone: '+43255485523',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.777404,16.640757',
  id: '48.777404,16.640757'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Tauernautobahn A-10',
  AddressLine2: 'KTN',
  PostCode: 9710,
  TownCity: 'Feistritz an der Drau',
  Latitude: '466.836.060',
  Longitude: '136.718.740',
  Telephone: '+4342454402',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '46.683606,13.671874',
  id: '46.683606,13.671874'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Klagenfurterstrasse 23',
  AddressLine2: 'KTN',
  PostCode: 9170,
  TownCity: 'Ferlach',
  Latitude: '465.280.000',
  Longitude: '142.916.000',
  Telephone: '+43422730849',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '46.528,14.2916',
  id: '46.528,14.2916'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wr.Neustaedt.Str.120',
  AddressLine2: 'NOE',
  PostCode: 2821,
  TownCity: 'Frohsdorf',
  Latitude: '477.550.212',
  Longitude: '162.528.991',
  Telephone: '+43262745290',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Yes',
  location: '47.7550212,16.2528991',
  id: '47.7550212,16.2528991'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Wolfgangseestr. 21',
  AddressLine2: 'SBG',
  PostCode: 5330,
  TownCity: 'Fuschl am See',
  Latitude: '477.958.597',
  Longitude: '133.046.364',
  Telephone: '+4362268269',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.7958597,13.3046364',
  id: '47.7958597,13.3046364'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wienerstr. 35',
  AddressLine2: 'NOE',
  PostCode: 2191,
  TownCity: 'Gaweinstal',
  Latitude: '484.728.610',
  Longitude: '165.887.350',
  Telephone: '+4325742183',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.472861,16.588735',
  id: '48.472861,16.588735'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Pramerdorf 13',
  AddressLine2: 'OOE',
  PostCode: 4922,
  TownCity: 'Geiersberg',
  Latitude: '481.988.000',
  Longitude: '136.049.000',
  Telephone: '+4377322288',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.1988,13.6049',
  id: '48.1988,13.6049'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Leopoldauerstr. 1',
  AddressLine2: 'NOE',
  PostCode: 2201,
  TownCity: 'Gerasdorf',
  Latitude: '482.932.841',
  Longitude: '164.670.928',
  Telephone: '+4322462334',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'No',
  location: '48.2932841,16.4670928',
  id: '48.2932841,16.4670928'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Dr.Franz Thomasstrasse 22a',
  AddressLine2: 'OOE',
  PostCode: 4810,
  TownCity: 'Gmunden',
  Latitude: '479.092.000',
  Longitude: '137.842.000',
  Telephone: '+43761262130',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.9092,13.7842',
  id: '47.9092,13.7842'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Innsbrucker Str.5',
  AddressLine2: 'T',
  PostCode: 6353,
  TownCity: 'Going',
  Latitude: '475.158.713',
  Longitude: '123.186.388',
  Telephone: '+4353582246',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.5158713,12.3186388',
  id: '47.5158713,12.3186388'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Harterstrasse 8',
  AddressLine2: 'STMK',
  PostCode: 8101,
  TownCity: 'Gratkorn',
  Latitude: '471.221.000',
  Longitude: '153.604.000',
  Telephone: '+433124290291',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '47.1221,15.3604',
  id: '47.1221,15.3604'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Froehlichgasse 45',
  AddressLine2: 'STMK',
  PostCode: 8010,
  TownCity: 'Graz',
  Latitude: '470.559.000',
  Longitude: '154.483.000',
  Telephone: '+43316472595',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.0559,15.4483',
  id: '47.0559,15.4483'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Kaerntnerstrasse 291',
  AddressLine2: 'STMK',
  PostCode: 8054,
  TownCity: 'Graz',
  Latitude: '470.338.000',
  Longitude: '154.088.000',
  Telephone: '+43316281049',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '47.0338,15.4088',
  id: '47.0338,15.4088'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Balldorf 39',
  AddressLine2: 'NOE',
  PostCode: 3304,
  TownCity: 'St. Georgen',
  Latitude: '481.240.000',
  Longitude: '149.732.000',
  Telephone: '+4374736858',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.124,14.9732',
  id: '48.124,14.9732'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Ragnitzstrasse 127',
  AddressLine2: 'STMK',
  PostCode: 8047,
  TownCity: 'Graz',
  Latitude: '470.777.350',
  Longitude: '154.902.050',
  Telephone: '+43316301922',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.077735,15.490205',
  id: '47.077735,15.490205'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Ostufer',
  AddressLine2: 'T',
  PostCode: 6156,
  TownCity: 'Gries am Brenner',
  Latitude: '470.392.729',
  Longitude: '114.749.310',
  Telephone: '+43527487310',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.0392729,11.474931',
  id: '47.0392729,11.474931'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Westufer',
  AddressLine2: 'T',
  PostCode: 6156,
  TownCity: 'Gries am Brenner',
  Latitude: '470.378.548',
  Longitude: '114.732.324',
  Telephone: '+43527487288',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.0378548,11.4732324',
  id: '47.0378548,11.4732324'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Industriestrasse 29',
  AddressLine2: 'OOE',
  PostCode: 4710,
  TownCity: 'Grieskirchen',
  Latitude: '482.270.000',
  Longitude: '138.435.000',
  Telephone: '+43724862227',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.227,13.8435',
  id: '48.227,13.8435'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Autobahn A2',
  AddressLine2: 'NOE',
  PostCode: 2353,
  TownCity: 'Guntramsdorf West',
  Latitude: '480.341.287',
  Longitude: '163.379.655',
  Telephone: '+43223652003',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.0341287,16.3379655',
  id: '48.0341287,16.3379655'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Westautobahn A1',
  AddressLine2: 'NOE',
  PostCode: 3350,
  TownCity: 'Haag',
  Latitude: '481.395.876',
  Longitude: '145.789.647',
  Telephone: '+43743442153',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1395876,14.5789647',
  id: '48.1395876,14.5789647'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Pfannhausstr. 14',
  AddressLine2: 'T',
  PostCode: 6060,
  TownCity: 'Hall In Tirol',
  Latitude: '472.793.000',
  Longitude: '115.014.000',
  Telephone: '+43522345005',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.2793,11.5014',
  id: '47.2793,11.5014'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Klein Pertholz 58',
  AddressLine2: 'NOE',
  PostCode: 3860,
  TownCity: 'Heidenreichstein',
  Latitude: '488.502.630',
  Longitude: '150.965.320',
  Telephone: '+43286252127',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.850263,15.096532',
  id: '48.850263,15.096532'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Liebensdorf 36',
  AddressLine2: 'STMK',
  PostCode: 8081,
  TownCity: 'Heiligenkreuz am Waasen',
  Latitude: '469.822.000',
  Longitude: '155.648.000',
  Telephone: '+4331342313',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '12:00',
  IsOpen24Hours: 'No',
  location: '46.9822,15.5648',
  id: '46.9822,15.5648'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Schulstrasse 8',
  AddressLine2: 'KTN',
  PostCode: 9562,
  TownCity: 'Himmelberg',
  Latitude: '467.514.455',
  Longitude: '140.353.928',
  Telephone: '+4342762254',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '06:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '46.7514455,14.0353928',
  id: '46.7514455,14.0353928'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'A12',
  AddressLine2: 'T',
  PostCode: 6070,
  TownCity: 'Ampass',
  Latitude: '472.632.780',
  Longitude: '114.534.580',
  Telephone: '+43512346411',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.263278,11.453458',
  id: '47.263278,11.453458'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hallerstrasse 100',
  AddressLine2: 'T',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '472.775.880',
  Longitude: '114.242.570',
  Telephone: '+43512209094',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:59',
  SundayFrom: '06:00',
  SundayTo: '21:59',
  IsOpen24Hours: 'No',
  location: '47.277588,11.424257',
  id: '47.277588,11.424257'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hoettinger Au 45',
  AddressLine2: 'T',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '472.653.295',
  Longitude: '113.791.518',
  Telephone: '800080645',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.2653295,11.3791518',
  id: '47.2653295,11.3791518'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Kranebitter Allee 30',
  AddressLine2: 'T',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '472.645.000',
  Longitude: '113.658.000',
  Telephone: '512274514',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.2645,11.3658',
  id: '47.2645,11.3658'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Burggasse 42',
  AddressLine2: 'STMK',
  PostCode: 8750,
  TownCity: 'Judenburg',
  Latitude: '471.688.000',
  Longitude: '146.544.000',
  Telephone: '+43357283307',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '10:00',
  SaturdayTo: '19:00',
  SundayFrom: '10:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Yes',
  location: '47.1688,14.6544',
  id: '47.1688,14.6544'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Autobahn A1, Rasthausstrasse 10',
  AddressLine2: 'NOE',
  PostCode: 3373,
  TownCity: 'Kemmelbach',
  Latitude: '481.551.024',
  Longitude: '151.001.745',
  Telephone: '+43741252748',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1551024,15.1001745',
  id: '48.1551024,15.1001745'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'St Johannerstrasse',
  AddressLine2: 'T',
  PostCode: 6370,
  TownCity: 'Kitzbuehel',
  Latitude: '474.650.240',
  Longitude: '123.864.740',
  Telephone: '+43535664377',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.465024,12.386474',
  id: '47.465024,12.386474'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Suedring 315',
  AddressLine2: 'KTN',
  PostCode: 9020,
  TownCity: 'Klagenfurt',
  Latitude: '466.089.910',
  Longitude: '143.304.850',
  Telephone: '+43463319538',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '46.608991,14.330485',
  id: '46.608991,14.330485'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Nr.147',
  AddressLine2: 'OOE',
  PostCode: 4564,
  TownCity: 'Klaus',
  Latitude: '478.301.808',
  Longitude: '141.569.033',
  Telephone: '+437585249',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.8301808,14.1569033',
  id: '47.8301808,14.1569033'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Kierlinger Strasse 31d',
  AddressLine2: 'NOE',
  PostCode: 3400,
  TownCity: 'Klosterneuburg',
  Latitude: '483.067.000',
  Longitude: '163.138.000',
  Telephone: '+43224332232',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.3067,16.3138',
  id: '48.3067,16.3138'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Schubertstrasse 25',
  AddressLine2: 'T',
  PostCode: 6330,
  TownCity: 'Kufstein',
  Latitude: '475.853.000',
  Longitude: '121.617.000',
  Telephone: '+43537262977',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.5853,12.1617',
  id: '47.5853,12.1617'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Packerstrasse 144',
  AddressLine2: 'STMK',
  PostCode: 8501,
  TownCity: 'Lieboch',
  Latitude: '469.765.000',
  Longitude: '153.287.000',
  Telephone: '+43313661187',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '46.9765,15.3287',
  id: '46.9765,15.3287'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Westautobahn A1',
  AddressLine2: 'OOE',
  PostCode: 4663,
  TownCity: 'Lindach',
  Latitude: '480.040.507',
  Longitude: '138.513.135',
  Telephone: '+4376138460',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.0040507,13.8513135',
  id: '48.0040507,13.8513135'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Dauphinestrasse 27',
  AddressLine2: 'OOE',
  PostCode: 4030,
  TownCity: 'Linz-Kleinmuenchen',
  Latitude: '482.534.400',
  Longitude: '143.105.300',
  Telephone: '800080645',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Yes',
  location: '48.25344,14.31053',
  id: '48.25344,14.31053'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Industriezeile 78',
  AddressLine2: 'OOE',
  PostCode: 4020,
  TownCity: 'Linz',
  Latitude: '483.003.000',
  Longitude: '143.206.000',
  Telephone: '+43732600859',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.3003,14.3206',
  id: '48.3003,14.3206'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Stockhofstrasse 42',
  AddressLine2: 'OOE',
  PostCode: 4020,
  TownCity: 'Linz',
  Latitude: '482.946.000',
  Longitude: '142.863.000',
  Telephone: '+43732664838',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.2946,14.2863',
  id: '48.2946,14.2863'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Muenzgrabenstrasse 121',
  AddressLine2: 'STMK',
  PostCode: 8010,
  TownCity: 'Graz',
  Latitude: '470.570.368',
  Longitude: '154.532.127',
  Telephone: '+43316465444',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '47.0570368,15.4532127',
  id: '47.0570368,15.4532127'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Wienerstrasse 435-437',
  AddressLine2: 'OOE',
  PostCode: 4030,
  TownCity: 'Linz-Textil',
  Latitude: '482.512.020',
  Longitude: '143.228.580',
  Telephone: '+43732307666',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.251202,14.322858',
  id: '48.251202,14.322858'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Waldeggstrasse 44',
  AddressLine2: 'OOE',
  PostCode: 4020,
  TownCity: 'Linz',
  Latitude: '482.884.000',
  Longitude: '142.874.000',
  Telephone: '+43732657321',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.2884,14.2874',
  id: '48.2884,14.2874'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wildbergstrasse 16',
  AddressLine2: 'OOE',
  PostCode: 4040,
  TownCity: 'Linz-Urfahr',
  Latitude: '483.149.339',
  Longitude: '142.852.477',
  Telephone: '+43732732586',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.3149339,14.2852477',
  id: '48.3149339,14.2852477'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Welserstr. 54',
  AddressLine2: 'OOE',
  PostCode: 4614,
  TownCity: 'Marchtrenk',
  Latitude: '481.901.000',
  Longitude: '140.998.000',
  Telephone: '+43724351114',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1901,14.0998',
  id: '48.1901,14.0998'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Poschacherstrasse',
  AddressLine2: 'OOE',
  PostCode: 4310,
  TownCity: 'Mauthausen',
  Latitude: '482.399.213',
  Longitude: '145.271.081',
  Telephone: '+4372385364',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '05:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'No',
  location: '48.2399213,14.5271081',
  id: '48.2399213,14.5271081'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Raecking 3',
  AddressLine2: 'NOE',
  PostCode: 3390,
  TownCity: 'Melk',
  Latitude: '482.300.000',
  Longitude: '153.310.000',
  Telephone: '+43275252377',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.23,15.331',
  id: '48.23,15.331'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Stock 11',
  AddressLine2: 'NOE',
  PostCode: 3251,
  TownCity: 'Merkenstetten',
  Latitude: '480.391.270',
  Longitude: '151.437.200',
  Telephone: '+4374892481',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.039127,15.14372',
  id: '48.039127,15.14372'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Lagerhaus Rohrbach',
  AddressLine1: 'Stauffstrasse 6',
  AddressLine2: 'OOE',
  PostCode: 4712,
  TownCity: 'Michaelnbach',
  Latitude: '482.891.000',
  Longitude: '138.334.000',
  Telephone: '+4372772520',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.2891,13.8334',
  id: '48.2891,13.8334'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Bundesstrasse 279',
  AddressLine2: 'T',
  PostCode: 6543,
  TownCity: 'Nauders/Reschenpass',
  Latitude: '468.512.474',
  Longitude: '105.042.976',
  Telephone: '+43547387268',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '46.8512474,10.5042976',
  id: '46.8512474,10.5042976'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Haslau 10',
  AddressLine2: 'OOE',
  PostCode: 4715,
  TownCity: 'Taufkirchen a.d.Tr.',
  Latitude: '482.708.285',
  Longitude: '137.420.110',
  Telephone: '+43773320600',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.2708285,13.742011',
  id: '48.2708285,13.742011'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Wiener Strasse 19',
  AddressLine2: 'STMK',
  PostCode: 8820,
  TownCity: 'Neumarkt',
  Latitude: '470.792.000',
  Longitude: '144.246.000',
  Telephone: '+4335842477',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.0792,14.4246',
  id: '47.0792,14.4246'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wienerstrasse 69',
  AddressLine2: 'BGLD',
  PostCode: 7100,
  TownCity: 'Neusiedl/See',
  Latitude: '479.580.858',
  Longitude: '168.352.404',
  Telephone: '2167314412',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.9580858,16.8352404',
  id: '47.9580858,16.8352404'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Bahnhofstrasse 27',
  AddressLine2: 'OOE',
  PostCode: 4722,
  TownCity: 'Peuerbach',
  Latitude: '483.389.797',
  Longitude: '137.715.259',
  Telephone: '+4372762206',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.3389797,13.7715259',
  id: '48.3389797,13.7715259'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Tragweinerstr. 31',
  AddressLine2: 'OOE',
  PostCode: 4230,
  TownCity: 'Pregarten',
  Latitude: '483.534.000',
  Longitude: '145.359.000',
  Telephone: '+4372362230',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '06:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '48.3534,14.5359',
  id: '48.3534,14.5359'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Weitenfeld 5',
  AddressLine2: 'OOE',
  PostCode: 4048,
  TownCity: 'Puchenau',
  Latitude: '483.120.000',
  Longitude: '142.288.000',
  Telephone: '+43732221545',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.312,14.2288',
  id: '48.312,14.2288'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Tauernstrasse 27',
  AddressLine2: 'SBG',
  PostCode: 5550,
  TownCity: 'Radstadt',
  Latitude: '473.791.000',
  Longitude: '134.738.000',
  Telephone: '+43645230921',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '07:00',
  SundayTo: '18:00',
  IsOpen24Hours: 'No',
  location: '47.3791,13.4738',
  id: '47.3791,13.4738'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Nr. 197',
  AddressLine2: 'STMK',
  PostCode: 8972,
  TownCity: 'Ramsau am Dachstein',
  Latitude: '474.417.504',
  Longitude: '136.566.539',
  Telephone: '+43368781405',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'No',
  location: '47.4417504,13.6566539',
  id: '47.4417504,13.6566539'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hauptstrasse 92',
  AddressLine2: 'NOE',
  PostCode: 2651,
  TownCity: 'Reichenau an der Rax',
  Latitude: '476.979.763',
  Longitude: '158.364.560',
  Telephone: '+43266652424',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.6979763,15.836456',
  id: '47.6979763,15.836456'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Lagerhaus Rohrbach',
  AddressLine1: 'Scheiblberg 44',
  AddressLine2: 'OOE',
  PostCode: 4150,
  TownCity: 'Berg bei Rohrbach',
  Latitude: '485.596.000',
  Longitude: '139.871.000',
  Telephone: '',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '12:00',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.5596,13.9871',
  id: '48.5596,13.9871'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Aignerstrasse 22',
  AddressLine2: 'SBG',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '477.972.000',
  Longitude: '130.674.000',
  Telephone: '+43662635841',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:59',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.7972,13.0674',
  id: '47.7972,13.0674'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Innsbr. Bundesstr.97',
  AddressLine2: 'SBG',
  PostCode: 5020,
  TownCity: 'Salzburg-Flughafen',
  Latitude: '477.928.000',
  Longitude: '130.009.000',
  Telephone: '+43662856349',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.7928,13.0009',
  id: '47.7928,13.0009'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Hauptstrasse 2',
  AddressLine2: 'OOE',
  PostCode: 4642,
  TownCity: 'Sattledt',
  Latitude: '480.733.000',
  Longitude: '140.521.000',
  Telephone: '+4372448782',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.0733,14.0521',
  id: '48.0733,14.0521'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Linzerstrasse 52',
  AddressLine2: 'OOE',
  PostCode: 4780,
  TownCity: 'Schaerding',
  Latitude: '484.512.000',
  Longitude: '134.360.000',
  Telephone: '+4377122194',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.4512,13.436',
  id: '48.4512,13.436'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Kärtner Str. 2',
  AddressLine2: 'STMK',
  PostCode: 8811,
  TownCity: 'Scheifling',
  Latitude: '471.528.290',
  Longitude: '144.140.570',
  Telephone: '+4335822485',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:59',
  SundayFrom: '08:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '47.152829,14.414057',
  id: '47.152829,14.414057'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Braunauer Str. 1',
  AddressLine2: 'SBG',
  PostCode: 5122,
  TownCity: 'Ach',
  Latitude: '481.570.682',
  Longitude: '128.402.924',
  Telephone: '+4377274018',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.1570682,12.8402924',
  id: '48.1570682,12.8402924'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Steyrerstrasse 29',
  AddressLine2: 'OOE',
  PostCode: 4522,
  TownCity: 'Sierning',
  Latitude: '480.457.000',
  Longitude: '143.130.000',
  Telephone: '+4372592421',
  WeekDayFrom: '07:00',
  WeekDayTo: '18:30',
  SaturdayFrom: '07:30',
  SaturdayTo: '13:00',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.0457,14.313',
  id: '48.0457,14.313'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Arnbach 74',
  AddressLine2: 'T',
  PostCode: 9920,
  TownCity: 'Sillian',
  Latitude: '467.439.465',
  Longitude: '123.935.279',
  Telephone: '+4348426311',
  WeekDayFrom: '08:00',
  WeekDayTo: '19:30',
  SaturdayFrom: '08:00',
  SaturdayTo: '19:30',
  SundayFrom: '08:00',
  SundayTo: '19:30',
  IsOpen24Hours: 'No',
  location: '46.7439465,12.3935279',
  id: '46.7439465,12.3935279'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Mariazellerstr.182',
  AddressLine2: 'NOE',
  PostCode: 3106,
  TownCity: 'Spratzern',
  Latitude: '481.792.415',
  Longitude: '156.155.487',
  Telephone: '+43274274960',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1792415,15.6155487',
  id: '48.1792415,15.6155487'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Salzburgerstrasse 401',
  AddressLine2: 'STMK',
  PostCode: 8950,
  TownCity: 'Stainach',
  Latitude: '475.324.000',
  Longitude: '141.060.000',
  Telephone: '+43368222650',
  WeekDayFrom: '06:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Yes',
  location: '47.5324,14.106',
  id: '47.5324,14.106'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Salzburgerstr.',
  AddressLine2: 'T',
  PostCode: 6380,
  TownCity: 'St. Johann in Tirol',
  Latitude: '475.292.853',
  Longitude: '124.286.349',
  Telephone: '+43535262439',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.5292853,12.4286349',
  id: '47.5292853,12.4286349'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Adsdorf 13',
  AddressLine2: 'OOE',
  PostCode: 4113,
  TownCity: 'St. Martin im Muehlkreis',
  Latitude: '484.338.000',
  Longitude: '140.433.000',
  Telephone: '+43723222350',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '12:00',
  IsOpen24Hours: 'No',
  location: '48.4338,14.0433',
  id: '48.4338,14.0433'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Bundesstrasse 59',
  AddressLine2: 'STMK',
  PostCode: 8770,
  TownCity: 'St. Michael',
  Latitude: '473.495.000',
  Longitude: '150.088.000',
  Telephone: '+4338435116',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.3495,15.0088',
  id: '47.3495,15.0088'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Pragerstr./Hornerstr.',
  AddressLine2: 'NOE',
  PostCode: 2000,
  TownCity: 'Stockerau',
  Latitude: '483.851.000',
  Longitude: '162.083.000',
  Telephone: '+43226662438',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.3851,16.2083',
  id: '48.3851,16.2083'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Wildshut 9',
  AddressLine2: 'SBG',
  PostCode: 5120,
  TownCity: 'St.Pantaleon',
  Latitude: '480.122.000',
  Longitude: '128.616.000',
  Telephone: '+4362776285',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '18:00',
  IsOpen24Hours: 'No',
  location: '48.0122,12.8616',
  id: '48.0122,12.8616'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Eisenstrasse 85',
  AddressLine2: 'OOE',
  PostCode: 4451,
  TownCity: 'St. Ulrich/Garsten',
  Latitude: '480.011.000',
  Longitude: '144.104.000',
  Telephone: '+43725254161',
  WeekDayFrom: '04:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.0011,14.4104',
  id: '48.0011,14.4104'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Werkstrasse 4',
  AddressLine2: 'NOE',
  PostCode: 4300,
  TownCity: 'St. Valentin',
  Latitude: '481.714.000',
  Longitude: '145.122.000',
  Telephone: '+43743552224',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '07:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'No',
  location: '48.1714,14.5122',
  id: '48.1714,14.5122'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Friesacherstrasse 68',
  AddressLine2: 'KTN',
  PostCode: 9300,
  TownCity: 'St. Veit an der Glan',
  Latitude: '467.734.670',
  Longitude: '143.683.680',
  Telephone: '+4342122398',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '46.773467,14.368368',
  id: '46.773467,14.368368'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Rodlbergerstrasse 39',
  AddressLine2: 'OOE',
  PostCode: 4600,
  TownCity: 'Thalheim bei Wels',
  Latitude: '481.538.000',
  Longitude: '140.368.000',
  Telephone: '+43724244079',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.1538,14.0368',
  id: '48.1538,14.0368'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Industriepark 2',
  AddressLine2: 'STMK',
  PostCode: 8784,
  TownCity: 'Trieben',
  Latitude: '474.938.000',
  Longitude: '144.829.000',
  Telephone: '+4336155161',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.4938,14.4829',
  id: '47.4938,14.4829'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hauptstrasse 9 A',
  AddressLine2: 'STMK',
  PostCode: 8793,
  TownCity: 'Trofaiach',
  Latitude: '474.197.000',
  Longitude: '150.148.000',
  Telephone: '+4338472879',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '13:00',
  IsOpen24Hours: 'No',
  location: '47.4197,15.0148',
  id: '47.4197,15.0148'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Loeck 20',
  AddressLine2: 'T',
  PostCode: 6441,
  TownCity: 'Umhausen',
  Latitude: '471.358.680',
  Longitude: '109.273.660',
  Telephone: '+4352555472',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.135868,10.927366',
  id: '47.135868,10.927366'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Ufer 4',
  AddressLine2: 'OOE',
  PostCode: 4360,
  TownCity: 'Grein',
  Latitude: '482.229.000',
  Longitude: '148.503.000',
  Telephone: '+437268472',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.2229,14.8503',
  id: '48.2229,14.8503'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Maria Gailer Strasse',
  AddressLine2: 'KTN',
  PostCode: 9500,
  TownCity: 'Villach',
  Latitude: '466.047.380',
  Longitude: '138.648.643',
  Telephone: '+434242312706',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '46.604738,13.8648643',
  id: '46.604738,13.8648643'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Turracher Bundesstr. 12',
  AddressLine2: 'KTN',
  PostCode: 9565,
  TownCity: 'Ebene-Reichenau',
  Latitude: '468.573.210',
  Longitude: '138.921.000',
  Telephone: '+434275217',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '46.857321,13.8921',
  id: '46.857321,13.8921'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Ossiacherzeile 50 A',
  AddressLine2: 'KTN',
  PostCode: 9500,
  TownCity: 'Villach',
  Latitude: '466.122.000',
  Longitude: '138.571.000',
  Telephone: '+43424224589',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '46.6122,13.8571',
  id: '46.6122,13.8571'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Inntalautobahn',
  AddressLine2: 'T',
  PostCode: 6114,
  TownCity: 'Weer',
  Latitude: '473.190.793',
  Longitude: '116.556.023',
  Telephone: '+43522468388',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.3190793,11.6556023',
  id: '47.3190793,11.6556023'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Eferdingerstrasse 81',
  AddressLine2: 'OOE',
  PostCode: 4600,
  TownCity: 'Wels',
  Latitude: '481.810.000',
  Longitude: '140.269.000',
  Telephone: '+43724250886',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.181,14.0269',
  id: '48.181,14.0269'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Altmannsdorferstr.94',
  AddressLine2: 'W',
  PostCode: 1120,
  TownCity: 'Wien',
  Latitude: '481.621.860',
  Longitude: '163.172.537',
  Telephone: '+4318043718',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.162186,16.3172537',
  id: '48.162186,16.3172537'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Altmannsdorferstr.117',
  AddressLine2: 'W',
  PostCode: 1120,
  TownCity: 'Wien',
  Latitude: '481.609.269',
  Longitude: '163.186.078',
  Telephone: '+4316678737',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1609269,16.3186078',
  id: '48.1609269,16.3186078'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Arsenalstrasse 5b',
  AddressLine2: 'W',
  PostCode: 1030,
  TownCity: 'Wien',
  Latitude: '481.777.000',
  Longitude: '163.929.000',
  Telephone: '+4317982552',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Yes',
  location: '48.1777,16.3929',
  id: '48.1777,16.3929'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Bruennerstrasse 57c',
  AddressLine2: 'W',
  PostCode: 1210,
  TownCity: 'Wien',
  Latitude: '482.671.390',
  Longitude: '164.025.640',
  Telephone: '+4312782239',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.267139,16.402564',
  id: '48.267139,16.402564'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Glemmtaler Landesstr.386',
  AddressLine2: 'SBG',
  PostCode: 5753,
  TownCity: 'Saalbach',
  Latitude: '473.890.774',
  Longitude: '126.423.411',
  Telephone: '+4365416453',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.3890774,12.6423411',
  id: '47.3890774,12.6423411'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Donaufelderstr. 91',
  AddressLine2: 'W',
  PostCode: 1210,
  TownCity: 'Wien',
  Latitude: '482.533.570',
  Longitude: '164.184.040',
  Telephone: '800080645',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.253357,16.418404',
  id: '48.253357,16.418404'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Dresdnerstrasse 8',
  AddressLine2: 'W',
  PostCode: 1200,
  TownCity: 'Wien',
  Latitude: '482.408.000',
  Longitude: '163.746.000',
  Telephone: '+4313304231',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.2408,16.3746',
  id: '48.2408,16.3746'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Einzingergasse 2',
  AddressLine2: 'W',
  PostCode: 1210,
  TownCity: 'Wien',
  Latitude: '482.825.000',
  Longitude: '163.799.000',
  Telephone: '+4312704191',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.2825,16.3799',
  id: '48.2825,16.3799'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Erdbergerlaende 30',
  AddressLine2: 'W',
  PostCode: 1030,
  TownCity: 'Wien',
  Latitude: '482.012.000',
  Longitude: '164.037.000',
  Telephone: '+4317154826',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.2012,16.4037',
  id: '48.2012,16.4037'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Erdbergstr. 184',
  AddressLine2: 'W',
  PostCode: 1030,
  TownCity: 'Wien',
  Latitude: '481.931.740',
  Longitude: '164.103.180',
  Telephone: '17963275',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.193174,16.410318',
  id: '48.193174,16.410318'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Erzherzog Karlstr. 253',
  AddressLine2: 'W',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '482.206.000',
  Longitude: '164.799.000',
  Telephone: '+4312821480',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.2206,16.4799',
  id: '48.2206,16.4799'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Am Fasangarten 9',
  AddressLine2: 'W',
  PostCode: 1120,
  TownCity: 'Wien',
  Latitude: '481.712.181',
  Longitude: '163.047.525',
  Telephone: '+4318135374',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.1712181,16.3047525',
  id: '48.1712181,16.3047525'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Franzosengraben',
  AddressLine2: 'W',
  PostCode: 1030,
  TownCity: 'Wien',
  Latitude: '481.861.822',
  Longitude: '164.125.764',
  Telephone: '+4317988210',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.1861822,16.4125764',
  id: '48.1861822,16.4125764'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Franz Josefskai/Morzinpl',
  AddressLine2: 'W',
  PostCode: 1010,
  TownCity: 'Wien',
  Latitude: '482.128.170',
  Longitude: '163.752.560',
  Telephone: '+4315337398',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.212817,16.375256',
  id: '48.212817,16.375256'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Gablenzgasse 72',
  AddressLine2: 'W',
  PostCode: 1160,
  TownCity: 'Wien',
  Latitude: '482.059.000',
  Longitude: '163.218.000',
  Telephone: '+4314921006',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.2059,16.3218',
  id: '48.2059,16.3218'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Gaudenzdorfer Guertel 77',
  AddressLine2: 'W',
  PostCode: 1120,
  TownCity: 'Wien',
  Latitude: '481.879.000',
  Longitude: '163.389.000',
  Telephone: '+4318155177',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1879,16.3389',
  id: '48.1879,16.3389'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Geiselbergstr.47/Hauffg.35',
  AddressLine2: 'W',
  PostCode: 1110,
  TownCity: 'Wien',
  Latitude: '481.749.070',
  Longitude: '164.079.790',
  Telephone: '+4317495599',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.174907,16.407979',
  id: '48.174907,16.407979'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hernalser Hauptstr.32-34',
  AddressLine2: 'W',
  PostCode: 1170,
  TownCity: 'Wien',
  Latitude: '482.171.142',
  Longitude: '163.382.813',
  Telephone: '+4314064649',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.2171142,16.3382813',
  id: '48.2171142,16.3382813'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Handelskai 90',
  AddressLine2: 'W',
  PostCode: 1200,
  TownCity: 'Wien',
  Latitude: '482.431.000',
  Longitude: '163.841.000',
  Telephone: '800080645',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Yes',
  location: '48.2431,16.3841',
  id: '48.2431,16.3841'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Handelskai 276-280',
  AddressLine2: 'W',
  PostCode: 1020,
  TownCity: 'Wien',
  Latitude: '482.166.590',
  Longitude: '164.169.430',
  Telephone: '+4317293183',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.216659,16.416943',
  id: '48.216659,16.416943'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hadikgasse/Kefergasse',
  AddressLine2: 'W',
  PostCode: 1140,
  TownCity: 'Wien',
  Latitude: '481.927.411',
  Longitude: '162.775.969',
  Telephone: '+4319141238',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1927411,16.2775969',
  id: '48.1927411,16.2775969'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Heiligenstaedterstr.77',
  AddressLine2: 'W',
  PostCode: 1190,
  TownCity: 'Wien',
  Latitude: '482.440.263',
  Longitude: '163.615.186',
  Telephone: '+4313681494',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.2440263,16.3615186',
  id: '48.2440263,16.3615186'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hietzinger Kai 133-135',
  AddressLine2: 'W',
  PostCode: 1130,
  TownCity: 'Wien',
  Latitude: '481.915.000',
  Longitude: '162.798.000',
  Telephone: '+4318771451',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1915,16.2798',
  id: '48.1915,16.2798'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Kaiser Ebersdorferstr.248-250',
  AddressLine2: 'W',
  PostCode: 1110,
  TownCity: 'Wien',
  Latitude: '481.596.809',
  Longitude: '164.577.638',
  Telephone: '+4317671063',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.1596809,16.4577638',
  id: '48.1596809,16.4577638'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Krottenbachstr. 17',
  AddressLine2: 'W',
  PostCode: 1190,
  TownCity: 'Wien',
  Latitude: '482.403.550',
  Longitude: '163.419.650',
  Telephone: '+4313681379',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.240355,16.341965',
  id: '48.240355,16.341965'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Laxenburgerstrasse 2a',
  AddressLine2: 'W',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '481.832.890',
  Longitude: '163.734.140',
  Telephone: '+4316044132',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.183289,16.373414',
  id: '48.183289,16.373414'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Linke Wienzeile 90-92',
  AddressLine2: 'W',
  PostCode: 1060,
  TownCity: 'Wien',
  Latitude: '481.943.722',
  Longitude: '163.539.679',
  Telephone: '+4315856962',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.1943722,16.3539679',
  id: '48.1943722,16.3539679'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Linzer Str. 135-137',
  AddressLine2: 'W',
  PostCode: 1140,
  TownCity: 'Wien',
  Latitude: '481.934.000',
  Longitude: '163.017.000',
  Telephone: '+4319854327',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.1934,16.3017',
  id: '48.1934,16.3017'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Margaretenguertel 43-45',
  AddressLine2: 'W',
  PostCode: 1050,
  TownCity: 'Wien',
  Latitude: '481.798.000',
  Longitude: '163.517.000',
  Telephone: '+4315480292',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1798,16.3517',
  id: '48.1798,16.3517'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Maroltingergasse 5',
  AddressLine2: 'W',
  PostCode: 1140,
  TownCity: 'Wien',
  Latitude: '482.035.804',
  Longitude: '163.030.850',
  Telephone: '+4319143237',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.2035804,16.303085',
  id: '48.2035804,16.303085'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Klopeinersee Str. 6',
  AddressLine2: 'KTN',
  PostCode: 9122,
  TownCity: 'St. Kanzian',
  Latitude: '466.151.155',
  Longitude: '145.753.641',
  Telephone: '+4342392450',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Yes',
  location: '46.6151155,14.5753641',
  id: '46.6151155,14.5753641'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Triesterstrasse 104',
  AddressLine2: 'W',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '481.573.000',
  Longitude: '163.411.000',
  Telephone: '+4316674134',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1573,16.3411',
  id: '48.1573,16.3411'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Triesterstrasse 153',
  AddressLine2: 'W',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '481.565.000',
  Longitude: '163.416.000',
  Telephone: '+4316167464',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1565,16.3416',
  id: '48.1565,16.3416'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wienerbergstr. 29',
  AddressLine2: 'W',
  PostCode: 1120,
  TownCity: 'Wien',
  Latitude: '481.709.000',
  Longitude: '163.368.000',
  Telephone: '+4318156279',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.1709,16.3368',
  id: '48.1709,16.3368'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Gewerbeparkstr.On1 Gew.Park Stadlau',
  AddressLine2: 'W',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '482.476.153',
  Longitude: '164.658.336',
  Telephone: '+4317344935',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.2476153,16.4658336',
  id: '48.2476153,16.4658336'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Zinnergasse 4',
  AddressLine2: 'W',
  PostCode: 1110,
  TownCity: 'Wien',
  Latitude: '481.612.000',
  Longitude: '164.715.000',
  Telephone: '+4317676404',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.1612,16.4715',
  id: '48.1612,16.4715'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Zur Autobahn 2',
  AddressLine2: 'NOE',
  PostCode: 3250,
  TownCity: 'Wieselburg',
  Latitude: '481.352.250',
  Longitude: '151.416.780',
  Telephone: '+43741658788',
  WeekDayFrom: '05:30',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.135225,15.141678',
  id: '48.135225,15.141678'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Altenberger  Strasse 7',
  AddressLine2: 'OOE',
  PostCode: 4040,
  TownCity: 'Linz-Auhof',
  Latitude: '483.317.367',
  Longitude: '143.175.808',
  Telephone: '+43732245847',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.3317367,14.3175808',
  id: '48.3317367,14.3175808'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Autobahnzubringer',
  AddressLine2: 'NOE',
  PostCode: 2752,
  TownCity: 'Woellersdorf',
  Latitude: '478.597.650',
  Longitude: '161.807.520',
  Telephone: '+43263342822',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.859765,16.180752',
  id: '47.859765,16.180752'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Industriezentrum Sued Ii',
  AddressLine2: 'NOE',
  PostCode: 2351,
  TownCity: 'Wiener Neudorf',
  Latitude: '480.788.244',
  Longitude: '163.239.240',
  Telephone: '+43223662430',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.0788244,16.323924',
  id: '48.0788244,16.323924'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Wienerstrasse 114',
  AddressLine2: 'NOE',
  PostCode: 2700,
  TownCity: 'Wiener Neustadt',
  Latitude: '478.295.640',
  Longitude: '162.443.700',
  Telephone: '+43262227939',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '47.829564,16.24437',
  id: '47.829564,16.24437'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Alte Poststr.25',
  AddressLine2: 'NOE',
  PostCode: 3341,
  TownCity: 'Ybbsitz',
  Latitude: '479.483.652',
  Longitude: '148.955.621',
  Telephone: '+43744386511',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '12:00',
  SundayFrom: '08:00',
  SundayTo: '12:00',
  IsOpen24Hours: 'Yes',
  location: '47.9483652,14.8955621',
  id: '47.9483652,14.8955621'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Aussergant',
  AddressLine2: 'VBG',
  PostCode: 6791,
  TownCity: 'St. Gallenkirch',
  Latitude: '470.202.617',
  Longitude: '99.771.738',
  Telephone: '+4355576257',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.0202617,9.9771738',
  id: '47.0202617,9.9771738'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Aiglhofstrasse 20',
  AddressLine2: 'SBG',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '478.034.111',
  Longitude: '130.286.574',
  Telephone: '+43662432552',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.8034111,13.0286574',
  id: '47.8034111,13.0286574'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Dorf 48',
  AddressLine2: 'T',
  PostCode: 6373,
  TownCity: 'Jochberg',
  Latitude: '473.758.000',
  Longitude: '124.190.000',
  Telephone: '+4353555297',
  WeekDayFrom: '07:30',
  WeekDayTo: '18:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '17:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.3758,12.419',
  id: '47.3758,12.419'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Nonntaler Hauptstrasse 65',
  AddressLine2: 'SBG',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '477.845.000',
  Longitude: '130.525.000',
  Telephone: '+43662825644',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.7845,13.0525',
  id: '47.7845,13.0525'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Kaerntner Strasse',
  AddressLine2: 'STMK',
  PostCode: 8580,
  TownCity: 'Koeflach',
  Latitude: '470.620.630',
  Longitude: '150.800.660',
  Telephone: '+4331442638',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.062063,15.080066',
  id: '47.062063,15.080066'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Pass Thurn Strasse 17',
  AddressLine2: 'T',
  PostCode: 6371,
  TownCity: 'Aurach',
  Latitude: '474.185.000',
  Longitude: '124.216.000',
  Telephone: '53566402016',
  WeekDayFrom: '06:30',
  WeekDayTo: '19:00',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4185,12.4216',
  id: '47.4185,12.4216'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Gries 16',
  AddressLine2: 'T',
  PostCode: 6631,
  TownCity: 'Lermoos',
  Latitude: '474.063.172',
  Longitude: '108.700.748',
  Telephone: '+4356732197',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.4063172,10.8700748',
  id: '47.4063172,10.8700748'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wald 1',
  AddressLine2: 'T',
  PostCode: 6306,
  TownCity: 'Soell',
  Latitude: '474.994.000',
  Longitude: '121.883.000',
  Telephone: '+4353335209',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.4994,12.1883',
  id: '47.4994,12.1883'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Nr.8',
  AddressLine2: 'T',
  PostCode: 6410,
  TownCity: 'Telfs-Sagl',
  Latitude: '473.171.250',
  Longitude: '110.935.730',
  Telephone: '+43526262277',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.317125,11.093573',
  id: '47.317125,11.093573'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Stubaitalstr. 81',
  AddressLine2: 'T',
  PostCode: 6167,
  TownCity: 'Neustift',
  Latitude: '471.115.000',
  Longitude: '113.096.000',
  Telephone: '+4352263525',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.1115,11.3096',
  id: '47.1115,11.3096'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Kremstal Bundestrasse 76',
  AddressLine2: 'OOE',
  PostCode: 4050,
  TownCity: 'Traun',
  Latitude: '482.306.260',
  Longitude: '142.218.045',
  Telephone: '722961291',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.230626,14.2218045',
  id: '48.230626,14.2218045'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hauptstrasse 30',
  AddressLine2: 'KTN',
  PostCode: 9871,
  TownCity: 'Seeboden',
  Latitude: '468.166.000',
  Longitude: '135.101.000',
  Telephone: '800080645',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '46.8166,13.5101',
  id: '46.8166,13.5101'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Joh.Herbst-Str. 15',
  AddressLine2: '',
  PostCode: 5061,
  TownCity: 'Glasenbach-Elsbethen',
  Latitude: '477.695.606',
  Longitude: '130.854.034',
  Telephone: '+43662621363',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:30',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:30',
  SundayFrom: '06:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.7695606,13.0854034',
  id: '47.7695606,13.0854034'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Hauptstrasse 1b',
  AddressLine2: 'KTN',
  PostCode: 9341,
  TownCity: 'Strassburg',
  Latitude: '468.950.000',
  Longitude: '143.377.000',
  Telephone: '+4342663101',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Yes',
  location: '46.895,14.3377',
  id: '46.895,14.3377'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Ausfahrtsstrasse',
  AddressLine2: 'NOE',
  PostCode: 1300,
  TownCity: 'Schwechat-Flughafen',
  Latitude: '481.248.081',
  Longitude: '165.619.969',
  Telephone: '+431700732618',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1248081,16.5619969',
  id: '48.1248081,16.5619969'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hirschgraben',
  AddressLine2: 'STMK',
  PostCode: 8707,
  TownCity: 'Leoben-Goess',
  Latitude: '473.640.164',
  Longitude: '150.961.791',
  Telephone: '+43384222194',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.3640164,15.0961791',
  id: '47.3640164,15.0961791'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wiener Strasse 51',
  AddressLine2: 'STMK',
  PostCode: 8720,
  TownCity: 'Knittelfeld',
  Latitude: '472.202.000',
  Longitude: '148.290.000',
  Telephone: '+43351283710',
  WeekDayFrom: '06:30',
  WeekDayTo: '22:30',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:30',
  SundayFrom: '06:30',
  SundayTo: '22:30',
  IsOpen24Hours: 'No',
  location: '47.2202,14.829',
  id: '47.2202,14.829'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Passauer Strasse 29',
  AddressLine2: 'OOE',
  PostCode: 4780,
  TownCity: 'Schaerding',
  Latitude: '484.675.150',
  Longitude: '134.417.705',
  Telephone: '+43771250021',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.467515,13.4417705',
  id: '48.467515,13.4417705'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Zellerstrasse 29',
  AddressLine2: 'SBG',
  PostCode: 5751,
  TownCity: 'Maishofen',
  Latitude: '473.507.000',
  Longitude: '128.005.000',
  Telephone: '+43654268056',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.3507,12.8005',
  id: '47.3507,12.8005'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Schnellstrasse S 6',
  AddressLine2: 'NOE',
  PostCode: 2620,
  TownCity: 'Natschbach',
  Latitude: '477.067.365',
  Longitude: '161.021.590',
  Telephone: '+43263569714',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.7067365,16.102159',
  id: '47.7067365,16.102159'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Voralpenkreuz A8',
  AddressLine2: 'OOE',
  PostCode: 4642,
  TownCity: 'Sattledt',
  Latitude: '480.660.300',
  Longitude: '140.350.212',
  Telephone: '+43724420189',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.06603,14.0350212',
  id: '48.06603,14.0350212'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Mariazeller Str.264',
  AddressLine2: 'NOE',
  PostCode: 3100,
  TownCity: 'St. Poelten',
  Latitude: '481.574.248',
  Longitude: '156.142.557',
  Telephone: '+432742883738',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.1574248,15.6142557',
  id: '48.1574248,15.6142557'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Lamprechtshauserner B156',
  AddressLine2: 'SBG',
  PostCode: 5101,
  TownCity: 'Bergheim / Lengfelden',
  Latitude: '478.441.697',
  Longitude: '130.505.776',
  Telephone: '+43662453775',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.8441697,13.0505776',
  id: '47.8441697,13.0505776'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'An Der Raststation-Lkw',
  AddressLine2: 'NOE',
  PostCode: 3385,
  TownCity: 'Voellerndorf',
  Latitude: '481.760.440',
  Longitude: '155.568.980',
  Telephone: '+43274272860',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.176044,15.556898',
  id: '48.176044,15.556898'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Hochhauser',
  AddressLine1: 'BREITENFURTER STRASSE 112',
  AddressLine2: 'W',
  PostCode: 1120,
  TownCity: 'WIEN',
  Latitude: '481.605.330',
  Longitude: '163.095.340',
  Telephone: '4318023624',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.160533,16.309534',
  id: '48.160533,16.309534'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Montafoner Strasse 29',
  AddressLine2: 'VBG',
  PostCode: 6700,
  TownCity: 'Bludenz',
  Latitude: '471.418.290',
  Longitude: '98.334.120',
  Telephone: '+43555267744',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.141829,9.833412',
  id: '47.141829,9.833412'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hauptstrasse 54',
  AddressLine2: 'NOE',
  PostCode: 3021,
  TownCity: 'Pressbaum',
  Latitude: '481.803.000',
  Longitude: '160.780.000',
  Telephone: '+43223352543',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.1803,16.078',
  id: '48.1803,16.078'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Stiegstrasse 63',
  AddressLine2: 'VBG',
  PostCode: 6830,
  TownCity: 'Rankweil',
  Latitude: '472.798.000',
  Longitude: '96.497.100',
  Telephone: '+43552244581',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.2798,9.64971',
  id: '47.2798,9.64971'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wiener Strasse 58',
  AddressLine2: 'NOE',
  PostCode: 2514,
  TownCity: 'Traiskirchen',
  Latitude: '480.212.320',
  Longitude: '162.979.630',
  Telephone: '+43225252359',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.021232,16.297963',
  id: '48.021232,16.297963'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Langenlebarnerstr.41',
  AddressLine2: 'NOE',
  PostCode: 3430,
  TownCity: 'Tulln',
  Latitude: '483.313.308',
  Longitude: '160.647.689',
  Telephone: '+43227262205',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:30',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:30',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '48.3313308,16.0647689',
  id: '48.3313308,16.0647689'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Reichshofstrasse',
  AddressLine2: 'VBG',
  PostCode: 6890,
  TownCity: 'Lustenau',
  Latitude: '474.245.180',
  Longitude: '96.518.010',
  Telephone: '+43557788184',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.424518,9.651801',
  id: '47.424518,9.651801'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Haemmerlestrasse 99',
  AddressLine2: 'VBG',
  PostCode: 6805,
  TownCity: 'Feldkirch-Gisingen',
  Latitude: '472.411.000',
  Longitude: '95.910.200',
  Telephone: '+43552273455',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.2411,9.59102',
  id: '47.2411,9.59102'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Leobersdorfer Strasse 12',
  AddressLine2: 'NOE',
  PostCode: 2560,
  TownCity: 'Berndorf',
  Latitude: '479.436.000',
  Longitude: '161.115.000',
  Telephone: '+43267282579',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.9436,16.1115',
  id: '47.9436,16.1115'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Triesterstrasse 68',
  AddressLine2: 'W',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '481.656.000',
  Longitude: '163.465.000',
  Telephone: '+4316676173',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1656,16.3465',
  id: '48.1656,16.3465'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Laab 2',
  AddressLine2: 'OOE',
  PostCode: 4770,
  TownCity: 'Andorf',
  Latitude: '483.525.531',
  Longitude: '135.590.310',
  Telephone: '07766/2356',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '48.3525531,13.559031',
  id: '48.3525531,13.559031'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Untermarktstrasse 61',
  AddressLine2: 'T',
  PostCode: 6410,
  TownCity: 'Telfs',
  Latitude: '473.075.170',
  Longitude: '110.792.870',
  Telephone: '+43526262162',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.307517,11.079287',
  id: '47.307517,11.079287'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Zillertalstr. 35',
  AddressLine2: 'T',
  PostCode: 6263,
  TownCity: 'Fuegen',
  Latitude: '473.454.000',
  Longitude: '118.528.000',
  Telephone: '+43528862413',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.3454,11.8528',
  id: '47.3454,11.8528'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Bundesstrasse 292',
  AddressLine2: 'T',
  PostCode: 6543,
  TownCity: 'Nauders',
  Latitude: '468.943.430',
  Longitude: '104.983.670',
  Telephone: '+43547387336',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '46.894343,10.498367',
  id: '46.894343,10.498367'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Laakirchner Hauptstr.43',
  AddressLine2: 'OOE',
  PostCode: 4662,
  TownCity: 'Steyrermuehl',
  Latitude: '479.893.129',
  Longitude: '138.117.320',
  Telephone: '+4376132217',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Yes',
  location: '47.9893129,13.811732',
  id: '47.9893129,13.811732'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Harderstrasse 84',
  AddressLine2: 'VBG',
  PostCode: 6972,
  TownCity: 'Fussach',
  Latitude: '474.674.000',
  Longitude: '96.582.500',
  Telephone: '+43557875718',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Yes',
  location: '47.4674,9.65825',
  id: '47.4674,9.65825'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Neumarkter Strasse 32',
  AddressLine2: 'KTN',
  PostCode: 9360,
  TownCity: 'Friesach',
  Latitude: '469.600.032',
  Longitude: '144.010.809',
  Telephone: '+4342682504',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.9600032,14.4010809',
  id: '46.9600032,14.4010809'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Dorfstrasse 148',
  AddressLine2: 'T',
  PostCode: 6450,
  TownCity: 'Soelden',
  Latitude: '469.631.000',
  Longitude: '110.077.000',
  Telephone: '+4352542466',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '46.9631,11.0077',
  id: '46.9631,11.0077'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Innsbrucker Strasse 24',
  AddressLine2: 'T',
  PostCode: 6162,
  TownCity: 'Mutters',
  Latitude: '472.302.000',
  Longitude: '113.809.000',
  Telephone: '+43512548540',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:30',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Yes',
  location: '47.2302,11.3809',
  id: '47.2302,11.3809'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Moelltalstrasse 33',
  AddressLine2: 'KTN',
  PostCode: 9813,
  TownCity: 'Moellbruecke',
  Latitude: '468.367.000',
  Longitude: '133.737.000',
  Telephone: '+4347692458',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '46.8367,13.3737',
  id: '46.8367,13.3737'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Im Buch 43',
  AddressLine2: 'VBG',
  PostCode: 6840,
  TownCity: 'Goetzis',
  Latitude: '473.397.706',
  Longitude: '96.521.602',
  Telephone: '+43552362719',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.3397706,9.6521602',
  id: '47.3397706,9.6521602'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Gleisdorfer Strasse 60',
  AddressLine2: 'STMK',
  PostCode: 8160,
  TownCity: 'Weiz',
  Latitude: '472.123.000',
  Longitude: '156.293.000',
  Telephone: '+4331722073',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:30',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:30',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.2123,15.6293',
  id: '47.2123,15.6293'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Autobahn St.Poelten-Pkw',
  AddressLine2: 'NOE',
  PostCode: 3385,
  TownCity: 'Voellerndorf',
  Latitude: '481.774.881',
  Longitude: '155.527.113',
  Telephone: '+4327492511',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1774881,15.5527113',
  id: '48.1774881,15.5527113'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Bundesstrasse',
  AddressLine2: 'VBG',
  PostCode: 6863,
  TownCity: 'Egg im Bregenzerwald',
  Latitude: '474.325.160',
  Longitude: '98.953.640',
  Telephone: '+4355122530',
  WeekDayFrom: '06:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.432516,9.895364',
  id: '47.432516,9.895364'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Schrems 3',
  AddressLine2: 'STMK',
  PostCode: 8163,
  TownCity: 'Fladnitz',
  Latitude: '472.687.280',
  Longitude: '154.805.620',
  Telephone: '+4331796132',
  WeekDayFrom: '06:30',
  WeekDayTo: '19:30',
  SaturdayFrom: '06:30',
  SaturdayTo: '19:30',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'No',
  location: '47.268728,15.480562',
  id: '47.268728,15.480562'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Amraser Seestrasse 10',
  AddressLine2: 'T',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '472.609.000',
  Longitude: '114.213.000',
  Telephone: '+43512346424',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'No',
  location: '47.2609,11.4213',
  id: '47.2609,11.4213'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Reichenauer Strasse 149',
  AddressLine2: 'T',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '472.738.000',
  Longitude: '114.282.000',
  Telephone: '+435122821560',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.2738,11.4282',
  id: '47.2738,11.4282'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Flachauwinklstraße 182',
  AddressLine2: 'SBG',
  PostCode: 5542,
  TownCity: 'Flachau',
  Latitude: '472.476.280',
  Longitude: '134.251.580',
  Telephone: '+43645733737',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.247628,13.425158',
  id: '47.247628,13.425158'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Autobahn Tankstelle',
  AddressLine1: 'Innkreisautobahn A8',
  AddressLine2: 'OOE',
  PostCode: 4676,
  TownCity: 'Aistersheim',
  Latitude: '481.781.000',
  Longitude: '137.468.912',
  Telephone: '+4377342232',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1781,13.7468912',
  id: '48.1781,13.7468912'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Linzerstrasse 62',
  AddressLine2: 'OOE',
  PostCode: 4614,
  TownCity: 'Marchtrenk',
  Latitude: '481.914.000',
  Longitude: '141.214.000',
  Telephone: '+43724354456',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.1914,14.1214',
  id: '48.1914,14.1214'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Braunauer Strasse 28',
  AddressLine2: 'SBG',
  PostCode: 5204,
  TownCity: 'Strasswalchen',
  Latitude: '479.881.000',
  Longitude: '132.495.000',
  Telephone: '+4362158356',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.9881,13.2495',
  id: '47.9881,13.2495'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Silvrettastrasse 17',
  AddressLine2: 'T',
  PostCode: 6561,
  TownCity: 'Ischgl',
  Latitude: '470.157.000',
  Longitude: '102.969.000',
  Telephone: '+4354445787',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.0157,10.2969',
  id: '47.0157,10.2969'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hirtenbergerstrasse 4e',
  AddressLine2: 'NOE',
  PostCode: 2544,
  TownCity: 'Leobersdorf',
  Latitude: '479.346.820',
  Longitude: '162.169.760',
  Telephone: '+43225664606',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '05:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Yes',
  location: '47.934682,16.216976',
  id: '47.934682,16.216976'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Erlach 138',
  AddressLine2: 'T',
  PostCode: 6150,
  TownCity: 'Steinach am Brenner',
  Latitude: '470.953.000',
  Longitude: '114.655.000',
  Telephone: '+4352722260',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.0953,11.4655',
  id: '47.0953,11.4655'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wiener Str. 76',
  AddressLine2: 'NOE',
  PostCode: 3500,
  TownCity: 'Krems',
  Latitude: '484.150.000',
  Longitude: '156.175.000',
  Telephone: '+43273277998',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.415,15.6175',
  id: '48.415,15.6175'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Rosenheimer Strasse 6',
  AddressLine2: 'T',
  PostCode: 6330,
  TownCity: 'Kufstein',
  Latitude: '475.938.000',
  Longitude: '121.773.000',
  Telephone: '+43537271021',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.5938,12.1773',
  id: '47.5938,12.1773'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Bruennerstrasse 186',
  AddressLine2: 'W',
  PostCode: 1210,
  TownCity: 'Wien',
  Latitude: '482.835.000',
  Longitude: '164.134.000',
  Telephone: '+4312926869',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.2835,16.4134',
  id: '48.2835,16.4134'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Lustenauer Strasse 112a',
  AddressLine2: 'VBG',
  PostCode: 6845,
  TownCity: 'Hohenems',
  Latitude: '473.736.000',
  Longitude: '96.775.900',
  Telephone: '+43557674262',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.3736,9.67759',
  id: '47.3736,9.67759'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Ennser Strasse 10 A',
  AddressLine2: 'OOE',
  PostCode: 4400,
  TownCity: 'Steyr',
  Latitude: '480.531.000',
  Longitude: '144.216.000',
  Telephone: '+43725282255',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.0531,14.4216',
  id: '48.0531,14.4216'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Puntigamer Strasse 130',
  AddressLine2: 'STMK',
  PostCode: 8055,
  TownCity: 'Graz',
  Latitude: '470.335.000',
  Longitude: '154.427.000',
  Telephone: '+43316292492',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.0335,15.4427',
  id: '47.0335,15.4427'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Brixentalerstrasse 13',
  AddressLine2: 'T',
  PostCode: 6364,
  TownCity: 'Brixen im Thale',
  Latitude: '474.449.000',
  Longitude: '122.335.000',
  Telephone: '+4353342880',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.4449,12.2335',
  id: '47.4449,12.2335'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Niederoesterreichring 20',
  AddressLine2: 'NOE',
  PostCode: 3100,
  TownCity: 'St. Poelten',
  Latitude: '481.980.980',
  Longitude: '156.393.920',
  Telephone: '+432742230033',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '05:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.198098,15.639392',
  id: '48.198098,15.639392'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Salzburgerstrasse 383',
  AddressLine2: 'OOE',
  PostCode: 4030,
  TownCity: 'Linz',
  Latitude: '482.472.760',
  Longitude: '142.648.030',
  Telephone: '+43732372021',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.247276,14.264803',
  id: '48.247276,14.264803'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'L.V.Hoehnelgasse',
  AddressLine2: 'W',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '481.589.740',
  Longitude: '163.870.690',
  Telephone: '+4316881900',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.158974,16.387069',
  id: '48.158974,16.387069'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Neue Landstrasse 68',
  AddressLine2: 'OOE',
  PostCode: 4655,
  TownCity: 'Vorchdorf',
  Latitude: '480.083.000',
  Longitude: '139.233.000',
  Telephone: '+4376145360',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.0083,13.9233',
  id: '48.0083,13.9233'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Salzburger Strasse 146',
  AddressLine2: 'OOE',
  PostCode: 4600,
  TownCity: 'Wels',
  Latitude: '481.566.000',
  Longitude: '140.009.000',
  Telephone: '+43724244560',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'No',
  location: '48.1566,14.0009',
  id: '48.1566,14.0009'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Gewerbestrasse 1',
  AddressLine2: 'SBG',
  PostCode: 5301,
  TownCity: 'Eugendorf-Strass',
  Latitude: '478.616.356',
  Longitude: '131.284.904',
  Telephone: '+4362252405',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '47.8616356,13.1284904',
  id: '47.8616356,13.1284904'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Iseltalerstrasse 25',
  AddressLine2: 'T',
  PostCode: 9900,
  TownCity: 'Lienz',
  Latitude: '468.298.000',
  Longitude: '127.547.000',
  Telephone: '+43485262659',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '46.8298,12.7547',
  id: '46.8298,12.7547'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Grazer Strasse 77',
  AddressLine2: 'STMK',
  PostCode: 8605,
  TownCity: 'Diemlach',
  Latitude: '474.332.207',
  Longitude: '152.721.147',
  Telephone: '+43386222112',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.4332207,15.2721147',
  id: '47.4332207,15.2721147'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Imlau  65',
  AddressLine2: 'SBG',
  PostCode: 5452,
  TownCity: 'Imlau',
  Latitude: '474.631.787',
  Longitude: '131.953.128',
  Telephone: '+4364685327',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.4631787,13.1953128',
  id: '47.4631787,13.1953128'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Salzkammergut Str. 8',
  AddressLine2: 'OOE',
  PostCode: 4844,
  TownCity: 'Regau',
  Latitude: '479.874.000',
  Longitude: '136.912.000',
  Telephone: '+43767275371',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'No',
  location: '47.9874,13.6912',
  id: '47.9874,13.6912'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wieselburger Strasse 2',
  AddressLine2: 'NOE',
  PostCode: 3281,
  TownCity: 'Oberndorf/Melk',
  Latitude: '480.604.170',
  Longitude: '152.136.130',
  Telephone: '+4374837000',
  WeekDayFrom: '06:45',
  WeekDayTo: '19:45',
  SaturdayFrom: '06:45',
  SaturdayTo: '19:45',
  SundayFrom: '08:00',
  SundayTo: '19:45',
  IsOpen24Hours: 'No',
  location: '48.060417,15.213613',
  id: '48.060417,15.213613'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Virgentalstrasse 39',
  AddressLine2: 'KTN',
  PostCode: 9972,
  TownCity: 'Virgen',
  Latitude: '470.018.000',
  Longitude: '124.643.000',
  Telephone: '+4348745267',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.0018,12.4643',
  id: '47.0018,12.4643'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Landesstrasse 1-3',
  AddressLine2: 'KTN',
  PostCode: 9130,
  TownCity: 'Poggersdorf',
  Latitude: '466.414.000',
  Longitude: '144.495.000',
  Telephone: '+43422481302',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '46.6414,14.4495',
  id: '46.6414,14.4495'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Lagerhaus Rohrbach',
  AddressLine1: 'Falkensteinstrasse 16',
  AddressLine2: 'OOE',
  PostCode: 4154,
  TownCity: 'Kollerschlag',
  Latitude: '486.023.000',
  Longitude: '138.389.000',
  Telephone: '+437287752848',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '05:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'No',
  location: '48.6023,13.8389',
  id: '48.6023,13.8389'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Hauptstrasse 34',
  AddressLine2: 'NOE',
  PostCode: 3200,
  TownCity: 'Obergrafendorf',
  Latitude: '481.498.000',
  Longitude: '155.495.000',
  Telephone: '+4327472275',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '06:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '48.1498,15.5495',
  id: '48.1498,15.5495'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Sebersdorf 304',
  AddressLine2: 'STMK',
  PostCode: 8272,
  TownCity: 'Sebersdorf',
  Latitude: '471.826.780',
  Longitude: '159.926.910',
  Telephone: '+43333324899',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.182678,15.992691',
  id: '47.182678,15.992691'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Brennersee 231',
  AddressLine2: '',
  PostCode: 6156,
  TownCity: 'Gries am Brenner',
  Latitude: '4.701.769.000',
  Longitude: '1.150.733.000',
  Telephone: '+43-5274-87223',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.01769,11.50733',
  id: '47.01769,11.50733'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn A13 (Brenner Autobahn) 234',
  AddressLine2: '',
  PostCode: 6156,
  TownCity: 'Gries am Brenner',
  Latitude: '4.701.424.000',
  Longitude: '1.150.557.000',
  Telephone: '+43-5274-87281',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.01424,11.50557',
  id: '47.01424,11.50557'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Gewerbestraße 8',
  AddressLine2: '',
  PostCode: 3382,
  TownCity: 'Loosdorf',
  Latitude: '4.819.000.000',
  Longitude: '1.540.900.000',
  Telephone: '+43-2754-56773',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.19,15.409',
  id: '48.19,15.409'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Rasthausstraße 3',
  AddressLine2: '',
  PostCode: 4300,
  TownCity: 'Sankt Valentin',
  Latitude: '4.819.676.000',
  Longitude: '1.452.693.000',
  Telephone: '+43-7435-58885',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.19676,14.52693',
  id: '48.19676,14.52693'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Laggen 18',
  AddressLine2: '',
  PostCode: 9861,
  TownCity: 'Eisentratten',
  Latitude: '4.691.988.000',
  Longitude: '1.357.964.000',
  Telephone: '+43-4732-2884',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.91988,13.57964',
  id: '46.91988,13.57964'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn A2 (Südautobahn) KM 36,7',
  AddressLine2: '',
  PostCode: 2721,
  TownCity: 'Bad Fischau',
  Latitude: '4.783.396.000',
  Longitude: '1.618.183.000',
  Telephone: '+43-2639-2411',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.83396,16.18183',
  id: '47.83396,16.18183'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'A12 Inntalautobahn / Wirtschaftsweg',
  AddressLine2: '',
  PostCode: 6321,
  TownCity: 'Angath',
  Latitude: '4.751.950.000',
  Longitude: '1.206.600.000',
  Telephone: '+43-5332-74373',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.5195,12.066',
  id: '47.5195,12.066'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn A12 (Inntalautobahn) bei K',
  AddressLine2: '',
  PostCode: 6134,
  TownCity: 'Vomp',
  Latitude: '4.734.700.000',
  Longitude: '1.169.027.000',
  Telephone: '+43-5242-64357',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.347,11.69027',
  id: '47.347,11.69027'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Dolina 36',
  AddressLine2: '',
  PostCode: 9131,
  TownCity: 'Grafenstein',
  Latitude: '4.663.960.000',
  Longitude: '1.445.090.000',
  Telephone: '+43-4225-29008',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.6396,14.4509',
  id: '46.6396,14.4509'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn Golling Ost 31',
  AddressLine2: '',
  PostCode: 5440,
  TownCity: 'Golling an der Salzach',
  Latitude: '4.758.396.000',
  Longitude: '1.315.750.000',
  Telephone: '+43-6244-6061',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.58396,13.1575',
  id: '47.58396,13.1575'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn A4 (Ostautobahn) KM 27,5',
  AddressLine2: '',
  PostCode: 2464,
  TownCity: 'Göttlesbrunn',
  Latitude: '4.805.676.000',
  Longitude: '1.671.078.000',
  Telephone: '+43-2162-88130',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.05676,16.71078',
  id: '48.05676,16.71078'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn A2 (Südautobahn) KM 190',
  AddressLine2: '',
  PostCode: 8143,
  TownCity: 'Dobl (Kaiserwald)',
  Latitude: '4.695.939.000',
  Longitude: '1.538.155.000',
  Telephone: '+43-3136-52985',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.95939,15.38155',
  id: '46.95939,15.38155'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Brenner Autobahn West',
  AddressLine2: '',
  PostCode: 6143,
  TownCity: 'Matrei Berg',
  Latitude: '4.714.126.338',
  Longitude: '1.144.595.606',
  Telephone: '+43(0)5273/6385',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.14126338,11.44595606',
  id: '47.14126338,11.44595606'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Amraser Seestrasse 64',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck-Amrass',
  Latitude: '4.726.341.380',
  Longitude: '1.143.339.430',
  Telephone: '+43(0)512/345498',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.2634138,11.4333943',
  id: '47.2634138,11.4333943'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'A22 Autobahn/Richtung Wien',
  AddressLine2: '',
  PostCode: 2100,
  TownCity: 'Korneuburg',
  Latitude: '4.834.728.256',
  Longitude: '1.632.001.716',
  Telephone: '+43(0)2262/75349',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.34728256,16.32001716',
  id: '48.34728256,16.32001716'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'A22 Autobahn/Richtung Stockerau',
  AddressLine2: '',
  PostCode: 2100,
  TownCity: 'Korneuburg',
  Latitude: '4.834.620.469',
  Longitude: '1.631.994.529',
  Telephone: '+43(0)2262/74589',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.34620469,16.31994529',
  id: '48.34620469,16.31994529'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Brenner Autobahn Ost',
  AddressLine2: '',
  PostCode: 6143,
  TownCity: 'Matrei Tal',
  Latitude: '4.714.072.000',
  Longitude: '1.144.653.000',
  Telephone: '+43(0)5273/6385',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.14072,11.44653',
  id: '47.14072,11.44653'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'A2 Südautobahn/Pack Km 235',
  AddressLine2: '',
  PostCode: 9451,
  TownCity: 'Preitenegg',
  Latitude: '4.693.881.000',
  Longitude: '1.492.458.000',
  Telephone: '+43(0)4354/2200',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.93881,14.92458',
  id: '46.93881,14.92458'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'A1/Westautobahn',
  AddressLine2: '',
  PostCode: 3373,
  TownCity: 'Ybbs-Kemmelbach',
  Latitude: '4.815.384.620',
  Longitude: '1.509.798.498',
  Telephone: '+43(0)7412/52746',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.1538462,15.09798498',
  id: '48.1538462,15.09798498'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Lamm 113',
  AddressLine2: '',
  PostCode: 5584,
  TownCity: 'Zederhaus/Krottendorf',
  Latitude: '4.713.408.000',
  Longitude: '1.354.854.000',
  Telephone: '+43(0)6478/800',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.13408,13.54854',
  id: '47.13408,13.54854'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Waldstraße Nr.4',
  AddressLine2: '',
  PostCode: 7410,
  TownCity: 'Loipersdorf-Kitzladen',
  Latitude: '4.728.726.000',
  Longitude: '1.606.778.000',
  Telephone: '+43(0)3359/2670',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.28726,16.06778',
  id: '47.28726,16.06778'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'An der Au 1',
  AddressLine2: '',
  PostCode: 6491,
  TownCity: 'Mils bei Imst',
  Latitude: '4.721.354.000',
  Longitude: '1.069.712.000',
  Telephone: '+43(0)5418/20137',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.21354,10.69712',
  id: '47.21354,10.69712'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wientalstraße/Auhofbrücke 40',
  AddressLine2: '',
  PostCode: 1140,
  TownCity: 'Wien',
  Latitude: '4.820.186.000',
  Longitude: '1.623.616.000',
  Telephone: '+43-1-9792586',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.20186,16.23616',
  id: '48.20186,16.23616'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hütteldorfer Straße 216b',
  AddressLine2: '',
  PostCode: 1140,
  TownCity: 'Wien',
  Latitude: '4.819.788.000',
  Longitude: '1.627.994.000',
  Telephone: '+43-1-9145580',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.19788,16.27994',
  id: '48.19788,16.27994'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Gunoldstraße 7/Muthgasse 1',
  AddressLine2: '',
  PostCode: 1190,
  TownCity: 'Wien',
  Latitude: '4.824.531.000',
  Longitude: '1.636.578.000',
  Telephone: '+43-1-3676565',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.24531,16.36578',
  id: '48.24531,16.36578'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Adalbert Stifter-Straße 67',
  AddressLine2: '',
  PostCode: 1200,
  TownCity: 'Wien',
  Latitude: '4.824.309.000',
  Longitude: '1.637.535.000',
  Telephone: '+43-1-3303242',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.24309,16.37535',
  id: '48.24309,16.37535'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Brünner Straße 172',
  AddressLine2: '',
  PostCode: 1210,
  TownCity: 'Wien',
  Latitude: '4.828.114.000',
  Longitude: '1.641.181.000',
  Telephone: '+43-1-2921258',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.28114,16.41181',
  id: '48.28114,16.41181'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Prager Straße 32',
  AddressLine2: '',
  PostCode: 1210,
  TownCity: 'Wien',
  Latitude: '4.826.513.000',
  Longitude: '1.639.386.000',
  Telephone: '+43-1-2711319',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.26513,16.39386',
  id: '48.26513,16.39386'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Breitenleer Straße Ost B302 112',
  AddressLine2: '',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '4.825.141.000',
  Longitude: '1.647.211.000',
  Telephone: '+43-1-7343236',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.25141,16.47211',
  id: '48.25141,16.47211'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hirschstettner Straße 34',
  AddressLine2: '',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '4.824.285.000',
  Longitude: '1.645.645.000',
  Telephone: '+43-1-2040012',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.24285,16.45645',
  id: '48.24285,16.45645'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Laxenburger Straße 202',
  AddressLine2: '',
  PostCode: 1232,
  TownCity: 'Wien',
  Latitude: '4.814.651.000',
  Longitude: '1.636.108.000',
  Telephone: '+43-1-6161980',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.14651,16.36108',
  id: '48.14651,16.36108'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Speisinger Straße 228',
  AddressLine2: '',
  PostCode: 1232,
  TownCity: 'Wien',
  Latitude: '4.815.489.000',
  Longitude: '1.627.110.000',
  Telephone: '+43-1-8887229',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.15489,16.2711',
  id: '48.15489,16.2711'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Reichsstraße 92',
  AddressLine2: '',
  PostCode: 3300,
  TownCity: 'Amstetten',
  Latitude: '4.811.657.000',
  Longitude: '1.490.712.000',
  Telephone: '+43-7472-68585',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.11657,14.90712',
  id: '48.11657,14.90712'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Haidhofstraße 4',
  AddressLine2: '',
  PostCode: 2500,
  TownCity: 'Baden',
  Latitude: '4.799.769.000',
  Longitude: '1.625.102.000',
  Telephone: '+43-2252-88974',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.99769,16.25102',
  id: '47.99769,16.25102'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Eisenstädter Straße 69',
  AddressLine2: '',
  PostCode: 7091,
  TownCity: 'Breitenbrunn',
  Latitude: '4.793.942.000',
  Longitude: '1.672.805.000',
  Telephone: '+43-2683-7060',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.93942,16.72805',
  id: '47.93942,16.72805'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Neustädter Straße 2',
  AddressLine2: '',
  PostCode: 2483,
  TownCity: 'Ebreichsdorf',
  Latitude: '4.796.042.000',
  Longitude: '1.639.770.000',
  Telephone: '+43-2254-72226',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.96042,16.3977',
  id: '47.96042,16.3977'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Stoitzendorferstraße 8',
  AddressLine2: '',
  PostCode: 3730,
  TownCity: 'Eggenburg',
  Latitude: '4.864.370.000',
  Longitude: '1.582.231.000',
  Telephone: '+43-2984-3367',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.6437,15.82231',
  id: '48.6437,15.82231'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Mattersburger Straße 28',
  AddressLine2: '',
  PostCode: 7000,
  TownCity: 'Eisenstadt',
  Latitude: '4.783.317.000',
  Longitude: '1.652.318.000',
  Telephone: '+43-2682-62034',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.83317,16.52318',
  id: '47.83317,16.52318'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Straße 130',
  AddressLine2: '',
  PostCode: 2230,
  TownCity: 'Gänserndorf',
  Latitude: '4.833.812.000',
  Longitude: '1.673.144.000',
  Telephone: '+43-2282-2281',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.33812,16.73144',
  id: '48.33812,16.73144'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'An der Bundesstraße 4',
  AddressLine2: '',
  PostCode: 3701,
  TownCity: 'Großweikersdorf',
  Latitude: '4.847.877.000',
  Longitude: '1.597.709.000',
  Telephone: '+43-2955-70277',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.47877,15.97709',
  id: '48.47877,15.97709'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Straße zur Autobahn 5',
  AddressLine2: '',
  PostCode: 3350,
  TownCity: 'Haag',
  Latitude: '4.811.517.000',
  Longitude: '1.457.068.000',
  Telephone: '+43-7434-42294',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.11517,14.57068',
  id: '48.11517,14.57068'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Gutenhofer Straße 9',
  AddressLine2: '',
  PostCode: 2325,
  TownCity: 'Himberg',
  Latitude: '4.807.949.000',
  Longitude: '1.644.134.000',
  Telephone: '+43-2235-84389',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.07949,16.44134',
  id: '48.07949,16.44134'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Leobersdorfer Straße 96',
  AddressLine2: '',
  PostCode: 2552,
  TownCity: 'Hirtenberg',
  Latitude: '4.793.151.000',
  Longitude: '1.619.569.000',
  Telephone: '+43-2256-81158',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.93151,16.19569',
  id: '47.93151,16.19569'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Straße 29',
  AddressLine2: '',
  PostCode: 2860,
  TownCity: 'Kirchschlag/Bucklige Welt',
  Latitude: '4.749.879.000',
  Longitude: '1.628.993.000',
  Telephone: '+43-2646-2252',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.49879,16.28993',
  id: '47.49879,16.28993'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Badener Straße 1',
  AddressLine2: '',
  PostCode: 2542,
  TownCity: 'Kottingbrunn',
  Latitude: '4.794.930.000',
  Longitude: '1.622.221.000',
  Telephone: '+43-2252-76349',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.9493,16.22221',
  id: '47.9493,16.22221'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Attergauerstraße 11',
  AddressLine2: '',
  PostCode: 4880,
  TownCity: 'Sankt Georgen im Attergau',
  Latitude: '4.793.260.000',
  Longitude: '1.349.221.000',
  Telephone: '+43-7667-6488',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.9326,13.49221',
  id: '47.9326,13.49221'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: '51',
  AddressLine2: '',
  PostCode: 4273,
  TownCity: 'Unterweissenbach',
  Latitude: '4.843.733.000',
  Longitude: '1.478.153.000',
  Telephone: '+43-7956-7258',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.43733,14.78153',
  id: '48.43733,14.78153'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Mühlkreisbahnstraße 3',
  AddressLine2: '',
  PostCode: 4111,
  TownCity: 'Walding',
  Latitude: '4.834.793.000',
  Longitude: '1.415.387.000',
  Telephone: '+43-7234-82473',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.34793,14.15387',
  id: '48.34793,14.15387'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Berg-Sam 22a',
  AddressLine2: '',
  PostCode: 5101,
  TownCity: 'Bergheim',
  Latitude: '4.783.065.000',
  Longitude: '1.306.571.000',
  Telephone: '+43-662-664788',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.83065,13.06571',
  id: '47.83065,13.06571'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Weichseldorf 143',
  AddressLine2: '',
  PostCode: 5733,
  TownCity: 'Bramberg',
  Latitude: '4.726.780.000',
  Longitude: '1.233.757.000',
  Telephone: '+43-6566-7013',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.2678,12.33757',
  id: '47.2678,12.33757'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Salzburger Straße 12',
  AddressLine2: '',
  PostCode: 5112,
  TownCity: 'Lamprechtshausen',
  Latitude: '4.798.643.000',
  Longitude: '1.295.445.000',
  Telephone: '+43-6274-6354',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.98643,12.95445',
  id: '47.98643,12.95445'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Salzburger Straße 2',
  AddressLine2: '',
  PostCode: 5162,
  TownCity: 'Obertrum',
  Latitude: '4.793.554.000',
  Longitude: '1.308.069.000',
  Telephone: '+43-6219-6213',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.93554,13.08069',
  id: '47.93554,13.08069'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bundesstraße 2',
  AddressLine2: '',
  PostCode: 5760,
  TownCity: 'Saalfelden',
  Latitude: '4.742.897.000',
  Longitude: '1.284.275.000',
  Telephone: '+43-6582-72272',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.42897,12.84275',
  id: '47.42897,12.84275'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Alpenstraße 106',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.777.622.000',
  Longitude: '1.306.826.000',
  Telephone: '+43-662-625985',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.77622,13.06826',
  id: '47.77622,13.06826'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Innsbrucker Bundesstraße 142',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.779.796.000',
  Longitude: '1.300.609.000',
  Telephone: '+43-662-827420',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.79796,13.00609',
  id: '47.79796,13.00609'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Münchner Bundesstraße 85',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.782.773.000',
  Longitude: '1.300.872.000',
  Telephone: '+43-662-435330',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.82773,13.00872',
  id: '47.82773,13.00872'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Schiller Straße 28',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.782.232.000',
  Longitude: '1.303.982.000',
  Telephone: '+43-662-450124',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.82232,13.03982',
  id: '47.82232,13.03982'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Vogelweider Straße 86',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.781.696.000',
  Longitude: '1.305.364.000',
  Telephone: '+43-662-877402',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.81696,13.05364',
  id: '47.81696,13.05364'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Salzburger Straße 52',
  AddressLine2: '',
  PostCode: 5620,
  TownCity: 'Schwarzach',
  Latitude: '4.732.019.000',
  Longitude: '1.314.384.000',
  Telephone: '+43-6415-7415',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.32019,13.14384',
  id: '47.32019,13.14384'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wagrainer Straße 11',
  AddressLine2: '',
  PostCode: 5600,
  TownCity: 'Sankt Johann im Pongau',
  Latitude: '4.734.862.000',
  Longitude: '1.320.651.000',
  Telephone: '+43-6412-4284',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.34862,13.20651',
  id: '47.34862,13.20651'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bundesstraße 112',
  AddressLine2: '',
  PostCode: 5582,
  TownCity: 'Sankt Michael im Lungau',
  Latitude: '4.709.406.000',
  Longitude: '1.367.553.000',
  Telephone: '+43-6477-8908',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.09406,13.67553',
  id: '47.09406,13.67553'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Brucker Bundesstraße 110',
  AddressLine2: '',
  PostCode: 5700,
  TownCity: 'Zell am See',
  Latitude: '4.729.793.000',
  Longitude: '1.279.454.000',
  Telephone: '+43-6542-53742',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.29793,12.79454',
  id: '47.29793,12.79454'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Innsbrucker Straße 34',
  AddressLine2: '',
  PostCode: 6230,
  TownCity: 'Brixlegg',
  Latitude: '4.743.323.000',
  Longitude: '1.188.036.000',
  Telephone: '+43-5337-62350',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.43323,11.88036',
  id: '47.43323,11.88036'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Oberachen 13',
  AddressLine2: '',
  PostCode: 6352,
  TownCity: 'Ellmau',
  Latitude: '4.752.129.000',
  Longitude: '1.226.891.000',
  Telephone: '+43-5358-3661',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.52129,12.26891',
  id: '47.52129,12.26891'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bahnhofstraße 11',
  AddressLine2: '',
  PostCode: 6361,
  TownCity: 'Hopfgarten',
  Latitude: '4.745.309.000',
  Longitude: '1.215.153.000',
  Telephone: '+43-5335-3624',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.45309,12.15153',
  id: '47.45309,12.15153'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Egger-Lienz-Straße 3d',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.725.633.000',
  Longitude: '1.138.869.000',
  Telephone: '+43-512-582345',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.25633,11.38869',
  id: '47.25633,11.38869'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Innrain 104',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.725.734.000',
  Longitude: '1.137.805.000',
  Telephone: '+43-512-575303',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.25734,11.37805',
  id: '47.25734,11.37805'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Gewerbezone 11',
  AddressLine2: '',
  PostCode: 6167,
  TownCity: 'Neustift',
  Latitude: '4.712.944.000',
  Longitude: '1.133.707.000',
  Telephone: '+43-5226-3188',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.12944,11.33707',
  id: '47.12944,11.33707'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Oskar Pirlo-Straße 32',
  AddressLine2: '',
  PostCode: 6330,
  TownCity: 'Kufstein',
  Latitude: '4.758.913.000',
  Longitude: '1.217.427.000',
  Telephone: '+43-5372-64549',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.58913,12.17427',
  id: '47.58913,12.17427'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Unterlängenfeld 117b',
  AddressLine2: '',
  PostCode: 6444,
  TownCity: 'Längenfeld',
  Latitude: '4.707.863.000',
  Longitude: '1.096.764.000',
  Telephone: '+43-5253-6385',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.07863,10.96764',
  id: '47.07863,10.96764'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Brenner Straße 114',
  AddressLine2: '',
  PostCode: 6143,
  TownCity: 'Matrei',
  Latitude: '4.712.860.000',
  Longitude: '1.145.258.000',
  Telephone: '+43-5273-6317',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.1286,11.45258',
  id: '47.1286,11.45258'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Münchner Straße 24',
  AddressLine2: '',
  PostCode: 6130,
  TownCity: 'Schwaz',
  Latitude: '4.735.044.000',
  Longitude: '1.170.603.000',
  Telephone: '+43-5242-62433',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.35044,11.70603',
  id: '47.35044,11.70603'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Krinz 27',
  AddressLine2: '',
  PostCode: 6100,
  TownCity: 'Reith bei Seefeld',
  Latitude: '4.732.402.000',
  Longitude: '1.119.909.000',
  Telephone: '+43-5212-2706',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.32402,11.19909',
  id: '47.32402,11.19909'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Höf 14',
  AddressLine2: '',
  PostCode: 6675,
  TownCity: 'Tannheim',
  Latitude: '4.749.917.000',
  Longitude: '1.051.679.000',
  Telephone: '+43-5675-6252',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.49917,10.51679',
  id: '47.49917,10.51679'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Aflinger Straße 4',
  AddressLine2: '',
  PostCode: 6176,
  TownCity: 'Völs',
  Latitude: '4.725.616.000',
  Longitude: '1.131.615.000',
  Telephone: '+43-512-303578',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.25616,11.31615',
  id: '47.25616,11.31615'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Villacher Straße 50',
  AddressLine2: '',
  PostCode: 9800,
  TownCity: 'Spittal an der Drau',
  Latitude: '4.679.163.000',
  Longitude: '1.350.961.000',
  Telephone: '+43-4762-2740',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.79163,13.50961',
  id: '46.79163,13.50961'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Framrach 17',
  AddressLine2: '',
  PostCode: 9433,
  TownCity: 'Sankt Andrä',
  Latitude: '4.674.490.000',
  Longitude: '1.482.285.000',
  Telephone: '+43-4358-2451',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.7449,14.82285',
  id: '46.7449,14.82285'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Grazer Straße 7',
  AddressLine2: '',
  PostCode: 9400,
  TownCity: 'Wolfsberg',
  Latitude: '4.684.742.000',
  Longitude: '1.484.422.000',
  Telephone: '+43-4352-3195',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.84742,14.84422',
  id: '46.84742,14.84422'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Traunuferstraße 108',
  AddressLine2: '',
  PostCode: 4052,
  TownCity: 'Ansfelden',
  Latitude: '4.821.607.000',
  Longitude: '1.427.936.000',
  Telephone: '+43-7229-78989',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.21607,14.27936',
  id: '48.21607,14.27936'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'An der Bundesstraße 1 ca.1KM',
  AddressLine2: '',
  PostCode: 4481,
  TownCity: 'Asten',
  Latitude: '4.822.243.000',
  Longitude: '1.440.833.000',
  Telephone: '+43-7224-66172',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.22243,14.40833',
  id: '48.22243,14.40833'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Salzburger Straße 91',
  AddressLine2: '',
  PostCode: 4800,
  TownCity: 'Attnang-Puchheim',
  Latitude: '4.800.541.000',
  Longitude: '1.370.937.000',
  Telephone: '+43-7674-64990',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.00541,13.70937',
  id: '48.00541,13.70937'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bahnhofstraße 42',
  AddressLine2: '',
  PostCode: 4810,
  TownCity: 'Gmunden',
  Latitude: '4.792.428.000',
  Longitude: '1.378.782.000',
  Telephone: '+43-7612-63990',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.92428,13.78782',
  id: '47.92428,13.78782'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wengerstraße 16',
  AddressLine2: '',
  PostCode: 4710,
  TownCity: 'Grieskirchen',
  Latitude: '4.823.270.000',
  Longitude: '1.382.238.000',
  Telephone: '+43-7248-68281',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.2327,13.82238',
  id: '48.2327,13.82238'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Kremstalstraße 30',
  AddressLine2: '',
  PostCode: 4053,
  TownCity: 'Haid',
  Latitude: '4.820.365.000',
  Longitude: '1.424.699.000',
  Telephone: '+43-7229-88344',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.20365,14.24699',
  id: '48.20365,14.24699'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hainzenbachstraße 18',
  AddressLine2: '',
  PostCode: 4060,
  TownCity: 'Leonding',
  Latitude: '4.827.868.000',
  Longitude: '1.424.570.000',
  Telephone: '+43-732-672547',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.27868,14.2457',
  id: '48.27868,14.2457'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Makartstraße 29',
  AddressLine2: '',
  PostCode: 4020,
  TownCity: 'Linz',
  Latitude: '4.828.592.000',
  Longitude: '1.430.255.000',
  Telephone: '+43-732-653180',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.28592,14.30255',
  id: '48.28592,14.30255'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hafenstraße 8',
  AddressLine2: '',
  PostCode: 4020,
  TownCity: 'Linz',
  Latitude: '4.831.554.000',
  Longitude: '1.430.166.000',
  Telephone: '+43-732-778012',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.31554,14.30166',
  id: '48.31554,14.30166'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Warte am See 28',
  AddressLine2: '',
  PostCode: 5311,
  TownCity: 'Innerschwand am Mondsee',
  Latitude: '4.783.687.000',
  Longitude: '1.339.455.000',
  Telephone: '+43-6232-2849',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.83687,13.39455',
  id: '47.83687,13.39455'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Salzburger Straße 8',
  AddressLine2: '',
  PostCode: 5310,
  TownCity: 'Mondsee',
  Latitude: '4.785.349.000',
  Longitude: '1.334.419.000',
  Telephone: '+43-6232-2583',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.85349,13.34419',
  id: '47.85349,13.34419'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hauptstraße 32',
  AddressLine2: '',
  PostCode: 4901,
  TownCity: 'Ottnang a. H.',
  Latitude: '4.809.666.000',
  Longitude: '1.366.729.000',
  Telephone: '+43-7676-7240',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.09666,13.66729',
  id: '48.09666,13.66729'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: '283',
  AddressLine2: '',
  PostCode: 8271,
  TownCity: 'Bad Waltersdorf',
  Latitude: '4.716.299.000',
  Longitude: '1.601.469.000',
  Telephone: '+43-3333-414110',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.16299,16.01469',
  id: '47.16299,16.01469'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Leobner Straße 59',
  AddressLine2: '',
  PostCode: 8600,
  TownCity: 'Bruck an der Mur',
  Latitude: '4.740.623.000',
  Longitude: '1.526.573.000',
  Telephone: '+43-3862-51022',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.40623,15.26573',
  id: '47.40623,15.26573'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn A9 (Phyrnautobahn) KM 164',
  AddressLine2: '',
  PostCode: 8121,
  TownCity: 'Deutschfeistritz',
  Latitude: '4.720.552.000',
  Longitude: '1.531.602.000',
  Telephone: '+43-3127-40920',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.20552,15.31602',
  id: '47.20552,15.31602'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Grazer Straße 88',
  AddressLine2: '',
  PostCode: 8530,
  TownCity: 'Deutschlandsberg',
  Latitude: '4.681.593.000',
  Longitude: '1.523.460.000',
  Telephone: '+43-3462-2753',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.81593,15.2346',
  id: '46.81593,15.2346'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Heinrichstraße 91',
  AddressLine2: '',
  PostCode: 8010,
  TownCity: 'Graz',
  Latitude: '4.708.178.000',
  Longitude: '1.545.404.000',
  Telephone: '+43-316-323151',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.08178,15.45404',
  id: '47.08178,15.45404'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Seebacher Gasse 8',
  AddressLine2: '',
  PostCode: 8010,
  TownCity: 'Graz',
  Latitude: '4.707.722.000',
  Longitude: '1.545.904.000',
  Telephone: '+43-316-325385',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.07722,15.45904',
  id: '47.07722,15.45904'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hinterbüchl 224',
  AddressLine2: '',
  PostCode: 8224,
  TownCity: 'Kaindorf',
  Latitude: '4.721.909.000',
  Longitude: '1.590.144.000',
  Telephone: '+43-3334-2272',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.21909,15.90144',
  id: '47.21909,15.90144'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'St. Ulrich 6',
  AddressLine2: '',
  PostCode: 4950,
  TownCity: 'Altheim',
  Latitude: '4.825.413.000',
  Longitude: '1.323.644.000',
  Telephone: '+43-7723-42552',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.25413,13.23644',
  id: '48.25413,13.23644'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Salzburger Straße 99',
  AddressLine2: '',
  PostCode: 5280,
  TownCity: 'Braunau',
  Latitude: '4.824.673.000',
  Longitude: '1.304.030.000',
  Telephone: '+43-7722-87227',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.24673,13.0403',
  id: '48.24673,13.0403'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Reiterholz 3',
  AddressLine2: '',
  PostCode: 4663,
  TownCity: 'Laakirchen',
  Latitude: '4.800.466.000',
  Longitude: '1.385.405.000',
  Telephone: '+43-7613-3510',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.00466,13.85405',
  id: '48.00466,13.85405'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Laaer Berg-Straße 240',
  AddressLine2: '',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '4.814.818.000',
  Longitude: '1.639.664.000',
  Telephone: '+43-1-6888065',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.14818,16.39664',
  id: '48.14818,16.39664'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'St. Pöltner Straße 54',
  AddressLine2: '',
  PostCode: 3130,
  TownCity: 'Herzogenburg',
  Latitude: '4.827.876.000',
  Longitude: '1.569.564.000',
  Telephone: '+43-2782-84981',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.27876,15.69564',
  id: '48.27876,15.69564'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Waidhofner Straße 23',
  AddressLine2: '',
  PostCode: 3331,
  TownCity: 'Hilm',
  Latitude: '4.802.154.000',
  Longitude: '1.475.676.000',
  Telephone: '+43-7448-2252',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.02154,14.75676',
  id: '48.02154,14.75676'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bernsteinstraße 6',
  AddressLine2: '',
  PostCode: 2273,
  TownCity: 'Hohenau an der March',
  Latitude: '4.860.217.000',
  Longitude: '1.690.004.000',
  Telephone: '+43-2535-2130',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.60217,16.90004',
  id: '48.60217,16.90004'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Eduard Rösch-Straße 6-8',
  AddressLine2: '',
  PostCode: 2000,
  TownCity: 'Stockerau',
  Latitude: '4.838.611.000',
  Longitude: '1.621.863.000',
  Telephone: '+43-2266-62926',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.38611,16.21863',
  id: '48.38611,16.21863'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Leberstraße 112-114',
  AddressLine2: '',
  PostCode: 1110,
  TownCity: 'Wien',
  Latitude: '4.817.000.000',
  Longitude: '1.640.953.000',
  Telephone: '+43-1-7491753',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.17,16.40953',
  id: '48.17,16.40953'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Groß-Enzersdorfer Straße 24-26',
  AddressLine2: '',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '4.821.686.000',
  Longitude: '1.649.345.000',
  Telephone: '+43-1-2806469',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.21686,16.49345',
  id: '48.21686,16.49345'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Brünner Straße 31',
  AddressLine2: '',
  PostCode: 2201,
  TownCity: 'Hagenbrunn',
  Latitude: '4.833.608.000',
  Longitude: '1.646.097.000',
  Telephone: '+43-2246-2576',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.33608,16.46097',
  id: '48.33608,16.46097'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Breitenleer Straße West B302 108',
  AddressLine2: '',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '4.825.108.000',
  Longitude: '1.647.035.000',
  Telephone: '+43-1-7342707',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.25108,16.47035',
  id: '48.25108,16.47035'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Äuleweg 1',
  AddressLine2: '',
  PostCode: 6751,
  TownCity: 'Innerbraz',
  Latitude: '4.714.077.000',
  Longitude: '992.512.000',
  Telephone: '+43-5552-28012',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.14077,9.92512',
  id: '47.14077,9.92512'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Brunner Straße 77-79',
  AddressLine2: '',
  PostCode: 1230,
  TownCity: 'Wien',
  Latitude: '4.813.342.000',
  Longitude: '1.629.849.000',
  Telephone: '+43-1-8695856',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.13342,16.29849',
  id: '48.13342,16.29849'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Freistädter Straße 50',
  AddressLine2: '',
  PostCode: 4209,
  TownCity: 'Engerwitzdorf',
  Latitude: '4.834.180.000',
  Longitude: '1.441.327.000',
  Telephone: '+43-7235-67890',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.3418,14.41327',
  id: '48.3418,14.41327'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Straße 49-53',
  AddressLine2: '',
  PostCode: 3400,
  TownCity: 'Klosterneuburg',
  Latitude: '4.828.666.000',
  Longitude: '1.634.386.000',
  Telephone: '+43-2243-38882',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.28666,16.34386',
  id: '48.28666,16.34386'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Straße 6',
  AddressLine2: '',
  PostCode: 3580,
  TownCity: 'Frauenhofen',
  Latitude: '4.866.569.000',
  Longitude: '1.563.271.000',
  Telephone: '+43-2982-60162',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.66569,15.63271',
  id: '48.66569,15.63271'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Neufahrn 71',
  AddressLine2: '',
  PostCode: 5202,
  TownCity: 'Neumarkt am Wallersee',
  Latitude: '4.792.330.000',
  Longitude: '1.321.660.000',
  Telephone: '+43-6216-4748',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.9233,13.2166',
  id: '47.9233,13.2166'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Straßganger Straße 436',
  AddressLine2: '',
  PostCode: 8054,
  TownCity: 'Graz',
  Latitude: '4.703.326.000',
  Longitude: '1.539.896.000',
  Telephone: '+43-316-283868',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.03326,15.39896',
  id: '47.03326,15.39896'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Etrichstraße 42',
  AddressLine2: '',
  PostCode: 1110,
  TownCity: 'Wien',
  Latitude: '4.815.133.000',
  Longitude: '1.646.320.000',
  Telephone: '+43-1-7684044',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.15133,16.4632',
  id: '48.15133,16.4632'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Straße 91',
  AddressLine2: '',
  PostCode: 2193,
  TownCity: 'Hobersdorf',
  Latitude: '4.857.280.000',
  Longitude: '1.663.940.000',
  Telephone: '+43-2573-6010',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.5728,16.6394',
  id: '48.5728,16.6394'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Reschenstraße 299',
  AddressLine2: '',
  PostCode: 6531,
  TownCity: 'Ried im Oberinntal',
  Latitude: '4.705.741.000',
  Longitude: '1.065.375.000',
  Telephone: '+43-5472-2338',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.05741,10.65375',
  id: '47.05741,10.65375'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Alte Poststraße 234',
  AddressLine2: '',
  PostCode: 8020,
  TownCity: 'Graz',
  Latitude: '4.705.947.000',
  Longitude: '1.541.386.000',
  Telephone: '+43-316-583900',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.05947,15.41386',
  id: '47.05947,15.41386'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Oberstiftung 100',
  AddressLine2: '',
  PostCode: 4190,
  TownCity: 'Bad Leonfelden',
  Latitude: '4.851.185.000',
  Longitude: '1.429.526.000',
  Telephone: '+43-7213-6188',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.51185,14.29526',
  id: '48.51185,14.29526'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Haagerstraße 38A',
  AddressLine2: '',
  PostCode: 4400,
  TownCity: 'Steyr',
  Latitude: '4.805.521.000',
  Longitude: '1.444.928.000',
  Telephone: '+43-7252-73420',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.05521,14.44928',
  id: '48.05521,14.44928'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Straße 126',
  AddressLine2: '',
  PostCode: 2700,
  TownCity: 'Wiener Neustadt',
  Latitude: '4.784.322.000',
  Longitude: '1.624.631.000',
  Telephone: '+43-2622-89106',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.84322,16.24631',
  id: '47.84322,16.24631'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: '7. Haidequerstraße 2 KM 1,5',
  AddressLine2: '',
  PostCode: 1110,
  TownCity: 'Wien',
  Latitude: '4.818.187.000',
  Longitude: '1.644.451.000',
  Telephone: '+43-1-7685313',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.18187,16.44451',
  id: '48.18187,16.44451'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Gewerbestraße 550',
  AddressLine2: '',
  PostCode: 2534,
  TownCity: 'Alland',
  Latitude: '4.806.751.000',
  Longitude: '1.606.339.000',
  Telephone: '+43-2258-8912',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.06751,16.06339',
  id: '48.06751,16.06339'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Teichstraße 1',
  AddressLine2: '',
  PostCode: 3442,
  TownCity: 'Langenrohr/Asparn',
  Latitude: '4.832.242.000',
  Longitude: '1.602.642.000',
  Telephone: '+43-2272-81123',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.32242,16.02642',
  id: '48.32242,16.02642'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Michael Koch-Straße 65',
  AddressLine2: '',
  PostCode: 7210,
  TownCity: 'Mattersburg',
  Latitude: '4.774.067.000',
  Longitude: '1.640.867.000',
  Telephone: '+43-2626-62184',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.74067,16.40867',
  id: '47.74067,16.40867'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Straße 197',
  AddressLine2: '',
  PostCode: 2513,
  TownCity: 'Möllersdorf',
  Latitude: '4.803.909.000',
  Longitude: '1.630.361.000',
  Telephone: '+43-2252-62163',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.03909,16.30361',
  id: '48.03909,16.30361'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Tullner Straße 202',
  AddressLine2: '',
  PostCode: 3040,
  TownCity: 'Neulengbach',
  Latitude: '4.819.653.000',
  Longitude: '1.589.983.000',
  Telephone: '+43-2772-53852',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.19653,15.89983',
  id: '48.19653,15.89983'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Parz. Nr. 2164 17-18',
  AddressLine2: '',
  PostCode: 7100,
  TownCity: 'Weiden am See',
  Latitude: '4.793.750.000',
  Longitude: '1.685.532.000',
  Telephone: '+43-2167-2565',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.9375,16.85532',
  id: '47.9375,16.85532'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Stoob Süd 6',
  AddressLine2: '',
  PostCode: 7350,
  TownCity: 'Oberpullendorf',
  Latitude: '4.750.957.000',
  Longitude: '1.650.023.000',
  Telephone: '+43-2612-42982',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.50957,16.50023',
  id: '47.50957,16.50023'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Brunner Gasse 48',
  AddressLine2: '',
  PostCode: 2380,
  TownCity: 'Perchtoldsdorf',
  Latitude: '4.811.554.000',
  Longitude: '1.627.169.000',
  Telephone: '+43-1-8691300',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.11554,16.27169',
  id: '48.11554,16.27169'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Mariazeller Straße 95',
  AddressLine2: '',
  PostCode: 3100,
  TownCity: 'Sankt Pölten',
  Latitude: '4.818.769.000',
  Longitude: '1.561.753.000',
  Telephone: '+43-2742-76302',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.18769,15.61753',
  id: '48.18769,15.61753'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Dr.Adolf Schärf-Straße 1',
  AddressLine2: '',
  PostCode: 3100,
  TownCity: 'Sankt Pölten',
  Latitude: '4.822.376.000',
  Longitude: '1.563.452.000',
  Telephone: '+43-2742-348236',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.22376,15.63452',
  id: '48.22376,15.63452'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn A 22',
  AddressLine2: '',
  PostCode: 2000,
  TownCity: 'Stockerau',
  Latitude: '4.838.086.000',
  Longitude: '1.620.408.000',
  Telephone: '+43-2266-63406',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.38086,16.20408',
  id: '48.38086,16.20408'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hauptstraße 119',
  AddressLine2: '',
  PostCode: 2231,
  TownCity: 'Strasshof',
  Latitude: '4.831.458.000',
  Longitude: '1.662.560.000',
  Telephone: '+43-2287-2349',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.31458,16.6256',
  id: '48.31458,16.6256'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Straße 2a',
  AddressLine2: '',
  PostCode: 3340,
  TownCity: 'Waidhofen an der Ybbs',
  Latitude: '4.796.667.000',
  Longitude: '1.476.931.000',
  Telephone: '+43-7442-52706',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.96667,14.76931',
  id: '47.96667,14.76931'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'IZ NÖ Süd Straße 4 Objekt 9',
  AddressLine2: '',
  PostCode: 2351,
  TownCity: 'Wiener Neudorf',
  Latitude: '4.807.760.000',
  Longitude: '1.632.330.000',
  Telephone: '+43-2236-62464',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.0776,16.3233',
  id: '48.0776,16.3233'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Neudörfler Straße 70',
  AddressLine2: '',
  PostCode: 2700,
  TownCity: 'Wiener Neustadt',
  Latitude: '4.780.470.000',
  Longitude: '1.626.798.000',
  Telephone: '+43-2622-29498',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.8047,16.26798',
  id: '47.8047,16.26798'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bahnhofstraße 18',
  AddressLine2: '',
  PostCode: 3370,
  TownCity: 'Ybbs an der Donau',
  Latitude: '4.817.122.000',
  Longitude: '1.508.459.000',
  Telephone: '+43-7412-52568',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.17122,15.08459',
  id: '48.17122,15.08459'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Umfahrungsstraße 28a',
  AddressLine2: '',
  PostCode: 2225,
  TownCity: 'Zistersdorf',
  Latitude: '4.854.239.000',
  Longitude: '1.676.417.000',
  Telephone: '+43-2532-2240',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.54239,16.76417',
  id: '48.54239,16.76417'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'St.Johanner Straße 15a',
  AddressLine2: '',
  PostCode: 6370,
  TownCity: 'Kitzbühel',
  Latitude: '4.745.609.000',
  Longitude: '1.238.639.000',
  Telephone: '+43-5356-67059',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.45609,12.38639',
  id: '47.45609,12.38639'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Gablenzgasse 109',
  AddressLine2: '',
  PostCode: 1150,
  TownCity: 'Wien',
  Latitude: '4.820.685.000',
  Longitude: '1.631.374.000',
  Telephone: '+43-1-9821210',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.20685,16.31374',
  id: '48.20685,16.31374'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Fernpaß Bundesstraße 1',
  AddressLine2: '',
  PostCode: 6465,
  TownCity: 'Nassereith',
  Latitude: '4.732.720.000',
  Longitude: '1.081.980.000',
  Telephone: '+43-5265-5898',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.3272,10.8198',
  id: '47.3272,10.8198'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Langenloiser Straße 85',
  AddressLine2: '',
  PostCode: 3542,
  TownCity: 'Gföhl',
  Latitude: '4.851.208.000',
  Longitude: '1.551.044.000',
  Telephone: '+43-2716-6064',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.51208,15.51044',
  id: '48.51208,15.51044'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: '10. Oktober-Straße 23',
  AddressLine2: '',
  PostCode: 9560,
  TownCity: 'Feldkirchen',
  Latitude: '4.672.299.000',
  Longitude: '1.409.689.000',
  Telephone: '+43-4276-2230',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.72299,14.09689',
  id: '46.72299,14.09689'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Keutschacher Straße 135',
  AddressLine2: '',
  PostCode: 9073,
  TownCity: 'Viktring',
  Latitude: '4.659.189.000',
  Longitude: '1.427.158.000',
  Telephone: '+43-463-281222',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.59189,14.27158',
  id: '46.59189,14.27158'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Gonowetz 15',
  AddressLine2: '',
  PostCode: 9150,
  TownCity: 'Bleiburg',
  Latitude: '4.657.613.000',
  Longitude: '1.477.537.000',
  Telephone: '+43-4235-2007',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.57613,14.77537',
  id: '46.57613,14.77537'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: '10. Oktober-Straße 11',
  AddressLine2: '',
  PostCode: 9330,
  TownCity: 'Althofen',
  Latitude: '4.687.159.000',
  Longitude: '1.446.729.000',
  Telephone: '+43-4262-27207-3',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.87159,14.46729',
  id: '46.87159,14.46729'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Klagenfurter Straße 16',
  AddressLine2: '',
  PostCode: 9400,
  TownCity: 'Wolfsberg',
  Latitude: '4.682.775.000',
  Longitude: '1.483.911.000',
  Telephone: '+43-4352-2529',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.82775,14.83911',
  id: '46.82775,14.83911'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Grazer Straße 42',
  AddressLine2: '',
  PostCode: 8200,
  TownCity: 'Gleisdorf',
  Latitude: '4.710.570.000',
  Longitude: '1.570.097.000',
  Telephone: '+43-3112-2104',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.1057,15.70097',
  id: '47.1057,15.70097'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hauptstraße 39',
  AddressLine2: '',
  PostCode: 9871,
  TownCity: 'Seeboden',
  Latitude: '4.681.713.000',
  Longitude: '1.351.108.000',
  Telephone: '+43-4762-81324',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.81713,13.51108',
  id: '46.81713,13.51108'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Iseltaler Straße 30',
  AddressLine2: '',
  PostCode: 9900,
  TownCity: 'Lienz',
  Latitude: '4.683.172.000',
  Longitude: '1.275.363.000',
  Telephone: '+43-4852-63090',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.83172,12.75363',
  id: '46.83172,12.75363'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Tiroler Straße  10',
  AddressLine2: '',
  PostCode: 9900,
  TownCity: 'Lienz',
  Latitude: '4.683.095.000',
  Longitude: '1.277.553.000',
  Telephone: '+43-4852-63060',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.83095,12.77553',
  id: '46.83095,12.77553'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Schwefel 53',
  AddressLine2: '',
  PostCode: 6850,
  TownCity: 'Dornbirn',
  Latitude: '4.742.804.000',
  Longitude: '973.995.000',
  Telephone: '+43-5572-25763',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.42804,9.73995',
  id: '47.42804,9.73995'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wallenmahd 1',
  AddressLine2: '',
  PostCode: 6850,
  TownCity: 'Dornbirn',
  Latitude: '4.739.618.000',
  Longitude: '973.126.000',
  Telephone: '+43-5572-23532',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.39618,9.73126',
  id: '47.39618,9.73126'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Königshofstraße 61',
  AddressLine2: '',
  PostCode: 6804,
  TownCity: 'Feldkirch',
  Latitude: '4.726.779.000',
  Longitude: '961.507.000',
  Telephone: '+43-5522-73589',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.26779,9.61507',
  id: '47.26779,9.61507'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Feldkircher Straße 47',
  AddressLine2: '',
  PostCode: 6820,
  TownCity: 'Frastanz',
  Latitude: '4.722.452.000',
  Longitude: '961.744.000',
  Telephone: '+43-5522-51109',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.22452,9.61744',
  id: '47.22452,9.61744'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Danöfen 126',
  AddressLine2: '',
  PostCode: 6754,
  TownCity: 'Klösterle',
  Latitude: '4.712.939.000',
  Longitude: '1.006.077.000',
  Telephone: '+43-5582-592',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.12939,10.06077',
  id: '47.12939,10.06077'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hofriedenstraße 17',
  AddressLine2: '',
  PostCode: 6911,
  TownCity: 'Lochau',
  Latitude: '4.753.470.000',
  Longitude: '975.204.000',
  Telephone: '+43-5574-43674',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.5347,9.75204',
  id: '47.5347,9.75204'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Grindlstraße 14',
  AddressLine2: '',
  PostCode: 6890,
  TownCity: 'Lustenau',
  Latitude: '4.742.005.000',
  Longitude: '965.871.000',
  Telephone: '+43-5577-83390',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.42005,9.65871',
  id: '47.42005,9.65871'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hagstraße 27',
  AddressLine2: '',
  PostCode: 6890,
  TownCity: 'Lustenau',
  Latitude: '4.744.937.000',
  Longitude: '966.475.000',
  Telephone: '+43-5577-86782',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.44937,9.66475',
  id: '47.44937,9.66475'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bundesstraße 77',
  AddressLine2: '',
  PostCode: 6710,
  TownCity: 'Nenzing',
  Latitude: '4.718.918.000',
  Longitude: '970.154.000',
  Telephone: '+43-5525-62383',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.18918,9.70154',
  id: '47.18918,9.70154'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Walgaustraße 68',
  AddressLine2: '',
  PostCode: 6712,
  TownCity: 'Thüringen',
  Latitude: '4.719.930.000',
  Longitude: '975.582.000',
  Telephone: '+43-5550-3399',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.1993,9.75582',
  id: '47.1993,9.75582'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Dornbirner Straße 22',
  AddressLine2: '',
  PostCode: 6922,
  TownCity: 'Wolfurt',
  Latitude: '4.745.410.000',
  Longitude: '975.495.000',
  Telephone: '+43-5574-71123',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4541,9.75495',
  id: '47.4541,9.75495'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Salzburger Straße 55',
  AddressLine2: '',
  PostCode: 6300,
  TownCity: 'Wörgl',
  Latitude: '4.749.028.000',
  Longitude: '1.207.354.000',
  Telephone: '+43-5332-73609',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.49028,12.07354',
  id: '47.49028,12.07354'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'An der Umfahrungsstraße West',
  AddressLine2: '',
  PostCode: 6280,
  TownCity: 'Zell am Ziller',
  Latitude: '4.722.926.000',
  Longitude: '1.189.207.000',
  Telephone: '+43-5282-3115',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.22926,11.89207',
  id: '47.22926,11.89207'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Arlbergstraße 135',
  AddressLine2: '',
  PostCode: 6900,
  TownCity: 'Bregenz',
  Latitude: '4.748.778.000',
  Longitude: '973.324.000',
  Telephone: '+43-5574-71115',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.48778,9.73324',
  id: '47.48778,9.73324'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bahnhofstraße 133',
  AddressLine2: '',
  PostCode: 8250,
  TownCity: 'Vorau',
  Latitude: '4.740.488.000',
  Longitude: '1.588.963.000',
  Telephone: '+43-3337-3246',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.40488,15.88963',
  id: '47.40488,15.88963'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: '50',
  AddressLine2: '',
  PostCode: 8424,
  TownCity: 'Landscha an der Mur',
  Latitude: '4.676.379.000',
  Longitude: '1.558.034.000',
  Telephone: '+43-3452-8204112',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.76379,15.58034',
  id: '46.76379,15.58034'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Klagenfurter Straße 230',
  AddressLine2: '',
  PostCode: 9462,
  TownCity: 'Bad St. Leonhard im Lavanttal',
  Latitude: '4.696.214.000',
  Longitude: '1.479.496.000',
  Telephone: '+43-4350-2361',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.96214,14.79496',
  id: '46.96214,14.79496'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Gailtalstraße 12',
  AddressLine2: '',
  PostCode: 9620,
  TownCity: 'Hermagor',
  Latitude: '4.662.964.000',
  Longitude: '1.337.877.000',
  Telephone: '+43-4282-2142',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.62964,13.37877',
  id: '46.62964,13.37877'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Arnbach 75b',
  AddressLine2: '',
  PostCode: 9920,
  TownCity: 'Sillian',
  Latitude: '4.674.021.000',
  Longitude: '1.237.517.000',
  Telephone: '+43-4842-6343',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.74021,12.37517',
  id: '46.74021,12.37517'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wiener Straße 79',
  AddressLine2: '',
  PostCode: 8605,
  TownCity: 'Kapfenberg',
  Latitude: '4.744.692.000',
  Longitude: '1.530.208.000',
  Telephone: '+43-3862-22614',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.44692,15.30208',
  id: '47.44692,15.30208'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Krottendorf 3b',
  AddressLine2: '',
  PostCode: 8605,
  TownCity: 'Kapfenberg',
  Latitude: '4.745.024.000',
  Longitude: '1.533.985.000',
  Telephone: '+43-3862-33825',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.45024,15.33985',
  id: '47.45024,15.33985'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Schwöbing 70',
  AddressLine2: '',
  PostCode: 8665,
  TownCity: 'Langenwang',
  Latitude: '4.755.876.000',
  Longitude: '1.559.092.000',
  Telephone: '+43-3854-6125',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.55876,15.59092',
  id: '47.55876,15.59092'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Packer Straße 6',
  AddressLine2: '',
  PostCode: 8501,
  TownCity: 'Lieboch',
  Latitude: '4.697.230.000',
  Longitude: '1.536.164.000',
  Telephone: '+43-3136-61808',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.9723,15.36164',
  id: '46.9723,15.36164'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Keltensiedlung 95',
  AddressLine2: '',
  PostCode: 8850,
  TownCity: 'Murau',
  Latitude: '4.711.225.000',
  Longitude: '1.418.230.000',
  Telephone: '+43-3532-3687',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.11225,14.1823',
  id: '47.11225,14.1823'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Lahnsattel Bundesstraße',
  AddressLine2: '',
  PostCode: 8692,
  TownCity: 'Neuberg',
  Latitude: '4.765.921.000',
  Longitude: '1.558.941.000',
  Telephone: '+43-3857-8274',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.65921,15.58941',
  id: '47.65921,15.58941'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Kärntner Straße 22',
  AddressLine2: '',
  PostCode: 8820,
  TownCity: 'Neumarkt',
  Latitude: '4.706.752.000',
  Longitude: '1.443.023.000',
  Telephone: '+43-3584-2167',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.06752,14.43023',
  id: '47.06752,14.43023'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Grazer Straße 123',
  AddressLine2: '',
  PostCode: 7400,
  TownCity: 'Oberwart',
  Latitude: '4.728.297.000',
  Longitude: '1.618.523.000',
  Telephone: '+43-3352-31380',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.28297,16.18523',
  id: '47.28297,16.18523'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn Schwerpunktparkplatz Weer',
  AddressLine2: '',
  PostCode: 6114,
  TownCity: 'Weer',
  Latitude: '4.731.835.000',
  Longitude: '1.165.378.000',
  Telephone: '+43-5224-67477',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.31835,11.65378',
  id: '47.31835,11.65378'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Börsegasse 11',
  AddressLine2: '',
  PostCode: 1010,
  TownCity: 'Wien',
  Latitude: '4.821.523.000',
  Longitude: '1.636.718.000',
  Telephone: '+43-1-534990',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.21523,16.36718',
  id: '48.21523,16.36718'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Passauer Straße 23',
  AddressLine2: '',
  PostCode: 4143,
  TownCity: 'Neustift im Mühlkreis',
  Latitude: '4.852.955.000',
  Longitude: '1.374.859.000',
  Telephone: '+43-7284-23350',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.52955,13.74859',
  id: '48.52955,13.74859'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Rheinstraße 24',
  AddressLine2: '',
  PostCode: 6971,
  TownCity: 'Hard',
  Latitude: '4.748.752.000',
  Longitude: '969.962.000',
  Telephone: '+43-5574-61498',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.48752,9.69962',
  id: '47.48752,9.69962'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Lastenstraße 38',
  AddressLine2: '',
  PostCode: 9300,
  TownCity: 'Sankt Veit an der Glan',
  Latitude: '4.676.591.000',
  Longitude: '1.436.587.000',
  Telephone: '+43-4212-30845',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.76591,14.36587',
  id: '46.76591,14.36587'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Minnesheimstraße 10',
  AddressLine2: '',
  PostCode: 5023,
  TownCity: 'Salzburg',
  Latitude: '4.781.309.000',
  Longitude: '1.306.950.000',
  Telephone: '+43-662-645239',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.81309,13.0695',
  id: '47.81309,13.0695'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Altenmarkt 218',
  AddressLine2: '',
  PostCode: 8280,
  TownCity: 'Fürstenfeld',
  Latitude: '4.706.559.000',
  Longitude: '1.603.680.000',
  Telephone: '+43-3382-51019',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.06559,16.0368',
  id: '47.06559,16.0368'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hall 360',
  AddressLine2: '',
  PostCode: 8911,
  TownCity: 'Admont',
  Latitude: '4.758.208.000',
  Longitude: '1.445.614.000',
  Telephone: '+43-3613-2224',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.58208,14.45614',
  id: '47.58208,14.45614'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Triester Straße 38',
  AddressLine2: '',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '4.817.740.000',
  Longitude: '1.635.653.000',
  Telephone: '+43-1-6046279',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.1774,16.35653',
  id: '48.1774,16.35653'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Eisenfeldstraße 2',
  AddressLine2: '',
  PostCode: 4600,
  TownCity: 'Wels',
  Latitude: '4.816.575.000',
  Longitude: '1.403.910.000',
  Telephone: '+43-7242-77702',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.16575,14.0391',
  id: '48.16575,14.0391'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'St. Peter Hauptstraße 228',
  AddressLine2: '',
  PostCode: 8042,
  TownCity: 'Graz',
  Latitude: '4.704.384.000',
  Longitude: '1.548.696.000',
  Telephone: '+43-316-403829',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.04384,15.48696',
  id: '47.04384,15.48696'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hauptstraße 232',
  AddressLine2: '',
  PostCode: 9210,
  TownCity: 'Pörtschach',
  Latitude: '4.663.668.000',
  Longitude: '1.413.709.000',
  Telephone: '+43-4272-2587',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.63668,14.13709',
  id: '46.63668,14.13709'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bierbaumerstraße 3',
  AddressLine2: '',
  PostCode: 8401,
  TownCity: 'Kalsdorf bei Graz',
  Latitude: '4.697.221.000',
  Longitude: '1.547.013.000',
  Telephone: '+43-3135-53343',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.97221,15.47013',
  id: '46.97221,15.47013'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wachbergstraße 3',
  AddressLine2: '',
  PostCode: 3390,
  TownCity: 'Melk',
  Latitude: '4.821.918.000',
  Longitude: '1.534.834.000',
  Telephone: '+43-2752-52933',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.21918,15.34834',
  id: '48.21918,15.34834'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Schnann-Gewerbezone 150',
  AddressLine2: '',
  PostCode: 6574,
  TownCity: 'Pettneu am Arlberg',
  Latitude: '4.714.890.000',
  Longitude: '1.037.430.000',
  Telephone: '+43-5447-20111',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.1489,10.3743',
  id: '47.1489,10.3743'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Maria-Gailer-Straße 53',
  AddressLine2: '',
  PostCode: 9500,
  TownCity: 'Villach',
  Latitude: '4.660.434.000',
  Longitude: '1.387.092.000',
  Telephone: '+43-4242-311180',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.60434,13.87092',
  id: '46.60434,13.87092'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Gleinkerseestraße 42',
  AddressLine2: '',
  PostCode: 4580,
  TownCity: 'Windischgarsten',
  Latitude: '4.771.611.000',
  Longitude: '1.431.597.000',
  Telephone: '+43-7562-20756',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.71611,14.31597',
  id: '47.71611,14.31597'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Schlöglstraße 77',
  AddressLine2: '',
  PostCode: 6060,
  TownCity: 'Hall in Tirol',
  Latitude: '4.727.348.000',
  Longitude: '1.147.347.000',
  Telephone: '+43-5223-43573',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.27348,11.47347',
  id: '47.27348,11.47347'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Ötztaler Bundesstraße 15',
  AddressLine2: '',
  PostCode: 6430,
  TownCity: 'Ötztal-Bahnhof',
  Latitude: '4.722.949.000',
  Longitude: '1.085.657.000',
  Telephone: '+43-5266-88158',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.22949,10.85657',
  id: '47.22949,10.85657'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Andechsstraße 83',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.726.531.000',
  Longitude: '1.142.629.000',
  Telephone: '+43-512-346219',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.26531,11.42629',
  id: '47.26531,11.42629'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Hof 978',
  AddressLine2: '',
  PostCode: 6861,
  TownCity: 'Alberschwende',
  Latitude: '4.745.076.000',
  Longitude: '982.779.000',
  Telephone: '+43-5579-85796',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.45076,9.82779',
  id: '47.45076,9.82779'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Thalerhofstrasse 70',
  AddressLine2: '',
  PostCode: 8141,
  TownCity: 'Unterpremstätten',
  Latitude: '4.697.377.000',
  Longitude: '1.542.602.000',
  Telephone: '+43(0)3136/55474',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.97377,15.42602',
  id: '46.97377,15.42602'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Fachmarktstrasse/Gewerbepark',
  AddressLine2: '',
  PostCode: 4655,
  TownCity: 'Vorchdorf',
  Latitude: '4.801.068.000',
  Longitude: '1.392.471.000',
  Telephone: '+43(0)7614/51845',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.01068,13.92471',
  id: '48.01068,13.92471'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bahnhofstraße 11a',
  AddressLine2: '',
  PostCode: 7471,
  TownCity: 'Rechnitz',
  Latitude: '4.730.261.000',
  Longitude: '1.644.242.000',
  Telephone: '+43-3363-7936918',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.30261,16.44242',
  id: '47.30261,16.44242'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Halbenrainerstraße  34',
  AddressLine2: '',
  PostCode: 8490,
  TownCity: 'Bad Radkersburg',
  Latitude: '4.669.205.000',
  Longitude: '1.597.730.000',
  Telephone: '+43-3476-29842',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.69205,15.9773',
  id: '46.69205,15.9773'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Sterngasse 13',
  AddressLine2: '',
  PostCode: 1230,
  TownCity: 'Wien',
  Latitude: '4.814.891.000',
  Longitude: '1.633.836.000',
  Telephone: '+43-1-6672130',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.14891,16.33836',
  id: '48.14891,16.33836'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'S1 KM 9',
  AddressLine2: '',
  PostCode: 2320,
  TownCity: 'Schwechat',
  Latitude: '4.812.249.000',
  Longitude: '1.643.870.000',
  Telephone: '+43-1-7062651',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.12249,16.4387',
  id: '48.12249,16.4387'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Völkermarkter Straße 272',
  AddressLine2: '',
  PostCode: 9020,
  TownCity: 'Klagenfurt',
  Latitude: '4.662.956.000',
  Longitude: '1.435.681.000',
  Telephone: '+43-463-34302',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.62956,14.35681',
  id: '46.62956,14.35681'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Mühlfeldstraße  2',
  AddressLine2: '',
  PostCode: 3441,
  TownCity: 'Einsiedl',
  Latitude: '4.826.998.000',
  Longitude: '1.597.126.000',
  Telephone: '+43-2274-21017',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.26998,15.97126',
  id: '48.26998,15.97126'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Industriestrasse 6',
  AddressLine2: '',
  PostCode: 9601,
  TownCity: 'Arnoldstein - EuroTruck',
  Latitude: '4.655.944.000',
  Longitude: '1.369.722.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.55944,13.69722',
  id: '46.55944,13.69722'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Andiesen 13',
  AddressLine2: '',
  PostCode: 4774,
  TownCity: 'Suben - EuroTruck',
  Latitude: '4.839.518.000',
  Longitude: '1.342.651.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.39518,13.42651',
  id: '48.39518,13.42651'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Bayernstraße  24',
  AddressLine2: '',
  PostCode: 5071,
  TownCity: 'Wals Siezenheim - EuroTruck',
  Latitude: '4.781.733.000',
  Longitude: '1.298.016.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.81733,12.98016',
  id: '47.81733,12.98016'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Endach 34',
  AddressLine2: '',
  PostCode: 6330,
  TownCity: 'Kufstein - EuroTruck',
  Latitude: '4.756.574.000',
  Longitude: '1.215.013.000',
  Telephone: '+43-5372-90828',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.56574,12.15013',
  id: '47.56574,12.15013'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Rasthausstraße 3',
  AddressLine2: '',
  PostCode: 4300,
  TownCity: 'Sankt Valentin - EuroTruck',
  Latitude: '4.819.676.000',
  Longitude: '1.452.632.000',
  Telephone: '+43-7435-58885',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.19676,14.52632',
  id: '48.19676,14.52632'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Dorfstrasse 29',
  AddressLine2: '',
  PostCode: 6344,
  TownCity: 'Walchsee',
  Latitude: '476.503.760',
  Longitude: '123.133.020',
  Telephone: '+435374562031',
  WeekDayFrom: '07:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.650376,12.313302',
  id: '47.650376,12.313302'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Weidlingerstrasse 3a',
  AddressLine2: 'NOE',
  PostCode: 3400,
  TownCity: 'Klosterneuburg',
  Latitude: '482.966.000',
  Longitude: '163.339.000',
  Telephone: '+43224332074',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '48.2966,16.3339',
  id: '48.2966,16.3339'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Huette 15',
  AddressLine2: '',
  PostCode: 6345,
  TownCity: 'Koessen',
  Latitude: '476.618.101',
  Longitude: '124.138.757',
  Telephone: '+4353756394',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.6618101,12.4138757',
  id: '47.6618101,12.4138757'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Salzburger Str.11',
  AddressLine2: 'OOE',
  PostCode: 5280,
  TownCity: 'Braunau',
  Latitude: '482.548.623',
  Longitude: '130.363.873',
  Telephone: '800080645',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Yes',
  location: '48.2548623,13.0363873',
  id: '48.2548623,13.0363873'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Grazer Vorstadt 11',
  AddressLine2: '',
  PostCode: 8570,
  TownCity: 'Voitsberg',
  Latitude: '4.704.770.000',
  Longitude: '1.515.420.000',
  Telephone: '+43(0)3142/22287',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.0477,15.1542',
  id: '47.0477,15.1542'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstraße 130',
  AddressLine2: '',
  PostCode: 2102,
  TownCity: 'Bisamberg',
  Latitude: '4.833.315.000',
  Longitude: '1.634.447.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.33315,16.34447',
  id: '48.33315,16.34447'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'S 6 Semmering Schnellstr.',
  AddressLine2: '',
  PostCode: 2641,
  TownCity: 'Schottwien',
  Latitude: '4.765.287.000',
  Longitude: '1.587.146.000',
  Telephone: '+43(0)2663/8872',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.65287,15.87146',
  id: '47.65287,15.87146'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Wolfgangseestr. 9',
  AddressLine2: '',
  PostCode: 5322,
  TownCity: 'Hof b. Salzburg',
  Latitude: '478.169.976',
  Longitude: '132.281.001',
  Telephone: '+4362292234',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '18:00',
  IsOpen24Hours: 'No',
  location: '47.8169976,13.2281001',
  id: '47.8169976,13.2281001'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstr. 87',
  AddressLine2: '',
  PostCode: 2640,
  TownCity: 'Gloggnitz',
  Latitude: '4.767.302.400',
  Longitude: '1.595.594.210',
  Telephone: '+43(0)2662/42624',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.673024,15.9559421',
  id: '47.673024,15.9559421'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: '',
  AddressLine1: 'ST.MARGARETHEN 151B',
  AddressLine2: '',
  PostCode: 6220,
  TownCity: 'BUCH BEI JENBACH.',
  Latitude: '473.833.160',
  Longitude: '117.718.310',
  Telephone: '6641011348',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.383316,11.771831',
  id: '47.383316,11.771831'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Klagenfurter Strasse 42 A',
  AddressLine2: '',
  PostCode: 9100,
  TownCity: 'Vlkermarkt',
  Latitude: '4.666.048.000',
  Longitude: '1.461.967.000',
  Telephone: '+43(0)4232/2568',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.66048,14.61967',
  id: '46.66048,14.61967'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Dorfstrasse 1',
  AddressLine2: '',
  PostCode: 6142,
  TownCity: 'Mieders',
  Latitude: '4.717.122.000',
  Longitude: '1.138.392.000',
  Telephone: '+43(0)5225/62664',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.17122,11.38392',
  id: '47.17122,11.38392'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstr. 3',
  AddressLine2: '',
  PostCode: 2301,
  TownCity: 'Wittau',
  Latitude: '4.818.391.930',
  Longitude: '1.659.570.230',
  Telephone: '+43(0)2215/3130',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1839193,16.5957023',
  id: '48.1839193,16.5957023'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Franz Josef Strasse 20',
  AddressLine2: '',
  PostCode: 8200,
  TownCity: 'Gleisdorf',
  Latitude: '4.710.214.500',
  Longitude: '1.571.351.000',
  Telephone: '+43(0)311/23225',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.102145,15.71351',
  id: '47.102145,15.71351'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstr. 2',
  AddressLine2: '',
  PostCode: 2632,
  TownCity: 'Wimpassing',
  Latitude: '4.770.430.210',
  Longitude: '1.603.503.790',
  Telephone: '+43(0)2630/38778',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.7043021,16.0350379',
  id: '47.7043021,16.0350379'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Halleiner Strasse 819',
  AddressLine2: '',
  PostCode: 5412,
  TownCity: 'Puch-Hallein',
  Latitude: '4.772.200.000',
  Longitude: '1.309.018.000',
  Telephone: '+43(0)6245/83127',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.722,13.09018',
  id: '47.722,13.09018'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Grenzackerstrasse 6-8',
  AddressLine2: '',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '4.816.209.000',
  Longitude: '1.638.060.000',
  Telephone: '+43(0)1/6073149',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.16209,16.3806',
  id: '48.16209,16.3806'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstrasse 83',
  AddressLine2: '',
  PostCode: 9587,
  TownCity: 'Hart b. Villach',
  Latitude: '4.655.596.000',
  Longitude: '1.379.205.000',
  Telephone: '+43(0)4257/2779',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.55596,13.79205',
  id: '46.55596,13.79205'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bad Eisenkappel',
  AddressLine2: '',
  PostCode: 9135,
  TownCity: 'Bad Eisenkappel 196',
  Latitude: '4.648.980.000',
  Longitude: '1.458.980.000',
  Telephone: '+43(0)4238/305',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.4898,14.5898',
  id: '46.4898,14.5898'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: '',
  AddressLine1: 'HALLERSTRASSE 164',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'INNSBRUCK',
  Latitude: '472.754.128',
  Longitude: '114.340.339',
  Telephone: '6641011348',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.2754128,11.4340339',
  id: '47.2754128,11.4340339'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Althanstrasse 14',
  AddressLine2: '',
  PostCode: 1090,
  TownCity: 'Wien',
  Latitude: '4.822.910.000',
  Longitude: '1.635.890.000',
  Telephone: '+43(0)1/3192664',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.2291,16.3589',
  id: '48.2291,16.3589'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Südautobahn Ri Italien',
  AddressLine2: '',
  PostCode: 9601,
  TownCity: 'Arnoldstein',
  Latitude: '4.655.072.000',
  Longitude: '1.368.218.000',
  Telephone: '+43(0)4255/2875',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.55072,13.68218',
  id: '46.55072,13.68218'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Industriestraße 49',
  AddressLine2: '',
  PostCode: 7400,
  TownCity: 'Oberwart',
  Latitude: '4.730.549.000',
  Longitude: '1.619.296.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.30549,16.19296',
  id: '47.30549,16.19296'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Andiesen 15',
  AddressLine2: '',
  PostCode: 4774,
  TownCity: 'St.Marienkirchen/Schärding',
  Latitude: '4.839.454.000',
  Longitude: '1.342.673.000',
  Telephone: '+43(0)7711/33045',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.39454,13.42673',
  id: '48.39454,13.42673'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Ötscherlandstrasse 48',
  AddressLine2: '',
  PostCode: 3292,
  TownCity: 'Gaming',
  Latitude: '4.792.944.000',
  Longitude: '1.508.818.000',
  Telephone: '+43(0)7485/97398',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.92944,15.08818',
  id: '47.92944,15.08818'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Zillertaler Schnellstrasse 80',
  AddressLine2: '',
  PostCode: 6272,
  TownCity: 'Stumm/Zillertal',
  Latitude: '4.727.754.000',
  Longitude: '1.188.779.000',
  Telephone: '+43(0)5283/29242',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.27754,11.88779',
  id: '47.27754,11.88779'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Stubaitalstraße 10',
  AddressLine2: '',
  PostCode: 6142,
  TownCity: 'Mieders',
  Latitude: '4.717.688.000',
  Longitude: '1.139.159.000',
  Telephone: '+43(0)5225/62893',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.17688,11.39159',
  id: '47.17688,11.39159'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Treietstrasse 25',
  AddressLine2: '',
  PostCode: 6833,
  TownCity: 'Klaus',
  Latitude: '4.730.529.000',
  Longitude: '964.300.000',
  Telephone: '+43(0)5523/62420',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.30529,9.643',
  id: '47.30529,9.643'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Birkenweg 171',
  AddressLine2: '',
  PostCode: 6460,
  TownCity: 'Imst',
  Latitude: '4.723.389.000',
  Longitude: '1.073.470.000',
  Telephone: '+43(0)5412/66889',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.23389,10.7347',
  id: '47.23389,10.7347'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Millstätter Bundesstrasse 21',
  AddressLine2: '',
  PostCode: 9545,
  TownCity: 'Radenthein',
  Latitude: '4.679.807.000',
  Longitude: '1.371.041.000',
  Telephone: '+43(0)4246/2747',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.79807,13.71041',
  id: '46.79807,13.71041'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Fürstenf.Schnellstrasse 191',
  AddressLine2: '',
  PostCode: 8280,
  TownCity: 'Fürstenfeld/Altenm.',
  Latitude: '4.704.979.600',
  Longitude: '1.607.404.400',
  Telephone: '+43(0)3382/53601',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.049796,16.074044',
  id: '47.049796,16.074044'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Autobahnstation Soellheim',
  AddressLine2: '',
  PostCode: 5300,
  TownCity: 'Hallwang',
  Latitude: '4.783.850.000',
  Longitude: '1.307.592.000',
  Telephone: '+43(0)6626/61246',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.8385,13.07592',
  id: '47.8385,13.07592'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Leopoldauerstr. 180',
  AddressLine2: '',
  PostCode: 1210,
  TownCity: 'Wien',
  Latitude: '4.826.240.000',
  Longitude: '1.643.590.000',
  Telephone: '+43(0)1/2582569',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.2624,16.4359',
  id: '48.2624,16.4359'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Simon Scheiner Str. 6',
  AddressLine2: '',
  PostCode: 2136,
  TownCity: 'Laa an der Thaya',
  Latitude: '4.871.853.000',
  Longitude: '1.639.589.000',
  Telephone: '+43(0)2522/2406',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.71853,16.39589',
  id: '48.71853,16.39589'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Jägerstrasse 60',
  AddressLine2: '',
  PostCode: 1200,
  TownCity: 'Wien',
  Latitude: '4.823.488.000',
  Longitude: '1.637.020.000',
  Telephone: '+43(0)1/3322304',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.23488,16.3702',
  id: '48.23488,16.3702'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wagramer Straße  94 DZ Garage 3',
  AddressLine2: '',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '',
  Longitude: '',
  Telephone: '+43-1-2021131',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '0,0',
  id: '0,0'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: '',
  AddressLine1: 'FOEHRENWALD 498',
  AddressLine2: '',
  PostCode: 6100,
  TownCity: 'SEEFELD',
  Latitude: '473.279.656',
  Longitude: '111.986.662',
  Telephone: '6641011348',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.3279656,11.1986662',
  id: '47.3279656,11.1986662'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Maierhof 13',
  AddressLine2: '',
  PostCode: 8443,
  TownCity: 'Gleinstätten',
  Latitude: '4.676.176.000',
  Longitude: '1.540.083.000',
  Telephone: '+43(0)3457/2247',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.76176,15.40083',
  id: '46.76176,15.40083'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wildbachlerstrae 52',
  AddressLine2: '',
  PostCode: 6341,
  TownCity: 'Ebbs',
  Latitude: '4.763.349.000',
  Longitude: '1.221.413.000',
  Telephone: '+43(0)5373/42384',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.63349,12.21413',
  id: '47.63349,12.21413'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bruggen 352',
  AddressLine2: '',
  PostCode: 6444,
  TownCity: 'Längenfeld',
  Latitude: '4.702.488.000',
  Longitude: '1.099.511.000',
  Telephone: '+43(0)5253/64881',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.02488,10.99511',
  id: '47.02488,10.99511'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 48',
  AddressLine2: '',
  PostCode: 3002,
  TownCity: 'Purkersdorf',
  Latitude: '4.820.566.000',
  Longitude: '1.619.258.000',
  Telephone: '+43(0)2231/62135',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.20566,16.19258',
  id: '48.20566,16.19258'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Liebenauer Hauptstraße 176',
  AddressLine2: '',
  PostCode: 8041,
  TownCity: 'Graz Liebenau',
  Latitude: '4.703.310.000',
  Longitude: '1.546.406.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.0331,15.46406',
  id: '47.0331,15.46406'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstrasse 14',
  AddressLine2: '',
  PostCode: 6068,
  TownCity: 'Mils',
  Latitude: '4.728.125.000',
  Longitude: '1.153.412.000',
  Telephone: '+43(0)5223/55746',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.28125,11.53412',
  id: '47.28125,11.53412'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mühltal 20',
  AddressLine2: '',
  PostCode: 6305,
  TownCity: 'Itter',
  Latitude: '4.748.211.000',
  Longitude: '1.214.705.000',
  Telephone: '+43(0)5332/23398',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.48211,12.14705',
  id: '47.48211,12.14705'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Tiroler Strasse 141',
  AddressLine2: '',
  PostCode: 9500,
  TownCity: 'Villach',
  Latitude: '4.663.186.000',
  Longitude: '1.382.397.000',
  Telephone: '+43(0)4242/55901',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.63186,13.82397',
  id: '46.63186,13.82397'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Linzerstr. 12',
  AddressLine2: '',
  PostCode: 4840,
  TownCity: 'Vöcklabruck',
  Latitude: '4.800.338.000',
  Longitude: '1.365.882.000',
  Telephone: '+43(0)7672/72350',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.00338,13.65882',
  id: '48.00338,13.65882'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Lindauerstrasse 38',
  AddressLine2: '',
  PostCode: 6911,
  TownCity: 'Lochau',
  Latitude: '4.752.680.000',
  Longitude: '974.612.000',
  Telephone: '+43(0)5574/43174',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.5268,9.74612',
  id: '47.5268,9.74612'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Salzburgerstrasse 26',
  AddressLine2: '',
  PostCode: 6112,
  TownCity: 'Wattens',
  Latitude: '4.729.203.000',
  Longitude: '1.159.930.000',
  Telephone: '+43(0)5224/52577',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.29203,11.5993',
  id: '47.29203,11.5993'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Biberhaufenweg 115',
  AddressLine2: '',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '4.820.206.000',
  Longitude: '1.647.318.000',
  Telephone: '+43(0)1/2823210',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.20206,16.47318',
  id: '48.20206,16.47318'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Ötztaler Höhe',
  AddressLine2: '',
  PostCode: 6430,
  TownCity: 'Ötztaler Bahnhof',
  Latitude: '4.723.157.000',
  Longitude: '1.085.312.000',
  Telephone: '+43(0)5266/88361',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.23157,10.85312',
  id: '47.23157,10.85312'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mönchhofer Strasse 10',
  AddressLine2: '',
  PostCode: 7132,
  TownCity: 'Frauenkirchen',
  Latitude: '4.784.322.000',
  Longitude: '1.693.024.000',
  Telephone: '+43(0)2172/7100',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.84322,16.93024',
  id: '47.84322,16.93024'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Raststation Süd-Ostautobahn A3  KM',
  AddressLine2: '',
  PostCode: 7053,
  TownCity: 'Hornstein',
  Latitude: '4.787.799.000',
  Longitude: '1.641.503.000',
  Telephone: '+43-2689-208540',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.87799,16.41503',
  id: '47.87799,16.41503'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gasteiner Bundesstrasse 167',
  AddressLine2: '',
  PostCode: 5630,
  TownCity: 'Bad Hofgastein',
  Latitude: '4.717.579.000',
  Longitude: '1.310.021.000',
  Telephone: '+43(0)6432/6123',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.17579,13.10021',
  id: '47.17579,13.10021'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Raxstrasse 38',
  AddressLine2: '',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '4.816.659.000',
  Longitude: '1.635.804.000',
  Telephone: '+43(0)1/6041060',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.16659,16.35804',
  id: '48.16659,16.35804'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Tiroler Strasse 2',
  AddressLine2: '',
  PostCode: 6424,
  TownCity: 'Silz',
  Latitude: '4.726.906.000',
  Longitude: '1.094.032.000',
  Telephone: '+43(0)5263/6921',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.26906,10.94032',
  id: '47.26906,10.94032'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Dorf 23d',
  AddressLine2: '',
  PostCode: 6200,
  TownCity: 'Wiesing',
  Latitude: '4.740.400.000',
  Longitude: '1.180.423.000',
  Telephone: '+43(0)5244/61546',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.404,11.80423',
  id: '47.404,11.80423'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Grabenweg 73',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.726.587.000',
  Longitude: '1.144.258.000',
  Telephone: '+43(0)5022776027',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.26587,11.44258',
  id: '47.26587,11.44258'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 46',
  AddressLine2: '',
  PostCode: 2100,
  TownCity: 'Korneuburg',
  Latitude: '4.833.762.000',
  Longitude: '1.633.970.000',
  Telephone: '+43(0)2262/72275',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.33762,16.3397',
  id: '48.33762,16.3397'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Schwarzach 6',
  AddressLine2: '',
  PostCode: 6351,
  TownCity: 'Scheffau-Blaiken',
  Latitude: '4.752.040.000',
  Longitude: '1.225.374.000',
  Telephone: '+43(0)5358/8464',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.5204,12.25374',
  id: '47.5204,12.25374'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Langgasse 95',
  AddressLine2: '',
  PostCode: 6460,
  TownCity: 'Imst',
  Latitude: '4.722.655.000',
  Longitude: '1.074.782.000',
  Telephone: '+43(0)5412/62730',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.22655,10.74782',
  id: '47.22655,10.74782'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Salzburgerstrasse 41',
  AddressLine2: '',
  PostCode: 4690,
  TownCity: 'Schwanenstadt',
  Latitude: '4.804.835.000',
  Longitude: '1.376.219.000',
  Telephone: '+43(0)7673/30911',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.04835,13.76219',
  id: '48.04835,13.76219'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Italienerstr. 57',
  AddressLine2: '',
  PostCode: 9500,
  TownCity: 'Villach',
  Latitude: '4.660.480.000',
  Longitude: '1.384.090.000',
  Telephone: '+43(0)4242/37200',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.6048,13.8409',
  id: '46.6048,13.8409'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Tulpenweg 2',
  AddressLine2: '',
  PostCode: 9150,
  TownCity: 'Bleiburg',
  Latitude: '4.658.571.000',
  Longitude: '1.479.439.000',
  Telephone: '+43(0)4235/2056',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.58571,14.79439',
  id: '46.58571,14.79439'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Leopoldstrasse 66',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.725.592.000',
  Longitude: '1.139.805.000',
  Telephone: '+43(0)512/584177',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.25592,11.39805',
  id: '47.25592,11.39805'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Umfahrungsstrasse',
  AddressLine2: '',
  PostCode: 5280,
  TownCity: 'Braunau',
  Latitude: '4.825.469.100',
  Longitude: '1.304.517.400',
  Telephone: '+43(0)7722/63280',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.254691,13.045174',
  id: '48.254691,13.045174'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Münchner Str. 75',
  AddressLine2: '',
  PostCode: 6100,
  TownCity: 'Seefeld',
  Latitude: '4.733.453.000',
  Longitude: '1.119.184.000',
  Telephone: '+43(0)5212/24070',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.33453,11.19184',
  id: '47.33453,11.19184'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Maria Enzersdorfer Str. 5-7',
  AddressLine2: '',
  PostCode: 2345,
  TownCity: 'Brunn am Gebirge',
  Latitude: '4.810.440.000',
  Longitude: '1.628.530.000',
  Telephone: '+43(0)2236/31122',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1044,16.2853',
  id: '48.1044,16.2853'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Innsbruckerstrasse 52A',
  AddressLine2: '',
  PostCode: 6130,
  TownCity: 'Schwaz',
  Latitude: '4.733.870.000',
  Longitude: '1.170.570.000',
  Telephone: '+43(0)5242/62582',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.3387,11.7057',
  id: '47.3387,11.7057'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstr. 182',
  AddressLine2: '',
  PostCode: 3400,
  TownCity: 'Klosterneuburg',
  Latitude: '4.829.070.000',
  Longitude: '1.633.850.000',
  Telephone: '+43(0)2243/37491',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.2907,16.3385',
  id: '48.2907,16.3385'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: '',
  AddressLine1: 'Glotzing 20',
  AddressLine2: '',
  PostCode: 4134,
  TownCity: 'Putzleinsdorf',
  Latitude: '485.044.760',
  Longitude: '138.772.980',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.504476,13.877298',
  id: '48.504476,13.877298'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Rheinstrasse',
  AddressLine2: '',
  PostCode: 6971,
  TownCity: 'Bregenz-Hard',
  Latitude: '4.748.410.000',
  Longitude: '969.251.000',
  Telephone: '+43(0)5574/72424',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.4841,9.69251',
  id: '47.4841,9.69251'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Ressavarstr. 50',
  AddressLine2: '',
  PostCode: 8230,
  TownCity: 'Hartberg',
  Latitude: '4.728.253.610',
  Longitude: '1.597.548.750',
  Telephone: '+43(0)3332/62522',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.2825361,15.9754875',
  id: '47.2825361,15.9754875'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Nr. 46',
  AddressLine2: '',
  PostCode: 9615,
  TownCity: 'Förolach',
  Latitude: '4.662.860.000',
  Longitude: '1.347.207.000',
  Telephone: '+43(0)4283/2270',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.6286,13.47207',
  id: '46.6286,13.47207'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Stockach 29A',
  AddressLine2: '',
  PostCode: 6653,
  TownCity: 'Bach im Lechtal',
  Latitude: '4.725.996.000',
  Longitude: '1.037.553.000',
  Telephone: '+43(0)5634/6156',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.25996,10.37553',
  id: '47.25996,10.37553'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Katschb. Bundesstrasse',
  AddressLine2: '',
  PostCode: 5570,
  TownCity: 'Mauterndorf',
  Latitude: '4.712.950.000',
  Longitude: '1.368.400.000',
  Telephone: '+43(0)6472/7349',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.1295,13.684',
  id: '47.1295,13.684'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Weinözttlstrasse 2',
  AddressLine2: '',
  PostCode: 8045,
  TownCity: 'Graz-Andritz',
  Latitude: '4.709.836.000',
  Longitude: '1.541.819.000',
  Telephone: '+43(0)316/683425',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.09836,15.41819',
  id: '47.09836,15.41819'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 5',
  AddressLine2: '',
  PostCode: 3400,
  TownCity: 'Klosterneuburg',
  Latitude: '4.829.999.000',
  Longitude: '1.633.266.000',
  Telephone: '+43(0)2243/32533',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.29999,16.33266',
  id: '48.29999,16.33266'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Salzburger Strasse 42',
  AddressLine2: '',
  PostCode: 4840,
  TownCity: 'Vcklabruck',
  Latitude: '4.800.389.000',
  Longitude: '1.363.893.000',
  Telephone: '+43(0)7672/23278',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.00389,13.63893',
  id: '48.00389,13.63893'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'An der Bundesstraße 37',
  AddressLine2: '',
  PostCode: 3500,
  TownCity: 'Krems',
  Latitude: '4.842.304.804',
  Longitude: '1.562.184.329',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.42304804,15.62184329',
  id: '48.42304804,15.62184329'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Klagenfurter Strasse 42',
  AddressLine2: '',
  PostCode: 9170,
  TownCity: 'Ferlach',
  Latitude: '4.652.908.000',
  Longitude: '1.428.975.000',
  Telephone: '+43(0)4227/30945',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.52908,14.28975',
  id: '46.52908,14.28975'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Achstrasse 39',
  AddressLine2: '',
  PostCode: 6922,
  TownCity: 'Wolfurt',
  Latitude: '4.748.160.000',
  Longitude: '974.579.000',
  Telephone: '+43(0)5574/72154',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4816,9.74579',
  id: '47.4816,9.74579'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Villacherstr. 143',
  AddressLine2: '',
  PostCode: 9020,
  TownCity: 'Klagenfurt',
  Latitude: '4.661.990.000',
  Longitude: '1.428.250.000',
  Telephone: '+43(0)463/22281',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.6199,14.2825',
  id: '46.6199,14.2825'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Eisenstädterstr. 7',
  AddressLine2: '',
  PostCode: 8380,
  TownCity: 'Jennersdorf',
  Latitude: '4.693.705.000',
  Longitude: '1.614.296.000',
  Telephone: '+43(0)3329/45731',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.93705,16.14296',
  id: '46.93705,16.14296'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Spielbergstrasse 4',
  AddressLine2: '',
  PostCode: 6391,
  TownCity: 'Fieberbrunn',
  Latitude: '4.747.970.000',
  Longitude: '1.253.890.000',
  Telephone: '+43(0)5354/56310',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4797,12.5389',
  id: '47.4797,12.5389'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Felberst.Visavis 102',
  AddressLine2: '',
  PostCode: 1150,
  TownCity: 'Wien',
  Latitude: '4.819.370.000',
  Longitude: '1.632.140.000',
  Telephone: '+43(0)1/9824169',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1937,16.3214',
  id: '48.1937,16.3214'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Landstrasse 114',
  AddressLine2: '',
  PostCode: 2410,
  TownCity: 'Hainburg',
  Latitude: '4.814.037.000',
  Longitude: '1.692.246.000',
  Telephone: '+43(0)2165/62562',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.14037,16.92246',
  id: '48.14037,16.92246'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Oberau 27',
  AddressLine2: '',
  PostCode: 6311,
  TownCity: 'Wildschönau',
  Latitude: '4.744.457.000',
  Longitude: '1.203.784.000',
  Telephone: '+43(0)5339/8176',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.44457,12.03784',
  id: '47.44457,12.03784'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Altenmarkt bei St. Gallen Nr. 81',
  AddressLine2: '',
  PostCode: 8934,
  TownCity: 'Altenmarkt bei St. Gallen',
  Latitude: '4.772.275.000',
  Longitude: '1.464.959.000',
  Telephone: '+43(0)3632/534',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.72275,14.64959',
  id: '47.72275,14.64959'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gewerbegebiet Süd 2',
  AddressLine2: '',
  PostCode: 8243,
  TownCity: 'Pinggau',
  Latitude: '4.744.683.100',
  Longitude: '1.606.326.900',
  Telephone: '+43(0)3339/24491',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.446831,16.063269',
  id: '47.446831,16.063269'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrasse 53',
  AddressLine2: '',
  PostCode: 3314,
  TownCity: 'Strengberg',
  Latitude: '4.814.545.000',
  Longitude: '1.464.091.000',
  Telephone: '+43(0)7432/2242',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.14545,14.64091',
  id: '48.14545,14.64091'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Katschberg-Bundesstrasse 34',
  AddressLine2: '',
  PostCode: 5550,
  TownCity: 'Radstadt',
  Latitude: '4.738.671.000',
  Longitude: '1.345.209.000',
  Telephone: '+43(0)6452/4382',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.38671,13.45209',
  id: '47.38671,13.45209'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mureckerstrasse 31',
  AddressLine2: '',
  PostCode: 8083,
  TownCity: 'St.Stefan im Rosental',
  Latitude: '4.690.101.600',
  Longitude: '1.571.373.000',
  Telephone: '+43(0)311/681254',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.901016,15.71373',
  id: '46.901016,15.71373'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Tibitsch 84',
  AddressLine2: '',
  PostCode: 9212,
  TownCity: 'Techelsberg am Wörthersee',
  Latitude: '4.663.335.000',
  Longitude: '1.410.509.000',
  Telephone: '+43(0)4272/20406',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.63335,14.10509',
  id: '46.63335,14.10509'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bahnhofstr. 29',
  AddressLine2: '',
  PostCode: 3150,
  TownCity: 'Wilhelmsburg',
  Latitude: '4.811.235.100',
  Longitude: '1.560.793.630',
  Telephone: '+43(0)2746/6053',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.112351,15.6079363',
  id: '48.112351,15.6079363'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Semmeringstr. 4',
  AddressLine2: '',
  PostCode: 2620,
  TownCity: 'Neunkirchen',
  Latitude: '4.772.613.260',
  Longitude: '1.607.312.930',
  Telephone: '+43(0)2620/62811',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.7261326,16.0731293',
  id: '47.7261326,16.0731293'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn A13 (Brenner Autobahn) 256',
  AddressLine2: '',
  PostCode: 6156,
  TownCity: 'Gries am Brenner',
  Latitude: '4.700.760.000',
  Longitude: '1.150.913.000',
  Telephone: '+43-5274-87365',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.0076,11.50913',
  id: '47.0076,11.50913'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Schieferrollstrasse 17',
  AddressLine2: '',
  PostCode: 6250,
  TownCity: 'Kundl',
  Latitude: '474.673.830',
  Longitude: '119.897.317',
  Telephone: '435338861136',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.467383,11.9897317',
  id: '47.467383,11.9897317'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Umfahrungsstrasse 29',
  AddressLine2: '',
  PostCode: 9100,
  TownCity: 'Vlkermarkt',
  Latitude: '4.666.313.000',
  Longitude: '1.463.101.000',
  Telephone: '+43(0)4232/3171',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.66313,14.63101',
  id: '46.66313,14.63101'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mitterweg/ Ecke Exelgasse',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.725.858.300',
  Longitude: '1.137.208.600',
  Telephone: '+43(0)512/272062',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.258583,11.372086',
  id: '47.258583,11.372086'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Dallingerstrasse 11',
  AddressLine2: '',
  PostCode: 4030,
  TownCity: 'Linz',
  Latitude: '4.824.226.000',
  Longitude: '1.429.119.000',
  Telephone: '+43(0)732/370019',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.24226,14.29119',
  id: '48.24226,14.29119'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: '',
  AddressLine1: 'Neue Innbrückenstrasse 2',
  AddressLine2: '',
  PostCode: 5280,
  TownCity: 'Braunau',
  Latitude: '482.485.240',
  Longitude: '130.155.320',
  Telephone: '6609181397',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.248524,13.015532',
  id: '48.248524,13.015532'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Breitenfurter Strasse 325',
  AddressLine2: '',
  PostCode: 1230,
  TownCity: 'Wien',
  Latitude: '4.814.304.000',
  Longitude: '1.629.244.000',
  Telephone: '+43(0)1/8690303',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.14304,16.29244',
  id: '48.14304,16.29244'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Grazer Strasse 21',
  AddressLine2: '',
  PostCode: 8410,
  TownCity: 'Wildon',
  Latitude: '4.689.038.000',
  Longitude: '1.549.800.000',
  Telephone: '+43(0)3182/2351',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.89038,15.498',
  id: '46.89038,15.498'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mittersillerstr. 9',
  AddressLine2: '',
  PostCode: 5722,
  TownCity: 'Niedernsill',
  Latitude: '4.728.363.000',
  Longitude: '1.263.376.000',
  Telephone: '+43(0)654/88517',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.28363,12.63376',
  id: '47.28363,12.63376'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'An der Stadlhütte 1E',
  AddressLine2: '',
  PostCode: 3002,
  TownCity: 'Purkersdorf',
  Latitude: '4.818.495.000',
  Longitude: '1.612.428.000',
  Telephone: '+43(0)2231/64668',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.18495,16.12428',
  id: '48.18495,16.12428'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mattersburger Strasse 20',
  AddressLine2: '',
  PostCode: 7000,
  TownCity: 'Eisenstadt',
  Latitude: '4.783.627.000',
  Longitude: '1.652.424.000',
  Telephone: '+43(0)2682/63142',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.83627,16.52424',
  id: '47.83627,16.52424'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mattseer Landstrasse 4',
  AddressLine2: '',
  PostCode: 5161,
  TownCity: 'Elixhausen',
  Latitude: '4.786.832.000',
  Longitude: '1.306.688.000',
  Telephone: '+43(0)662/480250',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.86832,13.06688',
  id: '47.86832,13.06688'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Bundesstrasse 49',
  AddressLine2: '',
  PostCode: 5071,
  TownCity: 'Wals',
  Latitude: '477.857.414',
  Longitude: '129.755.200',
  Telephone: '',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.7857414,12.97552',
  id: '47.7857414,12.97552'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstrasse 197',
  AddressLine2: '',
  PostCode: 6580,
  TownCity: 'St. Anton',
  Latitude: '4.713.976.000',
  Longitude: '1.028.971.000',
  Telephone: '+43(0)5446/42622',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.13976,10.28971',
  id: '47.13976,10.28971'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstrasse 28',
  AddressLine2: '',
  PostCode: 8753,
  TownCity: 'Fohnsdorf',
  Latitude: '4.719.201.000',
  Longitude: '1.470.616.000',
  Telephone: '+43(0)3572/47340',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.19201,14.70616',
  id: '47.19201,14.70616'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Urstein Nord 15',
  AddressLine2: '',
  PostCode: 5412,
  TownCity: 'Puch-Hallein',
  Latitude: '4.773.315.351',
  Longitude: '1.308.560.603',
  Telephone: '+43(0)6245/21019',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.73315351,13.08560603',
  id: '47.73315351,13.08560603'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Obere Hauptstrasse 44',
  AddressLine2: '',
  PostCode: 2425,
  TownCity: 'Nickelsdorf/Bgl.',
  Latitude: '4.794.265.000',
  Longitude: '1.706.133.000',
  Telephone: '+43(0)2146/2246',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.94265,17.06133',
  id: '47.94265,17.06133'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Rautenweg 13',
  AddressLine2: '',
  PostCode: 1222,
  TownCity: 'Wien',
  Latitude: '4.826.272.000',
  Longitude: '1.646.121.000',
  Telephone: '+43(0)1/2597962',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.26272,16.46121',
  id: '48.26272,16.46121'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gersthoferstr.geg. 13',
  AddressLine2: '',
  PostCode: 1180,
  TownCity: 'Wien',
  Latitude: '4.822.740.000',
  Longitude: '1.632.660.000',
  Telephone: '+43(0)1/4793107',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.2274,16.3266',
  id: '48.2274,16.3266'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Salzachtal Bundesstrasse 105',
  AddressLine2: '',
  PostCode: 5081,
  TownCity: 'Anif',
  Latitude: '4.773.769.000',
  Longitude: '1.306.754.000',
  Telephone: '+43(0)6246/72498',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.73769,13.06754',
  id: '47.73769,13.06754'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstrasse 311',
  AddressLine2: '',
  PostCode: 5662,
  TownCity: 'Gries/Taxenbach',
  Latitude: '4.729.578.000',
  Longitude: '1.298.227.000',
  Telephone: '+43(0)6543/8113',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.29578,12.98227',
  id: '47.29578,12.98227'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Fürstenweg 87',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.715.440.000',
  Longitude: '1.122.200.000',
  Telephone: '+43(0)512/22777',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.1544,11.222',
  id: '47.1544,11.222'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Huttengasse',
  AddressLine2: '',
  PostCode: 1160,
  TownCity: 'Wien',
  Latitude: '4.820.745.000',
  Longitude: '1.630.919.000',
  Telephone: '+43(0)1/4931672',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.20745,16.30919',
  id: '48.20745,16.30919'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Rennweg 20',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.727.650.000',
  Longitude: '1.139.950.000',
  Telephone: '+43(0)512/585400',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.2765,11.3995',
  id: '47.2765,11.3995'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstrasse 10',
  AddressLine2: '',
  PostCode: 2460,
  TownCity: 'Bruck/Leitha',
  Latitude: '4.802.955.000',
  Longitude: '1.671.775.000',
  Telephone: '+43(0)2162/62457',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.02955,16.71775',
  id: '48.02955,16.71775'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Salzburger Strasse 23',
  AddressLine2: '',
  PostCode: 6380,
  TownCity: 'St. Johann',
  Latitude: '4.752.678.000',
  Longitude: '1.242.438.000',
  Telephone: '+43(0)5352/62656',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.52678,12.42438',
  id: '47.52678,12.42438'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Münchner Bundesstrasse 29',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg-Liefering',
  Latitude: '4.781.961.000',
  Longitude: '1.301.756.000',
  Telephone: '+43(0)662/432125',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.81961,13.01756',
  id: '47.81961,13.01756'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstraße 94',
  AddressLine2: '',
  PostCode: 2620,
  TownCity: 'Neunkirchen',
  Latitude: '4.772.502.000',
  Longitude: '1.607.160.000',
  Telephone: '+43(0)2635/62341',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.72502,16.0716',
  id: '47.72502,16.0716'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Kremser Ladnstr. 33',
  AddressLine2: '',
  PostCode: 3452,
  TownCity: 'Heiligeneich',
  Latitude: '4.830.459.000',
  Longitude: '1.589.132.000',
  Telephone: '+43(0)2275/5351',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.30459,15.89132',
  id: '48.30459,15.89132'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Am Bäckerberg 17',
  AddressLine2: '',
  PostCode: 4490,
  TownCity: 'St. Florian',
  Latitude: '4.821.312.000',
  Longitude: '1.439.935.000',
  Telephone: '+43(0)7224/80446',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.21312,14.39935',
  id: '48.21312,14.39935'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Neunkirchnerstrasse 90',
  AddressLine2: '',
  PostCode: 2700,
  TownCity: 'Wr.Neustadt',
  Latitude: '477.985.984',
  Longitude: '162.249.398',
  Telephone: '+432622235910',
  WeekDayFrom: '05:30',
  WeekDayTo: '19:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '13:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.7985984,16.2249398',
  id: '47.7985984,16.2249398'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Lindenweg 21',
  AddressLine2: '',
  PostCode: 9754,
  TownCity: 'Steinfeld',
  Latitude: '4.675.275.000',
  Longitude: '1.324.998.000',
  Telephone: '+43(0)664/816094',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.75275,13.24998',
  id: '46.75275,13.24998'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Reschen Bundesstrae',
  AddressLine2: '',
  PostCode: 6522,
  TownCity: 'Prutz',
  Latitude: '4.709.181.000',
  Longitude: '1.066.737.000',
  Telephone: '+43(0)5472/20395',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.09181,10.66737',
  id: '47.09181,10.66737'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Nr. 301',
  AddressLine2: '',
  PostCode: 9613,
  TownCity: 'Feistritz an der Gail',
  Latitude: '4.657.380.000',
  Longitude: '1.360.441.000',
  Telephone: '+43(0)4256/20224',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.5738,13.60441',
  id: '46.5738,13.60441'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: '',
  AddressLine1: '',
  AddressLine2: '',
  PostCode: null,
  TownCity: 'WIENER NEUSTADT, FISCHAUERGASS',
  Latitude: '',
  Longitude: '',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '0,0',
  id: '0,0'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Arlbergstrasse 54',
  AddressLine2: '',
  PostCode: 6900,
  TownCity: 'Bregenz-Rieden',
  Latitude: '4.749.596.000',
  Longitude: '973.402.000',
  Telephone: '+43(0)5574/44231',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.49596,9.73402',
  id: '47.49596,9.73402'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Pirching 97',
  AddressLine2: '',
  PostCode: 8200,
  TownCity: 'Hofstätten an der Raab',
  Latitude: '4.708.969.000',
  Longitude: '1.573.272.000',
  Telephone: '+43(0)3112/57414',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.08969,15.73272',
  id: '47.08969,15.73272'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Johannes Humer Straße',
  AddressLine2: '',
  PostCode: 4663,
  TownCity: 'Laakirchen',
  Latitude: '4.800.553.000',
  Longitude: '1.384.661.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.00553,13.84661',
  id: '48.00553,13.84661'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Fischauer Gasse 217',
  AddressLine2: '',
  PostCode: 2700,
  TownCity: 'Wr. Neustadt',
  Latitude: '4.782.324.000',
  Longitude: '1.621.538.000',
  Telephone: '+43(0)2622/26716',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.82324,16.21538',
  id: '47.82324,16.21538'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Moosstrasse 30',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.779.344.000',
  Longitude: '1.302.920.000',
  Telephone: '+43(0)662/824825',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.79344,13.0292',
  id: '47.79344,13.0292'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Langgasse 119',
  AddressLine2: '',
  PostCode: 6830,
  TownCity: 'Rankweil',
  Latitude: '4.727.182.000',
  Longitude: '961.785.000',
  Telephone: '+43(0)5522/46805',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.27182,9.61785',
  id: '47.27182,9.61785'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Alte Poststrasse 87',
  AddressLine2: '',
  PostCode: 8020,
  TownCity: 'Graz',
  Latitude: '4.707.687.000',
  Longitude: '1.540.696.000',
  Telephone: '+43(0)316/586692',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.07687,15.40696',
  id: '47.07687,15.40696'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Salzburgerstrasse  45',
  AddressLine2: '',
  PostCode: 5550,
  TownCity: 'Radstadt',
  Latitude: '4.738.650.000',
  Longitude: '1.345.060.000',
  Telephone: '+43(0)6452/5546',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.3865,13.4506',
  id: '47.3865,13.4506'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Betriebsstrasse 1',
  AddressLine2: '',
  PostCode: 4210,
  TownCity: 'Unterweitersdorf',
  Latitude: '4.835.790.000',
  Longitude: '1.447.660.000',
  Telephone: '+43(0)7235/63850',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.3579,14.4766',
  id: '48.3579,14.4766'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstr. 96',
  AddressLine2: '',
  PostCode: 6511,
  TownCity: 'Zams',
  Latitude: '4.715.700.000',
  Longitude: '1.058.890.000',
  Telephone: '+43(0)5442/67312',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.157,10.5889',
  id: '47.157,10.5889'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'TauernAutobahn, Torren 275',
  AddressLine2: '',
  PostCode: 5440,
  TownCity: 'Golling',
  Latitude: '4.758.360.000',
  Longitude: '1.315.650.000',
  Telephone: '+43(0)6244/6084',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.5836,13.1565',
  id: '47.5836,13.1565'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Lustenauerstrasse 52',
  AddressLine2: '',
  PostCode: 6850,
  TownCity: 'Dornbirn',
  Latitude: '4.741.040.000',
  Longitude: '972.079.000',
  Telephone: '+43(0)5572/25837',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4104,9.72079',
  id: '47.4104,9.72079'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Markt 56',
  AddressLine2: '',
  PostCode: 5441,
  TownCity: 'Abtenau',
  Latitude: '4.756.645.000',
  Longitude: '1.334.052.000',
  Telephone: '+43(0)6243/20121',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.56645,13.34052',
  id: '47.56645,13.34052'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bahnhofstrae 37A',
  AddressLine2: '',
  PostCode: 6170,
  TownCity: 'Zirl',
  Latitude: '4.726.753.000',
  Longitude: '1.123.440.000',
  Telephone: '+43(0)5238/52622',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.26753,11.2344',
  id: '47.26753,11.2344'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Neusiedler Strasse 15',
  AddressLine2: '',
  PostCode: 2340,
  TownCity: 'Moedling',
  Latitude: '4.808.167.000',
  Longitude: '1.628.419.000',
  Telephone: '+43(0)2236/47109',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.08167,16.28419',
  id: '48.08167,16.28419'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Kärntnerstr. 86',
  AddressLine2: '',
  PostCode: 8053,
  TownCity: 'Graz - Wetzelsdorf',
  Latitude: '4.705.484.000',
  Longitude: '1.541.667.000',
  Telephone: '+43(0)316/272453',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.05484,15.41667',
  id: '47.05484,15.41667'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstrasse 67',
  AddressLine2: '',
  PostCode: 8472,
  TownCity: 'Strass',
  Latitude: '4.672.960.000',
  Longitude: '1.561.580.000',
  Telephone: '+43(0)3453/6160',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.7296,15.6158',
  id: '46.7296,15.6158'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mauthen 189',
  AddressLine2: '',
  PostCode: 9640,
  TownCity: 'Kötschach',
  Latitude: '4.666.567.000',
  Longitude: '1.299.836.000',
  Telephone: '+43(0)4715/408',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.66567,12.99836',
  id: '46.66567,12.99836'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Innsbruckerstr. 195',
  AddressLine2: '',
  PostCode: 6108,
  TownCity: 'Scharnitz',
  Latitude: '4.738.696.000',
  Longitude: '1.125.912.000',
  Telephone: '+43(0)5213/20296',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.38696,11.25912',
  id: '47.38696,11.25912'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wechsel-Bundesstrasse 45',
  AddressLine2: '',
  PostCode: 2840,
  TownCity: 'Grimmenstein',
  Latitude: '4.761.994.000',
  Longitude: '1.612.818.000',
  Telephone: '+43(0)2644/6024',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.61994,16.12818',
  id: '47.61994,16.12818'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrasse 166',
  AddressLine2: '',
  PostCode: 2231,
  TownCity: 'Straßhof',
  Latitude: '4.831.611.000',
  Longitude: '1.663.636.000',
  Telephone: '+43(0)2287/2306',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.31611,16.63636',
  id: '48.31611,16.63636'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Braitnerstrasse 62A',
  AddressLine2: '',
  PostCode: 2500,
  TownCity: 'Baden',
  Latitude: '4.800.204.000',
  Longitude: '1.624.167.000',
  Telephone: '+43(0)2252/87949',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.00204,16.24167',
  id: '48.00204,16.24167'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Sterneckstrasse 16',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '478.081.108',
  Longitude: '130.530.881',
  Telephone: '+43662872118',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '14:00',
  SundayFrom: '08:00',
  SundayTo: '14:00',
  IsOpen24Hours: 'No',
  location: '47.8081108,13.0530881',
  id: '47.8081108,13.0530881'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gutenbergstrasse 6',
  AddressLine2: '',
  PostCode: 6858,
  TownCity: 'Schwarzach',
  Latitude: '4.743.971.000',
  Longitude: '975.557.000',
  Telephone: '+43(0)522/776858',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.43971,9.75557',
  id: '47.43971,9.75557'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bruck Hainburgerstr. 19-21',
  AddressLine2: '',
  PostCode: 2320,
  TownCity: 'Schwechat',
  Latitude: '4.814.030.000',
  Longitude: '1.648.370.000',
  Telephone: '+43(0)1/7076159',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1403,16.4837',
  id: '48.1403,16.4837'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'S 33- Schnellstrasse',
  AddressLine2: '',
  PostCode: 3100,
  TownCity: 'St. Pölten',
  Latitude: '4.822.339.000',
  Longitude: '1.567.863.000',
  Telephone: '+43(0)2742/23007',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.22339,15.67863',
  id: '48.22339,15.67863'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstrasse 12',
  AddressLine2: '',
  PostCode: 2345,
  TownCity: 'Brunn/Geb.',
  Latitude: '4.811.532.000',
  Longitude: '1.628.873.000',
  Telephone: '+43(0)2236/37887',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.11532,16.28873',
  id: '48.11532,16.28873'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Ostbahnstraße 10',
  AddressLine2: '',
  PostCode: 8041,
  TownCity: 'Graz',
  Latitude: '4.704.195.000',
  Longitude: '1.546.420.000',
  Telephone: '+43-316-4249800',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.04195,15.4642',
  id: '47.04195,15.4642'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Handelskai 388',
  AddressLine2: '',
  PostCode: 1020,
  TownCity: 'Wien',
  Latitude: '4.820.320.000',
  Longitude: '1.643.580.000',
  Telephone: '+43(0)1/7280259',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.2032,16.4358',
  id: '48.2032,16.4358'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Tiroler Strasse 9',
  AddressLine2: '',
  PostCode: 9800,
  TownCity: 'Spittal/Drau',
  Latitude: '4.680.076.000',
  Longitude: '1.349.156.000',
  Telephone: '+43(0)4762/2986',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.80076,13.49156',
  id: '46.80076,13.49156'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wilfersdorf 104',
  AddressLine2: '',
  PostCode: 8200,
  TownCity: 'Gleisdorf',
  Latitude: '4.710.687.000',
  Longitude: '1.566.413.000',
  Telephone: '+43(0)3112/32700',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.10687,15.66413',
  id: '47.10687,15.66413'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 136',
  AddressLine2: '',
  PostCode: 2020,
  TownCity: 'Hollabrunn',
  Latitude: '4.854.932.000',
  Longitude: '1.607.915.000',
  Telephone: '+43(0)2952/4581',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.54932,16.07915',
  id: '48.54932,16.07915'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Königstetterstrasse 154',
  AddressLine2: '',
  PostCode: 3430,
  TownCity: 'Tulln - Nitzing',
  Latitude: '4.831.874.000',
  Longitude: '1.608.506.000',
  Telephone: '+43(0)2272/82685',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.31874,16.08506',
  id: '48.31874,16.08506'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'S 36 Murtal-Schnellstr. Sd',
  AddressLine2: '',
  PostCode: 8733,
  TownCity: 'St.Marein/Knittelf.',
  Latitude: '4.725.337.000',
  Longitude: '1.487.890.000',
  Telephone: '+43(0)3515/4843',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.25337,14.8789',
  id: '47.25337,14.8789'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Steinbrückl 14',
  AddressLine2: '',
  PostCode: 7531,
  TownCity: 'Kemeten',
  Latitude: '4.727.489.000',
  Longitude: '1.615.556.000',
  Telephone: '+43(0)3352/20168',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.27489,16.15556',
  id: '47.27489,16.15556'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Terminalstr. 99',
  AddressLine2: '',
  PostCode: 4600,
  TownCity: 'Wels - Pernau',
  Latitude: '4.818.990.000',
  Longitude: '1.407.330.000',
  Telephone: '+43(0)7242/78719',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.1899,14.0733',
  id: '48.1899,14.0733'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Niederland 112',
  AddressLine2: '',
  PostCode: 5091,
  TownCity: 'Unken',
  Latitude: '476.651.940',
  Longitude: '127.528.820',
  Telephone: '',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.665194,12.752882',
  id: '47.665194,12.752882'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bahnhofstrae 11',
  AddressLine2: '',
  PostCode: 6372,
  TownCity: 'Oberndorf',
  Latitude: '4.749.750.000',
  Longitude: '1.238.590.000',
  Telephone: '+43(0)5352/61515',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.4975,12.3859',
  id: '47.4975,12.3859'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstr. 45a',
  AddressLine2: '',
  PostCode: 3340,
  TownCity: 'Waidhofen/Ybbs',
  Latitude: '4.797.034.740',
  Longitude: '1.476.344.580',
  Telephone: '+43(0)7442/52562',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.9703474,14.7634458',
  id: '47.9703474,14.7634458'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gewerbepark Süd 1',
  AddressLine2: '',
  PostCode: 8504,
  TownCity: 'Preding',
  Latitude: '4.685.877.000',
  Longitude: '1.540.688.000',
  Telephone: '+43(0)3185/2311',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.85877,15.40688',
  id: '46.85877,15.40688'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Langgasse 96',
  AddressLine2: '',
  PostCode: 6460,
  TownCity: 'Imst',
  Latitude: '4.722.747.000',
  Longitude: '1.074.632.000',
  Telephone: '+43(0)5412/65151',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.22747,10.74632',
  id: '47.22747,10.74632'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 52',
  AddressLine2: '',
  PostCode: 7423,
  TownCity: 'Pinkafeld',
  Latitude: '4.737.913.000',
  Longitude: '1.612.189.000',
  Telephone: '+43(0)3357/42581',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.37913,16.12189',
  id: '47.37913,16.12189'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Sellraintalerstrasse',
  AddressLine2: '',
  PostCode: 6175,
  TownCity: 'Kematen',
  Latitude: '4.726.168.000',
  Longitude: '1.126.511.000',
  Telephone: '+43(0)5232/3322',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.26168,11.26511',
  id: '47.26168,11.26511'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wr. Neustädter Strasse 28',
  AddressLine2: '',
  PostCode: 2514,
  TownCity: 'Traiskirchen',
  Latitude: '4.800.817.000',
  Longitude: '1.626.397.000',
  Telephone: '+43(0)2252/52454',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.00817,16.26397',
  id: '48.00817,16.26397'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Wagramer Straße  79 DP Garage 4',
  AddressLine2: '',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '',
  Longitude: '',
  Telephone: '+43-1-2039669',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '0,0',
  id: '0,0'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Alpenstrasse 60',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.778.372.000',
  Longitude: '1.306.697.000',
  Telephone: '+43(0)662/624903',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.78372,13.06697',
  id: '47.78372,13.06697'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Raststation Steinhäusl A1',
  AddressLine2: '',
  PostCode: 3033,
  TownCity: 'Steinhäusl / Altlengbach A1',
  Latitude: '4.815.763.000',
  Longitude: '1.594.264.000',
  Telephone: '+43-2774-28846',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.15763,15.94264',
  id: '48.15763,15.94264'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Bundesstrasse 2',
  AddressLine2: '',
  PostCode: 5600,
  TownCity: 'St. Johann',
  Latitude: '473.344.020',
  Longitude: '131.893.444',
  Telephone: '',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.334402,13.1893444',
  id: '47.334402,13.1893444'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Oiden 110, Bundesstr.1',
  AddressLine2: '',
  PostCode: 3300,
  TownCity: 'Amstetten',
  Latitude: '4.813.310.000',
  Longitude: '1.484.160.000',
  Telephone: '+43(0)7472/25197',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1331,14.8416',
  id: '48.1331,14.8416'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Andechsstrasse 69',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.726.672.000',
  Longitude: '1.142.360.000',
  Telephone: '+43(0)512/344262',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.26672,11.4236',
  id: '47.26672,11.4236'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'St. Veiter-Strasse 168',
  AddressLine2: '',
  PostCode: 9020,
  TownCity: 'Klagenfurt',
  Latitude: '4.666.661.000',
  Longitude: '1.431.578.000',
  Telephone: '+43(0)463/41709',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.66661,14.31578',
  id: '46.66661,14.31578'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Lehen 20',
  AddressLine2: '',
  PostCode: 8970,
  TownCity: 'Schladming',
  Latitude: '4.739.670.000',
  Longitude: '1.370.390.000',
  Telephone: '+43(0)3687/22201',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.3967,13.7039',
  id: '47.3967,13.7039'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Reintal 21',
  AddressLine2: '',
  PostCode: 9841,
  TownCity: 'Winklern',
  Latitude: '4.686.212.000',
  Longitude: '1.289.067.000',
  Telephone: '+43(0)4822/247',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.86212,12.89067',
  id: '46.86212,12.89067'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 304',
  AddressLine2: '',
  PostCode: 8051,
  TownCity: 'Graz',
  Latitude: '4.709.837.000',
  Longitude: '1.540.378.000',
  Telephone: '+43(0)316/683664',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.09837,15.40378',
  id: '47.09837,15.40378'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Felbertauernstr.',
  AddressLine2: '',
  PostCode: 9971,
  TownCity: 'Matrei in Osttirol',
  Latitude: '4.700.519.000',
  Longitude: '1.254.105.000',
  Telephone: '+43(0)5022/77997',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.00519,12.54105',
  id: '47.00519,12.54105'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Schattwald 29',
  AddressLine2: '',
  PostCode: 6677,
  TownCity: 'Schattwald',
  Latitude: '4.751.210.000',
  Longitude: '1.046.280.000',
  Telephone: '+43(0)5675/20379',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.5121,10.4628',
  id: '47.5121,10.4628'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Tullner Strasse 4',
  AddressLine2: '',
  PostCode: 3051,
  TownCity: 'St.Christophen',
  Latitude: '4.816.641.340',
  Longitude: '1.588.509.380',
  Telephone: '+43(0)2772/52573',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1664134,15.8850938',
  id: '48.1664134,15.8850938'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Kremstalstrasse 117',
  AddressLine2: '',
  PostCode: 4053,
  TownCity: 'Ritzlhof',
  Latitude: '4.818.722.000',
  Longitude: '1.424.927.000',
  Telephone: '+43(0)7229/88351',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.18722,14.24927',
  id: '48.18722,14.24927'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Kaiserebersdorferstrasse 48',
  AddressLine2: '',
  PostCode: 1110,
  TownCity: 'Wien',
  Latitude: '4.816.746.000',
  Longitude: '1.642.691.000',
  Telephone: '+43(0)1/7676695',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.16746,16.42691',
  id: '48.16746,16.42691'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Ketzergasse 111',
  AddressLine2: '',
  PostCode: 1230,
  TownCity: 'Wien',
  Latitude: '4.812.772.410',
  Longitude: '1.630.555.310',
  Telephone: '+43(0)1/86924840',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1277241,16.3055531',
  id: '48.1277241,16.3055531'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bahnhofstr. 21',
  AddressLine2: '',
  PostCode: 6780,
  TownCity: 'Schruns',
  Latitude: '4.708.010.000',
  Longitude: '991.687.000',
  Telephone: '+43(0)5556/72470',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.0801,9.91687',
  id: '47.0801,9.91687'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Forstbergstrasse',
  AddressLine2: '',
  PostCode: 4470,
  TownCity: 'Enns',
  Latitude: '4.820.671.000',
  Longitude: '1.447.358.000',
  Telephone: '+43(0)7223/82872',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.20671,14.47358',
  id: '48.20671,14.47358'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Ennstal-Bundestrasse 213',
  AddressLine2: '',
  PostCode: 8970,
  TownCity: 'Schladming',
  Latitude: '4.739.032.000',
  Longitude: '1.365.833.000',
  Telephone: '+43(0)3687/24659',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.39032,13.65833',
  id: '47.39032,13.65833'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Loferer Strasse 28',
  AddressLine2: '',
  PostCode: 6322,
  TownCity: 'Kirchbichl',
  Latitude: '4.749.450.000',
  Longitude: '1.209.157.000',
  Telephone: '+43(0)5332/73772',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4945,12.09157',
  id: '47.4945,12.09157'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Salurner Str. 28',
  AddressLine2: '',
  PostCode: 6330,
  TownCity: 'Kufstein',
  Latitude: '4.757.640.000',
  Longitude: '1.216.040.000',
  Telephone: '+43(0)5372/62665',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.5764,12.1604',
  id: '47.5764,12.1604'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Buchau Eden 5',
  AddressLine2: '',
  PostCode: 6212,
  TownCity: 'Maurach',
  Latitude: '4.743.310.000',
  Longitude: '1.173.550.000',
  Telephone: '+43(0)5243/6113',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4331,11.7355',
  id: '47.4331,11.7355'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Sandtal 28',
  AddressLine2: '',
  PostCode: 5144,
  TownCity: 'Handenberg',
  Latitude: '4.814.160.000',
  Longitude: '1.301.510.000',
  Telephone: '+43(0)7748/32129',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1416,13.0151',
  id: '48.1416,13.0151'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Nonntaler Hauptstraße 53a',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.778.703.000',
  Longitude: '1.305.175.000',
  Telephone: '+43-662-820939',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.78703,13.05175',
  id: '47.78703,13.05175'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wr. Neustädter Strasse 99',
  AddressLine2: '',
  PostCode: 2601,
  TownCity: 'Sollenau',
  Latitude: '4.789.730.000',
  Longitude: '1.624.994.000',
  Telephone: '+43(0)2628/62420',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.8973,16.24994',
  id: '47.8973,16.24994'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstr. 3',
  AddressLine2: '',
  PostCode: 3493,
  TownCity: 'Hadersdorf a. Kamp',
  Latitude: '4.845.960.000',
  Longitude: '1.572.520.000',
  Telephone: '+43(0)2735/2410',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.4596,15.7252',
  id: '48.4596,15.7252'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Nr. 68',
  AddressLine2: '',
  PostCode: 8262,
  TownCity: 'Nestelbach Im Ilztal',
  Latitude: '4.709.185.487',
  Longitude: '1.587.899.702',
  Telephone: '+43(0)3385/421',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.09185487,15.87899702',
  id: '47.09185487,15.87899702'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrasse 77',
  AddressLine2: '',
  PostCode: 3170,
  TownCity: 'Hainfeld',
  Latitude: '4.803.824.000',
  Longitude: '1.576.290.000',
  Telephone: '+43(0)2764/2504',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.03824,15.7629',
  id: '48.03824,15.7629'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Feldkirchnerstrasse 1',
  AddressLine2: '',
  PostCode: 9556,
  TownCity: 'Liebenfels',
  Latitude: '4.673.750.000',
  Longitude: '1.428.580.000',
  Telephone: '+43(0)4215/2221',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.7375,14.2858',
  id: '46.7375,14.2858'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Atterseestrasse',
  AddressLine2: '',
  PostCode: 4863,
  TownCity: 'Seewalchen',
  Latitude: '4.795.200.000',
  Longitude: '1.358.630.000',
  Telephone: '+43(0)7662/2463',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.952,13.5863',
  id: '47.952,13.5863'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Umfahrungsstr.',
  AddressLine2: '',
  PostCode: 4802,
  TownCity: 'Ebensee',
  Latitude: '4.780.630.000',
  Longitude: '1.378.060.000',
  Telephone: '+43(0)6133/7565',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.8063,13.7806',
  id: '47.8063,13.7806'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Salzachtalstrasse 23',
  AddressLine2: '',
  PostCode: 5400,
  TownCity: 'Hallein',
  Latitude: '4.767.970.000',
  Longitude: '1.310.450.000',
  Telephone: '+43(0)6245/83029',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.6797,13.1045',
  id: '47.6797,13.1045'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstraße 38a',
  AddressLine2: '',
  PostCode: 8684,
  TownCity: 'Spittal am Semmering',
  Latitude: '4.761.322.000',
  Longitude: '1.574.718.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.61322,15.74718',
  id: '47.61322,15.74718'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstr. 18',
  AddressLine2: '',
  PostCode: 6923,
  TownCity: 'Lauterach',
  Latitude: '4.748.220.000',
  Longitude: '973.162.000',
  Telephone: '+43(0)5574/71829',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4822,9.73162',
  id: '47.4822,9.73162'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Brennerstr. 119',
  AddressLine2: '',
  PostCode: 6156,
  TownCity: 'Gries am Brenner',
  Latitude: '4.701.436.000',
  Longitude: '1.150.802.000',
  Telephone: '+43(0)5274/86210',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.01436,11.50802',
  id: '47.01436,11.50802'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bersbuch 401',
  AddressLine2: '',
  PostCode: 6866,
  TownCity: 'Andelsbuch',
  Latitude: '4.739.740.000',
  Longitude: '985.358.000',
  Telephone: '+43(0)5512/3214',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.3974,9.85358',
  id: '47.3974,9.85358'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrasse 11',
  AddressLine2: '',
  PostCode: 2763,
  TownCity: 'Pernitz',
  Latitude: '4.789.638.000',
  Longitude: '1.596.825.000',
  Telephone: '+43(0)2632/72369',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.89638,15.96825',
  id: '47.89638,15.96825'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Innsbrucker Strae 36',
  AddressLine2: '',
  PostCode: 6300,
  TownCity: 'Wörgl',
  Latitude: '4.748.262.000',
  Longitude: '1.205.798.000',
  Telephone: '+43(0)5332/73910',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.48262,12.05798',
  id: '47.48262,12.05798'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrae 91',
  AddressLine2: '',
  PostCode: 6433,
  TownCity: 'Tumpen',
  Latitude: '4.717.610.000',
  Longitude: '1.091.020.000',
  Telephone: '+43(0)5255/50148',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.1761,10.9102',
  id: '47.1761,10.9102'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Breitenfurterstr. 215',
  AddressLine2: '',
  PostCode: 1230,
  TownCity: 'Wien',
  Latitude: '4.815.680.000',
  Longitude: '1.630.500.000',
  Telephone: '+43(0)1/8042146',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1568,16.305',
  id: '48.1568,16.305'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Ebreichsdorfer Str. 17b',
  AddressLine2: '',
  PostCode: 2522,
  TownCity: 'Oberwaltersdorf',
  Latitude: '4.797.335.900',
  Longitude: '1.632.789.900',
  Telephone: '+43(0)2253/9201',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.973359,16.327899',
  id: '47.973359,16.327899'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Grieskirchnerstr. 39',
  AddressLine2: '',
  PostCode: 4600,
  TownCity: 'Wels',
  Latitude: '4.817.173.000',
  Longitude: '1.402.365.000',
  Telephone: '+43(0)7242/44205',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.17173,14.02365',
  id: '48.17173,14.02365'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hohenemser Strasse 1',
  AddressLine2: '',
  PostCode: 6890,
  TownCity: 'Lustenau',
  Latitude: '4.741.895.000',
  Longitude: '966.087.000',
  Telephone: '+43(0)5577/83209',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.41895,9.66087',
  id: '47.41895,9.66087'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Südring 260',
  AddressLine2: '',
  PostCode: 9020,
  TownCity: 'Klagenfurt',
  Latitude: '4.660.699.000',
  Longitude: '1.430.492.000',
  Telephone: '+43(0)463/310273',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.60699,14.30492',
  id: '46.60699,14.30492'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Leitenstrasse 30',
  AddressLine2: '',
  PostCode: 4812,
  TownCity: 'Pinsdorf',
  Latitude: '4.793.485.000',
  Longitude: '1.377.700.000',
  Telephone: '+43(0)7612/64429',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.93485,13.777',
  id: '47.93485,13.777'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Schwefel 70a',
  AddressLine2: '',
  PostCode: 6850,
  TownCity: 'Dornbirn',
  Latitude: '4.742.950.000',
  Longitude: '973.976.000',
  Telephone: '+43(0)5572/24326',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4295,9.73976',
  id: '47.4295,9.73976'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 125',
  AddressLine2: '',
  PostCode: 2345,
  TownCity: 'Brunn/Geb.',
  Latitude: '4.811.221.000',
  Longitude: '1.629.956.000',
  Telephone: '+43(0)2236/31790',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.11221,16.29956',
  id: '48.11221,16.29956'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 31',
  AddressLine2: '',
  PostCode: 2120,
  TownCity: 'Wolkersdorf',
  Latitude: '4.837.860.000',
  Longitude: '1.651.037.000',
  Telephone: '+43(0)2245/2424',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.3786,16.51037',
  id: '48.3786,16.51037'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bundesstrasse 142',
  AddressLine2: '',
  PostCode: 5230,
  TownCity: 'Mattighofen',
  Latitude: '4.810.236.000',
  Longitude: '1.315.068.000',
  Telephone: '+43(0)7742/2388',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.10236,13.15068',
  id: '48.10236,13.15068'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wolfgangseestraße 2',
  AddressLine2: '',
  PostCode: 5350,
  TownCity: 'Strobl',
  Latitude: '4.771.695.000',
  Longitude: '1.348.334.000',
  Telephone: '+43(0)6137/75752',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.71695,13.48334',
  id: '47.71695,13.48334'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Amraserseestr. 29',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.726.190.000',
  Longitude: '1.142.309.000',
  Telephone: '+43(0)512/343114',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.2619,11.42309',
  id: '47.2619,11.42309'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Steinleiten 40',
  AddressLine2: '',
  PostCode: 4890,
  TownCity: 'Frankenmarkt',
  Latitude: '4.798.556.000',
  Longitude: '1.343.298.000',
  Telephone: '+43(0)7684/6309',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.98556,13.43298',
  id: '47.98556,13.43298'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Ennser Strasse 152a',
  AddressLine2: '',
  PostCode: 4407,
  TownCity: 'Dietach',
  Latitude: '4.810.747.000',
  Longitude: '1.444.654.000',
  Telephone: '+43(0)7252/39009',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.10747,14.44654',
  id: '48.10747,14.44654'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstr. 35',
  AddressLine2: '',
  PostCode: 2640,
  TownCity: 'Gloggnitz',
  Latitude: '4.767.347.960',
  Longitude: '1.594.598.490',
  Telephone: '+43(0)2262/42524',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.6734796,15.9459849',
  id: '47.6734796,15.9459849'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Hauptsrasse 102',
  AddressLine2: '',
  PostCode: 7062,
  TownCity: 'St.Margarethen in Burgenland',
  Latitude: '478.034.233',
  Longitude: '166.139.933',
  Telephone: '+4326802232',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.8034233,16.6139933',
  id: '47.8034233,16.6139933'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Marktplatz 24',
  AddressLine2: '',
  PostCode: 8081,
  TownCity: 'Heiligenkreuz',
  Latitude: '4.695.660.000',
  Longitude: '1.558.490.000',
  Telephone: '+43(0)3134/2201',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.9566,15.5849',
  id: '46.9566,15.5849'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstrasse 38',
  AddressLine2: '',
  PostCode: 2401,
  TownCity: 'Fischamend',
  Latitude: '4.812.098.000',
  Longitude: '1.660.183.000',
  Telephone: '+43(0)2232/76251',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.12098,16.60183',
  id: '48.12098,16.60183'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gewerbepark Nord Nr. 11',
  AddressLine2: '',
  PostCode: 8430,
  TownCity: 'Gralla',
  Latitude: '4.681.861.000',
  Longitude: '1.555.269.000',
  Telephone: '+43(0)3452/76701',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.81861,15.55269',
  id: '46.81861,15.55269'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Donaustadtstrasse 43',
  AddressLine2: '',
  PostCode: 1220,
  TownCity: 'Wien',
  Latitude: '4.822.231.000',
  Longitude: '1.644.413.000',
  Telephone: '+43(0)1/2024043',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.22231,16.44413',
  id: '48.22231,16.44413'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Eisenstädter Strasse 19',
  AddressLine2: '',
  PostCode: 2483,
  TownCity: 'Weigelsdorf',
  Latitude: '4.794.775.000',
  Longitude: '1.640.919.000',
  Telephone: '+43(0)2254/72366',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.94775,16.40919',
  id: '47.94775,16.40919'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Wachauerstrasse 4',
  AddressLine2: '',
  PostCode: 3660,
  TownCity: 'Klein Poechlarn',
  Latitude: '482.173.600',
  Longitude: '152.160.691',
  Telephone: '+4374138008',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '48.21736,15.2160691',
  id: '48.21736,15.2160691'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Maria Theresienstr. 14',
  AddressLine2: '',
  PostCode: 6890,
  TownCity: 'Lustenau',
  Latitude: '4.742.820.000',
  Longitude: '965.945.000',
  Telephone: '+43(0)5577/83066',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4282,9.65945',
  id: '47.4282,9.65945'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'S 6 Semmering-Schnellstrasse',
  AddressLine2: '',
  PostCode: 8641,
  TownCity: 'St.Marein/Mrz',
  Latitude: '4.744.806.000',
  Longitude: '1.532.654.000',
  Telephone: '+43(0)3864/3728',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.44806,15.32654',
  id: '47.44806,15.32654'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Zeller Bundesstrasse 1a',
  AddressLine2: '',
  PostCode: 5760,
  TownCity: 'Saalfelden',
  Latitude: '4.742.299.000',
  Longitude: '1.283.617.000',
  Telephone: '+43(0)6582/73258',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.42299,12.83617',
  id: '47.42299,12.83617'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Märzenkeller 3',
  AddressLine2: '',
  PostCode: 8850,
  TownCity: 'Murau',
  Latitude: '4.711.325.000',
  Longitude: '1.416.195.000',
  Telephone: '+43(0)3532/2193',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.11325,14.16195',
  id: '47.11325,14.16195'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Untere Donaulände 15',
  AddressLine2: '',
  PostCode: 4020,
  TownCity: 'Linz',
  Latitude: '4.831.378.000',
  Longitude: '1.429.957.000',
  Telephone: '+43(0)732/773051',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.31378,14.29957',
  id: '48.31378,14.29957'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gerlos Bundesstr. 169',
  AddressLine2: '',
  PostCode: 6272,
  TownCity: 'Kaltenbach',
  Latitude: '4.729.360.000',
  Longitude: '1.187.525.000',
  Telephone: '+43(0)5283/2292',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.2936,11.87525',
  id: '47.2936,11.87525'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Alfred Fröhlich Strasse 1',
  AddressLine2: '',
  PostCode: 2201,
  TownCity: 'Gerasdorf',
  Latitude: '4.833.873.000',
  Longitude: '1.646.407.000',
  Telephone: '+43(0)2246/20646',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.33873,16.46407',
  id: '48.33873,16.46407'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Oberzellerg. 2a',
  AddressLine2: '',
  PostCode: 1030,
  TownCity: 'Wien',
  Latitude: '4.819.360.000',
  Longitude: '1.639.680.000',
  Telephone: '+43(0)1/7136175',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1936,16.3968',
  id: '48.1936,16.3968'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'S1, Ost KM 38,5',
  AddressLine2: '',
  PostCode: 2232,
  TownCity: 'Deutsch-Wagram',
  Latitude: '4.831.075.000',
  Longitude: '1.651.506.000',
  Telephone: '+43-2247-51947',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.31075,16.51506',
  id: '48.31075,16.51506'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Klagenfurterstrasse 40',
  AddressLine2: '',
  PostCode: 9400,
  TownCity: 'Wolfsberg',
  Latitude: '4.682.236.000',
  Longitude: '1.483.825.000',
  Telephone: '+43(0)4352/90946',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.82236,14.83825',
  id: '46.82236,14.83825'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Windegg 4',
  AddressLine2: '',
  PostCode: 4221,
  TownCity: 'Steyregg',
  Latitude: '4.828.590.000',
  Longitude: '1.435.160.000',
  Telephone: '+43(0)732/641175',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.2859,14.3516',
  id: '48.2859,14.3516'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Grazerstr. 44',
  AddressLine2: '',
  PostCode: 8680,
  TownCity: 'Mürzzuschlag',
  Latitude: '4.759.917.550',
  Longitude: '1.566.610.720',
  Telephone: '+43(0)3852/2548',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.5991755,15.6661072',
  id: '47.5991755,15.6661072'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Floragasse 7A',
  AddressLine2: '',
  PostCode: 1040,
  TownCity: 'Wien',
  Latitude: '4.819.456.000',
  Longitude: '1.636.808.000',
  Telephone: '+43(1)5051517',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.19456,16.36808',
  id: '48.19456,16.36808'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Innsbruckerstrasse - Kreisverkehr 1',
  AddressLine2: '',
  PostCode: 6300,
  TownCity: 'Wörgl',
  Latitude: '4.748.932.000',
  Longitude: '1.206.277.000',
  Telephone: '+43(0)5332/70696',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.48932,12.06277',
  id: '47.48932,12.06277'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Ossiacher Zeile 74',
  AddressLine2: '',
  PostCode: 9500,
  TownCity: 'Villach',
  Latitude: '4.661.752.000',
  Longitude: '1.386.021.000',
  Telephone: '+43(0)4242/41978',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.61752,13.86021',
  id: '46.61752,13.86021'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Omesberg 9',
  AddressLine2: '',
  PostCode: 6764,
  TownCity: 'Lech am Arlberg',
  Latitude: '4.720.491.000',
  Longitude: '1.014.054.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.20491,10.14054',
  id: '47.20491,10.14054'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gabelsbergerstr. 1',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.780.990.000',
  Longitude: '1.304.690.000',
  Telephone: '+43(0)662/874218',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.8099,13.0469',
  id: '47.8099,13.0469'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Pachern Hauptstrasse 47',
  AddressLine2: '',
  PostCode: 8075,
  TownCity: 'Pachern',
  Latitude: '4.705.305.000',
  Longitude: '1.552.888.000',
  Telephone: '+43(0)316/491915',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.05305,15.52888',
  id: '47.05305,15.52888'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Rattendorf 7',
  AddressLine2: '',
  PostCode: 9631,
  TownCity: 'Jenig',
  Latitude: '4.662.830.000',
  Longitude: '1.325.220.000',
  Telephone: '+43(0)4285/247',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.6283,13.2522',
  id: '46.6283,13.2522'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrasse 297',
  AddressLine2: '',
  PostCode: 8311,
  TownCity: 'Markt Hartmannsdorf',
  Latitude: '4.705.735.600',
  Longitude: '1.583.250.400',
  Telephone: '+43(0)311/45107',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.057356,15.832504',
  id: '47.057356,15.832504'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrasse 21',
  AddressLine2: '',
  PostCode: 3161,
  TownCity: 'St. Veit a. d. Gölsen',
  Latitude: '4.804.289.000',
  Longitude: '1.567.186.000',
  Telephone: '+43(0)2763/3198',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.04289,15.67186',
  id: '48.04289,15.67186'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mörtschach 72',
  AddressLine2: '',
  PostCode: 9842,
  TownCity: 'Mörtschach',
  Latitude: '4.692.286.000',
  Longitude: '1.291.034.000',
  Telephone: '+43(0)4826/365',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.92286,12.91034',
  id: '46.92286,12.91034'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 65',
  AddressLine2: '',
  PostCode: 3100,
  TownCity: 'St. Pölten',
  Latitude: '4.820.356.000',
  Longitude: '1.563.767.000',
  Telephone: '+43(0)2742/25863',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.20356,15.63767',
  id: '48.20356,15.63767'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Lagerhaus Rohrbach',
  AddressLine1: 'Schwarzenberg 168',
  AddressLine2: '',
  PostCode: 4164,
  TownCity: 'Schwarzenberg',
  Latitude: '487.362.668',
  Longitude: '138.195.991',
  Telephone: '',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Yes',
  location: '48.7362668,13.8195991',
  id: '48.7362668,13.8195991'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mankerstr. 8',
  AddressLine2: '',
  PostCode: 3233,
  TownCity: 'Kilb',
  Latitude: '4.810.080.000',
  Longitude: '1.540.520.000',
  Telephone: '+43(0)2748/7350',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1008,15.4052',
  id: '48.1008,15.4052'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Shuttleworthstraße 10',
  AddressLine2: '',
  PostCode: 1210,
  TownCity: 'Wien',
  Latitude: '4.827.050.000',
  Longitude: '1.641.214.000',
  Telephone: '+43-1-2903740',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.2705,16.41214',
  id: '48.2705,16.41214'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Leopoldstrae 67',
  AddressLine2: '',
  PostCode: 6020,
  TownCity: 'Innsbruck',
  Latitude: '4.725.394.000',
  Longitude: '1.139.918.000',
  Telephone: '+43(0)512/585958',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.25394,11.39918',
  id: '47.25394,11.39918'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Drautalstrasse 14',
  AddressLine2: '',
  PostCode: 9710,
  TownCity: 'Neu Feffernitz',
  Latitude: '4.668.925.000',
  Longitude: '1.368.395.000',
  Telephone: '+43(0)4245/40001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.68925,13.68395',
  id: '46.68925,13.68395'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wr. Neustädterstraße 18',
  AddressLine2: '',
  PostCode: 2733,
  TownCity: 'Grünbach/Schneeberg',
  Latitude: '4.779.664.090',
  Longitude: '1.599.371.410',
  Telephone: '+43(0)2637/2758',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.7966409,15.9937141',
  id: '47.7966409,15.9937141'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Straße 2a',
  AddressLine2: '',
  PostCode: 7540,
  TownCity: 'Güssing',
  Latitude: '4.706.475.000',
  Longitude: '1.632.512.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.06475,16.32512',
  id: '47.06475,16.32512'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Innsbrucker Straße 68',
  AddressLine2: '',
  PostCode: 6382,
  TownCity: 'Kirchdorf in Tirol',
  Latitude: '4.753.830.000',
  Longitude: '1.244.099.000',
  Telephone: '+43-5352-61088',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.5383,12.44099',
  id: '47.5383,12.44099'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Innsbruckerstrasse 90',
  AddressLine2: '',
  PostCode: 6060,
  TownCity: 'Hall',
  Latitude: '4.727.700.000',
  Longitude: '1.147.380.000',
  Telephone: '+43(0)5223/57777',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.277,11.4738',
  id: '47.277,11.4738'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gewerbepark Süd 12',
  AddressLine2: '',
  PostCode: 6330,
  TownCity: 'Kufstein',
  Latitude: '4.756.626.000',
  Longitude: '1.215.626.000',
  Telephone: '+43(0)5372/61622',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.56626,12.15626',
  id: '47.56626,12.15626'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 87',
  AddressLine2: '',
  PostCode: 3580,
  TownCity: 'Horn',
  Latitude: '4.866.258.000',
  Longitude: '1.567.015.000',
  Telephone: '+43(0)2982/2369',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.66258,15.67015',
  id: '48.66258,15.67015'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Frauentaler Strasse 122',
  AddressLine2: '',
  PostCode: 8530,
  TownCity: 'Deutschlandsberg',
  Latitude: '4.682.068.000',
  Longitude: '1.523.672.000',
  Telephone: '+43(0)3462/30061',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.82068,15.23672',
  id: '46.82068,15.23672'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Huben 40',
  AddressLine2: '',
  PostCode: 9971,
  TownCity: 'Matrei in Osttirol',
  Latitude: '4.693.460.000',
  Longitude: '1.257.500.000',
  Telephone: '+43(0)4872/5253',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.9346,12.575',
  id: '46.9346,12.575'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Niederland 218',
  AddressLine2: '',
  PostCode: 5091,
  TownCity: 'Unken',
  Latitude: '4.765.123.000',
  Longitude: '1.273.452.000',
  Telephone: '+43(0)6589/7291',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.65123,12.73452',
  id: '47.65123,12.73452'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Lanserstrasse 2A',
  AddressLine2: '',
  PostCode: 6071,
  TownCity: 'Aldrans',
  Latitude: '4.724.823.000',
  Longitude: '1.144.406.000',
  Telephone: '+43(0)512/341234',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.24823,11.44406',
  id: '47.24823,11.44406'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Villacherstr. 44',
  AddressLine2: '',
  PostCode: 9800,
  TownCity: 'Spittal a.d. Drau',
  Latitude: '4.679.190.000',
  Longitude: '1.350.830.000',
  Telephone: '+43(0)4762/2717',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.7919,13.5083',
  id: '46.7919,13.5083'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Doppler',
  AddressLine1: 'Hausmeninger Str. 74',
  AddressLine2: '',
  PostCode: 3362,
  TownCity: 'Mauer bei Amstetten',
  Latitude: '480.825.413',
  Longitude: '148.017.456',
  Telephone: '+43747552619',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.0825413,14.8017456',
  id: '48.0825413,14.8017456'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wachauer Bundesstrasse',
  AddressLine2: '',
  PostCode: 3620,
  TownCity: 'Spitz',
  Latitude: '4.836.250.000',
  Longitude: '1.541.680.000',
  Telephone: '+43(0)2713/2111',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.3625,15.4168',
  id: '48.3625,15.4168'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bahnhofstrasse 37',
  AddressLine2: '',
  PostCode: 3370,
  TownCity: 'Ybbs an der Donau',
  Latitude: '4.816.579.000',
  Longitude: '1.509.091.000',
  Telephone: '+43(0)7412/52431',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.16579,15.09091',
  id: '48.16579,15.09091'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Triesterstr. 285-287',
  AddressLine2: '',
  PostCode: 1230,
  TownCity: 'Wien',
  Latitude: '4.814.170.000',
  Longitude: '1.632.960.000',
  Telephone: '+43(0)1/6673109',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.1417,16.3296',
  id: '48.1417,16.3296'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Linzer Strasse 15',
  AddressLine2: '',
  PostCode: 4072,
  TownCity: 'Alkoven',
  Latitude: '4.828.863.000',
  Longitude: '1.411.763.000',
  Telephone: '+43(0)7274/6360',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.28863,14.11763',
  id: '48.28863,14.11763'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Südautobahn Ri Österreich',
  AddressLine2: '',
  PostCode: 9601,
  TownCity: 'Arnoldstein',
  Latitude: '4.655.109.000',
  Longitude: '1.368.326.000',
  Telephone: '+43(0)4255/2885',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.55109,13.68326',
  id: '46.55109,13.68326'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Obertrumer Landstrasse 11',
  AddressLine2: '',
  PostCode: 5201,
  TownCity: 'Seekirchen',
  Latitude: '479.036.543',
  Longitude: '131.205.386',
  Telephone: '+43624672648',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '47.9036543,13.1205386',
  id: '47.9036543,13.1205386'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Villacher Strasse 21',
  AddressLine2: '',
  PostCode: 9220,
  TownCity: 'Velden',
  Latitude: '4.661.364.000',
  Longitude: '1.403.563.000',
  Telephone: '+43(0)4274/2482',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.61364,14.03563',
  id: '46.61364,14.03563'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Obergäu 310',
  AddressLine2: '',
  PostCode: 5440,
  TownCity: 'Golling',
  Latitude: '4.759.194.000',
  Longitude: '1.317.540.000',
  Telephone: '+43(0)6244/21150',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.59194,13.1754',
  id: '47.59194,13.1754'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstraße 132',
  AddressLine2: '',
  PostCode: 8605,
  TownCity: 'Kapfenberg',
  Latitude: '4.745.479.000',
  Longitude: '1.531.747.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.45479,15.31747',
  id: '47.45479,15.31747'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Zellerstr. 38',
  AddressLine2: '',
  PostCode: 5730,
  TownCity: 'Mittersill',
  Latitude: '4.728.520.000',
  Longitude: '1.249.450.000',
  Telephone: '+43(0)6562/4406',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.2852,12.4945',
  id: '47.2852,12.4945'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mitschastrasse 38',
  AddressLine2: '',
  PostCode: 2130,
  TownCity: 'Mistelbach',
  Latitude: '4.856.456.000',
  Longitude: '1.657.595.000',
  Telephone: '+43(0)2572/2389',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.56456,16.57595',
  id: '48.56456,16.57595'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Seeringstraße  2',
  AddressLine2: '',
  PostCode: 4880,
  TownCity: 'St. Georgen im Attergau',
  Latitude: '4.792.917.000',
  Longitude: '1.350.204.000',
  Telephone: '+43-7667-80068',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.92917,13.50204',
  id: '47.92917,13.50204'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstr. 28-30',
  AddressLine2: '',
  PostCode: 7442,
  TownCity: 'Lockenhaus',
  Latitude: '4.741.090.000',
  Longitude: '1.641.470.000',
  Telephone: '+43(0)2616/2253',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4109,16.4147',
  id: '47.4109,16.4147'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Rosentalerstr. 196',
  AddressLine2: '',
  PostCode: 9020,
  TownCity: 'Klagenfurt',
  Latitude: '4.660.350.000',
  Longitude: '1.429.695.000',
  Telephone: '+43(0)463/23330',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.6035,14.29695',
  id: '46.6035,14.29695'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Linzer Straße 24',
  AddressLine2: '',
  PostCode: 4070,
  TownCity: 'Eferding',
  Latitude: '4.830.176.000',
  Longitude: '1.402.967.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.30176,14.02967',
  id: '48.30176,14.02967'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Murtalerstrasse 579',
  AddressLine2: '',
  PostCode: 5582,
  TownCity: 'St. Michael',
  Latitude: '470.954.278',
  Longitude: '136.385.084',
  Telephone: '',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '21:00',
  SundayFrom: '06:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'No',
  location: '47.0954278,13.6385084',
  id: '47.0954278,13.6385084'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Krottenbachstr. 32-34',
  AddressLine2: '',
  PostCode: 1190,
  TownCity: 'Wien',
  Latitude: '4.824.070.000',
  Longitude: '1.633.930.000',
  Telephone: '+43(0)1/3696149',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.2407,16.3393',
  id: '48.2407,16.3393'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Leitersdorf 177',
  AddressLine2: '',
  PostCode: 8330,
  TownCity: 'Feldbach',
  Latitude: '4.694.936.000',
  Longitude: '1.594.368.000',
  Telephone: '+43(0)315/239002',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.94936,15.94368',
  id: '46.94936,15.94368'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Burgstrasse 29',
  AddressLine2: '',
  PostCode: 6091,
  TownCity: 'Götzens',
  Latitude: '4.723.915.000',
  Longitude: '1.131.189.000',
  Telephone: '+43(0)5234/33881',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.23915,11.31189',
  id: '47.23915,11.31189'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Mühlgraben  22',
  AddressLine2: '',
  PostCode: 8773,
  TownCity: 'Kammern',
  Latitude: '4.738.843.000',
  Longitude: '1.490.320.000',
  Telephone: '+43(0)3844/8747',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.38843,14.9032',
  id: '47.38843,14.9032'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Talstrasse 25',
  AddressLine2: '',
  PostCode: 6284,
  TownCity: 'Ramsau',
  Latitude: '4.721.043.000',
  Longitude: '1.187.250.000',
  Telephone: '+43(0)5282/21563',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.21043,11.8725',
  id: '47.21043,11.8725'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'August-Resch-Strasse 2',
  AddressLine2: '',
  PostCode: 2542,
  TownCity: 'Kottingbrunn',
  Latitude: '4.795.510.000',
  Longitude: '1.621.933.000',
  Telephone: '+43(0)2252/25164',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.9551,16.21933',
  id: '47.9551,16.21933'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Allgäuer Strasse 66',
  AddressLine2: '',
  PostCode: 6600,
  TownCity: 'Reutte',
  Latitude: '4.749.905.000',
  Longitude: '1.072.006.000',
  Telephone: '+43(0)5672/62846',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.49905,10.72006',
  id: '47.49905,10.72006'
}, {
  Country: 'Austria',
  Brand: 'OM',
  MediciName: '',
  AddressLine1: 'Autobahn A5 / Brünner Straße KM 11',
  AddressLine2: '',
  PostCode: 2123,
  TownCity: 'Wolfpassing',
  Latitude: '4.842.490.000',
  Longitude: '1.654.799.000',
  Telephone: '+43-2245-89003',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.4249,16.54799',
  id: '48.4249,16.54799'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Rosegger Strasse 15',
  AddressLine2: '',
  PostCode: 9220,
  TownCity: 'Velden',
  Latitude: '4.660.427.000',
  Longitude: '1.403.532.400',
  Telephone: '+43(0)4274/34801',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.60427,14.035324',
  id: '46.60427,14.035324'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Station Leikermoser',
  AddressLine1: 'Alpenstrasse 111',
  AddressLine2: '',
  PostCode: 5081,
  TownCity: 'Anif',
  Latitude: '477.362.928',
  Longitude: '130.507.707',
  Telephone: '',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'No',
  location: '47.7362928,13.0507707',
  id: '47.7362928,13.0507707'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Industriestrasse 12',
  AddressLine2: '',
  PostCode: 6134,
  TownCity: 'Vomp',
  Latitude: '4.735.317.000',
  Longitude: '1.170.026.000',
  Telephone: '+43(0)5242/62783',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.35317,11.70026',
  id: '47.35317,11.70026'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Umfahrungsstr. 688',
  AddressLine2: '',
  PostCode: 6290,
  TownCity: 'Mayrhofen',
  Latitude: '4.716.000.000',
  Longitude: '1.184.930.000',
  Telephone: '+43(0)5285/8266',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.16,11.8493',
  id: '47.16,11.8493'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'St. Veiterstr. 61',
  AddressLine2: '',
  PostCode: 9020,
  TownCity: 'Klagenfurt',
  Latitude: '4.663.590.000',
  Longitude: '1.431.180.000',
  Telephone: '+43(0)463/440392',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.6359,14.3118',
  id: '46.6359,14.3118'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstr. 12',
  AddressLine2: '',
  PostCode: 8650,
  TownCity: 'Kindberg',
  Latitude: '4.751.208.300',
  Longitude: '1.545.672.790',
  Telephone: '+43(0)3865/2539',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.512083,15.4567279',
  id: '47.512083,15.4567279'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Am Wachtberg 5',
  AddressLine2: '',
  PostCode: 4441,
  TownCity: 'Behamberg/Steyr',
  Latitude: '4.804.618.000',
  Longitude: '1.447.219.000',
  Telephone: '+43(0)7252/80404',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.04618,14.47219',
  id: '48.04618,14.47219'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrasse 38',
  AddressLine2: '',
  PostCode: 2351,
  TownCity: 'Wr. Neudorf',
  Latitude: '4.808.638.000',
  Longitude: '1.631.786.000',
  Telephone: '+43(0)2236/62410',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.08638,16.31786',
  id: '48.08638,16.31786'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Schweizer Strasse 45',
  AddressLine2: '',
  PostCode: 6844,
  TownCity: 'Altach',
  Latitude: '4.735.316.000',
  Longitude: '963.469.000',
  Telephone: '+43(0)5523/62094',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.35316,9.63469',
  id: '47.35316,9.63469'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Liechtensteinerstrasse 115',
  AddressLine2: '',
  PostCode: 6800,
  TownCity: 'Feldkirch',
  Latitude: '4.722.187.000',
  Longitude: '957.614.000',
  Telephone: '+43(0)5522/73119',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.22187,9.57614',
  id: '47.22187,9.57614'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Riesstraße 422',
  AddressLine2: '',
  PostCode: 8010,
  TownCity: 'Kainbach',
  Latitude: '4.710.066.000',
  Longitude: '1.555.810.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.10066,15.5581',
  id: '47.10066,15.5581'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wienerstr. 97',
  AddressLine2: '',
  PostCode: 3300,
  TownCity: 'Amstetten',
  Latitude: '4.812.269.000',
  Longitude: '1.488.671.940',
  Telephone: '+43(0)7472/62558',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.12269,14.8867194',
  id: '48.12269,14.8867194'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrasse 456',
  AddressLine2: '',
  PostCode: 8962,
  TownCity: 'Gröbming',
  Latitude: '4.744.907.000',
  Longitude: '1.390.387.000',
  Telephone: '+43(0)3685/22195',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.44907,13.90387',
  id: '47.44907,13.90387'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstraße 2',
  AddressLine2: '',
  PostCode: 8063,
  TownCity: 'Eggersdorf bei Graz',
  Latitude: '4.712.395.000',
  Longitude: '1.561.076.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.12395,15.61076',
  id: '47.12395,15.61076'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Oeynhausner Strasse 55',
  AddressLine2: '',
  PostCode: 2512,
  TownCity: 'Tribuswinkel',
  Latitude: '4.800.083.000',
  Longitude: '1.626.912.000',
  Telephone: '+43(0)2252/80317',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.00083,16.26912',
  id: '48.00083,16.26912'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Gudrunstr. 116 - 118',
  AddressLine2: '',
  PostCode: 1100,
  TownCity: 'Wien',
  Latitude: '4.817.806.000',
  Longitude: '1.637.857.000',
  Telephone: '+43(0)1/6042372',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.17806,16.37857',
  id: '48.17806,16.37857'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Fischauergasse 34',
  AddressLine2: '',
  PostCode: 2700,
  TownCity: 'Wr. Neustadt',
  Latitude: '4.782.250.000',
  Longitude: '1.624.210.000',
  Telephone: '+43(0)2622/22884',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.8225,16.2421',
  id: '47.8225,16.2421'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Lanzendorfer Straße 471/1',
  AddressLine2: '',
  PostCode: 2481,
  TownCity: 'Achau',
  Latitude: '4.808.196.000',
  Longitude: '1.639.019.000',
  Telephone: '+43(0)732/664001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.08196,16.39019',
  id: '48.08196,16.39019'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Neustadter Bundesstr., 135',
  AddressLine2: '',
  PostCode: 2512,
  TownCity: 'Oeynhausen',
  Latitude: '4.799.723.000',
  Longitude: '1.629.984.000',
  Telephone: '+43(0)2252/84631',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.99723,16.29984',
  id: '47.99723,16.29984'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Innsbruckerstrasse 171',
  AddressLine2: '',
  PostCode: 6230,
  TownCity: 'Brixlegg',
  Latitude: '4.743.262.000',
  Longitude: '1.187.866.000',
  Telephone: '+43(0)5337/62598',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.43262,11.87866',
  id: '47.43262,11.87866'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Färberstrasse 10',
  AddressLine2: '',
  PostCode: 5760,
  TownCity: 'Saalfelden',
  Latitude: '4.742.614.000',
  Longitude: '1.284.030.000',
  Telephone: '+43(0)6582/72620',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.42614,12.8403',
  id: '47.42614,12.8403'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Erdbergstrasse 189',
  AddressLine2: '',
  PostCode: 1110,
  TownCity: 'Wien',
  Latitude: '4.818.596.000',
  Longitude: '1.642.439.000',
  Telephone: '+43(0)1/74020463',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.18596,16.42439',
  id: '48.18596,16.42439'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Ignaz Harrerstr. 78',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.781.210.000',
  Longitude: '1.302.400.000',
  Telephone: '+43(0)662/433629',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.8121,13.024',
  id: '47.8121,13.024'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Parndorferstr. 103',
  AddressLine2: '',
  PostCode: 2460,
  TownCity: 'Bruckneudorf',
  Latitude: '4.802.100.000',
  Longitude: '1.678.390.000',
  Telephone: '+43(0)2162/62434',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.021,16.7839',
  id: '48.021,16.7839'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Wiener Strasse 63',
  AddressLine2: '',
  PostCode: 3500,
  TownCity: 'Krems',
  Latitude: '4.841.439.000',
  Longitude: '1.561.417.000',
  Telephone: '+43(0)2732/84967',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.41439,15.61417',
  id: '48.41439,15.61417'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Kärntner Strasse 84',
  AddressLine2: '',
  PostCode: 9900,
  TownCity: 'Lienz',
  Latitude: '4.683.116.000',
  Longitude: '1.278.376.000',
  Telephone: '+43(0)4852/63020',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.83116,12.78376',
  id: '46.83116,12.78376'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'S 36 Murtal-Schnellstr. Nord',
  AddressLine2: '',
  PostCode: 8733,
  TownCity: 'St.Marein/Knittelf.',
  Latitude: '4.725.337.000',
  Longitude: '1.487.890.000',
  Telephone: '+43(0)3515/4331',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.25337,14.8789',
  id: '47.25337,14.8789'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Bonygasse/Ruckergasse',
  AddressLine2: '',
  PostCode: 1120,
  TownCity: 'Wien',
  Latitude: '4.817.907.900',
  Longitude: '1.632.654.200',
  Telephone: '+43(0)1/8155136',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '48.179079,16.326542',
  id: '48.179079,16.326542'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Anton Regner Strasse 59',
  AddressLine2: '',
  PostCode: 8720,
  TownCity: 'Knittelfeld',
  Latitude: '4.721.828.000',
  Longitude: '1.481.564.000',
  Telephone: '+43(0)3512/82180',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.21828,14.81564',
  id: '47.21828,14.81564'
}, {
  Country: 'Austria',
  Brand: 'BP',
  MediciName: 'BP Tankstelle',
  AddressLine1: 'Nibelungenstrasse 68',
  AddressLine2: '',
  PostCode: 3680,
  TownCity: 'Persenbeug',
  Latitude: '481.898.373',
  Longitude: '150.960.731',
  Telephone: '+43741254840',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'No',
  location: '48.1898373,15.0960731',
  id: '48.1898373,15.0960731'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Welser Strasse 8',
  AddressLine2: '',
  PostCode: 4060,
  TownCity: 'Leonding-Gaumbg.',
  Latitude: '4.827.470.000',
  Longitude: '1.427.285.000',
  Telephone: '+43(0)732/671776',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '48.2747,14.27285',
  id: '48.2747,14.27285'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Hauptstrasse 20',
  AddressLine2: '',
  PostCode: 6600,
  TownCity: 'Höfen',
  Latitude: '4.747.572.000',
  Longitude: '1.069.408.000',
  Telephone: '+43(0)5672/64750',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.47572,10.69408',
  id: '47.47572,10.69408'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Schallmooser Hauptstrasse 54',
  AddressLine2: '',
  PostCode: 5020,
  TownCity: 'Salzburg',
  Latitude: '4.780.776.000',
  Longitude: '1.306.226.000',
  Telephone: '+43(0)662/640034',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '47.80776,13.06226',
  id: '47.80776,13.06226'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Tirolerstrasse 17d',
  AddressLine2: '',
  PostCode: 9900,
  TownCity: 'Lienz',
  Latitude: '4.682.770.000',
  Longitude: '1.276.640.000',
  Telephone: '+43(0)4852/63428',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Yes',
  location: '46.8277,12.7664',
  id: '46.8277,12.7664'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Tirolerstrasse 4',
  AddressLine2: '',
  PostCode: 9781,
  TownCity: 'Oberdrauburg',
  Latitude: '4.674.921.000',
  Longitude: '1.296.380.000',
  Telephone: '+43(0)4710/29778',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '46.74921,12.9638',
  id: '46.74921,12.9638'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Rasing 11',
  AddressLine2: '',
  PostCode: 8630,
  TownCity: 'Mariazell',
  Latitude: '4.776.414.000',
  Longitude: '1.530.968.000',
  Telephone: '+43(0)3882/2747',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.76414,15.30968',
  id: '47.76414,15.30968'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Schanz 1A',
  AddressLine2: '',
  PostCode: 6632,
  TownCity: 'Ehrwald',
  Latitude: '4.742.880.000',
  Longitude: '1.091.840.000',
  Telephone: '+43(0)5673/20073',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4288,10.9184',
  id: '47.4288,10.9184'
}, {
  Country: 'Austria',
  Brand: 'AP',
  MediciName: '',
  AddressLine1: 'Rheinstrasse 4',
  AddressLine2: '',
  PostCode: 6900,
  TownCity: 'Bregenz',
  Latitude: '4.749.850.000',
  Longitude: '973.258.000',
  Telephone: '+43(0)5574/43760',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'No',
  location: '47.4985,9.73258',
  id: '47.4985,9.73258'
}, {
  Brand: 'ARAL',
  Street: 'Heerstraße  325',
  PostCode: 13593,
  City: 'Berlin',
  Latitude: '525.166.640',
  Longitude: '131.778.080',
  Telephone: '303616676',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.516664,13.177808',
  id: '52.516664,13.177808'
}, {
  Brand: 'ARAL',
  Street: 'Residenzstraße 29',
  PostCode: 13409,
  City: 'Berlin',
  Latitude: '525.683.150',
  Longitude: '133.618.640',
  Telephone: '304961035',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.568315,13.361864',
  id: '52.568315,13.361864'
}, {
  Brand: 'ARAL',
  Street: 'Seestraße 18/19',
  PostCode: 13353,
  City: 'Berlin',
  Latitude: '525.464.030',
  Longitude: '133.451.870',
  Telephone: '304536026',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.546403,13.345187',
  id: '52.546403,13.345187'
}, {
  Brand: 'ARAL',
  Street: 'Britzer Damm 202-204',
  PostCode: 12347,
  City: 'Berlin',
  Latitude: '524.453.680',
  Longitude: '134.334.140',
  Telephone: '306076210',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.445368,13.433414',
  id: '52.445368,13.433414'
}, {
  Brand: 'ARAL',
  Street: 'Potsdamer Chaussee 6',
  PostCode: 14163,
  City: 'Berlin',
  Latitude: '524.331.100',
  Longitude: '132.380.920',
  Telephone: '308027017',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.43311,13.238092',
  id: '52.43311,13.238092'
}, {
  Brand: 'ARAL',
  Street: 'Reichsstraße 13/14',
  PostCode: 14052,
  City: 'Berlin',
  Latitude: '525.136.460',
  Longitude: '132.660.360',
  Telephone: '303042708',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.513646,13.266036',
  id: '52.513646,13.266036'
}, {
  Brand: 'ARAL',
  Street: 'Prinzenstraße 29',
  PostCode: 10969,
  City: 'Berlin',
  Latitude: '525.018.940',
  Longitude: '134.098.390',
  Telephone: '306149860',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.501894,13.409839',
  id: '52.501894,13.409839'
}, {
  Brand: 'ARAL',
  Street: 'Falkenseer Damm 16',
  PostCode: 13585,
  City: 'Berlin',
  Latitude: '525.427.460',
  Longitude: '131.993.040',
  Telephone: '303333811',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.542746,13.199304',
  id: '52.542746,13.199304'
}, {
  Brand: 'ARAL',
  Street: 'Rathenower Straße 8',
  PostCode: 10559,
  City: 'Berlin',
  Latitude: '525.254.580',
  Longitude: '133.568.910',
  Telephone: '303944041',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.525458,13.356891',
  id: '52.525458,13.356891'
}, {
  Brand: 'ARAL',
  Street: 'Levetzowstraße 9',
  PostCode: 10555,
  City: 'Berlin',
  Latitude: '525.210.820',
  Longitude: '133.340.040',
  Telephone: '303914575',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.521082,13.334004',
  id: '52.521082,13.334004'
}, {
  Brand: 'ARAL',
  Street: 'Argentinische Allee 49',
  PostCode: 14163,
  City: 'Berlin',
  Latitude: '524.445.440',
  Longitude: '132.425.880',
  Telephone: '3081496169',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.444544,13.242588',
  id: '52.444544,13.242588'
}, {
  Brand: 'ARAL',
  Street: 'Brunnenstraße 119',
  PostCode: 13355,
  City: 'Berlin',
  Latitude: '525.407.850',
  Longitude: '133.934.570',
  Telephone: '3046404481',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.540785,13.393457',
  id: '52.540785,13.393457'
}, {
  Brand: 'ARAL',
  Street: 'Mariendorfer Damm 195',
  PostCode: 12107,
  City: 'Berlin',
  Latitude: '524.340.360',
  Longitude: '133.905.710',
  Telephone: '307418509',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.434036,13.390571',
  id: '52.434036,13.390571'
}, {
  Brand: 'ARAL',
  Street: 'Gartenfelder Straße 71',
  PostCode: 13599,
  City: 'Berlin',
  Latitude: '525.435.680',
  Longitude: '132.432.650',
  Telephone: '303343935',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.543568,13.243265',
  id: '52.543568,13.243265'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 86',
  PostCode: 12159,
  City: 'Berlin',
  Latitude: '524.741.130',
  Longitude: '133.388.690',
  Telephone: '308515286',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.474113,13.338869',
  id: '52.474113,13.338869'
}, {
  Brand: 'ARAL',
  Street: 'Scharnweberstraße 12-13',
  PostCode: 13405,
  City: 'Berlin',
  Latitude: '525.633.750',
  Longitude: '133.298.870',
  Telephone: '304964005',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.563375,13.329887',
  id: '52.563375,13.329887'
}, {
  Brand: 'ARAL',
  Street: 'Neuköllner Straße 219',
  PostCode: 12357,
  City: 'Berlin',
  Latitude: '524.286.310',
  Longitude: '134.798.370',
  Telephone: '306614212',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.428631,13.479837',
  id: '52.428631,13.479837'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Straße 24',
  PostCode: 13507,
  City: 'Berlin',
  Latitude: '525.843.720',
  Longitude: '132.877.440',
  Telephone: '3043400950',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.584372,13.287744',
  id: '52.584372,13.287744'
}, {
  Brand: 'ARAL',
  Street: 'Vor dem Schlesischen Tor',
  PostCode: 10997,
  City: 'Berlin',
  Latitude: '524.968.570',
  Longitude: '134.496.140',
  Telephone: '3061286590',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.496857,13.449614',
  id: '52.496857,13.449614'
}, {
  Brand: 'ARAL',
  Street: 'Forsthausstraße 50',
  PostCode: 60528,
  City: 'Frankfurt',
  Latitude: '500.800.150',
  Longitude: '86.552.580',
  Telephone: '69671026',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.080015,8.655258',
  id: '50.080015,8.655258'
}, {
  Brand: 'ARAL',
  Street: 'Eschersh. Landstr.  523',
  PostCode: 60431,
  City: 'Frankfurt',
  Latitude: '501.516.550',
  Longitude: '86.623.660',
  Telephone: '69525947',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '08:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '50.151655,8.662366',
  id: '50.151655,8.662366'
}, {
  Brand: 'ARAL',
  Street: 'Louisenstraße 146',
  PostCode: 61348,
  City: 'Bad Homburg',
  Latitude: '502.217.340',
  Longitude: '86.285.910',
  Telephone: '6172450201',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.221734,8.628591',
  id: '50.221734,8.628591'
}, {
  Brand: 'ARAL',
  Street: 'Gelnhäuser Straße 17',
  PostCode: 63505,
  City: 'Langenselbold',
  Latitude: '501.756.960',
  Longitude: '90.460.260',
  Telephone: '61842103',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:30',
  SaturdayTo: '23:00',
  SundayFrom: '07:30',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.175696,9.046026',
  id: '50.175696,9.046026'
}, {
  Brand: 'ARAL',
  Street: 'A 66 Erbenheim-Nord',
  PostCode: 65205,
  City: 'Wiesbaden',
  Latitude: '500.551.130',
  Longitude: '83.119.170',
  Telephone: '611718300',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.055113,8.311917',
  id: '50.055113,8.311917'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Landstr. 200',
  PostCode: 61440,
  City: 'Oberursel',
  Latitude: '501.804.410',
  Longitude: '86.053.760',
  Telephone: '617173993',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.180441,8.605376',
  id: '50.180441,8.605376'
}, {
  Brand: 'ARAL',
  Street: 'Konrad-Adenauer-Allee 1-3',
  PostCode: 61130,
  City: 'Nidderau',
  Latitude: '502.339.360',
  Longitude: '88.632.510',
  Telephone: '618722133',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.233936,8.863251',
  id: '50.233936,8.863251'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Straße 52',
  PostCode: 61476,
  City: 'Kronberg',
  Latitude: '501.739.230',
  Longitude: '85.233.870',
  Telephone: '617379124',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.173923,8.523387',
  id: '50.173923,8.523387'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 10',
  PostCode: 63486,
  City: 'Bruchköbel',
  Latitude: '501.780.680',
  Longitude: '89.142.580',
  Telephone: '618177724',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '18:00',
  IsOpen24Hours: 'Nein',
  location: '50.178068,8.914258',
  id: '50.178068,8.914258'
}, {
  Brand: 'ARAL',
  Street: 'Kirchhohl 15',
  PostCode: 65207,
  City: 'Wiesbaden',
  Latitude: '501.328.180',
  Longitude: '82.971.000',
  Telephone: '612761428',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '18:00',
  SundayFrom: '10:00',
  SundayTo: '17:00',
  IsOpen24Hours: 'Nein',
  location: '50.132818,8.2971',
  id: '50.132818,8.2971'
}, {
  Brand: 'ARAL',
  Street: 'Dotzheimer Straße 93',
  PostCode: 65197,
  City: 'Wiesbaden',
  Latitude: '500.777.020',
  Longitude: '82.212.850',
  Telephone: '61147027',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.077702,8.221285',
  id: '50.077702,8.221285'
}, {
  Brand: 'ARAL',
  Street: 'Bieberer Straße 157',
  PostCode: 63071,
  City: 'Offenbach',
  Latitude: '500.989.880',
  Longitude: '87.858.160',
  Telephone: '6985703117',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.098988,8.785816',
  id: '50.098988,8.785816'
}, {
  Brand: 'ARAL',
  Street: 'Darmstädter Landstr. 304',
  PostCode: 60598,
  City: 'Frankfurt',
  Latitude: '500.897.010',
  Longitude: '86.907.470',
  Telephone: '696809040',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.089701,8.690747',
  id: '50.089701,8.690747'
}, {
  Brand: 'ARAL',
  Street: 'Mainzer Landstraße 545',
  PostCode: 65933,
  City: 'Frankfurt',
  Latitude: '501.001.870',
  Longitude: '86.037.210',
  Telephone: '69394954',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.100187,8.603721',
  id: '50.100187,8.603721'
}, {
  Brand: 'ARAL',
  Street: 'Zeilsheimer Straße 62',
  PostCode: 65719,
  City: 'Hofheim',
  Latitude: '500.879.860',
  Longitude: '84.583.490',
  Telephone: '61926791',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.087986,8.458349',
  id: '50.087986,8.458349'
}, {
  Brand: 'ARAL',
  Street: 'Pariser Straße 2',
  PostCode: 55131,
  City: 'Mainz',
  Latitude: '499.878.600',
  Longitude: '82.669.550',
  Telephone: '6131573110',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.98786,8.266955',
  id: '49.98786,8.266955'
}, {
  Brand: 'ARAL',
  Street: 'Erbacher Straße 2',
  PostCode: 65343,
  City: 'Eltville',
  Latitude: '500.241.370',
  Longitude: '81.160.590',
  Telephone: '6123899352',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.024137,8.116059',
  id: '50.024137,8.116059'
}, {
  Brand: 'ARAL',
  Street: 'A3',
  PostCode: 65520,
  City: 'Bad Camberg',
  Latitude: '503.004.100',
  Longitude: '82.368.240',
  Telephone: '64346568',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.30041,8.236824',
  id: '50.30041,8.236824'
}, {
  Brand: 'ARAL',
  Street: 'Steinbacher Straße1',
  PostCode: 65620,
  City: 'Waldbrunn',
  Latitude: '505.055.610',
  Longitude: '80.922.330',
  Telephone: '6436602648',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.505561,8.092233',
  id: '50.505561,8.092233'
}, {
  Brand: 'ARAL',
  Street: 'Urseler Straße 38',
  PostCode: 61348,
  City: 'Bad Homburg',
  Latitude: '502.216.590',
  Longitude: '86.070.800',
  Telephone: '6172301300',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.221659,8.60708',
  id: '50.221659,8.60708'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 1',
  PostCode: 55595,
  City: 'Roxheim',
  Latitude: '498.675.740',
  Longitude: '78.202.350',
  Telephone: '67120354475',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.867574,7.820235',
  id: '49.867574,7.820235'
}, {
  Brand: 'ARAL',
  Street: 'Monzinger Straße 102-104',
  PostCode: 55566,
  City: 'Bad Sobernheim',
  Latitude: '497.876.750',
  Longitude: '76.393.320',
  Telephone: '67514069',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.787675,7.639332',
  id: '49.787675,7.639332'
}, {
  Brand: 'ARAL',
  Street: 'Bosenheimer Straße 45',
  PostCode: 55543,
  City: 'Bad Kreuznach',
  Latitude: '498.419.890',
  Longitude: '78.672.410',
  Telephone: '6718962600',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.841989,7.867241',
  id: '49.841989,7.867241'
}, {
  Brand: 'ARAL',
  Street: 'Im Steinautal 2',
  PostCode: 55765,
  City: 'Ellweiler',
  Latitude: '496.172.270',
  Longitude: '71.589.070',
  Telephone: '67822453',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.617227,7.158907',
  id: '49.617227,7.158907'
}, {
  Brand: 'ARAL',
  Street: 'Kreuznacher Straße/B 41',
  PostCode: 55596,
  City: 'Waldböckelheim',
  Latitude: '498.190.160',
  Longitude: '77.213.660',
  Telephone: '67586273',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.819016,7.721366',
  id: '49.819016,7.721366'
}, {
  Brand: 'ARAL',
  Street: 'Aral an der A 66, Nordsei',
  PostCode: 65929,
  City: 'Frankfurt',
  Latitude: '501.196.080',
  Longitude: '85.439.030',
  Telephone: '6930852981',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.119608,8.543903',
  id: '50.119608,8.543903'
}, {
  Brand: 'ARAL',
  Street: 'Aral an der A 66, Südseit',
  PostCode: 65929,
  City: 'Frankfurt',
  Latitude: '501.180.790',
  Longitude: '85.417.270',
  Telephone: '6930850810',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.118079,8.541727',
  id: '50.118079,8.541727'
}, {
  Brand: 'ARAL',
  Street: 'Vordere Oderstraße 2',
  PostCode: 65468,
  City: 'Trebur',
  Latitude: '499.303.500',
  Longitude: '84.066.780',
  Telephone: '61473139',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '49.93035,8.406678',
  id: '49.93035,8.406678'
}, {
  Brand: 'ARAL',
  Street: 'Gelnhäuser Straße 1',
  PostCode: 63571,
  City: 'Gelnhausen',
  Latitude: '501.867.130',
  Longitude: '91.621.860',
  Telephone: '605168801',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.186713,9.162186',
  id: '50.186713,9.162186'
}, {
  Brand: 'ARAL',
  Street: 'Adam-Opel-Straße 47-49',
  PostCode: 65428,
  City: 'Rüsselsheim',
  Latitude: '499.851.920',
  Longitude: '84.291.700',
  Telephone: '614262663',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.985192,8.42917',
  id: '49.985192,8.42917'
}, {
  Brand: 'ARAL',
  Street: 'Hitchinstraße 44',
  PostCode: 55411,
  City: 'Bingen',
  Latitude: '499.484.580',
  Longitude: '79.031.180',
  Telephone: '672144743',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.948458,7.903118',
  id: '49.948458,7.903118'
}, {
  Brand: 'ARAL',
  Street: 'Waldstraße 215',
  PostCode: 63071,
  City: 'Offenbach',
  Latitude: '500.868.800',
  Longitude: '87.704.830',
  Telephone: '6983071423',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.08688,8.770483',
  id: '50.08688,8.770483'
}, {
  Brand: 'ARAL',
  Street: 'Homburger Straße 66',
  PostCode: 61184,
  City: 'Karben',
  Latitude: '502.317.800',
  Longitude: '87.664.450',
  Telephone: '60393472',
  WeekDayFrom: '04:30',
  WeekDayTo: '23:00',
  SaturdayFrom: '04:30',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.23178,8.766445',
  id: '50.23178,8.766445'
}, {
  Brand: 'ARAL',
  Street: 'An der B 49 Südseite',
  PostCode: 65614,
  City: 'Beselich',
  Latitude: '504.679.150',
  Longitude: '81.374.990',
  Telephone: '6484396',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.467915,8.137499',
  id: '50.467915,8.137499'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 27',
  PostCode: 61209,
  City: 'Echzell',
  Latitude: '503.833.140',
  Longitude: '88.824.630',
  Telephone: '6008424',
  WeekDayFrom: '04:30',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:30',
  SaturdayTo: '23:00',
  SundayFrom: '06:30',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.383314,8.882463',
  id: '50.383314,8.882463'
}, {
  Brand: 'ARAL',
  Street: 'Höhenstraße 1',
  PostCode: 61381,
  City: 'Friedrichsdorf',
  Latitude: '502.529.220',
  Longitude: '86.350.350',
  Telephone: '61722663645',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.252922,8.635035',
  id: '50.252922,8.635035'
}, {
  Brand: 'ARAL',
  Street: 'Lämmerspieler Straße 50',
  PostCode: 63179,
  City: 'Obertshausen',
  Latitude: '500.887.200',
  Longitude: '88.637.300',
  Telephone: '6104407280',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:30',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:30',
  SundayFrom: '07:00',
  SundayTo: '21:30',
  IsOpen24Hours: 'Nein',
  location: '50.08872,8.86373',
  id: '50.08872,8.86373'
}, {
  Brand: 'ARAL',
  Street: 'Kennedy-/Berliner Straße',
  PostCode: 63477,
  City: 'Maintal',
  Latitude: '501.321.620',
  Longitude: '88.500.560',
  Telephone: '6181441748',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.132162,8.850056',
  id: '50.132162,8.850056'
}, {
  Brand: 'ARAL',
  Street: 'Usinger Straße 154',
  PostCode: 61239,
  City: 'Ober-Mörlen-Ziegenbe',
  Latitude: '503.663.840',
  Longitude: '86.238.500',
  Telephone: '6002868',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '19:30',
  SundayFrom: '08:00',
  SundayTo: '19:30',
  IsOpen24Hours: 'Nein',
  location: '50.366384,8.62385',
  id: '50.366384,8.62385'
}, {
  Brand: 'ARAL',
  Street: 'Lindenstraße 33',
  PostCode: 63538,
  City: 'Großkrotzenburg',
  Latitude: '500.818.660',
  Longitude: '89.862.470',
  Telephone: '61869158160',
  WeekDayFrom: '05:30',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '09:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.081866,8.986247',
  id: '50.081866,8.986247'
}, {
  Brand: 'ARAL',
  Street: 'Ludwig-Landmann-Straße 90',
  PostCode: 60488,
  City: 'Frankfurt',
  Latitude: '501.414.470',
  Longitude: '86.098.930',
  Telephone: '69764309',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '50.141447,8.609893',
  id: '50.141447,8.609893'
}, {
  Brand: 'ARAL',
  Street: 'Kelkheimer Str 43',
  PostCode: 65779,
  City: 'Kelkheim',
  Latitude: '501.459.940',
  Longitude: '84.295.850',
  Telephone: '619561301',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.145994,8.429585',
  id: '50.145994,8.429585'
}, {
  Brand: 'ARAL',
  Street: 'B 448',
  PostCode: 63110,
  City: 'Rodgau',
  Latitude: '500.691.980',
  Longitude: '88.778.690',
  Telephone: '61044657',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.069198,8.877869',
  id: '50.069198,8.877869'
}, {
  Brand: 'ARAL',
  Street: 'Ratsweg 12',
  PostCode: 60386,
  City: 'Frankfurt',
  Latitude: '501.235.240',
  Longitude: '87.233.960',
  Telephone: '69413968',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.123524,8.723396',
  id: '50.123524,8.723396'
}, {
  Brand: 'ARAL',
  Street: 'Koblenzer Straße 81',
  PostCode: 56130,
  City: 'Bad Ems',
  Latitude: '503.363.060',
  Longitude: '77.062.840',
  Telephone: '26032131',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.336306,7.706284',
  id: '50.336306,7.706284'
}, {
  Brand: 'ARAL',
  Street: 'Koblenzer Straße 15',
  PostCode: 56459,
  City: 'Langenhahn',
  Latitude: '505.838.390',
  Longitude: '79.182.990',
  Telephone: '2663969011',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.583839,7.918299',
  id: '50.583839,7.918299'
}, {
  Brand: 'ARAL',
  Street: 'Am Autobahnzubringer/West',
  PostCode: 56203,
  City: 'Höhr-Grenzhausen',
  Latitude: '504.419.190',
  Longitude: '76.664.160',
  Telephone: '26242696',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.441919,7.666416',
  id: '50.441919,7.666416'
}, {
  Brand: 'ARAL',
  Street: 'Koblenzer Straße 25 a',
  PostCode: 56410,
  City: 'Montabaur',
  Latitude: '504.293.460',
  Longitude: '78.229.060',
  Telephone: '2602970181',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.429346,7.822906',
  id: '50.429346,7.822906'
}, {
  Brand: 'ARAL',
  Street: 'Hunsrückhöhenstraße',
  PostCode: 54497,
  City: 'Morbach',
  Latitude: '498.169.040',
  Longitude: '71.278.800',
  Telephone: '65333195',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.816904,7.12788',
  id: '49.816904,7.12788'
}, {
  Brand: 'ARAL',
  Street: 'Kurfürstenstraße 69',
  PostCode: 54516,
  City: 'Wittlich',
  Latitude: '499.822.800',
  Longitude: '69.006.370',
  Telephone: '657120841',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.98228,6.900637',
  id: '49.98228,6.900637'
}, {
  Brand: 'ARAL',
  Street: 'Ostallee 22',
  PostCode: 54290,
  City: 'Trier',
  Latitude: '497.547.050',
  Longitude: '66.473.960',
  Telephone: '65174942',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.754705,6.647396',
  id: '49.754705,6.647396'
}, {
  Brand: 'ARAL',
  Street: 'Griedeler Straße 70',
  PostCode: 35510,
  City: 'Butzbach',
  Latitude: '504.366.100',
  Longitude: '86.812.570',
  Telephone: '6033970722',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.43661,8.681257',
  id: '50.43661,8.681257'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 9',
  PostCode: 56477,
  City: 'Rennerod',
  Latitude: '506.031.630',
  Longitude: '80.640.230',
  Telephone: '2664999560',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.603163,8.064023',
  id: '50.603163,8.064023'
}, {
  Brand: 'ARAL',
  Street: 'Oppenheimer Str. 7',
  PostCode: 64521,
  City: 'Groß-Gerau',
  Latitude: '499.216.750',
  Longitude: '84.721.260',
  Telephone: '61527854',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.921675,8.472126',
  id: '49.921675,8.472126'
}, {
  Brand: 'ARAL',
  Street: 'Biebricher Allee 186',
  PostCode: 65203,
  City: 'Wiesbaden',
  Latitude: '500.485.350',
  Longitude: '82.412.740',
  Telephone: '611694289',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.048535,8.241274',
  id: '50.048535,8.241274'
}, {
  Brand: 'ARAL',
  Street: 'Schlangenbader Straße 8',
  PostCode: 65344,
  City: 'Eltville',
  Latitude: '500.563.600',
  Longitude: '81.192.020',
  Telephone: '612371407',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.05636,8.119202',
  id: '50.05636,8.119202'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Straße',
  PostCode: 63619,
  City: 'Bad Orb',
  Latitude: '502.291.550',
  Longitude: '93.438.290',
  Telephone: '6052900313',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.229155,9.343829',
  id: '50.229155,9.343829'
}, {
  Brand: 'ARAL',
  Street: 'Taunus-Schnellweg-Nordsei',
  PostCode: 61352,
  City: 'Bad Homburg',
  Latitude: '502.087.200',
  Longitude: '86.166.690',
  Telephone: '617221266',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.20872,8.616669',
  id: '50.20872,8.616669'
}, {
  Brand: 'ARAL',
  Street: 'Taunus-Schnellweg-Südseit',
  PostCode: 61352,
  City: 'Bad Homburg',
  Latitude: '502.084.270',
  Longitude: '86.153.390',
  Telephone: '6172690835',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.208427,8.615339',
  id: '50.208427,8.615339'
}, {
  Brand: 'ARAL',
  Street: 'Am Industriehof 11',
  PostCode: 60487,
  City: 'Frankfurt',
  Latitude: '501.291.130',
  Longitude: '86.240.050',
  Telephone: '69776451',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.129113,8.624005',
  id: '50.129113,8.624005'
}, {
  Brand: 'ARAL',
  Street: 'Chauvignystraße 15',
  PostCode: 65366,
  City: 'Geisenheim',
  Latitude: '499.882.610',
  Longitude: '79.778.770',
  Telephone: '6722995520',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.988261,7.977877',
  id: '49.988261,7.977877'
}, {
  Brand: 'ARAL',
  Street: 'Isenburger Straße 20',
  PostCode: 63150,
  City: 'Heusenstamm',
  Latitude: '500.586.980',
  Longitude: '87.889.440',
  Telephone: '61045123',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.058698,8.788944',
  id: '50.058698,8.788944'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Straße 55',
  PostCode: 65549,
  City: 'Limburg',
  Latitude: '503.837.340',
  Longitude: '80.702.570',
  Telephone: '6431408687',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.383734,8.070257',
  id: '50.383734,8.070257'
}, {
  Brand: 'ARAL',
  Street: 'Friedrich-Ebert-Straße54',
  PostCode: 65428,
  City: 'Rüsselsheim',
  Latitude: '499.868.440',
  Longitude: '84.163.740',
  Telephone: '614215334',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '49.986844,8.416374',
  id: '49.986844,8.416374'
}, {
  Brand: 'ARAL',
  Street: 'Darmstädter Straße 43',
  PostCode: 63303,
  City: 'Dreieich',
  Latitude: '500.133.040',
  Longitude: '86.923.310',
  Telephone: '610365671',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.013304,8.692331',
  id: '50.013304,8.692331'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Straße 68',
  PostCode: 65189,
  City: 'Wiesbaden',
  Latitude: '500.662.370',
  Longitude: '82.690.200',
  Telephone: '611701141',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.066237,8.26902',
  id: '50.066237,8.26902'
}, {
  Brand: 'ARAL',
  Street: 'Bierstadter Straße 51',
  PostCode: 65189,
  City: 'Wiesbaden',
  Latitude: '500.833.420',
  Longitude: '82.623.080',
  Telephone: '611565373',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.083342,8.262308',
  id: '50.083342,8.262308'
}, {
  Brand: 'ARAL',
  Street: 'Mainzer Straße 103',
  PostCode: 65189,
  City: 'Wiesbaden',
  Latitude: '500.621.700',
  Longitude: '82.523.170',
  Telephone: '61174478',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.06217,8.252317',
  id: '50.06217,8.252317'
}, {
  Brand: 'ARAL',
  Street: 'Andernacher Straße 207',
  PostCode: 56070,
  City: 'Koblenz',
  Latitude: '503.783.680',
  Longitude: '75.849.870',
  Telephone: '26183720',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.378368,7.584987',
  id: '50.378368,7.584987'
}, {
  Brand: 'ARAL',
  Street: 'Schlachthofstraße 79',
  PostCode: 56073,
  City: 'Koblenz',
  Latitude: '503.628.880',
  Longitude: '75.744.780',
  Telephone: '26142582',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.362888,7.574478',
  id: '50.362888,7.574478'
}, {
  Brand: 'ARAL',
  Street: 'Hochheimer Straße 25',
  PostCode: 55246,
  City: 'Mainz-Kostheim',
  Latitude: '500.079.510',
  Longitude: '82.920.790',
  Telephone: '613463241',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.007951,8.292079',
  id: '50.007951,8.292079'
}, {
  Brand: 'ARAL',
  Street: 'Lyoner Straße 70',
  PostCode: 60528,
  City: 'Frankfurt',
  Latitude: '500.781.000',
  Longitude: '86.339.340',
  Telephone: '696661885',
  WeekDayFrom: '05:30',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.0781,8.633934',
  id: '50.0781,8.633934'
}, {
  Brand: 'ARAL',
  Street: 'Talstraße 12 / B414',
  PostCode: 56472,
  City: 'Hof',
  Latitude: '506.644.410',
  Longitude: '80.157.910',
  Telephone: '266164375',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.664441,8.015791',
  id: '50.664441,8.015791'
}, {
  Brand: 'ARAL',
  Street: 'Sebastianstraße 158',
  PostCode: 53474,
  City: 'Bad Neuenahr-Ahrweil',
  Latitude: '505.455.230',
  Longitude: '71.121.390',
  Telephone: '264135033',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.545523,7.112139',
  id: '50.545523,7.112139'
}, {
  Brand: 'ARAL',
  Street: 'Trierer Straße 97',
  PostCode: 54411,
  City: 'Hermeskeil',
  Latitude: '496.577.230',
  Longitude: '69.331.540',
  Telephone: '6503404',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:30',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:30',
  SundayFrom: '07:00',
  SundayTo: '22:30',
  IsOpen24Hours: 'Nein',
  location: '49.657723,6.933154',
  id: '49.657723,6.933154'
}, {
  Brand: 'ARAL',
  Street: 'Ruwerer Straße 35',
  PostCode: 54292,
  City: 'Trier',
  Latitude: '497.802.840',
  Longitude: '67.024.400',
  Telephone: '65152397',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.780284,6.70244',
  id: '49.780284,6.70244'
}, {
  Brand: 'ARAL',
  Street: 'Oberstiftstr 41 A',
  PostCode: 54338,
  City: 'Schweich',
  Latitude: '498.269.630',
  Longitude: '67.539.250',
  Telephone: '65022225',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '10:00',
  SundayTo: '17:00',
  IsOpen24Hours: 'Nein',
  location: '49.826963,6.753925',
  id: '49.826963,6.753925'
}, {
  Brand: 'ARAL',
  Street: 'Trierer Straße 1',
  PostCode: 53518,
  City: 'Adenau',
  Latitude: '503.779.370',
  Longitude: '69.492.870',
  Telephone: '2691433',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.377937,6.949287',
  id: '50.377937,6.949287'
}, {
  Brand: 'ARAL',
  Street: 'Sinziger Straße19',
  PostCode: 53424,
  City: 'Remagen',
  Latitude: '505.721.250',
  Longitude: '72.362.690',
  Telephone: '264223321',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '19:00',
  SundayFrom: '09:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '50.572125,7.236269',
  id: '50.572125,7.236269'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 221',
  PostCode: 56575,
  City: 'Weißenthurm',
  Latitude: '504.224.020',
  Longitude: '74.454.420',
  Telephone: '26372107',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '05:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.422402,7.445442',
  id: '50.422402,7.445442'
}, {
  Brand: 'ARAL',
  Street: 'Brohltalstraße 71',
  PostCode: 56651,
  City: 'Niederzissen',
  Latitude: '504.601.810',
  Longitude: '72.230.010',
  Telephone: '26366272',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.460181,7.223001',
  id: '50.460181,7.223001'
}, {
  Brand: 'ARAL',
  Street: 'Wiedtalstraße 24',
  PostCode: 53577,
  City: 'Neustadt (Wied)',
  Latitude: '506.208.070',
  Longitude: '74.278.440',
  Telephone: '268331350',
  WeekDayFrom: '08:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '19:00',
  SundayFrom: '10:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '50.620807,7.427844',
  id: '50.620807,7.427844'
}, {
  Brand: 'ARAL',
  Street: 'Moselstraße / B 416',
  PostCode: 56330,
  City: 'Kobern-Gondorf',
  Latitude: '503.142.550',
  Longitude: '74.623.930',
  Telephone: '26074065',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.314255,7.462393',
  id: '50.314255,7.462393'
}, {
  Brand: 'ARAL',
  Street: 'Kelberger Straße 68',
  PostCode: 56727,
  City: 'Mayen',
  Latitude: '503.252.990',
  Longitude: '72.136.250',
  Telephone: '26513438',
  WeekDayFrom: '04:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.325299,7.213625',
  id: '50.325299,7.213625'
}, {
  Brand: 'ARAL',
  Street: 'Berggärtenstraße',
  PostCode: 56564,
  City: 'Neuwied',
  Latitude: '504.430.750',
  Longitude: '74.694.690',
  Telephone: '263131991',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.443075,7.469469',
  id: '50.443075,7.469469'
}, {
  Brand: 'ARAL',
  Street: 'A3',
  PostCode: 53577,
  City: 'Neustadt',
  Latitude: '505.999.240',
  Longitude: '74.323.480',
  Telephone: '26833502',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.599924,7.432348',
  id: '50.599924,7.432348'
}, {
  Brand: 'ARAL',
  Street: 'An der Bundesstraße 521',
  PostCode: 63674,
  City: 'Altenstadt',
  Latitude: '502.848.880',
  Longitude: '89.408.600',
  Telephone: '60479858950',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.284888,8.94086',
  id: '50.284888,8.94086'
}, {
  Brand: 'ARAL',
  Street: 'Friedrich-Ebert-Anlage 7',
  PostCode: 63450,
  City: 'Hanau',
  Latitude: '501.296.990',
  Longitude: '89.215.110',
  Telephone: '61819698409',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.129699,8.921511',
  id: '50.129699,8.921511'
}, {
  Brand: 'ARAL',
  Street: 'Rheinstraße 18',
  PostCode: 55435,
  City: 'Gau-Algesheim',
  Latitude: '499.648.730',
  Longitude: '80.177.130',
  Telephone: '67252340',
  WeekDayFrom: '04:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '49.964873,8.017713',
  id: '49.964873,8.017713'
}, {
  Brand: 'ARAL',
  Street: 'Friedberger Landstr. 300',
  PostCode: 60389,
  City: 'Frankfurt',
  Latitude: '501.333.840',
  Longitude: '86.960.170',
  Telephone: '699551070',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.133384,8.696017',
  id: '50.133384,8.696017'
}, {
  Brand: 'ARAL',
  Street: 'A 66 Erbenheim-Süd',
  PostCode: 65205,
  City: 'Wiesbaden',
  Latitude: '500.542.560',
  Longitude: '83.114.880',
  Telephone: '611700710',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.054256,8.311488',
  id: '50.054256,8.311488'
}, {
  Brand: 'ARAL',
  Street: 'A5',
  PostCode: 35305,
  City: 'Grünberg',
  Latitude: '506.236.640',
  Longitude: '88.955.330',
  Telephone: '640191370',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.623664,8.895533',
  id: '50.623664,8.895533'
}, {
  Brand: 'ARAL',
  Street: 'A3',
  PostCode: 63879,
  City: 'Weibersbrunn',
  Latitude: '498.974.500',
  Longitude: '93.925.360',
  Telephone: '60949889336',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.89745,9.392536',
  id: '49.89745,9.392536'
}, {
  Brand: 'ARAL',
  Street: 'BAB A 49 -Ostseite',
  PostCode: 34295,
  City: 'Edermünde',
  Latitude: '512.230.410',
  Longitude: '94.201.250',
  Telephone: '56656454',
  WeekDayFrom: '04:30',
  WeekDayTo: '23:00',
  SaturdayFrom: '04:30',
  SaturdayTo: '23:00',
  SundayFrom: '04:30',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.223041,9.420125',
  id: '51.223041,9.420125'
}, {
  Brand: 'ARAL',
  Street: 'Königstraße 32',
  PostCode: 66538,
  City: 'Neunkirchen',
  Latitude: '493.446.070',
  Longitude: '71.742.580',
  Telephone: '6821140910',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.344607,7.174258',
  id: '49.344607,7.174258'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße',
  PostCode: 55494,
  City: 'Rheinböllen',
  Latitude: '499.961.640',
  Longitude: '76.838.200',
  Telephone: '67643000',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.996164,7.68382',
  id: '49.996164,7.68382'
}, {
  Brand: 'ARAL',
  Street: 'Schiffenberger Weg 68',
  PostCode: 35394,
  City: 'Gießen',
  Latitude: '505.741.590',
  Longitude: '86.849.710',
  Telephone: '64176428',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.574159,8.684971',
  id: '50.574159,8.684971'
}, {
  Brand: 'ARAL',
  Street: 'Prälat-Caire-Straße 14',
  PostCode: 67071,
  City: 'Ludwigshafen',
  Latitude: '494.920.610',
  Longitude: '83.833.110',
  Telephone: '621680551',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.492061,8.383311',
  id: '49.492061,8.383311'
}, {
  Brand: 'ARAL',
  Street: 'Haagstraße',
  PostCode: 66679,
  City: 'Losheim am See',
  Latitude: '495.113.730',
  Longitude: '67.533.740',
  Telephone: '687291044',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.511373,6.753374',
  id: '49.511373,6.753374'
}, {
  Brand: 'ARAL',
  Street: 'Brunckstraße 100',
  PostCode: 67063,
  City: 'Ludwigshafen',
  Latitude: '495.038.060',
  Longitude: '84.126.000',
  Telephone: '621696456',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.503806,8.4126',
  id: '49.503806,8.4126'
}, {
  Brand: 'ARAL',
  Street: 'Sandstraße 126',
  PostCode: 57072,
  City: 'Siegen',
  Latitude: '508.802.570',
  Longitude: '80.265.440',
  Telephone: '27124660',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.880257,8.026544',
  id: '50.880257,8.026544'
}, {
  Brand: 'ARAL',
  Street: 'An der B 27/B292',
  PostCode: 74821,
  City: 'Mosbach',
  Latitude: '493.377.320',
  Longitude: '91.166.380',
  Telephone: '626191199',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.337732,9.116638',
  id: '49.337732,9.116638'
}, {
  Brand: 'ARAL',
  Street: 'Straßburger Straße 34a',
  PostCode: 77694,
  City: 'Kehl',
  Latitude: '485.755.460',
  Longitude: '78.174.700',
  Telephone: '7851958877',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.575546,7.81747',
  id: '48.575546,7.81747'
}, {
  Brand: 'ARAL',
  Street: 'Industriestraße 5 a',
  PostCode: 67346,
  City: 'Speyer',
  Latitude: '493.097.310',
  Longitude: '84.445.350',
  Telephone: '623261143',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.309731,8.444535',
  id: '49.309731,8.444535'
}, {
  Brand: 'ARAL',
  Street: 'Mainzer Straße 117',
  PostCode: 67657,
  City: 'Kaiserslautern',
  Latitude: '494.557.550',
  Longitude: '77.958.790',
  Telephone: '63143688',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.455755,7.795879',
  id: '49.455755,7.795879'
}, {
  Brand: 'ARAL',
  Street: 'Linkenheimer Landstr. 125',
  PostCode: 76149,
  City: 'Karlsruhe',
  Latitude: '490.381.180',
  Longitude: '83.935.980',
  Telephone: '7214539429',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.038118,8.393598',
  id: '49.038118,8.393598'
}, {
  Brand: 'ARAL',
  Street: 'Mainzer Straße 140',
  PostCode: 67547,
  City: 'Worms',
  Latitude: '496.547.370',
  Longitude: '83.593.950',
  Telephone: '6241490921',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.654737,8.359395',
  id: '49.654737,8.359395'
}, {
  Brand: 'ARAL',
  Street: 'Saarstraße 58 a',
  PostCode: 54634,
  City: 'Bitburg',
  Latitude: '499.559.210',
  Longitude: '65.251.960',
  Telephone: '6561940247',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.955921,6.525196',
  id: '49.955921,6.525196'
}, {
  Brand: 'ARAL',
  Street: 'Neumühler Weg 40',
  PostCode: 66130,
  City: 'Saarbrücken',
  Latitude: '492.073.920',
  Longitude: '70.214.820',
  Telephone: '681875522',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.207392,7.021482',
  id: '49.207392,7.021482'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 72',
  PostCode: 57334,
  City: 'Bad Laasphe',
  Latitude: '509.301.060',
  Longitude: '84.249.370',
  Telephone: '2752200416',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.930106,8.424937',
  id: '50.930106,8.424937'
}, {
  Brand: 'ARAL',
  Street: 'Petersberger Straße 101',
  PostCode: 36100,
  City: 'Petersberg',
  Latitude: '505.525.480',
  Longitude: '97.019.440',
  Telephone: '66165535',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.552548,9.701944',
  id: '50.552548,9.701944'
}, {
  Brand: 'ARAL',
  Street: 'Rommelsbacher Straße 140',
  PostCode: 72760,
  City: 'Reutlingen',
  Latitude: '485.117.140',
  Longitude: '92.042.240',
  Telephone: '7121380665',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.511714,9.204224',
  id: '48.511714,9.204224'
}, {
  Brand: 'ARAL',
  Street: 'Bundesstraße 3',
  PostCode: 77749,
  City: 'Hohberg',
  Latitude: '484.152.450',
  Longitude: '78.869.770',
  Telephone: '7808911057',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '48.415245,7.886977',
  id: '48.415245,7.886977'
}, {
  Brand: 'ARAL',
  Street: 'Calmbacher Straße 53',
  PostCode: 75323,
  City: 'Bad Wildbad',
  Latitude: '487.588.470',
  Longitude: '85.513.760',
  Telephone: '7081380973',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.758847,8.551376',
  id: '48.758847,8.551376'
}, {
  Brand: 'ARAL',
  Street: 'Alter Postweg 1',
  PostCode: 74564,
  City: 'Crailsheim',
  Latitude: '491.386.810',
  Longitude: '100.627.690',
  Telephone: '7951294260',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.138681,10.062769',
  id: '49.138681,10.062769'
}, {
  Brand: 'ARAL',
  Street: 'Saarbrücker Straße 175',
  PostCode: 66557,
  City: 'Illingen-Uchtelfange',
  Latitude: '493.832.640',
  Longitude: '70.115.430',
  Telephone: '682543054',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.383264,7.011543',
  id: '49.383264,7.011543'
}, {
  Brand: 'ARAL',
  Street: 'Lorscher Straße 53',
  PostCode: 64646,
  City: 'Heppenheim',
  Latitude: '496.439.890',
  Longitude: '86.255.360',
  Telephone: '62527969160',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.643989,8.625536',
  id: '49.643989,8.625536'
}, {
  Brand: 'ARAL',
  Street: 'Amperestraße 1',
  PostCode: 64625,
  City: 'Bensheim',
  Latitude: '496.729.100',
  Longitude: '85.976.380',
  Telephone: '6251581734',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.67291,8.597638',
  id: '49.67291,8.597638'
}, {
  Brand: 'ARAL',
  Street: 'Schönbornstraße 11',
  PostCode: 63741,
  City: 'Aschaffenburg',
  Latitude: '499.870.990',
  Longitude: '91.601.520',
  Telephone: '6021410332',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.987099,9.160152',
  id: '49.987099,9.160152'
}, {
  Brand: 'ARAL',
  Street: 'Mainzer Straße 95',
  PostCode: 65428,
  City: 'Rüsselsheim',
  Latitude: '499.931.630',
  Longitude: '83.871.300',
  Telephone: '6142910915',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.993163,8.38713',
  id: '49.993163,8.38713'
}, {
  Brand: 'ARAL',
  Street: 'Autozubringer Süd',
  PostCode: 74199,
  City: 'Untergruppenbach',
  Latitude: '490.864.240',
  Longitude: '92.751.760',
  Telephone: '713170810',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.086424,9.275176',
  id: '49.086424,9.275176'
}, {
  Brand: 'ARAL',
  Street: 'Elisabeth-Selbert-Straße',
  PostCode: 63128,
  City: 'Dietzenbach',
  Latitude: '500.134.300',
  Longitude: '87.941.100',
  Telephone: '60748511300',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.01343,8.79411',
  id: '50.01343,8.79411'
}, {
  Brand: 'ARAL',
  Street: 'Konrad-Adenauer-Straße 15',
  PostCode: 55218,
  City: 'Ingelheim',
  Latitude: '499.790.700',
  Longitude: '80.540.200',
  Telephone: '6132431937',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.97907,8.05402',
  id: '49.97907,8.05402'
}, {
  Brand: 'ARAL',
  Street: 'Matthias-Öchsler- Str. 2',
  PostCode: 91522,
  City: 'Ansbach',
  Latitude: '492.990.540',
  Longitude: '105.861.780',
  Telephone: '9819775633',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.299054,10.586178',
  id: '49.299054,10.586178'
}, {
  Brand: 'ARAL',
  Street: 'Schwabenheimer Weg 135',
  PostCode: 55543,
  City: 'Bad Kreuznach',
  Latitude: '498.518.060',
  Longitude: '78.927.670',
  Telephone: '6718965105',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.851806,7.892767',
  id: '49.851806,7.892767'
}, {
  Brand: 'ARAL',
  Street: 'Trierer Straße 44-46',
  PostCode: 66265,
  City: 'Heusweiler',
  Latitude: '493.412.240',
  Longitude: '69.336.070',
  Telephone: '6806609512',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '49.341224,6.933607',
  id: '49.341224,6.933607'
}, {
  Brand: 'ARAL',
  Street: 'Rheinallee 207',
  PostCode: 55120,
  City: 'Mainz',
  Latitude: '500.271.540',
  Longitude: '82.229.960',
  Telephone: '6131687011',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.027154,8.222996',
  id: '50.027154,8.222996'
}, {
  Brand: 'ARAL',
  Street: 'Mainfrankenpark 24',
  PostCode: 97337,
  City: 'Dettelbach',
  Latitude: '497.784.830',
  Longitude: '100.662.750',
  Telephone: '9302932100',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.778483,10.066275',
  id: '49.778483,10.066275'
}, {
  Brand: 'ARAL',
  Street: 'Mammolshainer Weg',
  PostCode: 61462,
  City: 'Königstein',
  Latitude: '501.792.420',
  Longitude: '84.759.640',
  Telephone: '6174209242',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.179242,8.475964',
  id: '50.179242,8.475964'
}, {
  Brand: 'ARAL',
  Street: 'Straßburgstraße 1',
  PostCode: 97424,
  City: 'Schweinfurt',
  Latitude: '500.216.550',
  Longitude: '102.166.180',
  Telephone: '9721608526',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.021655,10.216618',
  id: '50.021655,10.216618'
}, {
  Brand: 'ARAL',
  Street: 'Mannheimer Straße 1',
  PostCode: 68766,
  City: 'Hockenheim',
  Latitude: '493.304.580',
  Longitude: '85.360.520',
  Telephone: '6205289123',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.330458,8.536052',
  id: '49.330458,8.536052'
}, {
  Brand: 'ARAL',
  Street: 'Hockenheimer Landstraße 4',
  PostCode: 68723,
  City: 'Schwetzingen',
  Latitude: '493.771.070',
  Longitude: '85.606.630',
  Telephone: '6202128091',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.377107,8.560663',
  id: '49.377107,8.560663'
}, {
  Brand: 'ARAL',
  Street: 'Fackenburger Allee 20',
  PostCode: 23554,
  City: 'Lübeck',
  Latitude: '538.704.600',
  Longitude: '106.700.160',
  Telephone: '45142183',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.87046,10.670016',
  id: '53.87046,10.670016'
}, {
  Brand: 'ARAL',
  Street: 'Lindenstraße 24',
  PostCode: 25524,
  City: 'Itzehoe',
  Latitude: '539.291.920',
  Longitude: '95.099.990',
  Telephone: '48213114',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.929192,9.509999',
  id: '53.929192,9.509999'
}, {
  Brand: 'ARAL',
  Street: 'Maintaler Straße 20',
  PostCode: 63452,
  City: 'Hanau',
  Latitude: '501.475.830',
  Longitude: '89.012.710',
  Telephone: '61819839891',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.147583,8.901271',
  id: '50.147583,8.901271'
}, {
  Brand: 'ARAL',
  Street: 'Raun 112',
  PostCode: 63667,
  City: 'Nidda',
  Latitude: '504.085.800',
  Longitude: '90.132.240',
  Telephone: '60432456',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '50.40858,9.013224',
  id: '50.40858,9.013224'
}, {
  Brand: 'ARAL',
  Street: 'Seestraße 34',
  PostCode: 61200,
  City: 'Wölfersheim',
  Latitude: '504.027.270',
  Longitude: '88.200.520',
  Telephone: '60365204',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.402727,8.820052',
  id: '50.402727,8.820052'
}, {
  Brand: 'ARAL',
  Street: 'Friedberger Straße 89',
  PostCode: 61118,
  City: 'Bad Vilbel',
  Latitude: '501.902.240',
  Longitude: '87.423.650',
  Telephone: '6101307498',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.190224,8.742365',
  id: '50.190224,8.742365'
}, {
  Brand: 'ARAL',
  Street: 'Kleine Brückenstraße 34',
  PostCode: 61352,
  City: 'Bad Homburg',
  Latitude: '502.253.090',
  Longitude: '86.773.700',
  Telephone: '6172185476',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.225309,8.67737',
  id: '50.225309,8.67737'
}, {
  Brand: 'ARAL',
  Street: 'A5',
  PostCode: 64625,
  City: 'Bensheim',
  Latitude: '496.892.800',
  Longitude: '86.033.410',
  Telephone: '625165100',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.68928,8.603341',
  id: '49.68928,8.603341'
}, {
  Brand: 'ARAL',
  Street: 'Kieler Straße 285',
  PostCode: 22525,
  City: 'Hamburg',
  Latitude: '535.838.110',
  Longitude: '99.331.570',
  Telephone: '40545127',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.583811,9.933157',
  id: '53.583811,9.933157'
}, {
  Brand: 'ARAL',
  Street: 'Rendsburger Straße 48-52',
  PostCode: 24534,
  City: 'Neumünster',
  Latitude: '540.821.410',
  Longitude: '99.794.840',
  Telephone: '432112622',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.082141,9.979484',
  id: '54.082141,9.979484'
}, {
  Brand: 'ARAL',
  Street: 'Stresemannstraße 329',
  PostCode: 22761,
  City: 'Hamburg',
  Latitude: '535.638.140',
  Longitude: '99.255.840',
  Telephone: '40857265',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.563814,9.925584',
  id: '53.563814,9.925584'
}, {
  Brand: 'ARAL',
  Street: 'Rissener Straße 89',
  PostCode: 22880,
  City: 'Wedel',
  Latitude: '535.836.410',
  Longitude: '97.205.150',
  Telephone: '41032519',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.583641,9.720515',
  id: '53.583641,9.720515'
}, {
  Brand: 'ARAL',
  Street: 'Pinneberger Straße 1-3',
  PostCode: 22457,
  City: 'Hamburg',
  Latitude: '536.325.370',
  Longitude: '99.106.570',
  Telephone: '405502787',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.632537,9.910657',
  id: '53.632537,9.910657'
}, {
  Brand: 'ARAL',
  Street: 'Fockbeker Chaussee 4-6',
  PostCode: 24768,
  City: 'Rendsburg',
  Latitude: '543.079.290',
  Longitude: '96.480.260',
  Telephone: '433171246',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.307929,9.648026',
  id: '54.307929,9.648026'
}, {
  Brand: 'ARAL',
  Street: 'Gneversdorfer Weg 12-14',
  PostCode: 23570,
  City: 'Lübeck',
  Latitude: '539.593.330',
  Longitude: '108.614.920',
  Telephone: '45025175',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.959333,10.861492',
  id: '53.959333,10.861492'
}, {
  Brand: 'ARAL',
  Street: 'Peutestraße 2',
  PostCode: 20539,
  City: 'Hamburg',
  Latitude: '535.276.350',
  Longitude: '100.246.570',
  Telephone: '407899840',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.527635,10.024657',
  id: '53.527635,10.024657'
}, {
  Brand: 'ARAL',
  Street: 'An der Bundesstraße 201',
  PostCode: 24376,
  City: 'Kappeln',
  Latitude: '546.592.500',
  Longitude: '99.253.320',
  Telephone: '4642924788',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '54.65925,9.925332',
  id: '54.65925,9.925332'
}, {
  Brand: 'ARAL',
  Street: 'Manhagener Allee 47-49',
  PostCode: 22926,
  City: 'Ahrensburg',
  Latitude: '536.695.360',
  Longitude: '102.439.550',
  Telephone: '410252343',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.669536,10.243955',
  id: '53.669536,10.243955'
}, {
  Brand: 'ARAL',
  Street: 'Hermann-Körner-Straße 51',
  PostCode: 21465,
  City: 'Reinbek',
  Latitude: '535.222.280',
  Longitude: '102.445.150',
  Telephone: '407279460',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '53.522228,10.244515',
  id: '53.522228,10.244515'
}, {
  Brand: 'ARAL',
  Street: 'Nordstraße 20',
  PostCode: 24943,
  City: 'Flensburg',
  Latitude: '547.970.870',
  Longitude: '94.762.140',
  Telephone: '4616740568',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.797087,9.476214',
  id: '54.797087,9.476214'
}, {
  Brand: 'ARAL',
  Street: 'Bgm.-Steenbock-Str. 39',
  PostCode: 23701,
  City: 'Eutin',
  Latitude: '541.288.610',
  Longitude: '106.248.680',
  Telephone: '452178038',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '54.128861,10.624868',
  id: '54.128861,10.624868'
}, {
  Brand: 'ARAL',
  Street: 'Eiffestraße 506',
  PostCode: 20537,
  City: 'Hamburg',
  Latitude: '535.515.890',
  Longitude: '100.514.300',
  Telephone: '40213820',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.551589,10.05143',
  id: '53.551589,10.05143'
}, {
  Brand: 'ARAL',
  Street: 'Osterfeldstraße 30',
  PostCode: 22529,
  City: 'Hamburg',
  Latitude: '535.983.940',
  Longitude: '99.744.700',
  Telephone: '405531672',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.598394,9.97447',
  id: '53.598394,9.97447'
}, {
  Brand: 'ARAL',
  Street: 'Nordschleswiger Straße 91',
  PostCode: 22049,
  City: 'Hamburg',
  Latitude: '535.798.510',
  Longitude: '100.698.370',
  Telephone: '40687042',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.579851,10.069837',
  id: '53.579851,10.069837'
}, {
  Brand: 'ARAL',
  Street: 'Winterhuder Weg 25',
  PostCode: 22085,
  City: 'Hamburg',
  Latitude: '535.735.750',
  Longitude: '100.225.810',
  Telephone: '402207516',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.573575,10.022581',
  id: '53.573575,10.022581'
}, {
  Brand: 'ARAL',
  Street: 'Kastanienallee 17-19',
  PostCode: 21255,
  City: 'Tostedt',
  Latitude: '532.821.340',
  Longitude: '97.140.030',
  Telephone: '4182293396',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.282134,9.714003',
  id: '53.282134,9.714003'
}, {
  Brand: 'ARAL',
  Street: 'Bargteheider Straße 100',
  PostCode: 22143,
  City: 'Hamburg',
  Latitude: '536.044.690',
  Longitude: '101.478.260',
  Telephone: '406773554',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.604469,10.147826',
  id: '53.604469,10.147826'
}, {
  Brand: 'ARAL',
  Street: 'Hamburger Straße 110',
  PostCode: 23843,
  City: 'Bad Oldesloe',
  Latitude: '538.030.710',
  Longitude: '103.590.540',
  Telephone: '453182243',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.803071,10.359054',
  id: '53.803071,10.359054'
}, {
  Brand: 'ARAL',
  Street: 'Schönberger Landstraße135',
  PostCode: 24232,
  City: 'Schönkirchen',
  Latitude: '543.389.120',
  Longitude: '102.219.490',
  Telephone: '4348446',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '54.338912,10.221949',
  id: '54.338912,10.221949'
}, {
  Brand: 'ARAL',
  Street: 'Sievekingsallee/Saling 30',
  PostCode: 20535,
  City: 'Hamburg',
  Latitude: '535.613.930',
  Longitude: '100.484.900',
  Telephone: '402502222',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.561393,10.04849',
  id: '53.561393,10.04849'
}, {
  Brand: 'ARAL',
  Street: 'PoppenbüttelerWeg 126',
  PostCode: 22399,
  City: 'Hamburg',
  Latitude: '536.497.320',
  Longitude: '100.644.190',
  Telephone: '405385227',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.649732,10.064419',
  id: '53.649732,10.064419'
}, {
  Brand: 'ARAL',
  Street: 'Segeberger Straße 42',
  PostCode: 23866,
  City: 'Nahe',
  Latitude: '537.957.330',
  Longitude: '101.414.600',
  Telephone: '4535407',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.795733,10.14146',
  id: '53.795733,10.14146'
}, {
  Brand: 'ARAL',
  Street: 'Spaldingstraße 107',
  PostCode: 20097,
  City: 'Hamburg',
  Latitude: '535.504.290',
  Longitude: '100.199.640',
  Telephone: '40234524',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.550429,10.019964',
  id: '53.550429,10.019964'
}, {
  Brand: 'ARAL',
  Street: 'Jesteburger Straße 12',
  PostCode: 21218,
  City: 'Seevetal',
  Latitude: '533.824.500',
  Longitude: '99.793.460',
  Telephone: '41052804',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.38245,9.979346',
  id: '53.38245,9.979346'
}, {
  Brand: 'ARAL',
  Street: 'Holsteiner Chaussee 55',
  PostCode: 22523,
  City: 'Hamburg',
  Latitude: '536.147.120',
  Longitude: '99.060.610',
  Telephone: '405706132',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.614712,9.906061',
  id: '53.614712,9.906061'
}, {
  Brand: 'ARAL',
  Street: 'Friedensallee 92',
  PostCode: 25335,
  City: 'Elmshorn',
  Latitude: '537.638.490',
  Longitude: '96.627.110',
  Telephone: '412182611',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.763849,9.662711',
  id: '53.763849,9.662711'
}, {
  Brand: 'ARAL',
  Street: 'Apensener Straße 20',
  PostCode: 21614,
  City: 'Buxtehude',
  Latitude: '534.664.800',
  Longitude: '96.766.100',
  Telephone: '416184850',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.46648,9.67661',
  id: '53.46648,9.67661'
}, {
  Brand: 'ARAL',
  Street: 'Ohechaussee 146',
  PostCode: 22848,
  City: 'Norderstedt',
  Latitude: '536.719.940',
  Longitude: '99.833.600',
  Telephone: '405234266',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.671994,9.98336',
  id: '53.671994,9.98336'
}, {
  Brand: 'ARAL',
  Street: 'An der E 47 -Landseite-',
  PostCode: 23775,
  City: 'Großenbrode',
  Latitude: '543.826.570',
  Longitude: '110.938.100',
  Telephone: '4367206',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '06:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '54.382657,11.09381',
  id: '54.382657,11.09381'
}, {
  Brand: 'ARAL',
  Street: 'Meldorfer Straße 24',
  PostCode: 25709,
  City: 'Marne',
  Latitude: '539.599.750',
  Longitude: '90.076.510',
  Telephone: '4851606',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '53.959975,9.007651',
  id: '53.959975,9.007651'
}, {
  Brand: 'ARAL',
  Street: 'Grodener Chaussee 52',
  PostCode: 27472,
  City: 'Cuxhaven',
  Latitude: '538.530.610',
  Longitude: '87.112.410',
  Telephone: '472123442',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.853061,8.711241',
  id: '53.853061,8.711241'
}, {
  Brand: 'ARAL',
  Street: 'Flensburger Chaussee 44',
  PostCode: 25813,
  City: 'Husum',
  Latitude: '544.876.440',
  Longitude: '90.772.710',
  Telephone: '484172690',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.487644,9.077271',
  id: '54.487644,9.077271'
}, {
  Brand: 'ARAL',
  Street: 'Stadtstraße 31',
  PostCode: 25348,
  City: 'Glückstadt',
  Latitude: '537.809.170',
  Longitude: '94.304.470',
  Telephone: '41242725',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.780917,9.430447',
  id: '53.780917,9.430447'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 4',
  PostCode: 22885,
  City: 'Barsbüttel',
  Latitude: '535.693.880',
  Longitude: '101.525.570',
  Telephone: '406700104',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.569388,10.152557',
  id: '53.569388,10.152557'
}, {
  Brand: 'ARAL',
  Street: 'Kollaustraße 200',
  PostCode: 22453,
  City: 'Hamburg',
  Latitude: '536.155.520',
  Longitude: '99.518.080',
  Telephone: '40585456',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.615552,9.951808',
  id: '53.615552,9.951808'
}, {
  Brand: 'ARAL',
  Street: 'Bremer Straße 300',
  PostCode: 21077,
  City: 'Hamburg',
  Latitude: '534.385.960',
  Longitude: '99.361.190',
  Telephone: '407604241',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.438596,9.936119',
  id: '53.438596,9.936119'
}, {
  Brand: 'ARAL',
  Street: 'Mengestraße 14',
  PostCode: 21107,
  City: 'Hamburg',
  Latitude: '535.002.840',
  Longitude: '99.941.580',
  Telephone: '407534767',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.500284,9.994158',
  id: '53.500284,9.994158'
}, {
  Brand: 'ARAL',
  Street: 'Schleswiger Damm 173',
  PostCode: 22457,
  City: 'Hamburg',
  Latitude: '536.415.630',
  Longitude: '99.168.750',
  Telephone: '405509471',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.641563,9.916875',
  id: '53.641563,9.916875'
}, {
  Brand: 'ARAL',
  Street: 'Ostfrieslandstraße 80',
  PostCode: 21129,
  City: 'Hamburg',
  Latitude: '535.265.800',
  Longitude: '98.848.680',
  Telephone: '407428605',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.52658,9.884868',
  id: '53.52658,9.884868'
}, {
  Brand: 'ARAL',
  Street: 'Hans-Böckler-Straße 35',
  PostCode: 27578,
  City: 'Bremerhaven',
  Latitude: '535.877.040',
  Longitude: '86.126.000',
  Telephone: '47160300',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:30',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.587704,8.6126',
  id: '53.587704,8.6126'
}, {
  Brand: 'ARAL',
  Street: 'Stresemannstraße 118',
  PostCode: 27576,
  City: 'Bremerhaven',
  Latitude: '535.552.020',
  Longitude: '85.936.560',
  Telephone: '47153080',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.555202,8.593656',
  id: '53.555202,8.593656'
}, {
  Brand: 'ARAL',
  Street: 'Bremer Landstraße4',
  PostCode: 27412,
  City: 'Tarmstedt',
  Latitude: '532.232.330',
  Longitude: '90.767.150',
  Telephone: '428389110',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '53.223233,9.076715',
  id: '53.223233,9.076715'
}, {
  Brand: 'ARAL',
  Street: 'Nienburger Straße 1',
  PostCode: 27283,
  City: 'Verden',
  Latitude: '529.180.790',
  Longitude: '92.198.630',
  Telephone: '42312747',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.918079,9.219863',
  id: '52.918079,9.219863'
}, {
  Brand: 'ARAL',
  Street: 'Ziegelstraße 46',
  PostCode: 23795,
  City: 'Bad Segeberg',
  Latitude: '539.420.050',
  Longitude: '103.000.400',
  Telephone: '45512920',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.942005,10.30004',
  id: '53.942005,10.30004'
}, {
  Brand: 'ARAL',
  Street: 'Theisbrügger Straße 1',
  PostCode: 21706,
  City: 'Drochtersen',
  Latitude: '537.153.520',
  Longitude: '93.727.770',
  Telephone: '41437463',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.715352,9.372777',
  id: '53.715352,9.372777'
}, {
  Brand: 'ARAL',
  Street: 'Elmshorner Straße 36',
  PostCode: 25524,
  City: 'Itzehoe',
  Latitude: '539.058.090',
  Longitude: '95.079.330',
  Telephone: '482182469',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.905809,9.507933',
  id: '53.905809,9.507933'
}, {
  Brand: 'ARAL',
  Street: 'Langenhorner Chaussee 53-',
  PostCode: 22335,
  City: 'Hamburg',
  Latitude: '536.387.530',
  Longitude: '100.133.720',
  Telephone: '405315155',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.638753,10.013372',
  id: '53.638753,10.013372'
}, {
  Brand: 'ARAL',
  Street: 'Ulzburger Straße 440',
  PostCode: 22844,
  City: 'Norderstedt',
  Latitude: '537.276.330',
  Longitude: '99.977.790',
  Telephone: '405222155',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.727633,9.997779',
  id: '53.727633,9.997779'
}, {
  Brand: 'ARAL',
  Street: 'Hamburger Straße 30',
  PostCode: 21481,
  City: 'Lauenburg',
  Latitude: '533.741.860',
  Longitude: '105.503.940',
  Telephone: '41533120',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Ja',
  location: '53.374186,10.550394',
  id: '53.374186,10.550394'
}, {
  Brand: 'ARAL',
  Street: 'Ratzeburger Allee 116',
  PostCode: 23562,
  City: 'Lübeck',
  Latitude: '538.412.190',
  Longitude: '107.079.040',
  Telephone: '451503133',
  WeekDayFrom: '04:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.841219,10.707904',
  id: '53.841219,10.707904'
}, {
  Brand: 'ARAL',
  Street: 'Wasserkrüger Weg 131',
  PostCode: 23879,
  City: 'Mölln',
  Latitude: '536.149.670',
  Longitude: '106.911.390',
  Telephone: '45423656',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.614967,10.691139',
  id: '53.614967,10.691139'
}, {
  Brand: 'ARAL',
  Street: 'Hamburger Landstraße 103',
  PostCode: 24113,
  City: 'Molfsee',
  Latitude: '542.725.920',
  Longitude: '100.749.240',
  Telephone: '431651536',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.272592,10.074924',
  id: '54.272592,10.074924'
}, {
  Brand: 'ARAL',
  Street: 'Schweriner Straße 75',
  PostCode: 23909,
  City: 'Ratzeburg',
  Latitude: '536.929.100',
  Longitude: '107.877.400',
  Telephone: '454183929',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.69291,10.78774',
  id: '53.69291,10.78774'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 61',
  PostCode: 24980,
  City: 'Schafflund',
  Latitude: '547.607.460',
  Longitude: '91.763.940',
  Telephone: '4639326',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '54.760746,9.176394',
  id: '54.760746,9.176394'
}, {
  Brand: 'ARAL',
  Street: 'Mittelweg 3',
  PostCode: 25980,
  City: 'Sylt',
  Latitude: '549.004.010',
  Longitude: '83.392.050',
  Telephone: '46518367790',
  WeekDayFrom: '06:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '54.900401,8.339205',
  id: '54.900401,8.339205'
}, {
  Brand: 'ARAL',
  Street: 'Hindenburgstraße 10',
  PostCode: 27442,
  City: 'Gnarrenburg',
  Latitude: '533.764.170',
  Longitude: '90.068.090',
  Telephone: '4763627155',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.376417,9.006809',
  id: '53.376417,9.006809'
}, {
  Brand: 'ARAL',
  Street: 'Ringstraße 26',
  PostCode: 32427,
  City: 'Minden',
  Latitude: '522.831.310',
  Longitude: '89.020.970',
  Telephone: '57121415',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.283131,8.902097',
  id: '52.283131,8.902097'
}, {
  Brand: 'ARAL',
  Street: 'Georgstraße 48',
  PostCode: 49809,
  City: 'Lingen',
  Latitude: '525.223.260',
  Longitude: '73.340.730',
  Telephone: '5913800',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.522326,7.334073',
  id: '52.522326,7.334073'
}, {
  Brand: 'ARAL',
  Street: 'Fröhlkingstraße 6',
  PostCode: 49584,
  City: 'Fürstenau',
  Latitude: '525.136.030',
  Longitude: '76.777.450',
  Telephone: '59012771',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '08:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '52.513603,7.677745',
  id: '52.513603,7.677745'
}, {
  Brand: 'ARAL',
  Street: 'Oppelner Straße 1',
  PostCode: 31167,
  City: 'Bockenem',
  Latitude: '520.019.070',
  Longitude: '101.351.850',
  Telephone: '5067697875',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.001907,10.135185',
  id: '52.001907,10.135185'
}, {
  Brand: 'ARAL',
  Street: 'Schiffdorfer Chaussee 120',
  PostCode: 27574,
  City: 'Bremerhaven',
  Latitude: '535.287.090',
  Longitude: '86.160.310',
  Telephone: '47135219',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.528709,8.616031',
  id: '53.528709,8.616031'
}, {
  Brand: 'ARAL',
  Street: 'Hamburger Straße 47',
  PostCode: 27283,
  City: 'Verden',
  Latitude: '529.479.810',
  Longitude: '92.331.420',
  Telephone: '423173264',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.947981,9.233142',
  id: '52.947981,9.233142'
}, {
  Brand: 'ARAL',
  Street: 'Drangstedter Straße 46',
  PostCode: 27624,
  City: 'GEESTLAND',
  Latitude: '536.264.140',
  Longitude: '88.243.840',
  Telephone: '47455085',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.626414,8.824384',
  id: '53.626414,8.824384'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 110',
  PostCode: 25462,
  City: 'Rellingen',
  Latitude: '536.547.860',
  Longitude: '98.234.910',
  Telephone: '4101204018',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.654786,9.823491',
  id: '53.654786,9.823491'
}, {
  Brand: 'ARAL',
  Street: 'Stockholmstraße 2',
  PostCode: 24109,
  City: 'Kiel',
  Latitude: '543.261.090',
  Longitude: '100.582.830',
  Telephone: '431527585',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.326109,10.058283',
  id: '54.326109,10.058283'
}, {
  Brand: 'ARAL',
  Street: 'Ritterhuder Straße 55',
  PostCode: 27711,
  City: 'Osterholz-Scharmbeck',
  Latitude: '532.148.260',
  Longitude: '87.786.520',
  Telephone: '47912772',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.214826,8.778652',
  id: '53.214826,8.778652'
}, {
  Brand: 'ARAL',
  Street: 'Möllner Straße 85',
  PostCode: 21493,
  City: 'Schwarzenbek',
  Latitude: '535.115.300',
  Longitude: '104.891.150',
  Telephone: '4151895430',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.51153,10.489115',
  id: '53.51153,10.489115'
}, {
  Brand: 'ARAL',
  Street: 'A7',
  PostCode: 24791,
  City: 'Alt Duvenstedt',
  Latitude: '543.641.910',
  Longitude: '96.706.570',
  Telephone: '4338466',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.364191,9.670657',
  id: '54.364191,9.670657'
}, {
  Brand: 'ARAL',
  Street: 'Pagenstecherstraße 58-60',
  PostCode: 49090,
  City: 'Osnabrück',
  Latitude: '522.897.380',
  Longitude: '80.274.460',
  Telephone: '54161931',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.289738,8.027446',
  id: '52.289738,8.027446'
}, {
  Brand: 'ARAL',
  Street: 'Oesterstraße 92',
  PostCode: 25704,
  City: 'Meldorf',
  Latitude: '540.886.840',
  Longitude: '90.960.750',
  Telephone: '48323211',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '54.088684,9.096075',
  id: '54.088684,9.096075'
}, {
  Brand: 'ARAL',
  Street: 'Olof-Palme-Allee 2',
  PostCode: 25541,
  City: 'Brunsbüttel',
  Latitude: '539.004.220',
  Longitude: '91.210.240',
  Telephone: '48527272',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '53.900422,9.121024',
  id: '53.900422,9.121024'
}, {
  Brand: 'ARAL',
  Street: 'Winsener Str. 25',
  PostCode: 21220,
  City: 'Seevetal',
  Latitude: '533.906.450',
  Longitude: '100.452.430',
  Telephone: '410582801',
  WeekDayFrom: '04:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.390645,10.045243',
  id: '53.390645,10.045243'
}, {
  Brand: 'ARAL',
  Street: 'Schwedendamm 21',
  PostCode: 24143,
  City: 'Kiel',
  Latitude: '543.073.560',
  Longitude: '101.367.290',
  Telephone: '43174400',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.307356,10.136729',
  id: '54.307356,10.136729'
}, {
  Brand: 'ARAL',
  Street: 'Hamburger Straße 123',
  PostCode: 24558,
  City: 'Henstedt-Ulzburg',
  Latitude: '537.869.870',
  Longitude: '99.792.660',
  Telephone: '419393626',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.786987,9.979266',
  id: '53.786987,9.979266'
}, {
  Brand: 'ARAL',
  Street: 'Isenstedter Straße 139',
  PostCode: 32339,
  City: 'Espelkamp',
  Latitude: '523.739.300',
  Longitude: '86.347.290',
  Telephone: '5772936176',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.37393,8.634729',
  id: '52.37393,8.634729'
}, {
  Brand: 'ARAL',
  Street: 'BAB 21',
  PostCode: 23795,
  City: 'Schackendorf',
  Latitude: '539.601.760',
  Longitude: '102.604.580',
  Telephone: '45514698',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.960176,10.260458',
  id: '53.960176,10.260458'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 62',
  PostCode: 26954,
  City: 'Nordenham',
  Latitude: '534.868.120',
  Longitude: '84.752.460',
  Telephone: '473122702',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.486812,8.475246',
  id: '53.486812,8.475246'
}, {
  Brand: 'ARAL',
  Street: 'Leipziger Straße 8c',
  PostCode: 38302,
  City: 'Wolfenbüttel',
  Latitude: '521.631.110',
  Longitude: '105.528.710',
  Telephone: '5331907575',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.163111,10.552871',
  id: '52.163111,10.552871'
}, {
  Brand: 'ARAL',
  Street: 'Rönnebrocksweg 15',
  PostCode: 27356,
  City: 'Rotenburg',
  Latitude: '531.166.880',
  Longitude: '93.951.520',
  Telephone: '4261971902',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.116688,9.395152',
  id: '53.116688,9.395152'
}, {
  Brand: 'ARAL',
  Street: 'Münsterstraße 63-67',
  PostCode: 49377,
  City: 'Vechta',
  Latitude: '527.189.230',
  Longitude: '82.871.410',
  Telephone: '4441921437',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.718923,8.287141',
  id: '52.718923,8.287141'
}, {
  Brand: 'ARAL',
  Street: 'Altendorfer Tor',
  PostCode: 37574,
  City: 'Einbeck',
  Latitude: '518.115.660',
  Longitude: '98.907.610',
  Telephone: '556174228',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.811566,9.890761',
  id: '51.811566,9.890761'
}, {
  Brand: 'ARAL',
  Street: 'An der Autobahn 1',
  PostCode: 27404,
  City: 'Gyhum',
  Latitude: '531.934.170',
  Longitude: '92.888.840',
  Telephone: '428695120',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.193417,9.288884',
  id: '53.193417,9.288884'
}, {
  Brand: 'ARAL',
  Street: 'Rendsburger Landstr. 205',
  PostCode: 24113,
  City: 'Kiel',
  Latitude: '543.004.660',
  Longitude: '100.921.950',
  Telephone: '4316409660',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.300466,10.092195',
  id: '54.300466,10.092195'
}, {
  Brand: 'ARAL',
  Street: 'Detmolder Straße 75',
  PostCode: 33100,
  City: 'Paderborn',
  Latitude: '517.322.990',
  Longitude: '87.648.380',
  Telephone: '5251527584',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.732299,8.764838',
  id: '51.732299,8.764838'
}, {
  Brand: 'ARAL',
  Street: 'Braunschw. Heerstraße 79',
  PostCode: 29227,
  City: 'Celle',
  Latitude: '526.006.350',
  Longitude: '101.073.820',
  Telephone: '5141880723',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.600635,10.107382',
  id: '52.600635,10.107382'
}, {
  Brand: 'ARAL',
  Street: 'Dieselstraße 1',
  PostCode: 23738,
  City: 'Lensahn',
  Latitude: '542.175.900',
  Longitude: '108.967.730',
  Telephone: '4363901841',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.21759,10.896773',
  id: '54.21759,10.896773'
}, {
  Brand: 'ARAL',
  Street: 'Großmannstraße 195-211',
  PostCode: 20539,
  City: 'Hamburg',
  Latitude: '535.367.530',
  Longitude: '100.642.040',
  Telephone: '4078079995',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.536753,10.064204',
  id: '53.536753,10.064204'
}, {
  Brand: 'ARAL',
  Street: 'Bruchstraße 35',
  PostCode: 57462,
  City: 'Olpe',
  Latitude: '510.242.640',
  Longitude: '78.446.930',
  Telephone: '27612212',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.024264,7.844693',
  id: '51.024264,7.844693'
}, {
  Brand: 'ARAL',
  Street: 'Konrad-Adenauer-Allee 3 a',
  PostCode: 21614,
  City: 'Buxtehude',
  Latitude: '534.701.050',
  Longitude: '97.136.460',
  Telephone: '4161645637',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.470105,9.713646',
  id: '53.470105,9.713646'
}, {
  Brand: 'ARAL',
  Street: 'Schimmelmannstraße 97',
  PostCode: 22043,
  City: 'Hamburg',
  Latitude: '535.722.340',
  Longitude: '101.049.740',
  Telephone: '4065389512',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.572234,10.104974',
  id: '53.572234,10.104974'
}, {
  Brand: 'ARAL',
  Street: 'Lange Straße 39',
  PostCode: 38300,
  City: 'Wolfenbüttel',
  Latitude: '521.565.580',
  Longitude: '105.396.760',
  Telephone: '53311235',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.156558,10.539676',
  id: '52.156558,10.539676'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 1',
  PostCode: 37589,
  City: 'Echte',
  Latitude: '517.877.550',
  Longitude: '100.642.170',
  Telephone: '5553919618',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.787755,10.064217',
  id: '51.787755,10.064217'
}, {
  Brand: 'ARAL',
  Street: 'Hamburger Straße 35 b',
  PostCode: 21339,
  City: 'Lüneburg',
  Latitude: '532.751.590',
  Longitude: '104.010.780',
  Telephone: '4131855291',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.275159,10.401078',
  id: '53.275159,10.401078'
}, {
  Brand: 'ARAL',
  Street: 'Ludwig-Erhard-Straße 4',
  PostCode: 29525,
  City: 'Uelzen',
  Latitude: '529.954.800',
  Longitude: '105.396.050',
  Telephone: '5813897450',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.99548,10.539605',
  id: '52.99548,10.539605'
}, {
  Brand: 'ARAL',
  Street: 'Bramfelder Chaussee 66',
  PostCode: 22177,
  City: 'Hamburg',
  Latitude: '535.972.080',
  Longitude: '100.647.010',
  Telephone: '4069793273',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.597208,10.064701',
  id: '53.597208,10.064701'
}, {
  Brand: 'ARAL',
  Street: 'Nettelnburger Landweg 2',
  PostCode: 21035,
  City: 'Hamburg',
  Latitude: '534.866.990',
  Longitude: '101.838.660',
  Telephone: '4073596311',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.486699,10.183866',
  id: '53.486699,10.183866'
}, {
  Brand: 'ARAL',
  Street: 'Weserstraße 239',
  PostCode: 27572,
  City: 'Bremerhaven',
  Latitude: '535.183.630',
  Longitude: '85.914.780',
  Telephone: '4717004265',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.518363,8.591478',
  id: '53.518363,8.591478'
}, {
  Brand: 'ARAL',
  Street: 'Koppelberg 1',
  PostCode: 24159,
  City: 'Kiel-Friedrichsort',
  Latitude: '544.034.290',
  Longitude: '101.662.160',
  Telephone: '4313970552',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.403429,10.166216',
  id: '54.403429,10.166216'
}, {
  Brand: 'ARAL',
  Street: 'Alte Heerstraße 4',
  PostCode: 31061,
  City: 'Alfeld',
  Latitude: '519.957.520',
  Longitude: '97.998.700',
  Telephone: '5181828326',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.995752,9.79987',
  id: '51.995752,9.79987'
}, {
  Brand: 'ARAL',
  Street: 'Lise-Meitner-Allee 3',
  PostCode: 25436,
  City: 'Tornesch',
  Latitude: '537.181.200',
  Longitude: '97.598.080',
  Telephone: '4120909760',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.71812,9.759808',
  id: '53.71812,9.759808'
}, {
  Brand: 'ARAL',
  Street: 'Paderborner Tor 180/B 7',
  PostCode: 34414,
  City: 'Warburg',
  Latitude: '514.945.930',
  Longitude: '91.260.220',
  Telephone: '5641743838',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.494593,9.126022',
  id: '51.494593,9.126022'
}, {
  Brand: 'ARAL',
  Street: 'Rudolf-Diesel-Straße 2',
  PostCode: 26670,
  City: 'Uplengen-Jübberde',
  Latitude: '532.628.410',
  Longitude: '77.560.890',
  Telephone: '4956990408',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.262841,7.756089',
  id: '53.262841,7.756089'
}, {
  Brand: 'ARAL',
  Street: 'Max-Planck-Straße 18',
  PostCode: 21423,
  City: 'Winsen',
  Latitude: '533.393.230',
  Longitude: '102.195.390',
  Telephone: '4171690795',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.339323,10.219539',
  id: '53.339323,10.219539'
}, {
  Brand: 'ARAL',
  Street: 'Hörster Heide 2',
  PostCode: 49434,
  City: 'Neuenkirchen',
  Latitude: '524.914.890',
  Longitude: '80.789.940',
  Telephone: '5495952150',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.491489,8.078994',
  id: '52.491489,8.078994'
}, {
  Brand: 'ARAL',
  Street: 'Holstenstraße 112',
  PostCode: 25560,
  City: 'Schenefeld',
  Latitude: '540.396.410',
  Longitude: '94.800.570',
  Telephone: '4892859750',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.039641,9.480057',
  id: '54.039641,9.480057'
}, {
  Brand: 'ARAL',
  Street: 'Osterholzer Heerstraße 119-121',
  PostCode: 28307,
  City: 'Bremen',
  Latitude: '530.581.020',
  Longitude: '89.385.440',
  Telephone: '4214173193',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.058102,8.938544',
  id: '53.058102,8.938544'
}, {
  Brand: 'ARAL',
  Street: 'Weser-Ems-Str. 1',
  PostCode: 28816,
  City: 'Stuhr',
  Latitude: '530.210.270',
  Longitude: '86.878.740',
  Telephone: '42213799',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.021027,8.687874',
  id: '53.021027,8.687874'
}, {
  Brand: 'ARAL',
  Street: 'Europaallee',
  PostCode: 28309,
  City: 'Bremen',
  Latitude: '530.347.400',
  Longitude: '88.913.150',
  Telephone: '4214581380',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.03474,8.891315',
  id: '53.03474,8.891315'
}, {
  Brand: 'ARAL',
  Street: 'Rudolf-Diesel-Straße 2',
  PostCode: 24558,
  City: 'Henstedt-Ulzburg',
  Latitude: '538.039.600',
  Longitude: '99.391.070',
  Telephone: '4193888252',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.80396,9.939107',
  id: '53.80396,9.939107'
}, {
  Brand: 'ARAL',
  Street: 'An der E 47 -Seeseite-',
  PostCode: 23775,
  City: 'Großenbrode',
  Latitude: '543.824.110',
  Longitude: '110.935.570',
  Telephone: '4367207',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.382411,11.093557',
  id: '54.382411,11.093557'
}, {
  Brand: 'ARAL',
  Street: 'Ernst-Barlach-Straße 1',
  PostCode: 26389,
  City: 'Wilhelmshaven',
  Latitude: '535.266.130',
  Longitude: '80.657.670',
  Telephone: '442171688',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.526613,8.065767',
  id: '53.526613,8.065767'
}, {
  Brand: 'ARAL',
  Street: 'Dieselstraße 2',
  PostCode: 48499,
  City: 'Salzbergen',
  Latitude: '523.147.800',
  Longitude: '73.536.560',
  Telephone: '5976940750',
  WeekDayFrom: '05:30',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '08:30',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '52.31478,7.353656',
  id: '52.31478,7.353656'
}, {
  Brand: 'ARAL',
  Street: 'Bockhorner Landstraße 63a',
  PostCode: 23826,
  City: 'Bark O.T. Bockhorn',
  Latitude: '539.184.090',
  Longitude: '101.276.570',
  Telephone: '4558549',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.918409,10.127657',
  id: '53.918409,10.127657'
}, {
  Brand: 'ARAL',
  Street: 'Eckernförder Straße 9 b',
  PostCode: 24376,
  City: 'Kappeln',
  Latitude: '546.572.390',
  Longitude: '99.461.280',
  Telephone: '464281008',
  WeekDayFrom: '05:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '54.657239,9.946128',
  id: '54.657239,9.946128'
}, {
  Brand: 'ARAL',
  Street: 'An der Autobahn Nr. 2',
  PostCode: 23858,
  City: 'Reinfeld',
  Latitude: '538.235.760',
  Longitude: '105.040.980',
  Telephone: '4533791203',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.823576,10.504098',
  id: '53.823576,10.504098'
}, {
  Brand: 'ARAL',
  Street: 'Wilhelm-Iwan-Ring 1',
  PostCode: 21035,
  City: 'Hamburg',
  Latitude: '534.898.170',
  Longitude: '101.150.830',
  Telephone: '4073431926',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.489817,10.115083',
  id: '53.489817,10.115083'
}, {
  Brand: 'ARAL',
  Street: 'Hamburger Straße 65',
  PostCode: 25355,
  City: 'Barmstedt',
  Latitude: '537.843.490',
  Longitude: '97.882.030',
  Telephone: '412385138',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '53.784349,9.788203',
  id: '53.784349,9.788203'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 1',
  PostCode: 21709,
  City: 'Himmelpforten',
  Latitude: '536.100.390',
  Longitude: '93.138.230',
  Telephone: '4144210521',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.610039,9.313823',
  id: '53.610039,9.313823'
}, {
  Brand: 'ARAL',
  Street: 'Sprockhöveler Straße 147',
  PostCode: 58455,
  City: 'Witten',
  Latitude: '514.377.220',
  Longitude: '73.212.800',
  Telephone: '2302274691',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '51.437722,7.32128',
  id: '51.437722,7.32128'
}, {
  Brand: 'ARAL',
  Street: 'Astenbergstraße 2',
  PostCode: 57319,
  City: 'Bad Berleburg',
  Latitude: '510.566.540',
  Longitude: '83.929.030',
  Telephone: '2751892558',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.056654,8.392903',
  id: '51.056654,8.392903'
}, {
  Brand: 'ARAL',
  Street: 'Südring 285',
  PostCode: 45711,
  City: 'Datteln',
  Latitude: '516.557.550',
  Longitude: '73.519.150',
  Telephone: '23632297',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.655755,7.351915',
  id: '51.655755,7.351915'
}, {
  Brand: 'ARAL',
  Street: 'Im I. Westfeld 35',
  PostCode: 44388,
  City: 'Dortmund',
  Latitude: '514.970.460',
  Longitude: '73.328.390',
  Telephone: '231631156',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.497046,7.332839',
  id: '51.497046,7.332839'
}, {
  Brand: 'ARAL',
  Street: 'Wittener Straße 95',
  PostCode: 44575,
  City: 'Castrop-Rauxel',
  Latitude: '515.434.950',
  Longitude: '73.170.060',
  Telephone: '230523098',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '51.543495,7.317006',
  id: '51.543495,7.317006'
}, {
  Brand: 'ARAL',
  Street: 'Westfalendamm 166',
  PostCode: 44141,
  City: 'Dortmund',
  Latitude: '515.040.570',
  Longitude: '74.984.770',
  Telephone: '2319776560',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.504057,7.498477',
  id: '51.504057,7.498477'
}, {
  Brand: 'ARAL',
  Street: 'Wambeler Hellweg 129',
  PostCode: 44143,
  City: 'Dortmund',
  Latitude: '515.207.840',
  Longitude: '75.235.120',
  Telephone: '231597276',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.520784,7.523512',
  id: '51.520784,7.523512'
}, {
  Brand: 'ARAL',
  Street: 'Marienborner Straße 173',
  PostCode: 57074,
  City: 'Siegen',
  Latitude: '508.763.160',
  Longitude: '80.377.260',
  Telephone: '27162061',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:45',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:45',
  SundayFrom: '08:00',
  SundayTo: '21:45',
  IsOpen24Hours: 'Nein',
  location: '50.876316,8.037726',
  id: '50.876316,8.037726'
}, {
  Brand: 'ARAL',
  Street: 'Allendorfer Straße 41',
  PostCode: 59846,
  City: 'Sundern',
  Latitude: '512.848.780',
  Longitude: '79.543.610',
  Telephone: '2393321',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.284878,7.954361',
  id: '51.284878,7.954361'
}, {
  Brand: 'ARAL',
  Street: 'Hattinger Straße 451',
  PostCode: 44795,
  City: 'Bochum',
  Latitude: '514.433.690',
  Longitude: '71.940.650',
  Telephone: '234471650',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.443369,7.194065',
  id: '51.443369,7.194065'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Straße 22',
  PostCode: 58339,
  City: 'Breckerfeld',
  Latitude: '512.573.000',
  Longitude: '74.692.400',
  Telephone: '23389155480',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.2573,7.46924',
  id: '51.2573,7.46924'
}, {
  Brand: 'ARAL',
  Street: 'Lindemannstraße 65',
  PostCode: 44137,
  City: 'Dortmund',
  Latitude: '515.014.860',
  Longitude: '74.517.440',
  Telephone: '2319003692',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.501486,7.451744',
  id: '51.501486,7.451744'
}, {
  Brand: 'ARAL',
  Street: 'Weseler Straße 90',
  PostCode: 45721,
  City: 'Haltern',
  Latitude: '517.400.000',
  Longitude: '71.740.350',
  Telephone: '23643624',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.74,7.174035',
  id: '51.74,7.174035'
}, {
  Brand: 'ARAL',
  Street: 'Heedfelder Straße14',
  PostCode: 58579,
  City: 'Schalksmühle',
  Latitude: '512.560.030',
  Longitude: '75.836.290',
  Telephone: '23516759180',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.256003,7.583629',
  id: '51.256003,7.583629'
}, {
  Brand: 'ARAL',
  Street: 'Heinrich-Lübke-Straße 66',
  PostCode: 59759,
  City: 'Arnsberg',
  Latitude: '514.235.720',
  Longitude: '79.893.890',
  Telephone: '293231576',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.423572,7.989389',
  id: '51.423572,7.989389'
}, {
  Brand: 'ARAL',
  Street: 'Schaphusstraße 9',
  PostCode: 44359,
  City: 'Dortmund',
  Latitude: '515.737.480',
  Longitude: '73.871.330',
  Telephone: '231333191',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.573748,7.387133',
  id: '51.573748,7.387133'
}, {
  Brand: 'ARAL',
  Street: 'Martener Straße 276',
  PostCode: 44379,
  City: 'Dortmund',
  Latitude: '515.087.620',
  Longitude: '73.884.770',
  Telephone: '231613466',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.508762,7.388477',
  id: '51.508762,7.388477'
}, {
  Brand: 'ARAL',
  Street: 'Bochumer Straße 173',
  PostCode: 45661,
  City: 'Recklinghausen',
  Latitude: '515.676.000',
  Longitude: '72.118.700',
  Telephone: '236163663',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.5676,7.21187',
  id: '51.5676,7.21187'
}, {
  Brand: 'ARAL',
  Street: 'Barmer Straße 44',
  PostCode: 58332,
  City: 'Schwelm',
  Latitude: '512.806.050',
  Longitude: '72.786.960',
  Telephone: '23364089979',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.280605,7.278696',
  id: '51.280605,7.278696'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 78',
  PostCode: 59872,
  City: 'Meschede',
  Latitude: '513.652.440',
  Longitude: '81.725.380',
  Telephone: '290397120',
  WeekDayFrom: '04:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '04:30',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.365244,8.172538',
  id: '51.365244,8.172538'
}, {
  Brand: 'ARAL',
  Street: 'Eppenhauser Straße 134',
  PostCode: 58093,
  City: 'Hagen',
  Latitude: '513.609.600',
  Longitude: '75.062.920',
  Telephone: '23317878512',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.36096,7.506292',
  id: '51.36096,7.506292'
}, {
  Brand: 'ARAL',
  Street: 'Hagener Straße 10',
  PostCode: 57223,
  City: 'Kreuztal',
  Latitude: '509.609.270',
  Longitude: '79.888.810',
  Telephone: '2732765532',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.960927,7.988881',
  id: '50.960927,7.988881'
}, {
  Brand: 'ARAL',
  Street: 'Mindener Straße 14',
  PostCode: 59889,
  City: 'Eslohe',
  Latitude: '512.504.390',
  Longitude: '82.028.310',
  Telephone: '2973571',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.250439,8.202831',
  id: '51.250439,8.202831'
}, {
  Brand: 'ARAL',
  Street: 'Talstraße 161',
  PostCode: 58515,
  City: 'Lüdenscheid',
  Latitude: '512.055.440',
  Longitude: '75.826.530',
  Telephone: '235171177',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.205544,7.582653',
  id: '51.205544,7.582653'
}, {
  Brand: 'ARAL',
  Street: 'Wehrscheid 7',
  PostCode: 57392,
  City: 'Schmallenberg',
  Latitude: '511.851.180',
  Longitude: '83.136.420',
  Telephone: '297496660',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.185118,8.313642',
  id: '51.185118,8.313642'
}, {
  Brand: 'ARAL',
  Street: 'Bräukerweg 122 b',
  PostCode: 58708,
  City: 'Menden',
  Latitude: '514.374.250',
  Longitude: '77.614.290',
  Telephone: '237360775',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.437425,7.761429',
  id: '51.437425,7.761429'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 210',
  PostCode: 59846,
  City: 'Sundern',
  Latitude: '513.364.170',
  Longitude: '79.993.830',
  Telephone: '29339837150',
  WeekDayFrom: '05:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.336417,7.999383',
  id: '51.336417,7.999383'
}, {
  Brand: 'ARAL',
  Street: 'Hagener Straße 19',
  PostCode: 57234,
  City: 'Wilnsdorf',
  Latitude: '508.177.050',
  Longitude: '81.061.760',
  Telephone: '273987330',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.817705,8.106176',
  id: '50.817705,8.106176'
}, {
  Brand: 'ARAL',
  Street: 'Hundemstraße 75',
  PostCode: 57368,
  City: 'Lennestadt',
  Latitude: '511.011.670',
  Longitude: '80.709.880',
  Telephone: '27236200',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.101167,8.070988',
  id: '51.101167,8.070988'
}, {
  Brand: 'ARAL',
  Street: 'Altenaer Straße 29',
  PostCode: 58769,
  City: 'Nachrodt-Wiblingwerde',
  Latitude: '513.338.900',
  Longitude: '76.497.260',
  Telephone: '23523515',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.33389,7.649726',
  id: '51.33389,7.649726'
}, {
  Brand: 'ARAL',
  Street: 'Hörder Straße 89',
  PostCode: 58239,
  City: 'Schwerte',
  Latitude: '514.527.500',
  Longitude: '75.608.580',
  Telephone: '230412930',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.45275,7.560858',
  id: '51.45275,7.560858'
}, {
  Brand: 'ARAL',
  Street: 'Kölner Straße 154',
  PostCode: 58566,
  City: 'Kierspe',
  Latitude: '511.245.740',
  Longitude: '76.033.500',
  Telephone: '23592057',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.124574,7.60335',
  id: '51.124574,7.60335'
}, {
  Brand: 'ARAL',
  Street: 'Herdecker Bach 37 A',
  PostCode: 58313,
  City: 'Herdecke',
  Latitude: '514.155.100',
  Longitude: '74.322.010',
  Telephone: '23302878',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.41551,7.432201',
  id: '51.41551,7.432201'
}, {
  Brand: 'ARAL',
  Street: 'Fröndenberger Straße 291',
  PostCode: 58708,
  City: 'Menden',
  Latitude: '514.684.780',
  Longitude: '77.688.070',
  Telephone: '2373390839',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '51.468478,7.768807',
  id: '51.468478,7.768807'
}, {
  Brand: 'ARAL',
  Street: 'Siegstraße 43',
  PostCode: 57076,
  City: 'Siegen',
  Latitude: '508.999.100',
  Longitude: '80.343.910',
  Telephone: '27173043',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.89991,8.034391',
  id: '50.89991,8.034391'
}, {
  Brand: 'ARAL',
  Street: 'Eilper Straße 40',
  PostCode: 58091,
  City: 'Hagen',
  Latitude: '513.469.340',
  Longitude: '74.948.600',
  Telephone: '233177020',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.346934,7.49486',
  id: '51.346934,7.49486'
}, {
  Brand: 'ARAL',
  Street: 'Warsteiner Straße 46',
  PostCode: 59872,
  City: 'Meschede',
  Latitude: '513.529.730',
  Longitude: '82.814.120',
  Telephone: '2912306',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:59',
  SundayFrom: '08:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.352973,8.281412',
  id: '51.352973,8.281412'
}, {
  Brand: 'ARAL',
  Street: 'Möhneufer 2',
  PostCode: 59755,
  City: 'Arnsberg',
  Latitude: '514.563.610',
  Longitude: '79.617.090',
  Telephone: '293222223',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.456361,7.961709',
  id: '51.456361,7.961709'
}, {
  Brand: 'ARAL',
  Street: 'Westenfelder Straße 20',
  PostCode: 59846,
  City: 'Sundern',
  Latitude: '513.256.780',
  Longitude: '80.383.650',
  Telephone: '2933976818',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:30',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:30',
  SundayFrom: '07:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.325678,8.038365',
  id: '51.325678,8.038365'
}, {
  Brand: 'ARAL',
  Street: 'Koblenzer Straße 82',
  PostCode: 57072,
  City: 'Siegen',
  Latitude: '508.708.540',
  Longitude: '80.145.190',
  Telephone: '271331480',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.870854,8.014519',
  id: '50.870854,8.014519'
}, {
  Brand: 'ARAL',
  Street: 'Brackeler Hellweg 160',
  PostCode: 44309,
  City: 'Dortmund',
  Latitude: '515.239.900',
  Longitude: '75.515.520',
  Telephone: '231257429',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.52399,7.551552',
  id: '51.52399,7.551552'
}, {
  Brand: 'ARAL',
  Street: 'Hagener Straße 156',
  PostCode: 44225,
  City: 'Dortmund',
  Latitude: '514.673.940',
  Longitude: '74.602.690',
  Telephone: '231731257',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.467394,7.460269',
  id: '51.467394,7.460269'
}, {
  Brand: 'ARAL',
  Street: 'Marburger Straße 25',
  PostCode: 57339,
  City: 'Erndtebrück',
  Latitude: '509.910.460',
  Longitude: '82.589.310',
  Telephone: '27532085',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.991046,8.258931',
  id: '50.991046,8.258931'
}, {
  Brand: 'ARAL',
  Street: 'Hagener Straße 121',
  PostCode: 58099,
  City: 'Hagen',
  Latitude: '513.864.650',
  Longitude: '74.779.070',
  Telephone: '233161999',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.386465,7.477907',
  id: '51.386465,7.477907'
}, {
  Brand: 'ARAL',
  Street: 'Lichtendorfer Straße 136',
  PostCode: 58239,
  City: 'Schwerte',
  Latitude: '514.673.380',
  Longitude: '75.950.740',
  Telephone: '230440366',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.467338,7.595074',
  id: '51.467338,7.595074'
}, {
  Brand: 'ARAL',
  Street: 'Preußenstraße 49',
  PostCode: 44532,
  City: 'Lünen',
  Latitude: '515.927.560',
  Longitude: '75.482.830',
  Telephone: '230641069',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.592756,7.548283',
  id: '51.592756,7.548283'
}, {
  Brand: 'ARAL',
  Street: 'Alfredstraße 121',
  PostCode: 45131,
  City: 'Essen',
  Latitude: '514.320.690',
  Longitude: '70.019.870',
  Telephone: '201772604',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.432069,7.001987',
  id: '51.432069,7.001987'
}, {
  Brand: 'ARAL',
  Street: 'Dortmunder Straße 20',
  PostCode: 45665,
  City: 'Recklinghausen',
  Latitude: '516.129.160',
  Longitude: '72.047.190',
  Telephone: '23619378150',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.612916,7.204719',
  id: '51.612916,7.204719'
}, {
  Brand: 'ARAL',
  Street: 'Hermannstraße 100',
  PostCode: 45964,
  City: 'Gladbeck',
  Latitude: '515.746.730',
  Longitude: '69.820.060',
  Telephone: '204325991',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.574673,6.982006',
  id: '51.574673,6.982006'
}, {
  Brand: 'ARAL',
  Street: 'Steeler Straße 281',
  PostCode: 45138,
  City: 'Essen',
  Latitude: '514.472.000',
  Longitude: '70.458.700',
  Telephone: '201287957',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.4472,7.04587',
  id: '51.4472,7.04587'
}, {
  Brand: 'ARAL',
  Street: 'Holsterhauser Straße 2',
  PostCode: 45147,
  City: 'Essen',
  Latitude: '514.462.540',
  Longitude: '70.005.780',
  Telephone: '201777426',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.446254,7.000578',
  id: '51.446254,7.000578'
}, {
  Brand: 'ARAL',
  Street: 'Heisinger Straße 487',
  PostCode: 45259,
  City: 'Essen',
  Latitude: '514.044.540',
  Longitude: '70.654.550',
  Telephone: '201460863',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '19:00',
  SundayFrom: '10:00',
  SundayTo: '18:30',
  IsOpen24Hours: 'Nein',
  location: '51.404454,7.065455',
  id: '51.404454,7.065455'
}, {
  Brand: 'ARAL',
  Street: 'Kölner Straße 70',
  PostCode: 41334,
  City: 'Nettetal',
  Latitude: '513.108.360',
  Longitude: '62.048.540',
  Telephone: '21576169',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.310836,6.204854',
  id: '51.310836,6.204854'
}, {
  Brand: 'ARAL',
  Street: 'Hülser Straße 299',
  PostCode: 47803,
  City: 'Krefeld',
  Latitude: '513.479.650',
  Longitude: '65.434.260',
  Telephone: '2151753235',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.347965,6.543426',
  id: '51.347965,6.543426'
}, {
  Brand: 'ARAL',
  Street: 'Klosterstraße 1',
  PostCode: 41379,
  City: 'Brüggen',
  Latitude: '512.378.520',
  Longitude: '61.892.370',
  Telephone: '21635534',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.237852,6.189237',
  id: '51.237852,6.189237'
}, {
  Brand: 'ARAL',
  Street: 'Umgehungsstraße 1',
  PostCode: 47647,
  City: 'Kerken',
  Latitude: '514.426.200',
  Longitude: '64.143.140',
  Telephone: '28337594',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.44262,6.414314',
  id: '51.44262,6.414314'
}, {
  Brand: 'ARAL',
  Street: 'Krefelder Straße 10',
  PostCode: 47441,
  City: 'Moers',
  Latitude: '514.520.670',
  Longitude: '66.203.240',
  Telephone: '284129441',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.452067,6.620324',
  id: '51.452067,6.620324'
}, {
  Brand: 'ARAL',
  Street: 'Röntgenstraße 4',
  PostCode: 45470,
  City: 'Mülheim',
  Latitude: '514.174.800',
  Longitude: '68.954.280',
  Telephone: '208374546',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.41748,6.895428',
  id: '51.41748,6.895428'
}, {
  Brand: 'ARAL',
  Street: 'Klever Straße 85',
  PostCode: 47559,
  City: 'Kranenburg',
  Latitude: '517.834.020',
  Longitude: '60.226.680',
  Telephone: '2826437',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.783402,6.022668',
  id: '51.783402,6.022668'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 48',
  PostCode: 41366,
  City: 'Schwalmtal',
  Latitude: '512.327.080',
  Longitude: '62.401.230',
  Telephone: '216320442',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '51.232708,6.240123',
  id: '51.232708,6.240123'
}, {
  Brand: 'ARAL',
  Street: 'Schottelstraße55',
  PostCode: 47877,
  City: 'Willich',
  Latitude: '512.808.640',
  Longitude: '64.674.190',
  Telephone: '215691870',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.280864,6.467419',
  id: '51.280864,6.467419'
}, {
  Brand: 'ARAL',
  Street: 'Dorstener Straße  84',
  PostCode: 44809,
  City: 'Bochum',
  Latitude: '514.894.370',
  Longitude: '72.080.330',
  Telephone: '23495544430',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.489437,7.208033',
  id: '51.489437,7.208033'
}, {
  Brand: 'ARAL',
  Street: 'Bismarckstraße 280',
  PostCode: 45889,
  City: 'Gelsenkirchen',
  Latitude: '515.331.430',
  Longitude: '71.107.590',
  Telephone: '20985906',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.533143,7.110759',
  id: '51.533143,7.110759'
}, {
  Brand: 'ARAL',
  Street: 'Frintroper Straße 66-68',
  PostCode: 45359,
  City: 'Essen',
  Latitude: '514.626.750',
  Longitude: '69.415.570',
  Telephone: '201672659',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.462675,6.941557',
  id: '51.462675,6.941557'
}, {
  Brand: 'ARAL',
  Street: 'Klever Straße 165',
  PostCode: 47839,
  City: 'Krefeld',
  Latitude: '513.828.430',
  Longitude: '65.049.780',
  Telephone: '2151730295',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.382843,6.504978',
  id: '51.382843,6.504978'
}, {
  Brand: 'ARAL',
  Street: 'Viersener Straße 142',
  PostCode: 41751,
  City: 'Viersen',
  Latitude: '512.544.890',
  Longitude: '63.475.750',
  Telephone: '216242285',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.254489,6.347575',
  id: '51.254489,6.347575'
}, {
  Brand: 'ARAL',
  Street: 'Wankumer Straße 18',
  PostCode: 47669,
  City: 'Wachtendonk',
  Latitude: '514.068.560',
  Longitude: '63.270.470',
  Telephone: '28369726510',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.406856,6.327047',
  id: '51.406856,6.327047'
}, {
  Brand: 'ARAL',
  Street: 'Polsumer Straße 152',
  PostCode: 45896,
  City: 'Gelsenkirchen',
  Latitude: '515.978.560',
  Longitude: '70.515.300',
  Telephone: '209630444',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.597856,7.05153',
  id: '51.597856,7.05153'
}, {
  Brand: 'ARAL',
  Street: 'Wittener Straße 71',
  PostCode: 58456,
  City: 'Witten',
  Latitude: '514.180.750',
  Longitude: '72.738.760',
  Telephone: '2302760298',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.418075,7.273876',
  id: '51.418075,7.273876'
}, {
  Brand: 'ARAL',
  Street: 'Im Hammertal 1',
  PostCode: 58456,
  City: 'Witten',
  Latitude: '514.054.510',
  Longitude: '72.521.300',
  Telephone: '232430592',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.405451,7.25213',
  id: '51.405451,7.25213'
}, {
  Brand: 'ARAL',
  Street: 'Weseler Straße 90',
  PostCode: 47661,
  City: 'Issum',
  Latitude: '515.402.480',
  Longitude: '64.379.340',
  Telephone: '28353864',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.540248,6.437934',
  id: '51.540248,6.437934'
}, {
  Brand: 'ARAL',
  Street: 'Halterner Straße 17',
  PostCode: 45657,
  City: 'Recklinghausen',
  Latitude: '516.193.940',
  Longitude: '71.978.110',
  Telephone: '236126261',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.619394,7.197811',
  id: '51.619394,7.197811'
}, {
  Brand: 'ARAL',
  Street: 'Horster Straße 121',
  PostCode: 45897,
  City: 'Gelsenkirchen',
  Latitude: '515.677.120',
  Longitude: '70.468.760',
  Telephone: '209597722',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '51.567712,7.046876',
  id: '51.567712,7.046876'
}, {
  Brand: 'ARAL',
  Street: 'Königsallee 142',
  PostCode: 44789,
  City: 'Bochum',
  Latitude: '514.611.550',
  Longitude: '72.206.080',
  Telephone: '234337046',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.461155,7.220608',
  id: '51.461155,7.220608'
}, {
  Brand: 'ARAL',
  Street: 'Castroper Straße 190',
  PostCode: 44791,
  City: 'Bochum',
  Latitude: '514.907.160',
  Longitude: '72.415.190',
  Telephone: '234591182',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.490716,7.241519',
  id: '51.490716,7.241519'
}, {
  Brand: 'ARAL',
  Street: 'Rellinghauser Straße 312',
  PostCode: 45136,
  City: 'Essen',
  Latitude: '514.348.780',
  Longitude: '70.339.270',
  Telephone: '201250191',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.434878,7.033927',
  id: '51.434878,7.033927'
}, {
  Brand: 'ARAL',
  Street: 'Hölkeskampring 109',
  PostCode: 44625,
  City: 'Herne',
  Latitude: '515.333.120',
  Longitude: '72.348.710',
  Telephone: '232340037',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.533312,7.234871',
  id: '51.533312,7.234871'
}, {
  Brand: 'ARAL',
  Street: 'Alleestraße 79',
  PostCode: 44793,
  City: 'Bochum',
  Latitude: '514.793.280',
  Longitude: '72.047.100',
  Telephone: '23466401',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.479328,7.20471',
  id: '51.479328,7.20471'
}, {
  Brand: 'ARAL',
  Street: 'Niederrheinallee 48',
  PostCode: 47506,
  City: 'Neukirchen-Vluyn',
  Latitude: '514.466.970',
  Longitude: '65.768.520',
  Telephone: '28454465',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.446697,6.576852',
  id: '51.446697,6.576852'
}, {
  Brand: 'ARAL',
  Street: 'Brechtstraße 12',
  PostCode: 45739,
  City: 'Oer-Erkenschwick',
  Latitude: '516.405.790',
  Longitude: '72.547.540',
  Telephone: '23681536',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.640579,7.254754',
  id: '51.640579,7.254754'
}, {
  Brand: 'ARAL',
  Street: 'Krayer Straße 188',
  PostCode: 45307,
  City: 'Essen',
  Latitude: '514.620.590',
  Longitude: '70.788.260',
  Telephone: '201590516',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.462059,7.078826',
  id: '51.462059,7.078826'
}, {
  Brand: 'ARAL',
  Street: 'Bochumer Straße 177',
  PostCode: 45886,
  City: 'Gelsenkirchen',
  Latitude: '514.962.420',
  Longitude: '71.144.370',
  Telephone: '209204182',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.496242,7.114437',
  id: '51.496242,7.114437'
}, {
  Brand: 'ARAL',
  Street: 'Kemnader Straße328',
  PostCode: 44797,
  City: 'Bochum',
  Latitude: '514.282.530',
  Longitude: '72.437.800',
  Telephone: '234791759',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.428253,7.24378',
  id: '51.428253,7.24378'
}, {
  Brand: 'ARAL',
  Street: 'Brassertstraße 124',
  PostCode: 45768,
  City: 'Marl',
  Latitude: '516.631.600',
  Longitude: '70.805.400',
  Telephone: '236566398',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.66316,7.08054',
  id: '51.66316,7.08054'
}, {
  Brand: 'ARAL',
  Street: 'Ewaldstraße 81',
  PostCode: 45892,
  City: 'Gelsenkirchen',
  Latitude: '515.751.170',
  Longitude: '71.164.410',
  Telephone: '20975527',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:30',
  SundayFrom: '09:00',
  SundayTo: '21:30',
  IsOpen24Hours: 'Nein',
  location: '51.575117,7.116441',
  id: '51.575117,7.116441'
}, {
  Brand: 'ARAL',
  Street: 'Hans-Sachs-Straße 64',
  PostCode: 46236,
  City: 'Bottrop',
  Latitude: '515.303.230',
  Longitude: '69.245.940',
  Telephone: '20416505',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.530323,6.924594',
  id: '51.530323,6.924594'
}, {
  Brand: 'ARAL',
  Street: 'Brabanter Straße 85',
  PostCode: 41751,
  City: 'Viersen',
  Latitude: '512.609.190',
  Longitude: '63.348.660',
  Telephone: '216251369',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.260919,6.334866',
  id: '51.260919,6.334866'
}, {
  Brand: 'ARAL',
  Street: 'Friedrich-Ebert-Straße 117',
  PostCode: 47226,
  City: 'Duisburg',
  Latitude: '514.049.890',
  Longitude: '67.119.440',
  Telephone: '2065313826',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.404989,6.711944',
  id: '51.404989,6.711944'
}, {
  Brand: 'ARAL',
  Street: 'Triftstraße 118-120',
  PostCode: 47533,
  City: 'Kleve',
  Latitude: '517.737.850',
  Longitude: '61.321.180',
  Telephone: '282140960',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.773785,6.132118',
  id: '51.773785,6.132118'
}, {
  Brand: 'ARAL',
  Street: 'Ostring 201',
  PostCode: 46238,
  City: 'Bottrop',
  Latitude: '515.218.960',
  Longitude: '69.526.060',
  Telephone: '204163364',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.521896,6.952606',
  id: '51.521896,6.952606'
}, {
  Brand: 'ARAL',
  Street: 'Heidhauser Straße 1',
  PostCode: 45239,
  City: 'Essen',
  Latitude: '513.881.020',
  Longitude: '70.226.000',
  Telephone: '201404066',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.388102,7.0226',
  id: '51.388102,7.0226'
}, {
  Brand: 'ARAL',
  Street: 'Römerstraße 454',
  PostCode: 47441,
  City: 'Moers',
  Latitude: '514.438.920',
  Longitude: '66.610.560',
  Telephone: '284151105',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.443892,6.661056',
  id: '51.443892,6.661056'
}, {
  Brand: 'ARAL',
  Street: 'Holsterhauser Straße 301',
  PostCode: 44625,
  City: 'Herne',
  Latitude: '515.330.590',
  Longitude: '72.141.340',
  Telephone: '232354548',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.533059,7.214134',
  id: '51.533059,7.214134'
}, {
  Brand: 'ARAL',
  Street: 'Mülheimer Straße 278-284',
  PostCode: 46045,
  City: 'Oberhausen',
  Latitude: '514.669.880',
  Longitude: '68.646.040',
  Telephone: '2088830797',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.466988,6.864604',
  id: '51.466988,6.864604'
}, {
  Brand: 'ARAL',
  Street: 'Schermbecker Landstr. 34',
  PostCode: 46569,
  City: 'Hünxe',
  Latitude: '516.681.540',
  Longitude: '67.418.040',
  Telephone: '28582468',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.668154,6.741804',
  id: '51.668154,6.741804'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Straße 343',
  PostCode: 46562,
  City: 'Voerde',
  Latitude: '515.991.750',
  Longitude: '66.639.430',
  Telephone: '28556574',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:30',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:30',
  SundayFrom: '09:00',
  SundayTo: '21:30',
  IsOpen24Hours: 'Nein',
  location: '51.599175,6.663943',
  id: '51.599175,6.663943'
}, {
  Brand: 'ARAL',
  Street: 'Spellenerstraße 13',
  PostCode: 46562,
  City: 'Voerde',
  Latitude: '516.291.960',
  Longitude: '66.587.930',
  Telephone: '28141301',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.629196,6.658793',
  id: '51.629196,6.658793'
}, {
  Brand: 'ARAL',
  Street: 'Kupferdreher Straße 61',
  PostCode: 45257,
  City: 'Essen',
  Latitude: '513.968.360',
  Longitude: '70.854.880',
  Telephone: '201482034',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.396836,7.085488',
  id: '51.396836,7.085488'
}, {
  Brand: 'ARAL',
  Street: 'Teutoburger Straße 1-3',
  PostCode: 46145,
  City: 'Oberhausen',
  Latitude: '515.166.230',
  Longitude: '68.572.460',
  Telephone: '208667060',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.516623,6.857246',
  id: '51.516623,6.857246'
}, {
  Brand: 'ARAL',
  Street: 'Wüstrathstraße 6',
  PostCode: 47829,
  City: 'Krefeld',
  Latitude: '513.534.370',
  Longitude: '66.427.400',
  Telephone: '215146632',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '05:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.353437,6.64274',
  id: '51.353437,6.64274'
}, {
  Brand: 'ARAL',
  Street: 'Bochumer Straße 4',
  PostCode: 45549,
  City: 'Sprockhövel',
  Latitude: '513.668.400',
  Longitude: '72.523.760',
  Telephone: '232474188',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.36684,7.252376',
  id: '51.36684,7.252376'
}, {
  Brand: 'ARAL',
  Street: 'Hagener Straße 110-114',
  PostCode: 58285,
  City: 'Gevelsberg',
  Latitude: '513.251.260',
  Longitude: '73.530.920',
  Telephone: '2332609080',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.325126,7.353092',
  id: '51.325126,7.353092'
}, {
  Brand: 'ARAL',
  Street: 'Talstraße 10',
  PostCode: 58332,
  City: 'Schwelm',
  Latitude: '512.929.550',
  Longitude: '72.862.600',
  Telephone: '23363645',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.292955,7.28626',
  id: '51.292955,7.28626'
}, {
  Brand: 'ARAL',
  Street: 'Hüstener Straße 44',
  PostCode: 59821,
  City: 'Arnsberg',
  Latitude: '514.104.960',
  Longitude: '80.549.330',
  Telephone: '293177251',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.410496,8.054933',
  id: '51.410496,8.054933'
}, {
  Brand: 'ARAL',
  Street: 'A45',
  PostCode: 57258,
  City: 'Freudenberg',
  Latitude: '508.817.330',
  Longitude: '79.341.000',
  Telephone: '2734495780',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.881733,7.9341',
  id: '50.881733,7.9341'
}, {
  Brand: 'ARAL',
  Street: 'Werdohler Landstraße 4',
  PostCode: 58511,
  City: 'Lüdenscheid',
  Latitude: '512.285.900',
  Longitude: '76.514.200',
  Telephone: '235192730',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.22859,7.65142',
  id: '51.22859,7.65142'
}, {
  Brand: 'ARAL',
  Street: 'A45',
  PostCode: 58513,
  City: 'Lüdenscheid',
  Latitude: '512.222.140',
  Longitude: '76.690.950',
  Telephone: '23514328972',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.222214,7.669095',
  id: '51.222214,7.669095'
}, {
  Brand: 'ARAL',
  Street: 'Flughafenstraße 425',
  PostCode: 44328,
  City: 'Dortmund',
  Latitude: '515.491.280',
  Longitude: '75.385.850',
  Telephone: '231235201',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.549128,7.538585',
  id: '51.549128,7.538585'
}, {
  Brand: 'ARAL',
  Street: 'Dortmunder Straße 110',
  PostCode: 58638,
  City: 'Iserlohn',
  Latitude: '513.805.690',
  Longitude: '76.818.900',
  Telephone: '237127435',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.380569,7.68189',
  id: '51.380569,7.68189'
}, {
  Brand: 'ARAL',
  Street: 'Hohenlimburger Straße 191',
  PostCode: 58119,
  City: 'Hagen',
  Latitude: '513.559.590',
  Longitude: '75.561.660',
  Telephone: '233445186',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.355959,7.556166',
  id: '51.355959,7.556166'
}, {
  Brand: 'ARAL',
  Street: 'Brechtener Straße 26',
  PostCode: 44536,
  City: 'Lünen',
  Latitude: '515.928.670',
  Longitude: '74.400.230',
  Telephone: '231871659',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.592867,7.440023',
  id: '51.592867,7.440023'
}, {
  Brand: 'ARAL',
  Street: 'Bebelstraße 69 a',
  PostCode: 46049,
  City: 'Oberhausen',
  Latitude: '514.715.250',
  Longitude: '68.376.100',
  Telephone: '20825434',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.471525,6.83761',
  id: '51.471525,6.83761'
}, {
  Brand: 'ARAL',
  Street: 'Horster Straße 370',
  PostCode: 45968,
  City: 'Gladbeck',
  Latitude: '515.465.580',
  Longitude: '70.128.830',
  Telephone: '204334859',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.546558,7.012883',
  id: '51.546558,7.012883'
}, {
  Brand: 'ARAL',
  Street: 'Mülheimer Straße 155',
  PostCode: 46045,
  City: 'Oberhausen',
  Latitude: '514.727.610',
  Longitude: '68.635.270',
  Telephone: '20821691',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.472761,6.863527',
  id: '51.472761,6.863527'
}, {
  Brand: 'ARAL',
  Street: 'Krefelder Straße 101',
  PostCode: 41539,
  City: 'Dormagen',
  Latitude: '511.083.700',
  Longitude: '68.256.190',
  Telephone: '21333310',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.10837,6.825619',
  id: '51.10837,6.825619'
}, {
  Brand: 'ARAL',
  Street: 'Athener Ring 1',
  PostCode: 50765,
  City: 'Köln',
  Latitude: '510.191.990',
  Longitude: '68.965.810',
  Telephone: '2217089582',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.019199,6.896581',
  id: '51.019199,6.896581'
}, {
  Brand: 'ARAL',
  Street: 'Friedrich-Ebert-Str. 113',
  PostCode: 46236,
  City: 'Bottrop',
  Latitude: '515.199.130',
  Longitude: '69.311.380',
  Telephone: '204122389',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.519913,6.931138',
  id: '51.519913,6.931138'
}, {
  Brand: 'ARAL',
  Street: 'Lüdinghauserstr 60',
  PostCode: 59394,
  City: 'Nordkirchen',
  Latitude: '517.420.070',
  Longitude: '75.169.440',
  Telephone: '259698557',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:30',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.742007,7.516944',
  id: '51.742007,7.516944'
}, {
  Brand: 'ARAL',
  Street: 'Hülser Straße 45',
  PostCode: 47906,
  City: 'Kempen',
  Latitude: '513.645.530',
  Longitude: '64.306.040',
  Telephone: '21523439',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.364553,6.430604',
  id: '51.364553,6.430604'
}, {
  Brand: 'ARAL',
  Street: 'Siegesstraße 140',
  PostCode: 46147,
  City: 'Oberhausen',
  Latitude: '515.211.490',
  Longitude: '67.878.090',
  Telephone: '208688849',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.521149,6.787809',
  id: '51.521149,6.787809'
}, {
  Brand: 'ARAL',
  Street: 'Koblenzer Straße 171',
  PostCode: 53177,
  City: 'Bonn',
  Latitude: '506.776.110',
  Longitude: '71.685.000',
  Telephone: '228333097',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.677611,7.1685',
  id: '50.677611,7.1685'
}, {
  Brand: 'ARAL',
  Street: 'Roermonder Straße 25-29',
  PostCode: 52134,
  City: 'Herzogenrath',
  Latitude: '508.294.930',
  Longitude: '60.780.540',
  Telephone: '240718113',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.829493,6.078054',
  id: '50.829493,6.078054'
}, {
  Brand: 'ARAL',
  Street: 'Märkische Straße 29',
  PostCode: 42281,
  City: 'Wuppertal',
  Latitude: '512.847.640',
  Longitude: '72.081.790',
  Telephone: '202520277',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.284764,7.208179',
  id: '51.284764,7.208179'
}, {
  Brand: 'ARAL',
  Street: 'Am Hagenblech 60',
  PostCode: 59955,
  City: 'Winterberg',
  Latitude: '512.004.440',
  Longitude: '85.309.260',
  Telephone: '298192600',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.200444,8.530926',
  id: '51.200444,8.530926'
}, {
  Brand: 'ARAL',
  Street: 'Nordring 178',
  PostCode: 46325,
  City: 'Borken',
  Latitude: '518.446.970',
  Longitude: '68.430.550',
  Telephone: '286164950',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.844697,6.843055',
  id: '51.844697,6.843055'
}, {
  Brand: 'ARAL',
  Street: 'Kölner Straße 316',
  PostCode: 47807,
  City: 'Krefeld',
  Latitude: '513.134.770',
  Longitude: '65.795.830',
  Telephone: '2151315131',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '51.313477,6.579583',
  id: '51.313477,6.579583'
}, {
  Brand: 'ARAL',
  Street: 'Herner Straße 256',
  PostCode: 44809,
  City: 'Bochum',
  Latitude: '515.016.220',
  Longitude: '72.127.000',
  Telephone: '2349530941',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.501622,7.2127',
  id: '51.501622,7.2127'
}, {
  Brand: 'ARAL',
  Street: 'Friedrich-Ebert-Straße 14',
  PostCode: 45659,
  City: 'Recklinghausen',
  Latitude: '516.027.070',
  Longitude: '71.850.950',
  Telephone: '236126544',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.602707,7.185095',
  id: '51.602707,7.185095'
}, {
  Brand: 'ARAL',
  Street: 'Hafenstraße 43 A',
  PostCode: 47119,
  City: 'Duisburg',
  Latitude: '514.532.080',
  Longitude: '67.403.330',
  Telephone: '203873020',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.453208,6.740333',
  id: '51.453208,6.740333'
}, {
  Brand: 'ARAL',
  Street: 'Neustraße 19',
  PostCode: 58256,
  City: 'Ennepetal',
  Latitude: '512.968.700',
  Longitude: '73.604.760',
  Telephone: '233388816',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.29687,7.360476',
  id: '51.29687,7.360476'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 2',
  PostCode: 50169,
  City: 'Kerpen',
  Latitude: '509.242.500',
  Longitude: '67.054.490',
  Telephone: '22731002',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.92425,6.705449',
  id: '50.92425,6.705449'
}, {
  Brand: 'ARAL',
  Street: 'Frauenberger Straße 168',
  PostCode: 53879,
  City: 'Euskirchen',
  Latitude: '506.701.300',
  Longitude: '67.725.480',
  Telephone: '2251782560',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.67013,6.772548',
  id: '50.67013,6.772548'
}, {
  Brand: 'ARAL',
  Street: 'Overweg 33',
  PostCode: 59494,
  City: 'Soest',
  Latitude: '515.535.490',
  Longitude: '81.585.490',
  Telephone: '2921944064',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.553549,8.158549',
  id: '51.553549,8.158549'
}, {
  Brand: 'ARAL',
  Street: 'Untergath 210',
  PostCode: 47805,
  City: 'Krefeld',
  Latitude: '513.219.780',
  Longitude: '65.903.650',
  Telephone: '2151557780',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.321978,6.590365',
  id: '51.321978,6.590365'
}, {
  Brand: 'ARAL',
  Street: 'Mülheimer Straße 15',
  PostCode: 53840,
  City: 'Troisdorf',
  Latitude: '508.190.620',
  Longitude: '71.426.350',
  Telephone: '2241809505',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.819062,7.142635',
  id: '50.819062,7.142635'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Straße 96',
  PostCode: 44867,
  City: 'Bochum',
  Latitude: '514.652.610',
  Longitude: '71.310.560',
  Telephone: '2327939497',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.465261,7.131056',
  id: '51.465261,7.131056'
}, {
  Brand: 'ARAL',
  Street: 'Kardinal-Galen-Straße 106',
  PostCode: 47058,
  City: 'Duisburg',
  Latitude: '514.374.320',
  Longitude: '67.762.410',
  Telephone: '203331435',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.437432,6.776241',
  id: '51.437432,6.776241'
}, {
  Brand: 'ARAL',
  Street: 'Neuenkamper Straße 2-4',
  PostCode: 42855,
  City: 'Remscheid',
  Latitude: '511.770.400',
  Longitude: '72.115.030',
  Telephone: '2191387558',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.17704,7.211503',
  id: '51.17704,7.211503'
}, {
  Brand: 'ARAL',
  Street: 'Alter Deutzer Postweg 102',
  PostCode: 51149,
  City: 'Köln',
  Latitude: '509.186.210',
  Longitude: '70.493.320',
  Telephone: '2203371622',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.918621,7.049332',
  id: '50.918621,7.049332'
}, {
  Brand: 'ARAL',
  Street: 'Castroper Hellweg 111',
  PostCode: 44805,
  City: 'Bochum',
  Latitude: '515.016.910',
  Longitude: '72.586.430',
  Telephone: '2349508090',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.501691,7.258643',
  id: '51.501691,7.258643'
}, {
  Brand: 'ARAL',
  Street: 'Bürener Straße 155',
  PostCode: 59590,
  City: 'Geseke',
  Latitude: '515.977.610',
  Longitude: '85.157.200',
  Telephone: '2942574916',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.597761,8.51572',
  id: '51.597761,8.51572'
}, {
  Brand: 'ARAL',
  Street: 'Nürnberger Straße 33',
  PostCode: 40599,
  City: 'Düsseldorf',
  Latitude: '511.704.250',
  Longitude: '68.594.080',
  Telephone: '2119991725',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.170425,6.859408',
  id: '51.170425,6.859408'
}, {
  Brand: 'ARAL',
  Street: 'Wittgensteiner Straße 8 -',
  PostCode: 57271,
  City: 'Hilchenbach',
  Latitude: '509.868.850',
  Longitude: '81.020.470',
  Telephone: '27332686',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.986885,8.102047',
  id: '50.986885,8.102047'
}, {
  Brand: 'ARAL',
  Street: 'Siegener Straße 449',
  PostCode: 57258,
  City: 'Freudenberg - Linden',
  Latitude: '509.013.090',
  Longitude: '79.266.700',
  Telephone: '27347192',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.901309,7.92667',
  id: '50.901309,7.92667'
}, {
  Brand: 'ARAL',
  Street: 'Ernestinenstraße 157',
  PostCode: 45141,
  City: 'Essen',
  Latitude: '514.677.830',
  Longitude: '70.454.530',
  Telephone: '2012018242',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.467783,7.045453',
  id: '51.467783,7.045453'
}, {
  Brand: 'ARAL',
  Street: 'Oskar-Schindler-Straße 1',
  PostCode: 50769,
  City: 'Köln',
  Latitude: '510.329.670',
  Longitude: '69.199.210',
  Telephone: '22170995092',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.032967,6.919921',
  id: '51.032967,6.919921'
}, {
  Brand: 'ARAL',
  Street: 'Roermonder Straße 18',
  PostCode: 41836,
  City: 'Hückelhoven',
  Latitude: '510.527.150',
  Longitude: '62.173.740',
  Telephone: '2433938733',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.052715,6.217374',
  id: '51.052715,6.217374'
}, {
  Brand: 'ARAL',
  Street: 'Marburger Straße 61',
  PostCode: 35083,
  City: 'Wetter',
  Latitude: '509.009.840',
  Longitude: '87.362.790',
  Telephone: '642392110',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.900984,8.736279',
  id: '50.900984,8.736279'
}, {
  Brand: 'ARAL',
  Street: 'Widdersdorfer Straße 427-',
  PostCode: 50933,
  City: 'Köln',
  Latitude: '509.468.770',
  Longitude: '68.805.000',
  Telephone: '2215894077',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.946877,6.8805',
  id: '50.946877,6.8805'
}, {
  Brand: 'ARAL',
  Street: 'Espenhof 1',
  PostCode: 49549,
  City: 'Ladbergen',
  Latitude: '521.464.880',
  Longitude: '77.361.240',
  Telephone: '5485831620',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.146488,7.736124',
  id: '52.146488,7.736124'
}, {
  Brand: 'ARAL',
  Street: 'Balinger Straße 26',
  PostCode: 78727,
  City: 'Oberndorf',
  Latitude: '483.001.680',
  Longitude: '86.117.520',
  Telephone: '74233558',
  WeekDayFrom: '05:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.300168,8.611752',
  id: '48.300168,8.611752'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Straße 78',
  PostCode: 53757,
  City: 'Sankt Augustin',
  Latitude: '507.832.310',
  Longitude: '72.359.830',
  Telephone: '22411488093',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.783231,7.235983',
  id: '50.783231,7.235983'
}, {
  Brand: 'ARAL',
  Street: 'Hohenstaufenstraße 5',
  PostCode: 73033,
  City: 'Göppingen',
  Latitude: '487.028.370',
  Longitude: '96.617.010',
  Telephone: '716175454',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.702837,9.661701',
  id: '48.702837,9.661701'
}, {
  Brand: 'ARAL',
  Street: 'Wilhelm-Leuschner-Str. 14',
  PostCode: 74080,
  City: 'Heilbronn',
  Latitude: '491.414.430',
  Longitude: '91.963.020',
  Telephone: '713142113',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.141443,9.196302',
  id: '49.141443,9.196302'
}, {
  Brand: 'ARAL',
  Street: 'Reutlinger Straße 72',
  PostCode: 72072,
  City: 'Tübingen',
  Latitude: '485.129.520',
  Longitude: '90.736.250',
  Telephone: '707131941',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.512952,9.073625',
  id: '48.512952,9.073625'
}, {
  Brand: 'ARAL',
  Street: 'Waiblinger Straße 23-25',
  PostCode: 70372,
  City: 'Stuttgart',
  Latitude: '488.043.450',
  Longitude: '92.202.730',
  Telephone: '7119561039',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.804345,9.220273',
  id: '48.804345,9.220273'
}, {
  Brand: 'ARAL',
  Street: 'An der B 14/Sulzbach.-Eck',
  PostCode: 71560,
  City: 'Sulzbach',
  Latitude: '490.050.610',
  Longitude: '95.019.420',
  Telephone: '71936104',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.005061,9.501942',
  id: '49.005061,9.501942'
}, {
  Brand: 'ARAL',
  Street: 'Ringstraße 20',
  PostCode: 71364,
  City: 'Winnenden',
  Latitude: '488.783.290',
  Longitude: '93.976.370',
  Telephone: '7195946149',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.878329,9.397637',
  id: '48.878329,9.397637'
}, {
  Brand: 'ARAL',
  Street: 'Entenstraße 18',
  PostCode: 74199,
  City: 'Untergruppenbach',
  Latitude: '490.867.250',
  Longitude: '92.755.600',
  Telephone: '7131976124',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.086725,9.27556',
  id: '49.086725,9.27556'
}, {
  Brand: 'ARAL',
  Street: 'Neuenstädter Straße 93',
  PostCode: 74172,
  City: 'Neckarsulm',
  Latitude: '491.989.070',
  Longitude: '92.323.690',
  Telephone: '713217709',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.198907,9.232369',
  id: '49.198907,9.232369'
}, {
  Brand: 'ARAL',
  Street: 'Neue Unlinger St. 4',
  PostCode: 88499,
  City: 'Riedlingen',
  Latitude: '481.482.900',
  Longitude: '94.851.310',
  Telephone: '737110286',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.14829,9.485131',
  id: '48.14829,9.485131'
}, {
  Brand: 'ARAL',
  Street: 'Mergentheimer Straße 2/1',
  PostCode: 74653,
  City: 'Künzelsau',
  Latitude: '492.819.380',
  Longitude: '96.913.810',
  Telephone: '79402221',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.281938,9.691381',
  id: '49.281938,9.691381'
}, {
  Brand: 'ARAL',
  Street: 'Heilbronner Straße 17',
  PostCode: 74613,
  City: 'Öhringen',
  Latitude: '492.022.230',
  Longitude: '94.951.080',
  Telephone: '794134945',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.202223,9.495108',
  id: '49.202223,9.495108'
}, {
  Brand: 'ARAL',
  Street: 'Stuttgarter Straße 30',
  PostCode: 72172,
  City: 'Sulz',
  Latitude: '483.671.810',
  Longitude: '86.365.950',
  Telephone: '74542492',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:30',
  SundayFrom: '09:00',
  SundayTo: '20:30',
  IsOpen24Hours: 'Nein',
  location: '48.367181,8.636595',
  id: '48.367181,8.636595'
}, {
  Brand: 'ARAL',
  Street: 'Herbertinger Straße 1',
  PostCode: 88348,
  City: 'Bad Saulgau',
  Latitude: '480.191.100',
  Longitude: '94.970.700',
  Telephone: '7581488636',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:30',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '48.01911,9.49707',
  id: '48.01911,9.49707'
}, {
  Brand: 'ARAL',
  Street: 'Kochendorfer Straße 2',
  PostCode: 74196,
  City: 'Neuenstadt',
  Latitude: '492.365.690',
  Longitude: '93.246.720',
  Telephone: '71391382',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '19:00',
  SundayFrom: '09:00',
  SundayTo: '17:00',
  IsOpen24Hours: 'Nein',
  location: '49.236569,9.324672',
  id: '49.236569,9.324672'
}, {
  Brand: 'ARAL',
  Street: 'Leipheimer Straße 34',
  PostCode: 89233,
  City: 'Neu-Ulm',
  Latitude: '484.044.060',
  Longitude: '100.339.400',
  Telephone: '731711295',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '48.404406,10.03394',
  id: '48.404406,10.03394'
}, {
  Brand: 'ARAL',
  Street: 'Haller Straße 18',
  PostCode: 74248,
  City: 'Ellhofen',
  Latitude: '491.488.110',
  Longitude: '93.219.140',
  Telephone: '7134916854',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '09:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '49.148811,9.321914',
  id: '49.148811,9.321914'
}, {
  Brand: 'ARAL',
  Street: 'Crailsheimer Straße 9',
  PostCode: 74585,
  City: 'Rot am See',
  Latitude: '492.470.700',
  Longitude: '100.285.080',
  Telephone: '79552294',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '49.24707,10.028508',
  id: '49.24707,10.028508'
}, {
  Brand: 'ARAL',
  Street: 'Herdweg 52',
  PostCode: 74523,
  City: 'Schwäbisch Hall',
  Latitude: '490.932.790',
  Longitude: '98.384.560',
  Telephone: '79072277',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:30',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '49.093279,9.838456',
  id: '49.093279,9.838456'
}, {
  Brand: 'ARAL',
  Street: 'Ludwigsburger Straße 15',
  PostCode: 74343,
  City: 'Sachsenheim',
  Latitude: '489.596.290',
  Longitude: '90.689.520',
  Telephone: '71476360',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.959629,9.068952',
  id: '48.959629,9.068952'
}, {
  Brand: 'ARAL',
  Street: 'Böblinger Straße 16',
  PostCode: 71034,
  City: 'Böblingen',
  Latitude: '486.892.410',
  Longitude: '89.556.910',
  Telephone: '7031676363',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.689241,8.955691',
  id: '48.689241,8.955691'
}, {
  Brand: 'ARAL',
  Street: 'Leonberger Straße 76',
  PostCode: 71272,
  City: 'Renningen',
  Latitude: '487.679.750',
  Longitude: '89.442.950',
  Telephone: '7159800881',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.767975,8.944295',
  id: '48.767975,8.944295'
}, {
  Brand: 'ARAL',
  Street: 'Stuttgarter Straße 75',
  PostCode: 74348,
  City: 'Lauffen',
  Latitude: '490.703.170',
  Longitude: '91.580.540',
  Telephone: '71336000',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.070317,9.158054',
  id: '49.070317,9.158054'
}, {
  Brand: 'ARAL',
  Street: 'Gosbertstraße 24',
  PostCode: 72108,
  City: 'Rottenburg',
  Latitude: '484.956.690',
  Longitude: '88.130.090',
  Telephone: '745791655',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.495669,8.813009',
  id: '48.495669,8.813009'
}, {
  Brand: 'ARAL',
  Street: 'Jacob-Schüle-Straße 60',
  PostCode: 73655,
  City: 'Plüderhausen',
  Latitude: '488.018.360',
  Longitude: '95.917.050',
  Telephone: '71819249794',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.801836,9.591705',
  id: '48.801836,9.591705'
}, {
  Brand: 'ARAL',
  Street: 'Laizerstraße3',
  PostCode: 72488,
  City: 'Sigmaringen',
  Latitude: '480.868.570',
  Longitude: '92.119.070',
  Telephone: '75713198',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '48.086857,9.211907',
  id: '48.086857,9.211907'
}, {
  Brand: 'ARAL',
  Street: 'Neue Nördlinger Straße 3',
  PostCode: 73441,
  City: 'Bopfingen',
  Latitude: '488.588.050',
  Longitude: '103.560.120',
  Telephone: '73627055',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.858805,10.356012',
  id: '48.858805,10.356012'
}, {
  Brand: 'ARAL',
  Street: 'Sigmaringer Straße 40',
  PostCode: 72501,
  City: 'Gammertingen',
  Latitude: '482.461.590',
  Longitude: '92.228.720',
  Telephone: '75742282',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.246159,9.222872',
  id: '48.246159,9.222872'
}, {
  Brand: 'ARAL',
  Street: 'Ritterstraße14',
  PostCode: 73650,
  City: 'Winterbach',
  Latitude: '488.048.790',
  Longitude: '94.788.210',
  Telephone: '718141154',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '48.804879,9.478821',
  id: '48.804879,9.478821'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 30',
  PostCode: 88427,
  City: 'Bad Schussenried',
  Latitude: '480.002.510',
  Longitude: '96.601.360',
  Telephone: '75832413',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.000251,9.660136',
  id: '48.000251,9.660136'
}, {
  Brand: 'ARAL',
  Street: 'Neckarsulmer Straße 91',
  PostCode: 74076,
  City: 'Heilbronn',
  Latitude: '491.564.030',
  Longitude: '92.242.440',
  Telephone: '7131953061',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.156403,9.224244',
  id: '49.156403,9.224244'
}, {
  Brand: 'ARAL',
  Street: 'Ehinger Straße 11',
  PostCode: 89601,
  City: 'Schelklingen',
  Latitude: '483.708.290',
  Longitude: '97.326.290',
  Telephone: '73942346',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.370829,9.732629',
  id: '48.370829,9.732629'
}, {
  Brand: 'ARAL',
  Street: 'Schwieberdinger Straße 70',
  PostCode: 71636,
  City: 'Ludwigsburg',
  Latitude: '488.905.330',
  Longitude: '91.738.350',
  Telephone: '7141462453',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.890533,9.173835',
  id: '48.890533,9.173835'
}, {
  Brand: 'ARAL',
  Street: 'Siemensstraße 21',
  PostCode: 71254,
  City: 'Ditzingen',
  Latitude: '488.229.020',
  Longitude: '90.694.220',
  Telephone: '71566437',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.822902,9.069422',
  id: '48.822902,9.069422'
}, {
  Brand: 'ARAL',
  Street: 'Marbacher Straße 36',
  PostCode: 74385,
  City: 'Pleidelsheim',
  Latitude: '489.592.200',
  Longitude: '92.101.520',
  Telephone: '7144281777',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.95922,9.210152',
  id: '48.95922,9.210152'
}, {
  Brand: 'ARAL',
  Street: 'Kirchheimer Straße 108',
  PostCode: 70619,
  City: 'Stuttgart',
  Latitude: '487.419.360',
  Longitude: '92.173.380',
  Telephone: '7114799609',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.741936,9.217338',
  id: '48.741936,9.217338'
}, {
  Brand: 'ARAL',
  Street: 'Murgtalstraße 47',
  PostCode: 72270,
  City: 'Baiersbronn',
  Latitude: '485.124.950',
  Longitude: '83.771.710',
  Telephone: '74422455',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.512495,8.377171',
  id: '48.512495,8.377171'
}, {
  Brand: 'ARAL',
  Street: 'Ulmer Straße 13',
  PostCode: 89264,
  City: 'Weißenhorn',
  Latitude: '483.075.090',
  Longitude: '101.524.890',
  Telephone: '73092751',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.307509,10.152489',
  id: '48.307509,10.152489'
}, {
  Brand: 'ARAL',
  Street: 'Weilimdorfer Straße 25',
  PostCode: 70839,
  City: 'Gerlingen',
  Latitude: '488.009.040',
  Longitude: '90.668.100',
  Telephone: '715621349',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.800904,9.06681',
  id: '48.800904,9.06681'
}, {
  Brand: 'ARAL',
  Street: 'Wiener Straße 39',
  PostCode: 70469,
  City: 'Stuttgart',
  Latitude: '488.132.310',
  Longitude: '91.651.550',
  Telephone: '7111353230',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.813231,9.165155',
  id: '48.813231,9.165155'
}, {
  Brand: 'ARAL',
  Street: 'Esslinger Straße 43',
  PostCode: 73776,
  City: 'Altbach',
  Latitude: '487.236.480',
  Longitude: '93.777.170',
  Telephone: '715327370',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.723648,9.377717',
  id: '48.723648,9.377717'
}, {
  Brand: 'ARAL',
  Street: 'Wieslauftalstraße 72',
  PostCode: 73614,
  City: 'Schorndorf',
  Latitude: '488.269.940',
  Longitude: '95.499.570',
  Telephone: '71815423',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:30',
  SaturdayFrom: '06:00',
  SaturdayTo: '17:30',
  SundayFrom: '08:30',
  SundayTo: '13:00',
  IsOpen24Hours: 'Nein',
  location: '48.826994,9.549957',
  id: '48.826994,9.549957'
}, {
  Brand: 'ARAL',
  Street: 'Schillerstraße 34',
  PostCode: 73312,
  City: 'Geislingen',
  Latitude: '486.097.570',
  Longitude: '98.426.130',
  Telephone: '733142173',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.609757,9.842613',
  id: '48.609757,9.842613'
}, {
  Brand: 'ARAL',
  Street: 'Kemptener Straße 61',
  PostCode: 89250,
  City: 'Senden',
  Latitude: '483.202.870',
  Longitude: '100.496.290',
  Telephone: '73075217',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.320287,10.049629',
  id: '48.320287,10.049629'
}, {
  Brand: 'ARAL',
  Street: 'Stuttgarter Straße 67',
  PostCode: 71384,
  City: 'Weinstadt',
  Latitude: '488.053.270',
  Longitude: '93.819.610',
  Telephone: '715165446',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.805327,9.381961',
  id: '48.805327,9.381961'
}, {
  Brand: 'ARAL',
  Street: 'Hohenheimer Straße 49',
  PostCode: 70794,
  City: 'Filderstadt',
  Latitude: '486.593.770',
  Longitude: '91.977.650',
  Telephone: '7117705710',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.659377,9.197765',
  id: '48.659377,9.197765'
}, {
  Brand: 'ARAL',
  Street: 'Stuttgarter Straße 36',
  PostCode: 73614,
  City: 'Schorndorf',
  Latitude: '488.056.990',
  Longitude: '95.150.020',
  Telephone: '7181979888',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.805699,9.515002',
  id: '48.805699,9.515002'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 51',
  PostCode: 71706,
  City: 'Markgröningen',
  Latitude: '489.050.160',
  Longitude: '90.910.170',
  Telephone: '71454642',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.905016,9.091017',
  id: '48.905016,9.091017'
}, {
  Brand: 'ARAL',
  Street: 'Ludwigsburger Straße 20',
  PostCode: 71696,
  City: 'Möglingen',
  Latitude: '488.877.790',
  Longitude: '91.286.910',
  Telephone: '7141484326',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.887779,9.128691',
  id: '48.887779,9.128691'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 84',
  PostCode: 72411,
  City: 'Bodelshausen',
  Latitude: '483.873.210',
  Longitude: '89.790.320',
  Telephone: '74717511',
  WeekDayFrom: '05:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.387321,8.979032',
  id: '48.387321,8.979032'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 18',
  PostCode: 73072,
  City: 'Donzdorf',
  Latitude: '486.859.770',
  Longitude: '98.038.480',
  Telephone: '716229869',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.685977,9.803848',
  id: '48.685977,9.803848'
}, {
  Brand: 'ARAL',
  Street: 'Messkircher Straße 2a',
  PostCode: 72488,
  City: 'Sigmaringen',
  Latitude: '480.756.340',
  Longitude: '91.934.970',
  Telephone: '757151988',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.075634,9.193497',
  id: '48.075634,9.193497'
}, {
  Brand: 'ARAL',
  Street: 'Mahdentalstraße 86',
  PostCode: 71065,
  City: 'Sindelfingen',
  Latitude: '487.067.910',
  Longitude: '90.279.860',
  Telephone: '7031811966',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.706791,9.027986',
  id: '48.706791,9.027986'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Straße 55',
  PostCode: 72458,
  City: 'Albstadt',
  Latitude: '482.056.030',
  Longitude: '90.371.560',
  Telephone: '74312697',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.205603,9.037156',
  id: '48.205603,9.037156'
}, {
  Brand: 'ARAL',
  Street: 'Kiesstraße 4-6',
  PostCode: 74348,
  City: 'Lauffen',
  Latitude: '490.770.720',
  Longitude: '91.529.360',
  Telephone: '71337261',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '49.077072,9.152936',
  id: '49.077072,9.152936'
}, {
  Brand: 'ARAL',
  Street: 'Heilbronner Straße 70',
  PostCode: 74363,
  City: 'Güglingen',
  Latitude: '490.664.030',
  Longitude: '90.113.040',
  Telephone: '71358420',
  WeekDayFrom: '06:30',
  WeekDayTo: '19:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.066403,9.011304',
  id: '49.066403,9.011304'
}, {
  Brand: 'ARAL',
  Street: 'Marbacher Straße 21',
  PostCode: 71729,
  City: 'Erdmannhausen',
  Latitude: '489.418.890',
  Longitude: '92.911.320',
  Telephone: '71446651',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.941889,9.291132',
  id: '48.941889,9.291132'
}, {
  Brand: 'ARAL',
  Street: 'Lindauer Straße 50',
  PostCode: 88316,
  City: 'Isny',
  Latitude: '476.896.000',
  Longitude: '100.328.560',
  Telephone: '75628117',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.6896,10.032856',
  id: '47.6896,10.032856'
}, {
  Brand: 'ARAL',
  Street: 'Stuttgarter Straße43',
  PostCode: 71083,
  City: 'Herrenberg',
  Latitude: '486.005.530',
  Longitude: '88.730.950',
  Telephone: '703224366',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.600553,8.873095',
  id: '48.600553,8.873095'
}, {
  Brand: 'ARAL',
  Street: 'A8',
  PostCode: 89331,
  City: 'Burgau',
  Latitude: '484.130.790',
  Longitude: '104.284.640',
  Telephone: '82222439',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.413079,10.428464',
  id: '48.413079,10.428464'
}, {
  Brand: 'ARAL',
  Street: 'Stuttgarter Straße 97A',
  PostCode: 73430,
  City: 'Aalen',
  Latitude: '488.320.230',
  Longitude: '100.879.910',
  Telephone: '736164525',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.832023,10.087991',
  id: '48.832023,10.087991'
}, {
  Brand: 'ARAL',
  Street: 'Löchgauer Straße 77-79',
  PostCode: 74321,
  City: 'Bietigheim-Bissingen',
  Latitude: '489.621.100',
  Longitude: '91.219.240',
  Telephone: '7142987935',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.96211,9.121924',
  id: '48.96211,9.121924'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 159',
  PostCode: 70771,
  City: 'Leinfelden-Echterdin',
  Latitude: '486.967.060',
  Longitude: '91.666.950',
  Telephone: '711793779',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.696706,9.166695',
  id: '48.696706,9.166695'
}, {
  Brand: 'ARAL',
  Street: 'Haller Straße 5',
  PostCode: 74582,
  City: 'Gerabronn',
  Latitude: '492.478.070',
  Longitude: '99.200.460',
  Telephone: '79525059',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '49.247807,9.920046',
  id: '49.247807,9.920046'
}, {
  Brand: 'ARAL',
  Street: 'Appenweierer Straße 27',
  PostCode: 77704,
  City: 'Oberkirch',
  Latitude: '485.336.760',
  Longitude: '80.724.870',
  Telephone: '78024446',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.533676,8.072487',
  id: '48.533676,8.072487'
}, {
  Brand: 'ARAL',
  Street: 'Albstraße 82',
  PostCode: 72764,
  City: 'Reutlingen',
  Latitude: '484.850.590',
  Longitude: '92.225.000',
  Telephone: '7121270312',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.485059,9.2225',
  id: '48.485059,9.2225'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstr. 156',
  PostCode: 70563,
  City: 'Stuttgart',
  Latitude: '487.309.370',
  Longitude: '90.953.620',
  Telephone: '711731748',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.730937,9.095362',
  id: '48.730937,9.095362'
}, {
  Brand: 'ARAL',
  Street: 'Wilhelm-Kraut-Straße111',
  PostCode: 72336,
  City: 'Balingen',
  Latitude: '482.642.030',
  Longitude: '88.524.850',
  Telephone: '74334278',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.264203,8.852485',
  id: '48.264203,8.852485'
}, {
  Brand: 'ARAL',
  Street: 'Ernst-Haller-Straße 62',
  PostCode: 78647,
  City: 'Trossingen',
  Latitude: '480.667.720',
  Longitude: '86.390.780',
  Telephone: '74256197',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.066772,8.639078',
  id: '48.066772,8.639078'
}, {
  Brand: 'ARAL',
  Street: 'Nürtinger Straße 49',
  PostCode: 72649,
  City: 'Wolfschlugen',
  Latitude: '486.511.580',
  Longitude: '92.955.940',
  Telephone: '7022953580',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.651158,9.295594',
  id: '48.651158,9.295594'
}, {
  Brand: 'ARAL',
  Street: 'Bundesstraße 19A',
  PostCode: 77652,
  City: 'Offenburg',
  Latitude: '484.904.970',
  Longitude: '79.509.690',
  Telephone: '78125793',
  WeekDayFrom: '05:30',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:30',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.490497,7.950969',
  id: '48.490497,7.950969'
}, {
  Brand: 'ARAL',
  Street: 'Bestenheider Landstraße 7',
  PostCode: 97877,
  City: 'Wertheim',
  Latitude: '497.817.130',
  Longitude: '95.040.710',
  Telephone: '93429347282',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.781713,9.504071',
  id: '49.781713,9.504071'
}, {
  Brand: 'ARAL',
  Street: 'Berghauptener Str. 21',
  PostCode: 77723,
  City: 'Gengenbach',
  Latitude: '484.000.530',
  Longitude: '80.074.240',
  Telephone: '7803922517',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.400053,8.007424',
  id: '48.400053,8.007424'
}, {
  Brand: 'ARAL',
  Street: 'Vaihinger Straße 70',
  PostCode: 70567,
  City: 'Stuttgart',
  Latitude: '487.277.440',
  Longitude: '91.426.740',
  Telephone: '711719300',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.727744,9.142674',
  id: '48.727744,9.142674'
}, {
  Brand: 'ARAL',
  Street: 'Holger-Crafoord-Straße 1',
  PostCode: 72379,
  City: 'Hechingen',
  Latitude: '483.520.990',
  Longitude: '89.756.890',
  Telephone: '74714950',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.352099,8.975689',
  id: '48.352099,8.975689'
}, {
  Brand: 'ARAL',
  Street: 'Herrenberger Straße 50',
  PostCode: 71034,
  City: 'Böblingen',
  Latitude: '486.830.290',
  Longitude: '90.015.260',
  Telephone: '7031225353',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.683029,9.001526',
  id: '48.683029,9.001526'
}, {
  Brand: 'ARAL',
  Street: 'Raiffeisenstraße 1',
  PostCode: 89079,
  City: 'Ulm',
  Latitude: '483.582.420',
  Longitude: '99.702.910',
  Telephone: '73144488',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.358242,9.970291',
  id: '48.358242,9.970291'
}, {
  Brand: 'ARAL',
  Street: 'Nördlinger Straße 72',
  PostCode: 89520,
  City: 'Heidenheim',
  Latitude: '486.906.360',
  Longitude: '101.658.610',
  Telephone: '732125375',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.690636,10.165861',
  id: '48.690636,10.165861'
}, {
  Brand: 'ARAL',
  Street: 'Karlsruher Straße 26A',
  PostCode: 75179,
  City: 'Pforzheim',
  Latitude: '489.020.540',
  Longitude: '86.610.440',
  Telephone: '7231106050',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.902054,8.661044',
  id: '48.902054,8.661044'
}, {
  Brand: 'ARAL',
  Street: 'Neuenstädter Straße 96',
  PostCode: 74172,
  City: 'Neckarsulm',
  Latitude: '491.992.710',
  Longitude: '92.329.500',
  Telephone: '7132980222',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.199271,9.23295',
  id: '49.199271,9.23295'
}, {
  Brand: 'ARAL',
  Street: 'Schwieberdinger Straße 85',
  PostCode: 71636,
  City: 'Ludwigsburg',
  Latitude: '488.900.160',
  Longitude: '91.688.270',
  Telephone: '7141446250',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.890016,9.168827',
  id: '48.890016,9.168827'
}, {
  Brand: 'ARAL',
  Street: 'Heidenheimer Straße 116',
  PostCode: 73447,
  City: 'Oberkochen',
  Latitude: '487.756.760',
  Longitude: '100.988.910',
  Telephone: '7364921880',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:30',
  SaturdayFrom: '08:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.775676,10.098891',
  id: '48.775676,10.098891'
}, {
  Brand: 'ARAL',
  Street: 'Rudolf-Diesel-Str. 2.',
  PostCode: 85235,
  City: 'Odelzhausen',
  Latitude: '483.070.890',
  Longitude: '112.037.790',
  Telephone: '8134555673',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.307089,11.203779',
  id: '48.307089,11.203779'
}, {
  Brand: 'ARAL',
  Street: 'Jahnstraße 40',
  PostCode: 88214,
  City: 'Ravensburg',
  Latitude: '477.756.660',
  Longitude: '96.039.380',
  Telephone: '7513529743',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.775666,9.603938',
  id: '47.775666,9.603938'
}, {
  Brand: 'ARAL',
  Street: 'Nellinger Straße 24',
  PostCode: 89188,
  City: 'Merklingen',
  Latitude: '485.161.010',
  Longitude: '97.596.900',
  Telephone: '73379233029',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.516101,9.75969',
  id: '48.516101,9.75969'
}, {
  Brand: 'ARAL',
  Street: 'Robert-Bosch-Straße 9-11',
  PostCode: 72622,
  City: 'Nürtingen',
  Latitude: '486.156.390',
  Longitude: '93.315.260',
  Telephone: '70229211645',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '48.615639,9.331526',
  id: '48.615639,9.331526'
}, {
  Brand: 'ARAL',
  Street: 'Marie-Curie-Straße 2',
  PostCode: 73770,
  City: 'Denkendorf',
  Latitude: '487.072.590',
  Longitude: '93.055.270',
  Telephone: '7113482625',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.707259,9.305527',
  id: '48.707259,9.305527'
}, {
  Brand: 'ARAL',
  Street: 'Mercedesstraße 51',
  PostCode: 72108,
  City: 'Rottenburg/Ergenzing',
  Latitude: '485.053.050',
  Longitude: '88.267.440',
  Telephone: '7457931293',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.505305,8.826744',
  id: '48.505305,8.826744'
}, {
  Brand: 'ARAL',
  Street: 'Ulmer Straße 91',
  PostCode: 88471,
  City: 'Laupheim',
  Latitude: '482.375.380',
  Longitude: '98.822.250',
  Telephone: '7392168623',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.237538,9.882225',
  id: '48.237538,9.882225'
}, {
  Brand: 'ARAL',
  Street: 'Werderstraße 105',
  PostCode: 79379,
  City: 'Müllheim',
  Latitude: '478.090.640',
  Longitude: '76.395.960',
  Telephone: '76313882',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.809064,7.639596',
  id: '47.809064,7.639596'
}, {
  Brand: 'ARAL',
  Street: 'Zeppelinweg 2',
  PostCode: 89542,
  City: 'Herbrechtingen',
  Latitude: '486.276.760',
  Longitude: '101.871.800',
  Telephone: '73249833735',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.627676,10.18718',
  id: '48.627676,10.18718'
}, {
  Brand: 'ARAL',
  Street: 'Besanconallee 30',
  PostCode: 79111,
  City: 'Freiburg',
  Latitude: '479.906.620',
  Longitude: '77.974.610',
  Telephone: '76145366413',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.990662,7.797461',
  id: '47.990662,7.797461'
}, {
  Brand: 'ARAL',
  Street: 'An der Tagweide 2',
  PostCode: 76139,
  City: 'Karlsruhe',
  Latitude: '490.202.780',
  Longitude: '84.594.290',
  Telephone: '721616522',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '18:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.020278,8.459429',
  id: '49.020278,8.459429'
}, {
  Brand: 'ARAL',
  Street: 'Am Leineufer 52',
  PostCode: 30419,
  City: 'Hannover',
  Latitude: '524.185.320',
  Longitude: '96.353.810',
  Telephone: '511794933',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.418532,9.635381',
  id: '52.418532,9.635381'
}, {
  Brand: 'ARAL',
  Street: 'Hannoversche Straße 41-43',
  PostCode: 29664,
  City: 'Walsrode',
  Latitude: '528.547.270',
  Longitude: '95.964.440',
  Telephone: '51615971',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.854727,9.596444',
  id: '52.854727,9.596444'
}, {
  Brand: 'ARAL',
  Street: 'Schwarzer Weg 39',
  PostCode: 31224,
  City: 'Peine',
  Latitude: '523.304.120',
  Longitude: '102.349.470',
  Telephone: '51716108',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.330412,10.234947',
  id: '52.330412,10.234947'
}, {
  Brand: 'ARAL',
  Street: 'Düshorner Straße 2 D',
  PostCode: 29683,
  City: 'Fallingbostel',
  Latitude: '528.646.890',
  Longitude: '96.907.160',
  Telephone: '51622183',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.864689,9.690716',
  id: '52.864689,9.690716'
}, {
  Brand: 'ARAL',
  Street: 'Messeschnellweg-West',
  PostCode: 30521,
  City: 'Hannover',
  Latitude: '523.296.130',
  Longitude: '98.121.870',
  Telephone: '511871830',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.329613,9.812187',
  id: '52.329613,9.812187'
}, {
  Brand: 'ARAL',
  Street: 'Auf der Bünte 1',
  PostCode: 31737,
  City: 'Rinteln',
  Latitude: '521.943.340',
  Longitude: '90.813.670',
  Telephone: '57515275',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:30',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '22:30',
  IsOpen24Hours: 'Nein',
  location: '52.194334,9.081367',
  id: '52.194334,9.081367'
}, {
  Brand: 'ARAL',
  Street: 'Vornhäger Straße 35',
  PostCode: 31655,
  City: 'Stadthagen',
  Latitude: '523.279.770',
  Longitude: '92.173.700',
  Telephone: '57213360',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.327977,9.21737',
  id: '52.327977,9.21737'
}, {
  Brand: 'ARAL',
  Street: 'Iltener Straße 8',
  PostCode: 31319,
  City: 'Sehnde',
  Latitude: '523.173.620',
  Longitude: '99.599.050',
  Telephone: '51384746',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.317362,9.959905',
  id: '52.317362,9.959905'
}, {
  Brand: 'ARAL',
  Street: 'Alte Heerstraße 18',
  PostCode: 31863,
  City: 'Coppenbrügge',
  Latitude: '521.159.590',
  Longitude: '95.552.250',
  Telephone: '5156780080',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '52.115959,9.555225',
  id: '52.115959,9.555225'
}, {
  Brand: 'ARAL',
  Street: 'Burgdorfer Straße29',
  PostCode: 31311,
  City: 'Uetze',
  Latitude: '524.641.460',
  Longitude: '101.954.470',
  Telephone: '51734649064',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '52.464146,10.195447',
  id: '52.464146,10.195447'
}, {
  Brand: 'ARAL',
  Street: 'Robert-Koch-Straße 2',
  PostCode: 30900,
  City: 'Wedemark',
  Latitude: '525.770.710',
  Longitude: '97.281.230',
  Telephone: '513092910',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '52.577071,9.728123',
  id: '52.577071,9.728123'
}, {
  Brand: 'ARAL',
  Street: 'Hann. Heerstraße 44',
  PostCode: 29227,
  City: 'Celle',
  Latitude: '526.092.790',
  Longitude: '100.758.010',
  Telephone: '514124511',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.609279,10.075801',
  id: '52.609279,10.075801'
}, {
  Brand: 'ARAL',
  Street: 'Wunstorfer Straße 64',
  PostCode: 31535,
  City: 'Neustadt a. Rbge.',
  Latitude: '524.953.800',
  Longitude: '94.562.420',
  Telephone: '503263993',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.49538,9.456242',
  id: '52.49538,9.456242'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 25',
  PostCode: 31079,
  City: 'Sibbesse',
  Latitude: '520.548.750',
  Longitude: '99.028.590',
  Telephone: '5065415',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.054875,9.902859',
  id: '52.054875,9.902859'
}, {
  Brand: 'ARAL',
  Street: 'Göttinger Chaussee  80',
  PostCode: 30459,
  City: 'Hannover',
  Latitude: '523.457.380',
  Longitude: '97.169.030',
  Telephone: '511421873',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.345738,9.716903',
  id: '52.345738,9.716903'
}, {
  Brand: 'ARAL',
  Street: 'Deisterstraße 13',
  PostCode: 31785,
  City: 'Hameln',
  Latitude: '521.043.400',
  Longitude: '93.658.860',
  Telephone: '515141626',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.10434,9.365886',
  id: '52.10434,9.365886'
}, {
  Brand: 'ARAL',
  Street: 'Wagenzeller Straße 14',
  PostCode: 30855,
  City: 'Langenhagen',
  Latitude: '524.748.110',
  Longitude: '97.346.290',
  Telephone: '511778059',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.474811,9.734629',
  id: '52.474811,9.734629'
}, {
  Brand: 'ARAL',
  Street: 'Welseder Straße 20',
  PostCode: 31840,
  City: 'Hessisch Oldendorf',
  Latitude: '521.711.370',
  Longitude: '92.380.180',
  Telephone: '51524715',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.171137,9.238018',
  id: '52.171137,9.238018'
}, {
  Brand: 'ARAL',
  Street: 'Gerhardstraße 54',
  PostCode: 31241,
  City: 'Ilsede',
  Latitude: '522.631.900',
  Longitude: '102.206.370',
  Telephone: '51728760',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.26319,10.220637',
  id: '52.26319,10.220637'
}, {
  Brand: 'ARAL',
  Street: 'Gutenbergstraße 1',
  PostCode: 30966,
  City: 'Hemmingen',
  Latitude: '523.227.500',
  Longitude: '97.249.280',
  Telephone: '511423980',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.32275,9.724928',
  id: '52.32275,9.724928'
}, {
  Brand: 'ARAL',
  Street: 'Hamelner Straße23',
  PostCode: 31855,
  City: 'Aerzen',
  Latitude: '520.696.670',
  Longitude: '93.084.680',
  Telephone: '51547098328',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.069667,9.308468',
  id: '52.069667,9.308468'
}, {
  Brand: 'ARAL',
  Street: 'Otternhägener Straße 4-6',
  PostCode: 30826,
  City: 'Garbsen',
  Latitude: '524.617.990',
  Longitude: '95.342.440',
  Telephone: '513152737',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:55',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:55',
  SundayFrom: '08:00',
  SundayTo: '20:55',
  IsOpen24Hours: 'Nein',
  location: '52.461799,9.534244',
  id: '52.461799,9.534244'
}, {
  Brand: 'ARAL',
  Street: 'A7',
  PostCode: 29690,
  City: 'Essel',
  Latitude: '526.958.230',
  Longitude: '96.854.610',
  Telephone: '507196260',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.695823,9.685461',
  id: '52.695823,9.685461'
}, {
  Brand: 'ARAL',
  Street: 'Calenberger Allee 1',
  PostCode: 31020,
  City: 'Salzhemmendorf',
  Latitude: '520.662.050',
  Longitude: '95.847.560',
  Telephone: '51535262',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '52.066205,9.584756',
  id: '52.066205,9.584756'
}, {
  Brand: 'ARAL',
  Street: 'Bevenroder Straße 24',
  PostCode: 38108,
  City: 'Braunschweig',
  Latitude: '522.896.600',
  Longitude: '105.616.110',
  Telephone: '531371472',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.28966,10.561611',
  id: '52.28966,10.561611'
}, {
  Brand: 'ARAL',
  Street: 'Wolfsburger Straße 41',
  PostCode: 38448,
  City: 'Wolfsburg',
  Latitude: '524.382.750',
  Longitude: '108.303.180',
  Telephone: '536373136',
  WeekDayFrom: '04:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '04:30',
  SaturdayTo: '22:00',
  SundayFrom: '05:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.438275,10.830318',
  id: '52.438275,10.830318'
}, {
  Brand: 'ARAL',
  Street: 'Marientaler Straße 52 b',
  PostCode: 38350,
  City: 'Helmstedt',
  Latitude: '522.387.070',
  Longitude: '110.001.500',
  Telephone: '535134766',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.238707,11.00015',
  id: '52.238707,11.00015'
}, {
  Brand: 'ARAL',
  Street: 'Hannoversche Straße 52',
  PostCode: 30855,
  City: 'Langenhagen',
  Latitude: '524.365.440',
  Longitude: '96.774.260',
  Telephone: '511784433',
  WeekDayFrom: '05:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '52.436544,9.677426',
  id: '52.436544,9.677426'
}, {
  Brand: 'ARAL',
  Street: 'Fuhrberger Landstraße 39',
  PostCode: 29225,
  City: 'Celle',
  Latitude: '526.047.070',
  Longitude: '100.187.570',
  Telephone: '514141463',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.604707,10.018757',
  id: '52.604707,10.018757'
}, {
  Brand: 'ARAL',
  Street: 'Pyrmonter Straße 32',
  PostCode: 31789,
  City: 'Hameln',
  Latitude: '520.984.970',
  Longitude: '93.506.250',
  Telephone: '5151980890',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Ja',
  location: '52.098497,9.350625',
  id: '52.098497,9.350625'
}, {
  Brand: 'ARAL',
  Street: 'Dorfstraße 7',
  PostCode: 31303,
  City: 'Burgdorf',
  Latitude: '524.444.090',
  Longitude: '99.819.650',
  Telephone: '51363920',
  WeekDayFrom: '04:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.444409,9.981965',
  id: '52.444409,9.981965'
}, {
  Brand: 'ARAL',
  Street: 'Messeschnellweg Ost',
  PostCode: 30539,
  City: 'Hannover',
  Latitude: '523.228.200',
  Longitude: '98.141.340',
  Telephone: '511861335',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.32282,9.814134',
  id: '52.32282,9.814134'
}, {
  Brand: 'ARAL',
  Street: 'Auf der Horst 64',
  PostCode: 30823,
  City: 'Garbsen',
  Latitude: '524.212.190',
  Longitude: '95.954.940',
  Telephone: '513771234',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.421219,9.595494',
  id: '52.421219,9.595494'
}, {
  Brand: 'ARAL',
  Street: 'Celler Straße 57',
  PostCode: 29308,
  City: 'Winsen',
  Latitude: '526.764.500',
  Longitude: '99.200.860',
  Telephone: '51438187',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '52.67645,9.920086',
  id: '52.67645,9.920086'
}, {
  Brand: 'ARAL',
  Street: 'Lemmier Straße 16 A',
  PostCode: 30989,
  City: 'Gehrden',
  Latitude: '523.075.700',
  Longitude: '96.070.530',
  Telephone: '51084884',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.30757,9.607053',
  id: '52.30757,9.607053'
}, {
  Brand: 'ARAL',
  Street: 'Lehrter Str. 86 (B 65 Nor',
  PostCode: 30559,
  City: 'Hannover',
  Latitude: '523.581.180',
  Longitude: '98.618.770',
  Telephone: '511527428',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.358118,9.861877',
  id: '52.358118,9.861877'
}, {
  Brand: 'ARAL',
  Street: 'Hildesheimer Straße 32',
  PostCode: 38114,
  City: 'Braunschweig',
  Latitude: '522.718.820',
  Longitude: '104.962.790',
  Telephone: '53152826',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.271882,10.496279',
  id: '52.271882,10.496279'
}, {
  Brand: 'ARAL',
  Street: 'Heinr.-Nordhoff-Str. 117',
  PostCode: 38440,
  City: 'Wolfsburg',
  Latitude: '524.271.960',
  Longitude: '107.715.800',
  Telephone: '536113248',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.427196,10.77158',
  id: '52.427196,10.77158'
}, {
  Brand: 'ARAL',
  Street: 'Wolfenbütteler Straße 51',
  PostCode: 38124,
  City: 'Braunschweig',
  Latitude: '522.349.830',
  Longitude: '105.277.500',
  Telephone: '531601278',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.234983,10.52775',
  id: '52.234983,10.52775'
}, {
  Brand: 'ARAL',
  Street: 'Schwarzer Weg 1',
  PostCode: 38170,
  City: 'Schöppenstedt',
  Latitude: '521.518.310',
  Longitude: '107.688.430',
  Telephone: '53329464505',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.151831,10.768843',
  id: '52.151831,10.768843'
}, {
  Brand: 'ARAL',
  Street: 'Braunschweiger Straße 167',
  PostCode: 38259,
  City: 'Salzgitter',
  Latitude: '520.538.980',
  Longitude: '103.804.850',
  Telephone: '534131260',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.053898,10.380485',
  id: '52.053898,10.380485'
}, {
  Brand: 'ARAL',
  Street: 'Wolfshagener Straße 2a',
  PostCode: 38685,
  City: 'Langelsheim',
  Latitude: '519.334.470',
  Longitude: '103.457.520',
  Telephone: '532686086',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.933447,10.345752',
  id: '51.933447,10.345752'
}, {
  Brand: 'ARAL',
  Street: 'Nordhäuser Straße 3',
  PostCode: 38667,
  City: 'Bad Harzburg',
  Latitude: '518.688.330',
  Longitude: '105.594.350',
  Telephone: '53223180',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.868833,10.559435',
  id: '51.868833,10.559435'
}, {
  Brand: 'ARAL',
  Street: 'Salzdahlumer Straße 190',
  PostCode: 38126,
  City: 'Braunschweig',
  Latitude: '522.405.070',
  Longitude: '105.374.160',
  Telephone: '53166961',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.240507,10.537416',
  id: '52.240507,10.537416'
}, {
  Brand: 'ARAL',
  Street: 'Lafferder Straße 7',
  PostCode: 38268,
  City: 'Lengede',
  Latitude: '522.064.920',
  Longitude: '103.035.880',
  Telephone: '53447666',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:30',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:30',
  SundayFrom: '07:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.206492,10.303588',
  id: '52.206492,10.303588'
}, {
  Brand: 'ARAL',
  Street: 'Sackring 8',
  PostCode: 38118,
  City: 'Braunschweig',
  Latitude: '522.639.960',
  Longitude: '105.008.520',
  Telephone: '531577440',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '52.263996,10.500852',
  id: '52.263996,10.500852'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Straße 48',
  PostCode: 38165,
  City: 'Lehre',
  Latitude: '523.269.170',
  Longitude: '106.660.690',
  Telephone: '53081588',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '52.326917,10.666069',
  id: '52.326917,10.666069'
}, {
  Brand: 'ARAL',
  Street: 'Adersheimer Straße 25',
  PostCode: 38304,
  City: 'Wolfenbüttel',
  Latitude: '521.612.530',
  Longitude: '105.172.950',
  Telephone: '53314738',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.161253,10.517295',
  id: '52.161253,10.517295'
}, {
  Brand: 'ARAL',
  Street: 'Helmstedter Straße 33',
  PostCode: 38364,
  City: 'Schöningen',
  Latitude: '521.453.060',
  Longitude: '109.674.130',
  Telephone: '535259024',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.145306,10.967413',
  id: '52.145306,10.967413'
}, {
  Brand: 'ARAL',
  Street: 'Vor dem Dorfe 30',
  PostCode: 38229,
  City: 'Salzgitter',
  Latitude: '521.400.240',
  Longitude: '103.349.230',
  Telephone: '534141780',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.140024,10.334923',
  id: '52.140024,10.334923'
}, {
  Brand: 'ARAL',
  Street: 'Reiseckenweg 7',
  PostCode: 38640,
  City: 'Goslar',
  Latitude: '519.075.430',
  Longitude: '104.386.190',
  Telephone: '532122292',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '06:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.907543,10.438619',
  id: '51.907543,10.438619'
}, {
  Brand: 'ARAL',
  Street: 'B 65 -Südseite-',
  PostCode: 30559,
  City: 'Hannover',
  Latitude: '523.577.670',
  Longitude: '98.622.470',
  Telephone: '5119508955',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.357767,9.862247',
  id: '52.357767,9.862247'
}, {
  Brand: 'ARAL',
  Street: 'Kennedydamm 200 Ost',
  PostCode: 31135,
  City: 'Hildesheim',
  Latitude: '521.795.200',
  Longitude: '99.648.430',
  Telephone: '512155951',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.17952,9.964843',
  id: '52.17952,9.964843'
}, {
  Brand: 'ARAL',
  Street: 'A2',
  PostCode: 31749,
  City: 'Auetal',
  Latitude: '522.251.780',
  Longitude: '92.295.290',
  Telephone: '57529296620',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.225178,9.229529',
  id: '52.225178,9.229529'
}, {
  Brand: 'ARAL',
  Street: 'Veerser Straße 98',
  PostCode: 29525,
  City: 'Uelzen',
  Latitude: '529.576.350',
  Longitude: '105.511.730',
  Telephone: '58174212',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.957635,10.551173',
  id: '52.957635,10.551173'
}, {
  Brand: 'ARAL',
  Street: 'Bodenburger Straße 55',
  PostCode: 31162,
  City: 'Bad Salzdetfurth',
  Latitude: '520.542.950',
  Longitude: '100.027.970',
  Telephone: '50632573',
  WeekDayFrom: '05:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '52.054295,10.002797',
  id: '52.054295,10.002797'
}, {
  Brand: 'ARAL',
  Street: 'Dr.-Heinrich-Jasper-Str.',
  PostCode: 38667,
  City: 'Bad Harzburg',
  Latitude: '518.904.410',
  Longitude: '105.436.030',
  Telephone: '532286471',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.890441,10.543603',
  id: '51.890441,10.543603'
}, {
  Brand: 'ARAL',
  Street: 'Hildesheimer Straße 18',
  PostCode: 38640,
  City: 'Goslar',
  Latitude: '519.126.960',
  Longitude: '104.219.560',
  Telephone: '532122135',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.912696,10.421956',
  id: '51.912696,10.421956'
}, {
  Brand: 'ARAL',
  Street: 'Altenbekener Damm 3',
  PostCode: 30173,
  City: 'Hannover',
  Latitude: '523.578.440',
  Longitude: '97.675.140',
  Telephone: '511885849',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '52.357844,9.767514',
  id: '52.357844,9.767514'
}, {
  Brand: 'ARAL',
  Street: 'Am Welfenplatz 19',
  PostCode: 30161,
  City: 'Hannover',
  Latitude: '523.834.310',
  Longitude: '97.399.190',
  Telephone: '511314425',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '52.383431,9.739919',
  id: '52.383431,9.739919'
}, {
  Brand: 'ARAL',
  Street: 'Marienstraße 108',
  PostCode: 30171,
  City: 'Hannover',
  Latitude: '523.701.880',
  Longitude: '97.589.190',
  Telephone: '511858508',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.370188,9.758919',
  id: '52.370188,9.758919'
}, {
  Brand: 'ARAL',
  Street: 'Poststraße 5',
  PostCode: 38350,
  City: 'Helmstedt',
  Latitude: '522.243.470',
  Longitude: '110.138.800',
  Telephone: '53517442',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.224347,11.01388',
  id: '52.224347,11.01388'
}, {
  Brand: 'ARAL',
  Street: 'Alfelder Straße 99',
  PostCode: 31139,
  City: 'Hildesheim',
  Latitude: '521.472.310',
  Longitude: '99.385.220',
  Telephone: '512143480',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.147231,9.938522',
  id: '52.147231,9.938522'
}, {
  Brand: 'ARAL',
  Street: 'Allersheimer Straße 66',
  PostCode: 37603,
  City: 'Holzminden',
  Latitude: '518.365.180',
  Longitude: '94.635.700',
  Telephone: '553193510',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '51.836518,9.46357',
  id: '51.836518,9.46357'
}, {
  Brand: 'ARAL',
  Street: 'Burgdorfer Straße 115',
  PostCode: 31275,
  City: 'Lehrte',
  Latitude: '523.873.990',
  Longitude: '99.704.790',
  Telephone: '513282960',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '52.387399,9.970479',
  id: '52.387399,9.970479'
}, {
  Brand: 'ARAL',
  Street: 'Wolfsburger Straße 24',
  PostCode: 38554,
  City: 'Weyhausen',
  Latitude: '524.626.120',
  Longitude: '107.188.780',
  Telephone: '53627479',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.462612,10.718878',
  id: '52.462612,10.718878'
}, {
  Brand: 'ARAL',
  Street: 'Esterholzer Straße 2',
  PostCode: 29525,
  City: 'Uelzen',
  Latitude: '529.639.450',
  Longitude: '105.672.740',
  Telephone: '58173820',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '22:30',
  SundayFrom: '07:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.963945,10.567274',
  id: '52.963945,10.567274'
}, {
  Brand: 'ARAL',
  Street: 'Dahlenburg. Landstraße 34',
  PostCode: 21337,
  City: 'Lüneburg',
  Latitude: '532.442.340',
  Longitude: '104.445.900',
  Telephone: '413151560',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.244234,10.44459',
  id: '53.244234,10.44459'
}, {
  Brand: 'ARAL',
  Street: 'Bültenweg 40',
  PostCode: 38106,
  City: 'Braunschweig',
  Latitude: '522.814.980',
  Longitude: '105.369.070',
  Telephone: '531347887',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.281498,10.536907',
  id: '52.281498,10.536907'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 58',
  PostCode: 33102,
  City: 'Paderborn',
  Latitude: '517.126.690',
  Longitude: '87.363.300',
  Telephone: '525137111',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.712669,8.73633',
  id: '51.712669,8.73633'
}, {
  Brand: 'ARAL',
  Street: 'Hannoversche Straße 142',
  PostCode: 28309,
  City: 'Bremen',
  Latitude: '530.484.270',
  Longitude: '88.915.300',
  Telephone: '421452290',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.048427,8.89153',
  id: '53.048427,8.89153'
}, {
  Brand: 'ARAL',
  Street: 'Habichtstraße 72-78',
  PostCode: 22305,
  City: 'Hamburg',
  Latitude: '535.923.070',
  Longitude: '100.549.160',
  Telephone: '40611041',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.592307,10.054916',
  id: '53.592307,10.054916'
}, {
  Brand: 'ARAL',
  Street: 'Stapelfeldtstraße 4',
  PostCode: 28237,
  City: 'Bremen',
  Latitude: '531.110.030',
  Longitude: '87.532.710',
  Telephone: '4216163511',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.111003,8.753271',
  id: '53.111003,8.753271'
}, {
  Brand: 'ARAL',
  Street: 'Herforder Str. 147 - 151',
  PostCode: 32257,
  City: 'Bünde',
  Latitude: '521.849.920',
  Longitude: '86.042.730',
  Telephone: '5223878088',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.184992,8.604273',
  id: '52.184992,8.604273'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 205',
  PostCode: 26639,
  City: 'Wiesmoor',
  Latitude: '534.094.120',
  Longitude: '77.279.030',
  Telephone: '49445150',
  WeekDayFrom: '04:30',
  WeekDayTo: '23:00',
  SaturdayFrom: '04:30',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '53.409412,7.727903',
  id: '53.409412,7.727903'
}, {
  Brand: 'ARAL',
  Street: 'Vahrenwalder Str. 323',
  PostCode: 30851,
  City: 'Langenhagen',
  Latitude: '524.238.510',
  Longitude: '97.323.020',
  Telephone: '511632212',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.423851,9.732302',
  id: '52.423851,9.732302'
}, {
  Brand: 'ARAL',
  Street: 'Albaxer Straße 63',
  PostCode: 37671,
  City: 'Höxter',
  Latitude: '517.860.200',
  Longitude: '93.870.160',
  Telephone: '5271920071',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.78602,9.387016',
  id: '51.78602,9.387016'
}, {
  Brand: 'ARAL',
  Street: 'Westerlange 2',
  PostCode: 37154,
  City: 'Northeim',
  Latitude: '517.309.020',
  Longitude: '99.728.270',
  Telephone: '5551909730',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.730902,9.972827',
  id: '51.730902,9.972827'
}, {
  Brand: 'ARAL',
  Street: 'B65 / Hauptstraße 1',
  PostCode: 31683,
  City: 'Obernkirchen',
  Latitude: '522.842.530',
  Longitude: '91.343.290',
  Telephone: '5724902984',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.284253,9.134329',
  id: '52.284253,9.134329'
}, {
  Brand: 'ARAL',
  Street: 'Kirchhorster Straße 24',
  PostCode: 30659,
  City: 'Hannover',
  Latitude: '524.143.720',
  Longitude: '98.261.680',
  Telephone: '5116139075',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.414372,9.826168',
  id: '52.414372,9.826168'
}, {
  Brand: 'ARAL',
  Street: 'Friedrich-Tietjen-Str. 15',
  PostCode: 27232,
  City: 'Sulingen',
  Latitude: '526.857.600',
  Longitude: '87.830.400',
  Telephone: '427193190',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.68576,8.78304',
  id: '52.68576,8.78304'
}, {
  Brand: 'ARAL',
  Street: 'Mindener Straße 17',
  PostCode: 32602,
  City: 'Vlotho',
  Latitude: '521.739.330',
  Longitude: '88.685.170',
  Telephone: '573380813',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.173933,8.868517',
  id: '52.173933,8.868517'
}, {
  Brand: 'ARAL',
  Street: 'Vördener Straße 23',
  PostCode: 49401,
  City: 'Damme',
  Latitude: '525.156.050',
  Longitude: '81.869.320',
  Telephone: '54915412',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '52.515605,8.186932',
  id: '52.515605,8.186932'
}, {
  Brand: 'ARAL',
  Street: 'Bielefelder Straße 56',
  PostCode: 32051,
  City: 'Herford',
  Latitude: '521.099.470',
  Longitude: '86.580.770',
  Telephone: '5221169742',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.109947,8.658077',
  id: '52.109947,8.658077'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 48',
  PostCode: 49439,
  City: 'Steinfeld',
  Latitude: '525.902.030',
  Longitude: '82.032.030',
  Telephone: '54927608',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '52.590203,8.203203',
  id: '52.590203,8.203203'
}, {
  Brand: 'ARAL',
  Street: 'Petershütter Allee 2',
  PostCode: 37520,
  City: 'Osterode',
  Latitude: '517.324.110',
  Longitude: '102.389.260',
  Telephone: '5522317986',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.732411,10.238926',
  id: '51.732411,10.238926'
}, {
  Brand: 'ARAL',
  Street: 'Borchener Straße336',
  PostCode: 33106,
  City: 'Paderborn',
  Latitude: '51.683.275',
  Longitude: '8.721.109',
  Telephone: '05251-892905',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.683275,8.721109',
  id: '51.683275,8.721109'
}, {
  Brand: 'ARAL',
  Street: 'Hansestraße 47 A',
  PostCode: 38112,
  City: 'Braunschweig',
  Latitude: '523.220.110',
  Longitude: '104.788.150',
  Telephone: '5313102198',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.322011,10.478815',
  id: '52.322011,10.478815'
}, {
  Brand: 'ARAL',
  Street: 'Leipziger Straße 201',
  PostCode: 37235,
  City: 'Hessisch Lichtenau',
  Latitude: '511.981.170',
  Longitude: '97.460.100',
  Telephone: '5602914088',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.198117,9.74601',
  id: '51.198117,9.74601'
}, {
  Brand: 'ARAL',
  Street: 'Am Mühlenhop  1',
  PostCode: 38154,
  City: 'Königslutter',
  Latitude: '523.115.570',
  Longitude: '108.238.850',
  Telephone: '5365941703',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.311557,10.823885',
  id: '52.311557,10.823885'
}, {
  Brand: 'ARAL',
  Street: 'Lüneburger Straße 14a',
  PostCode: 30880,
  City: 'Laatzen',
  Latitude: '522.941.300',
  Longitude: '98.254.850',
  Telephone: '5102916693',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.29413,9.825485',
  id: '52.29413,9.825485'
}, {
  Brand: 'ARAL',
  Street: 'Leuschner Straße 81',
  PostCode: 34134,
  City: 'Kassel',
  Latitude: '512.912.700',
  Longitude: '94.556.900',
  Telephone: '561402572',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.29127,9.45569',
  id: '51.29127,9.45569'
}, {
  Brand: 'ARAL',
  Street: 'Oberrege 6',
  PostCode: 26931,
  City: 'Elsfleth',
  Latitude: '532.355.360',
  Longitude: '84.556.680',
  Telephone: '4404951488',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.235536,8.455668',
  id: '53.235536,8.455668'
}, {
  Brand: 'ARAL',
  Street: 'Sieker Landstraße 128',
  PostCode: 22143,
  City: 'Hamburg',
  Latitude: '536.085.490',
  Longitude: '101.907.140',
  Telephone: '4067563128',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.608549,10.190714',
  id: '53.608549,10.190714'
}, {
  Brand: 'ARAL',
  Street: 'An der B5 3',
  PostCode: 25920,
  City: 'Risum-Lindholm',
  Latitude: '547.601.990',
  Longitude: '88.780.960',
  Telephone: '4661941159',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '54.760199,8.878096',
  id: '54.760199,8.878096'
}, {
  Brand: 'ARAL',
  Street: 'Korbacherstraße 2',
  PostCode: 34454,
  City: 'Bad Arolsen',
  Latitude: '513.811.580',
  Longitude: '89.989.410',
  Telephone: '56913584',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.381158,8.998941',
  id: '51.381158,8.998941'
}, {
  Brand: 'ARAL',
  Street: 'Schwanallee 56',
  PostCode: 35037,
  City: 'Marburg',
  Latitude: '507.991.010',
  Longitude: '87.624.290',
  Telephone: '6421167089',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.799101,8.762429',
  id: '50.799101,8.762429'
}, {
  Brand: 'ARAL',
  Street: 'Bredelarer Straße 50',
  PostCode: 34431,
  City: 'Marsberg',
  Latitude: '514.554.810',
  Longitude: '88.406.550',
  Telephone: '29928040',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.455481,8.840655',
  id: '51.455481,8.840655'
}, {
  Brand: 'ARAL',
  Street: 'Briloner Straße 47',
  PostCode: 34508,
  City: 'Willingen',
  Latitude: '512.939.360',
  Longitude: '86.047.940',
  Telephone: '56326397',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '08:30',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '51.293936,8.604794',
  id: '51.293936,8.604794'
}, {
  Brand: 'ARAL',
  Street: 'Burger Hauptstraße 10-12',
  PostCode: 35745,
  City: 'Herborn-Burg',
  Latitude: '506.935.250',
  Longitude: '83.027.550',
  Telephone: '27722675',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.693525,8.302755',
  id: '50.693525,8.302755'
}, {
  Brand: 'ARAL',
  Street: 'Königsallee 245',
  PostCode: 37079,
  City: 'Göttingen',
  Latitude: '515.487.550',
  Longitude: '99.094.770',
  Telephone: '551624459',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.548755,9.909477',
  id: '51.548755,9.909477'
}, {
  Brand: 'ARAL',
  Street: 'Giessener Straße 29',
  PostCode: 35423,
  City: 'Lich',
  Latitude: '505.245.350',
  Longitude: '88.174.280',
  Telephone: '6404950220',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.524535,8.817428',
  id: '50.524535,8.817428'
}, {
  Brand: 'ARAL',
  Street: 'Niestetalstraße 10',
  PostCode: 34266,
  City: 'Niestetal',
  Latitude: '513.091.050',
  Longitude: '95.706.910',
  Telephone: '5619209547',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.309105,9.570691',
  id: '51.309105,9.570691'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 32',
  PostCode: 34593,
  City: 'Knüllwald',
  Latitude: '510.066.370',
  Longitude: '94.729.940',
  Telephone: '56812872',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.006637,9.472994',
  id: '51.006637,9.472994'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 85',
  PostCode: 35236,
  City: 'Breidenbach',
  Latitude: '508.901.150',
  Longitude: '84.532.100',
  Telephone: '6465913004',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:30',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.890115,8.45321',
  id: '50.890115,8.45321'
}, {
  Brand: 'ARAL',
  Street: 'Korbacher Straße 24',
  PostCode: 34513,
  City: 'Waldeck-Sachsenhause',
  Latitude: '512.467.950',
  Longitude: '90.019.470',
  Telephone: '56341260',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.246795,9.001947',
  id: '51.246795,9.001947'
}, {
  Brand: 'ARAL',
  Street: 'Hannoversche Str. 45',
  PostCode: 37075,
  City: 'Göttingen',
  Latitude: '515.540.090',
  Longitude: '99.309.940',
  Telephone: '55132518',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.554009,9.930994',
  id: '51.554009,9.930994'
}, {
  Brand: 'ARAL',
  Street: 'Ederstraße 18',
  PostCode: 35066,
  City: 'Frankenberg',
  Latitude: '510.642.710',
  Longitude: '88.046.000',
  Telephone: '6451717147',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.064271,8.8046',
  id: '51.064271,8.8046'
}, {
  Brand: 'ARAL',
  Street: 'Grünberger Straße 40-42',
  PostCode: 35394,
  City: 'Gießen',
  Latitude: '505.840.690',
  Longitude: '86.873.860',
  Telephone: '6419433030',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.584069,8.687386',
  id: '50.584069,8.687386'
}, {
  Brand: 'ARAL',
  Street: 'Nürnberger Straße 106',
  PostCode: 34123,
  City: 'Kassel',
  Latitude: '512.944.640',
  Longitude: '95.116.550',
  Telephone: '56159182',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.294464,9.511655',
  id: '51.294464,9.511655'
}, {
  Brand: 'ARAL',
  Street: 'Schlitzer Straße 105',
  PostCode: 36039,
  City: 'Fulda',
  Latitude: '505.655.280',
  Longitude: '96.572.950',
  Telephone: '66151681',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.565528,9.657295',
  id: '50.565528,9.657295'
}, {
  Brand: 'ARAL',
  Street: 'Löhrstraße 6',
  PostCode: 35708,
  City: 'Haiger',
  Latitude: '507.415.880',
  Longitude: '82.076.490',
  Telephone: '27735895',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.741588,8.207649',
  id: '50.741588,8.207649'
}, {
  Brand: 'ARAL',
  Street: 'Herzberger Landstraße 3',
  PostCode: 37434,
  City: 'Gieboldehausen',
  Latitude: '516.156.220',
  Longitude: '102.186.760',
  Telephone: '5528200891',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.615622,10.218676',
  id: '51.615622,10.218676'
}, {
  Brand: 'ARAL',
  Street: 'Hedemündener Straße 10',
  PostCode: 34346,
  City: 'Hann. Münden',
  Latitude: '514.178.400',
  Longitude: '96.677.290',
  Telephone: '55411859',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.41784,9.667729',
  id: '51.41784,9.667729'
}, {
  Brand: 'ARAL',
  Street: 'Geismarlandstraße 63',
  PostCode: 37083,
  City: 'Göttingen',
  Latitude: '515.216.220',
  Longitude: '99.509.260',
  Telephone: '55173348',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '21:00',
  SundayFrom: '07:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.521622,9.950926',
  id: '51.521622,9.950926'
}, {
  Brand: 'ARAL',
  Street: 'Alsfelder Straße 12',
  PostCode: 36251,
  City: 'Bad Hersfeld',
  Latitude: '508.361.740',
  Longitude: '96.686.640',
  Telephone: '662172294',
  WeekDayFrom: '05:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.836174,9.668664',
  id: '50.836174,9.668664'
}, {
  Brand: 'ARAL',
  Street: 'Karl-Kellner-Ring 2',
  PostCode: 35576,
  City: 'Wetzlar',
  Latitude: '505.535.140',
  Longitude: '84.980.470',
  Telephone: '6441921390',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.553514,8.498047',
  id: '50.553514,8.498047'
}, {
  Brand: 'ARAL',
  Street: 'Grenzweg 2',
  PostCode: 35415,
  City: 'Pohlheim',
  Latitude: '505.416.960',
  Longitude: '87.450.630',
  Telephone: '64145427',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '18:00',
  SundayFrom: '09:00',
  SundayTo: '13:00',
  IsOpen24Hours: 'Nein',
  location: '50.541696,8.745063',
  id: '50.541696,8.745063'
}, {
  Brand: 'ARAL',
  Street: 'Marburger Straße 28',
  PostCode: 35315,
  City: 'Homberg',
  Latitude: '507.308.890',
  Longitude: '89.946.600',
  Telephone: '6633363',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.730889,8.99466',
  id: '50.730889,8.99466'
}, {
  Brand: 'ARAL',
  Street: 'Marburger Str. 15',
  PostCode: 35745,
  City: 'Herborn',
  Latitude: '507.004.980',
  Longitude: '83.375.950',
  Telephone: '277261240',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:00',
  SundayFrom: '08:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.700498,8.337595',
  id: '50.700498,8.337595'
}, {
  Brand: 'ARAL',
  Street: 'Marburger Straße 229',
  PostCode: 35396,
  City: 'Gießen',
  Latitude: '506.064.530',
  Longitude: '86.957.250',
  Telephone: '6419502133',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.606453,8.695725',
  id: '50.606453,8.695725'
}, {
  Brand: 'ARAL',
  Street: 'Leipziger Straße 266',
  PostCode: 34260,
  City: 'Kaufungen',
  Latitude: '512.842.210',
  Longitude: '96.026.280',
  Telephone: '56052524',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.284221,9.602628',
  id: '51.284221,9.602628'
}, {
  Brand: 'ARAL',
  Street: 'Fritzlarer Straße 61',
  PostCode: 34212,
  City: 'Melsungen',
  Latitude: '511.289.550',
  Longitude: '95.374.040',
  Telephone: '5661920715',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.128955,9.537404',
  id: '51.128955,9.537404'
}, {
  Brand: 'ARAL',
  Street: 'Langenbergstraße 9',
  PostCode: 34225,
  City: 'Baunatal',
  Latitude: '512.560.900',
  Longitude: '94.132.230',
  Telephone: '561492256',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.25609,9.413223',
  id: '51.25609,9.413223'
}, {
  Brand: 'ARAL',
  Street: 'Westerwaldstraße 26',
  PostCode: 35745,
  City: 'Herborn',
  Latitude: '506.784.650',
  Longitude: '83.013.500',
  Telephone: '277282520',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.678465,8.30135',
  id: '50.678465,8.30135'
}, {
  Brand: 'ARAL',
  Street: 'Petersburg  6',
  PostCode: 35075,
  City: 'Gladenbach',
  Latitude: '507.532.830',
  Longitude: '85.469.590',
  Telephone: '64621086',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.753283,8.546959',
  id: '50.753283,8.546959'
}, {
  Brand: 'ARAL',
  Street: 'Fuldaer Straße 27',
  PostCode: 36088,
  City: 'Hünfeld',
  Latitude: '506.673.820',
  Longitude: '97.621.460',
  Telephone: '66522705',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Ja',
  location: '50.667382,9.762146',
  id: '50.667382,9.762146'
}, {
  Brand: 'ARAL',
  Street: 'Kohlenstraße 60',
  PostCode: 34121,
  City: 'Kassel',
  Latitude: '513.092.110',
  Longitude: '94.620.220',
  Telephone: '56124343',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.309211,9.462022',
  id: '51.309211,9.462022'
}, {
  Brand: 'ARAL',
  Street: 'Herzberger Straße 1',
  PostCode: 37115,
  City: 'Duderstadt',
  Latitude: '515.158.540',
  Longitude: '102.607.600',
  Telephone: '55272838',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.515854,10.26076',
  id: '51.515854,10.26076'
}, {
  Brand: 'ARAL',
  Street: 'Kasseler Straße 21',
  PostCode: 35091,
  City: 'Cölbe',
  Latitude: '508.468.800',
  Longitude: '87.840.500',
  Telephone: '642182645',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.84688,8.78405',
  id: '50.84688,8.78405'
}, {
  Brand: 'ARAL',
  Street: 'Kasseler Straße 32',
  PostCode: 35683,
  City: 'Dillenburg',
  Latitude: '507.499.560',
  Longitude: '82.768.470',
  Telephone: '2771812117',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.749956,8.276847',
  id: '50.749956,8.276847'
}, {
  Brand: 'ARAL',
  Street: 'Westrandstraße 1',
  PostCode: 34582,
  City: 'Borken',
  Latitude: '510.491.100',
  Longitude: '92.730.200',
  Telephone: '56822433',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.04911,9.27302',
  id: '51.04911,9.27302'
}, {
  Brand: 'ARAL',
  Street: 'Kasseler Straße 53',
  PostCode: 34253,
  City: 'Lohfelden',
  Latitude: '512.682.220',
  Longitude: '95.616.360',
  Telephone: '560891807',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:30',
  SundayFrom: '08:00',
  SundayTo: '21:30',
  IsOpen24Hours: 'Nein',
  location: '51.268222,9.561636',
  id: '51.268222,9.561636'
}, {
  Brand: 'ARAL',
  Street: 'Magdeburger Straße 47',
  PostCode: 36037,
  City: 'Fulda',
  Latitude: '505.588.260',
  Longitude: '96.849.660',
  Telephone: '661604123',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '50.558826,9.684966',
  id: '50.558826,9.684966'
}, {
  Brand: 'ARAL',
  Street: 'Brückenstraße 2',
  PostCode: 35638,
  City: 'Leun',
  Latitude: '505.480.850',
  Longitude: '83.584.710',
  Telephone: '64738434',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.548085,8.358471',
  id: '50.548085,8.358471'
}, {
  Brand: 'ARAL',
  Street: 'BAB 49/Westseite',
  PostCode: 34295,
  City: 'Edermünde',
  Latitude: '512.237.650',
  Longitude: '94.195.530',
  Telephone: '56656388',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.223765,9.419553',
  id: '51.223765,9.419553'
}, {
  Brand: 'ARAL',
  Street: 'Driburger Straße 2',
  PostCode: 33034,
  City: 'Brakel',
  Latitude: '517.125.460',
  Longitude: '91.779.660',
  Telephone: '52723920240',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.712546,9.177966',
  id: '51.712546,9.177966'
}, {
  Brand: 'ARAL',
  Street: 'Edertalstraße 60',
  PostCode: 35108,
  City: 'Allendorf',
  Latitude: '510.215.580',
  Longitude: '86.630.960',
  Telephone: '64529110251',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '09:00',
  SundayTo: '13:00',
  IsOpen24Hours: 'Nein',
  location: '51.021558,8.663096',
  id: '51.021558,8.663096'
}, {
  Brand: 'ARAL',
  Street: 'Möhnestraße 22',
  PostCode: 59929,
  City: 'Brilon',
  Latitude: '514.032.200',
  Longitude: '85.711.450',
  Telephone: '29618601',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.40322,8.571145',
  id: '51.40322,8.571145'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstrasse 1',
  PostCode: 38700,
  City: 'Braunlage',
  Latitude: '517.201.890',
  Longitude: '106.124.920',
  Telephone: '5520923222',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.720189,10.612492',
  id: '51.720189,10.612492'
}, {
  Brand: 'ARAL',
  Street: 'A7',
  PostCode: 36148,
  City: 'Kalbach',
  Latitude: '504.197.850',
  Longitude: '97.354.760',
  Telephone: '9742204',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.419785,9.735476',
  id: '50.419785,9.735476'
}, {
  Brand: 'ARAL',
  Street: 'Borchener Straße 42',
  PostCode: 33098,
  City: 'Paderborn',
  Latitude: '517.108.600',
  Longitude: '87.463.930',
  Telephone: '525172335',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.71086,8.746393',
  id: '51.71086,8.746393'
}, {
  Brand: 'ARAL',
  Street: 'Röddenauer Straße 31',
  PostCode: 35066,
  City: 'Frankenberg',
  Latitude: '510.582.420',
  Longitude: '87.839.100',
  Telephone: '64516152',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.058242,8.78391',
  id: '51.058242,8.78391'
}, {
  Brand: 'ARAL',
  Street: 'Reinhäuser Landstraße 108',
  PostCode: 37083,
  City: 'Göttingen',
  Latitude: '515.202.180',
  Longitude: '99.404.110',
  Telephone: '55131705',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:30',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.520218,9.940411',
  id: '51.520218,9.940411'
}, {
  Brand: 'ARAL',
  Street: 'Weender Landstraße 62A',
  PostCode: 37075,
  City: 'Göttingen',
  Latitude: '515.444.020',
  Longitude: '99.323.060',
  Telephone: '55131444',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.544402,9.932306',
  id: '51.544402,9.932306'
}, {
  Brand: 'ARAL',
  Street: 'Veckerhagener Straße19',
  PostCode: 34233,
  City: 'Fuldatal',
  Latitude: '513.473.650',
  Longitude: '95.239.220',
  Telephone: '561811447',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.347365,9.523922',
  id: '51.347365,9.523922'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Straße 90',
  PostCode: 34121,
  City: 'Kassel',
  Latitude: '513.036.970',
  Longitude: '94.837.750',
  Telephone: '56123104',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.303697,9.483775',
  id: '51.303697,9.483775'
}, {
  Brand: 'ARAL',
  Street: 'Holländische Straße 142',
  PostCode: 34127,
  City: 'Kassel',
  Latitude: '513.337.410',
  Longitude: '94.956.710',
  Telephone: '5619893813',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.333741,9.495671',
  id: '51.333741,9.495671'
}, {
  Brand: 'ARAL',
  Street: 'Krummbogen 4',
  PostCode: 35039,
  City: 'Marburg',
  Latitude: '508.175.940',
  Longitude: '87.743.070',
  Telephone: '642161348',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.817594,8.774307',
  id: '50.817594,8.774307'
}, {
  Brand: 'ARAL',
  Street: 'Großseelheimer Straße 93',
  PostCode: 35039,
  City: 'Marburg',
  Latitude: '507.996.060',
  Longitude: '87.887.180',
  Telephone: '642124595',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.799606,8.788718',
  id: '50.799606,8.788718'
}, {
  Brand: 'ARAL',
  Street: 'Walburger Straße 51',
  PostCode: 37213,
  City: 'Witzenhausen',
  Latitude: '513.380.370',
  Longitude: '98.594.720',
  Telephone: '55422348',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.338037,9.859472',
  id: '51.338037,9.859472'
}, {
  Brand: 'ARAL',
  Street: 'Ippinghäuser Straße 10',
  PostCode: 34466,
  City: 'Wolfhagen',
  Latitude: '513.234.700',
  Longitude: '91.737.340',
  Telephone: '56929876100',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.32347,9.173734',
  id: '51.32347,9.173734'
}, {
  Brand: 'ARAL',
  Street: 'Geseker Straße 21',
  PostCode: 33154,
  City: 'Salzkotten',
  Latitude: '516.682.360',
  Longitude: '85.940.900',
  Telephone: '5258935377',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.668236,8.59409',
  id: '51.668236,8.59409'
}, {
  Brand: 'ARAL',
  Street: 'Paderborner Straße 22',
  PostCode: 33161,
  City: 'Hövelhof',
  Latitude: '518.187.510',
  Longitude: '86.592.430',
  Telephone: '52572631',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.818751,8.659243',
  id: '51.818751,8.659243'
}, {
  Brand: 'ARAL',
  Street: 'Scharzfelder Str. 6',
  PostCode: 37412,
  City: 'Herzberg',
  Latitude: '516.496.020',
  Longitude: '103.465.670',
  Telephone: '55213071',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.649602,10.346567',
  id: '51.649602,10.346567'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 116',
  PostCode: 67346,
  City: 'Speyer',
  Latitude: '493.272.700',
  Longitude: '84.293.580',
  Telephone: '623276218',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.32727,8.429358',
  id: '49.32727,8.429358'
}, {
  Brand: 'ARAL',
  Street: 'Zweibrücker Straße 130',
  PostCode: 66954,
  City: 'Pirmasens',
  Latitude: '492.139.390',
  Longitude: '75.974.540',
  Telephone: '633165954',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '49.213939,7.597454',
  id: '49.213939,7.597454'
}, {
  Brand: 'ARAL',
  Street: 'Rheinstraße 69',
  PostCode: 64295,
  City: 'Darmstadt',
  Latitude: '498.715.940',
  Longitude: '86.393.910',
  Telephone: '6151891309',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.871594,8.639391',
  id: '49.871594,8.639391'
}, {
  Brand: 'ARAL',
  Street: 'Bergheimer Straße 90',
  PostCode: 69115,
  City: 'Heidelberg',
  Latitude: '494.080.240',
  Longitude: '86.782.800',
  Telephone: '6221163048',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.408024,8.67828',
  id: '49.408024,8.67828'
}, {
  Brand: 'ARAL',
  Street: 'Nibelungenstraße 197',
  PostCode: 68642,
  City: 'Bürstadt',
  Latitude: '496.457.250',
  Longitude: '84.732.770',
  Telephone: '6206963564',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.645725,8.473277',
  id: '49.645725,8.473277'
}, {
  Brand: 'ARAL',
  Street: 'Rheinstraße 47-49',
  PostCode: 67574,
  City: 'Osthofen',
  Latitude: '497.046.440',
  Longitude: '83.334.750',
  Telephone: '62425028777',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '49.704644,8.333475',
  id: '49.704644,8.333475'
}, {
  Brand: 'ARAL',
  Street: 'Saarbrücker Straße 56',
  PostCode: 67742,
  City: 'Lauterecken',
  Latitude: '496.500.550',
  Longitude: '75.842.300',
  Telephone: '6382505',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.650055,7.58423',
  id: '49.650055,7.58423'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 71',
  PostCode: 67714,
  City: 'Waldfischbach-Burgal',
  Latitude: '492.865.310',
  Longitude: '76.496.200',
  Telephone: '6333274825',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '49.286531,7.64962',
  id: '49.286531,7.64962'
}, {
  Brand: 'ARAL',
  Street: 'Schaffhäuser Straße 2-6',
  PostCode: 55232,
  City: 'Alzey',
  Latitude: '497.507.780',
  Longitude: '81.192.110',
  Telephone: '673142299',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.750778,8.119211',
  id: '49.750778,8.119211'
}, {
  Brand: 'ARAL',
  Street: 'Ludwigstraße 21-25',
  PostCode: 69483,
  City: 'Wald-Michelbach',
  Latitude: '495.735.540',
  Longitude: '88.233.180',
  Telephone: '620794170',
  WeekDayFrom: '06:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.573554,8.823318',
  id: '49.573554,8.823318'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 77a',
  PostCode: 67685,
  City: 'Weilerbach',
  Latitude: '494.775.540',
  Longitude: '76.379.790',
  Telephone: '63741285',
  WeekDayFrom: '04:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '49.477554,7.637979',
  id: '49.477554,7.637979'
}, {
  Brand: 'ARAL',
  Street: 'Möhlstraße 1',
  PostCode: 68165,
  City: 'Mannheim',
  Latitude: '494.757.100',
  Longitude: '84.879.580',
  Telephone: '621407238',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.47571,8.487958',
  id: '49.47571,8.487958'
}, {
  Brand: 'ARAL',
  Street: 'Aschaffenburger Straße 3',
  PostCode: 64832,
  City: 'Babenhausen',
  Latitude: '499.574.280',
  Longitude: '89.580.510',
  Telephone: '607362912',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.957428,8.958051',
  id: '49.957428,8.958051'
}, {
  Brand: 'ARAL',
  Street: 'Friedrichsdorfer Landstra',
  PostCode: 69412,
  City: 'Eberbach',
  Latitude: '494.705.360',
  Longitude: '89.945.480',
  Telephone: '6271921821',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.470536,8.994548',
  id: '49.470536,8.994548'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Landstraße 257',
  PostCode: 64291,
  City: 'Darmstadt',
  Latitude: '499.181.920',
  Longitude: '86.547.590',
  Telephone: '6151371223',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.918192,8.654759',
  id: '49.918192,8.654759'
}, {
  Brand: 'ARAL',
  Street: 'Neckarsteinacher Str. 42',
  PostCode: 69250,
  City: 'Schönau',
  Latitude: '494.299.920',
  Longitude: '88.122.390',
  Telephone: '62288204',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '49.429992,8.812239',
  id: '49.429992,8.812239'
}, {
  Brand: 'ARAL',
  Street: 'Wormser Straße 128',
  PostCode: 67227,
  City: 'Frankenthal',
  Latitude: '495.483.600',
  Longitude: '83.538.490',
  Telephone: '62336070376',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.54836,8.353849',
  id: '49.54836,8.353849'
}, {
  Brand: 'ARAL',
  Street: 'Speyerer-Straße 105 a',
  PostCode: 67117,
  City: 'Limburgerhof',
  Latitude: '494.219.610',
  Longitude: '83.940.730',
  Telephone: '623667805',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '05:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '49.421961,8.394073',
  id: '49.421961,8.394073'
}, {
  Brand: 'ARAL',
  Street: 'Kirchheimer Straße 51-53',
  PostCode: 67269,
  City: 'Grünstadt',
  Latitude: '495.592.890',
  Longitude: '81.702.790',
  Telephone: '63593194',
  WeekDayFrom: '04:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '49.559289,8.170279',
  id: '49.559289,8.170279'
}, {
  Brand: 'ARAL',
  Street: 'Pariser Straße 202',
  PostCode: 67663,
  City: 'Kaiserslautern',
  Latitude: '494.406.200',
  Longitude: '77.426.490',
  Telephone: '63174230',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.44062,7.742649',
  id: '49.44062,7.742649'
}, {
  Brand: 'ARAL',
  Street: 'Relaisstraße 1-3',
  PostCode: 68219,
  City: 'Mannheim',
  Latitude: '494.383.930',
  Longitude: '85.237.710',
  Telephone: '621891606',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.438393,8.523771',
  id: '49.438393,8.523771'
}, {
  Brand: 'ARAL',
  Street: 'Kaiserstraße 110',
  PostCode: 66849,
  City: 'Landstuhl',
  Latitude: '494.140.050',
  Longitude: '75.824.630',
  Telephone: '637112115',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '49.414005,7.582463',
  id: '49.414005,7.582463'
}, {
  Brand: 'ARAL',
  Street: 'Bischheimer Straße 9',
  PostCode: 67292,
  City: 'Kirchheimbolanden',
  Latitude: '496.685.730',
  Longitude: '80.200.800',
  Telephone: '63525016',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.668573,8.02008',
  id: '49.668573,8.02008'
}, {
  Brand: 'ARAL',
  Street: 'Frankenthaler Straße 86',
  PostCode: 68307,
  City: 'Mannheim',
  Latitude: '495.501.150',
  Longitude: '84.490.750',
  Telephone: '621771755',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.550115,8.449075',
  id: '49.550115,8.449075'
}, {
  Brand: 'ARAL',
  Street: 'Trippstadter Straße 69',
  PostCode: 67663,
  City: 'Kaiserslautern',
  Latitude: '494.337.530',
  Longitude: '77.574.650',
  Telephone: '63128680',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.433753,7.757465',
  id: '49.433753,7.757465'
}, {
  Brand: 'ARAL',
  Street: 'Maximilianstraße 45',
  PostCode: 76534,
  City: 'Baden-Baden',
  Latitude: '487.498.440',
  Longitude: '82.545.920',
  Telephone: '72217438',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.749844,8.254592',
  id: '48.749844,8.254592'
}, {
  Brand: 'ARAL',
  Street: 'Zeiskamer Straße 63',
  PostCode: 76756,
  City: 'Bellheim',
  Latitude: '491.955.790',
  Longitude: '82.774.980',
  Telephone: '727293110',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.195579,8.277498',
  id: '49.195579,8.277498'
}, {
  Brand: 'ARAL',
  Street: 'Pforzheimer Straße69',
  PostCode: 75015,
  City: 'Bretten',
  Latitude: '490.328.370',
  Longitude: '87.066.490',
  Telephone: '72527327',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.032837,8.706649',
  id: '49.032837,8.706649'
}, {
  Brand: 'ARAL',
  Street: 'Niederramstädter Str. 170',
  PostCode: 64285,
  City: 'Darmstadt',
  Latitude: '498.564.130',
  Longitude: '86.692.270',
  Telephone: '615148696',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.856413,8.669227',
  id: '49.856413,8.669227'
}, {
  Brand: 'ARAL',
  Street: 'Hochspeyerer-Straße 53',
  PostCode: 67677,
  City: 'Enkenbach-Alsenborn',
  Latitude: '494.836.750',
  Longitude: '79.004.070',
  Telephone: '63034364',
  WeekDayFrom: '04:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '49.483675,7.900407',
  id: '49.483675,7.900407'
}, {
  Brand: 'ARAL',
  Street: 'Darmstädter Straße122',
  PostCode: 64331,
  City: 'Weiterstadt',
  Latitude: '499.022.500',
  Longitude: '85.947.870',
  Telephone: '61502190',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.90225,8.594787',
  id: '49.90225,8.594787'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 10',
  PostCode: 67697,
  City: 'Otterberg',
  Latitude: '494.992.060',
  Longitude: '77.727.720',
  Telephone: '63019778',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.499206,7.772772',
  id: '49.499206,7.772772'
}, {
  Brand: 'ARAL',
  Street: 'Neckarstraße 40',
  PostCode: 64711,
  City: 'Erbach',
  Latitude: '496.524.220',
  Longitude: '89.952.970',
  Telephone: '60623565',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.652422,8.995297',
  id: '49.652422,8.995297'
}, {
  Brand: 'ARAL',
  Street: 'Heidelberger Straße 9',
  PostCode: 69207,
  City: 'Sandhausen',
  Latitude: '493.459.160',
  Longitude: '86.600.790',
  Telephone: '62243159',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '49.345916,8.660079',
  id: '49.345916,8.660079'
}, {
  Brand: 'ARAL',
  Street: 'Gaustraße 156',
  PostCode: 67549,
  City: 'Worms',
  Latitude: '496.461.550',
  Longitude: '83.494.390',
  Telephone: '624152310',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.646155,8.349439',
  id: '49.646155,8.349439'
}, {
  Brand: 'ARAL',
  Street: 'Lauterstraße 1',
  PostCode: 67731,
  City: 'Otterbach',
  Latitude: '494.828.370',
  Longitude: '77.308.620',
  Telephone: '63019877',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.482837,7.730862',
  id: '49.482837,7.730862'
}, {
  Brand: 'ARAL',
  Street: 'Wormser Straße 53',
  PostCode: 64625,
  City: 'Bensheim',
  Latitude: '496.762.850',
  Longitude: '86.117.320',
  Telephone: '62512416',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.676285,8.611732',
  id: '49.676285,8.611732'
}, {
  Brand: 'ARAL',
  Street: 'Neustadter Straße 47',
  PostCode: 68309,
  City: 'Mannheim',
  Latitude: '494.996.350',
  Longitude: '85.068.950',
  Telephone: '621735151',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.499635,8.506895',
  id: '49.499635,8.506895'
}, {
  Brand: 'ARAL',
  Street: 'Branchweilerhofstraße 104',
  PostCode: 67433,
  City: 'Neustadt',
  Latitude: '493.507.100',
  Longitude: '81.639.820',
  Telephone: '632112320',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.35071,8.163982',
  id: '49.35071,8.163982'
}, {
  Brand: 'ARAL',
  Street: 'Rüdesheimer Straße 114',
  PostCode: 64295,
  City: 'Darmstadt',
  Latitude: '498.540.190',
  Longitude: '86.419.890',
  Telephone: '615163545',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.854019,8.641989',
  id: '49.854019,8.641989'
}, {
  Brand: 'ARAL',
  Street: 'Staatsstraße 101',
  PostCode: 64668,
  City: 'Rimbach',
  Latitude: '496.301.540',
  Longitude: '87.599.500',
  Telephone: '62536484',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:30',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.630154,8.75995',
  id: '49.630154,8.75995'
}, {
  Brand: 'ARAL',
  Street: 'Saar-Pfalz-Straße',
  PostCode: 66440,
  City: 'Blieskastel-Aßweiler',
  Latitude: '492.130.160',
  Longitude: '71.817.370',
  Telephone: '68038433',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.213016,7.181737',
  id: '49.213016,7.181737'
}, {
  Brand: 'ARAL',
  Street: 'Grülingstraße',
  PostCode: 66280,
  City: 'Sulzbach',
  Latitude: '493.065.510',
  Longitude: '70.562.070',
  Telephone: '68972307',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.306551,7.056207',
  id: '49.306551,7.056207'
}, {
  Brand: 'ARAL',
  Street: 'Alsweiler Straße 50',
  PostCode: 66646,
  City: 'Marpingen',
  Latitude: '494.557.960',
  Longitude: '70.607.400',
  Telephone: '68535107',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.455796,7.06074',
  id: '49.455796,7.06074'
}, {
  Brand: 'ARAL',
  Street: 'Trierer Straße 90',
  PostCode: 66839,
  City: 'Schmelz',
  Latitude: '494.630.000',
  Longitude: '68.516.650',
  Telephone: '688788393',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.463,6.851665',
  id: '49.463,6.851665'
}, {
  Brand: 'ARAL',
  Street: 'Glashüttenstraße 4',
  PostCode: 66287,
  City: 'Quierschied',
  Latitude: '493.297.150',
  Longitude: '70.567.140',
  Telephone: '689761902',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.329715,7.056714',
  id: '49.329715,7.056714'
}, {
  Brand: 'ARAL',
  Street: 'Wallerfanger Straße 69',
  PostCode: 66780,
  City: 'Rehlingen-Siersburg',
  Latitude: '493.650.290',
  Longitude: '66.871.490',
  Telephone: '68357591',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.365029,6.687149',
  id: '49.365029,6.687149'
}, {
  Brand: 'ARAL',
  Street: 'Mommstraße 1',
  PostCode: 66606,
  City: 'St. Wendel',
  Latitude: '494.697.930',
  Longitude: '71.674.290',
  Telephone: '68512753',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.469793,7.167429',
  id: '49.469793,7.167429'
}, {
  Brand: 'ARAL',
  Street: 'Poststraße 33',
  PostCode: 66687,
  City: 'Wadern',
  Latitude: '495.350.010',
  Longitude: '68.954.060',
  Telephone: '6871902924',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '49.535001,6.895406',
  id: '49.535001,6.895406'
}, {
  Brand: 'ARAL',
  Street: 'Straße des 13. Januar 197',
  PostCode: 66333,
  City: 'Völklingen',
  Latitude: '492.490.770',
  Longitude: '68.984.750',
  Telephone: '689881668',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.249077,6.898475',
  id: '49.249077,6.898475'
}, {
  Brand: 'ARAL',
  Street: 'Haustadter-Tal-Straße 110',
  PostCode: 66701,
  City: 'Beckingen',
  Latitude: '494.096.170',
  Longitude: '67.177.990',
  Telephone: '68357569',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.409617,6.717799',
  id: '49.409617,6.717799'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 22',
  PostCode: 66740,
  City: 'Saarlouis',
  Latitude: '493.252.160',
  Longitude: '67.577.460',
  Telephone: '683181203',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.325216,6.757746',
  id: '49.325216,6.757746'
}, {
  Brand: 'ARAL',
  Street: 'von-Boch-Liebig-Straße 21',
  PostCode: 66693,
  City: 'Mettlach',
  Latitude: '494.950.530',
  Longitude: '65.860.360',
  Telephone: '686493532',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.495053,6.586036',
  id: '49.495053,6.586036'
}, {
  Brand: 'ARAL',
  Street: 'Karolingerstraße 5',
  PostCode: 66333,
  City: 'Völklingen',
  Latitude: '492.475.470',
  Longitude: '68.592.460',
  Telephone: '689823327',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.247547,6.859246',
  id: '49.247547,6.859246'
}, {
  Brand: 'ARAL',
  Street: 'Lindenstraße 7',
  PostCode: 66583,
  City: 'Spiesen-Elversberg',
  Latitude: '493.178.000',
  Longitude: '71.218.400',
  Telephone: '682171436',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.3178,7.12184',
  id: '49.3178,7.12184'
}, {
  Brand: 'ARAL',
  Street: 'St. Johanner Straße 105',
  PostCode: 66115,
  City: 'Saarbrücken',
  Latitude: '492.390.220',
  Longitude: '69.767.910',
  Telephone: '68143798',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.239022,6.976791',
  id: '49.239022,6.976791'
}, {
  Brand: 'ARAL',
  Street: 'Höllerstraße 16',
  PostCode: 55597,
  City: 'Wöllstein',
  Latitude: '498.169.590',
  Longitude: '79.607.430',
  Telephone: '6703960203',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.816959,7.960743',
  id: '49.816959,7.960743'
}, {
  Brand: 'ARAL',
  Street: 'Chausseehaus',
  PostCode: 55291,
  City: 'Saulheim',
  Latitude: '498.694.740',
  Longitude: '81.462.010',
  Telephone: '67324317',
  WeekDayFrom: '05:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '18:00',
  IsOpen24Hours: 'Nein',
  location: '49.869474,8.146201',
  id: '49.869474,8.146201'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 129',
  PostCode: 77815,
  City: 'Bühl',
  Latitude: '487.021.000',
  Longitude: '81.393.170',
  Telephone: '72234364',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.7021,8.139317',
  id: '48.7021,8.139317'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 166 A',
  PostCode: 76448,
  City: 'Durmersheim',
  Latitude: '489.364.790',
  Longitude: '82.723.500',
  Telephone: '72457956',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.936479,8.27235',
  id: '48.936479,8.27235'
}, {
  Brand: 'ARAL',
  Street: 'Brettener Straße 68',
  PostCode: 75031,
  City: 'Eppingen',
  Latitude: '491.349.610',
  Longitude: '89.013.090',
  Telephone: '7262912086',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '49.134961,8.901309',
  id: '49.134961,8.901309'
}, {
  Brand: 'ARAL',
  Street: 'Wiesenstraße 30',
  PostCode: 76228,
  City: 'Karlsruhe',
  Latitude: '489.527.460',
  Longitude: '84.708.570',
  Telephone: '721450101',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.952746,8.470857',
  id: '48.952746,8.470857'
}, {
  Brand: 'ARAL',
  Street: 'Friedenstraße 2a',
  PostCode: 76767,
  City: 'Hagenbach',
  Latitude: '490.220.480',
  Longitude: '82.515.430',
  Telephone: '72734518',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.022048,8.251543',
  id: '49.022048,8.251543'
}, {
  Brand: 'ARAL',
  Street: 'Hindenburgstraße  75',
  PostCode: 75339,
  City: 'Höfen',
  Latitude: '488.080.280',
  Longitude: '85.839.700',
  Telephone: '70815286',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.808028,8.58397',
  id: '48.808028,8.58397'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 1',
  PostCode: 76549,
  City: 'Hügelsheim',
  Latitude: '487.991.020',
  Longitude: '81.158.710',
  Telephone: '7229181765',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.799102,8.115871',
  id: '48.799102,8.115871'
}, {
  Brand: 'ARAL',
  Street: 'Rheinstraße 128',
  PostCode: 76870,
  City: 'Kandel',
  Latitude: '490.852.650',
  Longitude: '82.102.040',
  Telephone: '727595670',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.085265,8.210204',
  id: '49.085265,8.210204'
}, {
  Brand: 'ARAL',
  Street: 'Durlacher Allee 46',
  PostCode: 76131,
  City: 'Karlsruhe',
  Latitude: '490.076.310',
  Longitude: '84.228.500',
  Telephone: '721696022',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '49.007631,8.42285',
  id: '49.007631,8.42285'
}, {
  Brand: 'ARAL',
  Street: 'Amalienstraße 55/59',
  PostCode: 76133,
  City: 'Karlsruhe',
  Latitude: '490.092.150',
  Longitude: '83.908.730',
  Telephone: '72126224',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.009215,8.390873',
  id: '49.009215,8.390873'
}, {
  Brand: 'ARAL',
  Street: 'Kaiserallee 12 G',
  PostCode: 76133,
  City: 'Karlsruhe',
  Latitude: '490.113.160',
  Longitude: '83.750.620',
  Telephone: '721842649',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.011316,8.375062',
  id: '49.011316,8.375062'
}, {
  Brand: 'ARAL',
  Street: 'Ebertstraße 32',
  PostCode: 76137,
  City: 'Karlsruhe',
  Latitude: '489.951.420',
  Longitude: '83.895.390',
  Telephone: '721817516',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '48.995142,8.389539',
  id: '48.995142,8.389539'
}, {
  Brand: 'ARAL',
  Street: 'Ankerstraße 64',
  PostCode: 75203,
  City: 'Königsbach-Stein',
  Latitude: '489.666.650',
  Longitude: '86.036.250',
  Telephone: '72325595',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:30',
  SundayFrom: '07:30',
  SundayTo: '20:30',
  IsOpen24Hours: 'Nein',
  location: '48.966665,8.603625',
  id: '48.966665,8.603625'
}, {
  Brand: 'ARAL',
  Street: 'Ettlinger-Straße35',
  PostCode: 76307,
  City: 'Karlsbad',
  Latitude: '489.173.120',
  Longitude: '84.999.410',
  Telephone: '72023233',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.917312,8.499941',
  id: '48.917312,8.499941'
}, {
  Brand: 'ARAL',
  Street: 'Pforzheimer Straße 55',
  PostCode: 75417,
  City: 'Mühlacker',
  Latitude: '489.482.790',
  Longitude: '88.345.840',
  Telephone: '70414840',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.948279,8.834584',
  id: '48.948279,8.834584'
}, {
  Brand: 'ARAL',
  Street: 'Wildbader Straße 55',
  PostCode: 75394,
  City: 'Oberreichenbach',
  Latitude: '487.341.920',
  Longitude: '86.627.840',
  Telephone: '7051968683',
  WeekDayFrom: '06:45',
  WeekDayTo: '19:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '19:00',
  SundayFrom: '08:30',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '48.734192,8.662784',
  id: '48.734192,8.662784'
}, {
  Brand: 'ARAL',
  Street: 'Landauer Straße 11',
  PostCode: 76877,
  City: 'Offenbach',
  Latitude: '491.943.760',
  Longitude: '81.875.270',
  Telephone: '63486473',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.194376,8.187527',
  id: '49.194376,8.187527'
}, {
  Brand: 'ARAL',
  Street: 'Kaiser-Friedrich-Straße 3',
  PostCode: 75172,
  City: 'Pforzheim',
  Latitude: '488.890.790',
  Longitude: '86.924.410',
  Telephone: '7231927913',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.889079,8.692441',
  id: '48.889079,8.692441'
}, {
  Brand: 'ARAL',
  Street: 'Alexanderplatz',
  PostCode: 75015,
  City: 'Bretten',
  Latitude: '490.378.420',
  Longitude: '86.877.450',
  Telephone: '7252958220',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.037842,8.687745',
  id: '49.037842,8.687745'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße',
  PostCode: 75056,
  City: 'Sulzfeld',
  Latitude: '491.095.290',
  Longitude: '88.551.310',
  Telephone: '7269919130',
  WeekDayFrom: '05:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.109529,8.855131',
  id: '49.109529,8.855131'
}, {
  Brand: 'ARAL',
  Street: 'Weingartener Straße 99',
  PostCode: 76646,
  City: 'Bruchsal',
  Latitude: '490.801.380',
  Longitude: '85.492.520',
  Telephone: '7257911412',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:30',
  SaturdayFrom: '07:30',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.080138,8.549252',
  id: '49.080138,8.549252'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße2',
  PostCode: 75196,
  City: 'Remchingen',
  Latitude: '489.529.960',
  Longitude: '85.706.880',
  Telephone: '723271202',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.952996,8.570688',
  id: '48.952996,8.570688'
}, {
  Brand: 'ARAL',
  Street: 'Stuttgarter Straße 139',
  PostCode: 71665,
  City: 'Vaihingen',
  Latitude: '489.224.260',
  Longitude: '89.740.540',
  Telephone: '704217154',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.922426,8.974054',
  id: '48.922426,8.974054'
}, {
  Brand: 'ARAL',
  Street: 'Haiterbacher Straße 24-26',
  PostCode: 72202,
  City: 'Nagold',
  Latitude: '485.488.750',
  Longitude: '87.267.870',
  Telephone: '74523562',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.548875,8.726787',
  id: '48.548875,8.726787'
}, {
  Brand: 'ARAL',
  Street: 'Groß-Umstädter Straße 1',
  PostCode: 64807,
  City: 'Dieburg',
  Latitude: '498.991.000',
  Longitude: '88.467.250',
  Telephone: '6071499697',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.8991,8.846725',
  id: '49.8991,8.846725'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 90',
  PostCode: 76669,
  City: 'Bad Schönborn',
  Latitude: '492.005.820',
  Longitude: '86.462.340',
  Telephone: '72539609997',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '49.200582,8.646234',
  id: '49.200582,8.646234'
}, {
  Brand: 'ARAL',
  Street: 'Lange Straße 122',
  PostCode: 76530,
  City: 'Baden-Baden',
  Latitude: '487.705.450',
  Longitude: '82.284.370',
  Telephone: '722125331',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.770545,8.228437',
  id: '48.770545,8.228437'
}, {
  Brand: 'ARAL',
  Street: 'Baumholderstraße 47-51',
  PostCode: 66629,
  City: 'Freisen',
  Latitude: '495.546.050',
  Longitude: '72.561.430',
  Telephone: '685592000',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.554605,7.256143',
  id: '49.554605,7.256143'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 73',
  PostCode: 67734,
  City: 'Katzweiler',
  Latitude: '495.046.510',
  Longitude: '76.923.980',
  Telephone: '63019140',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '49.504651,7.692398',
  id: '49.504651,7.692398'
}, {
  Brand: 'ARAL',
  Street: 'Kaiserstraße 1 E',
  PostCode: 66459,
  City: 'Kirkel',
  Latitude: '492.897.440',
  Longitude: '72.384.590',
  Telephone: '6849551',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '21:00',
  SundayFrom: '08:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.289744,7.238459',
  id: '49.289744,7.238459'
}, {
  Brand: 'ARAL',
  Street: 'Brandenburger Str. 13-15',
  PostCode: 68309,
  City: 'Mannheim',
  Latitude: '495.120.680',
  Longitude: '85.295.420',
  Telephone: '621706602',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.512068,8.529542',
  id: '49.512068,8.529542'
}, {
  Brand: 'ARAL',
  Street: 'Richard-Müller-Straße 7 a',
  PostCode: 67823,
  City: 'Obermoschel',
  Latitude: '497.294.740',
  Longitude: '77.769.790',
  Telephone: '6362993170',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.729474,7.776979',
  id: '49.729474,7.776979'
}, {
  Brand: 'ARAL',
  Street: 'Feldmannstraße 69-73',
  PostCode: 66119,
  City: 'Saarbrücken',
  Latitude: '492.235.190',
  Longitude: '69.931.050',
  Telephone: '68156485',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.223519,6.993105',
  id: '49.223519,6.993105'
}, {
  Brand: 'ARAL',
  Street: 'Ensheimer Straße 152',
  PostCode: 66386,
  City: 'St. Ingbert',
  Latitude: '492.631.350',
  Longitude: '71.028.760',
  Telephone: '68946874',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.263135,7.102876',
  id: '49.263135,7.102876'
}, {
  Brand: 'ARAL',
  Street: 'Neues Sträßel 4',
  PostCode: 69168,
  City: 'Wiesloch',
  Latitude: '492.895.300',
  Longitude: '86.784.270',
  Telephone: '62221038',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.28953,8.678427',
  id: '49.28953,8.678427'
}, {
  Brand: 'ARAL',
  Street: 'Fautenbacher Straße 22',
  PostCode: 77855,
  City: 'Achern',
  Latitude: '486.285.140',
  Longitude: '80.633.740',
  Telephone: '784124099',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Ja',
  location: '48.628514,8.063374',
  id: '48.628514,8.063374'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 114',
  PostCode: 77833,
  City: 'Ottersweier',
  Latitude: '486.735.900',
  Longitude: '81.206.720',
  Telephone: '72232818707',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.67359,8.120672',
  id: '48.67359,8.120672'
}, {
  Brand: 'ARAL',
  Street: 'Strassburger Straße 11a',
  PostCode: 77694,
  City: 'Kehl',
  Latitude: '485.760.970',
  Longitude: '78.180.070',
  Telephone: '7851958081',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.576097,7.818007',
  id: '48.576097,7.818007'
}, {
  Brand: 'ARAL',
  Street: 'A5',
  PostCode: 77767,
  City: 'Appenweier',
  Latitude: '485.637.910',
  Longitude: '79.569.170',
  Telephone: '78053402',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.563791,7.956917',
  id: '48.563791,7.956917'
}, {
  Brand: 'ARAL',
  Street: 'Spießstraße 2',
  PostCode: 66892,
  City: 'Bruchmühlbach-Miesau',
  Latitude: '493.921.770',
  Longitude: '74.379.140',
  Telephone: '6372993290',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.392177,7.437914',
  id: '49.392177,7.437914'
}, {
  Brand: 'ARAL',
  Street: 'Saarlandstraße 2',
  PostCode: 66482,
  City: 'Zweibrücken',
  Latitude: '492.495.680',
  Longitude: '73.701.920',
  Telephone: '6332472572',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.249568,7.370192',
  id: '49.249568,7.370192'
}, {
  Brand: 'ARAL',
  Street: 'Am Götzenberg 13',
  PostCode: 69126,
  City: 'Heidelberg',
  Latitude: '493.704.010',
  Longitude: '87.012.260',
  Telephone: '6221383813',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '49.370401,8.701226',
  id: '49.370401,8.701226'
}, {
  Brand: 'ARAL',
  Street: 'A81',
  PostCode: 78661,
  City: 'Dietingen',
  Latitude: '482.062.180',
  Longitude: '86.227.610',
  Telephone: '7417453',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.206218,8.622761',
  id: '48.206218,8.622761'
}, {
  Brand: 'ARAL',
  Street: 'Lochäckerstraße / B 294',
  PostCode: 75177,
  City: 'Pforzheim',
  Latitude: '489.100.010',
  Longitude: '87.189.110',
  Telephone: '72315660680',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.910001,8.718911',
  id: '48.910001,8.718911'
}, {
  Brand: 'ARAL',
  Street: 'Rosenheimer Straße 138',
  PostCode: 81669,
  City: 'München',
  Latitude: '481.192.060',
  Longitude: '116.077.720',
  Telephone: '89401258',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.119206,11.607772',
  id: '48.119206,11.607772'
}, {
  Brand: 'ARAL',
  Street: 'Münchener Straße 7',
  PostCode: 83512,
  City: 'Wasserburg',
  Latitude: '480.612.680',
  Longitude: '122.098.080',
  Telephone: '80719225808',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.061268,12.209808',
  id: '48.061268,12.209808'
}, {
  Brand: 'ARAL',
  Street: 'Münchener Straße 13',
  PostCode: 82054,
  City: 'Sauerlach',
  Latitude: '479.736.750',
  Longitude: '116.536.540',
  Telephone: '81049756',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.973675,11.653654',
  id: '47.973675,11.653654'
}, {
  Brand: 'ARAL',
  Street: 'Lochhausener Straße 229',
  PostCode: 81249,
  City: 'München',
  Latitude: '481.789.510',
  Longitude: '114.051.460',
  Telephone: '8989711640',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.178951,11.405146',
  id: '48.178951,11.405146'
}, {
  Brand: 'ARAL',
  Street: 'Franz Wisbacher Straße 8',
  PostCode: 83404,
  City: 'Ainring',
  Latitude: '478.057.580',
  Longitude: '129.571.210',
  Telephone: '865450404',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '21:00',
  SundayFrom: '05:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '47.805758,12.957121',
  id: '47.805758,12.957121'
}, {
  Brand: 'ARAL',
  Street: 'Riemer Straße 360',
  PostCode: 81829,
  City: 'München',
  Latitude: '481.406.310',
  Longitude: '116.839.160',
  Telephone: '89907116',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.140631,11.683916',
  id: '48.140631,11.683916'
}, {
  Brand: 'ARAL',
  Street: 'Allgäuer Straße 34',
  PostCode: 87700,
  City: 'Memmingen',
  Latitude: '479.722.880',
  Longitude: '101.826.450',
  Telephone: '833189251',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.972288,10.182645',
  id: '47.972288,10.182645'
}, {
  Brand: 'ARAL',
  Street: 'Aiblinger Au 52 a',
  PostCode: 83059,
  City: 'Kolbermoor',
  Latitude: '478.511.460',
  Longitude: '120.174.520',
  Telephone: '80614695',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.851146,12.017452',
  id: '47.851146,12.017452'
}, {
  Brand: 'ARAL',
  Street: 'Kufsteiner Straße 57',
  PostCode: 83022,
  City: 'Rosenheim',
  Latitude: '478.487.670',
  Longitude: '121.275.390',
  Telephone: '803113256',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.848767,12.127539',
  id: '47.848767,12.127539'
}, {
  Brand: 'ARAL',
  Street: 'Schongauer Straße 19',
  PostCode: 86971,
  City: 'Peiting',
  Latitude: '477.986.680',
  Longitude: '109.212.030',
  Telephone: '8861693041',
  WeekDayFrom: '06:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.798668,10.921203',
  id: '47.798668,10.921203'
}, {
  Brand: 'ARAL',
  Street: 'Augsburger Straße 25',
  PostCode: 86477,
  City: 'Adelsried',
  Latitude: '484.236.580',
  Longitude: '107.201.730',
  Telephone: '82948607430',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.423658,10.720173',
  id: '48.423658,10.720173'
}, {
  Brand: 'ARAL',
  Street: 'Schleißheimer Straße 257',
  PostCode: 80809,
  City: 'München',
  Latitude: '481.787.880',
  Longitude: '115.670.970',
  Telephone: '893508370',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.178788,11.567097',
  id: '48.178788,11.567097'
}, {
  Brand: 'ARAL',
  Street: 'Augsburger Straße 66',
  PostCode: 86830,
  City: 'Schwabmünchen',
  Latitude: '481.936.570',
  Longitude: '107.546.100',
  Telephone: '82324790',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.193657,10.75461',
  id: '48.193657,10.75461'
}, {
  Brand: 'ARAL',
  Street: 'Münchener Straße 42',
  PostCode: 82131,
  City: 'Gauting',
  Latitude: '480.679.520',
  Longitude: '113.872.370',
  Telephone: '8996031470',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.067952,11.387237',
  id: '48.067952,11.387237'
}, {
  Brand: 'ARAL',
  Street: 'Münchner Straße 10',
  PostCode: 85635,
  City: 'Höhenkirchen-Siegert',
  Latitude: '480.197.260',
  Longitude: '117.098.720',
  Telephone: '81028284',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.019726,11.709872',
  id: '48.019726,11.709872'
}, {
  Brand: 'ARAL',
  Street: 'Salzburger Straße 49',
  PostCode: 83329,
  City: 'Waging',
  Latitude: '479.341.140',
  Longitude: '127.418.120',
  Telephone: '86814379',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.934114,12.741812',
  id: '47.934114,12.741812'
}, {
  Brand: 'ARAL',
  Street: 'Haunstetter Straße 139 1/',
  PostCode: 86161,
  City: 'Augsburg',
  Latitude: '483.337.320',
  Longitude: '109.081.190',
  Telephone: '821573303',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.333732,10.908119',
  id: '48.333732,10.908119'
}, {
  Brand: 'ARAL',
  Street: 'Garmischer Straße 197',
  PostCode: 81377,
  City: 'München',
  Latitude: '481.213.460',
  Longitude: '115.188.170',
  Telephone: '897608904',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.121346,11.518817',
  id: '48.121346,11.518817'
}, {
  Brand: 'ARAL',
  Street: 'Schongauer Straße 7',
  PostCode: 86899,
  City: 'Landsberg',
  Latitude: '480.389.100',
  Longitude: '108.647.850',
  Telephone: '81912727',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.03891,10.864785',
  id: '48.03891,10.864785'
}, {
  Brand: 'ARAL',
  Street: 'Plinganser Str. 70',
  PostCode: 81369,
  City: 'München',
  Latitude: '481.122.740',
  Longitude: '115.391.820',
  Telephone: '89761566',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.112274,11.539182',
  id: '48.112274,11.539182'
}, {
  Brand: 'ARAL',
  Street: 'Münchener Straße 66',
  PostCode: 87700,
  City: 'Memmingen',
  Latitude: '479.963.370',
  Longitude: '101.991.430',
  Telephone: '83315724',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.996337,10.199143',
  id: '47.996337,10.199143'
}, {
  Brand: 'ARAL',
  Street: 'Untere Hauptstraße 11',
  PostCode: 85386,
  City: 'Eching',
  Latitude: '482.981.790',
  Longitude: '116.250.840',
  Telephone: '893192811',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.298179,11.625084',
  id: '48.298179,11.625084'
}, {
  Brand: 'ARAL',
  Street: 'Manchinger Straße 76',
  PostCode: 85053,
  City: 'Ingolstadt',
  Latitude: '487.539.330',
  Longitude: '114.498.870',
  Telephone: '84168555',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.753933,11.449887',
  id: '48.753933,11.449887'
}, {
  Brand: 'ARAL',
  Street: 'Wasserburger Landstr. 51',
  PostCode: 81825,
  City: 'München',
  Latitude: '481.197.220',
  Longitude: '116.777.340',
  Telephone: '89424843',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.119722,11.677734',
  id: '48.119722,11.677734'
}, {
  Brand: 'ARAL',
  Street: 'Rosenheimer Straße 83',
  PostCode: 83064,
  City: 'Raubling',
  Latitude: '478.047.910',
  Longitude: '121.198.770',
  Telephone: '80352766',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.804791,12.119877',
  id: '47.804791,12.119877'
}, {
  Brand: 'ARAL',
  Street: 'Grüneckerstraße 6',
  PostCode: 85375,
  City: 'Neufahrn',
  Latitude: '483.130.860',
  Longitude: '116.651.980',
  Telephone: '81654610',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.313086,11.665198',
  id: '48.313086,11.665198'
}, {
  Brand: 'ARAL',
  Street: 'Salzburger Straße 64',
  PostCode: 83071,
  City: 'Stephanskirchen',
  Latitude: '478.603.930',
  Longitude: '121.543.400',
  Telephone: '803171081',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.860393,12.15434',
  id: '47.860393,12.15434'
}, {
  Brand: 'ARAL',
  Street: 'Niedermayerstraße 54',
  PostCode: 84036,
  City: 'Landshut',
  Latitude: '485.462.070',
  Longitude: '121.810.830',
  Telephone: '87152916',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.546207,12.181083',
  id: '48.546207,12.181083'
}, {
  Brand: 'ARAL',
  Street: 'Augsburger Straße 32',
  PostCode: 87600,
  City: 'Kaufbeuren',
  Latitude: '478.845.400',
  Longitude: '106.367.120',
  Telephone: '83414859',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.88454,10.636712',
  id: '47.88454,10.636712'
}, {
  Brand: 'ARAL',
  Street: 'Landshuter Allee 163',
  PostCode: 80637,
  City: 'München',
  Latitude: '481.635.550',
  Longitude: '115.373.430',
  Telephone: '89156225',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.163555,11.537343',
  id: '48.163555,11.537343'
}, {
  Brand: 'ARAL',
  Street: 'Miesbacher Straße 33',
  PostCode: 83607,
  City: 'Holzkirchen',
  Latitude: '478.758.050',
  Longitude: '117.101.660',
  Telephone: '8024998184',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.875805,11.710166',
  id: '47.875805,11.710166'
}, {
  Brand: 'ARAL',
  Street: 'Augsburger Straße 42',
  PostCode: 86381,
  City: 'Krumbach',
  Latitude: '482.439.010',
  Longitude: '103.724.050',
  Telephone: '82823059',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '48.243901,10.372405',
  id: '48.243901,10.372405'
}, {
  Brand: 'ARAL',
  Street: 'Loferer Straße 29',
  PostCode: 83435,
  City: 'Bad Reichenhall',
  Latitude: '477.338.640',
  Longitude: '128.770.830',
  Telephone: '86512325',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.733864,12.877083',
  id: '47.733864,12.877083'
}, {
  Brand: 'ARAL',
  Street: 'Donauwörther Straße 15',
  PostCode: 86462,
  City: 'Langweid',
  Latitude: '484.652.980',
  Longitude: '108.660.530',
  Telephone: '821492625',
  WeekDayFrom: '05:30',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:30',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '48.465298,10.866053',
  id: '48.465298,10.866053'
}, {
  Brand: 'ARAL',
  Street: 'Leopoldstraße 208',
  PostCode: 80804,
  City: 'München',
  Latitude: '481.753.270',
  Longitude: '115.859.650',
  Telephone: '89363171',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.175327,11.585965',
  id: '48.175327,11.585965'
}, {
  Brand: 'ARAL',
  Street: 'A9',
  PostCode: 85301,
  City: 'Geisenhausen',
  Latitude: '485.565.500',
  Longitude: '115.879.130',
  Telephone: '8441801573',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.55655,11.587913',
  id: '48.55655,11.587913'
}, {
  Brand: 'ARAL',
  Street: 'A9',
  PostCode: 85120,
  City: 'Hepberg',
  Latitude: '488.361.480',
  Longitude: '114.711.640',
  Telephone: '84051336',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.836148,11.471164',
  id: '48.836148,11.471164'
}, {
  Brand: 'ARAL',
  Street: 'Schenkendorfstraße 15',
  PostCode: 80807,
  City: 'München',
  Latitude: '481.772.930',
  Longitude: '115.893.530',
  Telephone: '89364016',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.177293,11.589353',
  id: '48.177293,11.589353'
}, {
  Brand: 'ARAL',
  Street: 'Schumacherring 110',
  PostCode: 87437,
  City: 'Kempten',
  Latitude: '477.334.690',
  Longitude: '103.253.490',
  Telephone: '83173262',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.733469,10.325349',
  id: '47.733469,10.325349'
}, {
  Brand: 'ARAL',
  Street: 'Rosenheimer Straße 30 a',
  PostCode: 83059,
  City: 'Kolbermoor',
  Latitude: '478.498.760',
  Longitude: '120.677.060',
  Telephone: '803191354',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.849876,12.067706',
  id: '47.849876,12.067706'
}, {
  Brand: 'ARAL',
  Street: 'Haagerstraße 20',
  PostCode: 83558,
  City: 'Maitenbeth',
  Latitude: '481.535.790',
  Longitude: '120.921.830',
  Telephone: '807691930',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '48.153579,12.092183',
  id: '48.153579,12.092183'
}, {
  Brand: 'ARAL',
  Street: 'Boschetsrieder Straße 127',
  PostCode: 81379,
  City: 'München',
  Latitude: '480.984.630',
  Longitude: '115.185.780',
  Telephone: '89786153',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.098463,11.518578',
  id: '48.098463,11.518578'
}, {
  Brand: 'ARAL',
  Street: 'Dülferstraße 3',
  PostCode: 80933,
  City: 'München',
  Latitude: '482.126.490',
  Longitude: '115.601.390',
  Telephone: '8931207480',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '48.212649,11.560139',
  id: '48.212649,11.560139'
}, {
  Brand: 'ARAL',
  Street: 'Heinrich-Wieland-Straße 2',
  PostCode: 81735,
  City: 'München',
  Latitude: '481.183.280',
  Longitude: '116.310.800',
  Telephone: '89401320',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.118328,11.63108',
  id: '48.118328,11.63108'
}, {
  Brand: 'ARAL',
  Street: 'Tegernseer Landstraße 174',
  PostCode: 81539,
  City: 'München',
  Latitude: '481.077.420',
  Longitude: '115.795.320',
  Telephone: '896202810',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.107742,11.579532',
  id: '48.107742,11.579532'
}, {
  Brand: 'ARAL',
  Street: 'Äussere Rosenheimerstr23',
  PostCode: 83278,
  City: 'Traunstein',
  Latitude: '478.670.600',
  Longitude: '126.338.730',
  Telephone: '8613132',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.86706,12.633873',
  id: '47.86706,12.633873'
}, {
  Brand: 'ARAL',
  Street: 'Wasserburger Landstraße 3',
  PostCode: 85591,
  City: 'Vaterstetten',
  Latitude: '480.962.690',
  Longitude: '117.713.610',
  Telephone: '8106307446',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.096269,11.771361',
  id: '48.096269,11.771361'
}, {
  Brand: 'ARAL',
  Street: 'Landshuter Straße 9',
  PostCode: 84137,
  City: 'Vilsbiburg',
  Latitude: '484.486.630',
  Longitude: '123.510.330',
  Telephone: '8741926794',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '48.448663,12.351033',
  id: '48.448663,12.351033'
}, {
  Brand: 'ARAL',
  Street: 'Gilchingerstraße 31',
  PostCode: 82239,
  City: 'Alling',
  Latitude: '481.380.990',
  Longitude: '112.993.890',
  Telephone: '814171979',
  WeekDayFrom: '06:45',
  WeekDayTo: '20:30',
  SaturdayFrom: '07:30',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '19:30',
  IsOpen24Hours: 'Nein',
  location: '48.138099,11.299389',
  id: '48.138099,11.299389'
}, {
  Brand: 'ARAL',
  Street: 'Kemptener Straße 43',
  PostCode: 87452,
  City: 'Altusried',
  Latitude: '478.018.870',
  Longitude: '102.250.110',
  Telephone: '8373935375',
  WeekDayFrom: '06:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '22:00',
  SundayFrom: '07:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '47.801887,10.225011',
  id: '47.801887,10.225011'
}, {
  Brand: 'ARAL',
  Street: 'Stätzlinger Straße 86',
  PostCode: 86165,
  City: 'Augsburg',
  Latitude: '483.782.010',
  Longitude: '109.374.710',
  Telephone: '821713575',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '48.378201,10.937471',
  id: '48.378201,10.937471'
}, {
  Brand: 'ARAL',
  Street: 'Sachsenkamer Straße 23',
  PostCode: 83646,
  City: 'Bad Tölz',
  Latitude: '477.595.930',
  Longitude: '115.735.660',
  Telephone: '80415303',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.759593,11.573566',
  id: '47.759593,11.573566'
}, {
  Brand: 'ARAL',
  Street: 'Kemptener Straße 23',
  PostCode: 87634,
  City: 'Obergünzburg',
  Latitude: '478.430.430',
  Longitude: '104.165.260',
  Telephone: '8372496',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '47.843043,10.416526',
  id: '47.843043,10.416526'
}, {
  Brand: 'ARAL',
  Street: 'Allgäuerstraße 39',
  PostCode: 87459,
  City: 'Pfronten',
  Latitude: '475.846.240',
  Longitude: '105.592.780',
  Telephone: '8363296',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.584624,10.559278',
  id: '47.584624,10.559278'
}, {
  Brand: 'ARAL',
  Street: 'Freischützstraße 4',
  PostCode: 81927,
  City: 'München',
  Latitude: '481.575.690',
  Longitude: '116.422.390',
  Telephone: '89935312',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.157569,11.642239',
  id: '48.157569,11.642239'
}, {
  Brand: 'ARAL',
  Street: 'Münchener Straße 16',
  PostCode: 85540,
  City: 'Haar',
  Latitude: '481.085.380',
  Longitude: '117.221.060',
  Telephone: '89467300',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.108538,11.722106',
  id: '48.108538,11.722106'
}, {
  Brand: 'ARAL',
  Street: 'Georg-Brauchle-Ring 27',
  PostCode: 80992,
  City: 'München',
  Latitude: '481.761.040',
  Longitude: '115.356.160',
  Telephone: '89155116',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.176104,11.535616',
  id: '48.176104,11.535616'
}, {
  Brand: 'ARAL',
  Street: 'Richard Strauss-Straße 70',
  PostCode: 81679,
  City: 'München',
  Latitude: '481.457.180',
  Longitude: '116.163.390',
  Telephone: '89915079',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.145718,11.616339',
  id: '48.145718,11.616339'
}, {
  Brand: 'ARAL',
  Street: 'Isarstraße 3',
  PostCode: 85356,
  City: 'Freising',
  Latitude: '483.974.590',
  Longitude: '117.592.900',
  Telephone: '816183048',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.397459,11.75929',
  id: '48.397459,11.75929'
}, {
  Brand: 'ARAL',
  Street: 'Josef-Greschniok-Straße 1',
  PostCode: 94051,
  City: 'Hauzenberg',
  Latitude: '486.540.140',
  Longitude: '136.206.010',
  Telephone: '85861342',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.654014,13.620601',
  id: '48.654014,13.620601'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 20',
  PostCode: 82467,
  City: 'Garmisch-Partenkirch',
  Latitude: '475.004.530',
  Longitude: '111.014.430',
  Telephone: '882152733',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:30',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:30',
  SundayTo: '22:30',
  IsOpen24Hours: 'Nein',
  location: '47.500453,11.101443',
  id: '47.500453,11.101443'
}, {
  Brand: 'ARAL',
  Street: 'Bernecker Straße 49',
  PostCode: 95448,
  City: 'Bayreuth',
  Latitude: '499.547.970',
  Longitude: '116.000.940',
  Telephone: '92113046',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.954797,11.600094',
  id: '49.954797,11.600094'
}, {
  Brand: 'ARAL',
  Street: 'Oberstenfelder Straße 24',
  PostCode: 71717,
  City: 'Beilstein',
  Latitude: '490.348.160',
  Longitude: '93.162.730',
  Telephone: '70625670',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '49.034816,9.316273',
  id: '49.034816,9.316273'
}, {
  Brand: 'ARAL',
  Street: 'Bärnzeller Straße 5',
  PostCode: 94227,
  City: 'Zwiesel',
  Latitude: '490.010.610',
  Longitude: '132.203.350',
  Telephone: '9922802644',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.001061,13.220335',
  id: '49.001061,13.220335'
}, {
  Brand: 'ARAL',
  Street: 'Teplitzer Straße 24',
  PostCode: 84478,
  City: 'Waldkraiburg',
  Latitude: '482.116.360',
  Longitude: '124.173.230',
  Telephone: '86384185',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.211636,12.417323',
  id: '48.211636,12.417323'
}, {
  Brand: 'ARAL',
  Street: 'Passauer Straße 39',
  PostCode: 94081,
  City: 'Fürstenzell',
  Latitude: '485.251.870',
  Longitude: '133.270.440',
  Telephone: '85021313',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '05:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.525187,13.327044',
  id: '48.525187,13.327044'
}, {
  Brand: 'ARAL',
  Street: 'Gleiwitzer Straße 8',
  PostCode: 93073,
  City: 'Neutraubling',
  Latitude: '489.882.260',
  Longitude: '121.893.620',
  Telephone: '940179060',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.988226,12.189362',
  id: '48.988226,12.189362'
}, {
  Brand: 'ARAL',
  Street: 'Ingolstädter Str. 164-166',
  PostCode: 80939,
  City: 'München',
  Latitude: '482.017.770',
  Longitude: '115.861.490',
  Telephone: '8931699843',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.201777,11.586149',
  id: '48.201777,11.586149'
}, {
  Brand: 'ARAL',
  Street: 'Hofer Straße 2 / B 303',
  PostCode: 95502,
  City: 'Himmelkron',
  Latitude: '500.546.130',
  Longitude: '116.227.850',
  Telephone: '927357337',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.054613,11.622785',
  id: '50.054613,11.622785'
}, {
  Brand: 'ARAL',
  Street: 'Freisinger Straße 21',
  PostCode: 84048,
  City: 'Mainburg',
  Latitude: '486.357.650',
  Longitude: '117.791.980',
  Telephone: '87515838',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Ja',
  location: '48.635765,11.779198',
  id: '48.635765,11.779198'
}, {
  Brand: 'ARAL',
  Street: 'Heinrich-Lanz-Straße 1',
  PostCode: 70825,
  City: 'Korntal-Münchingen',
  Latitude: '488.505.880',
  Longitude: '91.054.710',
  Telephone: '7150959920',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.850588,9.105471',
  id: '48.850588,9.105471'
}, {
  Brand: 'ARAL',
  Street: 'Elbestraße 23',
  PostCode: 82538,
  City: 'Geretsried',
  Latitude: '478.804.460',
  Longitude: '114.608.220',
  Telephone: '817164255',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.880446,11.460822',
  id: '47.880446,11.460822'
}, {
  Brand: 'ARAL',
  Street: 'Forstenrieder Straße 26',
  PostCode: 82061,
  City: 'Neuried',
  Latitude: '480.914.060',
  Longitude: '114.682.990',
  Telephone: '8975070710',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.091406,11.468299',
  id: '48.091406,11.468299'
}, {
  Brand: 'ARAL',
  Street: 'Sigwolfstraße 2',
  PostCode: 85435,
  City: 'Erding',
  Latitude: '483.148.700',
  Longitude: '118.925.670',
  Telephone: '8122902786',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.31487,11.892567',
  id: '48.31487,11.892567'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 60',
  PostCode: 74360,
  City: 'Ilsfeld',
  Latitude: '490.588.820',
  Longitude: '92.712.010',
  Telephone: '706295220',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.058882,9.271201',
  id: '49.058882,9.271201'
}, {
  Brand: 'ARAL',
  Street: 'Gottlieb-Daimler-Straße 4',
  PostCode: 95666,
  City: 'Mitterteich',
  Latitude: '499.367.100',
  Longitude: '122.270.680',
  Telephone: '96334000029',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.93671,12.227068',
  id: '49.93671,12.227068'
}, {
  Brand: 'ARAL',
  Street: 'Landshuter Straße 42',
  PostCode: 85368,
  City: 'Moosburg',
  Latitude: '484.639.530',
  Longitude: '119.398.140',
  Telephone: '8761729846',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.463953,11.939814',
  id: '48.463953,11.939814'
}, {
  Brand: 'ARAL',
  Street: 'Pragstraße 138 A',
  PostCode: 70376,
  City: 'Stuttgart',
  Latitude: '488.075.380',
  Longitude: '91.941.540',
  Telephone: '7118560500',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.807538,9.194154',
  id: '48.807538,9.194154'
}, {
  Brand: 'ARAL',
  Street: 'Stuttgarter Straße 119',
  PostCode: 73054,
  City: 'Eislingen',
  Latitude: '486.964.190',
  Longitude: '96.902.370',
  Telephone: '7161990220',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.696419,9.690237',
  id: '48.696419,9.690237'
}, {
  Brand: 'ARAL',
  Street: 'Plieninger Straße 35',
  PostCode: 73765,
  City: 'Neuhausen',
  Latitude: '486.912.510',
  Longitude: '92.657.360',
  Telephone: '7158940101',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.691251,9.265736',
  id: '48.691251,9.265736'
}, {
  Brand: 'ARAL',
  Street: 'Laimeringer Straße 2',
  PostCode: 86453,
  City: 'Dasing',
  Latitude: '483.899.620',
  Longitude: '110.634.020',
  Telephone: '8205969083',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.389962,11.063402',
  id: '48.389962,11.063402'
}, {
  Brand: 'ARAL',
  Street: 'Enkeringer Straße 9',
  PostCode: 85125,
  City: 'Kinding',
  Latitude: '489.981.720',
  Longitude: '113.778.870',
  Telephone: '8467805892',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.998172,11.377887',
  id: '48.998172,11.377887'
}, {
  Brand: 'ARAL',
  Street: 'Zadarstrasse 11',
  PostCode: 82256,
  City: 'Fürstenfeldbruck',
  Latitude: '481.775.340',
  Longitude: '112.272.860',
  Telephone: '8141355400',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.177534,11.227286',
  id: '48.177534,11.227286'
}, {
  Brand: 'ARAL',
  Street: 'Alte Römerstraße 50',
  PostCode: 85221,
  City: 'Dachau',
  Latitude: '482.596.860',
  Longitude: '114.699.370',
  Telephone: '8131333352',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.259686,11.469937',
  id: '48.259686,11.469937'
}, {
  Brand: 'ARAL',
  Street: 'Friedrichstraße 160',
  PostCode: 71638,
  City: 'Ludwigsburg',
  Latitude: '488.886.610',
  Longitude: '92.135.500',
  Telephone: '7141890454',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.888661,9.21355',
  id: '48.888661,9.21355'
}, {
  Brand: 'ARAL',
  Street: 'Heilbronner Straße 289',
  PostCode: 70469,
  City: 'Stuttgart',
  Latitude: '488.126.110',
  Longitude: '91.799.960',
  Telephone: '7118560056',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.812611,9.179996',
  id: '48.812611,9.179996'
}, {
  Brand: 'ARAL',
  Street: 'Binderfeld 2',
  PostCode: 84518,
  City: 'Garching',
  Latitude: '481.259.350',
  Longitude: '125.741.690',
  Telephone: '8634400',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.125935,12.574169',
  id: '48.125935,12.574169'
}, {
  Brand: 'ARAL',
  Street: 'A8',
  PostCode: 71067,
  City: 'Sindelfingen',
  Latitude: '487.425.780',
  Longitude: '90.341.820',
  Telephone: '703170810',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.742578,9.034182',
  id: '48.742578,9.034182'
}, {
  Brand: 'ARAL',
  Street: 'Münchener Straße 43',
  PostCode: 83707,
  City: 'Bad Wiessee',
  Latitude: '477.178.370',
  Longitude: '117.189.550',
  Telephone: '8022857950',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '47.717837,11.718955',
  id: '47.717837,11.718955'
}, {
  Brand: 'ARAL',
  Street: 'Maybachstraße 101',
  PostCode: 70771,
  City: 'Leinfelden',
  Latitude: '487.089.590',
  Longitude: '91.430.080',
  Telephone: '7119975646',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.708959,9.143008',
  id: '48.708959,9.143008'
}, {
  Brand: 'ARAL',
  Street: 'Nördlinger Straße 18-22',
  PostCode: 73450,
  City: 'Neresheim',
  Latitude: '487.542.950',
  Longitude: '103.366.070',
  Telephone: '7326962325',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:59',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.754295,10.336607',
  id: '48.754295,10.336607'
}, {
  Brand: 'ARAL',
  Street: 'Schwarzleite 2',
  PostCode: 91220,
  City: 'Schnaittach',
  Latitude: '495.672.910',
  Longitude: '113.274.950',
  Telephone: '91539229892',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.567291,11.327495',
  id: '49.567291,11.327495'
}, {
  Brand: 'ARAL',
  Street: 'Weißenbachstr. 2',
  PostCode: 87730,
  City: 'Bad Grönenbach',
  Latitude: '478.839.130',
  Longitude: '102.482.170',
  Telephone: '8334986108',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.883913,10.248217',
  id: '47.883913,10.248217'
}, {
  Brand: 'ARAL',
  Street: 'Am Südpark 2',
  PostCode: 86633,
  City: 'Neuburg',
  Latitude: '487.201.880',
  Longitude: '111.720.390',
  Telephone: '8431430346',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.720188,11.172039',
  id: '48.720188,11.172039'
}, {
  Brand: 'ARAL',
  Street: 'Max-Planck-Straße 8',
  PostCode: 93055,
  City: 'Regensburg',
  Latitude: '490.001.640',
  Longitude: '121.483.210',
  Telephone: '9417995752',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.000164,12.148321',
  id: '49.000164,12.148321'
}, {
  Brand: 'ARAL',
  Street: 'Linderhof 8',
  PostCode: 88079,
  City: 'Kressbronn',
  Latitude: '476.078.140',
  Longitude: '95.869.660',
  Telephone: '7543953347',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.607814,9.586966',
  id: '47.607814,9.586966'
}, {
  Brand: 'ARAL',
  Street: 'Arnbrucker Straße 1 a',
  PostCode: 93444,
  City: 'Bad Kötzting',
  Latitude: '491.712.600',
  Longitude: '128.631.880',
  Telephone: '9941904901',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.17126,12.863188',
  id: '49.17126,12.863188'
}, {
  Brand: 'ARAL',
  Street: 'Gewerbepark Spörer Au 1',
  PostCode: 85368,
  City: 'Wang bei Moosburg',
  Latitude: '484.827.430',
  Longitude: '120.005.790',
  Telephone: '8709263168',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.482743,12.000579',
  id: '48.482743,12.000579'
}, {
  Brand: 'ARAL',
  Street: 'Aindlinger Strasse 11',
  PostCode: 86167,
  City: 'Augsburg',
  Latitude: '483.926.920',
  Longitude: '109.334.180',
  Telephone: '8217472450',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.392692,10.933418',
  id: '48.392692,10.933418'
}, {
  Brand: 'ARAL',
  Street: 'Rodezstraße 2 a',
  PostCode: 96052,
  City: 'Bamberg',
  Latitude: '499.141.370',
  Longitude: '109.087.610',
  Telephone: '9513090155',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.914137,10.908761',
  id: '49.914137,10.908761'
}, {
  Brand: 'ARAL',
  Street: 'Am Eichelberg 2',
  PostCode: 91567,
  City: 'Herrieden',
  Latitude: '492.587.520',
  Longitude: '105.018.710',
  Telephone: '9825929790',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.258752,10.501871',
  id: '49.258752,10.501871'
}, {
  Brand: 'ARAL',
  Street: 'An der Autobahn 1',
  PostCode: 91126,
  City: 'Kammerstein',
  Latitude: '493.113.600',
  Longitude: '110.022.430',
  Telephone: '9122879822',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.31136,11.002243',
  id: '49.31136,11.002243'
}, {
  Brand: 'ARAL',
  Street: 'Ohmstraße 1',
  PostCode: 86415,
  City: 'Mering',
  Latitude: '482.741.360',
  Longitude: '109.778.980',
  Telephone: '8233781227',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.274136,10.977898',
  id: '48.274136,10.977898'
}, {
  Brand: 'ARAL',
  Street: 'Hochstraße12',
  PostCode: 91325,
  City: 'Adelsdorf',
  Latitude: '497.035.010',
  Longitude: '108.810.120',
  Telephone: '9195925772',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.703501,10.881012',
  id: '49.703501,10.881012'
}, {
  Brand: 'ARAL',
  Street: 'Gruber Straße 22',
  PostCode: 96271,
  City: 'Grub am Forst',
  Latitude: '502.226.290',
  Longitude: '110.427.670',
  Telephone: '95609816715',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.222629,11.042767',
  id: '50.222629,11.042767'
}, {
  Brand: 'ARAL',
  Street: 'Schleißheimer Straße 124',
  PostCode: 85748,
  City: 'Garching',
  Latitude: '482.503.990',
  Longitude: '116.067.000',
  Telephone: '8932666948',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.250399,11.6067',
  id: '48.250399,11.6067'
}, {
  Brand: 'ARAL',
  Street: 'Schroßlacher Straße 2',
  PostCode: 85391,
  City: 'Allershausen',
  Latitude: '484.284.260',
  Longitude: '115.854.410',
  Telephone: '8166993709',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.428426,11.585441',
  id: '48.428426,11.585441'
}, {
  Brand: 'ARAL',
  Street: 'Am Jackinger Berg',
  PostCode: 94113,
  City: 'Tiefenbach',
  Latitude: '486.204.070',
  Longitude: '134.161.740',
  Telephone: '8509937774',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.620407,13.416174',
  id: '48.620407,13.416174'
}, {
  Brand: 'ARAL',
  Street: 'Bruckerstraße 34 a',
  PostCode: 82266,
  City: 'Inning',
  Latitude: '480.887.720',
  Longitude: '111.509.870',
  Telephone: '8143997503',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.088772,11.150987',
  id: '48.088772,11.150987'
}, {
  Brand: 'ARAL',
  Street: 'Reichsgrafenstraße 35/A3',
  PostCode: 94036,
  City: 'Passau',
  Latitude: '485.875.790',
  Longitude: '133.659.380',
  Telephone: '85175688830',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.587579,13.365938',
  id: '48.587579,13.365938'
}, {
  Brand: 'ARAL',
  Street: 'Kobelweg 64 1/4',
  PostCode: 86156,
  City: 'Augsburg',
  Latitude: '483.856.340',
  Longitude: '108.511.880',
  Telephone: '8212401234',
  WeekDayFrom: '05:30',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:30',
  SaturdayTo: '23:00',
  SundayFrom: '05:30',
  SundayTo: '23:00',
  IsOpen24Hours: 'Ja',
  location: '48.385634,10.851188',
  id: '48.385634,10.851188'
}, {
  Brand: 'ARAL',
  Street: 'Inninger Straße 99',
  PostCode: 86179,
  City: 'Augsburg',
  Latitude: '483.095.440',
  Longitude: '108.904.550',
  Telephone: '8216084769',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.309544,10.890455',
  id: '48.309544,10.890455'
}, {
  Brand: 'ARAL',
  Street: 'A3',
  PostCode: 97084,
  City: 'Würzburg',
  Latitude: '497.521.560',
  Longitude: '99.627.980',
  Telephone: '93165907',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.752156,9.962798',
  id: '49.752156,9.962798'
}, {
  Brand: 'ARAL',
  Street: 'Pingsdorfer Straße 106',
  PostCode: 50321,
  City: 'Brühl',
  Latitude: '508.206.120',
  Longitude: '68.953.210',
  Telephone: '2232941130',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.820612,6.895321',
  id: '50.820612,6.895321'
}, {
  Brand: 'ARAL',
  Street: 'Neusser Straße45',
  PostCode: 52428,
  City: 'Jülich',
  Latitude: '509.280.460',
  Longitude: '63.652.750',
  Telephone: '24612180',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.928046,6.365275',
  id: '50.928046,6.365275'
}, {
  Brand: 'ARAL',
  Street: 'Aachener Straße 25',
  PostCode: 50126,
  City: 'Bergheim',
  Latitude: '509.504.490',
  Longitude: '66.255.080',
  Telephone: '227141568',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.950449,6.625508',
  id: '50.950449,6.625508'
}, {
  Brand: 'ARAL',
  Street: 'Schleidener Straße 152',
  PostCode: 52076,
  City: 'Aachen',
  Latitude: '507.063.520',
  Longitude: '61.818.280',
  Telephone: '24081460290',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.706352,6.181828',
  id: '50.706352,6.181828'
}, {
  Brand: 'ARAL',
  Street: 'Aachener Straße 1100',
  PostCode: 50858,
  City: 'Köln',
  Latitude: '509.383.900',
  Longitude: '68.484.110',
  Telephone: '2234943601',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.93839,6.848411',
  id: '50.93839,6.848411'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 275',
  PostCode: 52379,
  City: 'Langerwehe',
  Latitude: '508.166.280',
  Longitude: '63.719.370',
  Telephone: '24231745',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '50.816628,6.371937',
  id: '50.816628,6.371937'
}, {
  Brand: 'ARAL',
  Street: 'Berg.-Gladbacher Str. 417',
  PostCode: 51067,
  City: 'Köln',
  Latitude: '509.677.940',
  Longitude: '70.339.210',
  Telephone: '221632555',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.967794,7.033921',
  id: '50.967794,7.033921'
}, {
  Brand: 'ARAL',
  Street: 'Luxemburger Straße 58',
  PostCode: 50354,
  City: 'Hürth',
  Latitude: '508.948.560',
  Longitude: '69.050.270',
  Telephone: '223365260',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.894856,6.905027',
  id: '50.894856,6.905027'
}, {
  Brand: 'ARAL',
  Street: 'Kölner Straße 138-166',
  PostCode: 53902,
  City: 'Bad Münstereifel',
  Latitude: '505.671.290',
  Longitude: '67.640.120',
  Telephone: '22534010',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.567129,6.764012',
  id: '50.567129,6.764012'
}, {
  Brand: 'ARAL',
  Street: 'Geierstraße 55',
  PostCode: 53881,
  City: 'Euskirchen',
  Latitude: '506.293.930',
  Longitude: '68.456.240',
  Telephone: '22556284',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.629393,6.845624',
  id: '50.629393,6.845624'
}, {
  Brand: 'ARAL',
  Street: 'Am Verteilerkreis Westsei',
  PostCode: 50968,
  City: 'Köln',
  Latitude: '508.920.890',
  Longitude: '69.663.270',
  Telephone: '221381718',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.892089,6.966327',
  id: '50.892089,6.966327'
}, {
  Brand: 'ARAL',
  Street: 'Potsdamer Platz 2',
  PostCode: 53119,
  City: 'Bonn',
  Latitude: '507.430.650',
  Longitude: '70.784.590',
  Telephone: '2287668731',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.743065,7.078459',
  id: '50.743065,7.078459'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstr. 43',
  PostCode: 53783,
  City: 'Eitorf',
  Latitude: '507.737.060',
  Longitude: '74.447.560',
  Telephone: '22433133',
  WeekDayFrom: '05:30',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '23:00',
  SundayFrom: '07:30',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.773706,7.444756',
  id: '50.773706,7.444756'
}, {
  Brand: 'ARAL',
  Street: 'Kölner Straße 74',
  PostCode: 51429,
  City: 'Bergisch Gladbach',
  Latitude: '509.620.310',
  Longitude: '71.483.000',
  Telephone: '220453818',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:30',
  SundayFrom: '07:30',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.962031,7.1483',
  id: '50.962031,7.1483'
}, {
  Brand: 'ARAL',
  Street: 'Trierer Straße 703',
  PostCode: 52078,
  City: 'Aachen',
  Latitude: '507.519.800',
  Longitude: '61.593.200',
  Telephone: '241522087',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.75198,6.15932',
  id: '50.75198,6.15932'
}, {
  Brand: 'ARAL',
  Street: 'Römerstraße 102-110',
  PostCode: 53111,
  City: 'Bonn',
  Latitude: '507.465.750',
  Longitude: '70.996.090',
  Telephone: '228636321',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.746575,7.099609',
  id: '50.746575,7.099609'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 384',
  PostCode: 52146,
  City: 'Würselen',
  Latitude: '508.093.850',
  Longitude: '61.441.590',
  Telephone: '240592738',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.809385,6.144159',
  id: '50.809385,6.144159'
}, {
  Brand: 'ARAL',
  Street: 'Rottbitzer Straße 32',
  PostCode: 53604,
  City: 'Bad Honnef',
  Latitude: '506.466.970',
  Longitude: '73.140.590',
  Telephone: '222480045',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:30',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.646697,7.314059',
  id: '50.646697,7.314059'
}, {
  Brand: 'ARAL',
  Street: 'Kölner Straße 13',
  PostCode: 53937,
  City: 'Schleiden',
  Latitude: '505.706.950',
  Longitude: '65.061.200',
  Telephone: '24442395',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:30',
  SundayFrom: '09:00',
  SundayTo: '21:30',
  IsOpen24Hours: 'Nein',
  location: '50.570695,6.50612',
  id: '50.570695,6.50612'
}, {
  Brand: 'ARAL',
  Street: 'Altenberger Dom-Straße 2',
  PostCode: 51519,
  City: 'Odenthal',
  Latitude: '510.313.890',
  Longitude: '71.113.780',
  Telephone: '220271845',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:30',
  SundayFrom: '08:00',
  SundayTo: '21:30',
  IsOpen24Hours: 'Nein',
  location: '51.031389,7.111378',
  id: '51.031389,7.111378'
}, {
  Brand: 'ARAL',
  Street: 'Odenthaler Straße 205',
  PostCode: 51467,
  City: 'Bergisch Gladbach',
  Latitude: '510.001.980',
  Longitude: '71.312.040',
  Telephone: '220235627',
  WeekDayFrom: '05:30',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.000198,7.131204',
  id: '51.000198,7.131204'
}, {
  Brand: 'ARAL',
  Street: 'Königswinterer Straße 273',
  PostCode: 53639,
  City: 'Königswinter',
  Latitude: '506.809.750',
  Longitude: '72.726.250',
  Telephone: '2223900971',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.680975,7.272625',
  id: '50.680975,7.272625'
}, {
  Brand: 'ARAL',
  Street: 'Heerstraße 88',
  PostCode: 53894,
  City: 'Mechernich',
  Latitude: '505.883.520',
  Longitude: '66.471.330',
  Telephone: '244331365',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.588352,6.647133',
  id: '50.588352,6.647133'
}, {
  Brand: 'ARAL',
  Street: 'Krefelder Straße 17',
  PostCode: 52146,
  City: 'Würselen',
  Latitude: '508.267.540',
  Longitude: '61.280.960',
  Telephone: '240582575',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.826754,6.128096',
  id: '50.826754,6.128096'
}, {
  Brand: 'ARAL',
  Street: 'Landsberger Straße 2',
  PostCode: 53119,
  City: 'Bonn',
  Latitude: '507.474.920',
  Longitude: '70.673.350',
  Telephone: '228661806',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.747492,7.067335',
  id: '50.747492,7.067335'
}, {
  Brand: 'ARAL',
  Street: 'Rösrather Straße 521B/ Lü',
  PostCode: 51107,
  City: 'Köln',
  Latitude: '509.236.070',
  Longitude: '70.792.320',
  Telephone: '221868077',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.923607,7.079232',
  id: '50.923607,7.079232'
}, {
  Brand: 'ARAL',
  Street: 'Friedrich-Ebert-Allee 97',
  PostCode: 52066,
  City: 'Aachen',
  Latitude: '507.627.000',
  Longitude: '60.969.540',
  Telephone: '24166204',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.7627,6.096954',
  id: '50.7627,6.096954'
}, {
  Brand: 'ARAL',
  Street: 'Kölnstraße 1',
  PostCode: 50999,
  City: 'Köln',
  Latitude: '508.697.950',
  Longitude: '70.055.830',
  Telephone: '2236962590',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.869795,7.005583',
  id: '50.869795,7.005583'
}, {
  Brand: 'ARAL',
  Street: 'Venloer Straße 39',
  PostCode: 50259,
  City: 'Pulheim',
  Latitude: '509.953.720',
  Longitude: '68.103.290',
  Telephone: '223854747',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.995372,6.810329',
  id: '50.995372,6.810329'
}, {
  Brand: 'ARAL',
  Street: 'Amsterdamer Straße 194',
  PostCode: 50735,
  City: 'Köln',
  Latitude: '509.747.470',
  Longitude: '69.703.220',
  Telephone: '2217127826',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.974747,6.970322',
  id: '50.974747,6.970322'
}, {
  Brand: 'ARAL',
  Street: 'Bonner Straße 2',
  PostCode: 51379,
  City: 'Leverkusen',
  Latitude: '510.668.920',
  Longitude: '69.910.810',
  Telephone: '2171715830',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.066892,6.991081',
  id: '51.066892,6.991081'
}, {
  Brand: 'ARAL',
  Street: 'Dünweg 55',
  PostCode: 51399,
  City: 'Burscheid',
  Latitude: '510.938.020',
  Longitude: '71.450.460',
  Telephone: '2174786368',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.093802,7.145046',
  id: '51.093802,7.145046'
}, {
  Brand: 'ARAL',
  Street: 'Römerstraße 30',
  PostCode: 52382,
  City: 'Niederzier',
  Latitude: '508.666.460',
  Longitude: '64.349.190',
  Telephone: '24283021',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.866646,6.434919',
  id: '50.866646,6.434919'
}, {
  Brand: 'ARAL',
  Street: 'Longericher Straße 543',
  PostCode: 50739,
  City: 'Köln',
  Latitude: '509.989.090',
  Longitude: '69.120.420',
  Telephone: '2215992601',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.998909,6.912042',
  id: '50.998909,6.912042'
}, {
  Brand: 'ARAL',
  Street: 'Willy-Brandt-Ring  101',
  PostCode: 51375,
  City: 'Leverkusen',
  Latitude: '510.273.380',
  Longitude: '70.420.830',
  Telephone: '21453024',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.027338,7.042083',
  id: '51.027338,7.042083'
}, {
  Brand: 'ARAL',
  Street: 'Kerpener Straße  185',
  PostCode: 50170,
  City: 'Kerpen',
  Latitude: '508.973.370',
  Longitude: '66.837.570',
  Telephone: '227351765',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.897337,6.683757',
  id: '50.897337,6.683757'
}, {
  Brand: 'ARAL',
  Street: 'Im Wingert 20',
  PostCode: 53115,
  City: 'Bonn',
  Latitude: '507.180.980',
  Longitude: '70.910.640',
  Telephone: '228212348',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.718098,7.091064',
  id: '50.718098,7.091064'
}, {
  Brand: 'ARAL',
  Street: 'Waldnieler Straße 60',
  PostCode: 41068,
  City: 'Mönchengladbach',
  Latitude: '511.948.290',
  Longitude: '64.196.370',
  Telephone: '2161837907',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.194829,6.419637',
  id: '51.194829,6.419637'
}, {
  Brand: 'ARAL',
  Street: 'Ringstraße 30',
  PostCode: 45219,
  City: 'Essen',
  Latitude: '513.613.930',
  Longitude: '69.436.230',
  Telephone: '20545132',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.361393,6.943623',
  id: '51.361393,6.943623'
}, {
  Brand: 'ARAL',
  Street: 'Kölner Straße 107',
  PostCode: 41199,
  City: 'Mönchengladbach',
  Latitude: '511.279.340',
  Longitude: '64.535.920',
  Telephone: '2166601018',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.127934,6.453592',
  id: '51.127934,6.453592'
}, {
  Brand: 'ARAL',
  Street: 'Eickener Straße 357',
  PostCode: 41063,
  City: 'Mönchengladbach',
  Latitude: '512.123.240',
  Longitude: '64.481.930',
  Telephone: '2161184615',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.212324,6.448193',
  id: '51.212324,6.448193'
}, {
  Brand: 'ARAL',
  Street: 'Roermonder Straße 2',
  PostCode: 41372,
  City: 'Niederkrüchten',
  Latitude: '512.107.760',
  Longitude: '61.432.330',
  Telephone: '216382397',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.210776,6.143233',
  id: '51.210776,6.143233'
}, {
  Brand: 'ARAL',
  Street: 'Lauerstraße 12',
  PostCode: 41812,
  City: 'Erkelenz',
  Latitude: '510.963.170',
  Longitude: '62.154.890',
  Telephone: '24326252',
  WeekDayFrom: '04:45',
  WeekDayTo: '20:00',
  SaturdayFrom: '05:30',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '51.096317,6.215489',
  id: '51.096317,6.215489'
}, {
  Brand: 'ARAL',
  Street: 'Gladbacher Straße 21',
  PostCode: 41849,
  City: 'Wassenberg',
  Latitude: '511.072.430',
  Longitude: '61.785.860',
  Telephone: '243220725',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.107243,6.178586',
  id: '51.107243,6.178586'
}, {
  Brand: 'ARAL',
  Street: 'Liedberger Straße 25',
  PostCode: 41238,
  City: 'Mönchengladbach',
  Latitude: '511.563.620',
  Longitude: '65.073.810',
  Telephone: '216682959',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.156362,6.507381',
  id: '51.156362,6.507381'
}, {
  Brand: 'ARAL',
  Street: 'Beecker Straße 79',
  PostCode: 41844,
  City: 'Wegberg',
  Latitude: '511.381.920',
  Longitude: '62.873.540',
  Telephone: '24343371',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.138192,6.287354',
  id: '51.138192,6.287354'
}, {
  Brand: 'ARAL',
  Street: 'Vorster Straße 384',
  PostCode: 41169,
  City: 'Mönchengladbach',
  Latitude: '512.012.650',
  Longitude: '63.486.300',
  Telephone: '2161559241',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.201265,6.34863',
  id: '51.201265,6.34863'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße2',
  PostCode: 51674,
  City: 'Wiehl',
  Latitude: '509.492.200',
  Longitude: '75.450.730',
  Telephone: '22627074607',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.94922,7.545073',
  id: '50.94922,7.545073'
}, {
  Brand: 'ARAL',
  Street: 'Krefelder Straße 472',
  PostCode: 41066,
  City: 'Mönchengladbach',
  Latitude: '512.182.900',
  Longitude: '64.773.630',
  Telephone: '2161602426',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.21829,6.477363',
  id: '51.21829,6.477363'
}, {
  Brand: 'ARAL',
  Street: 'Marienburger Straße 3',
  PostCode: 41063,
  City: 'Mönchengladbach',
  Latitude: '512.031.040',
  Longitude: '64.189.790',
  Telephone: '216186849',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.203104,6.418979',
  id: '51.203104,6.418979'
}, {
  Brand: 'ARAL',
  Street: 'Brückenstraße 83',
  PostCode: 51643,
  City: 'Gummersbach',
  Latitude: '510.237.030',
  Longitude: '75.554.550',
  Telephone: '226165147',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.023703,7.555455',
  id: '51.023703,7.555455'
}, {
  Brand: 'ARAL',
  Street: 'Hardt 76',
  PostCode: 40764,
  City: 'Langenfeld',
  Latitude: '511.194.740',
  Longitude: '69.735.700',
  Telephone: '2173907790',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.119474,6.97357',
  id: '51.119474,6.97357'
}, {
  Brand: 'ARAL',
  Street: 'Ahnfeldstraße 60',
  PostCode: 40239,
  City: 'Düsseldorf',
  Latitude: '512.415.910',
  Longitude: '67.970.760',
  Telephone: '211627610',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.241591,6.797076',
  id: '51.241591,6.797076'
}, {
  Brand: 'ARAL',
  Street: 'Linde 19',
  PostCode: 42287,
  City: 'Wuppertal',
  Latitude: '512.336.800',
  Longitude: '72.362.600',
  Telephone: '202464793',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.23368,7.23626',
  id: '51.23368,7.23626'
}, {
  Brand: 'ARAL',
  Street: 'Westring 83',
  PostCode: 42329,
  City: 'Wuppertal',
  Latitude: '512.298.790',
  Longitude: '70.742.730',
  Telephone: '202781423',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.229879,7.074273',
  id: '51.229879,7.074273'
}, {
  Brand: 'ARAL',
  Street: 'Venloer Straße 143',
  PostCode: 41569,
  City: 'Rommerskirchen',
  Latitude: '510.365.580',
  Longitude: '66.776.270',
  Telephone: '21837079',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.036558,6.677627',
  id: '51.036558,6.677627'
}, {
  Brand: 'ARAL',
  Street: 'Cronenberger Straße 388',
  PostCode: 42349,
  City: 'Wuppertal',
  Latitude: '512.290.100',
  Longitude: '71.493.880',
  Telephone: '2024087581',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.22901,7.149388',
  id: '51.22901,7.149388'
}, {
  Brand: 'ARAL',
  Street: 'Königswinterer Straße 409',
  PostCode: 53227,
  City: 'Bonn',
  Latitude: '507.244.290',
  Longitude: '71.529.560',
  Telephone: '228442219',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '08:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '50.724429,7.152956',
  id: '50.724429,7.152956'
}, {
  Brand: 'ARAL',
  Street: 'Kaarster Straße 1',
  PostCode: 41564,
  City: 'Kaarst',
  Latitude: '512.184.340',
  Longitude: '66.059.880',
  Telephone: '2131605076',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.218434,6.605988',
  id: '51.218434,6.605988'
}, {
  Brand: 'ARAL',
  Street: 'In den Kötten 9',
  PostCode: 40627,
  City: 'Düsseldorf',
  Latitude: '512.124.100',
  Longitude: '68.578.210',
  Telephone: '211279993',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.21241,6.857821',
  id: '51.21241,6.857821'
}, {
  Brand: 'ARAL',
  Street: 'Hochdahler Straße 130',
  PostCode: 42781,
  City: 'Haan',
  Latitude: '511.937.760',
  Longitude: '69.879.600',
  Telephone: '2129342673',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.193776,6.98796',
  id: '51.193776,6.98796'
}, {
  Brand: 'ARAL',
  Street: 'Volkardeyer Straße 54',
  PostCode: 40878,
  City: 'Ratingen',
  Latitude: '512.886.630',
  Longitude: '68.275.080',
  Telephone: '210243980',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.288663,6.827508',
  id: '51.288663,6.827508'
}, {
  Brand: 'ARAL',
  Street: 'Norfer Straße 54',
  PostCode: 41468,
  City: 'Neuss',
  Latitude: '511.742.760',
  Longitude: '67.379.470',
  Telephone: '2131364947',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.174276,6.737947',
  id: '51.174276,6.737947'
}, {
  Brand: 'ARAL',
  Street: 'Schulstraße 83',
  PostCode: 41363,
  City: 'Jüchen',
  Latitude: '511.140.590',
  Longitude: '65.501.570',
  Telephone: '21817069775',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.114059,6.550157',
  id: '51.114059,6.550157'
}, {
  Brand: 'ARAL',
  Street: 'Elberfelder Straße 99',
  PostCode: 42553,
  City: 'Velbert',
  Latitude: '513.079.140',
  Longitude: '70.876.100',
  Telephone: '2053923666',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.307914,7.08761',
  id: '51.307914,7.08761'
}, {
  Brand: 'ARAL',
  Street: 'A3',
  PostCode: 42697,
  City: 'Solingen',
  Latitude: '511.589.610',
  Longitude: '69.610.620',
  Telephone: '21274210',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.158961,6.961062',
  id: '51.158961,6.961062'
}, {
  Brand: 'ARAL',
  Street: 'Friesdorfer Straße 195',
  PostCode: 53175,
  City: 'Bonn',
  Latitude: '506.919.470',
  Longitude: '71.369.830',
  Telephone: '228310151',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.691947,7.136983',
  id: '50.691947,7.136983'
}, {
  Brand: 'ARAL',
  Street: 'Dellbrücker Straße 2-4',
  PostCode: 51469,
  City: 'Bergisch Gladbach',
  Latitude: '509.881.560',
  Longitude: '70.874.470',
  Telephone: '220251865',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.988156,7.087447',
  id: '50.988156,7.087447'
}, {
  Brand: 'ARAL',
  Street: 'Ratinger Straße 19',
  PostCode: 42579,
  City: 'Heiligenhaus',
  Latitude: '513.219.820',
  Longitude: '69.556.890',
  Telephone: '205693030',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.321982,6.955689',
  id: '51.321982,6.955689'
}, {
  Brand: 'ARAL',
  Street: 'Volksbadstraße 1',
  PostCode: 41065,
  City: 'Mönchengladbach',
  Latitude: '512.036.760',
  Longitude: '64.748.360',
  Telephone: '2161601788',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.203676,6.474836',
  id: '51.203676,6.474836'
}, {
  Brand: 'ARAL',
  Street: 'Bergerstraße 108',
  PostCode: 51145,
  City: 'Köln',
  Latitude: '508.909.740',
  Longitude: '70.678.500',
  Telephone: '220332882',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.890974,7.06785',
  id: '50.890974,7.06785'
}, {
  Brand: 'ARAL',
  Street: 'Bonner Straße 134',
  PostCode: 53757,
  City: 'Sankt Augustin',
  Latitude: '507.784.050',
  Longitude: '71.908.810',
  Telephone: '2241203231',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.778405,7.190881',
  id: '50.778405,7.190881'
}, {
  Brand: 'ARAL',
  Street: 'Moselstraße 2',
  PostCode: 53842,
  City: 'Troisdorf',
  Latitude: '508.100.690',
  Longitude: '71.578.050',
  Telephone: '2241809276',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.810069,7.157805',
  id: '50.810069,7.157805'
}, {
  Brand: 'ARAL',
  Street: 'Heinsberger Straße 77',
  PostCode: 52511,
  City: 'Geilenkirchen',
  Latitude: '509.707.370',
  Longitude: '61.196.670',
  Telephone: '24519149696',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.970737,6.119667',
  id: '50.970737,6.119667'
}, {
  Brand: 'ARAL',
  Street: 'Vogelsanger Weg 46',
  PostCode: 50858,
  City: 'Köln',
  Latitude: '509.354.920',
  Longitude: '68.534.720',
  Telephone: '221486151',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '50.935492,6.853472',
  id: '50.935492,6.853472'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Straße 390',
  PostCode: 51103,
  City: 'Köln',
  Latitude: '509.427.470',
  Longitude: '70.272.550',
  Telephone: '221871436',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.942747,7.027255',
  id: '50.942747,7.027255'
}, {
  Brand: 'ARAL',
  Street: 'Neusser Straße 856',
  PostCode: 50737,
  City: 'Köln',
  Latitude: '509.931.980',
  Longitude: '69.405.700',
  Telephone: '2219742284',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.993198,6.94057',
  id: '50.993198,6.94057'
}, {
  Brand: 'ARAL',
  Street: 'Olpener Straße 1026',
  PostCode: 51109,
  City: 'Köln',
  Latitude: '509.468.810',
  Longitude: '70.852.260',
  Telephone: '221840873',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.946881,7.085226',
  id: '50.946881,7.085226'
}, {
  Brand: 'ARAL',
  Street: 'Riehler Straße 12-14',
  PostCode: 50668,
  City: 'Köln',
  Latitude: '509.523.790',
  Longitude: '69.616.440',
  Telephone: '2219726040',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.952379,6.961644',
  id: '50.952379,6.961644'
}, {
  Brand: 'ARAL',
  Street: 'Rolshover Straße 183',
  PostCode: 51105,
  City: 'Köln',
  Latitude: '509.272.770',
  Longitude: '70.002.140',
  Telephone: '221836527',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.927277,7.000214',
  id: '50.927277,7.000214'
}, {
  Brand: 'ARAL',
  Street: 'Siegburger Straße 116',
  PostCode: 50679,
  City: 'Köln',
  Latitude: '509.229.940',
  Longitude: '69.806.950',
  Telephone: '221812107',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.922994,6.980695',
  id: '50.922994,6.980695'
}, {
  Brand: 'ARAL',
  Street: 'Zollstockgürtel 39',
  PostCode: 50969,
  City: 'Köln',
  Latitude: '509.057.840',
  Longitude: '69.378.630',
  Telephone: '221364366',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.905784,6.937863',
  id: '50.905784,6.937863'
}, {
  Brand: 'ARAL',
  Street: 'Berghauser Straße 10',
  PostCode: 42349,
  City: 'Wuppertal',
  Latitude: '512.026.950',
  Longitude: '71.311.140',
  Telephone: '2022471654',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.202695,7.131114',
  id: '51.202695,7.131114'
}, {
  Brand: 'ARAL',
  Street: 'Roermonder Straße 41',
  PostCode: 52525,
  City: 'Heinsberg',
  Latitude: '510.941.570',
  Longitude: '60.819.700',
  Telephone: '24527601',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.094157,6.08197',
  id: '51.094157,6.08197'
}, {
  Brand: 'ARAL',
  Street: 'Südring 115',
  PostCode: 40221,
  City: 'Düsseldorf',
  Latitude: '512.010.120',
  Longitude: '67.632.590',
  Telephone: '211392503',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.201012,6.763259',
  id: '51.201012,6.763259'
}, {
  Brand: 'ARAL',
  Street: 'Dormagener Straße 20',
  PostCode: 50129,
  City: 'Bergheim',
  Latitude: '509.865.010',
  Longitude: '66.716.750',
  Telephone: '22716785851',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.986501,6.671675',
  id: '50.986501,6.671675'
}, {
  Brand: 'ARAL',
  Street: 'Nordheide 21',
  PostCode: 27798,
  City: 'Hude',
  Latitude: '531.098.470',
  Longitude: '84.660.420',
  Telephone: '4408939918',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '53.109847,8.466042',
  id: '53.109847,8.466042'
}, {
  Brand: 'ARAL',
  Street: 'Osnabrücker Straße 13',
  PostCode: 49525,
  City: 'Lengerich',
  Latitude: '521.860.980',
  Longitude: '78.615.570',
  Telephone: '54814793',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.186098,7.861557',
  id: '52.186098,7.861557'
}, {
  Brand: 'ARAL',
  Street: 'Bergische Landstraße 605',
  PostCode: 40629,
  City: 'Düsseldorf',
  Latitude: '512.605.090',
  Longitude: '69.087.240',
  Telephone: '2112913810',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.260509,6.908724',
  id: '51.260509,6.908724'
}, {
  Brand: 'ARAL',
  Street: 'Bottroper Straße 298 a',
  PostCode: 45356,
  City: 'Essen',
  Latitude: '514.843.660',
  Longitude: '69.757.810',
  Telephone: '2016143248',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '51.484366,6.975781',
  id: '51.484366,6.975781'
}, {
  Brand: 'ARAL',
  Street: 'Wuppertaler Straße 110',
  PostCode: 42653,
  City: 'Solingen',
  Latitude: '511.993.200',
  Longitude: '70.751.240',
  Telephone: '2122571905',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.19932,7.075124',
  id: '51.19932,7.075124'
}, {
  Brand: 'ARAL',
  Street: 'Sachsstraße 2',
  PostCode: 50259,
  City: 'Pulheim',
  Latitude: '509.574.610',
  Longitude: '68.012.790',
  Telephone: '2234698914',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.957461,6.801279',
  id: '50.957461,6.801279'
}, {
  Brand: 'ARAL',
  Street: 'Kempener Straße 83',
  PostCode: 41334,
  City: 'Nettetal',
  Latitude: '513.104.130',
  Longitude: '62.918.140',
  Telephone: '21532387',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.310413,6.291814',
  id: '51.310413,6.291814'
}, {
  Brand: 'ARAL',
  Street: 'Nordstraße 176',
  PostCode: 52353,
  City: 'Düren',
  Latitude: '508.324.290',
  Longitude: '64.586.060',
  Telephone: '2421204542',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.832429,6.458606',
  id: '50.832429,6.458606'
}, {
  Brand: 'ARAL',
  Street: 'A 61 Ost',
  PostCode: 50181,
  City: 'Bedburg',
  Latitude: '510.048.630',
  Longitude: '65.221.440',
  Telephone: '2272904330',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.004863,6.522144',
  id: '51.004863,6.522144'
}, {
  Brand: 'ARAL',
  Street: 'Kurfürstenallee 49',
  PostCode: 28329,
  City: 'Bremen',
  Latitude: '530.820.460',
  Longitude: '88.551.550',
  Telephone: '421444244',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.082046,8.855155',
  id: '53.082046,8.855155'
}, {
  Brand: 'ARAL',
  Street: 'Bremer Straße 72',
  PostCode: 26135,
  City: 'Oldenburg',
  Latitude: '531.292.610',
  Longitude: '82.272.420',
  Telephone: '4419987199',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.129261,8.227242',
  id: '53.129261,8.227242'
}, {
  Brand: 'ARAL',
  Street: 'Ostertorstraße 45',
  PostCode: 26670,
  City: 'Uplengen-Remels',
  Latitude: '533.053.170',
  Longitude: '77.463.610',
  Telephone: '4956927799',
  WeekDayFrom: '04:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '04:00',
  SaturdayTo: '23:00',
  SundayFrom: '04:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '53.305317,7.746361',
  id: '53.305317,7.746361'
}, {
  Brand: 'ARAL',
  Street: 'Leerer Landstraße 27',
  PostCode: 26603,
  City: 'Aurich',
  Latitude: '534.646.770',
  Longitude: '74.877.070',
  Telephone: '49412385',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '53.464677,7.487707',
  id: '53.464677,7.487707'
}, {
  Brand: 'ARAL',
  Street: 'Neuschanzer Straße 37',
  PostCode: 26831,
  City: 'Bunde',
  Latitude: '531.821.080',
  Longitude: '72.613.360',
  Telephone: '4953406',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.182108,7.261336',
  id: '53.182108,7.261336'
}, {
  Brand: 'ARAL',
  Street: 'Grosse Straße 2',
  PostCode: 49134,
  City: 'Wallenhorst',
  Latitude: '523.473.410',
  Longitude: '80.183.240',
  Telephone: '540739522',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '52.347341,8.018324',
  id: '52.347341,8.018324'
}, {
  Brand: 'ARAL',
  Street: 'An der alten Bundessstraße 1a',
  PostCode: 26419,
  City: 'Schortens',
  Latitude: '535.422.620',
  Longitude: '79.525.220',
  Telephone: '44618634',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.542262,7.952522',
  id: '53.542262,7.952522'
}, {
  Brand: 'ARAL',
  Street: 'Meller Landstraße 66',
  PostCode: 49086,
  City: 'Osnabrück',
  Latitude: '522.507.280',
  Longitude: '81.036.040',
  Telephone: '541389458',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:30',
  SundayFrom: '08:00',
  SundayTo: '21:30',
  IsOpen24Hours: 'Nein',
  location: '52.250728,8.103604',
  id: '52.250728,8.103604'
}, {
  Brand: 'ARAL',
  Street: 'Clemens-August-Straße 52',
  PostCode: 49751,
  City: 'Sögel',
  Latitude: '528.397.850',
  Longitude: '75.258.260',
  Telephone: '59521433',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '52.839785,7.525826',
  id: '52.839785,7.525826'
}, {
  Brand: 'ARAL',
  Street: 'Auricher Landstraße 16',
  PostCode: 26629,
  City: 'Großefehn',
  Latitude: '533.967.110',
  Longitude: '75.688.140',
  Telephone: '4943990303',
  WeekDayFrom: '05:30',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '53.396711,7.568814',
  id: '53.396711,7.568814'
}, {
  Brand: 'ARAL',
  Street: 'Jeverstraße 9',
  PostCode: 26409,
  City: 'Wittmund',
  Latitude: '535.763.780',
  Longitude: '77.923.240',
  Telephone: '44625511',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.576378,7.792324',
  id: '53.576378,7.792324'
}, {
  Brand: 'ARAL',
  Street: 'Huchtinger Heerstraße 4',
  PostCode: 28259,
  City: 'Bremen',
  Latitude: '530.537.810',
  Longitude: '87.407.790',
  Telephone: '421581445',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.053781,8.740779',
  id: '53.053781,8.740779'
}, {
  Brand: 'ARAL',
  Street: 'Malgartenerstraße 2',
  PostCode: 49565,
  City: 'Bramsche',
  Latitude: '524.050.210',
  Longitude: '79.884.310',
  Telephone: '54619951818',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.405021,7.988431',
  id: '52.405021,7.988431'
}, {
  Brand: 'ARAL',
  Street: 'Lange Straße 65',
  PostCode: 27318,
  City: 'Hoya',
  Latitude: '528.049.580',
  Longitude: '91.358.220',
  Telephone: '42512800',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '52.804958,9.135822',
  id: '52.804958,9.135822'
}, {
  Brand: 'ARAL',
  Street: 'Weserstraße 46',
  PostCode: 32547,
  City: 'Bad Oeynhausen',
  Latitude: '521.979.940',
  Longitude: '88.093.940',
  Telephone: '57313848',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.197994,8.809394',
  id: '52.197994,8.809394'
}, {
  Brand: 'ARAL',
  Street: 'Esenser Straße 109-111',
  PostCode: 26607,
  City: 'Aurich',
  Latitude: '534.824.000',
  Longitude: '74.903.950',
  Telephone: '494171435',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.4824,7.490395',
  id: '53.4824,7.490395'
}, {
  Brand: 'ARAL',
  Street: 'Meppener Straße 69',
  PostCode: 49808,
  City: 'Lingen',
  Latitude: '525.313.770',
  Longitude: '73.100.460',
  Telephone: '59163082',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.531377,7.310046',
  id: '52.531377,7.310046'
}, {
  Brand: 'ARAL',
  Street: 'Auricher Straße 2',
  PostCode: 26835,
  City: 'Hesel',
  Latitude: '533.056.650',
  Longitude: '75.938.390',
  Telephone: '49502034',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.305665,7.593839',
  id: '53.305665,7.593839'
}, {
  Brand: 'ARAL',
  Street: 'Grevener Damm 78',
  PostCode: 48282,
  City: 'Emsdetten',
  Latitude: '521.672.470',
  Longitude: '75.422.050',
  Telephone: '25722540',
  WeekDayFrom: '04:30',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '52.167247,7.542205',
  id: '52.167247,7.542205'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 204',
  PostCode: 26842,
  City: 'Ostrhauderfehn',
  Latitude: '531.401.100',
  Longitude: '76.380.330',
  Telephone: '49525236',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '53.14011,7.638033',
  id: '53.14011,7.638033'
}, {
  Brand: 'ARAL',
  Street: 'Stedinger Straße 174',
  PostCode: 27753,
  City: 'Delmenhorst',
  Latitude: '530.654.740',
  Longitude: '86.333.120',
  Telephone: '422151566',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.065474,8.633312',
  id: '53.065474,8.633312'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 78',
  PostCode: 49824,
  City: 'Emlichheim',
  Latitude: '526.074.290',
  Longitude: '68.390.730',
  Telephone: '59431946',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.607429,6.839073',
  id: '52.607429,6.839073'
}, {
  Brand: 'ARAL',
  Street: 'Sudweyher Straße 78',
  PostCode: 28844,
  City: 'Weyhe',
  Latitude: '529.830.670',
  Longitude: '88.778.030',
  Telephone: '42036162',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.983067,8.877803',
  id: '52.983067,8.877803'
}, {
  Brand: 'ARAL',
  Street: 'Gökerstraße/Ecke Peterstr',
  PostCode: 26384,
  City: 'Wilhelmshaven',
  Latitude: '535.204.220',
  Longitude: '81.235.900',
  Telephone: '4421994353',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.520422,8.12359',
  id: '53.520422,8.12359'
}, {
  Brand: 'ARAL',
  Street: 'Dr.Warsing-Straße 209',
  PostCode: 26802,
  City: 'Moormerland',
  Latitude: '533.086.500',
  Longitude: '75.047.250',
  Telephone: '49544228',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '53.30865,7.504725',
  id: '53.30865,7.504725'
}, {
  Brand: 'ARAL',
  Street: 'Prinzessinweg  2',
  PostCode: 26122,
  City: 'Oldenburg',
  Latitude: '531.433.580',
  Longitude: '81.917.740',
  Telephone: '441971971',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.143358,8.191774',
  id: '53.143358,8.191774'
}, {
  Brand: 'ARAL',
  Street: 'Bünder Straße 343',
  PostCode: 32120,
  City: 'Hiddenhausen',
  Latitude: '521.633.290',
  Longitude: '86.348.440',
  Telephone: '522161977',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '52.163329,8.634844',
  id: '52.163329,8.634844'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 13',
  PostCode: 31603,
  City: 'Diepenau-Lavelsloh',
  Latitude: '524.213.010',
  Longitude: '87.396.350',
  Telephone: '57751020',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '52.421301,8.739635',
  id: '52.421301,8.739635'
}, {
  Brand: 'ARAL',
  Street: 'Bremerhavener Heerstr. 48',
  PostCode: 28717,
  City: 'Bremen',
  Latitude: '531.731.020',
  Longitude: '87.038.320',
  Telephone: '421633622',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.173102,8.703832',
  id: '53.173102,8.703832'
}, {
  Brand: 'ARAL',
  Street: 'Osterfeuerberger Ring 65',
  PostCode: 28219,
  City: 'Bremen',
  Latitude: '531.027.580',
  Longitude: '87.972.150',
  Telephone: '421385309',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '53.102758,8.797215',
  id: '53.102758,8.797215'
}, {
  Brand: 'ARAL',
  Street: 'Mindener Straße 89',
  PostCode: 32547,
  City: 'Bad Oeynhausen',
  Latitude: '522.124.910',
  Longitude: '88.152.420',
  Telephone: '573129688',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.212491,8.815242',
  id: '52.212491,8.815242'
}, {
  Brand: 'ARAL',
  Street: 'Adelheider Straße 77',
  PostCode: 27755,
  City: 'Delmenhorst',
  Latitude: '530.321.450',
  Longitude: '86.216.280',
  Telephone: '422123577',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.032145,8.621628',
  id: '53.032145,8.621628'
}, {
  Brand: 'ARAL',
  Street: 'Bramscher Straße 267',
  PostCode: 49090,
  City: 'Osnabrück',
  Latitude: '523.010.980',
  Longitude: '80.346.640',
  Telephone: '54163204',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '52.301098,8.034664',
  id: '52.301098,8.034664'
}, {
  Brand: 'ARAL',
  Street: 'Oldenburger Straße 99',
  PostCode: 28259,
  City: 'Bremen',
  Latitude: '530.490.930',
  Longitude: '87.137.730',
  Telephone: '421583475',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.049093,8.713773',
  id: '53.049093,8.713773'
}, {
  Brand: 'ARAL',
  Street: 'Auricher Straße 216',
  PostCode: 26721,
  City: 'Emden',
  Latitude: '533.910.550',
  Longitude: '72.078.460',
  Telephone: '492142563',
  WeekDayFrom: '04:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '04:00',
  SaturdayTo: '23:00',
  SundayFrom: '04:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '53.391055,7.207846',
  id: '53.391055,7.207846'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 72',
  PostCode: 49509,
  City: 'Recke',
  Latitude: '523.627.430',
  Longitude: '77.187.790',
  Telephone: '54533410',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.362743,7.718779',
  id: '52.362743,7.718779'
}, {
  Brand: 'ARAL',
  Street: 'Hundsmühler Straße 86',
  PostCode: 26131,
  City: 'Oldenburg',
  Latitude: '531.258.750',
  Longitude: '81.900.650',
  Telephone: '4419558927',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.125875,8.190065',
  id: '53.125875,8.190065'
}, {
  Brand: 'ARAL',
  Street: 'Glückaufstraße 153',
  PostCode: 49124,
  City: 'Georgsmarienhütte',
  Latitude: '522.033.800',
  Longitude: '81.098.950',
  Telephone: '54015469',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:30',
  SundayFrom: '09:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '52.20338,8.109895',
  id: '52.20338,8.109895'
}, {
  Brand: 'ARAL',
  Street: 'Gütersloher Straße 123',
  PostCode: 33649,
  City: 'Bielefeld',
  Latitude: '519.904.020',
  Longitude: '84.921.460',
  Telephone: '521442240',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:30',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:30',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.990402,8.492146',
  id: '51.990402,8.492146'
}, {
  Brand: 'ARAL',
  Street: 'Paderborner Straße 324',
  PostCode: 33689,
  City: 'Bielefeld',
  Latitude: '519.321.470',
  Longitude: '86.069.170',
  Telephone: '5205984040',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.932147,8.606917',
  id: '51.932147,8.606917'
}, {
  Brand: 'ARAL',
  Street: 'Münsterstraße63',
  PostCode: 33775,
  City: 'Versmold',
  Latitude: '520.386.460',
  Longitude: '81.425.150',
  Telephone: '542394330',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.038646,8.142515',
  id: '52.038646,8.142515'
}, {
  Brand: 'ARAL',
  Street: 'Enger Straße 14',
  PostCode: 32139,
  City: 'Spenge',
  Latitude: '521.408.560',
  Longitude: '84.921.130',
  Telephone: '5225859898',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '20:30',
  IsOpen24Hours: 'Nein',
  location: '52.140856,8.492113',
  id: '52.140856,8.492113'
}, {
  Brand: 'ARAL',
  Street: 'Jöllenbecker Straße 565',
  PostCode: 33739,
  City: 'Bielefeld',
  Latitude: '520.950.250',
  Longitude: '85.136.640',
  Telephone: '52063110',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.095025,8.513664',
  id: '52.095025,8.513664'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 44',
  PostCode: 33803,
  City: 'Steinhagen',
  Latitude: '520.091.480',
  Longitude: '84.137.930',
  Telephone: '5204921937',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.009148,8.413793',
  id: '52.009148,8.413793'
}, {
  Brand: 'ARAL',
  Street: 'Heeper Straße 77',
  PostCode: 33607,
  City: 'Bielefeld',
  Latitude: '520.217.240',
  Longitude: '85.481.290',
  Telephone: '52169507',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.021724,8.548129',
  id: '52.021724,8.548129'
}, {
  Brand: 'ARAL',
  Street: 'Lange Straße 100',
  PostCode: 32139,
  City: 'Spenge',
  Latitude: '521.480.730',
  Longitude: '84.850.030',
  Telephone: '522587720',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.148073,8.485003',
  id: '52.148073,8.485003'
}, {
  Brand: 'ARAL',
  Street: 'Bielefelder Straße 108',
  PostCode: 33790,
  City: 'Halle',
  Latitude: '520.508.650',
  Longitude: '83.768.570',
  Telephone: '52019641',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.050865,8.376857',
  id: '52.050865,8.376857'
}, {
  Brand: 'ARAL',
  Street: 'Hellweg 2 b',
  PostCode: 32825,
  City: 'Blomberg',
  Latitude: '519.395.350',
  Longitude: '90.909.210',
  Telephone: '52358089',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.939535,9.090921',
  id: '51.939535,9.090921'
}, {
  Brand: 'ARAL',
  Street: 'Bielefelder Straße 36',
  PostCode: 33824,
  City: 'Werther',
  Latitude: '520.703.660',
  Longitude: '84.137.670',
  Telephone: '52033331',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.070366,8.413767',
  id: '52.070366,8.413767'
}, {
  Brand: 'ARAL',
  Street: 'Potsdamer Straße 7',
  PostCode: 33719,
  City: 'Bielefeld',
  Latitude: '520.278.280',
  Longitude: '86.035.130',
  Telephone: '521331355',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.027828,8.603513',
  id: '52.027828,8.603513'
}, {
  Brand: 'ARAL',
  Street: 'A1',
  PostCode: 49545,
  City: 'Tecklenburg',
  Latitude: '522.325.340',
  Longitude: '78.785.470',
  Telephone: '5456935801',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.232534,7.878547',
  id: '52.232534,7.878547'
}, {
  Brand: 'ARAL',
  Street: 'Stieghorster Straße 42',
  PostCode: 33605,
  City: 'Bielefeld',
  Latitude: '520.018.650',
  Longitude: '85.892.850',
  Telephone: '521200809',
  WeekDayFrom: '06:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.001865,8.589285',
  id: '52.001865,8.589285'
}, {
  Brand: 'ARAL',
  Street: 'Am Lehester Deich 62',
  PostCode: 28357,
  City: 'Bremen',
  Latitude: '531.163.670',
  Longitude: '88.999.660',
  Telephone: '421270076',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.116367,8.899966',
  id: '53.116367,8.899966'
}, {
  Brand: 'ARAL',
  Street: 'Heidenoldendorfer Str.16',
  PostCode: 32758,
  City: 'Detmold',
  Latitude: '519.413.670',
  Longitude: '88.594.170',
  Telephone: '523168884',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.941367,8.859417',
  id: '51.941367,8.859417'
}, {
  Brand: 'ARAL',
  Street: 'Burgstraße 89',
  PostCode: 49413,
  City: 'Dinklage',
  Latitude: '526.602.000',
  Longitude: '81.362.450',
  Telephone: '4443736',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.6602,8.136245',
  id: '52.6602,8.136245'
}, {
  Brand: 'ARAL',
  Street: 'Heisfelder Straße 212',
  PostCode: 26789,
  City: 'Leer',
  Latitude: '532.473.460',
  Longitude: '74.584.820',
  Telephone: '4913584',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.247346,7.458482',
  id: '53.247346,7.458482'
}, {
  Brand: 'ARAL',
  Street: 'Bremer Straße 20',
  PostCode: 49624,
  City: 'Löningen',
  Latitude: '527.382.010',
  Longitude: '77.604.900',
  Telephone: '54323228',
  WeekDayFrom: '06:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:30',
  SundayFrom: '08:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.738201,7.76049',
  id: '52.738201,7.76049'
}, {
  Brand: 'ARAL',
  Street: 'Denekamper Straße 15',
  PostCode: 48529,
  City: 'Nordhorn',
  Latitude: '524.289.750',
  Longitude: '70.623.360',
  Telephone: '59215341',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.428975,7.062336',
  id: '52.428975,7.062336'
}, {
  Brand: 'ARAL',
  Street: 'Alte Poststraße 2',
  PostCode: 49074,
  City: 'Osnabrück',
  Latitude: '522.769.450',
  Longitude: '80.552.640',
  Telephone: '54121730',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.276945,8.055264',
  id: '52.276945,8.055264'
}, {
  Brand: 'ARAL',
  Street: 'Stolzenauer Landstraße 10',
  PostCode: 31595,
  City: 'Steyerberg',
  Latitude: '525.637.920',
  Longitude: '90.287.060',
  Telephone: '5764314',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '52.563792,9.028706',
  id: '52.563792,9.028706'
}, {
  Brand: 'ARAL',
  Street: 'Bismarckstraße 147',
  PostCode: 26382,
  City: 'Wilhelmshaven',
  Latitude: '535.282.440',
  Longitude: '81.149.080',
  Telephone: '442133455',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.528244,8.114908',
  id: '53.528244,8.114908'
}, {
  Brand: 'ARAL',
  Street: 'Kirchdornberger Straße 85',
  PostCode: 33619,
  City: 'Bielefeld',
  Latitude: '520.489.000',
  Longitude: '84.524.100',
  Telephone: '521101266',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '52.0489,8.45241',
  id: '52.0489,8.45241'
}, {
  Brand: 'ARAL',
  Street: 'Mindener Straße 115',
  PostCode: 32049,
  City: 'Herford',
  Latitude: '521.290.690',
  Longitude: '86.867.500',
  Telephone: '522124623',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.129069,8.68675',
  id: '52.129069,8.68675'
}, {
  Brand: 'ARAL',
  Street: 'Königstraße 57',
  PostCode: 32584,
  City: 'Löhne',
  Latitude: '521.911.020',
  Longitude: '87.143.150',
  Telephone: '57322225',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.191102,8.714315',
  id: '52.191102,8.714315'
}, {
  Brand: 'ARAL',
  Street: 'Wüllener Straße 85',
  PostCode: 48683,
  City: 'Ahaus',
  Latitude: '520.729.000',
  Longitude: '69.958.210',
  Telephone: '2561971191',
  WeekDayFrom: '04:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '52.0729,6.995821',
  id: '52.0729,6.995821'
}, {
  Brand: 'ARAL',
  Street: 'Altenberger Straße 34',
  PostCode: 48161,
  City: 'Münster',
  Latitude: '519.997.100',
  Longitude: '75.583.830',
  Telephone: '25339355140',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.99971,7.558383',
  id: '51.99971,7.558383'
}, {
  Brand: 'ARAL',
  Street: 'Wisbyring 15',
  PostCode: 59494,
  City: 'Soest',
  Latitude: '515.627.340',
  Longitude: '81.029.260',
  Telephone: '292173330',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '51.562734,8.102926',
  id: '51.562734,8.102926'
}, {
  Brand: 'ARAL',
  Street: 'Steinfurter Straße 1-3',
  PostCode: 48149,
  City: 'Münster',
  Latitude: '519.672.650',
  Longitude: '76.143.080',
  Telephone: '25122822',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.967265,7.614308',
  id: '51.967265,7.614308'
}, {
  Brand: 'ARAL',
  Street: 'Alleestraße 92',
  PostCode: 59065,
  City: 'Hamm',
  Latitude: '516.721.030',
  Longitude: '78.147.060',
  Telephone: '238151773',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.672103,7.814706',
  id: '51.672103,7.814706'
}, {
  Brand: 'ARAL',
  Street: 'Werner Straße 25',
  PostCode: 59387,
  City: 'Ascheberg',
  Latitude: '517.352.520',
  Longitude: '76.628.610',
  Telephone: '2599449',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.735252,7.662861',
  id: '51.735252,7.662861'
}, {
  Brand: 'ARAL',
  Street: 'Werler Straße 390',
  PostCode: 59069,
  City: 'Hamm',
  Latitude: '516.167.900',
  Longitude: '78.489.000',
  Telephone: '23852393',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.61679,7.8489',
  id: '51.61679,7.8489'
}, {
  Brand: 'ARAL',
  Street: 'Hammer Straße 231',
  PostCode: 59075,
  City: 'Hamm',
  Latitude: '516.899.950',
  Longitude: '77.751.060',
  Telephone: '2381788669',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.689995,7.775106',
  id: '51.689995,7.775106'
}, {
  Brand: 'ARAL',
  Street: 'Lippstädter Straße 23',
  PostCode: 59329,
  City: 'Wadersloh',
  Latitude: '517.088.400',
  Longitude: '82.601.270',
  Telephone: '25238474',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.70884,8.260127',
  id: '51.70884,8.260127'
}, {
  Brand: 'ARAL',
  Street: 'Münsterstraße 60',
  PostCode: 48727,
  City: 'Billerbeck',
  Latitude: '519.742.260',
  Longitude: '72.980.360',
  Telephone: '2543232393',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.974226,7.298036',
  id: '51.974226,7.298036'
}, {
  Brand: 'ARAL',
  Street: 'Clarholzer Straße 73',
  PostCode: 33442,
  City: 'Herzebrock-Clarholz',
  Latitude: '518.834.020',
  Longitude: '82.322.160',
  Telephone: '52452066',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.883402,8.232216',
  id: '51.883402,8.232216'
}, {
  Brand: 'ARAL',
  Street: 'Neubeckumer Straße 72',
  PostCode: 59269,
  City: 'Beckum',
  Latitude: '517.656.580',
  Longitude: '80.424.670',
  Telephone: '25213605',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.765658,8.042467',
  id: '51.765658,8.042467'
}, {
  Brand: 'ARAL',
  Street: 'Lünener Straße 31',
  PostCode: 59368,
  City: 'Werne',
  Latitude: '516.575.730',
  Longitude: '76.286.480',
  Telephone: '23894791',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.657573,7.628648',
  id: '51.657573,7.628648'
}, {
  Brand: 'ARAL',
  Street: 'Delbrücker Straße 22',
  PostCode: 33397,
  City: 'Rietberg',
  Latitude: '518.041.380',
  Longitude: '84.259.950',
  Telephone: '52448817',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.804138,8.425995',
  id: '51.804138,8.425995'
}, {
  Brand: 'ARAL',
  Street: 'Bundesstraße 1',
  PostCode: 59423,
  City: 'Unna',
  Latitude: '515.321.060',
  Longitude: '77.011.020',
  Telephone: '2303772435',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.532106,7.701102',
  id: '51.532106,7.701102'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Ring 21',
  PostCode: 59302,
  City: 'Oelde',
  Latitude: '518.313.440',
  Longitude: '81.509.280',
  Telephone: '25223621',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '23:00',
  SundayFrom: '07:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.831344,8.150928',
  id: '51.831344,8.150928'
}, {
  Brand: 'ARAL',
  Street: 'Bürener Straße 15',
  PostCode: 59590,
  City: 'Geseke',
  Latitude: '516.310.630',
  Longitude: '85.127.230',
  Telephone: '29426061',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.631063,8.512723',
  id: '51.631063,8.512723'
}, {
  Brand: 'ARAL',
  Street: 'Wolfsberger Straße 39',
  PostCode: 59348,
  City: 'Lüdinghausen',
  Latitude: '517.652.580',
  Longitude: '74.428.950',
  Telephone: '259122670',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.765258,7.442895',
  id: '51.765258,7.442895'
}, {
  Brand: 'ARAL',
  Street: 'Cappenberger Straße 101',
  PostCode: 44534,
  City: 'Lünen',
  Latitude: '516.297.790',
  Longitude: '75.265.790',
  Telephone: '230654268',
  WeekDayFrom: '06:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.629779,7.526579',
  id: '51.629779,7.526579'
}, {
  Brand: 'ARAL',
  Street: 'Beckumer Straße 109',
  PostCode: 59229,
  City: 'Ahlen',
  Latitude: '517.649.420',
  Longitude: '79.122.920',
  Telephone: '238263484',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.764942,7.912292',
  id: '51.764942,7.912292'
}, {
  Brand: 'ARAL',
  Street: 'Nürnberger Straße 131',
  PostCode: 90762,
  City: 'Fürth',
  Latitude: '494.665.340',
  Longitude: '110.100.820',
  Telephone: '911706607',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.466534,11.010082',
  id: '49.466534,11.010082'
}, {
  Brand: 'ARAL',
  Street: 'Wörthstraße 1A',
  PostCode: 97082,
  City: 'Würzburg',
  Latitude: '497.930.410',
  Longitude: '99.161.930',
  Telephone: '93142468',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.793041,9.916193',
  id: '49.793041,9.916193'
}, {
  Brand: 'ARAL',
  Street: 'Memmelsdorfer Straße 80',
  PostCode: 96052,
  City: 'Bamberg',
  Latitude: '499.056.010',
  Longitude: '108.964.850',
  Telephone: '95131562',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.905601,10.896485',
  id: '49.905601,10.896485'
}, {
  Brand: 'ARAL',
  Street: 'Ansbacher Straße 35',
  PostCode: 91560,
  City: 'Heilsbronn',
  Latitude: '493.340.260',
  Longitude: '107.825.580',
  Telephone: '9872955750',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.334026,10.782558',
  id: '49.334026,10.782558'
}, {
  Brand: 'ARAL',
  Street: 'Äußere Sulzbacher Straße 117',
  PostCode: 90491,
  City: 'Nürnberg',
  Latitude: '494.642.480',
  Longitude: '111.179.470',
  Telephone: '9115979448',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.464248,11.117947',
  id: '49.464248,11.117947'
}, {
  Brand: 'ARAL',
  Street: 'Nürnberger Straße 44',
  PostCode: 91781,
  City: 'Weißenburg',
  Latitude: '490.388.260',
  Longitude: '109.709.080',
  Telephone: '914182835',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.038826,10.970908',
  id: '49.038826,10.970908'
}, {
  Brand: 'ARAL',
  Street: 'Würzburger Straße 76',
  PostCode: 96049,
  City: 'Bamberg',
  Latitude: '498.776.720',
  Longitude: '108.840.290',
  Telephone: '95153020',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.877672,10.884029',
  id: '49.877672,10.884029'
}, {
  Brand: 'ARAL',
  Street: 'Ansbacher Straße 38',
  PostCode: 91541,
  City: 'Rothenburg',
  Latitude: '493.751.020',
  Longitude: '101.907.990',
  Telephone: '98616052',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.375102,10.190799',
  id: '49.375102,10.190799'
}, {
  Brand: 'ARAL',
  Street: 'Nürnberger Straße 96',
  PostCode: 96114,
  City: 'Hirschaid',
  Latitude: '498.111.970',
  Longitude: '109.978.090',
  Telephone: '95431640',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '49.811197,10.997809',
  id: '49.811197,10.997809'
}, {
  Brand: 'ARAL',
  Street: 'Oberstreuer Straße 13',
  PostCode: 97638,
  City: 'Mellrichstadt',
  Latitude: '504.244.820',
  Longitude: '102.970.770',
  Telephone: '9776815021',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.424482,10.297077',
  id: '50.424482,10.297077'
}, {
  Brand: 'ARAL',
  Street: 'Nürnberger Str. 39',
  PostCode: 91257,
  City: 'Pegnitz',
  Latitude: '497.497.010',
  Longitude: '115.397.010',
  Telephone: '92412713',
  WeekDayFrom: '05:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.749701,11.539701',
  id: '49.749701,11.539701'
}, {
  Brand: 'ARAL',
  Street: 'Rother Straße 13',
  PostCode: 91126,
  City: 'Schwabach',
  Latitude: '493.241.480',
  Longitude: '110.382.170',
  Telephone: '912261814',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.324148,11.038217',
  id: '49.324148,11.038217'
}, {
  Brand: 'ARAL',
  Street: 'Tirschenreuther Straße 20',
  PostCode: 92681,
  City: 'Erbendorf',
  Latitude: '498.355.480',
  Longitude: '120.547.580',
  Telephone: '96821733',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '12:00',
  IsOpen24Hours: 'Nein',
  location: '49.835548,12.054758',
  id: '49.835548,12.054758'
}, {
  Brand: 'ARAL',
  Street: 'Regensburger Straße70 a',
  PostCode: 92224,
  City: 'Amberg',
  Latitude: '494.379.840',
  Longitude: '118.788.230',
  Telephone: '962183744',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.437984,11.878823',
  id: '49.437984,11.878823'
}, {
  Brand: 'ARAL',
  Street: 'Gunzenhausener Straße 9',
  PostCode: 91732,
  City: 'Merkendorf',
  Latitude: '491.984.630',
  Longitude: '107.019.100',
  Telephone: '9826655222',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '49.198463,10.70191',
  id: '49.198463,10.70191'
}, {
  Brand: 'ARAL',
  Street: 'Meininger Straße 1',
  PostCode: 97702,
  City: 'Münnerstadt',
  Latitude: '502.517.380',
  Longitude: '101.958.870',
  Telephone: '97333130',
  WeekDayFrom: '07:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.251738,10.195887',
  id: '50.251738,10.195887'
}, {
  Brand: 'ARAL',
  Street: 'Coburger Straße 64',
  PostCode: 96215,
  City: 'Lichtenfels',
  Latitude: '501.510.470',
  Longitude: '110.592.600',
  Telephone: '95712633',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.151047,11.05926',
  id: '50.151047,11.05926'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 8',
  PostCode: 85095,
  City: 'Denkendorf',
  Latitude: '489.290.230',
  Longitude: '114.592.350',
  Telephone: '84661712',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.929023,11.459235',
  id: '48.929023,11.459235'
}, {
  Brand: 'ARAL',
  Street: 'Reuther Straße 58',
  PostCode: 91301,
  City: 'Forchheim',
  Latitude: '497.177.540',
  Longitude: '111.044.720',
  Telephone: '919194241',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.717754,11.104472',
  id: '49.717754,11.104472'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 6',
  PostCode: 97633,
  City: 'Saal',
  Latitude: '503.174.640',
  Longitude: '103.526.960',
  Telephone: '9762357',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.317464,10.352696',
  id: '50.317464,10.352696'
}, {
  Brand: 'ARAL',
  Street: 'Herrnstraße 27',
  PostCode: 90763,
  City: 'Fürth',
  Latitude: '494.641.580',
  Longitude: '109.879.080',
  Telephone: '911775990',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.464158,10.987908',
  id: '49.464158,10.987908'
}, {
  Brand: 'ARAL',
  Street: 'Erlanger Straße 33',
  PostCode: 91080,
  City: 'Uttenreuth',
  Latitude: '495.962.630',
  Longitude: '110.687.970',
  Telephone: '913157088',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '17:00',
  IsOpen24Hours: 'Nein',
  location: '49.596263,11.068797',
  id: '49.596263,11.068797'
}, {
  Brand: 'ARAL',
  Street: 'Bamberger Straße 17',
  PostCode: 97483,
  City: 'Eltmann',
  Latitude: '499.711.240',
  Longitude: '106.706.040',
  Telephone: '9522271',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '49.971124,10.670604',
  id: '49.971124,10.670604'
}, {
  Brand: 'ARAL',
  Street: 'Klein-Nürnberg 24',
  PostCode: 96106,
  City: 'Ebern',
  Latitude: '500.905.230',
  Longitude: '107.955.520',
  Telephone: '95315560',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.090523,10.795552',
  id: '50.090523,10.795552'
}, {
  Brand: 'ARAL',
  Street: 'Schweinfurter Straße36',
  PostCode: 97488,
  City: 'Stadtlauringen',
  Latitude: '501.832.130',
  Longitude: '103.550.320',
  Telephone: '9724545',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.183213,10.355032',
  id: '50.183213,10.355032'
}, {
  Brand: 'ARAL',
  Street: 'Schweinfurter Straße  78',
  PostCode: 97525,
  City: 'Schwebheim',
  Latitude: '500.042.660',
  Longitude: '102.423.680',
  Telephone: '97231569',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.004266,10.242368',
  id: '50.004266,10.242368'
}, {
  Brand: 'ARAL',
  Street: 'Erlanger Straße 98',
  PostCode: 90765,
  City: 'Fürth',
  Latitude: '494.857.760',
  Longitude: '109.964.850',
  Telephone: '9117906217',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.485776,10.996485',
  id: '49.485776,10.996485'
}, {
  Brand: 'ARAL',
  Street: 'Würzburger Straße 3-5',
  PostCode: 97753,
  City: 'Karlstadt',
  Latitude: '499.556.340',
  Longitude: '97.724.770',
  Telephone: '93532489',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.955634,9.772477',
  id: '49.955634,9.772477'
}, {
  Brand: 'ARAL',
  Street: 'Äuss. Bayreuther Str. 137',
  PostCode: 90411,
  City: 'Nürnberg',
  Latitude: '494.746.470',
  Longitude: '111.030.740',
  Telephone: '911511470',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.474647,11.103074',
  id: '49.474647,11.103074'
}, {
  Brand: 'ARAL',
  Street: 'Fürther Straße 1',
  PostCode: 91315,
  City: 'Höchstadt',
  Latitude: '496.979.800',
  Longitude: '108.031.220',
  Telephone: '91938286',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.69798,10.803122',
  id: '49.69798,10.803122'
}, {
  Brand: 'ARAL',
  Street: 'Ernst-Faber-Straße 19',
  PostCode: 96450,
  City: 'Coburg',
  Latitude: '502.481.100',
  Longitude: '109.657.530',
  Telephone: '956110345',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.24811,10.965753',
  id: '50.24811,10.965753'
}, {
  Brand: 'ARAL',
  Street: 'Bamberger Straße 4',
  PostCode: 97631,
  City: 'Bad Königshofen',
  Latitude: '502.980.950',
  Longitude: '104.684.710',
  Telephone: '97616451',
  WeekDayFrom: '07:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.298095,10.468471',
  id: '50.298095,10.468471'
}, {
  Brand: 'ARAL',
  Street: 'Stammbacher Straße 45',
  PostCode: 95213,
  City: 'Münchberg',
  Latitude: '501.902.620',
  Longitude: '117.784.000',
  Telephone: '925180710',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.190262,11.7784',
  id: '50.190262,11.7784'
}, {
  Brand: 'ARAL',
  Street: 'Nürnberger Straße2',
  PostCode: 92224,
  City: 'Amberg',
  Latitude: '494.444.890',
  Longitude: '118.409.180',
  Telephone: '9621320040',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.444489,11.840918',
  id: '49.444489,11.840918'
}, {
  Brand: 'ARAL',
  Street: 'Drausnickstraße 60',
  PostCode: 91052,
  City: 'Erlangen',
  Latitude: '495.957.610',
  Longitude: '110.317.010',
  Telephone: '913152370',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.595761,11.031701',
  id: '49.595761,11.031701'
}, {
  Brand: 'ARAL',
  Street: 'Äuß. Brucker Straße 171',
  PostCode: 91058,
  City: 'Erlangen',
  Latitude: '495.770.070',
  Longitude: '109.865.220',
  Telephone: '9131640702',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.577007,10.986522',
  id: '49.577007,10.986522'
}, {
  Brand: 'ARAL',
  Street: 'Rodacher Straße 69',
  PostCode: 96450,
  City: 'Coburg',
  Latitude: '502.740.480',
  Longitude: '109.573.760',
  Telephone: '956166036',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.274048,10.957376',
  id: '50.274048,10.957376'
}, {
  Brand: 'ARAL',
  Street: 'A3 /Am Katzenberg',
  PostCode: 97084,
  City: 'Würzburg',
  Latitude: '497.546.680',
  Longitude: '99.614.240',
  Telephone: '9316158090',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.754668,9.961424',
  id: '49.754668,9.961424'
}, {
  Brand: 'ARAL',
  Street: 'Fabrikzeile 5',
  PostCode: 95028,
  City: 'Hof',
  Latitude: '503.193.730',
  Longitude: '119.219.870',
  Telephone: '92818402310',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.319373,11.921987',
  id: '50.319373,11.921987'
}, {
  Brand: 'ARAL',
  Street: 'Bamberger Straße 74',
  PostCode: 91413,
  City: 'Neustadt a. d. Aisch',
  Latitude: '495.840.640',
  Longitude: '106.166.710',
  Telephone: '9161874225',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.584064,10.616671',
  id: '49.584064,10.616671'
}, {
  Brand: 'ARAL',
  Street: 'Mainstraße',
  PostCode: 97340,
  City: 'Marktbreit',
  Latitude: '496.690.330',
  Longitude: '101.452.920',
  Telephone: '93321303',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.669033,10.145292',
  id: '49.669033,10.145292'
}, {
  Brand: 'ARAL',
  Street: 'Maintalstraße 159',
  PostCode: 95460,
  City: 'Bad Berneck',
  Latitude: '500.376.690',
  Longitude: '116.774.590',
  Telephone: '9273502042',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.037669,11.677459',
  id: '50.037669,11.677459'
}, {
  Brand: 'ARAL',
  Street: 'Amberger Straße 18',
  PostCode: 92318,
  City: 'Neumarkt',
  Latitude: '492.851.910',
  Longitude: '114.652.890',
  Telephone: '918143441',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.285191,11.465289',
  id: '49.285191,11.465289'
}, {
  Brand: 'ARAL',
  Street: 'Jakob-Degen-Straße 21',
  PostCode: 96346,
  City: 'Wallenfels',
  Latitude: '502.660.220',
  Longitude: '114.755.790',
  Telephone: '9262993655',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '09:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '50.266022,11.475579',
  id: '50.266022,11.475579'
}, {
  Brand: 'ARAL',
  Street: 'Attelsdorf 19',
  PostCode: 96132,
  City: 'Schlüsselfeld',
  Latitude: '497.458.090',
  Longitude: '106.342.900',
  Telephone: '9552444',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.745809,10.63429',
  id: '49.745809,10.63429'
}, {
  Brand: 'ARAL',
  Street: 'Kitzinger Straße38',
  PostCode: 97359,
  City: 'Schwarzach',
  Latitude: '497.896.180',
  Longitude: '102.156.010',
  Telephone: '932499809',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.789618,10.215601',
  id: '49.789618,10.215601'
}, {
  Brand: 'ARAL',
  Street: 'Nürnberger Straße 25',
  PostCode: 90518,
  City: 'Altdorf',
  Latitude: '493.839.690',
  Longitude: '113.480.500',
  Telephone: '91871515',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.383969,11.34805',
  id: '49.383969,11.34805'
}, {
  Brand: 'ARAL',
  Street: 'Würzburger Straße 1u.3',
  PostCode: 97241,
  City: 'Bergtheim',
  Latitude: '498.974.380',
  Longitude: '100.672.110',
  Telephone: '9367522',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.897438,10.067211',
  id: '49.897438,10.067211'
}, {
  Brand: 'ARAL',
  Street: 'Bamberger Straße 23',
  PostCode: 97337,
  City: 'Dettelbach',
  Latitude: '498.015.150',
  Longitude: '101.656.310',
  Telephone: '93241468',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.801515,10.165631',
  id: '49.801515,10.165631'
}, {
  Brand: 'ARAL',
  Street: 'Lichtenfelser Straße 38',
  PostCode: 96231,
  City: 'Bad Staffelstein',
  Latitude: '501.038.780',
  Longitude: '110.057.940',
  Telephone: '95735700',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.103878,11.005794',
  id: '50.103878,11.005794'
}, {
  Brand: 'ARAL',
  Street: 'Würzburger Straße 59',
  PostCode: 63743,
  City: 'Aschaffenburg',
  Latitude: '499.699.230',
  Longitude: '91.630.680',
  Telephone: '6021930563',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.969923,9.163068',
  id: '49.969923,9.163068'
}, {
  Brand: 'ARAL',
  Street: 'Schweinauer Hauptstr. 92',
  PostCode: 90441,
  City: 'Nürnberg',
  Latitude: '494.293.390',
  Longitude: '110.438.510',
  Telephone: '9116266537',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.429339,11.043851',
  id: '49.429339,11.043851'
}, {
  Brand: 'ARAL',
  Street: 'Ulmenstraße 31',
  PostCode: 90443,
  City: 'Nürnberg',
  Latitude: '494.297.760',
  Longitude: '110.707.660',
  Telephone: '911414155',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.429776,11.070766',
  id: '49.429776,11.070766'
}, {
  Brand: 'ARAL',
  Street: 'Röthenbacher Straße 4',
  PostCode: 90571,
  City: 'Schwaig',
  Latitude: '494.721.080',
  Longitude: '112.016.480',
  Telephone: '911500169',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.472108,11.201648',
  id: '49.472108,11.201648'
}, {
  Brand: 'ARAL',
  Street: 'Fichtelgebirgsstraße 2',
  PostCode: 95709,
  City: 'Tröstau',
  Latitude: '500.159.590',
  Longitude: '119.587.450',
  Telephone: '92326193',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:30',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.015959,11.958745',
  id: '50.015959,11.958745'
}, {
  Brand: 'ARAL',
  Street: 'Wodanstraße 27',
  PostCode: 90461,
  City: 'Nürnberg',
  Latitude: '494.371.910',
  Longitude: '110.942.020',
  Telephone: '911460780',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.437191,11.094202',
  id: '49.437191,11.094202'
}, {
  Brand: 'ARAL',
  Street: 'Römerstraße 98',
  PostCode: 63785,
  City: 'Obernburg',
  Latitude: '498.466.270',
  Longitude: '91.515.910',
  Telephone: '602272033',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.846627,9.151591',
  id: '49.846627,9.151591'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 163',
  PostCode: 63768,
  City: 'Hösbach',
  Latitude: '500.051.750',
  Longitude: '92.111.580',
  Telephone: '60215825180',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.005175,9.211158',
  id: '50.005175,9.211158'
}, {
  Brand: 'ARAL',
  Street: 'B 169',
  PostCode: 4910,
  City: 'Elsterwerda',
  Latitude: '514.671.800',
  Longitude: '135.378.330',
  Telephone: '35334281',
  WeekDayFrom: '04:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '51.46718,13.537833',
  id: '51.46718,13.537833'
}, {
  Brand: 'ARAL',
  Street: 'Aschaffenburger Straße 86',
  PostCode: 63801,
  City: 'Kleinostheim',
  Latitude: '499.946.070',
  Longitude: '90.697.100',
  Telephone: '60279026',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.994607,9.06971',
  id: '49.994607,9.06971'
}, {
  Brand: 'ARAL',
  Street: 'Mainzer Straße 42',
  PostCode: 63897,
  City: 'Miltenberg',
  Latitude: '497.042.220',
  Longitude: '92.368.410',
  Telephone: '93712466',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.704222,9.236841',
  id: '49.704222,9.236841'
}, {
  Brand: 'ARAL',
  Street: 'Wintersbacher Straße 7',
  PostCode: 63874,
  City: 'Dammbach',
  Latitude: '498.602.610',
  Longitude: '92.913.620',
  Telephone: '60921539',
  WeekDayFrom: '06:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '07:30',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '49.860261,9.291362',
  id: '49.860261,9.291362'
}, {
  Brand: 'ARAL',
  Street: 'Aschaffenburger Straße33',
  PostCode: 63762,
  City: 'Großostheim',
  Latitude: '499.261.980',
  Longitude: '90.793.350',
  Telephone: '60269779501',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.926198,9.079335',
  id: '49.926198,9.079335'
}, {
  Brand: 'ARAL',
  Street: 'Kahlgrundstraße 137',
  PostCode: 63776,
  City: 'Mömbris',
  Latitude: '500.545.110',
  Longitude: '91.943.030',
  Telephone: '60291654',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.054511,9.194303',
  id: '50.054511,9.194303'
}, {
  Brand: 'ARAL',
  Street: 'Georg-Ledebour-Straße 4',
  PostCode: 90473,
  City: 'Nürnberg',
  Latitude: '494.013.340',
  Longitude: '111.508.180',
  Telephone: '9119898970',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.401334,11.150818',
  id: '49.401334,11.150818'
}, {
  Brand: 'ARAL',
  Street: 'Am Europakanal 26',
  PostCode: 91056,
  City: 'Erlangen',
  Latitude: '495.929.600',
  Longitude: '109.700.500',
  Telephone: '913147599',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.59296,10.97005',
  id: '49.59296,10.97005'
}, {
  Brand: 'ARAL',
  Street: 'A9',
  PostCode: 95180,
  City: 'Berg',
  Latitude: '504.066.710',
  Longitude: '117.740.500',
  Telephone: '9293940940',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.406671,11.77405',
  id: '50.406671,11.77405'
}, {
  Brand: 'ARAL',
  Street: 'Kantstraße 24',
  PostCode: 97074,
  City: 'Würzburg',
  Latitude: '497.784.930',
  Longitude: '99.426.800',
  Telephone: '931883082',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '49.778493,9.94268',
  id: '49.778493,9.94268'
}, {
  Brand: 'ARAL',
  Street: 'Kronenstraße 42',
  PostCode: 79211,
  City: 'Denzlingen',
  Latitude: '480.656.140',
  Longitude: '78.680.780',
  Telephone: '76669446813',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.065614,7.868078',
  id: '48.065614,7.868078'
}, {
  Brand: 'ARAL',
  Street: 'Freiburger Straße 11',
  PostCode: 77933,
  City: 'Lahr',
  Latitude: '483.405.050',
  Longitude: '78.419.340',
  Telephone: '782142263',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.340505,7.841934',
  id: '48.340505,7.841934'
}, {
  Brand: 'ARAL',
  Street: 'Neckarstraße 68',
  PostCode: 78056,
  City: 'V.S.-Schwenningen',
  Latitude: '480.577.130',
  Longitude: '85.355.150',
  Telephone: '7720956960',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.057713,8.535515',
  id: '48.057713,8.535515'
}, {
  Brand: 'ARAL',
  Street: 'Schönauer Straße 22',
  PostCode: 79674,
  City: 'Todtnau',
  Latitude: '478.280.350',
  Longitude: '79.450.250',
  Telephone: '76717499973',
  WeekDayFrom: '06:30',
  WeekDayTo: '19:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:30',
  SundayFrom: '07:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '47.828035,7.945025',
  id: '47.828035,7.945025'
}, {
  Brand: 'ARAL',
  Street: 'Leutkircher Straße 21',
  PostCode: 88410,
  City: 'Bad Wurzach',
  Latitude: '479.050.770',
  Longitude: '98.956.390',
  Telephone: '75642364',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '47.905077,9.895639',
  id: '47.905077,9.895639'
}, {
  Brand: 'ARAL',
  Street: 'Bregstraße  40a',
  PostCode: 78120,
  City: 'Furtwangen',
  Latitude: '480.453.530',
  Longitude: '81.967.130',
  Telephone: '77234460',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.045353,8.196713',
  id: '48.045353,8.196713'
}, {
  Brand: 'ARAL',
  Street: 'Schrahöfe 3',
  PostCode: 79215,
  City: 'Elzach',
  Latitude: '481.847.210',
  Longitude: '80.856.260',
  Telephone: '76829209402',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.184721,8.085626',
  id: '48.184721,8.085626'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 34',
  PostCode: 78132,
  City: 'Hornberg',
  Latitude: '482.138.200',
  Longitude: '82.274.610',
  Telephone: '7833363',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.21382,8.227461',
  id: '48.21382,8.227461'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 106',
  PostCode: 79336,
  City: 'Herbolzheim',
  Latitude: '482.163.320',
  Longitude: '77.792.430',
  Telephone: '76434230',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.216332,7.779243',
  id: '48.216332,7.779243'
}, {
  Brand: 'ARAL',
  Street: 'Freiburger Landstraße 23',
  PostCode: 79112,
  City: 'Freiburg',
  Latitude: '479.819.430',
  Longitude: '77.175.520',
  Telephone: '76644846',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.981943,7.717552',
  id: '47.981943,7.717552'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 79',
  PostCode: 88161,
  City: 'Lindenberg',
  Latitude: '475.995.930',
  Longitude: '98.842.060',
  Telephone: '8381927740',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.599593,9.884206',
  id: '47.599593,9.884206'
}, {
  Brand: 'ARAL',
  Street: 'Turmstraße 33',
  PostCode: 77933,
  City: 'Lahr',
  Latitude: '483.417.460',
  Longitude: '78.769.730',
  Telephone: '782125474',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.341746,7.876973',
  id: '48.341746,7.876973'
}, {
  Brand: 'ARAL',
  Street: 'Vöhrenbacherstraße 38',
  PostCode: 78050,
  City: 'V.S.-Villingen',
  Latitude: '480.580.180',
  Longitude: '84.439.370',
  Telephone: '772153511',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.058018,8.443937',
  id: '48.058018,8.443937'
}, {
  Brand: 'ARAL',
  Street: 'Tettnanger Straße 150',
  PostCode: 88069,
  City: 'Tettnang',
  Latitude: '476.685.370',
  Longitude: '95.611.400',
  Telephone: '75424073599',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.668537,9.56114',
  id: '47.668537,9.56114'
}, {
  Brand: 'ARAL',
  Street: 'Lippertsreuterstraße33',
  PostCode: 88662,
  City: 'Überlingen',
  Latitude: '477.717.580',
  Longitude: '91.739.770',
  Telephone: '75514767',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.771758,9.173977',
  id: '47.771758,9.173977'
}, {
  Brand: 'ARAL',
  Street: 'Bei der Pürschmark 4',
  PostCode: 78532,
  City: 'Tuttlingen',
  Latitude: '479.679.690',
  Longitude: '87.873.340',
  Telephone: '7461164574',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.967969,8.787334',
  id: '47.967969,8.787334'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 49',
  PostCode: 79591,
  City: 'Eimeldingen',
  Latitude: '476.338.670',
  Longitude: '75.940.610',
  Telephone: '76216908035',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.633867,7.594061',
  id: '47.633867,7.594061'
}, {
  Brand: 'ARAL',
  Street: 'Titiseestraße 21',
  PostCode: 79822,
  City: 'Titisee-Neustadt',
  Latitude: '479.195.730',
  Longitude: '82.051.130',
  Telephone: '07651/9361864',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '05:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.919573,8.205113',
  id: '47.919573,8.205113'
}, {
  Brand: 'ARAL',
  Street: 'Überlinger Straße 58',
  PostCode: 88630,
  City: 'Pfullendorf',
  Latitude: '479.276.630',
  Longitude: '92.430.760',
  Telephone: '75528625',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.927663,9.243076',
  id: '47.927663,9.243076'
}, {
  Brand: 'ARAL',
  Street: 'Schwarzwaldstrasse 246',
  PostCode: 79117,
  City: 'Freiburg',
  Latitude: '479.870.080',
  Longitude: '78.956.890',
  Telephone: '76169077',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.987008,7.895689',
  id: '47.987008,7.895689'
}, {
  Brand: 'ARAL',
  Street: 'Leo-Wohleb-Straße 2',
  PostCode: 79098,
  City: 'Freiburg',
  Latitude: '479.903.390',
  Longitude: '78.579.090',
  Telephone: '761709731',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.990339,7.857909',
  id: '47.990339,7.857909'
}, {
  Brand: 'ARAL',
  Street: 'Waldseer Straße64',
  PostCode: 88250,
  City: 'Weingarten',
  Latitude: '478.177.070',
  Longitude: '96.423.180',
  Telephone: '751561310',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.817707,9.642318',
  id: '47.817707,9.642318'
}, {
  Brand: 'ARAL',
  Street: 'Nordwerk 10',
  PostCode: 78176,
  City: 'Blumberg',
  Latitude: '478.462.090',
  Longitude: '85.548.950',
  Telephone: '77025783',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.846209,8.554895',
  id: '47.846209,8.554895'
}, {
  Brand: 'ARAL',
  Street: 'Friedrichstraße 57',
  PostCode: 79618,
  City: 'Rheinfelden',
  Latitude: '475.628.240',
  Longitude: '77.922.690',
  Telephone: '76231286',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.562824,7.792269',
  id: '47.562824,7.792269'
}, {
  Brand: 'ARAL',
  Street: 'Bundesstraße 7',
  PostCode: 79780,
  City: 'Stühlingen',
  Latitude: '477.510.540',
  Longitude: '84.580.300',
  Telephone: '77445211',
  WeekDayFrom: '07:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '47.751054,8.45803',
  id: '47.751054,8.45803'
}, {
  Brand: 'ARAL',
  Street: 'Augsburger Straße 32',
  PostCode: 93051,
  City: 'Regensburg',
  Latitude: '490.031.720',
  Longitude: '120.814.050',
  Telephone: '94196564',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.003172,12.081405',
  id: '49.003172,12.081405'
}, {
  Brand: 'ARAL',
  Street: 'Amberger Straße 31',
  PostCode: 92521,
  City: 'Schwarzenfeld',
  Latitude: '493.881.140',
  Longitude: '121.340.070',
  Telephone: '9435501210',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.388114,12.134007',
  id: '49.388114,12.134007'
}, {
  Brand: 'ARAL',
  Street: 'Viechtacher Straße 4',
  PostCode: 94239,
  City: 'Ruhmannsfelden',
  Latitude: '489.847.640',
  Longitude: '129.808.590',
  Telephone: '99291041',
  WeekDayFrom: '06:30',
  WeekDayTo: '19:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:30',
  SundayFrom: '09:00',
  SundayTo: '18:00',
  IsOpen24Hours: 'Nein',
  location: '48.984764,12.980859',
  id: '48.984764,12.980859'
}, {
  Brand: 'ARAL',
  Street: 'Chamer Straße 52',
  PostCode: 93426,
  City: 'Roding',
  Latitude: '491.992.590',
  Longitude: '125.243.140',
  Telephone: '94612109',
  WeekDayFrom: '04:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.199259,12.524314',
  id: '49.199259,12.524314'
}, {
  Brand: 'ARAL',
  Street: 'Bernauer Straße 20',
  PostCode: 94356,
  City: 'Kirchroth',
  Latitude: '489.473.630',
  Longitude: '125.453.090',
  Telephone: '9428226',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.947363,12.545309',
  id: '48.947363,12.545309'
}, {
  Brand: 'ARAL',
  Street: 'Landshuter Straße 20',
  PostCode: 84061,
  City: 'Ergoldsbach',
  Latitude: '486.847.200',
  Longitude: '122.060.870',
  Telephone: '87711345',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.68472,12.206087',
  id: '48.68472,12.206087'
}, {
  Brand: 'ARAL',
  Street: 'Puricellistraße 3',
  PostCode: 93049,
  City: 'Regensburg',
  Latitude: '490.224.980',
  Longitude: '120.626.940',
  Telephone: '94123371',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.022498,12.062694',
  id: '49.022498,12.062694'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 18',
  PostCode: 84130,
  City: 'Dingolfing',
  Latitude: '486.362.430',
  Longitude: '124.959.420',
  Telephone: '873173537',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.636243,12.495942',
  id: '48.636243,12.495942'
}, {
  Brand: 'ARAL',
  Street: 'Passauer Straße 18',
  PostCode: 94121,
  City: 'Salzweg',
  Latitude: '486.182.970',
  Longitude: '134.830.340',
  Telephone: '85142693',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:30',
  SundayFrom: '08:00',
  SundayTo: '19:30',
  IsOpen24Hours: 'Nein',
  location: '48.618297,13.483034',
  id: '48.618297,13.483034'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 61',
  PostCode: 92345,
  City: 'Dietfurt',
  Latitude: '490.331.310',
  Longitude: '115.929.520',
  Telephone: '8464602953',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.033131,11.592952',
  id: '49.033131,11.592952'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Straße 1',
  PostCode: 1979,
  City: 'Lauchhammer',
  Latitude: '514.685.660',
  Longitude: '137.392.940',
  Telephone: '35747258',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.468566,13.739294',
  id: '51.468566,13.739294'
}, {
  Brand: 'ARAL',
  Street: 'Nürnbergerstraße 35',
  PostCode: 92533,
  City: 'Wernberg-Köblitz',
  Latitude: '495.379.680',
  Longitude: '121.560.440',
  Telephone: '96042272',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '18:00',
  IsOpen24Hours: 'Nein',
  location: '49.537968,12.156044',
  id: '49.537968,12.156044'
}, {
  Brand: 'ARAL',
  Street: 'Dornbergsweg 49',
  PostCode: 38855,
  City: 'Wernigerode',
  Latitude: '518.528.730',
  Longitude: '107.899.730',
  Telephone: '3943533318',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.852873,10.789973',
  id: '51.852873,10.789973'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Straße 39',
  PostCode: 6886,
  City: 'Lutherstadt Wittenbe',
  Latitude: '518.786.650',
  Longitude: '126.518.710',
  Telephone: '3491481100',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.878665,12.651871',
  id: '51.878665,12.651871'
}, {
  Brand: 'ARAL',
  Street: 'Dessauer Straße 274 a',
  PostCode: 6886,
  City: 'Lutherstadt Wittenbe',
  Latitude: '518.656.450',
  Longitude: '126.216.980',
  Telephone: '3491667838',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.865645,12.621698',
  id: '51.865645,12.621698'
}, {
  Brand: 'ARAL',
  Street: 'Helmestraße 109',
  PostCode: 99734,
  City: 'Nordhausen',
  Latitude: '514.772.660',
  Longitude: '108.084.410',
  Telephone: '3631602241',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.477266,10.808441',
  id: '51.477266,10.808441'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Allee / B 2',
  PostCode: 16303,
  City: 'Schwedt',
  Latitude: '530.463.140',
  Longitude: '142.658.410',
  Telephone: '333223206',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '53.046314,14.265841',
  id: '53.046314,14.265841'
}, {
  Brand: 'ARAL',
  Street: 'Zollberg 70',
  PostCode: 6449,
  City: 'Aschersleben',
  Latitude: '517.574.770',
  Longitude: '114.420.810',
  Telephone: '3473226798',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.757477,11.442081',
  id: '51.757477,11.442081'
}, {
  Brand: 'ARAL',
  Street: 'Zehistaer Straße 1',
  PostCode: 1796,
  City: 'Pirna',
  Latitude: '509.531.590',
  Longitude: '139.384.410',
  Telephone: '3501769123',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.953159,13.938441',
  id: '50.953159,13.938441'
}, {
  Brand: 'ARAL',
  Street: 'Castrop-Rauxel-Allee 2',
  PostCode: 16792,
  City: 'Zehdenick',
  Latitude: '529.795.580',
  Longitude: '133.445.900',
  Telephone: '33072291',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.979558,13.34459',
  id: '52.979558,13.34459'
}, {
  Brand: 'ARAL',
  Street: 'Stralsunder Chaussee 23',
  PostCode: 18528,
  City: 'Bergen',
  Latitude: '544.075.200',
  Longitude: '134.161.350',
  Telephone: '3838251820',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '54.40752,13.416135',
  id: '54.40752,13.416135'
}, {
  Brand: 'ARAL',
  Street: 'Neubrandenburger Str. 10',
  PostCode: 17098,
  City: 'Friedland',
  Latitude: '536.604.910',
  Longitude: '135.419.730',
  Telephone: '3960120313',
  WeekDayFrom: '05:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '20:00',
  SundayFrom: '05:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '53.660491,13.541973',
  id: '53.660491,13.541973'
}, {
  Brand: 'ARAL',
  Street: 'Schönwalder Landstraße 1',
  PostCode: 17489,
  City: 'Greifswald',
  Latitude: '540.728.640',
  Longitude: '133.833.310',
  Telephone: '3834500709',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '54.072864,13.383331',
  id: '54.072864,13.383331'
}, {
  Brand: 'ARAL',
  Street: 'An der Karlskuppe 1',
  PostCode: 99817,
  City: 'Eisenach',
  Latitude: '509.941.860',
  Longitude: '102.929.660',
  Telephone: '3691818095',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '50.994186,10.292966',
  id: '50.994186,10.292966'
}, {
  Brand: 'ARAL',
  Street: 'Olvenstedter Graseweg',
  PostCode: 39128,
  City: 'Magdeburg',
  Latitude: '521.566.390',
  Longitude: '115.880.910',
  Telephone: '3917226129',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.156639,11.588091',
  id: '52.156639,11.588091'
}, {
  Brand: 'ARAL',
  Street: 'Industriestraße 1',
  PostCode: 37339,
  City: 'Worbis',
  Latitude: '514.132.260',
  Longitude: '103.578.250',
  Telephone: '3607430316',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.413226,10.357825',
  id: '51.413226,10.357825'
}, {
  Brand: 'ARAL',
  Street: 'Alte Klockenhäger Landstraße 1',
  PostCode: 18311,
  City: 'Ribnitz-Damgarten',
  Latitude: '542.393.770',
  Longitude: '124.079.080',
  Telephone: '3821811922',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.239377,12.407908',
  id: '54.239377,12.407908'
}, {
  Brand: 'ARAL',
  Street: 'Hohensteiner Chaussee',
  PostCode: 15344,
  City: 'Strausberg',
  Latitude: '525.716.170',
  Longitude: '139.029.830',
  Telephone: '334122321',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.571617,13.902983',
  id: '52.571617,13.902983'
}, {
  Brand: 'ARAL',
  Street: 'Lychener Straße 15',
  PostCode: 17268,
  City: 'Templin',
  Latitude: '531.289.370',
  Longitude: '134.894.750',
  Telephone: '39876822',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.128937,13.489475',
  id: '53.128937,13.489475'
}, {
  Brand: 'ARAL',
  Street: 'An der B 167 Nr. 4',
  PostCode: 16244,
  City: 'Finowfurt',
  Latitude: '528.498.580',
  Longitude: '136.849.410',
  Telephone: '33357308',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.849858,13.684941',
  id: '52.849858,13.684941'
}, {
  Brand: 'ARAL',
  Street: 'Freienhufener Straße 21',
  PostCode: 1983,
  City: 'Großräschen',
  Latitude: '515.857.750',
  Longitude: '140.026.840',
  Telephone: '357533140',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.585775,14.002684',
  id: '51.585775,14.002684'
}, {
  Brand: 'ARAL',
  Street: 'Thomas-Müntzer-Strasse 2',
  PostCode: 6217,
  City: 'Merseburg',
  Latitude: '513.427.870',
  Longitude: '119.892.000',
  Telephone: '3461525570',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.342787,11.9892',
  id: '51.342787,11.9892'
}, {
  Brand: 'ARAL',
  Street: 'Friedländer Landstr. 21 a',
  PostCode: 17389,
  City: 'Anklam',
  Latitude: '538.431.320',
  Longitude: '136.847.180',
  Telephone: '3971214444',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.843132,13.684718',
  id: '53.843132,13.684718'
}, {
  Brand: 'ARAL',
  Street: 'Poststrasse 21',
  PostCode: 17139,
  City: 'Malchin',
  Latitude: '537.416.300',
  Longitude: '127.665.960',
  Telephone: '3994222308',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.74163,12.766596',
  id: '53.74163,12.766596'
}, {
  Brand: 'ARAL',
  Street: 'Berstetal/A 13',
  PostCode: 15926,
  City: 'Luckau',
  Latitude: '519.109.000',
  Longitude: '137.965.000',
  Telephone: '354566820',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.9109,13.7965',
  id: '51.9109,13.7965'
}, {
  Brand: 'ARAL',
  Street: 'Rüblingsheide/A 13',
  PostCode: 15926,
  City: 'Luckau',
  Latitude: '518.887.490',
  Longitude: '138.371.910',
  Telephone: '354566830',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.888749,13.837191',
  id: '51.888749,13.837191'
}, {
  Brand: 'ARAL',
  Street: 'Laugkfeld 30',
  PostCode: 1968,
  City: 'Senftenberg',
  Latitude: '515.276.460',
  Longitude: '140.113.020',
  Telephone: '3573794952',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '51.527646,14.011302',
  id: '51.527646,14.011302'
}, {
  Brand: 'ARAL',
  Street: 'Camburger Straße 64',
  PostCode: 7743,
  City: 'Jena',
  Latitude: '509.425.580',
  Longitude: '115.970.680',
  Telephone: '3641424000',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.942558,11.597068',
  id: '50.942558,11.597068'
}, {
  Brand: 'ARAL',
  Street: 'Triftstraße 36',
  PostCode: 15517,
  City: 'Fürstenwalde',
  Latitude: '523.783.540',
  Longitude: '140.734.920',
  Telephone: '3361349966',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.378354,14.073492',
  id: '52.378354,14.073492'
}, {
  Brand: 'ARAL',
  Street: 'Dahlener Straße 3',
  PostCode: 4860,
  City: 'Torgau',
  Latitude: '515.551.180',
  Longitude: '130.008.170',
  Telephone: '3421712851',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.555118,13.000817',
  id: '51.555118,13.000817'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Chaussee 22',
  PostCode: 15234,
  City: 'Frankfurt',
  Latitude: '523.651.690',
  Longitude: '145.223.790',
  Telephone: '33564190',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.365169,14.522379',
  id: '52.365169,14.522379'
}, {
  Brand: 'ARAL',
  Street: 'Doberaner Straße/Gewerbegebiet',
  PostCode: 18225,
  City: 'Kühlungsborn',
  Latitude: '541.443.930',
  Longitude: '117.688.010',
  Telephone: '382936111',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '54.144393,11.768801',
  id: '54.144393,11.768801'
}, {
  Brand: 'ARAL',
  Street: 'Langensalzaer Landstraße',
  PostCode: 99974,
  City: 'Mühlhausen',
  Latitude: '511.985.770',
  Longitude: '104.783.810',
  Telephone: '3601440450',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.198577,10.478381',
  id: '51.198577,10.478381'
}, {
  Brand: 'ARAL',
  Street: 'Pritzwalker Straße',
  PostCode: 16909,
  City: 'Wittstock',
  Latitude: '531.603.600',
  Longitude: '124.674.200',
  Telephone: '3394433198',
  WeekDayFrom: '04:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.16036,12.46742',
  id: '53.16036,12.46742'
}, {
  Brand: 'ARAL',
  Street: 'Am Grünen Tal 10',
  PostCode: 19063,
  City: 'Schwerin',
  Latitude: '535.979.790',
  Longitude: '114.383.700',
  Telephone: '3853941059',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.597979,11.43837',
  id: '53.597979,11.43837'
}, {
  Brand: 'ARAL',
  Street: 'Münsaer Straße 65  /B 180',
  PostCode: 4600,
  City: 'Altenburg',
  Latitude: '509.832.980',
  Longitude: '124.561.160',
  Telephone: '3447579249',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.983298,12.456116',
  id: '50.983298,12.456116'
}, {
  Brand: 'ARAL',
  Street: 'Glauchauer Landstraße 70',
  PostCode: 8451,
  City: 'Crimmitschau',
  Latitude: '508.195.650',
  Longitude: '124.050.280',
  Telephone: '37625374',
  WeekDayFrom: '04:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '06:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.819565,12.405028',
  id: '50.819565,12.405028'
}, {
  Brand: 'ARAL',
  Street: 'Äußere Dresdner Straße',
  PostCode: 8066,
  City: 'Zwickau',
  Latitude: '507.186.710',
  Longitude: '125.156.120',
  Telephone: '375477462',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.718671,12.515612',
  id: '50.718671,12.515612'
}, {
  Brand: 'ARAL',
  Street: 'Plauer Chaussee 18',
  PostCode: 19386,
  City: 'Lübz',
  Latitude: '534.632.600',
  Longitude: '120.469.550',
  Telephone: '3873122205',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '05:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.46326,12.046955',
  id: '53.46326,12.046955'
}, {
  Brand: 'ARAL',
  Street: 'Rövershäger Chaussee 11',
  PostCode: 18146,
  City: 'Rostock',
  Latitude: '540.995.530',
  Longitude: '121.760.540',
  Telephone: '381690210',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.099553,12.176054',
  id: '54.099553,12.176054'
}, {
  Brand: 'ARAL',
  Street: 'Sternstraße 10',
  PostCode: 38820,
  City: 'Halberstadt',
  Latitude: '518.941.670',
  Longitude: '110.349.960',
  Telephone: '3941441180',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.894167,11.034996',
  id: '51.894167,11.034996'
}, {
  Brand: 'ARAL',
  Street: 'Neue Halberstädter Str.67',
  PostCode: 38889,
  City: 'Blankenburg',
  Latitude: '517.998.140',
  Longitude: '109.490.710',
  Telephone: '3944365438',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.799814,10.949071',
  id: '51.799814,10.949071'
}, {
  Brand: 'ARAL',
  Street: 'Ohrdrufer Straße',
  PostCode: 99867,
  City: 'Gotha',
  Latitude: '509.365.110',
  Longitude: '107.160.860',
  Telephone: '3621709631',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.936511,10.716086',
  id: '50.936511,10.716086'
}, {
  Brand: 'ARAL',
  Street: 'Stadtrodaer Straße 102',
  PostCode: 7747,
  City: 'Jena',
  Latitude: '508.773.870',
  Longitude: '116.219.810',
  Telephone: '3641334142',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.877387,11.621981',
  id: '50.877387,11.621981'
}, {
  Brand: 'ARAL',
  Street: 'Gothaer Straße 158',
  PostCode: 98528,
  City: 'Suhl',
  Latitude: '506.364.890',
  Longitude: '106.974.850',
  Telephone: '3681461595',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.636489,10.697485',
  id: '50.636489,10.697485'
}, {
  Brand: 'ARAL',
  Street: 'Essener Straße 31',
  PostCode: 4357,
  City: 'Leipzig',
  Latitude: '513.765.760',
  Longitude: '123.990.260',
  Telephone: '3416022765',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.376576,12.399026',
  id: '51.376576,12.399026'
}, {
  Brand: 'ARAL',
  Street: 'Hammerstraße 65',
  PostCode: 8523,
  City: 'Plauen',
  Latitude: '504.997.870',
  Longitude: '121.473.880',
  Telephone: '3741223173',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.499787,12.147388',
  id: '50.499787,12.147388'
}, {
  Brand: 'ARAL',
  Street: 'Chemnitzer Straße 8',
  PostCode: 8294,
  City: 'Lößnitz',
  Latitude: '506.315.170',
  Longitude: '127.367.950',
  Telephone: '377135357',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '21:00',
  SundayFrom: '05:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.631517,12.736795',
  id: '50.631517,12.736795'
}, {
  Brand: 'ARAL',
  Street: 'Hallesche Straße 101',
  PostCode: 99734,
  City: 'Nordhausen',
  Latitude: '514.936.730',
  Longitude: '108.162.180',
  Telephone: '3631603191',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.493673,10.816218',
  id: '51.493673,10.816218'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 45/47',
  PostCode: 15806,
  City: 'Zossen',
  Latitude: '522.171.030',
  Longitude: '134.411.340',
  Telephone: '3377302403',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.217103,13.441134',
  id: '52.217103,13.441134'
}, {
  Brand: 'ARAL',
  Street: 'Poggestraße 17',
  PostCode: 17166,
  City: 'Teterow',
  Latitude: '537.769.530',
  Longitude: '125.634.960',
  Telephone: '3996187931',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.776953,12.563496',
  id: '53.776953,12.563496'
}, {
  Brand: 'ARAL',
  Street: 'Heeresbergstraße 9',
  PostCode: 7549,
  City: 'Gera',
  Latitude: '508.422.310',
  Longitude: '120.767.880',
  Telephone: '36533022',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.842231,12.076788',
  id: '50.842231,12.076788'
}, {
  Brand: 'ARAL',
  Street: 'Weimarische Straße 26',
  PostCode: 99438,
  City: 'Bad Berka',
  Latitude: '509.044.590',
  Longitude: '112.782.680',
  Telephone: '3645841155',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.904459,11.278268',
  id: '50.904459,11.278268'
}, {
  Brand: 'ARAL',
  Street: 'Hohenzieritzer Straße 34',
  PostCode: 17235,
  City: 'Neustrelitz',
  Latitude: '533.724.150',
  Longitude: '130.742.420',
  Telephone: '3981203295',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.372415,13.074242',
  id: '53.372415,13.074242'
}, {
  Brand: 'ARAL',
  Street: 'Salzunger Straße 27',
  PostCode: 36433,
  City: 'Leimbach',
  Latitude: '508.135.890',
  Longitude: '102.041.700',
  Telephone: '3695628240',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.813589,10.20417',
  id: '50.813589,10.20417'
}, {
  Brand: 'ARAL',
  Street: 'Potsdamer Straße 82',
  PostCode: 14542,
  City: 'Werder',
  Latitude: '523.644.910',
  Longitude: '129.406.110',
  Telephone: '332745835',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.364491,12.940611',
  id: '52.364491,12.940611'
}, {
  Brand: 'ARAL',
  Street: 'Plauensche Straße 65',
  PostCode: 8606,
  City: 'Oelsnitz',
  Latitude: '504.262.450',
  Longitude: '121.766.790',
  Telephone: '3742122180',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.426245,12.176679',
  id: '50.426245,12.176679'
}, {
  Brand: 'ARAL',
  Street: 'Löderburger Straße',
  PostCode: 39418,
  City: 'Staßfurt',
  Latitude: '518.674.050',
  Longitude: '115.568.050',
  Telephone: '3925621597',
  WeekDayFrom: '04:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '05:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.867405,11.556805',
  id: '51.867405,11.556805'
}, {
  Brand: 'ARAL',
  Street: 'Eislebener Chaussee 200',
  PostCode: 6126,
  City: 'Halle',
  Latitude: '514.715.570',
  Longitude: '119.049.810',
  Telephone: '3456903263',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.471557,11.904981',
  id: '51.471557,11.904981'
}, {
  Brand: 'ARAL',
  Street: 'Stralsunder Straße 46',
  PostCode: 18507,
  City: 'Grimmen',
  Latitude: '541.177.680',
  Longitude: '130.436.360',
  Telephone: '383262443',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '54.117768,13.043636',
  id: '54.117768,13.043636'
}, {
  Brand: 'ARAL',
  Street: 'Leipziger  Straße 43',
  PostCode: 9599,
  City: 'Freiberg',
  Latitude: '509.288.400',
  Longitude: '133.279.740',
  Telephone: '373133699',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.92884,13.327974',
  id: '50.92884,13.327974'
}, {
  Brand: 'ARAL',
  Street: 'HalberstädterChaussee 196',
  PostCode: 39116,
  City: 'Magdeburg',
  Latitude: '521.053.440',
  Longitude: '115.900.980',
  Telephone: '3916229148',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.105344,11.590098',
  id: '52.105344,11.590098'
}, {
  Brand: 'ARAL',
  Street: 'Schilddorf 10',
  PostCode: 39606,
  City: 'Osterburg',
  Latitude: '527.681.330',
  Longitude: '117.548.840',
  Telephone: '393783756',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.768133,11.754884',
  id: '52.768133,11.754884'
}, {
  Brand: 'ARAL',
  Street: 'Ratzeburger Chaussee 3',
  PostCode: 19205,
  City: 'Gadebusch',
  Latitude: '536.970.760',
  Longitude: '111.022.480',
  Telephone: '3886712717',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.697076,11.102248',
  id: '53.697076,11.102248'
}, {
  Brand: 'ARAL',
  Street: 'Kreckwitzer Straße 2',
  PostCode: 2625,
  City: 'Bautzen',
  Latitude: '511.901.780',
  Longitude: '144.516.730',
  Telephone: '3591211090',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.190178,14.451673',
  id: '51.190178,14.451673'
}, {
  Brand: 'ARAL',
  Street: 'Annaberger Straße 94',
  PostCode: 9120,
  City: 'Chemnitz',
  Latitude: '508.145.930',
  Longitude: '129.130.100',
  Telephone: '371530780',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.814593,12.91301',
  id: '50.814593,12.91301'
}, {
  Brand: 'ARAL',
  Street: 'Friedenstraße 36',
  PostCode: 15517,
  City: 'Fürstenwalde',
  Latitude: '523.356.680',
  Longitude: '140.735.320',
  Telephone: '336157368',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.335668,14.073532',
  id: '52.335668,14.073532'
}, {
  Brand: 'ARAL',
  Street: 'Kamenzer Bogen 16',
  PostCode: 2977,
  City: 'Hoyerswerda',
  Latitude: '514.244.530',
  Longitude: '142.250.920',
  Telephone: '3571406537',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.424453,14.225092',
  id: '51.424453,14.225092'
}, {
  Brand: 'ARAL',
  Street: 'Triptiser Straße 36',
  PostCode: 7806,
  City: 'Neustadt',
  Latitude: '507.387.850',
  Longitude: '117.681.530',
  Telephone: '3648122249',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.738785,11.768153',
  id: '50.738785,11.768153'
}, {
  Brand: 'ARAL',
  Street: 'Freienwalder Straße',
  PostCode: 16356,
  City: 'Werneuchen',
  Latitude: '526.356.770',
  Longitude: '137.448.710',
  Telephone: '333987407',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.635677,13.744871',
  id: '52.635677,13.744871'
}, {
  Brand: 'ARAL',
  Street: 'Rostocker Straße 15',
  PostCode: 17213,
  City: 'Malchow',
  Latitude: '534.814.030',
  Longitude: '124.214.780',
  Telephone: '3993214757',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.481403,12.421478',
  id: '53.481403,12.421478'
}, {
  Brand: 'ARAL',
  Street: 'Nieskyer Straße 9',
  PostCode: 2828,
  City: 'Görlitz',
  Latitude: '511.634.700',
  Longitude: '149.726.850',
  Telephone: '3581316410',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.16347,14.972685',
  id: '51.16347,14.972685'
}, {
  Brand: 'ARAL',
  Street: 'Anderslebener Straße 42 a',
  PostCode: 39387,
  City: 'Oschersleben',
  Latitude: '520.288.230',
  Longitude: '112.459.800',
  Telephone: '394995959',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.028823,11.24598',
  id: '52.028823,11.24598'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Straße 125',
  PostCode: 15299,
  City: 'Müllrose',
  Latitude: '522.583.660',
  Longitude: '144.267.410',
  Telephone: '336064662',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.258366,14.426741',
  id: '52.258366,14.426741'
}, {
  Brand: 'ARAL',
  Street: 'Kummersdorfer Straße 13 d',
  PostCode: 15859,
  City: 'Storkow',
  Latitude: '522.636.180',
  Longitude: '139.355.840',
  Telephone: '3367867991',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.263618,13.935584',
  id: '52.263618,13.935584'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße',
  PostCode: 99831,
  City: 'Creuzburg',
  Latitude: '510.504.540',
  Longitude: '102.264.570',
  Telephone: '3692698488',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:30',
  SaturdayTo: '22:00',
  SundayFrom: '05:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '51.050454,10.226457',
  id: '51.050454,10.226457'
}, {
  Brand: 'ARAL',
  Street: 'Karl-Marx-Straße',
  PostCode: 15890,
  City: 'Eisenhüttenstadt',
  Latitude: '521.401.650',
  Longitude: '146.342.480',
  Telephone: '336444953',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.140165,14.634248',
  id: '52.140165,14.634248'
}, {
  Brand: 'ARAL',
  Street: 'Alvensleber Landstraße 5',
  PostCode: 39340,
  City: 'Haldensleben',
  Latitude: '522.763.060',
  Longitude: '113.897.880',
  Telephone: '390443232',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.276306,11.389788',
  id: '52.276306,11.389788'
}, {
  Brand: 'ARAL',
  Street: 'Neustrelitzer Straße 118',
  PostCode: 17033,
  City: 'Neubrandenburg',
  Latitude: '535.302.130',
  Longitude: '132.618.180',
  Telephone: '3953687733',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.530213,13.261818',
  id: '53.530213,13.261818'
}, {
  Brand: 'ARAL',
  Street: 'Neustadter Straße 137',
  PostCode: 96515,
  City: 'Sonneberg',
  Latitude: '503.491.460',
  Longitude: '111.528.110',
  Telephone: '3675802960',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:30',
  SaturdayFrom: '05:30',
  SaturdayTo: '22:30',
  SundayFrom: '05:30',
  SundayTo: '22:30',
  IsOpen24Hours: 'Ja',
  location: '50.349146,11.152811',
  id: '50.349146,11.152811'
}, {
  Brand: 'ARAL',
  Street: 'Altenburger Straße 79',
  PostCode: 4539,
  City: 'Groitzsch',
  Latitude: '511.510.180',
  Longitude: '122.865.980',
  Telephone: '3429642265',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.151018,12.286598',
  id: '51.151018,12.286598'
}, {
  Brand: 'ARAL',
  Street: 'Luckenwalder Strasse 111',
  PostCode: 15711,
  City: 'Königs Wusterhausen',
  Latitude: '522.838.200',
  Longitude: '136.145.330',
  Telephone: '3375242010',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.28382,13.614533',
  id: '52.28382,13.614533'
}, {
  Brand: 'ARAL',
  Street: 'Ludwigsluster Chaussee 24',
  PostCode: 19370,
  City: 'Parchim',
  Latitude: '534.264.460',
  Longitude: '118.192.700',
  Telephone: '3871443141',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.426446,11.81927',
  id: '53.426446,11.81927'
}, {
  Brand: 'ARAL',
  Street: 'Zschopauer Straße 234',
  PostCode: 9126,
  City: 'Chemnitz',
  Latitude: '508.141.580',
  Longitude: '129.548.280',
  Telephone: '37133471632',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.814158,12.954828',
  id: '50.814158,12.954828'
}, {
  Brand: 'ARAL',
  Street: 'Suhler Straße 5 a',
  PostCode: 99885,
  City: 'Ohrdruf',
  Latitude: '508.165.700',
  Longitude: '107.292.920',
  Telephone: '3624312755',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.81657,10.729292',
  id: '50.81657,10.729292'
}, {
  Brand: 'ARAL',
  Street: 'Dresdener Straße 30 t',
  PostCode: 4720,
  City: 'Döbeln',
  Latitude: '511.204.790',
  Longitude: '131.498.260',
  Telephone: '3431702392',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.120479,13.149826',
  id: '51.120479,13.149826'
}, {
  Brand: 'ARAL',
  Street: 'Leipziger Straße 95',
  PostCode: 4668,
  City: 'Grimma',
  Latitude: '512.313.180',
  Longitude: '127.039.640',
  Telephone: '3437917940',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.231318,12.703964',
  id: '51.231318,12.703964'
}, {
  Brand: 'ARAL',
  Street: 'Jenaer Straße 13',
  PostCode: 7607,
  City: 'Eisenberg',
  Latitude: '509.701.060',
  Longitude: '118.900.350',
  Telephone: '3669152900',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.970106,11.890035',
  id: '50.970106,11.890035'
}, {
  Brand: 'ARAL',
  Street: 'Berkaer Straße 71',
  PostCode: 99425,
  City: 'Weimar',
  Latitude: '509.607.260',
  Longitude: '113.164.040',
  Telephone: '3643905891',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.960726,11.316404',
  id: '50.960726,11.316404'
}, {
  Brand: 'ARAL',
  Street: 'Magdeburg-Leipziger Chaus',
  PostCode: 39443,
  City: 'Staßfurt',
  Latitude: '519.171.860',
  Longitude: '116.106.090',
  Telephone: '3926650130',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.917186,11.610609',
  id: '51.917186,11.610609'
}, {
  Brand: 'ARAL',
  Street: 'Badstraße 69',
  PostCode: 1454,
  City: 'Radeberg',
  Latitude: '511.309.910',
  Longitude: '139.166.890',
  Telephone: '3528442320',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.130991,13.916689',
  id: '51.130991,13.916689'
}, {
  Brand: 'ARAL',
  Street: 'Steinfurther Straße/B 184',
  PostCode: 6766,
  City: 'Wolfen',
  Latitude: '516.833.790',
  Longitude: '122.737.570',
  Telephone: '349422926',
  WeekDayFrom: '05:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '06:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.683379,12.273757',
  id: '51.683379,12.273757'
}, {
  Brand: 'ARAL',
  Street: 'Woldegker Straße 39',
  PostCode: 17036,
  City: 'Neubrandenburg',
  Latitude: '535.571.020',
  Longitude: '133.109.350',
  Telephone: '3957792285',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.557102,13.310935',
  id: '53.557102,13.310935'
}, {
  Brand: 'ARAL',
  Street: 'Leipziger Straße 20',
  PostCode: 4916,
  City: 'Herzberg',
  Latitude: '516.902.450',
  Longitude: '132.209.480',
  Telephone: '353521440',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.690245,13.220948',
  id: '51.690245,13.220948'
}, {
  Brand: 'ARAL',
  Street: 'Fischhausstraße 15',
  PostCode: 1099,
  City: 'Dresden',
  Latitude: '510.704.600',
  Longitude: '137.887.810',
  Telephone: '3518043050',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.07046,13.788781',
  id: '51.07046,13.788781'
}, {
  Brand: 'ARAL',
  Street: 'Am Eicheneck 7',
  PostCode: 18273,
  City: 'Güstrow',
  Latitude: '538.095.940',
  Longitude: '121.898.350',
  Telephone: '384322122',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.809594,12.189835',
  id: '53.809594,12.189835'
}, {
  Brand: 'ARAL',
  Street: 'An der Hochstraße 16',
  PostCode: 2906,
  City: 'Niesky',
  Latitude: '512.800.370',
  Longitude: '148.185.020',
  Telephone: '3588206921',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.280037,14.818502',
  id: '51.280037,14.818502'
}, {
  Brand: 'ARAL',
  Street: 'Am Wilhelmschacht 17',
  PostCode: 4552,
  City: 'Borna',
  Latitude: '511.161.920',
  Longitude: '124.859.270',
  Telephone: '3433208803',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.116192,12.485927',
  id: '51.116192,12.485927'
}, {
  Brand: 'ARAL',
  Street: 'Weinböhlaer Straße 46',
  PostCode: 1640,
  City: 'Coswig',
  Latitude: '511.354.770',
  Longitude: '135.768.790',
  Telephone: '352373629',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.135477,13.576879',
  id: '51.135477,13.576879'
}, {
  Brand: 'ARAL',
  Street: 'An der Crivitzer Chaussee',
  PostCode: 19063,
  City: 'Schwerin',
  Latitude: '536.003.180',
  Longitude: '114.650.760',
  Telephone: '3852072484',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.600318,11.465076',
  id: '53.600318,11.465076'
}, {
  Brand: 'ARAL',
  Street: 'Dohnaer Straße 103',
  PostCode: 1219,
  City: 'Dresden',
  Latitude: '510.137.560',
  Longitude: '137.747.900',
  Telephone: '3512842533',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:30',
  SaturdayFrom: '09:00',
  SaturdayTo: '17:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.013756,13.77479',
  id: '51.013756,13.77479'
}, {
  Brand: 'ARAL',
  Street: 'Geuzer Straße / B 185',
  PostCode: 6366,
  City: 'Köthen',
  Latitude: '517.546.000',
  Longitude: '119.488.100',
  Telephone: '3496550126',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.7546,11.94881',
  id: '51.7546,11.94881'
}, {
  Brand: 'ARAL',
  Street: 'Augustenstraße 88',
  PostCode: 6493,
  City: 'Harzgerode',
  Latitude: '516.345.990',
  Longitude: '111.471.290',
  Telephone: '394843130',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Ja',
  location: '51.634599,11.147129',
  id: '51.634599,11.147129'
}, {
  Brand: 'ARAL',
  Street: 'Schwarzenberger Straße 4',
  PostCode: 8340,
  City: 'Schwarzenberg',
  Latitude: '505.311.560',
  Longitude: '128.117.050',
  Telephone: '377486350',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.531156,12.811705',
  id: '50.531156,12.811705'
}, {
  Brand: 'ARAL',
  Street: 'Arneburger Straße 47/B 18',
  PostCode: 39576,
  City: 'Stendal',
  Latitude: '526.180.610',
  Longitude: '118.686.610',
  Telephone: '3931796822',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.618061,11.868661',
  id: '52.618061,11.868661'
}, {
  Brand: 'ARAL',
  Street: 'Kösener Straße',
  PostCode: 6618,
  City: 'Naumburg',
  Latitude: '511.509.730',
  Longitude: '117.839.960',
  Telephone: '3445778467',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.150973,11.783996',
  id: '51.150973,11.783996'
}, {
  Brand: 'ARAL',
  Street: 'Brielower Landstraße 17',
  PostCode: 14772,
  City: 'Brandenburg',
  Latitude: '524.303.020',
  Longitude: '125.533.480',
  Telephone: '3381700164',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.430302,12.553348',
  id: '52.430302,12.553348'
}, {
  Brand: 'ARAL',
  Street: 'Wittenberger Straße',
  PostCode: 6749,
  City: 'Bitterfeld',
  Latitude: '516.271.090',
  Longitude: '123.366.670',
  Telephone: '349324717',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.627109,12.336667',
  id: '51.627109,12.336667'
}, {
  Brand: 'ARAL',
  Street: 'Vor dem Rittertor',
  PostCode: 39164,
  City: 'Wanzleben',
  Latitude: '520.614.500',
  Longitude: '114.329.630',
  Telephone: '3920942078',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.06145,11.432963',
  id: '52.06145,11.432963'
}, {
  Brand: 'ARAL',
  Street: 'R.-Luxemburg-Straße 105',
  PostCode: 6917,
  City: 'Jessen',
  Latitude: '517.966.730',
  Longitude: '129.740.080',
  Telephone: '3537213597',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.796673,12.974008',
  id: '51.796673,12.974008'
}, {
  Brand: 'ARAL',
  Street: 'Großenhainer Straße 44',
  PostCode: 1127,
  City: 'Dresden',
  Latitude: '510.766.120',
  Longitude: '137.332.480',
  Telephone: '3518495448',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.076612,13.733248',
  id: '51.076612,13.733248'
}, {
  Brand: 'ARAL',
  Street: 'Leipziger Straße 206',
  PostCode: 9114,
  City: 'Chemnitz',
  Latitude: '508.568.680',
  Longitude: '128.752.180',
  Telephone: '371372415',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.856868,12.875218',
  id: '50.856868,12.875218'
}, {
  Brand: 'ARAL',
  Street: 'Genthiner Straße 65 a',
  PostCode: 14712,
  City: 'Rathenow',
  Latitude: '525.995.030',
  Longitude: '122.992.600',
  Telephone: '3385516278',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '52.599503,12.29926',
  id: '52.599503,12.29926'
}, {
  Brand: 'ARAL',
  Street: 'McDonalds-Straße 1',
  PostCode: 16356,
  City: 'Blumberg',
  Latitude: '525.824.240',
  Longitude: '135.872.250',
  Telephone: '333945150',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.582424,13.587225',
  id: '52.582424,13.587225'
}, {
  Brand: 'ARAL',
  Street: 'Prenzlauer Chaussee 76',
  PostCode: 16348,
  City: 'Wandlitz',
  Latitude: '527.431.480',
  Longitude: '134.584.500',
  Telephone: '3339781748',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.743148,13.45845',
  id: '52.743148,13.45845'
}, {
  Brand: 'ARAL',
  Street: 'Harzweg 19',
  PostCode: 6484,
  City: 'Quedlinburg',
  Latitude: '517.830.420',
  Longitude: '111.467.660',
  Telephone: '3946701128',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.783042,11.146766',
  id: '51.783042,11.146766'
}, {
  Brand: 'ARAL',
  Street: 'Straße des Friedens 40',
  PostCode: 3222,
  City: 'Lübbenau',
  Latitude: '518.533.340',
  Longitude: '139.492.920',
  Telephone: '354244848',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.853334,13.949292',
  id: '51.853334,13.949292'
}, {
  Brand: 'ARAL',
  Street: 'Röxer Straße 16',
  PostCode: 39576,
  City: 'Stendal',
  Latitude: '525.945.950',
  Longitude: '118.425.580',
  Telephone: '3931715620',
  WeekDayFrom: '04:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.594595,11.842558',
  id: '52.594595,11.842558'
}, {
  Brand: 'ARAL',
  Street: 'Am Heerweg 33',
  PostCode: 7768,
  City: 'Kahla',
  Latitude: '508.093.090',
  Longitude: '115.774.490',
  Telephone: '3642424005',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.809309,11.577449',
  id: '50.809309,11.577449'
}, {
  Brand: 'ARAL',
  Street: 'Zwickauer Straße 240',
  PostCode: 8468,
  City: 'Reichenbach',
  Latitude: '506.362.260',
  Longitude: '123.237.080',
  Telephone: '376512016',
  WeekDayFrom: '04:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.636226,12.323708',
  id: '50.636226,12.323708'
}, {
  Brand: 'ARAL',
  Street: 'Trockentalstraße 64',
  PostCode: 8527,
  City: 'Plauen',
  Latitude: '504.901.600',
  Longitude: '121.309.480',
  Telephone: '3741137066',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.49016,12.130948',
  id: '50.49016,12.130948'
}, {
  Brand: 'ARAL',
  Street: 'Bockauer Talstraße 20',
  PostCode: 8280,
  City: 'Aue',
  Latitude: '505.795.740',
  Longitude: '126.920.090',
  Telephone: '377120368',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '50.579574,12.692009',
  id: '50.579574,12.692009'
}, {
  Brand: 'ARAL',
  Street: 'Plauensche Straße 70',
  PostCode: 8223,
  City: 'Falkenstein',
  Latitude: '504.741.740',
  Longitude: '123.543.680',
  Telephone: '374571566',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.474174,12.354368',
  id: '50.474174,12.354368'
}, {
  Brand: 'ARAL',
  Street: 'An der Stadtautobahn 60',
  PostCode: 18119,
  City: 'Rostock',
  Latitude: '541.684.220',
  Longitude: '120.799.580',
  Telephone: '3815016',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.168422,12.079958',
  id: '54.168422,12.079958'
}, {
  Brand: 'ARAL',
  Street: 'Chemnitzer Straße 34',
  PostCode: 9456,
  City: 'Annaberg-Buchholz',
  Latitude: '505.911.240',
  Longitude: '130.110.430',
  Telephone: '373351015',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.591124,13.011043',
  id: '50.591124,13.011043'
}, {
  Brand: 'ARAL',
  Street: 'Magdeburger Chaussee 2',
  PostCode: 6118,
  City: 'Halle',
  Latitude: '515.218.780',
  Longitude: '119.537.430',
  Telephone: '3455210836',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.521878,11.953743',
  id: '51.521878,11.953743'
}, {
  Brand: 'ARAL',
  Street: 'Reichenbacher Straße 89',
  PostCode: 2827,
  City: 'Görlitz',
  Latitude: '511.474.880',
  Longitude: '149.414.480',
  Telephone: '358178180',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.147488,14.941448',
  id: '51.147488,14.941448'
}, {
  Brand: 'ARAL',
  Street: 'Chemnitzer Straße 47 d',
  PostCode: 9380,
  City: 'Thalheim',
  Latitude: '507.100.530',
  Longitude: '128.657.680',
  Telephone: '372138992',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.710053,12.865768',
  id: '50.710053,12.865768'
}, {
  Brand: 'ARAL',
  Street: 'Merzdorfer Weg 1 B',
  PostCode: 3042,
  City: 'Cottbus',
  Latitude: '517.672.490',
  Longitude: '143.590.600',
  Telephone: '355722208',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.767249,14.35906',
  id: '51.767249,14.35906'
}, {
  Brand: 'ARAL',
  Street: 'Str.der Jugend 14',
  PostCode: 39517,
  City: 'Tangerhütte',
  Latitude: '524.309.850',
  Longitude: '117.960.160',
  Telephone: '3935213726',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.430985,11.796016',
  id: '52.430985,11.796016'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Straße 25a',
  PostCode: 3226,
  City: 'Vetschau',
  Latitude: '517.847.790',
  Longitude: '140.669.660',
  Telephone: '3543371111',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.784779,14.066966',
  id: '51.784779,14.066966'
}, {
  Brand: 'ARAL',
  Street: 'Arthur-Scheunert-Allee 73',
  PostCode: 14558,
  City: 'Nuthetal',
  Latitude: '523.509.420',
  Longitude: '131.034.560',
  Telephone: '3320083903',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.350942,13.103456',
  id: '52.350942,13.103456'
}, {
  Brand: 'ARAL',
  Street: 'Rumburger Straße 38',
  PostCode: 2730,
  City: 'Ebersbach',
  Latitude: '509.979.680',
  Longitude: '146.165.200',
  Telephone: '3586300091',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.997968,14.61652',
  id: '50.997968,14.61652'
}, {
  Brand: 'ARAL',
  Street: 'Weißenfelser Straße 98',
  PostCode: 6712,
  City: 'Zeitz',
  Latitude: '510.693.620',
  Longitude: '121.219.170',
  Telephone: '3441214095',
  WeekDayFrom: '05:30',
  WeekDayTo: '21:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:30',
  SundayFrom: '07:00',
  SundayTo: '21:30',
  IsOpen24Hours: 'Ja',
  location: '51.069362,12.121917',
  id: '51.069362,12.121917'
}, {
  Brand: 'ARAL',
  Street: 'Meißner Straße 99',
  PostCode: 1445,
  City: 'Radebeul',
  Latitude: '511.037.000',
  Longitude: '136.735.000',
  Telephone: '3518302733',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Ja',
  location: '51.1037,13.6735',
  id: '51.1037,13.6735'
}, {
  Brand: 'ARAL',
  Street: 'Kranichfelder Straße 2',
  PostCode: 99097,
  City: 'Erfurt',
  Latitude: '509.584.480',
  Longitude: '110.531.050',
  Telephone: '3613465366',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.958448,11.053105',
  id: '50.958448,11.053105'
}, {
  Brand: 'ARAL',
  Street: 'Ludwig-Hupfeld-Straße 15',
  PostCode: 4178,
  City: 'Leipzig',
  Latitude: '513.485.330',
  Longitude: '123.014.730',
  Telephone: '3414418938',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.348533,12.301473',
  id: '51.348533,12.301473'
}, {
  Brand: 'ARAL',
  Street: 'Merseburger Straße',
  PostCode: 6242,
  City: 'Braunsbedra',
  Latitude: '512.886.180',
  Longitude: '119.046.740',
  Telephone: '3463321881',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.288618,11.904674',
  id: '51.288618,11.904674'
}, {
  Brand: 'ARAL',
  Street: 'Erfurter Straße 35',
  PostCode: 6526,
  City: 'Sangerhausen',
  Latitude: '514.565.130',
  Longitude: '113.002.160',
  Telephone: '3464517900',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.456513,11.300216',
  id: '51.456513,11.300216'
}, {
  Brand: 'ARAL',
  Street: 'Merseburger Straße 111',
  PostCode: 4177,
  City: 'Leipzig',
  Latitude: '513.381.680',
  Longitude: '123.255.990',
  Telephone: '3414801898',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.338168,12.325599',
  id: '51.338168,12.325599'
}, {
  Brand: 'ARAL',
  Street: 'Löbauer Straße 65',
  PostCode: 2625,
  City: 'Bautzen',
  Latitude: '511.786.300',
  Longitude: '144.530.800',
  Telephone: '3591211102',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.17863,14.45308',
  id: '51.17863,14.45308'
}, {
  Brand: 'ARAL',
  Street: 'Pasewalker Straße 110',
  PostCode: 13127,
  City: 'Berlin',
  Latitude: '525.861.530',
  Longitude: '134.299.280',
  Telephone: '304767080',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.586153,13.429928',
  id: '52.586153,13.429928'
}, {
  Brand: 'ARAL',
  Street: 'Mansfelder Straße',
  PostCode: 6333,
  City: 'Hettstedt',
  Latitude: '516.353.020',
  Longitude: '115.061.060',
  Telephone: '3476810278',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.635302,11.506106',
  id: '51.635302,11.506106'
}, {
  Brand: 'ARAL',
  Street: 'Bitterfelder Straße 51',
  PostCode: 6780,
  City: 'Zörbig',
  Latitude: '516.254.840',
  Longitude: '121.337.210',
  Telephone: '34956399202',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.625484,12.133721',
  id: '51.625484,12.133721'
}, {
  Brand: 'ARAL',
  Street: 'Leipziger Straße 63 a',
  PostCode: 6231,
  City: 'Bad Dürrenberg',
  Latitude: '512.908.990',
  Longitude: '120.762.980',
  Telephone: '346282328',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.290899,12.076298',
  id: '51.290899,12.076298'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Straße 101',
  PostCode: 15907,
  City: 'Lübben',
  Latitude: '519.424.190',
  Longitude: '139.108.700',
  Telephone: '35468456',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.942419,13.91087',
  id: '51.942419,13.91087'
}, {
  Brand: 'ARAL',
  Street: 'Albrechtstraße 35',
  PostCode: 6844,
  City: 'Dessau',
  Latitude: '518.483.430',
  Longitude: '122.418.530',
  Telephone: '3402210103',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.848343,12.241853',
  id: '51.848343,12.241853'
}, {
  Brand: 'ARAL',
  Street: 'Lüttgenröder Straße 1',
  PostCode: 38835,
  City: 'Osterwieck',
  Latitude: '519.686.840',
  Longitude: '106.997.070',
  Telephone: '394216140',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.968684,10.699707',
  id: '51.968684,10.699707'
}, {
  Brand: 'ARAL',
  Street: 'Lübsche Straße 161',
  PostCode: 23966,
  City: 'Wismar',
  Latitude: '538.944.850',
  Longitude: '114.402.390',
  Telephone: '3841704718',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.894485,11.440239',
  id: '53.894485,11.440239'
}, {
  Brand: 'ARAL',
  Street: 'Leipziger Straße 208',
  PostCode: 8058,
  City: 'Zwickau',
  Latitude: '507.426.860',
  Longitude: '124.881.690',
  Telephone: '375282684',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.742686,12.488169',
  id: '50.742686,12.488169'
}, {
  Brand: 'ARAL',
  Street: 'Maximilianallee 70',
  PostCode: 4129,
  City: 'Leipzig',
  Latitude: '513.858.000',
  Longitude: '123.897.450',
  Telephone: '3419129096',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.3858,12.389745',
  id: '51.3858,12.389745'
}, {
  Brand: 'ARAL',
  Street: 'Heidestraße 348',
  PostCode: 6849,
  City: 'Dessau',
  Latitude: '517.979.970',
  Longitude: '122.401.150',
  Telephone: '3408546482',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '51.797997,12.240115',
  id: '51.797997,12.240115'
}, {
  Brand: 'ARAL',
  Street: 'Planitzer Straße 3-5',
  PostCode: 8056,
  City: 'Zwickau',
  Latitude: '507.044.390',
  Longitude: '124.938.270',
  Telephone: '375282580',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.704439,12.493827',
  id: '50.704439,12.493827'
}, {
  Brand: 'ARAL',
  Street: 'Rosenfelder Straße 13',
  PostCode: 6116,
  City: 'Halle',
  Latitude: '515.022.480',
  Longitude: '120.336.820',
  Telephone: '3455606492',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.502248,12.033682',
  id: '51.502248,12.033682'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Straße 11',
  PostCode: 15537,
  City: 'Erkner',
  Latitude: '524.280.550',
  Longitude: '137.441.910',
  Telephone: '336224701',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '52.428055,13.744191',
  id: '52.428055,13.744191'
}, {
  Brand: 'ARAL',
  Street: 'An der B 100 32',
  PostCode: 6796,
  City: 'Brehna',
  Latitude: '515.537.360',
  Longitude: '121.966.590',
  Telephone: '3495448837',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.553736,12.196659',
  id: '51.553736,12.196659'
}, {
  Brand: 'ARAL',
  Street: 'Ernst-Thälmann-Straße 195',
  PostCode: 29410,
  City: 'Salzwedel',
  Latitude: '528.486.530',
  Longitude: '111.628.700',
  Telephone: '390134000',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.848653,11.16287',
  id: '52.848653,11.16287'
}, {
  Brand: 'ARAL',
  Street: 'Straße des 17. Juni 9',
  PostCode: 1257,
  City: 'Dresden',
  Latitude: '510.066.260',
  Longitude: '138.194.070',
  Telephone: '3512015155',
  WeekDayFrom: '05:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '51.006626,13.819407',
  id: '51.006626,13.819407'
}, {
  Brand: 'ARAL',
  Street: 'Brander Straße 55',
  PostCode: 9599,
  City: 'Freiberg',
  Latitude: '509.031.640',
  Longitude: '133.379.290',
  Telephone: '3731767710',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '05:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.903164,13.337929',
  id: '50.903164,13.337929'
}, {
  Brand: 'ARAL',
  Street: 'Fürstenwalder Damm 445-44',
  PostCode: 12587,
  City: 'Berlin',
  Latitude: '524.562.960',
  Longitude: '136.211.210',
  Telephone: '3064197518',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '22:00',
  SundayFrom: '07:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.456296,13.621121',
  id: '52.456296,13.621121'
}, {
  Brand: 'ARAL',
  Street: 'Toitenwinkler Allee 1',
  PostCode: 18147,
  City: 'Rostock',
  Latitude: '541.135.350',
  Longitude: '121.614.860',
  Telephone: '381690347',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.113535,12.161486',
  id: '54.113535,12.161486'
}, {
  Brand: 'ARAL',
  Street: 'Hamburger Straße 88 c',
  PostCode: 1157,
  City: 'Dresden',
  Latitude: '510.628.070',
  Longitude: '136.797.350',
  Telephone: '3514226969',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.062807,13.679735',
  id: '51.062807,13.679735'
}, {
  Brand: 'ARAL',
  Street: 'Potsdamer Straße 55',
  PostCode: 14469,
  City: 'Potsdam',
  Latitude: '524.195.780',
  Longitude: '130.127.950',
  Telephone: '3315053806',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.419578,13.012795',
  id: '52.419578,13.012795'
}, {
  Brand: 'ARAL',
  Street: 'Permoserstraße 32',
  PostCode: 4318,
  City: 'Leipzig',
  Latitude: '513.509.640',
  Longitude: '124.341.850',
  Telephone: '3412323185',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '05:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Ja',
  location: '51.350964,12.434185',
  id: '51.350964,12.434185'
}, {
  Brand: 'ARAL',
  Street: 'Wittenburger Straße 121',
  PostCode: 19059,
  City: 'Schwerin',
  Latitude: '536.284.920',
  Longitude: '113.897.900',
  Telephone: '3857851496',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.628492,11.38979',
  id: '53.628492,11.38979'
}, {
  Brand: 'ARAL',
  Street: 'Königsbrücker  Straße 136',
  PostCode: 1099,
  City: 'Dresden',
  Latitude: '510.938.580',
  Longitude: '137.664.930',
  Telephone: '3518807875',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '23:59',
  SundayFrom: '08:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '51.093858,13.766493',
  id: '51.093858,13.766493'
}, {
  Brand: 'ARAL',
  Street: 'Mühlenstraße 26',
  PostCode: 13187,
  City: 'Berlin',
  Latitude: '525.638.350',
  Longitude: '134.080.010',
  Telephone: '3047302020',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.563835,13.408001',
  id: '52.563835,13.408001'
}, {
  Brand: 'ARAL',
  Street: 'Köpenicker Landstr. 294,',
  PostCode: 12437,
  City: 'Berlin',
  Latitude: '524.647.040',
  Longitude: '134.969.920',
  Telephone: '3063979990',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.464704,13.496992',
  id: '52.464704,13.496992'
}, {
  Brand: 'ARAL',
  Street: 'Werftstraße 13',
  PostCode: 1139,
  City: 'Dresden',
  Latitude: '510.689.930',
  Longitude: '136.899.750',
  Telephone: '3518496290',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.068993,13.689975',
  id: '51.068993,13.689975'
}, {
  Brand: 'ARAL',
  Street: 'Oranienburger Chaussee 34',
  PostCode: 16548,
  City: 'Glienicke',
  Latitude: '526.362.840',
  Longitude: '133.068.940',
  Telephone: '3305677500',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.636284,13.306894',
  id: '52.636284,13.306894'
}, {
  Brand: 'ARAL',
  Street: 'Tonnaer Straße 30 b',
  PostCode: 99947,
  City: 'Bad Langensalza',
  Latitude: '511.040.000',
  Longitude: '106.638.110',
  Telephone: '3603815726',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.104,10.663811',
  id: '51.104,10.663811'
}, {
  Brand: 'ARAL',
  Street: 'Adlergestell 748',
  PostCode: 12527,
  City: 'Berlin',
  Latitude: '523.788.100',
  Longitude: '136.441.910',
  Telephone: '3067549184',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.37881,13.644191',
  id: '52.37881,13.644191'
}, {
  Brand: 'ARAL',
  Street: 'FINKENKRUGER STRAßE 57',
  PostCode: 14612,
  City: 'FALKENSEE',
  Latitude: '525.626.970',
  Longitude: '130.744.240',
  Telephone: '3322240134',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.562697,13.074424',
  id: '52.562697,13.074424'
}, {
  Brand: 'ARAL',
  Street: 'Hamburger Straße 34',
  PostCode: 14641,
  City: 'Nauen',
  Latitude: '526.076.820',
  Longitude: '128.677.910',
  Telephone: '3321450373',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.607682,12.867791',
  id: '52.607682,12.867791'
}, {
  Brand: 'ARAL',
  Street: 'Max-Liebermann-Straße 101',
  PostCode: 4157,
  City: 'Leipzig',
  Latitude: '513.760.730',
  Longitude: '123.776.600',
  Telephone: '3419121670',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.376073,12.37766',
  id: '51.376073,12.37766'
}, {
  Brand: 'ARAL',
  Street: 'Blankenauer Straße 70',
  PostCode: 9113,
  City: 'Chemnitz',
  Latitude: '508.563.290',
  Longitude: '129.294.800',
  Telephone: '371449293',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.856329,12.92948',
  id: '50.856329,12.92948'
}, {
  Brand: 'ARAL',
  Street: 'Wildenfelser Straße 15',
  PostCode: 8056,
  City: 'Zwickau',
  Latitude: '507.020.930',
  Longitude: '125.083.050',
  Telephone: '375287020',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.702093,12.508305',
  id: '50.702093,12.508305'
}, {
  Brand: 'ARAL',
  Street: 'Siemensstraße 58',
  PostCode: 7546,
  City: 'Gera',
  Latitude: '509.122.590',
  Longitude: '120.648.240',
  Telephone: '36577349500',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.912259,12.064824',
  id: '50.912259,12.064824'
}, {
  Brand: 'ARAL',
  Street: 'Dresdner Straße 164',
  PostCode: 1705,
  City: 'Freital',
  Latitude: '510.017.630',
  Longitude: '136.514.850',
  Telephone: '3514601502',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.001763,13.651485',
  id: '51.001763,13.651485'
}, {
  Brand: 'ARAL',
  Street: 'Osdorfer Ring 33-35',
  PostCode: 14979,
  City: 'Großbeeren',
  Latitude: '523.785.060',
  Longitude: '133.129.220',
  Telephone: '3370190587',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.378506,13.312922',
  id: '52.378506,13.312922'
}, {
  Brand: 'ARAL',
  Street: 'Aralallee 1',
  PostCode: 99441,
  City: 'Mellingen',
  Latitude: '509.346.700',
  Longitude: '113.896.350',
  Telephone: '3645380630',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.93467,11.389635',
  id: '50.93467,11.389635'
}, {
  Brand: 'ARAL',
  Street: 'Alter Spandauer Weg 5',
  PostCode: 14641,
  City: 'Wustermark',
  Latitude: '525.400.850',
  Longitude: '129.776.010',
  Telephone: '3323488174',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.540085,12.977601',
  id: '52.540085,12.977601'
}, {
  Brand: 'ARAL',
  Street: 'Hallesche Straße 41/Schwa',
  PostCode: 6686,
  City: 'Lützen / OT Zorbau',
  Latitude: '511.777.070',
  Longitude: '120.101.840',
  Telephone: '3444192234',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.177707,12.010184',
  id: '51.177707,12.010184'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Chaussee 68 /',
  PostCode: 15370,
  City: 'Vogelsdorf',
  Latitude: '524.995.560',
  Longitude: '137.438.010',
  Telephone: '3343965601',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.499556,13.743801',
  id: '52.499556,13.743801'
}, {
  Brand: 'ARAL',
  Street: 'Märkische Allee 155',
  PostCode: 12681,
  City: 'Berlin',
  Latitude: '525.320.260',
  Longitude: '135.363.050',
  Telephone: '3054397011',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '52.532026,13.536305',
  id: '52.532026,13.536305'
}, {
  Brand: 'ARAL',
  Street: 'Spenglerstr. 2',
  PostCode: 16356,
  City: 'Ahrensfelde',
  Latitude: '526.081.080',
  Longitude: '135.288.800',
  Telephone: '3094113252',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.608108,13.52888',
  id: '52.608108,13.52888'
}, {
  Brand: 'ARAL',
  Street: 'Rudolf-Diesel-Straße 02',
  PostCode: 19243,
  City: 'Wittenburg',
  Latitude: '535.022.510',
  Longitude: '110.979.560',
  Telephone: '3885252182',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.502251,11.097956',
  id: '53.502251,11.097956'
}, {
  Brand: 'ARAL',
  Street: 'Güstrower Straße 32',
  PostCode: 19055,
  City: 'Schwerin',
  Latitude: '536.434.480',
  Longitude: '114.247.850',
  Telephone: '3855815854',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.643448,11.424785',
  id: '53.643448,11.424785'
}, {
  Brand: 'ARAL',
  Street: 'Nordstr. 1/B 71',
  PostCode: 6420,
  City: 'Könnern',
  Latitude: '516.890.580',
  Longitude: '117.672.140',
  Telephone: '3469151256',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.689058,11.767214',
  id: '51.689058,11.767214'
}, {
  Brand: 'ARAL',
  Street: 'Lichtenseer Str.  18',
  PostCode: 1619,
  City: 'Zeithain',
  Latitude: '513.404.080',
  Longitude: '133.477.220',
  Telephone: '3525761326',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.340408,13.347722',
  id: '51.340408,13.347722'
}, {
  Brand: 'ARAL',
  Street: 'Bergstr. 121',
  PostCode: 1217,
  City: 'Dresden',
  Latitude: '510.198.330',
  Longitude: '137.300.030',
  Telephone: '3514016150',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.019833,13.730003',
  id: '51.019833,13.730003'
}, {
  Brand: 'ARAL',
  Street: 'Grabower Straße 2',
  PostCode: 39126,
  City: 'Magdeburg',
  Latitude: '522.048.780',
  Longitude: '116.722.120',
  Telephone: '39125443057',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.204878,11.672212',
  id: '52.204878,11.672212'
}, {
  Brand: 'ARAL',
  Street: 'Weißenseer Straße 68',
  PostCode: 99610,
  City: 'Sömmerda',
  Latitude: '511.631.510',
  Longitude: '111.068.330',
  Telephone: '3634612704',
  WeekDayFrom: '04:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.163151,11.106833',
  id: '51.163151,11.106833'
}, {
  Brand: 'ARAL',
  Street: 'Schützenstraße 11',
  PostCode: 12529,
  City: 'Schönefeld',
  Latitude: '523.948.260',
  Longitude: '135.336.240',
  Telephone: '3063497445',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.394826,13.533624',
  id: '52.394826,13.533624'
}, {
  Brand: 'ARAL',
  Street: 'Treuenbrietzener Straße 64',
  PostCode: 14913,
  City: 'Niedergörsdorf',
  Latitude: '520.089.940',
  Longitude: '130.120.000',
  Telephone: '33724429633',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.008994,13.012',
  id: '52.008994,13.012'
}, {
  Brand: 'ARAL',
  Street: 'Am Lückefeld 2',
  PostCode: 15831,
  City: 'Mahlow',
  Latitude: '523.581.720',
  Longitude: '134.323.880',
  Telephone: '3379379701',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.358172,13.432388',
  id: '52.358172,13.432388'
}, {
  Brand: 'ARAL',
  Street: 'Im Gewerbegebiet 1',
  PostCode: 14778,
  City: 'Wollin b.Brandenburg',
  Latitude: '522.890.880',
  Longitude: '124.643.090',
  Telephone: '3383371843',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.289088,12.464309',
  id: '52.289088,12.464309'
}, {
  Brand: 'ARAL',
  Street: 'Robert-Guthmann-Straße 1',
  PostCode: 15713,
  City: 'Königs Wusterhausen',
  Latitude: '523.144.960',
  Longitude: '136.627.010',
  Telephone: '3375507127',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.314496,13.662701',
  id: '52.314496,13.662701'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Allee 41',
  PostCode: 15806,
  City: 'Zossen / OT Wünsdorf',
  Latitude: '521.709.550',
  Longitude: '134.714.560',
  Telephone: '3370266125',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.170955,13.471456',
  id: '52.170955,13.471456'
}, {
  Brand: 'ARAL',
  Street: 'Ahornstraße 1',
  PostCode: 19077,
  City: 'Rastow',
  Latitude: '534.389.690',
  Longitude: '114.895.840',
  Telephone: '3875388842',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.438969,11.489584',
  id: '53.438969,11.489584'
}, {
  Brand: 'ARAL',
  Street: 'Altlandsberger Chaussee27',
  PostCode: 15366,
  City: 'Hoppegarten',
  Latitude: '525.440.510',
  Longitude: '136.469.900',
  Telephone: '3342423763',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.544051,13.64699',
  id: '52.544051,13.64699'
}, {
  Brand: 'ARAL',
  Street: 'Zuckerfabrik 1',
  PostCode: 39343,
  City: 'Alleringersleben',
  Latitude: '522.094.170',
  Longitude: '111.330.190',
  Telephone: '3940092246',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.209417,11.133019',
  id: '52.209417,11.133019'
}, {
  Brand: 'ARAL',
  Street: 'Hengstbergstraße 11',
  PostCode: 4668,
  City: 'Grimma',
  Latitude: '512.561.470',
  Longitude: '127.253.920',
  Telephone: '3437760812',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.256147,12.725392',
  id: '51.256147,12.725392'
}, {
  Brand: 'ARAL',
  Street: 'Suhler Straße 5 b',
  PostCode: 99885,
  City: 'Ohrdruf',
  Latitude: '508.151.650',
  Longitude: '107.296.850',
  Telephone: '3624313753',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.815165,10.729685',
  id: '50.815165,10.729685'
}, {
  Brand: 'ARAL',
  Street: 'SIEDLERWEG 1',
  PostCode: 2923,
  City: 'Kodersdorf',
  Latitude: '512.256.740',
  Longitude: '149.020.960',
  Telephone: '3582562337',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.225674,14.902096',
  id: '51.225674,14.902096'
}, {
  Brand: 'ARAL',
  Street: 'Neue Schichtstraße 16',
  PostCode: 9366,
  City: 'Niederdorf',
  Latitude: '507.244.860',
  Longitude: '127.788.260',
  Telephone: '3729692441',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.724486,12.778826',
  id: '50.724486,12.778826'
}, {
  Brand: 'ARAL',
  Street: 'Brücker Landstraße 22 a',
  PostCode: 14806,
  City: 'Belzig',
  Latitude: '521.446.990',
  Longitude: '126.128.560',
  Telephone: '3384130222',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.144699,12.612856',
  id: '52.144699,12.612856'
}, {
  Brand: 'ARAL',
  Street: 'Geltestraße 1',
  PostCode: 6184,
  City: 'Dölbau',
  Latitude: '514.763.330',
  Longitude: '120.862.640',
  Telephone: '3460252411',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.476333,12.086264',
  id: '51.476333,12.086264'
}, {
  Brand: 'ARAL',
  Street: 'Liebenwalder Straße 10 a',
  PostCode: 16567,
  City: 'Mühlenbeck',
  Latitude: '526.692.960',
  Longitude: '133.775.380',
  Telephone: '3305682856',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.669296,13.377538',
  id: '52.669296,13.377538'
}, {
  Brand: 'ARAL',
  Street: 'A4',
  PostCode: 9244,
  City: 'Oberlichtenau',
  Latitude: '508.934.690',
  Longitude: '129.459.900',
  Telephone: '3720883523',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.893469,12.94599',
  id: '50.893469,12.94599'
}, {
  Brand: 'ARAL',
  Street: 'Graf-York-Straße 23',
  PostCode: 19061,
  City: 'Schwerin',
  Latitude: '535.908.340',
  Longitude: '114.306.410',
  Telephone: '3853921301',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.590834,11.430641',
  id: '53.590834,11.430641'
}, {
  Brand: 'ARAL',
  Street: 'Werner-von-Siemens-Straße',
  PostCode: 16321,
  City: 'Bernau',
  Latitude: '526.861.210',
  Longitude: '135.701.940',
  Telephone: '3338758845',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.686121,13.570194',
  id: '52.686121,13.570194'
}, {
  Brand: 'ARAL',
  Street: 'BAB  A 20 - Fuchsberg Nor',
  PostCode: 23992,
  City: 'Glasin',
  Latitude: '539.107.840',
  Longitude: '117.585.710',
  Telephone: '38429446611',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.910784,11.758571',
  id: '53.910784,11.758571'
}, {
  Brand: 'ARAL',
  Street: 'BAB A 20 - Fuchsberg Süd',
  PostCode: 23992,
  City: 'Glasin',
  Latitude: '539.089.770',
  Longitude: '117.567.380',
  Telephone: '38429446620',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.908977,11.756738',
  id: '53.908977,11.756738'
}, {
  Brand: 'ARAL',
  Street: 'Holzmarktstraße 12/14',
  PostCode: 10179,
  City: 'Berlin',
  Latitude: '525.141.510',
  Longitude: '134.214.870',
  Telephone: '3024720748',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.514151,13.421487',
  id: '52.514151,13.421487'
}, {
  Brand: 'ARAL',
  Street: 'Elsterwerdaer Straße 19 a',
  PostCode: 1558,
  City: 'Großenhain',
  Latitude: '512.980.170',
  Longitude: '135.337.960',
  Telephone: '352238555',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.298017,13.533796',
  id: '51.298017,13.533796'
}, {
  Brand: 'ARAL',
  Street: 'Hochuferstraße 11',
  PostCode: 1662,
  City: 'Meißen',
  Latitude: '511.691.110',
  Longitude: '134.713.230',
  Telephone: '3521453101',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.169111,13.471323',
  id: '51.169111,13.471323'
}, {
  Brand: 'ARAL',
  Street: 'Radeberger Straße 18',
  PostCode: 1471,
  City: 'Radeburg',
  Latitude: '512.141.910',
  Longitude: '137.305.800',
  Telephone: '352082321',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.214191,13.73058',
  id: '51.214191,13.73058'
}, {
  Brand: 'ARAL',
  Street: 'Dresdner Tor Nord / A 4',
  PostCode: 1723,
  City: 'Wilsdruff',
  Latitude: '510.611.360',
  Longitude: '135.688.270',
  Telephone: '352049420',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.061136,13.568827',
  id: '51.061136,13.568827'
}, {
  Brand: 'ARAL',
  Street: 'Dresdner Tor Süd / A 4',
  PostCode: 1723,
  City: 'Wilsdruff',
  Latitude: '510.625.050',
  Longitude: '135.750.560',
  Telephone: '352049430',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.062505,13.575056',
  id: '51.062505,13.575056'
}, {
  Brand: 'ARAL',
  Street: 'Alte Altenberger Str. 40A',
  PostCode: 1744,
  City: 'Dippoldiswalde',
  Latitude: '508.868.760',
  Longitude: '136.709.730',
  Telephone: '3504612652',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.886876,13.670973',
  id: '50.886876,13.670973'
}, {
  Brand: 'ARAL',
  Street: 'Schopperstraße 83',
  PostCode: 7937,
  City: 'Zeulenroda',
  Latitude: '506.556.510',
  Longitude: '119.932.550',
  Telephone: '3662882710',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.655651,11.993255',
  id: '50.655651,11.993255'
}, {
  Brand: 'ARAL',
  Street: 'Demminer Straße 7',
  PostCode: 99091,
  City: 'Erfurt',
  Latitude: '510.131.670',
  Longitude: '109.875.430',
  Telephone: '3617914006',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.013167,10.987543',
  id: '51.013167,10.987543'
}, {
  Brand: 'ARAL',
  Street: 'Marschnerstraße 50',
  PostCode: 4109,
  City: 'Leipzig',
  Latitude: '513.402.810',
  Longitude: '123.543.410',
  Telephone: '3419808809',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.340281,12.354341',
  id: '51.340281,12.354341'
}, {
  Brand: 'ARAL',
  Street: 'Straße des Friedens',
  PostCode: 4668,
  City: 'Grimma',
  Latitude: '512.377.150',
  Longitude: '127.232.900',
  Telephone: '3437910258',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.237715,12.72329',
  id: '51.237715,12.72329'
}, {
  Brand: 'ARAL',
  Street: 'An der Tabaksmühle',
  PostCode: 4277,
  City: 'Leipzig',
  Latitude: '513.135.770',
  Longitude: '124.046.120',
  Telephone: '341868480',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.313577,12.404612',
  id: '51.313577,12.404612'
}, {
  Brand: 'ARAL',
  Street: 'Bautzener Straße 78',
  PostCode: 2681,
  City: 'Schirgiswalde-Kirsch',
  Latitude: '510.965.520',
  Longitude: '144.306.560',
  Telephone: '3592380130',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.096552,14.430656',
  id: '51.096552,14.430656'
}, {
  Brand: 'ARAL',
  Street: 'Elbstraße 8',
  PostCode: 1814,
  City: 'Rathmannsdorf',
  Latitude: '509.235.190',
  Longitude: '141.347.110',
  Telephone: '3502243088',
  WeekDayFrom: '04:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '04:00',
  SaturdayTo: '23:00',
  SundayFrom: '04:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.923519,14.134711',
  id: '50.923519,14.134711'
}, {
  Brand: 'ARAL',
  Street: 'Außenring 8',
  PostCode: 4860,
  City: 'Torgau',
  Latitude: '515.613.060',
  Longitude: '129.736.150',
  Telephone: '3421711855',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.561306,12.973615',
  id: '51.561306,12.973615'
}, {
  Brand: 'ARAL',
  Street: 'Leipziger Straße 31',
  PostCode: 4758,
  City: 'Oschatz',
  Latitude: '513.040.550',
  Longitude: '130.937.890',
  Telephone: '3435926583',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.304055,13.093789',
  id: '51.304055,13.093789'
}, {
  Brand: 'ARAL',
  Street: 'Ostring 250-254',
  PostCode: 24148,
  City: 'Kiel',
  Latitude: '543.135.580',
  Longitude: '101.628.860',
  Telephone: '431724260',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.313558,10.162886',
  id: '54.313558,10.162886'
}, {
  Brand: 'ARAL',
  Street: 'Hamburger Straße 35',
  PostCode: 22941,
  City: 'Bargteheide',
  Latitude: '537.242.560',
  Longitude: '102.553.790',
  Telephone: '4532501590',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.724256,10.255379',
  id: '53.724256,10.255379'
}, {
  Brand: 'ARAL',
  Street: 'Schoenningstedter Str. 1',
  PostCode: 21521,
  City: 'Aumühle',
  Latitude: '535.300.830',
  Longitude: '103.119.460',
  Telephone: '4104969968',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.530083,10.311946',
  id: '53.530083,10.311946'
}, {
  Brand: 'ARAL',
  Street: 'Flensburger Straße 1',
  PostCode: 24340,
  City: 'Eckernförde',
  Latitude: '544.650.460',
  Longitude: '98.342.870',
  Telephone: '4351470645',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.465046,9.834287',
  id: '54.465046,9.834287'
}, {
  Brand: 'ARAL',
  Street: 'Husumer Straße 30',
  PostCode: 24941,
  City: 'Flensburg',
  Latitude: '547.750.360',
  Longitude: '94.260.410',
  Telephone: '46197312',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.775036,9.426041',
  id: '54.775036,9.426041'
}, {
  Brand: 'ARAL',
  Street: 'Geesthachter Straße 24-28',
  PostCode: 21502,
  City: 'Geesthacht',
  Latitude: '534.406.000',
  Longitude: '103.656.220',
  Telephone: '41522591',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.4406,10.365622',
  id: '53.4406,10.365622'
}, {
  Brand: 'ARAL',
  Street: 'Hansastraße 44',
  PostCode: 21502,
  City: 'Geesthacht',
  Latitude: '534.404.350',
  Longitude: '103.930.300',
  Telephone: '4152847546',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '53.440435,10.39303',
  id: '53.440435,10.39303'
}, {
  Brand: 'ARAL',
  Street: 'Sieker Landstraße 235',
  PostCode: 22927,
  City: 'Großhansdorf',
  Latitude: '536.442.590',
  Longitude: '102.787.940',
  Telephone: '4102697052',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.644259,10.278794',
  id: '53.644259,10.278794'
}, {
  Brand: 'ARAL',
  Street: 'Steenbeker Weg 58',
  PostCode: 24106,
  City: 'Kiel',
  Latitude: '543.557.760',
  Longitude: '101.050.310',
  Telephone: '431331991',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '54.355776,10.105031',
  id: '54.355776,10.105031'
}, {
  Brand: 'ARAL',
  Street: 'Rautenbergstraße 37A',
  PostCode: 24306,
  City: 'Plön',
  Latitude: '541.571.660',
  Longitude: '104.409.250',
  Telephone: '4522760176',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.157166,10.440925',
  id: '54.157166,10.440925'
}, {
  Brand: 'ARAL',
  Street: 'Goehrdestraße 1 A',
  PostCode: 29549,
  City: 'Bad Bevensen',
  Latitude: '530.794.690',
  Longitude: '105.888.830',
  Telephone: '58212724',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '53.079469,10.588883',
  id: '53.079469,10.588883'
}, {
  Brand: 'ARAL',
  Street: 'Lueneburger Straße 4',
  PostCode: 21354,
  City: 'Bleckede',
  Latitude: '532.896.970',
  Longitude: '107.304.390',
  Telephone: '5852453',
  WeekDayFrom: '05:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '53.289697,10.730439',
  id: '53.289697,10.730439'
}, {
  Brand: 'ARAL',
  Street: 'Winsener Straße 38',
  PostCode: 21077,
  City: 'Hamburg',
  Latitude: '534.484.770',
  Longitude: '99.882.150',
  Telephone: '4076411998',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.448477,9.988215',
  id: '53.448477,9.988215'
}, {
  Brand: 'ARAL',
  Street: 'Hoheluftchaussee 78',
  PostCode: 20253,
  City: 'Hamburg',
  Latitude: '535.828.960',
  Longitude: '99.712.920',
  Telephone: '40483601',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.582896,9.971292',
  id: '53.582896,9.971292'
}, {
  Brand: 'ARAL',
  Street: 'Bundesstraße 38',
  PostCode: 20146,
  City: 'Hamburg',
  Latitude: '535.659.700',
  Longitude: '99.796.680',
  Telephone: '40456460',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.56597,9.979668',
  id: '53.56597,9.979668'
}, {
  Brand: 'ARAL',
  Street: 'Kieler Straße 314',
  PostCode: 22525,
  City: 'Hamburg',
  Latitude: '535.858.600',
  Longitude: '99.333.050',
  Telephone: '4054766700',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.58586,9.933305',
  id: '53.58586,9.933305'
}, {
  Brand: 'ARAL',
  Street: 'Otto-Brenner-Straße 6',
  PostCode: 21109,
  City: 'Hamburg',
  Latitude: '534.900.150',
  Longitude: '100.190.050',
  Telephone: '407542478',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.490015,10.019005',
  id: '53.490015,10.019005'
}, {
  Brand: 'ARAL',
  Street: 'Kieler Straße 560',
  PostCode: 22525,
  City: 'Hamburg',
  Latitude: '535.999.400',
  Longitude: '99.173.730',
  Telephone: '40545652',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.59994,9.917373',
  id: '53.59994,9.917373'
}, {
  Brand: 'ARAL',
  Street: 'Sülldorfer Landstraße 91',
  PostCode: 22589,
  City: 'Hamburg',
  Latitude: '535.779.610',
  Longitude: '98.095.360',
  Telephone: '40873813',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.577961,9.809536',
  id: '53.577961,9.809536'
}, {
  Brand: 'ARAL',
  Street: 'Königstraße 9',
  PostCode: 22767,
  City: 'Hamburg',
  Latitude: '535.495.910',
  Longitude: '99.527.180',
  Telephone: '40313481',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.549591,9.952718',
  id: '53.549591,9.952718'
}, {
  Brand: 'ARAL',
  Street: 'Amsinckstraße 48',
  PostCode: 20097,
  City: 'Hamburg',
  Latitude: '535.459.690',
  Longitude: '100.151.210',
  Telephone: '40230646',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.545969,10.015121',
  id: '53.545969,10.015121'
}, {
  Brand: 'ARAL',
  Street: 'Dehnhaide 49',
  PostCode: 22081,
  City: 'Hamburg',
  Latitude: '535.793.130',
  Longitude: '100.452.450',
  Telephone: '40295490',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.579313,10.045245',
  id: '53.579313,10.045245'
}, {
  Brand: 'ARAL',
  Street: 'Meiendorfer Mühlenweg 6A',
  PostCode: 22393,
  City: 'Hamburg',
  Latitude: '536.444.970',
  Longitude: '101.224.450',
  Telephone: '406018712',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.644497,10.122445',
  id: '53.644497,10.122445'
}, {
  Brand: 'ARAL',
  Street: 'Rahlstedter Straße 87',
  PostCode: 22149,
  City: 'Hamburg',
  Latitude: '535.977.350',
  Longitude: '101.529.240',
  Telephone: '4067561379',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.597735,10.152924',
  id: '53.597735,10.152924'
}, {
  Brand: 'ARAL',
  Street: 'Hummelsbuettler Hauptstraße 14',
  PostCode: 22339,
  City: 'Hamburg',
  Latitude: '536.372.090',
  Longitude: '100.347.990',
  Telephone: '4053897550',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.637209,10.034799',
  id: '53.637209,10.034799'
}, {
  Brand: 'ARAL',
  Street: 'Habichtstraße 39',
  PostCode: 22305,
  City: 'Hamburg',
  Latitude: '535.909.630',
  Longitude: '100.565.180',
  Telephone: '40615874',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Ja',
  location: '53.590963,10.056518',
  id: '53.590963,10.056518'
}, {
  Brand: 'ARAL',
  Street: 'Soltauer Straße 22',
  PostCode: 21335,
  City: 'Lüneburg',
  Latitude: '532.410.300',
  Longitude: '104.008.140',
  Telephone: '4131732511',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.24103,10.400814',
  id: '53.24103,10.400814'
}, {
  Brand: 'ARAL',
  Street: 'Universitätsallee 4',
  PostCode: 21335,
  City: 'Lüneburg',
  Latitude: '532.267.200',
  Longitude: '104.059.720',
  Telephone: '4131732501',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.22672,10.405972',
  id: '53.22672,10.405972'
}, {
  Brand: 'ARAL',
  Street: 'Eichholzerstraße 2',
  PostCode: 21436,
  City: 'Marschacht',
  Latitude: '534.167.290',
  Longitude: '103.679.600',
  Telephone: '4176246',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '18:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.416729,10.36796',
  id: '53.416729,10.36796'
}, {
  Brand: 'ARAL',
  Street: 'Bismarckstraße 355',
  PostCode: 28205,
  City: 'Bremen',
  Latitude: '530.735.990',
  Longitude: '88.515.970',
  Telephone: '421447691',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.073599,8.851597',
  id: '53.073599,8.851597'
}, {
  Brand: 'ARAL',
  Street: 'Bremer Straße 53',
  PostCode: 28816,
  City: 'Stuhr',
  Latitude: '530.178.930',
  Longitude: '87.893.420',
  Telephone: '421891063',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.017893,8.789342',
  id: '53.017893,8.789342'
}, {
  Brand: 'ARAL',
  Street: 'Norddeicher Straße 31-32',
  PostCode: 26506,
  City: 'Norden',
  Latitude: '536.000.100',
  Longitude: '71.925.540',
  Telephone: '49314256',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '53.60001,7.192554',
  id: '53.60001,7.192554'
}, {
  Brand: 'ARAL',
  Street: 'Alexanderstraße 202',
  PostCode: 26121,
  City: 'Oldenburg',
  Latitude: '531.613.070',
  Longitude: '82.077.150',
  Telephone: '441883268',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.161307,8.207715',
  id: '53.161307,8.207715'
}, {
  Brand: 'ARAL',
  Street: 'Nadorster Straße 288',
  PostCode: 26125,
  City: 'Oldenburg',
  Latitude: '531.665.280',
  Longitude: '82.272.130',
  Telephone: '44137770',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '53.166528,8.227213',
  id: '53.166528,8.227213'
}, {
  Brand: 'ARAL',
  Street: 'Herforder Straße 432',
  PostCode: 33609,
  City: 'Bielefeld',
  Latitude: '520.497.470',
  Longitude: '85.871.090',
  Telephone: '5219729855',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.049747,8.587109',
  id: '52.049747,8.587109'
}, {
  Brand: 'ARAL',
  Street: 'Jöllenbecker Straße 284',
  PostCode: 33613,
  City: 'Bielefeld',
  Latitude: '520.523.930',
  Longitude: '85.202.090',
  Telephone: '521888330',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '52.052393,8.520209',
  id: '52.052393,8.520209'
}, {
  Brand: 'ARAL',
  Street: 'Detmolder Straße 490',
  PostCode: 33605,
  City: 'Bielefeld',
  Latitude: '519.956.870',
  Longitude: '85.947.260',
  Telephone: '521203090',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.995687,8.594726',
  id: '51.995687,8.594726'
}, {
  Brand: 'ARAL',
  Street: 'Guetersloher Straße 240',
  PostCode: 33649,
  City: 'Bielefeld',
  Latitude: '519.801.690',
  Longitude: '84.775.160',
  Telephone: '521412962',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.980169,8.477516',
  id: '51.980169,8.477516'
}, {
  Brand: 'ARAL',
  Street: 'Wasserbreite 105',
  PostCode: 32257,
  City: 'Bünde',
  Latitude: '522.038.560',
  Longitude: '85.887.000',
  Telephone: '52235538',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '52.203856,8.5887',
  id: '52.203856,8.5887'
}, {
  Brand: 'ARAL',
  Street: 'Nordring 107',
  PostCode: 33330,
  City: 'Gütersloh',
  Latitude: '519.127.800',
  Longitude: '83.667.540',
  Telephone: '524114110',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.91278,8.366754',
  id: '51.91278,8.366754'
}, {
  Brand: 'ARAL',
  Street: 'Gildehauser Straße 154',
  PostCode: 48599,
  City: 'Gronau',
  Latitude: '522.214.130',
  Longitude: '70.421.370',
  Telephone: '25629919331',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.221413,7.042137',
  id: '52.221413,7.042137'
}, {
  Brand: 'ARAL',
  Street: 'Bielefelder Straße 23',
  PostCode: 33813,
  City: 'Oerlinghausen',
  Latitude: '519.689.230',
  Longitude: '86.917.720',
  Telephone: '52024079',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.968923,8.691772',
  id: '51.968923,8.691772'
}, {
  Brand: 'ARAL',
  Street: 'Salzufler Straße 111',
  PostCode: 32052,
  City: 'Herford',
  Latitude: '521.073.780',
  Longitude: '86.903.860',
  Telephone: '5221981593',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.107378,8.690386',
  id: '52.107378,8.690386'
}, {
  Brand: 'ARAL',
  Street: 'Hitzepohl-West 1',
  PostCode: 32457,
  City: 'Porta- Westfalica',
  Latitude: '522.192.820',
  Longitude: '88.847.900',
  Telephone: '57171208',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.219282,8.88479',
  id: '52.219282,8.88479'
}, {
  Brand: 'ARAL',
  Street: 'Coesfelder Straße 10',
  PostCode: 48653,
  City: 'Coesfeld',
  Latitude: '519.037.020',
  Longitude: '71.872.800',
  Telephone: '2546340',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '10:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.903702,7.18728',
  id: '51.903702,7.18728'
}, {
  Brand: 'ARAL',
  Street: 'Osnabrücker Straße 82',
  PostCode: 32312,
  City: 'Lübbecke',
  Latitude: '523.020.680',
  Longitude: '85.965.800',
  Telephone: '57418470',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.302068,8.59658',
  id: '52.302068,8.59658'
}, {
  Brand: 'ARAL',
  Street: 'Wolbecker Straße 140 A',
  PostCode: 48155,
  City: 'Münster',
  Latitude: '519.546.670',
  Longitude: '76.521.060',
  Telephone: '25165364',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.954667,7.652106',
  id: '51.954667,7.652106'
}, {
  Brand: 'ARAL',
  Street: 'Hammer Straße 311-313',
  PostCode: 48153,
  City: 'Münster',
  Latitude: '519.317.360',
  Longitude: '76.268.740',
  Telephone: '25178109',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '51.931736,7.626874',
  id: '51.931736,7.626874'
}, {
  Brand: 'ARAL',
  Street: 'Lemfoerder Straße 4',
  PostCode: 32369,
  City: 'Rahden',
  Latitude: '524.328.530',
  Longitude: '86.098.950',
  Telephone: '5771968092',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '52.432853,8.609895',
  id: '52.432853,8.609895'
}, {
  Brand: 'ARAL',
  Street: 'Sassenberger Straße 71',
  PostCode: 48231,
  City: 'Warendorf',
  Latitude: '519.569.710',
  Longitude: '80.069.050',
  Telephone: '25818091',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.956971,8.006905',
  id: '51.956971,8.006905'
}, {
  Brand: 'ARAL',
  Street: 'Osnabruecker Straße 304',
  PostCode: 49152,
  City: 'Bad Essen',
  Latitude: '523.323.650',
  Longitude: '83.144.820',
  Telephone: '54722150',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:30',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.332365,8.314482',
  id: '52.332365,8.314482'
}, {
  Brand: 'ARAL',
  Street: 'August-Kuempers-Straße 6',
  PostCode: 48493,
  City: 'Wettringen',
  Latitude: '522.109.660',
  Longitude: '73.254.450',
  Telephone: '2557927752',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '52.210966,7.325445',
  id: '52.210966,7.325445'
}, {
  Brand: 'ARAL',
  Street: 'Harburger Straße 74',
  PostCode: 29223,
  City: 'Celle',
  Latitude: '526.341.770',
  Longitude: '100.809.980',
  Telephone: '5141930909',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.634177,10.080998',
  id: '52.634177,10.080998'
}, {
  Brand: 'ARAL',
  Street: 'Goslarsche Straße 64',
  PostCode: 38678,
  City: 'Clausthal-Zellerfeld',
  Latitude: '518.211.260',
  Longitude: '103.420.640',
  Telephone: '5323982818',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.821126,10.342064',
  id: '51.821126,10.342064'
}, {
  Brand: 'ARAL',
  Street: 'Hinterm Hagen 21',
  PostCode: 38442,
  City: 'Wolfsburg',
  Latitude: '524.191.170',
  Longitude: '107.212.990',
  Telephone: '536262204',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.419117,10.721299',
  id: '52.419117,10.721299'
}, {
  Brand: 'ARAL',
  Street: 'Robert-Koch-Straße 1',
  PostCode: 38642,
  City: 'Goslar',
  Latitude: '519.220.030',
  Longitude: '104.380.660',
  Telephone: '532181468',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.922003,10.438066',
  id: '51.922003,10.438066'
}, {
  Brand: 'ARAL',
  Street: 'Marienburger Straße 85',
  PostCode: 31141,
  City: 'Hildesheim',
  Latitude: '521.365.670',
  Longitude: '99.703.870',
  Telephone: '512184438',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '52.136567,9.970387',
  id: '52.136567,9.970387'
}, {
  Brand: 'ARAL',
  Street: 'Ferdinand-Wallbrecht-Stra',
  PostCode: 30163,
  City: 'Hannover',
  Latitude: '523.982.490',
  Longitude: '97.413.570',
  Telephone: '5113946245',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.398249,9.741357',
  id: '52.398249,9.741357'
}, {
  Brand: 'ARAL',
  Street: 'Münsterstraße 208',
  PostCode: 46397,
  City: 'Bocholt',
  Latitude: '518.372.690',
  Longitude: '66.427.150',
  Telephone: '2871227206',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '51.837269,6.642715',
  id: '51.837269,6.642715'
}, {
  Brand: 'ARAL',
  Street: 'Duisburger Straße 121',
  PostCode: 47166,
  City: 'Duisburg',
  Latitude: '514.893.250',
  Longitude: '67.841.940',
  Telephone: '2039948050',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.489325,6.784194',
  id: '51.489325,6.784194'
}, {
  Brand: 'ARAL',
  Street: 'Weseler Straße 261',
  PostCode: 47169,
  City: 'Duisburg',
  Latitude: '515.139.270',
  Longitude: '67.418.630',
  Telephone: '203990888',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.513927,6.741863',
  id: '51.513927,6.741863'
}, {
  Brand: 'ARAL',
  Street: 'Alfredstraße 156-160',
  PostCode: 45131,
  City: 'Essen',
  Latitude: '514.285.740',
  Longitude: '70.003.170',
  Telephone: '201411545',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.428574,7.000317',
  id: '51.428574,7.000317'
}, {
  Brand: 'ARAL',
  Street: 'Vom-Stein-Straße 40',
  PostCode: 45894,
  City: 'Gelsenkirchen',
  Latitude: '515.743.770',
  Longitude: '70.601.920',
  Telephone: '20932900',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.574377,7.060192',
  id: '51.574377,7.060192'
}, {
  Brand: 'ARAL',
  Street: 'Dorstener Straße 666',
  PostCode: 45721,
  City: 'Haltern am See',
  Latitude: '517.140.050',
  Longitude: '70.943.330',
  Telephone: '2360901910',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.714005,7.094333',
  id: '51.714005,7.094333'
}, {
  Brand: 'ARAL',
  Street: 'Aktienstraße 226',
  PostCode: 45473,
  City: 'Mülheim',
  Latitude: '514.444.630',
  Longitude: '69.054.060',
  Telephone: '2089901013',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.444463,6.905406',
  id: '51.444463,6.905406'
}, {
  Brand: 'ARAL',
  Street: 'Bottroper Straße 211',
  PostCode: 46117,
  City: 'Oberhausen',
  Latitude: '515.027.260',
  Longitude: '68.921.610',
  Telephone: '208890648',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '51.502726,6.892161',
  id: '51.502726,6.892161'
}, {
  Brand: 'ARAL',
  Street: 'Vestische Straße 157',
  PostCode: 46117,
  City: 'Oberhausen',
  Latitude: '515.094.980',
  Longitude: '68.652.550',
  Telephone: '208632609',
  WeekDayFrom: '07:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.509498,6.865255',
  id: '51.509498,6.865255'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 139',
  PostCode: 46562,
  City: 'Voerde',
  Latitude: '516.007.300',
  Longitude: '66.970.790',
  Telephone: '285593022',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.60073,6.697079',
  id: '51.60073,6.697079'
}, {
  Brand: 'ARAL',
  Street: 'Bochumer Straße 6',
  PostCode: 44866,
  City: 'Bochum',
  Latitude: '514.794.430',
  Longitude: '71.498.800',
  Telephone: '232788617',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '51.479443,7.14988',
  id: '51.479443,7.14988'
}, {
  Brand: 'ARAL',
  Street: 'Sprockhoeveler Straße 1',
  PostCode: 58455,
  City: 'Witten',
  Latitude: '514.504.270',
  Longitude: '73.256.000',
  Telephone: '230255410',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.450427,7.3256',
  id: '51.450427,7.3256'
}, {
  Brand: 'ARAL',
  Street: 'Reeser Landstraße 20',
  PostCode: 46483,
  City: 'Wesel',
  Latitude: '516.642.520',
  Longitude: '66.098.660',
  Telephone: '281331402',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.664252,6.609866',
  id: '51.664252,6.609866'
}, {
  Brand: 'ARAL',
  Street: 'Duelmener Straße 8',
  PostCode: 46286,
  City: 'Dorsten',
  Latitude: '517.216.430',
  Longitude: '70.127.920',
  Telephone: '23694240',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.721643,7.012792',
  id: '51.721643,7.012792'
}, {
  Brand: 'ARAL',
  Street: 'Steinfurter Straße 72',
  PostCode: 59387,
  City: 'Ascheberg',
  Latitude: '518.004.170',
  Longitude: '76.495.960',
  Telephone: '25935536',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '19:00',
  SundayFrom: '10:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '51.800417,7.649596',
  id: '51.800417,7.649596'
}, {
  Brand: 'ARAL',
  Street: 'Werler Straße 56',
  PostCode: 59469,
  City: 'Ense',
  Latitude: '515.038.100',
  Longitude: '79.694.850',
  Telephone: '293849000',
  WeekDayFrom: '06:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '18:00',
  SundayFrom: '10:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '51.50381,7.969485',
  id: '51.50381,7.969485'
}, {
  Brand: 'ARAL',
  Street: 'Oststraße 28',
  PostCode: 33129,
  City: 'Delbrück',
  Latitude: '517.634.040',
  Longitude: '85.686.530',
  Telephone: '5250933286',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:30',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.763404,8.568653',
  id: '51.763404,8.568653'
}, {
  Brand: 'ARAL',
  Street: 'Stockumer Straße 168',
  PostCode: 44225,
  City: 'Dortmund',
  Latitude: '514.816.260',
  Longitude: '74.389.670',
  Telephone: '231711234',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.481626,7.438967',
  id: '51.481626,7.438967'
}, {
  Brand: 'ARAL',
  Street: 'Heiliger Weg / Löwenstr.',
  PostCode: 44135,
  City: 'Dortmund',
  Latitude: '515.094.970',
  Longitude: '74.739.470',
  Telephone: '231524830',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.509497,7.473947',
  id: '51.509497,7.473947'
}, {
  Brand: 'ARAL',
  Street: 'Mengeder Straße 432',
  PostCode: 44359,
  City: 'Dortmund',
  Latitude: '515.594.250',
  Longitude: '73.971.220',
  Telephone: '231350380',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.559425,7.397122',
  id: '51.559425,7.397122'
}, {
  Brand: 'ARAL',
  Street: 'Erwitterstraße 13',
  PostCode: 59590,
  City: 'Geseke',
  Latitude: '516.357.040',
  Longitude: '85.055.030',
  Telephone: '29421371',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.635704,8.505503',
  id: '51.635704,8.505503'
}, {
  Brand: 'ARAL',
  Street: 'Herdecker Straße 14',
  PostCode: 58089,
  City: 'Hagen',
  Latitude: '513.858.320',
  Longitude: '74.473.280',
  Telephone: '2331932809',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.385832,7.447328',
  id: '51.385832,7.447328'
}, {
  Brand: 'ARAL',
  Street: 'Neuenrader Straße 2',
  PostCode: 58802,
  City: 'Balve',
  Latitude: '513.146.590',
  Longitude: '78.750.740',
  Telephone: '2375910443',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.314659,7.875074',
  id: '51.314659,7.875074'
}, {
  Brand: 'ARAL',
  Street: 'Dortmunder Straße 51',
  PostCode: 44536,
  City: 'Lünen',
  Latitude: '516.099.420',
  Longitude: '75.084.810',
  Telephone: '230612366',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.609942,7.508481',
  id: '51.609942,7.508481'
}, {
  Brand: 'ARAL',
  Street: 'Briloner Straße 44',
  PostCode: 59872,
  City: 'Meschede',
  Latitude: '513.468.720',
  Longitude: '82.966.520',
  Telephone: '29151412',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.346872,8.296652',
  id: '51.346872,8.296652'
}, {
  Brand: 'ARAL',
  Street: 'Marienloher Straße 87',
  PostCode: 33104,
  City: 'Paderborn',
  Latitude: '517.457.070',
  Longitude: '87.416.180',
  Telephone: '52542430',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:30',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:30',
  SundayFrom: '07:00',
  SundayTo: '22:30',
  IsOpen24Hours: 'Nein',
  location: '51.745707,8.741618',
  id: '51.745707,8.741618'
}, {
  Brand: 'ARAL',
  Street: 'Kleiner Hellweg 2',
  PostCode: 33154,
  City: 'Salzkotten',
  Latitude: '516.412.870',
  Longitude: '86.648.170',
  Telephone: '5258940605',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.641287,8.664817',
  id: '51.641287,8.664817'
}, {
  Brand: 'ARAL',
  Street: 'Warburger Straße 120',
  PostCode: 33100,
  City: 'Paderborn',
  Latitude: '517.062.640',
  Longitude: '87.771.210',
  Telephone: '525162703',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.706264,8.777121',
  id: '51.706264,8.777121'
}, {
  Brand: 'ARAL',
  Street: 'Hellweg 34 A',
  PostCode: 59192,
  City: 'Bergkamen',
  Latitude: '516.513.350',
  Longitude: '76.627.840',
  Telephone: '23899260320',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.651335,7.662784',
  id: '51.651335,7.662784'
}, {
  Brand: 'ARAL',
  Street: 'Hansastraße 145',
  PostCode: 59427,
  City: 'Unna',
  Latitude: '515.402.800',
  Longitude: '76.557.110',
  Telephone: '2303591025',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.54028,7.655711',
  id: '51.54028,7.655711'
}, {
  Brand: 'ARAL',
  Street: 'Engelbertstraße',
  PostCode: 41462,
  City: 'Neuss',
  Latitude: '512.071.040',
  Longitude: '66.711.110',
  Telephone: '2131561102',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.207104,6.671111',
  id: '51.207104,6.671111'
}, {
  Brand: 'ARAL',
  Street: 'Freiligrathplatz 32',
  PostCode: 40474,
  City: 'Düsseldorf',
  Latitude: '512.650.860',
  Longitude: '67.534.240',
  Telephone: '2114350080',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.265086,6.753424',
  id: '51.265086,6.753424'
}, {
  Brand: 'ARAL',
  Street: 'Brehmstraße 90',
  PostCode: 40239,
  City: 'Düsseldorf',
  Latitude: '512.479.190',
  Longitude: '68.015.480',
  Telephone: '211627301',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.247919,6.801548',
  id: '51.247919,6.801548'
}, {
  Brand: 'ARAL',
  Street: 'Pariser Straße 82',
  PostCode: 40549,
  City: 'Düsseldorf',
  Latitude: '512.333.340',
  Longitude: '67.278.580',
  Telephone: '211501372',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.233334,6.727858',
  id: '51.233334,6.727858'
}, {
  Brand: 'ARAL',
  Street: 'Gerresheimer Straße 122',
  PostCode: 40721,
  City: 'Hilden',
  Latitude: '511.765.660',
  Longitude: '69.275.300',
  Telephone: '2103390049',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.176566,6.92753',
  id: '51.176566,6.92753'
}, {
  Brand: 'ARAL',
  Street: 'Haaner Straße 77',
  PostCode: 40699,
  City: 'Erkrath',
  Latitude: '512.044.070',
  Longitude: '69.702.300',
  Telephone: '2104948379',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.204407,6.97023',
  id: '51.204407,6.97023'
}, {
  Brand: 'ARAL',
  Street: 'Gladbacher Straße 313-331',
  PostCode: 47805,
  City: 'Krefeld',
  Latitude: '513.144.660',
  Longitude: '65.577.820',
  Telephone: '2151399027',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.314466,6.557782',
  id: '51.314466,6.557782'
}, {
  Brand: 'ARAL',
  Street: 'Am Krummenweg 9',
  PostCode: 40885,
  City: 'Ratingen',
  Latitude: '513.384.510',
  Longitude: '68.690.880',
  Telephone: '210218051',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.338451,6.869088',
  id: '51.338451,6.869088'
}, {
  Brand: 'ARAL',
  Street: 'Bergheimer Straße 252',
  PostCode: 41464,
  City: 'Neuss',
  Latitude: '511.816.400',
  Longitude: '66.882.580',
  Telephone: '213141655',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.18164,6.688258',
  id: '51.18164,6.688258'
}, {
  Brand: 'ARAL',
  Street: 'Nievenheimer Straße 6',
  PostCode: 41469,
  City: 'Neuss',
  Latitude: '511.577.180',
  Longitude: '67.311.790',
  Telephone: '21372257',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.157718,6.731179',
  id: '51.157718,6.731179'
}, {
  Brand: 'ARAL',
  Street: 'Heidestraße 116-120',
  PostCode: 42549,
  City: 'Velbert',
  Latitude: '513.318.620',
  Longitude: '70.385.490',
  Telephone: '2051255786',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.331862,7.038549',
  id: '51.331862,7.038549'
}, {
  Brand: 'ARAL',
  Street: 'Krefelder Straße 136',
  PostCode: 47877,
  City: 'Willich',
  Latitude: '512.733.480',
  Longitude: '65.550.310',
  Telephone: '21543045',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.273348,6.555031',
  id: '51.273348,6.555031'
}, {
  Brand: 'ARAL',
  Street: 'Langerfelder Straße 121',
  PostCode: 42389,
  City: 'Wuppertal',
  Latitude: '512.741.350',
  Longitude: '72.389.010',
  Telephone: '2022602549',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.274135,7.238901',
  id: '51.274135,7.238901'
}, {
  Brand: 'ARAL',
  Street: 'Uellendahler Straße 264',
  PostCode: 42109,
  City: 'Wuppertal',
  Latitude: '512.760.990',
  Longitude: '71.554.940',
  Telephone: '202750411',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.276099,7.155494',
  id: '51.276099,7.155494'
}, {
  Brand: 'ARAL',
  Street: 'Nevigeser Straße 31',
  PostCode: 42113,
  City: 'Wuppertal',
  Latitude: '512.687.260',
  Longitude: '71.268.510',
  Telephone: '2022761355',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.268726,7.126851',
  id: '51.268726,7.126851'
}, {
  Brand: 'ARAL',
  Street: 'Carnaper Straße 70',
  PostCode: 42283,
  City: 'Wuppertal',
  Latitude: '512.763.120',
  Longitude: '71.898.700',
  Telephone: '202507341',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.276312,7.18987',
  id: '51.276312,7.18987'
}, {
  Brand: 'ARAL',
  Street: 'Roermonder Straße 33',
  PostCode: 52072,
  City: 'Aachen',
  Latitude: '507.839.040',
  Longitude: '60.745.910',
  Telephone: '241872088',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.783904,6.074591',
  id: '50.783904,6.074591'
}, {
  Brand: 'ARAL',
  Street: 'Joseph-von-Görres Str. 53',
  PostCode: 52068,
  City: 'Aachen',
  Latitude: '507.791.930',
  Longitude: '61.097.240',
  Telephone: '241502672',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.779193,6.109724',
  id: '50.779193,6.109724'
}, {
  Brand: 'ARAL',
  Street: 'An der Bundesstraße 258',
  PostCode: 53534,
  City: 'Barweiler',
  Latitude: '503.574.590',
  Longitude: '68.571.730',
  Telephone: '2691930070',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.357459,6.857173',
  id: '50.357459,6.857173'
}, {
  Brand: 'ARAL',
  Street: 'Am Markt 2',
  PostCode: 54634,
  City: 'Bitburg',
  Latitude: '499.730.650',
  Longitude: '65.214.330',
  Telephone: '65619464660',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.973065,6.521433',
  id: '49.973065,6.521433'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 26',
  PostCode: 56823,
  City: 'Büchel',
  Latitude: '501.739.230',
  Longitude: '70.823.130',
  Telephone: '2678801',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '05:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.173923,7.082313',
  id: '50.173923,7.082313'
}, {
  Brand: 'ARAL',
  Street: 'Bonner Straße 16',
  PostCode: 54550,
  City: 'Daun',
  Latitude: '502.032.250',
  Longitude: '68.267.130',
  Telephone: '6592985318',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.203225,6.826713',
  id: '50.203225,6.826713'
}, {
  Brand: 'ARAL',
  Street: 'Rochusstraße 46',
  PostCode: 53123,
  City: 'Bonn',
  Latitude: '507.191.920',
  Longitude: '70.567.800',
  Telephone: '228624902',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.719192,7.05678',
  id: '50.719192,7.05678'
}, {
  Brand: 'ARAL',
  Street: 'Kölner Straße 177-181',
  PostCode: 53879,
  City: 'Euskirchen',
  Latitude: '506.622.700',
  Longitude: '68.058.350',
  Telephone: '22512190',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.66227,6.805835',
  id: '50.66227,6.805835'
}, {
  Brand: 'ARAL',
  Street: 'Gerolsteiner Straße 47',
  PostCode: 54570,
  City: 'Pelm',
  Latitude: '502.245.960',
  Longitude: '66.787.830',
  Telephone: '65913700',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.224596,6.678783',
  id: '50.224596,6.678783'
}, {
  Brand: 'ARAL',
  Street: 'Godesberger Allee 131',
  PostCode: 53175,
  City: 'Bonn',
  Latitude: '507.014.070',
  Longitude: '71.365.860',
  Telephone: '228376016',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.701407,7.136586',
  id: '50.701407,7.136586'
}, {
  Brand: 'ARAL',
  Street: 'Hengebachstr. 1',
  PostCode: 52396,
  City: 'Heimbach',
  Latitude: '506.353.870',
  Longitude: '64.741.890',
  Telephone: '2446444',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.635387,6.474189',
  id: '50.635387,6.474189'
}, {
  Brand: 'ARAL',
  Street: 'Wittlicher Straße 1',
  PostCode: 54523,
  City: 'Hetzerath',
  Latitude: '498.789.670',
  Longitude: '68.147.090',
  Telephone: '6508918825',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.878967,6.814709',
  id: '49.878967,6.814709'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 144',
  PostCode: 52156,
  City: 'Monschau',
  Latitude: '505.297.190',
  Longitude: '62.626.840',
  Telephone: '24723401',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.529719,6.262684',
  id: '50.529719,6.262684'
}, {
  Brand: 'ARAL',
  Street: 'Erftstraße 127',
  PostCode: 50170,
  City: 'Kerpen-Sindorf',
  Latitude: '509.116.720',
  Longitude: '66.834.050',
  Telephone: '2273570436',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.911672,6.683405',
  id: '50.911672,6.683405'
}, {
  Brand: 'ARAL',
  Street: 'Moselstraße 6',
  PostCode: 56253,
  City: 'Treis-Karden',
  Latitude: '501.854.710',
  Longitude: '73.052.530',
  Telephone: '26722682',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.185471,7.305253',
  id: '50.185471,7.305253'
}, {
  Brand: 'ARAL',
  Street: 'Berg.-Gladbacher Str. 781',
  PostCode: 51069,
  City: 'Köln',
  Latitude: '509.745.590',
  Longitude: '70.578.470',
  Telephone: '221681487',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.974559,7.057847',
  id: '50.974559,7.057847'
}, {
  Brand: 'ARAL',
  Street: 'Olpener Straße 498',
  PostCode: 51109,
  City: 'Köln',
  Latitude: '509.427.450',
  Longitude: '70.509.810',
  Telephone: '221895143',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.942745,7.050981',
  id: '50.942745,7.050981'
}, {
  Brand: 'ARAL',
  Street: 'Mülheimer Zubringer 140/S',
  PostCode: 51063,
  City: 'Köln',
  Latitude: '509.771.270',
  Longitude: '70.070.430',
  Telephone: '2219641905',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.977127,7.007043',
  id: '50.977127,7.007043'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Straße 198',
  PostCode: 51065,
  City: 'Köln',
  Latitude: '509.504.850',
  Longitude: '70.165.650',
  Telephone: '221693137',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.950485,7.016565',
  id: '50.950485,7.016565'
}, {
  Brand: 'ARAL',
  Street: 'Wingert 43',
  PostCode: 53894,
  City: 'Mechernich',
  Latitude: '506.181.080',
  Longitude: '66.518.670',
  Telephone: '2443911405',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.618108,6.651867',
  id: '50.618108,6.651867'
}, {
  Brand: 'ARAL',
  Street: 'Bonner Straße 6',
  PostCode: 53340,
  City: 'Meckenheim',
  Latitude: '506.262.340',
  Longitude: '70.203.450',
  Telephone: '22255013',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.626234,7.020345',
  id: '50.626234,7.020345'
}, {
  Brand: 'ARAL',
  Street: 'Heerstraße 51',
  PostCode: 53474,
  City: 'Bad Neuenahr-Ahr.',
  Latitude: '505.481.850',
  Longitude: '71.389.100',
  Telephone: '26411594',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.548185,7.13891',
  id: '50.548185,7.13891'
}, {
  Brand: 'ARAL',
  Street: 'Im Altwerk 27',
  PostCode: 52385,
  City: 'Nideggen',
  Latitude: '506.922.280',
  Longitude: '64.839.950',
  Telephone: '24271309',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.692228,6.483995',
  id: '50.692228,6.483995'
}, {
  Brand: 'ARAL',
  Street: 'Koblenzer Straße 36',
  PostCode: 56299,
  City: 'Ochtendung',
  Latitude: '503.505.250',
  Longitude: '73.944.500',
  Telephone: '26259592411',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.350525,7.39445',
  id: '50.350525,7.39445'
}, {
  Brand: 'ARAL',
  Street: 'Heerlener Straße 145',
  PostCode: 52531,
  City: 'Übach-Palenberg',
  Latitude: '509.283.360',
  Longitude: '60.672.470',
  Telephone: '2451909327',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.928336,6.067247',
  id: '50.928336,6.067247'
}, {
  Brand: 'ARAL',
  Street: 'Auelstraße 27-29',
  PostCode: 54589,
  City: 'Stadtkyll',
  Latitude: '503.536.110',
  Longitude: '65.241.250',
  Telephone: '6597961106',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '20:00',
  SundayFrom: '07:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.353611,6.524125',
  id: '50.353611,6.524125'
}, {
  Brand: 'ARAL',
  Street: 'Brandenburg 1',
  PostCode: 56856,
  City: 'Zell',
  Latitude: '500.170.490',
  Longitude: '71.804.650',
  Telephone: '6542960225',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.017049,7.180465',
  id: '50.017049,7.180465'
}, {
  Brand: 'ARAL',
  Street: 'Am Wassertor 17',
  PostCode: 57439,
  City: 'Attendorn',
  Latitude: '511.227.400',
  Longitude: '79.072.540',
  Telephone: '27224323',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.12274,7.907254',
  id: '51.12274,7.907254'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 170',
  PostCode: 56170,
  City: 'Bendorf',
  Latitude: '504.268.680',
  Longitude: '75.716.920',
  Telephone: '2622923413',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.426868,7.571692',
  id: '50.426868,7.571692'
}, {
  Brand: 'ARAL',
  Street: 'Kölner Straße 290',
  PostCode: 51702,
  City: 'Bergneustadt',
  Latitude: '510.216.790',
  Longitude: '76.588.990',
  Telephone: '226141042',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.021679,7.658899',
  id: '51.021679,7.658899'
}, {
  Brand: 'ARAL',
  Street: 'Burscheider Straße 154',
  PostCode: 51381,
  City: 'Leverkusen',
  Latitude: '510.793.710',
  Longitude: '70.372.600',
  Telephone: '2171734035',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.079371,7.03726',
  id: '51.079371,7.03726'
}, {
  Brand: 'ARAL',
  Street: 'Am Großen Garten 7a / B 2',
  PostCode: 56462,
  City: 'Höhn',
  Latitude: '506.191.650',
  Longitude: '79.906.040',
  Telephone: '2661949598',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.619165,7.990604',
  id: '50.619165,7.990604'
}, {
  Brand: 'ARAL',
  Street: 'Hoehestraße 51',
  PostCode: 51399,
  City: 'Burscheid',
  Latitude: '510.842.050',
  Longitude: '71.275.360',
  Telephone: '2174789535',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.084205,7.127536',
  id: '51.084205,7.127536'
}, {
  Brand: 'ARAL',
  Street: 'Hagener Straße 67',
  PostCode: 57489,
  City: 'Drolshagen',
  Latitude: '510.278.810',
  Longitude: '77.724.200',
  Telephone: '2761979408',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.027881,7.77242',
  id: '51.027881,7.77242'
}, {
  Brand: 'ARAL',
  Street: 'Koblenzer Straße 3',
  PostCode: 57629,
  City: 'Höchstenbach',
  Latitude: '506.337.370',
  Longitude: '77.460.700',
  Telephone: '2680442',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.633737,7.74607',
  id: '50.633737,7.74607'
}, {
  Brand: 'ARAL',
  Street: 'Römerstraße 221',
  PostCode: 56075,
  City: 'Koblenz',
  Latitude: '503.342.870',
  Longitude: '75.829.170',
  Telephone: '26132520',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.334287,7.582917',
  id: '50.334287,7.582917'
}, {
  Brand: 'ARAL',
  Street: 'Willy-Brandt-Ring 20',
  PostCode: 51373,
  City: 'Leverkusen',
  Latitude: '510.229.900',
  Longitude: '70.036.460',
  Telephone: '214830450',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.02299,7.003646',
  id: '51.02299,7.003646'
}, {
  Brand: 'ARAL',
  Street: 'Solinger Straße 67',
  PostCode: 51371,
  City: 'Leverkusen',
  Latitude: '510.560.730',
  Longitude: '69.538.970',
  Telephone: '21421349',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.056073,6.953897',
  id: '51.056073,6.953897'
}, {
  Brand: 'ARAL',
  Street: 'Neukirchener Straße 10',
  PostCode: 42799,
  City: 'Leichlingen',
  Latitude: '511.044.530',
  Longitude: '70.149.470',
  Telephone: '2175898083',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '07:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.104453,7.014947',
  id: '51.104453,7.014947'
}, {
  Brand: 'ARAL',
  Street: 'Koelner Straße 23',
  PostCode: 51789,
  City: 'Lindlar',
  Latitude: '510.176.270',
  Longitude: '73.721.770',
  Telephone: '22666075',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.017627,7.372177',
  id: '51.017627,7.372177'
}, {
  Brand: 'ARAL',
  Street: 'Rheinstraße 35',
  PostCode: 56424,
  City: 'Mogendorf',
  Latitude: '504.889.430',
  Longitude: '77.560.600',
  Telephone: '26235662',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.488943,7.75606',
  id: '50.488943,7.75606'
}, {
  Brand: 'ARAL',
  Street: 'Gartzenweg 58',
  PostCode: 40789,
  City: 'Monheim',
  Latitude: '510.916.760',
  Longitude: '68.973.960',
  Telephone: '217356997',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.091676,6.897396',
  id: '51.091676,6.897396'
}, {
  Brand: 'ARAL',
  Street: 'Waldbrölerstraße 61',
  PostCode: 51597,
  City: 'Morsbach',
  Latitude: '508.684.760',
  Longitude: '77.260.460',
  Telephone: '22948197',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.868476,7.726046',
  id: '50.868476,7.726046'
}, {
  Brand: 'ARAL',
  Street: 'Marienfelder Straße 2',
  PostCode: 53804,
  City: 'Much',
  Latitude: '509.051.900',
  Longitude: '74.115.840',
  Telephone: '22455580',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.90519,7.411584',
  id: '50.90519,7.411584'
}, {
  Brand: 'ARAL',
  Street: 'Broelstraße 29',
  PostCode: 53809,
  City: 'Ruppichteroth',
  Latitude: '508.477.410',
  Longitude: '75.006.410',
  Telephone: '2295920120',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.847741,7.500641',
  id: '50.847741,7.500641'
}, {
  Brand: 'ARAL',
  Street: 'Steinbuecheler Straße 48',
  PostCode: 51377,
  City: 'Leverkusen',
  Latitude: '510.479.450',
  Longitude: '70.604.230',
  Telephone: '2148505923',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.047945,7.060423',
  id: '51.047945,7.060423'
}, {
  Brand: 'ARAL',
  Street: 'Barmer Straße 70',
  PostCode: 42899,
  City: 'Remscheid',
  Latitude: '512.194.490',
  Longitude: '72.411.420',
  Telephone: '2191953342',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.219449,7.241142',
  id: '51.219449,7.241142'
}, {
  Brand: 'ARAL',
  Street: 'Bonner Straße 32-34',
  PostCode: 53721,
  City: 'Siegburg',
  Latitude: '507.913.510',
  Longitude: '72.046.060',
  Telephone: '224162409',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.791351,7.204606',
  id: '50.791351,7.204606'
}, {
  Brand: 'ARAL',
  Street: 'Schlagbaumer Straße 66',
  PostCode: 42653,
  City: 'Solingen',
  Latitude: '511.850.830',
  Longitude: '70.797.380',
  Telephone: '2122541192',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.185083,7.079738',
  id: '51.185083,7.079738'
}, {
  Brand: 'ARAL',
  Street: 'Hohe Straße 1',
  PostCode: 56244,
  City: 'Steinen',
  Latitude: '505.747.750',
  Longitude: '78.097.390',
  Telephone: '2666632',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.574775,7.809739',
  id: '50.574775,7.809739'
}, {
  Brand: 'ARAL',
  Street: 'Willmenroder Straße 32',
  PostCode: 56457,
  City: 'Westerburg',
  Latitude: '505.566.850',
  Longitude: '79.730.470',
  Telephone: '26634844',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.556685,7.973047',
  id: '50.556685,7.973047'
}, {
  Brand: 'ARAL',
  Street: 'Siegener Straße 19',
  PostCode: 51580,
  City: 'Reichshof',
  Latitude: '509.408.170',
  Longitude: '77.440.510',
  Telephone: '2297359',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:30',
  SaturdayTo: '22:00',
  SundayFrom: '07:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.940817,7.744051',
  id: '50.940817,7.744051'
}, {
  Brand: 'ARAL',
  Street: 'Leiersmühle 5',
  PostCode: 51688,
  City: 'Wipperfürth',
  Latitude: '511.218.470',
  Longitude: '74.103.050',
  Telephone: '22671847',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.121847,7.410305',
  id: '51.121847,7.410305'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 89',
  PostCode: 42799,
  City: 'Leichlingen',
  Latitude: '511.181.330',
  Longitude: '71.250.800',
  Telephone: '217438058',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.118133,7.12508',
  id: '51.118133,7.12508'
}, {
  Brand: 'ARAL',
  Street: 'Kasseler Straße 28',
  PostCode: 34479,
  City: 'Breuna',
  Latitude: '514.173.840',
  Longitude: '91.904.760',
  Telephone: '5693995195',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.417384,9.190476',
  id: '51.417384,9.190476'
}, {
  Brand: 'ARAL',
  Street: 'Leipziger Straße 126',
  PostCode: 37235,
  City: 'Hessisch Lichtenau',
  Latitude: '511.991.490',
  Longitude: '97.268.510',
  Telephone: '56022462',
  WeekDayFrom: '07:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.199149,9.726851',
  id: '51.199149,9.726851'
}, {
  Brand: 'ARAL',
  Street: 'Fuldatalstraße 1',
  PostCode: 34125,
  City: 'Kassel',
  Latitude: '513.235.190',
  Longitude: '95.134.590',
  Telephone: '561873851',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.323519,9.513459',
  id: '51.323519,9.513459'
}, {
  Brand: 'ARAL',
  Street: 'Northeimer Straße 31',
  PostCode: 37186,
  City: 'Moringen',
  Latitude: '517.031.140',
  Longitude: '98.750.960',
  Telephone: '5554400',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '20:30',
  IsOpen24Hours: 'Nein',
  location: '51.703114,9.875096',
  id: '51.703114,9.875096'
}, {
  Brand: 'ARAL',
  Street: 'Lindenplatz 1',
  PostCode: 36119,
  City: 'Neuhof',
  Latitude: '504.547.470',
  Longitude: '96.141.620',
  Telephone: '66552241',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.454747,9.614162',
  id: '50.454747,9.614162'
}, {
  Brand: 'ARAL',
  Street: 'Niederrheinische Str. 27',
  PostCode: 34626,
  City: 'Neukirchen',
  Latitude: '508.719.940',
  Longitude: '93.371.720',
  Telephone: '66947878',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.871994,9.337172',
  id: '50.871994,9.337172'
}, {
  Brand: 'ARAL',
  Street: 'Marburger Straße 42',
  PostCode: 35279,
  City: 'Neustadt',
  Latitude: '508.511.630',
  Longitude: '91.070.860',
  Telephone: '6692919662',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.851163,9.107086',
  id: '50.851163,9.107086'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Straße 2',
  PostCode: 34286,
  City: 'Spangenberg',
  Latitude: '511.164.390',
  Longitude: '96.573.530',
  Telephone: '56636310',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.116439,9.657353',
  id: '51.116439,9.657353'
}, {
  Brand: 'ARAL',
  Street: 'Landgrafenstraße 5',
  PostCode: 34590,
  City: 'Wabern',
  Latitude: '511.021.650',
  Longitude: '93.496.910',
  Telephone: '5683930315',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.102165,9.349691',
  id: '51.102165,9.349691'
}, {
  Brand: 'ARAL',
  Street: 'Neckarstraße 19',
  PostCode: 64283,
  City: 'Darmstadt',
  Latitude: '498.685.300',
  Longitude: '86.455.110',
  Telephone: '6151315733',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.86853,8.645511',
  id: '49.86853,8.645511'
}, {
  Brand: 'ARAL',
  Street: 'Reuterallee 51',
  PostCode: 64297,
  City: 'Darmstadt',
  Latitude: '498.217.030',
  Longitude: '86.415.930',
  Telephone: '615152527',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.821703,8.641593',
  id: '49.821703,8.641593'
}, {
  Brand: 'ARAL',
  Street: 'Heinrichstraße 241',
  PostCode: 64287,
  City: 'Darmstadt',
  Latitude: '498.677.490',
  Longitude: '86.819.540',
  Telephone: '615147843',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.867749,8.681954',
  id: '49.867749,8.681954'
}, {
  Brand: 'ARAL',
  Street: 'Hanauer Landstraße 34-40',
  PostCode: 60314,
  City: 'Frankfurt',
  Latitude: '501.129.220',
  Longitude: '86.997.320',
  Telephone: '69491523',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.112922,8.699732',
  id: '50.112922,8.699732'
}, {
  Brand: 'ARAL',
  Street: 'Oberer Kalbacher Weg 35',
  PostCode: 60437,
  City: 'Frankfurt',
  Latitude: '501.835.020',
  Longitude: '86.598.460',
  Telephone: '6995059127',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.183502,8.659846',
  id: '50.183502,8.659846'
}, {
  Brand: 'ARAL',
  Street: 'Grueneburgweg 67',
  PostCode: 60323,
  City: 'Frankfurt',
  Latitude: '501.219.700',
  Longitude: '86.690.960',
  Telephone: '69723810',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:45',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:45',
  SundayFrom: '08:00',
  SundayTo: '21:45',
  IsOpen24Hours: 'Nein',
  location: '50.12197,8.669096',
  id: '50.12197,8.669096'
}, {
  Brand: 'ARAL',
  Street: 'Lauterbacher Straße 68',
  PostCode: 63688,
  City: 'Gedern',
  Latitude: '504.291.390',
  Longitude: '91.967.060',
  Telephone: '60451369',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.429139,9.196706',
  id: '50.429139,9.196706'
}, {
  Brand: 'ARAL',
  Street: 'Waldgirmeser Straße 6',
  PostCode: 35633,
  City: 'Lahnau',
  Latitude: '505.781.940',
  Longitude: '85.643.240',
  Telephone: '644196470',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.578194,8.564324',
  id: '50.578194,8.564324'
}, {
  Brand: 'ARAL',
  Street: 'Lichtenberger Straße 66',
  PostCode: 64401,
  City: 'Groß-Bieberau',
  Latitude: '497.917.300',
  Longitude: '88.213.020',
  Telephone: '6162931427',
  WeekDayFrom: '05:45',
  WeekDayTo: '20:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:30',
  SundayFrom: '08:00',
  SundayTo: '20:30',
  IsOpen24Hours: 'Nein',
  location: '49.79173,8.821302',
  id: '49.79173,8.821302'
}, {
  Brand: 'ARAL',
  Street: 'Darmstaedter Straße 49A',
  PostCode: 64846,
  City: 'Groß-Zimmern',
  Latitude: '498.731.310',
  Longitude: '88.208.140',
  Telephone: '6071951360',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.873131,8.820814',
  id: '49.873131,8.820814'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Straße 12',
  PostCode: 35625,
  City: 'Hüttenberg',
  Latitude: '505.218.680',
  Longitude: '85.723.810',
  Telephone: '644174273',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.521868,8.572381',
  id: '50.521868,8.572381'
}, {
  Brand: 'ARAL',
  Street: 'Saalburgstraße 114',
  PostCode: 61350,
  City: 'Bad Homburg',
  Latitude: '502.369.950',
  Longitude: '85.930.140',
  Telephone: '617237205',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.236995,8.593014',
  id: '50.236995,8.593014'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Straße 19',
  PostCode: 63225,
  City: 'Langen',
  Latitude: '499.919.240',
  Longitude: '86.805.950',
  Telephone: '610321524',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '49.991924,8.680595',
  id: '49.991924,8.680595'
}, {
  Brand: 'ARAL',
  Street: 'Limburger Straße 34',
  PostCode: 65232,
  City: 'Taunusstein',
  Latitude: '501.703.950',
  Longitude: '82.115.910',
  Telephone: '612872462',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.170395,8.211591',
  id: '50.170395,8.211591'
}, {
  Brand: 'ARAL',
  Street: 'Friedhofstraße 11',
  PostCode: 63263,
  City: 'Neu-Isenburg',
  Latitude: '500.479.760',
  Longitude: '86.973.370',
  Telephone: '610239307',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.047976,8.697337',
  id: '50.047976,8.697337'
}, {
  Brand: 'ARAL',
  Street: 'Limburger Straße 15-19',
  PostCode: 65611,
  City: 'Brechen',
  Latitude: '503.615.370',
  Longitude: '81.705.620',
  Telephone: '6438836974',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.361537,8.170562',
  id: '50.361537,8.170562'
}, {
  Brand: 'ARAL',
  Street: 'Odenwaldstraße 13',
  PostCode: 64367,
  City: 'Mühltal',
  Latitude: '498.298.680',
  Longitude: '87.060.720',
  Telephone: '615114269',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.829868,8.706072',
  id: '49.829868,8.706072'
}, {
  Brand: 'ARAL',
  Street: 'Sodener Straße 29',
  PostCode: 61476,
  City: 'Kronberg',
  Latitude: '501.799.070',
  Longitude: '85.388.940',
  Telephone: '617364545',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.179907,8.538894',
  id: '50.179907,8.538894'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Straße 81',
  PostCode: 61440,
  City: 'Oberursel',
  Latitude: '502.059.560',
  Longitude: '85.799.590',
  Telephone: '61714475',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.205956,8.579959',
  id: '50.205956,8.579959'
}, {
  Brand: 'ARAL',
  Street: 'Kurt-Schumacher-Ring 138',
  PostCode: 63303,
  City: 'Dreieich',
  Latitude: '500.345.620',
  Longitude: '86.944.910',
  Telephone: '6103373437',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.034562,8.694491',
  id: '50.034562,8.694491'
}, {
  Brand: 'ARAL',
  Street: 'Bergstraße 4',
  PostCode: 35578,
  City: 'Wetzlar',
  Latitude: '505.507.640',
  Longitude: '85.026.490',
  Telephone: '644122440',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.550764,8.502649',
  id: '50.550764,8.502649'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofsplatz',
  PostCode: 65189,
  City: 'Wiesbaden',
  Latitude: '500.714.710',
  Longitude: '82.455.630',
  Telephone: '611719188',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.071471,8.245563',
  id: '50.071471,8.245563'
}, {
  Brand: 'ARAL',
  Street: 'Weinstraße 90',
  PostCode: 67278,
  City: 'Bockenheim',
  Latitude: '496.084.690',
  Longitude: '81.816.430',
  Telephone: '63599615906',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.608469,8.181643',
  id: '49.608469,8.181643'
}, {
  Brand: 'ARAL',
  Street: 'Am Rathausplatz 22',
  PostCode: 67125,
  City: 'Dannstadt-Schauernhe',
  Latitude: '494.318.670',
  Longitude: '83.092.470',
  Telephone: '6231403752',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:59',
  SundayFrom: '07:00',
  SundayTo: '22:59',
  IsOpen24Hours: 'Nein',
  location: '49.431867,8.309247',
  id: '49.431867,8.309247'
}, {
  Brand: 'ARAL',
  Street: 'Mannheimer Straße 112',
  PostCode: 67098,
  City: 'Bad Dürkheim',
  Latitude: '494.632.290',
  Longitude: '81.866.160',
  Telephone: '632294330',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:59',
  SundayFrom: '08:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '49.463229,8.186616',
  id: '49.463229,8.186616'
}, {
  Brand: 'ARAL',
  Street: 'Karlsruher Straße 50a',
  PostCode: 76275,
  City: 'Ettlingen',
  Latitude: '489.573.740',
  Longitude: '84.066.250',
  Telephone: '724312372',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.957374,8.406625',
  id: '48.957374,8.406625'
}, {
  Brand: 'ARAL',
  Street: 'Mahlastraße 37',
  PostCode: 67227,
  City: 'Frankenthal',
  Latitude: '495.260.500',
  Longitude: '83.549.320',
  Telephone: '6233349208',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.52605,8.354932',
  id: '49.52605,8.354932'
}, {
  Brand: 'ARAL',
  Street: 'A 6',
  PostCode: 68766,
  City: 'Hockenheim',
  Latitude: '493.152.620',
  Longitude: '85.776.300',
  Telephone: '620513513',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.315262,8.57763',
  id: '49.315262,8.57763'
}, {
  Brand: 'ARAL',
  Street: 'Mannheimer Straße 300-302',
  PostCode: 69123,
  City: 'Heidelberg',
  Latitude: '494.295.850',
  Longitude: '86.454.050',
  Telephone: '6221831614',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.429585,8.645405',
  id: '49.429585,8.645405'
}, {
  Brand: 'ARAL',
  Street: 'Haid-und-Neu-Straße 60',
  PostCode: 76131,
  City: 'Karlsruhe',
  Latitude: '490.152.080',
  Longitude: '84.353.650',
  Telephone: '721615570',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.015208,8.435365',
  id: '49.015208,8.435365'
}, {
  Brand: 'ARAL',
  Street: 'Siegfriedstraße 64',
  PostCode: 64385,
  City: 'Reichelsheim',
  Latitude: '497.224.960',
  Longitude: '88.681.940',
  Telephone: '61641214',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.722496,8.868194',
  id: '49.722496,8.868194'
}, {
  Brand: 'ARAL',
  Street: 'Hechtsheimer Straße 4',
  PostCode: 55131,
  City: 'Mainz',
  Latitude: '499.827.900',
  Longitude: '82.801.890',
  Telephone: '6131985789',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.98279,8.280189',
  id: '49.98279,8.280189'
}, {
  Brand: 'ARAL',
  Street: 'Hafenstraße 19-21',
  PostCode: 68159,
  City: 'Mannheim',
  Latitude: '494.940.560',
  Longitude: '84.570.630',
  Telephone: '62124207',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.494056,8.457063',
  id: '49.494056,8.457063'
}, {
  Brand: 'ARAL',
  Street: 'Oppauer Straße 125',
  PostCode: 67069,
  City: 'Ludwigshafen',
  Latitude: '495.340.190',
  Longitude: '83.888.570',
  Telephone: '621661459',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.534019,8.388857',
  id: '49.534019,8.388857'
}, {
  Brand: 'ARAL',
  Street: 'Heinigstraße 69',
  PostCode: 67059,
  City: 'Ludwigshafen',
  Latitude: '494.815.010',
  Longitude: '84.393.710',
  Telephone: '621514933',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.481501,8.439371',
  id: '49.481501,8.439371'
}, {
  Brand: 'ARAL',
  Street: 'Friedrich-Ebert-Straße 12',
  PostCode: 68167,
  City: 'Mannheim',
  Latitude: '494.965.830',
  Longitude: '84.877.060',
  Telephone: '621332213',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.496583,8.487706',
  id: '49.496583,8.487706'
}, {
  Brand: 'ARAL',
  Street: 'Zuzenhaeuser Straße 2',
  PostCode: 74909,
  City: 'Meckesheim',
  Latitude: '493.198.610',
  Longitude: '88.143.780',
  Telephone: '6226990562',
  WeekDayFrom: '04:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '49.319861,8.814378',
  id: '49.319861,8.814378'
}, {
  Brand: 'ARAL',
  Street: 'Steubenstraße 48',
  PostCode: 68163,
  City: 'Mannheim',
  Latitude: '494.607.390',
  Longitude: '84.781.270',
  Telephone: '6218322965',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.460739,8.478127',
  id: '49.460739,8.478127'
}, {
  Brand: 'ARAL',
  Street: 'Zellerstraße 34',
  PostCode: 56288,
  City: 'Kastellaun',
  Latitude: '500.688.680',
  Longitude: '74.393.970',
  Telephone: '67625599',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.068868,7.439397',
  id: '50.068868,7.439397'
}, {
  Brand: 'ARAL',
  Street: 'Römerstraße 1',
  PostCode: 69198,
  City: 'Schriesheim',
  Latitude: '494.749.390',
  Longitude: '86.585.180',
  Telephone: '620361422',
  WeekDayFrom: '04:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '04:00',
  SaturdayTo: '23:59',
  SundayFrom: '04:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '49.474939,8.658518',
  id: '49.474939,8.658518'
}, {
  Brand: 'ARAL',
  Street: 'Landauer Straße 65',
  PostCode: 67346,
  City: 'Speyer',
  Latitude: '493.089.350',
  Longitude: '84.239.430',
  Telephone: '623275427',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.308935,8.423943',
  id: '49.308935,8.423943'
}, {
  Brand: 'ARAL',
  Street: 'Mannheimerstraße 77',
  PostCode: 69469,
  City: 'Weinheim',
  Latitude: '495.494.260',
  Longitude: '86.547.390',
  Telephone: '620114295',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.549426,8.654739',
  id: '49.549426,8.654739'
}, {
  Brand: 'ARAL',
  Street: 'Alzeyer Straße 58',
  PostCode: 67549,
  City: 'Worms',
  Latitude: '496.319.110',
  Longitude: '83.473.080',
  Telephone: '624158314',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '49.631911,8.347308',
  id: '49.631911,8.347308'
}, {
  Brand: 'ARAL',
  Street: 'Hochwaldstraße 2',
  PostCode: 55765,
  City: 'Birkenfeld',
  Latitude: '496.507.580',
  Longitude: '71.574.860',
  Telephone: '678299390',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.650758,7.157486',
  id: '49.650758,7.157486'
}, {
  Brand: 'ARAL',
  Street: 'Lindenstraße 1',
  PostCode: 66583,
  City: 'Spiesen-Elversberg',
  Latitude: '493.176.890',
  Longitude: '71.224.820',
  Telephone: '6821790981',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.317689,7.122482',
  id: '49.317689,7.122482'
}, {
  Brand: 'ARAL',
  Street: 'Merziger Straße 106',
  PostCode: 66763,
  City: 'Dillingen',
  Latitude: '493.566.890',
  Longitude: '67.206.030',
  Telephone: '683171417',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.356689,6.720603',
  id: '49.356689,6.720603'
}, {
  Brand: 'ARAL',
  Street: 'Im Erfenbacher Tal 15',
  PostCode: 67661,
  City: 'Kaiserslautern',
  Latitude: '494.214.750',
  Longitude: '77.036.520',
  Telephone: '6313503528',
  WeekDayFrom: '05:15',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '09:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '49.421475,7.703652',
  id: '49.421475,7.703652'
}, {
  Brand: 'ARAL',
  Street: 'Primsweiler Straße 9',
  PostCode: 66839,
  City: 'Schmelz',
  Latitude: '494.186.010',
  Longitude: '68.450.310',
  Telephone: '68877474',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.418601,6.845031',
  id: '49.418601,6.845031'
}, {
  Brand: 'ARAL',
  Street: 'Saarbruecker Straße 100',
  PostCode: 66424,
  City: 'Homburg',
  Latitude: '493.186.780',
  Longitude: '73.278.160',
  Telephone: '6841120865',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.318678,7.327816',
  id: '49.318678,7.327816'
}, {
  Brand: 'ARAL',
  Street: 'Kreisstraße 26',
  PostCode: 66578,
  City: 'Schiffweiler',
  Latitude: '493.555.930',
  Longitude: '71.296.240',
  Telephone: '6821963739',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.355593,7.129624',
  id: '49.355593,7.129624'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 243',
  PostCode: 66589,
  City: 'Merchweiler',
  Latitude: '493.510.480',
  Longitude: '70.508.320',
  Telephone: '68255037',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.351048,7.050832',
  id: '49.351048,7.050832'
}, {
  Brand: 'ARAL',
  Street: 'Mainzer Straße 273',
  PostCode: 66121,
  City: 'Saarbrücken',
  Latitude: '492.255.000',
  Longitude: '70.296.550',
  Telephone: '68165358',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.2255,7.029655',
  id: '49.2255,7.029655'
}, {
  Brand: 'ARAL',
  Street: 'Metzer Straße 44',
  PostCode: 66740,
  City: 'Saarlouis',
  Latitude: '493.054.590',
  Longitude: '67.400.800',
  Telephone: '683140414',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.305459,6.74008',
  id: '49.305459,6.74008'
}, {
  Brand: 'ARAL',
  Street: 'Saarbrücker Straße 6 a',
  PostCode: 66386,
  City: 'St. Ingbert',
  Latitude: '492.756.110',
  Longitude: '71.105.750',
  Telephone: '68944468',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.275611,7.110575',
  id: '49.275611,7.110575'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 2 C',
  PostCode: 66802,
  City: 'Überherrn',
  Latitude: '492.486.090',
  Longitude: '66.971.890',
  Telephone: '683692570',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '49.248609,6.697189',
  id: '49.248609,6.697189'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 242',
  PostCode: 55743,
  City: 'Idar-Oberstein',
  Latitude: '497.095.120',
  Longitude: '73.153.560',
  Telephone: '678125120',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.709512,7.315356',
  id: '49.709512,7.315356'
}, {
  Brand: 'ARAL',
  Street: 'Trossinger Straße 80',
  PostCode: 78554,
  City: 'Aldingen',
  Latitude: '480.917.470',
  Longitude: '86.904.190',
  Telephone: '74241717',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '18:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.091747,8.690419',
  id: '48.091747,8.690419'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 41',
  PostCode: 77781,
  City: 'Biberach',
  Latitude: '483.403.200',
  Longitude: '80.246.650',
  Telephone: '78353925',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '18:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.34032,8.024665',
  id: '48.34032,8.024665'
}, {
  Brand: 'ARAL',
  Street: 'Lenzkircher Straße 24',
  PostCode: 79848,
  City: 'Bonndorf',
  Latitude: '478.183.360',
  Longitude: '83.306.620',
  Telephone: '770393780',
  WeekDayFrom: '06:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '47.818336,8.330662',
  id: '47.818336,8.330662'
}, {
  Brand: 'ARAL',
  Street: 'Koenigschaffhauser Straße',
  PostCode: 79346,
  City: 'Endingen',
  Latitude: '481.435.050',
  Longitude: '76.953.440',
  Telephone: '7642920880',
  WeekDayFrom: '07:30',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '19:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.143505,7.695344',
  id: '48.143505,7.695344'
}, {
  Brand: 'ARAL',
  Street: 'Adlerstraße 29',
  PostCode: 77948,
  City: 'Friesenheim',
  Latitude: '483.706.850',
  Longitude: '78.726.070',
  Telephone: '7821997535',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '48.370685,7.872607',
  id: '48.370685,7.872607'
}, {
  Brand: 'ARAL',
  Street: 'Steinacher Straße 7',
  PostCode: 77716,
  City: 'Haslach',
  Latitude: '482.777.310',
  Longitude: '80.842.920',
  Telephone: '78322404',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.277731,8.084292',
  id: '48.277731,8.084292'
}, {
  Brand: 'ARAL',
  Street: 'Talheimer Straße 21',
  PostCode: 72393,
  City: 'Burladingen - Melchi',
  Latitude: '483.610.610',
  Longitude: '91.452.840',
  Telephone: '71261583',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:30',
  SundayFrom: '08:00',
  SundayTo: '20:30',
  IsOpen24Hours: 'Nein',
  location: '48.361061,9.145284',
  id: '48.361061,9.145284'
}, {
  Brand: 'ARAL',
  Street: 'Hanauer Straße 64',
  PostCode: 63739,
  City: 'Aschaffenburg',
  Latitude: '499.804.520',
  Longitude: '91.324.440',
  Telephone: '602127874',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.980452,9.132444',
  id: '49.980452,9.132444'
}, {
  Brand: 'ARAL',
  Street: 'Am Luitpoldhain 1 A',
  PostCode: 96050,
  City: 'Bamberg',
  Latitude: '498.866.650',
  Longitude: '109.063.910',
  Telephone: '951130725',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.886665,10.906391',
  id: '49.886665,10.906391'
}, {
  Brand: 'ARAL',
  Street: 'An der Saale 1a',
  PostCode: 97717,
  City: 'Euerdorf',
  Latitude: '501.519.240',
  Longitude: '100.265.640',
  Telephone: '97047580',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:30',
  SaturdayFrom: '07:30',
  SaturdayTo: '19:00',
  SundayFrom: '09:30',
  SundayTo: '18:30',
  IsOpen24Hours: 'Nein',
  location: '50.151924,10.026564',
  id: '50.151924,10.026564'
}, {
  Brand: 'ARAL',
  Street: 'Erlanger Straße 41',
  PostCode: 91074,
  City: 'Herzogenaurach',
  Latitude: '495.669.820',
  Longitude: '108.928.250',
  Telephone: '9132797981',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.566982,10.892825',
  id: '49.566982,10.892825'
}, {
  Brand: 'ARAL',
  Street: 'Forststraße 3 - 5',
  PostCode: 63796,
  City: 'Kahl',
  Latitude: '500.752.660',
  Longitude: '90.016.230',
  Telephone: '6188900819',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '50.075266,9.001623',
  id: '50.075266,9.001623'
}, {
  Brand: 'ARAL',
  Street: 'Kanzleistraße 1',
  PostCode: 96328,
  City: 'Küps',
  Latitude: '502.107.240',
  Longitude: '112.909.230',
  Telephone: '92649959073',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.210724,11.290923',
  id: '50.210724,11.290923'
}, {
  Brand: 'ARAL',
  Street: 'Mergentheimer Straße 126',
  PostCode: 74653,
  City: 'Künzelsau',
  Latitude: '492.844.470',
  Longitude: '96.844.910',
  Telephone: '7940910821',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.284447,9.684491',
  id: '49.284447,9.684491'
}, {
  Brand: 'ARAL',
  Street: 'Kronacher Straße 40',
  PostCode: 96268,
  City: 'Mitwitz',
  Latitude: '502.497.300',
  Longitude: '112.146.300',
  Telephone: '9266254',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '18:30',
  IsOpen24Hours: 'Nein',
  location: '50.24973,11.21463',
  id: '50.24973,11.21463'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 7',
  PostCode: 69427,
  City: 'Mudau',
  Latitude: '495.299.720',
  Longitude: '92.047.450',
  Telephone: '628495025',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '49.529972,9.204745',
  id: '49.529972,9.204745'
}, {
  Brand: 'ARAL',
  Street: 'Würzburger Straße 44',
  PostCode: 97424,
  City: 'Schweinfurt',
  Latitude: '500.244.440',
  Longitude: '101.961.020',
  Telephone: '972181466',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:59',
  SundayFrom: '05:00',
  SundayTo: '22:59',
  IsOpen24Hours: 'Nein',
  location: '50.024444,10.196102',
  id: '50.024444,10.196102'
}, {
  Brand: 'ARAL',
  Street: 'Wuerzburger Straße 34',
  PostCode: 97215,
  City: 'Uffenheim',
  Latitude: '495.466.880',
  Longitude: '102.270.660',
  Telephone: '9842415',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.546688,10.227066',
  id: '49.546688,10.227066'
}, {
  Brand: 'ARAL',
  Street: 'Sommeracher Straße 35',
  PostCode: 97332,
  City: 'Volkach',
  Latitude: '498.601.870',
  Longitude: '102.273.480',
  Telephone: '9381809616',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.860187,10.227348',
  id: '49.860187,10.227348'
}, {
  Brand: 'ARAL',
  Street: 'Kronacher Straße 2',
  PostCode: 96465,
  City: 'Neustadt b. Coburg',
  Latitude: '502.761.080',
  Longitude: '111.595.340',
  Telephone: '95682449',
  WeekDayFrom: '06:30',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '17:00',
  SundayFrom: '08:00',
  SundayTo: '17:00',
  IsOpen24Hours: 'Nein',
  location: '50.276108,11.159534',
  id: '50.276108,11.159534'
}, {
  Brand: 'ARAL',
  Street: 'Nuernberger Straße 23',
  PostCode: 90518,
  City: 'Altdorf',
  Latitude: '493.844.280',
  Longitude: '113.488.470',
  Telephone: '918790180',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.384428,11.348847',
  id: '49.384428,11.348847'
}, {
  Brand: 'ARAL',
  Street: 'Ansbacher Straße 20',
  PostCode: 91595,
  City: 'Burgoberbach',
  Latitude: '492.390.430',
  Longitude: '105.927.490',
  Telephone: '9805275',
  WeekDayFrom: '06:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '49.239043,10.592749',
  id: '49.239043,10.592749'
}, {
  Brand: 'ARAL',
  Street: 'Forther Hauptstraße 2',
  PostCode: 90542,
  City: 'Eckental',
  Latitude: '495.892.560',
  Longitude: '112.170.150',
  Telephone: '9126259515',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.589256,11.217015',
  id: '49.589256,11.217015'
}, {
  Brand: 'ARAL',
  Street: 'Bamberger Straße 91',
  PostCode: 91301,
  City: 'Forchheim',
  Latitude: '497.339.000',
  Longitude: '110.569.360',
  Telephone: '9191727212',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.7339,11.056936',
  id: '49.7339,11.056936'
}, {
  Brand: 'ARAL',
  Street: 'Nuernberger Straße 126',
  PostCode: 90762,
  City: 'Fürth',
  Latitude: '494.675.630',
  Longitude: '110.057.290',
  Telephone: '911706709',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.467563,11.005729',
  id: '49.467563,11.005729'
}, {
  Brand: 'ARAL',
  Street: 'Ansbacher Straße 34',
  PostCode: 91586,
  City: 'Lichtenau',
  Latitude: '492.811.710',
  Longitude: '106.797.450',
  Telephone: '9827250',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '19:00',
  SundayFrom: '09:00',
  SundayTo: '12:00',
  IsOpen24Hours: 'Nein',
  location: '49.281171,10.679745',
  id: '49.281171,10.679745'
}, {
  Brand: 'ARAL',
  Street: 'Münchener Straße 401',
  PostCode: 90471,
  City: 'Nürnberg',
  Latitude: '494.018.530',
  Longitude: '111.154.670',
  Telephone: '911806266',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.401853,11.115467',
  id: '49.401853,11.115467'
}, {
  Brand: 'ARAL',
  Street: 'Allersberger Straße 33',
  PostCode: 91154,
  City: 'Roth',
  Latitude: '492.497.780',
  Longitude: '110.955.880',
  Telephone: '91712530',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.249778,11.095588',
  id: '49.249778,11.095588'
}, {
  Brand: 'ARAL',
  Street: 'Schwandorfer Straße 10',
  PostCode: 93426,
  City: 'Roding',
  Latitude: '492.058.210',
  Longitude: '124.863.660',
  Telephone: '94611233',
  WeekDayFrom: '05:30',
  WeekDayTo: '21:30',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:30',
  SundayFrom: '07:00',
  SundayTo: '21:30',
  IsOpen24Hours: 'Nein',
  location: '49.205821,12.486366',
  id: '49.205821,12.486366'
}, {
  Brand: 'ARAL',
  Street: 'Deggendorfer Straße 21',
  PostCode: 94535,
  City: 'Eging',
  Latitude: '487.144.000',
  Longitude: '132.664.380',
  Telephone: '8544327',
  WeekDayFrom: '06:30',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '48.7144,13.266438',
  id: '48.7144,13.266438'
}, {
  Brand: 'ARAL',
  Street: 'Deggendorfer Straße 48',
  PostCode: 94491,
  City: 'Hengersberg',
  Latitude: '487.801.680',
  Longitude: '130.528.300',
  Telephone: '990193470',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.780168,13.05283',
  id: '48.780168,13.05283'
}, {
  Brand: 'ARAL',
  Street: 'Neuburger Straße 141',
  PostCode: 94036,
  City: 'Passau',
  Latitude: '485.589.930',
  Longitude: '134.216.070',
  Telephone: '85153174',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.558993,13.421607',
  id: '48.558993,13.421607'
}, {
  Brand: 'ARAL',
  Street: 'Ruselstraße 37',
  PostCode: 94209,
  City: 'Regen',
  Latitude: '489.664.080',
  Longitude: '131.188.740',
  Telephone: '99212431',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.966408,13.118874',
  id: '48.966408,13.118874'
}, {
  Brand: 'ARAL',
  Street: 'Muenchner Straße 50',
  PostCode: 84359,
  City: 'Simbach',
  Latitude: '482.629.760',
  Longitude: '130.164.490',
  Telephone: '85712006',
  WeekDayFrom: '05:30',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:30',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '48.262976,13.016449',
  id: '48.262976,13.016449'
}, {
  Brand: 'ARAL',
  Street: 'Landshuter Straße 16',
  PostCode: 84149,
  City: 'Velden',
  Latitude: '483.686.260',
  Longitude: '122.512.420',
  Telephone: '8742919288',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.368626,12.251242',
  id: '48.368626,12.251242'
}, {
  Brand: 'ARAL',
  Street: 'Sauerlacher Straße 64',
  PostCode: 82515,
  City: 'Wolfratshausen',
  Latitude: '479.139.150',
  Longitude: '114.323.730',
  Telephone: '817120793',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '47.913915,11.432373',
  id: '47.913915,11.432373'
}, {
  Brand: 'ARAL',
  Street: 'Suedl.Muenchner Straße 28',
  PostCode: 82031,
  City: 'Grünwald',
  Latitude: '480.454.240',
  Longitude: '115.290.820',
  Telephone: '896412061',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '48.045424,11.529082',
  id: '48.045424,11.529082'
}, {
  Brand: 'ARAL',
  Street: 'Nymphenburger Straße 205',
  PostCode: 80639,
  City: 'München',
  Latitude: '481.577.310',
  Longitude: '115.282.730',
  Telephone: '89160379',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.157731,11.528273',
  id: '48.157731,11.528273'
}, {
  Brand: 'ARAL',
  Street: 'Kapuzinerstraße 46',
  PostCode: 80469,
  City: 'München',
  Latitude: '481.238.900',
  Longitude: '115.648.310',
  Telephone: '89761224',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.12389,11.564831',
  id: '48.12389,11.564831'
}, {
  Brand: 'ARAL',
  Street: 'Waldwiesenstraße 31',
  PostCode: 81375,
  City: 'München',
  Latitude: '481.190.090',
  Longitude: '114.782.350',
  Telephone: '8974029166',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '22:59',
  IsOpen24Hours: 'Nein',
  location: '48.119009,11.478235',
  id: '48.119009,11.478235'
}, {
  Brand: 'ARAL',
  Street: 'Berg-am-Laim-Straße 60',
  PostCode: 81673,
  City: 'München',
  Latitude: '481.305.090',
  Longitude: '116.171.730',
  Telephone: '8945409628',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.130509,11.617173',
  id: '48.130509,11.617173'
}, {
  Brand: 'ARAL',
  Street: 'Grünwalder Straße 175 C',
  PostCode: 81545,
  City: 'München',
  Latitude: '480.963.810',
  Longitude: '115.641.140',
  Telephone: '8964270493',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.096381,11.564114',
  id: '48.096381,11.564114'
}, {
  Brand: 'ARAL',
  Street: 'Wolfratshauser Straße 92',
  PostCode: 81379,
  City: 'München',
  Latitude: '480.923.170',
  Longitude: '115.392.770',
  Telephone: '897232174',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.092317,11.539277',
  id: '48.092317,11.539277'
}, {
  Brand: 'ARAL',
  Street: 'Aichacher Straße 26',
  PostCode: 86316,
  City: 'Friedberg',
  Latitude: '483.614.050',
  Longitude: '109.877.900',
  Telephone: '821601885',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.361405,10.98779',
  id: '48.361405,10.98779'
}, {
  Brand: 'ARAL',
  Street: 'Lachener Straße 56',
  PostCode: 86911,
  City: 'Dießen',
  Latitude: '479.579.670',
  Longitude: '111.021.960',
  Telephone: '88078776',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '47.957967,11.102196',
  id: '47.957967,11.102196'
}, {
  Brand: 'ARAL',
  Street: 'Münchener Straße 37',
  PostCode: 85567,
  City: 'Grafing',
  Latitude: '480.494.020',
  Longitude: '119.658.360',
  Telephone: '80921822',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '15:00',
  SundayFrom: '10:00',
  SundayTo: '14:00',
  IsOpen24Hours: 'Nein',
  location: '48.049402,11.965836',
  id: '48.049402,11.965836'
}, {
  Brand: 'ARAL',
  Street: 'Schondorfer Straße 12',
  PostCode: 86919,
  City: 'Utting am Ammersee',
  Latitude: '480.277.080',
  Longitude: '110.855.830',
  Telephone: '88067267',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.027708,11.085583',
  id: '48.027708,11.085583'
}, {
  Brand: 'ARAL',
  Street: 'Stuttgarter Straße 141',
  PostCode: 71522,
  City: 'Backnang',
  Latitude: '489.360.690',
  Longitude: '94.377.890',
  Telephone: '719163813',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '01:00',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '48.936069,9.437789',
  id: '48.936069,9.437789'
}, {
  Brand: 'ARAL',
  Street: 'Cannstatter Straße 46',
  PostCode: 70190,
  City: 'Stuttgart',
  Latitude: '487.892.190',
  Longitude: '91.923.240',
  Telephone: '7112625648',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.789219,9.192324',
  id: '48.789219,9.192324'
}, {
  Brand: 'ARAL',
  Street: 'Deinbacher Straße 5',
  PostCode: 73527,
  City: 'Schwäbisch Gmünd',
  Latitude: '488.164.560',
  Longitude: '97.780.750',
  Telephone: '717174516',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.816456,9.778075',
  id: '48.816456,9.778075'
}, {
  Brand: 'ARAL',
  Street: 'Remsstraße 10',
  PostCode: 73525,
  City: 'Schwäbisch Gmünd',
  Latitude: '488.027.950',
  Longitude: '97.975.670',
  Telephone: '717136288',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.802795,9.797567',
  id: '48.802795,9.797567'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 4-8',
  PostCode: 73342,
  City: 'Bad Ditzenbach',
  Latitude: '485.816.520',
  Longitude: '96.842.120',
  Telephone: '73355304',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '22:00',
  SundayFrom: '07:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.581652,9.684212',
  id: '48.581652,9.684212'
}, {
  Brand: 'ARAL',
  Street: 'Oststraße 72',
  PostCode: 74072,
  City: 'Heilbronn',
  Latitude: '491.400.130',
  Longitude: '92.299.140',
  Telephone: '7131177475',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.140013,9.229914',
  id: '49.140013,9.229914'
}, {
  Brand: 'ARAL',
  Street: 'Theodor Heuss Straße 20',
  PostCode: 72636,
  City: 'Frickenhausen',
  Latitude: '485.823.090',
  Longitude: '93.686.840',
  Telephone: '70252555',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:30',
  SundayTo: '21:30',
  IsOpen24Hours: 'Nein',
  location: '48.582309,9.368684',
  id: '48.582309,9.368684'
}, {
  Brand: 'ARAL',
  Street: 'Heilbronner Straße 2',
  PostCode: 74535,
  City: 'Mainhardt',
  Latitude: '490.768.720',
  Longitude: '95.632.530',
  Telephone: '79031081',
  WeekDayFrom: '07:30',
  WeekDayTo: '19:30',
  SaturdayFrom: '08:00',
  SaturdayTo: '18:00',
  SundayFrom: '09:00',
  SundayTo: '18:00',
  IsOpen24Hours: 'Nein',
  location: '49.076872,9.563253',
  id: '49.076872,9.563253'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 2',
  PostCode: 73563,
  City: 'Mögglingen',
  Latitude: '488.233.850',
  Longitude: '99.578.000',
  Telephone: '7174316',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:00',
  SundayFrom: '07:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.823385,9.9578',
  id: '48.823385,9.9578'
}, {
  Brand: 'ARAL',
  Street: 'Plieninger Straße 96',
  PostCode: 70567,
  City: 'Stuttgart',
  Latitude: '487.232.020',
  Longitude: '91.598.870',
  Telephone: '7117285215',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.723202,9.159887',
  id: '48.723202,9.159887'
}, {
  Brand: 'ARAL',
  Street: 'Wiener Straße 129',
  PostCode: 70469,
  City: 'Stuttgart',
  Latitude: '488.100.960',
  Longitude: '91.541.950',
  Telephone: '711852430',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.810096,9.154195',
  id: '48.810096,9.154195'
}, {
  Brand: 'ARAL',
  Street: 'Robert-Koch-Straße 64',
  PostCode: 70563,
  City: 'Stuttgart',
  Latitude: '487.248.770',
  Longitude: '91.065.600',
  Telephone: '7119019518',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.724877,9.10656',
  id: '48.724877,9.10656'
}, {
  Brand: 'ARAL',
  Street: 'Jesinger Hauptstraße 130',
  PostCode: 72070,
  City: 'Tübingen',
  Latitude: '485.248.160',
  Longitude: '89.874.360',
  Telephone: '70732597',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.524816,8.987436',
  id: '48.524816,8.987436'
}, {
  Brand: 'ARAL',
  Street: 'Rollinstraße 2',
  PostCode: 88400,
  City: 'Biberach',
  Latitude: '480.969.170',
  Longitude: '97.919.010',
  Telephone: '73519320',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.096917,9.791901',
  id: '48.096917,9.791901'
}, {
  Brand: 'ARAL',
  Street: 'Messkircher Straße 31',
  PostCode: 88512,
  City: 'Mengen',
  Latitude: '480.442.910',
  Longitude: '93.220.060',
  Telephone: '75725670',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '08:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '48.044291,9.322006',
  id: '48.044291,9.322006'
}, {
  Brand: 'ARAL',
  Street: 'Illerstraße 2',
  PostCode: 89077,
  City: 'Ulm',
  Latitude: '483.890.880',
  Longitude: '99.781.950',
  Telephone: '73130306',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.389088,9.978195',
  id: '48.389088,9.978195'
}, {
  Brand: 'ARAL',
  Street: 'Karlstraße 77',
  PostCode: 89073,
  City: 'Ulm',
  Latitude: '484.064.030',
  Longitude: '99.962.630',
  Telephone: '73123019',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '48.406403,9.996263',
  id: '48.406403,9.996263'
}, {
  Brand: 'ARAL',
  Street: 'Bismarckstraße 2',
  PostCode: 10625,
  City: 'Berlin',
  Latitude: '525.128.110',
  Longitude: '133.197.420',
  Telephone: '3034707002',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.512811,13.319742',
  id: '52.512811,13.319742'
}, {
  Brand: 'ARAL',
  Street: 'Kaiser-Friedrich-Str. 45 B',
  PostCode: 10627,
  City: 'Berlin',
  Latitude: '525.077.800',
  Longitude: '133.015.860',
  Telephone: '3032701495',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.50778,13.301586',
  id: '52.50778,13.301586'
}, {
  Brand: 'ARAL',
  Street: 'Messedamm 6',
  PostCode: 14057,
  City: 'Berlin',
  Latitude: '525.096.030',
  Longitude: '132.813.250',
  Telephone: '303025484',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.509603,13.281325',
  id: '52.509603,13.281325'
}, {
  Brand: 'ARAL',
  Street: 'Heerstraße 136',
  PostCode: 14055,
  City: 'Berlin',
  Latitude: '525.070.790',
  Longitude: '132.277.810',
  Telephone: '3030810202',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.507079,13.227781',
  id: '52.507079,13.227781'
}, {
  Brand: 'ARAL',
  Street: 'Skalitzer Straße 26',
  PostCode: 10999,
  City: 'Berlin',
  Latitude: '524.987.030',
  Longitude: '134.225.380',
  Telephone: '3061702190',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.498703,13.422538',
  id: '52.498703,13.422538'
}, {
  Brand: 'ARAL',
  Street: 'Neukoellner Str. 250-256',
  PostCode: 12357,
  City: 'Berlin',
  Latitude: '524.257.890',
  Longitude: '134.823.540',
  Telephone: '306613947',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.425789,13.482354',
  id: '52.425789,13.482354'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Straße 67',
  PostCode: 13507,
  City: 'Berlin',
  Latitude: '525.813.860',
  Longitude: '132.913.770',
  Telephone: '3043551049',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.581386,13.291377',
  id: '52.581386,13.291377'
}, {
  Brand: 'ARAL',
  Street: 'Kaiser-Wilhelm-Straße 117',
  PostCode: 12247,
  City: 'Berlin',
  Latitude: '524.398.320',
  Longitude: '133.512.520',
  Telephone: '3076680128',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.439832,13.351252',
  id: '52.439832,13.351252'
}, {
  Brand: 'ARAL',
  Street: 'Drakestraße 26a',
  PostCode: 12205,
  City: 'Berlin',
  Latitude: '524.408.490',
  Longitude: '132.994.310',
  Telephone: '3084309770',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.440849,13.299431',
  id: '52.440849,13.299431'
}, {
  Brand: 'ARAL',
  Street: 'Dudenstraße 19',
  PostCode: 10965,
  City: 'Berlin',
  Latitude: '524.848.930',
  Longitude: '133.813.660',
  Telephone: '3078913831',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.484893,13.381366',
  id: '52.484893,13.381366'
}, {
  Brand: 'ARAL',
  Street: 'Mariendorfer Damm 341',
  PostCode: 12107,
  City: 'Berlin',
  Latitude: '524.230.450',
  Longitude: '133.967.420',
  Telephone: '307411920',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '05:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.423045,13.396742',
  id: '52.423045,13.396742'
}, {
  Brand: 'ARAL',
  Street: 'Beusselstraße 55',
  PostCode: 10553,
  City: 'Berlin',
  Latitude: '525.308.550',
  Longitude: '133.283.940',
  Telephone: '303914404',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.530855,13.328394',
  id: '52.530855,13.328394'
}, {
  Brand: 'ARAL',
  Street: 'Suedwestkorso 36 A',
  PostCode: 14197,
  City: 'Berlin',
  Latitude: '524.684.390',
  Longitude: '133.112.890',
  Telephone: '308211291',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.468439,13.311289',
  id: '52.468439,13.311289'
}, {
  Brand: 'ARAL',
  Street: 'Blissestraße 21',
  PostCode: 10713,
  City: 'Berlin',
  Latitude: '524.843.250',
  Longitude: '133.206.710',
  Telephone: '308738323',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.484325,13.320671',
  id: '52.484325,13.320671'
}, {
  Brand: 'ARAL',
  Street: 'Hohenzollerndamm 97',
  PostCode: 14199,
  City: 'Berlin',
  Latitude: '524.758.870',
  Longitude: '132.791.620',
  Telephone: '308256179',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.475887,13.279162',
  id: '52.475887,13.279162'
}, {
  Brand: 'ARAL',
  Street: 'Westfaelische Straße 9-10',
  PostCode: 10709,
  City: 'Berlin',
  Latitude: '524.925.130',
  Longitude: '133.092.850',
  Telephone: '3086409379',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.492513,13.309285',
  id: '52.492513,13.309285'
}, {
  Brand: 'ARAL',
  Street: 'Kurfuerstendamm 128',
  PostCode: 10711,
  City: 'Berlin',
  Latitude: '524.958.040',
  Longitude: '132.876.050',
  Telephone: '3089096972',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.495804,13.287605',
  id: '52.495804,13.287605'
}, {
  Brand: 'ARAL',
  Street: 'Königstraße 58',
  PostCode: 14109,
  City: 'Berlin',
  Latitude: '524.192.100',
  Longitude: '131.585.420',
  Telephone: '308051018',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.41921,13.158542',
  id: '52.41921,13.158542'
}, {
  Brand: 'ARAL',
  Street: 'Angermuehle 8',
  PostCode: 94469,
  City: 'Deggendorf',
  Latitude: '488.351.130',
  Longitude: '129.589.760',
  Telephone: '99138300112',
  WeekDayFrom: '05:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.835113,12.958976',
  id: '48.835113,12.958976'
}, {
  Brand: 'ARAL',
  Street: 'Konrad-Adenauer-Damm 100',
  PostCode: 24143,
  City: 'Kiel',
  Latitude: '542.969.630',
  Longitude: '101.648.910',
  Telephone: '431782778',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.296963,10.164891',
  id: '54.296963,10.164891'
}, {
  Brand: 'ARAL',
  Street: 'Weisendorfer Straße 16',
  PostCode: 91056,
  City: 'Erlangen',
  Latitude: '496.256.820',
  Longitude: '109.406.430',
  Telephone: '9135729895',
  WeekDayFrom: '04:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '49.625682,10.940643',
  id: '49.625682,10.940643'
}, {
  Brand: 'ARAL',
  Street: 'Regensburger Straße 31',
  PostCode: 85290,
  City: 'Geisenfeld',
  Latitude: '486.866.150',
  Longitude: '116.179.310',
  Telephone: '845272790',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:30',
  SundayFrom: '07:30',
  SundayTo: '20:30',
  IsOpen24Hours: 'Nein',
  location: '48.686615,11.617931',
  id: '48.686615,11.617931'
}, {
  Brand: 'ARAL',
  Street: 'Erlanger Straße 40',
  PostCode: 90425,
  City: 'Nürnberg',
  Latitude: '494.736.850',
  Longitude: '110.648.630',
  Telephone: '911341045',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.473685,11.064863',
  id: '49.473685,11.064863'
}, {
  Brand: 'ARAL',
  Street: 'Schiffbeker Weg 228',
  PostCode: 22119,
  City: 'Hamburg',
  Latitude: '535.619.800',
  Longitude: '101.197.750',
  Telephone: '406532765',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.56198,10.119775',
  id: '53.56198,10.119775'
}, {
  Brand: 'ARAL',
  Street: 'Gewerkenstraße 63',
  PostCode: 45881,
  City: 'Gelsenkirchen',
  Latitude: '515.196.560',
  Longitude: '70.818.890',
  Telephone: '2094082034',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.519656,7.081889',
  id: '51.519656,7.081889'
}, {
  Brand: 'ARAL',
  Street: 'Buschkrugallee 84-92',
  PostCode: 12359,
  City: 'Berlin',
  Latitude: '524.558.910',
  Longitude: '134.496.410',
  Telephone: '306061011',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '52.455891,13.449641',
  id: '52.455891,13.449641'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 32',
  PostCode: 58762,
  City: 'Altena',
  Latitude: '513.023.460',
  Longitude: '76.670.490',
  Telephone: '235225355',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.302346,7.667049',
  id: '51.302346,7.667049'
}, {
  Brand: 'ARAL',
  Street: 'Regensburger Straße 30',
  PostCode: 85088,
  City: 'Vohburg',
  Latitude: '487.687.660',
  Longitude: '116.234.620',
  Telephone: '845792960',
  WeekDayFrom: '07:00',
  WeekDayTo: '18:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '13:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.768766,11.623462',
  id: '48.768766,11.623462'
}, {
  Brand: 'ARAL',
  Street: 'Schlossstraße 63-64',
  PostCode: 12165,
  City: 'Berlin',
  Latitude: '524.530.790',
  Longitude: '133.150.760',
  Telephone: '308344870',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.453079,13.315076',
  id: '52.453079,13.315076'
}, {
  Brand: 'ARAL',
  Street: 'Kölner Straße 356',
  PostCode: 40227,
  City: 'Düsseldorf',
  Latitude: '512.095.360',
  Longitude: '68.100.530',
  Telephone: '21199614011',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.209536,6.810053',
  id: '51.209536,6.810053'
}, {
  Brand: 'ARAL',
  Street: 'Stresemannstraße 310',
  PostCode: 27580,
  City: 'Bremerhaven',
  Latitude: '535.705.730',
  Longitude: '85.988.230',
  Telephone: '47185150',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.570573,8.598823',
  id: '53.570573,8.598823'
}, {
  Brand: 'ARAL',
  Street: 'Vogelsbergstraße 25',
  PostCode: 63679,
  City: 'Schotten',
  Latitude: '504.955.860',
  Longitude: '91.133.830',
  Telephone: '60441524',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.495586,9.113383',
  id: '50.495586,9.113383'
}, {
  Brand: 'ARAL',
  Street: 'A 2',
  PostCode: 31275,
  City: 'Lehrte',
  Latitude: '523.882.970',
  Longitude: '99.978.080',
  Telephone: '51324888',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.388297,9.997808',
  id: '52.388297,9.997808'
}, {
  Brand: 'ARAL',
  Street: 'Lagesche Straße 60-64',
  PostCode: 32657,
  City: 'Lemgo',
  Latitude: '520.225.350',
  Longitude: '88.858.770',
  Telephone: '526117044',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.022535,8.885877',
  id: '52.022535,8.885877'
}, {
  Brand: 'ARAL',
  Street: 'Mergentheimer Straße 31',
  PostCode: 97084,
  City: 'Würzburg',
  Latitude: '497.681.260',
  Longitude: '99.404.640',
  Telephone: '931611213',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.768126,9.940464',
  id: '49.768126,9.940464'
}, {
  Brand: 'ARAL',
  Street: 'Westend 52',
  PostCode: 46399,
  City: 'Bocholt',
  Latitude: '518.386.270',
  Longitude: '66.013.420',
  Telephone: '2871233642',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.838627,6.601342',
  id: '51.838627,6.601342'
}, {
  Brand: 'ARAL',
  Street: 'Wittenduener Allee 11',
  PostCode: 25826,
  City: 'St Peter-Ording',
  Latitude: '543.022.440',
  Longitude: '86.511.250',
  Telephone: '48633007',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '54.302244,8.651125',
  id: '54.302244,8.651125'
}, {
  Brand: 'ARAL',
  Street: 'Bahnstraße 131',
  PostCode: 46147,
  City: 'Oberhausen',
  Latitude: '515.295.830',
  Longitude: '67.979.490',
  Telephone: '208680549',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '10:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.529583,6.797949',
  id: '51.529583,6.797949'
}, {
  Brand: 'ARAL',
  Street: 'Weismainer Straße 58',
  PostCode: 96264,
  City: 'Altenkunstadt',
  Latitude: '501.279.960',
  Longitude: '112.397.430',
  Telephone: '95721627',
  WeekDayFrom: '06:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.127996,11.239743',
  id: '50.127996,11.239743'
}, {
  Brand: 'ARAL',
  Street: 'Curslacker Neuer Deich 34',
  PostCode: 21029,
  City: 'Hamburg',
  Latitude: '534.817.750',
  Longitude: '102.081.220',
  Telephone: '4072698676',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.481775,10.208122',
  id: '53.481775,10.208122'
}, {
  Brand: 'ARAL',
  Street: 'Charlottenburger Straße 9',
  PostCode: 37115,
  City: 'Duderstadt',
  Latitude: '515.163.670',
  Longitude: '102.429.810',
  Telephone: '5527941535',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '06:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.516367,10.242981',
  id: '51.516367,10.242981'
}, {
  Brand: 'ARAL',
  Street: 'Wilhelmstraße 24',
  PostCode: 42489,
  City: 'Wülfrath',
  Latitude: '512.853.290',
  Longitude: '70.474.940',
  Telephone: '205874220',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.285329,7.047494',
  id: '51.285329,7.047494'
}, {
  Brand: 'ARAL',
  Street: 'Regentalstraße 10',
  PostCode: 93474,
  City: 'Arrach',
  Latitude: '491.966.110',
  Longitude: '130.080.350',
  Telephone: '99433657',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.196611,13.008035',
  id: '49.196611,13.008035'
}, {
  Brand: 'ARAL',
  Street: 'Erdinger Straße 25',
  PostCode: 85570,
  City: 'Markt Schwaben',
  Latitude: '481.932.740',
  Longitude: '118.722.680',
  Telephone: '81216010',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.193274,11.872268',
  id: '48.193274,11.872268'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Straße 323',
  PostCode: 40595,
  City: 'Düsseldorf',
  Latitude: '511.380.050',
  Longitude: '69.049.630',
  Telephone: '2117053523',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.138005,6.904963',
  id: '51.138005,6.904963'
}, {
  Brand: 'ARAL',
  Street: 'Donatusstraße 20',
  PostCode: 50767,
  City: 'Köln',
  Latitude: '510.034.040',
  Longitude: '68.750.870',
  Telephone: '2219591032',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '08:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '51.003404,6.875087',
  id: '51.003404,6.875087'
}, {
  Brand: 'ARAL',
  Street: 'Werner-von-Siemens-Straße 2',
  PostCode: 30880,
  City: 'Laatzen',
  Latitude: '523.116.700',
  Longitude: '97.982.370',
  Telephone: '5118790787',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.31167,9.798237',
  id: '52.31167,9.798237'
}, {
  Brand: 'ARAL',
  Street: 'In der Schwärzgrub 20',
  PostCode: 55774,
  City: 'Baumholder',
  Latitude: '496.093.300',
  Longitude: '73.287.580',
  Telephone: '67837701',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.60933,7.328758',
  id: '49.60933,7.328758'
}, {
  Brand: 'ARAL',
  Street: 'Brühler Straße 160',
  PostCode: 50389,
  City: 'Wesseling',
  Latitude: '508.308.950',
  Longitude: '69.565.590',
  Telephone: '2232942668',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.830895,6.956559',
  id: '50.830895,6.956559'
}, {
  Brand: 'ARAL',
  Street: 'Schönberger Straße 7-16',
  PostCode: 74405,
  City: 'Gaildorf',
  Latitude: '489.793.460',
  Longitude: '97.793.300',
  Telephone: '79716005',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.979346,9.77933',
  id: '48.979346,9.77933'
}, {
  Brand: 'ARAL',
  Street: 'Krempelsdorfer Allee 63',
  PostCode: 23556,
  City: 'Lübeck',
  Latitude: '538.844.750',
  Longitude: '106.538.040',
  Telephone: '451492396',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.884475,10.653804',
  id: '53.884475,10.653804'
}, {
  Brand: 'ARAL',
  Street: 'Kölner Straße 255',
  PostCode: 51149,
  City: 'Köln',
  Latitude: '509.035.920',
  Longitude: '70.207.290',
  Telephone: '220317951',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.903592,7.020729',
  id: '50.903592,7.020729'
}, {
  Brand: 'ARAL',
  Street: 'Untermühlaustraße 50',
  PostCode: 68169,
  City: 'Mannheim',
  Latitude: '495.061.780',
  Longitude: '84.639.670',
  Telephone: '621316970',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.506178,8.463967',
  id: '49.506178,8.463967'
}, {
  Brand: 'ARAL',
  Street: 'Holsatenring 38',
  PostCode: 24539,
  City: 'Neumünster',
  Latitude: '540.633.870',
  Longitude: '99.837.430',
  Telephone: '432141297',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.063387,9.983743',
  id: '54.063387,9.983743'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 13',
  PostCode: 67691,
  City: 'Hochspeyer',
  Latitude: '494.433.630',
  Longitude: '78.828.090',
  Telephone: '6305715343',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.443363,7.882809',
  id: '49.443363,7.882809'
}, {
  Brand: 'ARAL',
  Street: 'Mühlheimer Straße 111-11',
  PostCode: 63075,
  City: 'Offenbach',
  Latitude: '501.055.890',
  Longitude: '87.857.810',
  Telephone: '6986781191',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.105589,8.785781',
  id: '50.105589,8.785781'
}, {
  Brand: 'ARAL',
  Street: 'Hagenburger Straße 8',
  PostCode: 31515,
  City: 'Wunstorf',
  Latitude: '524.295.900',
  Longitude: '94.230.010',
  Telephone: '5031913719',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.42959,9.423001',
  id: '52.42959,9.423001'
}, {
  Brand: 'ARAL',
  Street: 'Stader Straße 294-296',
  PostCode: 21075,
  City: 'Hamburg',
  Latitude: '534.708.720',
  Longitude: '99.237.330',
  Telephone: '407928704',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.470872,9.923733',
  id: '53.470872,9.923733'
}, {
  Brand: 'ARAL',
  Street: 'Werler Straße 30',
  PostCode: 32105,
  City: 'Bad Salzuflen',
  Latitude: '520.843.860',
  Longitude: '87.364.960',
  Telephone: '52224410',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.084386,8.736496',
  id: '52.084386,8.736496'
}, {
  Brand: 'ARAL',
  Street: 'Schollstraße 2',
  PostCode: 85055,
  City: 'Ingolstadt',
  Latitude: '487.752.510',
  Longitude: '114.593.520',
  Telephone: '8412294',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.775251,11.459352',
  id: '48.775251,11.459352'
}, {
  Brand: 'ARAL',
  Street: 'Dekkers Waide 1',
  PostCode: 46419,
  City: 'Isselburg',
  Latitude: '518.183.410',
  Longitude: '64.426.880',
  Telephone: '287495672',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.818341,6.442688',
  id: '51.818341,6.442688'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 32',
  PostCode: 24217,
  City: 'Schönberg',
  Latitude: '543.887.880',
  Longitude: '103.731.990',
  Telephone: '4344411144',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '54.388788,10.373199',
  id: '54.388788,10.373199'
}, {
  Brand: 'ARAL',
  Street: 'Kurt-Schumacher-Straße155',
  PostCode: 45881,
  City: 'Gelsenkirchen',
  Latitude: '515.328.840',
  Longitude: '70.775.370',
  Telephone: '2099443052',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.532884,7.077537',
  id: '51.532884,7.077537'
}, {
  Brand: 'ARAL',
  Street: 'Ploener Landstraße 7',
  PostCode: 23701,
  City: 'Eutin',
  Latitude: '541.289.600',
  Longitude: '105.800.200',
  Telephone: '45214016302',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '54.12896,10.58002',
  id: '54.12896,10.58002'
}, {
  Brand: 'ARAL',
  Street: 'Victoriastraße 174',
  PostCode: 45772,
  City: 'Marl',
  Latitude: '516.656.410',
  Longitude: '71.506.520',
  Telephone: '2365415204',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.665641,7.150652',
  id: '51.665641,7.150652'
}, {
  Brand: 'ARAL',
  Street: 'Alte Bundesstraße 25',
  PostCode: 71332,
  City: 'Waiblingen',
  Latitude: '488.248.630',
  Longitude: '93.197.700',
  Telephone: '7151905014',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.824863,9.31977',
  id: '48.824863,9.31977'
}, {
  Brand: 'ARAL',
  Street: 'Nikolaus-Otto-Straße 7-9',
  PostCode: 65582,
  City: 'Diez',
  Latitude: '503.723.920',
  Longitude: '80.360.920',
  Telephone: '6432910915',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.372392,8.036092',
  id: '50.372392,8.036092'
}, {
  Brand: 'ARAL',
  Street: 'Neuwieder Straße 38-40',
  PostCode: 56269,
  City: 'Dierdorf',
  Latitude: '505.475.500',
  Longitude: '76.474.190',
  Telephone: '26892038',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.54755,7.647419',
  id: '50.54755,7.647419'
}, {
  Brand: 'ARAL',
  Street: 'Otto-Renner-Straße 1',
  PostCode: 89231,
  City: 'Neu-Ulm',
  Latitude: '483.864.720',
  Longitude: '100.357.430',
  Telephone: '7317253429',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.386472,10.035743',
  id: '48.386472,10.035743'
}, {
  Brand: 'ARAL',
  Street: 'Hoehenweg 23-25',
  PostCode: 46147,
  City: 'Oberhausen',
  Latitude: '515.483.810',
  Longitude: '68.511.530',
  Telephone: '208627088',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.548381,6.851153',
  id: '51.548381,6.851153'
}, {
  Brand: 'ARAL',
  Street: 'Forstmeisterstraße 53',
  PostCode: 97705,
  City: 'Burkardroth',
  Latitude: '502.631.280',
  Longitude: '99.943.780',
  Telephone: '97349293',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.263128,9.994378',
  id: '50.263128,9.994378'
}, {
  Brand: 'ARAL',
  Street: 'Schorndorfer Straße 49',
  PostCode: 73660,
  City: 'Urbach',
  Latitude: '488.091.260',
  Longitude: '95.705.300',
  Telephone: '7181981808',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.809126,9.57053',
  id: '48.809126,9.57053'
}, {
  Brand: 'ARAL',
  Street: 'Alleestraße 18',
  PostCode: 56410,
  City: 'Montabaur',
  Latitude: '504.401.100',
  Longitude: '78.303.850',
  Telephone: '2602917369',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.44011,7.830385',
  id: '50.44011,7.830385'
}, {
  Brand: 'ARAL',
  Street: 'Am Augarten 1',
  PostCode: 35435,
  City: 'Wettenberg',
  Latitude: '506.113.910',
  Longitude: '86.481.710',
  Telephone: '6419805045',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.611391,8.648171',
  id: '50.611391,8.648171'
}, {
  Brand: 'ARAL',
  Street: 'Fischbacher Straße 1',
  PostCode: 55743,
  City: 'Idar-Oberstein',
  Latitude: '497.389.780',
  Longitude: '74.080.380',
  Telephone: '678499800',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.738978,7.408038',
  id: '49.738978,7.408038'
}, {
  Brand: 'ARAL',
  Street: 'Osnabruecker Straße 36 A',
  PostCode: 49205,
  City: 'Hasbergen',
  Latitude: '522.368.500',
  Longitude: '79.628.400',
  Telephone: '54053525',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.23685,7.96284',
  id: '52.23685,7.96284'
}, {
  Brand: 'ARAL',
  Street: 'Waldstraße 48-56',
  PostCode: 68305,
  City: 'Mannheim',
  Latitude: '495.282.290',
  Longitude: '84.899.840',
  Telephone: '621751361',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.528229,8.489984',
  id: '49.528229,8.489984'
}, {
  Brand: 'ARAL',
  Street: 'Rue de Wattrelos 11',
  PostCode: 52249,
  City: 'Eschweiler',
  Latitude: '508.253.200',
  Longitude: '62.471.520',
  Telephone: '2403830005',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.82532,6.247152',
  id: '50.82532,6.247152'
}, {
  Brand: 'ARAL',
  Street: 'Barkhausenstraße 50',
  PostCode: 27568,
  City: 'Bremerhaven',
  Latitude: '535.518.780',
  Longitude: '85.684.980',
  Telephone: '4719413835',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.551878,8.568498',
  id: '53.551878,8.568498'
}, {
  Brand: 'ARAL',
  Street: 'Kappeler Straße 50',
  PostCode: 55481,
  City: 'Kirchberg',
  Latitude: '499.504.770',
  Longitude: '74.040.950',
  Telephone: '6763960706',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:30',
  SundayFrom: '05:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.950477,7.404095',
  id: '49.950477,7.404095'
}, {
  Brand: 'ARAL',
  Street: 'Industriestraße 43',
  PostCode: 47652,
  City: 'Weeze',
  Latitude: '516.201.770',
  Longitude: '62.099.680',
  Telephone: '2837962551',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.620177,6.209968',
  id: '51.620177,6.209968'
}, {
  Brand: 'ARAL',
  Street: 'Industriestraße 1',
  PostCode: 64546,
  City: 'Mörfelden',
  Latitude: '499.790.760',
  Longitude: '85.815.390',
  Telephone: '6105921766',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.979076,8.581539',
  id: '49.979076,8.581539'
}, {
  Brand: 'ARAL',
  Street: 'Koblenzer Straße 237',
  PostCode: 56154,
  City: 'Boppard',
  Latitude: '502.367.400',
  Longitude: '75.770.540',
  Telephone: '67422447',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.23674,7.577054',
  id: '50.23674,7.577054'
}, {
  Brand: 'ARAL',
  Street: 'Boeblinger Straße 69',
  PostCode: 71088,
  City: 'Holzgerlingen',
  Latitude: '486.456.360',
  Longitude: '90.097.760',
  Telephone: '7031605288',
  WeekDayFrom: '04:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '04:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '48.645636,9.009776',
  id: '48.645636,9.009776'
}, {
  Brand: 'ARAL',
  Street: 'Kirchmeierstraße 20',
  PostCode: 93051,
  City: 'Regensburg',
  Latitude: '490.110.240',
  Longitude: '120.712.350',
  Telephone: '9413998303',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.011024,12.071235',
  id: '49.011024,12.071235'
}, {
  Brand: 'ARAL',
  Street: 'Baerenbruch 135',
  PostCode: 44379,
  City: 'Dortmund',
  Latitude: '515.197.440',
  Longitude: '73.725.630',
  Telephone: '2316102302',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.519744,7.372563',
  id: '51.519744,7.372563'
}, {
  Brand: 'ARAL',
  Street: 'Schöneberger Str. / Alboi',
  PostCode: 12103,
  City: 'Berlin',
  Latitude: '524.700.260',
  Longitude: '133.710.430',
  Telephone: '3075446987',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.470026,13.371043',
  id: '52.470026,13.371043'
}, {
  Brand: 'ARAL',
  Street: 'Garmischer Straße 138',
  PostCode: 80686,
  City: 'München',
  Latitude: '481.271.500',
  Longitude: '115.222.330',
  Telephone: '8957868298',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.12715,11.522233',
  id: '48.12715,11.522233'
}, {
  Brand: 'ARAL',
  Street: 'Obernburger Straße 127-13',
  PostCode: 63811,
  City: 'Stockstadt',
  Latitude: '499.651.950',
  Longitude: '90.751.680',
  Telephone: '6027401675',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.965195,9.075168',
  id: '49.965195,9.075168'
}, {
  Brand: 'ARAL',
  Street: 'Rübhofstraße 27',
  PostCode: 27711,
  City: 'Osterholz-Scharmbeck',
  Latitude: '532.280.020',
  Longitude: '88.110.510',
  Telephone: '4791982119',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.228002,8.811051',
  id: '53.228002,8.811051'
}, {
  Brand: 'ARAL',
  Street: 'Radeburger Straße/Hellerhof',
  PostCode: 1129,
  City: 'Dresden',
  Latitude: '510.945.320',
  Longitude: '137.361.350',
  Telephone: '3518498840',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.094532,13.736135',
  id: '51.094532,13.736135'
}, {
  Brand: 'ARAL',
  Street: 'BAB24 Nordseite',
  PostCode: 16833,
  City: 'Linum',
  Latitude: '527.545.410',
  Longitude: '128.553.900',
  Telephone: '3392250538',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.754541,12.85539',
  id: '52.754541,12.85539'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Straße 197',
  PostCode: 16515,
  City: 'Oranienburg',
  Latitude: '527.283.380',
  Longitude: '132.462.810',
  Telephone: '3301530720',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.728338,13.246281',
  id: '52.728338,13.246281'
}, {
  Brand: 'ARAL',
  Street: 'Leinastraße 80',
  PostCode: 99867,
  City: 'Gotha',
  Latitude: '509.341.200',
  Longitude: '106.760.270',
  Telephone: '3621702636',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.93412,10.676027',
  id: '50.93412,10.676027'
}, {
  Brand: 'ARAL',
  Street: 'Dresdener Straße 49C',
  PostCode: 2625,
  City: 'Bautzen',
  Latitude: '511.792.290',
  Longitude: '144.083.960',
  Telephone: '3591301677',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.179229,14.408396',
  id: '51.179229,14.408396'
}, {
  Brand: 'ARAL',
  Street: 'Theodor-Koerner-Allee 2',
  PostCode: 2763,
  City: 'Zittau',
  Latitude: '508.938.530',
  Longitude: '148.018.100',
  Telephone: '3583510275',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.893853,14.80181',
  id: '50.893853,14.80181'
}, {
  Brand: 'ARAL',
  Street: 'Sonnenstraße 1',
  PostCode: 9648,
  City: 'Mittweida',
  Latitude: '509.934.010',
  Longitude: '129.655.520',
  Telephone: '372790602',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.993401,12.965552',
  id: '50.993401,12.965552'
}, {
  Brand: 'ARAL',
  Street: 'Buttstaedter Straße 50',
  PostCode: 99510,
  City: 'Apolda',
  Latitude: '510.400.820',
  Longitude: '115.112.370',
  Telephone: '3644555516',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.040082,11.511237',
  id: '51.040082,11.511237'
}, {
  Brand: 'ARAL',
  Street: 'Gelsenkirchener Allee 30',
  PostCode: 3050,
  City: 'Cottbus',
  Latitude: '517.265.830',
  Longitude: '143.361.330',
  Telephone: '355524021',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.726583,14.336133',
  id: '51.726583,14.336133'
}, {
  Brand: 'ARAL',
  Street: 'Rudolstaedter Straße 58',
  PostCode: 99099,
  City: 'Erfurt',
  Latitude: '509.690.680',
  Longitude: '110.612.600',
  Telephone: '3614211782',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '05:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.969068,11.06126',
  id: '50.969068,11.06126'
}, {
  Brand: 'ARAL',
  Street: 'Leipziger Str. 129',
  PostCode: 4425,
  City: 'Taucha',
  Latitude: '513.721.200',
  Longitude: '124.749.880',
  Telephone: '3429834595',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.37212,12.474988',
  id: '51.37212,12.474988'
}, {
  Brand: 'ARAL',
  Street: 'Jerichower Straße 24',
  PostCode: 39114,
  City: 'Magdeburg',
  Latitude: '521.319.390',
  Longitude: '116.651.820',
  Telephone: '3918110060',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.131939,11.665182',
  id: '52.131939,11.665182'
}, {
  Brand: 'ARAL',
  Street: 'Leipziger Straße 257',
  PostCode: 9114,
  City: 'Chemnitz',
  Latitude: '508.581.330',
  Longitude: '128.691.320',
  Telephone: '3713301680',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.858133,12.869132',
  id: '50.858133,12.869132'
}, {
  Brand: 'ARAL',
  Street: 'Merseburger Straße 355',
  PostCode: 6132,
  City: 'Halle',
  Latitude: '514.401.220',
  Longitude: '119.845.170',
  Telephone: '3457758492',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '51.440122,11.984517',
  id: '51.440122,11.984517'
}, {
  Brand: 'ARAL',
  Street: 'BAB24 Südseite',
  PostCode: 16833,
  City: 'Linum',
  Latitude: '527.533.240',
  Longitude: '128.526.290',
  Telephone: '3392250546',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.753324,12.852629',
  id: '52.753324,12.852629'
}, {
  Brand: 'ARAL',
  Street: 'Eberswalder Straße 37',
  PostCode: 16227,
  City: 'Eberswalde-Finow',
  Latitude: '528.390.570',
  Longitude: '137.546.000',
  Telephone: '3334352814',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.839057,13.7546',
  id: '52.839057,13.7546'
}, {
  Brand: 'ARAL',
  Street: 'Potsdamer Straße 22',
  PostCode: 14776,
  City: 'Brandenburg',
  Latitude: '524.061.070',
  Longitude: '125.759.590',
  Telephone: '3381524079',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.406107,12.575959',
  id: '52.406107,12.575959'
}, {
  Brand: 'ARAL',
  Street: 'Koburger Straße 52',
  PostCode: 4416,
  City: 'Markkleeberg',
  Latitude: '512.860.890',
  Longitude: '123.660.980',
  Telephone: '3413582448',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.286089,12.366098',
  id: '51.286089,12.366098'
}, {
  Brand: 'ARAL',
  Street: 'Krietzschwitzer Straße 7',
  PostCode: 1796,
  City: 'Pirna',
  Latitude: '509.578.010',
  Longitude: '139.494.560',
  Telephone: '3501761642',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.957801,13.949456',
  id: '50.957801,13.949456'
}, {
  Brand: 'ARAL',
  Street: 'Gerlachstraße 8',
  PostCode: 14480,
  City: 'Potsdam',
  Latitude: '523.678.520',
  Longitude: '131.285.700',
  Telephone: '331613452',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.367852,13.12857',
  id: '52.367852,13.12857'
}, {
  Brand: 'ARAL',
  Street: 'Nikolaus-Otto-Straße 1',
  PostCode: 6667,
  City: 'Weißenfels',
  Latitude: '511.882.400',
  Longitude: '119.891.250',
  Telephone: '3443302222',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.18824,11.989125',
  id: '51.18824,11.989125'
}, {
  Brand: 'ARAL',
  Street: 'Drewitzer Straße 20 A',
  PostCode: 14478,
  City: 'Potsdam',
  Latitude: '523.741.100',
  Longitude: '130.881.520',
  Telephone: '331864635',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.37411,13.088152',
  id: '52.37411,13.088152'
}, {
  Brand: 'ARAL',
  Street: 'Schnellerstraße 20',
  PostCode: 12439,
  City: 'Berlin',
  Latitude: '524.598.190',
  Longitude: '135.044.150',
  Telephone: '306316974',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.459819,13.504415',
  id: '52.459819,13.504415'
}, {
  Brand: 'ARAL',
  Street: 'Riestedter Straße 55',
  PostCode: 6526,
  City: 'Sangerhausen',
  Latitude: '514.803.740',
  Longitude: '113.178.810',
  Telephone: '3464572843',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.480374,11.317881',
  id: '51.480374,11.317881'
}, {
  Brand: 'ARAL',
  Street: 'Magdeburger Chaussee 7 A',
  PostCode: 38820,
  City: 'Halberstadt',
  Latitude: '519.010.650',
  Longitude: '110.739.880',
  Telephone: '3941600245',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.901065,11.073988',
  id: '51.901065,11.073988'
}, {
  Brand: 'ARAL',
  Street: 'Kötzschenbroder Str. 188',
  PostCode: 1139,
  City: 'Dresden',
  Latitude: '510.906.150',
  Longitude: '136.755.740',
  Telephone: '3518301510',
  WeekDayFrom: '05:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.090615,13.675574',
  id: '51.090615,13.675574'
}, {
  Brand: 'ARAL',
  Street: 'Neefestraße 145',
  PostCode: 9116,
  City: 'Chemnitz',
  Latitude: '508.164.500',
  Longitude: '128.829.900',
  Telephone: '3712806239',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.81645,12.88299',
  id: '50.81645,12.88299'
}, {
  Brand: 'ARAL',
  Street: 'Märkische Allee 248',
  PostCode: 12679,
  City: 'Berlin',
  Latitude: '525.537.320',
  Longitude: '135.515.870',
  Telephone: '309312140',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.553732,13.551587',
  id: '52.553732,13.551587'
}, {
  Brand: 'ARAL',
  Street: 'Nordpark 3',
  PostCode: 6237,
  City: 'Leuna OT Kötschlitz',
  Latitude: '513.476.090',
  Longitude: '121.752.120',
  Telephone: '3463820984',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.347609,12.175212',
  id: '51.347609,12.175212'
}, {
  Brand: 'ARAL',
  Street: 'Stadtrodaer Straße 11',
  PostCode: 7749,
  City: 'Jena',
  Latitude: '509.060.340',
  Longitude: '115.898.750',
  Telephone: '3641394893',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.906034,11.589875',
  id: '50.906034,11.589875'
}, {
  Brand: 'ARAL',
  Street: 'Ichtershaeuser Straße 84',
  PostCode: 99310,
  City: 'Arnstadt',
  Latitude: '508.567.690',
  Longitude: '109.589.260',
  Telephone: '362878340',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.856769,10.958926',
  id: '50.856769,10.958926'
}, {
  Brand: 'ARAL',
  Street: 'Dessauer Platz 4',
  PostCode: 6118,
  City: 'Halle',
  Latitude: '514.974.110',
  Longitude: '119.819.500',
  Telephone: '3452909012',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.497411,11.98195',
  id: '51.497411,11.98195'
}, {
  Brand: 'ARAL',
  Street: 'Eutritzscher Straße 26',
  PostCode: 4105,
  City: 'Leipzig',
  Latitude: '513.560.360',
  Longitude: '123.769.080',
  Telephone: '3415611177',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.356036,12.376908',
  id: '51.356036,12.376908'
}, {
  Brand: 'ARAL',
  Street: 'Tessiner Straße 68',
  PostCode: 18055,
  City: 'Rostock',
  Latitude: '540.811.970',
  Longitude: '121.943.070',
  Telephone: '381690324',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.081197,12.194307',
  id: '54.081197,12.194307'
}, {
  Brand: 'ARAL',
  Street: 'Marienthaler Straße 2',
  PostCode: 8060,
  City: 'Zwickau',
  Latitude: '507.206.280',
  Longitude: '124.730.560',
  Telephone: '375523928',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.720628,12.473056',
  id: '50.720628,12.473056'
}, {
  Brand: 'ARAL',
  Street: 'Junkersstraße/Weststraße',
  PostCode: 6847,
  City: 'Dessau',
  Latitude: '518.261.710',
  Longitude: '122.149.410',
  Telephone: '340517101',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.826171,12.214941',
  id: '51.826171,12.214941'
}, {
  Brand: 'ARAL',
  Street: 'BAB A10 Nordseite',
  PostCode: 15749,
  City: 'Brusendorf',
  Latitude: '523.184.540',
  Longitude: '134.983.190',
  Telephone: '3376421968',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.318454,13.498319',
  id: '52.318454,13.498319'
}, {
  Brand: 'ARAL',
  Street: 'BAB A10 Suedseite',
  PostCode: 15749,
  City: 'Brusendorf',
  Latitude: '523.156.230',
  Longitude: '134.937.130',
  Telephone: '3376424937',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.315623,13.493713',
  id: '52.315623,13.493713'
}, {
  Brand: 'ARAL',
  Street: 'Grossbeerenstraße 121',
  PostCode: 14482,
  City: 'Potsdam',
  Latitude: '523.860.850',
  Longitude: '131.066.550',
  Telephone: '3317482335',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.386085,13.106655',
  id: '52.386085,13.106655'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Allee 214',
  PostCode: 10365,
  City: 'Berlin',
  Latitude: '525.114.390',
  Longitude: '134.938.610',
  Telephone: '305578472',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.511439,13.493861',
  id: '52.511439,13.493861'
}, {
  Brand: 'ARAL',
  Street: 'Greifswalder Chaussee 62',
  PostCode: 18439,
  City: 'Stralsund',
  Latitude: '542.766.880',
  Longitude: '131.068.700',
  Telephone: '3831270126',
  WeekDayFrom: '05:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '54.276688,13.10687',
  id: '54.276688,13.10687'
}, {
  Brand: 'ARAL',
  Street: 'Schoenebecker Straße 74',
  PostCode: 39104,
  City: 'Magdeburg',
  Latitude: '520.991.750',
  Longitude: '116.477.690',
  Telephone: '3914015407',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.099175,11.647769',
  id: '52.099175,11.647769'
}, {
  Brand: 'ARAL',
  Street: 'Zeppelinstraße 2',
  PostCode: 12529,
  City: 'Schönefeld',
  Latitude: '523.678.120',
  Longitude: '135.607.660',
  Telephone: '3063311794',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.367812,13.560766',
  id: '52.367812,13.560766'
}, {
  Brand: 'ARAL',
  Street: 'Kasseler Straße 50',
  PostCode: 99817,
  City: 'Eisenach',
  Latitude: '509.811.210',
  Longitude: '103.021.720',
  Telephone: '369177024',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.981121,10.302172',
  id: '50.981121,10.302172'
}, {
  Brand: 'ARAL',
  Street: 'Alt-Friedrichsfelde 64',
  PostCode: 12683,
  City: 'Berlin',
  Latitude: '525.103.140',
  Longitude: '135.384.870',
  Telephone: '305133078',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.510314,13.538487',
  id: '52.510314,13.538487'
}, {
  Brand: 'ARAL',
  Street: 'Ziolkowskistraße 14',
  PostCode: 39126,
  City: 'Magdeburg',
  Latitude: '521.754.530',
  Longitude: '116.291.030',
  Telephone: '3912515281',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.175453,11.629103',
  id: '52.175453,11.629103'
}, {
  Brand: 'ARAL',
  Street: 'Großenhainer Straße 41',
  PostCode: 1662,
  City: 'Meißen',
  Latitude: '511.657.990',
  Longitude: '134.867.260',
  Telephone: '3521739910',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.165799,13.486726',
  id: '51.165799,13.486726'
}, {
  Brand: 'ARAL',
  Street: 'An der Wuhlheide 240-242',
  PostCode: 12459,
  City: 'Berlin',
  Latitude: '524.552.110',
  Longitude: '135.557.740',
  Telephone: '3053790502',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.455211,13.555774',
  id: '52.455211,13.555774'
}, {
  Brand: 'ARAL',
  Street: 'Landsberger Straße 116',
  PostCode: 4157,
  City: 'Leipzig',
  Latitude: '513.794.950',
  Longitude: '123.586.350',
  Telephone: '3419129891',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.379495,12.358635',
  id: '51.379495,12.358635'
}, {
  Brand: 'ARAL',
  Street: 'Volkmannstraße 19',
  PostCode: 6112,
  City: 'Halle',
  Latitude: '514.879.140',
  Longitude: '119.841.890',
  Telephone: '3455126523',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.487914,11.984189',
  id: '51.487914,11.984189'
}, {
  Brand: 'ARAL',
  Street: 'Groepelinger Heerstr. 237',
  PostCode: 28239,
  City: 'Bremen',
  Latitude: '531.182.190',
  Longitude: '87.568.860',
  Telephone: '421612980',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.118219,8.756886',
  id: '53.118219,8.756886'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Straße 170',
  PostCode: 45144,
  City: 'Essen',
  Latitude: '514.524.520',
  Longitude: '69.727.930',
  Telephone: '2018761280',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.452452,6.972793',
  id: '51.452452,6.972793'
}, {
  Brand: 'ARAL',
  Street: 'Siemensstraße 37',
  PostCode: 60594,
  City: 'Frankfurt',
  Latitude: '501.023.600',
  Longitude: '86.955.240',
  Telephone: '69622953',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.10236,8.695524',
  id: '50.10236,8.695524'
}, {
  Brand: 'ARAL',
  Street: 'Bundesstraße 150',
  PostCode: 52538,
  City: 'Gangelt',
  Latitude: '509.886.320',
  Longitude: '60.330.850',
  Telephone: '24547458',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.988632,6.033085',
  id: '50.988632,6.033085'
}, {
  Brand: 'ARAL',
  Street: 'Kaiserstraße 145',
  PostCode: 45699,
  City: 'Herten',
  Latitude: '515.992.080',
  Longitude: '71.482.330',
  Telephone: '236637188',
  WeekDayFrom: '04:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '04:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '51.599208,7.148233',
  id: '51.599208,7.148233'
}, {
  Brand: 'ARAL',
  Street: 'Saarbruecker Straße 51',
  PostCode: 66822,
  City: 'Lebach',
  Latitude: '494.025.460',
  Longitude: '69.163.480',
  Telephone: '68813253',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.402546,6.916348',
  id: '49.402546,6.916348'
}, {
  Brand: 'ARAL',
  Street: 'Ostlandstraße 65',
  PostCode: 59556,
  City: 'Lippstadt',
  Latitude: '517.054.430',
  Longitude: '83.404.920',
  Telephone: '294180139',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.705443,8.340492',
  id: '51.705443,8.340492'
}, {
  Brand: 'ARAL',
  Street: 'Luebbecker Straße 166',
  PostCode: 32584,
  City: 'Löhne',
  Latitude: '522.249.070',
  Longitude: '87.063.770',
  Telephone: '5732972802',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.224907,8.706377',
  id: '52.224907,8.706377'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 166',
  PostCode: 69242,
  City: 'Mühlhausen',
  Latitude: '492.480.490',
  Longitude: '87.355.460',
  Telephone: '622262546',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '21:00',
  SundayFrom: '07:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.248049,8.735546',
  id: '49.248049,8.735546'
}, {
  Brand: 'ARAL',
  Street: 'Grossenbaumer Straße 67',
  PostCode: 45481,
  City: 'Mülheim',
  Latitude: '514.095.120',
  Longitude: '68.539.480',
  Telephone: '208423854',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.409512,6.853948',
  id: '51.409512,6.853948'
}, {
  Brand: 'ARAL',
  Street: 'Niendorfer Straße 50',
  PostCode: 22848,
  City: 'Norderstedt',
  Latitude: '536.725.000',
  Longitude: '99.683.690',
  Telephone: '4052876152',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.6725,9.968369',
  id: '53.6725,9.968369'
}, {
  Brand: 'ARAL',
  Street: 'Kreuzwiese 1',
  PostCode: 67806,
  City: 'Rockenhausen',
  Latitude: '496.423.020',
  Longitude: '78.269.090',
  Telephone: '63619946820',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.642302,7.826909',
  id: '49.642302,7.826909'
}, {
  Brand: 'ARAL',
  Street: 'Mainzer Straße 97',
  PostCode: 66121,
  City: 'Saarbrücken',
  Latitude: '492.294.800',
  Longitude: '70.078.180',
  Telephone: '68161887',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.22948,7.007818',
  id: '49.22948,7.007818'
}, {
  Brand: 'ARAL',
  Street: 'Großblittersdorfer Straße 285',
  PostCode: 66119,
  City: 'Saarbrücken',
  Latitude: '491.952.400',
  Longitude: '70.235.900',
  Telephone: '681872270',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.19524,7.02359',
  id: '49.19524,7.02359'
}, {
  Brand: 'ARAL',
  Street: 'Heiligenbronner Straße 10',
  PostCode: 78713,
  City: 'Schramberg',
  Latitude: '482.289.110',
  Longitude: '84.214.620',
  Telephone: '74229921710',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.228911,8.421462',
  id: '48.228911,8.421462'
}, {
  Brand: 'ARAL',
  Street: 'Bremer Straße 55',
  PostCode: 27367,
  City: 'Sottrum',
  Latitude: '531.126.940',
  Longitude: '92.267.170',
  Telephone: '426499110',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '53.112694,9.226717',
  id: '53.112694,9.226717'
}, {
  Brand: 'ARAL',
  Street: 'A 1 / Westseite',
  PostCode: 22965,
  City: 'Todendorf-W',
  Latitude: '536.936.230',
  Longitude: '103.217.130',
  Telephone: '4534292005',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.693623,10.321713',
  id: '53.693623,10.321713'
}, {
  Brand: 'ARAL',
  Street: 'Kaltenweider Straße 10',
  PostCode: 30900,
  City: 'Wedemark',
  Latitude: '525.423.590',
  Longitude: '97.274.440',
  Telephone: '51303554',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '10:00',
  SundayTo: '18:00',
  IsOpen24Hours: 'Nein',
  location: '52.542359,9.727444',
  id: '52.542359,9.727444'
}, {
  Brand: 'ARAL',
  Street: 'Cranger Straße 243',
  PostCode: 45891,
  City: 'Gelsenkirchen',
  Latitude: '515.622.240',
  Longitude: '70.875.870',
  Telephone: '20975561',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.562224,7.087587',
  id: '51.562224,7.087587'
}, {
  Brand: 'ARAL',
  Street: 'Kirchheimer Straße 97',
  PostCode: 73230,
  City: 'Kirchheim',
  Latitude: '486.416.950',
  Longitude: '94.760.370',
  Telephone: '702181775',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.641695,9.476037',
  id: '48.641695,9.476037'
}, {
  Brand: 'ARAL',
  Street: 'Luetticher Straße 100',
  PostCode: 40547,
  City: 'Düsseldorf',
  Latitude: '512.405.220',
  Longitude: '67.438.530',
  Telephone: '211552322',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.240522,6.743853',
  id: '51.240522,6.743853'
}, {
  Brand: 'ARAL',
  Street: 'Schullendamm 51',
  PostCode: 49716,
  City: 'Meppen',
  Latitude: '526.916.590',
  Longitude: '72.753.800',
  Telephone: '593112459',
  WeekDayFrom: '05:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '52.691659,7.27538',
  id: '52.691659,7.27538'
}, {
  Brand: 'ARAL',
  Street: 'Moselstraße 1',
  PostCode: 56332,
  City: 'Löf',
  Latitude: '502.395.890',
  Longitude: '74.418.240',
  Telephone: '2605952325',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.239589,7.441824',
  id: '50.239589,7.441824'
}, {
  Brand: 'ARAL',
  Street: 'Trautenfeldstraße 38',
  PostCode: 68535,
  City: 'Edingen-Neckarhausen',
  Latitude: '494.452.870',
  Longitude: '85.803.300',
  Telephone: '621471739',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.445287,8.58033',
  id: '49.445287,8.58033'
}, {
  Brand: 'ARAL',
  Street: 'Albtalstraße 18',
  PostCode: 76359,
  City: 'Marxzell',
  Latitude: '488.638.180',
  Longitude: '84.478.640',
  Telephone: '72486939',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.863818,8.447864',
  id: '48.863818,8.447864'
}, {
  Brand: 'ARAL',
  Street: 'Darmstaedter Landstr. 63',
  PostCode: 64331,
  City: 'Weiterstadt',
  Latitude: '499.264.790',
  Longitude: '86.026.130',
  Telephone: '61505457360',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.926479,8.602613',
  id: '49.926479,8.602613'
}, {
  Brand: 'ARAL',
  Street: 'Luebberstedter Straße 36',
  PostCode: 21272,
  City: 'Egestorf',
  Latitude: '531.940.460',
  Longitude: '100.721.260',
  Telephone: '4175587',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '53.194046,10.072126',
  id: '53.194046,10.072126'
}, {
  Brand: 'ARAL',
  Street: 'Burgstraße 2',
  PostCode: 26736,
  City: 'Krummhörn',
  Latitude: '534.361.150',
  Longitude: '70.933.330',
  Telephone: '4923990108',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.436115,7.093333',
  id: '53.436115,7.093333'
}, {
  Brand: 'ARAL',
  Street: 'Lange Straße 32',
  PostCode: 26935,
  City: 'Stadland-Rodenkirche',
  Latitude: '534.057.340',
  Longitude: '84.597.580',
  Telephone: '47322663',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '53.405734,8.459758',
  id: '53.405734,8.459758'
}, {
  Brand: 'ARAL',
  Street: 'Bremer Straße 34',
  PostCode: 34388,
  City: 'Trendelburg',
  Latitude: '515.907.210',
  Longitude: '94.090.880',
  Telephone: '5675725292',
  WeekDayFrom: '08:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.590721,9.409088',
  id: '51.590721,9.409088'
}, {
  Brand: 'ARAL',
  Street: 'Schuelperstraße 27',
  PostCode: 25764,
  City: 'Wesselburen',
  Latitude: '542.143.610',
  Longitude: '89.234.170',
  Telephone: '48332373',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '05:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '54.214361,8.923417',
  id: '54.214361,8.923417'
}, {
  Brand: 'ARAL',
  Street: 'Würzburger Straße 190',
  PostCode: 63743,
  City: 'Aschaffenburg',
  Latitude: '499.614.640',
  Longitude: '91.779.720',
  Telephone: '60218629290',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.961464,9.177972',
  id: '49.961464,9.177972'
}, {
  Brand: 'ARAL',
  Street: 'Ulmer Straße 33',
  PostCode: 89547,
  City: 'Gerstetten',
  Latitude: '486.174.950',
  Longitude: '100.247.070',
  Telephone: '732396240',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '09:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '48.617495,10.024707',
  id: '48.617495,10.024707'
}, {
  Brand: 'ARAL',
  Street: 'Friedrichstraße 23',
  PostCode: 89568,
  City: 'Hermaringen',
  Latitude: '485.976.070',
  Longitude: '102.562.530',
  Telephone: '7322919572',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.597607,10.256253',
  id: '48.597607,10.256253'
}, {
  Brand: 'ARAL',
  Street: 'Soegeler Straße 7',
  PostCode: 49762,
  City: 'Lathen',
  Latitude: '528.608.820',
  Longitude: '73.285.030',
  Telephone: '59331341',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.860882,7.328503',
  id: '52.860882,7.328503'
}, {
  Brand: 'ARAL',
  Street: 'Schwetzinger Straße 4',
  PostCode: 69214,
  City: 'Eppelheim',
  Latitude: '493.991.300',
  Longitude: '86.247.070',
  Telephone: '6221763291',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.39913,8.624707',
  id: '49.39913,8.624707'
}, {
  Brand: 'ARAL',
  Street: 'Blumenstraße 39',
  PostCode: 71106,
  City: 'Magstadt',
  Latitude: '487.448.290',
  Longitude: '89.711.710',
  Telephone: '7159949619',
  WeekDayFrom: '07:00',
  WeekDayTo: '18:30',
  SaturdayFrom: '08:00',
  SaturdayTo: '14:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.744829,8.971171',
  id: '48.744829,8.971171'
}, {
  Brand: 'ARAL',
  Street: 'Karnaper Straße 44',
  PostCode: 45329,
  City: 'Essen',
  Latitude: '515.173.700',
  Longitude: '70.075.580',
  Telephone: '2014366875',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.51737,7.007558',
  id: '51.51737,7.007558'
}, {
  Brand: 'ARAL',
  Street: 'Wittener Straße 68',
  PostCode: 45549,
  City: 'Sprockhövel',
  Latitude: '513.403.920',
  Longitude: '72.897.350',
  Telephone: '23392796',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.340392,7.289735',
  id: '51.340392,7.289735'
}, {
  Brand: 'ARAL',
  Street: 'Aachener Straße 203-209',
  PostCode: 50931,
  City: 'Köln',
  Latitude: '509.363.680',
  Longitude: '69.223.410',
  Telephone: '2219402524',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.936368,6.922341',
  id: '50.936368,6.922341'
}, {
  Brand: 'ARAL',
  Street: 'Homburger Landstraße 42',
  PostCode: 60435,
  City: 'Frankfurt',
  Latitude: '501.429.780',
  Longitude: '86.964.520',
  Telephone: '69541237',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:30',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:30',
  SundayFrom: '08:00',
  SundayTo: '20:30',
  IsOpen24Hours: 'Nein',
  location: '50.142978,8.696452',
  id: '50.142978,8.696452'
}, {
  Brand: 'ARAL',
  Street: 'Hofheimer Straße 24',
  PostCode: 65439,
  City: 'Flörsheim',
  Latitude: '500.477.550',
  Longitude: '84.344.820',
  Telephone: '614531103',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.047755,8.434482',
  id: '50.047755,8.434482'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 10',
  PostCode: 69226,
  City: 'Nußloch',
  Latitude: '493.293.010',
  Longitude: '86.933.370',
  Telephone: '622410320',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.329301,8.693337',
  id: '49.329301,8.693337'
}, {
  Brand: 'ARAL',
  Street: 'Silberburgstraße 21-23',
  PostCode: 72764,
  City: 'Reutlingen',
  Latitude: '484.983.420',
  Longitude: '92.165.590',
  Telephone: '712117691',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '17:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.498342,9.216559',
  id: '48.498342,9.216559'
}, {
  Brand: 'ARAL',
  Street: 'Dubelohstraße 143',
  PostCode: 33104,
  City: 'Paderborn',
  Latitude: '517.456.790',
  Longitude: '87.312.830',
  Telephone: '5254939178',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.745679,8.731283',
  id: '51.745679,8.731283'
}, {
  Brand: 'ARAL',
  Street: 'B 49/Nordseite',
  PostCode: 65614,
  City: 'Beselich',
  Latitude: '504.691.200',
  Longitude: '81.372.420',
  Telephone: '6484890071',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.46912,8.137242',
  id: '50.46912,8.137242'
}, {
  Brand: 'ARAL',
  Street: 'Werner-von-Siemens-Straße',
  PostCode: 25337,
  City: 'Elmshorn',
  Latitude: '537.455.240',
  Longitude: '97.057.940',
  Telephone: '41215796687',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.745524,9.705794',
  id: '53.745524,9.705794'
}, {
  Brand: 'ARAL',
  Street: 'Kölnstraße 272',
  PostCode: 50321,
  City: 'Brühl',
  Latitude: '508.453.040',
  Longitude: '69.155.690',
  Telephone: '2232411019',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.845304,6.915569',
  id: '50.845304,6.915569'
}, {
  Brand: 'ARAL',
  Street: 'A 5',
  PostCode: 79576,
  City: 'Weil am Rhein',
  Latitude: '476.002.070',
  Longitude: '76.030.250',
  Telephone: '762116361980',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.600207,7.603025',
  id: '47.600207,7.603025'
}, {
  Brand: 'ARAL',
  Street: 'Industriestraße 2',
  PostCode: 84326,
  City: 'Falkenberg',
  Latitude: '484.620.980',
  Longitude: '127.232.910',
  Telephone: '8727969200',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.462098,12.723291',
  id: '48.462098,12.723291'
}, {
  Brand: 'ARAL',
  Street: 'Dresdner Straße 36',
  PostCode: 1936,
  City: 'Königsbrück',
  Latitude: '512.577.010',
  Longitude: '138.914.250',
  Telephone: '3579536372',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.257701,13.891425',
  id: '51.257701,13.891425'
}, {
  Brand: 'ARAL',
  Street: 'Herner Straße 403',
  PostCode: 44807,
  City: 'Bochum',
  Latitude: '515.125.460',
  Longitude: '72.101.510',
  Telephone: '234532070',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.512546,7.210151',
  id: '51.512546,7.210151'
}, {
  Brand: 'ARAL',
  Street: 'A 60',
  PostCode: 55262,
  City: 'Heidesheim',
  Latitude: '500.023.910',
  Longitude: '81.092.590',
  Telephone: '613256539',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.002391,8.109259',
  id: '50.002391,8.109259'
}, {
  Brand: 'ARAL',
  Street: 'Mainzer Straße 116a',
  PostCode: 64572,
  City: 'Büttelborn',
  Latitude: '499.065.510',
  Longitude: '85.030.330',
  Telephone: '61529879262',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.906551,8.503033',
  id: '49.906551,8.503033'
}, {
  Brand: 'ARAL',
  Street: 'Westring 19',
  PostCode: 24850,
  City: 'Schuby',
  Latitude: '545.188.520',
  Longitude: '94.647.480',
  Telephone: '4621949406',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.518852,9.464748',
  id: '54.518852,9.464748'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 263',
  PostCode: 89343,
  City: 'Jettingen-Scheppach',
  Latitude: '484.094.520',
  Longitude: '104.447.410',
  Telephone: '8225959090',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.409452,10.444741',
  id: '48.409452,10.444741'
}, {
  Brand: 'ARAL',
  Street: 'Kiewer Straße 31',
  PostCode: 4205,
  City: 'Leipzig',
  Latitude: '513.202.270',
  Longitude: '122.765.730',
  Telephone: '3414229713',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.320227,12.276573',
  id: '51.320227,12.276573'
}, {
  Brand: 'ARAL',
  Street: 'Zeppelinstraße 2',
  PostCode: 91187,
  City: 'Röttenbach',
  Latitude: '491.574.150',
  Longitude: '110.411.530',
  Telephone: '91726859440',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.157415,11.041153',
  id: '49.157415,11.041153'
}, {
  Brand: 'ARAL',
  Street: 'Ulmer Straße 50',
  PostCode: 89269,
  City: 'Vöhringen',
  Latitude: '482.833.590',
  Longitude: '100.780.620',
  Telephone: '73069533212',
  WeekDayFrom: '06:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.283359,10.078062',
  id: '48.283359,10.078062'
}, {
  Brand: 'ARAL',
  Street: 'Fritzlarer Straße 39',
  PostCode: 34281,
  City: 'Gudensberg',
  Latitude: '511.760.500',
  Longitude: '93.563.930',
  Telephone: '56032302',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '18:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.17605,9.356393',
  id: '51.17605,9.356393'
}, {
  Brand: 'ARAL',
  Street: 'Römerallee 78',
  PostCode: 53909,
  City: 'Zülpich',
  Latitude: '507.036.440',
  Longitude: '66.666.990',
  Telephone: '22528306140',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.703644,6.666699',
  id: '50.703644,6.666699'
}, {
  Brand: 'ARAL',
  Street: 'Hedelfinger Straße 25 A',
  PostCode: 70327,
  City: 'Stuttgart',
  Latitude: '487.695.260',
  Longitude: '92.481.990',
  Telephone: '7114077791',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.769526,9.248199',
  id: '48.769526,9.248199'
}, {
  Brand: 'ARAL',
  Street: 'Stuttgarter Straße 80',
  PostCode: 73033,
  City: 'Göppingen',
  Latitude: '487.089.730',
  Longitude: '96.339.050',
  Telephone: '7161922495',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.708973,9.633905',
  id: '48.708973,9.633905'
}, {
  Brand: 'ARAL',
  Street: 'Würzburger Straße 1',
  PostCode: 97230,
  City: 'Estenfeld',
  Latitude: '498.286.860',
  Longitude: '99.993.430',
  Telephone: '9305218',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.828686,9.999343',
  id: '49.828686,9.999343'
}, {
  Brand: 'ARAL',
  Street: 'Grifter Straße 2',
  PostCode: 34302,
  City: 'Guxhagen',
  Latitude: '512.030.500',
  Longitude: '94.685.900',
  Telephone: '5665800070',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.20305,9.46859',
  id: '51.20305,9.46859'
}, {
  Brand: 'ARAL',
  Street: 'Vollmerhauser Straße 123',
  PostCode: 51645,
  City: 'Gummersbach',
  Latitude: '509.852.450',
  Longitude: '75.440.640',
  Telephone: '226177556',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.985245,7.544064',
  id: '50.985245,7.544064'
}, {
  Brand: 'ARAL',
  Street: 'Ovelgönne 20',
  PostCode: 49356,
  City: 'Diepholz',
  Latitude: '526.182.990',
  Longitude: '83.653.180',
  Telephone: '5441929980',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.618299,8.365318',
  id: '52.618299,8.365318'
}, {
  Brand: 'ARAL',
  Street: 'Landsberger Straße 423-425',
  PostCode: 81241,
  City: 'München',
  Latitude: '481.451.100',
  Longitude: '114.786.150',
  Telephone: '8982072936',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '48.14511,11.478615',
  id: '48.14511,11.478615'
}, {
  Brand: 'ARAL',
  Street: 'Berghauptener Straße 21b',
  PostCode: 77723,
  City: 'Gengenbach',
  Latitude: '483.997.320',
  Longitude: '80.078.180',
  Telephone: '78037759',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.399732,8.007818',
  id: '48.399732,8.007818'
}, {
  Brand: 'ARAL',
  Street: 'Zeilstraße 27',
  PostCode: 72793,
  City: 'Pfullingen',
  Latitude: '484.732.600',
  Longitude: '92.335.110',
  Telephone: '7121312580',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.47326,9.233511',
  id: '48.47326,9.233511'
}, {
  Brand: 'ARAL',
  Street: 'an der B 413',
  PostCode: 56269,
  City: 'Dierdorf',
  Latitude: '505.493.350',
  Longitude: '76.563.070',
  Telephone: '26893558',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.549335,7.656307',
  id: '50.549335,7.656307'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 14',
  PostCode: 51588,
  City: 'Nümbrecht',
  Latitude: '509.062.300',
  Longitude: '75.425.810',
  Telephone: '22937380',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.90623,7.542581',
  id: '50.90623,7.542581'
}, {
  Brand: 'ARAL',
  Street: 'A 1',
  PostCode: 27367,
  City: 'Sottrum',
  Latitude: '530.901.090',
  Longitude: '91.796.230',
  Telephone: '4205491',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.090109,9.179623',
  id: '53.090109,9.179623'
}, {
  Brand: 'ARAL',
  Street: 'Am Knückel 1',
  PostCode: 63843,
  City: 'Niedernberg',
  Latitude: '499.145.530',
  Longitude: '91.217.420',
  Telephone: '60283078950',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.914553,9.121742',
  id: '49.914553,9.121742'
}, {
  Brand: 'ARAL',
  Street: 'Max-Pollin-Strasse 2',
  PostCode: 85104,
  City: 'Pförring',
  Latitude: '488.167.670',
  Longitude: '116.935.720',
  Telephone: '8403927825',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '48.816767,11.693572',
  id: '48.816767,11.693572'
}, {
  Brand: 'ARAL',
  Street: 'Aurea 1-3',
  PostCode: 33378,
  City: 'Rheda-Wiedenbrück',
  Latitude: '518.312.320',
  Longitude: '82.184.660',
  Telephone: '25228343732',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.831232,8.218466',
  id: '51.831232,8.218466'
}, {
  Brand: 'ARAL',
  Street: 'Stolberger Straße 64',
  PostCode: 52249,
  City: 'Eschweiler',
  Latitude: '507.990.150',
  Longitude: '62.362.510',
  Telephone: '2403504360',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.799015,6.236251',
  id: '50.799015,6.236251'
}, {
  Brand: 'ARAL',
  Street: 'Landsberger Straße 2',
  PostCode: 82110,
  City: 'Germering',
  Latitude: '481.368.070',
  Longitude: '113.909.120',
  Telephone: '89847250',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.136807,11.390912',
  id: '48.136807,11.390912'
}, {
  Brand: 'ARAL',
  Street: 'Münchener Straße 16',
  PostCode: 82319,
  City: 'Starnberg',
  Latitude: '480.013.550',
  Longitude: '113.497.640',
  Telephone: '815116785',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '48.001355,11.349764',
  id: '48.001355,11.349764'
}, {
  Brand: 'ARAL',
  Street: 'Overhagener Straße 78',
  PostCode: 59557,
  City: 'Lippstadt',
  Latitude: '516.613.990',
  Longitude: '83.380.590',
  Telephone: '294110515',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.661399,8.338059',
  id: '51.661399,8.338059'
}, {
  Brand: 'ARAL',
  Street: 'Gressenicher Straße 85',
  PostCode: 52224,
  City: 'Stolberg',
  Latitude: '507.613.430',
  Longitude: '62.828.330',
  Telephone: '2402764757',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.761343,6.282833',
  id: '50.761343,6.282833'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 4',
  PostCode: 89143,
  City: 'Blaubeuren',
  Latitude: '484.001.890',
  Longitude: '97.969.130',
  Telephone: '73449528412',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.400189,9.796913',
  id: '48.400189,9.796913'
}, {
  Brand: 'ARAL',
  Street: 'Konrad-Adenauer-Straße 25',
  PostCode: 35745,
  City: 'Herborn',
  Latitude: '506.753.340',
  Longitude: '83.065.220',
  Telephone: '2772957192',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.675334,8.306522',
  id: '50.675334,8.306522'
}, {
  Brand: 'ARAL',
  Street: 'Schlossstraße 1',
  PostCode: 57537,
  City: 'Wissen',
  Latitude: '507.814.790',
  Longitude: '77.422.020',
  Telephone: '27422101',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.781479,7.742202',
  id: '50.781479,7.742202'
}, {
  Brand: 'ARAL',
  Street: 'Graf-Heinrich-Straße 18',
  PostCode: 57627,
  City: 'Hachenburg',
  Latitude: '506.637.240',
  Longitude: '78.180.360',
  Telephone: '26624455',
  WeekDayFrom: '04:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.663724,7.818036',
  id: '50.663724,7.818036'
}, {
  Brand: 'ARAL',
  Street: 'Triebstraße 11e',
  PostCode: 80993,
  City: 'München',
  Latitude: '481.876.340',
  Longitude: '115.308.510',
  Telephone: '891415222',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.187634,11.530851',
  id: '48.187634,11.530851'
}, {
  Brand: 'ARAL',
  Street: 'Gottlieb-Braun-Straße 33 / B 295',
  PostCode: 75382,
  City: 'Althengstett',
  Latitude: '487.267.510',
  Longitude: '87.861.090',
  Telephone: '7051922290',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.726751,8.786109',
  id: '48.726751,8.786109'
}, {
  Brand: 'Eni',
  Street: 'Vilsecker Str. 2',
  PostCode: 93057,
  City: 'Regensburg',
  Latitude: '4.901.643.000',
  Longitude: '1.210.176.000',
  Telephone: '0941/46705847',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.01643,12.10176',
  id: '49.01643,12.10176'
}, {
  Brand: 'Eni',
  Street: 'Umgehungsstrasse 65',
  PostCode: 71144,
  City: 'Steinenbronn',
  Latitude: '4.866.626.000',
  Longitude: '912.409.000',
  Telephone: '+49 (0) 71572750',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.66626,9.12409',
  id: '48.66626,9.12409'
}, {
  Brand: 'OMV',
  Street: 'Hauptstr. 156',
  PostCode: 77830,
  City: 'Bühlertal',
  Latitude: '4.867.975.000',
  Longitude: '819.530.000',
  Telephone: '+49-7223-72592',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.67975,8.1953',
  id: '48.67975,8.1953'
}, {
  Brand: 'ARAL',
  Street: 'August-Horch-Straße 1-3',
  PostCode: 56076,
  City: 'Koblenz',
  Latitude: '503.892.760',
  Longitude: '75.664.030',
  Telephone: '26198859626',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.389276,7.566403',
  id: '50.389276,7.566403'
}, {
  Brand: 'Westfalen',
  Street: 'Weseler Str. 269-271',
  PostCode: null,
  City: 'MUENSTER',
  Latitude: '51.94',
  Longitude: '760.893',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.94,7.60893',
  id: '51.94,7.60893'
}, {
  Brand: 'Westfalen',
  Street: 'Badenstedter Str. 145',
  PostCode: null,
  City: 'HANNOVER',
  Latitude: '52.359',
  Longitude: '967.777',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.359,9.67777',
  id: '52.359,9.67777'
}, {
  Brand: 'Westfalen',
  Street: 'Wilhelm-Hess-Str. 25',
  PostCode: null,
  City: 'BARSINGHAUSEN',
  Latitude: '523.074',
  Longitude: '945.226',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.3074,9.45226',
  id: '52.3074,9.45226'
}, {
  Brand: 'Eni',
  Street: 'Siemensstrasse 1',
  PostCode: 35460,
  City: 'Staufenberg',
  Latitude: '5.065.500.224',
  Longitude: '873.271.060',
  Telephone: '+49 (0) 64037785',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.65500224,8.7327106',
  id: '50.65500224,8.7327106'
}, {
  Brand: 'OMV',
  Street: 'Schönberger Straße 8',
  PostCode: 94513,
  City: 'Schönberg-Eberhardsreuth',
  Latitude: '4.881.645.000',
  Longitude: '1.335.750.000',
  Telephone: '+49-173-5311497',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.81645,13.3575',
  id: '48.81645,13.3575'
}, {
  Brand: 'Eni',
  Street: 'Friedrich-August-Str. 22',
  PostCode: 55765,
  City: 'Birkenfeld',
  Latitude: '4.964.907.000',
  Longitude: '716.119.000',
  Telephone: '+49 (0) 67825529',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.64907,7.16119',
  id: '49.64907,7.16119'
}, {
  Brand: 'ARAL',
  Street: 'Schehnberger Weg 11',
  PostCode: 26203,
  City: 'Wardenburg',
  Latitude: '530.505.240',
  Longitude: '82.113.010',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.050524,8.211301',
  id: '53.050524,8.211301'
}, {
  Brand: 'ARAL',
  Street: 'Ulmer Straße 19',
  PostCode: 89257,
  City: 'Illertissen',
  Latitude: '482.250.600',
  Longitude: '101.026.340',
  Telephone: '730396170',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.22506,10.102634',
  id: '48.22506,10.102634'
}, {
  Brand: 'Eni',
  Street: 'Muenchner Strasse 54',
  PostCode: 82131,
  City: 'Gauting',
  Latitude: '4.806.895.850',
  Longitude: '1.138.861.737',
  Telephone: '+49 (0) 89850512',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.0689585,11.38861737',
  id: '48.0689585,11.38861737'
}, {
  Brand: 'TOTAL',
  Street: 'PADERBORNER TOR 164',
  PostCode: 34414,
  City: 'WARBURG',
  Latitude: '51.492.236',
  Longitude: '9.130.931',
  Telephone: '05641-8530',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.492236,9.130931',
  id: '51.492236,9.130931'
}, {
  Brand: 'Westfalen',
  Street: 'Alexanderstr. 420',
  PostCode: null,
  City: 'OLDENBURG',
  Latitude: '531.752',
  Longitude: '819.138',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.1752,8.19138',
  id: '53.1752,8.19138'
}, {
  Brand: 'OMV',
  Street: 'Marktstr. 32',
  PostCode: 97355,
  City: 'Rüdenhausen',
  Latitude: '4.976.811.000',
  Longitude: '1.034.176.000',
  Telephone: '+49-9383-1232',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.76811,10.34176',
  id: '49.76811,10.34176'
}, {
  Brand: 'Eni',
  Street: 'Julius-Echter-Str. 26',
  PostCode: 97450,
  City: 'Arnstein-Heugrumbach',
  Latitude: '4.997.848.000',
  Longitude: '995.374.000',
  Telephone: '+49 (0) 93631212',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.97848,9.95374',
  id: '49.97848,9.95374'
}, {
  Brand: 'OMV',
  Street: 'Esslinger Str. 136',
  PostCode: 70734,
  City: 'Fellbach',
  Latitude: '4.880.452.000',
  Longitude: '926.896.000',
  Telephone: '+49-711-589637',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.80452,9.26896',
  id: '48.80452,9.26896'
}, {
  Brand: 'TOTAL',
  Street: 'RUPPERTSWIES 6',
  PostCode: 85092,
  City: 'KOESCHING',
  Latitude: '48.811.141',
  Longitude: '11.479.088',
  Telephone: '08456-9698530',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.811141,11.479088',
  id: '48.811141,11.479088'
}, {
  Brand: 'ARAL',
  Street: 'Heinestr. 10-12',
  PostCode: 48703,
  City: 'Stadtlohn',
  Latitude: '519.928.010',
  Longitude: '68.896.090',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.992801,6.889609',
  id: '51.992801,6.889609'
}, {
  Brand: 'TOTAL',
  Street: 'PLAUER CHAUSSEE 26',
  PostCode: 18292,
  City: 'KRAKOW AM SEE',
  Latitude: '53.647.913',
  Longitude: '12.265.485',
  Telephone: '038457-24645',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.647913,12.265485',
  id: '53.647913,12.265485'
}, {
  Brand: 'TOTAL',
  Street: 'JOSEF-NEUMEIER-STR. 1',
  PostCode: 85664,
  City: 'HOHENLINDEN',
  Latitude: '48.155.687',
  Longitude: '11.986.555',
  Telephone: '08124/444842',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.155687,11.986555',
  id: '48.155687,11.986555'
}, {
  Brand: 'ARAL',
  Street: 'Rostockerstr.',
  PostCode: 14641,
  City: 'Wustermark',
  Latitude: '525.601.120',
  Longitude: '129.708.500',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.560112,12.97085',
  id: '52.560112,12.97085'
}, {
  Brand: 'ARAL',
  Street: 'Haunstetter Straße 75',
  PostCode: 86161,
  City: 'Augsburg',
  Latitude: '483.462.770',
  Longitude: '109.053.440',
  Telephone: '8216609314',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.346277,10.905344',
  id: '48.346277,10.905344'
}, {
  Brand: 'ARAL',
  Street: 'Hindenburgstraße 41',
  PostCode: 89129,
  City: 'Langenau',
  Latitude: '484.956.470',
  Longitude: '101.174.450',
  Telephone: '73457150',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.495647,10.117445',
  id: '48.495647,10.117445'
}, {
  Brand: 'ARAL',
  Street: 'A 2',
  PostCode: 33719,
  City: 'Bielefeld',
  Latitude: '520.099.590',
  Longitude: '86.299.970',
  Telephone: '52089592215',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.009959,8.629997',
  id: '52.009959,8.629997'
}, {
  Brand: 'Eni',
  Street: 'Oberwinkl 4',
  PostCode: 83355,
  City: 'Grabenstaett',
  Latitude: '4.782.913.000',
  Longitude: '1.252.636.000',
  Telephone: '08661/982510',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '47.82913,12.52636',
  id: '47.82913,12.52636'
}, {
  Brand: 'Westfalen',
  Street: 'Coesfelder Str. 182',
  PostCode: null,
  City: 'DUELMEN',
  Latitude: '518.396',
  Longitude: '726.597',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8396,7.26597',
  id: '51.8396,7.26597'
}, {
  Brand: 'Westfalen',
  Street: 'Hauptstr. 63',
  PostCode: null,
  City: 'HASTE',
  Latitude: '523.839',
  Longitude: '939.574',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.3839,9.39574',
  id: '52.3839,9.39574'
}, {
  Brand: 'TOTAL',
  Street: 'HORSTHAUSER STR.213',
  PostCode: 44628,
  City: 'HERNE',
  Latitude: '51.560.818',
  Longitude: '7.231.214',
  Telephone: '02323-8603',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.560818,7.231214',
  id: '51.560818,7.231214'
}, {
  Brand: 'OMV',
  Street: 'Erlanger Str. 56',
  PostCode: 91074,
  City: 'Herzogenaurach',
  Latitude: '4.956.648.000',
  Longitude: '1.089.352.000',
  Telephone: '+49-9132-9343',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.56648,10.89352',
  id: '49.56648,10.89352'
}, {
  Brand: 'Eni',
  Street: 'Stuttgarter Strasse 92',
  PostCode: 75365,
  City: 'Calw',
  Latitude: '4.871.118.239',
  Longitude: '875.631.913',
  Telephone: '+49 (0) 70513027',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.71118239,8.75631913',
  id: '48.71118239,8.75631913'
}, {
  Brand: 'Westfalen',
  Street: 'Darfelder Str. 14',
  PostCode: null,
  City: 'BILLERBECK',
  Latitude: '519.825',
  Longitude: '729.368',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9825,7.29368',
  id: '51.9825,7.29368'
}, {
  Brand: 'TOTAL',
  Street: 'KORNWEG 1 / L1140',
  PostCode: 71409,
  City: 'SCHWAIKHEIM',
  Latitude: '48.874.866',
  Longitude: '9.343.257',
  Telephone: '07195-135491',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.874866,9.343257',
  id: '48.874866,9.343257'
}, {
  Brand: 'ARAL',
  Street: 'Berchtesgardener Straße 2',
  PostCode: 83435,
  City: 'Bad Reichenhall',
  Latitude: '477.176.010',
  Longitude: '128.752.010',
  Telephone: '865167220',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '47.717601,12.875201',
  id: '47.717601,12.875201'
}, {
  Brand: 'OMV',
  Street: 'In der Vorstadt 84',
  PostCode: 73529,
  City: 'Schwäbisch Gmünd',
  Latitude: '4.878.925.000',
  Longitude: '984.067.000',
  Telephone: '+49-7171-82519',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.78925,9.84067',
  id: '48.78925,9.84067'
}, {
  Brand: 'ARAL',
  Street: 'Geisental',
  PostCode: 44791,
  City: 'Bochum',
  Latitude: '514.977.120',
  Longitude: '72.606.800',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.497712,7.26068',
  id: '51.497712,7.26068'
}, {
  Brand: 'TOTAL',
  Street: 'AUTOHOF ELKERSBERG 2',
  PostCode: 57234,
  City: 'WILNSDORF',
  Latitude: '5.081.473',
  Longitude: '809.665',
  Telephone: '02739-40339-0',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.81473,8.09665',
  id: '50.81473,8.09665'
}, {
  Brand: 'ARAL',
  Street: 'Giessener Straße 10',
  PostCode: 35325,
  City: 'Mücke',
  Latitude: '506.130.420',
  Longitude: '90.273.330',
  Telephone: '6400951223',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:30',
  SaturdayTo: '23:59',
  SundayFrom: '08:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '50.613042,9.027333',
  id: '50.613042,9.027333'
}, {
  Brand: 'TOTAL',
  Street: 'MARXZELLER STR. 90',
  PostCode: 75305,
  City: 'NEUENBUERG',
  Latitude: '48.852.942',
  Longitude: '8.582.141',
  Telephone: '07082-413024',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.852942,8.582141',
  id: '48.852942,8.582141'
}, {
  Brand: 'TOTAL',
  Street: 'KLINKENBERG 28',
  PostCode: 17126,
  City: 'JARMEN',
  Latitude: '53.921.807',
  Longitude: '13.347.471',
  Telephone: '039997-10404',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.921807,13.347471',
  id: '53.921807,13.347471'
}, {
  Brand: 'TOTAL',
  Street: 'DUESSELDORFER STR.51',
  PostCode: 47239,
  City: 'DUISBURG',
  Latitude: '51.396.448',
  Longitude: '6.657.844',
  Telephone: '02151-406010',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.396448,6.657844',
  id: '51.396448,6.657844'
}, {
  Brand: 'Westfalen',
  Street: 'Lingener Damm 26',
  PostCode: null,
  City: 'RHEINE',
  Latitude: '522.882',
  Longitude: '744.072',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.2882,7.44072',
  id: '52.2882,7.44072'
}, {
  Brand: 'TOTAL',
  Street: 'KAMENZER STR. 27',
  PostCode: 1561,
  City: 'THIENDORF',
  Latitude: '51.294.494',
  Longitude: '13.736.175',
  Telephone: '035248-81325',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.294494,13.736175',
  id: '51.294494,13.736175'
}, {
  Brand: 'TOTAL',
  Street: 'KOELNER STR. 17 A',
  PostCode: 53489,
  City: 'SINZIG',
  Latitude: '50.548.412',
  Longitude: '7.245.048',
  Telephone: '02642-409200',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.548412,7.245048',
  id: '50.548412,7.245048'
}, {
  Brand: 'TOTAL',
  Street: 'FRIEDENSSTR. 88',
  PostCode: 23942,
  City: 'DASSOW',
  Latitude: '53.913.089',
  Longitude: '10.977.469',
  Telephone: '038826-80219',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.913089,10.977469',
  id: '53.913089,10.977469'
}, {
  Brand: 'TOTAL',
  Street: 'WERNSTEDTER STR 2',
  PostCode: 39624,
  City: 'KALBE',
  Latitude: '5.265.733',
  Longitude: '11.383.276',
  Telephone: '039080-40538',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.65733,11.383276',
  id: '52.65733,11.383276'
}, {
  Brand: 'ARAL',
  Street: 'Pferdebahnstr. 40',
  PostCode: 45141,
  City: 'Essen',
  Latitude: '514.657.850',
  Longitude: '69.919.100',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.465785,6.99191',
  id: '51.465785,6.99191'
}, {
  Brand: 'TOTAL',
  Street: 'SULZBACHTALSTR. 19',
  PostCode: 66125,
  City: 'SAARBRUECKEN',
  Latitude: '49.267.114',
  Longitude: '7.011.219',
  Telephone: '0681-3908027',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.267114,7.011219',
  id: '49.267114,7.011219'
}, {
  Brand: 'TOTAL',
  Street: 'FRIEDHOFSTR. 7',
  PostCode: 77694,
  City: 'KEHL',
  Latitude: '48.570.851',
  Longitude: '7.820.421',
  Telephone: '07851-640',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.570851,7.820421',
  id: '48.570851,7.820421'
}, {
  Brand: 'ARAL',
  Street: 'Am Sülzenbrückener Weg',
  PostCode: 99334,
  City: 'Ichtershausen',
  Latitude: '508.856.090',
  Longitude: '109.316.470',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.885609,10.931647',
  id: '50.885609,10.931647'
}, {
  Brand: 'ARAL',
  Street: 'Edisonstraße 14 a',
  PostCode: 86199,
  City: 'Augsburg',
  Latitude: '483.447.370',
  Longitude: '108.852.790',
  Telephone: '8215998707',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.344737,10.885279',
  id: '48.344737,10.885279'
}, {
  Brand: 'ARAL',
  Street: 'Seefelder Straße 28',
  PostCode: 82211,
  City: 'Herrsching',
  Latitude: '480.023.390',
  Longitude: '111.818.720',
  Telephone: '81528861',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '21:00',
  SundayFrom: '08:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.002339,11.181872',
  id: '48.002339,11.181872'
}, {
  Brand: 'ARAL',
  Street: 'Im Taubental 50',
  PostCode: 41468,
  City: 'Neuss',
  Latitude: '511.697.060',
  Longitude: '67.471.620',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.169706,6.747162',
  id: '51.169706,6.747162'
}, {
  Brand: 'TOTAL',
  Street: 'FREIENWALDER STR. 26',
  PostCode: 16269,
  City: 'WRIEZEN',
  Latitude: '527.286',
  Longitude: '14.117.792',
  Telephone: '033456-3113',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.7286,14.117792',
  id: '52.7286,14.117792'
}, {
  Brand: 'TOTAL',
  Street: 'RIBNITZER STR. 42',
  PostCode: 13051,
  City: 'BERLIN',
  Latitude: '52.565.599',
  Longitude: '13.491.795',
  Telephone: '030-9294032',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.565599,13.491795',
  id: '52.565599,13.491795'
}, {
  Brand: 'Westfalen',
  Street: 'Roemerstr. 17',
  PostCode: null,
  City: 'NIEDERZIER',
  Latitude: '50.866',
  Longitude: '643.456',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.866,6.43456',
  id: '50.866,6.43456'
}, {
  Brand: 'Westfalen',
  Street: 'Wandsbecker Zollstrasse 117-123',
  PostCode: null,
  City: 'HAMBURG',
  Latitude: '535.771',
  Longitude: '10.0838',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.5771,10.0838',
  id: '53.5771,10.0838'
}, {
  Brand: 'TOTAL',
  Street: 'PUSCHKINALLEE 3 - 5',
  PostCode: 99867,
  City: 'GOTHA',
  Latitude: '50.942.403',
  Longitude: '1.070.066',
  Telephone: '03621-853772',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.942403,10.70066',
  id: '50.942403,10.70066'
}, {
  Brand: 'Eni',
  Street: 'An der B 4',
  PostCode: 98673,
  City: 'Eisfeld (Guest)',
  Latitude: '5.039.444.221',
  Longitude: '1.092.150.216',
  Telephone: '+49 (0) 36863092',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.39444221,10.92150216',
  id: '50.39444221,10.92150216'
}, {
  Brand: 'OMV',
  Street: 'Ruselstr. 9',
  PostCode: 94209,
  City: 'Regen',
  Latitude: '4.896.803.000',
  Longitude: '1.312.750.000',
  Telephone: '+49-9921-904015',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.96803,13.1275',
  id: '48.96803,13.1275'
}, {
  Brand: 'ARAL',
  Street: 'Freiherr-von-Varnbüler Str. 1',
  PostCode: 71282,
  City: 'Hemmingen',
  Latitude: '488.648.150',
  Longitude: '90.393.890',
  Telephone: '71509185010',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.864815,9.039389',
  id: '48.864815,9.039389'
}, {
  Brand: 'ARAL',
  Street: 'Dürener Straße 407',
  PostCode: 50858,
  City: 'Köln',
  Latitude: '509.233.180',
  Longitude: '68.695.270',
  Telephone: '22117092660',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.923318,6.869527',
  id: '50.923318,6.869527'
}, {
  Brand: 'OMV',
  Street: 'Mühlfelderstr. 44',
  PostCode: 82211,
  City: 'Herrsching',
  Latitude: '4.799.379.000',
  Longitude: '1.117.299.000',
  Telephone: '+49-8152-399177',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.99379,11.17299',
  id: '47.99379,11.17299'
}, {
  Brand: 'Eni',
  Street: 'Brandenburger Strasse 1',
  PostCode: 67663,
  City: 'Kaiserslautern',
  Latitude: '4.943.350.156',
  Longitude: '775.022.410',
  Telephone: '+49 (0) 63130395',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.43350156,7.7502241',
  id: '49.43350156,7.7502241'
}, {
  Brand: 'TOTAL',
  Street: 'SEGEBERGER STR. 55 A',
  PostCode: 23617,
  City: 'STOCKELSDORF',
  Latitude: '53.887.794',
  Longitude: '10.644.622',
  Telephone: '0451-495676',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.887794,10.644622',
  id: '53.887794,10.644622'
}, {
  Brand: 'ARAL',
  Street: 'Forchheimer Str. 15',
  PostCode: 79359,
  City: 'Riegel am Kaiserstuh',
  Latitude: '481.567.710',
  Longitude: '77.446.260',
  Telephone: '76429078674',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.156771,7.744626',
  id: '48.156771,7.744626'
}, {
  Brand: 'TOTAL',
  Street: 'BUNDESSTR. 80',
  PostCode: 6179,
  City: 'LANGENBOGEN',
  Latitude: '51.479.283',
  Longitude: '11.784.206',
  Telephone: '034601-23164',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.479283,11.784206',
  id: '51.479283,11.784206'
}, {
  Brand: 'TOTAL',
  Street: 'HAUSACHER STR. 13',
  PostCode: 77793,
  City: 'GUTACH',
  Latitude: '48.283.339',
  Longitude: '8.193.928',
  Telephone: '07831-969488',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.283339,8.193928',
  id: '48.283339,8.193928'
}, {
  Brand: 'ARAL',
  Street: 'Essener Straße 225',
  PostCode: 45472,
  City: 'Mülheim',
  Latitude: '514.306.860',
  Longitude: '69.235.810',
  Telephone: '208431410',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.430686,6.923581',
  id: '51.430686,6.923581'
}, {
  Brand: 'TOTAL',
  Street: 'ZEPERNICKER STR. 9-10',
  PostCode: 13125,
  City: 'BERLIN',
  Latitude: '52.640.982',
  Longitude: '13.514.269',
  Telephone: '030-9497581',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.640982,13.514269',
  id: '52.640982,13.514269'
}, {
  Brand: 'TOTAL',
  Street: '218-220 LIMBACHER STR.',
  PostCode: 9116,
  City: 'CHEMNITZ',
  Latitude: '50.832.897',
  Longitude: '12.873.383',
  Telephone: '0371-364046',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.832897,12.873383',
  id: '50.832897,12.873383'
}, {
  Brand: 'ARAL',
  Street: 'Jahnstraße 88',
  PostCode: 73037,
  City: 'Göppingen',
  Latitude: '486.969.040',
  Longitude: '96.597.920',
  Telephone: '716173548',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.696904,9.659792',
  id: '48.696904,9.659792'
}, {
  Brand: 'TOTAL',
  Street: 'MARKENDORFER STR. 15',
  PostCode: 15234,
  City: 'FRANKFURT',
  Latitude: '52.340.698',
  Longitude: '14.529.344',
  Telephone: '0335-4000187',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.340698,14.529344',
  id: '52.340698,14.529344'
}, {
  Brand: 'Westfalen',
  Street: 'Resser Weg 20',
  PostCode: null,
  City: 'HERTEN',
  Latitude: '515.947',
  Longitude: '712.981',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.5947,7.12981',
  id: '51.5947,7.12981'
}, {
  Brand: 'TOTAL',
  Street: 'AN DER B 49',
  PostCode: 35447,
  City: 'REISKIRCHEN',
  Latitude: '50.596.135',
  Longitude: '8.817.457',
  Telephone: '6408549756',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.596135,8.817457',
  id: '50.596135,8.817457'
}, {
  Brand: 'Westfalen',
  Street: 'Stadtholzstr. 250',
  PostCode: null,
  City: 'BAT HERFORD-NORD',
  Latitude: '521.034',
  Longitude: '872.086',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.1034,8.72086',
  id: '52.1034,8.72086'
}, {
  Brand: 'ARAL',
  Street: 'Nordstraße 2',
  PostCode: 57578,
  City: 'Elkenroth',
  Latitude: '507.356.900',
  Longitude: '78.779.130',
  Telephone: '27479129748',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:30',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.73569,7.877913',
  id: '50.73569,7.877913'
}, {
  Brand: 'ARAL',
  Street: 'A 44',
  PostCode: 59457,
  City: 'Werl',
  Latitude: '515.323.830',
  Longitude: '78.612.640',
  Telephone: '29225399',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.532383,7.861264',
  id: '51.532383,7.861264'
}, {
  Brand: 'TOTAL',
  Street: 'ALTRUPPINER ALLEE 25',
  PostCode: 16816,
  City: 'NEURUPPIN',
  Latitude: '52.939.014',
  Longitude: '12.818.679',
  Telephone: '03391-3291',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.939014,12.818679',
  id: '52.939014,12.818679'
}, {
  Brand: 'TOTAL',
  Street: 'OSTERFELDSTR. 60',
  PostCode: 58300,
  City: 'WETTER',
  Latitude: '51.396.756',
  Longitude: '735.186',
  Telephone: '02335-70130',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.396756,7.35186',
  id: '51.396756,7.35186'
}, {
  Brand: 'ARAL',
  Street: 'Am Heidfuchsberg 1',
  PostCode: 39443,
  City: 'Staßfurt / OT Brumby',
  Latitude: '518.964.680',
  Longitude: '116.919.750',
  Telephone: '39291463920',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.896468,11.691975',
  id: '51.896468,11.691975'
}, {
  Brand: 'TOTAL',
  Street: 'WORMSER STR. 2',
  PostCode: 68519,
  City: 'VIERNHEIM',
  Latitude: '4.954.346',
  Longitude: '8.574.265',
  Telephone: '06204-76161',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.54346,8.574265',
  id: '49.54346,8.574265'
}, {
  Brand: 'ARAL',
  Street: 'Rotweg 28 - 32',
  PostCode: 70437,
  City: 'Stuttgart',
  Latitude: '488.337.310',
  Longitude: '91.825.660',
  Telephone: '711871958',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '48.833731,9.182566',
  id: '48.833731,9.182566'
}, {
  Brand: 'OMV',
  Street: 'Neumarkter Str. 23',
  PostCode: 84546,
  City: 'Egglkofen',
  Latitude: '4.839.173.000',
  Longitude: '1.245.294.000',
  Telephone: '+49-8639-708676',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.39173,12.45294',
  id: '48.39173,12.45294'
}, {
  Brand: 'TOTAL',
  Street: 'SANDBERG 30',
  PostCode: 4178,
  City: 'LEIPZIG',
  Latitude: '51.346.097',
  Longitude: '1.226.085',
  Telephone: '0341-9452118',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.346097,12.26085',
  id: '51.346097,12.26085'
}, {
  Brand: 'ARAL',
  Street: 'Landsberger Straße 20',
  PostCode: 86807,
  City: 'Buchloe',
  Latitude: '480.359.400',
  Longitude: '107.324.830',
  Telephone: '8241911291',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.03594,10.732483',
  id: '48.03594,10.732483'
}, {
  Brand: 'Westfalen',
  Street: 'Haller Str. 69',
  PostCode: null,
  City: 'STEINHAGEN',
  Latitude: '520.285',
  Longitude: '840.664',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0285,8.40664',
  id: '52.0285,8.40664'
}, {
  Brand: 'OMV',
  Street: 'Zugspitzstrasse 42',
  PostCode: 82467,
  City: 'Garmisch-Partenkirchen',
  Latitude: '4.749.058.000',
  Longitude: '1.107.674.000',
  Telephone: '+49-8821-51662',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.49058,11.07674',
  id: '47.49058,11.07674'
}, {
  Brand: 'Eni',
  Street: 'Neuburger Str. 104',
  PostCode: 94036,
  City: 'Passau',
  Latitude: '4.856.378.270',
  Longitude: '1.342.923.552',
  Telephone: '+49 (0) 85158991',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.5637827,13.42923552',
  id: '48.5637827,13.42923552'
}, {
  Brand: 'TOTAL',
  Street: 'WESTFALENDAMM 272',
  PostCode: 44141,
  City: 'DORTMUND',
  Latitude: '51.504.121',
  Longitude: '7.510.754',
  Telephone: '0231-596931',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.504121,7.510754',
  id: '51.504121,7.510754'
}, {
  Brand: 'Eni',
  Street: 'Rathausstr. 1',
  PostCode: 83734,
  City: 'Hausham',
  Latitude: '4.775.969.702',
  Longitude: '1.182.836.075',
  Telephone: '+49 (0) 80269226',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.75969702,11.82836075',
  id: '47.75969702,11.82836075'
}, {
  Brand: 'Eni',
  Street: 'Freiburger Strasse 9',
  PostCode: 77933,
  City: 'Lahr',
  Latitude: '4.834.151.034',
  Longitude: '784.149.881',
  Telephone: '+49 (0) 78219818',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.34151034,7.84149881',
  id: '48.34151034,7.84149881'
}, {
  Brand: 'ARAL',
  Street: 'Johann-G.-Gutenbergstraße 1',
  PostCode: 82140,
  City: 'Olching',
  Latitude: '481.995.100',
  Longitude: '113.359.410',
  Telephone: '81423060',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.19951,11.335941',
  id: '48.19951,11.335941'
}, {
  Brand: 'TOTAL',
  Street: '5 OLOF-PALME-ALLEE',
  PostCode: 25541,
  City: 'BRUNSBUETTEL',
  Latitude: '53.902.145',
  Longitude: '9.122.195',
  Telephone: '04852-9405010',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.902145,9.122195',
  id: '53.902145,9.122195'
}, {
  Brand: 'OMV',
  Street: 'Jesinger Str. 50',
  PostCode: 73230,
  City: 'Kirchheim',
  Latitude: '4.864.529.000',
  Longitude: '945.930.000',
  Telephone: '+49-7021-2848',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.64529,9.4593',
  id: '48.64529,9.4593'
}, {
  Brand: 'Westfalen',
  Street: 'Siemensallee 77',
  PostCode: null,
  City: 'KARLSRUHE',
  Latitude: '490.293',
  Longitude: '8.347',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.0293,8.347',
  id: '49.0293,8.347'
}, {
  Brand: 'TOTAL',
  Street: 'VOGELSTANGE 17',
  PostCode: 4741,
  City: 'ROSSWEIN',
  Latitude: '51.072.199',
  Longitude: '13.184.588',
  Telephone: '034322-43775',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.072199,13.184588',
  id: '51.072199,13.184588'
}, {
  Brand: 'TOTAL',
  Street: 'OSTERWALDER STR. 63',
  PostCode: 30827,
  City: 'GARBSEN',
  Latitude: '5.244.367',
  Longitude: '9.608.598',
  Telephone: '05131-91637',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.44367,9.608598',
  id: '52.44367,9.608598'
}, {
  Brand: 'TOTAL',
  Street: 'PENIGER STR. 1B',
  PostCode: 4643,
  City: 'GEITHAIN',
  Latitude: '51.051.394',
  Longitude: '12.706.932',
  Telephone: '034341-43567',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.051394,12.706932',
  id: '51.051394,12.706932'
}, {
  Brand: 'TOTAL',
  Street: 'ERLACHSEEWEG 10',
  PostCode: 76227,
  City: 'KARLSRUHE',
  Latitude: '48.985.302',
  Longitude: '8.444.489',
  Telephone: '0721-56867276',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.985302,8.444489',
  id: '48.985302,8.444489'
}, {
  Brand: 'TOTAL',
  Street: 'BERLINER STR. 135',
  PostCode: 3046,
  City: 'COTTBUS',
  Latitude: '51.761.389',
  Longitude: '14.324.722',
  Telephone: '0355-4946930',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.761389,14.324722',
  id: '51.761389,14.324722'
}, {
  Brand: 'ARAL',
  Street: 'Chausseestraße 71',
  PostCode: 17373,
  City: 'Ueckermünde',
  Latitude: '537.325.700',
  Longitude: '140.299.770',
  Telephone: '3977127044',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.73257,14.029977',
  id: '53.73257,14.029977'
}, {
  Brand: 'ARAL',
  Street: 'Haydnstraße 3',
  PostCode: 89250,
  City: 'Senden',
  Latitude: '483.282.980',
  Longitude: '100.420.080',
  Telephone: '7307939282',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.328298,10.042008',
  id: '48.328298,10.042008'
}, {
  Brand: 'TOTAL',
  Street: 'KESSENICHER STR. 212',
  PostCode: 53129,
  City: 'BONN-DOTTENDORF',
  Latitude: '50.702.771',
  Longitude: '7.116.055',
  Telephone: '0228-231116',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.702771,7.116055',
  id: '50.702771,7.116055'
}, {
  Brand: 'TOTAL',
  Street: '92 EUREN STRASSE',
  PostCode: 54294,
  City: 'TRIER EUREN',
  Latitude: '49.745.058',
  Longitude: '6.614.294',
  Telephone: '6519982926',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.745058,6.614294',
  id: '49.745058,6.614294'
}, {
  Brand: 'TOTAL',
  Street: 'ASCHERSLEBENER STR. 60',
  PostCode: 6333,
  City: 'HETTSTEDT',
  Latitude: '51.657.124',
  Longitude: '11.495.127',
  Telephone: '03476-851065',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.657124,11.495127',
  id: '51.657124,11.495127'
}, {
  Brand: 'Westfalen',
  Street: 'Olpener Str. 27',
  PostCode: null,
  City: 'KOELN',
  Latitude: '509.403',
  Longitude: '701.887',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.9403,7.01887',
  id: '50.9403,7.01887'
}, {
  Brand: 'TOTAL',
  Street: 'VOSSWALDESTR. 1',
  PostCode: 63457,
  City: 'HANAU',
  Latitude: '50.108.268',
  Longitude: '8.968.889',
  Telephone: '06181-5206287',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.108268,8.968889',
  id: '50.108268,8.968889'
}, {
  Brand: 'TOTAL',
  Street: 'KOHLENSTR. 51',
  PostCode: 54296,
  City: 'TRIER-TARFORST',
  Latitude: '49.745.128',
  Longitude: '6.691.606',
  Telephone: '0651-9990133',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.745128,6.691606',
  id: '49.745128,6.691606'
}, {
  Brand: 'TOTAL',
  Street: 'BAB WEST/A 11',
  PostCode: 16244,
  City: 'SCHORFHEIDE-BUCKOWSEE',
  Latitude: '5.288.303',
  Longitude: '1.370.189',
  Telephone: '033363-46210',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.88303,13.70189',
  id: '52.88303,13.70189'
}, {
  Brand: 'ARAL',
  Street: 'Lindenstraße 60',
  PostCode: 99718,
  City: 'Greußen',
  Latitude: '512.229.670',
  Longitude: '109.471.460',
  Telephone: '3636701255',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.222967,10.947146',
  id: '51.222967,10.947146'
}, {
  Brand: 'Eni',
  Street: 'Bruchkoebeler Landstrasse 53',
  PostCode: 63452,
  City: 'Hanau',
  Latitude: '5.014.617.500',
  Longitude: '890.929.000',
  Telephone: '+49 (0) 61818408',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.146175,8.90929',
  id: '50.146175,8.90929'
}, {
  Brand: 'TOTAL',
  Street: 'FRANKFURTER STR.14',
  PostCode: 4916,
  City: 'HERZBERG',
  Latitude: '51.697.164',
  Longitude: '13.251.557',
  Telephone: '03535-247650',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.697164,13.251557',
  id: '51.697164,13.251557'
}, {
  Brand: 'TOTAL',
  Street: 'HUENFELDER STR. 4',
  PostCode: 36282,
  City: 'HAUNECK',
  Latitude: '50.824.224',
  Longitude: '9.732.466',
  Telephone: '06621-75264',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.824224,9.732466',
  id: '50.824224,9.732466'
}, {
  Brand: 'TOTAL',
  Street: 'KARLSRUHER STR. 16',
  PostCode: 76437,
  City: 'RASTATT',
  Latitude: '48.863.835',
  Longitude: '8.217.852',
  Telephone: '07222-150160',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.863835,8.217852',
  id: '48.863835,8.217852'
}, {
  Brand: 'TOTAL',
  Street: 'BAB-BROHLTAL-OST/A 61',
  PostCode: 56651,
  City: 'NIEDERZISSEN',
  Latitude: '50.439.823',
  Longitude: '7.226.107',
  Telephone: '02636-9419460',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.439823,7.226107',
  id: '50.439823,7.226107'
}, {
  Brand: 'TOTAL',
  Street: 'EGERLAENDER WEG 2',
  PostCode: 74523,
  City: 'SCHWAEBISCH HALL',
  Latitude: '49.106.472',
  Longitude: '9.718.823',
  Telephone: '0791-52138',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.106472,9.718823',
  id: '49.106472,9.718823'
}, {
  Brand: 'TOTAL',
  Street: 'BILLSTEDTER HAUPTSTRASSE 21',
  PostCode: 22111,
  City: 'HAMBURG',
  Latitude: '53.541.648',
  Longitude: '10.098.817',
  Telephone: '040-7314835',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.541648,10.098817',
  id: '53.541648,10.098817'
}, {
  Brand: 'TOTAL',
  Street: 'BOBLITZER CHAUSSEESTR. 2',
  PostCode: 3222,
  City: 'LUEBBENAU/OT BOBLITZ',
  Latitude: '51.846.694',
  Longitude: '13.972.428',
  Telephone: '03542-44138',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.846694,13.972428',
  id: '51.846694,13.972428'
}, {
  Brand: 'TOTAL',
  Street: 'ZEILSHEIMER STR. 37-39',
  PostCode: 65719,
  City: 'HOFHEIM',
  Latitude: '50.088.542',
  Longitude: '8.454.397',
  Telephone: '06192-6776',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.088542,8.454397',
  id: '50.088542,8.454397'
}, {
  Brand: 'Eni',
  Street: 'An Der A 9',
  PostCode: 7927,
  City: 'Hirschberg',
  Latitude: '5.043.021.873',
  Longitude: '1.179.881.430',
  Telephone: '+49 (0) 36644260',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.43021873,11.7988143',
  id: '50.43021873,11.7988143'
}, {
  Brand: 'TOTAL',
  Street: 'REICHSSTR. 86',
  PostCode: 14052,
  City: 'BERLIN',
  Latitude: '52.514.988',
  Longitude: '13.262.316',
  Telephone: '030-3046042',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.514988,13.262316',
  id: '52.514988,13.262316'
}, {
  Brand: 'TOTAL',
  Street: 'HOHER RAIN 2',
  PostCode: 73770,
  City: 'DENKENDORF',
  Latitude: '486.929',
  Longitude: '930.493',
  Telephone: '71193445340',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.6929,9.30493',
  id: '48.6929,9.30493'
}, {
  Brand: 'Westfalen',
  Street: 'Heisenbergstr. 2a',
  PostCode: null,
  City: 'KERPEN',
  Latitude: '508.699',
  Longitude: '675.738',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.8699,6.75738',
  id: '50.8699,6.75738'
}, {
  Brand: 'Eni',
  Street: 'Kemptener Strasse 61',
  PostCode: 87600,
  City: 'Kaufbeuren',
  Latitude: '4.787.827.520',
  Longitude: '1.061.169.268',
  Telephone: '+49 (0) 83418281',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.8782752,10.61169268',
  id: '47.8782752,10.61169268'
}, {
  Brand: 'TOTAL',
  Street: 'ALTE LANDSTR.23-29',
  PostCode: 77972,
  City: 'MAHLBERG-ORSCHWEIER',
  Latitude: '48.274.934',
  Longitude: '7.786.951',
  Telephone: '07822-4349649',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.274934,7.786951',
  id: '48.274934,7.786951'
}, {
  Brand: 'Westfalen',
  Street: 'Lipprahmsdorfer 6',
  PostCode: null,
  City: 'DORSTEN',
  Latitude: '517.604',
  Longitude: '700.015',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.7604,7.00015',
  id: '51.7604,7.00015'
}, {
  Brand: 'OMV',
  Street: 'Oberpfälzer Alb Süd A 6',
  PostCode: 92278,
  City: 'Illschwang',
  Latitude: '4.942.363.000',
  Longitude: '1.168.646.000',
  Telephone: '+49-9666-951680',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.42363,11.68646',
  id: '49.42363,11.68646'
}, {
  Brand: 'TOTAL',
  Street: '42 ANSBACHER STR.',
  PostCode: 91589,
  City: 'AURACH',
  Latitude: '49.252.338',
  Longitude: '10.434.523',
  Telephone: '09804-9391922',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.252338,10.434523',
  id: '49.252338,10.434523'
}, {
  Brand: 'OMV',
  Street: 'Luitpoldstr. 34',
  PostCode: 84034,
  City: 'Landshut',
  Latitude: '4.854.085.000',
  Longitude: '1.214.145.000',
  Telephone: '+49-871-62445',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.54085,12.14145',
  id: '48.54085,12.14145'
}, {
  Brand: 'ARAL',
  Street: 'Pariser Straße 140',
  PostCode: 55268,
  City: 'Nieder-Olm',
  Latitude: '499.072.360',
  Longitude: '81.989.300',
  Telephone: '61369538615',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '49.907236,8.19893',
  id: '49.907236,8.19893'
}, {
  Brand: 'Westfalen',
  Street: 'Tiergartenstr. 84',
  PostCode: null,
  City: 'JADE',
  Latitude: '533.289',
  Longitude: '818.689',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.3289,8.18689',
  id: '53.3289,8.18689'
}, {
  Brand: 'Westfalen',
  Street: 'Hauptstr. 372-376',
  PostCode: null,
  City: 'LEOPOLDSHOEHE',
  Latitude: '519.756',
  Longitude: '866.539',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9756,8.66539',
  id: '51.9756,8.66539'
}, {
  Brand: 'Eni',
  Street: 'Mercedesring 2',
  PostCode: 86368,
  City: 'Gersthofen',
  Latitude: '4.843.679.000',
  Longitude: '1.086.898.000',
  Telephone: '+49 (0) 82146094',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.43679,10.86898',
  id: '48.43679,10.86898'
}, {
  Brand: 'Eni',
  Street: 'Wittstrasse 15',
  PostCode: 84036,
  City: 'Landshut',
  Latitude: '4.852.888.000',
  Longitude: '1.214.103.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.52888,12.14103',
  id: '48.52888,12.14103'
}, {
  Brand: 'Westfalen',
  Street: 'Pfarrer-Kraus-Str. 154',
  PostCode: null,
  City: 'KOBLENZ',
  Latitude: '503.697',
  Longitude: '7.6598',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.3697,7.6598',
  id: '50.3697,7.6598'
}, {
  Brand: 'Westfalen',
  Street: 'Donnerschweer 215',
  PostCode: null,
  City: 'OLDENBURG',
  Latitude: '531.513',
  Longitude: '823.653',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.1513,8.23653',
  id: '53.1513,8.23653'
}, {
  Brand: 'ARAL',
  Street: 'Oskar-Arnold-Straße 14',
  PostCode: 8056,
  City: 'Zwickau',
  Latitude: '507.065.350',
  Longitude: '125.010.940',
  Telephone: '3752040053',
  WeekDayFrom: '04:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.706535,12.501094',
  id: '50.706535,12.501094'
}, {
  Brand: 'ARAL',
  Street: 'Daimlerstraße 2',
  PostCode: 35260,
  City: 'Stadtallendorf',
  Latitude: '508.352.620',
  Longitude: '90.383.660',
  Telephone: '',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.835262,9.038366',
  id: '50.835262,9.038366'
}, {
  Brand: 'TOTAL',
  Street: '1 SCHAFFELDSTR.',
  PostCode: 46395,
  City: 'BOCHOLT',
  Latitude: '51.820.908',
  Longitude: '6.613.598',
  Telephone: '02871-15001',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.820908,6.613598',
  id: '51.820908,6.613598'
}, {
  Brand: 'TOTAL',
  Street: 'LANGE STR.159',
  PostCode: 44581,
  City: 'CASTROP-RAUXEL',
  Latitude: '51.587.316',
  Longitude: '7.326.022',
  Telephone: '02305-79420',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.587316,7.326022',
  id: '51.587316,7.326022'
}, {
  Brand: 'ARAL',
  Street: 'Theresienstraße 2',
  PostCode: 83278,
  City: 'Traunstein',
  Latitude: '478.721.080',
  Longitude: '126.382.330',
  Telephone: '8612681',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '18:00',
  IsOpen24Hours: 'Nein',
  location: '47.872108,12.638233',
  id: '47.872108,12.638233'
}, {
  Brand: 'TOTAL',
  Street: 'SUESSWIESENSTR. 2',
  PostCode: 74549,
  City: 'WOLPERTSHAUSEN',
  Latitude: '49.170.615',
  Longitude: '9.859.743',
  Telephone: '07904-9435899',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.170615,9.859743',
  id: '49.170615,9.859743'
}, {
  Brand: 'TOTAL',
  Street: 'ACHERINGER HAUPTSTR. 20',
  PostCode: 85354,
  City: 'FREISING',
  Latitude: '48.344.397',
  Longitude: '11.709.186',
  Telephone: '08165-64290',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.344397,11.709186',
  id: '48.344397,11.709186'
}, {
  Brand: 'ARAL',
  Street: 'Märkische Straße 14',
  PostCode: 58675,
  City: 'Hemer',
  Latitude: '513.931.130',
  Longitude: '77.584.870',
  Telephone: '237210628',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.393113,7.758487',
  id: '51.393113,7.758487'
}, {
  Brand: 'Eni',
  Street: 'Zellers 6',
  PostCode: 87509,
  City: 'Immenstadt',
  Latitude: '4.760.201.567',
  Longitude: '1.025.526.052',
  Telephone: '+49 (0) 83797176',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.60201567,10.25526052',
  id: '47.60201567,10.25526052'
}, {
  Brand: 'TOTAL',
  Street: 'VOCCARTSTR. 80',
  PostCode: 52134,
  City: 'HERZOGENRATH',
  Latitude: '50.850.548',
  Longitude: '6.076.434',
  Telephone: '02406-2480',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.850548,6.076434',
  id: '50.850548,6.076434'
}, {
  Brand: 'ARAL',
  Street: 'Neckaraue 2',
  PostCode: 71686,
  City: 'Remseck',
  Latitude: '488.901.960',
  Longitude: '92.712.130',
  Telephone: '714643532',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.890196,9.271213',
  id: '48.890196,9.271213'
}, {
  Brand: 'OMV',
  Street: 'Friesenheimer Str. 47',
  PostCode: 67069,
  City: 'Ludwigshafen',
  Latitude: '4.951.519.000',
  Longitude: '840.624.000',
  Telephone: '+49-621-651636',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.51519,8.40624',
  id: '49.51519,8.40624'
}, {
  Brand: 'OMV',
  Street: 'Landshuter Str. 42',
  PostCode: 84416,
  City: 'Taufkirchen',
  Latitude: '4.835.232.000',
  Longitude: '1.212.970.000',
  Telephone: '+49-8084-946444',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.35232,12.1297',
  id: '48.35232,12.1297'
}, {
  Brand: 'Eni',
  Street: 'Burgstr. 16',
  PostCode: 82467,
  City: 'Garmisch-Partenk.',
  Latitude: '4.749.893.076',
  Longitude: '1.108.798.154',
  Telephone: '+49 (0) 88219470',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.49893076,11.08798154',
  id: '47.49893076,11.08798154'
}, {
  Brand: 'Eni',
  Street: 'Am Hochfeldweg 2',
  PostCode: 85051,
  City: 'Ingolstadt-Zuchering',
  Latitude: '4.870.331.310',
  Longitude: '1.141.864.491',
  Telephone: '+49 (0) 84509286',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.7033131,11.41864491',
  id: '48.7033131,11.41864491'
}, {
  Brand: 'Westfalen',
  Street: 'Hansastr. 45a',
  PostCode: null,
  City: 'OSNABRUECK',
  Latitude: '522.879',
  Longitude: '8.0381',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.2879,8.0381',
  id: '52.2879,8.0381'
}, {
  Brand: 'Westfalen',
  Street: 'Luecker Str. 1',
  PostCode: null,
  City: 'DORMAGEN',
  Latitude: '510.949',
  Longitude: '681.309',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.0949,6.81309',
  id: '51.0949,6.81309'
}, {
  Brand: 'Westfalen',
  Street: 'Polsumer Str. 38 - 40',
  PostCode: null,
  City: 'GELSENKIRCHEN',
  Latitude: '515.885',
  Longitude: '705.046',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.5885,7.05046',
  id: '51.5885,7.05046'
}, {
  Brand: 'Westfalen',
  Street: 'Hauptstrasse 19',
  PostCode: null,
  City: 'AHLEN',
  Latitude: '518.005',
  Longitude: '795.315',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8005,7.95315',
  id: '51.8005,7.95315'
}, {
  Brand: 'TOTAL',
  Street: 'MEISSNER STR.41',
  PostCode: 1623,
  City: 'LOMMATZSCH',
  Latitude: '51.196.899',
  Longitude: '13.314.181',
  Telephone: '035241-58631',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.196899,13.314181',
  id: '51.196899,13.314181'
}, {
  Brand: 'ARAL',
  Street: 'A 61',
  PostCode: 56332,
  City: 'Dieblich',
  Latitude: '502.675.310',
  Longitude: '75.099.130',
  Telephone: '2607963465',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.267531,7.509913',
  id: '50.267531,7.509913'
}, {
  Brand: 'TOTAL',
  Street: 'AN DER STADTAUTOBAHN 70',
  PostCode: 18107,
  City: 'ROSTOCK-LUETTENKLEIN',
  Latitude: '54.131.519',
  Longitude: '12.062.964',
  Telephone: '0381-712092',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.131519,12.062964',
  id: '54.131519,12.062964'
}, {
  Brand: 'OMV',
  Street: 'Elly-Beinhornstraße 2',
  PostCode: 73760,
  City: 'Ostfildern - Scharnhausen',
  Latitude: '4.870.470.000',
  Longitude: '924.870.000',
  Telephone: '+49-7158-9878513',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.7047,9.2487',
  id: '48.7047,9.2487'
}, {
  Brand: 'TOTAL',
  Street: 'BAHNHOFSTR. 45',
  PostCode: 76744,
  City: 'WOERTH',
  Latitude: '49.046.448',
  Longitude: '8.273.192',
  Telephone: '07271-7006',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.046448,8.273192',
  id: '49.046448,8.273192'
}, {
  Brand: 'TOTAL',
  Street: 'WASBEKER STR. 361',
  PostCode: 24537,
  City: 'NEUMUENSTER',
  Latitude: '5.406.848',
  Longitude: '9.937.957',
  Telephone: '04321-61092',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.06848,9.937957',
  id: '54.06848,9.937957'
}, {
  Brand: 'TOTAL',
  Street: '2 ESSBAUM',
  PostCode: 83122,
  City: 'SAMERBERG',
  Latitude: '477.643',
  Longitude: '12.1973',
  Telephone: '08032/8826',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.7643,12.1973',
  id: '47.7643,12.1973'
}, {
  Brand: 'TOTAL',
  Street: 'BINGER LANDSTR. 25',
  PostCode: 55606,
  City: 'HOCHSTETTEN',
  Latitude: '49.799.247',
  Longitude: '7.509.407',
  Telephone: '06752-2066',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.799247,7.509407',
  id: '49.799247,7.509407'
}, {
  Brand: 'Eni',
  Street: 'Schwindgraben 2',
  PostCode: 93083,
  City: 'Obertraubling',
  Latitude: '4.897.125.000',
  Longitude: '1.217.505.000',
  Telephone: '+49 (0) 94015102',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.97125,12.17505',
  id: '48.97125,12.17505'
}, {
  Brand: 'ARAL',
  Street: 'Alpenstraße 4',
  PostCode: 83242,
  City: 'Reit im Winkl',
  Latitude: '476.688.390',
  Longitude: '124.946.520',
  Telephone: '86408237',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '18:00',
  SundayFrom: '08:00',
  SundayTo: '18:00',
  IsOpen24Hours: 'Nein',
  location: '47.668839,12.494652',
  id: '47.668839,12.494652'
}, {
  Brand: 'Westfalen',
  Street: 'Raiffeisenstr. 31 - 33',
  PostCode: null,
  City: 'WARENDORF',
  Latitude: '518.741',
  Longitude: '791.428',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8741,7.91428',
  id: '51.8741,7.91428'
}, {
  Brand: 'ARAL',
  Street: 'Sandweierer Straße 47',
  PostCode: 76532,
  City: 'Baden-Baden',
  Latitude: '488.112.010',
  Longitude: '81.953.910',
  Telephone: '7221809171',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.811201,8.195391',
  id: '48.811201,8.195391'
}, {
  Brand: 'TOTAL',
  Street: 'ROSENTHALER STR. 2',
  PostCode: 67304,
  City: 'EISENBERG',
  Latitude: '49.564.164',
  Longitude: '8.062.269',
  Telephone: '06351-5164',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.564164,8.062269',
  id: '49.564164,8.062269'
}, {
  Brand: 'TOTAL',
  Street: 'AUTOHOF STRASSBURGER STR. 4',
  PostCode: 31275,
  City: 'LEHRTE',
  Latitude: '52.385.398',
  Longitude: '9.952.765',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.385398,9.952765',
  id: '52.385398,9.952765'
}, {
  Brand: 'TOTAL',
  Street: 'OBERE LAUTER STR. 37',
  PostCode: 67731,
  City: 'OTTERBACH',
  Latitude: '49.478.785',
  Longitude: '7.735.516',
  Telephone: '06301-8258',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.478785,7.735516',
  id: '49.478785,7.735516'
}, {
  Brand: 'TOTAL',
  Street: 'ALEMANNENSTRASSE 1',
  PostCode: 84130,
  City: 'DINGOLFING',
  Latitude: '48.641.057',
  Longitude: '12.463.258',
  Telephone: '87313099830',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.641057,12.463258',
  id: '48.641057,12.463258'
}, {
  Brand: 'TOTAL',
  Street: 'BERLINER STR. 23',
  PostCode: 3226,
  City: 'VETSCHAU',
  Latitude: '51.786.099',
  Longitude: '14.065.982',
  Telephone: '035433-2350',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.786099,14.065982',
  id: '51.786099,14.065982'
}, {
  Brand: 'TOTAL',
  Street: 'JAKOBSTR. 43',
  PostCode: 67722,
  City: 'WINNWEILER',
  Latitude: '49.564.805',
  Longitude: '785.671',
  Telephone: '06302-2187',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.564805,7.85671',
  id: '49.564805,7.85671'
}, {
  Brand: 'ARAL',
  Street: 'Lange Göhren',
  PostCode: 39171,
  City: 'Osterweddingen',
  Latitude: '520.525.270',
  Longitude: '115.890.950',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.052527,11.589095',
  id: '52.052527,11.589095'
}, {
  Brand: 'ARAL',
  Street: 'König-Ludwig-Straße 2',
  PostCode: 87645,
  City: 'Schwangau',
  Latitude: '475.793.270',
  Longitude: '107.419.410',
  Telephone: '8362982721',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '20:00',
  SundayFrom: '06:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '47.579327,10.741941',
  id: '47.579327,10.741941'
}, {
  Brand: 'TOTAL',
  Street: 'ANTON-AULKE-RING 10',
  PostCode: 48308,
  City: 'SENDEN',
  Latitude: '51.855.365',
  Longitude: '7.492.069',
  Telephone: '02597-5547',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.855365,7.492069',
  id: '51.855365,7.492069'
}, {
  Brand: 'TOTAL',
  Street: 'BERLINER STR 15A',
  PostCode: 14169,
  City: 'BERLIN',
  Latitude: '52.436.134',
  Longitude: '1.326.584',
  Telephone: '030-81059385',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.436134,13.26584',
  id: '52.436134,13.26584'
}, {
  Brand: 'ARAL',
  Street: 'Äußere Moosburger Str. 1',
  PostCode: 85276,
  City: 'Pfaffenhofen',
  Latitude: '485.223.530',
  Longitude: '115.436.340',
  Telephone: '8441499190',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.522353,11.543634',
  id: '48.522353,11.543634'
}, {
  Brand: 'ARAL',
  Street: 'Dycker Feld',
  PostCode: 42653,
  City: 'Solingen',
  Latitude: '512.013.200',
  Longitude: '70.776.900',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.20132,7.07769',
  id: '51.20132,7.07769'
}, {
  Brand: 'ARAL',
  Street: 'Chiemseestraße 50',
  PostCode: 83278,
  City: 'Traunstein',
  Latitude: '478.681.010',
  Longitude: '126.222.010',
  Telephone: '86112973',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:30',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:30',
  SundayFrom: '07:00',
  SundayTo: '21:30',
  IsOpen24Hours: 'Nein',
  location: '47.868101,12.622201',
  id: '47.868101,12.622201'
}, {
  Brand: 'OMV',
  Street: 'Stuttgarter Strasse 54',
  PostCode: 72141,
  City: 'Walddorfhäslach',
  Latitude: '4.858.803.000',
  Longitude: '919.024.000',
  Telephone: '+49-7127-9774946',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.58803,9.19024',
  id: '48.58803,9.19024'
}, {
  Brand: 'Eni',
  Street: 'Hofgartenstrasse 10',
  PostCode: 72379,
  City: 'Hechingen',
  Latitude: '4.835.692.051',
  Longitude: '896.465.209',
  Telephone: '+49 (0) 74715315',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.35692051,8.96465209',
  id: '48.35692051,8.96465209'
}, {
  Brand: 'OMV',
  Street: 'Ingolstädter Str. 7 a',
  PostCode: 85101,
  City: 'Lenting',
  Latitude: '4.880.609.000',
  Longitude: '1.146.250.000',
  Telephone: '+49-8456-2223',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.80609,11.4625',
  id: '48.80609,11.4625'
}, {
  Brand: 'Eni',
  Street: 'Robert-Bosch-Str. 2-4',
  PostCode: 72525,
  City: 'Muensingen',
  Latitude: '4.841.539.000',
  Longitude: '947.401.000',
  Telephone: '+49 (0) 73815013',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.41539,9.47401',
  id: '48.41539,9.47401'
}, {
  Brand: 'OMV',
  Street: 'Landshuter Str. 101',
  PostCode: 94315,
  City: 'Straubing',
  Latitude: '4.886.722.000',
  Longitude: '1.257.413.000',
  Telephone: '+49-9421-530190',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.86722,12.57413',
  id: '48.86722,12.57413'
}, {
  Brand: 'Eni',
  Street: 'Lappersdorfer Strasse 110',
  PostCode: 93059,
  City: 'Regensburg',
  Latitude: '4.904.051.000',
  Longitude: '1.209.464.000',
  Telephone: '+49 (0) 94182903',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.04051,12.09464',
  id: '49.04051,12.09464'
}, {
  Brand: 'Westfalen',
  Street: 'Westtor 40',
  PostCode: null,
  City: 'SENDENHORST',
  Latitude: '518.432',
  Longitude: '781.461',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8432,7.81461',
  id: '51.8432,7.81461'
}, {
  Brand: 'ARAL',
  Street: 'Gothaer Straße 40',
  PostCode: 99094,
  City: 'Erfurt',
  Latitude: '509.649.820',
  Longitude: '109.991.200',
  Telephone: '36165319607',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.964982,10.99912',
  id: '50.964982,10.99912'
}, {
  Brand: 'OMV',
  Street: 'Nürnberger Str. 16 a',
  PostCode: 95448,
  City: 'Bayreuth',
  Latitude: '4.993.666.000',
  Longitude: '1.159.029.000',
  Telephone: '+49-921-65514',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.93666,11.59029',
  id: '49.93666,11.59029'
}, {
  Brand: 'OMV',
  Street: 'Eibacher Hauptstr. 109',
  PostCode: 90451,
  City: 'Nürnberg',
  Latitude: '4.939.911.000',
  Longitude: '1.103.779.000',
  Telephone: '+49-911-6494910',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.39911,11.03779',
  id: '49.39911,11.03779'
}, {
  Brand: 'TOTAL',
  Street: 'MUSKAUER STR. 32',
  PostCode: 3159,
  City: 'DOEBERN',
  Latitude: '51.608.299',
  Longitude: '1.460.808',
  Telephone: '035600-23083',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.608299,14.60808',
  id: '51.608299,14.60808'
}, {
  Brand: 'TOTAL',
  Street: '69 DANZIGER STR.',
  PostCode: 29633,
  City: 'MUNSTER',
  Latitude: '52.989.216',
  Longitude: '10.081.495',
  Telephone: '05192-6267',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.989216,10.081495',
  id: '52.989216,10.081495'
}, {
  Brand: 'ARAL',
  Street: 'Nebelhornstraße 8',
  PostCode: 87719,
  City: 'Mindelheim',
  Latitude: '480.360.850',
  Longitude: '105.013.710',
  Telephone: '826120100',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '48.036085,10.501371',
  id: '48.036085,10.501371'
}, {
  Brand: 'TOTAL',
  Street: 'O.-v.-NELL-BREUNING 4',
  PostCode: 51503,
  City: 'ROESRATH',
  Latitude: '50.884.422',
  Longitude: '7.186.394',
  Telephone: '02205-899802',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.884422,7.186394',
  id: '50.884422,7.186394'
}, {
  Brand: 'TOTAL',
  Street: 'AN DER B 96',
  PostCode: 15938,
  City: 'GOLSSEN',
  Latitude: '51.977.556',
  Longitude: '13.589.563',
  Telephone: '035452-432',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.977556,13.589563',
  id: '51.977556,13.589563'
}, {
  Brand: 'TOTAL',
  Street: 'ROSTOCKER STR. 42',
  PostCode: 17213,
  City: 'MALCHOW',
  Latitude: '53.480.994',
  Longitude: '12.421.106',
  Telephone: '039932-12695',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.480994,12.421106',
  id: '53.480994,12.421106'
}, {
  Brand: 'TOTAL',
  Street: 'HARBURGER BERGE WEST/ A7',
  PostCode: 21077,
  City: 'HAMBURG',
  Latitude: '53.424.393',
  Longitude: '9.962.937',
  Telephone: '040-7001010',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.424393,9.962937',
  id: '53.424393,9.962937'
}, {
  Brand: 'TOTAL',
  Street: 'OFFENBURGER STR. 22',
  PostCode: 79341,
  City: 'KENZINGEN',
  Latitude: '48.195.576',
  Longitude: '7.773.943',
  Telephone: '07644-2557940',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.195576,7.773943',
  id: '48.195576,7.773943'
}, {
  Brand: 'ARAL',
  Street: 'An der Autobahn 1',
  PostCode: 29690,
  City: 'Buchholz/Aller',
  Latitude: '526.738.090',
  Longitude: '96.890.940',
  Telephone: '50718060',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.673809,9.689094',
  id: '52.673809,9.689094'
}, {
  Brand: 'TOTAL',
  Street: 'PREINSTR. 126',
  PostCode: 44265,
  City: 'DORTMUND',
  Latitude: '51.468.183',
  Longitude: '7.492.734',
  Telephone: '0231-7246591',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.468183,7.492734',
  id: '51.468183,7.492734'
}, {
  Brand: 'OMV',
  Street: 'Windsbacher Str. 39',
  PostCode: 91174,
  City: 'Spalt',
  Latitude: '4.917.747.000',
  Longitude: '1.091.811.000',
  Telephone: '+49-9175-790990',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.17747,10.91811',
  id: '49.17747,10.91811'
}, {
  Brand: 'TOTAL',
  Street: 'CASTROPER STR. 123',
  PostCode: 45665,
  City: 'RECKLINGHAUSEN',
  Latitude: '51.604.639',
  Longitude: '7.214.727',
  Telephone: '02361-484773',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.604639,7.214727',
  id: '51.604639,7.214727'
}, {
  Brand: 'ARAL',
  Street: 'Wasseralfinger Straße 66',
  PostCode: 73434,
  City: 'Aalen-Fachsenfeld',
  Latitude: '488.784.870',
  Longitude: '100.499.960',
  Telephone: '7366922998',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.878487,10.049996',
  id: '48.878487,10.049996'
}, {
  Brand: 'TOTAL',
  Street: 'MELDORFER STR. 143',
  PostCode: 25746,
  City: 'HEIDE',
  Latitude: '54.184.383',
  Longitude: '9.090.378',
  Telephone: '0481-69881555',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.184383,9.090378',
  id: '54.184383,9.090378'
}, {
  Brand: 'TOTAL',
  Street: 'HERMANN-HESSE-STR. 1',
  PostCode: 39118,
  City: 'MAGDEBURG',
  Latitude: '52.094.863',
  Longitude: '11.605.684',
  Telephone: '0391-616397',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.094863,11.605684',
  id: '52.094863,11.605684'
}, {
  Brand: 'TOTAL',
  Street: 'AM WAETHERING NR. 2',
  PostCode: 17335,
  City: 'STRASBURG',
  Latitude: '53.502.069',
  Longitude: '13.733.866',
  Telephone: '039753-22077',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.502069,13.733866',
  id: '53.502069,13.733866'
}, {
  Brand: 'TOTAL',
  Street: 'SOESTER BOERDE NORD, A44',
  PostCode: 59494,
  City: 'SOEST',
  Latitude: '51.548.508',
  Longitude: '813.312',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.548508,8.13312',
  id: '51.548508,8.13312'
}, {
  Brand: 'ARAL',
  Street: 'Scherzachstraße 4',
  PostCode: 88281,
  City: 'Schlier',
  Latitude: '477.720.720',
  Longitude: '96.706.400',
  Telephone: '7529912202',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '18:00',
  IsOpen24Hours: 'Nein',
  location: '47.772072,9.67064',
  id: '47.772072,9.67064'
}, {
  Brand: 'ARAL',
  Street: 'Monzenbend 8',
  PostCode: 53894,
  City: 'Mechernich',
  Latitude: '506.186.820',
  Longitude: '66.517.070',
  Telephone: '2443310120',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.618682,6.651707',
  id: '50.618682,6.651707'
}, {
  Brand: 'OMV',
  Street: 'Priener Str. 10',
  PostCode: 83233,
  City: 'Bernau',
  Latitude: '4.781.329.000',
  Longitude: '1.237.437.000',
  Telephone: '+49-8051-89297',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.81329,12.37437',
  id: '47.81329,12.37437'
}, {
  Brand: 'ARAL',
  Street: 'BILDECHINGER STEIGE 64',
  PostCode: 72160,
  City: 'HORB AM NECKAR',
  Latitude: '484.555.750',
  Longitude: '86.990.150',
  Telephone: '74515506927',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.455575,8.699015',
  id: '48.455575,8.699015'
}, {
  Brand: 'Eni',
  Street: 'Guelzower Str. 15A',
  PostCode: 12619,
  City: 'Berlin',
  Latitude: '5.251.444.203',
  Longitude: '1.358.190.072',
  Telephone: '+49 (0) 30562931',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '52.51444203,13.58190072',
  id: '52.51444203,13.58190072'
}, {
  Brand: 'OMV',
  Street: 'Fürther Str. 60',
  PostCode: 90587,
  City: 'Veitsbronn',
  Latitude: '4.950.440.000',
  Longitude: '1.089.378.000',
  Telephone: '+49-911-7540929',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.5044,10.89378',
  id: '49.5044,10.89378'
}, {
  Brand: 'OMV',
  Street: 'Franz-Ludwig-Str. 14',
  PostCode: 97072,
  City: 'Würzburg',
  Latitude: '4.978.376.000',
  Longitude: '993.894.000',
  Telephone: '+49-931-74128',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.78376,9.93894',
  id: '49.78376,9.93894'
}, {
  Brand: 'OMV',
  Street: 'Bahnhofstr. 10',
  PostCode: 73240,
  City: 'Wendlingen',
  Latitude: '4.866.993.000',
  Longitude: '937.302.000',
  Telephone: '+49-7024-7152',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.66993,9.37302',
  id: '48.66993,9.37302'
}, {
  Brand: 'ARAL',
  Street: 'A44',
  PostCode: 59457,
  City: 'Werl',
  Latitude: '515.334.290',
  Longitude: '78.633.550',
  Telephone: '29225299',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.533429,7.863355',
  id: '51.533429,7.863355'
}, {
  Brand: 'ARAL',
  Street: 'Im langen Feld 17',
  PostCode: 71711,
  City: 'Murr',
  Latitude: '489.571.290',
  Longitude: '92.435.260',
  Telephone: '714429235',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.957129,9.243526',
  id: '48.957129,9.243526'
}, {
  Brand: 'Eni',
  Street: 'Bernecker Str.52',
  PostCode: 95448,
  City: 'Bayreuth',
  Latitude: '4.995.470.000',
  Longitude: '1.160.095.000',
  Telephone: '0921/78519303',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.9547,11.60095',
  id: '49.9547,11.60095'
}, {
  Brand: 'TOTAL',
  Street: '1 HERMANN-LOENS-WEG',
  PostCode: 25462,
  City: 'RELLINGEN',
  Latitude: '53.638.707',
  Longitude: '9.893.509',
  Telephone: '04101-34646',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.638707,9.893509',
  id: '53.638707,9.893509'
}, {
  Brand: 'Westfalen',
  Street: 'Alte Wittener Str. 40',
  PostCode: null,
  City: 'BOCHUM',
  Latitude: '51.468.118',
  Longitude: '7.271.057',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.468118,7.271057',
  id: '51.468118,7.271057'
}, {
  Brand: 'ARAL',
  Street: 'Auf der Held 2',
  PostCode: 56305,
  City: 'Puderbach',
  Latitude: '505.933.210',
  Longitude: '76.074.900',
  Telephone: '2684978878',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.593321,7.60749',
  id: '50.593321,7.60749'
}, {
  Brand: 'Westfalen',
  Street: 'Gahlenstr. 105',
  PostCode: null,
  City: 'DORSTEN',
  Latitude: '516.568',
  Longitude: '6.949',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.6568,6.949',
  id: '51.6568,6.949'
}, {
  Brand: 'TOTAL',
  Street: 'BAB BIGGENKOPF SUED',
  PostCode: 34474,
  City: 'DIEMELSTADT',
  Latitude: '51.499.488',
  Longitude: '8.983.397',
  Telephone: '05642-98100',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.499488,8.983397',
  id: '51.499488,8.983397'
}, {
  Brand: 'Westfalen',
  Street: 'Buchenweg 107',
  PostCode: null,
  City: 'OBERHAUSEN',
  Latitude: '515.455',
  Longitude: '682.915',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.5455,6.82915',
  id: '51.5455,6.82915'
}, {
  Brand: 'TOTAL',
  Street: 'HEIDESTR. 5',
  PostCode: 39291,
  City: 'GENTHIN OT SCHOPSDORF',
  Latitude: '52.247.771',
  Longitude: '12.249.149',
  Telephone: '39225395990',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.247771,12.249149',
  id: '52.247771,12.249149'
}, {
  Brand: 'TOTAL',
  Street: '8 ALDINGER STR.',
  PostCode: 71686,
  City: 'REMSECK',
  Latitude: '48.875.278',
  Longitude: '9.270.556',
  Telephone: '07146-810047',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.875278,9.270556',
  id: '48.875278,9.270556'
}, {
  Brand: 'TOTAL',
  Street: 'EUROPAALLEE 5A',
  PostCode: 28309,
  City: 'BREMEN',
  Latitude: '53.034.854',
  Longitude: '8.886.761',
  Telephone: '0421-48536565',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.034854,8.886761',
  id: '53.034854,8.886761'
}, {
  Brand: 'TOTAL',
  Street: 'GENTHINER STR. 59',
  PostCode: 14712,
  City: 'RATHENOW',
  Latitude: '525.994',
  Longitude: '12.297.083',
  Telephone: '03385-512175',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.5994,12.297083',
  id: '52.5994,12.297083'
}, {
  Brand: 'ARAL',
  Street: 'Wörishofer Straße 23',
  PostCode: 86842,
  City: 'Türkheim',
  Latitude: '480.554.260',
  Longitude: '106.369.160',
  Telephone: '8245962111',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.055426,10.636916',
  id: '48.055426,10.636916'
}, {
  Brand: 'ARAL',
  Street: 'Weißenhorner Straße 56',
  PostCode: 89297,
  City: 'Roggenburg-Biberach',
  Latitude: '482.880.640',
  Longitude: '102.139.910',
  Telephone: '73005184',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.288064,10.213991',
  id: '48.288064,10.213991'
}, {
  Brand: 'TOTAL',
  Street: 'COTTBUSER STRASSE 12 A',
  PostCode: 3185,
  City: 'PEITZ',
  Latitude: '51.853.187',
  Longitude: '14.406.464',
  Telephone: '035601-80645',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.853187,14.406464',
  id: '51.853187,14.406464'
}, {
  Brand: 'TOTAL',
  Street: 'WERLER STR. 370',
  PostCode: 59069,
  City: 'HAMM',
  Latitude: '51.645.934',
  Longitude: '7.842.771',
  Telephone: '02381-51543',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.645934,7.842771',
  id: '51.645934,7.842771'
}, {
  Brand: 'TOTAL',
  Street: 'NECKARBURG OST A81',
  PostCode: 78661,
  City: 'DIETINGEN',
  Latitude: '4.820.581',
  Longitude: '862.513',
  Telephone: '0741 8894',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.20581,8.62513',
  id: '48.20581,8.62513'
}, {
  Brand: 'Eni',
  Street: 'Thomas Muentzer Str. 74',
  PostCode: 6217,
  City: 'Merseburg',
  Latitude: '5.135.263.000',
  Longitude: '1.198.054.000',
  Telephone: '34618219700',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.35263,11.98054',
  id: '51.35263,11.98054'
}, {
  Brand: 'TOTAL',
  Street: 'RUDOLF-DIESEL-STR. 3-5',
  PostCode: 56566,
  City: 'NEUWIED',
  Latitude: '50.443.414',
  Longitude: '7.498.813',
  Telephone: '02631-9427150',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.443414,7.498813',
  id: '50.443414,7.498813'
}, {
  Brand: 'Eni',
  Street: 'Bayerstrasse 4',
  PostCode: 83308,
  City: 'Trostberg',
  Latitude: '4.802.707.070',
  Longitude: '1.255.643.400',
  Telephone: '+49 (0) 86212419',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.0270707,12.556434',
  id: '48.0270707,12.556434'
}, {
  Brand: 'TOTAL',
  Street: 'AN DER BORNHOHL 56',
  PostCode: 60437,
  City: 'FRANKFURT',
  Latitude: '50.196.435',
  Longitude: '8.666.941',
  Telephone: '069-5071584',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.196435,8.666941',
  id: '50.196435,8.666941'
}, {
  Brand: 'ARAL',
  Street: 'Dechenstraße 17',
  PostCode: 44147,
  City: 'Dortmund',
  Latitude: '515.198.280',
  Longitude: '74.400.830',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.519828,7.440083',
  id: '51.519828,7.440083'
}, {
  Brand: 'ARAL',
  Street: 'Bunsenstr. 3',
  PostCode: 85053,
  City: 'Ingolstadt',
  Latitude: '487.426.060',
  Longitude: '114.739.100',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.742606,11.47391',
  id: '48.742606,11.47391'
}, {
  Brand: 'ARAL',
  Street: 'Franziusstr. 25',
  PostCode: 60314,
  City: 'Frankfurt',
  Latitude: '501.115.920',
  Longitude: '87.286.890',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.111592,8.728689',
  id: '50.111592,8.728689'
}, {
  Brand: 'Eni',
  Street: 'Kamenzer Strasse 34',
  PostCode: 1896,
  City: 'Pulsnitz',
  Latitude: '5.120.022.925',
  Longitude: '1.402.258.282',
  Telephone: '+49 (0) 35955442',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.20022925,14.02258282',
  id: '51.20022925,14.02258282'
}, {
  Brand: 'Eni',
  Street: 'Oberthann 9',
  PostCode: 85301,
  City: 'Schweitenkirchen',
  Latitude: '4.850.747.000',
  Longitude: '1.158.537.000',
  Telephone: '+49 (0) 8444333',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.50747,11.58537',
  id: '48.50747,11.58537'
}, {
  Brand: 'Eni',
  Street: 'Potsdamer Strasse 31-32',
  PostCode: 14163,
  City: 'Berlin',
  Latitude: '5.243.433.082',
  Longitude: '1.324.911.747',
  Telephone: '+49 (0) 30802968',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.43433082,13.24911747',
  id: '52.43433082,13.24911747'
}, {
  Brand: 'OMV',
  Street: 'Katzwanger Str. 20',
  PostCode: 91126,
  City: 'Schwabach-Limbach',
  Latitude: '4.934.247.000',
  Longitude: '1.104.698.000',
  Telephone: '+49-9122-693232',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.34247,11.04698',
  id: '49.34247,11.04698'
}, {
  Brand: 'Westfalen',
  Street: 'Gesmolder Str. 61',
  PostCode: null,
  City: 'MELLE',
  Latitude: '521.996',
  Longitude: '832.536',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.1996,8.32536',
  id: '52.1996,8.32536'
}, {
  Brand: 'OMV',
  Street: 'Mannheimer Strasse 131',
  PostCode: 68723,
  City: 'Schwetzingen',
  Latitude: '4.939.214.000',
  Longitude: '856.455.000',
  Telephone: '+49-6202-4443',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.39214,8.56455',
  id: '49.39214,8.56455'
}, {
  Brand: 'Eni',
  Street: 'Buergermeister-Hefele-Strasse 1',
  PostCode: 86609,
  City: 'Donauwoerth',
  Latitude: '4.871.945.000',
  Longitude: '1.077.674.000',
  Telephone: '+49 (0) 90670556',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.71945,10.77674',
  id: '48.71945,10.77674'
}, {
  Brand: 'ARAL',
  Street: 'Dieselstr. 3',
  PostCode: 63110,
  City: 'Rodgau-Niederroden',
  Latitude: '499.879.060',
  Longitude: '88.680.040',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.987906,8.868004',
  id: '49.987906,8.868004'
}, {
  Brand: 'Westfalen',
  Street: 'Oldenburger Str. 87',
  PostCode: null,
  City: 'DELMENHORST',
  Latitude: '530.492',
  Longitude: '860.549',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.0492,8.60549',
  id: '53.0492,8.60549'
}, {
  Brand: 'Westfalen',
  Street: 'Schoetmarsche Str. 356',
  PostCode: null,
  City: 'LAGE',
  Latitude: '520.234',
  Longitude: '876.142',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0234,8.76142',
  id: '52.0234,8.76142'
}, {
  Brand: 'TOTAL',
  Street: 'ROEDGENER STR.3',
  PostCode: 4838,
  City: 'EILENBURG',
  Latitude: '514.581',
  Longitude: '12.614.588',
  Telephone: '03423-602911',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.4581,12.614588',
  id: '51.4581,12.614588'
}, {
  Brand: 'TOTAL',
  Street: 'WIENER STR. 39',
  PostCode: 1069,
  City: 'DRESDEN',
  Latitude: '51.036.099',
  Longitude: '13.748.482',
  Telephone: '0351-4710616',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.036099,13.748482',
  id: '51.036099,13.748482'
}, {
  Brand: 'Eni',
  Street: 'Rheinstr. 1a',
  PostCode: 56242,
  City: 'Selters (Westerwald)',
  Latitude: '5.052.831.000',
  Longitude: '775.815.000',
  Telephone: '02626/5208',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.52831,7.75815',
  id: '50.52831,7.75815'
}, {
  Brand: 'Avanti',
  Street: 'Espenpark 11',
  PostCode: 90559,
  City: 'Burgthann',
  Latitude: '4.931.851.000',
  Longitude: '1.133.914.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.31851,11.33914',
  id: '49.31851,11.33914'
}, {
  Brand: 'TOTAL',
  Street: 'FREIENHUFENER ECK-OST/ A13',
  PostCode: 1994,
  City: 'DROCHOW',
  Latitude: '51.558.599',
  Longitude: '13.920.292',
  Telephone: '357543650151',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.558599,13.920292',
  id: '51.558599,13.920292'
}, {
  Brand: 'OMV',
  Street: 'Fürther Str. 2',
  PostCode: 90574,
  City: 'Roßtal',
  Latitude: '4.939.935.000',
  Longitude: '1.088.638.000',
  Telephone: '+49-9127-9079612',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.39935,10.88638',
  id: '49.39935,10.88638'
}, {
  Brand: 'TOTAL',
  Street: 'KAELBERAUER STR. 12',
  PostCode: 63755,
  City: 'ALZENAU',
  Latitude: '5.009.877',
  Longitude: '9.100.041',
  Telephone: '06023-1829',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.09877,9.100041',
  id: '50.09877,9.100041'
}, {
  Brand: 'TOTAL',
  Street: 'AM KREUZSEE 1',
  PostCode: 19412,
  City: 'BRUEEL',
  Latitude: '53.729.394',
  Longitude: '11.690.909',
  Telephone: '038483-22231',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.729394,11.690909',
  id: '53.729394,11.690909'
}, {
  Brand: 'ARAL',
  Street: 'Wittener Straße 66',
  PostCode: 44789,
  City: 'Bochum',
  Latitude: '514.774.850',
  Longitude: '72.277.670',
  Telephone: '234335915',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.477485,7.227767',
  id: '51.477485,7.227767'
}, {
  Brand: 'ARAL',
  Street: 'Landdammstr. 44-48',
  PostCode: 65462,
  City: 'Ginsheim',
  Latitude: '499.906.730',
  Longitude: '83.138.660',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.990673,8.313866',
  id: '49.990673,8.313866'
}, {
  Brand: 'TOTAL',
  Street: '1 MINKELDONK',
  PostCode: 47495,
  City: 'RHEINBERG',
  Latitude: '51.534.471',
  Longitude: '6.588.898',
  Telephone: '02843-9596456',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.534471,6.588898',
  id: '51.534471,6.588898'
}, {
  Brand: 'TOTAL',
  Street: 'OSTERHOFENER STR. 1',
  PostCode: 93055,
  City: 'REGENSBURG',
  Latitude: '49.013.185',
  Longitude: '12.139.661',
  Telephone: '0941 59874567',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.013185,12.139661',
  id: '49.013185,12.139661'
}, {
  Brand: 'Eni',
  Street: 'Ahrensfelder Chaussee 152',
  PostCode: 12689,
  City: 'Berlin-Marzahn',
  Latitude: '5.257.228.787',
  Longitude: '1.356.490.688',
  Telephone: '+49 (0) 30937439',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '52.57228787,13.56490688',
  id: '52.57228787,13.56490688'
}, {
  Brand: 'Eni',
  Street: 'Chamer Strasse 48',
  PostCode: 94315,
  City: 'Straubing',
  Latitude: '4.888.767.000',
  Longitude: '1.257.400.000',
  Telephone: '+49 (0) 94211236',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.88767,12.574',
  id: '48.88767,12.574'
}, {
  Brand: 'TOTAL',
  Street: 'KOENIGSBRUECKER STR. 104',
  PostCode: 1099,
  City: 'DRESDEN',
  Latitude: '5.108.313',
  Longitude: '1.376.156',
  Telephone: '0351-8106054',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.08313,13.76156',
  id: '51.08313,13.76156'
}, {
  Brand: 'OMV',
  Street: 'Regensburger Str. 85',
  PostCode: 90478,
  City: 'Nürnberg',
  Latitude: '4.944.332.000',
  Longitude: '1.110.394.000',
  Telephone: '+49-911-465486',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.44332,11.10394',
  id: '49.44332,11.10394'
}, {
  Brand: 'ARAL',
  Street: 'Hamburger Str. 5',
  PostCode: 40221,
  City: 'Düsseldorf',
  Latitude: '512.150.200',
  Longitude: '67.387.730',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.21502,6.738773',
  id: '51.21502,6.738773'
}, {
  Brand: 'TOTAL',
  Street: 'METZER STR. 123',
  PostCode: 66117,
  City: 'SAARBRUECKEN',
  Latitude: '49.208.624',
  Longitude: '6.962.996',
  Telephone: '0681-5846632',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.208624,6.962996',
  id: '49.208624,6.962996'
}, {
  Brand: 'TOTAL',
  Street: 'OBERROEDERSTR.29',
  PostCode: 64859,
  City: 'EPPERTSHAUSEN',
  Latitude: '49.950.335',
  Longitude: '8.840.426',
  Telephone: '06071-31359',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.950335,8.840426',
  id: '49.950335,8.840426'
}, {
  Brand: 'ARAL',
  Street: 'Raiffeisenstraße 2',
  PostCode: 86720,
  City: 'Nördlingen',
  Latitude: '488.568.530',
  Longitude: '104.784.280',
  Telephone: '9081801092',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:30',
  SaturdayFrom: '06:30',
  SaturdayTo: '20:00',
  SundayFrom: '00:00',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.856853,10.478428',
  id: '48.856853,10.478428'
}, {
  Brand: 'Westfalen',
  Street: 'Olfener Str. 11',
  PostCode: null,
  City: 'SELM',
  Latitude: '517.059',
  Longitude: '745.515',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.7059,7.45515',
  id: '51.7059,7.45515'
}, {
  Brand: 'TOTAL',
  Street: 'RIEDLINGER STR. 52',
  PostCode: 88400,
  City: 'BIBERACH',
  Latitude: '48.095.749',
  Longitude: '9.779.529',
  Telephone: '07351-10022',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.095749,9.779529',
  id: '48.095749,9.779529'
}, {
  Brand: 'TOTAL',
  Street: 'WUELFERODE OST',
  PostCode: 30539,
  City: 'HANNOVER',
  Latitude: '52.333.294',
  Longitude: '9.864.092',
  Telephone: '0511-9545544',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.333294,9.864092',
  id: '52.333294,9.864092'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße. 34',
  PostCode: 86459,
  City: 'Gessertshausen',
  Latitude: '483.293.720',
  Longitude: '107.303.300',
  Telephone: '823896130',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.329372,10.73033',
  id: '48.329372,10.73033'
}, {
  Brand: 'ARAL',
  Street: 'Hohenzollernstraße 11',
  PostCode: 88356,
  City: 'Ostrach',
  Latitude: '479.516.130',
  Longitude: '93.835.750',
  Telephone: '',
  WeekDayFrom: '07:30',
  WeekDayTo: '17:30',
  SaturdayFrom: '08:00',
  SaturdayTo: '11:30',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.951613,9.383575',
  id: '47.951613,9.383575'
}, {
  Brand: 'Westfalen',
  Street: 'An der Leimkaul 1',
  PostCode: null,
  City: 'JUELICH',
  Latitude: '509.268',
  Longitude: '637.195',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.9268,6.37195',
  id: '50.9268,6.37195'
}, {
  Brand: 'Eni',
  Street: 'Preysingstr. 58',
  PostCode: 85283,
  City: 'Wolnzach',
  Latitude: '4.859.798.814',
  Longitude: '1.163.000.230',
  Telephone: '+49 (0) 84429119',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.59798814,11.6300023',
  id: '48.59798814,11.6300023'
}, {
  Brand: 'TOTAL',
  Street: 'AN DER B 247',
  PostCode: 99947,
  City: 'SCHOENSTEDT',
  Latitude: '51.124.818',
  Longitude: '1.058.159',
  Telephone: '036022-91919',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.124818,10.58159',
  id: '51.124818,10.58159'
}, {
  Brand: 'Eni',
  Street: 'Spitalhofstrasse 84',
  PostCode: 94032,
  City: 'Passau',
  Latitude: '4.857.020.316',
  Longitude: '1.342.698.438',
  Telephone: '+49 (0) 85170166',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.57020316,13.42698438',
  id: '48.57020316,13.42698438'
}, {
  Brand: 'Eni',
  Street: 'Bernlocher Str. 17',
  PostCode: 72829,
  City: 'Engstingen',
  Latitude: '4.838.574.624',
  Longitude: '930.056.324',
  Telephone: '+49 (0) 71293318',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.38574624,9.30056324',
  id: '48.38574624,9.30056324'
}, {
  Brand: 'Eni',
  Street: 'Kocheler Str. 11',
  PostCode: 83673,
  City: 'Bichl',
  Latitude: '4.771.488.343',
  Longitude: '1.141.361.774',
  Telephone: '+49 (0) 88571263',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.71488343,11.41361774',
  id: '47.71488343,11.41361774'
}, {
  Brand: 'OMV',
  Street: 'Mühldorfer Str. 72',
  PostCode: 84419,
  City: 'Schwindegg',
  Latitude: '4.827.162.000',
  Longitude: '1.226.840.000',
  Telephone: '+49-8082-8029',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.27162,12.2684',
  id: '48.27162,12.2684'
}, {
  Brand: 'Eni',
  Street: 'Eichendorffstr. 20',
  PostCode: 85609,
  City: 'Aschheim',
  Latitude: '4.816.518.378',
  Longitude: '1.172.058.280',
  Telephone: '+49 (0) 89909375',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.16518378,11.7205828',
  id: '48.16518378,11.7205828'
}, {
  Brand: 'TOTAL',
  Street: 'AN DER BUNDESSTR. 19',
  PostCode: 97234,
  City: 'REICHENBERG/ALBERTSHAUSEN',
  Latitude: '49.691.981',
  Longitude: '994.194',
  Telephone: '09334-355',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.691981,9.94194',
  id: '49.691981,9.94194'
}, {
  Brand: 'ARAL',
  Street: 'A7',
  PostCode: 97262,
  City: 'Hausen bei Würzburg',
  Latitude: '499.468.460',
  Longitude: '100.210.160',
  Telephone: '936390820',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.946846,10.021016',
  id: '49.946846,10.021016'
}, {
  Brand: 'ARAL',
  Street: 'Manfred-Roth-Straße 3',
  PostCode: 18196,
  City: 'Dummerstorf',
  Latitude: '540.217.010',
  Longitude: '122.318.560',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.021701,12.231856',
  id: '54.021701,12.231856'
}, {
  Brand: 'TOTAL',
  Street: '22 MAINBERGER STR.',
  PostCode: 97422,
  City: 'SCHWEINFURT',
  Latitude: '50.048.764',
  Longitude: '10.245.451',
  Telephone: '09721-3709616',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.048764,10.245451',
  id: '50.048764,10.245451'
}, {
  Brand: 'TOTAL',
  Street: 'BERLINER STR. 54 A',
  PostCode: 38104,
  City: 'BRAUNSCHWEIG',
  Latitude: '52.280.475',
  Longitude: '10.573.746',
  Telephone: '0531-374141',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.280475,10.573746',
  id: '52.280475,10.573746'
}, {
  Brand: 'ARAL',
  Street: 'Marie-Curie-Straße 2-6',
  PostCode: 46446,
  City: 'Emmerich',
  Latitude: '518.424.210',
  Longitude: '62.752.390',
  Telephone: '282297016',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.842421,6.275239',
  id: '51.842421,6.275239'
}, {
  Brand: 'Eni',
  Street: 'Langenwahler Str. 19',
  PostCode: 15517,
  City: 'Fuerstenwalde',
  Latitude: '5.235.128.331',
  Longitude: '1.407.231.842',
  Telephone: '+49 (0) 33615020',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.35128331,14.07231842',
  id: '52.35128331,14.07231842'
}, {
  Brand: 'ARAL',
  Street: 'Ingolstädter Straße 59',
  PostCode: 80939,
  City: 'München',
  Latitude: '481.929.590',
  Longitude: '115.836.400',
  Telephone: '893185530',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.192959,11.58364',
  id: '48.192959,11.58364'
}, {
  Brand: 'TOTAL',
  Street: 'ANDREAS MEYER STRASSE 63',
  PostCode: 22113,
  City: 'HAMBURG',
  Latitude: '5.351.235',
  Longitude: '1.008.885',
  Telephone: '040-53022628',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.51235,10.08885',
  id: '53.51235,10.08885'
}, {
  Brand: 'TOTAL',
  Street: 'DUERENER STR. 38',
  PostCode: 52372,
  City: 'KREUZAU',
  Latitude: '50.748.786',
  Longitude: '6.490.989',
  Telephone: '02422-503234',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.748786,6.490989',
  id: '50.748786,6.490989'
}, {
  Brand: 'OMV',
  Street: 'Straubinger Str. 9',
  PostCode: 94405,
  City: 'Landau',
  Latitude: '4.867.698.000',
  Longitude: '1.268.942.000',
  Telephone: '+49-9951-590121',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.67698,12.68942',
  id: '48.67698,12.68942'
}, {
  Brand: 'OMV',
  Street: 'Sudetenstr. 67',
  PostCode: 82538,
  City: 'Geretsried',
  Latitude: '4.785.358.000',
  Longitude: '1.149.494.000',
  Telephone: '+49-8171-90547',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.85358,11.49494',
  id: '47.85358,11.49494'
}, {
  Brand: 'TOTAL',
  Street: 'CUXHAVENER STR. 380',
  PostCode: 21149,
  City: 'HAMBURG',
  Latitude: '53.473.037',
  Longitude: '9.846.765',
  Telephone: '040-70380510',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.473037,9.846765',
  id: '53.473037,9.846765'
}, {
  Brand: 'TOTAL',
  Street: 'SONNEBERGER STR. 86',
  PostCode: 96523,
  City: 'STEINACH',
  Latitude: '50.422.285',
  Longitude: '11.166.052',
  Telephone: '036762-31911',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.422285,11.166052',
  id: '50.422285,11.166052'
}, {
  Brand: 'Westfalen',
  Street: 'Raesfelder Str. 24',
  PostCode: null,
  City: 'BORKEN',
  Latitude: '518.385',
  Longitude: '685.822',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8385,6.85822',
  id: '51.8385,6.85822'
}, {
  Brand: 'TOTAL',
  Street: 'BAMBERGER STR. 6',
  PostCode: 96117,
  City: 'MEMMELSDORF',
  Latitude: '49.931.393',
  Longitude: '10.950.915',
  Telephone: '0951-44291',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.931393,10.950915',
  id: '49.931393,10.950915'
}, {
  Brand: 'TOTAL',
  Street: 'MUECHELNER STR. 04',
  PostCode: 6242,
  City: 'BRAUNSBEDRA',
  Latitude: '5.128.373',
  Longitude: '11.875.212',
  Telephone: '034633-22213',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.28373,11.875212',
  id: '51.28373,11.875212'
}, {
  Brand: 'TOTAL',
  Street: 'HAUPTSTR. 56',
  PostCode: 31860,
  City: 'EMMERTHAL',
  Latitude: '52.048.264',
  Longitude: '938.995',
  Telephone: '05155-9199900',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.048264,9.38995',
  id: '52.048264,9.38995'
}, {
  Brand: 'ARAL',
  Street: 'Schmidener Straße 255',
  PostCode: 70374,
  City: 'Stuttgart',
  Latitude: '488.197.590',
  Longitude: '92.434.940',
  Telephone: '7116529222',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.819759,9.243494',
  id: '48.819759,9.243494'
}, {
  Brand: 'TOTAL',
  Street: 'CALWER STR. 304',
  PostCode: 72218,
  City: 'WILDBERG',
  Latitude: '48.636.672',
  Longitude: '8.746.805',
  Telephone: '07054-365',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.636672,8.746805',
  id: '48.636672,8.746805'
}, {
  Brand: 'TOTAL',
  Street: 'AHMSER STR. 95',
  PostCode: 32052,
  City: 'HERFORD',
  Latitude: '52.102.355',
  Longitude: '868.212',
  Telephone: '05221-71087',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.102355,8.68212',
  id: '52.102355,8.68212'
}, {
  Brand: 'ARAL',
  Street: 'Lindigstraße 6',
  PostCode: 63801,
  City: 'Kleinostheim',
  Latitude: '500.244.150',
  Longitude: '90.416.510',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.024415,9.041651',
  id: '50.024415,9.041651'
}, {
  Brand: 'TOTAL',
  Street: 'WERNBERGER STR. 34',
  PostCode: 92536,
  City: 'PFREIMD',
  Latitude: '49.499.364',
  Longitude: '12.184.555',
  Telephone: '09606-91277',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.499364,12.184555',
  id: '49.499364,12.184555'
}, {
  Brand: 'TOTAL',
  Street: 'AM PLATZ DER JUGEND 21',
  PostCode: 6406,
  City: 'BERNBURG',
  Latitude: '51.804.784',
  Longitude: '11.737.749',
  Telephone: '03471-350353',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.804784,11.737749',
  id: '51.804784,11.737749'
}, {
  Brand: 'TOTAL',
  Street: 'WEIMARISCHE STR. 31',
  PostCode: 99438,
  City: 'BAD BERKA',
  Latitude: '50.903.827',
  Longitude: '11.278.862',
  Telephone: '036458-42107',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.903827,11.278862',
  id: '50.903827,11.278862'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße Nord 16',
  PostCode: 69483,
  City: 'Wald-Michelbach',
  Latitude: '495.946.100',
  Longitude: '88.553.100',
  Telephone: '62072481',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.59461,8.85531',
  id: '49.59461,8.85531'
}, {
  Brand: 'Westfalen',
  Street: 'Schullendamm 63',
  PostCode: null,
  City: 'MEPPEN',
  Latitude: '526.916',
  Longitude: '727.288',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.6916,7.27288',
  id: '52.6916,7.27288'
}, {
  Brand: 'OMV',
  Street: 'Bayreuther Str. 2',
  PostCode: 95615,
  City: 'Marktredwitz',
  Latitude: '5.000.306.000',
  Longitude: '1.207.791.000',
  Telephone: '+49-9231-81106',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.00306,12.07791',
  id: '50.00306,12.07791'
}, {
  Brand: 'TOTAL',
  Street: 'BISMARKER STR.82',
  PostCode: 39606,
  City: 'OSTERBURG',
  Latitude: '52.787.241',
  Longitude: '11.742.624',
  Telephone: '03937-82928',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.787241,11.742624',
  id: '52.787241,11.742624'
}, {
  Brand: 'Westfalen',
  Street: 'Grevener Str. 395',
  PostCode: null,
  City: 'MUENSTER',
  Latitude: '520.015',
  Longitude: '761.421',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0015,7.61421',
  id: '52.0015,7.61421'
}, {
  Brand: 'Eni',
  Street: 'Mengener Str. 37',
  PostCode: 88605,
  City: 'Messkirch',
  Latitude: '4.799.306.000',
  Longitude: '911.726.000',
  Telephone: '+49 (0) 75759263',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.99306,9.11726',
  id: '47.99306,9.11726'
}, {
  Brand: 'Westfalen',
  Street: 'Muensterstr. 14',
  PostCode: null,
  City: 'GLANDORF',
  Latitude: '520.814',
  Longitude: '7.9986',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0814,7.9986',
  id: '52.0814,7.9986'
}, {
  Brand: 'Eni',
  Street: 'Paunsdorfer Str. 70',
  PostCode: 4316,
  City: 'Leipzig',
  Latitude: '5.134.236.000',
  Longitude: '1.244.519.000',
  Telephone: '+49 (0) 34165151',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.34236,12.44519',
  id: '51.34236,12.44519'
}, {
  Brand: 'ARAL',
  Street: 'Gernotstr. 3',
  PostCode: 44319,
  City: 'Dortmund',
  Latitude: '515.320.550',
  Longitude: '76.286.900',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.532055,7.62869',
  id: '51.532055,7.62869'
}, {
  Brand: 'Eni',
  Street: 'Gangkofener Str. 73',
  PostCode: 84160,
  City: 'Frontenhausen',
  Latitude: '4.854.516.395',
  Longitude: '1.252.843.920',
  Telephone: '+49 (0) 87321349',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.54516395,12.5284392',
  id: '48.54516395,12.5284392'
}, {
  Brand: 'Eni',
  Street: 'Roemerstrasse 76-78',
  PostCode: 68623,
  City: 'Lampertheim',
  Latitude: '4.959.240.000',
  Longitude: '847.200.000',
  Telephone: '+49 (0) 62069109',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.5924,8.472',
  id: '49.5924,8.472'
}, {
  Brand: 'Eni',
  Street: 'Further Strasse 8',
  PostCode: 93413,
  City: 'Cham',
  Latitude: '4.922.373.000',
  Longitude: '1.267.087.000',
  Telephone: '+49 (0) 99719270',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.22373,12.67087',
  id: '49.22373,12.67087'
}, {
  Brand: 'ARAL',
  Street: 'Cloppenburger Straße 239',
  PostCode: 26133,
  City: 'Oldenburg',
  Latitude: '531.165.740',
  Longitude: '82.143.180',
  Telephone: '44142648',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '53.116574,8.214318',
  id: '53.116574,8.214318'
}, {
  Brand: 'ARAL',
  Street: 'Oberstdorfer Straße 13',
  PostCode: 87435,
  City: 'Kempten',
  Latitude: '477.061.300',
  Longitude: '103.116.610',
  Telephone: '831523530',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '47.70613,10.311661',
  id: '47.70613,10.311661'
}, {
  Brand: 'ARAL',
  Street: 'Junkersstraße',
  PostCode: 4435,
  City: 'Schkeuditz-Glesien',
  Latitude: '514.440.670',
  Longitude: '122.391.320',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.444067,12.239132',
  id: '51.444067,12.239132'
}, {
  Brand: 'TOTAL',
  Street: 'SCHWERINSTR. 9-12',
  PostCode: 3149,
  City: 'FORST',
  Latitude: '51.729.999',
  Longitude: '1.462.808',
  Telephone: '03562-8334',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.729999,14.62808',
  id: '51.729999,14.62808'
}, {
  Brand: 'ARAL',
  Street: 'Ringstraße 3',
  PostCode: 4827,
  City: 'Gerichshain',
  Latitude: '513.555.420',
  Longitude: '125.692.140',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.355542,12.569214',
  id: '51.355542,12.569214'
}, {
  Brand: 'OMV',
  Street: 'Augsburger Str. 42',
  PostCode: 86720,
  City: 'Nördlingen',
  Latitude: '4.884.520.000',
  Longitude: '1.049.990.000',
  Telephone: '+49-9081-22128',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.8452,10.4999',
  id: '48.8452,10.4999'
}, {
  Brand: 'ARAL',
  Street: 'Am alten Bahnhof 1',
  PostCode: 51645,
  City: 'Gummersbach',
  Latitude: '510.033.450',
  Longitude: '75.758.830',
  Telephone: '2261789440',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.003345,7.575883',
  id: '51.003345,7.575883'
}, {
  Brand: 'Westfalen',
  Street: 'Sachsenstr. 149',
  PostCode: null,
  City: 'RECKLINGHAUSEN',
  Latitude: '516.043',
  Longitude: '727.329',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.6043,7.27329',
  id: '51.6043,7.27329'
}, {
  Brand: 'TOTAL',
  Street: 'SCHOELLERSTR. 155',
  PostCode: 52351,
  City: 'DUEREN',
  Latitude: '50.813.242',
  Longitude: '6.488.431',
  Telephone: '02421-14252',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.813242,6.488431',
  id: '50.813242,6.488431'
}, {
  Brand: 'Eni',
  Street: 'Traunsteiner Strasse 19',
  PostCode: 83313,
  City: 'Siegsdorf',
  Latitude: '4.782.500.051',
  Longitude: '1.263.813.139',
  Telephone: '+49 (0) 86626682',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.82500051,12.63813139',
  id: '47.82500051,12.63813139'
}, {
  Brand: 'ARAL',
  Street: 'Saarbrücker Straße 7 c',
  PostCode: 66292,
  City: 'Riegelsberg',
  Latitude: '492.975.400',
  Longitude: '69.479.700',
  Telephone: '680644748',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '49.29754,6.94797',
  id: '49.29754,6.94797'
}, {
  Brand: 'ARAL',
  Street: 'Neue Regensburger Straße 37',
  PostCode: 84030,
  City: 'Ergolding',
  Latitude: '485.588.570',
  Longitude: '121.654.830',
  Telephone: '',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.558857,12.165483',
  id: '48.558857,12.165483'
}, {
  Brand: 'TOTAL',
  Street: 'TROSSINGER STR. 41',
  PostCode: 78609,
  City: 'TUNINGEN',
  Latitude: '48.034.742',
  Longitude: '8.609.986',
  Telephone: '07464-529989-0',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.034742,8.609986',
  id: '48.034742,8.609986'
}, {
  Brand: 'ARAL',
  Street: 'A 3 / Autobahnraststätte Ohligser H',
  PostCode: 42697,
  City: 'Solingen',
  Latitude: '511.589.860',
  Longitude: '69.610.830',
  Telephone: '212645370',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.158986,6.961083',
  id: '51.158986,6.961083'
}, {
  Brand: 'OMV',
  Street: 'Landshuter Strasse 61',
  PostCode: 84137,
  City: 'Vilsbiburg',
  Latitude: '4.845.449.000',
  Longitude: '1.233.471.000',
  Telephone: '+49-8741-967284',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.45449,12.33471',
  id: '48.45449,12.33471'
}, {
  Brand: 'Westfalen',
  Street: 'Herner Strasse 249',
  PostCode: null,
  City: 'RECKLINGHAUSEN',
  Latitude: '515.834',
  Longitude: '7.2126',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.5834,7.2126',
  id: '51.5834,7.2126'
}, {
  Brand: 'Eni',
  Street: 'Spremberger Str.8/ B169 (Gewerbegeb',
  PostCode: 3116,
  City: 'Drebkau',
  Latitude: '5.164.623.900',
  Longitude: '1.423.759.500',
  Telephone: '+49 (0) 35602510',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.646239,14.237595',
  id: '51.646239,14.237595'
}, {
  Brand: 'ARAL',
  Street: 'Wilhelm-Hauff-Straße 43',
  PostCode: 74906,
  City: 'Bad Rappenau',
  Latitude: '492.108.290',
  Longitude: '90.701.050',
  Telephone: '7066915353',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.210829,9.070105',
  id: '49.210829,9.070105'
}, {
  Brand: 'Eni',
  Street: 'Rothenburger Strasse 32',
  PostCode: 90522,
  City: 'Oberasbach',
  Latitude: '4.943.594.000',
  Longitude: '1.097.738.000',
  Telephone: '+49 (0) 91169221',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.43594,10.97738',
  id: '49.43594,10.97738'
}, {
  Brand: 'OMV',
  Street: 'Mitterteicher Strasse 11',
  PostCode: 95643,
  City: 'Tirschenreuth',
  Latitude: '4.988.232.000',
  Longitude: '1.232.648.000',
  Telephone: '+49-9631-1706',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.88232,12.32648',
  id: '49.88232,12.32648'
}, {
  Brand: 'Eni',
  Street: 'Buergermeister-Schlosser-Str. 2',
  PostCode: 86199,
  City: 'Augsburg',
  Latitude: '4.831.878.000',
  Longitude: '1.086.128.000',
  Telephone: '0821/99863460',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.31878,10.86128',
  id: '48.31878,10.86128'
}, {
  Brand: 'OMV',
  Street: 'Wiesbadener Str. 43',
  PostCode: 90427,
  City: 'Nürnberg',
  Latitude: '4.952.286.000',
  Longitude: '1.100.714.000',
  Telephone: '+49-911-9301099',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.52286,11.00714',
  id: '49.52286,11.00714'
}, {
  Brand: 'ARAL',
  Street: 'Rudolf-Diesel-Str. 11',
  PostCode: 34253,
  City: 'Kassel',
  Latitude: '512.524.560',
  Longitude: '94.088.120',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.252456,9.408812',
  id: '51.252456,9.408812'
}, {
  Brand: 'Westfalen',
  Street: 'Astruper Str. 168',
  PostCode: null,
  City: 'BAT HUNTETAL WEST WARDENBURG',
  Latitude: '530.525',
  Longitude: '8.2061',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.0525,8.2061',
  id: '53.0525,8.2061'
}, {
  Brand: 'Eni',
  Street: 'Gelnhaeuser Str. 1-3',
  PostCode: 63517,
  City: 'Rodenbach',
  Latitude: '5.014.729.573',
  Longitude: '902.503.720',
  Telephone: '+49 (0) 61845050',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.14729573,9.0250372',
  id: '50.14729573,9.0250372'
}, {
  Brand: 'Westfalen',
  Street: 'Wanheimer Str. 225',
  PostCode: null,
  City: 'DUISBURG',
  Latitude: '51.243.516',
  Longitude: '6.450.886',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.243516,6.450886',
  id: '51.243516,6.450886'
}, {
  Brand: 'TOTAL',
  Street: 'ROENTGENSTR. 1',
  PostCode: 63526,
  City: 'ERLENSEE',
  Latitude: '50.182.387',
  Longitude: '9.007.049',
  Telephone: '06183-8007810',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.182387,9.007049',
  id: '50.182387,9.007049'
}, {
  Brand: 'Westfalen',
  Street: 'Hamelner Strasse 26',
  PostCode: null,
  City: 'LEMGO',
  Latitude: '52.026',
  Longitude: '891.649',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.026,8.91649',
  id: '52.026,8.91649'
}, {
  Brand: 'Eni',
  Street: 'Tuerkenfelder Strasse 2',
  PostCode: 82272,
  City: 'Moorenweis',
  Latitude: '4.814.694.000',
  Longitude: '1.107.282.000',
  Telephone: '81469979172',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.14694,11.07282',
  id: '48.14694,11.07282'
}, {
  Brand: 'TOTAL',
  Street: 'FRANKFURTER STR. 92',
  PostCode: 65830,
  City: 'KRIFTEL',
  Latitude: '50.088.748',
  Longitude: '8.477.606',
  Telephone: '06192-46296',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.088748,8.477606',
  id: '50.088748,8.477606'
}, {
  Brand: 'TOTAL',
  Street: '100 UNTERSTRASSE',
  PostCode: 44892,
  City: 'BOCHUM',
  Latitude: '51.469.916',
  Longitude: '7.306.949',
  Telephone: '0234-9272073',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.469916,7.306949',
  id: '51.469916,7.306949'
}, {
  Brand: 'ARAL',
  Street: 'Martin-Schleyer-Straße 6',
  PostCode: 78465,
  City: 'Konstanz',
  Latitude: '477.123.230',
  Longitude: '91.734.290',
  Telephone: '',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '47.712323,9.173429',
  id: '47.712323,9.173429'
}, {
  Brand: 'Eni',
  Street: 'Landstr. 3',
  PostCode: 84364,
  City: 'Bad Birnbach',
  Latitude: '4.844.749.000',
  Longitude: '1.304.008.000',
  Telephone: '08563/825',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.44749,13.04008',
  id: '48.44749,13.04008'
}, {
  Brand: 'ARAL',
  Street: 'Ferdinand-Porsche-Ring 18',
  PostCode: 63110,
  City: 'Rodgau',
  Latitude: '500.193.790',
  Longitude: '88.946.310',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.019379,8.894631',
  id: '50.019379,8.894631'
}, {
  Brand: 'ARAL',
  Street: 'Schwere-Reiter-Straße 26',
  PostCode: 80797,
  City: 'München',
  Latitude: '481.610.030',
  Longitude: '115.585.040',
  Telephone: '89188503',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.161003,11.558504',
  id: '48.161003,11.558504'
}, {
  Brand: 'TOTAL',
  Street: 'AM KESSELTAL 4',
  PostCode: 55129,
  City: 'MAINZ-EBERSHEIM',
  Latitude: '49.922.365',
  Longitude: '8.265.815',
  Telephone: '06136-954195',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.922365,8.265815',
  id: '49.922365,8.265815'
}, {
  Brand: 'TOTAL',
  Street: 'BAUTZNER STR. 72',
  PostCode: 1099,
  City: 'DRESDEN',
  Latitude: '51.064.376',
  Longitude: '13.763.571',
  Telephone: '0351-8049180',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.064376,13.763571',
  id: '51.064376,13.763571'
}, {
  Brand: 'OMV',
  Street: 'Heiligenfelder Allee 2',
  PostCode: 97688,
  City: 'Bad Kissingen',
  Latitude: '5.019.138.000',
  Longitude: '1.006.172.000',
  Telephone: '+49-971-7852506',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.19138,10.06172',
  id: '50.19138,10.06172'
}, {
  Brand: 'Avanti',
  Street: 'Bernauer Str. 49',
  PostCode: 83209,
  City: 'Prien',
  Latitude: '4.784.937.000',
  Longitude: '1.234.509.000',
  Telephone: '+49-8051-9639722',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.84937,12.34509',
  id: '47.84937,12.34509'
}, {
  Brand: 'ARAL',
  Street: 'Frankfurter Straße 102 A',
  PostCode: 63500,
  City: 'Seligenstadt',
  Latitude: '500.451.040',
  Longitude: '89.612.010',
  Telephone: '61828206288',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '50.045104,8.961201',
  id: '50.045104,8.961201'
}, {
  Brand: 'ARAL',
  Street: 'Österreicher Straße 64',
  PostCode: 1279,
  City: 'Dresden',
  Latitude: '510.210.010',
  Longitude: '138.405.010',
  Telephone: '3512514937',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.021001,13.840501',
  id: '51.021001,13.840501'
}, {
  Brand: 'OMV',
  Street: 'Alte Str. 39',
  PostCode: 94034,
  City: 'Passau',
  Latitude: '4.858.761.000',
  Longitude: '1.348.278.000',
  Telephone: '+49-851-42985',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.58761,13.48278',
  id: '48.58761,13.48278'
}, {
  Brand: 'TOTAL',
  Street: 'KAPELLENSTR. 29',
  PostCode: 50997,
  City: 'KOELN RONDORF',
  Latitude: '50.874.529',
  Longitude: '6.949.756',
  Telephone: '02233-23640',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.874529,6.949756',
  id: '50.874529,6.949756'
}, {
  Brand: 'TOTAL',
  Street: 'ALTENDERNER STR.90',
  PostCode: 44329,
  City: 'DORTMUND',
  Latitude: '51.569.289',
  Longitude: '7.511.427',
  Telephone: '0231-7289051',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.569289,7.511427',
  id: '51.569289,7.511427'
}, {
  Brand: 'ARAL',
  Street: 'Siemensstraße 9',
  PostCode: 71263,
  City: 'Weil der Stadt',
  Latitude: '487.561.230',
  Longitude: '88.674.360',
  Telephone: '70336005',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.756123,8.867436',
  id: '48.756123,8.867436'
}, {
  Brand: 'Eni',
  Street: 'Tuebinger Str. 26',
  PostCode: 72108,
  City: 'Rottenburg',
  Latitude: '4.847.332.000',
  Longitude: '893.871.000',
  Telephone: '+49 (0) 74729475',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.47332,8.93871',
  id: '48.47332,8.93871'
}, {
  Brand: 'ARAL',
  Street: 'Hallerstraße 1',
  PostCode: 66333,
  City: 'Völklingen-Wehrden',
  Latitude: '492.438.260',
  Longitude: '68.397.800',
  Telephone: '689829375',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.243826,6.83978',
  id: '49.243826,6.83978'
}, {
  Brand: 'OMV',
  Street: 'Christian-Seltmann-Str. 36',
  PostCode: 92637,
  City: 'Weiden',
  Latitude: '4.968.001.000',
  Longitude: '1.215.290.000',
  Telephone: '+49-961-22329',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.68001,12.1529',
  id: '49.68001,12.1529'
}, {
  Brand: 'ARAL',
  Street: 'Hörnleinsgrund 1',
  PostCode: 96450,
  City: 'Coburg',
  Latitude: '502.676.030',
  Longitude: '109.477.550',
  Telephone: '956130721',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.267603,10.947755',
  id: '50.267603,10.947755'
}, {
  Brand: 'Westfalen',
  Street: 'Steinfurter Str. 166',
  PostCode: null,
  City: 'MUENSTER',
  Latitude: '519.767',
  Longitude: '760.132',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9767,7.60132',
  id: '51.9767,7.60132'
}, {
  Brand: 'TOTAL',
  Street: 'BERLINER ALLEE 12',
  PostCode: 63225,
  City: 'LANGEN',
  Latitude: '49.992.269',
  Longitude: '8.649.521',
  Telephone: '06103-71010',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.992269,8.649521',
  id: '49.992269,8.649521'
}, {
  Brand: 'ARAL',
  Street: 'Zeller Straße 15',
  PostCode: 73235,
  City: 'Weilheim',
  Latitude: '486.203.710',
  Longitude: '95.402.330',
  Telephone: '70239564184',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.620371,9.540233',
  id: '48.620371,9.540233'
}, {
  Brand: 'Eni',
  Street: 'Heilbronner Str. 75',
  PostCode: 73635,
  City: 'Rudersberg',
  Latitude: '4.887.864.000',
  Longitude: '953.008.000',
  Telephone: '+49 (0) 71833022',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.87864,9.53008',
  id: '48.87864,9.53008'
}, {
  Brand: 'ARAL',
  Street: 'Bierweg 6',
  PostCode: 4435,
  City: 'Schkeuditz',
  Latitude: '513.980.060',
  Longitude: '121.889.550',
  Telephone: '3420465724',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.398006,12.188955',
  id: '51.398006,12.188955'
}, {
  Brand: 'TOTAL',
  Street: 'UBIERSTR. 8',
  PostCode: 53173,
  City: 'BONN',
  Latitude: '50.694.603',
  Longitude: '7.161.795',
  Telephone: '0228-363298',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.694603,7.161795',
  id: '50.694603,7.161795'
}, {
  Brand: 'TOTAL',
  Street: 'HAUPTSTR. 279',
  PostCode: 44892,
  City: 'BOCHUM-LANGENDREER',
  Latitude: '51.464.658',
  Longitude: '7.321.221',
  Telephone: '0234-286628',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.464658,7.321221',
  id: '51.464658,7.321221'
}, {
  Brand: 'TOTAL',
  Street: 'AM KEESBOOM 2',
  PostCode: 19258,
  City: 'BOIZENBURG',
  Latitude: '53.385.834',
  Longitude: '10.735.236',
  Telephone: '038847-38152',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.385834,10.735236',
  id: '53.385834,10.735236'
}, {
  Brand: 'ARAL',
  Street: 'Düsseldorfer Straße 491',
  PostCode: 51061,
  City: 'Köln',
  Latitude: '509.895.420',
  Longitude: '69.988.220',
  Telephone: '221664829',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.989542,6.998822',
  id: '50.989542,6.998822'
}, {
  Brand: 'TOTAL',
  Street: 'FUERSTENBERGER STR. 1-3',
  PostCode: 33142,
  City: 'BUEREN',
  Latitude: '51.548.447',
  Longitude: '8.563.832',
  Telephone: '02951-2902',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.548447,8.563832',
  id: '51.548447,8.563832'
}, {
  Brand: 'ARAL',
  Street: 'Alpenstraße 5',
  PostCode: 82362,
  City: 'Weilheim',
  Latitude: '478.356.710',
  Longitude: '111.437.470',
  Telephone: '881417593',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.835671,11.143747',
  id: '47.835671,11.143747'
}, {
  Brand: 'Eni',
  Street: 'Wunsiedler Str. 11',
  PostCode: 95032,
  City: 'Hof',
  Latitude: '5.030.473.879',
  Longitude: '1.191.968.644',
  Telephone: '+49 (0) 92819701',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.30473879,11.91968644',
  id: '50.30473879,11.91968644'
}, {
  Brand: 'TOTAL',
  Street: 'KEMNADER-STR. 14',
  PostCode: 44795,
  City: 'BOCHUM',
  Latitude: '51.439.863',
  Longitude: '7.214.262',
  Telephone: '0234-472286',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.439863,7.214262',
  id: '51.439863,7.214262'
}, {
  Brand: 'TOTAL',
  Street: 'KARLSRUHER STR. 61A',
  PostCode: 76351,
  City: 'LINKENHEIM-HOCHSTETTEN',
  Latitude: '49.125.293',
  Longitude: '8.408.952',
  Telephone: '07247-4271',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.125293,8.408952',
  id: '49.125293,8.408952'
}, {
  Brand: 'TOTAL',
  Street: 'AUTOHOF A14 POSTSTRASSE 3',
  PostCode: 4158,
  City: 'LEIPZIG',
  Latitude: '51.413.589',
  Longitude: '12.308.158',
  Telephone: '0341-4616662',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.413589,12.308158',
  id: '51.413589,12.308158'
}, {
  Brand: 'ARAL',
  Street: 'Illertisser Straße 40',
  PostCode: 89165,
  City: 'Dietenheim',
  Latitude: '482.104.910',
  Longitude: '100.773.650',
  Telephone: '73477593',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.210491,10.077365',
  id: '48.210491,10.077365'
}, {
  Brand: 'TOTAL',
  Street: 'BAMBERGER STR. 59/61',
  PostCode: 91456,
  City: 'DIESPECK',
  Latitude: '49.600.769',
  Longitude: '10.625.707',
  Telephone: '09161-885858',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.600769,10.625707',
  id: '49.600769,10.625707'
}, {
  Brand: 'ARAL',
  Street: 'Günzburger Straße 23',
  PostCode: 89362,
  City: 'Offingen',
  Latitude: '484.785.210',
  Longitude: '103.630.520',
  Telephone: '8224967490',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.478521,10.363052',
  id: '48.478521,10.363052'
}, {
  Brand: 'OMV',
  Street: 'Pörnbacher Str. 2',
  PostCode: 86558,
  City: 'Hohenwart',
  Latitude: '4.860.231.000',
  Longitude: '1.142.385.000',
  Telephone: '+49-8443-385',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.60231,11.42385',
  id: '48.60231,11.42385'
}, {
  Brand: 'ARAL',
  Street: 'BAB A 19 - West',
  PostCode: 18276,
  City: 'Recknitz',
  Latitude: '538.400.370',
  Longitude: '122.912.490',
  Telephone: '3845520962',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.840037,12.291249',
  id: '53.840037,12.291249'
}, {
  Brand: 'OMV',
  Street: 'Albrecht-Dürer-Str. 1/2',
  PostCode: 95448,
  City: 'Bayreuth',
  Latitude: '4.994.614.000',
  Longitude: '1.158.320.000',
  Telephone: '+49-921-62122',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.94614,11.5832',
  id: '49.94614,11.5832'
}, {
  Brand: 'Westfalen',
  Street: 'Am alten Bahnhof',
  PostCode: null,
  City: 'ALDENHOVEN',
  Latitude: '508.909',
  Longitude: '627.512',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.8909,6.27512',
  id: '50.8909,6.27512'
}, {
  Brand: 'TOTAL',
  Street: 'ALTER UENTROPER W.62',
  PostCode: 59071,
  City: 'HAMM',
  Latitude: '51.691.042',
  Longitude: '7.869.494',
  Telephone: '02381-80632',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.691042,7.869494',
  id: '51.691042,7.869494'
}, {
  Brand: 'ARAL',
  Street: 'Dieselstraße 16',
  PostCode: 84069,
  City: 'Schierling',
  Latitude: '488.382.730',
  Longitude: '121.230.050',
  Telephone: '94519489202',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.838273,12.123005',
  id: '48.838273,12.123005'
}, {
  Brand: 'Westfalen',
  Street: 'Wilhelmstr. 184',
  PostCode: null,
  City: 'HAMM',
  Latitude: '516.664',
  Longitude: '778.781',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.6664,7.78781',
  id: '51.6664,7.78781'
}, {
  Brand: 'Eni',
  Street: 'Welfenstrasse 32',
  PostCode: 70599,
  City: 'Stuttgart',
  Latitude: '4.871.958.000',
  Longitude: '920.812.000',
  Telephone: '+49 (0) 71145348',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.71958,9.20812',
  id: '48.71958,9.20812'
}, {
  Brand: 'ARAL',
  Street: 'A3',
  PostCode: 65549,
  City: 'Limburg',
  Latitude: '503.853.509',
  Longitude: '80.808.480',
  Telephone: '643171026',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.3853509,8.080848',
  id: '50.3853509,8.080848'
}, {
  Brand: 'Eni',
  Street: 'Ansbacher Strasse 34',
  PostCode: 91575,
  City: 'Windsbach',
  Latitude: '4.924.591.429',
  Longitude: '1.082.309.287',
  Telephone: '+49 (0) 9871246',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.24591429,10.82309287',
  id: '49.24591429,10.82309287'
}, {
  Brand: 'TOTAL',
  Street: 'IM RIEDLE 1',
  PostCode: 74572,
  City: 'BLAUFELDEN',
  Latitude: '49.295.903',
  Longitude: '9.978.714',
  Telephone: '07953-6249980',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.295903,9.978714',
  id: '49.295903,9.978714'
}, {
  Brand: 'TOTAL',
  Street: 'JUETERBOGER CH. 8',
  PostCode: 15936,
  City: 'DAHME',
  Latitude: '51.868.746',
  Longitude: '13.413.132',
  Telephone: '035451-8308',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.868746,13.413132',
  id: '51.868746,13.413132'
}, {
  Brand: 'ARAL',
  Street: 'Wolfegger Straße 2',
  PostCode: 88267,
  City: 'Vogt',
  Latitude: '477.756.320',
  Longitude: '97.709.190',
  Telephone: '7529912991',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '47.775632,9.770919',
  id: '47.775632,9.770919'
}, {
  Brand: 'TOTAL',
  Street: '3 GLINDENBERGER WEG',
  PostCode: 39126,
  City: 'MAGDEBURG',
  Latitude: '52.211.288',
  Longitude: '11.670.514',
  Telephone: '0391-59818744',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.211288,11.670514',
  id: '52.211288,11.670514'
}, {
  Brand: 'ARAL',
  Street: 'Kurt-Schumacher-Damm 12',
  PostCode: 49078,
  City: 'Osnabrück',
  Latitude: '522.706.230',
  Longitude: '80.248.330',
  Telephone: '5414095309',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '52.270623,8.024833',
  id: '52.270623,8.024833'
}, {
  Brand: 'TOTAL',
  Street: 'BERLINER CH.',
  PostCode: 15749,
  City: 'MITTENWALDE',
  Latitude: '52.270.299',
  Longitude: '13.536.795',
  Telephone: '033764-20389',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.270299,13.536795',
  id: '52.270299,13.536795'
}, {
  Brand: 'Westfalen',
  Street: 'In den Bremen 48',
  PostCode: null,
  City: 'SIMMERATH',
  Latitude: '50.607.472',
  Longitude: '6.312.293',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.607472,6.312293',
  id: '50.607472,6.312293'
}, {
  Brand: 'TOTAL',
  Street: 'NORDRING 4',
  PostCode: 6536,
  City: 'SUEDHARZ OT ROSSLA',
  Latitude: '51.463.125',
  Longitude: '11.055.666',
  Telephone: '034651-2548',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.463125,11.055666',
  id: '51.463125,11.055666'
}, {
  Brand: 'OMV',
  Street: 'Landsberger Straße 47 1/3',
  PostCode: 86343,
  City: 'Königsbrunn',
  Latitude: '4.825.679.000',
  Longitude: '1.088.252.000',
  Telephone: '+49-8231-347635',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.25679,10.88252',
  id: '48.25679,10.88252'
}, {
  Brand: 'TOTAL',
  Street: 'VALDERSWEG 99',
  PostCode: 52399,
  City: 'MERZENICH',
  Latitude: '5.081.976',
  Longitude: '6.532.959',
  Telephone: '02421-393988',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.81976,6.532959',
  id: '50.81976,6.532959'
}, {
  Brand: 'TOTAL',
  Street: 'SINZIGER STR. 15',
  PostCode: 53424,
  City: 'REMAGEN',
  Latitude: '50.572.904',
  Longitude: '7.235.282',
  Telephone: '02642-22045',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.572904,7.235282',
  id: '50.572904,7.235282'
}, {
  Brand: 'TOTAL',
  Street: 'AMBERGER STRASSE 37',
  PostCode: 92431,
  City: 'NEUNBURG V.W.',
  Latitude: '49.346.111',
  Longitude: '12.373.611',
  Telephone: '09672-3493',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.346111,12.373611',
  id: '49.346111,12.373611'
}, {
  Brand: 'TOTAL',
  Street: 'ARNSTAEDTER STR. 36',
  PostCode: 99096,
  City: 'ERFURT',
  Latitude: '50.962.852',
  Longitude: '11.033.377',
  Telephone: '0361-3460738',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.962852,11.033377',
  id: '50.962852,11.033377'
}, {
  Brand: 'TOTAL',
  Street: 'WIESBADENER STR. 77',
  PostCode: 65510,
  City: 'IDSTEIN',
  Latitude: '50.216.822',
  Longitude: '8.253.954',
  Telephone: '06126-953888',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.216822,8.253954',
  id: '50.216822,8.253954'
}, {
  Brand: 'OMV',
  Street: 'Friedrich-Eckart-Str. 39',
  PostCode: 81929,
  City: 'München',
  Latitude: '4.814.490.000',
  Longitude: '1.163.951.000',
  Telephone: '+49-89-931717',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.1449,11.63951',
  id: '48.1449,11.63951'
}, {
  Brand: 'TOTAL',
  Street: 'CHEMNITZER STR. 28f',
  PostCode: 9648,
  City: 'MITTWEIDA',
  Latitude: '509.792',
  Longitude: '12.981.795',
  Telephone: '03727-611392',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.9792,12.981795',
  id: '50.9792,12.981795'
}, {
  Brand: 'TOTAL',
  Street: 'USEDOMER STR. 1',
  PostCode: 1968,
  City: 'SENFTENBERG',
  Latitude: '5.150.996',
  Longitude: '13.981.811',
  Telephone: '03573-61085',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.50996,13.981811',
  id: '51.50996,13.981811'
}, {
  Brand: 'Eni',
  Street: 'Haller Str. 210',
  PostCode: 74564,
  City: 'Crailsheim-Rossfeld',
  Latitude: '4.914.136.000',
  Longitude: '1.006.094.000',
  Telephone: '+49 (0) 79512297',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.14136,10.06094',
  id: '49.14136,10.06094'
}, {
  Brand: 'TOTAL',
  Street: 'LEIPZIGER STR. 152',
  PostCode: 14929,
  City: 'TREUENBRIETZEN',
  Latitude: '52.088.299',
  Longitude: '12.851.795',
  Telephone: '033748-15429',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.088299,12.851795',
  id: '52.088299,12.851795'
}, {
  Brand: 'ARAL',
  Street: 'Solmser Gewerbepark 4',
  PostCode: 35606,
  City: 'Solms',
  Latitude: '505.434.900',
  Longitude: '84.111.220',
  Telephone: '6442927192',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.54349,8.411122',
  id: '50.54349,8.411122'
}, {
  Brand: 'ARAL',
  Street: 'Böttcherweg/Sattlerweg',
  PostCode: 4158,
  City: 'Leipzig',
  Latitude: '514.081.020',
  Longitude: '123.034.320',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.408102,12.303432',
  id: '51.408102,12.303432'
}, {
  Brand: 'ARAL',
  Street: 'Lange Göhren',
  PostCode: 39171,
  City: 'Osterweddingen',
  Latitude: '520.512.120',
  Longitude: '116.005.940',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.051212,11.600594',
  id: '52.051212,11.600594'
}, {
  Brand: 'TOTAL',
  Street: 'TROTHAER STRASSE 40',
  PostCode: 6118,
  City: 'HALLE',
  Latitude: '51.519.193',
  Longitude: '1.195.504',
  Telephone: '0345-5234122',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.519193,11.95504',
  id: '51.519193,11.95504'
}, {
  Brand: 'ARAL',
  Street: 'A5',
  PostCode: 61239,
  City: 'Ober-Mörlen',
  Latitude: '503.576.010',
  Longitude: '86.940.810',
  Telephone: '6002939786',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.357601,8.694081',
  id: '50.357601,8.694081'
}, {
  Brand: 'ARAL',
  Street: 'In der Quelle 74',
  PostCode: 63303,
  City: 'Dreieich-Offenthal',
  Latitude: '499.802.220',
  Longitude: '87.365.300',
  Telephone: '60748035221',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.980222,8.73653',
  id: '49.980222,8.73653'
}, {
  Brand: 'Westfalen',
  Street: 'Heeper Str. 413',
  PostCode: null,
  City: 'BIELEFELD',
  Latitude: '520.319',
  Longitude: '859.885',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0319,8.59885',
  id: '52.0319,8.59885'
}, {
  Brand: 'ARAL',
  Street: 'Bliesaue 3',
  PostCode: 66440,
  City: 'Blieskastel',
  Latitude: '492.356.570',
  Longitude: '72.697.830',
  Telephone: '68429219763',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '49.235657,7.269783',
  id: '49.235657,7.269783'
}, {
  Brand: 'Westfalen',
  Street: 'Detmolder 112',
  PostCode: null,
  City: 'PADERBORN',
  Latitude: '517.347',
  Longitude: '8.7669',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.7347,8.7669',
  id: '51.7347,8.7669'
}, {
  Brand: 'OMV',
  Street: 'Industriestr. 16',
  PostCode: 94315,
  City: 'Straubing',
  Latitude: '4.887.343.000',
  Longitude: '1.257.565.000',
  Telephone: '+49-9421-55270',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.87343,12.57565',
  id: '48.87343,12.57565'
}, {
  Brand: 'Eni',
  Street: 'Wuerzburger Str.9',
  PostCode: 74731,
  City: 'Wallduern',
  Latitude: '4.958.159.000',
  Longitude: '937.450.000',
  Telephone: '62829289646',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.58159,9.3745',
  id: '49.58159,9.3745'
}, {
  Brand: 'ARAL',
  Street: 'A 44',
  PostCode: 34289,
  City: 'Zierenberg',
  Latitude: '513.817.990',
  Longitude: '92.368.410',
  Telephone: '560656367981',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.381799,9.236841',
  id: '51.381799,9.236841'
}, {
  Brand: 'ARAL',
  Street: 'Bonner Str. 49',
  PostCode: 53919,
  City: 'Weilerswist',
  Latitude: '507.498.630',
  Longitude: '68.506.060',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.749863,6.850606',
  id: '50.749863,6.850606'
}, {
  Brand: 'ARAL',
  Street: 'A3',
  PostCode: 56412,
  City: 'Heiligenroth',
  Latitude: '504.542.080',
  Longitude: '78.668.160',
  Telephone: '26029163484',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.454208,7.866816',
  id: '50.454208,7.866816'
}, {
  Brand: 'ARAL',
  Street: 'Kraftsdorfer Straße 2',
  PostCode: 7629,
  City: 'Hermsdorf',
  Latitude: '508.892.950',
  Longitude: '118.715.180',
  Telephone: '3660154012',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.889295,11.871518',
  id: '50.889295,11.871518'
}, {
  Brand: 'Westfalen',
  Street: 'Nadorster 287',
  PostCode: null,
  City: 'OLDENBURG',
  Latitude: '531.663',
  Longitude: '822.672',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.1663,8.22672',
  id: '53.1663,8.22672'
}, {
  Brand: 'ARAL',
  Street: 'Luisenstraße 77',
  PostCode: 52477,
  City: 'Alsdorf',
  Latitude: '508.743.260',
  Longitude: '61.697.320',
  Telephone: '24046777544',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.874326,6.169732',
  id: '50.874326,6.169732'
}, {
  Brand: 'Eni',
  Street: 'Am Kletthamer Feld 2',
  PostCode: 85435,
  City: 'Erding',
  Latitude: '4.830.920.300',
  Longitude: '1.188.336.600',
  Telephone: '+49 (0) 81222274',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.309203,11.883366',
  id: '48.309203,11.883366'
}, {
  Brand: 'OMV',
  Street: 'Aalener Str. 74',
  PostCode: 73432,
  City: 'Aalen',
  Latitude: '4.882.309.000',
  Longitude: '1.012.102.000',
  Telephone: '+49-7361-87254',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.82309,10.12102',
  id: '48.82309,10.12102'
}, {
  Brand: 'ARAL',
  Street: 'Beim Neustadtsgüterbahnho',
  PostCode: 28199,
  City: 'Bremen',
  Latitude: '530.745.240',
  Longitude: '87.855.010',
  Telephone: '421500625',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.074524,8.785501',
  id: '53.074524,8.785501'
}, {
  Brand: 'OMV',
  Street: 'Erdinger Allee 2',
  PostCode: 85356,
  City: 'München Flughafen',
  Latitude: '4.835.972.000',
  Longitude: '1.182.388.000',
  Telephone: '+49-89-97051461',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.35972,11.82388',
  id: '48.35972,11.82388'
}, {
  Brand: 'Westfalen',
  Street: 'Windelsbleicher 101',
  PostCode: null,
  City: 'BIELEFELD',
  Latitude: '519.766',
  Longitude: '851.832',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9766,8.51832',
  id: '51.9766,8.51832'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 41',
  PostCode: 19230,
  City: 'Bandenitz',
  Latitude: '534.913.400',
  Longitude: '112.822.500',
  Telephone: '388505344',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.49134,11.28225',
  id: '53.49134,11.28225'
}, {
  Brand: 'TOTAL',
  Street: 'OSTERLANGE 1',
  PostCode: 99189,
  City: 'ELXLEBEN',
  Latitude: '51.048.005',
  Longitude: '10.942.121',
  Telephone: '036201-86263',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.048005,10.942121',
  id: '51.048005,10.942121'
}, {
  Brand: 'OMV',
  Street: 'An der B36 101',
  PostCode: 76287,
  City: 'Rheinstetten',
  Latitude: '4.898.699.000',
  Longitude: '833.329.000',
  Telephone: '+49-721-510165',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.98699,8.33329',
  id: '48.98699,8.33329'
}, {
  Brand: 'Eni',
  Street: 'Badenstr. 6',
  PostCode: 76467,
  City: 'Bietigheim',
  Latitude: '4.890.025.177',
  Longitude: '824.904.179',
  Telephone: '+49 (0) 72459381',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.90025177,8.24904179',
  id: '48.90025177,8.24904179'
}, {
  Brand: 'TOTAL',
  Street: '87 LOESSNITZER STR.',
  PostCode: 8280,
  City: 'AUE',
  Latitude: '50.595.584',
  Longitude: '1.270.855',
  Telephone: '03771-2765355',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.595584,12.70855',
  id: '50.595584,12.70855'
}, {
  Brand: 'TOTAL',
  Street: 'DRESDENER STR. 95',
  PostCode: 4758,
  City: 'OSCHATZ',
  Latitude: '51.290.311',
  Longitude: '13.135.587',
  Telephone: '03435-929730',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.290311,13.135587',
  id: '51.290311,13.135587'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 104',
  PostCode: 86381,
  City: 'Krumbach',
  Latitude: '482.517.870',
  Longitude: '103.566.840',
  Telephone: '82822492',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.251787,10.356684',
  id: '48.251787,10.356684'
}, {
  Brand: 'ARAL',
  Street: 'Heedfelder Straße 92',
  PostCode: 58509,
  City: 'Lüdenscheid',
  Latitude: '512.293.660',
  Longitude: '76.118.570',
  Telephone: '23516913',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:30',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.229366,7.611857',
  id: '51.229366,7.611857'
}, {
  Brand: 'Eni',
  Street: 'Zur Eisenschmiede 2',
  PostCode: 87480,
  City: 'Weitnau/Hofen',
  Latitude: '4.765.608.000',
  Longitude: '1.008.856.000',
  Telephone: '08375/975773',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '47.65608,10.08856',
  id: '47.65608,10.08856'
}, {
  Brand: 'TOTAL',
  Street: 'MARIENBORNER STR.204',
  PostCode: 57074,
  City: 'SIEGEN',
  Latitude: '50.876.417',
  Longitude: '8.039.582',
  Telephone: '0271-62105',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.876417,8.039582',
  id: '50.876417,8.039582'
}, {
  Brand: 'TOTAL',
  Street: 'HOCKENHEIMER STR. 108',
  PostCode: 68775,
  City: 'KETSCH',
  Latitude: '49.360.433',
  Longitude: '8.526.936',
  Telephone: '06202-692190',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.360433,8.526936',
  id: '49.360433,8.526936'
}, {
  Brand: 'TOTAL',
  Street: 'DORTMUNDER STR. 174',
  PostCode: 59077,
  City: 'HAMM',
  Latitude: '51.660.755',
  Longitude: '773.613',
  Telephone: '02381-461785',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.660755,7.73613',
  id: '51.660755,7.73613'
}, {
  Brand: 'TOTAL',
  Street: '61 NUERNBERGER STR.',
  PostCode: 86720,
  City: 'NOERDLINGEN',
  Latitude: '48.860.782',
  Longitude: '10.502.197',
  Telephone: '09081-6011',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.860782,10.502197',
  id: '48.860782,10.502197'
}, {
  Brand: 'TOTAL',
  Street: '45 SCHWERINER CHAUSSEE',
  PostCode: 19370,
  City: 'PARCHIM',
  Latitude: '53.439.546',
  Longitude: '11.845.423',
  Telephone: '03871-267297',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.439546,11.845423',
  id: '53.439546,11.845423'
}, {
  Brand: 'TOTAL',
  Street: 'EMMERICHER STRASSE 99',
  PostCode: 47533,
  City: 'KLEVE',
  Latitude: '51.800.822',
  Longitude: '6.162.648',
  Telephone: '02821-980082',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.800822,6.162648',
  id: '51.800822,6.162648'
}, {
  Brand: 'TOTAL',
  Street: 'MILOWER LANDSTR.35b',
  PostCode: 14712,
  City: 'RATHENOW',
  Latitude: '52.595.299',
  Longitude: '1.233.808',
  Telephone: '03385-511431',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.595299,12.33808',
  id: '52.595299,12.33808'
}, {
  Brand: 'TOTAL',
  Street: 'HAART 225',
  PostCode: 24539,
  City: 'NEUMUENSTER',
  Latitude: '54.060.944',
  Longitude: '10.010.858',
  Telephone: '04321-71272',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.060944,10.010858',
  id: '54.060944,10.010858'
}, {
  Brand: 'TOTAL',
  Street: '110 JESINGHAUSER STR.',
  PostCode: 42389,
  City: 'WUPPERTAL',
  Latitude: '51.282.761',
  Longitude: '7.256.123',
  Telephone: '0202-70516555',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.282761,7.256123',
  id: '51.282761,7.256123'
}, {
  Brand: 'TOTAL',
  Street: 'VOERSTETTER STR. 40',
  PostCode: 79194,
  City: 'GUNDELFINGEN',
  Latitude: '48.047.007',
  Longitude: '786.346',
  Telephone: '0761-585928',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.047007,7.86346',
  id: '48.047007,7.86346'
}, {
  Brand: 'ARAL',
  Street: 'Siemensstraße 57',
  PostCode: 7546,
  City: 'Gera',
  Latitude: '509.077.940',
  Longitude: '120.665.300',
  Telephone: '365415045',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.907794,12.06653',
  id: '50.907794,12.06653'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 46-48',
  PostCode: 69488,
  City: 'Birkenau',
  Latitude: '495.616.590',
  Longitude: '86.996.990',
  Telephone: '620131714',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.561659,8.699699',
  id: '49.561659,8.699699'
}, {
  Brand: 'TOTAL',
  Street: 'AUTOHOF BAB 7 / AS LUTTERBERG',
  PostCode: 34355,
  City: 'STAUFENBERG',
  Latitude: '51.371.034',
  Longitude: '9.633.298',
  Telephone: '05543-302710',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.371034,9.633298',
  id: '51.371034,9.633298'
}, {
  Brand: 'TOTAL',
  Street: '3 GEWERBEPARK FRIXING',
  PostCode: 84513,
  City: 'ERHARTING',
  Latitude: '48.272.625',
  Longitude: '12.549.562',
  Telephone: '08631-9895512',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.272625,12.549562',
  id: '48.272625,12.549562'
}, {
  Brand: 'Eni',
  Street: 'Gewerbestrasse 3',
  PostCode: 84427,
  City: 'St Wolfgang',
  Latitude: '4.821.235.390',
  Longitude: '1.213.412.406',
  Telephone: '+49 (0) 80851208',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.2123539,12.13412406',
  id: '48.2123539,12.13412406'
}, {
  Brand: 'ARAL',
  Street: 'Bundesstraße 42',
  PostCode: 53572,
  City: 'Unkel',
  Latitude: '506.019.430',
  Longitude: '72.219.230',
  Telephone: '22249879024',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.601943,7.221923',
  id: '50.601943,7.221923'
}, {
  Brand: 'ARAL',
  Street: 'Am Kruppwald/Ecke Bruckte',
  PostCode: 46238,
  City: 'Bottrop',
  Latitude: '515.094.570',
  Longitude: '69.556.180',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.509457,6.955618',
  id: '51.509457,6.955618'
}, {
  Brand: 'Eni',
  Street: 'Quiddestrae 31',
  PostCode: 81735,
  City: 'Muenchen',
  Latitude: '4.810.898.000',
  Longitude: '1.164.326.000',
  Telephone: '+49 (0) 89638498',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.10898,11.64326',
  id: '48.10898,11.64326'
}, {
  Brand: 'Westfalen',
  Street: 'Hauptstr. 134',
  PostCode: null,
  City: 'SCHWELM',
  Latitude: '512.879',
  Longitude: '730.058',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.2879,7.30058',
  id: '51.2879,7.30058'
}, {
  Brand: 'TOTAL',
  Street: 'KIRCHSTR. 1',
  PostCode: 47198,
  City: 'DUISBURG',
  Latitude: '51.443.872',
  Longitude: '6.675.952',
  Telephone: '02066-34083',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.443872,6.675952',
  id: '51.443872,6.675952'
}, {
  Brand: 'ARAL',
  Street: 'Neurieder Weg 33',
  PostCode: 86609,
  City: 'Donauwörth',
  Latitude: '487.197.820',
  Longitude: '107.614.650',
  Telephone: '9063060',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.719782,10.761465',
  id: '48.719782,10.761465'
}, {
  Brand: 'TOTAL',
  Street: 'SAARBRUECKER STR. 67',
  PostCode: 66359,
  City: 'BOUS',
  Latitude: '49.273.223',
  Longitude: '6.801.994',
  Telephone: '06834-3630',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.273223,6.801994',
  id: '49.273223,6.801994'
}, {
  Brand: 'TOTAL',
  Street: 'ADLERGESTELL 543',
  PostCode: 12527,
  City: 'BERLIN',
  Latitude: '52.414.343',
  Longitude: '13.572.973',
  Telephone: '030-67489152',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.414343,13.572973',
  id: '52.414343,13.572973'
}, {
  Brand: 'Westfalen',
  Street: 'Dolberger Str. 136',
  PostCode: null,
  City: 'AHLEN',
  Latitude: '517.503',
  Longitude: '789.772',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.7503,7.89772',
  id: '51.7503,7.89772'
}, {
  Brand: 'ARAL',
  Street: 'A1',
  PostCode: 27801,
  City: 'Dötlingen',
  Latitude: '529.149.350',
  Longitude: '84.260.350',
  Telephone: '443193920',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.914935,8.426035',
  id: '52.914935,8.426035'
}, {
  Brand: 'Eni',
  Street: 'Rommelshauser Strasse 30',
  PostCode: 70734,
  City: 'Fellbach',
  Latitude: '4.880.568.569',
  Longitude: '930.515.070',
  Telephone: '+49 (0) 71158171',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.80568569,9.3051507',
  id: '48.80568569,9.3051507'
}, {
  Brand: 'Westfalen',
  Street: 'Nordloher Str. 31',
  PostCode: null,
  City: 'APEN',
  Latitude: '531.757',
  Longitude: '782.345',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.1757,7.82345',
  id: '53.1757,7.82345'
}, {
  Brand: 'Eni',
  Street: 'Berliner Allee 14',
  PostCode: 16833,
  City: 'Fehrbellin',
  Latitude: '5.280.589.145',
  Longitude: '1.278.574.759',
  Telephone: '+49 (0) 33932724',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.80589145,12.78574759',
  id: '52.80589145,12.78574759'
}, {
  Brand: 'Eni',
  Street: 'Dieselstrasse 16',
  PostCode: 85386,
  City: 'Eching',
  Latitude: '4.830.859.000',
  Longitude: '1.164.120.000',
  Telephone: '08165/9087290',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.30859,11.6412',
  id: '48.30859,11.6412'
}, {
  Brand: 'Eni',
  Street: 'Lippertsreuter Strasse 13',
  PostCode: 88662,
  City: 'Ueberlingen',
  Latitude: '4.777.121.630',
  Longitude: '917.326.196',
  Telephone: '+49 (0) 75514590',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.7712163,9.17326196',
  id: '47.7712163,9.17326196'
}, {
  Brand: 'Westfalen',
  Street: 'Bornstr. 31 - 33',
  PostCode: null,
  City: 'DORTMUND',
  Latitude: '51.521',
  Longitude: '747.031',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.521,7.47031',
  id: '51.521,7.47031'
}, {
  Brand: 'Eni',
  Street: 'Schwetzinger Str. 125',
  PostCode: 69168,
  City: 'Wiesloch',
  Latitude: '4.929.142.500',
  Longitude: '868.419.000',
  Telephone: '+49 (0) 62225276',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.291425,8.68419',
  id: '49.291425,8.68419'
}, {
  Brand: 'Eni',
  Street: 'Ansbacher Strasse 102',
  PostCode: 90449,
  City: 'Nuernberg',
  Latitude: '4.942.069.000',
  Longitude: '1.103.272.000',
  Telephone: '+49 (0) 91167656',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.42069,11.03272',
  id: '49.42069,11.03272'
}, {
  Brand: 'TOTAL',
  Street: 'NUERNBERGER STR. 1A',
  PostCode: 91452,
  City: 'WILHERMSDORF',
  Latitude: '49.483.401',
  Longitude: '10.725.491',
  Telephone: '09102-993950',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.483401,10.725491',
  id: '49.483401,10.725491'
}, {
  Brand: 'TOTAL',
  Street: 'DURLACHER ALLEE 102',
  PostCode: 76137,
  City: 'KARLSRUHE',
  Latitude: '49.004.663',
  Longitude: '8.438.749',
  Telephone: '0721 20440908',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.004663,8.438749',
  id: '49.004663,8.438749'
}, {
  Brand: 'TOTAL',
  Street: 'BERLINER STR. 31',
  PostCode: 19348,
  City: 'PERLEBERG',
  Latitude: '53.070.305',
  Longitude: '11.876.505',
  Telephone: '03876-612301',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.070305,11.876505',
  id: '53.070305,11.876505'
}, {
  Brand: 'TOTAL',
  Street: '17 SCHOENEBERGER STR.',
  PostCode: 12103,
  City: 'BERLIN',
  Latitude: '52.470.148',
  Longitude: '13.371.984',
  Telephone: '030/43208844',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.470148,13.371984',
  id: '52.470148,13.371984'
}, {
  Brand: 'TOTAL',
  Street: 'BAB VIERWINDEN-NORD / A46',
  PostCode: 41516,
  City: 'GREVENBROICH',
  Latitude: '5.112.995',
  Longitude: '6.604.146',
  Telephone: '02182-1468',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.12995,6.604146',
  id: '51.12995,6.604146'
}, {
  Brand: 'TOTAL',
  Street: 'HAUPTSTR. 40',
  PostCode: 85659,
  City: 'FORSTERN',
  Latitude: '48.186.555',
  Longitude: '11.975.332',
  Telephone: '08124-527059',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.186555,11.975332',
  id: '48.186555,11.975332'
}, {
  Brand: 'ARAL',
  Street: 'Gablinger Weg 83',
  PostCode: 86154,
  City: 'Augsburg',
  Latitude: '483.987.740',
  Longitude: '108.686.690',
  Telephone: '82145309480',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.398774,10.868669',
  id: '48.398774,10.868669'
}, {
  Brand: 'TOTAL',
  Street: 'GERMERSHEIMER STR. 2A',
  PostCode: 67354,
  City: 'ROEMERBERG',
  Latitude: '49.299.267',
  Longitude: '8.410.588',
  Telephone: '06232-82636',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.299267,8.410588',
  id: '49.299267,8.410588'
}, {
  Brand: 'TOTAL',
  Street: 'ALTER LANDWEG 5',
  PostCode: 17258,
  City: 'FELDBERG',
  Latitude: '53.331.734',
  Longitude: '13.431.131',
  Telephone: '039831-20034',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.331734,13.431131',
  id: '53.331734,13.431131'
}, {
  Brand: 'OMV',
  Street: 'Oberotterbacher Str. 1',
  PostCode: 84056,
  City: 'Rottenburg',
  Latitude: '4.870.181.000',
  Longitude: '1.203.681.000',
  Telephone: '+49-8781-201500',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.70181,12.03681',
  id: '48.70181,12.03681'
}, {
  Brand: 'TOTAL',
  Street: 'PIRMASENSER STR. 93',
  PostCode: 66497,
  City: 'CONTWIG',
  Latitude: '49.248.422',
  Longitude: '744.306',
  Telephone: '06332-5833',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.248422,7.44306',
  id: '49.248422,7.44306'
}, {
  Brand: 'ARAL',
  Street: 'Bau L 944, Tor 11',
  PostCode: 67059,
  City: 'Ludwigshafen',
  Latitude: '495.056.640',
  Longitude: '84.154.120',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.505664,8.415412',
  id: '49.505664,8.415412'
}, {
  Brand: 'Eni',
  Street: 'Neuglosberg 11',
  PostCode: 96317,
  City: 'Kronach-Glosberg',
  Latitude: '5.028.835.587',
  Longitude: '1.130.928.937',
  Telephone: '+49 (0) 92619409',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.28835587,11.30928937',
  id: '50.28835587,11.30928937'
}, {
  Brand: 'Westfalen',
  Street: 'Hertener Weg 30-38',
  PostCode: null,
  City: 'HERTEN',
  Latitude: '51.6',
  Longitude: '709.332',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.6,7.09332',
  id: '51.6,7.09332'
}, {
  Brand: 'Eni',
  Street: 'Woelfershaeuser Strasse 49',
  PostCode: 36266,
  City: 'Heringen',
  Latitude: '5.088.496.600',
  Longitude: '998.764.300',
  Telephone: '+49 (0) 66241703',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.884966,9.987643',
  id: '50.884966,9.987643'
}, {
  Brand: 'TOTAL',
  Street: 'AN DER LANDSTR. 73',
  PostCode: 6429,
  City: 'NIENBURG',
  Latitude: '51.840.122',
  Longitude: '11.753.641',
  Telephone: '034721-22838',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.840122,11.753641',
  id: '51.840122,11.753641'
}, {
  Brand: 'ARAL',
  Street: 'Josef-Bautz-Str. 34',
  PostCode: 63450,
  City: 'Hanau',
  Latitude: '501.143.930',
  Longitude: '89.268.560',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.114393,8.926856',
  id: '50.114393,8.926856'
}, {
  Brand: 'OMV',
  Street: 'Weissenburger Str. 80',
  PostCode: 91710,
  City: 'Gunzenhausen',
  Latitude: '4.910.797.000',
  Longitude: '1.075.830.000',
  Telephone: '+49-9831-2311',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.10797,10.7583',
  id: '49.10797,10.7583'
}, {
  Brand: 'TOTAL',
  Street: 'HEIDELBERGER STR. 55',
  PostCode: 64285,
  City: 'DARMSTADT',
  Latitude: '49.861.427',
  Longitude: '8.646.803',
  Telephone: '06151-316459',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.861427,8.646803',
  id: '49.861427,8.646803'
}, {
  Brand: 'TOTAL',
  Street: '2 EGGLKOFENSTR.',
  PostCode: 84453,
  City: 'MUEHLDORF',
  Latitude: '48.256.443',
  Longitude: '12.533.697',
  Telephone: '08631-378121',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.256443,12.533697',
  id: '48.256443,12.533697'
}, {
  Brand: 'TOTAL',
  Street: 'THEODORSTR. 282',
  PostCode: 40472,
  City: 'DUESSELDORF',
  Latitude: '51.271.852',
  Longitude: '6.816.438',
  Telephone: '0211-6549816',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.271852,6.816438',
  id: '51.271852,6.816438'
}, {
  Brand: 'ARAL',
  Street: 'Industriestraße 2',
  PostCode: 94559,
  City: 'Niederwinkling',
  Latitude: '488.939.330',
  Longitude: '128.005.730',
  Telephone: '9962912020',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.893933,12.800573',
  id: '48.893933,12.800573'
}, {
  Brand: 'ARAL',
  Street: 'Kaufbeurer Straße 5',
  PostCode: 86830,
  City: 'Schwabmünchen',
  Latitude: '481.758.810',
  Longitude: '107.576.880',
  Telephone: '82324773',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.175881,10.757688',
  id: '48.175881,10.757688'
}, {
  Brand: 'TOTAL',
  Street: 'PRAEMIENSTR. 30',
  PostCode: 52223,
  City: 'STOLBERG',
  Latitude: '507.653',
  Longitude: '6.217.083',
  Telephone: '02402-24678',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.7653,6.217083',
  id: '50.7653,6.217083'
}, {
  Brand: 'Eni',
  Street: 'Untermuehlaustr. 105-107',
  PostCode: 68169,
  City: 'Mannheim',
  Latitude: '4.950.557.000',
  Longitude: '846.313.000',
  Telephone: '+49 (0) 62131650',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.50557,8.46313',
  id: '49.50557,8.46313'
}, {
  Brand: 'Westfalen',
  Street: 'Reichsstr. 3 - 5',
  PostCode: null,
  City: 'BONN',
  Latitude: '506.832',
  Longitude: '707.574',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.6832,7.07574',
  id: '50.6832,7.07574'
}, {
  Brand: 'OMV',
  Street: 'Gmünder Str. 19',
  PostCode: 73557,
  City: 'Mutlangen',
  Latitude: '4.882.070.000',
  Longitude: '979.512.000',
  Telephone: '+49-7171-71557',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.8207,9.79512',
  id: '48.8207,9.79512'
}, {
  Brand: 'TOTAL',
  Street: 'DESSAUER STR. 13',
  PostCode: 6886,
  City: 'WITTENBERG',
  Latitude: '51.865.663',
  Longitude: '12.615.115',
  Telephone: '03491-666986',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.865663,12.615115',
  id: '51.865663,12.615115'
}, {
  Brand: 'OMV',
  Street: 'Eschholzstr. 112',
  PostCode: 79115,
  City: 'Freiburg',
  Latitude: '4.799.241.000',
  Longitude: '783.146.000',
  Telephone: '+49-761-492979',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.99241,7.83146',
  id: '47.99241,7.83146'
}, {
  Brand: 'ARAL',
  Street: 'Amalienburgstraße 20',
  PostCode: 81247,
  City: 'München',
  Latitude: '481.643.180',
  Longitude: '114.885.410',
  Telephone: '8989160247',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.164318,11.488541',
  id: '48.164318,11.488541'
}, {
  Brand: 'TOTAL',
  Street: 'STAATSSTR. 47A',
  PostCode: 67483,
  City: 'EDESHEIM',
  Latitude: '4.926.583',
  Longitude: '8.133.551',
  Telephone: '06323-2876',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.26583,8.133551',
  id: '49.26583,8.133551'
}, {
  Brand: 'TOTAL',
  Street: 'ALTE STEINER STR. 43',
  PostCode: 75239,
  City: 'EISINGEN',
  Latitude: '48.949.397',
  Longitude: '8.664.782',
  Telephone: '07232-8994',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.949397,8.664782',
  id: '48.949397,8.664782'
}, {
  Brand: 'ARAL',
  Street: 'Tuttlinger Straße 38',
  PostCode: 78628,
  City: 'Rottweil',
  Latitude: '481.533.090',
  Longitude: '86.396.250',
  Telephone: '74121398',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.153309,8.639625',
  id: '48.153309,8.639625'
}, {
  Brand: 'TOTAL',
  Street: 'NORDALLEE 2',
  PostCode: 51147,
  City: 'KOELN',
  Latitude: '50.881.844',
  Longitude: '7.113.107',
  Telephone: '02203-591642',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.881844,7.113107',
  id: '50.881844,7.113107'
}, {
  Brand: 'ARAL',
  Street: 'Bregenzer Straße 43 - 45',
  PostCode: 88131,
  City: 'Lindau',
  Latitude: '475.530.180',
  Longitude: '97.014.450',
  Telephone: '',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:30',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:30',
  SundayFrom: '06:30',
  SundayTo: '22:30',
  IsOpen24Hours: 'Nein',
  location: '47.553018,9.701445',
  id: '47.553018,9.701445'
}, {
  Brand: 'TOTAL',
  Street: 'BERGSTR. 1',
  PostCode: 79737,
  City: 'HERRISCHRIED-HOGSCHU',
  Latitude: '47.647.048',
  Longitude: '8.011.354',
  Telephone: '07764-6190',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.647048,8.011354',
  id: '47.647048,8.011354'
}, {
  Brand: 'OMV',
  Street: 'Münchener Str. 49',
  PostCode: 85748,
  City: 'Garching',
  Latitude: '4.824.405.000',
  Longitude: '1.164.723.000',
  Telephone: '+49-89-32649709',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.24405,11.64723',
  id: '48.24405,11.64723'
}, {
  Brand: 'TOTAL',
  Street: 'LUDWIG-ERHARD-STR. 36',
  PostCode: 4103,
  City: 'LEIPZIG',
  Latitude: '51.343.231',
  Longitude: '12.395.467',
  Telephone: '0341-6810411',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.343231,12.395467',
  id: '51.343231,12.395467'
}, {
  Brand: 'Eni',
  Street: 'Zwieseler Strasse 44',
  PostCode: 94209,
  City: 'Regen',
  Latitude: '4.897.188.331',
  Longitude: '1.313.808.647',
  Telephone: '+49 (0) 99211852',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.97188331,13.13808647',
  id: '48.97188331,13.13808647'
}, {
  Brand: 'TOTAL',
  Street: 'W.-KAULISCH-STR.49',
  PostCode: 1844,
  City: 'NEUSTADT',
  Latitude: '51.028.819',
  Longitude: '1.420.862',
  Telephone: '03596-604238',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.028819,14.20862',
  id: '51.028819,14.20862'
}, {
  Brand: 'TOTAL',
  Street: 'ELLY-BEINHORN-RING 2 A',
  PostCode: 12529,
  City: 'SCHOENEFELD',
  Latitude: '52.370.372',
  Longitude: '13.527.195',
  Telephone: '030-609156230',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.370372,13.527195',
  id: '52.370372,13.527195'
}, {
  Brand: 'TOTAL',
  Street: 'WERTHEIMER STR. 50',
  PostCode: 97941,
  City: 'TAUBERBISCHOFSHEIM',
  Latitude: '49.630.185',
  Longitude: '966.614',
  Telephone: '934195164',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.630185,9.66614',
  id: '49.630185,9.66614'
}, {
  Brand: 'Westfalen',
  Street: 'Von-Galen-Str. 2',
  PostCode: null,
  City: 'BILLERBECK',
  Latitude: '519.704',
  Longitude: '7.2807',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9704,7.2807',
  id: '51.9704,7.2807'
}, {
  Brand: 'OMV',
  Street: 'Traunsteiner Str. 13',
  PostCode: 83317,
  City: 'Teisendorf',
  Latitude: '4.784.966.000',
  Longitude: '1.281.690.000',
  Telephone: '+49-8666-6898',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.84966,12.8169',
  id: '47.84966,12.8169'
}, {
  Brand: 'OMV',
  Street: 'Owinger Str. 11-13',
  PostCode: 72401,
  City: 'Haigerloch-Stetten',
  Latitude: '4.835.182.000',
  Longitude: '881.768.000',
  Telephone: '+49-7474-9551971',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.35182,8.81768',
  id: '48.35182,8.81768'
}, {
  Brand: 'TOTAL',
  Street: 'DEUBACHER STR.1',
  PostCode: 97922,
  City: 'LAUDA-KOENIGSHOFEN',
  Latitude: '49.541.334',
  Longitude: '9.736.617',
  Telephone: '934365440',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.541334,9.736617',
  id: '49.541334,9.736617'
}, {
  Brand: 'Westfalen',
  Street: 'Beckumer Str. 133',
  PostCode: null,
  City: 'LIPPSTADT',
  Latitude: '516.805',
  Longitude: '831.155',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.6805,8.31155',
  id: '51.6805,8.31155'
}, {
  Brand: 'Westfalen',
  Street: 'Metelener Stra? 40',
  PostCode: null,
  City: 'WETTRINGEN',
  Latitude: '522.067',
  Longitude: '731.031',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.2067,7.31031',
  id: '52.2067,7.31031'
}, {
  Brand: 'Eni',
  Street: 'Adlergestell 289 A',
  PostCode: 12489,
  City: 'Berlin',
  Latitude: '5.243.197.379',
  Longitude: '1.354.651.899',
  Telephone: '+49 (0) 30671329',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '52.43197379,13.54651899',
  id: '52.43197379,13.54651899'
}, {
  Brand: 'Westfalen',
  Street: 'Muensterstr. 52',
  PostCode: null,
  City: 'SENDEN',
  Latitude: '518.617',
  Longitude: '749.228',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8617,7.49228',
  id: '51.8617,7.49228'
}, {
  Brand: 'ARAL',
  Street: 'Luetticher Straße 108',
  PostCode: 40547,
  City: 'Düsseldorf',
  Latitude: '512.411.200',
  Longitude: '67.436.430',
  Telephone: '211552422',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.24112,6.743643',
  id: '51.24112,6.743643'
}, {
  Brand: 'ARAL',
  Street: 'Bismarckstraße 7',
  PostCode: 33775,
  City: 'Versmold',
  Latitude: '520.238.360',
  Longitude: '81.224.200',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.023836,8.12242',
  id: '52.023836,8.12242'
}, {
  Brand: 'TOTAL',
  Street: 'FERDINAND-EGELINSKI-STR. 3',
  PostCode: 17419,
  City: 'AHLBECK',
  Latitude: '53.934.109',
  Longitude: '1.419.451',
  Telephone: '038378-28415',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.934109,14.19451',
  id: '53.934109,14.19451'
}, {
  Brand: 'Eni',
  Street: 'Bahnhofstrasse 10A',
  PostCode: 94327,
  City: 'Bogen',
  Latitude: '4.890.949.000',
  Longitude: '1.269.103.000',
  Telephone: '+49 (0) 94221864',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.90949,12.69103',
  id: '48.90949,12.69103'
}, {
  Brand: 'ARAL',
  Street: 'Schneeberger Straße 30',
  PostCode: 8309,
  City: 'Eibenstock',
  Latitude: '505.013.010',
  Longitude: '126.031.010',
  Telephone: '377523145',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.501301,12.603101',
  id: '50.501301,12.603101'
}, {
  Brand: 'ARAL',
  Street: 'Industriestraße 8',
  PostCode: 26892,
  City: 'Dörpen',
  Latitude: '529.700.230',
  Longitude: '73.608.130',
  Telephone: '496391280',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '52.970023,7.360813',
  id: '52.970023,7.360813'
}, {
  Brand: 'OMV',
  Street: 'Flughafenstr. 70',
  PostCode: 70629,
  City: 'Stuttgart',
  Latitude: '4.869.277.000',
  Longitude: '919.899.000',
  Telephone: '+49-711-2206610',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.69277,9.19899',
  id: '48.69277,9.19899'
}, {
  Brand: 'TOTAL',
  Street: '45 NEUE JUELICHER STR.',
  PostCode: 52353,
  City: 'DUEREN',
  Latitude: '50.812.335',
  Longitude: '6.474.528',
  Telephone: '02421-941417',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.812335,6.474528',
  id: '50.812335,6.474528'
}, {
  Brand: 'ARAL',
  Street: 'A 1',
  PostCode: 54421,
  City: 'Reinsfeld',
  Latitude: '496.897.050',
  Longitude: '68.999.900',
  Telephone: '65861011',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.689705,6.89999',
  id: '49.689705,6.89999'
}, {
  Brand: 'ARAL',
  Street: 'HAUPTSTRAßE 100',
  PostCode: 44894,
  City: 'Bochum',
  Latitude: '514.807.630',
  Longitude: '73.239.500',
  Telephone: '23441747848',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.480763,7.32395',
  id: '51.480763,7.32395'
}, {
  Brand: 'TOTAL',
  Street: 'BAHNHOFSTR. 23',
  PostCode: 73257,
  City: 'KOENGEN',
  Latitude: '48.677.472',
  Longitude: '9.367.057',
  Telephone: '07024-84995',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.677472,9.367057',
  id: '48.677472,9.367057'
}, {
  Brand: 'Westfalen',
  Street: 'Bremer Str. 46',
  PostCode: null,
  City: 'TWISTRINGEN',
  Latitude: '528.045',
  Longitude: '864.704',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.8045,8.64704',
  id: '52.8045,8.64704'
}, {
  Brand: 'OMV',
  Street: 'Höhenrain West  A95',
  PostCode: 82335,
  City: 'Berg',
  Latitude: '',
  Longitude: '',
  Telephone: '+49-8171-21920',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '0,0',
  id: '0,0'
}, {
  Brand: 'OMV',
  Street: 'Ettlinger Str. 38',
  PostCode: 76332,
  City: 'Bad Herrenalb',
  Latitude: '4.880.506.000',
  Longitude: '844.490.000',
  Telephone: '+49-7083-2494',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.80506,8.4449',
  id: '48.80506,8.4449'
}, {
  Brand: 'TOTAL',
  Street: '1 SERVAISSTRASSE',
  PostCode: 54293,
  City: 'TRIER- EHRANG',
  Latitude: '4.980.637',
  Longitude: '668.543',
  Telephone: '6519680914',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.80637,6.68543',
  id: '49.80637,6.68543'
}, {
  Brand: 'OMV',
  Street: 'Stadtgraben 104',
  PostCode: 94315,
  City: 'Straubing',
  Latitude: '4.888.311.000',
  Longitude: '1.257.426.000',
  Telephone: '+49-9421-1380',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.88311,12.57426',
  id: '48.88311,12.57426'
}, {
  Brand: 'Westfalen',
  Street: 'Bahnhofstr. 37/39',
  PostCode: null,
  City: 'VREDEN',
  Latitude: '520.301',
  Longitude: '682.317',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0301,6.82317',
  id: '52.0301,6.82317'
}, {
  Brand: 'Eni',
  Street: 'Vorjurastrasse 180',
  PostCode: 90453,
  City: 'Nuernberg',
  Latitude: '4.936.863.000',
  Longitude: '1.104.559.000',
  Telephone: '+49 (0) 91163787',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.36863,11.04559',
  id: '49.36863,11.04559'
}, {
  Brand: 'ARAL',
  Street: 'A4',
  PostCode: 2625,
  City: 'Bautzen',
  Latitude: '511.931.848',
  Longitude: '144.185.958',
  Telephone: '3593780308',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.1931848,14.4185958',
  id: '51.1931848,14.4185958'
}, {
  Brand: 'OMV',
  Street: 'Auerfeldstr. 21',
  PostCode: 81541,
  City: 'München',
  Latitude: '4.812.362.000',
  Longitude: '1.159.545.000',
  Telephone: '+49-89-487038',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.12362,11.59545',
  id: '48.12362,11.59545'
}, {
  Brand: 'TOTAL',
  Street: 'LINDLARER STR.143',
  PostCode: 51491,
  City: 'OVERATH',
  Latitude: '50.962.701',
  Longitude: '7.244.351',
  Telephone: '02204-74445',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.962701,7.244351',
  id: '50.962701,7.244351'
}, {
  Brand: 'TOTAL',
  Street: 'GEWERBEPARK 3',
  PostCode: 18546,
  City: 'SASSNITZ',
  Latitude: '54.513.303',
  Longitude: '13.603.129',
  Telephone: '038392-32620',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.513303,13.603129',
  id: '54.513303,13.603129'
}, {
  Brand: 'Eni',
  Street: 'Heidenheimer Strasse 47',
  PostCode: 89537,
  City: 'Giengen',
  Latitude: '4.862.368.000',
  Longitude: '1.024.219.000',
  Telephone: '+49 (0) 73229195',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.62368,10.24219',
  id: '48.62368,10.24219'
}, {
  Brand: 'TOTAL',
  Street: 'GRABENSTR. 2',
  PostCode: 19061,
  City: 'SCHWERIN',
  Latitude: '53.601.225',
  Longitude: '11.391.104',
  Telephone: '0385-6666666',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.601225,11.391104',
  id: '53.601225,11.391104'
}, {
  Brand: 'TOTAL',
  Street: 'WATTENHEIMER STR. 28',
  PostCode: 67310,
  City: 'HETTENLEIDELHEIM',
  Latitude: '4.953.158',
  Longitude: '8.066.086',
  Telephone: '06351-122421',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.53158,8.066086',
  id: '49.53158,8.066086'
}, {
  Brand: 'TOTAL',
  Street: 'BERLINER STR. 25',
  PostCode: 51377,
  City: 'LEVERKUSEN',
  Latitude: '51.043.054',
  Longitude: '7.072.339',
  Telephone: '02148-909415',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.043054,7.072339',
  id: '51.043054,7.072339'
}, {
  Brand: 'TOTAL',
  Street: 'LANDSBERGER ALLEE 376',
  PostCode: 12681,
  City: 'BERLIN',
  Latitude: '52.535.395',
  Longitude: '13.522.849',
  Telephone: '030-5437728',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.535395,13.522849',
  id: '52.535395,13.522849'
}, {
  Brand: 'ARAL',
  Street: 'Münchener Straße 300 / It',
  PostCode: 40589,
  City: 'Düsseldorf',
  Latitude: '511.690.510',
  Longitude: '68.227.340',
  Telephone: '21170089550',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.169051,6.822734',
  id: '51.169051,6.822734'
}, {
  Brand: 'TOTAL',
  Street: 'HAGENER STR. 239',
  PostCode: 57223,
  City: 'KREUZTAL',
  Latitude: '50.988.082',
  Longitude: '7.959.252',
  Telephone: '02732-5531848',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.988082,7.959252',
  id: '50.988082,7.959252'
}, {
  Brand: 'TOTAL',
  Street: 'BRANDENBURGER STR.',
  PostCode: 14806,
  City: 'BAD BELZIG',
  Latitude: '52.150.539',
  Longitude: '12.600.528',
  Telephone: '033841-35772',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.150539,12.600528',
  id: '52.150539,12.600528'
}, {
  Brand: 'ARAL',
  Street: 'Donauwörther Straße 7',
  PostCode: 86405,
  City: 'Meitingen',
  Latitude: '485.481.030',
  Longitude: '108.530.670',
  Telephone: '82718132409',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '48.548103,10.853067',
  id: '48.548103,10.853067'
}, {
  Brand: 'ARAL',
  Street: 'Am Bahnhof B 1/ B 5',
  PostCode: 15562,
  City: 'Rüdersdorf',
  Latitude: '524.890.080',
  Longitude: '137.800.630',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.489008,13.780063',
  id: '52.489008,13.780063'
}, {
  Brand: 'TOTAL',
  Street: 'FRIEDEBURGER HAUPTSTR. 104',
  PostCode: 26446,
  City: 'FRIEDEBURG',
  Latitude: '5.345.741',
  Longitude: '783.668',
  Telephone: '04465-6249958',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.45741,7.83668',
  id: '53.45741,7.83668'
}, {
  Brand: 'TOTAL',
  Street: 'UNTERE SCHLOSSSTRASSE 37',
  PostCode: 9573,
  City: 'AUGUSTUSBURG',
  Latitude: '50.818.268',
  Longitude: '13.100.932',
  Telephone: '037291-6567',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.818268,13.100932',
  id: '50.818268,13.100932'
}, {
  Brand: 'TOTAL',
  Street: 'WISMARSCHE STR.153 A',
  PostCode: 23936,
  City: 'GREVESMUEHLEN',
  Latitude: '53.865.385',
  Longitude: '11.2095',
  Telephone: '03881-2729',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.865385,11.2095',
  id: '53.865385,11.2095'
}, {
  Brand: 'ARAL',
  Street: 'Motorstr. 21',
  PostCode: 70499,
  City: 'Stuttgart',
  Latitude: '488.281.180',
  Longitude: '91.091.000',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.828118,9.1091',
  id: '48.828118,9.1091'
}, {
  Brand: 'TOTAL',
  Street: 'HAMBURGER STR.44',
  PostCode: 1067,
  City: 'DRESDEN',
  Latitude: '51.061.286',
  Longitude: '13.693.845',
  Telephone: '0351-4942558',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.061286,13.693845',
  id: '51.061286,13.693845'
}, {
  Brand: 'TOTAL',
  Street: 'LUETJENBURGER STR. 61',
  PostCode: 24306,
  City: 'PLOEN',
  Latitude: '54.170.135',
  Longitude: '10.435.575',
  Telephone: '04522-749000',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.170135,10.435575',
  id: '54.170135,10.435575'
}, {
  Brand: 'ARAL',
  Street: 'A4',
  PostCode: 51491,
  City: 'Overath',
  Latitude: '509.531.280',
  Longitude: '73.190.190',
  Telephone: '22062201',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.953128,7.319019',
  id: '50.953128,7.319019'
}, {
  Brand: 'ARAL',
  Street: 'Königswinterer Straße 106',
  PostCode: 53639,
  City: 'Königswinter',
  Latitude: '506.989.080',
  Longitude: '72.777.070',
  Telephone: '2244876519',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.698908,7.277707',
  id: '50.698908,7.277707'
}, {
  Brand: 'ARAL',
  Street: 'Berghamer Straße 3',
  PostCode: 85435,
  City: 'Erding',
  Latitude: '482.766.040',
  Longitude: '118.991.830',
  Telephone: '812293447',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '20:00',
  SundayFrom: '00:00',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.276604,11.899183',
  id: '48.276604,11.899183'
}, {
  Brand: 'TOTAL',
  Street: 'LEIPZIGER STR. 12',
  PostCode: 1589,
  City: 'RIESA',
  Latitude: '51.289.399',
  Longitude: '13.289.583',
  Telephone: '03525-731747',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.289399,13.289583',
  id: '51.289399,13.289583'
}, {
  Brand: 'Eni',
  Street: 'Silbergstrasse 91 A/B 20',
  PostCode: 83489,
  City: 'Bischofswiesen - Strub',
  Latitude: '4.762.964.687',
  Longitude: '1.296.003.066',
  Telephone: '+49 (0) 86524757',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.62964687,12.96003066',
  id: '47.62964687,12.96003066'
}, {
  Brand: 'Avanti',
  Street: 'Hauptstr. 48',
  PostCode: 96272,
  City: 'Hochstadt',
  Latitude: '5.015.392.000',
  Longitude: '1.117.726.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.15392,11.17726',
  id: '50.15392,11.17726'
}, {
  Brand: 'OMV',
  Street: 'Waldstr. 255',
  PostCode: 63071,
  City: 'Offenbach',
  Latitude: '5.008.278.000',
  Longitude: '877.511.000',
  Telephone: '+49-69-83071274',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.08278,8.77511',
  id: '50.08278,8.77511'
}, {
  Brand: 'Eni',
  Street: 'Kossaerstr./Maximilianallee',
  PostCode: 4356,
  City: 'Leipzig-Podelwitz',
  Latitude: '5.141.061.144',
  Longitude: '1.239.406.657',
  Telephone: '+49 (0) 34152172',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.41061144,12.39406657',
  id: '51.41061144,12.39406657'
}, {
  Brand: 'Eni',
  Street: 'Stuttgarter Str. 144',
  PostCode: 73230,
  City: 'Kirchheim',
  Latitude: '4.865.565.000',
  Longitude: '942.765.000',
  Telephone: '+49 (0) 70213054',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.65565,9.42765',
  id: '48.65565,9.42765'
}, {
  Brand: 'Westfalen',
  Street: 'Grosse Str. 22',
  PostCode: null,
  City: 'NEUENKIRCHEN',
  Latitude: '525.096',
  Longitude: '806.854',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.5096,8.06854',
  id: '52.5096,8.06854'
}, {
  Brand: 'Westfalen',
  Street: 'Im Babenbecker Feld 56',
  PostCode: null,
  City: 'HERFORD',
  Latitude: '521.295',
  Longitude: '864.347',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.1295,8.64347',
  id: '52.1295,8.64347'
}, {
  Brand: 'Eni',
  Street: 'Hallesche Str. 182',
  PostCode: 4159,
  City: 'Leipzig',
  Latitude: '5.138.467.257',
  Longitude: '1.227.306.411',
  Telephone: '+49 (0) 34146157',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.38467257,12.27306411',
  id: '51.38467257,12.27306411'
}, {
  Brand: 'Westfalen',
  Street: 'Hildesheimer 407',
  PostCode: null,
  City: 'HANNOVER',
  Latitude: '523.261',
  Longitude: '978.182',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.3261,9.78182',
  id: '52.3261,9.78182'
}, {
  Brand: 'TOTAL',
  Street: 'HAUPTSTR. 257',
  PostCode: 67065,
  City: 'LUDWIGSHAFEN',
  Latitude: '49.450.165',
  Longitude: '8.423.482',
  Telephone: '0621-542476',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.450165,8.423482',
  id: '49.450165,8.423482'
}, {
  Brand: 'OMV',
  Street: 'Münchner Str. 11',
  PostCode: 85646,
  City: 'Anzing',
  Latitude: '4.815.124.000',
  Longitude: '1.185.108.000',
  Telephone: '+49-8121-3848',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.15124,11.85108',
  id: '48.15124,11.85108'
}, {
  Brand: 'ARAL',
  Street: 'Friedrich-Ebert-Str. 100',
  PostCode: 49811,
  City: 'Lingen',
  Latitude: '525.436.770',
  Longitude: '73.428.160',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.543677,7.342816',
  id: '52.543677,7.342816'
}, {
  Brand: 'TOTAL',
  Street: 'BLOCKDAMMWEG 6',
  PostCode: 10317,
  City: 'BERLIN',
  Latitude: '5.248.291',
  Longitude: '13.502.177',
  Telephone: '030-5099806',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.48291,13.502177',
  id: '52.48291,13.502177'
}, {
  Brand: 'TOTAL',
  Street: 'MARKTFLECKEN 17',
  PostCode: 17498,
  City: 'NEUENKIRCHEN',
  Latitude: '54.117.154',
  Longitude: '13.363.749',
  Telephone: '03834-2340',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.117154,13.363749',
  id: '54.117154,13.363749'
}, {
  Brand: 'TOTAL',
  Street: 'BAB EIFEL-OST / A48',
  PostCode: 54533,
  City: 'NIEDEROEFFLINGEN',
  Latitude: '50.069.233',
  Longitude: '6.881.855',
  Telephone: '06574-200',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.069233,6.881855',
  id: '50.069233,6.881855'
}, {
  Brand: 'TOTAL',
  Street: 'NEUKOELLNER STR. 294',
  PostCode: 12357,
  City: 'BERLIN',
  Latitude: '524.222',
  Longitude: '13.487.083',
  Telephone: '030-6613037',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.4222,13.487083',
  id: '52.4222,13.487083'
}, {
  Brand: 'Eni',
  Street: 'Von-Kahr-Str. 62',
  PostCode: 80999,
  City: 'Muenchen',
  Latitude: '4.817.759.000',
  Longitude: '1.146.744.000',
  Telephone: '+49 (0) 89812118',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.17759,11.46744',
  id: '48.17759,11.46744'
}, {
  Brand: 'TOTAL',
  Street: 'HOFER STR. 38',
  PostCode: 7926,
  City: 'GEFELL',
  Latitude: '50.435.176',
  Longitude: '11.855.584',
  Telephone: '036649-82258',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.435176,11.855584',
  id: '50.435176,11.855584'
}, {
  Brand: 'Avanti',
  Street: 'Am Kletthamer Feld 1',
  PostCode: 85424,
  City: 'Erding',
  Latitude: '4.830.734.000',
  Longitude: '1.188.442.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.30734,11.88442',
  id: '48.30734,11.88442'
}, {
  Brand: 'TOTAL',
  Street: '122 GREIFSWALDER CHAUSSEE',
  PostCode: 18439,
  City: 'STRALSUND',
  Latitude: '54.300.851',
  Longitude: '13.094.701',
  Telephone: '03831-2031038',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.300851,13.094701',
  id: '54.300851,13.094701'
}, {
  Brand: 'ARAL',
  Street: 'Gartenallee 2',
  PostCode: 4827,
  City: 'Machern',
  Latitude: '513.599.010',
  Longitude: '126.169.760',
  Telephone: '34292869790',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Ja',
  location: '51.359901,12.616976',
  id: '51.359901,12.616976'
}, {
  Brand: 'ARAL',
  Street: 'Wöllsteiner Straße 11',
  PostCode: 55599,
  City: 'Gau-Bickelheim',
  Latitude: '498.349.170',
  Longitude: '80.158.780',
  Telephone: '6701961510',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.834917,8.015878',
  id: '49.834917,8.015878'
}, {
  Brand: 'Westfalen',
  Street: 'Hervester Str. 43 - 45',
  PostCode: null,
  City: 'DORSTEN',
  Latitude: '517.137',
  Longitude: '701.182',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.7137,7.01182',
  id: '51.7137,7.01182'
}, {
  Brand: 'ARAL',
  Street: 'Zum Autohof 4',
  PostCode: 32457,
  City: 'Porta Westfalica',
  Latitude: '522.101.240',
  Longitude: '88.750.300',
  Telephone: '5731156930',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.210124,8.87503',
  id: '52.210124,8.87503'
}, {
  Brand: 'ARAL',
  Street: 'Escher Straße 273',
  PostCode: 50739,
  City: 'Köln',
  Latitude: '509.694.430',
  Longitude: '69.271.760',
  Telephone: '221171612',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.969443,6.927176',
  id: '50.969443,6.927176'
}, {
  Brand: 'OMV',
  Street: 'Bayerwaldstr. 10',
  PostCode: 94559,
  City: 'Niederwinkling',
  Latitude: '4.888.814.000',
  Longitude: '1.279.875.000',
  Telephone: '+49-9962-2030792',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.88814,12.79875',
  id: '48.88814,12.79875'
}, {
  Brand: 'ARAL',
  Street: 'A 57',
  PostCode: 47809,
  City: 'Krefeld',
  Latitude: '513.111.970',
  Longitude: '66.344.080',
  Telephone: '2151544940',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.311197,6.634408',
  id: '51.311197,6.634408'
}, {
  Brand: 'ARAL',
  Street: 'Kronprinzessinnenweg 120',
  PostCode: 14129,
  City: 'Berlin',
  Latitude: '524.341.780',
  Longitude: '131.907.530',
  Telephone: '308037233',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.434178,13.190753',
  id: '52.434178,13.190753'
}, {
  Brand: 'Westfalen',
  Street: 'Herforder Str. 54',
  PostCode: null,
  City: 'BUENDE',
  Latitude: '521.922',
  Longitude: '859.386',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.1922,8.59386',
  id: '52.1922,8.59386'
}, {
  Brand: 'TOTAL',
  Street: 'ROEMERSTR. 1',
  PostCode: 64560,
  City: 'RIEDSTADT-GODDELAU',
  Latitude: '49.834.679',
  Longitude: '8.484.132',
  Telephone: '06158-184056',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.834679,8.484132',
  id: '49.834679,8.484132'
}, {
  Brand: 'ARAL',
  Street: 'Heilbronner Straße 14-15',
  PostCode: 10711,
  City: 'Berlin',
  Latitude: '525.018.310',
  Longitude: '132.943.880',
  Telephone: '308913017',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '18:00',
  SundayFrom: '00:00',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.501831,13.294388',
  id: '52.501831,13.294388'
}, {
  Brand: 'ARAL',
  Street: 'Neukamp 4',
  PostCode: 26655,
  City: 'Westerstede',
  Latitude: '532.685.010',
  Longitude: '79.451.080',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.268501,7.945108',
  id: '53.268501,7.945108'
}, {
  Brand: 'Eni',
  Street: 'Jagsttal-Ost',
  PostCode: 74259,
  City: 'Widdern',
  Latitude: '4.931.591.500',
  Longitude: '941.948.500',
  Telephone: '+49 (0) 62985646',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.315915,9.419485',
  id: '49.315915,9.419485'
}, {
  Brand: 'TOTAL',
  Street: '11 MARTIN-LUTHER-STR.',
  PostCode: 71636,
  City: 'LUDWIGSBURG',
  Latitude: '48.896.111',
  Longitude: '9.18',
  Telephone: '07141-923171',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.896111,9.18',
  id: '48.896111,9.18'
}, {
  Brand: 'ARAL',
  Street: 'Robert-Bosch-Str. 1',
  PostCode: 73257,
  City: 'Köngen',
  Latitude: '486.850.890',
  Longitude: '93.736.760',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.685089,9.373676',
  id: '48.685089,9.373676'
}, {
  Brand: 'TOTAL',
  Street: 'PROVINZIALSTR. 55',
  PostCode: 53127,
  City: 'BONN',
  Latitude: '50.711.946',
  Longitude: '7.067.286',
  Telephone: '0228-254392',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.711946,7.067286',
  id: '50.711946,7.067286'
}, {
  Brand: 'TOTAL',
  Street: 'ALTE FRANKFURTER STR. 92',
  PostCode: 61118,
  City: 'BAD VILBEL',
  Latitude: '50.165.029',
  Longitude: '8.724.259',
  Telephone: '06101-85770',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.165029,8.724259',
  id: '50.165029,8.724259'
}, {
  Brand: 'ARAL',
  Street: 'Gerberstraße',
  PostCode: 51789,
  City: 'Lindlar',
  Latitude: '510.264.870',
  Longitude: '74.161.760',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.026487,7.416176',
  id: '51.026487,7.416176'
}, {
  Brand: 'ARAL',
  Street: 'Frankenstr.',
  PostCode: 97762,
  City: 'Hammelburg',
  Latitude: '501.280.790',
  Longitude: '99.363.640',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.128079,9.936364',
  id: '50.128079,9.936364'
}, {
  Brand: 'ARAL',
  Street: 'Friedberger Landstr. 325',
  PostCode: 60389,
  City: 'Frankfurt',
  Latitude: '501.403.790',
  Longitude: '86.985.320',
  Telephone: '6913025453',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.140379,8.698532',
  id: '50.140379,8.698532'
}, {
  Brand: 'TOTAL',
  Street: 'WOLDEGKER STR. 40',
  PostCode: 17036,
  City: 'NEUBRANDENBURG',
  Latitude: '53.556.247',
  Longitude: '13.312.756',
  Telephone: '0395-769570',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.556247,13.312756',
  id: '53.556247,13.312756'
}, {
  Brand: 'TOTAL',
  Street: 'LEIPHEIMER STR. 60',
  PostCode: 89233,
  City: 'NEU-ULM',
  Latitude: '48.405.597',
  Longitude: '1.003.808',
  Telephone: '0731-714728',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.405597,10.03808',
  id: '48.405597,10.03808'
}, {
  Brand: 'OMV',
  Street: 'Renkenweg 1',
  PostCode: 83026,
  City: 'Rosenheim',
  Latitude: '4.784.331.000',
  Longitude: '1.208.196.000',
  Telephone: '+49-8031-463206',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '47.84331,12.08196',
  id: '47.84331,12.08196'
}, {
  Brand: 'ARAL',
  Street: 'Sonnenfeld 2',
  PostCode: 6766,
  City: 'Bitterfeld',
  Latitude: '516.355.740',
  Longitude: '121.996.390',
  Telephone: '349397570',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.635574,12.199639',
  id: '51.635574,12.199639'
}, {
  Brand: 'ARAL',
  Street: 'Carl Benz Straße 11',
  PostCode: 57299,
  City: 'Burbach',
  Latitude: '507.504.000',
  Longitude: '81.000.610',
  Telephone: '27366891',
  WeekDayFrom: '06:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '17:00',
  SundayFrom: '00:00',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.7504,8.100061',
  id: '50.7504,8.100061'
}, {
  Brand: 'TOTAL',
  Street: '36 MOOSACHER STR.',
  PostCode: 80809,
  City: 'MUENCHEN',
  Latitude: '48.186.452',
  Longitude: '11.556.034',
  Telephone: '089/35756644',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.186452,11.556034',
  id: '48.186452,11.556034'
}, {
  Brand: 'Eni',
  Street: 'Platanenallee 11',
  PostCode: 63739,
  City: 'Aschaffenburg',
  Latitude: '4.997.689.000',
  Longitude: '915.258.000',
  Telephone: '+49 (0) 60214532',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.97689,9.15258',
  id: '49.97689,9.15258'
}, {
  Brand: 'Eni',
  Street: 'Roemeraecker 5',
  PostCode: 76351,
  City: 'Linkenheim-Hochstetten',
  Latitude: '4.913.768.241',
  Longitude: '842.713.194',
  Telephone: '07247/9544915',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.13768241,8.42713194',
  id: '49.13768241,8.42713194'
}, {
  Brand: 'Westfalen',
  Street: 'Werner Hellweg 526',
  PostCode: null,
  City: 'BOCHUM',
  Latitude: '514.924',
  Longitude: '731.072',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.4924,7.31072',
  id: '51.4924,7.31072'
}, {
  Brand: 'OMV',
  Street: 'Burgkirchener Str. 11',
  PostCode: 84489,
  City: 'Burghausen',
  Latitude: '4.817.570.000',
  Longitude: '1.282.444.000',
  Telephone: '+49-8677-66198',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.1757,12.82444',
  id: '48.1757,12.82444'
}, {
  Brand: 'Eni',
  Street: 'Heerstrasse 343-345',
  PostCode: 13593,
  City: 'Berlin',
  Latitude: '5.251.702.063',
  Longitude: '1.317.663.832',
  Telephone: '+49 (0) 30361317',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.51702063,13.17663832',
  id: '52.51702063,13.17663832'
}, {
  Brand: 'Eni',
  Street: 'Kantstrasse 64',
  PostCode: 67663,
  City: 'Kaiserslautern',
  Latitude: '4.943.216.000',
  Longitude: '778.105.000',
  Telephone: '+49 (0) 63131152',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.43216,7.78105',
  id: '49.43216,7.78105'
}, {
  Brand: 'OMV',
  Street: 'Heinrich-Wieland-Str. 97',
  PostCode: 81735,
  City: 'München',
  Latitude: '4.811.282.000',
  Longitude: '1.164.806.000',
  Telephone: '+49-89-42721395',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.11282,11.64806',
  id: '48.11282,11.64806'
}, {
  Brand: 'OMV',
  Street: 'Garmischer Str. 5',
  PostCode: 82418,
  City: 'Murnau',
  Latitude: '4.767.205.000',
  Longitude: '1.120.129.000',
  Telephone: '+49-8841-1285',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.67205,11.20129',
  id: '47.67205,11.20129'
}, {
  Brand: 'Eni',
  Street: 'Hohenkirchnerstr. 9',
  PostCode: 34376,
  City: 'Immenhausen',
  Latitude: '5.142.436.000',
  Longitude: '948.025.000',
  Telephone: '+49 (0) 56732573',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.42436,9.48025',
  id: '51.42436,9.48025'
}, {
  Brand: 'TOTAL',
  Street: 'WEISSENBERGER STR. 10',
  PostCode: 2708,
  City: 'LOEBAU',
  Latitude: '51.103.302',
  Longitude: '1.467.016',
  Telephone: '03585-860090',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.103302,14.67016',
  id: '51.103302,14.67016'
}, {
  Brand: 'OMV',
  Street: 'Nürnberger Str. 210',
  PostCode: 90579,
  City: 'Langenzenn',
  Latitude: '4.949.425.000',
  Longitude: '1.081.767.000',
  Telephone: '+49-9101-6594',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.49425,10.81767',
  id: '49.49425,10.81767'
}, {
  Brand: 'ARAL',
  Street: 'Römerstraße 80',
  PostCode: 77694,
  City: 'Kehl',
  Latitude: '485.129.620',
  Longitude: '78.196.160',
  Telephone: '78547396',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.512962,7.819616',
  id: '48.512962,7.819616'
}, {
  Brand: 'Westfalen',
  Street: 'Bottroper 331',
  PostCode: null,
  City: 'ESSEN',
  Latitude: '514.849',
  Longitude: '697.206',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.4849,6.97206',
  id: '51.4849,6.97206'
}, {
  Brand: 'Eni',
  Street: 'Am Kreuzstein 55',
  PostCode: 63477,
  City: 'Maintal',
  Latitude: '5.014.811.000',
  Longitude: '880.783.000',
  Telephone: '+49 (0) 61096163',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.14811,8.80783',
  id: '50.14811,8.80783'
}, {
  Brand: 'TOTAL',
  Street: 'BONNER STR. 71',
  PostCode: 53332,
  City: 'BORNHEIM',
  Latitude: '50.756.348',
  Longitude: '7.008.644',
  Telephone: '02222-9890200',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.756348,7.008644',
  id: '50.756348,7.008644'
}, {
  Brand: 'ARAL',
  Street: 'Zum Apelstein 1',
  PostCode: 4158,
  City: 'Leipzig / Lindenthal',
  Latitude: '513.935.510',
  Longitude: '123.419.640',
  Telephone: '3414618761',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '21:00',
  SundayFrom: '05:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.393551,12.341964',
  id: '51.393551,12.341964'
}, {
  Brand: 'TOTAL',
  Street: 'GOERLITZER STR.36',
  PostCode: 2906,
  City: 'NIESKY',
  Latitude: '51.288.299',
  Longitude: '1.482.058',
  Telephone: '03588-205610',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.288299,14.82058',
  id: '51.288299,14.82058'
}, {
  Brand: 'TOTAL',
  Street: '40 VLAEMISCHE STR.',
  PostCode: 49688,
  City: 'LASTRUP',
  Latitude: '52.797.332',
  Longitude: '7.865.608',
  Telephone: '04472-1450',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.797332,7.865608',
  id: '52.797332,7.865608'
}, {
  Brand: 'TOTAL',
  Street: '30 HERMANNSTEINERSTR.',
  PostCode: 35576,
  City: 'WETZLAR',
  Latitude: '5.056.834',
  Longitude: '849.974',
  Telephone: '06441-36333',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.56834,8.49974',
  id: '50.56834,8.49974'
}, {
  Brand: 'ARAL',
  Street: 'Zur Oschütz 1',
  PostCode: 7338,
  City: 'Kaulsdorf',
  Latitude: '506.172.370',
  Longitude: '114.280.400',
  Telephone: '3673332630',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.617237,11.42804',
  id: '50.617237,11.42804'
}, {
  Brand: 'TOTAL',
  Street: 'U.-VON-HUTTEN-STR. 7a',
  PostCode: 18069,
  City: 'ROSTOCK',
  Latitude: '5.409.538',
  Longitude: '1.208.314',
  Telephone: '0381-8083459',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.09538,12.08314',
  id: '54.09538,12.08314'
}, {
  Brand: 'ARAL',
  Street: 'A3',
  PostCode: 91056,
  City: 'Erlangen',
  Latitude: '495.803.010',
  Longitude: '109.300.010',
  Telephone: '9131758670',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.580301,10.930001',
  id: '49.580301,10.930001'
}, {
  Brand: 'TOTAL',
  Street: 'ECKENHEIMER LANDSTR. 181',
  PostCode: 60320,
  City: 'FRANKFURT',
  Latitude: '5.013.218',
  Longitude: '8.683.576',
  Telephone: '069-95500411',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.13218,8.683576',
  id: '50.13218,8.683576'
}, {
  Brand: 'Eni',
  Street: 'Bahnhofstr. 18',
  PostCode: 77709,
  City: 'Wolfach',
  Latitude: '4.829.414.979',
  Longitude: '822.118.453',
  Telephone: '+49 (0) 78344041',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.29414979,8.22118453',
  id: '48.29414979,8.22118453'
}, {
  Brand: 'ARAL',
  Street: 'Unterhachinger Straße 28',
  PostCode: 85521,
  City: 'Ottobrunn',
  Latitude: '480.631.450',
  Longitude: '116.520.120',
  Telephone: '896093130',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.063145,11.652012',
  id: '48.063145,11.652012'
}, {
  Brand: 'ARAL',
  Street: 'Portitzer Straße 71',
  PostCode: 4425,
  City: 'Taucha',
  Latitude: '513.820.790',
  Longitude: '124.776.120',
  Telephone: '3429863646',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '06:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.382079,12.477612',
  id: '51.382079,12.477612'
}, {
  Brand: 'TOTAL',
  Street: 'KLINGENTHALER STR. 3',
  PostCode: 8261,
  City: 'SCHOENECK',
  Latitude: '50.394.992',
  Longitude: '1.234.057',
  Telephone: '037464-82595',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.394992,12.34057',
  id: '50.394992,12.34057'
}, {
  Brand: 'TOTAL',
  Street: 'VELTENER STR. 22',
  PostCode: 16761,
  City: 'HENNIGSDORF',
  Latitude: '52.653.899',
  Longitude: '13.204.295',
  Telephone: '03302-810824',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.653899,13.204295',
  id: '52.653899,13.204295'
}, {
  Brand: 'TOTAL',
  Street: 'BODENBACHER STR. 64',
  PostCode: 1277,
  City: 'DRESDEN',
  Latitude: '51.029.399',
  Longitude: '13.790.689',
  Telephone: '0351-2561098',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.029399,13.790689',
  id: '51.029399,13.790689'
}, {
  Brand: 'TOTAL',
  Street: 'RHEINBERGER STR. 15',
  PostCode: 46509,
  City: 'XANTEN',
  Latitude: '51.654',
  Longitude: '647.073',
  Telephone: '02801-5360',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.654,6.47073',
  id: '51.654,6.47073'
}, {
  Brand: 'TOTAL',
  Street: 'DUERENER STR. 308',
  PostCode: 52249,
  City: 'ESCHWEILER',
  Latitude: '50.819.706',
  Longitude: '6.292.115',
  Telephone: '02403-21572',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.819706,6.292115',
  id: '50.819706,6.292115'
}, {
  Brand: 'TOTAL',
  Street: 'BAB OST/A 11',
  PostCode: 16244,
  City: 'SCHORFHEIDE-BUCKOWSEE',
  Latitude: '52.883.315',
  Longitude: '13.704.688',
  Telephone: '033363-46204',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.883315,13.704688',
  id: '52.883315,13.704688'
}, {
  Brand: 'ARAL',
  Street: 'Lindauer Straße 26',
  PostCode: 88069,
  City: 'Tettnang',
  Latitude: '476.685.180',
  Longitude: '95.912.570',
  Telephone: '75428456',
  WeekDayFrom: '06:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '22:00',
  SundayFrom: '07:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.668518,9.591257',
  id: '47.668518,9.591257'
}, {
  Brand: 'ARAL',
  Street: 'Rheinlanddamm 103',
  PostCode: 44139,
  City: 'Dortmund',
  Latitude: '514.993.530',
  Longitude: '74.717.200',
  Telephone: '2319128872',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.499353,7.47172',
  id: '51.499353,7.47172'
}, {
  Brand: 'Westfalen',
  Street: 'Elberfelder 148',
  PostCode: null,
  City: 'RADEVORMWALD',
  Latitude: '512.022',
  Longitude: '732.606',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.2022,7.32606',
  id: '51.2022,7.32606'
}, {
  Brand: 'TOTAL',
  Street: 'KURHESSENSTR. 111',
  PostCode: 60431,
  City: 'FRANKFURT',
  Latitude: '50.148.045',
  Longitude: '8.656.484',
  Telephone: '069-512131',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.148045,8.656484',
  id: '50.148045,8.656484'
}, {
  Brand: 'ARAL',
  Street: 'Holsterfeld 2',
  PostCode: 48499,
  City: 'Salzbergen',
  Latitude: '523.267.200',
  Longitude: '74.294.400',
  Telephone: '597197260',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.32672,7.42944',
  id: '52.32672,7.42944'
}, {
  Brand: 'Eni',
  Street: 'Damaschkeweg 64',
  PostCode: 15234,
  City: 'Frankfurt/Oder',
  Latitude: '5.233.690.809',
  Longitude: '1.452.094.182',
  Telephone: '+49 (0) 33550000',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.33690809,14.52094182',
  id: '52.33690809,14.52094182'
}, {
  Brand: 'ARAL',
  Street: 'A31',
  PostCode: 49835,
  City: 'Wietmarschen',
  Latitude: '525.017.970',
  Longitude: '72.012.551',
  Telephone: '5925905968',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.501797,7.2012551',
  id: '52.501797,7.2012551'
}, {
  Brand: 'Eni',
  Street: 'Schaffhauser Strasse 47',
  PostCode: 79761,
  City: 'Waldshut-Tiengen',
  Latitude: '4.763.465.000',
  Longitude: '828.400.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '47.63465,8.284',
  id: '47.63465,8.284'
}, {
  Brand: 'ARAL',
  Street: 'A 38',
  PostCode: 6542,
  City: 'Allstedt',
  Latitude: '514.293.125',
  Longitude: '113.938.494',
  Telephone: '3465267690',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.4293125,11.3938494',
  id: '51.4293125,11.3938494'
}, {
  Brand: 'Westfalen',
  Street: 'Lennestr. 17',
  PostCode: null,
  City: 'WERDOHL',
  Latitude: '512.571',
  Longitude: '775.746',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.2571,7.75746',
  id: '51.2571,7.75746'
}, {
  Brand: 'ARAL',
  Street: 'A 27',
  PostCode: 27299,
  City: 'Langwedel',
  Latitude: '529.994.750',
  Longitude: '91.818.220',
  Telephone: '4232306040',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.999475,9.181822',
  id: '52.999475,9.181822'
}, {
  Brand: 'Eni',
  Street: 'Haenchener Strasse 4',
  PostCode: 3050,
  City: 'Cottbus',
  Latitude: '5.172.071.273',
  Longitude: '1.434.449.376',
  Telephone: '+49 (0) 35553408',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.72071273,14.34449376',
  id: '51.72071273,14.34449376'
}, {
  Brand: 'ARAL',
  Street: 'Amandus-Stubbe-Straße 6',
  PostCode: 22113,
  City: 'Hamburg',
  Latitude: '535.057.430',
  Longitude: '100.896.650',
  Telephone: '4082245990',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.505743,10.089665',
  id: '53.505743,10.089665'
}, {
  Brand: 'ARAL',
  Street: 'Gewerbeweg 8',
  PostCode: 49751,
  City: 'Sögel',
  Latitude: '528.342.410',
  Longitude: '75.153.210',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.834241,7.515321',
  id: '52.834241,7.515321'
}, {
  Brand: 'TOTAL',
  Street: 'THOMAS-MUENTZER-STR. 5',
  PostCode: 6217,
  City: 'MERSEBURG',
  Latitude: '51.344.918',
  Longitude: '11.987.999',
  Telephone: '03461-503727',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.344918,11.987999',
  id: '51.344918,11.987999'
}, {
  Brand: 'TOTAL',
  Street: 'ELSTERWERDAER STR. 35',
  PostCode: 1990,
  City: 'ORTRAND',
  Latitude: '51.375.599',
  Longitude: '13.749.588',
  Telephone: '035755-53819',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.375599,13.749588',
  id: '51.375599,13.749588'
}, {
  Brand: 'TOTAL',
  Street: '62 STUTTGARTER STR.',
  PostCode: 73614,
  City: 'SCHORNDORF',
  Latitude: '48.805.717',
  Longitude: '9.508.026',
  Telephone: '07181-75965',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.805717,9.508026',
  id: '48.805717,9.508026'
}, {
  Brand: 'TOTAL',
  Street: 'FRANKFURTER STR. 37',
  PostCode: 15907,
  City: 'LUEBBEN',
  Latitude: '51.943.099',
  Longitude: '13.913.482',
  Telephone: '03546-3106',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.943099,13.913482',
  id: '51.943099,13.913482'
}, {
  Brand: 'Westfalen',
  Street: 'Brueckenstr. 85',
  PostCode: null,
  City: 'GUMMERSBACH',
  Latitude: '510.236',
  Longitude: '755.463',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.0236,7.55463',
  id: '51.0236,7.55463'
}, {
  Brand: 'Eni',
  Street: 'Reutlinger Strasse 68',
  PostCode: 72766,
  City: 'Reutlingen-Sondelf.',
  Latitude: '4.851.504.000',
  Longitude: '922.598.000',
  Telephone: '+49 (0) 71214918',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.51504,9.22598',
  id: '48.51504,9.22598'
}, {
  Brand: 'TOTAL',
  Street: 'BREMIGSWIESE 2',
  PostCode: 51674,
  City: 'WIEHL',
  Latitude: '50.944.154',
  Longitude: '7.573.806',
  Telephone: '02262-7084871',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.944154,7.573806',
  id: '50.944154,7.573806'
}, {
  Brand: 'ARAL',
  Street: 'Otto-Perutz-Straße 1-3',
  PostCode: 81829,
  City: 'München',
  Latitude: '481.379.480',
  Longitude: '117.127.500',
  Telephone: '89460990620',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.137948,11.71275',
  id: '48.137948,11.71275'
}, {
  Brand: 'Eni',
  Street: 'Puetrichstrasse 29',
  PostCode: 82362,
  City: 'Weilheim',
  Latitude: '4.784.385.000',
  Longitude: '1.114.761.000',
  Telephone: '+49 (0) 8812885',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.84385,11.14761',
  id: '47.84385,11.14761'
}, {
  Brand: 'ARAL',
  Street: 'A 39 Salzgitterhüttenblick Nord',
  PostCode: 38239,
  City: 'Salzgitter',
  Latitude: '521.913.010',
  Longitude: '103.992.070',
  Telephone: '53009304490',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.191301,10.399207',
  id: '52.191301,10.399207'
}, {
  Brand: 'Eni',
  Street: 'Pockinger Strasse 127',
  PostCode: 94072,
  City: 'Bad Fuessing',
  Latitude: '4.835.851.022',
  Longitude: '1.331.183.797',
  Telephone: '+49 (0) 85319800',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.35851022,13.31183797',
  id: '48.35851022,13.31183797'
}, {
  Brand: 'Westfalen',
  Street: 'Horster Str. 21',
  PostCode: null,
  City: 'WERNE',
  Latitude: '516.681',
  Longitude: '764.041',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.6681,7.64041',
  id: '51.6681,7.64041'
}, {
  Brand: 'ARAL',
  Street: 'Elseyer Straße 43',
  PostCode: 58119,
  City: 'Hagen',
  Latitude: '513.641.320',
  Longitude: '75.533.500',
  Telephone: '233451418',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.364132,7.55335',
  id: '51.364132,7.55335'
}, {
  Brand: 'OMV',
  Street: 'Wackersdorfer Str. 52',
  PostCode: 92421,
  City: 'Schwandorf',
  Latitude: '4.932.264.000',
  Longitude: '1.212.156.000',
  Telephone: '+49-9431-2521',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.32264,12.12156',
  id: '49.32264,12.12156'
}, {
  Brand: 'Westfalen',
  Street: 'Wüllener Str. 64',
  PostCode: null,
  City: 'AHAUS',
  Latitude: '520.738',
  Longitude: '699.724',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0738,6.99724',
  id: '52.0738,6.99724'
}, {
  Brand: 'Westfalen',
  Street: 'Schiffahrter Damm 29',
  PostCode: null,
  City: 'MUENSTER',
  Latitude: '519.691',
  Longitude: '765.604',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9691,7.65604',
  id: '51.9691,7.65604'
}, {
  Brand: 'Westfalen',
  Street: 'Kirchsiek 11',
  PostCode: null,
  City: 'PORTA WESTFALICA',
  Latitude: '522.394',
  Longitude: '892.517',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.2394,8.92517',
  id: '52.2394,8.92517'
}, {
  Brand: 'Eni',
  Street: 'Karlstr. 60',
  PostCode: 71394,
  City: 'Kernen',
  Latitude: '4.880.870.000',
  Longitude: '932.529.000',
  Telephone: '+49 (0) 7151-206',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.8087,9.32529',
  id: '48.8087,9.32529'
}, {
  Brand: 'Eni',
  Street: 'Bayreuther Str. 46',
  PostCode: 92224,
  City: 'Amberg',
  Latitude: '4.945.707.000',
  Longitude: '1.184.874.000',
  Telephone: '+49 (0) 96216202',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.45707,11.84874',
  id: '49.45707,11.84874'
}, {
  Brand: 'OMV',
  Street: 'Hauptstr. 87',
  PostCode: 72636,
  City: 'Frickenhausen',
  Latitude: '4.858.636.000',
  Longitude: '936.552.000',
  Telephone: '+49-7022-949270',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.58636,9.36552',
  id: '48.58636,9.36552'
}, {
  Brand: 'Westfalen',
  Street: 'Altenberger 128',
  PostCode: null,
  City: 'STEINFURT',
  Latitude: '52.111',
  Longitude: '740.107',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.111,7.40107',
  id: '52.111,7.40107'
}, {
  Brand: 'OMV',
  Street: 'Hauptstr. 30',
  PostCode: 91183,
  City: 'Wassermungenau',
  Latitude: '4.921.678.000',
  Longitude: '1.088.444.000',
  Telephone: '+49-9873-257',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.21678,10.88444',
  id: '49.21678,10.88444'
}, {
  Brand: 'Eni',
  Street: 'Schwetzinger Strasse 138',
  PostCode: 69124,
  City: 'Heidelberg',
  Latitude: '4.938.494.000',
  Longitude: '867.295.000',
  Telephone: '+49 (0) 62217855',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.38494,8.67295',
  id: '49.38494,8.67295'
}, {
  Brand: 'Westfalen',
  Street: 'Holtwicker Str. 31',
  PostCode: null,
  City: 'COESFELD',
  Latitude: '519.528',
  Longitude: '716.396',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9528,7.16396',
  id: '51.9528,7.16396'
}, {
  Brand: 'OMV',
  Street: 'Altdorfer Str. 15',
  PostCode: 90537,
  City: 'Feucht',
  Latitude: '4.937.714.000',
  Longitude: '1.121.555.000',
  Telephone: '+49-9128-3594',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.37714,11.21555',
  id: '49.37714,11.21555'
}, {
  Brand: 'Eni',
  Street: 'Kammermoosstr. 20',
  PostCode: 88339,
  City: 'Bad Waldsee',
  Latitude: '4.790.225.967',
  Longitude: '969.823.890',
  Telephone: '+49 (0) 75243111',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.90225967,9.6982389',
  id: '47.90225967,9.6982389'
}, {
  Brand: 'TOTAL',
  Street: 'STRALSUNDER CH. 18',
  PostCode: 18528,
  City: 'BERGEN',
  Latitude: '54.407.479',
  Longitude: '13.418.115',
  Telephone: '03838-22354',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.407479,13.418115',
  id: '54.407479,13.418115'
}, {
  Brand: 'TOTAL',
  Street: 'INDUSTRIESTRASSE 2',
  PostCode: 56412,
  City: 'HEILIGENROTH',
  Latitude: '50.451.513',
  Longitude: '7.848.464',
  Telephone: '02602-8387954',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.451513,7.848464',
  id: '50.451513,7.848464'
}, {
  Brand: 'TOTAL',
  Street: 'APFELWEG 2',
  PostCode: 38871,
  City: 'ILSENBURG',
  Latitude: '51.873.069',
  Longitude: '10.692.185',
  Telephone: '039452-49262',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.873069,10.692185',
  id: '51.873069,10.692185'
}, {
  Brand: 'TOTAL',
  Street: 'SCHORNDORFER STR. 10',
  PostCode: 71384,
  City: 'WEINSTADT',
  Latitude: '4.881.411',
  Longitude: '936.907',
  Telephone: '715161243',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.81411,9.36907',
  id: '48.81411,9.36907'
}, {
  Brand: 'ARAL',
  Street: 'Am Weidendamm',
  PostCode: 15831,
  City: 'Groß Kienitz',
  Latitude: '523.189.930',
  Longitude: '134.709.110',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.318993,13.470911',
  id: '52.318993,13.470911'
}, {
  Brand: 'ARAL',
  Street: 'Bochumer Straße 1',
  PostCode: 45663,
  City: 'Recklinghausen',
  Latitude: '515.827.870',
  Longitude: '72.130.930',
  Telephone: '236134084',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.582787,7.213093',
  id: '51.582787,7.213093'
}, {
  Brand: 'ARAL',
  Street: 'Maurerstraße 37',
  PostCode: 52477,
  City: 'Alsdorf',
  Latitude: '508.790.330',
  Longitude: '61.836.910',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.879033,6.183691',
  id: '50.879033,6.183691'
}, {
  Brand: 'Eni',
  Street: 'Lindauer Str. 43',
  PostCode: 88069,
  City: 'Tettnang',
  Latitude: '4.766.662.000',
  Longitude: '959.375.000',
  Telephone: '+49 (0) 75427400',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.66662,9.59375',
  id: '47.66662,9.59375'
}, {
  Brand: 'Westfalen',
  Street: 'Schneidemuehler Str. 9',
  PostCode: null,
  City: 'MUENSTER',
  Latitude: '519.946',
  Longitude: '764.946',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9946,7.64946',
  id: '51.9946,7.64946'
}, {
  Brand: 'ARAL',
  Street: 'Im Seewinkel 26',
  PostCode: 77652,
  City: 'Offenburg',
  Latitude: '484.815.750',
  Longitude: '79.368.810',
  Telephone: '78193226252',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.481575,7.936881',
  id: '48.481575,7.936881'
}, {
  Brand: 'ARAL',
  Street: 'Bautzner Landstraße 246',
  PostCode: 1328,
  City: 'Dresden OT Weißig',
  Latitude: '510.603.530',
  Longitude: '138.747.020',
  Telephone: '3512690772',
  WeekDayFrom: '05:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.060353,13.874702',
  id: '51.060353,13.874702'
}, {
  Brand: 'TOTAL',
  Street: '16 AUGSBURGER STRASSE',
  PostCode: 86609,
  City: 'DONAUWOERTH',
  Latitude: '48.710.815',
  Longitude: '10.787.838',
  Telephone: '0906-4494',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.710815,10.787838',
  id: '48.710815,10.787838'
}, {
  Brand: 'ARAL',
  Street: 'A7',
  PostCode: 34253,
  City: 'Lohfelden',
  Latitude: '512.774.049',
  Longitude: '95.318.486',
  Telephone: '56158580000',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.2774049,9.5318486',
  id: '51.2774049,9.5318486'
}, {
  Brand: 'Westfalen',
  Street: 'Appelhuelsener Str. 16',
  PostCode: null,
  City: 'NOTTULN',
  Latitude: '519.284',
  Longitude: '736.153',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9284,7.36153',
  id: '51.9284,7.36153'
}, {
  Brand: 'TOTAL',
  Street: 'COLBITZER STR.',
  PostCode: 39326,
  City: 'WOLMIRSTEDT',
  Latitude: '52.257.223',
  Longitude: '11.628.863',
  Telephone: '039201-21376',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.257223,11.628863',
  id: '52.257223,11.628863'
}, {
  Brand: 'ARAL',
  Street: 'Gewerbestraße 1',
  PostCode: 18233,
  City: 'Neubukow',
  Latitude: '540.390.410',
  Longitude: '116.911.520',
  Telephone: '3829416840',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '54.039041,11.691152',
  id: '54.039041,11.691152'
}, {
  Brand: 'Eni',
  Street: 'Heilbronner Strasse 31',
  PostCode: 74177,
  City: 'Bad Friedrichshall',
  Latitude: '4.921.915.000',
  Longitude: '921.513.000',
  Telephone: '+49 (0) 71362371',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.21915,9.21513',
  id: '49.21915,9.21513'
}, {
  Brand: 'Eni',
  Street: 'Enderstrasse 92 e',
  PostCode: 1277,
  City: 'Dresden',
  Latitude: '5.103.351.000',
  Longitude: '1.380.604.000',
  Telephone: '0351 / 25020192',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.03351,13.80604',
  id: '51.03351,13.80604'
}, {
  Brand: 'TOTAL',
  Street: '10 ADENAUER ALLEE',
  PostCode: 25524,
  City: 'ITZEHOE',
  Latitude: '5.392.171',
  Longitude: '9.512.394',
  Telephone: '04821-3333',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.92171,9.512394',
  id: '53.92171,9.512394'
}, {
  Brand: 'TOTAL',
  Street: 'AUTOHOF WILHELM-MAYBACH-STR. 3',
  PostCode: 89312,
  City: 'GUENZBURG',
  Latitude: '48.427.778',
  Longitude: '10.288.333',
  Telephone: '08221-2044013',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.427778,10.288333',
  id: '48.427778,10.288333'
}, {
  Brand: 'Eni',
  Street: 'Wuerzburger Strasse 4',
  PostCode: 97218,
  City: 'Gerbrunn',
  Latitude: '4.978.145.800',
  Longitude: '997.848.300',
  Telephone: '+49 (0) 93170768',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.781458,9.978483',
  id: '49.781458,9.978483'
}, {
  Brand: 'ARAL',
  Street: 'A3',
  PostCode: 63879,
  City: 'Weibersbrunn',
  Latitude: '498.963.650',
  Longitude: '93.955.830',
  Telephone: '6094',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.896365,9.395583',
  id: '49.896365,9.395583'
}, {
  Brand: 'OMV',
  Street: 'Allersberger Str. 22',
  PostCode: 91161,
  City: 'Hilpoltstein',
  Latitude: '4.919.371.000',
  Longitude: '1.118.906.000',
  Telephone: '+49-9174-1279',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.19371,11.18906',
  id: '49.19371,11.18906'
}, {
  Brand: 'TOTAL',
  Street: 'HARTH.HAUPTSTR. 50-52',
  PostCode: 70794,
  City: 'FILDERSTADT-HARTH',
  Latitude: '48.651.634',
  Longitude: '9.246.344',
  Telephone: '07158-8333',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.651634,9.246344',
  id: '48.651634,9.246344'
}, {
  Brand: 'Westfalen',
  Street: 'Osnabruecker Str. 4',
  PostCode: null,
  City: 'ROEDINGHAUSEN',
  Latitude: '521.987',
  Longitude: '846.148',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.1987,8.46148',
  id: '52.1987,8.46148'
}, {
  Brand: 'Eni',
  Street: 'Im Gewerbepark',
  PostCode: 96155,
  City: 'Buttenheim',
  Latitude: '4.979.857.000',
  Longitude: '1.102.748.000',
  Telephone: '+49 (0) 95454455',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.79857,11.02748',
  id: '49.79857,11.02748'
}, {
  Brand: 'Eni',
  Street: 'Frontenhausener Str. 40',
  PostCode: 84140,
  City: 'Gangkofen',
  Latitude: '4.843.850.067',
  Longitude: '1.256.236.309',
  Telephone: '+49 (0) 8722481',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.43850067,12.56236309',
  id: '48.43850067,12.56236309'
}, {
  Brand: 'Westfalen',
  Street: 'Grevener Str. 5',
  PostCode: null,
  City: 'LADBERGEN',
  Latitude: '52.136',
  Longitude: '773.631',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.136,7.73631',
  id: '52.136,7.73631'
}, {
  Brand: 'Eni',
  Street: 'Wittstocker Kreuz, Dorfstrae 67',
  PostCode: 16909,
  City: 'Liebenthal',
  Latitude: '5.314.874.161',
  Longitude: '1.239.723.847',
  Telephone: '+49 (0) 33962509',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '53.14874161,12.39723847',
  id: '53.14874161,12.39723847'
}, {
  Brand: 'Eni',
  Street: 'Hofheimer Str. 61',
  PostCode: 65719,
  City: 'Hofheim-Lorsbach',
  Latitude: '5.011.231.159',
  Longitude: '842.627.387',
  Telephone: '+49 (0) 61929019',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.11231159,8.42627387',
  id: '50.11231159,8.42627387'
}, {
  Brand: 'OMV',
  Street: 'Heidelberger Str. 49',
  PostCode: 76646,
  City: 'Bruchsal',
  Latitude: '4.913.613.000',
  Longitude: '860.429.000',
  Telephone: '+49-7251-300691',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.13613,8.60429',
  id: '49.13613,8.60429'
}, {
  Brand: 'Westfalen',
  Street: 'Dufkamp 87',
  PostCode: null,
  City: 'STADTLOHN',
  Latitude: '519.925',
  Longitude: '6.9273',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9925,6.9273',
  id: '51.9925,6.9273'
}, {
  Brand: 'TOTAL',
  Street: 'BRUCHSALER STR. 15',
  PostCode: 76698,
  City: 'UBSTADT',
  Latitude: '49.155.665',
  Longitude: '8.626.797',
  Telephone: '07251-967011',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.155665,8.626797',
  id: '49.155665,8.626797'
}, {
  Brand: 'TOTAL',
  Street: '1 WEILRODER STR.',
  PostCode: 61389,
  City: 'SCHMITTEN',
  Latitude: '50.289.255',
  Longitude: '8.461.242',
  Telephone: '60842377',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.289255,8.461242',
  id: '50.289255,8.461242'
}, {
  Brand: 'Eni',
  Street: 'Bat A 111 Stolper Heide',
  PostCode: 16540,
  City: 'Hohen-Neuendorf',
  Latitude: '5.265.138.313',
  Longitude: '1.324.402.717',
  Telephone: '+49 (0) 33024938',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '52.65138313,13.24402717',
  id: '52.65138313,13.24402717'
}, {
  Brand: 'Westfalen',
  Street: 'Bahnhofstr. 30',
  PostCode: null,
  City: 'DRENSTEINFURT',
  Latitude: '51.8',
  Longitude: '773.369',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8,7.73369',
  id: '51.8,7.73369'
}, {
  Brand: 'Eni',
  Street: 'Oelsnitzer Str. 10-12',
  PostCode: 8527,
  City: 'Plauen',
  Latitude: '5.048.498.767',
  Longitude: '1.213.900.274',
  Telephone: '+49 (0) 37412248',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.48498767,12.13900274',
  id: '50.48498767,12.13900274'
}, {
  Brand: 'ARAL',
  Street: 'Heisinger Straße 25',
  PostCode: 87463,
  City: 'Dietmannsried',
  Latitude: '478.068.430',
  Longitude: '102.962.860',
  Telephone: '83746710',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.806843,10.296286',
  id: '47.806843,10.296286'
}, {
  Brand: 'ARAL',
  Street: 'Industriering 5',
  PostCode: 49393,
  City: 'Lohne',
  Latitude: '526.583.320',
  Longitude: '82.007.400',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.658332,8.20074',
  id: '52.658332,8.20074'
}, {
  Brand: 'ARAL',
  Street: 'Werner-von-Siemens-Straße 3',
  PostCode: 83301,
  City: 'Traunreut',
  Latitude: '479.663.210',
  Longitude: '125.915.630',
  Telephone: '86692368',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:30',
  SaturdayFrom: '05:30',
  SaturdayTo: '23:59',
  SundayFrom: '06:30',
  SundayTo: '22:30',
  IsOpen24Hours: 'Nein',
  location: '47.966321,12.591563',
  id: '47.966321,12.591563'
}, {
  Brand: 'TOTAL',
  Street: '17 INNERE HERLASGRUENER STR.',
  PostCode: 8233,
  City: 'TREUEN',
  Latitude: '50.544.184',
  Longitude: '1.229.917',
  Telephone: '037468-2639',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.544184,12.29917',
  id: '50.544184,12.29917'
}, {
  Brand: 'TOTAL',
  Street: 'AM STEINERNEN KREUZ 1-3',
  PostCode: 96110,
  City: 'SCHESSLITZ',
  Latitude: '49.979.998',
  Longitude: '11.042.786',
  Telephone: '0954-2942020',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.979998,11.042786',
  id: '49.979998,11.042786'
}, {
  Brand: 'TOTAL',
  Street: 'AUGSBURGER STR. 29',
  PostCode: 89331,
  City: 'BURGAU',
  Latitude: '48.425.449',
  Longitude: '10.417.332',
  Telephone: '08222-410209',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.425449,10.417332',
  id: '48.425449,10.417332'
}, {
  Brand: 'TOTAL',
  Street: 'WOLFHAGER STRASSE 276',
  PostCode: 34128,
  City: 'KASSEL',
  Latitude: '51.331.399',
  Longitude: '9.455.723',
  Telephone: '0561-882422',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.331399,9.455723',
  id: '51.331399,9.455723'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 73',
  PostCode: 67487,
  City: 'Maikammer',
  Latitude: '493.064.610',
  Longitude: '81.421.640',
  Telephone: '63215222',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.306461,8.142164',
  id: '49.306461,8.142164'
}, {
  Brand: 'TOTAL',
  Street: 'DEHMER STR. 52',
  PostCode: 32549,
  City: 'BAD OEYNHAUSEN',
  Latitude: '52.233.714',
  Longitude: '883.337',
  Telephone: '05731-4989381',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.233714,8.83337',
  id: '52.233714,8.83337'
}, {
  Brand: 'TOTAL',
  Street: 'MERSEBURGER STR. 359',
  PostCode: 6132,
  City: 'HALLE',
  Latitude: '51.438.264',
  Longitude: '11.984.794',
  Telephone: '0345-7809911',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.438264,11.984794',
  id: '51.438264,11.984794'
}, {
  Brand: 'TOTAL',
  Street: 'AUF DER BROOKE 2',
  PostCode: 27404,
  City: 'ZEVEN-ELSDORF',
  Latitude: '53.230.603',
  Longitude: '9.342.874',
  Telephone: '04286-9266416',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.230603,9.342874',
  id: '53.230603,9.342874'
}, {
  Brand: 'ARAL',
  Street: 'Winckeläckerstr.',
  PostCode: 86807,
  City: 'Buchloe',
  Latitude: '480.283.080',
  Longitude: '107.067.700',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.028308,10.70677',
  id: '48.028308,10.70677'
}, {
  Brand: 'OMV',
  Street: 'Europastr. 3',
  PostCode: 87700,
  City: 'Memmingen',
  Latitude: '4.799.959.000',
  Longitude: '1.015.282.000',
  Telephone: '+49-8331-928485',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '47.99959,10.15282',
  id: '47.99959,10.15282'
}, {
  Brand: 'TOTAL',
  Street: '50 BURBACHER STR.',
  PostCode: 66117,
  City: 'SAARBRUECKEN',
  Latitude: '4.923.643',
  Longitude: '6.942.589',
  Telephone: '0681-703332',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.23643,6.942589',
  id: '49.23643,6.942589'
}, {
  Brand: 'TOTAL',
  Street: 'BAB A5/MAHLBERG-OST',
  PostCode: 77972,
  City: 'MAHLBERG',
  Latitude: '4.830.663',
  Longitude: '7.790.957',
  Telephone: '07825-849631',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.30663,7.790957',
  id: '48.30663,7.790957'
}, {
  Brand: 'TOTAL',
  Street: 'FELDSTR. 159-161',
  PostCode: 45699,
  City: 'HERTEN',
  Latitude: '51.607.199',
  Longitude: '7.124.186',
  Telephone: '02366-937333',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.607199,7.124186',
  id: '51.607199,7.124186'
}, {
  Brand: 'TOTAL',
  Street: 'BERLINER STR. 107',
  PostCode: 14547,
  City: 'BEELITZ',
  Latitude: '52.252.376',
  Longitude: '12.9861',
  Telephone: '033204-35508',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.252376,12.9861',
  id: '52.252376,12.9861'
}, {
  Brand: 'TOTAL',
  Street: 'BAB WESTSEITE',
  PostCode: 91620,
  City: 'OHRENBACH',
  Latitude: '49.485.222',
  Longitude: '10.210.361',
  Telephone: '09865-986113',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.485222,10.210361',
  id: '49.485222,10.210361'
}, {
  Brand: 'Eni',
  Street: 'Arneburger Strasse',
  PostCode: 39576,
  City: 'Stendal',
  Latitude: '5.261.214.000',
  Longitude: '1.186.306.000',
  Telephone: '+49 (0) 39317110',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '52.61214,11.86306',
  id: '52.61214,11.86306'
}, {
  Brand: 'TOTAL',
  Street: 'KOECKERN OST / BAB 9',
  PostCode: 6794,
  City: 'SANDERSDORF-BREHNA',
  Latitude: '516.064',
  Longitude: '12.185.646',
  Telephone: '034954-39237',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.6064,12.185646',
  id: '51.6064,12.185646'
}, {
  Brand: 'ARAL',
  Street: 'Beuchaer Straße 73 a',
  PostCode: 4821,
  City: 'Brandis',
  Latitude: '513.299.140',
  Longitude: '125.928.260',
  Telephone: '3429274221',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '51.329914,12.592826',
  id: '51.329914,12.592826'
}, {
  Brand: 'TOTAL',
  Street: 'BRANDISERSTR.1',
  PostCode: 4916,
  City: 'SCHOENEWALDE',
  Latitude: '51.813.387',
  Longitude: '13.208.991',
  Telephone: '353626060',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.813387,13.208991',
  id: '51.813387,13.208991'
}, {
  Brand: 'TOTAL',
  Street: 'SANDBREITE 1',
  PostCode: 6869,
  City: 'COSWIG',
  Latitude: '51.889.016',
  Longitude: '12.403.235',
  Telephone: '34903472774',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.889016,12.403235',
  id: '51.889016,12.403235'
}, {
  Brand: 'TOTAL',
  Street: 'BINGERSTR. 27',
  PostCode: 55131,
  City: 'MAINZ',
  Latitude: '49.997.663',
  Longitude: '8.256.677',
  Telephone: '06131-1432888',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.997663,8.256677',
  id: '49.997663,8.256677'
}, {
  Brand: 'TOTAL',
  Street: 'AM AUTOHOF 1',
  PostCode: 6188,
  City: 'LANDSBERG',
  Latitude: '51.544.789',
  Longitude: '1.201.427',
  Telephone: '034604/24690',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.544789,12.01427',
  id: '51.544789,12.01427'
}, {
  Brand: 'TOTAL',
  Street: 'STREITSTR. 84',
  PostCode: 13587,
  City: 'BERLIN',
  Latitude: '5.255.424',
  Longitude: '13.207.408',
  Telephone: '030/74782807',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.55424,13.207408',
  id: '52.55424,13.207408'
}, {
  Brand: 'Eni',
  Street: 'Jacob-Kaiser-Str. 20',
  PostCode: 18437,
  City: 'Stralsund',
  Latitude: '5.431.398.000',
  Longitude: '1.308.579.000',
  Telephone: '03831/498428',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.31398,13.08579',
  id: '54.31398,13.08579'
}, {
  Brand: 'TOTAL',
  Street: 'KALTHOFER FELD 1',
  PostCode: 58640,
  City: 'ISERLOHN',
  Latitude: '51.426.045',
  Longitude: '7.675.567',
  Telephone: '02371-44346',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.426045,7.675567',
  id: '51.426045,7.675567'
}, {
  Brand: 'TOTAL',
  Street: 'HAGENOWER CHAUSSEE 1',
  PostCode: 19243,
  City: 'WITTENBURG',
  Latitude: '53.505.747',
  Longitude: '11.079.898',
  Telephone: '038852-50132',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.505747,11.079898',
  id: '53.505747,11.079898'
}, {
  Brand: 'TOTAL',
  Street: 'TODDINER CH. 5',
  PostCode: 19230,
  City: 'HAGENOW',
  Latitude: '53.436.633',
  Longitude: '11.174.675',
  Telephone: '03883-722207',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.436633,11.174675',
  id: '53.436633,11.174675'
}, {
  Brand: 'TOTAL',
  Street: 'BURGALTENDORFER STR.3',
  PostCode: 45529,
  City: 'HATTINGEN',
  Latitude: '51.405.599',
  Longitude: '7.139.186',
  Telephone: '02324-40756',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.405599,7.139186',
  id: '51.405599,7.139186'
}, {
  Brand: 'Eni',
  Street: 'Augsburger Str. 23',
  PostCode: 86899,
  City: 'Landsberg',
  Latitude: '4.805.769.588',
  Longitude: '1.086.800.049',
  Telephone: '+49 (0) 81912483',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.05769588,10.86800049',
  id: '48.05769588,10.86800049'
}, {
  Brand: 'ARAL',
  Street: 'Bamenohler Straße 113',
  PostCode: 57413,
  City: 'Finnentrop',
  Latitude: '511.684.720',
  Longitude: '79.770.230',
  Telephone: '272150879',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.168472,7.977023',
  id: '51.168472,7.977023'
}, {
  Brand: 'OMV',
  Street: 'Auchtertstr. 19',
  PostCode: 72555,
  City: 'Metzingen',
  Latitude: '4.853.227.000',
  Longitude: '928.927.000',
  Telephone: '+49-7123-165553',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.53227,9.28927',
  id: '48.53227,9.28927'
}, {
  Brand: 'TOTAL',
  Street: 'BAB SAMERBERG/SUED A8',
  PostCode: 83101,
  City: 'ROHRDORF',
  Latitude: '4.780.201',
  Longitude: '1.217.543',
  Telephone: '08032-98930',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.80201,12.17543',
  id: '47.80201,12.17543'
}, {
  Brand: 'TOTAL',
  Street: 'KARLSRUHER STR. 82',
  PostCode: 76139,
  City: 'KARLSRUHE',
  Latitude: '49.025.306',
  Longitude: '8.448.921',
  Telephone: '0721-681250',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.025306,8.448921',
  id: '49.025306,8.448921'
}, {
  Brand: 'TOTAL',
  Street: 'HOEHERWEG 202',
  PostCode: 40233,
  City: 'DUESSELDORF',
  Latitude: '51.222.132',
  Longitude: '6.820.284',
  Telephone: '0211-5072244',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.222132,6.820284',
  id: '51.222132,6.820284'
}, {
  Brand: 'OMV',
  Street: 'Raiffeisenallee 7',
  PostCode: 82041,
  City: 'Oberhaching',
  Latitude: '4.803.234.000',
  Longitude: '1.158.462.000',
  Telephone: '+49-89-66665010',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.03234,11.58462',
  id: '48.03234,11.58462'
}, {
  Brand: 'OMV',
  Street: 'Berger Weg 15',
  PostCode: 87538,
  City: 'Fischen',
  Latitude: '4.746.182.000',
  Longitude: '1.026.796.000',
  Telephone: '+49-8326-7922',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.46182,10.26796',
  id: '47.46182,10.26796'
}, {
  Brand: 'OMV',
  Street: 'Stuttgarter Str. 150',
  PostCode: 74321,
  City: 'Bietigheim-Bissingen',
  Latitude: '4.894.455.000',
  Longitude: '913.912.000',
  Telephone: '+49-7142-773636',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.94455,9.13912',
  id: '48.94455,9.13912'
}, {
  Brand: 'ARAL',
  Street: 'Westerwaldstraße 8',
  PostCode: 56587,
  City: 'Oberhonnefeld-Gierend',
  Latitude: '505.607.180',
  Longitude: '75.273.420',
  Telephone: '26349231614',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.560718,7.527342',
  id: '50.560718,7.527342'
}, {
  Brand: 'Westfalen',
  Street: 'Am Rohrbusch 52',
  PostCode: null,
  City: 'BAT MUENSTERLAND OST MÜNSTER',
  Latitude: '519.402',
  Longitude: '755.176',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9402,7.55176',
  id: '51.9402,7.55176'
}, {
  Brand: 'OMV',
  Street: 'Überlinger Strasse 13',
  PostCode: 78351,
  City: 'Bodman-Ludwigshafen',
  Latitude: '4.781.598.000',
  Longitude: '906.143.000',
  Telephone: '+49-7773-5725',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '47.81598,9.06143',
  id: '47.81598,9.06143'
}, {
  Brand: 'ARAL',
  Street: 'A8',
  PostCode: 83101,
  City: 'Rohrdorf',
  Latitude: '478.029.570',
  Longitude: '121.781.360',
  Telephone: '8032956910',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.802957,12.178136',
  id: '47.802957,12.178136'
}, {
  Brand: 'OMV',
  Street: 'Niorter Str. 4',
  PostCode: 96450,
  City: 'Coburg',
  Latitude: '5.028.668.000',
  Longitude: '1.098.028.000',
  Telephone: '+49-9561-235668',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.28668,10.98028',
  id: '50.28668,10.98028'
}, {
  Brand: 'OMV',
  Street: 'Steinweg 20',
  PostCode: 91578,
  City: 'Leutershausen',
  Latitude: '4.929.478.000',
  Longitude: '1.041.187.000',
  Telephone: '+49-9823-927829',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.29478,10.41187',
  id: '49.29478,10.41187'
}, {
  Brand: 'OMV',
  Street: 'Haide 2',
  PostCode: 95233,
  City: 'Helmbrechts',
  Latitude: '5.023.193.000',
  Longitude: '1.173.516.000',
  Telephone: '+49-9252-3509800',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.23193,11.73516',
  id: '50.23193,11.73516'
}, {
  Brand: 'OMV',
  Street: 'Südeinfahrt 4',
  PostCode: 84347,
  City: 'Pfarrkirchen',
  Latitude: '4.842.127.000',
  Longitude: '1.294.254.000',
  Telephone: '+49-8561-911615',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.42127,12.94254',
  id: '48.42127,12.94254'
}, {
  Brand: 'Westfalen',
  Street: 'Kasseler Str. 38',
  PostCode: null,
  City: 'FRITZLAR',
  Latitude: '511.357',
  Longitude: '9.2725',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.1357,9.2725',
  id: '51.1357,9.2725'
}, {
  Brand: 'Eni',
  Street: 'Ortenburger Strasse 40',
  PostCode: 94474,
  City: 'Vilshofen',
  Latitude: '4.862.699.120',
  Longitude: '1.318.982.900',
  Telephone: '+49 (0) 85418595',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.6269912,13.189829',
  id: '48.6269912,13.189829'
}, {
  Brand: 'OMV',
  Street: 'Memminger Str. 64',
  PostCode: 88299,
  City: 'Leutkirch',
  Latitude: '4.783.317.000',
  Longitude: '1.001.976.000',
  Telephone: '+49-7561-3666',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.83317,10.01976',
  id: '47.83317,10.01976'
}, {
  Brand: 'Eni',
  Street: 'Berliner Str. 30',
  PostCode: 72458,
  City: 'Albstadt',
  Latitude: '4.820.617.118',
  Longitude: '903.445.253',
  Telephone: '+49 (0) 74315116',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.20617118,9.03445253',
  id: '48.20617118,9.03445253'
}, {
  Brand: 'TOTAL',
  Street: 'BUECKEBERGSTR. 1',
  PostCode: 31137,
  City: 'HILDESHEIM',
  Latitude: '52.153.883',
  Longitude: '9.929.226',
  Telephone: '05121-43777',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.153883,9.929226',
  id: '52.153883,9.929226'
}, {
  Brand: 'OMV',
  Street: 'Regensburger Strasse 58',
  PostCode: 93133,
  City: 'Burglengenfeld',
  Latitude: '4.919.831.000',
  Longitude: '1.205.695.000',
  Telephone: '+49-9471-80105',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.19831,12.05695',
  id: '49.19831,12.05695'
}, {
  Brand: 'Westfalen',
  Street: 'Ringer Str. 10',
  PostCode: null,
  City: 'EMLICHHEIM',
  Latitude: '526.069',
  Longitude: '685.482',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.6069,6.85482',
  id: '52.6069,6.85482'
}, {
  Brand: 'Eni',
  Street: 'Bahnhofstrasse 6',
  PostCode: 82487,
  City: 'Oberammergau',
  Latitude: '4.759.880.896',
  Longitude: '1.105.929.735',
  Telephone: '+49 (0) 88229306',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.59880896,11.05929735',
  id: '47.59880896,11.05929735'
}, {
  Brand: 'ARAL',
  Street: 'Münchener Straße 32',
  PostCode: 83629,
  City: 'Weyarn',
  Latitude: '478.639.830',
  Longitude: '117.957.960',
  Telephone: '8020904680',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.863983,11.795796',
  id: '47.863983,11.795796'
}, {
  Brand: 'OMV',
  Street: 'Kammerforststr. (An der B35) 4',
  PostCode: 76646,
  City: 'Bruchsal / Nordseite',
  Latitude: '4.913.807.000',
  Longitude: '856.455.000',
  Telephone: '+49-7251-91270',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.13807,8.56455',
  id: '49.13807,8.56455'
}, {
  Brand: 'ARAL',
  Street: 'A7 - Ost',
  PostCode: 36151,
  City: 'Burghaun',
  Latitude: '507.129.690',
  Longitude: '96.625.510',
  Telephone: '6653292',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.712969,9.662551',
  id: '50.712969,9.662551'
}, {
  Brand: 'Eni',
  Street: 'Muenchner Strasse 284',
  PostCode: 85051,
  City: 'Ingolstadt',
  Latitude: '4.872.273.865',
  Longitude: '1.144.046.177',
  Telephone: '+49 (0) 84112945',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.72273865,11.44046177',
  id: '48.72273865,11.44046177'
}, {
  Brand: 'TOTAL',
  Street: 'SAARBRUECKER STR. 1b',
  PostCode: 66299,
  City: 'FRIEDRICHSTHAL',
  Latitude: '49.316.973',
  Longitude: '7.087.744',
  Telephone: '06897-89213',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.316973,7.087744',
  id: '49.316973,7.087744'
}, {
  Brand: 'ARAL',
  Street: 'Bodenseestraße 40',
  PostCode: 87700,
  City: 'Memmingen',
  Latitude: '479.799.690',
  Longitude: '101.718.860',
  Telephone: '833171532',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.979969,10.171886',
  id: '47.979969,10.171886'
}, {
  Brand: 'TOTAL',
  Street: 'NESSERLANDER STR. 21',
  PostCode: 26721,
  City: 'EMDEN',
  Latitude: '53.360.669',
  Longitude: '7.200.787',
  Telephone: '04921-22928',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.360669,7.200787',
  id: '53.360669,7.200787'
}, {
  Brand: 'Westfalen',
  Street: 'Zum Bauverein 44',
  PostCode: null,
  City: 'GELSENKIRCHEN',
  Latitude: '515.269',
  Longitude: '701.836',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.5269,7.01836',
  id: '51.5269,7.01836'
}, {
  Brand: 'TOTAL',
  Street: 'AN DER B 102 NR. 2',
  PostCode: 14727,
  City: 'PREMNITZ',
  Latitude: '525.247',
  Longitude: '12.358.189',
  Telephone: '03386-280770',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.5247,12.358189',
  id: '52.5247,12.358189'
}, {
  Brand: 'TOTAL',
  Street: 'WERFTSTR. 13',
  PostCode: 18439,
  City: 'STRALSUND',
  Latitude: '54.302.192',
  Longitude: '13.100.592',
  Telephone: '03831-292749',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.302192,13.100592',
  id: '54.302192,13.100592'
}, {
  Brand: 'TOTAL',
  Street: 'RHEINSTR. 38',
  PostCode: 67574,
  City: 'OSTHOFEN',
  Latitude: '49.704.717',
  Longitude: '8.331.841',
  Telephone: '06242-6258',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.704717,8.331841',
  id: '49.704717,8.331841'
}, {
  Brand: 'ARAL',
  Street: 'A 2/Ostendorfstraße 60',
  PostCode: 59069,
  City: 'Hamm',
  Latitude: '516.323.120',
  Longitude: '78.739.460',
  Telephone: '23852577',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.632312,7.873946',
  id: '51.632312,7.873946'
}, {
  Brand: 'TOTAL',
  Street: 'HANAUER LANDSTR.114',
  PostCode: 63791,
  City: 'KARLSTEIN',
  Latitude: '50.037.022',
  Longitude: '9.036.114',
  Telephone: '06188-990599/98',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.037022,9.036114',
  id: '50.037022,9.036114'
}, {
  Brand: 'OMV',
  Street: 'Riedenburger Str. 37',
  PostCode: 93309,
  City: 'Kelheim',
  Latitude: '4.892.308.000',
  Longitude: '1.186.925.000',
  Telephone: '+49-9441-3540',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.92308,11.86925',
  id: '48.92308,11.86925'
}, {
  Brand: 'OMV',
  Street: 'Rannetsreit 4 1/2',
  PostCode: 94535,
  City: 'Eging am See',
  Latitude: '4.868.968.000',
  Longitude: '1.321.300.000',
  Telephone: '+49-8544-91141',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.68968,13.213',
  id: '48.68968,13.213'
}, {
  Brand: 'Westfalen',
  Street: 'Westfälische Str. 21',
  PostCode: null,
  City: 'BOCHUM',
  Latitude: '514.621',
  Longitude: '7.1545',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.4621,7.1545',
  id: '51.4621,7.1545'
}, {
  Brand: 'ARAL',
  Street: 'Günzburger Straße 54',
  PostCode: 89264,
  City: 'Weißenhorn',
  Latitude: '483.118.290',
  Longitude: '101.578.520',
  Telephone: '7309969820',
  WeekDayFrom: '06:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.311829,10.157852',
  id: '48.311829,10.157852'
}, {
  Brand: 'TOTAL',
  Street: 'LANGENDORFER STR. 52',
  PostCode: 56564,
  City: 'NEUWIED',
  Latitude: '5.043.396',
  Longitude: '745.512',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.43396,7.45512',
  id: '50.43396,7.45512'
}, {
  Brand: 'Eni',
  Street: 'Stuttgarter Strasse 24',
  PostCode: 70839,
  City: 'Gerlingen',
  Latitude: '4.878.995.000',
  Longitude: '904.618.000',
  Telephone: '+49 (0) 71562530',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.78995,9.04618',
  id: '48.78995,9.04618'
}, {
  Brand: 'TOTAL',
  Street: 'BAB SUEDSEITE/A 4',
  PostCode: 99198,
  City: 'EICHELBORN',
  Latitude: '50.948.153',
  Longitude: '11.193.944',
  Telephone: '36209432950',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.948153,11.193944',
  id: '50.948153,11.193944'
}, {
  Brand: 'TOTAL',
  Street: 'BAB NORDSEITE / A4',
  PostCode: 99198,
  City: 'EICHELBORN',
  Latitude: '50.950.035',
  Longitude: '11.197.363',
  Telephone: '036209-40228',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.950035,11.197363',
  id: '50.950035,11.197363'
}, {
  Brand: 'OMV',
  Street: 'Innsbrucker Ring 153',
  PostCode: 81669,
  City: 'München',
  Latitude: '4.811.363.000',
  Longitude: '1.161.697.000',
  Telephone: '+49-89-6806548',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.11363,11.61697',
  id: '48.11363,11.61697'
}, {
  Brand: 'TOTAL',
  Street: 'NIEVENHEIM WEST A57',
  PostCode: 41542,
  City: 'DORMAGEN',
  Latitude: '51.123.351',
  Longitude: '6.795.945',
  Telephone: '02133-70550',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.123351,6.795945',
  id: '51.123351,6.795945'
}, {
  Brand: 'ARAL',
  Street: 'Blaubeurer Straße 21',
  PostCode: 89077,
  City: 'Ulm',
  Latitude: '484.016.220',
  Longitude: '99.755.660',
  Telephone: '7313988710',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.401622,9.975566',
  id: '48.401622,9.975566'
}, {
  Brand: 'ARAL',
  Street: 'Kölner Straße 112',
  PostCode: 57368,
  City: 'Lennestadt-Grevenbrü',
  Latitude: '511.429.290',
  Longitude: '80.178.140',
  Telephone: '2721718258',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.142929,8.017814',
  id: '51.142929,8.017814'
}, {
  Brand: 'TOTAL',
  Street: 'FRITZ-REUTER-STRASSE 21',
  PostCode: 9423,
  City: 'GELENAU',
  Latitude: '50.699.649',
  Longitude: '12.939.698',
  Telephone: '037297-5055',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.699649,12.939698',
  id: '50.699649,12.939698'
}, {
  Brand: 'TOTAL',
  Street: 'AEUSS.WOLKENSTEINER STR. 3',
  PostCode: 9496,
  City: 'MARIENBERG',
  Latitude: '50.649.699',
  Longitude: '13.157.792',
  Telephone: '03735-22485',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.649699,13.157792',
  id: '50.649699,13.157792'
}, {
  Brand: 'ARAL',
  Street: 'Amselstraße 50',
  PostCode: 75180,
  City: 'Pforzheim',
  Latitude: '488.617.900',
  Longitude: '86.619.480',
  Telephone: '723171240',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.86179,8.661948',
  id: '48.86179,8.661948'
}, {
  Brand: 'Eni',
  Street: 'Buecheloher Str. B 87',
  PostCode: 98693,
  City: 'Ilmenau',
  Latitude: '5.069.034.343',
  Longitude: '1.094.027.344',
  Telephone: '+49 (0) 36772049',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.69034343,10.94027344',
  id: '50.69034343,10.94027344'
}, {
  Brand: 'Westfalen',
  Street: 'Zur Obersburg 2',
  PostCode: null,
  City: 'AUETAL',
  Latitude: '52.231',
  Longitude: '923.689',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.231,9.23689',
  id: '52.231,9.23689'
}, {
  Brand: 'TOTAL',
  Street: 'CZERNYRING 1',
  PostCode: 69115,
  City: 'HEIDELBERG',
  Latitude: '49.407.414',
  Longitude: '8.672.251',
  Telephone: '06221-29888',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.407414,8.672251',
  id: '49.407414,8.672251'
}, {
  Brand: 'OMV',
  Street: 'Feucht-West A 9',
  PostCode: 90537,
  City: 'Feucht',
  Latitude: '4.936.051.000',
  Longitude: '1.120.201.000',
  Telephone: '+49-9128-920980',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.36051,11.20201',
  id: '49.36051,11.20201'
}, {
  Brand: 'Eni',
  Street: 'Roemerstr. 50',
  PostCode: 85055,
  City: 'Ingolstadt',
  Latitude: '4.877.641.000',
  Longitude: '1.145.548.000',
  Telephone: '+49 (0) 84195653',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.77641,11.45548',
  id: '48.77641,11.45548'
}, {
  Brand: 'OMV',
  Street: 'Bundesstr. 23',
  PostCode: 79787,
  City: 'Lauchringen',
  Latitude: '4.763.248.000',
  Longitude: '829.985.000',
  Telephone: '+49-7741-2632',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.63248,8.29985',
  id: '47.63248,8.29985'
}, {
  Brand: 'TOTAL',
  Street: '37 BERLINER STR.',
  PostCode: 44866,
  City: 'BOCHUM',
  Latitude: '51.478.273',
  Longitude: '712.671',
  Telephone: '02327-31229',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.478273,7.12671',
  id: '51.478273,7.12671'
}, {
  Brand: 'TOTAL',
  Street: 'REICHERSDORFER STR. 9',
  PostCode: 4651,
  City: 'BAD LAUSICK',
  Latitude: '51.140.672',
  Longitude: '1.265.012',
  Telephone: '034345-22864',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.140672,12.65012',
  id: '51.140672,12.65012'
}, {
  Brand: 'TOTAL',
  Street: '31 PEGNITZER STRASSE',
  PostCode: 91278,
  City: 'POTTENSTEIN',
  Latitude: '49.768.495',
  Longitude: '11.409.829',
  Telephone: '09243-312',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.768495,11.409829',
  id: '49.768495,11.409829'
}, {
  Brand: 'OMV',
  Street: 'Herbolzheimer Str. 1',
  PostCode: 77975,
  City: 'Ringsheim',
  Latitude: '4.825.021.000',
  Longitude: '776.631.000',
  Telephone: '+49-7822-4489941',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.25021,7.76631',
  id: '48.25021,7.76631'
}, {
  Brand: 'ARAL',
  Street: 'Kronprinzenstraße 23',
  PostCode: 57250,
  City: 'Netphen',
  Latitude: '509.152.350',
  Longitude: '80.961.350',
  Telephone: '27381263',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.915235,8.096135',
  id: '50.915235,8.096135'
}, {
  Brand: 'OMV',
  Street: 'Ausseestrasse 4',
  PostCode: 83543,
  City: 'Rott',
  Latitude: '4.797.870.000',
  Longitude: '1.213.904.000',
  Telephone: '+49-8039-409694',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.9787,12.13904',
  id: '47.9787,12.13904'
}, {
  Brand: 'Eni',
  Street: 'Kulmbacher Str. 71',
  PostCode: 7318,
  City: 'Saalfeld',
  Latitude: '5.064.889.306',
  Longitude: '1.137.350.954',
  Telephone: '+49 (0) 36715205',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.64889306,11.37350954',
  id: '50.64889306,11.37350954'
}, {
  Brand: 'Westfalen',
  Street: 'Gehrdener Str. 5',
  PostCode: null,
  City: 'GEHRDEN',
  Latitude: '523.229',
  Longitude: '955.289',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.3229,9.55289',
  id: '52.3229,9.55289'
}, {
  Brand: 'OMV',
  Street: 'Freisinger Landstr. 26',
  PostCode: 80939,
  City: 'München',
  Latitude: '4.819.113.000',
  Longitude: '1.162.036.000',
  Telephone: '+49-89-3226636',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.19113,11.62036',
  id: '48.19113,11.62036'
}, {
  Brand: 'OMV',
  Street: 'Donautal West A 3',
  PostCode: 94036,
  City: 'Passau',
  Latitude: '4.858.986.000',
  Longitude: '1.336.608.000',
  Telephone: '+49-851-83580',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.58986,13.36608',
  id: '48.58986,13.36608'
}, {
  Brand: 'Westfalen',
  Street: 'Farger Str. 16',
  PostCode: null,
  City: 'BREMEN',
  Latitude: '',
  Longitude: '853.232',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '0,8.53232',
  id: '0,8.53232'
}, {
  Brand: 'ARAL',
  Street: 'A 2',
  PostCode: 38350,
  City: 'Helmstedt',
  Latitude: '522.202.120',
  Longitude: '110.583.350',
  Telephone: '53512077',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.220212,11.058335',
  id: '52.220212,11.058335'
}, {
  Brand: 'ARAL',
  Street: 'Königskamp 4',
  PostCode: 52428,
  City: 'Jülich',
  Latitude: '509.122.760',
  Longitude: '63.787.800',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.912276,6.37878',
  id: '50.912276,6.37878'
}, {
  Brand: 'Westfalen',
  Street: 'Duelmener Str. 26',
  PostCode: null,
  City: 'COESFELD',
  Latitude: '519.374',
  Longitude: '716.822',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9374,7.16822',
  id: '51.9374,7.16822'
}, {
  Brand: 'TOTAL',
  Street: 'WILHELMSDORFER LANDSTR. 36',
  PostCode: 14776,
  City: 'BRANDENBURG',
  Latitude: '52.395.213',
  Longitude: '12.540.529',
  Telephone: '03381-795506',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.395213,12.540529',
  id: '52.395213,12.540529'
}, {
  Brand: 'TOTAL',
  Street: 'WANDHOFENER STR. 9',
  PostCode: 58099,
  City: 'HAGEN',
  Latitude: '51.406.552',
  Longitude: '7.478.107',
  Telephone: '02331-9235959',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.406552,7.478107',
  id: '51.406552,7.478107'
}, {
  Brand: 'Eni',
  Street: 'Marburger Strasse 30',
  PostCode: 35075,
  City: 'Gladenbach',
  Latitude: '5.077.166.000',
  Longitude: '860.434.000',
  Telephone: '+49 (0) 64629116',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.77166,8.60434',
  id: '50.77166,8.60434'
}, {
  Brand: 'TOTAL',
  Street: 'LANGE STR. 147',
  PostCode: 33397,
  City: 'RIETBERG',
  Latitude: '51.832.478',
  Longitude: '8.445.303',
  Telephone: '05244-2318',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.832478,8.445303',
  id: '51.832478,8.445303'
}, {
  Brand: 'TOTAL',
  Street: 'SCHWERTER STR. 161',
  PostCode: 58099,
  City: 'HAGEN-BOELE',
  Latitude: '51.398.479',
  Longitude: '7.472.529',
  Telephone: '02331-65197',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.398479,7.472529',
  id: '51.398479,7.472529'
}, {
  Brand: 'TOTAL',
  Street: 'BERLINER STR. 1',
  PostCode: 15926,
  City: 'LUCKAU',
  Latitude: '51.851.696',
  Longitude: '13.703.166',
  Telephone: '03544-2347',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.851696,13.703166',
  id: '51.851696,13.703166'
}, {
  Brand: 'TOTAL',
  Street: 'KOENIGSTR. 52',
  PostCode: 73326,
  City: 'DEGGINGEN',
  Latitude: '48.596.781',
  Longitude: '9.715.919',
  Telephone: '07334-5408',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.596781,9.715919',
  id: '48.596781,9.715919'
}, {
  Brand: 'TOTAL',
  Street: 'AN DER SCHMUECKE 10',
  PostCode: 6577,
  City: 'HELDRUNGEN',
  Latitude: '51.299.696',
  Longitude: '11.202.084',
  Telephone: '034673-779718',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.299696,11.202084',
  id: '51.299696,11.202084'
}, {
  Brand: 'TOTAL',
  Street: 'RINGSTR. 12',
  PostCode: 69115,
  City: 'HEIDELBERG',
  Latitude: '49.404.265',
  Longitude: '8.682.606',
  Telephone: '06221-23773',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.404265,8.682606',
  id: '49.404265,8.682606'
}, {
  Brand: 'ARAL',
  Street: 'Landsberger Straße 38a',
  PostCode: 86179,
  City: 'Augsburg',
  Latitude: '483.123.760',
  Longitude: '109.078.570',
  Telephone: '8218150158',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.312376,10.907857',
  id: '48.312376,10.907857'
}, {
  Brand: 'ARAL',
  Street: 'Rudolf-Diesel-Str. 13',
  PostCode: 56220,
  City: 'Urmitz',
  Latitude: '504.004.980',
  Longitude: '75.167.200',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.400498,7.51672',
  id: '50.400498,7.51672'
}, {
  Brand: 'Westfalen',
  Street: 'Cloppenburger 224',
  PostCode: null,
  City: 'OLDENBURG',
  Latitude: '531.176',
  Longitude: '821.453',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.1176,8.21453',
  id: '53.1176,8.21453'
}, {
  Brand: 'ARAL',
  Street: 'Otto-Brenner-Straße 8',
  PostCode: 21337,
  City: 'Lüneburg',
  Latitude: '532.487.910',
  Longitude: '104.741.550',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.248791,10.474155',
  id: '53.248791,10.474155'
}, {
  Brand: 'TOTAL',
  Street: 'KARLSTR. 44',
  PostCode: 44649,
  City: 'HERNE',
  Latitude: '51.537.115',
  Longitude: '7.149.212',
  Telephone: '02325-977166',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.537115,7.149212',
  id: '51.537115,7.149212'
}, {
  Brand: 'ARAL',
  Street: 'Jenaische Straße 36',
  PostCode: 7407,
  City: 'Rudolstadt',
  Latitude: '507.271.960',
  Longitude: '113.643.090',
  Telephone: '3672424471',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '05:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.727196,11.364309',
  id: '50.727196,11.364309'
}, {
  Brand: 'OMV',
  Street: 'Triebstr. 35 a',
  PostCode: 80993,
  City: 'München',
  Latitude: '4.818.719.000',
  Longitude: '1.153.530.000',
  Telephone: '+49-89-14322935',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.18719,11.5353',
  id: '48.18719,11.5353'
}, {
  Brand: 'Eni',
  Street: 'Blankenburger Heerstr. 1 A',
  PostCode: 38820,
  City: 'Halberstadt',
  Latitude: '5.188.519.000',
  Longitude: '1.103.109.000',
  Telephone: '+49 (0) 39412615',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.88519,11.03109',
  id: '51.88519,11.03109'
}, {
  Brand: 'TOTAL',
  Street: 'WORMSER LANDSTR. 255',
  PostCode: 67346,
  City: 'SPEYER',
  Latitude: '49.337.856',
  Longitude: '8.429.663',
  Telephone: '06232-34051',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.337856,8.429663',
  id: '49.337856,8.429663'
}, {
  Brand: 'TOTAL',
  Street: 'PLAUER CHAUSSEE 2',
  PostCode: 18273,
  City: 'GUESTROW',
  Latitude: '53.785.019',
  Longitude: '12.196.471',
  Telephone: '03843-82149',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.785019,12.196471',
  id: '53.785019,12.196471'
}, {
  Brand: 'Eni',
  Street: 'Friedrich-Bauer-Str. 31',
  PostCode: 73642,
  City: 'Welzheim',
  Latitude: '4.887.023.863',
  Longitude: '962.364.836',
  Telephone: '+49 (0) 71829293',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.87023863,9.62364836',
  id: '48.87023863,9.62364836'
}, {
  Brand: 'TOTAL',
  Street: 'NEUSTAEDTER STR. 162',
  PostCode: 7381,
  City: 'POESSNECK',
  Latitude: '50.701.001',
  Longitude: '11.614.169',
  Telephone: '03647-425370',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.701001,11.614169',
  id: '50.701001,11.614169'
}, {
  Brand: 'TOTAL',
  Street: 'FR.-EBERT-STR. 14-16',
  PostCode: 68167,
  City: 'MANNHEIM',
  Latitude: '49.497.207',
  Longitude: '8.488.092',
  Telephone: '0621-333545',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.497207,8.488092',
  id: '49.497207,8.488092'
}, {
  Brand: 'TOTAL',
  Street: 'KOLKWITZER STR. 66A',
  PostCode: 3046,
  City: 'COTTBUS',
  Latitude: '51.753.051',
  Longitude: '14.297.501',
  Telephone: '0355-31481',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.753051,14.297501',
  id: '51.753051,14.297501'
}, {
  Brand: 'Eni',
  Street: 'Donaustrasse 2',
  PostCode: 76437,
  City: 'Rastatt',
  Latitude: '4.883.870.000',
  Longitude: '820.140.000',
  Telephone: '+49 (0) 72223402',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.8387,8.2014',
  id: '48.8387,8.2014'
}, {
  Brand: 'TOTAL',
  Street: 'BAB A 620 SUEDSEITE',
  PostCode: 66740,
  City: 'SAARLOUIS',
  Latitude: '49.299.797',
  Longitude: '6.748.377',
  Telephone: '06831-2060',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.299797,6.748377',
  id: '49.299797,6.748377'
}, {
  Brand: 'ARAL',
  Street: 'Schomburger Straße 1',
  PostCode: 88279,
  City: 'Amtzell',
  Latitude: '476.953.720',
  Longitude: '97.589.860',
  Telephone: '75206126',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.695372,9.758986',
  id: '47.695372,9.758986'
}, {
  Brand: 'ARAL',
  Street: 'Stader Straße 30',
  PostCode: 27419,
  City: 'Sittensen',
  Latitude: '532.885.590',
  Longitude: '95.077.400',
  Telephone: '4282592874',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.288559,9.50774',
  id: '53.288559,9.50774'
}, {
  Brand: 'TOTAL',
  Street: 'BERLINER TOR 2',
  PostCode: 14959,
  City: 'TREBBIN',
  Latitude: '52.222.036',
  Longitude: '13.219.567',
  Telephone: '033731-15271',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.222036,13.219567',
  id: '52.222036,13.219567'
}, {
  Brand: 'ARAL',
  Street: 'A3',
  PostCode: 56307,
  City: 'Dernbach',
  Latitude: '505.479.780',
  Longitude: '75.852.300',
  Telephone: '2689979680',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.547978,7.58523',
  id: '50.547978,7.58523'
}, {
  Brand: 'ARAL',
  Street: 'Ferdinand-Porsche-Straße',
  PostCode: 21684,
  City: 'Stade',
  Latitude: '535.772.120',
  Longitude: '94.956.910',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.577212,9.495691',
  id: '53.577212,9.495691'
}, {
  Brand: 'TOTAL',
  Street: 'FLUGPLATZSTR. 26',
  PostCode: 55126,
  City: 'MAINZ-FINTHEN',
  Latitude: '4.998.318',
  Longitude: '8.170.831',
  Telephone: '06131-40805',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.98318,8.170831',
  id: '49.98318,8.170831'
}, {
  Brand: 'ARAL',
  Street: 'Lützner Straße 536',
  PostCode: 4205,
  City: 'Leipzig',
  Latitude: '513.173.360',
  Longitude: '122.706.070',
  Telephone: '3414225520',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.317336,12.270607',
  id: '51.317336,12.270607'
}, {
  Brand: 'TOTAL',
  Street: 'PASEWALKER ALLEE 95 A',
  PostCode: 17389,
  City: 'ANKLAM',
  Latitude: '53.836.485',
  Longitude: '13.715.871',
  Telephone: '03971-210189',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.836485,13.715871',
  id: '53.836485,13.715871'
}, {
  Brand: 'TOTAL',
  Street: 'RACKWITZER STRASSE 26',
  PostCode: 4347,
  City: 'LEIPZIG',
  Latitude: '51.354.444',
  Longitude: '12.395',
  Telephone: '0341-2345861',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.354444,12.395',
  id: '51.354444,12.395'
}, {
  Brand: 'TOTAL',
  Street: 'LUEBECKER STR. 283',
  PostCode: 19059,
  City: 'SCHWERIN',
  Latitude: '53.645.703',
  Longitude: '11.382.095',
  Telephone: '0385-4864112',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.645703,11.382095',
  id: '53.645703,11.382095'
}, {
  Brand: 'TOTAL',
  Street: 'POISENTALSTR. 18',
  PostCode: 1705,
  City: 'FREITAL',
  Latitude: '50.996.099',
  Longitude: '13.652.083',
  Telephone: '0351-6491861',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.996099,13.652083',
  id: '50.996099,13.652083'
}, {
  Brand: 'TOTAL',
  Street: 'BOTTROPER STR. 18-20',
  PostCode: 46244,
  City: 'BOTTROP',
  Latitude: '51.600.596',
  Longitude: '6.914.389',
  Telephone: '02045-2128',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.600596,6.914389',
  id: '51.600596,6.914389'
}, {
  Brand: 'Westfalen',
  Street: 'Huelsdonker Str. 117',
  PostCode: null,
  City: 'MOERS',
  Latitude: '51.451',
  Longitude: '660.871',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.451,6.60871',
  id: '51.451,6.60871'
}, {
  Brand: 'OMV',
  Street: 'Burghauser Str. 79',
  PostCode: 84503,
  City: 'Altötting',
  Latitude: '4.822.238.000',
  Longitude: '1.268.815.000',
  Telephone: '+49-8671-881466',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.22238,12.68815',
  id: '48.22238,12.68815'
}, {
  Brand: 'Eni',
  Street: 'Perger Strasse 2',
  PostCode: 84489,
  City: 'Burghausen',
  Latitude: '4.816.638.159',
  Longitude: '1.282.779.441',
  Telephone: '+49 (0) 86776516',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.16638159,12.82779441',
  id: '48.16638159,12.82779441'
}, {
  Brand: 'TOTAL',
  Street: 'DOERRENBACHER STR. 22',
  PostCode: 66564,
  City: 'OTTWEILER',
  Latitude: '49.423.994',
  Longitude: '7.236.086',
  Telephone: '06858-332',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.423994,7.236086',
  id: '49.423994,7.236086'
}, {
  Brand: 'ARAL',
  Street: 'A5',
  PostCode: 77972,
  City: 'Mahlberg / Baden',
  Latitude: '483.094.740',
  Longitude: '77.891.560',
  Telephone: '7825877611',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.309474,7.789156',
  id: '48.309474,7.789156'
}, {
  Brand: 'OMV',
  Street: 'Gewerbegebiet 10',
  PostCode: 83093,
  City: 'Bad Endorf',
  Latitude: '4.791.727.000',
  Longitude: '1.229.360.000',
  Telephone: '+49-8053-4224',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.91727,12.2936',
  id: '47.91727,12.2936'
}, {
  Brand: 'Eni',
  Street: 'Waldseer Str. 107',
  PostCode: 88400,
  City: 'Biberach',
  Latitude: '4.808.592.000',
  Longitude: '979.590.000',
  Telephone: '+49 (0) 73512108',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.08592,9.7959',
  id: '48.08592,9.7959'
}, {
  Brand: 'Westfalen',
  Street: 'Eckendorfer Str. 32',
  PostCode: null,
  City: 'BIELEFELD',
  Latitude: '520.333',
  Longitude: '855.744',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0333,8.55744',
  id: '52.0333,8.55744'
}, {
  Brand: 'Westfalen',
  Street: 'Werler Str. 238-240',
  PostCode: null,
  City: 'HAMM',
  Latitude: '516.628',
  Longitude: '783.413',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.6628,7.83413',
  id: '51.6628,7.83413'
}, {
  Brand: 'Westfalen',
  Street: 'Coesfelder Str. 91',
  PostCode: null,
  City: 'COESFELD',
  Latitude: '51.896',
  Longitude: '719.337',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.896,7.19337',
  id: '51.896,7.19337'
}, {
  Brand: 'Eni',
  Street: 'Helmstedt Sued / BAB A2',
  PostCode: 38350,
  City: 'Helmstedt',
  Latitude: '5.222.447.000',
  Longitude: '1.101.115.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '52.22447,11.01115',
  id: '52.22447,11.01115'
}, {
  Brand: 'Westfalen',
  Street: 'Kopenhagener Straße 19',
  PostCode: 48163,
  City: 'Münster',
  Latitude: '5.156.203',
  Longitude: '7.355.878',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.56203,7.355878',
  id: '51.56203,7.355878'
}, {
  Brand: 'OMV',
  Street: 'Brüdener Strasse 16',
  PostCode: 71554,
  City: 'Weissach',
  Latitude: '4.893.136.000',
  Longitude: '948.191.000',
  Telephone: '+49-7191-310610',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.93136,9.48191',
  id: '48.93136,9.48191'
}, {
  Brand: 'Eni',
  Street: 'Schnellstrasse West',
  PostCode: 55130,
  City: 'Mainz',
  Latitude: '5.000.437.200',
  Longitude: '826.258.800',
  Telephone: '+49 (0) 61318648',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.004372,8.262588',
  id: '50.004372,8.262588'
}, {
  Brand: 'Westfalen',
  Street: 'Puesselbuerener Damm 16',
  PostCode: null,
  City: 'IBBENBUEREN',
  Latitude: '522.817',
  Longitude: '770.657',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.2817,7.70657',
  id: '52.2817,7.70657'
}, {
  Brand: 'Westfalen',
  Street: 'Kölner Str. 124',
  PostCode: null,
  City: 'BERGNEUSTADT',
  Latitude: '510.171',
  Longitude: '763.931',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.0171,7.63931',
  id: '51.0171,7.63931'
}, {
  Brand: 'OMV',
  Street: 'Jura Ost A3',
  PostCode: 92355,
  City: 'Velburg',
  Latitude: '',
  Longitude: '',
  Telephone: '+49-9182-9313-0',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '0,0',
  id: '0,0'
}, {
  Brand: 'TOTAL',
  Street: 'HAUPTSTR.23',
  PostCode: 56767,
  City: 'UERSFELD',
  Latitude: '50.255.816',
  Longitude: '701.357',
  Telephone: '02657-247',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.255816,7.01357',
  id: '50.255816,7.01357'
}, {
  Brand: 'TOTAL',
  Street: 'LANGENAUER STR. 1',
  PostCode: 9618,
  City: 'BRAND-ERBISDORF',
  Latitude: '50.857.879',
  Longitude: '13.319.008',
  Telephone: '037322-2271',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.857879,13.319008',
  id: '50.857879,13.319008'
}, {
  Brand: 'TOTAL',
  Street: 'GROTHUSSTR. 4',
  PostCode: 45881,
  City: 'GELSENKIRCHEN',
  Latitude: '5.152.028',
  Longitude: '7.078.772',
  Telephone: '0209-498552',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.52028,7.078772',
  id: '51.52028,7.078772'
}, {
  Brand: 'TOTAL',
  Street: 'ALZEYER STR. 73',
  PostCode: 55543,
  City: 'BAD KREUZNACH',
  Latitude: '49.836.608',
  Longitude: '7.877.203',
  Telephone: '0671-68373',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.836608,7.877203',
  id: '49.836608,7.877203'
}, {
  Brand: 'ARAL',
  Street: 'Stettener Straße 112',
  PostCode: 73732,
  City: 'Esslingen-Wäldenbron',
  Latitude: '487.594.010',
  Longitude: '93.173.810',
  Telephone: '7119385399',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.759401,9.317381',
  id: '48.759401,9.317381'
}, {
  Brand: 'TOTAL',
  Street: 'BUCHAECKERRING 40',
  PostCode: 74906,
  City: 'BAD RAPPENAU',
  Latitude: '4.921.302',
  Longitude: '9.078.999',
  Telephone: '07066-9158824',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.21302,9.078999',
  id: '49.21302,9.078999'
}, {
  Brand: 'TOTAL',
  Street: 'BONNER STR. 417-425',
  PostCode: 50968,
  City: 'KOELN',
  Latitude: '50.900.906',
  Longitude: '6.965.748',
  Telephone: '0221-372137',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.900906,6.965748',
  id: '50.900906,6.965748'
}, {
  Brand: 'Eni',
  Street: 'An der A 3',
  PostCode: 97355,
  City: 'Kleinlangheim - Haidt',
  Latitude: '4.978.054.801',
  Longitude: '1.024.479.844',
  Telephone: '+49 (0) 9325200',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.78054801,10.24479844',
  id: '49.78054801,10.24479844'
}, {
  Brand: 'TOTAL',
  Street: 'HEERESSTR. 27',
  PostCode: 66822,
  City: 'LEBACH',
  Latitude: '49.413.102',
  Longitude: '6.894.295',
  Telephone: '06881-52861',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.413102,6.894295',
  id: '49.413102,6.894295'
}, {
  Brand: 'Westfalen',
  Street: 'Detmolder Straße 230',
  PostCode: 33175,
  City: 'Bad Lippspringe',
  Latitude: '51.789.614',
  Longitude: '8.826.766',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.789614,8.826766',
  id: '51.789614,8.826766'
}, {
  Brand: 'OMV',
  Street: 'Verdistr. 68',
  PostCode: 81247,
  City: 'München',
  Latitude: '4.816.490.000',
  Longitude: '1.147.190.000',
  Telephone: '+49-89-8112537',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.1649,11.4719',
  id: '48.1649,11.4719'
}, {
  Brand: 'TOTAL',
  Street: 'DORFGUETINGEN 43',
  PostCode: 91555,
  City: 'FEUCHTWANGEN',
  Latitude: '4.921.633',
  Longitude: '10.300.219',
  Telephone: '09852-768',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.21633,10.300219',
  id: '49.21633,10.300219'
}, {
  Brand: 'TOTAL',
  Street: 'ERLIGHEIMER STR. 20',
  PostCode: 74369,
  City: 'LOECHGAU',
  Latitude: '49.003.447',
  Longitude: '9.104.392',
  Telephone: '07143-88302-0',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.003447,9.104392',
  id: '49.003447,9.104392'
}, {
  Brand: 'TOTAL',
  Street: 'KASSELER STR. 49-51',
  PostCode: 37247,
  City: 'GROSSALMERODE',
  Latitude: '51.258.345',
  Longitude: '9.778.154',
  Telephone: '05604-6386',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.258345,9.778154',
  id: '51.258345,9.778154'
}, {
  Brand: 'TOTAL',
  Street: 'RODGAUSTRASSE 6',
  PostCode: 63457,
  City: 'HANAU',
  Latitude: '50.116.658',
  Longitude: '8.928.875',
  Telephone: '06181-399572',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.116658,8.928875',
  id: '50.116658,8.928875'
}, {
  Brand: 'ARAL',
  Street: 'Siegener Straße 58',
  PostCode: 57610,
  City: 'Altenkirchen',
  Latitude: '506.934.030',
  Longitude: '76.543.540',
  Telephone: '26815186',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:30',
  SaturdayTo: '19:00',
  SundayFrom: '09:30',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '50.693403,7.654354',
  id: '50.693403,7.654354'
}, {
  Brand: 'ARAL',
  Street: 'Zielstattstraße 10',
  PostCode: 81379,
  City: 'München',
  Latitude: '481.032.440',
  Longitude: '115.334.750',
  Telephone: '89788232',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '18:00',
  SundayFrom: '00:00',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.103244,11.533475',
  id: '48.103244,11.533475'
}, {
  Brand: 'TOTAL',
  Street: 'BAB BROHLTAL WEST/A 61',
  PostCode: 56651,
  City: 'NIEDERZISSEN',
  Latitude: '50.441.791',
  Longitude: '7.224.669',
  Telephone: '02636-970077',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.441791,7.224669',
  id: '50.441791,7.224669'
}, {
  Brand: 'TOTAL',
  Street: 'STOCKUMER STR. 200',
  PostCode: 44225,
  City: 'DORTMUND',
  Latitude: '5.148.183',
  Longitude: '7.436.307',
  Telephone: '0231-711950',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.48183,7.436307',
  id: '51.48183,7.436307'
}, {
  Brand: 'ARAL',
  Street: 'Friedberger Straße 47 1/4',
  PostCode: 86161,
  City: 'Augsburg',
  Latitude: '483.556.280',
  Longitude: '109.190.020',
  Telephone: '821551677',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.355628,10.919002',
  id: '48.355628,10.919002'
}, {
  Brand: 'Eni',
  Street: 'Hoefener Strasse 44',
  PostCode: 90763,
  City: 'Fuerth',
  Latitude: '4.944.896.500',
  Longitude: '1.100.043.000',
  Telephone: '+49 (0) 91170571',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.448965,11.00043',
  id: '49.448965,11.00043'
}, {
  Brand: 'ARAL',
  Street: 'Industriestraße 12',
  PostCode: 58840,
  City: 'Plettenberg',
  Latitude: '511.964.070',
  Longitude: '78.418.320',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.196407,7.841832',
  id: '51.196407,7.841832'
}, {
  Brand: 'TOTAL',
  Street: 'DRESDNER STR. 5',
  PostCode: 9456,
  City: 'ANNABERG-BUCHHOLZ',
  Latitude: '5.059.026',
  Longitude: '13.013.472',
  Telephone: '03733-53417',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.59026,13.013472',
  id: '50.59026,13.013472'
}, {
  Brand: 'TOTAL',
  Street: 'AM ISAAK 46',
  PostCode: 96515,
  City: 'SONNEBERG',
  Latitude: '50.365.715',
  Longitude: '11.143.276',
  Telephone: '03675-702932',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.365715,11.143276',
  id: '50.365715,11.143276'
}, {
  Brand: 'TOTAL',
  Street: 'LORSBACHER STR.14',
  PostCode: 65817,
  City: 'EPPSTEIN',
  Latitude: '50.135.286',
  Longitude: '8.400.434',
  Telephone: '06198-349178',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.135286,8.400434',
  id: '50.135286,8.400434'
}, {
  Brand: 'Eni',
  Street: 'Halbendorfer Weg 9',
  PostCode: 2943,
  City: 'Weisswasser',
  Latitude: '5.152.204.305',
  Longitude: '1.460.600.898',
  Telephone: '+49 (0) 35762053',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.52204305,14.60600898',
  id: '51.52204305,14.60600898'
}, {
  Brand: 'TOTAL',
  Street: 'AUTOHOF REIMERSHECK 3',
  PostCode: 56424,
  City: 'MOGENDORF',
  Latitude: '50.482.766',
  Longitude: '7.749.125',
  Telephone: '02623-9262132',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.482766,7.749125',
  id: '50.482766,7.749125'
}, {
  Brand: 'Westfalen',
  Street: 'Roemerstr. 28',
  PostCode: null,
  City: 'BAT LICHTENDORF NORD DORTMUND',
  Latitude: '514.688',
  Longitude: '759.318',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.4688,7.59318',
  id: '51.4688,7.59318'
}, {
  Brand: 'Eni',
  Street: 'Freiburger Str. 3',
  PostCode: 79189,
  City: 'Bad Krozingen',
  Latitude: '4.792.133.714',
  Longitude: '770.635.386',
  Telephone: '+49 (0) 76339088',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.92133714,7.70635386',
  id: '47.92133714,7.70635386'
}, {
  Brand: 'OMV',
  Street: 'Bahnhofstr. 6',
  PostCode: 92660,
  City: 'Neustadt',
  Latitude: '4.972.812.000',
  Longitude: '1.216.544.000',
  Telephone: '+49-9602-1496',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.72812,12.16544',
  id: '49.72812,12.16544'
}, {
  Brand: 'Eni',
  Street: 'Albert-Schweitzer-Str. 68',
  PostCode: 82008,
  City: 'Unterhaching',
  Latitude: '4.808.286.446',
  Longitude: '1.160.232.257',
  Telephone: '+49 (0) 89617016',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.08286446,11.60232257',
  id: '48.08286446,11.60232257'
}, {
  Brand: 'Eni',
  Street: 'Schmidstrasse 2',
  PostCode: 94234,
  City: 'Viechtach',
  Latitude: '4.907.863.000',
  Longitude: '1.287.956.000',
  Telephone: '+49 (0) 99429042',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.07863,12.87956',
  id: '49.07863,12.87956'
}, {
  Brand: 'Eni',
  Street: 'Witschelstr. 91',
  PostCode: 90431,
  City: 'Nuernberg',
  Latitude: '4.944.781.000',
  Longitude: '1.103.898.000',
  Telephone: '+49 (0) 91194062',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.44781,11.03898',
  id: '49.44781,11.03898'
}, {
  Brand: 'Westfalen',
  Street: 'Melanchthonstr. 40',
  PostCode: null,
  City: 'HANNOVER',
  Latitude: '523.982',
  Longitude: '973.002',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.3982,9.73002',
  id: '52.3982,9.73002'
}, {
  Brand: 'OMV',
  Street: 'Straubinger Str. 47',
  PostCode: 94333,
  City: 'Geiselhöring',
  Latitude: '4.882.951.000',
  Longitude: '1.240.066.000',
  Telephone: '+49-9423-903997',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.82951,12.40066',
  id: '48.82951,12.40066'
}, {
  Brand: 'OMV',
  Street: 'Am Zubringer 6',
  PostCode: 76437,
  City: 'Rastatt',
  Latitude: '4.885.980.000',
  Longitude: '825.470.000',
  Telephone: '+49-7222-985758',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.8598,8.2547',
  id: '48.8598,8.2547'
}, {
  Brand: 'Westfalen',
  Street: 'Elsa-Brandstroem-Str. 137',
  PostCode: null,
  City: 'BOCHUM',
  Latitude: '514.563',
  Longitude: '717.608',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.4563,7.17608',
  id: '51.4563,7.17608'
}, {
  Brand: 'Eni',
  Street: 'Dachauer Strasse 469',
  PostCode: 80993,
  City: 'Muenchen',
  Latitude: '4.820.245.671',
  Longitude: '1.149.069.687',
  Telephone: '+49 (0) 89148397',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.20245671,11.49069687',
  id: '48.20245671,11.49069687'
}, {
  Brand: 'ARAL',
  Street: 'Titiseestraße 26',
  PostCode: 79822,
  City: 'Titisee-Neustadt',
  Latitude: '479.193.620',
  Longitude: '82.050.260',
  Telephone: '76519361997',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.919362,8.205026',
  id: '47.919362,8.205026'
}, {
  Brand: 'ARAL',
  Street: 'Trochtelfinger Straße 38',
  PostCode: 72829,
  City: 'Engstingen',
  Latitude: '483.832.717',
  Longitude: '92.827.525',
  Telephone: '71299362420',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:30',
  SundayFrom: '08:30',
  SundayTo: '20:30',
  IsOpen24Hours: 'Nein',
  location: '48.3832717,9.2827525',
  id: '48.3832717,9.2827525'
}, {
  Brand: 'Westfalen',
  Street: 'Am Dreieck 16',
  PostCode: null,
  City: 'REICHSHOF',
  Latitude: '509.281',
  Longitude: '764.401',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.9281,7.64401',
  id: '50.9281,7.64401'
}, {
  Brand: 'TOTAL',
  Street: 'WESTRING 40',
  PostCode: 19370,
  City: 'PARCHIM',
  Latitude: '53.427.507',
  Longitude: '11.827.283',
  Telephone: '03871-441021',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.427507,11.827283',
  id: '53.427507,11.827283'
}, {
  Brand: 'TOTAL',
  Street: 'AUTOHOF EYTHSTRASSE 16 A',
  PostCode: 72189,
  City: 'VOEHRINGEN',
  Latitude: '48.343.383',
  Longitude: '8.663.085',
  Telephone: '07454-96040',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.343383,8.663085',
  id: '48.343383,8.663085'
}, {
  Brand: 'TOTAL',
  Street: 'AHRSTR. 24',
  PostCode: 53925,
  City: 'KALL',
  Latitude: '50.470.835',
  Longitude: '6.530.862',
  Telephone: '02447-95050',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.470835,6.530862',
  id: '50.470835,6.530862'
}, {
  Brand: 'TOTAL',
  Street: 'NORDHAEUSER STR. 19',
  PostCode: 38899,
  City: 'OBERHARZ AM BROCKEN',
  Latitude: '51.688.948',
  Longitude: '10.844.776',
  Telephone: '039459-71276',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.688948,10.844776',
  id: '51.688948,10.844776'
}, {
  Brand: 'ARAL',
  Street: 'Alzeyer Straße 71',
  PostCode: 67549,
  City: 'Worms',
  Latitude: '496.322.920',
  Longitude: '83.419.610',
  Telephone: '6241209415',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.632292,8.341961',
  id: '49.632292,8.341961'
}, {
  Brand: 'Westfalen',
  Street: 'Ammerländer Str. 8',
  PostCode: null,
  City: 'BARSSEL',
  Latitude: '531.692',
  Longitude: '774.629',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.1692,7.74629',
  id: '53.1692,7.74629'
}, {
  Brand: 'TOTAL',
  Street: 'ZIEROWER LANDSTRASSE 1',
  PostCode: 23968,
  City: 'WISMAR',
  Latitude: '53.902.733',
  Longitude: '11.404.688',
  Telephone: '03841-643704',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.902733,11.404688',
  id: '53.902733,11.404688'
}, {
  Brand: 'TOTAL',
  Street: 'DESSAUER STR.',
  PostCode: 6366,
  City: 'KOETHEN',
  Latitude: '51.765.038',
  Longitude: '11.987.581',
  Telephone: '03496-216047',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.765038,11.987581',
  id: '51.765038,11.987581'
}, {
  Brand: 'ARAL',
  Street: 'Knorrstr. 24',
  PostCode: 34134,
  City: 'Kassel',
  Latitude: '512.891.840',
  Longitude: '94.746.620',
  Telephone: '5619413797',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:30',
  SundayFrom: '00:00',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.289184,9.474662',
  id: '51.289184,9.474662'
}, {
  Brand: 'ARAL',
  Street: 'Sonnenallee 113',
  PostCode: 12045,
  City: 'Berlin',
  Latitude: '524.814.790',
  Longitude: '134.413.700',
  Telephone: '306811313',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.481479,13.44137',
  id: '52.481479,13.44137'
}, {
  Brand: 'TOTAL',
  Street: 'DAHLENER STR. 16',
  PostCode: 39576,
  City: 'STENDAL',
  Latitude: '52.586.291',
  Longitude: '11.838.674',
  Telephone: '03931-414079',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.586291,11.838674',
  id: '52.586291,11.838674'
}, {
  Brand: 'Avanti',
  Street: 'Münchner Str. 30',
  PostCode: 82256,
  City: 'Fürstenfeldbruck',
  Latitude: '4.817.659.000',
  Longitude: '1.125.996.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.17659,11.25996',
  id: '48.17659,11.25996'
}, {
  Brand: 'TOTAL',
  Street: 'GERICKESTR. 108',
  PostCode: 39340,
  City: 'HALDENSLEBEN',
  Latitude: '522.805',
  Longitude: '1.143.137',
  Telephone: '03904-65040',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.2805,11.43137',
  id: '52.2805,11.43137'
}, {
  Brand: 'TOTAL',
  Street: 'AN DER A 3',
  PostCode: 93164,
  City: 'BRUNN',
  Latitude: '49.091.008',
  Longitude: '11.888.473',
  Telephone: '09498/9077889',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.091008,11.888473',
  id: '49.091008,11.888473'
}, {
  Brand: 'TOTAL',
  Street: 'OSTSTR. 5',
  PostCode: 42651,
  City: 'SOLINGEN',
  Latitude: '51.171.938',
  Longitude: '7.089.516',
  Telephone: '0212-202894',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.171938,7.089516',
  id: '51.171938,7.089516'
}, {
  Brand: 'TOTAL',
  Street: 'BENSBERGER STR. 240',
  PostCode: 51469,
  City: 'BERGISCH GLADBACH',
  Latitude: '50.975.315',
  Longitude: '7.140.252',
  Telephone: '02202-34120',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.975315,7.140252',
  id: '50.975315,7.140252'
}, {
  Brand: 'TOTAL',
  Street: 'NORDHAEUSER STR.9',
  PostCode: 99752,
  City: 'BLEICHERODE',
  Latitude: '51.447.369',
  Longitude: '10.584.102',
  Telephone: '036338-43505',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.447369,10.584102',
  id: '51.447369,10.584102'
}, {
  Brand: 'TOTAL',
  Street: 'COBURGER STR. 42',
  PostCode: 96476,
  City: 'BAD RODACH',
  Latitude: '50.340.853',
  Longitude: '10.784.046',
  Telephone: '09564-4833',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.340853,10.784046',
  id: '50.340853,10.784046'
}, {
  Brand: 'Westfalen',
  Street: 'Edertalstrasse 6',
  PostCode: null,
  City: 'EDERTAL',
  Latitude: '511.435',
  Longitude: '917.621',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.1435,9.17621',
  id: '51.1435,9.17621'
}, {
  Brand: 'TOTAL',
  Street: 'AN DER LEIBI 1',
  PostCode: 89278,
  City: 'NERSINGEN',
  Latitude: '4.841.636',
  Longitude: '10.100.961',
  Telephone: '07308-9240280',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.41636,10.100961',
  id: '48.41636,10.100961'
}, {
  Brand: 'Eni',
  Street: 'Reichenhaller Str. 24',
  PostCode: 83334,
  City: 'Inzell',
  Latitude: '4.776.111.933',
  Longitude: '1.274.836.932',
  Telephone: '+49 (0) 86659293',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.76111933,12.74836932',
  id: '47.76111933,12.74836932'
}, {
  Brand: 'Eni',
  Street: 'Muenchner Strasse 40',
  PostCode: 83043,
  City: 'Bad Aibling',
  Latitude: '4.787.268.102',
  Longitude: '1.198.067.547',
  Telephone: '+49 (0) 80618508',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.87268102,11.98067547',
  id: '47.87268102,11.98067547'
}, {
  Brand: 'Eni',
  Street: 'Ignaz-Schoen-Str. 1',
  PostCode: 97421,
  City: 'Schweinfurt',
  Latitude: '5.004.491.000',
  Longitude: '1.021.785.000',
  Telephone: '+49 (0) 97218232',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.04491,10.21785',
  id: '50.04491,10.21785'
}, {
  Brand: 'Eni',
  Street: 'Kleinenztalstrasse 39',
  PostCode: 75323,
  City: 'Bad Wildbad-Calmbach',
  Latitude: '4.877.125.543',
  Longitude: '858.378.518',
  Telephone: '+49 (0) 70817554',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.77125543,8.58378518',
  id: '48.77125543,8.58378518'
}, {
  Brand: 'Eni',
  Street: 'Nuernberger Strasse 2A',
  PostCode: 92224,
  City: 'Amberg',
  Latitude: '4.944.568.314',
  Longitude: '1.183.479.183',
  Telephone: '+49 (0) 96212247',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.44568314,11.83479183',
  id: '49.44568314,11.83479183'
}, {
  Brand: 'OMV',
  Street: 'Ingolstädter Str. 28',
  PostCode: 85080,
  City: 'Gaimersheim',
  Latitude: '4.880.202.000',
  Longitude: '1.136.987.000',
  Telephone: '+49-8458-5474',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.80202,11.36987',
  id: '48.80202,11.36987'
}, {
  Brand: 'OMV',
  Street: 'Donauwörther Str. 197',
  PostCode: 86154,
  City: 'Augsburg',
  Latitude: '4.839.747.000',
  Longitude: '1.088.137.000',
  Telephone: '+49-821-414815',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.39747,10.88137',
  id: '48.39747,10.88137'
}, {
  Brand: 'TOTAL',
  Street: 'TUNIBERGSTR. 2',
  PostCode: 79189,
  City: 'BAD KROZINGEN-HAUSEN',
  Latitude: '47.955.205',
  Longitude: '7.671.721',
  Telephone: '07633-14592',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.955205,7.671721',
  id: '47.955205,7.671721'
}, {
  Brand: 'ARAL',
  Street: 'Am Lachbach 1',
  PostCode: 63546,
  City: 'Hammersbach',
  Latitude: '502.430.660',
  Longitude: '89.979.570',
  Telephone: '6185893027',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.243066,8.997957',
  id: '50.243066,8.997957'
}, {
  Brand: 'TOTAL',
  Street: 'BERGSTR. 34',
  PostCode: 67067,
  City: 'LUDWIGSHAFEN-MAUDACH',
  Latitude: '49.453.446',
  Longitude: '8.375.457',
  Telephone: '0621-553424',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.453446,8.375457',
  id: '49.453446,8.375457'
}, {
  Brand: 'TOTAL',
  Street: 'BAB SUED / A10',
  PostCode: 14552,
  City: 'MICHENDORF',
  Latitude: '52.302.624',
  Longitude: '13.014.763',
  Telephone: '033205-46699',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.302624,13.014763',
  id: '52.302624,13.014763'
}, {
  Brand: 'Avanti',
  Street: 'Hofheimer Str. 17',
  PostCode: 65795,
  City: 'Hattersheim',
  Latitude: '5.007.071.000',
  Longitude: '848.125.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.07071,8.48125',
  id: '50.07071,8.48125'
}, {
  Brand: 'TOTAL',
  Street: 'CELLER STRASSE 65',
  PostCode: 38114,
  City: 'BRAUNSCHWEIG',
  Latitude: '52.279.604',
  Longitude: '10.505.423',
  Telephone: '53121931707',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.279604,10.505423',
  id: '52.279604,10.505423'
}, {
  Brand: 'OMV',
  Street: 'Autobahn Süd A3',
  PostCode: 91074,
  City: 'Herzogenaurach',
  Latitude: '4.958.264.000',
  Longitude: '1.092.685.000',
  Telephone: '+49-9131-41595',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.58264,10.92685',
  id: '49.58264,10.92685'
}, {
  Brand: 'ARAL',
  Street: 'A38, Kirchworbiser Wiesen 2',
  PostCode: 37339,
  City: 'Kirchworbis',
  Latitude: '104.146.470',
  Longitude: '514.027.860',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '10.414647,5.1402786',
  id: '10.414647,5.1402786'
}, {
  Brand: 'TOTAL',
  Street: 'FRIEDRICH-ECKARDT-STRASSE 2',
  PostCode: 8529,
  City: 'PLAUEN',
  Latitude: '50.508.046',
  Longitude: '12.158.484',
  Telephone: '03741-442003',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.508046,12.158484',
  id: '50.508046,12.158484'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 57',
  PostCode: 52388,
  City: 'Nörvenich',
  Latitude: '508.097.330',
  Longitude: '66.366.350',
  Telephone: '2426901818',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.809733,6.636635',
  id: '50.809733,6.636635'
}, {
  Brand: 'TOTAL',
  Street: 'BGM.-PUETZ-STRASSE 8',
  PostCode: 47137,
  City: 'DUISBURG',
  Latitude: '51.463.548',
  Longitude: '6.773.462',
  Telephone: '0203-446384',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.463548,6.773462',
  id: '51.463548,6.773462'
}, {
  Brand: 'TOTAL',
  Street: 'BAB WESTSEITE / A 61',
  PostCode: 67125,
  City: 'DANNSTADT-SCHAUERNHEIM',
  Latitude: '494.103',
  Longitude: '8.339.999',
  Telephone: '06231-2542',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.4103,8.339999',
  id: '49.4103,8.339999'
}, {
  Brand: 'TOTAL',
  Street: 'INDUSTRIESTR. 19',
  PostCode: 64546,
  City: 'MOERFELDEN-WALLDORF',
  Latitude: '49.983.596',
  Longitude: '8.579.311',
  Telephone: '06105-21069',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.983596,8.579311',
  id: '49.983596,8.579311'
}, {
  Brand: 'ARAL',
  Street: 'Göteborger Straße 12 a',
  PostCode: 6686,
  City: 'Lützen',
  Latitude: '512.517.340',
  Longitude: '121.378.860',
  Telephone: '3444420030',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.251734,12.137886',
  id: '51.251734,12.137886'
}, {
  Brand: 'OMV',
  Street: 'Hauptstr. 215',
  PostCode: 76297,
  City: 'Stutensee',
  Latitude: '4.905.888.000',
  Longitude: '846.842.000',
  Telephone: '+49-7244-91994',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.05888,8.46842',
  id: '49.05888,8.46842'
}, {
  Brand: 'Westfalen',
  Street: 'Soester Str. 43',
  PostCode: null,
  City: 'WERL',
  Latitude: '515.496',
  Longitude: '792.261',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.5496,7.92261',
  id: '51.5496,7.92261'
}, {
  Brand: 'Eni',
  Street: 'Frankenhoehe Nord',
  PostCode: 91589,
  City: 'Aurach',
  Latitude: '4.924.234.000',
  Longitude: '1.035.762.000',
  Telephone: '+49 (0) 98049191',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.24234,10.35762',
  id: '49.24234,10.35762'
}, {
  Brand: 'OMV',
  Street: 'Dieselstr. 11',
  PostCode: 73072,
  City: 'Donzdorf',
  Latitude: '4.868.615.000',
  Longitude: '979.036.000',
  Telephone: '+49-7162-9413430',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.68615,9.79036',
  id: '48.68615,9.79036'
}, {
  Brand: 'ARAL',
  Street: 'Im Schiffels 1',
  PostCode: 55491,
  City: 'Büchenbeuren',
  Latitude: '499.272.600',
  Longitude: '72.795.840',
  Telephone: '65435005438',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.92726,7.279584',
  id: '49.92726,7.279584'
}, {
  Brand: 'Eni',
  Street: 'Salierstrasse 62',
  PostCode: 67105,
  City: 'Schifferstadt',
  Latitude: '4.938.307.000',
  Longitude: '838.841.000',
  Telephone: '+49 (0) 62359886',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.38307,8.38841',
  id: '49.38307,8.38841'
}, {
  Brand: 'TOTAL',
  Street: 'LENGGRIESER STR. 87',
  PostCode: 83646,
  City: 'BAD TOELZ',
  Latitude: '47.747.856',
  Longitude: '11.565.409',
  Telephone: '08041-8445',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.747856,11.565409',
  id: '47.747856,11.565409'
}, {
  Brand: 'TOTAL',
  Street: '5 ALUMINIUMSTR.',
  PostCode: 21129,
  City: 'HAMBURG',
  Latitude: '53.517.282',
  Longitude: '989.025',
  Telephone: '040-30239835',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.517282,9.89025',
  id: '53.517282,9.89025'
}, {
  Brand: 'ARAL',
  Street: 'Donauwörther Straße 62',
  PostCode: 86633,
  City: 'Neuburg',
  Latitude: '487.335.580',
  Longitude: '111.671.110',
  Telephone: '84312790',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.733558,11.167111',
  id: '48.733558,11.167111'
}, {
  Brand: 'ARAL',
  Street: 'A4',
  PostCode: 51491,
  City: 'Overath',
  Latitude: '509.505.600',
  Longitude: '73.196.410',
  Telephone: '220695470',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.95056,7.319641',
  id: '50.95056,7.319641'
}, {
  Brand: 'Westfalen',
  Street: 'Kamper Str. 11',
  PostCode: null,
  City: 'SOLINGEN',
  Latitude: '511.586',
  Longitude: '700.536',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.1586,7.00536',
  id: '51.1586,7.00536'
}, {
  Brand: 'ARAL',
  Street: 'Europaallee 16-18',
  PostCode: 50226,
  City: 'Frechen',
  Latitude: '509.207.620',
  Longitude: '68.334.480',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.920762,6.833448',
  id: '50.920762,6.833448'
}, {
  Brand: 'TOTAL',
  Street: 'SCHUETZENSTR.18',
  PostCode: 58239,
  City: 'SCHWERTE',
  Latitude: '51.444.399',
  Longitude: '7.577.083',
  Telephone: '02304-13152',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.444399,7.577083',
  id: '51.444399,7.577083'
}, {
  Brand: 'ARAL',
  Street: 'Stockholmer Str. 17',
  PostCode: 67346,
  City: 'Speyer',
  Latitude: '492.938.140',
  Longitude: '84.772.640',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.293814,8.477264',
  id: '49.293814,8.477264'
}, {
  Brand: 'TOTAL',
  Street: 'BASLER STR. 57',
  PostCode: 79100,
  City: 'FREIBURG',
  Latitude: '47.987.067',
  Longitude: '7.838.608',
  Telephone: '761402902',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.987067,7.838608',
  id: '47.987067,7.838608'
}, {
  Brand: 'TOTAL',
  Street: 'AUTOHOF STRAENGENBACH 1',
  PostCode: 59510,
  City: 'LIPPETAL',
  Latitude: '51.695.657',
  Longitude: '7.967.105',
  Telephone: '02388-307400',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.695657,7.967105',
  id: '51.695657,7.967105'
}, {
  Brand: 'Westfalen',
  Street: 'Karl-Benz-Str. 2',
  PostCode: null,
  City: 'STEINFURT',
  Latitude: '521.597',
  Longitude: '731.872',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.1597,7.31872',
  id: '52.1597,7.31872'
}, {
  Brand: 'Eni',
  Street: 'Allersberger Str. 81',
  PostCode: 91154,
  City: 'Roth',
  Latitude: '4.924.674.000',
  Longitude: '1.110.639.000',
  Telephone: '+49 (0) 91716264',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.24674,11.10639',
  id: '49.24674,11.10639'
}, {
  Brand: 'Eni',
  Street: 'Unterislinger Weg 13',
  PostCode: 93053,
  City: 'Regensburg',
  Latitude: '4.899.972.000',
  Longitude: '1.211.096.000',
  Telephone: '+49 (0) 94172181',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.99972,12.11096',
  id: '48.99972,12.11096'
}, {
  Brand: 'Eni',
  Street: 'Mindelheimer Str. 78',
  PostCode: 87600,
  City: 'Kaufbeuren',
  Latitude: '4.790.606.284',
  Longitude: '1.060.847.739',
  Telephone: '+49 (0) 83411222',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.90606284,10.60847739',
  id: '47.90606284,10.60847739'
}, {
  Brand: 'Eni',
  Street: 'Eberstaedter Strasse 115',
  PostCode: 64319,
  City: 'Pfungstadt',
  Latitude: '4.980.436.000',
  Longitude: '860.201.000',
  Telephone: '+49 (0) 61578656',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.80436,8.60201',
  id: '49.80436,8.60201'
}, {
  Brand: 'Eni',
  Street: 'Magdeburger Str. 262',
  PostCode: 39218,
  City: 'Schoenebeck',
  Latitude: '5.203.420.000',
  Longitude: '1.170.533.000',
  Telephone: '+49 (0) 39288435',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '52.0342,11.70533',
  id: '52.0342,11.70533'
}, {
  Brand: 'TOTAL',
  Street: 'ZURMAIENERSTRASSE 155-157',
  PostCode: 54292,
  City: 'TRIER',
  Latitude: '49.771.696',
  Longitude: '6.657.853',
  Telephone: '6514368353',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.771696,6.657853',
  id: '49.771696,6.657853'
}, {
  Brand: 'Westfalen',
  Street: 'Danebrocker Str. 10',
  PostCode: null,
  City: 'WESTERKAPPELN S',
  Latitude: '52.260.647',
  Longitude: '7.849.282',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.260647,7.849282',
  id: '52.260647,7.849282'
}, {
  Brand: 'Westfalen',
  Street: 'Lingener Str. 106',
  PostCode: null,
  City: 'NORDHORN',
  Latitude: '524.406',
  Longitude: '708.632',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.4406,7.08632',
  id: '52.4406,7.08632'
}, {
  Brand: 'Eni',
  Street: 'Bergstrae 39',
  PostCode: 37339,
  City: 'Teistungen',
  Latitude: '5.146.998.000',
  Longitude: '1.026.971.000',
  Telephone: '+49 (0) 36071872',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.46998,10.26971',
  id: '51.46998,10.26971'
}, {
  Brand: 'Westfalen',
  Street: 'Dortmunder Str. 239',
  PostCode: null,
  City: 'RECKLINGHAUSEN',
  Latitude: '516.225',
  Longitude: '7.2291',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.6225,7.2291',
  id: '51.6225,7.2291'
}, {
  Brand: 'OMV',
  Street: 'Illesheimer Str. 3',
  PostCode: 91438,
  City: 'Bad Windsheim',
  Latitude: '4.949.774.000',
  Longitude: '1.040.906.000',
  Telephone: '+49-9841-6601082',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.49774,10.40906',
  id: '49.49774,10.40906'
}, {
  Brand: 'ARAL',
  Street: 'An der B 7',
  PostCode: 7580,
  City: 'Ronneburg',
  Latitude: '508.687.250',
  Longitude: '122.161.670',
  Telephone: '36602519387',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.868725,12.216167',
  id: '50.868725,12.216167'
}, {
  Brand: 'OMV',
  Street: 'Gadastraße 1',
  PostCode: 85232,
  City: 'Bergkirchen',
  Latitude: '4.823.738.000',
  Longitude: '1.135.283.000',
  Telephone: '+49-8142-2842072',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.23738,11.35283',
  id: '48.23738,11.35283'
}, {
  Brand: 'ARAL',
  Street: 'Hirschorner Straße 41',
  PostCode: 69239,
  City: 'Neckarsteinach',
  Latitude: '493.990.820',
  Longitude: '88.455.760',
  Telephone: '6229930635',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '49.399082,8.845576',
  id: '49.399082,8.845576'
}, {
  Brand: 'Eni',
  Street: 'Lindauer Str. 17',
  PostCode: 87509,
  City: 'Immenstadt',
  Latitude: '4.756.686.000',
  Longitude: '1.019.303.000',
  Telephone: '+49 (0) 83231855',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.56686,10.19303',
  id: '47.56686,10.19303'
}, {
  Brand: 'OMV',
  Street: 'Bamberger Str. 31',
  PostCode: 96129,
  City: 'Strullendorf',
  Latitude: '4.984.706.000',
  Longitude: '1.096.444.000',
  Telephone: '+49-9543-258',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.84706,10.96444',
  id: '49.84706,10.96444'
}, {
  Brand: 'Westfalen',
  Street: 'Stieghorster Str. 108',
  PostCode: null,
  City: 'BIELEFELD',
  Latitude: '520.073',
  Longitude: '858.954',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0073,8.58954',
  id: '52.0073,8.58954'
}, {
  Brand: 'OMV',
  Street: 'Haller Str. 15',
  PostCode: 73479,
  City: 'Ellwangen',
  Latitude: '4.896.503.000',
  Longitude: '1.012.732.000',
  Telephone: '+49-7961-562927',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.96503,10.12732',
  id: '48.96503,10.12732'
}, {
  Brand: 'ARAL',
  Street: 'Am Flurgraben 1-11',
  PostCode: 65462,
  City: 'Ginsheim-Gustavsburg',
  Latitude: '499.875.330',
  Longitude: '83.277.490',
  Telephone: '61342853500',
  WeekDayFrom: '04:30',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '49.987533,8.327749',
  id: '49.987533,8.327749'
}, {
  Brand: 'TOTAL',
  Street: '119 MARKSTR.',
  PostCode: 44803,
  City: 'BOCHUM',
  Latitude: '51.455.461',
  Longitude: '7.250.342',
  Telephone: '0234-38619',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.455461,7.250342',
  id: '51.455461,7.250342'
}, {
  Brand: 'TOTAL',
  Street: 'KIRCHSTR. 23',
  PostCode: 40699,
  City: 'ERKRATH',
  Latitude: '51.223.767',
  Longitude: '6.916.481',
  Telephone: '0211-9003030',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.223767,6.916481',
  id: '51.223767,6.916481'
}, {
  Brand: 'TOTAL',
  Street: '91 EICHBORNDAMM',
  PostCode: 13403,
  City: 'BERLIN',
  Latitude: '52.576.415',
  Longitude: '13.314.502',
  Telephone: '030-41703023',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.576415,13.314502',
  id: '52.576415,13.314502'
}, {
  Brand: 'TOTAL',
  Street: 'PARKSTR. 90 A',
  PostCode: 19322,
  City: 'WITTENBERGE',
  Latitude: '53.001.296',
  Longitude: '11.746.876',
  Telephone: '03877-402317',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.001296,11.746876',
  id: '53.001296,11.746876'
}, {
  Brand: 'ARAL',
  Street: 'Bräukerweg 132',
  PostCode: 58708,
  City: 'Menden',
  Latitude: '514.367.930',
  Longitude: '77.580.820',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.436793,7.758082',
  id: '51.436793,7.758082'
}, {
  Brand: 'TOTAL',
  Street: 'AM ANGER',
  PostCode: 38855,
  City: 'WERNIGERODE',
  Latitude: '5.183.787',
  Longitude: '10.794.663',
  Telephone: '03943-23094',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.83787,10.794663',
  id: '51.83787,10.794663'
}, {
  Brand: 'TOTAL',
  Street: 'INN.ZWICKAUER STR.29',
  PostCode: 8062,
  City: 'ZWICKAU',
  Latitude: '50.694.747',
  Longitude: '12.479.166',
  Telephone: '0375-787047',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.694747,12.479166',
  id: '50.694747,12.479166'
}, {
  Brand: 'TOTAL',
  Street: 'GOTTENHEIMER STR.16',
  PostCode: 79268,
  City: 'BOETZINGEN',
  Latitude: '48.070.138',
  Longitude: '7.720.078',
  Telephone: '76639427420',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.070138,7.720078',
  id: '48.070138,7.720078'
}, {
  Brand: 'TOTAL',
  Street: 'WUERZBURGER STR. 26',
  PostCode: 1187,
  City: 'DRESDEN',
  Latitude: '51.032.199',
  Longitude: '13.710.689',
  Telephone: '0351-4717777',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.032199,13.710689',
  id: '51.032199,13.710689'
}, {
  Brand: 'TOTAL',
  Street: 'ALTENLINDE 1',
  PostCode: 51789,
  City: 'LINDLAR',
  Latitude: '51.023.488',
  Longitude: '7.370.519',
  Telephone: '02266-5556',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.023488,7.370519',
  id: '51.023488,7.370519'
}, {
  Brand: 'TOTAL',
  Street: 'LEIBNIZSTR. 74',
  PostCode: 7548,
  City: 'GERA',
  Latitude: '50.891.055',
  Longitude: '12.068.623',
  Telephone: '0365-8321474',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.891055,12.068623',
  id: '50.891055,12.068623'
}, {
  Brand: 'TOTAL',
  Street: 'KEMBERGER STR. 9',
  PostCode: 6905,
  City: 'BAD SCHMIEDEBERG',
  Latitude: '51.693.899',
  Longitude: '12.737.083',
  Telephone: '034925-71176',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.693899,12.737083',
  id: '51.693899,12.737083'
}, {
  Brand: 'Westfalen',
  Street: 'Warendorfer Str. 12',
  PostCode: null,
  City: 'BEELEN',
  Latitude: '519.297',
  Longitude: '812.068',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9297,8.12068',
  id: '51.9297,8.12068'
}, {
  Brand: 'ARAL',
  Street: 'Göttinger Straße 45',
  PostCode: 37120,
  City: 'Bovenden',
  Latitude: '515.865.680',
  Longitude: '99.265.900',
  Telephone: '5518768',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.586568,9.92659',
  id: '51.586568,9.92659'
}, {
  Brand: 'ARAL',
  Street: 'Stettiner Chaussee',
  PostCode: 17309,
  City: 'Pasewalk',
  Latitude: '535.033.410',
  Longitude: '140.003.960',
  Telephone: '3973214214',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.503341,14.000396',
  id: '53.503341,14.000396'
}, {
  Brand: 'TOTAL',
  Street: 'OLCHINGER STR. 82',
  PostCode: 82194,
  City: 'GROEBENZELL',
  Latitude: '48.186.099',
  Longitude: '11.384.181',
  Telephone: '08142-58627',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.186099,11.384181',
  id: '48.186099,11.384181'
}, {
  Brand: 'Eni',
  Street: 'Regensburger Strasse 68/A',
  PostCode: 92224,
  City: 'Amberg',
  Latitude: '4.943.973.000',
  Longitude: '1.187.710.000',
  Telephone: '+49 (0) 96217814',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.43973,11.8771',
  id: '49.43973,11.8771'
}, {
  Brand: 'TOTAL',
  Street: 'SEBASTIANSTR. 28',
  PostCode: 91058,
  City: 'ERLANGEN',
  Latitude: '49.545.202',
  Longitude: '11.029.889',
  Telephone: '09131/602151',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.545202,11.029889',
  id: '49.545202,11.029889'
}, {
  Brand: 'TOTAL',
  Street: 'GLETZOWER STR. 62 B',
  PostCode: 19217,
  City: 'REHNA',
  Latitude: '53.785.438',
  Longitude: '1.104.411',
  Telephone: '038872-53341',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.785438,11.04411',
  id: '53.785438,11.04411'
}, {
  Brand: 'TOTAL',
  Street: 'BOEBLINGER STR. 6',
  PostCode: 71116,
  City: 'GAERTRINGEN',
  Latitude: '48.638.912',
  Longitude: '8.909.811',
  Telephone: '07034-285065',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.638912,8.909811',
  id: '48.638912,8.909811'
}, {
  Brand: 'Eni',
  Street: 'Dieburger Strasse 117',
  PostCode: 63322,
  City: 'Roedermark-Oberroden',
  Latitude: '4.997.824.548',
  Longitude: '876.175.000',
  Telephone: '+49 (0) 60748816',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.97824548,8.76175',
  id: '49.97824548,8.76175'
}, {
  Brand: 'TOTAL',
  Street: 'KLOETZER STR. 12a',
  PostCode: 1587,
  City: 'RIESA',
  Latitude: '5.130.536',
  Longitude: '13.297.558',
  Telephone: '03525/7770310',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.30536,13.297558',
  id: '51.30536,13.297558'
}, {
  Brand: 'TOTAL',
  Street: 'PROSPERSTR. 440',
  PostCode: 46238,
  City: 'BOTTROP',
  Latitude: '51.522.289',
  Longitude: '6.983.473',
  Telephone: '02041-7763253',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.522289,6.983473',
  id: '51.522289,6.983473'
}, {
  Brand: 'ARAL',
  Street: 'A7',
  PostCode: 37124,
  City: 'Rosdorf',
  Latitude: '514.893.340',
  Longitude: '98.776.290',
  Telephone: '550992500',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.489334,9.877629',
  id: '51.489334,9.877629'
}, {
  Brand: 'TOTAL',
  Street: 'LORCHER STR. 199',
  PostCode: 73529,
  City: 'SCHWAEBISCH-GMUEND',
  Latitude: '48.788.633',
  Longitude: '9.763.438',
  Telephone: '07171-9998895',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.788633,9.763438',
  id: '48.788633,9.763438'
}, {
  Brand: 'TOTAL',
  Street: 'LIECKER STR. 62',
  PostCode: 52525,
  City: 'HEINSBERG',
  Latitude: '51.066.445',
  Longitude: '6.088.018',
  Telephone: '02452-155476',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.066445,6.088018',
  id: '51.066445,6.088018'
}, {
  Brand: 'Eni',
  Street: 'Nicola-Tesla-Strae',
  PostCode: 77972,
  City: 'Mahlberg',
  Latitude: '4.827.536.233',
  Longitude: '778.465.699',
  Telephone: '+49 (0) 78224405',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.27536233,7.78465699',
  id: '48.27536233,7.78465699'
}, {
  Brand: 'TOTAL',
  Street: 'BANTER WEG 121',
  PostCode: 26389,
  City: 'WILHELMSHAVEN',
  Latitude: '53.522.372',
  Longitude: '8.091.551',
  Telephone: '04421-9644884',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.522372,8.091551',
  id: '53.522372,8.091551'
}, {
  Brand: 'TOTAL',
  Street: '2 KAPPEL',
  PostCode: 95473,
  City: 'CREUSSEN',
  Latitude: '498.413',
  Longitude: '11.623.887',
  Telephone: '9270378',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.8413,11.623887',
  id: '49.8413,11.623887'
}, {
  Brand: 'Westfalen',
  Street: 'Westkirchener 7-9',
  PostCode: null,
  City: 'ENNIGERLOH',
  Latitude: '518.396',
  Longitude: '802.576',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8396,8.02576',
  id: '51.8396,8.02576'
}, {
  Brand: 'TOTAL',
  Street: 'NECKARSTR. 47',
  PostCode: 71334,
  City: 'WAIBLINGEN',
  Latitude: '48.852.901',
  Longitude: '9.296.984',
  Telephone: '07151-52837',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.852901,9.296984',
  id: '48.852901,9.296984'
}, {
  Brand: 'TOTAL',
  Street: 'MUENCHNERSTR. 11A',
  PostCode: 85375,
  City: 'NEUFAHRN',
  Latitude: '48.318.361',
  Longitude: '11.691.502',
  Telephone: '08165-66442',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.318361,11.691502',
  id: '48.318361,11.691502'
}, {
  Brand: 'ARAL',
  Street: 'Eppelheimer Straße 78',
  PostCode: 69123,
  City: 'Heidelberg',
  Latitude: '494.034.310',
  Longitude: '86.461.970',
  Telephone: '62217569',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '18:00',
  IsOpen24Hours: 'Nein',
  location: '49.403431,8.646197',
  id: '49.403431,8.646197'
}, {
  Brand: 'ARAL',
  Street: 'Feldstraße 2',
  PostCode: 64347,
  City: 'Griesheim',
  Latitude: '498.674.870',
  Longitude: '85.545.320',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.867487,8.554532',
  id: '49.867487,8.554532'
}, {
  Brand: 'ARAL',
  Street: 'Rosenheimer Landstraße 26',
  PostCode: 85521,
  City: 'Ottobrunn',
  Latitude: '480.685.060',
  Longitude: '116.579.630',
  Telephone: '',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.068506,11.657963',
  id: '48.068506,11.657963'
}, {
  Brand: 'Westfalen',
  Street: 'Paderborner Str. 319',
  PostCode: null,
  City: 'BIELEFELD',
  Latitude: '519.324',
  Longitude: '8.6069',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9324,8.6069',
  id: '51.9324,8.6069'
}, {
  Brand: 'TOTAL',
  Street: 'GOETELSTR. 132-140',
  PostCode: 13595,
  City: 'BERLIN',
  Latitude: '52.521.435',
  Longitude: '13.203.645',
  Telephone: '030/22325440',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.521435,13.203645',
  id: '52.521435,13.203645'
}, {
  Brand: 'Westfalen',
  Street: 'Hauptstr. 58',
  PostCode: null,
  City: 'WILHELMSHAVEN',
  Latitude: '535.926',
  Longitude: '804.519',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.5926,8.04519',
  id: '53.5926,8.04519'
}, {
  Brand: 'TOTAL',
  Street: 'KRAIBURGER STR. 13',
  PostCode: 84478,
  City: 'WALDKRAIBURG',
  Latitude: '48.207.642',
  Longitude: '12.417.865',
  Telephone: '08631-378131',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.207642,12.417865',
  id: '48.207642,12.417865'
}, {
  Brand: 'TOTAL',
  Street: 'FRIEDRICH-FRANZ-STR. 2',
  PostCode: 14770,
  City: 'BRANDENBURG',
  Latitude: '524.136',
  Longitude: '12.516.795',
  Telephone: '03381-301295',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.4136,12.516795',
  id: '52.4136,12.516795'
}, {
  Brand: 'Westfalen',
  Street: 'Bielefelder Str. 107',
  PostCode: null,
  City: 'RHEDA-WIEDENBRUECK',
  Latitude: '518.459',
  Longitude: '832.075',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8459,8.32075',
  id: '51.8459,8.32075'
}, {
  Brand: 'ARAL',
  Street: 'Boltensternstraße 350',
  PostCode: 50735,
  City: 'Köln',
  Latitude: '509.805.050',
  Longitude: '69.690.380',
  Telephone: '2219775353',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.980505,6.969038',
  id: '50.980505,6.969038'
}, {
  Brand: 'Westfalen',
  Street: 'Cranger Str. 215',
  PostCode: null,
  City: 'GELSENKIRCHEN',
  Latitude: '515.636',
  Longitude: '708.538',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.5636,7.08538',
  id: '51.5636,7.08538'
}, {
  Brand: 'TOTAL',
  Street: '30 KASSELER STR.',
  PostCode: 35683,
  City: 'DILLENBURG',
  Latitude: '50.749.592',
  Longitude: '8.276.962',
  Telephone: '02771-812042',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.749592,8.276962',
  id: '50.749592,8.276962'
}, {
  Brand: 'Eni',
  Street: 'Berliner Allee 67',
  PostCode: 16303,
  City: 'Schwedt/Oder',
  Latitude: '5.305.255.601',
  Longitude: '1.428.044.531',
  Telephone: '+49 (0) 33322348',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '53.05255601,14.28044531',
  id: '53.05255601,14.28044531'
}, {
  Brand: 'Eni',
  Street: 'Heidensteilstrasse 8',
  PostCode: 55743,
  City: 'Idar-Oberstein',
  Latitude: '4.972.719.000',
  Longitude: '731.222.000',
  Telephone: '+49 (0) 67814313',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.72719,7.31222',
  id: '49.72719,7.31222'
}, {
  Brand: 'OMV',
  Street: 'Kronacher Str. 65',
  PostCode: 96349,
  City: 'Steinwiesen',
  Latitude: '5.029.145.000',
  Longitude: '1.145.218.000',
  Telephone: '+49-9262-478',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.29145,11.45218',
  id: '50.29145,11.45218'
}, {
  Brand: 'Eni',
  Street: 'Heidelberger Strasse 35',
  PostCode: 64342,
  City: 'Seeheim-Jugenheim',
  Latitude: '4.977.138.000',
  Longitude: '864.598.000',
  Telephone: '+49 (0) 62579629',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.77138,8.64598',
  id: '49.77138,8.64598'
}, {
  Brand: 'Eni',
  Street: 'Leistenstrasse 2',
  PostCode: 97204,
  City: 'Hoechberg',
  Latitude: '4.978.842.072',
  Longitude: '989.876.343',
  Telephone: '+49 (0) 93149266',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.78842072,9.89876343',
  id: '49.78842072,9.89876343'
}, {
  Brand: 'OMV',
  Street: 'Freyunger Str. 11',
  PostCode: 94151,
  City: 'Mauth',
  Latitude: '4.888.455.000',
  Longitude: '1.358.249.000',
  Telephone: '+49-8557-448',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.88455,13.58249',
  id: '48.88455,13.58249'
}, {
  Brand: 'Eni',
  Street: 'Zwingenberger Strasse 25',
  PostCode: 64342,
  City: 'Seeheim-Jugenheim',
  Latitude: '4.975.185.400',
  Longitude: '863.109.100',
  Telephone: '+49 (0) 62579036',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.751854,8.631091',
  id: '49.751854,8.631091'
}, {
  Brand: 'Westfalen',
  Street: 'Roitzheimer Str. 117',
  PostCode: null,
  City: 'EUSKIRCHEN',
  Latitude: '506.522',
  Longitude: '680.102',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.6522,6.80102',
  id: '50.6522,6.80102'
}, {
  Brand: 'Eni',
  Street: 'Plauensche Str. 2',
  PostCode: 7973,
  City: 'Greiz',
  Latitude: '5.064.720.623',
  Longitude: '1.219.469.413',
  Telephone: '+49 (0) 36616352',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.64720623,12.19469413',
  id: '50.64720623,12.19469413'
}, {
  Brand: 'ARAL',
  Street: 'Königsteiner Straße 23',
  PostCode: 65812,
  City: 'Bad Soden',
  Latitude: '501.402.400',
  Longitude: '85.050.940',
  Telephone: '6196671777',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '01:00',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '50.14024,8.505094',
  id: '50.14024,8.505094'
}, {
  Brand: 'TOTAL',
  Street: '50 THEELTALSTRASSE',
  PostCode: 66636,
  City: 'THOLEY-HASBORN',
  Latitude: '4.948.765',
  Longitude: '6.984.632',
  Telephone: '68538540045',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.48765,6.984632',
  id: '49.48765,6.984632'
}, {
  Brand: 'Westfalen',
  Street: 'Bruchstr. 18 - 21',
  PostCode: null,
  City: 'MUELHEIM',
  Latitude: '514.357',
  Longitude: '689.093',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.4357,6.89093',
  id: '51.4357,6.89093'
}, {
  Brand: 'ARAL',
  Street: 'Friedr.-Engels-Allee 237',
  PostCode: 42285,
  City: 'Wuppertal',
  Latitude: '512.634.290',
  Longitude: '71.777.950',
  Telephone: '20281457',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.263429,7.177795',
  id: '51.263429,7.177795'
}, {
  Brand: 'TOTAL',
  Street: 'DRESDNER STR.53',
  PostCode: 1458,
  City: 'OTTENDORF-OKRILLA',
  Latitude: '51.174.856',
  Longitude: '13.825.185',
  Telephone: '035205-54211',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.174856,13.825185',
  id: '51.174856,13.825185'
}, {
  Brand: 'TOTAL',
  Street: 'TONNAER STR. 28',
  PostCode: 99947,
  City: 'BAD LANGENSALZA',
  Latitude: '51.104.253',
  Longitude: '10.664.366',
  Telephone: '03603-842355',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.104253,10.664366',
  id: '51.104253,10.664366'
}, {
  Brand: 'TOTAL',
  Street: 'HECHTSBERG 8',
  PostCode: 77756,
  City: 'HAUSACH',
  Latitude: '4.828.186',
  Longitude: '8.137.154',
  Telephone: '07831-4149988',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.28186,8.137154',
  id: '48.28186,8.137154'
}, {
  Brand: 'ARAL',
  Street: 'Greizer Straße 1',
  PostCode: 8412,
  City: 'Werdau',
  Latitude: '507.235.010',
  Longitude: '123.742.330',
  Telephone: '37615209',
  WeekDayFrom: '04:30',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '05:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.723501,12.374233',
  id: '50.723501,12.374233'
}, {
  Brand: 'TOTAL',
  Street: 'SCHUETTENDELLE 27',
  PostCode: 42857,
  City: 'REMSCHEID',
  Latitude: '51.181.043',
  Longitude: '7.174.071',
  Telephone: '02191-2099115',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.181043,7.174071',
  id: '51.181043,7.174071'
}, {
  Brand: 'TOTAL',
  Street: 'LEHRTE STR. 20',
  PostCode: 31319,
  City: 'SEHNDE',
  Latitude: '5.231.778',
  Longitude: '9.966.101',
  Telephone: '05138-7099202',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.31778,9.966101',
  id: '52.31778,9.966101'
}, {
  Brand: 'TOTAL',
  Street: 'WIESENSTR.4',
  PostCode: 7749,
  City: 'JENA',
  Latitude: '50.932.806',
  Longitude: '1.159.395',
  Telephone: '03641-443555',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.932806,11.59395',
  id: '50.932806,11.59395'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 88',
  PostCode: 88074,
  City: 'Meckenbeuren',
  Latitude: '476.933.770',
  Longitude: '95.595.510',
  Telephone: '75424251',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.693377,9.559551',
  id: '47.693377,9.559551'
}, {
  Brand: 'Westfalen',
  Street: 'Asberger Str. 188',
  PostCode: null,
  City: 'MOERS',
  Latitude: '514.387',
  Longitude: '6.6576',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.4387,6.6576',
  id: '51.4387,6.6576'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 287',
  PostCode: 58675,
  City: 'Hemer',
  Latitude: '513.909.500',
  Longitude: '77.620.340',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.39095,7.762034',
  id: '51.39095,7.762034'
}, {
  Brand: 'TOTAL',
  Street: 'B2',
  PostCode: 86695,
  City: 'NORDENDORF',
  Latitude: '48.596.612',
  Longitude: '1.084.052',
  Telephone: '08273/9969887',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.596612,10.84052',
  id: '48.596612,10.84052'
}, {
  Brand: 'TOTAL',
  Street: 'UMGEHUNGSSTRASSE 1',
  PostCode: 18556,
  City: 'ALTENKIRCHEN',
  Latitude: '54.631.785',
  Longitude: '13.347.598',
  Telephone: '038391-231',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.631785,13.347598',
  id: '54.631785,13.347598'
}, {
  Brand: 'TOTAL',
  Street: 'DUESSELDORFER STR. 28',
  PostCode: 42781,
  City: 'HAAN',
  Latitude: '51.188.318',
  Longitude: '6.994.868',
  Telephone: '02129-959770',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.188318,6.994868',
  id: '51.188318,6.994868'
}, {
  Brand: 'TOTAL',
  Street: 'BRUEELER CH. 12',
  PostCode: 19406,
  City: 'STERNBERG',
  Latitude: '53.708.883',
  Longitude: '11.806.677',
  Telephone: '03847-2348',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.708883,11.806677',
  id: '53.708883,11.806677'
}, {
  Brand: 'OMV',
  Street: 'Erlanger Str. 3',
  PostCode: 90425,
  City: 'Nürnberg',
  Latitude: '4.946.995.000',
  Longitude: '1.106.720.000',
  Telephone: '+49-911-341430',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.46995,11.0672',
  id: '49.46995,11.0672'
}, {
  Brand: 'TOTAL',
  Street: 'SCHWANEBECKER CHAUSSEE 29',
  PostCode: 16321,
  City: 'BERNAU',
  Latitude: '5.266.806',
  Longitude: '1.358.234',
  Telephone: '03338-5500',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.66806,13.58234',
  id: '52.66806,13.58234'
}, {
  Brand: 'Westfalen',
  Street: 'Schulstrasse 32',
  PostCode: null,
  City: 'BERGKAMEN',
  Latitude: '51.606',
  Longitude: '761.878',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.606,7.61878',
  id: '51.606,7.61878'
}, {
  Brand: 'TOTAL',
  Street: 'DACHSBACHER STR. 1',
  PostCode: 91466,
  City: 'GERHARDSHOFEN',
  Latitude: '49.632.327',
  Longitude: '10.692.724',
  Telephone: '9163997290',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.632327,10.692724',
  id: '49.632327,10.692724'
}, {
  Brand: 'ARAL',
  Street: 'Nußdorfer Straße 101',
  PostCode: 88662,
  City: 'Überlingen',
  Latitude: '477.540.910',
  Longitude: '91.901.970',
  Telephone: '75513550',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.754091,9.190197',
  id: '47.754091,9.190197'
}, {
  Brand: 'OMV',
  Street: 'Siemensstr. 19',
  PostCode: 84030,
  City: 'Landshut',
  Latitude: '4.855.688.000',
  Longitude: '1.215.349.000',
  Telephone: '+49-871-140007',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.55688,12.15349',
  id: '48.55688,12.15349'
}, {
  Brand: 'TOTAL',
  Street: 'AUTOHOF BAB 10 /B1',
  PostCode: 14542,
  City: 'PLOETZIN',
  Latitude: '52.390.854',
  Longitude: '12.844.964',
  Telephone: '03327-730313',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.390854,12.844964',
  id: '52.390854,12.844964'
}, {
  Brand: 'TOTAL',
  Street: 'ERICH-SCHLESINGER-STR.26',
  PostCode: 18059,
  City: 'ROSTOCK',
  Latitude: '54.076.621',
  Longitude: '12.119.421',
  Telephone: '0381-442084',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.076621,12.119421',
  id: '54.076621,12.119421'
}, {
  Brand: 'TOTAL',
  Street: 'HALBERSTAEDTER STR.21 B',
  PostCode: 39435,
  City: 'EGELN',
  Latitude: '51.940.555',
  Longitude: '11.418.087',
  Telephone: '039268-2235',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.940555,11.418087',
  id: '51.940555,11.418087'
}, {
  Brand: 'TOTAL',
  Street: 'HAUPTSTR.149',
  PostCode: 47877,
  City: 'WILLICH',
  Latitude: '5.125.707',
  Longitude: '6.486.893',
  Telephone: '02156-60714',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.25707,6.486893',
  id: '51.25707,6.486893'
}, {
  Brand: 'ARAL',
  Street: 'A29',
  PostCode: 26203,
  City: 'Wardenburg',
  Latitude: '530.409.520',
  Longitude: '82.208.620',
  Telephone: '440791640',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.040952,8.220862',
  id: '53.040952,8.220862'
}, {
  Brand: 'TOTAL',
  Street: 'CHEMNITZER STR. 38 B',
  PostCode: 9232,
  City: 'HARTMANNSDORF',
  Latitude: '50.878.548',
  Longitude: '1.282.176',
  Telephone: '03722-96464',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.878548,12.82176',
  id: '50.878548,12.82176'
}, {
  Brand: 'Eni',
  Street: 'Bahnhofsallee',
  PostCode: 3253,
  City: 'Doberlug-Kirchhain',
  Latitude: '5.161.389.000',
  Longitude: '1.355.639.000',
  Telephone: '+49 (0) 35322451',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.61389,13.55639',
  id: '51.61389,13.55639'
}, {
  Brand: 'TOTAL',
  Street: 'HASTENERSTR. 33',
  PostCode: 42855,
  City: 'REMSCHEID',
  Latitude: '5.119.145',
  Longitude: '7.170.787',
  Telephone: '02191-81588',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.19145,7.170787',
  id: '51.19145,7.170787'
}, {
  Brand: 'TOTAL',
  Street: 'TALSTR. 60',
  PostCode: 98544,
  City: 'ZELLA-MEHLIS',
  Latitude: '50.653.158',
  Longitude: '10.663.462',
  Telephone: '03682-486011',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.653158,10.663462',
  id: '50.653158,10.663462'
}, {
  Brand: 'ARAL',
  Street: 'Ulmer Straße 13',
  PostCode: 89340,
  City: 'Leipheim',
  Latitude: '484.458.830',
  Longitude: '102.125.400',
  Telephone: '822171065',
  WeekDayFrom: '05:30',
  WeekDayTo: '23:59',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '48.445883,10.21254',
  id: '48.445883,10.21254'
}, {
  Brand: 'ARAL',
  Street: 'Raiffeisenstraße 17',
  PostCode: 56594,
  City: 'Willroth',
  Latitude: '505.659.680',
  Longitude: '75.265.350',
  Telephone: '2687929100',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.565968,7.526535',
  id: '50.565968,7.526535'
}, {
  Brand: 'TOTAL',
  Street: 'BAB OST A 67',
  PostCode: 64653,
  City: 'LORSCH',
  Latitude: '49.642.589',
  Longitude: '8.553.434',
  Telephone: '06251-175180',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.642589,8.553434',
  id: '49.642589,8.553434'
}, {
  Brand: 'TOTAL',
  Street: 'BERLINER ALLEE 2a',
  PostCode: 14662,
  City: 'FRIESACK',
  Latitude: '52.729.204',
  Longitude: '12.583.189',
  Telephone: '033235-1592',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.729204,12.583189',
  id: '52.729204,12.583189'
}, {
  Brand: 'Westfalen',
  Street: 'Hauptstrasse 1 a',
  PostCode: null,
  City: 'WABERN',
  Latitude: '510.824',
  Longitude: '937.424',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.0824,9.37424',
  id: '51.0824,9.37424'
}, {
  Brand: 'TOTAL',
  Street: 'GUNDELFINGER STR. 27',
  PostCode: 79108,
  City: 'FREIBURG',
  Latitude: '48.030.304',
  Longitude: '7.862.799',
  Telephone: '0761-552674',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.030304,7.862799',
  id: '48.030304,7.862799'
}, {
  Brand: 'TOTAL',
  Street: 'NUERTINGER STR. 64',
  PostCode: 72555,
  City: 'METZINGEN',
  Latitude: '48.543.652',
  Longitude: '9.290.245',
  Telephone: '07123-200830',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.543652,9.290245',
  id: '48.543652,9.290245'
}, {
  Brand: 'TOTAL',
  Street: 'EICKELER BRUCH 143',
  PostCode: 44652,
  City: 'HERNE',
  Latitude: '51.522.241',
  Longitude: '7.159.934',
  Telephone: '02325-33436',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.522241,7.159934',
  id: '51.522241,7.159934'
}, {
  Brand: 'Westfalen',
  Street: 'Koelner Landstr. 417',
  PostCode: null,
  City: 'DUEREN',
  Latitude: '508.137',
  Longitude: '651.702',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.8137,6.51702',
  id: '50.8137,6.51702'
}, {
  Brand: 'Westfalen',
  Street: 'Dollersweg 103',
  PostCode: null,
  City: 'DORTMUND',
  Latitude: '515.376',
  Longitude: '762.727',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.5376,7.62727',
  id: '51.5376,7.62727'
}, {
  Brand: 'Eni',
  Street: 'Flurteil Eule 2',
  PostCode: 4680,
  City: 'Colditz',
  Latitude: '5.113.726.944',
  Longitude: '1.279.151.064',
  Telephone: '+49 (0) 34381431',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.13726944,12.79151064',
  id: '51.13726944,12.79151064'
}, {
  Brand: 'Eni',
  Street: 'Blumberger Chaussee',
  PostCode: 16356,
  City: 'Ahrensfelde',
  Latitude: '5.258.415.806',
  Longitude: '1.358.893.345',
  Telephone: '+49 (0) 30937982',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '52.58415806,13.58893345',
  id: '52.58415806,13.58893345'
}, {
  Brand: 'Eni',
  Street: 'Obere Hauptstr. 5',
  PostCode: 85084,
  City: 'Reichertshofen/Wind.',
  Latitude: '4.864.514.720',
  Longitude: '1.151.395.257',
  Telephone: '+49 (0) 84533306',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.6451472,11.51395257',
  id: '48.6451472,11.51395257'
}, {
  Brand: 'TOTAL',
  Street: '3 CARL-BENZ-STR.',
  PostCode: 36251,
  City: 'BAD HERSFELD',
  Latitude: '50.858.249',
  Longitude: '9.722.719',
  Telephone: '06621-919744',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.858249,9.722719',
  id: '50.858249,9.722719'
}, {
  Brand: 'OMV',
  Street: 'Ludwigstr. 54',
  PostCode: 85399,
  City: 'Hallbergmoos',
  Latitude: '4.833.372.000',
  Longitude: '1.173.143.000',
  Telephone: '+49-811-9999889',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.33372,11.73143',
  id: '48.33372,11.73143'
}, {
  Brand: 'Westfalen',
  Street: 'Bachackerweg 1',
  PostCode: null,
  City: 'MARL',
  Latitude: '516.922',
  Longitude: '711.865',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.6922,7.11865',
  id: '51.6922,7.11865'
}, {
  Brand: 'ARAL',
  Street: 'Riedlinger Straße 67',
  PostCode: 88422,
  City: 'Bad Buchau',
  Latitude: '480.598.960',
  Longitude: '95.945.750',
  Telephone: '758291015',
  WeekDayFrom: '07:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:00',
  SundayFrom: '09:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.059896,9.594575',
  id: '48.059896,9.594575'
}, {
  Brand: 'TOTAL',
  Street: 'HOLZMARKT 49',
  PostCode: 50676,
  City: 'KOELN',
  Latitude: '50.931.279',
  Longitude: '6.962.356',
  Telephone: '0221-218144',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.931279,6.962356',
  id: '50.931279,6.962356'
}, {
  Brand: 'ARAL',
  Street: 'Ludwig-Erhard-Straße 25',
  PostCode: 28197,
  City: 'Bremen',
  Latitude: '530.876.310',
  Longitude: '87.121.690',
  Telephone: '0421-529580',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.087631,8.712169',
  id: '53.087631,8.712169'
}, {
  Brand: 'TOTAL',
  Street: 'SCHEFFELSTR. 13',
  PostCode: 78073,
  City: 'BAD DUERRHEIM',
  Latitude: '48.021.233',
  Longitude: '8.527.893',
  Telephone: '07726-7545',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.021233,8.527893',
  id: '48.021233,8.527893'
}, {
  Brand: 'TOTAL',
  Street: 'KOEPPELSDORFER STR. 69',
  PostCode: 96515,
  City: 'SONNEBERG',
  Latitude: '50.356.192',
  Longitude: '11.180.036',
  Telephone: '03675-427258',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.356192,11.180036',
  id: '50.356192,11.180036'
}, {
  Brand: 'TOTAL',
  Street: 'VENNHAUSER ALLEE 266',
  PostCode: 40627,
  City: 'DUESSELDORF',
  Latitude: '51.214.427',
  Longitude: '6.864.853',
  Telephone: '0211-279666',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.214427,6.864853',
  id: '51.214427,6.864853'
}, {
  Brand: 'TOTAL',
  Street: 'PLOENER STR. 2',
  PostCode: 19395,
  City: 'PLAU AM SEE',
  Latitude: '53.470.368',
  Longitude: '12.264.311',
  Telephone: '038735-40270',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.470368,12.264311',
  id: '53.470368,12.264311'
}, {
  Brand: 'TOTAL',
  Street: 'TAUBENSTR. 40',
  PostCode: 48282,
  City: 'EMSDETTEN',
  Latitude: '52.164.898',
  Longitude: '750.944',
  Telephone: '02572-8771733',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.164898,7.50944',
  id: '52.164898,7.50944'
}, {
  Brand: 'ARAL',
  Street: 'Münchener Straße 400',
  PostCode: 90471,
  City: 'Nürnberg',
  Latitude: '494.020.820',
  Longitude: '111.148.330',
  Telephone: '911805503',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.402082,11.114833',
  id: '49.402082,11.114833'
}, {
  Brand: 'TOTAL',
  Street: 'HANNOVERSCHE STR. 29',
  PostCode: 30916,
  City: 'ISERNHAGEN',
  Latitude: '52.434.059',
  Longitude: '9.863.619',
  Telephone: '0511-612900',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.434059,9.863619',
  id: '52.434059,9.863619'
}, {
  Brand: 'ARAL',
  Street: 'Kientenstraße 6',
  PostCode: 72458,
  City: 'Albstadt-Ebingen',
  Latitude: '482.099.770',
  Longitude: '90.187.050',
  Telephone: '743158816',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '00:00',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.209977,9.018705',
  id: '48.209977,9.018705'
}, {
  Brand: 'TOTAL',
  Street: 'SAARLANDSTR. 40-42',
  PostCode: 16515,
  City: 'ORANIENBURG',
  Latitude: '52.738.611',
  Longitude: '13.248.611',
  Telephone: '03301-3243',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.738611,13.248611',
  id: '52.738611,13.248611'
}, {
  Brand: 'TOTAL',
  Street: 'OESTINGHAUSER STR.14',
  PostCode: 59494,
  City: 'SOEST',
  Latitude: '51.580.547',
  Longitude: '8.111.727',
  Telephone: '02921-80663',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.580547,8.111727',
  id: '51.580547,8.111727'
}, {
  Brand: 'ARAL',
  Street: 'Evinger Straße 156',
  PostCode: 44339,
  City: 'Dortmund',
  Latitude: '515.462.770',
  Longitude: '74.603.790',
  Telephone: '2318091592',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.546277,7.460379',
  id: '51.546277,7.460379'
}, {
  Brand: 'TOTAL',
  Street: '36 BAHNHOFSTR.',
  PostCode: 27404,
  City: 'ZEVEN',
  Latitude: '53.287.222',
  Longitude: '9.276.944',
  Telephone: '04281-80380',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.287222,9.276944',
  id: '53.287222,9.276944'
}, {
  Brand: 'TOTAL',
  Street: 'BIEFANGSTR. 92',
  PostCode: 46149,
  City: 'OBERHAUSEN',
  Latitude: '51.513.599',
  Longitude: '6.827.426',
  Telephone: '0208-669830',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.513599,6.827426',
  id: '51.513599,6.827426'
}, {
  Brand: 'TOTAL',
  Street: 'IM HAG 2-4',
  PostCode: 73230,
  City: 'KIRCHHEIM/TECK',
  Latitude: '48.642.172',
  Longitude: '9.433.691',
  Telephone: '07021-863650',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.642172,9.433691',
  id: '48.642172,9.433691'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 51',
  PostCode: 86470,
  City: 'Thannhausen',
  Latitude: '482.861.520',
  Longitude: '104.676.770',
  Telephone: '82814763',
  WeekDayFrom: '06:45',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.286152,10.467677',
  id: '48.286152,10.467677'
}, {
  Brand: 'TOTAL',
  Street: 'DUESSELDORFER STR. 66',
  PostCode: 40721,
  City: 'HILDEN',
  Latitude: '51.164.699',
  Longitude: '6.917.088',
  Telephone: '02103-55579',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.164699,6.917088',
  id: '51.164699,6.917088'
}, {
  Brand: 'TOTAL',
  Street: 'PAXTONSTR. 2',
  PostCode: 48488,
  City: 'EMSBUEREN',
  Latitude: '52.358.815',
  Longitude: '7.261.105',
  Telephone: '05903-9321345',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.358815,7.261105',
  id: '52.358815,7.261105'
}, {
  Brand: 'TOTAL',
  Street: 'PULVERHAUSWEG',
  PostCode: 99734,
  City: 'NORDHAUSEN',
  Latitude: '51.494.437',
  Longitude: '1.081.467',
  Telephone: '03631-601162',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.494437,10.81467',
  id: '51.494437,10.81467'
}, {
  Brand: 'OMV',
  Street: 'Gewerbehof 11',
  PostCode: 84130,
  City: 'Dingolfing',
  Latitude: '4.864.523.000',
  Longitude: '1.248.435.000',
  Telephone: '+49-8731-73034',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.64523,12.48435',
  id: '48.64523,12.48435'
}, {
  Brand: 'TOTAL',
  Street: '324 HEERSTR.',
  PostCode: 13593,
  City: 'BERLIN',
  Latitude: '52.516.812',
  Longitude: '13.180.058',
  Telephone: '030-30124300',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.516812,13.180058',
  id: '52.516812,13.180058'
}, {
  Brand: 'ARAL',
  Street: 'Max-Holder-Straße 2',
  PostCode: 60437,
  City: 'Frankfurt',
  Latitude: '501.892.060',
  Longitude: '86.514.280',
  Telephone: '6995091947',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.189206,8.651428',
  id: '50.189206,8.651428'
}, {
  Brand: 'OMV',
  Street: 'Autobahn Süd A8',
  PostCode: 73344,
  City: 'Gruibingen',
  Latitude: '',
  Longitude: '',
  Telephone: '+49-7335-1849865',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '0,0',
  id: '0,0'
}, {
  Brand: 'Westfalen',
  Street: 'Attenser Allee 141',
  PostCode: null,
  City: 'NORDENHAM',
  Latitude: '535.026',
  Longitude: '847.792',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.5026,8.47792',
  id: '53.5026,8.47792'
}, {
  Brand: 'OMV',
  Street: 'Pockinger Str. 19',
  PostCode: 94072,
  City: 'Bad Füssing',
  Latitude: '4.836.166.000',
  Longitude: '1.331.181.000',
  Telephone: '+49-8531-981304',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.36166,13.31181',
  id: '48.36166,13.31181'
}, {
  Brand: 'Eni',
  Street: 'Freiburger Str. 17B',
  PostCode: 79215,
  City: 'Elzach',
  Latitude: '4.816.706.655',
  Longitude: '805.931.736',
  Telephone: '+49 (0) 76827766',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.16706655,8.05931736',
  id: '48.16706655,8.05931736'
}, {
  Brand: 'Westfalen',
  Street: 'Halterner Str. 61',
  PostCode: null,
  City: 'DUELMEN',
  Latitude: '518.255',
  Longitude: '727.279',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8255,7.27279',
  id: '51.8255,7.27279'
}, {
  Brand: 'Eni',
  Street: 'Neurieder Strasse 18',
  PostCode: 81475,
  City: 'Muenchen',
  Latitude: '4.808.914.000',
  Longitude: '1.147.439.000',
  Telephone: '+49 (0) 89751333',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.08914,11.47439',
  id: '48.08914,11.47439'
}, {
  Brand: 'ARAL',
  Street: 'Augsburger Straße 38',
  PostCode: 86899,
  City: 'Landsberg',
  Latitude: '480.590.290',
  Longitude: '108.680.680',
  Telephone: '819159655',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '48.059029,10.868068',
  id: '48.059029,10.868068'
}, {
  Brand: 'TOTAL',
  Street: 'HAUPTSTR. 180',
  PostCode: 69168,
  City: 'WIESLOCH',
  Latitude: '49.283.479',
  Longitude: '8.686.314',
  Telephone: '06222-50637',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.283479,8.686314',
  id: '49.283479,8.686314'
}, {
  Brand: 'Eni',
  Street: 'Hirsauer Strasse 216',
  PostCode: 75180,
  City: 'Pforzheim',
  Latitude: '4.887.152.000',
  Longitude: '867.859.000',
  Telephone: '+49 (0) 72317400',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.87152,8.67859',
  id: '48.87152,8.67859'
}, {
  Brand: 'ARAL',
  Street: 'Neuenkirchener Straße  44',
  PostCode: 48431,
  City: 'Rheine',
  Latitude: '522.792.820',
  Longitude: '74.293.300',
  Telephone: '59713626',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:59',
  SundayFrom: '08:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '52.279282,7.42933',
  id: '52.279282,7.42933'
}, {
  Brand: 'TOTAL',
  Street: '1 TAUNUSSTR.',
  PostCode: 63811,
  City: 'STOCKSTADT',
  Latitude: '49.960.854',
  Longitude: '9.080.667',
  Telephone: '60279795656',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.960854,9.080667',
  id: '49.960854,9.080667'
}, {
  Brand: 'ARAL',
  Street: 'A 39 Salzgitterhüttenblick Süd',
  PostCode: 38239,
  City: 'Salzgitter',
  Latitude: '521.888.970',
  Longitude: '103.962.150',
  Telephone: '',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.188897,10.396215',
  id: '52.188897,10.396215'
}, {
  Brand: 'Westfalen',
  Street: 'Bernard-Krone-Str. 1',
  PostCode: null,
  City: 'SPELLE',
  Latitude: '52.366',
  Longitude: '745.558',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.366,7.45558',
  id: '52.366,7.45558'
}, {
  Brand: 'Eni',
  Street: 'Olvenstedter Chaussee 22',
  PostCode: 39130,
  City: 'Magdeburg',
  Latitude: '5.215.276.000',
  Longitude: '1.157.365.000',
  Telephone: '+49 (0) 39172147',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '52.15276,11.57365',
  id: '52.15276,11.57365'
}, {
  Brand: 'Eni',
  Street: 'Clemensstrasse 12',
  PostCode: 56218,
  City: 'Muelheim-Kaerlich',
  Latitude: '5.039.013.000',
  Longitude: '748.685.000',
  Telephone: '+49 (0) 26301817',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.39013,7.48685',
  id: '50.39013,7.48685'
}, {
  Brand: 'OMV',
  Street: 'Gottmannshofer Str. 9',
  PostCode: 86637,
  City: 'Wertingen',
  Latitude: '4.855.653.000',
  Longitude: '1.069.081.000',
  Telephone: '+49-8272-5100',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.55653,10.69081',
  id: '48.55653,10.69081'
}, {
  Brand: 'Eni',
  Street: 'Illinger Eck B 10/B 35',
  PostCode: 75428,
  City: 'Illingen',
  Latitude: '4.895.328.663',
  Longitude: '891.265.141',
  Telephone: '+49 (0) 70422050',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.95328663,8.91265141',
  id: '48.95328663,8.91265141'
}, {
  Brand: 'OMV',
  Street: 'Kaiser- Karl- V.- Allee 60',
  PostCode: 93077,
  City: 'Bad Abbach',
  Latitude: '4.893.071.000',
  Longitude: '1.203.245.000',
  Telephone: '+49-9405-1398',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.93071,12.03245',
  id: '48.93071,12.03245'
}, {
  Brand: 'Westfalen',
  Street: 'Reher Weg 36 a',
  PostCode: null,
  City: 'AERZEN',
  Latitude: '520.429',
  Longitude: '9.2582',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0429,9.2582',
  id: '52.0429,9.2582'
}, {
  Brand: 'ARAL',
  Street: 'Am halben Weg 2',
  PostCode: 91301,
  City: 'Forchheim',
  Latitude: '496.983.830',
  Longitude: '110.649.749',
  Telephone: '91916219620',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.698383,11.0649749',
  id: '49.698383,11.0649749'
}, {
  Brand: 'ARAL',
  Street: 'Eugen-Sänger-Ring 1',
  PostCode: 85649,
  City: 'Brunnthal',
  Latitude: '480.430.350',
  Longitude: '116.643.180',
  Telephone: '8960807989',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Ja',
  location: '48.043035,11.664318',
  id: '48.043035,11.664318'
}, {
  Brand: 'Eni',
  Street: 'Kissinger Strasse 35',
  PostCode: 97769,
  City: 'Bad Brueckenau',
  Latitude: '5.031.187.411',
  Longitude: '979.383.164',
  Telephone: '+49 (0) 97412020',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.31187411,9.79383164',
  id: '50.31187411,9.79383164'
}, {
  Brand: 'ARAL',
  Street: 'A8',
  PostCode: 86368,
  City: 'Gersthofen-Edenberge',
  Latitude: '484.249.832',
  Longitude: '108.081.220',
  Telephone: '82148685416',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.4249832,10.808122',
  id: '48.4249832,10.808122'
}, {
  Brand: 'Eni',
  Street: 'Kriegsbergstrasse 55 A',
  PostCode: 70174,
  City: 'Stuttgart',
  Latitude: '4.878.229.000',
  Longitude: '917.149.000',
  Telephone: '+49 (0) 71129502',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.78229,9.17149',
  id: '48.78229,9.17149'
}, {
  Brand: 'Eni',
  Street: 'Ittlinger Str. 226',
  PostCode: 94315,
  City: 'Straubing',
  Latitude: '4.888.413.000',
  Longitude: '1.259.316.000',
  Telephone: '+49 (0) 94216113',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.88413,12.59316',
  id: '48.88413,12.59316'
}, {
  Brand: 'OMV',
  Street: 'Maitz 3',
  PostCode: 83607,
  City: 'Holzkirchen',
  Latitude: '4.788.855.000',
  Longitude: '1.168.485.000',
  Telephone: '+49-8024-993463',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '47.88855,11.68485',
  id: '47.88855,11.68485'
}, {
  Brand: 'Eni',
  Street: 'Stettiner Chaussee',
  PostCode: 17309,
  City: 'Pasewalk',
  Latitude: '5.350.287.725',
  Longitude: '1.400.323.994',
  Telephone: '+49 (0) 39732101',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '53.50287725,14.00323994',
  id: '53.50287725,14.00323994'
}, {
  Brand: 'Eni',
  Street: 'Dannenwalder Weg 69',
  PostCode: 13439,
  City: 'Berlin',
  Latitude: '5.259.169.070',
  Longitude: '1.334.644.097',
  Telephone: '+49 (0) 30416520',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.5916907,13.34644097',
  id: '52.5916907,13.34644097'
}, {
  Brand: 'OMV',
  Street: 'Wilhelmstr. 57',
  PostCode: 73433,
  City: 'Aalen',
  Latitude: '4.886.111.000',
  Longitude: '1.010.277.000',
  Telephone: '+49-7361-71268',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.86111,10.10277',
  id: '48.86111,10.10277'
}, {
  Brand: 'Eni',
  Street: 'Seeonerstrasse 1',
  PostCode: 83119,
  City: 'Obing',
  Latitude: '4.799.977.550',
  Longitude: '1.241.016.086',
  Telephone: '+49 (0) 86241450',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.9997755,12.41016086',
  id: '47.9997755,12.41016086'
}, {
  Brand: 'TOTAL',
  Street: 'LANDSBERGER ALLEE 526',
  PostCode: 12681,
  City: 'BERLIN',
  Latitude: '5.254.486',
  Longitude: '13.569.022',
  Telephone: '030-54376273/74',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.54486,13.569022',
  id: '52.54486,13.569022'
}, {
  Brand: 'ARAL',
  Street: 'Günzburger Straße 63',
  PostCode: 89423,
  City: 'Gundelfingen',
  Latitude: '485.439.050',
  Longitude: '103.675.340',
  Telephone: '9073800590',
  WeekDayFrom: '06:45',
  WeekDayTo: '20:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.543905,10.367534',
  id: '48.543905,10.367534'
}, {
  Brand: 'TOTAL',
  Street: 'HICKLSTR. 2',
  PostCode: 85764,
  City: 'OBERSCHLEISSHEIM',
  Latitude: '48.265.174',
  Longitude: '11.593.216',
  Telephone: '089-21894466',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.265174,11.593216',
  id: '48.265174,11.593216'
}, {
  Brand: 'TOTAL',
  Street: 'MANCHINGER STR. 84',
  PostCode: 85053,
  City: 'INGOLSTADT',
  Latitude: '48.752.958',
  Longitude: '11.451.818',
  Telephone: '0841/99389815',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.752958,11.451818',
  id: '48.752958,11.451818'
}, {
  Brand: 'TOTAL',
  Street: 'DUERENER STR. 168-172',
  PostCode: 52249,
  City: 'ESCHWEILER',
  Latitude: '50.818.873',
  Longitude: '6.282.095',
  Telephone: '02403-4600',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.818873,6.282095',
  id: '50.818873,6.282095'
}, {
  Brand: 'Westfalen',
  Street: 'Rekener Str. 100',
  PostCode: null,
  City: 'DUELMEN',
  Latitude: '518.484',
  Longitude: '719.356',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8484,7.19356',
  id: '51.8484,7.19356'
}, {
  Brand: 'TOTAL',
  Street: 'BAB- WUNNENSTEIN OST/A 81',
  PostCode: 74360,
  City: 'ILSFELD',
  Latitude: '49.043.009',
  Longitude: '9.262.472',
  Telephone: '07062-4170',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.043009,9.262472',
  id: '49.043009,9.262472'
}, {
  Brand: 'ARAL',
  Street: 'Altenberger Straße 93',
  PostCode: 42929,
  City: 'Wermelskirchen',
  Latitude: '510.911.550',
  Longitude: '71.868.710',
  Telephone: '21934691',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '10:00',
  SundayTo: '18:00',
  IsOpen24Hours: 'Nein',
  location: '51.091155,7.186871',
  id: '51.091155,7.186871'
}, {
  Brand: 'Westfalen',
  Street: 'Industriestrasse 1',
  PostCode: null,
  City: 'SUEDLOHN',
  Latitude: '519.354',
  Longitude: '682.016',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9354,6.82016',
  id: '51.9354,6.82016'
}, {
  Brand: 'OMV',
  Street: 'Hindenburgstr. 52',
  PostCode: 76297,
  City: 'Stutensee',
  Latitude: '4.910.381.000',
  Longitude: '848.181.000',
  Telephone: '+49-7249-3460',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.10381,8.48181',
  id: '49.10381,8.48181'
}, {
  Brand: 'Westfalen',
  Street: 'Laurenzstr. 108',
  PostCode: null,
  City: 'OCHTRUP',
  Latitude: '522.067',
  Longitude: '7.2077',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.2067,7.2077',
  id: '52.2067,7.2077'
}, {
  Brand: 'OMV',
  Street: 'Würzburger Str. 21 a',
  PostCode: 97292,
  City: 'Üttingen',
  Latitude: '4.979.667.000',
  Longitude: '973.547.000',
  Telephone: '+49-9369-1843',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.79667,9.73547',
  id: '49.79667,9.73547'
}, {
  Brand: 'ARAL',
  Street: 'Sezanner Straße 6',
  PostCode: 76316,
  City: 'Malsch',
  Latitude: '488.894.640',
  Longitude: '83.269.250',
  Telephone: '72464148',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.889464,8.326925',
  id: '48.889464,8.326925'
}, {
  Brand: 'Westfalen',
  Street: 'Heerstr. 166',
  PostCode: null,
  City: 'BAD NEUENAHR',
  Latitude: '505.493',
  Longitude: '7.1308',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.5493,7.1308',
  id: '50.5493,7.1308'
}, {
  Brand: 'OMV',
  Street: 'Bahnhofstr. 43',
  PostCode: 97346,
  City: 'Iphofen',
  Latitude: '4.969.877.000',
  Longitude: '1.025.995.000',
  Telephone: '+49-9323-3377',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.69877,10.25995',
  id: '49.69877,10.25995'
}, {
  Brand: 'ARAL',
  Street: 'Oberwerrner Weg 10',
  PostCode: 97502,
  City: 'Euerbach',
  Latitude: '500.628.460',
  Longitude: '101.445.270',
  Telephone: '0160/97050134',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.062846,10.144527',
  id: '50.062846,10.144527'
}, {
  Brand: 'ARAL',
  Street: 'Zeppelinstraße 14 - 16',
  PostCode: 88239,
  City: 'Wangen',
  Latitude: '476.901.810',
  Longitude: '98.288.760',
  Telephone: '',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '14:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '47.690181,9.828876',
  id: '47.690181,9.828876'
}, {
  Brand: 'TOTAL',
  Street: 'AM DORN 5-7',
  PostCode: 48308,
  City: 'SENDEN',
  Latitude: '51.904.118',
  Longitude: '7.488.412',
  Telephone: '02536-345620',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.904118,7.488412',
  id: '51.904118,7.488412'
}, {
  Brand: 'TOTAL',
  Street: 'AUTOHOF AM ESCHENBACH 1',
  PostCode: 97440,
  City: 'WERNECK',
  Latitude: '50.003.346',
  Longitude: '10.117.968',
  Telephone: '09722-94620',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.003346,10.117968',
  id: '50.003346,10.117968'
}, {
  Brand: 'Eni',
  Street: 'Nuernberger Strasse 51',
  PostCode: 91781,
  City: 'Weissenburg',
  Latitude: '4.903.406.047',
  Longitude: '1.097.185.819',
  Telephone: '+49 (0) 91418737',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.03406047,10.97185819',
  id: '49.03406047,10.97185819'
}, {
  Brand: 'ARAL',
  Street: 'Ludwig-Erhard-Straße 25',
  PostCode: 28197,
  City: 'Bremen',
  Latitude: '530.874.390',
  Longitude: '87.123.510',
  Telephone: '421529580',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '18:00',
  SundayFrom: '12:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '53.087439,8.712351',
  id: '53.087439,8.712351'
}, {
  Brand: 'OMV',
  Street: 'Florianstraße 31',
  PostCode: 85551,
  City: 'Kirchheim b. München',
  Latitude: '4.817.562.000',
  Longitude: '1.176.429.000',
  Telephone: '+49-89-90476622',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.17562,11.76429',
  id: '48.17562,11.76429'
}, {
  Brand: 'ARAL',
  Street: 'A5',
  PostCode: 61239,
  City: 'Ober-Mörlen',
  Latitude: '503.556.600',
  Longitude: '86.945.600',
  Telephone: '60027915',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.35566,8.69456',
  id: '50.35566,8.69456'
}, {
  Brand: 'ARAL',
  Street: 'Rhein-Taunus-Straße 52',
  PostCode: 56340,
  City: 'Dachsenhausen',
  Latitude: '502.535.510',
  Longitude: '77.331.140',
  Telephone: '67761356',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.253551,7.733114',
  id: '50.253551,7.733114'
}, {
  Brand: 'TOTAL',
  Street: 'SIEMENSSTR. 2',
  PostCode: 64289,
  City: 'DARMSTADT',
  Latitude: '49.896.099',
  Longitude: '868.058',
  Telephone: '06151-4929866',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.896099,8.68058',
  id: '49.896099,8.68058'
}, {
  Brand: 'Westfalen',
  Street: 'Westfalenstr. 124-126',
  PostCode: null,
  City: 'MUENSTER',
  Latitude: '519.071',
  Longitude: '763.365',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9071,7.63365',
  id: '51.9071,7.63365'
}, {
  Brand: 'Eni',
  Street: 'Cosimastrasse 234',
  PostCode: 81927,
  City: 'Muenchen',
  Latitude: '4.817.227.843',
  Longitude: '1.163.686.840',
  Telephone: '+49 (0) 89951631',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.17227843,11.6368684',
  id: '48.17227843,11.6368684'
}, {
  Brand: 'Westfalen',
  Street: 'Stadtholzstr. 252',
  PostCode: null,
  City: 'BAT HERFORD-S?',
  Latitude: '521.015',
  Longitude: '872.013',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.1015,8.72013',
  id: '52.1015,8.72013'
}, {
  Brand: 'OMV',
  Street: 'Memminger Str. 60',
  PostCode: 87439,
  City: 'Kempten',
  Latitude: '4.773.450.000',
  Longitude: '1.031.027.000',
  Telephone: '+49-831-23976',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.7345,10.31027',
  id: '47.7345,10.31027'
}, {
  Brand: 'OMV',
  Street: 'Winterbruckenweg 53',
  PostCode: 86316,
  City: 'Derching',
  Latitude: '4.840.693.000',
  Longitude: '1.095.067.000',
  Telephone: '+49-821-78094626',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.40693,10.95067',
  id: '48.40693,10.95067'
}, {
  Brand: 'TOTAL',
  Street: '9 NUERNBERGER STR.',
  PostCode: 70374,
  City: 'STUTTGART',
  Latitude: '48.806.115',
  Longitude: '9.2324',
  Telephone: '0711-522550',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.806115,9.2324',
  id: '48.806115,9.2324'
}, {
  Brand: 'TOTAL',
  Street: 'IM NEYL 30',
  PostCode: 59823,
  City: 'ARNSBERG',
  Latitude: '513.906',
  Longitude: '8.130.287',
  Telephone: '02937-6473',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.3906,8.130287',
  id: '51.3906,8.130287'
}, {
  Brand: 'ARAL',
  Street: 'Saynstraße 44',
  PostCode: 57627,
  City: 'Hachenburg',
  Latitude: '506.671.750',
  Longitude: '78.088.670',
  Telephone: '2662939200',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.667175,7.808867',
  id: '50.667175,7.808867'
}, {
  Brand: 'Eni',
  Street: 'Landsberger Strasse 447',
  PostCode: 81241,
  City: 'Muenchen',
  Latitude: '4.814.532.666',
  Longitude: '1.148.121.739',
  Telephone: '+49 (0) 89833399',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.14532666,11.48121739',
  id: '48.14532666,11.48121739'
}, {
  Brand: 'TOTAL',
  Street: 'BUNDESSTR.HAUS NR. 7',
  PostCode: 52159,
  City: 'ROETGEN',
  Latitude: '50.646.891',
  Longitude: '6.185.342',
  Telephone: '02471-2510',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.646891,6.185342',
  id: '50.646891,6.185342'
}, {
  Brand: 'TOTAL',
  Street: 'IN DER WAAGE 1',
  PostCode: 73463,
  City: 'WESTHAUSEN',
  Latitude: '48.883.055',
  Longitude: '10.174.955',
  Telephone: '07363-9547097',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.883055,10.174955',
  id: '48.883055,10.174955'
}, {
  Brand: 'ARAL',
  Street: 'Wegelnburgstraße 27',
  PostCode: 67065,
  City: 'Ludwigshafen',
  Latitude: '494.571.060',
  Longitude: '84.318.210',
  Telephone: '62157240520',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.457106,8.431821',
  id: '49.457106,8.431821'
}, {
  Brand: 'TOTAL',
  Street: 'SENNER STR. 8',
  PostCode: 33335,
  City: 'GUETERSLOH',
  Latitude: '5.194.014',
  Longitude: '8.486.962',
  Telephone: '05209-980818',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.94014,8.486962',
  id: '51.94014,8.486962'
}, {
  Brand: 'TOTAL',
  Street: 'ARNO-NITZSCHE STRASSE 32',
  PostCode: 4277,
  City: 'LEIPZIG',
  Latitude: '5.130.892',
  Longitude: '12.389.452',
  Telephone: '0341-3018122',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.30892,12.389452',
  id: '51.30892,12.389452'
}, {
  Brand: 'TOTAL',
  Street: 'AUTOHOF VOGELHERD 4',
  PostCode: 91236,
  City: 'ALFELD',
  Latitude: '49.410.373',
  Longitude: '11.544.802',
  Telephone: '09157-95170',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.410373,11.544802',
  id: '49.410373,11.544802'
}, {
  Brand: 'TOTAL',
  Street: 'SCHIMMELBUSCHSTR. 27',
  PostCode: 40699,
  City: 'ERKRATH-HOCHDAHL',
  Latitude: '51.215.475',
  Longitude: '6.948.487',
  Telephone: '02104-39604',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.215475,6.948487',
  id: '51.215475,6.948487'
}, {
  Brand: 'TOTAL',
  Street: 'AUTOHOF AN DER AUTOBAHN K4',
  PostCode: 91161,
  City: 'HILPOLTSTEIN',
  Latitude: '49.160.892',
  Longitude: '11.262.227',
  Telephone: '09174-976666',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.160892,11.262227',
  id: '49.160892,11.262227'
}, {
  Brand: 'Eni',
  Street: 'In der Au 1',
  PostCode: 96224,
  City: 'Burgkunstadt',
  Latitude: '5.013.719.718',
  Longitude: '1.125.089.166',
  Telephone: '+49 (0) 95721544',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.13719718,11.25089166',
  id: '50.13719718,11.25089166'
}, {
  Brand: 'ARAL',
  Street: 'An der Autobahn 1',
  PostCode: 95512,
  City: 'Neudrossenfeld',
  Latitude: '500.373.470',
  Longitude: '114.906.330',
  Telephone: '9203688996',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.037347,11.490633',
  id: '50.037347,11.490633'
}, {
  Brand: 'ARAL',
  Street: 'A 71',
  PostCode: 97638,
  City: 'Mellrichstadt',
  Latitude: '504.236.020',
  Longitude: '103.518.440',
  Telephone: '97767094943',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.423602,10.351844',
  id: '50.423602,10.351844'
}, {
  Brand: 'ARAL',
  Street: 'Engesserstraße 13',
  PostCode: 79108,
  City: 'Freiburg im Breisgau',
  Latitude: '480.186.701',
  Longitude: '78.422.270',
  Telephone: '76168002380',
  WeekDayFrom: '04:30',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '48.0186701,7.842227',
  id: '48.0186701,7.842227'
}, {
  Brand: 'TOTAL',
  Street: 'KOMMERNER STR. 147',
  PostCode: 53879,
  City: 'EUSKIRCHEN',
  Latitude: '50.656.601',
  Longitude: '6.768.086',
  Telephone: '02251-929103',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.656601,6.768086',
  id: '50.656601,6.768086'
}, {
  Brand: 'TOTAL',
  Street: 'BRAUNSCHWEIGER STR. 45 - 47',
  PostCode: 38518,
  City: 'GIFHORN',
  Latitude: '52.476.713',
  Longitude: '10.544.206',
  Telephone: '05371-13613',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.476713,10.544206',
  id: '52.476713,10.544206'
}, {
  Brand: 'TOTAL',
  Street: 'SCHUETZENSTR. 1A',
  PostCode: 98527,
  City: 'SUHL',
  Latitude: '5.058.809',
  Longitude: '1.071.919',
  Telephone: '03681-807271',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.58809,10.71919',
  id: '50.58809,10.71919'
}, {
  Brand: 'TOTAL',
  Street: '124-125 OSTERDEICH',
  PostCode: 28205,
  City: 'BREMEN',
  Latitude: '53.066.935',
  Longitude: '8.844.334',
  Telephone: '0421-440704',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.066935,8.844334',
  id: '53.066935,8.844334'
}, {
  Brand: 'ARAL',
  Street: 'Wetzlarer Straße 32',
  PostCode: 57250,
  City: 'Netphen',
  Latitude: '508.620.370',
  Longitude: '82.001.720',
  Telephone: '27372185959',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '18:00',
  SundayFrom: '09:00',
  SundayTo: '18:00',
  IsOpen24Hours: 'Nein',
  location: '50.862037,8.200172',
  id: '50.862037,8.200172'
}, {
  Brand: 'TOTAL',
  Street: 'BOLTENHAGENER STR. 11b',
  PostCode: 23948,
  City: 'KLUETZ',
  Latitude: '53.970.639',
  Longitude: '1.117.414',
  Telephone: '038825-22578',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.970639,11.17414',
  id: '53.970639,11.17414'
}, {
  Brand: 'Westfalen',
  Street: 'Herner Str. 269',
  PostCode: null,
  City: 'BOCHUM',
  Latitude: '515.025',
  Longitude: '721.228',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.5025,7.21228',
  id: '51.5025,7.21228'
}, {
  Brand: 'Westfalen',
  Street: 'Holsterhauser',
  PostCode: null,
  City: 'HERNE',
  Latitude: '51.531.645',
  Longitude: '7.205.456',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.531645,7.205456',
  id: '51.531645,7.205456'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 62',
  PostCode: 35428,
  City: 'Langgöns-Oberkleen',
  Latitude: '504.616.720',
  Longitude: '85.846.460',
  Telephone: '6447887360',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.461672,8.584646',
  id: '50.461672,8.584646'
}, {
  Brand: 'ARAL',
  Street: 'Bamberger Straße 27',
  PostCode: 96328,
  City: 'Küps',
  Latitude: '501.916.160',
  Longitude: '112.681.190',
  Telephone: '926480269',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.191616,11.268119',
  id: '50.191616,11.268119'
}, {
  Brand: 'ARAL',
  Street: 'Aiterhofener Straße 215',
  PostCode: 94315,
  City: 'Straubing',
  Latitude: '488.728.910',
  Longitude: '126.316.750',
  Telephone: '94211862815',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.872891,12.631675',
  id: '48.872891,12.631675'
}, {
  Brand: 'OMV',
  Street: 'Brucker Str. 79',
  PostCode: 85221,
  City: 'Dachau',
  Latitude: '4.825.687.000',
  Longitude: '1.141.902.000',
  Telephone: '+49-8131-56027',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.25687,11.41902',
  id: '48.25687,11.41902'
}, {
  Brand: 'Eni',
  Street: 'Killisfeldstrasse 44',
  PostCode: 76227,
  City: 'Karlsruhe',
  Latitude: '4.899.646.000',
  Longitude: '845.472.000',
  Telephone: '+49 (0) 72144175',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.99646,8.45472',
  id: '48.99646,8.45472'
}, {
  Brand: 'OMV',
  Street: 'Ooser Hauptstr. 6',
  PostCode: 76532,
  City: 'Baden-Baden',
  Latitude: '4.878.821.000',
  Longitude: '819.438.000',
  Telephone: '+49-7221-61035',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.78821,8.19438',
  id: '48.78821,8.19438'
}, {
  Brand: 'Eni',
  Street: 'Lindenring 5',
  PostCode: 82024,
  City: 'Taufkirchen',
  Latitude: '4.805.210.000',
  Longitude: '1.160.575.000',
  Telephone: '+49 (0) 89612171',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.0521,11.60575',
  id: '48.0521,11.60575'
}, {
  Brand: 'ARAL',
  Street: 'Dieskaustraße 483 a',
  PostCode: 4249,
  City: 'Leipzig',
  Latitude: '512.728.110',
  Longitude: '123.083.770',
  Telephone: '3414240640',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.272811,12.308377',
  id: '51.272811,12.308377'
}, {
  Brand: 'Westfalen',
  Street: 'Osnabruecker Str. 18',
  PostCode: null,
  City: 'WESTERKAPPELN',
  Latitude: '523.129',
  Longitude: '788.154',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.3129,7.88154',
  id: '52.3129,7.88154'
}, {
  Brand: 'Eni',
  Street: 'Konrad-Adenauer-Str. 71',
  PostCode: 63322,
  City: 'Roedermark-Urberrach',
  Latitude: '4.997.438.586',
  Longitude: '878.935.107',
  Telephone: '+49 (0) 60748618',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.97438586,8.78935107',
  id: '49.97438586,8.78935107'
}, {
  Brand: 'Eni',
  Street: 'Peisel',
  PostCode: 53797,
  City: 'Lohmar',
  Latitude: '5.086.883.000',
  Longitude: '725.244.000',
  Telephone: '+49 (0) 22469126',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.86883,7.25244',
  id: '50.86883,7.25244'
}, {
  Brand: 'TOTAL',
  Street: '64 LANDSTRASSE',
  PostCode: 42781,
  City: 'HAAN',
  Latitude: '51.204.903',
  Longitude: '7.041.496',
  Telephone: '02129-3456929',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.204903,7.041496',
  id: '51.204903,7.041496'
}, {
  Brand: 'ARAL',
  Street: 'Ingolstädter Straße 35',
  PostCode: 90461,
  City: 'Nürnberg',
  Latitude: '494.271.970',
  Longitude: '111.012.310',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.427197,11.101231',
  id: '49.427197,11.101231'
}, {
  Brand: 'TOTAL',
  Street: 'BAHNHOFSTR.60',
  PostCode: 38486,
  City: 'KLOETZE',
  Latitude: '52.630.039',
  Longitude: '11.153.438',
  Telephone: '03909-3890',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.630039,11.153438',
  id: '52.630039,11.153438'
}, {
  Brand: 'TOTAL',
  Street: '10 TIEFSTADT',
  PostCode: 84307,
  City: 'EGGENFELDEN',
  Latitude: '48.395.753',
  Longitude: '12.766.098',
  Telephone: '08721/508143',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.395753,12.766098',
  id: '48.395753,12.766098'
}, {
  Brand: 'TOTAL',
  Street: 'ILMENAUER STR.2',
  PostCode: 99326,
  City: 'STADTILM',
  Latitude: '50.767.666',
  Longitude: '11.064.826',
  Telephone: '03629-3172',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.767666,11.064826',
  id: '50.767666,11.064826'
}, {
  Brand: 'TOTAL',
  Street: 'HAUPTSTR. 72',
  PostCode: 74869,
  City: 'SCHWARZACH',
  Latitude: '49.366.603',
  Longitude: '8.981.484',
  Telephone: '06262-6294',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.366603,8.981484',
  id: '49.366603,8.981484'
}, {
  Brand: 'TOTAL',
  Street: 'BOUXWILLER STRASSE 1',
  PostCode: 64832,
  City: 'BABENHAUSEN',
  Latitude: '49.969.769',
  Longitude: '8.949.254',
  Telephone: '06073-723376',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.969769,8.949254',
  id: '49.969769,8.949254'
}, {
  Brand: 'TOTAL',
  Street: 'KOECKERN WEST/BAB 9',
  PostCode: 6794,
  City: 'SANDERSDORF-BREHNA',
  Latitude: '5.160.816',
  Longitude: '1.218.453',
  Telephone: '034954-39317',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.60816,12.18453',
  id: '51.60816,12.18453'
}, {
  Brand: 'TOTAL',
  Street: 'HAUPTSTR.21',
  PostCode: 85095,
  City: 'DENKENDORF',
  Latitude: '4.892.799',
  Longitude: '1.145.699',
  Telephone: '08466-458',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.92799,11.45699',
  id: '48.92799,11.45699'
}, {
  Brand: 'OMV',
  Street: 'Würzburger Str. 20',
  PostCode: 97199,
  City: 'Ochsenfurt',
  Latitude: '4.966.989.000',
  Longitude: '1.005.899.000',
  Telephone: '+49-9331-2514',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.66989,10.05899',
  id: '49.66989,10.05899'
}, {
  Brand: 'ARAL',
  Street: 'Schützstraße 2',
  PostCode: 56242,
  City: 'Selters',
  Latitude: '505.376.260',
  Longitude: '77.595.100',
  Telephone: '2626921605',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:30',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.537626,7.75951',
  id: '50.537626,7.75951'
}, {
  Brand: 'Westfalen',
  Street: 'Werler Str. 8',
  PostCode: null,
  City: 'ARNSBERG',
  Latitude: '514.549',
  Longitude: '795.911',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.4549,7.95911',
  id: '51.4549,7.95911'
}, {
  Brand: 'Eni',
  Street: 'Huettenfelder Str. 46',
  PostCode: 69502,
  City: 'Hemsbach',
  Latitude: '4.959.301.623',
  Longitude: '863.971.061',
  Telephone: '+49 (0) 62017144',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.59301623,8.63971061',
  id: '49.59301623,8.63971061'
}, {
  Brand: 'Eni',
  Street: 'Robert-Bosch-Strasse 14',
  PostCode: 35440,
  City: 'Linden',
  Latitude: '5.053.763.000',
  Longitude: '865.385.000',
  Telephone: '+49 (0) 64037202',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.53763,8.65385',
  id: '50.53763,8.65385'
}, {
  Brand: 'ARAL',
  Street: 'Hafenstr. 124',
  PostCode: 59067,
  City: 'Hamm',
  Latitude: '516.767.120',
  Longitude: '77.754.680',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.676712,7.775468',
  id: '51.676712,7.775468'
}, {
  Brand: 'TOTAL',
  Street: 'HESSENSTR. 79',
  PostCode: 36358,
  City: 'HERBSTEIN',
  Latitude: '5.055.652',
  Longitude: '9.350.362',
  Telephone: '06643-1347',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.55652,9.350362',
  id: '50.55652,9.350362'
}, {
  Brand: 'TOTAL',
  Street: 'FRANKFURTER STR. 85',
  PostCode: 58553,
  City: 'HALVER',
  Latitude: '51.184.418',
  Longitude: '7.509.619',
  Telephone: '02353-3322',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.184418,7.509619',
  id: '51.184418,7.509619'
}, {
  Brand: 'TOTAL',
  Street: 'BAHNSTR. 174',
  PostCode: 41069,
  City: 'MOENCHENGLADBACH',
  Latitude: '51.181.751',
  Longitude: '6.411.782',
  Telephone: '02161-592993',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.181751,6.411782',
  id: '51.181751,6.411782'
}, {
  Brand: 'ARAL',
  Street: 'A 7',
  PostCode: 38723,
  City: 'Seesen',
  Latitude: '519.255.880',
  Longitude: '101.429.020',
  Telephone: '5384969800',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.925588,10.142902',
  id: '51.925588,10.142902'
}, {
  Brand: 'TOTAL',
  Street: 'NEUE REIHE 94C',
  PostCode: 18225,
  City: 'KUEHLUNGSBORN',
  Latitude: '54.144.871',
  Longitude: '11.735.626',
  Telephone: '038293-7608',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.144871,11.735626',
  id: '54.144871,11.735626'
}, {
  Brand: 'Eni',
  Street: 'Schumacherring 96',
  PostCode: 87437,
  City: 'Kempten',
  Latitude: '4.773.111.000',
  Longitude: '1.032.634.000',
  Telephone: '+49 (0) 83175510',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '47.73111,10.32634',
  id: '47.73111,10.32634'
}, {
  Brand: 'ARAL',
  Street: 'Traunwalchner Straße 12',
  PostCode: 83301,
  City: 'Traunreut',
  Latitude: '479.570.430',
  Longitude: '125.962.480',
  Telephone: '86697897373',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.957043,12.596248',
  id: '47.957043,12.596248'
}, {
  Brand: 'ARAL',
  Street: 'A7',
  PostCode: 38723,
  City: 'Seesen',
  Latitude: '519.285.280',
  Longitude: '101.423.060',
  Telephone: '5384969502',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.928528,10.142306',
  id: '51.928528,10.142306'
}, {
  Brand: 'TOTAL',
  Street: 'ARTILLERIEPARK 5',
  PostCode: 14624,
  City: 'DALLGOW',
  Latitude: '52.533.461',
  Longitude: '13.059.905',
  Telephone: '03322-2137842/43',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.533461,13.059905',
  id: '52.533461,13.059905'
}, {
  Brand: 'TOTAL',
  Street: 'VAHRENER DAMM 10',
  PostCode: 49661,
  City: 'CLOPPENBURG',
  Latitude: '52.827.153',
  Longitude: '8.000.313',
  Telephone: '04471-7012924',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.827153,8.000313',
  id: '52.827153,8.000313'
}, {
  Brand: 'OMV',
  Street: 'Pfarrkirchener Str. 2',
  PostCode: 94099,
  City: 'Ruhstorf',
  Latitude: '4.843.202.000',
  Longitude: '1.333.280.000',
  Telephone: '+49-8531-3336',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.43202,13.3328',
  id: '48.43202,13.3328'
}, {
  Brand: 'ARAL',
  Street: 'Bodenseestraße 317',
  PostCode: 81249,
  City: 'München',
  Latitude: '481.411.840',
  Longitude: '114.139.550',
  Telephone: '8985796463',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '05:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '48.141184,11.413955',
  id: '48.141184,11.413955'
}, {
  Brand: 'TOTAL',
  Street: 'ISERLOHNER STR.45',
  PostCode: 59423,
  City: 'UNNA',
  Latitude: '51.526.759',
  Longitude: '769.485',
  Telephone: '02303-80000',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.526759,7.69485',
  id: '51.526759,7.69485'
}, {
  Brand: 'Eni',
  Street: 'Jakob-Schuele-Str. 52',
  PostCode: 73655,
  City: 'Pluederhausen',
  Latitude: '4.880.059.000',
  Longitude: '959.286.000',
  Telephone: '07181/82667',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.80059,9.59286',
  id: '48.80059,9.59286'
}, {
  Brand: 'Eni',
  Street: 'Hauptstrasse 5-7',
  PostCode: 90607,
  City: 'Rueckersdorf',
  Latitude: '4.949.203.000',
  Longitude: '1.123.676.000',
  Telephone: '+49 (0) 91157938',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.49203,11.23676',
  id: '49.49203,11.23676'
}, {
  Brand: 'OMV',
  Street: 'Hofmark-Aich-Str. 22',
  PostCode: 84030,
  City: 'Landshut',
  Latitude: '4.854.849.000',
  Longitude: '1.214.826.000',
  Telephone: '+49-871-1431757',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.54849,12.14826',
  id: '48.54849,12.14826'
}, {
  Brand: 'TOTAL',
  Street: 'AUTOHOF AN DER BAB 61',
  PostCode: 55444,
  City: 'WALDLAUBERSHEIM',
  Latitude: '49.924.245',
  Longitude: '7.824.723',
  Telephone: '06707 242984863',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.924245,7.824723',
  id: '49.924245,7.824723'
}, {
  Brand: 'TOTAL',
  Street: '1 FRIEDERIKENHOFER STR.',
  PostCode: 14979,
  City: 'GROSSBEEREN',
  Latitude: '52.390.341',
  Longitude: '13.340.665',
  Telephone: '033701-365783',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.390341,13.340665',
  id: '52.390341,13.340665'
}, {
  Brand: 'ARAL',
  Street: 'Hochstraße / Bergheimer S',
  PostCode: 47228,
  City: 'Duisburg',
  Latitude: '514.196.170',
  Longitude: '66.961.590',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.419617,6.696159',
  id: '51.419617,6.696159'
}, {
  Brand: 'ARAL',
  Street: 'Gerresheimer Landstr. 67',
  PostCode: 40627,
  City: 'Düsseldorf',
  Latitude: '512.016.100',
  Longitude: '69.047.970',
  Telephone: '211201996',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.20161,6.904797',
  id: '51.20161,6.904797'
}, {
  Brand: 'TOTAL',
  Street: 'WENDEWEHRSTR. 129',
  PostCode: 99974,
  City: 'MUEHLHAUSEN',
  Latitude: '51.216.242',
  Longitude: '10.463.415',
  Telephone: '03601-815176',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.216242,10.463415',
  id: '51.216242,10.463415'
}, {
  Brand: 'TOTAL',
  Street: 'BRAMFELDER STR. 86a',
  PostCode: 22305,
  City: 'HAMBURG',
  Latitude: '53.587.929',
  Longitude: '10.052.757',
  Telephone: '040-22605444',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.587929,10.052757',
  id: '53.587929,10.052757'
}, {
  Brand: 'ARAL',
  Street: 'Gutenbergstraße 2',
  PostCode: 28816,
  City: 'Stuhr',
  Latitude: '530.196.130',
  Longitude: '87.713.480',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.019613,8.771348',
  id: '53.019613,8.771348'
}, {
  Brand: 'ARAL',
  Street: 'A 57',
  PostCode: 47652,
  City: 'Weeze',
  Latitude: '516.522.770',
  Longitude: '61.982.590',
  Telephone: '2837664418',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.652277,6.198259',
  id: '51.652277,6.198259'
}, {
  Brand: 'Eni',
  Street: 'Hindemithstr. 4 a',
  PostCode: 55127,
  City: 'Mainz-Lerchenberg',
  Latitude: '4.995.925.000',
  Longitude: '820.013.000',
  Telephone: '+49 (0) 61317152',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.95925,8.20013',
  id: '49.95925,8.20013'
}, {
  Brand: 'TOTAL',
  Street: 'ROELCKESTR. 74-76',
  PostCode: 13088,
  City: 'BERLIN',
  Latitude: '52.562.493',
  Longitude: '13.459.476',
  Telephone: '030-92799956',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.562493,13.459476',
  id: '52.562493,13.459476'
}, {
  Brand: 'Eni',
  Street: 'Hauptstr. 57',
  PostCode: 77960,
  City: 'Seelbach',
  Latitude: '4.831.576.109',
  Longitude: '793.853.569',
  Telephone: '+49 (0) 78239663',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.31576109,7.93853569',
  id: '48.31576109,7.93853569'
}, {
  Brand: 'OMV',
  Street: 'Hauptstr. 38',
  PostCode: 91334,
  City: 'Hemhofen',
  Latitude: '4.968.629.000',
  Longitude: '1.093.884.000',
  Telephone: '+49-9195-992296',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.68629,10.93884',
  id: '49.68629,10.93884'
}, {
  Brand: 'Eni',
  Street: 'Friesenstrasse 38',
  PostCode: 71640,
  City: 'Ludwigsburg',
  Latitude: '4.889.322.000',
  Longitude: '922.330.000',
  Telephone: '+49 (0) 71412955',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.89322,9.2233',
  id: '48.89322,9.2233'
}, {
  Brand: 'Westfalen',
  Street: 'Davertstr. 53',
  PostCode: null,
  City: 'MUENSTER',
  Latitude: '518.829',
  Longitude: '760.634',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8829,7.60634',
  id: '51.8829,7.60634'
}, {
  Brand: 'OMV',
  Street: 'Augsburger Str. 187',
  PostCode: 89231,
  City: 'Neu-Ulm',
  Latitude: '4.840.123.000',
  Longitude: '1.002.225.000',
  Telephone: '+49-731-73711',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.40123,10.02225',
  id: '48.40123,10.02225'
}, {
  Brand: 'Westfalen',
  Street: 'Pyrmonter Str. 35',
  PostCode: null,
  City: 'HAMELN',
  Latitude: '520.996',
  Longitude: '935.025',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0996,9.35025',
  id: '52.0996,9.35025'
}, {
  Brand: 'TOTAL',
  Street: 'KOLONIESTR. 141',
  PostCode: 47057,
  City: 'DUISBURG',
  Latitude: '51.419.762',
  Longitude: '6.786.564',
  Telephone: '0203-352727',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.419762,6.786564',
  id: '51.419762,6.786564'
}, {
  Brand: 'TOTAL',
  Street: '14a DURMERSHEIMER STR.',
  PostCode: 76316,
  City: 'MALSCH',
  Latitude: '48.902.002',
  Longitude: '8.310.792',
  Telephone: '07246-8484',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.902002,8.310792',
  id: '48.902002,8.310792'
}, {
  Brand: 'OMV',
  Street: 'Freihofstr. 77',
  PostCode: 70439,
  City: 'Stuttgart',
  Latitude: '4.884.391.000',
  Longitude: '915.849.000',
  Telephone: '+49-711-801139',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.84391,9.15849',
  id: '48.84391,9.15849'
}, {
  Brand: 'Westfalen',
  Street: 'Roxeler Str. 556',
  PostCode: null,
  City: 'MUENSTER',
  Latitude: '51.955',
  Longitude: '7.5374',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.955,7.5374',
  id: '51.955,7.5374'
}, {
  Brand: 'Eni',
  Street: 'Neuoettinger Str. 36',
  PostCode: 84543,
  City: 'Winhoering',
  Latitude: '4.826.572.561',
  Longitude: '1.265.133.925',
  Telephone: '+49 (0) 86718820',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.26572561,12.65133925',
  id: '48.26572561,12.65133925'
}, {
  Brand: 'Eni',
  Street: 'Schweinfurter Strasse 1',
  PostCode: 93059,
  City: 'Regensburg',
  Latitude: '4.902.885.000',
  Longitude: '1.208.525.000',
  Telephone: '+49 (0) 94184667',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.02885,12.08525',
  id: '49.02885,12.08525'
}, {
  Brand: 'Westfalen',
  Street: 'Frettholz 5',
  PostCode: null,
  City: 'BARNTRUP',
  Latitude: '51.988.651',
  Longitude: '9.135.773',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.988651,9.135773',
  id: '51.988651,9.135773'
}, {
  Brand: 'Eni',
  Street: 'Holzweg 21',
  PostCode: 86156,
  City: 'Augsburg',
  Latitude: '4.838.996.000',
  Longitude: '1.086.653.000',
  Telephone: '+49 (0) 82146136',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.38996,10.86653',
  id: '48.38996,10.86653'
}, {
  Brand: 'Eni',
  Street: 'Reichenaustrasse 43 A',
  PostCode: 78467,
  City: 'Konstanz',
  Latitude: '4.767.176.000',
  Longitude: '916.650.000',
  Telephone: '+49 (0) 75316223',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.67176,9.1665',
  id: '47.67176,9.1665'
}, {
  Brand: 'ARAL',
  Street: 'Carl-Bosch Straße 1A',
  PostCode: 76829,
  City: 'Landau',
  Latitude: '491.925.560',
  Longitude: '81.425.160',
  Telephone: '63415590250',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.192556,8.142516',
  id: '49.192556,8.142516'
}, {
  Brand: 'Eni',
  Street: 'Industriepark Hoechst',
  PostCode: 65926,
  City: 'Frankfurt',
  Latitude: '5.008.232.000',
  Longitude: '854.354.000',
  Telephone: '+49 (0) 69300928',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.08232,8.54354',
  id: '50.08232,8.54354'
}, {
  Brand: 'OMV',
  Street: 'Epplestr. 55',
  PostCode: 70597,
  City: 'Stuttgart',
  Latitude: '4.874.407.000',
  Longitude: '916.911.000',
  Telephone: '+49-711-7655530',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.74407,9.16911',
  id: '48.74407,9.16911'
}, {
  Brand: 'OMV',
  Street: 'Bayerwaldstr. 2',
  PostCode: 94362,
  City: 'Neukirchen',
  Latitude: '4.897.400.000',
  Longitude: '1.275.256.000',
  Telephone: '+49-9961-94090',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.974,12.75256',
  id: '48.974,12.75256'
}, {
  Brand: 'Westfalen',
  Street: 'Stiftsallee 36',
  PostCode: null,
  City: 'MINDEN',
  Latitude: '523.039',
  Longitude: '890.441',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.3039,8.90441',
  id: '52.3039,8.90441'
}, {
  Brand: 'ARAL',
  Street: 'A7',
  PostCode: 91620,
  City: 'Ohrenbach',
  Latitude: '494.860.300',
  Longitude: '102.118.150',
  Telephone: '986597770',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.48603,10.211815',
  id: '49.48603,10.211815'
}, {
  Brand: 'Eni',
  Street: 'Morsbacher Str. 13',
  PostCode: 57537,
  City: 'Wissen / Sieg',
  Latitude: '5.078.691.000',
  Longitude: '772.913.000',
  Telephone: '02742/2876',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.78691,7.72913',
  id: '50.78691,7.72913'
}, {
  Brand: 'TOTAL',
  Street: 'DORFSTR.37a',
  PostCode: 16356,
  City: 'AHRENSFELDE',
  Latitude: '52.577.505',
  Longitude: '13.584.722',
  Telephone: '030-93497825',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.577505,13.584722',
  id: '52.577505,13.584722'
}, {
  Brand: 'TOTAL',
  Street: 'ROTTWEILER STR. 2',
  PostCode: 78727,
  City: 'OBERNDORF',
  Latitude: '4.828.614',
  Longitude: '8.578.105',
  Telephone: '07423-8109130',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.28614,8.578105',
  id: '48.28614,8.578105'
}, {
  Brand: 'Eni',
  Street: 'Frankfurter Strasse 50',
  PostCode: 64839,
  City: 'Muenster',
  Latitude: '4.992.920.876',
  Longitude: '885.750.393',
  Telephone: '+49 (0) 60713054',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.92920876,8.85750393',
  id: '49.92920876,8.85750393'
}, {
  Brand: 'OMV',
  Street: 'Ernst-Reuter-Str. 63',
  PostCode: 95030,
  City: 'Hof',
  Latitude: '5.031.150.000',
  Longitude: '1.190.745.000',
  Telephone: '+49-9281-91118',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.3115,11.90745',
  id: '50.3115,11.90745'
}, {
  Brand: 'TOTAL',
  Street: '23a OBERNDORFER STR.',
  PostCode: 84032,
  City: 'LANDSHUT',
  Latitude: '48.547.993',
  Longitude: '1.213.178',
  Telephone: '0871-40471787',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.547993,12.13178',
  id: '48.547993,12.13178'
}, {
  Brand: 'ARAL',
  Street: 'Limburger Straße 12',
  PostCode: 35759,
  City: 'Driedorf',
  Latitude: '506.181.800',
  Longitude: '81.921.960',
  Telephone: '27757224',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.61818,8.192196',
  id: '50.61818,8.192196'
}, {
  Brand: 'ARAL',
  Street: 'Robert-Bosch-Straße 10',
  PostCode: 71701,
  City: 'Schwieberdingen',
  Latitude: '488.819.010',
  Longitude: '90.792.810',
  Telephone: '715033403',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.881901,9.079281',
  id: '48.881901,9.079281'
}, {
  Brand: 'TOTAL',
  Street: 'EILENBURGER STR.',
  PostCode: 4849,
  City: 'BAD DUEBEN',
  Latitude: '51.584.499',
  Longitude: '12.592.266',
  Telephone: '034243-23151',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.584499,12.592266',
  id: '51.584499,12.592266'
}, {
  Brand: 'ARAL',
  Street: 'Bödigheimer Straße 24',
  PostCode: 74722,
  City: 'Buchen',
  Latitude: '495.152.870',
  Longitude: '93.215.440',
  Telephone: '628152150',
  WeekDayFrom: '06:45',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:45',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.515287,9.321544',
  id: '49.515287,9.321544'
}, {
  Brand: 'TOTAL',
  Street: 'SCHLACHTHOFSTR. 88',
  PostCode: 56073,
  City: 'KOBLENZ',
  Latitude: '50.362.534',
  Longitude: '7.571.578',
  Telephone: '0261-43111',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.362534,7.571578',
  id: '50.362534,7.571578'
}, {
  Brand: 'TOTAL',
  Street: '18 HAMBURGER STR.',
  PostCode: 19348,
  City: 'PERLEBERG',
  Latitude: '53.082.701',
  Longitude: '11.858.818',
  Telephone: '03876-785150',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.082701,11.858818',
  id: '53.082701,11.858818'
}, {
  Brand: 'ARAL',
  Street: 'Heerstraße 41',
  PostCode: 29693,
  City: 'Hodenhagen',
  Latitude: '527.627.150',
  Longitude: '95.830.250',
  Telephone: '5164682',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.762715,9.583025',
  id: '52.762715,9.583025'
}, {
  Brand: 'TOTAL',
  Street: 'ALTLUSSHEIMER STR. 54',
  PostCode: 68809,
  City: 'NEULUSSHEIM',
  Latitude: '49.294.434',
  Longitude: '851.686',
  Telephone: '06205-31735',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.294434,8.51686',
  id: '49.294434,8.51686'
}, {
  Brand: 'TOTAL',
  Street: '3 LAUENFOERDERSTR.',
  PostCode: 37176,
  City: 'NOERTEN-HARDENBERG',
  Latitude: '51.626.505',
  Longitude: '9.934.207',
  Telephone: '05503-9159377',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.626505,9.934207',
  id: '51.626505,9.934207'
}, {
  Brand: 'Westfalen',
  Street: 'Hauptstrasse 58',
  PostCode: null,
  City: 'NIEDERNWOEHREN',
  Latitude: '523.555',
  Longitude: '914.227',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.3555,9.14227',
  id: '52.3555,9.14227'
}, {
  Brand: 'TOTAL',
  Street: 'WESTERHAEUSER STR.',
  PostCode: 6484,
  City: 'QUEDLINBURG',
  Latitude: '51.792.288',
  Longitude: '11.129.096',
  Telephone: '03946-702014',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.792288,11.129096',
  id: '51.792288,11.129096'
}, {
  Brand: 'ARAL',
  Street: 'In der Pützgewann 3 b',
  PostCode: 56218,
  City: 'Mülheim Kärlich',
  Latitude: '503.964.880',
  Longitude: '75.127.660',
  Telephone: '26309669283',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.396488,7.512766',
  id: '50.396488,7.512766'
}, {
  Brand: 'OMV',
  Street: 'Füssener Str. 37',
  PostCode: 87484,
  City: 'Nesselwang',
  Latitude: '4.762.039.000',
  Longitude: '1.050.626.000',
  Telephone: '+49-8361-731',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.62039,10.50626',
  id: '47.62039,10.50626'
}, {
  Brand: 'OMV',
  Street: 'Brucker Str. 16',
  PostCode: 93149,
  City: 'Nittenau',
  Latitude: '4.920.208.000',
  Longitude: '1.228.010.000',
  Telephone: '+49-9436-903231',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.20208,12.2801',
  id: '49.20208,12.2801'
}, {
  Brand: 'TOTAL',
  Street: '44 EDISONSTR.',
  PostCode: 24145,
  City: 'KIEL',
  Latitude: '54.277.443',
  Longitude: '10.161.049',
  Telephone: '0431-717570',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.277443,10.161049',
  id: '54.277443,10.161049'
}, {
  Brand: 'TOTAL',
  Street: 'BORNAISCHE STR. 227',
  PostCode: 4279,
  City: 'LEIPZIG',
  Latitude: '51.284.905',
  Longitude: '12.394.645',
  Telephone: '0341-3389322',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.284905,12.394645',
  id: '51.284905,12.394645'
}, {
  Brand: 'Eni',
  Street: 'Gymnasiumstrasse 71',
  PostCode: 74072,
  City: 'Heilbronn',
  Latitude: '4.914.189.000',
  Longitude: '922.569.000',
  Telephone: '+49 (0) 71318049',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.14189,9.22569',
  id: '49.14189,9.22569'
}, {
  Brand: 'ARAL',
  Street: 'An der Haune 1 a',
  PostCode: 36251,
  City: 'Bad Hersfeld',
  Latitude: '508.621.710',
  Longitude: '97.228.130',
  Telephone: '6621919511',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.862171,9.722813',
  id: '50.862171,9.722813'
}, {
  Brand: 'Eni',
  Street: 'Eutighofer Str. 124',
  PostCode: 73525,
  City: 'Schwaebisch-Gmuend',
  Latitude: '4.878.604.000',
  Longitude: '976.823.000',
  Telephone: '+49 (0) 71713082',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.78604,9.76823',
  id: '48.78604,9.76823'
}, {
  Brand: 'TOTAL',
  Street: 'SCHIPKAUER STR.20',
  PostCode: 1987,
  City: 'SCHWARZHEIDE',
  Latitude: '51.465.799',
  Longitude: '13.868.194',
  Telephone: '035752-50893',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.465799,13.868194',
  id: '51.465799,13.868194'
}, {
  Brand: 'TOTAL',
  Street: 'BRESLAUER STR. 1',
  PostCode: 75181,
  City: 'PFORZHEIM',
  Latitude: '48.884.801',
  Longitude: '872.729',
  Telephone: '07231-63949',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.884801,8.72729',
  id: '48.884801,8.72729'
}, {
  Brand: 'Westfalen',
  Street: 'Bieste 2',
  PostCode: null,
  City: 'NEUENKIRCHEN',
  Latitude: '52.521',
  Longitude: '803.923',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.521,8.03923',
  id: '52.521,8.03923'
}, {
  Brand: 'TOTAL',
  Street: 'VOLMESTR. 49',
  PostCode: 58579,
  City: 'SCHALKSMUEHLE',
  Latitude: '51.245.303',
  Longitude: '7.523.955',
  Telephone: '02355-1404',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.245303,7.523955',
  id: '51.245303,7.523955'
}, {
  Brand: 'TOTAL',
  Street: 'FREIBERGER STR. 72',
  PostCode: 9569,
  City: 'OEDERAN',
  Latitude: '50.864.882',
  Longitude: '13.178.886',
  Telephone: '037292-60273',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.864882,13.178886',
  id: '50.864882,13.178886'
}, {
  Brand: 'ARAL',
  Street: 'Carl-Benz-Str. 10',
  PostCode: 60386,
  City: 'Frankfurt',
  Latitude: '501.217.640',
  Longitude: '87.491.240',
  Telephone: '6940809098',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.121764,8.749124',
  id: '50.121764,8.749124'
}, {
  Brand: 'TOTAL',
  Street: 'DAHLENER STR.520',
  PostCode: 41239,
  City: 'MOENCHENGLADBACH',
  Latitude: '51.159.478',
  Longitude: '6.415.267',
  Telephone: '02166-31582',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.159478,6.415267',
  id: '51.159478,6.415267'
}, {
  Brand: 'TOTAL',
  Street: 'AN DER B87',
  PostCode: 6628,
  City: 'BAD KOESEN',
  Latitude: '51.138.946',
  Longitude: '11.730.228',
  Telephone: '034463-26808',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.138946,11.730228',
  id: '51.138946,11.730228'
}, {
  Brand: 'TOTAL',
  Street: 'IN DEN ENGEMATTEN 2',
  PostCode: 79286,
  City: 'GLOTTERTAL',
  Latitude: '48.054.533',
  Longitude: '7.922.698',
  Telephone: '07684-909083',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.054533,7.922698',
  id: '48.054533,7.922698'
}, {
  Brand: 'TOTAL',
  Street: 'ANDERLEBENER STR. 53',
  PostCode: 39387,
  City: 'OSCHERSLEBEN',
  Latitude: '52.028.477',
  Longitude: '11.246.092',
  Telephone: '03949-81693',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.028477,11.246092',
  id: '52.028477,11.246092'
}, {
  Brand: 'TOTAL',
  Street: 'RASTANLAGE 10/ B31',
  PostCode: 79877,
  City: 'FRIEDENWEILER',
  Latitude: '47.897.916',
  Longitude: '8.273.432',
  Telephone: '76546829903',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.897916,8.273432',
  id: '47.897916,8.273432'
}, {
  Brand: 'TOTAL',
  Street: 'PRENZLAUER ALLEE 1-4',
  PostCode: 10405,
  City: 'BERLIN',
  Latitude: '52.528.899',
  Longitude: '1.341.808',
  Telephone: '030-4425643',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.528899,13.41808',
  id: '52.528899,13.41808'
}, {
  Brand: 'TOTAL',
  Street: 'HOLZMARKTSTR. 36-42',
  PostCode: 10243,
  City: 'BERLIN',
  Latitude: '52.511.399',
  Longitude: '13.428.477',
  Telephone: '030-2493777',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.511399,13.428477',
  id: '52.511399,13.428477'
}, {
  Brand: 'TOTAL',
  Street: 'CELLER STR. 27',
  PostCode: 29313,
  City: 'HAMBUEHREN',
  Latitude: '52.634.042',
  Longitude: '9.940.472',
  Telephone: '05084-6260',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.634042,9.940472',
  id: '52.634042,9.940472'
}, {
  Brand: 'ARAL',
  Street: 'An der Bundesstraße 5',
  PostCode: 71543,
  City: 'Wüstenrot',
  Latitude: '490.904.510',
  Longitude: '94.909.950',
  Telephone: '794591910',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '49.090451,9.490995',
  id: '49.090451,9.490995'
}, {
  Brand: 'ARAL',
  Street: 'Harxheimer Straße 1',
  PostCode: 67591,
  City: 'Wachenheim',
  Latitude: '496.366.860',
  Longitude: '81.643.110',
  Telephone: '6243317',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:30',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '49.636686,8.164311',
  id: '49.636686,8.164311'
}, {
  Brand: 'Eni',
  Street: 'Sonthofener Strasse 16',
  PostCode: 87561,
  City: 'Oberstdorf',
  Latitude: '4.741.292.000',
  Longitude: '1.027.536.000',
  Telephone: '+49 (0) 83229626',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.41292,10.27536',
  id: '47.41292,10.27536'
}, {
  Brand: 'ARAL',
  Street: 'Daimler Straße 2',
  PostCode: 48308,
  City: 'Senden',
  Latitude: '518.467.380',
  Longitude: '74.846.890',
  Telephone: '2597960750',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:30',
  SaturdayFrom: '05:30',
  SaturdayTo: '22:30',
  SundayFrom: '09:00',
  SundayTo: '22:30',
  IsOpen24Hours: 'Nein',
  location: '51.846738,7.484689',
  id: '51.846738,7.484689'
}, {
  Brand: 'Westfalen',
  Street: 'Zur alten Kolonie 1',
  PostCode: null,
  City: 'HOLZWICKEDE',
  Latitude: '515.148',
  Longitude: '762.053',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.5148,7.62053',
  id: '51.5148,7.62053'
}, {
  Brand: 'OMV',
  Street: 'Igersheimer Strasse 16',
  PostCode: 97980,
  City: 'Bad Mergentheim',
  Latitude: '4.949.057.000',
  Longitude: '977.910.000',
  Telephone: '+49-7931-2378',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.49057,9.7791',
  id: '49.49057,9.7791'
}, {
  Brand: 'TOTAL',
  Street: 'SALZUFLER ALLEE 40',
  PostCode: 14943,
  City: 'LUCKENWALDE',
  Latitude: '52.084.399',
  Longitude: '13.180.287',
  Telephone: '03371-622696',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.084399,13.180287',
  id: '52.084399,13.180287'
}, {
  Brand: 'Eni',
  Street: 'Haager Strasse 34',
  PostCode: 85435,
  City: 'Erding',
  Latitude: '4.830.168.997',
  Longitude: '1.190.837.822',
  Telephone: '+49 (0) 81229026',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.30168997,11.90837822',
  id: '48.30168997,11.90837822'
}, {
  Brand: 'Eni',
  Street: 'Oberopfinger Str. 25',
  PostCode: 88450,
  City: 'Berkheim',
  Latitude: '4.804.266.963',
  Longitude: '1.008.407.617',
  Telephone: '+49 (0) 8395658',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.04266963,10.08407617',
  id: '48.04266963,10.08407617'
}, {
  Brand: 'ARAL',
  Street: 'Baarstraße 162',
  PostCode: 58636,
  City: 'Iserlohn',
  Latitude: '514.004.650',
  Longitude: '76.977.380',
  Telephone: '237142200',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '12:01',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.400465,7.697738',
  id: '51.400465,7.697738'
}, {
  Brand: 'Westfalen',
  Street: 'Goekerstr. 125 a',
  PostCode: null,
  City: 'WILHELMSHAVEN',
  Latitude: '535.385',
  Longitude: '812.352',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.5385,8.12352',
  id: '53.5385,8.12352'
}, {
  Brand: 'ARAL',
  Street: 'A8 / Schmiedstraße  16',
  PostCode: 83607,
  City: 'Holzkirchen',
  Latitude: '479.072.670',
  Longitude: '117.173.360',
  Telephone: '80246080990',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.907267,11.717336',
  id: '47.907267,11.717336'
}, {
  Brand: 'OMV',
  Street: 'Rosenheimer Str. 40',
  PostCode: 83064,
  City: 'Raubling',
  Latitude: '4.779.679.000',
  Longitude: '1.212.035.000',
  Telephone: '+49-8035-873689',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '47.79679,12.12035',
  id: '47.79679,12.12035'
}, {
  Brand: 'Eni',
  Street: 'Goeltzschtalstr. 75',
  PostCode: 8209,
  City: 'Auerbach',
  Latitude: '5.049.522.921',
  Longitude: '1.239.653.702',
  Telephone: '+49 (0) 37441711',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.49522921,12.39653702',
  id: '50.49522921,12.39653702'
}, {
  Brand: 'OMV',
  Street: 'Am Oberfeld 1',
  PostCode: 86707,
  City: 'Westendorf',
  Latitude: '4.855.792.000',
  Longitude: '1.085.259.000',
  Telephone: '+49-8271-420425',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.55792,10.85259',
  id: '48.55792,10.85259'
}, {
  Brand: 'Eni',
  Street: 'Raffineriestr. 119',
  PostCode: 93333,
  City: 'Neustadt/Donau',
  Latitude: '4.878.366.927',
  Longitude: '1.176.958.312',
  Telephone: '+49 (0) 94457502',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.78366927,11.76958312',
  id: '48.78366927,11.76958312'
}, {
  Brand: 'Eni',
  Street: 'Metzer Str. 114',
  PostCode: 66117,
  City: 'Saarbruecken',
  Latitude: '4.921.765.300',
  Longitude: '696.833.600',
  Telephone: '+49 (0) 68154240',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.217653,6.968336',
  id: '49.217653,6.968336'
}, {
  Brand: 'Eni',
  Street: 'Hersbrucker Str. 64',
  PostCode: 91207,
  City: 'Lauf',
  Latitude: '4.951.597.000',
  Longitude: '1.129.061.000',
  Telephone: '+49 (0) 91238446',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.51597,11.29061',
  id: '49.51597,11.29061'
}, {
  Brand: 'OMV',
  Street: 'Ottenhof 51',
  PostCode: 91287,
  City: 'Plech',
  Latitude: '4.966.647.000',
  Longitude: '1.146.595.000',
  Telephone: '+49-9244-9251015',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.66647,11.46595',
  id: '49.66647,11.46595'
}, {
  Brand: 'Westfalen',
  Street: 'Jöllenbecker 526a',
  PostCode: null,
  City: 'BIELEFELD',
  Latitude: '52.895',
  Longitude: '851.515',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.895,8.51515',
  id: '52.895,8.51515'
}, {
  Brand: 'Eni',
  Street: 'Fuerther Strasse 51',
  PostCode: 90513,
  City: 'Zirndorf',
  Latitude: '4.944.864.858',
  Longitude: '1.096.262.984',
  Telephone: '+49 (0) 91160448',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.44864858,10.96262984',
  id: '49.44864858,10.96262984'
}, {
  Brand: 'OMV',
  Street: 'Köferinger Str. 2',
  PostCode: 92224,
  City: 'Amberg',
  Latitude: '4.943.697.000',
  Longitude: '1.185.764.000',
  Telephone: '+49-9621-82420',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.43697,11.85764',
  id: '49.43697,11.85764'
}, {
  Brand: 'Eni',
  Street: 'Riedfelder Ortsstrasse 2A',
  PostCode: 91413,
  City: 'Neustadt a. d. Aisch',
  Latitude: '4.958.219.894',
  Longitude: '1.059.729.519',
  Telephone: '+49 (0) 91612336',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.58219894,10.59729519',
  id: '49.58219894,10.59729519'
}, {
  Brand: 'ARAL',
  Street: 'A44',
  PostCode: 34289,
  City: 'Zierenberg',
  Latitude: '513.568.504',
  Longitude: '92.789.292',
  Telephone: '56068124',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.3568504,9.2789292',
  id: '51.3568504,9.2789292'
}, {
  Brand: 'TOTAL',
  Street: 'AUGUST-BEBEL-STR. 98',
  PostCode: 36433,
  City: 'BAD SALZUNGEN',
  Latitude: '50.808.279',
  Longitude: '1.025.842',
  Telephone: '03695-622489',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.808279,10.25842',
  id: '50.808279,10.25842'
}, {
  Brand: 'Westfalen',
  Street: 'Lange Str. 100',
  PostCode: null,
  City: 'ESSEN/OLDB.',
  Latitude: '527.159',
  Longitude: '793.839',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.7159,7.93839',
  id: '52.7159,7.93839'
}, {
  Brand: 'ARAL',
  Street: 'Im Mühlenbruch 2',
  PostCode: 53639,
  City: 'Königswinter',
  Latitude: '507.025.600',
  Longitude: '71.771.070',
  Telephone: '22237009181',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.70256,7.177107',
  id: '50.70256,7.177107'
}, {
  Brand: 'Westfalen',
  Street: 'Bocholter Str. 30a',
  PostCode: null,
  City: 'HAMMINKELN',
  Latitude: '517.771',
  Longitude: '661.225',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.7771,6.61225',
  id: '51.7771,6.61225'
}, {
  Brand: 'OMV',
  Street: 'Tannenberger Str. 2',
  PostCode: 86956,
  City: 'Schongau',
  Latitude: '4.780.700.000',
  Longitude: '1.087.044.000',
  Telephone: '+49-8861-2561494',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '47.807,10.87044',
  id: '47.807,10.87044'
}, {
  Brand: 'Eni',
  Street: 'Bahnhofstr./Am Lohpfad 1',
  PostCode: 35260,
  City: 'Stadtallendorf',
  Latitude: '5.082.895.000',
  Longitude: '902.061.000',
  Telephone: '+49 (0) 64289212',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.82895,9.02061',
  id: '50.82895,9.02061'
}, {
  Brand: 'Eni',
  Street: 'Alt Mahlsdorf 94',
  PostCode: 12623,
  City: 'Berlin-Hellersdorf',
  Latitude: '5.250.414.791',
  Longitude: '1.361.981.328',
  Telephone: '+49 (0) 30566781',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '52.50414791,13.61981328',
  id: '52.50414791,13.61981328'
}, {
  Brand: 'TOTAL',
  Street: 'BAHNHOFSTR. 22',
  PostCode: 89168,
  City: 'NIEDERSTOTZINGEN',
  Latitude: '4.853.613',
  Longitude: '10.236.988',
  Telephone: '07325/919070',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.53613,10.236988',
  id: '48.53613,10.236988'
}, {
  Brand: 'OMV',
  Street: 'Nördlinger Str. 9 c',
  PostCode: 91126,
  City: 'Schwabach',
  Latitude: '4.932.183.000',
  Longitude: '1.101.604.000',
  Telephone: '+49-9122-4531',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.32183,11.01604',
  id: '49.32183,11.01604'
}, {
  Brand: 'TOTAL',
  Street: 'LEININGER STR. 39-41',
  PostCode: 67269,
  City: 'GRUENSTADT',
  Latitude: '49.546.928',
  Longitude: '8.159.311',
  Telephone: '06359-92214',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.546928,8.159311',
  id: '49.546928,8.159311'
}, {
  Brand: 'TOTAL',
  Street: 'VELBURGER STR. 1',
  PostCode: 92331,
  City: 'PARSBERG',
  Latitude: '49.162.543',
  Longitude: '11.716.105',
  Telephone: '9492954590',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.162543,11.716105',
  id: '49.162543,11.716105'
}, {
  Brand: 'ARAL',
  Street: 'Pulsnitzer Straße 31',
  PostCode: 1900,
  City: 'Großröhrsdorf',
  Latitude: '511.513.550',
  Longitude: '140.202.870',
  Telephone: '3595231554',
  WeekDayFrom: '05:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '21:00',
  SundayFrom: '06:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.151355,14.020287',
  id: '51.151355,14.020287'
}, {
  Brand: 'TOTAL',
  Street: 'BLOCKSBERGSTR. 55',
  PostCode: 66955,
  City: 'PIRMASENS',
  Latitude: '49.191.867',
  Longitude: '7.599.652',
  Telephone: '06331-75557',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.191867,7.599652',
  id: '49.191867,7.599652'
}, {
  Brand: 'Eni',
  Street: 'Augsburger Strasse 133',
  PostCode: 86633,
  City: 'Neuburg / Donau',
  Latitude: '4.871.954.295',
  Longitude: '1.117.740.875',
  Telephone: '+49 (0) 84311329',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.71954295,11.17740875',
  id: '48.71954295,11.17740875'
}, {
  Brand: 'ARAL',
  Street: 'Im breiten Pfuhl 5',
  PostCode: 67365,
  City: 'Schwegenheim',
  Latitude: '492.719.380',
  Longitude: '83.385.240',
  Telephone: '63445074933',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.271938,8.338524',
  id: '49.271938,8.338524'
}, {
  Brand: 'ARAL',
  Street: 'GREBENSTEINER STRAßE',
  PostCode: 34369,
  City: 'HOFGEISMAR',
  Latitude: '514.814.320',
  Longitude: '93.899.300',
  Telephone: '56715070246',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.481432,9.38993',
  id: '51.481432,9.38993'
}, {
  Brand: 'OMV',
  Street: 'Hauptstr. 84',
  PostCode: 85579,
  City: 'Neubiberg',
  Latitude: '4.807.456.000',
  Longitude: '1.167.741.000',
  Telephone: '+49-89-604970',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.07456,11.67741',
  id: '48.07456,11.67741'
}, {
  Brand: 'Westfalen',
  Street: 'Ahlener Str. 118',
  PostCode: null,
  City: 'HAMM',
  Latitude: '517.075',
  Longitude: '783.246',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.7075,7.83246',
  id: '51.7075,7.83246'
}, {
  Brand: 'Eni',
  Street: 'Schlater Strasse 39',
  PostCode: 73037,
  City: 'Goeppingen',
  Latitude: '4.868.181.000',
  Longitude: '968.773.000',
  Telephone: '+49 (0) 71618118',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.68181,9.68773',
  id: '48.68181,9.68773'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 44',
  PostCode: 88175,
  City: 'Scheidegg',
  Latitude: '475.847.830',
  Longitude: '98.544.330',
  Telephone: '838181717',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '47.584783,9.854433',
  id: '47.584783,9.854433'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 155',
  PostCode: 57518,
  City: 'Alsdorf',
  Latitude: '507.812.660',
  Longitude: '78.906.000',
  Telephone: '27419374781',
  WeekDayFrom: '05:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.781266,7.8906',
  id: '50.781266,7.8906'
}, {
  Brand: 'Eni',
  Street: 'Am Bahnhof 1-10',
  PostCode: 4579,
  City: 'Espenhain',
  Latitude: '5.119.516.092',
  Longitude: '1.246.017.865',
  Telephone: '+49 (0) 34206721',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.19516092,12.46017865',
  id: '51.19516092,12.46017865'
}, {
  Brand: 'Eni',
  Street: 'Radolfzeller Strasse 35',
  PostCode: 78333,
  City: 'Stockach',
  Latitude: '4.784.504.617',
  Longitude: '899.927.077',
  Telephone: '+49 (0) 77712625',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.84504617,8.99927077',
  id: '47.84504617,8.99927077'
}, {
  Brand: 'TOTAL',
  Street: 'RHINSTRASSE 52',
  PostCode: 12681,
  City: 'BERLIN',
  Latitude: '52.518.378',
  Longitude: '13.520.045',
  Telephone: '030-54376256',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.518378,13.520045',
  id: '52.518378,13.520045'
}, {
  Brand: 'Eni',
  Street: 'Nuertinger Strasse 30',
  PostCode: 72661,
  City: 'Grafenberg',
  Latitude: '4.857.369.000',
  Longitude: '931.842.000',
  Telephone: '07123/939610',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.57369,9.31842',
  id: '48.57369,9.31842'
}, {
  Brand: 'TOTAL',
  Street: 'MEMMINGER HAU 1',
  PostCode: 89564,
  City: 'NATTHEIM',
  Latitude: '48.689.388',
  Longitude: '10.213.342',
  Telephone: '82739969887',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.689388,10.213342',
  id: '48.689388,10.213342'
}, {
  Brand: 'Westfalen',
  Street: 'Albersloher Weg 580',
  PostCode: null,
  City: 'MUENSTER',
  Latitude: '519.224',
  Longitude: '7.674',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9224,7.674',
  id: '51.9224,7.674'
}, {
  Brand: 'TOTAL',
  Street: 'MUENDELHEIMER STR. 106',
  PostCode: 47259,
  City: 'DUISBURG',
  Latitude: '5.136.991',
  Longitude: '6.739.864',
  Telephone: '0203-781405',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.36991,6.739864',
  id: '51.36991,6.739864'
}, {
  Brand: 'Eni',
  Street: 'Salzburger Strasse 1 b',
  PostCode: 83278,
  City: 'Traunstein',
  Latitude: '4.786.902.000',
  Longitude: '1.266.856.000',
  Telephone: '08611/2512',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.86902,12.66856',
  id: '47.86902,12.66856'
}, {
  Brand: 'TOTAL',
  Street: 'AM REMBERG 94',
  PostCode: 44269,
  City: 'DORTMUND',
  Latitude: '51.493.702',
  Longitude: '7.520.649',
  Telephone: '0231-42786733',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.493702,7.520649',
  id: '51.493702,7.520649'
}, {
  Brand: 'TOTAL',
  Street: 'STORLACHSTR. 2',
  PostCode: 72760,
  City: 'REUTLINGEN',
  Latitude: '4.850.397',
  Longitude: '921.235',
  Telephone: '07121-334290',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.50397,9.21235',
  id: '48.50397,9.21235'
}, {
  Brand: 'ARAL',
  Street: 'Jungenthaler Straße 75',
  PostCode: 57548,
  City: 'Kirchen',
  Latitude: '508.109.480',
  Longitude: '78.648.850',
  Telephone: '27417278',
  WeekDayFrom: '05:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.810948,7.864885',
  id: '50.810948,7.864885'
}, {
  Brand: 'Eni',
  Street: 'Regensburger Str. 65',
  PostCode: 85055,
  City: 'Ingolstadt',
  Latitude: '4.876.782.000',
  Longitude: '1.144.751.000',
  Telephone: '+49 (0) 84158198',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.76782,11.44751',
  id: '48.76782,11.44751'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 11',
  PostCode: 83128,
  City: 'Halfing',
  Latitude: '479.526.980',
  Longitude: '122.789.750',
  Telephone: '80558765',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '17:00',
  SundayFrom: '09:00',
  SundayTo: '14:00',
  IsOpen24Hours: 'Nein',
  location: '47.952698,12.278975',
  id: '47.952698,12.278975'
}, {
  Brand: 'ARAL',
  Street: 'A115',
  PostCode: 14055,
  City: 'Berlin',
  Latitude: '525.018.390',
  Longitude: '132.781.170',
  Telephone: '303024929',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:59',
  SaturdayFrom: '07:00',
  SaturdayTo: '16:59',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.501839,13.278117',
  id: '52.501839,13.278117'
}, {
  Brand: 'TOTAL',
  Street: 'GRUNDSTR. 99',
  PostCode: 1326,
  City: 'DRESDEN',
  Latitude: '51.056.899',
  Longitude: '13.821.686',
  Telephone: '0351-2683294',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.056899,13.821686',
  id: '51.056899,13.821686'
}, {
  Brand: 'TOTAL',
  Street: 'KIELER STR. 387-391',
  PostCode: 24536,
  City: 'NEUMUENSTER',
  Latitude: '54.102.776',
  Longitude: '9.990.301',
  Telephone: '04321-31282',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.102776,9.990301',
  id: '54.102776,9.990301'
}, {
  Brand: 'TOTAL',
  Street: 'SPREMBERGER STR. 30',
  PostCode: 3103,
  City: 'NEUPETERSHAIN',
  Latitude: '51.585.799',
  Longitude: '14.172.787',
  Telephone: '035751-2100',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.585799,14.172787',
  id: '51.585799,14.172787'
}, {
  Brand: 'OMV',
  Street: 'Bruckwiesen 12',
  PostCode: 91088,
  City: 'Bubenreuth',
  Latitude: '4.962.808.000',
  Longitude: '1.100.925.000',
  Telephone: '+49-9131-9742930',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.62808,11.00925',
  id: '49.62808,11.00925'
}, {
  Brand: 'TOTAL',
  Street: 'AM HELLENKREUZ 1',
  PostCode: 53332,
  City: 'BORNHEIM',
  Latitude: '50.762.397',
  Longitude: '6.975.811',
  Telephone: '02222-9891379',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.762397,6.975811',
  id: '50.762397,6.975811'
}, {
  Brand: 'TOTAL',
  Street: 'LINDENSTR. 9-10',
  PostCode: 17358,
  City: 'TORGELOW',
  Latitude: '53.629.923',
  Longitude: '13.999.691',
  Telephone: '03976-202336',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.629923,13.999691',
  id: '53.629923,13.999691'
}, {
  Brand: 'TOTAL',
  Street: 'BERLINER CH. 103',
  PostCode: 39288,
  City: 'BURG',
  Latitude: '52.273.381',
  Longitude: '11.872.191',
  Telephone: '03921-944029',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.273381,11.872191',
  id: '52.273381,11.872191'
}, {
  Brand: 'Eni',
  Street: 'Ludwigsburger Str. 29-33',
  PostCode: 74343,
  City: 'Sachsenheim',
  Latitude: '4.895.836.000',
  Longitude: '907.123.000',
  Telephone: '+49 (0) 71477621',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.95836,9.07123',
  id: '48.95836,9.07123'
}, {
  Brand: 'ARAL',
  Street: 'Fittingstraße 99',
  PostCode: 78224,
  City: 'Singen',
  Latitude: '477.541.340',
  Longitude: '88.526.830',
  Telephone: '77319269262',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.754134,8.852683',
  id: '47.754134,8.852683'
}, {
  Brand: 'Eni',
  Street: 'Godesberger Allee 63-65',
  PostCode: 53175,
  City: 'Bonn',
  Latitude: '5.069.702.000',
  Longitude: '714.142.000',
  Telephone: '+49 (0) 22837403',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.69702,7.14142',
  id: '50.69702,7.14142'
}, {
  Brand: 'TOTAL',
  Street: 'LAUFAMHOLZSTR. 146',
  PostCode: 90482,
  City: 'NUERNBERG',
  Latitude: '49.465.195',
  Longitude: '11.152.628',
  Telephone: '0911-501968',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.465195,11.152628',
  id: '49.465195,11.152628'
}, {
  Brand: 'Eni',
  Street: 'Nuernberger Str. 45',
  PostCode: 91154,
  City: 'Roth',
  Latitude: '4.924.995.000',
  Longitude: '1.108.998.000',
  Telephone: '+49 (0) 91716362',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.24995,11.08998',
  id: '49.24995,11.08998'
}, {
  Brand: 'Eni',
  Street: 'Darmstaedter Strasse 136',
  PostCode: 64625,
  City: 'Bensheim',
  Latitude: '4.968.325.206',
  Longitude: '862.170.914',
  Telephone: '+49 (0) 62519837',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.68325206,8.62170914',
  id: '49.68325206,8.62170914'
}, {
  Brand: 'Eni',
  Street: 'Heilbronner Str. 390',
  PostCode: 70469,
  City: 'Stuttgart',
  Latitude: '4.880.442.000',
  Longitude: '918.345.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.80442,9.18345',
  id: '48.80442,9.18345'
}, {
  Brand: 'TOTAL',
  Street: 'BERLINER STR. 123',
  PostCode: 14641,
  City: 'NAUEN',
  Latitude: '5.259.567',
  Longitude: '12.887.093',
  Telephone: '03321-455369',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.59567,12.887093',
  id: '52.59567,12.887093'
}, {
  Brand: 'ARAL',
  Street: 'Brannenburger Straße 12',
  PostCode: 83131,
  City: 'Nußdorf',
  Latitude: '477.433.580',
  Longitude: '121.542.070',
  Telephone: '8034904618',
  WeekDayFrom: '07:30',
  WeekDayTo: '18:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '18:00',
  SundayFrom: '08:00',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '47.743358,12.154207',
  id: '47.743358,12.154207'
}, {
  Brand: 'TOTAL',
  Street: 'KOELNER STR. 451',
  PostCode: 45481,
  City: 'MUELHEIM',
  Latitude: '51.363.467',
  Longitude: '6.863.279',
  Telephone: '0208-480146',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.363467,6.863279',
  id: '51.363467,6.863279'
}, {
  Brand: 'ARAL',
  Street: 'Albert-Einstein-Straße',
  PostCode: 31135,
  City: 'Hildesheim',
  Latitude: '521.603.120',
  Longitude: '99.973.760',
  Telephone: '51216908322',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.160312,9.997376',
  id: '52.160312,9.997376'
}, {
  Brand: 'ARAL',
  Street: 'Im Emscherbruch 10',
  PostCode: 45699,
  City: 'Herten',
  Latitude: '515.544.330',
  Longitude: '71.634.810',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.554433,7.163481',
  id: '51.554433,7.163481'
}, {
  Brand: 'OMV',
  Street: 'Münchener Str. 13',
  PostCode: 85604,
  City: 'Zorneding',
  Latitude: '4.808.304.000',
  Longitude: '1.182.219.000',
  Telephone: '+49-8106-247110',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.08304,11.82219',
  id: '48.08304,11.82219'
}, {
  Brand: 'TOTAL',
  Street: 'HARTENSTEINER STR. 11',
  PostCode: 8297,
  City: 'ZWOENITZ',
  Latitude: '50.634.353',
  Longitude: '12.794.047',
  Telephone: '037754-5536',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.634353,12.794047',
  id: '50.634353,12.794047'
}, {
  Brand: 'ARAL',
  Street: 'Monschauer Straße 33',
  PostCode: 41068,
  City: 'Mönchengladbach',
  Latitude: '511.928.400',
  Longitude: '64.090.790',
  Telephone: '21614770840',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.19284,6.409079',
  id: '51.19284,6.409079'
}, {
  Brand: 'ARAL',
  Street: 'Europa-Allee 75',
  PostCode: 54343,
  City: 'Föhren',
  Latitude: '498.634.700',
  Longitude: '67.918.180',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.86347,6.791818',
  id: '49.86347,6.791818'
}, {
  Brand: 'ARAL',
  Street: 'Grenzallee 6',
  PostCode: 12057,
  City: 'Berlin',
  Latitude: '524.690.420',
  Longitude: '134.618.750',
  Telephone: '3063227487',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.469042,13.461875',
  id: '52.469042,13.461875'
}, {
  Brand: 'ARAL',
  Street: 'Max-Planck-Str. 28',
  PostCode: 50354,
  City: 'Hürth',
  Latitude: '508.846.540',
  Longitude: '69.069.170',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.884654,6.906917',
  id: '50.884654,6.906917'
}, {
  Brand: 'OMV',
  Street: 'Äußere Parkstr. 21',
  PostCode: 84032,
  City: 'Altdorf',
  Latitude: '4.854.692.000',
  Longitude: '1.211.249.000',
  Telephone: '+49-871-35904',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.54692,12.11249',
  id: '48.54692,12.11249'
}, {
  Brand: 'TOTAL',
  Street: 'BLUMENAUER STR.9',
  PostCode: 9526,
  City: 'OLBERNHAU',
  Latitude: '50.662.199',
  Longitude: '13.329.181',
  Telephone: '037360-74280',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.662199,13.329181',
  id: '50.662199,13.329181'
}, {
  Brand: 'ARAL',
  Street: 'Tilsiter Straße 15',
  PostCode: 71065,
  City: 'Sindelfingen',
  Latitude: '487.031.600',
  Longitude: '90.298.660',
  Telephone: '7031633658',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '22:00',
  SundayFrom: '00:00',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.70316,9.029866',
  id: '48.70316,9.029866'
}, {
  Brand: 'TOTAL',
  Street: 'WILLY-BRANDT-STR. 206',
  PostCode: 46535,
  City: 'DINSLAKEN',
  Latitude: '51.549.561',
  Longitude: '6.728.918',
  Telephone: '02064-732480',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.549561,6.728918',
  id: '51.549561,6.728918'
}, {
  Brand: 'Westfalen',
  Street: 'Augustdorfer Str. 67',
  PostCode: null,
  City: 'DETMOLD',
  Latitude: '519.377',
  Longitude: '877.863',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9377,8.77863',
  id: '51.9377,8.77863'
}, {
  Brand: 'Westfalen',
  Street: 'Nienborger Str. 28',
  PostCode: null,
  City: 'GRONAU',
  Latitude: '521.757',
  Longitude: '704.898',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.1757,7.04898',
  id: '52.1757,7.04898'
}, {
  Brand: 'Westfalen',
  Street: 'In der Geist 110',
  PostCode: null,
  City: 'OELDE',
  Latitude: '518.119',
  Longitude: '813.451',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8119,8.13451',
  id: '51.8119,8.13451'
}, {
  Brand: 'TOTAL',
  Street: '13 RUDOLSTAEDTER STR.',
  PostCode: 7318,
  City: 'SAALFELD',
  Latitude: '50.657.195',
  Longitude: '11.347.568',
  Telephone: '03671-2264',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.657195,11.347568',
  id: '50.657195,11.347568'
}, {
  Brand: 'Westfalen',
  Street: 'Zum Eistruper Feld 2',
  PostCode: null,
  City: 'BISSENDORF',
  Latitude: '522.398',
  Longitude: '816.133',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.2398,8.16133',
  id: '52.2398,8.16133'
}, {
  Brand: 'TOTAL',
  Street: 'DR.-LEBER-STR. 8',
  PostCode: 23966,
  City: 'WISMAR',
  Latitude: '53.889.796',
  Longitude: '11.471.355',
  Telephone: '03841-40523',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.889796,11.471355',
  id: '53.889796,11.471355'
}, {
  Brand: 'ARAL',
  Street: 'Friedrichshafener Straße 73',
  PostCode: 88214,
  City: 'Ravensburg',
  Latitude: '477.385.710',
  Longitude: '95.983.750',
  Telephone: '75162384',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.738571,9.598375',
  id: '47.738571,9.598375'
}, {
  Brand: 'ARAL',
  Street: 'Schalmeistraße 2',
  PostCode: 86399,
  City: 'Bobingen',
  Latitude: '482.791.620',
  Longitude: '108.407.430',
  Telephone: '82348771',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.279162,10.840743',
  id: '48.279162,10.840743'
}, {
  Brand: 'Eni',
  Street: 'Ingolstaedter Str. 44 A',
  PostCode: 85072,
  City: 'Eichstaett',
  Latitude: '4.888.202.000',
  Longitude: '1.119.653.000',
  Telephone: '+49 (0) 84212865',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.88202,11.19653',
  id: '48.88202,11.19653'
}, {
  Brand: 'ARAL',
  Street: 'BAB A 19 - Ost',
  PostCode: 18276,
  City: 'Recknitz',
  Latitude: '538.399.430',
  Longitude: '122.878.090',
  Telephone: '3845520952',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.839943,12.287809',
  id: '53.839943,12.287809'
}, {
  Brand: 'ARAL',
  Street: 'Südstraße 74',
  PostCode: 71404,
  City: 'Korb',
  Latitude: '488.384.090',
  Longitude: '93.660.560',
  Telephone: '715131100',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '14:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.838409,9.366056',
  id: '48.838409,9.366056'
}, {
  Brand: 'Eni',
  Street: 'Neureuter Str. 5',
  PostCode: 76185,
  City: 'Karlsruhe',
  Latitude: '4.901.733.000',
  Longitude: '835.405.000',
  Telephone: '+49 (0) 72156801',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.01733,8.35405',
  id: '49.01733,8.35405'
}, {
  Brand: 'ARAL',
  Street: 'Moerser Straße 470-472',
  PostCode: 47475,
  City: 'Kamp-Lintfort',
  Latitude: '514.991.490',
  Longitude: '65.681.160',
  Telephone: '284293567',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.499149,6.568116',
  id: '51.499149,6.568116'
}, {
  Brand: 'ARAL',
  Street: 'A67',
  PostCode: 64319,
  City: 'Pfungstadt',
  Latitude: '498.134.220',
  Longitude: '85.780.550',
  Telephone: '615785531',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.813422,8.578055',
  id: '49.813422,8.578055'
}, {
  Brand: 'ARAL',
  Street: 'Robert-Bosch-Straße 40',
  PostCode: 88131,
  City: 'Lindau',
  Latitude: '475.476.940',
  Longitude: '97.280.790',
  Telephone: '838278767',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.547694,9.728079',
  id: '47.547694,9.728079'
}, {
  Brand: 'ARAL',
  Street: 'Im Schiffelland 24',
  PostCode: 66386,
  City: 'St. Ingbert',
  Latitude: '492.692.970',
  Longitude: '71.307.150',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.269297,7.130715',
  id: '49.269297,7.130715'
}, {
  Brand: 'ARAL',
  Street: 'Darmstädter Straße 2',
  PostCode: 64319,
  City: 'Pfungstadt',
  Latitude: '498.074.230',
  Longitude: '85.641.810',
  Telephone: '61579896791',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.807423,8.564181',
  id: '49.807423,8.564181'
}, {
  Brand: 'ARAL',
  Street: 'An der Aspe 1',
  PostCode: 36137,
  City: 'Großenlüder',
  Latitude: '505.814.690',
  Longitude: '95.438.570',
  Telephone: '66487128',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.581469,9.543857',
  id: '50.581469,9.543857'
}, {
  Brand: 'TOTAL',
  Street: 'BOTTROPER STR. 248',
  PostCode: 45964,
  City: 'GLADBECK',
  Latitude: '51.566.174',
  Longitude: '6.964.711',
  Telephone: '02043-489074',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.566174,6.964711',
  id: '51.566174,6.964711'
}, {
  Brand: 'ARAL',
  Street: 'Am Nienhaushof 18',
  PostCode: 47139,
  City: 'Duisburg',
  Latitude: '514.772.640',
  Longitude: '67.195.090',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.477264,6.719509',
  id: '51.477264,6.719509'
}, {
  Brand: 'Eni',
  Street: 'Dammmuehlenweg',
  PostCode: 39340,
  City: 'Haldensleben',
  Latitude: '5.227.768.883',
  Longitude: '1.144.230.902',
  Telephone: '+49 (0) 39044102',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '52.27768883,11.44230902',
  id: '52.27768883,11.44230902'
}, {
  Brand: 'OMV',
  Street: 'Ettlinger Str. 2',
  PostCode: 76337,
  City: 'Waldbronn',
  Latitude: '4.893.094.000',
  Longitude: '845.619.000',
  Telephone: '+49-7243-572557',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.93094,8.45619',
  id: '48.93094,8.45619'
}, {
  Brand: 'Westfalen',
  Street: 'Strangenhäuschen 10',
  PostCode: null,
  City: 'AACHEN',
  Latitude: '508.018',
  Longitude: '611.078',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.8018,6.11078',
  id: '50.8018,6.11078'
}, {
  Brand: 'Eni',
  Street: 'Kaiser-Friedrich-Str. 1a',
  PostCode: 35619,
  City: 'Braunfels/Lahn',
  Latitude: '5.051.772.000',
  Longitude: '839.122.000',
  Telephone: '06442/9320047',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.51772,8.39122',
  id: '50.51772,8.39122'
}, {
  Brand: 'ARAL',
  Street: 'Freiburger Strasse 9',
  PostCode: 79189,
  City: 'Bad Krozingen',
  Latitude: '479.186.930',
  Longitude: '77.035.400',
  Telephone: '76333536',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.918693,7.70354',
  id: '47.918693,7.70354'
}, {
  Brand: 'OMV',
  Street: 'Bannholzstr. 31',
  PostCode: 94065,
  City: 'Waldkirchen',
  Latitude: '4.873.223.000',
  Longitude: '1.360.869.000',
  Telephone: '+49-8581-96480',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.73223,13.60869',
  id: '48.73223,13.60869'
}, {
  Brand: 'TOTAL',
  Street: '72 ROSTOCKER STR .',
  PostCode: 18190,
  City: 'SANITZ',
  Latitude: '5.407.774',
  Longitude: '12.367.252',
  Telephone: '038209-308',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.07774,12.367252',
  id: '54.07774,12.367252'
}, {
  Brand: 'TOTAL',
  Street: 'PAFFRATHER STR. 215',
  PostCode: 51469,
  City: 'BERGISCH GLADBACH',
  Latitude: '50.995.278',
  Longitude: '7.109.758',
  Telephone: '02202-920265',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.995278,7.109758',
  id: '50.995278,7.109758'
}, {
  Brand: 'TOTAL',
  Street: 'JULIUS-LEBER-STR. 2c',
  PostCode: 53123,
  City: 'BONN',
  Latitude: '50.709.315',
  Longitude: '7.054.633',
  Telephone: '0228-96285704',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.709315,7.054633',
  id: '50.709315,7.054633'
}, {
  Brand: 'ARAL',
  Street: 'Carl-Schurz-Straße 160',
  PostCode: 50374,
  City: 'Erftstadt',
  Latitude: '508.103.980',
  Longitude: '68.085.120',
  Telephone: '223545711',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.810398,6.808512',
  id: '50.810398,6.808512'
}, {
  Brand: 'OMV',
  Street: 'Bamberger Str. 54',
  PostCode: 96149,
  City: 'Breitengüssbach',
  Latitude: '4.996.695.000',
  Longitude: '1.088.423.000',
  Telephone: '+49-9544-950348',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.96695,10.88423',
  id: '49.96695,10.88423'
}, {
  Brand: 'ARAL',
  Street: 'Manfred-von-Ardenne-Allee 2',
  PostCode: 71522,
  City: 'Backnang',
  Latitude: '489.592.110',
  Longitude: '94.231.260',
  Telephone: '7191345500',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.959211,9.423126',
  id: '48.959211,9.423126'
}, {
  Brand: 'ARAL',
  Street: 'Siemensstr. 5',
  PostCode: 42551,
  City: 'Velbert',
  Latitude: '513.478.620',
  Longitude: '70.632.370',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.347862,7.063237',
  id: '51.347862,7.063237'
}, {
  Brand: 'ARAL',
  Street: 'Kieler Straße 51-53',
  PostCode: 24568,
  City: 'Kaltenkirchen',
  Latitude: '538.407.260',
  Longitude: '99.476.190',
  Telephone: '41919528830',
  WeekDayFrom: '04:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '53.840726,9.947619',
  id: '53.840726,9.947619'
}, {
  Brand: 'ARAL',
  Street: 'Im Höning',
  PostCode: 63820,
  City: 'Elsenfeld',
  Latitude: '498.560.720',
  Longitude: '91.677.020',
  Telephone: '60225062265',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.856072,9.167702',
  id: '49.856072,9.167702'
}, {
  Brand: 'ARAL',
  Street: 'Landstraße 16',
  PostCode: 76547,
  City: 'Sinzheim',
  Latitude: '487.601.110',
  Longitude: '81.688.600',
  Telephone: '72218035625',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.760111,8.16886',
  id: '48.760111,8.16886'
}, {
  Brand: 'TOTAL',
  Street: 'PORTASTR. 44',
  PostCode: 32457,
  City: 'PORTA WESTFALICA',
  Latitude: '52.253.158',
  Longitude: '8.909.657',
  Telephone: '0571-51142',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.253158,8.909657',
  id: '52.253158,8.909657'
}, {
  Brand: 'TOTAL',
  Street: 'SAARSTR. 6',
  PostCode: 55122,
  City: 'MAINZ',
  Latitude: '49.997.709',
  Longitude: '8.253.618',
  Telephone: '06131-381434',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.997709,8.253618',
  id: '49.997709,8.253618'
}, {
  Brand: 'TOTAL',
  Street: 'WENSCHTSTR. 7',
  PostCode: 57078,
  City: 'SIEGEN',
  Latitude: '50.912.509',
  Longitude: '8.007.419',
  Telephone: '0271-74113512',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.912509,8.007419',
  id: '50.912509,8.007419'
}, {
  Brand: 'Westfalen',
  Street: 'Kurt-Schumacher Str. 236',
  PostCode: null,
  City: 'DINSLAKEN',
  Latitude: '515.575',
  Longitude: '679.647',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.5575,6.79647',
  id: '51.5575,6.79647'
}, {
  Brand: 'Westfalen',
  Street: 'Hannoversche Str. 20',
  PostCode: null,
  City: 'OSNABRUECK',
  Latitude: '52.264',
  Longitude: '806.611',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.264,8.06611',
  id: '52.264,8.06611'
}, {
  Brand: 'TOTAL',
  Street: 'WARNOWUFER 53',
  PostCode: 18057,
  City: 'ROSTOCK',
  Latitude: '54.093.524',
  Longitude: '12.115.502',
  Telephone: '0381-4903119',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.093524,12.115502',
  id: '54.093524,12.115502'
}, {
  Brand: 'ARAL',
  Street: 'Peter-Henlein-Str.',
  PostCode: 89312,
  City: 'Günzburg',
  Latitude: '484.734.330',
  Longitude: '102.733.520',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.473433,10.273352',
  id: '48.473433,10.273352'
}, {
  Brand: 'Westfalen',
  Street: 'Toepferstrasse 2',
  PostCode: null,
  City: 'WACHTBERG',
  Latitude: '506.128',
  Longitude: '705.347',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.6128,7.05347',
  id: '50.6128,7.05347'
}, {
  Brand: 'TOTAL',
  Street: 'MAGDEBURGER STR. 84',
  PostCode: 39261,
  City: 'ZERBST',
  Latitude: '51.975.019',
  Longitude: '12.066.033',
  Telephone: '03923-780437',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.975019,12.066033',
  id: '51.975019,12.066033'
}, {
  Brand: 'ARAL',
  Street: 'Neuenhöhe 20',
  PostCode: 42929,
  City: 'Wermelskirchen',
  Latitude: '511.461.570',
  Longitude: '72.360.310',
  Telephone: '2196731850',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.146157,7.236031',
  id: '51.146157,7.236031'
}, {
  Brand: 'TOTAL',
  Street: '1 MOORREDDER',
  PostCode: 23570,
  City: 'LUEBECK',
  Latitude: '53.962.734',
  Longitude: '10.861.101',
  Telephone: '04502-3510',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.962734,10.861101',
  id: '53.962734,10.861101'
}, {
  Brand: 'TOTAL',
  Street: 'HAFENSTR. 41',
  PostCode: 55276,
  City: 'OPPENHEIM',
  Latitude: '49.860.597',
  Longitude: '8.356.819',
  Telephone: '06133-9427',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.860597,8.356819',
  id: '49.860597,8.356819'
}, {
  Brand: 'ARAL',
  Street: 'Zehentbauernstraße 7',
  PostCode: 81539,
  City: 'München',
  Latitude: '481.142.780',
  Longitude: '115.762.830',
  Telephone: '896972174',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:45',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:45',
  SundayFrom: '07:00',
  SundayTo: '23:45',
  IsOpen24Hours: 'Nein',
  location: '48.114278,11.576283',
  id: '48.114278,11.576283'
}, {
  Brand: 'Eni',
  Street: 'Bayreuther Str. 26',
  PostCode: 92237,
  City: 'Sulzbach-Rosenberg',
  Latitude: '4.950.422.000',
  Longitude: '1.174.321.000',
  Telephone: '+49 (0) 96615313',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.50422,11.74321',
  id: '49.50422,11.74321'
}, {
  Brand: 'TOTAL',
  Street: 'MAGDEBURGER STR. 39-45',
  PostCode: 38820,
  City: 'HALBERSTADT',
  Latitude: '5.189.803',
  Longitude: '11.063.398',
  Telephone: '03941-600139',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.89803,11.063398',
  id: '51.89803,11.063398'
}, {
  Brand: 'TOTAL',
  Street: 'GUTENBERGSTRASSE 2',
  PostCode: 87781,
  City: 'UNGERHAUSEN',
  Latitude: '48.018.255',
  Longitude: '10.273.633',
  Telephone: '08393-9439170',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.018255,10.273633',
  id: '48.018255,10.273633'
}, {
  Brand: 'ARAL',
  Street: 'Halberstädter Str. 9',
  PostCode: 38644,
  City: 'Goslar',
  Latitude: '519.184.970',
  Longitude: '104.928.410',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.918497,10.492841',
  id: '51.918497,10.492841'
}, {
  Brand: 'TOTAL',
  Street: 'BAB BOERDE SUED / A2',
  PostCode: 39343,
  City: 'GROSS SANTERSLEBEN',
  Latitude: '52.184.845',
  Longitude: '1.144.895',
  Telephone: '039206-68764',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.184845,11.44895',
  id: '52.184845,11.44895'
}, {
  Brand: 'TOTAL',
  Street: 'BAB 4 / ALTENBURGER LAND-SUED',
  PostCode: 4626,
  City: 'SCHMOELLN OT WEISSBACH',
  Latitude: '50.856.747',
  Longitude: '12.313.485',
  Telephone: '034491/554813',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.856747,12.313485',
  id: '50.856747,12.313485'
}, {
  Brand: 'ARAL',
  Street: 'Ulmer Straße 39',
  PostCode: 73066,
  City: 'Uhingen',
  Latitude: '487.073.010',
  Longitude: '95.897.910',
  Telephone: '71619838726',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.707301,9.589791',
  id: '48.707301,9.589791'
}, {
  Brand: 'ARAL',
  Street: 'Bezirksstraße 16',
  PostCode: 67122,
  City: 'Altrip',
  Latitude: '494.349.660',
  Longitude: '84.845.310',
  Telephone: '62362685',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '49.434966,8.484531',
  id: '49.434966,8.484531'
}, {
  Brand: 'ARAL',
  Street: 'Innstraße 16',
  PostCode: 84453,
  City: 'Mühldorf',
  Latitude: '482.417.770',
  Longitude: '125.192.610',
  Telephone: '86319866933',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.241777,12.519261',
  id: '48.241777,12.519261'
}, {
  Brand: 'Westfalen',
  Street: 'Rheinberger Str. 306',
  PostCode: null,
  City: 'MOERS',
  Latitude: '51.488',
  Longitude: '663.318',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.488,6.63318',
  id: '51.488,6.63318'
}, {
  Brand: 'Eni',
  Street: 'Bayreuther Str. 22',
  PostCode: 91301,
  City: 'Forchheim',
  Latitude: '4.971.588.107',
  Longitude: '1.107.725.804',
  Telephone: '+49 (0) 91917277',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.71588107,11.07725804',
  id: '49.71588107,11.07725804'
}, {
  Brand: 'TOTAL',
  Street: '15 PROBSTZELLAER STR.',
  PostCode: 98743,
  City: 'GRAEFENTHAL',
  Latitude: '50.527.265',
  Longitude: '11.312.678',
  Telephone: '036703-80739',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.527265,11.312678',
  id: '50.527265,11.312678'
}, {
  Brand: 'Eni',
  Street: 'Am Gewerbepark 6',
  PostCode: 90552,
  City: 'Roethenbach',
  Latitude: '4.945.043.000',
  Longitude: '1.108.068.000',
  Telephone: '0911/54099439',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.45043,11.08068',
  id: '49.45043,11.08068'
}, {
  Brand: 'Eni',
  Street: 'Oelsnitzer Str.',
  PostCode: 8626,
  City: 'Adorf',
  Latitude: '5.032.649.610',
  Longitude: '1.225.652.487',
  Telephone: '+49 (0) 37423229',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.3264961,12.25652487',
  id: '50.3264961,12.25652487'
}, {
  Brand: 'Eni',
  Street: 'Muenchner Str. 121A',
  PostCode: 82008,
  City: 'Unterhaching',
  Latitude: '4.807.417.955',
  Longitude: '1.160.450.351',
  Telephone: '+49 (0) 89617338',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.07417955,11.60450351',
  id: '48.07417955,11.60450351'
}, {
  Brand: 'Westfalen',
  Street: 'Vardingholter Str. 10',
  PostCode: null,
  City: 'RHEDE',
  Latitude: '518.443',
  Longitude: '669.483',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8443,6.69483',
  id: '51.8443,6.69483'
}, {
  Brand: 'OMV',
  Street: 'An den Sandwellen 1',
  PostCode: 93326,
  City: 'Abensberg',
  Latitude: '4.881.087.000',
  Longitude: '1.185.896.000',
  Telephone: '+49-9443-905423',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.81087,11.85896',
  id: '48.81087,11.85896'
}, {
  Brand: 'Eni',
  Street: 'Laibacher Weg 1',
  PostCode: 95469,
  City: 'Speichersdorf',
  Latitude: '4.987.047.000',
  Longitude: '1.177.287.000',
  Telephone: '09275/6059362',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.87047,11.77287',
  id: '49.87047,11.77287'
}, {
  Brand: 'ARAL',
  Street: 'Forchheimer Straße 44',
  PostCode: 91320,
  City: 'Ebermannstadt',
  Latitude: '497.769.010',
  Longitude: '111.778.960',
  Telephone: '9194737016',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.776901,11.177896',
  id: '49.776901,11.177896'
}, {
  Brand: 'Westfalen',
  Street: 'Riesenbecker Str. 27',
  PostCode: null,
  City: 'HOESTEL',
  Latitude: '522.715',
  Longitude: '758.977',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.2715,7.58977',
  id: '52.2715,7.58977'
}, {
  Brand: 'Eni',
  Street: 'Rosenauer Strasse 6',
  PostCode: 94481,
  City: 'Grafenau',
  Latitude: '4.885.963.818',
  Longitude: '1.339.841.438',
  Telephone: '+49 (0) 85523488',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.85963818,13.39841438',
  id: '48.85963818,13.39841438'
}, {
  Brand: 'ARAL',
  Street: 'Nikola-Tesla-Straße 1',
  PostCode: 77955,
  City: 'Ettenheim',
  Latitude: '482.747.960',
  Longitude: '77.855.640',
  Telephone: '07822/3005688',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.274796,7.785564',
  id: '48.274796,7.785564'
}, {
  Brand: 'Westfalen',
  Street: 'Steinfurter Str. 24',
  PostCode: null,
  City: 'ASCHEBERG',
  Latitude: '517.926',
  Longitude: '762.922',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.7926,7.62922',
  id: '51.7926,7.62922'
}, {
  Brand: 'ARAL',
  Street: 'Ferdinand-Porsche-Str. 9',
  PostCode: 60386,
  City: 'Frankfurt',
  Latitude: '501.219.410',
  Longitude: '87.570.440',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.121941,8.757044',
  id: '50.121941,8.757044'
}, {
  Brand: 'Westfalen',
  Street: 'Ennigerloher Str. 13',
  PostCode: null,
  City: 'BECKUM',
  Latitude: '518.062',
  Longitude: '8.0256',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8062,8.0256',
  id: '51.8062,8.0256'
}, {
  Brand: 'TOTAL',
  Street: 'HANS-GUENTER-BOCK-STR. 8',
  PostCode: 16798,
  City: 'FUERSTENBERG',
  Latitude: '5.317.169',
  Longitude: '13.140.833',
  Telephone: '033093-32106',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.17169,13.140833',
  id: '53.17169,13.140833'
}, {
  Brand: 'Westfalen',
  Street: 'Bohlweg 70-72',
  PostCode: null,
  City: 'MUENSTER',
  Latitude: '519.673',
  Longitude: '764.306',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9673,7.64306',
  id: '51.9673,7.64306'
}, {
  Brand: 'Eni',
  Street: 'Halberstaedter Chaussee 25',
  PostCode: 39116,
  City: 'Magdeburg',
  Latitude: '5.208.910.000',
  Longitude: '1.156.870.000',
  Telephone: '+49 (0) 39163133',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '52.0891,11.5687',
  id: '52.0891,11.5687'
}, {
  Brand: 'Westfalen',
  Street: 'Roisdorfer Str.',
  PostCode: null,
  City: 'BORNHEIM',
  Latitude: '50.761',
  Longitude: '702.631',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.761,7.02631',
  id: '50.761,7.02631'
}, {
  Brand: 'TOTAL',
  Street: 'BRUNOSTRASSE 34',
  PostCode: 54329,
  City: 'KONZ',
  Latitude: '49.710.636',
  Longitude: '6.591.084',
  Telephone: '6501607546',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.710636,6.591084',
  id: '49.710636,6.591084'
}, {
  Brand: 'ARAL',
  Street: 'Hohberger Straße 19',
  PostCode: 71106,
  City: 'Magstadt',
  Latitude: '487.386.010',
  Longitude: '89.662.610',
  Telephone: '715994740',
  WeekDayFrom: '07:00',
  WeekDayTo: '19:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '14:00',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.738601,8.966261',
  id: '48.738601,8.966261'
}, {
  Brand: 'OMV',
  Street: 'Nürnberger Strasse 17 a',
  PostCode: 92237,
  City: 'Sulzbach-Rosenberg',
  Latitude: '4.950.604.000',
  Longitude: '1.172.953.000',
  Telephone: '+49-9661-4345',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.50604,11.72953',
  id: '49.50604,11.72953'
}, {
  Brand: 'OMV',
  Street: 'Bahnhofstr. 5',
  PostCode: 97769,
  City: 'Bad Brückenau',
  Latitude: '5.030.738.000',
  Longitude: '978.652.000',
  Telephone: '+49-9741-2525',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.30738,9.78652',
  id: '50.30738,9.78652'
}, {
  Brand: 'TOTAL',
  Street: 'BAB HOLMMOORWEG WEST/A 7',
  PostCode: 25451,
  City: 'QUICKBORN',
  Latitude: '53.717.743',
  Longitude: '9.937.974',
  Telephone: '04106-3939',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.717743,9.937974',
  id: '53.717743,9.937974'
}, {
  Brand: 'TOTAL',
  Street: 'RHINSTRASSE 175',
  PostCode: 13053,
  City: 'BERLIN',
  Latitude: '52.536.691',
  Longitude: '1.351.541',
  Telephone: '030-9824536',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.536691,13.51541',
  id: '52.536691,13.51541'
}, {
  Brand: 'ARAL',
  Street: 'Hans-Böckler-Allee 33',
  PostCode: 30173,
  City: 'Hannover',
  Latitude: '523.713.920',
  Longitude: '97.737.650',
  Telephone: '5118112116',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.371392,9.773765',
  id: '52.371392,9.773765'
}, {
  Brand: 'TOTAL',
  Street: 'BERLINER STR. 23',
  PostCode: 14712,
  City: 'RATHENOW',
  Latitude: '52.603.594',
  Longitude: '12.351.393',
  Telephone: '03385-5200351',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.603594,12.351393',
  id: '52.603594,12.351393'
}, {
  Brand: 'TOTAL',
  Street: 'ERFURTER STR. 60',
  PostCode: 98693,
  City: 'ILMENAU',
  Latitude: '50.691.333',
  Longitude: '10.904.988',
  Telephone: '03677-840890',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.691333,10.904988',
  id: '50.691333,10.904988'
}, {
  Brand: 'TOTAL',
  Street: '58 LIMBACHER STR.',
  PostCode: 9113,
  City: 'CHEMNITZ',
  Latitude: '50.837.992',
  Longitude: '12.896.985',
  Telephone: '0371-3363873',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.837992,12.896985',
  id: '50.837992,12.896985'
}, {
  Brand: 'TOTAL',
  Street: '25 LINDENSTRASSE',
  PostCode: 54662,
  City: 'HERFORST',
  Latitude: '49.946.297',
  Longitude: '6.690.504',
  Telephone: '6562930304',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.946297,6.690504',
  id: '49.946297,6.690504'
}, {
  Brand: 'ARAL',
  Street: 'Kölner Straße 36-40',
  PostCode: 50226,
  City: 'Frechen',
  Latitude: '509.105.910',
  Longitude: '68.178.820',
  Telephone: '223414646',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.910591,6.817882',
  id: '50.910591,6.817882'
}, {
  Brand: 'TOTAL',
  Street: '61 DEGGENDORFER STRASSE',
  PostCode: 94447,
  City: 'PLATTLING',
  Latitude: '48.782.849',
  Longitude: '12.880.382',
  Telephone: '09931-3696',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.782849,12.880382',
  id: '48.782849,12.880382'
}, {
  Brand: 'ARAL',
  Street: 'Erzbergerstraße 61',
  PostCode: 88239,
  City: 'Wangen',
  Latitude: '476.776.380',
  Longitude: '98.405.850',
  Telephone: '',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.677638,9.840585',
  id: '47.677638,9.840585'
}, {
  Brand: 'TOTAL',
  Street: 'STAENDENHOF 1, B10',
  PostCode: 66957,
  City: 'RUPPERTSWEILER',
  Latitude: '49.204.063',
  Longitude: '7.696.084',
  Telephone: '06395-6280',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.204063,7.696084',
  id: '49.204063,7.696084'
}, {
  Brand: 'TOTAL',
  Street: '71-75 KROHNSTIEG',
  PostCode: 22415,
  City: 'HAMBURG',
  Latitude: '53.648.328',
  Longitude: '10.005.961',
  Telephone: '040-5319690',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.648328,10.005961',
  id: '53.648328,10.005961'
}, {
  Brand: 'TOTAL',
  Street: 'POSTSTR. 12',
  PostCode: 98559,
  City: 'OBERHOF',
  Latitude: '50.704.994',
  Longitude: '10.731.859',
  Telephone: '036842-22598',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.704994,10.731859',
  id: '50.704994,10.731859'
}, {
  Brand: 'OMV',
  Street: 'Hauptstr. 1',
  PostCode: 85414,
  City: 'Kirchdorf',
  Latitude: '4.845.841.000',
  Longitude: '1.165.260.000',
  Telephone: '+49-8166-9668',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.45841,11.6526',
  id: '48.45841,11.6526'
}, {
  Brand: 'TOTAL',
  Street: 'NEUHOEFER STR. 28',
  PostCode: 21107,
  City: 'HAMBURG',
  Latitude: '53.510.792',
  Longitude: '9.984.535',
  Telephone: '040-7532295',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.510792,9.984535',
  id: '53.510792,9.984535'
}, {
  Brand: 'TOTAL',
  Street: 'FRANKENBERGER STR. 56',
  PostCode: 9661,
  City: 'HAINICHEN',
  Latitude: '50.968.002',
  Longitude: '13.105.072',
  Telephone: '037207-2588',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.968002,13.105072',
  id: '50.968002,13.105072'
}, {
  Brand: 'TOTAL',
  Street: 'GRAVENHORSTER STR.21',
  PostCode: 49477,
  City: 'IBBENBUEREN',
  Latitude: '52.274.546',
  Longitude: '7.704.868',
  Telephone: '05451-894751',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.274546,7.704868',
  id: '52.274546,7.704868'
}, {
  Brand: 'ARAL',
  Street: 'BAB A 1',
  PostCode: 27367,
  City: 'Sottrum',
  Latitude: '530.908.870',
  Longitude: '91.779.420',
  Telephone: '4205494',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.090887,9.177942',
  id: '53.090887,9.177942'
}, {
  Brand: 'TOTAL',
  Street: 'TURNSTR. 3',
  PostCode: 25335,
  City: 'ELMSHORN',
  Latitude: '53.754.605',
  Longitude: '9.641.849',
  Telephone: '04121-3232',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.754605,9.641849',
  id: '53.754605,9.641849'
}, {
  Brand: 'TOTAL',
  Street: 'GOESCHWITZER STR. 1',
  PostCode: 7745,
  City: 'JENA',
  Latitude: '50.898.015',
  Longitude: '11.593.384',
  Telephone: '03641-215080',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.898015,11.593384',
  id: '50.898015,11.593384'
}, {
  Brand: 'OMV',
  Street: 'Würzburger Str. 45',
  PostCode: 97424,
  City: 'Schweinfurt',
  Latitude: '5.002.438.000',
  Longitude: '1.019.651.000',
  Telephone: '+49-9721-82873',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.02438,10.19651',
  id: '50.02438,10.19651'
}, {
  Brand: 'ARAL',
  Street: 'Dreher Str. 18',
  PostCode: 42899,
  City: 'Remscheid',
  Latitude: '512.228.350',
  Longitude: '72.292.290',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.222835,7.229229',
  id: '51.222835,7.229229'
}, {
  Brand: 'TOTAL',
  Street: 'DUNDENHEIMER STR. 31',
  PostCode: 77743,
  City: 'NEURIED',
  Latitude: '4.845.091',
  Longitude: '7.816.408',
  Telephone: '07807-887',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.45091,7.816408',
  id: '48.45091,7.816408'
}, {
  Brand: 'Avanti',
  Street: 'Degernpoint A1',
  PostCode: 85368,
  City: 'Moosburg',
  Latitude: '4.847.139.000',
  Longitude: '1.196.389.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.47139,11.96389',
  id: '48.47139,11.96389'
}, {
  Brand: 'TOTAL',
  Street: 'LINDENTHALER STR.61',
  PostCode: 4155,
  City: 'LEIPZIG',
  Latitude: '51.366.007',
  Longitude: '12.366.082',
  Telephone: '0341-5832105',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.366007,12.366082',
  id: '51.366007,12.366082'
}, {
  Brand: 'TOTAL',
  Street: 'MURGTALSTR.89',
  PostCode: 76571,
  City: 'GAGGENAU',
  Latitude: '48.810.308',
  Longitude: '8.310.143',
  Telephone: '07225-5603',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.810308,8.310143',
  id: '48.810308,8.310143'
}, {
  Brand: 'OMV',
  Street: 'Regensburger Str. 68',
  PostCode: 84061,
  City: 'Ergoldsbach',
  Latitude: '4.870.810.000',
  Longitude: '1.219.439.000',
  Telephone: '+49-8771-1329',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.7081,12.19439',
  id: '48.7081,12.19439'
}, {
  Brand: 'ARAL',
  Street: 'Longericher Str. 207',
  PostCode: 50739,
  City: 'Köln',
  Latitude: '509.824.580',
  Longitude: '69.198.100',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.982458,6.91981',
  id: '50.982458,6.91981'
}, {
  Brand: 'TOTAL',
  Street: 'UERDINGER STR. 130',
  PostCode: 47259,
  City: 'DUISBURG',
  Latitude: '51.358.706',
  Longitude: '6.687.479',
  Telephone: '0203-781709',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.358706,6.687479',
  id: '51.358706,6.687479'
}, {
  Brand: 'OMV',
  Street: 'Burgkirchener Str. 14',
  PostCode: 84489,
  City: 'Burghausen',
  Latitude: '4.817.755.000',
  Longitude: '1.282.541.000',
  Telephone: '+49-8677-2674',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.17755,12.82541',
  id: '48.17755,12.82541'
}, {
  Brand: 'TOTAL',
  Street: 'LAUCHHAMMERSTR. 12',
  PostCode: 1591,
  City: 'RIESA',
  Latitude: '51.313.278',
  Longitude: '13.289.316',
  Telephone: '03525-733388',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.313278,13.289316',
  id: '51.313278,13.289316'
}, {
  Brand: 'ARAL',
  Street: 'Borchener Straße336',
  PostCode: 33106,
  City: 'Paderborn',
  Latitude: '516.832.750',
  Longitude: '87.211.090',
  Telephone: '5251892905',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.683275,8.721109',
  id: '51.683275,8.721109'
}, {
  Brand: 'ARAL',
  Street: 'A2',
  PostCode: 33334,
  City: 'Gütersloh',
  Latitude: '518.758.618',
  Longitude: '84.180.650',
  Telephone: '524151600',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.8758618,8.418065',
  id: '51.8758618,8.418065'
}, {
  Brand: 'OMV',
  Street: 'Bahnhofstr. 6 a',
  PostCode: 97500,
  City: 'Ebelsbach',
  Latitude: '4.998.454.000',
  Longitude: '1.066.873.000',
  Telephone: '+49-9522-708014',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.98454,10.66873',
  id: '49.98454,10.66873'
}, {
  Brand: 'Eni',
  Street: 'Suedl.Muenchener Str. 24 A',
  PostCode: 82031,
  City: 'Gruenwald',
  Latitude: '4.804.465.439',
  Longitude: '1.152.815.993',
  Telephone: '+49 (0) 89641387',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.04465439,11.52815993',
  id: '48.04465439,11.52815993'
}, {
  Brand: 'Eni',
  Street: 'Bamberger Strasse 39',
  PostCode: 96135,
  City: 'Stegaurach',
  Latitude: '4.986.295.938',
  Longitude: '1.084.646.514',
  Telephone: '+49 (0) 95129333',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.86295938,10.84646514',
  id: '49.86295938,10.84646514'
}, {
  Brand: 'Eni',
  Street: 'Radeburger Str. 150',
  PostCode: 1558,
  City: 'Grossenhain',
  Latitude: '5.129.513.500',
  Longitude: '1.354.341.500',
  Telephone: '+49 (0) 35225023',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.295135,13.543415',
  id: '51.295135,13.543415'
}, {
  Brand: 'TOTAL',
  Street: 'BRANDENBURGISCHE STR. 3',
  PostCode: 14974,
  City: 'LUDWIGSFELDE',
  Latitude: '52.311.758',
  Longitude: '13.243.392',
  Telephone: '03378-870107',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.311758,13.243392',
  id: '52.311758,13.243392'
}, {
  Brand: 'ARAL',
  Street: 'Carl-Benz-Straße 1',
  PostCode: 61191,
  City: 'Rosbach vor der Höhe',
  Latitude: '502.934.520',
  Longitude: '86.936.990',
  Telephone: '60038269661',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.293452,8.693699',
  id: '50.293452,8.693699'
}, {
  Brand: 'TOTAL',
  Street: 'PRORAER CHAUSSEE 8',
  PostCode: 18609,
  City: 'BINZ',
  Latitude: '54.402.414',
  Longitude: '13.602.854',
  Telephone: '038393-2557',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.402414,13.602854',
  id: '54.402414,13.602854'
}, {
  Brand: 'ARAL',
  Street: 'Paradiesstr. 17',
  PostCode: 52349,
  City: 'Düren',
  Latitude: '508.061.120',
  Longitude: '64.721.910',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.806112,6.472191',
  id: '50.806112,6.472191'
}, {
  Brand: 'TOTAL',
  Street: '3 FRAUNHOFER STR.',
  PostCode: 93092,
  City: 'BARBING',
  Latitude: '4.899.568',
  Longitude: '12.235.221',
  Telephone: '09401-5278911',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.99568,12.235221',
  id: '48.99568,12.235221'
}, {
  Brand: 'ARAL',
  Street: 'Benzstraße 7',
  PostCode: 64646,
  City: 'Heppenheim',
  Latitude: '496.475.190',
  Longitude: '86.246.980',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.647519,8.624698',
  id: '49.647519,8.624698'
}, {
  Brand: 'TOTAL',
  Street: 'HAUPTSTR. 258',
  PostCode: 66287,
  City: 'QUIERSCHIED',
  Latitude: '49.341.651',
  Longitude: '7.020.608',
  Telephone: '06825-5306',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.341651,7.020608',
  id: '49.341651,7.020608'
}, {
  Brand: 'TOTAL',
  Street: 'HILDBURGHAUSER STR. 222',
  PostCode: 12209,
  City: 'BERLIN',
  Latitude: '52.419.154',
  Longitude: '13.319.371',
  Telephone: '030-76687462',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.419154,13.319371',
  id: '52.419154,13.319371'
}, {
  Brand: 'TOTAL',
  Street: 'HAUPTSTRASSE 121',
  PostCode: 98553,
  City: 'WALDAU',
  Latitude: '50.504.367',
  Longitude: '10.833.668',
  Telephone: '036878-61521',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.504367,10.833668',
  id: '50.504367,10.833668'
}, {
  Brand: 'OMV',
  Street: 'Mühldorfer Str. 45',
  PostCode: 84539,
  City: 'Ampfing',
  Latitude: '4.825.661.000',
  Longitude: '1.242.440.000',
  Telephone: '+49-8636-1051',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.25661,12.4244',
  id: '48.25661,12.4244'
}, {
  Brand: 'TOTAL',
  Street: 'BORNSTR.266',
  PostCode: 44145,
  City: 'DORTMUND',
  Latitude: '51.535.715',
  Longitude: '7.474.473',
  Telephone: '0231-818218',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.535715,7.474473',
  id: '51.535715,7.474473'
}, {
  Brand: 'TOTAL',
  Street: 'WALLDORFER STRASSE 59',
  PostCode: 98617,
  City: 'HERPF',
  Latitude: '50.583.495',
  Longitude: '10.339.036',
  Telephone: '036943-63204',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.583495,10.339036',
  id: '50.583495,10.339036'
}, {
  Brand: 'ARAL',
  Street: 'Freisinger Str. 27',
  PostCode: 85386,
  City: 'Eching',
  Latitude: '483.076.560',
  Longitude: '116.470.540',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.307656,11.647054',
  id: '48.307656,11.647054'
}, {
  Brand: 'TOTAL',
  Street: 'GROSSBEERENSTR. 137',
  PostCode: 14482,
  City: 'POTSDAM',
  Latitude: '52.385.799',
  Longitude: '13.108.893',
  Telephone: '0331-7482098',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.385799,13.108893',
  id: '52.385799,13.108893'
}, {
  Brand: 'ARAL',
  Street: 'Äußere-Kanal-Straße 90',
  PostCode: 50827,
  City: 'Köln',
  Latitude: '509.585.360',
  Longitude: '69.052.120',
  Telephone: '221551000',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.958536,6.905212',
  id: '50.958536,6.905212'
}, {
  Brand: 'ARAL',
  Street: 'Kleine Eichen 1 a',
  PostCode: 34355,
  City: 'Staufenberg',
  Latitude: '513.753.570',
  Longitude: '96.287.740',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.375357,9.628774',
  id: '51.375357,9.628774'
}, {
  Brand: 'ARAL',
  Street: 'Nikolaus-Otto-Str.',
  PostCode: 41515,
  City: 'Grevenbroich',
  Latitude: '510.856.600',
  Longitude: '66.188.670',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.08566,6.618867',
  id: '51.08566,6.618867'
}, {
  Brand: 'TOTAL',
  Street: 'LEIPZIGER STR. 87',
  PostCode: 4600,
  City: 'ALTENBURG',
  Latitude: '51.008.008',
  Longitude: '12.453.298',
  Telephone: '03447-836804',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.008008,12.453298',
  id: '51.008008,12.453298'
}, {
  Brand: 'Westfalen',
  Street: 'Rolshover Str. 380',
  PostCode: null,
  City: 'KOELN',
  Latitude: '509.205',
  Longitude: '699.883',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.9205,6.99883',
  id: '50.9205,6.99883'
}, {
  Brand: 'TOTAL',
  Street: 'DELITZSCHER STR. 36',
  PostCode: 6112,
  City: 'HALLE',
  Latitude: '51.478.518',
  Longitude: '11.996.009',
  Telephone: '0345-5606281',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.478518,11.996009',
  id: '51.478518,11.996009'
}, {
  Brand: 'TOTAL',
  Street: 'HEERSTR.270',
  PostCode: 47053,
  City: 'DUISBURG',
  Latitude: '5.141.509',
  Longitude: '6.762.589',
  Telephone: '0203-63355',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.41509,6.762589',
  id: '51.41509,6.762589'
}, {
  Brand: 'Westfalen',
  Street: 'Pothof 14',
  PostCode: null,
  City: 'NOTTULN',
  Latitude: '519.279',
  Longitude: '735.334',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9279,7.35334',
  id: '51.9279,7.35334'
}, {
  Brand: 'Westfalen',
  Street: 'Rheindeichstr. 20',
  PostCode: null,
  City: 'DUISBURG',
  Latitude: '514.572',
  Longitude: '671.349',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.4572,6.71349',
  id: '51.4572,6.71349'
}, {
  Brand: 'ARAL',
  Street: 'Siegener Straße 10',
  PostCode: 57548,
  City: 'Kirchen',
  Latitude: '508.121.990',
  Longitude: '78.801.530',
  Telephone: '274161497',
  WeekDayFrom: '06:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '08:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.812199,7.880153',
  id: '50.812199,7.880153'
}, {
  Brand: 'OMV',
  Street: 'Landsberger Str. 43',
  PostCode: 86179,
  City: 'Augsburg',
  Latitude: '4.831.128.000',
  Longitude: '1.090.683.000',
  Telephone: '+49-821-83811',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.31128,10.90683',
  id: '48.31128,10.90683'
}, {
  Brand: 'TOTAL',
  Street: '39 AEPPELALLEE',
  PostCode: 65203,
  City: 'WIESBADEN',
  Latitude: '50.047.652',
  Longitude: '8.231.238',
  Telephone: '6116010537',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.047652,8.231238',
  id: '50.047652,8.231238'
}, {
  Brand: 'ARAL',
  Street: 'Alter Postweg 4-6',
  PostCode: 68309,
  City: 'Mannheim',
  Latitude: '495.159.640',
  Longitude: '85.205.710',
  Telephone: '6217163939',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.515964,8.520571',
  id: '49.515964,8.520571'
}, {
  Brand: 'TOTAL',
  Street: 'BAB A9 PEGNITZ WEST',
  PostCode: 91257,
  City: 'PEGNITZ',
  Latitude: '49.749.645',
  Longitude: '11.510.718',
  Telephone: '92414944539',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.749645,11.510718',
  id: '49.749645,11.510718'
}, {
  Brand: 'ARAL',
  Street: 'Otto-Hahn-Str. 16',
  PostCode: 35510,
  City: 'Butzbach',
  Latitude: '504.306.710',
  Longitude: '86.876.230',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.430671,8.687623',
  id: '50.430671,8.687623'
}, {
  Brand: 'TOTAL',
  Street: '60-62 BRITZER DAMM',
  PostCode: 12347,
  City: 'BERLIN',
  Latitude: '52.457.048',
  Longitude: '13.436.356',
  Telephone: '030/62981575',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.457048,13.436356',
  id: '52.457048,13.436356'
}, {
  Brand: 'ARAL',
  Street: 'Carl-Benz-Straße 20-24',
  PostCode: 78467,
  City: 'Konstanz',
  Latitude: '476.775.860',
  Longitude: '91.470.510',
  Telephone: '7531694090',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '47.677586,9.147051',
  id: '47.677586,9.147051'
}, {
  Brand: 'TOTAL',
  Street: '78 PADERBORNER STR.',
  PostCode: 33189,
  City: 'SCHLANGEN',
  Latitude: '51.800.774',
  Longitude: '8.837.084',
  Telephone: '05252-7109',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.800774,8.837084',
  id: '51.800774,8.837084'
}, {
  Brand: 'TOTAL',
  Street: 'NEBELSCHUETZER STR.4',
  PostCode: 1917,
  City: 'KAMENZ',
  Latitude: '51.263.299',
  Longitude: '14.116.393',
  Telephone: '03578/309435',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.263299,14.116393',
  id: '51.263299,14.116393'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Straße 111',
  PostCode: 51063,
  City: 'Köln',
  Latitude: '509.737.970',
  Longitude: '70.125.540',
  Telephone: '22196426619',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.973797,7.012554',
  id: '50.973797,7.012554'
}, {
  Brand: 'Eni',
  Street: 'Leinstrae 11',
  PostCode: 30159,
  City: 'Hannover',
  Latitude: '5.236.960.898',
  Longitude: '973.556.302',
  Telephone: '+49 (0) 51136373',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.36960898,9.73556302',
  id: '52.36960898,9.73556302'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 79-81',
  PostCode: 69151,
  City: 'Neckargemünd',
  Latitude: '493.924.580',
  Longitude: '87.798.260',
  Telephone: '62231244',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:30',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:30',
  SundayFrom: '05:00',
  SundayTo: '22:30',
  IsOpen24Hours: 'Nein',
  location: '49.392458,8.779826',
  id: '49.392458,8.779826'
}, {
  Brand: 'TOTAL',
  Street: 'HUEBLERSTR. 36',
  PostCode: 1309,
  City: 'DRESDEN',
  Latitude: '51.049.195',
  Longitude: '13.793.189',
  Telephone: '0351-40425070',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.049195,13.793189',
  id: '51.049195,13.793189'
}, {
  Brand: 'Eni',
  Street: 'Wuerzburger Strasse 37',
  PostCode: 97199,
  City: 'Ochsenfurt',
  Latitude: '4.966.867.228',
  Longitude: '1.006.488.621',
  Telephone: '+49 (0) 93313125',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.66867228,10.06488621',
  id: '49.66867228,10.06488621'
}, {
  Brand: 'OMV',
  Street: 'Ungererstr. 46-50',
  PostCode: 80802,
  City: 'München',
  Latitude: '4.816.605.000',
  Longitude: '1.159.002.000',
  Telephone: '+49-89-393888',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.16605,11.59002',
  id: '48.16605,11.59002'
}, {
  Brand: 'OMV',
  Street: 'Hindenburgring 40',
  PostCode: 61348,
  City: 'Bad Homburg',
  Latitude: '5.022.732.000',
  Longitude: '860.544.000',
  Telephone: '+49-6172-934712',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.22732,8.60544',
  id: '50.22732,8.60544'
}, {
  Brand: 'ARAL',
  Street: 'Bertha-Benz-Straße',
  PostCode: 26160,
  City: 'Bad Zwischenahn',
  Latitude: '531.829.250',
  Longitude: '80.735.170',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.182925,8.073517',
  id: '53.182925,8.073517'
}, {
  Brand: 'TOTAL',
  Street: 'DAIMLER STR. 1',
  PostCode: 71546,
  City: 'ASPACH',
  Latitude: '48.971.858',
  Longitude: '9.387.147',
  Telephone: '07191-3678024',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.971858,9.387147',
  id: '48.971858,9.387147'
}, {
  Brand: 'Eni',
  Street: 'Leipziger Str./B 93',
  PostCode: 4600,
  City: 'Altenburg',
  Latitude: '5.101.108.590',
  Longitude: '1.245.412.420',
  Telephone: '+49 (0) 34478343',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.0110859,12.4541242',
  id: '51.0110859,12.4541242'
}, {
  Brand: 'Westfalen',
  Street: 'Duerener Str. 388-392',
  PostCode: null,
  City: 'KOELN',
  Latitude: '509.255',
  Longitude: '689.543',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.9255,6.89543',
  id: '50.9255,6.89543'
}, {
  Brand: 'Westfalen',
  Street: 'Lemgoer Str. 15',
  PostCode: null,
  City: 'DETMOLD',
  Latitude: '519.436',
  Longitude: '887.837',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9436,8.87837',
  id: '51.9436,8.87837'
}, {
  Brand: 'Eni',
  Street: 'Freyburger Str. 17',
  PostCode: 6132,
  City: 'Halle',
  Latitude: '5.144.469.000',
  Longitude: '1.196.994.000',
  Telephone: '+49 (0) 34577030',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.44469,11.96994',
  id: '51.44469,11.96994'
}, {
  Brand: 'Eni',
  Street: 'Rangaustr. 4A',
  PostCode: 90530,
  City: 'Wendelstein',
  Latitude: '4.934.587.000',
  Longitude: '1.111.118.000',
  Telephone: '+49 (0) 91298929',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.34587,11.11118',
  id: '49.34587,11.11118'
}, {
  Brand: 'Eni',
  Street: 'Hauptstr.',
  PostCode: 73553,
  City: 'Alfdorf',
  Latitude: '4.884.252.000',
  Longitude: '970.899.600',
  Telephone: '+49 (0) 71729399',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.84252,9.708996',
  id: '48.84252,9.708996'
}, {
  Brand: 'OMV',
  Street: 'Münchner Str. 17',
  PostCode: 85391,
  City: 'Allershausen',
  Latitude: '4.843.006.000',
  Longitude: '1.159.428.000',
  Telephone: '+49-8166-1604',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.43006,11.59428',
  id: '48.43006,11.59428'
}, {
  Brand: 'Eni',
  Street: 'Hedelfinger Str. 73',
  PostCode: 70327,
  City: 'Stuttgart',
  Latitude: '4.876.608.000',
  Longitude: '924.985.000',
  Telephone: '+49 (0) 71142246',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.76608,9.24985',
  id: '48.76608,9.24985'
}, {
  Brand: 'Westfalen',
  Street: 'Dinxperloer Str. 55',
  PostCode: null,
  City: 'BOCHOLT',
  Latitude: '518.429',
  Longitude: '660.589',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8429,6.60589',
  id: '51.8429,6.60589'
}, {
  Brand: 'Westfalen',
  Street: 'Breite Str. 173 a',
  PostCode: null,
  City: 'BRAKE',
  Latitude: '53.325',
  Longitude: '846.438',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.325,8.46438',
  id: '53.325,8.46438'
}, {
  Brand: 'Eni',
  Street: 'Leibnizstr. 24',
  PostCode: 74211,
  City: 'Leingarten',
  Latitude: '4.914.383.000',
  Longitude: '912.661.000',
  Telephone: '07131-3994325',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.14383,9.12661',
  id: '49.14383,9.12661'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 1',
  PostCode: 83324,
  City: 'Ruhpolding',
  Latitude: '477.661.010',
  Longitude: '126.408.010',
  Telephone: '8663419603',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '47.766101,12.640801',
  id: '47.766101,12.640801'
}, {
  Brand: 'Avanti',
  Street: 'Bamberger Str. 55',
  PostCode: 91301,
  City: 'Forchheim',
  Latitude: '4.972.625.000',
  Longitude: '1.105.784.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.72625,11.05784',
  id: '49.72625,11.05784'
}, {
  Brand: 'OMV',
  Street: 'Wendling 14',
  PostCode: 83737,
  City: 'Irschenberg',
  Latitude: '4.782.675.000',
  Longitude: '1.189.825.000',
  Telephone: '+49-8025-281103',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '47.82675,11.89825',
  id: '47.82675,11.89825'
}, {
  Brand: 'Eni',
  Street: 'An der B80',
  PostCode: 6313,
  City: 'Wimmelburg',
  Latitude: '5.151.930.000',
  Longitude: '1.151.052.000',
  Telephone: '03475/637393',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.5193,11.51052',
  id: '51.5193,11.51052'
}, {
  Brand: 'Westfalen',
  Street: 'Stedinger Landstr. 21',
  PostCode: null,
  City: 'DELMENHORST',
  Latitude: '53.086',
  Longitude: '865.038',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.086,8.65038',
  id: '53.086,8.65038'
}, {
  Brand: 'TOTAL',
  Street: 'GREIFSWALDER CHAUSSEE 4',
  PostCode: 18507,
  City: 'GRIMMEN',
  Latitude: '54.109.253',
  Longitude: '1.305.623',
  Telephone: '038326-2134',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.109253,13.05623',
  id: '54.109253,13.05623'
}, {
  Brand: 'ARAL',
  Street: 'Gießener Straße 60',
  PostCode: 34560,
  City: 'Fritzlar',
  Latitude: '511.208.460',
  Longitude: '92.776.410',
  Telephone: '5622930418',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.120846,9.277641',
  id: '51.120846,9.277641'
}, {
  Brand: 'TOTAL',
  Street: 'MALCHINER STR. 81',
  PostCode: 17153,
  City: 'STAVENHAGEN',
  Latitude: '53.700.237',
  Longitude: '12.897.694',
  Telephone: '039954-22175',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.700237,12.897694',
  id: '53.700237,12.897694'
}, {
  Brand: 'ARAL',
  Street: 'Landstraße 32/B 57',
  PostCode: 52441,
  City: 'Linnich',
  Latitude: '509.598.290',
  Longitude: '62.244.840',
  Telephone: '24628420',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.959829,6.224484',
  id: '50.959829,6.224484'
}, {
  Brand: 'Eni',
  Street: 'Lenggrieser Strasse 46',
  PostCode: 83646,
  City: 'Bad Toelz',
  Latitude: '4.774.650.124',
  Longitude: '1.156.668.200',
  Telephone: '+49 (0) 80418147',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.74650124,11.566682',
  id: '47.74650124,11.566682'
}, {
  Brand: 'ARAL',
  Street: 'Albert-Vietel-Straße 4',
  PostCode: 9217,
  City: 'Burgstädt',
  Latitude: '508.984.320',
  Longitude: '128.162.120',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.898432,12.816212',
  id: '50.898432,12.816212'
}, {
  Brand: 'Eni',
  Street: 'Westhafenstrasse 1',
  PostCode: 13353,
  City: 'Berlin',
  Latitude: '5.253.828.412',
  Longitude: '1.334.408.673',
  Telephone: '+49 (0) 30395361',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.53828412,13.34408673',
  id: '52.53828412,13.34408673'
}, {
  Brand: 'Westfalen',
  Street: 'Bottroper Str. 184',
  PostCode: null,
  City: 'BOTTROP',
  Latitude: '515.702',
  Longitude: '691.089',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.5702,6.91089',
  id: '51.5702,6.91089'
}, {
  Brand: 'Westfalen',
  Street: 'Teutoburger-Wald-Str. 45',
  PostCode: null,
  City: 'GEORGSMARIENHUETTE',
  Latitude: '521.901',
  Longitude: '806.873',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.1901,8.06873',
  id: '52.1901,8.06873'
}, {
  Brand: 'TOTAL',
  Street: '10 NIROSTASTR.',
  PostCode: 47807,
  City: 'KREFELD',
  Latitude: '51.288.352',
  Longitude: '6.553.527',
  Telephone: '02151-9372354',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.288352,6.553527',
  id: '51.288352,6.553527'
}, {
  Brand: 'Eni',
  Street: 'Grossingersheimer Strasse',
  PostCode: 74321,
  City: 'Bietigheim-Bissingen',
  Latitude: '4.895.915.307',
  Longitude: '913.343.474',
  Telephone: '+49 (0) 71425343',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.95915307,9.13343474',
  id: '48.95915307,9.13343474'
}, {
  Brand: 'Westfalen',
  Street: 'Schiffahrter Damm 506',
  PostCode: null,
  City: 'MUENSTER',
  Latitude: '520.104',
  Longitude: '767.915',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0104,7.67915',
  id: '52.0104,7.67915'
}, {
  Brand: 'Westfalen',
  Street: 'Weseler Str. 212-216',
  PostCode: null,
  City: 'MUENSTER',
  Latitude: '51.944',
  Longitude: '761.172',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.944,7.61172',
  id: '51.944,7.61172'
}, {
  Brand: 'ARAL',
  Street: 'Rheinstraße 50',
  PostCode: 57632,
  City: 'Flammersfeld',
  Latitude: '506.482.580',
  Longitude: '75.271.390',
  Telephone: '26851508',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.648258,7.527139',
  id: '50.648258,7.527139'
}, {
  Brand: 'TOTAL',
  Street: 'AUTOHOF KRONSKAMP 3',
  PostCode: 30890,
  City: 'BARSINGHAUSEN',
  Latitude: '52.333.197',
  Longitude: '9.414.628',
  Telephone: '05105-514923',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.333197,9.414628',
  id: '52.333197,9.414628'
}, {
  Brand: 'ARAL',
  Street: 'Am Schornacker 47',
  PostCode: 46485,
  City: 'Wesel',
  Latitude: '516.619.990',
  Longitude: '67.014.070',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.661999,6.701407',
  id: '51.661999,6.701407'
}, {
  Brand: 'TOTAL',
  Street: 'HOMBERGER STR. 5',
  PostCode: 36251,
  City: 'BAD HERSFELD',
  Latitude: '50.874.171',
  Longitude: '970.514',
  Telephone: '06621-917756',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.874171,9.70514',
  id: '50.874171,9.70514'
}, {
  Brand: 'TOTAL',
  Street: 'DAETZINGER STR. 36',
  PostCode: 71120,
  City: 'GRAFENAU',
  Latitude: '48.714.914',
  Longitude: '8.900.578',
  Telephone: '07033-43950',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.714914,8.900578',
  id: '48.714914,8.900578'
}, {
  Brand: 'TOTAL',
  Street: 'VOGELSANGER STR.37',
  PostCode: 58300,
  City: 'WETTER',
  Latitude: '51.360.818',
  Longitude: '7.375.968',
  Telephone: '02335-60371',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.360818,7.375968',
  id: '51.360818,7.375968'
}, {
  Brand: 'TOTAL',
  Street: 'SUEDRING 60',
  PostCode: 40223,
  City: 'DUESSELDORF',
  Latitude: '51.200.071',
  Longitude: '6.767.971',
  Telephone: '0211-151643',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.200071,6.767971',
  id: '51.200071,6.767971'
}, {
  Brand: 'TOTAL',
  Street: 'ZOSSENER DAMM 19',
  PostCode: 15827,
  City: 'BLANKENFELDE',
  Latitude: '52.330.122',
  Longitude: '13.404.113',
  Telephone: '03379-379940',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.330122,13.404113',
  id: '52.330122,13.404113'
}, {
  Brand: 'OMV',
  Street: 'Richtfeld 1',
  PostCode: 92442,
  City: 'Wackersdorf',
  Latitude: '4.931.359.000',
  Longitude: '1.215.862.000',
  Telephone: '+49-9431-7979145',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.31359,12.15862',
  id: '49.31359,12.15862'
}, {
  Brand: 'TOTAL',
  Street: 'UMGEHUNGSSTR. 18',
  PostCode: 1723,
  City: 'WILSDRUFF',
  Latitude: '5.105.498',
  Longitude: '13.538.353',
  Telephone: '035204-61992',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.05498,13.538353',
  id: '51.05498,13.538353'
}, {
  Brand: 'TOTAL',
  Street: 'DUDWEILER STR. 111',
  PostCode: 66111,
  City: 'SAARBRUECKEN',
  Latitude: '49.240.977',
  Longitude: '7.001.475',
  Telephone: '0681-34507',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.240977,7.001475',
  id: '49.240977,7.001475'
}, {
  Brand: 'OMV',
  Street: 'Hauptstr. 61',
  PostCode: 73466,
  City: 'Lauchheim',
  Latitude: '4.887.108.000',
  Longitude: '1.024.136.000',
  Telephone: '+49-7363-5341',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.87108,10.24136',
  id: '48.87108,10.24136'
}, {
  Brand: 'TOTAL',
  Street: 'FRIEDRICHSTR.1',
  PostCode: 99310,
  City: 'ARNSTADT',
  Latitude: '5.084.032',
  Longitude: '10.956.505',
  Telephone: '03628-76218',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.84032,10.956505',
  id: '50.84032,10.956505'
}, {
  Brand: 'ARAL',
  Street: 'A3',
  PostCode: 46569,
  City: 'Hünxe',
  Latitude: '516.353.780',
  Longitude: '67.474.700',
  Telephone: '2858503',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.635378,6.74747',
  id: '51.635378,6.74747'
}, {
  Brand: 'Eni',
  Street: 'Bachstr. 1',
  PostCode: 93455,
  City: 'Traitsching',
  Latitude: '4.915.276.000',
  Longitude: '1.264.594.000',
  Telephone: '+49 (0) 9974395',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.15276,12.64594',
  id: '49.15276,12.64594'
}, {
  Brand: 'OMV',
  Street: 'Ernst-Reuter-Str. 166',
  PostCode: 95030,
  City: 'Hof/Saale',
  Latitude: '5.032.610.000',
  Longitude: '1.189.288.000',
  Telephone: '+49-9281-64899',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.3261,11.89288',
  id: '50.3261,11.89288'
}, {
  Brand: 'Westfalen',
  Street: 'Armlandstrasse 22',
  PostCode: null,
  City: 'GESCHER',
  Latitude: '51.957',
  Longitude: '700.132',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.957,7.00132',
  id: '51.957,7.00132'
}, {
  Brand: 'ARAL',
  Street: 'An der Kleinbahn 5',
  PostCode: 41334,
  City: 'Nettetal',
  Latitude: '513.286.470',
  Longitude: '61.963.330',
  Telephone: '21578958775',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.328647,6.196333',
  id: '51.328647,6.196333'
}, {
  Brand: 'TOTAL',
  Street: 'GOLDBACH SUED/ A 27',
  PostCode: 27299,
  City: 'LANGWEDEL',
  Latitude: '52.999.166',
  Longitude: '9.180.315',
  Telephone: '04232-306020',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.999166,9.180315',
  id: '52.999166,9.180315'
}, {
  Brand: 'Westfalen',
  Street: 'Brockbachstr. 20',
  PostCode: null,
  City: 'WESTERKAPPELN N',
  Latitude: '52.261.223',
  Longitude: '7.846.699',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.261223,7.846699',
  id: '52.261223,7.846699'
}, {
  Brand: 'TOTAL',
  Street: 'KOELNER STR. 86',
  PostCode: 57439,
  City: 'ATTENDORN',
  Latitude: '51.118.728',
  Longitude: '7.893.328',
  Telephone: '02722-3235',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.118728,7.893328',
  id: '51.118728,7.893328'
}, {
  Brand: 'TOTAL',
  Street: 'GEWERBESTR. 4',
  PostCode: 99880,
  City: 'LAUCHA',
  Latitude: '50.922.497',
  Longitude: '10.552.224',
  Telephone: '03622-440211',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.922497,10.552224',
  id: '50.922497,10.552224'
}, {
  Brand: 'TOTAL',
  Street: 'BRAUNSCHWEIGER STR.137',
  PostCode: 38518,
  City: 'GIFHORN',
  Latitude: '52.465.322',
  Longitude: '10.542.578',
  Telephone: '05371-3455',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.465322,10.542578',
  id: '52.465322,10.542578'
}, {
  Brand: 'TOTAL',
  Street: 'TAUNUSSTR. 1',
  PostCode: 61191,
  City: 'ROSBACH',
  Latitude: '50.307.008',
  Longitude: '8.697.208',
  Telephone: '06003-1203',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.307008,8.697208',
  id: '50.307008,8.697208'
}, {
  Brand: 'Westfalen',
  Street: 'Polsumer Str. 236',
  PostCode: null,
  City: 'GELSENKIRCHEN',
  Latitude: '516.074',
  Longitude: '704.898',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.6074,7.04898',
  id: '51.6074,7.04898'
}, {
  Brand: 'TOTAL',
  Street: 'DARMSTAEDTER LANDSTR 2',
  PostCode: 64569,
  City: 'NAUHEIM',
  Latitude: '49.939.078',
  Longitude: '8.448.889',
  Telephone: '06152-64953',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.939078,8.448889',
  id: '49.939078,8.448889'
}, {
  Brand: 'TOTAL',
  Street: '2 ZUM HANSA-CENTER',
  PostCode: 49451,
  City: 'HOLDORF',
  Latitude: '5.259.744',
  Longitude: '8.118.027',
  Telephone: '05494-9159243',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.59744,8.118027',
  id: '52.59744,8.118027'
}, {
  Brand: 'ARAL',
  Street: 'Donauwörther Straße 63',
  PostCode: 86653,
  City: 'Monheim',
  Latitude: '488.367.260',
  Longitude: '108.451.950',
  Telephone: '90911863',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:00',
  SundayFrom: '08:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.836726,10.845195',
  id: '48.836726,10.845195'
}, {
  Brand: 'TOTAL',
  Street: 'PRAGER STRASSE 420',
  PostCode: 4288,
  City: 'LEIPZIG',
  Latitude: '51.290.829',
  Longitude: '12.444.689',
  Telephone: '034297-42240',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.290829,12.444689',
  id: '51.290829,12.444689'
}, {
  Brand: 'ARAL',
  Street: 'Gewerbepark B 3',
  PostCode: 93086,
  City: 'Wörth an der Donau',
  Latitude: '490.006.400',
  Longitude: '123.641.570',
  Telephone: '94829080210',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.00064,12.364157',
  id: '49.00064,12.364157'
}, {
  Brand: 'ARAL',
  Street: 'Friedrich-Pogge-Weg 60',
  PostCode: 18273,
  City: 'Güstrow',
  Latitude: '537.728.720',
  Longitude: '121.665.080',
  Telephone: '3843334848',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '53.772872,12.166508',
  id: '53.772872,12.166508'
}, {
  Brand: 'Westfalen',
  Street: 'Muensterdamm 35',
  PostCode: null,
  City: 'LAER',
  Latitude: '520.568',
  Longitude: '736.472',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0568,7.36472',
  id: '52.0568,7.36472'
}, {
  Brand: 'TOTAL',
  Street: 'AUGUST-BEBEL-STR. 31',
  PostCode: 7743,
  City: 'JENA',
  Latitude: '50.932.023',
  Longitude: '1.157.218',
  Telephone: '03641-820879',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.932023,11.57218',
  id: '50.932023,11.57218'
}, {
  Brand: 'Eni',
  Street: 'Wolfratshauser Str. 232',
  PostCode: 81479,
  City: 'Muenchen',
  Latitude: '4.807.575.912',
  Longitude: '1.152.380.028',
  Telephone: '+49 (0) 89791228',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.07575912,11.52380028',
  id: '48.07575912,11.52380028'
}, {
  Brand: 'TOTAL',
  Street: 'FLOEHATALSTR. 2A',
  PostCode: 9509,
  City: 'POCKAU-LENGEFELD',
  Latitude: '507.003',
  Longitude: '13.229.295',
  Telephone: '037367-9659',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.7003,13.229295',
  id: '50.7003,13.229295'
}, {
  Brand: 'Eni',
  Street: 'Hauptstr. 40',
  PostCode: 96275,
  City: 'Marktzeuln',
  Latitude: '5.016.035.000',
  Longitude: '1.118.467.000',
  Telephone: '09574/505',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.16035,11.18467',
  id: '50.16035,11.18467'
}, {
  Brand: 'Eni',
  Street: 'Loewenberger Strasse 135',
  PostCode: 90475,
  City: 'Nuernberg',
  Latitude: '4.941.112.000',
  Longitude: '1.117.117.000',
  Telephone: '+49 (0) 91183475',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.41112,11.17117',
  id: '49.41112,11.17117'
}, {
  Brand: 'ARAL',
  Street: 'A8',
  PostCode: 83451,
  City: 'Piding',
  Latitude: '477.680.540',
  Longitude: '129.421.770',
  Telephone: '86512750',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.768054,12.942177',
  id: '47.768054,12.942177'
}, {
  Brand: 'Westfalen',
  Street: 'Horster Str. 189',
  PostCode: null,
  City: 'GELSENKIRCHEN',
  Latitude: '515.614',
  Longitude: '704.644',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.5614,7.04644',
  id: '51.5614,7.04644'
}, {
  Brand: 'Eni',
  Street: 'Pacelli-Allee 1 A',
  PostCode: 36043,
  City: 'Fulda',
  Latitude: '5.054.708.000',
  Longitude: '970.289.000',
  Telephone: '+49 (0) 66163985',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.54708,9.70289',
  id: '50.54708,9.70289'
}, {
  Brand: 'OMV',
  Street: 'Weichengereuth 23',
  PostCode: 96450,
  City: 'Coburg',
  Latitude: '5.024.712.000',
  Longitude: '1.096.084.000',
  Telephone: '+49-9561-39474',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.24712,10.96084',
  id: '50.24712,10.96084'
}, {
  Brand: 'Eni',
  Street: 'Dillinger Strasse 34',
  PostCode: 86660,
  City: 'Tapfheim',
  Latitude: '4.867.982.245',
  Longitude: '1.069.982.659',
  Telephone: '+49 (0) 90709217',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.67982245,10.69982659',
  id: '48.67982245,10.69982659'
}, {
  Brand: 'Eni',
  Street: 'Waiblinger Strasse 67',
  PostCode: 71364,
  City: 'Winnenden',
  Latitude: '4.887.320.000',
  Longitude: '938.895.000',
  Telephone: '+49 (0) 71951740',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.8732,9.38895',
  id: '48.8732,9.38895'
}, {
  Brand: 'Eni',
  Street: 'Nuernberger Strasse 18',
  PostCode: 70374,
  City: 'Stuttgart',
  Latitude: '4.880.984.000',
  Longitude: '925.133.000',
  Telephone: '+49 (0) 71152745',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.80984,9.25133',
  id: '48.80984,9.25133'
}, {
  Brand: 'TOTAL',
  Street: 'ULMER STR. 206',
  PostCode: 70188,
  City: 'STUTTGART',
  Latitude: '48.779.397',
  Longitude: '9.230.689',
  Telephone: '0711-423388',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.779397,9.230689',
  id: '48.779397,9.230689'
}, {
  Brand: 'ARAL',
  Street: 'Mindelheimer Straße 1',
  PostCode: 86842,
  City: 'Türkheim',
  Latitude: '480.431.580',
  Longitude: '106.206.590',
  Telephone: '8245904530',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.043158,10.620659',
  id: '48.043158,10.620659'
}, {
  Brand: 'ARAL',
  Street: 'ALBERT-EINSTEIN-STR. 1 a',
  PostCode: 97080,
  City: 'WÜRZBURG',
  Latitude: '498.084.270',
  Longitude: '98.863.230',
  Telephone: '93140409720',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.808427,9.886323',
  id: '49.808427,9.886323'
}, {
  Brand: 'TOTAL',
  Street: 'HEIDELBERGER STR.71',
  PostCode: 64385,
  City: 'REICHELSHEIM',
  Latitude: '49.710.188',
  Longitude: '8.833.537',
  Telephone: '06164-913955',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.710188,8.833537',
  id: '49.710188,8.833537'
}, {
  Brand: 'Eni',
  Street: 'Pfaenderstr. 15 a',
  PostCode: 88161,
  City: 'Lindenberg',
  Latitude: '4.759.771.000',
  Longitude: '987.793.500',
  Telephone: '+49 (0) 83819298',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.59771,9.877935',
  id: '47.59771,9.877935'
}, {
  Brand: 'Eni',
  Street: 'Gustav-Schwab-Strasse 41',
  PostCode: 72762,
  City: 'Reutlingen',
  Latitude: '4.848.938.000',
  Longitude: '919.247.000',
  Telephone: '+49 (0) 71212392',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.48938,9.19247',
  id: '48.48938,9.19247'
}, {
  Brand: 'Westfalen',
  Street: 'Becheltestr. 56',
  PostCode: null,
  City: 'HAGEN',
  Latitude: '51.378',
  Longitude: '745.177',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.378,7.45177',
  id: '51.378,7.45177'
}, {
  Brand: 'TOTAL',
  Street: 'SCHWABMUENCHNER STR. 16',
  PostCode: 86836,
  City: 'KLOSTERLECHFELD',
  Latitude: '48.159.048',
  Longitude: '1.082.816',
  Telephone: '08232-8088112',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.159048,10.82816',
  id: '48.159048,10.82816'
}, {
  Brand: 'OMV',
  Street: 'Feuchtwanger Str.  111',
  PostCode: 91522,
  City: 'Ansbach',
  Latitude: '4.929.187.000',
  Longitude: '1.055.568.000',
  Telephone: '+49-981-61227',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.29187,10.55568',
  id: '49.29187,10.55568'
}, {
  Brand: 'Westfalen',
  Street: 'Auf dem Quellberg 18',
  PostCode: null,
  City: 'DUELMEN',
  Latitude: '518.435',
  Longitude: '7.2946',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8435,7.2946',
  id: '51.8435,7.2946'
}, {
  Brand: 'ARAL',
  Street: 'A2 / Brockweg 300',
  PostCode: 33334,
  City: 'Gütersloh',
  Latitude: '518.663.030',
  Longitude: '83.692.090',
  Telephone: '524151100',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.866303,8.369209',
  id: '51.866303,8.369209'
}, {
  Brand: 'Westfalen',
  Street: 'Meller Str. 41',
  PostCode: null,
  City: 'BISSENDORF',
  Latitude: '522.325',
  Longitude: '817.424',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.2325,8.17424',
  id: '52.2325,8.17424'
}, {
  Brand: 'OMV',
  Street: 'Hallstadter Str. 103',
  PostCode: 96052,
  City: 'Bamberg',
  Latitude: '4.991.060.000',
  Longitude: '1.088.181.000',
  Telephone: '+49-951-70096715',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.9106,10.88181',
  id: '49.9106,10.88181'
}, {
  Brand: 'ARAL',
  Street: 'Buxheimer Straße 113',
  PostCode: 87700,
  City: 'Memmingen',
  Latitude: '479.916.880',
  Longitude: '101.606.230',
  Telephone: '833161722',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:30',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.991688,10.160623',
  id: '47.991688,10.160623'
}, {
  Brand: 'TOTAL',
  Street: 'CALBERLAHER DAMM 54',
  PostCode: 38518,
  City: 'GIFHORN',
  Latitude: '52.475.355',
  Longitude: '10.556.442',
  Telephone: '05371-12646',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.475355,10.556442',
  id: '52.475355,10.556442'
}, {
  Brand: 'ARAL',
  Street: 'Robert-Bosch-Str. 4',
  PostCode: 64319,
  City: 'Pfungstadt',
  Latitude: '498.145.910',
  Longitude: '86.144.260',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.814591,8.614426',
  id: '49.814591,8.614426'
}, {
  Brand: 'TOTAL',
  Street: 'ST. WENDELER STR. 33',
  PostCode: 66606,
  City: 'ST. WENDEL',
  Latitude: '49.493.333',
  Longitude: '7.114.444',
  Telephone: '06854-8654',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.493333,7.114444',
  id: '49.493333,7.114444'
}, {
  Brand: 'Westfalen',
  Street: 'Weseler Str. 383',
  PostCode: null,
  City: 'MUENSTER',
  Latitude: '519.331',
  Longitude: '759.754',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9331,7.59754',
  id: '51.9331,7.59754'
}, {
  Brand: 'Westfalen',
  Street: 'BAT Lipperland',
  PostCode: null,
  City: 'BAT LIPPERLAND',
  Latitude: '52.073.474',
  Longitude: '8.667.235',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.073474,8.667235',
  id: '52.073474,8.667235'
}, {
  Brand: 'ARAL',
  Street: 'A 71',
  PostCode: 98716,
  City: 'Geraberg',
  Latitude: '507.253.110',
  Longitude: '108.458.110',
  Telephone: '36776894953',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.725311,10.845811',
  id: '50.725311,10.845811'
}, {
  Brand: 'TOTAL',
  Street: 'UETTERLINGSER STR.39',
  PostCode: 58791,
  City: 'WERDOHL',
  Latitude: '51.254.486',
  Longitude: '7.745.862',
  Telephone: '02392-180326',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.254486,7.745862',
  id: '51.254486,7.745862'
}, {
  Brand: 'TOTAL',
  Street: 'BIELEFELDER STR.81',
  PostCode: 33803,
  City: 'STEINHAGEN',
  Latitude: '52.013.299',
  Longitude: '8.440.977',
  Telephone: '05204-4634',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.013299,8.440977',
  id: '52.013299,8.440977'
}, {
  Brand: 'TOTAL',
  Street: '35-37 HEERSTR.',
  PostCode: 14055,
  City: 'BERLIN',
  Latitude: '52.508.358',
  Longitude: '13.260.575',
  Telephone: '030-50569275',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.508358,13.260575',
  id: '52.508358,13.260575'
}, {
  Brand: 'OMV',
  Street: 'Regensburger Str. 30',
  PostCode: 93128,
  City: 'Regenstauf',
  Latitude: '4.911.912.000',
  Longitude: '1.212.603.000',
  Telephone: '+49-9402-1369',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.11912,12.12603',
  id: '49.11912,12.12603'
}, {
  Brand: 'TOTAL',
  Street: 'HANAUERSTR. 61',
  PostCode: 63579,
  City: 'FREIGERICHT',
  Latitude: '50.147.092',
  Longitude: '9.111.798',
  Telephone: '06055-915217',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.147092,9.111798',
  id: '50.147092,9.111798'
}, {
  Brand: 'ARAL',
  Street: 'Siemensstraße 2',
  PostCode: 84109,
  City: 'Wörth a. d. Isar',
  Latitude: '486.251.070',
  Longitude: '123.226.280',
  Telephone: '8702949351',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.625107,12.322628',
  id: '48.625107,12.322628'
}, {
  Brand: 'OMV',
  Street: 'Logistikring 8',
  PostCode: 85084,
  City: 'Reichertshofen',
  Latitude: '4.864.340.000',
  Longitude: '1.151.960.000',
  Telephone: '+49-8453-334 68',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.6434,11.5196',
  id: '48.6434,11.5196'
}, {
  Brand: 'Eni',
  Street: 'Reichenhaller Strasse 20',
  PostCode: 83483,
  City: 'Bischofswiesen',
  Latitude: '4.766.930.000',
  Longitude: '1.294.402.000',
  Telephone: '08652/985950',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.6693,12.94402',
  id: '47.6693,12.94402'
}, {
  Brand: 'Westfalen',
  Street: 'Schlingstr. 26',
  PostCode: null,
  City: 'BARNSTORF',
  Latitude: '527.115',
  Longitude: '850.964',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.7115,8.50964',
  id: '52.7115,8.50964'
}, {
  Brand: 'ARAL',
  Street: 'A61',
  PostCode: 56332,
  City: 'Dieblich',
  Latitude: '502.664.250',
  Longitude: '75.128.210',
  Telephone: '2607768',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.266425,7.512821',
  id: '50.266425,7.512821'
}, {
  Brand: 'ARAL',
  Street: 'Saarstraße 22-26',
  PostCode: 61169,
  City: 'Friedberg',
  Latitude: '503.315.720',
  Longitude: '87.562.270',
  Telephone: '60311660487',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.331572,8.756227',
  id: '50.331572,8.756227'
}, {
  Brand: 'Eni',
  Street: 'Impler Str. 21',
  PostCode: 81371,
  City: 'Muenchen',
  Latitude: '4.812.237.785',
  Longitude: '1.154.864.215',
  Telephone: '+49 (0) 89725771',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.12237785,11.54864215',
  id: '48.12237785,11.54864215'
}, {
  Brand: 'TOTAL',
  Street: 'OLPENER STR. 690',
  PostCode: 51109,
  City: 'KOELN',
  Latitude: '5.094.499',
  Longitude: '7.062.567',
  Telephone: '0221-6908711',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.94499,7.062567',
  id: '50.94499,7.062567'
}, {
  Brand: 'OMV',
  Street: 'Unterhirschberg 4 a',
  PostCode: 82396,
  City: 'Pähl',
  Latitude: '4.789.710.000',
  Longitude: '1.118.832.000',
  Telephone: '+49-8808-921354',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '47.8971,11.18832',
  id: '47.8971,11.18832'
}, {
  Brand: 'TOTAL',
  Street: 'KESSELSDORFER STR. 214',
  PostCode: 1169,
  City: 'DRESDEN',
  Latitude: '51.039.847',
  Longitude: '13.657.067',
  Telephone: '0351-4126824',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.039847,13.657067',
  id: '51.039847,13.657067'
}, {
  Brand: 'TOTAL',
  Street: 'WESTSTR. 10',
  PostCode: 58089,
  City: 'HAGEN',
  Latitude: '51.385.874',
  Longitude: '7.437.439',
  Telephone: '02331-3483454',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.385874,7.437439',
  id: '51.385874,7.437439'
}, {
  Brand: 'OMV',
  Street: 'Solitudestr. 207',
  PostCode: 70499,
  City: 'Stuttgart',
  Latitude: '4.881.298.000',
  Longitude: '911.056.000',
  Telephone: '+49-711-8873880',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.81298,9.11056',
  id: '48.81298,9.11056'
}, {
  Brand: 'TOTAL',
  Street: '102 DONAUWOERTHER STR.',
  PostCode: 86154,
  City: 'AUGSBURG',
  Latitude: '48.389.149',
  Longitude: '10.881.633',
  Telephone: '0821-414291',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.389149,10.881633',
  id: '48.389149,10.881633'
}, {
  Brand: 'Westfalen',
  Street: 'Rheiner Landstr. 69',
  PostCode: null,
  City: 'OSNABRUECK',
  Latitude: '522.744',
  Longitude: '802.246',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.2744,8.02246',
  id: '52.2744,8.02246'
}, {
  Brand: 'ARAL',
  Street: 'Industriestraße 10a',
  PostCode: 63920,
  City: 'Großheubach',
  Latitude: '497.184.820',
  Longitude: '92.305.650',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.718482,9.230565',
  id: '49.718482,9.230565'
}, {
  Brand: 'OMV',
  Street: 'Raiffeisenstr. 1',
  PostCode: 93173,
  City: 'Wenzenbach',
  Latitude: '4.907.074.000',
  Longitude: '1.218.456.000',
  Telephone: '+49-9407-90367',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.07074,12.18456',
  id: '49.07074,12.18456'
}, {
  Brand: 'ARAL',
  Street: 'Henleinstraße 35',
  PostCode: 86368,
  City: 'Gersthofen',
  Latitude: '484.422.230',
  Longitude: '108.740.330',
  Telephone: '82142092000',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.442223,10.874033',
  id: '48.442223,10.874033'
}, {
  Brand: 'TOTAL',
  Street: 'RITTER-TUSCHL-STR. 39',
  PostCode: 94501,
  City: 'ALDERSBACH',
  Latitude: '48.590.693',
  Longitude: '13.093.868',
  Telephone: '8543916298',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.590693,13.093868',
  id: '48.590693,13.093868'
}, {
  Brand: 'ARAL',
  Street: 'Brauckstraße 41',
  PostCode: 58454,
  City: 'Witten',
  Latitude: '514.584.590',
  Longitude: '73.908.430',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.458459,7.390843',
  id: '51.458459,7.390843'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 49',
  PostCode: 53506,
  City: 'Ahrbrück',
  Latitude: '504.855.440',
  Longitude: '69.738.780',
  Telephone: '02643/6513',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:30',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:30',
  SundayFrom: '08:00',
  SundayTo: '20:30',
  IsOpen24Hours: 'Nein',
  location: '50.485544,6.973878',
  id: '50.485544,6.973878'
}, {
  Brand: 'OMV',
  Street: 'Gleiwitzer Str. 220',
  PostCode: 90475,
  City: 'Nürnberg',
  Latitude: '4.941.065.000',
  Longitude: '1.114.677.000',
  Telephone: '+49-911-805572',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.41065,11.14677',
  id: '49.41065,11.14677'
}, {
  Brand: 'Eni',
  Street: 'Rosenheimer Str. 44',
  PostCode: 83101,
  City: 'Rohrdorf',
  Latitude: '4.782.453.501',
  Longitude: '1.215.353.384',
  Telephone: '+49 (0) 80317113',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.82453501,12.15353384',
  id: '47.82453501,12.15353384'
}, {
  Brand: 'Westfalen',
  Street: 'Emschertalstr. 131',
  PostCode: null,
  City: 'DORTMUND',
  Latitude: '515.061',
  Longitude: '758.915',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.5061,7.58915',
  id: '51.5061,7.58915'
}, {
  Brand: 'Eni',
  Street: 'Homberger Str. 24',
  PostCode: 35325,
  City: 'Muecke/Bernsfeld',
  Latitude: '5.066.766.000',
  Longitude: '899.412.000',
  Telephone: '+49 (0) 66348856',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.66766,8.99412',
  id: '50.66766,8.99412'
}, {
  Brand: 'Westfalen',
  Street: 'Von-Steuben-Str. 13',
  PostCode: null,
  City: 'MUENSTER',
  Latitude: '519.549',
  Longitude: '763.226',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9549,7.63226',
  id: '51.9549,7.63226'
}, {
  Brand: 'TOTAL',
  Street: 'RUDOLF-BREITSCHEID-STR. 9b',
  PostCode: 15848,
  City: 'BEESKOW',
  Latitude: '52.166.157',
  Longitude: '1.424.021',
  Telephone: '03366-20497',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.166157,14.24021',
  id: '52.166157,14.24021'
}, {
  Brand: 'Eni',
  Street: 'Aeuss.Zwickauer Str. 16-20',
  PostCode: 9350,
  City: 'Lichtenstein',
  Latitude: '5.075.273.644',
  Longitude: '1.262.110.193',
  Telephone: '+49 (0) 37204213',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.75273644,12.62110193',
  id: '50.75273644,12.62110193'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 80',
  PostCode: 64401,
  City: 'Groß-Bieberau',
  Latitude: '498.073.140',
  Longitude: '88.252.200',
  Telephone: '6162940610',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '05:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '49.807314,8.82522',
  id: '49.807314,8.82522'
}, {
  Brand: 'ARAL',
  Street: 'Schussenstraße 10',
  PostCode: 88250,
  City: 'Weingarten',
  Latitude: '478.120.500',
  Longitude: '96.407.600',
  Telephone: '75145623',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '47.81205,9.64076',
  id: '47.81205,9.64076'
}, {
  Brand: 'TOTAL',
  Street: 'ROEMERSTR.16',
  PostCode: 63486,
  City: 'BRUCHKOEBEL',
  Latitude: '50.179.423',
  Longitude: '8.909.233',
  Telephone: '06181-576095',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.179423,8.909233',
  id: '50.179423,8.909233'
}, {
  Brand: 'ARAL',
  Street: 'Kaiserwörthdamm 3',
  PostCode: 67065,
  City: 'Ludwigshafen',
  Latitude: '494.613.590',
  Longitude: '84.345.610',
  Telephone: '-',
  WeekDayFrom: '07:00',
  WeekDayTo: '17:00',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.461359,8.434561',
  id: '49.461359,8.434561'
}, {
  Brand: 'Eni',
  Street: 'Hubertusstrasse 9',
  PostCode: 83022,
  City: 'Rosenheim',
  Latitude: '4.785.597.168',
  Longitude: '1.211.058.043',
  Telephone: '+49 (0) 80313710',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.85597168,12.11058043',
  id: '47.85597168,12.11058043'
}, {
  Brand: 'Eni',
  Street: 'Schnellstrasse Ost',
  PostCode: 55130,
  City: 'Mainz',
  Latitude: '5.000.437.200',
  Longitude: '826.258.800',
  Telephone: '+49 (0) 61316982',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.004372,8.262588',
  id: '50.004372,8.262588'
}, {
  Brand: 'ARAL',
  Street: 'Dibberser Strasse 30',
  PostCode: 21244,
  City: 'Buchholz',
  Latitude: '533.627.120',
  Longitude: '98.731.210',
  Telephone: '41815110',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.362712,9.873121',
  id: '53.362712,9.873121'
}, {
  Brand: 'TOTAL',
  Street: 'KUPFERHUETTE 7',
  PostCode: 6526,
  City: 'SANGERHAUSEN',
  Latitude: '51.486.026',
  Longitude: '11.308.634',
  Telephone: '03464-572685',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.486026,11.308634',
  id: '51.486026,11.308634'
}, {
  Brand: 'TOTAL',
  Street: '18 AUTOHOF PFEFFERHOEHE',
  PostCode: 36304,
  City: 'ALSFELD',
  Latitude: '50.733.436',
  Longitude: '9.241.473',
  Telephone: '06631-800850',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.733436,9.241473',
  id: '50.733436,9.241473'
}, {
  Brand: 'ARAL',
  Street: 'Bornstraße 221',
  PostCode: 44145,
  City: 'Dortmund',
  Latitude: '515.316.790',
  Longitude: '74.736.190',
  Telephone: '231810342',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.531679,7.473619',
  id: '51.531679,7.473619'
}, {
  Brand: 'ARAL',
  Street: 'Essener Straße 29',
  PostCode: 57234,
  City: 'Wilnsdorf',
  Latitude: '508.236.820',
  Longitude: '80.785.000',
  Telephone: '27394793763',
  WeekDayFrom: '06:30',
  WeekDayTo: '18:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '12:00',
  SundayFrom: '00:00',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.823682,8.0785',
  id: '50.823682,8.0785'
}, {
  Brand: 'ARAL',
  Street: 'Bertha-Benz-Str.',
  PostCode: 74343,
  City: 'Sachsenheim',
  Latitude: '489.578.790',
  Longitude: '90.817.670',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.957879,9.081767',
  id: '48.957879,9.081767'
}, {
  Brand: 'TOTAL',
  Street: 'VON-SAUERSTR. 27',
  PostCode: 22761,
  City: 'HAMBURG',
  Latitude: '53.565.573',
  Longitude: '9.906.537',
  Telephone: '040-89064795',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.565573,9.906537',
  id: '53.565573,9.906537'
}, {
  Brand: 'TOTAL',
  Street: 'BAHNHOFSTR. 68-72',
  PostCode: 2826,
  City: 'GOERLITZ',
  Latitude: '51.146.099',
  Longitude: '14.983.898',
  Telephone: '03581-409306',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.146099,14.983898',
  id: '51.146099,14.983898'
}, {
  Brand: 'TOTAL',
  Street: 'MAX-PLANCK-STR. B3/B33',
  PostCode: 77656,
  City: 'OFFENBURG',
  Latitude: '4.847.251',
  Longitude: '7.927.314',
  Telephone: '0781-20396585',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.47251,7.927314',
  id: '48.47251,7.927314'
}, {
  Brand: 'OMV',
  Street: 'Lonetal West A7',
  PostCode: 89537,
  City: 'Giengen',
  Latitude: '',
  Longitude: '',
  Telephone: '+49-7324-980064',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '0,0',
  id: '0,0'
}, {
  Brand: 'Eni',
  Street: 'Josephspitalstr. 12',
  PostCode: 80331,
  City: 'Muenchen',
  Latitude: '4.813.650.000',
  Longitude: '1.156.593.000',
  Telephone: '+49 (0) 89558692',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.1365,11.56593',
  id: '48.1365,11.56593'
}, {
  Brand: 'ARAL',
  Street: 'Königsberger Str.',
  PostCode: 41460,
  City: 'Neuss',
  Latitude: '512.152.150',
  Longitude: '67.035.290',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.215215,6.703529',
  id: '51.215215,6.703529'
}, {
  Brand: 'ARAL',
  Street: 'A7 / E45',
  PostCode: 31174,
  City: 'Schellerten',
  Latitude: '521.261.990',
  Longitude: '100.518.260',
  Telephone: '512133322',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.126199,10.051826',
  id: '52.126199,10.051826'
}, {
  Brand: 'ARAL',
  Street: 'Asbacher Straße 17 b',
  PostCode: 98574,
  City: 'Schmalkalden',
  Latitude: '507.193.440',
  Longitude: '104.734.890',
  Telephone: '36834650680',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.719344,10.473489',
  id: '50.719344,10.473489'
}, {
  Brand: 'Westfalen',
  Street: 'Am Zubringer 2',
  PostCode: null,
  City: 'BAD SALZUFLEN',
  Latitude: '520.826',
  Longitude: '871.713',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0826,8.71713',
  id: '52.0826,8.71713'
}, {
  Brand: 'Eni',
  Street: 'Dreisesselstr. 39',
  PostCode: 94089,
  City: 'Neureichenau',
  Latitude: '4.874.444.361',
  Longitude: '1.374.979.969',
  Telephone: '+49 (0) 85839607',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.74444361,13.74979969',
  id: '48.74444361,13.74979969'
}, {
  Brand: 'Eni',
  Street: 'Abtsgmuender Strasse 20',
  PostCode: 73433,
  City: 'Aalen',
  Latitude: '4.886.931.000',
  Longitude: '1.010.666.000',
  Telephone: '+49 (0) 73617409',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.86931,10.10666',
  id: '48.86931,10.10666'
}, {
  Brand: 'TOTAL',
  Street: 'AM RHEINUEBERGANG',
  PostCode: 77866,
  City: 'RHEINAU-FREISTETT',
  Latitude: '48.680.519',
  Longitude: '7.922.238',
  Telephone: '07844-991122/24',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.680519,7.922238',
  id: '48.680519,7.922238'
}, {
  Brand: 'TOTAL',
  Street: 'DETMOLDSTR. 1',
  PostCode: 80935,
  City: 'MUENCHEN',
  Latitude: '48.192.311',
  Longitude: '11.564.284',
  Telephone: '089-35043040',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.192311,11.564284',
  id: '48.192311,11.564284'
}, {
  Brand: 'OMV',
  Street: 'Rudolf-Diesel-Str. 1',
  PostCode: 91625,
  City: 'Schnelldorf',
  Latitude: '4.917.141.000',
  Longitude: '1.024.099.000',
  Telephone: '+49-7950-8010',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.17141,10.24099',
  id: '49.17141,10.24099'
}, {
  Brand: 'Westfalen',
  Street: 'Wessumer Str. 63',
  PostCode: null,
  City: 'AHAUS',
  Latitude: '520.823',
  Longitude: '700.263',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0823,7.00263',
  id: '52.0823,7.00263'
}, {
  Brand: 'TOTAL',
  Street: 'BAB SUED/A 24',
  PostCode: 19372,
  City: 'STOLPE',
  Latitude: '53.366.965',
  Longitude: '11.727.052',
  Telephone: '038725-20216',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.366965,11.727052',
  id: '53.366965,11.727052'
}, {
  Brand: 'TOTAL',
  Street: 'ALBRECHTSTR. 95',
  PostCode: 6844,
  City: 'DESSAU',
  Latitude: '51.848.763',
  Longitude: '12.240.352',
  Telephone: '0340-214417',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.848763,12.240352',
  id: '51.848763,12.240352'
}, {
  Brand: 'Westfalen',
  Street: 'Rheiner Str. 121',
  PostCode: null,
  City: 'EMSDETTEN',
  Latitude: '521.825',
  Longitude: '752.076',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.1825,7.52076',
  id: '52.1825,7.52076'
}, {
  Brand: 'ARAL',
  Street: 'Maarweg 126',
  PostCode: 50825,
  City: 'Köln',
  Latitude: '509.430.890',
  Longitude: '68.987.960',
  Telephone: '221541909',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.943089,6.898796',
  id: '50.943089,6.898796'
}, {
  Brand: 'TOTAL',
  Street: 'DIEDERSDORFER STR. 2',
  PostCode: 15306,
  City: 'SEELOW',
  Latitude: '5.252.988',
  Longitude: '1.437.046',
  Telephone: '03346-316',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.52988,14.37046',
  id: '52.52988,14.37046'
}, {
  Brand: 'TOTAL',
  Street: 'HAMMER LANDSTR. 204',
  PostCode: 20537,
  City: 'HAMBURG',
  Latitude: '53.554.041',
  Longitude: '10.063.172',
  Telephone: '040-211533',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.554041,10.063172',
  id: '53.554041,10.063172'
}, {
  Brand: 'ARAL',
  Street: 'Ober-Saulheimer Straße 17',
  PostCode: 55286,
  City: 'Wörrstadt',
  Latitude: '498.526.480',
  Longitude: '81.231.930',
  Telephone: '67321403',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '49.852648,8.123193',
  id: '49.852648,8.123193'
}, {
  Brand: 'ARAL',
  Street: 'Nicolaus-Otto-Straße',
  PostCode: 56727,
  City: 'Mayen',
  Latitude: '503.330.970',
  Longitude: '72.618.150',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.333097,7.261815',
  id: '50.333097,7.261815'
}, {
  Brand: 'TOTAL',
  Street: 'KARL-LIEBKNECHT-STR. 155',
  PostCode: 15711,
  City: 'ZEESEN',
  Latitude: '52.275.299',
  Longitude: '13.631.795',
  Telephone: '03375-900332',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.275299,13.631795',
  id: '52.275299,13.631795'
}, {
  Brand: 'TOTAL',
  Street: 'MELLINGHOFER STR.110',
  PostCode: 45473,
  City: 'MUELHEIM',
  Latitude: '51.445.336',
  Longitude: '6.884.433',
  Telephone: '0208-995570',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.445336,6.884433',
  id: '51.445336,6.884433'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 23',
  PostCode: 83339,
  City: 'Chieming',
  Latitude: '478.903.520',
  Longitude: '125.372.000',
  Telephone: '8664927124',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.890352,12.5372',
  id: '47.890352,12.5372'
}, {
  Brand: 'TOTAL',
  Street: 'ARNO-SCHMIDT-STR. 28 A',
  PostCode: 8134,
  City: 'WILDENFELS',
  Latitude: '50.680.678',
  Longitude: '12.592.253',
  Telephone: '037603-550711',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.680678,12.592253',
  id: '50.680678,12.592253'
}, {
  Brand: 'Westfalen',
  Street: 'Bursdamm 1',
  PostCode: null,
  City: 'DELBRUECK',
  Latitude: '517.822',
  Longitude: '862.402',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.7822,8.62402',
  id: '51.7822,8.62402'
}, {
  Brand: 'OMV',
  Street: 'Frankfurter Str. 14',
  PostCode: 97737,
  City: 'Gemünden',
  Latitude: '5.005.939.000',
  Longitude: '968.624.000',
  Telephone: '+49-9351-3471',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.05939,9.68624',
  id: '50.05939,9.68624'
}, {
  Brand: 'Eni',
  Street: 'Gerolsbacher Str. 9',
  PostCode: 86529,
  City: 'Schrobenhausen',
  Latitude: '4.855.546.000',
  Longitude: '1.126.806.000',
  Telephone: '+49 (0) 82528877',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.55546,11.26806',
  id: '48.55546,11.26806'
}, {
  Brand: 'TOTAL',
  Street: 'MUENSTERSTR. 51',
  PostCode: 49477,
  City: 'IBBENBUEREN',
  Latitude: '52.269.119',
  Longitude: '7.717.478',
  Telephone: '05451-894755',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.269119,7.717478',
  id: '52.269119,7.717478'
}, {
  Brand: 'ARAL',
  Street: 'Grotwisch 2',
  PostCode: 24589,
  City: 'Dätgen',
  Latitude: '541.800.570',
  Longitude: '99.441.700',
  Telephone: '43299136038',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '54.180057,9.94417',
  id: '54.180057,9.94417'
}, {
  Brand: 'TOTAL',
  Street: 'GOTHAER STR.9',
  PostCode: 99880,
  City: 'WALTERSHAUSEN',
  Latitude: '50.901.788',
  Longitude: '1.056.649',
  Telephone: '03622-68444',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.901788,10.56649',
  id: '50.901788,10.56649'
}, {
  Brand: 'TOTAL',
  Street: 'AHLBECKER STR. 37',
  PostCode: 17454,
  City: 'ZINNOWITZ',
  Latitude: '54.067.402',
  Longitude: '13.919.616',
  Telephone: '038377-42728',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.067402,13.919616',
  id: '54.067402,13.919616'
}, {
  Brand: 'ARAL',
  Street: 'Brückenstraße 6',
  PostCode: 87616,
  City: 'Marktoberdorf-Thalho',
  Latitude: '477.735.770',
  Longitude: '106.035.840',
  Telephone: '83425352',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '20:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '47.773577,10.603584',
  id: '47.773577,10.603584'
}, {
  Brand: 'TOTAL',
  Street: 'URBANSTR. 65',
  PostCode: 73728,
  City: 'ESSLINGEN',
  Latitude: '48.739.577',
  Longitude: '9.319.722',
  Telephone: '0711-9319777',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.739577,9.319722',
  id: '48.739577,9.319722'
}, {
  Brand: 'TOTAL',
  Street: 'FRAUENLOBSTR.112',
  PostCode: 44805,
  City: 'BOCHUM',
  Latitude: '51.515.299',
  Longitude: '7.258.893',
  Telephone: '0234-850992',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.515299,7.258893',
  id: '51.515299,7.258893'
}, {
  Brand: 'TOTAL',
  Street: 'RHEINLANDSTR. 28-30',
  PostCode: 72070,
  City: 'TUEBINGEN',
  Latitude: '48.523.507',
  Longitude: '9.039.299',
  Telephone: '07071-45366',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.523507,9.039299',
  id: '48.523507,9.039299'
}, {
  Brand: 'TOTAL',
  Street: 'DINGELSTAEDTER STRASSE 33',
  PostCode: 37308,
  City: 'HEILIGENSTADT',
  Latitude: '5.137.533',
  Longitude: '10.148.411',
  Telephone: '03606-608552',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.37533,10.148411',
  id: '51.37533,10.148411'
}, {
  Brand: 'Westfalen',
  Street: 'Sternbuschweg 103a',
  PostCode: null,
  City: 'DUISBURG',
  Latitude: '514.267',
  Longitude: '679.122',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.4267,6.79122',
  id: '51.4267,6.79122'
}, {
  Brand: 'Eni',
  Street: 'Detter Strasse 1A',
  PostCode: 94469,
  City: 'Deggendorf',
  Latitude: '4.884.126.000',
  Longitude: '1.295.684.000',
  Telephone: '+49 (0) 99138308',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.84126,12.95684',
  id: '48.84126,12.95684'
}, {
  Brand: 'ARAL',
  Street: 'Am Lunedeich 138',
  PostCode: 27572,
  City: 'Bremerhaven',
  Latitude: '535.051.644',
  Longitude: '85.896.677',
  Telephone: '0471-9730120',
  WeekDayFrom: '00:00',
  WeekDayTo: '00:00',
  SaturdayFrom: '00:00',
  SaturdayTo: '00:00',
  SundayFrom: '00:00',
  SundayTo: '00:00',
  IsOpen24Hours: 'Ja',
  location: '53.5051644,8.5896677',
  id: '53.5051644,8.5896677'
}, {
  Brand: 'Westfalen',
  Street: 'Borsteler Landstr. 47',
  PostCode: null,
  City: 'ACHIM',
  Latitude: '530.175',
  Longitude: '903.867',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.0175,9.03867',
  id: '53.0175,9.03867'
}, {
  Brand: 'ARAL',
  Street: 'Eisenbahnstraße 8',
  PostCode: 78315,
  City: 'Radolfzell',
  Latitude: '477.415.940',
  Longitude: '89.547.800',
  Telephone: '7732988268',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:30',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:30',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.741594,8.95478',
  id: '47.741594,8.95478'
}, {
  Brand: 'ARAL',
  Street: 'Züttlinger Straße 5',
  PostCode: 74219,
  City: 'Möckmühl',
  Latitude: '493.219.300',
  Longitude: '93.638.250',
  Telephone: '62982509',
  WeekDayFrom: '04:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '04:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.32193,9.363825',
  id: '49.32193,9.363825'
}, {
  Brand: 'ARAL',
  Street: 'Burghausener Straße 7',
  PostCode: 84558,
  City: 'Kirchweidach',
  Latitude: '480.877.530',
  Longitude: '126.457.670',
  Telephone: '8623240',
  WeekDayFrom: '07:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.087753,12.645767',
  id: '48.087753,12.645767'
}, {
  Brand: 'ARAL',
  Street: 'Lessingstraße 2 a',
  PostCode: 8294,
  City: 'Lößnitz',
  Latitude: '506.115.230',
  Longitude: '127.200.640',
  Telephone: '377133411',
  WeekDayFrom: '05:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '21:00',
  SundayFrom: '07:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '50.611523,12.720064',
  id: '50.611523,12.720064'
}, {
  Brand: 'Eni',
  Street: 'Heidelberger Strasse 49',
  PostCode: 74821,
  City: 'Mosbach',
  Latitude: '4.934.331.178',
  Longitude: '910.529.611',
  Telephone: '+49 (0) 62617767',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.34331178,9.10529611',
  id: '49.34331178,9.10529611'
}, {
  Brand: 'ARAL',
  Street: 'An der BAB A5 Ost',
  PostCode: 76694,
  City: 'Forst',
  Latitude: '491.610.020',
  Longitude: '85.691.260',
  Telephone: '72512200',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.161002,8.569126',
  id: '49.161002,8.569126'
}, {
  Brand: 'Westfalen',
  Street: 'Zum Heubach 3',
  PostCode: null,
  City: 'REKEN',
  Latitude: '518.411',
  Longitude: '709.537',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8411,7.09537',
  id: '51.8411,7.09537'
}, {
  Brand: 'TOTAL',
  Street: 'HARENBERGER MEILE 39',
  PostCode: 30926,
  City: 'SEELZE',
  Latitude: '5.237.781',
  Longitude: '9.618.669',
  Telephone: '05137-877106',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.37781,9.618669',
  id: '52.37781,9.618669'
}, {
  Brand: 'OMV',
  Street: 'Überkinger Str. 50',
  PostCode: 73312,
  City: 'Geislingen',
  Latitude: '4.862.233.000',
  Longitude: '982.286.000',
  Telephone: '+49-7331-60763',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.62233,9.82286',
  id: '48.62233,9.82286'
}, {
  Brand: 'Eni',
  Street: 'Donaustaufer Str. 316',
  PostCode: 93055,
  City: 'Regensburg',
  Latitude: '4.902.503.000',
  Longitude: '1.214.775.000',
  Telephone: '+49 (0) 94145318',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.02503,12.14775',
  id: '49.02503,12.14775'
}, {
  Brand: 'ARAL',
  Street: 'A 45',
  PostCode: 57258,
  City: 'Freudenberg',
  Latitude: '508.766.230',
  Longitude: '79.482.440',
  Telephone: '27345471',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.876623,7.948244',
  id: '50.876623,7.948244'
}, {
  Brand: 'OMV',
  Street: 'Lindauer Str. 12',
  PostCode: 88079,
  City: 'Kressbronn',
  Latitude: '4.759.260.000',
  Longitude: '960.258.000',
  Telephone: '+49-7543-8034',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.5926,9.60258',
  id: '47.5926,9.60258'
}, {
  Brand: 'ARAL',
  Street: 'Geschwister-Scholl-Straße 32',
  PostCode: 39307,
  City: 'Genthin',
  Latitude: '524.087.850',
  Longitude: '121.627.450',
  Telephone: '39339482699',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.408785,12.162745',
  id: '52.408785,12.162745'
}, {
  Brand: 'ARAL',
  Street: 'Marktstraße 34',
  PostCode: 36132,
  City: 'Eiterfeld',
  Latitude: '507.621.900',
  Longitude: '97.920.480',
  Telephone: '66727089',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.76219,9.792048',
  id: '50.76219,9.792048'
}, {
  Brand: 'OMV',
  Street: 'Ottobrunner Str. 116',
  PostCode: 81737,
  City: 'München',
  Latitude: '4.810.269.000',
  Longitude: '1.162.537.000',
  Telephone: '+49-89-63894012',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.10269,11.62537',
  id: '48.10269,11.62537'
}, {
  Brand: 'ARAL',
  Street: 'A31',
  PostCode: 49835,
  City: 'Wietmarschen',
  Latitude: '525.286.110',
  Longitude: '71.968.890',
  Telephone: '5925998264',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.528611,7.196889',
  id: '52.528611,7.196889'
}, {
  Brand: 'OMV',
  Street: 'Hauptstr. 89',
  PostCode: 63322,
  City: 'Rödermark',
  Latitude: '5.000.429.000',
  Longitude: '881.290.000',
  Telephone: '+49-6074-90585',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.00429,8.8129',
  id: '50.00429,8.8129'
}, {
  Brand: 'Westfalen',
  Street: 'Benninghofer Str. 265',
  PostCode: null,
  City: 'DORTMUND',
  Latitude: '514.687',
  Longitude: '751.574',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.4687,7.51574',
  id: '51.4687,7.51574'
}, {
  Brand: 'ARAL',
  Street: 'Niedersachsenweg/Unterste',
  PostCode: 44309,
  City: 'Dortmund',
  Latitude: '515.290.890',
  Longitude: '75.276.600',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.529089,7.52766',
  id: '51.529089,7.52766'
}, {
  Brand: 'ARAL',
  Street: 'Altenessener Straße 288',
  PostCode: 45326,
  City: 'Essen',
  Latitude: '514.923.650',
  Longitude: '70.098.310',
  Telephone: '2018378311',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.492365,7.009831',
  id: '51.492365,7.009831'
}, {
  Brand: 'TOTAL',
  Street: 'ERLENWIESE 21',
  PostCode: 35794,
  City: 'MENGERSKIRCHEN',
  Latitude: '5.053.168',
  Longitude: '8.143.343',
  Telephone: '06476-411250',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.53168,8.143343',
  id: '50.53168,8.143343'
}, {
  Brand: 'TOTAL',
  Street: 'HAUPTSTR.286',
  PostCode: 97896,
  City: 'FREUDENBERG',
  Latitude: '4.975.044',
  Longitude: '9.325.976',
  Telephone: '09375-306',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.75044,9.325976',
  id: '49.75044,9.325976'
}, {
  Brand: 'OMV',
  Street: 'Konstanzer Str. 8',
  PostCode: 79761,
  City: 'Waldshut-Tiengen',
  Latitude: '4.761.076.000',
  Longitude: '823.031.000',
  Telephone: '+49-7751-3027',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.61076,8.23031',
  id: '47.61076,8.23031'
}, {
  Brand: 'TOTAL',
  Street: 'SCHERMBECKER LSTR.28',
  PostCode: 46485,
  City: 'WESEL',
  Latitude: '51.659.519',
  Longitude: '6.638.875',
  Telephone: '0281-50167',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.659519,6.638875',
  id: '51.659519,6.638875'
}, {
  Brand: 'Westfalen',
  Street: 'Schlosser Str 2',
  PostCode: null,
  City: 'OLFEN',
  Latitude: '51.706.752',
  Longitude: '7.392.409',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.706752,7.392409',
  id: '51.706752,7.392409'
}, {
  Brand: 'TOTAL',
  Street: 'STEGENWALDHAUSER STR. 1',
  PostCode: 95152,
  City: 'SELBITZ OT SELLANGER',
  Latitude: '50.324.341',
  Longitude: '11.784.478',
  Telephone: '9280953550',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.324341,11.784478',
  id: '50.324341,11.784478'
}, {
  Brand: 'Eni',
  Street: 'Stettener Str. 51',
  PostCode: 88709,
  City: 'Meersburg',
  Latitude: '4.769.355.407',
  Longitude: '927.939.105',
  Telephone: '+49 (0) 75329618',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.69355407,9.27939105',
  id: '47.69355407,9.27939105'
}, {
  Brand: 'TOTAL',
  Street: 'HEERSTR. 78',
  PostCode: 60488,
  City: 'FRANKFURT',
  Latitude: '50.147.081',
  Longitude: '8.612.149',
  Telephone: '069-762965',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.147081,8.612149',
  id: '50.147081,8.612149'
}, {
  Brand: 'TOTAL',
  Street: 'HAUPTSTR. 103',
  PostCode: 65843,
  City: 'SULZBACH',
  Latitude: '501.378',
  Longitude: '8.519.186',
  Telephone: '06196-71144',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.1378,8.519186',
  id: '50.1378,8.519186'
}, {
  Brand: 'Eni',
  Street: 'Schlierseer Strasse 9',
  PostCode: 83734,
  City: 'Hausham',
  Latitude: '4.774.636.000',
  Longitude: '1.184.433.000',
  Telephone: '+49 (0) 80269327',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.74636,11.84433',
  id: '47.74636,11.84433'
}, {
  Brand: 'Eni',
  Street: 'Oeslauer Strasse 13',
  PostCode: 96472,
  City: 'Roedental',
  Latitude: '5.029.485.000',
  Longitude: '1.104.786.000',
  Telephone: '+49 (0) 95638252',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.29485,11.04786',
  id: '50.29485,11.04786'
}, {
  Brand: 'ARAL',
  Street: 'Würzburger Straße 64',
  PostCode: 74736,
  City: 'Hardheim',
  Latitude: '496.117.740',
  Longitude: '94.873.010',
  Telephone: '628350531',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.611774,9.487301',
  id: '49.611774,9.487301'
}, {
  Brand: 'TOTAL',
  Street: '41 SAARBRUECKENER STR.',
  PostCode: 74078,
  City: 'HEILBRONN',
  Latitude: '49.155.181',
  Longitude: '9.176.363',
  Telephone: '07131-42829',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.155181,9.176363',
  id: '49.155181,9.176363'
}, {
  Brand: 'ARAL',
  Street: 'Stuttgarter Straße 112 (B',
  PostCode: 73054,
  City: 'Eislingen',
  Latitude: '486.960.900',
  Longitude: '96.950.350',
  Telephone: '7161821404',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.69609,9.695035',
  id: '48.69609,9.695035'
}, {
  Brand: 'OMV',
  Street: 'Bahnhofstr. 20',
  PostCode: 91171,
  City: 'Greding',
  Latitude: '4.904.364.000',
  Longitude: '1.134.997.000',
  Telephone: '+49-8463-605152',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.04364,11.34997',
  id: '49.04364,11.34997'
}, {
  Brand: 'TOTAL',
  Street: 'ASBACHER STR. 55',
  PostCode: 53545,
  City: 'LINZ',
  Latitude: '50.569.706',
  Longitude: '7.286.899',
  Telephone: '02644-2749',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.569706,7.286899',
  id: '50.569706,7.286899'
}, {
  Brand: 'ARAL',
  Street: 'Hauptstraße 12',
  PostCode: 87656,
  City: 'Germaringen',
  Latitude: '479.242.240',
  Longitude: '106.689.280',
  Telephone: '834162283',
  WeekDayFrom: '06:30',
  WeekDayTo: '20:30',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:30',
  SundayFrom: '08:00',
  SundayTo: '20:30',
  IsOpen24Hours: 'Nein',
  location: '47.924224,10.668928',
  id: '47.924224,10.668928'
}, {
  Brand: 'TOTAL',
  Street: 'GRIEDELER STR.72',
  PostCode: 35510,
  City: 'BUTZBACH',
  Latitude: '50.436.532',
  Longitude: '8.681.493',
  Telephone: '06033-60763',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.436532,8.681493',
  id: '50.436532,8.681493'
}, {
  Brand: 'TOTAL',
  Street: 'STEINSTR.11',
  PostCode: 16303,
  City: 'SCHWEDT',
  Latitude: '53.071.391',
  Longitude: '14.254.327',
  Telephone: '03332-417992',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.071391,14.254327',
  id: '53.071391,14.254327'
}, {
  Brand: 'TOTAL',
  Street: 'HAUPTSTR. 200',
  PostCode: 16547,
  City: 'BIRKENWERDER',
  Latitude: '52.702.871',
  Longitude: '13.272.647',
  Telephone: '03303-501693',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.702871,13.272647',
  id: '52.702871,13.272647'
}, {
  Brand: 'TOTAL',
  Street: 'AUTOHOF ROSENGARTENWEG 1',
  PostCode: 67281,
  City: 'KIRCHHEIM',
  Latitude: '49.554.165',
  Longitude: '8.185.635',
  Telephone: '06359-9692132',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.554165,8.185635',
  id: '49.554165,8.185635'
}, {
  Brand: 'TOTAL',
  Street: 'DOTZHEIMER STR. 184',
  PostCode: 65197,
  City: 'WIESBADEN',
  Latitude: '50.076.068',
  Longitude: '820.861',
  Telephone: '0611-420626',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.076068,8.20861',
  id: '50.076068,8.20861'
}, {
  Brand: 'Westfalen',
  Street: 'Luebecker Str. 55',
  PostCode: null,
  City: 'MINDEN',
  Latitude: '522.746',
  Longitude: '889.266',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.2746,8.89266',
  id: '52.2746,8.89266'
}, {
  Brand: 'TOTAL',
  Street: 'AN DER B 255',
  PostCode: 35756,
  City: 'MITTENAAR',
  Latitude: '50.698.278',
  Longitude: '840.891',
  Telephone: '02778-913110',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.698278,8.40891',
  id: '50.698278,8.40891'
}, {
  Brand: 'TOTAL',
  Street: 'ALFREDSTR. 248',
  PostCode: 45133,
  City: 'ESSEN',
  Latitude: '51.421.944',
  Longitude: '6.998.477',
  Telephone: '0201-410115',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.421944,6.998477',
  id: '51.421944,6.998477'
}, {
  Brand: 'Westfalen',
  Street: 'Westheider Weg 46 - 48',
  PostCode: null,
  City: 'VERSMOLD',
  Latitude: '520.439',
  Longitude: '8.1492',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0439,8.1492',
  id: '52.0439,8.1492'
}, {
  Brand: 'ARAL',
  Street: 'Lindenstraße 5',
  PostCode: 38312,
  City: 'Flöthe',
  Latitude: '520.762.150',
  Longitude: '104.954.590',
  Telephone: '5339928366',
  WeekDayFrom: '04:30',
  WeekDayTo: '23:59',
  SaturdayFrom: '04:30',
  SaturdayTo: '23:59',
  SundayFrom: '04:30',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.076215,10.495459',
  id: '52.076215,10.495459'
}, {
  Brand: 'TOTAL',
  Street: 'SCHMALKALDER STRASSE 13',
  PostCode: 45665,
  City: 'RECKLINGHAUSEN',
  Latitude: '51.596.634',
  Longitude: '7.244.881',
  Telephone: '02361-9387600',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.596634,7.244881',
  id: '51.596634,7.244881'
}, {
  Brand: 'ARAL',
  Street: 'Lindenstraße 2',
  PostCode: 9241,
  City: 'Mühlau',
  Latitude: '508.985.670',
  Longitude: '127.742.610',
  Telephone: '37226157',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Ja',
  location: '50.898567,12.774261',
  id: '50.898567,12.774261'
}, {
  Brand: 'ARAL',
  Street: 'Molkerei-/Kervenheimer St',
  PostCode: 47589,
  City: 'Uedem',
  Latitude: '516.582.440',
  Longitude: '62.752.040',
  Telephone: '2825539955',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.658244,6.275204',
  id: '51.658244,6.275204'
}, {
  Brand: 'TOTAL',
  Street: 'MAGDEBURGER STR. 28',
  PostCode: 39245,
  City: 'GOMMERN',
  Latitude: '52.081.546',
  Longitude: '11.815.554',
  Telephone: '039200-51317',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.081546,11.815554',
  id: '52.081546,11.815554'
}, {
  Brand: 'TOTAL',
  Street: 'BAB A8 - AUGSBURG OST',
  PostCode: 86169,
  City: 'AUGSBURG',
  Latitude: '48.412.052',
  Longitude: '10.912.778',
  Telephone: '82148044019',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.412052,10.912778',
  id: '48.412052,10.912778'
}, {
  Brand: 'TOTAL',
  Street: 'HORSTER STR. 69',
  PostCode: 45897,
  City: 'GELSENKIRCHEN',
  Latitude: '5.157.352',
  Longitude: '704.802',
  Telephone: '0209-594033',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.57352,7.04802',
  id: '51.57352,7.04802'
}, {
  Brand: 'Eni',
  Street: 'Allguer Str. 7',
  PostCode: 87642,
  City: 'Halblech',
  Latitude: '4.763.806.000',
  Longitude: '1.082.567.000',
  Telephone: '+49 (0) 83689404',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.63806,10.82567',
  id: '47.63806,10.82567'
}, {
  Brand: 'Westfalen',
  Street: 'Butjadinger Str. 6',
  PostCode: null,
  City: 'NORDENHAM',
  Latitude: '534.778',
  Longitude: '845.039',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.4778,8.45039',
  id: '53.4778,8.45039'
}, {
  Brand: 'Westfalen',
  Street: 'Wilhelm-Tenhagen-Str. 1',
  PostCode: null,
  City: 'BOTTROP',
  Latitude: '515.353',
  Longitude: '697.838',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.5353,6.97838',
  id: '51.5353,6.97838'
}, {
  Brand: 'Eni',
  Street: 'Grosse Allee 17',
  PostCode: 89407,
  City: 'Dillingen',
  Latitude: '4.858.051.000',
  Longitude: '1.049.173.000',
  Telephone: '+49 (0) 90718062',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.58051,10.49173',
  id: '48.58051,10.49173'
}, {
  Brand: 'ARAL',
  Street: 'A 7',
  PostCode: 29646,
  City: 'Bispingen',
  Latitude: '531.086.850',
  Longitude: '99.828.820',
  Telephone: '519498550',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.108685,9.982882',
  id: '53.108685,9.982882'
}, {
  Brand: 'TOTAL',
  Street: 'HEITERER BLICK 2',
  PostCode: 4720,
  City: 'GROSSWEITZSCHEN',
  Latitude: '51.152.499',
  Longitude: '13.120.292',
  Telephone: '03431-729730',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.152499,13.120292',
  id: '51.152499,13.120292'
}, {
  Brand: 'TOTAL',
  Street: 'OFFENBURGER STR. 36',
  PostCode: 77855,
  City: 'ACHERN',
  Latitude: '48.605.389',
  Longitude: '8.032.417',
  Telephone: '07841-21364',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.605389,8.032417',
  id: '48.605389,8.032417'
}, {
  Brand: 'Westfalen',
  Street: 'Friesoyther Str. 2',
  PostCode: null,
  City: 'BOESEL',
  Latitude: '530.061',
  Longitude: '795.418',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.0061,7.95418',
  id: '53.0061,7.95418'
}, {
  Brand: 'Westfalen',
  Street: 'Lindenstr. 35',
  PostCode: null,
  City: 'NEUENKIRCHEN',
  Latitude: '524.194',
  Longitude: '7.8381',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.4194,7.8381',
  id: '52.4194,7.8381'
}, {
  Brand: 'TOTAL',
  Street: 'HOENOWER CHAUSSEE 1 A',
  PostCode: 15345,
  City: 'ALTLANDSBERG',
  Latitude: '52.556.613',
  Longitude: '1.371.417',
  Telephone: '033438-61811',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.556613,13.71417',
  id: '52.556613,13.71417'
}, {
  Brand: 'TOTAL',
  Street: 'ALTE HEERSTR.2-4',
  PostCode: 49124,
  City: 'GEORGSMARIENHUETTE',
  Latitude: '52.222.064',
  Longitude: '8.083.536',
  Telephone: '05401-43174',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.222064,8.083536',
  id: '52.222064,8.083536'
}, {
  Brand: 'TOTAL',
  Street: 'BARLEBER CH. 3',
  PostCode: 39126,
  City: 'MAGDEBURG',
  Latitude: '5.217.856',
  Longitude: '11.629.122',
  Telephone: '0391-2530930',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.17856,11.629122',
  id: '52.17856,11.629122'
}, {
  Brand: 'TOTAL',
  Street: 'SCHATTHAEUSER STR. 44',
  PostCode: 69168,
  City: 'WIESLOCH',
  Latitude: '49.303.297',
  Longitude: '873.808',
  Telephone: '06222-772652',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.303297,8.73808',
  id: '49.303297,8.73808'
}, {
  Brand: 'ARAL',
  Street: 'Werner-von-Siemens-Str.',
  PostCode: 28832,
  City: 'Achim',
  Latitude: '530.230.480',
  Longitude: '90.668.200',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.023048,9.06682',
  id: '53.023048,9.06682'
}, {
  Brand: 'Eni',
  Street: 'Verdistrasse 141',
  PostCode: 81247,
  City: 'Muenchen',
  Latitude: '4.816.447.242',
  Longitude: '1.145.922.867',
  Telephone: '+49 (0) 89811133',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.16447242,11.45922867',
  id: '48.16447242,11.45922867'
}, {
  Brand: 'Eni',
  Street: 'Herterichstrasse 111',
  PostCode: 81477,
  City: 'Muenchen',
  Latitude: '4.807.513.000',
  Longitude: '1.151.092.000',
  Telephone: '+49 (0) 89795346',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.07513,11.51092',
  id: '48.07513,11.51092'
}, {
  Brand: 'TOTAL',
  Street: 'MUNDSBURGER DAMM 47',
  PostCode: 22087,
  City: 'HAMBURG',
  Latitude: '53.568.682',
  Longitude: '10.024.122',
  Telephone: '040-22739104',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.568682,10.024122',
  id: '53.568682,10.024122'
}, {
  Brand: 'Westfalen',
  Street: 'Mittelstr. 20',
  PostCode: null,
  City: 'HORN',
  Latitude: '518.713',
  Longitude: '894.023',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8713,8.94023',
  id: '51.8713,8.94023'
}, {
  Brand: 'TOTAL',
  Street: 'STETTINER STR. 100',
  PostCode: 17291,
  City: 'PRENZLAU',
  Latitude: '53.329.198',
  Longitude: '13.867.499',
  Telephone: '03984-2888',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.329198,13.867499',
  id: '53.329198,13.867499'
}, {
  Brand: 'TOTAL',
  Street: 'SCHLACHTHOFSTR. 14',
  PostCode: 99085,
  City: 'ERFURT',
  Latitude: '50.986.245',
  Longitude: '11.038.236',
  Telephone: '0361-5623517',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.986245,11.038236',
  id: '50.986245,11.038236'
}, {
  Brand: 'TOTAL',
  Street: 'PENIGER STR.40',
  PostCode: 4654,
  City: 'FROHBURG',
  Latitude: '51.049.725',
  Longitude: '12.556.274',
  Telephone: '034348-51177',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.049725,12.556274',
  id: '51.049725,12.556274'
}, {
  Brand: 'ARAL',
  Street: 'Daniel-Eckhard-Str. 8',
  PostCode: 45356,
  City: 'Essen',
  Latitude: '514.998.620',
  Longitude: '69.813.550',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.499862,6.981355',
  id: '51.499862,6.981355'
}, {
  Brand: 'TOTAL',
  Street: 'MAINZER STR. 4',
  PostCode: 65550,
  City: 'LIMBURG-LINTER',
  Latitude: '50.370.873',
  Longitude: '808.469',
  Telephone: '06431-47600',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.370873,8.08469',
  id: '50.370873,8.08469'
}, {
  Brand: 'Westfalen',
  Street: 'Bockhorner Str. 78',
  PostCode: null,
  City: 'VAREL',
  Latitude: '533.849',
  Longitude: '805.711',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.3849,8.05711',
  id: '53.3849,8.05711'
}, {
  Brand: 'OMV',
  Street: 'Neuenstädter Str. 42',
  PostCode: 74172,
  City: 'Neckarsulm',
  Latitude: '4.919.575.000',
  Longitude: '922.982.000',
  Telephone: '+49-7132-6506',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.19575,9.22982',
  id: '49.19575,9.22982'
}, {
  Brand: 'OMV',
  Street: 'Bodenseestr. 9',
  PostCode: 88048,
  City: 'Friedrichshafen',
  Latitude: '4.768.706.000',
  Longitude: '949.084.000',
  Telephone: '+49-7541-52410',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.68706,9.49084',
  id: '47.68706,9.49084'
}, {
  Brand: 'Eni',
  Street: 'Welzheimer Strasse 22',
  PostCode: 73614,
  City: 'Schorndorf',
  Latitude: '4.881.425.000',
  Longitude: '952.972.000',
  Telephone: '+49 (0) 71819797',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.81425,9.52972',
  id: '48.81425,9.52972'
}, {
  Brand: 'ARAL',
  Street: 'Salinenstraße 56',
  PostCode: 99085,
  City: 'Erfurt',
  Latitude: '510.068.400',
  Longitude: '110.454.090',
  Telephone: '36126232610',
  WeekDayFrom: '04:30',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.00684,11.045409',
  id: '51.00684,11.045409'
}, {
  Brand: 'ARAL',
  Street: 'Hermannstraße 105-109',
  PostCode: 44263,
  City: 'Dortmund',
  Latitude: '514.873.910',
  Longitude: '75.104.370',
  Telephone: '231422063',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '51.487391,7.510437',
  id: '51.487391,7.510437'
}, {
  Brand: 'Eni',
  Street: 'Ostpreussendamm 89',
  PostCode: 12207,
  City: 'Berlin',
  Latitude: '5.241.503.211',
  Longitude: '1.330.259.409',
  Telephone: '+49 (0) 30755197',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '52.41503211,13.30259409',
  id: '52.41503211,13.30259409'
}, {
  Brand: 'ARAL',
  Street: 'Drachenloch 3',
  PostCode: 78052,
  City: 'Villingen-Schwenningen',
  Latitude: '480.757.660',
  Longitude: '84.916.480',
  Telephone: '07721/9959187',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.075766,8.491648',
  id: '48.075766,8.491648'
}, {
  Brand: 'OMV',
  Street: 'Am Aicher Feld 1',
  PostCode: 84032,
  City: 'Altdorf',
  Latitude: '4.856.582.000',
  Longitude: '1.210.093.000',
  Telephone: '+49-871-95389336',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.56582,12.10093',
  id: '48.56582,12.10093'
}, {
  Brand: 'TOTAL',
  Street: '61 CHAUSSEESTR.',
  PostCode: 10115,
  City: 'BERLIN',
  Latitude: '52.537.242',
  Longitude: '13.375.376',
  Telephone: '030-25762994',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.537242,13.375376',
  id: '52.537242,13.375376'
}, {
  Brand: 'ARAL',
  Street: 'Friedrich-Engels-Straße 2',
  PostCode: 51545,
  City: 'Waldbröl',
  Latitude: '508.905.700',
  Longitude: '76.474.130',
  Telephone: '22912244',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.89057,7.647413',
  id: '50.89057,7.647413'
}, {
  Brand: 'TOTAL',
  Street: 'INDUSTRIESTR.22',
  PostCode: 67227,
  City: 'FRANKENTHAL',
  Latitude: '49.551.375',
  Longitude: '8.347.798',
  Telephone: '06233-7370237',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.551375,8.347798',
  id: '49.551375,8.347798'
}, {
  Brand: 'TOTAL',
  Street: 'HEINRICHPLATZ 3',
  PostCode: 45888,
  City: 'GELSENKIRCHEN',
  Latitude: '51.512.288',
  Longitude: '7.111.116',
  Telephone: '0209-1659850',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.512288,7.111116',
  id: '51.512288,7.111116'
}, {
  Brand: 'TOTAL',
  Street: 'WERLER STR. 60',
  PostCode: 58706,
  City: 'MENDEN',
  Latitude: '51.447.844',
  Longitude: '7.786.557',
  Telephone: '02373-3544',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.447844,7.786557',
  id: '51.447844,7.786557'
}, {
  Brand: 'TOTAL',
  Street: 'OLPER STR.41',
  PostCode: 51491,
  City: 'OVERATH',
  Latitude: '50.946.921',
  Longitude: '7.217.666',
  Telephone: '02204-73484',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.946921,7.217666',
  id: '50.946921,7.217666'
}, {
  Brand: 'TOTAL',
  Street: 'BAB A 620 NORDSEITE',
  PostCode: 66740,
  City: 'SAARLOUIS',
  Latitude: '49.299.819',
  Longitude: '6.749.409',
  Telephone: '06831-1585',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.299819,6.749409',
  id: '49.299819,6.749409'
}, {
  Brand: 'TOTAL',
  Street: 'OSTRING 140',
  PostCode: 47574,
  City: 'GOCH',
  Latitude: '51.672.499',
  Longitude: '6.172.083',
  Telephone: '02823-7406',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.672499,6.172083',
  id: '51.672499,6.172083'
}, {
  Brand: 'ARAL',
  Street: 'Kocheler Straße 26',
  PostCode: 83670,
  City: 'Bad Heilbrunn',
  Latitude: '477.413.270',
  Longitude: '114.529.680',
  Telephone: '8046188045',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:30',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '47.741327,11.452968',
  id: '47.741327,11.452968'
}, {
  Brand: 'TOTAL',
  Street: 'GRENZHOEFER WEG 35',
  PostCode: 69123,
  City: 'HEIDELBERG',
  Latitude: '49.424.057',
  Longitude: '8.638.456',
  Telephone: '06221-705929',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.424057,8.638456',
  id: '49.424057,8.638456'
}, {
  Brand: 'TOTAL',
  Street: 'FUESSENER STR. 2A',
  PostCode: 87471,
  City: 'DURACH',
  Latitude: '47.699.386',
  Longitude: '10.3415',
  Telephone: '0831-63279',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.699386,10.3415',
  id: '47.699386,10.3415'
}, {
  Brand: 'ARAL',
  Street: 'Naheweinstraße 182',
  PostCode: 55450,
  City: 'Langenlonsheim',
  Latitude: '499.011.010',
  Longitude: '78.993.610',
  Telephone: '6704961595',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.901101,7.899361',
  id: '49.901101,7.899361'
}, {
  Brand: 'Eni',
  Street: 'Kreisstrasse',
  PostCode: 66578,
  City: 'Schiffweiler',
  Latitude: '4.935.391.000',
  Longitude: '713.962.000',
  Telephone: '+49 (0) 68219637',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.35391,7.13962',
  id: '49.35391,7.13962'
}, {
  Brand: 'Avanti',
  Street: 'Aldingerstrasse 170',
  PostCode: 71638,
  City: 'Ludwigsburg',
  Latitude: '4.888.007.000',
  Longitude: '921.837.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.88007,9.21837',
  id: '48.88007,9.21837'
}, {
  Brand: 'TOTAL',
  Street: 'UNTERE WIESEN 2',
  PostCode: 76437,
  City: 'RASTATT',
  Latitude: '48.861.316',
  Longitude: '8.239.667',
  Telephone: '07222-830839',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.861316,8.239667',
  id: '48.861316,8.239667'
}, {
  Brand: 'Westfalen',
  Street: 'Dorfstr. 4',
  PostCode: null,
  City: 'NEUHARLINGERSIEL',
  Latitude: '536.841',
  Longitude: '768.646',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.6841,7.68646',
  id: '53.6841,7.68646'
}, {
  Brand: 'ARAL',
  Street: 'Schellweilerstraße 1',
  PostCode: 66871,
  City: 'Konken',
  Latitude: '495.137.800',
  Longitude: '73.720.800',
  Telephone: '63849933155',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '49.51378,7.37208',
  id: '49.51378,7.37208'
}, {
  Brand: 'Eni',
  Street: 'Herborner Str. 34',
  PostCode: 35096,
  City: 'Weimar/Nieder-Weimar',
  Latitude: '5.076.000.475',
  Longitude: '873.340.648',
  Telephone: '+49 (0) 64217615',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.76000475,8.73340648',
  id: '50.76000475,8.73340648'
}, {
  Brand: 'TOTAL',
  Street: 'HOHENSTEINER STR.44',
  PostCode: 9385,
  City: 'LUGAU',
  Latitude: '50.746.735',
  Longitude: '1.274.194',
  Telephone: '037295-41978',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.746735,12.74194',
  id: '50.746735,12.74194'
}, {
  Brand: 'ARAL',
  Street: 'A7',
  PostCode: 36275,
  City: 'Kirchheim',
  Latitude: '508.508.471',
  Longitude: '95.860.774',
  Telephone: '66255116',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.8508471,9.5860774',
  id: '50.8508471,9.5860774'
}, {
  Brand: 'TOTAL',
  Street: 'FORSTSTRASSE 15',
  PostCode: 40597,
  City: 'DUESSELDORF',
  Latitude: '51.168.945',
  Longitude: '6.875.034',
  Telephone: '0211-7100357',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.168945,6.875034',
  id: '51.168945,6.875034'
}, {
  Brand: 'Eni',
  Street: 'Mannheimer Str. 2',
  PostCode: 67134,
  City: 'Birkenheide',
  Latitude: '4.947.983.000',
  Longitude: '826.447.000',
  Telephone: '+49 (0) 62373301',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.47983,8.26447',
  id: '49.47983,8.26447'
}, {
  Brand: 'TOTAL',
  Street: 'KOLPINGSTR.32',
  PostCode: 74081,
  City: 'HEILBRONN',
  Latitude: '49.122.806',
  Longitude: '9.195.898',
  Telephone: '07131-255313',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.122806,9.195898',
  id: '49.122806,9.195898'
}, {
  Brand: 'TOTAL',
  Street: 'MARGARETE-SOMMER-STR. 2',
  PostCode: 10407,
  City: 'BERLIN',
  Latitude: '52.530.831',
  Longitude: '13.440.946',
  Telephone: '030-42852514',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.530831,13.440946',
  id: '52.530831,13.440946'
}, {
  Brand: 'ARAL',
  Street: 'Ilexstraße 5',
  PostCode: 26639,
  City: 'Wiesmoor',
  Latitude: '533.756.280',
  Longitude: '77.498.060',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.375628,7.749806',
  id: '53.375628,7.749806'
}, {
  Brand: 'TOTAL',
  Street: 'LEHER HEERSTR. 98',
  PostCode: 28359,
  City: 'BREMEN',
  Latitude: '53.101.569',
  Longitude: '8.878.291',
  Telephone: '0421-234565',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.101569,8.878291',
  id: '53.101569,8.878291'
}, {
  Brand: 'ARAL',
  Street: 'An der Westumgehung 1',
  PostCode: 85521,
  City: 'Ottobrunn',
  Latitude: '480.720.330',
  Longitude: '116.522.640',
  Telephone: '8962749631',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '48.072033,11.652264',
  id: '48.072033,11.652264'
}, {
  Brand: 'TOTAL',
  Street: 'BINDERSLEBENER LANDSTR. 98',
  PostCode: 99092,
  City: 'ERFURT',
  Latitude: '50.972.397',
  Longitude: '10.972.779',
  Telephone: '0361-2229393',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.972397,10.972779',
  id: '50.972397,10.972779'
}, {
  Brand: 'Eni',
  Street: 'Magdeburger Chaussee 83',
  PostCode: 39288,
  City: 'Burg',
  Latitude: '5.226.464.000',
  Longitude: '1.184.645.000',
  Telephone: '+49 (0) 39219448',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.26464,11.84645',
  id: '52.26464,11.84645'
}, {
  Brand: 'TOTAL',
  Street: 'An der B 62 Nr. 2',
  PostCode: 36469,
  City: 'TIEFENORT',
  Latitude: '50.825.771',
  Longitude: '10.160.083',
  Telephone: '03695-82010',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.825771,10.160083',
  id: '50.825771,10.160083'
}, {
  Brand: 'TOTAL',
  Street: 'PFORTZER STR. 1',
  PostCode: 76744,
  City: 'WOERTH',
  Latitude: '49.038.298',
  Longitude: '8.289.295',
  Telephone: '07271-41041',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.038298,8.289295',
  id: '49.038298,8.289295'
}, {
  Brand: 'TOTAL',
  Street: '158 SPAETHSTR.',
  PostCode: 12359,
  City: 'BERLIN',
  Latitude: '52.453.355',
  Longitude: '1.345.764',
  Telephone: '030/43208977',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.453355,13.45764',
  id: '52.453355,13.45764'
}, {
  Brand: 'Avanti',
  Street: 'Aldingerstr. 74',
  PostCode: 70378,
  City: 'Stuttgart',
  Latitude: '4.884.104.000',
  Longitude: '923.088.000',
  Telephone: '+49-711-3652812',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.84104,9.23088',
  id: '48.84104,9.23088'
}, {
  Brand: 'TOTAL',
  Street: 'ROSSERTSTR. 2',
  PostCode: 65451,
  City: 'KELSTERBACH',
  Latitude: '50.066.025',
  Longitude: '8.537.291',
  Telephone: '06107-3013911',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.066025,8.537291',
  id: '50.066025,8.537291'
}, {
  Brand: 'Eni',
  Street: 'Bezirksstrae 29',
  PostCode: 85716,
  City: 'Unterschleiheim',
  Latitude: '4.828.175.955',
  Longitude: '1.157.578.900',
  Telephone: '+49 (0) 89310511',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.28175955,11.575789',
  id: '48.28175955,11.575789'
}, {
  Brand: 'TOTAL',
  Street: 'FRIEDRICHSTR. 41',
  PostCode: 54516,
  City: 'WITTLICH',
  Latitude: '4.998.833',
  Longitude: '6.899.069',
  Telephone: '06571-7277',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.98833,6.899069',
  id: '49.98833,6.899069'
}, {
  Brand: 'TOTAL',
  Street: 'KUPFERDREHERSTR.50-52',
  PostCode: 45257,
  City: 'ESSEN',
  Latitude: '51.397.817',
  Longitude: '7.085.411',
  Telephone: '0201-480081',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.397817,7.085411',
  id: '51.397817,7.085411'
}, {
  Brand: 'OMV',
  Street: 'Kastenseestr. 6',
  PostCode: 85625,
  City: 'Glonn',
  Latitude: '4.798.777.000',
  Longitude: '1.186.313.000',
  Telephone: '+49-8093-5320',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.98777,11.86313',
  id: '47.98777,11.86313'
}, {
  Brand: 'Westfalen',
  Street: 'Braukstr. 160',
  PostCode: null,
  City: 'BAT RESSER MARK NORD GELSENKIRCHEN',
  Latitude: '515.709',
  Longitude: '710.451',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.5709,7.10451',
  id: '51.5709,7.10451'
}, {
  Brand: 'OMV',
  Street: 'Nürnberger Str. 10',
  PostCode: 86609,
  City: 'Donauwörth',
  Latitude: '4.873.049.000',
  Longitude: '1.077.838.000',
  Telephone: '+49-906-9998254',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.73049,10.77838',
  id: '48.73049,10.77838'
}, {
  Brand: 'ARAL',
  Street: 'A1',
  PostCode: 21109,
  City: 'Hamburg',
  Latitude: '534.938.103',
  Longitude: '100.339.526',
  Telephone: '407544131',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.4938103,10.0339526',
  id: '53.4938103,10.0339526'
}, {
  Brand: 'ARAL',
  Street: 'A5',
  PostCode: 77767,
  City: 'Appenweier',
  Latitude: '485.617.170',
  Longitude: '79.568.880',
  Telephone: '78052949',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.561717,7.956888',
  id: '48.561717,7.956888'
}, {
  Brand: 'TOTAL',
  Street: 'AN DER MAGISTRALE 2',
  PostCode: 6124,
  City: 'HALLE',
  Latitude: '51.481.699',
  Longitude: '11.945.292',
  Telephone: '0345-8044362',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.481699,11.945292',
  id: '51.481699,11.945292'
}, {
  Brand: 'ARAL',
  Street: 'A7',
  PostCode: 21077,
  City: 'Hamburg',
  Latitude: '534.234.690',
  Longitude: '99.670.880',
  Telephone: '4076115317',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:50',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:50',
  IsOpen24Hours: 'Ja',
  location: '53.423469,9.967088',
  id: '53.423469,9.967088'
}, {
  Brand: 'TOTAL',
  Street: 'LORCHER STR. 67',
  PostCode: 73033,
  City: 'GOEPPINGEN',
  Latitude: '48.710.005',
  Longitude: '9.649.593',
  Telephone: '07161-25770',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.710005,9.649593',
  id: '48.710005,9.649593'
}, {
  Brand: 'ARAL',
  Street: 'IM BIRKENGRUND',
  PostCode: 63179,
  City: 'OBERTSHAUSEN',
  Latitude: '500.625.760',
  Longitude: '88.297.000',
  Telephone: '06104/4052680',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.062576,8.8297',
  id: '50.062576,8.8297'
}, {
  Brand: 'Eni',
  Street: 'Wallensteinstrasse 90',
  PostCode: 90431,
  City: 'Nuernberg',
  Latitude: '4.943.750.000',
  Longitude: '1.103.140.000',
  Telephone: '+49 (0) 91161422',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.4375,11.0314',
  id: '49.4375,11.0314'
}, {
  Brand: 'ARAL',
  Street: 'Hixberger Straße 28 a',
  PostCode: 66292,
  City: 'Riegelsberg',
  Latitude: '492.952.760',
  Longitude: '69.342.980',
  Telephone: '68063660',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.295276,6.934298',
  id: '49.295276,6.934298'
}, {
  Brand: 'OMV',
  Street: 'Römerstr. 1',
  PostCode: 71296,
  City: 'Heimsheim',
  Latitude: '4.882.422.000',
  Longitude: '886.853.000',
  Telephone: '+49-7033-138995',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.82422,8.86853',
  id: '48.82422,8.86853'
}, {
  Brand: 'ARAL',
  Street: 'Berliner Allee 7',
  PostCode: 39326,
  City: 'Hohenwarsleben',
  Latitude: '521.770.710',
  Longitude: '114.901.250',
  Telephone: '3920460849',
  WeekDayFrom: '00:00',
  WeekDayTo: '',
  SaturdayFrom: '00:00',
  SaturdayTo: '',
  SundayFrom: '00:00',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '52.177071,11.490125',
  id: '52.177071,11.490125'
}, {
  Brand: 'TOTAL',
  Street: 'RHEINSTR. 31',
  PostCode: 56179,
  City: 'VALLENDAR',
  Latitude: '5.039.315',
  Longitude: '761.682',
  Telephone: '0261-96371544',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.39315,7.61682',
  id: '50.39315,7.61682'
}, {
  Brand: 'Eni',
  Street: 'Radmeisterstrasse 1',
  PostCode: 90455,
  City: 'Nuernberg',
  Latitude: '4.937.093.000',
  Longitude: '1.107.774.000',
  Telephone: '+49 (0) 91188318',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.37093,11.07774',
  id: '49.37093,11.07774'
}, {
  Brand: 'Eni',
  Street: 'Freiherr-vom-Stein-Str. 30',
  PostCode: 99734,
  City: 'Nordhausen',
  Latitude: '5.149.546.000',
  Longitude: '1.077.823.000',
  Telephone: '+49 (0) 36319029',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.49546,10.77823',
  id: '51.49546,10.77823'
}, {
  Brand: 'OMV',
  Street: 'Spessartstr. 74',
  PostCode: 63743,
  City: 'Aschaffenburg',
  Latitude: '4.996.477.000',
  Longitude: '915.119.000',
  Telephone: '+49-6021-91724',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.96477,9.15119',
  id: '49.96477,9.15119'
}, {
  Brand: 'TOTAL',
  Street: 'AUTOHOF - AM SCHLUETERSHOF 27',
  PostCode: 47059,
  City: 'DUISBURG',
  Latitude: '51.437.949',
  Longitude: '6.738.798',
  Telephone: '0203-314086',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.437949,6.738798',
  id: '51.437949,6.738798'
}, {
  Brand: 'ARAL',
  Street: 'A 30',
  PostCode: 49328,
  City: 'Melle',
  Latitude: '521.960.530',
  Longitude: '83.843.810',
  Telephone: '542242010',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.196053,8.384381',
  id: '52.196053,8.384381'
}, {
  Brand: 'OMV',
  Street: 'Kemptener Str. 14-16',
  PostCode: 88131,
  City: 'Lindau',
  Latitude: '4.755.481.000',
  Longitude: '970.492.000',
  Telephone: '+49-8382-73558',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '47.55481,9.70492',
  id: '47.55481,9.70492'
}, {
  Brand: 'Eni',
  Street: 'Innsbrucker Ring 149',
  PostCode: 81669,
  City: 'Muenchen',
  Latitude: '4.811.435.095',
  Longitude: '1.161.760.989',
  Telephone: '+49 (0) 89689199',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.11435095,11.61760989',
  id: '48.11435095,11.61760989'
}, {
  Brand: 'TOTAL',
  Street: 'BAEUMCHEN 15',
  PostCode: 6667,
  City: 'WEISSENFELS',
  Latitude: '51.268.721',
  Longitude: '11.978.313',
  Telephone: '03443-3383245',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.268721,11.978313',
  id: '51.268721,11.978313'
}, {
  Brand: 'TOTAL',
  Street: 'EICHENBUEHLER STR. 52',
  PostCode: 63897,
  City: 'MILTENBERG',
  Latitude: '4.970.441',
  Longitude: '9.267.278',
  Telephone: '09371-3885',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.70441,9.267278',
  id: '49.70441,9.267278'
}, {
  Brand: 'ARAL',
  Street: 'Lütticher Str. 1',
  PostCode: 53842,
  City: 'Troisdorf',
  Latitude: '508.384.680',
  Longitude: '71.135.180',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.838468,7.113518',
  id: '50.838468,7.113518'
}, {
  Brand: 'ARAL',
  Street: 'B 3 / Am Krekel 3',
  PostCode: 35039,
  City: 'Marburg',
  Latitude: '507.937.050',
  Longitude: '87.607.600',
  Telephone: '6421165036',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.793705,8.76076',
  id: '50.793705,8.76076'
}, {
  Brand: 'TOTAL',
  Street: 'AM SPORTPARK 32',
  PostCode: 58097,
  City: 'HAGEN',
  Latitude: '51.368.975',
  Longitude: '747.524',
  Telephone: '02331-81616',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.368975,7.47524',
  id: '51.368975,7.47524'
}, {
  Brand: 'TOTAL',
  Street: 'B14/MARBACHER STR.',
  PostCode: 71364,
  City: 'WINNENDEN',
  Latitude: '4.888.244',
  Longitude: '938.669',
  Telephone: '071955-988995',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.88244,9.38669',
  id: '48.88244,9.38669'
}, {
  Brand: 'ARAL',
  Street: 'Gewerbepark 2',
  PostCode: 91350,
  City: 'Gremsdorf',
  Latitude: '496.984.920',
  Longitude: '108.547.340',
  Telephone: '9193504495',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.698492,10.854734',
  id: '49.698492,10.854734'
}, {
  Brand: 'Westfalen',
  Street: 'Düsseldorfer Str. 2',
  PostCode: null,
  City: 'TOENISVORST',
  Latitude: '513.231',
  Longitude: '647.272',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.3231,6.47272',
  id: '51.3231,6.47272'
}, {
  Brand: 'TOTAL',
  Street: 'BAB A6/FRANKENHOEHE SUED',
  PostCode: 91589,
  City: 'AURACH',
  Latitude: '4.924.149',
  Longitude: '10.3522',
  Telephone: '09804-919150',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.24149,10.3522',
  id: '49.24149,10.3522'
}, {
  Brand: 'OMV',
  Street: 'Hauptstr. 10',
  PostCode: 82490,
  City: 'Farchant',
  Latitude: '4.753.128.000',
  Longitude: '1.111.262.000',
  Telephone: '+49-8821-6466',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.53128,11.11262',
  id: '47.53128,11.11262'
}, {
  Brand: 'ARAL',
  Street: 'Illerstraße 12',
  PostCode: 87527,
  City: 'Sonthofen',
  Latitude: '475.237.160',
  Longitude: '102.623.060',
  Telephone: '83213322',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.523716,10.262306',
  id: '47.523716,10.262306'
}, {
  Brand: 'TOTAL',
  Street: '12-14 FRIEDRICH-EBERT-STR.',
  PostCode: 42549,
  City: 'VELBERT',
  Latitude: '51.334.795',
  Longitude: '7.048.918',
  Telephone: '02051-255409',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.334795,7.048918',
  id: '51.334795,7.048918'
}, {
  Brand: 'Eni',
  Street: 'Manchinger Str. 115',
  PostCode: 85053,
  City: 'Ingolstadt',
  Latitude: '4.874.492.000',
  Longitude: '1.147.045.000',
  Telephone: '+49 (0) 84162883',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.74492,11.47045',
  id: '48.74492,11.47045'
}, {
  Brand: 'ARAL',
  Street: 'Weserstraße 6',
  PostCode: 26452,
  City: 'Sande',
  Latitude: '53.489.976',
  Longitude: '8.027.651',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '00:00',
  SaturdayFrom: '00:00',
  SaturdayTo: '00:00',
  SundayFrom: '00:00',
  SundayTo: '00:00',
  IsOpen24Hours: 'Ja',
  location: '53.489976,8.027651',
  id: '53.489976,8.027651'
}, {
  Brand: 'TOTAL',
  Street: 'OBOTRITENRING 19 A',
  PostCode: 19053,
  City: 'SCHWERIN',
  Latitude: '53.638.696',
  Longitude: '11.404.074',
  Telephone: '0385-77313',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.638696,11.404074',
  id: '53.638696,11.404074'
}, {
  Brand: 'ARAL',
  Street: 'Meraner Straße 11',
  PostCode: 86165,
  City: 'Augsburg',
  Latitude: '483.816.230',
  Longitude: '109.324.250',
  Telephone: '8217292985',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.381623,10.932425',
  id: '48.381623,10.932425'
}, {
  Brand: 'ARAL',
  Street: 'Nickerner Weg 8',
  PostCode: 1257,
  City: 'Dresden',
  Latitude: '509.938.780',
  Longitude: '137.973.700',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.993878,13.79737',
  id: '50.993878,13.79737'
}, {
  Brand: 'TOTAL',
  Street: 'VON KETTELER STR. 13',
  PostCode: 33106,
  City: 'PADERBORN',
  Latitude: '51.733.849',
  Longitude: '8.689.568',
  Telephone: '05254-5150',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.733849,8.689568',
  id: '51.733849,8.689568'
}, {
  Brand: 'OMV',
  Street: 'Regensburgerstr. 91',
  PostCode: 94315,
  City: 'Straubing',
  Latitude: '4.888.025.000',
  Longitude: '1.255.274.000',
  Telephone: '+49-9421-22302',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.88025,12.55274',
  id: '48.88025,12.55274'
}, {
  Brand: 'Eni',
  Street: 'Maroldstrasse 64',
  PostCode: 85229,
  City: 'Markt Indersdorf',
  Latitude: '4.835.492.000',
  Longitude: '1.138.783.000',
  Telephone: '+49 (0) 81366479',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.35492,11.38783',
  id: '48.35492,11.38783'
}, {
  Brand: 'TOTAL',
  Street: 'LANGER WEG 17',
  PostCode: 1257,
  City: 'DRESDEN',
  Latitude: '51.006.899',
  Longitude: '13.809.999',
  Telephone: '0351-2753023',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.006899,13.809999',
  id: '51.006899,13.809999'
}, {
  Brand: 'OMV',
  Street: 'Albert-Mayer-Str. 22',
  PostCode: 83052,
  City: 'Bruckmühl',
  Latitude: '4.788.208.000',
  Longitude: '1.193.292.000',
  Telephone: '+49-8062-7187',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.88208,11.93292',
  id: '47.88208,11.93292'
}, {
  Brand: 'OMV',
  Street: 'Waldhäuser Str. 122',
  PostCode: 72076,
  City: 'Tübingen',
  Latitude: '4.854.559.000',
  Longitude: '905.606.000',
  Telephone: '+49-7071-63299',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.54559,9.05606',
  id: '48.54559,9.05606'
}, {
  Brand: 'OMV',
  Street: 'Wilhelmstr. 130',
  PostCode: 72461,
  City: 'Albstadt',
  Latitude: '4.827.637.000',
  Longitude: '900.009.000',
  Telephone: '+49-7432-21717',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.27637,9.00009',
  id: '48.27637,9.00009'
}, {
  Brand: 'Westfalen',
  Street: 'Neue Ritterstr. 41',
  PostCode: null,
  City: 'KREFELD',
  Latitude: '513.289',
  Longitude: '658.639',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.3289,6.58639',
  id: '51.3289,6.58639'
}, {
  Brand: 'ARAL',
  Street: 'Magdeburger Landstraße 14 a',
  PostCode: 39638,
  City: 'Gardelegen',
  Latitude: '525.178.360',
  Longitude: '114.124.740',
  Telephone: '39077799377',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.517836,11.412474',
  id: '52.517836,11.412474'
}, {
  Brand: 'ARAL',
  Street: 'Lindenstraße 1',
  PostCode: 89284,
  City: 'Pfaffenhofen',
  Latitude: '483.587.010',
  Longitude: '101.601.480',
  Telephone: '7302919086',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '48.358701,10.160148',
  id: '48.358701,10.160148'
}, {
  Brand: 'ARAL',
  Street: 'Breslauer Straße 2',
  PostCode: 67245,
  City: 'Lambsheim',
  Latitude: '495.048.230',
  Longitude: '82.913.970',
  Telephone: '6233579820',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.504823,8.291397',
  id: '49.504823,8.291397'
}, {
  Brand: 'TOTAL',
  Street: 'MERSEBURGER STR.31 b',
  PostCode: 6632,
  City: 'FREYBURG',
  Latitude: '51.220.685',
  Longitude: '11.772.799',
  Telephone: '034464-28271',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.220685,11.772799',
  id: '51.220685,11.772799'
}, {
  Brand: 'ARAL',
  Street: 'Mergentheimer Straße 3',
  PostCode: 97941,
  City: 'Tauberbischofsheim',
  Latitude: '496.228.030',
  Longitude: '96.683.140',
  Telephone: '9341953880',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Nein',
  location: '49.622803,9.668314',
  id: '49.622803,9.668314'
}, {
  Brand: 'TOTAL',
  Street: 'BOSCHSTR. 2',
  PostCode: 55283,
  City: 'NIERSTEIN',
  Latitude: '49.863.279',
  Longitude: '8.332.962',
  Telephone: '06133-491802',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.863279,8.332962',
  id: '49.863279,8.332962'
}, {
  Brand: 'ARAL',
  Street: 'A 1',
  PostCode: 50354,
  City: 'Hürth',
  Latitude: '508.571.010',
  Longitude: '68.061.510',
  Telephone: '22371335',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.857101,6.806151',
  id: '50.857101,6.806151'
}, {
  Brand: 'ARAL',
  Street: 'Burggrafenstraße',
  PostCode: 45139,
  City: 'Essen',
  Latitude: '514.621.670',
  Longitude: '70.301.210',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.462167,7.030121',
  id: '51.462167,7.030121'
}, {
  Brand: 'TOTAL',
  Street: 'ROERMONDER STR. 315',
  PostCode: 52072,
  City: 'AACHEN',
  Latitude: '50.796.132',
  Longitude: '6.063.573',
  Telephone: '0241-12773',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.796132,6.063573',
  id: '50.796132,6.063573'
}, {
  Brand: 'TOTAL',
  Street: 'WACKENBERGSTR. 2-6',
  PostCode: 13156,
  City: 'BERLIN',
  Latitude: '52.582.894',
  Longitude: '13.404.284',
  Telephone: '030-4765891',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.582894,13.404284',
  id: '52.582894,13.404284'
}, {
  Brand: 'TOTAL',
  Street: '92 PETUELRING',
  PostCode: 80807,
  City: 'MUENCHEN',
  Latitude: '4.817.816',
  Longitude: '11.572.129',
  Telephone: '089-3595277',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.17816,11.572129',
  id: '48.17816,11.572129'
}, {
  Brand: 'TOTAL',
  Street: 'WEILSTRASSE 112',
  PostCode: 35789,
  City: 'WEILMUENSTER',
  Latitude: '504.341',
  Longitude: '837.449',
  Telephone: '06472-8313603',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.4341,8.37449',
  id: '50.4341,8.37449'
}, {
  Brand: 'ARAL',
  Street: 'Junginger Straße 11',
  PostCode: 89081,
  City: 'Ulm',
  Latitude: '484.364.430',
  Longitude: '99.709.890',
  Telephone: '7319609671',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:30',
  SundayFrom: '08:00',
  SundayTo: '18:30',
  IsOpen24Hours: 'Nein',
  location: '48.436443,9.970989',
  id: '48.436443,9.970989'
}, {
  Brand: 'ARAL',
  Street: 'Siemensring 41',
  PostCode: 47877,
  City: 'Willich',
  Latitude: '512.701.180',
  Longitude: '65.120.130',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.270118,6.512013',
  id: '51.270118,6.512013'
}, {
  Brand: 'TOTAL',
  Street: 'ROSA-LUXEMBURG-STR. 133',
  PostCode: 6917,
  City: 'JESSEN',
  Latitude: '51.797.243',
  Longitude: '1.298.743',
  Telephone: '03537-212408',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.797243,12.98743',
  id: '51.797243,12.98743'
}, {
  Brand: 'TOTAL',
  Street: 'RUHRORTER STR. 65',
  PostCode: 46049,
  City: 'OBERHAUSEN',
  Latitude: '51.477.426',
  Longitude: '6.829.578',
  Telephone: '0208-807951',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.477426,6.829578',
  id: '51.477426,6.829578'
}, {
  Brand: 'ARAL',
  Street: 'SIMBACHER STRAßE 36',
  PostCode: 84524,
  City: 'NEUÖTTING',
  Latitude: '482.398.640',
  Longitude: '127.105.280',
  Telephone: '86718839794',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.239864,12.710528',
  id: '48.239864,12.710528'
}, {
  Brand: 'TOTAL',
  Street: 'BAHNHOFSTR.23',
  PostCode: 19273,
  City: 'NEUHAUS',
  Latitude: '53.286.863',
  Longitude: '10.936.809',
  Telephone: '038841-20733',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.286863,10.936809',
  id: '53.286863,10.936809'
}, {
  Brand: 'TOTAL',
  Street: 'CHAUSSEESTRASSE 79',
  PostCode: 18356,
  City: 'BARTH',
  Latitude: '54.353.473',
  Longitude: '12.724.514',
  Telephone: '038231-2023',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.353473,12.724514',
  id: '54.353473,12.724514'
}, {
  Brand: 'ARAL',
  Street: 'Riemer Straße 339',
  PostCode: 81829,
  City: 'München',
  Latitude: '481.405.380',
  Longitude: '116.798.480',
  Telephone: '',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.140538,11.679848',
  id: '48.140538,11.679848'
}, {
  Brand: 'TOTAL',
  Street: 'DARMSTAEDTER STR. 92',
  PostCode: 63303,
  City: 'DREIEICH',
  Latitude: '50.008.819',
  Longitude: '8.689.436',
  Telephone: '06103-697050',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.008819,8.689436',
  id: '50.008819,8.689436'
}, {
  Brand: 'Avanti',
  Street: 'Windsbacherstr. 24',
  PostCode: 91522,
  City: 'Ansbach',
  Latitude: '4.929.316.000',
  Longitude: '1.060.393.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.29316,10.60393',
  id: '49.29316,10.60393'
}, {
  Brand: 'ARAL',
  Street: 'Rheiner Str. 176 A',
  PostCode: 49809,
  City: 'Lingen',
  Latitude: '524.971.750',
  Longitude: '73.289.700',
  Telephone: '59161047865',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.497175,7.32897',
  id: '52.497175,7.32897'
}, {
  Brand: 'ARAL',
  Street: 'Zeche Crone 2',
  PostCode: 44265,
  City: 'Dortmund',
  Latitude: '514.723.300',
  Longitude: '74.853.910',
  Telephone: '23142788770',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.47233,7.485391',
  id: '51.47233,7.485391'
}, {
  Brand: 'Eni',
  Street: 'Flughafen Nordallee 45',
  PostCode: 85356,
  City: 'Muenchen',
  Latitude: '4.835.395.434',
  Longitude: '1.174.937.560',
  Telephone: '+49 (0) 89970518',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.35395434,11.7493756',
  id: '48.35395434,11.7493756'
}, {
  Brand: 'OMV',
  Street: 'Bahnhofstr. 53',
  PostCode: 87435,
  City: 'Kempten',
  Latitude: '4.771.809.000',
  Longitude: '1.031.398.000',
  Telephone: '+49-831-22707',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.71809,10.31398',
  id: '47.71809,10.31398'
}, {
  Brand: 'ARAL',
  Street: 'Schneeberger Straße 83 a',
  PostCode: 8112,
  City: 'Wilkau-Haßlau',
  Latitude: '506.730.660',
  Longitude: '125.282.720',
  Telephone: '375661265',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.673066,12.528272',
  id: '50.673066,12.528272'
}, {
  Brand: 'TOTAL',
  Street: 'ESCHOLLBRUECKER STR.47',
  PostCode: 64319,
  City: 'PFUNGSTADT',
  Latitude: '49.802.817',
  Longitude: '858.906',
  Telephone: '61579390703',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.802817,8.58906',
  id: '49.802817,8.58906'
}, {
  Brand: 'TOTAL',
  Street: 'SCHUTTERSTR. 3',
  PostCode: 77746,
  City: 'SCHUTTERWALD',
  Latitude: '48.465.678',
  Longitude: '7.879.933',
  Telephone: '0781-96560',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.465678,7.879933',
  id: '48.465678,7.879933'
}, {
  Brand: 'ARAL',
  Street: 'Lütgendortmunder Hellweg 200/a.d A4',
  PostCode: 44892,
  City: 'Bochum',
  Latitude: '514.940.240',
  Longitude: '73.394.350',
  Telephone: '23432589808',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.494024,7.339435',
  id: '51.494024,7.339435'
}, {
  Brand: 'TOTAL',
  Street: 'OBERNEULANDER HEERSTR. 38',
  PostCode: 28355,
  City: 'BREMEN',
  Latitude: '53.100.367',
  Longitude: '8.902.807',
  Telephone: '0421-251443',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.100367,8.902807',
  id: '53.100367,8.902807'
}, {
  Brand: 'TOTAL',
  Street: 'EICHSTAEDTER CHAUSSEE 4',
  PostCode: 16727,
  City: 'OBERKRAEMER OT VEHLEFANZ',
  Latitude: '52.708.687',
  Longitude: '13.106.989',
  Telephone: '03304-502308',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.708687,13.106989',
  id: '52.708687,13.106989'
}, {
  Brand: 'ARAL',
  Street: 'Gewerbegebiet Am Dachsberg 8',
  PostCode: 53604,
  City: 'Bad Honnef',
  Latitude: '506.479.840',
  Longitude: '73.346.060',
  Telephone: '22249897088',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.647984,7.334606',
  id: '50.647984,7.334606'
}, {
  Brand: 'ARAL',
  Street: 'Situlistraße 36',
  PostCode: 80939,
  City: 'München',
  Latitude: '481.906.680',
  Longitude: '116.165.570',
  Telephone: '8932195426',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.190668,11.616557',
  id: '48.190668,11.616557'
}, {
  Brand: 'TOTAL',
  Street: 'MITTELHAEUSER STR. 69',
  PostCode: 99089,
  City: 'ERFURT',
  Latitude: '51.009.575',
  Longitude: '11.018.028',
  Telephone: '0361-7920622',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.009575,11.018028',
  id: '51.009575,11.018028'
}, {
  Brand: 'TOTAL',
  Street: 'VENLOER STR. 46',
  PostCode: 41569,
  City: 'ROMMERSKIRCHEN',
  Latitude: '51.034.381',
  Longitude: '6.687.699',
  Telephone: '02183-9530',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.034381,6.687699',
  id: '51.034381,6.687699'
}, {
  Brand: 'TOTAL',
  Street: 'AUTOHOF NORD-LIPPE STR. 44',
  PostCode: 59368,
  City: 'WERNE-STOCKUM',
  Latitude: '51.698.549',
  Longitude: '7.673.591',
  Telephone: '02389-780256',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.698549,7.673591',
  id: '51.698549,7.673591'
}, {
  Brand: 'TOTAL',
  Street: '1 ZUR KUHBRUECKE',
  PostCode: 1458,
  City: 'OTTENDORF-OKRILLA',
  Latitude: '51.174.726',
  Longitude: '13.854.034',
  Telephone: '03520/5189791',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.174726,13.854034',
  id: '51.174726,13.854034'
}, {
  Brand: 'TOTAL',
  Street: 'WERRASTR.',
  PostCode: 98617,
  City: 'MEININGEN',
  Latitude: '50.559.459',
  Longitude: '10.413.132',
  Telephone: '03693-476431',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.559459,10.413132',
  id: '50.559459,10.413132'
}, {
  Brand: 'ARAL',
  Street: 'Langenbacher Straße 18',
  PostCode: 56470,
  City: 'Bad Marienberg',
  Latitude: '506.480.290',
  Longitude: '79.530.690',
  Telephone: '2661931012',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.648029,7.953069',
  id: '50.648029,7.953069'
}, {
  Brand: 'TOTAL',
  Street: 'TESSINER STR. 98',
  PostCode: 18055,
  City: 'ROSTOCK',
  Latitude: '54.080.731',
  Longitude: '12.189.121',
  Telephone: '0381-683783',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.080731,12.189121',
  id: '54.080731,12.189121'
}, {
  Brand: 'Eni',
  Street: 'Lise-Meitner-Strasse 2',
  PostCode: 82110,
  City: 'Germering',
  Latitude: '4.814.067.000',
  Longitude: '1.136.856.000',
  Telephone: '089/89408492',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.14067,11.36856',
  id: '48.14067,11.36856'
}, {
  Brand: 'Westfalen',
  Street: 'Hauptstr. 455',
  PostCode: null,
  City: 'APEN',
  Latitude: '532.166',
  Longitude: '776.252',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.2166,7.76252',
  id: '53.2166,7.76252'
}, {
  Brand: 'OMV',
  Street: 'Hauptstr. 17',
  PostCode: 90596,
  City: 'Schwanstetten',
  Latitude: '4.932.486.000',
  Longitude: '1.112.315.000',
  Telephone: '+49-9170-977324',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.32486,11.12315',
  id: '49.32486,11.12315'
}, {
  Brand: 'Eni',
  Street: 'Augsburger Strasse 231',
  PostCode: 70327,
  City: 'Stuttgart',
  Latitude: '4.879.124.000',
  Longitude: '924.389.000',
  Telephone: '0711/32770830',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.79124,9.24389',
  id: '48.79124,9.24389'
}, {
  Brand: 'TOTAL',
  Street: 'FRANKFURTER STR.2',
  PostCode: 35440,
  City: 'LINDEN',
  Latitude: '50.532.499',
  Longitude: '8.649.999',
  Telephone: '06403-2522',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.532499,8.649999',
  id: '50.532499,8.649999'
}, {
  Brand: 'Westfalen',
  Street: 'Rheiner Str.5',
  PostCode: null,
  City: 'BAD BENTHEIM',
  Latitude: '523.047',
  Longitude: '715.061',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.3047,7.15061',
  id: '52.3047,7.15061'
}, {
  Brand: 'Eni',
  Street: 'Frankfurter Strasse 110',
  PostCode: 63150,
  City: 'Heusenstamm',
  Latitude: '5.005.859.000',
  Longitude: '879.407.000',
  Telephone: '+49 (0) 61046182',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.05859,8.79407',
  id: '50.05859,8.79407'
}, {
  Brand: 'ARAL',
  Street: 'Moosbronner Straße 37',
  PostCode: 76275,
  City: 'Ettlingen OT Schöllb',
  Latitude: '488.949.900',
  Longitude: '84.208.430',
  Telephone: '72431855041',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '19:00',
  SundayFrom: '08:30',
  SundayTo: '19:00',
  IsOpen24Hours: 'Nein',
  location: '48.89499,8.420843',
  id: '48.89499,8.420843'
}, {
  Brand: 'ARAL',
  Street: 'BAB 659',
  PostCode: 68519,
  City: 'Viernheim',
  Latitude: '495.424.970',
  Longitude: '86.087.540',
  Telephone: '6204919183',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.542497,8.608754',
  id: '49.542497,8.608754'
}, {
  Brand: 'ARAL',
  Street: 'Odenwaldstraße 7',
  PostCode: 67551,
  City: 'Worms-Pfeddersheim',
  Latitude: '496.343.360',
  Longitude: '82.809.310',
  Telephone: '6247905314',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.634336,8.280931',
  id: '49.634336,8.280931'
}, {
  Brand: 'OMV',
  Street: 'Kriegsstr. 139',
  PostCode: 76135,
  City: 'Karlsruhe',
  Latitude: '4.900.546.000',
  Longitude: '837.916.000',
  Telephone: '+49-721-848162',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.00546,8.37916',
  id: '49.00546,8.37916'
}, {
  Brand: 'Eni',
  Street: 'Stuttgarter Strasse 144',
  PostCode: 71522,
  City: 'Backnang',
  Latitude: '4.893.528.000',
  Longitude: '943.749.000',
  Telephone: '+49 (0) 71916267',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.93528,9.43749',
  id: '48.93528,9.43749'
}, {
  Brand: 'Eni',
  Street: 'Magdeburger Strasse',
  PostCode: 68309,
  City: 'Mannheim',
  Latitude: '4.951.046.000',
  Longitude: '855.189.000',
  Telephone: '+49 (0) 62170620',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.51046,8.55189',
  id: '49.51046,8.55189'
}, {
  Brand: 'Eni',
  Street: 'Haager Strasse 41',
  PostCode: 84405,
  City: 'Dorfen',
  Latitude: '4.826.914.338',
  Longitude: '1.215.474.119',
  Telephone: '+49 (0) 8081589',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.26914338,12.15474119',
  id: '48.26914338,12.15474119'
}, {
  Brand: 'Eni',
  Street: 'Scheidter Strasse 260-262',
  PostCode: 66125,
  City: 'Saarbruecken(Dudw.)',
  Latitude: '4.926.802.000',
  Longitude: '704.973.000',
  Telephone: '+49 (0) 68977336',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.26802,7.04973',
  id: '49.26802,7.04973'
}, {
  Brand: 'Westfalen',
  Street: 'Osttor 63',
  PostCode: null,
  City: 'SENDENHORST',
  Latitude: '518.412',
  Longitude: '784.299',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8412,7.84299',
  id: '51.8412,7.84299'
}, {
  Brand: 'ARAL',
  Street: 'Am Römerfeld 1',
  PostCode: 71149,
  City: 'Bondorf',
  Latitude: '485.067.600',
  Longitude: '88.358.500',
  Telephone: '17409497450',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.50676,8.83585',
  id: '48.50676,8.83585'
}, {
  Brand: 'OMV',
  Street: 'Bayerweg 39',
  PostCode: 94379,
  City: 'St. Englmar',
  Latitude: '4.900.258.000',
  Longitude: '1.282.268.000',
  Telephone: '+49-9965-268',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.00258,12.82268',
  id: '49.00258,12.82268'
}, {
  Brand: 'Eni',
  Street: 'Muenchner Strasse 28',
  PostCode: 86641,
  City: 'Rain am Lech',
  Latitude: '4.868.998.685',
  Longitude: '1.092.103.429',
  Telephone: '09090 / 9594877',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.68998685,10.92103429',
  id: '48.68998685,10.92103429'
}, {
  Brand: 'Eni',
  Street: 'Meitinger Str. 16-18',
  PostCode: 86672,
  City: 'Thierhaupten',
  Latitude: '4.856.677.000',
  Longitude: '1.089.826.000',
  Telephone: '+49 (0) 82712426',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.56677,10.89826',
  id: '48.56677,10.89826'
}, {
  Brand: 'OMV',
  Street: 'Freisinger Landstr. 9',
  PostCode: 80939,
  City: 'München',
  Latitude: '4.819.044.000',
  Longitude: '1.161.779.000',
  Telephone: '+49-89-3232447',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.19044,11.61779',
  id: '48.19044,11.61779'
}, {
  Brand: 'ARAL',
  Street: 'Friedberger Straße 22',
  PostCode: 35410,
  City: 'Hungen',
  Latitude: '504.723.480',
  Longitude: '88.983.780',
  Telephone: '64028093512',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.472348,8.898378',
  id: '50.472348,8.898378'
}, {
  Brand: 'TOTAL',
  Street: 'B51 WINDMUEHLE',
  PostCode: 54298,
  City: 'WELSCHBILLIG',
  Latitude: '49.828.256',
  Longitude: '6.564.298',
  Telephone: '650699044',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.828256,6.564298',
  id: '49.828256,6.564298'
}, {
  Brand: 'OMV',
  Street: 'Neumarkter Str. 41',
  PostCode: 92348,
  City: 'Berg',
  Latitude: '4.932.594.000',
  Longitude: '1.144.380.000',
  Telephone: '+49-9189-396',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.32594,11.4438',
  id: '49.32594,11.4438'
}, {
  Brand: 'Eni',
  Street: 'Heerstr. 106',
  PostCode: 94315,
  City: 'Straubing',
  Latitude: '4.888.282.000',
  Longitude: '1.258.570.000',
  Telephone: '+49 (0) 94212114',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.88282,12.5857',
  id: '48.88282,12.5857'
}, {
  Brand: 'Avanti',
  Street: 'Augsburgerstrasse 740',
  PostCode: 70329,
  City: 'Stuttgart - Obertürkheim',
  Latitude: '4.875.710.000',
  Longitude: '927.330.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.7571,9.2733',
  id: '48.7571,9.2733'
}, {
  Brand: 'Eni',
  Street: 'Wuerzburger Landstr. 47',
  PostCode: 91522,
  City: 'Ansbach',
  Latitude: '4.930.936.964',
  Longitude: '1.056.108.243',
  Telephone: '+49 (0) 98187221',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.30936964,10.56108243',
  id: '49.30936964,10.56108243'
}, {
  Brand: 'Eni',
  Street: 'Koelschhaeuser Strasse 25',
  PostCode: 35630,
  City: 'Ehringshausen',
  Latitude: '5.060.745.713',
  Longitude: '838.310.627',
  Telephone: '+49 (0) 64438112',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.60745713,8.38310627',
  id: '50.60745713,8.38310627'
}, {
  Brand: 'TOTAL',
  Street: 'SCHWABENSTR. 41',
  PostCode: 68239,
  City: 'MANNHEIM',
  Latitude: '49.460.773',
  Longitude: '8.569.707',
  Telephone: '0621-472576',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.460773,8.569707',
  id: '49.460773,8.569707'
}, {
  Brand: 'TOTAL',
  Street: 'SCHEINFELDER STR. 15',
  PostCode: 96160,
  City: 'GEISELWIND',
  Latitude: '49.769.437',
  Longitude: '10.470.366',
  Telephone: '09556-180',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.769437,10.470366',
  id: '49.769437,10.470366'
}, {
  Brand: 'TOTAL',
  Street: 'GOECKINGKSTR.',
  PostCode: 99755,
  City: 'ELLRICH',
  Latitude: '5.157.985',
  Longitude: '10.677.402',
  Telephone: '036332-20236',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.57985,10.677402',
  id: '51.57985,10.677402'
}, {
  Brand: 'ARAL',
  Street: 'Bruchfeld 60-70',
  PostCode: 47809,
  City: 'Krefeld',
  Latitude: '513.429.240',
  Longitude: '66.278.850',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.342924,6.627885',
  id: '51.342924,6.627885'
}, {
  Brand: 'OMV',
  Street: 'Neumarkter Str. 10',
  PostCode: 92355,
  City: 'Velburg',
  Latitude: '4.923.472.000',
  Longitude: '1.166.834.000',
  Telephone: '+49-9182-2580',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.23472,11.66834',
  id: '49.23472,11.66834'
}, {
  Brand: 'OMV',
  Street: 'Hirtenwiesen 2 a',
  PostCode: 96364,
  City: 'Marktrodach',
  Latitude: '5.025.217.000',
  Longitude: '1.139.246.000',
  Telephone: '+49-9261-501648',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.25217,11.39246',
  id: '50.25217,11.39246'
}, {
  Brand: 'Eni',
  Street: 'Engersgaustrasse 67',
  PostCode: 56566,
  City: 'Neuwied',
  Latitude: '5.044.210.000',
  Longitude: '754.527.000',
  Telephone: '+49 (0) 26228322',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.4421,7.54527',
  id: '50.4421,7.54527'
}, {
  Brand: 'OMV',
  Street: 'Ingolstädter Strasse 24',
  PostCode: 85084,
  City: 'Reichertshofen',
  Latitude: '4.865.998.000',
  Longitude: '1.146.424.000',
  Telephone: '+49-8453-446',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.65998,11.46424',
  id: '48.65998,11.46424'
}, {
  Brand: 'ARAL',
  Street: 'A93',
  PostCode: 92670,
  City: 'Windischeschenbach',
  Latitude: '498.169.690',
  Longitude: '121.706.662',
  Telephone: '96819199770',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.816969,12.1706662',
  id: '49.816969,12.1706662'
}, {
  Brand: 'Westfalen',
  Street: 'Sindorfer Str. 52',
  PostCode: null,
  City: 'KERPEN II',
  Latitude: '508.826',
  Longitude: '669.456',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.8826,6.69456',
  id: '50.8826,6.69456'
}, {
  Brand: 'ARAL',
  Street: 'Stuttgarter Straße 85',
  PostCode: 73061,
  City: 'Ebersbach a. d. Fils',
  Latitude: '487.136.980',
  Longitude: '95.131.780',
  Telephone: '7163532228',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.713698,9.513178',
  id: '48.713698,9.513178'
}, {
  Brand: 'TOTAL',
  Street: 'NUERNBERGER STRASSE 67',
  PostCode: 36199,
  City: 'ROTENBURG-LISPENHAUSEN',
  Latitude: '50.990.655',
  Longitude: '9.775.772',
  Telephone: '06623-919323',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.990655,9.775772',
  id: '50.990655,9.775772'
}, {
  Brand: 'ARAL',
  Street: 'Rombacher Hütte 6-10',
  PostCode: 44795,
  City: 'Bochum',
  Latitude: '514.655.850',
  Longitude: '71.927.020',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.465585,7.192702',
  id: '51.465585,7.192702'
}, {
  Brand: 'TOTAL',
  Street: 'RHOENHOF 5',
  PostCode: 36124,
  City: 'EICHENZELL',
  Latitude: '50.487.897',
  Longitude: '9.707.698',
  Telephone: '06659-915450',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.487897,9.707698',
  id: '50.487897,9.707698'
}, {
  Brand: 'TOTAL',
  Street: 'BERLINER CH.',
  PostCode: 39307,
  City: 'GENTHIN',
  Latitude: '52.408.178',
  Longitude: '12.175.533',
  Telephone: '03933-803515',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.408178,12.175533',
  id: '52.408178,12.175533'
}, {
  Brand: 'TOTAL',
  Street: 'MUENCHNER STR. 87',
  PostCode: 85221,
  City: 'DACHAU',
  Latitude: '48.245.212',
  Longitude: '11.448.775',
  Telephone: '08131-5150350',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.245212,11.448775',
  id: '48.245212,11.448775'
}, {
  Brand: 'TOTAL',
  Street: 'ALTONAER STR. 377',
  PostCode: 25462,
  City: 'RELLINGEN',
  Latitude: '53.622.226',
  Longitude: '9.884.988',
  Telephone: '04101-32439',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.622226,9.884988',
  id: '53.622226,9.884988'
}, {
  Brand: 'TOTAL',
  Street: '143 DARMSTAEDTER STR.',
  PostCode: 68647,
  City: 'BIBLIS',
  Latitude: '49.695.014',
  Longitude: '8.465.266',
  Telephone: '06245-2007479',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.695014,8.465266',
  id: '49.695014,8.465266'
}, {
  Brand: 'TOTAL',
  Street: 'A 81 BAB-WUNNENSTEIN-WEST/A 81',
  PostCode: 74360,
  City: 'ILSFELD',
  Latitude: '49.046.778',
  Longitude: '9.265.634',
  Telephone: '07062-267330',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.046778,9.265634',
  id: '49.046778,9.265634'
}, {
  Brand: 'ARAL',
  Street: 'Hannoversche Straße 2 a',
  PostCode: 34225,
  City: 'Baunatal',
  Latitude: '512.421.480',
  Longitude: '94.383.140',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.242148,9.438314',
  id: '51.242148,9.438314'
}, {
  Brand: 'TOTAL',
  Street: '12b WANZLEBENER Allee',
  PostCode: 39164,
  City: 'WANZLEBEN-BOERDE',
  Latitude: '52.096.721',
  Longitude: '11.307.683',
  Telephone: '039407-5080',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.096721,11.307683',
  id: '52.096721,11.307683'
}, {
  Brand: 'ARAL',
  Street: 'Seestraße 1',
  PostCode: 83471,
  City: 'Schönau am Königssee',
  Latitude: '475.927.610',
  Longitude: '129.874.410',
  Telephone: '865295630',
  WeekDayFrom: '06:30',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '21:00',
  SundayFrom: '06:30',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '47.592761,12.987441',
  id: '47.592761,12.987441'
}, {
  Brand: 'TOTAL',
  Street: 'BERLINER CH. 70',
  PostCode: 6886,
  City: 'WITTENBERG',
  Latitude: '51.890.654',
  Longitude: '12.665.478',
  Telephone: '03491-443011',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.890654,12.665478',
  id: '51.890654,12.665478'
}, {
  Brand: 'ARAL',
  Street: 'Kattegatweg 1',
  PostCode: 46446,
  City: 'Emmerich',
  Latitude: '518.802.660',
  Longitude: '61.736.780',
  Telephone: '2828903520',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.880266,6.173678',
  id: '51.880266,6.173678'
}, {
  Brand: 'Westfalen',
  Street: 'Ostenallee 48',
  PostCode: null,
  City: 'HAMM',
  Latitude: '516.858',
  Longitude: '7.8345',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.6858,7.8345',
  id: '51.6858,7.8345'
}, {
  Brand: 'OMV',
  Street: 'Gruber Str. 80',
  PostCode: 85586,
  City: 'Poing',
  Latitude: '4.816.862.000',
  Longitude: '1.179.230.000',
  Telephone: '+49-8121-25440',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.16862,11.7923',
  id: '48.16862,11.7923'
}, {
  Brand: 'Westfalen',
  Street: 'Petzer Strasse 6 c',
  PostCode: null,
  City: 'BUEKEBURG',
  Latitude: '522.625',
  Longitude: '903.767',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.2625,9.03767',
  id: '52.2625,9.03767'
}, {
  Brand: 'ARAL',
  Street: 'Pflaumenallee 1',
  PostCode: 39343,
  City: 'Uhrsleben',
  Latitude: '521.938.050',
  Longitude: '112.634.910',
  Telephone: '390529600',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.193805,11.263491',
  id: '52.193805,11.263491'
}, {
  Brand: 'Eni',
  Street: 'Mueldorferstr. 67',
  PostCode: 84503,
  City: 'Altoetting',
  Latitude: '4.822.608.793',
  Longitude: '1.265.910.202',
  Telephone: '+49 (0) 86719698',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.22608793,12.65910202',
  id: '48.22608793,12.65910202'
}, {
  Brand: 'TOTAL',
  Street: 'HAFENBAHNSTR 74',
  PostCode: 68305,
  City: 'MANNHEIM',
  Latitude: '49.513.342',
  Longitude: '8.484.253',
  Telephone: '0621-7621810',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.513342,8.484253',
  id: '49.513342,8.484253'
}, {
  Brand: 'Eni',
  Street: 'Kastanienallee 5',
  PostCode: 39261,
  City: 'Zerbst',
  Latitude: '5.195.752.000',
  Longitude: '1.208.217.000',
  Telephone: '+49 (0) 39237720',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.95752,12.08217',
  id: '51.95752,12.08217'
}, {
  Brand: 'TOTAL',
  Street: 'GIESSENER STR.120',
  PostCode: 35452,
  City: 'HEUCHELHEIM',
  Latitude: '50.586.323',
  Longitude: '8.641.411',
  Telephone: '0641-63624',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.586323,8.641411',
  id: '50.586323,8.641411'
}, {
  Brand: 'Westfalen',
  Street: 'Knallhuetter Str. 43',
  PostCode: null,
  City: 'BAUNATAL',
  Latitude: '512.583',
  Longitude: '944.833',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.2583,9.44833',
  id: '51.2583,9.44833'
}, {
  Brand: 'ARAL',
  Street: 'Konrad-Adenauer-Straße 192',
  PostCode: 57572,
  City: 'Niederfischbach',
  Latitude: '508.621.150',
  Longitude: '78.856.190',
  Telephone: '2734571767',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.862115,7.885619',
  id: '50.862115,7.885619'
}, {
  Brand: 'TOTAL',
  Street: '70 MUENCHENER STR.',
  PostCode: 84453,
  City: 'MUEHLDORF',
  Latitude: '4.824.572',
  Longitude: '12.510.453',
  Telephone: '08631/37810',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.24572,12.510453',
  id: '48.24572,12.510453'
}, {
  Brand: 'TOTAL',
  Street: 'WISMARSCHE STR. 13B',
  PostCode: 18246,
  City: 'BUETZOW',
  Latitude: '53.846.768',
  Longitude: '11.958.524',
  Telephone: '038461-67157',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.846768,11.958524',
  id: '53.846768,11.958524'
}, {
  Brand: 'TOTAL',
  Street: 'I.-GANDHI-STR. 106-109',
  PostCode: 13053,
  City: 'BERLIN',
  Latitude: '52.547.499',
  Longitude: '1.346.808',
  Telephone: '030-96064977',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.547499,13.46808',
  id: '52.547499,13.46808'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 19',
  PostCode: 63691,
  City: 'Ranstadt',
  Latitude: '503.550.520',
  Longitude: '89.828.980',
  Telephone: '6041828272',
  WeekDayFrom: '04:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.355052,8.982898',
  id: '50.355052,8.982898'
}, {
  Brand: 'ARAL',
  Street: 'Herzog-Otto-Straße 11',
  PostCode: 83308,
  City: 'Trostberg',
  Latitude: '480.252.800',
  Longitude: '125.416.660',
  Telephone: '862161692',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '09:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.02528,12.541666',
  id: '48.02528,12.541666'
}, {
  Brand: 'ARAL',
  Street: 'Schlesische Straße 75',
  PostCode: 58636,
  City: 'Iserlohn',
  Latitude: '513.779.950',
  Longitude: '77.284.090',
  Telephone: '237162222',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.377995,7.728409',
  id: '51.377995,7.728409'
}, {
  Brand: 'TOTAL',
  Street: 'ZSCHOCHERSCHE STR. 84',
  PostCode: 4229,
  City: 'LEIPZIG',
  Latitude: '5.132.266',
  Longitude: '12.3319',
  Telephone: '0341-4803837',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.32266,12.3319',
  id: '51.32266,12.3319'
}, {
  Brand: 'TOTAL',
  Street: 'HAUPTSTR. 25',
  PostCode: 77880,
  City: 'SASBACH',
  Latitude: '48.635.478',
  Longitude: '808.809',
  Telephone: '07841-664138',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.635478,8.08809',
  id: '48.635478,8.08809'
}, {
  Brand: 'ARAL',
  Street: 'August-Horch-Straße 15',
  PostCode: 56070,
  City: 'Koblenz',
  Latitude: '503.930.440',
  Longitude: '75.719.370',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.393044,7.571937',
  id: '50.393044,7.571937'
}, {
  Brand: 'Westfalen',
  Street: 'Nordener Str. 5',
  PostCode: null,
  City: 'WESTERHOLT',
  Latitude: '535.897',
  Longitude: '745.539',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.5897,7.45539',
  id: '53.5897,7.45539'
}, {
  Brand: 'OMV',
  Street: 'Egerlandstr. 2',
  PostCode: 96342,
  City: 'Stockheim',
  Latitude: '5.030.423.000',
  Longitude: '1.128.393.000',
  Telephone: '+49-9265-439',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.30423,11.28393',
  id: '50.30423,11.28393'
}, {
  Brand: 'OMV',
  Street: 'Krappenrother Str. 2',
  PostCode: 96215,
  City: 'Lichtenfels',
  Latitude: '5.015.009.000',
  Longitude: '1.108.189.000',
  Telephone: '+49-9571-757856',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.15009,11.08189',
  id: '50.15009,11.08189'
}, {
  Brand: 'Eni',
  Street: 'Sulzbacher Str. 192/1',
  PostCode: 71522,
  City: 'Backnang',
  Latitude: '4.896.056.000',
  Longitude: '943.078.000',
  Telephone: '+49 (0) 71919543',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.96056,9.43078',
  id: '48.96056,9.43078'
}, {
  Brand: 'Eni',
  Street: 'Bamberger Strasse 30',
  PostCode: 95445,
  City: 'Bayreuth',
  Latitude: '4.993.694.000',
  Longitude: '1.155.421.000',
  Telephone: '+49 (0) 92162678',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.93694,11.55421',
  id: '49.93694,11.55421'
}, {
  Brand: 'Eni',
  Street: 'Nuernberger Str. 109',
  PostCode: 91710,
  City: 'Gunzenhausen',
  Latitude: '4.912.719.767',
  Longitude: '1.076.824.883',
  Telephone: '+49 (0) 98312504',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.12719767,10.76824883',
  id: '49.12719767,10.76824883'
}, {
  Brand: 'OMV',
  Street: 'Regensburger Str. 2 a',
  PostCode: 90592,
  City: 'Schwarzenbruck',
  Latitude: '4.935.962.000',
  Longitude: '1.125.311.000',
  Telephone: '+49-9128-723003',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.35962,11.25311',
  id: '49.35962,11.25311'
}, {
  Brand: 'Eni',
  Street: 'In Laisen 14',
  PostCode: 72766,
  City: 'Reutlingen',
  Latitude: '4.850.396.000',
  Longitude: '922.034.000',
  Telephone: '+49 (0) 71214902',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.50396,9.22034',
  id: '48.50396,9.22034'
}, {
  Brand: 'OMV',
  Street: 'Werner-v.-Siemens-Str. 45',
  PostCode: 91052,
  City: 'Erlangen',
  Latitude: '4.959.270.000',
  Longitude: '1.101.430.000',
  Telephone: '+49-9131-24262',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.5927,11.0143',
  id: '49.5927,11.0143'
}, {
  Brand: 'TOTAL',
  Street: 'WALDENBURGER STR. 115',
  PostCode: 8371,
  City: 'GLAUCHAU',
  Latitude: '50.840.243',
  Longitude: '12.542.515',
  Telephone: '03763-15022',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.840243,12.542515',
  id: '50.840243,12.542515'
}, {
  Brand: 'TOTAL',
  Street: 'LEONHARDSBERG 17',
  PostCode: 86150,
  City: 'AUGSBURG',
  Latitude: '48.370.697',
  Longitude: '10.900.372',
  Telephone: '0821-514662',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.370697,10.900372',
  id: '48.370697,10.900372'
}, {
  Brand: 'Eni',
  Street: 'Boschetsrieder Str. 162',
  PostCode: 81379,
  City: 'Muenchen',
  Latitude: '4.811.107.000',
  Longitude: '1.153.658.000',
  Telephone: '+49 (0) 89748794',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.11107,11.53658',
  id: '48.11107,11.53658'
}, {
  Brand: 'Eni',
  Street: 'Schongauer Str. 22',
  PostCode: 82380,
  City: 'Peissenberg',
  Latitude: '4.779.315.314',
  Longitude: '1.106.287.882',
  Telephone: '+49 (0) 88032091',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.79315314,11.06287882',
  id: '47.79315314,11.06287882'
}, {
  Brand: 'TOTAL',
  Street: 'BAB NORD/A 24',
  PostCode: 19372,
  City: 'STOLPE',
  Latitude: '53.367.656',
  Longitude: '11.731.897',
  Telephone: '038725-169975',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.367656,11.731897',
  id: '53.367656,11.731897'
}, {
  Brand: 'Eni',
  Street: 'Laufamholz Strasse 294',
  PostCode: 90482,
  City: 'Nuernberg',
  Latitude: '4.946.895.000',
  Longitude: '1.116.705.000',
  Telephone: '+49 (0) 91150128',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.46895,11.16705',
  id: '49.46895,11.16705'
}, {
  Brand: 'OMV',
  Street: 'Brettergartenstr. 76',
  PostCode: 90427,
  City: 'Nürnberg',
  Latitude: '4.947.043.000',
  Longitude: '1.102.615.000',
  Telephone: '+49-911-316477',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.47043,11.02615',
  id: '49.47043,11.02615'
}, {
  Brand: 'ARAL',
  Street: 'Grünwalder Weg 42',
  PostCode: 82008,
  City: 'Unterhaching',
  Latitude: '480.568.470',
  Longitude: '115.995.910',
  Telephone: '8961100544',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.056847,11.599591',
  id: '48.056847,11.599591'
}, {
  Brand: 'OMV',
  Street: 'Ortsstr. 35',
  PostCode: 89278,
  City: 'Nersingen',
  Latitude: '4.841.844.000',
  Longitude: '1.013.331.000',
  Telephone: '+49-7308-3472',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.41844,10.13331',
  id: '48.41844,10.13331'
}, {
  Brand: 'Eni',
  Street: 'Aeussere Bautzener Str. 38',
  PostCode: 2708,
  City: 'Loebau',
  Latitude: '5.110.569.485',
  Longitude: '1.465.293.466',
  Telephone: '+49 (0) 35858606',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.10569485,14.65293466',
  id: '51.10569485,14.65293466'
}, {
  Brand: 'ARAL',
  Street: 'Poststraße 40',
  PostCode: 76534,
  City: 'Baden-Baden',
  Latitude: '487.292.950',
  Longitude: '81.560.180',
  Telephone: '722396420',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.729295,8.156018',
  id: '48.729295,8.156018'
}, {
  Brand: 'ARAL',
  Street: 'Krumbacher Straße 54',
  PostCode: 89335,
  City: 'Ichenhausen',
  Latitude: '483.658.070',
  Longitude: '103.059.670',
  Telephone: '82235222',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.365807,10.305967',
  id: '48.365807,10.305967'
}, {
  Brand: 'Eni',
  Street: 'Gewerbegeb. Langweid a.d. B301',
  PostCode: 84094,
  City: 'Elsendorf',
  Latitude: '4.871.580.033',
  Longitude: '1.180.222.790',
  Telephone: '+49 (0) 87539674',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.71580033,11.8022279',
  id: '48.71580033,11.8022279'
}, {
  Brand: 'TOTAL',
  Street: 'KOENIGSBRUECKER LANDSTR. 265',
  PostCode: 1108,
  City: 'DRESDEN-WEIXDORF',
  Latitude: '51.140.392',
  Longitude: '13.797.571',
  Telephone: '0351-8802301',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.140392,13.797571',
  id: '51.140392,13.797571'
}, {
  Brand: 'TOTAL',
  Street: 'TOEPELSTR.',
  PostCode: 4746,
  City: 'HARTHA',
  Latitude: '511.028',
  Longitude: '1.295.808',
  Telephone: '034328-60424',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.1028,12.95808',
  id: '51.1028,12.95808'
}, {
  Brand: 'TOTAL',
  Street: 'FOLTZRING 38-40',
  PostCode: 67227,
  City: 'FRANKENTHAL',
  Latitude: '49.537.769',
  Longitude: '8.359.031',
  Telephone: '06233-66197',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.537769,8.359031',
  id: '49.537769,8.359031'
}, {
  Brand: 'TOTAL',
  Street: 'WUERZBURGER STR. 55',
  PostCode: 97318,
  City: 'BIEBELRIED',
  Latitude: '49.766.837',
  Longitude: '10.075.818',
  Telephone: '09302-6498750',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.766837,10.075818',
  id: '49.766837,10.075818'
}, {
  Brand: 'TOTAL',
  Street: 'DUEBENER STR. 133',
  PostCode: 4509,
  City: 'DELITZSCH',
  Latitude: '51.531.904',
  Longitude: '12.357.294',
  Telephone: '034202/969969',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.531904,12.357294',
  id: '51.531904,12.357294'
}, {
  Brand: 'OMV',
  Street: 'Münchner Str. 47',
  PostCode: 85737,
  City: 'Ismaning',
  Latitude: '4.822.560.000',
  Longitude: '1.167.138.000',
  Telephone: '+49-89-969539',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.2256,11.67138',
  id: '48.2256,11.67138'
}, {
  Brand: 'Westfalen',
  Street: 'Stresemannstr. 253',
  PostCode: null,
  City: 'BREMERHAVEN',
  Latitude: '535.651',
  Longitude: '859.509',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.5651,8.59509',
  id: '53.5651,8.59509'
}, {
  Brand: 'ARAL',
  Street: 'Kaiserstraße 11',
  PostCode: 67816,
  City: 'Standenbühl',
  Latitude: '495.940.530',
  Longitude: '79.852.730',
  Telephone: '6357989117',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '49.594053,7.985273',
  id: '49.594053,7.985273'
}, {
  Brand: 'ARAL',
  Street: 'Vardeler Weg 1/B69',
  PostCode: 49377,
  City: 'Vechta',
  Latitude: '527.560.300',
  Longitude: '82.741.480',
  Telephone: '44418545212',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.75603,8.274148',
  id: '52.75603,8.274148'
}, {
  Brand: 'Eni',
  Street: 'Haidenbucher Strasse 2',
  PostCode: 86916,
  City: 'Kaufering',
  Latitude: '4.808.772.898',
  Longitude: '1.085.619.475',
  Telephone: '+49 (0) 81919662',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.08772898,10.85619475',
  id: '48.08772898,10.85619475'
}, {
  Brand: 'ARAL',
  Street: 'Gustav-Stresemann-Straße',
  PostCode: 55411,
  City: 'Bingen',
  Latitude: '499.261.623',
  Longitude: '79.339.705',
  Telephone: '67219628623',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.9261623,7.9339705',
  id: '49.9261623,7.9339705'
}, {
  Brand: 'OMV',
  Street: 'Weissenburger Str. 24',
  PostCode: 85072,
  City: 'Eichstätt',
  Latitude: '4.889.507.000',
  Longitude: '1.117.613.000',
  Telephone: '+49-8421-2675',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.89507,11.17613',
  id: '48.89507,11.17613'
}, {
  Brand: 'OMV',
  Street: 'Bayerwaldstr. 12',
  PostCode: 94327,
  City: 'Bogen',
  Latitude: '4.891.138.000',
  Longitude: '1.269.500.000',
  Telephone: '+49-9422-805096',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.91138,12.695',
  id: '48.91138,12.695'
}, {
  Brand: 'ARAL',
  Street: 'In den Siebenmorgen 2c',
  PostCode: 56077,
  City: 'Koblenz',
  Latitude: '503.712.520',
  Longitude: '76.454.310',
  Telephone: '2616710307',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.371252,7.645431',
  id: '50.371252,7.645431'
}, {
  Brand: 'ARAL',
  Street: 'Im Stöckmädle 29',
  PostCode: 76307,
  City: 'Karlsbad-Ittersbach',
  Latitude: '488.804.200',
  Longitude: '85.100.050',
  Telephone: '7248934160',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:30',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '48.88042,8.510005',
  id: '48.88042,8.510005'
}, {
  Brand: 'Westfalen',
  Street: 'Koelner Str. 188',
  PostCode: null,
  City: 'GUMMERSBACH',
  Latitude: '510.042',
  Longitude: '759.517',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.0042,7.59517',
  id: '51.0042,7.59517'
}, {
  Brand: 'ARAL',
  Street: 'Roedernallee 179',
  PostCode: 13407,
  City: 'Berlin',
  Latitude: '525.791.290',
  Longitude: '133.463.920',
  Telephone: '3040910920',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.579129,13.346392',
  id: '52.579129,13.346392'
}, {
  Brand: 'ARAL',
  Street: 'Neunkirchener Straße 2',
  PostCode: 7952,
  City: 'Pausa',
  Latitude: '505.879.230',
  Longitude: '119.981.850',
  Telephone: '374327739',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '20:00',
  SundayFrom: '09:00',
  SundayTo: '17:00',
  IsOpen24Hours: 'Nein',
  location: '50.587923,11.998185',
  id: '50.587923,11.998185'
}, {
  Brand: 'TOTAL',
  Street: 'HAUPTSTRASSE / KORNBLUMENWEG 43',
  PostCode: 78244,
  City: 'GOTTMADINGEN',
  Latitude: '4.773.656',
  Longitude: '8.7889',
  Telephone: '77319072490',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.73656,8.7889',
  id: '47.73656,8.7889'
}, {
  Brand: 'TOTAL',
  Street: 'KAISERSLAUTERER STR.37',
  PostCode: 66424,
  City: 'HOMBURG-BRUCHHOF',
  Latitude: '49.340.669',
  Longitude: '7.356.216',
  Telephone: '06841-64264',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.340669,7.356216',
  id: '49.340669,7.356216'
}, {
  Brand: 'TOTAL',
  Street: 'WESTICKER STR. 58',
  PostCode: 58730,
  City: 'FROENDENBERG',
  Latitude: '51.475.344',
  Longitude: '7.788.722',
  Telephone: '02373-76259',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.475344,7.788722',
  id: '51.475344,7.788722'
}, {
  Brand: 'Westfalen',
  Street: 'Ritter-Heinrich-Str.',
  PostCode: null,
  City: 'ULMEN',
  Latitude: '502.142',
  Longitude: '697.223',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.2142,6.97223',
  id: '50.2142,6.97223'
}, {
  Brand: 'TOTAL',
  Street: '98-99 ARNULFSTR.',
  PostCode: 12105,
  City: 'BERLIN',
  Latitude: '52.455.578',
  Longitude: '13.367.255',
  Telephone: '030-75489500',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.455578,13.367255',
  id: '52.455578,13.367255'
}, {
  Brand: 'TOTAL',
  Street: 'AN DER AUTOBAHN 4',
  PostCode: 28876,
  City: 'OYTEN',
  Latitude: '5.305.335',
  Longitude: '9.031.812',
  Telephone: '04207-7081',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.05335,9.031812',
  id: '53.05335,9.031812'
}, {
  Brand: 'ARAL',
  Street: 'An der B9',
  PostCode: 56656,
  City: 'Brohl-Lützing',
  Latitude: '504.862.870',
  Longitude: '73.290.600',
  Telephone: '26334729318',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.486287,7.32906',
  id: '50.486287,7.32906'
}, {
  Brand: 'ARAL',
  Street: 'Steinkampstraße 56',
  PostCode: 45476,
  City: 'Mülheim an der Ruhr',
  Latitude: '514.474.880',
  Longitude: '68.459.530',
  Telephone: '20837749841',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.447488,6.845953',
  id: '51.447488,6.845953'
}, {
  Brand: 'OMV',
  Street: 'Ammerholz 3',
  PostCode: 93080,
  City: 'Pentling',
  Latitude: '4.897.290.000',
  Longitude: '1.206.508.000',
  Telephone: '+49-9405-5009024',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.9729,12.06508',
  id: '48.9729,12.06508'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 8 a',
  PostCode: 86981,
  City: 'Kinsau',
  Latitude: '478.816.250',
  Longitude: '108.914.950',
  Telephone: '8869228',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '47.881625,10.891495',
  id: '47.881625,10.891495'
}, {
  Brand: 'ARAL',
  Street: 'Stuttgarter Straße 54',
  PostCode: 75417,
  City: 'Mühlacker',
  Latitude: '489.465.310',
  Longitude: '88.464.220',
  Telephone: '70413100',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.946531,8.846422',
  id: '48.946531,8.846422'
}, {
  Brand: 'TOTAL',
  Street: 'SALEMER STR. 47',
  PostCode: 88697,
  City: 'BERMATINGEN',
  Latitude: '47.735.831',
  Longitude: '9.341.367',
  Telephone: '07544-741930',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.735831,9.341367',
  id: '47.735831,9.341367'
}, {
  Brand: 'Eni',
  Street: 'Vacher Strasse 417',
  PostCode: 90768,
  City: 'Fuerth',
  Latitude: '4.952.106.000',
  Longitude: '1.096.406.000',
  Telephone: '+49 (0) 91176138',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.52106,10.96406',
  id: '49.52106,10.96406'
}, {
  Brand: 'Eni',
  Street: 'Am Moos 24',
  PostCode: 96465,
  City: 'Neustadt',
  Latitude: '5.033.223.370',
  Longitude: '1.111.406.417',
  Telephone: '+49 (0) 95686114',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.3322337,11.11406417',
  id: '50.3322337,11.11406417'
}, {
  Brand: 'Eni',
  Street: 'Bahnhofstrasse 47',
  PostCode: 84494,
  City: 'Neumarkt/St.Veit',
  Latitude: '4.835.931.661',
  Longitude: '1.250.180.251',
  Telephone: '+49 (0) 86397098',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.35931661,12.50180251',
  id: '48.35931661,12.50180251'
}, {
  Brand: 'OMV',
  Street: 'Münchener Str. 45',
  PostCode: 85221,
  City: 'Dachau',
  Latitude: '4.825.183.000',
  Longitude: '1.144.119.000',
  Telephone: '+49-8131-83758',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.25183,11.44119',
  id: '48.25183,11.44119'
}, {
  Brand: 'TOTAL',
  Street: 'WAIBSTAEDTER STR. 35',
  PostCode: 74924,
  City: 'NECKARBISCHOFSHEIM',
  Latitude: '49.295.791',
  Longitude: '895.444',
  Telephone: '07263-969900',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.295791,8.95444',
  id: '49.295791,8.95444'
}, {
  Brand: 'ARAL',
  Street: 'Salzer Straße 18 d',
  PostCode: 39240,
  City: 'Calbe',
  Latitude: '519.124.020',
  Longitude: '117.775.680',
  Telephone: '3929177800',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '05:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '51.912402,11.777568',
  id: '51.912402,11.777568'
}, {
  Brand: 'Eni',
  Street: 'Ettaler Str. 3',
  PostCode: 82496,
  City: 'Oberau',
  Latitude: '4.755.964.000',
  Longitude: '1.113.368.000',
  Telephone: '+49 (0) 88249305',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.55964,11.13368',
  id: '47.55964,11.13368'
}, {
  Brand: 'ARAL',
  Street: 'Fauerbacher Straße 95',
  PostCode: 61169,
  City: 'Friedberg-Fauerbach',
  Latitude: '503.302.240',
  Longitude: '87.685.190',
  Telephone: '6031719730',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '23:00',
  SundayFrom: '06:00',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '50.330224,8.768519',
  id: '50.330224,8.768519'
}, {
  Brand: 'ARAL',
  Street: 'Am Tannenwald 4',
  PostCode: 66459,
  City: 'Kirkel',
  Latitude: '492.878.000',
  Longitude: '72.178.600',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.2878,7.21786',
  id: '49.2878,7.21786'
}, {
  Brand: 'Avanti',
  Street: 'Staudhamer Feld 3',
  PostCode: 83512,
  City: 'Wasserburg am Inn',
  Latitude: '4.806.050.000',
  Longitude: '1.217.407.000',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.0605,12.17407',
  id: '48.0605,12.17407'
}, {
  Brand: 'Westfalen',
  Street: 'Brautäcker 2',
  PostCode: null,
  City: 'FRITZLAR',
  Latitude: '51.140.017',
  Longitude: '9.274.789',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.140017,9.274789',
  id: '51.140017,9.274789'
}, {
  Brand: 'OMV',
  Street: 'Alteburgstr. 79',
  PostCode: 72762,
  City: 'Reutlingen',
  Latitude: '4.848.294.000',
  Longitude: '920.088.000',
  Telephone: '+49-7121-270778',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.48294,9.20088',
  id: '48.48294,9.20088'
}, {
  Brand: 'OMV',
  Street: 'Aichacher Str. 13',
  PostCode: 85250,
  City: 'Altomünster',
  Latitude: '4.838.467.000',
  Longitude: '1.125.065.000',
  Telephone: '+49-8254-8213',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.38467,11.25065',
  id: '48.38467,11.25065'
}, {
  Brand: 'OMV',
  Street: 'Kaufbeurer Str. 38',
  PostCode: 86825,
  City: 'Bad Wörishofen',
  Latitude: '4.800.227.000',
  Longitude: '1.060.423.000',
  Telephone: '+49-8247-2331',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.00227,10.60423',
  id: '48.00227,10.60423'
}, {
  Brand: 'ARAL',
  Street: 'A 61',
  PostCode: 67105,
  City: 'Schifferstadt',
  Latitude: '494.092.620',
  Longitude: '83.415.170',
  Telephone: '6231915156',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.409262,8.341517',
  id: '49.409262,8.341517'
}, {
  Brand: 'Eni',
  Street: 'Zwickauer Strasse 16',
  PostCode: 8485,
  City: 'Lengenfeld',
  Latitude: '5.057.422.777',
  Longitude: '1.237.429.416',
  Telephone: '+49 (0) 37606378',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.57422777,12.37429416',
  id: '50.57422777,12.37429416'
}, {
  Brand: 'TOTAL',
  Street: 'FABRIKSTR. 17',
  PostCode: 1683,
  City: 'NOSSEN',
  Latitude: '510.617',
  Longitude: '13.289.999',
  Telephone: '035242-68259',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.0617,13.289999',
  id: '51.0617,13.289999'
}, {
  Brand: 'TOTAL',
  Street: '17 HEIDENHEIMER STR.',
  PostCode: 73450,
  City: 'NERESHEIM',
  Latitude: '48.754.974',
  Longitude: '10.328.288',
  Telephone: '7326389',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.754974,10.328288',
  id: '48.754974,10.328288'
}, {
  Brand: 'ARAL',
  Street: 'Mockstädter Straße 2',
  PostCode: 61197,
  City: 'Florstadt-Staden',
  Latitude: '503.299.550',
  Longitude: '89.141.250',
  Telephone: '60357114',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.329955,8.914125',
  id: '50.329955,8.914125'
}, {
  Brand: 'Eni',
  Street: 'Loebauer Str. 151',
  PostCode: 2625,
  City: 'Bautzen',
  Latitude: '5.117.910.500',
  Longitude: '1.445.051.000',
  Telephone: '+49 (0) 35912419',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.179105,14.45051',
  id: '51.179105,14.45051'
}, {
  Brand: 'Eni',
  Street: 'Hauptstr. 23',
  PostCode: 83329,
  City: 'Waging',
  Latitude: '4.795.683.000',
  Longitude: '1.275.798.000',
  Telephone: '08681/4631',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.95683,12.75798',
  id: '47.95683,12.75798'
}, {
  Brand: 'Westfalen',
  Street: 'Preussnstr. 2 a',
  PostCode: null,
  City: 'WILHELMSHAVEN',
  Latitude: '535.669',
  Longitude: '809.868',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.5669,8.09868',
  id: '53.5669,8.09868'
}, {
  Brand: 'OMV',
  Street: 'Mellrichstädter Höhe Ost A 71',
  PostCode: 97638,
  City: 'Mellrichstadt-Rossrieth',
  Latitude: '5.042.136.000',
  Longitude: '1.034.902.000',
  Telephone: '+49-9776-7090343',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.42136,10.34902',
  id: '50.42136,10.34902'
}, {
  Brand: 'Westfalen',
  Street: 'Koenigstr. 69',
  PostCode: null,
  City: 'GREVEN',
  Latitude: '520.934',
  Longitude: '7.6234',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0934,7.6234',
  id: '52.0934,7.6234'
}, {
  Brand: 'OMV',
  Street: 'Versbacher Str. 106',
  PostCode: 97078,
  City: 'Würzburg',
  Latitude: '4.981.609.000',
  Longitude: '996.334.000',
  Telephone: '+49-931-21585',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.81609,9.96334',
  id: '49.81609,9.96334'
}, {
  Brand: 'Westfalen',
  Street: 'Osnabruecker Str. 6',
  PostCode: null,
  City: 'DISSEN',
  Latitude: '521.157',
  Longitude: '819.804',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.1157,8.19804',
  id: '52.1157,8.19804'
}, {
  Brand: 'TOTAL',
  Street: 'ADLERGESTELL 623',
  PostCode: 12527,
  City: 'BERLIN',
  Latitude: '52.387.774',
  Longitude: '13.630.654',
  Telephone: '030-6758108',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.387774,13.630654',
  id: '52.387774,13.630654'
}, {
  Brand: 'OMV',
  Street: 'Sulzbacher Str. 24 - 26',
  PostCode: 90489,
  City: 'Nürnberg',
  Latitude: '4.945.788.000',
  Longitude: '1.109.329.000',
  Telephone: '+49-911-5882711',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.45788,11.09329',
  id: '49.45788,11.09329'
}, {
  Brand: 'Westfalen',
  Street: 'Niedringhausener Str. 130',
  PostCode: null,
  City: 'HUELLHORST',
  Latitude: '522.693',
  Longitude: '863.558',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.2693,8.63558',
  id: '52.2693,8.63558'
}, {
  Brand: 'Eni',
  Street: 'Stettfelder Str. 56',
  PostCode: 76698,
  City: 'Ubstadt-Weiher',
  Latitude: '4.916.381.104',
  Longitude: '863.457.943',
  Telephone: '+49 (0) 72519616',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.16381104,8.63457943',
  id: '49.16381104,8.63457943'
}, {
  Brand: 'TOTAL',
  Street: 'AARSTR. 212',
  PostCode: 65232,
  City: 'TAUNUSSTEIN',
  Latitude: '50.152.839',
  Longitude: '8.180.473',
  Telephone: '06128-982542',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.152839,8.180473',
  id: '50.152839,8.180473'
}, {
  Brand: 'ARAL',
  Street: 'Peter-Hurst-Str.',
  PostCode: 38448,
  City: 'Wolfsburg',
  Latitude: '523.718.460',
  Longitude: '107.372.530',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.371846,10.737253',
  id: '52.371846,10.737253'
}, {
  Brand: 'TOTAL',
  Street: '11 NOERDLINGER STR.',
  PostCode: 86757,
  City: 'WALLERSTEIN',
  Latitude: '4.888.358',
  Longitude: '10.476.118',
  Telephone: '09081-7087',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.88358,10.476118',
  id: '48.88358,10.476118'
}, {
  Brand: 'TOTAL',
  Street: 'BENTHEIMER STR. 233',
  PostCode: 48529,
  City: 'NORDHORN',
  Latitude: '52.419.552',
  Longitude: '7.083.555',
  Telephone: '05921-80450',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.419552,7.083555',
  id: '52.419552,7.083555'
}, {
  Brand: 'TOTAL',
  Street: 'BULGARISCHE STR. 9',
  PostCode: 12435,
  City: 'BERLIN',
  Latitude: '52.483.773',
  Longitude: '13.478.669',
  Telephone: '030-5337955',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.483773,13.478669',
  id: '52.483773,13.478669'
}, {
  Brand: 'ARAL',
  Street: 'A 7',
  PostCode: 34593,
  City: 'Knüllwald',
  Latitude: '510.368.350',
  Longitude: '94.877.600',
  Telephone: '5685922290',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.036835,9.48776',
  id: '51.036835,9.48776'
}, {
  Brand: 'Eni',
  Street: 'Am Huenstein 65',
  PostCode: 99735,
  City: 'Nohra',
  Latitude: '5.142.875.000',
  Longitude: '1.071.415.000',
  Telephone: '+49 (0) 36334538',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.42875,10.71415',
  id: '51.42875,10.71415'
}, {
  Brand: 'TOTAL',
  Street: 'SCHLACHTHAUSSTR. 52',
  PostCode: 89312,
  City: 'GUENZBURG',
  Latitude: '4.845.862',
  Longitude: '10.2767',
  Telephone: '08221-2039255',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.45862,10.2767',
  id: '48.45862,10.2767'
}, {
  Brand: 'ARAL',
  Street: 'Landsberger Straße 2',
  PostCode: 86836,
  City: 'Graben',
  Latitude: '481.986.740',
  Longitude: '108.539.490',
  Telephone: '82322230',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.198674,10.853949',
  id: '48.198674,10.853949'
}, {
  Brand: 'Westfalen',
  Street: 'Osningstr. 15',
  PostCode: null,
  City: 'BIELEFELD',
  Latitude: '520.334',
  Longitude: '855.888',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0334,8.55888',
  id: '52.0334,8.55888'
}, {
  Brand: 'Eni',
  Street: 'Weinstrasse Nord 57',
  PostCode: 67098,
  City: 'Bad Duerkheim',
  Latitude: '4.946.098.000',
  Longitude: '816.238.000',
  Telephone: '+49 (0) 63226601',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.46098,8.16238',
  id: '49.46098,8.16238'
}, {
  Brand: 'Westfalen',
  Street: 'Wittener Str. 19',
  PostCode: null,
  City: 'GEVELSBERG',
  Latitude: '513.217',
  Longitude: '733.794',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.3217,7.33794',
  id: '51.3217,7.33794'
}, {
  Brand: 'ARAL',
  Street: 'Hoeber-u.Mandelbaumstr. 1',
  PostCode: 68794,
  City: 'Oberhausen-Rheinhaus',
  Latitude: '492.504.550',
  Longitude: '85.008.380',
  Telephone: '72542031920',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.250455,8.500838',
  id: '49.250455,8.500838'
}, {
  Brand: 'Westfalen',
  Street: 'Mettinger Str. 33',
  PostCode: null,
  City: 'IBBENBUEREN',
  Latitude: '522.665',
  Longitude: '778.847',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.2665,7.78847',
  id: '52.2665,7.78847'
}, {
  Brand: 'TOTAL',
  Street: '3 RUHMRISCHKAMP',
  PostCode: 38179,
  City: 'SCHWUELPER',
  Latitude: '52.337.642',
  Longitude: '10.417.812',
  Telephone: '05303-9709854',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.337642,10.417812',
  id: '52.337642,10.417812'
}, {
  Brand: 'Westfalen',
  Street: 'Roentgenstr. 2/2a',
  PostCode: null,
  City: 'HERFORD',
  Latitude: '520.608',
  Longitude: '864.421',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0608,8.64421',
  id: '52.0608,8.64421'
}, {
  Brand: 'Westfalen',
  Street: 'Jacob-Borchers-Str. 49 a',
  PostCode: null,
  City: 'ZETEL',
  Latitude: '534.217',
  Longitude: '797.962',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.4217,7.97962',
  id: '53.4217,7.97962'
}, {
  Brand: 'Westfalen',
  Street: 'Luebbecker Str.115',
  PostCode: null,
  City: 'KIRCHLENGERN',
  Latitude: '522.042',
  Longitude: '864.026',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.2042,8.64026',
  id: '52.2042,8.64026'
}, {
  Brand: 'TOTAL',
  Street: 'ALT MAHLSDORF 60',
  PostCode: 12623,
  City: 'BERLIN',
  Latitude: '52.504.209',
  Longitude: '13.624.332',
  Telephone: '030-5677297',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.504209,13.624332',
  id: '52.504209,13.624332'
}, {
  Brand: 'ARAL',
  Street: 'Auf der Gänsmatt 14',
  PostCode: 79650,
  City: 'Schopfheim',
  Latitude: '476.469.010',
  Longitude: '78.071.440',
  Telephone: '76224900',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '47.646901,7.807144',
  id: '47.646901,7.807144'
}, {
  Brand: 'TOTAL',
  Street: 'ALBERT-VATER-STR. 30',
  PostCode: 39108,
  City: 'MAGDEBURG',
  Latitude: '52.141.251',
  Longitude: '11.623.434',
  Telephone: '0391-2511279',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.141251,11.623434',
  id: '52.141251,11.623434'
}, {
  Brand: 'ARAL',
  Street: 'Kölner Straße 35',
  PostCode: 57610,
  City: 'Altenkirchen',
  Latitude: '506.868.080',
  Longitude: '76.340.160',
  Telephone: '2681987630',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.686808,7.634016',
  id: '50.686808,7.634016'
}, {
  Brand: 'ARAL',
  Street: 'Colditzer Straße 7 c',
  PostCode: 9306,
  City: 'Rochlitz',
  Latitude: '510.611.480',
  Longitude: '127.868.070',
  Telephone: '373741351',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.061148,12.786807',
  id: '51.061148,12.786807'
}, {
  Brand: 'ARAL',
  Street: 'Von-Ketteler-Straße 4',
  PostCode: 96049,
  City: 'Bamberg',
  Latitude: '498.865.990',
  Longitude: '109.218.480',
  Telephone: '9519172722',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.886599,10.921848',
  id: '49.886599,10.921848'
}, {
  Brand: 'TOTAL',
  Street: 'BAB BOERDE NORD / A2',
  PostCode: 39343,
  City: 'GROSS SANTERSLEBEN',
  Latitude: '52.183.716',
  Longitude: '1.145.575',
  Telephone: '039206-68351',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.183716,11.45575',
  id: '52.183716,11.45575'
}, {
  Brand: 'TOTAL',
  Street: 'UNGERATH 301',
  PostCode: 41366,
  City: 'SCHWALMTAL',
  Latitude: '51.208.558',
  Longitude: '628.083',
  Telephone: '02163-943140',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.208558,6.28083',
  id: '51.208558,6.28083'
}, {
  Brand: 'TOTAL',
  Street: 'OHMSTR. 25',
  PostCode: 70736,
  City: 'FELLBACH',
  Latitude: '48.822.349',
  Longitude: '9.285.043',
  Telephone: '0711-54041707',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.822349,9.285043',
  id: '48.822349,9.285043'
}, {
  Brand: 'TOTAL',
  Street: 'MAINZER STR. 140',
  PostCode: 67657,
  City: 'KAISERSLAUTERN',
  Latitude: '49.457.298',
  Longitude: '7.801.527',
  Telephone: '0631-40226',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.457298,7.801527',
  id: '49.457298,7.801527'
}, {
  Brand: 'OMV',
  Street: 'Gewerbepark 2',
  PostCode: 96149,
  City: 'Breitengüssbach',
  Latitude: '4.997.341.000',
  Longitude: '1.087.534.000',
  Telephone: '+49-9544-982090',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.97341,10.87534',
  id: '49.97341,10.87534'
}, {
  Brand: 'TOTAL',
  Street: 'BERLINER STR. 31',
  PostCode: 16244,
  City: 'SCHORFHEIDE',
  Latitude: '52.903.099',
  Longitude: '13.535.292',
  Telephone: '033393-516',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.903099,13.535292',
  id: '52.903099,13.535292'
}, {
  Brand: 'TOTAL',
  Street: 'GRABOWER ALLEE 31',
  PostCode: 19288,
  City: 'LUDWIGSLUST',
  Latitude: '5.331.607',
  Longitude: '11.506.477',
  Telephone: '03874-61143/45',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.31607,11.506477',
  id: '53.31607,11.506477'
}, {
  Brand: 'TOTAL',
  Street: 'HARPENER HELLWEG 88',
  PostCode: 44805,
  City: 'BOCHUM-HARPEN',
  Latitude: '51.499.297',
  Longitude: '7.276.438',
  Telephone: '0234-231498',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.499297,7.276438',
  id: '51.499297,7.276438'
}, {
  Brand: 'TOTAL',
  Street: 'LANGES GEWAND 3',
  PostCode: 78604,
  City: 'RIETHEIM-WEILHEIM',
  Latitude: '480.306',
  Longitude: '8.7763',
  Telephone: '07461-969725100',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.0306,8.7763',
  id: '48.0306,8.7763'
}, {
  Brand: 'TOTAL',
  Street: 'WEILBURGER STR. 30/33',
  PostCode: 61250,
  City: 'USINGEN',
  Latitude: '50.340.076',
  Longitude: '8.534.939',
  Telephone: '06081-2680',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.340076,8.534939',
  id: '50.340076,8.534939'
}, {
  Brand: 'ARAL',
  Street: 'Pasinger Straße 50',
  PostCode: 82166,
  City: 'Gräfelfing',
  Latitude: '481.180.980',
  Longitude: '114.426.650',
  Telephone: '898543576',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.118098,11.442665',
  id: '48.118098,11.442665'
}, {
  Brand: 'TOTAL',
  Street: 'NAUMBURGER STRASSE 26',
  PostCode: 6667,
  City: 'WEISSENFELS',
  Latitude: '51.198.265',
  Longitude: '11.963.868',
  Telephone: '03443-334566',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.198265,11.963868',
  id: '51.198265,11.963868'
}, {
  Brand: 'ARAL',
  Street: 'Memminger Straße 182',
  PostCode: 89231,
  City: 'Neu-Ulm',
  Latitude: '483.662.500',
  Longitude: '100.098.520',
  Telephone: '73183034',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.36625,10.009852',
  id: '48.36625,10.009852'
}, {
  Brand: 'TOTAL',
  Street: 'EDINGER RIEDWEG 8-12',
  PostCode: 68219,
  City: 'MANNHEIM',
  Latitude: '49.421.773',
  Longitude: '8.534.002',
  Telephone: '0621-8019209',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.421773,8.534002',
  id: '49.421773,8.534002'
}, {
  Brand: 'OMV',
  Street: 'Hauptstr. 19',
  PostCode: 94571,
  City: 'Schaufling',
  Latitude: '4.884.217.000',
  Longitude: '1.307.111.000',
  Telephone: '+49-9904-503',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.84217,13.07111',
  id: '48.84217,13.07111'
}, {
  Brand: 'ARAL',
  Street: 'Mindelheimer Straße 19',
  PostCode: 87772,
  City: 'Pfaffenhausen',
  Latitude: '481.203.660',
  Longitude: '104.620.150',
  Telephone: '82657332333',
  WeekDayFrom: '06:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.120366,10.462015',
  id: '48.120366,10.462015'
}, {
  Brand: 'OMV',
  Street: 'Köschinger Forst West A 9',
  PostCode: 85120,
  City: 'Hepberg',
  Latitude: '4.883.858.000',
  Longitude: '1.146.848.000',
  Telephone: '+49-8405-304',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.83858,11.46848',
  id: '48.83858,11.46848'
}, {
  Brand: 'OMV',
  Street: 'Erlanger Str. 1',
  PostCode: 91093,
  City: 'Hessdorf',
  Latitude: '4.962.866.000',
  Longitude: '1.091.182.000',
  Telephone: '+49-9135-799838',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.62866,10.91182',
  id: '49.62866,10.91182'
}, {
  Brand: 'ARAL',
  Street: 'Dillinger Straße 4 1/2',
  PostCode: 89415,
  City: 'Lauingen',
  Latitude: '485.732.230',
  Longitude: '104.343.930',
  Telephone: '90722423',
  WeekDayFrom: '05:00',
  WeekDayTo: '23:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '23:59',
  SundayFrom: '07:30',
  SundayTo: '23:00',
  IsOpen24Hours: 'Nein',
  location: '48.573223,10.434393',
  id: '48.573223,10.434393'
}, {
  Brand: 'TOTAL',
  Street: 'QUERFURTER STR. 4A',
  PostCode: 6217,
  City: 'MERSEBURG',
  Latitude: '51.371.793',
  Longitude: '11.982.872',
  Telephone: '03461-204531',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.371793,11.982872',
  id: '51.371793,11.982872'
}, {
  Brand: 'TOTAL',
  Street: 'KREUZBERGSTR. 65',
  PostCode: 42899,
  City: 'REMSCHEID',
  Latitude: '51.208.645',
  Longitude: '7.231.365',
  Telephone: '02191-462832',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.208645,7.231365',
  id: '51.208645,7.231365'
}, {
  Brand: 'ARAL',
  Street: 'Dammfeldstraße 1',
  PostCode: 31275,
  City: 'Lehrte',
  Latitude: '523.982.600',
  Longitude: '99.736.000',
  Telephone: '51323523',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.39826,9.9736',
  id: '52.39826,9.9736'
}, {
  Brand: 'TOTAL',
  Street: '116 KUFSTEINER STR.',
  PostCode: 83064,
  City: 'RAUBLING',
  Latitude: '47.766.016',
  Longitude: '12.106.026',
  Telephone: '08035-90190',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.766016,12.106026',
  id: '47.766016,12.106026'
}, {
  Brand: 'TOTAL',
  Street: 'PARKSTR. 16',
  PostCode: 47877,
  City: 'WILLICH',
  Latitude: '51.266.699',
  Longitude: '6.551.393',
  Telephone: '02154-205770',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.266699,6.551393',
  id: '51.266699,6.551393'
}, {
  Brand: 'Eni',
  Street: 'Bahnhofstrasse 37',
  PostCode: 83253,
  City: 'Rimsting',
  Latitude: '4.789.013.217',
  Longitude: '1.234.439.795',
  Telephone: '+49 (0) 80516109',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.89013217,12.34439795',
  id: '47.89013217,12.34439795'
}, {
  Brand: 'TOTAL',
  Street: 'WASEN 24',
  PostCode: 79244,
  City: 'MUENSTERTAL',
  Latitude: '47.854.263',
  Longitude: '7.779.449',
  Telephone: '07636-7889780',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.854263,7.779449',
  id: '47.854263,7.779449'
}, {
  Brand: 'TOTAL',
  Street: '23 SCHULTH.-KOLLEI-STR.',
  PostCode: 55411,
  City: 'BINGEN',
  Latitude: '49.950.114',
  Longitude: '7.903.223',
  Telephone: '06721-44713',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.950114,7.903223',
  id: '49.950114,7.903223'
}, {
  Brand: 'ARAL',
  Street: 'Zeppelinstraße 9-11',
  PostCode: 61118,
  City: 'Bad Vilbel',
  Latitude: '501.939.440',
  Longitude: '87.249.660',
  Telephone: '6101557525',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.193944,8.724966',
  id: '50.193944,8.724966'
}, {
  Brand: 'TOTAL',
  Street: 'HAUPERSWEILER STR. 1',
  PostCode: 66629,
  City: 'FREISEN-OBERKIRCHEN',
  Latitude: '49.524.975',
  Longitude: '7.262.966',
  Telephone: '06855-7100',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.524975,7.262966',
  id: '49.524975,7.262966'
}, {
  Brand: 'TOTAL',
  Street: 'IDSTEINER STR. 50',
  PostCode: 65527,
  City: 'NIEDERNHAUSEN',
  Latitude: '5.016.433',
  Longitude: '8.312.604',
  Telephone: '06127-903620',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.16433,8.312604',
  id: '50.16433,8.312604'
}, {
  Brand: 'ARAL',
  Street: 'Beisenbusch 2',
  PostCode: 48301,
  City: 'Nottuln',
  Latitude: '519.121.180',
  Longitude: '73.992.780',
  Telephone: '2509990460',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.912118,7.399278',
  id: '51.912118,7.399278'
}, {
  Brand: 'Westfalen',
  Street: 'Gerdener Str. 4',
  PostCode: null,
  City: 'MELLE',
  Latitude: '521.427',
  Longitude: '838.747',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.1427,8.38747',
  id: '52.1427,8.38747'
}, {
  Brand: 'Eni',
  Street: 'Leipziger Str. 51',
  PostCode: 4828,
  City: 'Bennewitz',
  Latitude: '5.136.241.500',
  Longitude: '1.269.706.000',
  Telephone: '+49 (0) 34258109',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.362415,12.69706',
  id: '51.362415,12.69706'
}, {
  Brand: 'Eni',
  Street: 'Friedensstrasse 108',
  PostCode: 63165,
  City: 'Muehlheim',
  Latitude: '5.011.990.000',
  Longitude: '882.474.000',
  Telephone: '+49 (0) 61087280',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.1199,8.82474',
  id: '50.1199,8.82474'
}, {
  Brand: 'Eni',
  Street: 'Mahlower Str. 241',
  PostCode: 14513,
  City: 'Teltow',
  Latitude: '5.238.695.825',
  Longitude: '1.330.493.905',
  Telephone: '+49 (0) 33284718',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '52.38695825,13.30493905',
  id: '52.38695825,13.30493905'
}, {
  Brand: 'TOTAL',
  Street: 'ZUM DOENGES 2',
  PostCode: 36208,
  City: 'WILDECK-OBERSUHL',
  Latitude: '50.955.079',
  Longitude: '10.022.439',
  Telephone: '06626-92100',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.955079,10.022439',
  id: '50.955079,10.022439'
}, {
  Brand: 'ARAL',
  Street: 'Opelstraße 1',
  PostCode: 78467,
  City: 'Konstanz',
  Latitude: '476.760.900',
  Longitude: '91.531.300',
  Telephone: '07531/1272460',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.67609,9.15313',
  id: '47.67609,9.15313'
}, {
  Brand: 'Westfalen',
  Street: 'Bahnhofstr. 41',
  PostCode: null,
  City: 'JEVER',
  Latitude: '535.706',
  Longitude: '789.232',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.5706,7.89232',
  id: '53.5706,7.89232'
}, {
  Brand: 'ARAL',
  Street: 'Königsbrücker Straße 25',
  PostCode: 1561,
  City: 'Schönfeld',
  Latitude: '512.954.160',
  Longitude: '137.300.640',
  Telephone: '3524888447',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.295416,13.730064',
  id: '51.295416,13.730064'
}, {
  Brand: 'TOTAL',
  Street: 'NAUENER STR. 116',
  PostCode: 14612,
  City: 'FALKENSEE',
  Latitude: '52.586.399',
  Longitude: '13.064.999',
  Telephone: '03322-22701',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.586399,13.064999',
  id: '52.586399,13.064999'
}, {
  Brand: 'TOTAL',
  Street: '53c HORSTWEG',
  PostCode: 14478,
  City: 'POTSDAM',
  Latitude: '52.382.194',
  Longitude: '13.089.295',
  Telephone: '0331-87003713',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.382194,13.089295',
  id: '52.382194,13.089295'
}, {
  Brand: 'TOTAL',
  Street: 'RICHRATHER STR. 116',
  PostCode: 40723,
  City: 'HILDEN',
  Latitude: '51.158.335',
  Longitude: '6.938.557',
  Telephone: '02103-64460',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.158335,6.938557',
  id: '51.158335,6.938557'
}, {
  Brand: 'Westfalen',
  Street: 'Moehlenkampstr. 9',
  PostCode: null,
  City: 'DUISBURG',
  Latitude: '514.802',
  Longitude: '674.379',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.4802,6.74379',
  id: '51.4802,6.74379'
}, {
  Brand: 'TOTAL',
  Street: 'AACHER STR. 18',
  PostCode: 78234,
  City: 'ENGEN',
  Latitude: '47.855.709',
  Longitude: '8.781.561',
  Telephone: '07733-2055',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.855709,8.781561',
  id: '47.855709,8.781561'
}, {
  Brand: 'TOTAL',
  Street: 'ZOLLHAUSSTR. 10',
  PostCode: 52353,
  City: 'DUEREN',
  Latitude: '50.822.199',
  Longitude: '6.461.393',
  Telephone: '02421-81150',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.822199,6.461393',
  id: '50.822199,6.461393'
}, {
  Brand: 'ARAL',
  Street: 'Nürtinger Straße 24',
  PostCode: 72622,
  City: 'Nürtingen',
  Latitude: '486.180.640',
  Longitude: '93.131.560',
  Telephone: '70225029310',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.618064,9.313156',
  id: '48.618064,9.313156'
}, {
  Brand: 'Westfalen',
  Street: 'Duesseldorfer Landstr. 343',
  PostCode: null,
  City: 'DUISBURG',
  Latitude: '513.535',
  Longitude: '674.872',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.3535,6.74872',
  id: '51.3535,6.74872'
}, {
  Brand: 'Eni',
  Street: 'Badelacher Weg 10',
  PostCode: 36404,
  City: 'Vacha',
  Latitude: '5.082.847.000',
  Longitude: '1.003.043.000',
  Telephone: '+49 (0) 36962246',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.82847,10.03043',
  id: '50.82847,10.03043'
}, {
  Brand: 'Eni',
  Street: 'Karlsruher Str. 85',
  PostCode: 75179,
  City: 'Pforzheim',
  Latitude: '4.890.988.000',
  Longitude: '864.727.000',
  Telephone: '+49 (0) 72313282',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.90988,8.64727',
  id: '48.90988,8.64727'
}, {
  Brand: 'Eni',
  Street: 'Richard-Wagner-Ring 26',
  PostCode: 76437,
  City: 'Rastatt',
  Latitude: '4.886.691.000',
  Longitude: '820.811.000',
  Telephone: '+49 (0) 72222281',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.86691,8.20811',
  id: '48.86691,8.20811'
}, {
  Brand: 'OMV',
  Street: 'Schaffhauserstr. 40',
  PostCode: 79713,
  City: 'Bad Säckingen',
  Latitude: '4.755.987.000',
  Longitude: '796.361.000',
  Telephone: '+49-7761-1480',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '47.55987,7.96361',
  id: '47.55987,7.96361'
}, {
  Brand: 'Eni',
  Street: 'Am Hockheimring, Fahrerlager 3',
  PostCode: 68766,
  City: 'Hockenheim',
  Latitude: '4.932.117.000',
  Longitude: '856.822.000',
  Telephone: '06205/950173',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.32117,8.56822',
  id: '49.32117,8.56822'
}, {
  Brand: 'OMV',
  Street: 'Erlanger Str. 17',
  PostCode: 91077,
  City: 'Neunkirchen',
  Latitude: '4.961.051.000',
  Longitude: '1.112.775.000',
  Telephone: '+49-9134-611',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.61051,11.12775',
  id: '49.61051,11.12775'
}, {
  Brand: 'TOTAL',
  Street: 'FRIEDENSSTR.1',
  PostCode: 39393,
  City: 'VOELPKE',
  Latitude: '52.140.622',
  Longitude: '11.105.736',
  Telephone: '039402-9610',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.140622,11.105736',
  id: '52.140622,11.105736'
}, {
  Brand: 'OMV',
  Street: 'BAB 6',
  PostCode: 91126,
  City: 'Kammerstein',
  Latitude: '4.930.635.000',
  Longitude: '1.096.747.000',
  Telephone: '+49-9122-60397-0',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.30635,10.96747',
  id: '49.30635,10.96747'
}, {
  Brand: 'TOTAL',
  Street: 'ERNST-THAELMANN-STR. 1',
  PostCode: 2994,
  City: 'BERNSDORF',
  Latitude: '51.376.899',
  Longitude: '14.071.393',
  Telephone: '035723-20110',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.376899,14.071393',
  id: '51.376899,14.071393'
}, {
  Brand: 'Westfalen',
  Street: 'Lippestr. 34',
  PostCode: null,
  City: 'LIPPSTADT',
  Latitude: '516.902',
  Longitude: '837.274',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.6902,8.37274',
  id: '51.6902,8.37274'
}, {
  Brand: 'ARAL',
  Street: 'Dormettinger Straße 13',
  PostCode: 72359,
  City: 'Dotternhausen',
  Latitude: '482.273.960',
  Longitude: '87.833.170',
  Telephone: '742791015',
  WeekDayFrom: '04:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '05:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.227396,8.783317',
  id: '48.227396,8.783317'
}, {
  Brand: 'OMV',
  Street: 'Hauptstr. 2',
  PostCode: 91356,
  City: 'Kirchehrenbach',
  Latitude: '4.973.137.000',
  Longitude: '1.114.116.000',
  Telephone: '+49-9191-94814',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.73137,11.14116',
  id: '49.73137,11.14116'
}, {
  Brand: 'Eni',
  Street: 'Sandershaeuser Str. 43',
  PostCode: 34123,
  City: 'Kassel',
  Latitude: '5.130.889.000',
  Longitude: '951.857.000',
  Telephone: '+49 (0) 56157995',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.30889,9.51857',
  id: '51.30889,9.51857'
}, {
  Brand: 'Eni',
  Street: 'Goethestr. 2',
  PostCode: 88046,
  City: 'Friedrichshafen',
  Latitude: '4.766.001.000',
  Longitude: '948.732.000',
  Telephone: '+49 (0) 75417313',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.66001,9.48732',
  id: '47.66001,9.48732'
}, {
  Brand: 'TOTAL',
  Street: 'UNTERER KELLERMANN 1',
  PostCode: 97273,
  City: 'KUERNACH',
  Latitude: '49.84',
  Longitude: '10.0183',
  Telephone: '93679889070',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.84,10.0183',
  id: '49.84,10.0183'
}, {
  Brand: 'TOTAL',
  Street: 'HANNOEVERSCHE STR.52A',
  PostCode: 44143,
  City: 'DORTMUND',
  Latitude: '5.152.445',
  Longitude: '7.511.009',
  Telephone: '0231-5315877',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.52445,7.511009',
  id: '51.52445,7.511009'
}, {
  Brand: 'Eni',
  Street: 'Seehauser Str. 9',
  PostCode: 83324,
  City: 'Ruhpolding',
  Latitude: '4.775.992.000',
  Longitude: '1.265.020.000',
  Telephone: '+49 (0) 86631336',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.75992,12.6502',
  id: '47.75992,12.6502'
}, {
  Brand: 'OMV',
  Street: 'Am Forst 2',
  PostCode: 85560,
  City: 'Ebersberg',
  Latitude: '4.809.624.000',
  Longitude: '1.196.238.000',
  Telephone: '+49-8092-868300',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.09624,11.96238',
  id: '48.09624,11.96238'
}, {
  Brand: 'Eni',
  Street: 'Bahnhofstrae 15',
  PostCode: 84079,
  City: 'Bruckberg',
  Latitude: '4.852.009.841',
  Longitude: '1.199.503.696',
  Telephone: '+49 (0) 87659204',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.52009841,11.99503696',
  id: '48.52009841,11.99503696'
}, {
  Brand: 'TOTAL',
  Street: 'KANTSTR. 25',
  PostCode: 97074,
  City: 'WUERZBURG',
  Latitude: '49.777.984',
  Longitude: '9.942.717',
  Telephone: '0931-81701',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.777984,9.942717',
  id: '49.777984,9.942717'
}, {
  Brand: 'TOTAL',
  Street: '75 LERCHENAUERSTR.',
  PostCode: 80809,
  City: 'MUENCHEN',
  Latitude: '48.182.302',
  Longitude: '11.553.909',
  Telephone: '089-23540304',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.182302,11.553909',
  id: '48.182302,11.553909'
}, {
  Brand: 'OMV',
  Street: 'Straubinger Str. 73',
  PostCode: 93055,
  City: 'Regensburg',
  Latitude: '4.901.370.000',
  Longitude: '1.213.971.000',
  Telephone: '+49-941-7994512',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.0137,12.13971',
  id: '49.0137,12.13971'
}, {
  Brand: 'OMV',
  Street: 'Amberger Str. 42',
  PostCode: 92318,
  City: 'Neumarkt',
  Latitude: '4.928.663.000',
  Longitude: '1.146.839.000',
  Telephone: '+49-9181-43466',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.28663,11.46839',
  id: '49.28663,11.46839'
}, {
  Brand: 'OMV',
  Street: 'Königsbrunner Str. 1 a',
  PostCode: 86399,
  City: 'Bobingen',
  Latitude: '4.826.464.000',
  Longitude: '1.083.999.000',
  Telephone: '+49-8234-6623',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.26464,10.83999',
  id: '48.26464,10.83999'
}, {
  Brand: 'Eni',
  Street: 'Dresdner Str. 84',
  PostCode: 9130,
  City: 'Chemnitz',
  Latitude: '5.084.159.176',
  Longitude: '1.293.563.266',
  Telephone: '+49 (0) 37140450',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.84159176,12.93563266',
  id: '50.84159176,12.93563266'
}, {
  Brand: 'Eni',
  Street: 'Blaufelder Strasse',
  PostCode: 74564,
  City: 'Crailsheim',
  Latitude: '4.914.064.308',
  Longitude: '1.007.338.490',
  Telephone: '+49 (0) 79515922',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.14064308,10.0733849',
  id: '49.14064308,10.0733849'
}, {
  Brand: 'Eni',
  Street: 'Jenaer Str. 71',
  PostCode: 7607,
  City: 'Eisenberg',
  Latitude: '5.096.984.449',
  Longitude: '1.185.835.613',
  Telephone: '+49 (0) 36691561',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.96984449,11.85835613',
  id: '50.96984449,11.85835613'
}, {
  Brand: 'OMV',
  Street: 'Gewerbestrasse Nord 19',
  PostCode: 86857,
  City: 'Hurlach',
  Latitude: '4.812.714.000',
  Longitude: '1.083.544.000',
  Telephone: '+49-8248-9019180',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.12714,10.83544',
  id: '48.12714,10.83544'
}, {
  Brand: 'Eni',
  Street: 'H.-M.-Schleyer Str. 1',
  PostCode: 71063,
  City: 'Sindelfingen',
  Latitude: '4.870.349.621',
  Longitude: '900.171.422',
  Telephone: '+49 (0) 70318165',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.70349621,9.00171422',
  id: '48.70349621,9.00171422'
}, {
  Brand: 'ARAL',
  Street: 'Rosensteinstraße 1',
  PostCode: 70806,
  City: 'Kornwestheim',
  Latitude: '488.563.010',
  Longitude: '91.855.610',
  Telephone: '715422060',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.856301,9.185561',
  id: '48.856301,9.185561'
}, {
  Brand: 'TOTAL',
  Street: 'HALTERNER STR.100',
  PostCode: 45657,
  City: 'RECKLINGHAUSEN',
  Latitude: '5.162.437',
  Longitude: '7.192.956',
  Telephone: '02361-22485',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.62437,7.192956',
  id: '51.62437,7.192956'
}, {
  Brand: 'ARAL',
  Street: 'Glanstraße 44',
  PostCode: 66869,
  City: 'Kusel',
  Latitude: '495.397.600',
  Longitude: '74.131.750',
  Telephone: '06381/429715',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '49.53976,7.413175',
  id: '49.53976,7.413175'
}, {
  Brand: 'TOTAL',
  Street: 'ROTHSTEINSLACHE 2',
  PostCode: 4895,
  City: 'FALKENBERG',
  Latitude: '51.587.042',
  Longitude: '13.248.595',
  Telephone: '03536-531275',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.587042,13.248595',
  id: '51.587042,13.248595'
}, {
  Brand: 'TOTAL',
  Street: '3 PLAUER LANDSTR.',
  PostCode: 14772,
  City: 'BRANDENBURG',
  Latitude: '52.416.699',
  Longitude: '12.485.982',
  Telephone: '03381-700066',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.416699,12.485982',
  id: '52.416699,12.485982'
}, {
  Brand: 'ARAL',
  Street: 'Oppelner Straße 203',
  PostCode: 90473,
  City: 'Nürnberg',
  Latitude: '494.022.380',
  Longitude: '111.335.970',
  Telephone: '9119898948',
  WeekDayFrom: '07:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '09:00',
  SaturdayTo: '21:00',
  SundayFrom: '09:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '49.402238,11.133597',
  id: '49.402238,11.133597'
}, {
  Brand: 'TOTAL',
  Street: 'SAALFELDER STR. 90',
  PostCode: 98739,
  City: 'LICHTE',
  Latitude: '50.523.624',
  Longitude: '11.216.205',
  Telephone: '036701-60425',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.523624,11.216205',
  id: '50.523624,11.216205'
}, {
  Brand: 'OMV',
  Street: 'Hohenlohe Süd A6',
  PostCode: 74632,
  City: 'Neuenstein',
  Latitude: '',
  Longitude: '',
  Telephone: '+49-7942-917116',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '0,0',
  id: '0,0'
}, {
  Brand: 'ARAL',
  Street: 'Hamburger Straße 65',
  PostCode: 38114,
  City: 'Braunschweig',
  Latitude: '522.853.700',
  Longitude: '105.185.500',
  Telephone: '5312396801',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.28537,10.51855',
  id: '52.28537,10.51855'
}, {
  Brand: 'ARAL',
  Street: 'Plohnbachstraße 2',
  PostCode: 8485,
  City: 'Lengenfeld',
  Latitude: '505.752.690',
  Longitude: '123.869.750',
  Telephone: '3760633663',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.575269,12.386975',
  id: '50.575269,12.386975'
}, {
  Brand: 'TOTAL',
  Street: '116 ROSENHEIMER STR.',
  PostCode: 83080,
  City: 'NIEDERAUDORF',
  Latitude: '4.766.748',
  Longitude: '1.216.909',
  Telephone: '08033/1501',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.66748,12.16909',
  id: '47.66748,12.16909'
}, {
  Brand: 'TOTAL',
  Street: 'AN DER KLEMPNITZ 2',
  PostCode: 16868,
  City: 'WUSTERHAUSEN/DOSSE OT WUSTERHAUSEN',
  Latitude: '52.893.602',
  Longitude: '12.455.119',
  Telephone: '033979-14515/507',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.893602,12.455119',
  id: '52.893602,12.455119'
}, {
  Brand: 'OMV',
  Street: 'Dammühlstr. 28',
  PostCode: 76829,
  City: 'Landau',
  Latitude: '4.920.163.000',
  Longitude: '812.423.000',
  Telephone: '+49-6341-81526',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.20163,8.12423',
  id: '49.20163,8.12423'
}, {
  Brand: 'TOTAL',
  Street: '357 a DR.-WILH.-ROELEN-STR',
  PostCode: 47179,
  City: 'DUISBURG',
  Latitude: '51.527.171',
  Longitude: '6.735.937',
  Telephone: '0203-493735',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.527171,6.735937',
  id: '51.527171,6.735937'
}, {
  Brand: 'ARAL',
  Street: 'Industriestraße',
  PostCode: 84130,
  City: 'Dingolfing',
  Latitude: '486.477.200',
  Longitude: '124.534.540',
  Telephone: '',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.64772,12.453454',
  id: '48.64772,12.453454'
}, {
  Brand: 'Westfalen',
  Street: 'Embser Landstr. 22',
  PostCode: 28832,
  City: 'ACHIM',
  Latitude: '5.301.937',
  Longitude: '903.098',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.01937,9.03098',
  id: '53.01937,9.03098'
}, {
  Brand: 'Eni',
  Street: 'Hauptstrasse 38',
  PostCode: 73329,
  City: 'Kuchen',
  Latitude: '4.863.763.000',
  Longitude: '979.569.000',
  Telephone: '+49 (0) 73318681',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.63763,9.79569',
  id: '48.63763,9.79569'
}, {
  Brand: 'Westfalen',
  Street: 'Sutthauser Str. 166',
  PostCode: null,
  City: 'OSNABRUECK',
  Latitude: '522.561',
  Longitude: '804.179',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.2561,8.04179',
  id: '52.2561,8.04179'
}, {
  Brand: 'Westfalen',
  Street: 'Nicolaus-Otto-Str. 1',
  PostCode: null,
  City: 'DUEREN',
  Latitude: '507.793',
  Longitude: '650.664',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.7793,6.50664',
  id: '50.7793,6.50664'
}, {
  Brand: 'OMV',
  Street: 'Mannheimer Str. 78 a',
  PostCode: 68782,
  City: 'Brühl',
  Latitude: '4.940.219.000',
  Longitude: '853.787.000',
  Telephone: '+49-6202-702949',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.40219,8.53787',
  id: '49.40219,8.53787'
}, {
  Brand: 'OMV',
  Street: 'Am Bahnhof 2',
  PostCode: 86983,
  City: 'Lechbruck',
  Latitude: '4.769.755.000',
  Longitude: '1.079.198.000',
  Telephone: '+49-8862-8498',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.69755,10.79198',
  id: '47.69755,10.79198'
}, {
  Brand: 'TOTAL',
  Street: 'MEIERKAMP 1',
  PostCode: 31249,
  City: 'HOHENHAMELN',
  Latitude: '5.226.262',
  Longitude: '1.007.507',
  Telephone: '05128-9489910',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.26262,10.07507',
  id: '52.26262,10.07507'
}, {
  Brand: 'Westfalen',
  Street: 'Am Anger 22',
  PostCode: null,
  City: 'GUETERSLOH',
  Latitude: '519.005',
  Longitude: '840.111',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9005,8.40111',
  id: '51.9005,8.40111'
}, {
  Brand: 'ARAL',
  Street: 'Südliche Ringstraße 62',
  PostCode: 85053,
  City: 'Ingolstadt',
  Latitude: '487.613.520',
  Longitude: '114.404.930',
  Telephone: '84162593',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.761352,11.440493',
  id: '48.761352,11.440493'
}, {
  Brand: 'TOTAL',
  Street: 'JAEGERHOF 10',
  PostCode: 41516,
  City: 'GREVENBROICH',
  Latitude: '51.121.755',
  Longitude: '6.666.714',
  Telephone: '02182-885034',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.121755,6.666714',
  id: '51.121755,6.666714'
}, {
  Brand: 'ARAL',
  Street: 'Baukreativstraße',
  PostCode: 91628,
  City: 'Steinsfeld-Endsee',
  Latitude: '494.454.950',
  Longitude: '102.458.530',
  Telephone: '',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.445495,10.245853',
  id: '49.445495,10.245853'
}, {
  Brand: 'TOTAL',
  Street: '2 AM SAUERECK',
  PostCode: 86732,
  City: 'OETTINGEN',
  Latitude: '48.946.509',
  Longitude: '10.595.822',
  Telephone: '09082-1444',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.946509,10.595822',
  id: '48.946509,10.595822'
}, {
  Brand: 'TOTAL',
  Street: '107 HAUPTSTR.',
  PostCode: 49205,
  City: 'HASBERGEN',
  Latitude: '52.265.749',
  Longitude: '7.964.343',
  Telephone: '05405-8081564',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.265749,7.964343',
  id: '52.265749,7.964343'
}, {
  Brand: 'Eni',
  Street: 'Friedberger Strasse 10',
  PostCode: 61381,
  City: 'Friedrichsdorf-Koepp',
  Latitude: '5.027.863.000',
  Longitude: '865.529.000',
  Telephone: '+49 (0) 61757067',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.27863,8.65529',
  id: '50.27863,8.65529'
}, {
  Brand: 'ARAL',
  Street: 'Nienhagener Straße 13',
  PostCode: 29339,
  City: 'Wathlingen',
  Latitude: '525.408.030',
  Longitude: '101.415.190',
  Telephone: '5144681',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.540803,10.141519',
  id: '52.540803,10.141519'
}, {
  Brand: 'ARAL',
  Street: 'Rheinstr. 9',
  PostCode: 96052,
  City: 'Bamberg',
  Latitude: '499.102.140',
  Longitude: '108.557.070',
  Telephone: '220578157',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.910214,10.855707',
  id: '49.910214,10.855707'
}, {
  Brand: 'OMV',
  Street: 'Mindelheimer Str. 33',
  PostCode: 87742,
  City: 'Dirlewang',
  Latitude: '4.800.137.000',
  Longitude: '1.050.650.000',
  Telephone: '+49-8267-207',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.00137,10.5065',
  id: '48.00137,10.5065'
}, {
  Brand: 'ARAL',
  Street: 'A9',
  PostCode: 91257,
  City: 'Pegnitz',
  Latitude: '497.473.470',
  Longitude: '115.133.460',
  Telephone: '92414944510',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.747347,11.513346',
  id: '49.747347,11.513346'
}, {
  Brand: 'TOTAL',
  Street: '36 A BRAUNSCHWEIGER STR.',
  PostCode: 38723,
  City: 'SEESEN',
  Latitude: '51.897.853',
  Longitude: '10.183.157',
  Telephone: '05381-1220',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.897853,10.183157',
  id: '51.897853,10.183157'
}, {
  Brand: 'Eni',
  Street: 'Obertuerkheimer Str. 21',
  PostCode: 73733,
  City: 'Esslingen',
  Latitude: '4.875.094.000',
  Longitude: '927.560.000',
  Telephone: '+49 (0) 71191833',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.75094,9.2756',
  id: '48.75094,9.2756'
}, {
  Brand: 'TOTAL',
  Street: 'HOMBERGER STR.21',
  PostCode: 40882,
  City: 'RATINGEN',
  Latitude: '51.295.966',
  Longitude: '686.676',
  Telephone: '02102-843167',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.295966,6.86676',
  id: '51.295966,6.86676'
}, {
  Brand: 'TOTAL',
  Street: 'ROHRAUER STR.38',
  PostCode: 71154,
  City: 'NUFRINGEN',
  Latitude: '4.862.083',
  Longitude: '889.461',
  Telephone: '7032958055',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.62083,8.89461',
  id: '48.62083,8.89461'
}, {
  Brand: 'Westfalen',
  Street: 'Bahnhofstr. 90',
  PostCode: null,
  City: 'OERLINGHAUSEN',
  Latitude: '519.795',
  Longitude: '871.404',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9795,8.71404',
  id: '51.9795,8.71404'
}, {
  Brand: 'TOTAL',
  Street: '18 HEIDESTRASSE',
  PostCode: 10557,
  City: 'BERLIN',
  Latitude: '52.534.217',
  Longitude: '13.365.195',
  Telephone: '030 308739 87',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.534217,13.365195',
  id: '52.534217,13.365195'
}, {
  Brand: 'Eni',
  Street: 'Neckarsteinacher Str. 21',
  PostCode: 69151,
  City: 'Neckargemuend',
  Latitude: '4.939.578.334',
  Longitude: '880.907.417',
  Telephone: '+49 (0) 62232715',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.39578334,8.80907417',
  id: '49.39578334,8.80907417'
}, {
  Brand: 'ARAL',
  Street: 'Oldenburger Str. 290',
  PostCode: 26203,
  City: 'Wardenburg',
  Latitude: '530.569.020',
  Longitude: '81.995.960',
  Telephone: '4407998070',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '53.056902,8.199596',
  id: '53.056902,8.199596'
}, {
  Brand: 'Eni',
  Street: 'Callenberger Strasse 38',
  PostCode: 96450,
  City: 'Coburg',
  Latitude: '5.026.845.000',
  Longitude: '1.095.439.000',
  Telephone: '+49 (0) 95616956',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.26845,10.95439',
  id: '50.26845,10.95439'
}, {
  Brand: 'TOTAL',
  Street: 'MERCHINGER STR.1',
  PostCode: 74706,
  City: 'OSTERBURKEN',
  Latitude: '49.427.937',
  Longitude: '9.427.066',
  Telephone: '629164210',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.427937,9.427066',
  id: '49.427937,9.427066'
}, {
  Brand: 'OMV',
  Street: 'Speyerer Str. 20',
  PostCode: 69124,
  City: 'Heidelberg',
  Latitude: '4.939.567.000',
  Longitude: '866.930.000',
  Telephone: '+49-6221-22787',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.39567,8.6693',
  id: '49.39567,8.6693'
}, {
  Brand: 'OMV',
  Street: 'Immenstädter Str. 5',
  PostCode: 87534,
  City: 'Oberstaufen',
  Latitude: '4.755.529.000',
  Longitude: '1.002.560.000',
  Telephone: '+49-8386-960740',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.55529,10.0256',
  id: '47.55529,10.0256'
}, {
  Brand: 'ARAL',
  Street: 'Buehlweg 1',
  PostCode: 95482,
  City: 'Gefrees',
  Latitude: '500.940.510',
  Longitude: '117.316.390',
  Telephone: '92549616926',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.094051,11.731639',
  id: '50.094051,11.731639'
}, {
  Brand: 'ARAL',
  Street: 'Bitscher Straße 20a',
  PostCode: 66482,
  City: 'Zweibrücken',
  Latitude: '492.355.240',
  Longitude: '73.619.550',
  Telephone: '6332470736',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.235524,7.361955',
  id: '49.235524,7.361955'
}, {
  Brand: 'ARAL',
  Street: 'Holderäcker 1',
  PostCode: 73527,
  City: 'Schwäbisch Gmünd',
  Latitude: '488.263.000',
  Longitude: '98.540.070',
  Telephone: '7171878453',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Ja',
  location: '48.8263,9.854007',
  id: '48.8263,9.854007'
}, {
  Brand: 'ARAL',
  Street: 'Porschestraße 3',
  PostCode: 71634,
  City: 'Ludwigsburg',
  Latitude: '489.205.040',
  Longitude: '91.505.730',
  Telephone: '71416432890',
  WeekDayFrom: '07:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '08:00',
  SaturdayTo: '22:00',
  SundayFrom: '00:00',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.920504,9.150573',
  id: '48.920504,9.150573'
}, {
  Brand: 'TOTAL',
  Street: 'AM URBICHER KREUZ 34',
  PostCode: 99099,
  City: 'ERFURT',
  Latitude: '50.949.585',
  Longitude: '11.094.185',
  Telephone: '0361-4210828',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.949585,11.094185',
  id: '50.949585,11.094185'
}, {
  Brand: 'TOTAL',
  Street: 'GOLDBECKSTR. 1',
  PostCode: 69493,
  City: 'HIRSCHBERG',
  Latitude: '49.507.398',
  Longitude: '8.636.922',
  Telephone: '06201-876431',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.507398,8.636922',
  id: '49.507398,8.636922'
}, {
  Brand: 'Westfalen',
  Street: 'Nobelstraße',
  PostCode: null,
  City: 'HOERSTEL',
  Latitude: '52.309.080',
  Longitude: '7.616.140',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.30908,7.61614',
  id: '52.30908,7.61614'
}, {
  Brand: 'Eni',
  Street: 'Niederwerrner Str. 4  1/3',
  PostCode: 97421,
  City: 'Schweinfurt',
  Latitude: '5.004.840.000',
  Longitude: '1.022.893.000',
  Telephone: '+49 (0) 97211865',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.0484,10.22893',
  id: '50.0484,10.22893'
}, {
  Brand: 'TOTAL',
  Street: 'BORSCHER STRASSE 21',
  PostCode: 36419,
  City: 'GEISA',
  Latitude: '50.722.452',
  Longitude: '995.847',
  Telephone: '036967-76511',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.722452,9.95847',
  id: '50.722452,9.95847'
}, {
  Brand: 'ARAL',
  Street: 'A6',
  PostCode: 66914,
  City: 'Waldmohr',
  Latitude: '493.728.080',
  Longitude: '73.754.100',
  Telephone: '63737514',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.372808,7.37541',
  id: '49.372808,7.37541'
}, {
  Brand: 'ARAL',
  Street: 'Elmstraße 2',
  PostCode: 38448,
  City: 'Wolfsburg',
  Latitude: '523.425.790',
  Longitude: '108.522.490',
  Telephone: '53652889',
  WeekDayFrom: '06:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '07:30',
  SaturdayTo: '20:30',
  SundayFrom: '08:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '52.342579,10.852249',
  id: '52.342579,10.852249'
}, {
  Brand: 'TOTAL',
  Street: 'SYKERSTR. 74',
  PostCode: 27751,
  City: 'DELMENHORST',
  Latitude: '53.050.333',
  Longitude: '8.654.466',
  Telephone: '04221-71253',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.050333,8.654466',
  id: '53.050333,8.654466'
}, {
  Brand: 'ARAL',
  Street: 'In der Lehmkuhlenbreite 3',
  PostCode: 39179,
  City: 'Barleben',
  Latitude: '522.055.570',
  Longitude: '116.065.820',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.205557,11.606582',
  id: '52.205557,11.606582'
}, {
  Brand: 'TOTAL',
  Street: 'ERFURTER LANDSTRASSE 49',
  PostCode: 99095,
  City: 'ERFURT-STOTTERNHEIM',
  Latitude: '5.104.624',
  Longitude: '11.042.137',
  Telephone: '036204-52515',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.04624,11.042137',
  id: '51.04624,11.042137'
}, {
  Brand: 'TOTAL',
  Street: 'OBERSPREESTR. 138',
  PostCode: 12555,
  City: 'BERLIN',
  Latitude: '524.458',
  Longitude: '1.356.179',
  Telephone: '030-6519761',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.4458,13.56179',
  id: '52.4458,13.56179'
}, {
  Brand: 'TOTAL',
  Street: 'ZSCHOPAUER STR. 319',
  PostCode: 9127,
  City: 'CHEMNITZ',
  Latitude: '50.810.155',
  Longitude: '12.965.084',
  Telephone: '0371-7253452',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.810155,12.965084',
  id: '50.810155,12.965084'
}, {
  Brand: 'OMV',
  Street: 'An der B 25 / Luitpoldstr. 23',
  PostCode: 91550,
  City: 'Dinkelsbühl',
  Latitude: '4.906.781.000',
  Longitude: '1.032.819.000',
  Telephone: '+49-9851-552309',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.06781,10.32819',
  id: '49.06781,10.32819'
}, {
  Brand: 'TOTAL',
  Street: 'ROSTOCKER STR. 154',
  PostCode: 17166,
  City: 'TETEROW',
  Latitude: '53.785.857',
  Longitude: '12.570.469',
  Telephone: '03996-172306',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.785857,12.570469',
  id: '53.785857,12.570469'
}, {
  Brand: 'ARAL',
  Street: 'Kötzschenbroder Str. 193',
  PostCode: 1139,
  City: 'Dresden',
  Latitude: '510.926.010',
  Longitude: '136.705.010',
  Telephone: '3518371511',
  WeekDayFrom: '06:00',
  WeekDayTo: '20:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '20:00',
  SundayFrom: '07:00',
  SundayTo: '20:00',
  IsOpen24Hours: 'Nein',
  location: '51.092601,13.670501',
  id: '51.092601,13.670501'
}, {
  Brand: 'TOTAL',
  Street: 'HEEKER STR. 9',
  PostCode: 48629,
  City: 'METELEN',
  Latitude: '52.143.433',
  Longitude: '7.207.813',
  Telephone: '02556-98775',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.143433,7.207813',
  id: '52.143433,7.207813'
}, {
  Brand: 'ARAL',
  Street: 'Im Seifental',
  PostCode: 53498,
  City: 'Bad Breisig',
  Latitude: '505.343.530',
  Longitude: '72.683.820',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.534353,7.268382',
  id: '50.534353,7.268382'
}, {
  Brand: 'ARAL',
  Street: 'Sonnenfeld 2',
  PostCode: 83395,
  City: 'Freilassing',
  Latitude: '478.429.010',
  Longitude: '129.709.010',
  Telephone: '86547721985',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '06:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.842901,12.970901',
  id: '47.842901,12.970901'
}, {
  Brand: 'ARAL',
  Street: 'Kleinsendelbacher Str. 12',
  PostCode: 91077,
  City: 'Neunkirchen am Brand',
  Latitude: '496.041.420',
  Longitude: '111.380.340',
  Telephone: '9134906906',
  WeekDayFrom: '05:30',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:30',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.604142,11.138034',
  id: '49.604142,11.138034'
}, {
  Brand: 'Westfalen',
  Street: 'Weseler Str. 3',
  PostCode: null,
  City: 'DUELMEN',
  Latitude: '518.648',
  Longitude: '736.028',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8648,7.36028',
  id: '51.8648,7.36028'
}, {
  Brand: 'ARAL',
  Street: 'A 61 West',
  PostCode: 50181,
  City: 'Bedburg',
  Latitude: '510.051.410',
  Longitude: '65.222.700',
  Telephone: '2272407650',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.005141,6.52227',
  id: '51.005141,6.52227'
}, {
  Brand: 'ARAL',
  Street: 'Neukamp 4',
  PostCode: 26655,
  City: 'Westerstede',
  Latitude: '532.570.680',
  Longitude: '79.279.450',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '53.257068,7.927945',
  id: '53.257068,7.927945'
}, {
  Brand: 'TOTAL',
  Street: 'MARTIN-LUTHER-STR. 21',
  PostCode: 66111,
  City: 'SAARBRUECKEN',
  Latitude: '49.237.582',
  Longitude: '7.004.841',
  Telephone: '0681-398444',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.237582,7.004841',
  id: '49.237582,7.004841'
}, {
  Brand: 'OMV',
  Street: 'Augsburger Str. 7',
  PostCode: 86497,
  City: 'Horgau',
  Latitude: '4.839.453.000',
  Longitude: '1.069.446.000',
  Telephone: '+49-8294-470',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.39453,10.69446',
  id: '48.39453,10.69446'
}, {
  Brand: 'TOTAL',
  Street: 'WALDSTR. 20',
  PostCode: 37520,
  City: 'OSTERODE',
  Latitude: '51.725.941',
  Longitude: '10.261.293',
  Telephone: '05522-2522',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.725941,10.261293',
  id: '51.725941,10.261293'
}, {
  Brand: 'Eni',
  Street: 'An Der B 12',
  PostCode: 83558,
  City: 'Maitenbeth-Thal',
  Latitude: '4.815.275.325',
  Longitude: '1.208.951.466',
  Telephone: '+49 (0) 8076631',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.15275325,12.08951466',
  id: '48.15275325,12.08951466'
}, {
  Brand: 'Eni',
  Street: 'Herfaer Str. 4',
  PostCode: 36289,
  City: 'Friedewald',
  Latitude: '5.088.390.000',
  Longitude: '986.600.000',
  Telephone: '+49 (0) 66749191',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.8839,9.866',
  id: '50.8839,9.866'
}, {
  Brand: 'OMV',
  Street: 'Schopflocherstr. 2',
  PostCode: 91555,
  City: 'Feuchtwangen',
  Latitude: '4.915.665.000',
  Longitude: '1.032.987.000',
  Telephone: '+49-9852-6133454',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.15665,10.32987',
  id: '49.15665,10.32987'
}, {
  Brand: 'ARAL',
  Street: 'An der BAB 5',
  PostCode: 79258,
  City: 'Hartheim-Bremgarten',
  Latitude: '479.082.590',
  Longitude: '75.923.190',
  Telephone: '763395802340',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.908259,7.592319',
  id: '47.908259,7.592319'
}, {
  Brand: 'TOTAL',
  Street: 'BECHLINER CHAUSSEE 18A',
  PostCode: 16816,
  City: 'NEURUPPIN',
  Latitude: '52.906.089',
  Longitude: '12.751.269',
  Telephone: '03391-3469813',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.906089,12.751269',
  id: '52.906089,12.751269'
}, {
  Brand: 'ARAL',
  Street: 'Industriestraße 7a',
  PostCode: 36275,
  City: 'Kirchheim',
  Latitude: '510.313.890',
  Longitude: '95.707.170',
  Telephone: '6625632',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.031389,9.570717',
  id: '51.031389,9.570717'
}, {
  Brand: 'ARAL',
  Street: 'A7',
  PostCode: 88451,
  City: 'Dettingen an der Iller',
  Latitude: '481.238.570',
  Longitude: '101.125.590',
  Telephone: '7354576',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.123857,10.112559',
  id: '48.123857,10.112559'
}, {
  Brand: 'Westfalen',
  Street: 'Bielefelder Str. 107',
  PostCode: null,
  City: 'BAT REMSCHEID-Ostseite',
  Latitude: '511.586',
  Longitude: '723.084',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.1586,7.23084',
  id: '51.1586,7.23084'
}, {
  Brand: 'TOTAL',
  Street: 'OSTERHOLZER HEERSTR. 83',
  PostCode: 28307,
  City: 'BREMEN',
  Latitude: '53.058.803',
  Longitude: '8.929.957',
  Telephone: '0421-451779',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.058803,8.929957',
  id: '53.058803,8.929957'
}, {
  Brand: 'OMV',
  Street: 'Rüdenhausenerstr. 20',
  PostCode: 97353,
  City: 'Wiesentheid',
  Latitude: '4.978.656.000',
  Longitude: '1.034.026.000',
  Telephone: '+49-9383-9099958',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.78656,10.34026',
  id: '49.78656,10.34026'
}, {
  Brand: 'Eni',
  Street: 'Eisenacher Str. 15',
  PostCode: 36266,
  City: 'Philippstal',
  Latitude: '5.088.779.500',
  Longitude: '992.265.500',
  Telephone: '+49 (0) 6620425',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.887795,9.922655',
  id: '50.887795,9.922655'
}, {
  Brand: 'Eni',
  Street: 'Flughafenstrasse 81',
  PostCode: 1109,
  City: 'Dresden',
  Latitude: '5.112.055.389',
  Longitude: '1.376.287.394',
  Telephone: '+49 (0) 35189031',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.12055389,13.76287394',
  id: '51.12055389,13.76287394'
}, {
  Brand: 'TOTAL',
  Street: 'AN DER B 51',
  PostCode: 54597,
  City: 'OLZHEIM',
  Latitude: '50.268.831',
  Longitude: '6.455.859',
  Telephone: '06552-99110',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.268831,6.455859',
  id: '50.268831,6.455859'
}, {
  Brand: 'Eni',
  Street: 'Schwaighofstrasse 93',
  PostCode: 83684,
  City: 'Tegernsee',
  Latitude: '4.769.437.000',
  Longitude: '1.177.229.000',
  Telephone: '+49 (0) 80225140',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.69437,11.77229',
  id: '47.69437,11.77229'
}, {
  Brand: 'Eni',
  Street: 'Vilbeler Landstrasse 248',
  PostCode: 60388,
  City: 'Frankfurt',
  Latitude: '5.015.693.000',
  Longitude: '874.517.000',
  Telephone: '+49 (0) 61092760',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.15693,8.74517',
  id: '50.15693,8.74517'
}, {
  Brand: 'OMV',
  Street: 'Lindauer Str. 10',
  PostCode: 86845,
  City: 'Großaitingen',
  Latitude: '4.822.747.000',
  Longitude: '1.078.073.000',
  Telephone: '+49-8203-961998',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.22747,10.78073',
  id: '48.22747,10.78073'
}, {
  Brand: 'Westfalen',
  Street: 'Herforder 110-114',
  PostCode: null,
  City: 'BIELEFELD',
  Latitude: '520.328',
  Longitude: '854.145',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0328,8.54145',
  id: '52.0328,8.54145'
}, {
  Brand: 'Westfalen',
  Street: 'Herforder 111',
  PostCode: null,
  City: 'VLOTHO',
  Latitude: '521.573',
  Longitude: '884.415',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.1573,8.84415',
  id: '52.1573,8.84415'
}, {
  Brand: 'ARAL',
  Street: 'Heisenbergstraße 3-9',
  PostCode: 50169,
  City: 'Kerpen',
  Latitude: '508.698.240',
  Longitude: '67.563.190',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.869824,6.756319',
  id: '50.869824,6.756319'
}, {
  Brand: 'TOTAL',
  Street: '23a SOLMITZSTRASSE',
  PostCode: 23569,
  City: 'LUEBECK',
  Latitude: '53.915.282',
  Longitude: '10.805.329',
  Telephone: '0451-301413',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.915282,10.805329',
  id: '53.915282,10.805329'
}, {
  Brand: 'TOTAL',
  Street: '200 KONRAD-ADENAUER-DAMM',
  PostCode: 53123,
  City: 'BONN',
  Latitude: '50.712.222',
  Longitude: '7.040.556',
  Telephone: '0228-644806',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.712222,7.040556',
  id: '50.712222,7.040556'
}, {
  Brand: 'TOTAL',
  Street: 'AM HEERBACH 1',
  PostCode: 63857,
  City: 'WALDASCHAFF',
  Latitude: '49.980.904',
  Longitude: '9.275.372',
  Telephone: '06095-3373',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.980904,9.275372',
  id: '49.980904,9.275372'
}, {
  Brand: 'Eni',
  Street: 'Dietzgenstr. 127',
  PostCode: 13158,
  City: 'Berlin',
  Latitude: '5.259.468.387',
  Longitude: '1.340.291.895',
  Telephone: '+49 (0) 30477504',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '52.59468387,13.40291895',
  id: '52.59468387,13.40291895'
}, {
  Brand: 'ARAL',
  Street: 'Kossaer Str. 2',
  PostCode: 4356,
  City: 'Leipzig',
  Latitude: '514.109.670',
  Longitude: '123.880.240',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.410967,12.388024',
  id: '51.410967,12.388024'
}, {
  Brand: 'ARAL',
  Street: 'Teuringer Straße 16',
  PostCode: 88045,
  City: 'Friedrichshafen',
  Latitude: '476.706.750',
  Longitude: '94.802.510',
  Telephone: '754155599',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '47.670675,9.480251',
  id: '47.670675,9.480251'
}, {
  Brand: 'Eni',
  Street: 'Dortelweiler Strasse 49',
  PostCode: 60389,
  City: 'Frankfurt',
  Latitude: '5.013.377.000',
  Longitude: '870.642.000',
  Telephone: '+49 (0) 69461333',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.13377,8.70642',
  id: '50.13377,8.70642'
}, {
  Brand: 'OMV',
  Street: 'Türkheimer Str. 20',
  PostCode: 86825,
  City: 'Bad Wörishofen',
  Latitude: '4.801.001.000',
  Longitude: '1.059.885.000',
  Telephone: '+49-8247-34862',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.01001,10.59885',
  id: '48.01001,10.59885'
}, {
  Brand: 'Westfalen',
  Street: 'Osnabrücker Str. 21',
  PostCode: null,
  City: 'BAD ROTHENFELDE',
  Latitude: '521.131',
  Longitude: '816.225',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.1131,8.16225',
  id: '52.1131,8.16225'
}, {
  Brand: 'TOTAL',
  Street: 'INDUSTRIESTR. 2',
  PostCode: 63533,
  City: 'MAINHAUSEN',
  Latitude: '50.017.003',
  Longitude: '8.993.676',
  Telephone: '06182-3127',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.017003,8.993676',
  id: '50.017003,8.993676'
}, {
  Brand: 'ARAL',
  Street: 'A66',
  PostCode: 36381,
  City: 'Schlüchtern',
  Latitude: '503.875.520',
  Longitude: '95.261.440',
  Telephone: '66619164695',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '50.387552,9.526144',
  id: '50.387552,9.526144'
}, {
  Brand: 'Eni',
  Street: 'Bahnhofstr 1',
  PostCode: 82152,
  City: 'Planegg',
  Latitude: '4.810.185.854',
  Longitude: '1.142.627.818',
  Telephone: '+49 (0) 89859415',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.10185854,11.42627818',
  id: '48.10185854,11.42627818'
}, {
  Brand: 'OMV',
  Street: 'Hauptstr. 3',
  PostCode: 96191,
  City: 'Viereth-Trunstadt',
  Latitude: '4.992.298.000',
  Longitude: '1.078.126.000',
  Telephone: '+49-9503-7655',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.92298,10.78126',
  id: '49.92298,10.78126'
}, {
  Brand: 'Westfalen',
  Street: 'Mondstrasse 160 - 162',
  PostCode: null,
  City: 'MUENSTER',
  Latitude: '519.554',
  Longitude: '767.722',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.9554,7.67722',
  id: '51.9554,7.67722'
}, {
  Brand: 'Eni',
  Street: 'Muenchner Strasse 24/26',
  PostCode: 82256,
  City: 'Fuerstenfeldbruck',
  Latitude: '4.817.665.000',
  Longitude: '1.126.006.000',
  Telephone: '+49 (0) 81416200',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.17665,11.26006',
  id: '48.17665,11.26006'
}, {
  Brand: 'TOTAL',
  Street: 'DORSTENER STR. 203',
  PostCode: 44652,
  City: 'HERNE',
  Latitude: '5.152.743',
  Longitude: '7.187.499',
  Telephone: '02325-910170',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.52743,7.187499',
  id: '51.52743,7.187499'
}, {
  Brand: 'TOTAL',
  Street: 'HOHERODSKOPFSTR. 2',
  PostCode: 63667,
  City: 'NIDDA',
  Latitude: '50.420.064',
  Longitude: '9.1991',
  Telephone: '06043-7031',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.420064,9.1991',
  id: '50.420064,9.1991'
}, {
  Brand: 'TOTAL',
  Street: 'PRAGER STRASSE 179',
  PostCode: 4299,
  City: 'LEIPZIG',
  Latitude: '51.313.024',
  Longitude: '12.414.926',
  Telephone: '0341-8629060',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.313024,12.414926',
  id: '51.313024,12.414926'
}, {
  Brand: 'TOTAL',
  Street: 'BAB 9 AUSFAHRT DROYSSIG/ 21B',
  PostCode: 6721,
  City: 'OSTERFELD',
  Latitude: '51.040.224',
  Longitude: '11.938.468',
  Telephone: '034422-31018',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.040224,11.938468',
  id: '51.040224,11.938468'
}, {
  Brand: 'TOTAL',
  Street: 'HABICHTSTR. 11-13',
  PostCode: 13505,
  City: 'BERLIN',
  Latitude: '52.585.858',
  Longitude: '13.231.867',
  Telephone: '030-43673233',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.585858,13.231867',
  id: '52.585858,13.231867'
}, {
  Brand: 'TOTAL',
  Street: 'GRUNDSCHOETTELER STR 58',
  PostCode: 58300,
  City: 'WETTER',
  Latitude: '51.371.183',
  Longitude: '7.369.739',
  Telephone: '02335-69161',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.371183,7.369739',
  id: '51.371183,7.369739'
}, {
  Brand: 'OMV',
  Street: 'An der Alten Ziegelei 4',
  PostCode: 89269,
  City: 'Vöhringen-Illerberg',
  Latitude: '4.828.531.000',
  Longitude: '1.011.667.000',
  Telephone: '+49-7306-926805',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.28531,10.11667',
  id: '48.28531,10.11667'
}, {
  Brand: 'ARAL',
  Street: 'Günzburger Straße 55',
  PostCode: 89335,
  City: 'Ichenhausen',
  Latitude: '483.740.010',
  Longitude: '103.113.010',
  Telephone: '82231281',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '48.374001,10.311301',
  id: '48.374001,10.311301'
}, {
  Brand: 'ARAL',
  Street: 'Hölker Feld 4',
  PostCode: 42279,
  City: 'Wuppertal',
  Latitude: '512.953.590',
  Longitude: '72.477.040',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.295359,7.247704',
  id: '51.295359,7.247704'
}, {
  Brand: 'TOTAL',
  Street: 'NIBELUNGENSTR. 129',
  PostCode: 64653,
  City: 'LORSCH',
  Latitude: '49.653.192',
  Longitude: '8.557.544',
  Telephone: '06251-51222',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.653192,8.557544',
  id: '49.653192,8.557544'
}, {
  Brand: 'Westfalen',
  Street: 'Paderborner Str. 32',
  PostCode: null,
  City: 'HOEVELHOF',
  Latitude: '518.176',
  Longitude: '866.084',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.8176,8.66084',
  id: '51.8176,8.66084'
}, {
  Brand: 'TOTAL',
  Street: 'SUTELSTR. 23',
  PostCode: 30659,
  City: 'HANNOVER',
  Latitude: '52.414.336',
  Longitude: '9.7974',
  Telephone: '0511-6497636',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.414336,9.7974',
  id: '52.414336,9.7974'
}, {
  Brand: 'Eni',
  Street: 'Merzhauser Strasse 104',
  PostCode: 79100,
  City: 'Freiburg',
  Latitude: '4.797.979.000',
  Longitude: '783.187.000',
  Telephone: '+49 (0) 76140345',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.97979,7.83187',
  id: '47.97979,7.83187'
}, {
  Brand: 'ARAL',
  Street: 'Werner-v.-Siemens-Str. 31',
  PostCode: 89415,
  City: 'Lauingen an der Donau',
  Latitude: '485.754.830',
  Longitude: '104.546.100',
  Telephone: '90727017866',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.575483,10.45461',
  id: '48.575483,10.45461'
}, {
  Brand: 'Eni',
  Street: 'Griesheimer Str. 31',
  PostCode: 64560,
  City: 'Riedstadt-Wolfskehlen',
  Latitude: '4.985.529.487',
  Longitude: '850.622.223',
  Telephone: '+49 (0) 61589752',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.85529487,8.50622223',
  id: '49.85529487,8.50622223'
}, {
  Brand: 'Westfalen',
  Street: 'Meckenheimer Str. 8',
  PostCode: null,
  City: 'RHEINBACH',
  Latitude: '50.625',
  Longitude: '696.066',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.625,6.96066',
  id: '50.625,6.96066'
}, {
  Brand: 'Eni',
  Street: 'Hauptstr. 1a',
  PostCode: 67297,
  City: 'Marnheim',
  Latitude: '4.962.936.000',
  Longitude: '803.572.000',
  Telephone: '+49 (0) 63527048',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.62936,8.03572',
  id: '49.62936,8.03572'
}, {
  Brand: 'ARAL',
  Street: 'Magdeburger Straße 252',
  PostCode: 39218,
  City: 'Schönebeck',
  Latitude: '520.202.770',
  Longitude: '117.103.570',
  Telephone: '03928/425942',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '07:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '52.020277,11.710357',
  id: '52.020277,11.710357'
}, {
  Brand: 'TOTAL',
  Street: 'ALMOSENBERG 4',
  PostCode: 97877,
  City: 'WERTHEIM',
  Latitude: '49.770.979',
  Longitude: '958.841',
  Telephone: '09342-3029802',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.770979,9.58841',
  id: '49.770979,9.58841'
}, {
  Brand: 'OMV',
  Street: 'Inntal West A 93',
  PostCode: 83088,
  City: 'Kiefersfelden',
  Latitude: '4.761.360.000',
  Longitude: '1.220.120.000',
  Telephone: '+49-8033-302560',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '47.6136,12.2012',
  id: '47.6136,12.2012'
}, {
  Brand: 'Westfalen',
  Street: 'Bahnhofstr. 76',
  PostCode: null,
  City: 'PADERBORN',
  Latitude: '517.122',
  Longitude: '873.424',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.7122,8.73424',
  id: '51.7122,8.73424'
}, {
  Brand: 'ARAL',
  Street: 'Heronger Feld  9',
  PostCode: 47638,
  City: 'Straelen',
  Latitude: '513.825.630',
  Longitude: '62.453.130',
  Telephone: '28395682873',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.382563,6.245313',
  id: '51.382563,6.245313'
}, {
  Brand: 'Westfalen',
  Street: 'Landstr. 10',
  PostCode: null,
  City: 'USLAR',
  Latitude: '51.627',
  Longitude: '966.106',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.627,9.66106',
  id: '51.627,9.66106'
}, {
  Brand: 'Eni',
  Street: 'Inntal-Ost / Autobahnrastst.2',
  PostCode: 83126,
  City: 'Kiefersfelden',
  Latitude: '4.773.387.000',
  Longitude: '1.213.147.000',
  Telephone: '+49 (0) 80333045',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '47.73387,12.13147',
  id: '47.73387,12.13147'
}, {
  Brand: 'Eni',
  Street: 'Siegener Strasse',
  PostCode: 65589,
  City: 'Hadamar',
  Latitude: '5.047.772.000',
  Longitude: '805.952.000',
  Telephone: '+49 (0) 64332630',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.47772,8.05952',
  id: '50.47772,8.05952'
}, {
  Brand: 'Eni',
  Street: 'Gottl.-Daimler-Str. 44',
  PostCode: 68165,
  City: 'Mannheim',
  Latitude: '4.947.373.378',
  Longitude: '849.856.516',
  Telephone: '+49 (0) 62144855',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.47373378,8.49856516',
  id: '49.47373378,8.49856516'
}, {
  Brand: 'Westfalen',
  Street: 'Schiffahrter Damm 511',
  PostCode: null,
  City: 'MUENSTER',
  Latitude: '520.105',
  Longitude: '7.6789',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0105,7.6789',
  id: '52.0105,7.6789'
}, {
  Brand: 'TOTAL',
  Street: 'DRESDNER STR. 49',
  PostCode: 1156,
  City: 'DRESDEN',
  Latitude: '51.087.051',
  Longitude: '13.640.393',
  Telephone: '0351-4540000',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.087051,13.640393',
  id: '51.087051,13.640393'
}, {
  Brand: 'TOTAL',
  Street: 'BAHNHOFSTR. 35',
  PostCode: 69190,
  City: 'WALLDORF',
  Latitude: '4.930.046',
  Longitude: '8.645.012',
  Telephone: '06227-890050',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.30046,8.645012',
  id: '49.30046,8.645012'
}, {
  Brand: 'TOTAL',
  Street: 'PIRSCHHEIDE 1',
  PostCode: 14471,
  City: 'POTSDAM',
  Latitude: '523.753',
  Longitude: '13.008.198',
  Telephone: '0331-972922',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.3753,13.008198',
  id: '52.3753,13.008198'
}, {
  Brand: 'ARAL',
  Street: 'A20',
  PostCode: 17129,
  City: 'Völschov',
  Latitude: '538.565.680',
  Longitude: '133.347.400',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.856568,13.33474',
  id: '53.856568,13.33474'
}, {
  Brand: 'OMV',
  Street: 'Kufsteiner Str. 27',
  PostCode: 83064,
  City: 'Raubling',
  Latitude: '4.778.699.000',
  Longitude: '1.210.983.000',
  Telephone: '+49-8035-98052',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.78699,12.10983',
  id: '47.78699,12.10983'
}, {
  Brand: 'ARAL',
  Street: 'Wiesenauer Str. 12',
  PostCode: 30851,
  City: 'Langenhagen',
  Latitude: '524.251.040',
  Longitude: '97.172.570',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '52.425104,9.717257',
  id: '52.425104,9.717257'
}, {
  Brand: 'Westfalen',
  Street: 'Natruper Str. 166',
  PostCode: null,
  City: 'OSNABRUECK',
  Latitude: '522.905',
  Longitude: '802.025',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.2905,8.02025',
  id: '52.2905,8.02025'
}, {
  Brand: 'ARAL',
  Street: 'Amperestraße 3',
  PostCode: 63225,
  City: 'Langen',
  Latitude: '499.985.920',
  Longitude: '86.529.960',
  Telephone: '61038037198',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.998592,8.652996',
  id: '49.998592,8.652996'
}, {
  Brand: 'TOTAL',
  Street: 'SONNEWALDER STR.91 b',
  PostCode: 3238,
  City: 'FINSTERWALDE',
  Latitude: '51.644.699',
  Longitude: '13.693.893',
  Telephone: '03531-704610',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.644699,13.693893',
  id: '51.644699,13.693893'
}, {
  Brand: 'Eni',
  Street: 'Schoenbuchstr. 40',
  PostCode: 71032,
  City: 'Boeblingen',
  Latitude: '4.867.316.000',
  Longitude: '901.353.000',
  Telephone: '+49 (0) 70312797',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.67316,9.01353',
  id: '48.67316,9.01353'
}, {
  Brand: 'TOTAL',
  Street: 'BAHNHOFSTR. 37',
  PostCode: 57392,
  City: 'SCHMALLENBERG',
  Latitude: '51.158.288',
  Longitude: '8.291.456',
  Telephone: '02972-6279',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.158288,8.291456',
  id: '51.158288,8.291456'
}, {
  Brand: 'ARAL',
  Street: 'Würzburger Straße 29',
  PostCode: 96049,
  City: 'Bamberg',
  Latitude: '498.801.230',
  Longitude: '108.836.390',
  Telephone: '95154378',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '49.880123,10.883639',
  id: '49.880123,10.883639'
}, {
  Brand: 'TOTAL',
  Street: 'REICHENBACHER STR. 115',
  PostCode: 8056,
  City: 'ZWICKAU',
  Latitude: '50.708.401',
  Longitude: '12.464.761',
  Telephone: '0375-216851',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.708401,12.464761',
  id: '50.708401,12.464761'
}, {
  Brand: 'ARAL',
  Street: 'Profilstr. 7',
  PostCode: 58091,
  City: 'Hagen',
  Latitude: '513.889.650',
  Longitude: '75.124.630',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.388965,7.512463',
  id: '51.388965,7.512463'
}, {
  Brand: 'TOTAL',
  Street: 'LEIPZIGER STRASSE 2F',
  PostCode: 4552,
  City: 'BORNA',
  Latitude: '51.132.339',
  Longitude: '1.250.571',
  Telephone: '03433-208626',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.132339,12.50571',
  id: '51.132339,12.50571'
}, {
  Brand: 'ARAL',
  Street: 'Blankenburgstraße 112',
  PostCode: 9114,
  City: 'Chemnitz OT Borna-He',
  Latitude: '508.639.530',
  Longitude: '129.072.880',
  Telephone: '3713379026',
  WeekDayFrom: '05:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '50.863953,12.907288',
  id: '50.863953,12.907288'
}, {
  Brand: 'TOTAL',
  Street: 'KOELLERTALSTR. 141',
  PostCode: 66346,
  City: 'PUETTLINGEN',
  Latitude: '492.939',
  Longitude: '6.892.088',
  Telephone: '06806-440332',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.2939,6.892088',
  id: '49.2939,6.892088'
}, {
  Brand: 'TOTAL',
  Street: 'VATERSTETTEN WEST A 99',
  PostCode: 85622,
  City: 'VATERSTETTEN',
  Latitude: '48.124.502',
  Longitude: '11.757.973',
  Telephone: '810635200',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.124502,11.757973',
  id: '48.124502,11.757973'
}, {
  Brand: 'Westfalen',
  Street: 'Ruhrtalstr. 5',
  PostCode: null,
  City: 'HAGEN',
  Latitude: '51.412',
  Longitude: '751.293',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.412,7.51293',
  id: '51.412,7.51293'
}, {
  Brand: 'Eni',
  Street: 'Muenchner Str. 57',
  PostCode: 85737,
  City: 'Ismaning',
  Latitude: '4.822.335.767',
  Longitude: '1.166.946.611',
  Telephone: '+49 (0) 89969238',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.22335767,11.66946611',
  id: '48.22335767,11.66946611'
}, {
  Brand: 'OMV',
  Street: 'Schweinfurter Str. 42',
  PostCode: 97076,
  City: 'Würzburg',
  Latitude: '4.979.958.000',
  Longitude: '995.614.000',
  Telephone: '+49-931-23310',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.79958,9.95614',
  id: '49.79958,9.95614'
}, {
  Brand: 'TOTAL',
  Street: 'BAB SUEDSEITE / A 4',
  PostCode: 50226,
  City: 'FRECHEN',
  Latitude: '5.092.856',
  Longitude: '6.775.694',
  Telephone: '02234-52164',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.92856,6.775694',
  id: '50.92856,6.775694'
}, {
  Brand: 'Eni',
  Street: 'Luitpoldstrae 55',
  PostCode: 84034,
  City: 'Landshut',
  Latitude: '4.854.211.133',
  Longitude: '1.213.959.734',
  Telephone: '+49 (0) 87161836',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.54211133,12.13959734',
  id: '48.54211133,12.13959734'
}, {
  Brand: 'OMV',
  Street: 'Erlbacher Str. 4',
  PostCode: 84172,
  City: 'Buch am Erlbach',
  Latitude: '4.844.968.000',
  Longitude: '1.203.303.000',
  Telephone: '+49-8709-9286008',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.44968,12.03303',
  id: '48.44968,12.03303'
}, {
  Brand: 'Eni',
  Street: 'Schmarler Damm 9',
  PostCode: 18069,
  City: 'Rostock',
  Latitude: '5.412.927.218',
  Longitude: '1.207.547.295',
  Telephone: '+49 (0) 38112096',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '54.12927218,12.07547295',
  id: '54.12927218,12.07547295'
}, {
  Brand: 'OMV',
  Street: 'Keimstr. 2',
  PostCode: 86420,
  City: 'Diedorf',
  Latitude: '4.836.319.000',
  Longitude: '1.078.954.000',
  Telephone: '+49-821-4864976',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.36319,10.78954',
  id: '48.36319,10.78954'
}, {
  Brand: 'Eni',
  Street: 'Tegernseer Strasse 20',
  PostCode: 83703,
  City: 'Gmund',
  Latitude: '4.774.743.942',
  Longitude: '1.173.804.212',
  Telephone: '+49 (0) 80227526',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.74743942,11.73804212',
  id: '47.74743942,11.73804212'
}, {
  Brand: 'OMV',
  Street: 'Cosimastr. 10',
  PostCode: 81927,
  City: 'München',
  Latitude: '4.815.563.000',
  Longitude: '1.163.000.000',
  Telephone: '+49-89-914754',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.15563,11.63',
  id: '48.15563,11.63'
}, {
  Brand: 'Eni',
  Street: 'Elverdisser Str. 327',
  PostCode: 32052,
  City: 'Herford',
  Latitude: '5.210.577.592',
  Longitude: '866.133.834',
  Telephone: '+49 (0) 52217123',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.10577592,8.66133834',
  id: '52.10577592,8.66133834'
}, {
  Brand: 'ARAL',
  Street: 'Am Wattengraben 2',
  PostCode: 55276,
  City: 'Oppenheim',
  Latitude: '498.456.640',
  Longitude: '83.601.250',
  Telephone: '61333869197',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.845664,8.360125',
  id: '49.845664,8.360125'
}, {
  Brand: 'ARAL',
  Street: 'Bau L 944, Tor 11',
  PostCode: 67059,
  City: 'Ludwigshafen',
  Latitude: '495.056.640',
  Longitude: '84.154.120',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '49.505664,8.415412',
  id: '49.505664,8.415412'
}, {
  Brand: 'TOTAL',
  Street: 'SCHILLERSTR. 80',
  PostCode: 63741,
  City: 'ASCHAFFENBURG',
  Latitude: '49.985.794',
  Longitude: '9.135.177',
  Telephone: '06021-423279',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.985794,9.135177',
  id: '49.985794,9.135177'
}, {
  Brand: 'Westfalen',
  Street: 'Fr.-Ebert-Str. 129',
  PostCode: null,
  City: 'WUPPERTAL',
  Latitude: '512.511',
  Longitude: '712.846',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.2511,7.12846',
  id: '51.2511,7.12846'
}, {
  Brand: 'ARAL',
  Street: 'A96',
  PostCode: 86899,
  City: 'Landsberg Lech',
  Latitude: '480.579.540',
  Longitude: '108.474.760',
  Telephone: '8191972010',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '48.057954,10.847476',
  id: '48.057954,10.847476'
}, {
  Brand: 'TOTAL',
  Street: '2 WALTER-ALTHOFFSTR.',
  PostCode: 31167,
  City: 'BOCKENEM',
  Latitude: '52.001.488',
  Longitude: '10.144.712',
  Telephone: '05067-7948807',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.001488,10.144712',
  id: '52.001488,10.144712'
}, {
  Brand: 'TOTAL',
  Street: 'ALTENAER STR. 244',
  PostCode: 58513,
  City: 'LUEDENSCHEID',
  Latitude: '51.254.477',
  Longitude: '7.635.161',
  Telephone: '02351-50021',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.254477,7.635161',
  id: '51.254477,7.635161'
}, {
  Brand: 'TOTAL',
  Street: '75 GROSSENBAUMER ALLEE',
  PostCode: 47269,
  City: 'DUISBURG',
  Latitude: '51.369.542',
  Longitude: '6.779.052',
  Telephone: '0203-761567',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.369542,6.779052',
  id: '51.369542,6.779052'
}, {
  Brand: 'TOTAL',
  Street: 'WEINHEIMER STR. 34',
  PostCode: 69509,
  City: 'MOERLENBACH',
  Latitude: '495.966',
  Longitude: '873.165',
  Telephone: '62093305',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.5966,8.73165',
  id: '49.5966,8.73165'
}, {
  Brand: 'TOTAL',
  Street: '87 HALBERSTAEDTER STR.',
  PostCode: 6484,
  City: 'QUEDLINBURG',
  Latitude: '51.809.142',
  Longitude: '11.128.739',
  Telephone: '03946-9019080',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.809142,11.128739',
  id: '51.809142,11.128739'
}, {
  Brand: 'TOTAL',
  Street: 'LUCKENWALDER STR. 19',
  PostCode: 14913,
  City: 'JUETERBOG',
  Latitude: '52.003.599',
  Longitude: '1.308.808',
  Telephone: '03372-432352',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.003599,13.08808',
  id: '52.003599,13.08808'
}, {
  Brand: 'Eni',
  Street: 'Finsterwalder Str. 14',
  PostCode: 3205,
  City: 'Calau',
  Latitude: '5.174.351.496',
  Longitude: '1.394.139.301',
  Telephone: '+49 (0) 35418019',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '51.74351496,13.94139301',
  id: '51.74351496,13.94139301'
}, {
  Brand: 'TOTAL',
  Street: '18 KOELNER STR.',
  PostCode: 54634,
  City: 'BITBURG',
  Latitude: '4.997.878',
  Longitude: '652.592',
  Telephone: '65612395',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.97878,6.52592',
  id: '49.97878,6.52592'
}, {
  Brand: 'TOTAL',
  Street: 'SIEGBURGER STR.490-492',
  PostCode: 51105,
  City: 'KOELN',
  Latitude: '50.913.178',
  Longitude: '699.761',
  Telephone: '0221-834992',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.913178,6.99761',
  id: '50.913178,6.99761'
}, {
  Brand: 'TOTAL',
  Street: 'LEONRODSTR. 48',
  PostCode: 80636,
  City: 'MUENCHEN',
  Latitude: '48.157.499',
  Longitude: '11.543.893',
  Telephone: '089-1292340',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.157499,11.543893',
  id: '48.157499,11.543893'
}, {
  Brand: 'Eni',
  Street: 'Talstr. 37',
  PostCode: 66287,
  City: 'Quierschied',
  Latitude: '4.930.380.000',
  Longitude: '702.652.000',
  Telephone: '+49 (0) 6897-841',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.3038,7.02652',
  id: '49.3038,7.02652'
}, {
  Brand: 'TOTAL',
  Street: 'KEMNADER STR. 315',
  PostCode: 44797,
  City: 'BOCHUM',
  Latitude: '51.428.481',
  Longitude: '7.242.503',
  Telephone: '0234-791008',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.428481,7.242503',
  id: '51.428481,7.242503'
}, {
  Brand: 'ARAL',
  Street: 'Görschlitzer Str. 7',
  PostCode: 4849,
  City: 'Bad Düben',
  Latitude: '515.899.690',
  Longitude: '126.019.520',
  Telephone: '3424325037',
  WeekDayFrom: '05:00',
  WeekDayTo: '21:00',
  SaturdayFrom: '06:00',
  SaturdayTo: '21:00',
  SundayFrom: '06:00',
  SundayTo: '21:00',
  IsOpen24Hours: 'Nein',
  location: '51.589969,12.601952',
  id: '51.589969,12.601952'
}, {
  Brand: 'OMV',
  Street: 'Alte Str. 32',
  PostCode: 79576,
  City: 'Weil am Rhein',
  Latitude: '4.759.776.000',
  Longitude: '759.987.000',
  Telephone: '+49-7621-72160',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '47.59776,7.59987',
  id: '47.59776,7.59987'
}, {
  Brand: 'TOTAL',
  Street: 'SANGERHAEUSER STR.',
  PostCode: 6556,
  City: 'ARTERN',
  Latitude: '51.364.087',
  Longitude: '11.287.002',
  Telephone: '03466-740568',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.364087,11.287002',
  id: '51.364087,11.287002'
}, {
  Brand: 'TOTAL',
  Street: 'DRESDNER STR. 30 o',
  PostCode: 4720,
  City: 'DOEBELN',
  Latitude: '51.120.799',
  Longitude: '13.150.689',
  Telephone: '03431-574700',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.120799,13.150689',
  id: '51.120799,13.150689'
}, {
  Brand: 'ARAL',
  Street: 'Gladbecker Str. 398 A',
  PostCode: 45326,
  City: 'Essen',
  Latitude: '514.861.590',
  Longitude: '69.992.990',
  Telephone: '20143644416',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.486159,6.999299',
  id: '51.486159,6.999299'
}, {
  Brand: 'Eni',
  Street: 'Industriestr. 9',
  PostCode: 64653,
  City: 'Lorsch',
  Latitude: '4.964.247.000',
  Longitude: '857.126.000',
  Telephone: '+49 (0) 6251/572',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.64247,8.57126',
  id: '49.64247,8.57126'
}, {
  Brand: 'TOTAL',
  Street: 'BAHNHOFSTR. 8',
  PostCode: 77781,
  City: 'BIBERACH',
  Latitude: '48.338.685',
  Longitude: '8.030.357',
  Telephone: '07835-3070',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.338685,8.030357',
  id: '48.338685,8.030357'
}, {
  Brand: 'Eni',
  Street: 'Oberfoehringer Str. 178',
  PostCode: 81925,
  City: 'Muenchen',
  Latitude: '4.816.625.326',
  Longitude: '1.162.366.635',
  Telephone: '+49 (0) 89951498',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.16625326,11.62366635',
  id: '48.16625326,11.62366635'
}, {
  Brand: 'TOTAL',
  Street: 'AM FEUCHTEN WINKEL 11',
  PostCode: 13127,
  City: 'BERLIN',
  Latitude: '52.582.052',
  Longitude: '1.343.239',
  Telephone: '030-47484636',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.582052,13.43239',
  id: '52.582052,13.43239'
}, {
  Brand: 'TOTAL',
  Street: 'PRITZWALKER STR. 11',
  PostCode: 16949,
  City: 'PUTLITZ',
  Latitude: '53.237.716',
  Longitude: '12.055.587',
  Telephone: '033981-80578',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.237716,12.055587',
  id: '53.237716,12.055587'
}, {
  Brand: 'OMV',
  Street: 'Lechwiesen Nord  A96',
  PostCode: 86899,
  City: 'Landsberg am Lech',
  Latitude: '4.805.963.000',
  Longitude: '1.084.657.000',
  Telephone: '+0049-08191-9720',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '48.05963,10.84657',
  id: '48.05963,10.84657'
}, {
  Brand: 'OMV',
  Street: 'Industriestr. 11',
  PostCode: 96114,
  City: 'Hirschaid',
  Latitude: '4.981.893.000',
  Longitude: '1.100.073.000',
  Telephone: '+49-9543-850485',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.81893,11.00073',
  id: '49.81893,11.00073'
}, {
  Brand: 'Westfalen',
  Street: 'Loburg 58',
  PostCode: null,
  City: 'OSTBEVERN',
  Latitude: '520.367',
  Longitude: '785.874',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.0367,7.85874',
  id: '52.0367,7.85874'
}, {
  Brand: 'TOTAL',
  Street: 'STORKOWER STR. 174',
  PostCode: 10369,
  City: 'BERLIN',
  Latitude: '5.252.744',
  Longitude: '13.457.798',
  Telephone: '030-9720385',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.52744,13.457798',
  id: '52.52744,13.457798'
}, {
  Brand: 'TOTAL',
  Street: 'DEKAN-LAIST-STR. 56',
  PostCode: 55129,
  City: 'MAINZ',
  Latitude: '49.963.816',
  Longitude: '8.253.473',
  Telephone: '06131-9728871',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.963816,8.253473',
  id: '49.963816,8.253473'
}, {
  Brand: 'TOTAL',
  Street: '245-247 LANGENER LANDSTR.',
  PostCode: 27578,
  City: 'BREMERHAVEN',
  Latitude: '53.587.848',
  Longitude: '8.597.189',
  Telephone: '0471-804075',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '53.587848,8.597189',
  id: '53.587848,8.597189'
}, {
  Brand: 'TOTAL',
  Street: 'CHAUSSEESTR. 45',
  PostCode: 17438,
  City: 'WOLGAST',
  Latitude: '54.046.696',
  Longitude: '13.752.053',
  Telephone: '03836-232995',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '54.046696,13.752053',
  id: '54.046696,13.752053'
}, {
  Brand: 'TOTAL',
  Street: 'MUENSTERSTR. 212 A',
  PostCode: 49479,
  City: 'IBBENBUEREN',
  Latitude: '52.258.098',
  Longitude: '7.711.524',
  Telephone: '05451-936673',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.258098,7.711524',
  id: '52.258098,7.711524'
}, {
  Brand: 'TOTAL',
  Street: 'WIMSHEIMER STR. 21',
  PostCode: 75233,
  City: 'TIEFENBRONN',
  Latitude: '4.882.864',
  Longitude: '880.122',
  Telephone: '07234-95150',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.82864,8.80122',
  id: '48.82864,8.80122'
}, {
  Brand: 'TOTAL',
  Street: 'DIESELSTR. 2',
  PostCode: 41352,
  City: 'KORSCHENBROICH',
  Latitude: '5.116.795',
  Longitude: '659.075',
  Telephone: '02182-5272',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.16795,6.59075',
  id: '51.16795,6.59075'
}, {
  Brand: 'TOTAL',
  Street: '33-35 TEMPELHOFER UFER',
  PostCode: 10963,
  City: 'BERLIN',
  Latitude: '52.500.478',
  Longitude: '13.376.696',
  Telephone: '030-2621259',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '52.500478,13.376696',
  id: '52.500478,13.376696'
}, {
  Brand: 'Eni',
  Street: 'Ingolstaedter Str. 1',
  PostCode: 93349,
  City: 'Mindelstetten',
  Latitude: '4.885.047.097',
  Longitude: '1.164.196.287',
  Telephone: '+49 (0) 84049148',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.85047097,11.64196287',
  id: '48.85047097,11.64196287'
}, {
  Brand: 'OMV',
  Street: 'Hauptstr. 4',
  PostCode: 94436,
  City: 'Haunersdorf / Simbach',
  Latitude: '4.860.872.000',
  Longitude: '1.271.751.000',
  Telephone: '+49-9956-323',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.60872,12.71751',
  id: '48.60872,12.71751'
}, {
  Brand: 'TOTAL',
  Street: 'MITTELBIEL 2',
  PostCode: 35606,
  City: 'SOLMS',
  Latitude: '50.554.398',
  Longitude: '8.412.371',
  Telephone: '06441-9521852',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.554398,8.412371',
  id: '50.554398,8.412371'
}, {
  Brand: 'TOTAL',
  Street: 'AUGSBURGER STRASSE 2',
  PostCode: 99091,
  City: 'ERFURT',
  Latitude: '51.002.242',
  Longitude: '11.002.569',
  Telephone: '0361-26232699',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.002242,11.002569',
  id: '51.002242,11.002569'
}, {
  Brand: 'ARAL',
  Street: 'Bahnhofstraße 140',
  PostCode: 63477,
  City: 'Maintal',
  Latitude: '501.502.390',
  Longitude: '88.360.010',
  Telephone: '61814980046',
  WeekDayFrom: '06:00',
  WeekDayTo: '22:00',
  SaturdayFrom: '07:00',
  SaturdayTo: '22:00',
  SundayFrom: '08:00',
  SundayTo: '22:00',
  IsOpen24Hours: 'Nein',
  location: '50.150239,8.836001',
  id: '50.150239,8.836001'
}, {
  Brand: 'TOTAL',
  Street: 'GRENZHAMMER 4',
  PostCode: 98693,
  City: 'ILMENAU',
  Latitude: '50.678.669',
  Longitude: '10.935.988',
  Telephone: '03677-842787',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.678669,10.935988',
  id: '50.678669,10.935988'
}, {
  Brand: 'OMV',
  Street: 'Passauer Str. 31',
  PostCode: 94104,
  City: 'Tittling',
  Latitude: '4.872.440.000',
  Longitude: '1.338.222.000',
  Telephone: '+49-8504-1648',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.7244,13.38222',
  id: '48.7244,13.38222'
}, {
  Brand: 'ARAL',
  Street: 'A5',
  PostCode: 79415,
  City: 'Bad Bellingen',
  Latitude: '477.382.050',
  Longitude: '75.519.520',
  Telephone: '763581100',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '47.738205,7.551952',
  id: '47.738205,7.551952'
}, {
  Brand: 'Westfalen',
  Street: 'Spenrather Weg 1',
  PostCode: null,
  City: 'KOELN',
  Latitude: '509.805',
  Longitude: '684.661',
  Telephone: '',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '50.9805,6.84661',
  id: '50.9805,6.84661'
}, {
  Brand: 'TOTAL',
  Street: 'FRANKENSTR. 241',
  PostCode: 45134,
  City: 'ESSEN',
  Latitude: '51.422.046',
  Longitude: '7.028.408',
  Telephone: '0201-50776097',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '51.422046,7.028408',
  id: '51.422046,7.028408'
}, {
  Brand: 'ARAL',
  Street: 'A72',
  PostCode: 8606,
  City: 'Oelsnitz/Vogtl.',
  Latitude: '504.336.250',
  Longitude: '121.296.790',
  Telephone: '3742125420',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.433625,12.129679',
  id: '50.433625,12.129679'
}, {
  Brand: 'OMV',
  Street: 'Münchner Str. 30',
  PostCode: 85567,
  City: 'Grafing',
  Latitude: '4.804.862.000',
  Longitude: '1.196.680.000',
  Telephone: '+49-8092-9254',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.04862,11.9668',
  id: '48.04862,11.9668'
}, {
  Brand: 'Eni',
  Street: 'Mannheimer Strasse 1A',
  PostCode: 76676,
  City: 'Graben-Neudorf',
  Latitude: '4.916.917.858',
  Longitude: '849.272.693',
  Telephone: '+49 (0) 72557255',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Ja',
  location: '49.16917858,8.49272693',
  id: '49.16917858,8.49272693'
}, {
  Brand: 'ARAL',
  Street: 'Mülheimer Heide 1',
  PostCode: 53945,
  City: 'Blankenheim',
  Latitude: '504.499.590',
  Longitude: '66.678.960',
  Telephone: '2449919251',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '50.449959,6.667896',
  id: '50.449959,6.667896'
}, {
  Brand: 'ARAL',
  Street: 'Otto-Hahn-Strasse 3',
  PostCode: 46569,
  City: 'Hünxe',
  Latitude: '516.309.950',
  Longitude: '66.902.820',
  Telephone: '-',
  WeekDayFrom: '00:00',
  WeekDayTo: '23:59',
  SaturdayFrom: '00:00',
  SaturdayTo: '23:59',
  SundayFrom: '00:00',
  SundayTo: '23:59',
  IsOpen24Hours: 'Ja',
  location: '51.630995,6.690282',
  id: '51.630995,6.690282'
}, {
  Brand: 'OMV',
  Street: 'Immenhofer Str. 48',
  PostCode: 70180,
  City: 'Stuttgart',
  Latitude: '4.876.293.000',
  Longitude: '917.658.000',
  Telephone: '+49-711-609164',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.76293,9.17658',
  id: '48.76293,9.17658'
}, {
  Brand: 'OMV',
  Street: 'Altöttinger Str. 40',
  PostCode: 84524,
  City: 'Neuötting',
  Latitude: '4.823.475.000',
  Longitude: '1.268.707.000',
  Telephone: '+49-8671-2398',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '48.23475,12.68707',
  id: '48.23475,12.68707'
}, {
  Brand: 'OMV',
  Street: 'Prüfeninger Str. 43',
  PostCode: 93049,
  City: 'Regensburg',
  Latitude: '4.901.670.000',
  Longitude: '1.207.516.000',
  Telephone: '+49-941-22373',
  WeekDayFrom: '',
  WeekDayTo: '',
  SaturdayFrom: '',
  SaturdayTo: '',
  SundayFrom: '',
  SundayTo: '',
  IsOpen24Hours: 'Nein',
  location: '49.0167,12.07516',
  id: '49.0167,12.07516'
}];
